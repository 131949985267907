import React from 'react';
import { connect } from 'react-redux';
import { getReportMetrics } from './store/selectors';
import Indicator from './Indicator';
import './ConstructionCurrentBudget.css';

const ConstructionCurrentBudget = (props) => {
  const { metrics } = props;
  const { current, previous, original } =
    (metrics && metrics.construction_budget) || {};

  return (
    <Indicator
      className="project-dashboard-construction-current-budget"
      name="Construction Current Budget"
      type="currency"
      tooltipPosition="right"
      current={current}
      previous={previous}
      original={original}
    />
  );
};

export default connect((state) => {
  return {
    metrics: getReportMetrics(state),
  };
})(ConstructionCurrentBudget);
