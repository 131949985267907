import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import './TermsModal.css';
import TermsModalText from './TermsModalText';

class TermsModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    invited: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
  };

  handleYes = (event) => {
    event.preventDefault();
    this.props.onHide(true);
  };

  render() {
    return (
      <Modal className="terms-modal" show={this.props.show}>
        <Modal.Body>
          <TermsModalText />
          <div className="terms_conditions_buttons">
            <input
              id="confirmation-modal-yes"
              className="btn button button-orange"
              type="button"
              value={this.props.invited ? 'Close' : 'Accept Terms'}
              onClick={this.handleYes}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default TermsModal;
