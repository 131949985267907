import {
  SUBMITTAL_LOG_INIT,
  SUBMITTAL_TAB_SELECTED_SET,
  SUBMITTAL_MODAL_SHOW_SET,
  SUBMITTAL_MODAL_INIT,
  SUBMITTAL_FORM_VALUE_UPDATE,
  SUBMITTAL_FILE_DELETE,
  SUBMITTAL_READ_ONLY_SET,
  SUBMITTAL_LEAD_TIME_READ_ONLY_SET,
  SUBMITTAL_GC_ACCELERATION_SET,
  SHOW_TRANSMITTAL_PDF_SET,
  SUBMITTAL_FORM_SET,
  SUBMITTAL_FORM_ERRORS_SET,
  SUBMITTAL_LOG_PDF_VIEWER_SET,
  SUBMITTAL_PDF_VIEWER_SET,
  SUBMITTAL_SHOW_ERROR_MESSAGE_SET,
  SUBMITTAL_ADDL_ASSIGNEES_ADD,
  SUBMITTAL_ADDL_ASSIGNEES_UPDATE,
  SUBMITTAL_ADDL_ASSIGNEES_DELETE,
  SUBMITTAL_CREATED_REVISION_MODAL_SHOW_SET,
  SUBMITTALS_RESPONSE_MODAL_SET,
  SUBMITTALS_RESPONSES_VIEW_MODAL_SET,
} from 'components/admin/projects/details/project-submittal-log/store/action-types';
import { SubmittalStatusKeys } from 'domain/submittal-status-enum';
import { uuidUtil } from 'services/utils/uuid-util.js';

const initialState = {
  submittalTabSelected: 2,
  submittalModalShow: false,
  submittalReadOnly: false,
  submittalLeadTimeReadOnly: false,
  submittalGcAcceleration: false,
  showTransmittalPdf: false,
  submittalLogPdfViewer: false,
  submittalPdfViewer: false,
  submittalShowErrorMessage: false,
  submittalCreatedRevisionModalShow: false,
  submittalResponsesViewModalShow: false,
  submittalResponseModalShow: false,

  submittalForm: {
    title: '',
    status: SubmittalStatusKeys.PENDING,
    physicalCopies: '0',
    question: '',
    assignee: [],
    recipients: [],
    reqDocs: [],
    reqImages: [],
    reqDocsDelete: [],
    reqImagesDelete: [],
    addlAssignees: [],
    errors: {},
    uid: uuidUtil.uuidv4(),
  },
};

export default function projectSubmittalLogUiReducer(
  state = initialState,
  action
) {
  let addlAssignees = null;

  switch (action.type) {
    case SUBMITTAL_LOG_INIT:
      return initialState;

    case SUBMITTAL_TAB_SELECTED_SET:
      return {
        ...state,
        submittalTabSelected: action.payload,
      };

    case SUBMITTAL_MODAL_SHOW_SET:
      return {
        ...state,
        submittalModalShow: action.payload,
      };

    case SUBMITTAL_MODAL_INIT:
      return {
        ...state,
        submittalForm: initialState.submittalForm,
        submittalReadOnly: initialState.submittalReadOnly,
        submittalLeadTimeReadOnly: initialState.submittalLeadTimeReadOnly,
        submittalGcAcceleration: initialState.submittalGcAcceleration,
        submittalShowErrorMessage: initialState.submittalShowErrorMessage,
      };

    case SUBMITTAL_FORM_VALUE_UPDATE:
      return {
        ...state,
        submittalShowErrorMessage: false,
        submittalForm: {
          ...state.submittalForm,
          [action.payload.attribute]: action.payload.value,
          errors: {
            ...state.submittalForm.errors,
            [action.payload.attribute]: false,
          },
        },
      };

    case SUBMITTAL_FILE_DELETE:
      return {
        ...state,
        submittalForm: {
          ...state.submittalForm,
          [action.payload.attribute]: [
            ...state.submittalForm[action.payload.attribute],
            action.payload.value,
          ],
        },
      };

    case SUBMITTAL_READ_ONLY_SET:
      return {
        ...state,
        submittalReadOnly: action.payload,
      };

    case SUBMITTAL_LEAD_TIME_READ_ONLY_SET:
      return {
        ...state,
        submittalLeadTimeReadOnly: action.payload,
      };

    case SHOW_TRANSMITTAL_PDF_SET:
      return {
        ...state,
        showTransmittalPdf: action.payload,
      };

    case SUBMITTAL_FORM_SET:
      return {
        ...state,
        submittalForm: action.payload,
      };

    case SUBMITTAL_FORM_ERRORS_SET:
      return {
        ...state,
        submittalForm: {
          ...state.submittalForm,
          errors: action.payload,
        },
      };

    case SUBMITTAL_GC_ACCELERATION_SET:
      return {
        ...state,
        submittalGcAcceleration: action.payload,
      };

    case SUBMITTAL_LOG_PDF_VIEWER_SET:
      return {
        ...state,
        submittalLogPdfViewer: action.payload,
      };

    case SUBMITTAL_PDF_VIEWER_SET:
      return {
        ...state,
        submittalPdfViewer: action.payload,
      };

    case SUBMITTAL_SHOW_ERROR_MESSAGE_SET:
      return {
        ...state,
        submittalShowErrorMessage: action.payload,
      };

    case SUBMITTAL_ADDL_ASSIGNEES_ADD:
      return {
        ...state,
        submittalForm: {
          ...state.submittalForm,
          addlAssignees: [...state.submittalForm.addlAssignees, action.payload],
        },
      };

    case SUBMITTAL_ADDL_ASSIGNEES_UPDATE:
      addlAssignees = [...state.submittalForm.addlAssignees];
      addlAssignees[action.payload.index] = {
        ...addlAssignees[action.payload.index],
        ...action.payload.addlAssignee,
      };
      return {
        ...state,
        submittalForm: {
          ...state.submittalForm,
          addlAssignees,
        },
      };

    case SUBMITTAL_ADDL_ASSIGNEES_DELETE:
      addlAssignees = [...state.submittalForm.addlAssignees];
      addlAssignees[action.payload] = {
        ...addlAssignees[action.payload],
        destroy: true,
      };
      return {
        ...state,
        submittalForm: {
          ...state.submittalForm,
          addlAssignees,
        },
      };

    case SUBMITTAL_CREATED_REVISION_MODAL_SHOW_SET:
      return {
        ...state,
        submittalCreatedRevisionModalShow: action.payload,
      };
    case SUBMITTALS_RESPONSE_MODAL_SET:
      return { ...state, submittalResponseModalShow: action.payload };
    case SUBMITTALS_RESPONSES_VIEW_MODAL_SET:
      console.log(action.payload);
      return { ...state, submittalResponsesViewModalShow: action.payload };
    default:
      return state;
  }
}
