import { SET_LOADED } from './action-types';

const initialState = {
  loaded: false,
};

export default function projectDashboardUiReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_LOADED:
      return {
        ...state,
        loaded: action.payload,
      };

    default:
      return state;
  }
}
