import React, { Component } from 'react';
import './Rate.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { getDarkMode } from 'selectors/theme';

class Rate extends Component {
  static propTypes = {
    className: PropTypes.string,
    totalItems: PropTypes.number,
    currentItems: PropTypes.number,
    itemTooltips: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    ),
    title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    titleTooltip: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  };

  static defaultProps = {
    totalItems: 5,
    currentItems: 0,
  };

  renderItem = (_, index) => {
    const { currentItems, itemTooltips } = this.props;

    const tooltip = itemTooltips && itemTooltips[index];

    const item = (
      <div
        key={index}
        className={`rate-item ${index < currentItems ? 'selected' : ''}`}
      ></div>
    );

    return tooltip ? (
      <OverlayTrigger
        key={index}
        placement="bottom"
        overlay={
          <Tooltip id="rate-item-tooltip" className="rate-item-tooltip">
            {tooltip}
          </Tooltip>
        }
      >
        {item}
      </OverlayTrigger>
    ) : (
      item
    );
  };

  renderTitle = () => {
    const { title, titleTooltip } = this.props;

    if (!title) {
      return null;
    }

    const item = <div className="rate-title">{title}</div>;

    return titleTooltip ? (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id="rate-title-tooltip" className="rate-title-tooltip">
            {titleTooltip}
          </Tooltip>
        }
      >
        {item}
      </OverlayTrigger>
    ) : (
      item
    );
  };

  render() {
    const { className, totalItems, darkMode } = this.props;

    const title = this.renderTitle();

    return totalItems > 0 ? (
      <div
        className={`rate-container ${darkMode ? 'dark-mode' : ''} ${
          className || ''
        }`}
      >
        {title}
        <div className="rate-items">
          {Array(totalItems).fill(0).map(this.renderItem)}
        </div>
      </div>
    ) : (
      title
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(Rate);
