import axios from 'axios';
import BaseApi from './base-api';

class ServiceAreasApi extends BaseApi {
  getServiceAreas() {
    return axios
      .get(this.baseUrl + '/service_areas')
      .then((response) => {
        return {
          ok: true,
          serviceAreas: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const serviceAreasApi = new ServiceAreasApi();
