import React, { useState } from 'react';
import { connect } from 'react-redux';

import FormModal from 'components/shared/modal/FormModal';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import Input from 'components/shared/input/Input';

import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  setAddTypeModal,
  setAddTitleModal,
  createMeetingTitle,
  createMeetingType,
} from './store/actions';
import {
  getProjectNewMeetingsAddTypeModal,
  getProjectNewMeetingsAddTitleModal,
} from './store/selectors';
import './AttendanceForm.css';
import './MeetingModal.css';

const AddTypeOrTitleModal = (props) => {
  const { modalType, dispatch, project, showIfTitle, showIfType } = props;
  const [newType, setNewType] = useState('');

  const handleClose = () => {
    if (modalType === 'type') {
      dispatch(setAddTypeModal(false));
    } else {
      dispatch(setAddTitleModal(false));
    }
  };
  const handleSave = () => {
    if (modalType === 'type') {
      dispatch(createMeetingType(project.id, newType));
    } else {
      dispatch(createMeetingTitle(project.id, newType));
    }
    handleClose();
  };
  return (
    <FormModal
      className={`meeting-modal editing`}
      show={modalType === 'type' ? showIfType : showIfTitle}
      onClose={handleClose}
      subTitle={modalType === 'type' ? 'Add Type' : 'Add Title'}
    >
      <FormControlBlock
        position="left"
        readonly={false}
        control={
          <Input
            type={'text'}
            placeholder={
              modalType === 'type' ? 'Meeting Type' : 'Meeting Title'
            }
            onChange={(value) => setNewType(value)}
            readonly={false}
            value={newType}
          />
        }
      />
      <div className="button-actions">
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.WHITE}
          label="Cancel"
          onClick={handleClose}
        />
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          label={'Save'}
          onClick={handleSave}
        />
      </div>
    </FormModal>
  );
};

export default connect((state) => ({
  showIfType: getProjectNewMeetingsAddTypeModal(state),
  showIfTitle: getProjectNewMeetingsAddTitleModal(state),
  project: getCurrentProject(state),
}))(AddTypeOrTitleModal);
