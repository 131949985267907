import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCloseoutContribs } from 'components/admin/projects/details/project-closeout/store/selectors';
import CloseoutContribForm from 'components/admin/projects/details/project-closeout/CloseoutContribForm';
import { compareCompanyTypeHierarchy } from 'services/utils/closeout-table-util';

class CloseoutContribForms extends Component {
  render() {
    const { closeoutContribs } = this.props;
    return (
      <div className="closeout-contrib-forms">
        {closeoutContribs
          .filter((contrib) =>
            contrib.closeout_contributor_cats.some(
              (contribCat) => !!contribCat.applies
            )
          )
          .sort((contrib1, contrib2) =>
            compareCompanyTypeHierarchy(
              contrib1.company_type,
              contrib2.company_type
            )
          )
          .map((closeoutContrib) => (
            <React.Fragment key={closeoutContrib.id}>
              <CloseoutContribForm closeoutContrib={closeoutContrib} />
            </React.Fragment>
          ))}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    closeoutContribs: getCloseoutContribs(state),
  };
})(CloseoutContribForms);
