export const COMPANY_TYPES = {
  ARCHITECT: 'Architect',
  CONSTRUCTION_PROJECT_MANAGER: 'ConstructionProjectManager',
  CONSULTANT: 'Consultant',
  LANDLORD: 'Landlord',
  PROPERTY_MANAGER: 'PropertyManager',
  CONTRACTOR: 'Contractor',
  CLIENT: 'Client',
  GENERAL_CONTRACTOR: 'GeneralContractor',
  OWNER_AUTHORIZED_REP: 'OwnerAuthorizedRep',
};

export const COMPANY_TYPES_SORTED = [
  COMPANY_TYPES.CLIENT,
  COMPANY_TYPES.OWNER_AUTHORIZED_REP,
  COMPANY_TYPES.ARCHITECT,
  COMPANY_TYPES.CONSULTANT,
  COMPANY_TYPES.CONSTRUCTION_PROJECT_MANAGER,
  COMPANY_TYPES.GENERAL_CONTRACTOR,
  COMPANY_TYPES.CONTRACTOR,
  COMPANY_TYPES.LANDLORD,
  COMPANY_TYPES.PROPERTY_MANAGER,
];

export const COMPANY_UNDERSCORE_TYPES = {
  [COMPANY_TYPES.CLIENT]: 'client',
  [COMPANY_TYPES.CONTRACTOR]: 'contractor',
  [COMPANY_TYPES.GENERAL_CONTRACTOR]: 'general_contractor',
  [COMPANY_TYPES.OWNER_AUTHORIZED_REP]: 'owner_authorized_rep',
  [COMPANY_TYPES.ARCHITECT]: 'architect',
  [COMPANY_TYPES.LANDLORD]: 'landlord',
  [COMPANY_TYPES.PROPERTY_MANAGER]: 'property_manager',
  [COMPANY_TYPES.CONSULTANT]: 'consultant',
  [COMPANY_TYPES.CONSTRUCTION_PROJECT_MANAGER]: 'construction_project_manager',
};

export const COMPANY_TYPE_LABELS = {
  [COMPANY_TYPES.ARCHITECT]: 'Architect',
  [COMPANY_TYPES.CONSTRUCTION_PROJECT_MANAGER]: 'Construction Project Manager',
  [COMPANY_TYPES.CONSULTANT]: 'Consultant',
  [COMPANY_TYPES.LANDLORD]: 'Landlord',
  [COMPANY_TYPES.PROPERTY_MANAGER]: 'Property Manager',
  [COMPANY_TYPES.CONTRACTOR]: 'Subcontractor',
  [COMPANY_TYPES.CLIENT]: 'Owner',
  [COMPANY_TYPES.GENERAL_CONTRACTOR]: 'General Contractor',
  [COMPANY_TYPES.OWNER_AUTHORIZED_REP]: 'OAR',
};

export const COMPANY_TYPE_LABELS_PLURAL = {
  [COMPANY_TYPES.ARCHITECT]: 'Architects',
  [COMPANY_TYPES.CONSTRUCTION_PROJECT_MANAGER]: 'Construction Project Managers',
  [COMPANY_TYPES.CONSULTANT]: 'Consultants',
  [COMPANY_TYPES.LANDLORD]: 'Landlords',
  [COMPANY_TYPES.PROPERTY_MANAGER]: 'Property Managers',
  [COMPANY_TYPES.CONTRACTOR]: 'Subcontractors',
  [COMPANY_TYPES.CLIENT]: 'Owners',
  [COMPANY_TYPES.GENERAL_CONTRACTOR]: 'General Contractors',
  [COMPANY_TYPES.OWNER_AUTHORIZED_REP]: 'OARs',
};

export const companyTypeOptions = COMPANY_TYPES_SORTED.map((type) => ({
  value: type,
  label: COMPANY_TYPE_LABELS[type],
}));
