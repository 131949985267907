import User from 'domain/user';

class GlobalPermissions {
  canViewDashboardPage = (user) => User.isEnabled(user);

  canViewProjectsPage = (user) => User.isEnabled(user);

  canViewDirectoryPage = (user) => User.isEnabled(user);

  canViewCompaniesPage = (user) => User.isAdminOrSubscriber(user);

  canViewUsersPage = (user) => User.isAdmin(user);

  canViewProjectLocationsPage = (user) => User.isAdminOrSubscriber(user);

  canViewTeamPage = (user) => User.isCompany(user);
}

export default new GlobalPermissions();
