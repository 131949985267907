import React from 'react';
import { Modal } from 'react-bootstrap';
import Budget from './Budget';
import './BudgetModal.css';

const BudgetModal = (props) => {
  const { show, onHide } = props;
  return (
    <Modal className="budget-modal" show={show} onHide={onHide}>
      <Modal.Header closeButton />
      <Modal.Body>
        <Budget expandable={false} scrollable />
      </Modal.Body>
    </Modal>
  );
};

export default BudgetModal;
