import {
  WORK_ORDERS_CREATION_TASKS_TASK_ADD_EMPTY_NEW,
  WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_NEW,
  WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_SUCCESS,
  WORK_ORDERS_CREATION_TASKS_TASK_CHANGE_VALUE,
  WORK_ORDERS_CREATION_TASKS_TASK_EDIT,
  WORK_ORDERS_CREATION_TASKS_TASK_SAVE_SUCCESS,
  WORK_ORDERS_CREATION_TASKS_STATE_INIT,
  WORK_ORDERS_TASK_UPDATE_SUCCESS,
  WORK_ORDERS_CREATION_TASKS_TASK_IMAGE_REMOVE_SUCCESS,
} from '../../../../actions/admin/work-orders/action-types';
import { buildTaskName } from 'services/utils/text-util';

const initialState = {
  currentTask: createEmptyTask([]),
  editTaskId: 0, // If 0, a new task is being edited, if -1 no task is under edition, otherwise contains a task id under edition
  companyName: '',
};

export function createEmptyTask(tasks) {
  let number = 1;
  if (tasks !== undefined && tasks.length > 0) {
    number = tasks[tasks.length - 1].number + 1;
  }
  return {
    id: 0,
    number: number,
    completionDate: null,
    trade: 0,
    subTrade: 0,
    tradeLabel: '',
    floorNumber: '',
    room: '',
    name: '',
    description: '',
    images: [],
  };
}

export default function tasksDataReducer(state = initialState, action) {
  let newTask;

  switch (action.type) {
    case WORK_ORDERS_CREATION_TASKS_STATE_INIT:
      return {
        ...state,
        currentTask: action.payload.currentTask,
        editTaskId: action.payload.editTaskId,
        companyName: action.payload.companyName,
      };

    case WORK_ORDERS_CREATION_TASKS_TASK_CHANGE_VALUE:
      newTask = {
        ...state.currentTask,
      };

      if (action.payload.attribute === 'images') {
        newTask['images'] = newTask['images'].concat(action.payload.value);
      } else {
        newTask[action.payload.attribute] = action.payload.value;
      }

      // If trades are updated, removeUser value for subTrade
      if (action.payload.attribute === 'trade') {
        newTask['tradeLabel'] = action.payload.label;
        newTask['subTrade'] = 0;
      }

      if (
        action.payload.attribute === 'trade' &&
        newTask['tradeLabel'] !== ''
      ) {
        newTask['name'] = buildTaskName(newTask['tradeLabel']);
      }

      return {
        ...state,
        currentTask: newTask,
      };

    case WORK_ORDERS_CREATION_TASKS_TASK_SAVE_SUCCESS:
    case WORK_ORDERS_TASK_UPDATE_SUCCESS:
      return {
        ...state,
        editTaskId: -1,
      };

    case WORK_ORDERS_CREATION_TASKS_TASK_ADD_EMPTY_NEW:
      return {
        ...state,
        currentTask: createEmptyTask(action.payload),
        editTaskId: 0,
      };

    case WORK_ORDERS_CREATION_TASKS_TASK_EDIT:
      return {
        ...state,
        currentTask: action.payload.task,
        editTaskId: action.payload.taskId,
      };

    case WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_NEW:
    case WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_SUCCESS:
      return {
        ...state,
        editTaskId: -1,
      };

    case WORK_ORDERS_CREATION_TASKS_TASK_IMAGE_REMOVE_SUCCESS:
      return {
        ...state,
        currentTask: {
          ...state.currentTask,
          images: state.currentTask.images.filter((element, index) => {
            return index !== action.payload;
          }),
        },
      };

    default:
      return state;
  }
}
