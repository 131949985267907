import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import FormModal from 'components/shared/modal/FormModal';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import {
  getShowEmailModal,
  getShowCustomEmailError,
  getShowNewCustomEmail,
  getEmails,
  getSubject,
  getMessage,
  getRecipients,
  getCustomEmail,
  getHistory,
} from 'components/admin/projects/details/project-email-modal/store/selectors';
import {
  initProjectEmailModal,
  setShowEmailModal,
  setShowCustomEmailError,
  setShowNewCustomEmail,
  addEmail,
  addRecipient,
  setSubject,
  setMessage,
  setEmails,
  setRecipients,
  setCustomEmail,
  loadEmailHistory,
} from 'components/admin/projects/details/project-email-modal/store/actions';
import EmailHistoryModal from 'components/admin/projects/details/project-email-modal/EmailHistoryModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import Input from 'components/shared/input/Input';
import TextareaAutosize from 'react-autosize-textarea';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import { validateEmail } from 'services/utils/text-util';
import classnames from 'classnames';
import { getDarkMode } from 'selectors/theme';
import './ProjectEmailModal.css';

class ProjectEmailModal extends Component {
  state = {
    showHistoryModal: false,
  };
  componentDidMount() {
    if (
      !this.props.directory ||
      this.props.directory.project_id !== this.props.projectId
    ) {
      this.props
        .dispatch(loadDirectoryRequest(this.props.projectId))
        .then((response) => {
          if (response.ok) {
            this.props.dispatch(setEmails(this.recipientOptions()));
          }
        });
    } else {
      this.props.dispatch(setEmails(this.recipientOptions()));
    }
    const initPayload = {
      subject: this.props.initialSubject,
      message: this.props.initialMessage,
      initialMessage: this.props.initialMessage,
    };
    this.props.dispatch(initProjectEmailModal(initPayload));
  }

  handleClose = (event) => {
    event.preventDefault();
    this.props.dispatch(setShowEmailModal(false));
  };

  handleCustomEmailAdd = () => {
    if (validateEmail(this.props.customEmail)) {
      this.props.dispatch(setShowCustomEmailError(false));
      this.props.dispatch(setShowNewCustomEmail(false));
      const emailId =
        Math.max(...this.props.emails.map((email) => email.id)) + 1;
      this.props.dispatch(
        addEmail({ id: emailId, label: this.props.customEmail })
      );
      this.props.dispatch(addRecipient([emailId, this.props.customEmail]));
      this.props.dispatch(setCustomEmail(''));
    } else {
      this.props.dispatch(setShowCustomEmailError(true));
    }
  };

  handleShowCustomEmailAdd = () => {
    this.props.dispatch(setShowNewCustomEmail(true));
  };

  handleShowCustomEmailHide = () => {
    this.props.dispatch(setShowNewCustomEmail(false));
  };

  handleSubjectChange = (subject) => {
    this.props.dispatch(setSubject(subject));
  };

  handleMessageChange = (message) => {
    this.props.dispatch(setMessage(message));
  };

  handleRecipientsChange = (recipients) => {
    this.props.dispatch(setRecipients(recipients));
  };

  handleCustomEmailChange = (customEmail) => {
    this.props.dispatch(setCustomEmail(customEmail));
  };

  handleHistoryClick = () => {
    const { itemId, itemType, dispatch } = this.props;
    dispatch(loadEmailHistory(itemId, itemType));
    this.setState({ showHistoryModal: true });
  };

  isSendButtonEnabled = () => {
    return (
      this.props.recipients.length > 0 &&
      this.props.subject !== '' &&
      this.props.message !== ''
    );
  };

  uniqueEmails(emails) {
    let uniques = [];
    emails.forEach((email) => {
      if (!uniques.find((unique) => unique.id === email.id)) {
        uniques.push(email);
      }
    });
    return uniques;
  }

  recipientOptions = () => {
    return this.uniqueEmails(
      this.props.directory.project_contributors
        .map((pc) => {
          return pc.users.map((user) => ({ id: user.id, label: user.email }));
        })
        .flat(1)
    );
  };

  render() {
    const {
      subTitle,
      title,
      showEmailModal,
      showCustomEmailError,
      showNewCustomEmail,
      recipients,
      emails,
      customEmail,
      message,
      initialMessage,
      subject,
      showHistoryButton,
      history,
      darkMode,
    } = this.props;

    return (
      <FormModal
        className="project-email-modal"
        show={showEmailModal}
        subTitle={title || 'Send Email'}
        onClose={this.handleClose}
      >
        <div className="project-email-container">
          <Form horizontal>
            <div className="form-block">
              <label className="form-block-label">To</label>
              <MultipleDropdown
                className="to-emails-dropdown"
                dropdownClassName="alternate-dropdown-control"
                options={emails}
                currentValues={recipients}
                placeholder="Select emails..."
                onChange={(values) => this.handleRecipientsChange(values)}
              />
            </div>
            {!showNewCustomEmail ? (
              <div className="form-block">
                <Button
                  type={BUTTON_TYPES.LINK}
                  icon={BUTTON_ICONS.PLUS}
                  label="Custom Email"
                  onClick={this.handleShowCustomEmailAdd}
                />
              </div>
            ) : (
              <React.Fragment>
                <div className="form-block">
                  <label className="form-block-label">Custom Email</label>
                  <Input
                    placeholder="Custom email"
                    valid={!showCustomEmailError}
                    value={customEmail}
                    onChange={(value) => this.handleCustomEmailChange(value)}
                  />
                </div>
                <div className="form-block">
                  <Button
                    color={BUTTON_COLORS.WHITE}
                    label="Cancel"
                    onClick={this.handleShowCustomEmailHide}
                  />
                  <Button
                    color={BUTTON_COLORS.GREEN}
                    label="Add"
                    onClick={this.handleCustomEmailAdd}
                  />
                </div>
                {showCustomEmailError && (
                  <div className="invalid-message">
                    Please verify email address.
                  </div>
                )}
              </React.Fragment>
            )}
            <div className="form-block">
              <label className="form-block-label">Subject</label>
              <Input
                placeholder="Subject"
                value={subject}
                onChange={(value) => this.handleSubjectChange(value)}
              />
            </div>
            <div className="form-block">
              <label className="form-block-label">Message</label>
              <TextareaAutosize
                rows={4}
                maxRows={8}
                className={classnames({
                  'text-area': true,
                  'input-area': true,
                  'form-control': true,
                  'dark-mode': darkMode,
                })}
                placeholder="Message"
                onBlur={(e) => this.handleMessageChange(e.target.value)}
                defaultValue={message}
              />
            </div>
            <div className="buttons-container">
              {showHistoryButton && (
                <Button
                  type={BUTTON_TYPES.CONFIRMATION}
                  color={BUTTON_COLORS.WHITE}
                  label="History"
                  onClick={this.handleHistoryClick}
                />
              )}
              <Button
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.GREEN}
                label="Send"
                disabled={!this.isSendButtonEnabled()}
                onClick={() =>
                  this.props.handleSendEmail({
                    recipients: recipients.map((recipient) => recipient[1]),
                    subject,
                    message,
                    initialMessage,
                  })
                }
              />
            </div>
            {this.state.showHistoryModal && (
              <EmailHistoryModal
                show={this.state.showHistoryModal}
                subTitle={subTitle}
                history={history}
                onClose={() => {
                  this.setState({ showHistoryModal: false });
                }}
              />
            )}
          </Form>
        </div>
      </FormModal>
    );
  }
}

export default connect((state) => {
  return {
    directory: getDirectory(state),
    showEmailModal: getShowEmailModal(state),
    showCustomEmailError: getShowCustomEmailError(state),
    showNewCustomEmail: getShowNewCustomEmail(state),
    emails: getEmails(state),
    subject: getSubject(state),
    message: getMessage(state),
    recipients: getRecipients(state),
    customEmail: getCustomEmail(state),
    history: getHistory(state),
    darkMode: getDarkMode(state),
  };
})(ProjectEmailModal);
