import { createSelector } from 'reselect';

export const getCurrentUser = createSelector(
  (state) => state.auth.user,
  (permissions) => {
    return permissions;
  }
);

export const isLoggedIn = createSelector(
  (state) => state.auth.loggedIn,
  (loggedIn) => {
    return loggedIn;
  }
);

export const getAccessToken = createSelector(
  (state) => state.auth.accessToken,
  (accessToken) => {
    return accessToken;
  }
);

export const getClient = createSelector(
  (state) => state.auth.client,
  (client) => {
    return client;
  }
);

export const getAccounts = createSelector(
  (state) => state.auth.accounts,
  (accounts) => {
    return accounts;
  }
);
