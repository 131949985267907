import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './SimilarCompaniesModal.css';
import FormModal from 'components/shared/modal/FormModal';
import Selector from 'components/shared/selector/Selector';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import companiesUtil from 'services/utils/companies-util';

function SimilarCompaniesModal(props) {
  const { companies, onSubmit, onClose } = props;

  const [companyId, setCompanyId] = useState('new');

  const companyOption = (field) => {
    const { company, disclaimer } = field;

    return {
      value: company.id,
      label: (
        <span>
          {companiesUtil.getCompanyName(company)}
          {!!company.location &&
            ` - ${company.location.city}, ${company.location.state}`}
          {disclaimer && <b>{` (${disclaimer})`}</b>}
        </span>
      ),
    };
  };

  const companyOptions = () => companies.map(companyOption);

  return (
    <FormModal
      className="similar-companies-modal"
      show={true}
      onClose={onClose}
      subTitle="Duplicate Company"
    >
      <React.Fragment>
        <p className="small-regular initial-paragraph">
          Your company may already be registered with 360+
        </p>
        <p className="small-regular yes-paragraph">
          If <b>yes</b>, select from the list and choose to Link Company
        </p>
        <p className="small-regular no-paragraph">
          If <b>no</b>, select Add New Company
        </p>
        <div className="form-block">
          <FormControlBlock
            label={companyId !== 'new' && 'Select Company'}
            control={
              <Selector
                options={[
                  { value: 'new', label: 'Add New Company' },
                  ...companyOptions(),
                ]}
                value={companyId}
                onChange={(option) => setCompanyId(option.value)}
              />
            }
          />
        </div>
        <div className="buttons-container">
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label="Continue"
            onClick={() => onSubmit(companyId !== 'new' ? companyId : null)}
          />
        </div>
      </React.Fragment>
    </FormModal>
  );
}

SimilarCompaniesModal.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SimilarCompaniesModal;
