import { createSelector } from 'reselect';

export const getActiveRoute = createSelector(
  (state) => state.sidebar.activeRoute,
  (activeRoute) => {
    return activeRoute;
  }
);

export const getHideSidebar = createSelector(
  (state) => state.sidebar.hideSideBar,
  (hideSideBar) => {
    return hideSideBar;
  }
);

export const getShowNewServiceWorkModal = createSelector(
  (state) => state.sidebar.showNewServiceWorkModal,
  (showNewServiceWorkModal) => {
    return !!showNewServiceWorkModal;
  }
);
