import { showErrorMessage } from 'actions/messages';
import {
  SET_PROJECT_REPORT_METRICS,
  SET_PROJECT_REPORTS,
  SET_PHOTOS,
  SET_SHARED_ALBUMS,
  SET_SHARED_FOLDERS,
  SET_LOADED,
} from './action-types';
import { projectReportsApi } from 'services/api/project-reports-api';
import { albumsApi } from 'services/api/albums-api';
import { projectDocumentationsApi } from 'services/api/project-documentations-api';
import { projectPhotosApi } from 'services/api/project-photos-api';

export function setProjectReportMetrics(metrics = null) {
  return {
    type: SET_PROJECT_REPORT_METRICS,
    payload: metrics,
  };
}

export function loadProjectReportMetrics(projectId) {
  return (dispatch) => {
    return projectReportsApi.getDashboardMetrics(projectId).then((response) => {
      if (response.ok) {
        dispatch(setProjectReportMetrics(response.metrics));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function setProjectReports(reports = null) {
  return {
    type: SET_PROJECT_REPORTS,
    payload: reports,
  };
}

export function loadProjectReports(projectId, number) {
  return (dispatch) => {
    return projectReportsApi
      .getProjectReports(
        projectId,
        `?resolved=true&date_desc=true&per_page=${number}`
      )
      .then((response) => {
        if (response.ok) {
          dispatch(setProjectReports(response.projectReports));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setPhotos(photos = null) {
  return {
    type: SET_PHOTOS,
    payload: photos,
  };
}

export function loadPhotos(projectId, perPage) {
  return (dispatch) => {
    return projectPhotosApi
      .getAll(projectId, 'photo', { perPage, type: 'image' })
      .then((response) => {
        if (response.ok) dispatch(setPhotos(response.photos));
        else dispatch(showErrorMessage(response.errors));

        return response;
      });
  };
}

export function setSharedAlbums(albums = null) {
  return {
    type: SET_SHARED_ALBUMS,
    payload: albums,
  };
}

export function loadSharedAlbums(projectId) {
  return (dispatch) => {
    return albumsApi.getSharedAlbums(projectId).then((response) => {
      if (response.ok) {
        dispatch(setSharedAlbums(response.albums));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function setSharedFolders(folders = null) {
  return {
    type: SET_SHARED_FOLDERS,
    payload: folders,
  };
}

export function loadSharedFolders(projectId) {
  return (dispatch) => {
    return projectDocumentationsApi
      .getAdvancedDocuments(projectId)
      .then((response) => {
        if (response.ok) {
          dispatch(setSharedFolders(response.advancedDocuments));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setLoaded(loaded = false) {
  return {
    type: SET_LOADED,
    payload: loaded,
  };
}

export function loadData(
  projectId,
  canShowReports,
  hasAdvancedDocuments,
  reportsCount,
  photosCount
) {
  return (dispatch) => {
    Promise.all(
      [
        canShowReports && dispatch(loadProjectReportMetrics(projectId)),
        canShowReports && dispatch(loadProjectReports(projectId, reportsCount)),
        dispatch(loadPhotos(projectId, photosCount)),
        //dispatch(loadSharedAlbums(projectId)),
        hasAdvancedDocuments && dispatch(loadSharedFolders(projectId)),
      ].filter((task) => !!task)
    ).then(() => {
      dispatch(setLoaded(true));
    });
  };
}

export function clearData() {
  return (dispatch) => {
    dispatch(setProjectReportMetrics());
    dispatch(setProjectReports());
    dispatch(setPhotos());
    dispatch(setSharedAlbums());
    dispatch(setSharedFolders());
    dispatch(setLoaded());
  };
}
