import React from 'react';

const ThumbsUpIcon = ({ fill = '#ffffff', width = 34, height = 32 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 34 32"
      fill="none"
    >
      <path
        d="M30.3335 10.334H20.9802L22.8518 4.72232C23.1885 3.70898 23.0185 2.58565 22.3935 1.71898C21.7685 0.852318 20.7552 0.333984 19.6868 0.333984H17.0002C16.5052 0.333984 16.0368 0.553984 15.7185 0.933984L7.88516 10.334H3.66683C1.8285 10.334 0.333496 11.829 0.333496 13.6673V28.6673C0.333496 30.5056 1.8285 32.0007 3.66683 32.0007H25.8452C26.5233 31.9984 27.1848 31.7904 27.7422 31.4041C28.2996 31.0178 28.7266 30.4715 28.9668 29.8373L33.5618 17.5856C33.6316 17.3985 33.6672 17.2004 33.6668 17.0007V13.6673C33.6668 11.829 32.1718 10.334 30.3335 10.334ZM3.66683 13.6673H7.00016V28.6673H3.66683V13.6673ZM30.3335 16.699L25.8452 28.6673H10.3335V12.604L17.7802 3.66732H19.6902L17.0868 11.4723C17.0023 11.7228 16.9787 11.9899 17.0181 12.2514C17.0574 12.5128 17.1585 12.7611 17.3129 12.9757C17.4674 13.1903 17.6708 13.365 17.9062 13.4853C18.1417 13.6055 18.4024 13.6679 18.6668 13.6673H30.3335V16.699Z"
        fill={fill}
      />
    </svg>
  );
};

export default ThumbsUpIcon;
