import loaderReducer from './loader';
import authenticationReducer from './authentication';
import workOrders from './admin/work-orders/work-orders';
import messagesReducer from './messages';
import themeReducer from './theme';
import { createSceneReducer } from './reducers-util';
import userInvitationsDataReducer from './client/user-invitations.data';
import contractors from './admin/contractors/contractors';
import buildings from './admin/buildings/buildings';
import projectLocations from './admin/project-locations/project-locations';
import clients from './admin/clients/clients';
import quotes from './admin/quotes/quotes';
import tasks from './admin/tasks/tasks';
import invoices from './admin/invoices/invoices';
import deposits from './admin/deposits/deposits';
import sidebarReducer from './admin/sidebar/sidebar';
import team from './admin/team/team';
import users from './admin/users/users';
import tutorials from 'components/admin/tutorials/store/reducers';
import notifications from './notifications';
import contractorsInvoices from 'components/admin/contractors-invoices/store/reducers';
import reports from './admin/reports/reports';
import setPasswordUiReducer from 'reducers/client/set-password.ui';
import registerUserUiReducer from 'reducers/client/register-user.ui';
import { reducer as formReducer } from 'redux-form';
import purchaseOrders from 'components/admin/purchase-orders/store/reducers';
import surveys from 'components/admin/surveys/store/reducers';
import projects from 'components/admin/projects/store/reducers';
import paginationTableReducer from 'components/shared/pagination/table/store/reducers';
import generalContractors from 'components/admin/general-contractors/store/reducers';
import ownerAuthorizedReps from 'components/admin/owner-authorized-reps/store/reducers';
import serviceProviders from 'components/admin/service-providers/store/reducers';
import companies from 'components/admin/companies/store/reducers';
import pdfViewerBluebeamReducer from './pdfViewerBluebeam';
import rfis from 'components/admin/rfis/store/reducers';
import submittals from 'components/admin/submittals/store/reducers';
import projectOpenItems from 'components/admin/project-open-items/store/reducers';
import chats from 'components/admin/chats/store/reducers';
import fieldWorkOrders from 'components/admin/field-work-orders/store/reducers';
import recentActivity from 'components/admin/recent-activity/store/reducers';
import featuredItems from 'components/admin/featured-items/store/reducers';
import superadmin from 'components/admin/superadmin/store/reducers';
import registration from 'components/admin/registration/store/reducers';
import loginBackgroundImageReducer from './loginBackgroundImage';

export function getReducers() {
  return {
    auth: authenticationReducer,
    loader: loaderReducer,
    messages: messagesReducer,
    theme: themeReducer,
    sidebar: sidebarReducer,
    loginBackgroundImage: loginBackgroundImageReducer,
    userInvitations: createSceneReducer(userInvitationsDataReducer),
    workOrders,
    contractors,
    buildings,
    projectLocations,
    clients,
    quotes,
    tasks,
    invoices,
    deposits,
    team,
    users,
    tutorials,
    notifications,
    contractorsInvoices,
    reports,
    purchaseOrders,
    setPassword: createSceneReducer(null, setPasswordUiReducer),
    registerUser: createSceneReducer(null, registerUserUiReducer),
    form: formReducer,
    surveys,
    projects,
    paginationTable: paginationTableReducer,
    generalContractors,
    ownerAuthorizedReps,
    serviceProviders: serviceProviders,
    companies,
    pdfViewer: pdfViewerBluebeamReducer,
    rfis,
    submittals,
    projectOpenItems,
    chats,
    fieldWorkOrders,
    recentActivity,
    featuredItems,
    superadmin,
    registration,
  };
}
