import { DEFAULT_NOTIFICATION_SETTINGS_FORM } from 'domain/notification-settings';
import {
  RESET_FORM_NOTIFICATION_SETTINGS,
  SET_FORM_NOTIFICATION_SETTINGS,
  SET_SUBJECTS_NOTIFICATION_SETTINGS,
} from './action-types';

const initialState = {
  subjects: [],
  form: DEFAULT_NOTIFICATION_SETTINGS_FORM,
};

export default function notificationSettingsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case RESET_FORM_NOTIFICATION_SETTINGS: {
      return { ...state, form: DEFAULT_NOTIFICATION_SETTINGS_FORM };
    }
    case SET_FORM_NOTIFICATION_SETTINGS: {
      return { ...state, form: { ...state.form, ...action.payload.form } };
    }
    case SET_SUBJECTS_NOTIFICATION_SETTINGS: {
      return { ...state, subjects: action.payload.subjects };
    }
    default:
      return state;
  }
}
