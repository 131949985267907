import {
  PROJECT_DETAILS_INIT,
  PROJECT_DETAILS_CURRENT_PROJECT_SET,
} from 'components/admin/projects/details/store/action-types';

const initialState = {
  currentProject: null,
};

export default function projectDetailsDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case PROJECT_DETAILS_INIT:
      return initialState;

    case PROJECT_DETAILS_CURRENT_PROJECT_SET:
      return {
        ...state,
        currentProject: action.payload,
      };

    default:
      return state;
  }
}
