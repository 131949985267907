import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import { setProjectReportAttr } from 'components/admin/projects/details/project-daily-report-log/store/actions';
import { getProjectReportValue } from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import { getDarkMode } from 'selectors/theme';
import './ReportsForm.css';

class DisclaimerForm extends Component {
  handleAttributeChange = (attr, value) => {
    this.props.dispatch(setProjectReportAttr(attr, value));
  };

  render() {
    const { report } = this.props;
    return (
      <div>
        <div className="report-form-block">
          <TextareaAutosizeWrapper
            placeholder="Remarks"
            initialValue={report.disclaimer_remarks || ''}
            onChange={(value) =>
              this.handleAttributeChange('disclaimer_remarks', value)
            }
            maxRows={50}
          />
        </div>
        <div className="separate-block-in-row" />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    darkMode: getDarkMode(state),
    report: getProjectReportValue(state),
  }),
  null
)(DisclaimerForm);
