import {
  DIRECTORY_SET,
  DIRECTORY_PDF_SET,
  NEW_CONTRIBUTOR_MODAL_INIT,
  COMPANY_USERS_SET,
  PROJECT_CONTRIBUTORS_UPDATE,
  PROJECT_CONTRIBUTORS_UPDATE_WITH_EDIT_USER,
  PROJECT_CONTRIBUTORS_DELETE,
  SET_ROLES,
} from 'components/admin/projects/details/project-directory/store/action-types';

const initialState = {
  directory: null,
  directoryPdf: null,
  companyUsers: [],
  roles: null,
};

export default function projectDirectoryDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case DIRECTORY_SET:
      return {
        ...state,
        directory: action.payload,
      };

    case DIRECTORY_PDF_SET:
      return {
        ...state,
        directoryPdf: action.payload,
      };

    case NEW_CONTRIBUTOR_MODAL_INIT:
      return {
        ...state,
        companyUsers: initialState.companyUsers,
      };

    case COMPANY_USERS_SET:
      return {
        ...state,
        companyUsers: action.payload,
      };

    case PROJECT_CONTRIBUTORS_UPDATE_WITH_EDIT_USER:
      return {
        ...state,
        directory: {
          ...state.directory,
          project_contributors: state.directory.project_contributors.map(
            (contrib) => {
              return {
                ...contrib,
                users: contrib.users.map((user) => {
                  if (user.id === action.payload.id) {
                    return action.payload;
                  } else {
                    return user;
                  }
                }),
              };
            }
          ),
        },
      };

    case PROJECT_CONTRIBUTORS_UPDATE:
      return {
        ...state,
        directory: {
          ...state.directory,
          project_contributors: state.directory.project_contributors.map(
            (contrib) => {
              if (contrib.id === action.payload.id) {
                return action.payload;
              } else {
                return contrib;
              }
            }
          ),
        },
      };

    case PROJECT_CONTRIBUTORS_DELETE:
      return {
        ...state,
        directory: {
          ...state.directory,
          project_contributors: state.directory.project_contributors.filter(
            (contrib) => {
              return contrib.id !== action.payload;
            }
          ),
        },
      };

    case SET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };

    default:
      return state;
  }
}
