import axios from 'axios';
import BaseApi from './base-api';

class FilesApi extends BaseApi {
  create(
    model,
    subModel,
    identifier,
    name,
    label = undefined,
    description = undefined,
    position = undefined
  ) {
    const payload = new global.FormData();
    payload.append(`${subModel}[model]`, model);
    payload.append(`${subModel}[identifier]`, identifier);
    payload.append(`${subModel}[name]`, name);
    if (label) payload.append(`${subModel}[label]`, label);
    if (description) payload.append(`${subModel}[description]`, description);
    if (position) payload.append(`${subModel}[position]`, position);

    return axios
      .post(this.baseUrl + `/${subModel}s/direct_upload`, payload, {
        loadingDisabled: true,
      })
      .then((data) => ({ ok: true, data }))
      .catch((error) => ({ ok: false, msg: error.message }));
  }
}

export const filesApi = new FilesApi();
