import { useState } from 'react';
import FieldReport, {
  FIELD_REPORT_FORM_INIT,
  SCHEDULE_DELAY_STATUS,
  NO_COMPLIENCE,
  SECTION_INFORMATION,
  SECTION_STATUS,
} from 'domain/field-report';
import {
  fieldReportToForm,
  saveFieldReport,
  setFieldReportDraftModal,
  setFieldReportHtml,
} from './store/actions';

const useFieldReportForm = (props) => {
  const { dispatch, onClose, currentProject, currentFieldReport } = props;

  const initForm = () => {
    const res = { ...FIELD_REPORT_FORM_INIT, ...currentFieldReport };
    if (
      (!res.latitude || !res.longitude) &&
      currentProject.project_location &&
      currentProject.project_location.location.latitude &&
      currentProject.project_location.location.longitude
    ) {
      res.latitude = currentProject.project_location.location.latitude;
      res.longitude = currentProject.project_location.location.longitude;
    }
    return res;
  };

  const [form, setForm] = useState(initForm());
  const [formSection, setFormSection] = useState('information');

  const handleFormChange = (values) => {
    const newForm = { ...form, ...values };
    setForm(newForm);
  };

  const validateForm = () => {
    const errors = {};
    const regExp = /[a-zA-Z]/g;

    if (!form.date) errors.date = true;
    if (
      form.observation_schedule_status &&
      form.observation_schedule_status === SCHEDULE_DELAY_STATUS &&
      (!form.observation_schedule_remarks ||
        !regExp.test(form.observation_schedule_remarks))
    )
      errors.observation_schedule_remarks = true;

    ['design', 'quality', 'safety'].forEach((area) => {
      const key = `observation_${area}_complience`;
      const remark_key = `observation_${area}_remarks`;
      if (
        form[key] &&
        form[key] === NO_COMPLIENCE &&
        (!form[remark_key] || !regExp.test(form[remark_key]))
      )
        errors[remark_key] = true;
    });

    return errors;
  };

  const handleSave = (draft, preview = false) => {
    const values = { ...form, draft };

    delete values.errors;

    values.open_items = values.open_items.map((item) => item.id);

    if (values.location) {
      values.latitude = values.location.latitude;
      values.longitude = values.location.longitude;
      delete values.location;
    }

    if (values.recipients) {
      values.user_ids = values.recipients.map((item) => item[0]);
      delete values.recipients;
    }

    return dispatch(saveFieldReport(currentProject.id, values, preview));
  };

  const handleSaveDraft = () => {
    handleSave(true).then((response) => {
      if (response.ok) onClose();
    });
  };

  const handlePublish = () => {
    const errors = validateForm();
    handleFormChange({ errors });

    if (Object.keys(errors).length > 0) {
      if (FieldReport.hasErrorInformaitonSection(errors)) {
        setFormSection(SECTION_INFORMATION);
      } else if (FieldReport.hasErrorProjectStatusSection(errors)) {
        setFormSection(SECTION_STATUS);
      }
    } else {
      handleSave(false).then((response) => {
        if (response.ok) onClose();
      });
    }
  };

  const handleView = () =>
    handleSave(true, true).then((response) => {
      if (response.ok) {
        handleFormChange(fieldReportToForm(response.report));
      }
    });
  const handleHtmlView = () => {
    dispatch(setFieldReportHtml(form));
  };
  const handleCancel = () => dispatch(setFieldReportDraftModal(true));
  const handleCloseSaveDraftModal = () => onClose();
  const handleSubmitSaveDraftModal = () => handleSaveDraft();

  return {
    form,
    formSection,
    setFormSection,
    handleFormChange,
    handleView,
    handleSaveDraft,
    handlePublish,
    handleCancel,
    handleCloseSaveDraftModal,
    handleSubmitSaveDraftModal,
    handleHtmlView,
  };
};

export default useFieldReportForm;
