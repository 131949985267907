import {
  CLIENTS_CLIENT_BUILDINGS_LOAD_SUCCESS,
  CLIENTS_CLIENT_INVOICES_LOAD_SUCCESS,
  CLIENTS_CLIENT_LOAD_SUCCESS,
  CLIENTS_CLIENT_PURCHASE_ORDERS_SUCCESS,
  CLIENTS_CLIENT_ROLES_LOAD_SUCCESS,
  CLIENTS_CLIENT_SET,
  CLIENTS_CLIENT_SURVEYS_LOAD_SUCCESS,
  CLIENTS_CLIENT_USERS_ADD,
  CLIENTS_CLIENT_WORK_ORDERS_LOAD_SUCCESS,
  CLIENTS_INVITE_CLIENT_ADD,
  CLIENTS_LOAD_SUCCESS,
  CLIENTS_USERS_SET,
  CLIENTS_ADD_TO_BEGINNING,
  CLIENTS_CLIENT_PROJECTS_LOAD_SUCCESS,
} from 'actions/admin/clients/action-types';
import { sortByAttribute } from 'services/utils/sort-util';

const initialState = {
  buildings: [],
  clients: [],
  currentClient: null,
  invoices: null,
  purchaseOrders: null,
  roles: [],
  surveys: null,
  users: [],
  workOrders: null,
  projects: null,
};

export default function clientsDataReducer(state = initialState, action) {
  switch (action.type) {
    case CLIENTS_CLIENT_BUILDINGS_LOAD_SUCCESS:
      return {
        ...state,
        buildings: action.payload,
      };

    case CLIENTS_CLIENT_INVOICES_LOAD_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
      };

    case CLIENTS_CLIENT_LOAD_SUCCESS:
      return {
        ...state,
        buildings: initialState.buildings,
        currentClient: action.payload,
        users: initialState.users,
      };

    case CLIENTS_CLIENT_PURCHASE_ORDERS_SUCCESS:
      return {
        ...state,
        purchaseOrders: action.payload,
      };

    case CLIENTS_CLIENT_ROLES_LOAD_SUCCESS:
      return {
        ...state,
        roles: action.payload,
      };

    case CLIENTS_CLIENT_SET:
      return {
        ...state,
        currentClient: action.payload,
      };

    case CLIENTS_CLIENT_SURVEYS_LOAD_SUCCESS:
      return {
        ...state,
        surveys: action.payload,
      };

    case CLIENTS_CLIENT_USERS_ADD:
      return {
        ...state,
        users: state.users.concat(action.payload),
      };

    case CLIENTS_CLIENT_WORK_ORDERS_LOAD_SUCCESS:
      return {
        ...state,
        workOrders: action.payload,
      };

    case CLIENTS_INVITE_CLIENT_ADD: {
      let clients = state.clients.find(
        (client) => client.id === action.payload.id
      )
        ? state.clients
        : state.clients.concat([action.payload]);
      return {
        ...state,
        clients: sortByAttribute(clients, 'name'),
      };
    }
    case CLIENTS_LOAD_SUCCESS:
      return {
        ...state,
        clients: action.payload,
      };

    case CLIENTS_USERS_SET:
      return {
        ...state,
        users: action.payload,
      };

    case CLIENTS_ADD_TO_BEGINNING: {
      let clients = state.clients.find(
        (client) => client.id === action.payload.id
      )
        ? state.clients
        : [action.payload, ...state.clients];
      return {
        ...state,
        clients: clients,
      };
    }
    case CLIENTS_CLIENT_PROJECTS_LOAD_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      };

    default:
      return state;
  }
}
