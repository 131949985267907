import React from 'react';
import { connect } from 'react-redux';

import PdfViewer from 'components/shared/pdf-viewer/PdfViewer';
import { getFieldReportPdf } from './store/selectors';
import { setFieldReportPdf } from './store/actions';

const FieldReportPdfModal = (props) => {
  const { dispatch, fieldReportPdf } = props;
  const onClose = () => dispatch(setFieldReportPdf(null));

  if (fieldReportPdf === null) return null;

  return (
    <PdfViewer
      content={{
        source: fieldReportPdf,
        type: 'url',
      }}
      show={fieldReportPdf != null}
      onClose={onClose}
    />
  );
};

export default connect(
  (state) => ({ fieldReportPdf: getFieldReportPdf(state) }),
  null
)(FieldReportPdfModal);
