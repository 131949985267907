import {
  DEPOSITS_LOAD_SUCCESS,
  DEPOSITS_DEPOSIT_ATTRIBUTE_UPDATE,
  DEPOSITS_DEPOSIT_LOAD_SUCCESS,
  DEPOSITS_USERS_LOAD_SUCCESS,
} from 'actions/admin/deposits/action-types';

const initialState = {
  deposits: null,
  currentDeposit: null,
  usersByClientId: {},
};

export default function depositsDataReducer(state = initialState, action) {
  switch (action.type) {
    case DEPOSITS_LOAD_SUCCESS:
      return {
        ...state,
        deposits: action.payload,
      };

    case DEPOSITS_DEPOSIT_LOAD_SUCCESS:
      return {
        ...state,
        currentDeposit: action.payload,
      };

    case DEPOSITS_DEPOSIT_ATTRIBUTE_UPDATE:
      return {
        ...state,
        currentDeposit: {
          ...state.currentDeposit,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case DEPOSITS_USERS_LOAD_SUCCESS:
      return {
        ...state,
        usersByClientId: {
          ...state.usersByClientId,
          [action.payload.clientId]: action.payload.users,
        },
      };

    default:
      return state;
  }
}
