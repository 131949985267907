import { workOrdersApi } from 'services/api/work-orders-api';
import TasksUtil from 'services/utils/tasks-util';
import { quotesApi } from 'services/api/quotes-api';
import { tradesApi } from 'services/api/trades-api';
import { tasksApi } from 'services/api/tasks-api';
import { contractorsLienReleasesApi } from 'services/api/contractors-lien-releases-api';
import {
  WORK_ORDERS_DETAILS_DOCUMENTS_UPLOAD_SUCCESS,
  WORK_ORDERS_DETAILS_NAV_BAR_SELECTION,
  WORK_ORDERS_DETAILS_STATE_INIT,
  WORK_ORDERS_DETAILS_TASK_CANCEL,
  WORK_ORDERS_DETAILS_TASK_CHANGE_VALUE,
  WORK_ORDERS_DETAILS_TASK_EDIT,
  WORK_ORDERS_DETAILS_TASK_LOAD_SUCCESS,
  WORK_ORDERS_DETAILS_DOCUMENT_REMOVE_SUCCESS,
  WORK_ORDERS_DETAILS_QUOTES_ADD,
  WORK_ORDERS_DETAILS_QUOTES_UPDATE,
  WORK_ORDERS_DETAILS_QUOTES_LOAD_SUCCESS,
  WORK_ORDERS_DETAILS_QUOTES_TABLE_FILTER_CHANGE,
  WORK_ORDERS_DETAILS_QUOTES_TABLE_RADIO_SELECT,
  WORK_ORDERS_DETAILS_QUOTES_UPDATE_SUCCESS,
  WORK_ORDERS_DETAILS_QUOTES_DELETE_SUCCESS,
  WORK_ORDERS_DETAILS_CHANGE_ORDER_ADD,
  WORK_ORDERS_DETAILS_MOVED_TO_ACR,
  WORK_ORDERS_DETAILS_PROPOSAL_ADD,
  WORK_ORDERS_DETAILS_PROPOSALS_LOAD_SUCCESS,
  WORK_ORDERS_DETAILS_QUOTES_SELECT,
  WORK_ORDERS_DETAILS_QUOTE_DETAIL_MODAL_CLOSE,
  WORK_ORDERS_DETAILS_TASK_IMAGE_REMOVE_SUCCESS,
  WORK_ORDERS_DETAILS_TASK_UPDATE,
  WORK_ORDERS_DETAILS_PROPOSAL_UPDATE,
  WORK_ORDERS_DETAILS_NOTES_LOAD_SUCCESS,
  WORK_ORDERS_DETAILS_CLIENT_STREAM_MODAL_SHOW,
  WORK_ORDERS_DETAILS_NOTE_ADD_SUCCESS,
  WORK_ORDERS_DETAILS_TASK_NOTES_LOAD_SUCCESS,
  WORK_ORDERS_DETAILS_TASK_NOTE_ADD_SUCCESS,
  WORK_ORDERS_DETAILS_MEMBERS_LOAD_SUCCESS,
  WORK_ORDERS_DETAILS_MEMBERS_ADD,
  WORK_ORDERS_DETAILS_OPEN_ITEMS_LOAD_SUCCESS,
  WORK_ORDERS_DETAILS_OPEN_ITEMS_ADD,
  WORK_ORDERS_DETAILS_OPEN_ITEM_SELECTED,
  WORK_ORDERS_DETAILS_OPEN_ITEMS_UPDATE,
  WORK_ORDERS_DETAILS_UNREAD_NOTES_SET,
  WORK_ORDERS_DETAILS_PRE_EXISTING_CONDITIONS_DESCRIPTION_CHANGE,
  WORK_ORDERS_DETAILS_PRE_EXISTING_CONDITIONS_CHANGE,
  WORK_ORDERS_DETAILS_PRE_EXISTING_CONDITIONS_RESET,
  WORK_ORDERS_DETAILS_SET_CURRENT_WORK_ORDER,
  WORK_ORDERS_DETAILS_WORK_ORDER_NAME_EDIT,
  WORK_ORDERS_DETAILS_BUDGET_SET,
  WORK_ORDERS_DETAILS_QUOTES_REMOVE,
  WORK_ORDERS_DETAILS_WORK_ORDER_NAME_CHANGE,
  WORK_ORDERS_DETAILS_WORK_ORDER_NAME_SAVING,
  WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_EDIT,
  WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_CHANGE,
  WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_REMINDER_CHANGE,
  WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_SAVING,
  WORK_ORDERS_DETAILS_CONTRACTOR_INVOICE_SET,
  WORK_ORDERS_DETAILS_CONTRACTOR_LIEN_RELEASE_SET,
  WORK_ORDERS_DETAILS_CONTRACTOR_LIEN_RELEASES_SET,
  WORK_ORDERS_DETAILS_CONTRACTOR_LIEN_RELEASE_REMOVE,
  WORK_ORDERS_DETAILS_TASK_SELECT_SET,
  WORK_ORDERS_DETAILS_TASK_SELECT_CLEAR,
  WORK_ORDERS_DETAILS_WORK_ORDER_JOB_NUMBER_EDIT,
  WORK_ORDERS_DETAILS_WORK_ORDER_JOB_NUMBER_CHANGE,
  WORK_ORDERS_DETAILS_WORK_ORDER_JOB_NUMBER_SAVING,
  WORK_ORDERS_DETAILS_SHOW_WOA_SIGNING_MODAL_SET,
  WORK_ORDERS_DETAILS_WOA_SIGNING_URL_SET,
  WORK_ORDERS_DETAILS_WOAS_SET,
} from 'actions/admin/work-orders/action-types';
import {
  baseRoutePath,
  loadSubTradesSuccess,
  loadTasksFromWorkOrderSuccess,
  loadTradesSuccess,
  removeTask,
  selectWorkOrderSuccess,
  updateTask,
  updateTaskChecklist,
  updateTaskSuccess,
  updateWorkOrderAttribute,
} from '../work-orders';
import { push } from 'react-router-redux';
import { showErrorMessage } from 'actions/messages';
import TasksApi from 'services/api/tasks-api';
import { WorkOrderStatusEnum } from 'services/utils/work-order-status-enum';
import { tasksChecklistApi } from 'services/api/tasks-checklist-api';
import User from 'domain/user';
import {
  getSelectAllChecked,
  getSelectedTasks,
} from 'selectors/admin/work-orders/details/details';
import { getTasks } from 'selectors/admin/work-orders/work-orders';
import { TaskStatusEnum } from 'domain/task-status-enum';
import { getTime } from 'components/admin/work-orders/details/time/store/actions';
import { getVendorInvoices } from 'components/admin/work-orders/details/vendor-invoices/store/actions';
import { getReceipts } from 'components/admin/work-orders/details/receipts/store/actions';
import { updateCurrentWorkOrderAttribute } from 'actions/admin/work-orders/work-orders';
import WorkOrdersApi from 'services/api/work-orders-api';
import { workOrderAuthorizationsApi } from 'services/api/work-order-authorizations-api';

export const signEasyBasePath = 'dl.getsigneasy.com';

export function addMember(member) {
  return {
    type: WORK_ORDERS_DETAILS_MEMBERS_ADD,
    payload: member,
  };
}

export function addOpenItem(openItem) {
  return {
    type: WORK_ORDERS_DETAILS_OPEN_ITEMS_ADD,
    payload: { taskId: openItem.task.id, item: openItem },
  };
}

export function detailsAddChangeOrder() {
  return {
    type: WORK_ORDERS_DETAILS_CHANGE_ORDER_ADD,
  };
}

export function detailsMovedToAcr() {
  return {
    type: WORK_ORDERS_DETAILS_MOVED_TO_ACR,
  };
}

export function addProposal(proposal) {
  return {
    type: WORK_ORDERS_DETAILS_PROPOSAL_ADD,
    payload: proposal,
  };
}

export function addTaskNoteRequest(taskId, text) {
  return (dispatch) => {
    return tasksApi.addNote(taskId, text).then((response) => {
      if (response.ok) {
        dispatch(addTaskNoteSuccess(response.note));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
  };
}

export function addTaskNoteSuccess(note) {
  return {
    type: WORK_ORDERS_DETAILS_TASK_NOTE_ADD_SUCCESS,
    payload: note,
  };
}

export function addWorkOrderNoteRequest(workOrderId, note) {
  return (dispatch) => {
    return workOrdersApi.addNote(workOrderId, note).then((response) => {
      if (response.ok) {
        dispatch(addWorkOrderNoteSuccess(response.note));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function addWorkOrderNoteSuccess(note) {
  return {
    type: WORK_ORDERS_DETAILS_NOTE_ADD_SUCCESS,
    payload: note,
  };
}

export function approveTaskWorkRequest(taskId) {
  return (dispatch) => {
    return tasksApi.approveWork(taskId).then((response) => {
      if (response.ok) {
        dispatch(updateTask(response.task)); // to update task on task list on store
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function cancelTask(workOrderId) {
  return (dispatch) => {
    dispatch({
      type: WORK_ORDERS_DETAILS_TASK_CANCEL,
    });
    dispatch(push(baseRoutePath + '/' + workOrderId));
  };
}

export function changeWorkOrderJobNumber(value) {
  return {
    type: WORK_ORDERS_DETAILS_WORK_ORDER_JOB_NUMBER_CHANGE,
    payload: value,
  };
}

export function changeWorkOrderName(value) {
  return {
    type: WORK_ORDERS_DETAILS_WORK_ORDER_NAME_CHANGE,
    payload: value,
  };
}

export function changeWorkOrderNote(value) {
  return {
    type: WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_CHANGE,
    payload: value,
  };
}

export function changeWorkOrderNoteReminder(value) {
  return {
    type: WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_REMINDER_CHANGE,
    payload: value,
  };
}

export function changeQuotesTableFilterSuccess(filter) {
  return {
    type: WORK_ORDERS_DETAILS_QUOTES_TABLE_FILTER_CHANGE,
    payload: filter,
  };
}

export function closeQuoteDetailsModal() {
  return {
    type: WORK_ORDERS_DETAILS_QUOTE_DETAIL_MODAL_CLOSE,
  };
}

export function completeTask(taskId) {
  return (dispatch) => {
    return tasksApi.complete(taskId).then((response) => {
      if (response.ok) {
        dispatch(selectTaskSuccess(TasksUtil.mapTask(response.task)));
        dispatch(updateTask(response.task));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function deleteTaskRequest(taskId) {
  return (dispatch) => {
    return tasksApi.removeTask(taskId).then((response) => {
      if (response.ok) {
        dispatch(selectTaskSuccess(null));
        dispatch(removeQuotes(taskId));
        dispatch(removeTask(taskId));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function editTask() {
  return {
    type: WORK_ORDERS_DETAILS_TASK_EDIT,
  };
}

export function editWorkOrderJobNumber(value) {
  return {
    type: WORK_ORDERS_DETAILS_WORK_ORDER_JOB_NUMBER_EDIT,
    payload: value,
  };
}

export function editWorkOrderName(value) {
  return {
    type: WORK_ORDERS_DETAILS_WORK_ORDER_NAME_EDIT,
    payload: value,
  };
}

export function editWorkOrderNote(value) {
  return {
    type: WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_EDIT,
    payload: value,
  };
}

export function goToTask(workOrderId, taskId) {
  return (dispatch) => {
    dispatch(push(`${baseRoutePath}/${workOrderId}/task/${taskId}`));
  };
}

export function initializeComponentAppState() {
  return {
    type: WORK_ORDERS_DETAILS_STATE_INIT,
  };
}

export function loadBudgetRequest(workOrderId) {
  return (dispatch) => {
    return workOrdersApi.getBudget(workOrderId).then((response) => {
      if (response.ok) {
        dispatch(setBudget(response.budget));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadContractorInvoiceRequest(workOrderId) {
  return (dispatch) => {
    return workOrdersApi.getContractorInvoices(workOrderId).then((response) => {
      if (response.ok) {
        if (response.invoices.length > 0) {
          dispatch(setContractorInvoice(response.invoices[0]));

          if (response.invoices[0].status === 'paid') {
            dispatch(loadContractorLienReleaseRequest(workOrderId));
          }
        } else {
          dispatch(setContractorInvoice(null));
        }
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadContractorLienReleaseRequest(workOrderId) {
  return (dispatch) => {
    return workOrdersApi
      .getContractorLienReleases(workOrderId)
      .then((response) => {
        if (response.ok) {
          if (response.lienReleases.length > 0) {
            dispatch(setContractorLienRelease(response.lienReleases[0]));
          } else {
            dispatch(setContractorLienRelease(null));
          }
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
  };
}

export function loadContractorLienReleasesRequest(workOrderId) {
  return (dispatch) => {
    return workOrdersApi
      .getContractorLienReleases(workOrderId)
      .then((response) => {
        if (response.ok) {
          dispatch(setContractorLienReleases(response.lienReleases));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
  };
}

export function deleteContractorLienReleaseRequest(lienReleaseId) {
  return (dispatch) => {
    return contractorsLienReleasesApi
      .deleteLienRelease(lienReleaseId)
      .then((response) => {
        if (response.ok) {
          dispatch(removeContractorLienRelease(lienReleaseId));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadMembersRequest(workOrderId) {
  return (dispatch) => {
    return workOrdersApi.getMembers(workOrderId).then((response) => {
      if (response.ok) {
        dispatch(loadMembersSuccess(response.members));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadMembersSuccess(members) {
  return {
    type: WORK_ORDERS_DETAILS_MEMBERS_LOAD_SUCCESS,
    payload: members,
  };
}

export function loadProposalsFromWorkOrderRequest(workOrderId) {
  return (dispatch) => {
    return workOrdersApi.getProposals(workOrderId).then((response) => {
      if (response.ok) {
        dispatch(loadProposalsSucces(response.proposals));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadProposalsSucces(proposals) {
  return {
    type: WORK_ORDERS_DETAILS_PROPOSALS_LOAD_SUCCESS,
    payload: proposals,
  };
}

export function loadQuotesRequest(workOrderId) {
  return (dispatch) => {
    return workOrdersApi.getQuotes(workOrderId).then((response) => {
      if (response.ok) {
        dispatch(loadQuotesSuccess(response.quotes));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadQuotesSuccess(quotes) {
  return {
    type: WORK_ORDERS_DETAILS_QUOTES_LOAD_SUCCESS,
    payload: quotes,
  };
}

export function counterQuote(quoteId, counter) {
  return (dispatch) => {
    return quotesApi
      .counterQuote(quoteId, { counter: counter })
      .then((response) => {
        if (response.ok) {
          dispatch(updateQuoteSuccess(response.quote));
        }

        return response;
      });
  };
}

export function updateQuoteRequest(quoteId, estimate, value, contractorId) {
  return (dispatch) => {
    return quotesApi
      .updateQuote(quoteId, {
        estimate: estimate,
        value: value,
        contractorId: contractorId,
      })
      .then((response) => {
        if (response.ok) {
          dispatch(updateQuoteSuccess(response.quote));
        }
      });
  };
}

export function updateQuoteSuccess(quote) {
  return {
    type: WORK_ORDERS_DETAILS_QUOTES_UPDATE_SUCCESS,
    payload: quote,
  };
}

export function deleteQuote(quoteId) {
  return (dispatch) => {
    return quotesApi.deleteQuote(quoteId).then((response) => {
      if (response.ok) {
        dispatch(deleteQuoteSuccess(quoteId));
      }
    });
  };
}

export function deleteQuoteSuccess(quoteId) {
  return {
    type: WORK_ORDERS_DETAILS_QUOTES_DELETE_SUCCESS,
    payload: quoteId,
  };
}

export function loadSubTradesRequest(tradeId) {
  return (dispatch) => {
    return tradesApi.getSubTrades(tradeId).then((response) => {
      if (response.ok) {
        dispatch(loadSubTradesSuccess(tradeId, response.subTrades));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadTaskNotesRequest(taskId) {
  return (dispatch) => {
    return tasksApi.getNotes(taskId).then((response) => {
      if (response.ok) {
        dispatch(loadTaskNotesSuccess(response.notes));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadTaskNotesSuccess(notes) {
  return {
    type: WORK_ORDERS_DETAILS_TASK_NOTES_LOAD_SUCCESS,
    payload: notes,
  };
}

export function loadTasksFromWorkOrderRequest(workOrderId, userIsContractor) {
  return (dispatch) => {
    return workOrdersApi.getTasks(workOrderId).then((response) => {
      if (response.ok) {
        dispatch(loadTasksFromWorkOrderSuccess(response.tasks));
        dispatch(clearTasksSelection());
        if (!userIsContractor) {
          dispatch(loadProposalsFromWorkOrderRequest(workOrderId));
        }
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadTaskForQuoteDetail(taskId, quoteId) {
  return (dispatch) => {
    return tasksApi.getTask(taskId).then((response) => {
      if (response.ok) {
        dispatch(selectTaskSuccess(TasksUtil.mapTask(response.task)));
        dispatch(selectQuote(quoteId));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadTradesRequest() {
  return (dispatch) => {
    return tradesApi.getTrades().then((response) => {
      if (response.ok) {
        dispatch(loadTradesSuccess(response.trades));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadWorkOrderNotesRequest(workOrderId) {
  return (dispatch) => {
    return workOrdersApi.getNotes(workOrderId).then((response) => {
      if (response.ok) {
        dispatch(loadWorkOrderNotesSuccess(response.notes));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadWorkOrderNotesSuccess(notes) {
  return {
    type: WORK_ORDERS_DETAILS_NOTES_LOAD_SUCCESS,
    payload: notes,
  };
}

export function loadWorkOrderRequest(
  workOrderId,
  userIsAdmin,
  userIsContractor,
  contractorUserCanSeeMoney = false
) {
  return (dispatch) => {
    dispatch(selectWorkOrderSuccess(null)); // clean current selected WO (new WOs don't have some info required by WO details)
    return workOrdersApi.getWorkOrder(workOrderId).then((response) => {
      if (response.ok) {
        dispatch(selectWorkOrderSuccess(response.workOrder));
        dispatch(loadTasksFromWorkOrderRequest(workOrderId, userIsContractor));

        // Load WO open items if corresponds
        const statusEnum = WorkOrderStatusEnum.create(
          response.workOrder.status
        );
        if (
          (userIsAdmin || userIsContractor) &&
          (WorkOrderStatusEnum.IN_PROGRESS.equals(statusEnum) ||
            WorkOrderStatusEnum.COMPLETE.equals(statusEnum) ||
            WorkOrderStatusEnum.CLOSED.equals(statusEnum))
        ) {
          dispatch(loadOpenItemsRequest(workOrderId));
        }

        if (
          userIsAdmin &&
          (WorkOrderStatusEnum.COMPLETE.equals(statusEnum) ||
            WorkOrderStatusEnum.CLOSED.equals(statusEnum))
        ) {
          dispatch(loadContractorLienReleasesRequest(workOrderId));
        }

        if (userIsContractor) {
          dispatch(getReceipts(workOrderId));
          dispatch(getTime(workOrderId));
          dispatch(getVendorInvoices(workOrderId));

          if (
            contractorUserCanSeeMoney &&
            (WorkOrderStatusEnum.IN_PROGRESS.equals(statusEnum) ||
              WorkOrderStatusEnum.COMPLETE.equals(statusEnum) ||
              WorkOrderStatusEnum.CLOSED.equals(statusEnum))
          ) {
            dispatch(loadContractorInvoiceRequest(workOrderId));
          }
        } else {
          dispatch(loadBudgetRequest(workOrderId));
        }

        if (userIsAdmin || contractorUserCanSeeMoney) {
          dispatch(loadWorkOrderAuthorizations(workOrderId));
        }
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function loadOpenItemsRequest(workOrderId) {
  return (dispatch) => {
    return workOrdersApi.getOpenItems(workOrderId).then((response) => {
      if (response.ok) {
        const openItemsByTaskId = mapWorkOrderOpenItems(response.openItems);
        dispatch(loadOpenItemsSuccess(openItemsByTaskId));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

/**
 * Map list of open items to a map by task id.
 * @param openItems
 * @return {*}
 */
export function mapWorkOrderOpenItems(openItems) {
  return openItems.reduce((accumulator, currentOpenItem) => {
    let items = accumulator[currentOpenItem.task.id];
    if (accumulator[currentOpenItem.task.id] === undefined) {
      items = [];
    }
    items.push(currentOpenItem);
    accumulator[currentOpenItem.task.id] = items;
    return accumulator;
  }, {});
}

export function loadOpenItemsSuccess(openItemsByTaskId) {
  return {
    type: WORK_ORDERS_DETAILS_OPEN_ITEMS_LOAD_SUCCESS,
    payload: openItemsByTaskId,
  };
}

export function markItemTaskChecklistRequest(
  taskId,
  taskChecklistId,
  itemId,
  value
) {
  return (dispatch) => {
    return tasksChecklistApi
      .markItemChecklist(taskChecklistId, itemId, value)
      .then((response) => {
        if (response.ok) {
          dispatch(updateTaskChecklist(taskId, response.taskChecklist));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function removeDocumentRequest(workOrderId, documentId) {
  return (dispatch) => {
    return workOrdersApi
      .deleteDocument(workOrderId, documentId)
      .then((response) => {
        if (response.ok) {
          dispatch(removeDocumentSuccess(documentId));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
  };
}

export function removeDocumentSuccess(documentId) {
  return {
    type: WORK_ORDERS_DETAILS_DOCUMENT_REMOVE_SUCCESS,
    payload: documentId,
  };
}

export function removeImageRequest(currentTask, imageIndex) {
  return (dispatch) => {
    const image = currentTask.images[imageIndex];
    if (image.id === 0) {
      // Image is local
      dispatch(removeImage(imageIndex));
    } else {
      // Image is stored in the backend, removeUser from there
      return TasksApi.removeImage(currentTask.id, image.id).then((response) => {
        if (response.ok) {
          dispatch(removeImage(imageIndex));
          dispatch(updateTask(response.task));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
    }
  };
}

export function removeImage(imageIndex) {
  return {
    type: WORK_ORDERS_DETAILS_TASK_IMAGE_REMOVE_SUCCESS,
    payload: imageIndex,
  };
}

export function removeCompleteTaskImagesRequest(taskChecklistId, imageId) {
  return (dispatch) => {
    return tasksChecklistApi
      .removeCompleteTaskImage(taskChecklistId, imageId)
      .then((response) => {
        if (response.ok) {
          dispatch(
            updateTaskChecklist(
              response.taskChecklist.task_id,
              response.taskChecklist
            )
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function removePreExistingConditionsImagesRequest(
  taskChecklistId,
  imageId
) {
  return (dispatch) => {
    return tasksChecklistApi
      .removePreExistingConditionsImage(taskChecklistId, imageId)
      .then((response) => {
        if (response.ok) {
          dispatch(
            updateTaskChecklist(
              response.taskChecklist.task_id,
              response.taskChecklist
            )
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function removeQuotes(taskId) {
  return {
    type: WORK_ORDERS_DETAILS_QUOTES_REMOVE,
    payload: taskId,
  };
}

export function savePreExistingConditionsDescriptionRequest(
  taskChecklistId,
  description
) {
  return (dispatch) => {
    return tasksChecklistApi
      .savePreExistingConditionsDescription(taskChecklistId, description)
      .then((response) => {
        if (response.ok) {
          dispatch(
            updateTaskChecklist(
              response.taskChecklist.task_id,
              response.taskChecklist
            )
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
  };
}

export function saveWorkOrderJobNumber(
  workOrderId,
  value,
  workOrderJob = null
) {
  return (dispatch) => {
    dispatch(setWorkOrderJobNumberSaving(true));
    let promise;
    if (workOrderJob === null) {
      promise = workOrdersApi.createJobNumber(workOrderId, value);
    } else {
      promise = workOrdersApi.updateJobNumber(workOrderJob.id, value);
    }
    return promise.then((response) => {
      dispatch(setWorkOrderJobNumberSaving(false));
      if (response.ok) {
        dispatch(editWorkOrderJobNumber(false));
        dispatch(
          updateCurrentWorkOrderAttribute(
            'work_order_job',
            response.workOrderJob
          )
        );
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function saveWorkOrderNameRequest(workOrderId, value) {
  return (dispatch) => {
    dispatch(setWorkOrderNameSaving(true));
    return workOrdersApi.updateName(workOrderId, value).then((response) => {
      dispatch(setWorkOrderNameSaving(false));
      if (response.ok) {
        dispatch(editWorkOrderName(false));
        dispatch(updateWorkOrderAttribute(response.workOrder, 'name'));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

function setWorkOrderJobNumberSaving(value) {
  return {
    type: WORK_ORDERS_DETAILS_WORK_ORDER_JOB_NUMBER_SAVING,
    payload: value,
  };
}

function setWorkOrderNameSaving(value) {
  return {
    type: WORK_ORDERS_DETAILS_WORK_ORDER_NAME_SAVING,
    payload: value,
  };
}

export function saveWorkOrderNoteRequest(workOrderId, value, reminderValue) {
  return (dispatch) => {
    dispatch(setWorkOrderNoteSaving(true));
    return workOrdersApi
      .updateNote(workOrderId, value, reminderValue)
      .then((response) => {
        dispatch(setWorkOrderNoteSaving(false));
        if (response.ok) {
          dispatch(editWorkOrderNote(false));
          dispatch(updateWorkOrderAttribute(response.workOrder, 'note'));
          dispatch(
            updateWorkOrderAttribute(response.workOrder, 'note_reminder_date')
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
  };
}

function setWorkOrderNoteSaving(value) {
  return {
    type: WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_SAVING,
    payload: value,
  };
}

export function selectNavigationBar(workOrderId, value) {
  return (dispatch) => {
    dispatch({
      type: WORK_ORDERS_DETAILS_NAV_BAR_SELECTION,
      payload: value,
    });
    dispatch(push(baseRoutePath + '/' + workOrderId));
  };
}

export function selectQuote(quoteId) {
  return {
    type: WORK_ORDERS_DETAILS_QUOTES_SELECT,
    payload: quoteId,
  };
}

export function selectQuotesTableRadio(taskId, quoteId) {
  return {
    type: WORK_ORDERS_DETAILS_QUOTES_TABLE_RADIO_SELECT,
    payload: { taskId, quoteId },
  };
}

export function setBudget(budget) {
  return {
    type: WORK_ORDERS_DETAILS_BUDGET_SET,
    payload: budget,
  };
}

export function setContractorInvoice(contractorInvoice) {
  return {
    type: WORK_ORDERS_DETAILS_CONTRACTOR_INVOICE_SET,
    payload: contractorInvoice,
  };
}

export function setContractorLienRelease(contractorLienRelease) {
  return {
    type: WORK_ORDERS_DETAILS_CONTRACTOR_LIEN_RELEASE_SET,
    payload: contractorLienRelease,
  };
}

export function setContractorLienReleases(contractorLienReleases) {
  return {
    type: WORK_ORDERS_DETAILS_CONTRACTOR_LIEN_RELEASES_SET,
    payload: contractorLienReleases,
  };
}

export function removeContractorLienRelease(lienReleaseId) {
  return {
    type: WORK_ORDERS_DETAILS_CONTRACTOR_LIEN_RELEASE_REMOVE,
    payload: lienReleaseId,
  };
}

export function setCurrentWorkOrder(workOrder) {
  return {
    type: WORK_ORDERS_DETAILS_SET_CURRENT_WORK_ORDER,
    payload: workOrder,
  };
}

export function selectTaskRequest(workOrderId, taskId, currentUser) {
  return (dispatch) => {
    return tasksApi.getTask(taskId).then((response) => {
      if (response.ok) {
        const task = response.task;
        const tradeId = task.trade.id;
        dispatch(resetPreExistingConditions());

        // Task checklist description.
        if (task.task_checklist !== null) {
          if (task.task_checklist.pre_existing_desc !== null) {
            dispatch(
              updatePreExistingConditionsDescription(
                task.task_checklist.pre_existing_desc
              )
            );
          }

          dispatch(
            updatePreExistingConditions(
              (task.task_checklist.pre_existing_desc !== null &&
                task.task_checklist.pre_existing_desc !== '') ||
                task.task_checklist.pre_existing_images.length > 0
                ? true
                : null
            ) // at first, no "false", just null
          );
        }

        // Load quotes only if user is contractor and has at least one role
        const loadQuotes =
          User.isContractor(currentUser) && currentUser.roles.length > 0;
        if (loadQuotes) {
          return quotesApi.getQuotesByTask(taskId).then((response) => {
            if (response.ok) {
              dispatch(updateQuotes(response.quotes));
              dispatch(loadSubTradesRequest(tradeId));
              dispatch(updateTask(task)); // current task does not contain all task info, update task on the list
              dispatch(selectTaskSuccess(TasksUtil.mapTask(task)));
            } else {
              dispatch(showErrorMessage(response.errors));
            }

            return response;
          });
        } else {
          dispatch(loadSubTradesRequest(tradeId));
          dispatch(updateTask(task)); // current task does not contain all task info, update task on the list
          dispatch(selectTaskSuccess(TasksUtil.mapTask(task)));
        }
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function selectTaskSuccess(task) {
  return {
    type: WORK_ORDERS_DETAILS_TASK_LOAD_SUCCESS,
    payload: task,
  };
}

export function setSelectedOpenItem(openItem) {
  return {
    type: WORK_ORDERS_DETAILS_OPEN_ITEM_SELECTED,
    payload: openItem,
  };
}

export function setShowClientStreamModalRequest(workOrderId) {
  return (dispatch) => {
    return workOrdersApi.markNotesAsReaded(workOrderId).then((response) => {
      if (response.ok) {
        dispatch(updateWorkOrderUnreadNotes(false));
        dispatch(setShowClientStreamModal(true));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function setShowClientStreamModal(open) {
  return {
    type: WORK_ORDERS_DETAILS_CLIENT_STREAM_MODAL_SHOW,
    payload: open,
  };
}

export function startTask(taskId) {
  return (dispatch) => {
    return tasksApi.start(taskId).then((response) => {
      if (response.ok) {
        dispatch(selectTaskSuccess(TasksUtil.mapTask(response.task)));
        dispatch(updateTask(response.task));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function submitEditOpenRequestRequest(taskId, openItemId, editForm) {
  return (dispatch) => {
    return tasksApi
      .updateOpenItem(taskId, openItemId, editForm)
      .then((response) => {
        if (response.ok) {
          dispatch(updateOpenItem(response.openItem));
          dispatch(setSelectedOpenItem(null));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
  };
}

export function taskChange(taskId, attribute, value, label) {
  return {
    type: WORK_ORDERS_DETAILS_TASK_CHANGE_VALUE,
    payload: {
      taskId: taskId,
      attribute: attribute,
      value: value,
      label: label,
    },
  };
}

export function taskChangeRequest(taskId, attribute, value, label) {
  return (dispatch) => {
    if (attribute === 'images') {
      // If new files uploaded, read them first so it's possible to show with a preview

      let imageLoaded = [];
      let imageLoaderPromises = value.map((image) => {
        return new Promise((resolve) => {
          let reader = new FileReader();
          reader.onloadend = () => {
            imageLoaded.push({
              id: 0,
              file: {
                original: image,
                img: reader.result,
              },
              preview: null,
              url: null,
            });
            resolve();
          };
          reader.readAsDataURL(image);
        });
      });

      Promise.all(imageLoaderPromises).then(() => {
        dispatch(taskChange(taskId, attribute, imageLoaded));
      });
    } else {
      dispatch(taskChange(taskId, attribute, value, label));
    }
  };
}

export function updatePreExistingConditions(value) {
  return {
    type: WORK_ORDERS_DETAILS_PRE_EXISTING_CONDITIONS_CHANGE,
    payload: value,
  };
}

export function updatePreExistingConditionsDescription(value) {
  return {
    type: WORK_ORDERS_DETAILS_PRE_EXISTING_CONDITIONS_DESCRIPTION_CHANGE,
    payload: value,
  };
}

export function resetPreExistingConditions() {
  return {
    type: WORK_ORDERS_DETAILS_PRE_EXISTING_CONDITIONS_RESET,
  };
}

export function addQuote(quote) {
  return {
    type: WORK_ORDERS_DETAILS_QUOTES_ADD,
    payload: quote,
  };
}

export function updateQuotes(quotes) {
  return {
    type: WORK_ORDERS_DETAILS_QUOTES_UPDATE,
    payload: quotes,
  };
}

export function updateTaskRequest(workOrderId, task) {
  return (dispatch) => {
    return tasksApi.updateTask(task).then((response) => {
      if (response.ok) {
        dispatch(updateTaskSuccess());
        dispatch(updateTask(response.task));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function taskScheduleUpdate(taskId, start, end) {
  return (dispatch) => {
    return tasksApi.updateTaskSchedule(taskId, start, end).then((response) => {
      if (response.ok) {
        dispatch(updateTask(response.task));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function updateWorkOrderUnreadNotes(unread) {
  return {
    type: WORK_ORDERS_DETAILS_UNREAD_NOTES_SET,
    payload: unread,
  };
}

export function uploadDocumentsRequest(workOrderId, files) {
  return (dispatch) => {
    return workOrdersApi
      .uploadDocuments(workOrderId, files)
      .then((response) => {
        if (response.ok) {
          dispatch(uploadDocumentsSuccess(response.documents));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
  };
}

export function uploadDocumentsSuccess(documents) {
  return {
    type: WORK_ORDERS_DETAILS_DOCUMENTS_UPLOAD_SUCCESS,
    payload: documents,
  };
}

export function updateCurrentTask(task) {
  return {
    type: WORK_ORDERS_DETAILS_TASK_UPDATE,
    payload: TasksUtil.mapTask(task),
  };
}

export function updateOpenItem(openItem) {
  return {
    type: WORK_ORDERS_DETAILS_OPEN_ITEMS_UPDATE,
    payload: { taskId: openItem.task.id, item: openItem },
  };
}

export function updateProposal(proposal) {
  return {
    type: WORK_ORDERS_DETAILS_PROPOSAL_UPDATE,
    payload: proposal,
  };
}

export function uploadCompleteTaskImagesRequest(taskChecklistId, images) {
  return (dispatch) => {
    return tasksChecklistApi
      .uploadCompleteTaskImages(taskChecklistId, images)
      .then((response) => {
        if (response.ok) {
          dispatch(
            updateTaskChecklist(
              response.taskChecklist.task_id,
              response.taskChecklist
            )
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
  };
}

export function uploadContractorInvoiceRequest(workOrderId, values) {
  return (dispatch) => {
    return workOrdersApi
      .uploadContractorInvoice(workOrderId, values)
      .then((response) => {
        if (response.ok) {
          dispatch(setContractorInvoice(response.contractorInvoice));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
  };
}

export function uploadContractorLienReleaseRequest(workOrderId, file) {
  return (dispatch) => {
    return workOrdersApi
      .uploadContractorLienRelease(workOrderId, file)
      .then((response) => {
        if (response.ok) {
          dispatch(setContractorLienRelease(response.contractorLienRelease));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
  };
}

export function uploadPreExistingConditionsImagesRequest(
  taskChecklistId,
  images
) {
  return (dispatch) => {
    return tasksChecklistApi
      .uploadPreExistingConditionsImages(taskChecklistId, images)
      .then((response) => {
        if (response.ok) {
          dispatch(
            updateTaskChecklist(
              response.taskChecklist.task_id,
              response.taskChecklist
            )
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
  };
}

export function withdrawQuoteRequest(quoteId) {
  return (dispatch) => {
    return quotesApi.withdrawQuote(quoteId).then((response) => {
      if (response.ok) {
        dispatch(updateQuotes([response.quote]));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function selectAllTasks() {
  return (dispatch, getState) => {
    const selectAllChecked = getSelectAllChecked(getState());
    if (selectAllChecked) {
      dispatch(setTaskSelection({}, false));
    } else {
      let taskIds = {};
      const tasks = getTasks(getState());
      if (tasks !== null && tasks.length > 0) {
        taskIds = extractTaskIds(tasks);
      }
      dispatch(setTaskSelection(taskIds, true));
    }
  };
}

export function deselectAllTasks() {
  return (dispatch) => {
    dispatch(setTaskSelection({}, false));
  };
}

export function selectTask(task) {
  return (dispatch, getState) => {
    const selectedTasks = getSelectedTasks(getState());
    let newSelectedTasks = { ...selectedTasks };
    if (selectedTasks[task.id] !== undefined) {
      delete newSelectedTasks[task.id];
    } else {
      newSelectedTasks[task.id] = task;
    }

    const tasks = getTasks(getState());
    let allChecked = false;
    if (tasks !== null && tasks.length > 0) {
      const taskIds = extractTaskIds(tasks);
      allChecked =
        Object.keys(taskIds).length === Object.keys(newSelectedTasks).length;
    }

    dispatch(setTaskSelection(newSelectedTasks, allChecked));
  };
}

function extractTaskIds(results) {
  return (
    results
      // For some reports, each item contains a work_order, for other just the work order id
      .reduce((accumulator, task) => {
        if (
          accumulator[task.id] === undefined &&
          !TaskStatusEnum.CLS.equals(task.status)
        ) {
          accumulator[task.id] = task;
        }
        return accumulator;
      }, {})
  );
}

function setTaskSelection(selectedTasks, allChecked) {
  return {
    type: WORK_ORDERS_DETAILS_TASK_SELECT_SET,
    payload: { selectedTasks, allChecked },
  };
}

export function clearTasksSelection() {
  return {
    type: WORK_ORDERS_DETAILS_TASK_SELECT_CLEAR,
  };
}

export function updateWorkOrderSchedule(workOrderId, start, end) {
  return (dispatch) => {
    let data = { schedule_start_date: start, schedule_end_date: end };
    return WorkOrdersApi.updateWorkOrder(workOrderId, data).then((response) => {
      if (response.ok) {
        dispatch(
          updateWorkOrderAttribute(response.workOrder, 'schedule_start_date')
        );
        dispatch(
          updateWorkOrderAttribute(response.workOrder, 'schedule_end_date')
        );
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function setWoaSigningUrl(url) {
  return {
    type: WORK_ORDERS_DETAILS_WOA_SIGNING_URL_SET,
    payload: url,
  };
}

export function hideWoaSigningModal() {
  return {
    type: WORK_ORDERS_DETAILS_SHOW_WOA_SIGNING_MODAL_SET,
  };
}

export function showSignWoaModal(workOrderAuthorizationId) {
  return (dispatch) => {
    workOrderAuthorizationsApi
      .getSigningUrl(workOrderAuthorizationId, '')
      .then((response) => {
        if (response.ok) {
          const signingUrl = response.data.url.replace(
            signEasyBasePath,
            `${signEasyBasePath}/#`
          );
          dispatch(setWoaSigningUrl(signingUrl));
        }
      });
  };
}

export function setWorkOrderAuthorizations(workOrderAuthorizations) {
  return {
    type: WORK_ORDERS_DETAILS_WOAS_SET,
    payload: workOrderAuthorizations,
  };
}

export function loadWorkOrderAuthorizations(workOrderId) {
  return (dispatch) => {
    workOrderAuthorizationsApi
      .getWorkOrderAuthorizations(workOrderId)
      .then((response) => {
        if (response.ok) {
          dispatch(
            setWorkOrderAuthorizations(response.workOrderAuthorizations)
          );
        }
      });
  };
}
