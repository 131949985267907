import React, { Component } from 'react';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import ReadOnlyInput from 'components/shared/input/ReadOnlyInput';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

class ReviewFormForSub extends Component {
  render() {
    const {
      report,
      parentReport,
      index,
      readOnly,
      editEntry,
      currentDailyReport,
    } = this.props;

    const {
      sub_technician,
      tech_number,
      hours_worked,
      preset_tasks,
      preset_task_other,
      location,
      work_performed,
      additionalInfo,
    } = report;

    const title = sub_technician
      ? `${sub_technician.first_name} ${sub_technician.last_name || ''}`
      : `Technician ${tech_number}`;

    return (
      <div className="review-form-sub-wrapper">
        <DetailsTitle
          className="modal-form-subtitle"
          title={title}
          rightButtons={
            !readOnly && index === 0 && currentDailyReport.status !== 'approved'
              ? [
                  <Button
                    key="edit"
                    type={BUTTON_TYPES.LINK}
                    color={BUTTON_COLORS.GREEN}
                    label="Edit"
                    onClick={editEntry.bind(null, parentReport)}
                  />,
                ]
              : null
          }
        />
        <div className="form-block multicontrols-block">
          <FormControlBlock
            label="Total Hours"
            control={<ReadOnlyInput value={hours_worked || 0} />}
          />
        </div>
        {((preset_tasks && preset_tasks.length > 0) || preset_task_other) && (
          <div className="form-block">
            <FormControlBlock
              label="Tasks Performed"
              control={
                <ul className="form-items-list small-regular">
                  {preset_tasks.length > 0 &&
                    preset_tasks.map((task) => (
                      <li key={task.id}>{task.label}</li>
                    ))}
                  {preset_task_other && <li>{preset_task_other}</li>}
                </ul>
              }
            />
          </div>
        )}
        {work_performed && (
          <div className="form-block">
            <FormControlBlock
              label="Description"
              control={
                <TextareaAutosizeWrapper
                  initialValue={work_performed}
                  disabled={true}
                />
              }
            />
          </div>
        )}
        {location && (
          <div className="form-block">
            <FormControlBlock
              label="Location"
              control={
                <TextareaAutosizeWrapper
                  initialValue={location}
                  disabled={true}
                />
              }
            />
          </div>
        )}
        {additionalInfo && additionalInfo.length && (
          <React.Fragment>
            <DetailsTitle
              className="modal-form-subtitle"
              title={`${title} Additonal Locations`}
            />
            {additionalInfo.map((info, infoIndex) => (
              <React.Fragment key={info.id}>
                <DetailsTitle
                  className="modal-form-subtitle"
                  title={`Additional Location ${infoIndex + 1}`}
                />
                <div className="form-block">
                  <FormControlBlock
                    label="Total Hours"
                    control={<ReadOnlyInput value={info.hours_worked || 0} />}
                  />
                </div>
                {((info.preset_tasks && info.preset_tasks.length > 0) ||
                  info.preset_task_other) && (
                  <div className="form-block">
                    <FormControlBlock
                      label="Tasks Performed"
                      control={
                        <ul className="form-items-list small-regular">
                          {info.preset_tasks.length > 0 &&
                            info.preset_tasks.map((task) => (
                              <li key={task.id}>{task.label}</li>
                            ))}
                          {info.preset_task_other && (
                            <li>{info.preset_task_other}</li>
                          )}
                        </ul>
                      }
                    />
                  </div>
                )}
                {info.location && (
                  <div className="form-block">
                    <FormControlBlock
                      label="Location"
                      control={
                        <TextareaAutosizeWrapper
                          initialValue={info.location}
                          disabled={true}
                        />
                      }
                    />
                  </div>
                )}
                {info.work_performed && (
                  <div className="form-block">
                    <FormControlBlock
                      label="Description"
                      control={
                        <TextareaAutosizeWrapper
                          initialValue={info.work_performed}
                          disabled={true}
                        />
                      }
                    />
                  </div>
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default ReviewFormForSub;
