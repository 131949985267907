import {
  RECEIPTS_SHOW_ADD_MODAL_SET,
  RECEIPTS_TO_EDIT_SET,
} from 'components/admin/work-orders/details/receipts/store/action-types';

const initialState = {
  receiptToEdit: null,
  showAddMaterialsModal: false,
};

export default function receiptsUiReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIPTS_SHOW_ADD_MODAL_SET:
      return {
        ...state,
        showAddMaterialsModal: action.payload,
      };

    case RECEIPTS_TO_EDIT_SET:
      return {
        ...state,
        receiptToEdit: action.payload,
      };

    default:
      return state;
  }
}
