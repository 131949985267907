import {
  USERS_INVITE_USER_ROLES_LOAD_SUCCESS,
  USERS_LOAD_SUCCESS,
  USERS_USER_ADD,
  USERS_USER_EDIT,
  USERS_USER_REMOVE,
} from '../../../actions/admin/users/action-types';
import { sortByAttribute } from '../../../services/utils/sort-util';

const initialState = {
  roles: [],
  users: [],
};

export default function usersDataReducer(state = initialState, action) {
  switch (action.type) {
    case USERS_LOAD_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };

    case USERS_INVITE_USER_ROLES_LOAD_SUCCESS:
      return {
        ...state,
        roles: action.payload,
      };

    case USERS_USER_ADD:
      return {
        ...state,
        users: sortByAttribute(
          state.users.concat([action.payload]),
          'first_name'
        ),
      };

    case USERS_USER_EDIT:
      return {
        ...state,
        users: state.users.map((user) => {
          if (user.id === action.payload.id) {
            return action.payload;
          } else {
            return user;
          }
        }),
      };

    case USERS_USER_REMOVE:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload),
      };

    default:
      return state;
  }
}
