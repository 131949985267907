export const REPORT_RESULTS_LOAD_SUCCESS = 'REPORT_LOAD_SUCCESS';
export const REPORT_PAGE_CHANGE = 'REPORT_PAGE_CHANGE';
export const REPORT_START_DATE_CHANGE = 'REPORT_START_DATE_CHANGE';
export const REPORT_END_DATE_CHANGE = 'REPORT_END_DATE_CHANGE';
export const REPORT_EXTRA_FILTER_VALUE_CHANGE =
  'REPORT_EXTRA_FILTER_VALUE_CHANGE';
export const REPORT_SEARCHING_CHANGE = 'REPORT_SEARCHING_CHANGE';
export const REPORT_WORK_ORDER_SELECT_CLEAR = 'REPORT_WORK_ORDER_SELECT_CLEAR';
export const REPORT_WORK_ORDER_SELECT_SET = 'REPORT_WORK_ORDER_SELECT_SET';
export const REPORTS_RESET = 'REPORTS_RESET';
