import {
  WORK_ORDERS_DETAILS_PROPOSAL_COMPLETION_DATE_UPDATE,
  WORK_ORDERS_DETAILS_PROPOSAL_MARKUP_UPDATE,
  WORK_ORDERS_DETAILS_PROPOSAL_MODAL_SHOW,
  WORK_ORDERS_DETAILS_PROPOSAL_MODAL_EDIT,
  WORK_ORDERS_DETAILS_PROPOSAL_START_DATE_UPDATE,
  WORK_ORDERS_DETAILS_PROPOSAL_USERS_LOAD_SUCCESS,
  WORK_ORDERS_DETAILS_PROPOSAL_DEPOSIT_COST_CHANGE,
  WORK_ORDERS_DETAILS_PROPOSAL_ADD_BREAKDOWNITEM,
  WORK_ORDERS_DETAILS_PROPOSAL_REMOVE_BREAKDOWNITEM,
  WORK_ORDERS_DETAILS_PROPOSAL_UPDATE_BREAKDOWNITEM,
  WORK_ORDERS_DETAILS_PROPOSAL_QUOTE_UPDATE,
} from 'actions/admin/work-orders/action-types';

const initialState = {
  completionDate: null,
  valuesByQuoteId: {},
  startDate: null,
  depositCost: null,
  users: [],
};

export default function proposalDataReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_DETAILS_PROPOSAL_COMPLETION_DATE_UPDATE:
      return {
        ...state,
        completionDate: action.payload,
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_MARKUP_UPDATE:
      return {
        ...state,
        valuesByQuoteId: {
          ...state.valuesByQuoteId,
          [action.payload.quoteId]: {
            ...state.valuesByQuoteId[action.payload.quoteId],
            [action.payload.key]: action.payload.value,
          },
        },
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_MODAL_SHOW:
      return {
        ...state,
        completionDate: action.payload.completionDate,
        valuesByQuoteId: action.payload.valuesByQuoteId,
        startDate: action.payload.startDate,
        depositCost:
          action.payload.deposit !== undefined &&
          action.payload.deposit !== null
            ? action.payload.deposit.cost
            : null,
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_MODAL_EDIT:
      return {
        ...state,
        selectedProposal: action.payload,
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_QUOTE_UPDATE:
      return {
        ...state,
        selectedProposal: {
          ...state.selectedProposal,
          proposal_quotes: state.selectedProposal.proposal_quotes.map((pq) => {
            if (pq.id === action.payload.proposalQuoteId) {
              return {
                ...pq,
                [action.payload.key]: action.payload.value,
              };
            } else {
              return pq;
            }
          }),
        },
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_START_DATE_UPDATE:
      return {
        ...state,
        startDate: action.payload,
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_USERS_LOAD_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_DEPOSIT_COST_CHANGE:
      return {
        ...state,
        depositCost: action.payload,
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_ADD_BREAKDOWNITEM:
      return {
        ...state,
        valuesByQuoteId: {
          ...state.valuesByQuoteId,
          [action.payload.quoteId]: {
            ...state.valuesByQuoteId[action.payload.quoteId],
            breakdownItems: [
              ...state.valuesByQuoteId[action.payload.quoteId].breakdownItems,
              action.payload.breakdownItem,
            ],
          },
        },
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_REMOVE_BREAKDOWNITEM:
      return {
        ...state,
        valuesByQuoteId: {
          ...state.valuesByQuoteId,
          [action.payload.quoteId]: {
            ...state.valuesByQuoteId[action.payload.quoteId],
            breakdownItems: state.valuesByQuoteId[
              action.payload.quoteId
            ].breakdownItems.filter((item, index) => {
              return index !== action.payload.biIndex;
            }),
          },
        },
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_UPDATE_BREAKDOWNITEM:
      return {
        ...state,
        valuesByQuoteId: {
          ...state.valuesByQuoteId,
          [action.payload.quoteId]: {
            ...state.valuesByQuoteId[action.payload.quoteId],
            breakdownItems: state.valuesByQuoteId[
              action.payload.quoteId
            ].breakdownItems.map((item, index) => {
              if (index === action.payload.biIndex) {
                return {
                  ...item,
                  [action.payload.key]: action.payload.value,
                };
              } else {
                return item;
              }
            }),
          },
        },
      };

    default:
      return state;
  }
}
