import {
  CONTRACTORS_CONTACTS_MODAL_SHOW,
  CONTRACTORS_CONTRACTOR_WORK_ORDERS_PAGE_CHANGE,
  CONTRACTORS_EDIT_FORM_LOAD_FROM_CURRENT,
  CONTRACTORS_NEW_CLEAR_DATA,
  CONTRACTORS_NEW_FORM_INVALID,
  CONTRACTORS_NEW_FORM_VALID,
  CONTRACTORS_NEW_FORM_VALUE_CHANGE,
  CONTRACTORS_NEW_SERVICE_AREA_SELECT,
  CONTRACTORS_NEW_TRADE_SELECT,
  CONTRACTORS_NEW_UNIONS_SELECT,
  CONTRACTORS_MSA_SIGNING_URL_CHANGE,
  CONTRACTORS_MSA_SIGNING_MODAL_HIDE,
  CONTRACTORS_SET_PAGINATION_DATA,
  CONTRACTORS_CONTRACTOR_INVITE_USER_SHOW,
  CONTRACTORS_INVITE_FORM_CLEAR,
  CONTRACTORS_SET_OTHER_UNION,
  CONTRACTORS_SHOW_OTHER_UNION,
} from 'actions/admin/contractors/action-types';

const initialState = {
  currentPage: 1,
  invalidFields: [],
  form: {
    companyName: '',
    licenseNumber: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    streetAddress: '',
    unitNumber: '',
    city: '',
    state: '',
    zipCode: '',
    confirmed: false,
    roleId: '', // for contractor user invite
    otherUnion: '',
  },
  selectedServiceAreasId: [],
  selectedTradesId: [],
  selectedUnionsId: [],
  showContacts: false,
  validationMessage: '',
  msaSigningUrl: null,
  showMsaSigningModal: false,
  contractorsCurrentPage: 1,
  contractorsTotal: 0,
  contractorsPageSize: 25,
  showInviteContractorUserModal: false,
  showOtherUnion: false,
};

export default function contractorsUiReducer(state = initialState, action) {
  switch (action.type) {
    case CONTRACTORS_CONTRACTOR_WORK_ORDERS_PAGE_CHANGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case CONTRACTORS_EDIT_FORM_LOAD_FROM_CURRENT:
      return {
        ...state,
        invalidFields: [],
        form: {
          companyName: action.payload.name,
          licenseNumber: action.payload.license_number || '',
          firstName: action.payload.contact.first_name,
          lastName: action.payload.contact.last_name,
          email: action.payload.contact.email,
          phoneNumber: action.payload.contact.phone_number,
          streetAddress: action.payload.location
            ? action.payload.location.street_address_1
            : '',
          unitNumber: action.payload.location
            ? action.payload.location.street_address_2
            : '',
          city: action.payload.location ? action.payload.location.city : '',
          state: action.payload.location ? action.payload.location.state : '',
          zipCode: action.payload.location ? action.payload.location.zip : '',
          confirmed: action.payload.confirmed,
          otherUnion: action.payload.other_union,
        },
        selectedServiceAreasId: action.payload.service_areas.map(
          (serviceArea) => {
            return serviceArea.id;
          }
        ),
        selectedTradesId: action.payload.trades.map((trade) => {
          return trade.id;
        }),
        selectedUnionsId: action.payload.unions.map((union) => {
          return union.id;
        }),
        validationMessage: '',
        showOtherUnion: action.payload.other_union ? true : false,
      };

    case CONTRACTORS_NEW_CLEAR_DATA:
      return initialState;

    case CONTRACTORS_NEW_FORM_INVALID:
      return {
        ...state,
        invalidFields: action.payload.invalidFields,
        validationMessage: action.payload.message,
      };

    case CONTRACTORS_NEW_FORM_VALID:
      return {
        ...state,
        invalidFields: [],
        validationMessage: '',
      };

    case CONTRACTORS_NEW_FORM_VALUE_CHANGE:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case CONTRACTORS_NEW_SERVICE_AREA_SELECT:
      let newSelectedServiceAreasId;
      if (
        state.selectedServiceAreasId.find(
          (element) => element === action.payload
        ) !== undefined
      ) {
        newSelectedServiceAreasId = state.selectedServiceAreasId.filter(
          (element) => element !== action.payload
        );
      } else {
        newSelectedServiceAreasId = state.selectedServiceAreasId.concat(
          action.payload
        );
      }

      return {
        ...state,
        selectedServiceAreasId: newSelectedServiceAreasId,
      };

    case CONTRACTORS_NEW_TRADE_SELECT:
      let newSelectedTradesId;
      if (
        state.selectedTradesId.find((element) => element === action.payload) !==
        undefined
      ) {
        newSelectedTradesId = state.selectedTradesId.filter(
          (element) => element !== action.payload
        );
      } else {
        newSelectedTradesId = state.selectedTradesId.concat(action.payload);
      }

      return {
        ...state,
        selectedTradesId: newSelectedTradesId,
      };

    case CONTRACTORS_NEW_UNIONS_SELECT:
      return {
        ...state,
        selectedUnionsId: action.payload,
      };

    case CONTRACTORS_CONTACTS_MODAL_SHOW:
      return {
        ...state,
        showContacts: action.payload,
      };

    case CONTRACTORS_MSA_SIGNING_URL_CHANGE:
      return {
        ...state,
        msaSigningUrl: action.payload,
        showMsaSigningModal: true,
      };

    case CONTRACTORS_MSA_SIGNING_MODAL_HIDE:
      return {
        ...state,
        showMsaSigningModal: false,
      };

    case CONTRACTORS_SET_PAGINATION_DATA:
      return {
        ...state,
        contractorsCurrentPage: action.payload.page,
        contractorsTotal: action.payload.total,
        contractorsPageSize: action.payload.pageSize,
      };

    case CONTRACTORS_CONTRACTOR_INVITE_USER_SHOW:
      return {
        ...state,
        showInviteContractorUserModal: action.payload,
      };

    case CONTRACTORS_INVITE_FORM_CLEAR:
      return {
        ...state,
        form: initialState.form,
        invalidFields: [],
        validationMessage: '',
      };

    case CONTRACTORS_SET_OTHER_UNION:
      return {
        ...state,
        form: {
          ...state.form,
          unionOther: action.payload,
        },
      };

    case CONTRACTORS_SHOW_OTHER_UNION:
      return {
        ...state,
        showOtherUnion: action.payload,
      };

    default:
      return state;
  }
}
