import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { Grid, Typography } from '@mui/material';
import Switch from 'components/shared/switch/Switch';
import Selector from 'components/shared/selector/Selector';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

import {
  NOTIFICATIONS_FREQUENCY_TYPES,
  NOTIFICATIONS_TIME_OPTIONS,
} from 'domain/notification-settings';
import {
  getFormNotificationSettings,
  getSubjectsNotificationSettings,
} from './store/selectors';
import {
  setFormNotificationSettings,
  updateNotificationSetting,
} from './store/actions';

const NotificationSettingForm = (props) => {
  const { dispatch, subjects, form, handleCancel, handleSubmit } = props;

  const onSubmit = () => {
    dispatch(updateNotificationSetting(form)).then((response) => {
      if (response.ok) {
        if (typeof handleSubmit === 'function') handleSubmit();
      }
    });
  };
  const onCancel = () => {
    if (typeof handleCancel === 'function') handleCancel();
  };

  const onFormChange = (values) => {
    if (
      values.frequencyType &&
      form.frequencyType.value !== values.frequencyType.value
    ) {
      const allow =
        values.frequencyType.value === NOTIFICATIONS_FREQUENCY_TYPES[2].value;
      values.frequencies = form.frequencies.map((f) => ({ ...f, allow }));
    }

    dispatch(setFormNotificationSettings(values));
  };
  const onFrequencyChange = (values, index) => {
    const frequencies = [...form.frequencies];
    frequencies[index] = { ...frequencies[index], ...values };
    onFormChange({ frequencies });
  };
  const onSubjectToggle = (subject) => {
    const newSubjects = form.subjects.filter((s) => s.value !== subject.value);
    if (newSubjects.length === form.subjects.length) newSubjects.push(subject);
    onFormChange({ subjects: newSubjects });
  };

  const renderTimeSlot = (startTime, endTime, onChange) => {
    const startOptions = NOTIFICATIONS_TIME_OPTIONS.filter((o) =>
      moment(o.value, 'HH:mm').isBefore(
        moment(endTime.value, 'HH:mm'),
        'minute'
      )
    );
    const endOptions = NOTIFICATIONS_TIME_OPTIONS.filter((o) =>
      moment(o.value, 'HH:mm').isAfter(
        moment(startTime.value, 'HH:mm'),
        'minute'
      )
    );
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
      >
        <Grid item flex={1}>
          <Selector
            value={startTime}
            options={startOptions}
            onChange={(start) => onChange({ start })}
          />
        </Grid>
        <Grid item>
          <Typography fontWeight={600}>to</Typography>
        </Grid>
        <Grid item flex={1}>
          <Selector
            value={endTime}
            options={endOptions}
            onChange={(end) => onChange({ end })}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container className="notification-setting-form" spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography color="text.primary">
                  Allow In-App notifications
                </Typography>
              </Grid>
              <Grid item>
                <Switch
                  variant="twocolors"
                  value={form.allowInApp}
                  onChange={(allowInApp) => onFormChange({ allowInApp })}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography color="text.primary">
                  Allow Email notifications
                </Typography>
              </Grid>
              <Grid item>
                <Switch
                  variant="twocolors"
                  value={form.allowEmail}
                  onChange={(allowEmail) => onFormChange({ allowEmail })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {(form.allowEmail || form.allowInApp) && (
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item>
              <Typography color="text.primary" fontWeight={600}>
                Notify frequency
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                paragraph
                color="text.secondary"
                variant="caption"
                marginBottom={0}
              >
                Notifications will only be sent during the hours you specify.
                Outside of those hours, notifications will be paused.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={4}>
                  <Selector
                    value={form.frequencyType}
                    options={NOTIFICATIONS_FREQUENCY_TYPES}
                    onChange={(frequencyType) =>
                      onFormChange({ frequencyType })
                    }
                  />
                </Grid>
                {form.frequencyType.value !==
                  NOTIFICATIONS_FREQUENCY_TYPES[2].value && (
                  <Grid item xs={8}>
                    {renderTimeSlot(form.start, form.end, onFormChange)}
                  </Grid>
                )}
              </Grid>
            </Grid>
            {form.frequencyType.value ===
              NOTIFICATIONS_FREQUENCY_TYPES[2].value &&
              form.frequencies.map((f, index) => (
                <Grid item xs={12} key={f.value}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                      <Typography color="text.primary">{f.label}</Typography>
                    </Grid>
                    <Grid item height={56} display="flex" alignItems="center">
                      <Switch
                        variant="twocolors"
                        value={f.allow}
                        onChange={(allow) =>
                          onFrequencyChange({ allow }, index)
                        }
                      />
                    </Grid>
                    {f.allow && (
                      <Grid item flex={1}>
                        {renderTimeSlot(f.start, f.end, (values) =>
                          onFrequencyChange(values, index)
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Grid>
      )}
      {subjects.length > 0 && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography color="text.primary" fontWeight={600}>
                Notify about...
              </Typography>
            </Grid>
            <Grid item>
              <Grid container columnSpacing={2} rowSpacing={1}>
                {subjects.map((s) => (
                  <Grid item xs={4} key={s.value}>
                    <LabeledCheckBox
                      position="left"
                      label={s.label}
                      checked={
                        typeof form.subjects.find(
                          (subject) => subject.value === s.value
                        ) !== 'undefined'
                      }
                      onChange={() => onSubjectToggle(s)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item xs={4}>
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label={'Cancel'}
              onClick={onCancel}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label={'Save'}
              onClick={onSubmit}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const OptionPropType = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});
NotificationSettingForm.propTypes = {
  subjects: PropTypes.arrayOf(OptionPropType).isRequired,
  form: PropTypes.shape({
    id: PropTypes.number,
    allowInApp: PropTypes.bool.isRequired,
    allowEmail: PropTypes.bool.isRequired,
    frequencyType: OptionPropType.isRequired,
    frequencies: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        start: OptionPropType.isRequired,
        end: OptionPropType.isRequired,
        allow: PropTypes.bool.isRequired,
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
    subjects: PropTypes.arrayOf(OptionPropType).isRequired,
  }).isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect((state) => ({
  form: getFormNotificationSettings(state),
  subjects: getSubjectsNotificationSettings(state),
}))(NotificationSettingForm);
