import { WORK_ORDERS_DETAILS_BUDGET_USERS_LOAD_SUCCESS } from 'actions/admin/work-orders/action-types';

const initialState = {
  users: [],
};

export default function budgetDataReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_DETAILS_BUDGET_USERS_LOAD_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };

    default:
      return state;
  }
}
