import {
  NEW_PROJECT_GC_SET,
  NEW_PROJECT_ID_SET,
  SUBSCRIBERS_ADD_TO_BEGINNING,
  NEW_PROJECT_CLIENTS_SET,
  NEW_PROJECT_CLIENTS_ADD_TO_BEGINNING,
} from 'components/admin/projects/creation/store/action-types';

const initialState = {
  subscribers: null,
  clients: [],
  newProjectId: null,
};

export default function newProjectDataReducer(state = initialState, action) {
  switch (action.type) {
    case NEW_PROJECT_GC_SET:
      return {
        ...state,
        subscribers: action.payload,
      };

    case NEW_PROJECT_ID_SET:
      return {
        ...state,
        newProjectId: action.payload,
      };

    case SUBSCRIBERS_ADD_TO_BEGINNING: {
      let subscribers = state.subscribers.data.some(
        (subscriber) => subscriber.id === action.payload.id
      )
        ? state.subscribers.data
        : [action.payload, ...state.subscribers.data];
      return {
        ...state,
        subscribers: {
          ...state.subscribers,
          data: subscribers,
        },
      };
    }

    case NEW_PROJECT_CLIENTS_SET:
      return {
        ...state,
        clients: action.payload,
      };

    case NEW_PROJECT_CLIENTS_ADD_TO_BEGINNING: {
      let clients = state.clients.find(
        (client) => client.id === action.payload.id
      )
        ? state.clients
        : [action.payload, ...state.clients];
      return {
        ...state,
        clients: clients,
      };
    }

    default:
      return state;
  }
}
