import {
  ACR_MISC_COST_SET,
  ACR_ADDITIONAL_COST_SET,
} from 'components/admin/work-orders/details/anticipated-cost-report/store/action-types';

const initialState = {
  miscCosts: {},
  proposal: {
    additionalCost: '',
    additionalCostDesc: '',
  },
};

export default function anticipatedCostReportDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case ACR_MISC_COST_SET:
      return {
        ...state,
        miscCosts: {
          ...state.miscCosts,
          [action.payload.quoteId]: action.payload.value,
        },
      };

    case ACR_ADDITIONAL_COST_SET:
      return {
        ...state,
        proposal: {
          ...state.proposal,
          [action.payload.attr]: action.payload.value,
        },
      };

    default:
      return state;
  }
}
