import { createSelector } from 'reselect';

export const getShowEmailModal = createSelector(
  (state) => state.projects.scenes.details.scenes.pdfViewer.data.showModal,
  (showModal) => {
    return showModal;
  }
);

export const getDocumentId = createSelector(
  (state) => state.projects.scenes.details.scenes.pdfViewer.data.documentId,
  (documentId) => {
    return documentId;
  }
);

export const getEmails = createSelector(
  (state) => state.projects.scenes.details.scenes.pdfViewer.data.emails,
  (emails) => {
    return emails;
  }
);

export const getRecipients = createSelector(
  (state) => state.projects.scenes.details.scenes.pdfViewer.data.recipients,
  (recipients) => {
    return recipients;
  }
);

export const getPdfTronSuccess = createSelector(
  (state) => state.projects.scenes.details.scenes.pdfViewer.data.pdfTronSuccess,
  (pdfTronSuccess) => {
    return pdfTronSuccess;
  }
);
