import { showErrorMessage } from 'actions/messages';
import { usersApi } from 'services/api/users-api';
import { serviceProvidersApi } from 'services/api/service-providers-api';
import { tradesApi } from 'services/api/trades-api';
import { unionsApi } from 'services/api/union-api';
import { rolesApi } from 'services/api/roles-api';
import {
  SET_TRADE_CODES,
  SET_UNIONS,
  SET_ROLES,
  SET_DRAFT,
  SET_SIMILAR_COMPANIES,
  UPDATE_FORM,
  SHOW_SIMILAR_COMPANIES_MODAL,
  SHOW_REGISTERED_MODAL,
  SHOW_VERIFYING_MODAL,
} from './action-types';
import { dataUrlToFile } from 'services/utils/files-util';
import { getAccountAdminRole } from 'domain/user-role-enum';

export function setTradeCodes(trade_codes) {
  return {
    type: SET_TRADE_CODES,
    payload: trade_codes,
  };
}

export function loadTradeCodes() {
  return (dispatch) => {
    return tradesApi.getTrades().then((response) => {
      if (response.ok) {
        dispatch(setTradeCodes(response.trades));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function setUnions(unions) {
  return {
    type: SET_UNIONS,
    payload: unions,
  };
}

export function loadUnions() {
  return (dispatch) => {
    return unionsApi.getUnions().then((response) => {
      if (response.ok) {
        dispatch(setUnions(response.unions));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function setRoles(roles) {
  return {
    type: SET_ROLES,
    payload: roles,
  };
}

export function loadRoles() {
  return (dispatch) => {
    return rolesApi.getRoles('Company').then((response) => {
      if (response.ok) {
        dispatch(setRoles(response.permissions));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function setDraft(currentUser, draft) {
  return {
    type: SET_DRAFT,
    payload: { draft, currentUser },
  };
}

export function loadDraft(currentUser) {
  return (dispatch) => {
    return usersApi.getDraft().then((response) => {
      const { ok, draft, errors } = response;
      if (ok && draft && draft.company && draft.company.logo) {
        dataUrlToFile(draft.company.logo).then((file) => {
          dispatch(
            setDraft(currentUser, {
              ...draft,
              company: {
                ...draft.company,
                logo: {
                  url: URL.createObjectURL(file),
                  file: file,
                  image: file,
                },
              },
            })
          );
        });
      } else if (ok) {
        dispatch(setDraft(currentUser, draft));
      } else {
        dispatch(showErrorMessage(errors));
      }
    });
  };
}

export function updateForm(field, value) {
  return {
    type: UPDATE_FORM,
    payload: { field, value },
  };
}

export function saveDraft(draft) {
  return (dispatch) => {
    return usersApi.saveDraft(draft).then((response) => {
      if (!response.ok) {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function setSimilarCompanies(companies) {
  return {
    type: SET_SIMILAR_COMPANIES,
    payload: companies,
  };
}

export function setShowSimilarCompaniesModal(show) {
  return {
    type: SHOW_SIMILAR_COMPANIES_MODAL,
    payload: show,
  };
}

export function setShowRegisteredModal(show) {
  return {
    type: SHOW_REGISTERED_MODAL,
    payload: show,
  };
}

export function setShowVerifyingModal(show) {
  return {
    type: SHOW_VERIFYING_MODAL,
    payload: show,
  };
}

const makeRegisterCompanyValues = (company) => {
  const {
    name,
    street_address_1,
    street_address_2,
    city,
    state,
    zip,
    phone_number,
    fax_number,
    logo,
    license_number,
    trade_codes,
    unions,
    other_union,
  } = company;

  const hasOtherUnion = unions.some((union) => union[0] === 'other');

  return {
    name: name,
    officePhoneNumber: phone_number,
    officeFaxNumber: fax_number,
    streetAddress: street_address_1,
    unitNumber: street_address_2,
    city: city,
    state: state,
    zipCode: zip,
    licenceNumber: license_number,
    selectedUnionsId: unions
      .filter((union) => union[0] !== 'other')
      .map((union) => union[0]),
    otherUnion: hasOtherUnion ? other_union : null,
    tradesId: trade_codes.map((trade_code) => trade_code[0]),
    logo: logo,
  };
};

const makeRegisterContactValues = (company, contact, roles) => {
  const {
    email,
    first_name,
    last_name,
    phone_number,
    title,
    role_id,
  } = contact;
  const role = roles.find((role) => role.id === role_id);

  return {
    email: email,
    firstName: first_name,
    lastName: last_name,
    phoneNumber: phone_number,
    title: title,
    titleRole: role.name,
  };
};

const makeRegisterUserValues = (company, user, roles) => {
  const { email, first_name, last_name, phone_number, title, role_id } = user;
  const role = roles.find((role) => role.id === role_id);
  const adminRole = getAccountAdminRole(roles);

  return {
    email: email,
    firstName: first_name,
    lastName: last_name,
    phoneNumber: phone_number,
    title: title,
    rolesId: [role, adminRole].filter((role) => !!role).map((role) => role.id),
  };
};

const makeRegisterNewValues = (form, roles) => {
  const { company, user, primary_contact } = form;

  return {
    ...makeRegisterCompanyValues(company),
    ...makeRegisterContactValues(
      company,
      primary_contact.same_user ? user : primary_contact,
      roles
    ),
    user: makeRegisterUserValues(company, user, roles),
  };
};

const makeRegisterExistentValues = (form, roles) => {
  const { company, user } = form;

  return {
    user: makeRegisterUserValues(company, user, roles),
  };
};

const makeFindSimilarValues = (form) => {
  const { company, user, primary_contact } = form;
  const contact = primary_contact.same_user ? user : primary_contact;

  return {
    mode: 'list',
    company_type: company.type,
    name: company.name,
    email: contact.email,
    phone_number: contact.phone_number,
    office_phone_number: company.phone_number,
    office_fax_number: company.fax_number,
  };
};

export function registerNewCompany(form, roles = [], find_similar = false) {
  return (dispatch) => {
    const register = () => {
      return serviceProvidersApi
        .registerToNewCompany(
          form.company.type,
          makeRegisterNewValues(form, roles)
        )
        .then((response) => {
          dispatch(setShowSimilarCompaniesModal(false));
          if (response.ok) {
            dispatch(setShowRegisteredModal(true));
          } else {
            dispatch(showErrorMessage(response.errors));
          }
        });
    };

    if (find_similar) {
      return serviceProvidersApi
        .findSimilar(makeFindSimilarValues(form))
        .then((response) => {
          if (response.ok && response.companies.length > 0) {
            dispatch(setSimilarCompanies(response.companies));
            dispatch(setShowSimilarCompaniesModal(true));
          } else if (response.ok) {
            register();
          } else {
            dispatch(showErrorMessage(response.errors));
          }
        });
    } else {
      return register();
    }
  };
}

export function registerToExistentCompany(companyId, form, roles = []) {
  return (dispatch) => {
    return serviceProvidersApi
      .registerToExistentCompany(
        form.company.type,
        companyId,
        makeRegisterExistentValues(form, roles)
      )
      .then((response) => {
        dispatch(setShowSimilarCompaniesModal(false));
        if (response.ok) {
          dispatch(setShowVerifyingModal(true));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
  };
}
