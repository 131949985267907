import { createSelector } from 'reselect';

export const getCompaniesCurrentPage = createSelector(
  (state) => state.companies.ui.currentPage,
  (currentPage) => {
    return currentPage;
  }
);

export const getCompanies = createSelector(
  (state) => state.companies.ui.companies,
  (companies) => {
    return companies;
  }
);

export const getCompaniesDropdown = createSelector(
  (state) => state.companies.ui.companyDropdown,
  (companyDropdown) => {
    return companyDropdown;
  }
);

export const getCompaniesTotal = createSelector(
  (state) => state.companies.ui.total,
  (total) => {
    return total;
  }
);

export const getCompaniesPageSize = createSelector(
  (state) => state.companies.ui.pageSize,
  (pageSize) => {
    return pageSize;
  }
);

export const getCompanyNameToSearch = createSelector(
  (state) => state.companies.ui.nameToSearch,
  (nameToSearch) => {
    return nameToSearch;
  }
);

export const getCompanyData = createSelector(
  (state) => state.companies.ui.companyData,
  (companyData) => {
    return companyData;
  }
);

export const getUserList = createSelector(
  (state) => state.companies.ui.userList,
  (userList) => {
    return userList;
  }
);

export const getProjectsList = createSelector(
  (state) => state.companies.ui.projectsList,
  (projectsList) => {
    return projectsList;
  }
);

export const getUserForm = createSelector(
  (state) => state.companies.ui.userForm,
  (userForm) => {
    return userForm;
  }
);

export const getShowInviteUserModal = createSelector(
  (state) => state.companies.ui.showInviteUserModal,
  (showInviteUserModal) => {
    return showInviteUserModal;
  }
);

export const getShowConfirmationDeleteModal = createSelector(
  (state) => state.companies.ui.showConfirmationDeleteModal,
  (showConfirmationDeleteModal) => {
    return showConfirmationDeleteModal;
  }
);

export const getCurrentUserCompany = createSelector(
  (state) => state.companies.ui.currentUserCompany,
  (currentUserCompany) => {
    return currentUserCompany;
  }
);

export const getVerificationModal = createSelector(
  (state) => state.companies.ui.verificationModal,
  (verificationModal) => {
    return verificationModal;
  }
);

export const getUnions = createSelector(
  (state) => state.companies.ui.unions,
  (unions) => {
    return unions;
  }
);

export const getSelectedUnionsId = createSelector(
  (state) => {
    return state.form.CompaniesEditForm.values
      ? state.form.CompaniesEditForm.values.selectedUnionsId
      : [];
  },
  (selectedUnionId) => {
    return selectedUnionId;
  }
);

export const getRoles = createSelector(
  (state) => state.companies.ui.roles,
  (roles) => {
    return roles;
  }
);

export const getLicenceNumber = createSelector(
  (state) => state.companies.ui.licenceNumber,
  (licenceNumber) => {
    return licenceNumber;
  }
);

export const getCurrentStage = createSelector(
  (state) => state.companies.ui.currentStage,
  (currentStage) => {
    return currentStage;
  }
);

export const getCompanyLogo = createSelector(
  (state) => state.companies.ui.companyLogo,
  (companyLogo) => {
    return companyLogo;
  }
);

export const getCompanySelectedTab = createSelector(
  (state) => state.companies.ui.selectedTab,
  (selectedTab) => {
    return selectedTab;
  }
);
