import React, { Component } from 'react';
import { connect } from 'react-redux';
import Chat from 'components/shared/chat/Chat';
import { getRfiQuestions } from 'components/admin/projects/details/project-rfi-log/store/selectors';
import {
  loadRfiQuestionNotes,
  markRfiQuestionNotesRead,
  createRfiQuestionNote,
} from 'components/admin/projects/details/project-rfi-log/store/actions';
import { getCurrentUser } from 'selectors/authentication';

class RfiQuestionNotes extends Component {
  constructor(props) {
    super(props);

    this.questionKey = 0;
  }

  getQuestion = () => {
    const { questions } = this.props;
    return questions[this.questionKey] || null;
  };

  loadNotes = () => {
    const question = this.getQuestion();

    this.props.dispatch(
      markRfiQuestionNotesRead(
        this.questionKey,
        question,
        this.props.currentUser.id
      )
    );
    return this.props.dispatch(
      loadRfiQuestionNotes(this.questionKey, question.id)
    );
  };

  buildNote = (newNote) => {
    return {
      note: newNote.content ? newNote.content : '',
      images: newNote.images ? newNote.images : [],
      documents: newNote.documents ? newNote.documents : [],
    };
  };

  handleSubmit = (newNote) => {
    const question = this.getQuestion();

    return this.props.dispatch(
      createRfiQuestionNote(
        this.questionKey,
        question.id,
        this.buildNote(newNote)
      )
    );
  };

  render() {
    const question = this.getQuestion();

    return (
      question && (
        <Chat
          name="RFI"
          notes={question.notes}
          loadChat={this.loadNotes}
          handleSubmit={this.handleSubmit}
        />
      )
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    questions: getRfiQuestions(state),
  };
})(RfiQuestionNotes);
