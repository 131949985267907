import { createSelector } from 'reselect';

export const getProjects = createSelector(
  (state) => state.projects.data.projects,
  (projects) => {
    return projects;
  }
);

export const getMissingDrs = createSelector(
  (state) => state.projects.data.missingDrs,
  (missingDrs) => {
    return missingDrs;
  }
);

export const getBidsDue = createSelector(
  (state) => state.projects.data.bidsDue,
  (bidsDue) => {
    return bidsDue;
  }
);

export const getCurrentPage = createSelector(
  (state) => state.projects.ui.currentPage,
  (currentPage) => {
    return currentPage;
  }
);

export const getMissingDrsCurrentPage = createSelector(
  (state) => state.projects.ui.missingDrsCurrentPage,
  (missingDrsCurrentPage) => {
    return missingDrsCurrentPage;
  }
);

export const getBidsDueCurrentPage = createSelector(
  (state) => state.projects.ui.bidsDueCurrentPage,
  (bidsDueCurrentPage) => {
    return bidsDueCurrentPage;
  }
);

export const getCurrentNav = createSelector(
  (state) => state.projects.ui.currentNav,
  (currentNav) => {
    return currentNav;
  }
);

export const getMissingDrsCount = createSelector(
  (state) => state.projects.data.missingDrsCount,
  (missingDrsCount) => {
    return missingDrsCount;
  }
);

export const getBidsDueCount = createSelector(
  (state) => state.projects.data.bidsDueCount,
  (bidsDueCount) => {
    return bidsDueCount;
  }
);

export const getShowArchiveConfirmationModal = createSelector(
  (state) => state.projects.ui.showArchiveConfirmationModal,
  (showArchiveConfirmationModal) => {
    return showArchiveConfirmationModal;
  }
);
