import React, { useState } from 'react';
import { connect } from 'react-redux';

import Input from 'components/shared/input/Input';
import Button, {
  BUTTON_ICONS,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

import { getProjectNewMeetingsFilters } from './store/selectors';
import './NewMeetingsSearchFilter.css';

const NewMeetingSearchFilter = (props) => {
  const { handleFilterChange, currentFilters } = props;
  const [value, setValue] = useState(currentFilters.search);

  const handleClick = () => handleFilterChange({ search: value });

  return (
    <div className="new-meeting-search-filter">
      <Input placeholder="Search" value={value} onChange={setValue} />
      <Button
        icon={BUTTON_ICONS.SEARCH}
        color={BUTTON_COLORS.WHITE}
        onClick={handleClick}
      />
    </div>
  );
};

export default connect((state) => {
  return {
    currentFilters: getProjectNewMeetingsFilters(state),
  };
})(NewMeetingSearchFilter);
