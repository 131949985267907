export const CONTRACTORS_CONTACTS_MODAL_SHOW =
  'CONTRACTORS_CONTACTS_MODAL_SHOW';
export const CONTRACTORS_CONTRACTOR_LOAD_SUCCESS =
  'CONTRACTORS_CONTRACTOR_LOAD_SUCCESS';
export const CONTRACTORS_CONTRACTOR_WORK_ORDERS_LOAD_SUCCESS =
  'CONTRACTORS_CONTRACTOR_WORK_ORDERS_LOAD_SUCCESS';
export const CONTRACTORS_CONTRACTOR_WORK_ORDERS_PAGE_CHANGE =
  'CONTRACTORS_CONTRACTOR_WORK_ORDERS_PAGE_CHANGE';
export const CONTRACTORS_EDIT_FORM_LOAD_FROM_CURRENT =
  'CONTRACTORS_EDIT_FORM_LOAD_FROM_CURRENT';
export const CONTRACTORS_INVOICES_SET = 'CONTRACTORS_INVOICES_SET';
export const CONTRACTORS_CONTRACTOR_PROJECTS_LOAD_SUCCESS =
  'CONTRACTORS_CONTRACTOR_PROJECTS_LOAD_SUCCESS';
export const CONTRACTORS_LOAD_SUCCESS = 'CONTRACTORS_LOAD_SUCCESS';
export const CONTRACTORS_NEW_CLEAR_DATA = 'CONTRACTORS_NEW_CLEAR_DATA';
export const CONTRACTORS_NEW_FORM_INVALID = 'CONTRACTORS_NEW_FORM_INVALID';
export const CONTRACTORS_NEW_FORM_VALID = 'CONTRACTORS_NEW_FORM_VALID';
export const CONTRACTORS_NEW_FORM_VALUE_CHANGE =
  'CONTRACTORS_NEW_FORM_VALUE_CHANGE';
export const CONTRACTORS_NEW_PARAMETERS_LOAD_SUCCESS =
  'CONTRACTORS_NEW_PARAMETERS_LOAD_SUCCESS';
export const CONTRACTORS_NEW_SERVICE_AREA_SELECT =
  'CONTRACTORS_NEW_SERVICE_AREA_SELECT';
export const CONTRACTORS_NEW_TRADE_SELECT = 'CONTRACTORS_NEW_TRADE_SELECT';
export const CONTRACTORS_NEW_UNIONS_SELECT = 'CONTRACTORS_NEW_UNIONS_SELECT';
export const CONTRACTORS_USERS_SET = 'CONTRACTORS_USERS_SET';
export const CONTRACTORS_MSA_SIGNING_URL_CHANGE =
  'CONTRACTORS_MSA_SIGNING_URL_CHANGE';
export const CONTRACTORS_MSA_SIGNING_MODAL_HIDE =
  'CONTRACTORS_MSA_SIGNING_MODAL_HIDE';
export const CONTRACTORS_SET_PAGINATION_DATA =
  'CONTRACTORS_SET_PAGINATION_DATA';
export const CONTRACTORS_CONTRACTOR_ROLES_LOAD_SUCCESS =
  'CONTRACTORS_CONTRACTOR_ROLES_LOAD_SUCCESS';
export const CONTRACTORS_CONTRACTOR_INVITE_USER_SHOW =
  'CONTRACTORS_CONTRACTOR_INVITE_USER_SHOW';
export const CONTRACTORS_CONTRACTOR_USER_ADD =
  'CONTRACTORS_CONTRACTOR_USER_ADD';
export const CONTRACTORS_INVITE_FORM_CLEAR = 'CONTRACTORS_INVITE_FORM_CLEAR';
export const CONTRACTORS_SET_OTHER_UNION = 'CONTRACTORS_SET_OTHER_UNION';
export const CONTRACTORS_SHOW_OTHER_UNION = 'CONTRACTORS_SHOW_OTHER_UNION';
