import {
  OWNER_AUTHORIZED_REPS_LOAD_SUCCESS,
  OWNER_AUTHORIZED_REP_LOAD_SUCCESS,
  OWNER_AUTHORIZED_REP_PROJECTS_LOAD_SUCCESS,
  OWNER_AUTHORIZED_REPS_USERS_SET,
  OWNER_AUTHORIZED_REPS_ROLES_LOAD_SUCCESS,
  OWNER_AUTHORIZED_REPS_USER_ADD,
} from 'components/admin/owner-authorized-reps/store/action-types';

const initialState = {
  ownerAuthorizedReps: { data: [], total: 0 },
  currentownerAuthorizedRep: null,
  projects: null,
  users: [],
  roles: [],
};

export default function ownerAuthorizedRepsDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case OWNER_AUTHORIZED_REP_LOAD_SUCCESS:
      return {
        ...state,
        currentOwnerAuthorizedRep: action.payload,
      };

    case OWNER_AUTHORIZED_REPS_LOAD_SUCCESS:
      return {
        ...state,
        ownerAuthorizedReps: action.payload,
      };

    case OWNER_AUTHORIZED_REP_PROJECTS_LOAD_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      };

    case OWNER_AUTHORIZED_REPS_USERS_SET:
      return {
        ...state,
        users: action.payload,
      };

    case OWNER_AUTHORIZED_REPS_ROLES_LOAD_SUCCESS:
      return {
        ...state,
        roles: action.payload,
      };

    case OWNER_AUTHORIZED_REPS_USER_ADD:
      return {
        ...state,
        users: state.users.concat([action.payload]),
      };

    default:
      return state;
  }
}
