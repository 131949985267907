import React from 'react';
import { connect } from 'react-redux';
import { getReportMetrics } from './store/selectors';
import Indicator from './Indicator';
import './ProjectCompletion.css';

const ProjectCompletion = (props) => {
  const { metrics } = props;
  const { current, previous, original } =
    (metrics && metrics.projected_completion) || {};

  return (
    <Indicator
      className="project-dashboard-project-completion"
      name="Projected Completion"
      type="date"
      current={current}
      previous={previous}
      original={original}
    />
  );
};

export default connect((state) => {
  return {
    metrics: getReportMetrics(state),
  };
})(ProjectCompletion);
