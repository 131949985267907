import {
  PURCHASE_ORDERS_CURRENT_PAGE_SET,
  PURCHASE_ORDERS_SHOW_MODAL_SET,
} from 'components/admin/purchase-orders/store/action-types';

const initialState = {
  createForm: {
    number: '',
  },
  currentPage: 1,
  showCreateModal: false,
};

export default function purchaseOrdersUiReducer(state = initialState, action) {
  switch (action.type) {
    case PURCHASE_ORDERS_CURRENT_PAGE_SET:
      return {
        ...state,
        currentPage: action.payload,
      };

    case PURCHASE_ORDERS_SHOW_MODAL_SET:
      return {
        ...state,
        showCreateModal: action.payload,
      };

    default:
      return state;
  }
}
