import { createSelector } from 'reselect';

export const getCloseoutCats = createSelector(
  (state) => state.projects.scenes.details.scenes.closeout.data.closeoutCats,
  (closeoutCats) => {
    return closeoutCats;
  }
);

export const getCloseoutCompanyTypes = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.closeout.data.closeoutCompanyTypes,
  (companyTypes) => {
    return companyTypes;
  }
);

export const getCloseout = createSelector(
  (state) => state.projects.scenes.details.scenes.closeout.data.closeout,
  (closeout) => {
    return closeout;
  }
);

export const getCloseoutContribs = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.closeout.data.closeoutContribs,
  (closeoutContribs) => {
    return closeoutContribs;
  }
);

export const getCurrentPage = createSelector(
  (state) => state.projects.scenes.details.scenes.closeout.data.currentPage,
  (currentPage) => {
    return currentPage;
  }
);

export const getTotal = createSelector(
  (state) => state.projects.scenes.details.scenes.closeout.data.total,
  (total) => {
    return total;
  }
);

export const getPageSize = createSelector(
  (state) => state.projects.scenes.details.scenes.closeout.data.pageSize,
  (pageSize) => {
    return pageSize;
  }
);

export const getCloseoutPercentage = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.closeout.data.closeoutPercentage,
  (closeoutPercentage) => {
    return closeoutPercentage;
  }
);

export const getCloseoutManuals = createSelector(
  (state) => state.projects.scenes.details.scenes.closeout.data.closeoutManuals,
  (closeoutManuals) => {
    return closeoutManuals;
  }
);

export const getCloseoutManualsCurrentPage = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.closeout.data
      .closeoutManualsCurrentPage,
  (closeoutManualsCurrentPage) => {
    return closeoutManualsCurrentPage;
  }
);

export const getCloseoutManualsTotal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.closeout.data.closeoutManualsTotal,
  (closeoutManualsTotal) => {
    return closeoutManualsTotal;
  }
);

export const getCloseoutManualsPageSize = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.closeout.data.closeoutManualsPageSize,
  (closeoutManualsPageSize) => {
    return closeoutManualsPageSize;
  }
);

export const getCloseoutManual = createSelector(
  (state) => state.projects.scenes.details.scenes.closeout.data.closeoutManual,
  (closeoutManual) => {
    return closeoutManual;
  }
);

export const getCloseoutCatsCompanyTypes = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.closeout.data.closeoutCatsCompanyTypes,
  (closeoutCatsCompanyTypes) => {
    return closeoutCatsCompanyTypes;
  }
);

export const getCloseoutCatShowDeleteConfirmation = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.closeout.data
      .showCloseoutCatDeletionConfirmation,
  (showCloseoutCatDeletionConfirmation) => {
    return showCloseoutCatDeletionConfirmation;
  }
);

export const getCloseoutCatSelected = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.closeout.data.selectedCloseoutCat,
  (selectedCloseoutCat) => {
    return selectedCloseoutCat;
  }
);

export const getCloseoutCatShowModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.closeout.data.showCloseoutCatModal,
  (showCloseoutCatModal) => {
    return showCloseoutCatModal;
  }
);
