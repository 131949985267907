import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';
import { DueDateFilterUtil } from 'services/utils/due-date-filter-util';

class SubmittalsApi extends BaseApi {
  getSubmittals(
    projectId,
    page,
    status,
    label,
    dueDateFilter,
    tradeCodeFilter
  ) {
    const dueDateQuery = DueDateFilterUtil.getDueDateQuery(dueDateFilter);
    let url = `${
      this.baseUrl
    }/projects/${projectId}/submittals?page=${page}&${status}=true${
      dueDateQuery ? `&${dueDateQuery}` : ''
    }`;
    if (tradeCodeFilter && tradeCodeFilter !== 'all') {
      url += `&by_csi_code=${tradeCodeFilter}`;
    }
    if (label) {
      url += `&by_label=${label}`;
    }

    return axios
      .get(url)
      .then((response) => {
        return {
          ok: true,
          submittals: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
          submittalsTradeCodes: response.headers['trade-codes']
            ? JSON.parse(response.headers['trade-codes'])
            : [],
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getSubmittalsAll(page, filters) {
    let query = `page=${page}`;
    Object.keys(filters).forEach((filter) => {
      query += `&${filter}=${filters[filter]}`;
    });

    return axios
      .get(`${this.baseUrl}/submittals?${query}`)
      .then((response) => {
        return {
          ok: true,
          submittals: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getSubmittalsAllCount(filters) {
    let query = '';
    Object.keys(filters).forEach((filter, index) => {
      if (index > 0) {
        query += '&';
      }
      query += `${filter}=${filters[filter]}`;
    });

    return axios
      .get(`${this.baseUrl}/submittals/count?${query}`)
      .then((response) => {
        return {
          ok: true,
          count: numeral(response.headers['total']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getSubmittal(projectId, submittalId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/submittals/${submittalId}`)
      .then((response) => {
        return {
          ok: true,
          submittal: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getSubmittalLogPdf(projectId, status) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/submittal_log/print?${status}=true`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        return {
          ok: true,
          submittalLogPdf: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getSubmittalLeadTimeLogPdf(projectId) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/submittal_lead_time_log/print`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        return {
          ok: true,
          submittalLeadTimeLogPdf: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createSubmittal(projectId, values) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = this.submittalParams(values);

    return axios
      .post(`${this.baseUrl}/projects/${projectId}/submittals`, data, headers)
      .then((response) => {
        return {
          ok: true,
          submittal: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateSubmittal(projectId, submittalId, values) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = this.submittalParams(values);

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/submittals/${submittalId}`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          submittal: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateSubmittalLeadTime(projectId, submittalId, values) {
    const data = {
      submittal: {
        lead_time_applies: values.leadTimeApplies,
        lead_time_weeks: values.leadTimeWeeks,
        order_date: values.orderDate,
        vendor_delivery: values.vendorDelivery,
        field_delivery: values.fieldDelivery,
        lead_time_reminder: values.leadTimeReminder,
      },
    };

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/submittals/${submittalId}`,
        data
      )
      .then((response) => {
        return {
          ok: true,
          submittal: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  respondSubmittal(projectId, submittalId, values) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = this.respondParams(values);

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/submittals/${submittalId}/respond`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          submittal: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getTransmittalPdf(projectId, submittalId) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/submittals/${submittalId}/transmittal`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        return {
          ok: true,
          transmittalPdf: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getNotes(submittalId) {
    return axios
      .get(`${this.baseUrl}/submittals/${submittalId}/notes`)
      .then((response) => {
        return {
          ok: true,
          notes: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  markNotesRead(submittalId) {
    return axios
      .put(`${this.baseUrl}/submittals/${submittalId}/notes/mark_all_read`)
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createNote(submittalId, content) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const apiPayload = new FormData();
    apiPayload.append('note[content]', content.content);

    let index = 0;
    if (content.images && content.images.length > 0) {
      content.images
        .filter((image) => !image.id)
        .forEach((image) => {
          apiPayload.append(
            `note[images_attributes][${index}][image]`,
            image.file,
            image.file.name
          );
          index++;
        });
    }

    index = 0;
    if (content.documents && content.documents.length > 0) {
      content.documents
        .filter((doc) => !doc.id)
        .forEach((doc) => {
          apiPayload.append(
            `note[documents_attributes][${index}][document]`,
            doc.file,
            doc.file.name
          );
          index++;
        });
    }

    return axios
      .post(
        `${this.baseUrl}/submittals/${submittalId}/notes`,
        apiPayload,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          note: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  deleteSubmittal(projectId, submittalId) {
    return axios
      .delete(`${this.baseUrl}/projects/${projectId}/submittals/${submittalId}`)
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  submittalParams(values) {
    const reqDocsKey = values.newRevision
      ? 'revision_documents'
      : 'request_documents_attributes';
    const reqImgsKey = values.newRevision
      ? 'revision_images'
      : 'request_images_attributes';
    let reqImgsIndex = 0;
    let reqDocsIndex = 0;
    let apiPayload = new FormData();
    apiPayload.append('submittal[title]', values.title);
    apiPayload.append('submittal[status]', values.status);
    apiPayload.append('submittal[csi_code_id]', values.csiCodeId);
    apiPayload.append('submittal[physical_copies]', values.physicalCopies);
    apiPayload.append('submittal[assignee_id]', values.assigneeId);
    apiPayload.append('submittal[requested_at]', values.requestedAt);
    apiPayload.append('submittal[due_date]', values.dueDate);
    apiPayload.append('submittal[extended_due_date]', values.extendedDueDate);
    apiPayload.append('submittal[question]', values.question);
    apiPayload.append('submittal[closed]', !!values.closed);
    apiPayload.append('submittal[uid]', values.uid);

    if (values.contractorId) {
      apiPayload.append('submittal[contractor_id]', values.contractorId);
    }

    if (values.newRevision) {
      apiPayload.append('submittal[number]', values.number);
      apiPayload.append(
        'submittal[revised_submittal_id]',
        values.revised_submittal_id
      );
    }

    values.addlAssignees.forEach((addlAssignee, index) => {
      if (addlAssignee.id) {
        apiPayload.append(
          `submittal[submittal_addl_assignees_attributes][${index}][id]`,
          addlAssignee.id
        );
      }
      if (addlAssignee.destroy) {
        apiPayload.append(
          `submittal[submittal_addl_assignees_attributes][${index}][_destroy]`,
          true
        );
      } else {
        apiPayload.append(
          `submittal[submittal_addl_assignees_attributes][${index}][assignee_id]`,
          addlAssignee.assigneeId
        );
        apiPayload.append(
          `submittal[submittal_addl_assignees_attributes][${index}][due_date]`,
          addlAssignee.dueDate
        );
      }
    });

    values.recipientIds.forEach((rcptId) => {
      apiPayload.append('submittal[recipient_ids][]', rcptId);
    });

    values.reqImages
      .filter((img) => img.file)
      .forEach((img) => {
        let file = img.file;
        apiPayload.append(
          `submittal[${reqImgsKey}][${reqImgsIndex}][image]`,
          file,
          file.name
        );
        reqImgsIndex++;
      });

    values.reqDocs
      .filter((doc) => doc.file)
      .forEach((doc) => {
        let file = doc.file;
        apiPayload.append(
          `submittal[${reqDocsKey}][${reqDocsIndex}][key]`,
          file.name
        );
        reqDocsIndex++;
      });

    values.reqImagesDelete
      .filter((img) => img.id)
      .forEach((img) => {
        apiPayload.append(
          `submittal[${reqImgsKey}][${reqImgsIndex}][id]`,
          img.id
        );
        apiPayload.append(
          `submittal[${reqImgsKey}][${reqImgsIndex}][_destroy]`,
          true
        );
        reqImgsIndex++;
      });

    values.reqDocsDelete
      .filter((doc) => doc && doc.id)
      .forEach((doc) => {
        apiPayload.append(
          `submittal[${reqDocsKey}][${reqDocsIndex}][id]`,
          doc.id
        );
        apiPayload.append(
          `submittal[${reqDocsKey}][${reqDocsIndex}][_destroy]`,
          true
        );
        reqDocsIndex++;
      });

    return apiPayload;
  }

  respondParams(values) {
    let resImgsIndex = 0;
    let resDocsIndex = 0;
    let apiPayload = new FormData();
    apiPayload.append('submittal[status]', values.status);
    apiPayload.append('submittal[remarks]', values.remarks);
    apiPayload.append('submittal[stamp_action]', values.stampAction);
    if (values.closed || values.closed === false)
      apiPayload.append('submittal[closed]', values.closed);

    values.resImages
      .filter((img) => img.file)
      .forEach((img) => {
        let file = img.file;
        apiPayload.append(
          `submittal[response_images_attributes][${resImgsIndex}][image]`,
          file,
          file.name
        );
        resImgsIndex++;
      });

    values.resDocs
      .filter((doc) => doc.file)
      .forEach((doc) => {
        let file = doc.file;
        apiPayload.append(
          `submittal[response_documents_attributes][${resDocsIndex}][key]`,
          file.name
        );
        resDocsIndex++;
      });

    values.resImagesDelete
      .filter((img) => img.id)
      .forEach((img) => {
        apiPayload.append(
          `submittal[response_images_attributes][${resImgsIndex}][id]`,
          img.id
        );
        apiPayload.append(
          `submittal[response_images_attributes][${resImgsIndex}][_destroy]`,
          true
        );
        resImgsIndex++;
      });

    values.resDocsDelete
      .filter((doc) => doc.id)
      .forEach((doc) => {
        apiPayload.append(
          `submittal[response_documents_attributes][${resDocsIndex}][id]`,
          doc.id
        );
        apiPayload.append(
          `submittal[response_documents_attributes][${resDocsIndex}][_destroy]`,
          true
        );
        resDocsIndex++;
      });

    return apiPayload;
  }
}

export const submittalsApi = new SubmittalsApi();
