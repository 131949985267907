export const handlePasteTableFromExcel = (e) => {
  const htmlString = e.clipboardData.getData('text/html');
  if (htmlString) {
    e.preventDefault();

    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    const tables = doc.getElementsByTagName('table');
    if (tables.length > 0) {
      return tableToArray(tables[0]);
    }
  }
  return [];
};

export const tableToArray = (table) => {
  const array = [];
  for (let i = 0; i < table.rows.length; i++) {
    const arrRow = [];
    const row = table.rows[i];
    for (let j = 0; j < row.cells.length; j++) {
      const cell = row.cells[j];
      arrRow.push(cell.textContent);
    }
    array.push(arrRow);
  }
  return array;
};
