import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DocumentLink from 'components/shared/document/DocumentLink';
import LogoUpload from 'components/shared/logo-upload/LogoUpload';
import moment from 'moment';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import ReadOnlyInput from 'components/shared/input/ReadOnlyInput';

class ProjectOpenItemReview extends Component {
  static propTypes = {
    openItem: PropTypes.shape({}).isRequired,
  };

  render() {
    const { openItem } = this.props;
    return (
      <div className="review-form-trade-block review-form-project-open-item">
        <DetailsTitle
          className="modal-form-subtitle"
          title={`${openItem.critical ? 'Critical' : ''} Open Item`}
        />
        {!!(openItem.assignee_label || openItem.resolve_by) && (
          <div className="form-block multicontrols-block">
            {!!openItem.assignee_label && (
              <FormControlBlock
                label="Assigned To"
                control={<ReadOnlyInput value={openItem.assignee_label} />}
              />
            )}
            {!!openItem.resolve_by && (
              <FormControlBlock
                label="Due Date"
                control={
                  <ReadOnlyInput
                    value={moment(openItem.resolve_by).format('MM/DD/YYYY')}
                  />
                }
              />
            )}
          </div>
        )}
        {openItem.recipients && openItem.recipients.length > 0 && (
          <div className="form-block multicontrols-block">
            <FormControlBlock
              label="CC Recipients"
              control={
                <TextareaAutosizeWrapper
                  initialValue={openItem.recipients
                    .map((rcpt) => `${rcpt.first_name} ${rcpt.last_name}`)
                    .join(', ')}
                  disabled={true}
                />
              }
            />
          </div>
        )}
        <div className="form-block multicontrols-block">
          <FormControlBlock
            label="Description"
            control={
              <TextareaAutosizeWrapper
                initialValue={openItem.description || '-'}
                disabled={true}
              />
            }
          />
        </div>
        {((openItem.documents && openItem.documents.length > 0) ||
          (openItem.images && openItem.images.length > 0)) && (
          <div className="form-resources-block">
            {openItem.documents && openItem.documents.length > 0 && (
              <div className="form-documents">
                <FormControlBlock
                  className="without-focus"
                  label="Documents"
                  control={
                    <div className="form-resources-body">
                      {openItem.documents.map((doc, index) => {
                        return (
                          <DocumentLink key={index} url={doc.document.url} />
                        );
                      })}
                    </div>
                  }
                />
              </div>
            )}
            {openItem.images && openItem.images.length > 0 && (
              <div className="form-images">
                <FormControlBlock
                  className="without-focus"
                  label="Photos"
                  control={
                    <div className="form-resources-body">
                      <LogoUpload
                        readOnly
                        logos={openItem.images.map((image) => ({
                          preview: image.image.gallery.url,
                          url: image.image.url,
                        }))}
                      />
                    </div>
                  }
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ProjectOpenItemReview;
