import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Collapse, Col } from 'react-bootstrap';
import 'components/admin/companies/CompaniesDetails.css';
import DetailsHeader from 'components/shared/details/DetailsHeader';
import ContractorStatus from 'components/admin/contractors/ContractorStatus';
import DetailsInfoItem from 'components/shared/details/DetailsInfoItem';
import {
  getCompanyData,
  getUserList,
  getProjectsList,
  getUserForm,
  getShowInviteUserModal,
  getShowConfirmationDeleteModal,
  getCompanySelectedTab,
} from 'components/admin/companies/store/selectors';
import { formatPhoneNumber } from 'services/utils/text-util';
import LogoUpload from 'components/shared/logo-upload/LogoUpload';
import { simpleScrollToTop } from 'services/utils/scroller-util';
import {
  loadCompanyById,
  loadCompaniesUser,
  loadCompanyProjects,
  setCurrentUserId,
  setShowEditUserModal,
  loadUserForm,
  editUser,
  deleteUser,
  inviteUser,
  setShowConfirmationModal,
  goToEditCompany,
  setCompanySelectedTab,
} from 'components/admin/companies/store/actions';
import { clearUserData } from 'components/admin/companies/store/actions';
import NavigationBar from 'components/shared/navigation/NavigationBar';
import UserTable from 'components/admin/companies/UserTable';
import ProjectTable from 'components/admin/companies/ProjectTable';
import StampDetails from 'components/shared/stamp/StampDetails';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faAngleDown from '@fortawesome/fontawesome-free-solid/faAngleDown';
import faAngleUp from '@fortawesome/fontawesome-free-solid/faAngleUp';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import { getCurrentUser } from 'selectors/authentication';

class CompaniesDetails extends Component {
  constructor(props) {
    super(props);
    const { match, currentUser } = this.props;
    this.companyId =
      (match && match.params.id) || props.id || currentUser.accountable_id;
    this.myTeam = this.companyId === currentUser.accountable_id;
    this.state = { showStamp: false };
  }

  componentDidUpdate() {
    simpleScrollToTop();
  }

  componentDidMount() {
    const { selectedTab, dispatch } = this.props;
    dispatch(loadCompanyById(this.companyId));
    this.loadTabResources(selectedTab);
  }

  handleEdit = () => {
    const { dispatch } = this.props;
    dispatch(goToEditCompany(this.companyId));
  };

  handleUserSelection = (index) => {
    const { dispatch, users } = this.props;
    dispatch(setCurrentUserId(users[index].id));
    dispatch(loadUserForm(users[index]));
    dispatch(setShowEditUserModal(true));
  };

  handleShowStamp = () => {
    this.setState({ showStamp: !this.state.showStamp });
  };

  getDetailsInfoAddress() {
    const {
      companyData: { location },
    } = this.props;

    return (
      location && (
        <DetailsInfoItem
          key="address"
          lines={[
            location.street_address_1,
            `${location.city}, ${location.state} ${location.zip}`,
          ]}
          title="ADDRESS"
        />
      )
    );
  }

  getDetailInfoPrimaryContact() {
    const {
      companyData: { contact },
    } = this.props;

    return (
      contact && (
        <DetailsInfoItem
          key="company"
          lines={[
            <span className="company-name">
              {`${contact.first_name} ${contact.last_name}`}
            </span>,
            <a className="email-link" href={`mailto:${contact.email}`}>
              {contact.email}
            </a>,
            formatPhoneNumber(contact.phone_number),
          ]}
          title={
            <div>
              <span>PRIMARY CONTACT</span>
            </div>
          }
        />
      )
    );
  }

  getDetailInfoStamp() {
    const {
      companyData: { stamp, name },
    } = this.props;
    const { showStamp } = this.state;

    return (
      stamp && (
        <DetailsInfoItem
          key="stamp"
          className="details-info-item-stamp-container"
          lines={[
            <Collapse in={showStamp} className="collapse-info">
              <div>
                <Col md={12}>
                  <StampDetails
                    companyName={name}
                    stamp={stamp}
                    value="approved"
                  />
                </Col>
                <div className="clearfix" />
              </div>
            </Collapse>,
          ]}
          title={
            <div
              className="details-info-item-stamp"
              onClick={this.handleShowStamp}
            >
              <span>STAMP</span>
              <span>
                <FontAwesomeIcon icon={showStamp ? faAngleUp : faAngleDown} />
              </span>
            </div>
          }
        />
      )
    );
  }

  getDetailInfoLicenseNumber() {
    const {
      companyData: { license_number: licenseNumber },
    } = this.props;

    return (
      licenseNumber && (
        <DetailsInfoItem
          key="license"
          lines={[licenseNumber]}
          title="LICENSE NUMBER"
        />
      )
    );
  }

  getDetailInfoUnions() {
    const {
      companyData: { unions },
    } = this.props;

    return (
      unions &&
      unions.length > 0 && (
        <DetailsInfoItem
          key="unions"
          lines={unions.map(({ detail, label }) => (
            <span>
              {label}
              {detail && <span className="union-detail">({detail})</span>}
            </span>
          ))}
          title="UNIONS"
        />
      )
    );
  }

  getDetailsInfoItems() {
    return [
      this.getDetailsInfoAddress(),
      this.getDetailInfoPrimaryContact(),
      this.getDetailInfoLicenseNumber(),
      this.getDetailInfoUnions(),
      this.getDetailInfoStamp(),
    ].filter((item) => item);
  }

  loadTabResources(tab) {
    const { dispatch } = this.props;

    switch (tab) {
      case 'user':
        dispatch(loadCompaniesUser(this.companyId));
        break;
      case 'project':
        dispatch(loadCompanyProjects(this.companyId));
        break;
      default:
        break;
    }
  }

  handleTabChange(tab) {
    const { dispatch } = this.props;

    this.loadTabResources(tab);
    dispatch(setCompanySelectedTab(tab));
  }

  render() {
    if (!this.props.companyData) return null;

    const {
      selectedTab,
      companyData: { name, logo, status },
      users,
      projects,
    } = this.props;

    const detailsInfoItems = this.getDetailsInfoItems();

    return (
      <div className="company-details">
        <DetailsHeader
          leftHeader={{
            className: 'header-field',
            title: 'COMPANY',
            value: name,
          }}
          rightHeaders={[
            logo &&
              logo.image && [
                {
                  value: (
                    <div className="logo-preview-container">
                      <LogoUpload
                        extensions={['.jpg', '.jpeg', '.gif', '.png', '.heic']}
                        logos={[
                          {
                            preview: logo.image.url,
                            url: logo.image.url,
                          },
                        ]}
                        readOnly={true}
                        viewMode="contain"
                      />
                    </div>
                  ),
                },
              ],
            !!status && [
              {
                title: 'USER STATUS',
                value: <ContractorStatus status={status} />,
              },
            ],
          ].filter((component) => component)}
        />

        <div className="content-container">
          <DetailsTitle
            title="Company Information"
            rightButtons={[
              <Button
                key="edit"
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Edit Info"
                onClick={this.handleEdit}
              />,
            ]}
          />
          <div className="information-items">{[detailsInfoItems]}</div>
          <NavigationBar
            items={[
              { id: 'user', title: 'Team' },
              { id: 'project', title: 'Projects' },
            ]}
            selectedValue={selectedTab}
            onSelected={(tab) => this.handleTabChange(tab)}
          />
          {selectedTab === 'user' && users && <UserTable />}
          {selectedTab === 'project' && projects && <ProjectTable />}
        </div>
      </div>
    );
  }

  handleCloseEditUserModal = () => {
    const { dispatch } = this.props;
    dispatch(setShowEditUserModal(false));
  };

  handleInviteClient = () => {
    const { dispatch } = this.props;
    dispatch(clearUserData());
    dispatch(setShowEditUserModal(true));
  };

  handleEditUserOnSubmit = (values) => {
    const {
      dispatch,
      companyData: { id },
      user,
    } = this.props;
    if (user) {
      dispatch(editUser(values, id));
    } else {
      dispatch(inviteUser(values, id));
    }
  };

  handleDeleteEditUserModal = (response) => {
    const {
      user,
      dispatch,
      companyData: { id },
    } = this.props;
    if (response) {
      dispatch(deleteUser(user, id));
    }
    dispatch(setShowConfirmationModal(false));
  };

  handleShowConfirmationDelete = () => {
    const { dispatch } = this.props;
    dispatch(setShowConfirmationModal(true));
  };
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    companyData: getCompanyData(state),
    users: getUserList(state),
    projects: getProjectsList(state),
    user: getUserForm(state),
    showInviteUserModal: getShowInviteUserModal(state),
    showConfirmationDeleteModal: getShowConfirmationDeleteModal(state),
    selectedTab: getCompanySelectedTab(state),
  };
})(CompaniesDetails);
