import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './ProjectOpenItemForm.css';
import Selector from 'components/shared/selector/Selector';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import DatePicker from 'components/shared/date-picker/DatePicker';
import { todayValidation } from 'services/utils/date-util';
import DocumentUpload, {
  FILE_TYPES,
} from 'components/shared/document-upload/DocumentUpload';
import DocumentLink from 'components/shared/document/DocumentLink';
import ImageUpload from 'components/shared/image-upload/ImageUpload';
import { imageExtensions } from 'services/utils/files-util';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import Project from 'domain/project';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import {
  getCritical,
  getOpenItemForm,
  getOpenItemFormErrors,
} from 'components/admin/projects/details/project-open-items/store/selectors';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import {
  setOpenItemFormAttr,
  setOpenItemFormErrors,
  clearOpenItemFormErrorAttr,
  resetOpenItemForm,
} from 'components/admin/projects/details/project-open-items/store/actions';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import { getDarkMode } from 'selectors/theme';
import { getCurrentUser } from 'selectors/authentication';
import User from 'domain/user';

class ProjectOpenItemForm extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.currentProject) this.loadDirectory();
  }

  componentWillUnmount() {
    this.props.dispatch(resetOpenItemForm());
  }

  loadDirectory = () => {
    const { currentProject, directory, dispatch } = this.props;
    if (!directory || directory.project_id !== currentProject.id) {
      dispatch(loadDirectoryRequest(currentProject.id));
    }
  };

  handleAttributeChange = (attr, value) => {
    this.props.dispatch(setOpenItemFormAttr(attr, value));
    this.props.dispatch(clearOpenItemFormErrorAttr(attr));
  };

  mapRecipientIds = () => {
    const { form } = this.props;
    return form.recipients && form.recipients.map((rcpt) => rcpt[0]);
  };

  handleSaveDraft = () => {
    const { form, critical, onSave } = this.props;
    const recipientIds = this.mapRecipientIds();
    const values = { ...form, recipientIds, critical, draft: true };
    onSave(values);
  };

  handleSave = () => {
    const { form, critical, onSave, dispatch } = this.props;
    const errors = {};
    if (
      form.projectContributorId === null ||
      form.projectContributorId === undefined
    ) {
      errors.projectContributorId = true;
    }

    if (!form.assigneeId) {
      errors.assigneeId = true;
    }

    if (!form.resolveBy) {
      errors.resolveBy = true;
    }

    if (!form.description) {
      errors.description = true;
    }

    if (Object.keys(errors).length > 0) {
      dispatch(setOpenItemFormErrors(errors));
    } else {
      const recipientIds = this.mapRecipientIds();
      const values = { ...form, recipientIds, critical, draft: false };
      onSave(values);
    }
  };

  projectContributorOptions = () => {
    const { directory } = this.props;
    const options = Project.projectContributorOptions({ directory });
    return options;
  };

  recipientOptions = () => {
    const { currentUser, currentProject, directory } = this.props;
    return User.isAdminGcOar(currentUser, currentProject)
      ? Project.recipientOptions({ directory })
      : Project.primaryRecipientOptions({ directory });
  };

  assigneeOptions = () => {
    return this.recipientOptions().map((opt) => {
      return { value: opt.id, label: opt.label };
    });
  };

  getArrayAttribute = (attr) => {
    const { form } = this.props;
    if (form && form[attr] && form[attr].length > 0) {
      return form[attr];
    }
    return [];
  };

  setImages = (files) => {
    const images = [...this.getArrayAttribute('images')];
    files.forEach(function (val) {
      images.push({
        url: val.url || URL.createObjectURL(val),
        file: val,
      });
    });

    this.handleAttributeChange('images', images);
  };

  removeImage = (index) => {
    const images = [...this.getArrayAttribute('images')];
    const deletedImages = [...this.getArrayAttribute('deletedImages')];
    const deletedImage = images.splice(index, 1);
    deletedImages.push(deletedImage[0]);

    this.handleAttributeChange('images', images);
    this.handleAttributeChange('deletedImages', deletedImages);
  };

  setDocuments = (files) => {
    const docs = [...this.getArrayAttribute('documents')];
    files.forEach(function (val) {
      docs.push({
        file: val,
        name: val.name,
      });
    });

    this.handleAttributeChange('documents', docs);
  };

  removeDocument = (index) => {
    const docs = [...this.getArrayAttribute('documents')];
    const deletedDocs = [...this.getArrayAttribute('deletedDocuments')];
    const deletedDoc = docs.splice(index, 1);
    deletedDocs.push(deletedDoc[0]);

    this.handleAttributeChange('documents', docs);
    this.handleAttributeChange('deletedDocuments', deletedDocs);
  };

  showSaveDraftBtn = () => {
    const { form } = this.props;
    return !form.id || form.draft;
  };

  render() {
    const { form, errors, onCancel, darkMode } = this.props;
    const showSaveDraftBtn = this.showSaveDraftBtn();
    const showErrorMessage =
      !!errors && !!Object.keys(errors).find((e) => errors[e]);

    return (
      <React.Fragment>
        <div
          className={`project-open-item-form ${darkMode ? 'dark-mode' : ''}`}
        >
          {form.id && (
            <div className="form-block project-open-item-resolved">
              <LabeledCheckBox
                position="left"
                label="Mark Resolved"
                onChange={this.handleAttributeChange.bind(null, 'resolved')}
                checked={form.resolved}
              />
            </div>
          )}
          <div className="form-block">
            <FormControlBlock
              label={form.projectContributorId !== null && 'Cost Code'}
              control={
                <Selector
                  placeholder="Cost Code"
                  onChange={(option) =>
                    this.handleAttributeChange(
                      'projectContributorId',
                      option && option.value
                    )
                  }
                  value={form.projectContributorId}
                  options={this.projectContributorOptions()}
                  valid={!errors.projectContributorId}
                />
              }
            />
          </div>
          <div className="form-block">
            <FormControlBlock
              label={!!form.assigneeId && 'Assigned To'}
              control={
                <Selector
                  placeholder="Assigned To"
                  onChange={(option) =>
                    this.handleAttributeChange('assigneeId', option.value)
                  }
                  value={form.assigneeId}
                  options={this.assigneeOptions()}
                  valid={!errors.assigneeId}
                />
              }
            />
          </div>
          <div className="form-block">
            <FormControlBlock
              label={(form.recipients || []).length > 0 && 'CC Recipients'}
              control={
                <MultipleDropdown
                  options={this.recipientOptions()}
                  currentValues={form.recipients || []}
                  placeholder="CC Recipients"
                  onChange={(values) =>
                    this.handleAttributeChange('recipients', values)
                  }
                />
              }
            />
          </div>
          <div className="form-block">
            <FormControlBlock
              label={!!form.resolveBy && 'Due Date'}
              control={
                <DatePicker
                  isValidDate={todayValidation}
                  placeholder="Due Date"
                  onChange={(value) =>
                    this.handleAttributeChange('resolveBy', value)
                  }
                  value={form.resolveBy}
                  valid={!errors.resolveBy}
                />
              }
            />
          </div>
          <div className="form-block">
            <FormControlBlock
              label={!!form.description && 'Description'}
              control={
                <TextareaAutosizeWrapper
                  placeholder="Description"
                  initialValue={form.description}
                  valid={!errors.description}
                  onChange={(value) =>
                    this.handleAttributeChange('description', value)
                  }
                />
              }
            />
          </div>
          <div className="form-resources-block">
            <div className="form-documents">
              <FormControlBlock
                className="without-focus"
                label="Documents"
                control={
                  <div className="form-resources-body">
                    {form.documents &&
                      form.documents.map((doc, index) => {
                        return (
                          <DocumentLink
                            key={index}
                            file={doc}
                            url={doc.url}
                            onRemove={() => this.removeDocument(index)}
                          />
                        );
                      })}
                    <DocumentUpload
                      fileType={FILE_TYPES.ANY}
                      onDrop={(files) => this.setDocuments(files)}
                    />
                  </div>
                }
              />
            </div>
            <div className="form-images">
              <FormControlBlock
                className="without-focus"
                label="Photos"
                control={
                  <div className="form-resources-body">
                    <ImageUpload
                      extensions={imageExtensions}
                      multiple
                      onUpload={(files) => this.setImages(files)}
                      onRemove={(index) => this.removeImage(index)}
                      images={form.images}
                    />
                  </div>
                }
              />
            </div>
          </div>
        </div>
        <div className="project-open-item-actions">
          {showErrorMessage && (
            <h4 className="error-message">
              There are missing fields, please review.
            </h4>
          )}
          <div className="buttons-container">
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Cancel"
              onClick={onCancel}
            />
            {showSaveDraftBtn && (
              <Button
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.WHITE}
                label="Save Draft"
                onClick={this.handleSaveDraft}
              />
            )}
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label={showSaveDraftBtn ? 'Submit' : 'Save'}
              onClick={this.handleSave}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    directory: getDirectory(state),
    critical: getCritical(state),
    form: getOpenItemForm(state),
    errors: getOpenItemFormErrors(state),
    darkMode: getDarkMode(state),
  };
})(ProjectOpenItemForm);
