import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormModal from 'components/shared/modal/FormModal';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import { setEmails } from 'components/admin/projects/details/project-email-modal/store/actions';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import PropTypes from 'prop-types';
import Input from 'components/shared/input/Input';
import {
  getEmails,
  getRecipients,
} from 'components/admin/projects/details/project-email-modal/store/selectors';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import './DownloadObjectModal.css';
import TextareaAutosize from 'react-autosize-textarea';
import classnames from 'classnames';
import { getDarkMode } from 'selectors/theme';

class DownloadObjectsModal extends Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    folders: PropTypes.func.isRequired,
    projectId: PropTypes.number.isRequired,
    currentUser: PropTypes.object.isRequired,
    prefix: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subject: PropTypes.string,
    message: PropTypes.string,
    darkMode: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      recipients: [[props.currentUser.id, props.currentUser.email]],
      message: props.message || this.initialMessage(),
      subject: props.subject || this.initialSubject(),
    };
  }

  componentDidMount() {
    const { currentProject } = this.props;

    if (currentProject && currentProject.id) {
      this.props
        .dispatch(loadDirectoryRequest(this.props.currentProject.id))
        .then((response) => {
          if (response.ok) {
            this.props.dispatch(setEmails(this.recipientOptions()));
          }
        });
    }
  }

  handleSubjectChange = (value) => {
    this.setState({ subject: value });
  };

  handleMessageChange = (value) => {
    this.setState({ message: value });
  };

  handleRecipientsChange = (values) => {
    this.setState({ recipients: values });
  };

  isSendButtonEnabled = () => {
    const { recipients, subject, message } = this.state;
    return recipients.length > 0 && subject !== '' && message !== '';
  };

  uniqueEmails(emails) {
    let uniques = [];
    emails.forEach((email) => {
      if (!uniques.find((unique) => unique.id === email.id)) {
        uniques.push(email);
      }
    });
    return uniques;
  }

  recipientOptions = () => {
    return this.uniqueEmails(
      this.props.directory.project_contributors
        .map((pc) => {
          return pc.users.map((user) => ({ id: user.id, label: user.email }));
        })
        .flat(1)
    );
  };

  initialSubject = () => {
    const { currentProject } = this.props;

    return `${currentProject.number} ${currentProject.name} Documents`;
  };

  initialMessage = () => {
    const { currentProject } = this.props;
    const {
      street_address_1,
      city,
      state,
    } = currentProject.project_location.location;

    return `Please find the below link to download  documents \
for ${currentProject.name}, located at ${street_address_1}, ${city}, ${state}.\n
`;
  };

  render() {
    const { showModal, onClose, emails, title, darkMode } = this.props;
    const { recipients, message, subject } = this.state;

    return (
      showModal && (
        <FormModal
          show={showModal}
          onClose={onClose}
          className="download-objects-modal"
          subTitle={title}
        >
          <div className="download-objects-container">
            <div className="form-block">
              <label className="form-block-label">To</label>
              <MultipleDropdown
                options={emails}
                currentValues={recipients}
                placeholder="Select recipients"
                onChange={(values) => this.handleRecipientsChange(values)}
              />
            </div>
            <div className="form-block">
              <label className="form-block-label">Subject</label>
              <Input
                placeholder="Subject"
                value={subject}
                onChange={(value) => this.handleSubjectChange(value)}
              />
            </div>
            <div className="form-block">
              <label className="form-block-label">Message</label>
              <TextareaAutosize
                rows={4}
                maxRows={8}
                className={classnames({
                  'text-area': true,
                  'input-area': true,
                  'form-control': true,
                  'dark-mode': darkMode,
                })}
                placeholder="Message"
                onBlur={(e) => this.handleMessageChange(e.target.value)}
                defaultValue={message}
              />
            </div>
          </div>
          <div className="buttons-container">
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              disabled={!this.isSendButtonEnabled()}
              label="Send"
              onClick={() =>
                this.props.onSubmit({
                  recipients: recipients.map((recipient) => recipient[1]),
                  subject,
                  message,
                })
              }
            />
          </div>
        </FormModal>
      )
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    emails: getEmails(state),
    recipients: getRecipients(state),
    directory: getDirectory(state),
    darkMode: getDarkMode(state),
  };
})(DownloadObjectsModal);
