import * as ACTIONS from '../actions/loader';

const initialState = {
  loading: false,
  loaders: 0,
};

export default function loaderReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.LOADER_START:
      return {
        ...state,
        loaders: state.loaders + 1,
        loading: true,
      };

    case ACTIONS.LOADER_END:
      let loaders = state.loaders - 1;
      if (loaders < 0) {
        // For the situation when a loaderStart is not executed on purpose
        loaders = 0;
      }
      let loading = state.loading;
      if (loaders === 0) {
        loading = false;
      }

      return {
        ...state,
        loaders: loaders,
        loading: loading,
      };

    default:
      return state;
  }
}
