import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormModal from 'components/shared/modal/FormModal';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getShowOpenItemDetailsModal,
  getCritical,
  getCurrentProjectOpenItem,
  getEditOpenItemDetailsModal,
  getOpenItemPdfViewer,
} from 'components/admin/projects/details/project-open-items/store/selectors';
import { getShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/selectors';
import {
  closeOpenItemDetailsModal,
  editOpenItemDetailsModal,
  cancelEditOpenItemDetailsModal,
  setOpenItemForm,
  updateProjectOpenItem,
  deleteProjectOpenItem,
  setOpenItemPdfViewer,
} from 'components/admin/projects/details/project-open-items/store/actions';
import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';
import ProjectOpenItemDetails from 'components/admin/projects/details/project-open-items/ProjectOpenItemDetails';
import ProjectOpenItemForm from 'components/admin/projects/details/project-open-items/ProjectOpenItemForm';
import PdfViewer from 'components/shared/pdf-viewer/PdfViewer';
import ProjectEmailModal from 'components/admin/projects/details/project-email-modal/ProjectEmailModal';
import { sendProjectResourceEmails } from 'components/admin/projects/details/store/actions';
import { formatNumber } from 'services/utils/text-util';
import moment from 'moment';
import ProjectOpenItemNotes from 'components/admin/projects/details/project-open-items/ProjectOpenItemNotes';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import { downloadFile } from 'services/utils/download-util';

class ProjectOpenItemDetailsModal extends Component {
  static propTypes = {
    onClose: PropTypes.func,
  };

  static defaultProps = {
    onClose: null,
  };

  constructor(props) {
    super(props);

    this.state = { showDeleteConfirmation: false };
  }

  buildEmailModalTitle = () => {
    const { currentProject, openItem } = this.props;
    return `${currentProject.name} - Open Item ${formatNumber(
      openItem.number
    )}`;
  };

  buildEmailModalSubject = () => {
    const { currentProject, openItem } = this.props;
    return `${currentProject.number} ${
      currentProject.name
    } - Open Item ${formatNumber(openItem.number)} - ${openItem.description}`;
  };

  buildEmailModalMessage = () => {
    const { currentProject, openItem } = this.props;
    let location = '';
    if (
      currentProject.project_location &&
      currentProject.project_location.location
    ) {
      const projectLocation = currentProject.project_location.location;
      location = ` at ${projectLocation.street_address_1}, ${projectLocation.city} ${projectLocation.state}`;
    }

    return `Please find the attached Open Item #${openItem.number} for Project ${currentProject.name}${location}. If you have any questions, please reach out via chat or email.`;
  };

  handleSendEmail = (emailsData) => {
    const { dispatch, openItem } = this.props;
    dispatch(
      sendProjectResourceEmails(openItem.id, 'ProjectOpenItem', emailsData)
    );
  };

  handleOpenItemDetailsModalClose = () => {
    const { onClose, dispatch } = this.props;
    dispatch(closeOpenItemDetailsModal());
    onClose && onClose();
  };

  handleEdit = () => {
    const { openItem, dispatch } = this.props;
    dispatch(
      setOpenItemForm({
        ...openItem,
        projectContributorId: openItem.project_contributor_id || '',
        assigneeId: openItem.assignee_id,
        resolveBy: openItem.resolve_by,
        recipients: openItem.recipients.map((rcpt) => [rcpt.id]),
        documents: openItem.documents.map((doc) => {
          return {
            ...doc,
            url: doc.document.url,
          };
        }),
        images: openItem.images.map((image) => {
          return {
            ...image,
            url: image.image.gallery.url,
          };
        }),
      })
    );
    dispatch(editOpenItemDetailsModal());
  };

  handleEditCancel = () => {
    this.props.dispatch(cancelEditOpenItemDetailsModal());
  };

  handleUpdate = (values) => {
    const { currentProject, openItem, dispatch } = this.props;
    dispatch(updateProjectOpenItem(currentProject.id, openItem.id, values));
  };

  handleDeleteConfirmed = () => {
    const { currentProject, openItem, dispatch } = this.props;
    dispatch(deleteProjectOpenItem(currentProject.id, openItem.id));
  };

  handleDeleteConfirmation = (confirmed) => {
    this.setState({ showDeleteConfirmation: false });
    confirmed && this.handleDeleteConfirmed();
  };

  handleDelete = () => {
    this.setState({ showDeleteConfirmation: true });
  };

  handleViewPdf = () => {
    const { dispatch } = this.props;
    dispatch(setOpenItemPdfViewer(true));
  };

  handleRfiPdfViewerClose = () => {
    this.props.dispatch(setOpenItemPdfViewer(false));
  };

  handleShowEmailModal = () => {
    this.props.dispatch(setShowEmailModal(true));
  };

  hasPdf = () => {
    const {
      openItem: { pdf_document },
    } = this.props;
    return pdf_document && pdf_document.document && pdf_document.document.url
      ? true
      : false;
  };

  handleDownloadPdf = () => {
    const { openItem, currentProject } = this.props;
    const { number, pdf_document, critical } = openItem;
    const file = pdf_document.document.url + `?time=${moment().unix()}`;
    const fileName = `${currentProject.name} - ${
      critical ? 'Critical Item' : 'Open Item'
    } ${number}`;
    downloadFile(file, fileName);
  };

  modalSubtitle = () => {
    const { currentProject, critical, openItem } = this.props;

    return `${currentProject.name} - ${
      critical ? 'Critical Item' : 'Open Item'
    } ${openItem ? openItem.number : ''}`;
  };

  render() {
    const {
      currentProject,
      openItem,
      showOpenItemDetailsModal,
      editOpenItemDetailsModal,
      openItemPdfViewer,
      showEmailModal,
    } = this.props;
    const { showDeleteConfirmation } = this.state;
    return (
      currentProject &&
      showOpenItemDetailsModal && (
        <FormModal
          className="project-open-item-form-modal"
          show={showOpenItemDetailsModal}
          onClose={this.handleOpenItemDetailsModalClose}
          subTitle={this.modalSubtitle()}
          sidebar={<ProjectOpenItemNotes />}
          actions={[
            openItem && (
              <Button
                key="email"
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Email"
                onClick={this.handleShowEmailModal}
              />
            ),
            openItem && this.hasPdf() && (
              <Button
                key="view_pdf"
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="View PDF"
                onClick={this.handleViewPdf}
              />
            ),
            openItem && this.hasPdf() && (
              <Button
                key="download_pdf"
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Download"
                onClick={this.handleDownloadPdf}
              />
            ),
          ].filter((action) => action)}
        >
          {editOpenItemDetailsModal ? (
            <ProjectOpenItemForm
              onSave={this.handleUpdate}
              onCancel={this.handleEditCancel}
            />
          ) : (
            <ProjectOpenItemDetails
              onDelete={this.handleDelete}
              onEdit={this.handleEdit}
            />
          )}

          {showDeleteConfirmation && (
            <ConfirmationModal
              show={showDeleteConfirmation}
              message="Are you sure you want to delete this item?"
              onHide={this.handleDeleteConfirmation}
            />
          )}

          {openItemPdfViewer && (
            <PdfViewer
              content={{
                source: `${
                  openItem.pdf_document.document.url
                }?time=${moment().unix()}`,
                type: 'url',
              }}
              show={openItemPdfViewer}
              onClose={this.handleRfiPdfViewerClose}
            />
          )}
          {showEmailModal && (
            <ProjectEmailModal
              subTitle={this.buildEmailModalTitle()}
              handleSendEmail={this.handleSendEmail}
              projectId={currentProject.id}
              initialSubject={this.buildEmailModalSubject()}
              initialMessage={this.buildEmailModalMessage()}
              showHistoryButton
              itemType="ProjectOpenItem"
              itemId={openItem.id}
            />
          )}
        </FormModal>
      )
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    openItem: getCurrentProjectOpenItem(state),
    showOpenItemDetailsModal: getShowOpenItemDetailsModal(state),
    editOpenItemDetailsModal: getEditOpenItemDetailsModal(state),
    critical: getCritical(state),
    openItemPdfViewer: getOpenItemPdfViewer(state),
    showEmailModal: getShowEmailModal(state),
  };
})(ProjectOpenItemDetailsModal);
