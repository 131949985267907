import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { simpleScrollToTop } from 'services/utils/scroller-util';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import Project from 'domain/project';
import { getDarkMode } from 'selectors/theme';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import { showErrorMessage } from 'actions/messages';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getProjectReportValue,
  getprojectReportsPermissions,
  getLastProjectReport,
  getProjectReportPreview,
  getProjectReportInitialTab,
} from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import ReportInformationForm from 'components/admin/projects/details/project-daily-report-log/project-report-forms/ReportInformationForm';
import ProjectSummaryForm from 'components/admin/projects/details/project-daily-report-log/project-report-forms/ProjectSummaryForm';
import ProjectStatusForm from 'components/admin/projects/details/project-daily-report-log/project-report-forms/ProjectStatusForm';
import BudgetForm from 'components/admin/projects/details/project-daily-report-log/project-report-forms/BudgetForm';
import ScheduleForm from 'components/admin/projects/details/project-daily-report-log/project-report-forms/ScheduleForm';
import PhotosDocumentsForm from 'components/admin/projects/details/project-daily-report-log/project-report-forms/PhotosDocumentsForm';
import DisclaimerForm from 'components/admin/projects/details/project-daily-report-log/project-report-forms/DisclaimerForm';
import ProjectReportSaveDraftModal from 'components/admin/projects/details/project-daily-report-log/ProjectReportSaveDraftModal';
import ProjectReportErrorModal from 'components/admin/projects/details/project-daily-report-log/ProjectReportErrorModal';
import ProjectReportPublishModal from 'components/admin/projects/details/project-daily-report-log/ProjectReportPublishModal';
import ProjectReportSubmittedReviewModal from 'components/admin/projects/details/project-daily-report-log/ProjectReportSubmittedReviewModal';
import ProjectReportSendReviewModal from 'components/admin/projects/details/project-daily-report-log/ProjectReportSendReviewModal';
import {
  addProjectReports,
  updateProjectReports,
  loadProjectReportRequest,
  sendToReviewProjectReport,
  previewProjectReport,
  setProjectReportPreview,
  setProjectReportOpenDetails,
  setProjectReportInitialTab,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';
import PdfViewer from 'components/shared/pdf-viewer/PdfViewer';
import MessageModal from 'components/shared/modal/MessageModal';
import DirectUploadFiles from 'components/shared/direct-upload/DirectUploadFiles';
import { loadProjectReportsRequest } from './store/actions';

import './AddProjectReportLog.css';
import { withRouter } from 'react-router-dom';

const itemsMenu = [
  {
    key: 'report_information',
    label: 'Report Information',
  },
  {
    key: 'project_summary',
    label: 'Project Summary',
  },
  {
    key: 'project_status',
    label: 'Project Status',
  },
  {
    key: 'budget',
    label: 'Budget',
  },
  {
    key: 'schedule',
    label: 'Schedule',
  },
  {
    key: 'photos_documents',
    label: 'Photos & Documents',
  },
  {
    key: 'disclaimer',
    label: 'Disclaimer',
  },
];

class AddProjectReportLog extends Component {
  constructor(props) {
    super(props);
    const { match } = props;
    const stepKey = match.params.step || itemsMenu[0].key;
    const currentItem =
      itemsMenu.find((item) => item.key === stepKey) || itemsMenu[0];

    this.state = {
      // currentItemMenu: itemsMenu[0],
      currentItemMenu: currentItem,
      showSaveDraftModal: false,
      showErrorModal: false,
      showsubmittedModal: false,
      showReviewModal: false,
      showPublishModal: false,
      missingField: false,
      showFileUpload: false,
      previewing: false,
      reviewing: false,
      failSections: {},
    };
  }

  componentDidMount() {
    const {
      projectId,
      projectReportId,
      dispatch,
      lastProjectReport,
      number,
      user,
      initialTab,
      match,
      history,
    } = this.props;
    const { section, subsection } = match.params;
    let stepKey = match.params.step || itemsMenu[0].key;
    this.loadDirectory();

    if (projectReportId > 0) {
      dispatch(loadProjectReportRequest(projectId, projectReportId));
    } else if (lastProjectReport) {
      dispatch(
        loadProjectReportRequest(
          projectId,
          lastProjectReport.id,
          true,
          number,
          user.id
        )
      );
    }

    if (initialTab) {
      this.onItemHandle(initialTab);
      setProjectReportInitialTab();
    }
    if (!match.params.step) {
      history.replace(
        `/admin/projects/${projectId}/${section}/${subsection}/new/${stepKey}`
      );
    }

    const currentItem =
      itemsMenu.find((item) => item.key === stepKey) || itemsMenu[0];
    this.setState({ currentItemMenu: currentItem });

    simpleScrollToTop();
  }

  componentDidUpdate(prevProps) {
    const { match, history } = this.props;
    const { projectId, section, subsection } = match.params;
    const prevStep = prevProps.match.params.step;
    const currentStep = match.params.step;

    if (subsection !== 'project-reports') {
      this.setState({ showSaveDraftModal: true });
      history.replace(
        `/admin/projects/${projectId}/${section}/project-reports`
      );
    }

    if (prevStep !== currentStep) {
      if (currentStep) {
        const currentItem =
          itemsMenu.find((item) => item.key === currentStep) || itemsMenu[0];
        if (currentItem.key !== this.state.currentItemMenu.key) {
          this.setState({ currentItemMenu: currentItem });
        }
      }
    }
  }

  loadDirectory = () => {
    const { project, directory, dispatch } = this.props;
    if (!directory || directory.project_id !== project.id) {
      dispatch(loadDirectoryRequest(project.id));
    }
  };

  recipientEmails = () => {
    const { projectReportPermissions: permissions } = this.props;
    permissions.push('oar');
    return Project.filterReportRecipients({
      directory: this.props.directory,
      permissions,
      type: 'email',
      reportPermissions: true,
    });
  };

  validate = (values) => {
    const message = [];
    const failSections = {};
    values.contract_info
      .filter(
        (item) =>
          (!item.previous_published_report_id || item.isEdit) && !item._destroy
      )
      .forEach((item) => {
        if (
          !item.description ||
          !item.amount ||
          !item.start ||
          !item.completion
        ) {
          message.push('Fill in the contract information table');
          failSections['project_summary'] = true;
          return;
        }
      });
    values.statuses
      .filter((item) => (item.isEdit || item.isUpdate) && !item._destroy)
      .forEach((item) => {
        if (!item.description || !item.date) {
          message.push('Fill in the project status tables');
          failSections['project_status'] = true;
          return;
        }
      });
    values.budgets
      .filter((item) => !item._destroy)
      .forEach((item) => {
        if (item.project_report_budget_category_id === 1) {
          if (
            !item.description ||
            !item.current_cost ||
            !item.original_cost ||
            (item.children &&
              item.children.some(
                (i) => !i.description || !i.current_cost || !i.original_cost
              ))
          ) {
            message.push('Fill in the budget summary table');
            failSections['budget'] = true;
            return;
          }
        }
        if (item.project_report_budget_category_id === 2) {
          if (
            !item.contract_amount ||
            !item.paid_to_date_amount ||
            (item.children &&
              item.children.some(
                (i) => !i.contract_amount || !i.paid_to_date_amount
              ))
          ) {
            message.push('Fill in the pay to date table');
            failSections['budget'] = true;
            return;
          }
        }
      });
    if (!values.completion_date) {
      failSections['schedule'] = true;
      message.push('Fill in projected completion');
    }
    this.setState({ failSections: failSections });
    return message;
  };

  onReview = () => {
    const { values, projectId, projectReportId, dispatch } = this.props;

    dispatch(loadProjectReportRequest(projectId, projectReportId));

    if (!values.number) this.setState({ showErrorModal: true });
    else this.setState({ showReviewModal: true });
  };

  onPreview = () => {
    const { values, projectId, projectReportId, dispatch } = this.props;

    dispatch(loadProjectReportRequest(projectId, projectReportId));
    dispatch(previewProjectReport(projectId, projectReportId, values));
  };

  onClose = () => {
    const { projectId, match, history, dispatch } = this.props;
    const subsection = match.params.subsection;

    history.push(`/admin/projects/${projectId}/reporting/${subsection}`);

    dispatch(setProjectReportOpenDetails({ value: false, id: 0 }));
    dispatch(loadProjectReportsRequest(projectId, 1, ''));
  };

  onFinishUpload = () => {
    this.setState({ showFileUpload: false });
    if (this.state.reviewing) this.onReview();
    else if (this.state.previewing) this.onPreview();
    else this.onClose();
  };

  hasFilesToUpload = () => {
    const { values } = this.props;

    return (
      typeof (values.images || []).find((i) => !i.id) !== 'undefined' ||
      typeof (values.documents || []).find((i) => !i.id) !== 'undefined'
    );
  };

  onFinishSave = ({ ok }) => {
    if (!ok) return;

    if (this.hasFilesToUpload()) this.setState({ showFileUpload: true });
    else this.onFinishUpload();
  };

  onSave = (status = 'draft', previewing = false, reviewing = false) => {
    const {
      values,
      projectId,
      projectReportId,
      number,
      dispatch,
      user,
    } = this.props;

    const errors = this.validate(values);
    this.setState({
      showPublishModal: false,
      missingField: errors.length > 0,
      previewing,
      reviewing,
    });

    if (status === 'published' && errors.length > 0) {
      this.setState({ missingField: true });
      dispatch(showErrorMessage('There are missing fields, please review.'));
      return;
    }

    if (projectReportId > 0) {
      dispatch(
        updateProjectReports(
          projectId,
          projectReportId,
          values,
          status,
          user.id
        )
      ).then(this.onFinishSave);
    } else {
      dispatch(
        addProjectReports(
          projectId,
          values,
          number,
          status,
          user.id,
          previewing
        )
      ).then(this.onFinishSave);
    }
  };

  handleClosePreview = () => {
    const { dispatch } = this.props;
    dispatch(setProjectReportPreview());
  };

  handleSendToReview = (emails) => {
    const { values, projectId, projectReportId, dispatch } = this.props;
    dispatch(
      sendToReviewProjectReport(projectId, projectReportId, emails, values)
    );
  };

  onItemHandle = (item) => {
    const { history, match } = this.props;
    const { projectId, section, subsection } = match.params;
    history.push(
      `/admin/projects/${projectId}/${section}/${subsection}/new/${item.key}`
    );

    this.setState({ currentItemMenu: item });
    simpleScrollToTop();
  };

  renderSectionContent = () => {
    const {
      project,
      projectId,
      projectReportId,
      projectReportPermissions,
      number,
    } = this.props;
    const { missingField } = this.state;
    switch (this.state.currentItemMenu.key) {
      case itemsMenu[0].key:
        return <ReportInformationForm permissions={projectReportPermissions} />;
      case itemsMenu[1].key:
        return (
          <ProjectSummaryForm
            projectId={projectId}
            projectReportId={projectReportId}
          />
        );
      case itemsMenu[2].key:
        return (
          <ProjectStatusForm
            projectId={projectId}
            projectReportId={projectReportId}
            missingField={missingField}
            number={number}
          />
        );
      case itemsMenu[3].key:
        return (
          <BudgetForm
            projectId={projectId}
            projectReportId={projectReportId}
            sqFootage={project.sq_footage}
            isNewReport={number}
            missingField={missingField}
          />
        );
      case itemsMenu[4].key:
        return (
          <ScheduleForm
            projectId={projectId}
            projectReportId={projectReportId}
            isNewReport={number}
            missingField={missingField}
          />
        );
      case itemsMenu[5].key:
        return (
          <PhotosDocumentsForm
            projectId={projectId}
            projectReportId={projectReportId}
            missingField={missingField}
          />
        );
      case itemsMenu[6].key:
        return <DisclaimerForm />;
      default:
        return null;
    }
  };

  render() {
    const {
      number,
      darkMode,
      values,
      projectReportPreview,
      onClose,
      projectReportId,
    } = this.props;
    const {
      showSaveDraftModal,
      showErrorModal,
      showsubmittedModal,
      showPublishModal,
      showReviewModal,
      showFileUpload,
      failSections,
    } = this.state;
    const reportNumber = number || values.number;

    return (
      <div className="add-project-report-log">
        <div className="add-project-report-menu-container">
          <div className="menu-title primary-text-color">
            Project Report{' '}
            {reportNumber < 10 ? `0${reportNumber}` : reportNumber}
          </div>
          <div className="menu-item-container">
            {itemsMenu.map((item) => (
              <div
                key={item.key}
                className={classnames({
                  'menu-item': true,
                  'menu-item-active':
                    item.key === this.state.currentItemMenu.key,
                  'menu-item-fail': failSections[item.key],
                })}
                onClick={this.onItemHandle.bind(null, item)}
              >
                {item.label}
              </div>
            ))}
          </div>
          <div className="menu-buttons-container">
            <div className="menu-button">
              <Button
                className="white-button-background-project-reports"
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.WHITE}
                label="Cancel"
                onClick={() => this.setState({ showSaveDraftModal: true })}
              />
            </div>
            <div className="menu-button">
              <Button
                className="white-button-background-project-reports"
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.WHITE}
                label="PDF Preview"
                onClick={this.onSave.bind(null, 'draft', true, false)}
              />
            </div>
            <div className="menu-button">
              <Button
                className="white-button-background-project-reports"
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.WHITE}
                label="Save Draft"
                onClick={this.onSave.bind(null, 'draft', false, false)}
              />
            </div>
            <div className="menu-button">
              <Button
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.GREEN}
                label="Send Review"
                onClick={this.onSave.bind(null, 'draft', false, true)}
              />
            </div>
            <div className="menu-button">
              <Button
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.GREEN}
                label="Publish"
                onClick={() => this.setState({ showPublishModal: true })}
              />
            </div>
          </div>
        </div>
        <div
          className={classnames({
            'add-project-report-form-container': true,
            'missing-fields': this.state.missingField,
          })}
        >
          <div className="title-form primary-text-color">
            {this.state.currentItemMenu.label}
          </div>
          {this.renderSectionContent()}
        </div>

        <ProjectReportSaveDraftModal
          show={showSaveDraftModal}
          onClose={() => {
            this.setState({ showSaveDraftModal: false });
            onClose();
          }}
          onCloseModal={() => this.setState({ showSaveDraftModal: false })}
          onConfirm={this.onSave.bind(null, 'draft', false, false)}
        />
        <ProjectReportErrorModal
          show={showErrorModal}
          error="Please save a draft of your report prior to sending it for review."
          onClose={() => this.setState({ showErrorModal: false })}
        />
        <ProjectReportPublishModal
          show={showPublishModal}
          onClose={() => this.setState({ showPublishModal: false })}
          onConfirm={this.onSave.bind(null, 'published', false, false)}
        />
        <ProjectReportSubmittedReviewModal
          show={showsubmittedModal}
          onClose={() => this.setState({ showsubmittedModal: false })}
          darkMode={darkMode}
        />
        <ProjectReportSendReviewModal
          emails={this.recipientEmails() || []}
          show={showReviewModal}
          onClose={() => this.setState({ showReviewModal: false })}
          onSend={this.handleSendToReview}
        />
        {showFileUpload && (
          <DirectUploadFiles
            files={[
              ...(values.images || [])
                .filter((img) => !img.id)
                .map(({ file, description, position }) => ({
                  file,
                  description,
                  position,
                  label: null,
                  subModel: 'image',
                })),
              ...(values.documents || [])
                .filter((doc) => !doc.id)
                .map(({ file, position }) => ({
                  file,
                  position,
                  label: null,
                  subModel: 'doc',
                })),
            ]}
            model="project_report"
            identifier={projectReportId}
            onComplete={this.onFinishUpload}
          />
        )}

        {projectReportPreview &&
          (projectReportPreview === 'loading' ? (
            <MessageModal
              show={true}
              message={<div>Making PDF, please wait...</div>}
              buttons={[]}
            />
          ) : (
            <PdfViewer
              content={{
                source: projectReportPreview,
                type: 'url',
              }}
              show={true}
              onClose={this.handleClosePreview}
            />
          ))}
      </div>
    );
  }
}

AddProjectReportLog.propTypes = {
  projectId: PropTypes.number.isRequired,
  projectReportId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
};

export default withRouter(
  connect(
    (state) => ({
      darkMode: getDarkMode(state),
      values: getProjectReportValue(state),
      directory: getDirectory(state),
      project: getCurrentProject(state),
      projectReportPermissions: getprojectReportsPermissions(state),
      lastProjectReport: getLastProjectReport(state),
      projectReportPreview: getProjectReportPreview(state),
      initialTab: getProjectReportInitialTab(state),
      user: state.auth.user,
    }),
    null
  )(AddProjectReportLog)
);
