import {
  WORK_ORDERS_DETAILS_CLIENT_STREAM_MODAL_SHOW,
  WORK_ORDERS_DETAILS_NAV_BAR_SELECTION,
  WORK_ORDERS_DETAILS_OPEN_ITEM_SELECTED,
  WORK_ORDERS_DETAILS_PRE_EXISTING_CONDITIONS_CHANGE,
  WORK_ORDERS_DETAILS_PRE_EXISTING_CONDITIONS_DESCRIPTION_CHANGE,
  WORK_ORDERS_DETAILS_PRE_EXISTING_CONDITIONS_RESET,
  WORK_ORDERS_DETAILS_QUOTES_TABLE_FILTER_CHANGE,
  WORK_ORDERS_DETAILS_STATE_INIT,
  WORK_ORDERS_DETAILS_TASK_CANCEL,
  WORK_ORDERS_DETAILS_TASK_EDIT,
  WORK_ORDERS_DETAILS_WORK_ORDER_NAME_CHANGE,
  WORK_ORDERS_DETAILS_WORK_ORDER_NAME_EDIT,
  WORK_ORDERS_DETAILS_WORK_ORDER_NAME_SAVING,
  WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_CHANGE,
  WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_REMINDER_CHANGE,
  WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_EDIT,
  WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_SAVING,
  WORK_ORDERS_TASK_UPDATE_SUCCESS,
  WORK_ORDERS_WO_SELECT_SUCCESS,
  WORK_ORDERS_DETAILS_TASK_SELECT_SET,
  WORK_ORDERS_DETAILS_TASK_SELECT_CLEAR,
  WORK_ORDERS_DETAILS_WORK_ORDER_JOB_NUMBER_CHANGE,
  WORK_ORDERS_DETAILS_WORK_ORDER_JOB_NUMBER_EDIT,
  WORK_ORDERS_DETAILS_WORK_ORDER_JOB_NUMBER_SAVING,
  WORK_ORDERS_DETAILS_SHOW_WOA_SIGNING_MODAL_SET,
  WORK_ORDERS_DETAILS_WOA_SIGNING_URL_SET,
} from 'actions/admin/work-orders/action-types';

const initialState = {
  editTask: false,
  navigationBarSelectedItem: 1,
  preExistingConditions: null, // boolean
  preExistingConditionsDescription: '',
  quotesTableSelectedFilter: 'all',
  selectedOpenItemId: null,
  showClientStreamModal: false,
  workOrderJobNumberEdit: false,
  workOrderJobNumberSaving: false,
  workOrderJobNumberValue: '',
  workOrderNameEdit: false,
  workOrderNameSaving: false,
  workOrderNameValue: '',
  workOrderNoteEdit: false,
  workOrderNoteSaving: false,
  workOrderNoteValue: '',
  workOrderNoteReminderValue: null,
  selectAllChecked: false,
  selectedTasks: {},
  showWoaSigningModal: false,
  woaSigningUrl: null,
};

export default function detailsUiReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_DETAILS_CLIENT_STREAM_MODAL_SHOW:
      return {
        ...state,
        showClientStreamModal: action.payload,
      };

    case WORK_ORDERS_DETAILS_OPEN_ITEM_SELECTED:
      return {
        ...state,
        selectedOpenItemId: action.payload !== null ? action.payload.id : null,
      };

    case WORK_ORDERS_DETAILS_PRE_EXISTING_CONDITIONS_CHANGE:
      return {
        ...state,
        preExistingConditions: action.payload,
      };

    case WORK_ORDERS_DETAILS_PRE_EXISTING_CONDITIONS_DESCRIPTION_CHANGE:
      return {
        ...state,
        preExistingConditionsDescription: action.payload,
      };

    case WORK_ORDERS_DETAILS_PRE_EXISTING_CONDITIONS_RESET:
      return {
        ...state,
        preExistingConditions: null,
        preExistingConditionsDescription: '',
      };

    case WORK_ORDERS_DETAILS_TASK_EDIT:
      return {
        ...state,
        editTask: true,
      };

    case WORK_ORDERS_DETAILS_TASK_CANCEL:
      return {
        ...state,
        editTask: false,
      };

    case WORK_ORDERS_TASK_UPDATE_SUCCESS:
      return {
        ...state,
        editTask: false,
      };

    case WORK_ORDERS_DETAILS_STATE_INIT:
      return {
        ...state,
        editTask: false,
        navigationBarSelectedItem: 1,
        quotesTableSelectedFilter: 'all',
      };

    case WORK_ORDERS_DETAILS_NAV_BAR_SELECTION:
      return {
        ...state,
        editTask: false,
        navigationBarSelectedItem: action.payload,
      };

    case WORK_ORDERS_DETAILS_QUOTES_TABLE_FILTER_CHANGE:
      return {
        ...state,
        quotesTableSelectedFilter: action.payload,
      };

    case WORK_ORDERS_DETAILS_WORK_ORDER_JOB_NUMBER_CHANGE:
      return {
        ...state,
        workOrderJobNumberValue: action.payload,
      };

    case WORK_ORDERS_DETAILS_WORK_ORDER_JOB_NUMBER_EDIT:
      return {
        ...state,
        workOrderJobNumberEdit: action.payload,
      };

    case WORK_ORDERS_DETAILS_WORK_ORDER_JOB_NUMBER_SAVING:
      return {
        ...state,
        workOrderJobNumberSaving: action.payload,
      };

    case WORK_ORDERS_DETAILS_WORK_ORDER_NAME_CHANGE:
      return {
        ...state,
        workOrderNameValue: action.payload,
      };

    case WORK_ORDERS_DETAILS_WORK_ORDER_NAME_EDIT:
      return {
        ...state,
        workOrderNameEdit: action.payload,
      };

    case WORK_ORDERS_DETAILS_WORK_ORDER_NAME_SAVING:
      return {
        ...state,
        workOrderNameSaving: action.payload,
      };

    case WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_CHANGE:
      return {
        ...state,
        workOrderNoteValue: action.payload,
      };

    case WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_REMINDER_CHANGE:
      return {
        ...state,
        workOrderNoteReminderValue: action.payload,
      };

    case WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_EDIT:
      return {
        ...state,
        workOrderNoteEdit: action.payload,
        workOrderNoteReminderValue: null,
      };

    case WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_SAVING:
      return {
        ...state,
        workOrderNoteSaving: action.payload,
      };

    case WORK_ORDERS_WO_SELECT_SUCCESS:
      if (action.payload !== null) {
        return {
          ...state,
          workOrderJobNumberEdit: false,
          workOrderNameEdit: false,
          workOrderNoteEdit: false,
          workOrderJobNumberValue:
            action.payload.work_order_job !== null &&
            action.payload.work_order_job !== undefined
              ? action.payload.work_order_job.number
              : '',
          workOrderNameValue:
            action.payload.name !== null ? action.payload.name : '',
          workOrderNoteValue:
            action.payload.note !== null ? action.payload.note : '',
        };
      } else {
        return state;
      }

    case WORK_ORDERS_DETAILS_TASK_SELECT_SET:
      return {
        ...state,
        selectAllChecked: action.payload.allChecked,
        selectedTasks: action.payload.selectedTasks,
      };

    case WORK_ORDERS_DETAILS_TASK_SELECT_CLEAR:
      return {
        ...state,
        selectAllChecked: initialState.selectAllChecked,
        selectedTasks: initialState.selectedTasks,
      };

    case WORK_ORDERS_DETAILS_WOA_SIGNING_URL_SET:
      return {
        ...state,
        woaSigningUrl: action.payload,
        showWoaSigningModal: true,
      };

    case WORK_ORDERS_DETAILS_SHOW_WOA_SIGNING_MODAL_SET:
      return {
        ...state,
        showWoaSigningModal: false,
      };

    default:
      return state;
  }
}
