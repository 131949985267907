import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import './NavigationBar.css';
import NavigationItem from './NavigationItem';

class NavigationBar extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        show: PropTypes.bool,
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        class: PropTypes.string,
        render: PropTypes.func,
      })
    ).isRequired,
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSelected: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  static defaultProps = { disabled: false };

  constructor(props) {
    super(props);

    // The items will not change during the execution, so it's ok to set the state based on props in the constructor
    this.state = {
      idSelected:
        props.selectedValue || (props.items.length > 0 ? props.items[0].id : 0),
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      newProps.selectedValue &&
      newProps.selectedValue !== this.state.idSelected
    ) {
      this.setState({
        idSelected: newProps.selectedValue,
      });
    }
  }

  handleItemSelect = (value) => {
    this.setState({
      idSelected: value,
    });
    this.props.onSelected(value);
  };

  render() {
    const { darkMode } = this.props;

    return (
      <div className={`navigation-bar ${darkMode ? 'dark-mode' : ''}`}>
        {this.props.items
          .filter((element) => element.show === undefined || element.show)
          .map((element) => {
            if (typeof element.render !== 'undefined')
              return (
                <div key={element.id} className="navigation-item">
                  {element.render({
                    onSelected: this.props.disabled
                      ? () => {}
                      : this.handleItemSelect,
                    selected: element.id === this.state.idSelected,
                    element,
                  })}
                </div>
              );
            return (
              <NavigationItem
                key={element.id}
                id={element.id}
                title={element.title}
                total={element.total}
                selected={element.id === this.state.idSelected}
                itemClass={element.class}
                onSelected={
                  this.props.disabled ? () => {} : this.handleItemSelect
                }
              />
            );
          })}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(NavigationBar);
