import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';

class CloseoutManualsApi extends BaseApi {
  getCloseoutManuals(projectId, page = 1) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/closeout/closeout_manuals?page=${page}`
      )
      .then((response) => {
        return {
          ok: true,
          closeoutManuals: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getCloseoutManual(projectId, id) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/closeout/closeout_manuals/${id}`,
        { loadingDisabled: true }
      )
      .then((response) => {
        return {
          ok: true,
          closeoutManual: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createCloseoutManual(projectId) {
    return axios
      .post(`${this.baseUrl}/projects/${projectId}/closeout/closeout_manuals`)
      .then((response) => {
        return {
          ok: true,
          closeoutManual: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  deleteCloseoutManual(projectId, id) {
    return axios
      .delete(
        `${this.baseUrl}/projects/${projectId}/closeout/closeout_manuals/${id}`
      )
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const closeoutManualsApi = new CloseoutManualsApi();
