import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import SubmittalDocuments from 'components/admin/projects/details/project-submittal-log/SubmittalDocuments';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import ReadOnlyInput from 'components/shared/input/ReadOnlyInput';

import './SubmittalResponsesViewModal.css';
import User from '../../../../../domain/user';

import { getSubmittalForm } from './store/selectors';
export const SubmittalSingleResponse = (props) => {
  const {
    form,
    canAddRemarks,
    currentUser,
    submittal,
    handleFormChange,
    handleFormDeleteFile,
    response,
  } = props;

  const makeDate = (date) => {
    return date
      ? ` on ${moment(date).format('MM/DD/YYYY')} at ${moment(date).format(
          'hh:mm A'
        )}`
      : '';
  };
  const makeResponseLabel = (user, date) => {
    return (
      <ReadOnlyInput
        value={`${User.fullNameWithCompany(user)}${makeDate(date)}`}
      />
    );
  };

  return (
    <>
      <div className="submittal-response-modal-content-container">
        {
          <div className="form-block">
            <FormControlBlock
              className="highlighted"
              label="Responded By"
              control={makeResponseLabel(
                response.assignee,
                response.returned_at
              )}
              readOnly
            />
          </div>
        }
        <div className="form-block">
          <FormControlBlock
            className="highlighted"
            label="Responded By"
            control={makeResponseLabel(response.assignee, response.returned_at)}
            readOnly
          />
        </div>

        <div className="form-block">
          <FormControlBlock
            label="Stamp"
            control={<ReadOnlyInput value={response.stamp_title} />}
            readOnly
          />
        </div>
        <div className="form-block">
          <FormControlBlock
            label={(!canAddRemarks || !!form.remarks) && 'Remarks'}
            control={
              <TextareaAutosizeWrapper
                placeholder="Remarks"
                disabled={true}
                initialValue={response.remarks}
                onChange={() => {}}
              />
            }
            readOnly
          />
        </div>
        <SubmittalDocuments
          currentUser={currentUser}
          submittal={submittal}
          form={form}
          fileType="res"
          handleFormChange={handleFormChange}
          handleFormDeleteFile={handleFormDeleteFile}
          readOnly={true}
        />
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    form: getSubmittalForm(state),
  };
})(SubmittalSingleResponse);
