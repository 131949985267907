import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './ProjectOpenItemsTabs.css';
import NavigationBar from 'components/shared/navigation/NavigationBar';
import ProjectOpenItems from 'components/admin/project-open-items/ProjectOpenItems';
import ProjectOpenItemsCritical from 'components/admin/project-open-items/ProjectOpenItemsCritical';

class ProjectOpenItemsTabs extends Component {
  static propTypes = {
    filters: PropTypes.shape(),
    filtersCritical: PropTypes.shape(),
  };

  static defaultProps = {
    filters: {},
    filtersCritical: {},
  };

  constructor(props) {
    super(props);

    this.state = { tab: 1 };
  }

  handleTabSelection = (tab) => {
    this.setState({ tab: tab });
  };

  render() {
    const { filters, filtersCritical } = this.props;
    const { tab } = this.state;
    return (
      <div className="project-open-items-tabs">
        <div className="project-open-items-tabs-header">
          <NavigationBar
            items={[
              {
                id: 1,
                title: 'Overdue',
              },
              {
                id: 2,
                title: 'Critical',
              },
            ]}
            selectedValue={tab}
            onSelected={this.handleTabSelection}
          />
        </div>

        {tab === 1 && <ProjectOpenItems filters={filters} />}
        {tab === 2 && <ProjectOpenItemsCritical filters={filtersCritical} />}
      </div>
    );
  }
}

export default connect((state) => {
  return {};
})(ProjectOpenItemsTabs);
