import {
  INVOICES_INVOICE_CUSTOM_EMAIL_CHANGE,
  INVOICES_INVOICE_CUSTOM_EMAIL_VALIDATION_SET,
  INVOICES_INVOICE_EDIT_CANCEL,
  INVOICES_INVOICE_EDIT_SET,
  INVOICES_INVOICE_EMAIL_MODAL_SHOW_SET,
  INVOICES_INVOICE_EMAILS_REMOVE,
  INVOICES_INVOICE_EMAILS_USERS_ADD,
  INVOICES_INVOICE_FORM_ATTRIBUTE_CHANGE,
  INVOICES_INVOICE_FORM_INVALID,
  INVOICES_INVOICE_FORM_ITEM_LINE_CHANGE,
  INVOICES_INVOICE_FORM_VALID,
  INVOICES_INVOICE_LOAD_SUCCESS,
  INVOICES_INVOICE_FORM_MISC_COST_ADD,
  INVOICES_INVOICE_FORM_MISC_COST_CLEAR,
  INVOICES_INVOICE_FORM_MISC_COST_REMOVE,
  INVOICES_INVOICE_PDF_VIEWER_CLOSE,
  INVOICES_INVOICE_PRINT_SUCCESS,
  INVOICES_INVOICE_SAVE_SHOW,
  INVOICES_INVOICE_USER_SELECT,
  INVOICES_PAGE_CHANGE,
  INVOICES_TAB_SELECTION_CHANGE,
  INVOICES_SELECTED_STATUS_SET,
} from 'actions/admin/invoices/action-types';
import numeral from 'numeral';

const initialState = {
  currentPage: 1,
  customEmail: '',
  customEmailValid: true,
  edit: false,
  form: {
    additionalCostsByLineItemId: {},
    adjustmentDesc: '',
    adjustment: '',
    adjustmentSign: 1,
    amountsByLineItemId: {}, // [lineItemId]: value
    dueDate: '',
    emails: [], // [ { id: number, email: string } ]
    miscCosts: [], // [ { id: number, description: string, cost: string } ]
  },
  invalidFields: [],
  pdf: null,
  showEmailModal: false,
  showPdfViewer: false,
  showSave: false,
  validationMessage: '',
  usersIdSelected: [],
  tabSelection: 1,
  selectedStatus: 'all',
};

export default function invoicesUiReducer(state = initialState, action) {
  switch (action.type) {
    case INVOICES_INVOICE_CUSTOM_EMAIL_CHANGE:
      return {
        ...state,
        customEmail: action.payload,
      };

    case INVOICES_INVOICE_CUSTOM_EMAIL_VALIDATION_SET:
      return {
        ...state,
        customEmailValid: action.payload,
      };

    case INVOICES_INVOICE_EDIT_SET:
      return {
        ...state,
        customEmail: '',
        customEmailValid: true,
        edit: action.payload,
        usersIdSelected: [],
      };

    case INVOICES_INVOICE_EMAIL_MODAL_SHOW_SET:
      return {
        ...state,
        showEmailModal: action.payload,
      };

    case INVOICES_INVOICE_EMAILS_REMOVE:
      let newEmails;
      if (action.payload.id === 0) {
        newEmails = state.form.emails.filter(
          (email, index) => index !== action.payload.index
        );
      } else {
        newEmails = state.form.emails.filter(
          (email) => email.id !== action.payload.id
        );
      }

      return {
        ...state,
        form: {
          ...state.form,
          emails: newEmails,
        },
      };

    case INVOICES_INVOICE_FORM_ATTRIBUTE_CHANGE:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case INVOICES_INVOICE_FORM_ITEM_LINE_CHANGE:
      return {
        ...state,
        form: {
          ...state.form,
          amountsByLineItemId: {
            ...state.form.amountsByLineItemId,
            [action.payload.itemId]: action.payload.value,
          },
        },
      };

    case INVOICES_INVOICE_FORM_INVALID:
      return {
        ...state,
        invalidFields: action.payload.invalidLineItemId,
        validationMessage: action.payload.validationMessage,
      };

    case INVOICES_INVOICE_FORM_VALID:
      return {
        ...state,
        invalidFields: [],
        validationMessage: '',
      };

    case INVOICES_INVOICE_EDIT_CANCEL:
    case INVOICES_INVOICE_LOAD_SUCCESS:
      return {
        ...state,
        edit: false,
        form: {
          additionalCostsByLineItemId: action.payload.line_items.reduce(
            (accumulator, currentValue) => {
              accumulator[currentValue.id] = currentValue.additional_cost;
              return accumulator;
            },
            {}
          ),
          adjustmentDesc: action.payload.adjustment_desc || '',
          adjustment: action.payload.adjustment,
          adjustmentSign:
            numeral(action.payload.adjustment).value() >= 0 ? 1 : -1,
          amountsByLineItemId: action.payload.line_items.reduce(
            (accumulator, currentValue) => {
              accumulator[currentValue.id] = currentValue.cost;
              return accumulator;
            },
            {}
          ),
          dueDate: action.payload.due_date,
          emails: action.payload.send_to.map((value) => {
            return {
              id: 0,
              email: value,
            };
          }),
          purchaseOrder:
            action.payload.purchase_order !== null
              ? action.payload.purchase_order
              : '',
          miscCosts: action.payload.misc_items.map((miscItem) => {
            return {
              id: miscItem.id,
              cost: miscItem.cost,
              description: miscItem.description,
            };
          }),
        },
        invalidFields: [],
        validationMessage: '',
      };

    case INVOICES_INVOICE_PDF_VIEWER_CLOSE:
      return {
        ...state,
        showPdfViewer: false,
      };

    case INVOICES_INVOICE_PRINT_SUCCESS:
      return {
        ...state,
        pdf: action.payload,
        showPdfViewer: true,
      };

    case INVOICES_INVOICE_FORM_MISC_COST_ADD:
      return {
        ...state,
        form: {
          ...state.form,
          miscCosts: state.form.miscCosts.concat([
            {
              id: action.payload.id,
              description: action.payload.description,
              cost: action.payload.cost,
            },
          ]),
        },
      };

    case INVOICES_INVOICE_FORM_MISC_COST_CLEAR:
      return {
        ...state,
        form: {
          ...state.form,
          miscCosts: [],
        },
      };

    case INVOICES_INVOICE_FORM_MISC_COST_REMOVE:
      return {
        ...state,
        form: {
          ...state.form,
          miscCosts: state.form.miscCosts.filter(
            (item, index) => index !== action.payload
          ),
        },
      };

    case INVOICES_INVOICE_SAVE_SHOW:
      return {
        ...state,
        showSave: action.payload,
      };

    case INVOICES_INVOICE_USER_SELECT:
      return {
        ...state,
        usersIdSelected: action.payload,
      };

    case INVOICES_INVOICE_EMAILS_USERS_ADD:
      return {
        ...state,
        form: {
          ...state.form,
          emails: state.form.emails.concat(action.payload),
        },
        usersIdSelected: [],
      };

    case INVOICES_PAGE_CHANGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case INVOICES_TAB_SELECTION_CHANGE:
      return {
        ...state,
        tabSelection: action.payload,
      };

    case INVOICES_SELECTED_STATUS_SET:
      return {
        ...state,
        selectedStatus: action.payload,
      };

    default:
      return state;
  }
}
