import React from 'react';
import { connect } from 'react-redux';

import ProjectEmailModal from 'components/admin/projects/details/project-email-modal/ProjectEmailModal';

import { getCurrentFieldReport } from './store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/selectors';
import { sendEmailFieldReport } from 'components/admin/projects/details/project-daily-report-log/field-reports/store/actions';

const FieldReportEmailModal = (props) => {
  const {
    dispatch,
    currentProject,
    showEmailModal,
    currentFieldReport,
  } = props;

  const handleSendEmail = (emailsData) => {
    dispatch(
      sendEmailFieldReport(
        currentProject.id,
        currentFieldReport.id,
        emailsData.recipients,
        emailsData.subject,
        emailsData.message
      )
    );
  };

  const buildEmailModalSubject = () => {
    return `${currentProject.number} ${currentProject.name} - Field Report ${currentFieldReport.formatted_number} ${currentFieldReport.title}`;
  };

  const buildEmailModalMessage = () => {
    let location = '';
    if (
      currentProject.project_location &&
      currentProject.project_location.location
    ) {
      const projectLocation = currentProject.project_location.location;
      location = ` at ${projectLocation.street_address_1}, ${projectLocation.city} ${projectLocation.state}`;
    }

    return `Please find the attached Field Report ${currentFieldReport.formatted_number} for Project ${currentProject.name}${location}. If you have any questions, please reach out via chat or email.`;
  };

  if (!showEmailModal) return null;

  return (
    <ProjectEmailModal
      subTitle={currentProject.name}
      projectId={currentProject.id}
      handleSendEmail={handleSendEmail}
      initialSubject={buildEmailModalSubject()}
      initialMessage={buildEmailModalMessage()}
      itemType="FieldReport"
      itemId={currentFieldReport.id}
    />
  );
};

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    currentFieldReport: getCurrentFieldReport(state),
    showEmailModal: getShowEmailModal(state),
  };
})(FieldReportEmailModal);
