import React, { useMemo } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import Button, {
  BUTTON_COLORS,
  BUTTON_ICONS,
  BUTTON_TYPES,
} from 'components/shared/button/Button';
import CollapsibleSection from 'components/shared/collapsible-section/CollapsibleSection';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import Menu, { MENU_SELECTOR } from 'components/shared/menu/Menu';
import Selector from '../../../../shared/selector/Selector';
import DragableTable from 'components/shared/table/DragableTable';
import DatePicker from 'components/shared/date-picker/DatePicker';
import EditDarkIcon from 'images/editable-pencil-dark.svg';
import EditIcon from 'images/editable-pencil.svg';

import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import { calculateDateDuration } from 'services/utils/date-util';

import useSchedulesCategory from './SchedulesCategory.hook';
import { getProjectScheduleFilters } from './store/selectors';

import './SchedulesCategory.css';

const SchedulesCategory = (props) => {
  const { category, filters, directory } = props;
  const {
    schedules,
    isCollapsed,
    canResolve,
    canDelete,
    canAdd,
    canPublish,
    canEmail,
    canExport,
    canEdit,
    canArchive,
    onOrderChange,
    onRowValueChange,
    onRowChange,
    onResolveSchedule,
    onDeleteSchedule,
    addSchedule,
    viewResolved,
    publishPdf,
    editCategory,
    archiveCategory,
    sendEmail,
    exportCategory,
    persistCloseCategories,
    isNewSchedule,
    onImportFromExcel,
    getResponsibleOptions,
  } = useSchedulesCategory(props);

  // rows
  const descriptionInput = (
    value,
    onBlur,
    onPaste,
    showError,
    readOnly,
    clear = false
  ) => {
    return (
      <TextareaAutosizeWrapper
        maxRows={2}
        clear={clear}
        onBlur={onBlur}
        onPaste={onPaste}
        autoFocus={!value}
        readOnly={readOnly}
        value={value}
        placeholder="Description"
        valid={showError && !!value}
        className="schedule-text-area"
      />
    );
  };
  const dateSelector = (val, onChange, readOnly = false) => {
    const d = moment(val);
    const value = d.isValid() ? d.utc().format('YYYY-MM-DD') : null;
    return (
      <DatePicker
        clearable
        hideUnderline
        value={value}
        placeholder={'--'}
        onChange={onChange}
        readOnly={readOnly}
        className={'schedule-datetime-picker'}
        calendarIcon={EditIcon}
        calendarIconDark={EditDarkIcon}
      />
    );
  };
  const responsibleSelector = (row, onChange, readOnly = false) => {
    return (
      <Selector
        clearable
        hideUnderline
        placeholder="--"
        readOnly={readOnly}
        className="responsible-dropdown"
        options={getResponsibleOptions()}
        value={{
          mainValue: row.responsible_company,
          subValue: row.responsible,
        }}
        onChange={({
          mainValue: responsible_company,
          subValue: responsible,
        }) => {
          onChange({ ...row, responsible_company, responsible });
        }}
      />
    );
  };

  const getRowActions = (row, i) => {
    const actions = [];
    const isNew = isNewSchedule(row);

    if (!isNew && canResolve) {
      actions.push({
        label: 'Resolve',
        action: () => onResolveSchedule(row, i),
      });
    }

    if (canDelete) {
      actions.push({ label: 'Delete', action: () => onDeleteSchedule(row) });
    }
    return (
      <div className="actions">
        {actions.map((a) => (
          <Button
            key={`action_${row.id}_${a.label}`}
            type={BUTTON_TYPES.LINK}
            color={BUTTON_COLORS.GREEN}
            label={a.label}
            onClick={a.action}
          />
        ))}
      </div>
    );
  };
  const getRow = (row, i) => {
    const isNew = isNewSchedule(row);
    const hasError = row.errors && Object.keys(row.errors).length > 0;

    return {
      id: isNew ? 0 : row.id,
      className: `${hasError ? 'schedule-error' : ''}`,
      disabled: isNew,
      cells: [
        descriptionInput(
          row.description,
          onRowValueChange('description', row, i),
          onImportFromExcel(row, i),
          hasError,
          !canEdit,
          row.copied
        ),
        calculateDateDuration(row.current_start, row.current_completion),
        dateSelector(
          row.original_start,
          onRowValueChange('original_start', row, i),
          !canEdit
        ),
        dateSelector(
          row.previous_start,
          onRowValueChange('previous_start', row, i),
          !canEdit
        ),
        dateSelector(
          row.current_start,
          onRowValueChange('current_start', row, i),
          !canEdit
        ),
        calculateDateDuration(row.original_start, row.current_start),
        dateSelector(
          row.original_completion,
          onRowValueChange('original_completion', row, i),
          !canEdit
        ),
        dateSelector(
          row.previous_completion,
          onRowValueChange('previous_completion', row, i),
          !canEdit
        ),
        dateSelector(
          row.current_completion,
          onRowValueChange('current_completion', row, i),
          !canEdit
        ),
        calculateDateDuration(row.original_completion, row.current_completion),
        responsibleSelector(row, onRowChange(i), !canEdit),
        getRowActions(row, i),
      ],
    };
  };
  const getRows = () => schedules.map((r, i) => getRow(r, i));

  // category
  const getCategoryActions = () => {
    const actions = [];

    const categoryHasUnsavedSchedule = schedules.reduce(
      (prev, curr) => prev || isNewSchedule(curr),
      false
    );

    if (canAdd && !categoryHasUnsavedSchedule) {
      actions.push({
        content: 'Add Row',
        onClick: addSchedule,
        type: 'add',
      });
    }

    actions.push({
      content: 'View Resolved',
      onClick: viewResolved,
      type: 'btn',
    });

    if (canPublish) {
      actions.push({
        content: 'Publish PDF',
        onClick: publishPdf,
        type: 'menu',
      });
    }

    if (canEmail) {
      const emailActions = [];
      emailActions.push({ content: 'PDF', onClick: sendEmail('pdf') });
      emailActions.push({ content: 'Excel', onClick: sendEmail('excel') });
      actions.push({ content: 'Email', type: 'menu', subitems: emailActions });
    }

    if (canExport) {
      const exportActions = [];
      exportActions.push({ content: 'PDF', onClick: exportCategory('pdf') });
      exportActions.push({
        content: 'Excel',
        onClick: exportCategory('excel'),
      });
      actions.push({
        content: 'Export',
        type: 'menu',
        subitems: exportActions,
      });
    }

    if (canEdit) {
      actions.push({
        content: 'Edit Info',
        onClick: editCategory,
        type: 'menu',
      });
    }

    if (canArchive) {
      actions.push({
        content: 'Archive',
        onClick: archiveCategory,
        type: 'menu',
      });
    }

    return (
      <div className="header-actions">
        {actions
          .filter((a) => a.type === 'btn' || a.type === 'add')
          .map((a, index) => (
            <Button
              key={`action_${category.id}_${index}`}
              type={BUTTON_TYPES.LINK}
              color={BUTTON_COLORS.GREEN}
              icon={a.type === 'add' ? BUTTON_ICONS.PLUS : undefined}
              label={a.content}
              onClick={a.onClick}
            />
          ))}

        <Menu
          key="more-actions"
          selector={MENU_SELECTOR.MORE_OPTIONS_VERTICAL}
          items={actions.filter((a) => a.type === 'menu')}
        />
      </div>
    );
  };

  const categoryActions = getCategoryActions();
  const rows = useMemo(() => getRows(), [schedules, directory]);

  return (
    <CollapsibleSection
      disabled={schedules.length === 0}
      visible={!isCollapsed}
      onShow={persistCloseCategories}
      title={category.name}
      iconPosition={'start'}
      className={'schedules-collapsible'}
      withContainer={false}
      right={categoryActions}
      showChevronDisabled
    >
      <DragableTable
        rows={rows}
        disabled={!!filters.search && filters.search.length > 0}
        onOrderChange={onOrderChange}
        className="schedules-table"
      />
    </CollapsibleSection>
  );
};

export default connect((state) => ({
  currentUser: getCurrentUser(state),
  project: getCurrentProject(state),
  filters: getProjectScheduleFilters(state),
  directory: getDirectory(state),
}))(SchedulesCategory);
