import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { isLoggedIn } from '../../selectors/authentication';

export const PrivateRouteLocal = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                referrer: rest.location.pathname,
                search: rest.location.search,
              }, // keep track of previous route to go after login
            }}
          />
        )
      }
    />
  );
};

export const PrivateRoute = connect((state) => {
  return {
    isLoggedIn: isLoggedIn(state),
  };
})(PrivateRouteLocal);
