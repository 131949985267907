import React, { Component } from 'react';
import { connect } from 'react-redux';
import './CoiExps.css';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getCoiExps,
  getCoiExpsTotal,
  getCoiExpsCurrentPage,
  getCoiExpsPageSize,
  getLiabilities,
} from 'components/admin/projects/details/project-coi-exps/store/selectors';
import {
  loadCoiExpsRequest,
  loadLiabilitiesRequest,
} from 'components/admin/projects/details/project-coi-exps/store/actions';
import NavigationBar from 'components/shared/navigation/NavigationBar';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import CoiExp from 'components/admin/projects/details/project-coi-exps/CoiExp';
import SimpleTable from 'components/shared/table/SimpleTable';
import { getDarkMode } from 'selectors/theme';
import CoiExpPermissions from 'permissions/coi-exp-permissions';

class CoiExps extends Component {
  componentDidMount() {
    this.handlePageChange(1);
    this.loadLiabilities();
  }

  handleNavSelect = () => {
    this.handlePageChange(1);
  };

  handlePageChange = (page) => {
    const { currentProject, dispatch } = this.props;
    if (currentProject) {
      dispatch(loadCoiExpsRequest(currentProject.id, page));
    }
  };

  loadLiabilities = () => {
    const { dispatch } = this.props;
    dispatch(loadLiabilitiesRequest());
  };

  renderHeaderButtons() {}

  render() {
    const {
      currentUser,
      currentProject,
      coiExps,
      coiExpsTotal,
      coiExpsCurrentPage,
      coiExpsPageSize,
      liabilities,
      renderHeaders,
      darkMode,
    } = this.props;

    const readOnly = !CoiExpPermissions.canEdit(currentUser, currentProject);
    const liabilitiesHeaders =
      liabilities && liabilities.map((liability) => liability.short_label);

    return (
      coiExps &&
      liabilities && (
        <div
          className={`content-container project-documentation ${
            darkMode ? 'dark-mode' : ''
          }`}
        >
          {renderHeaders(this.renderHeaderButtons())}
          <div className="coi-exps">
            <div className="coi-exps-nav">
              <NavigationBar
                items={[
                  {
                    id: 1,
                    title: 'COIs',
                  },
                ]}
                selectedValue={1}
                onSelected={this.handleNavSelect}
              />
            </div>
            <div className="coi-exps-table">
              <SimpleTable
                headers={['COMPANY NAME', ...liabilitiesHeaders]}
                headersOnly={true}
              />
              {coiExps.map((coiExp, index) => (
                <React.Fragment key={coiExp.id}>
                  <CoiExp coiExp={coiExp} readOnly={readOnly} index={index} />
                </React.Fragment>
              ))}
            </div>
            <div className="pagination-container">
              <SimplePagination
                currentPage={coiExpsCurrentPage}
                pageSize={coiExpsPageSize}
                total={coiExpsTotal}
                onPageChange={this.handlePageChange}
              />
            </div>
          </div>
        </div>
      )
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    coiExps: getCoiExps(state),
    coiExpsTotal: getCoiExpsTotal(state),
    coiExpsCurrentPage: getCoiExpsCurrentPage(state),
    coiExpsPageSize: getCoiExpsPageSize(state),
    liabilities: getLiabilities(state),
    darkMode: getDarkMode(state),
  };
})(CoiExps);
