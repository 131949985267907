import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import './DocumentLink.css';
import folderSvg from './folder.svg';
import folderDarkSvg from './folderDarkMode.svg';
import pdfSvg from './pdf.svg';
import pdfDarkSvg from './pdfDarkMode.svg';
import docSvg from './doc.svg';
import docDarkSvg from './docDarkMode.svg';
import xlsSvg from './xls.svg';
import xlsDarkSvg from './xlsDarkMode.svg';
import imageSvg from './image.svg';
import imageDarkSvg from './imageDarkMode.svg';
import removeSvg from 'images/deleteCross.svg';
import removeDarkSvg from 'images/deleteCrossDarkMode.svg';
import doneSvg from 'images/done.svg';
import doneDarkSvg from 'images/doneDarkMode.svg';
import waitingSvg from 'images/clock.svg';
import waitingDarkSvg from 'images/clockDarkMode.svg';
import uploadSvg from 'images/uploadSvg.svg';
import uploadDarkSvg from 'images/uploadSvgDark.svg';
import errorSvg from 'images/questionMarkGreen.svg';
import errorDarkSvg from 'images/questionMarkNeon.svg';

import { getLastTokenFromUrl } from 'services/utils/text-util';

function DocumentLink(props) {
  const getName = (props) => {
    let result = 'Unknown';
    if (props.url !== null) {
      const name = getLastTokenFromUrl(props.url);
      if (name !== null) {
        result = decodeURIComponent(name);
      }
    } else if (props.file !== null) {
      result = props.file.name;
    }
    return result;
  };

  const renderIcon = (icon, alt) => {
    return <img className="document-link-image" src={icon} alt={alt} />;
  };

  const getIcon = (props) => {
    if (props.showStatus) {
      let iconDark, icon;
      switch (props.status) {
        case 'WAITING':
          icon = waitingSvg;
          iconDark = waitingDarkSvg;
          break;
        case 'UPLOADING':
          icon = uploadSvg;
          iconDark = uploadDarkSvg;
          break;
        case 'ERROR':
          icon = errorSvg;
          iconDark = errorDarkSvg;
          break;
        default:
          icon = doneSvg;
          iconDark = doneDarkSvg;
          break;
      }

      return renderIcon(props.darkMode ? iconDark : icon, props.status);
    }

    if (props.file && props.file.folder) {
      return renderIcon(props.darkMode ? folderDarkSvg : folderSvg, 'folder');
    }

    let icon;
    let fileName = '';

    if (props.url !== null) {
      const tokens = props.url.split('/');
      if (tokens.length > 0) {
        fileName = tokens[tokens.length - 1];
      }
    } else if (props.file !== null) {
      fileName = props.file.name;
    }

    const fileNameTokes = fileName.split('.');
    let extension = null;
    if (fileNameTokes.length > 0) {
      extension = fileNameTokes[fileNameTokes.length - 1].toLowerCase();
    }
    switch (extension) {
      case 'pdf':
        icon = renderIcon(props.darkMode ? pdfDarkSvg : pdfSvg, 'pdf');
        break;

      case 'doc':
      case 'docx':
        icon = renderIcon(props.darkMode ? docDarkSvg : docSvg, 'doc');
        break;

      case 'xls':
      case 'xlsx':
        icon = renderIcon(props.darkMode ? xlsDarkSvg : xlsSvg, 'xls');
        break;

      case 'jpeg':
      case 'jpg':
      case 'png':
      case 'dwg':
      case 'heic':
        icon = renderIcon(props.darkMode ? imageDarkSvg : imageSvg, 'pic');
        break;

      default:
        icon = renderIcon(props.darkMode ? docDarkSvg : docSvg, 'doc');
        break;
    }

    return icon;
  };

  const getUrl = (props) => {
    if (props.readOnly) {
      return null;
    } else if (props.url !== null) {
      return props.url;
    } else if (props.file !== null && props.file.hasOwnProperty('preview')) {
      return props.file.preview;
    } else {
      return null;
    }
  };

  const getRemoveIcon = (props) => {
    return (
      <img
        className="document-link-remove"
        src={props.darkMode ? removeDarkSvg : removeSvg}
        alt="remove"
        onClick={(event) => handleRemove(props, event)}
      />
    );
  };

  const handleRemove = (props, event) => {
    event.stopPropagation();
    props.onRemove(event);
  };

  const handleClick = (props, event) => {
    event.stopPropagation(); // to use inside SimpleTable (which prevents defaults on tr clicks)
    !props.readOnly && props.onClick && props.onClick(event);
  };

  return (
    <div
      className={`document-link ${props.darkMode ? 'dark-mode' : ''} ${
        props.readOnly ? 'read-only' : ''
      }`}
    >
      {props.showIcon && props.iconPosition === 'left' && getIcon(props)}
      {props.onRemove && props.iconPosition === 'right' && getRemoveIcon(props)}
      <div className="document-link-label">
        <a
          className={!props.showLink ? 'not-active' : null}
          href={getUrl(props)}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(event) => handleClick(props, event)}
        >
          <div>{getName(props)}</div>
        </a>
      </div>
      {props.showIcon && props.iconPosition === 'right' && getIcon(props)}
      {props.onRemove && props.iconPosition === 'left' && getRemoveIcon(props)}
    </div>
  );
}

DocumentLink.propTypes = {
  file: PropTypes.any,
  showIcon: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  showLink: PropTypes.bool,
  url: PropTypes.string,
  readOnly: PropTypes.bool,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  showStatus: PropTypes.bool,
  status: PropTypes.oneOf([
    'INITIAL',
    'WAITING',
    'UPLOADING',
    'FINISHED',
    'ERROR',
  ]),
};

DocumentLink.defaultProps = {
  file: null,
  showIcon: true,
  iconPosition: 'left',
  showLink: true,
  url: null,
  readOnly: false,
  onClick: null,
  onRemove: null,
  showStatus: false,
  status: 'FINISHED',
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(DocumentLink);
