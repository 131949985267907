import React, { Component } from 'react';
import './Reports.css';
import { goToWorkOrder } from 'actions/admin/work-orders/work-orders';
import Number from 'components/shared/text/Number';
import BaseReport from 'components/admin/reports/BaseReport';
import { connect } from 'react-redux';
import { getReportResults } from 'selectors/admin/reports/reports';

class ContractorWoVarianceReport extends Component {
  handleRowSelection = (index) => {
    this.props.dispatch(goToWorkOrder(this.props.reportResults.data[index].id));
  };

  createTableRowsData = () => {
    if (this.props.reportResults !== null) {
      return this.props.reportResults.data.map((result, index) => {
        let revenue = result.quotes_cost - result.actual_cost;
        const rowData = {
          id: index,
          selectable: true,
          data: [
            result.number,
            result.work_order_job !== null ? result.work_order_job : 'N/A',
            result.name,
            result.client.name,
            <Number value={result.quotes_cost} format="$0,0" />,
            <Number value={result.actual_cost} format="$0,0" />,
            <Number value={revenue} format="$0,0" />,
            <div>
              <Number
                value={(revenue * 100) / result.quotes_cost}
                format="0,0"
              />
              %
            </div>,
          ],
        };
        return {
          first: true,
          rowData,
          workOrderId: result.id,
        };
      });
    } else {
      return [];
    }
  };

  render() {
    return (
      <BaseReport
        reportName="contractor_wo_variance_report"
        tableHeader={[
          'WO #',
          'JOB #',
          'NAME',
          'OWNER NAME',
          'QUOTE',
          'ACTUAL COST',
          'REVENUE',
          'VARIANCE',
        ]}
        tableRowsData={this.createTableRowsData()}
        title="Work Order Variance"
        onRowSelection={this.handleRowSelection}
      />
    );
  }
}

export default connect((state) => {
  return {
    reportResults: getReportResults(state),
  };
})(ContractorWoVarianceReport);
