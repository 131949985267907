import { createSelector } from 'reselect';

export const getProjectLocations = createSelector(
  (state) => state.projectLocations.data.projectLocations,
  (projectLocations) => {
    return projectLocations;
  }
);

export const getClients = createSelector(
  (state) => state.projectLocations.data.clients,
  (clients) => {
    return clients;
  }
);

export const getCurrentProjectLocation = createSelector(
  (state) => state.projectLocations.data.currentProjectLocation,
  (currentProjectLocation) => {
    return currentProjectLocation;
  }
);

export const getCurrentPage = createSelector(
  (state) => state.projectLocations.ui.currentPage,
  (currentPage) => {
    return currentPage;
  }
);

export const getEngineerAdded = createSelector(
  (state) => state.projectLocations.ui.engineerAdded,
  (engineerAdded) => {
    return engineerAdded;
  }
);

export const getFiles = createSelector(
  (state) => state.projectLocations.ui.files,
  (files) => {
    return files;
  }
);

export const getForm = createSelector(
  (state) => state.projectLocations.ui.form,
  (form) => {
    return form;
  }
);

export const getInvalidFields = createSelector(
  (state) => state.projectLocations.ui.invalidFields,
  (invalidFields) => {
    return invalidFields;
  }
);

export const getParkingOnSite = createSelector(
  (state) => state.projectLocations.ui.parkingOnSite,
  (parkingOnSite) => {
    return parkingOnSite;
  }
);

export const getSelectedClientsId = createSelector(
  (state) => state.projectLocations.ui.selectedClientsId,
  (selectedClientsId) => {
    return selectedClientsId;
  }
);

export const getSelectedContractors = createSelector(
  (state) => state.projectLocations.ui.selectedContractors,
  (selectedContractors) => {
    return selectedContractors;
  }
);

export const getSelectedUnionsId = createSelector(
  (state) => state.projectLocations.ui.selectedUnionsId,
  (selectedUnionsId) => {
    return selectedUnionsId;
  }
);

export const getUnions = createSelector(
  (state) => state.projectLocations.data.unions,
  (unions) => {
    return unions;
  }
);

export const getValidationMessage = createSelector(
  (state) => state.projectLocations.ui.validationMessage,
  (validationMessage) => {
    return validationMessage;
  }
);

export const getWorkOrders = createSelector(
  (state) => state.projectLocations.data.workOrders,
  (workOrders) => {
    return workOrders;
  }
);

export const getProjects = createSelector(
  (state) => state.projectLocations.data.projects,
  (projects) => {
    return projects;
  }
);

export const getProjectLocationsCurrentPage = createSelector(
  (state) => state.projectLocations.ui.projectLocationsCurrentPage,
  (projectLocationsCurrentPage) => {
    return projectLocationsCurrentPage;
  }
);

export const getProjectLocationsTotal = createSelector(
  (state) => state.projectLocations.ui.projectLocationsTotal,
  (projectLocationsTotal) => {
    return projectLocationsTotal;
  }
);

export const getProjectLocationsPageSize = createSelector(
  (state) => state.projectLocations.ui.projectLocationsPageSize,
  (projectLocationsPageSize) => {
    return projectLocationsPageSize;
  }
);
