import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';

class ChatsApi extends BaseApi {
  getChatActivity(filters) {
    const byProject = filters.by_project;
    delete filters.by_project;

    let query = '';
    Object.keys(filters).forEach((filter, index) => {
      if (index > 0) {
        query += '&';
      }
      query += `${filter}=${filters[filter]}`;
    });

    let path = 'notes/activity';
    if (byProject) {
      path = `projects/${byProject}/activity`;
    }

    return axios
      .get(`${this.baseUrl}/${path}?${query}`)
      .then((response) => {
        return {
          ok: true,
          activity: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
          totalUnread: numeral(response.headers['total-unread']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  markRead(noteId, read) {
    return axios
      .post(`${this.baseUrl}/notes/${noteId}/mark_read`, {
        read: read,
      })
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const chatsApi = new ChatsApi();
