import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { sameOrBeforeTodayValidation } from 'services/utils/date-util';
import { DailyReportUtil } from 'services/utils/daily-report-util';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import ImageUpload from 'components/shared/image-upload/ImageUpload';
import RadioButton from 'components/shared/radio-button/RadioButton';
import CharsCounterTextarea from 'components/shared/chars-counter/CharsCounterTextarea';
import UnitsInput from 'components/shared/input/UnitsInput';
import Selector from 'components/shared/selector/Selector';
import MapSelectLocation from 'components/shared/map/MapSelectLocation';

import Input from 'components/shared/input/Input';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import ProjectEmailModal from 'components/admin/projects/details/project-email-modal/ProjectEmailModal';
import DatePicker from 'components/shared/date-picker/DatePicker';
import FormModal from 'components/shared/modal/FormModal';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { getSettings } from 'components/admin/projects/details/project-settings/store/selectors';
import { getShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/selectors';
import {
  getContributors,
  getActiveContributor,
  getContributorsForm,
  getShowReviewForm,
  getCurrentDailyReport,
  getDailyReportIsSubmitted,
  getEditMode,
  getActiveEntryId,
  getReportExtraInputs,
  getGeneralContactorName,
  getMemberForm,
  getMembers,
  getDeletedMembers,
  getReportOpenItem,
  getGCImages,
  getGCDeletedImages,
  getDailyReportsTabSelected,
  getAddFloor,
  getSimplifiedDailyReportForm,
} from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import { getDarkMode } from 'selectors/theme';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  setSimplifiedDailyReportForm,
  sendDailyReportEmails,
  createSimplifiedDailyReport,
  updateSimplifiedDailyReport,
  deleteReportRequest,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';
import './AddSimplifiedDailyReportModal.css';
import { imageExtensions } from 'services/utils/files-util';
import { showErrorMessage } from 'actions/messages';
import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';
import { getCurrentUser } from 'selectors/authentication';
import User from 'domain/user';
import Project from 'domain/project';
import {
  weatherConditionOptions,
  weatherPrecipitationOptions,
  weatherWindOptions,
} from 'domain/weather';
import {
  importWeatherInformation,
  loadTimezone,
} from 'components/admin/projects/details/project-daily-report-log/field-reports/store/actions.js';
import timezonesArray from 'components/admin/projects/details/project-daily-report-log/field-reports/TimezonesList';

import DailyReportPermissions from 'permissions/daily-report-permissions';
import classnames from 'classnames';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import {
  setShowSimplifiedAddDailyReportModal,
  setGeneralContractorName,
  setContributors,
} from './store/actions';

const AddSimplifiedDailyReportModal = (props) => {
  const {
    dispatch,
    isSubmitted,
    memberForm,
    activeContributor,
    showReviewForm,
    show,
    contributors,
    projectId,
    editMode,
    generalContactorName,
    currentDailyReport,
    simplifiedDailyReportForm,
    currentProject,
    currentUser,
    activeEntryId,
    showEmailModal,
    darkMode,
  } = props;
  const handleClose = () => {
    dispatch(setShowSimplifiedAddDailyReportModal(false));
    dispatch(setSimplifiedDailyReportForm({}));
    dispatch(setContributors([]));
  };

  const [setErrors, errors] = useState({});
  const [initialContributors, setInitialContributors] = useState([]);
  useEffect(() => {
    setInitialContributors(simplifiedDailyReportForm.contributors);
  }, []);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleSimplifiedDailyReportFormChange = (values) => {
    dispatch(setSimplifiedDailyReportForm(values));
  };
  const handleDelete = () => {
    dispatch(
      deleteReportRequest(
        currentProject.id,
        simplifiedDailyReportForm.id,
        false
      )
    ).then((response) => {
      if (response.ok) {
        handleClose();
      }
    });
  };
  const handleDeleteConfirmation = (confirmed) => {
    setShowDeleteConfirmation(false);
    confirmed && handleDelete();
  };
  const generateTitle = () => {
    if (memberForm) {
      return 'Site Staff';
    }

    if (isSubmitted) {
      return currentProject.name;
    }

    if (!showReviewForm && !editMode) {
      return 'Add Daily Report';
    }

    let name = 'Daily Report';
    if (activeContributor) {
      let contrib;
      if (currentDailyReport.daily_report_entries) {
        contrib = currentDailyReport.daily_report_entries.find(
          (entry) => entry.id === activeEntryId
        );
      }
      if (currentDailyReport.sub_daily_report_entries) {
        contrib = currentDailyReport.sub_daily_report_entries.find(
          (entry) => entry.id === activeEntryId
        );
      }

      if (contrib && contrib.project_contributor) {
        name = DailyReportUtil.tradeLabel(contrib.project_contributor);
      } else {
        contrib = contributors[activeContributor - 1];
        name = `${contrib[1]}`;
      }
    }

    return (
      <React.Fragment>
        {DailyReportPermissions.canEditDate(currentUser, currentProject) ? (
          <DatePicker
            isValidDate={sameOrBeforeTodayValidation}
            value={simplifiedDailyReportForm.report_date}
            onChange={(report_date) =>
              handleSimplifiedDailyReportFormChange({
                ...simplifiedDailyReportForm,
                report_date,
              })
            }
          />
        ) : (
          `${moment(currentDailyReport.report_date).format('MM/DD/YYYY')} - `
        )}
        {name}
      </React.Fragment>
    );
  };

  const setImages = (files) => {
    let images = [...simplifiedDailyReportForm.images];
    files.forEach(function (val) {
      images.push({
        url: val.url || URL.createObjectURL(val),
        file: val,
      });
    });
    handleSimplifiedDailyReportFormChange({
      ...simplifiedDailyReportForm,
      images,
    });
  };

  const removeImages = (index) => {
    let images = [...simplifiedDailyReportForm.images];
    let deleted_images = [...simplifiedDailyReportForm.deleted_images];
    let deletedImage = images.splice(index, 1);
    deleted_images.push(deletedImage[0]);

    handleSimplifiedDailyReportFormChange({
      ...simplifiedDailyReportForm,
      images,
      deleted_images,
    });
  };

  const handleImportWeather = async () => {
    if (
      simplifiedDailyReportForm.location &&
      simplifiedDailyReportForm.report_date
    ) {
      const response = await dispatch(
        importWeatherInformation(
          simplifiedDailyReportForm.location,
          simplifiedDailyReportForm.report_date,
          simplifiedDailyReportForm.arrival_time,
          simplifiedDailyReportForm.departure_time
        )
      );

      if (response.ok)
        handleSimplifiedDailyReportFormChange({
          ...simplifiedDailyReportForm,
          ...response.info,
        });
    } else {
      dispatch(
        showErrorMessage(
          'Location and Date are required to import weather information.'
        )
      );
    }
  };
  const subcontractor = () => {
    return User.isContractor(currentUser, currentProject);
  };

  const getGeneralContractorLabel = (loadedDirectory) => {
    const directory = loadedDirectory || props.directory;

    const gcContributor = Project.gcContributor({
      project: currentProject,
      directory,
    });
    if (!gcContributor) return null;

    return DailyReportUtil.tradeLabel(gcContributor);
  };

  const handleShowEmailModal = () => {
    dispatch(setShowEmailModal(true));
  };

  const handleLocationChange = async (latLng) => {
    const response = await dispatch(loadTimezone(latLng));

    if (response.ok) {
      const timezone_name = response.timezone_name;
      const timezone = timezonesArray.filter(
        (o) => o.timezone_name === timezone_name
      ).value;
      handleSimplifiedDailyReportFormChange({
        ...simplifiedDailyReportForm,
        location: latLng,
        timezone,
        timezone_name,
      });
    } else {
      dispatch(showErrorMessage('An error occurred.'));
    }
  };
  const handleSaveDraft = () => {
    const values = {
      ...simplifiedDailyReportForm,
      status: 'draft',
      is_simplified_daily_report: true,
    };
    dispatch(setSimplifiedDailyReportForm(values));
    if (!values.id) {
      handleCreateSimplifiedDailyReport();
      handleClose();
      return;
    }
    handleUpdateSimplifiedDailyReport();
    handleClose();
  };
  const handleValidation = () => {
    if (!simplifiedDailyReportForm.report_date)
      setErrors({ report_date: 'Report date is missing' });
    return;
  };
  const handleCreateSimplifiedDailyReport = () => {
    dispatch(
      createSimplifiedDailyReport(
        {
          ...simplifiedDailyReportForm,
          status: 'submitted',
          is_simplified_daily_report: true,
        },
        projectId
      )
    );
  };
  const handleUpdateSimplifiedDailyReport = () => {
    dispatch(
      updateSimplifiedDailyReport(
        {
          ...simplifiedDailyReportForm,
          status: 'submitted',
          is_simplified_daily_report: true,
        },
        projectId,
        initialContributors
      )
    );
  };
  const handleSubmit = () => {
    handleValidation();
    handleCreateSimplifiedDailyReport();
    handleClose();
  };
  const handleUpdate = () => {
    handleValidation();
    handleUpdateSimplifiedDailyReport();
    handleClose();
  };
  const handleDiscardDraft = () => {
    setShowDeleteConfirmation(true);
  };
  const handleContributorsChange = (contributors) => {
    if (errors.contributorsField) {
      errors.contributorsField = false;
      this.setState({
        errors: errors,
      });
    }

    const generalContactorIndex = contributors.findIndex(
      (contrib) => contrib[0] === 0
    );

    if (generalContactorIndex >= 0) {
      const gcLabel = getGeneralContractorLabel();
      dispatch(setGeneralContractorName([0, gcLabel]));
      contributors.splice(generalContactorIndex, 1);
    } else {
      dispatch(setGeneralContractorName(null));
    }
    dispatch(setContributors(contributors));

    handleSimplifiedDailyReportFormChange({
      ...simplifiedDailyReportForm,
      contributors,
    });
  };

  const currentContributors = () => {
    const values = [...contributors];
    if (generalContactorName) {
      values.unshift(generalContactorName);
    }
    return values;
  };

  const handleSendEmail = (emailsData) => {
    dispatch(
      sendDailyReportEmails(
        simplifiedDailyReportForm.id,
        'DailyReport',
        emailsData
      )
    );
  };

  const buildEmailModalSubject = () => {
    return `${currentProject.number} ${currentProject.name} - Daily Report #${simplifiedDailyReportForm.number}`;
  };

  const buildEmailModalMessage = () => {
    return `Please find the attached Daily Report #${simplifiedDailyReportForm.number} for Project ${currentProject.name}. If you have any questions, please reach out via chat or email.`;
  };

  const renderTradesForm = () => {
    const {
      directory,
      activeContributor,
      showReviewForm,
      isSubmitted,
      currentUser,
      currentProject,
    } = props;

    let contributorsList = [];
    if (directory && directory.project_contributors) {
      const gcContributor = Project.gcContributor({
        project: currentProject,
        directory,
      });
      contributorsList = directory.project_contributors
        .filter((contrib) => {
          if (gcContributor && contrib.id === gcContributor.id) return false;

          if (subcontractor())
            return contrib.company_id === currentUser.accountable_id;

          return true;
        })
        .sort((contrib1, contrib2) =>
          contrib1.csi_code.division < contrib2.csi_code.division ? -1 : 1
        )
        .map((contrib) => {
          return { id: contrib.id, label: DailyReportUtil.tradeLabel(contrib) };
        });
    }

    const gcLabel = getGeneralContractorLabel();
    const gcOption = gcLabel && { id: 0, label: gcLabel };
    if (!subcontractor() && gcOption) {
      contributorsList.unshift(gcOption);
    }
    if (!activeContributor && !showReviewForm && !isSubmitted) {
      const values = currentContributors();
      return (
        directory &&
        directory.project_contributors && (
          <div className="form-block">
            <FormControlBlock
              label={values.length > 0 && 'Trades'}
              control={
                <MultipleDropdown
                  dropdownClassName="alternate-dropdown-control"
                  options={contributorsList}
                  currentValues={values}
                  placeholder="Trades"
                  onChange={(values) => handleContributorsChange(values)}
                  valid={!errors.contributorsField}
                />
              }
            />
          </div>
        )
      );
    }
  };

  const InputLocation = (
    <MapSelectLocation
      onChange={(latLng) => handleLocationChange(latLng)}
      defaultCenterLat={
        simplifiedDailyReportForm.location
          ? simplifiedDailyReportForm.location.latitude
          : undefined
      }
      defaultCenterLng={
        simplifiedDailyReportForm.longitude
          ? simplifiedDailyReportForm.location.longitude
          : undefined
      }
      markerLat={
        simplifiedDailyReportForm.location
          ? simplifiedDailyReportForm.location.latitude
          : undefined
      }
      markerLng={
        simplifiedDailyReportForm.location
          ? simplifiedDailyReportForm.location.longitude
          : undefined
      }
    />
  );

  const InputTemperature = (
    <FormControlBlock
      hideBottomLabel
      className="input-temperature"
      label={simplifiedDailyReportForm.weather_temperature && 'Temperature'}
      control={
        <UnitsInput
          type={'number'}
          placeholder={'Temperature'}
          value={simplifiedDailyReportForm.weather_temperature}
          unit="F"
          unitPosition={'end'}
          onChange={(value) => {
            handleSimplifiedDailyReportFormChange({
              ...simplifiedDailyReportForm,
              weather_temperature: value,
            });
          }}
        />
      }
    />
  );

  const InputWind = (
    <FormControlBlock
      hideBottomLabel
      className="input-wind"
      label={simplifiedDailyReportForm.weather_wind && 'Wind'}
      control={
        <UnitsInput
          type={'number'}
          placeholder={'Wind'}
          value={simplifiedDailyReportForm.weather_wind}
          unit={
            simplifiedDailyReportForm.weather_wind_direction &&
            simplifiedDailyReportForm.weather_wind_direction !== 'none'
              ? `mph ${
                  weatherWindOptions.find(
                    (o) =>
                      o.value ===
                      simplifiedDailyReportForm.weather_wind_direction
                  ).short
                }`
              : 'mph'
          }
          unitPosition={'end'}
          onChange={(value) =>
            handleSimplifiedDailyReportFormChange({
              ...simplifiedDailyReportForm,
              weather_wind: value,
            })
          }
        />
      }
    />
  );

  const InputWindDirection = (
    <FormControlBlock
      hideBottomLabel
      label={simplifiedDailyReportForm.weather_wind && 'Wind Direction'}
      className="input-wind-direction"
      control={
        <Selector
          className="select-dropdown"
          placeholder={'Wind Direction'}
          value={simplifiedDailyReportForm.weather_wind_direction}
          onChange={(option) =>
            handleSimplifiedDailyReportFormChange({
              ...simplifiedDailyReportForm,
              weather_wind_direction: option ? option.value : option,
            })
          }
          valid={
            !(
              simplifiedDailyReportForm.errors &&
              simplifiedDailyReportForm.errors.weather_wind_direction
            )
          }
          options={weatherWindOptions}
          clearable
        />
      }
    />
  );

  const InputHumidity = (
    <FormControlBlock
      hideBottomLabel
      className="input-humidity"
      label={simplifiedDailyReportForm.weather_humidity && 'Humidity'}
      control={
        <UnitsInput
          type={'number'}
          placeholder={'Humidity'}
          value={simplifiedDailyReportForm.weather_humidity}
          unit="%"
          unitPosition={'end'}
          onChange={(value) =>
            handleSimplifiedDailyReportFormChange({
              ...simplifiedDailyReportForm,
              weather_humidity: value,
            })
          }
        />
      }
    />
  );

  const InputCondition = (
    <FormControlBlock
      hideBottomLabel
      className="input-condition"
      label={simplifiedDailyReportForm.weather_condition && 'Condition'}
      control={
        <Selector
          className="select-dropdown"
          placeholder={'Condition'}
          value={simplifiedDailyReportForm.weather_condition}
          onChange={(option) =>
            handleSimplifiedDailyReportFormChange({
              ...simplifiedDailyReportForm,
              weather_condition: option ? option.value : option,
            })
          }
          valid={
            !(
              simplifiedDailyReportForm.errors &&
              simplifiedDailyReportForm.errors.weather_condition
            )
          }
          options={weatherConditionOptions}
          clearable
        />
      }
    />
  );

  const InputPrecipitation = (
    <FormControlBlock
      hideBottomLabel
      label={simplifiedDailyReportForm.weather_precipitation && 'Precipitation'}
      className="input-precipitation"
      control={
        <Selector
          className="select-dropdown"
          placeholder={'Precipitation'}
          value={simplifiedDailyReportForm.weather_precipitation}
          onChange={(option) =>
            handleSimplifiedDailyReportFormChange({
              ...simplifiedDailyReportForm,
              weather_precipitation: option ? option.value : option,
            })
          }
          options={weatherPrecipitationOptions}
          valid={
            !(
              simplifiedDailyReportForm.errors &&
              simplifiedDailyReportForm.errors.weather_precipitation
            )
          }
          clearable
        />
      }
    />
  );

  const InputWeatherRemarks = (
    <FormControlBlock
      hideBottomLabel
      className="input-weather-remarks inline-block"
      label={simplifiedDailyReportForm.weather_remarks && 'Remarks'}
      control={
        <CharsCounterTextarea
          placeholder={'Remarks'}
          value={simplifiedDailyReportForm.weather_remarks}
          valid={
            !(
              simplifiedDailyReportForm.errors &&
              simplifiedDailyReportForm.errors.weather_remarks
            )
          }
          onChange={(value) =>
            handleSimplifiedDailyReportFormChange({
              ...simplifiedDailyReportForm,
              weather_remarks: value,
            })
          }
          limit={100}
        />
      }
    />
  );

  const reportDate = (
    <FormControlBlock
      hideBottomLabel
      className="input-weather-remarks inline-block"
      label="Report Date"
      control={
        <DatePicker
          isValidDate={sameOrBeforeTodayValidation}
          value={simplifiedDailyReportForm.report_date}
          onChange={(report_date) =>
            handleSimplifiedDailyReportFormChange({
              ...simplifiedDailyReportForm,
              report_date,
            })
          }
        />
      }
    />
  );

  const renderEmailButton = () => {
    if (
      simplifiedDailyReportForm &&
      (simplifiedDailyReportForm.status === 'submitted' ||
        simplifiedDailyReportForm.status === 'approved')
    ) {
      return (
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.WHITE}
          label="Email"
          onClick={handleShowEmailModal}
        />
      );
    }
    return null;
  };
  const renderCreateButton = () => {
    if (!simplifiedDailyReportForm.id) {
      return (
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          label="Submit"
          onClick={handleSubmit}
        />
      );
    }
    return (
      <Button
        type={BUTTON_TYPES.CONFIRMATION}
        color={BUTTON_COLORS.GREEN}
        label="Submit"
        onClick={handleUpdate}
      />
    );
  };
  const renderCloseButton = () => {
    return (
      <Button
        type={BUTTON_TYPES.CONFIRMATION}
        color={BUTTON_COLORS.WHITE}
        label="Close"
        onClick={handleClose}
      />
    );
  };

  const renderSaveDraftButton = () => {
    if (
      !simplifiedDailyReportForm.id ||
      simplifiedDailyReportForm.status === 'draft'
    )
      return (
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.WHITE}
          label="Save Draft"
          onClick={handleSaveDraft}
        />
      );
  };

  const renderDiscardButton = () => {
    if (
      !simplifiedDailyReportForm.id ||
      simplifiedDailyReportForm.status === 'draft'
    )
      return (
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.WHITE}
          label="Discard Draft"
          onClick={handleDiscardDraft}
        />
      );
  };

  let checkedColor;
  if (darkMode) {
    checkedColor = '#d4fb00';
  } else {
    checkedColor = '#00b091';
  }
  if (simplifiedDailyReportForm.is_safety_accepted) checkedColor = 'red';

  return (
    <FormModal
      id="simplified-daily-report-form-modal"
      className={classnames({
        'simplified-daily-report-form-modal': true,
        'submitted-message': isSubmitted,
        'member-form': memberForm,
        'active-contributor': activeContributor,
        'review-form': showReviewForm,
        'initial-form':
          !memberForm && !activeContributor && !showReviewForm && !isSubmitted,
      })}
      show={show}
      onClose={handleClose}
      subTitle={generateTitle()}
    >
      <div className="simplified-daily-report-form-body">
        <div className="form-block">{reportDate}</div>
        <div className="form-block">
          <FormControlBlock
            label="Laborer count"
            control={
              <Input
                placeholder="Laborer Count"
                onChange={(laborerCount) =>
                  handleSimplifiedDailyReportFormChange({
                    ...simplifiedDailyReportForm,
                    laborer_count: laborerCount,
                  })
                }
                value={simplifiedDailyReportForm.laborer_count}
              />
            }
          />
        </div>
        {renderTradesForm()}
        <div className="form-block">
          <label
            className={`simplified-daily-report-form-block-label simplified-daily-report-radiobox-group-label ${
              simplifiedDailyReportForm.is_safety_accepted
                ? 'red-safety-issues'
                : ''
            }`}
          >
            Safety Issues:
          </label>
          <RadioButton
            className={`${
              simplifiedDailyReportForm.is_safety_accepted ? 'checked-red' : ''
            }`}
            options={[
              {
                value: true,
                label: 'Yes',
              },
              {
                value: false,
                label: 'No',
              },
            ]}
            value={simplifiedDailyReportForm.is_safety_accepted}
            orientation="horizontal"
            onChange={(value) =>
              handleSimplifiedDailyReportFormChange({
                ...simplifiedDailyReportForm,
                is_safety_accepted: value,
              })
            }
            checkedColor={checkedColor}
          />
        </div>
        <div className="form-block">
          <FormControlBlock
            label={'Summary'}
            control={
              <TextareaAutosizeWrapper
                placeholder="Summary"
                initialValue={simplifiedDailyReportForm.comments}
                onChange={(comments) =>
                  handleSimplifiedDailyReportFormChange({
                    ...simplifiedDailyReportForm,
                    comments,
                  })
                }
              />
            }
          />
        </div>
        {/* <div className="form-block">{InputTimezone}</div> */}
        <div className="simplified-daily-report-form-block-label">
          <label className="simplified-daily-report-form-block-label simplified-daily-report-radiobox-group-label">
            Location
          </label>
        </div>
        <div className="location-section-container">
          <div className="simplified-daily-report-map-block">
            {InputLocation}
          </div>
        </div>
        <div className="simplified-daily-report-form-block-label">
          <label className="simplified-daily-report-form-block-label simplified-daily-report-radiobox-group-label">
            Weather Conditions
          </label>
          <Button
            key="weather-import"
            type={BUTTON_TYPES.LINK}
            icon={BUTTON_ICONS.DOWNLOAD}
            label="Import"
            onClick={handleImportWeather}
          />
        </div>
        <div className="form-block">
          {InputTemperature}
          {InputHumidity}
          {InputWind}
          {InputWindDirection}
        </div>
        <div className="form-block">
          {InputCondition}
          {InputPrecipitation}
        </div>
        {InputWeatherRemarks}
        <div className="simplified-daily-report-form-block-label">
          <label className="simplified-daily-report-form-block-label simplified-daily-report-radiobox-group-label">
            Photos
          </label>
        </div>
        <div className="form-block">
          <div className="form-images">
            <FormControlBlock
              className="without-focus"
              control={
                <div className="form-resources-body">
                  <ImageUpload
                    extensions={imageExtensions}
                    multiple
                    onUpload={(files) => setImages(files)}
                    onRemove={(index) => removeImages(index)}
                    images={simplifiedDailyReportForm.images}
                  />
                </div>
              }
            />
          </div>
        </div>
      </div>
      <div className="menu-buttons-container">
        {renderCloseButton()}
        {renderDiscardButton()}
        {renderSaveDraftButton()}
        {renderEmailButton()}
        {renderCreateButton()}
      </div>
      {showEmailModal && (
        <ProjectEmailModal
          subTitle={currentProject.name}
          handleSendEmail={handleSendEmail}
          projectId={projectId}
          initialSubject={buildEmailModalSubject()}
          initialMessage={buildEmailModalMessage()}
        />
      )}
      {showDeleteConfirmation && (
        <ConfirmationModal
          show={showDeleteConfirmation}
          message="Are you sure you want to discard this Daily Report?"
          onHide={handleDeleteConfirmation}
        />
      )}
    </FormModal>
  );
};

export default connect((state) => {
  return {
    directory: getDirectory(state),
    settings: getSettings(state),
    currentUser: getCurrentUser(state),
    contributors: getContributors(state),
    contributorsForm: getContributorsForm(state),
    activeContributor: getActiveContributor(state),
    currentDailyReport: getCurrentDailyReport(state),
    showReviewForm: getShowReviewForm(state),
    isSubmitted: getDailyReportIsSubmitted(state),
    currentProject: getCurrentProject(state),
    editMode: getEditMode(state),
    activeEntryId: getActiveEntryId(state),
    showEmailModal: getShowEmailModal(state),
    reportExtraInputs: getReportExtraInputs(state),
    generalContactorName: getGeneralContactorName(state),
    memberForm: getMemberForm(state),
    members: getMembers(state),
    deletedMembers: getDeletedMembers(state),
    reportOpenItem: getReportOpenItem(state),
    gcImages: getGCImages(state),
    gcDeletedImages: getGCDeletedImages(state),
    dailyReportsTabSelected: getDailyReportsTabSelected(state),
    addFloor: getAddFloor(state),
    simplifiedDailyReportForm: getSimplifiedDailyReportForm(state),
    darkMode: getDarkMode(state),
  };
})(AddSimplifiedDailyReportModal);
