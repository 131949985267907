import { createSelector } from 'reselect';

export const getMeetingInfo = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.meetingMinutes.data.meetingInfo,
  (meetingInfo) => {
    return meetingInfo;
  }
);

export const getMeetingInfoEdit = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.meetingMinutes.ui.meetingInfoEdit,
  (meetingInfoEdit) => {
    return meetingInfoEdit;
  }
);

export const getMeetings = createSelector(
  (state) => state.projects.scenes.details.scenes.meetingMinutes.data.meetings,
  (meetings) => {
    return meetings;
  }
);

export const getShowPdfViewer = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.meetingMinutes.ui.showPdfViewer,
  (showPdfViewer) => {
    return showPdfViewer;
  }
);

export const getMeetingPdf = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.meetingMinutes.data.meetingPdf,
  (meetingPdf) => {
    return meetingPdf;
  }
);

export const getMeetingModalShow = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.meetingMinutes.ui.meetingModalShow,
  (meetingModalShow) => {
    return meetingModalShow;
  }
);

export const getMeetingCategories = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.meetingMinutes.data.meetingCategories,
  (meetingCategories) => {
    return meetingCategories;
  }
);

export const getMeetingForm = createSelector(
  (state) => state.projects.scenes.details.scenes.meetingMinutes.ui.meetingForm,
  (meetingForm) => {
    return meetingForm;
  }
);

export const getMeetingItems = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.meetingMinutes.ui.meetingItems,
  (meetingItems) => {
    return meetingItems;
  }
);

export const getShowAttendeesModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.meetingMinutes.ui.showAttendeesModal,
  (showAttendeesModal) => {
    return showAttendeesModal;
  }
);

export const getReviewMeeting = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.meetingMinutes.ui.reviewMeeting,
  (reviewMeeting) => {
    return reviewMeeting;
  }
);

export const getMeetingItemSettings = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.meetingMinutes.ui.meetingItemSettings,
  (meetingItemSettings) => {
    return meetingItemSettings;
  }
);

export const getCurrentMeeting = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.meetingMinutes.data.currentMeeting,
  (currentMeeting) => {
    return currentMeeting;
  }
);

export const getConfirmationMsg = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.meetingMinutes.ui.confirmationMsg,
  (confirmationMsg) => {
    return confirmationMsg;
  }
);

export const getConfirmApproval = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.meetingMinutes.ui.confirmApproval,
  (confirmApproval) => {
    return confirmApproval;
  }
);

export const getMeetingTypes = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.meetingMinutes.data.meetingTypes,
  (meetingTypes) => {
    return meetingTypes;
  }
);

export const getSelectedMeetingType = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.meetingMinutes.ui.selectedMeetingType,
  (selectedMeetingType) => {
    return selectedMeetingType;
  }
);
