import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { getProjectPhotosCurrentAlbum } from './store/selectors';
import { setProjectPhotosFilters } from './store/actions';

const ProjectPhotosBreadCrumbs = (props) => {
  const { dispatch, currentAlbum } = props;

  if (!currentAlbum || currentAlbum.breadcrumbs.length <= 1) return null;

  const renderBreadCrumbItem = (b, i, arr) => {
    const isRoot = i === 0;
    const isCurrent = arr.length - 1 === i;

    if (isCurrent) {
      return (
        <Typography key={'breadcrumb_' + b.id} color="text.primary">
          {b.title}
        </Typography>
      );
    }

    return (
      <Link
        key={'breadcrumb_' + b.id}
        underline="hover"
        component="button"
        color="inherit"
        onClick={onBreadcrumItemClick(isRoot ? null : b.id)}
      >
        {b.title}
      </Link>
    );
  };
  const onBreadcrumItemClick = (albumId) => () => {
    dispatch(setProjectPhotosFilters({ albumId, doSearch: true }));
  };

  const getCounts = () => {
    let res = [];
    const { count_albums, count_photos } = currentAlbum;

    if (count_albums > 0)
      res.push(`${count_albums} Album${count_albums > 1 ? 's' : ''}`);
    if (count_photos > 0)
      res.push(`${count_photos} Photo${count_photos > 1 ? 's' : ''}`);

    return res.join(', ');
  };

  const getCreatedText = () => {
    if (!currentAlbum.created_by || !currentAlbum.created_at) return '';
    const name = currentAlbum.created_by.full_name;
    const date = moment(currentAlbum.created_at).format('MM/DD/YYYY');
    return `Created by ${name} on ${date}`;
  };

  return (
    <Grid container spacing={2} alignItems={'end'}>
      <Grid item>
        <Breadcrumbs maxItems={3} itemsAfterCollapse={2}>
          {currentAlbum.breadcrumbs.map(renderBreadCrumbItem)}
        </Breadcrumbs>
      </Grid>
      <Grid item>
        <Typography color="text.disabled" variant="caption">
          {getCounts()}
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="text.disabled" variant="caption">
          {getCreatedText()}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default connect((state) => ({
  currentAlbum: getProjectPhotosCurrentAlbum(state),
}))(ProjectPhotosBreadCrumbs);
