import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import MessageModal from 'components/shared/modal/MessageModal';
import invalidPng from 'images/invalid.png';
import { MeetingStatusEnum } from 'domain/meeting-status-enum';

class MeetingActions extends Component {
  static propTypes = {
    primary: PropTypes.bool.isRequired,
    reviewMeeting: PropTypes.bool.isRequired,
    lastMeeting: PropTypes.bool.isRequired,
    meetingStatus: PropTypes.string.isRequired,
    meetingItems: PropTypes.object.isRequired,
    handleSend: PropTypes.func.isRequired,
    handleShowConfirmApproval: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired,
    handleReview: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  emptyMeetingItems = () => {
    const { meetingItems } = this.props;
    const emptyItems = Object.keys(meetingItems).filter((key) => {
      const item = meetingItems[key];
      return !!item.code && !item.destroy && !item.info;
    });
    return emptyItems.length > 0;
  };

  onReview = () => {
    if (this.emptyMeetingItems()) {
      this.setState({ invalidReview: true });
    } else {
      this.props.handleReview();
    }
  };

  hideInvalidReviewModal = () => {
    this.setState({ invalidReview: false });
  };

  renderInvalidReviewModal = () => {
    const invalidReview = !!this.state && !!this.state.invalidReview;
    return (
      invalidReview && (
        <MessageModal
          show={invalidReview}
          message={<div>Make sure to fill in all items before continuing</div>}
          icon={invalidPng}
          onHide={this.hideInvalidReviewModal}
        />
      )
    );
  };

  render() {
    const meetingStatus = MeetingStatusEnum.create(this.props.meetingStatus);
    const { primary, reviewMeeting, lastMeeting } = this.props;
    return reviewMeeting ? (
      !MeetingStatusEnum.DRAFT.equals(meetingStatus) ? (
        lastMeeting || !MeetingStatusEnum.APPROVED.equals(meetingStatus) ? (
          <div className="buttons-container">
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Edit"
              onClick={this.props.handleBack}
            />
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Save"
              onClick={() => this.props.handleSend(meetingStatus.status)}
            />
            {!MeetingStatusEnum.APPROVED.equals(meetingStatus) && (
              <Button
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.GREEN}
                label="Approve & Publish"
                onClick={() => this.props.handleSend('approved', true)}
              />
            )}
          </div>
        ) : (
          <div className="buttons-container"></div>
        )
      ) : (
        <div className="buttons-container">
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.WHITE}
            label="Edit"
            onClick={this.props.handleBack}
          />
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.WHITE}
            label="Save Draft"
            onClick={() => this.props.handleSend('draft')}
          />
          {!primary && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Send Approval"
              onClick={() => this.props.handleSend('pending', true)}
            />
          )}
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label="Approve & Publish"
            onClick={this.props.handleShowConfirmApproval}
          />
        </div>
      )
    ) : (
      <div className="buttons-container">
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.WHITE}
          label="Cancel"
          onClick={this.props.handleClose}
        />
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          label="Review"
          onClick={this.onReview}
        />
        {this.renderInvalidReviewModal()}
      </div>
    );
  }
}

export default connect()(MeetingActions);
