export const MESSAGES_ERROR_SHOW = 'MESSAGES_ERROR_SHOW';
export const MESSAGES_ERROR_CLOSE = 'MESSAGES_ERROR_CLOSE';

export function closeErrorMessage(id) {
  return {
    type: MESSAGES_ERROR_CLOSE,
    payload: id,
  };
}

export function showErrorMessage(messages) {
  let param = messages;
  if (typeof messages === 'string') {
    param = [messages];
  }

  return {
    type: MESSAGES_ERROR_SHOW,
    payload: param,
  };
}
