import { showErrorMessage } from 'actions/messages';
import { submittalsApi } from 'services/api/submittals-api';
import { submittalAddlAssigneesApi } from 'services/api/submittal-addl-assignees-api';
import Submittal from 'domain/submittal';
import {
  SUBMITTAL_LOG_INIT,
  SUBMITTALS_SET,
  SUBMITTAL_TAB_SELECTED_SET,
  SUBMITTAL_MODAL_SHOW_SET,
  SUBMITTAL_MODAL_INIT,
  SUBMITTAL_FORM_VALUE_UPDATE,
  SUBMITTAL_FILE_DELETE,
  SUBMITTAL_READ_ONLY_SET,
  SUBMITTAL_LEAD_TIME_READ_ONLY_SET,
  SUBMITTAL_GC_ACCELERATION_SET,
  SHOW_TRANSMITTAL_PDF_SET,
  TRANSMITTAL_PDF_SET,
  SUBMITTAL_FORM_SET,
  SUBMITTAL_FORM_ERRORS_SET,
  CURRENT_SUBMITTAL_SET,
  SUBMITTAL_UPDATE,
  SUBMITTAL_NOTES_SET,
  SUBMITTAL_NOTES_ADD,
  SUBMITTAL_LOG_PDF_VIEWER_SET,
  SUBMITTAL_LOG_PDF_SET,
  SUBMITTAL_PDF_VIEWER_SET,
  SUBMITTAL_SHOW_ERROR_MESSAGE_SET,
  SUBMITTAL_ADDL_ASSIGNEES_ADD,
  SUBMITTAL_ADDL_ASSIGNEES_UPDATE,
  SUBMITTAL_ADDL_ASSIGNEES_DELETE,
  SUBMITTAL_ADDL_ASSIGNEE_RESPONSE,
  SUBMITTALS_DELETE,
  SUBMITTAL_CREATED_REVISION_MODAL_SHOW_SET,
  REVISION_SUBMITTAL_SET,
  SUBMITTALS_RESPONSES_VIEW_MODAL_SET,
  SUBMITTALS_RESPONSE_MODAL_SET,
} from 'components/admin/projects/details/project-submittal-log/store/action-types';
import { SubmittalStatusKeys } from 'domain/submittal-status-enum';
import User from 'domain/user';

export function initSubmittalLog() {
  return {
    type: SUBMITTAL_LOG_INIT,
  };
}

export function setSubmittalTabSelected(submittalTab) {
  return {
    type: SUBMITTAL_TAB_SELECTED_SET,
    payload: submittalTab,
  };
}

export function loadSubmittalsSuccess(
  submittalsData,
  page,
  status,
  dueDateFilter,
  tradeCodeFilter
) {
  return {
    type: SUBMITTALS_SET,
    payload: {
      ...submittalsData,
      currentPage: page,
      currentStatus: status,
      dueDateFilter,
      tradeCodeFilter,
    },
  };
}

export function loadSubmittalsRequest(
  projectId,
  page,
  status,
  dueDateFilter,
  tradeCodeFilter,
  searchText
) {
  return (dispatch) => {
    return submittalsApi
      .getSubmittals(
        projectId,
        page,
        status,
        searchText,
        dueDateFilter,
        tradeCodeFilter
      )
      .then((response) => {
        if (response.ok) {
          dispatch(
            loadSubmittalsSuccess(
              response,
              page,
              status,
              dueDateFilter,
              tradeCodeFilter
            )
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setSubmittalModalShow(show) {
  return {
    type: SUBMITTAL_MODAL_SHOW_SET,
    payload: show,
  };
}

export function setSubmittalResponseModalShow(show) {
  return {
    type: SUBMITTALS_RESPONSE_MODAL_SET,
    payload: show,
  };
}

export function setSubmittalResponsesViewModalShow(show) {
  return {
    type: SUBMITTALS_RESPONSES_VIEW_MODAL_SET,
    payload: show,
  };
}

export function initSubmittalModal() {
  return {
    type: SUBMITTAL_MODAL_INIT,
  };
}

export function updateSubmittalFormValue(attribute, value) {
  return {
    type: SUBMITTAL_FORM_VALUE_UPDATE,
    payload: { attribute: attribute, value: value },
  };
}

export function deleteSubmittalFile(attribute, value) {
  return {
    type: SUBMITTAL_FILE_DELETE,
    payload: { attribute: attribute, value: value },
  };
}

export function createSubmittal(projectId, values, page, status) {
  return (dispatch) => {
    return submittalsApi.createSubmittal(projectId, values).then((response) => {
      if (response.ok) {
        const submittal = response.submittal;
        dispatch(loadSubmittalsRequest(projectId, page, status));
        if (submittal.revised_submittal_id) {
          dispatch(loadRevisionSubmittalSuccess(submittal));
        }
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function setSubmittalReadOnly(readOnly) {
  return {
    type: SUBMITTAL_READ_ONLY_SET,
    payload: readOnly,
  };
}

export function setSubmittalLeadTimeReadOnly(leadTimeReadOnly) {
  return {
    type: SUBMITTAL_LEAD_TIME_READ_ONLY_SET,
    payload: leadTimeReadOnly,
  };
}

export function setSubmittalGcAcceleration(gcAcceleration) {
  return {
    type: SUBMITTAL_GC_ACCELERATION_SET,
    payload: gcAcceleration,
  };
}

export function setShowTransmittalPdf(showPdf) {
  return {
    type: SHOW_TRANSMITTAL_PDF_SET,
    payload: showPdf,
  };
}

export function setTransmittalPdf(pdf) {
  return {
    type: TRANSMITTAL_PDF_SET,
    payload: pdf,
  };
}

export function loadTransmittalPdf(projectId, submittalId) {
  return (dispatch) => {
    return submittalsApi
      .getTransmittalPdf(projectId, submittalId)
      .then((response) => {
        if (response.ok) {
          dispatch(setTransmittalPdf(response.transmittalPdf));
          dispatch(setShowTransmittalPdf(true));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadSubmittalSuccess(submittal) {
  return {
    type: CURRENT_SUBMITTAL_SET,
    payload: submittal,
  };
}

export function loadSubmittalRequest(projectId, submittalId, mapSubmittal) {
  return (dispatch) => {
    return submittalsApi
      .getSubmittal(projectId, submittalId)
      .then((response) => {
        if (response.ok) {
          if (mapSubmittal) {
            response.submittal = mapSubmittal(response.submittal);
          }
          dispatch(loadSubmittalSuccess(response.submittal));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setSubmittalForm(form) {
  return {
    type: SUBMITTAL_FORM_SET,
    payload: form,
  };
}

export function setSubmittalFormErrors(errors) {
  return {
    type: SUBMITTAL_FORM_ERRORS_SET,
    payload: errors,
  };
}

export function updateSubmittalSuccess(submittal) {
  return {
    type: SUBMITTAL_UPDATE,
    payload: submittal,
  };
}

export function updateSubmittal(projectId, submittalId, values) {
  return (dispatch) => {
    return submittalsApi
      .updateSubmittal(projectId, submittalId, values)
      .then((response) => {
        if (response.ok) {
          dispatch(updateSubmittalSuccess(response.submittal));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function updateSubmittalLeadTime(projectId, submittalId, values) {
  return (dispatch) => {
    return submittalsApi
      .updateSubmittalLeadTime(projectId, submittalId, values)
      .then((response) => {
        if (response.ok) {
          dispatch(updateSubmittalSuccess(response.submittal));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function respondSubmittal(projectId, submittalId, values) {
  return (dispatch) => {
    return submittalsApi
      .respondSubmittal(projectId, submittalId, values)
      .then((response) => {
        if (response.ok) {
          dispatch(updateSubmittalSuccess(response.submittal));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function respondSubmittalAddlAssigneeSuccess(submittalAddlAssignee) {
  return {
    type: SUBMITTAL_ADDL_ASSIGNEE_RESPONSE,
    payload: submittalAddlAssignee,
  };
}

export function respondSubmittalAddlAssignee(id, values) {
  return (dispatch) => {
    return submittalAddlAssigneesApi
      .updateSubmittalAddlAssignee(id, values)
      .then((response) => {
        if (response.ok) {
          dispatch(
            respondSubmittalAddlAssigneeSuccess(response.submittalAddlAssignee)
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setSubmittalNotes(notes) {
  return {
    type: SUBMITTAL_NOTES_SET,
    payload: notes,
  };
}

export function clearSubmittalNotes() {
  return {
    type: SUBMITTAL_NOTES_SET,
    payload: [],
  };
}

export function loadSubmittalNotes(submittalId) {
  return (dispatch) => {
    return submittalsApi.getNotes(submittalId).then((response) => {
      if (response.ok) {
        dispatch(setSubmittalNotes(response.notes));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function markSubmittalNotesRead(submittalId, form, userId) {
  return (dispatch) => {
    return submittalsApi.markNotesRead(submittalId).then((response) => {
      if (response.ok) {
        const notesReadBy = form.notesReadBy.concat(userId.toString());
        dispatch(updateSubmittalFormValue('notesReadBy', notesReadBy));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function addSubmittalNote(note) {
  return {
    type: SUBMITTAL_NOTES_ADD,
    payload: note,
  };
}

export function createSubmittalNote(submittalId, content) {
  return (dispatch) => {
    return submittalsApi.createNote(submittalId, content).then((response) => {
      if (response.ok) {
        dispatch(addSubmittalNote(response.note));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function makeSubmittalForm(submittal) {
  return {
    ...submittal,
    submittedBy: submittal.submitted_by,
    csiCodeId: submittal.csi_code.id,
    csiCodeLabel: `${submittal.csi_code.division} - ${submittal.csi_code.label}`,
    contractorId: submittal.contractor_id || '',
    physicalCopies: (submittal.physical_copies || 0).toString(),
    assignee: submittal.assignee
      ? [submittal.assignee.id, User.fullNameWithCompany(submittal.assignee)]
      : [],
    recipients: submittal.recipients.map((rcpt) => [
      rcpt.id,
      User.fullNameWithCompany(rcpt),
    ]),
    requestedDate: submittal.requested_at,
    dueDate: submittal.due_date,
    extendedDueDate: submittal.extended_due_date,
    stampAction: submittal.stamp_action,
    notesReadBy: submittal.notes_read_by,
    leadTimeApplies: submittal.lead_time_applies,
    leadTimeWeeks:
      submittal.lead_time_weeks !== null
        ? submittal.lead_time_weeks.toString()
        : '',
    orderDate: submittal.order_date,
    vendorDelivery: submittal.vendor_delivery,
    fieldDelivery: submittal.field_delivery,
    leadTimeReminder: submittal.lead_time_reminder,
    reqImages: [],
    reqImagesDelete: [],
    reqDocs: [],
    reqDocsDelete: [],
    resImages: [],
    resImagesDelete: [],
    resDocs: [],
    resDocsDelete: [],
    addlAssignees: submittal.submittal_addl_assignees.map((addlAssignee) => {
      return {
        id: addlAssignee.id,
        assigneeId: addlAssignee.assignee_id,
        assigneeLabel: User.fullNameWithCompany(addlAssignee.assignee),
        dueDate: addlAssignee.due_date,
        returnedAt: addlAssignee.returned_at,
        stampAction: addlAssignee.stamp_action,
        stampTitle: addlAssignee.stamp_title,
      };
    }),
    errors: {},
  };
}

export function setCurrentSubmittal(submittal) {
  return (dispatch) => {
    dispatch(setSubmittalForm(makeSubmittalForm(submittal)));
  };
}

export function openSubmittal(projectId, submittalId, user) {
  return (dispatch) => {
    dispatch(initSubmittalModal());
    dispatch(loadSubmittalRequest(projectId, submittalId)).then((response) => {
      if (response.ok) {
        const { submittal } = response;
        const readOnly = Submittal.readOnly(submittal, user);

        dispatch(setCurrentSubmittal(submittal));
        dispatch(setSubmittalReadOnly(readOnly));
        dispatch(setSubmittalLeadTimeReadOnly(true));
        dispatch(setSubmittalModalShow(true));
      }
    });
  };
}

export function makeRevisedSubmittal(submittal) {
  return {
    ...submittal,
    newRevision: true,
    revised_submittal_id: submittal.id,
    status: SubmittalStatusKeys.PENDING,
    closed: false,
    submitted_by: null,
    submitted_at: null,
    responded_by: null,
    stamp_action: null,
    stamp_title: null,
    response_documents: [],
    response_images: [],
    remarks: null,
    submittal_addl_assignees: submittal.submittal_addl_assignees.map(
      (addlAssignee) => ({
        ...addlAssignee,
        id: null,
        stamp_action: null,
        stamp_title: null,
        response_documents: [],
        response_images: [],
      })
    ),
  };
}

export function reviseSubmittal(projectId, submittalId, user) {
  return (dispatch) => {
    dispatch(initSubmittalModal());
    dispatch(
      loadSubmittalRequest(projectId, submittalId, makeRevisedSubmittal)
    ).then((response) => {
      if (response.ok) {
        const { submittal } = response;
        dispatch(setCurrentSubmittal(submittal));
        dispatch(setSubmittalModalShow(true));
      }
    });
  };
}

export function setSubmittalLogPdfViewer(pdfViewer) {
  return {
    type: SUBMITTAL_LOG_PDF_VIEWER_SET,
    payload: pdfViewer,
  };
}

export function loadSubmittalLogPdfSuccess(submittalLogPdf) {
  return {
    type: SUBMITTAL_LOG_PDF_SET,
    payload: submittalLogPdf,
  };
}

export function loadSubmittalLogPdfRequest(projectId, status) {
  return (dispatch) => {
    return submittalsApi
      .getSubmittalLogPdf(projectId, status)
      .then((response) => {
        if (response.ok) {
          dispatch(loadSubmittalLogPdfSuccess(response.submittalLogPdf));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadSubmittalLeadTimeLogPdfRequest(projectId) {
  return (dispatch) => {
    return submittalsApi
      .getSubmittalLeadTimeLogPdf(projectId)
      .then((response) => {
        if (response.ok) {
          dispatch(
            loadSubmittalLogPdfSuccess(response.submittalLeadTimeLogPdf)
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setSubmittalPdfViewer(pdfViewer) {
  return {
    type: SUBMITTAL_PDF_VIEWER_SET,
    payload: pdfViewer,
  };
}

export function setSetShowSubmittalErrorMessage(showErrorMessage) {
  return {
    type: SUBMITTAL_SHOW_ERROR_MESSAGE_SET,
    payload: showErrorMessage,
  };
}

export function addSubmittalAddlAssignee(addlAssignee) {
  return {
    type: SUBMITTAL_ADDL_ASSIGNEES_ADD,
    payload: addlAssignee,
  };
}

export function updateSubmittalAddlAssignee(addlAssignee, index) {
  return {
    type: SUBMITTAL_ADDL_ASSIGNEES_UPDATE,
    payload: { addlAssignee, index },
  };
}

export function deleteSubmittalAddlAssignee(index) {
  return {
    type: SUBMITTAL_ADDL_ASSIGNEES_DELETE,
    payload: index,
  };
}

export function deleteSubmittalSuccess(submittalId) {
  return {
    type: SUBMITTALS_DELETE,
    payload: submittalId,
  };
}

export function deleteSubmittal(projectId, submittalId) {
  return (dispatch) => {
    return submittalsApi
      .deleteSubmittal(projectId, submittalId)
      .then((response) => {
        if (response.ok) {
          dispatch(deleteSubmittalSuccess(submittalId));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setSubmittalCreatedRevisionModalShow(show) {
  return {
    type: SUBMITTAL_CREATED_REVISION_MODAL_SHOW_SET,
    payload: show,
  };
}

export function loadRevisionSubmittalSuccess(submittal) {
  return {
    type: REVISION_SUBMITTAL_SET,
    payload: submittal,
  };
}

export function loadCreatedRevisionSubmittalRequest(projectId, submittalId) {
  return (dispatch) => {
    return submittalsApi
      .getSubmittal(projectId, submittalId)
      .then((response) => {
        if (response.ok) {
          dispatch(loadRevisionSubmittalSuccess(response.submittal));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}
