import React from 'react';
import { ThemeProvider } from '@mui/material';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import { theme, themeDark } from './miu-theme';

const MiuThemeProvider = ({ darkMode, children }) => {
  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      {children}
    </ThemeProvider>
  );
};

export default connect((state) => ({ darkMode: getDarkMode(state) }))(
  MiuThemeProvider
);
