import {
  OWNER_AUTHORIZED_REPS_PAGE_CHANGE,
  OWNER_AUTHORIZED_REP_PROJECTS_PAGE_CHANGE,
  OWNER_AUTHORIZED_REPS_CONTACTS_MODAL_SHOW,
  OWNER_AUTHORIZED_REPS_INVITE_FORM_CLEAR,
  OWNER_AUTHORIZED_REPS_INVITE_USER_SHOW,
  OWNER_AUTHORIZED_REPS_NEW_FORM_VALID,
  OWNER_AUTHORIZED_REPS_NEW_FORM_INVALID,
  OWNER_AUTHORIZED_REPS_NEW_FORM_VALUE_CHANGE,
} from 'components/admin/owner-authorized-reps/store/action-types';

const initialState = {
  currentPage: 1,
  showContacts: false,
  projectsCurrentPage: 1,
  invalidFields: [],
  form: {
    companyName: '',
    licenseNumber: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    streetAddress: '',
    unitNumber: '',
    city: '',
    state: '',
    zipCode: '',
    confirmed: false,
    roleId: '', // for user invite
  },
  validationMessage: '',
  showInviteOwnerAuthorizedRepUserModal: false,
};

export default function ownerAuthorizedRepsUiReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case OWNER_AUTHORIZED_REPS_PAGE_CHANGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case OWNER_AUTHORIZED_REP_PROJECTS_PAGE_CHANGE:
      return {
        ...state,
        projectsCurrentPage: action.payload,
      };

    case OWNER_AUTHORIZED_REPS_CONTACTS_MODAL_SHOW:
      return {
        ...state,
        showContacts: action.payload,
      };

    case OWNER_AUTHORIZED_REPS_INVITE_FORM_CLEAR:
      return {
        ...state,
        form: initialState.form,
        invalidFields: [],
        validationMessage: '',
      };

    case OWNER_AUTHORIZED_REPS_INVITE_USER_SHOW:
      return {
        ...state,
        showInviteOwnerAuthorizedRepUserModal: action.payload,
      };

    case OWNER_AUTHORIZED_REPS_NEW_FORM_VALID:
      return {
        ...state,
        invalidFields: [],
        validationMessage: '',
      };

    case OWNER_AUTHORIZED_REPS_NEW_FORM_INVALID:
      return {
        ...state,
        invalidFields: action.payload.invalidFields,
        validationMessage: action.payload.message,
      };

    case OWNER_AUTHORIZED_REPS_NEW_FORM_VALUE_CHANGE:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.attribute]: action.payload.value,
        },
      };

    default:
      return state;
  }
}
