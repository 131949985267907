export const dropdownOptions = (options, sort = false) =>
  (sort ? options.sort() : options).map((option) => ({
    value: option,
    label: option,
  }));

export const multipleDropdownOptions = (options, sort = false) =>
  (sort ? options.sort() : options).map((option) => ({
    id: option,
    label: option,
  }));

export const multipleDropdownValues = (options) =>
  options.map((option) =>
    typeof option === 'object' ? [option.id, option.label] : [option]
  );

export const sortDropdownOptions = (options) =>
  options.sort((option1, option2) =>
    option1.label.localeCompare(option2.label)
  );
