import { useEffect, useRef, useState } from 'react';

const useWindowDragAndDrop = (props) => {
  const { disabled } = props;
  const [dragging, setDragging] = useState(false);
  const dragCounter = useRef(0);

  useEffect(() => {
    if (!disabled) startListeningDrop();
    else stopListeningDrop();
    return () => stopListeningDrop();
  }, [disabled]);

  const startListeningDrop = () => {
    document.addEventListener('dragenter', handleDragIn, false);
    document.addEventListener('dragleave', handleDragOut, false);
    dragCounter.current = 0;
  };
  const stopListeningDrop = () => {
    document.removeEventListener('dragenter', handleDragIn, false);
    document.removeEventListener('dragleave', handleDragOut, false);
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.types.includes('Files')) {
      dragCounter.current++;
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0)
        setDragging(true);
    }
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current <= 0) setDragging(false);
  };

  return { setDragging, dragging };
};

export default useWindowDragAndDrop;
