import React, { Component } from 'react';
import SimpleTable from './SimpleTable';
import { WorkOrderStatusEnum } from 'services/utils/work-order-status-enum';
import PropTypes from 'prop-types';
import Number from 'components/shared/text/Number';
import Date from 'components/shared/text/Date';
import SimpleText from 'components/shared/text/SimpleText';
import './WorkOrdersTable.css';
import BaseStatus from 'components/shared/text/BaseStatus';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import { WorkOrder } from 'domain/work-order';
import User from 'domain/user';

/**
 * Simple table for work orders with pagination. Shows fixed headers.
 */
class WorkOrdersTable extends Component {
  static propTypes = {
    currentFilter: PropTypes.string,
    currentPage: PropTypes.number.isRequired,
    currentUser: PropTypes.object,
    pageSize: PropTypes.number.isRequired,
    showFilter: PropTypes.bool,
    totals: PropTypes.shape({
      all: PropTypes.number.isRequired,
      draft: PropTypes.number,
      pending: PropTypes.number,
      inProgress: PropTypes.number,
      complete: PropTypes.number,
      closed: PropTypes.number,
    }).isRequired,
    workOrderMarkById: PropTypes.object, // [key: id, value: bool]
    workOrders: PropTypes.arrayOf(PropTypes.any).isRequired,
    onFilterChange: PropTypes.func,
    onPageChange: PropTypes.func.isRequired,
    onWorkOrderSelection: PropTypes.func.isRequired,
  };

  static defaultProps = {
    currentUser: null,
    showFilter: true,
    workOrderMarkById: {},
  };

  handleGenerateLink = (index) => {
    const { workOrders } = this.props;
    return `work-orders/${workOrders[index].id}`;
  };

  render() {
    const { currentUser } = this.props;

    const isContractor = currentUser !== null && User.isContractor(currentUser);
    const isClient = currentUser !== null && User.isClient(currentUser);

    const showAmount = !isContractor;
    const showVariance =
      currentUser !== null && User.isAdminOrSubscriber(currentUser);
    const showJobNumber = isContractor;
    const showClientName = !isClient;

    let headers = [
      {
        title: '',
        show: () => true,
      },
      {
        title: 'WO #',
        show: () => true,
      },
      {
        title: 'JOB #',
        show: () => showJobNumber,
      },
      {
        title: 'NAME',
        show: () => true,
      },
      {
        title: 'OWNER NAME',
        show: () => showClientName,
      },
      {
        title: isContractor ? 'GENERAL CONTRACTOR' : 'CONTRACTOR',
        show: () => true,
      },
      {
        title: 'LOCATION',
        show: () => true,
      },
      {
        title: 'STATUS',
        show: () => true,
      },
      {
        title: 'START DATE',
        show: () => true,
      },
      {
        title: 'END DATE',
        show: () => true,
      },
      {
        title: 'WO AMOUNT',
        show: () => showAmount,
      },
      {
        title: 'CREATED AT',
        show: () => true,
      },
      {
        title: 'VARIANCE',
        show: () => showVariance,
      },
    ];

    headers = headers.filter((item) => item.show()).map((item) => item.title);
    return (
      <div className="wo-table-container">
        <SimpleTable
          className="work-orders-table"
          headers={headers}
          rows={this.mapWorkOrders(
            this.props.workOrders,
            showAmount,
            showVariance,
            showJobNumber,
            showClientName
          )}
          emptyMessage="No Work Orders"
          onRowSelected={this.props.onWorkOrderSelection}
          onGenerateLink={this.handleGenerateLink}
        />

        <div className="pagination-container">
          <SimplePagination
            currentPage={this.props.currentPage}
            pageSize={this.props.pageSize}
            total={this.props.totals.all}
            onPageChange={this.props.onPageChange}
          />
        </div>
      </div>
    );
  }

  handleFilterSelect = (option) => {
    if (this.props.onFilterChange !== undefined) {
      this.props.onFilterChange(option);
    }
  };

  buildContractorName = (wo) => {
    const { currentUser } = this.props;
    const isContractor = currentUser !== null && User.isContractor(currentUser);
    const isGc = currentUser !== null && User.isGeneralContractor(currentUser);
    let contractorName = '';
    if (isContractor) {
      contractorName = wo.general_contractor_name;
    } else if (isGc) {
      contractorName = wo.contractor_name;
    } else {
      contractorName = wo.general_contractor_name || wo.contractor_name;
    }
    return contractorName || 'N/A';
  };

  mapWorkOrders = (
    workOrders,
    showAmount,
    showVariance,
    showJobNumber,
    showClientName
  ) => {
    return workOrders.map((element) => {
      const showMark = this.props.workOrderMarkById[element.id] !== undefined;
      const startDate = WorkOrder.getStartDate(element);
      const completionDate = WorkOrder.getCompletionDate(element);
      const amount = WorkOrder.getTotalCost(element);

      let row = [
        {
          content: (
            <BaseStatus
              labels={[
                {
                  id: 'read',
                  label: '',
                },
                {
                  id: 'unread',
                  label: '',
                },
              ]}
              status={showMark ? 'unread' : 'read'}
            />
          ),
          show: () => true,
        },
        {
          content: (
            <div className={showMark ? 'small-bold' : null}>
              {element.number.toString()}
            </div>
          ),
          show: () => true,
        },
        {
          content: WorkOrder.getJobNumber(element, 'N/A'),
          show: () => showJobNumber,
        },
        {
          content: WorkOrder.getName(element, 'N/A'),
          show: () => true,
        },
        {
          content: (
            <span className="company-name-cell">
              {element.client.company_name}
            </span>
          ),
          show: () => showClientName,
        },
        {
          content: this.buildContractorName(element),
          show: () => true,
        },
        {
          content: (
            <SimpleText
              lines={[
                element.building.location.street_address_1,
                element.building.location.city,
              ]}
            />
          ),
          show: () => true,
        },
        {
          content: WorkOrderStatusEnum.create(element.status).label,
          show: () => true,
        },
        {
          content:
            startDate !== null ? <Date value={startDate} format="ll" /> : 'TBD',
          show: () => true,
        },
        {
          content:
            completionDate !== null ? (
              <Date value={completionDate} format="ll" />
            ) : (
              'TBD'
            ),
          show: () => true,
        },
        {
          content:
            amount !== null ? <Number value={amount} format="$0,0" /> : 'TBD',
          show: () => showAmount,
        },
        {
          content:
            element.created_at != null && element.created_at !== null ? (
              <Date value={element.created_at} format="ll" />
            ) : (
              'N/A'
            ),
          show: () => true,
        },
        {
          content: WorkOrder.getVariance(element, 'N/A'),
          show: () => showVariance,
        },
      ];

      row = row.filter((item) => item.show()).map((item) => item.content);

      return row;
    });
  };
}

export default WorkOrdersTable;
