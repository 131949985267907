import React from 'react';
import { DatePicker as MiuDatePicker } from '@mui/x-date-pickers';

import { IconButton, InputAdornment } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import colors from 'styles/colors';
import iconCalendar from 'images/calendar.svg';
import iconCalendarDark from 'images/calendarDarkMode.svg';
import remove from 'images/cancel.svg';
import removeDark from 'images/cancel-dark.svg';
import { useDebouncedCallback } from 'use-debounce';

import './DatePicker.css';
export const TYPING_TIMEOUT = 500;

const DatePicker = (props) => {
  const {
    value,
    onChange,
    calendarIcon,
    calendarIconDark,
    darkMode,
    className,
    format,
    ioFormat,
    readOnly,
    valid,
    isValidDate,
    disabled,
    clearable,
    hideUnderline,
    hideAdornment,
    editableInput,
    placeholder,
    optionFontWeight,
  } = props;

  const handleChange = (value) => {
    let res = null;
    if (value && value.isValid()) res = value.format(ioFormat);
    if (res !== props.value) onChange(res);
  };

  const debouncedChange = useDebouncedCallback(handleChange, TYPING_TIMEOUT);

  const handleValueChange = (value) => {
    if (editableInput) debouncedChange(value);
    else handleChange(value);
  };

  const shouldDisableDate = (d) => !isValidDate(d);

  const inputStyles = {
    '& .MuiInputBase-root': {
      height: 40,
      paddingLeft: hideUnderline ? 0 : 1,
      paddingRight: hideUnderline ? 0 : 1.25,
      '&:before': {
        display: hideUnderline || readOnly ? 'none' : 'block',
        borderColor: darkMode
          ? colors['dark-mode-light-white']
          : colors['light-mode-dark-black'],
      },
      '&:hover:not(.Mui-disabled, .Mui-error):before': {
        borderWidth: 1,
        borderColor: darkMode ? colors['neon'] : colors['primary-green'],
      },
      '&.Mui-error:before': {
        borderColor: darkMode ? colors['chart-blue'] : colors['chart-red'],
      },
      '&:after': { display: 'none' },
    },
    '& .MuiInputBase-input': {
      fontSize: 16,
      fontWeight: optionFontWeight,
      '&::placeholder': {
        color: darkMode
          ? colors['white-text']
          : colors['light-mode-black-text'],
        opacity: 0.7,
      },
    },
  };

  const paperStyles = {
    '& .MuiDateCalendar-root': { width: 270, maxHeight: 300 },
    '& .MuiYearCalendar-root': { width: 270, maxHeight: 215 },
    '& .MuiPickersYear-yearButton, & .MuiPickersDay-root': {
      '&.MuiPickersDay-root': { width: 30, height: 30 },
      '&.MuiPickersYear-yearButton': {
        width: 60,
        height: 30,
        fontSize: '1.45rem',
        lineHeight: 1.95,
      },
      '&:hover': {
        backgroundColor: darkMode
          ? 'rgba(255,255,255,0.05)'
          : 'rgba(0,0,0,0.05)',
      },
      '&:focus': {
        backgroundColor: darkMode
          ? 'rgba(255,255,255,0.15)'
          : 'rgba(0,0,0,0.15)',
      },
      '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus, &.Mui-selected:active': {
        color: 'white',
        backgroundColor: darkMode ? colors['chart-blue'] : colors['chart-red'],
      },
    },
    '& .MuiDayCalendar-weekDayLabel': { width: 30, height: 32 },
  };
  const adormentStyles = { paddingLeft: 0, paddingRight: 0 };

  return (
    <div className={`date-picker${readOnly ? ' readonly' : ''} ${className}`}>
      <MiuDatePicker
        value={value ? moment(value, ioFormat) : null}
        readOnly={readOnly}
        onChange={handleValueChange}
        format={format}
        slotProps={{
          textField: {
            variant: 'standard',
            placeholder: placeholder,
            sx: inputStyles,
            readOnly: !editableInput || readOnly,
            error: !valid,
          },
          desktopPaper: { sx: paperStyles },
        }}
        shouldDisableDate={
          isValidDate && !readOnly && !disabled ? shouldDisableDate : undefined
        }
        yearsPerRow={3}
        dayOfWeekFormatter={(day) =>
          day.charAt(0).toUpperCase() + day.charAt(1).toLowerCase()
        }
        disabled={disabled}
        showDaysOutsideCurrentMonth
        slots={{
          inputAdornment: ({ children, position }) => {
            if (hideAdornment) return null;
            return (
              <InputAdornment {...children.props} position={position}>
                <IconButton disableRipple style={adormentStyles}>
                  <img
                    className="icon-image"
                    src={darkMode ? calendarIconDark : calendarIcon}
                    alt="adorment"
                  />
                </IconButton>
              </InputAdornment>
            );
          },
        }}
      />
      {value && clearable && !readOnly && (
        <img
          className={`date-picker-remove ${
            hideAdornment && 'hidden-adornment'
          } ${hideUnderline && 'hidden-underline'}`}
          src={darkMode ? removeDark : remove}
          alt="remove"
          onClick={(event) => {
            event.preventDefault();
            handleChange(null);
          }}
        />
      )}
    </div>
  );
};

DatePicker.propTypes = {
  value: PropTypes.string, // yyyy-mm-dd
  onChange: PropTypes.func,
  className: PropTypes.string,
  format: PropTypes.string,
  ioFormat: PropTypes.string,
  darkMode: PropTypes.bool,
  calendarIcon: PropTypes.node,
  calendarIconDark: PropTypes.node,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  isValidDate: PropTypes.func,
  hideUnderline: PropTypes.bool,
  clearable: PropTypes.bool,
  hideAdornment: PropTypes.bool,
  valid: PropTypes.bool,
  editableInput: PropTypes.bool,
  placeholder: PropTypes.string,
  optionFontWeight: PropTypes.number,
};

DatePicker.defaultProps = {
  className: '',
  format: 'MM/DD/YYYY',
  ioFormat: 'YYYY-MM-DD',
  isValidDate: () => true,
  readOnly: false,
  disabled: false,
  clearable: false,
  darkMode: true,
  calendarIcon: iconCalendar,
  calendarIconDark: iconCalendarDark,
  hideUnderline: false,
  hideAdornment: false,
  editableInput: true,
  valid: true,
  optionFontWeight: 400,
  onChange: () => {},
};

export default connect((state) => ({ darkMode: getDarkMode(state) }))(
  DatePicker
);
