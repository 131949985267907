import React, { Component } from 'react';
import './EnterpriseSidebarMenu.css';
import EnterpriseSidebarItem from './EnterpriseSidebarItem';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faAngleDown from '@fortawesome/fontawesome-free-solid/faAngleDown';
import faAngleUp from '@fortawesome/fontawesome-free-solid/faAngleUp';

class EnterpriseSidebarMenu extends Component {
  static propTypes = {
    menu: PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string,
      selected: PropTypes.bool,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        label: PropTypes.string,
        route: PropTypes.string,
        selected: PropTypes.bool,
        show: PropTypes.bool,
        customAction: PropTypes.func,
        customClass: PropTypes.string,
      })
    ),
    onItemSelected: PropTypes.func,
    onGenerateLink: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      showMenu: true,
    };
  }

  handleClickMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      showMenu: !this.state.showMenu,
    });
  };

  render() {
    const { onItemSelected, onGenerateLink } = this.props;
    return (
      <div
        className={`enterprise-menu ${
          this.props.menu.selected ? 'selected' : ''
        } ${this.state.showMenu ? 'toggled' : ''}`}
      >
        <div className="sidebar-menu-row">
          <div className="sidebar-menu-cell" onClick={this.handleClickMenu}>
            <div className="icon">
              <img src={this.props.menu.icon} alt="icon" />
            </div>
            <span>{this.props.menu.label}</span>
            <span className="toggle">
              <FontAwesomeIcon
                icon={this.state.showMenu ? faAngleUp : faAngleDown}
              />
            </span>
          </div>
        </div>

        <Collapse in={this.state.showMenu} className="collapse-info">
          <div>
            {this.props.items
              .filter((element) => element.show)
              .map((element, index) => {
                return (
                  <EnterpriseSidebarItem
                    key={index}
                    id={element.icon}
                    label={element.label}
                    selected={element.selected}
                    onSelected={element.customAction || onItemSelected}
                    onGenerateLink={onGenerateLink}
                    customClass={element.customClass}
                  />
                );
              })}
          </div>
        </Collapse>
      </div>
    );
  }
}

export default EnterpriseSidebarMenu;
