import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReadOnlyInput from './ReadOnlyInput';
import './TextArea.css';

class TextArea extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    theme: PropTypes.string,
    valid: PropTypes.bool,
    value: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    className: null,
    required: false,
    valid: true,
    value: '',
  };

  handleOnBlur = (event) => {
    if (this.props.onBlur !== undefined) {
      this.props.onBlur(event.target.value);
    }
  };

  handleOnChange = (event) => {
    if (this.props.onChange !== undefined) {
      this.props.onChange(event.target.value);
    }
  };

  render() {
    return this.props.readOnly ? (
      <ReadOnlyInput
        className={
          'text-area' +
          (this.props.className !== null ? ' ' + this.props.className : '')
        }
        value={this.props.value}
      />
    ) : (
      <textarea
        className={
          'text-area form-control input-area' +
          (this.props.className !== null ? ' ' + this.props.className : '') +
          (this.props.required ? ' required' : '') +
          (!this.props.valid ? ' invalid' : '')
        }
        disabled={this.props.disabled}
        placeholder={this.props.placeholder}
        required={this.props.required}
        value={this.props.value}
        onBlur={this.handleOnBlur}
        onChange={this.handleOnChange}
      />
    );
  }
}

export default TextArea;
