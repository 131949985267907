import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {
  faChevronUp,
  faChevronDown,
} from '@fortawesome/fontawesome-free-solid';
import PropTypes from 'prop-types';

import { getDarkMode } from 'selectors/theme';
import './CollapsibleSection.css';
import colors from 'styles/colors';

const CollapsibleSection = (props) => {
  const {
    title,
    children,
    right,
    initialShow,
    darkMode,
    disabled,
    iconPosition,
    className,
    withContainer,
    onShow,
    showChevronDisabled,
    visible,
  } = props;
  const [show, setShow] = useState(initialShow || visible);

  useEffect(() => setShow(visible), [visible]);

  const colorChevron =
    showChevronDisabled && disabled
      ? darkMode
        ? colors['light-mode-medium-black']
        : colors['dark-mode-light-white']
      : darkMode
      ? colors.neon
      : colors['primary-green'];

  const icon = (!disabled || showChevronDisabled) && (
    <FontAwesomeIcon
      icon={show ? faChevronUp : faChevronDown}
      onClick={() => (disabled ? null : setShow(!show))}
      color={colorChevron}
      className={`collapsible-icon ${iconPosition}`}
      size="1x"
    />
  );

  const handleShow = () => {
    setShow(!show);
    if (typeof onShow === 'function') onShow(!show);
  };

  const header = (
    <div
      className={`collapsible-header ${className ? className + '-header' : ''}`}
    >
      <div
        className={`collapsible-title ${!disabled ? 'active' : ''}`}
        onClick={() => (!disabled ? handleShow() : null)}
      >
        {iconPosition === 'start' && icon}
        <label className="collapsible-label">{title}</label>
        {iconPosition === 'default' && icon}
      </div>
      {typeof right !== 'undefined' && (
        <div className="collapsible-right">{right}</div>
      )}
      {iconPosition === 'end' && icon}
    </div>
  );

  const content = show && (
    <div
      className={`collapsible-body  ${className ? className + '-body' : ''}`}
    >
      {children}
    </div>
  );

  if (!withContainer) {
    return (
      <Fragment>
        {header}
        {content}
      </Fragment>
    );
  }

  return (
    <div className={`collapsible-section ${className}`}>
      {header}
      {content}
    </div>
  );
};

CollapsibleSection.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  right: PropTypes.node,
  initialShow: PropTypes.bool,
  darkMode: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['start', 'end', 'default']),
  withContainer: PropTypes.bool,
  onShow: PropTypes.func,
  showChevronDisabled: PropTypes.bool,
  visible: PropTypes.bool,
};

CollapsibleSection.defaultProps = {
  iconPosition: 'default',
  disabled: false,
  initialShow: false,
  right: null,
  className: '',
  withContainer: true,
  onShow: null,
  showChevronDisabled: false,
  visible: false,
};

export default connect((state) => ({ darkMode: getDarkMode(state) }))(
  CollapsibleSection
);
