import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getNotifications } from 'selectors/notifications';
import {
  getDocumentation,
  getDocumentationCount,
} from 'components/admin/projects/details/project-documentation/store/selectors';
import { markAllAsRead } from 'actions/notifications';
import {
  loadDocumentationRequest,
  loadDocumentationCountRequest,
  updateDocumentationRequest,
  resetDocumentation,
} from 'components/admin/projects/details/project-documentation/store/actions';
import DocumentsTable from 'components/admin/projects/details/project-documentation/DocumentsTable';
import HyperlinksTable from 'components/admin/projects/details/project-documentation/HyperlinksTable';
import AdvancedDocumentsTable from 'components/admin/projects/details/project-documentation/AdvancedDocumentsTable';
import User from 'domain/user';

const per_page = 5;
const drawingDocuments = [
  { name: 'bidding_documents', param: 'biddingDocuments', label: 'Bidding' },
  {
    name: 'plan_check_documents',
    param: 'planCheckDocuments',
    label: 'Plan Check',
  },
  { name: 'ifc_documents', param: 'ifcDocuments', label: 'IFC' },
  {
    name: 'bulletin_arch_documents',
    param: 'bulletinArchDocuments',
    label: 'Bulletin Arch',
  },
  {
    name: 'bulletin_structural_documents',
    param: 'bulletinStructuralDocuments',
    label: 'Bulletin Structural',
  },
  {
    name: 'bulletin_mep_documents',
    param: 'bulletinMepDocuments',
    label: 'Bulletin MEP',
  },
  { name: 'sketches_documents', param: 'sketchesDocuments', label: 'Sketches' },
  { name: 'ffe_documents', param: 'ffeDocuments', label: 'AVITS / FFE' },
];
const documents = [
  {
    name: 'building_documents',
    param: 'buildingDocuments',
    label: 'Building Rules & Regs / COI Requirements',
  },
  {
    name: 'drawing_documents',
    param: 'drawingDocuments',
    label: 'Drawings',
    subCategories: drawingDocuments,
  },
  {
    name: 'bulletin_documents',
    param: 'bulletinDocuments',
    label: 'Drawing Logs',
  },
  { name: 'permit_documents', param: 'permitDocuments', label: 'Permits' },
  { name: 'schedule_documents', param: 'scheduleDocuments', label: 'Schedule' },
  { name: 'coi_documents', param: 'coiDocuments', label: 'COIs' },
  { name: 'old_coi_documents', param: 'oldCoiDocuments', label: 'Old COIs' },
  {
    name: 'change_estimate_documents',
    param: 'changeEstimateDocuments',
    label: 'Change Estimate Logs',
  },
  { name: 'cad_documents', param: 'cadDocuments', label: 'CADs' },
  { name: 'misc_documents', param: 'miscDocuments', label: 'Misc' },
];

class ProjectDocumentation extends Component {
  constructor(props) {
    super(props);

    this.state = { showRemoveConfirmation: false };
  }

  componentDidMount() {
    const { currentProject } = this.props;
    if (currentProject) {
      this.documentsCount();
      this.hyperlinksLoad();
      documents
        .filter((docs) => this.showDocs(docs) && docs.param !== 'coiDocuments')
        .forEach((docs) => this.pageChange(1, docs));
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetDocumentation());
  }

  showDocs = (docs) => {
    const { currentUser, currentProject } = this.props;
    return (
      !!currentProject &&
      (docs.param !== 'changeEstimateDocuments' ||
        !User.isContractor(currentUser, currentProject))
    );
  };

  documentsReadOnly = () => {
    const { currentUser, currentProject } = this.props;
    return (
      !!currentProject &&
      !User.isAdminProjectSubscriberGcOar(currentUser, currentProject)
    );
  };

  documentsCategoryReadOnly = (docs) => {
    const { currentUser, currentProject } = this.props;
    const archOrLandlordOrSub =
      User.isArchitect(currentUser, currentProject) ||
      User.isLandlord(currentUser, currentProject) ||
      User.isContractor(currentUser, currentProject);

    return (
      (!archOrLandlordOrSub || docs.param !== 'cadDocuments') &&
      (!User.isArchitect(currentUser, currentProject) ||
        docs.param !== 'bulletinDocuments')
    );
  };

  buildCatParams = (page, category) => {
    return { category: category.name, page, per_page };
  };

  hyperlinksLoad = () => {
    const { currentProject, dispatch } = this.props;
    dispatch(
      loadDocumentationRequest(currentProject.id, { category: 'hyperlinks' })
    );
  };

  pageChange = (page, category) => {
    const { currentProject, dispatch } = this.props;
    dispatch(
      loadDocumentationRequest(
        currentProject.id,
        this.buildCatParams(page, category)
      )
    );
  };

  documentsCount = () => {
    const { currentProject, dispatch } = this.props;
    dispatch(loadDocumentationCountRequest(currentProject.id));
  };

  updateHyperlinks = (hyperlinks) => {
    const { currentProject, dispatch } = this.props;
    dispatch(
      updateDocumentationRequest(
        currentProject.id,
        { hyperlinks: hyperlinks },
        { category: 'hyperlinks' }
      )
    );
  };

  parseDocuments = (files) => {
    return files.map(function (val) {
      return {
        file: val,
        name: val.name,
      };
    });
  };

  dropDocuments = (files, category) => {
    const { currentProject, dispatch } = this.props;
    const { param } = category;
    const values = { [param]: this.parseDocuments(files) };
    dispatch(
      updateDocumentationRequest(
        currentProject.id,
        values,
        this.buildCatParams(1, category)
      )
    );
  };

  confirmRemoveDocument = (id, page, category) => {
    this.setState({ showRemoveConfirmation: true, id, page, category });
  };

  removeDocument = (confirmed) => {
    if (confirmed) {
      const { currentProject, dispatch } = this.props;
      const { id, page, category } = this.state;
      const { param } = category;
      const values = { [param]: [{ id: id, destroy: true }] };
      dispatch(
        updateDocumentationRequest(
          currentProject.id,
          values,
          this.buildCatParams(page, category)
        )
      );
    }
    this.setState({ showRemoveConfirmation: false });
  };

  documentRead = (doc) => {
    const { notifications, dispatch } = this.props;
    notifications.content
      .filter((notification) => {
        return (
          !notification.read_at &&
          notification.notifiable_type === 'DocumentNotification' &&
          notification.notifiable.document_id === doc.id
        );
      })
      .forEach((notification) => {
        return dispatch(
          markAllAsRead(
            undefined,
            notification.notifiable.id,
            notification.notifiable_type
          )
        );
      });

    return null;
  };

  unreadNotifications = (cat, doc) => {
    const { notifications, documentationCount } = this.props;
    return !!notifications.content.find((notification) => {
      return (
        !notification.read_at &&
        notification.notifiable_type === 'DocumentNotification' &&
        notification.notifiable.documentable_id === documentationCount.id &&
        ((cat && `${notification.notifiable.document_label}s` === cat.name) ||
          (doc && notification.notifiable.document_id === doc.id))
      );
    });
  };

  mapDocuments = (category) => {
    const { documentation, documentationCount } = this.props;
    const docs = {};
    docs[category.name] = documentation[category.name];
    docs[`${category.name}_count`] =
      documentationCount[`${category.name}_count`];
    (category.subCategories || []).forEach((subCat) => {
      docs[subCat.name] = documentation[subCat.name];
      docs[`${subCat.name}_count`] = documentationCount[`${subCat.name}_count`];
      docs[`${subCat.name}_unread`] = this.unreadNotifications(subCat);
      (docs[subCat.name] || []).forEach((doc) => {
        docs[`${subCat.name}_unread_${doc.id}`] = this.unreadNotifications(
          null,
          doc
        );
      });
    });

    return docs;
  };

  renderHeaderButtons() {}

  render() {
    const {
      documentation,
      documentationCount,
      currentProject,
      renderHeaders,
    } = this.props;
    const { showRemoveConfirmation } = this.state;
    const readOnly = this.documentsReadOnly();
    const bucket = `${process.env.REACT_APP_ADVANCED_DOCUMENTS_S3_BUCKET}`;

    return (
      <React.Fragment>
        {currentProject && currentProject.advanced_document_view ? (
          <AdvancedDocumentsTable
            bucket={bucket}
            region="us-east-1"
            prefix={currentProject.advanced_document_view_root_directory}
            delimiter="/"
            renderHeaders={renderHeaders}
          />
        ) : (
          <div className="content-container project-documentation">
            {renderHeaders(this.renderHeaderButtons())}
            {documentation.hyperlinks && (
              <HyperlinksTable
                hyperlinks={documentation.hyperlinks}
                readOnly={readOnly}
                handleUpdate={this.updateHyperlinks}
                handleLoad={this.hyperlinksLoad}
              />
            )}
            {documentationCount &&
              documents
                .filter((docs) => this.showDocs(docs))
                .map((docs, index) => {
                  return (
                    docs.param !== 'coiDocuments' &&
                    documentation[docs.name] && (
                      <DocumentsTable
                        key={index}
                        category={docs}
                        perPage={per_page}
                        readOnly={
                          (readOnly && this.documentsCategoryReadOnly(docs)) ||
                          docs.param === 'oldCoiDocuments'
                        }
                        handleDrop={this.dropDocuments}
                        handleRemove={this.confirmRemoveDocument}
                        handlePageChange={this.pageChange}
                        handleDocumentRead={this.documentRead}
                        {...this.mapDocuments(docs)}
                      />
                    )
                  );
                })}
            {showRemoveConfirmation && (
              <ConfirmationModal
                show={showRemoveConfirmation}
                message="Are you sure you want to delete this document?"
                onHide={this.removeDocument}
              />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    documentation: getDocumentation(state),
    documentationCount: getDocumentationCount(state),
    notifications: getNotifications(state),
  };
})(ProjectDocumentation);
