const timezonesArray = [
  {
    value: 'PST-08:00',
    label: 'Pacific Standard Time -08:00',
    timezone_name: 'Pacific Standard Time',
  },
  {
    value: 'MST-07:00',
    label: 'Mountain Standard Time -07:00',
    timezone_name: 'Mountain Standard Time',
  },
  {
    value: 'CST-06:00',
    label: 'Central Standard Time (North America) -06:00',
    timezone_name: 'Central Standard Time (North America)',
  },
  {
    value: 'EST-05:00',
    label: 'Eastern Standard Time (North America) -05:00',
    timezone_name: 'Eastern Standard Time (North America)',
  },
  {
    value: 'BIT-12:00',
    label: 'Baker Island Time -12:00',
    timezone_name: 'Baker Island Time',
  },
  {
    value: 'IDLW-12:00',
    label: 'International Day Line West time zone -12:00',
    timezone_name: 'International Day Line West time zone',
  },
  { value: 'NUT-11:00', label: 'Niue Time -11:00', timezone_name: 'Niue Time' },
  {
    value: 'SST-11:00',
    label: 'Samoa Standard Time -11:00',
    timezone_name: 'Samoa Standard Time',
  },
  {
    value: 'CKT-10:00',
    label: 'Cook Island Time -10:00',
    timezone_name: 'Cook Island Time',
  },
  {
    value: 'HST-10:00',
    label: 'Hawaii–Aleutian Standard Time -10:00',
    timezone_name: 'Hawaii–Aleutian Standard Time',
  },
  {
    value: 'SDT-10:00',
    label: 'Samoa Daylight Time -10:00',
    timezone_name: 'Samoa Daylight Time',
  },
  {
    value: 'TAHT-10:00',
    label: 'Tahiti Time -10:00',
    timezone_name: 'Tahiti Time',
  },
  {
    value: 'AKST-09:00',
    label: 'Alaska Standard Time -09:00',
    timezone_name: 'Alaska Standard Time',
  },
  {
    value: 'GAMT-09:00',
    label: 'Gambier Islands Time -09:00',
    timezone_name: 'Gambier Islands Time',
  },
  {
    value: 'GIT-09:00',
    label: 'Gambier Island Time -09:00',
    timezone_name: 'Gambier Island Time',
  },
  {
    value: 'HDT-09:00',
    label: 'Hawaii–Aleutian Daylight Time -09:00',
    timezone_name: 'Hawaii–Aleutian Daylight Time',
  },
  {
    value: 'MIT-09:00',
    label: 'Marquesas Islands Time -09:00',
    timezone_name: 'Marquesas Islands Time',
  },
  {
    value: 'MART-09:30',
    label: 'Marquesas Islands Time -09:30',
    timezone_name: 'Marquesas Islands Time',
  },
  {
    value: 'AKDT-08:00',
    label: 'Alaska Daylight Time -08:00',
    timezone_name: 'Alaska Daylight Time',
  },
  {
    value: 'CIST-08:00',
    label: 'Clipperton Island Standard Time -08:00',
    timezone_name: 'Clipperton Island Standard Time',
  },
  {
    value: 'PST-08:00',
    label: 'Pacific Standard Time -08:00',
    timezone_name: 'Pacific Standard Time',
  },
  {
    value: 'MST-07:00',
    label: 'Mountain Standard Time -07:00',
    timezone_name: 'Mountain Standard Time',
  },
  {
    value: 'PDT-07:00',
    label: 'Pacific Daylight Time -07:00',
    timezone_name: 'Pacific Daylight Time',
  },
  {
    value: 'EAST-06:00',
    label: 'Easter Island Standard Time -06:00',
    timezone_name: 'Easter Island Standard Time',
  },
  {
    value: 'GALT-06:00',
    label: 'Galápagos Time -06:00',
    timezone_name: 'Galápagos Time',
  },
  {
    value: 'MDT-06:00',
    label: 'Mountain Daylight Time -06:00',
    timezone_name: 'Mountain Daylight Time',
  },
  { value: 'ACT-05:00', label: 'Acre Time -05:00', timezone_name: 'Acre Time' },
  {
    value: 'CDT-05:00',
    label: 'Central Daylight Time (North America) -05:00',
    timezone_name: 'Central Daylight Time (North America)',
  },
  {
    value: 'COT-05:00',
    label: 'Colombia Time -05:00',
    timezone_name: 'Colombia Time',
  },
  {
    value: 'CST-5-05:00',
    label: 'Cuba Standard Time -05:00',
    timezone_name: 'Cuba Standard Time',
  },
  {
    value: 'EASST-05:00',
    label: 'Easter Island Summer Time -05:00',
    timezone_name: 'Easter Island Summer Time',
  },
  {
    value: 'ECT-05:00',
    label: 'Ecuador Time -05:00',
    timezone_name: 'Ecuador Time',
  },
  {
    value: 'EST-05:00',
    label: 'Eastern Standard Time (North America) -05:00',
    timezone_name: 'Eastern Standard Time (North America)',
  },
  { value: 'PET-05:00', label: 'Peru Time -05:00', timezone_name: 'Peru Time' },
  {
    value: 'AMT-04:00',
    label: 'Amazon Time -04:00',
    timezone_name: 'Amazon Time',
  },
  {
    value: 'AST-04:00',
    label: 'Atlantic Standard Time -04:00',
    timezone_name: 'Atlantic Standard Time',
  },
  {
    value: 'BOT-04:00',
    label: 'Bolivia Time -04:00',
    timezone_name: 'Bolivia Time',
  },
  {
    value: 'CDT-4-04:00',
    label: 'Cuba Daylight Time -04:00',
    timezone_name: 'Cuba Daylight Time',
  },
  {
    value: 'CLT-04:00',
    label: 'Chile Standard Time -04:00',
    timezone_name: 'Chile Standard Time',
  },
  {
    value: 'ECT-4-04:00',
    label: 'Eastern Caribbean Time -04:00',
    timezone_name: 'Eastern Caribbean Time',
  },
  {
    value: 'EDT-04:00',
    label: 'Eastern Daylight Time (North America) -04:00',
    timezone_name: 'Eastern Daylight Time (North America)',
  },
  {
    value: 'FKT-04:00',
    label: 'Falkland Islands Time -04:00',
    timezone_name: 'Falkland Islands Time',
  },
  {
    value: 'GYT-04:00',
    label: 'Guyana Time -04:00',
    timezone_name: 'Guyana Time',
  },
  {
    value: 'PYT-04:00',
    label: 'Paraguay Time -04:00',
    timezone_name: 'Paraguay Time',
  },
  {
    value: 'VET-04:00',
    label: 'Venezuelan Standard Time -04:00',
    timezone_name: 'Venezuelan Standard Time',
  },
  {
    value: 'ADT-03:00',
    label: 'Atlantic Daylight Time -03:00',
    timezone_name: 'Atlantic Daylight Time',
  },
  {
    value: 'AMST-03:00',
    label: 'Amazon Summer Time -03:00',
    timezone_name: 'Amazon Summer Time',
  },
  {
    value: 'ART-03:00',
    label: 'Argentina Time -03:00',
    timezone_name: 'Argentina Time',
  },
  {
    value: 'BRT-03:00',
    label: 'Brasília Time -03:00',
    timezone_name: 'Brasília Time',
  },
  {
    value: 'CLST-03:00',
    label: 'Chile Summer Time -03:00',
    timezone_name: 'Chile Summer Time',
  },
  {
    value: 'FKST-03:00',
    label: 'Falkland Islands Summer Time -03:00',
    timezone_name: 'Falkland Islands Summer Time',
  },
  {
    value: 'GFT-03:00',
    label: 'French Guiana Time -03:00',
    timezone_name: 'French Guiana Time',
  },
  {
    value: 'NST-03:00',
    label: 'Newfoundland Standard Time -03:00',
    timezone_name: 'Newfoundland Standard Time',
  },
  {
    value: 'NT-03:00',
    label: 'Newfoundland Time -03:00',
    timezone_name: 'Newfoundland Time',
  },
  {
    value: 'PMST-03:00',
    label: 'Saint Pierre and Miquelon Standard Time -03:00',
    timezone_name: 'Saint Pierre and Miquelon Standard Time',
  },
  {
    value: 'PYST-03:00',
    label: 'Paraguay Summer Time -03:00',
    timezone_name: 'Paraguay Summer Time',
  },
  {
    value: 'ROTT-03:00',
    label: 'Rothera Research Station Time -03:00',
    timezone_name: 'Rothera Research Station Time',
  },
  {
    value: 'SRT-03:00',
    label: 'Suriname Time -03:00',
    timezone_name: 'Suriname Time',
  },
  {
    value: 'UYT-03:00',
    label: 'Uruguay Standard Time -03:00',
    timezone_name: 'Uruguay Standard Time',
  },
  {
    value: 'BRST-02:00',
    label: 'Brasília Summer Time -02:00',
    timezone_name: 'Brasília Summer Time',
  },
  {
    value: 'FNT-02:00',
    label: 'Fernando de Noronha Time -02:00',
    timezone_name: 'Fernando de Noronha Time',
  },
  {
    value: 'GST-2-02:00',
    label: 'South Georgia and the South Sandwich Islands Time -02:00',
    timezone_name: 'South Georgia and the South Sandwich Islands Time',
  },
  {
    value: 'NDT-02:00',
    label: 'Newfoundland Daylight Time -02:00',
    timezone_name: 'Newfoundland Daylight Time',
  },
  {
    value: 'PMDT-02:00',
    label: 'Saint Pierre and Miquelon Daylight Time -02:00',
    timezone_name: 'Saint Pierre and Miquelon Daylight Time',
  },
  {
    value: 'UYST-02:00',
    label: 'Uruguay Summer Time -02:00',
    timezone_name: 'Uruguay Summer Time',
  },
  {
    value: 'AZOT-01:00',
    label: 'Azores Standard Time -01:00',
    timezone_name: 'Azores Standard Time',
  },
  {
    value: 'CVT-01:00',
    label: 'Cape Verde Time -01:00',
    timezone_name: 'Cape Verde Time',
  },
  {
    value: 'EGT-01:00',
    label: 'East Greenland Time -01:00',
    timezone_name: 'East Greenland Time',
  },
  {
    value: 'ASEAN+00:00',
    label: 'ASEAN Common Time +00:00',
    timezone_name: 'ASEAN Common Time',
  },
  {
    value: 'AET+00:00',
    label: 'Australian Eastern Time +00:00',
    timezone_name: 'Australian Eastern Time',
  },
  {
    value: 'AZOST+00:00',
    label: 'Azores Summer Time +00:00',
    timezone_name: 'Azores Summer Time',
  },
  {
    value: 'COST+00:00',
    label: 'Colombia Summer Time +00:00',
    timezone_name: 'Colombia Summer Time',
  },
  {
    value: 'CT+00:00',
    label: 'China Time +00:00',
    timezone_name: 'China Time',
  },
  {
    value: 'DFT+00:00',
    label: 'AIX-specific equivalent of Central European Time +00:00',
    timezone_name: 'AIX-specific equivalent of Central European Time',
  },
  {
    value: 'EGST+00:00',
    label: 'Eastern Greenland Summer Time +00:00',
    timezone_name: 'Eastern Greenland Summer Time',
  },
  {
    value: 'GMT+00:00',
    label: 'Greenwich Mean Time +00:00',
    timezone_name: 'Greenwich Mean Time',
  },
  {
    value: 'HAEC+00:00',
    label:
      'Heure Avancée d’Europe Centrale French-language name for CEST +00:00',
    timezone_name:
      'Heure Avancée d’Europe Centrale French-language name for CEST',
  },
  {
    value: 'BST+01:00',
    label: 'British Summer Time +01:00',
    timezone_name: 'British Summer Time',
  },
  {
    value: 'CET+01:00',
    label: 'Central European Time +01:00',
    timezone_name: 'Central European Time',
  },
  {
    value: 'IST+1+01:00',
    label: 'Irish Standard Time +01:00',
    timezone_name: 'Irish Standard Time',
  },
  {
    value: 'MET+01:00',
    label: 'Middle European Time Same zone as CET +01:00',
    timezone_name: 'Middle European Time Same zone as CET',
  },
  {
    value: 'CAT+02:00',
    label: 'Central Africa Time +02:00',
    timezone_name: 'Central Africa Time',
  },
  {
    value: 'CEST+02:00',
    label: 'Central European Summer Time +02:00',
    timezone_name: 'Central European Summer Time',
  },
  {
    value: 'EET+02:00',
    label: 'Eastern European Time +02:00',
    timezone_name: 'Eastern European Time',
  },
  {
    value: 'IST+2+02:00',
    label: 'Israel Standard Time +02:00',
    timezone_name: 'Israel Standard Time',
  },
  {
    value: 'KALT+02:00',
    label: 'Kaliningrad Time +02:00',
    timezone_name: 'Kaliningrad Time',
  },
  {
    value: 'MEST+02:00',
    label: 'Middle European Summer Time Same zone as CEST +02:00',
    timezone_name: 'Middle European Summer Time Same zone as CEST',
  },
  {
    value: 'SAST+02:00',
    label: 'South African Standard Time +02:00',
    timezone_name: 'South African Standard Time',
  },
  {
    value: 'WAST+02:00',
    label: 'West Africa Summer Time +02:00',
    timezone_name: 'West Africa Summer Time',
  },
  {
    value: 'AST+3+03:00',
    label: 'Arabia Standard Time +03:00',
    timezone_name: 'Arabia Standard Time',
  },
  {
    value: 'EAT+03:00',
    label: 'East Africa Time +03:00',
    timezone_name: 'East Africa Time',
  },
  {
    value: 'EEST+03:00',
    label: 'Eastern European Summer Time +03:00',
    timezone_name: 'Eastern European Summer Time',
  },
  {
    value: 'FET+03:00',
    label: 'Further-eastern European Time +03:00',
    timezone_name: 'Further-eastern European Time',
  },
  {
    value: 'IDT+03:00',
    label: 'Israel Daylight Time +03:00',
    timezone_name: 'Israel Daylight Time',
  },
  {
    value: 'IOT+03:00',
    label: 'Indian Ocean Time +03:00',
    timezone_name: 'Indian Ocean Time',
  },
  {
    value: 'MSK+03:00',
    label: 'Moscow Time +03:00',
    timezone_name: 'Moscow Time',
  },
  {
    value: 'SYOT+03:00',
    label: 'Showa Station Time +03:00',
    timezone_name: 'Showa Station Time',
  },
  {
    value: 'TRT+03:00',
    label: 'Turkey Time +03:00',
    timezone_name: 'Turkey Time',
  },
  {
    value: 'IRST+03:30',
    label: 'Iran Standard Time +03:30',
    timezone_name: 'Iran Standard Time',
  },
  {
    value: 'AMT+4+04:00',
    label: 'Armenia Time +04:00',
    timezone_name: 'Armenia Time',
  },
  {
    value: 'AZT+04:00',
    label: 'Azerbaijan Time +04:00',
    timezone_name: 'Azerbaijan Time',
  },
  {
    value: 'GET+04:00',
    label: 'Georgia Standard Time +04:00',
    timezone_name: 'Georgia Standard Time',
  },
  {
    value: 'GST+04:00',
    label: 'Gulf Standard Time +04:00',
    timezone_name: 'Gulf Standard Time',
  },
  {
    value: 'MUT+04:00',
    label: 'Mauritius Time +04:00',
    timezone_name: 'Mauritius Time',
  },
  {
    value: 'RET+04:00',
    label: 'Réunion Time +04:00',
    timezone_name: 'Réunion Time',
  },
  {
    value: 'SAMT+04:00',
    label: 'Samara Time +04:00',
    timezone_name: 'Samara Time',
  },
  {
    value: 'SCT+04:00',
    label: 'Seychelles Time +04:00',
    timezone_name: 'Seychelles Time',
  },
  {
    value: 'VOLT+04:00',
    label: 'Volgograd Time +04:00',
    timezone_name: 'Volgograd Time',
  },
  {
    value: 'AFT+04:30',
    label: 'Afghanistan Time +04:30',
    timezone_name: 'Afghanistan Time',
  },
  {
    value: 'IRDT+04:30',
    label: 'Iran Daylight Time +04:30',
    timezone_name: 'Iran Daylight Time',
  },
  {
    value: 'AQTT+05:00',
    label: 'Aqtobe Time +05:00',
    timezone_name: 'Aqtobe Time',
  },
  {
    value: 'HMT+05:00',
    label: 'Heard and McDonald Islands Time +05:00',
    timezone_name: 'Heard and McDonald Islands Time',
  },
  {
    value: 'MAWT+05:00',
    label: 'Mawson Station Time +05:00',
    timezone_name: 'Mawson Station Time',
  },
  {
    value: 'MVT+05:00',
    label: 'Maldives Time +05:00',
    timezone_name: 'Maldives Time',
  },
  {
    value: 'NPT+05:00',
    label: 'Nepal Time +05:00',
    timezone_name: 'Nepal Time',
  },
  {
    value: 'ORAT+05:00',
    label: 'Oral Time +05:00',
    timezone_name: 'Oral Time',
  },
  {
    value: 'PKT+05:00',
    label: 'Pakistan Standard Time +05:00',
    timezone_name: 'Pakistan Standard Time',
  },
  {
    value: 'SLST+05:00',
    label: 'Sri Lanka Standard Time +05:00',
    timezone_name: 'Sri Lanka Standard Time',
  },
  {
    value: 'TFT+05:00',
    label: 'French Southern and Antarctic Time +05:00',
    timezone_name: 'French Southern and Antarctic Time',
  },
  {
    value: 'TJT+05:00',
    label: 'Tajikistan Time +05:00',
    timezone_name: 'Tajikistan Time',
  },
  {
    value: 'TMT+05:00',
    label: 'Turkmenistan Time +05:00',
    timezone_name: 'Turkmenistan Time',
  },
  {
    value: 'UZT+05:00',
    label: 'Uzbekistan Time +05:00',
    timezone_name: 'Uzbekistan Time',
  },
  {
    value: 'IST+5.5+05:30',
    label: 'Indian Standard Time +05:30',
    timezone_name: 'Indian Standard Time',
  },
  {
    value: 'ALMT+06:00',
    label: 'Alma-Ata Time +06:00',
    timezone_name: 'Alma-Ata Time',
  },
  {
    value: 'BIOT+06:00',
    label: 'British Indian Ocean Time +06:00',
    timezone_name: 'British Indian Ocean Time',
  },
  {
    value: 'BST+6+06:00',
    label: 'Bangladesh Standard Time +06:00',
    timezone_name: 'Bangladesh Standard Time',
  },
  {
    value: 'BTT+06:00',
    label: 'Bhutan Time +06:00',
    timezone_name: 'Bhutan Time',
  },
  {
    value: 'KGT+06:00',
    label: 'Kyrgyzstan Time +06:00',
    timezone_name: 'Kyrgyzstan Time',
  },
  {
    value: 'MMT+06:00',
    label: 'Myanmar Standard Time +06:00',
    timezone_name: 'Myanmar Standard Time',
  },
  {
    value: 'OMST+06:00',
    label: 'Omsk Time +06:00',
    timezone_name: 'Omsk Time',
  },
  {
    value: 'VOST+06:00',
    label: 'Vostok Station Time +06:00',
    timezone_name: 'Vostok Station Time',
  },
  {
    value: 'CCT+06:30',
    label: 'Cocos Islands Time +06:30',
    timezone_name: 'Cocos Islands Time',
  },
  {
    value: 'CXT+07:00',
    label: 'Christmas Island Time +07:00',
    timezone_name: 'Christmas Island Time',
  },
  {
    value: 'DAVT+07:00',
    label: 'Davis Time +07:00',
    timezone_name: 'Davis Time',
  },
  {
    value: 'HOVT+07:00',
    label: 'Hovd Time +07:00',
    timezone_name: 'Hovd Time',
  },
  {
    value: 'ICT+07:00',
    label: 'Indochina Time +07:00',
    timezone_name: 'Indochina Time',
  },
  {
    value: 'KRAT+07:00',
    label: 'Krasnoyarsk Time +07:00',
    timezone_name: 'Krasnoyarsk Time',
  },
  {
    value: 'NOVT+07:00',
    label: 'Novosibirsk Time +07:00',
    timezone_name: 'Novosibirsk Time',
  },
  {
    value: 'THA+07:00',
    label: 'Thailand Standard Time +07:00',
    timezone_name: 'Thailand Standard Time',
  },
  {
    value: 'AWST+08:00',
    label: 'Australian Western Standard Time +08:00',
    timezone_name: 'Australian Western Standard Time',
  },
  {
    value: 'BDT+08:00',
    label: 'Brunei Time +08:00',
    timezone_name: 'Brunei Time',
  },
  {
    value: 'CHOT+08:00',
    label: 'Choibalsan Standard Time +08:00',
    timezone_name: 'Choibalsan Standard Time',
  },
  {
    value: 'CIT+08:00',
    label: 'Central Indonesia Time +08:00',
    timezone_name: 'Central Indonesia Time',
  },
  {
    value: 'CST+8+08:00',
    label: 'China Standard Time +08:00',
    timezone_name: 'China Standard Time',
  },
  {
    value: 'HKT+08:00',
    label: 'Hong Kong Time +08:00',
    timezone_name: 'Hong Kong Time',
  },
  {
    value: 'HOVST+08:00',
    label: 'Hovd Summer Time +08:00',
    timezone_name: 'Hovd Summer Time',
  },
  {
    value: 'IRKT+08:00',
    label: 'Irkutsk Time +08:00',
    timezone_name: 'Irkutsk Time',
  },
  {
    value: 'MYT+08:00',
    label: 'Malaysia Time +08:00',
    timezone_name: 'Malaysia Time',
  },
  {
    value: 'PHT+08:00',
    label: 'Philippine Time +08:00',
    timezone_name: 'Philippine Time',
  },
  {
    value: 'SGT+08:00',
    label: 'Singapore Time +08:00',
    timezone_name: 'Singapore Time',
  },
  {
    value: 'ULAT+08:00',
    label: 'Ulaanbaatar Standard Time +08:00',
    timezone_name: 'Ulaanbaatar Standard Time',
  },
  {
    value: 'ACWST+08:30',
    label: 'Australian Central Western Standard Time +08:30',
    timezone_name: 'Australian Central Western Standard Time',
  },
  {
    value: 'CWST+08:30',
    label: 'Central Western Standard Time (Australia) unofficial +08:30',
    timezone_name: 'Central Western Standard Time (Australia) unofficial',
  },
  {
    value: 'CHOST+09:00',
    label: 'Choibalsan Summer Time +09:00',
    timezone_name: 'Choibalsan Summer Time',
  },
  {
    value: 'EIT+09:00',
    label: 'Eastern Indonesian Time +09:00',
    timezone_name: 'Eastern Indonesian Time',
  },
  {
    value: 'JST+09:00',
    label: 'Japan Standard Time +09:00',
    timezone_name: 'Japan Standard Time',
  },
  {
    value: 'KST+09:00',
    label: 'Korea Standard Time +09:00',
    timezone_name: 'Korea Standard Time',
  },
  {
    value: 'TLT+09:00',
    label: 'Timor Leste Time +09:00',
    timezone_name: 'Timor Leste Time',
  },
  {
    value: 'ULAST+09:00',
    label: 'Ulaanbaatar Summer Time +09:00',
    timezone_name: 'Ulaanbaatar Summer Time',
  },
  {
    value: 'ACST+09:30',
    label: 'Australian Central Standard Time +09:30',
    timezone_name: 'Australian Central Standard Time',
  },
  {
    value: 'AEST+10:00',
    label: 'Australian Eastern Standard Time +10:00',
    timezone_name: 'Australian Eastern Standard Time',
  },
  {
    value: 'CHST+10:00',
    label: 'Chamorro Standard Time +10:00',
    timezone_name: 'Chamorro Standard Time',
  },
  {
    value: 'CHUT+10:00',
    label: 'Chuuk Time +10:00',
    timezone_name: 'Chuuk Time',
  },
  {
    value: 'DDUT+10:00',
    label: 'Dumont d’Urville Time +10:00',
    timezone_name: 'Dumont d’Urville Time',
  },
  {
    value: 'PGT+10:00',
    label: 'Papua New Guinea Time +10:00',
    timezone_name: 'Papua New Guinea Time',
  },
  {
    value: 'VLAT+10:00',
    label: 'Vladivostok Time +10:00',
    timezone_name: 'Vladivostok Time',
  },
  {
    value: 'ACDT+10:30',
    label: 'Australian Central Daylight Saving Time +10:30',
    timezone_name: 'Australian Central Daylight Saving Time',
  },
  {
    value: 'LHST+10:30',
    label: 'Lord Howe Standard Time +10:30',
    timezone_name: 'Lord Howe Standard Time',
  },
  {
    value: 'AEDT+11:00',
    label: 'Australian Eastern Daylight Saving Time +11:00',
    timezone_name: 'Australian Eastern Daylight Saving Time',
  },
  {
    value: 'BST+11+11:00',
    label: 'Bougainville Standard Time +11:00',
    timezone_name: 'Bougainville Standard Time',
  },
  {
    value: 'KOST+11:00',
    label: 'Kosrae Time +11:00',
    timezone_name: 'Kosrae Time',
  },
  {
    value: 'LHST+11+11:00',
    label: 'Lord Howe Summer Time +11:00',
    timezone_name: 'Lord Howe Summer Time',
  },
  {
    value: 'MAGT+11:00',
    label: 'Magadan Time +11:00',
    timezone_name: 'Magadan Time',
  },
  {
    value: 'MIST+11:00',
    label: 'Macquarie Island Station Time +11:00',
    timezone_name: 'Macquarie Island Station Time',
  },
  {
    value: 'NCT+11:00',
    label: 'New Caledonia Time +11:00',
    timezone_name: 'New Caledonia Time',
  },
  {
    value: 'NFT+11:00',
    label: 'Norfolk Island Time +11:00',
    timezone_name: 'Norfolk Island Time',
  },
  {
    value: 'PONT+11:00',
    label: 'Pohnpei Standard Time +11:00',
    timezone_name: 'Pohnpei Standard Time',
  },
  {
    value: 'SAKT+11:00',
    label: 'Sakhalin Island Time +11:00',
    timezone_name: 'Sakhalin Island Time',
  },
  {
    value: 'SBT+11:00',
    label: 'Solomon Islands Time +11:00',
    timezone_name: 'Solomon Islands Time',
  },
  {
    value: 'SRET+11:00',
    label: 'Srednekolymsk Time +11:00',
    timezone_name: 'Srednekolymsk Time',
  },
  {
    value: 'VUT+11:00',
    label: 'Vanuatu Time +11:00',
    timezone_name: 'Vanuatu Time',
  },
  {
    value: 'ANAT+12:00',
    label: 'Anadyr Time +12:00',
    timezone_name: 'Anadyr Time',
  },
  { value: 'FJT+12:00', label: 'Fiji Time +12:00', timezone_name: 'Fiji Time' },
  {
    value: 'GILT+12:00',
    label: 'Gilbert Island Time +12:00',
    timezone_name: 'Gilbert Island Time',
  },
  {
    value: 'MHT+12:00',
    label: 'Marshall Islands Time +12:00',
    timezone_name: 'Marshall Islands Time',
  },
  {
    value: 'NZST+12:00',
    label: 'New Zealand Standard Time +12:00',
    timezone_name: 'New Zealand Standard Time',
  },
  {
    value: 'PETT+12:00',
    label: 'Kamchatka Time +12:00',
    timezone_name: 'Kamchatka Time',
  },
  {
    value: 'TVT+12:00',
    label: 'Tuvalu Time +12:00',
    timezone_name: 'Tuvalu Time',
  },
  {
    value: 'WAKT+12:00',
    label: 'Wake Island Time +12:00',
    timezone_name: 'Wake Island Time',
  },
  {
    value: 'CHAST+12:30',
    label: 'Chatham Standard Time +12:30',
    timezone_name: 'Chatham Standard Time',
  },
  {
    value: 'NZDT+13:00',
    label: 'New Zealand Daylight Time +13:00',
    timezone_name: 'New Zealand Daylight Time',
  },
  {
    value: 'PHOT+13:00',
    label: 'Phoenix Island Time +13:00',
    timezone_name: 'Phoenix Island Time',
  },
  {
    value: 'TKT+13:00',
    label: 'Tokelau Time +13:00',
    timezone_name: 'Tokelau Time',
  },
  {
    value: 'TOT+13:00',
    label: 'Tonga Time +13:00',
    timezone_name: 'Tonga Time',
  },
  {
    value: 'CHADT+13:30',
    label: 'Chatham Daylight Time +13:30',
    timezone_name: 'Chatham Daylight Time',
  },
  {
    value: 'LINT+14:00',
    label: 'Line Islands Time +14:00',
    timezone_name: 'Line Islands Time',
  },
];

export default timezonesArray;
