import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Grid, Button, Row } from 'react-bootstrap';
import { InputLabel, Input, FormControl } from '@mui/material';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/fontawesome-free-solid';
import Loader from 'components/shared/loader/LoginLoader';
import './RecoverPassword.css';
import { Link } from 'react-router-dom';
import { authApi } from '../../../services/api/auth-api';
import { getLoginBackgroundImage } from '../../../actions/loginBackgroundImage';
import logoPng from '../login/logo.png';
import { browserClassname } from 'services/utils/browser-util';
import SupportLink from '../SupportLink';

const confirmUrl = window.location.origin + '/reset-password';

class RecoverPassword extends Component {
  constructor() {
    super();
    this.state = {
      confirmationMessage: '',
      email: '',
      errors: [],
      showMessage: false,
      loadImage: true,
      loadImageError: false,
      noEmail: false,
    };
  }

  componentDidMount() {
    if (!this.props.imageUrl) {
      this.props.getBackgroundImage();
      this.setState({
        loadImage: false,
        loadImageError: false,
      });
    }
  }

  componentDidUpdate() {
    if (
      (this.props.imageUrl || this.props.imageUrl === '') &&
      !this.state.loadImage
    ) {
      const imageLoader = new Image();
      imageLoader.src = this.props.imageUrl;
      imageLoader.onload = () => {
        this.setState({
          loadImage: true,
          loadImageError: false,
        });
      };

      imageLoader.onerror = () => {
        this.setState({
          loadImage: true,
          loadImageError: true,
        });
      };
    }
  }

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value,
      noEmail: false,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.state.email) {
      this.setState({ noEmail: true });
      return;
    }

    authApi.recoverPassword(this.state.email, confirmUrl).then((response) => {
      if (response.ok) {
        this.setState({
          confirmationMessage: response.message,
          showMessage: true,
        });
      } else {
        this.setState({
          errors: response.errors,
        });
      }
    });
  };

  render() {
    const backgroundImage =
      !this.state.loadImageError && this.props.imageUrl
        ? { backgroundImage: 'url(' + this.props.imageUrl + ')' }
        : {};
    return this.state.loadImage ? (
      <div className="client recover-password" style={backgroundImage}>
        <Grid className="max-height">
          {!this.state.showMessage ? (
            <div className="align-center">
              <Col md={9}>
                <div
                  className={browserClassname({
                    firefox: 'form-container-firefox',
                    default: 'form-container',
                  })}
                >
                  <div className="top-buttons">
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      color="white"
                      style={{ fontSize: '25px' }}
                      onClick={() => this.props.history.goBack()}
                    />
                  </div>
                  <form onSubmit={this.handleSubmit}>
                    <Col md={7} sm={9} className="left-panel">
                      <Row>
                        <Col md={12}>
                          <div className="logo logo-with-back">
                            <img src={logoPng} alt="Logo" />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="title">
                            <div className="title-1">Password Recovery</div>
                          </div>
                        </Col>

                        <Col md={12}>
                          <FormControl
                            fullWidth
                            className={this.state.noEmail ? 'label-error' : ''}
                          >
                            <InputLabel htmlFor="standard-adornment-email">
                              Enter your email
                            </InputLabel>
                            <Input
                              id="standard-adornment-email"
                              type="email"
                              value={this.state.password}
                              onChange={this.handleEmailChange}
                              error={
                                this.state.errors.length > 0 ||
                                this.state.noEmail
                              }
                            />
                          </FormControl>
                        </Col>

                        {this.state.errors.length > 0 ? (
                          <Col md={12}>
                            <div className="errors">{this.state.errors}</div>
                          </Col>
                        ) : null}

                        <Col className="mobile-container-button">
                          <Button
                            type="submit"
                            className={`btn-continue button-next`}
                          >
                            <span className="text-btn">Continue</span>
                            <span className="arrow" />
                          </Button>
                        </Col>

                        <Col md={6} mdOffset={3}>
                          <div style={{ height: 50 }} />
                          <SupportLink />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={5} sm={3} className="right-panel">
                      <Button
                        type="submit"
                        className={`btn-continue button-next`}
                      >
                        <span className="text-btn">Continue</span>
                        <span className="arrow" />
                      </Button>
                    </Col>
                  </form>
                </div>
              </Col>
            </div>
          ) : (
            <div className="align-center">
              <Col md={9}>
                <div className="form-container">
                  <Col md={7} sm={9} className="left-panel">
                    <div className="message">
                      <span>{this.state.confirmationMessage}</span>
                    </div>
                  </Col>

                  <Col md={5} sm={3} className="right-panel">
                    <Link
                      to="/login"
                      className="go-to-login"
                      style={{ cursor: 'pointer', color: '#D4FB00' }}
                    >
                      Go to login
                    </Link>
                  </Col>
                </div>
              </Col>
            </div>
          )}
        </Grid>
      </div>
    ) : (
      <Loader loadImage={this.state.loadImage} />
    );
  }
}

export default connect(
  (state) => {
    return {
      imageUrl: state.loginBackgroundImage.url,
    };
  },
  (dispatch) => {
    return {
      getBackgroundImage: () => dispatch(getLoginBackgroundImage()),
    };
  }
)(RecoverPassword);
