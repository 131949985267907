import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './RfiQuestions.css';
import moment from 'moment';
import { RfiStatusEnum } from 'domain/rfi-status-enum';
import Project from 'domain/project';
import { getRfiQuestions } from 'components/admin/projects/details/project-rfi-log/store/selectors';
import RfiQuestion from 'components/admin/projects/details/project-rfi-log/RfiQuestion';
import {
  updateRfiFormValue,
  addRfiQuestion,
  attachRfiQuestions,
  deleteRfiQuestion,
  updateRfiQuestionValue,
  deleteQuestionFile,
} from 'components/admin/projects/details/project-rfi-log/store/actions';
import { getRfiForm } from 'components/admin/projects/details/project-rfi-log/store/selectors';
import { getDarkMode } from 'selectors/theme';
import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import { containerScrollToBottom } from 'services/utils/scroller-util';
import User from 'domain/user';

class RfiQuestions extends Component {
  static propTypes = {
    currentUser: PropTypes.shape({}).isRequired,
    contribType: PropTypes.string.isRequired,
    project: PropTypes.shape({}).isRequired,
    directory: PropTypes.shape({}).isRequired,
    currentRfi: PropTypes.shape({}),
    readOnly: PropTypes.bool,
    modalReadOnly: PropTypes.bool,
    gcAcceleration: PropTypes.bool,
    showResponses: PropTypes.bool,
    questionsReadOnly: PropTypes.bool,
    showAssignedQuestionsOnly: PropTypes.bool,
    filterForUsers: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    if (props.currentRfi) {
      this.initQuestions(props.currentRfi);
    } else {
      this.handleAddAdditionalRfi(true);
    }
  }

  componentDidUpdate() {
    if (this.questionAdded) {
      containerScrollToBottom('rfi-body');
      this.questionAdded = false;
    }
  }

  initQuestions = (rfi) => {
    const { currentUser, modalReadOnly } = this.props;
    const questions = rfi.rfi_questions.map((qus) => {
      const addlAssignee = qus.rfi_addl_assignees.find(
        (addlAssignee) => addlAssignee.assignee_id === currentUser.id
      );
      return {
        ...qus,
        notesReadBy: qus.notes_read_by,
        assignee: qus.assignee
          ? [qus.assignee.id, User.fullNameWithCompany(qus.assignee)]
          : [],
        addlAssignees: qus.rfi_addl_assignees.map((addlAssignee) => {
          return {
            id: addlAssignee.id,
            assigneeId: addlAssignee.assignee_id,
            assigneeLabel: addlAssignee.assignee_label,
            dueDate: addlAssignee.due_date,
            respondedAt: addlAssignee.responded_at,
            response: addlAssignee.response,
          };
        }),
        officialResponse: qus.response,
        response:
          addlAssignee && !modalReadOnly ? addlAssignee.response : qus.response,
        reqDocsDelete: [],
        reqImagesDelete: [],
        resDocsDelete: [],
        resImagesDelete: [],
        errors: {},
      };
    });

    this.props.dispatch(attachRfiQuestions(questions));
  };

  assigneeChanged = (questionKey) => {
    const { questions } = this.props;
    const allAssigned = !Object.keys(questions).find(
      (key) => key !== questionKey && questions[key].assignee.length === 0
    );

    if (allAssigned) {
      this.props.dispatch(
        updateRfiFormValue('status', RfiStatusEnum.ASSIGNED.status)
      );
    }
  };

  resolvedChanged = (questionKey) => {
    const { questions } = this.props;
    const allResolved = !Object.keys(questions).find(
      (key) => key !== questionKey && !questions[key].resolved
    );

    if (allResolved) {
      this.props.dispatch(
        updateRfiFormValue('status', RfiStatusEnum.RESOLVED.status)
      );
    }
  };

  loadContributors = () => {
    const { directory } = this.props;

    return {
      ok: true,
      options: Project.filterRecipients({
        directory,
        primaryOnly: true,
      }).map(({ id, label }) => ({ value: id, label })),
    };
  };

  mapRfiQuestions = () => {
    let questions = Object.keys(this.props.questions).map((key) => {
      const question = this.props.questions[key];
      const { addlAssignees } = question;
      const { assignee_id } = question;

      const isAssigned =
        assignee_id === this.props.currentUser.id ||
        addlAssignees.some((a) => a.assigneeId === this.props.currentUser.id);

      if (!isAssigned && this.props.filterForUser && !this.props.gcAcceleration)
        return null;
      return (
        <RfiQuestion
          key={key}
          currentUser={this.props.currentUser}
          project={this.props.project}
          currentRfi={this.props.currentRfi}
          contribType={this.props.contribType}
          dueDate={this.props.form.extendedDueDate || this.props.form.dueDate}
          questionKey={key}
          question={question}
          assigneeChanged={this.assigneeChanged}
          resolvedChanged={this.resolvedChanged}
          loadContributors={this.loadContributors}
          handleQuestionChange={this.handleRfiQuestionChange}
          handleQuestionDeleteFile={this.handleRfiQuestionDeleteFile}
          handleDeleteRfi={this.handleDeleteRfi}
          readOnly={this.props.readOnly}
          modalReadOnly={this.props.modalReadOnly}
          gcAcceleration={this.props.gcAcceleration}
          showResponses={this.props.showResponses}
          questionsReadOnly={this.props.questionsReadOnly}
        />
      );
    });

    return questions;
  };

  handleRfiQuestionChange = (key, attribute, value) => {
    this.props.dispatch(updateRfiQuestionValue(key, attribute, value));
  };

  handleRfiQuestionDeleteFile = (key, attribute, value) => {
    this.props.dispatch(deleteQuestionFile(key, attribute, value));
  };

  handleAddAdditionalRfi = (initialRfi = false) => {
    this.questionAdded = !initialRfi;
    const key = Object.keys(this.props.questions).length + 1;
    this.props.dispatch(
      addRfiQuestion(key, {
        number: key,
        action: '',
        question: '',
        response: '',
        resolved: false,
        assignee: [],
        addlAssignees: [],
        rfi_addl_assignees: [],
        reqDocs: [],
        reqImages: [],
        reqDocsDelete: [],
        reqImagesDelete: [],
        resDocs: [],
        resImages: [],
        resDocsDelete: [],
        resImagesDelete: [],
      })
    );
  };

  handleDeleteRfi = (key) => {
    this.props.dispatch(deleteRfiQuestion(key));
  };
  render() {
    const {
      currentUser,
      currentRfi,
      readOnly,
      darkMode,
      questionsReadOnly,
    } = this.props;

    return (
      <>
        <div className={`rfi-questions ${darkMode ? 'dark-mode' : ''}`}>
          <DetailsTitle
            className="rfi-title"
            title="Request For Information"
            rightButtons={[
              !readOnly && !questionsReadOnly && (
                <Button
                  key="add-question"
                  type={BUTTON_TYPES.LINK}
                  icon={BUTTON_ICONS.PLUS}
                  label="Add Request"
                  onClick={() => this.handleAddAdditionalRfi()}
                />
              ),
            ].filter((button) => button)}
          />

          <p className="rfi-submitted-by small-regular">
            Submitted by
            {currentRfi && currentRfi.submitted_by ? (
              <span>
                &nbsp;
                {User.fullNameWithCompany(currentRfi.submitted_by)}
                &nbsp;on{' '}
                {moment(
                  currentRfi.submitted_at || currentRfi.received_at
                ).format('MM/DD/YYYY')}
                &nbsp;at{' '}
                {moment(
                  currentRfi.submitted_at || currentRfi.received_at
                ).format('hh:mm A')}
              </span>
            ) : (
              <span>
                &nbsp;{User.fullNameWithCompany(currentUser)}
                &nbsp;on {moment().format('MM/DD/YYYY')}
              </span>
            )}
          </p>

          <div className="rfi-questions">{this.mapRfiQuestions()}</div>
        </div>
      </>
    );
  }
}

export default connect((state) => {
  return {
    questions: getRfiQuestions(state),
    form: getRfiForm(state),
    darkMode: getDarkMode(state),
  };
})(RfiQuestions);
