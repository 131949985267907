import { SET_PASSWORD_PASSWORD_UPDATE } from 'actions/client/set-password';

const initialState = {
  errors: [],
  password: '',
  passwordConfirmation: '',
};

export default function setPasswordUiReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PASSWORD_PASSWORD_UPDATE:
      return {
        ...state,
        [action.payload.attribute]: action.payload.value,
      };

    default:
      return state;
  }
}
