import { createSelector } from 'reselect';

export const getShowCreateProposal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.proposal.ui.showCreateProposal,
  (showCreateProposal) => {
    return showCreateProposal;
  }
);

export const getShowCreateProposalSuccess = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.proposal.ui.showCreateProposalSuccess,
  (showCreateProposalSuccess) => {
    return showCreateProposalSuccess;
  }
);

export const getShowPdfViewer = createSelector(
  (state) => state.projects.scenes.details.scenes.proposal.ui.showPdfViewer,
  (showPdfViewer) => {
    return showPdfViewer;
  }
);

export const getShowApproveProposalSuccess = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.proposal.ui.showApproveProposalSuccess,
  (showApproveProposalSuccess) => {
    return showApproveProposalSuccess;
  }
);

export const getProjectProposal = createSelector(
  (state) => state.projects.scenes.details.scenes.proposal.data.projectProposal,
  (projectProposal) => {
    return projectProposal;
  }
);

export const getProjectProposalPdf = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.proposal.data.projectProposalPdf,
  (projectProposalPdf) => {
    return projectProposalPdf;
  }
);

export const getProjectProposalAddlItems = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.proposal.ui.projectProposalAddlItems,
  (projectProposalAddlItems) => {
    return projectProposalAddlItems;
  }
);

export const getProjectProposalItems = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.proposal.ui.projectProposalItems,
  (projectProposalItems) => {
    return projectProposalItems;
  }
);

export const getProjectProposalForm = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.proposal.ui.projectProposalForm,
  (projectProposalForm) => {
    return projectProposalForm;
  }
);

export const getCsiCodes = createSelector(
  (state) => state.projects.scenes.details.scenes.proposal.data.csiCodes,
  (csiCodes) => {
    return csiCodes;
  }
);

export const getItemDelete = createSelector(
  (state) => state.projects.scenes.details.scenes.proposal.ui.itemDelete,
  (itemDelete) => {
    return itemDelete;
  }
);

export const getReadOnly = createSelector(
  (state) => state.projects.scenes.details.scenes.proposal.ui.readOnly,
  (readOnly) => {
    return readOnly;
  }
);

export const getShowAwardRecommendation = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.proposal.ui.showAwardRecommendation,
  (showAwardRecommendation) => {
    return showAwardRecommendation;
  }
);

export const getShowEmailModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.emailModal.data.showEmailModal,
  (showEmailModal) => {
    return showEmailModal;
  }
);

export const getShowLostOrCancelledModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.proposal.ui.showLostOrCancelledModal,
  (showLostOrCancelledModal) => {
    return showLostOrCancelledModal;
  }
);

export const getIsCancelledModal = createSelector(
  (state) => state.projects.scenes.details.scenes.proposal.ui.isCancelledModal,
  (isCancelledModal) => {
    return isCancelledModal;
  }
);

export const getMessage = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.proposal.ui.cancelledLostModal.message,
  (message) => {
    return message;
  }
);

export const getDate = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.proposal.ui.cancelledLostModal.date,
  (date) => {
    return date;
  }
);

export const getTerminationCategory = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.proposal.ui.cancelledLostModal
      .terminationCategory,
  (terminationCategory) => {
    return terminationCategory;
  }
);
