import axios from 'axios';
import BaseApi from './base-api';

class MeetingTypesApi extends BaseApi {
  getMeetingTypes(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/meeting_types`)
      .then((response) => {
        return {
          ok: true,
          meetingTypes: this.makeTree(response.data),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateMeetingTypes(projectId, typesTree) {
    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/meeting_types/batch_update`,
        typesTree
      )
      .then((response) => {
        return {
          ok: true,
          meetingTypes: this.makeTree(response.data),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  makeTree = (list) => {
    const sections = list.reduce((current, type) => {
      const parent = type.parent_id || '';
      return {
        ...current,
        [parent]: [...(current[parent] || []), type],
      };
    }, {});

    const makeSection = (section) =>
      section.map((type) => ({
        ...type,
        children: sections[type.id] ? makeSection(sections[type.id]) : [],
      }));

    return makeSection(sections[''] || []);
  };
}

export const meetingTypesApi = new MeetingTypesApi();
