import { createSelector } from 'reselect';

export const getReportViews = createSelector(
  (state) => state.superadmin.data.reports.data.reportViews,
  (reportViews) => {
    return reportViews;
  }
);

export const getAvailableCompanies = createSelector(
  (state) => state.superadmin.data.reports.data.availableCompanies,
  (availableCompanies) => {
    return availableCompanies;
  }
);

export const getAvailableProjects = createSelector(
  (state) => state.superadmin.data.reports.data.availableProjects,
  (availableProjects) => {
    return availableProjects;
  }
);

export const getAvailableCities = createSelector(
  (state) => state.superadmin.data.reports.data.availableCities,
  (availableCities) => {
    return availableCities;
  }
);

export const getAvailableStates = createSelector(
  (state) => state.superadmin.data.reports.data.availableStates,
  (availableStates) => {
    return availableStates;
  }
);

export const getAvailableZips = createSelector(
  (state) => state.superadmin.data.reports.data.availableZips,
  (availableZips) => {
    return availableZips;
  }
);

export const getAvailableAddresses = createSelector(
  (state) => state.superadmin.data.reports.data.availableAddresses,
  (availableAddresses) => {
    return availableAddresses;
  }
);

export const getCurrentReportViewId = createSelector(
  (state) => state.superadmin.data.reports.ui.id,
  (id) => {
    return id;
  }
);

export const getCurrentReportViewDetails = createSelector(
  (state) => state.superadmin.data.reports.ui.details,
  (details) => {
    return details;
  }
);

export const getCurrentReportViewCompanyInformation = createSelector(
  (state) => state.superadmin.data.reports.ui.company_information,
  (company_information) => {
    return company_information;
  }
);

export const getCurrentReportViewProjectInformation = createSelector(
  (state) => state.superadmin.data.reports.ui.project_information,
  (project_information) => {
    return project_information;
  }
);

export const getCurrentReportViewProjectUsage = createSelector(
  (state) => state.superadmin.data.reports.ui.project_usage,
  (project_usage) => {
    return project_usage;
  }
);

export const getCurrentReportViewMaterialOrder = createSelector(
  (state) => state.superadmin.data.reports.ui.material_order,
  (material_order) => {
    return material_order;
  }
);

export const getCurrentReportViewSubscription = createSelector(
  (state) => state.superadmin.data.reports.ui.subscription,
  (subscription) => {
    return subscription;
  }
);

export const getShowSaveView = createSelector(
  (state) => state.superadmin.data.reports.ui.showSaveView,
  (showSaveView) => {
    return showSaveView;
  }
);

export const getRunningReport = createSelector(
  (state) => state.superadmin.data.reports.ui.runningReport,
  (runningReport) => {
    return runningReport;
  }
);
