/* eslint-disable default-case */
import axios from 'axios';
import BaseApi from './base-api';

class SecurityCamerasApi extends BaseApi {
  get(projectId) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/security_camera_configuration/`
      )
      .then((response) => {
        return {
          ok: true,
          configuration: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors:
            error.response && error.response.data && error.response.data.errors,
        };
      });
  }

  preview(projectId) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/security_camera_configuration/preview`
      )
      .then((response) => {
        return {
          ok: true,
          preview: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data && error.response.data.errors,
        };
      });
  }

  picture(projectId, cameraId) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/security_camera_configuration/picture?camera_id=${cameraId}`,
        { loadingDisabled: true }
      )
      .then((response) => {
        return {
          ok: true,
          picture: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors:
            error.response && error.response.data && error.response.data.errors,
        };
      });
  }

  saveConfiguration(projectId, values) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = this.saveConfigurationParams(values);
    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/security_camera_configuration/`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          configuration: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  saveConfigurationParams({ connector, url, username, password }) {
    const apiPayload = new FormData();
    apiPayload.append(
      'security_camera_configuration[connector]',
      connector || 'empty'
    );
    if (!connector) return apiPayload;

    if (url) apiPayload.append('security_camera_configuration[url]', url);
    if (username)
      apiPayload.append('security_camera_configuration[username]', username);
    if (password)
      apiPayload.append('security_camera_configuration[password]', password);
    return apiPayload;
  }

  saveCameras(projectId, values) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = this.saveCamerasParams(values);
    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/security_camera_configuration/cameras`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          configuration: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  saveCamerasParams({ cameras }) {
    const apiPayload = new FormData();
    cameras
      .filter(({ selected }) => selected)
      .forEach(({ channel, name, description }, index) => {
        const path = `security_camera_configuration[cameras][${index}]`;
        apiPayload.append(`${path}[channel]`, channel);
        apiPayload.append(`${path}[name]`, name);
        apiPayload.append(`${path}[description]`, description);
      });
    return apiPayload;
  }

  savePermissions(projectId, values) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = this.savePermissionsParams(values);
    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/security_camera_configuration/permissions`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          configuration: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  savePermissionsParams({ permission, users }) {
    const apiPayload = new FormData();
    apiPayload.append('security_camera_configuration[permission]', permission);
    users.forEach(({ id: userId }) =>
      apiPayload.append(
        `security_camera_configuration[permission_user_ids][]`,
        userId
      )
    );
    return apiPayload;
  }
}

export const securityCamerasApi = new SecurityCamerasApi();
