export const WORK_ORDERS_CURRENT_WO_ATTRIBUTE_UPDATE =
  'WORK_ORDERS_CURRENT_WO_ATTRIBUTE_UPDATE';
export const WORK_ORDERS_LOAD_SUCCESS = 'WORK_ORDERS_LOAD_SUCCESS';
export const WORK_ORDERS_WO_SELECT_SUCCESS = 'WORK_ORDERS_WO_SELECT_SUCCESS';
export const WORK_ORDERS_WO_LOAD_TASKS_SUCCESS =
  'WORK_ORDERS_WO_LOAD_TASKS_SUCCESS';
export const WORK_ORDERS_WORK_ORDER_CREATION_SUCCESS =
  'WORK_ORDERS_WORK_ORDER_CREATION_SUCCESS';
export const WORK_ORDERS_WO_ADD = 'WORK_ORDERS_WO_ADD';
export const WORK_ORDERS_LOAD_TRADES_SUCCESS =
  'WORK_ORDERS_LOAD_TRADES_SUCCESS';
export const WORK_ORDERS_LOAD_SUB_TRADES_SUCCESS =
  'WORK_ORDERS_LOAD_SUB_TRADES_SUCCESS';
export const WORK_ORDERS_LOAD_CONTRACTORS_SUCCESS =
  'WORK_ORDERS_LOAD_CONTRACTORS_SUCCESS';
export const WORK_ORDERS_CONTRACTOR_NEW_SUCCESS =
  'WORK_ORDERS_CONTRACTOR_NEW_SUCCESS';
export const WORK_ORDERS_WO_ATTRIBUTE_UPDATE =
  'WORK_ORDERS_WO_ATTRIBUTE_UPDATE';
export const WORK_ORDERS_WO_UPDATE = 'WORK_ORDERS_WO_UPDATE';
export const WORK_ORDERS_UNION_TRADES_LOAD_SUCCESS =
  'WORK_ORDERS_UNION_TRADES_LOAD_SUCCESS';
export const WORK_ORDERS_SEARCH_TEXT_CHANGE = 'WORK_ORDERS_SEARCH_TEXT_CHANGE';
export const WORK_ORDERS_SUMMARY_LOAD_SUCCESS =
  'WORK_ORDERS_SUMMARY_LOAD_SUCCESS';
export const WORK_ORDERS_SUMMARY_TASK_REMOVE =
  'WORK_ORDERS_SUMMARY_TASK_REMOVE';
export const WORK_ORDERS_TASK_CHECKLIST_UPDATE =
  'WORK_ORDERS_TASK_CHECKLIST_UPDATE';
export const WORK_ORDERS_TASK_REMOVE = 'WORK_ORDERS_TASK_REMOVE';
export const WORK_ORDERS_TASK_UPDATE_SUCCESS =
  'WORK_ORDERS_TASK_UPDATE_SUCCESS';
export const WORK_ORDERS_TASK_UPDATE = 'WORK_ORDERS_TASK_UPDATE';
export const WORK_ORDERS_PAGE_CHANGE = 'WORK_ORDERS_PAGE_CHANGE';
export const WORK_ORDERS_INVOICES_TAB_CHANGE =
  'WORK_ORDERS_INVOICES_TAB_CHANGE';
export const WORK_ORDERS_CURRENT_WO_COMPLETE =
  'WORK_ORDERS_CURRENT_WO_COMPLETE';

export const WORK_ORDERS_CREATION_CLIENTS_CLIENT_SET =
  'WORK_ORDERS_CREATION_CLIENTS_CLIENT_SET';
export const WORK_ORDERS_CREATION_CLIENTS_LOAD_SUCCESS =
  'WORK_ORDERS_CREATION_CLIENTS_LOAD_SUCCESS';

export const WORK_ORDERS_CREATION_WO_CANCELLATION_MODAL_CLOSE =
  'WORK_ORDERS_CREATION_WO_CANCELLATION_MODAL_CLOSE';
export const WORK_ORDERS_CREATION_WO_CANCELLATION_MODAL_SHOW =
  'WORK_ORDERS_CREATION_WO_CANCELLATION_MODAL_SHOW';
export const WORK_ORDERS_CREATION_WO_NEW_PROCESS_STARTED =
  'WORK_ORDERS_CREATION_WO_NEW_PROCESS_STARTED';

export const WORK_ORDERS_CREATION_LOAD_BUILDINGS_SUCCESS =
  'WORK_ORDERS_CREATION_LOAD_BUILDINGS_SUCCESS';
export const WORK_ORDERS_CREATION_SELECT_BUILDING =
  'WORK_ORDERS_CREATION_SELECT_BUILDING';
export const WORK_ORDERS_CREATION_NEW_BUILDING_REQUEST =
  'WORK_ORDERS_CREATION_NEW_BUILDING_REQUEST';
export const WORK_ORDERS_CREATION_NEW_BUILDING_SUCCESS =
  'WORK_ORDERS_CREATION_NEW_BUILDING_SUCCESS';
export const WORK_ORDERS_CREATION_NEW_BUILDING_CHANGE_VALUE =
  'WORK_ORDERS_CREATION_NEW_BUILDING_CHANGE_VALUE';
export const WORK_ORDERS_CREATION_NEW_BUILDING_EDIT =
  'WORK_ORDERS_CREATION_NEW_BUILDING_EDIT';
export const WORK_ORDERS_CREATION_BUILDING_UPDATE_SUCCESS =
  'WORK_ORDERS_CREATION_BUILDING_UPDATE_SUCCESS';
export const WORK_ORDERS_CREATION_BUILDING_LOCATION_UPDATE_SUCCESS =
  'WORK_ORDERS_CREATION_BUILDING_LOCATION_UPDATE_SUCCESS';
export const WORK_ORDERS_CREATION_BUILDING_DOCUMENTS_UPLOAD_SUCCESS =
  'WORK_ORDERS_CREATION_BUILDING_DOCUMENTS_UPLOAD_SUCCESS';
export const WORK_ORDERS_CREATION_BUILDING_DOCUMENTS_REMOVE_SUCCESS =
  'WORK_ORDERS_CREATION_BUILDING_DOCUMENTS_REMOVE_SUCCESS';

export const WORK_ORDERS_CREATION_TASKS_STATE_INIT =
  'WORK_ORDERS_CREATION_TASKS_STATE_INIT';
export const WORK_ORDERS_CREATION_TASKS_TASK_SAVE_SUCCESS =
  'WORK_ORDERS_CREATION_TASKS_TASK_SAVE_SUCCESS';
export const WORK_ORDERS_CREATION_TASKS_TASK_EDIT =
  'WORK_ORDERS_CREATION_TASKS_TASK_EDIT';
export const WORK_ORDERS_CREATION_TASKS_TASK_CHANGE_VALUE =
  'WORK_ORDERS_CREATION_TASKS_TASK_CHANGE_VALUE';
export const WORK_ORDERS_CREATION_TASKS_TASK_ADD_EMPTY_NEW =
  'WORK_ORDERS_CREATION_TASKS_TASK_ADD_EMPTY_NEW';
export const WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_NEW =
  'WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_NEW';
export const WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_SUCCESS =
  'WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_SUCCESS';
export const WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_SHOW =
  'WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_SHOW';
export const WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_HIDE =
  'WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_HIDE';
export const WORK_ORDERS_CREATION_TASKS_TASK_IMAGE_REMOVE_SUCCESS =
  'WORK_ORDERS_CREATION_TASKS_TASK_IMAGE_REMOVE_SUCCESS';

export const WORK_ORDERS_CREATION_SUB_CONTRACTORS_STATE_INIT =
  'WORK_ORDERS_CREATION_SUB_CONTRACTORS_STATE_INIT';
export const WORK_ORDERS_CREATION_SUB_CONTRACTORS_SELECT =
  'WORK_ORDERS_CREATION_SUB_CONTRACTORS_SELECT';
export const WORK_ORDERS_CREATION_SUB_CONTRACTORS_REMOVE =
  'WORK_ORDERS_CREATION_SUB_CONTRACTORS_REMOVE';
export const WORK_ORDERS_CREATION_SUB_CONTRACTORS_QUESTION_ANSWERED =
  'WORK_ORDERS_CREATION_SUB_CONTRACTORS_QUESTION_ANSWERED';
export const WORK_ORDERS_CREATION_SUB_CONTRACTORS_VALID_CONFIRMATION =
  'WORK_ORDERS_CREATION_SUB_CONTRACTORS_VALID_CONFIRMATION';
export const WORK_ORDERS_CREATION_SUB_CONTRACTORS_INVALID_CONFIRMATION =
  'WORK_ORDERS_CREATION_SUB_CONTRACTORS_INVALID_CONFIRMATION';
export const WORK_ORDERS_CREATION_SUB_CONTRACTORS_ADD =
  'WORK_ORDERS_CREATION_SUB_CONTRACTORS_ADD';
export const WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_VALUE_CHANGE =
  'WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_VALUE_CHANGE';
export const WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_CANCEL =
  'WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_CANCEL';
export const WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_VALID =
  'WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_VALID';
export const WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_INVALID =
  'WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_INVALID';
export const WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_SUCCESS =
  'WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_SUCCESS';
export const WORK_ORDERS_CREATION_SUB_CONTRACTORS_CURRENT_SET =
  'WORK_ORDERS_CREATION_SUB_CONTRACTORS_CURRENT_SET';
export const WORK_ORDERS_CREATION_UNION_TRADES_VALID_CONFIRMATION =
  'WORK_ORDERS_CREATION_UNION_TRADES_VALID_CONFIRMATION';
export const WORK_ORDERS_CREATION_UNION_TRADES_INVALID_CONFIRMATION =
  'WORK_ORDERS_CREATION_UNION_TRADES_INVALID_CONFIRMATION';

export const WORK_ORDERS_CREATION_WORKING_HOURS_VALID =
  'WORK_ORDERS_CREATION_WORKING_HOURS_VALID';
export const WORK_ORDERS_CREATION_WORKING_HOURS_INVALID =
  'WORK_ORDERS_CREATION_WORKING_HOURS_INVALID';
export const WORK_ORDERS_CREATION_WORKING_HOURS_SELECT =
  'WORK_ORDERS_CREATION_WORKING_HOURS_SELECT';
export const WORK_ORDERS_CREATION_WORKING_HOURS_OTHER_CHANGE =
  'WORK_ORDERS_CREATION_WORKING_HOURS_OTHER_CHANGE';
export const WORK_ORDERS_CREATION_WORKING_HOURS_CURRENT_SET =
  'WORK_ORDERS_CREATION_WORKING_HOURS_CURRENT_SET';

export const WORK_ORDERS_CREATION_UNION_TRADES_CURRENT_SET =
  'WORK_ORDERS_CREATION_UNION_TRADES_CURRENT_SET';
export const WORK_ORDERS_CREATION_UNION_TRADES_SELECT =
  'WORK_ORDERS_CREATION_UNION_TRADES_SELECT';

export const WORK_ORDERS_CREATION_SUMMARY_CONFIRMATION_SUCCESS =
  'WORK_ORDERS_CREATION_SUMMARY_CONFIRMATION_SUCCESS';
export const WORK_ORDERS_CREATION_SUMMARY_CONFIRMATION_WITHOUT_PICS_SUCCESS =
  'WORK_ORDERS_CREATION_SUMMARY_CONFIRMATION_WITHOUT_PICS_SUCCESS';
export const WORK_ORDERS_CREATION_SUMMARY_START =
  'WORK_ORDERS_CREATION_SUMMARY_START';

export const WORK_ORDERS_DETAILS_ADD_MEMBER_FORM_INVALID =
  'WORK_ORDERS_DETAILS_ADD_MEMBER_FORM_INVALID';
export const WORK_ORDERS_DETAILS_ADD_MEMBER_FORM_VALID =
  'WORK_ORDERS_DETAILS_ADD_MEMBER_FORM_VALID';
export const WORK_ORDERS_DETAILS_ADD_MEMBER_FORM_VALUE_UPDATE =
  'WORK_ORDERS_DETAILS_ADD_MEMBER_FORM_VALUE_UPDATE';
export const WORK_ORDERS_DETAILS_ADD_MEMBER_MODAL_SHOW =
  'WORK_ORDERS_DETAILS_ADD_MEMBER_MODAL_SHOW';
export const WORK_ORDERS_DETAILS_ADD_MEMBER_USERS_LOAD_SUCCESS =
  'WORK_ORDERS_DETAILS_ADD_MEMBER_USERS_LOAD_SUCCESS';

export const WORK_ORDERS_DETAILS_ADD_OPEN_ITEM_FORM_INVALID =
  'WORK_ORDERS_DETAILS_ADD_OPEN_ITEM_FORM_INVALID';
export const WORK_ORDERS_DETAILS_ADD_OPEN_ITEM_FORM_VALID =
  'WORK_ORDERS_DETAILS_ADD_OPEN_ITEM_FORM_VALID';
export const WORK_ORDERS_DETAILS_ADD_OPEN_ITEM_FORM_VALUE_UPDATE =
  'WORK_ORDERS_DETAILS_ADD_OPEN_ITEM_FORM_VALUE_UPDATE';
export const WORK_ORDERS_DETAILS_ADD_OPEN_ITEM_MEMBERS_LOAD_SUCCESS =
  'WORK_ORDERS_DETAILS_ADD_OPEN_ITEM_MEMBERS_LOAD_SUCCESS';
export const WORK_ORDERS_DETAILS_ADD_OPEN_ITEM_MODAL_SHOW =
  'WORK_ORDERS_DETAILS_ADD_OPEN_ITEM_MODAL_SHOW';

export const WORK_ORDERS_DETAILS_BUDGET_BACK =
  'WORK_ORDERS_DETAILS_BUDGET_BACK';
export const WORK_ORDERS_DETAILS_BUDGET_CURRENT_VIEW_SET =
  'WORK_ORDERS_DETAILS_BUDGET_CURRENT_VIEW_SET';
export const WORK_ORDERS_DETAILS_BUDGET_EMAIL_ADD =
  'WORK_ORDERS_DETAILS_BUDGET_EMAIL_ADD';
export const WORK_ORDERS_DETAILS_BUDGET_EMAIL_CLEAR =
  'WORK_ORDERS_DETAILS_BUDGET_EMAIL_CLEAR';
export const WORK_ORDERS_DETAILS_BUDGET_EMAIL_MODAL_SHOW_SET =
  'WORK_ORDERS_DETAILS_BUDGET_EMAIL_MODAL_SHOW_SET';
export const WORK_ORDERS_DETAILS_BUDGET_EMAIL_REMOVE =
  'WORK_ORDERS_DETAILS_BUDGET_EMAIL_REMOVE';
export const WORK_ORDERS_DETAILS_BUDGET_MESSAGE_SET =
  'WORK_ORDERS_DETAILS_BUDGET_MESSAGE_SET';
export const WORK_ORDERS_DETAILS_BUDGET_MODAL_SHOW_APPROVAL_CONFIRMATION_SET =
  'WORK_ORDERS_DETAILS_BUDGET_MODAL_SHOW_APPROVAL_CONFIRMATION_SET';
export const WORK_ORDERS_DETAILS_BUDGET_MODAL_SHOW_APPROVE_REVIEW_SET =
  'WORK_ORDERS_DETAILS_BUDGET_MODAL_SHOW_APPROVE_REVIEW_SET';
export const WORK_ORDERS_DETAILS_BUDGET_MODAL_SHOW_SET =
  'WORK_ORDERS_DETAILS_BUDGET_MODAL_SHOW_SET';
export const WORK_ORDERS_DETAILS_BUDGET_PDF_VIEWER_CLOSE =
  'WORK_ORDERS_DETAILS_BUDGET_PDF_VIEWER_CLOSE';
export const WORK_ORDERS_DETAILS_BUDGET_PRINT_SUCCESS =
  'WORK_ORDERS_DETAILS_BUDGET_PRINT_SUCCESS';
export const WORK_ORDERS_DETAILS_BUDGET_NEXT =
  'WORK_ORDERS_DETAILS_BUDGET_NEXT';
export const WORK_ORDERS_DETAILS_BUDGET_SUBJECT_SET =
  'WORK_ORDERS_DETAILS_BUDGET_SUBJECT_SET';
export const WORK_ORDERS_DETAILS_BUDGET_TASK_CHECK =
  'WORK_ORDERS_DETAILS_BUDGET_TASK_CHECK';
export const WORK_ORDERS_DETAILS_BUDGET_USERS_LOAD_SUCCESS =
  'WORK_ORDERS_DETAILS_BUDGET_USERS_LOAD_SUCCESS';
export const WORK_ORDERS_DETAILS_BUDGET_VALUE_UPDATE =
  'WORK_ORDERS_DETAILS_BUDGET_VALUE_UPDATE';
export const WORK_ORDERS_DETAILS_BUDGET_VALUES_INIT =
  'WORK_ORDERS_DETAILS_BUDGET_VALUES_INIT';

export const WORK_ORDERS_DETAILS_BUDGET_SET = 'WORK_ORDERS_DETAILS_BUDGET_SET';
export const WORK_ORDERS_DETAILS_CONTRACTOR_INVOICE_SET =
  'WORK_ORDERS_DETAILS_CONTRACTOR_INVOICE_SET';
export const WORK_ORDERS_DETAILS_CONTRACTOR_LIEN_RELEASE_SET =
  'WORK_ORDERS_DETAILS_CONTRACTOR_LIEN_RELEASE_SET';
export const WORK_ORDERS_DETAILS_CONTRACTOR_LIEN_RELEASES_SET =
  'WORK_ORDERS_DETAILS_CONTRACTOR_LIEN_RELEASES_SET';
export const WORK_ORDERS_DETAILS_CONTRACTOR_LIEN_RELEASE_REMOVE =
  'WORK_ORDERS_DETAILS_CONTRACTOR_LIEN_RELEASE_REMOVE';
export const WORK_ORDERS_DETAILS_CLIENT_STREAM_MODAL_SHOW =
  'WORK_ORDERS_DETAILS_CLIENT_STREAM_MODAL_SHOW';
export const WORK_ORDERS_DETAILS_TASK_LOAD_SUCCESS =
  'WORK_ORDERS_DETAILS_TASK_LOAD_SUCCESS';
export const WORK_ORDERS_DETAILS_TASK_CHANGE_VALUE =
  'WORK_ORDERS_DETAILS_TASK_CHANGE_VALUE';
export const WORK_ORDERS_DETAILS_TASK_EDIT = 'WORK_ORDERS_DETAILS_TASK_EDIT';
export const WORK_ORDERS_DETAILS_TASK_CANCEL =
  'WORK_ORDERS_DETAILS_TASK_CANCEL';
export const WORK_ORDERS_DETAILS_TASK_IMAGE_REMOVE_SUCCESS =
  'WORK_ORDERS_DETAILS_TASK_IMAGE_REMOVE_SUCCESS';
export const WORK_ORDERS_DETAILS_TASK_UPDATE =
  'WORK_ORDERS_DETAILS_TASK_UPDATE';
export const WORK_ORDERS_DETAILS_STATE_INIT = 'WORK_ORDERS_DETAILS_STATE_INIT';
export const WORK_ORDERS_DETAILS_NAV_BAR_SELECTION =
  'WORK_ORDERS_DETAILS_NAV_BAR_SELECTION';
export const WORK_ORDERS_DETAILS_NOTE_ADD_SUCCESS =
  'WORK_ORDERS_DETAILS_NOTE_ADD_SUCCESS';
export const WORK_ORDERS_DETAILS_NOTES_LOAD_SUCCESS =
  'WORK_ORDERS_DETAILS_NOTES_LOAD_SUCCESS';
export const WORK_ORDERS_DETAILS_TASK_NOTE_ADD_SUCCESS =
  'WORK_ORDERS_DETAILS_TASK_NOTE_ADD_SUCCESS';
export const WORK_ORDERS_DETAILS_TASK_NOTES_LOAD_SUCCESS =
  'WORK_ORDERS_DETAILS_TASK_NOTES_LOAD_SUCCESS';
export const WORK_ORDERS_DETAILS_MEMBERS_ADD =
  'WORK_ORDERS_DETAILS_MEMBERS_ADD';
export const WORK_ORDERS_DETAILS_MEMBERS_LOAD_SUCCESS =
  'WORK_ORDERS_DETAILS_MEMBERS_LOAD_SUCCESS';
export const WORK_ORDERS_DETAILS_MEMBERS_REMOVE =
  'WORK_ORDERS_DETAILS_MEMBERS_REMOVE';
export const WORK_ORDERS_DETAILS_DOCUMENTS_UPLOAD_SUCCESS =
  'WORK_ORDERS_DETAILS_DOCUMENTS_UPLOAD_SUCCESS';
export const WORK_ORDERS_DETAILS_DOCUMENT_REMOVE_SUCCESS =
  'WORK_ORDERS_DETAILS_DOCUMENT_REMOVE_SUCCESS';
export const WORK_ORDERS_DETAILS_OPEN_ITEM_EDIT_SET =
  'WORK_ORDERS_DETAILS_OPEN_ITEM_EDIT_SET';
export const WORK_ORDERS_DETAILS_EDIT_OPEN_ITEM_FORM_VALUE_UPDATE =
  'WORK_ORDERS_DETAILS_EDIT_OPEN_ITEM_FORM_VALUE_UPDATE';
export const WORK_ORDERS_DETAILS_OPEN_ITEM_SELECTED =
  'WORK_ORDERS_DETAILS_OPEN_ITEM_SELECTED';
export const WORK_ORDERS_DETAILS_OPEN_ITEMS_ADD =
  'WORK_ORDERS_DETAILS_OPEN_ITEMS_ADD';
export const WORK_ORDERS_DETAILS_OPEN_ITEMS_UPDATE =
  'WORK_ORDERS_DETAILS_OPEN_ITEMS_UPDATE';
export const WORK_ORDERS_DETAILS_OPEN_ITEMS_LOAD_SUCCESS =
  'WORK_ORDERS_DETAILS_OPEN_ITEMS_LOAD_SUCCESS';
export const WORK_ORDERS_DETAILS_PRE_EXISTING_CONDITIONS_CHANGE =
  'WORK_ORDERS_DETAILS_PRE_EXISTING_CONDITIONS_CHANGE';
export const WORK_ORDERS_DETAILS_PRE_EXISTING_CONDITIONS_DESCRIPTION_CHANGE =
  'WORK_ORDERS_DETAILS_PRE_EXISTING_CONDITIONS_DESCRIPTION_CHANGE';
export const WORK_ORDERS_DETAILS_PRE_EXISTING_CONDITIONS_RESET =
  'WORK_ORDERS_DETAILS_PRE_EXISTING_CONDITIONS_RESET';
export const WORK_ORDERS_DETAILS_CHANGE_ORDER_ADD =
  'WORK_ORDERS_DETAILS_CHANGE_ORDER_ADD';
export const WORK_ORDERS_DETAILS_MOVED_TO_ACR =
  'WORK_ORDERS_DETAILS_MOVED_TO_ACR';
export const WORK_ORDERS_DETAILS_PROPOSAL_ADD =
  'WORK_ORDERS_DETAILS_PROPOSAL_ADD';
export const WORK_ORDERS_DETAILS_PROPOSAL_UPDATE =
  'WORK_ORDERS_DETAILS_PROPOSAL_UPDATE';
export const WORK_ORDERS_DETAILS_PROPOSALS_LOAD_SUCCESS =
  'WORK_ORDERS_DETAILS_PROPOSALS_LOAD_SUCCESS';
export const WORK_ORDERS_DETAILS_QUOTE_DETAIL_MODAL_CLOSE =
  'WORK_ORDERS_DETAILS_QUOTE_DETAIL_MODAL_CLOSE';
export const WORK_ORDERS_DETAILS_QUOTES_LOAD_SUCCESS =
  'WORK_ORDERS_DETAILS_QUOTES_LOAD_SUCCESS';
export const WORK_ORDERS_DETAILS_QUOTES_REQUEST_MODAL_SHOW =
  'WORK_ORDERS_DETAILS_QUOTES_REQUEST_MODAL_SHOW';
export const WORK_ORDERS_DETAILS_QUOTES_REQUEST_MODAL_CLOSE =
  'WORK_ORDERS_DETAILS_QUOTES_REQUEST_MODAL_CLOSE';
export const WORK_ORDERS_DETAILS_QUOTES_REMOVE =
  'WORK_ORDERS_DETAILS_QUOTES_REMOVE';
export const WORK_ORDERS_DETAILS_QUOTES_SELECT =
  'WORK_ORDERS_DETAILS_QUOTES_SELECT';
export const WORK_ORDERS_DETAILS_QUOTES_UPDATE_SUCCESS =
  'WORK_ORDERS_DETAILS_QUOTES_UPDATE_SUCCESS';
export const WORK_ORDERS_DETAILS_QUOTES_DELETE_SUCCESS =
  'WORK_ORDERS_DETAILS_QUOTES_DELETE_SUCCESS';
export const WORK_ORDERS_DETAILS_QUOTES_TABLE_FILTER_CHANGE =
  'WORK_ORDERS_DETAILS_QUOTES_TABLE_FILTER_CHANGE';
export const WORK_ORDERS_DETAILS_QUOTES_TABLE_RADIO_SELECT =
  'WORK_ORDERS_DETAILS_QUOTES_TABLE_RADIO_SELECT';
export const WORK_ORDERS_DETAILS_QUOTES_ADD = 'WORK_ORDERS_DETAILS_QUOTES_ADD';
export const WORK_ORDERS_DETAILS_QUOTES_UPDATE =
  'WORK_ORDERS_DETAILS_QUOTES_UPDATE';
export const WORK_ORDERS_DETAILS_UNREAD_NOTES_SET =
  'WORK_ORDERS_DETAILS_UNREAD_NOTES_SET';
export const WORK_ORDERS_DETAILS_SET_CURRENT_WORK_ORDER =
  'WORK_ORDERS_DETAILS_SET_CURRENT_WORK_ORDER';
export const WORK_ORDERS_DETAILS_WORK_ORDER_JOB_NUMBER_CHANGE =
  'WORK_ORDERS_DETAILS_WORK_ORDER_JOB_NUMBER_CHANGE';
export const WORK_ORDERS_DETAILS_WORK_ORDER_JOB_NUMBER_EDIT =
  'WORK_ORDERS_DETAILS_WORK_ORDER_JOB_NUMBER_EDIT';
export const WORK_ORDERS_DETAILS_WORK_ORDER_JOB_NUMBER_SAVING =
  'WORK_ORDERS_DETAILS_WORK_ORDER_JOB_NUMBER_SAVING';
export const WORK_ORDERS_DETAILS_WORK_ORDER_NAME_CHANGE =
  'WORK_ORDERS_DETAILS_WORK_ORDER_NAME_CHANGE';
export const WORK_ORDERS_DETAILS_WORK_ORDER_NAME_EDIT =
  'WORK_ORDERS_DETAILS_WORK_ORDER_NAME_EDIT';
export const WORK_ORDERS_DETAILS_WORK_ORDER_NAME_SAVING =
  'WORK_ORDERS_DETAILS_WORK_ORDER_NAME_SAVING';
export const WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_CHANGE =
  'WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_CHANGE';
export const WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_REMINDER_CHANGE =
  'WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_REMINDER_CHANGE';
export const WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_EDIT =
  'WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_EDIT';
export const WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_SAVING =
  'WORK_ORDERS_DETAILS_WORK_ORDER_NOTE_SAVING';

export const WORK_ORDERS_DETAILS_NEW_TASK_ATTRIBUTE_CHANGE =
  'WORK_ORDERS_DETAILS_NEW_TASK_ATTRIBUTE_CHANGE';
export const WORK_ORDERS_DETAILS_NEW_TASK_FORM_CLEAR =
  'WORK_ORDERS_DETAILS_NEW_TASK_FORM_CLEAR';
export const WORK_ORDERS_DETAILS_NEW_TASK_MODAL_SHOW =
  'WORK_ORDERS_DETAILS_NEW_TASK_MODAL_SHOW';
export const WORK_ORDERS_DETAILS_NEW_TASK_IMAGE_REMOVE =
  'WORK_ORDERS_DETAILS_NEW_TASK_IMAGE_REMOVE';

export const WORK_ORDERS_DETAILS_QUOTES_REQUEST_BID_DUE_DATE_SET =
  'WORK_ORDERS_DETAILS_QUOTES_REQUEST_BID_DUE_DATE_SET';
export const WORK_ORDERS_DETAILS_QUOTES_REQUEST_CONTRACTOR_SET =
  'WORK_ORDERS_DETAILS_QUOTES_REQUEST_CONTRACTOR_SET';
export const WORK_ORDERS_DETAILS_QUOTES_REQUEST_CONTRACTOR_ADD =
  'WORK_ORDERS_DETAILS_QUOTES_REQUEST_CONTRACTOR_ADD';
export const WORK_ORDERS_DETAILS_QUOTES_REQUEST_CONTRACTOR_REMOVE =
  'WORK_ORDERS_DETAILS_QUOTES_REQUEST_CONTRACTOR_REMOVE';
export const WORK_ORDERS_DETAILS_QUOTES_REQUEST_PREVIEW_SHOW =
  'WORK_ORDERS_DETAILS_QUOTES_REQUEST_PREVIEW_SHOW';
export const WORK_ORDERS_DETAILS_QUOTES_REQUEST_PREVIEW_GO_BACK =
  'WORK_ORDERS_DETAILS_QUOTES_REQUEST_PREVIEW_GO_BACK';
export const WORK_ORDERS_DETAILS_QUOTES_REQUEST_SUBMIT_SUCCESS =
  'WORK_ORDERS_DETAILS_QUOTES_REQUEST_SUBMIT_SUCCESS';

export const WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_CLOSE =
  'WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_CLOSE';
export const WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_FORM_INVALID =
  'WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_FORM_INVALID';
export const WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_FORM_EDIT =
  'WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_FORM_EDIT';
export const WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_FORM_VALUE_UPDATE =
  'WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_FORM_VALUE_UPDATE';
export const WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_FORM_VALID =
  'WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_FORM_VALID';
export const WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_SHOW =
  'WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_SHOW';
export const WORK_ORDERS_DETAILS_CREATE_QUOTE_STATE_SET =
  'WORK_ORDERS_DETAILS_CREATE_QUOTE_STATE_SET';
export const WORK_ORDERS_DETAILS_CREATE_QUOTE_VALIDATION_MESSAGE_SET =
  'WORK_ORDERS_DETAILS_CREATE_QUOTE_VALIDATION_MESSAGE_SET';
export const WORK_ORDERS_DETAILS_REVIEW_QUOTE_MODAL_SHOW =
  'WORK_ORDERS_DETAILS_REVIEW_QUOTE_MODAL_SHOW';

export const WORK_ORDERS_DETAILS_CREATE_QUOTES_FORM_SET =
  'WORK_ORDERS_DETAILS_CREATE_QUOTES_FORM_SET';
export const WORK_ORDERS_DETAILS_CREATE_QUOTES_MODAL_SHOW_SET =
  'WORK_ORDERS_DETAILS_CREATE_QUOTES_MODAL_SHOW_SET';
export const WORK_ORDERS_DETAILS_CREATE_QUOTES_STATE_SET =
  'WORK_ORDERS_DETAILS_CREATE_QUOTES_STATE_SET';

export const WORK_ORDERS_DETAILS_DENY_QUOTE_MODAL_CLOSE =
  'WORK_ORDERS_DETAILS_DENY_QUOTE_MODAL_CLOSE';
export const WORK_ORDERS_DETAILS_DENY_QUOTE_MODAL_SHOW =
  'WORK_ORDERS_DETAILS_DENY_QUOTE_MODAL_SHOW';
export const WORK_ORDERS_DETAILS_DENY_QUOTE_REASON_CHANGE =
  'WORK_ORDERS_DETAILS_DENY_QUOTE_REASON_CHANGE';
export const WORK_ORDERS_DETAILS_DENY_QUOTE_QUESTION_ANSWERED =
  'WORK_ORDERS_DETAILS_DENY_QUOTE_QUESTION_ANSWERED';

export const WORK_ORDERS_DETAILS_DECLINE_PROPOSAL_MODAL_CLOSE =
  'WORK_ORDERS_DETAILS_DECLINE_PROPOSAL_MODAL_CLOSE';
export const WORK_ORDERS_DETAILS_DECLINE_PROPOSAL_MODAL_SHOW =
  'WORK_ORDERS_DETAILS_DECLINE_PROPOSAL_MODAL_SHOW';

export const WORK_ORDERS_DETAILS_PROPOSAL_BACK =
  'WORK_ORDERS_DETAILS_PROPOSAL_BACK';
export const WORK_ORDERS_DETAILS_PROPOSAL_COMPLETION_DATE_UPDATE =
  'WORK_ORDERS_DETAILS_PROPOSAL_COMPLETION_DATE_UPDATE';
export const WORK_ORDERS_DETAILS_PROPOSAL_CREATE_SUCCESS =
  'WORK_ORDERS_DETAILS_PROPOSAL_CREATE_SUCCESS';
export const WORK_ORDERS_DETAILS_PROPOSAL_EMAIL_ADD =
  'WORK_ORDERS_DETAILS_PROPOSAL_EMAIL_ADD';
export const WORK_ORDERS_DETAILS_PROPOSAL_EMAIL_CLEAR =
  'WORK_ORDERS_DETAILS_PROPOSAL_EMAIL_CLEAR';
export const WORK_ORDERS_DETAILS_PROPOSAL_EMAIL_MODAL_SHOW_SET =
  'WORK_ORDERS_DETAILS_PROPOSAL_EMAIL_MODAL_SHOW_SET';
export const WORK_ORDERS_DETAILS_PROPOSAL_EMAIL_REMOVE =
  'WORK_ORDERS_DETAILS_PROPOSAL_EMAIL_REMOVE';
export const WORK_ORDERS_DETAILS_PROPOSAL_MARKUP_UPDATE =
  'WORK_ORDERS_DETAILS_PROPOSAL_MARKUP_UPDATE';
export const WORK_ORDERS_DETAILS_PROPOSAL_MESSAGE_SET =
  'WORK_ORDERS_DETAILS_PROPOSAL_MESSAGE_SET';
export const WORK_ORDERS_DETAILS_PROPOSAL_MODAL_CLOSE =
  'WORK_ORDERS_DETAILS_PROPOSAL_MODAL_CLOSE';
export const WORK_ORDERS_DETAILS_PROPOSAL_MODAL_SHOW =
  'WORK_ORDERS_DETAILS_PROPOSAL_MODAL_SHOW';
export const WORK_ORDERS_DETAILS_PROPOSAL_MODAL_EDIT =
  'WORK_ORDERS_DETAILS_PROPOSAL_MODAL_EDIT';
export const WORK_ORDERS_DETAILS_PROPOSAL_NEXT =
  'WORK_ORDERS_DETAILS_PROPOSAL_NEXT';
export const WORK_ORDERS_DETAILS_PROPOSAL_PDF_VIEWER_CLOSE =
  'WORK_ORDERS_DETAILS_PROPOSAL_PDF_VIEWER_CLOSE';
export const WORK_ORDERS_DETAILS_PROPOSAL_PRINT_SUCCESS =
  'WORK_ORDERS_DETAILS_PROPOSAL_PRINT_SUCCESS';
export const WORK_ORDERS_DETAILS_PROPOSAL_START_DATE_UPDATE =
  'WORK_ORDERS_DETAILS_PROPOSAL_START_DATE_UPDATE';
export const WORK_ORDERS_DETAILS_PROPOSAL_SUBJECT_SET =
  'WORK_ORDERS_DETAILS_PROPOSAL_SUBJECT_SET';
export const WORK_ORDERS_DETAILS_PROPOSAL_USERS_LOAD_SUCCESS =
  'WORK_ORDERS_DETAILS_PROPOSAL_USERS_LOAD_SUCCESS';
export const WORK_ORDERS_DETAILS_PROPOSAL_DEPOSIT_SELECTED_CHANGE =
  'WORK_ORDERS_DETAILS_PROPOSAL_DEPOSIT_SELECTED_CHANGE';
export const WORK_ORDERS_DETAILS_PROPOSAL_DEPOSIT_COST_CHANGE =
  'WORK_ORDERS_DETAILS_PROPOSAL_DEPOSIT_COST_CHANGE';
export const WORK_ORDERS_DETAILS_PROPOSAL_ADD_BREAKDOWNITEM =
  'WORK_ORDERS_DETAILS_PROPOSAL_ADD_BREAKDOWNITEM';
export const WORK_ORDERS_DETAILS_PROPOSAL_REMOVE_BREAKDOWNITEM =
  'WORK_ORDERS_DETAILS_PROPOSAL_REMOVE_BREAKDOWNITEM';
export const WORK_ORDERS_DETAILS_PROPOSAL_UPDATE_BREAKDOWNITEM =
  'WORK_ORDERS_DETAILS_PROPOSAL_UPDATE_BREAKDOWNITEM';

export const WORK_ORDERS_DETAILS_PROPOSAL_APPROVAL_MODAL_CLOSE =
  'WORK_ORDERS_DETAILS_PROPOSAL_APPROVAL_MODAL_CLOSE';
export const WORK_ORDERS_DETAILS_PROPOSAL_APPROVAL_SELECT =
  'WORK_ORDERS_DETAILS_PROPOSAL_APPROVAL_SELECT';
export const WORK_ORDERS_DETAILS_PROPOSAL_SUCCESS_MODAL_OPEN =
  'WORK_ORDERS_DETAILS_PROPOSAL_SUCCESS_MODAL_OPEN';
export const WORK_ORDERS_DETAILS_PROPOSAL_SUCCESS_MODAL_CLOSE =
  'WORK_ORDERS_DETAILS_PROPOSAL_SUCCESS_MODAL_CLOSE';

export const WORK_ORDERS_DETAILS_PROPOSAL_QUOTE_UPDATE =
  'WORK_ORDERS_DETAILS_PROPOSAL_QUOTE_UPDATE';

export const WORK_ORDERS_DETAILS_PURCHASE_ORDER_CANCEL =
  'WORK_ORDERS_DETAILS_PURCHASE_ORDER_CANCEL';
export const WORK_ORDERS_DETAILS_PURCHASE_ORDER_DOCUMENT_FILE_SET =
  'WORK_ORDERS_DETAILS_PURCHASE_ORDER_DOCUMENT_FILE_SET';
export const WORK_ORDERS_DETAILS_PURCHASE_ORDER_DOCUMENT_REMOVE =
  'WORK_ORDERS_DETAILS_PURCHASE_ORDER_DOCUMENT_REMOVE';
export const WORK_ORDERS_DETAILS_PURCHASE_ORDER_EDIT_SET =
  'WORK_ORDERS_DETAILS_PURCHASE_ORDER_EDIT_SET';
export const WORK_ORDERS_DETAILS_PURCHASE_ORDER_NUMBER_SET =
  'WORK_ORDERS_DETAILS_PURCHASE_ORDER_NUMBER_SET';
export const WORK_ORDERS_DETAILS_PURCHASE_ORDER_UPDATE =
  'WORK_ORDERS_DETAILS_PURCHASE_ORDER_UPDATE';

export const WORK_ORDERS_DETAILS_QUALIFICATIONS_ADD_SUCCESS =
  'WORK_ORDERS_DETAILS_QUALIFICATIONS_ADD_SUCCESS';
export const WORK_ORDERS_DETAILS_QUALIFICATIONS_LOAD_SUCCESS =
  'WORK_ORDERS_DETAILS_QUALIFICATIONS_LOAD_SUCCESS';
export const WORK_ORDERS_DETAILS_QUALIFICATIONS_MODAL_SHOW =
  'WORK_ORDERS_DETAILS_QUALIFICATIONS_MODAL_SHOW';
export const WORK_ORDERS_DETAILS_QUALIFICATIONS_OPTION_SELECTED =
  'WORK_ORDERS_DETAILS_QUALIFICATIONS_OPTION_SELECTED';
export const WORK_ORDERS_DETAILS_QUALIFICATIONS_SELECTED =
  'WORK_ORDERS_DETAILS_QUALIFICATIONS_SELECTED';
export const WORK_ORDERS_DETAILS_QUALIFICATIONS_SELECTED_BY_ID_SET =
  'WORK_ORDERS_DETAILS_QUALIFICATIONS_SELECTED_BY_ID_SET';
export const WORK_ORDERS_DETAILS_QUALIFICATIONS_SELECTED_CLEAR =
  'WORK_ORDERS_DETAILS_QUALIFICATIONS_SELECTED_CLEAR';
export const WORK_ORDERS_DETAILS_QUALIFICATIONS_TRADES_LOAD_SUCCESS =
  'WORK_ORDERS_DETAILS_QUALIFICATIONS_TRADES_LOAD_SUCCESS';

export const WORK_ORDERS_DETAILS_TASK_SELECT_SET =
  'WORK_ORDERS_DETAILS_TASK_SELECT_SET';
export const WORK_ORDERS_DETAILS_TASK_SELECT_CLEAR =
  'WORK_ORDERS_DETAILS_TASK_SELECT_CLEAR';

export const WORK_ORDERS_DETAILS_SHOW_WOA_SIGNING_MODAL_SET =
  'WORK_ORDERS_DETAILS_SHOW_WOA_SIGNING_MODAL_SET';
export const WORK_ORDERS_DETAILS_WOA_SIGNING_URL_SET =
  'WORK_ORDERS_DETAILS_WOA_SIGNING_URL_SET';
export const WORK_ORDERS_DETAILS_WOAS_SET = 'WORK_ORDERS_DETAILS_WOAS_SET';

export const WORK_ORDERS_ADD_BUILDING_TO_BEGINNING =
  'WORK_ORDERS_ADD_BUILDING_TO_BEGINNING';
