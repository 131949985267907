import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import SimpleTable from 'components/shared/table/SimpleTable';
import faAngleDown from '@fortawesome/fontawesome-free-solid/faAngleDown';
import faAngleUp from '@fortawesome/fontawesome-free-solid/faAngleUp';
import './CoiExp.css';
import classnames from 'classnames';
import { getLiabilities } from 'components/admin/projects/details/project-coi-exps/store/selectors';
import { getCoiExpDocs } from 'components/admin/projects/details/project-coi-exps/store/selectors';
import { loadCoiExpDocsRequest } from 'components/admin/projects/details/project-coi-exps/store/actions';
import CoiExpDocs from 'components/admin/projects/details/project-coi-exps/CoiExpDocs';
import Date from 'components/shared/text/Date';
import moment from 'moment';
import { getDarkMode } from 'selectors/theme';

class CoiExp extends Component {
  static propTypes = {
    coiExp: PropTypes.shape({}).isRequired,
    readOnly: PropTypes.bool,
    index: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      showForm: false,
      readOnly: true,
      index: 0,
    };
  }

  expiresSoon = (coiExpLiability) => {
    return (
      coiExpLiability && moment().diff(coiExpLiability.exp_date, 'days') > -2
    );
  };

  expired = (coiExpLiability) => {
    return (
      coiExpLiability && moment().diff(coiExpLiability.exp_date, 'days') >= 0
    );
  };

  mapCoiExpLiabilities = () => {
    const { coiExp, liabilities } = this.props;
    const coiExpLiabilities = coiExp.coi_exp_liabilities;
    return liabilities.map((liability) => {
      const coiExpLiability = coiExpLiabilities.find(
        (expLiability) => expLiability.liability_id === liability.id
      );
      return coiExpLiability && coiExpLiability.exp_date ? (
        <Date
          className={classnames({
            'expires-soon': this.expiresSoon(coiExpLiability),
            expired: this.expired(coiExpLiability),
          })}
          value={coiExpLiability.exp_date}
          format="MM/DD/YYYY"
        />
      ) : (
        'N/A'
      );
    });
  };

  handleCoiExpDocsPageChange = (page) => {
    const { coiExp, dispatch } = this.props;
    dispatch(loadCoiExpDocsRequest(coiExp.id, page));
  };

  showForm = () => {
    if (!this.state.showForm) {
      this.handleCoiExpDocsPageChange(1, 'client_landlord');
    }
    this.setState({ showForm: !this.state.showForm });
  };

  formattedCsiCode = () => {
    const { coiExp } = this.props;
    return `${coiExp.csi_code_division} ${coiExp.csi_code_label}`;
  };

  render() {
    const { liabilities, coiExp, coiExpDocs, readOnly, darkMode } = this.props;
    const { showForm } = this.state;
    return (
      <div className={`coi-exp ${darkMode ? 'dark-mode' : ''}`}>
        <div
          className={`coi-exp-header ${showForm ? 'show-form' : ''}`}
          onClick={this.showForm}
        >
          <SimpleTable
            className={classnames({
              'projects-table': true,
              'coi-exp-table': true,
            })}
            headers={Array(liabilities.length + 1).fill('')}
            rows={[
              [
                <div className="coi-exp-toggle-container">
                  <h4 className="coi-exp-toggle-title">
                    {this.formattedCsiCode()} - <b>{coiExp.company_name}</b>
                    <span className="coi-exp-total">
                      {coiExp.coi_exp_docs_count}
                    </span>
                  </h4>
                  <span className="coi-exp-toggle">
                    <FontAwesomeIcon
                      icon={showForm ? faAngleUp : faAngleDown}
                    />
                  </span>
                </div>,
                ...this.mapCoiExpLiabilities(),
              ],
            ]}
            emptyMessage="No Exp. Dates"
          />
        </div>
        <Collapse in={showForm} className="collapse-info">
          <div className="coi-exp-container">
            <CoiExpDocs
              coiExpId={coiExp.id}
              coiExpDocs={coiExpDocs && coiExpDocs[coiExp.id]}
              readOnly={readOnly}
              onPageChange={this.handleCoiExpDocsPageChange}
            />
          </div>
        </Collapse>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    liabilities: getLiabilities(state),
    coiExpDocs: getCoiExpDocs(state),
    darkMode: getDarkMode(state),
  };
})(CoiExp);
