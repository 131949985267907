import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Button, {
  BUTTON_ICONS,
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faExpandArrowsAlt, faPlay } from '@fortawesome/fontawesome-free-solid';
import colors from 'styles/colors';
import { getDarkMode } from 'selectors/theme';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import ProjectPhotosFavorite from './ProjectPhotosFavorite';
import {
  openProjectPhotosDetail,
  openProjectPhotosForm,
} from './store/actions';
import './ProjectPhotosItem.css';

const ProjectPhotosItem = (props) => {
  const {
    id,
    cover,
    title,
    count,
    createdBy,
    createdAt,
    favorite,
    type,
    selected,
    hideFavorite,
    hideCreatedInfo,
    hideExpand,
    hideEdit,
    darkMode,
    dispatch,
    currentProject,
    isVideo,
  } = props;

  const onExpand = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (type === 'album' && count === 0) onEdit(e);
    else dispatch(openProjectPhotosDetail(currentProject.id, id, type));
  };

  const onEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(openProjectPhotosForm(currentProject.id, [{ id, type }]));
  };

  const renderCover = () => {
    if (cover && cover.image)
      return (
        <img src={cover.image.gallery.url} alt="cover" className="cover" />
      );

    if (cover && cover.video)
      return <img src={cover.video.thumb.url} alt="cover" className="cover" />;

    return <div className="cover default-cover" />;
  };

  return (
    <div className={`project-photos-item ${selected ? 'selected' : ''}`}>
      {renderCover()}
      {!hideFavorite && (
        <div className="item-favorite">
          <ProjectPhotosFavorite
            id={id}
            type={type}
            favorite={favorite}
            width={32}
            height={32}
          />
        </div>
      )}

      {!hideEdit && (
        <div className="item-edit">
          <Button
            onClick={onEdit}
            icon={BUTTON_ICONS.EDIT_BLACK}
            type={BUTTON_TYPES.DEFAULT}
            color={BUTTON_COLORS.GREEN}
          />
        </div>
      )}

      {isVideo && (
        <div className="item-video">
          <FontAwesomeIcon icon={faPlay} />
        </div>
      )}

      <div
        className={`bottom-content ${type} ${cover ? 'has-image' : 'no-image'}`}
      >
        {type === 'album' && (
          <>
            <div className={`album-info ${hideExpand ? 'hide-expand' : ''}`}>
              <span>{title}</span>
              <span>{count}</span>
            </div>
            {!hideCreatedInfo && (
              <div className="album-info">
                <span>
                  By {createdBy.full_name} at{' '}
                  {moment(createdAt).format('MM/DD/YYYY')}
                </span>
              </div>
            )}
          </>
        )}
        {type === 'photo' && !hideCreatedInfo && (
          <div className="photo-info">
            <span>By {createdBy.full_name}</span>
            <span>{moment(createdAt).format('MM/DD/YYYY')}</span>
          </div>
        )}

        {!hideExpand && (
          <div className="item-expand">
            <Button
              onClick={onExpand}
              type={BUTTON_TYPES.LINK}
              label={
                <FontAwesomeIcon
                  icon={faExpandArrowsAlt}
                  color={
                    darkMode || !!cover
                      ? colors['white-text']
                      : colors['light-mode-black-text']
                  }
                  style={{ fontSize: 18 }}
                />
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

ProjectPhotosItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  cover: PropTypes.shape({}),
  title: PropTypes.string,
  createdBy: PropTypes.shape({
    id: PropTypes.number.isRequired,
    full_name: PropTypes.string.isRequired,
  }),
  createdAt: PropTypes.string,
  favorite: PropTypes.bool,
  selected: PropTypes.bool,
  type: PropTypes.oneOf(['album', 'photo']).isRequired,
  hideFavorite: PropTypes.bool,
  hideCreatedInfo: PropTypes.bool,
  hideEdit: PropTypes.bool,
  hideExpand: PropTypes.bool,
  isVideo: PropTypes.bool,
};

ProjectPhotosItem.defaultProps = {
  hideFavorite: false,
  hideCreatedInfo: false,
  selected: false,
  title: '',
  count: 0,
  isVideo: false,
  hideExpand: false,
};

export default connect((state) => ({
  darkMode: getDarkMode(state),
  currentProject: getCurrentProject(state),
}))(ProjectPhotosItem);
