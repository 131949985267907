import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

export default function Number(props) {
  return (
    <span className={props.className !== null ? props.className : null}>
      {props.format !== null
        ? numeral(props.value).format(props.format)
        : numeral(props.value).value().toString()}
    </span>
  );
}

Number.propTypes = {
  className: PropTypes.string,
  format: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

Number.defaultProps = {
  className: null,
  format: null,
};
