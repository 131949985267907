import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  getProjectReportValue as getReport,
  getFieldProjectResolvedInfo as getResolvedContracts,
} from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import { getDarkMode } from 'selectors/theme';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getprojectReportOpenDetails } from 'components/admin/projects/details/project-daily-report-log/store/selectors';

import {
  getProjectReportResolveInfoByAttr as loadResolvedContract,
  addProjectReportContactInformationItem as addContract,
  projectReportResolvedTableFilter as filterResolvedContract,
  projectReportRestoreItemByAttr as restoreContract,
  setProjectReportTablesOrderChange as setOrderContract,
  updateContractInfoOrderRequest as changeOrderContract,
  setProjectReportTableItemAttrByType as setContract,
  setProjectReportTableMultipleItemsByType as setMultipleContracts,
  setResolvedStatusByAttr as setResolved,
  deleteProjectReportTableItemByType as deleteContract,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';

import ProjectReportPermissions from 'permissions/project-report-permissions';

import CollapsibleSection from 'components/shared/collapsible-section/CollapsibleSection';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import ProjectReportProjectSummaryModal from './ProjectReportProjectSummaryModal';
import ContractInfoTable from './ContractInfoTable';

const ATTR = 'contract_info';
const MODAL_NAME = 'resolved-contract';
const MODAL_TYPE = 'contract_info';

const ContractInfoForm = (props) => {
  const {
    dispatch,
    resolvedInfo,
    reportId,
    report,
    isNewReport,
    darkMode,
    currentUser,
    currentProject,
    missingField,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(true);

  useEffect(() => {
    loadResolvedContracts();
  }, []);

  const loadResolvedContracts = () => {
    if (reportId)
      dispatch(loadResolvedContract(currentProject.id, reportId, ATTR));
  };

  const handleAddItem = () => {
    dispatch(addContract());
  };

  const handleOpenModal = () => {
    setShowModal(true);
    dispatch(loadResolvedContract(currentProject.id, reportId, ATTR));
  };

  const handleSearchModal = (search) => {
    dispatch(filterResolvedContract(MODAL_TYPE, search, ATTR));
  };

  const handleRestore = (item) => {
    dispatch(restoreContract(currentProject.id, reportId, ATTR, item));
  };

  const handleOrderChange = (result) => {
    dispatch(setOrderContract(result, ATTR)).then(() => {
      if (!isNewReport) {
        dispatch(
          changeOrderContract(currentProject.id, reportId, report.contract_info)
        );
      }
    });
  };

  const handleContractChange = (attr, value, item) => {
    dispatch(setContract(item, attr, value, ATTR));
  };

  const handleAddMultiple = (first, items) => {
    dispatch(setMultipleContracts(first, items, ATTR));
  };

  const handleResolve = (item) => {
    dispatch(setResolved(currentProject.id, reportId, ATTR, item));
  };

  const handleDelete = (item, destroy) => {
    dispatch(deleteContract(item, destroy, ATTR));
  };

  const canDelete = ProjectReportPermissions.canDeleteContractInfo(
    currentUser,
    currentProject
  );

  return (
    <>
      <CollapsibleSection
        title="Contract Information"
        right={
          show ? (
            <div className="row-block">
              <Button
                label="Add Item"
                onClick={handleAddItem}
                type={BUTTON_TYPES.LINK}
                icon={BUTTON_ICONS.PLUS}
                key="contract-information-add-item"
              />
              <div className="buttons-contract-information-separate" />
              {resolvedInfo.contract_info &&
                resolvedInfo.contract_info.length > 0 && (
                  <Button
                    label="View Resolved"
                    onClick={handleOpenModal}
                    type={BUTTON_TYPES.LINK}
                    color={BUTTON_COLORS.GREEN}
                    key="contract-information-view-resolved"
                  />
                )}
            </div>
          ) : undefined
        }
        visible={show}
        onShow={setShow}
        withContainer={false}
      >
        <ContractInfoTable
          data={report.contract_info}
          onOrderChange={handleOrderChange}
          onValueChange={handleContractChange}
          onAddMultiple={handleAddMultiple}
          onResolved={handleResolve}
          onDelete={handleDelete}
          missingField={missingField}
          status={report.status}
          canDelete={canDelete}
          reportId={reportId}
          darkMode={darkMode}
        />
      </CollapsibleSection>
      <ProjectReportProjectSummaryModal
        show={showModal}
        projectId={currentProject.id}
        modalName={MODAL_NAME}
        projectReportId={reportId}
        data={resolvedInfo.contract_info || []}
        onClose={() => setShowModal(!showModal)}
        onSearch={handleSearchModal}
        onRestore={handleRestore}
      />
    </>
  );
};

export default connect((state) => ({
  report: getReport(state),
  darkMode: getDarkMode(state),
  currentUser: getCurrentUser(state),
  currentProject: getCurrentProject(state),
  resolvedInfo: getResolvedContracts(state),
  reportId: getprojectReportOpenDetails(state).id,
}))(ContractInfoForm);
