import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Grid, Typography } from '@mui/material';

import { isMixedForm } from 'services/utils/project-photos-util';
import { getDarkMode } from 'selectors/theme';

import tradeIcon from 'images/trades.svg';
import tradeDarkIcon from 'images/tradesDark.svg';
import tagIcon from 'images/tag.svg';
import tagDarkIcon from 'images/tagDark.svg';
import locationIcon from 'images/location.svg';
import locationDarkIcon from 'images/locationDark.svg';

import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getProjectPhotosForm } from './store/selectors';
import {
  setProjectPhotosShowLocations,
  setProjectPhotosShowTags,
  setProjectPhotosShowTrades,
  setProjectPhotosShowUsers,
  updateProjectPhotosForm,
} from './store/actions';
import ProjectPhotosFormLikes from './ProjectPhotosFormLikes';
import ProjectPhotosFormDescription from './ProjectPhotosFormDescription';
import ChipSelector from './ProjectPhotosFormChipSelector';
import ProjectPhotosFormTagModal from './ProjectPhotosFormTagModal';
import ProjectPhotosFormTradesModal from './ProjectPhotosFormTradesModal';
import ProjectPhotosFormLocationsModal from './ProjectPhotosFormLocationsModal';
import ProjectPhotosFormUsersModal from './ProjectPhotosFormUsersModal';

import './ProjectPhotosForm.css';
import ProjectPhotosFormComments from './ProjectPhotosFormComments';
import ProjectPhotosFormMenu from './ProjectPhotosFormMenu';
import ProjectPhotoPermissions from 'permissions/project-photo-permissions';

const ProjectPhotosForm = (props) => {
  const { dispatch, form, darkMode, currentProject, currentUser } = props;

  const mixed = isMixedForm(form);
  const canEdit = ProjectPhotoPermissions.canEdit(
    currentUser,
    currentProject,
    form
  );

  const onFormChange = (values) => {
    const auxDeleted = (attr, deletedAttr, idAttr = 'value') => {
      if (values[attr]) {
        values[deletedAttr] = form[attr].filter(
          (f) => !values[attr].find((u) => u[idAttr] === f[idAttr])
        );
      }
      return values;
    };

    auxDeleted('users', 'deletedUsers', 'id');
    auxDeleted('tags', 'deletedTags');
    auxDeleted('sites', 'deletedSites');
    auxDeleted('areas', 'deletedAreas');
    auxDeleted('trades', 'deletedTrades');

    dispatch(updateProjectPhotosForm(currentProject.id, form.ids, values));
  };

  const tradesInput = (
    <ChipSelector
      values={form.trades}
      onChange={(trades) => onFormChange({ trades })}
      onAdd={() => dispatch(setProjectPhotosShowTrades(true))}
      label="Trades"
      icon={darkMode ? tradeDarkIcon : tradeIcon}
      readOnly={!canEdit}
    />
  );
  const tagsInput = (
    <ChipSelector
      values={form.tags}
      onChange={(tags) => onFormChange({ tags })}
      onAdd={() => dispatch(setProjectPhotosShowTags(true))}
      label="Tags"
      icon={darkMode ? tagDarkIcon : tagIcon}
      readOnly={!canEdit}
    />
  );
  const sitesInput = (
    <ChipSelector
      values={form.sites}
      onChange={(sites) => onFormChange({ sites })}
      onAdd={() => dispatch(setProjectPhotosShowLocations(true))}
      label="Locations"
      icon={darkMode ? locationDarkIcon : locationIcon}
      readOnly={!canEdit}
    />
  );
  const areasInput = form.sites && form.sites.length > 0 && (
    <ChipSelector
      values={form.areas}
      onChange={(areas) => onFormChange({ areas })}
      onAdd={() => dispatch(setProjectPhotosShowLocations(true))}
      label="Areas"
      icon={darkMode ? locationDarkIcon : locationIcon}
      readOnly={!canEdit}
    />
  );
  const usersInput = (
    <ChipSelector
      values={form.users}
      fixedValues={
        form.createdBy ? [{ ...form.createdBy, disabled: true }] : []
      }
      onChange={(users) => onFormChange({ users })}
      onAdd={() => dispatch(setProjectPhotosShowUsers(true))}
      label="Users"
      showHeaders
      headers={['NAME', 'COMPANY']}
      labelKey={'full_name'}
      valueKey={'id'}
      renderRow={(r) => [r.full_name, r.accountable_name]}
      icon={darkMode ? tagDarkIcon : tagIcon}
      readOnly={!canEdit}
    />
  );

  return (
    <>
      <div className="project-photos-form">
        <Grid
          container
          spacing={2}
          paddingX={4}
          paddingY={1}
          flexDirection="column"
        >
          <Grid item>
            <ProjectPhotosFormLikes disabled={mixed} />
          </Grid>
          <Grid item>
            <ProjectPhotosFormDescription />
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={1.5}
              alignItems="baseline"
              justifyContent="space-between"
            >
              <Grid item flexGrow={1}>
                <Typography color={'text.secondary'}>
                  Added by
                  {mixed ? (
                    ' Mixed...'
                  ) : (
                    <Typography color={'primary'} variant="span">
                      {' ' + form.createdBy.full_name}
                    </Typography>
                  )}
                </Typography>
              </Grid>
              {!mixed && (
                <Grid item>
                  <Typography color={'text.secondary'} fontSize={14}>
                    {moment(form.createdAt).format('MM/DD/YYYY [at] HH:mm A')}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <ProjectPhotosFormMenu />
              </Grid>
              <Grid item className="chips-selector-container">
                {tradesInput}
                {sitesInput}
                {areasInput}
                {tagsInput}
                {usersInput}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ProjectPhotosFormComments />
      </div>
      <ProjectPhotosFormTagModal onChange={onFormChange} />
      <ProjectPhotosFormTradesModal onChange={onFormChange} />
      <ProjectPhotosFormLocationsModal onChange={onFormChange} />
      <ProjectPhotosFormUsersModal onChange={onFormChange} />
    </>
  );
};

export default connect((state) => ({
  form: getProjectPhotosForm(state),
  darkMode: getDarkMode(state),
  currentProject: getCurrentProject(state),
  currentUser: getCurrentUser(state),
}))(ProjectPhotosForm);
