import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import FormModal from 'components/shared/modal/FormModal';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import {
  setShowProjectReportPermissionsModal,
  sendProjectReportsPermissions,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';
import { getprojectReportsPermissions } from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import { getDarkMode } from 'selectors/theme';
import './ProjectReportPermissionsModal.css';

const description =
  'Select the Company Types who are able to view issued Project Reports. A confirmed checkbox is required for users to populate as Recipients when creating a new Report.';
const checkboxInfo = [
  {
    id: 7,
    key: 'oar',
    label: 'OAR',
    readOnly: true,
  },
  {
    id: 0,
    key: 'client',
    label: 'Owner',
  },
  {
    id: 1,
    key: 'architect',
    label: 'Architect',
  },
  {
    id: 2,
    key: 'consultant',
    label: 'Consultants',
  },
  {
    id: 3,
    key: 'general_contractor',
    label: 'Contractors',
  },
  {
    id: 4,
    key: 'contractor',
    label: 'Subcontractors',
  },
  {
    id: 5,
    key: 'landlord',
    label: 'Landlord',
  },
  {
    id: 6,
    key: 'property_manager',
    label: 'Property Management',
  },
];

class ProjectReportPermissionsModal extends Component {
  constructor(props) {
    const permissions = {};
    props.projectReportPermissions.forEach((item) => {
      permissions[item] = true;
    });
    super(props);
    this.state = {
      selectedTypes: {
        oar: true,
        ...permissions,
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.projectReportPermissions !== this.props.projectReportPermissions
    ) {
      const permissions = {};
      this.props.projectReportPermissions.forEach((item) => {
        permissions[item] = true;
      });
      this.setState({
        selectedTypes: {
          oar: true,
          ...permissions,
        },
      });
    }
  }

  handleCheckboxChange = (item) => {
    const key = item.key;
    this.setState({
      selectedTypes: {
        ...this.state.selectedTypes,
        [key]: !this.state.selectedTypes[item.key],
      },
    });
  };

  handleContinue = (values) => {
    const { dispatch, projectId } = this.props;
    dispatch(sendProjectReportsPermissions(projectId, values));
    this.handleClose();
  };

  handleClose = () => {
    this.props.dispatch(setShowProjectReportPermissionsModal(false));
  };

  render() {
    const { show, darkMode } = this.props;
    return (
      <FormModal
        id="project-report-form-modal"
        className={classnames({
          'permissions-modal-view': true,
        })}
        show={show}
        onClose={this.handleClose}
        subTitle="Permissions"
      >
        <div className="permissions-modal-form">
          <div
            className={classnames({
              'permissions-description': true,
              'permissions-description-dark': darkMode,
            })}
          >
            {description}
          </div>
          {checkboxInfo.map((item) => (
            <div
              key={item.key}
              className={classnames({
                'permissions-checkbox-container': true,
                'permissions-checkbox-container-dark': darkMode,
              })}
            >
              <LabeledCheckBox
                className="permissions-checkbox"
                label={item.label}
                position="left"
                onChange={this.handleCheckboxChange.bind(null, item)}
                checked={!!this.state.selectedTypes[item.key]}
                readOnly={!!item.readOnly}
              />
            </div>
          ))}
          <div className="permissions-buttons-section">
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Cancel"
              onClick={this.handleClose}
            />
            <div className="permissions-buttons-separate" />
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Continue"
              onClick={this.handleContinue.bind(null, this.state.selectedTypes)}
            />
          </div>
        </div>
      </FormModal>
    );
  }
}

ProjectReportPermissionsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  projectId: PropTypes.number.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  projectReportPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default connect(
  (state) => ({
    darkMode: getDarkMode(state),
    projectReportPermissions: getprojectReportsPermissions(state),
  }),
  null
)(ProjectReportPermissionsModal);
