export const CLIENT_TEAM_BUILDINGS_LOAD_SUCCESS =
  'CLIENT_TEAM_BUILDINGS_LOAD_SUCCESS';
export const CLIENT_TEAM_CLIENT_LOAD_SUCCESS =
  'CLIENT_TEAM_CLIENT_LOAD_SUCCESS';
export const CLIENT_TEAM_CLIENT_FORM_INVALID =
  'CLIENT_TEAM_CLIENT_FORM_INVALID';
export const CLIENT_TEAM_CLIENT_FORM_VALID = 'CLIENT_TEAM_CLIENT_FORM_VALID';
export const CLIENT_TEAM_CLIENT_FORM_VALUE_UPDATE =
  'CLIENT_TEAM_CLIENT_FORM_VALUE_UPDATE';
export const CLIENT_TEAM_EDIT_FORM_LOAD_FROM_CURRENT =
  'CLIENT_TEAM_EDIT_FORM_LOAD_FROM_CURRENT';
export const CLIENT_TEAM_INVITE_USER_FORM_LOAD =
  'CLIENT_TEAM_INVITE_USER_FORM_LOAD';
export const CLIENT_TEAM_INVITE_USER_FORM_VALUE_UPDATE =
  'CLIENT_TEAM_INVITE_USER_FORM_VALUE_UPDATE';
export const CLIENT_TEAM_INVITE_USER_MODAL_SHOW =
  'CLIENT_TEAM_INVITE_USER_MODAL_SHOW';
export const CLIENT_TEAM_INVITE_USER_ID_SET = 'CLIENT_TEAM_INVITE_USER_ID_SET';
export const CLIENT_TEAM_USERS_ADD = 'CLIENT_TEAM_USERS_ADD';
export const CLIENT_TEAM_USERS_EDIT = 'CLIENT_TEAM_USERS_EDIT';
export const CLIENT_TEAM_USERS_REMOVE = 'CLIENT__TEAM_USERS_REMOVE';
export const CLIENT_TEAM_USERS_LOAD_SUCCESS = 'CLIENT_TEAM_USERS_LOAD_SUCCESS';
export const CLIENT_TEAM_ROLES_LOAD_SUCCESS = 'CLIENT_TEAM_ROLES_LOAD_SUCCESS';
export const CLIENT_TEAM_SHOW_VERIFICATION_MODAL =
  'CLIENT_TEAM_SHOW_VERIFICATION_MODAL';

export const CONTRACTOR_TEAM_INVITE_USER_FORM_INVALID =
  'CONTRACTOR_TEAM_INVITE_USER_FORM_INVALID';
export const CONTRACTOR_TEAM_INVITE_USER_FORM_LOAD =
  'CONTRACTOR_TEAM_INVITE_USER_FORM_LOAD';
export const CONTRACTOR_TEAM_INVITE_USER_FORM_VALID =
  'CONTRACTOR_TEAM_INVITE_USER_FORM_VALID';
export const CONTRACTOR_TEAM_INVITE_USER_FORM_VALUE_UPDATE =
  'CONTRACTOR_TEAM_INVITE_USER_FORM_VALUE_UPDATE';
export const CONTRACTOR_TEAM_INVITE_USER_ID_SET =
  'CONTRACTOR_TEAM_INVITE_USER_ID_SET';
export const CONTRACTOR_TEAM_INVITE_USER_MODAL_SHOW =
  'CONTRACTOR_TEAM_INVITE_USER_MODAL_SHOW';
export const CONTRACTOR_TEAM_ROLES_LOAD_SUCCESS =
  'CONTRACTOR_TEAM_ROLES_LOAD_SUCCESS';
export const CONTRACTOR_TEAM_USERS_ADD = 'CONTRACTOR_TEAM_USERS_ADD';
export const CONTRACTOR_TEAM_USERS_EDIT = 'CONTRACTOR_TEAM_USERS_EDIT';
export const CONTRACTOR_TEAM_USERS_LOAD_SUCCESS =
  'CONTRACTOR_TEAM_USERS_LOAD_SUCCESS';
export const CONTRACTOR_TEAM_USERS_REMOVE = 'CONTRACTOR_TEAM_USERS_REMOVE';
export const CONTACTOR_TEAM_CURRENT_PAGE_TECHNICIANS =
  'CONTACTOR_TEAM_CURRENT_PAGE_TECHNICIANS';
export const CONTACTOR_TEAM_PER_PAGE_TECHNICIANS =
  'CONTACTOR_TEAM_PER_PAGE_TECHNICIANS';
export const CONTACTOR_TEAM_TOTAL_TECHNICIANS =
  'CONTACTOR_TEAM_TOTAL_TECHNICIANS';
export const CONTACTOR_TEAM_SET_ACTIVE_TAB = 'CONTACTOR_TEAM_SET_ACTIVE_TAB';
export const CONTRACTOR_TEAM_TECHNICIANS_LOAD_SUCCESS =
  'CONTRACTOR_TEAM_TECHNICIANS_LOAD_SUCCESS';
export const CONTRACTOR_TEAM_TECHNICIANS_ADD =
  'CONTRACTOR_TEAM_TECHNICIANS_ADD';
export const CONTRACTOR_TEAM_INVITE_TECHNICIAN_MODAL_SHOW =
  'CONTRACTOR_TEAM_INVITE_TECHNICIAN_MODAL_SHOW';
export const CONTRACTOR_TEAM_INVITE_TECHNICIAN_ID_SET =
  'CONTRACTOR_TEAM_INVITE_TECHNICIAN_ID_SET';
export const CONTRACTOR_TEAM_TECHNICIANS_EDIT =
  'CONTRACTOR_TEAM_TECHNICIANS_EDIT';
export const CONTRACTOR_TEAM_TECHNICIANS_REMOVE =
  'CONTRACTOR_TEAM_TECHNICIANS_REMOVE';
export const CONTRACTOR_TEAM_SHOW_VERIFICATION_MODAL =
  'CONTRACTOR_TEAM_SHOW_VERIFICATION_MODAL';

export const GENERAL_CONTRACTOR_TEAM_INVITE_USER_FORM_INVALID =
  'GENERAL_CONTRACTOR_TEAM_INVITE_USER_FORM_INVALID';
export const GENERAL_CONTRACTOR_TEAM_INVITE_USER_FORM_LOAD =
  'GENERAL_CONTRACTOR_TEAM_INVITE_USER_FORM_LOAD';
export const GENERAL_CONTRACTOR_TEAM_INVITE_USER_FORM_VALID =
  'GENERAL_CONTRACTOR_TEAM_INVITE_USER_FORM_VALID';
export const GENERAL_CONTRACTOR_TEAM_INVITE_USER_FORM_VALUE_UPDATE =
  'GENERAL_CONTRACTOR_TEAM_INVITE_USER_FORM_VALUE_UPDATE';
export const GENERAL_CONTRACTOR_TEAM_INVITE_USER_ID_SET =
  'GENERAL_CONTRACTOR_TEAM_INVITE_USER_ID_SET';
export const GENERAL_CONTRACTOR_TEAM_INVITE_USER_MODAL_SHOW =
  'GENERAL_CONTRACTOR_TEAM_INVITE_USER_MODAL_SHOW';
export const GENERAL_CONTRACTOR_TEAM_ROLES_LOAD_SUCCESS =
  'GENERAL_CONTRACTOR_TEAM_ROLES_LOAD_SUCCESS';
export const GENERAL_CONTRACTOR_TEAM_USERS_ADD =
  'GENERAL_CONTRACTOR_TEAM_USERS_ADD';
export const GENERAL_CONTRACTOR_TEAM_USERS_EDIT =
  'GENERAL_CONTRACTOR_TEAM_USERS_EDIT';
export const GENERAL_CONTRACTOR_TEAM_USERS_LOAD_SUCCESS =
  'GENERAL_CONTRACTOR_TEAM_USERS_LOAD_SUCCESS';
export const GENERAL_CONTRACTOR_TEAM_USERS_REMOVE =
  'GENERAL_CONTRACTOR_TEAM_USERS_REMOVE';
export const GENERAL_CONTRACTOR_TEAM_SHOW_VERIFICATION_MODAL =
  'GENERAL_CONTRACTOR_TEAM_SHOW_VERIFICATION_MODAL';

export const OWNER_AUTHORIZED_REP_TEAM_INVITE_USER_FORM_INVALID =
  'OWNER_AUTHORIZED_REP_TEAM_INVITE_USER_FORM_INVALID';
export const OWNER_AUTHORIZED_REP_TEAM_INVITE_USER_FORM_LOAD =
  'OWNER_AUTHORIZED_REP_TEAM_INVITE_USER_FORM_LOAD';
export const OWNER_AUTHORIZED_REP_TEAM_INVITE_USER_FORM_VALID =
  'OWNER_AUTHORIZED_REP_TEAM_INVITE_USER_FORM_VALID';
export const OWNER_AUTHORIZED_REP_TEAM_INVITE_USER_FORM_VALUE_UPDATE =
  'OWNER_AUTHORIZED_REP_TEAM_INVITE_USER_FORM_VALUE_UPDATE';
export const OWNER_AUTHORIZED_REP_TEAM_INVITE_USER_ID_SET =
  'OWNER_AUTHORIZED_REP_TEAM_INVITE_USER_ID_SET';
export const OWNER_AUTHORIZED_REP_TEAM_INVITE_USER_MODAL_SHOW =
  'OWNER_AUTHORIZED_REP_TEAM_INVITE_USER_MODAL_SHOW';
export const OWNER_AUTHORIZED_REP_TEAM_ROLES_LOAD_SUCCESS =
  'OWNER_AUTHORIZED_REP_TEAM_ROLES_LOAD_SUCCESS';
export const OWNER_AUTHORIZED_REP_TEAM_USERS_ADD =
  'OWNER_AUTHORIZED_REP_TEAM_USERS_ADD';
export const OWNER_AUTHORIZED_REP_TEAM_USERS_EDIT =
  'OWNER_AUTHORIZED_REP_TEAM_USERS_EDIT';
export const OWNER_AUTHORIZED_REP_TEAM_USERS_LOAD_SUCCESS =
  'OWNER_AUTHORIZED_REP_TEAM_USERS_LOAD_SUCCESS';
export const OWNER_AUTHORIZED_REP_TEAM_USERS_REMOVE =
  'OWNER_AUTHORIZED_REP_TEAM_USERS_REMOVE';
export const OWNER_AUTHORIZED_REP_TEAM_SHOW_VERIFICATION_MODAL =
  'OWNER_AUTHORIZED_REP_TEAM_SHOW_VERIFICATION_MODAL';
