import { showErrorMessage } from 'actions/messages';
import { reportsApi } from 'services/api/reports-api';
import {
  getReportResults,
  getSelectAllChecked,
  getSelectedWorkOrders,
  getCurrentPage,
} from 'selectors/admin/reports/reports';
import {
  REPORT_RESULTS_LOAD_SUCCESS,
  REPORT_PAGE_CHANGE,
  REPORT_START_DATE_CHANGE,
  REPORT_END_DATE_CHANGE,
  REPORT_EXTRA_FILTER_VALUE_CHANGE,
  REPORT_SEARCHING_CHANGE,
  REPORTS_RESET,
  REPORT_WORK_ORDER_SELECT_CLEAR,
  REPORT_WORK_ORDER_SELECT_SET,
} from 'actions/admin/reports/action-types';

export function changePage(newPage) {
  return {
    type: REPORT_PAGE_CHANGE,
    payload: newPage,
  };
}

export function clearSelection() {
  return {
    type: REPORT_WORK_ORDER_SELECT_CLEAR,
  };
}

export function exportReport(
  report,
  ids,
  startDate,
  endDate,
  extraFilterValues
) {
  return (dispatch) => {
    return reportsApi
      .exportReport(report, ids, startDate, endDate, extraFilterValues)
      .then((response) => {
        if (response.ok) {
          return {
            ...response.content,
            fileName: `${report}.xlsx`,
          };
        } else {
          dispatch(showErrorMessage(response.errors));
          return null;
        }
      });
  };
}

export function loadReportRequest(
  report,
  page,
  startDate,
  endDate,
  extraFilterValues
) {
  return (dispatch) => {
    dispatch(changeSearching(true));
    return reportsApi
      .getReport(report, page, startDate, endDate, extraFilterValues)
      .then((response) => {
        if (response.ok) {
          dispatch(loadReportSuccess(response.results));
          dispatch(changePage(page));
          dispatch(selectWorkOrder());
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        dispatch(changeSearching(false));

        return response;
      });
  };
}

export function loadReportSuccess(results) {
  return {
    type: REPORT_RESULTS_LOAD_SUCCESS,
    payload: results,
  };
}

export function changeStartDate(value) {
  return {
    type: REPORT_START_DATE_CHANGE,
    payload: value,
  };
}

export function changeEndDate(value) {
  return {
    type: REPORT_END_DATE_CHANGE,
    payload: value,
  };
}

export function changeExtraFilterValue(attribute, value) {
  return {
    type: REPORT_EXTRA_FILTER_VALUE_CHANGE,
    payload: { attribute: attribute, value: value },
  };
}

export function changeSearching(value) {
  return {
    type: REPORT_SEARCHING_CHANGE,
    payload: value,
  };
}

export function resetReports() {
  return {
    type: REPORTS_RESET,
  };
}

export function selectAllWorkOrders() {
  return (dispatch, getState) => {
    const currentPage = getCurrentPage(getState());
    const selectAllChecked = getSelectAllChecked(getState());
    let currentPageSelection = {};
    if (selectAllChecked) {
      currentPageSelection[currentPage] = {};
      dispatch(setWorkOrderSelection(currentPageSelection, false));
    } else {
      let workOrderIds = {};
      const reportResults = getReportResults(getState());
      if (reportResults !== null && reportResults.data.length > 0) {
        workOrderIds = extractWorkOrderIds(reportResults.data);
      }
      currentPageSelection[currentPage] = workOrderIds;
      dispatch(setWorkOrderSelection(currentPageSelection, true));
    }
  };
}

export function selectWorkOrder(workOrderId) {
  return (dispatch, getState) => {
    const currentPage = getCurrentPage(getState());
    const selectedWorkOrders = getSelectedWorkOrders(getState());
    let newSelectedWorkOrders = { ...selectedWorkOrders[currentPage] };
    let currentPageSelection = {};
    if (
      selectedWorkOrders[currentPage] !== undefined &&
      selectedWorkOrders[currentPage][workOrderId] !== undefined
    ) {
      delete newSelectedWorkOrders[workOrderId];
    } else if (workOrderId !== undefined) {
      newSelectedWorkOrders[workOrderId] = workOrderId;
    }

    const reportResults = getReportResults(getState());
    let allChecked = false;
    if (reportResults !== null && reportResults.data.length > 0) {
      const workOrderIds = extractWorkOrderIds(reportResults.data);
      allChecked =
        Object.keys(workOrderIds).length ===
        Object.keys(newSelectedWorkOrders).length;
    }

    currentPageSelection[currentPage] = newSelectedWorkOrders;
    dispatch(setWorkOrderSelection(currentPageSelection, allChecked));
  };
}

function extractWorkOrderIds(reportResults) {
  return (
    reportResults
      // For some reports, each item contains a work_order, for other just the work order id
      .map((result) =>
        result.work_order
          ? result.work_order.id
          : result.project_id
          ? result.project_id
          : result.id
      )
      .reduce((accumulator, workOrderId) => {
        if (accumulator[workOrderId] === undefined) {
          accumulator[workOrderId] = workOrderId;
        }
        return accumulator;
      }, {})
  );
}

function setWorkOrderSelection(selectedWorkOrders, allChecked) {
  return {
    type: REPORT_WORK_ORDER_SELECT_SET,
    payload: { selectedWorkOrders, allChecked },
  };
}
