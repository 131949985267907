import { showErrorMessage } from 'actions/messages';
import { fieldWorkOrdersApi } from 'services/api/field-work-orders-api';
import {
  FIELD_WORK_ORDERS_COUNT,
  FIELD_WORK_ORDERS_LOAD,
} from 'components/admin/field-work-orders/store/action-types';

export function fieldWorkOrdersCountSuccess(count) {
  return {
    type: FIELD_WORK_ORDERS_COUNT,
    payload: count,
  };
}

export function fieldWorkOrdersCount(filters) {
  return (dispatch) => {
    return fieldWorkOrdersApi
      .getFieldWorkOrdersAllCount(filters)
      .then((response) => {
        if (response.ok) {
          dispatch(fieldWorkOrdersCountSuccess(response.count));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function fieldWorkOrdersSuccess(fieldWorkOrders) {
  return {
    type: FIELD_WORK_ORDERS_LOAD,
    payload: fieldWorkOrders,
  };
}

export function fieldWorkOrdersRequest(page, filters) {
  return (dispatch) => {
    return fieldWorkOrdersApi
      .getFieldWorkOrdersAll(page, filters)
      .then((response) => {
        if (response.ok) {
          dispatch(fieldWorkOrdersSuccess({ ...response, currentPage: page }));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}
