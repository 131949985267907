import {
  GENERAL_CONTRACTORS_PAGE_CHANGE,
  NEXT_STAGE,
  LICENCE_NUMBER_VALUE_CHANGE,
  NEW_ONBOARDING,
  COMPANY_LOGO_FILE_SET,
  GENERAL_CONTRACTOR_ONBOARDING_STAGE_CHANGE,
  GENERAL_CONTRACTOR_PROJECTS_PAGE_CHANGE,
  GENERAL_CONTRACTORS_CONTACTS_MODAL_SHOW,
  GENERAL_CONTRACTORS_INVITE_FORM_CLEAR,
  GENERAL_CONTRACTORS_INVITE_USER_SHOW,
  GENERAL_CONTRACTORS_NEW_FORM_VALID,
  GENERAL_CONTRACTORS_NEW_FORM_INVALID,
  GENERAL_CONTRACTORS_NEW_FORM_VALUE_CHANGE,
} from 'components/admin/general-contractors/store/action-types';

const stages = ['licence-input', 'logo-upload'];

const initialState = {
  currentPage: 1,
  currentStage: 'licence-input',
  licenceNumber: '',
  companyLogo: { url: '', image: '' },
  hideSideBar: false,
  projectsCurrentPage: 1,
  showContacts: false,
  invalidFields: [],
  form: {
    companyName: '',
    licenseNumber: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    streetAddress: '',
    unitNumber: '',
    city: '',
    state: '',
    zipCode: '',
    confirmed: false,
    roleId: '', // for user invite
    selectedUnionsId: [],
    other_union: '',
  },
  validationMessage: '',
  showInviteGeneralContractorUserModal: false,
};

export default function generalContractorsUiReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GENERAL_CONTRACTORS_PAGE_CHANGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case NEW_ONBOARDING:
      return initialState;

    case NEXT_STAGE:
      return {
        ...state,
        currentStage: stages[stages.indexOf(state.currentStage) + 1],
      };

    case GENERAL_CONTRACTOR_ONBOARDING_STAGE_CHANGE:
      return {
        ...state,
        currentStage: action.payload,
      };

    case LICENCE_NUMBER_VALUE_CHANGE:
      return {
        ...state,
        licenceNumber: action.payload,
      };

    case COMPANY_LOGO_FILE_SET:
      return {
        ...state,
        companyLogo: { url: action.payload.url, image: action.payload.file },
      };

    case GENERAL_CONTRACTOR_PROJECTS_PAGE_CHANGE:
      return {
        ...state,
        projectsCurrentPage: action.payload,
      };

    case GENERAL_CONTRACTORS_CONTACTS_MODAL_SHOW:
      return {
        ...state,
        showContacts: action.payload,
      };

    case GENERAL_CONTRACTORS_INVITE_FORM_CLEAR:
      return {
        ...state,
        form: initialState.form,
        invalidFields: [],
        validationMessage: '',
      };

    case GENERAL_CONTRACTORS_INVITE_USER_SHOW:
      return {
        ...state,
        showInviteGeneralContractorUserModal: action.payload,
      };

    case GENERAL_CONTRACTORS_NEW_FORM_VALID:
      return {
        ...state,
        invalidFields: [],
        validationMessage: '',
      };

    case GENERAL_CONTRACTORS_NEW_FORM_INVALID:
      return {
        ...state,
        invalidFields: action.payload.invalidFields,
        validationMessage: action.payload.message,
      };

    case GENERAL_CONTRACTORS_NEW_FORM_VALUE_CHANGE:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.attribute]: action.payload.value,
        },
      };

    default:
      return state;
  }
}
