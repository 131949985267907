import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import './CheckBox.css';

class SimpleCheckBox extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    readOnly: PropTypes.bool,
    shape: PropTypes.oneOf(['round', 'square', 'bell']),
    size: PropTypes.oneOf(['small', 'medium']),
    darkModeFixed: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    className: null,
    readOnly: false,
    shape: 'square',
    size: 'small',
    darkModeFixed: false,
  };

  handleSelectChange = (event) => {
    if (!this.props.readOnly && this.props.onChange) {
      this.props.onChange(this.props.id, event);
    }
  };

  render() {
    const {
      id,
      className,
      inputClassName,
      size,
      readOnly,
      checked,
      shape,
      darkMode,
      darkModeFixed,
    } = this.props;

    return (
      <label className="native-checkbox">
        <input
          type="checkbox"
          className={inputClassName}
          value={id}
          onClick={this.handleSelectChange}
        />
        <span
          className={`checkbox-container ${className || ''} ${
            darkModeFixed || darkMode ? ' dark-mode' : ''
          } ${readOnly ? ' read-only' : ''} ${
            checked ? ' checked' : ''
          } size-${size} shape-${shape} `}
        ></span>
      </label>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(SimpleCheckBox);
