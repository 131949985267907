import axios from 'axios';
import numeral from 'numeral';

import BaseApi from './base-api';

class SubDailyReportsApi extends BaseApi {
  getDailyReports(projectId, currentPage) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/sub_daily_reports?page=${currentPage}`
      )
      .then((response) => {
        return {
          ok: true,
          dailyReports: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getDailyReportsCount(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/sub_daily_reports/count`)
      .then((response) => {
        return {
          ok: true,
          total: numeral(response.headers['total']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getDailyReportLogPdf(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/sub_daily_report_log/print`, {
        responseType: 'blob',
      })
      .then((response) => {
        return {
          ok: true,
          dailyReportLogPdf: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  addDailyReport(values, reportDate, projectId) {
    const data = {
      sub_daily_report: {
        report_date: reportDate,
        sub_daily_report_entries_attributes: values,
      },
    };

    return axios
      .post(`${this.baseUrl}/projects/${projectId}/sub_daily_reports`, data)
      .then((response) => {
        return {
          ok: true,
          dailyReport: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateDailyReport(values, projectId, dailyReportId) {
    const data = {
      sub_daily_report: {
        report_date: values.reportDate,
        gc_staff_on_site: values.gcStaffOnSite,
        inspections: values.inspections,
        deliveries: values.deliveries,
        received_submittal_ids: values.received_submittal_ids,
        visitors: values.visitors,
        phone_calls: values.phone_calls,
        status: values.status,
        sub_daily_report_entries_attributes:
          values.dailyReportEntriesAttributes,
      },
    };

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/sub_daily_reports/${dailyReportId}`,
        data
      )
      .then((response) => {
        return {
          ok: true,
          dailyReport: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  duplicateDailyReport(values, projectId, dailyReportId) {
    const data = {
      sub_daily_report: {
        report_date: values.reportDate,
      },
    };

    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/sub_daily_reports/${dailyReportId}/duplicate`,
        data
      )
      .then((response) => {
        return {
          ok: true,
          dailyReport: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getDailyReport(projectId, id, loadingDisabled = false) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/sub_daily_reports/${id}`, {
        loadingDisabled: loadingDisabled,
      })
      .then((response) => {
        return {
          ok: true,
          dailyReport: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getDailyReportPdf(projectId, dailyReportId) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/sub_daily_reports/${dailyReportId}/print`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        return {
          ok: true,
          reportPdf: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  deleteDailyReport(projectId, dailyReportId) {
    return axios
      .delete(
        `${this.baseUrl}/projects/${projectId}/sub_daily_reports/${dailyReportId}`
      )
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const subDailyReportsApi = new SubDailyReportsApi();
