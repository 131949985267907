const css = `
<style>
  
  body {
    font-family: 'Titillium Web', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c4249;
    text-align: left;
    font-size: 12px;
    margin: 0;
    padding: 0;
  }
  
  .col {
    -webkit-box-flex: 1;
  }
  
  .flexrow {
    display: -webkit-box;
    -webkit-box-pack: flex-start;
  
    &.justify{
      -webkit-box-pack: flex-justify;
    }
  
    &.start{
      -webkit-box-align: flex-start;
    }
  }
  
  .vertical-justify {
    color: blue;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-pack: justify;
  }
  
  .header {
    color: white;
    font-size: 23px;
    background-color: #191b24;
    text-align: left;
    padding: 5px 28px;
    font-weight: 600;
  }
  
  .subheader {
    font-size: 23px;
    background-color: #f3f3f3;
    text-align: left;
    padding: 5px 28px;
    font-weight: 100;
  }
  
  .sub-header {
    font-style: italic;
    font-size: 24px;
    padding-left: 26px;
    margin-top: 16px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      background-color: #eaeaec;
    }
    tr:nth-child(even) {
      background-color: #f3f3f3;
    }
  }
  
  .empty {
    display: -webkit-box;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    min-height: 200px;
    font-size: 21px;
  }
  
  .remarks {
    padding: 10px 26px 0px 26px;
    font-size: 21px;
  }
  
  p.small-memo {
    background-color: #eaeaec;
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
    padding: 0px 28px;
    margin: 0px;
    width: 100%;
  }
  
  .detail-item {
    margin-right: 10px;
    margin-left: 5px;
    font-size: 20px;
    .label {
      font-size: 20px;
      font-weight: 600;
      color:black;
      padding-left: 0;
    }
    .value {
      font-size: 20px;
  
      span {
        display: block;
      }
    }
  
    &.small{
      .label {
        font-size: 20px;
        color:black;
      }
      .value {
        font-size: 20px;
      }
    }
  
    &.flexrow{
      .label{
        margin-right: 12px;
        color:black;
      }
    }
  
    &.inline{
      .value, .label{
        display: inline;
        color:black;

      }
  
      .label{
        margin-right: 12px;
        color:black;

      }
    }
  }
  
  .detail-remarks {
    margin-left: 30px;
    font-size: 20px;
    width: 100%;
  }
  
  .first-page {
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  
  .project-report-header {
    background: #191b24;
    color: white;
    display: -webkit-box;
    -webkit-box-pack: flex-start;
    -webkit-box-align: center;
  }
  
  .project-report-header .first {
    padding: 12px 28px;
    -webkit-box-flex: 1;
  }
  
  .project-report-header .first h2 {
    margin: 0px;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .project-report-header .first h3 {
    margin: 0px;
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
  }
  
  .project-report-header .second {
    padding: 0px 28px;
    -webkit-box-flex: 1;
  }
  
  .project-report-header .second h2 {
    text-align: right;
    font-size: 22px;
    font-weight: normal;
  }
  
  .project-report-header .second h2 span {
    margin-left: 14px;
    color: #d4fb00 !important;
  }
  
  
  
  .project-report-footer {
    .page-number {
      text-align: right;
      font-size: 15px;
      padding-right: 32px;
    }
  
    &.field-reports{
      display: -webkit-box;
      -webkit-box-pack: justify;
      -webkit-box-align: start;
      padding-left: 24px;
  
      .page-number {
        margin-top: 6px;
      }
  
      .company-info{
        margin-top: -2px;
        font-size: 14px;
        line-height: 19px;
        width: 70%;
  
        .name{
          display: block;
          font-weight: 600;
          width: 100%;
        }
  
        .address{
          width: 100%;
        }
      }
  
    }
  }
  
  .project-report-title {
    .col {
      min-height: 310px;
      &.no-height{
        min-height: 0;
      }
    }
  
    .col:first-child {
      width: 48%;
      padding-left: 28px;
      margin-top: 18px;
  
      p {
        margin-bottom: 28px;
        font-size: 30px;
        line-height: 36px;
        font-weight: normal;
        min-height: 72px;
  
        &.title{
          margin-bottom: 18px;
          min-height: 0;
          text-align: left;
        }
      }
      .title-details {
        margin: 0px;
        .label {
          margin: 0;
          width: 25;
          font-size: 19px;
          min-width: 130px;
          font-weight: 600;
          color:black;
        }
        .value {
          font-size: 21px;
          width: 75%;
          margin-left:20px;
  
          span {
            display: block;
            padding-left:15px !important;
          }
        }
      }
      .title-details:nth-child(2) {
        margin-bottom: 46px;
      }
    }
  
    .col:nth-child(2) {
      width: 52%;
      position: relative;
      img {
        width: 100%;
        height: 310px;
      }
    }
  
    .col.no-padding{
      padding-left:0;
    }
    .col.margin-top{
      margin-top: 18px;
    }
  
    .col.col-20{
      width: 18%;
    }
  
    .users {
      width: 100%;
      min-height: 110px;
      padding: 0px 28px;
      .title-details {
        margin: 0px;
        .label {
          margin: 0;
          width: 25;
          font-size: 19px;
          min-width: 130px;
          font-weight: 600;
          color:black;

        }
        .value {
          font-size: 21px;
          width: 75%;
        }
      }
    }
  
    .images-container {
      width: 100%;
      margin-bottom: 24px;
      white-space: nowrap;
      padding: 0px 28px;
      margin-top: 24px;
      min-height: 160px;
      .images-container-row {
        width: 100%;
        padding: 0px;
        img {
          width: auto;
          height: 160px;
          padding: 5px;
          &.landscape {
            width: 266px;
          }
          &.portrait {
            width: 127px;
          }
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
  
  .project-report-summary {
    min-height: 260px;
    .content {
      padding: 22px 24px 0px;
      .col {
        padding: 0px;
        width: 48%;
      }
      .col:nth-child(2) {
        width: 35%;
        margin-left: 28px;
        position: relative;
        .completion-date {
          position: absolute;
          display: inline-block;
          top: 180px;
          left: 30px;
          > span {
            font-size: 18px;
            font-weight: 600;
            display: block;
            text-align: right;
          }
        }
        .graph {
          img {
            padding-top: 45px;
          }
        }
      }
  
      p {
        font-size: 21px;
      }
  
      .label {
        font-size: 21px;
        display: inline-block;
        width: 100%;
        font-weight: 600;
        color: black;
      }
      .value {
        display: block;
        background-color: #d4fb00;
        padding: 20px 28px;
        font-size: 31px;
        font-weight: 600;
      }
      .row-current-costs {
        margin-top: 20px;
        .paid-to-date {
          width: 60%;
        }
        .current-sf {
          width: 40%;
          .label, .value {
            margin-left: 15px;
            color:black;
          }
        }
      }
    }
  }
  
  .project-report-directory {
    margin-bottom: 24px;
    .content-header {
      .header {
        margin-top: 3px;
        -webkit-box-flex: 1;
      }
      .header:first-child {
        margin-right: 3px;
      }
      .header:nth-child(2) {
        margin-left: 3px;
      }
    }
  
    .content {
      min-height: 225px;
      font-size: 13px;
      .col {
        width: 50%;
        min-height: 100px;
      }
      .col:first-child {
        padding-right: 3px;
      }
      .col:nth-child(2) {
        padding-left: 3px;
      }
      table {
        thead {
          th {
            width: 50%;
            div {
              background-color: #eaeaec;
              font-weight: 600;
              font-style: italic;
              font-size: 18px;
              padding: 4px 0px;
              margin-bottom: 20px;
            }
            &:first-child {
              div {
                padding-left: 26px;
              }
            }
          }
        }
        td {
          width: 50%;
          background-color: #FFFFFF;
          font-size: 21px;
          padding: 2px 0px;
          &:first-child {
            padding-left: 26px;
          }
        }
      }
      .sub-header {
        background-color: #eaeaec;
        margin-bottom: 20px;
        font-size: 18px;
        font-style: italic;
        font-weight: 600;
        padding: 4px 26px;
      }
      ul {
        font-size: 21px;
        list-style-type: none;
        margin: 0px;
  
        li {
          margin: 0px;
          &:before {
            content: '\x15AA';
            font-size: 12px;
            margin-top: -4px;
            margin-right: 12px;
          }
        }
      }
    }
  }
  
  .weather-report{
    margin-top: 36px;
    font-size: 20px;
    .content {
      padding: 22px 24px;
    }
  }
  .weather-report-remarks{
    margin-left: -20px;
    font-size: 20px;
    width: 100%;
  }
  .observation-report{
    .content {
      padding: 22px 24px 32px;
  
      .space{
        height: 27px;
      }
  
      .detail-item{
        margin-top: 9px;
        font-size: 20px;

      }
  
      span{
        .detail-item{
          display: inline;
          margin-right: 36px;
          font-size: 20px;
          &:last-child{
            margin-right: 0;
          }
          
          .label,.value{
            display: inline;
            color:black;
            font-size: 20px;
          }
        }
      }
  
      .flexrow .detail-item:first-child{
        margin-right: 36px;
        font-size: 20px;

      }
    }
  }
  
  .project-open-items{
    .sub-header {
      font-style: italic;
      font-size: 24px;
      padding-left: 0;
      margin-top: 16px;
      margin-bottom: 10px;
    }
    margin-left: 20px;
    table {
      table-layout: fixed;
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      margin-top: 1px;  
  
      thead {
        th {
          font-weight: 600;
          font-style: italic;
          font-size: 20px;
  
          &:first-child {
            padding-left: 26px;
            width: 12%;
          }
          &:nth-child(4){
            width: 14%;
          }
          &:last-child {
            padding-right: 26px;
            width: 8%;
          }
        }
      }
  
      td {
        font-size: 20px;
        &:first-child {
          padding-left: 26px;
          width: 12%;
  
        }
        &:nth-child(4){
          width: 14%;
        }
        &:last-child {
          padding-right: 26px;
          width: 8%;
        }
      }
    }
  }
  
  .right-cell {
    float: right;
  }
  
  .project-report-contract {
    padding-top: 3px;
    padding-bottom: 24px;
  
    min-height: 125px;
    table {
      table-layout: fixed;
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      margin-top: 1px;
  
      th {
        font-weight: 600;
        font-style: italic;
        font-size: 18px;
        padding: 4px 0px;
        width: 12%;
  
        &:first-child {
          padding-left: 26px;
          width: 41%;
        }
        &:nth-child(2), 
        &:nth-child(3) {
          width: 16%;
        }
        .header:nth-child(2) {
          margin-left: 3px;
        }
  
        &:nth-child(6) {
          width: 18%;
        }
        &:last-child {
          padding-right: 5%;
          width: 17%;
        }
      }
  
      td {
        font-size: 21px;
        padding: 1px 0px;
  
        &:first-child {
          padding-left: 26px;
        }
        &:last-child {
          padding-right: 5%;
        }
      }
    }
  
    .right-cell {
      white-space: nowrap; 
    }
  }
  
  .project-report-status {
    padding-top: 3px;
    padding-bottom: 24px;
  
    .sub-header {
      font-style: italic;
      font-size: 26px;
      padding-left: 26px;
      margin-top: 16px;
    }
    .header + * {
      margin-top: 0px;
    }
    table {
      table-layout: fixed;
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      margin-top: 1px;
  
      thead {
        th {
          font-weight: 600;
          font-style: italic;
          font-size: 18px;
          padding: 4px 0px;
  
          &:first-child {
            padding-left: 26px;
            width: 20%;
          }
          &:last-child {
            padding-right: 5%;
            width: 80%;
          }
        }
      }
  
      td {
        font-size: 21px;
        padding: 1px 0px;
  
        &:first-child {
          padding-left: 26px;
        }
        &:last-child {
          padding-right: 5%;
        }
      }
    }
  }
  
  .project-report-schedule {
    padding-top: 3px;
    padding-bottom: 24px;
  
    .header {
      margin-top: 0px;
    }
  
    .sub-header {
      font-style: italic;
      font-size: 26px;
      padding-left: 26px;
  
      &:table{
        margin-top: 0px;
      }
  
      &.attached{
        background-color: #eaeaec;
        margin-top: 16px;
        margin-bottom: 20px;
        font-size: 18px;
        font-style: italic;
        font-weight: 600;
        padding: 4px 26px;
  
      }
    }
  
    .header + * {
      margin-top: 0px;
    }
    table {
      table-layout: fixed;
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      margin-top: 1px;
      thead {
        th {
          font-weight: 600;
          font-style: italic;
          font-size: 18px;
          padding: 4px 0px;
          width: 13%;
          &:first-child {
            padding-left: 26px;
          }
          &:last-child {
            padding-right: 5%;
          }
        }
      }
      td {
        font-size: 21px;
        padding: 1px 0px;
        &:first-child {
          padding-left: 26px;
        }
        &:last-child {
          padding-right: 5%;
        }
      }
    }
   
    ul {
      font-size: 21px;
      list-style-type: none;
      margin: 0px;
  
      li {
        margin: 0px;
        &:before {
          content: '\x15AA';
          font-size: 12px;
          margin-top: -4px;
          margin-right: 12px;
        }
      }
    }
  }
  
  .project-report-budget {
    padding-top: 3px;
    padding-bottom: 24px;
  
    .header {
      margin-top: 0px;
    }
  
    .sub-header {
      font-style: italic;
      font-size: 26px;
      padding-left: 26px;
      margin-top: 16px;
    }
    .header + * {
      margin-top: 0px;
    }
    table {
      table-layout: fixed;
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      margin-top: 1px;
  
      thead {
        th {
          font-weight: 600;
          font-style: italic;
          font-size: 18px;
          padding: 4px 0px;
          width: 16%;
  
          &:first-child {
            padding-left: 26px;
            width: 25%;
          }
          &:last-child {
            padding-right: 3%;
            width: 17%;
          }
        }
      }
      
      td {
        font-size: 21px;
        padding: 1px 0px;
  
        &:first-child {
          padding-left: 26px;
        }
        &:last-child {
          padding-right: 3%;
        }
      }
  
      tr.child {
        td {
          &:first-child {
            padding-left: 52px;
          }
        }
      }
  
      tr:last-child {
        font-weight: 600;
      }
  
      th:not(:first-child),
      td:not(:first-child) {
        text-align: right;
      }
    }
  }
  
  .project-report-photos {
    padding-top: 3px;
    padding-bottom: 24px;
  }
  
  .project-report-photos .header {
    margin-top: 0px;
  }
  
  .project-report-photos .container {
    display: flex;
    flex-direction: column; /* Stack images vertically */
    width: 100%;
    padding: 0px 13px;
    align-items: center; /* Center-align the images */
  }
  
  .project-report-photos .img-content {
    padding: 13px;
  }
  
  .project-report-photos .img-content.landscape,
  .project-report-photos .img-content.portrait {
    width: auto; /* Adjust the width based on the content */
  }
  
  .project-report-photos img {
    width: 100%; /* Make images responsive */
    height: auto;
  }
  
  .project-report-photos .img-description {
    width: 100%;
    font-style: italic;
    font-size: 18px;
    line-height: 22px;
    margin: 0px;
  }
  
  
  .project-report-attached-documents {
    padding-top: 3px;
    padding-bottom: 24px;
  
    .header {
      margin-top: 0px;
    }
  
    ul {
      display: inline-block;
      font-size: 21px;
      list-style-type: none;
      margin: 0px;
      width: 45%;
  
      li {
        margin: 0px;
        &:before {
          content: '\x15AA';
          font-size: 12px;
          margin-top: -4px;
          margin-right: 12px;
        }
      }
    }
  }
  
  .project-report-disclaimer {
    padding-top: 3px;
  
    .header {
      margin-top: 0px;
    }
  
    .content{
      padding: 10px 26px 0px 26px;
      font-size: 20px;
    }
  }
  
  
  .clearfix{
    clear: both;
  }
  
  .padding-bottom{
    padding-bottom: 36px;
  }
  </style>

  `;

export default css;
