import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import BasicTable from 'components/shared/table/BasicTable';
import './SimpleTable.css';

class SimpleTable extends Component {
  static propTypes = {
    className: PropTypes.string,
    headers: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    ),
    rows: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.any), // simple array with "anything" for each column
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            .isRequired, // the "id" of the row, will be returned when clicking
          className: PropTypes.string, // Class to add to each cell for the current row
          selectable: PropTypes.bool, // if this particular row can be selected (only works if onRowSelected function is passed), default to true
          data: PropTypes.arrayOf(PropTypes.any).isRequired,
        }),
      ])
    ),
    emptyMessage: PropTypes.string,
    showBottomLine: PropTypes.bool,
    headersOnly: PropTypes.bool,
    actionCells: PropTypes.number,
    onRowSelected: PropTypes.func,
    onGenerateLink: PropTypes.func,
    stickyHeader: PropTypes.bool,
    noRows: PropTypes.bool,
  };

  static defaultProps = {
    headers: [],
    rows: [],
    emptyMessage: 'No Items',
    showBottomLine: false,
    headersOnly: false,
    newView: false,
    stickyHeader: false,
    noRows: false,
    actionCells: 0,
  };

  buildElement = (id, element) => {
    const { onGenerateLink } = this.props;
    if (onGenerateLink) {
      return <a href={onGenerateLink(id)}>{element}</a>;
    } else {
      return element;
    }
  };

  handleRowClick = (id, selectable, event) => {
    if (
      typeof this.props.onGenerateLink === 'undefined' ||
      this.props.onRowSelected
    ) {
      event.preventDefault();

      if (selectable) {
        this.props.onRowSelected(id, event);
      }
    }
  };

  render() {
    let columnsNumber = 0;
    const { rows, headers, noRows, actionCells } = this.props;
    if (headers.length > 0) {
      // Get columns from headers
      columnsNumber = this.props.headers.length;
    } else {
      // If no headers, from row items number
      if (rows.length > 0) {
        columnsNumber = this.props.rows[0].length;
      }
    }

    let cols = [];
    if (columnsNumber > 0) {
      for (let i = 0; i < columnsNumber; i++) {
        cols.push(<col key={i + 1} />);
      }
    }

    const emptyRows = !noRows ? (
      <tr>
        <td className="td-empty-table" colSpan={this.props.headers.length}>
          {this.props.emptyMessage}
        </td>
      </tr>
    ) : null;
    return (
      <React.Fragment>
        {!this.props.newView && (
          <Table
            className={
              'simple-table' +
              (this.props.className !== undefined
                ? ' ' + this.props.className
                : '') +
              (this.props.darkMode ? ' dark-mode' : '') +
              (this.props.stickyHeader ? ' sticky-header' : '')
            }
          >
            {cols.length > 0 && <colgroup>{cols}</colgroup>}
            {this.props.headers.length > 0 && (
              <thead>
                <tr>
                  {this.props.headers.map((element, index) => {
                    if (typeof element === 'string') {
                      return (
                        <th key={index}>
                          <span>{element}</span>
                        </th>
                      );
                    } else {
                      return <th key={index}>{element}</th>;
                    }
                  })}
                </tr>
              </thead>
            )}
            {!this.props.headersOnly && (
              <tbody>
                {this.props.rows.length === 0
                  ? emptyRows
                  : this.props.rows.map((row, index, array) => {
                      if (!Array.isArray(row)) {
                        return (
                          <tr
                            key={row.id}
                            className={this.createRowClassName(
                              row,
                              index,
                              array
                            )}
                            onClick={
                              !actionCells
                                ? this.handleRowClick.bind(
                                    this,
                                    row.id,
                                    this.props.onRowSelected !== undefined &&
                                      (row.selectable === undefined ||
                                        row.selectable)
                                  )
                                : null
                            }
                          >
                            {row.data.map((element, columnIndex) => {
                              return (
                                <td
                                  className={
                                    row.className !== undefined
                                      ? row.className
                                      : null
                                  }
                                  key={columnIndex}
                                  onClick={
                                    actionCells &&
                                    columnIndex < row.data.length - actionCells
                                      ? this.handleRowClick.bind(
                                          this,
                                          row.id,
                                          this.props.onRowSelected !==
                                            undefined &&
                                            (row.selectable === undefined ||
                                              row.selectable)
                                        )
                                      : null
                                  }
                                >
                                  {this.buildElement(row.id, element)}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      } else {
                        return (
                          <tr
                            key={index}
                            className={this.createRowClassName(
                              null,
                              index,
                              array
                            )}
                            onClick={
                              !actionCells
                                ? this.handleRowClick.bind(
                                    this,
                                    index,
                                    this.props.onRowSelected !== undefined
                                  )
                                : null
                            }
                          >
                            {row.map((element, columnIndex) => {
                              return (
                                <td
                                  key={columnIndex}
                                  onClick={
                                    actionCells &&
                                    columnIndex < row.length - actionCells
                                      ? this.handleRowClick.bind(
                                          this,
                                          index,
                                          this.props.onRowSelected !== undefined
                                        )
                                      : null
                                  }
                                >
                                  {this.buildElement(index, element)}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      }
                    })}
              </tbody>
            )}
          </Table>
        )}
        {this.props.newView && (
          <div
            className={
              'basic-table' +
              (this.props.className !== undefined
                ? ' ' + this.props.className
                : '') +
              (this.props.darkMode ? ' dark-mode' : '')
            }
          >
            <BasicTable
              rowsData={rows}
              headersData={headers}
              isSelectable={this.props.onRowSelected !== undefined}
              handleRowClick={this.handleRowClick.bind(this)}
            />
            {rows.length === 0 && (
              <div className="empty-table-message">
                {this.props.emptyMessage}
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }

  createRowClassName = (row = null, index, rows) => {
    let className = [];
    if (this.props.onRowSelected !== undefined) {
      if (row === null) {
        className.push('selectable');
      } else if (row.selectable === undefined || row.selectable) {
        className.push('selectable');
      }
    }

    if (index === rows.length - 1 && this.props.showBottomLine) {
      className.push('last-row');
    }
    if (this.props.onGenerateLink) {
      className.push('link-tab');
    }

    return className.length === 0 ? null : className.join(' ');
  };
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(SimpleTable);
