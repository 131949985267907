import React from 'react';
import { connect } from 'react-redux';
import FormModal from 'components/shared/modal/FormModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import { getDarkMode } from 'selectors/theme';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getRfiResponsesViewModalShow,
  getRfiForm,
  getRfiQuestions,
} from './store/selectors';

import './RfiResponseModal.css';
import './RfiActions.css';
import { setRfiResponsesViewModalShow } from './store/actions';
import RfiQuestions from './RfiQuestions';

const RfiResponsesViewModal = (props) => {
  const {
    rfiResponsesViewModalShow,
    rfi,
    currentUser,
    contribType,
    gcAcceleration,
    project,
    dispatch,
    directory,
  } = props;

  const handleClose = () => {
    dispatch(setRfiResponsesViewModalShow(false));
  };

  return (
    <FormModal
      show={rfiResponsesViewModalShow}
      subTitle={`RFI ${rfi ? rfi.number : ''} ${
        rfi ? rfi.title : ''
      } Questions and responses`}
      className="rfi-response-modal"
      onClose={handleClose}
    >
      <div className="rfi-response-modal-response-container">
        <RfiQuestions
          currentUser={currentUser}
          contribType={contribType}
          project={project}
          directory={directory}
          currentRfi={rfi}
          readOnly={true}
          modalReadOnly={true}
          gcAcceleration={gcAcceleration}
          showResponses={true}
          questionsReadOnly={true}
        />
        <div className="rfi-response-actions">
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.WHITE}
            label="Cancel"
            onClick={handleClose}
          />
        </div>
      </div>
    </FormModal>
  );
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
    rfiResponsesViewModalShow: getRfiResponsesViewModalShow(state),
    form: getRfiForm(state),
    questions: getRfiQuestions(state),
    project: getCurrentProject(state),
  };
})(RfiResponsesViewModal);
