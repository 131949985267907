import React from 'react';
import { connect } from 'react-redux';
import { Chip as ChipComponent } from '@mui/material';
import { getDarkMode } from 'selectors/theme';
import cancelSvg from 'images/cancel.svg';
import cancelDarkSvg from 'images/cancel-dark.svg';
import colors from 'styles/colors.js';

const Chip = (props) => {
  const { darkMode, label, onDelete, onClick, size, color, icon } = props;

  const sx = () => {
    if (typeof onClick === 'undefined') return;

    return {
      '&.MuiChip-root': {
        '&.MuiChip-filled': {
          '&:hover': {
            backgroundColor:
              colors[
                !darkMode ? 'dark-mode-light-white' : 'dark-mode-medium-gray'
              ],
          },
          '&:active': {
            boxShadow: 'none',
          },
          '.MuiTouchRipple-root': {
            display: 'none',
          },
        },
      },
    };
  };

  return (
    <ChipComponent
      className="chip"
      variant="filled"
      size={size}
      label={label}
      onDelete={onDelete}
      onClick={onClick}
      sx={sx()}
      color={color || 'info'}
      icon={icon}
      deleteIcon={
        <img
          src={darkMode ? cancelDarkSvg : cancelSvg}
          style={{ width: 12, height: 12, paddingRight: 4 }}
          alt="Remove"
        />
      }
    />
  );
};

export default connect((state) => ({ darkMode: getDarkMode(state) }))(Chip);
