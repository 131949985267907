import { createSelector } from 'reselect';
import { THEMES } from 'domain/theme.js';

export const getDarkMode = createSelector(
  (state) => state.theme.theme === THEMES.DARK,
  (errors) => {
    return errors;
  }
);

export const getChatOpen = createSelector(
  (state) => state.theme.chatOpen !== false,
  (errors) => {
    return errors;
  }
);
