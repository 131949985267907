import {
  SET_DRAFT,
  UPDATE_FORM,
  SHOW_SIMILAR_COMPANIES_MODAL,
  SHOW_REGISTERED_MODAL,
  SHOW_VERIFYING_MODAL,
} from './action-types';

export const initialState = {
  form: {
    company: {
      name: null,
      street_address_1: null,
      street_address_2: null,
      city: null,
      state: null,
      zip: null,
      phone_number: null,
      fax_number: null,
      logo: null,
      type: null,
      license_number: null,
      trade_codes: [],
      unions: [],
      other_union: null,
    },
    user: {
      email: null,
      first_name: null,
      last_name: null,
      phone_number: null,
      title: null,
      role_id: null,
    },
    primary_contact: {
      same_user: true,
      email: null,
      first_name: null,
      last_name: null,
      phone_number: null,
      title: null,
      role_id: null,
    },
  },
  showSimilarCompaniesModal: false,
  showRegisteredModal: false,
  showVerifyingModal: false,
};

function makeForm(draft, currentUser) {
  return {
    company: {
      ...initialState.form.company,
      ...draft.company,
      trade_codes: JSON.parse(
        (draft.company && draft.company.trade_codes) || '[]'
      ),
      unions: JSON.parse((draft.company && draft.company.unions) || '[]'),
    },
    user: {
      ...initialState.form.user,
      email: currentUser.email,
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      ...draft.user,
    },
    primary_contact: {
      ...initialState.form.primary_contact,
      ...draft.primary_contact,
    },
  };
}

export default function registrationUiReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DRAFT:
      return {
        ...state,
        form: makeForm(action.payload.draft || {}, action.payload.currentUser),
      };

    case UPDATE_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.field]: action.payload.value,
        },
      };

    case SHOW_SIMILAR_COMPANIES_MODAL:
      return {
        ...state,
        showSimilarCompaniesModal: action.payload,
      };

    case SHOW_REGISTERED_MODAL:
      return {
        ...state,
        showRegisteredModal: action.payload,
      };

    case SHOW_VERIFYING_MODAL:
      return {
        ...state,
        showVerifyingModal: action.payload,
      };

    default:
      return state;
  }
}
