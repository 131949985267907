import React from 'react';
import { connect } from 'react-redux';

import FormModal from 'components/shared/modal/FormModal';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import Input from 'components/shared/input/Input';
import UnitsInput from 'components/shared/input/UnitsInput';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import Selector from 'components/shared/selector/Selector';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

import { getProjectSites } from 'components/admin/projects/details/project-site-area/store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getEditSiteAreaModal,
  getShowAddProjectSiteModal,
} from './store/selectors';

import useAddProjectSiteModal from './AddProjectSiteModal.hook';

import './AddProjectSiteArea.css';

const AddProjectAreaModal = (props) => {
  const { show, edit } = props;
  const {
    siteOptions,
    areaOptions,
    invalidForm,
    form,
    handleFormChange,
    handleClose,
    handleSubmit,
  } = useAddProjectSiteModal(props);

  const InputEditLocation = (
    <FormControlBlock
      hideBottomLabel
      className="input-schedule"
      label={form.siteId && 'Location Name'}
      control={
        <Selector
          className="select-dropdown"
          placeholder={'Location Name'}
          value={form.siteId}
          onChange={(option) =>
            handleFormChange({ siteId: option ? option.value : option })
          }
          valid={!(form.errors && form.errors.siteId)}
          options={siteOptions}
        />
      }
    />
  );

  const InputName = (
    <FormControlBlock
      hideBottomLabel
      className="input-name"
      label={form.name && `${edit ? 'Modified' : 'Location'} Name`}
      control={
        <Input
          type={'text'}
          placeholder={`${edit ? 'Modified' : 'Location'} Name`}
          value={form.name}
          valid={!(form.errors && form.errors.name)}
          onChange={(name) => handleFormChange({ name })}
        />
      }
    />
  );

  const InputNumber = (
    <FormControlBlock
      hideBottomLabel
      className="input-number"
      label={form.number && `${edit ? 'Modified' : 'Location'} Number`}
      control={
        <UnitsInput
          placeholder={`${edit ? 'Modified' : 'Location'} Number`}
          value={form.number}
          valid={!(form.errors && form.errors.number)}
          unit="#"
          unitPosition={'start'}
          onChange={(number) => handleFormChange({ number })}
        />
      }
    />
  );

  const InputDuplicatedAreas = (
    <FormControlBlock
      hideBottomLabel
      className="input-areas"
      label={
        form.duplicateAreas &&
        form.duplicateAreas.length > 0 &&
        'Duplicate Areas'
      }
      control={
        <MultipleDropdown
          options={areaOptions}
          currentValues={form.duplicateAreas}
          placeholder="Duplicate Areas"
          onChange={(duplicateAreas) => handleFormChange({ duplicateAreas })}
        />
      }
    />
  );

  const InputDelete = (
    <LabeledCheckBox
      label={'Delete Location'}
      checked={form.delete}
      shape={'round'}
      onChange={(d) => handleFormChange({ delete: d })}
    />
  );

  return (
    <FormModal
      className="add-project-site"
      show={show}
      subTitle={`${edit ? 'Edit' : 'Add'} Location`}
      onClose={handleClose}
    >
      {edit && <div className="block-container">{InputEditLocation}</div>}
      <div className="block-container">
        {InputName}
        {InputNumber}
        {edit && <span className="or-span">or</span>}
        {edit && InputDelete}
      </div>
      {!edit && <div className="block-container">{InputDuplicatedAreas}</div>}
      {invalidForm && (
        <span className="error-message">
          There are some missing fields, please review.
        </span>
      )}
      <div className="button-container">
        <Button
          id="cancel-site"
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.WHITE}
          label="Cancel"
          onClick={() => handleClose(false)}
        />
        <Button
          id="add-site"
          label={form.delete ? 'Delete' : 'Save'}
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          onClick={handleSubmit}
        />
      </div>
    </FormModal>
  );
};

export default connect((state) => ({
  currentProject: getCurrentProject(state),
  projectSites: getProjectSites(state),
  show: getShowAddProjectSiteModal(state),
  edit: getEditSiteAreaModal(state),
}))(AddProjectAreaModal);
