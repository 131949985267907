import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import './ReadOnlyInput.css';

class ReadOnlyInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.any,
  };

  static defaultProps = {
    className: null,
    value: '',
  };

  render() {
    const { className, value, darkMode } = this.props;

    return (
      <span
        className={`readonly-input form-control input-area ${className || ''} ${
          darkMode ? 'dark-mode' : ''
        }`}
      >
        {value}
      </span>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(ReadOnlyInput);
