import React from 'react';
import PropTypes from 'prop-types';
import Number from 'components/shared/text/Number';
import Indicator from './Indicator';

const PaidIndicator = (props) => {
  const { className, name, tooltipPosition } = props;
  let { contract, paid } = props;
  contract = parseFloat(contract || 0, 10);
  paid = parseFloat(paid || 0, 10);

  const getValue = (value) => <Number value={value || 0} format="$0,0" />;

  const getBalance = () => {
    return getValue(contract - paid);
  };

  const getComplete = () => {
    let percentage = contract > 0 ? (paid * 100) / contract : 0;
    percentage =
      percentage > 1
        ? (percentage = Math.round(percentage))
        : percentage > 0
        ? percentage.toFixed(1)
        : 0;
    return `${percentage}% of contract complete`;
  };

  const getTooltipContent = () => {
    return (
      <React.Fragment>
        <p className="indicator-tooltip-name">{name}</p>
        <span className="indicator-tooltip-current-value">
          {'Current '}
          {getValue(paid)}
        </span>
        <p className="indicator-tooltip-field">
          <span className="indicator-tooltip-field-label">Balance</span>
          <span className="indicator-tooltip-field-value">{getBalance()}</span>
        </p>
        <p className="indicator-tooltip-field">{getComplete()}</p>
      </React.Fragment>
    );
  };

  return (
    <Indicator
      className={className}
      name={name}
      type="currency"
      current={paid}
      tooltipContent={getTooltipContent()}
      tooltipPosition={tooltipPosition}
    />
  );
};

PaidIndicator.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  contract: PropTypes.any,
  paid: PropTypes.any,
};

PaidIndicator.defaultTypes = {
  className: '',
  contract: null,
  paid: null,
  tooltipContent: null,
  tooltipPosition: 'left',
};

export default PaidIndicator;
