import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './FieldWorkOrderDetails.css';
import Date from 'components/shared/text/Date';
import FieldWorkOrderGCForm from 'components/admin/projects/details/project-daily-report-log/FieldWorkOrderGCForm';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import ReadOnlyInput from 'components/shared/input/ReadOnlyInput';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';

class FieldWorkOrderDetails extends Component {
  static propTypes = {
    fieldWorkOrder: PropTypes.shape({}),
    fieldWorkOrderForm: PropTypes.shape({}),
    handleAttributeChange: PropTypes.func.isRequired,
    fieldWorkOrderEdit: PropTypes.bool.isRequired,
    showErrorMessage: PropTypes.bool.isRequired,
  };

  status = {
    pending: 'Pending',
    sub_approved: 'Sub-Approved',
    gc_approved: 'GC-Approved',
    sub_denied: 'Sub-Denied',
    gc_denied: 'GC-Denied',
  };

  render() {
    const {
      fieldWorkOrder,
      fieldWorkOrderForm,
      handleAttributeChange,
      fieldWorkOrderEdit,
      showErrorMessage,
    } = this.props;

    const tasks = [
      ...(fieldWorkOrder.preset_tasks || []).map((task) => task.label),
      fieldWorkOrder.preset_task_other,
    ].filter((task) => task);

    return (
      <div className="field-work-order-details">
        <div className="form-block">
          <FormControlBlock
            label="EWO #"
            control={<ReadOnlyInput value={fieldWorkOrder.number} />}
          />
        </div>
        {tasks.length > 0 && (
          <div className="form-block">
            <FormControlBlock
              label="Tasks to be performed"
              control={
                <ul className="form-items-list small-regular">
                  {tasks.map((task, index) => (
                    <li key={index}>{task}</li>
                  ))}
                </ul>
              }
            />
          </div>
        )}
        <div className="form-block">
          <FormControlBlock
            label="Work Required"
            control={
              <TextareaAutosizeWrapper
                initialValue={fieldWorkOrder.work_performed}
                disabled={true}
              />
            }
          />
        </div>
        {!!fieldWorkOrder.comments && (
          <FormControlBlock
            label="Comments"
            control={
              <TextareaAutosizeWrapper
                initialValue={fieldWorkOrder.comments}
                disabled={true}
              />
            }
          />
        )}
        <div className="form-block">
          <FormControlBlock
            label="Start date"
            control={
              <ReadOnlyInput
                value={
                  !!fieldWorkOrder.start_date ? (
                    <Date
                      value={fieldWorkOrder.start_date}
                      format="MM/DD/YYYY"
                    />
                  ) : (
                    'TBD'
                  )
                }
              />
            }
          />
          <FormControlBlock
            label="Completion Date"
            control={
              <ReadOnlyInput
                value={
                  !!fieldWorkOrder.completion_date ? (
                    <Date
                      className="fwo-date-field"
                      value={fieldWorkOrder.completion_date}
                      format="MM/DD/YYYY"
                    />
                  ) : (
                    'TBD'
                  )
                }
              />
            }
          />
        </div>
        <div className="form-block">
          <FormControlBlock
            label="Cost Impact"
            control={
              <ReadOnlyInput
                value={fieldWorkOrder.cost_impact ? 'Yes' : 'No'}
              />
            }
          />
          {fieldWorkOrder.cost && (
            <FormControlBlock
              label="Cost"
              control={<ReadOnlyInput value={fieldWorkOrder.cost} />}
            />
          )}
        </div>
        <div className="form-block">
          <FormControlBlock
            label="Delay"
            control={
              <ReadOnlyInput value={fieldWorkOrder.delay ? 'Yes' : 'No'} />
            }
          />
          {fieldWorkOrder.delay && (
            <FormControlBlock
              label="Delay Reason"
              control={
                <TextareaAutosizeWrapper
                  initialValue={fieldWorkOrder.delay_reason}
                  disabled={true}
                />
              }
            />
          )}
        </div>
        <div className="form-block">
          <FormControlBlock
            label="Labor"
            control={
              <TextareaAutosizeWrapper
                initialValue={fieldWorkOrder.labor}
                disabled={true}
              />
            }
          />
        </div>
        {!!fieldWorkOrder.materials && (
          <div className="form-block">
            <FormControlBlock
              label="Materials"
              control={
                <TextareaAutosizeWrapper
                  initialValue={fieldWorkOrder.materials}
                  disabled={true}
                />
              }
            />
          </div>
        )}
        {!fieldWorkOrderEdit ? (
          <div className="form-block">
            <FormControlBlock
              label="Status"
              control={
                <ReadOnlyInput value={this.status[fieldWorkOrder.status]} />
              }
            />
            {(fieldWorkOrder.status === 'sub_denied' ||
              fieldWorkOrder.status === 'gc_denied') && (
              <FormControlBlock
                label="Denied reason"
                control={
                  <TextareaAutosizeWrapper
                    initialValue={fieldWorkOrder.denied_reason}
                    disabled={true}
                  />
                }
              />
            )}
          </div>
        ) : (
          <FieldWorkOrderGCForm
            fieldWorkOrderForm={fieldWorkOrderForm}
            handleAttributeChange={handleAttributeChange}
            showErrorMessage={showErrorMessage}
          />
        )}
      </div>
    );
  }
}

export default FieldWorkOrderDetails;
