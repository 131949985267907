import {
  WORK_ORDERS_WO_ADD,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_ADD,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_CURRENT_SET,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_CANCEL,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_INVALID,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_SUCCESS,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_VALUE_CHANGE,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_QUESTION_ANSWERED,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_REMOVE,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_SELECT,
  WORK_ORDERS_CREATION_UNION_TRADES_SELECT,
  WORK_ORDERS_CREATION_UNION_TRADES_CURRENT_SET,
} from '../../../../actions/admin/work-orders/action-types';

const initialState = {
  newSubContractor: null,
  selectedSubContractorsId: [],
  selectedUnionTradesId: [],
};

function createEmptyForm() {
  return {
    subContractorName: {
      value: '',
      valid: true,
    },
    phoneNumber: {
      value: '',
      valid: true,
    },
    streetAddress: {
      value: '',
      valid: true,
    },
    unitNumberFloor: {
      value: '',
      valid: true,
    },
    city: {
      value: '',
      valid: true,
    },
    state: {
      value: '',
      valid: true,
    },
    zipCode: {
      value: '',
      valid: true,
    },
    contactFirstName: {
      value: '',
      valid: true,
    },
    contactLastName: {
      value: '',
      valid: true,
    },
    email: {
      value: '',
      valid: true,
    },
  };
}

export default function subContractorsUnionTradesDataReducer(
  state = initialState,
  action
) {
  let newSubContractor;

  switch (action.type) {
    case WORK_ORDERS_CREATION_SUB_CONTRACTORS_CURRENT_SET:
      return {
        ...state,
        selectedSubContractorsId: action.payload,
      };

    case WORK_ORDERS_CREATION_SUB_CONTRACTORS_SELECT:
      return {
        ...state,
        selectedSubContractorsId: state.selectedSubContractorsId.concat([
          action.payload,
        ]),
      };

    case WORK_ORDERS_CREATION_SUB_CONTRACTORS_ADD:
      return {
        ...state,
        newSubContractor: createEmptyForm(),
      };

    case WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_CANCEL:
      return {
        ...state,
        newSubContractor: null,
      };

    case WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_VALUE_CHANGE:
      newSubContractor = {
        ...state.newSubContractor,
      };
      newSubContractor[action.payload.attribute] = {
        value: action.payload.value,
        valid: true,
      };

      return {
        ...state,
        newSubContractor: newSubContractor,
      };

    case WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_INVALID:
      newSubContractor = {
        ...state.newSubContractor,
      };

      action.payload.fields.forEach((field) => {
        newSubContractor[field] = {
          ...state.newSubContractor[field],
          valid: false,
        };
      });

      return {
        ...state,
        newSubContractor: newSubContractor,
      };

    case WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_SUCCESS:
      return {
        ...state,
        selectedSubContractorsId: state.selectedSubContractorsId.concat(
          action.payload.id
        ),
      };

    case WORK_ORDERS_WO_ADD:
      return {
        ...state,
        newSubContractor: null,
        selectedSubContractorsId: [],
      };

    case WORK_ORDERS_CREATION_SUB_CONTRACTORS_REMOVE:
      return {
        ...state,
        selectedSubContractorsId: state.selectedSubContractorsId.filter(
          (element) => element !== action.payload
        ),
      };

    case WORK_ORDERS_CREATION_SUB_CONTRACTORS_QUESTION_ANSWERED:
      return {
        ...state,
        selectedSubContractorsId: action.payload.clearSelected
          ? []
          : state.selectedSubContractorsId,
      };

    case WORK_ORDERS_CREATION_UNION_TRADES_SELECT:
      return {
        ...state,
        selectedUnionTradesId: action.payload.newSelectedUnionTradesId,
      };

    case WORK_ORDERS_CREATION_UNION_TRADES_CURRENT_SET:
      return {
        ...state,
        selectedUnionTradesId: action.payload,
      };

    default:
      return state;
  }
}
