import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Grid from '@mui/material/Grid';

import { getCurrentProject } from 'components/admin/projects/details/store/selectors';

import AddAlbumButton from './AddAlbumButton';
import UploadPhotosButton from './UploadPhotosButton';
import ProjectPhotosAlbumFormModal from './ProjectPhotosAlbumFormModal';
import ProjectPhotosFiltersModal from './ProjectPhotosFiltersModal';
import ProjectPhotosFormModal from './ProjectPhotosFormModal';
import ProjectPhotosUploadModal from './ProjectPhotosUploadModal';
import ProjectPhotosDetailModal from './ProjectPhotosDetailModal';
import ProjectPhotosBreadCrumbs from './ProjectPhotosBreadCrumbs';
import ProjectPhotosHeader from './ProjectPhotosHeader';

import {
  getProjectPhotosCurrentAlbum,
  getProjectPhotosFilters,
} from './store/selectors';
import './ProjectPhotos.css';
import useProjectPhotos from './ProjectPhotos.hook';
import ProjectPhotosContent from './ProjectPhotosContent';
import ProjectPhotosMoveToModal from './ProjectPhotosMoveToModal';
import ProjectPhotosEmailModal from './ProjectPhotosEmailModal';
import ProjectPhotosDeleteModal from './ProjectPhotosDeleteModal';

const ProjectPhotos = (props) => {
  useProjectPhotos(props);
  const { renderHeaders, currentAlbum } = props;

  const renderButtons = () => (
    <Grid container spacing={2}>
      <Grid item>{<AddAlbumButton />}</Grid>
      {!!currentAlbum && <Grid item>{<UploadPhotosButton />}</Grid>}
    </Grid>
  );

  return (
    <Fragment>
      <div className={'content-container project-photos'}>
        {renderHeaders(renderButtons())}
        <div className={'main-content'}>
          <ProjectPhotosBreadCrumbs />
          <ProjectPhotosHeader />
          <ProjectPhotosContent />
        </div>
      </div>

      <ProjectPhotosFiltersModal />
      <ProjectPhotosAlbumFormModal />
      <ProjectPhotosFormModal />
      <ProjectPhotosUploadModal />
      <ProjectPhotosDetailModal />
      <ProjectPhotosMoveToModal />
      <ProjectPhotosEmailModal />
      <ProjectPhotosDeleteModal />
    </Fragment>
  );
};

export default withRouter(
  connect((state) => ({
    currentAlbum: getProjectPhotosCurrentAlbum(state),
    currentProject: getCurrentProject(state),
    filters: getProjectPhotosFilters(state),
  }))(ProjectPhotos)
);
