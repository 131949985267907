import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import deleteCross from 'images/deleteCross.svg';
import deleteCrossDM from 'images/deleteCrossDarkMode.svg';
import { getDarkMode } from 'selectors/theme';
import './InputMaxLength.css';

class InputMaxLength extends Component {
  render() {
    const { label, value, onChange, onClear, maxLength, darkMode } = this.props;
    return (
      <FormControlBlock
        label={value ? label : ''}
        control={
          <div>
            <div className="input-with-icon-section">
              <TextareaAutosizeWrapper
                maxLength={maxLength}
                placeholder={label}
                value={value}
                onChange={onChange}
              />
              <img
                className="icon"
                src={darkMode ? deleteCrossDM : deleteCross}
                alt="clear"
                onClick={onClear}
              />
            </div>
            <span className="numbers-of-letters">
              {`${value ? value.length : 0}/${maxLength}`}
            </span>
          </div>
        }
      />
    );
  }
}

InputMaxLength.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  maxLength: PropTypes.number,
};

InputMaxLength.defaultProps = {
  label: '',
  value: '',
  onChange: () => null,
  onClear: () => null,
  maxLength: 30,
};

export default connect(
  (state) => ({
    darkMode: getDarkMode(state),
  }),
  null
)(InputMaxLength);
