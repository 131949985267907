import axios from 'axios';
import BaseApi from './base-api';

class CsiCodesApi extends BaseApi {
  getCsiCodes() {
    return axios
      .get(`${this.baseUrl}/csi_codes`)
      .then((response) => {
        return {
          ok: true,
          csiCodes: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getPresetTasks(id) {
    return axios
      .get(`${this.baseUrl}/csi_codes/${id}/preset_tasks`)
      .then((response) => {
        return {
          ok: true,
          presetTasks: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getSupervisorTasks() {
    return axios
      .get(`${this.baseUrl}/supervisor_tasks`)
      .then((response) => {
        return {
          ok: true,
          presetTasks: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const csiCodesApi = new CsiCodesApi();
