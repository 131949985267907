import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import './Loader.css';
import loaderSvg from './loader.svg';
import loaderSvgDark from './loaderDark.svg';

class Loader extends Component {
  static propTypes = {
    loadImage: PropTypes.bool,
    loggedIn: PropTypes.bool,
  };

  static defaultProps = {
    loadImage: true,
  };

  render() {
    const { darkMode, loadImage } = this.props;
    return (
      !loadImage && (
        <div className="loader-container">
          <img src={darkMode ? loaderSvgDark : loaderSvg} alt="loader" />
        </div>
      )
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
    loggedIn: state.auth.loggedIn,
  };
}, null)(Loader);
