import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import FormModal from 'components/shared/modal/FormModal';
import FormControlBlock from 'components/shared/form/FormControlBlock';

import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import Selector from 'components/shared/selector/Selector';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import {
  loadActionItemsCategories,
  setSubmitCorrectionsModalShow,
  createMeetingsCorrection,
  setFormMeetingInstance,
} from './store/actions';
import {
  getProjectNewMeetingsParents,
  getSubmitCorrectionsModalShow,
  getProjectNewMeetingsForm,
  getActionItemsCategories,
  getMeetingsInstanceModal,
  getMeetingsInstanceForm,
} from './store/selectors';
import './EditTypeOrTitleModal.css';
import './CorrectionsSubmitModal.css';
import InputMaxLength from '../../../../shared/input/InputMaxLength';

const MeetingsCorrectionsSubmitModal = (props) => {
  const {
    dispatch,
    project,
    form,
    actionItemsCategories,
    show,
    currentUser,
    showInstanceForm,
    instanceForm,
  } = props;
  const [selectedMeetingItem, setSelectedMeetingItem] = useState({});
  const [remark, setRemark] = useState('');

  useEffect(() => {
    dispatch(loadActionItemsCategories(project.id));
  }, []);
  const handleClose = () => {
    dispatch(setSubmitCorrectionsModalShow(false));
  };

  const handleRemarkChange = (text) => {
    setRemark(text);
  };
  const latestInstanceId = form.latestInstanceId;
  const latestInstance = form.meetingsInstances.filter(
    (inst) => inst.id === latestInstanceId
  );
  let selectorOptions = [];
  if (latestInstance && latestInstance.meetingsActionItems)
    selectorOptions = latestInstance.meetingsActionItems.map((ai) => {
      return {
        value: ai.id,
        label: `${ai.formattedNumber} ${ai.description}`,
      };
    });
  selectorOptions = [
    ...selectorOptions,
    { value: 'info', label: 'info' },
    { value: 'attend', label: 'Attend' },
    { value: 'attach', label: 'Attach' },
  ];
  const categoriesInOptions = actionItemsCategories.map((c) => {
    return { value: c.id, label: `Category: ${c.name}` };
  });
  selectorOptions = [...selectorOptions, ...categoriesInOptions];

  const handleSubmit = (submitAndNew) => {
    const correction = {
      correctionRemark: remark,
      meetingsParentId: form.id,
      meetingsInstanceId: latestInstanceId,
      createdById: currentUser.id,
      submittedById: currentUser.id,
      status: 'pending',
      meetingCorrectionNo: selectedMeetingItem.label,
    };
    if (showInstanceForm) {
      const oldCorrections = instanceForm.meetingsCorrections;
      dispatch(
        setFormMeetingInstance({
          ...showInstanceForm,
          meetingsCorrections: [...oldCorrections, correction],
        })
      );
      if (submitAndNew) {
        setRemark('');
      }
      if (!submitAndNew) dispatch(setSubmitCorrectionsModalShow(false));
      return;
    }
    dispatch(createMeetingsCorrection(project.id, correction));
    if (submitAndNew) {
      setRemark('');
    }
    if (!submitAndNew) dispatch(setSubmitCorrectionsModalShow(false));
  };

  return (
    <>
      <FormModal
        className={`meeting-modal editing`}
        show={show}
        onClose={() => handleClose()}
        subTitle={'Submit Meeting Correction'}
      >
        <div className="meeting-title-and-number">{`${form.title} - Meeting ${
          latestInstance[0].isAgenda ? 'Agenda' : 'Minutes'
        } #${latestInstance[0].formattedNumber}`}</div>
        <div className="explanation">
          Submit only one correction issue at the time for each topic or Item
          Number. If there are multiple errors or omissions for any portion of
          these, please submit a separate record.
        </div>
        <FormControlBlock
          label="Meeting Item"
          position="right"
          readonly={false}
          control={
            <Selector
              className="select-dropdown"
              placeholder={'Meeting Item'}
              value={selectedMeetingItem}
              onChange={(option) => setSelectedMeetingItem(option)}
              options={selectorOptions}
              clearable
            />
          }
        ></FormControlBlock>
        <FormControlBlock
          label="Meeting Type"
          position="right"
          readonly={false}
          control={
            <InputMaxLength
              placeholder={'Correction Remark'}
              value={remark}
              onChange={handleRemarkChange}
              options={selectorOptions}
              maxLength={250}
            />
          }
        ></FormControlBlock>
        <div className="button-actions-three">
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.WHITE}
            label="Cancel"
            onClick={handleClose}
          />
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label={'Submit'}
            onClick={() => handleSubmit(false)}
          />
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label={'Submit & New'}
            onClick={() => handleSubmit(true)}
          />
        </div>
      </FormModal>
    </>
  );
};

export default connect((state) => ({
  project: getCurrentProject(state),
  meetingsParents: getProjectNewMeetingsParents(state),
  show: getSubmitCorrectionsModalShow(state),
  form: getProjectNewMeetingsForm(state),
  actionItemsCategories: getActionItemsCategories(state),
  currentUser: getCurrentUser(state),
  showInstanceForm: getMeetingsInstanceModal(state),
  instanceForm: getMeetingsInstanceForm(state),
}))(MeetingsCorrectionsSubmitModal);
