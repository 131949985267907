import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { countdown } from 'services/utils/date-util';
import Number from 'components/shared/text/Number';
import Date, { DATE_FORMAT } from 'components/shared/text/Date';
import TooltipControl from 'components/shared/tooltip/TooltipControl';
import { getDarkMode } from 'selectors/theme';
import './Indicator.css';
import IncTendencyVector from './images/inc-tendency.svg';
import DecTendencyVector from './images/dec-tendency.svg';
import IndicatorVector from './images/indicator.svg';
import IndicatorDarkVector from './images/indicator-dark.svg';
import SchedulePositiveVector from './images/schedule-positive.svg';
import ScheduleNegativeVector from './images/schedule-negative.svg';

const Indicator = (props) => {
  const {
    className,
    name,
    type,
    tooltipContent,
    tooltipPosition,
    darkMode,
  } = props;

  const castValues = () => ({
    current: castValue(props.current),
    original: castValue(props.original),
    previous: castValue(props.previous),
  });

  const castValue = (value) => {
    switch (type) {
      case 'currency':
        return parseFloat(value || 0, 10);
      default:
        return value;
    }
  };

  const { current, previous, original } = castValues();

  const getValue = (source, format = null) => {
    switch (type) {
      case 'date':
        return current ? (
          <Date value={source} format={format || DATE_FORMAT.TEXT} />
        ) : (
          '--'
        );
      case 'currency':
        return <Number value={source || 0} format={format || '$0,0'} />;
      default:
        return 0;
    }
  };

  const getTooltipValue = (source) => {
    switch (type) {
      case 'date':
        return getValue(source, DATE_FORMAT.TEXT_LONG);
      default:
        return getValue(source);
    }
  };

  const getVariance = () => {
    if (!original) return '--';

    switch (type) {
      case 'date':
        return getDateVariance();
      case 'currency':
        return getCurrencyVariance();
      default:
        return '--';
    }
  };

  const getDateVariance = () => {
    if (current === original) return '--';

    const diff = countdown(current, original, {
      camelCase: true,
      fullPath: false,
    });
    return `${current > original ? '+ ' : '- '}${diff}`;
  };

  const getCurrencyVariance = () => {
    const diff = current - original;
    return (
      <React.Fragment>
        {diff > 0 ? '+ ' : diff < 0 ? '- ' : ''}
        {getTooltipValue(Math.abs(diff))}
      </React.Fragment>
    );
  };

  const getPreviousChange = () => {
    if (!previous || current === previous)
      return 'No Change since last Project Report';

    switch (type) {
      case 'date':
        return getDatePreviousChange();
      case 'currency':
        return getCurrencyPreviousChange();
      default:
        return '--';
    }
  };

  const getDatePreviousChange = () => {
    const diff = countdown(current, previous, {
      separator: '-',
      pluralize: false,
      fullPath: false,
    });

    return `${diff} ${
      current > previous ? 'increase' : 'decrease'
    } since last Project Report`;
  };

  const getCurrencyPreviousChange = () => {
    const diff = current - previous;
    let percentage = (diff * 100) / previous;
    percentage =
      percentage > 1
        ? (percentage = Math.round(percentage))
        : percentage.toFixed(1);
    percentage = Math.abs(percentage);

    return `${percentage}% ${
      diff > 0 ? 'increase' : 'decrease'
    } since last Project Report`;
  };

  const getTendencyIcon = () => {
    if (!previous || current === previous) return null;

    switch (type) {
      case 'date':
        return current > previous
          ? ScheduleNegativeVector
          : SchedulePositiveVector;
      default:
        return current > previous ? IncTendencyVector : DecTendencyVector;
    }
  };

  const getTooltipValueTitle = () => {
    switch (type) {
      case 'currency':
        return 'Current ';
      default:
        return '';
    }
  };

  const getTooltipIcon = () =>
    getTendencyIcon() || (darkMode ? IndicatorDarkVector : IndicatorVector);

  const getTooltipContent = () => {
    return (
      <React.Fragment>
        <p className="indicator-tooltip-name">{name}</p>
        <span className="indicator-tooltip-current-value">
          {getTooltipValueTitle()}
          {getTooltipValue(current)}
        </span>
        <p className="indicator-tooltip-field">
          <span className="indicator-tooltip-field-label">Original</span>
          <span className="indicator-tooltip-field-value">
            {getTooltipValue(original)}
          </span>
        </p>
        <p className="indicator-tooltip-field">
          <span className="indicator-tooltip-field-label">Variance</span>
          <span className="indicator-tooltip-field-value">{getVariance()}</span>
        </p>
        <p className="indicator-tooltip-field">{getPreviousChange()}</p>
      </React.Fragment>
    );
  };

  const renderTooltip = () => {
    return (
      <TooltipControl
        className="project-dashboard-indicator-tooltip"
        control={
          <div className="indicator-tooltip-icon">
            <img src={getTooltipIcon()} alt="tooltip" />
          </div>
        }
        tooltip={tooltipContent || getTooltipContent()}
        position={tooltipPosition}
        placement="top"
      />
    );
  };

  return (
    <div
      className={classnames(`project-dashboard-indicator ${type}-indicator`, {
        'dark-mode': darkMode,
        [className]: !!className,
      })}
    >
      <div className="indicator-name">{name}</div>
      <div className="indicator-value">
        {getValue(current)}
        {renderTooltip()}
      </div>
    </div>
  );
};

Indicator.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  current: PropTypes.any,
  previous: PropTypes.any,
  original: PropTypes.any,
  tooltipContent: PropTypes.any,
  tooltipPosition: PropTypes.string,
};

Indicator.defaultTypes = {
  className: '',
  previous: null,
  original: null,
  tooltipContent: null,
  tooltipPosition: 'left',
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(Indicator);
