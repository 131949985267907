import { createSelector } from 'reselect';

export const getRfisCount = createSelector(
  (state) => state.rfis.data.rfisCount,
  (rfisCount) => {
    return rfisCount;
  }
);

export const getRfis = createSelector(
  (state) => state.rfis.data.rfis,
  (rfis) => {
    return rfis;
  }
);

export const getCurrentStatus = createSelector(
  (state) => state.rfis.data.currentStatus,
  (currentStatus) => {
    return currentStatus;
  }
);

export const getTotal = createSelector(
  (state) => state.rfis.data.total,
  (total) => {
    return total;
  }
);

export const getCurrentPage = createSelector(
  (state) => state.rfis.data.currentPage,
  (currentPage) => {
    return currentPage;
  }
);

export const getPageSize = createSelector(
  (state) => state.rfis.data.pageSize,
  (pageSize) => {
    return pageSize;
  }
);
