import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Grid } from '@mui/material';
import ReadOnlyInput from 'components/shared/input/ReadOnlyInput';
import ImageDescriptionUpload from 'components/shared/image-upload/ImageDescriptionUpload';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import CharsCounterTextarea from 'components/shared/chars-counter/CharsCounterTextarea';
import UserPermissionsForm from 'components/shared/user-permissions/UserPermissionsForm';
import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
} from 'components/shared/button/Button';

import colors from 'styles/colors';

import { getDarkMode } from 'selectors/theme';
import { getCurrentUser } from 'selectors/authentication';
import { getProjectPhotosForm } from './store/selectors';
import { setProjectPhotosForm } from './store/actions';
import ProjectPhotosFilterTrades from './ProjectPhotosFilterTrades';
import ProjectPhotosFilterSitesAreas from './ProjectPhotosFilterSitesAreas';
import ProjectPhotosFilterTags from './ProjectPhotosFilterTags';
import Chips from '../../../../shared/chip/Chips';

const ProjectPhotosAlbumFormInfo = (props) => {
  const { dispatch, form, currentUser, darkMode } = props;
  const [showUserList, setShowUserList] = useState(false);

  const onFormChange = (f) => {
    dispatch(setProjectPhotosForm(f));
  };

  const onUserDelete = (user) => {
    const users = form.users.filter((u) => u.id !== user.id);
    onFormChange({ users });
  };

  const usersInput = (
    <FormControlBlock
      label={'Users'}
      hideBottomLabel
      control={
        <Grid
          paddingTop={1.25}
          paddingBottom={0.4}
          borderBottom={1}
          borderColor={
            colors[darkMode ? 'dark-mode-light-white' : 'light-mode-dark-black']
          }
        >
          <Chips
            values={[
              {
                id: (form.createdBy || currentUser).id,
                label: (form.createdBy || currentUser).full_name,
                disabled: true,
              },
              ...form.users.map(({ id, full_name: label, disabled }) => ({
                id,
                label,
                disabled,
              })),
            ]}
            spacing={0.4}
            onDelete={onUserDelete}
          />
        </Grid>
      }
    />
  );
  const tagUsersButton = (
    <Button
      key="add-user"
      type={BUTTON_TYPES.LINK}
      label={showUserList ? '' : 'Tag Users'}
      onClick={() => setShowUserList(!showUserList)}
      icon={showUserList ? BUTTON_ICONS.BACK : BUTTON_ICONS.PLUS}
    />
  );
  const userListInput = (
    <UserPermissionsForm
      canChangePermissions
      users={form.users}
      onUsersChange={(users) => onFormChange({ users })}
    />
  );

  if (showUserList) {
    return (
      <Grid container spacing={2}>
        <Grid item children={tagUsersButton} />
        <Grid item xs={12} children={usersInput} />
        <Grid item xs={12} children={userListInput} />
      </Grid>
    );
  }

  const createdByInput = (
    <FormControlBlock
      hideBottomLabel
      label={'Created By'}
      control={
        <ReadOnlyInput value={(form.createdBy || currentUser).full_name} />
      }
    />
  );
  const createdAtInput = (
    <FormControlBlock
      hideBottomLabel
      label={'Date'}
      control={
        <ReadOnlyInput
          value={moment(form.createdAt ? form.createdAt : undefined).format(
            'MM/DD/YYYY'
          )}
        />
      }
    />
  );
  const coverInput = (
    <ImageDescriptionUpload
      dropZoneText={'Upload Album Cover'}
      multiple={false}
      hideDescription
      deletedImages={[]}
      images={form.cover && !form.cover._destroy ? [form.cover] : []}
      onChange={([cover]) => onFormChange({ cover })}
      onDelete={() =>
        onFormChange(
          form.cover && form.cover.id
            ? { cover: { ...form.cover, _destroy: true } }
            : { cover: null }
        )
      }
    />
  );
  const titleInput = (
    <FormControlBlock
      label={form.title ? 'Album Name' : ''}
      hideBottomLabel
      control={
        <CharsCounterTextarea
          limit={50}
          className="title"
          placeholder={'Album Name'}
          value={form.title}
          valid={!(form.errors && form.errors.title)}
          onChange={(title) => onFormChange({ title })}
        />
      }
    />
  );
  const descriptionInput = (
    <FormControlBlock
      label={form.description ? 'Album Description' : ''}
      hideBottomLabel
      control={
        <CharsCounterTextarea
          limit={200}
          className="description"
          placeholder={'Album Description'}
          value={form.description}
          valid={!(form.errors && form.errors.description)}
          onChange={(description) => onFormChange({ description })}
        />
      }
    />
  );
  const tradesInput = (
    <FormControlBlock
      label={form.trades.length > 0 ? 'Trades' : ''}
      hideBottomLabel
      control={
        <ProjectPhotosFilterTrades
          value={form.trades}
          onChange={onFormChange}
        />
      }
    />
  );
  const sitesAreasInput = (
    <ProjectPhotosFilterSitesAreas
      sites={form.sites}
      areas={form.areas}
      onChange={onFormChange}
    />
  );
  const tagsInput = (
    <FormControlBlock
      label={form.tags.length > 0 ? 'Tags' : ''}
      hideBottomLabel
      control={
        <ProjectPhotosFilterTags
          enableAdd
          value={form.tags}
          onChange={onFormChange}
        />
      }
    />
  );

  return (
    <Grid container spacing={1.5}>
      <Grid item xs={5} children={createdByInput} />
      <Grid item xs={5} children={createdAtInput} />

      <Grid item xs={12} marginTop={1} children={coverInput} />
      <Grid item xs={12} children={titleInput} />
      <Grid item xs={12} children={descriptionInput} />
      <Grid item xs={12} children={tradesInput} />
      <Grid item xs={12} children={sitesAreasInput} />
      <Grid item xs={12} children={tagsInput} />
      <Grid item xs={12} children={usersInput} />
      <Grid item xs={12} children={tagUsersButton} />
    </Grid>
  );
};

export default connect((state) => ({
  darkMode: getDarkMode(state),
  form: getProjectPhotosForm(state),
  currentUser: getCurrentUser(state),
}))(ProjectPhotosAlbumFormInfo);
