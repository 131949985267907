import React from 'react';
import { connect } from 'react-redux';

import filterSvg from 'images/filterSvg.svg';
import filterSvgDark from 'images/filterDarkSvg.svg';
import { getDarkMode } from 'selectors/theme';

import SearchBar from 'components/shared/navigation/SearchBar';
import { getProjectPhotosFilters } from './store/selectors';
import {
  setProjectPhotosFilters,
  setProjectPhotosShowFilters,
} from './store/actions';

const ProjectPhotosFilterSearch = (props) => {
  const { dispatch, filters, darkMode } = props;

  const onSearch = () => {
    dispatch(setProjectPhotosShowFilters(true));
  };

  const onSearchTextChange = (search) => {
    dispatch(setProjectPhotosFilters({ search, doSearch: true }));
  };

  return (
    <SearchBar
      searchPlaceHolder="Search"
      onSearchTextChange={onSearchTextChange}
      onSearch={onSearch}
      searchText={filters.search}
      searchIcon={darkMode ? filterSvgDark : filterSvg}
    />
  );
};

export default connect((state) => ({
  filters: getProjectPhotosFilters(state),
  darkMode: getDarkMode(state),
}))(ProjectPhotosFilterSearch);
