import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Input from './Input';

const UnitsInput = (props) => {
  const { autoFocus, onBlur, onFocus, unitPosition, unit, value, type } = props;

  const [focused, setFocused] = useState(autoFocus);

  const handleBlur = () => {
    setFocused(false);
    if (typeof onBlur === 'function') onBlur();
  };

  const handleFocus = () => {
    setFocused(true);
    if (typeof onFocus === 'function') onFocus();
  };

  const getUnitsValue = () => {
    if (!value) return '';
    if (unit.length > 0) {
      if (unitPosition === 'start') return `${unit} ${value}`;
      else return `${value} ${unit}`;
    }
    return value;
  };

  return (
    <Input
      {...props}
      onBlur={handleBlur}
      onFocus={handleFocus}
      value={focused ? value : getUnitsValue()}
      type={focused ? type : 'text'}
    />
  );
};

UnitsInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  theme: PropTypes.string,
  type: PropTypes.string,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  autoFocus: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  unit: PropTypes.string,
  unitPosition: PropTypes.oneOf(['start', 'end']),
};

UnitsInput.defaultProps = {
  required: false,
  type: 'text',
  valid: true,
  value: '',
  autoFocus: false,
  unit: '',
  unitPosition: 'end',
};

export default connect()(UnitsInput);
