import React, { useEffect } from 'react';
import Menu from 'components/shared/menu/Menu';
import { connect } from 'react-redux';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getFieldReportFilters,
  getFieldReportsCreators,
} from './store/selectors';
import {
  loadFieldReportsCreators,
  loadFieldReportsRequest,
} from './store/actions';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import Project from 'domain/project';
import './FieldReportTabMenu.css';

const FieldReportTabMenu = (props) => {
  const {
    dispatch,
    element,
    selected,
    onSelected,
    fieldReportCreators,
    directory,
    currentProject,
    filters,
  } = props;

  useEffect(() => loadDirectory(), []);

  const loadDirectory = () => {
    if (!directory || directory.project_id !== currentProject.id) {
      dispatch(loadDirectoryRequest(currentProject.id));
    }
  };

  const fetchItems = (callback) => {
    if (fieldReportCreators === null)
      dispatch(loadFieldReportsCreators(currentProject.id)).then((response) => {
        if (response.ok && renderItems(response.creators).length > 0)
          callback();
        else handleSelect([]);
      });
    else callback();
  };

  const handleSelect = (values) => {
    const newFilters = { ...filters, createdBy: values, page: 1 };
    dispatch(loadFieldReportsRequest(currentProject.id, newFilters));
    onSelected(element.id);
  };

  const renderItems = (creators = fieldReportCreators) => {
    let res = [];

    if (
      creators &&
      creators.length > 1 &&
      directory &&
      directory.project_contributors
    ) {
      let content = 'View All';
      let onClick = () => handleSelect([]);
      res.push({ content, onClick });

      directory.project_contributors.forEach((c) => {
        if (
          c.users.some((contributor) =>
            creators.some(
              (creator) =>
                creator.id === contributor.id &&
                creator.accountable_id === contributor.accountable_id
            )
          )
        ) {
          content = Project.contributorLabel(c);

          onClick = () => handleSelect(c.users.map((u) => u.id));
          res.push({ content, onClick });
        }
      });
    }

    return res;
  };

  const hasDropdown =
    !directory || !fieldReportCreators || renderItems().length > 0;

  const menuTarget = (
    <div
      className={
        'navigation-item' +
        (selected ? ' selected' : '') +
        (element.class ? ` ${element.class}` : '')
      }
      onClick={!hasDropdown ? () => handleSelect([]) : undefined}
    >
      <span>{element.title}</span>
    </div>
  );

  return (
    <Menu
      className="field-report-menu"
      placement="bottom-start"
      selector={menuTarget}
      items={renderItems}
      arrow={false}
      fetchItems={fetchItems}
    />
  );
};

export default connect((state) => ({
  fieldReportCreators: getFieldReportsCreators(state),
  currentProject: getCurrentProject(state),
  directory: getDirectory(state),
  filters: getFieldReportFilters(state),
}))(FieldReportTabMenu);
