import { WORK_ORDERS_CREATION_CLIENTS_LOAD_SUCCESS } from '../../../../actions/admin/work-orders/action-types';

const initialState = {
  clients: [],
};

export default function clientsDataReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_CREATION_CLIENTS_LOAD_SUCCESS:
      return {
        ...state,
        clients: action.payload,
      };

    default:
      return state;
  }
}
