import { createSelector } from 'reselect';

export const getReports = createSelector(
  (state) => state.reports.ui.reports,
  (reports) => {
    return reports;
  }
);

export const getSelectAllChecked = createSelector(
  (state) => state.reports.ui.selectAllChecked,
  (selectAllChecked) => {
    return selectAllChecked;
  }
);

export const getCurrentPage = createSelector(
  (state) => state.reports.ui.currentPage,
  (currentPage) => {
    return currentPage;
  }
);

export const getReportResults = createSelector(
  (state) => state.reports.data.results,
  (results) => {
    return results;
  }
);

export const getSelectedWorkOrders = createSelector(
  (state) => state.reports.ui.selectedWorkOrders,
  (selectedWorkOrders) => {
    return selectedWorkOrders;
  }
);

export const getStartDate = createSelector(
  (state) => state.reports.ui.startDate,
  (startDate) => {
    return startDate;
  }
);

export const getEndDate = createSelector(
  (state) => state.reports.ui.endDate,
  (endDate) => {
    return endDate;
  }
);

export const getExtraFilterValues = createSelector(
  (state) => state.reports.ui.extraFilterValues,
  (extraFilterValues) => {
    return extraFilterValues;
  }
);

export const getSearching = createSelector(
  (state) => state.reports.ui.searching,
  (searching) => {
    return searching;
  }
);
