import React from 'react';
import Project from 'domain/project';

export class DailyReportUtil {
  static tradeLabel = ({ csi_code: csiCode, company }) => (
    <span>
      {Project.csiCodeLabel(csiCode)} - <b>{company.name}</b>
    </span>
  );
  static findContributor = (directory, id) => {
    if (!directory || !directory.project_contributors || !id) return '';
    return directory.project_contributors.find((pc) => pc.id === id);
  };
}
