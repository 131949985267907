import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './AddlAssigneeForm.css';
import FormModal from 'components/shared/modal/FormModal';
import Selector from 'components/shared/selector/Selector';
import DatePicker from 'components/shared/date-picker/DatePicker';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import { todayValidation } from 'services/utils/date-util';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import User from 'domain/user';

class AddlAssigneeForm extends Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    maxDueDate: PropTypes.string,
    addlAssignees: PropTypes.array.isRequired,
    addlAssignee: PropTypes.shape({}),
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    maxDueDate: null,
    addlAssignee: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      assigneeId: props.addlAssignee.assigneeId || '',
      assigneeLabel: props.addlAssignee.assigneeLabel || '',
      dueDate: props.addlAssignee.dueDate || props.maxDueDate || '',
    };
  }

  filterRecipients = () => {
    const { addlAssignees, addlAssignee, directory } = this.props;
    if (!directory) {
      return [];
    }

    let users = {};
    let contributors = directory.project_contributors;
    contributors = contributors
      .filter((pc) => pc.primary)
      .map((pc) => {
        return pc.users
          .filter(
            (usr) =>
              !users[usr.id] &&
              !addlAssignees.find(
                (addlAssignee) =>
                  !addlAssignee.destroy && addlAssignee.assigneeId === usr.id
              )
          )
          .map((usr) => {
            users[usr.id] = true;
            const usrLabel = User.fullNameWithCompany(usr, pc.company);
            return { value: usr.id, label: usrLabel };
          });
      })
      .reduce((acc, val) => acc.concat(val), []);

    if (addlAssignee.assigneeId) {
      contributors.unshift({
        value: addlAssignee.assigneeId,
        label: addlAssignee.assigneeLabel,
      });
    }

    return contributors;
  };

  handleChange = (attribute, value) => {
    this.setState({ [attribute]: value });
  };

  handleAssigneeChange = (option) => {
    this.handleChange('assigneeId', option.value);
    this.handleChange('assigneeLabel', option.label);
  };

  handleSubmit = () => {
    const { assigneeId, assigneeLabel, dueDate } = this.state;
    this.props.onSubmit({ assigneeId, assigneeLabel, dueDate });
  };

  validDueDate = (date) => {
    const { maxDueDate } = this.props;
    return (
      todayValidation(date) && (!maxDueDate || date.isSameOrBefore(maxDueDate))
    );
  };

  render() {
    const { showModal, onClose } = this.props;
    const { assigneeId, dueDate } = this.state;
    return (
      showModal && (
        <FormModal
          className="addl-assignee-form"
          show={showModal}
          onClose={onClose}
          subTitle="Additional Assignee"
        >
          <div className="form-block">
            <label className="form-block-label">Assignee</label>
            <Selector
              options={this.filterRecipients()}
              placeholder="Contributors..."
              value={assigneeId}
              onChange={this.handleAssigneeChange}
            />
          </div>
          <div className="form-block">
            <label className="form-block-label">Due</label>
            <DatePicker
              isValidDate={this.validDueDate}
              placeholder="Chose Date..."
              value={dueDate}
              onChange={(value) => this.handleChange('dueDate', value)}
            />
          </div>
          <div className="buttons-container">
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              disabled={!assigneeId || !dueDate}
              label="Done"
              onClick={this.handleSubmit}
            />
          </div>
        </FormModal>
      )
    );
  }
}

export default connect((state) => {
  return {
    directory: getDirectory(state),
  };
})(AddlAssigneeForm);
