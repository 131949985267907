import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { useMedia } from 'react-use';
import colors from 'styles/colors';
import { getDarkMode } from 'selectors/theme';
import { getReportMetrics } from './store/selectors';
import './ConstructionComplete.css';

const ConstructionComplete = (props) => {
  const { metrics, darkMode } = props;
  const now = moment();
  const isMedium = useMedia('(max-height: 1000px), (max-width: 1600px)');
  const isSmall = useMedia('(max-height: 800px), (max-width: 1300px)');

  const makeDisabledRows = () =>
    [...Array(5)]
      .map((_, months) => {
        return {
          date: moment(now.format('YYYY-MM-01'))
            .add(-months, 'M')
            .format('YYYY-MM-DD'),
          value: months % 2 ? 15 : 10,
        };
      })
      .reverse();

  const absoluteMonths = (date) => date.month() + date.year() * 12;
  const monthsDiff = (date1, date2) =>
    absoluteMonths(date1) - absoluteMonths(date2);

  const formatDate = (date) =>
    monthsDiff(date, now) !== 0
      ? date.format(isSmall ? 'MMM' : 'MMMM')
      : 'Today';

  let rows = (metrics && metrics.construction_complete) || [];

  const disabled = rows.length < 1;
  if (disabled) rows = makeDisabledRows();
  rows = rows.map((row) => ({ ...row, date: moment(row.date) }));
  rows = rows.reduce((result, row, index) => {
    result = [...result, row];
    const diff =
      index < rows.length - 1 ? monthsDiff(rows[index + 1].date, row.date) : 0;
    for (let months = 1; months < diff; months++) {
      result.push({
        date: moment(row.date.format('YYYY-MM-01')).add(months, 'M'),
      });
    }
    return result;
  }, []);
  rows = rows.map((row) => ({ ...row, dateLabel: formatDate(row.date) }));
  const chartColor =
    colors[
      disabled
        ? darkMode
          ? 'dark-mode-medium-white'
          : 'light-mode-medium-black'
        : 'chart-blue'
    ];
  if (rows.length < 5 && rows.length > 1) {
    rows = [
      ...rows,
      ...[...Array(5 - rows.length)].map(() => ({ dateLabel: '' })),
    ];
  }

  return (
    <div
      className={classnames('project-dashboard-construction-complete', {
        'dark-mode': darkMode,
        disabled: disabled,
      })}
    >
      <div className="construction-complete-title">Construction Complete</div>
      <div className="contruction-complete-chart">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={rows}
            margin={{
              top: isSmall ? 8 : isMedium ? 12 : 24,
              right: isSmall ? 16 : isMedium ? 24 : 32,
              left: isSmall ? 8 : isMedium ? 16 : 32,
              bottom: isSmall ? 4 : isMedium ? 8 : 12,
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={chartColor} stopOpacity={0.8} />
                <stop offset="95%" stopColor={chartColor} stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid
              stroke={colors[darkMode ? 'dark-line-grey' : 'light-line-grey']}
              vertical={false}
            />
            <XAxis
              height={24}
              dataKey="dateLabel"
              axisLine={false}
              tickLine={false}
              tick={{
                fontSize: 10,
                fill:
                  colors[
                    darkMode
                      ? 'dark-mode-clear-gray'
                      : 'light-mode-medium-black'
                  ],
              }}
            />
            <YAxis
              domain={[0, 100]}
              width={24}
              dataKey="value"
              axisLine={false}
              tickLine={false}
              tick={{
                fontSize: 10,
                fill:
                  colors[
                    darkMode
                      ? 'dark-mode-clear-gray'
                      : 'light-mode-medium-black'
                  ],
              }}
              tickFormatter={(value) => `${value}%`}
            />
            <Area
              connectNulls
              type="monotone"
              dataKey="value"
              fill={disabled ? 'transparent' : 'url(#colorUv)'}
              stroke={chartColor}
              dot={
                !disabled && {
                  r: isSmall ? 3 : 4,
                  fill: chartColor,
                  fillOpacity: 1,
                }
              }
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default connect((state) => {
  return {
    metrics: getReportMetrics(state),
    darkMode: getDarkMode(state),
  };
})(ConstructionComplete);
