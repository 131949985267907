import React from 'react';
import './WorkOrdersTitle.css';
import PropTypes from 'prop-types';
import numeral from 'numeral';

export default function WorkOrdersTitle(props) {
  return (
    <div className="work-orders-title">
      <div>{props.title}</div>
      {props.purchaseOrder !== null && (
        <div>
          <div>
            <div className="wot-title">CURRENT PO #</div>
            <div className="h3">{props.purchaseOrder.number}</div>
          </div>
          <div>
            <div className="wot-title">AMOUNT</div>
            <div className="h3">
              {numeral(props.purchaseOrder.amount).format('$0,0')}
            </div>
          </div>
          <div>
            <div className="wot-title">BALANCE</div>
            <div className="h3">
              {numeral(props.purchaseOrder.balance).format('$0,0')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

WorkOrdersTitle.propTypes = {
  purchaseOrder: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    balance: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
  }),
  title: PropTypes.string.isRequired,
};

WorkOrdersTitle.defaultProps = {
  purchaseOrder: null,
};
