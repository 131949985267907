import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './FieldWorkOrders.css';
import { fieldWorkOrdersRequest } from 'components/admin/field-work-orders/store/actions';
import FieldWorkOrdersTable from 'components/admin/field-work-orders/FieldWorkOrdersTable';

class FieldWorkOrders extends Component {
  static propTypes = {
    filters: PropTypes.shape(),
  };

  static defaultProps = {
    filters: {},
  };

  componentDidMount() {
    this.handlePageChange(1);
  }

  handlePageChange = (page, status) => {
    const { filters, dispatch } = this.props;
    dispatch(fieldWorkOrdersRequest(page, filters));
  };

  render() {
    return (
      <div className="field-work-orders">
        <FieldWorkOrdersTable onPageChange={this.handlePageChange} />
      </div>
    );
  }
}

export default connect((state) => {
  return {};
})(FieldWorkOrders);
