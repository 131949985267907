import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CompaniesDetails from 'components/admin/companies/CompaniesDetails';
import CompaniesEdit from 'components/admin/companies/CompaniesEdit';
import { showErrorMessage } from '../../../actions/messages';
import { connect } from 'react-redux';

class TeamContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    this.props.dispatch(
      showErrorMessage(
        'Something went wrong with the request, please try again later.'
      )
    );
  }

  render() {
    if (this.state.hasError) {
      return <Redirect to="/" />;
    } else {
      return (
        <div>
          <Switch>
            <Route
              exact
              path={`${this.props.match.url}/`}
              component={CompaniesDetails}
            />
            <Route
              exact
              path={`${this.props.match.url}/edit`}
              component={CompaniesEdit}
            />
          </Switch>
        </div>
      );
    }
  }
}

export default connect()(TeamContainer);
