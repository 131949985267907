import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import './SortHeader.css';
import vectorSvg from './vector.svg';
import vectorDarkSvg from './vector-dark.svg';
import vectorSelectedSvg from './vector-selected.svg';
import vectorSelectedDarkSvg from './vector-selected-dark.svg';

function SortHeader(props) {
  const { label, selected, desc, onChange, darkMode } = props;

  const handleChange = (event) => {
    event.preventDefault();
    onChange(!desc, selected && !desc);
  };

  const iconSvg = darkMode ? vectorDarkSvg : vectorSvg;
  const iconSelectedSvg = darkMode ? vectorSelectedDarkSvg : vectorSelectedSvg;

  return (
    <div
      className={classnames({
        'sort-header': true,
        'dark-mode': darkMode,
        'selected-header': selected,
      })}
      onClick={handleChange}
    >
      <span className="header-label-text">{label}</span>
      <div className="header-label-icons">
        {[
          (!selected || desc) && {
            src: selected ? iconSelectedSvg : iconSvg,
            alt: 'up',
            className: 'up-icon',
          },
          (!selected || !desc) && {
            src: selected ? iconSelectedSvg : iconSvg,
            alt: 'down',
            className: 'down-icon',
          },
        ]
          .filter((icon) => icon)
          .map((icon) => (
            <img
              key={icon.alt}
              className={classnames({
                'header-label-icon': true,
                [icon.className]: true,
              })}
              src={icon.src}
              alt={icon.alt}
            />
          ))}
      </div>
    </div>
  );
}

SortHeader.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  desc: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

SortHeader.defaultTypes = {
  selected: false,
  desc: false,
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(SortHeader);
