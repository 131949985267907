import {
  VENDOR_INVOICES_INVOICE_ADD,
  VENDOR_INVOICES_INVOICE_REMOVE,
  VENDOR_INVOICES_SET,
} from 'components/admin/work-orders/details/vendor-invoices/store/action-types';
import { WORK_ORDERS_DETAILS_STATE_INIT } from 'actions/admin/work-orders/action-types';

const initialState = {
  invoices: [],
};

export default function vendorInvoicesDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case VENDOR_INVOICES_INVOICE_ADD:
      return {
        ...state,
        invoices: state.invoices.concat([action.payload]),
      };

    case VENDOR_INVOICES_INVOICE_REMOVE:
      return {
        ...state,
        invoices: state.invoices.filter(
          (invoice) => invoice.id !== action.payload
        ),
      };

    case VENDOR_INVOICES_SET:
      return {
        ...state,
        invoices: action.payload,
      };

    case WORK_ORDERS_DETAILS_STATE_INIT:
      return {
        ...state,
        invoices: initialState.invoices,
      };

    default:
      return state;
  }
}
