import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SimpleButton.css';

class SimpleButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    img: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    img: null,
  };

  handleOnClick = (event) => {
    event.preventDefault();
    this.props.onClick();
  };

  render() {
    return (
      <button
        className={
          'simple-button btn button' +
          (this.props.className !== null ? ' ' + this.props.className : '')
        }
        disabled={this.props.disabled}
        onClick={this.handleOnClick}
      >
        {this.props.img !== null ? (
          <img src={this.props.img} alt="plus" />
        ) : null}
        {this.props.label}
      </button>
    );
  }
}

export default SimpleButton;
