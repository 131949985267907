import React from 'react';

import FormModal from 'components/shared/modal/FormModal';

import SecurityCamera from './SecurityCamera';

import './ViewSecurityCameraModal.css';

const ViewSecurityCameraModal = ({ projectId, camera, onClose }) => (
  <FormModal
    className="view-security-camera-modal"
    show
    onClose={onClose}
    subTitle={`${camera.name} ${
      camera.channel !== camera.name ? ` (${camera.channel})` : null
    } - ${camera.description}`}
  >
    <SecurityCamera
      key={camera.channel}
      projectId={projectId}
      camera={camera}
      autoreload
    />
  </FormModal>
);

export default ViewSecurityCameraModal;
