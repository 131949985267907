import React, { Component } from 'react';
import './DropdownMenu.css';
import vector from './vector.svg';
import vectorDark from './vector-dark.svg';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getDarkMode } from 'selectors/theme';
import Menu from './Menu';

export const DROPDOWN_TYPES = {
  FORM: 'form-dropdown-menu',
  SECTION_SELECTOR: 'section-selector-dropdown-menu',
};

export const CounterItem = (label, counter) => {
  return (
    <React.Fragment>
      {label}
      <span className="menu-item-counter">{counter}</span>
    </React.Fragment>
  );
};

class DropdownMenuWithIcon extends Component {
  static propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        className: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
        selectedLabel: PropTypes.oneOfType([
          PropTypes.element,
          PropTypes.string,
        ]),
        child: PropTypes.number,
        show: PropTypes.bool,
      })
    ).isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    renderSelector: PropTypes.func,
    icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    renderIcon: PropTypes.bool,
  };

  static defaultProps = {
    type: DROPDOWN_TYPES.FORM,
    value: null,
    placeholder: '',
    icon: null,
  };

  handleOnChange = (option) => {
    if (this.props.onChange !== undefined) {
      this.props.onChange(option);
    }
  };

  handleRenderSelector = (open) => {
    const {
      options,
      value,
      placeholder,
      renderSelector,
      darkMode,
    } = this.props;

    const selectedOption =
      value !== null && options.find((option) => option.value === value);

    if (renderSelector) {
      return renderSelector(selectedOption, open);
    }

    const label = selectedOption
      ? selectedOption.selectedLabel || selectedOption.label
      : placeholder;
    return (
      <div className="dropdown-menu-selector">
        {label}
        <img
          className="dropdown-menu-selector-arrow"
          src={darkMode ? vectorDark : vector}
          alt="arrow"
        />
      </div>
    );
  };

  render() {
    const { type, className, options, value, icon } = this.props;
    const renderSelector = icon
      ? typeof icon === 'function'
        ? icon()
        : icon
      : this.handleRenderSelector();

    const items = options
      .filter((option) => option.show !== false)
      .map((option) => ({
        className: `${option.className || ''} ${
          option.value === value ? 'selected' : ''
        }`,
        content: option.label,
        child: option.child,
        onClick: () => this.handleOnChange(option),
        closeOnSelect: option.closeOnSelect,
      }));

    return (
      <Menu
        className={`dropdown-menu-container ${type} ${className || ''}`}
        renderSelector={() => renderSelector}
        items={items}
        placement="bottom-start"
      />
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(DropdownMenuWithIcon);
