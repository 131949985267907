import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import PropTypes from 'prop-types';
import vectorSvg from 'components/shared/table/vector.svg';
import vectorDarkSvg from 'components/shared/table/vector-dark.svg';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import Meeting from 'domain/new-meeting';
import {
  getProjectNewMeetingsFilters,
  getMeetingsTitles,
  getMeetingsTypes,
  getMeetingsParentsTitles,
  getMeetingsParentsTypes,
  getProjectNewMeetingsInfoModal,
  getProjectMeetingsParentsTabNavigation,
  getProjectNewMeetingsAddTypeModal,
  getProjectNewMeetingsAddTitleModal,
  getProjectNewMeetingsEditTypeModal,
  getProjectNewMeetingsEditTitleModal,
  getMeetingsInstanceModal,
  getProjectNewMeetingsForm,
  getMeetingsInstanceForm,
  getActionItems,
} from './store/selectors';
import './AttendanceTable.css';
import SingleRadioButton from './SingleRadioButton';

const VectorIcon = ({ id, toggleFunction, darkMode, expandedParentRows }) => {
  const isExpanded = !!expandedParentRows[id];
  return (
    <img
      alt="collapsable-indicator"
      src={`${darkMode ? vectorDarkSvg : vectorSvg}`}
      className={`${isExpanded ? 'rotatable-icon' : ''}`}
      onClick={() => toggleFunction(id)}
    />
  );
};

const AttendanceTable = ({
  rows = { data: [] },
  headersOnly,
  newView,
  darkMode,
  handleFormChange,
  instanceForm,
}) => {
  const [expandedParentRows, setExpandedParentRows] = useState({});
  const [attendance, setAttendance] = useState({});
  const [nonDirectoryAttendance, setNonDirectoryAttendance] = useState({});
  const [parentRowsChecked, setParentRowsChecked] = useState({});
  const [categorizedUsers, setCategorizedUsers] = useState({});

  const radioButtonOptions = instanceForm.isAgenda
    ? [{ id: 'distribution', label: '' }]
    : [
        { id: 'present_in_person', label: '' },
        { id: 'present_remote', label: '' },
        { id: 'absent', label: '' },
        { id: 'distribution', label: '' },
      ];
  useEffect(() => {
    const initialAttendance = {};
    const initialGuestAttendance = {};
    if (!instanceForm.attendeeDetails) {
      rows.data.forEach((user) => {
        initialAttendance[user.id] = 'distribution'; // Default status
      });
      setAttendance(initialAttendance);
      handleFormChange({ attendeeDetails: initialAttendance });
    } else {
      const processedAttendance =
        typeof instanceForm.attendeeDetails === 'string'
          ? JSON.parse(instanceForm.attendeeDetails)
          : instanceForm.attendeeDetails;
      setAttendance(processedAttendance);
      handleFormChange({
        attendeeDetails: processedAttendance,
      });
    }
    //Same for non directory guests
    if (
      !instanceForm.nonUserAttendeesDetails &&
      instanceForm.nonDirectoryAttendees &&
      instanceForm.nonDirectoryAttendees.length > 0
    ) {
      instanceForm.nonDirectoryAttendees.forEach((att) => {
        initialGuestAttendance[att.id] = 'present_in_person';
      });
    }

    if (
      instanceForm.nonUserAttendeesDetails &&
      instanceForm.nonDirectoryAttendees &&
      instanceForm.nonDirectoryAttendees.length > 0
    ) {
      const processedGuestAttendance =
        typeof instanceForm.nonUserAttendeesDetails === 'string'
          ? JSON.parse(instanceForm.nonUserAttendeesDetails)
          : instanceForm.nonUserAttendeesDetails;
      setNonDirectoryAttendance(processedGuestAttendance);
      handleFormChange({
        nonUserAttendeesDetails: processedGuestAttendance,
      });
    }
    setCategorizedUsers(categorizeUsersByRoles(rows.data));
  }, [rows.data]);

  useEffect(() => {
    setCategorizedUsers(categorizeUsersByRoles(instanceForm.attendees));
  }, [instanceForm.attendees]);

  useEffect(() => {
    checkAndSetParentRowsStatus();
  }, [attendance, categorizedUsers]);

  const handleAttendanceChange = (userId, status) => {
    const updatedAttendance = { ...attendance, [userId]: status };
    setAttendance(updatedAttendance);
    handleFormChange({ attendeeDetails: updatedAttendance });
  };
  const handleNonDirectoryAttendanceChange = (userId, status) => {
    const updatedAttendance = { ...nonDirectoryAttendance, [userId]: status };
    setNonDirectoryAttendance(updatedAttendance);
    handleFormChange({ nonUserAttendeesDetails: updatedAttendance });
  };

  const handleAttendanceChangeOnChildRow = (parentRow, userId, status) => {
    handleAttendanceChange(userId, status);
    const childRows = categorizedUsers[parentRow] || [];
    const allSameStatus = childRows.every(
      (child) => attendance[child.id] === status || child.id === userId
    );

    if (allSameStatus) {
      setParentRowsChecked((prevState) => ({
        ...prevState,
        [parentRow]: status,
      }));
    } else {
      setParentRowsChecked((prevState) => {
        const { [parentRow]: _, ...newState } = prevState;
        return newState;
      });
    }
  };
  const handleAttendanceChangeOnNonDirectoryUserRow = (
    parentRow,
    userId,
    status
  ) => {
    const { [parentRow]: _, ...newState } = parentRowsChecked;
    handleNonDirectoryAttendanceChange(userId, status);
    setParentRowsChecked(newState);
  };

  const handleAttendanceChangeOnParentRow = (parentRowId, status) => {
    if (parentRowId === 'meetingGuests') {
      setNonDirectoryAttendance((prev) => {
        const updatedAttendance = { ...prev };
        const nonDirectoryUsers = instanceForm.nonDirectoryAttendees;
        nonDirectoryUsers.forEach((u) => {
          updatedAttendance[u.id] = status;
        });
        return updatedAttendance;
      });
      setParentRowsChecked({ ...parentRowsChecked, [parentRowId]: status });
      return;
    } else {
      setAttendance((prev) => {
        const updatedAttendance = { ...prev };
        const childRows = categorizedUsers[parentRowId] || [];
        childRows.forEach((child) => {
          updatedAttendance[child.id] = status;
        });
        return updatedAttendance;
      });
      setParentRowsChecked({ ...parentRowsChecked, [parentRowId]: status });
    }
  };

  const toggleParentRow = useCallback((id) => {
    setExpandedParentRows((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  }, []);

  const categorizeUsersByRoles = (users) => {
    const rolesMap = {};
    users.forEach((user) => {
      const role = Meeting.companyRoleLabel(user);
      if (!rolesMap[role]) {
        rolesMap[role] = [];
      }
      rolesMap[role].push(user);
    });
    return rolesMap;
  };

  const checkAndSetParentRowsStatus = () => {
    const newParentRowsChecked = {};
    Object.keys(categorizedUsers).forEach((parentRow) => {
      const childRows = categorizedUsers[parentRow] || [];
      const allSameStatus =
        childRows.length > 0 &&
        childRows.every(
          (child) => attendance[child.id] === attendance[childRows[0].id]
        );
      if (allSameStatus) {
        newParentRowsChecked[parentRow] = attendance[childRows[0].id];
      }
    });
    setParentRowsChecked(newParentRowsChecked);
  };
  const renderChildRow = (parentRow, childRow) => {
    return (
      <tr key={childRow.id}>
        <td></td>
        <td key={childRow.id + childRow.name}>{childRow.fullName}</td>
        <td key={childRow.id + childRow.name}>{childRow.title}</td>
        {radioButtonOptions.map((option) => (
          <td key={option.id}>
            <SingleRadioButton
              option={{ value: option.id, label: option.label }}
              selectedValue={attendance[childRow.id] === option.id}
              onChange={(value) =>
                handleAttendanceChangeOnChildRow(parentRow, childRow.id, value)
              }
              readOnly={!instanceForm.editAttendees}
            />
          </td>
        ))}
      </tr>
    );
  };

  const renderRow = (parentRow, attendees) => {
    return (
      <>
        <tr key={parentRow} className="parent" onClick={() => {}}>
          <td>
            <VectorIcon
              id={parentRow}
              toggleFunction={toggleParentRow}
              darkMode={darkMode}
              expandedParentRows={expandedParentRows}
            />
          </td>
          <td>
            <div className="parent-row-text">{parentRow}</div>
          </td>
          <td>
            <div className="parent-row-text"></div>
          </td>
          {radioButtonOptions.map((option) => (
            <td key={option.id}>
              <SingleRadioButton
                option={{ value: option.id, label: option.label }}
                selectedValue={
                  parentRowsChecked &&
                  parentRowsChecked[parentRow] === option.id
                }
                onChange={(value) =>
                  handleAttendanceChangeOnParentRow(parentRow, value)
                }
                readOnly={!instanceForm.editAttendees}
              />
            </td>
          ))}
        </tr>
        {expandedParentRows[parentRow] &&
          attendees.map((att) => renderChildRow(parentRow, att))}
      </>
    );
  };

  const renderGuestsRows = (parentRow, user) => {
    // These are child rows but for those guests that don't belong to the company
    // directory
    return (
      <tr key={user.id}>
        <td></td>
        <td>{user.firstName + ' ' + user.lastName}</td>
        <td>{user.company}</td>
        {radioButtonOptions.map((option) => (
          <td key={option.id}>
            <SingleRadioButton
              option={{ value: option.id, label: option.label }}
              selectedValue={nonDirectoryAttendance[user.id] === option.id}
              onChange={(value) =>
                handleAttendanceChangeOnNonDirectoryUserRow(
                  parentRow,
                  user.id,
                  value
                )
              }
              readOnly={!instanceForm.editAttendees}
            />
          </td>
        ))}
      </tr>
    );
  };
  const renderParentRowForMeetingGuests = (users) => {
    return (
      <>
        <tr key={'meeting-guests'} onClick={() => {}}>
          <td>
            <VectorIcon
              id="meetingGuests"
              toggleFunction={toggleParentRow}
              darkMode={darkMode}
              expandedParentRows={expandedParentRows}
            />
          </td>
          <td>
            <div className="parent-row-text">{'Meeting Guests'}</div>
          </td>
          <td>
            <div className="parent-row-text"></div>
          </td>
          {radioButtonOptions.map((option) => (
            <td key={option.id}>
              <SingleRadioButton
                option={{ value: option.id, label: option.label }}
                selectedValue={
                  parentRowsChecked &&
                  parentRowsChecked['meetingGuests'] === option.id
                } // Replace with your state management logic
                onChange={(value) =>
                  handleAttendanceChangeOnParentRow('meetingGuests', value)
                }
                readOnly={!instanceForm.editAttendees}
              />
            </td>
          ))}
        </tr>
        {expandedParentRows['meetingGuests'] &&
          users.map((att) => renderGuestsRows('Meeting Guests', att))}
      </>
    );
  };
  const renderHeaders = () => {
    return instanceForm.isAgenda ? (
      <tr key="meetings-table-header">
        <th>
          <span className="header-row-text-attendance"> </span>
        </th>
        <th>
          <span className="header-row-text-attendance">NAME</span>
        </th>

        <th>
          <span className="header-row-text-attendance">TITLE</span>
        </th>
        <th>
          <span className="header-row-text-attendance">DISTRIBUTION</span>
        </th>
      </tr>
    ) : (
      <tr key="meetings-table-header">
        <th>
          <span className="header-row-text-attendance"> </span>
        </th>
        <th>
          <span className="header-row-text-attendance">NAME</span>
        </th>

        <th>
          <span className="header-row-text-attendance">TITLE</span>
        </th>
        <th>
          <span className="header-row-text-attendance">PRESENT IN PERSON</span>
        </th>
        <th>
          <span className="header-row-text-attendance">PRESENT REMOTE</span>
        </th>
        <th>
          <span className="header-row-text-attendance">ABSENT</span>
        </th>
        <th>
          <span className="header-row-text-attendance">DISTRIBUTION ONLY</span>
        </th>
      </tr>
    );
  };
  const { nonDirectoryAttendees } = instanceForm;
  return (
    <React.Fragment>
      {!newView && (
        <table
          className={`attendance-instance-table ${darkMode ? 'dark-mode' : ''}`}
        >
          <thead>{renderHeaders()}</thead>
          {!headersOnly && (
            <tbody>
              {Object.entries(categorizedUsers).map(([role, users]) => {
                return renderRow(role, users);
              })}

              {nonDirectoryAttendees &&
                nonDirectoryAttendees.length > 0 &&
                renderParentRowForMeetingGuests(nonDirectoryAttendees)}
            </tbody>
          )}
        </table>
      )}
    </React.Fragment>
  );
};

AttendanceTable.propTypes = {
  className: PropTypes.string,
  headers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  emptyMessage: PropTypes.string,
  showBottomLine: PropTypes.bool,
  headersOnly: PropTypes.bool,
  actionCells: PropTypes.number,
  onRowSelected: PropTypes.func,
  onGenerateLink: PropTypes.func,
  stickyHeader: PropTypes.bool,
  noRows: PropTypes.bool,
  handleFilterChange: PropTypes.func,
};

AttendanceTable.defaultProps = {
  headers: [],
  rows: [],
  emptyMessage: 'No Items',
  showBottomLine: false,
  headersOnly: false,
  newView: false,
  stickyHeader: false,
  noRows: false,
  actionCells: 0,
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
    meetingsTitles: getMeetingsTitles(state),
    meetingsTypes: getMeetingsTypes(state),
    meetingsParentsTitles: getMeetingsParentsTitles(state),
    meetingsParentsTypes: getMeetingsParentsTypes(state),
    currentFilters: getProjectNewMeetingsFilters(state),
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    infoModalShow: getProjectNewMeetingsInfoModal(state),
    tabNavigation: getProjectMeetingsParentsTabNavigation(state),
    showAddTypeModal: getProjectNewMeetingsAddTypeModal(state),
    showAddTitleModal: getProjectNewMeetingsAddTitleModal(state),
    showEditTypeModal: getProjectNewMeetingsEditTypeModal(state),
    showEditTitleModal: getProjectNewMeetingsEditTitleModal(state),
    showInstanceForm: getMeetingsInstanceModal(state),
    form: getProjectNewMeetingsForm(state),
    instanceForm: getMeetingsInstanceForm(state),
    actionItems: getActionItems(state),
  };
})(AttendanceTable);
