import React, { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';

import { Grid } from '@mui/material';
import FormModal from 'components/shared/modal/FormModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

import {
  getProjectPhotosForm,
  getProjectPhotosShowTags,
} from './store/selectors';
import { setProjectPhotosShowTags } from './store/actions';
import ProjectPhotosFilterTags from './ProjectPhotosFilterTags';

const ProjectPhotosFormTagModal = (props) => {
  const { dispatch, form, show, onChange } = props;
  const [value, setValue] = useState(form.tags);

  useEffect(() => {
    if (show) setValue(form.tags);
  }, [show]);

  const onClose = () => {
    dispatch(setProjectPhotosShowTags(false));
  };
  const onSave = () => {
    onChange({ tags: value });
    onClose();
  };

  return (
    <FormModal
      className="project-photos-tags-modal"
      show={show}
      onClose={onClose}
      subTitle="Tags"
    >
      <Grid
        container
        justifyContent="center"
        paddingX={4}
        paddingBottom={2.5}
        spacing={4}
      >
        <Grid item xs={12}>
          <ProjectPhotosFilterTags
            value={value}
            onChange={({ tags }) => setValue(tags)}
            enableAdd
          />
        </Grid>
        <Grid item>
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label={'Save'}
            onClick={onSave}
          />
        </Grid>
      </Grid>
    </FormModal>
  );
};

export default connect((state) => ({
  form: getProjectPhotosForm(state),
  show: getProjectPhotosShowTags(state),
}))(ProjectPhotosFormTagModal);
