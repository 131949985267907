import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Reports.css';
import {
  loadReportRequest,
  changeStartDate,
  changeEndDate,
  resetReports,
  exportReport,
  selectAllWorkOrders,
  selectWorkOrder,
  clearSelection,
} from 'actions/admin/reports/reports';
import {
  getCurrentPage,
  getStartDate,
  getEndDate,
  getExtraFilterValues,
  getSearching,
  getReportResults,
  getSelectAllChecked,
  getSelectedWorkOrders,
} from 'selectors/admin/reports/reports';
import { simpleScrollToTop } from 'services/utils/scroller-util';
import SimpleTable from 'components/shared/table/SimpleTable';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import ReportFilter from './ReportFilter';
import FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import CheckBox from 'components/shared/checkbox/CheckBox';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import Button, { BUTTON_COLORS } from 'components/shared/button/Button';

class BaseReport extends Component {
  static propTypes = {
    reportName: PropTypes.string.isRequired,
    tableHeader: PropTypes.arrayOf(PropTypes.string).isRequired,
    tableRowsData: PropTypes.arrayOf(
      PropTypes.shape({
        first: PropTypes.bool.isRequired,
        rowData: PropTypes.shape.isRequired,
        workOrderId: PropTypes.number.isRequired,
      })
    ),
    title: PropTypes.string.isRequired,
    onRowSelection: PropTypes.func.isRequired,
    extraFilters: PropTypes.array,
  };

  componentDidMount() {
    this.handlePageChange(1);
  }

  componentDidUpdate(prevProps) {
    // If selected items did not change, then scroll top top
    if (
      Object.keys(prevProps.selectedWorkOrders).length ===
      Object.keys(this.props.selectedWorkOrders).length
    ) {
      simpleScrollToTop();
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetReports());
  }

  handlePageChange = (newPage) => {
    // Should return promise to process results in search
    return this.props.dispatch(
      loadReportRequest(
        this.props.reportName,
        newPage,
        this.props.startDate,
        this.props.endDate,
        this.props.extraFilterValues
      )
    );
  };

  handleStartDateChange = (value) => {
    this.props.dispatch(changeStartDate(value));
  };

  handleEndDateChange = (value) => {
    this.props.dispatch(changeEndDate(value));
  };

  handleExport = () => {
    const {
      reportName,
      startDate,
      endDate,
      extraFilterValues,
      dispatch,
    } = this.props;
    const ids = this.selectedWorkOrderIds();
    dispatch(
      exportReport(reportName, ids, startDate, endDate, extraFilterValues)
    ).then((content) => {
      if (!content) return;

      const file = new File([content.data], content.fileName, {
        type: content.type,
      });
      FileSaver.saveAs(file);
    });
  };

  handleSearch = () => {
    this.handlePageChange(1).then((response) => {
      if (response.ok) {
        this.props.dispatch(clearSelection());
      }
    });
  };

  handleSelectAllCheck = () => {
    this.props.dispatch(selectAllWorkOrders());
  };

  handleWorkOrderSelection = (workOrderId) => {
    this.props.dispatch(selectWorkOrder(workOrderId));
  };

  selectedWorkOrderIds() {
    let selectedIds = [];
    Object.keys(this.props.selectedWorkOrders).forEach((page) => {
      Object.keys(this.props.selectedWorkOrders[page]).forEach((woId) => {
        selectedIds.push(woId);
      });
    });
    return selectedIds;
  }

  mapRows() {
    // Map the info from the parent report to a list of row data (what SimpleTable expects)
    // For each item in tableRowsData, add the CheckBox if item is the first one.
    return this.props.tableRowsData.map((item) => {
      if (item.first) {
        const selectedWorkOrders = this.props.selectedWorkOrders[
          this.props.currentPage
        ];
        return {
          ...item.rowData,
          data: [
            <CheckBox
              checked={
                selectedWorkOrders !== undefined &&
                selectedWorkOrders[item.workOrderId] !== undefined
              }
              onChange={() => this.handleWorkOrderSelection(item.workOrderId)}
            />,
            ...item.rowData.data,
          ],
        };
      } else {
        return {
          ...item.rowData,
          data: ['', ...item.rowData.data],
        };
      }
    });
  }

  render() {
    return (
      <div className="reports">
        <DetailsTitle
          title={this.props.title}
          rightButtons={[
            <Button
              key="search"
              color={BUTTON_COLORS.GREEN}
              label={this.props.searching ? 'Searching...' : 'Search'}
              disabled={this.props.searching}
              onClick={this.handleSearch}
            />,
            <Button
              key="export"
              color={BUTTON_COLORS.GREEN}
              label="Export to Excel"
              disabled={this.selectedWorkOrderIds().length === 0}
              onClick={this.handleExport}
            />,
          ]}
        />
        <div className="content-container">
          <ReportFilter
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            onStartDateChange={this.handleStartDateChange}
            onEndDateChange={this.handleEndDateChange}
            extraFilters={this.props.extraFilters}
          />
          {this.props.reportResults !== null && (
            <div>
              <SimpleTable
                className="report-table"
                headers={[
                  <CheckBox
                    checked={this.props.allChecked}
                    className="check-select-all"
                    readOnly={
                      this.props.reportResults === null ||
                      this.props.reportResults.total === 0
                    }
                    onChange={this.handleSelectAllCheck}
                  />,
                  ...this.props.tableHeader,
                ]}
                rows={this.mapRows()}
                emptyMessage="No Results"
                onRowSelected={this.props.onRowSelection}
              />

              <div className="pagination-container">
                <SimplePagination
                  currentPage={this.props.currentPage}
                  pageSize={this.props.reportResults.pageSize}
                  total={this.props.reportResults.total}
                  onPageChange={this.handlePageChange}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    allChecked: getSelectAllChecked(state),
    currentPage: getCurrentPage(state),
    startDate: getStartDate(state),
    endDate: getEndDate(state),
    extraFilterValues: getExtraFilterValues(state),
    searching: getSearching(state),
    selectedWorkOrders: getSelectedWorkOrders(state),
    reportResults: getReportResults(state),
  };
})(BaseReport);
