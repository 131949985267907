import { showErrorMessage } from '../../messages';
import {
  TASKS_CONTRACTOR_SELECT,
  TASKS_LOAD_SUCCESS,
  TASKS_PAGE_CHANGE,
  TASKS_TRADES_SELECT,
  TASKS_TRADES_SUCCESS,
  TASKS_UI_INITIALIZE,
} from './action-types';
import { tasksApi } from '../../../services/api/tasks-api';
import { tradesApi } from '../../../services/api/trades-api';
import { push } from 'react-router-redux';
import { selectWorkOrderRequest } from '../work-orders/work-orders';

export function changePage(newPage) {
  return {
    type: TASKS_PAGE_CHANGE,
    payload: newPage,
  };
}

export function initializeUi() {
  return {
    type: TASKS_UI_INITIALIZE,
  };
}

export function firstLoadTasksRequest() {
  return (dispatch) => {
    dispatch(initializeUi());

    let promises = [];
    promises.push(tasksApi.getTasks(1));
    promises.push(tradesApi.getTrades());

    return Promise.all(promises).then((results) => {
      if (results[0].ok) {
        dispatch(loadTasksSuccess(results[0].tasks));
      } else {
        dispatch(showErrorMessage(results[0].errors));
      }

      if (results[1].ok) {
        dispatch(loadTradesSuccess(results[1].trades));
      } else {
        dispatch(showErrorMessage(results[1].errors));
      }
    });
  };
}

export function loadTasksRequest(
  page,
  contractorId,
  tradeId,
  critical,
  taskStatus
) {
  return (dispatch) => {
    return tasksApi
      .getTasks(
        page,
        contractorId === null ? undefined : contractorId,
        tradeId === null ? undefined : tradeId,
        critical,
        taskStatus
      )
      .then((response) => {
        if (response.ok) {
          dispatch(loadTasksSuccess(response.tasks));
          dispatch(changePage(page));
          dispatch(
            selectContractor(contractorId === undefined ? null : contractorId)
          );
          dispatch(selectTrade(tradeId === undefined ? null : tradeId));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadTasksSuccess(tasks) {
  return {
    type: TASKS_LOAD_SUCCESS,
    payload: tasks,
  };
}

export function loadTradesSuccess(trades) {
  return {
    type: TASKS_TRADES_SUCCESS,
    payload: trades,
  };
}

export function openTaskDetails(task) {
  return (dispatch) => {
    if (task.status === 'draft') {
      dispatch(
        selectWorkOrderRequest(task.work_order_id, task.work_order.status)
      );
    } else {
      dispatch(
        push(`/admin/work-orders/${task.work_order_id}/task/${task.id}`)
      );
    }
  };
}

export function selectContractor(contractorId) {
  return {
    type: TASKS_CONTRACTOR_SELECT,
    payload: contractorId,
  };
}

export function selectTrade(tradeId) {
  return {
    type: TASKS_TRADES_SELECT,
    payload: tradeId,
  };
}
