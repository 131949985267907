import React from 'react';
import { connect } from 'react-redux';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import DatePicker from 'components/shared/date-picker/DatePicker';
import TimePicker from 'components/shared/time-picker/TimePicker';
import Input from 'components/shared/input/Input';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

import {
  setAddNewMeetingModalShow,
  setEditNextMeetingModalShow,
  setAddTypeModalShow,
  setEditTypeModalShow,
  setAddTitleModalShow,
  setEditTitleModalShow,
} from './store/actions';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Selector from 'components/shared/selector/Selector';

import {
  getAddNextMeetingModal,
  getMeetingsInstanceForm,
  getEditNextMeetingModal,
  getActionItemsErrors,
  getInstanceInfoErrors,
  getMeetingsInstanceIsBackup,
  getMeetingsTypes,
  getMeetingsTitles,
} from './store/selectors';
import './MeetingsInstanceForm.css';
import AddNextMeetingModal from './AddNextMeetingModal';
import EditNextMeetingModal from './EditNextMeetingModal';
import newMeetingsPermissions from '../../../../../permissions/new-meetings-permissions';
import SearchableDropDown from 'components/shared/dropdown/SearchableDropDown';
import timezonesArray from '../project-daily-report-log/field-reports/TimezonesList';

const MeetingsInstanceFormQuickStart = (props) => {
  const {
    handleFormChange,
    instanceForm,
    showAddNextMeetingModal,
    showEditNextMeetingModal,
    dispatch,
    meetingsParent,
    actionItemsErrors,
    instanceInfoErrors,
    currentUser,
    meetingsTypes,
    meetingsTitles,
  } = props;

  const handleEditNextMeetingInfo = () => {
    if (!meetingsParent.nextMeeting) {
      dispatch(setAddNewMeetingModalShow(true));
    } else {
      dispatch(setEditNextMeetingModalShow(true));
    }
  };
  const errors = () => {
    const instanceInfoErrorsKeys = Object.keys(instanceInfoErrors);
    return (
      <div className="errors-container">
        {actionItemsErrors
          ? actionItemsErrors.map((e) => <div>{e}</div>)
          : null}
        {instanceInfoErrorsKeys.length > 0
          ? instanceInfoErrorsKeys.map((e) => (
              <div>{instanceInfoErrors[e]}</div>
            ))
          : null}
      </div>
    );
  };
  const MeetingDate = (
    <FormControlBlock
      hideBottomLabel
      label={instanceForm.meetingDate && 'Meeting Date'}
      control={
        <DatePicker
          className="date"
          placeholder={'Meeting Date'}
          value={instanceForm.meetingDate}
          onChange={(value) => handleFormChange({ meetingDate: value })}
        />
      }
    />
  );

  const StartTime = (
    <FormControlBlock
      hideBottomLabel
      label={instanceForm.startTime && 'Arrival Time'}
      control={
        <TimePicker
          className={'clock'}
          placeholder={'Start Time'}
          onChange={(value) => handleFormChange({ startTime: value })}
          value={instanceForm.startTime}
          valid={!(instanceForm.errors && instanceForm.errors.startTime)}
        />
      }
    />
  );

  const EndTime = (
    <FormControlBlock
      hideBottomLabel
      label={instanceForm.endTime && 'End Time'}
      control={
        <TimePicker
          className={'clock'}
          placeholder={'End Time'}
          onChange={(value) => handleFormChange({ endTime: value })}
          value={instanceForm.endTime}
        />
      }
    />
  );

  const AddressInput = (
    <FormControlBlock
      hideBottomLabel
      className="input-address"
      hideLabel={!instanceForm.address}
      label={instanceForm.address && 'Address'}
      control={
        <Input
          type={'text'}
          placeholder={'Address'}
          value={instanceForm.address}
          onChange={(address) => handleFormChange({ address })}
        />
      }
    />
  );
  const LocationInput = (
    <FormControlBlock
      hideBottomLabel
      hideLabel={!instanceForm.location}
      label={instanceForm.title && 'Location'}
      control={
        <Input
          type={'text'}
          placeholder={'Location'}
          value={instanceForm.location}
          onChange={(location) => handleFormChange({ location })}
        />
      }
    />
  );
  const CallInfo = (
    <FormControlBlock
      hideBottomLabel
      hideLabel={!instanceForm.callInfo}
      label={instanceForm.title && 'Call Info'}
      control={
        <Input
          type={'text'}
          placeholder={'Call Info'}
          value={instanceForm.callInfo}
          onChange={(callInfo) => handleFormChange({ callInfo })}
        />
      }
    />
  );
  const otherInfo = (
    <FormControlBlock
      label="Other Info"
      position="left"
      readonly={false}
      control={
        <Input
          type={'text'}
          placeholder={'Other Info'}
          value={instanceForm.otherInfo}
          valid={!(errors && errors.otherInfo)}
          onChange={(otherInfo) => handleFormChange({ otherInfo })}
        />
      }
    ></FormControlBlock>
  );
  const JobWalkCheckbox = (
    <FormControlBlock
      hideBottomLabel
      className="input-condition"
      control={
        <LabeledCheckBox
          label="Job Walk Conducted"
          checked={!!instanceForm.jobWalkConducted}
          onChange={(jobWalkConducted) =>
            handleFormChange({ jobWalkConducted })
          }
          position="left"
        />
      }
    />
  );

  const privateMeeting = (
    <FormControlBlock
      readonly={false}
      className="input-condition"
      control={
        <LabeledCheckBox
          label="Private Meeting"
          checked={!!instanceForm.privateMeeting}
          onChange={(privateMeeting) => handleFormChange({ privateMeeting })}
          position="left"
        />
      }
    ></FormControlBlock>
  );
  const privateToolTip = (
    <Tooltip
      title={
        <div className="tooltip-container">
          <div className="tooltip-title">Private Meeting</div>
          <div className="tooltip-text">
            Meetings that are marked Private will only appear to those who have
            been included in Attendance.
          </div>
        </div>
      }
      position="right"
    >
      <IconButton className="tooltip-help-icon">
        <HelpOutlineOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
  const CallLink = (
    <FormControlBlock
      hideBottomLabel
      hideLabel={!instanceForm.callLink}
      label={instanceForm.title && 'Call Link'}
      control={
        <Input
          type={'text'}
          placeholder={'Call Link'}
          value={instanceForm.callLink}
          onChange={(callLink) => handleFormChange({ callLink })}
        />
      }
    />
  );

  const WebLink = (
    <FormControlBlock
      hideBottomLabel
      hideLabel={!instanceForm.webLink}
      label={instanceForm.webLink && 'Weblink'}
      control={
        <Input
          type={'text'}
          placeholder={'Weblink'}
          value={instanceForm.webLink}
          onChange={(webLink) => handleFormChange({ webLink })}
        />
      }
    />
  );
  const typeSelector = (
    <FormControlBlock
      label="Meeting Type"
      position="right"
      readonly={false}
      control={
        <Selector
          className="select-dropdown"
          placeholder={'Meeting Type'}
          value={instanceForm.meetingType}
          onChange={(option) => {
            if (option && option.label === 'Add Type') {
              dispatch(setAddTypeModalShow(true));
            } else if (option && option.label === 'Edit Type') {
              dispatch(setEditTypeModalShow(true));
            } else {
              handleFormChange({
                meetingType: option ? option.value : option,
              });
            }
          }}
          options={meetingsTypes}
          clearable
        />
      }
    ></FormControlBlock>
  );
  const titleSelector = (
    <FormControlBlock
      label="Meeting Title"
      position="left"
      readonly={false}
      control={
        <Selector
          className="select-dropdown"
          placeholder={'Meeting Title'}
          value={instanceForm.title}
          onChange={(option) => {
            if (option && option.label === 'Add Title') {
              dispatch(setAddTitleModalShow(true));
            } else if (option && option.label === 'Edit Title') {
              dispatch(setEditTitleModalShow(true));
            } else {
              handleFormChange({
                title: option ? option.value : option,
              });
            }
          }}
          options={meetingsTitles}
          clearable
        />
      }
    ></FormControlBlock>
  );
  const InputTimezone = (
    <FormControlBlock
      hideBottomLabel
      className="input-condition"
      control={
        <SearchableDropDown
          className="select-dropdown"
          placeholder="Timezone"
          value={instanceForm.timezone}
          onChange={(option) =>
            handleFormChange({
              timezone: option ? option.value : option,
              timezone_name: option ? option.timezone_name : option,
            })
          }
          loadOptions={() =>
            new Promise((resolve) =>
              resolve({ ok: true, options: timezonesArray })
            )
          }
          clearable
        />
      }
    />
  );
  return (
    <React.Fragment>
      <div>
        <div className="form-block-container">
          <div className="form-block address">{titleSelector}</div>
          <div className="form-block location">{typeSelector}</div>
        </div>
        <div className="form-block-container">
          <div className="form-block">{MeetingDate}</div>

          <div className="form-block">
            <div className="form-control-container">
              {StartTime}
              {EndTime}
              {JobWalkCheckbox}
              {privateMeeting}
              {privateToolTip}
            </div>
          </div>
        </div>
        <div className="form-block-container">
          <div className="form-block address">{InputTimezone}</div>
        </div>
        <div className="form-block-container">
          <div className="form-block address">{AddressInput}</div>
          <div className="form-block location">{LocationInput}</div>
        </div>

        <div className="form-block-container">
          <div className="form-block call-info">{CallInfo}</div>
          <div className="form-block call-link">{CallLink}</div>
        </div>
        <div className="form-block-container">
          <div className="form-block weblink">{WebLink}</div>
        </div>
        <div className="form-block-container">
          <div className="form-block weblink">{otherInfo}</div>
        </div>
        <div className="form-block-container">
          <div className="next-meeting-text">Next Meeting</div>
          <div className="edit-button-container">
            {newMeetingsPermissions.canEditInstance(
              currentUser,
              instanceForm,
              meetingsParent.status === 'archived'
            ) && (
              <Button
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Edit"
                onClick={() => handleEditNextMeetingInfo()}
              />
            )}
          </div>
        </div>
        <div className="next-meeting-datetimes-text">
          {meetingsParent.nextMeeting || 'No meetings set'}
        </div>
        {errors()}
      </div>
      {!meetingsParent.nextMeeting && showAddNextMeetingModal && (
        <AddNextMeetingModal handleFormChange={handleFormChange} />
      )}
      {meetingsParent.nextMeeting && showEditNextMeetingModal && (
        <EditNextMeetingModal handleFormChange={handleFormChange} />
      )}
    </React.Fragment>
  );
};

export default connect((state) => ({
  currentProject: getCurrentProject(state),
  instanceForm: getMeetingsInstanceForm(state),
  showAddNextMeetingModal: getAddNextMeetingModal(state),
  showEditNextMeetingModal: getEditNextMeetingModal(state),
  actionItemsErrors: getActionItemsErrors(state),
  instanceInfoErrors: getInstanceInfoErrors(state),
  currentUser: getCurrentUser(state),
  meetingsInstanceIsBackup: getMeetingsInstanceIsBackup(state),
  meetingsTypes: getMeetingsTypes(state),
  meetingsTitles: getMeetingsTitles(state),
}))(MeetingsInstanceFormQuickStart);
