import React from 'react';
import { connect } from 'react-redux';
import { getReportMetrics } from './store/selectors';
import PaidIndicator from './PaidIndicator';
import './ConstructionPaidToDate.css';

const ConstructionPaidToDate = (props) => {
  const { metrics } = props;
  const { contract, paid } =
    (metrics && metrics.construction_paid_to_date) || {};

  return (
    <PaidIndicator
      className="project-dashboard-construction-paid-to-date"
      name="Construction Paid To Date"
      contract={contract}
      paid={paid}
      tooltipPosition="right"
    />
  );
};

export default connect((state) => {
  return {
    metrics: getReportMetrics(state),
  };
})(ConstructionPaidToDate);
