import axios from 'axios';
import BaseApi from './base-api';

class ProjectSettingsApi extends BaseApi {
  show(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/project_settings`)
      .then((response) => {
        return {
          ok: true,
          projectSettings: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  update(projectId, values) {
    const data = this.projectSettingsParams(values);
    return axios
      .put(`${this.baseUrl}/projects/${projectId}/project_settings`, data)
      .then((response) => {
        return {
          ok: true,
          projectSettings: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  projectSettingsParams(values) {
    return {
      project_settings: {
        multiple_floors: values.multipleFloors,
      },
    };
  }
}

export const projectSettingsApi = new ProjectSettingsApi();
