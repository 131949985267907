import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';

class InvoicesApi extends BaseApi {
  getInvoices(page, status) {
    let filter = '';
    if (status) {
      filter += `&${status}=true`;
    }

    return axios
      .get(
        `${this.baseUrl}/invoices?page=${
          page !== undefined ? page : 1
        }${filter}`
      )
      .then((response) => {
        return {
          ok: true,
          invoices: {
            content: response.data,
            total: numeral(response.headers['total']).value(),
            pageSize: numeral(response.headers['per-page']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getInvoice(invoiceId) {
    return axios
      .get(`${this.baseUrl}/invoices/${invoiceId}`)
      .then((response) => {
        return {
          ok: true,
          invoice: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  deleteInvoice(invoiceId) {
    return axios
      .delete(`${this.baseUrl}/invoices/${invoiceId}`)
      .then(() => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  sendInvoice(invoiceId) {
    return axios
      .put(`${this.baseUrl}/invoices/${invoiceId}/send_to_client`)
      .then((response) => {
        return {
          ok: true,
          invoice: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  markInvoiceAsPaid(invoiceId) {
    return axios
      .put(`${this.baseUrl}/invoices/${invoiceId}/paid`)
      .then((response) => {
        return {
          ok: true,
          invoice: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createInvoiceData(form, miscItemsIdToDelete = []) {
    const data = {
      invoice: {
        adjustment: form.adjustment,
        adjustment_desc: form.adjustmentDesc,
        due_date: form.dueDate,
        send_to: form.emails.map((value) => value.email),
        line_items_attributes: Object.keys(form.amountsByLineItemId).map(
          (key) => {
            return {
              id: key,
              cost: form.amountsByLineItemId[key],
            };
          }
        ),
      },
    };

    if (form.miscCosts.length > 0 || miscItemsIdToDelete.length > 0) {
      const attributes = [];
      form.miscCosts.forEach((misc) => {
        const data = {
          cost: misc.cost,
          description: misc.description,
        };
        if (misc.id > 0) {
          data['id'] = misc.id;
        }
        attributes.push(data);
      });

      miscItemsIdToDelete.forEach((idToDelete) => {
        attributes.push({
          id: idToDelete,
          _destroy: true,
        });
      });

      data['invoice']['misc_items_attributes'] = attributes;
    }
    return data;
  }

  updateInvoice(invoiceId, form, miscItemsIdToDelete) {
    const data = this.createInvoiceData(form, miscItemsIdToDelete);

    return axios
      .put(`${this.baseUrl}/invoices/${invoiceId}`, data)
      .then((response) => {
        return {
          ok: true,
          invoice: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getAsPdf(invoiceId) {
    return axios
      .get(`${this.baseUrl}/invoices/${invoiceId}/print`, {
        responseType: 'blob',
      })
      .then((response) => {
        return {
          ok: true,
          pdf: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  sendEmail(invoiceId, emails, subject, message) {
    const data = {
      invoice: {
        send_to: emails,
        subject: subject,
        message: message,
      },
    };
    return axios
      .put(`${this.baseUrl}/invoices/${invoiceId}/email`, data)
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export let invoicesApi = new InvoicesApi();
