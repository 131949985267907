import React from 'react';
import { connect } from 'react-redux';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';

import { getDarkMode } from 'selectors/theme';
import { getCurrentUser } from 'selectors/authentication';
import ParentsInstancesTable from './ParentsInstancesTable';
import {
  getProjectMeetingsParentsTabNavigation,
  getProjectNewMeetingsFilters,
  getTotalNewMeetings,
  getProjectNewMeetingsParents,
} from './store/selectors';
import './MeetingsTable.css';

const MeetingsTable = (props) => {
  const { headers, meetingsParents, handleFilterChange } = props;

  return (
    <React.Fragment>
      <div className="new-meeting-table-container">
        <ParentsInstancesTable
          className="new-meetings-table"
          emptyMessage="No Meetings"
          actionCells={1}
          stickyHeader={true}
          headers={headers}
          rows={{ data: meetingsParents }}
          onRowSelected={() => {}}
          handleFilterChange={handleFilterChange}
        />
      </div>
    </React.Fragment>
  );
};

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    tabNavigation: getProjectMeetingsParentsTabNavigation(state),
    currentFilters: getProjectNewMeetingsFilters(state),
    total: getTotalNewMeetings(state),
    meetingsParents: getProjectNewMeetingsParents(state),
    darkMode: getDarkMode(state),
  };
})(MeetingsTable);
