import {
  QUOTES_MODAL_CLOSE,
  QUOTES_PAGE_CHANGE,
  QUOTES_SELECTION_SUCCESS,
} from '../../../actions/admin/quotes/action-types';

const initialState = {
  currentPage: 1,
  selectedQuoteIndex: null,
  taskImages: [],
};

export default function quotesUiReducer(state = initialState, action) {
  switch (action.type) {
    case QUOTES_MODAL_CLOSE:
      return {
        ...state,
        selectedQuoteIndex: null,
        taskImages: [],
      };

    case QUOTES_PAGE_CHANGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case QUOTES_SELECTION_SUCCESS:
      return {
        ...state,
        selectedQuoteIndex: action.payload.quoteIndex,
        taskImages: action.payload.images,
      };

    default:
      return state;
  }
}
