import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './HyperlinksTable.css';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import removeSvg from 'images/deleteCross.svg';
import removeDarkSvg from 'images/deleteCrossDarkMode.svg';
import NavigationBar from 'components/shared/navigation/NavigationBar';
import SimpleTable from 'components/shared/table/SimpleTable';
import Input from 'components/shared/input/Input';
import { getDarkMode } from 'selectors/theme';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

class HyperlinksTable extends Component {
  static propTypes = {
    hyperlinks: PropTypes.arrayOf(PropTypes.string).isRequired,
    readOnly: PropTypes.bool,
    handleUpdate: PropTypes.func.isRequired,
    handleLoad: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      newHyperlink: '',
      newHyperlinkName: '',
      showRemoveConfirmation: false,
    };
  }

  onNewHyperlinkChange = (hyperlink) => {
    this.setState({ newHyperlink: hyperlink });
  };

  onNewHyperlinkNameChange = (hyperlinkName) => {
    this.setState({ newHyperlinkName: hyperlinkName });
  };

  addHyperlink = (e) => {
    e.preventDefault();
    const pattern = /^((http|https|ftp):\/\/)/;
    const { hyperlinks, handleUpdate } = this.props;
    const { newHyperlink, newHyperlinkName } = this.state;
    const hyperlinkPayload = [newHyperlink, newHyperlinkName];
    if (!pattern.test(newHyperlink)) {
      hyperlinkPayload[0] = `http://${newHyperlink}`;
    }

    handleUpdate([...hyperlinks, hyperlinkPayload]);
    this.onNewHyperlinkChange('');
    this.onNewHyperlinkNameChange('');
  };

  confirmRemoveHyperlink = (index) => {
    this.setState({ showRemoveConfirmation: true, index: index });
  };

  removeHyperlink = (confirmed) => {
    if (confirmed) {
      const { hyperlinks, handleUpdate } = this.props;
      const { index } = this.state;
      hyperlinks.splice(index, 1);
      handleUpdate(hyperlinks);
    }
    this.setState({ showRemoveConfirmation: false });
  };

  mapHyperlinks = () => {
    const { hyperlinks, readOnly, darkMode } = this.props;
    return hyperlinks
      .map((hyperlink, index) => {
        hyperlink = hyperlink.split(',');
        const name =
          hyperlink.length > 1 && hyperlink[1] ? hyperlink[1] : hyperlink[0];
        return (
          hyperlink && [
            <div className="hyperlink-viewer">
              {!readOnly && (
                <img
                  className="remove-doc-icon"
                  src={darkMode ? removeDarkSvg : removeSvg}
                  alt="remove"
                  onClick={() => this.confirmRemoveHyperlink(index)}
                />
              )}
              <a
                className="hyperlink-link"
                href={hyperlink[0]}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(event) => event.stopPropagation()}
              >
                {name}
              </a>
            </div>,
          ]
        );
      })
      .filter((row) => row);
  };

  render() {
    const { hyperlinks, readOnly, handleLoad, darkMode } = this.props;
    const {
      newHyperlink,
      newHyperlinkName,
      showRemoveConfirmation,
    } = this.state;
    return (
      <div
        className={`hyperlinks-table ${darkMode ? 'dark-mode' : ''} ${
          !readOnly ? 'can-remove-doc' : ''
        }`}
      >
        <NavigationBar
          items={[{ id: 1, title: 'Hyperlinks', total: hyperlinks.length }]}
          selectedValue={1}
          onSelected={handleLoad}
        />
        <div className="hyperlinks-table-container">
          <SimpleTable
            className="stretch-table"
            headers={['LINK']}
            rows={this.mapHyperlinks()}
            emptyMessage="No hyperlinks added yet."
          />
        </div>
        {!readOnly && (
          <form className="hyperlink-form" onSubmit={this.addHyperlink}>
            <Input
              placeholder="https://"
              value={newHyperlink}
              onChange={this.onNewHyperlinkChange}
            />
            <Input
              placeholder="Name to Display"
              value={newHyperlinkName}
              onChange={this.onNewHyperlinkNameChange}
            />
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.BLUE_GRAY}
              label="Add Link"
              disabled={!newHyperlink || !newHyperlinkName}
            />
          </form>
        )}
        {showRemoveConfirmation && (
          <ConfirmationModal
            show={showRemoveConfirmation}
            message="Are you sure you want to delete this hyperlink?"
            onHide={this.removeHyperlink}
          />
        )}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(HyperlinksTable);
