import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import FormModal from 'components/shared/modal/FormModal';
import SimpleTable from 'components/shared/table/SimpleTable';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import {
  setReviewCorrectionsModalShow,
  updateMeetingsParent,
} from './store/actions';
import {
  getActionItems,
  getMeetingsInstanceForm,
  getProjectNewMeetingsForm,
  getReviewCorrectionsModalShow,
} from './store/selectors';
import './CorrectionsSubmitModal.css';
import RadioButton from '../../../../shared/radio-button/RadioButton';

const MeetingsCorrectionsReview = (props) => {
  const { dispatch, show, form, project, currentUser } = props;
  const handleClose = () => {
    dispatch(setReviewCorrectionsModalShow(false));
  };
  const [corrections, setCorrections] = useState([]);
  useEffect(() => {
    setCorrections(form.meetingsCorrections);
  }, []);
  const parseDate = (date) => {
    const d = moment(date);
    return d.isValid() ? d.utc().format('MM/DD/YYYY') : '--';
  };
  const statusOptions = [
    { value: 'approved', label: 'Approve' },
    { value: 'rejected', label: 'Reject' },
  ];
  const handleStatusChange = (row, option) => {
    const editedRow = { ...row, status: option, resolvedById: currentUser.id };
    const editedRows = corrections.map((c) =>
      c.id === row.id ? editedRow : c
    );
    setCorrections(editedRows);
  };

  const correctionsHeaders = [
    'ITEM NO',
    'CORRECTION REMARK',
    'SUBMITTED BY',
    'DATE',
    'STATUS',
  ];

  const actions = (row) => {
    if (!row) return;
    const correctionRow = corrections.filter((c) => c.id === row.id);
    const correctionStatus =
      correctionRow && correctionRow.length > 0 ? correctionRow[0].status : '';
    return (
      <div className="corrections-action-status-container">
        <RadioButton
          options={statusOptions}
          onChange={(option) => {
            handleStatusChange(row, option);
          }}
          className={'approve-options'}
          orientation={'table'}
          value={correctionStatus}
        />
      </div>
    );
  };
  const getRow = (row) => {
    return {
      id: row.id,
      className: 'correction-row-style',
      selectable: true,
      data: [
        row.meetingCorrectionNo,
        row.correctionRemark,
        row.submittedBy && row.submittedBy.label,
        parseDate(row.createdAt),
        actions(row),
      ],
    };
  };

  const getRows = () => {
    if (!form || !form.meetingsCorrections) return;
    const unresolvedCorrections = form.meetingsCorrections.filter(
      (c) => !(c.status === 'approved' || c.status === 'rejected')
    );
    const rows = unresolvedCorrections.map((c) => getRow(c));
    return rows;
  };

  const handleSave = () => {
    const modifiedParentForm = { ...form, meetingsCorrections: corrections };
    dispatch(updateMeetingsParent(project.id, form.id, modifiedParentForm));
    dispatch(setReviewCorrectionsModalShow(false));
  };

  if (!show) return null;

  const titleComponent = <div className="search-bar-title">Item History</div>;

  return (
    <FormModal
      className={`review-corrections-modal`}
      show={show}
      onClose={handleClose}
      subTitle={'Meeting Minutes'}
      title={titleComponent}
    >
      <SimpleTable
        className="custom-table-style"
        emptyMessage={`No Corrections have been submitted`}
        headers={correctionsHeaders}
        actionCells={1}
        rows={getRows()}
        stickyHeader={true}
      />
      <div className="button-actions">
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.WHITE}
          label="Cancel"
          onClick={handleClose}
        />
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          label={'Save'}
          onClick={handleSave}
        />
      </div>
    </FormModal>
  );
};

export default connect((state) => ({
  show: getReviewCorrectionsModalShow(state),
  currentUser: getCurrentUser(state),
  project: getCurrentProject(state),
  actionItems: getActionItems(state),
  instanceForm: getMeetingsInstanceForm(state),
  form: getProjectNewMeetingsForm(state),
}))(MeetingsCorrectionsReview);
