import { showErrorMessage } from 'actions/messages';
import { projectSettingsApi } from 'services/api/project-settings-api';
import { SETTINGS_SET } from 'components/admin/projects/details/project-settings/store/action-types';

export function loadSettingsSuccess(settings) {
  return {
    type: SETTINGS_SET,
    payload: settings,
  };
}

export function loadSettings(projectId) {
  return (dispatch) => {
    return projectSettingsApi.show(projectId).then((response) => {
      if (response.ok) {
        dispatch(loadSettingsSuccess(response.projectSettings));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function updateSettings(projectId, values) {
  return (dispatch) => {
    return projectSettingsApi.update(projectId, values).then((response) => {
      if (response.ok) {
        dispatch(loadSettingsSuccess(response.projectSettings));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}
