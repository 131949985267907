import axios from 'axios';
import BaseApi from './base-api';
import { fileToDataUrl } from 'services/utils/files-util';

export class UsersApi extends BaseApi {
  removeUser(id) {
    return axios
      .delete(`${this.baseUrl}/users/${id}`)
      .then(() => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getUsers() {
    return axios
      .get(`${this.baseUrl}/users`)
      .then((response) => {
        return {
          ok: true,
          users: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getDraft() {
    return axios
      .get(`${this.baseUrl}/users/draft`)
      .then((response) => {
        return {
          ok: true,
          draft: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  async saveDraft(draft) {
    const data = {
      user: {
        draft: {
          ...draft,
          company: {
            ...draft.company,
            logo: draft.company.logo
              ? await fileToDataUrl(draft.company.logo.file)
              : null,
            trade_codes: JSON.stringify(draft.company.trade_codes),
            unions: JSON.stringify(draft.company.unions),
          },
        },
      },
    };

    return axios
      .put(`${this.baseUrl}/users/draft`, data)
      .then((response) => {
        return {
          ok: true,
          user: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getProfile() {
    return axios
      .get(`${this.baseUrl}/users/profile`)
      .then((response) => {
        return {
          ok: true,
          profile: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  registerUser(form) {
    const data = {
      user: {
        email: form.email,
        first_name: form.first_name,
        last_name: form.last_name,
        password: form.password,
        terms_and_conditions: true,
      },
    };

    return axios
      .post(`${this.baseUrl}/users/register`, data)
      .then((response) => {
        return {
          ok: true,
          user: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  addUser(
    firstName,
    lastName,
    email,
    phoneNumber = null,
    rolesId,
    title = null,
    buildingsId = null
  ) {
    const data = this.createAddEditUserData(
      firstName,
      lastName,
      email,
      phoneNumber,
      rolesId,
      title,
      buildingsId
    );

    return axios
      .post(`${this.baseUrl}/users`, data)
      .then((response) => {
        return {
          ok: true,
          user: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateUser(
    id,
    firstName,
    lastName,
    email,
    phoneNumber = null,
    rolesId,
    title = null,
    buildingId = null
  ) {
    const data = this.createAddEditUserData(
      firstName,
      lastName,
      email,
      phoneNumber,
      rolesId,
      title,
      buildingId
    );

    return axios
      .put(`${this.baseUrl}/users/${id}`, data)
      .then((response) => {
        return {
          ok: true,
          user: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  resendUserInvite(id) {
    const data = {
      id: id,
    };

    return axios
      .put(`${this.baseUrl}/users/${id}/resend_invite`, data)
      .then((response) => {
        return {
          ok: true,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createAddEditUserData(
    firstName,
    lastName,
    email,
    phoneNumber = null,
    rolesId,
    title = null,
    buildingsId = null
  ) {
    const data = {
      user: {
        email: email,
        first_name: firstName,
        last_name: lastName,
        role_ids: rolesId,
      },
    };

    if (phoneNumber !== null && phoneNumber !== '') {
      data.user['phone_number'] = phoneNumber;
    }

    if (title !== null && title !== '') {
      data.user['title'] = title;
    }

    if (buildingsId !== null) {
      data.user['building_ids'] = buildingsId;
    }

    return data;
  }

  updateTermsAndConditions(isAccepted) {
    return axios
      .put(`${this.baseUrl}/user/terms_and_conditions`, {
        user: { terms_and_conditions: isAccepted },
      })
      .then((response) => {
        return {
          ok: true,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  setTheme(theme) {
    return axios
      .put(`${this.baseUrl}/user/theme`, {
        user: { theme: theme },
      })
      .then((response) => {
        return {
          ok: true,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  resendInvite(userId) {
    return axios
      .put(`${this.baseUrl}/users/${userId}/resend_invite`, {
        id: userId,
      })
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
        };
      });
  }
}

export let usersApi = new UsersApi();
export default UsersApi = new UsersApi();
