import User from 'domain/user';
import Document from 'domain/document';

class DocumentPermissions {
  canManagePrivateFolders = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project) ||
    User.isClient(user, project);

  canViewPrivateFolders = (file, user, project) =>
    User.isAdminOrProjectSubscriber(user, project) ||
    Document.isDocumentFolderCreator(file, user);

  canViewSharedFolders = (file, user, project) =>
    this.canViewPrivateFolders(file, user, project) ||
    Document.isDocumentFolderSharedWithUser(file, user);

  canViewCount = (user, project) =>
    !Document.hasAdvancedDocuments(project) &&
    !User.isContractor(user, project);

  canDeleteFolders = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project) ||
    User.isArchitect(user, project);

  canRenameFolder = (file, user, project) =>
    Document.isDocumentFolder(file) &&
    (User.isAdminProjectSubscriberGcOar(user, project) ||
      (file.visibility === 'private' &&
        Document.isDocumentFolderCreator(file, user)) ||
      (file.visibility === 'public' &&
        Document.isDocumentFolderCreatorCompany(file, user)));

  canMoveFolder = (file, user, project) =>
    Document.isDocumentFolder(file) &&
    (User.isAdminProjectSubscriberGcOar(user, project) ||
      (file.visibility === 'private' &&
        Document.isDocumentFolderCreator(file, user)) ||
      (file.visibility === 'public' &&
        Document.isDocumentFolderCreatorCompany(file, user)));
}

export default new DocumentPermissions();
