import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import './ImageUpload.css';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import removeSvg from 'images/deleteCross.svg';
import removeDarkSvg from 'images/deleteCrossDarkMode.svg';
import editSvg from 'images/edit-pencil.svg';
import editDarkSvg from 'images/edit-pencil-dark.svg';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import FullScreenImageViewer from 'components/shared/image-upload/FullScreenImageViewer';
import { validateFile } from 'services/utils/files-util';
import FormModal from 'components/shared/modal/FormModal';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import convertHeicFile from '../../../services/utils/heic-converter';
import { showErrorMessage } from '../../../actions/messages';
import { imageRotation } from '../../../services/utils/images-util';

class ImageUpload extends Component {
  static propTypes = {
    extensions: PropTypes.arrayOf(PropTypes.string),
    images: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        preview: PropTypes.string,
        file: PropTypes.object,
        description: PropTypes.string,
      })
    ),
    min: PropTypes.number,
    max: PropTypes.number,
    notes: PropTypes.string,
    readOnly: PropTypes.bool,
    multiple: PropTypes.bool,
    visibleButton: PropTypes.bool,
    onUpload: PropTypes.func,
    onRemove: PropTypes.func,
    onImageChange: PropTypes.func,
    onEditImages: PropTypes.func,
  };

  static defaultProps = {
    extensions: [],
    images: [],
    min: 0,
    max: 0,
    notes: null,
    multiple: true,
    visibleButton: true,
    onEditImages: null,
  };

  constructor(props) {
    super(props);
    this.handleRotateImage = this.handleRotateImage.bind(this);
    this.state = {
      imageIndexToRemove: 0,
      imageIndexToEdit: 0,
      fullScreenUrl: '',
      showConfirmation: false,
      showEdit: false,
      showFullScreen: false,
      edit: null,
    };
  }

  handleAddPhotoClick = () => {
    this.fileUpload.click();
  };

  handleFullScreenOpen = (url, event) => {
    event.preventDefault();
    this.setState({
      fullScreenUrl: url,
      showFullScreen: true,
    });
  };

  handleFullScreenClose = () => {
    this.setState({
      showFullScreen: false,
    });
  };

  validateAndConvertFile = async (file) => {
    if (validateFile(file.name, this.props.extensions)) {
      const processedFile = await convertHeicFile(file);
      return processedFile;
    }
  };

  convertAllFiles = async (files) => {
    const failedFiles = [];
    const filesArray = [...files];
    const successFiles = await Promise.all(
      filesArray
        .map(async (file) => {
          try {
            return await this.validateAndConvertFile(file);
          } catch (_e) {
            failedFiles.push(file);
          }
        })
        .filter((file) => file)
    );
    return { failedFiles, successFiles };
  };

  handleNewImagesUpload = async (files) => {
    const conversionResult = await this.convertAllFiles(files);
    let { successFiles, failedFiles } = conversionResult;
    // Error message:
    const failedFileNames = failedFiles.map((file) => file.fileName);
    if (failedFiles && failedFiles.length > 0)
      // If there are any heic files that could not be converted
      this.props.showErrorMessage(
        `Error while converting files ${failedFileNames.join(', ')}`
      );

    if (this.props.max > 0) {
      // Remove extra files uploaded
      successFiles = successFiles.slice(0, this.props.max);
    }

    // Clear input to allow to upload the same file twice
    this.fileUpload.value = '';

    this.props.onUpload(successFiles);
  };

  handleRemoveRequest(index, event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      imageIndexToRemove: index,
      showConfirmation: true,
    });
  }

  handleEditRequest(index, event) {
    event.preventDefault();
    const { description } = this.props.images[index];
    this.setState({
      imageIndexToEdit: index,
      showEdit: true,
      edit: {
        description,
      },
    });
  }

  handleRemove = (result) => {
    if (result) {
      this.props.onRemove(this.state.imageIndexToRemove);
    }
    this.setState({
      imageIndexToRemove: 0,
      showConfirmation: false,
    });
  };

  handleEdit = (result) => {
    if (result)
      this.props.onImageChange(this.state.imageIndexToEdit, this.state.edit);

    this.setState({
      imageIndexToEdit: 0,
      showEdit: false,
      edit: null,
    });
  };

  handleRotateImage = async (degrees) => {
    if (this.state.isRotating) return;
    this.setState({ isRotating: true });
    const { images, onUpload, onRemove, onEditImages } = this.props;
    const { fullScreenUrl } = this.state;
    try {
      const rotationResult = await imageRotation(
        images,
        fullScreenUrl,
        degrees,
        onUpload,
        onRemove,
        onEditImages
      );
      if (onEditImages) {
        this.setState({ fullScreenUrl: rotationResult.rotatedImageUrl });
      }
      if (!onEditImages && rotationResult && rotationResult.rotatedImageUrl) {
        this.setState({
          fullScreenUrl: rotationResult.rotatedImageUrl,
        });
      }
    } catch (error) {
      console.error('Rotation result is invalid');
      return;
    } finally {
      this.setState({ isRotating: false });
    }
  };

  render() {
    const { edit } = this.state;

    const buttonLabel = this.props.multiple ? 'Add Photos' : 'Add Photo';
    let minMaxSpan = null;
    if (this.props.min > 0) {
      minMaxSpan = 'Minimum of ' + this.props.min;
    }

    if (this.props.max > 0) {
      if (this.props.min > 0) {
        minMaxSpan += ', maximum of ' + this.props.max;
      } else {
        minMaxSpan = 'Maximum of ' + this.props.max;
      }
    }

    if (minMaxSpan !== null) {
      minMaxSpan += '.';
    }

    return (
      <div className={`image-upload ${this.props.darkMode ? 'dark-mode' : ''}`}>
        <div className="preview-container">
          {this.props.images.map((image, index) => {
            if (image.preview !== undefined && image.preview !== null) {
              return this.getImageDiv(index, image.preview, {
                show: image.url !== null,
                url: image.url,
                id: image.id,
              });
            } else if (image.url !== undefined && image.url !== null) {
              return this.getImageDiv(index, image.url, {
                show: this.props.readOnly,
                url: image.url,
                id: image.id,
              });
            } else if (image.file !== undefined && image.file !== null) {
              return this.getImageDiv(index, image.file.img);
            } else {
              return null;
            }
          })}

          <ConfirmationModal
            show={this.state.showConfirmation}
            message="Are you sure?"
            onHide={this.handleRemove}
          />
          {this.state.showEdit ? (
            <FormModal
              className="image-upload-edit-form"
              show
              onClose={() => this.handleEdit(false)}
              subTitle="Edit Image Description"
            >
              <div className="form-block">
                <FormControlBlock
                  label={!!edit.description && 'Description'}
                  control={
                    <TextareaAutosizeWrapper
                      placeholder="Description"
                      initialValue={edit.description}
                      onChange={(value) =>
                        this.setState({ edit: { ...edit, description: value } })
                      }
                    />
                  }
                />
              </div>
              <div className="buttons-container">
                <Button
                  type={BUTTON_TYPES.CONFIRMATION}
                  color={BUTTON_COLORS.GREEN}
                  label="UPDATE"
                  onClick={() => this.handleEdit(true)}
                />
              </div>
            </FormModal>
          ) : null}
        </div>

        {this.props.visibleButton && !this.props.readOnly ? (
          <div className="image-upload-button-container">
            <div>
              <Button
                className="add-photos-button"
                type={BUTTON_TYPES.LINK}
                icon={BUTTON_ICONS.PLUS}
                disabled={
                  this.props.max > 0
                    ? this.props.images.length >= this.props.max
                    : false
                }
                label={buttonLabel}
                onClick={this.handleAddPhotoClick}
              />
            </div>

            {(minMaxSpan !== null || this.props.notes !== null) && (
              <div>
                <ul>
                  {this.props.notes !== null && (
                    <li>
                      <span>{this.props.notes}</span>
                    </li>
                  )}
                  {minMaxSpan !== null ? (
                    <li>
                      <span>{minMaxSpan}</span>
                    </li>
                  ) : null}
                </ul>
              </div>
            )}

            <input
              accept={
                this.props.extensions.length > 0
                  ? this.props.extensions
                  : 'image/*'
              }
              multiple={this.props.multiple}
              ref={(input) => {
                this.fileUpload = input;
              }}
              style={{ display: 'none' }}
              type="file"
              onChange={(event) => {
                event.preventDefault();
                this.handleNewImagesUpload(event.target.files);
              }}
            />
          </div>
        ) : null}

        <FullScreenImageViewer
          show={this.state.showFullScreen}
          url={this.state.fullScreenUrl}
          onClose={this.handleFullScreenClose}
          onImageRotate={this.handleRotateImage}
          readOnly={this.props.readOnly}
          isRotating={this.state.isRotating}
        />
      </div>
    );
  }

  /**
   *
   * @param index
   * @param source
   * @param fullScreenInfo  - { show: boolean, url: string }
   * @return {*}
   */
  getImageDiv(index, source, fullScreenInfo = null) {
    if (fullScreenInfo !== null && fullScreenInfo.show) {
      return (
        <div key={index}>
          <img
            className="img-full-screen"
            src={source}
            alt={''}
            onClick={this.handleFullScreenOpen.bind(this, fullScreenInfo.url)}
          />
        </div>
      );
    } else {
      return (
        <div key={index}>
          <img
            className="img-full-screen"
            src={source}
            alt={''}
            onClick={this.handleFullScreenOpen.bind(this, fullScreenInfo.url)}
          />
          {(this.props.onRemove !== undefined || this.props.onImageChange) && (
            <div
              className="remove-container"
              onClick={this.handleFullScreenOpen.bind(this, fullScreenInfo.url)}
            >
              {this.props.onImageChange !== undefined && (
                <img
                  className="edit-image"
                  src={this.props.darkMode ? editDarkSvg : editSvg}
                  alt=""
                  onClick={this.handleEditRequest.bind(this, index)}
                />
              )}
              {this.props.onRemove !== undefined && (
                <img
                  className="remove-image"
                  src={this.props.darkMode ? removeDarkSvg : removeSvg}
                  alt=""
                  onClick={this.handleRemoveRequest.bind(this, index)}
                />
              )}
            </div>
          )}
        </div>
      );
    }
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    showErrorMessage: (message) => dispatch(showErrorMessage(message)),
    // your other dispatch props here
  };
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
}, mapDispatchToProps)(ImageUpload);
