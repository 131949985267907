import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './RfiModal.css';
import { initRfiModal } from 'components/admin/projects/details/project-rfi-log/store/actions';
import FormModal from 'components/shared/modal/FormModal';
import RfiForm from 'components/admin/projects/details/project-rfi-log/RfiForm';
import RfiQuestions from 'components/admin/projects/details/project-rfi-log/RfiQuestions';
import RfiActions from 'components/admin/projects/details/project-rfi-log/RfiActions';
import RfiDetails from 'components/admin/projects/details/project-rfi-log/RfiDetails';
import RfiQuestionNotes from 'components/admin/projects/details/project-rfi-log/RfiQuestionNotes';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import { setRfiPdfViewer } from 'components/admin/projects/details/project-rfi-log/store/actions';
import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';
import moment from 'moment';
import { downloadFile } from 'services/utils/download-util';
import RfiPermissions from 'permissions/rfi-permissions';
import RfiResponseModal from './RfiResponseModal';
import RfiResponsesViewModal from './RfiResponsesViewModal';
class RfiModal extends Component {
  static propTypes = {
    currentUser: PropTypes.shape({}).isRequired,
    contribType: PropTypes.string.isRequired,
    project: PropTypes.shape({}).isRequired,
    directory: PropTypes.shape({}).isRequired,
    number: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    show: PropTypes.bool.isRequired,
    currentRfi: PropTypes.shape({}),
    readOnly: PropTypes.bool,
    gcAcceleration: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    props.dispatch(initRfiModal());
  }

  componentDidUpdate(prevProps) {
    const { show, currentRfi, history, match } = this.props;
    const { projectId, subsection, itemId } = match.params;

    if (show && !prevProps.show && currentRfi && currentRfi.id) {
      if (itemId !== currentRfi.id.toString()) {
        history.replace(
          `/admin/projects/${projectId}/rfis/${subsection}/${currentRfi.id}`
        );
      }
    }

    if (!show && prevProps.show) {
      if (itemId) {
        history.replace(`/admin/projects/${projectId}/rfis/${subsection}`);
      }
    }
  }

  handleViewPdf = () => {
    this.props.dispatch(setRfiPdfViewer(true));
  };

  handleShowEmailModal = () => {
    this.props.dispatch(setShowEmailModal(true));
  };

  hasPdf = () => {
    const {
      currentRfi: { pdf_document },
    } = this.props;
    return pdf_document && pdf_document.document && pdf_document.document.url
      ? true
      : false;
  };

  handleDownloadPdf = () => {
    const { currentRfi, project } = this.props;
    const { number, pdf_document } = currentRfi;
    const file = pdf_document.document.url + `?time=${moment().unix()}`;
    const fileName = `${project.name} - RFI ${number ? number : 'Draft'}`;
    downloadFile(file, fileName);
  };

  render() {
    const {
      project,
      directory,
      currentUser,
      currentRfi,
      contribType,
      number,
      show,
      readOnly,
      gcAcceleration,
      handleClose,
    } = this.props;

    const formReadonly =
      readOnly ||
      (currentRfi &&
        !RfiPermissions.canEdit(currentUser, project, directory, currentRfi));

    return (
      <FormModal
        className={`rfi-modal ${!currentRfi ? 'create-rfi' : ''}`}
        show={show}
        onClose={handleClose}
        subTitle={`${project.name} - RFI ${number}`}
        sidebar={<RfiQuestionNotes />}
        actions={[
          currentRfi && (
            <Button
              key="email"
              type={BUTTON_TYPES.LINK}
              color={BUTTON_COLORS.GREEN}
              label="Email"
              onClick={this.handleShowEmailModal}
            />
          ),
          currentRfi && this.hasPdf() && (
            <Button
              key="view_pdf"
              type={BUTTON_TYPES.LINK}
              color={BUTTON_COLORS.GREEN}
              label="View PDF"
              onClick={this.handleViewPdf}
            />
          ),
          currentRfi && this.hasPdf() && (
            <Button
              key="download_pdf"
              type={BUTTON_TYPES.LINK}
              color={BUTTON_COLORS.GREEN}
              label="Download"
              onClick={this.handleDownloadPdf}
            />
          ),
        ].filter((action) => action)}
      >
        <div id="rfi-body" className="rfi-body">
          {formReadonly ? (
            <RfiDetails currentRfi={currentRfi} />
          ) : (
            <RfiForm
              currentUser={currentUser}
              contribType={contribType}
              project={project}
              directory={directory}
              currentRfi={currentRfi}
            />
          )}

          <RfiQuestions
            currentUser={currentUser}
            contribType={contribType}
            project={project}
            directory={directory}
            currentRfi={currentRfi}
            readOnly={formReadonly}
            modalReadOnly={readOnly}
            gcAcceleration={gcAcceleration}
            showResponses={false}
            questionsReadOnly={false}
          />
        </div>

        <RfiActions
          currentUser={currentUser}
          contribType={contribType}
          project={project}
          directory={directory}
          currentRfi={currentRfi}
          modalReadOnly={readOnly}
          readOnly={formReadonly}
          gcAcceleration={gcAcceleration}
          handleClose={handleClose}
        />
        <RfiResponseModal
          rfi={currentRfi}
          currentUser={currentUser}
          contribType={this.props.contribType}
          readOnly={this.props.readOnly}
          handleResponseChange={this.handleRfiQuestionChange}
          handleQuestionChange={this.handleRfiQuestionChange}
          handleQuestionDeleteFile={this.handleRfiQuestionDeleteFile}
          assigneeChanged={this.assigneeChanged}
          resolvedChanged={this.resolvedChanged}
          loadContributors={this.loadContributors}
          handleDeleteRfi={this.handleDeleteRfi}
          directory={directory}
          gcAcceleration={gcAcceleration}
        />
        <RfiResponsesViewModal
          rfi={currentRfi}
          currentUser={currentUser}
          contribType={this.props.contribType}
          readOnly={this.props.readOnly}
          handleResponseChange={this.handleRfiQuestionChange}
          handleQuestionChange={this.handleRfiQuestionChange}
          handleQuestionDeleteFile={this.handleRfiQuestionDeleteFile}
          assigneeChanged={this.assigneeChanged}
          resolvedChanged={this.resolvedChanged}
          loadContributors={this.loadContributors}
          handleDeleteRfi={this.handleDeleteRfi}
          directory={directory}
        />
      </FormModal>
    );
  }
}

export default withRouter(connect()(RfiModal));
