import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import './SimplePagination.css';
import { Pagination } from 'react-bootstrap';
import GoToPage from './GoToPage';

const MINIMAL_PAGE_ITEM_COUNT = 7;

class SimplePagination extends Component {
  static propTypes = {
    currentPage: PropTypes.number,
    pageSize: PropTypes.number,
    total: PropTypes.number.isRequired,
    visiblePages: PropTypes.number, //odd greater than 6
    goToPage: PropTypes.bool,
    onPageChange: PropTypes.func,
  };

  static defaultProps = {
    currentPage: 1,
    pageSize: 25,
    visiblePages: 7,
    goToPage: true,
  };

  handlePrev = (disabled) => {
    if (!disabled) {
      if (this.props.onPageChange) {
        this.props.onPageChange(this.props.currentPage - 1);
      }
    }
  };

  handleNext = (disabled) => {
    if (!disabled) {
      if (this.props.onPageChange) {
        this.props.onPageChange(this.props.currentPage + 1);
      }
    }
  };

  handlePage = (pageNumber) => {
    if (this.props.onPageChange && this.props.currentPage !== pageNumber) {
      this.props.onPageChange(pageNumber);
    }
  };

  handleFirst = (disabled) => {
    if (!disabled) {
      this.props.onPageChange(1);
    }
  };

  handleLast = (disabled) => {
    if (!disabled) {
      this.props.onPageChange(this.numberOfPages);
    }
  };

  render() {
    const {
      currentPage,
      pageSize,
      total,
      visiblePages,
      goToPage,
      darkMode,
    } = this.props;

    this.numberOfPages = Math.ceil(total / pageSize);
    this.pages = this.createPages();

    const morePagesThanVisible = this.numberOfPages > visiblePages;
    return (
      <div className={`simple-pagination ${darkMode ? 'dark-mode' : ''}`}>
        <Pagination>
          <Pagination.Prev
            className="pagination-prev"
            disabled={currentPage === 1}
            onClick={this.handlePrev.bind(this, currentPage === 1)}
          />
          {this.pages.map((page, index) => {
            return typeof page === 'number' ? (
              <Pagination.Item
                key={index}
                active={page + 1 === currentPage}
                className="pagination-item"
                onClick={this.handlePage.bind(this, page + 1)}
              >
                {page + 1}
              </Pagination.Item>
            ) : (
              <Pagination.Ellipsis key={index} disabled={true} />
            );
          })}
          <Pagination.Next
            className="pagination-next"
            disabled={
              currentPage === this.numberOfPages || this.numberOfPages === 0
            }
            onClick={this.handleNext.bind(
              this,
              currentPage === this.numberOfPages || this.numberOfPages === 0
            )}
          />
        </Pagination>
        {morePagesThanVisible && goToPage && (
          <GoToPage
            numberOfPages={this.numberOfPages}
            onChange={(page) => this.handlePage(page)}
          />
        )}
      </div>
    );
  }

  createPages() {
    const { currentPage, visiblePages } = this.props;
    const totalPages = this.numberOfPages;

    if (visiblePages < MINIMAL_PAGE_ITEM_COUNT) {
      throw new Error(
        `Must allow at least ${MINIMAL_PAGE_ITEM_COUNT} page items`
      );
    }
    if (visiblePages % 2 === 0) {
      throw new Error(`Must allow odd number of page items`);
    }
    if (totalPages < visiblePages) {
      return [...new Array(totalPages).keys()];
    }

    const left = Math.max(
      0,
      Math.min(
        totalPages - visiblePages,
        currentPage - Math.floor(visiblePages / 2)
      )
    );
    const items = new Array(visiblePages);
    for (let i = 0; i < visiblePages; i += 1) {
      items[i] = i + left;
    }
    // replace non-ending items with placeholders
    if (items[0] > 0) {
      items[0] = 0;
      items[1] = 'prev-more';
    }
    if (items[items.length - 1] < totalPages - 1) {
      items[items.length - 1] = totalPages - 1;
      items[items.length - 2] = 'next-more';
    }
    return items;
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(SimplePagination);
