import axios from 'axios';
import BaseApi from './base-api';

class S3Api extends BaseApi {
  getSignedUrl(params, loadingDisabled = false) {
    const {
      name,
      contentType,
      model,
      identifier,
      subModel,
      prefix,
      advancedDocument = false,
    } = params;

    return axios
      .get(this.baseUrl + `/s3/signed_url`, {
        params: {
          name,
          contentType,
          model,
          subModel,
          identifier,
          prefix,
          advancedDocument,
        },
        loadingDisabled,
      })
      .then((data) => data)
      .catch((error) => console.error(error));
  }

  getMetadata(url, loadingDisabled = false) {
    return axios
      .head(url, { loadingDisabled })
      .then((response) => ({ ok: true, headers: response.headers }))
      .catch((error) => ({ ok: false, errors: 'File not found' }));
  }
}

export const s3Api = new S3Api();
