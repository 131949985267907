import React from 'react';
import { connect } from 'react-redux';
import ImageDescriptionUpload from 'components/shared/image-upload/ImageDescriptionUpload';
import DocumentUpload from 'components/shared/document-upload/DocumentUpload';
import DocumentLinkBluebeam from 'components/shared/document/DocumentLinkBluebeam';

const FieldReportFiles = (props) => {
  const { handleFormChange, form } = props;

  const InputDocuments = (
    <DocumentUpload
      onDrop={(docs) => {
        const report_documents = [...form.report_documents];
        docs.forEach((file) => {
          report_documents.push({ file, name: file.name });
        });
        handleFormChange({ report_documents });
      }}
    />
  );
  const InputDocumentsPreview = (
    <div className="document-upload-previews">
      {form.report_documents.map((doc, index) => (
        <DocumentLinkBluebeam
          key={doc.id || index}
          file={doc}
          onRemove={() => {
            const report_documents = form.report_documents.filter(
              (_, i) => i !== index
            );
            const deleted_report_documents = [
              ...form.deleted_report_documents,
              form.report_documents[index],
            ];
            handleFormChange({ report_documents, deleted_report_documents });
          }}
        />
      ))}
    </div>
  );

  const InputPhotos = (
    <ImageDescriptionUpload
      images={form.report_images}
      deletedImages={form.deleted_report_images}
      onChange={(report_images) => {
        handleFormChange({ report_images });
      }}
      onDelete={(report_images, deleted_report_images) => {
        handleFormChange({ report_images, deleted_report_images });
      }}
    />
  );

  return (
    <React.Fragment>
      <div>
        <div className="form-block">
          <label className="form-label">Documents</label>
          {InputDocuments}
          {InputDocumentsPreview}
        </div>
        <div className="form-block">
          <label className="form-label">Photos</label>
          {InputPhotos}
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect()(FieldReportFiles);
