import { createSelector } from 'reselect';

export const currentSurveySelector = createSelector(
  (state) => state.surveys.data.currentSurvey,
  (currentSurvey) => {
    return currentSurvey;
  }
);

export const paginationInfoSelector = createSelector(
  (state) => state.surveys.ui.paginationInfo,
  (paginationInfo) => {
    return paginationInfo;
  }
);

export const pendingTotalSelector = createSelector(
  (state) => state.surveys.ui.pendingTotal,
  (pendingTotal) => {
    return pendingTotal;
  }
);

export const showValidationMessageSelector = createSelector(
  (state) => state.surveys.ui.showValidationMessage,
  (showValidationMessage) => {
    return showValidationMessage;
  }
);

export const surveyFormSelector = createSelector(
  (state) => state.surveys.ui.surveyForm,
  (surveyForm) => {
    return surveyForm;
  }
);

export const surveysSelector = createSelector(
  (state) => state.surveys.data.surveys,
  (surveys) => {
    return surveys;
  }
);
