import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import './HtmlViewer.css';
import ImageButton from 'components/shared/button/ImageButton';
import closeSvg from 'images/close.svg';

export default function HtmlViewer(props) {
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const hasHtmlData = props.content && props.content.source;

  if (!hasHtmlData) return null;
  const renderHtml = () => {
    return { __html: props.content.source };
  };
  return (
    <Modal className="html-viewer-modal" show={!!hasHtmlData}>
      <Modal.Header>
        <div className="body-bold">Pdf Preview</div>
        <ImageButton alt="close" src={closeSvg} onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={renderHtml()} />
      </Modal.Body>
    </Modal>
  );
}

HtmlViewer.propTypes = {
  show: PropTypes.bool,
  content: PropTypes.shape({
    source: PropTypes.any,
    type: PropTypes.oneOf(['url', 'blob', 'html']),
  }),
  onClose: PropTypes.func,
};

HtmlViewer.defaultProps = {
  show: false,
  content: null,
};
