import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';

class CoiExpsApi extends BaseApi {
  getCoiExps(projectId, page = 1) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/coi_exps?page=${page}&per_page=8`
      )
      .then((response) => {
        return {
          ok: true,
          coiExps: response.data,
          coiExpsTotal: numeral(response.headers['total']).value(),
          coiExpsPageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getCoiExp(projectId, coiExpId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/coi_exps/${coiExpId}`)
      .then((response) => {
        return {
          ok: true,
          coiExp: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const coiExpsApi = new CoiExpsApi();
