import { createSelector } from 'reselect';

export const getDocumentation = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.documentation.data.documentation,
  (documentation) => {
    return documentation;
  }
);

export const getDocumentationCount = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.documentation.data.documentationCount,
  (documentationCount) => {
    return documentationCount;
  }
);

export const getAdvancedDocuments = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.documentation.data.advancedDocuments,
  (advancedDocuments) => {
    return advancedDocuments;
  }
);

export const getInitialPrefix = createSelector(
  (state) => {
    return state.projects.scenes.details.scenes.documentation.data
      .initialPrefix;
  },
  (initialPrefix) => {
    return initialPrefix;
  }
);

export const getIsUpdateValue = createSelector(
  (state) => state.projects.scenes.details.scenes.documentation.data.isUpdate,
  (isUpdate) => {
    return isUpdate;
  }
);
