import { COMPANY_TYPES } from 'domain/company-type-enum';

export const COMPANY_TYPE_HIERARCHY = {
  [COMPANY_TYPES.ARCHITECT]: 1,
  [COMPANY_TYPES.CONSULTANT]: 2,
  [COMPANY_TYPES.OWNER_AUTHORIZED_REP]: 3,
  [COMPANY_TYPES.GENERAL_CONTRACTOR]: 4,
  [COMPANY_TYPES.CONSTRUCTION_PROJECT_MANAGER]: 5,
  [COMPANY_TYPES.CONTRACTOR]: 6,
};

export function compareCompanyTypeHierarchy(a, b) {
  if (COMPANY_TYPE_HIERARCHY[a] < COMPANY_TYPE_HIERARCHY[b]) {
    return -1;
  }
  if (COMPANY_TYPE_HIERARCHY[a] > COMPANY_TYPE_HIERARCHY[b]) {
    return 1;
  }
  return 0;
}
