import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getDarkMode } from '../../selectors/theme';
import {
  addUnreadMessage,
  loadNotificationsRequest,
  markAllAsRead,
  markAsRead,
  markAsUnread,
  setCurrentPage,
  setShowLoader,
} from 'actions/notifications';
import { goToInvoice } from 'actions/admin/invoices/invoices';
import { goToContractor } from 'actions/admin/contractors/contractors';
import { goToPurchaseOrders } from 'components/admin/purchase-orders/store/actions';
import {
  NotificationTypes,
  getNotifiableType,
  getNotifiable,
} from 'domain/notification';
import { goToSurvey } from 'components/admin/surveys/store/actions';
import {
  getAccessToken,
  getClient,
  getCurrentUser,
} from 'selectors/authentication';
import NotificationViewer from 'components/header/NotificationViewer';
import NotificationsSocket from 'services/notifications-socket';
import {
  getCurrentPage,
  getNotifications,
  getShowLoader,
} from 'selectors/notifications';
import {
  goToWorkOrder,
  selectWorkOrderRequest,
} from 'actions/admin/work-orders/work-orders';
import { goToTask } from 'actions/admin/work-orders/details/details';
import { WorkOrderStatusEnum } from 'services/utils/work-order-status-enum';
import { goToClient } from 'actions/admin/clients/clients';
import { goToContractorInvoices } from 'components/admin/contractors-invoices/store/actions';
import { goToProjectDetails } from 'components/admin/projects/store/actions';
import moment from 'moment';
import User from 'domain/user';

class NotificationsContainer extends Component {
  static propTypes = {
    recentActivity: PropTypes.bool,
    notifications: PropTypes.shape(),
    currentPage: PropTypes.number,
    onPageChange: PropTypes.func,
  };

  static defaultProps = {
    recentActivity: false,
    notifications: null,
    currentPage: null,
    onPageChange: null,
  };

  componentDidMount() {
    // Do not load notifications on recent activity.
    if (this.props.recentActivity) {
      return null;
    }

    this.props.dispatch(loadNotificationsRequest()).then((response) => {
      if (response.ok) {
        this.notificationsSocket = new NotificationsSocket(
          this.props.currentUser.uid,
          this.props.accessToken,
          this.props.client,
          this.handleOnUpdate
        );

        this.notificationsSocket.subscribe({ channel: 'NotificationsChannel' });
      }
    });
  }

  componentWillUnmount() {
    if (this.notificationsSocket) {
      this.notificationsSocket.disconnect();
    }
  }

  render() {
    let pageSize = 0;
    let total = 0;
    let totalUnread = 0;
    let messages = [];
    if (this.props.notifications !== null) {
      pageSize = this.props.notifications.pageSize;
      total = this.props.notifications.total;
      totalUnread = this.props.notifications.totalUnread;
      messages = this.props.notifications.content
        .map((message) => {
          return NotificationsContainer.mapMessage(message);
        })
        .filter((message) => message);
    }

    return (
      <NotificationViewer
        recentActivity={this.props.recentActivity}
        currentPage={this.props.currentPage}
        currentProject={this.props.currentProject}
        pageSize={pageSize}
        messages={messages}
        showLoader={this.props.showLoader}
        total={total}
        totalUnread={totalUnread}
        onMessageOpen={this.handleMessageOpen}
        onMessageRead={this.handleMessageRead}
        onMessageUnread={this.handleMessageUnread}
        onMarkAllAsRead={this.handleMarkAllAsRead}
        onShowNewer={this.handleShowNewer}
        onShowOlder={this.handleShowOlder}
        onPageChange={this.props.onPageChange || this.handlePageChange}
        title={this.props.title}
        darkMode={this.props.darkMode}
      />
    );
  }

  handleOnUpdate = (data) => {
    if (data !== undefined && data.notification !== undefined) {
      this.props.dispatch(
        addUnreadMessage(
          data.notification,
          this.props.notifications.total,
          this.props.notifications.totalUnread
        )
      );
    }
  };

  handleMessageOpen = (id) => {
    const message = this.props.notifications.content.find(
      (message) => message.id === id
    );
    if (message !== undefined) {
      if (message.read_at === null) {
        this.props.dispatch(
          markAsRead(message.id, this.props.notifications.totalUnread)
        );
      }
      this.goToMessageTarget(message);
    }
  };

  handleMessageRead = (id) => {
    const message = this.props.notifications.content.find(
      (message) => message.id === id
    );
    if (message !== undefined) {
      this.props.dispatch(
        markAsRead(message.id, this.props.notifications.totalUnread)
      );
    }
  };

  handleMessageUnread = (id) => {
    const message = this.props.notifications.content.find(
      (message) => message.id === id
    );
    if (message !== undefined) {
      this.props.dispatch(
        markAsUnread(message.id, this.props.notifications.totalUnread)
      );
    }
  };

  handleMarkAllAsRead = () => {
    this.props.dispatch(markAllAsRead());
  };

  handlePageChange = (newPage) => {
    this.props.dispatch(setShowLoader(true));
    this.props.dispatch(loadNotificationsRequest(newPage)).then((response) => {
      this.props.dispatch(setShowLoader(false));
      if (response.ok) {
        this.props.dispatch(setCurrentPage(newPage));
      }
    });
  };

  handleShowNewer = () => {
    this.props.dispatch(setShowLoader(true));
    this.props
      .dispatch(loadNotificationsRequest(this.props.currentPage - 1))
      .then((response) => {
        this.props.dispatch(setShowLoader(false));
        if (response.ok) {
          this.props.dispatch(setCurrentPage(this.props.currentPage - 1));
        }
      });
  };

  handleShowOlder = () => {
    this.props.dispatch(setShowLoader(true));
    this.props
      .dispatch(loadNotificationsRequest(this.props.currentPage + 1))
      .then((response) => {
        this.props.dispatch(setShowLoader(false));
        if (response.ok) {
          this.props.dispatch(setCurrentPage(this.props.currentPage + 1));
        }
      });
  };

  goToMessageTarget = (message) => {
    const notifiableType = getNotifiableType(message);
    const notifiable = getNotifiable(message);

    if (notifiableType === NotificationTypes.WORK_ORDER) {
      if (
        WorkOrderStatusEnum.DRAFT.equals(
          WorkOrderStatusEnum.create(notifiable.status)
        )
      ) {
        this.props.dispatch(
          selectWorkOrderRequest(notifiable.id, notifiable.status)
        );
      } else {
        this.props.dispatch(goToWorkOrder(notifiable.id));
      }
    } else if (notifiableType === NotificationTypes.TASK) {
      this.props.dispatch(goToTask(notifiable.work_order_id, notifiable.id));
    } else if (notifiableType === NotificationTypes.INVOICE) {
      this.props.dispatch(goToInvoice(notifiable.id));
    } else if (notifiableType === NotificationTypes.CONTRACTOR) {
      this.props.dispatch(goToContractor(notifiable.id));
    } else if (notifiableType === NotificationTypes.PURCHASE_ORDER) {
      this.props.dispatch(goToPurchaseOrders());
    } else if (notifiableType === NotificationTypes.SURVEY) {
      this.props.dispatch(goToSurvey(notifiable.id));
    } else if (notifiableType === NotificationTypes.CLIENT) {
      this.props.dispatch(goToClient(notifiable.id));
    } else if (notifiableType === NotificationTypes.CONTRACTOR_INVOICE) {
      this.props.dispatch(goToContractorInvoices());
    } else if (notifiableType === NotificationTypes.PROJECT) {
      this.props.dispatch(goToProjectDetails(notifiable.id));
    } else if (notifiableType === NotificationTypes.MEETING) {
      this.props.dispatch(
        goToProjectDetails(notifiable.project_id, { meetingId: notifiable.id })
      );
    } else if (notifiableType === NotificationTypes.RFI) {
      this.props.dispatch(
        goToProjectDetails(notifiable.project_id, { rfiId: notifiable.id })
      );
    } else if (notifiableType === NotificationTypes.RFA) {
      this.props.dispatch(
        goToProjectDetails(notifiable.project_id, { rfaId: notifiable.id })
      );
    } else if (notifiableType === NotificationTypes.SCHEDULE_CATEGORY) {
      this.props.dispatch(
        goToProjectDetails(notifiable.project_id, {
          schedule: true,
          categoryId: notifiable.id,
        })
      );
    } else if (notifiableType === NotificationTypes.SUBMITTAL) {
      this.props.dispatch(
        goToProjectDetails(notifiable.project_id, {
          submittalId: notifiable.id,
        })
      );
    } else if (notifiableType === NotificationTypes.PROJECT_OPEN_ITEM) {
      this.props.dispatch(
        goToProjectDetails(notifiable.project_id, {
          projectOpenItemId: notifiable.id,
          projectOpenItemCritical: notifiable.critical,
        })
      );
    } else if (notifiableType === NotificationTypes.SUB_DAILY_REPORT) {
      this.props.dispatch(
        goToProjectDetails(notifiable.project_id, {
          dailyReportId: notifiable.id,
          subDailyReport: true,
        })
      );
    } else if (notifiableType === NotificationTypes.DOCUMENT_NOTIFICATION) {
      window.open(notifiable.document_url, '_blank');
    } else if (notifiableType === NotificationTypes.COI_EXP) {
      this.props.dispatch(
        goToProjectDetails(notifiable.project_id, { documentation: true })
      );
    } else if (notifiableType === NotificationTypes.FIELD_REPORT) {
      this.props.dispatch(
        goToProjectDetails(notifiable.project_id, {
          fieldReports: true,
          reportId: notifiable.id,
        })
      );
    } else if (notifiableType === NotificationTypes.MEETINGS_PARENT) {
      this.props.dispatch(
        goToProjectDetails(notifiable.project_id, {
          meetingsParent: true,
          meetingsParentId: notifiable.id,
        })
      );
    } else if (notifiableType === NotificationTypes.MEETINGS_INSTANCE) {
      this.props.dispatch(
        goToProjectDetails(notifiable.project_id, {
          meetingsInstance: true,
          meetingsInstanceId: notifiable.id,
        })
      );
    }
  };

  static mapMessage(message) {
    const notifiableType = getNotifiableType(message);
    const notifiable = getNotifiable(message);
    if (!notifiable) return null;

    const { user } = message;
    const data = {
      id: message.id,
      message: message.action,
      unread: message.read_at === null,
      content: message.content,
      createdAt: message.created_at,
      project: notifiable.project_name,
    };

    if (user) {
      data['user'] = User.fullNameWithCompany(user);
    }

    if (notifiableType === NotificationTypes.WORK_ORDER) {
      data['target'] = `WO #${notifiable.number}`;
    } else if (notifiableType === NotificationTypes.TASK) {
      data['target'] = `Task #${notifiable.number} WO #${notifiable.wo_number}`;
    } else if (notifiableType === NotificationTypes.INVOICE) {
      data['target'] = `Invoice WO #${notifiable.wo_number}`;
    } else if (notifiableType === NotificationTypes.CONTRACTOR) {
      data['target'] = `${notifiable.name}`;
    } else if (notifiableType === NotificationTypes.PURCHASE_ORDER) {
      data['target'] = `PO #${notifiable.number}`;
    } else if (notifiableType === NotificationTypes.SURVEY) {
      data['target'] = `Survey WO #${notifiable.wo_number}`;
    } else if (notifiableType === NotificationTypes.CLIENT) {
      data['target'] = `${notifiable.name}`;
    } else if (notifiableType === NotificationTypes.CONTRACTOR_INVOICE) {
      data['target'] = `WO #${notifiable.work_order_number}`;
    } else if (notifiableType === NotificationTypes.PROJECT) {
      data['target'] = `Project ${notifiable.name}`;
    } else if (notifiableType === NotificationTypes.MEETING) {
      data['target'] = `Meeting ${moment(notifiable.meeting_date).format(
        'L'
      )} - ${notifiable.project_name}`;
    } else if (notifiableType === NotificationTypes.RFI) {
      data[
        'target'
      ] = `RFI #${notifiable.number} - ${notifiable.project_name} - ${notifiable.title}`;
    } else if (notifiableType === NotificationTypes.RFA) {
      data[
        'target'
      ] = `RFA #${notifiable.number} - ${notifiable.project_name} - ${notifiable.request_title}`;
    } else if (notifiableType === NotificationTypes.FIELD_REPORT) {
      data[
        'target'
      ] = `Field Report #${notifiable.number} - ${notifiable.project_name} - ${notifiable.title}`;
    } else if (notifiableType === NotificationTypes.SCHEDULE_CATEGORY) {
      data['target'] = `Schedule ${notifiable.name}`;
    } else if (notifiableType === NotificationTypes.SUBMITTAL) {
      data[
        'target'
      ] = `Submittal ${notifiable.division}-${notifiable.number} - ${notifiable.project_name} - ${notifiable.title}`;
    } else if (notifiableType === NotificationTypes.PROJECT_OPEN_ITEM) {
      data[
        'target'
      ] = `Open Item #${notifiable.number} - ${notifiable.project_name}`;
    } else if (notifiableType === NotificationTypes.ORDER) {
      data[
        'target'
      ] = `Order #${notifiable.reference_number} - ${notifiable.project_name}`;
      data['message'] = `${data['message']}. Check your mobile app.`;
      data['webAvailable'] = false;
    } else if (notifiableType === NotificationTypes.PAR) {
      data['target'] = `PAR ${moment(notifiable.created_at).format('L')} - ${
        notifiable.project_name
      }`;
      data['message'] = `${data['message']}. Check your mobile app.`;
      data['webAvailable'] = false;
    } else if (notifiableType === NotificationTypes.SUB_DAILY_REPORT) {
      data['target'] = `Sub Daily Report ${moment(
        notifiable.report_date
      ).format('L')} - ${notifiable.project_name}`;
    } else if (notifiableType === NotificationTypes.DOCUMENT_NOTIFICATION) {
      data['target'] = notifiable.name;
    } else if (notifiableType === NotificationTypes.COI_EXP) {
      data['target'] = `Project ${notifiable.project_name}`;
    } else if (notifiableType === NotificationTypes.PROJECT_REPORT) {
      data['target'] = '';
    } else if (notifiableType === NotificationTypes.MEETINGS_PARENT) {
      data[
        'target'
      ] = `Meeting ${notifiable.title} - ${notifiable.project_name}`;
    } else if (notifiableType === NotificationTypes.MEETINGS_INSTANCE) {
      data['target'] = `Meeting ${
        notifiable.is_agenda ? 'Agenda' : 'Minutes'
      } ${notifiable.title} - ${notifiable.formatted_number}`;
    } else if (notifiableType === NotificationTypes.MEETINGS_ACTION_ITEM) {
      data['target'] = `Action Item ${notifiable.description}`;
    } else if (notifiableType === NotificationTypes.ACTION_ITEMS_UPDATE) {
      data['target'] = `Action Item ${notifiable.description}`;
    } else {
      data['target'] = 'unknown';
    }

    return data;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    accessToken: getAccessToken(state),
    client: getClient(state),
    currentPage: ownProps.currentPage || getCurrentPage(state),
    currentUser: getCurrentUser(state),
    notifications: ownProps.notifications || getNotifications(state),
    showLoader: getShowLoader(state),
    darkMode: getDarkMode(state),
  };
};

export default connect(mapStateToProps)(NotificationsContainer);
