import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Backdrop,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListSubheader,
  Typography,
} from '@mui/material';
import {
  faCheck,
  faClock,
  faExclamation,
} from '@fortawesome/fontawesome-free-solid';
import { useCounter, useMap, useQueue } from 'react-use';
import Icon from '@fortawesome/react-fontawesome';
import Button, { BUTTON_COLORS } from 'components/shared/button/Button';
import DocumentLink from 'components/shared/document/DocumentLink';
import { getDarkMode } from 'selectors/theme';
import { filesApi } from 'services/api/files-api';
import { previewImage } from 'services/utils/images-util';
import useDirectUpload from 'services/hooks/direct-upload';
import colors from 'styles/colors';

import './DirectUploadFiles.css';

const MAX_UPLOADING = 5;
// STATUSES
const STATUS = {
  INITIAL: 'INITIAL',
  WAITING: 'WAITING',
  UPLOADING: 'UPLOADING',
  FINISHED: 'FINISHED',
  ERROR: 'ERROR',
};

const DirectUploadFiles = ({
  files: filesToUpload,
  onComplete,
  model,
  identifier,
  darkMode,
}) => {
  const { directUpload } = useDirectUpload();
  const [files, setFiles] = useMap(
    filesToUpload.reduce((acc, f) => {
      const name = f.file.name;
      acc[name] = { ...f, status: STATUS.INITIAL, percent: 0, name };
      return acc;
    }, {})
  );
  const [count, setCount] = useCounter(0);
  const queue = useQueue();

  const uploadProgress = (f) => (pEvent) => {
    if (pEvent.isTrusted) {
      f.percent = (pEvent.loaded / pEvent.total) * 100;
      setFiles.set(f.name, f);
    }
  };

  const uploadFile = async (f) => {
    const subModel = f.subModel === 'doc' ? 'documents' : `${f.subModel}s`;
    const S3params = { model: `${model}s`, identifier, subModel };

    // upload photo
    const res = await directUpload(f.file, S3params, uploadProgress(f));
    if (!res.ok) {
      setError(f);
      return;
    }

    // create & upload thumbnail
    if (f.subModel === 'image') {
      const res = await previewImage(f.file, 215, 286);
      if (!res.ok) {
        setError(f);
        return;
      }

      const resPreview = await directUpload(res.file, S3params);
      if (!resPreview.ok) {
        setError(f);
        return;
      }
    }

    // create image or document
    const saved = await filesApi.create(
      model,
      f.subModel,
      identifier,
      f.name,
      f.label,
      f.description,
      f.position
    );

    if (!saved.ok) {
      setError(f);
      return;
    }

    f.object = saved.data;
    f.status = STATUS.FINISHED;
    setCount.dec();
  };

  const onFinish = () => onComplete();

  const allFinished = () =>
    Object.values(files).reduce(
      (acc, curr) => acc && curr.status === STATUS.FINISHED,
      true
    );

  const setError = (f) => {
    f.status = STATUS.ERROR;
    setCount.dec();
  };

  const hasError = () =>
    Object.values(files).reduce(
      (acc, curr) => acc || curr.status === STATUS.ERROR,
      false
    );

  const processUploadQueue = () => {
    if (queue.size > 0 && count < MAX_UPLOADING) {
      queue.first.status = STATUS.UPLOADING;

      queue.remove();
      setCount.inc();

      uploadFile(queue.first);
    } else if (queue.size === 0 && allFinished()) {
      onFinish();
    }
  };

  const onRetry = () => onStartUpload();

  const onStartUpload = () => {
    if (Object.keys(files).length > 0) {
      Object.values(files).forEach((f) => {
        if (f.status === STATUS.INITIAL || f.status === STATUS.ERROR) {
          f.status = STATUS.WAITING;
          queue.add(f);
        }
      });
    }
  };

  useEffect(() => processUploadQueue(), [queue.size, count]);
  useEffect(() => onStartUpload(), []);

  const getFileRightIcon = (f) => {
    if (f.status === STATUS.INITIAL) return null;
    if (f.status === STATUS.WAITING)
      return (
        <Icon
          icon={faClock}
          color={
            darkMode ? colors['white-main'] : colors['light-mode-black-tex']
          }
        />
      );
    if (f.status === STATUS.FINISHED)
      return (
        <Icon
          icon={faCheck}
          color={darkMode ? colors.neon : colors['primary-green']}
        />
      );
    if (f.status === STATUS.ERROR)
      return (
        <Icon
          icon={faExclamation}
          color={darkMode ? colors['chart-blue'] : colors['chart-red']}
        />
      );

    return (
      <Typography color="text.primary" fontWeight={600} component="span">
        {Math.round(f.percent)}%
      </Typography>
    );
  };

  return (
    <Backdrop open className="direct-upload-files">
      <Box className="content">
        <List>
          <ListSubheader>Uploading Files</ListSubheader>
          {Object.values(files).map((f, i) => (
            <React.Fragment key={f.name}>
              <Divider />
              <ListItem secondaryAction={getFileRightIcon(f)}>
                <DocumentLink
                  showIcon
                  readOnly
                  file={f.file}
                  iconPosition="left"
                />
              </ListItem>
            </React.Fragment>
          ))}
          <Divider />
        </List>

        {(hasError() || true) && (
          <Grid
            container
            spacing={2}
            paddingX={2}
            paddingY={1.5}
            className="buttons"
          >
            <Grid item xs={6}>
              <Button
                color={BUTTON_COLORS.GREEN}
                label="Retry Upload"
                onClick={onRetry}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                color={BUTTON_COLORS.WHITE}
                label="Cancel"
                onClick={onFinish}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Backdrop>
  );
};

DirectUploadFiles.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.shape().isRequired,
      subModel: PropTypes.oneOf(['image', 'doc']).isRequired,
      label: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  onComplete: PropTypes.func.isRequired,
  model: PropTypes.string.isRequired,
  identifier: PropTypes.number.isRequired,
};

DirectUploadFiles.defaultProps = {
  files: [],
};

export default connect((state) => ({ darkMode: getDarkMode(state) }))(
  DirectUploadFiles
);
