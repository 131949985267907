import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import MultipleSelector from 'components/shared/selector/MultipleSelector';

import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';

const ProjectPhotosFilterUsers = (props) => {
  const { value, onChange, directory } = props;

  const onFilter = (users) => onChange({ users });

  const userOptions = useMemo(() => {
    let options = [];
    if (directory && directory.project_contributors) {
      directory.project_contributors.forEach((c) => {
        c.users.forEach((u) => {
          if (!options.find(({ value }) => u.id === value)) {
            options.push({ value: u.id, label: u.label });
          }
        });
      });
    }
    return options;
  }, [directory]);

  return (
    <MultipleSelector
      onChange={onFilter}
      value={value || []}
      className="users-dropdown"
      placeholder="User"
      options={userOptions}
    />
  );
};

export default connect((state) => ({
  directory: getDirectory(state),
}))(ProjectPhotosFilterUsers);
