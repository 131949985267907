const keys = {};

export const TaskStatusKeys = {
  DRAFT: 'draft',
  RFP: 'rfp',
  PLM: 'plm',
  AGG: 'agg',
  WOA: 'woa',
  PRE: 'pre',
  WIP: 'wip',
  CMP: 'cmp',
  CLS: 'cls',
};

export class TaskStatusEnum {
  constructor(status) {
    this.status = status;
    keys[status] = this;
  }

  static get(status) {
    if (keys[status]) {
      return keys[status];
    }
    return null;
  }

  equals(value) {
    if (typeof value === 'string') {
      return this.status === value;
    } else if (typeof value === 'object' && value.hasOwnProperty('status')) {
      return this.status === value.status;
    } else {
      return false;
    }
  }
}

TaskStatusEnum.DRAFT = new TaskStatusEnum(TaskStatusKeys.DRAFT);
TaskStatusEnum.RFP = new TaskStatusEnum(TaskStatusKeys.RFP);
TaskStatusEnum.PLM = new TaskStatusEnum(TaskStatusKeys.PLM);
TaskStatusEnum.AGG = new TaskStatusEnum(TaskStatusKeys.AGG);
TaskStatusEnum.WOA = new TaskStatusEnum(TaskStatusKeys.WOA);
TaskStatusEnum.PRE = new TaskStatusEnum(TaskStatusKeys.PRE);
TaskStatusEnum.WIP = new TaskStatusEnum(TaskStatusKeys.WIP);
TaskStatusEnum.CMP = new TaskStatusEnum(TaskStatusKeys.CMP);
TaskStatusEnum.CLS = new TaskStatusEnum(TaskStatusKeys.CLS);
