import { showErrorMessage } from './messages';
import { notificationsApi } from '../services/api/notifications-api';

export const NOTIFICATIONS_ADD = 'NOTIFICATIONS_ADD';
export const NOTIFICATIONS_CURRENT_PAGE_SET = 'NOTIFICATIONS_CURRENT_PAGE_SET';
export const NOTIFICATIONS_SHOW_LOADER_SET = 'NOTIFICATIONS_SHOW_LOADER_SET';
export const NOTIFICATIONS_SET = 'NOTIFICATIONS_SET';
export const NOTIFICATIONS_TOTAL_UPDATE = 'NOTIFICATIONS_TOTAL_UPDATE';
export const NOTIFICATIONS_UPDATE = 'NOTIFICATIONS_UPDATE';
export const NOTIFICATIONS_UNREAD_TOTAL_UPDATE =
  'NOTIFICATIONS_UNREAD_TOTAL_UPDATE';
export const NOTIFICATIONS_NOTIFICATION_UPDATE =
  'NOTIFICATIONS_NOTIFICATION_UPDATE';
export const NOTIFICATIONS_CHAT_UPDATE = 'NOTIFICATIONS_CHAT_UPDATE';

export function addUnreadMessage(message, total, totalUnread) {
  return (dispatch) => {
    dispatch({
      type: NOTIFICATIONS_ADD,
      payload: message,
    });
    dispatch({
      type: NOTIFICATIONS_TOTAL_UPDATE,
      payload: total + 1,
    });
    dispatch(updateUnreadTotal(totalUnread + 1));
  };
}

export function loadNotificationsRequest(page) {
  return (dispatch) => {
    return notificationsApi.getNotifications(page).then((response) => {
      if (response.ok) {
        dispatch(setNotifications(response.notifications));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function markAllAsRead(workOrderId, notifiableId, notifiableType) {
  return (dispatch) => {
    return notificationsApi
      .markAllAsRead(workOrderId, notifiableId, notifiableType)
      .then((response) => {
        if (response.ok) {
          // Mark all returns all notifications
          dispatch(setNotifications(response.notifications));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function markAsRead(messageId, totalUnread) {
  return (dispatch) => {
    return notificationsApi.markAsRead(messageId).then((response) => {
      if (response.ok) {
        dispatch(updateNotification(response.notification));
        dispatch(updateUnreadTotal(totalUnread - 1));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function markAsUnread(messageId, totalUnread) {
  return (dispatch) => {
    return notificationsApi.markAsUnread(messageId).then((response) => {
      if (response.ok) {
        dispatch(updateNotification(response.notification));
        dispatch(updateUnreadTotal(totalUnread + 1));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function setCurrentPage(page) {
  return {
    type: NOTIFICATIONS_CURRENT_PAGE_SET,
    payload: page,
  };
}

export function setNotifications(notifications) {
  return {
    type: NOTIFICATIONS_SET,
    payload: notifications,
  };
}

export function setShowLoader(value) {
  return {
    type: NOTIFICATIONS_SHOW_LOADER_SET,
    payload: value,
  };
}

export function updateNotification(notification) {
  return {
    type: NOTIFICATIONS_NOTIFICATION_UPDATE,
    payload: notification,
  };
}

export function updateNotifications(notifications) {
  return {
    type: NOTIFICATIONS_UPDATE,
    payload: notifications,
  };
}

export function updateUnreadTotal(value) {
  return {
    type: NOTIFICATIONS_UNREAD_TOTAL_UPDATE,
    payload: value,
  };
}

export function markNotifiableNotificationsAsRead(
  notifiableId,
  notifiableType
) {
  return (dispatch) => {
    return notificationsApi
      .markAllAsRead(undefined, notifiableId, notifiableType)
      .then((response) => {
        if (response.ok) {
          // Mark all returns all notifications
          dispatch(updateNotifications(response.notifications.content));
          dispatch(updateNotificationsChat(notifiableId, notifiableType));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function updateNotificationsChat(id, type) {
  return {
    type: NOTIFICATIONS_CHAT_UPDATE,
    payload: { id, type },
  };
}
