import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Reports.css';
import { getReportResults } from 'selectors/admin/reports/reports';
import BaseReport from 'components/admin/reports/BaseReport';
import Date from 'components/shared/text/Date';
import Selector from 'components/shared/selector/Selector';
import ProjectOpenItemDetailsModal from 'components/admin/projects/details/project-open-items/ProjectOpenItemDetailsModal';
import { getExtraFilterValues } from 'selectors/admin/reports/reports';
import { changeExtraFilterValue } from 'actions/admin/reports/reports';
import moment from 'moment';
import { loadProjectRequest } from 'components/admin/projects/details/store/actions';
import {
  loadOpenItem,
  setOpenItemsCritical,
} from 'components/admin/projects/details/project-open-items/store/actions';
import Input from 'components/shared/input/Input';

class ProjectOpenItemsReport extends Component {
  constructor(props) {
    super(props);

    this.state = { showModal: false };
  }

  open = (projectOpenItem) => {
    const { dispatch } = this.props;
    this.setState({ showModal: true });
    dispatch(loadProjectRequest(projectOpenItem.project_id));
    dispatch(setOpenItemsCritical(projectOpenItem.critical));
    dispatch(loadOpenItem(projectOpenItem.project_id, projectOpenItem.id));
  };

  close = () => {
    this.setState({ showModal: false });
  };

  handleRowSelection = (index) => {
    const { reportResults } = this.props;
    const openItem = reportResults.data[index];
    this.open(openItem);
  };

  renderDescription = (desc) => {
    return `${desc.substring(0, 50)}${desc.length > 50 ? '...' : ''}`;
  };

  createTableRowsData = () => {
    if (this.props.reportResults !== null) {
      let projNumber = null;
      return this.props.reportResults.data.map((result, index) => {
        let first = result.project_number !== projNumber;
        const rowData = {
          id: index,
          selectable: true,
          data: [
            first ? result.project_number : '',
            first ? result.project_name : '',
            result.number,
            (result.csi_code && result.csi_code.division) || '00',
            result.assignor_label,
            result.assignee_label,
            result.description && this.renderDescription(result.description),
            <Date value={result.created_at} format="MM/DD/YYYY" />,
            result.resolve_by && (
              <Date value={result.resolve_by} format="MM/DD/YYYY" />
            ),
            result.responded_at && (
              <Date value={result.responded_at} format="MM/DD/YYYY" />
            ),
            result.draft
              ? 'Draft'
              : result.resolved
              ? 'Resolved'
              : result.critical
              ? 'Critical'
              : 'Pending',
          ],
        };
        if (!first) {
          rowData['className'] = 'no-top-border';
        }
        projNumber = result.project_number;
        return {
          rowData,
          first,
          workOrderId: result.project_id,
        };
      });
    } else {
      return [];
    }
  };

  handleExtraFilterValueChange = (attribute, value) => {
    const { dispatch } = this.props;
    dispatch(changeExtraFilterValue(attribute, value));
    if (value === 'past_due') {
      dispatch(changeExtraFilterValue('scope_args', [moment().format()]));
    } else {
      dispatch(changeExtraFilterValue('scope_args', []));
    }
  };

  extraFilters = () => {
    return [
      <Input
        placeholder="Assignor"
        value={this.props.extraFilterValues['assignor']}
        onChange={(value) =>
          this.handleExtraFilterValueChange('assignor', value)
        }
      />,
      <Input
        placeholder="Assignee"
        value={this.props.extraFilterValues['assignee']}
        onChange={(value) =>
          this.handleExtraFilterValueChange('assignee', value)
        }
      />,
      <Selector
        options={[
          { value: 'past_due', label: 'Past Due' },
          { value: 'unresolved', label: 'Pending' },
          { value: 'resolved', label: 'Resolved' },
        ]}
        clearable={true}
        placeholder="All"
        value={this.props.extraFilterValues['scope']}
        onChange={(newOption) =>
          this.handleExtraFilterValueChange(
            'scope',
            newOption ? newOption.value : ''
          )
        }
      />,
    ];
  };

  render() {
    const { showModal } = this.state;
    return (
      <React.Fragment>
        <BaseReport
          reportName="project_open_items_report"
          tableHeader={[
            'PROJECT NO',
            'PROJECT NAME',
            'ITEM NO',
            'COST CODE',
            'ASSIGNOR',
            'ASSIGNEE',
            'DESCRIPTION',
            'CREATED',
            'DUE',
            'RESPONDED',
            'STATUS',
          ]}
          tableRowsData={this.createTableRowsData()}
          title="Open Items Reports"
          onRowSelection={this.handleRowSelection}
          extraFilters={this.extraFilters()}
        />
        {showModal && <ProjectOpenItemDetailsModal onClose={this.close} />}
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    reportResults: getReportResults(state),
    extraFilterValues: getExtraFilterValues(state),
  };
})(ProjectOpenItemsReport);
