import moment from 'moment';
import {
  NOTIFICATIONS_FREQUENCY_WEEKDAYS,
  DEFAULT_FREQUENCY_TIME_SLOT,
} from 'domain/notification-settings';

export const dataToFormNotificationSetting = (data) => ({
  id: data.id,
  allowInApp: data.allow_in_app,
  allowEmail: data.allow_email,
  start: formatFrequencyTime(data.start),
  end: formatFrequencyTime(data.end),
  frequencyType: {
    value: data.frequency_type,
    label: data.frequency_type_formatted,
  },
  frequencies: NOTIFICATIONS_FREQUENCY_WEEKDAYS.map((wd) => {
    const frequencyData = data.notification_setting_frequencies.find(
      (f) => f.day_of_week === wd.value
    );

    if (typeof frequencyData !== 'undefined')
      return dataToFormNotificationSettingFrequency(frequencyData);

    return { ...wd, ...DEFAULT_FREQUENCY_TIME_SLOT, allow: false };
  }),
  subjects: [],
});

export const dataToFormNotificationSettingFrequency = (data) => ({
  id: data.id,
  allow: true,
  start: formatFrequencyTime(data.start),
  end: formatFrequencyTime(data.end),
  value: data.day_of_week,
  label: data.formatted_day_of_week,
});

export const formatFrequencyTime = (date) => {
  const time = moment.utc(date);
  const isMidnight = time.hours() === 23 && time.minutes() === 59;

  return {
    value: time.format('HH:mm:ss'),
    label: isMidnight ? 'Midnight' : time.format('hh:mm A'),
  };
};

export const timeToSave = (time) => moment(time, 'HH:mm:ss').format('HH:mm:ss');

export const formToFormData = (form) => {
  const data = new FormData();

  data.append('notification_setting[allow_in_app]', form.allowInApp);
  data.append('notification_setting[allow_email]', form.allowEmail);
  data.append('notification_setting[start]', timeToSave(form.start.value));
  data.append('notification_setting[end]', timeToSave(form.end.value));
  data.append('notification_setting[frequency_type]', form.frequencyType.value);

  form.frequencies.forEach((f, index) => {
    if (f.id || f.allow) {
      const key = 'notification_setting_frequencies_attributes';

      if (f.id) data.append(`notification_setting[${key}][${index}][id]`, f.id);
      if (!f.allow)
        data.append(`notification_setting[${key}][${index}][_destroy]`, true);

      data.append(
        `notification_setting[${key}][${index}][start]`,
        timeToSave(f.start.value)
      );
      data.append(
        `notification_setting[${key}][${index}][end]`,
        timeToSave(f.end.value)
      );
      data.append(
        `notification_setting[${key}][${index}][day_of_week]`,
        f.value
      );
    }
  });

  return data;
};
