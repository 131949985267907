import {
  RFIS_SET,
  RFIS_ADD,
  RFIS_UPDATE,
  RFIS_DELETE,
  CURRENT_RFI_SET,
  RFI_LOG_PDF_SET,
  RFI_ADDL_ASSIGNEE_RESPOND_SUCCESS,
} from 'components/admin/projects/details/project-rfi-log/store/action-types';

const initialState = {
  rfis: [],
  total: 0,
  currentPage: 1,
  currentStatus: 'not_pre',
  pageSize: 25,
  currentRfi: null,
  rfiLogPdf: null,
  dueDateFilter: 'all',
};

export default function projectRfiLogDataReducer(state = initialState, action) {
  switch (action.type) {
    case RFIS_SET:
      return {
        ...state,
        ...action.payload,
      };

    case RFIS_ADD:
      return {
        ...state,
        rfis: [action.payload, ...state.rfis],
      };

    case RFIS_UPDATE:
      return {
        ...state,
        rfis: state.rfis.map((rfi) => {
          if (rfi.id === action.payload.id) {
            return action.payload;
          } else {
            return rfi;
          }
        }),
      };

    case RFIS_DELETE:
      return {
        ...state,
        rfis: state.rfis.filter((rfi) => rfi.id !== action.payload),
      };

    case CURRENT_RFI_SET:
      return {
        ...state,
        currentRfi: action.payload,
      };

    case RFI_LOG_PDF_SET:
      return {
        ...state,
        rfiLogPdf: action.payload,
      };

    case RFI_ADDL_ASSIGNEE_RESPOND_SUCCESS:
      return {
        ...state,
        rfis: state.rfis.map((rfi) => {
          return {
            ...rfi,
            rfi_questions: rfi.rfi_questions.map((qus) => {
              return {
                ...qus,
                rfi_addl_assignees: qus.rfi_addl_assignees.map(
                  (addlAssignee) => {
                    if (addlAssignee.id === action.payload.id) {
                      return action.payload;
                    } else {
                      return addlAssignee;
                    }
                  }
                ),
              };
            }),
          };
        }),
      };

    default:
      return state;
  }
}
