import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import { Modal } from 'react-bootstrap';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import './Modal.css';

class ConfirmationModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    onHide: PropTypes.func.isRequired,
    subMessage: PropTypes.string,
  };

  handleYes = (event) => {
    event.preventDefault();
    this.props.onHide(true);
  };

  handleNo = (event) => {
    event.preventDefault();
    this.props.onHide(false);
  };

  render() {
    return (
      <Modal
        className={`basic-modal confirmation-modal${
          this.props.darkMode ? ' dark-mode' : ''
        }`}
        show={this.props.show}
      >
        {this.props.title && (
          <Modal.Header>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          <div className="messages-container">
            <div className="main-message">{this.props.message}</div>
            <div className="sub-message">{this.props.subMessage}</div>
          </div>
          <div className="buttons-container">
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              id="confirmation-modal-no"
              key="no"
              label="No"
              onClick={this.handleNo}
            />
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              id="confirmation-modal-yes"
              key="yes"
              label="Yes"
              onClick={this.handleYes}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(ConfirmationModal);
