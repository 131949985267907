export const MATERIAL_ORDER_DETAILS_FIELDS = [
  { id: 'color', label: 'Color' },
  { id: 'size', label: 'Size' },
  { id: 'height', label: 'Height' },
  { id: 'length', label: 'Length' },
  { id: 'width', label: 'Width' },
  { id: 'weight', label: 'Weight' },
  { id: 'color2', label: 'Color 2' },
  { id: 'size2', label: 'Size 2' },
  { id: 'height2', label: 'Height 2' },
  { id: 'length2', label: 'Length 2' },
  { id: 'width2', label: 'Width 2' },
  { id: 'weight2', label: 'Weight 2' },
];

export const MATERIAL_ORDER_IDS_FIELDS = [
  { id: 'item_no', label: 'Item No' },
  { id: 'barcode', label: 'Barcode' },
  { id: 'barcode_upc', label: 'Barcode UPC' },
  { id: 'barcode_ean', label: 'Barcode EAN' },
  { id: 'upc_a', label: 'UPC-A' },
  { id: 'ucc_13', label: 'UCC-13' },
  { id: 'ucc_8', label: 'UCC-8' },
  { id: 'asin', label: 'ASIN' },
  { id: 'model', label: 'Model' },
  { id: 'mpn', label: 'MPN' },
];

export const MATERIAL_ORDER_FEATURES_FIELDS = [
  { id: 'features', label: 'Features' },
  { id: 'ingredients', label: 'Ingredients' },
  { id: 'nutrition_facts', label: 'Nutrition Facts' },
  { id: 'Material', label: 'material' },
  { id: 'pattern', label: 'Pattern' },
  { id: 'age_group', label: 'Age Group' },
  { id: 'gender', label: 'Gender' },
  { id: 'contributors', label: 'Contributors' },
  { id: 'release_date', label: 'Release Date' },
  { id: 'category', label: 'Category (API)' },
  { id: 'stores', label: 'Stores' },
  { id: 'reviews', label: 'Reviews' },
  { id: 'energy_efficiency_rating', label: 'Energy Efficiency Rating' },
  { id: 'photos', label: 'Photos' },
  { id: 'photos2', label: 'Photos 2' },
];
