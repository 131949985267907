import { createSelector } from 'reselect';

export const getAllRecentActivity = createSelector(
  (state) => state.recentActivity.data.allRecentActivity,
  (allRecentActivity) => {
    return allRecentActivity;
  }
);

export const getSpeRecentActivity = createSelector(
  (state) => state.recentActivity.data.speRecentActivity,
  (speRecentActivity) => {
    return speRecentActivity;
  }
);

export const getSwRecentActivity = createSelector(
  (state) => state.recentActivity.data.swRecentActivity,
  (swRecentActivity) => {
    return swRecentActivity;
  }
);
