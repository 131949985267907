import React, { Component } from 'react';
import { connect } from 'react-redux';
import './NewProject.css';
import successSvg from './success.svg';
import successDarkSvg from './success-dark.svg';
import { getNewProjectId } from 'components/admin/projects/creation/store/selectors';
import { goToProjectDetails } from 'components/admin/projects/store/actions';
import { getDarkMode } from 'selectors/theme';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

class Success extends Component {
  handleContinue = () => {
    this.props.dispatch(goToProjectDetails(this.props.newProjectId));
  };

  render() {
    const { darkMode } = this.props;

    return (
      <div className="project-success">
        <div className="project-success-content">
          <img
            src={darkMode ? successDarkSvg : successSvg}
            className="success-check"
            alt="Success"
          />

          <h2>Project Created</h2>

          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label="Continue"
            onClick={this.handleContinue}
          />
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    newProjectId: getNewProjectId(state),
    darkMode: getDarkMode(state),
  };
})(Success);
