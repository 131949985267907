import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import './SingleRadioButton.css';

class SingleRadioButton extends Component {
  static propTypes = {
    className: PropTypes.string,
    option: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    }).isRequired,
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    valid: PropTypes.bool,
    darkMode: PropTypes.bool,
  };

  static defaultProps = {
    readOnly: false,
    valid: true,
    darkMode: false,
  };

  handleChange = (event) => {
    event.preventDefault();
    const { readOnly, onChange, option } = this.props;
    if (onChange && !readOnly) {
      onChange(option.value);
    }
  };

  render() {
    const {
      className,
      option,
      selectedValue,
      readOnly,
      valid,
      darkMode,
    } = this.props;

    return (
      <div
        className={
          `radio-button` +
          (className ? ` ${className}` : '') +
          (darkMode ? ' dark-mode' : '') +
          (!valid ? ' invalid' : '')
        }
        onClick={this.handleChange}
      >
        <div
          className={`radiobutton-control ${selectedValue ? 'checked' : ''} ${
            readOnly ? 'read-only' : ''
          }`}
        />
        <span>{option.label}</span>
      </div>
    );
  }
}

export default connect((state) => ({
  darkMode: getDarkMode(state),
}))(SingleRadioButton);
