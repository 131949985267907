import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ProjectOpenItemsReportModal.css';
import FormModal from 'components/shared/modal/FormModal';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getShowOpenItemsReportModal } from 'components/admin/projects/details/project-open-items/store/selectors';
import { closeOpenItemsReportModal } from 'components/admin/projects/details/project-open-items/store/actions';
import ProjectOpenItemsReport from 'components/admin/reports/ProjectOpenItemsReport';

class ProjectOpenItemsReportModal extends Component {
  handleModalClose = () => {
    const { dispatch } = this.props;
    dispatch(closeOpenItemsReportModal());
  };

  render() {
    const { currentProject, showOpenItemsReportModal } = this.props;
    return (
      showOpenItemsReportModal && (
        <React.Fragment>
          <FormModal
            className="project-open-items-report-modal"
            show={showOpenItemsReportModal}
            onClose={this.handleModalClose}
          >
            <ProjectOpenItemsReport project={currentProject} />
          </FormModal>
        </React.Fragment>
      )
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    showOpenItemsReportModal: getShowOpenItemsReportModal(state),
  };
})(ProjectOpenItemsReportModal);
