import axios from 'axios';
import BaseApi from './base-api';

class CloseoutCatsCompanyTypesApi extends BaseApi {
  getCloseoutCatsCompanyTypes(projectId) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/closeout/closeout_cats_company_types`
      )
      .then((response) => {
        return {
          ok: true,
          closeoutCatsCompanyTypes: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateCloseoutCatsCompanyType(projectId, catCompanyTypeId, applies) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = new FormData();
    data.append('closeout_cats_company_type[applies]', applies);

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/closeout/closeout_cats_company_types/${catCompanyTypeId}`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          closeoutCatsCompanyType: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const closeoutCatsCompanyTypesApi = new CloseoutCatsCompanyTypesApi();
