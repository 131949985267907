import { USER_ROLES, companyRoles, userRoleLabel } from './user-role-enum';
import { USER_STATUS } from './user-status-enum';
import { COMPANY_TYPES } from './company-type-enum';

class User {
  hasRole = (user, wantedRole) =>
    this.userRoles(user).some((role) => role.name === wantedRole);

  isAdmin = (user) => this.hasRole(user, USER_ROLES.SPACKLE_ADMIN);

  isSuperAdmin = (user) => this.hasRole(user, USER_ROLES.SUPERADMIN);

  isAccountAdmin = (user) => this.hasRole(user, USER_ROLES.ACCOUNT_ADMIN);

  isPendingConfirmation = ({ accountable }) =>
    accountable && !accountable.confirmed;

  isPendingTermsAndConditions = ({
    terms_and_conditions: termsAndConditions,
  }) => !termsAndConditions;

  userRoles = (user) => {
    if (!user || !user.roles) return [];

    const resourceType = this.companyId(user) ? 'Company' : null;

    return user.roles.filter((role) => role.resource_type === resourceType);
  };

  companyRole = (user) => companyRoles(this.userRoles(user))[0];

  companyRoleId = (user) => {
    const role = this.companyRole(user);
    return (role && role.id) || null;
  };

  companyRoleName = (user) => {
    const role = this.companyRole(user);
    return (role && role.name) || null;
  };

  companyRoleLabel = (user) => {
    const role = this.companyRoleName(user);
    return userRoleLabel(role);
  };

  companyRoleFullLabel = (user, empty = 'N/A') => {
    const roleLabel = this.companyRoleLabel(user) || empty;

    if (this.isAccountAdmin(user))
      return `${roleLabel} (${userRoleLabel(USER_ROLES.ACCOUNT_ADMIN)})`;

    if (this.isSuperAdmin(user))
      return `${roleLabel} (${userRoleLabel(USER_ROLES.SUPERADMIN)})`;

    return roleLabel;
  };

  fullName = ({ first_name: firstName, last_name: lastName }) =>
    `${firstName} ${lastName}`;

  fullNameWithTitle = (user) => {
    const fullName = this.fullName(user);
    if (!user.title) return fullName;

    return `${fullName} ${user.title}`;
  };

  companyName = (user) => {
    const company = user && (user.company || user.accountable);
    return (company && company.name) || '360Plus';
  };

  fullNameWithCompany = (user) =>
    `${this.fullName(user)} - ${this.companyName(user)}`;

  isContributorUser = (contributor, user) =>
    ((contributor && contributor.users) || []).some(
      (contributorUser) => contributorUser.id === user.id
    );

  userContributor = (directory, user) =>
    ((directory && directory.project_contributors) || []).find((contributor) =>
      this.isContributorUser(contributor, user)
    );

  userContributorType = (directory, user) => {
    const contributor = this.userContributor(directory, user);
    return contributor && contributor.contributor_type;
  };

  contributorType = (user, project) =>
    (user && user.contributor_type) ||
    (project && project.contributor_type) ||
    null;

  companyId = (user) => user && (user.accountable_id || user.company_id);

  isCompany = (user) => !!this.companyId(user);

  isSubscriber = ({ accountable }) =>
    (accountable && accountable.subscriber) || false;

  isAdminOrSubscriber = (user) => this.isAdmin(user) || this.isSubscriber(user);

  isProjectSubscriber = (user, project) =>
    !!project && this.companyId(user) === project.creator_company_id;

  isAdminOrProjectSubscriber = (user, project) =>
    this.isAdmin(user) || this.isProjectSubscriber(user, project);

  isAdminProjectSubscriberGcOar = (user, project) =>
    this.isAdminOrProjectSubscriber(user, project) ||
    this.isGcOar(user, project);

  isProjectSubscriberAccountAdmin = (user, project) =>
    this.isProjectSubscriber(user, project) && this.isAccountAdmin(user);

  isAdminOrProjectSubscriberAccountAdmin = (user, project) =>
    this.isAdmin(user) || this.isProjectSubscriberAccountAdmin(user, project);

  isContributorType = (user, project, wantedType) =>
    (this.contributorType(user, project) ||
      (!!user && user.original_company_type)) === wantedType;

  isArchitect = (user, project) =>
    this.isContributorType(user, project, COMPANY_TYPES.ARCHITECT);

  isConstructionProjectManager = (user, project) =>
    this.isContributorType(
      user,
      project,
      COMPANY_TYPES.CONSTRUCTION_PROJECT_MANAGER
    );

  isConsultant = (user, project) =>
    this.isContributorType(user, project, COMPANY_TYPES.CONSULTANT);

  isLandlord = (user, project) =>
    this.isContributorType(user, project, COMPANY_TYPES.LANDLORD);

  isPropertyManager = (user, project) =>
    this.isContributorType(user, project, COMPANY_TYPES.PROPERTY_MANAGER);

  isContractor = (user, project) =>
    this.isContributorType(user, project, COMPANY_TYPES.CONTRACTOR);

  isClient = (user, project) =>
    this.isContributorType(user, project, COMPANY_TYPES.CLIENT);

  isGeneralContractor = (user, project) =>
    this.isContributorType(user, project, COMPANY_TYPES.GENERAL_CONTRACTOR);

  isOar = (user, project) =>
    this.isContributorType(user, project, COMPANY_TYPES.OWNER_AUTHORIZED_REP);

  isGcOar = (user, project) =>
    this.isGeneralContractor(user, project) || this.isOar(user, project);

  isAdminGcOar = (user, project) =>
    this.isAdmin(user) || this.isGcOar(user, project);

  isPrimary = (user, project) =>
    !this.isAdminGcOar(user, project) && !this.isContractor(user, project);

  isUserStatus = ({ status }, wantedStatus) => status === wantedStatus;

  isActive = (user) => this.isUserStatus(user, USER_STATUS.ACTIVE);

  isInactive = (user) => this.isUserStatus(user, USER_STATUS.INACTIVE);

  isPendingInvitation = (user) =>
    this.isUserStatus(user, USER_STATUS.PENDING_INVITATION);

  isRegistering = (user) => this.isUserStatus(user, USER_STATUS.REGISTERING);

  isVerifying = (user) => this.isUserStatus(user, USER_STATUS.VERIFYING);

  isRejected = (user) => this.isUserStatus(user, USER_STATUS.REJECTED);

  isEnabled = (user) => this.isActive(user) || this.isInactive(user);

  isSameCompany = (user1, user2) =>
    !!user1 && !!user2 && this.companyId(user1) === this.companyId(user2);
}

export default new User();
