import {
  BUILDINGS_NEW_CLEAR_DATA,
  BUILDINGS_NEW_FORM_VALUE_CHANGE,
  BUILDINGS_NEW_FORM_INVALID,
  BUILDINGS_NEW_FORM_VALID,
  BUILDINGS_BUILDING_WORK_ORDERS_PAGE_CHANGE,
  BUILDINGS_EDIT_FORM_LOAD_FROM_CURRENT,
  BUILDINGS_SET_PAGINATION_DATA,
} from '../../../actions/admin/buildings/action-types';

const initialState = {
  currentPage: 1,
  engineerAdded: false,
  files: [], // array { removed: boolean, file: file uploaded, document: document from backend }
  form: {
    buildingName: '',
    streetAddress: '',
    unitNumber: '',
    city: '',
    state: '',
    zipCode: '',
    managerFirstName: '',
    managerLastName: '',
    managerEmail: '',
    managerPhoneNumber: '',
    engineerFirstName: '',
    engineerLastName: '',
    engineerEmail: '',
    engineerPhoneNumber: '',
  },
  invalidFields: [],
  parkingOnSite: null, // boolean
  selectedClientsId: [],
  selectedContractors: [], // array { value, label }
  selectedUnionsId: [],
  validationMessage: '',
  buildingsCurrentPage: 1,
  buildingsTotal: 0,
  buildingsPageSize: 25,
};

export default function buildingsUiReducer(state = initialState, action) {
  switch (action.type) {
    case BUILDINGS_BUILDING_WORK_ORDERS_PAGE_CHANGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case BUILDINGS_EDIT_FORM_LOAD_FROM_CURRENT:
      return {
        ...state,
        engineerAdded: action.payload.engineer !== null,
        files: action.payload.documents.map((document) => {
          return {
            removed: false,
            file: null,
            document: document,
          };
        }),
        form: {
          buildingName: action.payload.name,
          locationId: action.payload.location.id,
          streetAddress: action.payload.location.street_address_1,
          unitNumber:
            action.payload.location.street_address_2 !== null
              ? action.payload.location.street_address_2
              : '',
          city: action.payload.location.city,
          state: action.payload.location.state,
          zipCode: action.payload.location.zip,
          propOwnerName: action.payload.property_owner_name || '',
          propOwnerAddressId:
            action.payload.property_owner_address &&
            action.payload.property_owner_address.id,
          propOwnerStreetAddress:
            (action.payload.property_owner_address &&
              action.payload.property_owner_address.street_address_1) ||
            '',
          propOwnerUnitNumber:
            (action.payload.property_owner_address &&
              action.payload.property_owner_address.street_address_2) ||
            '',
          propOwnerCity:
            (action.payload.property_owner_address &&
              action.payload.property_owner_address.city) ||
            '',
          propOwnerState:
            (action.payload.property_owner_address &&
              action.payload.property_owner_address.state) ||
            '',
          propOwnerZipCode:
            (action.payload.property_owner_address &&
              action.payload.property_owner_address.zip) ||
            '',
          managerFirstName:
            action.payload.property_manager !== null
              ? action.payload.property_manager.first_name
              : '',
          managerLastName:
            action.payload.property_manager !== null
              ? action.payload.property_manager.last_name
              : '',
          managerEmail:
            action.payload.property_manager !== null
              ? action.payload.property_manager.email
              : '',
          managerPhoneNumber:
            action.payload.property_manager !== null &&
            action.payload.property_manager.phone_number
              ? action.payload.property_manager.phone_number
              : '',
          propManagerAddressId:
            action.payload.property_manager_address &&
            action.payload.property_manager_address.id,
          propManagerStreetAddress:
            (action.payload.property_manager_address &&
              action.payload.property_manager_address.street_address_1) ||
            '',
          propManagerUnitNumber:
            (action.payload.property_manager_address &&
              action.payload.property_manager_address.street_address_2) ||
            '',
          propManagerCity:
            (action.payload.property_manager_address &&
              action.payload.property_manager_address.city) ||
            '',
          propManagerState:
            (action.payload.property_manager_address &&
              action.payload.property_manager_address.state) ||
            '',
          propManagerZipCode:
            (action.payload.property_manager_address &&
              action.payload.property_manager_address.zip) ||
            '',
          engineerFirstName:
            action.payload.engineer !== null
              ? action.payload.engineer.first_name
              : '',
          engineerLastName:
            action.payload.engineer !== null
              ? action.payload.engineer.last_name
              : '',
          engineerEmail:
            action.payload.engineer !== null
              ? action.payload.engineer.email
              : '',
          engineerPhoneNumber:
            action.payload.engineer !== null &&
            action.payload.engineer.phone_number
              ? action.payload.engineer.phone_number
              : '',
        },
        invalidFields: [],
        parkingOnSite:
          action.payload.parking_on_site !== null
            ? action.payload.parking_on_site
            : null,
        selectedClientsId:
          action.payload.clients !== undefined
            ? action.payload.clients.map((client) => client.id)
            : [],
        selectedContractors: action.payload.propietary_sub_contractors.map(
          (contractor) => {
            return {
              value: contractor.id,
              label: contractor.name,
            };
          }
        ),
        selectedUnionsId: action.payload.unions.map((union) => {
          return union.id;
        }),
        validationMessage: '',
      };

    case BUILDINGS_NEW_CLEAR_DATA:
      return initialState;

    case BUILDINGS_NEW_FORM_INVALID:
      return {
        ...state,
        invalidFields: action.payload.invalidFields,
        validationMessage: action.payload.message,
      };

    case BUILDINGS_NEW_FORM_VALID:
      return {
        ...state,
        invalidFields: [],
        validationMessage: '',
      };

    case BUILDINGS_NEW_FORM_VALUE_CHANGE:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case BUILDINGS_SET_PAGINATION_DATA:
      return {
        ...state,
        buildingsCurrentPage: action.payload.page,
        buildingsTotal: action.payload.total,
        buildingsPageSize: action.payload.pageSize,
      };

    default:
      return state;
  }
}
