import React from 'react';
import TimePicker from 'components/shared/time-picker/TimePicker';

/**
 * Wrapper for DateTime picker field used inside redux-form.
 * @param props
 * @return {*}
 * @constructor
 */
export default function FormInput(props) {
  return (
    <TimePicker
      valid={!props.meta.error || !props.meta.submitFailed}
      value={props.input.value}
      onChange={(value) => props.input.onChange(value)}
      {...props}
    />
  );
}
