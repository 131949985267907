import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'components/admin/projects/details/project-directory/SelectUsersForm.css';
import { Form } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import MultipleSelectList from 'components/shared/select/MultipleSelectList';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import User from 'domain/user';

class SelectUsersForm extends Component {
  static propTypes = {
    users: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  renderMultipleSelect = (props) => {
    return (
      <MultipleSelectList
        options={this.props.users.map((user) => {
          return {
            id: user.id,
            selected: props.input.value.includes(user.id),
            value: User.fullNameWithTitle(user),
          };
        })}
        show={5}
        onSelect={(id) => this.handleUserSelection(props, id)}
      />
    );
  };

  handleUserSelection = (props, id) => {
    if (props.input.value.includes(id)) {
      props.input.onChange(props.input.value.filter((item) => item !== id));
    } else {
      props.input.onChange(props.input.value.concat(id));
    }
  };

  render() {
    const { onClose } = this.props;
    return (
      <div className="select-users-form">
        <Form horizontal onSubmit={this.props.handleSubmit}>
          <div className="form-block">
            <label className="form-block-label">Employees</label>
            <Field component={this.renderMultipleSelect} name="userIds" />
          </div>
          {this.props.invalid && this.props.submitFailed && (
            <div className="invalid-message">
              The outlined inputs should be added in order to Select Users.
            </div>
          )}
          <div className="buttons-container">
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Cancel"
              onClick={onClose}
            />
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Save"
              onClick={this.props.handleSubmit}
              disabled={this.props.invalid}
            />
          </div>
        </Form>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  if (!values.userIds || values.userIds.length === 0) {
    errors.userIds = 'Required';
  }

  return errors;
}

export default reduxForm({
  form: 'SelectUsersForm',
  validate,
})(SelectUsersForm);
