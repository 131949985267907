export const INVOICES_INVOICE_ATTRIBUTE_UPDATE =
  'INVOICES_INVOICE_ATTRIBUTE_UPDATE';
export const INVOICES_INVOICE_CUSTOM_EMAIL_CHANGE =
  'INVOICES_INVOICE_CUSTOM_EMAIL_CHANGE';
export const INVOICES_INVOICE_CUSTOM_EMAIL_VALIDATION_SET =
  'INVOICES_INVOICE_CUSTOM_EMAIL_VALIDATION_SET';
export const INVOICES_INVOICE_EDIT_CANCEL = 'INVOICES_INVOICE_EDIT_CANCEL';
export const INVOICES_INVOICE_EDIT_SET = 'INVOICES_INVOICE_EDIT_SET';
export const INVOICES_INVOICE_EMAIL_MODAL_SHOW_SET =
  'INVOICES_INVOICE_EMAIL_MODAL_SHOW_SET';
export const INVOICES_INVOICE_EMAILS_REMOVE = 'INVOICES_INVOICE_EMAILS_REMOVE';
export const INVOICES_INVOICE_EMAILS_USERS_ADD =
  'INVOICES_INVOICE_EMAILS_USERS_ADD';
export const INVOICES_INVOICE_FORM_ATTRIBUTE_CHANGE =
  'INVOICES_INVOICE_FORM_ATTRIBUTE_CHANGE';
export const INVOICES_INVOICE_FORM_INVALID = 'INVOICES_INVOICE_FORM_INVALID';
export const INVOICES_INVOICE_FORM_ITEM_LINE_CHANGE =
  'INVOICES_INVOICE_FORM_ITEM_LINE_CHANGE';
export const INVOICES_INVOICE_FORM_MISC_COST_ADD =
  'INVOICES_INVOICE_FORM_MISC_COST_ADD';
export const INVOICES_INVOICE_FORM_MISC_COST_CLEAR =
  'INVOICES_INVOICE_FORM_MISC_COST_CLEAR';
export const INVOICES_INVOICE_FORM_MISC_COST_REMOVE =
  'INVOICES_INVOICE_FORM_MISC_COST_REMOVE';
export const INVOICES_INVOICE_FORM_VALID = 'INVOICES_INVOICE_FORM_VALID';
export const INVOICES_INVOICE_ITEM_LINE_CHANGE =
  'INVOICES_INVOICE_ITEM_LINE_CHANGE';
export const INVOICES_INVOICE_LOAD_SUCCESS = 'INVOICES_INVOICE_LOAD_SUCCESS';
export const INVOICES_INVOICE_PDF_VIEWER_CLOSE =
  'INVOICES_INVOICE_PDF_VIEWER_CLOSE';
export const INVOICES_INVOICE_PRINT_SUCCESS = 'INVOICES_INVOICE_PRINT_SUCCESS';
export const INVOICES_INVOICE_SAVE_SHOW = 'INVOICES_INVOICE_SAVE_SHOW';
export const INVOICES_INVOICE_USER_SELECT = 'INVOICES_INVOICE_USER_SELECT';
export const INVOICES_LOAD_SUCCESS = 'INVOICES_LOAD_SUCCESS';
export const INVOICES_PAGE_CHANGE = 'INVOICES_PAGE_CHANGE';
export const INVOICES_USERS_LOAD_SUCCESS = 'INVOICES_USERS_LOAD_SUCCESS';
export const INVOICES_TAB_SELECTION_CHANGE = 'INVOICES_TAB_SELECTION_CHANGE';
export const INVOICES_SELECTED_STATUS_SET = 'INVOICES_SELECTED_STATUS_SET';
