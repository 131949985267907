import {
  TASKS_CONTRACTOR_SELECT,
  TASKS_PAGE_CHANGE,
  TASKS_TRADES_SELECT,
  TASKS_TRADES_SUCCESS,
  TASKS_UI_INITIALIZE,
} from '../../../actions/admin/tasks/action-types';

const initialState = {
  currentPage: 1,
  selectedContractorId: null,
  selectedTradeId: null,
};

export default function tasksUiReducer(state = initialState, action) {
  switch (action.type) {
    case TASKS_CONTRACTOR_SELECT:
      return {
        ...state,
        selectedContractorId: action.payload,
      };

    case TASKS_PAGE_CHANGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case TASKS_TRADES_SELECT:
      return {
        ...state,
        selectedTradeId: action.payload,
      };

    case TASKS_TRADES_SUCCESS:
      return {
        ...state,
        selectedTradeId: null,
      };

    case TASKS_UI_INITIALIZE:
      return initialState;

    default:
      return state;
  }
}
