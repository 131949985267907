import {
  DEFAULT_INSTANCE_FORM_TAB,
  DEFAULT_INSTANCE_FORM,
  DEFAULT_PARENT_FORM,
} from '../../../../../../domain/new-meeting';
import {
  PROJECT_NEW_MEETINGS_INIT,
  PROJECT_NEW_MEETINGS_SET_FORM_MODAL,
  PROJECT_NEW_MEETINGS_SET_FILTERS,
  PROJECT_NEW_MEETINGS_SET_FORM_TAB,
  PROJECT_NEW_MEETINGS_SET_MESSAGE,
  PROJECT_NEW_MEETINGS_SET_ADD_TYPE_MODAL,
  PROJECT_NEW_MEETINGS_SET_ADD_TITLE_MODAL,
  PROJECT_NEW_MEETINGS_SET_EDIT_TYPE_MODAL,
  PROJECT_NEW_MEETINGS_SET_EDIT_TITLE_MODAL,
  DELETE_TYPE_ERROR_MODAL_TOGGLE,
  PROJECT_NEW_MEETINGS_SET_INFO_MODAL,
  PROJECT_NEW_MEETINGS_SET_INFO_TAB,
  SET_MEETINGS_TABLE_TAB,
  SET_MEETINGS_INSTANCE_FORM_SHOW,
  SET_SCHEDULE_MODAL,
  ADD_NEW_MEETING_MODAL_SHOW,
  EDIT_NEXT_MEETING_MODAL_SHOW,
  ADD_CATEGORY_MODAL_SHOW,
  ADD_GUEST_MODAL_SHOW,
  ACTION_ITEMS_RESOLVED_MODAL_SHOW,
  ACTION_ITEMS_HISTORY_MODAL_SHOW,
  ACTION_ITEMS_HISTORY_SET_FILTERS,
  ACTION_ITEMS_RESOLVED_SET_FILTERS,
  SET_NOTEPAD_SHOW,
  SET_SUBMIT_CORRECTIONS_MODAL_SHOW,
  SET_EDIT_CORRECTIONS_MODAL_SHOW,
  SET_VIEW_CORRECTIONS_MODAL_SHOW,
  SET_REVIEW_CORRECTIONS_MODAL_SHOW,
  SET_PENDING_CORRECTIONS_ERROR_MODAL_SHOW,
  SET_INSTANCE_INFO_ERRORS,
  SET_ACTION_ITEMS_ERRORS,
  SET_MEETINGS_INSTANCE_PDF_VIEWER,
  GET_MEETINGS_PARENT_SUCCESS,
  SET_MEETINGS_INSTANCE_FORM_IS_BACKUP,
  SET_CONFIRM_CLOSE_MODAL_SHOW,
  SET_PDF_WAITING_PREVIEW,
  PROJECT_NEW_MEETINGS_SET_INSTACES_FILTERS,
  SET_MEETINGS_INSTANCE_HTML_VIEWER,
} from './action-types';
import {
  DEFAULT_MEETING_TAB,
  DEFAULT_MEETING_FORM_TAB,
  DEFAULT_MEETING_INFO_TAB,
  defaultMeetingsTitleOptions,
  defaultMeetingsTypeOptions,
} from 'domain/new-meeting';

export const INITIAL_MEETING_FILTERS = {
  search: '',
  by_meeting_parent_type: 'all',
  by_meeting_parent_title: 'all',
  by_created_by: '',
};
export const INITIAL_INSTANCE_MEETING_FILTERS = {
  search: '',
  by_date: 'all',
  by_submitter: 'all',
  date_from: '',
  date_to: '',
};
const initialState = {
  tab: DEFAULT_MEETING_TAB,
  filters: INITIAL_MEETING_FILTERS,
  instancesFilters: INITIAL_INSTANCE_MEETING_FILTERS,
  showFormModal: false,
  showInfoModal: false,
  showAddTypeModal: false,
  showAddTitleModal: false,
  showEditTypeModal: false,
  showEditTitleModal: false,
  showAddCategoryModal: false,
  showActionItemResolvedModal: false,
  showActionItemHistoryModal: false,
  showAddGuestModal: false,
  showInstanceForm: false,
  showAddNewMeetingModal: false,
  showEditNextMeetingModal: false,
  showDeleteTypeOrTitleErrorModal: false,
  showSubmitCorrectionsModal: false,
  showEditCorrectionsModal: false,
  showViewCorrectionsModal: false,
  showReviewCorrectionsModal: false,
  showPendingCorrectionsErrorModal: false,
  showMeetingsInstancePdfModal: false,
  showNotepad: false,
  showConfirmCloseModal: false,
  showPreviewModal: false,
  meetingsInstanceIsBackup: false,
  formTab: DEFAULT_MEETING_FORM_TAB,
  infoTab: DEFAULT_MEETING_INFO_TAB,
  instanceFormTab: DEFAULT_INSTANCE_FORM_TAB,
  meetingsTitles: defaultMeetingsTitleOptions,
  meetingsTypes: defaultMeetingsTypeOptions,
  instanceForm: DEFAULT_INSTANCE_FORM,
  form: DEFAULT_PARENT_FORM,
  message: null,
  messageType: 'SUCCESS',
  typeEmailModal: null,
  pdf: null,
  pdfType: 'pdf',
  showResolvedModal: false,
  actionItemHistory: {},
  actionItemHistoryFilters: {},
  actionItemResolvedFilters: {},
  instanceInfoErrors: {},
  actionItemsErrors: [],
};

export default function projectNewMeetingsModuleUiReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case PROJECT_NEW_MEETINGS_INIT:
      return initialState;
    case PROJECT_NEW_MEETINGS_SET_FILTERS:
      return { ...state, filters: action.payload };
    case PROJECT_NEW_MEETINGS_SET_INSTACES_FILTERS:
      return { ...state, instancesFilters: action.payload };
    case PROJECT_NEW_MEETINGS_SET_FORM_MODAL:
      return { ...state, showFormModal: action.payload };
    case PROJECT_NEW_MEETINGS_SET_FORM_TAB:
      return { ...state, formTab: action.payload };
    case PROJECT_NEW_MEETINGS_SET_INFO_MODAL:
      return { ...state, showInfoModal: action.payload };
    case PROJECT_NEW_MEETINGS_SET_INFO_TAB:
      return { ...state, infoTab: action.payload };
    case PROJECT_NEW_MEETINGS_SET_MESSAGE: {
      const { message, type: messageType } = action.payload;
      return { ...state, message, messageType };
    }
    case DELETE_TYPE_ERROR_MODAL_TOGGLE: {
      return { ...state, showDeleteTypeOrTitleErrorModal: action.payload };
    }
    case PROJECT_NEW_MEETINGS_SET_ADD_TYPE_MODAL:
      return { ...state, showAddTypeModal: action.payload };
    case PROJECT_NEW_MEETINGS_SET_ADD_TITLE_MODAL:
      return { ...state, showAddTitleModal: action.payload };
    case PROJECT_NEW_MEETINGS_SET_EDIT_TITLE_MODAL:
      return { ...state, showEditTitleModal: action.payload };
    case PROJECT_NEW_MEETINGS_SET_EDIT_TYPE_MODAL:
      return { ...state, showEditTypeModal: action.payload };
    case SET_SCHEDULE_MODAL:
      return { ...state, showScheduleModal: action.payload };

    case SET_MEETINGS_TABLE_TAB:
      return { ...state, tab: action.payload };
    case SET_MEETINGS_INSTANCE_FORM_SHOW: {
      return { ...state, showInstanceForm: action.payload };
    }
    case ADD_NEW_MEETING_MODAL_SHOW: {
      return { ...state, showAddNewMeetingModal: action.payload };
    }
    case EDIT_NEXT_MEETING_MODAL_SHOW: {
      return { ...state, showEditNextMeetingModal: action.payload };
    }
    case ADD_CATEGORY_MODAL_SHOW: {
      return { ...state, showAddCategoryModal: action.payload };
    }
    case ADD_GUEST_MODAL_SHOW: {
      return { ...state, showAddGuestModal: action.payload };
    }
    case ACTION_ITEMS_RESOLVED_MODAL_SHOW: {
      return { ...state, showActionItemsResolvedModal: action.payload };
    }
    case ACTION_ITEMS_HISTORY_MODAL_SHOW: {
      return {
        ...state,
        showActionItemHistoryModal: action.payload.show,
        actionItemHistory: action.payload.actionItemHistory,
      };
    }
    case ACTION_ITEMS_HISTORY_SET_FILTERS: {
      return { ...state, actionItemHistoryFilters: action.payload };
    }
    case ACTION_ITEMS_RESOLVED_SET_FILTERS: {
      return { ...state, actionItemResolvedFilters: action.payload };
    }
    case SET_NOTEPAD_SHOW: {
      return { ...state, showNotepad: action.payload };
    }
    case SET_SUBMIT_CORRECTIONS_MODAL_SHOW: {
      return { ...state, showSubmitCorrectionsModal: action.payload };
    }
    case SET_EDIT_CORRECTIONS_MODAL_SHOW: {
      return { ...state, showEditCorrectionsModal: action.payload };
    }
    case SET_VIEW_CORRECTIONS_MODAL_SHOW: {
      return { ...state, showViewCorrectionsModal: action.payload };
    }
    case SET_REVIEW_CORRECTIONS_MODAL_SHOW: {
      return { ...state, showReviewCorrectionsModal: action.payload };
    }
    case SET_PENDING_CORRECTIONS_ERROR_MODAL_SHOW: {
      return { ...state, showPendingCorrectionsErrorModal: action.payload };
    }
    case SET_INSTANCE_INFO_ERRORS: {
      return { ...state, instanceInfoErrors: action.payload };
    }
    case SET_ACTION_ITEMS_ERRORS: {
      return { ...state, actionItemsErrors: action.payload };
    }
    case SET_MEETINGS_INSTANCE_PDF_VIEWER: {
      return { ...state, showMeetingsInstancePdfModal: action.payload };
    }
    case GET_MEETINGS_PARENT_SUCCESS: {
      return { ...state, showFormModal: true };
    }
    case SET_MEETINGS_INSTANCE_FORM_IS_BACKUP: {
      return { ...state, meetingsInstanceIsBackup: action.payload };
    }
    case SET_CONFIRM_CLOSE_MODAL_SHOW: {
      return { ...state, showConfirmCloseModal: action.payload };
    }
    case SET_PDF_WAITING_PREVIEW: {
      return { ...state, showPreviewModal: action.payload };
    }
    case SET_MEETINGS_INSTANCE_HTML_VIEWER: {
      return { ...state, showMeetingsInstanceHtmlViewer: action.payload };
    }
    default:
      return state;
  }
}
