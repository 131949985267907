import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';
import './CloseoutContribForm.css';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faAngleDown from '@fortawesome/fontawesome-free-solid/faAngleDown';
import faAngleUp from '@fortawesome/fontawesome-free-solid/faAngleUp';
import CharsCounterTextarea from 'components/shared/chars-counter/CharsCounterTextarea';
import DocumentLink from 'components/shared/document/DocumentLink';
import DocumentUpload, {
  FILE_TYPES,
} from 'components/shared/document-upload/DocumentUpload';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import MessageModal from 'components/shared/modal/MessageModal';
import invalidPng from 'images/invalid.png';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  updateCloseoutContrib,
  closeoutPercentage,
} from 'components/admin/projects/details/project-closeout/store/actions';
import { getDarkMode } from 'selectors/theme';
import removeSvg from 'images/deleteCross.svg';
import removeDarkSvg from 'images/deleteCrossDarkMode.svg';
import { getCurrentUser } from 'selectors/authentication';
import CloseoutPermissions from 'permissions/closeout-permissions';

class CloseoutContribForm extends Component {
  static propTypes = {
    closeoutContrib: PropTypes.shape({}).isRequired,
  };

  constructor(props) {
    super(props);

    const { currentUser, currentProject } = props;
    this.state = {
      contribForm: this.initializeContribForm(props.closeoutContrib),
      showForm:
        currentProject &&
        CloseoutPermissions.canViewContributionFormByDefault(
          currentUser,
          currentProject
        ),
      showRemoveConfirmation: false,
      showInvalidDocsMsg: false,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { closeoutContrib } = this.props;
    const newCloseoutContrib = newProps.closeoutContrib;
    if (closeoutContrib !== newCloseoutContrib) {
      this.setState({
        contribForm: this.initializeContribForm(newCloseoutContrib),
      });
    }
  }

  initializeContribForm = (closeoutContrib) => {
    const contribForm = { comments: closeoutContrib.comments };
    closeoutContrib.closeout_contributor_cats.forEach((contribCat) => {
      contribForm[contribCat.id] = {};
      contribForm[contribCat.id].documents = contribCat.documents.map((doc) => {
        return {
          ...doc,
          url: doc.document.url,
        };
      });
      contribForm[contribCat.id].applies = contribCat.applies;
      contribForm[contribCat.id].comments = contribCat.comments;
    });
    return contribForm;
  };

  handleContribFormChange = (attr, value, callback) => {
    this.setState(
      {
        contribForm: {
          ...this.state.contribForm,
          [attr]: value,
        },
      },
      callback
    );
  };

  handleContribFormCatChange = (categoryId, attr, value, callback) => {
    this.setState(
      {
        contribForm: {
          ...this.state.contribForm,
          [categoryId]: {
            ...this.state.contribForm[categoryId],
            [attr]: value,
          },
        },
      },
      callback
    );
  };

  getArrayAttribute = (categoryId, attr) => {
    const { contribForm } = this.state;
    const contribFormCat = contribForm[categoryId];
    if (
      contribFormCat &&
      contribFormCat[attr] &&
      contribFormCat[attr].length > 0
    ) {
      return contribFormCat[attr];
    }
    return [];
  };

  setDocuments = (categoryId, files) => {
    const docs = [...this.getArrayAttribute(categoryId, 'documents')];
    files.forEach(function (val) {
      docs.push({
        file: val,
        name: val.name,
      });
    });
    this.handleContribFormCatChange(
      categoryId,
      'documents',
      docs,
      this.handleSave
    );
  };

  removeDocument = (confirmed) => {
    if (confirmed) {
      const { categoryId, index } = this.state;
      const docs = [...this.getArrayAttribute(categoryId, 'documents')];
      const deletedDocs = [
        ...this.getArrayAttribute(categoryId, 'deletedDocuments'),
      ];
      const deletedDoc = docs.splice(index, 1);
      deletedDocs.push(deletedDoc[0]);

      this.handleContribFormCatChange(categoryId, 'documents', docs, () =>
        this.handleContribFormCatChange(
          categoryId,
          'deletedDocuments',
          deletedDocs,
          this.handleSave
        )
      );
    }
    this.setState({ showRemoveConfirmation: false });
  };

  confirmRemoveDocument = (categoryId, index) => {
    this.setState({
      showRemoveConfirmation: true,
      categoryId: categoryId,
      index: index,
    });
  };

  handleSave = () => {
    const { currentProject, closeoutContrib, dispatch } = this.props;
    const { contribForm } = this.state;
    dispatch(
      updateCloseoutContrib(currentProject.id, closeoutContrib, contribForm)
    ).then((response) => {
      if (response.ok) {
        dispatch(closeoutPercentage(currentProject.id));
      }
    });
  };

  showForm = () => {
    this.setState({ showForm: !this.state.showForm });
  };

  handleShowInvalidDocsMsg = () => {
    this.setState({ showInvalidDocsMsg: true });
  };

  handleDismissInvalidDocsMsg = () => {
    this.setState({ showInvalidDocsMsg: false });
  };

  allowGcToAdjustApplicability = () => {
    const { currentUser, currentProject } = this.props;

    return CloseoutPermissions.canAdjustApplicability(
      currentUser,
      currentProject
    );
  };

  render() {
    const {
      currentUser,
      currentProject,
      closeoutContrib,
      darkMode,
    } = this.props;
    const {
      contribForm,
      showForm,
      showRemoveConfirmation,
      showInvalidDocsMsg,
    } = this.state;

    const canSeeContributorName = CloseoutPermissions.canViewContributorName(
      currentUser,
      currentProject
    );

    const canEdit = CloseoutPermissions.canViewContributor(
      currentUser,
      currentProject
    );

    return (
      <div className={`closeout-contrib-form ${darkMode ? 'dark-mode' : ''}`}>
        <div className="closeout-contrib-form-header" onClick={this.showForm}>
          <h4>
            {closeoutContrib.csi_code_division} {closeoutContrib.csi_code_label}
            {canSeeContributorName && (
              <span>
                {' '}
                - <b>{closeoutContrib.company_name}</b>
              </span>
            )}
          </h4>
          <span className="closeout-contrib-form-toggle">
            <FontAwesomeIcon icon={showForm ? faAngleUp : faAngleDown} />
          </span>
        </div>
        <Collapse in={showForm} className="collapse-info">
          <div className="closeout-contrib-form-container">
            {showForm && (
              <React.Fragment>
                <CharsCounterTextarea
                  placeholder="Remarks"
                  onBlur={(e) =>
                    this.handleContribFormChange(
                      'comments',
                      e.target.value,
                      this.handleSave
                    )
                  }
                  value={contribForm.comments}
                  readOnly={!canEdit}
                />
                <div className="closeout-contrib-form-categories">
                  {closeoutContrib.closeout_contributor_cats
                    .filter((contribCat) => contribForm[contribCat.id].applies)
                    .sort(
                      (contribCat1, contribCat2) =>
                        contribCat1.closeout_cat_position -
                        contribCat2.closeout_cat_position
                    )
                    .map((contribCat) => {
                      return (
                        <div
                          className="closeout-contrib-form-category"
                          key={contribCat.id}
                        >
                          <p className="closeout-contrib-form-subtitle">
                            {contribCat.closeout_cat.label}
                          </p>
                          {contribForm[contribCat.id].applies && (
                            <React.Fragment>
                              {contribCat.closeout_cat.value === 'misc' && (
                                <CharsCounterTextarea
                                  placeholder="Item Name"
                                  onBlur={(e) =>
                                    this.handleContribFormCatChange(
                                      contribCat.id,
                                      'comments',
                                      e.target.value,
                                      this.handleSave
                                    )
                                  }
                                  value={contribForm[contribCat.id].comments}
                                  readOnly={!canEdit}
                                />
                              )}
                              {canEdit && (
                                <DocumentUpload
                                  fileType={FILE_TYPES.ANY}
                                  onDropAccepted={(files) =>
                                    this.setDocuments(contribCat.id, files)
                                  }
                                  onDropRejected={this.handleShowInvalidDocsMsg}
                                />
                              )}
                              <div className="closeout-contrib-form-block-docs">
                                {contribForm[contribCat.id] &&
                                  contribForm[contribCat.id].documents &&
                                  contribForm[contribCat.id].documents.map(
                                    (doc, index) => {
                                      return (
                                        <div className="doc-viewer" key={index}>
                                          <DocumentLink
                                            file={doc}
                                            url={doc.url}
                                            iconPosition="left"
                                          />
                                          {canEdit && (
                                            <img
                                              className="remove-doc-icon"
                                              src={
                                                darkMode
                                                  ? removeDarkSvg
                                                  : removeSvg
                                              }
                                              alt="remove"
                                              onClick={() =>
                                                this.confirmRemoveDocument(
                                                  contribCat.id,
                                                  index
                                                )
                                              }
                                            />
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                      );
                    })}
                </div>
                {showRemoveConfirmation && (
                  <ConfirmationModal
                    show={showRemoveConfirmation}
                    message="Are you sure you want to delete this document?"
                    onHide={this.removeDocument}
                  />
                )}
                {showInvalidDocsMsg && (
                  <MessageModal
                    show={showInvalidDocsMsg}
                    icon={invalidPng}
                    message="File format not supported. Please try uploading again as PDF."
                    onHide={this.handleDismissInvalidDocsMsg}
                  />
                )}
              </React.Fragment>
            )}
          </div>
        </Collapse>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    currentUser: getCurrentUser(state),
    darkMode: getDarkMode(state),
  };
})(CloseoutContribForm);
