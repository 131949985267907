import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Iframe from 'react-iframe';
import './PdfViewer.css';
import ImageButton from 'components/shared/button/ImageButton';
import closeSvg from 'images/close.svg';

export default function PdfViewer(props) {
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  let url = null;
  if (props.content !== null) {
    if (props.content.type === 'url') {
      url = props.content.source;
    } else if (props.content.type === 'blob') {
      if (navigator.appVersion.toString().indexOf('.NET') > 0) {
        window.navigator.msSaveBlob(props.content.source);
        return null;
      } else {
        //Create a Blob from the PDF Stream
        const file = new Blob([props.content.source], {
          type: 'application/pdf',
        });
        //Build a URL from the file
        url = URL.createObjectURL(file);
      }
    }
  }

  return (
    <Modal className="pdf-viewer-modal" show={props.show}>
      <Modal.Header>
        <div className="body-bold">Pdf Viewer</div>
        <ImageButton alt="close" src={closeSvg} onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        {url !== null && (
          <Iframe
            url={url}
            className="pdf-viewer-iframe"
            width="100%"
            height="100%"
            display="initial"
            position="relative"
            allowFullScreen
          />
        )}
      </Modal.Body>
    </Modal>
  );
}

PdfViewer.propTypes = {
  show: PropTypes.bool,
  content: PropTypes.shape({
    source: PropTypes.any,
    type: PropTypes.oneOf(['url', 'blob']),
  }),
  onClose: PropTypes.func,
};

PdfViewer.defaultProps = {
  show: false,
  content: null,
};
