import { Grid, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import Button, { BUTTON_TYPES } from 'components/shared/button/Button';
import colors from 'styles/colors';
import { getDarkMode } from 'selectors/theme';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import ThumbsUpIcon from 'components/shared/icons/ThumbsUpIcon';
import { getProjectPhotosForm } from './store/selectors';
import { setProjectPhotosForm } from './store/actions';
import { toggleProjectPostLike } from '../project-posts/store/actions';

const ProjectPhotosFormLikes = (props) => {
  const {
    form,
    dispatch,
    disabled,
    darkMode,
    currentUser,
    currentProject,
  } = props;

  const count = form.likes.length;
  const liked =
    typeof form.likes.find((u) => u.id === currentUser.id) !== 'undefined';

  const onLike = () => {
    dispatch(
      toggleProjectPostLike(currentProject.id, form.postId, !liked)
    ).then((response) => {
      if (response.ok) {
        const likes = form.likes.filter((u) => u.id !== currentUser.id);
        if (likes.length === form.likes.length) likes.push(currentUser);
        dispatch(setProjectPhotosForm({ likes }));
      }
    });
  };

  return (
    <Grid container alignItems="baseline" spacing={1.5}>
      <Grid item>
        <Button
          onClick={onLike}
          disabled={disabled}
          type={BUTTON_TYPES.LINK}
          label={
            <ThumbsUpIcon
              fill={
                liked
                  ? colors[darkMode ? 'neon' : 'primary-green']
                  : colors[darkMode ? 'white-text' : 'light-mode-black-text']
              }
            />
          }
        />
      </Grid>
      {!disabled && (
        <Grid item>
          <Typography variant="h4" color="text.primary">
            {count}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default connect((state) => ({
  darkMode: getDarkMode(state),
  form: getProjectPhotosForm(state),
  currentProject: getCurrentProject(state),
  currentUser: getCurrentUser(state),
}))(ProjectPhotosFormLikes);
