import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormControlBlock, {
  CONTROL_SIZE,
} from 'components/shared/form/FormControlBlock';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import DatePicker from 'components/shared/date-picker/DatePicker';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import {
  sameOrBeforeDateValidation,
  sameOrAfterDateValidation,
} from 'services/utils/date-util';
import {
  MATERIAL_ORDER_DETAILS_FIELDS,
  MATERIAL_ORDER_IDS_FIELDS,
  MATERIAL_ORDER_FEATURES_FIELDS,
} from './store/domain';
import { getCurrentReportViewMaterialOrder } from './store/selectors';
import { setReportViewFormValue } from './store/actions';

class ReportMaterialOrders extends Component {
  constructor(props) {
    super(props);

    this.statusCountOptions = [
      { id: 'active', label: 'Active' },
      { id: 'revised', label: 'Revised' },
      { id: 'canceled', label: 'Canceled' },
    ];

    this.detailsFields = [
      { id: 'color', label: 'Color' },
      { id: 'size', label: 'Size' },
      { id: 'height', label: 'Height' },
      { id: 'length', label: 'Length' },
      { id: 'width', label: 'Width' },
      { id: 'weight', label: 'Weight' },
      { id: 'color2', label: 'Color 2' },
      { id: 'size2', label: 'Size 2' },
      { id: 'height2', label: 'Height 2' },
      { id: 'length2', label: 'Length 2' },
      { id: 'width2', label: 'Width 2' },
      { id: 'weight2', label: 'Weight 2' },
    ];

    this.idsFields = [
      { id: 'item_no', label: 'Item No' },
      { id: 'barcode', label: 'Barcode' },
      { id: 'barcode_upc', label: 'Barcode UPC' },
      { id: 'barcode_ean', label: 'Barcode EAN' },
      { id: 'upc_a', label: 'UPC-A' },
      { id: 'ucc_13', label: 'UCC-13' },
      { id: 'ucc_8', label: 'UCC-8' },
      { id: 'asin', label: 'ASIN' },
      { id: 'model', label: 'Model' },
      { id: 'mpn', label: 'MPN' },
    ];

    this.featuresFields = [
      { id: 'features', label: 'Features' },
      { id: 'ingredients', label: 'Ingredients' },
      { id: 'nutrition_facts', label: 'Nutrition Facts' },
      { id: 'Material', label: 'material' },
      { id: 'pattern', label: 'Pattern' },
      { id: 'age_group', label: 'Age Group' },
      { id: 'gender', label: 'Gender' },
      { id: 'contributors', label: 'Contributors' },
      { id: 'release_date', label: 'Release Date' },
      { id: 'category', label: 'Category (API)' },
      { id: 'stores', label: 'Stores' },
      { id: 'reviews', label: 'Reviews' },
      { id: 'energy_efficiency_rating', label: 'Energy Efficiency Rating' },
      { id: 'photos', label: 'Photos' },
      { id: 'photos2', label: 'Photos 2' },
    ];
  }

  handleFormChange = (attribute, value) => {
    const { dispatch } = this.props;
    dispatch(setReportViewFormValue('material_order', attribute, value));
  };

  makeCheckboxsRow = (
    key,
    fields,
    index = null,
    columns = 6,
    controlSize = CONTROL_SIZE.MEDIUM
  ) => {
    const values = this.props.currentReportViewMaterialOrder[key] || {};

    return (
      <div key={index} className="form-block">
        {fields.map((field) => (
          <FormControlBlock
            key={field.id}
            size={controlSize}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange(key, { ...values, [field.id]: value })
                }
                checked={!!values[field.id]}
                label={field.label}
                position="left"
              />
            }
          />
        ))}
        {[...Array(columns - fields.length).keys()].map((index) => (
          <FormControlBlock key={`empty_${index}`} size={controlSize} />
        ))}
      </div>
    );
  };

  makeCheckboxsSection = (
    key,
    title,
    fields,
    columns = 6,
    controlSize = CONTROL_SIZE.MEDIUM
  ) => {
    const rows = fields.reduce((rows, field, index) => {
      const row_index = Math.floor(index / columns);
      rows[row_index] = [...(rows[row_index] || []), field];
      return rows;
    }, []);
    return (
      <React.Fragment>
        <div className="sidebar-form-description">{title}</div>
        {rows.map((row, index) =>
          this.makeCheckboxsRow(key, row, index, columns, controlSize)
        )}
      </React.Fragment>
    );
  };

  render() {
    const {
      currentReportViewMaterialOrder: {
        status_counts,
        order_date_from,
        order_date_to,
        customer_titles,
        vendors,
        products_revised,
        products_removed,
        items_offered,
        categories,
        item_types,
        vendor_notes,
        lead_time,
        brands,
        brands2,
        manufacturers,
        manufacturers2,
        title,
        title2,
        description,
        description2,
        multipack,
      },
    } = this.props;

    return (
      <React.Fragment>
        <div className="sidebar-form-body-subtitle">Order Data</div>

        <div className="form-block">
          <FormControlBlock
            label={!!status_counts.length > 0 && 'Order Count By Status'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Order Count By Status"
                currentValues={status_counts}
                onChange={(values) =>
                  this.handleFormChange('status_counts', values)
                }
                search={false}
                options={this.statusCountOptions}
              />
            }
          />
          <FormControlBlock
            label={!!order_date_from && 'Orders From'}
            size={CONTROL_SIZE.SMALL}
            control={
              <DatePicker
                isValidDate={(date) =>
                  sameOrBeforeDateValidation(date, order_date_to)
                }
                placeholder="Orders From"
                value={order_date_from}
                clearable
                onChange={(value) =>
                  this.handleFormChange('order_date_from', value)
                }
              />
            }
          />
          <FormControlBlock
            label={!!order_date_to && 'Orders To'}
            size={CONTROL_SIZE.SMALL}
            control={
              <DatePicker
                isValidDate={(date) =>
                  sameOrAfterDateValidation(date, order_date_from)
                }
                placeholder="Orders To"
                value={order_date_to}
                clearable
                onChange={(value) =>
                  this.handleFormChange('order_date_to', value)
                }
              />
            }
          />
          <FormControlBlock size={CONTROL_SIZE.BIG} />
          <FormControlBlock size={CONTROL_SIZE.BIG} />
        </div>

        <div className="sidebar-form-body-subtitle">Customer Information</div>

        <div className="form-block">
          <FormControlBlock
            label={!!customer_titles.length > 0 && 'Customer Title'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Customer Title"
                currentValues={customer_titles}
                onChange={(values) =>
                  this.handleFormChange('customer_titles', values)
                }
                search={false}
                options={[]}
              />
            }
          />
          <FormControlBlock size={CONTROL_SIZE.BIG} />
          <FormControlBlock size={CONTROL_SIZE.BIG} />
          <FormControlBlock size={CONTROL_SIZE.BIG} />
        </div>

        <div className="sidebar-form-body-subtitle">Vendor Information</div>

        <div className="form-block">
          <FormControlBlock
            label={!!vendors.length > 0 && 'Vendor Name'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Vendor Name"
                currentValues={vendors}
                onChange={(values) => this.handleFormChange('vendors', values)}
                search={false}
                options={[]}
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('products_revised', value)
                }
                checked={products_revised}
                label="Products Revised"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('products_removed', value)
                }
                checked={products_removed}
                label="Products Removed"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('items_offered', value)
                }
                checked={items_offered}
                label="Items Offered"
                position="left"
              />
            }
          />
        </div>

        <div className="sidebar-form-body-subtitle">Product Information</div>

        <div className="form-block">
          <FormControlBlock
            label={!!categories.length > 0 && 'Category'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Category"
                currentValues={categories}
                onChange={(values) =>
                  this.handleFormChange('categories', values)
                }
                search={false}
                options={[]}
              />
            }
          />
          <FormControlBlock
            label={!!item_types.length > 0 && 'Item Type'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Item Type"
                currentValues={item_types}
                onChange={(values) =>
                  this.handleFormChange('item_types', values)
                }
                search={false}
                options={[]}
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('vendor_notes', value)
                }
                checked={vendor_notes}
                label="Vendor Notes"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={
              <LabeledCheckBox
                onChange={(value) => this.handleFormChange('lead_time', value)}
                checked={lead_time}
                label="Lead Time"
                position="left"
              />
            }
          />
        </div>

        <div className="sidebar-form-description">General</div>

        <div className="form-block">
          <FormControlBlock
            label={!!brands.length > 0 && 'Brand'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Brand"
                currentValues={brands}
                onChange={(values) => this.handleFormChange('brands', values)}
                search={false}
                options={[]}
              />
            }
          />
          <FormControlBlock
            label={!!brands2.length > 0 && 'Brand 2'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Brand 2"
                currentValues={brands2}
                onChange={(values) => this.handleFormChange('brands2', values)}
                search={false}
                options={[]}
              />
            }
          />
          <FormControlBlock
            label={!!manufacturers.length > 0 && 'Manufacturers'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Manufacturers"
                currentValues={manufacturers}
                onChange={(values) =>
                  this.handleFormChange('manufacturers', values)
                }
                search={false}
                options={[]}
              />
            }
          />
          <FormControlBlock
            label={!!manufacturers2.length > 0 && 'Manufacturers 2'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Manufacturers 2"
                currentValues={manufacturers2}
                onChange={(values) =>
                  this.handleFormChange('manufacturers2', values)
                }
                search={false}
                options={[]}
              />
            }
          />
        </div>

        <div className="form-block">
          <FormControlBlock
            size={CONTROL_SIZE.MEDIUM}
            control={
              <LabeledCheckBox
                onChange={(value) => this.handleFormChange('title', value)}
                checked={title}
                label="Title"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.MEDIUM}
            control={
              <LabeledCheckBox
                onChange={(value) => this.handleFormChange('title2', value)}
                checked={title2}
                label="Title 2"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.MEDIUM}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('description', value)
                }
                checked={description}
                label="Description"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.MEDIUM}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('description2', value)
                }
                checked={description2}
                label="Description 2"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.MEDIUM}
            control={
              <LabeledCheckBox
                onChange={(value) => this.handleFormChange('multipack', value)}
                checked={multipack}
                label="Multipack"
                position="left"
              />
            }
          />
          <FormControlBlock size={CONTROL_SIZE.MEDIUM} />
        </div>

        {this.makeCheckboxsSection(
          'details',
          'Details',
          MATERIAL_ORDER_DETAILS_FIELDS
        )}
        {this.makeCheckboxsSection('ids', 'IDs', MATERIAL_ORDER_IDS_FIELDS)}
        {this.makeCheckboxsSection(
          'features',
          'Features',
          MATERIAL_ORDER_FEATURES_FIELDS
        )}
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    currentReportViewMaterialOrder: getCurrentReportViewMaterialOrder(state),
  };
})(ReportMaterialOrders);
