import { createSelector } from 'reselect';

export const getFieldWorkOrdersCount = createSelector(
  (state) => state.fieldWorkOrders.data.fieldWorkOrdersCount,
  (fieldWorkOrdersCount) => {
    return fieldWorkOrdersCount;
  }
);

export const getFieldWorkOrders = createSelector(
  (state) => state.fieldWorkOrders.data.fieldWorkOrders,
  (fieldWorkOrders) => {
    return fieldWorkOrders;
  }
);

export const getTotal = createSelector(
  (state) => state.fieldWorkOrders.data.total,
  (total) => {
    return total;
  }
);

export const getCurrentPage = createSelector(
  (state) => state.fieldWorkOrders.data.currentPage,
  (currentPage) => {
    return currentPage;
  }
);

export const getPageSize = createSelector(
  (state) => state.fieldWorkOrders.data.pageSize,
  (pageSize) => {
    return pageSize;
  }
);
