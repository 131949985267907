import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Date from 'components/shared/text/Date';
import SimpleTable from 'components/shared/table/SimpleTable';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import MessageModal from 'components/shared/modal/MessageModal';
import {
  getMissingDrsCurrentPage,
  getMissingDrs,
} from 'components/admin/projects/store/selectors';
import {
  loadMissingDrsRequest,
  goToProjectDetails,
  dailyReportsReminder,
} from 'components/admin/projects/store/actions';

class MissingDailyReports extends Component {
  static propTypes = {
    filters: PropTypes.shape(),
  };

  static defaultProps = {
    filters: {},
  };

  constructor(props) {
    super(props);

    this.state = { reminderSent: false };
  }

  componentDidMount() {
    this.handlePageChange(1);
  }

  handlePageChange = (page) => {
    const { filters, dispatch } = this.props;
    dispatch(loadMissingDrsRequest({ ...filters, page }));
  };

  handleOpen = (id) => {
    this.props.dispatch(goToProjectDetails(id));
  };

  handleSendReminder = (project, event) => {
    const { dispatch } = this.props;
    event.stopPropagation();
    dispatch(dailyReportsReminder(project.id)).then((response) => {
      if (response.ok) {
        this.setState({ reminderSent: true });
      }
    });
  };

  handleHideReminderSent = () => {
    this.setState({ reminderSent: false });
  };

  mapProjects = () => {
    const { projects } = this.props;
    return projects.content.map((project) => {
      return {
        id: project.id,
        data: [
          project.number,
          project.name,
          project.last_dr_date && (
            <Date
              className="last-dr-date"
              value={project.last_dr_date}
              format="MM/DD/YYYY"
            />
          ),
          <div
            className="clickable"
            onClick={(e) => this.handleSendReminder(project, e)}
          >
            Send Reminder
          </div>,
        ],
      };
    });
  };

  handleGenerateLink = (id) => {
    return `projects/${id}`;
  };

  render() {
    const { projects, currentPage } = this.props;
    const { reminderSent } = this.state;
    return (
      projects && (
        <div className="missing-daily-reports">
          <SimpleTable
            className="projects-table"
            headers={[
              'PROJECT NO',
              'PROJECT NAME',
              'LAST SUBMISSION',
              'ACTIONS',
            ]}
            rows={this.mapProjects()}
            emptyMessage="No Projects"
            onRowSelected={this.handleOpen}
            onGenerateLink={this.handleGenerateLink}
          />
          <div className="pagination-container">
            <SimplePagination
              currentPage={currentPage}
              pageSize={projects.pageSize}
              total={projects.total}
              onPageChange={this.handlePageChange}
            />
          </div>
          {reminderSent && (
            <MessageModal
              show={reminderSent}
              message="Superintendent has been reminded to add the Daily Reports"
              onHide={this.handleHideReminderSent}
            />
          )}
        </div>
      )
    );
  }
}

export default connect((state) => {
  return {
    currentPage: getMissingDrsCurrentPage(state),
    projects: getMissingDrs(state),
  };
})(MissingDailyReports);
