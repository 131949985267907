import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Selector from 'components/shared/selector/Selector';
import { fwoStatusList } from 'services/utils/field-work-order-util';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import './FieldWorkOrderDetails.css';

class FieldWorkOrderGCForm extends Component {
  static propTypes = {
    fieldWorkOrderForm: PropTypes.shape({}),
    handleAttributeChange: PropTypes.func.isRequired,
    showErrorMessage: PropTypes.bool.isRequired,
  };

  missingField(value) {
    const { showErrorMessage } = this.props;
    return !value && showErrorMessage;
  }

  render() {
    const { fieldWorkOrderForm, handleAttributeChange } = this.props;
    let statusList = [...fwoStatusList];
    statusList = statusList.filter((s) => s.value !== 'pending');
    return (
      <div className="form-block work-order-status-block">
        <FormControlBlock
          label={fieldWorkOrderForm.fwoStatus && 'Status'}
          control={
            <Selector
              placeholder="Status"
              options={statusList}
              value={fieldWorkOrderForm.fwoStatus}
              onChange={(value) => handleAttributeChange('fwoStatus', value)}
              valid={!this.missingField(fieldWorkOrderForm.fwoStatus)}
            />
          }
        />
        {fieldWorkOrderForm.fwoStatus &&
          fieldWorkOrderForm.fwoStatus.value === 'denied' && (
            <FormControlBlock
              label={fieldWorkOrderForm.fwoStatus && 'Denied Reason'}
              control={
                <TextareaAutosizeWrapper
                  placeholder="Denied Reason"
                  initialValue={fieldWorkOrderForm.fwoDeniedReason || ''}
                  valid={
                    !this.missingField(
                      fieldWorkOrderForm.fwoDeniedReason,
                      'fwoDeniedReason'
                    )
                  }
                  onChange={(value) =>
                    handleAttributeChange('fwoDeniedReason', value)
                  }
                />
              }
            />
          )}
      </div>
    );
  }
}

export default FieldWorkOrderGCForm;
