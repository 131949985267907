import { createSelector } from 'reselect';

export const getForm = createSelector(
  (state) => state.registration.ui.form,
  (reportViews) => {
    return reportViews;
  }
);

export const getShowSimilarCompaniesModal = createSelector(
  (state) => state.registration.ui.showSimilarCompaniesModal,
  (showSimilarCompaniesModal) => {
    return showSimilarCompaniesModal;
  }
);

export const getShowRegisteredModal = createSelector(
  (state) => state.registration.ui.showRegisteredModal,
  (showRegisteredModal) => {
    return showRegisteredModal;
  }
);

export const getShowVerifyingModal = createSelector(
  (state) => state.registration.ui.showVerifyingModal,
  (showVerifyingModal) => {
    return showVerifyingModal;
  }
);

export const getTradeCodes = createSelector(
  (state) => state.registration.data.trade_codes,
  (trade_codes) => {
    return trade_codes;
  }
);

export const getUnions = createSelector(
  (state) => state.registration.data.unions,
  (unions) => {
    return unions;
  }
);

export const getRoles = createSelector(
  (state) => state.registration.data.roles,
  (roles) => {
    return roles;
  }
);

export const getSimilarCompanies = createSelector(
  (state) => state.registration.data.similar_companies,
  (similar_companies) => {
    return similar_companies;
  }
);
