import React, { useState } from 'react';

import Button, {
  BUTTON_COLORS,
  BUTTON_TYPES,
} from 'components/shared/button/Button';
import Date from 'components/shared/text/Date';

import { useEffect, useMemo } from 'react';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import { loadPreviousFieldReport } from './store/actions';
import {
  showOpenItemFormModal,
  editOpenItemDetailsModal,
  setOpenItemForm,
} from 'components/admin/projects/details/project-open-items/store/actions';
import Project from 'domain/project';

import SimpleTruncate from 'components/shared/text/SimpleTruncate.js';

const useFieldReportFormStatus = (props) => {
  const { dispatch, directory, currentProject, form, handleFormChange } = props;
  const [showImages, setShowImages] = useState(
    form.project_images && form.project_images.length > 0
  );

  useEffect(() => {
    if (currentProject.id) {
      if (!directory || directory.project_id !== currentProject.id) {
        dispatch(loadDirectoryRequest(currentProject.id));
      }
    }
  }, []);

  const tradeOptions = useMemo(
    () => [
      { id: 'all', label: 'All Cost Codes' },
      ...((directory && directory.project_contributors) || []).map(
        (contributor) => ({
          id: contributor.id,
          label: Project.contributorLabel(contributor),
        })
      ),
    ],
    [directory]
  );

  const openItemsRows = useMemo(() => {
    if (!form.open_items || form.open_items.length === 0) return [];

    return form.open_items.map((item, index) => [
      item.number < 10 ? `0${item.number}` : item.number,
      <SimpleTruncate text={item.description} shownLines={2} />,
      item.assignee_label,
      item.resolve_by ? (
        <Date value={item.resolve_by} format="MM/DD/YYYY" />
      ) : (
        '--'
      ),
      item.critical ? 'YES' : 'NO',
      <Button
        key={`action_${item.id}_${index}`}
        type={BUTTON_TYPES.LINK}
        color={BUTTON_COLORS.GREEN}
        label={'EDIT'}
        onClick={() => handleEditOpenItem(item)}
      />,
    ]);
  }, [form.open_items]);

  const handleAddOpenItem = () => {
    dispatch(showOpenItemFormModal());
  };
  const handleEditOpenItem = (openItem) => {
    dispatch(
      setOpenItemForm({
        ...openItem,
        projectContributorId: openItem.project_contributor_id || '',
        assigneeId: openItem.assignee_id,
        resolveBy: openItem.resolve_by,
        recipients: openItem.recipients.map((rcpt) => [rcpt.id]),
        documents: openItem.documents.map((doc) => {
          return {
            ...doc,
            url: doc.document.url,
          };
        }),
        images: openItem.images.map((image) => {
          return {
            ...image,
            url: image.image.gallery.url,
          };
        }),
      })
    );
    dispatch(editOpenItemDetailsModal());
    dispatch(showOpenItemFormModal());
  };
  const handleAddOpenItemClose = (response) => {
    if (response.ok) {
      const open_items = form.open_items.filter(
        (item) => item.id !== response.projectOpenItem.id
      );
      open_items.push(response.projectOpenItem);
      handleFormChange({ open_items });
    }
  };

  const handleAddPhotos = () => setShowImages(true);

  const handleLoadPrevius = async () => {
    const response = await dispatch(loadPreviousFieldReport(currentProject.id));
    if (response.ok && response.report) {
      const loadValues = {};
      Object.keys(response.report).forEach((key) => {
        if (key.includes('observation')) loadValues[key] = response.report[key];
      });
      handleFormChange(loadValues);
    }
  };

  return {
    tradeOptions,
    openItemsRows,
    showImages,
    handleAddOpenItem,
    handleAddOpenItemClose,
    handleAddPhotos,
    handleLoadPrevius,
  };
};

export default useFieldReportFormStatus;
