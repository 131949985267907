import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'components/admin/companies/UserForm.css';
import { Form } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import Input from 'components/shared/input/Input';
import Selector from 'components/shared/selector/Selector';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import { companyTitleOptions, companyRoleOptions } from 'domain/user-role-enum';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

class InviteUserForm extends Component {
  static propTypes = {
    companyType: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    canSetAccountAdmin: PropTypes.bool.isRequired,
    roles: PropTypes.shape({}).isRequired,
    initialValues: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
      title: PropTypes.string,
      assignedRole: PropTypes.string,
      accountAdmin: PropTypes.bool,
      accountableId: PropTypes.number,
    }),
  };

  renderInput = (props) => {
    return (
      <Input
        valid={!props.meta.error || !props.meta.submitFailed}
        value={props.input.value}
        onChange={(value) => props.input.onChange(value)}
        {...props}
      />
    );
  };

  renderCheckBox = (props) => {
    return (
      <LabeledCheckBox
        checked={!!props.input.value}
        onChange={(value) => props.input.onChange(value)}
        {...props}
      />
    );
  };

  onChangeDropDown = (option, props) => {
    const value = option && option.value ? option.value : '';
    props.input.onChange(value);
  };

  renderDropDown = (props) => {
    return (
      <Selector
        valid={!props.meta.error || !props.meta.submitFailed}
        value={props.input.value}
        onChange={(option) => this.onChangeDropDown(option, props)}
        {...props}
      />
    );
  };

  render() {
    const {
      roles,
      canSetAccountAdmin,
      onClose,
      onDelete,
      initialValues,
    } = this.props;

    return (
      <div className="invite-edit-user-form">
        <Form horizontal onSubmit={this.props.handleSubmit}>
          <div className="form-block">
            <label className="form-block-label">First Name</label>
            <Field
              component={this.renderInput}
              name="firstName"
              placeholder="First Name"
            />
          </div>
          <div className="form-block">
            <label className="form-block-label">Last Name</label>
            <Field
              component={this.renderInput}
              name="lastName"
              placeholder="Last Name"
            />
          </div>
          <div className="form-block">
            <label className="form-block-label">Email</label>
            <Field
              component={this.renderInput}
              name="email"
              placeholder="Email"
            />
          </div>
          <div className="form-block">
            <label className="form-block-label">Phone Number</label>
            <Field
              component={this.renderInput}
              name="phoneNumber"
              placeholder="Phone Number"
            />
            <Field
              component={this.renderInput}
              name="phoneExt"
              placeholder="ext"
            />
          </div>
          <div className="form-block">
            <label className="form-block-label">Title</label>
            <Field
              component={this.renderDropDown}
              name="title"
              placeholder="Select Title"
              options={companyTitleOptions}
            />
            <Field
              component={this.renderInput}
              name="title"
              placeholder="Or Type in"
            />
          </div>
          <div className="form-block">
            <label className="form-block-label">Role</label>
            <Field
              component={this.renderDropDown}
              name="assignedRole"
              placeholder="Select Role"
              options={companyRoleOptions(roles)}
            />
          </div>
          {canSetAccountAdmin && (
            <div className="form-block">
              <Field
                component={this.renderCheckBox}
                name="accountAdmin"
                label="Account Administrator"
              />
            </div>
          )}
          {this.props.invalid && this.props.submitFailed && (
            <div className="invalid-message">
              The outlined inputs should be added in order to Invite a User.
            </div>
          )}
          <div className="buttons-container">
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Cancel"
              onClick={onClose}
            />
            {initialValues && (
              <Button
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.GREEN}
                label="Delete"
                onClick={onDelete}
              />
            )}
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Save"
              disabled={this.props.invalid}
              onClick={this.props.handleSubmit}
            />
          </div>
        </Form>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = 'Required';
  }

  if (!values.lastName) {
    errors.lastName = 'Required';
  }

  if (!values.email) {
    errors.email = 'Required';
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = 'Required';
  }

  if (!values.title) {
    errors.title = 'Required';
  }

  if (!values.assignedRole) {
    errors.assignedRole = 'Required';
  }

  return errors;
}

export default reduxForm({
  form: 'InviteUserForm',
  validate,
})(InviteUserForm);
