import User from 'domain/user';

class CloseoutPermissions {
  canViewRequirements = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project);

  canEdit = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project);

  canEditManual = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project);

  canViewContributionFormByDefault = (user, project) =>
    User.isContractor(user, project);

  canAdjustApplicability = (user, project) => !User.isGcOar(user, project);

  canViewContributorName = (user, project) => !User.isContractor(user, project);

  canViewContributor = (user, project) => !User.isPrimary(user, project);
}

export default new CloseoutPermissions();
