import {
  ALL_RECENT_ACTIVITY_LOAD,
  SPE_RECENT_ACTIVITY_LOAD,
  SW_RECENT_ACTIVITY_LOAD,
} from 'components/admin/recent-activity/store/action-types';

const initialState = {
  allRecentActivity: null,
  speRecentActivity: null,
  swRecentActivity: null,
};

export default function recentActivityDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case ALL_RECENT_ACTIVITY_LOAD:
      return {
        ...state,
        allRecentActivity: action.payload,
      };

    case SPE_RECENT_ACTIVITY_LOAD:
      return {
        ...state,
        speRecentActivity: action.payload,
      };

    case SW_RECENT_ACTIVITY_LOAD:
      return {
        ...state,
        swRecentActivity: action.payload,
      };

    default:
      return state;
  }
}
