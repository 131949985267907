import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllRecentActivity } from 'components/admin/recent-activity/store/selectors';
import { recentActivityRequest } from 'components/admin/recent-activity/store/actions';
import { allRecentActivitySuccess } from 'components/admin/recent-activity/store/actions';
import RecentActivity from 'components/admin/recent-activity/RecentActivity';

class AllRecentActivity extends Component {
  handlePageChange = (page) => {
    const { dispatch, currentProject } = this.props;
    const params = currentProject && {
      by_project: currentProject.id,
    };
    dispatch(recentActivityRequest(page, params)).then((response) => {
      if (response.ok) {
        dispatch(allRecentActivitySuccess(response.notifications));
      }
    });
  };

  render() {
    const { recentActivity, currentProject } = this.props;
    return (
      <RecentActivity
        title="Recent Activity"
        recentActivity={recentActivity}
        currentProject={currentProject}
        onPageChange={this.handlePageChange}
      />
    );
  }
}

export default connect((state) => {
  return {
    recentActivity: getAllRecentActivity(state),
  };
})(AllRecentActivity);
