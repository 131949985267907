import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import './CheckBox.css';

class CheckBox extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    readOnly: PropTypes.bool,
    valid: PropTypes.bool,
    shape: PropTypes.oneOf(['round', 'square', 'bell']),
    size: PropTypes.oneOf(['small', 'medium']),
    darkModeFixed: PropTypes.bool,
    onChange: PropTypes.func,
    color: PropTypes.string,
    title: PropTypes.string,
  };

  static defaultProps = {
    className: null,
    readOnly: false,
    valid: true,
    shape: 'square',
    size: 'small',
    darkModeFixed: false,
    onChange: () => {},
    color: null,
    title: '',
  };

  handleSelectChange = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!this.props.readOnly) {
      this.props.onChange(this.props.id);
    }
  };

  render() {
    const {
      className,
      size,
      readOnly,
      valid,
      checked,
      shape,
      darkMode,
      darkModeFixed,
      color,
      title,
    } = this.props;

    return (
      <div
        className={`checkbox-container ${className || ''} ${
          darkModeFixed || darkMode ? ' dark-mode' : ''
        } ${readOnly ? ' read-only' : ''} ${checked ? ' checked' : ''} ${
          !valid ? ' invalid' : ''
        } size-${size} shape-${shape} ${color || ''} `}
        onClick={this.handleSelectChange}
        title={title}
      ></div>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(CheckBox);
