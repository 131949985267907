import React from 'react';
import { connect } from 'react-redux';

import FavoriteIcon from 'components/shared/icons/FavoriteIcon';
import Button, { BUTTON_TYPES } from 'components/shared/button/Button';
import colors from 'styles/colors';

import { getDarkMode } from 'selectors/theme';

import { setProjectPhotosFilters } from './store/actions';
import { getProjectPhotosFilters } from './store/selectors';

const ProjectPhotosFilterFavorite = (props) => {
  const { filters, darkMode, outlined, width, height, dispatch } = props;

  const favorite = filters.favorites;
  const toggleFavorite = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setProjectPhotosFilters({ favorites: !favorite, doSearch: true }));
  };

  const color = favorite
    ? darkMode
      ? colors.neon
      : colors['primary-green']
    : colors['dark-mode-light-white'];

  const strokeColor = outlined
    ? colors['light-mode-dark-black']
    : colors['white-text'];

  return (
    <Button
      onClick={toggleFavorite}
      type={BUTTON_TYPES.LINK}
      label={
        <FavoriteIcon
          stroke={favorite ? color : strokeColor}
          fill={color}
          outlined={outlined}
          width={width}
          height={height}
        />
      }
    />
  );
};

export default connect((state) => ({
  filters: getProjectPhotosFilters(state),
  darkMode: getDarkMode(state),
}))(ProjectPhotosFilterFavorite);
