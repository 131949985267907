const colors = {
  'dark-mode-black': '#191b24',
  'dark-mode-grey': '#21242f',
  'white-text': '#ffffff',
  neon: '#d4fb00',
  'white-main': '#ffffff',
  'light-grey': '#f7f7f8',
  'light-grey-over-light-grey': '#f3f3f3',
  'very-light-grey': '#fcfcfc',
  'light-line-grey': '#f0f1f2',
  'dark-line-grey': '#3a3e4a',
  'primary-green': '#00b091',
  'dark-mode-green': '#00fb92',
  'light-mode-black-text': '#454a5c',
  'dark-mode-medium-gray': '#303441',
  'dark-mode-medium-gray-light': '#363a46',
  black: '#000000',
  'light-mode-medium-black': '#9ea0aa',
  'light-mode-clear-black': '#eaeaec',
  'light-mode-dark-black': '#7a7e8b',
  'dark-mode-light-white': '#bcbdc1',
  'dark-mode-medium-white': '#909197',
  'dark-mode-light-grey': '#3b4050',
  'dark-mode-grey-over-light-grey': '#43495a',
  'dark-mode-notifications-light-grey': '#2b2f3d',
  'dark-mode-notifications-dark-grey': '#252834',
  'dark-mode-clear-gray': '#A3A3A3',
  'chart-blue': '#02C7FF',
  'chart-red': '#FB6120',
};

export const muiThemeColor = (light, dark, condition) => (props) =>
  props.darkMode ? colors[dark] || dark : colors[light] || light;

export default colors;
