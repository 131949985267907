import { PURCHASE_ORDERS_SET } from 'components/admin/purchase-orders/store/action-types';

const initialState = {
  purchaseOrders: null,
};

export default function purchaseOrdersDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case PURCHASE_ORDERS_SET:
      return {
        ...state,
        purchaseOrders: action.payload,
      };

    default:
      return state;
  }
}
