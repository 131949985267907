import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import './LogoUpload.css';
import SimpleButton from 'components/shared/button/SimpleButton';
import plusIconPng from 'images/plus-icon.png';
import removeSvg from 'images/remove.svg';
import removeDarkSvg from 'images/remove-dark.svg';
import plusSvg from 'images/mdi_plus-circle.svg';
import plusSvgDark from 'images/mdi_plus-circle-dark.svg';
import uploadSvg from '../document-upload/upload.svg';
import uploadSvgDark from '../document-upload/uploadDarkMode.svg';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import FullScreenImageViewer from 'components/shared/logo-upload/FullScreenImageViewer';
import Dropzone from 'react-dropzone';

export const LOGO_UPLOAD_ICON = {
  DEFAULT: {
    light: uploadSvg,
    dark: uploadSvgDark,
  },
  PLUS: {
    light: plusSvg,
    dark: plusSvgDark,
  },
};

class LogoUpload extends Component {
  static propTypes = {
    className: PropTypes.string,
    extensions: PropTypes.arrayOf(PropTypes.string),
    logos: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        preview: PropTypes.string,
        file: PropTypes.object,
      })
    ),
    icon: PropTypes.shape({
      light: PropTypes.string,
      dark: PropTypes.string,
    }),
    min: PropTypes.number,
    max: PropTypes.number,
    notes: PropTypes.string,
    dropzoneLabel: PropTypes.string,
    readOnly: PropTypes.bool,
    deleteConfirmationTitle: PropTypes.string,
    deleteConfirmationMessage: PropTypes.string,
    viewMode: PropTypes.string,
    valid: PropTypes.bool,
    onRemove: PropTypes.func,
    onUpload: PropTypes.func,
  };

  static defaultProps = {
    extensions: [],
    logos: [],
    min: 0,
    max: 0,
    notes: null,
    valid: true,
    dropzoneLabel: 'Drag & Drop or Browse Logo',
    deleteConfirmationTitle: 'Remove logo',
    deleteConfirmationMessage: 'Are you sure?',
    viewMode: 'img',
    icon: LOGO_UPLOAD_ICON.DEFAULT,
  };

  constructor(props) {
    super(props);
    this.state = {
      logosIndexToRemove: 0,
      fullScreenUrl: '',
      showConfirmation: false,
      showFullScreen: false,
    };
  }

  handleAddPhotoClick = () => {
    this.fileUpload.click();
  };

  validateFile(name) {
    const index = name.lastIndexOf('.');
    if (index === -1) {
      // The file has no extension, so is not valid
      alert(
        'File "' + name + '" could not be uploaded because has no extension.'
      );
      return false;
    } else if (this.props.extensions.length > 0) {
      const fileExtension = name.substring(index);
      if (
        this.props.extensions.find(
          (element) => element.toLowerCase() === fileExtension.toLowerCase()
        ) === undefined
      ) {
        alert(
          'File "' +
            name +
            '" has an invalid extensions, should be one of [' +
            this.props.extensions.join(', ') +
            '].'
        );
        return false;
      }
    }

    return true;
  }

  handleFullScreenOpen = (url, event) => {
    event.preventDefault();
    this.setState({
      fullScreenUrl: url,
      showFullScreen: true,
    });
  };

  handleFullScreenClose = () => {
    this.setState({
      showFullScreen: false,
    });
  };

  handleNewLogosUpload = (files) => {
    let newFiles = [];
    for (let i = 0; i < files.length; i++) {
      if (this.validateFile(files.item(i).name)) {
        newFiles.push(files.item(i));
      }
    }

    if (this.props.max > 0) {
      // Remove extra files uploaded
      newFiles = newFiles.slice(0, this.props.max);
    }

    // Clear input to allow to upload the same file twice
    this.fileUpload.value = '';

    this.props.onUpload(newFiles);
  };

  handleRemoveRequest(index, event) {
    event.preventDefault();
    this.setState({
      logoIndexToRemove: index,
      showConfirmation: true,
    });
  }

  handleRemove = (result) => {
    if (result) {
      this.props.onRemove(this.state.logoIndexToRemove);
    }
    this.setState({
      logoIndexToRemove: 0,
      showConfirmation: false,
    });
  };
  render() {
    return (
      <div
        className={`logo-upload ${this.props.className || ''} ${
          !this.props.valid ? 'invalid' : ''
        } ${this.props.darkMode ? 'dark-mode' : ''}`}
      >
        <div className="preview-container">
          {this.props.logos.map((logo, index) => {
            if (logo.preview !== undefined && logo.preview !== null) {
              return this.getLogoDiv(index, logo.preview, {
                show: this.props.readOnly && logo.url !== null,
                url: logo.url,
              });
            } else if (logo.url !== undefined && logo.url !== null) {
              return this.getLogoDiv(index, logo.url, {
                show: this.props.readOnly,
                url: logo.url,
              });
            } else if (logo.file !== undefined && logo.file !== null) {
              return this.getLogoDiv(index, logo.file.img);
            } else {
              return null;
            }
          })}

          <ConfirmationModal
            show={this.state.showConfirmation}
            title={this.props.deleteConfirmationTitle}
            message={this.props.deleteConfirmationMessage}
            onHide={this.handleRemove}
          />
        </div>

        {!this.props.readOnly && this.props.logos.length === 0 ? (
          <div className="logo-upload-button-container">
            {!this.props.useDropzone ? (
              <div>
                <SimpleButton
                  className="add-photos-button"
                  disabled={
                    this.props.max > 0
                      ? this.props.logos.length >= this.props.max
                      : false
                  }
                  img={plusIconPng}
                  label={this.props.max === 1 ? 'Add Logo' : 'Add Logos'}
                  onClick={this.handleAddPhotoClick}
                />
              </div>
            ) : (
              <Dropzone
                accept="image/*"
                className="files-dropzone dropzone-container"
                disabled={
                  this.props.max > 0
                    ? this.props.logos.length >= this.props.max
                    : false
                }
                onDrop={(files) => this.props.onUpload(files)}
              >
                <img
                  src={
                    this.props.darkMode
                      ? this.props.icon.dark
                      : this.props.icon.light
                  }
                  alt="remove"
                />
                <p className="add-message">{this.props.dropzoneLabel}</p>
              </Dropzone>
            )}

            <input
              accept={
                this.props.extensions.length > 0
                  ? this.props.extensions
                  : 'image/*'
              }
              multiple
              ref={(input) => {
                this.fileUpload = input;
              }}
              style={{ display: 'none' }}
              type="file"
              onChange={(event) => {
                event.preventDefault();
                this.handleNewLogosUpload(event.target.files);
              }}
            />
          </div>
        ) : null}

        <FullScreenImageViewer
          show={this.state.showFullScreen}
          url={this.state.fullScreenUrl}
          onClose={this.handleFullScreenClose}
        />
      </div>
    );
  }

  /**
   *
   * @param index
   * @param source
   * @param fullScreenInfo  - { show: boolean, url: string }
   * @return {*}
   */
  getLogoDiv(index, source, fullScreenInfo = null) {
    const img = this.getLogoView(source, fullScreenInfo);

    if (fullScreenInfo !== null && fullScreenInfo.show) {
      return (
        <div
          key={index}
          onClick={this.handleFullScreenOpen.bind(this, fullScreenInfo.url)}
        >
          {img}
        </div>
      );
    } else {
      return (
        <div key={index}>
          {img}
          {this.props.onRemove !== undefined && (
            <div className="remove-container">
              <img
                src={this.props.darkMode ? removeDarkSvg : removeSvg}
                alt=""
                onClick={this.handleRemoveRequest.bind(this, index)}
              />
            </div>
          )}
        </div>
      );
    }
  }

  getLogoView(source, fullScreenInfo) {
    const { viewMode } = this.props;
    const className = `logoImage ${
      fullScreenInfo ? 'img-full-screen' : ''
    } ${viewMode}Mode`;

    switch (viewMode) {
      case 'img':
        source = typeof source === 'object' ? source.url : source;
        return <img className={className} src={source} alt={''} />;
      default:
        return (
          <div
            className={className}
            style={{
              backgroundImage: `url("${source}")`,
            }}
          ></div>
        );
    }
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(LogoUpload);
