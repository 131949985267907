import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/shared/button/Button';

function CounterButton(props) {
  return (
    <Button
      className={props.className}
      disabled={props.disabled}
      icon={props.icon}
      onClick={props.onClick}
      label={
        props.counter || props.counter === 0 ? (
          <React.Fragment>
            {props.label}
            <sup className="button-counter">{props.counter}</sup>
          </React.Fragment>
        ) : (
          props.label
        )
      }
    />
  );
}

CounterButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  counter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  icon: PropTypes.string,
  onClick: PropTypes.func,
};

CounterButton.defaultProps = {
  disabled: false,
  icon: null,
};

export default CounterButton;
