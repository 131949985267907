import axios from 'axios';
import BaseApi from './base-api';

class MeetingInfosApi extends BaseApi {
  getMeetingInfo(projectId, meetingType) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/meeting_info${
          meetingType ? `?type=${meetingType}` : ''
        }`
      )
      .then((response) => {
        return {
          ok: true,
          meetingInfo: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createMeetingInfo(projectId, values) {
    const data = this.meetingInfoParams(values);

    return axios
      .post(`${this.baseUrl}/projects/${projectId}/meeting_info`, data)
      .then((response) => {
        return {
          ok: true,
          meetingInfo: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateMeetingInfo(projectId, values) {
    const data = this.meetingInfoParams(values);

    return axios
      .put(`${this.baseUrl}/projects/${projectId}/meeting_info`, data)
      .then((response) => {
        return {
          ok: true,
          meetingInfo: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  meetingInfoParams(values) {
    return {
      meeting_info: {
        meeting_type_id: values.meetingTypeId,
        day_of_week: values.dayOfWeek,
        starts_at: values.startsAt,
        ends_at: values.endsAt,
        time_zone: values.timeZone,
        meeting_location: values.meetingLocation,
        site_walk: values.siteWalk,
        phone_number: values.phoneNumber,
        code: values.code,
        host_pin: values.hostPin,
        web_link: values.webLink,
        link_title: values.linkTitle,
      },
    };
  }
}

export const meetingInfosApi = new MeetingInfosApi();
