import {
  REPORT_PAGE_CHANGE,
  REPORT_START_DATE_CHANGE,
  REPORT_END_DATE_CHANGE,
  REPORT_EXTRA_FILTER_VALUE_CHANGE,
  REPORT_SEARCHING_CHANGE,
  REPORTS_RESET,
  REPORT_WORK_ORDER_SELECT_SET,
  REPORT_WORK_ORDER_SELECT_CLEAR,
} from 'actions/admin/reports/action-types';
import OutstandingPricingReport from 'components/admin/reports/OutstandingPricingReport';
import OpenPricingReport from 'components/admin/reports/OpenPricingReport';
import WorkOrderVarianceReport from 'components/admin/reports/WorkOrderVarianceReport';
import ReceiptsReport from 'components/admin/reports/ReceiptsReport';
import ContractorWoVarianceReport from 'components/admin/reports/ContractorWoVarianceReport';
import MissingContractorInvoicesReport from 'components/admin/reports/MissingContractorInvoicesReport';
import OpenItemsReport from 'components/admin/reports/OpenItemsReport';
import ProjectOpenItemsReport from 'components/admin/reports/ProjectOpenItemsReport';
import moment from 'moment';

const initialState = {
  reports: [
    {
      name: 'Outstanding AGG Pricing',
      description:
        'WO Status: Pending, Task Status: AGG, Quote Status: Waiting - By Bid Due Date',
      route: 'outstanding-pricing-report',
      component: OutstandingPricingReport,
      admin: true,
    },
    {
      name: 'Open RFP Pricing',
      description:
        'WO Status: Pending, Task Status: RFP - By Target Completion Date',
      route: 'open-pricing-report',
      component: OpenPricingReport,
      admin: true,
    },
    {
      name: 'Work Order Variance',
      description: 'WO Status: Complete - By Invoice Creation Date',
      route: 'work-order-variance-report',
      component: WorkOrderVarianceReport,
      admin: true,
    },
    {
      name: 'Materials',
      description: 'WO Materials - By Materials Uploaded Date',
      route: 'receipts-report',
      component: ReceiptsReport,
      contractor: true,
    },
    {
      name: 'Contractor Work Order Variance',
      description: 'WO Status: Complete - By Completion Date',
      route: 'contractor-wo-variance-report',
      component: ContractorWoVarianceReport,
      contractor: true,
    },
    {
      name: 'Open Items Report',
      description: 'Open Items: By Due Date and Status',
      route: 'open-items-report',
      component: OpenItemsReport,
      admin: true,
    },
    {
      name: 'Missing Contractor Invoices Report',
      description: 'WO Status: Complete or Closed - By Completion Date',
      route: 'missing-contractor-invoices-report',
      component: MissingContractorInvoicesReport,
      admin: true,
    },
    {
      name: 'Open Items Report',
      description: 'Open Items: By Due Date and Status',
      route: 'project-open-items-report',
      component: ProjectOpenItemsReport,
      generalContractor: true,
    },
  ],
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment().add(1, 'month').format('YYYY-MM-DD'),
  extraFilterValues: {},
  searching: false,
  selectAllChecked: false,
  selectedWorkOrders: {},
};

export default function reportsUiReducer(state = initialState, action) {
  switch (action.type) {
    case REPORT_PAGE_CHANGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case REPORT_START_DATE_CHANGE:
      return {
        ...state,
        startDate: action.payload,
      };

    case REPORT_END_DATE_CHANGE:
      return {
        ...state,
        endDate: action.payload,
      };

    case REPORT_EXTRA_FILTER_VALUE_CHANGE:
      return {
        ...state,
        extraFilterValues: {
          ...state.extraFilterValues,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case REPORT_SEARCHING_CHANGE:
      return {
        ...state,
        searching: action.payload,
      };

    case REPORT_WORK_ORDER_SELECT_CLEAR:
      return {
        ...state,
        selectAllChecked: initialState.selectAllChecked,
        selectedWorkOrders: initialState.selectedWorkOrders,
      };

    case REPORT_WORK_ORDER_SELECT_SET:
      return {
        ...state,
        selectAllChecked: action.payload.allChecked,
        selectedWorkOrders: {
          ...state.selectedWorkOrders,
          ...action.payload.selectedWorkOrders,
        },
      };

    case REPORTS_RESET:
      return initialState;

    default:
      return state;
  }
}
