import {
  OPEN_ITEMS_LOAD_SUCCESS,
  OPEN_ITEMS_MODAL_CLOSE,
  OPEN_ITEM_CREATE_SUCCESS,
  OPEN_ITEM_LOAD_SUCCESS,
  OPEN_ITEM_UPDATE_SUCCESS,
  OPEN_ITEM_DELETE_SUCCESS,
  OPEN_ITEMS_COUNT_SUCCESS,
  OPEN_ITEMS_COUNT_INIT,
  OPEN_ITEMS_CRITICAL_SET,
  OPEN_ITEM_NOTES_LOAD_SUCCESS,
  OPEN_ITEM_NOTE_CREATE_SUCCESS,
  OPEN_ITEM_NOTE_CLEAR,
  OPEN_ITEM_UPDATE_VALUE,
} from 'components/admin/projects/details/project-open-items/store/action-types';

const initialState = {
  projectOpenItems: [],
  currentPage: 1,
  total: 0,
  pageSize: 25,
  currentProjectOpenItem: null,
  projectOpenItemsCount: null,
  projectOpenItemsCritCount: null,
  projectOpenItemNotes: [],
  dueDateFilter: 'all',
};

export default function projectOpenItemsDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case OPEN_ITEMS_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case OPEN_ITEMS_MODAL_CLOSE:
      return {
        ...initialState,
        projectOpenItemsCount: state.projectOpenItemsCount,
        projectOpenItemsCritCount: state.projectOpenItemsCritCount,
      };

    case OPEN_ITEM_CREATE_SUCCESS:
      return {
        ...state,
        projectOpenItems: [action.payload, ...state.projectOpenItems],
      };

    case OPEN_ITEM_LOAD_SUCCESS:
      return {
        ...state,
        currentProjectOpenItem: action.payload,
      };

    case OPEN_ITEM_UPDATE_SUCCESS:
      return {
        ...state,
        projectOpenItems: state.projectOpenItems.map((openItem) => {
          return openItem.id === action.payload.id ? action.payload : openItem;
        }),
      };

    case OPEN_ITEM_DELETE_SUCCESS:
      return {
        ...state,
        projectOpenItems: state.projectOpenItems.filter((openItem) => {
          return openItem.id !== action.payload;
        }),
      };

    case OPEN_ITEMS_COUNT_SUCCESS:
      return {
        ...state,
        projectOpenItemsCount: action.payload.total,
        projectOpenItemsCritCount: action.payload.totalCritical,
      };

    case OPEN_ITEMS_COUNT_INIT:
      return {
        ...state,
        projectOpenItemsCount: initialState.projectOpenItemsCount,
        projectOpenItemsCritCount: initialState.projectOpenItemsCritCount,
      };

    case OPEN_ITEMS_CRITICAL_SET:
      return {
        ...state,
        critical: action.payload,
      };

    case OPEN_ITEM_NOTES_LOAD_SUCCESS:
      return {
        ...state,
        projectOpenItemNotes: action.payload,
      };

    case OPEN_ITEM_NOTE_CREATE_SUCCESS:
      return {
        ...state,
        projectOpenItemNotes: [...state.projectOpenItemNotes, action.payload],
      };

    case OPEN_ITEM_NOTE_CLEAR:
      return {
        ...state,
        projectOpenItemNotes: [],
      };

    case OPEN_ITEM_UPDATE_VALUE:
      return {
        ...state,
        currentProjectOpenItem: {
          ...state.currentProjectOpenItem,
          [action.payload.attr]: action.payload.value,
        },
      };

    default:
      return state;
  }
}
