import {
  CONTRACTOR_TEAM_INVITE_USER_FORM_INVALID,
  CONTRACTOR_TEAM_INVITE_USER_FORM_LOAD,
  CONTRACTOR_TEAM_INVITE_USER_FORM_VALID,
  CONTRACTOR_TEAM_INVITE_USER_FORM_VALUE_UPDATE,
  CONTRACTOR_TEAM_INVITE_USER_ID_SET,
  CONTRACTOR_TEAM_INVITE_USER_MODAL_SHOW,
  CONTACTOR_TEAM_CURRENT_PAGE_TECHNICIANS,
  CONTACTOR_TEAM_PER_PAGE_TECHNICIANS,
  CONTACTOR_TEAM_TOTAL_TECHNICIANS,
  CONTACTOR_TEAM_SET_ACTIVE_TAB,
  CONTRACTOR_TEAM_INVITE_TECHNICIAN_MODAL_SHOW,
  CONTRACTOR_TEAM_INVITE_TECHNICIAN_ID_SET,
  CONTRACTOR_TEAM_SHOW_VERIFICATION_MODAL,
} from 'actions/admin/team/action-types';
import { UserModalState } from 'components/shared/modal/InviteUserModal';

export const initialState = {
  currentUserId: 0,
  currentTechnicianId: 0,
  form: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    rolesId: null,
  },
  invalidFields: [],
  showInviteUserModal: false,
  showInviteTechnicianModal: false,
  userModalState: UserModalState.NEW, // one of UserModalState
  technicianModalState: UserModalState.NEW,
  validationMessage: '',
  currentPageTechnicians: 1,
  perPageTechnicians: 1,
  totalTechnicians: 1,
  activeTab: 1,
  verificationModal: null,
};

export default function contractorTeamUiReducer(state = initialState, action) {
  switch (action.type) {
    case CONTRACTOR_TEAM_INVITE_USER_FORM_INVALID:
      return {
        ...state,
        invalidFields: action.payload.invalidFields,
        validationMessage: action.payload.message,
      };

    case CONTRACTOR_TEAM_INVITE_USER_FORM_LOAD:
      return {
        ...state,
        form: action.payload,
        invalidFields: [],
        validationMessage: '',
      };

    case CONTRACTOR_TEAM_INVITE_USER_FORM_VALID:
      return {
        ...state,
        invalidFields: [],
        validationMessage: '',
      };

    case CONTRACTOR_TEAM_INVITE_USER_FORM_VALUE_UPDATE:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case CONTRACTOR_TEAM_INVITE_USER_ID_SET:
      return {
        ...state,
        currentUserId: action.payload,
      };

    case CONTRACTOR_TEAM_INVITE_TECHNICIAN_ID_SET:
      return {
        ...state,
        currentTechnicianId: action.payload,
      };

    case CONTRACTOR_TEAM_INVITE_USER_MODAL_SHOW:
      const newState = {
        ...state,
        showInviteUserModal: action.payload.open,
      };
      if (action.payload.state !== undefined) {
        newState['userModalState'] = action.payload.state;
      }

      return newState;

    case CONTRACTOR_TEAM_INVITE_TECHNICIAN_MODAL_SHOW:
      const newStateTechnician = {
        ...state,
        showInviteTechnicianModal: action.payload.open,
      };
      if (action.payload.state !== undefined) {
        newStateTechnician['technicianModalState'] = action.payload.state;
      }

      return newStateTechnician;
    case CONTACTOR_TEAM_CURRENT_PAGE_TECHNICIANS:
      return {
        ...state,
        currentPageTechnicians: action.payload,
      };

    case CONTACTOR_TEAM_PER_PAGE_TECHNICIANS:
      return {
        ...state,
        perPageTechnicians: action.payload,
      };

    case CONTACTOR_TEAM_TOTAL_TECHNICIANS:
      return {
        ...state,
        totalTechnicians: action.payload,
      };

    case CONTACTOR_TEAM_SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };

    case CONTRACTOR_TEAM_SHOW_VERIFICATION_MODAL:
      return {
        ...state,
        verificationModal: action.payload,
      };

    default:
      return state;
  }
}
