import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormModal from 'components/shared/modal/FormModal';
import UserForm from 'components/admin/companies/UserForm';
import { loadRoles } from './store/actions';
import { getRoles } from './store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import DirectoryPermissions from 'permissions/directory-permissions';

class UserModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
      title: PropTypes.string,
      assignedRole: PropTypes.string,
      accountAdmin: PropTypes.bool,
      accountableId: PropTypes.number,
    }),
  };

  componentDidMount() {
    const { roles, dispatch } = this.props;
    if (!roles) dispatch(loadRoles());
  }

  render() {
    const {
      show,
      handleSubmit,
      handleClose,
      handleDelete,
      initialValues,
      currentUser,
      roles,
    } = this.props;

    const title = initialValues ? 'Edit User' : 'Invite User';
    const canSetAccountAdmin = DirectoryPermissions.canSetAccountAdmin(
      currentUser
    );

    return (
      roles && (
        <FormModal
          className="invite-edit-user-form-modal"
          show={show}
          subTitle={title}
          onClose={handleClose}
        >
          <UserForm
            onSubmit={handleSubmit}
            onClose={handleClose}
            onDelete={handleDelete}
            initialValues={initialValues}
            roles={roles}
            canSetAccountAdmin={canSetAccountAdmin}
          />
        </FormModal>
      )
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    roles: getRoles(state),
  };
})(UserModal);
