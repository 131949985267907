import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import FormControlBlock from 'components/shared/form/FormControlBlock';
import CharsCounterTextarea from 'components/shared/chars-counter/CharsCounterTextarea';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { loadPreviousFieldReport } from './store/actions';

const FieldReportDisclaimer = (props) => {
  const { dispatch, handleFormChange, form, currentProject } = props;

  const handleLoadPrevius = async () => {
    const response = await dispatch(loadPreviousFieldReport(currentProject.id));
    if (response.ok && response.report && response.report.disclaimer) {
      const loadValues = { disclaimer: response.report.disclaimer };
      handleFormChange(loadValues);
    }
  };

  useEffect(() => {
    if (!form.id && !form.disclaimer) handleLoadPrevius();
  }, []);

  const InputDisclaimer = (
    <FormControlBlock
      hideBottomLabel
      className={'disclaimer-remark'}
      label={form.disclaimer && 'Remarks'}
      control={
        <CharsCounterTextarea
          placeholder={'Remarks'}
          value={form.disclaimer}
          valid={!(form.errors && form.errors.disclaimer)}
          onChange={(disclaimer) => handleFormChange({ disclaimer })}
          limit={500}
          maxRows={undefined}
        />
      }
    />
  );

  return (
    <div>
      <div className="form-block">{InputDisclaimer}</div>
    </div>
  );
};

export default connect((state) => ({
  currentProject: getCurrentProject(state),
}))(FieldReportDisclaimer);
