import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './PropertyOwnerModal.css';
import FormModal from 'components/shared/modal/FormModal';
import Input from 'components/shared/input/Input';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { updateProjectLocation } from 'components/admin/projects/details/project-closeout/store/actions';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

class PropertyOwnerModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { form: this.initForm(props.currentProject.project_location) };
  }

  initForm = (projectLocation) => {
    return {
      name: projectLocation.property_owner_name || '',
      locationId:
        projectLocation.property_owner_address &&
        projectLocation.property_owner_address.id,
      streetAddress1:
        (projectLocation.property_owner_address &&
          projectLocation.property_owner_address.street_address_1) ||
        '',
      streetAddress2:
        (projectLocation.property_owner_address &&
          projectLocation.property_owner_address.street_address_2) ||
        '',
      city:
        (projectLocation.property_owner_address &&
          projectLocation.property_owner_address.city) ||
        '',
      state:
        (projectLocation.property_owner_address &&
          projectLocation.property_owner_address.state) ||
        '',
      zip:
        (projectLocation.property_owner_address &&
          projectLocation.property_owner_address.zip) ||
        '',
    };
  };

  mapProjectLocationData = () => {
    const { form } = this.state;
    const { currentProject } = this.props;
    const projectLocation = currentProject.project_location;
    return {
      propOwnerName: form.name,
      propOwnerAddress: {
        id: form.locationId,
        streetAddress: form.streetAddress1,
        unitNumber: form.streetAddress2,
        city: form.city,
        state: form.state,
        zipCode: form.zip,
      },
      location: {
        id: projectLocation.location.id,
        streetAddress: projectLocation.location.street_address_1,
        unitNumber: projectLocation.location.street_address_2 || '',
        city: projectLocation.location.city,
        state: projectLocation.location.state,
        zipCode: projectLocation.location.zip,
      },
    };
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { currentProject, onSubmit, dispatch } = this.props;
    const values = this.mapProjectLocationData();
    dispatch(
      updateProjectLocation(currentProject.project_location.id, values)
    ).then((response) => {
      if (response.ok) {
        onSubmit();
      }
    });
  };

  handleFormChange = (attribute, value) => {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [attribute]: value,
      },
    });
  };

  formValid = () => {
    const { form } = this.state;
    return (
      !!form.name &&
      !!form.streetAddress1 &&
      !!form.city &&
      !!form.state &&
      !!form.zip
    );
  };

  render() {
    const { form } = this.state;
    const { show, onClose } = this.props;
    return (
      <div id="property-owner-form-modal">
        <FormModal
          className="property-owner-modal"
          show={show}
          subTitle="Please provide the Property Owner information."
          onClose={onClose}
        >
          <Form horizontal onSubmit={this.handleSubmit}>
            <div className="form-block">
              <label className="form-block-label">Property Owner Name</label>
              <Input
                id="name"
                placeholder="Property Owner Name"
                value={form.name}
                theme="normal"
                onChange={this.handleFormChange.bind(this, 'name')}
              />
            </div>
            <div className="form-block">
              <label className="form-block-label">Address Line 1</label>
              <Input
                id="street-address-1"
                placeholder="Street Address"
                value={form.streetAddress1}
                theme="normal"
                onChange={this.handleFormChange.bind(this, 'streetAddress1')}
              />
            </div>
            <div className="form-block">
              <label className="form-block-label">Address Line 2</label>
              <Input
                id="street-address-2"
                placeholder="Unit number / Floor"
                value={form.streetAddress2}
                theme="normal"
                onChange={this.handleFormChange.bind(this, 'streetAddress2')}
              />
            </div>
            <div className="form-block">
              <label className="form-block-label">City</label>
              <Input
                id="city"
                placeholder="City"
                theme="normal"
                value={form.city}
                onChange={this.handleFormChange.bind(this, 'city')}
              />
            </div>
            <div className="form-block">
              <label className="form-block-label">State / Zip</label>
              <Input
                id="state"
                placeholder="State"
                value={form.state}
                theme="normal"
                onChange={this.handleFormChange.bind(this, 'state')}
              />
              <Input
                id="zip"
                placeholder="Zip"
                value={form.zip}
                theme="normal"
                onChange={this.handleFormChange.bind(this, 'zip')}
              />
            </div>

            <div className="buttons-container">
              <Button
                id="send-invite"
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.WHITE}
                label="Cancel"
                onClick={onClose}
              />
              <Button
                id="send-invite"
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.GREEN}
                label="Add"
                onClick={this.handleSubmit}
                disabled={!this.formValid()}
              />
            </div>
          </Form>
        </FormModal>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
  };
})(PropertyOwnerModal);
