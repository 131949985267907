import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import MultipleSelector from 'components/shared/selector/MultipleSelector';
import { createFilterOptions } from '@mui/material';

import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getProjectTags } from 'components/admin/projects/details/project-tags/store/selectors';

import {
  createProjectTag,
  loadProjectTags,
} from '../project-tags/store/actions';

const filter = createFilterOptions();

const ProjectPhotosFilterTags = (props) => {
  const {
    dispatch,
    value,
    onChange,
    currentProject,
    projectTags,
    enableAdd,
  } = props;

  const onFilter = async (tags) => {
    if (
      tags.length > 0 &&
      typeof tags[tags.length - 1].value === 'string' &&
      tags[tags.length - 1].label.startsWith('Add')
    ) {
      const tag = tags[tags.length - 1];
      const response = await dispatch(
        createProjectTag(currentProject.id, tag.value)
      );
      if (response.ok) {
        const { id: value, label } = response.data;
        tags[tags.length - 1] = { value, label };
      } else {
        tags.splice(tags.length - 1, 1);
      }
    }
    onChange({ tags });
  };

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);

    if (filtered.length === 0 && params.inputValue !== '') {
      filtered.push({
        value: params.inputValue,
        label: `Add "${params.inputValue}"`,
      });
    }

    return filtered;
  };

  useEffect(() => {
    if (currentProject.id && (!projectTags || projectTags.length === 0)) {
      dispatch(loadProjectTags(currentProject.id));
    }
  }, []);

  return (
    <MultipleSelector
      onChange={onFilter}
      value={value || []}
      className="tags-dropdown"
      placeholder="Tag"
      options={projectTags}
      filterOptions={enableAdd && filterOptions}
    />
  );
};

export default connect((state) => ({
  currentProject: getCurrentProject(state),
  projectTags: getProjectTags(state),
}))(ProjectPhotosFilterTags);
