import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import DocumentUpload, {
  FILE_TYPES,
} from 'components/shared/document-upload/DocumentUpload';
import DocumentLink from 'components/shared/document/DocumentLink';
import ImageUpload from 'components/shared/image-upload/ImageUpload';
import { imageExtensions } from 'services/utils/files-util';
import {
  getOpenItemForm,
  getOpenItemFormErrors,
} from 'components/admin/projects/details/project-open-items/store/selectors';
import {
  setOpenItemFormAttr,
  clearOpenItemFormErrorAttr,
  resetOpenItemForm,
} from 'components/admin/projects/details/project-open-items/store/actions';
import FormControlBlock from 'components/shared/form/FormControlBlock';

class ProjectOpenItemRespondForm extends Component {
  componentWillUnmount() {
    this.props.dispatch(resetOpenItemForm());
  }

  handleAttributeChange = (attr, value) => {
    this.props.dispatch(setOpenItemFormAttr(attr, value));
    this.props.dispatch(clearOpenItemFormErrorAttr(attr));
  };

  getArrayAttribute = (attr) => {
    const { form } = this.props;
    if (form && form[attr] && form[attr].length > 0) {
      return form[attr];
    }
    return [];
  };

  setImages = (files) => {
    const images = [...this.getArrayAttribute('images')];
    files.forEach(function (val) {
      images.push({
        url: val.url || URL.createObjectURL(val),
        file: val,
      });
    });

    this.handleAttributeChange('images', images);
  };

  removeImage = (index) => {
    const images = [...this.getArrayAttribute('images')];
    const deletedImages = [...this.getArrayAttribute('deletedImages')];
    const deletedImage = images.splice(index, 1);
    deletedImages.push(deletedImage[0]);

    this.handleAttributeChange('images', images);
    this.handleAttributeChange('deletedImages', deletedImages);
  };

  setDocuments = (files) => {
    const docs = [...this.getArrayAttribute('documents')];
    files.forEach(function (val) {
      docs.push({
        file: val,
        name: val.name,
      });
    });

    this.handleAttributeChange('documents', docs);
  };

  removeDocument = (index) => {
    const docs = [...this.getArrayAttribute('documents')];
    const deletedDocs = [...this.getArrayAttribute('deletedDocuments')];
    const deletedDoc = docs.splice(index, 1);
    deletedDocs.push(deletedDoc[0]);

    this.handleAttributeChange('documents', docs);
    this.handleAttributeChange('deletedDocuments', deletedDocs);
  };

  render() {
    const { form, errors } = this.props;
    return (
      <React.Fragment>
        <div className="form-block">
          <FormControlBlock
            label={!!form.response && 'Response'}
            control={
              <TextareaAutosizeWrapper
                placeholder="Description"
                initialValue={form.response}
                valid={!errors.response}
                onChange={(value) =>
                  this.handleAttributeChange('response', value)
                }
              />
            }
          />
        </div>
        <div className="form-resources-block">
          <div className="form-documents">
            <FormControlBlock
              className="without-focus"
              label="Documents"
              control={
                <div className="form-resources-body">
                  {form.documents &&
                    form.documents.map((doc, index) => {
                      return (
                        <DocumentLink
                          key={index}
                          file={doc}
                          url={doc.url}
                          onRemove={() => this.removeDocument(index)}
                        />
                      );
                    })}
                  <DocumentUpload
                    fileType={FILE_TYPES.ANY}
                    onDrop={(files) => this.setDocuments(files)}
                  />
                </div>
              }
            />
          </div>
          <div className="form-images">
            <FormControlBlock
              className="without-focus"
              label="Photos"
              control={
                <div className="form-resources-body">
                  <ImageUpload
                    extensions={imageExtensions}
                    multiple
                    onUpload={(files) => this.setImages(files)}
                    onRemove={(index) => this.removeImage(index)}
                    images={form.images}
                  />
                </div>
              }
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    form: getOpenItemForm(state),
    errors: getOpenItemFormErrors(state),
  };
})(ProjectOpenItemRespondForm);
