export const MEETING_MINUTES_INIT = 'MEETING_MINUTES_INIT';
export const MEETING_INFO_SET = 'MEETING_INFO_SET';
export const MEETING_INFO_EDIT_SET = 'MEETING_INFO_EDIT_SET';
export const MEETINGS_SET = 'MEETINGS_SET';
export const MEETING_MODAL_SHOW_SET = 'MEETING_MODAL_SHOW_SET';
export const MEETING_CATEGORIES_SET = 'MEETING_CATEGORIES_SET';
export const MEETING_MODAL_INIT = 'MEETING_MODAL_INIT';
export const MEETING_FORM_VALUE_UPDATE = 'MEETING_FORM_VALUE_UPDATE';
export const MEETING_ITEM_VALUE_UPDATE = 'MEETING_ITEM_VALUE_UPDATE';
export const MEETING_ITEM_ADD = 'MEETING_ITEM_ADD';
export const MEETING_ITEMS_ATTACH = 'MEETING_ITEMS_ATTACH';
export const ATTENDEES_MODAL_SHOW_SET = 'ATTENDEES_MODAL_SHOW_SET';
export const REVIEW_MEETING_SET = 'REVIEW_MEETING_SET';
export const MEETINGS_ADD = 'MEETINGS_ADD';
export const MEETING_ITEM_SETTINGS_SET = 'MEETING_ITEM_SETTINGS_SET';
export const CURRENT_MEETING_SET = 'CURRENT_MEETING_SET';
export const MEETINGS_UPDATE = 'MEETINGS_UPDATE';
export const MEETING_PDF_SET = 'MEETING_PDF_SET';
export const TOGGLE_PDF_VIEWER_SUCCESS = 'TOGGLE_PDF_VIEWER_SUCCESS';
export const CONFIRMATION_MSG_SET = 'CONFIRMATION_MSG_SET';
export const CONFIRM_APPROVAL_SET = 'CONFIRM_APPROVAL_SET';
export const MEETING_REMOVE = 'MEETING_REMOVE';
export const MEETING_TYPES_SET = 'MEETING_TYPES_SET';
export const SELECTED_MEETING_TYPE_SET = 'SELECTED_MEETING_TYPE_SET';
