import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BaseStatus from './BaseStatus';
import { USER_STATUS_SORTED, userStatusLabel } from 'domain/user-status-enum';
import './UserStatus.css';

const statusOptions = () =>
  USER_STATUS_SORTED.map((status) => ({
    id: status,
    label: userStatusLabel(status),
  }));

class UserStatus extends Component {
  static propTypes = {
    status: PropTypes.string.isRequired,
  };

  render() {
    const { status } = this.props;

    return (
      <div className="user-status">
        <BaseStatus labels={statusOptions()} status={status} />
      </div>
    );
  }
}

export default UserStatus;
