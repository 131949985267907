import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import SidebarForm from 'components/shared/sidebar-form/SidebarForm';
import {
  getCurrentReportViewId,
  getCurrentReportViewDetails,
  getCurrentReportViewCompanyInformation,
  getCurrentReportViewProjectInformation,
  getCurrentReportViewProjectUsage,
  getCurrentReportViewMaterialOrder,
  getCurrentReportViewSubscription,
  getShowSaveView,
  getRunningReport,
} from './store/selectors';
import {
  showSaveViewModal,
  createReportView,
  updateReportView,
  runReport,
  reloadRunningReport,
  clearRunningReport,
} from './store/actions';
import ReportDetails from './ReportDetails';
import ReportProjectInformation from './ReportProjectInformation';
import ReportProjectUsage from './ReportProjectUsage';
import ReportMaterialOrders from './ReportMaterialOrders';
import ReportSubscriptions from './ReportSubscriptions';
import SaveViewModal from './SaveViewModal';
import RunReportModal from './RunReportModal';
import './ReportsForm.css';

class ReportsForm extends Component {
  constructor(props) {
    super(props);

    this.sections = [
      {
        id: 'details',
        title: 'Report Criteria',
        render: () => <ReportDetails />,
      },
      {
        id: 'project_information',
        title: 'Project Information',
        render: () => <ReportProjectInformation />,
      },
      {
        id: 'project_usage',
        title: 'Project Usage',
        render: () => <ReportProjectUsage />,
      },
      {
        id: 'material_orders',
        title: 'Material Orders',
        render: () => <ReportMaterialOrders />,
      },
      {
        id: 'subscriptions',
        title: 'Subscriptions',
        render: () => <ReportSubscriptions />,
      },
    ];
  }

  validForm = () => {
    const {
      currentReportViewDetails: { report_type, scope },
    } = this.props;

    return !!report_type && (report_type !== 'analytics' || !!scope);
  };

  handleOpenSaveViewForm = () => {
    const { dispatch } = this.props;
    dispatch(showSaveViewModal());
  };

  makeRequest = () => {
    const {
      currentReportViewDetails,
      currentReportViewCompanyInformation,
      currentReportViewProjectInformation,
      currentReportViewProjectUsage,
      currentReportViewMaterialOrder,
      currentReportViewSubscription,
    } = this.props;

    return {
      details: currentReportViewDetails,
      company_information: currentReportViewCompanyInformation,
      project_information: currentReportViewProjectInformation,
      project_usage: currentReportViewProjectUsage,
      material_order: currentReportViewMaterialOrder,
      subscription: currentReportViewSubscription,
    };
  };

  handleSaveView = () => {
    const { currentViewId, dispatch } = this.props;

    const values = this.makeRequest();
    dispatch(
      currentViewId
        ? updateReportView(currentViewId, values)
        : createReportView(values)
    );
  };

  handleSubmit = () => {
    const { dispatch } = this.props;

    const values = this.makeRequest();
    dispatch(runReport(values));
  };

  handleReloadRunningReport = () => {
    const { runningReport, dispatch } = this.props;

    if (runningReport && runningReport.status === 'pending') {
      dispatch(reloadRunningReport(runningReport.id));
    }
  };

  downloadDocument = () => {
    const {
      runningReport: { document },
    } = this.props;

    const url = document && document.document && document.document.url;
    if (url) {
      window.open(url);
    }
  };

  hideRunningModal = () => {
    const { dispatch, runningReport } = this.props;

    if (runningReport && runningReport.status === 'done') {
      this.downloadDocument(document);
    }
    dispatch(clearRunningReport());
  };

  render() {
    const { runningReport, showSaveView } = this.props;
    const validForm = this.validForm();

    return (
      <React.Fragment>
        <SidebarForm
          className="superadmin-reports-form"
          title="Report Details"
          sections={this.sections}
          actions={[
            <Button
              key="save-view"
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.TRANSPARENT}
              label="Save View"
              disabled={!validForm}
              onClick={this.handleOpenSaveViewForm}
            />,
            <Button
              key="submit"
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Submit"
              disabled={!validForm}
              onClick={this.handleSubmit}
            />,
          ]}
        />
        {showSaveView && <SaveViewModal onSave={this.handleSaveView} />}
        {!!runningReport && (
          <RunReportModal
            runningReport={runningReport}
            onClose={this.hideRunningModal}
            onReload={this.handleReloadRunningReport}
          />
        )}
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    currentViewId: getCurrentReportViewId(state),
    currentReportViewDetails: getCurrentReportViewDetails(state),
    currentReportViewCompanyInformation: getCurrentReportViewCompanyInformation(
      state
    ),
    currentReportViewProjectInformation: getCurrentReportViewProjectInformation(
      state
    ),
    currentReportViewProjectUsage: getCurrentReportViewProjectUsage(state),
    currentReportViewMaterialOrder: getCurrentReportViewMaterialOrder(state),
    currentReportViewSubscription: getCurrentReportViewSubscription(state),
    showSaveView: getShowSaveView(state),
    runningReport: getRunningReport(state),
  };
})(ReportsForm);
