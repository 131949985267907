import axios from 'axios';
import BaseApi from './base-api';

class CloseoutApi extends BaseApi {
  getCloseout(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/closeout`)
      .then((response) => {
        return {
          ok: true,
          closeout: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateCloseout(projectId, values) {
    let apiPayload = new FormData();
    apiPayload.append('closeout[warranty_date]', values.warrantyDate);
    apiPayload.append('closeout[due_date]', values.dueDate);

    values.warrantyDocuments &&
      values.warrantyDocuments.forEach((doc, index) => {
        if (doc.newDoc === true) {
          apiPayload.append(
            `closeout[warranty_documents_attributes][${index}][key]`,
            doc.name
          );
        }
      });

    values.delWarrantyDocuments &&
      values.delWarrantyDocuments.forEach((doc, index) => {
        apiPayload.append(
          `closeout[warranty_documents_attributes][${index}][id]`,
          doc.id
        );
        apiPayload.append(
          `closeout[warranty_documents_attributes][${index}][_destroy]`,
          true
        );
      });

    return axios
      .put(`${this.baseUrl}/projects/${projectId}/closeout`, apiPayload)
      .then((response) => {
        return {
          ok: true,
          closeout: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  requestCloseout(projectId) {
    return axios
      .put(`${this.baseUrl}/projects/${projectId}/closeout/send_request`)
      .then((response) => {
        return {
          ok: true,
          closeout: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  downloadCloseoutDocuments(projectId) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/closeout/send_closeout_documents`
      )
      .then((response) => {
        return {
          ok: true,
          closeout: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const closeoutApi = new CloseoutApi();
