import React, { Component } from 'react';
import { connect } from 'react-redux';
import './NewProject.css';
import SimpleTable from 'components/shared/table/SimpleTable';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import CheckBox from 'components/shared/checkbox/CheckBox';
import { companyTypeOptions } from 'domain/company-type-enum';
import { getCurrentUser } from 'selectors/authentication';
import {
  getSubscribers,
  getCurrentPage,
  getProjectForm,
  getShowNewCompanyModal,
} from 'components/admin/projects/creation/store/selectors';
import {
  loadSubscribersRequest,
  setProjectFormValue,
  nextStage,
  setShowNewCompanyModal,
} from 'components/admin/projects/creation/store/actions';
import { loadCsiCodes } from 'components/admin/projects/details/project-proposal/store/actions';
import { getCsiCodes } from 'components/admin/projects/details/project-proposal/store/selectors';
import NewCompanyModal from 'components/admin/projects/creation/NewCompanyModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import SearchBar from 'components/shared/navigation/SearchBar';
import FormControlBlock, {
  CONTROL_SIZE,
} from 'components/shared/form/FormControlBlock';
import Selector from 'components/shared/selector/Selector';
import User from 'domain/user';

class SubscriberCompanySelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameToSearch: '',
    };
  }

  componentDidMount() {
    this.loadCompanies(1);
    this.props.dispatch(loadCsiCodes());

    if (!this.hasToSelectCompany())
      this.handleProjectFormChange(
        'creatorCompanyId',
        this.props.currentUser.accountable_id
      );
  }

  showAddNewCompanyModal = () => {
    this.props.dispatch(setShowNewCompanyModal(true));
  };

  loadCompanies = (page) => {
    const { nameToSearch } = this.state;
    const { dispatch } = this.props;
    dispatch(loadSubscribersRequest(page, nameToSearch));
  };

  handleProjectFormChange = (attribute, value) => {
    this.props.dispatch(setProjectFormValue(attribute, value));
  };

  handleCompanyTypeChange = (option) => {
    this.handleProjectFormChange('creatorCompanyType', option.value);
    this.handleProjectFormChange('creatorCompanyCsiCode', '');
  };

  handleCompanyCsiCodeChange = (option) => {
    this.handleProjectFormChange('creatorCompanyCsiCode', option.value);
  };

  handleContinue = () => {
    this.props.dispatch(nextStage());
  };

  handlePageChange = (newPage) => {
    this.loadCompanies(newPage);
  };

  handleSearchTextChange = (value) => {
    this.setState({ nameToSearch: value }, () => this.loadCompanies(1));
  };

  hasToSelectCompany = () => User.isAdmin(this.props.currentUser);

  render() {
    const { nameToSearch } = this.state;
    const {
      projectForm,
      subscribers,
      currentPage,
      showNewCompanyModal,
      cancelFunction,
      csiCodes,
    } = this.props;

    const showTable = this.hasToSelectCompany();

    return (
      <React.Fragment>
        <SearchBar
          title="Which Company is the Project for?"
          searchText={nameToSearch}
          onSearchTextChange={this.handleSearchTextChange}
          onSearch={showTable ? this.handleSearchTextChange : null}
          addButton={
            showTable && (
              <Button
                type={BUTTON_TYPES.LINK}
                label="Add New Company"
                icon={BUTTON_ICONS.PLUS}
                onClick={this.showAddNewCompanyModal}
              />
            )
          }
        />

        <div className="details-form company-selection">
          <div className="form-block">
            <FormControlBlock
              label={projectForm.creatorCompanyType && 'Contributor Type'}
              size={CONTROL_SIZE.BIG}
              control={
                <Selector
                  placeholder="Select Contributor Type"
                  options={companyTypeOptions}
                  value={projectForm.creatorCompanyType}
                  onChange={this.handleCompanyTypeChange}
                />
              }
            />
            {projectForm.creatorCompanyType ? (
              <FormControlBlock
                label={projectForm.creatorCompanyCsiCode && 'Cost Code'}
                size={CONTROL_SIZE.BIG}
                control={
                  <Selector
                    placeholder="Select Cost Code"
                    options={csiCodes
                      .filter(
                        (csiCode) =>
                          csiCode.contributor_type ===
                          projectForm.creatorCompanyType
                      )
                      .map((code) => {
                        return {
                          value: code.id,
                          label: `${code.division} - ${code.label}`,
                        };
                      })}
                    value={projectForm.creatorCompanyCsiCode}
                    onChange={this.handleCompanyCsiCodeChange}
                  />
                }
              />
            ) : null}
          </div>
        </div>

        {showTable && (
          <React.Fragment>
            <div className="selection-table-container company-selection">
              <SimpleTable
                headers={['', 'COMPANY NAME', 'POINT OF CONTACT']}
                rows={this.mapSubscribers(
                  (subscribers && subscribers.data) || []
                )}
                emptyMessage="No Companies"
              />
            </div>
            {subscribers && (
              <SimplePagination
                currentPage={currentPage}
                pageSize={subscribers.pageSize}
                total={subscribers.total}
                onPageChange={this.handlePageChange}
              />
            )}
          </React.Fragment>
        )}

        <div className="buttons-container">
          {cancelFunction && (
            <Button
              key="cancel"
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Cancel"
              onClick={cancelFunction}
            />
          )}
          <Button
            key="continue"
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label="Continue"
            disabled={!this.canContinue()}
            onClick={this.handleContinue}
          />
        </div>

        {showNewCompanyModal && <NewCompanyModal />}
      </React.Fragment>
    );
  }

  mapSubscribers = (subscribers) => {
    return subscribers.map((subscriber) => {
      return [
        <CheckBox
          id={subscriber.id}
          checked={subscriber.id === this.props.projectForm.creatorCompanyId}
          shape={'round'}
          size={'medium'}
          onChange={this.handleProjectFormChange.bind(this, 'creatorCompanyId')}
        />,
        subscriber.name,
        User.fullName(subscriber.contact),
      ];
    });
  };

  canContinue = () => {
    const { projectForm } = this.props;
    return (
      !!projectForm.creatorCompanyType &&
      !!projectForm.creatorCompanyCsiCode &&
      !!projectForm.creatorCompanyId
    );
  };
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    subscribers: getSubscribers(state),
    currentPage: getCurrentPage(state),
    projectForm: getProjectForm(state),
    showNewCompanyModal: getShowNewCompanyModal(state),
    csiCodes: getCsiCodes(state),
  };
})(SubscriberCompanySelection);
