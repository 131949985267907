import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormModal from 'components/shared/modal/FormModal';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DirectS3Uploader from 'components/shared/document-upload/DirectS3Uploader';
import { setDirectUploadInProcess } from 'components/admin/projects/details/store/actions';
import {
  getCurrentProject,
  getDirectUploadInProcess,
} from 'components/admin/projects/details/store/selectors';
import { getCloseout } from 'components/admin/projects/details/project-closeout/store/selectors';
import DocumentLinkBluebeam from 'components/shared/document/DocumentLinkBluebeam';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import './WarrantyDocumentsModal.css';

class WarrantyDocumentsModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      documents: props.closeout.warranty_documents || [],
      newDocuments: [],
    };
  }

  addDocumentsToState = (file) => {
    file[0].newDoc = true;
    this.setState({ documents: this.state.documents.concat(file) });
  };

  handleDocumentDeletion = (doc, index) => {
    const { onSubmit } = this.props;
    const { documents } = this.state;
    documents.splice(index, 1);
    this.setState({ documents: documents });
    if (!doc.newDoc) {
      onSubmit('delWarrantyDocuments', [doc]);
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { documents } = this.state;
    const { onSubmit, onClose } = this.props;
    onSubmit('warrantyDocuments', documents);
    return onClose();
  };

  formValid = () => {
    const { documents } = this.state;
    return documents.length > 0;
  };

  // Context: the document can be sent from the server and URL missing due to the job still running to download
  // the doc
  documentFullyUploaded = (doc) => {
    return doc.document_direct && doc.document_direct.url !== null;
  };

  render() {
    const { closeout, show, onClose, directUploadInProcess } = this.props;
    const { documents } = this.state;

    return (
      <div id="warranty-documents-form-modal">
        <FormModal
          className="warranty-documents-modal"
          show={show}
          subTitle="Upload Warranty Documents"
          onClose={onClose}
        >
          <Form horizontal onSubmit={this.handleSubmit}>
            {documents &&
              documents
                .filter((doc) => {
                  return this.documentFullyUploaded(doc) || doc.newDoc;
                })
                .map((doc, index) => {
                  return (
                    <div className={'doc-viewer'} key={index}>
                      <DocumentLinkBluebeam
                        file={doc.document_direct || doc}
                        onRemove={() => this.handleDocumentDeletion(doc, index)}
                      />
                    </div>
                  );
                })}

            <DirectS3Uploader
              acceptedTypes="application/pdf, .doc, .docx, .xls, .xlsx"
              model="closeouts"
              modelIdentifier={closeout.id.toString()}
              apppendToState={this.addDocumentsToState}
              updateStateUploadInProcess={setDirectUploadInProcess}
            />
            <div className="buttons-container">
              <Button
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.WHITE}
                label="Cancel"
                disabled={directUploadInProcess}
                onClick={onClose}
              />
              <Button
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.GREEN}
                disabled={!this.formValid() || directUploadInProcess}
                label={directUploadInProcess ? 'Uploading...' : 'Submit'}
                onClick={this.handleSubmit}
              />
            </div>
          </Form>
        </FormModal>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    directUploadInProcess: getDirectUploadInProcess(state),
    closeout: getCloseout(state),
  };
})(WarrantyDocumentsModal);
