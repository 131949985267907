import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';
import { getDarkMode } from 'selectors/theme';

import arrow from 'images/arrow.svg';
import arrowDark from 'images/arrow-dark.svg';
import cancelSvg from 'images/cancel.svg';
import cancelDarkSvg from 'images/cancel-dark.svg';
import colors from 'styles/colors';

const MultipleSelector = (props) => {
  const {
    dispatch,
    options,
    value,
    placeholder,
    className,
    claerable,
    disabled,
    valid,
    search,
    onChange,
    darkMode,
    ...otherOptions
  } = props;

  const isOptionEqualToValue = (o, v) => o.value === v.value;

  const handleChange = (_, values) => onChange(values);

  const renderIcon = (
    <img
      className={'selector-arrow'}
      src={!darkMode ? arrow : arrowDark}
      alt={'arrow'}
    />
  );

  const ChipProps = {
    variant: 'filled',
    sx: { fontSize: 16 },
    deleteIcon: (
      <img
        src={darkMode ? cancelDarkSvg : cancelSvg}
        style={{ width: 12, height: 12, paddingRight: 4 }}
        alt="Remove"
      />
    ),
  };

  const renderInput = ({ InputProps, ...params }) => (
    <TextField
      {...params}
      InputProps={{ ...InputProps, readOnly: !search }}
      placeholder={value.length > 0 ? '' : placeholder}
      variant="standard"
    />
  );

  const ListboxStyles = {
    '&.MuiAutocomplete-listbox': {
      padding: 0,
      '.MuiAutocomplete-option': {
        position: 'relative',
        height: 44,
        paddingLeft: 2.5,
        paddingRight: 2.5,
        '&::after': {
          content: "''",
          position: 'absolute',
          bottom: 0,
          left: 16,
          width: 'calc(100% - 32px)',
          height: '1px',
          backgroundColor: darkMode
            ? 'rgba(255, 255, 255, 0.05)'
            : colors['light-mode-clear-black'],
        },
        '&:last-child::after': { content: 'none' },
        '&[aria-selected="true"]': {
          backgroundColor: 'transparent',
          color: colors[darkMode ? 'neon' : 'primary-green'],
          '&::after': {
            backgroundColor: colors[darkMode ? 'neon' : 'primary-green'],
          },
        },
      },
    },
  };

  const PopperStyles = {
    '& .MuiPaper-root': {
      borderRadius: 0,
      boxShadow: '1px 2px 6px rgba(0, 0, 0, 0.15)',
    },
  };

  const Styles = {
    '&.MuiAutocomplete-root': {
      '.MuiTextField-root': {
        '.MuiInput-root': {
          minHeight: 40,
          paddingLeft: 0.25,
          paddingTop: 1.25,
          paddingBottom: 0.25,

          '&::before': {
            borderBottomColor: valid
              ? colors[
                  darkMode ? 'dark-mode-light-white' : 'light-mode-dark-black'
                ]
              : colors[darkMode ? 'chart-blue' : 'chart-red'],
          },
          '&::after': { content: 'none' },

          '&:hover': {
            '&:not(.Mui-disabled, .Mui-error)': {
              '&::before': { borderBottomWidth: 1 },
            },
          },
          '.MuiInput-input': {
            padding: 0.75,
            minWidth: 50,

            '&::placeholder': {
              color: valid
                ? colors[darkMode ? 'white-text' : 'light-mode-black-text']
                : colors[darkMode ? 'chart-blue' : 'chart-red'],
              opacity: 0.7,
            },
          },
          '.MuiChip-root': {
            marginTop: 0.25,
            marginBottom: 0.25,
            marginRight: 0,
            backgroundColor: darkMode
              ? 'rgba(255, 255, 255, 0.05)'
              : colors['light-mode-clear-black'],
            color: valid
              ? colors[darkMode ? 'white-text' : 'light-mode-black-text']
              : colors[darkMode ? 'chart-blue' : 'chart-red'],
          },
        },
        '.MuiAutocomplete-endAdornment': { right: 8 },
      },
    },
  };

  return (
    <Autocomplete
      multiple
      sx={Styles}
      value={value}
      options={options}
      disabled={disabled}
      disableCloseOnSelect
      className={className}
      ChipProps={ChipProps}
      popupIcon={renderIcon}
      disableClearable={!claerable}
      ListboxProps={{ sx: ListboxStyles }}
      componentsProps={{ popper: { sx: PopperStyles } }}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={renderInput}
      onChange={handleChange}
      {...otherOptions}
    />
  );
};

MultipleSelector.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    })
  ).isRequired,
  value: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  valid: PropTypes.bool,
  search: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

MultipleSelector.defaultProps = {
  valid: true,
  search: true,
  placeholder: '',
  disabled: false,
};

export default connect((state) => ({ darkMode: getDarkMode(state) }))(
  MultipleSelector
);
