import axios from 'axios';
import BaseApi from './base-api';

class MeetingsApi extends BaseApi {
  getMeetings(projectId, meetingType) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/meetings${
          meetingType ? `?by_type=${meetingType}` : ''
        }`
      )
      .then((response) => {
        return {
          ok: true,
          meetings: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getMeeting(projectId, meetingId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/meetings/${meetingId}`)
      .then((response) => {
        return {
          ok: true,
          meeting: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  deleteMeeting(projectId, meetingId) {
    return axios
      .delete(`${this.baseUrl}/projects/${projectId}/meetings/${meetingId}`)
      .then((response) => {
        return {
          ok: true,
          meetings: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getMeetingPdf(projectId, meetingId) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/meetings/${meetingId}/print`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        return {
          ok: true,
          pdf: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createMeeting(projectId, values) {
    const data = this.meetingParams(values);

    return axios
      .post(`${this.baseUrl}/projects/${projectId}/meetings`, data)
      .then((response) => {
        return {
          ok: true,
          meeting: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateMeeting(projectId, meetingId, values) {
    const data = this.meetingParams(values);

    return axios
      .put(`${this.baseUrl}/projects/${projectId}/meetings/${meetingId}`, data)
      .then((response) => {
        return {
          ok: true,
          meeting: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  meetingParams(values) {
    return {
      meeting: {
        status: values.status,
        meeting_date: values.meetingDate,
        attendee_ids: values.attendeeIds,
        meeting_type_id: values.meetingTypeId,
        meeting_items_attributes: values.meetingItems.map((item) => {
          return {
            id: item.id,
            code: item.code,
            meeting_category_id: item.meetingCategoryId,
            new_item: item.newItem,
            info: item.info,
            new_info: item.newInfo,
            resolved: item.resolved,
            resolved_note: item.resolvedNote,
            _destroy: item.destroy,
            contributor_ids: item.contributorIds,
          };
        }),
      },
    };
  }
}

export const meetingsApi = new MeetingsApi();
