import {
  REGISTER_USER_UPDATE_FORM,
  REGISTER_USER_SET_ERRORS,
} from 'actions/client/register-user';

const initialState = {
  form: {
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    terms_and_conditions: false,
  },
  errors: {
    messages: [],
    fields: [],
  },
};

export default function registerUserUiReducer(state = initialState, action) {
  switch (action.type) {
    case REGISTER_USER_UPDATE_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case REGISTER_USER_SET_ERRORS:
      return {
        ...state,
        errors: action.payload || initialState.errors,
      };

    default:
      return state;
  }
}
