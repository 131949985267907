import {
  SET_FIELD_REPORT_DELETE_MODAL,
  SET_FIELD_REPORT_DRAFT_MODAL,
  SET_FIELD_REPORT_FORM,
  SET_FIELD_REPORT_RESOLVED_MODAL,
  SET_FIELD_REPORT_WAITING_PREVIEW,
} from './action-types';

const initialState = {
  showDeleteConfirmation: false,
  showForm: false,
  showResolvedModal: false,
  showDraftModal: false,
  showWaitingPreview: false,
};

export default function projectFieldReportUiReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_FIELD_REPORT_DELETE_MODAL:
      return {
        ...state,
        showDeleteConfirmation: action.payload,
      };

    case SET_FIELD_REPORT_FORM:
      return {
        ...state,
        showForm: action.payload,
      };

    case SET_FIELD_REPORT_RESOLVED_MODAL:
      return {
        ...state,
        showResolvedModal: action.payload,
      };

    case SET_FIELD_REPORT_DRAFT_MODAL:
      return {
        ...state,
        showDraftModal: action.payload,
      };

    case SET_FIELD_REPORT_WAITING_PREVIEW:
      return {
        ...state,
        showWaitingPreview: action.payload,
      };

    default:
      return state;
  }
}
