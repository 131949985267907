import React, { useRef, useState } from 'react';

import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import { compose, withProps } from 'recompose';
import { connect } from 'react-redux';
import { showErrorMessage } from 'actions/messages';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {
  faCrosshairs,
  faMapMarkerAlt,
} from '@fortawesome/fontawesome-free-solid';
import axios from 'axios';

const key = process.env.REACT_APP_GOOGLE_MAPS_KEY;
const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`;

const MapSelectLocation = compose(
  withProps({
    googleMapURL,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: (
      <div className="map-container" style={{ position: 'relative' }} />
    ),
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const {
    defaultCenterLat = 34.052026101343586,
    defaultCenterLng = -118.2483483753089,
    defaultZoom = 10,
    dispatch,
    onChange,
    markerLat,
    markerLng,
    showLocateAddress = false,
    address = '',
  } = props;

  const [locating, setLocating] = useState(false);
  const [addressLocating, setAddressLocating] = useState(false);
  const ref = useRef(null);

  const setCoordintates = (latitude, longitude) => {
    const google = window.google;
    const latLng = new google.maps.LatLng(latitude, longitude);
    ref.current.panTo(latLng);
    onChange({ latitude, longitude });
    setTimeout(() => {
      setLocating(false);
      setAddressLocating(false);
    }, 1000);
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      setLocating(true);
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          setCoordintates(latitude, longitude);
        },
        (error) => {
          let message = 'Error';
          switch (error.code) {
            case error.PERMISSION_DENIED:
              message = 'User denied the request for Geolocation.';
              break;
            case error.POSITION_UNAVAILABLE:
              message = 'Location information is unavailable.';
              break;
            case error.TIMEOUT:
              message = 'The request to get user location timed out.';
              break;
            case error.UNKNOWN_ERROR:
              message = 'An unknown error occurred.';
              break;
            default:
              message = 'Error';
          }
          dispatch(showErrorMessage(message));
        }
      );
    } else {
      dispatch(
        showErrorMessage('Geolocation is not supported by this browser.')
      );
    }
  };

  const getAddressLocation = async () => {
    setAddressLocating(true);

    try {
      const API = axios.create({});
      const response = await API.get(
        `https://maps.googleapis.com/maps/api/geocode/json`,
        { params: { address, key } }
      );

      if (response.data.status === 'OK') {
        const { lat, lng } = response.data.results[0].geometry.location;
        setCoordintates(lat, lng);
      } else if (response.data.status === 'ZERO_RESULTS') {
        const message = 'No result found for your location';
        dispatch(showErrorMessage(message));
      }
    } catch (error) {
      dispatch(showErrorMessage('Error fetching coordinates'));
      setAddressLocating(false);
      console.log('Error fetching coordinates:', error);
    }
  };

  const Control = ({
    icon,
    onClick,
    title = '',
    disable = false,
    active = false,
  }) => (
    <button
      disabled={disable}
      onClick={onClick}
      title={title}
      style={{
        backgroundColor: '#fff',
        border: '2px solid #fff',
        borderRadius: '3px',
        cursor: 'pointer',
        height: 40,
        width: 40,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 0px 3px  rgb(0,0,0, 0.2)',
      }}
    >
      <FontAwesomeIcon
        icon={icon}
        color={active ? '#fb6120' : '#666666'}
        style={{ fontSize: 22 }}
      />
    </button>
  );

  const onMarkerMove = (e) => {
    onChange({
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng(),
    });
  };

  return (
    <GoogleMap
      ref={ref}
      defaultZoom={defaultZoom}
      defaultCenter={{ lat: defaultCenterLat, lng: defaultCenterLng }}
      onClick={onMarkerMove}
      options={{
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
      }}
    >
      <Marker
        draggable={true}
        position={{
          lat: markerLat || defaultCenterLat,
          lng: markerLng || defaultCenterLng,
        }}
        onDragEnd={onMarkerMove}
      />
      <div
        style={{
          position: 'absolute',
          zIndex: 1000,
          right: 10,
          top: 10,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row-reverse',
          gap: 10,
        }}
      >
        <Control
          icon={faCrosshairs}
          active={locating}
          disable={locating}
          onClick={getCurrentLocation}
          title="Click to center on your location."
        />
        {showLocateAddress && (
          <Control
            icon={faMapMarkerAlt}
            active={addressLocating}
            disable={addressLocating || !address || address.trim().length === 0}
            title="Click to locate your address."
            onClick={getAddressLocation}
          />
        )}
      </div>
    </GoogleMap>
  );
});

export default connect()(MapSelectLocation);
