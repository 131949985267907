import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SimpleTable from 'components/shared/table/SimpleTable';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import SimpleText from 'components/shared/text/SimpleText';
import ProjectStatus from 'components/shared/project-status/ProjectStatus';
import { ProjectStatusEnum } from 'domain/project-status-enum';
import { goToProjectDetails } from 'components/admin/projects/store/actions';

class ResourceProjects extends Component {
  static propTypes = {
    resource: PropTypes.shape({}),
    projects: PropTypes.shape({}),
    currentPage: PropTypes.number.isRequired,
    loadProjectsRequest: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { resource, loadProjectsRequest } = this.props;
    if (resource !== null) {
      this.props.dispatch(loadProjectsRequest(resource.id, 1));
    }
  }

  mapProjects = (projects) => {
    return projects.map((project) => {
      return [
        project.number,
        project.name,
        project.client ? project.client.name : '-',
        <SimpleText
          lines={[
            project.project_location.location.street_address_1,
            project.project_location.location.city,
          ]}
        />,
        <ProjectStatus
          status={ProjectStatusEnum.create(project.status)}
          type="list"
        />,
      ].filter((attr) => attr);
    });
  };

  handleProjectsRowSelection = (index) => {
    this.props.dispatch(
      goToProjectDetails(this.props.projects.content[index].id)
    );
  };

  handleProjectsPageChange = (newPage) => {
    const { resource, loadProjectsRequest } = this.props;
    this.props.dispatch(loadProjectsRequest(resource.id, newPage));
  };

  render() {
    const { resource, currentPage, projects } = this.props;
    if (resource !== null && projects !== null) {
      return (
        <div className="resource-projects">
          <SimpleTable
            className="projects-table"
            headers={[
              'PROJECT NO',
              'PROJECT NAME',
              'OWNER NAME',
              'LOCATION',
              'STAGE',
            ].filter((header) => header)}
            rows={this.mapProjects(projects.content)}
            emptyMessage="No Projects"
            onRowSelected={this.handleProjectsRowSelection}
          />

          <div className="pagination-container">
            <SimplePagination
              currentPage={currentPage}
              pageSize={projects.pageSize}
              total={projects.total}
              onPageChange={this.handleProjectsPageChange}
            />
          </div>
        </div>
      );
    }
    return null;
  }
}

export default connect()(ResourceProjects);
