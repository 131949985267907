import React, { useState } from 'react';
import { connect } from 'react-redux';

import FormModal from 'components/shared/modal/FormModal';
import NavigationBar from 'components/shared/navigation/NavigationBar';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import Input from 'components/shared/input/Input';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import Selector from 'components/shared/selector/Selector';
import DatePicker from 'components/shared/date-picker/DatePicker';
import TimePicker from 'components/shared/time-picker/TimePicker';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import {
  MEETING_FORM_TABS,
  DEFAULT_MEETING_FORM_TAB,
  frequencyOptions,
} from 'domain/new-meeting';
import MultipleDropdown from '../../../../shared/dropdown/MultipleDropdown';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getWeekendsAndHolidays,
  iCalToDropdownArray,
} from '../../../../../services/utils/date-util';
import {
  setFormProjectMeetings,
  setFormTabProjectMeetings,
  createMeetingsParent,
  setMeetingsModalShow,
  setAddTypeModalShow,
  setAddTitleModalShow,
  setEditTypeModalShow,
  setEditTitleModalShow,
  updateMeetingsParent,
} from './store/actions';
import {
  getProjectNewMeetingsParents,
  getProjectNewMeetingsInstances,
  getProjectMeetingsParentsFormTab,
  getProjectNewMeetingsFormModal,
  getProjectNewMeetingsForm,
  getMeetingsTitles,
  getMeetingsTypes,
  getProjectNewMeetingsAddTypeModal,
  getProjectNewMeetingsAddTitleModal,
  getProjectNewMeetingsEditTypeModal,
  getProjectNewMeetingsEditTitleModal,
  getMeetingsParentsTitles,
  getMeetingsParentsTypes,
} from './store/selectors';
import AttendanceForm from './AttendanceForm';
import './AttendanceForm.css';
import './MeetingModal.css';
import AddTypeOrTitleModal from './AddTypeOrTitleModal';
import EditTypeOrTitleModal from './EditTypeOrTitleModal';
import Meeting from 'domain/new-meeting';
import SearchableDropDown from 'components/shared/dropdown/SearchableDropDown';
import timezonesArray from '../project-daily-report-log/field-reports/TimezonesList';

const MeetingModal = (props) => {
  const {
    dispatch,
    project,
    show,
    tab,
    form,
    meetingsParentsTypes,
    meetingsParentsTitles,
    currentUser,
    showAddTypeModal,
    showAddTitleModal,
    showEditTypeModal,
    showEditTitleModal,
  } = props;
  const [errors, setErrors] = useState({});
  // form
  const isEdit = !!form.id;
  const handleFormChange = (fields) => {
    dispatch(setFormProjectMeetings(fields));
  };
  const handleSave = () => {
    const errs = Meeting.meetingsParentsErrors(form);
    const createdById = form.createdById || currentUser.id;
    const submittedById = form.createdById || currentUser.id;
    const publishedById = form.createdById || currentUser.id;
    const formToSubmit = {
      ...form,
      createdById,
      submittedById,
      currentUser,
      publishedById,
      ateendees: form.users,
      status: 'published',
    };
    setErrors(errs);
    if (Object.keys(errs).length > 0) {
      setTab(DEFAULT_MEETING_FORM_TAB);
      return;
    }
    if (!form.id) {
      //create action
      dispatch(createMeetingsParent(project.id, formToSubmit));
    } else {
      //update action
      dispatch(updateMeetingsParent(project.id, form.id, form));
    }
    handleClose();
  };

  // tabs
  const setTab = (key) => dispatch(setFormTabProjectMeetings(key));
  const buildTabs = () => {
    return MEETING_FORM_TABS.map((t) => ({
      ...t,
      class: t.id === tab ? 'sel' : '',
    }));
  };

  // modal
  const handleClose = () => dispatch(setMeetingsModalShow(false));
  const meetingsTypes = [
    ...meetingsParentsTypes,
    { value: 'Add Type', label: 'Add Type' },
    { value: 'Edit Type', label: 'Edit Type' },
  ];
  const meetingsTitles = [
    ...meetingsParentsTitles,
    { value: 'Add Title', label: 'Add Title' },
    { value: 'Edit Title', label: 'Edit Title' },
  ];
  if (!show) return null;
  const renderErrors = (errs) => {
    return Object.keys(errs).length > 0 ? (
      <div className="errors-container">
        {Object.keys(errs).map((e) => (
          <div>{errs[e]}</div>
        ))}
      </div>
    ) : null;
  };
  const InputTimezone = (
    <FormControlBlock
      hideBottomLabel
      className="input-condition"
      control={
        <SearchableDropDown
          className="select-dropdown"
          placeholder="Timezone"
          value={form.timezone}
          onChange={(option) =>
            handleFormChange({
              timezone: option ? option.value : option,
              timezone_name: option ? option.timezone_name : option,
            })
          }
          loadOptions={() =>
            new Promise((resolve) =>
              resolve({ ok: true, options: timezonesArray })
            )
          }
          clearable
        />
      }
    />
  );

  return (
    <>
      <FormModal
        className={`new-meeting-modal  ${
          tab === 'permissions' ? form.visibility : ''
        } ${tab === 'editing' ? form.editing : ''}`}
        show={show}
        onClose={handleClose}
        subTitle={`${isEdit ? 'Edit' : 'Add'} Meeting`}
      >
        <NavigationBar
          items={buildTabs()}
          onSelected={setTab}
          selectedValue={tab}
        />
        {tab === 'general_info' && (
          <div className="form-container">
            <div className="form-block multicontrols-block">
              <div className="two-fields-container">
                <FormControlBlock
                  label="Meeting Type"
                  position="right"
                  readonly={false}
                  control={
                    <Selector
                      className="select-dropdown"
                      placeholder={'Meeting Type'}
                      value={form.meetingType}
                      onChange={(option) => {
                        if (option && option.label === 'Add Type') {
                          dispatch(setAddTypeModalShow(true));
                        } else if (option && option.label === 'Edit Type') {
                          dispatch(setEditTypeModalShow(true));
                        } else {
                          handleFormChange({
                            meetingType: option ? option.value : option,
                          });
                        }
                      }}
                      options={meetingsTypes}
                      valid={!(form.errors && form.errors.title)}
                      clearable
                    />
                  }
                ></FormControlBlock>
                <FormControlBlock
                  label="Meeting Title"
                  position="left"
                  readonly={false}
                  control={
                    <Selector
                      className="select-dropdown"
                      placeholder={'Meeting Title'}
                      value={form.title}
                      onChange={(option) => {
                        if (option && option.label === 'Add Title') {
                          dispatch(setAddTitleModalShow(true));
                        } else if (option && option.label === 'Edit Title') {
                          dispatch(setEditTitleModalShow(true));
                        } else {
                          handleFormChange({
                            title: option ? option.value : option,
                          });
                        }
                      }}
                      options={meetingsTitles}
                      valid={!(form.errors && form.errors.title)}
                      clearable
                    />
                  }
                ></FormControlBlock>
              </div>
            </div>
            <div className="form-block">
              <FormControlBlock
                label="Address"
                position="left"
                readonly={false}
                control={
                  <Input
                    type={'text'}
                    placeholder={'Address'}
                    value={form.address}
                    valid={!(errors && errors.address)}
                    onChange={(address) => handleFormChange({ address })}
                    readonly={false}
                  />
                }
              ></FormControlBlock>
            </div>

            <div className="form-block multicontrols-block">
              <div className="two-fields-container">
                <FormControlBlock
                  label="Location"
                  position="left"
                  readonly={false}
                  control={
                    <Input
                      type={'text'}
                      placeholder={'Location'}
                      value={form.location}
                      valid={!(errors && errors.location)}
                      onChange={(location) => handleFormChange({ location })}
                    />
                  }
                ></FormControlBlock>
                <FormControlBlock
                  label="Access"
                  position="right"
                  readonly={false}
                  control={
                    <Input
                      type={'text'}
                      placeholder={'Access'}
                      value={form.access}
                      valid={!(errors && errors.access)}
                      onChange={(access) => handleFormChange({ access })}
                    />
                  }
                ></FormControlBlock>
              </div>
            </div>
            <div className="form-block multicontrols-block">
              <div className="two-fields-container">
                <FormControlBlock
                  label="Job Walk"
                  position="left"
                  readonly={false}
                  control={
                    <Input
                      type={'text'}
                      placeholder={'Job Walk'}
                      value={form.jobWalk}
                      valid={!(errors && errors.jobWalk)}
                      onChange={(jobWalk) => handleFormChange({ jobWalk })}
                    />
                  }
                ></FormControlBlock>
                <FormControlBlock
                  label="Parking"
                  position="right"
                  readonly={false}
                  control={
                    <Input
                      type={'text'}
                      placeholder={'Parking'}
                      value={form.parking}
                      valid={!(errors && errors.parking)}
                      onChange={(parking) => handleFormChange({ parking })}
                    />
                  }
                ></FormControlBlock>
              </div>
            </div>
            <div className="form-block multicontrols-block">
              <div className="two-fields-container">
                <FormControlBlock
                  label="Call Info"
                  position="left"
                  readonly={false}
                  control={
                    <Input
                      type={'text'}
                      placeholder={'Call Info'}
                      value={form.callInfo}
                      valid={!(errors && errors.callInfo)}
                      onChange={(callInfo) => handleFormChange({ callInfo })}
                    />
                  }
                ></FormControlBlock>
                <FormControlBlock
                  label="Call Link"
                  position="right"
                  readonly={false}
                  control={
                    <Input
                      type={'text'}
                      placeholder={'Call Link'}
                      value={form.callLink}
                      valid={!(errors && errors.callLink)}
                      onChange={(callLink) => handleFormChange({ callLink })}
                    />
                  }
                ></FormControlBlock>
              </div>
            </div>
            <div className="form-block">
              <FormControlBlock
                label="Weblink"
                position="left"
                readonly={false}
                control={
                  <Input
                    type={'text'}
                    placeholder={'Weblink'}
                    value={form.webLink}
                    valid={!(errors && errors.webLink)}
                    onChange={(webLink) => handleFormChange({ webLink })}
                    readonly={false}
                  />
                }
              ></FormControlBlock>
            </div>
            <div className="form-block">
              <FormControlBlock
                label="Other Info"
                position="left"
                readonly={false}
                control={
                  <Input
                    type={'text'}
                    placeholder={'Other Info'}
                    value={form.otherInfo}
                    valid={!(errors && errors.otherInfo)}
                    onChange={(otherInfo) => handleFormChange({ otherInfo })}
                  />
                }
              ></FormControlBlock>
            </div>
            <div className="form-block">
              <div className="checkboxes-and-tooltip-container">
                <FormControlBlock
                  position="left"
                  readonly={false}
                  control={
                    <LabeledCheckBox
                      label="OnlineMeeting"
                      checked={!!form.onlineMeeting}
                      onChange={(onlineMeeting) =>
                        handleFormChange({ onlineMeeting })
                      }
                      position="left"
                    />
                  }
                ></FormControlBlock>
                <FormControlBlock
                  position="left"
                  readonly={false}
                  control={
                    <LabeledCheckBox
                      label="Private Meeting"
                      checked={!!form.privateMeeting}
                      onChange={(privateMeeting) =>
                        handleFormChange({ privateMeeting })
                      }
                      position="left"
                    />
                  }
                ></FormControlBlock>
                <Tooltip
                  title={
                    <div className="tooltip-container">
                      <div className="tooltip-title">Private Meeting</div>
                      <div className="tooltip-text">
                        Meetings that are marked Private will only appear to
                        those who have been included in Attendance.
                      </div>
                    </div>
                  }
                  position="right"
                >
                  <IconButton className="tooltip-icon">
                    <HelpOutlineOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div className="form-block"> {renderErrors(errors)}</div>
          </div>
        )}
        {tab === 'schedule' && (
          <div className="form-container">
            <div className="form-block-container">
              <div className="three-fields-container">
                <FormControlBlock
                  label="Start Date"
                  readonly={false}
                  control={
                    <DatePicker
                      format="MM/DD/YYYY"
                      placeholder={'MM/DD/YYYY'}
                      value={form.startDate}
                      clearable
                      onChange={(startDate) => handleFormChange({ startDate })}
                    />
                  }
                />
                <FormControlBlock
                  label="End Date"
                  readonly={false}
                  control={
                    <DatePicker
                      format="MM/DD/YYYY"
                      placeholder={'MM/DD/YYYY'}
                      value={form.endDate}
                      clearable
                      onChange={(endDate) => handleFormChange({ endDate })}
                    />
                  }
                />
                <FormControlBlock
                  label="Frequency"
                  position="right"
                  readonly={false}
                  control={
                    <MultipleDropdown
                      className="select-dropdown"
                      options={frequencyOptions}
                      currentValues={
                        Array.isArray(form.recurrence)
                          ? form.recurrence
                          : iCalToDropdownArray(form.recurrence)
                      }
                      placeholder="Frequency"
                      onChange={(values) => {
                        if (
                          values.some((subarray) => subarray.includes('Daily'))
                        ) {
                          handleFormChange({
                            recurrence: [['daily', 'Daily']],
                          });
                          return;
                        }
                        handleFormChange({ recurrence: values });
                      }}
                    />
                  }
                />
              </div>
            </div>
            <div className="form-block multicontrols-block">
              <div className="times-container">
                <FormControlBlock
                  label="Start Time"
                  readonly={false}
                  control={
                    <TimePicker
                      className={'clock'}
                      placeholder={'Start Time'}
                      onChange={(value) =>
                        handleFormChange({ startTime: value })
                      }
                      value={form.startTime}
                      valid={!(form.errors && form.errors.startTime)}
                    />
                  }
                />
                <FormControlBlock
                  label="End Time"
                  readonly={false}
                  control={
                    <TimePicker
                      className={'clock'}
                      placeholder={'End Time'}
                      onChange={(value) => handleFormChange({ endTime: value })}
                      value={form.endTime}
                      valid={!(form.errors && form.errors.endTime)}
                    />
                  }
                />
                {InputTimezone}
              </div>
            </div>
            <div className="form-block">
              <FormControlBlock
                label="webLink"
                position="left"
                readonly={false}
                control={
                  <Input
                    type={'text'}
                    placeholder={'Weblink'}
                    value={form.webLink}
                    valid={!(errors && errors.webLink)}
                    onChange={(webLink) => handleFormChange({ webLink })}
                    readonly={false}
                  />
                }
              ></FormControlBlock>
            </div>
            <div className="form-block">
              <div className="vertical-block">
                <div className="exceptions-title">Next Meeting</div>
                <div className="exceptions-text">
                  Recurring meetings will not take place on the following dates
                  due to holidays or other events
                </div>
                <div className="next-meetings-block">
                  {form.startDate &&
                    form.endDate &&
                    getWeekendsAndHolidays(form.startDate, form.endDate).map(
                      (date) => {
                        return <div className="exceptions-text">{date}</div>;
                      }
                    )}
                </div>
              </div>
            </div>
            {renderErrors(errors)}
          </div>
        )}
        {tab === 'attendance' && (
          <div className="form-container">
            <AttendanceForm
              users={form.attendees}
              onUsersChange={(users) => {
                handleFormChange({ attendees: users });
              }}
            />
            {renderErrors(errors)}
          </div>
        )}

        <div className="button-actions">
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.WHITE}
            label="Cancel"
            onClick={handleClose}
          />
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label={'Save'}
            onClick={handleSave}
          />
        </div>
      </FormModal>
      {showAddTypeModal && (
        <AddTypeOrTitleModal
          showActions={true}
          primary={true}
          modalType="type"
        />
      )}
      {showAddTitleModal && (
        <AddTypeOrTitleModal
          showActions={true}
          primary={true}
          modalType="title"
        />
      )}
      {showEditTypeModal && (
        <EditTypeOrTitleModal
          showActions={true}
          primary={true}
          modalType="type"
        />
      )}
      {showEditTitleModal && (
        <EditTypeOrTitleModal
          showActions={true}
          primary={true}
          modalType="title"
        />
      )}
    </>
  );
};

export default connect((state) => ({
  show: getProjectNewMeetingsFormModal(state),
  currentUser: getCurrentUser(state),
  project: getCurrentProject(state),
  showAddTypeModal: getProjectNewMeetingsAddTypeModal(state),
  showAddTitleModal: getProjectNewMeetingsAddTitleModal(state),
  showEditTypeModal: getProjectNewMeetingsEditTypeModal(state),
  showEditTitleModal: getProjectNewMeetingsEditTitleModal(state),
  tab: getProjectMeetingsParentsFormTab(state),
  form: getProjectNewMeetingsForm(state),
  parents: getProjectNewMeetingsParents(state),
  instances: getProjectNewMeetingsInstances(state),
  meetingsTitles: getMeetingsTitles(state),
  meetingsTypes: getMeetingsTypes(state),
  meetingsParentsTitles: getMeetingsParentsTitles(state),
  meetingsParentsTypes: getMeetingsParentsTypes(state),
}))(MeetingModal);
