import {
  INVOICES_INVOICE_ATTRIBUTE_UPDATE,
  INVOICES_INVOICE_ITEM_LINE_CHANGE,
  INVOICES_INVOICE_LOAD_SUCCESS,
  INVOICES_LOAD_SUCCESS,
  INVOICES_USERS_LOAD_SUCCESS,
} from 'actions/admin/invoices/action-types';

const initialState = {
  currentInvoice: null,
  invoices: null,
  usersByClientId: {},
};

export default function invoicesDataReducer(state = initialState, action) {
  switch (action.type) {
    case INVOICES_INVOICE_LOAD_SUCCESS:
      return {
        ...state,
        currentInvoice: action.payload,
      };

    case INVOICES_LOAD_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
      };

    case INVOICES_INVOICE_ATTRIBUTE_UPDATE:
      return {
        ...state,
        currentInvoice: {
          ...state.currentInvoice,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case INVOICES_INVOICE_ITEM_LINE_CHANGE:
      return {
        ...state,
        currentInvoice: {
          ...state.currentInvoice,
          line_items: state.currentInvoice.line_items.map((lineItem) => {
            if (lineItem.id === action.payload.lineItemId) {
              return {
                ...lineItem,
                cost: action.payload.value,
              };
            } else {
              return lineItem;
            }
          }),
        },
      };

    case INVOICES_USERS_LOAD_SUCCESS:
      return {
        ...state,
        usersByClientId: {
          ...state.usersByClientId,
          [action.payload.clientId]: action.payload.users,
        },
      };

    default:
      return state;
  }
}
