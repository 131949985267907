import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import queryString from 'query-string';
import DetailsHeader from 'components/shared/details/DetailsHeader';
import NavigationBar from 'components/shared/navigation/NavigationBar';
import ReportsForm from './reports/ReportsForm';
import './Superadmin.css';

class Superadmin extends Component {
  constructor(props) {
    super(props);

    this.sections = [
      {
        id: 'reports',
        title: 'Reports',
        show: true,
        render: () => <ReportsForm />,
      },
      {
        id: 'subscriptions',
        title: 'Subscriptions',
        show: true,
        render: () => <div>TODO</div>,
      },
    ].filter((section) => section.show);
    this.defaultSection = this.sections.length > 0 ? this.sections[0] : null;
  }

  currentSection = () => {
    const { location } = this.props;
    const params = queryString.parse(location && location.search);
    return params.section
      ? this.sections.find((section) => section.id === params.section)
      : this.defaultSection;
  };

  handleChangeSection = (section) => {
    const { dispatch } = this.props;
    dispatch(push(`/admin/superadmin?section=${section}`));
  };

  render() {
    const section = this.currentSection();

    return (
      <div className="superadmin">
        <DetailsHeader
          leftHeader={{
            value: '360 ADMIN',
          }}
        />
        <NavigationBar
          items={this.sections}
          selectedValue={section ? section.id : null}
          onSelected={this.handleChangeSection}
        />
        {section ? section.render() : null}
      </div>
    );
  }
}

export default connect()(Superadmin);
