import {
  USER_INVITATIONS_REQUEST_PERMISSIONS_SUCCESS,
  USER_INVITATIONS_REQUEST_SUCCESS,
  USER_REMOVE_REQUEST_SUCCESS,
} from '../../actions/client/user-invitations';

const initialState = {
  permissions: [],
  users: [],
};

export default function userInvitationsDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case USER_INVITATIONS_REQUEST_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
        users: [],
      };

    case USER_INVITATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };

    case USER_REMOVE_REQUEST_SUCCESS:
      return {
        ...state,
        // errors: [],
        users: state.users.filter((element) => element.id !== action.payload),
      };

    default:
      return state;
  }
}
