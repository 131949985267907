export default class Range {
  constructor(from = 0, to = 0) {
    this.from = from || 0;
    this.to = to || 0;
  }

  static makeOptions(numbers, less = false, greater = false, multiple = false) {
    return numbers.reduce((options, number, index) => {
      if (index === 0 && less) {
        options.push(new Range(0, number).option(multiple));
      }
      if (index > 0) {
        options.push(new Range(numbers[index - 1], number).option(multiple));
      }
      if (index === numbers.length - 1 && greater) {
        options.push(new Range(number).option(multiple));
      }

      return options;
    }, []);
  }

  static decode(range_s) {
    const range_a = (range_s || '..').split('..');
    return new Range(
      range_a[0] ? parseInt(range_a[0], 10) : 0,
      range_a[1] ? parseInt(range_a[1], 10) : 0
    );
  }

  static makeValue(from, to) {
    return from !== null || to !== null ? new Range(from, to).encode() : null;
  }

  encode() {
    const { from, to } = this;

    return `${from || ''}..${to || ''}`;
  }

  label() {
    const { from, to } = this;

    if (!from && !to) {
      return 'all';
    } else if (!from) {
      return `< ${to}`;
    } else if (!to) {
      return `${from} +`;
    } else {
      return `${from} to ${to}`;
    }
  }

  option(multiple = false) {
    return multiple
      ? {
          id: this.encode(),
          label: this.label(),
        }
      : {
          value: this.encode(),
          label: this.label(),
        };
  }

  match(number) {
    return number >= this.from && (!this.to || number <= this.to);
  }
}
