import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Grid, Row } from 'react-bootstrap';
import './Review.css';
import {
  loadOrderMaterialsRequest,
  changeOrderMaterialsFormValue,
  confirmOrder,
} from 'actions/order-materials/order-materials';
import {
  getOrderMaterials,
  getOrderMaterialsForm,
  showConfirmationMessage,
} from 'selectors/order-materials/order-materials';
import SimpleTable from 'components/shared/table/SimpleTable';
import FilterHeader from 'components/shared/table/FilterHeader';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import CharsCounterTextarea from 'components/shared/chars-counter/CharsCounterTextarea';
import Button from 'components/shared/button/Button';
import MessageModal from 'components/shared/modal/MessageModal';
import doneIcon from 'images/done.svg';

class OrderMaterialsReview extends Component {
  componentDidMount() {
    this.props.dispatch(
      loadOrderMaterialsRequest(
        this.props.match.params.projectId,
        this.props.match.params.token
      )
    );
  }

  renderHeaders = () => {
    const headers = ['Description', 'Unit Price', 'Quantity', 'Subtotal'];
    return headers.map((el) => {
      return <FilterHeader label={el} />;
    });
  };

  handleCheckboxChange = (attribute, value) => {
    this.props.dispatch(changeOrderMaterialsFormValue(attribute, value));
  };

  handleNotesChange = (value) => {
    this.props.dispatch(changeOrderMaterialsFormValue('notes', value));
  };

  handleOrderConfirm = () => {
    let { orderMaterialForm, order } = this.props;
    let availableOrderMateriasIds = order.order_materials.map(({ id }) => id);
    let orderMatieralFormKeys = Object.keys(orderMaterialForm);
    let keysToRemove = orderMatieralFormKeys.filter(function (key) {
      return orderMaterialForm[key] === false;
    });
    let idsToRemove = keysToRemove.map((key) =>
      parseInt(key.split('orderMaterialCheckBox_')[1], 10)
    );
    let orderMaterialsIdsToConfirm = availableOrderMateriasIds.filter(function (
      el
    ) {
      return idsToRemove.indexOf(el) < 0;
    });

    this.props.dispatch(
      confirmOrder(
        this.props.match.params.projectId,
        this.props.match.params.token,
        {
          notes: orderMaterialForm.notes,
          available_material_ids: orderMaterialsIdsToConfirm,
        }
      )
    );
  };

  mapOrderMaterials = (orderMaterials) => {
    const { orderMaterialForm } = this.props;
    return orderMaterials.map((orderMaterial) => {
      return [
        <LabeledCheckBox
          checked={
            orderMaterialForm === undefined ||
            orderMaterialForm[`orderMaterialCheckBox_${orderMaterial.id}`] ===
              undefined
              ? true
              : orderMaterialForm[`orderMaterialCheckBox_${orderMaterial.id}`]
          }
          size="medium"
          label={orderMaterial.material_description}
          position="left"
          onChange={(value) =>
            this.handleCheckboxChange(
              `orderMaterialCheckBox_${orderMaterial.id}`,
              value
            )
          }
        />,
        orderMaterial.unit_price,
        orderMaterial.quantity,
        orderMaterial.subtotal,
      ];
    });
  };

  handleCloseConfirmationModal = () => {
    this.setState({
      confirmModal: false,
    });
    this.props.history.push('/');
  };

  render() {
    const { order, confirmModal } = this.props;
    return (
      <Grid fluid={true} className="order-materials__wrapper">
        <MessageModal
          show={confirmModal}
          message={'Order has been confirmed. You may close this page.'}
          icon={doneIcon}
          onHide={this.handleCloseConfirmationModal}
        />
        {order && (
          <React.Fragment>
            <Row>
              <Col md={12}>
                <span className="order-materials__page-title">
                  Order Request
                </span>
              </Col>
            </Row>
            <Row className="mt-40px">
              <Col md={4}>
                <Row>
                  <Col md={3}>
                    <span className="order-materials__column-title">
                      Project Info
                    </span>
                  </Col>
                  <Col md={6}>
                    <div className="order-materials__column-text">
                      <p>{`${order.project.number} ${order.project.name}`}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <Row>
                  <Col md={3}>
                    <span className="order-materials__column-title">
                      Ordered By
                    </span>
                  </Col>
                  <Col md={6}>
                    <div className="order-materials__column-text">
                      <p>{`${order.user.full_name} - ${order.user.title}`}</p>
                      <p>{`${order.user.email}`}</p>
                      <p>{`${order.user.phone_number}`}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <Row>
                  <Col md={3}>
                    <span className="order-materials__column-title">
                      Order Date
                    </span>
                  </Col>
                  <Col md={6}>
                    <div className="order-materials__column-text">
                      <p>{`${order.date_ordered}`}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p className="order-materials__text mt-96px">
                  You have recevied the following order. Please confirm
                  availability by selecting the checkbox.
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <SimpleTable
                  headers={this.renderHeaders()}
                  rows={this.mapOrderMaterials(order.order_materials)}
                  emptyMessage="No Order Materials"
                />
              </Col>
            </Row>
            <Row>
              <Col md={2} mdOffset={10}>
                <p className="order-materials__total mt-33px">Total</p>
                <p className="order-materials__total">{order.total}</p>
                <p className="order-materials__info-text">taxes included</p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p className="order-materials__text mt-12px">
                  Provide a message to the Customer regarding stock, delivery,
                  or other issues, below.
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <CharsCounterTextarea
                  className="order-materials__notes mt-33px"
                  placeholder="Remarks"
                  onBlur={(e) => this.handleNotesChange(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="text-center">
                <Button
                  label="Confirm Order"
                  onClick={this.handleOrderConfirm}
                  type="btn button confirmation-button"
                  color="button-green"
                />
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Grid>
    );
  }
}

export default connect((state) => {
  return {
    order: getOrderMaterials(state),
    orderMaterialForm: getOrderMaterialsForm(state),
    confirmModal: showConfirmationMessage(state),
  };
})(OrderMaterialsReview);
