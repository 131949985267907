/* eslint-disable default-case */
import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';
import { DueDateFilterUtil } from 'services/utils/due-date-filter-util';

class RfasApi extends BaseApi {
  getProjectRfas(projectId, filters) {
    const filtersQuery = this.makeFiltersQuery(filters);

    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/rfas?page=${
          filters.page || 1
        }${filtersQuery}`
      )
      .then((response) => {
        return {
          ok: true,
          rfas: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors:
            error.response && error.response.data && error.response.data.errors,
        };
      });
  }

  makeFiltersQuery({
    pre,
    due,
    status,
    search,
    number_order,
    company_number_order,
    title_order,
    submitted_order,
    reviewed_order,
    resolved_order,
    accelerate,
    for_record,
    by_request_type,
    by_submitted_user,
    by_response_user,
    by_due_to,
    by_due_from,
    by_submitted_date_to,
    by_submitted_date_from,
  }) {
    const dueDateQuery = DueDateFilterUtil.getDueDateQuery(due, true);

    let query = '';
    if (pre !== null) query += pre ? '&pre=true' : '&not_pre=true';
    if (dueDateQuery) query += `&${dueDateQuery}`;
    if (status !== 'all') query += `&${this.makeStatusQuery(status)}`;
    if (search) query += `&by_text=${search}`;
    if (number_order && number_order !== 'all')
      query += `&number_order=${number_order}`;
    if (company_number_order && company_number_order !== 'all')
      query += `&company_number_order=${company_number_order}`;
    if (title_order && title_order !== 'all')
      query += `&title_order=${title_order}`;
    if (submitted_order && submitted_order !== 'all')
      query += `&submitted_order=${submitted_order}`;
    if (reviewed_order && reviewed_order !== 'all')
      query += `&reviewed_order=${reviewed_order}`;
    if (resolved_order && resolved_order !== 'all')
      query += `&resolved_order=${resolved_order}`;
    if (accelerate) query += `&accelerate=true`;
    if (for_record) query += `&for_record=true`;
    if (by_request_type) query += `&by_request_type=${by_request_type}`;
    if (by_submitted_user && by_submitted_user.length > 0) {
      const values = by_submitted_user.map((user) => user[0]).join(',');
      query += `&by_submitted_user=${values}`;
    }
    if (by_response_user && by_response_user.length > 0) {
      const values = by_response_user.map((user) => user[0]).join(',');
      query += `&by_response_user=${values}`;
    }
    if (by_due_to) query += `&by_due_to=${by_due_to}`;
    if (by_due_from) query += `&by_due_from=${by_due_from}`;
    if (by_submitted_date_to)
      query += `&by_submitted_date_to=${by_submitted_date_to}`;
    if (by_submitted_date_from)
      query += `&by_submitted_date_from=${by_submitted_date_from}`;

    return query;
  }

  makeStatusQuery(status) {
    switch (status) {
      case 'draft':
        return 'any_draft=true';
      case 'pending':
        return 'pending=true';
      case 'submitted':
        return 'submitted=true';
      case 'for_record':
        return 'for_record=true';
      case 'approved':
        return 'by_resolved_code=approved';
      case 'approved_as_noted':
        return 'by_resolved_code=approved_as_noted';
      case 'revise':
        return 'by_resolved_code=revise';
      case 'rejected':
        return 'by_resolved_code=rejected';
      case 'discarded':
        return 'discarded=true';
      default:
        return '';
    }
  }

  getRfa(projectId, rfaId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/rfas/${rfaId}`)
      .then((response) => {
        return { ok: true, rfa: response.data };
      })
      .catch((error) => {
        return {
          ok: false,
          errors:
            error.response && error.response.data && error.response.data.errors,
        };
      });
  }

  getRfasLogPdf(projectId, pre) {
    let query = '';
    if (pre !== null) query += pre ? '&pre=true' : '&not_pre=true';
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/rfa_log/print?${query}`, {
        responseType: 'blob',
      })
      .then((response) => {
        return { ok: true, rfaLogPdf: response.data };
      })
      .catch((error) => {
        return {
          ok: false,
          errors:
            error.response && error.response.data && error.response.data.errors,
        };
      });
  }

  deleteRfa(projectId, rfaId) {
    return axios
      .delete(`${this.baseUrl}/projects/${projectId}/rfas/${rfaId}`)
      .then((response) => {
        return { ok: true };
      })
      .catch((error) => {
        return {
          ok: false,
          errors:
            error.response && error.response.data && error.response.data.errors,
        };
      });
  }

  saveRfa(projectId, values, action) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const [method, path] = this.saveRfaMethod(values, action);
    const data = this.saveRfaParams(values, action);

    return axios[method](
      `${this.baseUrl}/projects/${projectId}/rfas${path}`,
      data,
      headers
    )
      .then((response) => {
        return {
          ok: true,
          rfi: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  saveRfaMethod({ id, revised_rfa_id }, action) {
    if (!id)
      return revised_rfa_id
        ? ['post', `/${revised_rfa_id}/revise`]
        : ['post', ''];

    const path = `/${id}`;
    switch (action) {
      case 'respond':
        return ['post', `${path}/respond`];
      case 'review':
        return ['post', `${path}/review`];
      case 'update':
      case 'transform_prfa':
      case 'save_draft':
        return ['put', path];
      default:
        return ['post', `${path}/submit`];
    }
  }

  saveRfaParams(values, action) {
    let apiPayload = new FormData();
    console.log('action', action);
    if (!['respond', 'review'].includes(action))
      this.appendDetailParams(apiPayload, values);

    if (!['review'].includes(action))
      this.appendResponseParams(apiPayload, values);

    if (['review'].includes(action))
      this.appendReviewParams(apiPayload, values);

    if (!['respond'].includes(action))
      this.appendAssignedParams(apiPayload, values);

    if (action === 'save_draft') apiPayload.append('rfa[draft]', true);
    if (action === 'transform_prfa')
      this.appendPRfaTransformationParams(apiPayload, values);
    if (['draft_for_record', 'for_record'].includes(values.status))
      apiPayload.append('rfa[for_record]', true);

    return apiPayload;
  }

  appendDetailParams(apiPayload, values) {
    const {
      request_type,
      request_title,
      request_description,
      due_date,
      csi_code_id,
    } = values;

    apiPayload.append('rfa[request_title]', request_title);
    apiPayload.append('rfa[request_description]', request_description);
    if (request_type) apiPayload.append('rfa[request_type]', request_type);
    if (due_date) apiPayload.append('rfa[due_date]', due_date);
    if (csi_code_id) apiPayload.append('rfa[csi_code_id]', csi_code_id);

    this.appendFiles(apiPayload, values, 'request');
  }

  appendPRfaTransformationParams(apiPayload, values) {
    const {
      request_type,
      request_title,
      request_description,
      due_date,
      csi_code_id,
      status,
    } = values;
    apiPayload.append('rfa[request_title]', request_title);
    apiPayload.append('rfa[request_description]', request_description);
    if (request_type) apiPayload.append('rfa[request_type]', request_type);
    if (due_date) apiPayload.append('rfa[due_date]', due_date);
    if (csi_code_id) apiPayload.append('rfa[csi_code_id]', csi_code_id);
    if (status) apiPayload.append('rfa[status]', status);
    console.log('values in api', values);
    console.log('payload', apiPayload);
    this.appendFiles(apiPayload, values, 'request');
  }

  appendResponseParams(apiPayload, values) {
    const {
      response_code,
      response_description,
      responded_by,
      responded_at,
      response_method,
      response_method_description,
    } = values;

    apiPayload.append('rfa[response_description]', response_description);
    apiPayload.append(
      'rfa[response_method_description]',
      response_method_description
    );
    if (response_code) apiPayload.append('rfa[response_code]', response_code);
    if (responded_by)
      apiPayload.append('rfa[responded_by_id]', responded_by.value);
    if (responded_at) apiPayload.append('rfa[responded_at]', responded_at);
    if (response_method)
      apiPayload.append('rfa[response_method]', response_method);

    this.appendFiles(apiPayload, values, 'response');
  }

  appendReviewParams(apiPayload, values) {
    const { response_code, review_description } = values;

    if (response_code) apiPayload.append('rfa[response_code]', response_code);
    apiPayload.append('rfa[review_description]', review_description);

    this.appendFiles(apiPayload, values, 'review');
  }

  appendAssignedParams(apiPayload, values) {
    const { assigned_to, ccs, addl_recipients } = values;

    if (assigned_to)
      apiPayload.append('rfa[assigned_to_id]', assigned_to.value);
    if (ccs & (ccs.length > 0)) {
      ccs.forEach((cc) => apiPayload.append('rfa[ccs][]', cc));
    } else {
      // Append an empty array to clear out CCs on the backend
      apiPayload.append('rfa[ccs][]', '');
    }
    addl_recipients.forEach((addl_recipient) =>
      apiPayload.append('rfa[addl_recipients][]', addl_recipient)
    );
  }

  appendFiles(apiPayload, values, name) {
    this.appendTypeFiles(apiPayload, values, name, 'document');
    this.appendTypeFiles(apiPayload, values, name, 'image');
  }

  appendTypeFiles(apiPayload, values, name, type) {
    const key = `${name}_${type}s`;
    const files = values[key];
    const deletedFiles = values[`deleted_${key}`];
    const isRevision = !!values.revised_rfa_id;

    let index = 0;
    files
      .filter((file) => file.file)
      .forEach(({ file }) => {
        apiPayload.append(
          `rfa[${key}_attributes][${index}][${type}]`,
          file,
          file.name
        );
        index++;
      });
    deletedFiles
      .filter((file) => file.id)
      .forEach((file) => {
        if (isRevision) {
          apiPayload.append(`rfa[original_${type}s_removed][]`, file.id);
        } else {
          apiPayload.append(`rfa[${key}_attributes][${index}][id]`, file.id);
          apiPayload.append(`rfa[${key}_attributes][${index}][_destroy]`, true);
          index++;
        }
      });
  }

  getNotes(rfaId) {
    return axios
      .get(`${this.baseUrl}/rfas/${rfaId}/notes`)
      .then((response) => {
        return {
          ok: true,
          notes: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  markNotesRead(rfaId) {
    return axios
      .put(`${this.baseUrl}/rfas/${rfaId}/notes/mark_all_read`)
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createNote(rfaId, content) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const apiPayload = new FormData();
    apiPayload.append('note[content]', content.note);

    let index = 0;
    if (content.images && content.images.length > 0) {
      content.images
        .filter((image) => !image.id)
        .forEach((image) => {
          apiPayload.append(
            `note[images_attributes][${index}][image]`,
            image.file,
            image.file.name
          );
          index++;
        });
    }

    index = 0;
    if (content.documents && content.documents.length > 0) {
      content.documents
        .filter((doc) => !doc.id)
        .forEach((doc) => {
          apiPayload.append(
            `note[documents_attributes][${index}][document]`,
            doc.file,
            doc.file.name
          );
          index++;
        });
    }

    return axios
      .post(`${this.baseUrl}/rfas/${rfaId}/notes`, apiPayload, headers)
      .then((response) => {
        return {
          ok: true,
          note: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const rfasApi = new RfasApi();
