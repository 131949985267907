import Rfi from 'domain/rfi';
import User from 'domain/user';

class RfiPermissions {
  canManage = (user, project, directory, rfi) => {
    if (User.isAdminOrProjectSubscriber(user, project)) return true;

    if (Rfi.isSubmittedByGcOar(directory, rfi))
      return Rfi.isSubmittedByUserCompany(user, rfi);

    return User.isGcOar(user, project);
  };

  canViewPending = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project) ||
    User.isContractor(user, project);

  canViewSubmitted = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project);

  canViewDraft = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project) ||
    User.isContractor(user, project);

  canView = (user, project, rfi) =>
    Rfi.isResolved(rfi) ||
    (!Rfi.isAssignedToUser(user, project, rfi) &&
      !Rfi.isAddlAssignedToUser(user, project, rfi)) ||
    Rfi.isRespondedByUser(user, project, rfi);

  canEdit = (user, project, directory, rfi) =>
    this.canManage(user, project, directory, rfi) ||
    (Rfi.isSubcontractorUser(user, project, rfi) &&
      (Rfi.isDraft(rfi) || Rfi.isPending(rfi)));

  canCreate = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project) ||
    User.isContractor(user, project);

  canAssign = (user, project) => User.isAdminGcOar(user, project);

  canDiscard = (rfi) => Rfi.isDraft(rfi) || Rfi.isPending(rfi);

  canAccelerate = (user, project, directory, rfi) =>
    !!rfi &&
    this.canManage(user, project, directory, rfi) &&
    !Rfi.isAssignedToUser(user, rfi) &&
    !Rfi.isAddlAssignedToUser(user, rfi);

  canSaveDraft = (user, rfi) =>
    !rfi || Rfi.isDraft(rfi) || Rfi.isPendingUserResponse(user, rfi);
}

export default new RfiPermissions();
