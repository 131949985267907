import {
  PROJECT_PROPOSAL_SET,
  PROJECT_PROPOSAL_PDF_SET,
  CSI_CODES_SET,
} from 'components/admin/projects/details/project-proposal/store/action-types';

const initialState = {
  projectProposal: null,
  projectProposalPdf: null,
  csiCodes: [],
};

export default function projectProposalDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case PROJECT_PROPOSAL_SET:
      return {
        ...state,
        projectProposal: action.payload,
      };

    case PROJECT_PROPOSAL_PDF_SET:
      return {
        ...state,
        projectProposalPdf: action.payload,
      };

    case CSI_CODES_SET:
      return {
        ...state,
        csiCodes: action.payload,
      };

    default:
      return state;
  }
}
