import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Selector from 'components/shared/selector/Selector';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import DatePicker from 'components/shared/date-picker/DatePicker';
import { todayValidation } from 'services/utils/date-util';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import DocumentUpload, {
  FILE_TYPES,
} from 'components/shared/document-upload/DocumentUpload';
import DocumentLink from 'components/shared/document/DocumentLink';
import ImageUpload from 'components/shared/image-upload/ImageUpload';
import { imageExtensions } from 'services/utils/files-util';
import Project from 'domain/project';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';

class ProjectOpenItemForm extends Component {
  static propTypes = {
    form: PropTypes.shape({}).isRequired,
    errors: PropTypes.shape({}).isRequired,
    handleAttributeChange: PropTypes.func.isRequired,
  };

  recipientOptions = () => {
    const { directory } = this.props;
    return Project.recipientOptions({ directory });
  };

  assigneeOptions = () => {
    return this.recipientOptions().map((opt) => {
      return { value: opt.id, label: opt.label };
    });
  };

  setImages = (files) => {
    let images = [...this.getImages()];
    files.forEach(function (val) {
      images.push({
        url: val.url || URL.createObjectURL(val),
        file: val,
      });
    });

    const { handleAttributeChange } = this.props;
    handleAttributeChange('openItemImages', images);
  };

  removeImage = (index) => {
    let images = [...this.getImages()];
    let deletedImages = [...this.getDeletedImages()];
    let deletedImage = images.splice(index, 1);
    deletedImages.push(deletedImage[0]);

    const { handleAttributeChange } = this.props;
    handleAttributeChange('openItemImages', images);
    handleAttributeChange('openItemDeletedImages', deletedImages);
  };

  getImages = () => {
    const { form } = this.props;
    if (form && form.openItemImages && form.openItemImages.length > 0) {
      return form.openItemImages;
    }
    return [];
  };

  getDeletedImages = () => {
    const { form } = this.props;
    if (
      form &&
      form.openItemDeletedImages &&
      form.openItemDeletedImages.length > 0
    ) {
      return form.openItemDeletedImages;
    }
    return [];
  };

  setDocuments = (files) => {
    let docs = [...this.getDocuments()];
    files.forEach(function (val) {
      docs.push({
        file: val,
        name: val.name,
      });
    });

    const { handleAttributeChange } = this.props;
    handleAttributeChange('openItemDocuments', docs);
  };

  removeDocument = (index) => {
    let docs = [...this.getDocuments()];
    let deletedDocs = [...this.getDeletedDocuments()];
    let deletedDoc = docs.splice(index, 1);
    deletedDocs.push(deletedDoc[0]);

    const { handleAttributeChange } = this.props;
    handleAttributeChange('openItemDocuments', docs);
    handleAttributeChange('openItemDeletedDocuments', deletedDocs);
  };

  getDocuments = () => {
    const { form } = this.props;
    if (form && form.openItemDocuments && form.openItemDocuments.length > 0) {
      return form.openItemDocuments;
    }
    return [];
  };

  getDeletedDocuments = () => {
    const { form } = this.props;
    if (
      form &&
      form.openItemDeletedDocuments &&
      form.openItemDeletedDocuments.length > 0
    ) {
      return form.openItemDeletedDocuments;
    }
    return [];
  };

  render() {
    const { form, errors, handleAttributeChange } = this.props;
    return (
      <React.Fragment>
        <div className="form-block">
          <FormControlBlock
            label={form.openItemAssigneeId && 'Assigned To'}
            control={
              <Selector
                placeholder="Assigned To"
                onChange={(option) =>
                  handleAttributeChange('openItemAssigneeId', option.value)
                }
                value={form.openItemAssigneeId}
                options={this.assigneeOptions()}
                valid={!errors.openItemAssigneeId}
              />
            }
          />
        </div>
        <div className="form-block">
          <FormControlBlock
            label={
              (form.openItemRecipients || []).length > 0 && 'CC Recipients'
            }
            control={
              <MultipleDropdown
                options={this.recipientOptions()}
                currentValues={form.openItemRecipients || []}
                placeholder="CC Recipients"
                onChange={(values) =>
                  handleAttributeChange('openItemRecipients', values)
                }
              />
            }
          />
        </div>
        <div className="form-block">
          <FormControlBlock
            label={form.openItemResolveBy && 'Due Date'}
            control={
              <DatePicker
                isValidDate={todayValidation}
                placeholder="Due Date"
                onChange={(value) =>
                  handleAttributeChange('openItemResolveBy', value)
                }
                value={form.openItemResolveBy}
                valid={!errors.openItemResolveBy}
              />
            }
          />
        </div>
        <div className="form-block">
          <FormControlBlock
            label={form.openItemDesc && 'Description'}
            control={
              <TextareaAutosizeWrapper
                placeholder="Description"
                initialValue={form.openItemDesc || ''}
                valid={!errors.openItemDesc}
                onChange={(value) =>
                  handleAttributeChange('openItemDesc', value)
                }
              />
            }
          />
        </div>
        <div className="form-block">
          <LabeledCheckBox
            label="Mark Critical"
            position="left"
            onChange={handleAttributeChange.bind(null, 'openItemCritical')}
            checked={form.openItemCritical}
          />
        </div>
        <div className="form-resources-block">
          <div className="form-documents">
            <FormControlBlock
              className="without-focus"
              label="Documents"
              control={
                <div className="form-resources-body">
                  {form.openItemDocuments &&
                    form.openItemDocuments.map((doc, index) => {
                      return (
                        <DocumentLink
                          key={index}
                          file={doc}
                          url={doc.url}
                          onRemove={() => this.removeDocument(index)}
                        />
                      );
                    })}
                  <DocumentUpload
                    fileType={FILE_TYPES.ANY}
                    onDrop={(files) => this.setDocuments(files)}
                  />
                </div>
              }
            />
          </div>
          <div className="form-images">
            <FormControlBlock
              className="without-focus"
              label="Photos"
              control={
                <div className="form-resources-body">
                  <ImageUpload
                    extensions={imageExtensions}
                    multiple
                    onUpload={(files) => this.setImages(files)}
                    onRemove={(index) => this.removeImage(index)}
                    images={this.getImages()}
                  />
                </div>
              }
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    directory: getDirectory(state),
  };
})(ProjectOpenItemForm);
