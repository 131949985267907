import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getDarkMode } from 'selectors/theme';
import { connect } from 'react-redux';
import './DraggableGallery.css';

const DraggableGallery = (props) => {
  const {
    items,
    renderItem,
    columns,
    spacing,
    onItemClick,
    darkMode,

    // drag
    enableDrag,
    onMove,
    enableOrder,
    onOrder,
    droppableKey,

    // select
    enableSelect,
    selected,
    onSelectedChange,
    areEqualItems,
    initialCount,

    // permissions
    canItemBeSelected,
    canItemBeMoved,
    canItemBeOrder,
    canItemBeMoveTo,
  } = props;

  const [selectedItems, setSelectedItems] = useState(selected || null);

  const selecting = selectedItems !== null;
  const shiftSelectStartItem = useRef(null);
  const startLongClick = useRef(null);

  const [startDragging, setStartDragging] = useState(false);
  const dragging = useRef(false);

  useEffect(() => {
    if (!areSameArray(selected, selectedItems)) setSelectedItems(selected);
  }, [selected]);

  // utils
  const areSameArray = (a1, a2) => {
    if (!a1 || !a2) return false;
    if (a1.length !== a2.length) return false;

    return a1.reduce(
      (acc, curr, index) => acc && areSameItem(curr, a2[index]),
      true
    );
  };
  const areSameItem = (i1, i2) => {
    if (typeof areEqualItems === 'function') return areEqualItems(i1, i2);
    else return i1.id === i2.id;
  };
  const isItemSelected = (item) =>
    Array.isArray(selectedItems) &&
    typeof selectedItems.find((i) => areSameItem(i, item)) !== 'undefined';

  // permissions
  const canBeSelected = (item) => {
    if (typeof canItemBeSelected !== 'function') return true;
    return canItemBeSelected(item);
  };
  const canBeMoved = (item) => {
    if (typeof canItemBeMoved !== 'function') return true;
    return canItemBeMoved(item);
  };
  const canBeOrder = (item) => {
    if (typeof canItemBeOrder !== 'function') return true;
    return canItemBeOrder(item);
  };
  const canBeMoveTo = (item) => {
    if (typeof canItemBeMoveTo !== 'function') return true;
    return canItemBeMoveTo(item);
  };

  // select
  const onStartSelect = (item) => {
    const res = [item];
    setSelectedItems(res);
    if (typeof onSelectedChange === 'function') onSelectedChange(res);
  };
  const onItemSelect = (item, item2) => {
    if (!selecting) return;

    let res = (selectedItems || []).filter((i) => !areSameItem(i, item));
    if (typeof item2 !== 'undefined') {
      let add = false;
      items.forEach((i) => {
        const isStart = areSameItem(i, item);
        const isEnd = areSameItem(i, item2);

        if (!add && (isStart || isEnd)) {
          res.push(i);
          add = true;
        } else if (add) {
          if (!res.find((it) => areSameItem(it, i))) res.push(i);
          if (isStart || isEnd) add = false;
        }
      });
    } else {
      if (res.length === selectedItems.length) res.push(item);
      else if (res.length === 0) res = null; // when no more items stops selecting
    }

    setSelectedItems(res);
    if (typeof onSelectedChange === 'function') onSelectedChange(res);
  };
  const onSelectCountClick = (item) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (selecting) onItemSelect(item);
    else onStartSelect(item);
  };

  // differenciates long press from normal click
  const handleClick = (item) => (e) => {
    const start = startLongClick.current;
    const end = Date.now();

    // long press
    if (Math.abs(end - start) > 400 && canBeSelected(item)) {
      onItemLongClick(item);
    } else {
      handleItemClick(item)(e);
    }

    startLongClick.current = null;
  };

  // click
  const handleItemClick = (item) => (e) => {
    if (
      enableSelect &&
      canBeSelected(item) &&
      (e.ctrlKey || e.metaKey || e.shiftKey)
    ) {
      if (e.shiftKey && shiftSelectStartItem.current) {
        onItemSelect(shiftSelectStartItem.current, item);
        shiftSelectStartItem.current = item;
      } else {
        if (selecting) onItemSelect(item);
        else onStartSelect(item);
        shiftSelectStartItem.current = item;
      }
    } else {
      onItemClick(item);
    }
  };

  // order
  const handleOrder = (sources, destination, position) => {
    const res = items.filter((i) => {
      return (
        areSameItem(i, destination) || !sources.find((s) => areSameItem(s, i))
      );
    });

    let index = res.findIndex((i) => areSameItem(i, destination));
    if (sources.find((s) => areSameItem(s, destination))) res.splice(index, 1);
    if (position === 'right') index++;

    res.splice(index, 0, ...sources);

    onOrder(res);
  };

  // long click
  const onItemLongClick = (item) => {
    if (!dragging.current) {
      if (selecting) onItemSelect(item);
      else onStartSelect(item);
    }
  };
  const onStartItemLongClick = (_) => (_) => {
    startLongClick.current = Date.now();
    // setStartLongClick(true);
  };

  // drag and drop utils
  const onStartDragItem = (item) => (e) => {
    setStartDragging(true);
    dragging.current = item;
    e.dataTransfer.setData(droppableKey, JSON.stringify(item));

    const clone = e.currentTarget.cloneNode(true);
    clone.classList.remove('selected');
    clone.style.width = `${e.currentTarget.offsetWidth}px`;
    clone.style.height = `${e.currentTarget.offsetHeight}px`;
    clone.style.position = 'absolute';
    clone.style.bottom = '100%';
    clone.style.right = '100%';
    clone.setAttribute('id', 'DRAG_ITEM_CLONE');
    if (darkMode) clone.classList.add('dark-mode');
    if (selectedItems && selectedItems.length > 1)
      clone.classList.add('multiple');

    document.body.appendChild(clone);

    e.dataTransfer.setDragImage(clone, 0, 0);
  };
  const onEndDragItem = (_) => (_) => {
    setStartDragging(false);
    dragging.current = false;
    const clone = document.getElementById('DRAG_ITEM_CLONE');
    if (clone) clone.remove();
  };
  const onDropItem = (destination, position = 'center') => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setStartDragging(false);
    dragging.current = null;
    const data = e.dataTransfer.getData(droppableKey);
    if (data) {
      const source = JSON.parse(data);
      e.currentTarget.classList.remove('droppable');

      if (!areSameItem(source, destination)) {
        if (position === 'center' && typeof onMove === 'function') {
          onMove(selectedItems || [source], destination);
        } else if (
          (position === 'left' || position === 'right') &&
          typeof onOrder === 'function'
        ) {
          handleOrder(selectedItems || [source], destination, position);
        }
      }
    }
  };
  const onDragOverItem = (item, canDrop = true) => (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (canDrop && !(dragging.current && areSameItem(dragging.current, item)))
      e.currentTarget.classList.add('droppable');
  };
  const onDragLeaveItem = (item, canDrop = true) => (e) => {
    e.stopPropagation();
    if (canDrop && !(dragging.current && areSameItem(dragging.current, item)))
      e.currentTarget.classList.remove('droppable');
  };

  // styles
  const gridStyles = {
    gap: spacing - 2,
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
  };
  const itemStyles = {
    borderWidth: 2,
    borderColor: 'transparent',
    borderStyle: 'solid',
    minHeight: 0,
    minWidth: 0,
    cursor: typeof onItemClick === 'function' ? 'pointer' : 'auto',
  };

  // select count
  let selectedCount = initialCount || 0;

  return (
    <div className="draggable-gallery" style={gridStyles}>
      {items.map((item, i, arr) => {
        const selected = isItemSelected(item);
        if (selected) selectedCount += 1;

        const last = i === arr.length - 1;
        const endRow = (i + 1) % columns === 0;

        const canSelect = enableSelect && canBeSelected(item);
        const canOrder = enableOrder && canBeOrder(item);
        const canMove = enableDrag && canBeMoved(item);
        const canMoveTo =
          enableDrag && typeof onMove !== 'undefined' && canBeMoveTo(item);
        const canDrag = enableDrag && (canOrder || canMove);

        const onMouseDown = canSelect ? onStartItemLongClick(item) : undefined;

        const onClick = handleClick(item);

        const onDragStart =
          canDrag && (!selecting || selected)
            ? onStartDragItem(item)
            : undefined;
        const onDragEnd = canDrag ? onEndDragItem(item) : undefined;
        const onDrop =
          canMoveTo && !selected ? onDropItem(item, 'center') : undefined;
        const onDragOver = onDragOverItem(item, canMoveTo && !selected);
        const onDragLeave = onDragLeaveItem(item, canMoveTo && !selected);

        const onDragOverSide = onDragOverItem(item);
        const onDragLeaveSide = onDragLeaveItem(item);

        return (
          <div
            key={i}
            style={itemStyles}
            className={`draggable-item ${selected ? 'selected' : ''}`}
            onMouseDown={onMouseDown}
            onClick={onClick}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            draggable={canDrag}
          >
            {canOrder && startDragging && (
              <div
                className="left-drop"
                style={{ width: (spacing || 8) * 3, left: -2 * (spacing || 8) }}
                onDragOver={onDragOverSide}
                onDragLeave={onDragLeaveSide}
                onDrop={onDropItem(item, 'left')}
              ></div>
            )}

            {canOrder && startDragging && (last || endRow) && (
              <div
                className="left-drop"
                style={{
                  width: (spacing || 8) * 3,
                  right: -2 * (spacing || 8),
                }}
                onDragOver={onDragOverSide}
                onDragLeave={onDragLeaveSide}
                onDrop={onDropItem(item, 'right')}
              ></div>
            )}
            {canSelect && selecting && (
              <div
                className={`selected-count ${selected ? 'selected' : ''}`}
                onClick={onSelectCountClick(item)}
              >
                {selected && selectedCount}
              </div>
            )}
            {renderItem(item, selected)}
          </div>
        );
      })}
    </div>
  );
};

DraggableGallery.propTypes = {
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  columns: PropTypes.number,
  spacing: PropTypes.number,
  onItemClick: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,

  // drag
  enableDrag: PropTypes.bool,
  onMove: PropTypes.func,
  enableOrder: PropTypes.bool,
  onOrder: PropTypes.func.isRequired,
  droppableKey: PropTypes.string.isRequired,

  // select
  enableSelect: PropTypes.bool,
  selected: PropTypes.array,
  onSelectedChange: PropTypes.func,
  areEqualItems: PropTypes.func,
  initialCount: PropTypes.number,

  // permissions
  canItemBeSelected: PropTypes.func,
  canItemBeMoved: PropTypes.func,
  canItemBeOrder: PropTypes.func,
  canItemBeMoveTo: PropTypes.func,
};

DraggableGallery.defaultProps = {
  columns: 4,
  spacing: 8,
  enableDrag: true,
  enableSelect: true,
  enableOrder: true,
  initialCount: 0,
};

export default connect((state) => ({ darkMode: getDarkMode(state) }))(
  DraggableGallery
);
