import React from 'react';
import { connect } from 'react-redux';

import ConfirmationModal from 'components/shared/modal/ConfirmationModal.js';

import { getShowDeleteConfirmation, getCurrentRfa } from './store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';

import { deleteRfaRequest, setShowDeleteConfirmation } from './store/actions';

const ModalRfaLogDeleteConfirmation = (props) => {
  const {
    dispatch,
    currentProject,
    showDeleteConfirmation,
    currentRfa,
  } = props;

  const handleDeleteConfirmation = (ok) => {
    if (ok) dispatch(deleteRfaRequest(currentProject.id, currentRfa.id));
    else dispatch(setShowDeleteConfirmation(false));
  };

  if (!showDeleteConfirmation) return null;

  return (
    <ConfirmationModal
      show={showDeleteConfirmation}
      message="Are you sure you want to discard this RFA?"
      onHide={handleDeleteConfirmation}
    />
  );
};

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    currentRfa: getCurrentRfa(state),
    showDeleteConfirmation: getShowDeleteConfirmation(state),
  };
})(ModalRfaLogDeleteConfirmation);
