import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Date from 'components/shared/text/Date';
import ChatAttachments from 'components/shared/chat/ChatAttachments';
import { getCurrentUser } from 'selectors/authentication';

class ChatMessage extends Component {
  static propTypes = {
    note: PropTypes.shape({
      content: PropTypes.string,
      created_at: PropTypes.string,
      documents: PropTypes.arrayOf(PropTypes.shape({})),
      images: PropTypes.arrayOf(PropTypes.shape({})),
      user: PropTypes.shape({
        accountable_id: PropTypes.number,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
      }),
    }).isRequired,
    elapsedTime: PropTypes.shape({
      years: PropTypes.number,
      months: PropTypes.number,
      days: PropTypes.number,
      hours: PropTypes.number,
      minutes: PropTypes.number,
    }),
  };

  static defaultProps = {
    note: {
      content: '',
      created_at: '',
      documents: [],
      images: [],
      user: {
        accountable_id: null,
        first_name: '',
        last_name: '',
      },
    },
    elapsedTime: null,
  };

  render() {
    const { note, elapsedTime, currentUser } = this.props;

    const { user, created_at, content } = note;

    const mine = currentUser.id === user.id;

    return (
      <div
        className={`chat-message ${
          mine ? 'outgoing-message' : 'incoming-message'
        }`}
      >
        <div className="chat-message-content">
          <div className="chat-message-header">
            <span className="chat-message-name">
              {user.first_name} {user.last_name}
            </span>
            {elapsedTime ? (
              <span className="chat-message-date">
                {this.renderCreatedAt()}
              </span>
            ) : (
              created_at && (
                <Date
                  className="chat-message-date"
                  value={created_at}
                  format="MM/DD/YYYY hh:mm A"
                />
              )
            )}
          </div>
          <div className="chat-message-text">{content}</div>
          <ChatAttachments note={note} />
        </div>
        <div className="chat-message-arrow"></div>
      </div>
    );
  }

  renderCreatedAt() {
    const { elapsedTime } = this.props;
    const values = [];
    if (elapsedTime.years > 0) {
      values.push(elapsedTime.years + 'y');
    }
    if (elapsedTime.months > 0) {
      values.push(elapsedTime.months + 'mo');
    }
    // Only show two attributes from the range value
    if (elapsedTime.days > 0 && values.length < 2) {
      values.push(elapsedTime.days + 'd');
    }
    if (elapsedTime.hours > 0 && values.length < 2) {
      values.push(elapsedTime.hours + 'h');
    }
    if (elapsedTime.minutes > 0 && values.length < 2) {
      values.push(elapsedTime.minutes + 'm');
    }

    return values.join(' ');
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
  };
})(ChatMessage);
