import { authApi } from 'services/api/auth-api';
import { showErrorMessage } from 'actions/messages';

export function getLoginBackgroundImage() {
  return (dispatch) => {
    return authApi.getLoginBackgroundImage().then((response) => {
      if (response.ok) {
        dispatch(addBackgroundImageToStore(response.data.urlImage));
      } else {
        dispatch(showErrorMessage(response.errors));
        dispatch(addBackgroundImageToStore(''));
      }
    });
  };
}

function addBackgroundImageToStore(url) {
  return {
    type: 'ADD_BACKGROUND_IMAGE_TO_STORE',
    url,
  };
}
