import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import NavigationBar from 'components/shared/navigation/NavigationBar';
import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
} from 'components/shared/button/Button';

import { MEETING_TABS, DEFAULT_PARENT_FORM } from 'domain/new-meeting';

import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import {
  loadMeetingsParents,
  loadMeetingsTitles,
  loadMeetingsTypes,
  setFormMeetingInstance,
  setInstanceFormShow,
  setMeetingsTableTab,
} from './store/actions.js';
import {
  getProjectNewMeetingsFilters,
  getProjectNewMeetingsFormModal,
  getMeetingsInstanceModal,
  getMeetingsInstancesFilters,
} from './store/selectors';

import './ProjectNewMeetings.css';
import MeetingsTable from './MeetingsTable';
import MeetingsInstanceForm from './MeetingsInstanceForm';
import NewMeetingsSearchFilter from './NewMeetingsSearchFilter';
import { setCurrentMeeting, setMeetingsModalShow } from './store/actions.js';
import MeetingModal from './MeetingModal.js';
import { DEFAULT_INSTANCE_FORM } from '../../../../../domain/new-meeting.js';

const ProjectNewMeetingsModule = (props) => {
  const {
    dispatch,
    renderHeaders,
    filters,
    currentProject,
    directory,
    meetingModalShow,
    showInstanceForm,
    instancesFilters,
  } = props;

  useEffect(() => {
    if (currentProject && currentProject.id) {
      // Dispatch the action here
      dispatch(
        loadMeetingsParents(currentProject.id, 1, filters, instancesFilters)
      );
      dispatch(loadMeetingsTypes(currentProject.id));
      dispatch(loadMeetingsTitles(currentProject.id));
    }
    handleFilterChange();
    loadDirectory();
  }, []);

  const loadDirectory = () => {
    if (!directory || directory.project_id !== currentProject.id)
      dispatch(loadDirectoryRequest(currentProject.id));
  };

  // filters
  const handleFilterChange = (newFilters = {}, newInstancesFilters = {}) => {
    dispatch(
      loadMeetingsParents(currentProject.id, 1, newFilters, newInstancesFilters)
    );
  };

  const handleAddMeeting = () => {
    dispatch(setCurrentMeeting(DEFAULT_PARENT_FORM));
    dispatch(setMeetingsModalShow(true));
  };

  const handleQuickStart = () => {
    dispatch(
      setFormMeetingInstance({
        ...DEFAULT_INSTANCE_FORM,
        isQuickStart: true,
        status: 'draft_minutes',
        isAgenda: false,
      })
    );
    dispatch(setInstanceFormShow(true));
  };

  const buildHeaderButtons = () => {
    const res = [
      <NewMeetingsSearchFilter
        key="new-meeting-search-filter"
        handleFilterChange={handleFilterChange}
      />,
    ];

    res.push(
      <Button
        key="add-new-meeting"
        type={BUTTON_TYPES.LINK}
        icon={BUTTON_ICONS.PLUS}
        label="Add meeting"
        onClick={handleAddMeeting}
      />
    );
    res.push(
      <Button
        key="add-new-meeting-quickstart"
        type={BUTTON_TYPES.LINK}
        label="Quick Start"
        onClick={handleQuickStart}
      />
    );

    return res;
  };

  // tabs
  const handleTabChange = (tab) => {
    dispatch(setMeetingsTableTab(tab));
  };
  const buildTabs = () => {
    return MEETING_TABS.map((t) => ({
      ...t,
      class: t.id === filters.tab ? 'sel' : '',
    }));
  };

  return (
    <React.Fragment>
      {!showInstanceForm && (
        <div className="project-new-meeting content-container">
          {!showInstanceForm && renderHeaders(buildHeaderButtons())}
          {!showInstanceForm && (
            <div className="project-new-meeting navigation-bar">
              <NavigationBar
                items={buildTabs()}
                onSelected={handleTabChange}
                selectedValue={filters.tab}
              />
            </div>
          )}
          <div className="table-container">
            <MeetingsTable
              project={currentProject}
              handleFilterChange={handleFilterChange}
            />
          </div>
          {meetingModalShow && (
            <MeetingModal showActions={true} primary={true} />
          )}
        </div>
      )}
      {showInstanceForm && <MeetingsInstanceForm />}
    </React.Fragment>
  );
};

export default connect((state) => ({
  filters: getProjectNewMeetingsFilters(state),
  instanceFilters: getMeetingsInstancesFilters(state),
  currentProject: getCurrentProject(state),
  meetingModalShow: getProjectNewMeetingsFormModal(state),
  directory: getDirectory(state),
  showInstanceForm: getMeetingsInstanceModal(state),
}))(ProjectNewMeetingsModule);
