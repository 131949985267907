import React from 'react';
import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';
import useSnackbar from 'components/shared/snackbar/Snackbar.hook';
import {
  archiveProjectPhotos,
  downloadProjectPhotos,
  eraseProjectPhoto,
  moveProjectPhotosToAlbum,
  openProjectPhotosAlbumForm,
  openProjectPhotosForm,
  recycleProjectPhoto,
  restoreArchivedProjectPhoto,
  restoreProjectPhoto,
  setProjectPhotosSelectedAlbums,
  setProjectPhotosSelectedIds,
  setProjectPhotosSelectedPhotos,
  setProjectPhotosShowDelete,
  setProjectPhotosShowForm,
  setProjectPhotosShowMoveTo,
  toggleProjectPhotoFavorite,
} from './store/actions';

const useProjectPhotosActions = (props) => {
  const { dispatch, currentProject } = props;
  const { showSnackbar, closeSnackbar } = useSnackbar();

  const unselect = () => {
    dispatch(setProjectPhotosSelectedAlbums([], false));
    dispatch(setProjectPhotosSelectedPhotos([], false));
    dispatch(setProjectPhotosSelectedIds([]));
  };

  const undoSnackbar = (undo, count = 1, action = 'deleted') => {
    const msg = `${count} item${count > 1 ? 's' : ''} have been ${action}`;
    showSnackbar(msg, {
      action: (snackbarId) => (
        <button
          onClick={() => {
            closeSnackbar(snackbarId);
            undo();
          }}
        >
          UNDO
        </button>
      ),
    });
  };

  // favorite
  const moveToFavorites = (ids) => {
    return dispatch(
      toggleProjectPhotoFavorite(currentProject.id, ids, true)
    ).then((response) => {
      if (response.ok) unselect();
      return response;
    });
  };
  // archive
  const moveToArchive = (ids) => {
    return dispatch(archiveProjectPhotos(currentProject.id, ids)).then(
      (response) => {
        if (response.ok) {
          unselect();
          undoSnackbar(() => restoreArchived(ids), ids.length, 'archived');
        }
        return response;
      }
    );
  };
  // recycle
  const moveToRecycle = (ids) => {
    return dispatch(recycleProjectPhoto(currentProject.id, ids)).then(
      (response) => {
        if (response.ok) {
          unselect();
          undoSnackbar(() => restore(ids), ids.length);
        }

        return response;
      }
    );
  };
  // move
  const moveToAlbum = (ids, album) => {
    return dispatch(
      moveProjectPhotosToAlbum(currentProject.id, album.id, ids)
    ).then((response) => {
      if (response.ok) unselect();

      return response;
    });
  };
  // restore
  const restore = (ids) => {
    return dispatch(restoreProjectPhoto(currentProject.id, ids)).then(
      (response) => {
        if (response.ok) unselect();
        return response;
      }
    );
  };
  // restore archived
  const restoreArchived = (ids) => {
    return dispatch(restoreArchivedProjectPhoto(currentProject.id, ids)).then(
      (response) => {
        if (response.ok) unselect();
        return response;
      }
    );
  };
  // admin erase
  const erase = (ids) => {
    return dispatch(eraseProjectPhoto(currentProject.id, ids)).then(
      (response) => {
        if (response.ok) unselect();

        return response;
      }
    );
  };

  // menu actions
  const onUnselect = () => unselect();
  const onSelect = () => {
    dispatch(setProjectPhotosSelectedAlbums([], true));
    dispatch(setProjectPhotosSelectedPhotos([], true));
  };
  const onMove = (ids) => () => {
    dispatch(setProjectPhotosSelectedIds(ids));
    dispatch(setProjectPhotosShowMoveTo(true));
  };
  const onEditAlbum = (ids, load = false) => () => {
    dispatch(setProjectPhotosShowForm(false));
    dispatch(openProjectPhotosAlbumForm(currentProject.id, ids[0].id));
  };
  const onEdit = (ids) => () => {
    dispatch(openProjectPhotosForm(currentProject.id, ids));
  };
  const onDownload = (ids) => () => {
    dispatch(
      downloadProjectPhotos(
        currentProject.id,
        currentProject.name,
        currentProject.number,
        ids
      )
    ).then((response) => {
      if (response.ok) unselect();
    });
  };
  const onEmail = (ids) => () => {
    dispatch(setProjectPhotosSelectedIds(ids));
    dispatch(setShowEmailModal(true));
  };
  const onArchive = (ids) => () => moveToArchive(ids);
  const onRestoreArchived = (ids) => () => restoreArchived(ids);
  const onRecycle = (ids) => () => moveToRecycle(ids);

  const onRestoreRecycled = (ids) => () => restore(ids);
  const onErase = (ids) => () => {
    dispatch(setProjectPhotosSelectedIds(ids));
    dispatch(setProjectPhotosShowDelete(true));
  };

  return {
    // actions
    moveToAlbum,
    moveToArchive,
    moveToFavorites,
    moveToRecycle,
    erase,
    // menu
    onUnselect,
    onSelect,
    onMove,
    onEdit,
    onEditAlbum,
    onDownload,
    onEmail,
    onArchive,
    onRecycle,
    onErase,
    onRestoreArchived,
    onRestoreRecycled,
  };
};

export default useProjectPhotosActions;
