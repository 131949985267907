export const USERS_LOAD_SUCCESS = 'USERS_LOAD_SUCCESS';
export const USERS_INVITE_USER_FORM_INVALID = 'USERS_INVITE_USER_FORM_INVALID';
export const USERS_INVITE_USER_FORM_LOAD = 'USERS_INVITE_USER_FORM_LOAD';
export const USERS_INVITE_USER_FORM_VALID = 'USERS_INVITE_USER_FORM_VALID';
export const USERS_INVITE_USER_FORM_VALUE_UPDATE =
  'USERS_INVITE_USER_FORM_VALUE_UPDATE';
export const USERS_INVITE_USER_ID_SET = 'USERS_INVITE_USER_ID_SET';
export const USERS_INVITE_USER_MODAL_SHOW = 'USERS_INVITE_USER_MODAL_SHOW';
export const USERS_INVITE_USER_ROLES_LOAD_SUCCESS =
  'USERS_INVITE_USER_ROLES_LOAD_SUCCESS';
export const USERS_USER_ADD = 'USERS_USER_ADD';
export const USERS_USER_EDIT = 'USERS_USER_EDIT';
export const USERS_USER_REMOVE = 'USERS_USER_REMOVE';
