import { newMeetingsApi } from 'services/api/project-new-meetings-api';
import { newMeetingsInstancesApi } from 'services/api/project-new-meetings-instances-api';
import Meeting, { DEFAULT_MEETING_FORM_TAB } from 'domain/new-meeting';
import { showErrorMessage } from 'actions/messages';
import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';
import {
  PROJECT_NEW_MEETINGS_SET_FILTERS,
  PROJECT_NEW_MEETINGS_SET_INSTACES_FILTERS,
  PROJECT_NEW_MEETINGS_SET,
  PROJECT_NEW_MEETINGS_EDIT,
  PROJECT_NEW_MEETINGS_REMOVE,
  PROJECT_NEW_MEETINGS_ADD_NEW_MEETING,
  PROJECT_NEW_MEETINGS_SET_FORM,
  PROJECT_NEW_MEETINGS_SET_MESSAGE,
  PROJECT_NEW_MEETINGS_SET_FORM_TAB,
  PROJECT_NEW_MEETINGS_SET_FORM_MODAL,
  PROJECT_NEW_MEETINGS_SET_ADD_TYPE_MODAL,
  PROJECT_NEW_MEETINGS_SET_ADD_TITLE_MODAL,
  GET_MEETINGS_PARENTS_START,
  GET_MEETINGS_PARENTS_SUCCESS,
  GET_MEETINGS_PARENTS_ERROR,
  GET_MEETING_TYPES,
  GET_MEETING_TYPES_ERROR,
  GET_MEETING_TYPES_SUCCESS,
  GET_MEETING_TITLES,
  GET_MEETING_TITLES_ERROR,
  GET_MEETING_TITLES_SUCCESS,
  CREATE_MEETINGS_PARENT_SUCCESS,
  CREATE_MEETINGS_PARENT_ERROR,
  UPDATE_MEETINGS_PARENT_SUCCESS,
  UPDATE_MEETINGS_PARENT_ERROR,
  CREATE_TITLE,
  CREATE_TITLE_SUCCESS,
  CREATE_TITLE_ERROR,
  CREATE_TYPE,
  CREATE_TYPE_SUCCESS,
  CREATE_TYPE_ERROR,
  UPDATE_TITLE,
  UPDATE_TITLE_SUCCESS,
  UPDATE_TITLE_ERROR,
  UPDATE_TYPE,
  UPDATE_TYPE_SUCCESS,
  UPDATE_TYPE_ERROR,
  DELETE_TYPE,
  DELETE_TYPE_SUCCESS,
  DELETE_TYPE_ERROR,
  DELETE_TITLE,
  DELETE_TITLE_SUCCESS,
  DELETE_TITLE_ERROR,
  PROJECT_NEW_MEETINGS_SET_EDIT_TITLE_MODAL,
  PROJECT_NEW_MEETINGS_SET_EDIT_TYPE_MODAL,
  DELETE_TYPE_ERROR_MODAL_TOGGLE,
  PROJECT_NEW_MEETINGS_SET_INFO_MODAL,
  PROJECT_NEW_MEETINGS_SET_INFO_TAB,
  SET_MEETINGS_TABLE_TAB,
  SET_MEETINGS_INSTANCE_FORM_TAB,
  CREATE_MEETINGS_INSTANCE_SUCCESS,
  CREATE_MEETINGS_INSTANCE_ERROR,
  MEETINGS_INSTANCE_SET_FORM,
  MEETINGS_INSTANCE_SET,
  SET_SCHEDULE_MODAL,
  SET_MEETINGS_INSTANCE_FORM_SHOW,
  ADD_NEW_MEETING_MODAL_SHOW,
  EDIT_NEXT_MEETING_MODAL_SHOW,
  ACTION_ITEMS_RESOLVED_MODAL_SHOW,
  GET_MEETINGS_INSTANCE_ERROR,
  GET_MEETINGS_INSTANCE_SUCCESS,
  UPDATE_MEETINGS_INSTANCE_SUCCESS,
  UPDATE_MEETINGS_INSTANCE_ERROR,
  ADD_CATEGORY_MODAL_SHOW,
  CREATE_CATEGORY,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_ERROR,
  GET_ACTION_ITEMS_CATEGORIES,
  GET_ACTION_ITEMS_CATEGORIES_ERROR,
  GET_ACTION_ITEMS_CATEGORIES_SUCCESS,
  ADD_GUEST_MODAL_SHOW,
  CREATE_NON_DIRECTORY_ATTENDEE,
  CREATE_NON_DIRECTORY_ATTENDEE_ERROR,
  CREATE_NON_DIRECTORY_ATTENDEE_SUCCESS,
  CREATE_ACTION_ITEM,
  CREATE_ACTION_ITEM_ERROR,
  CREATE_ACTION_ITEM_SUCCESS,
  UPDATE_ACTION_ITEM,
  UPDATE_ACTION_ITEM_ERROR,
  UPDATE_ACTION_ITEM_SUCCESS,
  DELETE_ACTION_ITEM,
  DELETE_ACTION_ITEM_ERROR,
  DELETE_ACTION_ITEM_SUCCESS,
  SET_INSTANCE_ACTION_ITEMS,
  DELETE_INSTANCE_ERROR,
  DELETE_INSTANCE_SUCCESS,
  CREATE_ACTION_ITEM_UPDATE_SUCCESS,
  CREATE_ACTION_ITEM_UPDATE_ERROR,
  UPDATE_ACTION_ITEM_UPDATE_SUCCESS,
  UPDATE_ACTION_ITEM_UPDATE_ERROR,
  ACTION_ITEMS_HISTORY_MODAL_SHOW,
  ACTION_ITEMS_HISTORY_SET_FILTERS,
  ACTION_ITEMS_RESOLVED_SET_FILTERS,
  SET_NOTEPAD_SHOW,
  SET_SUBMIT_CORRECTIONS_MODAL_SHOW,
  SET_EDIT_CORRECTIONS_MODAL_SHOW,
  SET_VIEW_CORRECTIONS_MODAL_SHOW,
  SET_REVIEW_CORRECTIONS_MODAL_SHOW,
  CREATE_CORRECTION_ERROR,
  CREATE_CORRECTION_SUCCESS,
  MEETINGS_PARENTS_COUNT,
  SET_PENDING_CORRECTIONS_ERROR_MODAL_SHOW,
  SET_INSTANCE_INFO_ERRORS,
  SET_ACTION_ITEMS_ERRORS,
  SET_MEETINGS_INSTANCE_PDF_VIEWER,
  GET_MEETINGS_PARENT_SUCCESS,
  GET_MEETINGS_PARENT_ERROR,
  GET_MEETINGS_INSTANCE_AGENDA_BACKUP_SUCCESS,
  SET_MEETINGS_INSTANCE_FORM_IS_BACKUP,
  SET_CONFIRM_CLOSE_MODAL_SHOW,
  SET_PDF_WAITING_PREVIEW,
  SET_MEETINGS_INSTANCE_HTML,
  SET_MEETINGS_INSTANCE_HTML_VIEWER,
} from './action-types';
import { initialState } from './reducers.data';

/*************************************************
 ****************** REDUX STATE ******************
 ************************************************/

// tables
export const setFiltersProjectMeetings = (payload) => ({
  type: PROJECT_NEW_MEETINGS_SET_FILTERS,
  payload,
});
export const setInstancesFiltersProjectMeetings = (payload) => ({
  type: PROJECT_NEW_MEETINGS_SET_INSTACES_FILTERS,
  payload,
});
export const setMeetingsParentsCount = (count) => ({
  type: MEETINGS_PARENTS_COUNT,
  payload: count,
});

export const setActionItemHistoryDueDateFilters = (payload) => ({
  type: ACTION_ITEMS_HISTORY_SET_FILTERS,
  payload,
});

export const setActionItemsResolvedDueDateFilters = (payload) => ({
  type: ACTION_ITEMS_RESOLVED_SET_FILTERS,
  payload,
});

export const setNotepadShow = (payload) => ({
  type: SET_NOTEPAD_SHOW,
  payload,
});

// form
export const setInstanceFormIsBackup = (payload) => ({
  type: SET_MEETINGS_INSTANCE_FORM_IS_BACKUP,
  payload,
});
export const setFormTabProjectMeetings = (payload) => ({
  type: PROJECT_NEW_MEETINGS_SET_FORM_TAB,
  payload,
});
export const setInfoTabProjectMeetings = (payload) => ({
  type: PROJECT_NEW_MEETINGS_SET_INFO_TAB,
  payload,
});
export const setInstanceFormTab = (payload) => ({
  type: SET_MEETINGS_INSTANCE_FORM_TAB,
  payload,
});
export const setFormProjectMeetings = (payload = {}) => ({
  type: PROJECT_NEW_MEETINGS_SET_FORM,
  payload,
});

export const setFormMeetingInstance = (payload = {}) => ({
  type: MEETINGS_INSTANCE_SET_FORM,
  payload,
});

export const setCurrentMeeting = (payload = {}) => ({
  type: PROJECT_NEW_MEETINGS_SET,
  payload,
});
export const setCurrentMeetingInstance = (payload = {}) => ({
  type: MEETINGS_INSTANCE_SET,
  payload,
});
export const setMeetingsModalShow = (showAddTypeModal) => ({
  type: PROJECT_NEW_MEETINGS_SET_FORM_MODAL,
  payload: showAddTypeModal,
});
export const setScheduleModalShow = (showScheduleModal) => ({
  type: SET_SCHEDULE_MODAL,
  payload: showScheduleModal,
});
export const setDeleteTypeOrTitleErrorModalShow = (
  showDeleteTypeOrTitleErrorModal
) => ({
  type: DELETE_TYPE_ERROR_MODAL_TOGGLE,
  payload: showDeleteTypeOrTitleErrorModal,
});
export const setAddTypeModalShow = (addTypeModalShow) => ({
  type: PROJECT_NEW_MEETINGS_SET_ADD_TYPE_MODAL,
  payload: addTypeModalShow,
});
export const setEditTypeModalShow = (editTypeModalShow) => ({
  type: PROJECT_NEW_MEETINGS_SET_EDIT_TYPE_MODAL,
  payload: editTypeModalShow,
});

export const setEditTitleModalShow = (editTitleModalShow) => ({
  type: PROJECT_NEW_MEETINGS_SET_EDIT_TITLE_MODAL,
  payload: editTitleModalShow,
});

export const setAddTitleModalShow = (addTitleModalShow) => ({
  type: PROJECT_NEW_MEETINGS_SET_ADD_TITLE_MODAL,
  payload: addTitleModalShow,
});

export const setAddNewMeetingModalShow = (addNewMeetingModalShow) => ({
  type: ADD_NEW_MEETING_MODAL_SHOW,
  payload: addNewMeetingModalShow,
});

export const setEditNextMeetingModalShow = (editNextMeetingModalShow) => ({
  type: EDIT_NEXT_MEETING_MODAL_SHOW,
  payload: editNextMeetingModalShow,
});

export const setAddCategoryModal = (addCategoryModalShow) => ({
  type: ADD_CATEGORY_MODAL_SHOW,
  payload: addCategoryModalShow,
});

export const setActionItemsResolvedModal = (
  setActionItemsResolvedModalShow
) => ({
  type: ACTION_ITEMS_RESOLVED_MODAL_SHOW,
  payload: setActionItemsResolvedModalShow,
});

export const setPendingCorrectionsErrorModalShow = (
  pendingCorrectionsErrorModalShow
) => ({
  type: SET_PENDING_CORRECTIONS_ERROR_MODAL_SHOW,
  payload: pendingCorrectionsErrorModalShow,
});

export const setConfirmCloseModalShow = (confirmCloseModalShow) => ({
  type: SET_CONFIRM_CLOSE_MODAL_SHOW,
  payload: confirmCloseModalShow,
});
export function setPreviewModalShow(show) {
  return {
    type: SET_PDF_WAITING_PREVIEW,
    payload: show,
  };
}
export const setActionItemHistoryModal = (
  setActionItemHistoryModalShowPayload
) => ({
  type: ACTION_ITEMS_HISTORY_MODAL_SHOW,
  payload: setActionItemHistoryModalShowPayload,
});

export const setAddGuestModal = (addGuestModalShow) => ({
  type: ADD_GUEST_MODAL_SHOW,
  payload: addGuestModalShow,
});

export const setSubmitCorrectionsModalShow = (submitCorrectionsModalShow) => ({
  type: SET_SUBMIT_CORRECTIONS_MODAL_SHOW,
  payload: submitCorrectionsModalShow,
});

export const setEditCorrectionsModalShow = (editCorrectionsModalShow) => ({
  type: SET_EDIT_CORRECTIONS_MODAL_SHOW,
  payload: editCorrectionsModalShow,
});

export const setViewCorrectionsModalShow = (viewCorrectionsModalShow) => ({
  type: SET_VIEW_CORRECTIONS_MODAL_SHOW,
  payload: viewCorrectionsModalShow,
});

export const setReviewCorrectionsModalShow = (reviewCorrectionsModalShow) => ({
  type: SET_REVIEW_CORRECTIONS_MODAL_SHOW,
  payload: reviewCorrectionsModalShow,
});

export const toggleFormModalNewMeetingsModule = (show) => {
  return (dispatch) => {
    if (!show) {
      dispatch(setFormProjectMeetings(initialState.form));
      dispatch(setFormTabProjectMeetings(DEFAULT_MEETING_FORM_TAB));
    }
    dispatch(setFormProjectMeetings(show));
  };
};
export const getMeetingsParentToForm = (data) => {
  return {
    meetingType: data.meeting_type,
    title: data.title,
    address: data.address,
    location: data.location,
    access: data.access,
    jobWalk: data.job_walk,
    ranking: data.ranking,
    callInfo: data.call_info,
    callLink: data.call_link,
    webLink: data.web_link,
    otherInfo: data.other_info,
    privateMeeting: data.private_meeting,
    startDate: data.start_date,
    endDate: data.end_date,
    recurrence: data.recurrence,
    startTime: data.start_time,
    endTime: data.end_time,
    meetingExceptions: data.meeting_exceptions,
  };
};
export const getInstancesParentToForm = (data) => {
  return {
    meetingType: data.meeting_type,
    title: data.title,
    address: data.address,
    location: data.location,
    access: data.access,
    jobWalk: data.job_walk,
    ranking: data.ranking,
    callInfo: data.call_info,
    callLink: data.call_link,
    webLink: data.web_link,
    otherInfo: data.other_info,
    privateMeeting: data.private_meeting,
    startDate: data.start_date,
    endDate: data.end_date,
    startTime: data.start_time,
    endTime: data.end_time,
    meetingExceptions: data.meeting_exceptions,
    isAgenda: data.is_agenda,
    private: data.private,
    attendeeIds: data.attendee_ids,
    users: data.attendees,
  };
};

export const setActionItems = (payload) => {
  return { type: SET_INSTANCE_ACTION_ITEMS, payload };
};

// modal form

export const setFormModalProjectMeetings = (payload) => ({
  type: PROJECT_NEW_MEETINGS_SET_FORM_MODAL,
  payload,
});

export const setInstanceFormShow = (payload) => ({
  type: SET_MEETINGS_INSTANCE_FORM_SHOW,
  payload,
});
export const setInfoModalProjectMeetings = (payload) => ({
  type: PROJECT_NEW_MEETINGS_SET_INFO_MODAL,
  payload,
});
export const setAddTypeModal = (payload) => ({
  type: PROJECT_NEW_MEETINGS_SET_ADD_TYPE_MODAL,
  payload,
});

export const toggleAddTypeModal = (show) => {
  return (dispatch) => {
    dispatch(setAddTypeModal(show));
  };
};

export const setAddTitleModal = (payload) => ({
  type: PROJECT_NEW_MEETINGS_SET_ADD_TITLE_MODAL,
  payload,
});
export const toggleAddTitleModal = (show) => {
  return (dispatch) => {
    dispatch(setAddTitleModal(show));
  };
};

export const setEditTypeModal = (payload) => ({
  type: PROJECT_NEW_MEETINGS_SET_EDIT_TYPE_MODAL,
  payload,
});
export const toggleEditTypeModal = (show) => {
  return (dispatch) => {
    dispatch(setEditTypeModal(show));
  };
};

export const setEditTitleModal = (payload) => ({
  type: PROJECT_NEW_MEETINGS_SET_EDIT_TITLE_MODAL,
  payload,
});
export const toggleEditTitleModal = (show) => {
  return (dispatch) => {
    dispatch(setEditTitleModal(show));
  };
};
export const toggleFormModalProjectMeeting = (show) => {
  return (dispatch) => {
    if (!show) {
      dispatch(setFormTabProjectMeetings(initialState.form));
      dispatch(setFormTabProjectMeetings(DEFAULT_MEETING_FORM_TAB));
    }
    dispatch(setFormModalProjectMeetings(show));
  };
};

// message
export const setModalMessageProjectMeeting = (message, type = 'SUCCESS') => ({
  type: PROJECT_NEW_MEETINGS_SET_MESSAGE,
  payload: { message, type },
});

// meetings (rerenders)
export const setProjectMeetings = (payload) => ({
  type: PROJECT_NEW_MEETINGS_SET,
  payload,
});

// (no rerenders)
export const editMeetingsParents = (parentsMeetingId, values) => ({
  type: PROJECT_NEW_MEETINGS_EDIT,
  payload: { parentsMeetingId, values },
});

// schedules (no rerenders)
export const addProjectMeeting = (categoryId, values) => ({
  type: PROJECT_NEW_MEETINGS_ADD_NEW_MEETING,
  payload: { categoryId, values },
});
export const removeMeeting = (parentsMeetingId) => ({
  type: PROJECT_NEW_MEETINGS_REMOVE,
  payload: parentsMeetingId,
});

/*************************************************
 ******************* API CALLS *******************
 ************************************************/
const cleanFilters = (filters) => {
  const cleanedFilters = {};
  Object.keys(filters).forEach((key) => {
    if (filters[key] !== null && filters[key] !== '') {
      cleanedFilters[key] = filters[key];
    }
  });
  return cleanedFilters;
};

export const loadActionItemsCategories = (projectId) => {
  return (dispatch) => {
    dispatch({ type: GET_ACTION_ITEMS_CATEGORIES });

    newMeetingsApi
      .getActionItemsCategories(projectId)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
          dispatch({
            type: GET_ACTION_ITEMS_CATEGORIES_ERROR,
            payload: response.errors,
          });
        } else {
          dispatch({
            type: GET_ACTION_ITEMS_CATEGORIES_SUCCESS,
            payload: response.actionItemsCategories,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_ACTION_ITEMS_CATEGORIES_ERROR,
          payload: error,
        });
      });
  };
};

export const loadMeetingsParents = (
  projectId,
  page,
  filters,
  instanceFilters = {}
) => {
  return (dispatch) => {
    dispatch({ type: GET_MEETINGS_PARENTS_START });

    newMeetingsApi
      .getMeetingsParents(
        projectId,
        page,
        cleanFilters(filters),
        cleanFilters(instanceFilters)
      )
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
          dispatch({
            type: GET_MEETINGS_PARENTS_ERROR,
            payload: response.errors,
          });
        } else {
          dispatch(setFiltersProjectMeetings(cleanFilters(filters)));
          dispatch(
            setInstancesFiltersProjectMeetings(cleanFilters(instanceFilters))
          );
          dispatch({
            type: GET_MEETINGS_PARENTS_SUCCESS,
            payload: response.meetingsParents,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_MEETINGS_PARENTS_ERROR,
          payload: error,
        });
      });
  };
};

export const loadMeetingsTypes = (projectId) => {
  return (dispatch) => {
    dispatch({ type: GET_MEETING_TYPES });

    newMeetingsApi
      .getMeetingsParentTypes(projectId)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
          dispatch({
            type: GET_MEETING_TYPES_ERROR,
            payload: response.errors,
          });
        } else {
          dispatch({
            type: GET_MEETING_TYPES_SUCCESS,
            payload: response.meetingsParentsTypes,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_MEETING_TYPES_ERROR,
          payload: error,
        });
      });
  };
};

export const createActionItemCategory = (projectId, values) => {
  return (dispatch) => {
    dispatch({ type: CREATE_CATEGORY });
    newMeetingsApi
      .createActionItemCategory(projectId, values)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
          dispatch({ type: CREATE_CATEGORY_ERROR, payload: response.errors });
        } else {
          dispatch({
            type: CREATE_CATEGORY_SUCCESS,
            payload: response.actionItemsCategory,
          });
        }
      })
      .catch((error) => {
        dispatch(
          showErrorMessage({ type: CREATE_CATEGORY_ERROR, payload: error })
        );
      });
  };
};
export const createActionItem = (projectId, values) => {
  return (dispatch) => {
    dispatch({ type: CREATE_ACTION_ITEM });
    newMeetingsApi
      .createActionItem(projectId, values)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
          dispatch({
            type: CREATE_ACTION_ITEM_ERROR,
            payload: response.errors,
          });
        } else {
          dispatch({
            type: CREATE_ACTION_ITEM_SUCCESS,
            payload: response.meetingsActionItem,
          });
        }
      })
      .catch((error) => {
        dispatch(
          showErrorMessage({ type: CREATE_ACTION_ITEM_ERROR, payload: error })
        );
      });
  };
};
export const updateActionItem = (projectId, actionItemId, values) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_ACTION_ITEM });
    newMeetingsApi
      .updateActionItem(projectId, actionItemId, values)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
          dispatch({
            type: UPDATE_ACTION_ITEM_ERROR,
            payload: response.errors,
          });
        } else {
          dispatch({
            type: UPDATE_ACTION_ITEM_SUCCESS,
            payload: response.meetingsActionItem,
          });
        }
      })
      .catch((error) => {
        dispatch(
          showErrorMessage({ type: UPDATE_ACTION_ITEM_ERROR, payload: error })
        );
      });
  };
};
export const deleteActionItem = (projectId, actionItemId, values) => {
  return (dispatch) => {
    dispatch({ type: DELETE_ACTION_ITEM });
    newMeetingsApi
      .deleteActionItem(projectId, actionItemId, values)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
          dispatch({
            type: DELETE_ACTION_ITEM_ERROR,
            payload: response.errors,
          });
        } else {
          dispatch({
            type: DELETE_ACTION_ITEM_SUCCESS,
            payload: response.meetingsActionItem,
          });
        }
      })
      .catch((error) => {
        dispatch(
          showErrorMessage({ type: DELETE_ACTION_ITEM_ERROR, payload: error })
        );
      });
  };
};

export const createNonDirectoryAttendee = (projectId, nonDirectoryAttendee) => {
  return (dispatch) => {
    dispatch({ type: CREATE_NON_DIRECTORY_ATTENDEE });
    newMeetingsApi
      .createNonDirectoryAttendee(projectId, nonDirectoryAttendee)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
          dispatch({
            type: CREATE_NON_DIRECTORY_ATTENDEE_ERROR,
            payload: response.errors,
          });
        } else {
          dispatch({
            type: CREATE_NON_DIRECTORY_ATTENDEE_SUCCESS,
            payload: response.nonDirectoryAttendee,
          });
        }
      });
  };
};
export const createMeetingType = (projectId, typeName) => {
  return (dispatch) => {
    dispatch({ type: CREATE_TYPE });
    newMeetingsApi
      .createMeetingsParentsType(projectId, typeName)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
          dispatch({
            type: CREATE_TYPE_ERROR,
            payload: response.errors,
          });
        } else {
          dispatch({
            type: CREATE_TYPE_SUCCESS,
            payload: response,
          });
        }
      })
      .catch((error) => {
        dispatch(
          showErrorMessage({
            type: CREATE_TYPE_ERROR,
            payload: error,
          })
        );
      });
  };
};
export const updateMeetingType = (projectId, typeId, newName) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_TYPE });
    newMeetingsApi
      .updateMeetingsParentsType(projectId, typeId, newName)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
          dispatch({
            type: UPDATE_TYPE_ERROR,
            payload: response.errors,
          });
        } else {
          dispatch({
            type: UPDATE_TYPE_SUCCESS,
            payload: response.meetingsParentsTitles,
          });
        }
      })
      .catch((error) => {
        dispatch(
          showErrorMessage({
            type: UPDATE_TITLE_ERROR,
            payload: error,
          })
        );
      });
  };
};
export const createMeetingTitle = (projectId, titleName) => {
  return (dispatch) => {
    dispatch({ type: CREATE_TITLE });
    newMeetingsApi
      .createMeetingsParentsTitle(projectId, titleName)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
          dispatch({
            type: CREATE_TITLE_ERROR,
            payload: response.errors,
          });
        } else {
          dispatch({
            type: CREATE_TITLE_SUCCESS,
            payload: response,
          });
        }
      })
      .catch((error) => {
        dispatch(
          showErrorMessage({
            type: CREATE_TITLE_ERROR,
            payload: error,
          })
        );
      });
  };
};
export const updateMeetingTitle = (projectId, titleId, newName) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_TITLE });
    newMeetingsApi
      .updateMeetingsParentsTitle(projectId, titleId, newName)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
          dispatch({
            type: UPDATE_TITLE_ERROR,
            payload: response.errors,
          });
        } else {
          dispatch({
            type: UPDATE_TITLE_SUCCESS,
            payload: response.meetingsParentsTitles,
          });
        }
      })
      .catch((error) => {
        dispatch(
          showErrorMessage({
            type: UPDATE_TITLE_ERROR,
            payload: error,
          })
        );
      });
  };
};
export const deleteMeetingsInstance = (projectId, meetingsInstanceId) => {
  return (dispatch) => {
    newMeetingsInstancesApi
      .deleteMeetingsInstance(projectId, meetingsInstanceId)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
          dispatch({
            type: DELETE_INSTANCE_ERROR,
            payload: response.errors,
          });
        } else {
          dispatch({
            type: DELETE_INSTANCE_SUCCESS,
            payload: meetingsInstanceId,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: DELETE_INSTANCE_ERROR, payload: error });
      });
  };
};
export const deleteMeetingsParentsType = (projectId, typeId) => {
  return (dispatch) => {
    dispatch({ type: DELETE_TYPE });

    newMeetingsApi
      .deleteMeetingsParentsType(projectId, typeId)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
          dispatch({
            type: DELETE_TYPE_ERROR,
            payload: response.errors,
          });
        } else {
          dispatch({ type: DELETE_TYPE_SUCCESS, payload: typeId });
        }
      })
      .catch((error) => {
        dispatch({ type: DELETE_TYPE_ERROR, payload: error });
      });
  };
};

export const deleteMeetingsParentsTitle = (projectId, titleId) => {
  return (dispatch) => {
    dispatch({ type: DELETE_TITLE });

    newMeetingsApi
      .deleteMeetingsParentsTitle(projectId, titleId)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
          dispatch({
            type: DELETE_TITLE_ERROR,
            payload: response.errors,
          });
        } else {
          dispatch({ type: DELETE_TITLE_SUCCESS, payload: titleId });
        }
      })
      .catch((error) => {
        dispatch({ type: DELETE_TITLE_ERROR, payload: error });
      });
  };
};

export const loadMeetingsTitles = (projectId) => {
  return (dispatch) => {
    dispatch({ type: GET_MEETING_TITLES });

    newMeetingsApi
      .getMeetingsParentTitles(projectId)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
          dispatch({
            type: GET_MEETING_TITLES_ERROR,
            payload: response.errors,
          });
        } else {
          dispatch({
            type: GET_MEETING_TITLES_SUCCESS,
            payload: response.meetingsParentsTitles,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_MEETING_TITLES_ERROR,
          payload: error,
        });
      });
  };
};

export const updateMeetingsInstance = (
  projectId,
  meetingsInstanceId,
  values
) => {
  return (dispatch) =>
    newMeetingsInstancesApi
      .updateInstanceMeeting(projectId, meetingsInstanceId, values)
      .then((response) => {
        if (!response.ok) {
          dispatch({
            type: UPDATE_MEETINGS_INSTANCE_ERROR,
            payload: response.errors,
          });
          dispatch(showErrorMessage(response.errors));
        } else {
          dispatch({
            type: UPDATE_MEETINGS_INSTANCE_SUCCESS,
            payload: response.meetingsInstance,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_MEETINGS_INSTANCE_ERROR,
          payload: error,
        });
      });
};

export const getMeetingsInstance = (projectId, meetingsInstanceId) => {
  return (dispatch) =>
    newMeetingsInstancesApi
      .getMeetingsInstanceById(projectId, meetingsInstanceId)
      .then((response) => {
        if (!response.ok) {
          dispatch({
            type: GET_MEETINGS_INSTANCE_ERROR,
            payload: response.errors,
          });
          dispatch(showErrorMessage(response.errors));
        } else {
          dispatch({
            type: GET_MEETINGS_INSTANCE_SUCCESS,
            payload: response.meetingsInstance,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: GET_MEETINGS_INSTANCE_ERROR, payload: error });
      });
};
export const getMeetingsInstanceAgendaBackup = (
  projectId,
  meetingsInstanceId
) => {
  return (dispatch) =>
    newMeetingsInstancesApi
      .getMeetingsInstanceById(projectId, meetingsInstanceId)
      .then((response) => {
        if (!response.ok) {
          dispatch({
            type: GET_MEETINGS_INSTANCE_ERROR,
            payload: response.errors,
          });
          dispatch(showErrorMessage(response.errors));
        } else {
          dispatch({
            type: GET_MEETINGS_INSTANCE_AGENDA_BACKUP_SUCCESS,
            payload: response.meetingsInstance,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: GET_MEETINGS_INSTANCE_ERROR, payload: error });
      });
};
export const getMeetingsParent = (projectId, meetingsParentId) => {
  return (dispatch) =>
    newMeetingsApi
      .getMeetingsParentById(projectId, meetingsParentId)
      .then((response) => {
        if (!response.ok) {
          dispatch({
            type: GET_MEETINGS_PARENT_ERROR,
            payload: response.errors,
          });
          dispatch(showErrorMessage(response.errors));
        } else {
          dispatch({
            type: GET_MEETINGS_PARENT_SUCCESS,
            payload: response.meetingsParent,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: GET_MEETINGS_PARENT_ERROR, payload: error });
      });
};

export const updateMeetingsParent = (projectId, meetingsParentId, values) => {
  return (dispatch) =>
    newMeetingsApi
      .updateParentMeeting(projectId, meetingsParentId, values)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
          dispatch({ type: UPDATE_MEETINGS_PARENT_ERROR });
        } else {
          dispatch({ type: UPDATE_MEETINGS_PARENT_SUCCESS, payload: values });
        }
        return response;
      });
};

export const createMeetingsParent = (projectId, values) => {
  return (dispatch) => {
    newMeetingsApi
      .createMeetingsParent(projectId, values)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
          dispatch({
            type: CREATE_MEETINGS_PARENT_ERROR,
            payload: response.errors,
          });
        } else {
          dispatch({
            type: CREATE_MEETINGS_PARENT_SUCCESS,
            payload: response.meetingsParent,
          });
        }
      })
      .catch((error) => {
        dispatch(showErrorMessage(error));
        dispatch({
          type: CREATE_MEETINGS_PARENT_ERROR,
          payload: error,
        });
      });
  };
};
export const createMeetingsInstance = (projectId, values) => {
  return (dispatch) => {
    newMeetingsInstancesApi
      .createMeetingsInstance(projectId, values)
      .then((response) => {
        if (!response.ok) {
          dispatch(
            showErrorMessage({
              type: CREATE_MEETINGS_INSTANCE_ERROR,
              payload: response.errors,
            })
          );
        } else {
          dispatch({
            type: CREATE_MEETINGS_INSTANCE_SUCCESS,
            payload: response.meetingsInstance,
          });
        }
      })
      .catch((error) => {
        dispatch(showErrorMessage(error));
        dispatch({
          type: CREATE_MEETINGS_INSTANCE_ERROR,
          payload: error,
        });
      });
  };
};

export const createActionItemUpdate = (projectId, values) => {
  return (dispatch) => {
    newMeetingsApi
      .createActionItemUpdate(projectId, values)
      .then((response) => {
        if (!response.ok) {
          dispatch(
            showErrorMessage({
              type: CREATE_ACTION_ITEM_UPDATE_ERROR,
              payload: response.errors,
            })
          );
        } else {
          dispatch({
            type: CREATE_ACTION_ITEM_UPDATE_SUCCESS,
            payload: values,
          });
        }
      })
      .catch((error) => {
        dispatch(showErrorMessage(error));
        dispatch({
          type: CREATE_ACTION_ITEM_UPDATE_ERROR,
          payload: error,
        });
      });
  };
};

export const updateActionItemUpdate = (projectId, updateId, values) => {
  return (dispatch) => {
    newMeetingsApi
      .updateActionItemUpdate(projectId, updateId, values)
      .then((response) => {
        if (!response.ok) {
          dispatch(
            showErrorMessage({
              type: UPDATE_ACTION_ITEM_UPDATE_ERROR,
              payload: response.errors,
            })
          );
        } else {
          dispatch({
            type: UPDATE_ACTION_ITEM_UPDATE_SUCCESS,
            payload: values,
          });
        }
      })
      .catch((error) => {
        dispatch(showErrorMessage(error));
        dispatch({
          type: UPDATE_ACTION_ITEM_UPDATE_ERROR,
          payload: error,
        });
      });
  };
};
export const createMeetingsCorrection = (projectId, values) => {
  return (dispatch) =>
    newMeetingsApi
      .createMeetingsCorrection(projectId, values)
      .then((response) => {
        if (!response.ok) {
          dispatch(
            showErrorMessage({
              type: CREATE_CORRECTION_ERROR,
              payload: response.errors,
            })
          );
        } else {
          dispatch({
            type: CREATE_CORRECTION_SUCCESS,
            payload: values,
          });
        }
      })
      .catch((error) => {
        dispatch(showErrorMessage(error));
        dispatch({
          type: CREATE_CORRECTION_ERROR,
          payload: error,
        });
      });
};
export const setMeetingsTableTab = (tab) => {
  return (dispatch) => {
    dispatch({ type: SET_MEETINGS_TABLE_TAB, payload: tab });
  };
};
export const setInstanceInfoErrors = (values) => {
  const errs = Meeting.instanceInfoFormErrors(values);
  return (dispatch) => {
    dispatch({ type: SET_INSTANCE_INFO_ERRORS, payload: errs });
  };
};
export const setActionItemsErrors = (items) => {
  const errs = Meeting.checkIfActionItemsAreIncomplete(items);
  return (dispatch) => {
    dispatch({ type: SET_ACTION_ITEMS_ERRORS, payload: errs });
  };
};
export const setMeetingsInstancePdfViewerShow = (
  showMeetingsInstancePdfViewer
) => {
  return (dispatch) => {
    dispatch({
      type: SET_MEETINGS_INSTANCE_PDF_VIEWER,
      payload: showMeetingsInstancePdfViewer,
    });
  };
};

export const setMeetingsInstanceHtmlViewerShow = (
  showMeetingsInstanceHtmlViewer
) => {
  return (dispatch) => {
    dispatch({
      type: SET_MEETINGS_INSTANCE_HTML_VIEWER,
      payload: showMeetingsInstanceHtmlViewer,
    });
  };
};
export const sendInstanceEmailReport = (
  projectId,
  instanceId,
  emails,
  subject,
  message,
  documentType
) => {
  return (dispatch) => {
    return newMeetingsInstancesApi
      .sendEmail(projectId, instanceId, emails, subject, message, documentType)
      .then((response) => {
        if (response.ok) {
          dispatch(setShowEmailModal(false));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
};
export const sendNextMeetingChangeEmail = (
  projectId,
  parentId,
  emails,
  subject,
  message
) => {
  return (dispatch) => {
    return newMeetingsApi
      .sendNextMeetingChangeEmail(projectId, parentId, emails, subject, message)
      .then((response) => {
        if (response.ok) {
          dispatch(setShowEmailModal(false));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
};

export const setMeetingsInstanceHtml = (html) => {
  return { type: SET_MEETINGS_INSTANCE_HTML, payload: html };
};
