import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Reports.css';
import { openTaskDetails } from 'actions/admin/tasks/tasks';
import { getReportResults } from 'selectors/admin/reports/reports';
import BaseReport from 'components/admin/reports/BaseReport';
import Date from 'components/shared/text/Date';
import Selector from 'components/shared/selector/Selector';
import { getExtraFilterValues } from 'selectors/admin/reports/reports';
import { changeExtraFilterValue } from 'actions/admin/reports/reports';

class OpenItemsReport extends Component {
  handleRowSelection = (index) => {
    this.props.dispatch(
      openTaskDetails(this.props.reportResults.data[index].task)
    );
  };

  createTableRowsData = () => {
    if (this.props.reportResults !== null) {
      let woNumber = null;
      return this.props.reportResults.data.map((result, index) => {
        let first = result.work_order.number !== woNumber;
        const rowData = {
          id: index,
          selectable: true,
          data: [
            first ? result.work_order.number : '',
            first ? result.work_order.name : '',
            first ? result.client.name : '',
            `${result.task.number}-${result.letter}`,
            result.task.name,
            `${result.user.first_name} ${result.user.last_name}`,
            result.description,
            <Date value={result.due_date} format="LL" />,
            result.resolved ? 'Complete' : 'Incomplete',
            result.resolved_note,
          ],
        };
        if (!first) {
          rowData['className'] = 'no-top-border';
        }
        woNumber = result.work_order.number;
        return {
          rowData,
          first,
          workOrderId: result.work_order.id,
        };
      });
    } else {
      return [];
    }
  };

  handleExtraFilterValueChange = (attribute, value) => {
    this.props.dispatch(changeExtraFilterValue(attribute, value));
  };

  extraFilters = () => {
    return [
      <Selector
        options={[
          { value: 0, label: 'Incomplete' },
          { value: 1, label: 'Complete' },
        ]}
        clearable={true}
        placeholder="All"
        value={this.props.extraFilterValues['resolved']}
        onChange={(newOption) =>
          this.handleExtraFilterValueChange(
            'resolved',
            newOption ? newOption.value : ''
          )
        }
      />,
    ];
  };

  render() {
    return (
      <BaseReport
        reportName="open_items_report"
        tableHeader={[
          'WO #',
          'NAME',
          'OWNER NAME',
          'TASK #',
          'TASK NAME',
          'ASSIGNED TO',
          'DESCRIPTION',
          'DUE',
          'STATUS',
          'NOTE',
        ]}
        tableRowsData={this.createTableRowsData()}
        title="Open Items"
        onRowSelection={this.handleRowSelection}
        extraFilters={this.extraFilters()}
      />
    );
  }
}

export default connect((state) => {
  return {
    reportResults: getReportResults(state),
    extraFilterValues: getExtraFilterValues(state),
  };
})(OpenItemsReport);
