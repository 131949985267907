import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCurrentUser } from 'selectors/authentication';
import Chat from 'components/shared/chat/Chat';
import {
  getCurrentProjectOpenItem,
  getProjectOpenItemNotes,
} from 'components/admin/projects/details/project-open-items/store/selectors';
import {
  markOpenItemNotesRead,
  loadOpenItemNotes,
  createOpenItemNote,
  clearItemNotes,
} from 'components/admin/projects/details/project-open-items/store/actions';

class ProjectOpenItemNotes extends Component {
  componentDidMount() {
    this.markNotesRead();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearItemNotes());
  }

  markNotesRead = () => {
    const { currentUser, openItem, dispatch } = this.props;
    dispatch(markOpenItemNotesRead(openItem, currentUser.id));
  };

  loadNotes = () => {
    const { openItem, dispatch } = this.props;
    return dispatch(loadOpenItemNotes(openItem.id));
  };

  handleSubmit = (newNote) => {
    const { openItem, dispatch } = this.props;
    return dispatch(createOpenItemNote(openItem.id, newNote));
  };

  render() {
    const { openItemNotes } = this.props;
    return (
      <Chat
        name="Open Item"
        notes={openItemNotes}
        loadChat={this.loadNotes}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    openItem: getCurrentProjectOpenItem(state),
    openItemNotes: getProjectOpenItemNotes(state),
  };
})(ProjectOpenItemNotes);
