import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { getDarkMode } from 'selectors/theme';
import ImageUpload from 'components/shared/image-upload/ImageUpload';
import FullScreenImageViewer from 'components/shared/image-upload/FullScreenImageViewer';
import { imageExtensions } from 'services/utils/files-util';
import DocumentUpload, {
  FILE_TYPES,
} from 'components/shared/document-upload/DocumentUpload';
import DocumentLink from 'components/shared/document/DocumentLink';
import DocumentPreview from 'components/shared/document/DocumentPreview';
import {
  setProjectReportAttr,
  getProjectReportsPreviousImageAndDocuments,
  projectReportPhotosAndDocumentsFilterClear,
  projectReportPhotosAndDocumentsFilter,
  setProjectPhotosAndDocumentsFilteringActive,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';
import {
  getProjectReportValue,
  getFieldProjectResolvedInfo,
  getProjectPhotosAndDocumentsFilteringActive,
} from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import filterIcon from 'components/shared/navigation/filterIcon.svg';
import filterIconDark from 'components/shared/navigation/filterIconDark.svg';

import FilterReportsModal from './FilterReportsModal';
import './ReportsForm.css';

class PhotosDocumentsForm extends Component {
  constructor(props) {
    super(props);
    this.filterModal = React.createRef();
    this.state = {
      isViewResolved: false,
      modalShow: false,
      showFullScreen: false,
      fullScreenUrl: '',
    };
  }

  componentDidMount() {
    const { dispatch, projectId } = this.props;
    projectId &&
      dispatch(getProjectReportsPreviousImageAndDocuments(projectId));
  }

  handleFullScreenOpen = (url, event) => {
    event.preventDefault();
    this.setState({
      fullScreenUrl: url,
      showFullScreen: true,
    });
  };

  handleFullScreenClose = () => {
    this.setState({
      showFullScreen: false,
    });
  };

  handleAttributeChange = (attr, value) => {
    this.props.dispatch(setProjectReportAttr(attr, value));
  };

  setDocuments = (files) => {
    let docs = [...this.getArrayAttribute('documents')];
    files.forEach((val) => {
      docs.push({ file: val, name: val.name, position: docs.length });
    });

    this.handleAttributeChange('documents', docs);
  };

  removeDocument = (index) => {
    let docs = [...this.getArrayAttribute('documents')];
    let deletedDocs = [...this.getArrayAttribute('deletedDocuments')];
    let deletedDoc = docs.splice(index, 1);
    deletedDocs.push(deletedDoc[0]);

    this.handleAttributeChange('documents', docs);
    this.handleAttributeChange('deletedDocuments', deletedDocs);
  };

  getArrayAttribute = (attr) => {
    const { report } = this.props;
    if (report && report[attr] && report[attr].length > 0) {
      return report[attr];
    }
    return [];
  };

  setImages = (files) => {
    const images = [...this.getArrayAttribute('images')];
    files.forEach(function (val) {
      images.push({
        url: val.url || URL.createObjectURL(val),
        file: val,
        position: images.length,
      });
    });

    this.handleAttributeChange('images', images);
  };

  setImageData = (index, { description }) => {
    let images = [...this.getArrayAttribute('images')];
    images = images.map((image, imageIndex) =>
      imageIndex === index ? { ...image, description } : image
    );
    this.handleAttributeChange('images', images);
  };

  removeImage = (index) => {
    const images = [...this.getArrayAttribute('images')];
    const deletedImages = [...this.getArrayAttribute('deletedImages')];
    const deletedImage = images.splice(index, 1);
    deletedImages.push(deletedImage[0]);
    this.handleAttributeChange('images', images);
    this.handleAttributeChange('deletedImages', deletedImages);
  };

  getImageDiv(index, source, fullScreenInfo = null) {
    return (
      <img
        key={index}
        className="image-container"
        src={source}
        alt={''}
        onClick={this.handleFullScreenOpen.bind(this, fullScreenInfo.url)}
      />
    );
  }

  getViewResolved = () => {
    const { dispatch } = this.props;
    const { isViewResolved } = this.state;
    this.setState({ isViewResolved: !isViewResolved });
    if (isViewResolved) {
      dispatch(projectReportPhotosAndDocumentsFilterClear());
      dispatch(setProjectPhotosAndDocumentsFilteringActive(false));
      this.filterModal.current.getWrappedInstance().clearFiltering();
    }
  };

  renderLink(label, onHandle) {
    return (
      <div className="document-links-label" onClick={() => onHandle()}>
        {label}
      </div>
    );
  }

  render() {
    const {
      report,
      darkMode,
      resolvedInfo,
      dispatch,
      reportFilterActive,
    } = this.props;
    const { modalShow, isViewResolved } = this.state;
    const documents = report.documents || [];
    let previousData = resolvedInfo.photosAndDocuments || [];
    previousData = previousData.filter(
      (item) => item.images.length > 0 || item.documents.length > 0
    );

    return (
      <div>
        <div className="project-status-view-resolved">
          <Button
            key="project-status-view-resolved"
            type={BUTTON_TYPES.LINK}
            color={BUTTON_COLORS.GREEN}
            label={isViewResolved ? 'Hide Resolved' : 'View Resolved'}
            onClick={this.getViewResolved.bind(this)}
          />
        </div>
        <div className="row-block-documents">
          <div className="report-form-block documents">
            <label className="report-form-block-label">Documents</label>
            <div className="item-block">
              <div className="item-block-documents">
                <DocumentPreview
                  documents={documents}
                  onRemove={(_, index) => this.removeDocument(index)}
                  onOrder={(docs) =>
                    this.handleAttributeChange('documents', docs)
                  }
                />
                {documents.length > 0 && <div style={{ marginTop: '24px' }} />}
                <div style={{ width: '213px' }}>
                  <DocumentUpload
                    fileType={FILE_TYPES.PDF}
                    onDrop={(files) => this.setDocuments(files)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="report-form-block photos">
            <label className="report-form-block-label">Photos</label>
            <div className="image-upload-container item-block">
              <ImageUpload
                multiple={true}
                extensions={imageExtensions}
                onUpload={(files) => this.setImages(files)}
                onImageChange={this.setImageData}
                onRemove={(index) => this.removeImage(index)}
                images={report.images || []}
                readOnly={false}
              />
            </div>
          </div>
        </div>
        {isViewResolved && (
          <React.Fragment>
            <div className="filter-icon">
              {reportFilterActive ? (
                <Button
                  key="clear-filter"
                  type={BUTTON_TYPES.LINK}
                  color={BUTTON_COLORS.GREEN}
                  label="Clear Filter"
                  onClick={() => {
                    dispatch(projectReportPhotosAndDocumentsFilterClear());
                    dispatch(
                      setProjectPhotosAndDocumentsFilteringActive(false)
                    );
                    this.filterModal.current
                      .getWrappedInstance()
                      .clearFiltering();
                  }}
                />
              ) : (
                <img
                  src={darkMode ? filterIconDark : filterIcon}
                  alt="Filter"
                  onClick={() => this.setState({ modalShow: true })}
                />
              )}
            </div>
            {previousData
              .sort((a, b) => b.number - a.number)
              .map((item, index) => (
                <div key={index} style={{ marginBottom: 40 }}>
                  <label className="report-form-block-label">
                    {`Project Report ${
                      item.number < 10 ? '0' + item.number : item.number
                    }`}
                  </label>
                  <label className="report-form-weekly-report-date">
                    {moment(item.date).format('MM/DD/YYYY')}
                  </label>
                  <div className="row-block-documents">
                    {item.documents.length > 0 && (
                      <div className="report-form-block documents">
                        <div className="item-block">
                          {item.documents.map((doc, index) => (
                            <div
                              key={index}
                              className="row-block row-space-between row-align-items-center"
                            >
                              <div className="document-name">
                                <DocumentLink
                                  key={index}
                                  url={doc.document.url}
                                  showIcon
                                />
                              </div>
                            </div>
                          ))}
                          {documents.length > 2 &&
                            this.renderLink('Show more', null)}
                        </div>
                      </div>
                    )}
                    <div>
                      {item.images &&
                        item.images.map((image, index) =>
                          this.getImageDiv(index, image.image.url, {
                            show: image.image.url !== null,
                            url: image.image.url,
                          })
                        )}
                    </div>
                  </div>
                </div>
              ))}
          </React.Fragment>
        )}
        <FilterReportsModal
          show={modalShow}
          onClose={() => this.setState({ modalShow: false })}
          onSend={(startDate, endDate) => {
            dispatch(projectReportPhotosAndDocumentsFilter(startDate, endDate));
            dispatch(setProjectPhotosAndDocumentsFilteringActive(true));
          }}
          ref={this.filterModal}
        />
        <FullScreenImageViewer
          show={this.state.showFullScreen}
          url={this.state.fullScreenUrl}
          onClose={this.handleFullScreenClose}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    darkMode: getDarkMode(state),
    report: getProjectReportValue(state),
    resolvedInfo: getFieldProjectResolvedInfo(state),
    reportFilterActive: getProjectPhotosAndDocumentsFilteringActive(state),
  }),
  null
)(PhotosDocumentsForm);
