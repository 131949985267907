import {
  CLOSEOUT_CATS_SET,
  CLOSEOUT_SET,
  CLOSEOUT_CONTRIBS_SET,
  CLOSEOUT_CONTRIB_SET,
  CLOSEOUT_PERCENTAGE_SET,
  CLOSEOUT_MANUALS_SET,
  CLOSEOUT_MANUAL_SET,
  CLOSEOUT_MANUAL_UPDATE,
  CLOSEOUT_MANUAL_DELETE,
  CLOSEOUT_COMPANY_TYPES_SET,
  CLOSEOUT_CATS_COMPANY_TYPES_SET,
  CLOSEOUT_CATS_COMPANY_TYPE_SET,
  CLOSEOUT_CAT_DELETE,
  CLOSEOUT_CAT_UPDATE,
  CLOSEOUT_CATS_ORDER_CHANGE,
  SHOW_CLOSEOUT_CAT_DELETION_CONFIRMATION,
  SHOW_CLOSEOUT_CAT_MODAL,
  CLOSEOUT_CAT_SET,
  CLOSEOUT_CONTRIB_CAT_UPDATE,
  HIDE_CLOSEOUT_CAT_MODAL,
  HIDE_CLOSEOUT_CAT_DELETION_CONFIRMATION,
} from 'components/admin/projects/details/project-closeout/store/action-types';

const initialState = {
  closeoutCats: [],
  closeout: null,
  closeoutContribs: [],
  currentPage: 1,
  total: 0,
  pageSize: 25,
  closeoutPercentage: 0,
  closeoutManuals: [],
  closeoutManualsCurrentPage: 1,
  closeoutManualsTotal: 0,
  closeoutManualsPageSize: 25,
  closeoutManual: null,
  closeoutCompanyTypes: [],
  closeoutCatsCompanyTypes: [],
  tab: 1,
  showCloseoutCatDeletionConfirmation: false,
  showCloseoutCatModal: false,
  selectedCloseoutCat: null,
};

const setNewOrder = (state, action) => {
  const newCloseoutCatOrder = Array.from(state.closeoutCats);
  const cat = newCloseoutCatOrder.splice(action.payload.source.index, 1);
  newCloseoutCatOrder.splice(action.payload.destination.index, 0, cat[0]);
  return newCloseoutCatOrder;
};

export default function projectCloseoutDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case CLOSEOUT_CATS_SET:
      return {
        ...state,
        closeoutCats: action.payload,
      };

    case CLOSEOUT_COMPANY_TYPES_SET:
      return {
        ...state,
        closeoutCompanyTypes: action.payload,
      };

    case CLOSEOUT_CATS_COMPANY_TYPES_SET:
      return {
        ...state,
        closeoutCatsCompanyTypes: action.payload,
      };

    case CLOSEOUT_CATS_COMPANY_TYPE_SET:
      return {
        ...state,
        closeoutCatsCompanyTypes: state.closeoutCatsCompanyTypes.map(
          (catsCompanyType) => {
            return catsCompanyType.id === action.payload.id
              ? action.payload
              : catsCompanyType;
          }
        ),
      };

    case CLOSEOUT_SET:
      return {
        ...state,
        closeout: action.payload,
      };

    case CLOSEOUT_CONTRIBS_SET:
      return {
        ...state,
        ...action.payload,
      };

    case CLOSEOUT_CONTRIB_SET:
      return {
        ...state,
        closeoutContribs: state.closeoutContribs.map((closeoutContrib) => {
          return closeoutContrib.id === action.payload.id
            ? action.payload
            : closeoutContrib;
        }),
      };

    case CLOSEOUT_PERCENTAGE_SET:
      return {
        ...state,
        closeoutPercentage: action.payload,
      };

    case CLOSEOUT_MANUALS_SET:
      return {
        ...state,
        ...action.payload,
      };

    case CLOSEOUT_MANUAL_SET:
      return {
        ...state,
        closeoutManual: action.payload,
        closeoutManuals: [action.payload, ...state.closeoutManuals],
      };

    case CLOSEOUT_MANUAL_UPDATE:
      return {
        ...state,
        closeoutManual: action.payload,
        closeoutManuals: state.closeoutManuals.map((clsManual) => {
          return clsManual.id === action.payload.id
            ? action.payload
            : clsManual;
        }),
      };

    case CLOSEOUT_MANUAL_DELETE:
      return {
        ...state,
        closeoutManuals: state.closeoutManuals.filter((clsManual) => {
          return clsManual.id !== action.payload;
        }),
      };

    case CLOSEOUT_CAT_DELETE:
      return {
        ...state,
        showCloseoutCatDeletionConfirmation: false,
        selectedCloseoutCat: null,
        closeoutCats: state.closeoutCats.filter((cat) => {
          return cat.id !== action.payload;
        }),
      };

    case CLOSEOUT_CAT_UPDATE:
      return {
        ...state,
        selectedCloseoutCat: null,
        showCloseoutCatModal: false,
        closeoutCats: state.closeoutCats.map((cat) => {
          if (cat.id === action.payload.id) {
            return {
              ...cat,
              label: action.payload.label,
            };
          } else {
            return cat;
          }
        }),
      };

    case CLOSEOUT_CATS_ORDER_CHANGE:
      return {
        ...state,
        closeoutCats: setNewOrder(state, action),
      };

    case SHOW_CLOSEOUT_CAT_DELETION_CONFIRMATION:
      return {
        ...state,
        selectedCloseoutCat: action.payload.closeoutCat,
        showCloseoutCatDeletionConfirmation: true,
      };

    case HIDE_CLOSEOUT_CAT_DELETION_CONFIRMATION:
      return {
        ...state,
        selectedCloseoutCat: null,
        showCloseoutCatDeletionConfirmation: false,
      };

    case SHOW_CLOSEOUT_CAT_MODAL:
      return {
        ...state,
        selectedCloseoutCat: action.payload.closeoutCat,
        showCloseoutCatModal: true,
      };

    case HIDE_CLOSEOUT_CAT_MODAL:
      return {
        ...state,
        selectedCloseoutCat: null,
        showCloseoutCatModal: false,
      };

    case CLOSEOUT_CAT_SET:
      return {
        ...state,
        showCloseoutCatModal: false,
        selectedCloseoutCat: null,
        closeoutCats: state.closeoutCats.concat(action.payload.closeoutCat),
      };

    case CLOSEOUT_CONTRIB_CAT_UPDATE:
      return {
        ...state,
        closeoutContribs: state.closeoutContribs.map((contrib) => {
          if (
            contrib.id !==
            action.payload.closeoutContributorCat.closeout_contributor_id
          ) {
            return contrib;
          } else {
            contrib.closeout_contributor_cats.map((contribCat) => {
              if (contribCat.id !== action.payload.closeoutContributorCat.id) {
                return contribCat;
              } else {
                contribCat.applies =
                  action.payload.closeoutContributorCat.applies;
                return contribCat;
              }
            });
            return contrib;
          }
        }),
      };

    default:
      return state;
  }
}
