import React from 'react';

const FavoriteIcon = ({
  fill = '#D4FB00',
  stroke = '#D4FB00',
  width = 30,
  height = 28,
  outlined = false,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.19 10.8156L18.2692 11.0563H18.5225H28.8912L20.4041 17.0072L20.1887 17.1582L20.2738 17.4071L23.5831 27.0792L15.2037 21.0804L15 20.9346L14.7963 21.0804L6.41597 27.0798L9.71934 17.4069L9.80427 17.1582L9.58916 17.0072L1.10815 11.0563H11.4775H11.7308L11.81 10.8156L15 1.11988L18.19 10.8156Z"
        fill={outlined ? 'transparent' : fill}
        stroke={stroke}
        strokeWidth={outlined ? 2 : 1}
      />
    </svg>
  );
};

export default FavoriteIcon;
