import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Header.css';
import logo from './logo-white.svg';

class HeaderOnlyLogo extends Component {
  render() {
    return (
      <header className="header white-bg">
        <img src={logo} className="logo" alt="360 Logo" />
      </header>
    );
  }
}

export default connect((state) => {
  return {};
})(HeaderOnlyLogo);
