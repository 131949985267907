import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import FormModal from 'components/shared/modal/FormModal';
import SimpleTable from 'components/shared/table/SimpleTable';
import FilterHeader from 'components/shared/table/FilterHeader';

import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';

import { setActionItemHistoryModal, setActionItems } from './store/actions';
import {
  getActionItems,
  getActionItemHistoryModal,
  getMeetingsInstanceForm,
  getProjectNewMeetingsForm,
} from './store/selectors';
import './ActionItemsResolvedModal.css';
import Meeting from 'domain/new-meeting';
import { upperCasetoCamelCase } from '../../../../../services/utils/case-utils';

const ActionItemHistoryModal = (props) => {
  const { dispatch, show, actionItems, actionItem, instanceForm, form } = props;
  const [filters, setFilters] = useState({});
  const [dateTo, setDateTo] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [originalOrder, setOriginalOrder] = useState([]);
  const handleClose = () => {
    dispatch(setActionItems(originalOrder));
    dispatch(setActionItemHistoryModal(false));
  };

  useEffect(() => {
    setOriginalOrder([...actionItems]);
  }, []);

  useEffect(() => {
    if (!dateFrom) return;
    let sortedUpdates = [...actionItem.actionItemsUpdates];
    sortedUpdates = sortedUpdates.filter((a) => {
      return a.due_date
        ? moment(a.due_date.split('T')[0]).isAfter(moment(dateFrom), 'day')
        : false;
    });

    dispatch(setActionItems(sortedUpdates));
  }, [dateFrom]);
  useEffect(() => {
    if (!dateTo) return;
    let sortedUpdates = [...actionItems.actionItemsUpdates];
    sortedUpdates = sortedUpdates.filter((a) => {
      return a.due_date
        ? moment(a.due_date.split('T')[0]).isBefore(moment(dateTo), 'day')
        : false;
    });
    dispatch(setActionItems(sortedUpdates));
  }, [dateTo]);
  const parseDate = (date) => {
    const d = moment(date);
    return d.isValid() ? d.utc().format('MM/DD/YYYY') : '--';
  };
  const parseDateShort = (date) => {
    const d = moment(date);
    return d.isValid() ? d.utc().format('MM/DD/') : '--';
  };
  const actionItemsHeaders = [
    'ITEM NO',
    'ITEM DATE',
    'DESCRIPTION',
    'CATEGORY',
    'DUE DATE',
    'PRIORITY',
    'RESOLVED BY',
    'RESOLVED ON',
  ];

  const handleDateChangeFrom = (option) => {
    handleFilterChange(option, 'FROM');
  };
  const handleDateChangeTo = (option) => {
    handleFilterChange(option, 'TO');
  };
  const handleDateValueChangeTo = (value) => {
    setDateTo(value);
    handleFilterChange('to', 'TO');
  };
  const handleDateValueChangeFrom = (value) => {
    setDateFrom(value);
    handleFilterChange('from', 'FROM');
  };

  const textFilterOptions = [
    { id: 'clear_filter', label: 'Clear Filter' },
    { id: 'ascending', label: 'Ascending' },
    { id: 'descending', label: 'Descending' },
  ];
  const dateFilterOptions = [
    { id: 'clear_filter', label: 'Clear Filter' },
    { id: 'newest', label: 'Newest' },
    { id: 'oldest', label: 'Oldest' },
    {
      id: 'range',
      label: 'Range',
      subitems: [
        {
          id: 'from',
          label: 'From',
          isDate: true,
          onChange: handleDateChangeFrom,
          onDateChange: handleDateValueChangeFrom,
          dateValue: dateFrom,
        },
        {
          id: 'to',
          label: 'To',
          isDate: true,
          onChange: handleDateChangeTo,
          onDateChange: handleDateValueChangeTo,
          dateValue: dateTo,
        },
      ],
    },
  ];

  const handleFilterChange = (option, name) => {
    const key = upperCasetoCamelCase(name);
    setFilters({ ...filters, [name]: option });
    // Deep copy of actionItem to avoid direct state mutation
    let sortedActionItem = { ...actionItem };

    // Sorting function that can be reused for both actionItemsUpdates and nestedUpdates
    const sortUpdates = (updates) => {
      return updates.sort((a, b) => {
        if (option === 'ascending' || option === 'newest') {
          return a[key] < b[key] ? -1 : 1;
        } else if (option === 'descending' || option === 'oldest') {
          return a[key] > b[key] ? -1 : 1;
        }
        return 0;
      });
    };

    // Sort actionItemsUpdates
    if (sortedActionItem.actionItemsUpdates) {
      sortedActionItem.actionItemsUpdates = sortUpdates(
        sortedActionItem.actionItemsUpdates
      );

      // Sort nestedUpdates within each actionItemUpdate
      sortedActionItem.actionItemsUpdates.forEach((update) => {
        if (update.nestedUpdates) {
          update.nestedUpdates = sortUpdates(update.nestedUpdates);
        }
      });
    }

    // Update the action items in the store after sorting
    const newItems = actionItems.map((ai) =>
      ai.id === actionItem.id ? sortedActionItem : ai
    );
    dispatch(setActionItems(newItems));

    // Reset to original order if 'clear_filter' is selected
    if (option === 'clear_filter') {
      dispatch(setActionItems(originalOrder));
    }
  };
  const actionItemsFilters = (headers) => {
    return headers.map((f) => {
      const isSearchable =
        f === 'CATEGORY' ||
        f === 'DESCRIPTION' ||
        f === 'ITEM_NO' ||
        f === 'PRIORITY';
      if (f === 'ACTIONS') return f;
      return (
        <FilterHeader
          clearable
          search={isSearchable}
          label={f}
          options={isSearchable ? textFilterOptions : dateFilterOptions}
          className="simple-header"
          onFilter={(option) => handleFilterChange(option, f)}
          selected={filters[f]}
        />
      );
    });
  };

  const parseResolvedBy = (row) => {
    if (row.resolvedBy) return row.resolvedBy.fullName;
    return '--';
  };
  const getRow = (row, isNestedUpdate = false) => {
    // Add an indent or marker to the description for nested updates
    const createdOn = isNestedUpdate
      ? `    *${parseDateShort(row.dueDate)}`
      : parseDate(row.dueDate);
    const newItemNo =
      row.stringifiedActionNo || instanceForm.meetingsActionItems.length + 1;
    const newInstanceFormNumber = form.meetingsInstances.length + 1;
    const instanceFormNumber =
      instanceForm.instance_number || newInstanceFormNumber;
    const newItemNumber =
      (instanceForm.formattedNumber ||
        Meeting.numberFormatting(instanceFormNumber)) +
      '.' +
      Meeting.numberFormatting(newItemNo);
    return [
      newItemNumber,
      createdOn,
      row.description,
      row.category,
      parseDate(row.dueDate),
      row.priority,
      parseResolvedBy(row),
      parseDate(row.resolvedOn),
    ];
  };

  const getRows = () => {
    // Start with the action item itself
    const rows = [getRow(actionItem)];
    const hasUpdates =
      actionItem.actionItemsUpdates && actionItem.actionItemsUpdates.length > 0;
    const hasNestedUpdates =
      actionItem.actionItemsUpdates &&
      actionItem.actionItemsUpdates.some(
        (u) => u.nestedUpdates && u.nestedUpdates.length > 0
      );

    if (!hasUpdates) return rows;
    actionItem.actionItemsUpdates.forEach((update) => {
      rows.push(getRow(update));

      // Add rows for each nestedUpdate
      if (hasNestedUpdates)
        update.nestedUpdates.forEach((nestedUpdate) => {
          rows.push(getRow(nestedUpdate, true));
        });
    });

    return rows;
  };

  if (!show) return null;

  const titleComponent = <div className="search-bar-title">Item History</div>;

  return (
    <FormModal
      className={`action-items-resolved-modal`}
      show={show}
      onClose={handleClose}
      subTitle={'Meeting Minutes'}
      title={titleComponent}
    >
      <SimpleTable
        className="action-item-resolved-table"
        emptyMessage={`No Resolved Items`}
        headers={actionItemsFilters(actionItemsHeaders)}
        actionCells={1}
        rows={getRows()}
        stickyHeader={true}
      />
    </FormModal>
  );
};

export default connect((state) => ({
  show: getActionItemHistoryModal(state),
  currentUser: getCurrentUser(state),
  project: getCurrentProject(state),
  actionItems: getActionItems(state),
  instanceForm: getMeetingsInstanceForm(state),
  form: getProjectNewMeetingsForm(state),
}))(ActionItemHistoryModal);
