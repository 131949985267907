import { combineReducers } from 'redux';
import { createSceneReducer } from '../../reducers-util';
import createQuoteUiReducer from './details/create-quote.ui';
import denyQuoteUiReducer from './details/deny-quote.ui';
import proposalUiReducer from './details/proposal.ui';
import proposalDataReducer from './details/proposal.data';
import declineProposalUiReducer from './details/decline-proposal.ui';
import detailsDataReducer from './details/details.data';
import detailsUiReducer from './details/details.ui';
import quotesRequestDataReducer from './details/quotes-request.data';
import quotesRequestUiReducer from './details/quotes-request.ui';
import proposalApprovalDataReducer from './details/proposal-approval.data';
import proposalApprovalUiReducer from './details/proposal-approval.ui';
import buildingsDataReducer from './creation/buildings.data';
import subContractorsUnionTradesUiReducer from './creation/sub-contractors-union-trades.ui';
import tasksUiReducer from './creation/tasks.ui';
import buildingsUiReducer from './creation/buildings.ui';
import tasksDataReducer from './creation/tasks.data';
import workOrderSummaryUiReducer from './creation/work-order-summary.ui';
import workingHoursDataReducer from './creation/working-hours.data';
import workingHoursUiReducer from './creation/working-hours.ui';
import subContractorsUnionTradesDataReducer from './creation/sub-contractors-union-trades.data';
import workOrdersDataReducer from './work-orders.data';
import workOrdersUiReducer from './work-orders.ui';
import createQuoteDataReducer from './details/create-quote.data';
import creationUiReducer from './creation/creation.ui';
import membersDataReducer from './details/members.data';
import membersUiReducer from './details/members.ui';
import openItemsDataReducer from './details/open-items.data';
import openItemsUiReducer from './details/open-items.ui';
import clientsDataReducer from './creation/clients.data';
import clientsUiReducer from './creation/clients.ui';
import qualificationsDataReducer from './details/qualifications.data';
import qualificationsUiReducer from './details/qualifications.ui';
import newTaskDataReducer from 'reducers/admin/work-orders/details/new-task.data';
import newTaskUiReducer from 'reducers/admin/work-orders/details/new-task.ui';
import budgetUiReducer from 'reducers/admin/work-orders/details/budget.ui';
import purchaseOrderUiReducer from 'reducers/admin/work-orders/details/purchase-order.ui';
import budgetDataReducer from 'reducers/admin/work-orders/details/budget.data';
import timeDataReducer from 'components/admin/work-orders/details/time/store/reducers.data';
import timeUiReducer from 'components/admin/work-orders/details/time/store/reducers.ui';
import vendorInvoicesDataReducer from 'components/admin/work-orders/details/vendor-invoices/store/reducers.data';
import vendorInvoicesUiReducer from 'components/admin/work-orders/details/vendor-invoices/store/reducers.ui';
import transferWorkOrderUiReducer from 'components/admin/work-orders/details/transfer/store/reducers.ui';
import receiptsDataReducer from 'components/admin/work-orders/details/receipts/store/reducers.data';
import receiptsUiReducer from 'components/admin/work-orders/details/receipts/store/reducers.ui';
import anticipatedCostReportDataReducer from 'components/admin/work-orders/details/anticipated-cost-report/store/reducers.data';
import anticipatedCostReportUiReducer from 'components/admin/work-orders/details/anticipated-cost-report/store/reducers.ui';
import changeOrderTasksDataReducer from 'components/admin/work-orders/details/change-order-tasks/store/reducers.data';
import changeOrderTasksUiReducer from 'components/admin/work-orders/details/change-order-tasks/store/reducers.ui';
import changeOrderQuotesDataReducer from 'components/admin/work-orders/details/change-order-quotes/store/reducers.data';
import changeOrderQuotesUiReducer from 'components/admin/work-orders/details/change-order-quotes/store/reducers.ui';
import changeOrdersDataReducer from 'components/admin/work-orders/details/change-orders/store/reducers.data';
import changeOrdersUiReducer from 'components/admin/work-orders/details/change-orders/store/reducers.ui';

export default createSceneReducer(
  workOrdersDataReducer,
  workOrdersUiReducer,
  combineReducers({
    creation: createSceneReducer(
      null,
      creationUiReducer,
      combineReducers({
        buildings: createSceneReducer(buildingsDataReducer, buildingsUiReducer),
        clients: createSceneReducer(clientsDataReducer, clientsUiReducer),
        subContractorsUnionTrades: createSceneReducer(
          subContractorsUnionTradesDataReducer,
          subContractorsUnionTradesUiReducer
        ),
        summary: createSceneReducer(null, workOrderSummaryUiReducer),
        tasks: createSceneReducer(tasksDataReducer, tasksUiReducer),
        workingHours: createSceneReducer(
          workingHoursDataReducer,
          workingHoursUiReducer
        ),
      })
    ),
    details: createSceneReducer(
      detailsDataReducer,
      detailsUiReducer,
      combineReducers({
        budget: createSceneReducer(budgetDataReducer, budgetUiReducer),
        createQuote: createSceneReducer(
          createQuoteDataReducer,
          createQuoteUiReducer
        ),
        denyQuote: createSceneReducer(null, denyQuoteUiReducer),
        members: createSceneReducer(membersDataReducer, membersUiReducer),
        newTask: createSceneReducer(newTaskDataReducer, newTaskUiReducer),
        openItems: createSceneReducer(openItemsDataReducer, openItemsUiReducer),
        proposal: createSceneReducer(proposalDataReducer, proposalUiReducer),
        proposalApproval: createSceneReducer(
          proposalApprovalDataReducer,
          proposalApprovalUiReducer
        ),
        declineProposal: createSceneReducer(null, declineProposalUiReducer),
        purchaseOrder: createSceneReducer(null, purchaseOrderUiReducer),
        qualifications: createSceneReducer(
          qualificationsDataReducer,
          qualificationsUiReducer
        ),
        quotesRequest: createSceneReducer(
          quotesRequestDataReducer,
          quotesRequestUiReducer
        ),
        receipts: createSceneReducer(receiptsDataReducer, receiptsUiReducer),
        time: createSceneReducer(timeDataReducer, timeUiReducer),
        transfer: createSceneReducer(null, transferWorkOrderUiReducer),
        vendorInvoices: createSceneReducer(
          vendorInvoicesDataReducer,
          vendorInvoicesUiReducer
        ),
        anticipatedCostReport: createSceneReducer(
          anticipatedCostReportDataReducer,
          anticipatedCostReportUiReducer
        ),
        changeOrderTasks: createSceneReducer(
          changeOrderTasksDataReducer,
          changeOrderTasksUiReducer
        ),
        changeOrderQuotes: createSceneReducer(
          changeOrderQuotesDataReducer,
          changeOrderQuotesUiReducer
        ),
        changeOrders: createSceneReducer(
          changeOrdersDataReducer,
          changeOrdersUiReducer
        ),
      })
    ),
  })
);
