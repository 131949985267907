/* eslint-disable default-case */
import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';
import { DueDateFilterUtil } from 'services/utils/due-date-filter-util';

class RfisApi extends BaseApi {
  getRfis(projectId, page, status, dueDateFilter) {
    const dueDateQuery = DueDateFilterUtil.getDueDateQuery(dueDateFilter);

    return axios
      .get(
        `${
          this.baseUrl
        }/projects/${projectId}/rfis?page=${page}&${status}=true${
          dueDateQuery ? `&${dueDateQuery}` : ''
        }`
      )
      .then((response) => {
        return {
          ok: true,
          rfis: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getRfisAll(page, filters) {
    let query = `page=${page}`;
    Object.keys(filters).forEach((filter) => {
      query += `&${filter}=${filters[filter]}`;
    });

    return axios
      .get(`${this.baseUrl}/rfis?${query}`)
      .then((response) => {
        return {
          ok: true,
          rfis: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getRfisAllCount(filters) {
    let query = '';
    Object.keys(filters).forEach((filter, index) => {
      if (index > 0) {
        query += '&';
      }
      query += `${filter}=${filters[filter]}`;
    });

    return axios
      .get(`${this.baseUrl}/rfis/count?${query}`)
      .then((response) => {
        return {
          ok: true,
          count: numeral(response.headers['total']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getRfi(projectId, rfiId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/rfis/${rfiId}`)
      .then((response) => {
        return {
          ok: true,
          rfi: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getRfiLogPdf(projectId, status) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/rfi_log/print?${status}=true`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        return {
          ok: true,
          rfiLogPdf: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createRfi(projectId, values) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = this.rfiParams(values);

    return axios
      .post(`${this.baseUrl}/projects/${projectId}/rfis`, data, headers)
      .then((response) => {
        return {
          ok: true,
          rfi: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateRfi(projectId, rfiId, values) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = this.rfiParams(values);

    return axios
      .put(`${this.baseUrl}/projects/${projectId}/rfis/${rfiId}`, data, headers)
      .then((response) => {
        return {
          ok: true,
          rfi: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  respondRfi(projectId, rfiId, values) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = this.respondRfiParams(values);

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/rfis/${rfiId}/respond`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          rfi: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  deleteRfi(projectId, rfiId) {
    return axios
      .delete(`${this.baseUrl}/projects/${projectId}/rfis/${rfiId}`)
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  rfiParams(values) {
    let questionsIndex = 0;
    let apiPayload = new FormData();
    apiPayload.append('rfi[csi_code_id]', values.csiCodeId);
    apiPayload.append('rfi[due_date]', values.dueDate);
    apiPayload.append('rfi[extended_due_date]', values.extendedDueDate);
    apiPayload.append('rfi[status]', values.status);
    apiPayload.append('rfi[title]', values.title);

    if (values.contractorId) {
      apiPayload.append('rfi[contractor_id]', values.contractorId);
    }

    values.relCsiCodeIds.forEach((csiCodeId) => {
      apiPayload.append('rfi[rel_csi_code_ids][]', csiCodeId);
    });

    values.recipientIds.forEach((rcptId) => {
      apiPayload.append('rfi[recipient_ids][]', rcptId);
    });

    values.questions.forEach((qus) => {
      let reqImgsIndex = 0;
      let reqDocsIndex = 0;
      apiPayload.append(
        `rfi[rfi_questions_attributes][${questionsIndex}][number]`,
        qus.number
      );
      apiPayload.append(
        `rfi[rfi_questions_attributes][${questionsIndex}][assignee_id]`,
        qus.assigneeId
      );
      apiPayload.append(
        `rfi[rfi_questions_attributes][${questionsIndex}][action]`,
        qus.action
      );
      apiPayload.append(
        `rfi[rfi_questions_attributes][${questionsIndex}][question]`,
        qus.question
      );
      apiPayload.append(
        `rfi[rfi_questions_attributes][${questionsIndex}][resolved]`,
        qus.resolved
      );

      if (qus.id) {
        apiPayload.append(
          `rfi[rfi_questions_attributes][${questionsIndex}][id]`,
          qus.id
        );
      }

      qus.addlAssignees.forEach((addlAssignee, index) => {
        if (addlAssignee.id) {
          apiPayload.append(
            `rfi[rfi_questions_attributes][${questionsIndex}][rfi_addl_assignees_attributes][${index}][id]`,
            addlAssignee.id
          );
        }
        if (addlAssignee.destroy) {
          apiPayload.append(
            `rfi[rfi_questions_attributes][${questionsIndex}][rfi_addl_assignees_attributes][${index}][_destroy]`,
            true
          );
        } else {
          apiPayload.append(
            `rfi[rfi_questions_attributes][${questionsIndex}][rfi_addl_assignees_attributes][${index}][assignee_id]`,
            addlAssignee.assigneeId
          );
          apiPayload.append(
            `rfi[rfi_questions_attributes][${questionsIndex}][rfi_addl_assignees_attributes][${index}][due_date]`,
            addlAssignee.dueDate
          );
        }
      });

      qus.reqImages
        .filter((img) => img.file)
        .forEach((img) => {
          let file = img.file;
          apiPayload.append(
            `rfi[rfi_questions_attributes][${questionsIndex}][request_images_attributes][${reqImgsIndex}][image]`,
            file,
            file.name
          );
          reqImgsIndex++;
        });

      qus.reqDocs
        .filter((doc) => doc.file)
        .forEach((doc) => {
          let file = doc.file;
          apiPayload.append(
            `rfi[rfi_questions_attributes][${questionsIndex}][request_documents_attributes][${reqDocsIndex}][document]`,
            file,
            file.name
          );
          reqDocsIndex++;
        });

      qus.reqImagesDelete
        .filter((img) => img.id)
        .forEach((img) => {
          apiPayload.append(
            `rfi[rfi_questions_attributes][${questionsIndex}][request_images_attributes][${reqImgsIndex}][id]`,
            img.id
          );
          apiPayload.append(
            `rfi[rfi_questions_attributes][${questionsIndex}][request_images_attributes][${reqImgsIndex}][_destroy]`,
            true
          );
          reqImgsIndex++;
        });

      qus.reqDocsDelete
        .filter((doc) => doc.id)
        .forEach((doc) => {
          apiPayload.append(
            `rfi[rfi_questions_attributes][${questionsIndex}][request_documents_attributes][${reqDocsIndex}][id]`,
            doc.id
          );
          apiPayload.append(
            `rfi[rfi_questions_attributes][${questionsIndex}][request_documents_attributes][${reqDocsIndex}][_destroy]`,
            true
          );
          reqDocsIndex++;
        });

      questionsIndex++;
    });

    values.questionsDeleted &&
      values.questionsDeleted
        .filter((qus) => qus.id)
        .forEach((qus) => {
          apiPayload.append(
            `rfi[rfi_questions_attributes][${questionsIndex}][id]`,
            qus.id
          );
          apiPayload.append(
            `rfi[rfi_questions_attributes][${questionsIndex}][_destroy]`,
            true
          );

          questionsIndex++;
        });

    return apiPayload;
  }

  respondRfiParams(values) {
    let questionsIndex = 0;
    let apiPayload = new FormData();
    apiPayload.append('draft', values.draft);

    values.questions
      .filter((qus) => qus.id)
      .forEach((qus) => {
        let resImgsIndex = 0;
        let resDocsIndex = 0;
        apiPayload.append(
          `rfi[rfi_questions_attributes][${questionsIndex}][id]`,
          qus.id
        );
        apiPayload.append(
          `rfi[rfi_questions_attributes][${questionsIndex}][response]`,
          qus.response || ''
        );
        apiPayload.append(
          `rfi[rfi_questions_attributes][${questionsIndex}][draft_response]`,
          qus.draftResponse
        );

        qus.resImages
          .filter((img) => img.file)
          .forEach((img) => {
            let file = img.file;
            apiPayload.append(
              `rfi[rfi_questions_attributes][${questionsIndex}][response_images_attributes][${resImgsIndex}][image]`,
              file,
              file.name
            );
            resImgsIndex++;
          });

        qus.resDocs
          .filter((doc) => doc.file)
          .forEach((doc) => {
            let file = doc.file;
            apiPayload.append(
              `rfi[rfi_questions_attributes][${questionsIndex}][response_documents_attributes][${resDocsIndex}][document]`,
              file,
              file.name
            );
            resDocsIndex++;
          });

        qus.resImagesDelete
          .filter((img) => img.id)
          .forEach((img) => {
            apiPayload.append(
              `rfi[rfi_questions_attributes][${questionsIndex}][response_images_attributes][${resImgsIndex}][id]`,
              img.id
            );
            apiPayload.append(
              `rfi[rfi_questions_attributes][${questionsIndex}][response_images_attributes][${resImgsIndex}][_destroy]`,
              true
            );
            resImgsIndex++;
          });

        qus.resDocsDelete
          .filter((doc) => doc.id)
          .forEach((doc) => {
            apiPayload.append(
              `rfi[rfi_questions_attributes][${questionsIndex}][response_documents_attributes][${resDocsIndex}][id]`,
              doc.id
            );
            apiPayload.append(
              `rfi[rfi_questions_attributes][${questionsIndex}][response_documents_attributes][${resDocsIndex}][_destroy]`,
              true
            );
            resDocsIndex++;
          });

        questionsIndex++;
      });

    return apiPayload;
  }
}

export const rfisApi = new RfisApi();
