import React, { Component } from 'react';
import { connect } from 'react-redux';

import './Header.css';
import logoSvg from './logo.svg';
import sunSvg from './sun.svg';
import moonSvg from './moon.svg';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

import {
  getCurrentUser,
  getAccounts,
  isLoggedIn,
} from '../../selectors/authentication';
import { getDarkMode } from '../../selectors/theme';
import { getActiveRoute } from 'selectors/admin/sidebar/sidebar';
import UserHeader from './UserHeader';
import HeaderNav from './HeaderNav';
import {
  switchAccount,
  clearCurrentUser,
  logoutRequest,
} from '../../actions/authentication';
import { setBrightnessMode } from '../../actions/theme';
import NotificationsContainer from './NotificationsContainer';

const DarkModeSwitch = styled(Switch)(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,
  display: 'flex',
  marginRight: 24,
  '& .MuiSwitch-track': {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 100,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  '& .MuiSwitch-thumb': {
    width: 16,
    height: 16,
    margin: 4,
    boxShadow: 'none',
    background: '#191B24',
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    color: theme.palette.grey[500],
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      color: theme.palette.common.white,
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#303441',
        borderColor: '#303441',
      },
    },
  },
}));

class Header extends Component {
  handleAccountClick = (acc) => {
    this.props.dispatch(switchAccount(acc));
  };

  handleAddAccount = () => {
    this.props.dispatch(clearCurrentUser());
  };

  handleLogout = () => {
    this.props.dispatch(logoutRequest());
  };

  switchThumb() {
    return (
      <div className="switch-icon-container">
        {this.props.darkMode ? (
          <img src={moonSvg} className="switch-icon" alt="switch icon dark" />
        ) : (
          <img src={sunSvg} className="switch-icon" alt="switch icon" />
        )}
      </div>
    );
  }

  onModeChange = () => {
    const { darkMode, dispatch } = this.props;
    dispatch(setBrightnessMode(!darkMode));
  };

  setGlobalClass() {
    const { darkMode } = this.props;
    document.body.classList.toggle('dark-mode', darkMode);
  }

  render() {
    this.setGlobalClass();
    const isLogin = this.props.isLoggedIn;
    const withHeader = (
      <header className="header">
        <img src={logoSvg} className="logo" alt="360 Logo" />

        <React.Fragment>
          <div className="header-left-container">
            <HeaderNav />
          </div>
          <div className="header-right-container">
            <DarkModeSwitch
              checked={this.props.darkMode}
              icon={this.switchThumb()}
              checkedIcon={this.switchThumb()}
              onChange={this.onModeChange}
              inputProps={{ 'aria-label': 'DarkMode' }}
            />
            <NotificationsContainer />
            <UserHeader
              currentUser={this.props.currentUser}
              accounts={this.props.accounts || []}
              onAccountClick={this.handleAccountClick}
              onAddAccount={this.handleAddAccount}
              onLogout={this.handleLogout}
            />
          </div>
        </React.Fragment>
      </header>
    );
    return <div>{isLogin ? withHeader : null}</div>;
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    accounts: getAccounts(state),
    isLoggedIn: isLoggedIn(state),
    activeRoute: getActiveRoute(state),
    darkMode: getDarkMode(state),
  };
})(Header);
