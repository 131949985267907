import React, { Component } from 'react';
import UserInvitations from './UserInvitations';
import UserInvitationsSent from './UserInvitationsSent';
import ConfirmationModal from '../../shared/modal/ConfirmationModal';
import { connect } from 'react-redux';
import {
  userInvitationsRequest,
  userInvitationsStart,
  userRemoveRequest,
} from '../../../actions/client/user-invitations';
import {
  getUserInvitationsPermissions,
  getUserInvitationsUsers,
} from '../../../selectors/client/user-invitations';
import { getCurrentUser } from '../../../selectors/authentication';

export class UserInvitationsContainer extends Component {
  userIndexToRemove = null;
  userIdToRemove = null;

  constructor() {
    super();
    this.state = {
      invitationsSent: false,
      invitations: [],
      currentInvitation: {
        firstName: '',
        lastName: '',
        email: '',
        permissions: [],
        title: '',
      },
      usersInvited: [],
      showRemoveConfirmation: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(userInvitationsStart());
  }

  componentDidUpdate(prevProps) {
    // When permissions are loaded, initialize current invitation
    if (
      this.props.permissions.length !== prevProps.permissions.length &&
      this.props.permissions.length > 0
    ) {
      this.setState({
        currentInvitation: {
          firstName: '',
          lastName: '',
          email: '',
          permissions: UserInvitationsContainer._initPermissions(
            this.props.permissions
          ),
          title: '',
        },
      });
    }

    if (this.props.users.length !== prevProps.users.length) {
      // Populate users list with new users
      let users = this.props.users.map((user) => {
        let newElement = {
          firstName: user.first_name,
          lastName: user.last_name,
          email: user.email,
          id: user.id,
          permissions: UserInvitationsContainer._initPermissions(
            this.props.permissions
          ),
          title: user.title,
        };

        // For every role in the user, mark permissions selected
        newElement.permissions.forEach((permission) => {
          if (
            user.roles.find((element) => element.id === permission.id) !==
            undefined
          ) {
            permission.selected = true;
          }
        });

        return newElement;
      });

      this.setState({
        usersInvited: users,
      });

      if (this.props.users.length === 0) {
        this.setState({
          invitationsSent: true,
        });
      }
    }
  }

  static _initPermissions(permissions) {
    return permissions.map((element) => {
      return {
        id: element.id,
        label: element.label,
        selected: false,
      };
    });
  }

  handleCurrentInvitationChange = (invitation) => {
    this.setState({
      currentInvitation: invitation,
    });
  };

  handleAddInvitation = (newInvitation) => {
    let newInvitations = this.state.invitations.slice();
    newInvitations.push(newInvitation);
    this.setState({
      currentInvitation: {
        firstName: '',
        lastName: '',
        email: '',
        permissions: UserInvitationsContainer._initPermissions(
          this.props.permissions
        ),
        title: '',
      },
      invitations: newInvitations,
    });
  };

  handleSentInvitation = () => {
    let params = this.state.invitations.map((element) => {
      return {
        ...element,
        permissions: element.permissions
          .filter((element) => element.selected)
          .map((element) => {
            return { id: element.id };
          }),
      };
    });

    this.props.dispatch(userInvitationsRequest(this.props.currentUser, params));
  };

  handleRemoveInvitation = (id) => {
    this.userIndexToRemove = id;
    this.setState({
      showRemoveConfirmation: true,
    });
  };

  handleRemoveUser = (userId) => {
    this.userIdToRemove = userId;
    this.setState({
      showRemoveConfirmation: true,
    });
  };

  handleConfirmationModalHide = (confirmation) => {
    if (confirmation) {
      if (this.userIndexToRemove !== null) {
        this.setState({
          invitations: this.state.invitations.filter(
            (element, index) => index !== this.userIndexToRemove
          ),
        });
        this.userIndexToRemove = null;
      } else if (this.userIdToRemove !== null) {
        this.props.dispatch(userRemoveRequest(this.userIdToRemove));
        this.userIdToRemove = null;
      }
    }

    this.setState({
      showRemoveConfirmation: false,
    });
  };

  render() {
    return (
      <div style={{ height: '100%' }}>
        {!this.state.invitationsSent ? (
          <UserInvitations
            invitations={this.state.invitations.map((element, index) => {
              element.id = index;
              return element;
            })}
            currentInvitation={this.state.currentInvitation}
            onAdd={this.handleAddInvitation}
            onSent={this.handleSentInvitation}
            onRemove={this.handleRemoveInvitation}
            onCurrentInvitationChange={this.handleCurrentInvitationChange}
          />
        ) : (
          <UserInvitationsSent
            invitations={this.state.usersInvited}
            onRemove={this.handleRemoveUser}
          />
        )}

        <ConfirmationModal
          show={this.state.showRemoveConfirmation}
          message={'Are you sure you want to remove this item?'}
          onHide={this.handleConfirmationModalHide}
        />
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    permissions: getUserInvitationsPermissions(state),
    users: getUserInvitationsUsers(state),
  };
})(UserInvitationsContainer);
