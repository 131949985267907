import React from 'react';
import { connect } from 'react-redux';

/* import {
} from './store/actions'; */
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import AttendanceTable from './AttendanceTable';
import {
  getMeetingsInstanceForm,
  getProjectNewMeetingsForm,
  getActionItemsErrors,
  getInstanceInfoErrors,
} from './store/selectors';
import './MeetingsInstanceForm.css';

const MeetingsInstanceFormAttendance = (props) => {
  const {
    handleFormChange,
    instanceForm,
    actionItemsErrors,
    instanceInfoErrors,
  } = props;
  const errors = () => {
    const instanceInfoErrorsKeys = Object.keys(instanceInfoErrors);

    return (
      <div className="errors-container">
        {actionItemsErrors
          ? actionItemsErrors.map((e) => <div>{e}</div>)
          : null}
        {instanceInfoErrorsKeys.length > 0
          ? instanceInfoErrorsKeys.map((e) => (
              <div>{instanceInfoErrors[e]}</div>
            ))
          : null}
      </div>
    );
  };
  return (
    <React.Fragment>
      <div className="form-block-container">
        <AttendanceTable
          form={instanceForm}
          handleFormChange={handleFormChange}
          rows={{ data: instanceForm.attendees }}
        />
      </div>
      {errors()}
    </React.Fragment>
  );
};

export default connect((state) => ({
  currentProject: getCurrentProject(state),
  instanceForm: getMeetingsInstanceForm(state),
  meetingsParent: getProjectNewMeetingsForm(state),
  actionItemsErrors: getActionItemsErrors(state),
  instanceInfoErrors: getInstanceInfoErrors(state),
}))(MeetingsInstanceFormAttendance);
