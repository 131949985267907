import { useEffect, useState } from 'react';
import {
  INITIAL_ALBUM_FORM_TAB,
  PERMISSIONS_ALBUM_FORM_TAB,
} from 'domain/project-photo';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import {
  createProjectPhotoAlbum,
  saveProjectAlbum,
  setProjectPhotosFilters,
  setProjectPhotosForm,
  setProjectPhotosShowAlbumForm,
  setProjectPhotosStartUpload,
} from './store/actions';

const useProjectPhotosAlbumForm = (props) => {
  const {
    dispatch,
    show,
    currentProject,
    directory,
    form,
    startUpload,
  } = props;

  const [tab, setTab] = useState(INITIAL_ALBUM_FORM_TAB);

  const onClose = () => {
    dispatch(setProjectPhotosShowAlbumForm(false));
    if (startUpload) {
      dispatch(setProjectPhotosStartUpload(false));
      dispatch(
        setProjectPhotosFilters({
          albumId: form.ids[0].id,
          doSearch: true,
        })
      );
    }
  };

  const validate = () => {
    const errors = {};
    if (!form.title) errors.title = true;
    if (!form.permission) errors.permission = true;

    dispatch(setProjectPhotosForm({ errors }));
    const noError = Object.keys(errors).length === 0;

    if (errors.title) setTab(INITIAL_ALBUM_FORM_TAB);
    if (errors.permission) setTab(PERMISSIONS_ALBUM_FORM_TAB);

    return noError;
  };

  const save = () => {
    if (form.ids.length > 0) {
      return dispatch(saveProjectAlbum(currentProject.id, form));
    } else {
      return dispatch(createProjectPhotoAlbum(currentProject.id, form));
    }
  };

  const onSave = () => {
    if (validate()) save();
  };

  useEffect(() => {
    if (show) setTab(INITIAL_ALBUM_FORM_TAB);
    if (show && currentProject.id) {
      if (!directory || directory.project_id !== currentProject.id) {
        dispatch(loadDirectoryRequest(currentProject.id));
      }
    }
  }, [show]);

  const onFormChange = (f) => {
    dispatch(setProjectPhotosForm(f));
  };

  return { tab, onClose, onSave, setTab, onFormChange };
};

export default useProjectPhotosAlbumForm;
