import { createSelector } from 'reselect';

export const getCurrentProject = createSelector(
  (state) => state.projects.scenes.details.data.currentProject,
  (currentProject) => {
    return currentProject;
  }
);

export const getNavBarSelectedItem = createSelector(
  (state) => state.projects.scenes.details.ui.navBarSelectedItem,
  (navBarSelectedItem) => {
    return navBarSelectedItem;
  }
);

export const getShowPdfViewer = createSelector(
  (state) => state.projects.scenes.details.ui.showPdfViewer,
  (showPdfViewer) => {
    return showPdfViewer;
  }
);

export const getDirectUploadInProcess = createSelector(
  (state) => state.projects.scenes.details.ui.directUploadInProcess,
  (directUploadInProcess) => {
    return directUploadInProcess;
  }
);

export const getShowDashboard = createSelector(
  (state) => state.projects.scenes.details.ui.showDashboard,
  (showDashboard) => {
    return showDashboard;
  }
);
