import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';

class SurveysApi extends BaseApi {
  getSurvey(surveyId) {
    return axios
      .get(`${this.baseUrl}/surveys/${surveyId}`)
      .then((response) => {
        return {
          ok: true,
          survey: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getSurveys(page = 1, pending = null) {
    let url = `${this.baseUrl}/surveys?page=${page}`;
    if (pending !== null) {
      url += `&pending=${pending}`;
    }
    return axios
      .get(url)
      .then((response) => {
        return {
          ok: true,
          surveys: {
            content: response.data,
            total: numeral(response.headers['total']).value(),
            totalPending: numeral(response.headers['total-pending']).value(),
            pageSize: numeral(response.headers['per-page']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getClientSurveys(clientId, page = 1) {
    return axios
      .get(`${this.baseUrl}/clients/${clientId}/surveys?page=${page}`)
      .then((response) => {
        return {
          ok: true,
          surveys: {
            content: response.data,
            total: numeral(response.headers['total']).value(),
            pageSize: numeral(response.headers['per-page']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateSurvey(surveyId, values) {
    const apiPayload = {
      survey: {
        response_time_sat: values.response_time_sat,
        work_quality_sat: values.work_quality_sat,
        recommend_spackle: values.recommend_spackle,
      },
    };
    if (values.note !== null) {
      apiPayload.survey.note = values.note;
    }
    return axios
      .put(`${this.baseUrl}/surveys/${surveyId}`, apiPayload)
      .then((response) => {
        return {
          ok: true,
          survey: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export let surveysApi = new SurveysApi();
