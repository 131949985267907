import AuthApi from 'services/api/auth-api';
import { usersApi } from 'services/api/users-api';
import AuthenticationStorage from 'services/authentication-storage';
import { updateTheme } from 'actions/theme.js';
import ServiceWorkRedirectUtil from 'services/utils/service-work-redirect-util.js';
import { showErrorMessage } from 'actions/messages';

export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE';
export const AUTHENTICATION_LOGOUT = 'AUTHENTICATION_LOGOUT';
export const AUTHENTICATION_ACCOUNTS_SET = 'AUTHENTICATION_ACCOUNTS_SET';

export function authenticationRequest(email, password) {
  return (dispatch) => {
    return AuthApi.login(email, password).then((response) => {
      if (response.ok) {
        AuthenticationStorage.setCredentials(
          response.data.accessToken,
          response.data.client,
          response.data.user
        );
        dispatch(updateTheme(response.data.user.theme));
        dispatch(
          authenticationSuccess(
            response.data.accessToken,
            response.data.client,
            response.data.user
          )
        );

        const accounts = AuthenticationStorage.setAccounts(
          response.data.accessToken,
          response.data.client,
          response.data.user
        );
        dispatch(authenticationSetAccounts(accounts));
        ServiceWorkRedirectUtil.redirectUser(response.data.user, dispatch);
      } else {
        dispatch(authenticationError(response.errors));
      }

      return response;
    });
  };
}

export function authenticationSuccess(accessToken, client, user) {
  return {
    type: AUTHENTICATION_SUCCESS,
    payload: {
      accessToken,
      client,
      user,
    },
  };
}

export function updateUser(user) {
  const creds = AuthenticationStorage.getCredentials();
  const accounts = AuthenticationStorage.setAccounts(
    creds.accessToken,
    creds.client,
    user
  );
  AuthenticationStorage.setCredentials(creds.accessToken, creds.client, user);
  return (dispatch) => {
    dispatch(authenticationSetAccounts(accounts));
  };
}

export function reloadUser() {
  return (dispatch) => {
    return usersApi.getProfile().then((response) => {
      if (response.ok) {
        dispatch(updateUser(response.profile));
        window.location.reload();
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function authenticationError(errors) {
  return {
    type: AUTHENTICATION_FAILURE,
    payload: errors,
  };
}

/**
 *
 * @param keepCurrentRoute If false, redirect to home (/)
 * @return {function(*): (*|PromiseLike<T>|Promise<T>)}
 */
export function logoutRequest(keepCurrentRoute) {
  // Clear credentials from local storage
  AuthenticationStorage.clearCurrentAccount();
  AuthenticationStorage.clearCredentials();

  return (dispatch) => {
    return AuthApi.logout().then(() => {
      // Update state
      dispatch(updateTheme());
      dispatch(logoutFinish());
      if (!keepCurrentRoute) {
        window.location.reload();
      }
    });
  };
}

export function logoutFinish() {
  return {
    type: AUTHENTICATION_LOGOUT,
  };
}

export function authRefreshUserAccountable(accountable) {
  let creds = AuthenticationStorage.getCredentials();
  AuthenticationStorage.setCredentials(creds.accessToken, creds.client, {
    ...creds.user,
    ...{ accountable: accountable },
  });
}

export function authenticationSetAccounts(accounts) {
  return {
    type: AUTHENTICATION_ACCOUNTS_SET,
    payload: accounts,
  };
}

export function switchAccount(account) {
  AuthenticationStorage.setCredentials(
    account.accessToken,
    account.client,
    account.user
  );
  window.location.reload();
  return (dispatch) => {};
}

export function clearCurrentUser() {
  AuthenticationStorage.clearCredentials();
  window.location.reload();
  return (dispatch) => {};
}
