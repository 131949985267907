import {
  CONTRACTORS_INVOICES_INVOICE_REMOVE,
  CONTRACTORS_INVOICES_INVOICE_UPDATE,
  CONTRACTORS_INVOICES_LOAD_SUCCESS,
  CONTRACTORS_INVOICES_VENDOR_INVOICES_INVOICE_UPDATE,
  CONTRACTORS_INVOICES_VENDOR_INVOICES_SET,
} from 'components/admin/contractors-invoices/store/action-types';

const initialState = {
  invoices: [],
  vendorInvoices: [],
};

export default function contractorsInvoicesDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case CONTRACTORS_INVOICES_INVOICE_REMOVE:
      return {
        ...state,
        invoices: state.invoices.filter(
          (invoice) => invoice.id !== action.payload
        ),
      };

    case CONTRACTORS_INVOICES_LOAD_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
      };

    case CONTRACTORS_INVOICES_INVOICE_UPDATE:
      return {
        ...state,
        invoices: state.invoices.map((invoice) => {
          if (invoice.id === action.payload.id) {
            return action.payload;
          } else {
            return invoice;
          }
        }),
      };

    case CONTRACTORS_INVOICES_VENDOR_INVOICES_INVOICE_UPDATE:
      return {
        ...state,
        vendorInvoices: state.vendorInvoices.map((invoice) => {
          if (invoice.id === action.payload.id) {
            return action.payload;
          } else {
            return invoice;
          }
        }),
      };

    case CONTRACTORS_INVOICES_VENDOR_INVOICES_SET:
      return {
        ...state,
        vendorInvoices: action.payload,
      };

    default:
      return state;
  }
}
