import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  loadPresetTasksRequest,
  handleWorkedFrom,
  handleWorkedTo,
  handleAttributeChangeAction,
  handleHoursAmountAction,
  handleHoursAmountActionMoreAction,
  changeAmountOfTechnicians,
  handleAttributeChangeForSub,
  handleAttributeChangeForSubMoreAction,
  handleDateMoreInfo,
  addedDeletedTechnicians,
  setDailyReportAddFloor,
  setActiveEntriesId,
  addSubDailyReportEntriesFloor,
  loadPresetTasksSuccess,
  deleteSubDailyReportEntry,
  updateTradeForm,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { getSettings } from 'components/admin/projects/details/project-settings/store/selectors';
import {
  getActiveContributor,
  getContributors,
  getPresetTasks,
  getContributorsForm,
  getActiveEntryId,
  getCurrentDailyReport,
  getAddFloor,
} from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import ProjectOpenItemForm from 'components/admin/projects/details/project-daily-report-log/ProjectOpenItemForm';
import Input from 'components/shared/input/Input';
import SearchableDropDownPaginated from 'components/shared/dropdown/SearchableDropDownPaginated';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import FloorButton from 'components/shared/button/FloorButton';
import TimePicker from 'components/shared/time-picker/TimePicker';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import ImageUpload from 'components/shared/image-upload/ImageUpload';
import { imageExtensions } from 'services/utils/files-util';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  loadTechnicianRequest,
  ContactorTeamTabs,
} from 'actions/admin/team/contractor-team';
import { getTechnicians } from 'selectors/admin/team/contractor-team';
import { getCurrentUser } from 'selectors/authentication';
import SubTechniciansApi from 'services/api/sub-technicians-api';
import FieldWorkOrderForm from 'components/admin/projects/details/project-daily-report-log/FieldWorkOrderForm';
import { containerScrollToTop } from 'services/utils/scroller-util';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import User from 'domain/user';

const defaultInfo = {
  hoursAmount: '',
  workedFrom: '',
  workedTo: '',
  location: '',
  customTask: '',
  workPerformed: '',
  presetTasks: [],
};

const defaultTechnician = {
  ...defaultInfo,
  tech: { value: 1, label: 'Technician 1', custom: true },
  additionalInfo: [],
};

class SingleTradesFormForSubComponent extends Component {
  handleDate = (techId, value, key, infoIndex) => {
    const { dispatch, activeEntryId } = this.props;
    if (key === 'workedFrom') {
      if (infoIndex !== undefined) {
        dispatch(
          handleDateMoreInfo(value, activeEntryId, techId, key, infoIndex)
        );
      } else {
        dispatch(handleWorkedFrom(value, activeEntryId, techId));
      }
    } else {
      if (infoIndex !== undefined) {
        dispatch(
          handleDateMoreInfo(value, activeEntryId, techId, key, infoIndex)
        );
      } else {
        dispatch(handleWorkedTo(value, activeEntryId, techId));
      }
    }
  };

  handleAttributeChange = (attribute, value) => {
    const { dispatch, activeEntryId } = this.props;
    dispatch(handleAttributeChangeAction(attribute, value, activeEntryId));
  };

  handleHoursAmount = (techId, value, infoIndex) => {
    const { dispatch, activeEntryId } = this.props;
    if (infoIndex !== undefined) {
      dispatch(
        handleHoursAmountActionMoreAction(
          value,
          activeEntryId,
          techId,
          infoIndex
        )
      );
    } else {
      dispatch(handleHoursAmountAction(value, activeEntryId, techId));
    }
  };

  changeTechnicianAmount = (action, index = null) => {
    const { dispatch, activeEntryId } = this.props;

    const contribFormData = this.getContribFormData();
    if (contribFormData && contribFormData.technicians) {
      const newTechnicians = [...contribFormData.technicians];
      if (action === 'add') {
        const defaultTechnicianValue = {
          ...defaultTechnician,
          tech: {
            value: newTechnicians.length + 1,
            label: `Technician ${newTechnicians.length + 1}`,
            custom: true,
          },
        };
        newTechnicians.push(defaultTechnicianValue);
      } else {
        const deletedTech =
          index !== null
            ? newTechnicians.splice(index, 1)[0]
            : newTechnicians.pop();
        if (deletedTech.additionalInfo && deletedTech.additionalInfo.length) {
          const deletedTechnicians = [
            ...contribFormData.deletedTechnicians,
            ...deletedTech.additionalInfo,
          ];
          dispatch(
            handleAttributeChangeAction(
              'deletedTechnicians',
              deletedTechnicians,
              activeEntryId
            )
          );
        }
        if (deletedTech.id) {
          dispatch(addedDeletedTechnicians(deletedTech, activeEntryId));
        }
      }
      dispatch(changeAmountOfTechnicians(newTechnicians, activeEntryId));
    }
  };

  addTechnician = () => {
    this.changeTechnicianAmount('add');
  };

  removeTechnician = (index = null) => {
    this.changeTechnicianAmount('remove', index);
  };

  handleAddFloor = (drEntry = null) => {
    const { currentDailyReport, dispatch, activeEntryId } = this.props;
    const presetTasks = [...this.props.presetTasks];
    dispatch(setDailyReportAddFloor(true));
    if (drEntry) {
      dispatch(setActiveEntriesId(drEntry.id));
    } else {
      dispatch(
        addSubDailyReportEntriesFloor(activeEntryId, currentDailyReport)
      );
    }
    dispatch(loadPresetTasksSuccess(presetTasks));
    containerScrollToTop('daily-report-form-modal');
  };

  handleDeleteFloorClick = () => {
    const {
      dispatch,
      activeEntryId,
      currentDailyReport,
      contributorsForm,
    } = this.props;
    const currentEntry = currentDailyReport.sub_daily_report_entries.find(
      (val) => val.id === activeEntryId
    );
    const filteredForm = Object.entries(contributorsForm).filter(
      ([key, value]) => value.id !== activeEntryId
    );
    const newForm = {};
    filteredForm.forEach(([key, val]) => {
      newForm[key] = {
        workPerformed: val.workPerformed,
        floor: val.floor,
        area: val.area,
        comments: val.comments,
        presetTasks: val.presetTasks,
        customTask: val.customTask,
        images: val.images,
        technicians: val.technicians,
        deletedImages: val.deletedImages,
        deletedTechnicians: val.deletedTechnicians,
      };
    });
    dispatch(setActiveEntriesId(currentEntry['sub_daily_report_entry_id']));
    dispatch(deleteSubDailyReportEntry(activeEntryId, currentDailyReport));
    dispatch(updateTradeForm(newForm));
    dispatch(setDailyReportAddFloor(false));
  };

  buildAdditionalInfo(additionalInfo) {
    return additionalInfo && additionalInfo.length
      ? additionalInfo.map((info) => ({
          ...info,
          id: '',
          sub_technician_id: '',
          sub_technician: null,
        }))
      : [];
  }

  duplicateTechnician = () => {
    const contribFormData = this.getContribFormData();
    if (contribFormData && contribFormData.technicians) {
      const tech =
        contribFormData.technicians[contribFormData.technicians.length - 1];
      if (tech) {
        const newTechnicians = [...contribFormData.technicians];
        for (let i = 1; i < contribFormData.techCount; i++) {
          const techNumber = contribFormData.technicians.length + i;
          newTechnicians.push({
            ...tech,
            id: '',
            sub_technician_id: '',
            sub_technician: null,
            tech_number: techNumber,
            tech: {
              value: techNumber,
              label: `Technician ${techNumber}`,
              custom: true,
            },
            additionalInfo: this.buildAdditionalInfo(tech.additionalInfo),
          });
        }
        this.handleAttributeChange('duplicateTech', false);
        this.handleAttributeChange('technicians', newTechnicians);
      }
    }
  };

  isTheLastTechnician = () => {
    const contribFormData = this.getContribFormData();
    return (
      contribFormData &&
      contribFormData.technicians &&
      contribFormData.technicians.length === 1
    );
  };

  setImages = (attribute, files) => {
    let images = [...this.getImages()];
    files.forEach(function (val) {
      images.push({
        url: val.url || URL.createObjectURL(val),
        file: val,
      });
    });

    this.handleAttributeChange(attribute, images);
  };

  removeImages = (attribute, index) => {
    let images = [...this.getImages()];
    let deletedImages = [...this.getDeletedImages()];
    let deletedImage = images.splice(index, 1);
    deletedImages.push(deletedImage[0]);

    this.handleAttributeChange(attribute, images);
    this.handleAttributeChange('deletedImages', deletedImages);
  };

  getImages = () => {
    const contribFormData = this.getContribFormData();
    if (
      contribFormData &&
      contribFormData.images &&
      contribFormData.images.length > 0
    ) {
      return contribFormData.images;
    }
    return [];
  };

  getDeletedImages = () => {
    const contribFormData = this.getContribFormData();
    if (
      contribFormData &&
      contribFormData.deletedImages &&
      contribFormData.deletedImages.length > 0
    ) {
      return contribFormData.deletedImages;
    }
    return [];
  };

  loadPresetTasks = () => {
    const { dispatch, activeContributor, contributors, directory } = this.props;
    if (contributors.length > 0) {
      const contributor = directory.project_contributors.find((contrib) => {
        return contrib.id === contributors[activeContributor - 1][0];
      });
      dispatch(loadPresetTasksRequest(contributor.csi_code.id));
    }
  };

  getContribFormData = () => {
    const { contributorsForm, activeEntryId } = this.props;
    if (activeEntryId && contributorsForm[activeEntryId]) {
      return contributorsForm[activeEntryId];
    }
    return null;
  };

  isErrorValue = (index, key) => {
    const { errors } = this.props;
    return (
      errors.technicians &&
      errors.technicians[index] &&
      errors.technicians[index][key]
    );
  };

  isErrorValueForMoreInfo = (index, infoIndex, key) => {
    const { errors } = this.props;
    return (
      errors.technicians &&
      errors.technicians[index] &&
      errors.technicians[index].info &&
      errors.technicians[index].info[infoIndex] &&
      errors.technicians[index].info[infoIndex][key]
    );
  };

  validTechCount = () => {
    const contribFormData = this.getContribFormData();
    return (
      !!contribFormData &&
      !!contribFormData.techCount &&
      contribFormData.techCount > 0 &&
      contribFormData.techCount < 100
    );
  };

  componentDidMount() {
    const {
      currentUser,
      currentProject,
      submitFailedErrors,
      dispatch,
    } = this.props;

    const isSubcontractor = User.isContractor(currentUser, currentProject);
    if (isSubcontractor) {
      dispatch(loadTechnicianRequest(1, ContactorTeamTabs.Technicians.value));
    }
    this.loadPresetTasks();
    if (submitFailedErrors) {
      submitFailedErrors();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeEntryId !== this.props.activeEntryId) {
      this.loadPresetTasks();
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setDailyReportAddFloor(false));
  }

  handleChooseTechnician = (option, techId, attribute) => {
    const { dispatch, activeEntryId } = this.props;
    if (option) {
      dispatch(
        handleAttributeChangeForSub(
          option.custom ? null : option,
          activeEntryId,
          techId,
          attribute
        )
      );
    }
  };

  handleAttributeChangeForSub = (values, techId, attribute, infoIndex) => {
    const { dispatch, activeEntryId } = this.props;
    if (infoIndex !== undefined) {
      dispatch(
        handleAttributeChangeForSubMoreAction(
          values,
          activeEntryId,
          techId,
          attribute,
          infoIndex
        )
      );
    } else {
      dispatch(
        handleAttributeChangeForSub(values, activeEntryId, techId, attribute)
      );
    }
  };

  addMoreInfo = (index, infoIndex) => {
    const { dispatch, activeEntryId } = this.props;
    const contribFormData = this.getContribFormData();
    if (contribFormData && contribFormData.technicians) {
      const newTechnicians = [...contribFormData.technicians];
      if (infoIndex !== undefined) {
        const deletedInfo = newTechnicians[index].additionalInfo.splice(
          infoIndex,
          1
        );
        if (deletedInfo[0] && deletedInfo[0].id) {
          dispatch(addedDeletedTechnicians(deletedInfo[0], activeEntryId));
        }
      } else {
        if (!newTechnicians[index].additionalInfo) {
          newTechnicians[index].additionalInfo = [defaultInfo];
        } else {
          newTechnicians[index].additionalInfo = [
            ...newTechnicians[index].additionalInfo,
            defaultInfo,
          ];
        }
      }
      dispatch(changeAmountOfTechnicians(newTechnicians, activeEntryId));
    }
  };

  loadTechnicians = (input, page, customTech) => {
    const options = page === 1 ? [customTech] : [];
    return SubTechniciansApi.getTechnicians(page, input).then((response) => {
      if (response.ok) {
        let resp = {
          ok: true,
          options: [
            ...options,
            ...response.technicians.map((element) => {
              return {
                value: element.id,
                label: `${element.first_name} ${element.last_name || ''}`,
              };
            }),
          ],
          hasMore: true,
        };
        return resp;
      }
    });
  };

  render() {
    const {
      activeContributor,
      errors,
      presetTasks,
      currentDailyReport,
      activeEntryId,
      addFloor,
      settings,
    } = this.props;
    const contribFormData = this.getContribFormData();
    let presetTaskList = [];
    if (presetTasks && presetTasks.length > 0) {
      presetTaskList = presetTasks.map((task) => {
        return { id: task.id, label: task.label };
      });
    }
    const currentEntry = currentDailyReport.sub_daily_report_entries.find(
      (val) => val.id === activeEntryId
    );
    const childFloors = currentDailyReport.sub_daily_report_entries.filter(
      (val) => val.sub_daily_report_entry_id === activeEntryId
    );
    const isChildrenFloor =
      currentEntry && currentEntry.sub_daily_report_entry_id;

    return (
      <React.Fragment>
        {contribFormData ? (
          <div className="single-trade-form-wrapper">
            {settings && settings.multiple_floors && (
              <div className="form-block">
                <FormControlBlock
                  label={!!contribFormData.floor && 'Floor'}
                  control={
                    <Input
                      type="number"
                      placeholder="Floor"
                      onChange={this.handleAttributeChange.bind(null, 'floor')}
                      value={contribFormData.floor || ''}
                      valid={!errors.floor}
                    />
                  }
                />
                <FormControlBlock
                  label={!!contribFormData.area && 'Area'}
                  control={
                    <Input
                      placeholder="Area"
                      onChange={this.handleAttributeChange.bind(null, 'area')}
                      value={contribFormData.area || ''}
                    />
                  }
                />
              </div>
            )}

            <DetailsTitle
              className="modal-form-title"
              title="Technicians"
              rightButtons={[
                <div
                  key="duplicate-add"
                  className="form-block multicontrols-block"
                >
                  <LabeledCheckBox
                    label="Duplicate Tech"
                    position="left"
                    onChange={this.handleAttributeChange.bind(
                      null,
                      'duplicateTech'
                    )}
                    checked={contribFormData.duplicateTech}
                  />
                  {!contribFormData.duplicateTech ? (
                    <Button
                      color={BUTTON_COLORS.GREEN}
                      label="Add Technician"
                      onClick={this.addTechnician}
                    />
                  ) : (
                    <React.Fragment>
                      <FormControlBlock
                        hideBottomLabel
                        label={!!contribFormData.techCount && 'How Many'}
                        control={
                          <Input
                            className="tech-count"
                            placeholder="How Many"
                            type="number"
                            onChange={this.handleAttributeChange.bind(
                              null,
                              'techCount'
                            )}
                            value={contribFormData.techCount}
                          />
                        }
                      />
                      <Button
                        color={BUTTON_COLORS.GREEN}
                        disabled={!this.validTechCount()}
                        label="Duplicate"
                        onClick={this.duplicateTechnician}
                      />
                    </React.Fragment>
                  )}
                </div>,
              ]}
            />
            {contribFormData.technicians &&
              contribFormData.technicians.length &&
              contribFormData.technicians.map((technician, index) => {
                return (
                  <React.Fragment key={`${activeContributor}${index}`}>
                    <DetailsTitle
                      className="modal-form-subtitle"
                      title={`Technician ${index + 1}`}
                      rightButtons={
                        contribFormData.technicians.length > 1
                          ? [
                              <Button
                                key="remove-tech"
                                type={BUTTON_TYPES.LINK}
                                icon={BUTTON_ICONS.DELETE}
                                onClick={() => this.removeTechnician(index)}
                              />,
                            ]
                          : null
                      }
                    />
                    <div className="form-block multicontrols-block">
                      <FormControlBlock
                        hideBottomLabel
                        className="daily-report-sub-technician-user"
                        label={
                          (technician.sub_technician || technician.tech) &&
                          'Technician'
                        }
                        control={
                          <SearchableDropDownPaginated
                            placeholder="Technician"
                            valid={true}
                            defaultArrow={true}
                            withCreate={true}
                            value={technician.sub_technician || technician.tech}
                            loadOptions={(input, page) =>
                              this.loadTechnicians(input, page, technician.tech)
                            }
                            onChange={(value) =>
                              this.handleChooseTechnician(
                                value,
                                index,
                                'sub_technician'
                              )
                            }
                          />
                        }
                      />
                      <FormControlBlock
                        hideBottomLabel
                        label={!!technician.hoursAmount && 'Worked Hours'}
                        control={
                          <Input
                            type="number"
                            onChange={(value) =>
                              this.handleHoursAmount(index, value)
                            }
                            value={technician.hoursAmount}
                            placeholder="Worked Hours"
                            valid={!this.isErrorValue(index, 'hoursAmount')}
                          />
                        }
                      />
                      <span className="form-block-text">Or</span>
                      <FormControlBlock
                        hideBottomLabel
                        label={!!technician.workedFrom && 'Worked From'}
                        control={
                          <TimePicker
                            placeholder="Worked From"
                            onChange={(value) =>
                              this.handleDate(index, value, 'workedFrom')
                            }
                            value={technician.workedFrom}
                            valid={!this.isErrorValue(index, 'workedFrom')}
                          />
                        }
                      />
                      <FormControlBlock
                        hideBottomLabel
                        label={!!technician.workedTo && 'To'}
                        control={
                          <TimePicker
                            placeholder="To"
                            onChange={(value) =>
                              this.handleDate(index, value, 'workedTo')
                            }
                            value={technician.workedTo}
                            valid={!this.isErrorValue(index, 'workedTo')}
                          />
                        }
                      />
                    </div>
                    <div className="form-block">
                      <FormControlBlock
                        label={
                          (technician.presetTasks || []).length > 0 &&
                          'Preset Tasks'
                        }
                        control={
                          <MultipleDropdown
                            dropdownClassName="alternate-dropdown-control"
                            options={presetTaskList}
                            currentValues={technician.presetTasks || []}
                            placeholder="Preset Tasks"
                            onChange={(values) =>
                              this.handleAttributeChangeForSub(
                                values,
                                index,
                                'presetTasks'
                              )
                            }
                            valid={!this.isErrorValue(index, 'presetTasks')}
                          />
                        }
                      />
                    </div>
                    <div className="form-block">
                      <FormControlBlock
                        label={!!technician.customTask && 'Custom Task'}
                        control={
                          <Input
                            placeholder="Custom Task"
                            onChange={(value) =>
                              this.handleAttributeChangeForSub(
                                value,
                                index,
                                'customTask'
                              )
                            }
                            value={technician.customTask}
                            valid={!this.isErrorValue(index, 'customTask')}
                          />
                        }
                      />
                    </div>
                    <div className="form-block">
                      <FormControlBlock
                        label={!!technician.workPerformed && 'Description'}
                        control={
                          <Input
                            placeholder="Description"
                            onChange={(value) =>
                              this.handleAttributeChangeForSub(
                                value,
                                index,
                                'workPerformed'
                              )
                            }
                            value={technician.workPerformed}
                          />
                        }
                      />
                    </div>
                    {settings && !settings.multiple_floors && (
                      <div className="form-block">
                        <FormControlBlock
                          label={!!technician.location && 'Location'}
                          control={
                            <Input
                              placeholder="Location"
                              onChange={(value) =>
                                this.handleAttributeChangeForSub(
                                  value,
                                  index,
                                  'location'
                                )
                              }
                              value={technician.location}
                            />
                          }
                        />
                      </div>
                    )}

                    {technician.location && (
                      <DetailsTitle
                        className="modal-form-subtitle"
                        title={`Technician ${index + 1} Additonal Locations`}
                        rightButtons={[
                          <Button
                            key="add-location"
                            type={BUTTON_TYPES.LINK}
                            icon={BUTTON_ICONS.PLUS}
                            label="Add Location"
                            onClick={() => this.addMoreInfo(index)}
                          />,
                        ]}
                      />
                    )}
                    {technician.additionalInfo &&
                      technician.additionalInfo.map((info, infoIndex) => {
                        return (
                          <React.Fragment
                            key={`${activeContributor}${index}${infoIndex}`}
                          >
                            <div className="form-block multicontrols-block right-block">
                              <span className="form-block-title">
                                Additional Location {infoIndex + 1}
                              </span>
                              <FormControlBlock
                                label={!!info.hoursAmount && 'Worked Hours'}
                                control={
                                  <Input
                                    type="number"
                                    onChange={(value) =>
                                      this.handleHoursAmount(
                                        index,
                                        value,
                                        infoIndex
                                      )
                                    }
                                    value={info.hoursAmount}
                                    placeholder="Worked Hours"
                                    valid={
                                      !this.isErrorValueForMoreInfo(
                                        index,
                                        infoIndex,
                                        'hoursAmount'
                                      )
                                    }
                                  />
                                }
                              />
                              <span className="form-block-text">Or</span>
                              <FormControlBlock
                                label={!!info.workedFrom && 'Worked From'}
                                control={
                                  <TimePicker
                                    placeholder="Worked From"
                                    onChange={(value) =>
                                      this.handleDate(
                                        index,
                                        value,
                                        'workedFrom',
                                        infoIndex
                                      )
                                    }
                                    value={info.workedFrom}
                                    valid={
                                      !this.isErrorValueForMoreInfo(
                                        index,
                                        infoIndex,
                                        'workedFrom'
                                      )
                                    }
                                  />
                                }
                              />
                              <FormControlBlock
                                label={!!info.workedTo && 'To'}
                                control={
                                  <TimePicker
                                    placeholder="To"
                                    onChange={(value) =>
                                      this.handleDate(
                                        index,
                                        value,
                                        'workedTo',
                                        infoIndex
                                      )
                                    }
                                    value={info.workedTo}
                                    valid={
                                      !this.isErrorValueForMoreInfo(
                                        index,
                                        infoIndex,
                                        'workedTo'
                                      )
                                    }
                                  />
                                }
                              />
                              <Button
                                type={BUTTON_TYPES.LINK}
                                icon={BUTTON_ICONS.DELETE}
                                onClick={() =>
                                  this.addMoreInfo(index, infoIndex)
                                }
                              />
                            </div>
                            <div className="form-block">
                              <FormControlBlock
                                label={
                                  (info.presetTasks || []).length > 0 &&
                                  'Preset Tasks'
                                }
                                control={
                                  <MultipleDropdown
                                    dropdownClassName="alternate-dropdown-control"
                                    options={presetTaskList}
                                    currentValues={info.presetTasks || []}
                                    placeholder="Preset Tasks"
                                    onChange={(values) =>
                                      this.handleAttributeChangeForSub(
                                        values,
                                        index,
                                        'presetTasks',
                                        infoIndex
                                      )
                                    }
                                    valid={
                                      !this.isErrorValueForMoreInfo(
                                        index,
                                        infoIndex,
                                        'presetTasks'
                                      )
                                    }
                                  />
                                }
                              />
                            </div>
                            <div className="form-block">
                              <FormControlBlock
                                label={!!info.customTask && 'Custom Task'}
                                control={
                                  <Input
                                    placeholder="Custom Task"
                                    onChange={(value) =>
                                      this.handleAttributeChangeForSub(
                                        value,
                                        index,
                                        'customTask',
                                        infoIndex
                                      )
                                    }
                                    value={info.customTask}
                                    valid={
                                      !this.isErrorValueForMoreInfo(
                                        index,
                                        infoIndex,
                                        'customTask'
                                      )
                                    }
                                  />
                                }
                              />
                            </div>
                            <div className="form-block">
                              <FormControlBlock
                                label={!!info.location && 'Location'}
                                control={
                                  <Input
                                    placeholder="Location"
                                    onChange={(value) =>
                                      this.handleAttributeChangeForSub(
                                        value,
                                        index,
                                        'location',
                                        infoIndex
                                      )
                                    }
                                    value={info.location}
                                  />
                                }
                              />
                            </div>
                            <div className="form-block">
                              <FormControlBlock
                                label={!!info.workPerformed && 'Description'}
                                control={
                                  <Input
                                    placeholder="Description"
                                    onChange={(value) =>
                                      this.handleAttributeChangeForSub(
                                        value,
                                        index,
                                        'workPerformed',
                                        infoIndex
                                      )
                                    }
                                    value={info.workPerformed}
                                  />
                                }
                              />
                            </div>
                          </React.Fragment>
                        );
                      })}
                  </React.Fragment>
                );
              })}

            <DetailsTitle className="modal-form-title" title="Information" />

            <div className="form-resources-block">
              <div className="form-images">
                <FormControlBlock
                  className="without-focus"
                  label="Photos"
                  control={
                    <div className="form-resources-body">
                      <ImageUpload
                        extensions={imageExtensions}
                        multiple
                        onUpload={(files) => this.setImages('images', files)}
                        onRemove={(index) => this.removeImages('images', index)}
                        images={this.getImages()}
                      />
                    </div>
                  }
                />
              </div>
            </div>

            <DetailsTitle
              className="modal-form-subtitle"
              title="Open Item"
              rightButtons={[
                <LabeledCheckBox
                  key="open-item"
                  label="Is there an Open Item to report?"
                  position="left"
                  onChange={this.handleAttributeChange.bind(null, 'isDelay')}
                  checked={contribFormData.isDelay}
                />,
              ]}
            />
            {contribFormData.isDelay && (
              <ProjectOpenItemForm
                form={contribFormData}
                errors={errors}
                handleAttributeChange={this.handleAttributeChange}
              />
            )}

            <DetailsTitle
              className="modal-form-subtitle"
              title="Extra Work Order"
              rightButtons={[
                <LabeledCheckBox
                  key="ewo"
                  label="Extra Work Order Required?"
                  position="left"
                  onChange={this.handleAttributeChange.bind(
                    null,
                    'isFieldWorkOrder'
                  )}
                  checked={contribFormData.isFieldWorkOrder}
                />,
              ]}
            />
            {contribFormData.isFieldWorkOrder && (
              <FieldWorkOrderForm
                fieldWorkOrderForm={contribFormData}
                handleAttributeChange={this.handleAttributeChange}
                showErrorMessage={false}
                formErrors={errors}
                fieldWorkOrder={currentDailyReport.field_work_order}
                fwoForSubForm
              />
            )}

            {((childFloors && !!childFloors.length) ||
              (settings && settings.multiple_floors)) && (
              <React.Fragment>
                <DetailsTitle
                  className="modal-form-subtitle"
                  title="Floors"
                  rightButtons={
                    settings && settings.multiple_floors
                      ? [
                          addFloor || isChildrenFloor ? (
                            <FloorButton
                              key="delete-floor"
                              isForAdd={false}
                              onClick={this.handleDeleteFloorClick}
                            />
                          ) : (
                            <FloorButton
                              key="add-floor"
                              isForAdd={true}
                              onClick={this.handleAddFloor}
                            />
                          ),
                        ]
                      : null
                  }
                />
                {childFloors && !!childFloors.length && (
                  <ul className="trade-item-floor-items small-regular">
                    {childFloors.map((drEntry) => (
                      <li
                        className="trade-item-floor-item"
                        key={drEntry.id}
                        onClick={() => this.handleAddFloor(drEntry)}
                      >
                        {!!drEntry.floor && `Floor ${drEntry.floor}`}
                        {!!drEntry.floor && !!drEntry.area && ' - '}
                        {!!drEntry.area && drEntry.area}
                        {!drEntry.floor && !drEntry.area && 'Draft Floor'}
                      </li>
                    ))}
                  </ul>
                )}
              </React.Fragment>
            )}
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    directory: getDirectory(state),
    settings: getSettings(state),
    contributorsForm: getContributorsForm(state),
    presetTasks: getPresetTasks(state),
    activeContributor: getActiveContributor(state),
    contributors: getContributors(state),
    activeEntryId: getActiveEntryId(state),
    currentProject: getCurrentProject(state),
    technicians: getTechnicians(state),
    currentDailyReport: getCurrentDailyReport(state),
    addFloor: getAddFloor(state),
  };
})(SingleTradesFormForSubComponent);
