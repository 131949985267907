import heic2any from 'heic2any';

const convertHeicFile = async (file) => {
  const extension = file.name.split('.').pop().toLowerCase(); // Get the file extension, from the last dot in the file name in case there are multiple dots.
  // TODO: use this similar approach of the last dot in 'src/services/utils/download-util.js' currently substituting dots with underscores.

  if (extension !== 'heic') return file;

  const { name, webkitRelativePath, lastModified, preview } = file;
  const baseName = name.substring(0, name.lastIndexOf('.'));
  const newName = `${baseName}.png`;

  const convertedBlob = await heic2any({
    blob: file,
    toType: 'image/png',
  });

  if (!convertedBlob) throw new Error('Conversion failed');

  // Handle the case where convertedBlob is an array
  const blobData = Array.isArray(convertedBlob)
    ? convertedBlob[0]
    : convertedBlob;

  const newFile = new File([blobData], newName, {
    type: 'image/png',
    webkitRelativePath,
    lastModified,
    preview,
  });

  return newFile;
};

export default convertHeicFile;
