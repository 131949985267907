import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './MeetingModal.css';
import FormModal from 'components/shared/modal/FormModal';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import MeetingTitle from 'components/admin/projects/details/project-meeting-minutes/MeetingTitle';
import MeetingItemsTable from 'components/admin/projects/details/project-meeting-minutes/MeetingItemsTable';
import MeetingActions from 'components/admin/projects/details/project-meeting-minutes/MeetingActions';
import {
  MeetingStatusKeys,
  MeetingStatusEnum,
} from 'domain/meeting-status-enum';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import {
  getMeetingModalShow,
  getMeetingCategories,
  getMeetings,
  getMeetingForm,
  getMeetingItems,
  getShowAttendeesModal,
  getReviewMeeting,
  getCurrentMeeting,
  getConfirmApproval,
  getMeetingInfo,
  getSelectedMeetingType,
} from 'components/admin/projects/details/project-meeting-minutes/store/selectors';
import {
  setMeetingModalShow,
  loadMeetingCategoriesRequest,
  loadMeetingRequest,
  initMeetingModal,
  updateMeetingFormValue,
  attachMeetingItems,
  setShowAttendeesModal,
  setReviewMeeting,
  createMeeting,
  updateMeeting,
  setConfirmationMsg,
  setConfirmApproval,
} from 'components/admin/projects/details/project-meeting-minutes/store/actions';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import moment from 'moment';
import { prevDay } from 'services/utils/date-util';
import User from 'domain/user';

class MeetingModal extends Component {
  static propTypes = {
    showActions: PropTypes.bool.isRequired,
    primary: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    props.dispatch(initMeetingModal());

    if (!props.showActions) {
      this.handleReview();
    }
  }

  componentDidMount() {
    if (!this.props.currentProject) {
      return;
    }

    if (
      !this.props.directory ||
      this.props.directory.project_id !== this.props.currentProject.id
    ) {
      this.props.dispatch(loadDirectoryRequest(this.props.currentProject.id));
    }

    this.props.dispatch(loadMeetingCategoriesRequest()).then((response) => {
      const currentMeeting = this.props.currentMeeting;
      if (currentMeeting) {
        this.handleReview();
        this.handleMeetingFormChange(
          'attendeeIds',
          currentMeeting.attendees.map((attendee) => {
            return [
              attendee.id,
              this.userLabel(attendee, attendee.accountable),
            ];
          })
        );
        this.handleMeetingFormChange(
          'meetingDate',
          currentMeeting.meeting_date
        );
        this.handleMeetingFormChange('number', currentMeeting.number);
        this.prepopulateFromCurrentMeeting(currentMeeting);
        this.prepopulateMeetingItems();
      } else {
        const { meetingInfo } = this.props;
        const meetingDate = meetingInfo
          ? prevDay(meetingInfo.day_of_week)
          : moment().format('YYYY-MM-DD');
        const lastMeeting = this.props.meetings[0];
        this.handleMeetingFormChange('meetingDate', meetingDate);
        if (lastMeeting) {
          this.props
            .dispatch(
              loadMeetingRequest(this.props.currentProject.id, lastMeeting.id)
            )
            .then((response) => {
              const number = response.meeting.number + 1;
              this.handleMeetingFormChange('number', number);
              this.prepopulateFromLastMeeting(response.meeting);
              this.prepopulateMeetingItems();
            });
        } else {
          this.handleMeetingFormChange('number', 1);
          this.prepopulateMeetingItems();
        }
      }
    });
  }

  meetingItemKeysByCategory = (meetingCatId) => {
    return Object.keys(this.props.meetingItems).filter(
      (key) => this.props.meetingItems[key].meetingCategoryId === meetingCatId
    );
  };

  userLabel = (user, company) => {
    return (
      <span>
        {`${user.first_name} ${user.last_name} - `}
        <span className="company-name">{company.name}</span>
      </span>
    );
  };

  mapContributors = (contributors) => {
    return contributors.map((contrib) => [
      contrib.id,
      User.fullNameWithCompany(contrib),
    ]);
  };

  prepopulateMeetingItems = () => {
    let meetingItems = {};
    let key = Object.keys(this.props.meetingItems).length;
    this.props.meetingCategories
      .filter(
        (meetingCat) =>
          this.meetingItemKeysByCategory(meetingCat.id).length === 0
      )
      .forEach((meetingCat) => {
        key += 1;
        meetingItems[key] = {
          meetingCategoryId: meetingCat.id,
          newItem: true,
          info: '',
          contributorIds: [],
          meetingCategory: {
            orderNumber: meetingCat.order_number,
          },
        };
      });

    this.props.dispatch(attachMeetingItems(meetingItems));
  };

  prepopulateFromLastMeeting = (lastMeeting) => {
    let meetingItems = {};
    let key = 0;
    lastMeeting.meeting_items
      .filter((item) => !item.resolved)
      .forEach((item) => {
        key += 1;
        meetingItems[key] = {
          code: item.code,
          meetingCategoryId: item.meeting_category_id,
          newItem: false,
          info: `${item.info}${
            item.new_info
              ? ` ${moment(item.new_info_date).format('M/D')} ${item.new_info}`
              : ''
          }`,
          contributorIds: this.mapContributors(item.contributors),
          meetingCategory: {
            orderNumber: item.meeting_category.order_number,
          },
        };
      });

    this.props.dispatch(attachMeetingItems(meetingItems));
  };

  prepopulateFromCurrentMeeting = (meeting) => {
    let meetingItems = {};
    let key = 0;
    meeting.meeting_items.forEach((item) => {
      key += 1;
      meetingItems[key] = {
        id: item.id,
        code: item.code,
        meetingCategoryId: item.meeting_category_id,
        newItem: item.new_item,
        info: item.info,
        newInfo: item.new_info,
        newInfoDate: item.new_info_date,
        resolved: item.resolved,
        resolvedNote: item.resolved_note,
        resolvedDate: item.resolved_date,
        contributorIds: this.mapContributors(item.contributors),
        meetingCategory: {
          orderNumber: item.meeting_category.order_number,
        },
      };
    });

    this.props.dispatch(attachMeetingItems(meetingItems));
  };

  renderAttendeesPopup = () => {
    let users = {};

    if (!this.props.directory) {
      return [];
    }

    return this.props.directory.project_contributors
      .map((pc) => {
        return pc.users
          .filter((usr) => !users[usr.id])
          .map((usr) => {
            users[usr.id] = true;
            const usrLabel = this.userLabel(usr, pc.company);
            return (
              <LabeledCheckBox
                key={usr.id}
                checked={
                  !!this.props.meetingForm.attendeeIds.find(
                    (attendee) => attendee[0] === usr.id
                  )
                }
                onChange={(value) => {
                  if (value) {
                    this.handleMeetingFormChange(
                      'attendeeIds',
                      this.props.meetingForm.attendeeIds.concat([
                        [usr.id, usrLabel],
                      ])
                    );
                  } else {
                    this.handleMeetingFormChange(
                      'attendeeIds',
                      this.props.meetingForm.attendeeIds.filter(
                        (attendee) => attendee[0] !== usr.id
                      )
                    );
                  }
                }}
                label={usrLabel}
              />
            );
          });
      })
      .reduce((acc, val) => acc.concat(val), []);
  };

  confirmApprovalMsg = () => {
    return this.props.primary
      ? 'Are you sure you are ready to Publish?'
      : 'Are you sure you are ready to Publish without PM Approval?';
  };

  handleMeetingFormChange = (attribute, value) => {
    this.props.dispatch(updateMeetingFormValue(attribute, value));
  };

  handleClose = () => {
    this.props.dispatch(setMeetingModalShow(false));
  };

  handleRollCall = () => {
    this.props.dispatch(setShowAttendeesModal(!this.props.showAttendeesModal));
  };

  handleCloseAttendeesModal = () => {
    this.props.dispatch(setShowAttendeesModal(false));
  };

  handleReview = () => {
    this.props.dispatch(setReviewMeeting(true));
  };

  handleBack = () => {
    this.props.dispatch(setReviewMeeting(false));
  };

  handleShowConfirmApproval = () => {
    this.props.dispatch(setConfirmApproval(true));
  };

  handleConfirmApproval = (confirmApproval) => {
    if (confirmApproval) {
      this.handleSend('approved', true);
    } else {
      this.props.dispatch(setConfirmApproval(false));
    }
  };

  handleSend = (status = 'draft', send = false) => {
    const meeting = {
      status: status,
      meetingDate: this.props.meetingForm.meetingDate,
      attendeeIds: this.props.meetingForm.attendeeIds.map(
        (attendee) => attendee[0]
      ),
      meetingItems: Object.keys(this.props.meetingItems)
        .filter((key) => {
          const item = this.props.meetingItems[key];
          return item.code && item.info && (!item.destroy || item.id);
        })
        .map((key) => {
          const item = this.props.meetingItems[key];
          return {
            id: item.id,
            code: item.code,
            meetingCategoryId: item.meetingCategoryId,
            newItem: item.newItem,
            info: item.info,
            newInfo: item.newInfo,
            resolved: item.resolved,
            resolvedNote: item.resolvedNote,
            destroy: item.destroy,
            contributorIds: item.contributorIds.map((contrib) => contrib[0]),
            meetingCategory: {
              orderNumber: item.meetingCategory.orderNumber,
            },
          };
        }),
    };

    let promise = null;
    const { currentMeeting, selectedMeetingType } = this.props;
    if (currentMeeting) {
      promise = this.props.dispatch(
        updateMeeting(
          this.props.currentProject.id,
          currentMeeting.id,
          meeting,
          selectedMeetingType
        )
      );
    } else {
      promise = this.props.dispatch(
        createMeeting(
          this.props.currentProject.id,
          meeting,
          selectedMeetingType
        )
      );
    }

    promise.then((response) => {
      if (response.ok) {
        if (send) {
          let msg = '';
          const meetingStatus = MeetingStatusEnum.create(
            response.meeting.status
          );
          if (MeetingStatusEnum.PENDING.equals(meetingStatus)) {
            msg = 'The Meeting Minutes has been sent for approval.';
          } else {
            msg = 'The Meeting Minutes has been published to the team.';
          }

          this.props.dispatch(setConfirmationMsg(msg));
        } else {
          this.handleClose();
        }
      }
    });
  };

  lastMeeting = () => {
    const { meetings, currentMeeting } = this.props;
    return !!currentMeeting && currentMeeting.id === meetings[0].id;
  };

  render() {
    return (
      <div>
        {this.props.currentProject &&
          this.props.meetingForm.number &&
          this.props.meetingForm.meetingDate && (
            <FormModal
              className="meeting-modal"
              show={this.props.meetingModalShow}
              onClose={this.handleClose}
              onBack={
                this.props.reviewMeeting && this.props.showActions
                  ? this.handleBack
                  : undefined
              }
              subTitle={`${this.props.currentProject.name} - ${
                this.props.currentMeeting ? 'Meeting Details' : 'Add Meeting'
              }`}
              title={
                <MeetingTitle
                  reviewMeeting={this.props.reviewMeeting}
                  meetingForm={this.props.meetingForm}
                  showAttendeesModal={this.props.showAttendeesModal}
                  handleRollCall={this.handleRollCall}
                  handleCloseAttendeesModal={this.handleCloseAttendeesModal}
                  renderAttendeesPopup={this.renderAttendeesPopup}
                  handleMeetingFormChange={this.handleMeetingFormChange}
                />
              }
            >
              {this.props.reviewMeeting &&
                this.props.meetingForm.attendeeIds.length > 0 && (
                  <div className="review-attendees">
                    <div className="review-and-send">Review & Send</div>
                    <div className="review-attended">Attended</div>
                    {this.props.meetingForm.attendeeIds.map((attendee) => {
                      return (
                        <div key={attendee[0]} className="review-attendee">
                          {attendee[1]}
                        </div>
                      );
                    })}
                  </div>
                )}

              <MeetingItemsTable
                meetingNumber={this.props.meetingForm.number}
                meetingDate={this.props.meetingForm.meetingDate}
                reviewMeeting={this.props.reviewMeeting}
              />

              {this.props.showActions && (
                <MeetingActions
                  primary={this.props.primary}
                  reviewMeeting={this.props.reviewMeeting}
                  lastMeeting={this.lastMeeting()}
                  meetingStatus={
                    this.props.currentMeeting
                      ? this.props.currentMeeting.status
                      : MeetingStatusKeys.DRAFT
                  }
                  meetingItems={this.props.meetingItems}
                  handleSend={this.handleSend}
                  handleShowConfirmApproval={this.handleShowConfirmApproval}
                  handleBack={this.handleBack}
                  handleReview={this.handleReview}
                  handleClose={this.handleClose}
                />
              )}

              {this.props.confirmApproval && (
                <ConfirmationModal
                  show={this.props.confirmApproval}
                  message={this.confirmApprovalMsg()}
                  onHide={this.handleConfirmApproval}
                />
              )}
            </FormModal>
          )}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    directory: getDirectory(state),
    meetingModalShow: getMeetingModalShow(state),
    meetingCategories: getMeetingCategories(state),
    meetings: getMeetings(state),
    meetingForm: getMeetingForm(state),
    meetingItems: getMeetingItems(state),
    showAttendeesModal: getShowAttendeesModal(state),
    reviewMeeting: getReviewMeeting(state),
    currentMeeting: getCurrentMeeting(state),
    confirmApproval: getConfirmApproval(state),
    meetingInfo: getMeetingInfo(state),
    selectedMeetingType: getSelectedMeetingType(state),
  };
})(MeetingModal);
