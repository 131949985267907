import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImageUpload from 'components/shared/image-upload/ImageUpload';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import Selector from 'components/shared/selector/Selector';
import TimePicker from 'components/shared/time-picker/TimePicker';
import Input from 'components/shared/input/Input';
import FloorButton from 'components/shared/button/FloorButton';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import ProjectOpenItemForm from 'components/admin/projects/details/project-daily-report-log/ProjectOpenItemForm';
import {
  loadSupervisorTasksRequest,
  setMembers,
  setDeletedMembers,
  setReportOpenItemValue,
  setGCImagesAction,
  setGCDeletedImagesAction,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { getSettings } from 'components/admin/projects/details/project-settings/store/selectors';
import {
  getSupervisorTasks,
  getGeneralContactorName,
  getMembers,
  getDeletedMembers,
  getReportOpenItem,
  getGCImages,
  getGCDeletedImages,
} from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import { imageExtensions } from 'services/utils/files-util';
import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import User from 'domain/user';
import Project from 'domain/project';

const initialMember = {
  user: '',
  floor: '',
  area: '',
  hoursAmount: '',
  workedFrom: '',
  workedTo: '',
  supervisorTasks: [],
  customTask: '',
  comments: '',
};

class MemberFormModal extends Component {
  handleMemberAttribute = (key, index, values) => {
    const { dispatch, members, clearErrors } = this.props;

    const membersList = [...members];
    const newMember = { ...membersList[index] };
    newMember[key] = values;
    membersList.splice(index, 1, newMember);
    clearErrors(index, key);
    dispatch(setMembers(membersList));
  };

  insertMember = (member) => {
    const { dispatch, members } = this.props;
    const membersList = [...members];
    membersList.push(member);
    dispatch(setMembers(membersList));
  };

  addMember = () => {
    this.insertMember(initialMember);
  };

  addMemberFloor = (user) => {
    this.insertMember({ ...initialMember, user, isChildFloor: true });
  };

  removeMember = (index) => {
    const { dispatch, members, deletedMembers } = this.props;
    const membersList = [...members];
    const deletedMember = membersList.splice(index, 1)[0];
    if (deletedMember.id) {
      const newDeletedMembers = [...deletedMembers];
      newDeletedMembers.push(deletedMember);
      dispatch(setDeletedMembers(newDeletedMembers));
    }
    dispatch(setMembers(membersList));
  };

  deleteMember = () => {
    const { members } = this.props;
    this.removeMember(members.length - 1);
  };

  deletedMemberFloor = (index) => {
    this.removeMember(index);
  };

  handleHoursAmount = (index, value) => {
    const { dispatch, members, clearErrors } = this.props;
    const membersList = [...members];
    const newMember = { ...membersList[index] };
    newMember.hoursAmount = value;
    newMember.workedFrom = '';
    newMember.workedTo = '';
    membersList.splice(index, 1, newMember);
    clearErrors(index, 'hoursAmount');
    dispatch(setMembers(membersList));
  };

  handleDate = (key, index, value) => {
    const { dispatch, members, clearErrors } = this.props;
    const membersList = [...members];
    const newMember = { ...membersList[index] };
    newMember[key] = value;
    newMember.hoursAmount = '';
    membersList.splice(index, 1, newMember);
    clearErrors(index, key);
    dispatch(setMembers(membersList));
  };

  getUsersList = (index) => {
    const { currentProject, directory, members } = this.props;

    const gcContributor = Project.gcContributor({
      project: currentProject,
      directory,
    });
    return ((gcContributor && gcContributor.users) || [])
      .filter(
        (user) =>
          !members.some(
            (member) => member.user && member.user.value === user.id
          ) ||
          members.findIndex(
            (member) => member.user && member.user.value === user.id
          ) === index
      )
      .map((user) => ({
        value: user.id,
        label: User.fullName(user),
      }));
  };

  handleOpenItemChange = (attribute, value) => {
    const { openItem, clearErrors, dispatch } = this.props;
    clearErrors(openItem, attribute);
    dispatch(setReportOpenItemValue(attribute, value));
  };

  componentDidMount() {
    const {
      dispatch,
      members,
      supervisorTasks,
      submitFailedErrors,
    } = this.props;
    if (supervisorTasks.length === 0) {
      dispatch(loadSupervisorTasksRequest());
    }
    if (!members.length) {
      dispatch(setMembers([initialMember]));
    }
    if (submitFailedErrors) {
      submitFailedErrors();
    }
  }

  setImages = (files) => {
    const { gcImages, dispatch } = this.props;
    let images = [...gcImages];
    files.forEach(function (val) {
      images.push({
        url: val.url || URL.createObjectURL(val),
        file: val,
      });
    });
    dispatch(setGCImagesAction(images));
  };

  removeImages = (index) => {
    const { gcImages, gcDeletedImages, dispatch } = this.props;
    let images = [...gcImages];
    let deletedImages = [...gcDeletedImages];
    let deletedImage = images.splice(index, 1);
    deletedImages.push(deletedImage[0]);

    dispatch(setGCDeletedImagesAction(deletedImages));
    dispatch(setGCImagesAction(images));
  };

  renderMember = (member, index, memberNumber) => {
    const { members, supervisorTasks, settings, errors } = this.props;
    const { errorsMemberForm } = errors;
    return (
      <div className="member-block-wrapper" key={index}>
        <DetailsTitle
          className="modal-form-subtitle"
          title={`Member ${memberNumber}${member.isChildFloor ? ' Floor' : ''}`}
          rightButtons={[
            settings &&
              settings.multiple_floors &&
              member.user &&
              (member.isChildFloor ? (
                <FloorButton
                  key="remove-floor"
                  isForAdd={false}
                  onClick={() => this.deletedMemberFloor(index)}
                />
              ) : (
                <FloorButton
                  key="add-floor"
                  isForAdd={true}
                  onClick={() => this.addMemberFloor(member.user)}
                />
              )),
            members.length > 1 && !member.isChildFloor && (
              <Button
                key="delete"
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Delete"
                onClick={this.deleteMember}
              />
            ),
          ].filter((button) => button)}
        />
        {!member.isChildFloor && (
          <div className="form-block">
            <FormControlBlock
              label={!!(member.user && member.user.value) && 'User'}
              control={
                <Selector
                  options={this.getUsersList(index)}
                  value={(member.user && member.user.value) || ''}
                  placeholder="User"
                  onChange={(values) =>
                    this.handleMemberAttribute('user', index, values)
                  }
                  valid={
                    !errorsMemberForm[index] || !errorsMemberForm[index].user
                  }
                />
              }
            />
          </div>
        )}
        {settings && settings.multiple_floors && (
          <div className="form-block">
            <FormControlBlock
              label={!!member.floor && 'Floor'}
              control={
                <Input
                  type="number"
                  placeholder="Floor"
                  onChange={this.handleMemberAttribute.bind(
                    null,
                    'floor',
                    index
                  )}
                  value={member.floor || ''}
                />
              }
            />
            <FormControlBlock
              label={!!member.area && 'Area'}
              control={
                <Input
                  placeholder="Area"
                  onChange={this.handleMemberAttribute.bind(
                    null,
                    'area',
                    index
                  )}
                  value={member.area || ''}
                />
              }
            />
          </div>
        )}
        <div className="form-block multicontrols-block">
          <FormControlBlock
            label={!!member.hoursAmount && 'Worked Hours'}
            control={
              <Input
                type="number"
                onChange={(value) => this.handleHoursAmount(index, value)}
                value={member.hoursAmount}
                placeholder="Worked Hours"
                valid={
                  !errorsMemberForm[index] ||
                  !errorsMemberForm[index].hoursAmount
                }
              />
            }
          />
          <span className="form-block-text">Or</span>
          <FormControlBlock
            label={!!member.workedFrom && 'Worked From'}
            control={
              <TimePicker
                placeholder="Worked From"
                onChange={(value) =>
                  this.handleDate('workedFrom', index, value)
                }
                value={member.workedFrom}
                valid={
                  !errorsMemberForm[index] ||
                  !errorsMemberForm[index].workedFrom
                }
              />
            }
          />
          <FormControlBlock
            label={!!member.workedTo && 'To'}
            control={
              <TimePicker
                placeholder="To"
                onChange={(value) => this.handleDate('workedTo', index, value)}
                value={member.workedTo}
                valid={
                  !errorsMemberForm[index] || !errorsMemberForm[index].workedTo
                }
              />
            }
          />
        </div>
        <div className="form-block">
          <FormControlBlock
            label={(member.supervisorTasks || []).length > 0 && 'Preset Tasks'}
            control={
              <MultipleDropdown
                options={supervisorTasks}
                currentValues={member.supervisorTasks || []}
                placeholder="Preset Tasks"
                onChange={(values) =>
                  this.handleMemberAttribute('supervisorTasks', index, values)
                }
                valid={
                  !errorsMemberForm[index] ||
                  !errorsMemberForm[index].supervisorTasks
                }
              />
            }
          />
        </div>
        <div className="form-block">
          <FormControlBlock
            label={!!member.customTask && 'Custom Task'}
            control={
              <Input
                placeholder="Custom Task"
                onChange={this.handleMemberAttribute.bind(
                  null,
                  'customTask',
                  index
                )}
                value={member.customTask}
              />
            }
          />
        </div>
        <div className="form-block">
          <FormControlBlock
            label={!!member.comments && 'Comments'}
            control={
              <TextareaAutosizeWrapper
                placeholder="Comments"
                initialValue={member.comments || ''}
                onChange={(value) =>
                  this.handleMemberAttribute('comments', index, value)
                }
              />
            }
          />
        </div>
      </div>
    );
  };

  render() {
    const { members, openItem, gcImages, errors } = this.props;
    const { errorsOpenItemForm } = errors;
    let memberNumber = 0;

    return (
      <React.Fragment>
        {members && members.length > 0 && (
          <div className="single-trade-form-wrapper">
            <DetailsTitle
              className="modal-form-title"
              title="Members"
              rightButtons={[
                <Button
                  key="add-member"
                  type={BUTTON_TYPES.LINK}
                  icon={BUTTON_ICONS.PLUS}
                  label="Add Member"
                  onClick={this.addMember}
                />,
              ]}
            />
            {members
              .filter((member) => !member.isChildFloor)
              .map((member, index) => {
                memberNumber++;
                return (
                  <React.Fragment key={index}>
                    {this.renderMember(
                      member,
                      members.indexOf(member),
                      memberNumber
                    )}
                    {members
                      .filter(
                        (member2) =>
                          member2.isChildFloor &&
                          member2.user.value === member.user.value
                      )
                      .map((member2) =>
                        this.renderMember(
                          member2,
                          members.indexOf(member2),
                          memberNumber
                        )
                      )}
                  </React.Fragment>
                );
              })}

            <DetailsTitle className="modal-form-title" title="Information" />
            <div className="form-resources-block">
              <div className="form-images">
                <FormControlBlock
                  className="without-focus"
                  label="Photos"
                  control={
                    <div className="form-resources-body">
                      <ImageUpload
                        extensions={imageExtensions}
                        multiple
                        onUpload={(files) => this.setImages(files)}
                        onRemove={(index) => this.removeImages(index)}
                        images={gcImages}
                      />
                    </div>
                  }
                />
              </div>
            </div>

            <DetailsTitle
              className="modal-form-subtitle"
              title="Open Item"
              rightButtons={[
                <LabeledCheckBox
                  key="open-item"
                  label="Is there an Open Item to report?"
                  position="left"
                  onChange={this.handleOpenItemChange.bind(null, 'isDelay')}
                  checked={openItem.isDelay}
                />,
              ]}
            />
            {openItem.isDelay && (
              <ProjectOpenItemForm
                form={openItem}
                errors={errorsOpenItemForm}
                handleAttributeChange={this.handleOpenItemChange}
              />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    directory: getDirectory(state),
    settings: getSettings(state),
    generalContactorName: getGeneralContactorName(state),
    supervisorTasks: getSupervisorTasks(state),
    members: getMembers(state),
    deletedMembers: getDeletedMembers(state),
    openItem: getReportOpenItem(state),
    gcImages: getGCImages(state),
    gcDeletedImages: getGCDeletedImages(state),
  };
})(MemberFormModal);
