import User from 'domain/user';
import Meeting from 'domain/meeting';

class MeetingPermissions {
  canPrimaryContribute = (user, project) => User.isPrimary(user, project);

  canAdd = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project) ||
    User.isPrimary(user, project);

  canEditInfo = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project) ||
    User.isPrimary(user, project);

  canEdit = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project) ||
    User.isPrimary(user, project);

  canDelete = (currentUser, meeting, meetings) =>
    Meeting.isLast(meeting, meetings) &&
    (!Meeting.isApproved(meeting) || User.isAdmin(currentUser));

  canAddNewInfo = ({ user, project, meeting, meetingItem, lastMeeting }) => {
    if (
      Meeting.isResolvedItem(meetingItem) ||
      (!lastMeeting && !Meeting.isApproved(meeting))
    )
      return false;

    return (
      (User.isAdminProjectSubscriberGcOar(user, project) &&
        !Meeting.isNewItem(meetingItem)) ||
      Meeting.isUserAssignedToItem(meetingItem, user)
    );
  };
}

export default new MeetingPermissions();
