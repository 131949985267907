import React from 'react';
import { connect } from 'react-redux';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import ImageUpload from 'components/shared/image-upload/ImageUpload';
import DocumentUploadWithPreview from 'components/shared/document-upload/DocumentUploadWithPreview';
import InputMaxLength from '../../../../shared/input/InputMaxLength';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import {
  getMeetingsInstanceForm,
  getActionItemsErrors,
  getInstanceInfoErrors,
  getProjectNewMeetingsForm,
} from './store/selectors';
import './MeetingsInstanceForm.css';
import newMeetingsPermissions from '../../../../../permissions/new-meetings-permissions';

const MeetingsInstanceFormAttachments = (props) => {
  const {
    handleFormChange,
    instanceForm,
    actionItemsErrors,
    instanceInfoErrors,
    currentUser,
    form,
  } = props;
  const canEdit = newMeetingsPermissions.canEditInstance(
    currentUser,
    instanceForm,
    form.status === 'archived'
  );
  const errors = () => {
    const instanceInfoErrorsKeys = Object.keys(instanceInfoErrors);
    return (
      <div className="errors-container">
        {actionItemsErrors
          ? actionItemsErrors.map((e) => <div>{e}</div>)
          : null}
        {instanceInfoErrorsKeys.length > 0
          ? instanceInfoErrorsKeys.map((e) => (
              <div>{instanceInfoErrors[e]}</div>
            ))
          : null}
      </div>
    );
  };
  const handleUploadImage = (files) => {
    const images = [...instanceForm.attachedImages];
    files.forEach((file) => {
      images.push({ url: URL.createObjectURL(file), file });
    });

    handleFormChange({ ...instanceForm, attachedImages: images });
  };

  const handleUploadDocument = (files) => {
    const documents = [...instanceForm.attachedDocuments];
    files.forEach((file) => {
      documents.push({ file, name: file.name });
    });
    handleFormChange({ ...instanceForm, attachedDocuments: documents });
  };

  const handleRemoveImage = (index) => {
    const images = [...instanceForm.attachedImages];
    const deletedImages = [
      ...instanceForm.deletedImages,
      ...images.splice(index, 1),
    ];

    handleFormChange({
      ...instanceForm,
      attachedImages: images,
      deletedImages,
    });
  };

  const handleRemoveDocument = (index) => {
    const documents = [...instanceForm.attachedDocuments];
    const deletedDocuments = [
      ...instanceForm.deletedDocuments,
      ...documents.splice(index, 1),
    ];

    handleFormChange({
      ...instanceForm,
      attachedDocuments: documents,
      deletedDocuments,
    });
  };
  return (
    <React.Fragment>
      <div>
        <div className="attachment-form-text">
          Include the following information as attachments
        </div>
        <div className="attachments-checkboxes-container">
          <FormControlBlock
            hideBottomLabel
            className="input-condition"
            control={
              <LabeledCheckBox
                readOnly={!canEdit}
                label="Directory"
                checked={!!instanceForm.hasDirectoryPdf}
                onChange={(hasDirectoryPdf) =>
                  handleFormChange({ ...instanceForm, hasDirectoryPdf })
                }
                position="left"
              />
            }
          />
          <FormControlBlock
            hideBottomLabel
            className="input-condition"
            control={
              <LabeledCheckBox
                readOnly={!canEdit}
                label="Open Items Log"
                checked={!!instanceForm.hasOpenItemsLogPdf}
                onChange={(hasOpenItemsLogPdf) =>
                  handleFormChange({ ...instanceForm, hasOpenItemsLogPdf })
                }
                position="left"
              />
            }
          />
          <FormControlBlock
            hideBottomLabel
            className="input-condition"
            control={
              <LabeledCheckBox
                readOnly={!canEdit}
                label="RFA Log"
                checked={!!instanceForm.hasRfaLogPdf}
                onChange={(hasRfaLogPdf) =>
                  handleFormChange({ ...instanceForm, hasRfaLogPdf })
                }
                position="left"
              />
            }
          />
        </div>
        <div className="attachments-checkboxes-container-small">
          <FormControlBlock
            hideBottomLabel
            className="input-condition"
            control={
              <LabeledCheckBox
                readOnly={!canEdit}
                label="RFI Log"
                checked={!!instanceForm.hasRfiLogPdf}
                onChange={(hasRfiLogPdf) =>
                  handleFormChange({ ...instanceForm, hasRfiLogPdf })
                }
                position="left"
              />
            }
          />
          <FormControlBlock
            hideBottomLabel
            className="input-condition"
            control={
              <LabeledCheckBox
                readOnly={!canEdit}
                label="Submittal Log"
                checked={!!instanceForm.hasSubmittalLogPdf}
                onChange={(hasSubmittalLogPdf) =>
                  handleFormChange({ ...instanceForm, hasSubmittalLogPdf })
                }
                position="left"
              />
            }
          />
        </div>
        <div className="attachment-form-subtitle">Project Schedule</div>
        <div className="attachments-schedules-container">
          <FormControlBlock
            hideBottomLabel
            className="input-condition"
            control={
              <LabeledCheckBox
                readOnly={!canEdit}
                label="Schedules Summary"
                checked={!!instanceForm.hasScheduleSummaryPdf}
                onChange={(hasScheduleSummaryPdf) =>
                  handleFormChange({ ...instanceForm, hasScheduleSummaryPdf })
                }
                position="left"
              />
            }
          />
          <FormControlBlock
            hideBottomLabel
            className="input-condition"
            control={
              <LabeledCheckBox
                readOnly={!canEdit}
                label="Lookahead"
                checked={!!instanceForm.hasLookaheadPdf}
                onChange={(hasLookaheadPdf) =>
                  handleFormChange({ ...instanceForm, hasLookaheadPdf })
                }
                position="left"
              />
            }
          />
          <FormControlBlock
            hideBottomLabel
            className="input-condition"
            control={
              <LabeledCheckBox
                readOnly={!canEdit}
                label="Milestones"
                checked={!!instanceForm.hasMilestonesPdf}
                onChange={(hasMilestonesPdf) =>
                  handleFormChange({ ...instanceForm, hasMilestonesPdf })
                }
                position="left"
              />
            }
          />
        </div>
        <div className="attachment-form-title">Documents & Photos</div>
        <div className="attachments-uploaders-container">
          <FormControlBlock
            control={
              <DocumentUploadWithPreview
                multiple
                documents={instanceForm.attachedDocuments}
                onDrop={handleUploadDocument}
                onRemove={handleRemoveDocument}
                canEdit={!canEdit}
              />
            }
          />
          <FormControlBlock
            control={
              <ImageUpload
                extensions={['.jpg', '.jpeg', '.gif', '.png', '.heic']}
                images={instanceForm.attachedImages}
                onRemove={handleRemoveImage}
                onUpload={handleUploadImage}
                multiple
                readOnly={!canEdit}
              />
            }
          />
        </div>
        <div className="attachment-form-title">Disclaimer</div>
        <FormControlBlock
          label="Disclaimer"
          position="right"
          readonly={!canEdit}
          control={
            <InputMaxLength
              placeholder={'Correction Remark'}
              value={instanceForm.disclaimer}
              onChange={(disclaimer) =>
                handleFormChange({ ...instanceForm, disclaimer })
              }
              maxLength={500}
            />
          }
        ></FormControlBlock>
      </div>
      {errors()}
    </React.Fragment>
  );
};

export default connect((state) => ({
  currentProject: getCurrentProject(state),
  instanceForm: getMeetingsInstanceForm(state),
  actionItemsErrors: getActionItemsErrors(state),
  instanceInfoErrors: getInstanceInfoErrors(state),
  currentUser: getCurrentUser(state),
  form: getProjectNewMeetingsForm(state),
}))(MeetingsInstanceFormAttachments);
