import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import FormModal from 'components/shared/modal/FormModal';
import Input from 'components/shared/input/Input';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import {
  getCurrentReportViewDetails,
  getShowSaveView,
} from './store/selectors';
import { setReportViewFormValue, hideSaveViewModal } from './store/actions';
import './SaveViewModal.css';

class SaveViewModal extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
  };

  handleFormChange = (attribute, value) => {
    const { dispatch } = this.props;
    dispatch(setReportViewFormValue('details', attribute, value));
  };

  handleClose = () => {
    const { dispatch } = this.props;
    dispatch(hideSaveViewModal());
  };

  validForm = () => {
    const {
      currentReportViewDetails: { name },
    } = this.props;

    return !!name;
  };

  render() {
    const {
      currentReportViewDetails: { name },
      showSaveView,
      onSave,
    } = this.props;

    return (
      <div id="report-save-view-modal">
        <FormModal
          className="report-save-view-modal"
          show={showSaveView}
          subTitle="Save View"
          onClose={this.handleClose}
        >
          <Form horizontal onSubmit={this.handleSubmit}>
            <div className="form-block">
              <FormControlBlock
                label={!!name && 'View Name'}
                control={
                  <Input
                    placeholder="View Name"
                    value={name}
                    onChange={this.handleFormChange.bind(this, 'name')}
                  />
                }
              />
            </div>

            <div className="buttons-container">
              <Button
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.GREEN}
                label="Save"
                onClick={onSave}
                disabled={!this.validForm()}
              />
            </div>
          </Form>
        </FormModal>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentReportViewDetails: getCurrentReportViewDetails(state),
    showSaveView: getShowSaveView(state),
  };
})(SaveViewModal);
