import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Table } from 'react-bootstrap';
import classNames from 'classnames';
import dragDropIcon from 'images/dragDropIcon.svg';
import dragDropIconDark from 'images/dragDropIconDark.svg';
import { getDarkMode } from 'selectors/theme';
import './DragableTable.css';

const DraggableTable = (props) => {
  const {
    headers,
    rows,
    onOrderChange,
    emptyMessage,
    className,
    darkMode,
    containerClassName,
    disabled,
  } = props;

  const cols = useMemo(() => {
    return headers.map((_, i) => <col key={i + 1} />);
  }, []);

  const renderRow = (cells) =>
    cells.map((cell, i) => (
      <td key={i + 1}>
        <span>{cell}</span>
      </td>
    ));

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination || destination.index === source.index) return;
    onOrderChange(result);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="dropable" isDropDisabled={disabled}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            className={`table-dragable ${containerClassName}`}
            ref={provided.innerRef}
          >
            <Table
              className={classNames({
                'simple-table': true,
                'projects-table': true,
                'dragable-table': true,
                [className]: !!className,
              })}
            >
              {cols.length > 0 && (
                <colgroup>
                  <col key={0} />
                  {cols}
                </colgroup>
              )}
              {headers.length > 0 && (
                <thead>
                  <tr>
                    <th key={0}></th>
                    {headers.map((element, i) => (
                      <th key={i + 1}>
                        <span>{element}</span>
                      </th>
                    ))}
                  </tr>
                </thead>
              )}
              <tbody>
                {rows.map((row, index) => {
                  return (
                    <Draggable
                      key={row.id}
                      draggableId={row.id.toString()}
                      index={index}
                      isDragDisabled={disabled || row.disabled}
                    >
                      {(provided, shapshot) => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                          key={row.id}
                          className={`${
                            shapshot.isDragging ? 'dragging' : ''
                          } ${row.className} ${disabled ? 'disabled' : ''}`}
                        >
                          <td key={0} className="td-icon">
                            <span>
                              <div className="drag-drop-icon">
                                <img
                                  src={
                                    darkMode ? dragDropIconDark : dragDropIcon
                                  }
                                  alt="drag and drop"
                                />
                              </div>
                            </span>
                          </td>
                          {renderRow(row.cells)}
                        </tr>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </tbody>
            </Table>
            {rows.length === 0 && (
              <div className="empty-table-message">{emptyMessage}</div>
            )}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DraggableTable.propTypes = {
  headers: PropTypes.array,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      cells: PropTypes.array.isRequired,
      className: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ),
  disabled: PropTypes.bool,
  emptyMessage: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  onOrderChange: PropTypes.func,
  darkMode: PropTypes.bool,
};

DraggableTable.defaultProps = {
  headers: [],
  rows: [],
  emptyMessage: 'No Items',
  className: null,
  containerClassName: null,
  onOrderChange: () => {},
  darkMode: true,
  disabled: false,
};

export default connect((state) => ({
  darkMode: getDarkMode(state),
}))(DraggableTable);
