import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import {
  setProjectReportAttr,
  getProjectReportResolveInfoByAttr,
  projectReportResolvedTableFilter,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';
import {
  getProjectReportValue,
  getFieldProjectResolvedInfo,
} from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import { getDarkMode } from 'selectors/theme';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import ProjectReportProjectSummaryModal from './ProjectReportProjectSummaryModal';
import './ReportsForm.css';

const attach_documents = [
  { key: 'recent_daily_reports', label: 'Recent Daily Reports' },
  { key: 'rfi_log', label: 'RFI Log' },
  { key: 'recent_meeting_mins', label: 'Recent Meeting Mins' },
  { key: 'submittal_log', label: 'Submittal Log' },
];

class ProjectSummaryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalName: '',
    };
  }

  componentDidMount() {
    const { projectId, projectReportId, dispatch } = this.props;
    dispatch(
      getProjectReportResolveInfoByAttr(projectId, projectReportId, 'remarks')
    );
  }

  handleAttributeChange = (attr, value) => {
    this.props.dispatch(setProjectReportAttr(attr, value));
  };

  handleCheckboxChange = (item) => {
    const {
      report: { attach_documents = [] },
    } = this.props;
    const data = {
      ...attach_documents,
      [item.key]: {
        key: item.key,
        value: attach_documents[item.key]
          ? !attach_documents[item.key].value
          : true,
      },
    };
    this.handleAttributeChange('attach_documents', data);
  };

  handleModalShow = (value, modalName, attr) => {
    const { projectId, projectReportId, dispatch } = this.props;
    this.setState({ showModal: value, modalName: modalName });
    dispatch(
      getProjectReportResolveInfoByAttr(projectId, projectReportId, attr)
    );
  };

  render() {
    const {
      report,
      resolvedInfo,
      dispatch,
      projectId,
      projectReportId,
    } = this.props;
    const { showModal, modalName } = this.state;

    return (
      <div>
        <div className="report-form-block">
          <div className="row-block row-space-between">
            <label className="report-form-block-label">Project Summary</label>
            <div className="align-self-start">
              {resolvedInfo.remarks && resolvedInfo.remarks.length > 0 && (
                <Button
                  key="remarks-view-resolved"
                  type={BUTTON_TYPES.LINK}
                  color={BUTTON_COLORS.GREEN}
                  label="View Resolved"
                  onClick={this.handleModalShow.bind(
                    null,
                    true,
                    'resolved-remarks',
                    'remarks'
                  )}
                />
              )}
            </div>
          </div>
          <TextareaAutosizeWrapper
            placeholder="Remarks"
            maxLength={500}
            initialValue={report.remarks || ''}
            onChange={(value) => this.handleAttributeChange('remarks', value)}
          />
          <span className="numbers-of-letters">
            {`${report.remarks ? report.remarks.length : 0}/500`}
          </span>
        </div>

        <div className="report-form-block">
          <label className="report-form-block-label">Project Documents</label>
          <span className="project-info-description">
            Include the following information as attachments to the report:
          </span>
          <div className="row-block checkbox-container">
            {attach_documents.map((item) => (
              <div key={item.key} className="checkbox-row-container">
                <LabeledCheckBox
                  className="project-info-checkbox"
                  label={item.label}
                  position="left"
                  onChange={this.handleCheckboxChange.bind(null, item)}
                  checked={
                    report.attach_documents &&
                    report.attach_documents[item.key] &&
                    report.attach_documents[item.key].value
                  }
                />
              </div>
            ))}
          </div>
        </div>
        <ProjectReportProjectSummaryModal
          show={showModal}
          modalName={modalName}
          projectId={projectId}
          projectReportId={projectReportId}
          data={resolvedInfo.remarks || []}
          onClose={() => this.setState({ showModal: !showModal })}
          onSearch={(search) =>
            dispatch(
              projectReportResolvedTableFilter('remarks', search, 'remarks')
            )
          }
        />
      </div>
    );
  }
}

ProjectSummaryForm.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  report: PropTypes.shape(),
  projectReportId: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
};

export default connect(
  (state) => ({
    darkMode: getDarkMode(state),
    report: getProjectReportValue(state),
    resolvedInfo: getFieldProjectResolvedInfo(state),
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
  }),
  null
)(ProjectSummaryForm);
