import {
  OPEN_ITEMS_MODAL_SHOW,
  OPEN_ITEMS_MODAL_CLOSE,
  OPEN_ITEMS_REPORT_MODAL_SHOW,
  OPEN_ITEMS_REPORT_MODAL_CLOSE,
  OPEN_ITEM_FORM_MODAL_SHOW,
  OPEN_ITEM_FORM_MODAL_CLOSE,
  OPEN_ITEM_FORM_ATTR_SET,
  OPEN_ITEM_FORM_ERRORS_SET,
  OPEN_ITEM_FORM_ERRORS_ATTR_CLEAR,
  OPEN_ITEM_FORM_SET,
  OPEN_ITEM_FORM_RESET,
  OPEN_ITEM_DETAILS_MODAL_SHOW,
  OPEN_ITEM_DETAILS_MODAL_CLOSE,
  OPEN_ITEM_DETAILS_MODAL_EDIT,
  OPEN_ITEM_DETAILS_MODAL_EDIT_CANCEL,
  OPEN_ITEM_DETAILS_MODAL_RESPOND,
  OPEN_ITEM_DETAILS_MODAL_RESPOND_CANCEL,
  OPEN_ITEM_PDF_VIEWER_SET,
} from 'components/admin/projects/details/project-open-items/store/action-types';

const initialState = {
  showOpenItemsModal: false,
  showOpenItemsReportModal: false,
  showOpenItemFormModal: false,
  showOpenItemDetailsModal: false,
  editOpenItemDetailsModal: false,
  respondOpenItemDetailsModal: false,
  notesOpenItemDetailsModal: false,
  openItemForm: {},
  openItemFormErrors: {},
};

export default function projectOpenItemsUiReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case OPEN_ITEMS_MODAL_SHOW:
      return {
        ...state,
        showOpenItemsModal: true,
      };

    case OPEN_ITEMS_MODAL_CLOSE:
      return {
        ...state,
        showOpenItemsModal: false,
      };

    case OPEN_ITEMS_REPORT_MODAL_SHOW:
      return {
        ...state,
        showOpenItemsReportModal: true,
      };

    case OPEN_ITEMS_REPORT_MODAL_CLOSE:
      return {
        ...state,
        showOpenItemsReportModal: false,
      };

    case OPEN_ITEM_FORM_MODAL_SHOW:
      return {
        ...state,
        showOpenItemFormModal: true,
      };

    case OPEN_ITEM_FORM_MODAL_CLOSE:
      return {
        ...state,
        showOpenItemFormModal: false,
      };

    case OPEN_ITEM_DETAILS_MODAL_SHOW:
      return {
        ...state,
        showOpenItemDetailsModal: true,
      };

    case OPEN_ITEM_DETAILS_MODAL_CLOSE:
      return {
        ...state,
        showOpenItemDetailsModal: false,
        editOpenItemDetailsModal: false,
        respondOpenItemDetailsModal: false,
        notesOpenItemDetailsModal: false,
      };

    case OPEN_ITEM_DETAILS_MODAL_EDIT:
      return {
        ...state,
        editOpenItemDetailsModal: true,
      };

    case OPEN_ITEM_DETAILS_MODAL_EDIT_CANCEL:
      return {
        ...state,
        editOpenItemDetailsModal: false,
      };

    case OPEN_ITEM_DETAILS_MODAL_RESPOND:
      return {
        ...state,
        respondOpenItemDetailsModal: true,
      };

    case OPEN_ITEM_DETAILS_MODAL_RESPOND_CANCEL:
      return {
        ...state,
        respondOpenItemDetailsModal: false,
      };

    case OPEN_ITEM_FORM_ATTR_SET:
      return {
        ...state,
        openItemForm: {
          ...state.openItemForm,
          [action.payload.attr]: action.payload.value,
        },
      };

    case OPEN_ITEM_FORM_ERRORS_SET:
      return {
        ...state,
        openItemFormErrors: action.payload,
      };

    case OPEN_ITEM_FORM_ERRORS_ATTR_CLEAR:
      return {
        ...state,
        openItemFormErrors: {
          ...state.openItemFormErrors,
          [action.payload]: false,
        },
      };

    case OPEN_ITEM_FORM_SET:
      return {
        ...state,
        openItemForm: action.payload,
      };

    case OPEN_ITEM_FORM_RESET:
      return {
        ...state,
        openItemForm: initialState.openItemForm,
        openItemFormErrors: initialState.openItemFormErrors,
      };

    case OPEN_ITEM_PDF_VIEWER_SET:
      return {
        ...state,
        openItemPdfViewer: action.payload,
      };

    default:
      return state;
  }
}
