import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes, { bool } from 'prop-types';
import { push } from 'react-router-redux';
import './ChatActivity.css';
import SimpleTable from 'components/shared/table/SimpleTable';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import RfiModalContainer from 'components/admin/projects/details/project-rfi-log/RfiModalContainer';
import ModalRfa from 'components/admin/projects/details/project-rfa-log/ModalRfa';
import SubmittalModalContainer from 'components/admin/projects/details/project-submittal-log/SubmittalModalContainer';
import ProjectOpenItemDetailsModal from 'components/admin/projects/details/project-open-items/ProjectOpenItemDetailsModal';
import { getCurrentUser } from 'selectors/authentication';
import { getDarkMode } from 'selectors/theme';
import { getChatActivity } from 'components/admin/chats/store/selectors';
import { loadProjectRequest } from 'components/admin/projects/details/store/actions';
import { openRfi } from 'components/admin/projects/details/project-rfi-log/store/actions';
import { openRfa } from 'components/admin/projects/details/project-rfa-log/store/actions';
import { openSubmittal } from 'components/admin/projects/details/project-submittal-log/store/actions';
import {
  loadOpenItem,
  setOpenItemsCritical,
} from 'components/admin/projects/details/project-open-items/store/actions';
import {
  chatActivityRequest,
  markRead,
} from 'components/admin/chats/store/actions';
import Date from 'components/shared/text/Date';
import PreviewPopover from 'components/shared/preview-popover/PreviewPopover';
import TextTruncate from 'react-text-truncate';

class ChatActivity extends Component {
  static propTypes = {
    filters: PropTypes.shape(),
    darkMode: bool,
  };

  static defaultProps = {
    filters: { per_page: 5 },
  };

  constructor(props) {
    super(props);

    this.state = { showModal: null };
  }

  componentDidMount() {
    this.loadChatActivity(1);
  }

  scrollToTop = () => {
    if (this.chatActContentEl) {
      this.chatActContentEl.scrollTop = 0;
    }
  };

  loadChatActivity = (page) => {
    this.currentPage = page;
    const { filters, dispatch, currentProject } = this.props;
    const params = { ...filters, page };
    if (currentProject) {
      params.by_project = currentProject.id;
    }
    dispatch(chatActivityRequest(params)).then(
      (response) => response.ok && this.scrollToTop()
    );
  };

  closeModal = () => {
    this.setState({ showModal: null });
  };

  openModal = (chat) => {
    const { currentUser, dispatch } = this.props;
    const subject = chat.notifiable;
    this.setState({ showModal: chat.notifiable_type });
    dispatch(loadProjectRequest(subject.project_id));
    switch (chat.notifiable_type) {
      case 'Rfi':
        return dispatch(openRfi(subject.project_id, subject.id));

      case 'Rfa':
        return dispatch(openRfa(subject.project_id, subject.id));

      case 'Submittal':
        return dispatch(
          openSubmittal(subject.project_id, subject.id, currentUser)
        );

      case 'ProjectOpenItem':
        dispatch(setOpenItemsCritical(subject.critical));
        return dispatch(loadOpenItem(subject.project_id, subject.id));

      default:
        return null;
    }
  };

  open = (chat) => {
    const { dispatch } = this.props;
    const subject = chat.notifiable;
    switch (chat.notifiable_type) {
      case 'Rfi':
      case 'Rfa':
      case 'Submittal':
      case 'ProjectOpenItem':
        return this.openModal(chat);

      case 'WorkOrder':
        return dispatch(push(`/admin/work-orders/${subject.id}`));

      case 'Task':
        return dispatch(
          push(`/admin/work-orders/${subject.work_order_id}/task/${subject.id}`)
        );

      default:
        return null;
    }
  };

  respond = (id) => {
    const { chatActivity, dispatch } = this.props;
    const chat = chatActivity.activity.find((chat) => chat.id === id);
    if (this.isRead(chat)) {
      return this.open(chat);
    }
    dispatch(markRead(chat.id, true)).then((response) => {
      if (response.ok) {
        this.loadChatActivity(this.currentPage);
        this.open(chat);
      }
    });
  };

  isRead = (chat) => {
    return !!chat.read_at;
  };

  read = (id) => {
    const { chatActivity, dispatch } = this.props;
    const chat = chatActivity.activity.find((chat) => chat.id === id);
    dispatch(markRead(chat.id, !this.isRead(chat))).then(
      (response) => response.ok && this.loadChatActivity(this.currentPage)
    );
  };

  handleGenerateLink = (id) => {
    const { chatActivity } = this.props;
    const chat = chatActivity.activity.find((chat) => chat.id === id);
    const subject = chat.notifiable;
    switch (chat.notifiable_type) {
      case 'Rfi':
        return `projects/${subject.project_id}?rfiId=${subject.id}`;

      case 'Rfa':
        return `projects/${subject.project_id}?rfaId=${subject.id}`;

      case 'Submittal':
        return `projects/${subject.project_id}?submittalId=${subject.id}`;

      case 'ProjectOpenItem':
        return `projects/${subject.project_id}?projectOpenItemId=${subject.id}`;

      case 'WorkOrder':
        return `work-orders/${subject.id}`;

      case 'Task':
        return `work-orders/${subject.work_order_id}/task/${subject.id}`;

      default:
        return null;
    }
  };

  subText = (title, maxLength = 30) => {
    return title.length > maxLength
      ? `${title.substring(0, maxLength)}...`
      : title;
  };

  getUserName = (chat, short) => {
    const { user } = chat;
    return `${user.first_name} ${
      user.last_name && short
        ? `${user.last_name[0]}.`
        : user.last_name
        ? user.last_name
        : ''
    }`;
  };

  buildUserName = (chat) => {
    return this.subText(this.getUserName(chat, true));
  };

  buildDate = (chat) => {
    const { created_at } = chat;
    return this.subText(<Date value={created_at} format={'MM/DD'} />);
  };

  buildRespondItem = (chat) => {
    const { id } = chat;
    return (
      <div
        onClick={() => {
          this.respond(id);
        }}
        className="clickable"
      >
        Respond
      </div>
    );
  };

  buildMarkAsReadItem = (chat) => {
    const { id } = chat;
    return (
      <div className="clickable" onClick={() => this.read(id)}>
        {this.isRead(chat) ? 'Mark As New' : 'Mark As Read'}
      </div>
    );
  };

  getOwner = (chat) => {
    const subject = chat.notifiable;
    switch (chat.notifiable_type) {
      case 'Rfi':
        return `RFI #${subject.number} - ${subject.project_name}`;

      case 'Rfa':
        return `RFA #${subject.number} - ${subject.project_name}`;

      case 'Submittal':
        return `Submittal #${subject.division}-${subject.number} - ${subject.project_name}`;

      case 'ProjectOpenItem':
        return `Open Item #${subject.number}`;

      case 'WorkOrder':
        return `WO #${subject.number} ${
          subject.name ? `- ${subject.name}` : ''
        }`;

      case 'Task':
        return `WO #${subject.wo_number} Task ${subject.name}`;

      default:
        return '';
    }
  };

  getTitle = (chat) => {
    const subject = chat.notifiable;
    switch (chat.notifiable_type) {
      case 'Rfi':
      case 'Rfa':
      case 'Submittal':
        return subject.title;
      case 'ProjectOpenItem':
        return subject.description;
      default:
        return '';
    }
  };

  getContent = (chat) => {
    return chat.content;
  };

  buildSubject = (chat) => {
    const title = this.getTitle(chat);
    const owner = this.getOwner(chat);
    const content = this.getContent(chat);
    const userName = this.getUserName(chat);
    const date = this.buildDate(chat);

    const subject = `${this.subText(owner)} - ${this.subText(content)}`;

    const popoverContent = (
      <div>
        {title && <div>{title}</div>}
        <div>{content}</div>
      </div>
    );

    return (
      <PreviewPopover
        id={chat.id}
        title={owner}
        subtitle={userName}
        date={date}
        content={popoverContent}
        button={<TextTruncate line={2} text={subject} />}
        float="left"
      />
    );
  };

  buildProject = (chat) => {
    return chat.notifiable.project_name || '-';
  };

  mapActivity = () => {
    const { chatActivity, currentProject } = this.props;
    return chatActivity.activity.map((chat) => {
      return {
        id: chat.id,
        className: this.isRead(chat) ? 'read' : 'unread',
        data: [
          this.buildDate(chat),
          this.buildUserName(chat),
          !currentProject && this.buildProject(chat),
          this.buildSubject(chat),
          this.buildRespondItem(chat),
          this.buildMarkAsReadItem(chat),
        ].filter((column) => column),
      };
    });
  };

  render() {
    const { chatActivity, darkMode, currentProject } = this.props;
    const { showModal } = this.state;
    return (
      chatActivity && (
        <div
          className={`activity-container chat-activity ${
            darkMode ? 'dark-mode' : ''
          }`}
        >
          <div className="activity-header">
            <div className="activity-title">Chat</div>
            <div className="activity-total">{chatActivity.total}</div>
          </div>
          <div
            className="activity-content"
            ref={(el) => (this.chatActContentEl = el)}
          >
            <SimpleTable
              headers={[
                'DATE',
                'FROM',
                !currentProject && 'PROJECT',
                'ACTIVITY',
                'ACTIONS',
                ' ',
              ].filter((header) => header)}
              rows={this.mapActivity()}
              emptyMessage="No Activity"
              onGenerateLink={this.handleGenerateLink}
              stickyHeader={true}
            />
          </div>
          <div className="pagination-container">
            <SimplePagination
              currentPage={chatActivity.currentPage}
              pageSize={chatActivity.pageSize}
              total={chatActivity.total}
              onPageChange={this.loadChatActivity}
            />
          </div>

          {showModal === 'Rfi' && (
            <RfiModalContainer onClose={this.closeModal} />
          )}

          {showModal === 'Rfa' && <ModalRfa onClose={this.closeModal} />}
          {showModal === 'Submittal' && (
            <SubmittalModalContainer onClose={this.closeModal} />
          )}
          {showModal === 'ProjectOpenItem' && (
            <ProjectOpenItemDetailsModal onClose={this.closeModal} />
          )}
        </div>
      )
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    chatActivity: getChatActivity(state),
    darkMode: getDarkMode(state),
  };
})(ChatActivity);
