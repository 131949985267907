import React from 'react';
import PropTypes from 'prop-types';

export default function NavigationItem(props) {
  const handleOnSelected = (event) => {
    event.preventDefault();
    event.stopPropagation();
    props.onSelected(props.id);
  };

  return (
    <div
      className={
        'navigation-item' +
        (props.selected ? ' selected' : '') +
        (props.itemClass ? ` ${props.itemClass}` : '')
      }
      onClick={handleOnSelected}
    >
      <span>{props.title}</span>
      {props.total !== null && (
        <span className="navigation-item-total">{props.total}</span>
      )}
    </div>
  );
}

NavigationItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selected: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelected: PropTypes.func.isRequired,
};

NavigationItem.defaultProps = {
  selected: false,
  total: null,
};
