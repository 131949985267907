import axios from 'axios';
import BaseApi from './base-api';

class TimezoneApi extends BaseApi {
  getTimezoneByLatLong(latitude, longitude) {
    return axios
      .get(
        this.baseUrl +
          '/timezones/timezone?lat=' +
          latitude +
          '&lon=' +
          longitude
      )
      .then((response) => {
        return {
          ok: true,
          timezone_abbreviation: response.data.timezone_abbreviation,
          timezone_name: response.data.timezone_name,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export default TimezoneApi = new TimezoneApi();
