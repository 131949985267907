const StampActions = [
  {
    action: 'approved',
    title: 'No Exceptions Taken/Approved',
  },
  {
    action: 'approved_as_noted',
    title: 'Corrections Required as Noted',
    subtitle:
      'Resubmittal only required if Contractor cannot comply with corrections.',
  },
  {
    action: 'revise',
    title: 'Revise and Resubmit as Noted',
  },
  {
    action: 'rejected',
    title: 'Rejected',
  },
  {
    action: 'not_reviewed',
    title: 'Not Reviewed',
    subtitle: 'Submittal not required.',
  },
  {
    action: 'for_record',
    title: 'For Record Only',
    subtitle: 'Submittal not reviewed.',
  },
];

export default StampActions;
