import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import NavigationBar from 'components/shared/navigation/NavigationBar';
import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

import { SCHEDULE_TABS, DEFAULT_SCHEDULE_TAB } from 'domain/schedule';

import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import { getProjectScheduleFilters } from './store/selectors';
import {
  loadProjectSchedules,
  loadProjectSchedulesLog,
  toggleFormModalProjectSchedules,
} from './store/actions';

import SchedulesTable from './SchedulesTable';
import './ProjectSchedule.css';
import ScheduleSearchFilter from './ScheduleSearchFilter';
import Schedules from './Schedules';
import ProjectScheduleFormModal from './ProjectScheduleFormModal';
import ProjectScheduleMessageModal from './ProjectScheduleMessageModal';
import ProjectScheduleEmailModal from './ProjectScheduleEmailModal';
import ProjectSchedulePdfModal from './ProjectSchedulePdfModal';
import ProjectScheduleResolvedModal from './ProjectScheduleResolvedModal';
import { INITIAL_SCHEDULE_FILTERS } from './store/reducers.ui';

const getTabValueFromSubsection = (subsection) => subsection || 'schedules';

const getSubsectionFromTabValue = (tabValue) => tabValue || 'schedules';

const ProjectSchedule = (props) => {
  const {
    dispatch,
    renderHeaders,
    filters,
    currentProject,
    directory,
    match,
    history,
  } = props;
  const { projectId, subsection } = match.params;
  const tabValue = getTabValueFromSubsection(subsection);

  useEffect(() => {
    handleFilterChange({ tab: tabValue });
    loadDirectory();
  }, [currentProject, tabValue]);

  const loadDirectory = () => {
    if (!directory || directory.project_id !== currentProject.id)
      dispatch(loadDirectoryRequest(currentProject.id));
  };

  // filters
  const handleFilterChange = (newFilters = {}) => {
    let params;

    if (newFilters.tab) params = { ...INITIAL_SCHEDULE_FILTERS, ...newFilters };
    else {
      params = { ...filters, ...newFilters };
      // remove other sort params if new one is added
      const sortKey = Object.keys(newFilters).find((k) => k.includes('sort'));
      if (sortKey && newFilters[sortKey] && newFilters[sortKey] !== 'all') {
        Object.keys(params).forEach((p) => {
          if (p.includes('sort') && p !== sortKey) params[p] = null;
        });
      }
    }
    if (currentProject)
      dispatch(loadProjectSchedules(currentProject.id, params));
  };

  // header buttons
  const handleAdd = () => {
    dispatch(toggleFormModalProjectSchedules(true));
  };

  const handleViewLog = () => {
    dispatch(loadProjectSchedulesLog(currentProject.id, filters));
  };

  const buildHeaderButtons = () => {
    const res = [
      <ScheduleSearchFilter
        key="search-filter"
        handleFilterChange={handleFilterChange}
      />,
    ];

    if (filters.tab === DEFAULT_SCHEDULE_TAB) {
      res.push(
        <Button
          key="add-schedule"
          type={BUTTON_TYPES.LINK}
          icon={BUTTON_ICONS.PLUS}
          label="Add Schedule"
          onClick={handleAdd}
        />
      );
    } else {
      res.push(
        <Button
          key="view-log"
          type={BUTTON_TYPES.LINK}
          color={BUTTON_COLORS.GREEN}
          label="View Log"
          onClick={handleViewLog}
        />
      );
    }
    return res;
  };

  // tabs
  const handleTabChange = (tab) => {
    const newSubsection = getSubsectionFromTabValue(tab);
    history.push(`/admin/projects/${projectId}/schedule/${newSubsection}`);
    handleFilterChange({ tab });
  };
  const buildTabs = () => {
    return SCHEDULE_TABS.map((t) => ({
      ...t,
      class: t.id === filters.tab ? 'sel' : '',
    }));
  };

  return (
    <div className="project-schedule content-container">
      {renderHeaders(buildHeaderButtons())}
      <NavigationBar
        items={buildTabs()}
        onSelected={handleTabChange}
        selectedValue={filters.tab}
      />
      <div className="table-container">
        {filters.tab === DEFAULT_SCHEDULE_TAB ? (
          <Schedules />
        ) : (
          <SchedulesTable handleFilterChange={handleFilterChange} />
        )}
      </div>
      <ProjectScheduleFormModal />
      <ProjectScheduleMessageModal />
      <ProjectScheduleEmailModal />
      <ProjectSchedulePdfModal />
      <ProjectScheduleResolvedModal />
    </div>
  );
};

export default connect((state) => ({
  filters: getProjectScheduleFilters(state),
  currentProject: getCurrentProject(state),
  directory: getDirectory(state),
}))(withRouter(ProjectSchedule));
