import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getDarkMode } from 'selectors/theme';
import Project from 'domain/project';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import Date from 'components/shared/text/Date';
import SimpleTable from 'components/shared/table/SimpleTable';
import FilterHeader from 'components/shared/table/FilterHeader';
import PdfViewer from 'components/shared/pdf-viewer/PdfViewer';
import ProjectReportErrorModal from 'components/admin/projects/details/project-daily-report-log/ProjectReportErrorModal';
import './FieldProjectReportLogTable.css';
import {
  deleteProjectReport,
  setProjectReportOpenDetails,
  sendToEmailProjectReport,
  reloadProjectReportRequest,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';
import { loadEmailHistory } from 'components/admin/projects/details/project-email-modal/store/actions';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getprojectReportsPermissions } from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import { getHistory } from 'components/admin/projects/details/project-email-modal/store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import ProjectReportSendEmailModal from 'components/admin/projects/details/project-daily-report-log/ProjectReportSendEmailModal';
import FieldReportPermissions from 'permissions/field-report-permissions';

class FieldProjectReportLogTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterDate: 'all',
      filterSubmit: 'all',
      showEmailsModal: false,
      projectReportId: null,
      showPdf: false,
      urlPdf: '',
      reportNumber: null,
      showErrorModal: false,
    };
  }

  componentDidMount() {
    this.loadDirectory();
  }

  loadDirectory = () => {
    const { project, directory, dispatch } = this.props;
    if (!directory || directory.project_id !== project.id) {
      dispatch(loadDirectoryRequest(project.id));
    }
  };

  status = (report) => {
    if (report.status === 'draft') {
      return <div className="report-status draft">Draft</div>;
    } else if (report.status === 'published') {
      return (
        <div className="report-status draft">
          {`Published ${moment(report.published_at).format('MM/DD/YYYY')}`}
        </div>
      );
    } else if (report.status === 'reviewed') {
      return <div className="report-status draft">Issued For Review</div>;
    }
  };

  handleDeleteProjectReport = (e, projectReportId) => {
    const { dispatch, project } = this.props;
    dispatch(deleteProjectReport(project.id, projectReportId));
    e.stopPropagation();
  };

  handleViewPdf = (e, report) => {
    report.pdf &&
      this.setState({
        showPdf: true,
        urlPdf: report.pdf.document.url,
      });
    e.stopPropagation();
  };

  handleEmail = (e, report) => {
    this.setState({
      reportNumber: report.number,
      showEmailsModal: true,
      projectReportId: report.id,
    });
    e.stopPropagation();
  };

  handleFilterChange = (filter, filterState) => {
    if (filter !== 'all') {
      const filterString =
        filterState !== 'all'
          ? `?${filter}=true&${filterState}=true`
          : `?${filter}=true`;
      this.props.onLoad(filterString);
    } else {
      const filterString = filterState !== 'all' ? `?${filterState}=true` : ``;
      this.props.onLoad(filterString);
    }
  };

  clickPdfButton = (e, report, isRetry = false) => {
    const { project, dispatch } = this.props;

    if (!report.pdf) {
      if (!isRetry) {
        dispatch(reloadProjectReportRequest(project.id, report.id)).then(
          (response) => {
            this.clickPdfButton(e, response.data || report, true);
          }
        );
      } else {
        this.setState({ showErrorModal: true });
      }
    } else {
      this.handleViewPdf(e, report);
    }
  };

  getHistoryEmails = () => {
    const { dispatch } = this.props;
    const { projectReportId } = this.state;
    dispatch(loadEmailHistory(projectReportId, 'ProjectReport'));
  };

  recipientEmails = () => {
    const { projectReportPermissions: permissions } = this.props;
    permissions.push('oar');
    return Project.filterReportRecipients({
      directory: this.props.directory,
      permissions,
      type: 'email',
    });
  };

  selectedRow = (index) => {
    const { fieldProjectReports, dispatch, currentUser, project } = this.props;
    const report = fieldProjectReports[index];
    FieldReportPermissions.canOpen(currentUser, project, report)
      ? report.status !== 'published' &&
        dispatch(
          setProjectReportOpenDetails({
            value: true,
            id: report.id,
          })
        )
      : report.pdf &&
        this.setState({
          showPdf: true,
          urlPdf: report.pdf.document.url,
        });
  };

  viewButton = (report) => {
    const { currentUser, project } = this.props;
    return (
      <div className="row-block">
        {FieldReportPermissions.canSendEmail(currentUser, project, report) && (
          <React.Fragment>
            <Button
              type={BUTTON_TYPES.LINK}
              color={BUTTON_COLORS.GREEN}
              label="Email"
              onClick={(e) => this.handleEmail(e, report)}
            />
            <div className="buttons-separate" />
          </React.Fragment>
        )}
        {report.status === 'published' && (
          <React.Fragment>
            <Button
              type={BUTTON_TYPES.LINK}
              color={BUTTON_COLORS.GREEN}
              label="PDF"
              onClick={(e) => this.clickPdfButton(e, report)}
            />
            <div className="buttons-separate" />
          </React.Fragment>
        )}
        {FieldReportPermissions.canDelete(currentUser, project, report) && (
          <Button
            type={BUTTON_TYPES.LINK}
            color={BUTTON_COLORS.GREEN}
            label="Delete"
            onClick={(e) => this.handleDeleteProjectReport(e, report.id)}
          />
        )}
      </div>
    );
  };

  submittedBy = (user) => {
    const title = user.title ? ` - ${user.title}` : '';
    return user.label || `${user.first_name} ${user.last_name}${title}`;
  };

  mapProjectReports = () => {
    const { fieldProjectReports, currentUser, project } = this.props;
    let data = fieldProjectReports;
    if (!FieldReportPermissions.canViewDraft(currentUser, project)) {
      data = data.filter((report) => report.status !== 'draft');
    }
    return data.map((report) => [
      report.number < 10 ? `0${report.number}` : report.number,
      report.date ? <Date value={report.date} format="MM/DD/YYYY" /> : '-',
      report.submitted_by ? this.submittedBy(report.submitted_by) : '-',
      this.status(report),
      this.viewButton(report),
    ]);
  };

  render() {
    const { project, dispatch, currentUser, history } = this.props;
    const {
      filterDate,
      filterSubmit,
      projectReportId,
      showEmailsModal,
      showPdf,
      urlPdf,
      reportNumber,
      showErrorModal,
    } = this.state;
    const emails = [
      { id: currentUser.id, label: currentUser.email },
      ...(this.recipientEmails() || []),
    ];
    const headers = [
      'REPORT NO',
      <FilterHeader
        label="REPORT DATE"
        options={[
          { id: 'all', label: 'All' },
          { id: 'date_desc', label: 'Newest' },
          { id: 'date_asc', label: 'Oldest' },
        ]}
        selected={filterDate}
        onFilter={(filter) => {
          this.handleFilterChange(filter, filterSubmit);
          this.setState({ filterDate: filter });
        }}
      />,
      <FilterHeader
        label="SUBMITTED BY"
        options={[
          { id: 'all', label: 'All' },
          { id: 'submitted_by_asc', label: 'A-Z' },
          { id: 'submitted_by_desc', label: 'Z-A' },
        ]}
        selected={filterSubmit}
        onFilter={(filter) => {
          this.handleFilterChange(filter, filterDate);
          this.setState({ filterSubmit: filter });
        }}
      />,
      'STATUS',
      'ACTIONS',
    ];

    return (
      <React.Fragment>
        <SimpleTable
          className={classnames({
            'simple-table': true,
            'projects-table': true,
            'field-project-report-log-table': true,
          })}
          headers={headers}
          rows={this.mapProjectReports()}
          emptyMessage="No Project Reports"
          onRowSelected={this.selectedRow}
        />
        <ProjectReportSendEmailModal
          emails={emails}
          show={showEmailsModal}
          onClose={() => this.setState({ showEmailsModal: false })}
          title="Send Email"
          project={project}
          reportNumber={reportNumber}
          getHistoryEmails={this.getHistoryEmails}
          history={history}
          onSend={(emails, subject, message) =>
            dispatch(
              sendToEmailProjectReport(
                project.id,
                projectReportId,
                emails,
                subject,
                message
              )
            )
          }
        />
        <PdfViewer
          show={showPdf}
          content={{ source: urlPdf, type: 'url' }}
          onClose={() => this.setState({ showPdf: false })}
        />
        <ProjectReportErrorModal
          show={showErrorModal}
          error="Your report is being prepared, please try again in a few minutes."
          title="PDF"
          onClose={() => this.setState({ showErrorModal: false })}
        />
      </React.Fragment>
    );
  }
}

FieldProjectReportLogTable.propTypes = {
  fieldProjectReports: PropTypes.arrayOf(PropTypes.shape({})),
  project: PropTypes.shape().isRequired,
  darkMode: PropTypes.bool.isRequired,
  onLoad: PropTypes.func.isRequired,
};

FieldProjectReportLogTable.defaultProps = {
  fieldProjectReports: [],
};

export default connect(
  (state) => ({
    darkMode: getDarkMode(state),
    directory: getDirectory(state),
    project: getCurrentProject(state),
    projectReportPermissions: getprojectReportsPermissions(state),
    currentUser: getCurrentUser(state),
    history: getHistory(state),
  }),
  null
)(FieldProjectReportLogTable);
