import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import './TooltipControl.css';

class TooltipControl extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    control: PropTypes.element.isRequired,
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
      .isRequired,
    placement: PropTypes.string,
    position: PropTypes.string,
    size: PropTypes.string,
  };

  static defaultProps = {
    id: 'tooltip-control',
    className: '',
    placement: 'bottom',
    position: 'left',
    size: 'medium',
  };

  render() {
    const {
      id,
      className,
      control,
      tooltip,
      placement,
      position,
      size,
      darkMode,
    } = this.props;

    return (
      <OverlayTrigger
        placement={placement}
        overlay={
          <Tooltip
            id={id}
            className={`tooltip-control ${className} ${position} ${size} ${
              darkMode ? 'dark-mode' : ''
            }`}
          >
            {tooltip}
          </Tooltip>
        }
      >
        {control}
      </OverlayTrigger>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(TooltipControl);
