import React, { Component } from 'react';
import Time from 'components/shared/text/Time';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import ProjectOpenItemReview from 'components/admin/projects/details/project-daily-report-log/ProjectOpenItemReview';
import {
  setMemberForm,
  setEditMode,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';
import LogoUpload from 'components/shared/logo-upload/LogoUpload';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import ReadOnlyInput from 'components/shared/input/ReadOnlyInput';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';

class ReviewFormForStaff extends Component {
  editMember = () => {
    this.props.dispatch(setMemberForm(true));
    this.props.dispatch(setEditMode(true));
    this.props.clearErrorOnStaff();
    this.props.clearErrorOnOpenItem();
  };

  membersToList = () => {
    const { members } = this.props;
    const membersToList = [];
    members.forEach((member) => {
      membersToList.push({
        ...member,
        isChildFloor: !!membersToList.find(
          (member2) => member2.user_id === member.user_id
        ),
      });
    });

    return membersToList;
  };

  renderMember = (member, child) => {
    return (
      <React.Fragment key={member.id}>
        <DetailsTitle
          className="modal-form-subtitle"
          title={
            <React.Fragment>
              {(member.floor || member.area) && (
                <p>
                  {member.floor && `Floor ${member.floor}`}
                  {member.floor && member.area && ' - '}
                  {member.area || ''}
                </p>
              )}
              <p>{`${member.user.first_name} ${member.user.last_name}`}</p>
            </React.Fragment>
          }
          rightButtons={
            !this.props.readOnly && !child
              ? [
                  <Button
                    key="edit"
                    type={BUTTON_TYPES.LINK}
                    color={BUTTON_COLORS.GREEN}
                    label="Edit"
                    onClick={this.editMember}
                  />,
                ]
              : null
          }
        />
        <div className="form-block multicontrols-block">
          <FormControlBlock
            label="Hours"
            control={<ReadOnlyInput value={member.hours_worked || 0} />}
          />
          {member.started_at && (
            <FormControlBlock
              label="Started At"
              control={
                <ReadOnlyInput
                  value={
                    <Time
                      className="small-regular review-form-date-span"
                      value={member.started_at}
                      format="LT"
                    />
                  }
                />
              }
            />
          )}
          {member.ended_at && (
            <FormControlBlock
              label="Ended At"
              control={
                <ReadOnlyInput
                  value={
                    <Time
                      className="small-regular review-form-date-span"
                      value={member.ended_at}
                      format="LT"
                    />
                  }
                />
              }
            />
          )}
        </div>
        {((member.supervisor_tasks && member.supervisor_tasks.length > 0) ||
          member.supervisor_task_other) && (
          <div className="form-block">
            <FormControlBlock
              label="Tasks Performed"
              control={
                <ul className="form-items-list small-regular">
                  {member.supervisor_tasks.length > 0 &&
                    member.supervisor_tasks.map((task) => (
                      <li key={task.id}>{task.label}</li>
                    ))}
                  {member.supervisor_task_other && (
                    <li>{member.supervisor_task_other}</li>
                  )}
                </ul>
              }
            />
          </div>
        )}
        {member.comments && !this.props.readOnly && (
          <div className="form-block">
            <FormControlBlock
              label="Comments"
              control={
                <TextareaAutosizeWrapper
                  initialValue={member.comments}
                  disabled={true}
                />
              }
            />
          </div>
        )}
      </React.Fragment>
    );
  };

  render() {
    const {
      members,
      generalContactorLabel,
      openItem,
      errorsForStaff,
      errorsForOpenItem,
      images,
    } = this.props;
    const membersToList = members && members.length > 0 && this.membersToList();
    return (
      <div className="review-form-trade">
        <DetailsTitle
          className="modal-form-title"
          title={generalContactorLabel}
          valid={!errorsForStaff && !errorsForOpenItem}
        />
        {members && members.length > 0 ? (
          membersToList
            .filter((member) => !member.isChildFloor)
            .map((member, index) => {
              return (
                <React.Fragment key={index}>
                  {this.renderMember(member)}
                  {membersToList
                    .filter(
                      (member2) =>
                        member2.isChildFloor &&
                        member2.user_id === member.user_id
                    )
                    .map((member2) => this.renderMember(member2, true))}
                </React.Fragment>
              );
            })
        ) : (
          <React.Fragment>
            <DetailsTitle
              className="modal-form-subtitle"
              title="Staff Member"
              rightButtons={
                !this.props.readOnly
                  ? [
                      <Button
                        key="edit"
                        type={BUTTON_TYPES.LINK}
                        color={BUTTON_COLORS.GREEN}
                        label="Edit"
                        onClick={this.editMember}
                      />,
                    ]
                  : null
              }
            />
            <div className="form-block multicontrols-block">
              <FormControlBlock
                label="Hours"
                control={<ReadOnlyInput value={0} />}
              />
            </div>
          </React.Fragment>
        )}
        {images && images.length > 0 && (
          <div className="form-resources-block">
            <div className="form-images">
              <FormControlBlock
                className="without-focus"
                label="Photos"
                control={
                  <div className="form-resources-body">
                    <LogoUpload
                      readOnly
                      logos={images.map((image) => ({
                        preview: image.image.gallery.url,
                        url: image.image.url,
                      }))}
                    />
                  </div>
                }
              />
            </div>
          </div>
        )}
        {openItem && <ProjectOpenItemReview openItem={openItem} />}
      </div>
    );
  }
}

export default ReviewFormForStaff;
