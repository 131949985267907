import {
  RFAS_SET,
  RFA_SET,
  RFA_LOG_PDF_SET,
  RFA_DELETE,
  RFA_NOTES_SET,
  RFA_NOTE_ADD,
  RFA_FORM_SET,
  RFA_FORM_RESET,
  RFA_DATA_CHANGED_SET,
} from 'components/admin/projects/details/project-rfa-log/store/action-types';
import {
  DEFAULT_STATUS_OPTION,
  DEFAULT_DUE_OPTION,
  DEFAULT_PRE_OPTION,
} from 'domain/rfa';

const initialState = {
  rfas: [],
  total: 0,
  currentRfa: null,
  logPdf: null,
  logPdfType: 'url',
  dataChanged: false,
  filters: {
    due: DEFAULT_DUE_OPTION,
    page: 1,
    status: DEFAULT_STATUS_OPTION,
    search: '',
    pageSize: 25,
    pre: DEFAULT_PRE_OPTION,
    number_order: 'all',
    company_number_order: 'all',
    title_order: 'all',
    submitted_order: 'all',
    reviewed_order: 'all',
    resolved_order: 'all',
    accelerate: false,
    for_record: false,
    by_request_type: null,
    by_submitted_user: [],
    by_response_user: [],
    by_due_to: null,
    by_due_from: null,
    by_submitted_date_to: null,
    by_submitted_date_from: null,
  },
  rfaNotes: [],
  form: {
    status: null,
    formatted_status: null,
    request_type: null,
    request_title: '',
    request_description: '',
    due_date: null,
    csi_code_id: null,
    csi_code_label: '00',
    submitted_by: null,
    submitted_at: null,
    assigned_to: null,
    ccs: [],
    addl_recipients: [],
    request_documents: [],
    request_images: [],
    deleted_request_documents: [],
    deleted_request_images: [],

    response_code: null,
    formatted_response_code: null,
    response_description: '',
    responded_by: null,
    responded_at: null,
    responded_at_date: null,
    responded_at_time: null,
    accelerated_by: null,
    accelerated_at: null,
    accelerated: false,
    response_method: null,
    formatted_response_method: null,
    response_method_description: '',
    response_documents: [],
    response_images: [],
    deleted_response_documents: [],
    deleted_response_images: [],

    review_description: null,
    reviewed_by: null,
    reviewed_at: null,
    review_documents: [],
    review_images: [],
    deleted_review_documents: [],
    deleted_review_images: [],

    errors: null,
  },
};

export default function projectRfaLogDataReducer(state = initialState, action) {
  switch (action.type) {
    case RFAS_SET:
      return {
        ...state,
        ...action.payload,
      };

    case RFA_LOG_PDF_SET:
      return {
        ...state,
        logPdf: action.payload.logPdf,
        logPdfType: action.payload.logPdfType,
      };

    case RFA_DATA_CHANGED_SET:
      return { ...state, dataChanged: action.payload };

    case RFA_SET:
      return {
        ...state,
        currentRfa: action.payload.currentRfa,
      };

    case RFA_DELETE:
      return {
        ...state,
        rfas: state.rfas.filter((rfa) => rfa.id !== action.payload.rfaId),
      };

    case RFA_FORM_SET:
      return {
        ...state,
        form: { ...state.form, ...action.payload.values },
      };

    case RFA_FORM_RESET:
      return {
        ...state,
        form: { ...initialState.form, ...action.payload.values },
      };

    case RFA_NOTES_SET:
      return { ...state, rfaNotes: action.payload };

    case RFA_NOTE_ADD:
      return {
        ...state,
        rfaNotes: [...state.rfaNotes, action.payload],
      };

    default:
      return state;
  }
}
