import { showErrorMessage } from 'actions/messages';
import { notificationsApi } from 'services/api/notifications-api';
import {
  ALL_RECENT_ACTIVITY_LOAD,
  SPE_RECENT_ACTIVITY_LOAD,
  SW_RECENT_ACTIVITY_LOAD,
} from 'components/admin/recent-activity/store/action-types';

export function allRecentActivitySuccess(recentActivity) {
  return {
    type: ALL_RECENT_ACTIVITY_LOAD,
    payload: recentActivity,
  };
}

export function speRecentActivitySuccess(recentActivity) {
  return {
    type: SPE_RECENT_ACTIVITY_LOAD,
    payload: recentActivity,
  };
}

export function swRecentActivitySuccess(recentActivity) {
  return {
    type: SW_RECENT_ACTIVITY_LOAD,
    payload: recentActivity,
  };
}

export function recentActivityRequest(page, filters) {
  filters = {
    ...filters,
    recent_activity: true,
  };
  return (dispatch) => {
    return notificationsApi.getNotifications(page, filters).then((response) => {
      if (!response.ok) {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}
