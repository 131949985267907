import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import './DashboardNav.css';
import classnames from 'classnames';
import { simpleScrollToTop } from 'services/utils/scroller-util';
import { Dashboard } from 'domain/dashboard';
import { getCurrentUser } from 'selectors/authentication';
import { getRfisCount } from 'components/admin/rfis/store/selectors';
import { getSubmittalsCount } from 'components/admin/submittals/store/selectors';
import {
  getMissingDrsCount,
  getBidsDueCount,
} from 'components/admin/projects/store/selectors';
import {
  getProjectOpenItemsCount,
  getProjectOpenItemsCritCount,
} from 'components/admin/project-open-items/store/selectors';
import { getFieldWorkOrdersCount } from 'components/admin/field-work-orders/store/selectors';
import { rfisCount } from 'components/admin/rfis/store/actions';
import { submittalsCount } from 'components/admin/submittals/store/actions';
import {
  missingDrsCount,
  bidsDueCount,
} from 'components/admin/projects/store/actions';
import {
  projectOpenItemsCount,
  projectOpenItemsCriticalCount,
} from 'components/admin/project-open-items/store/actions';
import { fieldWorkOrdersCount } from 'components/admin/field-work-orders/store/actions';
import DashboardProjects from 'components/admin/dashboard/DashboardProjects';
import Rfis from 'components/admin/rfis/Rfis';
import Submittals from 'components/admin/submittals/Submittals';
import MissingDailyReports from 'components/admin/projects/MissingDailyReports';
import BidsDue from 'components/admin/projects/BidsDue';
import ProjectOpenItemsTabs from 'components/admin/project-open-items/ProjectOpenItemsTabs';
import FieldWorkOrders from 'components/admin/field-work-orders/FieldWorkOrders';

class DashboardNav extends Component {
  constructor(props) {
    super(props);

    const dashboard = new Dashboard(props.currentUser);

    this.perPage = dashboard.perPage;
    this.showMyProjects = dashboard.showMyProjects;

    this.filters = dashboard.filters;
    this.filtersDrs = dashboard.filtersDrs;
    this.filtersBidsDue = dashboard.filtersBidsDue;
    this.filtersQuotesBidsDue = dashboard.filtersQuotesBidsDue;
    this.filtersCritical = dashboard.filtersCritical;
    this.filtersFwos = dashboard.filtersFwos;

    this.state = {
      currentNav: 0,
      showBidsDue: false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(rfisCount(this.filters));
    dispatch(submittalsCount(this.filters));
    dispatch(projectOpenItemsCriticalCount(this.filtersCritical));
    dispatch(bidsDueCount(this.filtersBidsDue));
    dispatch(missingDrsCount(this.filtersDrs));
    dispatch(projectOpenItemsCount(this.filters));
    dispatch(fieldWorkOrdersCount(this.filtersFwos));
  }

  componentDidUpdate() {
    simpleScrollToTop();
    this.navContentDiv.scrollTop = 0;
  }

  renderTitle = () => {
    return 'DASHBOARD';
  };

  buildNavItems = () => {
    const {
      rfisCount,
      submittalsCount,
      missingDrsCount,
      projectOpenItemsCount,
      projectOpenItemsCritCount,
      fieldWorkOrdersCount,
      bidsDueCount,
    } = this.props;
    return [
      { count: rfisCount, title: 'OVERDUE RFIs' },
      { count: submittalsCount, title: 'OVERDUE SUBMITTALS' },
      { count: missingDrsCount, title: 'MISSING DAILY REPORTS' },
      {
        count: projectOpenItemsCount + projectOpenItemsCritCount,
        title: 'OVERDUE/CRITICAL ITEMS',
      },
      { count: fieldWorkOrdersCount, title: 'PENDING EWO TICKETS' },
      { count: bidsDueCount, title: 'BIDS DUE' },
    ].map((item) => {
      return (
        <React.Fragment>
          <div className="dashboard-nav-item-count">{item.count}</div>
          <div className="dashboard-nav-item-title">{item.title}</div>
        </React.Fragment>
      );
    });
  };

  buildContentItems = () => {
    return [
      <DashboardProjects
        perPage={this.perPage}
        showNav={!this.showMyProjects}
        showSw={false}
      />,
      <Rfis filters={this.filters} />,
      <Submittals filters={this.filters} />,
      <MissingDailyReports filters={this.filtersDrs} />,
      <ProjectOpenItemsTabs
        filters={this.filters}
        filtersCritical={this.filtersCritical}
      />,
      <FieldWorkOrders filters={this.filtersFwos} />,
      <BidsDue filters={this.filtersBidsDue} />,
    ];
  };

  handleNavClick = (nav) => {
    const { currentNav } = this.state;
    currentNav === nav
      ? this.setState({ currentNav: 0 })
      : this.setState({ currentNav: nav });
  };

  toggleBidsDue = () => {
    this.setState({ showBidsDue: !this.state.showBidsDue });
  };

  bidsDueButtonLabel = () => {
    const { bidsDueCount } = this.props;
    let label = '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0';

    return `Bids Due${label}(${bidsDueCount})`;
  };

  withDarkMode = (className) => {
    return `${className} ${this.props.darkMode ? 'dark-mode' : ''}`;
  };

  render() {
    const navItems = this.buildNavItems();
    const contentItems = this.buildContentItems();
    const { currentNav } = this.state;
    const title = this.renderTitle();
    return (
      <div className="dashboard-nav">
        <div className="dashboard-nav-header">
          <div className={this.withDarkMode('full-title')}>{title}</div>
        </div>
        <div className={this.withDarkMode('dashboard-nav-items')}>
          {navItems.map((item, index) => {
            return (
              <div
                key={index}
                className={this.withDarkMode(
                  classnames({
                    'dashboard-nav-item': true,
                    selected: currentNav === index + 1,
                  })
                )}
                onClick={() => this.handleNavClick(index + 1)}
              >
                {item}
              </div>
            );
          })}
        </div>
        <div
          className={this.withDarkMode('dashboard-nav-content')}
          ref={(ref) => (this.navContentDiv = ref)}
        >
          {contentItems.find((item, index) => currentNav === index)}
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      currentUser: getCurrentUser(state),
      rfisCount: getRfisCount(state),
      submittalsCount: getSubmittalsCount(state),
      missingDrsCount: getMissingDrsCount(state),
      bidsDueCount: getBidsDueCount(state),
      projectOpenItemsCount: getProjectOpenItemsCount(state),
      projectOpenItemsCritCount: getProjectOpenItemsCritCount(state),
      fieldWorkOrdersCount: getFieldWorkOrdersCount(state),
      darkMode: getDarkMode(state),
    };
  },
  null,
  null,
  { withRef: true }
)(DashboardNav);
