import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormModal from 'components/shared/modal/FormModal';
import SelectUsersForm from 'components/admin/projects/details/project-directory/SelectUsersForm';

class SelectUsersModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    users: PropTypes.array.isRequired,
    selectedUsers: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  render() {
    const {
      show,
      users,
      selectedUsers,
      handleSubmit,
      handleClose,
    } = this.props;
    return (
      <FormModal show={show} subTitle="Select Users" onClose={handleClose}>
        <SelectUsersForm
          initialValues={{ userIds: selectedUsers.map((usr) => usr.id) }}
          users={users}
          onSubmit={handleSubmit}
          onClose={handleClose}
        />
      </FormModal>
    );
  }
}

export default SelectUsersModal;
