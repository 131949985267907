export const LOADER_START = 'LOADER_START';
export const LOADER_END = 'LOADER_END';

export function loaderStart() {
  return {
    type: LOADER_START,
  };
}

export function loaderEnd() {
  return {
    type: LOADER_END,
  };
}
