import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Form, FormGroup } from 'react-bootstrap';
import './NewProjectLocation.css';
import Input from 'components/shared/input/Input';
import {
  getCurrentProjectLocation,
  getForm,
  getInvalidFields,
  getValidationMessage,
} from 'selectors/admin/project-locations/project-locations';
import {
  updateFormValue,
  submitFormRequest,
  updateFormFromCurrentProjectLocation,
  loadProjectLocationDetailsRequest,
} from 'actions/admin/project-locations/project-locations';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import { getDarkMode } from 'selectors/theme';

class NewProjectLocation extends Component {
  constructor(props) {
    super(props);
    this.editProjectLocation = false;
  }

  componentDidMount() {
    if (this.props.match.params.hasOwnProperty('id')) {
      // Editing the project location
      this.editProjectLocation = true;
      if (this.props.currentProjectLocation === null) {
        // If project location not loaded, load it
        this.props
          .dispatch(
            loadProjectLocationDetailsRequest(this.props.match.params.id)
          )
          .then(() =>
            this.props.dispatch(
              updateFormFromCurrentProjectLocation(
                this.props.currentProjectLocation
              )
            )
          );
      } else {
        this.props.dispatch(
          updateFormFromCurrentProjectLocation(
            this.props.currentProjectLocation
          )
        );
      }
    }
  }

  handleFormChange = (attribute, value) => {
    this.props.dispatch(updateFormValue(attribute, value));
  };

  handleSubmit = () => {
    this.props.dispatch(
      submitFormRequest(
        this.props.form,
        this.editProjectLocation
          ? this.props.currentProjectLocation.id
          : undefined
      )
    );
  };

  render() {
    const { darkMode } = this.props;

    return (
      <div className={`new-project-location ${darkMode ? 'dark-mode' : ''}`}>
        <DetailsTitle
          className="main-form-title"
          title={
            this.editProjectLocation
              ? 'EDIT PROJECT LOCATION'
              : 'ADD A PROJECT LOCATION'
          }
        />
        <Form className="content-container" horizontal>
          <DetailsTitle
            title="Location Information"
            rightButtons={[
              <Button
                key="save"
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.GREEN}
                label={
                  this.editProjectLocation ? 'Save Location' : 'Add Location'
                }
                onClick={this.handleSubmit}
              />,
            ]}
          />
          <FormGroup>
            <Col sm={4}>
              {this.renderField('Street Address', 'streetAddress')}
            </Col>
            <Col sm={4}>
              {this.renderField('Unit number / Floor (optional)', 'unitNumber')}
            </Col>
          </FormGroup>
          <FormGroup>
            <Col sm={4}>{this.renderField('City', 'city')}</Col>
            <Col sm={2}>{this.renderField('State', 'state')}</Col>
            <Col sm={2}>
              {this.renderField('Zip Code (optional)', 'zipCode')}
            </Col>
          </FormGroup>
          <FormGroup>
            <Col sm={4}>
              {this.renderField(
                'Location Name (optional)',
                'projectLocationName'
              )}
            </Col>
          </FormGroup>

          {this.props.validationMessage !== '' && (
            <div className="invalid-message">
              {this.props.validationMessage}
            </div>
          )}
        </Form>
      </div>
    );
  }

  renderField(placeholder, attribute) {
    return (
      <Input
        placeholder={placeholder}
        valid={
          this.props.invalidFields.find((element) => element === attribute) ===
          undefined
        }
        value={this.props.form[attribute]}
        onChange={this.handleFormChange.bind(this, attribute)}
      />
    );
  }
}

export default connect((state) => {
  return {
    currentProjectLocation: getCurrentProjectLocation(state),
    form: getForm(state),
    invalidFields: getInvalidFields(state),
    validationMessage: getValidationMessage(state),
    darkMode: getDarkMode(state),
  };
})(NewProjectLocation);
