import React from 'react';
import { connect } from 'react-redux';

import PdfViewer from 'components/shared/pdf-viewer/PdfViewer';

import {
  getRfaLogPdf,
  getShowRfaLogPdf,
  getRfaLogPdfType,
} from './store/selectors';
import { setRfaLogPdf } from './store/actions';

const ModalRfaLogPdf = (props) => {
  const { dispatch, showRfaLogPdf, rfaLogPdf, rfaLogPdfType } = props;
  const handleClose = () => dispatch(setRfaLogPdf(null, false));

  if (!showRfaLogPdf) return null;

  return (
    <PdfViewer
      show={showRfaLogPdf}
      onClose={handleClose}
      content={{
        source: rfaLogPdf,
        type: rfaLogPdfType,
      }}
    />
  );
};

export default connect((state) => {
  return {
    rfaLogPdf: getRfaLogPdf(state),
    rfaLogPdfType: getRfaLogPdfType(state),
    showRfaLogPdf: getShowRfaLogPdf(state),
  };
})(ModalRfaLogPdf);
