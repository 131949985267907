import { MESSAGES_ERROR_CLOSE, MESSAGES_ERROR_SHOW } from '../actions/messages';

let messageId = 1;

const initialState = {
  errors: [], // array of { id, message }
};

export default function messagesReducer(state = initialState, action) {
  switch (action.type) {
    case MESSAGES_ERROR_CLOSE:
      return {
        ...state,
        errors: state.errors.filter((element) => element.id !== action.payload),
      };

    case MESSAGES_ERROR_SHOW:
      let errors =
        (action.payload.data && action.payload.data.errors) || action.payload;
      if (!Array.isArray(errors)) errors = [];

      return {
        ...state,
        errors: state.errors.concat(
          errors.map((message) => {
            return {
              id: messageId++,
              message: message,
            };
          })
        ),
      };

    default:
      return state;
  }
}
