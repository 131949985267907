import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class Time extends Component {
  static propTypes = {
    className: PropTypes.string,
    format: PropTypes.string,
    value: PropTypes.string.isRequired,
  };

  render() {
    let time;
    let tMoment = moment(this.props.value);
    if (tMoment.isValid()) {
      time = moment
        .utc(this.props.value)
        .format(this.props.format ? this.props.format : 'hh:mm A');
    } else {
      time = 'Invalid time';
    }

    return (
      <span
        className={
          this.props.className !== undefined ? this.props.className : null
        }
      >
        {time}
      </span>
    );
  }
}

export default Time;
