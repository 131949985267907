import axios from 'axios';
import numeral from 'numeral';
import moment from 'moment';
import BaseApi from './base-api';

class ProjectReportsApi extends BaseApi {
  getProjectReportsPdf(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/project_report_log/print`, {
        responseType: 'blob',
      })
      .then((response) => {
        return {
          ok: true,
          projectReportsLogPdf: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getProjectReports(projectId, filter, currentPage = 1) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/project_reports${
          filter || ''
        }?page=${currentPage}`
      )
      .then((response) => {
        return {
          ok: true,
          projectReports: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getDashboardMetrics(projectId) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/project_reports/dashboard_metrics`
      )
      .then((response) => {
        return {
          ok: true,
          metrics: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getProjectReport(
    projectId,
    projectReportId,
    reload = false,
    loadingDisabled = false
  ) {
    let url = `${this.baseUrl}/projects/${projectId}/project_reports/${projectReportId}`;
    if (reload) url += '?reload=true';

    return axios
      .get(url, { loadingDisabled })
      .then((response) => {
        return {
          ok: true,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getLastProjectReport(projectId) {
    let url = `${this.baseUrl}/projects/${projectId}/project_reports/last_report`;
    return axios
      .get(url)
      .then((response) => {
        return {
          ok: true,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  deleteProjectReports(projectId, projectReportId) {
    return axios
      .delete(
        `${this.baseUrl}/projects/${projectId}/project_reports/${projectReportId}`
      )
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  addProjectReports(projectId, values, number, status, userId, isPrevent) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const newData = this.addProjectReportParams(
      values,
      status,
      userId,
      number,
      [],
      isPrevent
    );

    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/project_reports`,
        newData,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateProjectReports(projectId, projectReportsId, values, status, userId) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const updateData = this.addProjectReportParams(values, status, userId);

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/project_reports/${projectReportsId}`,
        updateData,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getProjectReportResolveInfoByAttr(projectId, projectReportId, attr) {
    const url =
      attr === 'remarks'
        ? `${this.baseUrl}/projects/${projectId}/project_reports?resolved=true`
        : `${this.baseUrl}/projects/${projectId}/project_reports/${projectReportId}/${attr}?resolved=true`;

    return axios
      .get(url)
      .then((response) => {
        return {
          ok: true,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  setResolvedStatusByAttr(projectId, projectReportId, attr, item) {
    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/project_reports/${projectReportId}/${attr}/${item.id}/resolve`,
        item
      )
      .then((response) => {
        return {
          ok: true,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  projectReportRestoreItemByAttr(projectId, projectReportId, attr, item) {
    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/project_reports/${projectReportId}/${attr}/${item.id}/restore`,
        item
      )
      .then((response) => {
        return {
          ok: true,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  sendToReviewProjectReport(projectId, projectReportId, emails, values) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const newData = this.addProjectReportParams(
      values,
      'reviewed',
      null,
      null,
      emails
    );
    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/project_reports/${projectReportId}/review`,
        newData,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  previewProjectReport(projectId, projectReportId, values) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const newData = this.addProjectReportParams(values, 'draft');
    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/project_reports/${projectReportId}/preview`,
        newData,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  sendProjectReportsPermissions(projectId, permissions) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    let apiPayload = new FormData();
    const array = Object.entries(permissions)
      .filter((item) => item[1])
      .filter((item) => item[0] !== 'oar');
    array.map((item) =>
      apiPayload.append(`project[project_report_permissions][]`, item[0])
    );
    array.length === 0 &&
      apiPayload.append(`project[project_report_permissions]`, []);

    return axios
      .put(`${this.baseUrl}/projects/${projectId}`, apiPayload, headers)
      .then((response) => {
        return {
          ok: true,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  sendToEmailProjectReport(
    projectId,
    projectReportId,
    emails,
    subject,
    message
  ) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    let apiPayload = new FormData();
    emails.map((item) =>
      apiPayload.append(`project_report[emails][]`, item[1])
    );
    apiPayload.append(`project_report[subject]`, subject);
    apiPayload.append(`project_report[message]`, message);
    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/project_reports/${projectReportId}/email`,
        apiPayload,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getProjectReportsPreviousImageAndDocuments(projectId) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/project_reports/previous_image_and_documents`
      )
      .then((response) => {
        return {
          ok: true,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getProjectReportPdfPrint(projectId, projectReportId, type) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/project_reports/${projectReportId}/${type}/print`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        return {
          ok: true,
          reportPdf: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getProjectReportExcelExport(projectId, projectReportId, type) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/project_reports/${projectReportId}/${type}/excel`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        return {
          ok: true,
          reportPdf: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateContractInfoOrder(projectId, projectReportId, contractInfo) {
    let data = {
      contract_info_sort_ids: contractInfo
        .filter((item) => typeof item.id !== 'string')
        .map((item) => item.id),
    };
    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/project_reports/${projectReportId}/contract_info/sort`,
        {
          project_report_contract_info: data,
        }
      )
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateBudgetOrder(projectId, projectReportId, budgetId, budgets, result) {
    const budget_sort_ids = [];
    if (result.type === 'subItems') {
      const parent_id = Number(result.source.droppableId.split('-')[2]);
      const parent = budgets.find((item) => item.id === parent_id);

      if (parent) {
        parent.children.forEach((item) => {
          if (typeof item.id !== 'string') budget_sort_ids.push(item.id);
        });
      }
    } else {
      budgets.forEach((item) => {
        if (
          item.project_report_budget_category_id === budgetId &&
          typeof item.id !== 'string'
        ) {
          budget_sort_ids.push(item.id);
        }
      });
    }
    let data = { budget_sort_ids, project_report_budget_category_id: budgetId };
    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/project_reports/${projectReportId}/budgets/sort`,
        { project_report_budget: data }
      )
      .then((response) => ({ ok: true }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  updateScheduleOrder(projectId, projectReportId, scheduleId, schedules) {
    let data = {
      schedule_sort_ids: schedules
        .filter(
          (item) =>
            item.project_report_schedule_category_id === scheduleId &&
            typeof item.id !== 'string'
        )
        .map((item) => item.id),
      project_report_schedule_category_id: scheduleId,
    };
    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/project_reports/${projectReportId}/schedules/sort`,
        {
          project_report_schedule: data,
        }
      )
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  addProjectReportParams(
    values,
    status,
    userId,
    number = null,
    emails = [],
    isPrevent
  ) {
    let apiPayload = new FormData();
    if (status === 'reviewed') {
      emails.map((item) =>
        apiPayload.append(`project_report[emails][]`, item[1])
      );
    }
    if (status === 'published') {
      apiPayload.append(`project_report[submitted_by_id]`, userId);
      apiPayload.append(
        `project_report[published_at]`,
        moment(new Date()).format('YYYY-MM-DD')
      );
    }
    apiPayload.append(`project_report[date]`, values.date);
    apiPayload.append(`project_report[number]`, number || values.number);
    apiPayload.append(`project_report[remarks]`, values.remarks || '');
    apiPayload.append(`project_report[status]`, status);
    apiPayload.append(
      `project_report[disclaimer_remarks]`,
      values.disclaimer_remarks || ''
    );
    apiPayload.append(
      `project_report[budget_remarks]`,
      values.budget_remarks || ''
    );
    apiPayload.append(
      `project_report[original_completion_date]`,
      values.original_completion_date || ''
    );
    apiPayload.append(
      `project_report[previous_completion_date]`,
      values.previous_completion_date || ''
    );
    apiPayload.append(
      `project_report[completion_date]`,
      values.completion_date || ''
    );
    apiPayload.append(
      `project_report[schedule_remarks]`,
      values.schedule_remarks || ''
    );

    apiPayload.append(
      `project_report[directory_details]`,
      values.directory_details || ''
    );
    apiPayload.append(
      `project_report[directory_details_type]`,
      values.directory_details_type || ''
    );
    Object.values(values.attach_documents || {})
      .filter((item) => item.value)
      .forEach((item) => {
        apiPayload.append(`project_report[attach_documents][]`, item.key);
      });
    if (values.recipients && values.recipients.length > 0) {
      values.recipients.forEach((item) => {
        apiPayload.append(`project_report[user_ids][]`, item[0].toString());
      });
    }
    const hasCoverImage =
      values.cover_image &&
      values.cover_image.length > 0 &&
      values.cover_image[0].file;
    if (hasCoverImage) {
      apiPayload.append(
        `project_report[cover_image_attributes][image]`,
        values.cover_image[0].file,
        values.cover_image[0].file && values.cover_image[0].file.name
      );
    }
    if (
      !hasCoverImage &&
      values.deleted_cover_image &&
      values.deleted_cover_image.length > 0 &&
      values.deleted_cover_image[0].id
    ) {
      apiPayload.append(
        `project_report[cover_image_attributes][id]`,
        values.deleted_cover_image[0].id
      );
      apiPayload.append(
        `project_report[cover_image_attributes][_destroy]`,
        true
      );
    }

    let imagesIndex = 0;
    if (values.images && values.images.length > 0) {
      values.images
        .filter((image) => image.id)
        .forEach((image) => {
          apiPayload.append(
            `project_report[images_attributes][${imagesIndex}][description]`,
            image.description || ''
          );
          apiPayload.append(
            `project_report[images_attributes][${imagesIndex}][position]`,
            image.position
          );
          apiPayload.append(
            `project_report[images_attributes][${imagesIndex}][id]`,
            image.id
          );
          imagesIndex++;
        });
    }

    if (values.deletedImages && values.deletedImages.length > 0) {
      values.deletedImages
        .filter((image) => image.id)
        .forEach((image) => {
          apiPayload.append(
            `project_report[images_attributes][${imagesIndex}][id]`,
            image.id
          );
          apiPayload.append(
            `project_report[images_attributes][${imagesIndex}][_destroy]`,
            true
          );
          imagesIndex++;
        });
    }

    let docIndex = 0;
    if (values.documents && values.documents.length > 0) {
      values.documents
        .filter((doc) => doc.id)
        .forEach((doc) => {
          apiPayload.append(
            `project_report[documents_attributes][${docIndex}][position]`,
            doc.position
          );
          apiPayload.append(
            `project_report[documents_attributes][${docIndex}][id]`,
            doc.id
          );
          docIndex++;
        });
    }

    if (values.deletedDocuments && values.deletedDocuments.length > 0) {
      values.deletedDocuments
        .filter((document) => document.id)
        .forEach((doc) => {
          apiPayload.append(
            `project_report[documents_attributes][${docIndex}][id]`,
            doc.id
          );
          apiPayload.append(
            `project_report[documents_attributes][${docIndex}][_destroy]`,
            true
          );
          docIndex++;
        });
    }

    values.contract_info.forEach((item, index) => {
      apiPayload.append(
        `project_report[project_report_contract_infos_attributes][${index}][description]`,
        item.description
      );
      apiPayload.append(
        `project_report[project_report_contract_infos_attributes][${index}][amount]`,
        +item.amount
      );
      apiPayload.append(
        `project_report[project_report_contract_infos_attributes][${index}][add_services]`,
        item.add_services
      );
      apiPayload.append(
        `project_report[project_report_contract_infos_attributes][${index}][paid_to_date]`,
        item.paid_to_date
      );
      apiPayload.append(
        `project_report[project_report_contract_infos_attributes][${index}][start]`,
        item.start
      );
      apiPayload.append(
        `project_report[project_report_contract_infos_attributes][${index}][completion]`,
        item.completion
      );
      apiPayload.append(
        `project_report[project_report_contract_infos_attributes][${index}][include_amount]`,
        item.include_amount
      );
      apiPayload.append(
        `project_report[project_report_contract_infos_attributes][${index}][include_team]`,
        item.include_team
      );
      apiPayload.append(
        `project_report[project_report_contract_infos_attributes][${index}][_destroy]`,
        item._destroy || false
      );
      if (!isPrevent) {
        if (!item.isEdit) {
          apiPayload.append(
            `project_report[project_report_contract_infos_attributes][${index}][id]`,
            item.id
          );
        }
      }
      if (item.previous_published_report_id) {
        apiPayload.append(
          `project_report[project_report_contract_infos_attributes][${index}][previous_published_report_id]`,
          item.previous_published_report_id
        );
      }
    });

    values.statuses.forEach((item, index) => {
      apiPayload.append(
        `project_report[project_report_statuses_attributes][${index}][description]`,
        item.description
      );
      apiPayload.append(
        `project_report[project_report_statuses_attributes][${index}][code]`,
        item.code
      );
      apiPayload.append(
        `project_report[project_report_statuses_attributes][${index}][revision_number]`,
        item.revision_number
      );
      apiPayload.append(
        `project_report[project_report_statuses_attributes][${index}][date]`,
        item.date
      );
      apiPayload.append(
        `project_report[project_report_statuses_attributes][${index}][_destroy]`,
        item._destroy || false
      );
      if (!isPrevent) {
        if (!item.isEdit) {
          apiPayload.append(
            `project_report[project_report_statuses_attributes][${index}][id]`,
            item.id
          );
        }
      }
      apiPayload.append(
        `project_report[project_report_statuses_attributes][${index}][project_report_status_type_id]`,
        item.project_report_status_type_id
      );
      if (item.previous_published_report_id) {
        apiPayload.append(
          `project_report[project_report_statuses_attributes][${index}][previous_published_report_id]`,
          item.previous_published_report_id
        );
      }
    });

    values.schedule_categories.forEach((id) => {
      apiPayload.append(`project_report[schedule_category_ids][]`, id);
    });
    if (values.schedule_categories.length === 0)
      apiPayload.append(`project_report[schedule_category_ids][]`, '');

    apiPayload = this.addProjectReportBudgetParams(
      values.budgets,
      apiPayload,
      isPrevent
    );

    return apiPayload;
  }

  addProjectReportBudgetParams(
    budgets,
    apiPayload,
    isPrevent,
    parentIndex = null
  ) {
    budgets.forEach((item, index) => {
      const hasChildren = item.children && item.children.length >= 0;

      const childPrefix =
        parentIndex !== null ? `[${parentIndex}][children_attributes]` : '';

      apiPayload.append(
        `project_report[project_report_budgets_attributes]${childPrefix}[${index}][description]`,
        item.description || ''
      );
      apiPayload.append(
        `project_report[project_report_budgets_attributes]${childPrefix}[${index}][project_report_budget_category_id]`,
        item.project_report_budget_category_id
      );
      apiPayload.append(
        `project_report[project_report_budgets_attributes]${childPrefix}[${index}][_destroy]`,
        item._destroy || false
      );
      apiPayload.append(
        `project_report[project_report_budgets_attributes]${childPrefix}[${index}][construction]`,
        item.construction || false
      );

      if (!isPrevent) {
        if (!item.isEdit) {
          apiPayload.append(
            `project_report[project_report_budgets_attributes]${childPrefix}[${index}][id]`,
            item.id
          );
        }
      }

      if (item.project_report_budget_category_id === 1) {
        apiPayload.append(
          `project_report[project_report_budgets_attributes]${childPrefix}[${index}][current_cost]`,
          item.current_cost || 0
        );
        apiPayload.append(
          `project_report[project_report_budgets_attributes]${childPrefix}[${index}][original_cost]`,
          item.original_cost || 0
        );
        apiPayload.append(
          `project_report[project_report_budgets_attributes]${childPrefix}[${index}][previous_cost]`,
          item.previous_cost || 0
        );
        if (item.previous_published_report_id) {
          apiPayload.append(
            `project_report[project_report_budgets_attributes]${childPrefix}[${index}][previous_published_report_id]`,
            item.previous_published_report_id
          );
        }
      }
      if (item.project_report_budget_category_id === 2) {
        apiPayload.append(
          `project_report[project_report_budgets_attributes]${childPrefix}[${index}][contract_amount]`,
          item.contract_amount || 0
        );
        apiPayload.append(
          `project_report[project_report_budgets_attributes]${childPrefix}[${index}][paid_to_date_amount]`,
          item.paid_to_date_amount || 0
        );
        if (item.previous_published_report_id) {
          apiPayload.append(
            `project_report[project_report_budgets_attributes]${childPrefix}[${index}][previous_published_report_id]`,
            item.previous_published_report_id
          );
        }
      }

      if (hasChildren) {
        apiPayload = this.addProjectReportBudgetParams(
          item.children,
          apiPayload,
          isPrevent,
          index
        );
      }
    });

    return apiPayload;
  }
}

export const projectReportsApi = new ProjectReportsApi();
