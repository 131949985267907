import User from './user';
import ProjectReportPermissions from '../permissions/project-report-permissions';
import { COMPANY_TYPES } from './company-type-enum';

export const TERMINATION_CATEGORY_OPTIONS = [
  'Pricing',
  'Schedule',
  'Using prefered contractor',
  'Other',
];

export const CONDITION_OPTIONS = [
  'New Structure',
  'New Structure Expansion',
  'Renovation Site',
  'Renovation Exterior',
  'Renovation Interior',
];

export const INDUSTRY_OPTIONS = [
  'Commercial',
  'Residential',
  'Industrial',
  'Heavy',
];

export const INDUSTRY_TYPE_OPTIONS = {
  Commercial: [
    'Office',
    'Retail',
    'Hotels',
    'Food Service',
    'Health & Wellness',
    'Medical',
    'Religious/NFP',
    'Education',
    'Transportation',
    'Stadium',
  ],
  Residential: [
    'Single Family',
    'Single Family Attached',
    'Multi Family',
    'Mixed Use',
  ],
  Industrial: [
    'Warehouse',
    'Storage',
    'Laboratory',
    'Manufacturing',
    'Utility',
    'R&D',
    'Flex',
  ],
  Heavy: ['Highway', 'Civil', 'Marine', 'Utility'],
};

export const PRIMARY_USERS_OPTIONS = [1, 5, 10, 20, 50, 100, 200];

export const COST_OPTIONS = [1, 5, 10, 20, 50, 100];

export const SQ_FOOTAGE_OPTIONS = [
  1000,
  5000,
  10000,
  20000,
  50000,
  100000,
  500000,
];

class Project {
  companyContributors = ({
    directory,
    companyId,
    contributorType,
    csiCodeId,
  }) =>
    ((directory && directory.project_contributors) || []).filter(
      (contributor) =>
        (!contributorType ||
          contributor.contributor_type === contributorType) &&
        (!csiCodeId || contributor.csi_code.id === csiCodeId) &&
        contributor.company_id === companyId
    );

  gcCompany = (project) => (project && project.general_contractor) || null;

  gcContributor = ({ project, directory }) => {
    const company = this.gcCompany(project);
    if (!company) return null;

    return this.companyContributors({
      directory,
      companyId: company.id,
      contributorType: COMPANY_TYPES.GENERAL_CONTRACTOR,
    })[0];
  };

  proposalOrCsiCodes = ({ projectProposal, csiCodes }) => {
    if (projectProposal) return projectProposal.project_proposal_items;
    if (csiCodes) return csiCodes.map((code) => ({ csi_code: code }));
    return [];
  };

  directoryOrProposal = ({
    project,
    directory,
    currentUser,
    projectProposal,
    csiCodes,
  }) => {
    if (User.isContractor(currentUser, project))
      return (
        (directory && directory.project_contributors) ||
        []
      ).filter((contrib) => User.isSameCompany(contrib, currentUser));

    return [
      ...this.proposalOrCsiCodes({ projectProposal, csiCodes }),
      ...((directory && directory.project_contributors) || []),
    ];
  };

  csiCodeLabel = ({ division, label }) => `${division} - ${label}`;

  contributorLabel = ({ company, csi_code: csiCode }) =>
    `${csiCode.division} ${csiCode.label} - ${company.name}`;

  csiCodesOptions = ({
    project,
    directory,
    currentUser,
    projectProposal,
    csiCodes,
  }) => {
    const addedCsiCodes = new Set();
    return this.directoryOrProposal({
      project,
      directory,
      currentUser,
      projectProposal,
      csiCodes,
    })
      .filter((item) => item.csi_code)
      .map(({ csi_code: csiCode }) => {
        if (addedCsiCodes.has(csiCode.id)) return null;

        addedCsiCodes.add(csiCode.id);
        return {
          value: csiCode.id,
          label: this.csiCodeLabel(csiCode),
        };
      })
      .filter((csiCode) => !!csiCode)
      .sort((csiCode1, csiCode2) =>
        csiCode1.label.localeCompare(csiCode2.label)
      );
  };

  subcontractorOptions = ({ project, directory, csiCodeId }) => {
    if (!csiCodeId) return [];

    return ((directory && directory.project_contributors) || [])
      .filter(
        (contributor) =>
          User.isContractor(contributor, project) &&
          contributor.csi_code &&
          contributor.csi_code.id === csiCodeId
      )
      .map((contributor) => ({
        value: contributor.company_id,
        label: contributor.company.name,
      }))
      .sort((sub1, sub2) => (sub1.label < sub2.label ? -1 : 1));
  };

  projectContributorOptions = ({ directory }) =>
    ((directory && directory.project_contributors) || [])
      .map((contributor) => ({
        value: contributor.id,
        label: this.contributorLabel(contributor),
      }))
      .sort((contributor1, contributor2) =>
        contributor1.label.localeCompare(contributor2.label)
      );

  filterRecipients = ({ directory, primaryOnly }) => {
    const addedUsers = new Set();
    return ((directory && directory.project_contributors) || [])
      .filter((contributor) => !primaryOnly || contributor.primary)
      .reduce(
        (totalUsers, { company, users }) => [
          ...totalUsers,
          ...users
            .map((user) => {
              if (addedUsers.has(user.id)) return null;

              addedUsers.add(user.id);
              return {
                id: user.id,
                label: User.fullNameWithCompany({ ...user, company }),
              };
            })
            .filter((user) => user),
        ],
        []
      );
  };

  filterReportRecipients = ({
    project,
    directory,
    reportPermissions,
    checkReportPermissions = false,
    primaryOnly = false,
    labelType = 'name',
  }) => {
    const addedUsers = new Set();
    return ((directory && directory.project_contributors) || [])
      .filter((contributor) => !primaryOnly || contributor.primary)
      .reduce(
        (totalUsers, contributor) => [
          ...totalUsers,
          ...contributor.users
            .map((user) => {
              if (
                addedUsers.has(user.id) ||
                (checkReportPermissions &&
                  !ProjectReportPermissions.canView(
                    contributor,
                    project,
                    reportPermissions
                  ))
              )
                return null;

              addedUsers.add(user.id);
              return {
                id: user.id,
                label:
                  labelType === 'email'
                    ? user.email
                    : User.fullNameWithCompany({
                        ...user,
                        company: contributor.company,
                      }),
              };
            })
            .filter((user) => user),
        ],
        []
      );
  };

  recipientOptions = ({ directory }) => this.filterRecipients({ directory });

  primaryRecipientOptions = ({ directory }) =>
    this.filterRecipients({ directory, primaryOnly: true });
}

export default new Project();
