import {
  SET_FIELD_REPORTS_FILTERS,
  SET_FIELD_REPORTS,
  SET_FIELD_REPORT,
  SET_FIELD_REPORT_PDF,
  SET_FIELD_REPORT_HTML,
  DELETE_FIELD_REPORT,
  SET_FIELD_REPORTS_CREATORS,
  FIELD_REPORT_RELOAD,
} from './action-types';

const initialState = {
  filters: {
    page: 1,
    pageSize: 10,
    date: 'all',
    publishedBy: 'all',
    status: 'all',
    createdBy: [],
  },
  total: 0,
  reports: [],
  report: null,
  reportPdf: null,
  reportHtml: null,
  creators: null,
};

export default function projectFieldReportDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_FIELD_REPORTS:
      let reports = [...action.payload.reports];
      let total = action.payload.total;

      if (action.payload.page > 1) {
        reports = [state.reports, ...reports];
        total = state.total;
      }

      return { ...state, reports, total };

    case SET_FIELD_REPORTS_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };

    case SET_FIELD_REPORT:
      return {
        ...state,
        report: action.payload,
      };

    case FIELD_REPORT_RELOAD:
      reports = [...state.reports];
      const index = reports.findIndex((r) => r.id === action.payload.id);
      if (index >= 0) reports[index] = action.payload;
      else reports.push(action.payload);

      return { ...state, reports };

    case SET_FIELD_REPORT_PDF:
      return {
        ...state,
        reportPdf: action.payload,
      };

    case SET_FIELD_REPORT_HTML:
      return {
        ...state,
        reportHtml: action.payload,
      };

    case DELETE_FIELD_REPORT:
      return {
        ...state,
        reports: state.reports.filter((r) => r.id !== action.payload),
        total: state.total - 1,
      };

    case SET_FIELD_REPORTS_CREATORS:
      return {
        ...state,
        creators: action.payload,
      };

    default:
      return state;
  }
}
