import React from 'react';
import PropTypes from 'prop-types';
import './MultipleSelectList.css';
import removeSvg from '../../../images/remove.svg';
import removeDarkSvg from '../../../images/remove.svg';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';

/**
 * Show list of values, allow to select more than one.
 * It's possible to remove items from the list, for this to work a "onRemove" function should be defined.
 * If an item id es zero (0), then the index is returned when removing an item.
 */
function MultipleSelectList(props) {
  const handleSelection = (id, event) => {
    event.preventDefault();
    if (!props.readOnly && props.onSelect) {
      props.onSelect(id);
    }
  };

  const handleRemove = (id, index, event) => {
    event.stopPropagation();
    if (props.onRemove) {
      props.onRemove(id, index);
    }
  };

  return (
    <div
      className={`multiple-select-list show-height-${props.show} ${
        props.darkMode ? 'dark-mode' : ''
      }`}
    >
      {props.options.length > 0 ? (
        <ul>
          {props.options.map((option, index) => {
            return (
              <li
                key={index}
                className={option.selected ? 'selected' : null}
                onClick={handleSelection.bind(this, option.id)}
              >
                {option.value}
                {props.onRemove !== undefined && (
                  <div className="msl-remove-button">
                    <img
                      src={props.darkMode ? removeDarkSvg : removeSvg}
                      alt="remove"
                      onClick={handleRemove.bind(this, option.id, index)}
                    />
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      ) : (
        <div className="msl-no-options small-regular">
          {props.noValuesMessage}
        </div>
      )}
    </div>
  );
}

MultipleSelectList.propTypes = {
  noValuesMessage: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      selected: PropTypes.bool,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
  ),
  readOnly: PropTypes.bool,
  show: PropTypes.oneOf([3, 4, 5, 6]),
  onRemove: PropTypes.func,
  onSelect: PropTypes.func,
};

MultipleSelectList.defaultProps = {
  noValuesMessage: 'No values',
  options: [],
  readOnly: false,
  show: 3,
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(MultipleSelectList);
