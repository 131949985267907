import React from 'react';
import { connect } from 'react-redux';

import ProjectEmailModal from 'components/admin/projects/details/project-email-modal/ProjectEmailModal';

import { getCurrentRfa } from './store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/selectors';
import { sendProjectResourceEmails } from 'components/admin/projects/details/store/actions';

import { formatNumber } from 'services/utils/text-util';

const ModalRfaLogEmail = (props) => {
  const { dispatch, currentProject, showEmailModal, currentRfa } = props;

  const handleSendEmail = (emailsData) => {
    dispatch(sendProjectResourceEmails(currentRfa.id, 'Rfa', emailsData));
  };

  const buildEmailModalSubject = () => {
    return `${currentProject.number} ${currentProject.name} - RFA ${
      currentRfa.number ? formatNumber(currentRfa.number) + ' - ' : ''
    }${currentRfa.request_title}`;
  };

  const buildEmailModalMessage = () => {
    let location = '';
    if (
      currentProject.project_location &&
      currentProject.project_location.location
    ) {
      const projectLocation = currentProject.project_location.location;
      location = ` at ${projectLocation.street_address_1}, ${projectLocation.city} ${projectLocation.state}`;
    }

    return `Please find the attached RFA ${
      currentRfa.number ? formatNumber(currentRfa.number) : ''
    } for Project ${
      currentProject.name
    }${location}. If you have any questions, please reach out via chat or email.`;
  };

  if (!showEmailModal) return null;

  return (
    <ProjectEmailModal
      subTitle={currentProject.name}
      projectId={currentProject.id}
      handleSendEmail={handleSendEmail}
      initialSubject={buildEmailModalSubject()}
      initialMessage={buildEmailModalMessage()}
      itemType="Rfa"
      itemId={currentRfa.id}
    />
  );
};

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    currentRfa: getCurrentRfa(state),
    showEmailModal: getShowEmailModal(state),
  };
})(ModalRfaLogEmail);
