import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import FormModal from 'components/shared/modal/FormModal';
import SimpleTable from 'components/shared/table/SimpleTable';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import {
  setViewCorrectionsModalShow,
  updateMeetingsParent,
} from './store/actions';
import {
  getProjectNewMeetingsForm,
  getViewCorrectionsModalShow,
} from './store/selectors';
import './EditTypeOrTitleModal.css';
import './CorrectionsSubmitModal.css';
import RadioButton from '../../../../shared/radio-button/RadioButton';
import { capitalizeFirstLetters } from '../../../../../services/utils/case-utils';

const MeetingsCorrectionsViewAllModal = (props) => {
  const { dispatch, show, form, project, currentUser } = props;
  const [editingRowId, setEditingRowId] = useState(null);
  const handleClose = () => {
    dispatch(setViewCorrectionsModalShow(false));
  };
  const [corrections, setCorrections] = useState([]);
  useEffect(() => {
    setCorrections(form.meetingsCorrections);
  }, []);
  const parseDate = (date) => {
    const d = moment(date);
    return d.isValid() ? d.utc().format('MM/DD/YYYY') : '--';
  };
  const statusOptions = [
    { value: 'approved', label: 'Approve' },
    { value: 'rejected', label: 'Reject' },
  ];

  const handleStatusChange = (row, option) => {
    const editedRow = { ...row, status: option, resolvedById: currentUser.id };
    const editedRows = corrections.map((c) =>
      c.id === row.id ? editedRow : c
    );
    setCorrections(editedRows);
  };

  const correctionsHeaders = [
    'ITEM NO',
    'CORRECTION REMARK',
    'SUBMITTED BY',
    'DATE',
    'STATUS',
    '',
  ];

  const actions = (row) => {
    if (editingRowId === row.id) {
      return (
        <RadioButton
          options={statusOptions}
          onChange={(option) => handleStatusChange(row, option)}
          className={'approve-options'}
          orientation={'horizontal-row'}
          value={row.status}
        />
      );
    }

    return capitalizeFirstLetters(row.status);
  };

  const getRow = (row) => {
    const editButtonCell =
      row.status === 'approved' || row.status === 'rejected' ? (
        <Button
          type={BUTTON_TYPES.LINK}
          color={BUTTON_COLORS.GREEN}
          label="Edit"
          onClick={() => setEditingRowId(row.id)}
        />
      ) : null;
    const rowClass =
      editingRowId === row.id
        ? 'correction-row-style edit-mode-row'
        : 'correction-row-style';
    return {
      id: row.id, // Assuming each row has a unique identifier
      className: rowClass, // Custom class name based on the row's data
      selectable: true, // Assuming rows are selectable, adjust as needed
      data: [
        row.meetingCorrectionNo,
        row.correctionRemark,
        row.submittedBy && row.submittedBy.label,
        parseDate(row.createdAt),
        actions(row),
        editButtonCell,
      ],
    };
  };

  const getRows = () => {
    const rows = corrections.map((c) => getRow(c));
    return rows;
  };

  const handleSave = () => {
    const modifiedParentForm = { ...form, meetingsCorrections: corrections };
    dispatch(updateMeetingsParent(project.id, form.id, modifiedParentForm));
  };

  if (!show) return null;

  const titleComponent = (
    <div className="search-bar-title">All Corrections</div>
  );

  return (
    <FormModal
      className={`review-corrections-modal`}
      show={show}
      onClose={handleClose}
      subTitle={'Meeting Minutes'}
      title={titleComponent}
    >
      <SimpleTable
        className="custom-table-style"
        emptyMessage={`No Corrections have been submitted`}
        headers={correctionsHeaders}
        actionCells={1}
        rows={getRows()}
        stickyHeader={true}
      />
      <div className="button-actions">
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.WHITE}
          label="Cancel"
          onClick={handleClose}
        />
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          label={'Save'}
          onClick={handleSave}
        />
      </div>
    </FormModal>
  );
};

export default connect((state) => ({
  show: getViewCorrectionsModalShow(state),
  currentUser: getCurrentUser(state),
  project: getCurrentProject(state),
  form: getProjectNewMeetingsForm(state),
}))(MeetingsCorrectionsViewAllModal);
