import { showErrorMessage } from 'actions/messages';
import {
  INVOICES_INVOICE_ATTRIBUTE_UPDATE,
  INVOICES_INVOICE_CUSTOM_EMAIL_CHANGE,
  INVOICES_INVOICE_CUSTOM_EMAIL_VALIDATION_SET,
  INVOICES_INVOICE_EDIT_CANCEL,
  INVOICES_INVOICE_EDIT_SET,
  INVOICES_INVOICE_EMAIL_MODAL_SHOW_SET,
  INVOICES_INVOICE_EMAILS_REMOVE,
  INVOICES_INVOICE_EMAILS_USERS_ADD,
  INVOICES_INVOICE_FORM_ATTRIBUTE_CHANGE,
  INVOICES_INVOICE_FORM_INVALID,
  INVOICES_INVOICE_FORM_ITEM_LINE_CHANGE,
  INVOICES_INVOICE_FORM_VALID,
  INVOICES_INVOICE_ITEM_LINE_CHANGE,
  INVOICES_INVOICE_LOAD_SUCCESS,
  INVOICES_INVOICE_FORM_MISC_COST_ADD,
  INVOICES_INVOICE_FORM_MISC_COST_CLEAR,
  INVOICES_INVOICE_FORM_MISC_COST_REMOVE,
  INVOICES_INVOICE_PDF_VIEWER_CLOSE,
  INVOICES_INVOICE_PRINT_SUCCESS,
  INVOICES_INVOICE_SAVE_SHOW,
  INVOICES_INVOICE_USER_SELECT,
  INVOICES_LOAD_SUCCESS,
  INVOICES_PAGE_CHANGE,
  INVOICES_USERS_LOAD_SUCCESS,
  INVOICES_TAB_SELECTION_CHANGE,
  INVOICES_SELECTED_STATUS_SET,
} from 'actions/admin/invoices/action-types';
import { invoicesApi } from 'services/api/invoices-api';
import { push } from 'react-router-redux';
import { clientsApi } from 'services/api/clients-api';
import { validateEmail } from 'services/utils/text-util';

export const baseRoutePath = '/admin/invoices';

export function addFormMiscCost(id, description, cost) {
  return {
    type: INVOICES_INVOICE_FORM_MISC_COST_ADD,
    payload: { id, description, cost },
  };
}

export function cancelEdit(invoice) {
  return {
    type: INVOICES_INVOICE_EDIT_CANCEL,
    payload: invoice,
  };
}

export function changePage(newPage) {
  return {
    type: INVOICES_PAGE_CHANGE,
    payload: newPage,
  };
}

export function clearFormMiscCost() {
  return {
    type: INVOICES_INVOICE_FORM_MISC_COST_CLEAR,
  };
}

export function closePdfViewer() {
  return {
    type: INVOICES_INVOICE_PDF_VIEWER_CLOSE,
  };
}

export function customEmailAdd(email) {
  return (dispatch) => {
    if (validateEmail(email)) {
      dispatch(
        emailsAdd([
          {
            id: 0,
            email: email,
          },
        ])
      );
      dispatch(customEmailChange(''));
      dispatch(setCustomEmailValid(true));
    } else {
      dispatch(setCustomEmailValid(false));
    }
  };
}

export function customEmailChange(value) {
  return {
    type: INVOICES_INVOICE_CUSTOM_EMAIL_CHANGE,
    payload: value,
  };
}

export function emailRemove(id, index) {
  return {
    type: INVOICES_INVOICE_EMAILS_REMOVE,
    payload: { id, index },
  };
}

export function extractDeletedMiscItems(formMiscItems, originalMiscItems) {
  const idsToDelete = [];
  originalMiscItems.forEach((originalMiscItem) => {
    if (
      formMiscItems.find(
        (formMiscItem) => formMiscItem.id === originalMiscItem.id
      ) === undefined
    ) {
      idsToDelete.push(originalMiscItem.id);
    }
  });
  return idsToDelete;
}

export function invalid(invalidLineItemId, validationMessage) {
  return {
    type: INVOICES_INVOICE_FORM_INVALID,
    payload: { invalidLineItemId, validationMessage },
  };
}

export function goToInvoice(invoiceId) {
  return (dispatch) => {
    dispatch(push(`${baseRoutePath}/${invoiceId}`));
  };
}

export function loadInvoiceRequest(invoiceId) {
  return (dispatch) => {
    return invoicesApi.getInvoice(invoiceId).then((response) => {
      if (response.ok) {
        dispatch(loadInvoiceSuccess(response.invoice));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function loadInvoiceSuccess(invoice) {
  return {
    type: INVOICES_INVOICE_LOAD_SUCCESS,
    payload: invoice,
  };
}

export function loadInvoicesRequest(page, status) {
  return (dispatch) => {
    return invoicesApi.getInvoices(page, status).then((response) => {
      if (response.ok) {
        dispatch(loadInvoicesSuccess(response.invoices));
        dispatch(changePage(page));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function loadInvoicesSuccess(invoices) {
  return {
    type: INVOICES_LOAD_SUCCESS,
    payload: invoices,
  };
}

export function loadUsersRequest(clientId) {
  return (dispatch) => {
    return clientsApi.getUsers(clientId).then((response) => {
      if (response.ok) {
        dispatch(loadUsersSuccess(clientId, response.users));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadUsersSuccess(clientId, users) {
  return {
    type: INVOICES_USERS_LOAD_SUCCESS,
    payload: { clientId, users },
  };
}

export function markAsPaidRequest(invoiceId) {
  return (dispatch) => {
    return invoicesApi.markInvoiceAsPaid(invoiceId).then((response) => {
      if (response.ok) {
        dispatch(
          updateCurrentInvoiceAttribute('status', response.invoice.status)
        );
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function printInvoice(invoiceId) {
  return (dispatch) => {
    return invoicesApi.getAsPdf(invoiceId).then((response) => {
      if (response.ok) {
        dispatch(printProposalSuccess(response.pdf));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function printProposalSuccess(pdf) {
  return {
    type: INVOICES_INVOICE_PRINT_SUCCESS,
    payload: pdf,
  };
}

export function removeFormMiscCost(index) {
  return {
    type: INVOICES_INVOICE_FORM_MISC_COST_REMOVE,
    payload: index,
  };
}

export function sendInvoiceRequest(invoiceId) {
  return (dispatch) => {
    return invoicesApi.sendInvoice(invoiceId).then((response) => {
      if (response.ok) {
        dispatch(
          updateCurrentInvoiceAttribute('sent_at', response.invoice.sent_at)
        );
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function sendEmail(invoiceId, emails, subject, message) {
  return (dispatch) => {
    return invoicesApi
      .sendEmail(invoiceId, emails, subject, message)
      .then((response) => {
        if (response.ok) {
          dispatch(setShowEmailModal(false));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setCustomEmailValid(value) {
  return {
    type: INVOICES_INVOICE_CUSTOM_EMAIL_VALIDATION_SET,
    payload: value,
  };
}

export function setEdit(value) {
  return {
    type: INVOICES_INVOICE_EDIT_SET,
    payload: value,
  };
}

export function setShowEmailModal(value) {
  return {
    type: INVOICES_INVOICE_EMAIL_MODAL_SHOW_SET,
    payload: value,
  };
}

export function setShowSave(value) {
  return {
    type: INVOICES_INVOICE_SAVE_SHOW,
    payload: value,
  };
}

export function updateFormAttributeChange(attribute, value) {
  return {
    type: INVOICES_INVOICE_FORM_ATTRIBUTE_CHANGE,
    payload: { attribute, value },
  };
}

export function updateFormItemLineChange(itemId, value) {
  return {
    type: INVOICES_INVOICE_FORM_ITEM_LINE_CHANGE,
    payload: { itemId, value },
  };
}

export function updateCurrentInvoiceAttribute(attribute, value) {
  return {
    type: INVOICES_INVOICE_ATTRIBUTE_UPDATE,
    payload: { attribute, value },
  };
}

export function updateCurrentInvoiceLineItem(lineItemId, value) {
  return {
    type: INVOICES_INVOICE_ITEM_LINE_CHANGE,
    payload: { lineItemId, value },
  };
}

export function updateInvoiceRequest(invoiceId, form, originalMiscItems) {
  const miscItemsIdToDelete = extractDeletedMiscItems(
    form.miscCosts,
    originalMiscItems
  );
  return (dispatch) => {
    return invoicesApi
      .updateInvoice(invoiceId, form, miscItemsIdToDelete)
      .then((response) => {
        if (response.ok) {
          dispatch(
            updateCurrentInvoiceAttribute(
              'adjustment',
              response.invoice.adjustment
            )
          );
          dispatch(
            updateCurrentInvoiceAttribute('due_date', response.invoice.due_date)
          );
          dispatch(
            updateCurrentInvoiceAttribute('cost', response.invoice.cost)
          );
          dispatch(
            updateCurrentInvoiceAttribute('emails', response.invoice.send_to)
          );
          dispatch(
            updateCurrentInvoiceAttribute(
              'total_cost',
              response.invoice.total_cost
            )
          );
          response.invoice.line_items.forEach((lineItem) =>
            dispatch(updateCurrentInvoiceLineItem(lineItem.id, lineItem.cost))
          );
          dispatch(
            updateCurrentInvoiceAttribute(
              'misc_items',
              response.invoice.misc_items
            )
          );

          // Update misc costs form values because new id's could be returned from backend
          dispatch(clearFormMiscCost());
          response.invoice.misc_items.forEach((miscItem) =>
            dispatch(
              addFormMiscCost(miscItem.id, miscItem.description, miscItem.cost)
            )
          );
          dispatch(setEdit(false));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
  };
}

export function usersEmailAdd(usersIdSelected, users) {
  return (dispatch) => {
    const payload = users
      .filter(
        (user) => usersIdSelected.find((id) => id === user.id) !== undefined
      )
      .map((user) => {
        return {
          id: 0, // as the email return wihout id, we should use index as key
          email: user.email,
        };
      });

    dispatch(emailsAdd(payload));
  };
}

function emailsAdd(emails) {
  return {
    type: INVOICES_INVOICE_EMAILS_USERS_ADD,
    payload: emails,
  };
}

export function userSelected(userId, usersIdSelected) {
  return {
    type: INVOICES_INVOICE_USER_SELECT,
    payload:
      usersIdSelected.find((id) => id === userId) !== undefined
        ? usersIdSelected.filter((id) => id !== userId)
        : usersIdSelected.concat([userId]),
  };
}

export function valid() {
  return {
    type: INVOICES_INVOICE_FORM_VALID,
  };
}

export function tabSelectionChange(id) {
  return {
    type: INVOICES_TAB_SELECTION_CHANGE,
    payload: id,
  };
}

export function setSelectedStatus(status) {
  return {
    type: INVOICES_SELECTED_STATUS_SET,
    payload: status,
  };
}
