import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import FormModal from 'components/shared/modal/FormModal';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import Input from 'components/shared/input/Input';
import MultipleDropdown from '../../../../shared/dropdown/MultipleDropdown';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';

import {
  setFormMeetingInstance,
  setAddGuestModal,
  createNonDirectoryAttendee,
} from './store/actions';
import {
  getProjectNewMeetingsAddTypeModal,
  getProjectNewMeetingsAddTitleModal,
  getAddGuestModalShow,
  getMeetingsInstanceForm,
} from './store/selectors';
import './AttendanceForm.css';
import './MeetingModal.css';
import { snakeToCamel } from '../../../../../services/utils/case-utils';

const AddTypeOrTitleModal = (props) => {
  const { dispatch, project, directory, show, instanceForm } = props;
  const [directoryGuest, setDirectoryGuest] = useState([]);
  const [nonDirectoryGuest, setNonDirectoryGuest] = useState({
    firstName: '',
    lastName: '',
    title: '',
    company: '',
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (!directory || directory.project_id !== project.id)
      dispatch(loadDirectoryRequest(project.id));
  }, []);

  let allUsers = [];
  directory.project_contributors.forEach((contributor) => {
    allUsers = allUsers.concat(contributor.users);
  });
  const uniqueUsers = [];
  const seenIds = new Set();
  allUsers.forEach((user) => {
    if (!seenIds.has(user.id)) {
      uniqueUsers.push({
        id: user.id,
        value: user.label,
        label: user.label,
      });
      seenIds.add(user.id);
    }
  });

  const handleClose = () => {
    dispatch(setAddGuestModal(false));
  };
  const handleSave = () => {
    let formErrors = {};

    if (directoryGuest[0] && directoryGuest[0][0]) {
      const oldAttendees = instanceForm.attendees;
      const fullNewUsers = directoryGuest.map((guest) => {
        return allUsers.find((usr) => usr.id === guest[0]);
      });
      const transformedUsers = fullNewUsers.map((usr) => snakeToCamel(usr));
      const newAttendeeIds = fullNewUsers.map((usr) => usr.id);
      const formWithNewGuest = {
        ...instanceForm,
        attendees: [...oldAttendees, ...transformedUsers],
        attendeeIds: [...instanceForm.attendeeIds, ...newAttendeeIds],
      };
      dispatch(setFormMeetingInstance(formWithNewGuest));
      return true;
    } else if (
      nonDirectoryGuest.firstName ||
      nonDirectoryGuest.lastName ||
      nonDirectoryGuest.title ||
      nonDirectoryGuest.company
    ) {
      // Validate non-directory guest fields
      formErrors = nonDirectoryGuestValidation();
      setErrors(formErrors);
      if (Object.keys(formErrors).length === 0) {
        // Logic for when a non-directory guest is filled
        const oldNonDirectoryAttendees = instanceForm.nonDirectoryAttendees;
        const formWithNewGuest = {
          ...instanceForm,
          nonDirectoryAttendees: [
            ...oldNonDirectoryAttendees,
            snakeToCamel(nonDirectoryGuest),
          ],
        };
        dispatch(setFormMeetingInstance(formWithNewGuest));
        dispatch(
          createNonDirectoryAttendee(project.id, {
            ...nonDirectoryGuest,
            meetingsParentId: instanceForm.meetingsParentId,
            meetingsInstanceId: instanceForm.id,
          })
        );
        return true;
        // Add further actions here
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const handleSaveOnlyOne = () => {
    const isSaveSuccesful = handleSave();
    if (isSaveSuccesful) handleClose();
  };
  const handleDirectoryChange = (options) => {
    setDirectoryGuest(options);
    if (options && options.length > 0) {
      setNonDirectoryGuest({
        firstName: '',
        lastName: '',
        title: '',
        company: '',
      });
    }
  };
  const handleInputChange = (field, value) => {
    setNonDirectoryGuest({ ...nonDirectoryGuest, [field]: value });
    setDirectoryGuest([]);
  };

  const handleSaveAndAdd = () => {
    handleSave();
    setNonDirectoryGuest({
      firstName: '',
      lastName: '',
      title: '',
      company: '',
    });
    setDirectoryGuest([]);
  };

  const nonDirectoryGuestValidation = () => {
    let errors = {};
    if (!nonDirectoryGuest.firstName) {
      errors['firstName'] = 'Missing First Name';
    }
    if (!nonDirectoryGuest.lastName) {
      errors['lastName'] = 'Missing Last Name';
    }
    if (!nonDirectoryGuest.title) {
      errors['title'] = 'Missing Title';
    }
    if (!nonDirectoryGuest.company) {
      errors['company'] = 'Missing Company';
    }
    return errors;
  };

  return (
    <FormModal
      className={`meeting-modal editing`}
      show={show}
      onClose={handleClose}
      subTitle="Add Guest"
    >
      <div className="add-guest-modal-bold-text">From Project Directory</div>
      <FormControlBlock
        position="left"
        readonly={false}
        control={
          <MultipleDropdown
            options={uniqueUsers}
            currentValues={directoryGuest}
            placeholder="Add Guest"
            onChange={(values) => handleDirectoryChange(values)}
          />
        }
      />
      <div className="add-guest-modal-bold-text">or</div>
      <div className="form-block multicontrols-block">
        <div className="two-fields-container">
          <FormControlBlock
            position="left"
            readonly={false}
            control={
              <Input
                type={'text'}
                placeholder={'First Name'}
                valid={!errors.firstName}
                value={nonDirectoryGuest.firstName}
                onChange={(value) => handleInputChange('firstName', value)}
              />
            }
          ></FormControlBlock>
          <FormControlBlock
            label="Access"
            position="right"
            readonly={false}
            control={
              <Input
                type={'text'}
                placeholder={'Last Name'}
                value={nonDirectoryGuest.lastName}
                valid={!errors.lastName}
                onChange={(value) => handleInputChange('lastName', value)}
              />
            }
          ></FormControlBlock>
        </div>
      </div>
      <div className="form-block">
        <FormControlBlock
          position="left"
          readonly={false}
          control={
            <Input
              type={'text'}
              placeholder={'Title'}
              valid={!errors.title}
              value={nonDirectoryGuest.title}
              onChange={(value) => handleInputChange('title', value)}
              readonly={false}
            />
          }
        ></FormControlBlock>
      </div>
      <div className="form-block">
        <FormControlBlock
          position="left"
          readonly={false}
          control={
            <Input
              type={'text'}
              placeholder={'Company'}
              valid={!errors.company}
              value={nonDirectoryGuest.company}
              onChange={(value) => handleInputChange('company', value)}
              readonly={false}
            />
          }
        ></FormControlBlock>
      </div>
      <div className="button-actions-three">
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.WHITE}
          label="Cancel"
          onClick={handleClose}
        />
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          label={'Save'}
          onClick={handleSaveOnlyOne}
        />{' '}
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          label={'Save & Add'}
          onClick={handleSaveAndAdd}
        />
      </div>
    </FormModal>
  );
};

export default connect((state) => ({
  showIfType: getProjectNewMeetingsAddTypeModal(state),
  showIfTitle: getProjectNewMeetingsAddTitleModal(state),
  project: getCurrentProject(state),
  directory: getDirectory(state),
  show: getAddGuestModalShow(state),
  instanceForm: getMeetingsInstanceForm(state),
}))(AddTypeOrTitleModal);
