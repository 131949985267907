import { showErrorMessage } from 'actions/messages';
import { rfisApi } from 'services/api/rfis-api';
import { rfiQuestionsApi } from 'services/api/rfi-questions-api';
import { rfiAddlAssigneesApi } from 'services/api/rfi-addl-assignees-api';
import {
  RFI_LOG_INIT,
  RFIS_SET,
  RFI_TAB_SELECTED_SET,
  RFI_MODAL_SHOW_SET,
  RFI_MODAL_INIT,
  RFI_FORM_VALUE_UPDATE,
  RFI_QUESTION_VALUE_UPDATE,
  RFI_QUESTION_ERRORS_SET,
  RFI_QUESTION_ADD,
  RFI_QUESTIONS_ATTACH,
  RFI_QUESTION_DELETE,
  RFIS_ADD,
  RFIS_UPDATE,
  RFIS_DELETE,
  CURRENT_RFI_SET,
  RFI_READ_ONLY_SET,
  RFI_FORM_VALUES_SET,
  RFI_FORM_ERRORS_SET,
  RFI_QUESTION_FILE_DELETE,
  RFI_QUESTION_NOTE_ADD,
  RFI_GC_ACCELERATION_SET,
  RFI_LOG_PDF_VIEWER_SET,
  RFI_LOG_PDF_SET,
  RFI_PDF_VIEWER_SET,
  RFI_SHOW_ERROR_MESSAGE_SET,
  RFI_ADDL_ASSIGNEES_ADD,
  RFI_ADDL_ASSIGNEES_UPDATE,
  RFI_ADDL_ASSIGNEES_DELETE,
  RFI_ADDL_ASSIGNEE_RESPOND_SUCCESS,
  RFI_MODAL_RESPONSE_SHOW_SET,
  RFI_MODAL_RESPONSES_VIEW_SHOW_SET,
} from 'components/admin/projects/details/project-rfi-log/store/action-types';

export function initRfiLog() {
  return {
    type: RFI_LOG_INIT,
  };
}

export function loadRfisSuccess(rfisData, page, status, dueDateFilter) {
  return {
    type: RFIS_SET,
    payload: {
      ...rfisData,
      currentPage: page,
      currentStatus: status,
      dueDateFilter,
    },
  };
}

export function loadRfisRequest(projectId, page, status, dueDateFilter) {
  return (dispatch) => {
    return rfisApi
      .getRfis(projectId, page, status, dueDateFilter)
      .then((response) => {
        if (response.ok) {
          dispatch(loadRfisSuccess(response, page, status, dueDateFilter));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setRfiTabSelected(rfiTab) {
  return {
    type: RFI_TAB_SELECTED_SET,
    payload: rfiTab,
  };
}

export function setRfiModalShow(show) {
  return {
    type: RFI_MODAL_SHOW_SET,
    payload: show,
  };
}

export function initRfiModal() {
  return {
    type: RFI_MODAL_INIT,
  };
}

export function setRfiResponseModalShow(show) {
  return {
    type: RFI_MODAL_RESPONSE_SHOW_SET,
    payload: show,
  };
}

export function setRfiResponsesViewModalShow(show) {
  return {
    type: RFI_MODAL_RESPONSES_VIEW_SHOW_SET,
    payload: show,
  };
}

export function updateRfiFormValue(attribute, value) {
  return {
    type: RFI_FORM_VALUE_UPDATE,
    payload: { attribute: attribute, value: value },
  };
}

export function updateRfiQuestionValue(key, attribute, value) {
  return {
    type: RFI_QUESTION_VALUE_UPDATE,
    payload: { key: key, attribute: attribute, value: value },
  };
}

export function setRfiQuestionErrors(key, errors) {
  return {
    type: RFI_QUESTION_ERRORS_SET,
    payload: { key, errors },
  };
}

export function addRfiQuestion(key, item) {
  return {
    type: RFI_QUESTION_ADD,
    payload: { key: key, item: item },
  };
}

export function attachRfiQuestions(questions) {
  return {
    type: RFI_QUESTIONS_ATTACH,
    payload: questions,
  };
}

export function deleteRfiQuestion(key) {
  return {
    type: RFI_QUESTION_DELETE,
    payload: key,
  };
}

export function createRfiSuccess(rfi) {
  return {
    type: RFIS_ADD,
    payload: rfi,
  };
}

export function createRfi(projectId, values) {
  return (dispatch) => {
    return rfisApi.createRfi(projectId, values).then((response) => {
      if (response.ok) {
        dispatch(createRfiSuccess(response.rfi));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function updateRfiSuccess(rfi) {
  return {
    type: RFIS_UPDATE,
    payload: rfi,
  };
}

export function updateRfi(projectId, rfiId, values) {
  return (dispatch) => {
    return rfisApi.updateRfi(projectId, rfiId, values).then((response) => {
      if (response.ok) {
        dispatch(updateRfiSuccess(response.rfi));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function respondRfi(projectId, rfiId, values) {
  return (dispatch) => {
    return rfisApi.respondRfi(projectId, rfiId, values).then((response) => {
      if (response.ok) {
        dispatch(updateRfiSuccess(response.rfi));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function respondRfiAddlAssignee(userId, questions) {
  return (dispatch) => {
    const promises = [];
    questions.forEach((qus) => {
      const addlAssignee = qus.addlAssignees.find(
        (addlAssignee) => addlAssignee.assigneeId === userId
      );
      const addlAssigneeId = addlAssignee && addlAssignee.id;
      if (addlAssigneeId) {
        promises.push(
          rfiAddlAssigneesApi.updaterfiAddlAssignee(addlAssigneeId, qus)
        );
      }
    });
    return Promise.all(promises).then((values) => {
      const result = {};
      if (!values.find((value) => !value.ok)) {
        values.forEach((value) => {
          dispatch(respondRfiAddlAssigneeSuccess(value.rfiAddlAssignee));
        });
        result.ok = true;
      } else {
        const errors = [];
        values.forEach((value) => {
          if (!value.ok) {
            errors.push(value.errors);
          }
        });
        dispatch(showErrorMessage(errors));
        result.ok = false;
      }

      return result;
    });
  };
}

export function setCurrentRfi(rfi) {
  return {
    type: CURRENT_RFI_SET,
    payload: rfi,
  };
}

export function setRfiReadOnly(readOnly) {
  return {
    type: RFI_READ_ONLY_SET,
    payload: readOnly,
  };
}

export function loadRfiRequest(projectId, rfiId) {
  return (dispatch) => {
    return rfisApi.getRfi(projectId, rfiId).then((response) => {
      if (!response.ok) {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function setRfiFormValues(rfi) {
  return {
    type: RFI_FORM_VALUES_SET,
    payload: rfi,
  };
}

export function setRfiFormErrors(errors) {
  return {
    type: RFI_FORM_ERRORS_SET,
    payload: errors,
  };
}

export function deleteQuestionFile(key, attribute, value) {
  return {
    type: RFI_QUESTION_FILE_DELETE,
    payload: { key: key, attribute: attribute, value: value },
  };
}

export function loadRfiQuestionNotes(questionKey, questionId) {
  return (dispatch) => {
    return rfiQuestionsApi.getNotes(questionId).then((response) => {
      if (response.ok) {
        dispatch(updateRfiQuestionValue(questionKey, 'notes', response.notes));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function markRfiQuestionNotesRead(questionKey, question, userId) {
  return (dispatch) => {
    return rfiQuestionsApi.markNotesRead(question.id).then((response) => {
      if (response.ok) {
        const notesReadBy = question.notesReadBy.concat(userId.toString());
        dispatch(
          updateRfiQuestionValue(questionKey, 'notesReadBy', notesReadBy)
        );
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function addRfiQuestionNote(key, note) {
  return {
    type: RFI_QUESTION_NOTE_ADD,
    payload: { key: key, note: note },
  };
}

export function createRfiQuestionNote(questionKey, questionId, content) {
  return (dispatch) => {
    return rfiQuestionsApi.createNote(questionId, content).then((response) => {
      if (response.ok) {
        dispatch(addRfiQuestionNote(questionKey, response.note));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function openRfi(projectId, rfiId, readOnly = false) {
  return (dispatch) => {
    dispatch(loadRfiRequest(projectId, rfiId)).then((response) => {
      if (response.ok) {
        dispatch(setCurrentRfi(response.rfi));
        dispatch(setRfiReadOnly(readOnly));
        dispatch(setRfiModalShow(true));
      }
    });
  };
}

export function openRfiResponseModalWithData(
  projectId,
  rfiId,
  readOnly = false
) {
  return (dispatch) => {
    dispatch(loadRfiRequest(projectId, rfiId)).then((response) => {
      if (response.ok) {
        dispatch(setCurrentRfi(response.rfi));
        dispatch(setRfiReadOnly(readOnly));
        dispatch(setRfiResponseModalShow(true));
      }
    });
  };
}

export function openRfiResponsesViewModalWithData(
  projectId,
  rfiId,
  readOnly = false
) {
  return (dispatch) => {
    dispatch(loadRfiRequest(projectId, rfiId)).then((response) => {
      if (response.ok) {
        dispatch(setCurrentRfi(response.rfi));
        dispatch(setRfiReadOnly(readOnly));
        dispatch(setRfiResponseModalShow(true));
      }
    });
  };
}

export function setRfiGcAcceleration(gcAcceleration) {
  return {
    type: RFI_GC_ACCELERATION_SET,
    payload: gcAcceleration,
  };
}

export function setRfiLogPdfViewer(pdfViewer) {
  return {
    type: RFI_LOG_PDF_VIEWER_SET,
    payload: pdfViewer,
  };
}

export function loadRfiLogPdfSuccess(rfiLogPdf) {
  return {
    type: RFI_LOG_PDF_SET,
    payload: rfiLogPdf,
  };
}

export function loadRfiLogPdfRequest(projectId, status = 'not_pre') {
  return (dispatch) => {
    return rfisApi.getRfiLogPdf(projectId, status).then((response) => {
      if (response.ok) {
        dispatch(loadRfiLogPdfSuccess(response.rfiLogPdf));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function setRfiPdfViewer(pdfViewer) {
  return {
    type: RFI_PDF_VIEWER_SET,
    payload: pdfViewer,
  };
}

export function deleteRfiSuccess(rfiId) {
  return {
    type: RFIS_DELETE,
    payload: rfiId,
  };
}

export function deleteRfi(projectId, rfiId) {
  return (dispatch) => {
    return rfisApi.deleteRfi(projectId, rfiId).then((response) => {
      if (response.ok) {
        dispatch(deleteRfiSuccess(rfiId));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function setSetShowRfiErrorMessage(showErrorMessage) {
  return {
    type: RFI_SHOW_ERROR_MESSAGE_SET,
    payload: showErrorMessage,
  };
}

export function addRfiAddlAssignee(key, addlAssignee) {
  return {
    type: RFI_ADDL_ASSIGNEES_ADD,
    payload: { key, addlAssignee },
  };
}

export function updateRfiAddlAssignee(key, addlAssignee, index) {
  return {
    type: RFI_ADDL_ASSIGNEES_UPDATE,
    payload: { key, addlAssignee, index },
  };
}

export function deleteRfiAddlAssignee(key, index) {
  return {
    type: RFI_ADDL_ASSIGNEES_DELETE,
    payload: { key, index },
  };
}

export function respondRfiAddlAssigneeSuccess(addlAssignee) {
  return {
    type: RFI_ADDL_ASSIGNEE_RESPOND_SUCCESS,
    payload: addlAssignee,
  };
}
