export const CLIENTS_CLIENT_BUILDINGS_LOAD_SUCCESS =
  'CLIENTS_CLIENT_BUILDINGS_LOAD_SUCCESS';
export const CLIENTS_CLIENT_FILTER_CHANGE = 'CLIENTS_CLIENT_FILTER_CHANGE';
export const CLIENTS_CLIENT_INVITE_USER_SHOW =
  'CLIENTS_CLIENT_INVITE_USER_SHOW';
export const CLIENTS_CLIENT_LOAD_SUCCESS = 'CLIENTS_CLIENT_LOAD_SUCCESS';
export const CLIENTS_CLIENT_INVOICES_LOAD_SUCCESS =
  'CLIENTS_CLIENT_INVOICES_LOAD_SUCCESS';
export const CLIENTS_CLIENT_SET = 'CLIENTS_CLIENT_SET';
export const CLIENTS_CLIENT_NOTE_EDITOR_EDIT_SET =
  'CLIENTS_CLIENT_NOTE_EDITOR_EDIT_SET';
export const CLIENTS_CLIENT_NOTE_EDITOR_REMINDER_CHANGE =
  'CLIENTS_CLIENT_NOTE_EDITOR_REMINDER_CHANGE';
export const CLIENTS_CLIENT_NOTE_EDITOR_SAVING_SET =
  'CLIENTS_CLIENT_NOTE_EDITOR_SAVING_SET';
export const CLIENTS_CLIENT_NOTE_EDITOR_TEXT_CHANGE =
  'CLIENTS_CLIENT_NOTE_EDITOR_TEXT_CHANGE';
export const CLIENTS_CLIENT_PAGE_CHANGE = 'CLIENTS_CLIENT_PAGE_CHANGE';
export const CLIENTS_CLIENT_PURCHASE_ORDERS_SUCCESS =
  'CLIENTS_CLIENT_PURCHASE_ORDERS_SUCCESS';
export const CLIENTS_CLIENT_ROLES_LOAD_SUCCESS =
  'CLIENTS_CLIENT_ROLES_LOAD_SUCCESS';
export const CLIENTS_CLIENT_SURVEYS_LOAD_SUCCESS =
  'CLIENTS_CLIENT_SURVEYS_LOAD_SUCCESS';
export const CLIENTS_CLIENT_USERS_ADD = 'CLIENTS_CLIENT_USERS_ADD';
export const CLIENTS_CLIENT_WORK_ORDERS_LOAD_SUCCESS =
  'CLIENTS_CLIENT_WORK_ORDERS_LOAD_SUCCESS';
export const CLIENTS_CONTACTS_MODAL_SHOW = 'CLIENTS_CONTACTS_MODAL_SHOW';
export const CLIENTS_INVITE_ATTRIBUTE_CHANGE =
  'CLIENTS_INVITE_ATTRIBUTE_CHANGE';
export const CLIENTS_INVITE_CLIENT_ADD = 'CLIENTS_INVITE_CLIENT_ADD';
export const CLIENTS_INVITE_FORM_CLEAR = 'CLIENTS_INVITE_FORM_CLEAR';
export const CLIENTS_INVITE_FORM_INVALID = 'CLIENTS_INVITE_FORM_INVALID';
export const CLIENTS_INVITE_FORM_VALID = 'CLIENTS_INVITE_FORM_VALID';
export const CLIENTS_INVITE_USER_MODAL_SHOW = 'CLIENTS_INVITE_USER_MODAL_SHOW';
export const CLIENTS_LOAD_SUCCESS = 'CLIENTS_LOAD_SUCCESS';
export const CLIENTS_USERS_SET = 'CLIENTS_USERS_SET';
export const CLIENTS_SET_PAGINATION_DATA = 'CLIENTS_SET_PAGINATION_DATA';
export const CLIENTS_ADD_TO_BEGINNING = 'CLIENTS_ADD_TO_BEGINNING';
export const CLIENTS_CLIENT_PROJECTS_LOAD_SUCCESS =
  'CLIENTS_CLIENT_PROJECTS_LOAD_SUCCESS';
