import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';

class ReportsApi extends BaseApi {
  getReport(report, page, startDate, endDate, extraFilterValues) {
    let queryString = `page=${
      page !== undefined ? page : 1
    }&start_date=${startDate}&end_date=${endDate}`;
    Object.keys(extraFilterValues).forEach((attr) => {
      if (extraFilterValues[attr].constructor === [].constructor) {
        extraFilterValues[attr].forEach(
          (value) => (queryString += `&${attr}[]=${value}`)
        );
      } else {
        queryString += `&${attr}=${extraFilterValues[attr]}`;
      }
    });

    return axios
      .get(`${this.baseUrl}/reports/${report}?${queryString}`)
      .then((response) => {
        return {
          ok: true,
          results: {
            data: response.data,
            total: numeral(response.headers['total']).value(),
            pageSize: numeral(response.headers['per-page']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  exportReport(report, ids, startDate, endDate, extraFilterValues) {
    const apiPayload = {
      work_order_ids: ids,
      start_date: startDate,
      end_date: endDate,
    };
    Object.keys(extraFilterValues).forEach(
      (attr) => (apiPayload[attr] = extraFilterValues[attr])
    );

    return axios
      .post(`${this.baseUrl}/reports/${report}/print`, apiPayload, {
        responseType: 'blob',
      })
      .then((response) => {
        return {
          ok: true,
          content: {
            data: response.data,
            type: response.headers['content-type'],
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const reportsApi = new ReportsApi();
