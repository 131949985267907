import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { countdown } from 'services/utils/date-util';
import { RfiStatusEnum } from 'domain/rfi-status-enum';
import User from 'domain/user';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import ReadOnlyInput from 'components/shared/input/ReadOnlyInput';

class RfiDetails extends Component {
  static propTypes = {
    currentRfi: PropTypes.shape({}).isRequired,
  };

  render() {
    const { currentRfi } = this.props;
    const dueDate = currentRfi.due_date
      ? moment(currentRfi.due_date).format('MM/DD/YYYY')
      : '-';
    const extendedDueDate = currentRfi.extended_due_date
      ? moment(currentRfi.extended_due_date).format('MM/DD/YYYY')
      : null;
    const resolutionDate =
      currentRfi.resolved_at &&
      moment(currentRfi.resolved_at).format('MM/DD/YYYY');
    const today = moment();
    let dueDateCountdown = currentRfi.due_date
      ? countdown(today.format('YYYY-MM-DD'), currentRfi.due_date)
      : null;
    let extendedDueDateCountdown = currentRfi.extended_due_date
      ? countdown(today.format('YYYY-MM-DD'), currentRfi.extended_due_date)
      : null;
    if (dueDateCountdown) {
      dueDateCountdown +=
        today > moment(currentRfi.due_date) ? ' past due' : ' left';
    }
    if (extendedDueDateCountdown) {
      extendedDueDateCountdown +=
        today > moment(currentRfi.extended_due_date) ? ' past due' : ' left';
    }

    const statusEnum = RfiStatusEnum.create(currentRfi.status);
    const resolved = RfiStatusEnum.RESOLVED.equals(statusEnum);
    let statusLabel = statusEnum.label;
    statusLabel = `${statusLabel.charAt(0).toUpperCase()}${statusLabel.slice(
      1
    )}`;

    return (
      <div className="rfi-details">
        <div className="form-block">
          <FormControlBlock
            label="Cost Code"
            control={
              <ReadOnlyInput
                value={`${currentRfi.csi_code.division} - ${currentRfi.csi_code.label}`}
              />
            }
          />
        </div>
        <div className="form-block">
          <FormControlBlock
            label="CC Trades"
            control={
              <ReadOnlyInput
                value={
                  currentRfi.rel_csi_codes.filter(
                    (csiCode) => csiCode.id !== currentRfi.csi_code.id
                  ).length === 0
                    ? '-'
                    : currentRfi.rel_csi_codes
                        .filter(
                          (csiCode) => csiCode.id !== currentRfi.csi_code.id
                        )
                        .sort((csiCode1, csiCode2) =>
                          csiCode1.division < csiCode2.division ? -1 : 1
                        )
                        .map(
                          (csiCode) => `${csiCode.division} - ${csiCode.label}`
                        )
                        .join(', ')
                }
              />
            }
          />
        </div>
        <div className="form-block">
          <FormControlBlock
            label="CC Recipients"
            control={
              <ReadOnlyInput
                value={
                  currentRfi.recipients.length === 0
                    ? '-'
                    : currentRfi.recipients
                        .map((rcpt) => {
                          return User.fullNameWithCompany(rcpt);
                        })
                        .join(', ')
                }
              />
            }
          />
        </div>
        <div className="form-block">
          <FormControlBlock
            label="Subcontractor"
            control={
              <ReadOnlyInput
                value={currentRfi.contractor ? currentRfi.contractor.name : '-'}
              />
            }
          />
        </div>
        <div className="form-block multicontrols-block">
          <FormControlBlock
            label="Due Date"
            bottomLabel={!resolved ? dueDateCountdown : null}
            control={<ReadOnlyInput value={dueDate} />}
          />
          {!!extendedDueDate && (
            <FormControlBlock
              label="Extended Due"
              bottomLabel={!resolved ? extendedDueDateCountdown : null}
              control={<ReadOnlyInput value={extendedDueDate} />}
            />
          )}
          {!!resolutionDate && (
            <FormControlBlock
              label="Resolution Date"
              control={<ReadOnlyInput value={resolutionDate} />}
            />
          )}
          <FormControlBlock
            label="Status"
            control={<ReadOnlyInput value={statusLabel} />}
          />
        </div>
      </div>
    );
  }
}

export default connect()(RfiDetails);
