import { push } from 'react-router-redux';
import { authApi } from 'services/api/auth-api';
import { authenticationRequest } from 'actions/authentication';

export const SET_PASSWORD_PASSWORD_UPDATE = 'SET_PASSWORD_PASSWORD_UPDATE';

export function confirmPassword(
  invitationToken,
  password,
  passwordConfirmation,
  termsAccepted
) {
  return (dispatch) => {
    return authApi
      .confirmPassword(
        invitationToken,
        password,
        passwordConfirmation,
        termsAccepted
      )
      .then((response) => {
        if (response.ok) {
          return dispatch(authenticationRequest(response.email, password)).then(
            (response) => {
              if (response.ok) {
                dispatch(push('/'));
              } else {
                dispatch(updatePassword('errors', response.errors));
              }
            }
          );
        } else {
          dispatch(updatePassword('errors', response.errors));
        }
      });
  };
}

export function updatePassword(attribute, value) {
  return {
    type: SET_PASSWORD_PASSWORD_UPDATE,
    payload: { attribute, value },
  };
}
