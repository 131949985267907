import {
  BUILDINGS_BUILDING_LOAD_SUCCESS,
  BUILDINGS_BUILDING_PROJECTS_LOAD_SUCCESS,
  BUILDINGS_LOAD_SUCCESS,
  BUILDINGS_ADD_BUILDING_TO_BEGINNING,
} from '../../../actions/admin/buildings/action-types';

const initialState = {
  buildings: [],
  clients: [],
  currentBuilding: null, // we need another attribute because the buildings list doesn't contain all the info required
  unions: [],
  workOrders: null,
  projects: null,
};

export default function buildingsDataReducer(state = initialState, action) {
  switch (action.type) {
    case BUILDINGS_BUILDING_LOAD_SUCCESS:
      return {
        ...state,
        currentBuilding: action.payload,
      };

    case BUILDINGS_BUILDING_PROJECTS_LOAD_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      };

    case BUILDINGS_LOAD_SUCCESS:
      return {
        ...state,
        buildings: action.payload,
      };

    case BUILDINGS_ADD_BUILDING_TO_BEGINNING:
      return {
        ...state,
        buildings: [action.payload, ...state.buildings],
      };

    default:
      return state;
  }
}
