import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './EnterpriseSidebar.css';
import EnterpriseSidebarMenu from './EnterpriseSidebarMenu';
import SidebarItem from './SidebarItem';
import PropTypes from 'prop-types';
import dashboardSelected from './images/dashboard-selected.png';
import dashboardNotSelected from './images/dashboard-not-selected.png';
import enterpriseSelected from './images/enterprise-selected.png';
import enterpriseNotSelected from './images/enterprise-not-selected.png';
import buildingsSelected from './images/buildings-selected.png';
import buildingsNotSelected from './images/buildings-not-selected.png';
import clientsSelected from './images/clients-selected.png';
import clientsNotSelected from './images/clients-not-selected.png';
import contractorsSelected from './images/contractors-selected.png';
import contractorsNotSelected from './images/contractors-not-selected.png';
import teamSelected from './images/team-selected.png';
import teamNotSelected from './images/team-not-selected.png';
import tutorialsSelected from './images/tutorials-selected.png';
import tutorialsNotSelected from './images/tutorials-not-selected.png';
import { getCurrentUserCompany } from 'components/admin/service-providers/store/selectors.js';
import { getCurrentContractor } from '../../../selectors/admin/contractors/contractors.js';

const itemProps = PropTypes.shape({
  icon: PropTypes.string,
  label: PropTypes.string,
  route: PropTypes.string,
  selected: PropTypes.bool,
  show: PropTypes.bool,
  customAction: PropTypes.func,
  customClass: PropTypes.string,
});

class EnterpriseSidebar extends Component {
  static propTypes = {
    sharedItemsTop: PropTypes.arrayOf(itemProps),
    enterpriseItems: PropTypes.arrayOf(itemProps),
    sharedItemsBottom: PropTypes.arrayOf(itemProps),
    onItemSelected: PropTypes.func,
    onGenerateLink: PropTypes.func,
    currentCompany: PropTypes.object,
  };

  enterpriseSelected = () => {
    return !!this.props.enterpriseItems.find((item) => item.selected);
  };

  renderSharedItems = (items) => {
    const { onItemSelected, onGenerateLink } = this.props;
    return items
      .filter((element) => element.show)
      .map((element, index) => {
        let icon;
        switch (element.icon) {
          case 'dashboard':
            icon = element.selected ? dashboardSelected : dashboardNotSelected;
            break;

          case 'buildings':
            icon = element.selected ? buildingsSelected : buildingsNotSelected;
            break;

          case 'clients':
            icon = element.selected ? clientsSelected : clientsNotSelected;
            break;

          case 'contractors':
            icon = element.selected
              ? contractorsSelected
              : contractorsNotSelected;
            break;

          case 'users':
            icon = element.selected ? teamSelected : teamNotSelected; // Share icon with team
            break;

          case 'team':
            icon = element.selected ? teamSelected : teamNotSelected;
            break;

          case 'tutorials':
            icon = element.selected ? tutorialsSelected : tutorialsNotSelected;
            break;

          default:
            icon = null;
        }

        return (
          <SidebarItem
            key={index}
            id={element.icon}
            image={icon}
            label={element.label}
            selected={element.selected}
            onSelected={onItemSelected}
            onGenerateLink={onGenerateLink}
          />
        );
      });
  };

  render() {
    const {
      sharedItemsTop,
      sharedItemsBottom,
      onItemSelected,
      onGenerateLink,
    } = this.props;

    return (
      <div className="enterprise-sidebar-admin">
        <div className="sidebar-admin-table">
          {this.renderSharedItems(sharedItemsTop)}
        </div>

        <EnterpriseSidebarMenu
          menu={{
            icon: this.enterpriseSelected()
              ? enterpriseSelected
              : enterpriseNotSelected,
            label: 'Enterprise',
            selected: this.enterpriseSelected(),
          }}
          items={this.props.enterpriseItems}
          onItemSelected={onItemSelected}
          onGenerateLink={onGenerateLink}
        />

        <div className="sidebar-admin-table">
          {this.renderSharedItems(sharedItemsBottom)}
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentCompany: getCurrentUserCompany(state) || getCurrentContractor(state),
  };
})(withRouter(EnterpriseSidebar));
