import React, { useState } from 'react';
import VideoImageThumbnail from 'react-video-thumbnail-image';
import { cropImage } from 'services/utils/images-util';
import './VideoThumb.css';

const WIDTH = 384;
const HEIGHT = 288;

const VideoThumb = (props) => {
  const { video, onCreated, ...imageProps } = props;
  const [image, setImage] = useState(null);

  const createHandler = (uri) => {
    if (typeof onCreated === 'function') onCreated(uri);
    setImage(uri);
  };
  const thumbnailHandler = (thumb) => {
    cropImage(thumb, WIDTH, HEIGHT, 0, 0, createHandler);
  };

  if (image) return <img src={image} alt="thumbnail" {...imageProps} />;

  return (
    <div className="video-thumb">
      <VideoImageThumbnail
        width={WIDTH}
        height={HEIGHT}
        videoUrl={video}
        snapshotAtTime={1}
        renderThumbnail={false}
        thumbnailHandler={thumbnailHandler}
      />
    </div>
  );
};

export default VideoThumb;
