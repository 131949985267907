import {
  SUBMITTALS_SET,
  SUBMITTAL_MODAL_INIT,
  TRANSMITTAL_PDF_SET,
  CURRENT_SUBMITTAL_SET,
  SUBMITTAL_UPDATE,
  SUBMITTAL_NOTES_SET,
  SUBMITTAL_NOTES_ADD,
  SUBMITTAL_LOG_PDF_SET,
  SUBMITTAL_ADDL_ASSIGNEE_RESPONSE,
  SUBMITTALS_DELETE,
  REVISION_SUBMITTAL_SET,
} from 'components/admin/projects/details/project-submittal-log/store/action-types';

const initialState = {
  submittals: [],
  submittalsTradeCodes: [],
  submittalNotes: [],
  total: 0,
  currentPage: 1,
  currentStatus: 'not_pre',
  pageSize: 25,
  transmittalPdf: null,
  currentSubmittal: null,
  submittalLogPdf: null,
  revisionSubmittal: null,
  dueDateFilter: 'all',
  tradeCodeFilter: 'all',
};

export default function projectSubmittalLogDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SUBMITTALS_SET:
      return {
        ...state,
        ...action.payload,
      };

    case SUBMITTAL_MODAL_INIT:
      return {
        ...state,
        submittalNotes: initialState.submittalNotes,
        transmittalPdf: initialState.transmittalPdf,
        currentSubmittal: initialState.currentSubmittal,
      };

    case TRANSMITTAL_PDF_SET:
      return {
        ...state,
        transmittalPdf: action.payload,
      };

    case CURRENT_SUBMITTAL_SET:
      return {
        ...state,
        currentSubmittal: action.payload,
      };

    case SUBMITTAL_UPDATE:
      return {
        ...state,
        submittals: state.submittals
          .map((submittal) => {
            if (submittal.id === action.payload.id) {
              return action.payload;
            } else {
              return submittal;
            }
          })
          .sort((s1, s2) => s1.division.localeCompare(s2.division)),
      };

    case SUBMITTAL_NOTES_SET:
      return {
        ...state,
        submittalNotes: action.payload,
      };

    case SUBMITTAL_NOTES_ADD:
      return {
        ...state,
        submittalNotes: [...state.submittalNotes, action.payload],
      };

    case SUBMITTAL_LOG_PDF_SET:
      return {
        ...state,
        submittalLogPdf: action.payload,
      };

    case SUBMITTAL_ADDL_ASSIGNEE_RESPONSE:
      return {
        ...state,
        submittals: state.submittals.map((submittal) => {
          if (submittal.id === action.payload.submittal_id) {
            return {
              ...submittal,
              submittal_addl_assignees: submittal.submittal_addl_assignees.map(
                (addlAssignee) => {
                  if (addlAssignee.id === action.payload.id) {
                    return action.payload;
                  } else {
                    return addlAssignee;
                  }
                }
              ),
            };
          } else {
            return submittal;
          }
        }),
      };

    case SUBMITTALS_DELETE:
      return {
        ...state,
        submittals: state.submittals.filter(
          (submittal) => submittal.id !== action.payload
        ),
      };

    case REVISION_SUBMITTAL_SET:
      return {
        ...state,
        revisionSubmittal: action.payload,
      };

    default:
      return state;
  }
}
