import { INITIAL_FILTERS, INITIAL_FORM } from 'domain/project-photo';
import {
  SET_PROJECT_PHOTOS_FILTERS,
  SET_PROJECT_PHOTOS_FORM,
  SET_PROJECT_PHOTOS_CURRENT_ALBUM,
  SET_PROJECT_PHOTOS_FAVORITE,
  SET_PROJECT_PHOTOS_CURRENT_ALBUMS,
  SET_PROJECT_PHOTOS_CURRENT_PHOTOS,
  ADD_PROJECT_PHOTOS_CURRENT_ALBUMS,
  ADD_PROJECT_PHOTOS_CURRENT_PHOTOS,
  REMOVE_PROJECT_PHOTOS_CURRENT_ALBUMS,
  REMOVE_PROJECT_PHOTOS_CURRENT_PHOTOS,
  ADD_PROJECT_PHOTOS_COMMENT,
  SET_PROJECT_PHOTOS_FIXED_ALBUMS,
  ORDER_PROJECT_PHOTOS_CURRENT_ALBUMS,
  ORDER_PROJECT_PHOTOS_CURRENT_PHOTOS,
  UPDATE_PROJECT_PHOTOS_ALBUM,
  SET_PROJECT_PHOTOS_DETAIL,
  UPDATE_PROJECT_PHOTOS_DETAIL,
  REMOVE_PROJECT_PHOTOS_DETAIL,
  OPEN_PROJECT_PHOTOS_CURRENT_DETAIL,
} from './action-types';

const initialState = {
  currentAlbum: null,
  totalAlbums: 0,
  pageAlbums: 0,
  albums: [],
  totalPhotos: 0,
  pagePhotos: 0,
  photos: [],
  detail: [],
  filters: INITIAL_FILTERS,
  form: INITIAL_FORM,
  fixedAlbums: null,
};

export default function projectPhotosDataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROJECT_PHOTOS_CURRENT_ALBUM: {
      return { ...state, currentAlbum: action.payload };
    }

    case SET_PROJECT_PHOTOS_CURRENT_ALBUMS: {
      const { albums: data, page, total } = action.payload;

      if (page > 1 && state.pageAlbums === page) return state;

      let albums = data;
      if (page > 1) albums = [...state.albums, ...data];

      return { ...state, pageAlbums: page, totalAlbums: total, albums };
    }

    case ADD_PROJECT_PHOTOS_CURRENT_ALBUMS: {
      const albums = [...action.payload, ...state.albums];
      const totalAlbums = state.totalAlbums + action.payload.length;
      const currentAlbum = state.currentAlbum
        ? {
            ...state.currentAlbum,
            count_albums:
              state.currentAlbum.count_albums + action.payload.length,
          }
        : null;

      return { ...state, albums, totalAlbums, currentAlbum };
    }

    case UPDATE_PROJECT_PHOTOS_ALBUM: {
      const album = action.payload;
      const albums = [...state.albums];

      const index = albums.find((a) => a.id === album.id);
      if (index >= 0) albums[index] = album;

      return { ...state, albums };
    }

    case ORDER_PROJECT_PHOTOS_CURRENT_ALBUMS: {
      const orderIds = action.payload;
      const albums = orderIds.map((id) =>
        state.albums.find((a) => a.id === id)
      );

      return { ...state, albums };
    }

    case REMOVE_PROJECT_PHOTOS_CURRENT_ALBUMS: {
      const albums = state.albums.filter((a) => !action.payload.includes(a.id));
      const totalAlbums = state.totalAlbums - action.payload.length;
      const currentAlbum = state.currentAlbum
        ? {
            ...state.currentAlbum,
            count_albums:
              state.currentAlbum.count_albums - action.payload.length,
          }
        : null;
      return { ...state, albums, totalAlbums, currentAlbum };
    }

    case SET_PROJECT_PHOTOS_CURRENT_PHOTOS: {
      const { photos: data, page, total } = action.payload;
      if (page > 1 && state.pagePhotos === page) return state;

      let photos = data;
      if (page > 1) photos = [...state.photos, ...data];

      return { ...state, pagePhotos: page, totalPhotos: total, photos };
    }

    case ADD_PROJECT_PHOTOS_CURRENT_PHOTOS: {
      const photos = [...action.payload, ...state.photos];
      const totalPhotos = state.totalPhotos + action.payload.length;
      const currentAlbum = state.currentAlbum
        ? {
            ...state.currentAlbum,
            count_photos:
              state.currentAlbum.count_photos + action.payload.length,
          }
        : null;

      return { ...state, photos, totalPhotos, currentAlbum };
    }

    case ORDER_PROJECT_PHOTOS_CURRENT_PHOTOS: {
      const orderIds = action.payload;
      const photos = orderIds.map((id) =>
        state.photos.find((p) => p.id === id)
      );

      return { ...state, photos };
    }

    case REMOVE_PROJECT_PHOTOS_CURRENT_PHOTOS: {
      const photos = state.photos.filter((p) => !action.payload.includes(p.id));
      const totalPhotos = state.totalPhotos - action.payload.length;
      const currentAlbum = state.currentAlbum
        ? {
            ...state.currentAlbum,
            count_photos:
              state.currentAlbum.count_photos - action.payload.length,
          }
        : null;
      return { ...state, photos, totalPhotos, currentAlbum };
    }

    case SET_PROJECT_PHOTOS_FILTERS: {
      // reset filters
      if (typeof action.payload === 'undefined') {
        return { ...state, filters: { ...INITIAL_FILTERS } };
      }

      const filters = { ...state.filters, ...action.payload };

      // if album changes then reset albumPage and photosPage
      if (
        typeof action.payload.albumsPage === 'undefined' &&
        typeof action.payload.photosPage === 'undefined' &&
        action.payload.doSearch
      ) {
        filters.albumsPage = 1;
        filters.photosPage = 1;
      }

      return { ...state, filters };
    }

    case SET_PROJECT_PHOTOS_FORM: {
      if (typeof action.payload === 'undefined') {
        return { ...state, form: { ...INITIAL_FORM } };
      }

      return { ...state, form: { ...state.form, ...action.payload } };
    }

    case ADD_PROJECT_PHOTOS_COMMENT: {
      const comment = action.payload;
      const comments = [...state.form.comments];

      if (comment.reply_id) {
        const index = comments.findIndex((c) => c.id === comment.reply_id);
        if (index >= 0) comments[index].replies.push(comment);
      } else {
        comments.push(comment);
      }

      return { ...state, form: { ...state.form, comments } };
    }

    case SET_PROJECT_PHOTOS_FAVORITE: {
      const { ids, value: favorite, type } = action.payload;

      const detail = [...state.detail];
      ids.forEach((id) => {
        const index = detail.findIndex((p) => p.id === id);
        if (index >= 0) detail[index] = { ...detail[index], favorite };
      });

      if (type === 'current') {
        return {
          ...state,
          currentAlbum: { ...state.currentAlbum, favorite },
          detail,
        };
      } else if (type === 'album') {
        const albums = [...state.albums];

        ids.forEach((id) => {
          const index = state.albums.findIndex((a) => a.id === id);
          if (index >= 0) albums[index] = { ...albums[index], favorite };
        });

        return { ...state, albums, detail };
      } else if (type === 'photo') {
        const photos = [...state.photos];

        ids.forEach((id) => {
          const index = state.photos.findIndex((a) => a.id === id);
          if (index >= 0) photos[index] = { ...photos[index], favorite };
        });

        return { ...state, photos, detail };
      }

      return state;
    }

    case SET_PROJECT_PHOTOS_FIXED_ALBUMS: {
      return { ...state, fixedAlbums: action.payload };
    }

    case SET_PROJECT_PHOTOS_DETAIL: {
      if (typeof action.payload === 'undefined')
        return { ...state, detail: [] };

      return { ...state, detail: [...state.detail, ...action.payload] };
    }

    case UPDATE_PROJECT_PHOTOS_DETAIL: {
      const { id, fields } = action.payload;

      const detail = [...state.detail];
      const index = detail.findIndex((d) => d.id === id);

      if (index >= 0) detail[index] = { ...detail[index], ...fields };

      return { ...state, detail };
    }

    case REMOVE_PROJECT_PHOTOS_DETAIL: {
      const detail = state.detail.filter((d) => d.id !== action.payload);

      return { ...state, detail };
    }

    case OPEN_PROJECT_PHOTOS_CURRENT_DETAIL: {
      const detail = [...state.photos];
      return { ...state, detail };
    }

    default:
      return state;
  }
}
