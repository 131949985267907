import React, { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';

import { Grid } from '@mui/material';
import FormModal from 'components/shared/modal/FormModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getProjectPhotosForm,
  getProjectPhotosShowTrades,
} from './store/selectors';
import { setProjectPhotosShowTrades } from './store/actions';
import ProjectPhotosFilterTrades from './ProjectPhotosFilterTrades';

const ProjectPhotosFormTradesModal = (props) => {
  const { dispatch, form, show, directory, currentProject, onChange } = props;
  const [value, setValue] = useState(form.trades);

  useEffect(() => {
    if (show) {
      setValue(form.trades);

      if (currentProject.id) {
        if (!directory || directory.project_id !== currentProject.id) {
          dispatch(loadDirectoryRequest(currentProject.id));
        }
      }
    }
  }, [show]);

  const onClose = () => {
    dispatch(setProjectPhotosShowTrades(false));
  };
  const onSave = () => {
    onChange({ trades: value });
    onClose();
  };

  return (
    <FormModal
      className="project-photos-trades-modal"
      show={show}
      onClose={onClose}
      subTitle="Trades"
    >
      <Grid
        container
        justifyContent="center"
        paddingX={4}
        paddingBottom={2.5}
        spacing={4}
      >
        <Grid item xs={12}>
          <ProjectPhotosFilterTrades
            value={value}
            onChange={({ trades }) => setValue(trades)}
            enableAdd
          />
        </Grid>
        <Grid item>
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label={'Save'}
            onClick={onSave}
          />
        </Grid>
      </Grid>
    </FormModal>
  );
};

export default connect((state) => ({
  form: getProjectPhotosForm(state),
  show: getProjectPhotosShowTrades(state),
  directory: getDirectory(state),
  currentProject: getCurrentProject(state),
}))(ProjectPhotosFormTradesModal);
