import {
  SHOW_NEW_CONTRIBUTOR_MODAL_SET,
  SHOW_EDIT_USER_MODAL_SET,
  NEW_CONTRIBUTOR_MODAL_INIT,
  SHOW_NEW_COMPANY_FORM_SET,
  SHOW_SELECT_USERS_FORM_SET,
  SHOW_INVITE_USER_FORM_SET,
  SHOW_DIRECTORY_PDF_VIEWER_SET,
  CURRENT_CONTRIBUTOR_SET,
  NEW_CONTRIBUTOR_TYPE_SET,
  CONTRIBUTOR_SETTINGS_TOGGLE,
  USER_SETTINGS_TOGGLE,
  SET_SHOW_NOTIFICATION_SETTINGS_MODAL,
} from 'components/admin/projects/details/project-directory/store/action-types';

const initialState = {
  showNewContributorModal: false,
  showEditUserModal: false,
  showNewCompanyForm: false,
  showSelectUsersForm: false,
  showInviteUserForm: false,
  showDirectoryPdfViewer: false,
  showNotificationSettingsModal: false,
  newContributorType: null,
  contributorSettings: {},
  userSettings: {},
  editedUser: {},
  currentContributor: {},
};

export default function projectDirectoryUiReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SHOW_NEW_CONTRIBUTOR_MODAL_SET:
      return {
        ...state,
        showNewContributorModal: action.payload,
      };

    case SHOW_EDIT_USER_MODAL_SET:
      return {
        ...state,
        showEditUserModal: action.payload.showEditUserModal,
        editedUser: action.payload.user,
      };

    case NEW_CONTRIBUTOR_MODAL_INIT:
      return {
        ...state,
        newContributorType: initialState.newContributorType,
      };

    case SHOW_NEW_COMPANY_FORM_SET:
      return {
        ...state,
        showNewCompanyForm: action.payload,
      };

    case SHOW_SELECT_USERS_FORM_SET:
      return {
        ...state,
        showSelectUsersForm: action.payload,
      };

    case SHOW_INVITE_USER_FORM_SET:
      return {
        ...state,
        showInviteUserForm: action.payload,
      };

    case SHOW_DIRECTORY_PDF_VIEWER_SET:
      return {
        ...state,
        showDirectoryPdfViewer: action.payload,
      };

    case SET_SHOW_NOTIFICATION_SETTINGS_MODAL:
      return {
        ...state,
        showNotificationSettingsModal: action.payload,
      };

    case CURRENT_CONTRIBUTOR_SET:
      return {
        ...state,
        currentContributor: action.payload,
      };

    case NEW_CONTRIBUTOR_TYPE_SET:
      return {
        ...state,
        newContributorType: action.payload,
      };

    case CONTRIBUTOR_SETTINGS_TOGGLE:
      return {
        ...state,
        contributorSettings: {
          [action.payload]: !state.contributorSettings[action.payload],
        },
      };

    case USER_SETTINGS_TOGGLE:
      return {
        ...state,
        userSettings: {
          [action.payload]: !state.userSettings[action.payload],
        },
      };

    default:
      return state;
  }
}
