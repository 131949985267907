import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './SubmittalForm.css';
import { Form } from 'react-bootstrap';
import Selector from 'components/shared/selector/Selector';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import Input from 'components/shared/input/Input';
import moment from 'moment';
import {
  todayValidation,
  beforeTodayValidation,
} from 'services/utils/date-util';
import { countdown } from 'services/utils/date-util';
import Project from 'domain/project';
import {
  SubmittalStatusKeys,
  SubmittalStatusEnum,
  submittalStatusLabel,
} from 'domain/submittal-status-enum';
import {
  loadProjectProposalRequest,
  loadCsiCodes,
} from 'components/admin/projects/details/project-proposal/store/actions';
import {
  getProjectProposal,
  getCsiCodes,
} from 'components/admin/projects/details/project-proposal/store/selectors';
import {
  getSubmittalForm,
  getSubmittalReadOnly,
} from 'components/admin/projects/details/project-submittal-log/store/selectors';
import {
  updateSubmittalFormValue,
  clearSubmittalNotes,
} from 'components/admin/projects/details/project-submittal-log/store/actions';
import { getDarkMode } from 'selectors/theme';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import ReadOnlyInput from 'components/shared/input/ReadOnlyInput';
import DatePicker from 'components/shared/date-picker/DatePicker';
import User from 'domain/user';
import SubmittalPermissions from 'permissions/submittal-permissions';
import CollapsibleSection from '../../../../shared/collapsible-section/CollapsibleSection';

class SubmittalForm extends Component {
  static propTypes = {
    currentUser: PropTypes.shape({}).isRequired,
    project: PropTypes.shape({}).isRequired,
    directory: PropTypes.shape({}).isRequired,
    submittal: PropTypes.shape({}),
  };

  componentDidMount() {
    const { currentUser, project } = this.props;

    if (!User.isContractor(currentUser, project)) this.loadCsiCodes();
  }

  componentWillUnmount() {
    this.props.dispatch(clearSubmittalNotes());
  }

  loadCsiCodes = () => {
    const { project, projectProposal, csiCodes, dispatch } = this.props;

    if (projectProposal && projectProposal.project_id === project.id) return;

    dispatch(loadProjectProposalRequest(project.id)).then((response) => {
      if (response.projectProposal || (csiCodes && csiCodes.length > 0)) return;

      dispatch(loadCsiCodes());
    });
  };

  handleFormChange = (attribute, value) => {
    this.props.dispatch(updateSubmittalFormValue(attribute, value));
  };

  handleCsiCodeChange = (csiCodeId) => {
    this.handleFormChange('csiCodeId', csiCodeId);
    this.handleFormChange('contractorId', '');

    const subOptions = this.submittalSubcontractorOptions(csiCodeId);
    if (subOptions.length === 1) {
      this.handleFormChange('contractorId', subOptions[0].value);
    }
  };

  submittalCsiCodeOptions = () => {
    const {
      project,
      directory,
      currentUser,
      projectProposal,
      csiCodes,
    } = this.props;
    return Project.csiCodesOptions({
      project,
      directory,
      currentUser,
      projectProposal,
      csiCodes,
    });
  };

  submittalSubcontractorOptions = (csiCodeId) => {
    const { project, directory } = this.props;
    return Project.subcontractorOptions({
      project,
      directory,
      csiCodeId,
    });
  };

  submittalStatusOptions = () => {
    return Object.keys(SubmittalStatusKeys)
      .filter(
        (status) =>
          !SubmittalStatusEnum.DRAFT.equals(SubmittalStatusEnum[status]) &&
          !SubmittalStatusEnum.DISCARDED.equals(SubmittalStatusEnum[status])
      )
      .map((status) => {
        const statusEnum = SubmittalStatusEnum[status];
        return {
          value: statusEnum.status,
          label: `${statusEnum.label
            .charAt(0)
            .toUpperCase()}${statusEnum.label.slice(1)}`,
        };
      });
  };

  render() {
    const { currentUser, project, directory, submittal, form } = this.props;

    const subcontractor = User.isContractor(currentUser, project);

    const readOnly =
      this.props.readOnly ||
      !SubmittalPermissions.canEdit(currentUser, project, directory, submittal);

    const canAssign = SubmittalPermissions.canAssign(
      currentUser,
      project,
      directory,
      submittal
    );

    const canEditStatus = SubmittalPermissions.canEditStatus(
      currentUser,
      project,
      form
    );

    const subOptions = this.submittalSubcontractorOptions(form.csiCodeId);

    const stampAction =
      form.assignee_stamp &&
      form.assignee_stamp.stamp_actions.find(
        (stampAction) => stampAction.action === form.stampAction
      );
    const stampTitle = stampAction && stampAction.title;

    const assignee = form.assignee;
    const today = moment();
    let requestedDateCountdown =
      form && form.requestedDate
        ? countdown(today.format('YYYY-MM-DD'), form.requestedDate)
        : null;
    if (requestedDateCountdown) {
      requestedDateCountdown += ' ago';
    }
    let dueDateCountdown =
      form && form.dueDate
        ? countdown(today.format('YYYY-MM-DD'), form.dueDate)
        : null;
    let extendedDueDateCountdown =
      form && form.extendedDueDate
        ? countdown(today.format('YYYY-MM-DD'), form.extendedDueDate)
        : null;
    if (dueDateCountdown) {
      dueDateCountdown += today > moment(form.dueDate) ? ' past due' : ' left';
    }
    if (extendedDueDateCountdown) {
      extendedDueDateCountdown +=
        today > moment(form.extendedDueDate) ? ' past due' : ' left';
    }

    return (
      <div className="submittal-form">
        <CollapsibleSection title="Submitall Information">
          <Form horizontal>
            <div className="form-block">
              <FormControlBlock
                label={!!form.csiCodeId && 'Cost Code'}
                control={
                  <Selector
                    placeholder="Cost Code"
                    value={form.csiCodeId}
                    onChange={(option) =>
                      this.handleCsiCodeChange(option.value)
                    }
                    options={this.submittalCsiCodeOptions()}
                    valid={!form.errors.csiCodeId}
                    readOnly={readOnly}
                  />
                }
              />
            </div>
            <div className="form-block">
              <FormControlBlock
                label={
                  (readOnly || form.physicalCopies !== null) &&
                  'Physical Copies'
                }
                control={
                  <Input
                    type="number"
                    placeholder="Physical Copies"
                    value={
                      form.physicalCopies !== null
                        ? form.physicalCopies
                        : readOnly
                        ? '-'
                        : ''
                    }
                    onChange={(value) =>
                      this.handleFormChange('physicalCopies', value)
                    }
                    readOnly={readOnly}
                  />
                }
              />
              <FormControlBlock
                className="submittal-form-status"
                label="Status"
                control={
                  !canEditStatus ? (
                    <ReadOnlyInput
                      value={submittalStatusLabel({
                        ...form,
                        stamp_title: stampTitle,
                      })}
                    />
                  ) : (
                    <Selector
                      value={form.status}
                      onChange={(option) =>
                        this.handleFormChange('status', option.value)
                      }
                      options={this.submittalStatusOptions()}
                      valid={!form.errors.status}
                    />
                  )
                }
              />
            </div>
            {(readOnly || (!subcontractor && subOptions.length > 1)) && (
              <div className="form-block">
                <FormControlBlock
                  label={(readOnly || !!form.contractorId) && 'Subcontractor'}
                  control={
                    readOnly ? (
                      <ReadOnlyInput
                        value={form.contractor ? form.contractor.name : '-'}
                      />
                    ) : (
                      <Selector
                        placeholder="Subcontractor"
                        value={form.contractorId}
                        onChange={(option) =>
                          this.handleFormChange('contractorId', option.value)
                        }
                        options={subOptions}
                      />
                    )
                  }
                />
              </div>
            )}
            <div className="form-block multicontrols-block">
              {canAssign && (
                <FormControlBlock
                  label={(readOnly || !!form.requestedDate) && 'Requested Date'}
                  bottomLabel={!form.closed ? requestedDateCountdown : null}
                  control={
                    readOnly ? (
                      <ReadOnlyInput
                        value={
                          form.requestedDate
                            ? moment(form.requestedDate).format('MM/DD/YYYY')
                            : '-'
                        }
                      />
                    ) : (
                      <DatePicker
                        placeholder="Requested Date"
                        clearable={true}
                        isValidDate={beforeTodayValidation}
                        value={form.requestedDate}
                        onChange={(value) =>
                          this.handleFormChange('requestedDate', value)
                        }
                      />
                    )
                  }
                />
              )}
              <FormControlBlock
                label={(readOnly || !!form.dueDate) && 'Due Date'}
                bottomLabel={!form.closed ? dueDateCountdown : null}
                control={
                  readOnly ? (
                    <ReadOnlyInput
                      value={
                        form.dueDate
                          ? moment(form.dueDate).format('MM/DD/YYYY')
                          : '-'
                      }
                    />
                  ) : (
                    <DatePicker
                      placeholder="Due Date"
                      clearable={true}
                      isValidDate={todayValidation}
                      value={form.dueDate}
                      onChange={(value) =>
                        this.handleFormChange('dueDate', value)
                      }
                      valid={!form.errors.dueDate}
                    />
                  )
                }
              />
              {assignee && (!readOnly || form.extendedDueDate) && (
                <FormControlBlock
                  label={
                    (readOnly || !!form.extendedDueDate) && 'Extended Due Date'
                  }
                  bottomLabel={!form.closed ? extendedDueDateCountdown : null}
                  control={
                    readOnly ? (
                      <ReadOnlyInput
                        value={moment(form.extendedDueDate).format(
                          'MM/DD/YYYY'
                        )}
                      />
                    ) : (
                      <DatePicker
                        placeholder="Extended Due"
                        clearable={true}
                        isValidDate={todayValidation}
                        value={form.extendedDueDate}
                        onChange={(value) =>
                          this.handleFormChange('extendedDueDate', value)
                        }
                        valid={!form.errors.extendedDueDate}
                      />
                    )
                  }
                />
              )}
            </div>
            <div className="form-block">
              <FormControlBlock
                label={(readOnly || !!form.title) && 'Title'}
                control={
                  <TextareaAutosizeWrapper
                    placeholder="Title"
                    initialValue={form.title || (readOnly ? '-' : '')}
                    valid={!form.errors.title}
                    disabled={readOnly}
                    onChange={(value) => this.handleFormChange('title', value)}
                  />
                }
              />
            </div>
          </Form>
        </CollapsibleSection>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    projectProposal: getProjectProposal(state),
    csiCodes: getCsiCodes(state),
    form: getSubmittalForm(state),
    readOnly: getSubmittalReadOnly(state),
    darkMode: getDarkMode(state),
  };
})(SubmittalForm);
