import { SET_EMAIL_HISTORY } from 'components/admin/projects/details/project-email-modal/store/action-types';

export const initialState = {
  history: [],
};

export default function projectEmailModalDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_EMAIL_HISTORY:
      return {
        ...state,
        history: action.payload,
      };
    default:
      return state;
  }
}
