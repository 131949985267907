import {
  MEETING_INFO_SET,
  MEETINGS_SET,
  MEETING_CATEGORIES_SET,
  MEETINGS_ADD,
  CURRENT_MEETING_SET,
  MEETINGS_UPDATE,
  MEETING_PDF_SET,
  MEETING_TYPES_SET,
} from 'components/admin/projects/details/project-meeting-minutes/store/action-types';

const initialState = {
  meetingInfo: null,
  meetings: [],
  meetingCategories: [],
  meetingTypes: null,
  currentMeeting: null,
  meetingPdf: null,
};

export default function projectMeetingMinutesDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case MEETING_INFO_SET:
      return {
        ...state,
        meetingInfo: action.payload,
      };

    case MEETINGS_SET:
      return {
        ...state,
        meetings: action.payload,
      };

    case MEETING_PDF_SET:
      return {
        ...state,
        meetingPdf: action.payload,
      };

    case MEETING_CATEGORIES_SET:
      return {
        ...state,
        meetingCategories: action.payload,
      };

    case MEETINGS_ADD:
      return {
        ...state,
        meetings: [action.payload, ...state.meetings],
      };

    case CURRENT_MEETING_SET:
      return {
        ...state,
        currentMeeting: action.payload,
      };

    case MEETINGS_UPDATE:
      return {
        ...state,
        meetings: state.meetings.map((meeting) => {
          if (meeting.id === action.payload.id) {
            return action.payload;
          } else {
            return meeting;
          }
        }),
      };

    case MEETING_TYPES_SET:
      return {
        ...state,
        meetingTypes: action.payload,
      };

    default:
      return state;
  }
}
