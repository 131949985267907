import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import PropTypes from 'prop-types';
import SearchableDropDownPaginated from 'components/shared/dropdown/SearchableDropDownPaginated';
import TextareaAutosize from 'react-autosize-textarea';
import moment from 'moment';
import classnames from 'classnames';
import Menu, { MENU_SELECTOR } from 'components/shared/menu/MenuOld';

class MeetingItem extends Component {
  static propTypes = {
    itemKey: PropTypes.string.isRequired,
    item: PropTypes.shape({}).isRequired,
    meetingItemSettings: PropTypes.string,
    reviewMeeting: PropTypes.bool.isRequired,
    handleMeetingItemSettingsClick: PropTypes.func.isRequired,
    handleMeetingInfoMenuClick: PropTypes.func.isRequired,
    handleMeetingItemChange: PropTypes.func.isRequired,
    renderMenuForm: PropTypes.func.isRequired,
    loadContributors: PropTypes.func.isRequired,
  };

  render() {
    const { itemKey, item, darkMode } = this.props;
    return (
      <tr>
        <td>
          {this.props.reviewMeeting || !item.code ? (
            ''
          ) : (
            <Menu
              selector={MENU_SELECTOR.MORE_OPTIONS_VERTICAL}
              placement="bottom-start"
              items={
                this.props.meetingItemSettings === 'new-info'
                  ? [
                      {
                        content: this.props.renderMenuForm(itemKey, 'new-info'),
                      },
                    ]
                  : this.props.meetingItemSettings === 'mark-resolved'
                  ? [
                      {
                        content: this.props.renderMenuForm(
                          itemKey,
                          'mark-resolved'
                        ),
                      },
                    ]
                  : [
                      !item.newItem && {
                        content: 'Add New Information',
                        closeOnSelect: false,
                        onClick: () =>
                          this.props.handleMeetingInfoMenuClick(
                            null,
                            itemKey,
                            'new-info'
                          ),
                      },
                      item.resolved
                        ? {
                            content: 'Mark as Unresolved',
                            closeOnSelect: false,
                            onClick: () =>
                              this.props.handleMeetingInfoMenuClick(
                                null,
                                itemKey,
                                'mark-unresolved'
                              ),
                          }
                        : {
                            content: 'Mark as Resolved',
                            closeOnSelect: false,
                            onClick: () =>
                              this.props.handleMeetingInfoMenuClick(
                                null,
                                itemKey,
                                'mark-resolved'
                              ),
                          },
                      {
                        content: 'Delete',
                        onClick: () =>
                          this.props.handleMeetingInfoMenuClick(
                            null,
                            itemKey,
                            'delete'
                          ),
                      },
                    ].filter((item) => item)
              }
            />
          )}
        </td>
        <td>
          <span className={!!item.newItem ? 'meeting-item-new' : ''}>
            {item.code || '-'}
          </span>
        </td>
        <td>
          {this.props.reviewMeeting ? (
            <span className={!!item.newItem ? 'meeting-item-new' : ''}>
              {item.info}
            </span>
          ) : (
            <TextareaAutosize
              rows={1}
              maxRows={4}
              className={classnames({
                'text-area': true,
                'input-area': true,
                'form-control': true,
                'dark-mode': darkMode,
                'meeting-item-new': !!item.newItem,
              })}
              placeholder="Discussion description goes here"
              defaultValue={item.info}
              onBlur={(e) =>
                this.props.handleMeetingItemChange(
                  itemKey,
                  'info',
                  e.target.value
                )
              }
            />
          )}
          {item.newInfo && (
            <span className="meeting-item-new">
              {' '}
              {moment(item.newInfoDate).format('M/D')} {item.newInfo}
              &nbsp;&nbsp;
            </span>
          )}
          {item.resolved && (
            <span className="resolved-label">
              {this.props.reviewMeeting && <span>&nbsp;&nbsp;</span>}
              RESOLVED - {moment(item.resolvedDate).format('M/D')}{' '}
              {item.resolvedNote}
            </span>
          )}
        </td>
        <td>
          {item.contributorIds.map((contrib) => {
            const contribId = contrib[0];
            const contribLabel = contrib[1];
            return this.props.reviewMeeting ? (
              <div key={contribId} className="contributor-selected">
                {contribLabel}
              </div>
            ) : (
              <div
                key={contribId}
                className="contributor-selected"
                onClick={() => {
                  this.props.handleMeetingItemChange(
                    itemKey,
                    'contributorIds',
                    item.contributorIds.filter(
                      (contrib) => contrib[0] !== contribId
                    )
                  );
                }}
              >
                <span className="remove">X</span>
                <span>{contribLabel}</span>
              </div>
            );
          })}
          {!this.props.reviewMeeting && (
            <SearchableDropDownPaginated
              loadOptions={this.props.loadContributors}
              onChange={(option) => {
                if (
                  !item.contributorIds.find(
                    (contrib) => contrib[0] === option.value
                  )
                ) {
                  this.props.handleMeetingItemChange(
                    itemKey,
                    'contributorIds',
                    item.contributorIds.concat([[option.value, option.label]])
                  );
                }
              }}
              placeholder="+ Add Contributor"
            />
          )}
        </td>
      </tr>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(MeetingItem);
