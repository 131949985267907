import axios from 'axios';
import BaseApi from './base-api';

class UnionsApi extends BaseApi {
  getUnions() {
    return axios
      .get(this.baseUrl + '/unions')
      .then((response) => {
        return {
          ok: true,
          unions: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export let unionsApi = new UnionsApi();
export default UnionsApi = new UnionsApi();
