import {
  CHANGE_ORDER_MODAL_CLOSE,
  CHANGE_ORDER_MODAL_SHOW,
  CHANGE_ORDER_BACK,
  CHANGE_ORDER_CREATE_SUCCESS,
  CHANGE_ORDER_NEXT,
  CHANGE_ORDER_SUCCESS_MODAL_OPEN,
  CHANGE_ORDER_SUCCESS_MODAL_CLOSE,
  CHANGE_ORDER_PRINT_SUCCESS,
  CHANGE_ORDER_PDF_VIEWER_CLOSE,
  CHANGE_ORDER_EMAIL_MODAL_SHOW_SET,
  CHANGE_ORDER_EMAIL_ADD,
  CHANGE_ORDER_EMAIL_CLEAR,
  CHANGE_ORDER_EMAIL_REMOVE,
  CHANGE_ORDER_SUBJECT_SET,
  CHANGE_ORDER_MESSAGE_SET,
} from 'components/admin/work-orders/details/change-orders/store/action-types';

const initialState = {
  showChangeOrderModal: false,
  currentView: 'form', // oneOf: [form, review, thank-you]
  showSuccessMessage: false,
  pdf: null,
  showPdfViewer: false,
  emails: [],
  subject: '',
  message: '',
  showEmailModal: false,
};

export default function changeOrdersUiReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_ORDER_MODAL_CLOSE:
      return {
        ...state,
        showChangeOrderModal: false,
      };

    case CHANGE_ORDER_MODAL_SHOW:
      return {
        ...state,
        currentView: 'form',
        showChangeOrderModal: true,
      };

    case CHANGE_ORDER_BACK:
      return {
        ...state,
        currentView: action.payload,
      };

    case CHANGE_ORDER_CREATE_SUCCESS:
      return {
        ...state,
        currentView: 'thank-you',
      };

    case CHANGE_ORDER_NEXT:
      return {
        ...state,
        currentView: action.payload,
      };

    case CHANGE_ORDER_SUCCESS_MODAL_OPEN:
      return {
        ...state,
        showSuccessMessage: true,
      };

    case CHANGE_ORDER_SUCCESS_MODAL_CLOSE:
      return {
        ...state,
        showSuccessMessage: false,
      };

    case CHANGE_ORDER_PRINT_SUCCESS:
      return {
        ...state,
        pdf: action.payload,
        showPdfViewer: true,
      };

    case CHANGE_ORDER_PDF_VIEWER_CLOSE:
      return {
        ...state,
        showPdfViewer: false,
      };

    case CHANGE_ORDER_EMAIL_MODAL_SHOW_SET:
      return {
        ...state,
        showEmailModal: action.payload,
      };

    case CHANGE_ORDER_EMAIL_ADD:
      if (
        state.emails.find((email) => email === action.payload) === undefined
      ) {
        return {
          ...state,
          emails: state.emails.concat([action.payload]),
        };
      } else {
        return state;
      }

    case CHANGE_ORDER_EMAIL_CLEAR:
      return {
        ...state,
        emails: [],
      };

    case CHANGE_ORDER_EMAIL_REMOVE:
      return {
        ...state,
        emails: state.emails.filter((email) => email !== action.payload),
      };

    case CHANGE_ORDER_SUBJECT_SET:
      return {
        ...state,
        subject: action.payload,
      };

    case CHANGE_ORDER_MESSAGE_SET:
      return {
        ...state,
        message: action.payload,
      };

    default:
      return state;
  }
}
