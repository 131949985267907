import {
  WORK_ORDERS_DETAILS_DECLINE_PROPOSAL_MODAL_CLOSE,
  WORK_ORDERS_DETAILS_DECLINE_PROPOSAL_MODAL_SHOW,
} from 'actions/admin/work-orders/action-types';

const initialState = {
  show: false,
};

export default function declineProposalUiReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_DETAILS_DECLINE_PROPOSAL_MODAL_CLOSE:
      return {
        ...state,
        show: false,
      };

    case WORK_ORDERS_DETAILS_DECLINE_PROPOSAL_MODAL_SHOW:
      return {
        ...state,
        show: true,
      };

    default:
      return state;
  }
}
