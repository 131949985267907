import { Grid, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { getDarkMode } from 'selectors/theme';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { isMixedForm } from 'services/utils/project-photos-util';
import replyIcon from 'images/reply.svg';

import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';

import { getProjectPhotosForm } from './store/selectors';
import { addProjectPostComment } from '../project-posts/store/actions';
import { addProjectPhotosFormComment } from './store/actions';

const ProjectPhotosFormComments = (props) => {
  const { dispatch, form, currentProject } = props;

  const [reply, setReply] = useState(null);
  const [comment, setComment] = useState('');
  const [error, setError] = useState(false);

  const mixed = isMixedForm(form);
  const onReply = (c) => () => {
    setReply(reply === c.id ? null : c.id);
  };

  const renderComment = (comment) => {
    const isReply = comment.reply_id;
    const isReplying = comment.id === reply;
    return (
      <Grid
        container
        width={'100%'}
        spacing={0.25}
        paddingY={1.5}
        paddingRight={4}
        alignItems={'baseline'}
        paddingLeft={isReply ? 8 : 4}
        className={`comment-item ${isReply ? 'reply' : ''} ${
          isReplying ? 'replying' : ''
        }`}
      >
        <Grid item xs={12}>
          <Typography fontWeight={600} color="text.primary">
            {comment.created_by.full_name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography whiteSpace={'pre-wrap'} color="text.primary">
            {comment.comment}
          </Typography>
        </Grid>
        <Grid item>
          <Typography color={'text.secondary'} marginRight={1} fontSize={14}>
            {moment(comment.created_at).format('MM/DD/YYYY [at] hh:mm A')}
          </Typography>
        </Grid>
        {!isReply && (
          <Grid item>
            <Button
              type={BUTTON_TYPES.LINK}
              color={BUTTON_COLORS.GREEN}
              label={isReplying ? 'Replying' : 'Reply'}
              onClick={onReply(comment)}
            />
          </Grid>
        )}
        {isReply && (
          <div className="reply-icon">
            <img src={replyIcon} alt="Reply" />
          </div>
        )}
      </Grid>
    );
  };

  const renderList = () => {
    const isEmpty = form.comments.length === 0;

    return (
      <Grid
        container
        height={'100%'}
        flexDirection={'column'}
        alignItems={isEmpty ? 'center' : 'flex-start'}
        justifyContent={isEmpty ? 'center' : 'flex-start'}
        paddingTop={'1px'}
      >
        {isEmpty && (
          <Typography color={'text.secondary'} variant="h6" paddingY={4}>
            No Comments
          </Typography>
        )}
        {form.comments.map((c) => {
          return (
            <Fragment key={'comment_' + c.id}>
              <Grid item width={'100%'}>
                {renderComment(c)}
              </Grid>
              {c.replies.map((c1) => (
                <Grid item key={'comment_' + c1.id} width={'100%'}>
                  {renderComment(c1)}
                </Grid>
              ))}
            </Fragment>
          );
        })}
      </Grid>
    );
  };

  const isValid = () => {
    const valid = comment.replace(/ /g, '').length >= 0;
    setError(!valid);

    return valid;
  };
  const onSend = () => {
    if (!isValid()) return;

    const data = { comment, reply };
    dispatch(addProjectPostComment(currentProject.id, form.postId, data)).then(
      (response) => {
        if (response.ok) {
          dispatch(addProjectPhotosFormComment(response.data));
          setComment('');
          setReply(null);
        }
      }
    );
  };

  const renderInput = () => {
    return (
      <Grid
        container
        paddingX={4}
        paddingTop={1.5}
        paddingBottom={2}
        spacing={2}
        direction="column"
      >
        <Grid item>
          <FormControlBlock
            hideBottomLabel
            className="form-description"
            label={comment && 'Add a Comment...'}
            control={
              <TextareaAutosizeWrapper
                placeholder={'Add a Comment...'}
                value={comment}
                readOnly={mixed}
                disabled={mixed}
                className="comment-textarea"
                onChange={setComment}
                valid={!error}
              />
            }
          />
        </Grid>
        <Grid item>
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label={'Send'}
            onClick={onSend}
            disabled={mixed}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      flexGrow={1}
      alignItems={'stretch'}
      flexDirection={'column'}
      flexWrap={'nowrap'}
      className="project-photos-comments"
    >
      <Grid item flexGrow={1} className="list-section">
        {renderList()}
      </Grid>
      <Grid item className="input-section">
        {renderInput()}
      </Grid>
    </Grid>
  );
};

export default connect((state) => ({
  form: getProjectPhotosForm(state),
  darkMode: getDarkMode(state),
  currentProject: getCurrentProject(state),
}))(ProjectPhotosFormComments);
