export const PROJECT_LOCATIONS_PROJECT_LOCATION_LOAD_SUCCESS =
  'PROJECT_LOCATIONS_PROJECT_LOCATION_LOAD_SUCCESS';
export const PROJECT_LOCATIONS_PROJECT_LOCATION_WORK_ORDERS_PAGE_CHANGE =
  'PROJECT_LOCATIONS_PROJECT_LOCATION_WORK_ORDERS_PAGE_CHANGE';
export const PROJECT_LOCATIONS_PROJECT_LOCATION_PROJECTS_LOAD_SUCCESS =
  'PROJECT_LOCATIONS_PROJECT_LOCATION_PROJECTS_LOAD_SUCCESS';
export const PROJECT_LOCATIONS_EDIT_FORM_LOAD_FROM_CURRENT =
  'PROJECT_LOCATIONS_EDIT_FORM_LOAD_FROM_CURRENT';
export const PROJECT_LOCATIONS_LOAD_SUCCESS = 'PROJECT_LOCATIONS_LOAD_SUCCESS';
export const PROJECT_LOCATIONS_NEW_CLEAR_DATA =
  'PROJECT_LOCATIONS_NEW_CLEAR_DATA';
export const PROJECT_LOCATIONS_NEW_FORM_INVALID =
  'PROJECT_LOCATIONS_NEW_FORM_INVALID';
export const PROJECT_LOCATIONS_NEW_FORM_VALID =
  'PROJECT_LOCATIONS_NEW_FORM_VALID';
export const PROJECT_LOCATIONS_NEW_FORM_VALUE_CHANGE =
  'PROJECT_LOCATIONS_NEW_FORM_VALUE_CHANGE';
export const PROJECT_LOCATIONS_SET_PAGINATION_DATA =
  'PROJECT_LOCATIONS_SET_PAGINATION_DATA';
export const PROJECT_LOCATIONS_ADD_PROJECT_LOCATION_TO_BEGINNING =
  'PROJECT_LOCATIONS_ADD_PROJECT_LOCATION_TO_BEGINNING';
