export const NotificationTypes = {
  WORK_ORDER: 'WorkOrder',
  TASK: 'Task',
  INVOICE: 'Invoice',
  CONTRACTOR: 'Contractor',
  PURCHASE_ORDER: 'PurchaseOrder',
  SURVEY: 'Survey',
  CLIENT: 'Owner',
  CONTRACTOR_INVOICE: 'ContractorInvoice',
  PROJECT: 'Project',
  MEETING: 'Meeting',
  RFI: 'Rfi',
  RFA: 'Rfa',
  SUBMITTAL: 'Submittal',
  PROJECT_OPEN_ITEM: 'ProjectOpenItem',
  NOTE: 'Note',
  ORDER: 'Order',
  PAR: 'Par',
  SUB_DAILY_REPORT: 'SubDailyReport',
  DOCUMENT_NOTIFICATION: 'DocumentNotification',
  COI_EXP: 'CoiExp',
  PROJECT_REPORT: 'ProjectReport',
  FIELD_REPORT: 'FieldReport',
  SCHEDULE_CATEGORY: 'ScheduleCategory',
  MEETINGS_INSTANCE: 'MeetingsInstance',
  MEETINGS_PARENT: 'MeetingsParent',
  MEETINGS_ACTION_ITEM: 'MeetingsActionItem',
  ACTION_ITEMS_UPDATE: 'ActionItemsUpdate',
};

export function getNotifiableType(notification) {
  return notification.notifiable_type === NotificationTypes.NOTE
    ? notification.notifiable.notifiable_type
    : notification.notifiable_type;
}

export function getNotifiable(notification) {
  return notification.notifiable_type === NotificationTypes.NOTE
    ? notification.notifiable.notifiable
    : notification.notifiable;
}

export function unreadNotifications(notifications, type, fallbackAttr) {
  let unreadById = {};
  if (notifications !== null && notifications.content.length > 0) {
    // Look for notifications not read yet, to mark the element
    unreadById = notifications.content
      .filter((notification) => notification.read_at === null)
      .reduce((accumulator, currentNotification) => {
        const notifiableType = getNotifiableType(currentNotification);
        const notifiable = getNotifiable(currentNotification);
        let id = null;
        if (notifiableType === type) {
          id = notifiable.id;
        } else if (notifiable !== null && fallbackAttr) {
          id = notifiable[fallbackAttr];
        }
        if (id !== null) {
          accumulator[id] = true;
        }
        return accumulator;
      }, {});
  }

  return unreadById;
}
