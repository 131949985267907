import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import { Carousel } from 'react-bootstrap';

import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getProjectPhotosDetail,
  getProjectPhotosDetailPhotoId,
  getProjectPhotosEditImage,
  getProjectPhotosFilters,
  getProjectPhotosForm,
  getProjectPhotosShowDetail,
  getProjectPhotosTotalPhotos,
} from './store/selectors';
import { loadProjectPhotoForm, loadProjectPhotosDetail } from './store/actions';

import ProjectPhotosDetailMenu from './ProjectPhotosDetailMenu';
import ProjectPhotosDetailVideo from './ProjectPhotosDetailVideo';
import ProjectPhotosDetailPhoto from './ProjectPhotosDetailPhoto';
import { useDebouncedCallback } from 'use-debounce';

const ProjectPhotosDetailSlider = (props) => {
  const {
    dispatch,
    currentProject,
    photos,
    form,
    edit,
    show,
    initialPhotoId,
    currentFilters,
    totalPhotos,
  } = props;
  const type = form.ids[0].type;
  const [current, setCurrent] = useState(0);

  const setInitialCurrent = () => {
    let currentIndex = 0;
    if (initialPhotoId && show) {
      const index = photos.findIndex((p) => p.id === initialPhotoId);
      if (index >= 0) currentIndex = index;
    }
    setCurrent(currentIndex);
  };
  useEffect(() => setInitialCurrent(), [show]);

  const renderImage = (photo) => {
    return <ProjectPhotosDetailPhoto photo={photo} />;
  };

  const renderVideo = (video) => {
    return <ProjectPhotosDetailVideo video={video} />;
  };

  const renderMenu = () => {
    const currentPhoto = photos[current];
    return <ProjectPhotosDetailMenu currentPhoto={currentPhoto} />;
  };

  const parseNumber = (num) => (num < 10 ? `0${num}` : num);

  const updatePhotoForm = (next) => {
    const photo = photos[next];
    dispatch(loadProjectPhotoForm(currentProject.id, photo.id, type));
  };

  const debouncedChangePhoto = useDebouncedCallback(
    (value) => updatePhotoForm(value),
    800
  );

  const onCarouselChage = (next) => {
    setCurrent(next);
    if (type === 'photo') debouncedChangePhoto(next);
    const TOTAL_PER_PAGE = 8;

    const loaded = photos.length;
    const total = type === 'photo' ? totalPhotos : form.count_photos;

    if (loaded < total && next === loaded - 1) {
      let filters = {};
      if (type === 'photo') filters = { ...currentFilters };
      else filters = { albumId: form.ids[0].id };
      filters.photosPage = Math.round(loaded / TOTAL_PER_PAGE) + 1;

      dispatch(loadProjectPhotosDetail(currentProject.id, filters));
    }
  };

  return (
    <>
      <Carousel
        wrap={false}
        slide={false}
        indicators={false}
        onSelect={onCarouselChage}
        activeIndex={current}
        controls={photos.length > 0 && !edit}
        className="project-photos-carousel"
      >
        {photos.map((p, i) => {
          return (
            <Carousel.Item key={'photo_' + i + '_' + p.id}>
              <div>{p.is_video ? renderVideo(p) : renderImage(p)}</div>
            </Carousel.Item>
          );
        })}
      </Carousel>
      <Grid
        container
        alignItems={'center'}
        height={60}
        paddingX={3}
        bgcolor={'background.default'}
      >
        <Grid item xs={4}>
          <Typography
            color="text.primary"
            fontWeight={600}
            marginRight={1.5}
            component="span"
          >
            {form.title || ''}
          </Typography>

          <Typography fontSize={14} component="span" color={'text.secondary'}>
            {parseNumber(current + 1)} of{' '}
            {parseNumber(type === 'photo' ? totalPhotos : form.count_photos)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          {photos.length > 0 && renderMenu()}
        </Grid>
        <Grid item xs={4} />
      </Grid>
    </>
  );
};

export default connect((state) => ({
  show: getProjectPhotosShowDetail(state),
  photos: getProjectPhotosDetail(state),
  form: getProjectPhotosForm(state),
  currentProject: getCurrentProject(state),
  edit: getProjectPhotosEditImage(state),
  initialPhotoId: getProjectPhotosDetailPhotoId(state),
  currentFilters: getProjectPhotosFilters(state),
  totalPhotos: getProjectPhotosTotalPhotos(state),
}))(ProjectPhotosDetailSlider);
