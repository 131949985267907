import {
  WORK_ORDERS_DETAILS_ADD_MEMBER_FORM_INVALID,
  WORK_ORDERS_DETAILS_ADD_MEMBER_FORM_VALID,
  WORK_ORDERS_DETAILS_ADD_MEMBER_FORM_VALUE_UPDATE,
  WORK_ORDERS_DETAILS_ADD_MEMBER_MODAL_SHOW,
} from '../../../../actions/admin/work-orders/action-types';

const initialState = {
  form: {
    note: '',
    userId: null,
  },
  invalidFields: [],
  showAddMemberModal: false,
  validationMessage: '',
};

export default function membersUiReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_DETAILS_ADD_MEMBER_FORM_INVALID:
      return {
        ...state,
        invalidFields: action.payload.invalidFields,
        validationMessage: action.payload.message,
      };

    case WORK_ORDERS_DETAILS_ADD_MEMBER_FORM_VALID:
      return {
        ...state,
        invalidFields: [],
        validationMessage: '',
      };

    case WORK_ORDERS_DETAILS_ADD_MEMBER_FORM_VALUE_UPDATE:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case WORK_ORDERS_DETAILS_ADD_MEMBER_MODAL_SHOW:
      return {
        ...initialState,
        showAddMemberModal: action.payload,
      };

    default:
      return state;
  }
}
