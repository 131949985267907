const initialState = {
  url: null,
};

export default function loginBackgroundImage(state = initialState, action) {
  switch (action.type) {
    case 'ADD_BACKGROUND_IMAGE_TO_STORE':
      return {
        ...state,
        url: action.url,
      };

    default:
      return state;
  }
}
