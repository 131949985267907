import { multipleDropdownValues } from 'services/utils/dropdown-utils';
import {
  MATERIAL_ORDER_DETAILS_FIELDS,
  MATERIAL_ORDER_IDS_FIELDS,
  MATERIAL_ORDER_FEATURES_FIELDS,
} from './domain';
import {
  NEW_REPORT_VIEW,
  EDIT_REPORT_VIEW,
  REPORT_VIEW_FORM_VALUE_CHANGE,
  SHOW_SAVE_VIEW_MODAL,
  HIDE_SAVE_VIEW_MODAL,
  SET_RUNNING_REPORT,
  CLEAR_RUNNING_REPORT,
} from './action-types';
import { COMPANY_TYPES } from 'domain/company-type-enum';

const booleanMapToArray = (map) => Object.keys(map).filter((key) => map[key]);
const arrayToBooleanMap = (array) =>
  array.reduce(
    (result, key) => ({
      ...result,
      [typeof key === 'object' ? key.id : key]: true,
    }),
    {}
  );

export const initialState = {
  showSaveView: false,
  runningReport: null,
  details: {
    name: null,
    report_type: null,
    scope: null,
    start_date: null,
    end_date: null,
  },
  company_information: {
    suscriber_company_types: [],
    suscriber_companies: [],
    contributer_company_types: [],
    contributer_companies: [],
    contributer_companies_status: null,
  },
  project_information: {
    projects: [],
    street_address_1s: [],
    street_address_2s: [],
    cities: [],
    states: [],
    zips: [],
    statuses: [],
    public: null,
    conditions: [],
    industries: [],
    industry_types: [],
    primary_users_counts: [],
    sq_footages: [],
    sq_footage_from: null,
    sq_footage_to: null,
    estimated_costs: [],
    estimated_cost_from: null,
    estimated_cost_to: null,
    est_start_date_from: null,
    est_start_date_to: null,
    est_completion_date_from: null,
    est_completion_date_to: null,
    est_duration: true,
    creation_date_from: null,
    creation_date_to: null,
    termination_date_from: null,
    termination_date_to: null,
    duration: true,
  },
  project_usage: {
    total_users_count: true,
    primary_users_count: true,
    user_types_counts: [],
    meetings_count: true,
    documents_count: true,
    documents_size: true,
    rfis_count: true,
    submittals_count: true,
    daily_reports_count: true,
    sub_daily_reports_count: true,
    closeout_files_size: true,
    emails_sent_count: true,
    project_files_size: true,
  },
  material_order: {
    status_counts: [],
    order_date_from: null,
    order_date_to: null,
    customer_titles: [],
    vendors: [],
    products_revised: true,
    products_removed: true,
    items_offered: true,
    categories: [],
    item_types: [],
    vendor_notes: true,
    lead_time: true,
    brands: [],
    brands2: [],
    manufacturers: [],
    manufacturers2: [],
    title: true,
    title2: true,
    description: true,
    description2: true,
    multipack: true,
    details: arrayToBooleanMap(MATERIAL_ORDER_DETAILS_FIELDS),
    ids: arrayToBooleanMap(MATERIAL_ORDER_IDS_FIELDS),
    features: arrayToBooleanMap(MATERIAL_ORDER_FEATURES_FIELDS),
  },
  subscription: {
    per_term_report: true,
    terms: [],
    fee: true,
    payment_mode: true,
    start_date: true,
    cancel_date: true,
    duration: true,
    renewal_terms: [],
    renewal_total_terms: true,
    renewal_fee: true,
    renewal_paid_to_date: true,
    renewal_payment_mode: true,
    renewal_start_date: true,
    renewal_cancel_date: true,
    renewal_duration: true,
    archived_terms: [],
    archived_total_terms: true,
    archived_fee: true,
    archived_paid_to_date: true,
    archived_payment_mode: true,
    archived_start_date: true,
    archived_cancel_date: true,
    archived_duration: true,
    statuses: [],
    counts: [],
    times_suspended: true,
    total_project_count: true,
  },
};

const mapDetails = (values) => ({
  name: values.name,
  report_type: values.report_type,
  scope: values.scope,
  start_date: values.start_date,
  end_date: values.end_date,
});

const mapCompanyInformation = (values) => ({
  id: values.id,
  suscriber_company_types: multipleDropdownValues(
    values.suscriber_company_types || []
  ),
  suscriber_companies: multipleDropdownValues(values.suscriber_companies || []),
  contributer_company_types: multipleDropdownValues(
    values.contributer_company_types || []
  ),
  contributer_companies: multipleDropdownValues(
    values.contributer_companies || []
  ),
  contributer_companies_status: values.contributer_companies_status,
});

const mapProjectInformation = (values) => ({
  id: values.id,
  projects: multipleDropdownValues(values.projects || []),
  street_address_1s: multipleDropdownValues(values.street_address_1s || []),
  street_address_2s: multipleDropdownValues(values.street_address_2s || []),
  cities: multipleDropdownValues(values.cities || []),
  states: multipleDropdownValues(values.states || []),
  zips: multipleDropdownValues(values.zips || []),
  statuses: multipleDropdownValues(values.statuses || []),
  public: values.public !== null ? (values.public ? 1 : 0) : null,
  conditions: multipleDropdownValues(values.conditions || []),
  industries: multipleDropdownValues(values.industries || []),
  industry_types: multipleDropdownValues(values.industry_types || []),
  primary_users_counts: multipleDropdownValues(
    values.primary_users_counts || []
  ),
  sq_footages: multipleDropdownValues(values.sq_footages || []),
  sq_footage_from: values.sq_footage_from,
  sq_footage_to: values.sq_footage_to,
  estimated_costs: multipleDropdownValues(values.estimated_costs || []),
  estimated_cost_from: values.estimated_cost_from,
  estimated_cost_to: values.estimated_cost_to,
  est_start_date_from: values.est_start_date_from,
  est_start_date_to: values.est_start_date_to,
  est_completion_date_from: values.est_completion_date_from,
  est_completion_date_to: values.est_completion_date_to,
  est_duration: values.est_duration,
  creation_date_from: values.creation_date_from,
  creation_date_to: values.creation_date_to,
  termination_date_from: values.termination_date_from,
  termination_date_to: values.termination_date_to,
  duration: values.duration,
});

const mapProjectUsage = (values) => ({
  id: values.id,
  total_users_count: values.total_users_count,
  primary_users_count: values.primary_users_count,
  user_types_counts: multipleDropdownValues(
    booleanMapToArray({
      [COMPANY_TYPES.OWNER_AUTHORIZED_REP]: values.owner_authorized_reps_count,
      [COMPANY_TYPES.GENERAL_CONTRACTOR]: values.general_contractors_count,
      [COMPANY_TYPES.CLIENT]: values.clients_count,
      [COMPANY_TYPES.ARCHITECT]: values.architects_count,
      [COMPANY_TYPES.CONSULTANT]: values.consultants_count,
      [COMPANY_TYPES.LANDLORD]: values.landlords_count,
      [COMPANY_TYPES.PROPERTY_MANAGER]: values.property_managers_count,
      [COMPANY_TYPES.CONTRACTOR]: values.contractors_count,
    })
  ),
  meetings_count: values.meetings_count,
  documents_count: values.documents_count,
  documents_size: values.documents_size,
  rfis_count: values.rfis_count,
  submittals_count: values.submittals_count,
  daily_reports_count: values.daily_reports_count,
  sub_daily_reports_count: values.sub_daily_reports_count,
  closeout_files_size: values.closeout_files_size,
  emails_sent_count: values.emails_sent_count,
  project_files_size: values.project_files_size,
});

const mapMaterialOrder = (values) => ({
  id: values.id,
  status_counts: multipleDropdownValues(
    booleanMapToArray({
      active: values.active_count,
      canceled: values.canceled_count,
      revised: values.revised_count,
    })
  ),
  order_date_from: values.order_date_from,
  order_date_to: values.order_date_to,
  customer_titles: multipleDropdownValues(values.customer_titles || []),
  vendors: multipleDropdownValues(values.vendors || []),
  products_revised: values.products_revised,
  products_removed: values.products_removed,
  items_offered: values.items_offered,
  categories: multipleDropdownValues(values.categories || []),
  item_types: multipleDropdownValues(values.item_types || []),
  vendor_notes: values.vendor_notes,
  lead_time: values.lead_time,
  brands: multipleDropdownValues(values.brands || []),
  brands2: multipleDropdownValues(values.brands2 || []),
  manufacturers: multipleDropdownValues(values.manufacturers || []),
  manufacturers2: multipleDropdownValues(values.manufacturers2 || []),
  title: values.title,
  title2: values.title2,
  description: values.description,
  description2: values.description2,
  multipack: values.multipack,
  details: arrayToBooleanMap(values.details || []),
  ids: arrayToBooleanMap(values.ids || []),
  features: arrayToBooleanMap(values.features || []),
});

const mapSubscription = (values) => ({
  id: values.id,
  per_term_report: values.per_term_report,
  terms: multipleDropdownValues(values.terms || []),
  fee: values.fee,
  payment_mode: values.payment_mode,
  start_date: values.start_date,
  cancel_date: values.cancel_date,
  duration: values.duration,
  renewal_terms: multipleDropdownValues(values.renewal_terms || []),
  renewal_total_terms: values.renewal_total_terms,
  renewal_fee: values.renewal_fee,
  renewal_paid_to_date: values.renewal_paid_to_date,
  renewal_payment_mode: values.renewal_payment_mode,
  renewal_start_date: values.renewal_start_date,
  renewal_cancel_date: values.renewal_cancel_date,
  renewal_duration: values.renewal_duration,
  archived_terms: multipleDropdownValues(values.archived_terms || []),
  archived_total_terms: values.archived_total_terms,
  archived_fee: values.archived_fee,
  archived_paid_to_date: values.archived_paid_to_date,
  archived_payment_mode: values.archived_payment_mode,
  archived_start_date: values.archived_start_date,
  archived_cancel_date: values.archived_cancel_date,
  archived_duration: values.archived_duration,
  statuses: multipleDropdownValues(values.statuses || []),
  counts: multipleDropdownValues(values.counts || []),
  times_suspended: values.times_suspended,
  total_project_count: values.total_project_count,
});

export default function reportsUiReducer(state = initialState, action) {
  switch (action.type) {
    case NEW_REPORT_VIEW:
      return initialState;

    case EDIT_REPORT_VIEW:
      return {
        ...state,
        id: action.payload.view.id,
        details: mapDetails(action.payload.view),
        company_information: mapCompanyInformation(
          action.payload.view.report_view_company_information
        ),
        project_information: mapProjectInformation(
          action.payload.view.report_view_project_information
        ),
        project_usage: mapProjectUsage(
          action.payload.view.report_view_project_usage
        ),
        material_order: mapMaterialOrder(
          action.payload.view.report_view_material_order
        ),
        subscription: mapSubscription(
          action.payload.view.report_view_subscription
        ),
      };

    case REPORT_VIEW_FORM_VALUE_CHANGE:
      return {
        ...state,
        [action.payload.section]: {
          ...state[action.payload.section],
          [action.payload.attribute]: action.payload.value,
        },
      };

    case SHOW_SAVE_VIEW_MODAL:
      return {
        ...state,
        showSaveView: true,
      };

    case HIDE_SAVE_VIEW_MODAL:
      return {
        ...state,
        showSaveView: false,
      };

    case SET_RUNNING_REPORT:
      return {
        ...state,
        runningReport: action.payload.view,
      };
    case CLEAR_RUNNING_REPORT:
      return {
        ...state,
        runningReport: null,
      };

    default:
      return state;
  }
}
