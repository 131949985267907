import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReadOnlyInput from './ReadOnlyInput';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import './Input.css';

class Input extends Component {
  static propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    theme: PropTypes.string,
    type: PropTypes.string,
    valid: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    autoFocus: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    required: false,
    type: 'text',
    valid: true,
    value: '',
  };

  handleOnBlur = (event) => {
    if (this.props.onBlur !== undefined) {
      this.props.onBlur(event.target.value);
    }
  };

  handleOnFocus = (event) => {
    if (this.props.onFocus !== undefined) {
      this.props.onFocus(event.target.value);
    }
  };

  handleOnChange = (event) => {
    if (this.props.onChange !== undefined) {
      this.props.onChange(event.target.value);
    }
  };

  render() {
    const {
      className,
      readOnly,
      disabled,
      valid,
      required,
      placeholder,
      type,
      autoFocus,
      darkMode,
    } = this.props;
    const value = this.props.value !== null ? this.props.value : '';

    return readOnly ? (
      <ReadOnlyInput
        className={'input' + (className !== undefined ? ' ' + className : '')}
        value={value}
      />
    ) : (
      <input
        className={
          'form-control input' +
          (className !== undefined ? ' ' + className : '') +
          (darkMode ? ' dark-mode' : '') +
          (!valid ? ' invalid' : '') +
          (required ? ' required' : '')
        }
        placeholder={placeholder}
        required={required}
        value={value}
        type={type}
        autoFocus={autoFocus}
        onBlur={this.handleOnBlur}
        onFocus={this.handleOnFocus}
        onChange={this.handleOnChange}
        onWheel={type === 'number' ? (e) => e.target.blur() : null}
        disabled={disabled}
      />
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(Input);
