import 'core-js/es6/map';
import 'core-js/es6/set';
import React from 'react';
import ReactDOM from 'react-dom';
import './polyfill';
// Import bootstrap styles first to avoid conflict with our styles
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { getReducers } from './reducers/reducers';
import { createBrowserHistory } from 'history';
import {
  ConnectedRouter,
  routerMiddleware,
  routerReducer,
} from 'react-router-redux';
import SnackbarProvider from './components/shared/snackbar/SnackbarProvider';
import MiuThemeProvider from './theme/miu-theme-provider';
import { configureApi } from './services/api/api-configuration';
import AuthenticationStorage from './services/authentication-storage';
import { clearUnwantedHistoryState } from './services/utils/router-state-util';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

// Build history
let history = createBrowserHistory();
history = clearUnwantedHistoryState(history);

// Build the middleware for intercepting and dispatching navigation actions
const middleware = routerMiddleware(history);

const reducers = getReducers();

let initialState = {};

// Check user information in the local storage
const credentials = AuthenticationStorage.getCredentials();
const accounts = AuthenticationStorage.getAccounts();
if (credentials !== null) {
  initialState['auth'] = credentials;
  initialState['auth'].errors = [];
  initialState['auth'].loggedIn = true;
  initialState['auth'].accounts = accounts;
  initialState['theme'] = {
    theme: credentials.user && credentials.user.theme,
  };
}

// To debug redux store in Chrome DevTools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  combineReducers({
    ...reducers,
    router: routerReducer,
  }),
  initialState,
  composeEnhancers(applyMiddleware(middleware, thunk))
);

// Configure API layer
configureApi(store.dispatch, store.getState);

ReactDOM.render(
  <Provider store={store}>
    <MiuThemeProvider>
      <SnackbarProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </LocalizationProvider>
      </SnackbarProvider>
    </MiuThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/2vJdu84
serviceWorker.unregister();
