import {
  SET_SERVICE_PROVIDER_DROPDOWN,
  SET_CURRENT_PAGE,
  SET_SERVICE_PROVIDER_DATA,
  SET_TOTAL,
  SET_PAGE_SIZE,
  SET_NAME_TO_SEARCH,
  SET_SERVICE_PROVIDER_USER_DATA,
  SET_COMPANY_DATA,
  SET_CURRENT_USER_COMPANY,
  SERVICE_PROVIDERS_INVITE_USER_ID_SET,
  SERVICE_PROVIDERS_INVITE_USER_MODAL_SHOW,
  SERVICE_PROVIDERS_INVITE_USER_FORM_LOAD,
  SERVICE_PROVIDERS_SHOW_CONFIRMATION_MODAL,
  SERVICE_PROVIDERS_CLEAR_FILTERS,
  SERVICE_PROVIDERS_SHOW_VERIFICATION_MODAL,
} from 'components/admin/service-providers/store/action-types';
import { UserModalState } from 'components/shared/modal/InviteUserModal';

const initialState = {
  currentPage: 1,
  serviceProviderDropdown: 'Architect',
  serviceProviders: [],
  total: 1,
  pageSize: 10,
  nameToSearch: '',
  companyData: { name: '', status: '', contact: {} },
  currentUserId: 0,
  clientForm: {
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    globalPurchaseOrderRequired: false,
    purchaseOrderRequired: false,
    streetAddress: '',
    unitNumber: '',
    city: '',
    state: '',
    zipCode: '',
  },
  invalidFields: [],
  showInviteUserModal: false,
  userForm: {
    buildingsId: [],
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    rolesId: [],
    title: '',
  },
  userModalState: UserModalState.NEW, // one of UserModalState
  validationMessage: '',
  currentUserCompany: null,
  verificationModal: null,
};

export default function serviceProvidersUIReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case SET_SERVICE_PROVIDER_DROPDOWN:
      return {
        ...initialState,
        serviceProviderDropdown: action.payload,
      };

    case SET_SERVICE_PROVIDER_DATA:
      return {
        ...state,
        serviceProviders: action.payload,
      };

    case SET_NAME_TO_SEARCH:
      return {
        ...state,
        nameToSearch: action.payload,
      };

    case SET_TOTAL:
      return {
        ...state,
        total: action.payload,
      };

    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    case SET_SERVICE_PROVIDER_USER_DATA:
      return {
        ...state,
        userList: action.payload,
      };
    case SET_COMPANY_DATA:
      return {
        ...state,
        companyData: action.payload,
      };

    case SET_CURRENT_USER_COMPANY:
      return {
        ...state,
        currentUserCompany: action.payload,
      };

    case SERVICE_PROVIDERS_INVITE_USER_ID_SET:
      return {
        ...state,
        currentUserId: action.payload,
      };

    case SERVICE_PROVIDERS_INVITE_USER_FORM_LOAD:
      return {
        ...state,
        userForm: action.payload,
        invalidFields: [],
        validationMessage: '',
      };

    case SERVICE_PROVIDERS_INVITE_USER_MODAL_SHOW:
      const newState = {
        ...state,
        invalidFields: [],
        showInviteUserModal: action.payload,
        validationMessage: '',
      };

      return newState;

    case SERVICE_PROVIDERS_SHOW_CONFIRMATION_MODAL:
      return {
        ...state,
        showConfirmationDeleteModal: action.payload,
      };

    case SERVICE_PROVIDERS_CLEAR_FILTERS:
      return initialState;

    case SERVICE_PROVIDERS_SHOW_VERIFICATION_MODAL:
      return {
        ...state,
        verificationModal: action.payload,
      };

    default:
      return state;
  }
}
