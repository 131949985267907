import React, { Component } from 'react';
import { toUpperFirstLetter } from '../../../services/utils/text-util';
import PropTypes from 'prop-types';
import './ContractorStatus.css';

class ContractorStatus extends Component {
  static propTypes = {
    status: PropTypes.string.isRequired,
  };

  render() {
    return (
      <span>
        <div className={'contractor-status ' + this.props.status} />
        {toUpperFirstLetter(this.props.status)}
      </span>
    );
  }
}

export default ContractorStatus;
