import {
  defaultMeetingsTitleOptions,
  defaultMeetingsTypeOptions,
  DEFAULT_INSTANCE_FORM,
  DEFAULT_PARENT_FORM,
} from 'domain/new-meeting';

import {
  PROJECT_NEW_MEETINGS_ADD_NEW_MEETING,
  PROJECT_NEW_MEETINGS_EDIT,
  PROJECT_NEW_MEETINGS_INIT,
  PROJECT_NEW_MEETINGS_REMOVE,
  PROJECT_NEW_MEETINGS_SET,
  PROJECT_NEW_MEETINGS_SET_FORM,
  GET_MEETINGS_PARENTS_ERROR,
  GET_MEETINGS_PARENTS_SUCCESS,
  GET_MEETINGS_PARENTS_START,
  GET_MEETING_TYPES,
  GET_MEETING_TYPES_ERROR,
  GET_MEETING_TYPES_SUCCESS,
  GET_MEETING_TITLES,
  GET_MEETING_TITLES_ERROR,
  GET_MEETING_TITLES_SUCCESS,
  DELETE_TITLE_SUCCESS,
  DELETE_TYPE_SUCCESS,
  CREATE_MEETINGS_PARENT_SUCCESS,
  CREATE_MEETINGS_PARENT_ERROR,
  UPDATE_MEETINGS_PARENT_SUCCESS,
  UPDATE_MEETINGS_PARENT_ERROR,
  CREATE_TITLE_SUCCESS,
  CREATE_TYPE_SUCCESS,
  CREATE_MEETINGS_INSTANCE_SUCCESS,
  CREATE_MEETINGS_INSTANCE_ERROR,
  UPDATE_MEETINGS_INSTANCE_SUCCESS,
  UPDATE_MEETINGS_INSTANCE_ERROR,
  MEETINGS_INSTANCE_SET,
  MEETINGS_INSTANCE_SET_FORM,
  GET_ACTION_ITEMS_CATEGORIES,
  GET_ACTION_ITEMS_CATEGORIES_SUCCESS,
  GET_ACTION_ITEMS_CATEGORIES_ERROR,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_ERROR /* CONTINUE HERE */,
  CREATE_NON_DIRECTORY_ATTENDEE_SUCCESS,
  CREATE_NON_DIRECTORY_ATTENDEE_ERROR,
  GET_MEETINGS_INSTANCE_SUCCESS,
  GET_MEETINGS_INSTANCE_ERROR,
  CREATE_ACTION_ITEM_SUCCESS,
  CREATE_ACTION_ITEM_ERROR,
  UPDATE_ACTION_ITEM_SUCCESS,
  UPDATE_ACTION_ITEM_ERROR,
  SET_INSTANCE_ACTION_ITEMS,
  DELETE_INSTANCE_SUCCESS,
  DELETE_INSTANCE_ERROR,
  CREATE_ACTION_ITEM_UPDATE_SUCCESS,
  CREATE_ACTION_ITEM_UPDATE_ERROR,
  UPDATE_ACTION_ITEM_UPDATE_SUCCESS,
  CREATE_CORRECTION_ERROR,
  CREATE_CORRECTION_SUCCESS,
  GET_MEETINGS_PARENT_SUCCESS,
  GET_MEETINGS_PARENT_ERROR,
  GET_MEETINGS_INSTANCE_AGENDA_BACKUP_SUCCESS,
  SET_MEETINGS_INSTANCE_HTML,
} from './action-types';

import {
  snakeToCamel,
  camelToSnake,
} from '../../../../../../services/utils/case-utils';
import { icalArrayToString } from '../../../../../../services/utils/date-util';

export const initialState = {
  meetings: [],
  currentMeetingParent: {},
  meetingsTitles: defaultMeetingsTitleOptions,
  meetingsTypes: defaultMeetingsTypeOptions,
  actionItemsCategories: [],
  meetingsParentsTitles: [],
  meetingsParentsTypes: [],
  loadingMeetingsParents: false,
  loadingMeetingsParentsTypes: false,
  loadingMeetingsParentsTitles: false,
  loadingActionItemsCategories: false,
  meetingsParents: [],
  instanceForm: DEFAULT_INSTANCE_FORM,
  form: DEFAULT_PARENT_FORM,
  category: null,
  actionItems: [],
  meetingsActionItems: [],
};

export default function projectNewMeetingsModuleDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case PROJECT_NEW_MEETINGS_INIT:
      return initialState;
    case PROJECT_NEW_MEETINGS_SET:
      return {
        ...state,
        form: snakeToCamel(action.payload),
      };
    case MEETINGS_INSTANCE_SET: {
      return {
        ...state,
        instanceForm: snakeToCamel(action.paylaod),
      };
    }
    case PROJECT_NEW_MEETINGS_ADD_NEW_MEETING: {
      return {
        ...state,
        meetingsParents: [...state.meetingsParents, action.payload],
      };
    }
    case PROJECT_NEW_MEETINGS_EDIT: {
      const { categoryId, category } = action.payload;

      const meetings = state.meetings;
      const categoryIndex = meetings.findIndex((c) => c.id === categoryId);
      if (categoryIndex < 0) return state;

      const oldCategory = state.meetings[categoryIndex];
      meetings[categoryIndex] = { ...oldCategory, ...category };

      return { ...state, meetings };
    }
    case CREATE_MEETINGS_PARENT_SUCCESS: {
      return {
        ...state,
        form: null,
        meetingsParents: [action.payload, ...state.meetingsParents],
      };
    }
    case CREATE_MEETINGS_PARENT_ERROR: {
      return { ...state, error: action.payload };
    }
    case CREATE_MEETINGS_INSTANCE_SUCCESS: {
      const meetings_parent_id = action.payload.meetings_parent_id;
      const newMeetingNo = action.payload.meetings_parent_id + 1 || 0;
      const formattedNumber =
        newMeetingNo < 10 ? '0' + newMeetingNo : String(newMeetingNo);
      const instanceId = action.payload.id;
      return {
        ...state,
        meetingsParents: state.meetingsParents.map((parent) => {
          return parent.id === meetings_parent_id
            ? {
                ...parent,
                latest_instance_id: instanceId,
                meetings_instances: [
                  {
                    ...action.payload,
                    meeting_number: newMeetingNo,
                    formatted_number: formattedNumber,
                  },
                  ...parent.meetings_instances,
                ],
              }
            : parent;
        }),
      };
    }
    case CREATE_MEETINGS_INSTANCE_ERROR: {
      return { ...state, error: action.payload };
    }
    case CREATE_NON_DIRECTORY_ATTENDEE_SUCCESS: {
      const { nonDirectoryAttendees } = state.instanceForm;
      return {
        ...state,
        instanceForm: {
          ...state.instanceForm,
          nonDirectoryAttendees: [...nonDirectoryAttendees, action.payload],
        },
      };
    }
    case CREATE_NON_DIRECTORY_ATTENDEE_ERROR: {
      return { ...state, error: action.payload };
    }
    case UPDATE_MEETINGS_INSTANCE_ERROR: {
      return { ...state, error: action.payload };
    }
    case GET_MEETINGS_INSTANCE_SUCCESS: {
      const modifiedDocs = action.payload.attached_documents.map((d) => {
        return { ...d, url: d.document.url };
      });
      const modifiedImgs = action.payload.attached_images.map((i) => {
        return { ...i, url: i.image.url };
      });

      const instanceWithObjectifiedNote = {
        ...action.payload,
        meetings_instances_notes: action.payload.meetings_instances_notes[0],
        deletedImages: [],
        deletedDocuments: [],
        attachedDocuments: modifiedDocs,
        attachedImages: modifiedImgs,
      };
      return {
        ...state,
        instanceForm: snakeToCamel(instanceWithObjectifiedNote),
        actionItems: snakeToCamel(action.payload.meetings_action_items),
      };
    }
    case GET_MEETINGS_INSTANCE_AGENDA_BACKUP_SUCCESS: {
      const modifiedDocs = action.payload.attached_documents.map((d) => {
        return { ...d, url: d.document.url };
      });
      const modifiedImgs = action.payload.attached_images.map((i) => {
        return { ...i, url: i.image.url };
      });

      const agendaBackup = action.payload.meetings_agenda_backup;
      const notes =
        agendaBackup.meetings_instances_notes &&
        agendaBackup.meetings_instances_notes[0];
      const instanceAgendaBackupWithObjectifiedNote = {
        ...agendaBackup,
        meetings_instances_notes: notes,
        deletedImages: [],
        deletedDocuments: [],
        attachedDocuments: modifiedDocs,
        attachedImages: modifiedImgs,
        isAgenda: true,
      };
      return {
        ...state,
        instanceForm: snakeToCamel(instanceAgendaBackupWithObjectifiedNote),
      };
    }
    case GET_MEETINGS_INSTANCE_ERROR: {
      return { ...state, error: action.payload };
    }
    case UPDATE_MEETINGS_INSTANCE_SUCCESS: {
      return {
        ...state,
        meetingsParents: state.meetingsParents.map((meetingParent) => {
          if (meetingParent.id === action.payload.meetings_parent_id) {
            return {
              ...meetingParent,
              meetings_instances: meetingParent.meetings_instances.map(
                (instance) =>
                  instance.id === action.payload.id ? action.payload : instance
              ),
            };
          }
          return meetingParent;
        }),
      };
    }

    case UPDATE_MEETINGS_PARENT_SUCCESS: {
      return {
        ...state,
        meetingsParents: state.meetingsParents.map((meetingParent) =>
          meetingParent.id === action.payload.id
            ? camelToSnake({
                ...action.payload,
                recurrence: icalArrayToString(action.payload.recurrence),
              })
            : meetingParent
        ),
      };
    }
    case UPDATE_MEETINGS_PARENT_ERROR: {
      return { ...state, error: action.payload };
    }

    case PROJECT_NEW_MEETINGS_REMOVE: {
      const { categoryId, meetingId } = action.payload;

      const meetings = state.meetings;
      const categoryIndex = meetings.findIndex((c) => c.id === categoryId);
      if (categoryIndex < 0) return state;

      const category = state.meetings[categoryIndex];
      category.meetings = category.meetings.filter((s) => s.id !== meetingId);

      return { ...state, meetings };
    }
    case PROJECT_NEW_MEETINGS_SET_FORM:
      return {
        ...state,
        form: { ...state.form, ...action.payload },
      };
    case MEETINGS_INSTANCE_SET_FORM:
      return {
        ...state,
        instanceForm: { ...state.instanceForm, ...action.payload },
      };
    case GET_MEETINGS_PARENTS_START:
      return {
        ...state,
        loadingMeetingsParents: true,
      };

    case GET_MEETINGS_PARENTS_SUCCESS:
      return {
        ...state,
        meetingsParents: action.payload,
        loadingMeetingsParents: false,
      };

    case GET_MEETINGS_PARENTS_ERROR:
      return {
        ...state,
        loadingMeetingsParents: false,
        error: action.payload,
      };
    case GET_MEETINGS_PARENT_SUCCESS: {
      const camelCasePayload = snakeToCamel(action.payload);
      return { ...state, form: { ...state.form, ...camelCasePayload } };
    }
    case GET_MEETINGS_PARENT_ERROR: {
      return { ...state, error: action.payload };
    }
    case GET_MEETING_TYPES:
      return {
        ...state,
        loadingMeetingsParentsTitles: true,
      };
    case GET_MEETING_TITLES:
      return {
        ...state,
        loadingMeetingsParentsTitles: true,
      };
    case GET_ACTION_ITEMS_CATEGORIES:
      return {
        ...state,
        loadingActionItemsCategories: true,
      };
    case GET_ACTION_ITEMS_CATEGORIES_SUCCESS:
      return {
        ...state,
        actionItemsCategories: action.payload,
        loadingactionItemsCategories: false,
      };
    case GET_ACTION_ITEMS_CATEGORIES_ERROR:
      return {
        ...state,
        error: action.payload,
        loadingActionItemsCategories: false,
      };
    case GET_MEETING_TYPES_SUCCESS:
      return {
        ...state,
        meetingsParentsTypes: action.payload.map((t) => {
          return { value: t.name, label: t.name, typeId: t.id };
        }),
        loadingMeetingsParentsTypes: false,
      };
    case GET_MEETING_TYPES_ERROR:
      return {
        ...state,
        error: action.payload,
        loadingMeetingsParentsTypes: false,
      };

    case GET_MEETING_TITLES_SUCCESS:
      return {
        ...state,
        meetingsParentsTitles: action.payload.map((t) => {
          return { value: t.name, label: t.name, titleId: t.id };
        }),
        loadingMeetingsParentsTitles: false,
      };
    case GET_MEETING_TITLES_ERROR:
      return {
        ...state,
        error: action.payload,
        loadingMeetingsParentsTitles: false,
      };
    case DELETE_TITLE_SUCCESS:
      return {
        ...state,
        meetingsParentsTitles: state.meetingsParentsTitles.filter(
          (title) => title.titleId !== action.payload
        ),
      };
    case DELETE_TYPE_SUCCESS:
      return {
        ...state,
        meetingsParentsTypes: state.meetingsParentsTypes.filter(
          (type) => type.typeId !== action.payload
        ),
      };
    case CREATE_TITLE_SUCCESS:
      return {
        ...state,
        meetingsParentsTitles: [
          ...state.meetingsParentsTitles,
          {
            titleId: action.payload.meetingsParentsTitle.id,
            label: action.payload.meetingsParentsTitle.name,
            value: action.payload.meetingsParentsTitle.name,
          },
        ],
      };
    case CREATE_TYPE_SUCCESS:
      return {
        ...state,
        meetingsParentsTypes: [
          ...state.meetingsParentsTypes,
          {
            typeId: action.payload.meetingsParentsType.id,
            label: action.payload.meetingsParentsType.name,
            value: action.payload.meetingsParentsType.name,
          },
        ],
      };
    case CREATE_CATEGORY_SUCCESS: {
      const { actionItemsCategories } = state;
      return {
        ...state,
        actionItemsCategories: [...actionItemsCategories, action.payload],
      };
    }
    case CREATE_CATEGORY_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case CREATE_ACTION_ITEM_SUCCESS: {
      const actionItemsAux = state.actionItems.filter((ai) => ai.id); //Remove the "in the works" item
      return {
        ...state,
        actionItems: [...actionItemsAux, action.payload],
      };
    }
    case CREATE_ACTION_ITEM_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case UPDATE_ACTION_ITEM_SUCCESS: {
      const { id } = action.payload;
      const allActionItems = state.actionItems;
      return {
        ...state,
        actionItems: allActionItems.map((ai) => {
          if (ai.id === id) {
            return action.payload;
          } else {
            return ai;
          }
        }),
      };
    }
    case UPDATE_ACTION_ITEM_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case SET_INSTANCE_ACTION_ITEMS: {
      return {
        ...state,
        actionItems: action.payload,
      };
    }

    case DELETE_INSTANCE_SUCCESS: {
      return {
        ...state,
        meetingsParents: state.meetingsParents.map((meetingParent) => {
          return {
            ...meetingParent,
            meetings_instances: meetingParent.meetings_instances.filter(
              (instance) => instance.id !== action.payload
            ),
          };
        }),
      };
    }
    case DELETE_INSTANCE_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case CREATE_ACTION_ITEM_UPDATE_SUCCESS: {
      return {
        ...state,
        actionItemsUpdates: [...state.actionItemsUpdates, action.payload],
      };
    }
    case CREATE_ACTION_ITEM_UPDATE_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case UPDATE_ACTION_ITEM_UPDATE_SUCCESS: {
      const { actionItemsUpdates } = state;
      return {
        ...state,
        actionItemsUpdates: actionItemsUpdates.map((aiu) => {
          if (aiu.id === action.payload.id) {
            return action.payload;
          } else {
            return aiu;
          }
        }),
      };
    }
    case CREATE_CORRECTION_SUCCESS: {
      const camelCaseData = snakeToCamel(action.payload);
      const meetingsParentId = camelCaseData.meetingsParentId;
      const meetingParent = state.meetingsParents.filter(
        (mp) => mp.id === meetingsParentId
      );
      if (meetingParent.length < 1) return { ...state };
      const editedParent = {
        ...meetingParent[0],
        meetingsCorrections: [
          ...meetingParent.meetingsCorrections,
          camelCaseData,
        ],
        meetings_corrections: [
          ...meetingParent.meetings_corrections,
          action.payload,
        ],
      };
      const editedParents = state.meetingsParents.map((mp) =>
        mp.id === meetingsParentId ? editedParent : mp
      );
      return { ...state, meetingsParents: editedParents };
    }
    case CREATE_CORRECTION_ERROR: {
      return { ...state, error: action.payload };
    }
    case SET_MEETINGS_INSTANCE_HTML: {
      return { ...state, meetingsInstanceHtml: action.payload };
    }
    default:
      return state;
  }
}
