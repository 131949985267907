import User from './user';

class DailyReport {
  isSubmittedByUserCompany = (user, dailyReport) =>
    !!dailyReport &&
    !!dailyReport.submitted_by &&
    User.isSameCompany(user, dailyReport.submitted_by);

  isSubmittedByGcOar = (directory, dailyReport) =>
    !!dailyReport &&
    !!dailyReport.submitted_by &&
    User.isGcOar(User.userContributor(directory, dailyReport.submitted_by));
}

export default new DailyReport();
