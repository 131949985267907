import { createSelector } from 'reselect';

export const getNotifications = createSelector(
  (state) => state.notifications.data.notifications,
  (notifications) => {
    return notifications;
  }
);

export const getCurrentPage = createSelector(
  (state) => state.notifications.ui.currentPage,
  (currentPage) => {
    return currentPage;
  }
);

export const getShowLoader = createSelector(
  (state) => state.notifications.ui.showLoader,
  (showLoader) => {
    return showLoader;
  }
);
