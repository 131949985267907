import ClientApi from '../../services/api/clients-api';
import RolesApi from '../../services/api/roles-api';
import UsersApi from '../../services/api/users-api';
import { showErrorMessage } from '../messages';
import { USER_ROLE_LABELS } from 'domain/user-role-enum';

export const USER_INVITATIONS_REQUEST_PERMISSIONS_SUCCESS =
  'USER_INVITATIONS_REQUEST_PERMISSIONS_SUCCESS';
export const USER_INVITATIONS_REQUEST_SUCCESS =
  'USER_INVITATIONS_REQUEST_SUCCESS';
export const USER_REMOVE_REQUEST_SUCCESS = 'USER_REMOVE_REQUEST_SUCCESS';

export function userInvitationsStart() {
  return (dispatch) => {
    return RolesApi.getRoles('Client').then((response) => {
      if (response.ok) {
        dispatch(
          permissionsRequestSuccess(
            response.permissions
              .filter((element) => {
                return element.name !== 'owner';
              })
              .map((element) => {
                return {
                  id: element.id,
                  label: USER_ROLE_LABELS[element.name],
                };
              })
          )
        );
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function permissionsRequestSuccess(permissions) {
  return {
    type: USER_INVITATIONS_REQUEST_PERMISSIONS_SUCCESS,
    payload: permissions,
  };
}

export function userInvitationsRequest(user, invitations) {
  return (dispatch) => {
    ClientApi.inviteUsers(user.accountable_id, invitations).then((response) => {
      if (response.ok) {
        dispatch(userInvitationsRequestSuccess(response.users));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function userInvitationsRequestSuccess(users) {
  return {
    type: USER_INVITATIONS_REQUEST_SUCCESS,
    payload: users,
  };
}

export function userRemoveRequest(id) {
  return (dispatch) => {
    UsersApi.removeUser(id).then((response) => {
      if (response.ok) {
        dispatch(userRemoveSuccess(id));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function userRemoveSuccess(id) {
  return {
    type: USER_REMOVE_REQUEST_SUCCESS,
    payload: id,
  };
}
