import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import FormModal from 'components/shared/modal/FormModal';
import SearchBar from 'components/shared/navigation/SearchBar';
import Date from 'components/shared/text/Date';
import SimpleTable from 'components/shared/table/SimpleTable';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import './ProjectReportFormModal.css';
import { getFieldProjectReports } from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import { connect } from 'react-redux';
class ProjectReportBudgetModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
    };
  }

  toDateFormat = (value) => {
    return value ? <Date value={value} format="MM/DD/YYYY" /> : '--';
  };

  getName = (value) => {
    return value ? value.first_name + ' ' + value.last_name : '';
  };

  viewNoItems = (label) => {
    return <div className="modal-no-items text-color">No {label}</div>;
  };

  toFormat = (number) => {
    return numeral(number).format('$0,0.00');
  };

  findReportNum = (projectReportId) => {
    const { fieldProjectReports } = this.props;
    let report = fieldProjectReports.find(
      (report) => report.id === projectReportId
    );
    return report ? report.number : '';
  };

  mapTable = () => {
    const { data, typeTable, onRestore } = this.props;
    if (typeTable === 'budget_summary') {
      return data
        .filter((item) => item.project_report_budget_category_id === 1)
        .map((item) => {
          let reportNum = this.findReportNum(
            item.previous_published_report_id || item.project_report_id
          );
          return {
            id: item.id,
            data: [
              reportNum < 10 ? `0${reportNum}` : reportNum,
              item.description,
              this.toFormat(item.original_cost),
              this.toFormat(item.previous_cost),
              this.toFormat(item.current_cost),
              this.toFormat(item.original_cost - item.current_cost),
              '--',
              this.getName(item.resolved_by),
              this.toDateFormat(item.resolved_at),
              <Button
                key="action-restore"
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Restore"
                onClick={() => onRestore(item)}
              />,
            ],
          };
        });
    } else {
      return data
        .filter((item) => item.project_report_budget_category_id === 2)
        .map((item) => {
          const balance =
            item.contract_amount && item.paid_to_date_amount
              ? item.contract_amount - item.paid_to_date_amount
              : '--';
          const complete =
            balance !== 0 ? (balance / item.contract_amount) * 100 : 100;
          return {
            id: item.id,
            data: [
              item.previous_published_report_id || item.project_report_id,
              item.description,
              this.toFormat(item.contract_amount),
              this.toFormat(item.paid_to_date_amount),
              this.toFormat(balance),
              complete ? 100 - Math.round(complete) + '%' : '--',
              this.getName(item.resolved_by),
              this.toDateFormat(item.resolved_at),
              <Button
                key="action-restore"
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Restore"
                onClick={() => onRestore(item)}
              />,
            ],
          };
        });
    }
  };

  render() {
    const { show, modalName, onClose, onSearch, data, typeTable } = this.props;
    const title =
      modalName === 'resolved-remarks'
        ? 'Resolved Budget Remarks'
        : 'Resolved Budget Items';
    const headers =
      typeTable === 'budget_summary'
        ? [
            'REPORT NO',
            'DESCRIPTION',
            'ORIGINAL',
            'PREVIOUS',
            'CURRENT',
            'VARIANCE',
            'COST/SF',
            'RESOLVED BY',
            'RESOLVED ON ',
            '',
          ]
        : [
            'REPORT NO',
            'DESCRIPTION',
            'BUDGET',
            'PAID TO DATE',
            'BALANCE',
            'COMPLETE %',
            'RESOLVED BY',
            'RESOLVED ON ',
            '',
          ];
    return (
      <FormModal
        id="project-report-form-modal"
        className="project-report-form-modal-view"
        show={show}
        onClose={onClose}
        subTitle="Project Report"
      >
        <div className="container-header-title">
          <div className="modal-name text-color">{title}</div>
          <SearchBar
            className="search-input-background"
            searchPlaceHolder="Search"
            searchText={''}
            onSearch={(value) => this.setState({ search: value })}
            onSearchTextChange={(value) => {
              this.setState({ search: value });
              onSearch(value);
            }}
          />
        </div>
        {modalName === 'resolved-remarks' && (
          <div className="container-body-remark">
            {data.length > 0
              ? data
                  .filter((report) => report.budget_remarks)
                  .map((report) => (
                    <div key={report.id}>
                      <div className="label-section">
                        <span className="label text-color">Report No</span>
                        <span className="value text-color">
                          {report.number < 10
                            ? `0${report.number}`
                            : report.number}
                        </span>
                        <span className="label text-color">Report Date </span>
                        <span className="value text-color">
                          {this.toDateFormat(report.date)}
                        </span>
                        <span className="label text-color">Submitted By</span>
                        <span className="value text-color">
                          {this.getName(report.submitted_by)}
                        </span>
                      </div>
                      <div className="remark-section">
                        <span className="label-remark">Remark</span>
                        <div className="value-remark text-color">
                          {report.budget_remarks}
                        </div>
                      </div>
                    </div>
                  ))
              : this.viewNoItems('Remarks')}
          </div>
        )}
        {modalName === 'resolved-items' && (
          <div className="container-body-project-report-modal-table">
            <SimpleTable
              className="simple-table projects-table"
              headers={headers}
              rows={this.mapTable()}
            />
          </div>
        )}
      </FormModal>
    );
  }
}

ProjectReportBudgetModal.propTypes = {
  show: PropTypes.bool,
  modalName: PropTypes.string,
  onClose: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape()),
  typeTable: PropTypes.string,
};

ProjectReportBudgetModal.defaultProps = {
  show: false,
  modalName: 'resolved-remarks',
  onClose: () => null,
  data: [],
  typeTable: '',
};

export default connect((state) => {
  return {
    fieldProjectReports: getFieldProjectReports(state),
  };
})(ProjectReportBudgetModal);
