import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PdfViewer from 'components/shared/pdf-viewer/PdfViewer';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import { getShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/selectors';
import {
  getSubmittalModalShow,
  getShowTransmittalPdf,
  getTransmittalPdf,
  getCurrentSubmittal,
  getSubmittalPdfViewer,
  getSubmittalCreatedRevisionModalShow,
} from 'components/admin/projects/details/project-submittal-log/store/selectors';
import { sendProjectResourceEmails } from 'components/admin/projects/details/store/actions';
import {
  setSubmittalModalShow,
  setShowTransmittalPdf,
  setSubmittalPdfViewer,
} from 'components/admin/projects/details/project-submittal-log/store/actions';
import ProjectEmailModal from 'components/admin/projects/details/project-email-modal/ProjectEmailModal';
import SubmittalModal from 'components/admin/projects/details/project-submittal-log/SubmittalModal';
import SubmittalCreatedRevisionModal from 'components/admin/projects/details/project-submittal-log/SubmittalCreatedRevisionModal';
import moment from 'moment';

class SubmittalModalContainer extends Component {
  static propTypes = {
    onClose: PropTypes.func,
  };

  static defaultProps = {
    onClose: null,
  };

  componentDidMount() {
    this.loadDirectory();
  }

  loadDirectory = () => {
    const { currentProject, directory, dispatch } = this.props;
    if (
      currentProject &&
      (!directory || directory.project_id !== currentProject.id)
    ) {
      dispatch(loadDirectoryRequest(currentProject.id));
    }
  };

  handleCloseSubmittalModal = () => {
    const { onClose, dispatch } = this.props;
    dispatch(setSubmittalModalShow(false));
    onClose && onClose();
  };

  handleTransmittalPdfClose = () => {
    this.props.dispatch(setShowTransmittalPdf(false));
  };

  handleSubmittalPdfViewerClose = () => {
    this.props.dispatch(setSubmittalPdfViewer(false));
  };

  handleSendEmail = (emailsData) => {
    this.props.dispatch(
      sendProjectResourceEmails(
        this.props.currentSubmittal.id,
        'Submittal',
        emailsData
      )
    );
  };

  buildEmailModalSubject = () => {
    const { currentProject, currentSubmittal } = this.props;
    return `${currentProject.number} ${currentProject.name} - Submittal ${currentSubmittal.csi_code.division}-${currentSubmittal.number} - ${currentSubmittal.title}`;
  };

  buildEmailModalMessage = () => {
    const { currentProject, currentSubmittal } = this.props;
    let location = '';
    if (
      currentProject.project_location &&
      currentProject.project_location.location
    ) {
      const projectLocation = currentProject.project_location.location;
      location = ` at ${projectLocation.street_address_1}, ${projectLocation.city} ${projectLocation.state}`;
    }

    return `Please find the attached Submittal #${currentSubmittal.csi_code.division}-${currentSubmittal.number} for Project ${currentProject.name}${location}. If you have any questions, please reach out via chat or email.`;
  };

  render() {
    const {
      currentUser,
      currentProject,
      directory,
      currentSubmittal,
      submittalModalShow,
      showTransmittalPdf,
      transmittalPdf,
      submittalPdfViewer,
      showEmailModal,
      showSubmittalCreatedRevisionModal,
    } = this.props;
    return (
      currentProject &&
      directory && (
        <React.Fragment>
          {submittalModalShow && (
            <SubmittalModal
              currentUser={currentUser}
              project={currentProject}
              directory={directory}
              submittal={currentSubmittal}
              show={submittalModalShow}
              handleClose={this.handleCloseSubmittalModal}
            />
          )}

          {showTransmittalPdf && (
            <PdfViewer
              content={{
                source: transmittalPdf,
                type: 'blob',
              }}
              show={showTransmittalPdf}
              onClose={this.handleTransmittalPdfClose}
            />
          )}

          {submittalPdfViewer && (
            <PdfViewer
              content={{
                source: `${
                  currentSubmittal.pdf_document.document.url
                }?time=${moment().unix()}`,
                type: 'url',
              }}
              show={submittalPdfViewer}
              onClose={this.handleSubmittalPdfViewerClose}
            />
          )}

          {showEmailModal && (
            <ProjectEmailModal
              subTitle={this.props.currentProject.name}
              handleSendEmail={this.handleSendEmail}
              projectId={currentProject.id}
              initialSubject={this.buildEmailModalSubject()}
              initialMessage={this.buildEmailModalMessage()}
              showHistoryButton
              itemType="Submittal"
              itemId={currentSubmittal.id}
            />
          )}
          {showSubmittalCreatedRevisionModal && (
            <SubmittalCreatedRevisionModal
              projectId={currentProject.id}
              handleClose={this.handleCloseSubmittalModal}
            />
          )}
        </React.Fragment>
      )
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    directory: getDirectory(state),
    currentSubmittal: getCurrentSubmittal(state),
    submittalModalShow: getSubmittalModalShow(state),
    showTransmittalPdf: getShowTransmittalPdf(state),
    transmittalPdf: getTransmittalPdf(state),
    submittalPdfViewer: getSubmittalPdfViewer(state),
    showEmailModal: getShowEmailModal(state),
    showSubmittalCreatedRevisionModal: getSubmittalCreatedRevisionModalShow(
      state
    ),
  };
})(SubmittalModalContainer);
