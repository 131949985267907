import React from 'react';
import './MegaMenuDropdown.css';
import vector from '../menu/vector.svg';
import vectorDark from '../menu/vector-dark.svg';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import MegaMenu from './MegaMenu';

export const DROPDOWN_TYPES = {
  FORM: 'form-mega-menu-dropdown',
  SECTION_SELECTOR: 'section-selector-mega-menu-dropdown',
};

export const CounterItem = (label, counter) => {
  return (
    <React.Fragment>
      {label}
      <span className="mega-menu-item-counter">{counter}</span>
    </React.Fragment>
  );
};

const MegaMenuDropdown = (props) => {
  const {
    type,
    className,
    options,
    categories,
    value,
    placeholder,
    onChange,
    renderSelector,
    darkMode,
    megaMenuWidth,
    customLabels,
  } = props;

  const handleOnChange = (option) => {
    if (onChange !== undefined) {
      onChange(option);
    }
  };

  const handleRenderSelector = (open) => {
    const selectedOption =
      value !== null && options.find((option) => option.value === value);

    if (renderSelector) {
      return renderSelector(selectedOption, open);
    }

    let label = selectedOption
      ? selectedOption.selectedLabel || selectedOption.label
      : placeholder;

    if (customLabels) {
      if (Object.keys(customLabels).includes(selectedOption.value))
        label = customLabels[selectedOption.value];
    }
    return (
      <div className="mega-menu-dropdown-selector">
        {label}
        <img
          className="mega-menu-dropdown-selector-arrow"
          src={darkMode ? vectorDark : vector}
          alt="arrow"
        />
      </div>
    );
  };

  const items = options
    .filter((option) => option.show !== false)
    .map((option) => ({
      className: `${option.className || ''} ${
        option.value === value ? 'selected' : ''
      }`,
      content: option.label,
      child: option.child,
      onClick: () => handleOnChange(option),
      closeOnSelect: option.closeOnSelect,
      isDate: option.isDate,
      onDateChange: option.onDateChange,
      dateValue: option.dateValue,
      subitems:
        option.subitems &&
        option.subitems.map((si) => ({
          className: `${si.className || ''} ${
            si.value === value ? 'selected' : ''
          }`,
          content: si.label,
          child: si.child,
          onClick: () => handleOnChange(si),
          onChange: () => handleOnChange(si),
          closeOnSelect: si.closeOnSelect,
          isDate: si.isDate,
          onDateChange: si.onDateChange,
          dateValue: si.dateValue,
        })),
    }));

  const processedCategories = () => {
    let pCats = {};

    Object.entries(categories).forEach(([category, options]) => {
      pCats[category] = options
        .filter((o) => !!o)
        .filter((option) => option.show !== false)
        .map((option) => ({
          className: `${option.className || ''} ${
            option.value === value ? 'selected' : ''
          }`,
          content: option.label,
          child: option.child,
          onClick: () => handleOnChange(option),
          closeOnSelect: option.closeOnSelect,
          isDate: option.isDate,
          onDateChange: option.onDateChange,
          dateValue: option.dateValue,
          subitems:
            option.subitems &&
            option.subitems.map((si) => ({
              className: `${si.className || ''} ${
                si.value === value ? 'selected' : ''
              }`,
              content: si.label,
              child: si.child,
              onClick: () => handleOnChange(si),
              onChange: () => handleOnChange(si),
              closeOnSelect: si.closeOnSelect,
              isDate: si.isDate,
              onDateChange: si.onDateChange,
              dateValue: si.dateValue,
            })),
        }));
    });
    return pCats;
  };

  return (
    <MegaMenu
      className={`mega-menu-dropdown mega-menu-dropdown-container ${type} ${
        className || ''
      }`}
      renderSelector={handleRenderSelector}
      items={items}
      placement="bottom-start"
      categories={processedCategories()}
      megaMenuWidth={megaMenuWidth}
    />
  );
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(MegaMenuDropdown);
