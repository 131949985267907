import React from 'react';
import { Redirect, Route } from 'react-router-dom';

/**
 * Conditional routing, based on show argument.
 * @param Component
 * @param show
 * @param fallbackUrl
 * @param rest
 * @return {*}
 * @constructor
 */
export const ConditionRoute = ({
  component: Component,
  show,
  fallbackUrl = null,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      show ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: fallbackUrl === null ? '/' : fallbackUrl,
          }}
        />
      )
    }
  />
);
