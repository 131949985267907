import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Swiper as SwiperLib, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import { getDarkMode } from 'selectors/theme';
import './Swiper.css';
import viewMoreSvg from './view-more.svg';
import viewMoreSvgDark from './view-more-dark.svg';

SwiperCore.use([Pagination]);

const Swiper = (props) => {
  const {
    className,
    slides,
    slidesPerView,
    spaceBetween,
    pagination: { clickable },
    viewMore,
  } = props;
  const darkMode = props.darkMode || props.fixedDarkMode;
  const [slideIndex, setSlideIndex] = useState(0);

  const renderSlide = (slide, index) => {
    const { content, className, onClick } = slide;

    return (
      <SwiperSlide className={className} key={index} onClick={onClick}>
        {content}
      </SwiperSlide>
    );
  };

  return (
    <React.Fragment>
      <SwiperLib
        className={classnames('swiper-container', {
          'dark-mode': darkMode,
          [className]: !!className,
        })}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        pagination={{
          clickable: clickable,
        }}
        onSlideChange={(swipper) => {
          setSlideIndex(swipper.activeIndex);
        }}
      >
        {slides.map(renderSlide)}
      </SwiperLib>
      {viewMore && (
        <div
          className={classnames('swiper-view-more', {
            'dark-mode': darkMode,
            [`${className}-view-more`]: !!className,
          })}
          onClick={viewMore.onClick}
        >
          {!!viewMore.renderSlideLeftContent &&
            viewMore.renderSlideLeftContent(slideIndex)}
          <span>View More</span>
          <img src={darkMode ? viewMoreSvgDark : viewMoreSvg} alt="view-more" />
          {!!viewMore.renderSlideRightContent &&
            viewMore.renderSlideRightContent(slideIndex)}
        </div>
      )}
    </React.Fragment>
  );
};

Swiper.propTypes = {
  className: PropTypes.string,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
        .isRequired,
      onClick: PropTypes.func,
    })
  ),
  slidesPerView: PropTypes.any,
  spaceBetween: PropTypes.number,
  fixedDarkMode: PropTypes.bool,
  pagination: PropTypes.shape({
    clickable: PropTypes.bool,
  }),
  viewMore: PropTypes.shape({
    onClick: PropTypes.func,
    renderSlideLeftContent: PropTypes.func,
    renderSlideRightContent: PropTypes.func,
  }),
};

Swiper.defaultProps = {
  className: '',
  slides: [],
  slidesPerView: 'auto',
  spaceBetween: 0,
  pagination: {
    clickable: true,
  },
  viewMore: null,
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(Swiper);
