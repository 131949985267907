import axios from 'axios';
import BaseApi from './base-api';

export class SubTechniciansApi extends BaseApi {
  getTechnicians(page, name) {
    const address = name
      ? `${this.baseUrl}/sub_technicians?page=${page}&by_name=${name}`
      : `${this.baseUrl}/sub_technicians?page=${page}`;
    return axios
      .get(address)
      .then((response) => {
        return {
          ok: true,
          technicians: response.data,
          perPage: response.headers['per-page'],
          total: response.headers.total,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  addTechnician(data) {
    const resultData = this.createAddEditTechnicianData(data);

    return axios
      .post(`${this.baseUrl}/sub_technicians`, resultData)
      .then((response) => {
        return {
          ok: true,
          technician: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateTechnician(id, data) {
    const resultData = this.createAddEditTechnicianData(data);

    return axios
      .put(`${this.baseUrl}/sub_technicians/${id}`, resultData)
      .then((response) => {
        return {
          ok: true,
          technician: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  removeTechnician(id) {
    return axios
      .delete(`${this.baseUrl}/sub_technicians/${id}`)
      .then(() => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createAddEditTechnicianData(data) {
    const resultData = {
      sub_technician: {
        first_name: data.firstName,
        last_name: data.lastName || '',
        email: data.email || '',
        phone_number: data.phoneNumber || '',
      },
    };

    return resultData;
  }
}

export default SubTechniciansApi = new SubTechniciansApi();
