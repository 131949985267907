import {
  SET_SHOW_ADD_AREA_MODAL,
  SET_SHOW_ADD_SITE_MODAL,
} from './action-types';

const initialState = {
  showAddSitesModal: false,
  showAddAreaModal: false,
  edit: false,
};

export default function projectSitesUiReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SHOW_ADD_SITE_MODAL:
      return {
        ...state,
        showAddSitesModal: action.payload.show,
        edit: action.payload.edit,
      };
    case SET_SHOW_ADD_AREA_MODAL:
      return {
        ...state,
        showAddAreaModal: action.payload.show,
        edit: action.payload.edit,
      };
    default:
      return state;
  }
}
