import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import SidebarForm from 'components/shared/sidebar-form/SidebarForm';
import './Registration.css';
import { getCurrentUser } from 'selectors/authentication';
import {
  getForm,
  getShowSimilarCompaniesModal,
  getShowRegisteredModal,
  getShowVerifyingModal,
  getSimilarCompanies,
  getRoles,
} from './store/selectors';
import {
  loadTradeCodes,
  loadUnions,
  loadRoles,
  loadDraft,
  saveDraft,
  setShowSimilarCompaniesModal,
  setShowRegisteredModal,
  setShowVerifyingModal,
  registerNewCompany,
  registerToExistentCompany,
} from './store/actions';
import CompanyInformation from './CompanyInformation';
import UserInformation from './UserInformation';
import PrimaryContact from './PrimaryContact';
import MessageModal, {
  MODAL_ICONS,
} from 'components/shared/modal/MessageModal';
import { reloadUser, logoutRequest } from 'actions/authentication';
import SimilarCompaniesModal from 'components/admin/companies/SimilarCompaniesModal';
import User from 'domain/user';

function Registration(props) {
  const {
    currentUser,
    form,
    showSimilarCompaniesModal,
    showRegisteredModal,
    showVerifyingModal,
    similarCompanies,
    roles,
    dispatch,
  } = props;

  useEffect(() => {
    dispatch(loadTradeCodes());
    dispatch(loadUnions());
    dispatch(loadRoles());
    dispatch(loadDraft(currentUser));

    if (!User.isRegistering(currentUser)) {
      dispatch(setShowVerifyingModal(true));
    }
  }, []);

  const handleSaveDraft = () => {
    dispatch(saveDraft(form));
  };

  const validCompany = () => {
    const {
      form: {
        company: {
          name,
          street_address_1,
          city,
          state,
          zip,
          logo,
          type,
          license_number,
          trade_codes,
          unions,
          other_union,
        },
      },
    } = props;
    const hasOtherUnion = unions.some((union) => union[0] === 'other');

    return (
      name &&
      street_address_1 &&
      city &&
      state &&
      zip &&
      logo &&
      type &&
      license_number &&
      trade_codes.length > 0 &&
      unions.length > 0 &&
      (!hasOtherUnion || other_union)
    );
  };

  const validUser = () => {
    const {
      user: { email, first_name, last_name, phone_number, title, role_id },
    } = form;

    return email && first_name && last_name && phone_number && role_id && title;
  };

  const validPrimaryContact = () => {
    const {
      form: {
        primary_contact: {
          same_user,
          email,
          first_name,
          last_name,
          phone_number,
          title,
          role_id,
        },
      },
    } = props;

    return (
      same_user ||
      (email && first_name && last_name && phone_number && role_id && title)
    );
  };

  const validForm = () => {
    return !!(validCompany() && validUser() && validPrimaryContact());
  };

  const handleResolveSimilar = (companyId) => {
    dispatch(
      companyId
        ? registerToExistentCompany(companyId, form, roles)
        : registerNewCompany(form, roles, false)
    );
  };

  const handleSubmit = () => {
    dispatch(registerNewCompany(form, roles, true));
  };

  const handleCancelSimilar = () => {
    dispatch(setShowSimilarCompaniesModal(false));
  };

  const handleRegistered = () => {
    dispatch(setShowRegisteredModal(false));
    dispatch(reloadUser());
  };

  const handleAddNewCompany = () => {
    dispatch(setShowVerifyingModal(false));
  };

  const handleLogout = () => {
    dispatch(logoutRequest());
  };

  const isRejected = User.isRejected(currentUser);

  return (
    <React.Fragment>
      <SidebarForm
        className="admin-user-registration"
        title="User Registration"
        sections={[
          {
            id: 'main',
            title: 'Registration Information',
            render: () => (
              <React.Fragment>
                <CompanyInformation />
                <UserInformation />
                <PrimaryContact />
              </React.Fragment>
            ),
          },
        ]}
        actions={[
          <Button
            key="save-draft"
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.TRANSPARENT}
            label="Save Draft"
            onClick={handleSaveDraft}
          />,
          <Button
            key="submit"
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label="Register"
            disabled={!validForm()}
            onClick={handleSubmit}
          />,
        ]}
      />

      {showSimilarCompaniesModal && (
        <SimilarCompaniesModal
          companies={similarCompanies}
          onSubmit={handleResolveSimilar}
          onClose={handleCancelSimilar}
        />
      )}

      {showRegisteredModal && (
        <MessageModal
          show={true}
          onHide={handleRegistered}
          message={
            <React.Fragment>
              <p className="small-regular">Your login has been created.</p>
              <p className="small-regular">
                Please continue with registration in order to add your first
                project.
              </p>
            </React.Fragment>
          }
          icon={MODAL_ICONS.SUCCESS}
        />
      )}

      {showVerifyingModal && (
        <MessageModal
          show={true}
          message={
            isRejected ? (
              <React.Fragment>
                <p className="small-regular">
                  Your subscription request was not able to be verified.
                </p>
                <p className="small-regular">
                  If the request to link to this Company was made in error, you
                  may register as a new Company in the application by clicking
                  below.
                </p>
                <p className="small-regular">
                  If you believe this is the correct Company, please contact an
                  Administrator so they may add your account information
                  internally.
                </p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <p className="small-regular">
                  Your subscription request has been created and sent to company
                  administrators to approve it.
                </p>
                <p className="small-regular">
                  If the request to link to this Company was made in error, you
                  may register as a new Company in the application by clicking
                  below.
                </p>
              </React.Fragment>
            )
          }
          icon={isRejected ? MODAL_ICONS.EXCLAMATION : MODAL_ICONS.SUCCESS}
          buttons={[
            <Button
              key="new-company"
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Add New Company"
              onClick={handleAddNewCompany}
            />,
            <Button
              key="close"
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Close"
              onClick={handleLogout}
            />,
          ]}
        />
      )}
    </React.Fragment>
  );
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    form: getForm(state),
    showSimilarCompaniesModal: getShowSimilarCompaniesModal(state),
    showRegisteredModal: getShowRegisteredModal(state),
    showVerifyingModal: getShowVerifyingModal(state),
    similarCompanies: getSimilarCompanies(state),
    roles: getRoles(state),
  };
})(Registration);
