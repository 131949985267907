import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';

class ProjectLocationsApi extends BaseApi {
  getProjectLocations(clientId, page, search) {
    let filter = '';
    if (clientId !== undefined) {
      filter = `by_company=${clientId}`;
    }
    if (search !== undefined) {
      filter += `&search=${search}`;
    }

    filter += page ? `&page=${page}` : '';

    return axios
      .get(`${this.baseUrl}/project_locations?${filter}`)
      .then((response) => {
        return {
          ok: true,
          project_locations: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getProjectLocation(projectLocationId) {
    return axios
      .get(`${this.baseUrl}/project_locations/${projectLocationId}`)
      .then((response) => {
        return {
          ok: true,
          project_location: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getProjects(projectLocationId, page, projectStatus) {
    return axios
      .get(
        `${this.baseUrl}/project_locations/${projectLocationId}/projects?page=${
          page !== undefined ? page : 1
        }${projectStatus ? `&${projectStatus}=true` : ''}`
      )
      .then((response) => {
        return {
          ok: true,
          projects: {
            content: response.data,
            total: numeral(response.headers['total']).value(),
            totalPending: numeral(response.headers['total-pending']).value(),
            totalInProgress: numeral(
              response.headers['total-in_progress']
            ).value(),
            totalComplete: numeral(response.headers['total-complete']).value(),
            pageSize: numeral(response.headers['per-page']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createProjectLocation(data) {
    const formData = this.createFormData(data);
    return axios
      .post(this.baseUrl + '/project_locations', formData)
      .then((response) => {
        return {
          ok: true,
          project_location: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateProjectLocation(projectLocationId, data) {
    const formData = this.createFormData(data);
    return axios
      .put(`${this.baseUrl}/project_locations/${projectLocationId}`, formData)
      .then((response) => {
        return {
          ok: true,
          project_location: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createFormData(data) {
    const formData = {
      project_location: {
        name: data.name || '',
        location_attributes: {
          id: data.location.id || '',
          street_address_1: data.location.streetAddress,
          street_address_2: data.location.unitNumber || '',
          city: data.location.city,
          state: data.location.state,
          zip: data.location.zipCode || '',
        },
      },
    };

    if (data.relatedCompanyId)
      formData['related_company_id'] = data.relatedCompanyId;

    return formData;
  }

  updateProjectLocationNameAndLocation(projectLocationId, locationId, data) {
    let putData = {
      project_location: {
        location_attributes: {
          id: locationId,
          street_address_1: data.streetAddress,
          street_address_2: data.unitNumber,
          city: data.city,
          state: data.state,
          zip: data.zipCode,
        },
      },
    };

    if (data.name !== '') {
      putData['project_location']['name'] = data.name;
    }

    return axios
      .put(this.baseUrl + '/project_locations/' + projectLocationId, putData)
      .then((response) => {
        return {
          ok: true,
          newProjectLocation: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export let projectLocationsApi = new ProjectLocationsApi();
export default ProjectLocationsApi = new ProjectLocationsApi();
