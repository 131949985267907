import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';
import {
  setCurrentFieldReport,
  setFieldReportDeleteModal,
  openFieldReport,
  setFieldReportPdf,
  reloadFieldReport,
} from './store/actions';
import FieldReportPermissions from 'permissions/field-report-permissions';

const useFieldReportTable = (props) => {
  const { dispatch, currentUser, fieldReports, project, location } = props;
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    const state =
      location && (location.state || queryString.parse(location.search));
    if (
      state &&
      Object.keys(state).length > 0 &&
      state.fieldReports &&
      state.reportId &&
      !isNaN(state.reportId)
    ) {
      handleOpenPdf({ id: Number(state.reportId) });
    }
  }, []);

  const canEdit = (report) =>
    FieldReportPermissions.canEdit(currentUser, project, report);
  const canOpenPdf = (report) =>
    FieldReportPermissions.canOpenPdf(currentUser, project, report);
  const canSendEmail = (report) =>
    FieldReportPermissions.canSendEmail(currentUser, project, report);
  const canDelete = (report) =>
    FieldReportPermissions.canDelete(currentUser, project, report);

  const handleSendEmail = (report) => {
    dispatch(setCurrentFieldReport(report));
    dispatch(setShowEmailModal(true));
  };

  const handleOpenPdf = (report, isRetry = false) => {
    if (report.pdf_document)
      dispatch(setFieldReportPdf(report.pdf_document.document.url));
    else if (!isRetry) {
      dispatch(reloadFieldReport(project.id, report.id)).then((response) => {
        setTimeout(() => handleOpenPdf(response.report || report, true), 400);
      });
    } else {
      setShowErrorModal(true);
    }
  };

  const handleDelete = (report) => {
    dispatch(setCurrentFieldReport(report));
    dispatch(setFieldReportDeleteModal(true));
  };

  const handleEdit = (report) => {
    if (canEdit(report)) dispatch(openFieldReport(project.id, report.id));
  };

  const handleRowSelected = (index) => {
    const report = fieldReports[index];
    if (report) {
      if (canEdit(report)) handleEdit(report);
      else if (canOpenPdf(report)) handleOpenPdf(report);
    }
  };

  return {
    showErrorModal,
    setShowErrorModal,
    handleSendEmail,
    handleOpenPdf,
    handleDelete,
    handleEdit,
    handleRowSelected,
    canEdit,
    canOpenPdf,
    canSendEmail,
    canDelete,
  };
};

export default useFieldReportTable;
