import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import './SubmittalLogTable.css';
import Date from 'components/shared/text/Date';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import BaseStatus from 'components/shared/text/BaseStatus';
import { submittalStatusLabel } from 'domain/submittal-status-enum';
import doneSvg from 'images/done.svg';
import doneDarkModeSvg from 'images/doneDarkMode.svg';
import moment from 'moment';
import { getDarkMode } from 'selectors/theme';
import FilterHeader from 'components/shared/table/FilterHeader';

class SubmittalLeadTimeLogTable extends Component {
  static propTypes = {
    submittals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    tradeCodes: PropTypes.arrayOf(PropTypes.shape({})),
    total: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    currentStatus: PropTypes.string.isRequired,
    pageSize: PropTypes.number.isRequired,
    unreadById: PropTypes.shape({}),
    handlePageChange: PropTypes.func.isRequired,
    handleOpenSubmittal: PropTypes.func.isRequired,
  };

  checkDeliveryIsDue = (element) => {
    const fieldDeliveryDate = moment(element.field_delivery);
    const today = moment().format('YYYY-MM-DD');
    return fieldDeliveryDate.isBefore(today) && !element.delivered_in_id;
  };

  handleGenerateLink = (id) => {
    return `?submittalId=${id}`;
  };

  mapSubmittals = (headers) => {
    const { unreadById, handleOpenSubmittal, darkMode } = this.props;
    let currentCsiCodeId = null;
    return [].concat(
      ...this.props.submittals.map((submittal) => {
        const link = this.handleGenerateLink(submittal.id);
        let row = [];
        if (currentCsiCodeId !== submittal.csi_code.id) {
          currentCsiCodeId = submittal.csi_code.id;
          row.push(
            <tr key={`csi-${currentCsiCodeId}`} className="csi-code-row">
              <td>{submittal.csi_code.division}</td>
              <td className="csi-code-label" colSpan={headers.length - 1}>
                {submittal.csi_code.label}
              </td>
            </tr>
          );
        }

        const leadTime = submittal.lead_time_weeks;
        const unread = unreadById && !!unreadById[submittal.id];
        let className = 'selectable link-tab';
        if (this.checkDeliveryIsDue(submittal)) {
          className += ' due-date';
        }
        row.push(
          <tr
            key={submittal.id}
            className={className}
            onClick={(e) => {
              e.preventDefault();
              handleOpenSubmittal(submittal.id);
            }}
          >
            <td>
              <a href={link}>
                {unread && (
                  <BaseStatus
                    labels={[
                      {
                        id: 'unread',
                        label: '',
                      },
                    ]}
                    status="unread"
                  />
                )}
                {submittal.formatted_number}
              </a>
            </td>
            <td className="submittal-title">
              <a href={link}>{submittal.title || '-'}</a>
            </td>
            <td>
              <a href={link}>{submittalStatusLabel(submittal)}</a>
            </td>
            <td>
              <a href={link}>
                {leadTime !== null
                  ? `${leadTime} ${leadTime === 1 ? 'Week' : 'Weeks'}`
                  : '-'}
              </a>
            </td>
            <td>
              <a href={link}>
                {submittal.vendor_delivery ? (
                  <Date value={submittal.vendor_delivery} format="MM/DD/YYYY" />
                ) : (
                  '-'
                )}
              </a>
            </td>
            <td>
              <a href={link}>
                {submittal.field_delivery ? (
                  <Date value={submittal.field_delivery} format="MM/DD/YYYY" />
                ) : (
                  '-'
                )}
              </a>
            </td>
            <td>
              <a href={link}>
                {submittal.delivered_in_id ? (
                  <img
                    alt="done check"
                    src={darkMode ? doneDarkModeSvg : doneSvg}
                  />
                ) : (
                  ''
                )}
              </a>
            </td>
            <td>
              <a href={link}>
                <Button
                  type={BUTTON_TYPES.LINK}
                  color={BUTTON_COLORS.GREEN}
                  label="View"
                />
              </a>
            </td>
          </tr>
        );

        return row;
      })
    );
  };

  render() {
    const {
      submittals,
      tradeCodes,
      currentPage,
      currentStatus,
      pageSize,
      total,
      handlePageChange,
      darkMode,
      tradeCodeFilter = 'all',
    } = this.props;
    const headers = [
      <FilterHeader
        className="trade-code-header"
        label="COST CODE"
        options={[
          {
            id: 'all',
            label: 'All Cost Codes',
          },
          ...(tradeCodes || [])
            .map((code) => ({
              id: code.id,
              label: `${code.division} ${code.label}`,
            }))
            .sort((code1, code2) => code1.label.localeCompare(code2.label)),
        ]}
        selected={tradeCodeFilter}
        search={true}
        onFilter={(filter) => handlePageChange(1, currentStatus, null, filter)}
      />,
      'TITLE',
      'STATUS',
      'LEAD TIME',
      'VENDOR DELIVERY',
      'FIELD DELIVERY',
      'DELIVERED',
      'ACTIONS',
    ];
    return (
      <div className="submittal-lead-time-log-table-container">
        <Table
          className={`simple-table projects-table submittal-lead-time-log-table ${
            darkMode ? 'dark-mode' : ''
          }`}
        >
          <colgroup>
            {headers.map((element, index) => {
              return <col key={index + 1} />;
            })}
          </colgroup>

          <thead>
            <tr>
              {headers.map((element, index) => {
                return (
                  <th key={index}>
                    <span>{element}</span>
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {submittals.length === 0 ? (
              <tr>
                <td className="td-empty-table" colSpan={headers.length}>
                  No Submittals
                </td>
              </tr>
            ) : (
              this.mapSubmittals(headers)
            )}
          </tbody>
        </Table>
        <div className="pagination-container">
          <SimplePagination
            currentPage={currentPage}
            pageSize={pageSize}
            total={total}
            onPageChange={(page) => {
              handlePageChange(page, currentStatus, null, tradeCodeFilter);
            }}
          />
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(SubmittalLeadTimeLogTable);
