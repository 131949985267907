import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import './PreviewPopover.css';

class PreviewPopover extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    button: PropTypes.element.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    date: PropTypes.element,
    content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    placement: PropTypes.string,
    float: PropTypes.string,
    trigger: PropTypes.string,
  };

  static defaultProps = {
    placement: 'top',
    float: 'right',
  };

  render() {
    const {
      id,
      button,
      title,
      subtitle,
      date,
      content,
      placement,
      float,
      trigger,
      darkMode,
    } = this.props;

    const popover = (
      <Popover
        id={id}
        className={`preview-popover ${
          darkMode ? 'dark-mode' : ''
        } ${float}Position`}
        title={title}
      >
        {subtitle && <div className="preview-subtitle">{subtitle}</div>}
        {date && <div className="preview-date">{date}</div>}
        <div className="preview-content">{content}</div>
        <div className="preview-arrow"></div>
      </Popover>
    );

    return (
      <OverlayTrigger trigger={trigger} placement={placement} overlay={popover}>
        {button}
      </OverlayTrigger>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(PreviewPopover);
