import { showErrorMessage } from 'actions/messages';
import {
  ORDER_MATERIALS_LOADED,
  ORDER_MATERIALS_FORM_VALUE_CHANGED,
  ORDER_MATERIALS_CONFIRMATION_MODAL_LOAD,
} from 'actions/order-materials/action-types';
import { orderMaterialsApi } from 'services/api/order-materials-api';

export function loadOrderMaterialsRequest(projectId, token) {
  return (dispatch) => {
    return orderMaterialsApi
      .getOrderMaterials(projectId, token)
      .then((response) => {
        if (response.ok) {
          dispatch(loadOrderMaterialsSuccess(response.order));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadOrderMaterialsSuccess(order) {
  return {
    type: ORDER_MATERIALS_LOADED,
    payload: order,
  };
}

export function changeOrderMaterialsFormValue(attribute, value) {
  return {
    type: ORDER_MATERIALS_FORM_VALUE_CHANGED,
    payload: { attribute, value },
  };
}

export function confirmationModalWindowLoad(value) {
  return {
    type: ORDER_MATERIALS_CONFIRMATION_MODAL_LOAD,
    payload: value,
  };
}

export function confirmOrder(projectId, token, params) {
  return (dispatch) => {
    return orderMaterialsApi
      .confirmOrder(projectId, token, params)
      .then((response) => {
        if (response.ok) {
          dispatch(loadOrderMaterialsSuccess(response.order));
          dispatch(confirmationModalWindowLoad(true));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}
