import { showErrorMessage } from 'actions/messages';
import { closeoutCatsApi } from 'services/api/closeout-cats-api';
import { closeoutCompanyTypesApi } from 'services/api/closeout-company-types-api';
import { closeoutApi } from 'services/api/closeout-api';
import { closeoutContributorsApi } from 'services/api/closeout-contributors-api';
import { closeoutContributorCatsApi } from 'services/api/closeout-contributor-cats-api';
import { closeoutManualsApi } from 'services/api/closeout-manuals-api';
import { closeoutCatsCompanyTypesApi } from 'services/api/closeout-cats-company-types-api';
import { projectLocationsApi } from 'services/api/project-locations-api';
import {
  CLOSEOUT_CATS_SET,
  CLOSEOUT_COMPANY_TYPES_SET,
  CLOSEOUT_SET,
  CLOSEOUT_CONTRIBS_SET,
  CLOSEOUT_CONTRIB_SET,
  CLOSEOUT_PERCENTAGE_SET,
  CLOSEOUT_MANUALS_SET,
  CLOSEOUT_MANUAL_SET,
  CLOSEOUT_MANUAL_UPDATE,
  CLOSEOUT_MANUAL_DELETE,
  CLOSEOUT_CATS_COMPANY_TYPES_SET,
  CLOSEOUT_CATS_COMPANY_TYPE_SET,
  CLOSEOUT_CAT_DELETE,
  CLOSEOUT_CAT_UPDATE,
  CLOSEOUT_CATS_ORDER_CHANGE,
  SHOW_CLOSEOUT_CAT_DELETION_CONFIRMATION,
  SHOW_CLOSEOUT_CAT_MODAL,
  CLOSEOUT_CAT_SET,
  CLOSEOUT_CONTRIB_CAT_UPDATE,
  HIDE_CLOSEOUT_CAT_MODAL,
  HIDE_CLOSEOUT_CAT_DELETION_CONFIRMATION,
} from 'components/admin/projects/details/project-closeout/store/action-types';

export function loadCloseoutCatsSuccess(closeoutCats) {
  return {
    type: CLOSEOUT_CATS_SET,
    payload: closeoutCats,
  };
}

export function loadCloseoutCompanyTypesSuccess(closeoutCompanyTypes) {
  return {
    type: CLOSEOUT_COMPANY_TYPES_SET,
    payload: closeoutCompanyTypes,
  };
}

export function loadCloseoutCatsRequest(projectId) {
  return (dispatch) => {
    return closeoutCatsApi.getCloseoutCats(projectId).then((response) => {
      if (response.ok) {
        dispatch(loadCloseoutCatsSuccess(response.closeoutCats));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function loadCloseoutCompanyTypesRequest() {
  return (dispatch) => {
    return closeoutCompanyTypesApi
      .getCloseoutCompanyTypes()
      .then((response) => {
        if (response.ok) {
          dispatch(
            loadCloseoutCompanyTypesSuccess(response.closeoutCompanyTypes)
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
  };
}

export function loadCloseoutSuccess(closeout) {
  return {
    type: CLOSEOUT_SET,
    payload: closeout,
  };
}

export function loadCloseoutRequest(projectId) {
  return (dispatch) => {
    return closeoutApi.getCloseout(projectId).then((response) => {
      if (response.ok) {
        dispatch(loadCloseoutSuccess(response.closeout));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function updateCloseout(projectId, values) {
  return (dispatch) => {
    return closeoutApi.updateCloseout(projectId, values).then((response) => {
      if (response.ok) {
        dispatch(loadCloseoutSuccess(response.closeout));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function requestCloseout(projectId) {
  return (dispatch) => {
    return closeoutApi.requestCloseout(projectId).then((response) => {
      if (response.ok) {
        dispatch(loadCloseoutSuccess(response.closeout));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function loadCloseoutContribsSuccess(closeoutContribs, total) {
  return {
    type: CLOSEOUT_CONTRIBS_SET,
    payload: { closeoutContribs, total },
  };
}

export function loadCloseoutContribsRequest(projectId) {
  return (dispatch) => {
    return closeoutContributorsApi
      .getCloseoutContributors(projectId)
      .then((response) => {
        if (response.ok) {
          dispatch(
            loadCloseoutContribsSuccess(
              response.closeoutContributors,
              response.total
            )
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function updateCloseoutContribSuccess(closeoutContrib) {
  return {
    type: CLOSEOUT_CONTRIB_SET,
    payload: closeoutContrib,
  };
}

export function updateCloseoutContrib(projectId, closeoutContrib, values) {
  return (dispatch) => {
    return closeoutContributorsApi
      .updateCloseoutContributor(projectId, closeoutContrib, values)
      .then((response) => {
        if (response.ok) {
          dispatch(updateCloseoutContribSuccess(response.closeoutContributor));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function closeoutPercentageSuccess(percentage) {
  return {
    type: CLOSEOUT_PERCENTAGE_SET,
    payload: percentage,
  };
}

export function closeoutPercentage(projectId) {
  return (dispatch) => {
    return closeoutContributorCatsApi
      .getCompletedPercentage(projectId)
      .then((response) => {
        if (response.ok) {
          dispatch(closeoutPercentageSuccess(response.completedPercentage));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function updateProjectLocation(projectLocationId, values) {
  return (dispatch) => {
    return projectLocationsApi
      .updateProjectLocation(projectLocationId, values)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadCloseoutManualsSuccess(
  closeoutManuals,
  closeoutManualsCurrentPage,
  closeoutManualsTotal,
  closeoutManualsPageSize
) {
  return {
    type: CLOSEOUT_MANUALS_SET,
    payload: {
      closeoutManuals,
      closeoutManualsCurrentPage,
      closeoutManualsTotal,
      closeoutManualsPageSize,
    },
  };
}

export function loadCloseoutManualsRequest(projectId, page) {
  return (dispatch) => {
    return closeoutManualsApi
      .getCloseoutManuals(projectId, page)
      .then((response) => {
        if (response.ok) {
          dispatch(
            loadCloseoutManualsSuccess(
              response.closeoutManuals,
              page,
              response.total,
              response.pageSize
            )
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function createCloseoutManualSuccess(closeoutManual) {
  return {
    type: CLOSEOUT_MANUAL_SET,
    payload: closeoutManual,
  };
}

export function createCloseoutManual(projectId) {
  return (dispatch) => {
    return closeoutManualsApi
      .createCloseoutManual(projectId)
      .then((response) => {
        if (response.ok) {
          dispatch(createCloseoutManualSuccess(response.closeoutManual));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadCloseoutManualSuccess(closeoutManual) {
  return {
    type: CLOSEOUT_MANUAL_UPDATE,
    payload: closeoutManual,
  };
}

export function loadCloseoutManual(projectId, id) {
  return (dispatch) => {
    return closeoutManualsApi
      .getCloseoutManual(projectId, id)
      .then((response) => {
        if (response.ok) {
          dispatch(loadCloseoutManualSuccess(response.closeoutManual));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function deleteCloseoutManualSuccess(id) {
  return {
    type: CLOSEOUT_MANUAL_DELETE,
    payload: id,
  };
}

export function deleteCloseoutManual(projectId, id) {
  return (dispatch) => {
    return closeoutManualsApi
      .deleteCloseoutManual(projectId, id)
      .then((response) => {
        if (response.ok) {
          dispatch(deleteCloseoutManualSuccess(id));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadCloseoutCatsCompanyTypesSuccess(types) {
  return {
    type: CLOSEOUT_CATS_COMPANY_TYPES_SET,
    payload: types,
  };
}

export function loadCloseoutCatsCompanyTypes(projectId) {
  return (dispatch) => {
    return closeoutCatsCompanyTypesApi
      .getCloseoutCatsCompanyTypes(projectId)
      .then((response) => {
        if (response.ok) {
          dispatch(
            loadCloseoutCatsCompanyTypesSuccess(
              response.closeoutCatsCompanyTypes
            )
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setCloseoutCatsCompanyType(catsCompanyType) {
  return {
    type: CLOSEOUT_CATS_COMPANY_TYPE_SET,
    payload: catsCompanyType,
  };
}

export function updateCloseoutCatsCompanyType(
  projectId,
  catCompanyTypeId,
  applies
) {
  return (dispatch) => {
    return closeoutCatsCompanyTypesApi
      .updateCloseoutCatsCompanyType(projectId, catCompanyTypeId, applies)
      .then((response) => {
        if (response.ok) {
          dispatch(
            setCloseoutCatsCompanyType(response.closeoutCatsCompanyType)
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function downloadCloseoutDocumentsRequest(projectId) {
  return (dispatch) => {
    return closeoutApi.downloadCloseoutDocuments(projectId).then((response) => {
      if (!response.ok) {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
  };
}

export function createCloseoutCatRequest(projectId, name) {
  return (dispatch) => {
    return closeoutCatsApi
      .createCloseoutCat(projectId, name)
      .then((response) => {
        if (response.ok) {
          dispatch(setCloseoutCatSuccess(response.closeoutCat));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
}

export function deleteCloseoutCatSuccess(closeoutCat) {
  return {
    type: CLOSEOUT_CAT_DELETE,
    payload: closeoutCat.id,
  };
}

export function hideCloseoutCatDeletionConfirmation() {
  return {
    type: HIDE_CLOSEOUT_CAT_DELETION_CONFIRMATION,
    payload: null,
  };
}

export function deleteCloseoutCatRequest(projectId, closeoutCatId) {
  return (dispatch) => {
    return closeoutCatsApi
      .deleteCloseoutCat(projectId, closeoutCatId)
      .then((response) => {
        if (response.ok) {
          dispatch(deleteCloseoutCatSuccess(response.closeoutCat));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
}

export function updateCloseoutCatSucess(id, label) {
  return {
    type: CLOSEOUT_CAT_UPDATE,
    payload: { id, label },
  };
}

export function updateCloseoutCatRequest(projectId, name, id) {
  return (dispatch) => {
    return closeoutCatsApi
      .updateCloseoutCat(projectId, name, id)
      .then((response) => {
        if (response.ok) {
          dispatch(updateCloseoutCatSucess(id, name));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
}

export function updateCloseoutCatsOrderRequest(projectId, closeoutCats) {
  return (dispatch) => {
    return closeoutCatsApi
      .updateCloseoutCatsOrder(projectId, closeoutCats)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
}

export const setCloseoutCatsOrderChange = (result) => (dispatch) => {
  dispatch({
    type: CLOSEOUT_CATS_ORDER_CHANGE,
    payload: result,
  });
  return Promise.resolve();
};

export function showCloseoutCatDeletionConfirmation(closeoutCat) {
  return {
    type: SHOW_CLOSEOUT_CAT_DELETION_CONFIRMATION,
    payload: { closeoutCat },
  };
}

export function showCloseoutCatModal(closeoutCat) {
  return {
    type: SHOW_CLOSEOUT_CAT_MODAL,
    payload: { closeoutCat },
  };
}

export function setCloseoutCatSuccess(closeoutCat) {
  return {
    type: CLOSEOUT_CAT_SET,
    payload: { closeoutCat },
  };
}

export function updateCloseoutContributorCatSucess(closeoutContributorCat) {
  return {
    type: CLOSEOUT_CONTRIB_CAT_UPDATE,
    payload: { closeoutContributorCat },
  };
}

export function updateCloseoutContribCatRequest(
  projectId,
  closeoutContributorCatId,
  applies
) {
  return (dispatch) => {
    return closeoutContributorCatsApi
      .updateCloseoutContributorCat(
        projectId,
        closeoutContributorCatId,
        applies
      )
      .then((response) => {
        if (response.ok) {
          dispatch(
            updateCloseoutContributorCatSucess(response.closeoutContributorCat)
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
}

export function hideCloseoutCatModal() {
  return {
    type: HIDE_CLOSEOUT_CAT_MODAL,
    payload: null,
  };
}
