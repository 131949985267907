import axios from 'axios';
import BaseApi from './base-api';

class RolesApi extends BaseApi {
  getRoles(type) {
    let filter = '';
    if (type !== undefined) {
      filter = `?type=${type}`;
    }

    return axios
      .get(`${this.baseUrl}/roles${filter}`)
      .then((response) => {
        return {
          ok: true,
          permissions: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export let rolesApi = new RolesApi();
export default RolesApi = new RolesApi();
