import { showErrorMessage } from '../../messages';
import { usersApi } from '../../../services/api/users-api';
import {
  USERS_INVITE_USER_FORM_INVALID,
  USERS_INVITE_USER_FORM_LOAD,
  USERS_INVITE_USER_FORM_VALID,
  USERS_INVITE_USER_FORM_VALUE_UPDATE,
  USERS_INVITE_USER_ID_SET,
  USERS_INVITE_USER_MODAL_SHOW,
  USERS_INVITE_USER_ROLES_LOAD_SUCCESS,
  USERS_LOAD_SUCCESS,
  USERS_USER_ADD,
  USERS_USER_EDIT,
  USERS_USER_REMOVE,
} from './action-types';
import { updateUser } from 'actions/authentication.js';
import { rolesApi } from '../../../services/api/roles-api';

export function addUser(user) {
  return {
    type: USERS_USER_ADD,
    payload: user,
  };
}

export function deleteUserRequest(userId) {
  return (dispatch) => {
    return usersApi.removeUser(userId).then((response) => {
      if (response.ok) {
        dispatch(removeUser(userId));
        dispatch(setShowInviteUserModal(false));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function editUser(user) {
  return {
    type: USERS_USER_EDIT,
    payload: user,
  };
}

export function invalidForm(invalidFields, message) {
  return {
    type: USERS_INVITE_USER_FORM_INVALID,
    payload: { invalidFields, message },
  };
}

export function loadRolesRequest() {
  return (dispatch) => {
    return rolesApi.getRoles().then((response) => {
      if (response.ok) {
        dispatch(loadRolesSuccess(response.permissions));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadRolesSuccess(roles) {
  return {
    type: USERS_INVITE_USER_ROLES_LOAD_SUCCESS,
    payload: roles,
  };
}

export function loadUserForm(user) {
  return (dispatch) => {
    let form;
    if (user !== null) {
      form = {
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        phoneNumber: user.phone_number != null ? user.phone_number : '',
        rolesId: user.roles.length > 0 ? user.roles[0].id : null, // Only one role can be defined
        title: user.title !== null ? user.title : '',
      };
    } else {
      form = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        rolesId: null,
        title: '',
      };
    }

    dispatch({
      type: USERS_INVITE_USER_FORM_LOAD,
      payload: form,
    });
  };
}

export function loadUsersRequest() {
  return (dispatch) => {
    return usersApi.getUsers().then((response) => {
      if (response.ok) {
        dispatch(loadUsersSuccess(response.users));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadUsersSuccess(users) {
  return {
    type: USERS_LOAD_SUCCESS,
    payload: users,
  };
}

export function removeUser(userId) {
  return {
    type: USERS_USER_REMOVE,
    payload: userId,
  };
}

export function setCurrentUserId(userId) {
  return {
    type: USERS_INVITE_USER_ID_SET,
    payload: userId,
  };
}

export function setShowInviteUserModal(open, state) {
  return {
    type: USERS_INVITE_USER_MODAL_SHOW,
    payload: { open, state: state ? state : undefined },
  };
}

export function setResendInviteUser(resend, userId) {
  return (dispatch) => {
    return usersApi.resendUserInvite(userId).then((response) => {
      if (response.ok) {
        dispatch(validForm(response));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function submitFormRequest(form, currentUserId) {
  return (dispatch) => {
    // Validate form
    const requiredFields = [
      'firstName',
      'lastName',
      'email',
      'phoneNumber',
      'rolesId',
    ];
    const invalidFields = [];
    requiredFields.forEach((attribute) => {
      if (form[attribute] === '' || form[attribute] === null) {
        invalidFields.push(attribute);
      }
    });

    if (invalidFields.length > 0) {
      dispatch(
        invalidForm(
          invalidFields,
          'The outlined inputs should be added in order to invite a user.'
        )
      );

      return null;
    } else {
      dispatch(validForm());

      if (currentUserId === 0) {
        return usersApi
          .addUser(
            form.firstName,
            form.lastName,
            form.email,
            form.phoneNumber,
            [form.rolesId],
            form.title
          )
          .then((response) => {
            if (response.ok) {
              dispatch(addUser(response.user));
              dispatch(setShowInviteUserModal(false));
            } else {
              dispatch(showErrorMessage(response.errors));
            }

            return response;
          });
      } else {
        return usersApi
          .updateUser(
            currentUserId,
            form.firstName,
            form.lastName,
            form.email,
            form.phoneNumber,
            [form.rolesId],
            form.title
          )
          .then((response) => {
            if (response.ok) {
              dispatch(editUser(response.user));
              dispatch(setShowInviteUserModal(false));
            } else {
              dispatch(showErrorMessage(response.errors));
            }

            return response;
          });
      }
    }
  };
}

export function updateFormValue(attribute, value) {
  return {
    type: USERS_INVITE_USER_FORM_VALUE_UPDATE,
    payload: { attribute, value },
  };
}

export function validForm() {
  return {
    type: USERS_INVITE_USER_FORM_VALID,
  };
}

export function updateTermsAndConditions(isAccepted) {
  return (dispatch) => {
    return usersApi.updateTermsAndConditions(isAccepted).then((response) => {
      if (response.ok) {
        dispatch(updateUser(response.data));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}
