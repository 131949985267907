import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MessageModal, {
  MODAL_ICONS,
} from 'components/shared/modal/MessageModal';
import { getRevisionSubmittal } from 'components/admin/projects/details/project-submittal-log/store/selectors';
import {
  setSubmittalCreatedRevisionModalShow,
  loadCreatedRevisionSubmittalRequest,
} from 'components/admin/projects/details/project-submittal-log/store/actions';

class SubmittalCreatedRevisionModal extends Component {
  static propTypes = {
    projectId: PropTypes.number.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  state = {
    intervalId: null,
  };

  componentDidMount() {
    const intervalId = setInterval(this.fetchSubmittal, 5000);
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  inProgress = () => {
    const { submittal } = this.props;
    return submittal && submittal.revision_in_progress;
  };

  fetchSubmittal = () => {
    const { projectId, submittal, dispatch } = this.props;
    if (this.inProgress()) {
      dispatch(loadCreatedRevisionSubmittalRequest(projectId, submittal.id));
    }
  };

  handleClose = () => {
    const { handleClose, dispatch } = this.props;
    dispatch(setSubmittalCreatedRevisionModalShow(false));
    handleClose();
  };

  render() {
    const { submittal } = this.props;
    const inProgress = this.inProgress();

    return (
      submittal && (
        <MessageModal
          show={true}
          onHide={this.handleClose}
          message={
            inProgress
              ? 'Creating submittal revision...'
              : 'The submittal revision has been created'
          }
          icon={inProgress ? null : MODAL_ICONS.SUCCESS}
        />
      )
    );
  }
}

export default connect((state) => {
  return {
    submittal: getRevisionSubmittal(state),
  };
})(SubmittalCreatedRevisionModal);
