import React from 'react';
import { connect } from 'react-redux';

import {
  MaterialDesignContent,
  SnackbarProvider as SnackbarProviderRoot,
} from 'notistack';
import { styled } from '@mui/material';
import { getDarkMode } from 'selectors/theme';
import colors from 'styles/colors';

const SnackbarProvider = ({ children, darkMode }) => {
  const styles = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent': {
      fontSize: 14,
      borderRadius: 0,
      fontFamily: 'Titillium Web, sans-serif',
    },
    '& button': {
      borderWidth: 0,
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      fontWeight: 600,
      '&:hover': { textDecoration: 'underline' },
    },
    '&.notistack-MuiContent-default': {
      backgroundColor:
        colors[darkMode ? 'dark-mode-medium-gray' : 'white-main'],
      color: colors[darkMode ? 'white-text' : 'light-mode-black-text'],
      '& button': { color: colors[darkMode ? 'neon' : 'primary-green'] },
    },
    '&.notistack-MuiContent-info': {
      backgroundColor:
        colors[darkMode ? 'dark-mode-grey' : 'light-mode-clear-black'],
      color: colors[darkMode ? 'white-text' : 'light-mode-black-text'],
      '& button': { color: colors[darkMode ? 'neon' : 'primary-green'] },
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: colors[darkMode ? 'chart-blue' : 'chart-red'],
      color: colors[!darkMode ? 'white-text' : 'light-mode-black-text'],
    },
    '&.notistack-MuiContent-success': {
      backgroundColor: colors[darkMode ? 'neon' : 'primary-green'],
      color: colors[!darkMode ? 'white-text' : 'light-mode-black-text'],
    },
  }));

  return (
    <SnackbarProviderRoot
      maxSnack={5}
      autoHideDuration={3000}
      hideIconVariant
      Components={{
        info: styles,
        default: styles,
        warning: styles,
        success: styles,
        error: styles,
      }}
    >
      {children}
    </SnackbarProviderRoot>
  );
};

export default connect((state) => ({ darkMode: getDarkMode(state) }))(
  SnackbarProvider
);
