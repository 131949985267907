export const fwoStatusList = [
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'approved',
    label: 'Approved',
  },
  {
    value: 'denied',
    label: 'Denied',
  },
];
