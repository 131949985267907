import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import SimpleTable from 'components/shared/table/SimpleTable';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

import {
  setShowEmailModal,
  setRecipients,
} from 'components/admin/projects/details/project-email-modal/store/actions';

import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import SchedulePermissions from 'permissions/schedule-permissions';

import {
  getProjectScheduleFilters,
  getProjectSchedules,
} from './store/selectors';
import {
  ArchiveByFilter,
  CompanyFilter,
  DateFilterHeader,
  NameFilter,
  SubmittedByFilter,
} from './SchedulesTableFilters';
import './SchedulesTable.css';
import {
  loadCategoryProjectSchedules,
  setModalEmailTypeProjectSchedule,
  setModalPdfProjectSchedule,
  deleteCategoryProjectSchedule,
  restoreCategoryProjectSchedule,
  loadPdfPublishScheduleCategory,
} from './store/actions';

const SchedulesTable = (props) => {
  const {
    dispatch,
    filters,
    handleFilterChange,
    schedules,
    project,
    currentUser,
  } = props;
  const isPublishedTab = filters.tab === 'published';
  const isArchivedTab = filters.tab === 'archived';

  const restore = (c) => {
    dispatch(restoreCategoryProjectSchedule(project.id, c.id));
  };
  const sendEmail = (c) => {
    dispatch(loadCategoryProjectSchedules(project.id, c.id)).then(
      (response) => {
        if (response.ok) {
          dispatch(setModalEmailTypeProjectSchedule('pdf'));
          dispatch(setShowEmailModal(true));
          dispatch(setRecipients([[currentUser.id, currentUser.email]]));
        }
      }
    );
  };
  const openPDF = (c) => {
    if (
      c.pdf_document &&
      c.pdf_document.document &&
      c.pdf_document.document.url
    ) {
      dispatch(setModalPdfProjectSchedule(c.pdf_document.document.url));
    } else {
      dispatch(loadPdfPublishScheduleCategory(project.id, c));
    }
  };
  const remove = (c) => {
    dispatch(deleteCategoryProjectSchedule(project.id, c.id));
  };

  const getHeaders = () => {
    const res = [];
    res.push(
      <NameFilter
        isSort={isArchivedTab}
        handleFilterChange={handleFilterChange}
      />
    );
    if (isPublishedTab)
      res.push(
        <DateFilterHeader
          label="PUBLISHED DATE"
          onChange={({ from, to, sort }) =>
            handleFilterChange({
              sortPublishedDate: sort,
              publishedDateFrom: from,
              publishedDateTo: to,
            })
          }
          values={{
            sort: filters.sortPublishedDate,
            to: filters.publishedDateTo,
            from: filters.publishedDateFrom,
          }}
          withRange
        />
      );
    if (isPublishedTab)
      res.push(
        <CompanyFilter
          label="COMPANY PUBLISHED"
          handleFilterChange={handleFilterChange}
        />
      );
    if (isPublishedTab)
      res.push(<SubmittedByFilter handleFilterChange={handleFilterChange} />);
    if (isArchivedTab)
      res.push(
        <DateFilterHeader
          label="CREATED DATE"
          withRange
          onChange={({ sort, from, to }) =>
            handleFilterChange({
              sortCreatedDate: sort,
              createdDateFrom: from,
              createdDateTo: to,
            })
          }
          values={{
            sort: filters.sortCreatedDate,
            from: filters.createdDateFrom,
            to: filters.createdDateTo,
          }}
        />
      );
    if (isArchivedTab)
      res.push(
        <CompanyFilter
          label="COMPANY"
          handleFilterChange={handleFilterChange}
        />
      );
    if (isArchivedTab)
      res.push(<SubmittedByFilter handleFilterChange={handleFilterChange} />);
    if (isArchivedTab)
      res.push(
        <DateFilterHeader
          label="ARCHIVED DATE"
          withRange
          onChange={({ sort, from, to }) =>
            handleFilterChange({
              sortArchivedDate: sort,
              archivedDateFrom: from,
              archivedDateTo: to,
            })
          }
          values={{
            sort: filters.sortArchivedDate,
            from: filters.archivedDateFrom,
            to: filters.archivedDateTo,
          }}
        />
      );
    if (isArchivedTab)
      res.push(<ArchiveByFilter handleFilterChange={handleFilterChange} />);
    if (isArchivedTab) res.push('LAST PUBLISHED');
    res.push('ACTIONS');
    return res;
  };
  const formatDate = (date, hour = false) => {
    const d = moment(date);
    return d.isValid() ? d.format(`MM/DD/YYYY${hour ? ' hh:mm A' : ''}`) : '--';
  };
  const actionButton = ({ label, action }, index) => (
    <Button
      key={`action_${index}`}
      type={BUTTON_TYPES.LINK}
      color={BUTTON_COLORS.GREEN}
      label={label}
      onClick={action}
    />
  );
  const getRow = (s, i) => {
    const canEdit = SchedulePermissions.canEdit(currentUser, project, s);
    const canView = SchedulePermissions.canView(currentUser, project, s);
    const canDelete = SchedulePermissions.canDelete(currentUser, project, s);
    const res = [];

    res.push(s.name);
    if (isPublishedTab)
      res.push(s.published_at ? formatDate(s.published_at, true) : '--');
    if (isArchivedTab) res.push(formatDate(s.created_at));

    res.push(s.created_by_company_name ? s.created_by_company_name : '--');
    res.push(s.created_by.full_name);

    if (isArchivedTab) res.push(formatDate(s.archived_at));
    if (isArchivedTab) res.push(s.archived_by ? s.archived_by.label : '--');
    if (isArchivedTab) res.push(formatDate(s.last_published_at));

    const actions = [];
    if (isPublishedTab && canView)
      actions.push({ action: () => sendEmail(s), label: 'Email' });
    if (isPublishedTab && canView)
      actions.push({ action: () => openPDF(s), label: 'PDF' });
    if (isPublishedTab && canDelete)
      actions.push({ action: () => remove(s), label: 'Delete' });

    if (isArchivedTab && canEdit)
      actions.push({ action: () => restore(s), label: 'Restore' });

    res.push(actions.map(actionButton));

    return res;
  };

  const onRowSelected = (i) => (isPublishedTab ? openPDF(schedules[i]) : null);

  const rows = schedules.map(getRow);
  const headers = getHeaders();
  return (
    <div className="schedule-table-container">
      <SimpleTable
        className="schedule-table"
        emptyMessage={`No ${
          isPublishedTab ? 'Published' : 'Archived'
        } Schedules`}
        headers={headers}
        onRowSelected={onRowSelected}
        actionCells={1}
        rows={rows}
        stickyHeader={true}
      />
    </div>
  );
};

export default connect((state) => ({
  filters: getProjectScheduleFilters(state),
  schedules: getProjectSchedules(state),
  project: getCurrentProject(state),
  currentUser: getCurrentUser(state),
}))(SchedulesTable);
