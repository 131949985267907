import {
  PAGINATION_TABLE_CURRENT_PAGE_SET,
  PAGINATION_TABLE_INIT,
  PAGINATION_TABLE_PAGE_SIZE_SET,
  PAGINATION_TABLE_TOTAL_SET,
} from 'components/shared/pagination/table/store/action-types';

const initialState = {
  // For now, only one PaginationTable is supported at the same time
  currentPage: 1,
  pageSize: 25,
  total: 0,
};

export default function paginationTableReducer(state = initialState, action) {
  switch (action.type) {
    case PAGINATION_TABLE_CURRENT_PAGE_SET:
      return {
        ...state,
        currentPage: action.payload,
      };

    case PAGINATION_TABLE_INIT:
      return initialState;

    case PAGINATION_TABLE_PAGE_SIZE_SET:
      return {
        ...state,
        pageSize: action.payload,
      };

    case PAGINATION_TABLE_TOTAL_SET:
      return {
        ...state,
        total: action.payload,
      };

    default:
      return state;
  }
}
