import { sortByAttribute } from 'services/utils/sort-util';

export const USER_ROLES = {
  OWNER: 'owner',
  ACCOUNT_ADMIN: 'account_admin',
  SPACKLE_ADMIN: 'spackle_admin',
  SUPERADMIN: 'superadmin',
  BUSINESS_DEVELOPMENT: 'business_development',
  FIELD: 'field',
  BUILDING_MANAGER: 'building_manager',
  PROJECT_MANAGER: 'project_manager',
  PROJECT_EXECUTIVE: 'project_executive',
  FIELD_PERSONNEL: 'field_personnel',
  FOREMAN: 'foreman',
  CO_FOUNDER: 'co_founder',
  PROJECT_ENGINEER: 'project_engineer',
  SENIOR_PROJECT_MANAGER: 'senior_project_manager',
  SENIOR_PROJECT_ADMIN: 'senior_project_administrator',
  SUPERINTENDENT: 'superintendent',
  ASSISTANT_SUPERINTENDENT: 'assistant_superintendent',
  SENIOR_SUPERINTENDENT: 'senior_superintendent',
  ASSISTANT_PROJECT_MANAGER: 'assistant_project_manager',
  PRESIDENT: 'president',
  VICE_PRESIDENT: 'vice_president',
  EXECUTIVE: 'executive',
  DIRECTOR: 'director',
  PRECONSTRUCTION_MANAGER: 'preconstruction_manager',
  PRECONSTRUCTION_ENGINEER: 'preconstruction_engineer',
  SENIOR_PROJECT_ENGINEER: 'senior_project_engineer',
  FIELD_ENGINEER: 'field_engineer',
  SENIOR_ESTIMATOR: 'senior_estimator',
  ESTIMATOR: 'estimator',
  PURCHASING_AGENT: 'purchasing_agent',
  SCHEDULING_MANAGER: 'scheduling_manager',
  PROJECT_SCHEDULER: 'project_scheduler',
  VDC_MANAGER: 'vdc_manager',
  VDC_ENGINEER: 'vdc_engineer',
  TECHNICIAN: 'technician',
  EQUIPMENT_OPERATOR: 'equipment_operator',
  LABORER: 'laborer',
  FIELD_OPERATIONS_MANAGER: 'field_operations_manager',
  SAFETY_MANAGER: 'safety_manager',
  SAFETY_ENGINEER: 'safety_engineer',
  QA_QC_MANAGER: 'qa_qc_manager',
  LOGISTICS_MANAGER: 'logistics_manager',
  BUSINESS_MANAGER: 'business_manager',
  ADMINISTRATOR: 'administrator',
  SENIOR_ACCOUNTANT: 'senior_accountant',
  ACCOUNTANT: 'accountant',
  TEAM_COORDINATOR: 'team_coordinator',
  COORDINATOR: 'coordinator',
  MEP_MANAGER: 'mep_manager',
  PROJECT_ADMINISTRATOR: 'project_administrator',
  PROJECT_ACCOUNTANT: 'project_accountant',
  PROJECT_COORDINATOR: 'project_coordinator',
  GENERAL_SUPERINTENDENT: 'general_superintendent',
  FIELD_OPS_MANAGER: 'field_ops_manager',
  SENIOR_CONTROLLER: 'senior_controller',
  PROJECT_DIRECTOR: 'project_director',
  PORTFOLIO_MANAGER: 'portfolio_manager',
  PROGRAM_MANAGER: 'program_manager',
  DEVELOPMENT_MANAGER: 'development_manager',
  CONSTRUCTION_MANAGER: 'construction_manager',
  PROCUREMENT_MANAGER: 'procurement_manager',
  SCHEDULING_ANALYST: 'scheduling_analyst',
  PROCUREMENT_SPECIALIST: 'procurement_specialist',
  QC_QA_MANAGER: 'qc_qa_manager',
  RISK_MANAGER: 'risk_manager',
  RISK_ANALYST: 'risk_analyst',
  PROJECT_CONTROLLER: 'project_controller',
  WO_APPROVER: 'wo_approver',
  WO_COMPLETION_APPROVER: 'wo_completion_approver',
};

export const USER_ROLE_LABELS = {
  [USER_ROLES.OWNER]: 'Owner',
  [USER_ROLES.ACCOUNT_ADMIN]: 'Account Admin',
  [USER_ROLES.SPACKLE_ADMIN]: '360Plus Admin',
  [USER_ROLES.SUPERADMIN]: 'Superadmin',
  [USER_ROLES.BUSINESS_DEVELOPMENT]: 'Business Development',
  [USER_ROLES.FIELD]: 'Field',
  [USER_ROLES.BUILDING_MANAGER]: 'Property Manager',
  [USER_ROLES.PROJECT_MANAGER]: 'Project Manager',
  [USER_ROLES.PROJECT_EXECUTIVE]: 'Project Executive',
  [USER_ROLES.FIELD_PERSONNEL]: 'Field Personnel',
  [USER_ROLES.FOREMAN]: 'Foreman',
  [USER_ROLES.CO_FOUNDER]: 'Co-Founder',
  [USER_ROLES.PROJECT_ENGINEER]: 'Project Engineer',
  [USER_ROLES.SENIOR_PROJECT_MANAGER]: 'Senior Project Manager',
  [USER_ROLES.SENIOR_PROJECT_ADMIN]: 'Senior Project Administrator',
  [USER_ROLES.SUPERINTENDENT]: 'Superintendent',
  [USER_ROLES.ASSISTANT_SUPERINTENDENT]: 'Assistant Superintendent',
  [USER_ROLES.SENIOR_SUPERINTENDENT]: 'Senior Superintendent',
  [USER_ROLES.ASSISTANT_PROJECT_MANAGER]: 'Assistant Project Manager',
  [USER_ROLES.PRESIDENT]: 'President',
  [USER_ROLES.VICE_PRESIDENT]: 'Vice President',
  [USER_ROLES.EXECUTIVE]: 'Executive',
  [USER_ROLES.DIRECTOR]: 'Director',
  [USER_ROLES.PRECONSTRUCTION_MANAGER]: 'Preconstruction Manager',
  [USER_ROLES.PRECONSTRUCTION_ENGINEER]: 'Preconstruction Engineer',
  [USER_ROLES.SENIOR_PROJECT_ENGINEER]: 'Senior Project Engineer',
  [USER_ROLES.FIELD_ENGINEER]: 'Field Engineer',
  [USER_ROLES.SENIOR_ESTIMATOR]: 'Senior Estimator',
  [USER_ROLES.ESTIMATOR]: 'Estimator',
  [USER_ROLES.PURCHASING_AGENT]: 'Purchasing Agent',
  [USER_ROLES.SCHEDULING_MANAGER]: 'Scheduling Manager',
  [USER_ROLES.PROJECT_SCHEDULER]: 'Project Scheduler',
  [USER_ROLES.VDC_MANAGER]: 'VDC Manager',
  [USER_ROLES.VDC_ENGINEER]: 'VDC Engineer',
  [USER_ROLES.TECHNICIAN]: 'Technician',
  [USER_ROLES.EQUIPMENT_OPERATOR]: 'Equipment Operator',
  [USER_ROLES.LABORER]: 'Laborer',
  [USER_ROLES.FIELD_OPERATIONS_MANAGER]: 'Field Operations Manager',
  [USER_ROLES.SAFETY_MANAGER]: 'Safety Manager',
  [USER_ROLES.SAFETY_ENGINEER]: 'Safety Engineer',
  [USER_ROLES.QA_QC_MANAGER]: 'QA/QC Manager',
  [USER_ROLES.LOGISTICS_MANAGER]: 'Logistics Manager',
  [USER_ROLES.BUSINESS_MANAGER]: 'Business Manager',
  [USER_ROLES.ADMINISTRATOR]: 'Administrator',
  [USER_ROLES.SENIOR_ACCOUNTANT]: 'Senior Accountant',
  [USER_ROLES.ACCOUNTANT]: 'Accountant',
  [USER_ROLES.TEAM_COORDINATOR]: 'Team Coordinator',
  [USER_ROLES.COORDINATOR]: 'Coordinator',
  [USER_ROLES.MEP_MANAGER]: 'MEP Manager',
  [USER_ROLES.PROJECT_ADMINISTRATOR]: 'Project Administrator',
  [USER_ROLES.PROJECT_ACCOUNTANT]: 'Project Accountant',
  [USER_ROLES.PROJECT_COORDINATOR]: 'Project Coordinator',
  [USER_ROLES.GENERAL_SUPERINTENDENT]: 'General Superintendent',
  [USER_ROLES.FIELD_OPS_MANAGER]: 'Field Operations Manager',
  [USER_ROLES.SENIOR_CONTROLLER]: 'Senior Controller',
  [USER_ROLES.PROJECT_DIRECTOR]: 'Project Director',
  [USER_ROLES.PORTFOLIO_MANAGER]: 'Portfolio Manager',
  [USER_ROLES.PROGRAM_MANAGER]: 'Program Manager',
  [USER_ROLES.DEVELOPMENT_MANAGER]: 'Development Manager',
  [USER_ROLES.CONSTRUCTION_MANAGER]: 'Construction Manager',
  [USER_ROLES.PROCUREMENT_MANAGER]: 'Procurement Manager',
  [USER_ROLES.SCHEDULING_ANALYST]: 'Scheduling Analyst',
  [USER_ROLES.PROCUREMENT_SPECIALIST]: 'Procurement Specialist',
  [USER_ROLES.QC_QA_MANAGER]: 'QC/QA Manager',
  [USER_ROLES.RISK_MANAGER]: 'Risk Manager',
  [USER_ROLES.RISK_ANALYST]: 'Risk Analyst',
  [USER_ROLES.PROJECT_CONTROLLER]: 'Project Controller',
  [USER_ROLES.WO_APPROVER]: 'May Approve Work Orders',
  [USER_ROLES.WO_COMPLETION_APPROVER]: 'May Approve Completion of Work',
};

export const INTERNAL_ROLES = new Set([
  USER_ROLES.ACCOUNT_ADMIN,
  USER_ROLES.SUPERADMIN,
]);

export const COMPANY_TITLES = [
  'Owner',
  'Acoustic Engineer',
  'Administrator',
  'Asset Manager',
  'Assistant Building Engineer',
  'Assistant Property Manager',
  'Associate',
  'Audio Video Consultant',
  'Building Engineer',
  'Building Technician',
  'Business Manager',
  'Civil Engineer',
  'Coordinator',
  'Design Coordinator',
  'Design Director',
  'Designer',
  'Director',
  'Electrical Engineer',
  'Envelope Consultant',
  'Executive',
  'Expeditor',
  'Field Operations',
  'Fire Engineering',
  'Fire Life Safety Engineer',
  'Furniture Consultant',
  'Geotech Engineer',
  'Historical Consultant',
  'Interior Designer',
  'Kitchen Consultant',
  'Land Surveyor',
  'Landscape Architect',
  'Lighting Consultant',
  'Lighting Designer',
  'MEP Engineer',
  'Mechanical Engineer',
  'Partner',
  'Peer Review',
  'Portfolio Manager',
  'Principal',
  'Project Architect',
  'Project Engineer',
  'Project Manager',
  'Property Manager',
  'Regional Manager',
  'Security Consultant',
  'Senior Associate',
  'Senior Building Engineer',
  'Senior Designer',
  'Senior Property Manager',
  'Soils Engineer',
  'Structural Engineer',
  'Studio Director',
  'Technician',
  'Technology Consultant',
  'Water Feature Consultant',
];

export const userRoleLabel = (role) => (role && USER_ROLE_LABELS[role]) || role;

export const companyRoles = (roles) =>
  roles.filter((role) => !INTERNAL_ROLES.has(role.name));

export const companyRoleOptions = (roles) =>
  sortByAttribute(companyRoles(roles), 'sort_order').map((role) => ({
    value: role.name,
    label: userRoleLabel(role.name),
  }));

export const getAccountAdminRole = (roles) =>
  roles.find((role) => role.name === USER_ROLES.ACCOUNT_ADMIN);

export const companyTitleOptions = COMPANY_TITLES.sort().map((title) => ({
  value: title,
  label: title,
}));
