import {
  PROJECT_SCHEDULE_ADD_CATEGORY,
  PROJECT_SCHEDULE_ADD_SCHEDULE,
  PROJECT_SCHEDULE_EDIT_CATEGORY,
  PROJECT_SCHEDULE_INIT,
  PROJECT_SCHEDULE_REMOVE_FROM_CATEGORY,
  PROJECT_SCHEDULE_SET,
  PROJECT_SCHEDULE_SET_FORM,
  PROJECT_SCHEDULE_REMOVE_CATEGORY,
  PROJECT_SCHEDULE_SET_CATEGORY,
  PROJECT_SCHEDULE_REMOVE_RESOLVED,
} from './action-types';

export const initialState = {
  schedules: [],
  form: {
    id: null,
    name: '',
    duplicate: null,
    copyDates: false,
    copyVisibility: false,
    copyEditing: false,
    visibility: 'private',
    visibilityUsers: [],
    editing: 'private',
    editingUsers: [],
    createdBy: null,
    createdAt: null,
  },
  category: null,
};

export default function projectScheduleDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case PROJECT_SCHEDULE_INIT:
      return initialState;
    case PROJECT_SCHEDULE_SET:
      return {
        ...state,
        schedules: action.payload,
      };
    case PROJECT_SCHEDULE_ADD_CATEGORY: {
      const schedules = [...state.schedules];
      const category = action.payload;

      const index = schedules.findIndex((c) => c.id === category.id);
      if (index >= 0) schedules[index] = category;
      else schedules.push(category);

      return { ...state, schedules };
    }
    case PROJECT_SCHEDULE_EDIT_CATEGORY: {
      const { categoryId, category } = action.payload;

      const schedules = state.schedules;
      const categoryIndex = schedules.findIndex((c) => c.id === categoryId);
      if (categoryIndex < 0) return state;

      const oldCategory = state.schedules[categoryIndex];
      schedules[categoryIndex] = { ...oldCategory, ...category };

      return { ...state, schedules };
    }
    case PROJECT_SCHEDULE_REMOVE_CATEGORY: {
      const schedules = state.schedules.filter((c) => c.id !== action.payload);
      return { ...state, schedules };
    }
    case PROJECT_SCHEDULE_ADD_SCHEDULE: {
      const { categoryId, schedule } = action.payload;

      const schedules = state.schedules;
      const categoryIndex = schedules.findIndex((c) => c.id === categoryId);
      if (categoryIndex < 0) return state;

      const category = state.schedules[categoryIndex];
      const index = category.schedules.findIndex((s) => s.id === schedule.id);

      if (index >= 0) category.schedules[index] = schedule;
      else category.schedules.push(schedule);

      return { ...state, schedules };
    }
    case PROJECT_SCHEDULE_REMOVE_FROM_CATEGORY: {
      const { categoryId, scheduleId } = action.payload;

      const schedules = state.schedules;
      const categoryIndex = schedules.findIndex((c) => c.id === categoryId);
      if (categoryIndex < 0) return state;

      const category = state.schedules[categoryIndex];
      category.schedules = category.schedules.filter(
        (s) => s.id !== scheduleId
      );

      return { ...state, schedules };
    }
    case PROJECT_SCHEDULE_SET_FORM:
      return {
        ...state,
        form: { ...state.form, ...action.payload },
      };
    case PROJECT_SCHEDULE_SET_CATEGORY:
      return { ...state, category: action.payload };
    case PROJECT_SCHEDULE_REMOVE_RESOLVED: {
      const category = {
        ...state.category,
        resolved_schedules: state.category.resolved_schedules.filter(
          (s) => s.id !== action.payload
        ),
      };
      return { ...state, category };
    }
    default:
      return state;
  }
}
