import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export default class FullScreenImageViewer extends Component {
  static propTypes = {
    show: PropTypes.bool,
    url: PropTypes.string.isRequired,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    show: false,
  };

  handleClose = (event) => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    if (this.props.show) {
      return (
        <Lightbox
          mainSrc={this.props.url}
          reactModalStyle={{
            overlay: {
              zIndex: 2000,
            },
          }}
          onCloseRequest={this.handleClose}
        />
      );
    } else {
      return <div />;
    }
  }
}
