import axios from 'axios';
import BaseApi from './base-api';

class ContractorsLienReleasesApi extends BaseApi {
  deleteLienRelease(lienReleaseId) {
    return axios
      .delete(`${this.baseUrl}/contractor_lien_releases/${lienReleaseId}`)
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export let contractorsLienReleasesApi = new ContractorsLienReleasesApi();
