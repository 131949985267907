import { createSelector } from 'reselect';

export const getReportMetrics = createSelector(
  (state) => state.projects.scenes.details.scenes.dashboard.data.reportMetrics,
  (reportMetrics) => {
    return reportMetrics;
  }
);

export const getReports = createSelector(
  (state) => state.projects.scenes.details.scenes.dashboard.data.reports,
  (reports) => {
    return reports;
  }
);

export const getPhotos = createSelector(
  (state) => state.projects.scenes.details.scenes.dashboard.data.photos,
  (photos) => {
    return photos;
  }
);

export const getSharedAlbums = createSelector(
  (state) => state.projects.scenes.details.scenes.dashboard.data.sharedAlbums,
  (sharedAlbums) => {
    return sharedAlbums;
  }
);

export const getSharedFolders = createSelector(
  (state) => state.projects.scenes.details.scenes.dashboard.data.sharedFolders,
  (sharedFolders) => {
    return sharedFolders;
  }
);

export const getLoaded = createSelector(
  (state) => state.projects.scenes.details.scenes.dashboard.ui.loaded,
  (loaded) => {
    return loaded;
  }
);
