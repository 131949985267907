import {
  WORK_ORDERS_DETAILS_QUALIFICATIONS_MODAL_SHOW,
  WORK_ORDERS_DETAILS_QUALIFICATIONS_OPTION_SELECTED,
  WORK_ORDERS_DETAILS_QUALIFICATIONS_SELECTED,
  WORK_ORDERS_DETAILS_QUALIFICATIONS_SELECTED_BY_ID_SET,
  WORK_ORDERS_DETAILS_QUALIFICATIONS_SELECTED_CLEAR,
} from '../../../../actions/admin/work-orders/action-types';

const initialState = {
  selectedQualificationsById: {}, //   { id: { id: number, option: null } }
  showNewQualificationModal: false,
};

export default function qualificationsUiReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_DETAILS_QUALIFICATIONS_MODAL_SHOW:
      return {
        ...state,
        showNewQualificationModal: action.payload,
      };

    case WORK_ORDERS_DETAILS_QUALIFICATIONS_OPTION_SELECTED:
      return {
        ...state,
        selectedQualificationsById: {
          ...state.selectedQualificationsById,
          [action.payload.qualificationId]: {
            id: action.payload.qualificationId,
            option: action.payload.option,
          },
        },
      };

    case WORK_ORDERS_DETAILS_QUALIFICATIONS_SELECTED:
      const newSelectedQualificationsById = {
        ...state.selectedQualificationsById,
      };

      if (state.selectedQualificationsById[action.payload] !== undefined) {
        delete newSelectedQualificationsById[action.payload];
      } else {
        newSelectedQualificationsById[action.payload] = {
          id: action.payload,
          option: null,
        };
      }

      return {
        ...state,
        selectedQualificationsById: newSelectedQualificationsById,
      };

    case WORK_ORDERS_DETAILS_QUALIFICATIONS_SELECTED_BY_ID_SET:
      return {
        ...state,
        selectedQualificationsById: action.payload,
      };

    case WORK_ORDERS_DETAILS_QUALIFICATIONS_SELECTED_CLEAR:
      return {
        ...state,
        selectedQualificationsById: {},
      };

    default:
      return state;
  }
}
