import { logoutRequest } from '../../actions/authentication';

class ServiceWorkRedirectUtil {
  redirectUser(user, dispatch) {
    const serviceWorkApp = process.env.REACT_APP_SERVICE_WORK;
    const path = window.location.pathname;

    if (serviceWorkApp === 'true' && !user.service_work) {
      dispatch(logoutRequest());
      return (window.location.href = `${process.env.REACT_APP_360PLUS_URL}${path}`);
    }
  }
}

export default new ServiceWorkRedirectUtil();
