import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';

class BuildingsApi extends BaseApi {
  getBuildings(clientId, page, search) {
    let filter = '';
    if (clientId !== undefined) {
      filter = `by_client=${clientId}`;
    }
    if (search !== undefined) {
      filter += `&search=${search}`;
    }

    filter += page ? `&page=${page}` : '';

    return axios
      .get(`${this.baseUrl}/buildings?${filter}`)
      .then((response) => {
        return {
          ok: true,
          buildings: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getBuilding(buildingId) {
    return axios
      .get(`${this.baseUrl}/buildings/${buildingId}`)
      .then((response) => {
        return {
          ok: true,
          building: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getWorkOrders(buildingId, page, workOrderStatus, critical) {
    let filter = '';
    if (workOrderStatus !== undefined) {
      filter = `&${workOrderStatus.status}=true`;
    } else if (critical !== undefined && critical) {
      filter = '&critical=true';
    }

    return axios
      .get(
        `${this.baseUrl}/buildings/${buildingId}/work_orders?page=${
          page !== undefined ? page : 1
        }${filter}`
      )
      .then((response) => {
        return {
          ok: true,
          workOrders: {
            content: response.data,
            total: numeral(response.headers['total']).value(),
            totalComplete: numeral(response.headers['total-complete']).value(),
            totalCritical: numeral(response.headers['total-critical']).value(),
            totalDraft: numeral(response.headers['total-draft']).value(),
            totalInProgress: numeral(
              response.headers['total-in_progress']
            ).value(),
            totalPending: numeral(response.headers['total-pending']).value(),
            totalPlm: numeral(response.headers['total-plm']).value(),
            totalWoa: numeral(response.headers['total-woa']).value(),
            totalClosed: numeral(response.headers['total-closed']).value(),
            totalDeclined: numeral(response.headers['total-declined']).value(),
            pageSize: numeral(response.headers['per-page']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getProjects(buildingId, page, projectStatus) {
    return axios
      .get(
        `${this.baseUrl}/buildings/${buildingId}/projects?page=${
          page !== undefined ? page : 1
        }${projectStatus ? `&${projectStatus}=true` : ''}`
      )
      .then((response) => {
        return {
          ok: true,
          projects: {
            content: response.data,
            total: numeral(response.headers['total']).value(),
            totalPending: numeral(response.headers['total-pending']).value(),
            totalInProgress: numeral(
              response.headers['total-in_progress']
            ).value(),
            totalComplete: numeral(response.headers['total-complete']).value(),
            pageSize: numeral(response.headers['per-page']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createBuilding(data) {
    const formData = this.createFormData(data);
    return axios
      .post(this.baseUrl + '/buildings', formData)
      .then((response) => {
        return {
          ok: true,
          building: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateBuilding(buildingId, data) {
    const formData = this.createFormData(data);
    return axios
      .put(`${this.baseUrl}/buildings/${buildingId}`, formData)
      .then((response) => {
        return {
          ok: true,
          building: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createFormData(data) {
    const formData = {
      building: {
        name: data.name || '',
        location_attributes: {
          id: data.location.id || '',
          street_address_1: data.location.streetAddress,
          street_address_2: data.location.unitNumber || '',
          city: data.location.city,
          state: data.location.state,
          zip: data.location.zipCode || '',
        },
      },
    };

    if (data.clientsId) {
      formData['building']['client_ids'] = data.clientsId;
    }

    return formData;
  }

  updateBuildingNameAndLocation(buildingId, locationId, data) {
    let putData = {
      building: {
        location_attributes: {
          id: locationId,
          street_address_1: data.streetAddress,
          street_address_2: data.unitNumber,
          city: data.city,
          state: data.state,
          zip: data.zipCode,
        },
      },
    };

    if (data.name !== '') {
      putData['building']['name'] = data.name;
    }

    return axios
      .put(this.baseUrl + '/buildings/' + buildingId, putData)
      .then((response) => {
        return {
          ok: true,
          newBuilding: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  uploadDocuments(buildingId, documents) {
    let data = new FormData();
    documents.forEach((document) => {
      data.append('building[documents_attributes][][document]', document);
    });

    return axios
      .put(this.baseUrl + '/buildings/' + buildingId, data)
      .then((response) => {
        return {
          ok: true,
          building: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  deleteDocument(buildingId, documentId) {
    return axios
      .put(this.baseUrl + '/buildings/' + buildingId, {
        building: {
          documents_attributes: [{ id: documentId, _destroy: true }],
        },
      })
      .then((response) => {
        return {
          ok: true,
          building: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export let buildingsApi = new BuildingsApi();
export default BuildingsApi = new BuildingsApi();
