import React from 'react';
import { connect } from 'react-redux';

import ProjectEmailModal from 'components/admin/projects/details/project-email-modal/ProjectEmailModal';

import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/selectors';
import { getMeetingsInstanceForm } from './store/selectors';
import { sendInstanceEmailReport } from './store/actions';

const FieldReportEmailModal = (props) => {
  const {
    dispatch,
    currentProject,
    showEmailModal,
    instanceForm,
    subjectItem,
  } = props;

  const handleSendEmail = (emailsData) => {
    dispatch(
      sendInstanceEmailReport(
        currentProject.id,
        instanceForm.id,
        emailsData.recipients,
        emailsData.subject,
        emailsData.message,
        instanceForm.documentType
      )
    );
  };

  const parseSubjectItem = () => {
    if (subjectItem === 'agenda') return 'Agenda';
    if (subjectItem === 'agendanotes') return 'Agenda & Notes';
    if (subjectItem === 'minutes') return 'Minutes';
  };
  const buildEmailModalSubject = () => {
    return `${currentProject.number} ${
      currentProject.name
    } - Meetings ${parseSubjectItem()}  ${instanceForm.formatted_number} ${
      instanceForm.title
    }`;
  };

  const buildEmailModalMessage = () => {
    let location = '';
    if (
      currentProject.project_location &&
      currentProject.project_location.location
    ) {
      const projectLocation = currentProject.project_location.location;
      location = ` at ${projectLocation.street_address_1}, ${projectLocation.city} ${projectLocation.state}`;
    }

    return `Please find the attached Meetings ${
      instanceForm.isAgenda ? 'Agenda' : 'Minutes'
    } ${instanceForm.formatted_number} for Project ${
      currentProject.name
    }${location}. If you have any questions, please reach out via email.`;
  };

  if (!showEmailModal) return null;

  return (
    <ProjectEmailModal
      subTitle={currentProject.name}
      projectId={currentProject.id}
      handleSendEmail={handleSendEmail}
      initialSubject={buildEmailModalSubject()}
      initialMessage={buildEmailModalMessage()}
      itemType="MeetingsInstance"
      itemId={instanceForm.id}
    />
  );
};

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    showEmailModal: getShowEmailModal(state),
    instanceForm: getMeetingsInstanceForm(state),
  };
})(FieldReportEmailModal);
