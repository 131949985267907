import { push } from 'react-router-redux';
import { showErrorMessage } from 'actions/messages';
import { companiesApi } from 'services/api/companies-api';
import { unionsApi } from 'services/api/union-api';
import { rolesApi } from 'services/api/roles-api';
import { formatPhoneNumber } from 'services/utils/text-util';
import User from 'domain/user';

import {
  SET_COMPANY_DROPDOWN,
  SET_CURRENT_PAGE,
  SET_COMPANIES_DATA,
  SET_TOTAL,
  SET_PAGE_SIZE,
  SET_NAME_TO_SEARCH,
  SET_COMPANY_USER_DATA,
  SET_COMPANY_PROJECTS_DATA,
  SET_COMPANY_DATA,
  SET_CURRENT_USER_COMPANY,
  COMPANIES_INVITE_USER_FORM_LOAD,
  COMPANIES_INVITE_USER_MODAL_SHOW,
  COMPANIES_INVITE_USER_ID_SET,
  COMPANIES_SHOW_CONFIRMATION_MODAL,
  COMPANIES_CLEAR_FILTERS,
  COMPANIES_SHOW_VERIFICATION_MODAL,
  SET_UNIONS,
  SET_ROLES,
  NEXT_STAGE,
  LICENCE_NUMBER_VALUE_CHANGE,
  NEW_ONBOARDING,
  COMPANY_LOGO_FILE_SET,
  COMPANY_ONBOARDING_STAGE_CHANGE,
  COMPANY_SELECT_TAB,
} from 'components/admin/companies/store/action-types';

const baseRoutePath = '/admin/companies';
const baseRoutePathTeam = '/admin/team';

export function setCompanyDropdown(project) {
  return {
    type: SET_COMPANY_DROPDOWN,
    payload: project,
  };
}

export function setCompaniesData(project) {
  return {
    type: SET_COMPANIES_DATA,
    payload: project,
  };
}

export function setCompanyUserData(userData) {
  return {
    type: SET_COMPANY_USER_DATA,
    payload: userData,
  };
}

export function setCompanyProjectsData(projectsData) {
  return {
    type: SET_COMPANY_PROJECTS_DATA,
    payload: projectsData,
  };
}

export function setCompanyData(company) {
  return {
    type: SET_COMPANY_DATA,
    payload: company,
  };
}

export function setCurrentUserCompany(company) {
  return {
    type: SET_CURRENT_USER_COMPANY,
    payload: company,
  };
}

export function loadCompanies(type, name, page) {
  return (dispatch) => {
    return companiesApi
      .getCompanies(type, name, page, false)
      .then((response) => {
        if (response.ok) {
          dispatch(setCompaniesData(response.companies));
          dispatch(setTotal(response.total));
          dispatch(setPageSize(response.pageSize));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
  };
}

export function loadCompaniesUser(companyId) {
  return (dispatch) => {
    return companiesApi.getCompanyUsers(companyId, false).then((response) => {
      if (response.ok) {
        dispatch(setCompanyUserData(response.users));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function loadCompanyProjects(companyId) {
  return (dispatch) => {
    return companiesApi
      .getCompanyProjects(companyId, false)
      .then((response) => {
        if (response.ok) {
          dispatch(setCompanyProjectsData(response.projects));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadCompanyById(companyId) {
  return (dispatch) => {
    return companiesApi.showCompanyById(companyId, false).then((response) => {
      if (response.ok) {
        dispatch(setCompanyData(response.company));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadCurrentUserCompany(id) {
  return (dispatch) => {
    return companiesApi.showCompanyById(id, false).then((response) => {
      if (response.ok) {
        dispatch(setCurrentUserCompany(response.company));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function updateCurrentUserCompany(id, values) {
  return (dispatch) => {
    return companiesApi.updateCompany(id, values).then((response) => {
      if (response.ok) {
        dispatch(setCurrentUserCompany(response.company));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function updateCompany(id, values) {
  return (dispatch) => {
    return companiesApi.updateCompany(id, values).then((response) => {
      if (response.ok) {
        dispatch(setCompanyData(response.company));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function createCompany(values) {
  return (dispatch) => {
    return companiesApi.createCompany(values).then((response) => {
      if (response.ok) {
        dispatch(setCompanyData(response.company));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function inviteExistentCompany(companyId) {
  return (dispatch) => {
    return companiesApi.inviteExistent(companyId).then((response) => {
      if (response.ok) {
        dispatch(setCompanyData(response.company));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

const makeFindSimilarValues = ({
  name,
  email,
  phoneNumber,
  officePhoneNumber,
  officeFaxNumber,
}) => ({
  mode: 'list',
  name: name,
  email: email,
  phone_number: phoneNumber,
  office_phone_number: officePhoneNumber,
  office_fax_number: officeFaxNumber,
});

export function findSimilarCompany(values) {
  return (dispatch) => {
    return companiesApi
      .findSimilar(makeFindSimilarValues(values))
      .then((response) => {
        if (!response.ok) dispatch(showErrorMessage(response.errors));

        return response;
      });
  };
}

export function changePage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setTotal(total) {
  return {
    type: SET_TOTAL,
    payload: total,
  };
}

export function setNameToSearch(name) {
  return {
    type: SET_NAME_TO_SEARCH,
    payload: name,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}

export function loadUserForm(user) {
  return (dispatch) => {
    const form = {
      id: user.id,
      accountableId: user.accountable_id,
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      phoneNumber: user.phone_number
        ? formatPhoneNumber(user.phone_number)
        : '',
      phoneExt: user.phone_ext || '',
      assignedRole: User.companyRoleName(user),
      accountAdmin: User.isAccountAdmin(user),
      title: user.title || '',
    };

    dispatch({
      type: COMPANIES_INVITE_USER_FORM_LOAD,
      payload: form,
    });
  };
}

export function clearUserData() {
  return {
    type: COMPANIES_INVITE_USER_FORM_LOAD,
  };
}

export function clearFilters() {
  return {
    type: COMPANIES_CLEAR_FILTERS,
  };
}

export function setShowEditUserModal(open) {
  return {
    type: COMPANIES_INVITE_USER_MODAL_SHOW,
    payload: open,
  };
}

export function setShowConfirmationModal(open) {
  return {
    type: COMPANIES_SHOW_CONFIRMATION_MODAL,
    payload: open,
  };
}

export function setCurrentUserId(userId) {
  return {
    type: COMPANIES_INVITE_USER_ID_SET,
    payload: userId,
  };
}

export function editUser(values, companyId) {
  return (dispatch) => {
    return companiesApi.editUser(values).then((response) => {
      if (!response.ok) {
        dispatch(showErrorMessage(response.errors));
      } else {
        dispatch(setShowEditUserModal(false));
        dispatch(loadCompaniesUser(companyId));
      }

      return response;
    });
  };
}

export function inviteUser(values, companyId) {
  return (dispatch) => {
    return companiesApi.inviteUser(companyId, values).then((response) => {
      if (!response.ok) {
        dispatch(showErrorMessage(response.errors));
      } else {
        dispatch(setShowEditUserModal(false));
        dispatch(loadCompaniesUser(companyId));
      }

      return response;
    });
  };
}

export function deleteUser(values, companyId) {
  return (dispatch) => {
    return companiesApi.deleteUser(values).then((response) => {
      if (!response.ok) {
        dispatch(showErrorMessage(response.errors));
      } else {
        dispatch(setShowEditUserModal(false));
        dispatch(loadCompaniesUser(companyId));
      }

      return response;
    });
  };
}

export function goToCompanies() {
  return (dispatch) => {
    dispatch(push(baseRoutePath));
  };
}

export function goToNewCompany() {
  return (dispatch) => {
    dispatch(push(`${baseRoutePath}/new`));
  };
}

export function goToCompanyDetails(id) {
  const team = window.location.pathname.includes(baseRoutePathTeam);
  return (dispatch) => {
    if (team) {
      dispatch(push(baseRoutePathTeam));
    } else {
      dispatch(push(`${baseRoutePath}/${id}`));
    }
  };
}

export function goToEditCompany(id) {
  const team = window.location.pathname.includes(baseRoutePathTeam);
  return (dispatch) => {
    if (team) {
      dispatch(push(`${baseRoutePathTeam}/edit`));
    } else {
      dispatch(push(`${baseRoutePath}/${id}/edit`));
    }
  };
}

export function setShowVerificationModal(show, user = null, action = null) {
  return {
    type: COMPANIES_SHOW_VERIFICATION_MODAL,
    payload: show ? { user, action } : null,
  };
}

function verificationAction(user, action) {
  switch (action) {
    case 'reject':
      return companiesApi.rejectUser(user);
    default:
      return companiesApi.verifyUser(user);
  }
}

export function verifyUser(user, action) {
  return (dispatch) => {
    verificationAction(user, action).then((response) => {
      dispatch(setShowVerificationModal(false));
      if (response.ok) {
        dispatch(loadCompaniesUser(user.accountable_id));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadUnions() {
  return (dispatch) => {
    return unionsApi.getUnions().then((response) => {
      if (response.ok) {
        dispatch(setUnions(response.unions));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function setUnions(unions) {
  return {
    type: SET_UNIONS,
    payload: unions,
  };
}

export function loadRoles() {
  return (dispatch) => {
    return rolesApi.getRoles('Company').then((response) => {
      if (response.ok) {
        dispatch(setRoles(response.permissions));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function setRoles(roles) {
  return {
    type: SET_ROLES,
    payload: roles,
  };
}

export function showOnboarding() {
  return (dispatch) => {
    dispatch(push(`${baseRoutePath}/onboarding`));
  };
}

export function setOnboarding(companyData) {
  const licenceNumber = companyData.licenceNumber || '';
  const companyLogo =
    companyData.logo && companyData.logo.image && companyData.logo.image.url
      ? { url: companyData.logo.image.url }
      : { url: '', image: '' };
  return {
    type: NEW_ONBOARDING,
    payload: {
      companyData,
      licenceNumber,
      companyLogo,
    },
  };
}

export function newOnboarding(companyId) {
  return (dispatch) => {
    return companiesApi.showCompanyById(companyId, false).then((response) => {
      if (response.ok) {
        dispatch(setOnboarding(response.company));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function setStage(value) {
  return {
    payload: value,
    type: COMPANY_ONBOARDING_STAGE_CHANGE,
  };
}

export function nextStage() {
  return {
    type: NEXT_STAGE,
  };
}

export function setLicenceNumberValue(value) {
  return {
    type: LICENCE_NUMBER_VALUE_CHANGE,
    payload: value,
  };
}

export function setCompanyLogo(file) {
  return {
    type: COMPANY_LOGO_FILE_SET,
    payload: file,
  };
}

export function showDashboard() {
  return (dispatch) => {
    dispatch(push('/admin/dashboard'));
  };
}

export function submitOnboarding(onboardingData) {
  return (dispatch) => {
    return companiesApi
      .updateCompanyOnboardingData(onboardingData)
      .then((response) => {
        if (response.ok) {
          dispatch(showDashboard()); // load dashboard again
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
  };
}

export function setCompanySelectedTab(value) {
  return {
    type: COMPANY_SELECT_TAB,
    payload: value,
  };
}
