import { Grid, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';

const ProjectPhotosTitle = ({ title, count }) => {
  if (!count || !title) return null;

  return (
    <Grid container spacing={1} alignItems={'baseline'}>
      <Grid item>
        <Typography variant="h6" color="text.primary">
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="text.disabled">{count}</Typography>
      </Grid>
    </Grid>
  );
};

export default connect()(ProjectPhotosTitle);
