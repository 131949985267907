import { createSelector } from 'reselect';

export const getCurrentUserId = createSelector(
  (state) => state.users.ui.currentUserId,
  (currentUserId) => {
    return currentUserId;
  }
);

export const getForm = createSelector(
  (state) => state.users.ui.form,
  (form) => {
    return form;
  }
);

export const getRoles = createSelector(
  (state) => state.users.data.roles,
  (roles) => {
    return roles;
  }
);

export const getUsers = createSelector(
  (state) => state.users.data.users,
  (users) => {
    return users;
  }
);

export const getShowInviteUserModal = createSelector(
  (state) => state.users.ui.showInviteUserModal,
  (showInviteUserModal) => {
    return showInviteUserModal;
  }
);

export const getInvalidFields = createSelector(
  (state) => state.users.ui.invalidFields,
  (invalidFields) => {
    return invalidFields;
  }
);

export const getUserModalState = createSelector(
  (state) => state.users.ui.userModalState,
  (userModalState) => {
    return userModalState;
  }
);

export const getValidationMessage = createSelector(
  (state) => state.users.ui.validationMessage,
  (validationMessage) => {
    return validationMessage;
  }
);
