import React from 'react';
import { connect } from 'react-redux';

import FormModal from 'components/shared/modal/FormModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import UserPermissionsForm from 'components/shared/user-permissions/UserPermissionsForm';

import { getCurrentProject } from 'components/admin/projects/details/store/selectors';

import { getSecurityCamerasPermissionsForm } from './store/selectors';
import {
  setSecurityCameraPermissionsForm,
  setSecurityCameraPermissionsFormValue,
  saveSecurityCameraPermissions,
} from './store/actions';
import './PermissionsModal.css';

const PermissionsModal = ({ dispatch, currentProject, permissionsForm }) => {
  const handleClose = () => dispatch(setSecurityCameraPermissionsForm());

  const handleSubmit = () =>
    dispatch(saveSecurityCameraPermissions(currentProject.id, permissionsForm));

  const handleFormChange = (name, value) =>
    dispatch(setSecurityCameraPermissionsFormValue(name, value));

  return (
    <FormModal
      className="security-cameras-permissions-modal"
      show
      onClose={handleClose}
      subTitle="Security Cameras Permissions"
    >
      <div className="users-list">
        <UserPermissionsForm
          users={permissionsForm.users}
          visibility={permissionsForm.permission}
          onVisibilityChange={(visibility) =>
            handleFormChange('permission', visibility)
          }
          onUsersChange={(users) => handleFormChange('users', users)}
          canChangePermissions
        />
      </div>
      <div className="buttons-container">
        <Button
          type={BUTTON_TYPES.RECTANGLE}
          color={BUTTON_COLORS.WHITE}
          className="button-cancel"
          label="CANCEL"
          onClick={handleClose}
        />
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          label="SAVE"
          onClick={handleSubmit}
        />
      </div>
    </FormModal>
  );
};

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    permissionsForm: getSecurityCamerasPermissionsForm(state),
  };
})(PermissionsModal);
