import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Dashboard.css';
import DashboardNav from 'components/admin/dashboard/DashboardNav';
import DashboardRecentActivity from 'components/admin/dashboard/DashboardRecentActivity';
import ChatActivity from 'components/admin/chats/ChatActivity';
import { getCurrentUser } from 'selectors/authentication';
import Registration from 'components/admin/registration/Registration';
import User from 'domain/user';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.navElement = React.createRef();
    this.userIsEnabled = User.isEnabled(props.currentUser);
  }

  componentDidUpdate() {
    if (!this.userIsEnabled) {
      this.navElement.current.getWrappedInstance().handleNavClick(0);
    }
  }

  render() {
    return this.userIsEnabled ? (
      <div className="dashboard">
        <div className="dashboard-content">
          <DashboardNav ref={this.navElement} />
          <div className="dashboard-activity">
            <DashboardRecentActivity />
            <ChatActivity />
          </div>
        </div>
      </div>
    ) : (
      <Registration />
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
  };
})(Dashboard);
