import axios from 'axios';
import BaseApi from './base-api';

class ProjectResourceMailerApi extends BaseApi {
  postResourceEmails(resourceId, resourceType, emailData) {
    const data = this.resourceEmailsParams(resourceId, resourceType, emailData);
    return axios
      .post(`${this.baseUrl}/project_resource_mailer`, data)
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  resourceEmailsParams(resourceId, resourceType, emailData) {
    return {
      resource_id: resourceId,
      resource_type: resourceType,
      subject: emailData.subject,
      message:
        emailData.message !== emailData.initialMessage
          ? emailData.message
          : 'default',
      send_to: emailData.recipients,
    };
  }

  getEmailHistory(id, type) {
    return axios
      .get(
        `${this.baseUrl}/project_resource_mailer?resource_id=${id}&resource_type=${type}`
      )
      .then((response) => {
        return {
          ok: true,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const projectResourceMailerApi = new ProjectResourceMailerApi();
