import React, { useMemo } from 'react';

import moment from 'moment';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getDarkMode } from 'selectors/theme';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getFieldReportFilters, getFieldReports } from './store/selectors';

import { parsePersonName } from 'services/utils/rfa-util';

import SimpleTable from 'components/shared/table/SimpleTable';
import FilterHeader from 'components/shared/table/FilterHeader';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import FieldReportEmailModal from './FieldReportEmailModal';
import FieldReportPdfModal from './FieldReportPdfModal';
import FieldReportDeleteConfirmationModal from './FieldReportDeleteConfirmationModal';
import ProjectReportErrorModal from '../ProjectReportErrorModal';

import useFieldReportTable from './FieldReportTable.hook';
import './FieldReportTable.css';
import { withRouter } from 'react-router-dom';

const FieldReportTable = (props) => {
  const { fieldReports, handleFilterChange, filters } = props;
  const {
    showErrorModal,
    setShowErrorModal,
    handleDelete,
    handleOpenPdf,
    handleRowSelected,
    handleSendEmail,
    handleEdit,
    canEdit,
    canSendEmail,
    canDelete,
    canOpenPdf,
  } = useFieldReportTable(props);

  const headers = [
    'REPORT NO',
    <FilterHeader
      label="REPORT DATE"
      options={[
        { id: 'all', label: 'All' },
        { id: 'desc', label: 'Newest' },
        { id: 'asc', label: 'Oldest' },
      ]}
      selected={filters.date}
      onFilter={(date) => handleFilterChange({ date })}
    />,
    <FilterHeader
      label="SUBMITTED BY"
      options={[
        { id: 'all', label: 'All' },
        { id: 'asc', label: 'A-Z' },
        { id: 'desc', label: 'Z-A' },
      ]}
      selected={filters.publishedBy}
      onFilter={(publishedBy) => handleFilterChange({ publishedBy })}
    />,
    'TITLE',
    'STATUS',
    'ACTIONS',
  ];

  const parseDate = (date) => {
    const d = moment(date);
    return d.isValid() ? d.format('MM/DD/YYYY') : date;
  };

  const parseReportStatus = (report) => {
    if (report.status === 'deleted') {
      return `${report.formatted_status} ${parseDate(
        report.deleted_at
      )} by ${parsePersonName(report.deleted_by)}`;
    } else if (report.status === 'published') {
      return `${report.formatted_status} ${parseDate(report.published_at)}`;
    }
    return report.formatted_status;
  };

  const renderReportActions = (report) => {
    const actions = [];

    if (canSendEmail(report)) {
      actions.push({
        label: 'Email',
        action: () => handleSendEmail(report),
      });
    }

    if (canOpenPdf(report)) {
      actions.push({
        label: 'PDF',
        action: () => handleOpenPdf(report),
      });
    }

    if (canEdit(report)) {
      actions.push({
        label: 'Edit',
        action: () => handleEdit(report),
      });
    }

    if (canDelete(report)) {
      actions.push({
        label: 'Delete',
        action: () => handleDelete(report),
      });
    }

    return (
      <div className="actions">
        {actions.map((a, index) => (
          <Button
            key={`action_${report.id}_${index}`}
            type={BUTTON_TYPES.LINK}
            color={BUTTON_COLORS.GREEN}
            label={a.label}
            onClick={a.action}
          />
        ))}
      </div>
    );
  };

  const mapReports = (reports) => {
    return reports.map((r) => [
      `${r.formatted_number}`,
      parseDate(r.date),
      r.published_by
        ? parsePersonName(r.published_by, true)
        : parsePersonName(r.created_by, true),
      r.title,
      parseReportStatus(r),
      renderReportActions(r),
    ]);
  };

  const reports = useMemo(() => mapReports(fieldReports), [fieldReports]);

  return (
    <React.Fragment>
      <SimpleTable
        className={classnames({
          'simple-table': true,
          'projects-table': true,
          'field-report-table': true,
        })}
        headers={headers}
        rows={reports}
        emptyMessage="No Field Reports"
        actionCells={1}
        stickyHeader={true}
        onRowSelected={handleRowSelected}
      />

      <FieldReportEmailModal />
      <FieldReportPdfModal />
      <FieldReportDeleteConfirmationModal />
      <ProjectReportErrorModal
        show={showErrorModal}
        error="Your report is being prepared, please try again in a few minutes."
        title="PDF"
        onClose={() => setShowErrorModal(false)}
      />
    </React.Fragment>
  );
};

FieldReportTable.propTypes = {
  project: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape(),
  darkMode: PropTypes.bool.isRequired,
  fieldReports: PropTypes.arrayOf(PropTypes.shape({})),
  handleFilterChange: PropTypes.func.isRequired,
};

FieldReportTable.defaultProps = {
  fieldReports: [],
  currentUser: {},
};

export default connect(
  (state) => ({
    darkMode: getDarkMode(state),
    project: getCurrentProject(state),
    currentUser: getCurrentUser(state),
    fieldReports: getFieldReports(state),
    filters: getFieldReportFilters(state),
  }),
  null
)(withRouter(FieldReportTable));
