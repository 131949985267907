import User from 'domain/user';
import ProjectPhoto from 'domain/project-photo';

class ProjectPhotoPermissions {
  hasItemPermission = (user, project, item) => {
    if (!item) return false;

    return (
      User.isAdminOrProjectSubscriberAccountAdmin(user, project) ||
      ProjectPhoto.isCreator(user, item) ||
      ProjectPhoto.hasSharedPermissions(user, item) ||
      ProjectPhoto.hasPublicPermissions(user, item)
    );
  };

  canErase = (user, project) =>
    User.isAdminOrProjectSubscriberAccountAdmin(user, project);

  canAddItem = (user, project, item) => {
    if (!item) return true;

    return (
      item.status === 'active' && this.hasItemPermission(user, project, item)
    );
  };

  canEdit = (user, project, form) =>
    User.isAdminOrProjectSubscriberAccountAdmin(user, project) ||
    ProjectPhoto.isCreator(user, form);

  canChangePermissions = (user, project, form) =>
    this.canEdit(user, project, form);
}

export default new ProjectPhotoPermissions();
