import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ProjectCloseout.css';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCloseout } from 'components/admin/projects/details/project-closeout/store/selectors';
import {
  loadCloseoutCatsRequest,
  loadCloseoutRequest,
  loadCloseoutContribsRequest,
  loadCloseoutManualsRequest,
  loadCloseoutCompanyTypesRequest,
  loadCloseoutCatsCompanyTypes,
} from 'components/admin/projects/details/project-closeout/store/actions';
import NavigationBar from 'components/shared/navigation/NavigationBar';
import CloseoutTable from 'components/admin/projects/details/project-closeout/CloseoutTable';
import CloseoutForm from 'components/admin/projects/details/project-closeout/CloseoutForm';
import CloseoutContribForms from 'components/admin/projects/details/project-closeout/CloseoutContribForms';
import CloseoutManualLog from 'components/admin/projects/details/project-closeout/CloseoutManualLog';
import { getDarkMode } from 'selectors/theme';
import CloseoutRequirements from './CloseoutRequirements';
import { getCurrentUser } from 'selectors/authentication';
import CloseoutPermissions from 'permissions/closeout-permissions';

class ProjectCloseout extends Component {
  constructor(props) {
    super(props);

    this.state = { tab: 1 };
  }

  loadTabData(tab) {
    const { currentProject, dispatch } = this.props;
    if (currentProject) {
      switch (tab) {
        case 1:
          dispatch(loadCloseoutCatsRequest(currentProject.id));
          dispatch(loadCloseoutRequest(currentProject.id));
          dispatch(loadCloseoutContribsRequest(currentProject.id));
          dispatch(loadCloseoutCatsCompanyTypes(currentProject.id));
          break;

        case 2:
          dispatch(loadCloseoutManualsRequest(currentProject.id));
          break;

        case 3:
          dispatch(loadCloseoutCompanyTypesRequest());
          break;

        default:
      }
    }
  }

  componentDidMount() {
    const { tab } = this.state;
    this.loadTabData(tab);
  }

  handleTabSelection = (tab) => {
    this.setState({ tab: tab });
    this.loadTabData(tab);
  };

  renderHeaderButtons() {}

  render() {
    const {
      closeout,
      renderHeaders,
      darkMode,
      currentUser,
      currentProject,
    } = this.props;
    const { tab } = this.state;

    const canSeeRequirements = CloseoutPermissions.canViewRequirements(
      currentUser,
      currentProject
    );

    return (
      closeout && (
        <React.Fragment>
          <div
            className={`content-container project-closeout ${
              darkMode ? 'dark-mode' : ''
            }`}
          >
            {renderHeaders(this.renderHeaderButtons())}
            <div className="project-closeout-header">
              <NavigationBar
                items={[
                  {
                    id: 1,
                    title: 'Closeout',
                  },
                  {
                    id: 2,
                    title: 'Closeout Manual',
                  },
                  {
                    id: 3,
                    title: 'Requirements',
                    show: canSeeRequirements,
                  },
                ]}
                selectedValue={tab}
                onSelected={this.handleTabSelection}
              />
            </div>

            {tab === 1 && (
              <div className="project-closeout-body">
                <CloseoutForm readOnly={true} currentTab={tab} />
                <CloseoutTable />
              </div>
            )}

            {tab === 2 && <CloseoutManualLog />}
            {tab === 3 && (
              <div className="project-closeout-requirement-body">
                <CloseoutRequirements currentTab={tab} />
              </div>
            )}
          </div>
          {tab === 1 && <CloseoutContribForms />}
        </React.Fragment>
      )
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    closeout: getCloseout(state),
    currentUser: getCurrentUser(state),
    darkMode: getDarkMode(state),
  };
})(ProjectCloseout);
