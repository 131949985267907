import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadPresetTasksRequest } from 'components/admin/projects/details/project-daily-report-log/store/actions';
import {
  getPresetTasks,
  getCurrentFieldWorkOrder,
} from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import Input from 'components/shared/input/Input';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import Selector from 'components/shared/selector/Selector';
import DatePicker from 'components/shared/date-picker/DatePicker';
import { fwoStatusList } from 'services/utils/field-work-order-util';
import {
  sameOrBeforeDateValidation,
  sameOrAfterDateValidation,
} from 'services/utils/date-util';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import './FieldWorkOrderForm.css';

class FieldWorkOrderForm extends Component {
  loadPresetTasks = () => {
    const { dispatch, currentFieldWorkOrder } = this.props;
    dispatch(loadPresetTasksRequest(currentFieldWorkOrder.csi_code.id));
  };

  invalidTasks = (field) => {
    const { fieldWorkOrderForm, showErrorMessage, formErrors } = this.props;
    return (
      ((!fieldWorkOrderForm.fwoPresetTasks ||
        !fieldWorkOrderForm.fwoPresetTasks.length) &&
        !fieldWorkOrderForm.fwoCustomTask &&
        showErrorMessage) ||
      (formErrors && formErrors[field])
    );
  };

  missingField(value, field) {
    const { showErrorMessage, formErrors } = this.props;
    return (!value && showErrorMessage) || (formErrors && formErrors[field]);
  }

  componentDidMount() {
    if (!this.props.fwoForSubForm) {
      this.loadPresetTasks();
    }
  }

  render() {
    const {
      presetTasks,
      fieldWorkOrderForm,
      currentFieldWorkOrder,
      fwoForSubForm,
      handleAttributeChange,
    } = this.props;
    let presetTaskList = [];
    if (presetTasks && presetTasks.length > 0) {
      presetTaskList = presetTasks.map((task) => {
        return { id: task.id, label: task.label };
      });
    }
    let statusList = [...fwoStatusList];
    const currentStatus = currentFieldWorkOrder
      ? currentFieldWorkOrder.status
      : 'pending';
    if (currentStatus !== 'pending') {
      statusList = statusList.filter((s) => s.value !== 'pending');
    }
    return (
      <React.Fragment>
        {fieldWorkOrderForm && (
          <div className="field-work-order-form">
            <div className="form-block">
              <FormControlBlock
                label={fieldWorkOrderForm.fwoNumber && 'EWO #'}
                control={
                  <Input
                    placeholder="EWO #"
                    onChange={handleAttributeChange.bind(null, 'fwoNumber')}
                    value={fieldWorkOrderForm.fwoNumber}
                    valid={
                      !this.missingField(
                        fieldWorkOrderForm.fwoNumber,
                        'fwoNumber'
                      )
                    }
                  />
                }
              />
            </div>
            {presetTaskList.length > 0 && (
              <div className="form-block">
                <FormControlBlock
                  label={fieldWorkOrderForm.fwoPresetTasks && 'Preset Tasks'}
                  control={
                    <MultipleDropdown
                      dropdownClassName="alternate-dropdown-control"
                      options={presetTaskList}
                      currentValues={fieldWorkOrderForm.fwoPresetTasks || []}
                      placeholder="Preset Tasks"
                      onChange={(values) =>
                        handleAttributeChange('fwoPresetTasks', values)
                      }
                      valid={!this.invalidTasks('fwoPresetTasks')}
                    />
                  }
                />
              </div>
            )}
            <div className="form-block">
              <FormControlBlock
                label={fieldWorkOrderForm.fwoCustomTask && 'Custom Task'}
                control={
                  <Input
                    placeholder="Custom Task"
                    onChange={handleAttributeChange.bind(null, 'fwoCustomTask')}
                    value={fieldWorkOrderForm.fwoCustomTask}
                    valid={!this.invalidTasks('fwoPresetTasks')}
                  />
                }
              />
            </div>
            <div className="form-block">
              <FormControlBlock
                label={fieldWorkOrderForm.fwoWorkPerformed && 'Work Required'}
                control={
                  <TextareaAutosizeWrapper
                    placeholder="Work Required"
                    initialValue={fieldWorkOrderForm.fwoWorkPerformed || ''}
                    valid={
                      !this.missingField(
                        fieldWorkOrderForm.fwoWorkPerformed,
                        'fwoWorkPerformed'
                      )
                    }
                    onChange={(value) =>
                      handleAttributeChange('fwoWorkPerformed', value)
                    }
                  />
                }
              />
            </div>
            <div className="form-block">
              <FormControlBlock
                label={fieldWorkOrderForm.fwoComments && 'Comments'}
                control={
                  <TextareaAutosizeWrapper
                    placeholder="Comments"
                    initialValue={fieldWorkOrderForm.fwoComments || ''}
                    onChange={(value) =>
                      handleAttributeChange('fwoComments', value)
                    }
                  />
                }
              />
            </div>
            <div className="form-block">
              <FormControlBlock
                label={fieldWorkOrderForm.fwoStartDate && 'Start date'}
                control={
                  <DatePicker
                    placeholder="Start date"
                    clearable={true}
                    isValidDate={(date) =>
                      sameOrBeforeDateValidation(
                        date,
                        fieldWorkOrderForm.fwoCompletionDate
                      )
                    }
                    onChange={(value) =>
                      handleAttributeChange('fwoStartDate', value)
                    }
                    value={fieldWorkOrderForm.fwoStartDate}
                  />
                }
              />
              <FormControlBlock
                label={
                  fieldWorkOrderForm.fwoCompletionDate && 'Completion date'
                }
                control={
                  <DatePicker
                    placeholder="Completion Date"
                    clearable={true}
                    isValidDate={(date) =>
                      sameOrAfterDateValidation(
                        date,
                        fieldWorkOrderForm.fwoStartDate
                      )
                    }
                    onChange={(value) =>
                      handleAttributeChange('fwoCompletionDate', value)
                    }
                    value={fieldWorkOrderForm.fwoCompletionDate}
                  />
                }
              />
            </div>
            {!fwoForSubForm && (
              <React.Fragment>
                <div className="form-block">
                  <LabeledCheckBox
                    label="Cost Impact"
                    position="left"
                    onChange={handleAttributeChange.bind(null, 'fwoCostImpact')}
                    checked={fieldWorkOrderForm.fwoCostImpact}
                  />
                  {fieldWorkOrderForm.fwoCostImpact && (
                    <FormControlBlock
                      label={fieldWorkOrderForm.fwoCost && 'Cost'}
                      control={
                        <Input
                          placeholder="Cost"
                          onChange={handleAttributeChange.bind(null, 'fwoCost')}
                          value={fieldWorkOrderForm.fwoCost}
                          type="number"
                        />
                      }
                    />
                  )}
                </div>
                <div className="form-block">
                  <LabeledCheckBox
                    label="Delay"
                    position="left"
                    onChange={handleAttributeChange.bind(null, 'fwoDelay')}
                    checked={fieldWorkOrderForm.fwoDelay}
                  />
                  {fieldWorkOrderForm.fwoDelay && (
                    <FormControlBlock
                      label={
                        fieldWorkOrderForm.fwoDelayReason && 'Delay Reason'
                      }
                      control={
                        <TextareaAutosizeWrapper
                          placeholder="Delay Reason"
                          initialValue={fieldWorkOrderForm.fwoDelayReason || ''}
                          valid={
                            !this.missingField(
                              fieldWorkOrderForm.fwoDelayReason,
                              'fwoDelayReason'
                            )
                          }
                          onChange={(value) =>
                            handleAttributeChange('fwoDelayReason', value)
                          }
                        />
                      }
                    />
                  )}
                </div>
              </React.Fragment>
            )}
            <div className="form-block">
              <FormControlBlock
                label={fieldWorkOrderForm.fwoLabor && 'Labor'}
                control={
                  <TextareaAutosizeWrapper
                    placeholder="Labor"
                    initialValue={fieldWorkOrderForm.fwoLabor || ''}
                    valid={
                      !this.missingField(
                        fieldWorkOrderForm.fwoLabor,
                        'fwoLabor'
                      )
                    }
                    onChange={(value) =>
                      handleAttributeChange('fwoLabor', value)
                    }
                  />
                }
              />
            </div>
            <div className="form-block">
              <FormControlBlock
                label={fieldWorkOrderForm.fwoMaterials && 'Materials'}
                control={
                  <TextareaAutosizeWrapper
                    placeholder="Materials"
                    initialValue={fieldWorkOrderForm.fwoMaterials || ''}
                    valid={
                      !this.missingField(
                        fieldWorkOrderForm.fwoMaterials,
                        'fwoMaterials'
                      )
                    }
                    onChange={(value) =>
                      handleAttributeChange('fwoMaterials', value)
                    }
                  />
                }
              />
            </div>
            {!fwoForSubForm && (
              <div className="form-block work-order-status-block">
                <FormControlBlock
                  label={fieldWorkOrderForm.fwoStatus && 'Status'}
                  control={
                    <Selector
                      options={statusList}
                      placeholder="Status"
                      value={fieldWorkOrderForm.fwoStatus}
                      onChange={(value) =>
                        handleAttributeChange('fwoStatus', value)
                      }
                      valid={
                        !this.missingField(
                          fieldWorkOrderForm.fwoStatus,
                          'fwoStatus'
                        )
                      }
                    />
                  }
                />
                {fieldWorkOrderForm.fwoStatus &&
                  fieldWorkOrderForm.fwoStatus.value === 'denied' && (
                    <FormControlBlock
                      label={fieldWorkOrderForm.fwoStatus && 'Denied Reason'}
                      control={
                        <TextareaAutosizeWrapper
                          placeholder="Denied Reason"
                          initialValue={
                            fieldWorkOrderForm.fwoDeniedReason || ''
                          }
                          valid={
                            !this.missingField(
                              fieldWorkOrderForm.fwoDeniedReason,
                              'fwoDeniedReason'
                            )
                          }
                          onChange={(value) =>
                            handleAttributeChange('fwoDeniedReason', value)
                          }
                        />
                      }
                    />
                  )}
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    presetTasks: getPresetTasks(state),
    currentFieldWorkOrder: getCurrentFieldWorkOrder(state),
  };
})(FieldWorkOrderForm);
