import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Dropdown.css';
import { Async, AsyncCreatable } from 'react-select';
import 'react-select/dist/react-select.css';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import vector from './vector.svg';
import vectorDark from './vector-dark.svg';

/**
 * Searchable drop down. Expects options from a remote location. Delays the options request if the user types fast (delay configurable)
 */
class SearchableDropDown extends Component {
  static propTypes = {
    defaultArrow: PropTypes.bool,
    arrowRenderer: PropTypes.func,
    autoload: PropTypes.bool,
    cache: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    className: PropTypes.string,
    clearable: PropTypes.bool,
    creatable: PropTypes.bool,
    keyDelay: PropTypes.number,
    loadOptions: PropTypes.func.isRequired, // Return promise (Object with "ok", "options" or "errors", function receives the input from the drop down, should return array of [value, label]
    placeholder: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    defaultArrow: false,
    arrowRenderer: null,
    autoload: true,
    clearable: false,
    creatable: false,
    keyDelay: 500,
    onChange: () => {},
  };

  timeOutId = 0;

  createInputTimeOut(input, callback) {
    return setTimeout(() => {
      this.timeOutId = 0;
      this.props.loadOptions(input).then((response) => {
        if (response.ok) {
          callback(null, {
            complete: false,
            options: response.options,
          });
        } else {
          callback(response.errors, {
            complete: false,
            options: [],
          });
        }
      });
    }, this.props.keyDelay);
  }

  getOptions(input, callback) {
    if (this.timeOutId > 0) {
      clearTimeout(this.timeOutId);
      this.timeOutId = this.createInputTimeOut(input, callback);
    } else {
      this.timeOutId = this.createInputTimeOut(input, callback);
    }
  }

  handleChange = (option) => {
    this.props.onChange(option);
  };

  handleCreatableKeyDown = (param) => {
    // Only allow to create new when clicking TAB or ENTER
    // See: https://app.zenhub.com/workspace/o/spkltarmac/spackle-web/issues/355
    return param.keyCode === 9 || param.keyCode === 13;
  };

  renderDefaultArrow = () => (
    <img
      className="dropdown-arrow"
      src={this.props.darkMode ? vectorDark : vector}
      alt={'arrow'}
    />
  );

  render() {
    const arrowRenderer =
      this.props.arrowRenderer ||
      (this.props.defaultArrow && this.renderDefaultArrow) ||
      null;

    return (
      <div
        className={
          'dropdown searchable-dropdown' +
          (this.props.className !== undefined
            ? ' ' + this.props.className
            : '') +
          (this.props.darkMode ? ' dark-mode' : '')
        }
      >
        {!this.props.creatable ? (
          <Async
            arrowRenderer={arrowRenderer}
            autoload={this.props.autoload}
            cache={this.props.cache}
            clearable={this.props.clearable}
            loadOptions={this.getOptions.bind(this)}
            placeholder={this.props.placeholder}
            searchable={true}
            value={this.props.value}
            onChange={this.handleChange}
          />
        ) : (
          <AsyncCreatable
            {...this.props}
            shouldKeyDownEventCreateNewOption={this.handleCreatableKeyDown}
          />
        )}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(SearchableDropDown);
