import axios from 'axios/index';
import { loaderEnd, loaderStart } from '../../actions/loader';
import {
  getAccessToken,
  getClient,
  getCurrentUser,
  isLoggedIn,
} from '../../selectors/authentication';
import { logoutRequest } from '../../actions/authentication';

const LOGIN_PATHNAMES = [
  '/login',
  '/set-password',
  '/recover-password',
  '/reset-password',
  '/register',
];

const loadingDisabled = (config) =>
  config &&
  config.hasOwnProperty('loadingDisabled') &&
  !!config.loadingDisabled;

export function configureApi(dispatch, getState) {
  axios.interceptors.request.use((config) => {
    // Custom config to prevent loader to be shown for a asynch request
    if (!loadingDisabled(config)) dispatch(loaderStart());

    let newConfig = config;
    const state = getState();
    if (isLoggedIn(state)) {
      newConfig = { ...config };
      newConfig.headers = {
        ...config.headers,
        'access-token': getAccessToken(state),
        client: getClient(state),
        'token-type': 'bearer',
        uid: getCurrentUser(state).uid,
      };
    }

    return newConfig;
  });

  axios.interceptors.response.use(
    (response) => {
      if (!loadingDisabled(response.config)) dispatch(loaderEnd());
      return response;
    },
    (error) => {
      if (!loadingDisabled(error.response.config)) dispatch(loaderEnd());
      let newError = error;
      const code = error.response.data.code;
      const { pathname } = window.location;

      if (
        error.response.status === 401 &&
        !LOGIN_PATHNAMES.includes(pathname)
      ) {
        if (pathname === '/admin/dashboard' || code === 're_login') {
          dispatch(logoutRequest());
        } else {
          // redirect user to dashboard
          window.location = '/admin/dashboard';
        }
      } else {
        if (!error.response.data.hasOwnProperty('errors')) {
          newError = {
            ...error,
            response: {
              data: {
                errors: ['An unexpected error occurred.'],
              },
            },
          };
        }
      }

      return Promise.reject(newError);
    }
  );
}
