import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './ProjectOpenItemDetails.css';
import DocumentLink from 'components/shared/document/DocumentLink';
import LogoUpload from 'components/shared/logo-upload/LogoUpload';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import moment from 'moment';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getCurrentProjectOpenItem,
  getRespondOpenItemDetailsModal,
  getCritical,
  getOpenItemForm,
  getOpenItemFormErrors,
} from 'components/admin/projects/details/project-open-items/store/selectors';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import {
  setOpenItemForm,
  respondOpenItemDetailsModal,
  cancelRespondOpenItemDetailsModal,
  respondProjectOpenItem,
  setOpenItemFormErrors,
} from 'components/admin/projects/details/project-open-items/store/actions';
import ProjectOpenItemRespondForm from 'components/admin/projects/details/project-open-items/ProjectOpenItemRespondForm';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import ReadOnlyInput from 'components/shared/input/ReadOnlyInput';
import { getDarkMode } from 'selectors/theme';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import { containerScrollToBottom } from 'services/utils/scroller-util';
import User from 'domain/user';
import OpenItemPermissions from 'permissions/open-item-permissions';

class ProjectOpenItemDetails extends Component {
  static propTypes = {
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.currentProject) this.loadDirectory();
  }
  loadDirectory = () => {
    const { currentProject, directory, dispatch } = this.props;
    if (!directory || directory.project_id !== currentProject.id) {
      dispatch(loadDirectoryRequest(currentProject.id));
    }
  };

  componentDidUpdate() {
    if (this.moveToRespond) {
      containerScrollToBottom('project-open-item-details');
      this.moveToRespond = false;
    }
  }

  handleRespond = () => {
    this.moveToRespond = true;

    const { openItem, dispatch } = this.props;
    dispatch(
      setOpenItemForm({
        ...openItem,
        documents: openItem.response_documents.map((doc) => {
          return {
            ...doc,
            url: doc.document.url,
          };
        }),
        images: openItem.response_images.map((image) => {
          return {
            ...image,
            url: image.image.gallery.url,
          };
        }),
      })
    );
    dispatch(respondOpenItemDetailsModal());
  };

  handleRespondCancel = () => {
    this.props.dispatch(cancelRespondOpenItemDetailsModal());
  };

  handleRespondSave = (values) => {
    const { currentProject, openItem, dispatch } = this.props;
    dispatch(respondProjectOpenItem(currentProject.id, openItem.id, values));
  };

  handleSaveDraft = () => {
    const { form } = this.props;
    const values = { ...form, draftResponse: true };
    this.handleRespondSave(values);
  };

  handleSave = () => {
    const { form, dispatch } = this.props;
    const errors = {};
    if (!form.response) {
      errors.response = true;
    }

    if (Object.keys(errors).length > 0) {
      dispatch(setOpenItemFormErrors(errors));
    } else {
      const values = { ...form, draftResponse: false };
      this.handleRespondSave(values);
    }
  };

  renderUserLabel = (user, date) => {
    return (
      (user ? `${User.fullNameWithCompany(user)}` : '') +
      (date
        ? `${user ? ' on ' : ''}${moment(date).format(
            'MM/DD/YYYY'
          )} at ${moment(date).format('hh:mm A')}`
        : '')
    );
  };

  render() {
    const {
      currentUser,
      currentProject,
      directory,
      openItem,
      respondOpenItemDetailsModal,
      critical,
      form,
      errors,
      onDelete,
      onEdit,
      darkMode,
    } = this.props;

    if (!openItem) return null;

    let csiCode = openItem.csi_code;
    if (!csiCode) {
      csiCode = {};
      csiCode['division'] = '00';
      csiCode['label'] = 'General Contractor';
    }

    const showErrorMessage =
      !!errors && !!Object.keys(errors).find((e) => errors[e]);

    const canDeleteOpenItem = OpenItemPermissions.canDelete(
      currentUser,
      currentProject,
      directory,
      openItem
    );

    const canAccelerateOpenItem = OpenItemPermissions.canAccelerate(
      currentUser,
      currentProject,
      openItem
    );

    const canRespondOpenItem = OpenItemPermissions.canRespond(
      currentUser,
      openItem
    );

    const canEditOpenItem = OpenItemPermissions.canEdit(
      currentUser,
      currentProject,
      directory,
      openItem
    );

    return (
      <React.Fragment>
        <div
          id="project-open-item-details"
          className={`project-open-item-details ${darkMode ? 'dark-mode' : ''}`}
        >
          <div className="form-block">
            <FormControlBlock
              label="Cost Code"
              control={
                <ReadOnlyInput
                  value={`${csiCode.division} - ${csiCode.label}`}
                />
              }
            />
          </div>
          <div className="form-block">
            <FormControlBlock
              label="Assigned To"
              control={<ReadOnlyInput value={openItem.assignee_label || '-'} />}
            />
          </div>
          <div className="form-block">
            <FormControlBlock
              label="CC Recipients"
              control={
                <ReadOnlyInput
                  value={
                    !openItem.recipients || openItem.recipients.length === 0
                      ? '-'
                      : openItem.recipients
                          .map((rcpt) => {
                            return User.fullNameWithCompany(rcpt);
                          })
                          .join(', ')
                  }
                />
              }
            />
          </div>
          <div className="form-block multicontrols-block">
            <FormControlBlock
              label="Due Date"
              control={
                <ReadOnlyInput
                  value={
                    openItem.resolve_by
                      ? moment(openItem.resolve_by).format('MM/DD/YYYY')
                      : '-'
                  }
                />
              }
            />
            <FormControlBlock
              label="Status"
              control={
                <ReadOnlyInput
                  value={openItem.resolved ? 'Resolved' : 'Pending'}
                />
              }
            />
          </div>
          <DetailsTitle
            className="project-open-item-request-title"
            title={critical ? 'Critical Item' : 'Open Item'}
          />
          {!!(openItem.created_by || openItem.created_at) && (
            <div className="form-block">
              <FormControlBlock
                label="Submitted By"
                control={
                  <ReadOnlyInput
                    value={this.renderUserLabel(
                      openItem.created_by,
                      openItem.created_at
                    )}
                  />
                }
              />
            </div>
          )}
          <div className="form-block">
            <FormControlBlock
              label="Description"
              control={
                <TextareaAutosizeWrapper
                  initialValue={openItem.description || '-'}
                  disabled={true}
                />
              }
            />
          </div>
          {((!!openItem.documents && openItem.documents.length > 0) ||
            (!!openItem.images && openItem.images.length > 0)) && (
            <div className="form-resources-block">
              {!!openItem.documents && openItem.documents.length > 0 && (
                <div className="form-documents">
                  <FormControlBlock
                    className="without-focus"
                    label="Documents"
                    control={
                      <div className="form-resources-body">
                        {openItem.documents.map((doc, index) => {
                          return (
                            <DocumentLink key={index} url={doc.document.url} />
                          );
                        })}
                      </div>
                    }
                  />
                </div>
              )}
              {!!openItem.images && openItem.images.length > 0 && (
                <div className="form-images">
                  <FormControlBlock
                    className="without-focus"
                    label="Photos"
                    control={
                      <div className="form-resources-body">
                        <LogoUpload
                          readOnly
                          logos={openItem.images.map((image) => ({
                            preview: image.image.gallery.url,
                            url: image.image.url,
                          }))}
                        />
                      </div>
                    }
                  />
                </div>
              )}
            </div>
          )}
          <DetailsTitle
            className="project-open-item-response-title"
            title={`${critical ? 'Critical Item' : 'Open Item'} Response`}
          />
          {respondOpenItemDetailsModal ? (
            <ProjectOpenItemRespondForm />
          ) : (
            <React.Fragment>
              {!!openItem.responded_at && (
                <div className="form-block">
                  <FormControlBlock
                    label="Responded"
                    bottomLabel={openItem.draft_response ? 'Draft' : null}
                    control={
                      <ReadOnlyInput
                        value={this.renderUserLabel(
                          null,
                          openItem.responded_at
                        )}
                      />
                    }
                  />
                </div>
              )}
              <div className="form-block">
                <FormControlBlock
                  label="Response"
                  control={
                    <TextareaAutosizeWrapper
                      initialValue={openItem.response || '-'}
                      disabled={true}
                    />
                  }
                />
              </div>
              {((!!openItem.response_documents &&
                openItem.response_documents.length > 0) ||
                (!!openItem.response_images &&
                  openItem.response_images.length > 0)) && (
                <div className="form-resources-block">
                  {!!openItem.response_documents &&
                    openItem.response_documents.length > 0 && (
                      <div className="form-documents">
                        <FormControlBlock
                          className="without-focus"
                          label="Documents"
                          control={
                            <div className="form-resources-body">
                              {openItem.response_documents.map((doc, index) => {
                                return (
                                  <DocumentLink
                                    key={index}
                                    url={doc.document.url}
                                  />
                                );
                              })}
                            </div>
                          }
                        />
                      </div>
                    )}
                  {!!openItem.response_images &&
                    openItem.response_images.length > 0 && (
                      <div className="form-images">
                        <FormControlBlock
                          className="without-focus"
                          label="Photos"
                          control={
                            <div className="form-resources-body">
                              <LogoUpload
                                readOnly
                                logos={openItem.response_images.map(
                                  (image) => ({
                                    preview: image.image.gallery.url,
                                    url: image.image.url,
                                  })
                                )}
                              />
                            </div>
                          }
                        />
                      </div>
                    )}
                </div>
              )}
            </React.Fragment>
          )}
        </div>
        <div className="project-open-item-actions">
          {respondOpenItemDetailsModal ? (
            <React.Fragment>
              {showErrorMessage && (
                <h4 className="error-message">
                  There are missing fields, please review.
                </h4>
              )}
              <div className="buttons-container">
                <Button
                  type={BUTTON_TYPES.CONFIRMATION}
                  color={BUTTON_COLORS.WHITE}
                  label="Cancel"
                  onClick={this.handleRespondCancel}
                />
                <Button
                  type={BUTTON_TYPES.CONFIRMATION}
                  color={BUTTON_COLORS.WHITE}
                  label="Save Draft"
                  onClick={this.handleSaveDraft}
                />
                <Button
                  type={BUTTON_TYPES.CONFIRMATION}
                  color={BUTTON_COLORS.GREEN}
                  label={
                    !form.responded_at || form.draft_response
                      ? 'Submit'
                      : 'Save'
                  }
                  onClick={this.handleSave}
                />
              </div>
            </React.Fragment>
          ) : (
            <div className="buttons-container">
              {canDeleteOpenItem && (
                <Button
                  type={BUTTON_TYPES.CONFIRMATION}
                  color={BUTTON_COLORS.WHITE}
                  label="Delete"
                  onClick={onDelete}
                />
              )}
              {canAccelerateOpenItem ? (
                <Button
                  type={BUTTON_TYPES.CONFIRMATION}
                  color={BUTTON_COLORS.WHITE}
                  label="Accelerate"
                  onClick={this.handleRespond}
                />
              ) : (
                canRespondOpenItem && (
                  <Button
                    type={BUTTON_TYPES.CONFIRMATION}
                    color={BUTTON_COLORS.GREEN}
                    label={
                      openItem.responded_at || openItem.draft_response
                        ? 'Edit Response'
                        : 'Respond'
                    }
                    onClick={this.handleRespond}
                  />
                )
              )}
              {canEditOpenItem && (
                <Button
                  type={BUTTON_TYPES.CONFIRMATION}
                  color={BUTTON_COLORS.GREEN}
                  label={
                    openItem.responded_at && !openItem.draft_response
                      ? 'Edit or Resolve'
                      : 'Edit'
                  }
                  onClick={onEdit}
                />
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    directory: getDirectory(state),
    openItem: getCurrentProjectOpenItem(state),
    respondOpenItemDetailsModal: getRespondOpenItemDetailsModal(state),
    critical: getCritical(state),
    form: getOpenItemForm(state),
    errors: getOpenItemFormErrors(state),
    darkMode: getDarkMode(state),
  };
})(ProjectOpenItemDetails);
