import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import './PdfViewerBluebeam.css';
import Button from 'components/shared/button/Button';
import ImageButton from 'components/shared/button/ImageButton';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import Iframe from 'react-iframe';
import closeSvg from 'images/close.svg';
import { getCurrentUser } from 'selectors/authentication';
import { bluebeamApi } from 'services/api/bluebeam-api';
import { pdfTronApi } from 'services/api/pdftron-api';
import { createdBluebeamSession } from 'actions/pdfViewerBluebeam';
import { savedPdfTronSuccess, resetPdfTronMessages } from './store/actions';
import { getPdfTronSuccess } from './store/selectors';

import { setShowModal } from 'components/shared/pdf-viewer-bluebeam/store/actions';

import UsersNotificationModal from './UsersNotificationModal';

class PdfViewerBluebeam extends Component {
  static propTypes = {
    show: PropTypes.bool,
    content: PropTypes.shape({
      source: PropTypes.any,
      type: PropTypes.oneOf(['url', 'blob']),
    }),
    bbIntegration: PropTypes.bool,
    pdftronIntegration: PropTypes.bool,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    show: false,
    content: null,
    bbIntegration: false,
    pdftronIntegration: false,
    pdfTronSuccess: null,
  };

  loadPdf() {
    this.props.onResetPdftronMessages();
    const url = this.getContent(this.props);

    if (this.viewer) {
      window.WebViewer(
        {
          path: process.env.REACT_APP_WEBVIEWER_URL,
          initialDoc: url,
          annotationUser: `${this.props.currentUser.first_name} ${this.props.currentUser.last_name}`,
          config: `${process.env.REACT_APP_WEBVIEWER_URL}/pdftron_config.js`,
          disableLogs: true,
        },
        this.viewer
      );
    }
  }

  componentDidMount() {
    window.addEventListener('message', this.handleIframeMessages.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleIframeMessages);
  }

  constructor(props) {
    super(props);

    this.viewer = null;

    this.setViewerRef = (element) => {
      this.viewer = element;
      this.loadPdf();
    };

    this.docViewer = null;
    this.annotManager = null;
    this.instance = null;

    this.state = {
      showSaveConfirmation: false,
      closeModal: false,
      pdfTronChangesDetected: false,
      openedWithBluebeam: false,
    };
  }

  handleClose = () => {
    if (
      this.props.pdftronIntegration &&
      this.state.pdfTronChangesDetected &&
      !this.state.openedWithBluebeam
    ) {
      this.setState({
        showSaveConfirmation: true,
      });
    } else {
      if (this.props.onClose) {
        this.props.onClose();
      }
    }
  };

  handleCloseConfirmation = (response) => {
    if (response) {
      this.setState({
        closeModal: true,
      });
      this.handleClickSave();
    } else if (this.props.onClose) {
      this.props.onClose();
    }
    this.setState({
      showSaveConfirmation: false,
    });
  };

  handleClickSave = () => {
    if (this.viewer) {
      const iframe = document.getElementsByClassName('pdf-web-viewer')[0]
        .firstElementChild;
      const message = {
        type: 'saveDocument',
        documentId: this.props.documentId,
      };
      iframe.contentWindow.postMessage(message, '*');
    }
  };

  handleIframeMessages = (event) => {
    if (event.data.type === 'clickDetected') {
      this.setState({
        pdfTronChangesDetected: true,
      });
    }

    if (
      event.data.type === 'savePdf' &&
      event.data.documentId === this.props.documentId
    ) {
      this.props.onResetPdftronMessages();
      pdfTronApi
        .savePdf(event.data.data, this.props.documentId, this.props.fileName)
        .then((response) => {
          this.props.onSavedPdfTronSuccess(response.ok);
          this.setState({
            pdfTronChangesDetected: false,
          });
          if (this.props.onClose && this.state.closeModal) {
            this.props.onClose();
          }
        });
    }
  };

  handleOpenWithBluebeam = () => {
    bluebeamApi.isAuthorized().then((response) => {
      if (response.ok) {
        if (response.data.error) {
          bluebeamApi.authorize(this.props.documentId).then((response) => {
            if (response.ok) {
              window.open(response.url, '_blank');
            }
          });
        } else {
          bluebeamApi
            .editInBluebeam(this.props.documentId, this.props.content.source)
            .then((response) => {
              window.open(response.url);
            });
        }
      }
    });
    this.setState({
      openedWithBluebeam: true,
    });
    this.props.onCreatedBluebeamSession();
  };

  handleInviteUsers = () => {
    this.props.onHandleInviteUsers(this.props.documentId);
  };

  getContent = (props) => {
    let url = null;

    if (props.content !== null) {
      if (props.content.type === 'url') {
        url = props.content.source;
      } else if (props.content.type === 'blob') {
        if (navigator.appVersion.toString().indexOf('.NET') > 0) {
          window.navigator.msSaveBlob(props.content.source);
          return null;
        } else {
          //Create a Blob from the PDF Stream
          const file = new Blob([props.content.source], {
            type: 'application/pdf',
          });
          //Build a URL from the file
          url = URL.createObjectURL(file);
        }
      }
    }
    return url;
  };

  render() {
    const props = this.props;
    const url = props.show && this.getContent(props);

    return (
      props.show && (
        <React.Fragment>
          <Modal className="pdf-viewer-modal" show={props.show}>
            <Modal.Header>
              <div className="body-bold">Pdf Viewer</div>
              <div className="button-container">
                {props.pdfTronSuccess === true && (
                  <p className="pdfTronMessage pdfTronSuccess">
                    PDF successfully saved
                  </p>
                )}
                {props.pdfTronSuccess === false && (
                  <p className="pdfTronMessage pdfTronError">PDF not saved</p>
                )}
                {props.pdftronIntegration && !this.state.openedWithBluebeam && (
                  <Button
                    className="button-orange"
                    label="Save Changes"
                    onClick={this.handleClickSave}
                  />
                )}
                {props.bbIntegration && (
                  <React.Fragment>
                    <Button
                      className="button-white"
                      label="Invite users to edit"
                      onClick={this.handleInviteUsers}
                    />

                    <Button
                      className="button-white"
                      label="Open with Bluebeam Studio"
                      onClick={this.handleOpenWithBluebeam}
                    />
                  </React.Fragment>
                )}
              </div>
              <ImageButton
                alt="close"
                src={closeSvg}
                onClick={this.handleClose}
              />
            </Modal.Header>
            <Modal.Body>
              {url !== null && props.pdftronIntegration && (
                <div className="pdf-web-viewer" ref={this.setViewerRef}></div>
              )}
              {url !== null && !props.pdftronIntegration && (
                <Iframe
                  url={url}
                  className="pdf-viewer-iframe"
                  width="100%"
                  height="100%"
                  display="initial"
                  position="relative"
                  allowFullScreen
                />
              )}
            </Modal.Body>
          </Modal>

          <UsersNotificationModal />

          <ConfirmationModal
            show={this.state.showSaveConfirmation}
            message="Do you want to save your changes before closing?"
            onHide={this.handleCloseConfirmation}
          />
        </React.Fragment>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pdfTronSuccess: getPdfTronSuccess(state),
    currentUser: getCurrentUser(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCreatedBluebeamSession: () => {
    dispatch(createdBluebeamSession());
  },
  onHandleInviteUsers: (documentId) => {
    dispatch(setShowModal({ modal: true, documentId: documentId }));
  },
  onResetPdftronMessages: () => {
    dispatch(resetPdfTronMessages());
  },
  onSavedPdfTronSuccess: (response) => {
    dispatch(savedPdfTronSuccess(response));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PdfViewerBluebeam);
