import React, { Component } from 'react';
import { connect } from 'react-redux';
import './NewProject.css';
import Input from 'components/shared/input/Input';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import {
  TERMINATION_CATEGORY_OPTIONS,
  CONDITION_OPTIONS,
  INDUSTRY_OPTIONS,
  INDUSTRY_TYPE_OPTIONS,
  PRIMARY_USERS_OPTIONS,
  COST_OPTIONS,
} from 'domain/project';
import Range from 'services/utils/range-utils';
import {
  dropdownOptions,
  multipleDropdownOptions,
} from 'services/utils/dropdown-utils';
import { getProjectForm } from 'components/admin/projects/creation/store/selectors';
import {
  setProjectFormValue,
  nextStage,
} from 'components/admin/projects/creation/store/actions';
import {
  loadSettings,
  updateSettings,
} from 'components/admin/projects/details/project-settings/store/actions';
import numeral from 'numeral';
import DatePicker from 'components/shared/date-picker/DatePicker';
import {
  todayValidation,
  sameOrBeforeTodayValidation,
} from 'services/utils/date-util';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import Selector from 'components/shared/selector/Selector';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import { ProjectStatusSorted } from 'domain/project-status-enum';
import { getSettings } from 'components/admin/projects/details/project-settings/store/selectors';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import FormControlBlock, {
  CONTROL_SIZE,
} from 'components/shared/form/FormControlBlock';
import RadioButton from 'components/shared/radio-button/RadioButton';

class ProjectDetails extends Component {
  constructor(props) {
    super(props);

    this.terminationCategoryOptions = dropdownOptions(
      TERMINATION_CATEGORY_OPTIONS
    );
    this.conditionOptions = multipleDropdownOptions(CONDITION_OPTIONS);
    this.industryOptions = dropdownOptions(INDUSTRY_OPTIONS);
    this.industryTypeOptions = Object.keys(INDUSTRY_TYPE_OPTIONS).reduce(
      (options, option) => ({
        ...options,
        [option]: dropdownOptions(INDUSTRY_TYPE_OPTIONS[option]),
      }),
      {}
    );
    this.primaryUsersOptions = Range.makeOptions(
      PRIMARY_USERS_OPTIONS,
      false,
      true
    );
    this.costOptions = Range.makeOptions(COST_OPTIONS, true, true);
  }

  componentDidMount() {
    const { dispatch, currentProject } = this.props;
    if (currentProject) dispatch(loadSettings(currentProject.id));
  }

  componentDidUpdate() {
    const { dispatch, currentProject, settings } = this.props;
    if (currentProject && !settings) dispatch(loadSettings(currentProject.id));
  }

  handleProjectFormChange = (attribute, value) => {
    this.props.dispatch(setProjectFormValue(attribute, value));
  };

  handleContinue = () => {
    this.props.dispatch(nextStage());
  };

  advancedDocumentCheckbox = () => {
    const { currentProject } = this.props;
    if (currentProject && currentProject.documents_count === 0) {
      return (
        <div className="form-block">
          <LabeledCheckBox
            checked={this.props.projectForm.advancedDocumentView}
            size="medium"
            label="Enable Advanced Document View"
            onChange={(value) =>
              this.handleProjectFormChange('advancedDocumentView', value)
            }
          />
        </div>
      );
    }
  };

  sub_status_option(label) {
    return <span className="substatus-option">{label}</span>;
  }

  handleMultipleFloorsChange = () => {
    const { currentProject, settings, dispatch } = this.props;
    const values = { multipleFloors: !settings.multiple_floors };
    dispatch(updateSettings(currentProject.id, values));
  };

  hasStatusReason = () => {
    const {
      projectForm: { status },
    } = this.props;

    return status === 'cancelled' || status === 'lost';
  };

  render() {
    const {
      settings,
      projectForm: {
        number,
        name,
        sqFootage,
        bidDueDate,
        estStartDate,
        estCompletionDate,
        status,
        terminationDate,
        terminationCategory,
        terminationReason,
        isPublic,
        estimatedCost,
        primaryUsersCount,
        industry,
        industryType,
        conditions,
      },
      cancelFunction,
      backFunction,
    } = this.props;

    return (
      <React.Fragment>
        <DetailsTitle title="Project Details" />

        <div className="details-form create-project-details">
          <div className="form-block">
            <FormControlBlock
              label={!!number && 'Project Number'}
              size={CONTROL_SIZE.MEDIUM}
              control={
                <Input
                  placeholder="Project Number"
                  value={number}
                  onChange={this.handleProjectFormChange.bind(this, 'number')}
                />
              }
            />
            <FormControlBlock
              label={!!name && 'Project Name'}
              size={CONTROL_SIZE.BIG}
              control={
                <Input
                  placeholder="Project Name"
                  value={name}
                  onChange={this.handleProjectFormChange.bind(this, 'name')}
                />
              }
            />
            <FormControlBlock
              label={!!status && 'Stage'}
              size={CONTROL_SIZE.BIG}
              control={
                <Selector
                  className="status-selector"
                  placeholder="Stage"
                  options={ProjectStatusSorted.map((status) => {
                    return {
                      value: status.status,
                      label: status.isSubStatus()
                        ? this.sub_status_option(status.label)
                        : status.label,
                    };
                  })}
                  value={status}
                  onChange={(option) =>
                    this.handleProjectFormChange('status', option.value)
                  }
                />
              }
            />
          </div>

          <div className="form-block">
            <FormControlBlock
              label={!!sqFootage && 'Square Feet'}
              size={CONTROL_SIZE.MEDIUM}
              control={
                <Input
                  placeholder="Square Feet"
                  value={sqFootage ? numeral(sqFootage).format('0,0') : ''}
                  onChange={(value) =>
                    this.handleProjectFormChange(
                      'sqFootage',
                      numeral(value).value()
                    )
                  }
                />
              }
            />
            <FormControlBlock
              label={!!bidDueDate && 'Bid Due'}
              size={CONTROL_SIZE.SMALL}
              control={
                <DatePicker
                  placeholder={'Bid Due'}
                  format="MM/DD/YYYY"
                  isValidDate={todayValidation}
                  value={bidDueDate}
                  clearable
                  onChange={(value) =>
                    this.handleProjectFormChange('bidDueDate', value)
                  }
                />
              }
            />
            <FormControlBlock
              label={!!estStartDate && 'Est Start'}
              size={CONTROL_SIZE.SMALL}
              control={
                <DatePicker
                  format="MM/DD/YYYY"
                  placeholder={'Est Start'}
                  value={estStartDate}
                  clearable
                  onChange={(value) =>
                    this.handleProjectFormChange('estStartDate', value)
                  }
                />
              }
            />
            <FormControlBlock
              label={!!estCompletionDate && 'Est Completion'}
              size={CONTROL_SIZE.SMALL}
              control={
                <DatePicker
                  format="MM/DD/YYYY"
                  placeholder={'Est Completion'}
                  isValidDate={todayValidation}
                  value={estCompletionDate}
                  clearable
                  onChange={(value) =>
                    this.handleProjectFormChange('estCompletionDate', value)
                  }
                />
              }
            />
          </div>

          {this.hasStatusReason() && (
            <React.Fragment>
              <div className="form-block">
                {status === 'lost' && (
                  <FormControlBlock
                    label={!!terminationCategory && 'Termination Category'}
                    size={CONTROL_SIZE.BIG}
                    control={
                      <Selector
                        placeholder="Termination Category"
                        options={this.terminationCategoryOptions}
                        value={terminationCategory}
                        onChange={(option) =>
                          this.handleProjectFormChange(
                            'terminationCategory',
                            option.value
                          )
                        }
                      />
                    }
                  />
                )}
                <FormControlBlock
                  label={!!terminationDate && 'Termination Date'}
                  size={CONTROL_SIZE.SMALL}
                  control={
                    <DatePicker
                      format="MM/DD/YYYY"
                      placeholder={'Termination Date'}
                      isValidDate={sameOrBeforeTodayValidation}
                      value={terminationDate}
                      clearable
                      onChange={(value) =>
                        this.handleProjectFormChange('terminationDate', value)
                      }
                    />
                  }
                />
              </div>
              <div className="form-block">
                <FormControlBlock
                  label={!!terminationReason && 'Termination Reason'}
                  size={CONTROL_SIZE.DOUBLE_BIG}
                  control={
                    <TextareaAutosizeWrapper
                      placeholder="Termination Reason"
                      initialValue={terminationReason || ''}
                      onChange={(value) =>
                        this.handleProjectFormChange('terminationReason', value)
                      }
                    />
                  }
                />
              </div>
            </React.Fragment>
          )}

          <div className="form-block">
            <RadioButton
              options={[
                {
                  value: 0,
                  label: 'Private',
                },
                {
                  value: 1,
                  label: 'Public',
                },
              ]}
              value={isPublic === false ? 0 : 1}
              orientation="vertical"
              onChange={(value) =>
                this.handleProjectFormChange('isPublic', value === 1)
              }
            />
          </div>

          <div className="form-block">
            <FormControlBlock
              label={!!conditions.length && 'Conditions'}
              bottomLabel="Select one or more conditions, that best describes the Project Copy"
              size={CONTROL_SIZE.DOUBLE_BIG}
              control={
                <MultipleDropdown
                  options={this.conditionOptions}
                  currentValues={conditions}
                  placeholder="Conditions"
                  search={false}
                  onChange={(value) =>
                    this.handleProjectFormChange('conditions', value)
                  }
                />
              }
            />
          </div>

          <div className="form-block">
            <FormControlBlock
              label={!!industry && 'Project Industry'}
              size={CONTROL_SIZE.BIG}
              control={
                <Selector
                  placeholder="Project Industry"
                  options={this.industryOptions}
                  value={industry}
                  onChange={(option) =>
                    this.handleProjectFormChange('industry', option.value)
                  }
                />
              }
            />
            <FormControlBlock
              label={!!industryType && 'Project Type'}
              size={CONTROL_SIZE.BIG}
              control={
                <Selector
                  placeholder="Project Type"
                  options={
                    (industry && this.industryTypeOptions[industry]) || []
                  }
                  value={industryType}
                  onChange={(option) =>
                    this.handleProjectFormChange('industryType', option.value)
                  }
                />
              }
            />
          </div>

          <div className="form-block">
            <FormControlBlock
              label={estimatedCost !== null && 'Est Project Cost ($MM)'}
              size={CONTROL_SIZE.BIG}
              control={
                <Selector
                  placeholder="Est Project Cost ($MM)"
                  options={this.costOptions}
                  value={estimatedCost}
                  onChange={(option) =>
                    this.handleProjectFormChange('estimatedCost', option.value)
                  }
                />
              }
            />
            <FormControlBlock
              label={!!primaryUsersCount && 'Total Project Users'}
              bottomLabel="Select the total estimated users from all companies, who will be collaborating on the Project"
              size={CONTROL_SIZE.BIG}
              control={
                <Selector
                  placeholder="Total Project Users"
                  options={this.primaryUsersOptions}
                  value={primaryUsersCount}
                  onChange={(option) =>
                    this.handleProjectFormChange(
                      'primaryUsersCount',
                      option.value
                    )
                  }
                />
              }
            />
          </div>

          {this.currentProject && settings && (
            <div className="form-block">
              <FormControlBlock
                bottomLabel="Daily Report requires a floor number for each trade"
                control={
                  <LabeledCheckBox
                    onChange={this.handleMultipleFloorsChange}
                    checked={settings.multiple_floors}
                    label="Multiple Floors"
                    position="left"
                  />
                }
              />
            </div>
          )}
        </div>

        <div className="buttons-container">
          {cancelFunction && (
            <Button
              key="cancel"
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Cancel"
              onClick={cancelFunction}
            />
          )}
          {backFunction && (
            <Button
              key="back"
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Back"
              onClick={backFunction}
            />
          )}
          <Button
            key="continue"
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label="Continue"
            disabled={!this.canContinue()}
            onClick={this.handleContinue}
          />
        </div>
      </React.Fragment>
    );
  }

  canContinue = () => {
    return (
      !!this.props.projectForm.number &&
      !!this.props.projectForm.name &&
      !!this.props.projectForm.sqFootage &&
      !!this.props.projectForm.status &&
      (!this.hasStatusReason() ||
        (!!this.props.projectForm.terminationDate &&
          !!this.props.projectForm.terminationReason &&
          (this.props.projectForm.status !== 'lost' ||
            !!this.props.projectForm.terminationCategory)))
    );
  };
}

export default connect((state) => {
  return {
    projectForm: getProjectForm(state),
    currentProject: getCurrentProject(state),
    currentUser: getCurrentUser(state),
    settings: getSettings(state),
  };
})(ProjectDetails);
