import {
  CHANGE_ORDER_QUOTES_REQUEST_MODAL_SET,
  CHANGE_ORDER_QUOTES_REQUEST_CONFIRMATION_SET,
} from 'components/admin/work-orders/details/change-order-quotes/store/action-types';

const initialState = {
  showChangeOrderQuoteModal: false,
  showChangeOrderRequestConfirmation: false,
};

export default function changeOrderQuotesUiReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case CHANGE_ORDER_QUOTES_REQUEST_MODAL_SET:
      return {
        ...state,
        showChangeOrderQuoteModal: !state.showChangeOrderQuoteModal,
      };

    case CHANGE_ORDER_QUOTES_REQUEST_CONFIRMATION_SET:
      return {
        ...state,
        showChangeOrderRequestConfirmation: !state.showChangeOrderRequestConfirmation,
      };

    default:
      return state;
  }
}
