import axios from 'axios';
import numeral from 'numeral';
import BaseApi from 'services/api/base-api';

class TasksApi extends BaseApi {
  getFormData(formData, data, previousKey) {
    if (data instanceof Object) {
      Object.keys(data).forEach((key) => {
        const value = data[key];
        if (value instanceof Object && !Array.isArray(value)) {
          return this.getFormData(formData, value, key);
        }
        if (previousKey) {
          key = `${previousKey}[${key}]`;
        }
        if (Array.isArray(value)) {
          value.forEach((val) => {
            formData.append(`${key}[]`, val);
          });
        } else {
          formData.append(key, value);
        }
      });
    }
  }

  createTask(task, workOrderId, preTask = false) {
    let taskData = new FormData();
    taskData.append('task[work_order_id]', workOrderId.toString());
    taskData.append('task[sub_trade_id]', task.subTrade.toString());
    taskData.append('task[completion_date]', task.completionDate);
    taskData.append('task[floor]', task.floorNumber);
    taskData.append('task[room]', task.room);
    taskData.append('task[name]', task.name);
    taskData.append('task[description]', task.description);
    task.images.forEach((image) => {
      taskData.append(
        'task[images_attributes][][image]',
        image.file.original,
        image.file.original.name
      );
    });
    const path = preTask ? '/tasks/create_pre' : '/tasks';
    if (preTask) {
      taskData.append(
        'contractor_id',
        task.contractor ? task.contractor.value : ''
      );
    }

    return axios
      .post(this.baseUrl + path, taskData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        return {
          ok: true,
          newTask: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateTask(task) {
    let taskData = new FormData();
    taskData.append('task[sub_trade_id]', task.subTrade.toString());
    taskData.append('task[completion_date]', task.completionDate);
    taskData.append('task[floor]', task.floorNumber);
    taskData.append('task[room]', task.room);
    taskData.append('task[name]', task.name);
    taskData.append('task[description]', task.description);
    task.images
      .filter((image) => {
        return image.id === 0;
      }) // Only send new images
      .forEach((image) => {
        taskData.append(
          'task[images_attributes][][image]',
          image.file.original,
          image.file.original.name
        );
      });

    return axios
      .put(this.baseUrl + '/tasks/' + task.id, taskData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        return {
          ok: true,
          task: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateTaskSchedule(taskId, start, end) {
    let taskData = new FormData();
    taskData.append('task[schedule_start_date]', start);
    taskData.append('task[schedule_end_date]', end);

    return axios
      .put(this.baseUrl + '/tasks/' + taskId, taskData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        return {
          ok: true,
          task: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  removeTask(id) {
    return axios
      .delete(`${this.baseUrl}/tasks/${id}`)
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getTask(taskId) {
    return axios
      .get(this.baseUrl + '/tasks/' + taskId)
      .then((response) => {
        return {
          ok: true,
          task: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getTasks(page, contractorId, tradeId, critical, taskStatusEnum) {
    return axios
      .get(
        this.baseUrl +
          '/tasks?page=' +
          (page !== undefined ? page : 1) +
          (contractorId !== undefined ? '&by_contractor=' + contractorId : '') +
          (tradeId !== undefined ? '&by_trade=' + tradeId : '') +
          (critical !== undefined ? '&critical=true' : '') +
          (taskStatusEnum !== undefined
            ? '&' + taskStatusEnum.status + '=true'
            : '')
      )
      .then((response) => {
        return {
          ok: true,
          tasks: {
            content: response.data,
            total: numeral(response.headers['total']).value(),
            totalCritical: numeral(response.headers['total-critical']).value(),
            totalPlm: numeral(response.headers['total-plm']).value(),
            totalAgg: numeral(response.headers['total-agg']).value(),
            totalCmp: numeral(response.headers['total-cmp']).value(),
            totalCls: numeral(response.headers['total-cls']).value(),
            totalDraft: numeral(response.headers['total-draft']).value(),
            totalPre: numeral(response.headers['total-pre']).value(),
            totalRfp: numeral(response.headers['total-rfp']).value(),
            totalWip: numeral(response.headers['total-wip']).value(),
            totalWoa: numeral(response.headers['total-woa']).value(),
            pageSize: numeral(response.headers['per-page']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  addQuoteRequest(taskId, contractorsIds, bidDueDate) {
    return axios
      .put(this.baseUrl + '/tasks/' + taskId + '/quotes', {
        task: {
          bid_due_date: bidDueDate,
          contractor_ids: contractorsIds,
        },
      })
      .then((response) => {
        return {
          ok: true,
          task: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  start(taskId) {
    return axios
      .put(this.baseUrl + '/tasks/' + taskId + '/start')
      .then((response) => {
        return {
          ok: true,
          task: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  complete(taskId) {
    return axios
      .put(this.baseUrl + '/tasks/' + taskId + '/complete')
      .then((response) => {
        return {
          ok: true,
          task: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  removeImage(taskId, imageId) {
    return axios
      .put(this.baseUrl + '/tasks/' + taskId, {
        task: {
          images_attributes: [
            {
              id: imageId,
              _destroy: true,
            },
          ],
        },
      })
      .then((response) => {
        return {
          ok: true,
          task: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  addNote(taskId, content) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const apiPayload = new FormData();
    apiPayload.append('note[content]', content.content);

    let index = 0;
    if (content.images && content.images.length > 0) {
      content.images
        .filter((image) => !image.id)
        .forEach((image) => {
          apiPayload.append(
            `note[images_attributes][${index}][image]`,
            image.file,
            image.file.name
          );
          index++;
        });
    }

    index = 0;
    if (content.documents && content.documents.length > 0) {
      content.documents
        .filter((doc) => !doc.id)
        .forEach((doc) => {
          apiPayload.append(
            `note[documents_attributes][${index}][document]`,
            doc.file,
            doc.file.name
          );
          index++;
        });
    }

    return axios
      .post(`${this.baseUrl}/tasks/${taskId}/notes`, apiPayload, headers)
      .then((response) => {
        return {
          ok: true,
          note: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getNotes(taskId) {
    return axios
      .get(`${this.baseUrl}/tasks/${taskId}/notes`)
      .then((response) => {
        return {
          ok: true,
          notes: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  addOpenItem(taskId, userId, description, critical, dueDate) {
    const data = {
      open_item: {
        description: description,
        due_date: dueDate,
        user_id: userId,
        critical: critical,
      },
    };

    return axios
      .post(`${this.baseUrl}/tasks/${taskId}/open_items`, data)
      .then((response) => {
        return {
          ok: true,
          openItem: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateOpenItem(taskId, openItemId, editForm) {
    const data = {
      open_item: {
        user_id: editForm.userId,
        description: editForm.description,
        due_date: editForm.dueDate,
        resolved: editForm.resolved,
        resolved_note: editForm.resolvedNote,
      },
    };

    return axios
      .put(`${this.baseUrl}/tasks/${taskId}/open_items/${openItemId}`, data)
      .then((response) => {
        return {
          ok: true,
          openItem: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getMembers(taskId) {
    return axios
      .get(`${this.baseUrl}/tasks/${taskId}/work_order_users`)
      .then((response) => {
        return {
          ok: true,
          members: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  approveWork(taskId) {
    return axios
      .put(`${this.baseUrl}/tasks/${taskId}/approve_completion`)
      .then((response) => {
        return {
          ok: true,
          task: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createReceipt(taskId, values) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    let apiPayload = new FormData();
    apiPayload.append('receipt[cost]', values.cost);
    apiPayload.append('receipt[description]', values.description);
    if (values.image) {
      apiPayload.append('receipt[image_attributes][image]', values.image);
    }
    if (values.document) {
      apiPayload.append(
        'receipt[document_attributes][document]',
        values.document
      );
    }

    return axios
      .post(`${this.baseUrl}/tasks/${taskId}/receipts`, apiPayload, headers)
      .then((response) => {
        return {
          ok: true,
          receipt: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createTimeEntry(taskId, values) {
    const apiPayload = {
      time_entry: {
        name_title: values.name,
        start_date: values.startDate,
        time_in: values.timeIn,
        time_out: values.timeOut,
      },
    };
    return axios
      .post(`${this.baseUrl}/tasks/${taskId}/time_entries`, apiPayload)
      .then((response) => {
        return {
          ok: true,
          timeEntry: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createVendorInvoices(taskId, values) {
    let apiPayload = new FormData();
    apiPayload.append('vendor_invoice[vendor_name]', values.vendorName);
    apiPayload.append('vendor_invoice[due_date]', values.dueDate);
    apiPayload.append('vendor_invoice[cost]', values.cost);
    apiPayload.append(
      'vendor_invoice[document_attributes][document]',
      values.document
    );
    return axios
      .post(`${this.baseUrl}/tasks/${taskId}/vendor_invoices`, apiPayload)
      .then((response) => {
        return {
          ok: true,
          invoice: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const tasksApi = new TasksApi();
export default TasksApi = new TasksApi();
