import {
  SET_PROJECT_REPORT_METRICS,
  SET_PROJECT_REPORTS,
  SET_PHOTOS,
  SET_SHARED_ALBUMS,
  SET_SHARED_FOLDERS,
} from './action-types';

const initialState = {
  reportMetrics: null,
  reports: null,
  photos: null,
  sharedAlbums: null,
  sharedFolders: null,
};

export default function projectPhotosDataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROJECT_REPORT_METRICS:
      return {
        ...state,
        reportMetrics: action.payload,
      };

    case SET_PROJECT_REPORTS:
      return {
        ...state,
        reports: action.payload,
      };

    case SET_PHOTOS:
      return {
        ...state,
        photos: (action.payload || []).map(({ image }) => image),
      };

    case SET_SHARED_ALBUMS:
      return {
        ...state,
        sharedAlbums: action.payload,
      };

    case SET_SHARED_FOLDERS:
      return {
        ...state,
        sharedFolders: action.payload,
      };

    default:
      return state;
  }
}
