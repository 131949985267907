import { showErrorMessage } from 'actions/messages';
import { projectOpenItemsApi } from 'services/api/project-open-items-api';
import {
  PROJECT_OPEN_ITEMS_COUNT,
  PROJECT_OPEN_ITEMS_CRITICAL_COUNT,
  PROJECT_OPEN_ITEMS_LOAD,
} from 'components/admin/project-open-items/store/action-types';

export function projectOpenItemsCountSuccess(count) {
  return {
    type: PROJECT_OPEN_ITEMS_COUNT,
    payload: count,
  };
}

export function projectOpenItemsCount(filters) {
  return (dispatch) => {
    return projectOpenItemsApi
      .getProjectOpenItemsAllCount(filters)
      .then((response) => {
        if (response.ok) {
          dispatch(
            projectOpenItemsCountSuccess(
              response.count + response.countCritical
            )
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function projectOpenItemsCriticalCountSuccess(count) {
  return {
    type: PROJECT_OPEN_ITEMS_CRITICAL_COUNT,
    payload: count,
  };
}

export function projectOpenItemsCriticalCount(filters) {
  return (dispatch) => {
    return projectOpenItemsApi
      .getProjectOpenItemsAllCount(filters)
      .then((response) => {
        if (response.ok) {
          dispatch(
            projectOpenItemsCriticalCountSuccess(response.countCritical)
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function projectOpenItemsSuccess(projectOpenItems) {
  return {
    type: PROJECT_OPEN_ITEMS_LOAD,
    payload: projectOpenItems,
  };
}

export function projectOpenItemsRequest(page, filters) {
  return (dispatch) => {
    return projectOpenItemsApi
      .getProjectOpenItemsAll(page, filters)
      .then((response) => {
        if (response.ok) {
          dispatch(projectOpenItemsSuccess({ ...response, currentPage: page }));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}
