import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SelectList.css';
import LabeledCheckBox from '../checkbox/LabeledCheckBox';

class SelectList extends Component {
  static propTypes = {
    values: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        selected: PropTypes.bool,
      })
    ),
    showAs: PropTypes.oneOf(['list', 'table']),
    onSelect: PropTypes.func,
  };

  static defaultProps = {
    showAs: 'list',
    values: [],
  };

  handleChange = (itemId) => {
    this.props.onSelect(itemId);
  };

  render() {
    return (
      <div className="select-list">
        {this.props.showAs === 'list'
          ? this.renderAsList()
          : this.renderAsTable()}
      </div>
    );
  }

  renderAsList() {
    return (
      <ul>
        {this.props.values.map((element, index) => {
          return <li key={index}>{this.renderElement(element)}</li>;
        })}
      </ul>
    );
  }

  renderAsTable() {
    const rows = [];
    let row = [];
    this.props.values.forEach((element, index) => {
      if (index % 2 === 0) {
        row = [];

        row.push(<div>{this.renderElement(element)}</div>);

        rows.push(row);
      } else {
        row.push(<div>{this.renderElement(element)}</div>);
      }
    });

    return (
      <table className="select-table">
        <tbody>
          {rows.map((row, index) => {
            return (
              <tr key={index}>
                <td>{row[0]}</td>
                <td>{row[1]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderElement(element) {
    return (
      <LabeledCheckBox
        position="left"
        label={element.value}
        checked={element.selected}
        id={element.id}
        onChange={() => this.handleChange(element.id)}
      />
    );
  }
}

export default SelectList;
