export const clearUnwantedHistoryState = (history) => {
  if (history.location.state) {
    const state = { ...history.location.state };
    if (state.projectOpenItemId) {
      delete state.projectOpenItemId;
      delete state.projectOpenItemCritical;
    }
    if (state.rfiId) delete state.rfiId;
    if (state.rfaId) delete state.rfaId;
    if (state.subDailyReport) {
      delete state.dailyReportId;
      delete state.subDailyReport;
    }
    if (state.fieldReports) {
      delete state.fieldReports;
      delete state.reportId;
    }
    if (state.meetingId) delete state.meetingId;
    if (state.meetingsParentId) delete state.meetingsParentId;
    if (state.meetingsInstanceId) delete state.meetingsInstanceId;
    if (state.submittalId) delete state.submittalId;
    if (state.documentation) delete state.documentation;
    history.replace({ ...history.location, state });
  }

  return history;
};
