import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './FieldWorkOrderLogTable.css';
import SimpleTable from 'components/shared/table/SimpleTable';
import { openFieldWorkOrder } from 'components/admin/projects/details/project-daily-report-log/store/actions';
import Date from 'components/shared/text/Date';

class FieldWorkOrderLogTable extends Component {
  static propTypes = {
    fieldWorkOrders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    canSeeDailyReports: PropTypes.bool.isRequired,
  };

  status = {
    pending: 'Pending',
    sub_approved: 'Sub-Approved',
    gc_approved: 'GC-Approved',
    sub_denied: 'Sub-Denied',
    gc_denied: 'GC-Denied',
  };

  mapFieldWorkOrders = () => {
    const { fieldWorkOrders, canSeeDailyReports } = this.props;
    return fieldWorkOrders.map((fieldWO) => {
      const result = [
        fieldWO.number,
        fieldWO.work_performed || '-',
        fieldWO.start_date ? (
          <Date value={fieldWO.start_date} format="MM/DD/YYYY" />
        ) : (
          'TBD'
        ),
        fieldWO.completion_date ? (
          <Date value={fieldWO.completion_date} format="MM/DD/YYYY" />
        ) : (
          'TBD'
        ),
        fieldWO.delay ? 'Yes' : 'No',
        fieldWO.cost_impact ? fieldWO.cost || 'Yes' : 'No',
        fieldWO.status ? this.status[fieldWO.status] : '-',
      ];
      if (canSeeDailyReports) {
        const csiCode = fieldWO.csi_code ? fieldWO.csi_code.division : '';
        const contractor = fieldWO.contractor ? fieldWO.contractor.name : '-';
        result.splice(1, 0, csiCode, contractor);
      }
      return result;
    });
  };

  selectedRow = (id) => {
    const { fieldWorkOrders, dispatch } = this.props;
    const fieldWorkOrder = fieldWorkOrders[id];
    if (fieldWorkOrder && fieldWorkOrder.id && fieldWorkOrder.project_id) {
      dispatch(
        openFieldWorkOrder(fieldWorkOrder.project_id, fieldWorkOrder.id)
      );
    }
  };

  handleGenerateLink = (index) => {
    const { fieldWorkOrders } = this.props;
    return `?fieldWorkOrderId=${fieldWorkOrders[index].id}`;
  };

  render() {
    const { canSeeDailyReports } = this.props;
    const headers = [
      'NUMBER',
      'WORK REQUIRED',
      'START',
      'COMPLETION',
      'DELAY',
      'COST IMPACT',
      'STATUS',
    ];
    if (canSeeDailyReports) {
      headers.splice(1, 0, 'COST CODE', 'COMPANY NAME');
    }
    return (
      <SimpleTable
        className={classnames({
          'simple-table': true,
          'projects-table': true,
          'field-work-order-log-table': true,
          'sub-field-work-order-log-table': !canSeeDailyReports,
        })}
        headers={headers}
        rows={this.mapFieldWorkOrders()}
        emptyMessage="No Extra Work Orders"
        onRowSelected={this.selectedRow}
        onGenerateLink={this.handleGenerateLink}
      />
    );
  }
}

export default connect()(FieldWorkOrderLogTable);
