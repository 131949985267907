import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import FilterHeader from 'components/shared/table/FilterHeader';
import DatePicker from 'components/shared/date-picker/DatePicker';
import { alphabeticalFilterOptions, dateFilterOptions } from 'domain/schedule';
import {
  sameOrAfterDateValidation,
  sameOrBeforeDateValidation,
} from 'services/utils/date-util';

import colors from 'styles/colors';
import { getDarkMode } from 'selectors/theme';
import {
  getProjectScheduleFilters,
  getProjectSchedules,
} from './store/selectors';

// SUBITEMS FILTER
const ExtFilterHeader = (props) => {
  const { label, options, values, onChange, darkMode } = props;
  const [subitemsParentId, setSubitemsParentId] = useState(null);
  const optionHasSubitems = (o) => o.subitems && o.subitems.length > 0;
  const optionFindById = (id) => options.find((o) => o.id === id);
  const getOptions = () => {
    const res = [];
    if (subitemsParentId !== null) {
      const parent = optionFindById(subitemsParentId);
      const color = colors[`chart-${darkMode ? 'blue' : 'red'}`];
      res.push({
        id: 'back',
        label: <span style={{ color }}>Back</span>,
        closeOnSelect: false,
      });
      if (parent) {
        parent.subitems.forEach(({ id, label }) => {
          return res.push({ id, label });
        });
      }
    } else {
      options.forEach((o) => {
        if (optionHasSubitems(o)) o.closeOnSelect = false;
        res.push(o);
      });
    }
    return res;
  };
  const filterOptions = getOptions();
  const onFilter = (optionId) => {
    if (subitemsParentId !== null) {
      if (optionId === 'back') setSubitemsParentId(null);
      onChange({
        [subitemsParentId]: optionId === 'back' ? null : optionId,
        sort: null,
      });
    } else {
      const selected = optionFindById(optionId);
      if (optionHasSubitems(selected)) setSubitemsParentId(selected.id);
      else onChange({ sort: optionId });
    }
  };
  return (
    <FilterHeader
      className="exteded-filter"
      label={label}
      options={filterOptions}
      onFilter={onFilter}
      selected={values[subitemsParentId || 'sort']}
    />
  );
};

// DATE FILTER
const DateFilter = (props) => {
  const { label, onChange, values, withRange, darkMode } = props;
  const [showRange, setShowRange] = useState(false);

  const onFilter = (optionId) => {
    if (optionId === 'by_range') {
      setShowRange(true);
    } else if (optionId === 'back') {
      setShowRange(false);
      onChange({ sort: values.sort, from: null, to: null });
    } else if (optionId !== 'to' && optionId !== 'from') {
      onChange({ sort: optionId });
    }
  };

  const getOptions = () => {
    if (!withRange) return dateFilterOptions;

    let res = [];
    if (!showRange) {
      res = [...dateFilterOptions];
      res.push({ id: 'by_range', label: 'Range', closeOnSelect: false });
    } else {
      const color = colors[`chart-${darkMode ? 'blue' : 'red'}`];
      res.push({
        id: 'back',
        label: <span style={{ color }}>Back</span>,
        closeOnSelect: false,
      });
      res.push({
        id: 'from',
        closeOnSelect: false,
        className: 'date-menu-item',
        label: (
          <DatePicker
            format="MM/DD/YYYY"
            placeholder="From"
            editableInput={false}
            value={values.from}
            onChange={(from) => onChange({ from, to: values.to, sort: null })}
            isValidDate={(d) => sameOrBeforeDateValidation(d, values.to)}
            hideUnderline
            clearable
          />
        ),
      });
      res.push({
        id: 'to',
        closeOnSelect: false,
        className: 'date-menu-item',
        label: (
          <DatePicker
            format="MM/DD/YYYY"
            placeholder="To"
            value={values.to}
            onChange={(to) => onChange({ from: values.from, to, sort: null })}
            isValidDate={(d) => sameOrAfterDateValidation(d, values.from)}
            hideUnderline
            editableInput={false}
            clearable
          />
        ),
      });

      let label = '';
      if (values.from || values.to) {
        if (!values.to)
          label = `From ${moment(values.from).format('MM/DD/YYYY')}`;
        else if (!values.from)
          label = `To ${moment(values.to).format('MM/DD/YYYY')}`;
        else
          label = `${moment(values.from).format('MM/DD/YYYY')} - ${moment(
            values.to
          ).format('MM/DD/YYYY')}`;
      }
      res.push({ id: 'range_value', label, show: false });
    }
    return res;
  };

  const getSelectedValue = () => {
    if (showRange) return values.from || values.to ? 'range_value' : null;
    return values.sort;
  };

  const options = getOptions();
  const selected = getSelectedValue();

  return (
    <FilterHeader
      className="date-filter"
      options={options}
      label={label}
      onFilter={onFilter}
      selected={selected}
    />
  );
};

// name filter
const Name = (props) => {
  const { filters, handleFilterChange, isSort, schedules } = props;
  const byNameOptions = useMemo(
    () =>
      isSort
        ? []
        : schedules.reduce((acc, { parent, name: label }) => {
            if (parent && !acc.find((n) => n.id === parent.id))
              acc.push({ id: parent.id, label });
            return acc;
          }, []),
    [filters.tab]
  );

  const getOptions = () => {
    const res = [...alphabeticalFilterOptions];
    if (byNameOptions.length > 0) {
      res.push({ id: 'byName', label: 'By Name', subitems: byNameOptions });
    }
    return res;
  };
  const options = getOptions();

  return (
    <ExtendedFilterHeader
      label={'SCHEDULE NAME'}
      options={options}
      values={{ byName: filters.byName, sort: filters.sortName }}
      onChange={({ sort, byName }) =>
        handleFilterChange({ sortName: sort, byName })
      }
    />
  );
};

// company filter
const Company = (props) => {
  const { filters, handleFilterChange, schedules, label } = props;

  const byCompanyName = useMemo(
    () =>
      schedules.reduce((acc, { created_by_company }) => {
        if (
          created_by_company &&
          !acc.find((a) => a.id === created_by_company.id)
        )
          acc.push({
            id: created_by_company.id,
            label: created_by_company.name,
          });
        return acc;
      }, []),
    [filters.tab]
  );

  const getOptions = () => {
    const res = [...alphabeticalFilterOptions];
    if (byCompanyName.length > 0) {
      res.push({ id: 'byCompany', label: 'By Name', subitems: byCompanyName });
    }
    return res;
  };
  const options = getOptions();

  return (
    <ExtendedFilterHeader
      label={label}
      options={options}
      values={{ sort: filters.sortCompany, byCompany: filters.byCompany }}
      onChange={({ sort, byCompany }) =>
        handleFilterChange({ sortCompany: sort, byCompany })
      }
    />
  );
};

// submitted by filter
const SubmittedBy = (props) => {
  const { filters, handleFilterChange, schedules } = props;

  const bySubmittedUsers = useMemo(
    () =>
      schedules.reduce((acc, { created_by }) => {
        if (!acc.find((a) => a.id === created_by.id))
          acc.push({ id: created_by.id, label: created_by.label });
        return acc;
      }, []),
    [filters.tab]
  );

  const getOptions = () => {
    const res = [...alphabeticalFilterOptions];
    if (bySubmittedUsers.length > 0) {
      res.push({
        id: 'bySubmittedUser',
        label: 'By Name',
        subitems: bySubmittedUsers,
      });
    }
    return res;
  };
  const options = getOptions();

  return (
    <ExtendedFilterHeader
      label="SUBMITTED BY"
      options={options}
      values={{
        bySubmittedUser: filters.byCreatedUser,
        sort: filters.sortCreatedBy,
      }}
      onChange={({ sort: sortCreatedBy, bySubmittedUser: byCreatedUser }) =>
        handleFilterChange({ sortCreatedBy, byCreatedUser })
      }
    />
  );
};

// submitted by filter
const ArchiveBy = (props) => {
  const { filters, handleFilterChange, schedules } = props;

  const byArchiveUsers = useMemo(
    () =>
      schedules.reduce((acc, { archived_by }) => {
        if (archived_by && !acc.find((a) => a.id === archived_by.id))
          acc.push({ id: archived_by.id, label: archived_by.label });
        return acc;
      }, []),
    [filters.tab]
  );

  const getOptions = () => {
    const res = [...alphabeticalFilterOptions];
    if (byArchiveUsers.length > 0) {
      res.push({
        id: 'byArchivedUser',
        label: 'By Name',
        subitems: byArchiveUsers,
      });
    }
    return res;
  };
  const options = getOptions();

  return (
    <ExtendedFilterHeader
      label="ARCHIVE BY"
      options={options}
      values={{
        sort: filters.sortArchivedBy,
        byArchivedUser: filters.byArchivedUser,
      }}
      onChange={({ sort, byArchivedUser }) =>
        handleFilterChange({ byArchivedUser, sortArchivedBy: sort })
      }
    />
  );
};

export const ExtendedFilterHeader = connect((state) => ({
  darkMode: getDarkMode(state),
}))(ExtFilterHeader);

export const DateFilterHeader = connect((state) => ({
  darkMode: getDarkMode(state),
}))(DateFilter);

export const NameFilter = connect((state) => ({
  filters: getProjectScheduleFilters(state),
  schedules: getProjectSchedules(state),
}))(Name);

export const CompanyFilter = connect((state) => ({
  filters: getProjectScheduleFilters(state),
  schedules: getProjectSchedules(state),
}))(Company);

export const SubmittedByFilter = connect((state) => ({
  filters: getProjectScheduleFilters(state),
  schedules: getProjectSchedules(state),
}))(SubmittedBy);

export const ArchiveByFilter = connect((state) => ({
  filters: getProjectScheduleFilters(state),
  schedules: getProjectSchedules(state),
}))(ArchiveBy);
