import { WorkOrderStatusKeys } from 'services/utils/work-order-status-enum';
import moment from 'moment';

function getProposalAttribute(workOrder, attribute) {
  const element = workOrder.waiting_proposal || workOrder.approved_proposal;
  if (element !== null) {
    if (element[attribute] !== null && element[attribute] !== undefined) {
      return element[attribute];
    }
  }
  return null;
}

export class WorkOrder {
  static getCompletionDate(workOrder = null) {
    if (workOrder === null) {
      return null;
    }
    let endDate = getProposalAttribute(workOrder, 'completion_date');
    if (endDate !== null) {
      let scheduleEndDate = workOrder.schedule_end_date
        ? moment(workOrder.schedule_end_date)
            .subtract(1, 'day')
            .format('YYYY-MM-DD')
        : null;
      endDate = scheduleEndDate || workOrder.completed_at || endDate;
    }
    return endDate;
  }

  static getJobNumber(workOrder, defaultValue = null) {
    return workOrder.work_order_job !== null &&
      workOrder.work_order_job !== undefined
      ? workOrder.work_order_job.number
      : defaultValue;
  }

  static getName(workOrder, defaultValue = null) {
    return workOrder.name !== null ? workOrder.name : defaultValue;
  }

  static getStartDate(workOrder = null) {
    if (workOrder === null) {
      return null;
    }
    let startDate = getProposalAttribute(workOrder, 'start_date');
    if (startDate !== null) {
      startDate =
        workOrder.schedule_start_date || workOrder.started_at || startDate;
    }
    return startDate;
  }

  static getTotalCost(workOrder) {
    if (workOrder === null) {
      return null;
    }
    return workOrder.cost
      ? workOrder.cost
      : getProposalAttribute(workOrder, 'cost');
  }

  /**
   * Returns true if the given work order is currently or has already fulfilled the given status.
   * @param workOrder
   * @param status
   */
  static isStatusFulFilled(workOrder, status = '') {
    if (WorkOrderStatusKeys[status.toUpperCase()] === undefined) {
      return false;
    }
    // Dont take into account DECLINED and UNKNOWN status
    const filteredKeys = Object.keys(WorkOrderStatusKeys).filter(
      (key) =>
        WorkOrderStatusKeys[key] !== WorkOrderStatusKeys.DECLINED &&
        WorkOrderStatusKeys[key] !== WorkOrderStatusKeys.UNKNOWN
    );

    const woIndex = filteredKeys.indexOf(workOrder.status.toUpperCase());
    const givenIndex = filteredKeys.indexOf(status.toUpperCase());
    if (woIndex !== -1 && givenIndex !== -1) {
      return woIndex >= givenIndex;
    }
    return false;
  }

  static getVariance(workOrder, defaultValue = null) {
    let variance;
    if (workOrder.variance) {
      variance = `${workOrder.variance}%`;
    } else {
      const proposal =
        workOrder.waiting_proposal ||
        workOrder.approved_proposal ||
        workOrder.budget;
      variance =
        proposal !== undefined &&
        proposal !== null &&
        proposal.variance !== null
          ? `${proposal.variance}%`
          : defaultValue;
    }
    return variance;
  }

  static hasPurchaseOrder(workOrder) {
    return (
      workOrder.purchase_order !== null && workOrder.purchase_order_doc !== null
    );
  }
}
