import React, { Component } from 'react';
import { connect } from 'react-redux';
import './CloseoutManualLog.css';
import simpleLoading from 'images/simple-loading.gif';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import Date from 'components/shared/text/Date';
import DocumentLink from 'components/shared/document/DocumentLink';
import SimpleTable from 'components/shared/table/SimpleTable';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getCloseoutPercentage,
  getCloseoutManuals,
  getCloseoutManualsCurrentPage,
  getCloseoutManualsTotal,
  getCloseoutManualsPageSize,
  getCloseoutManual,
} from 'components/admin/projects/details/project-closeout/store/selectors';
import {
  loadCloseoutManualsRequest,
  createCloseoutManual,
  loadCloseoutManual,
  deleteCloseoutManual,
} from 'components/admin/projects/details/project-closeout/store/actions';
import CloseoutPermissions from 'permissions/closeout-permissions';
import { getCurrentUser } from 'selectors/authentication';

class CloseoutManualLog extends Component {
  componentDidMount() {
    const intervalId = setInterval(this.fetchCloseoutManual, 5000);
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  fetchCloseoutManual = () => {
    const { currentProject, closeoutManual, dispatch } = this.props;
    if (closeoutManual && !closeoutManual.document) {
      dispatch(loadCloseoutManual(currentProject.id, closeoutManual.id));
    }
  };

  mapCloseoutManuals = () => {
    const { closeoutManuals } = this.props;
    const readOnly = this.readOnly();
    return closeoutManuals.map((closeoutManual) => {
      return {
        id: closeoutManual.id,
        data: [
          closeoutManual.number,
          closeoutManual.document ? (
            <Date
              value={closeoutManual.document.created_at}
              format="MM/DD/YYYY"
            />
          ) : (
            <img className="processing" src={simpleLoading} alt="processing" />
          ),
          closeoutManual.document ? (
            <DocumentLink url={closeoutManual.document.document.url} />
          ) : (
            <img className="processing" src={simpleLoading} alt="processing" />
          ),
          !readOnly && (
            <Button
              type={BUTTON_TYPES.LINK}
              color={BUTTON_COLORS.GREEN}
              label="Delete"
              disabled={!closeoutManual.document}
              onClick={() => this.handleDeleteManual(closeoutManual.id)}
            />
          ),
        ],
      };
    });
  };

  handlePageChange = (newPage) => {
    const { currentProject, dispatch } = this.props;
    dispatch(loadCloseoutManualsRequest(currentProject.id, newPage));
  };

  handleCreateManual = () => {
    const { currentProject, dispatch } = this.props;
    dispatch(createCloseoutManual(currentProject.id));
  };

  handleDeleteManual = (id) => {
    const { currentProject, dispatch } = this.props;
    dispatch(deleteCloseoutManual(currentProject.id, id));
  };

  readOnly = () => {
    const { currentUser, currentProject } = this.props;

    return !CloseoutPermissions.canEditManual(currentUser, currentProject);
  };

  render() {
    const {
      closeoutPercentage,
      closeoutManual,
      currentPage,
      pageSize,
      total,
    } = this.props;
    const readOnly = this.readOnly();
    return (
      <div className="closeout-manual-log">
        <div className="closeout-manual-create">
          {!readOnly && (
            <Button
              type={BUTTON_TYPES.LINK}
              icon={BUTTON_ICONS.PLUS}
              label="Create Manual"
              disabled={
                closeoutPercentage < 100 ||
                (closeoutManual && !closeoutManual.document)
              }
              onClick={this.handleCreateManual}
            />
          )}
        </div>
        <div className="closeout-manual-table">
          <SimpleTable
            className="projects-table"
            headers={['#', 'CREATED', 'LINK', 'ACTIONS']}
            rows={this.mapCloseoutManuals()}
            emptyMessage="No Items"
          />
          <div className="pagination-container">
            <SimplePagination
              currentPage={currentPage}
              pageSize={pageSize}
              total={total}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    closeoutPercentage: getCloseoutPercentage(state),
    closeoutManual: getCloseoutManual(state),
    closeoutManuals: getCloseoutManuals(state),
    currentPage: getCloseoutManualsCurrentPage(state),
    total: getCloseoutManualsTotal(state),
    pageSize: getCloseoutManualsPageSize(state),
  };
})(CloseoutManualLog);
