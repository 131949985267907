import axios from 'axios';
import BaseApi from './base-api';

class CloseoutContributorCatsApi extends BaseApi {
  getCompletedPercentage(projectId) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/closeout/closeout_contributor_cats/completed_percentage`
      )
      .then((response) => {
        return {
          ok: true,
          completedPercentage: response.data.percentage,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateCloseoutContributorCat(projectId, id, applies) {
    let data = {
      applies: applies,
    };
    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/closeout/closeout_contributor_cats/${id}`,
        {
          closeout_contributor_cat: data,
        }
      )
      .then((response) => {
        return {
          ok: true,
          closeoutContributorCat: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const closeoutContributorCatsApi = new CloseoutContributorCatsApi();
