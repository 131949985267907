import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ProjectStatusEnum } from 'domain/project-status-enum';
import Date from 'components/shared/text/Date';
import SimpleTable from 'components/shared/table/SimpleTable';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import ProjectStatus from 'components/shared/project-status/ProjectStatus';
import {
  getBidsDueCurrentPage,
  getBidsDue,
} from 'components/admin/projects/store/selectors';
import {
  loadBidsDueRequest,
  goToProjectDetails,
} from 'components/admin/projects/store/actions';

class BidsDue extends Component {
  static propTypes = {
    filters: PropTypes.shape(),
  };

  static defaultProps = {
    filters: {},
  };

  componentDidMount() {
    this.handlePageChange(1);
  }

  handlePageChange = (page) => {
    const { filters, dispatch } = this.props;
    dispatch(loadBidsDueRequest({ ...filters, page }));
  };

  handleOpen = (id) => {
    this.props.dispatch(goToProjectDetails(id));
  };

  handleGenerateLink = (id) => {
    return `projects/${id}`;
  };

  mapProjects = () => {
    const { projects } = this.props;
    return projects.content.map((project) => {
      return {
        id: project.id,
        data: [
          project.number,
          project.name,
          project.bid_due_date && (
            <Date
              className="bid-due-date"
              value={project.bid_due_date}
              format="MM/DD/YYYY"
            />
          ),
          <ProjectStatus
            status={ProjectStatusEnum.create(project.status)}
            type="list"
          />,
        ],
      };
    });
  };

  render() {
    const { projects, currentPage } = this.props;
    return (
      projects && (
        <div className="bids-due">
          <SimpleTable
            className="projects-table"
            headers={['PROJECT NO', 'PROJECT NAME', 'BID DUE', 'STAGE']}
            rows={this.mapProjects()}
            emptyMessage="No Projects"
            onRowSelected={this.handleOpen}
            onGenerateLink={this.handleGenerateLink}
          />
          <div className="pagination-container">
            <SimplePagination
              currentPage={currentPage}
              pageSize={projects.pageSize}
              total={projects.total}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      )
    );
  }
}

export default connect((state) => {
  return {
    currentPage: getBidsDueCurrentPage(state),
    projects: getBidsDue(state),
  };
})(BidsDue);
