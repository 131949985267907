import React, { Component } from 'react';
import './App.css';
import Main from './components/main/Main';
import Header from './components/header/Header';
import HeaderOnlyLogo from './components/header/HeaderOnlyLogo';
import Loader from './components/shared/loader/Loader';
import Messages from './components/shared/messages/Messages';

class App extends Component {
  render() {
    const currentPath = window.location.pathname;

    return (
      <div>
        {currentPath.includes('order-materials/review') ? (
          <HeaderOnlyLogo />
        ) : (
          <Header />
        )}
        <Main />

        <Loader />
        <Messages />
      </div>
    );
  }
}

export default App;
