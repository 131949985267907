import React from 'react';
import { connect } from 'react-redux';
import { getReportMetrics } from './store/selectors';
import PaidIndicator from './PaidIndicator';
import './PaidToDate.css';

const PaidToDate = (props) => {
  const { metrics } = props;
  const { contract, paid } = (metrics && metrics.paid_to_date) || {};

  return (
    <PaidIndicator
      className="project-dashboard-paid-to-date"
      name="Project Paid To Date"
      contract={contract}
      paid={paid}
    />
  );
};

export default connect((state) => {
  return {
    metrics: getReportMetrics(state),
  };
})(PaidToDate);
