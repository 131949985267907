import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './TimePicker.css';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import ReadOnlyInput from '../input/ReadOnlyInput';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';

class TimePicker extends Component {
  static propTypes = {
    className: PropTypes.string,
    format: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    theme: PropTypes.string,
    valid: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    format: 'hh:mm A',
    valid: true,
    value: null,
  };

  handleOnChange = (valueMoment) => {
    this.props.onChange(valueMoment.format('YYYY-MM-DD hh:mm A'));
  };

  render() {
    const darkModeClassName = this.props.darkMode ? ' dark-mode' : '';

    return this.props.readOnly ? (
      <ReadOnlyInput
        className={
          this.props.className !== undefined ? ' ' + this.props.className : ''
        }
        value={
          this.props.value
            ? moment
                .utc(this.props.value, 'YYYY-MM-DD hh:mm A')
                .format(this.props.format)
            : ''
        }
      />
    ) : (
      <div className={`time-picker${darkModeClassName}`}>
        <Datetime
          closeOnSelect={true}
          inputProps={{
            className:
              'form-control input' +
              (this.props.required ? ' required' : '') +
              (!this.props.valid ? ' invalid' : '') +
              darkModeClassName +
              ' ' +
              this.props.className,
            placeholder: this.props.placeholder,
            readOnly: true,
          }}
          value={
            this.props.value
              ? moment
                  .utc(this.props.value, 'YYYY-MM-DD hh:mm A')
                  .format(this.props.format)
              : ''
          }
          dateFormat={false}
          timeFormat={this.props.format}
          onChange={(value) => this.handleOnChange(value)}
        />
      </div>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(TimePicker);
