import {
  PROJECTS_LOAD_SUCCESS,
  PROJECTS_MISSING_DRS_LOAD_SUCCESS,
  PROJECTS_BIDS_DUE_LOAD_SUCCESS,
  PROJECTS_MISSING_DRS_COUNT,
  PROJECTS_BIDS_DUE_COUNT,
} from 'components/admin/projects/store/action-types';

const initialState = {
  projects: null,
  missingDrs: null,
  bidsDue: null,
  missingDrsCount: 0,
  bidsDueCount: 0,
};

export default function projectsDataReducer(state = initialState, action) {
  switch (action.type) {
    case PROJECTS_LOAD_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      };

    case PROJECTS_MISSING_DRS_LOAD_SUCCESS:
      return {
        ...state,
        missingDrs: action.payload,
      };

    case PROJECTS_BIDS_DUE_LOAD_SUCCESS:
      return {
        ...state,
        bidsDue: action.payload,
      };

    case PROJECTS_MISSING_DRS_COUNT:
      return {
        ...state,
        missingDrsCount: action.payload,
      };

    case PROJECTS_BIDS_DUE_COUNT:
      return {
        ...state,
        bidsDueCount: action.payload,
      };

    default:
      return state;
  }
}
