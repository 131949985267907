import {
  WORK_ORDERS_DETAILS_PROPOSAL_BACK,
  WORK_ORDERS_DETAILS_PROPOSAL_CREATE_SUCCESS,
  WORK_ORDERS_DETAILS_PROPOSAL_EMAIL_ADD,
  WORK_ORDERS_DETAILS_PROPOSAL_EMAIL_CLEAR,
  WORK_ORDERS_DETAILS_PROPOSAL_EMAIL_MODAL_SHOW_SET,
  WORK_ORDERS_DETAILS_PROPOSAL_EMAIL_REMOVE,
  WORK_ORDERS_DETAILS_PROPOSAL_MESSAGE_SET,
  WORK_ORDERS_DETAILS_PROPOSAL_MODAL_CLOSE,
  WORK_ORDERS_DETAILS_PROPOSAL_MODAL_SHOW,
  WORK_ORDERS_DETAILS_PROPOSAL_MODAL_EDIT,
  WORK_ORDERS_DETAILS_PROPOSAL_NEXT,
  WORK_ORDERS_DETAILS_PROPOSAL_PDF_VIEWER_CLOSE,
  WORK_ORDERS_DETAILS_PROPOSAL_PRINT_SUCCESS,
  WORK_ORDERS_DETAILS_PROPOSAL_SUBJECT_SET,
  WORK_ORDERS_DETAILS_PROPOSAL_DEPOSIT_SELECTED_CHANGE,
} from 'actions/admin/work-orders/action-types';

const initialState = {
  currentView: 'form', // oneOf: [form, review, thank-you]
  emails: [],
  message: '',
  pdf: null,
  show: false,
  showEmailModal: false,
  showPdfViewer: false,
  subject: '',
  depositSelected: false,
};

export default function proposalUiReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_DETAILS_PROPOSAL_BACK:
      return {
        ...state,
        currentView: action.payload,
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_CREATE_SUCCESS:
      return {
        ...state,
        currentView: 'thank-you',
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_EMAIL_ADD:
      if (
        state.emails.find((email) => email === action.payload) === undefined
      ) {
        return {
          ...state,
          emails: state.emails.concat([action.payload]),
        };
      } else {
        return state;
      }

    case WORK_ORDERS_DETAILS_PROPOSAL_EMAIL_CLEAR:
      return {
        ...state,
        emails: [],
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_EMAIL_MODAL_SHOW_SET:
      return {
        ...state,
        showEmailModal: action.payload,
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_EMAIL_REMOVE:
      return {
        ...state,
        emails: state.emails.filter((email) => email !== action.payload),
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_MESSAGE_SET:
      return {
        ...state,
        message: action.payload,
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_MODAL_CLOSE:
      return {
        ...state,
        show: false,
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_MODAL_SHOW:
      return {
        ...state,
        currentView: 'form',
        show: true,
        depositSelected:
          action.payload.deposit !== undefined &&
          action.payload.deposit !== null,
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_MODAL_EDIT:
      return {
        ...state,
        currentView: 'edit',
        show: true,
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_NEXT:
      return {
        ...state,
        currentView: action.payload,
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_PDF_VIEWER_CLOSE:
      return {
        ...state,
        showPdfViewer: false,
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_PRINT_SUCCESS:
      return {
        ...state,
        pdf: action.payload,
        showPdfViewer: true,
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_SUBJECT_SET:
      return {
        ...state,
        subject: action.payload,
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_DEPOSIT_SELECTED_CHANGE:
      return {
        ...state,
        depositSelected: action.payload,
      };

    default:
      return state;
  }
}
