import React, { useState } from 'react';
import './VideoThumb.css';
import { drawImageProp } from 'services/utils/images-util';

const WIDTH = 384;
const HEIGHT = 288;

const ImageThumb = (props) => {
  const { image: img, onCreated, ...imageProps } = props;
  const [image, setImage] = useState(null);

  const createHandler = (uri) => {
    if (typeof onCreated === 'function') onCreated(uri);
    setImage(uri);
  };

  if (image) return <img src={image} alt="thumbnail" {...imageProps} />;

  return (
    <img
      src={img}
      alt="thumbnail"
      {...imageProps}
      onLoad={(event) => {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        const originalImage = event.target;

        drawImageProp(ctx, originalImage, 0, 0, WIDTH, HEIGHT, 0.5, 0.5);

        var dataurl = canvas.toDataURL(originalImage.type);
        createHandler(dataurl);
      }}
    />
  );
};

export default ImageThumb;
