import { dataUrlToFile } from 'services/utils/files-util';

/**
 * Create File for all data images passed. The function returns a Promise that will resolve when all
 * images are processed.
 * @param images
 * @return {Promise<any[]>}
 */
export function getFilesFromDataImages(images) {
  let imagesLoaded = [];
  let imageLoaderPromises = images.map((image) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        imagesLoaded.push({
          id: 0,
          file: {
            original: image,
            img: reader.result,
          },
          url: null,
        });
        resolve();
      };
      reader.readAsDataURL(image);
    });
  });

  return new Promise((resolve) => {
    Promise.all(imageLoaderPromises).then(() => {
      resolve(imagesLoaded);
    });
  });
}

export function cropImage(
  imgUri,
  width = 400,
  height = 300,
  xstart = 0,
  ystart = 0,
  callback
) {
  try {
    let resize_canvas = document.createElement('canvas');
    let orig_src = new Image();
    orig_src.src = imgUri;
    orig_src.onload = function () {
      resize_canvas.width = width;
      resize_canvas.height = height;
      let cnv = resize_canvas.getContext('2d');
      cnv.drawImage(
        orig_src,
        xstart,
        ystart,
        width,
        height,
        0,
        0,
        width,
        height
      );
      let newimgUri = resize_canvas.toDataURL('image/png').toString();
      callback(newimgUri);
    };
  } catch (e) {
    callback(imgUri);
  }
}

export function drawImageProp(ctx, img, x, y, w, h, offsetX, offsetY) {
  if (arguments.length === 2) {
    x = y = 0;
    w = ctx.canvas.width;
    h = ctx.canvas.height;
  }

  // default offset is center
  offsetX = typeof offsetX === 'number' ? offsetX : 0.5;
  offsetY = typeof offsetY === 'number' ? offsetY : 0.5;

  // keep bounds [0.0, 1.0]
  if (offsetX < 0) offsetX = 0;
  if (offsetY < 0) offsetY = 0;
  if (offsetX > 1) offsetX = 1;
  if (offsetY > 1) offsetY = 1;

  let iw = img.naturalWidth,
    ih = img.naturalHeight,
    r = Math.min(w / iw, h / ih),
    nw = iw * r, // new prop. width
    nh = ih * r, // new prop. height
    cx,
    cy,
    cw,
    ch,
    ar = 1;

  // decide which gap to fill
  if (nw < w) ar = w / nw;
  if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh; // updated
  nw *= ar;
  nh *= ar;

  // calc source rectangle
  cw = iw / (nw / w);
  ch = ih / (nh / h);

  cx = (iw - cw) * offsetX;
  cy = (ih - ch) * offsetY;

  // make sure source rectangle is valid
  if (cx < 0) cx = 0;
  if (cy < 0) cy = 0;
  if (cw > iw) cw = iw;
  if (ch > ih) ch = ih;

  // fill image in dest. rectangle
  ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
}

export function getImageThumbnail(imgUri, width, height) {
  const promise = new Promise((resolve) => {
    thumbnailImage(imgUri, width, height, (uri) => {
      resolve(uri);
    });
  });

  return promise.then((uri) => uri).catch(() => imgUri);
}

export function thumbnailImage(imgUri, width = 400, height = 300, callback) {
  try {
    let canvas = document.createElement('canvas');
    let originalImage = new Image();
    originalImage.src = imgUri;
    originalImage.onload = function () {
      canvas.width = width;
      canvas.height = height;
      let ctx = canvas.getContext('2d');

      drawImageProp(ctx, originalImage, 0, 0, width, height, 0.5, 0.5);
      const uri = canvas.toDataURL(originalImage.type).toString();

      callback(uri);
    };
  } catch (e) {
    callback(imgUri);
  }
}

export async function previewImage(
  file,
  previewWidth = 400,
  previewHeight = 300
) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      try {
        const img = new Image();
        img.onload = async () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          const ratio = img.width / img.height;

          let newWidth = previewWidth;
          let newHeight = previewHeight;

          if (ratio > 1) newHeight = Math.round(newWidth / ratio);
          else newWidth = Math.round(newHeight * ratio);

          const scaleX = previewWidth / newWidth;
          const scaleY = previewHeight / newHeight;
          const scale = Math.max(scaleX, scaleY);

          newWidth *= scale;
          newHeight *= scale;

          const originX = (previewWidth - newWidth) / 2;
          const originY = (previewHeight - newHeight) / 2;

          canvas.width = previewWidth;
          canvas.height = previewHeight;

          ctx.drawImage(img, originX, originY, newWidth, newHeight);
          const base64 = canvas.toDataURL(img.type);

          const previewName = `gallery_${file.name}`;
          const previewFile = await dataUrlToFile(base64, previewName);

          resolve({ ok: true, file: previewFile });
        };

        img.src = e.target.result;
      } catch (error) {
        reject({ ok: false, msg: error });
      }
    };

    reader.readAsDataURL(file);
  });
}
export async function imageRotation(
  images,
  imgUri,
  rotationAngle,
  onUpload,
  onRemove,
  onEditImages
) {
  // 1- Get the original image and make a backup
  const imageIndex = images.findIndex((img) => img.url === imgUri);
  const originalImage = images[imageIndex];
  if (imageIndex === -1) {
    console.error('Image not found');
    return images;
  }

  const imageToRotate = originalImage;
  // 2- Rotate Image
  const rotatedImageUrl = await rotateImageAndGetUrl(
    imageToRotate,
    rotationAngle
  );
  const updatedImages = [...images];
  const originalFileName = imageToRotate.url.split('/').pop();
  // 3- Create a file for the image
  const file = await dataURLtoFile(
    rotatedImageUrl,
    `${originalFileName}-rotated.png`
  );
  // 4a  - use a onEdit prop if found. Won't run the rest of the code
  if (onEditImages) {
    // If an editing image function was provided, prioritize it
    updatedImages[imageIndex] = {
      ...updatedImages[imageIndex],
      file,
      id: null,
      url: rotatedImageUrl,
      preview: rotatedImageUrl,
    };
    const toDelete = [];
    if (originalImage.id) toDelete.push(originalImage);
    onEditImages(updatedImages, toDelete);
    return { updatedImages, rotatedImageUrl, file, imageIndex };
  }
  // 4b -  Use the onUpload and onRemoveProps to add the rotated image and delete the
  // original one
  updatedImages.push(file);
  const deleteIndex = updatedImages.findIndex(
    (img) => img.url === originalImage.url
  );

  let finalFile = file;
  finalFile.url = rotatedImageUrl;
  if (onUpload) onUpload([finalFile]);
  if (onRemove) onRemove(deleteIndex);
  return { updatedImages, rotatedImageUrl, file, imageIndex };
}

export async function dataURLtoFile(dataurl, filename) {
  const res = await fetch(dataurl);
  const blob = await res.blob();
  return new File([blob], filename, { type: 'image/png' });
}

async function rotateImageAndGetUrl(imageToRotate, rotationAngle, fileName) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.src = imageToRotate.url;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const maxDimension = Math.max(image.width, image.height);
      canvas.width = canvas.height = maxDimension;

      ctx.translate(maxDimension / 2, maxDimension / 2);
      ctx.rotate((rotationAngle * Math.PI) / 180);
      ctx.drawImage(image, -image.width / 2, -image.height / 2);

      // Use toBlob to get a Blob from the canvas
      canvas.toBlob((blob) => {
        if (blob) {
          // Create a File from the Blob
          const file = new File([blob], fileName, { type: blob.type });
          if (file) resolve(URL.createObjectURL(file));
        } else {
          reject(new Error('Blob creation failed'));
        }
      }, 'image/png');
    };
    image.onerror = reject;
  });
}
