/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {
  faChevronUp,
  faChevronDown,
} from '@fortawesome/fontawesome-free-solid';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import {
  setProjectReportAttr,
  addProjectReportBudgetDescriptionById,
  setProjectReportTableItemAttrByType,
  deleteProjectReportTableItemByType,
  setResolvedStatusByAttr,
  getProjectReportResolveInfoByAttr,
  projectReportResolvedTableFilter,
  setProjectReportTablesOrderChange,
  updateBudgetOrderRequest,
  projectReportRestoreItemByAttr,
  setProjectReportTableMultipleItemsByType,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';
import {
  getProjectReportValue,
  getFieldProjectResolvedInfo,
} from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import { getDarkMode } from 'selectors/theme';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import ProjectReportPermissions from 'permissions/project-report-permissions';
import BudgetTable from './BudgetTable';
import ProjectReportBudgetModal from './ProjectReportBudgetModal';
import ContractInfoForm from './ContractInfoForm';
import './ReportsForm.css';

const categories = [
  { id: 1, key: 'budget_summary', label: 'Budget Summary' },
  { id: 2, key: 'paid_to_date', label: 'Paid To Date' },
];

class BudgetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownInfo: {},
      showModal: false,
      modalName: 'resolved-remarks',
      budgetTypeId: null,
      typeTable: '',
    };
  }

  componentDidMount() {
    const { projectId, projectReportId, dispatch } = this.props;
    if (projectReportId) {
      dispatch(
        getProjectReportResolveInfoByAttr(projectId, projectReportId, 'budgets')
      );
    }
    dispatch(
      getProjectReportResolveInfoByAttr(projectId, projectReportId, 'remarks')
    );
  }

  handleAttributeChange = (attr, value, item) => {
    this.props.dispatch(
      setProjectReportTableItemAttrByType(item, attr, value, 'budgets')
    );
  };

  handleModalShow = (
    key,
    value,
    typeId = null,
    modalName = '',
    typeTable = ''
  ) => {
    const { projectId, projectReportId, dispatch } = this.props;
    this.setState({
      [key]: value,
      modalName,
      budgetTypeId: typeId,
      typeTable,
    });
    if (key === 'showModal' && value) {
      if (modalName === 'resolved-remarks') {
        dispatch(
          getProjectReportResolveInfoByAttr(
            projectId,
            projectReportId,
            'remarks'
          )
        );
      } else {
        dispatch(
          getProjectReportResolveInfoByAttr(
            projectId,
            projectReportId,
            'budgets'
          )
        );
      }
    }
  };

  handleDropdownClick = (key) => {
    const { dropdownInfo } = this.state;
    this.setState({
      dropdownInfo: {
        ...dropdownInfo,
        [key]: !dropdownInfo[key],
      },
    });
  };

  handleOrderChange = (result, budgetId) => {
    const { projectId, projectReportId, dispatch, isNewReport } = this.props;
    isNewReport
      ? dispatch(setProjectReportTablesOrderChange(result, 'budgets'))
      : dispatch(setProjectReportTablesOrderChange(result, 'budgets')).then(
          () => {
            dispatch(
              updateBudgetOrderRequest(
                projectId,
                projectReportId,
                budgetId,
                this.props.report.budgets,
                result
              )
            );
          }
        );
  };

  getResolvedData = (id) => {
    const { resolvedInfo } = this.props;
    const data =
      resolvedInfo.budgets &&
      resolvedInfo.budgets.filter(
        (item) => item.project_report_budget_category_id === id
      );
    return data || [];
  };

  renderTables = (id) => {
    const {
      report,
      projectId,
      projectReportId,
      dispatch,
      sqFootage,
      missingField,
      darkMode,
      currentUser,
      currentProject,
    } = this.props;
    const { budgets } = report;

    return (
      <BudgetTable
        data={budgets}
        typeId={id}
        onOrderChange={(result) => this.handleOrderChange(result, id)}
        onValueChange={this.handleAttributeChange}
        onResolved={(item) =>
          dispatch(
            setResolvedStatusByAttr(projectId, projectReportId, 'budgets', item)
          )
        }
        onDelete={(item, destroy) =>
          dispatch(deleteProjectReportTableItemByType(item, destroy, 'budgets'))
        }
        onAddRow={(item) =>
          dispatch(addProjectReportBudgetDescriptionById(id, null, item.id))
        }
        onAddMultiple={(first, items) =>
          dispatch(
            setProjectReportTableMultipleItemsByType(first, items, 'budgets')
          )
        }
        sqFootage={sqFootage}
        missingField={missingField}
        darkMode={darkMode}
        status={report.status}
        canDelete={ProjectReportPermissions.canDeleteBudget(
          currentUser,
          currentProject
        )}
        reportId={projectReportId}
      />
    );
  };

  render() {
    const {
      report,
      darkMode,
      dispatch,
      resolvedInfo,
      projectId,
      projectReportId,
    } = this.props;
    const {
      dropdownInfo,
      modalName,
      showModal,
      budgetTypeId,
      typeTable,
    } = this.state;
    const colorChevron = darkMode ? '#D4FB00' : '#00B091';
    const dataForModal = {};
    if (modalName === 'resolved-items') {
      dataForModal.data =
        resolvedInfo.budgets &&
        resolvedInfo.budgets.filter(
          (item) => item.project_report_budget_category_id === budgetTypeId
        );
      dataForModal.type = 'budgets';
      dataForModal.attr = 'description';
    } else {
      dataForModal.data = resolvedInfo.remarks;
      dataForModal.type = 'remarks';
      dataForModal.attr = 'remarks';
    }

    return (
      <div>
        {categories.map((item) => (
          <div
            key={item.key}
            className={classnames({
              'report-form-block': true,
              'non-margin-bottom-form-block': dropdownInfo[item.key],
            })}
          >
            <div className="row-block row-space-between">
              <div>
                <label className="report-form-block-label">{item.label}</label>
                <FontAwesomeIcon
                  icon={!dropdownInfo[item.key] ? faChevronUp : faChevronDown}
                  color={colorChevron}
                  size="1x"
                  onClick={() => this.handleDropdownClick(item.key)}
                />
              </div>
              {!dropdownInfo[item.key] && (
                <div className="row-block">
                  <Button
                    key="project-status-add-item"
                    type={BUTTON_TYPES.LINK}
                    icon={BUTTON_ICONS.PLUS}
                    label="Add Item"
                    onClick={() =>
                      dispatch(addProjectReportBudgetDescriptionById(item.id))
                    }
                  />
                  <div className="buttons-contract-information-separate" />
                  {this.getResolvedData(item.id).length > 0 && (
                    <Button
                      key="project-budget-view-resolved"
                      type={BUTTON_TYPES.LINK}
                      color={BUTTON_COLORS.GREEN}
                      label="View Resolved"
                      onClick={this.handleModalShow.bind(
                        null,
                        'showModal',
                        true,
                        item.id,
                        'resolved-items',
                        item.key
                      )}
                      className="budget-view-resolved"
                    />
                  )}
                </div>
              )}
            </div>
            {!dropdownInfo[item.key] && this.renderTables(item.id)}
          </div>
        ))}

        <div className="report-form-block non-margin-bottom-form-block contract-info-container">
          <ContractInfoForm
            missingField={this.props.missingField}
            isNewReport={this.props.isNewReport}
          />
        </div>

        <div className="report-form-block">
          <div className="row-block row-space-between">
            <div>
              <label className="report-form-block-label">Budget Remarks</label>
              <FontAwesomeIcon
                icon={!dropdownInfo['remarks'] ? faChevronUp : faChevronDown}
                color={darkMode ? '#D4FB00' : '#00B091'}
                size="1x"
                onClick={() => this.handleDropdownClick('remarks')}
              />
            </div>
            {!dropdownInfo['remarks'] && (
              <div className="align-self-start">
                {resolvedInfo.remarks && resolvedInfo.remarks.length > 0 && (
                  <Button
                    key="remarks-view-resolved"
                    type={BUTTON_TYPES.LINK}
                    color={BUTTON_COLORS.GREEN}
                    label="View Resolved"
                    onClick={this.handleModalShow.bind(
                      null,
                      'showModal',
                      true,
                      null,
                      'resolved-remarks'
                    )}
                  />
                )}
              </div>
            )}
          </div>
          {!dropdownInfo['remarks'] && (
            <div>
              <TextareaAutosizeWrapper
                placeholder="Remarks"
                maxLength={500}
                initialValue={report.budget_remarks || ''}
                onChange={(value) =>
                  this.props.dispatch(
                    setProjectReportAttr('budget_remarks', value)
                  )
                }
              />
              <span className="numbers-of-letters">
                {`${
                  report.budget_remarks ? report.budget_remarks.length : 0
                }/500`}
              </span>
            </div>
          )}
        </div>

        <ProjectReportBudgetModal
          data={dataForModal.data}
          show={showModal}
          modalName={modalName}
          onClose={this.handleModalShow.bind(null, 'showModal', false)}
          onSearch={(search) =>
            dispatch(
              projectReportResolvedTableFilter(
                dataForModal.type,
                search,
                dataForModal.attr
              )
            )
          }
          onRestore={(item) =>
            dispatch(
              projectReportRestoreItemByAttr(
                projectId,
                projectReportId,
                'budgets',
                item
              )
            )
          }
          typeTable={typeTable}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    darkMode: getDarkMode(state),
    report: getProjectReportValue(state),
    resolvedInfo: getFieldProjectResolvedInfo(state),
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
  }),
  null
)(BudgetForm);
