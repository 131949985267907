import {
  INIT_PROJECT_EMAIL_MODAL,
  SET_SHOW_EMAIL_MODAL,
  SET_SHOW_CUSTOM_EMAIL_ERROR,
  SET_SHOW_NEW_CUSTOM_EMAIL,
  SET_EMAILS,
  ADD_EMAIL,
  ADD_RECIPIENT,
  SET_SUBJECT,
  SET_MESSAGE,
  SET_RECIPIENTS,
  SET_CUSTOM_EMAIL,
  SET_EMAIL_HISTORY,
} from 'components/admin/projects/details/project-email-modal/store/action-types';
import { showErrorMessage } from 'actions/messages';
import { projectResourceMailerApi } from 'services/api/project-resource-mailer-api';

export function initProjectEmailModal(payload) {
  return {
    type: INIT_PROJECT_EMAIL_MODAL,
    payload: payload,
  };
}

export function setShowEmailModal(value) {
  return {
    type: SET_SHOW_EMAIL_MODAL,
    payload: value,
  };
}

export function setShowCustomEmailError(value) {
  return {
    type: SET_SHOW_CUSTOM_EMAIL_ERROR,
    payload: value,
  };
}

export function setShowNewCustomEmail(value) {
  return {
    type: SET_SHOW_NEW_CUSTOM_EMAIL,
    payload: value,
  };
}

export function setEmails(emails) {
  return {
    type: SET_EMAILS,
    payload: emails,
  };
}

export function addEmail(emails) {
  return {
    type: ADD_EMAIL,
    payload: emails,
  };
}

export function addRecipient(recipient) {
  return {
    type: ADD_RECIPIENT,
    payload: recipient,
  };
}

export function setSubject(subject) {
  return {
    type: SET_SUBJECT,
    payload: subject,
  };
}

export function setMessage(message) {
  return {
    type: SET_MESSAGE,
    payload: message,
  };
}

export function setRecipients(recipients) {
  return {
    type: SET_RECIPIENTS,
    payload: recipients,
  };
}

export function setCustomEmail(email) {
  return {
    type: SET_CUSTOM_EMAIL,
    payload: email,
  };
}

function loadEmailHistorySuccess(history) {
  return {
    type: SET_EMAIL_HISTORY,
    payload: history,
  };
}

export function loadEmailHistory(id, type) {
  return (dispatch) => {
    return projectResourceMailerApi
      .getEmailHistory(id, type)
      .then((response) => {
        if (response.ok) {
          dispatch(loadEmailHistorySuccess(response.data));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}
