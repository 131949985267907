import { createSelector } from 'reselect';

export const getSubmittalsCount = createSelector(
  (state) => state.submittals.data.submittalsCount,
  (submittalsCount) => {
    return submittalsCount;
  }
);

export const getSubmittals = createSelector(
  (state) => state.submittals.data.submittals,
  (submittals) => {
    return submittals;
  }
);

export const getCurrentStatus = createSelector(
  (state) => state.submittals.data.currentStatus,
  (currentStatus) => {
    return currentStatus;
  }
);

export const getTotal = createSelector(
  (state) => state.submittals.data.total,
  (total) => {
    return total;
  }
);

export const getCurrentPage = createSelector(
  (state) => state.submittals.data.currentPage,
  (currentPage) => {
    return currentPage;
  }
);

export const getPageSize = createSelector(
  (state) => state.submittals.data.pageSize,
  (pageSize) => {
    return pageSize;
  }
);
