import React from 'react';
import PropTypes from 'prop-types';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';

export default function FloorButton(props) {
  const handleClick = () => {
    if (props.onClick && !props.disabled) {
      props.onClick();
    }
  };

  return props.isForAdd ? (
    <Button
      className="add-floors-button"
      type={BUTTON_TYPES.LINK}
      icon={BUTTON_ICONS.PLUS}
      disabled={props.disabled}
      label="Add Floor"
      onClick={handleClick}
    />
  ) : (
    <Button
      className="add-floors-button"
      type={BUTTON_TYPES.LINK}
      color={BUTTON_COLORS.GREEN}
      disabled={props.disabled}
      label="Remove Floor"
      onClick={handleClick}
    />
  );
}

FloorButton.propTypes = {
  disabled: PropTypes.bool,
  isForAdd: PropTypes.bool,
  onClick: PropTypes.func,
};

FloorButton.defaultProps = {
  disabled: false,
  isForAdd: false,
};
