import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import ProjectReportPermissions from 'permissions/project-report-permissions';
import Document from 'domain/document';
import { getDarkMode } from 'selectors/theme';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import {
  getReportMetrics,
  getReports,
  getPhotos,
  getSharedAlbums,
  getSharedFolders,
  getLoaded,
} from './store/selectors';
import { loadData, clearData } from './store/actions';
import Photos from './Photos';
import Budget from './Budget';
import ProjectReports from './ProjectReports';
import ConstructionComplete from './ConstructionComplete';
import ProjectCompletion from './ProjectCompletion';
import CurrentBudget from './CurrentBudget';
import ConstructionCurrentBudget from './ConstructionCurrentBudget';
import SharedFolders from './SharedFolders';
import PaidToDate from './PaidToDate';
import ConstructionPaidToDate from './ConstructionPaidToDate';
import './ProjectDashboard.css';

const PROJECT_REPORTS_COUNT = 3;
const PHOTOS_COUNT = 10;

const ProjectDashboard = (props) => {
  const { currentProject, currentUser, loaded, darkMode, dispatch } = props;

  useEffect(() => {
    if (!currentProject) return;

    const canShowReports = ProjectReportPermissions.canView(
      currentUser,
      currentProject
    );
    const hasAdvancedDocuments = Document.hasAdvancedDocuments(currentProject);

    dispatch(
      loadData(
        currentProject.id,
        canShowReports,
        hasAdvancedDocuments,
        PROJECT_REPORTS_COUNT,
        PHOTOS_COUNT
      )
    );

    return () => {
      dispatch(clearData());
    };
  }, [currentProject]);

  return (
    loaded && (
      <div
        className={classnames('project-dashboard', { 'dark-mode': darkMode })}
      >
        <Photos />
        <Budget />
        <ProjectReports />
        <ConstructionComplete />
        <ProjectCompletion />
        <CurrentBudget />
        <PaidToDate />
        <SharedFolders />
        <ConstructionCurrentBudget />
        <ConstructionPaidToDate />
      </div>
    )
  );
};

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    currentUser: getCurrentUser(state),
    reportMetrics: getReportMetrics(state),
    reports: getReports(state),
    photos: getPhotos(state),
    sharedAlbums: getSharedAlbums(state),
    sharedFolders: getSharedFolders(state),
    loaded: getLoaded(state),
    darkMode: getDarkMode(state),
  };
})(ProjectDashboard);
