import User from 'domain/user';

class ProjectPermissions {
  canViewMyProjects = (user) => User.isCompany(user);

  canCreate = (user) => User.isAdminOrSubscriber(user);

  canEdit = (user, project) => User.isAdminOrProjectSubscriber(user, project);

  canEditDescription = (user, project) =>
    User.isAdminOrProjectSubscriber(user, project);

  canEditLogo = (user, project) =>
    User.isAdminOrProjectSubscriber(user, project);

  canEditStatusDetails = (user, project) =>
    User.isAdminOrProjectSubscriber(user, project);
}

export default new ProjectPermissions();
