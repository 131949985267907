import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentStage } from './store/selectors';
import { newOnboarding } from './store/actions';
import CompanyOnboardingLicenceNumberInput from './CompanyOnboardingLicenceNumberInput';
import CompanyOnboardingLogoUpload from './CompanyOnboardingLogoUpload';
import { getDarkMode } from 'selectors/theme';
import './CompanyOnboarding.css';

class CompanyOnboarding extends Component {
  constructor(props) {
    super(props);

    const { currentUser } = this.props;
    props.dispatch(newOnboarding(currentUser.accountable_id));
  }

  renderCurrentStage = () => {
    let stage;

    switch (this.props.currentStage) {
      case 'licence-input':
        stage = <CompanyOnboardingLicenceNumberInput />;
        break;

      case 'logo-upload':
        stage = <CompanyOnboardingLogoUpload />;
        break;

      default:
        stage = null;
    }

    return stage;
  };

  render() {
    const { darkMode } = this.props;

    return (
      <div className={`company-onboarding ${darkMode ? 'dark-mode' : ''}`}>
        {this.renderCurrentStage()}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentStage: getCurrentStage(state),
    darkMode: getDarkMode(state),
  };
})(CompanyOnboarding);
