import { camelize } from 'services/utils/text-util';

const keys = {};

export const ProjectStatusSorted = [];

export class ProjectStatusEnum {
  constructor(status, label, category, isClosed) {
    this.status = status;
    this.label = label;
    this.category = category;
    this.isClosed = isClosed;
    this.subStatuses = [];

    keys[status] = this;
    ProjectStatusSorted.push(this);
    if (this.category) {
      this.category.subStatuses.push(this);
    }
  }

  static create(status) {
    if (keys[status]) {
      return keys[status];
    }

    return ProjectStatusEnum.UNKNOWN;
  }

  equals(projectStatusEnum) {
    return this.status === projectStatusEnum.status;
  }

  isCategory() {
    return this.subStatuses.length > 0;
  }

  isSubStatus() {
    return !!this.category;
  }

  getTotalKey(category = false) {
    return (
      'total' +
      camelize(this.status.replace(/_/g, ' ')) +
      (category && this.isCategory() ? 'Category' : '')
    );
  }
}

ProjectStatusEnum.PLANNING = new ProjectStatusEnum('planning', 'Planning');
ProjectStatusEnum.DESIGN = new ProjectStatusEnum('design', 'Design');
ProjectStatusEnum.PRECON = new ProjectStatusEnum('precon', 'Preconstruction');
ProjectStatusEnum.IN_PURSUIT = new ProjectStatusEnum(
  'in_pursuit',
  'In Pursuit',
  ProjectStatusEnum.PRECON
);
ProjectStatusEnum.ON_HOLD = new ProjectStatusEnum(
  'on_hold',
  'On Hold',
  ProjectStatusEnum.PRECON
);
ProjectStatusEnum.PENDING = new ProjectStatusEnum(
  'pending',
  'Estimating',
  ProjectStatusEnum.PRECON
);
ProjectStatusEnum.PROCUREMENT = new ProjectStatusEnum(
  'procurement',
  'Procurement',
  ProjectStatusEnum.PRECON
);
ProjectStatusEnum.IN_PROGRESS = new ProjectStatusEnum(
  'in_progress',
  'Construction'
);
ProjectStatusEnum.POST_CONSTRUCTION = new ProjectStatusEnum(
  'post_construction',
  'Post Construction'
);
ProjectStatusEnum.CLOSEOUT = new ProjectStatusEnum(
  'closeout',
  'Closeout',
  ProjectStatusEnum.POST_CONSTRUCTION
);
ProjectStatusEnum.WARRANTY = new ProjectStatusEnum(
  'warranty',
  'Warranty',
  ProjectStatusEnum.POST_CONSTRUCTION
);
ProjectStatusEnum.COMPLETE = new ProjectStatusEnum(
  'complete',
  'Complete',
  null,
  true
);
ProjectStatusEnum.CANCELLED = new ProjectStatusEnum(
  'cancelled',
  'Canceled',
  null,
  true
);
ProjectStatusEnum.LOST = new ProjectStatusEnum('lost', 'No Award', null, true);
/* ProjectStatusEnum.ARCHIVED_CATEGORY = new ProjectStatusEnum(
  'archived_project',
  'Archived'
); */
ProjectStatusEnum.ARCHIVED = new ProjectStatusEnum('archived', 'Archived');
