import {
  WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_FORM_INVALID,
  WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_FORM_VALID,
  WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_FORM_VALUE_UPDATE,
  WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_SHOW,
  WORK_ORDERS_DETAILS_REVIEW_QUOTE_MODAL_SHOW,
} from 'actions/admin/work-orders/action-types';
import numeral from 'numeral';

const initialState = {
  form: {},
};

export default function createQuoteDataReducer(state = initialState, action) {
  let newForm;

  switch (action.type) {
    case WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_SHOW:
      return {
        ...state,
        form: {
          startDate: {
            value: '',
            valid: true,
          },
          duration: {
            value: null,
            valid: true,
          },
          cost: {
            value: '',
            valid: true,
          },
          materialLeadDays: {
            value: '',
            valid: true,
          },
          description: {
            value: '',
          },
        },
      };

    case WORK_ORDERS_DETAILS_REVIEW_QUOTE_MODAL_SHOW:
      return {
        ...state,
        form: {
          startDate: {
            value: action.payload.start_date,
            valid: true,
          },
          duration: {
            value: numeral(action.payload.days_of_duration).value(),
            valid: true,
          },
          cost: {
            value:
              action.payload.status === 'received' &&
              action.payload.counter_status === 'negotiating'
                ? action.payload.counter
                : action.payload.estimate,
            valid: true,
          },
          materialLeadDays: {
            value:
              action.payload.material_lead_days !== null
                ? numeral(action.payload.material_lead_days).value()
                : '',
            valid: true,
          },
          description: {
            value:
              action.payload.description !== null
                ? action.payload.description
                : '',
          },
        },
      };

    case WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_FORM_VALUE_UPDATE:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.attribute]: {
            value: action.payload.value,
            valid: true,
          },
        },
      };

    case WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_FORM_INVALID:
      newForm = {
        ...state.form,
      };
      action.payload.forEach((attribute) => (newForm[attribute].valid = false));

      return {
        ...state,
        form: newForm,
      };

    case WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_FORM_VALID:
      newForm = {
        ...state.form,
      };
      Object.keys(newForm).forEach((key) => (newForm[key].valid = true));

      return {
        ...state,
        form: newForm,
      };

    default:
      return state;
  }
}
