import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormModal from 'components/shared/modal/FormModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import DocumentLink from 'components/shared/document/DocumentLink';
import { getDarkMode } from 'selectors/theme';
import './MoveFolderOrFileModal.css';
import AWS from 'aws-sdk';

const s3 = new AWS.S3();

class MoveFolderOrFileModal extends Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    prefix: PropTypes.string.isRequired,
    selectedObject: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      files: [],
      selectedFolder: null,
    };
  }

  componentDidMount = () => {
    this.fetchFolders();
  };

  fetchFolders = () => {
    var params = {
      Bucket: this.props.bucket,
      Prefix: this.props.prefix,
      Delimiter: this.props.delimiter,
    };

    var scope = {
      Contents: [],
      CommonPrefixes: [],
      params: params,
    };

    scope.cb = this.updateFilesDisplayed;
    return s3.makeUnauthenticatedRequest(
      'listObjectsV2',
      scope.params,
      this.updateFilesDisplayed(scope)
    );
  };

  updateFilesDisplayed = (scope) => (err, data) => {
    const { selectedObject } = this.props;
    if (data) {
      var files = data.Contents;
      const directoryDepth = this.props.prefix.split('/').length + 1;

      files = files.concat(data.CommonPrefixes);
      if (scope.params.Prefix) {
        // filter out folder if prefix
        files = files.filter((file) => file.Key !== scope.params.Prefix);
        files = files.filter((file) => file.Key.endsWith('/'));
        // ensure directories displayed are the correct depth
        files = files.filter(
          (file) => file.Key.split('/').length === directoryDepth
        );
        files = files.filter((file) => file.Key !== selectedObject);
      }

      this.setState({ files });
    }
  };

  folderNameFromPath = (path) => {
    const parts = path.split('/');
    const name = `${parts[parts.length - 2]}/`;
    return name;
  };

  fileNameFromPath = (path) => {
    return this.htmlEscape(path.replace(/^.*[\\/]/, ''));
  };

  objectDisplayName = (object) => {
    return object.endsWith('/')
      ? this.folderNameFromPath(object)
      : this.fileNameFromPath(object);
  };

  htmlEscape = (str) => {
    return str
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/\//g, '&#x2F;')
      .replace(/`/g, '&#x60;')
      .replace(/=/g, '&#x3D;');
  };

  selectFolder = (file) => {
    this.setState({ selectedFolder: file.Key });
  };

  folderSelected = (file) => {
    return file.Key === this.state.selectedFolder ? 'selected' : '';
  };

  handleSubmit = () => {
    var values = {
      selectedObject: this.props.selectedObject,
      selectedDestination: this.state.selectedFolder,
      parentFolder: this.props.prefix,
    };
    this.props.onSubmit(values);
  };

  render() {
    const { showModal, onClose, selectedObject } = this.props;
    const { files, selectedFolder } = this.state;
    return (
      showModal && (
        <FormModal
          className="move-folder-modal"
          show={showModal}
          onClose={onClose}
          subTitle="Move Folder/File"
        >
          <div className="row move-folder-modal-container">
            <div className="col-lg-12">
              <div className="panel">
                <div className="panel-heading clearfix">
                  <div className="row">
                    <span className="small-bold">Selected:</span>
                    <span className="small-regular">
                      {this.objectDisplayName(selectedObject)}
                    </span>
                  </div>
                </div>
                <div className="panel-body">
                  <table className="table table-hover">
                    <tbody>
                      {files.map((file, index) => (
                        <tr key={index} className={this.folderSelected(file)}>
                          <td onClick={() => this.selectFolder(file)}>
                            <DocumentLink
                              file={{
                                name: this.folderNameFromPath(file.Key),
                                folder: true,
                              }}
                              readOnly={true}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="buttons-container">
                <Button
                  type={BUTTON_TYPES.CONFIRMATION}
                  color={BUTTON_COLORS.WHITE}
                  label="Cancel"
                  onClick={onClose}
                />
                <Button
                  type={BUTTON_TYPES.CONFIRMATION}
                  color={BUTTON_COLORS.GREEN}
                  disabled={!selectedFolder}
                  label="Move"
                  onClick={this.handleSubmit}
                />
              </div>
            </div>
          </div>
        </FormModal>
      )
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(MoveFolderOrFileModal);
