import {
  TIME_ADD_ENTRY_SUCCESS,
  TIME_RATE_CONFIRM_SUCCESS,
  TIME_REMOVE,
  TIME_SET,
  TIME_UPDATE,
} from 'components/admin/work-orders/details/time/store/action-types';
import { WORK_ORDERS_DETAILS_STATE_INIT } from 'actions/admin/work-orders/action-types';

const initialState = {
  time: [],
};

export default function timeDataReducer(state = initialState, action) {
  switch (action.type) {
    case TIME_ADD_ENTRY_SUCCESS:
      return {
        ...state,
        time: state.time.concat([action.payload]),
      };

    case TIME_RATE_CONFIRM_SUCCESS:
      return {
        time: state.time.map((timeEntry) => {
          if (timeEntry.id === action.payload.id) {
            return action.payload;
          }
          return timeEntry;
        }),
      };

    case TIME_REMOVE:
      return {
        ...state,
        time: state.time.filter((time) => time.id !== action.payload),
      };

    case TIME_SET:
      return {
        ...state,
        time: action.payload,
      };

    case TIME_UPDATE:
      return {
        ...state,
        time: state.time.map((time) => {
          if (time.id === action.payload.id) {
            return action.payload;
          }
          return time;
        }),
      };

    case WORK_ORDERS_DETAILS_STATE_INIT:
      return {
        ...state,
        time: initialState.time,
      };

    default:
      return state;
  }
}
