import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { useInterval } from 'react-use';
import EmptyImageIcon from '@mui/icons-material/Image';

import { getDarkMode } from 'selectors/theme';

import { securityCamerasApi } from 'services/api/security-cameras-api';

import './SecurityCamera.css';

const CAMERA_REFRESH_INTERVAL = 500;

const SecurityCamera = ({
  projectId,
  camera: { id: cameraId, channel, name, description },
  autoreload,
  onClick,
  darkMode,
}) => {
  const [loadingPicture, setLoadingPicture] = useState(null);
  const [picture, setPicture] = useState(null);
  const isMounted = useRef(false);

  const loadPicture = () => {
    if (loadingPicture) return;

    setLoadingPicture(true);
    securityCamerasApi.picture(projectId, cameraId).then((response) => {
      if (!isMounted.current) return;

      setLoadingPicture(false);
      if (response.picture) setPicture(response.picture.picture);
    });
  };

  useEffect(() => {
    isMounted.current = true;
    loadPicture();

    return () => {
      isMounted.current = false;
    };
  }, []);

  useInterval(() => {
    if (autoreload) loadPicture();
  }, CAMERA_REFRESH_INTERVAL);

  return (
    <div
      className={classnames('security-camera', {
        'dark-mode': darkMode,
      })}
      style={{
        backgroundImage: picture
          ? `url(data:image/png;base64,${picture})`
          : null,
      }}
      onClick={onClick}
    >
      {!picture && <EmptyImageIcon className="loading-camera" />}
      <div
        className={classnames('security-camera-metadata', {
          'without-camera': !picture,
        })}
      >
        <div className="security-camera-name">
          {name} {channel !== name ? ` (${channel})` : null}
        </div>
        <div className="security-camera-description">{description}</div>
      </div>
    </div>
  );
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(SecurityCamera);
