import { INITIAL_FORM } from 'domain/project-photo';

export const isMixedForm = (form) => form.ids.length > 1;

export const separateAlbumsFromPhotos = (ids) => {
  const albumIds = [];
  const photoIds = [];

  ids.forEach(({ id, type }) => {
    if (type === 'album' || type === 'current') albumIds.push(id);
    else photoIds.push(id);
  });

  return { albumIds, photoIds };
};

export const itemDataToForm = (item, type, configuration = false) => {
  const res = { ...INITIAL_FORM };
  const isAlbum = type === 'album';

  if (item.id) res.ids = [{ id: item.id, type }];
  if (item.title) res.title = item.title;
  if (item.description) res.description = item.description;

  res.tags = item.project_tags
    ? item.project_tags.map(({ id: value, label }) => ({
        value,
        label,
      }))
    : [];

  res.sites = item.project_sites
    ? item.project_sites.map(({ id, name, number }) => {
        let label = name;
        if (number && number !== '0') label = `#${number} - ${label}`;

        return { value: id, label };
      })
    : [];

  res.areas = item.project_areas
    ? item.project_areas.map(
        ({ id, name, project_site_number, project_site_name }) => {
          let label = name;
          if (project_site_number && project_site_number !== '0') {
            label += ` - #${project_site_number} - ${project_site_name}`;
          } else label += ` - ${name}`;

          return { value: id, label };
        }
      )
    : [];

  res.trades = item.csi_codes
    ? item.csi_codes.map((t) => ({
        value: t.id,
        label: `${t.division} - ${t.label}`,
      }))
    : [];

  res.users = item.users || [];

  if (item.created_by) res.createdBy = item.created_by;
  if (item.created_at) res.createdAt = item.created_at;

  res.permission = configuration ? item.album_permission : item.permission;
  res.permissionUsers =
    (configuration ? item.album_permission_users : item.permission_users) || [];

  if (isAlbum && item.cover) {
    res.cover = { id: item.cover.id, url: item.cover.image.url };
  }

  res.status = item.status;
  res.likes = item.likes || [];
  res.postId = item.project_post_id;
  res.comments = item.comments || [];

  if (isAlbum && !configuration) res.count_photos = item.count_photos || 0;

  return res;
};

const auxFilterMultiple = (items1, items2) => {
  return items1.filter((i1) => items2.find((i2) => i1.id === i2.id));
};
const auxPermissionMultiple = (item1, item2) => {
  let permission = 'public';
  let permissionUsers = [];

  if (item1.permission === item2.permission) {
    permission = item1.permission;
    if (permission === 'shared') {
      permissionUsers = auxFilterMultiple(
        item1.permissionUsers,
        item2.permissionUsers
      );
    }
  } else if (item1.permission === 'private' || item2.permission === 'private') {
    permission = 'private';
  } else if (item1.permission === 'shared' || item2.permission === 'shared') {
    permissionUsers =
      item1.permission === 'shared'
        ? item1.permissionUsers
        : item2.permissionUsers;
  }

  return { permission, permissionUsers };
};
export const multipleItemsToForm = (items) => {
  if (items.length === 0) return { ...INITIAL_FORM };
  if (items.length === 1) return itemDataToForm(items[0], items[0].type);

  const initial = itemDataToForm(items[0], items[0].type);
  items.shift();

  const res = items.reduce((acc, curr) => {
    acc.ids.push({ id: curr.id, type: curr.type });
    acc.areas = auxFilterMultiple(acc.areas, curr.project_areas);
    acc.sites = auxFilterMultiple(acc.sites, curr.project_sites);
    acc.tags = auxFilterMultiple(acc.tags, curr.project_tags);
    acc.users = auxFilterMultiple(acc.users, curr.users);
    acc.trades = auxFilterMultiple(acc.trades, curr.csi_codes);

    const { permission, permissionUsers } = auxPermissionMultiple(acc, curr);
    acc.permission = permission;
    acc.permissionUsers = permissionUsers;

    acc.status = acc.status !== curr.status ? 'mixed' : acc.status;
    acc.createdBy =
      acc.createdBy && acc.createdBy === curr.created_by ? acc.createdBy : null;

    acc.likes = [];
    acc.postId = null;
    acc.comments = [];

    return acc;
  }, initial);

  return res;
};

const isAlbum = (c) => typeof c.title !== 'undefined';
export const itemsToIds = (items) => {
  return items.map((i) => ({
    id: i.id,
    type: isAlbum(i) ? 'album' : 'photo',
  }));
};
