import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import Input from 'components/shared/input/Input';
import { getLicenceNumber } from './store/selectors';
import { setLicenceNumberValue, nextStage } from './store/actions';
import CompanyOnboardingStatus from './CompanyOnboardingStatus';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

class LicenceNumberInput extends Component {
  handleLicenceNumberChange = (value) => {
    this.props.dispatch(setLicenceNumberValue(value));
  };

  handleContinue = () => {
    this.props.dispatch(nextStage());
  };

  render() {
    return (
      <div className="license-number-input">
        <CompanyOnboardingStatus activeStep={0} />

        <h2 className="onboarding-title">Contractor Licence</h2>

        <p className="add-message">
          Users will be emailed an invite to finish creating their account.
        </p>

        <div className="details-form">
          <Row>
            <Input
              placeholder="Add contractor license (optional)"
              value={this.props.licenceNumber}
              onChange={(value) => this.handleLicenceNumberChange(value)}
              className="onboarding-input"
            />
          </Row>
          <Row>
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Continue"
              onClick={this.handleContinue}
            />
          </Row>
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    licenceNumber: getLicenceNumber(state),
  };
})(LicenceNumberInput);
