import React, { useEffect, useState } from 'react';
import { Drawer } from '@mui/material';
import { connect } from 'react-redux';
import { getRfaFilters, getShowRfaFilters } from './store/selectors';
import { setShowRfaFilters } from './store/actions';
import closePng from 'images/close.png';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import './RfaFilters.css';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import Selector from 'components/shared/selector/Selector';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import DatePicker from 'components/shared/date-picker/DatePicker';
import { rfaTypes, statusFilterOptions } from 'domain/rfa';
import Project from 'domain/project';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import {
  sameOrAfterDateValidation,
  sameOrBeforeTodayValidation,
  sameOrBeforeDateValidation,
} from 'services/utils/date-util';

const RfaFilters = ({
  dispatch,
  handleFilterChange,
  currentFilters,
  show,
  currentProject,
  directory,
}) => {
  const [filters, setFilters] = useState({
    for_record: false,
    accelerate: false,
    by_request_type: null,
    by_submitted_user: [],
    by_response_user: [],
    status: null,
    by_due_to: null,
    by_due_from: null,
    by_submitted_date_to: null,
    by_submitted_date_from: null,
  });

  useEffect(() => {
    if (show) setFilters({ ...currentFilters });
  }, [show]);

  useEffect(() => {
    loadDirectory();
  }, []);

  const loadDirectory = () => {
    if (!directory || directory.project_id !== currentProject.id) {
      dispatch(loadDirectoryRequest(currentProject.id));
    }
  };
  const onFilterChange = (key, value) => {
    setFilters((state) => ({ ...state, [key]: value }));
  };
  const onClose = () => {
    dispatch(setShowRfaFilters(false));
  };
  const onViewResults = () => {
    handleFilterChange(filters);
    onClose();
  };

  return (
    <Drawer open={show} anchor="right" onClose={onClose}>
      <div className="rfa-filters">
        <div className="filters-header">
          <h4>Filter</h4>
          <span className="close-icon">
            <img src={closePng} alt="close" onClick={onClose} />
          </span>
        </div>
        <div className="filters">
          <div className="field line-field">
            <div>
              <LabeledCheckBox
                checked={filters.for_record}
                size="medium"
                label="For Record"
                onChange={(value) => onFilterChange('for_record', value)}
              />
            </div>
            <div>
              <LabeledCheckBox
                checked={filters.accelerate}
                size="medium"
                label="Accelerated"
                onChange={(value) => onFilterChange('accelerate', value)}
              />
            </div>
          </div>
          <div className="field">
            <h3>By Request Type</h3>
            <Selector
              className="select-dropdown"
              placeholder={'Request Type'}
              value={filters.by_request_type}
              onChange={(option) =>
                onFilterChange(
                  'by_request_type',
                  option ? option.value : option
                )
              }
              options={rfaTypes}
              clearable
            />
          </div>
          <div className="field">
            <h3>Submitted By</h3>
            <MultipleDropdown
              onChange={(values) => onFilterChange('by_submitted_user', values)}
              currentValues={filters.by_submitted_user || []}
              className="submitted-dropdown"
              placeholder="User"
              options={Project.recipientOptions({ directory })}
            />
          </div>
          <div className="field">
            <h3>Responded By</h3>
            <MultipleDropdown
              onChange={(values) => onFilterChange('by_response_user', values)}
              currentValues={filters.by_response_user || []}
              className="response-dropdown"
              placeholder="User"
              options={Project.recipientOptions({ directory })}
            />
          </div>
          <div className="field">
            <h3>By Status</h3>
            <Selector
              className="select-dropdown"
              placeholder={'Status'}
              value={filters.status}
              onChange={(option) =>
                onFilterChange('status', option ? option.value : option)
              }
              options={statusFilterOptions.map(({ id: value, label }) => ({
                value,
                label,
              }))}
              clearable
            />
          </div>
          <div className="field">
            <h3>By Submitted Date</h3>
            <div className="line-field">
              <DatePicker
                className="due-date"
                placeholder="From"
                clearable
                value={filters.by_submitted_date_from}
                onChange={(value) =>
                  onFilterChange('by_submitted_date_from', value)
                }
                isValidDate={(date) =>
                  sameOrBeforeDateValidation(
                    date,
                    filters.by_submitted_date_to
                  ) && sameOrBeforeTodayValidation(date)
                }
              />
              <DatePicker
                className="due-date due-to-date"
                placeholder="To"
                clearable
                value={filters.by_submitted_date_to}
                onChange={(value) =>
                  onFilterChange('by_submitted_date_to', value)
                }
                isValidDate={(date) =>
                  sameOrAfterDateValidation(
                    date,
                    filters.by_submitted_date_from
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className="footer">
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.WHITE}
            label="Cancel"
            onClick={onClose}
          />
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label={'View Results'}
            onClick={onViewResults}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default connect((state) => ({
  show: getShowRfaFilters(state),
  currentFilters: getRfaFilters(state),
  directory: getDirectory(state),
  currentProject: getCurrentProject(state),
}))(RfaFilters);
