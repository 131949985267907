import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { showErrorMessage } from 'actions/messages';
import Projects from 'components/admin/projects/Projects';
import NewProject from 'components/admin/projects/creation/NewProject';
import ProjectDetails from 'components/admin/projects/details/ProjectDetails';
import EditProject from 'components/admin/projects/creation/EditProject';

/**
 * Parent container for all projects components/routes. Also catch render errors and redirects to /
 */
class ProjectsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    this.props.dispatch(
      showErrorMessage(
        'Something went wrong with the request, please try again later.'
      )
    );
  }

  render() {
    if (this.state.hasError) {
      return <Redirect to="/" />;
    } else {
      return (
        <React.Fragment>
          <Switch>
            <Route exact path={this.props.match.url} component={Projects} />

            <Route
              exact
              path={`${this.props.match.url}/new-project`}
              component={NewProject}
            />

            <Route
              exact
              path={`${this.props.match.url}/edit/:projectId`}
              component={EditProject}
            />

            {/* REDIRECTS */}
            {/* General redirect route */}
            <Route
              exact
              path={`${this.props.match.url}/:projectId`}
              render={({ history, match }) => {
                history.replace(
                  `${this.props.match.url}/${match.params.projectId}/${ProjectDetails.DETAIL_SECTIONS.HOME}`
                );
                return null;
              }}
            />

            {/* Redirect route for /rfas without subsection */}
            <Route
              exact
              path={`${this.props.match.url}/:projectId/rfas`}
              render={({ match }) => (
                <Redirect
                  to={`${this.props.match.url}/${match.params.projectId}/rfas/rfa`}
                />
              )}
            />

            {/* GENERAL ROUTES */}
            {/* Specific routes for sections/subsections with itemId */}
            <Route
              path={`${this.props.match.url}/:projectId/:section(rfis|rfas)/:subsection/:itemId`}
              component={ProjectDetails}
            />

            {/* Specific routes for reporting with steps */}

            <Route
              path={`${this.props.match.url}/:projectId/:section(reporting)/:subsection/new/:step?`}
              component={ProjectDetails}
            />

            {/* General route */}
            <Route
              path={`${this.props.match.url}/:projectId/:section/:subsection?`}
              component={ProjectDetails}
            />
          </Switch>
        </React.Fragment>
      );
    }
  }
}

export default connect()(ProjectsContainer);
