import {
  INIT_PROJECT_EMAIL_MODAL,
  SET_SHOW_EMAIL_MODAL,
  SET_SHOW_CUSTOM_EMAIL_ERROR,
  SET_SHOW_NEW_CUSTOM_EMAIL,
  SET_EMAILS,
  ADD_EMAIL,
  ADD_RECIPIENT,
  SET_SUBJECT,
  SET_MESSAGE,
  SET_RECIPIENTS,
  SET_CUSTOM_EMAIL,
} from 'components/admin/projects/details/project-email-modal/store/action-types';

export const initialState = {
  showEmailModal: false,
  showCustomEmailError: false,
  showNewCustomEmail: false,
  emails: [],
  subject: '',
  message: '',
  initialMessage: '',
  recipients: [],
  customEmail: '',
};

export default function projectEmailModalUiReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case INIT_PROJECT_EMAIL_MODAL:
      return {
        ...state,
        recipients: [],
        showCustomEmailError: false,
        showNewCustomEmail: false,
        subject: action.payload.subject,
        message: action.payload.message,
        initialMessage: action.payload.initialMessage,
        customEmail: '',
      };
    case SET_SHOW_EMAIL_MODAL:
      return {
        ...state,
        showEmailModal: action.payload,
      };
    case SET_SHOW_CUSTOM_EMAIL_ERROR:
      return {
        ...state,
        showCustomEmailError: action.payload,
      };
    case SET_SHOW_NEW_CUSTOM_EMAIL:
      return {
        ...state,
        showNewCustomEmail: action.payload,
      };
    case SET_EMAILS:
      return {
        ...state,
        emails: action.payload,
      };
    case ADD_EMAIL:
      return {
        ...state,
        emails: [action.payload, ...state.emails],
      };
    case ADD_RECIPIENT:
      return {
        ...state,
        recipients: [action.payload, ...state.recipients],
      };
    case SET_SUBJECT:
      return {
        ...state,
        subject: action.payload,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case SET_RECIPIENTS:
      return {
        ...state,
        recipients: action.payload,
      };
    case SET_CUSTOM_EMAIL:
      return {
        ...state,
        customEmail: action.payload,
      };
    default:
      return state;
  }
}
