import axios from 'axios';
import BaseApi from './base-api';

class CloseoutCatsApi extends BaseApi {
  getCloseoutCats(projectId) {
    let filter = `by_project=${projectId}`;

    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/closeout/closeout_cats?${filter}`
      )
      .then((response) => {
        return {
          ok: true,
          closeoutCats: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createCloseoutCat(projectId, name) {
    let data = {
      label: name,
      project_id: projectId,
    };
    return axios
      .post(`${this.baseUrl}/projects/${projectId}/closeout/closeout_cats`, {
        closeout_cat: data,
      })
      .then((response) => {
        return {
          ok: true,
          closeoutCat: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  deleteCloseoutCat(projectId, closeoutCatId) {
    return axios
      .delete(
        `${this.baseUrl}/projects/${projectId}/closeout/closeout_cats/${closeoutCatId}`
      )
      .then((response) => {
        return {
          ok: true,
          closeoutCat: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateCloseoutCat(projectId, name, id) {
    let data = {
      label: name,
      project_id: projectId,
    };
    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/closeout/closeout_cats/${id}`,
        {
          closeout_cat: data,
        }
      )
      .then((response) => {
        return {
          ok: true,
          closeoutCat: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateCloseoutCatsOrder(projectId, closeoutCats) {
    let data = {
      closeout_cat_ids: closeoutCats.map((cat) => cat.id),
    };
    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/closeout/closeout_cats/sort`,
        {
          closeout_cat: data,
        }
      )
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const closeoutCatsApi = new CloseoutCatsApi();
