import { notificationSettingsApi } from 'services/api/notification-settings-api';
import { showErrorMessage } from 'actions/messages';

import {
  RESET_FORM_NOTIFICATION_SETTINGS,
  SET_FORM_NOTIFICATION_SETTINGS,
  SET_SUBJECTS_NOTIFICATION_SETTINGS,
} from './action-types';

export const setFormNotificationSettings = (form) => ({
  type: SET_FORM_NOTIFICATION_SETTINGS,
  payload: { form },
});

export const resetFormNotificationSettings = () => ({
  type: RESET_FORM_NOTIFICATION_SETTINGS,
});

export const setSubjectsNotificationSettings = (subjects = []) => ({
  type: SET_SUBJECTS_NOTIFICATION_SETTINGS,
  payload: { subjects },
});

export const updateNotificationSetting = (form) => {
  return (dispatch) => {
    return notificationSettingsApi.update(form).then((response) => {
      if (!response.ok) dispatch(showErrorMessage(response.errors));

      return response;
    });
  };
};
