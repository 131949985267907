import moment from 'moment';
import 'moment-precise-range-plugin';
import { toUpperFirstLetter } from 'services/utils/text-util';

/**
 * Get difference between two dates.
 * Dates should be defined in a ISO 8601 format.
 *
 * @param from - From Date
 * @param to - To Date. Optional, if not specified, today is used.
 * @return {{years : number, months : number, days : number, hours : number, minutes : number}}
 */
export function getRange(from, to = null) {
  let toMoment;
  if (to !== null) {
    toMoment = moment.utc(to);
  } else {
    toMoment = moment.utc();
  }

  const fromMoment = moment.utc(from);

  let result = moment.preciseDiff(fromMoment, toMoment, true);
  delete result.firstDateWasLater;
  delete result.seconds;

  return result;
}

const DEFAULT_COUNTDOW_OPTIONS = {
  separator: ' ',
  camelCase: false,
  fullPath: true,
  pluralize: true,
};

export function countdown(from, to = null, options = {}) {
  const { separator, camelCase, fullPath, pluralize } = {
    ...DEFAULT_COUNTDOW_OPTIONS,
    ...options,
  };

  const makeLabel = (date, value) =>
    `${camelCase ? toUpperFirstLetter(date) : date}${
      pluralize && value > 1 ? 's' : ''
    }`;

  const elapsedTime = getRange(from, to);
  const values = [];
  let label = '';
  if (elapsedTime.years > 0) {
    label = makeLabel('year', elapsedTime.years);
    values.push(`${elapsedTime.years}${separator}${label}`);
  }
  if (elapsedTime.months > 0) {
    label = makeLabel('month', elapsedTime.months);
    values.push(`${elapsedTime.months}${separator}${label}`);
  }
  // Only show two attributes from the range value
  if (elapsedTime.days > 0 && values.length < 2) {
    label = makeLabel('day', elapsedTime.days);
    values.push(`${elapsedTime.days}${separator}${label}`);
  }
  if (elapsedTime.hours > 0 && values.length < 2) {
    label = makeLabel('hour', elapsedTime.hours);
    values.push(`${elapsedTime.hours}${separator}${label}`);
  }
  if (elapsedTime.minutes > 0 && values.length < 2) {
    label = makeLabel('minute', elapsedTime.minutes);
    values.push(`${elapsedTime.minutes}${separator}${label}`);
  }

  return fullPath ? values.join(', ') : values[0] || '';
}

/**
 * Get first date form array of dates. Dates should be defined in a ISO 8601 format.
 * @param arrayOfDates
 * @return {string} Date as YYYY-MM-DD format or null if array is empty
 */
export function getFirstDate(arrayOfDates) {
  let min = null;
  if (
    arrayOfDates === undefined ||
    arrayOfDates === null ||
    arrayOfDates.length === 0
  ) {
    return min;
  }

  arrayOfDates.forEach((date) => {
    let dateMoment = moment(date);
    if (min === null || min.isAfter(dateMoment)) {
      min = dateMoment;
    }
  });

  return min.format('YYYY-MM-DD');
}

/**
 * Get last date form array of dates. Dates should be defined in a ISO 8601 format.
 * @param arrayOfDates
 * @return {string} Date as YYYY-MM-DD format or null if array is empty
 */
export function getLastDate(arrayOfDates) {
  let max = null;
  if (
    arrayOfDates === undefined ||
    arrayOfDates === null ||
    arrayOfDates.length === 0
  ) {
    return max;
  }

  arrayOfDates.forEach((date) => {
    let dateMoment = moment(date);
    if (max === null || max.isBefore(dateMoment)) {
      max = dateMoment;
    }
  });

  return max.format('YYYY-MM-DD');
}

const yesterday = moment().subtract(1, 'day');

/**
 * Validate a date against yesterday. To be used to validate dates in DatetimePicker component.
 * @param date
 * @return {*|boolean}
 */
export function todayValidation(date) {
  return date.isAfter(yesterday);
}

/**
 * Validates if a date is same or before maxDate. To be used to validate dates in DatetimePicker component.
 * @param date
 * @param maxDate
 * @return {*|boolean}
 */
export function sameOrBeforeDateValidation(date, maxDate) {
  return maxDate ? date.isSameOrBefore(moment(maxDate)) : true;
}

/**
 * Validates if a date is same or after minDate. To be used to validate dates in DatetimePicker component.
 * @param date
 * @param minDate
 * @return {*|boolean}
 */
export function sameOrAfterDateValidation(date, minDate) {
  return minDate ? date.isSameOrAfter(moment(minDate)) : true;
}

/**
 * Validate a date is before today. To be used to validate dates in DatetimePicker component.
 * @param date
 * @return {*|boolean}
 */
export function beforeTodayValidation(date) {
  return date.isBefore(moment());
}

export function sameOrBeforeTodayValidation(date) {
  return date.isSameOrBefore(moment());
}

/**
 * Get the next date of a given day (from 0 to 6). Eg. The next Tuesday is nextDay(2)
 * @param day
 * @return {string} Date as YYYY-MM-DD format or null if array is empty
 */
export function nextDay(day) {
  const today = moment().isoWeekday();
  let nextD = null;

  if (today <= day) {
    nextD = moment().isoWeekday(day);
  } else {
    nextD = moment().add(1, 'weeks').isoWeekday(day);
  }

  return nextD.format('YYYY-MM-DD');
}

/**
 * Get the previous date of a given day (from 0 to 6). Eg. The previous Tuesday is prevDay(2)
 * @param day
 * @return {string} Date as YYYY-MM-DD format or null if array is empty
 */
export function prevDay(day) {
  const today = moment().isoWeekday();
  let prevD = null;

  if (today >= day) {
    prevD = moment().isoWeekday(day);
  } else {
    prevD = moment().add(-1, 'weeks').isoWeekday(day);
  }

  return prevD.format('YYYY-MM-DD');
}

export function utcTime(datetime) {
  return (
    datetime && moment.utc(datetime, 'YYYY-MM-DD hh:mm A').format('hh:mm A')
  );
}

export function calculateDateDuration(start, completion) {
  let duration = '--';
  if (start && completion) {
    const oneD = moment(start).startOf('day');
    const TwoD = moment(completion).startOf('day');
    const days = TwoD.diff(oneD, 'days');
    const month = TwoD.diff(oneD, 'month', true).toFixed(1);
    duration =
      +month > 1 ? `${month} Months` : `${days} Day${days === 1 ? '' : 's'}`;
  }
  return duration;
}

export function calculateDatesDuration(dates) {
  let nMonth = 0;
  let nDays = 0;
  dates.forEach(({ start, completion }) => {
    if (start && completion) {
      const oneD = moment(start).startOf('day');
      const TwoD = moment(completion).startOf('day');
      const days = TwoD.diff(oneD, 'days');
      const month = TwoD.diff(oneD, 'month', true).toFixed(1);
      nMonth += +month;
      nDays += +days;
    }
  });

  return nDays > 31 ? `${nMonth} Month` : `${nDays} Days`;
}

export function utcTimeToLocalTime(t) {
  if (!t) return null;
  const dateUTC = moment(t).utc();

  const today = moment().utc();
  today.hours(dateUTC.hours());
  today.minutes(dateUTC.minutes());

  const dateLocal = today.clone().local();
  return dateLocal.format('YYYY-MM-DD hh:mm A');
}
export function toICalString(selectedArray) {
  if (!selectedArray || !Array.isArray(selectedArray)) return '';
  const dayMap = {
    monday: 'MO',
    tuesday: 'TU',
    wednesday: 'WE',
    thursday: 'TH',
    friday: 'FR',
    saturday: 'SA',
    sunday: 'SU',
    daily: 'DAILY',
  };

  const isDaily = selectedArray.some(
    (subarray) => subarray[0].toLowerCase() === 'daily'
  );
  if (isDaily) {
    return 'FREQ=DAILY';
  }

  const days = selectedArray
    .map((subarray) => dayMap[subarray[0].toLowerCase()])
    .filter(Boolean);
  return `FREQ=WEEKLY;BYDAY=${days.join(',')}`;
}

export function iCalToReadable(iCalString) {
  if (!iCalString || typeof iCalString !== 'string') return '';
  const dayNameMap = {
    MO: 'Mon',
    TU: 'Tue',
    WE: 'Wed',
    TH: 'Thu',
    FR: 'Fri',
    SA: 'Sat',
    SU: 'Sun',
  };
  if (iCalString === 'FREQ=DAILY') {
    return 'Daily';
  }

  const match = iCalString.match(/FREQ=WEEKLY;BYDAY=(.+)$/);
  if (match && match[1]) {
    const days = match[1]
      .split(',')
      .map((abbr) => dayNameMap[abbr])
      .join(', ');
    return `Weekly: ${days}`;
  }

  return 'Unknown Format';
}

export function icalArrayToString(icalArray) {
  if (!icalArray || !Array.isArray(icalArray) || icalArray.length === 0) {
    return '';
  }

  const isDaily = icalArray.some((item) => item[0].toLowerCase() === 'daily');
  if (isDaily) {
    return 'FREQ=DAILY';
  }

  const dayMapReverse = {
    monday: 'MO',
    tuesday: 'TU',
    wednesday: 'WE',
    thursday: 'TH',
    friday: 'FR',
    saturday: 'SA',
    sunday: 'SU',
  };

  const days = icalArray
    .map((item) => dayMapReverse[item[0].toLowerCase()])
    .filter(Boolean)
    .join(',');

  if (days.length > 0) {
    return `FREQ=WEEKLY;BYDAY=${days}`;
  }

  return '';
}

export function iCalToDropdownArray(iCalString) {
  // Map for converting iCal day abbreviations to full day names and formatted strings
  if (!iCalString) return [];
  const dayMap = {
    MO: ['monday', 'Weekly, Monday'],
    TU: ['tuesday', 'Weekly, Tuesday'],
    WE: ['wednesday', 'Weekly, Wednesday'],
    TH: ['thursday', 'Weekly, Thursday'],
    FR: ['friday', 'Weekly, Friday'],
    SA: ['saturday', 'Weekly, Saturday'],
    SU: ['sunday', 'Weekly, Sunday'],
  };

  if (iCalString === 'FREQ=DAILY') {
    return [['daily', 'Daily']];
  }

  const match = iCalString.match(/FREQ=WEEKLY;BYDAY=(.+)$/);
  if (match && match[1]) {
    return match[1].split(',').map((abbr) => dayMap[abbr]);
  }

  return [];
}

export function isWeekend(date) {
  const dayOfWeek = date.getDay();
  return dayOfWeek === 0 || dayOfWeek === 6; // 0 = Sunday, 6 = Saturday
}

export function isUSHoliday(date) {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  // New Year's Day, Independence Day, Veterans Day, Christmas ... more?
  const newYearsDay = new Date(year, 0, 1); // January 1
  const independenceDay = new Date(year, 6, 4); // July 4
  const veteransDay = new Date(year, 10, 11); // November 11
  const christmasDay = new Date(year, 11, 25); // December 25

  const holidays = [newYearsDay, independenceDay, veteransDay, christmasDay];
  return holidays.some(
    (holiday) =>
      holiday.getDate() === day &&
      holiday.getMonth() === month &&
      holiday.getFullYear() === year
  );
}

function formatDate(date) {
  const month = date.getMonth() + 1; // getMonth() returns 0-11
  const day = date.getDate();
  const year = date.getFullYear();

  return `${padWithZero(month)}/${padWithZero(day)}/${year}`;
}

function padWithZero(number) {
  return number < 10 ? `0${number}` : number;
}

export function getWeekendsAndHolidays(startDate, endDate) {
  const dates = [];
  let currentDate = new Date(startDate);

  const endDateObject = new Date(endDate);

  while (currentDate <= endDateObject) {
    if (isWeekend(currentDate) || isUSHoliday(currentDate)) {
      dates.push(formatDate(currentDate));
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
}
