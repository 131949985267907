import { useMemo, useEffect, useState } from 'react';

import { loadProjectSitesAreas } from 'components/admin/projects/details/project-site-area/store/action';
import { setShowAddProjectSiteModal, submitProjectSite } from './store/action';

const initialForm = {
  name: '',
  number: null,
  duplicateAreas: [],
  siteId: null,
  delete: false,
};

const useAddProjectSiteModal = (props) => {
  const {
    dispatch,
    currentProject,
    projectSites,
    show,
    edit,
    closeCallback,
  } = props;
  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    if (show) setForm(initialForm);
    if (currentProject.id && show) {
      if (!projectSites || projectSites.projectId !== currentProject.id) {
        dispatch(loadProjectSitesAreas(currentProject.id));
      }
    }
  }, [show]);

  const { siteOptions, areaOptions } = useMemo(() => {
    const siteOptions = [];
    const areaOptions = [];

    if (
      projectSites &&
      projectSites.sites &&
      currentProject &&
      projectSites.projectId === currentProject.id
    ) {
      projectSites.sites.forEach((s) => {
        let label = s.name;
        if (s.number && s.number !== '0') label = `#${s.number} - ${label}`;
        siteOptions.push({ value: s.id, label });
      });

      projectSites.areas.forEach((a) => {
        let label = a.name;
        if (a.project_site_number && a.project_site_number !== '0')
          label += ` - #${a.project_site_number} - ${a.project_site_name}`;
        else label += ` - ${a.name}`;
        areaOptions.push({ id: a.id, label });
      });
    }

    return { siteOptions, areaOptions };
  }, [projectSites]);

  const handleClose = (submitted) => {
    dispatch(setShowAddProjectSiteModal(false));
    if (typeof closeCallback === 'function') closeCallback(submitted);
  };
  const handleFormChange = (values) => {
    const result = { ...form, ...values };

    if (values.siteId) {
      if (projectSites && projectSites.sites) {
        const site = projectSites.sites.find((s) => s.id === values.siteId);
        if (site) {
          result.name = site.name;
          result.number = site.number;
        }
      }
    }

    setForm(result);
  };

  const validateForm = () => {
    const errors = {};
    if (!form.delete && (!form.name || form.name.length === 0))
      errors.name = true;
    if (edit && !form.siteId) errors.siteId = true;
    return errors;
  };

  const onSave = () => {
    const data = { ...form };
    delete form.errors;
    data.duplicateAreas = data.duplicateAreas.map((arr) => arr[0]);
    dispatch(submitProjectSite(currentProject.id, data)).then((response) => {
      if (response.ok) handleClose(true);
    });
  };

  const handleSubmit = () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) handleFormChange({ errors });
    else onSave();
  };

  return {
    handleClose,
    handleSubmit,
    handleFormChange,
    form,
    invalidForm: form.errors && Object.keys(form.errors).length > 0,
    siteOptions,
    areaOptions,
  };
};

export default useAddProjectSiteModal;
