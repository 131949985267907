import React from 'react';
import { connect } from 'react-redux';

import ProjectPhotoPermissions from 'permissions/project-photo-permissions';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import FormControlBlock from 'components/shared/form/FormControlBlock';

import { isMixedForm } from 'services/utils/project-photos-util';

import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getProjectPhotosForm } from './store/selectors';
import { setProjectPhotosForm, updateProjectPhotosForm } from './store/actions';

const ProjectPhotosFormDescription = (props) => {
  const { dispatch, form, currentUser, currentProject } = props;
  const mixed = isMixedForm(form);
  const canEdit = ProjectPhotoPermissions.canEdit(
    currentUser,
    currentProject,
    form
  );

  const onDescriptionChange = (description) => {
    if (!canEdit) return;

    dispatch(
      updateProjectPhotosForm(currentProject.id, form.ids, { description })
    );
  };

  const onChange = (description) => {
    dispatch(setProjectPhotosForm({ description }));
  };

  return (
    <FormControlBlock
      hideBottomLabel
      className="form-description"
      label={form.description && 'Description'}
      control={
        <TextareaAutosizeWrapper
          placeholder={mixed ? 'Mixed...' : 'Description'}
          initialValue={form.description}
          readOnly={mixed || !canEdit}
          disabled={mixed || !canEdit}
          className="description-textarea"
          onChange={onChange}
          onBlur={onDescriptionChange}
        />
      }
    />
  );
};

export default connect((state) => ({
  form: getProjectPhotosForm(state),
  currentProject: getCurrentProject(state),
  currentUser: getCurrentUser(state),
}))(ProjectPhotosFormDescription);
