import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import ProjectEmailModal from 'components/admin/projects/details/project-email-modal/ProjectEmailModal';

import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/selectors';
import {
  getProjectSchedulesCurrentCategory,
  getProjectSchedulesTypeEmailModal,
} from './store/selectors';
import { sendEmailScheduleCategory } from './store/actions';

const ProjectScheduleEmailModal = (props) => {
  const { dispatch, show, type, currentCategory, currentProject } = props;

  const handleSendEmail = ({ recipients, subject, message }) => {
    dispatch(
      sendEmailScheduleCategory(
        currentProject.id,
        currentCategory.id,
        recipients,
        subject,
        message,
        type
      )
    );
  };

  const buildEmailModalSubject = () => {
    return `${currentProject.number} ${currentProject.name} - ${
      currentProject.client ? currentProject.client.name : '-'
    } ${currentCategory.name} ${moment().format('MM/DD/YYYY')}`;
  };

  const buildEmailModalMessage = () => {
    let location = '';
    if (
      currentProject.project_location &&
      currentProject.project_location.location
    ) {
      const projectLocation = currentProject.project_location.location;
      location = ` at ${projectLocation.street_address_1}, ${projectLocation.city} ${projectLocation.state}`;
    }

    return `Please find the attached ${currentCategory.name} for Project ${currentProject.name} located at ${location}. If you have any questions, please reach out via chat or email.`;
  };

  if (!show) return null;

  return (
    <ProjectEmailModal
      subTitle={currentProject.name}
      projectId={currentProject.id}
      handleSendEmail={handleSendEmail}
      initialSubject={buildEmailModalSubject()}
      initialMessage={buildEmailModalMessage()}
      itemType="ProjectScheduleCategory"
      itemId={currentCategory.id}
    />
  );
};

export default connect((state) => ({
  show: getShowEmailModal(state),
  type: getProjectSchedulesTypeEmailModal(state),
  currentCategory: getProjectSchedulesCurrentCategory(state),
  currentProject: getCurrentProject(state),
}))(ProjectScheduleEmailModal);
