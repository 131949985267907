import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import vector from './vector.svg';
import vectorDark from './vector-dark.svg';
import remove from './remove.svg';
import removeDark from './remove-dark.svg';
import './MultipleDropdown.css';
import OutsideAlerter from 'components/shared/OutsideAlerter';
import Input from 'components/shared/input/Input';

class MultipleDropdown extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        className: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      })
    ).isRequired,
    currentValues: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    valid: PropTypes.bool,
    search: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    valid: true,
    search: true,
    placeholder: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      showOptions: false,
      filteredOptions: null,
      search: '',
    };
  }

  renderOptionsPopup = () => {
    const { options, currentValues, onChange } = this.props;
    const { filteredOptions } = this.state;

    return (filteredOptions || options).map((opt) => {
      const selected = !!currentValues.find((val) => val[0] === opt.id);
      return (
        <React.Fragment key={opt.id}>
          <button
            type="button"
            className={`button-option${selected ? ' selected' : ''} ${
              opt.className || ''
            }`}
            onClick={() => {
              if (!selected) {
                onChange(currentValues.concat([[opt.id, opt.label]]));
              } else {
                onChange(currentValues.filter((val) => val[0] !== opt.id));
              }
            }}
          >
            {opt.label}
          </button>
          <div className="menu-item-separator"></div>
        </React.Fragment>
      );
    });
  };

  handleToggleOptions = () => {
    this.setState({ showOptions: !this.state.showOptions });
  };

  renderLabel = (value) => {
    if (value[1]) {
      return value[1];
    } else {
      const { options } = this.props;
      const selectedOption = options.find((opt) => opt.id === value[0]);
      return selectedOption && selectedOption.label;
    }
  };

  selectedLabels = () => {
    const { currentValues, darkMode } = this.props;
    return (
      <div className="label-preview-container">
        {currentValues.map((value, index) => (
          <div key={index} className="label-preview-item">
            <span className="label-preview-item-text">
              {this.renderLabel(value)}
            </span>
            <img
              className="label-preview-item-remove"
              src={darkMode ? removeDark : remove}
              alt="remove"
              onClick={(event) => this.removeSelection(event, value)}
            />
          </div>
        ))}
      </div>
    );
  };

  removeSelection = (event, value) => {
    const { onChange, currentValues } = this.props;

    event.stopPropagation();
    const newValues = currentValues.filter((val) => val[0] !== value[0]);
    onChange(newValues);
  };

  allowSearching = () => {
    const { options, search } = this.props;
    return search && options.every((opt) => typeof opt.label === 'string');
  };

  handleSearchChange = (value) => {
    const { options } = this.props;
    const filter = new RegExp(value.toLowerCase(), 'g');
    const newFilteredOptions = [
      ...options.filter((opt) => filter.test(opt.label.toLowerCase())),
    ];
    this.setState({ filteredOptions: newFilteredOptions, search: value });
  };

  render() {
    const {
      options,
      placeholder,
      className,
      dropdownClassName,
      currentValues,
      valid,
      darkMode,
    } = this.props;
    const { showOptions, search, filteredOptions } = this.state;
    const allowSearching = this.allowSearching();
    return (
      <div
        className={`multiple-dropdown${className ? ` ${className}` : ''} ${
          darkMode ? ' dark-mode' : ''
        } ${!valid ? 'invalid' : ''}`}
      >
        <div
          className={`dropdown-control ${
            dropdownClassName ? dropdownClassName : 'default-dropdown-control'
          }`}
          onClick={this.handleToggleOptions}
        >
          {currentValues.length > 0 ? (
            this.selectedLabels()
          ) : (
            <div className="dropdown-control-placeholder">{placeholder}</div>
          )}
          <img
            className="dropdown-menu-selector-arrow"
            src={darkMode ? vectorDark : vector}
            alt="arrow"
          />
        </div>
        {showOptions && (
          <OutsideAlerter onClickOutside={this.handleToggleOptions}>
            <div className="options">
              {allowSearching && (
                <Input
                  placeholder="Search..."
                  value={search}
                  onChange={this.handleSearchChange}
                />
              )}
              {(filteredOptions || options).length > 0 ? (
                <div className="options-buttons">
                  {this.renderOptionsPopup()}
                </div>
              ) : (
                <div className="no-options">No results found</div>
              )}
            </div>
          </OutsideAlerter>
        )}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(MultipleDropdown);
