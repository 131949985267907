const LOCAL_STORAGE_KEY = 'spkl_accclem';
const LOCAL_STORAGE_KEY_ACCOUNTS = 'spkl_accounts';

class AuthenticationStorage {
  static setCredentials(accessToken, client, user) {
    const localObject = {
      accessToken,
      client,
      user,
    };
    this.setLocalStorageItem(LOCAL_STORAGE_KEY, localObject);
  }

  static setAccounts(accessToken, client, user) {
    const accounts = this.getAccounts();
    const localObject = [
      ...accounts.filter((acc) => acc.user.id !== user.id),
      {
        accessToken,
        client,
        user,
      },
    ];
    this.setLocalStorageItem(LOCAL_STORAGE_KEY_ACCOUNTS, localObject);
    return localObject;
  }

  static getCredentials() {
    return this.retrieveLocalStorageItem(LOCAL_STORAGE_KEY);
  }

  static initializeAccounts() {
    const accounts = [this.getCredentials()].filter((item) => !!item);
    this.setLocalStorageItem(LOCAL_STORAGE_KEY_ACCOUNTS, accounts);
    return accounts;
  }

  static getAccounts() {
    const accounts = this.retrieveLocalStorageItem(LOCAL_STORAGE_KEY_ACCOUNTS);
    return accounts ? accounts : this.initializeAccounts();
  }

  static clearCredentials() {
    try {
      window.localStorage.removeItem(LOCAL_STORAGE_KEY);
    } catch (e) {
      console.log(e);
    }
  }

  static clearCurrentAccount() {
    const creds = this.getCredentials();
    const newAccounts = this.getAccounts().filter(
      (acc) => acc.user.id !== (creds && creds.user.id)
    );
    this.setLocalStorageItem(LOCAL_STORAGE_KEY_ACCOUNTS, newAccounts);
  }

  static setLocalStorageItem(key, object) {
    try {
      const json = JSON.stringify(object);
      const encoded = window.btoa(json);
      window.localStorage.setItem(key, encoded);
    } catch (e) {
      console.log(e);
    }
  }

  static removeLocalStorageItem(key) {
    try {
      window.localStorage.removeItem(key);
    } catch (e) {
      console.log(e);
    }
  }

  static retrieveLocalStorageItem(key) {
    let result = null;

    const encoded = window.localStorage.getItem(key);
    if (encoded !== null) {
      try {
        const decoded = window.atob(encoded);
        result = JSON.parse(decoded);
      } catch (e) {
        console.log(e);
      }
    }

    return result;
  }
}

export default AuthenticationStorage;
