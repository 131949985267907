import React from 'react';
import PropTypes from 'prop-types';
import { change } from 'redux-form';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import { validateFile } from 'services/utils/files-util';
import removeSvg from 'images/remove.svg';

class FormImageUpload extends React.Component {
  static propTypes = {
    multiple: PropTypes.bool,
    extensions: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    multiple: false,
    extensions: [],
  };

  state = {
    images: [],
    imageIndexToRemove: 0,
    showConfirmation: false,
  };

  render() {
    const {
      multiple,
      input: { value: omitValue, onChange, onBlur, ...inputProps },
      meta: omitMeta,
      ...props
    } = this.props;

    const { images } = this.state;

    return (
      <div className="image-upload">
        <div className="preview-container">
          {this.renderImages(images)}
          <ConfirmationModal
            show={this.state.showConfirmation}
            message="Are you sure?"
            onHide={this.handleRemove}
          />
        </div>
        <div className="image-upload-button-container">
          <div>
            {this.renderButton()}
            <input
              onChange={this.adaptFileEvent(onChange)}
              ref={(input) => {
                this.fileUpload = input;
              }}
              type="file"
              style={{ display: 'none' }}
              multiple={multiple}
              accept={this.props.extensions.join(', ')}
              {...inputProps}
              {...props}
            />
          </div>
        </div>
      </div>
    );
  }

  renderButton = () => {
    const button = (
      <Button
        type={BUTTON_TYPES.LINK}
        icon={BUTTON_ICONS.PLUS}
        label="Add photo"
        onClick={this.handleAddPhotoClick}
      />
    );

    if (this.props.multiple || this.state.images.length === 0) {
      return button;
    }

    return null;
  };

  renderImages = (images) => {
    if (images.length > 0) {
      return images.map((image, index) => {
        if (image.preview) {
          return (
            <div key={index}>
              <img src={image.preview} alt={image.name} />
              <div className="remove-container">
                <img
                  src={removeSvg}
                  alt=""
                  onClick={this.handleRemoveRequest.bind(this, index)}
                />
              </div>
            </div>
          );
        }
        return null;
      });
    }
    return null;
  };

  adaptFileEvent = (delegate) => (e) => {
    const { files } = e.target;
    let newFiles = [...this.state.images];

    for (let i = 0; i < files.length; i++) {
      if (validateFile(files.item(i).name, this.props.extensions)) {
        const imagefile = files.item(i);
        const previewUrl = URL.createObjectURL(imagefile);
        imagefile.preview = previewUrl;
        newFiles.push(imagefile);
      }
    }

    this.setState({
      images: newFiles,
    });

    this.fileUpload.value = '';

    delegate(newFiles);
  };

  handleRemove = (result) => {
    let { imageIndexToRemove, images } = this.state;
    const resultImages = [...images];

    if (result) {
      resultImages.splice(imageIndexToRemove, 1);
      this.handleResetField(resultImages);
    }

    this.setState({
      showConfirmation: false,
      imageIndexToRemove: 0,
      images: resultImages,
    });
  };

  handleRemoveRequest(index, e) {
    e.preventDefault();
    this.setState({
      imageIndexToRemove: index,
      showConfirmation: true,
    });
  }

  handleResetField = (newImages) => {
    const {
      input: { name },
      meta: { dispatch, form },
    } = this.props;

    dispatch(change(form, name, newImages));
  };

  handleAddPhotoClick = () => {
    this.fileUpload.click();
  };
}

export default FormImageUpload;
