import {
  WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_CLOSE,
  WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_FORM_EDIT,
  WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_FORM_VALID,
  WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_SHOW,
  WORK_ORDERS_DETAILS_CREATE_QUOTE_STATE_SET,
  WORK_ORDERS_DETAILS_CREATE_QUOTE_VALIDATION_MESSAGE_SET,
  WORK_ORDERS_DETAILS_CREATE_QUOTES_FORM_SET,
  WORK_ORDERS_DETAILS_CREATE_QUOTES_MODAL_SHOW_SET,
  WORK_ORDERS_DETAILS_CREATE_QUOTES_STATE_SET,
  WORK_ORDERS_DETAILS_REVIEW_QUOTE_MODAL_SHOW,
} from 'actions/admin/work-orders/action-types';

const initialState = {
  createQuotesForm: null, // to keep track of redux-form state after continue
  createQuotesModalState: 'edit', // 'edit', 'review', 'confirmation'
  state: 'edit', // 'edit', 'review', 'confirmation'
  show: false,
  showCreateQuotesModal: false,
  validationMessage: null,
  showCounter: null,
};

export default function createQuoteUiReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_SHOW:
      return {
        ...state,
        show: true,
        state: 'edit',
        validationMessage: null,
        showCounter: null,
      };

    case WORK_ORDERS_DETAILS_REVIEW_QUOTE_MODAL_SHOW:
      return {
        ...state,
        show: true,
        state: 'review',
        validationMessage: null,
        showCounter:
          action.payload.status === 'received' &&
          action.payload.counter_status === 'negotiating',
      };

    case WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_CLOSE:
      return {
        ...state,
        show: false,
      };

    case WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_FORM_EDIT:
      return {
        ...state,
        state: 'edit',
        validationMessage: null,
        showCounter: null,
      };

    case WORK_ORDERS_DETAILS_CREATE_QUOTE_VALIDATION_MESSAGE_SET:
      return {
        ...state,
        validationMessage: action.payload,
      };

    case WORK_ORDERS_DETAILS_CREATE_QUOTE_MODAL_FORM_VALID:
      return {
        ...state,
        state: 'review',
        validationMessage: null,
      };

    case WORK_ORDERS_DETAILS_CREATE_QUOTE_STATE_SET:
      return {
        ...state,
        state: action.payload,
      };

    case WORK_ORDERS_DETAILS_CREATE_QUOTES_FORM_SET:
      return {
        ...state,
        createQuotesForm: action.payload,
      };

    case WORK_ORDERS_DETAILS_CREATE_QUOTES_MODAL_SHOW_SET:
      return {
        ...state,
        createQuotesForm: null,
        createQuotesModalState: 'edit',
        showCreateQuotesModal: action.payload,
      };

    case WORK_ORDERS_DETAILS_CREATE_QUOTES_STATE_SET:
      return {
        ...state,
        createQuotesModalState: action.payload,
      };

    default:
      return state;
  }
}
