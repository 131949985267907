import React, { Component } from 'react';
import { connect } from 'react-redux';
import './NewProject.css';
import SimpleTable from 'components/shared/table/SimpleTable';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import CheckBox from 'components/shared/checkbox/CheckBox';
import {
  getProjectForm,
  getShowNewClientModal,
  getClients,
  getClientsCurrentPage,
  getClientsPageSize,
  getClientsTotal,
} from 'components/admin/projects/creation/store/selectors';
import {
  loadClientsRequest,
  loadClientsSuccess,
  setProjectFormValue,
  nextStage,
  setShowNewClientModal,
} from 'components/admin/projects/creation/store/actions';
import NewClientModal from 'components/admin/projects/creation/NewClientModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import SearchBar from 'components/shared/navigation/SearchBar';

class ClientSelection extends Component {
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(loadClientsRequest(1));
  }

  handleClientSelection = (value) => {
    const {
      projectForm: { clientId },
      dispatch,
    } = this.props;

    if (value !== clientId) {
      dispatch(setProjectFormValue('clientId', value));
      dispatch(setProjectFormValue('projectLocationId', null));
    }
  };

  handleContinue = () => {
    const { dispatch } = this.props;

    dispatch(nextStage());
  };

  showAddNewClientModal = () => {
    const { dispatch } = this.props;

    dispatch(setShowNewClientModal(true));
  };

  loadClients = (page, nameToSearch = '') => {
    const { dispatch } = this.props;
    dispatch(loadClientsSuccess([]));
    dispatch(loadClientsRequest(page, nameToSearch));
  };

  handleClientsPageChange = (newPage) => {
    this.loadClients(newPage);
  };

  handleSearchTextChange = (nameToSearch) => {
    this.loadClients(1, nameToSearch);
  };

  render() {
    const {
      clients,
      clientsCurrentPage,
      clientsPageSize,
      clientsTotal,
      showNewClientModal,
      cancelFunction,
      backFunction,
    } = this.props;

    return (
      <React.Fragment>
        <SearchBar
          title="Which Owner is the Project for?"
          onSearchTextChange={this.handleSearchTextChange}
          onSearch={this.handleSearchTextChange}
          addButton={
            <Button
              type={BUTTON_TYPES.LINK}
              icon={BUTTON_ICONS.PLUS}
              label="Add New Owner"
              onClick={this.showAddNewClientModal}
            />
          }
        />

        <div className="selection-table-container owner-selection">
          <SimpleTable
            headers={['', 'COMPANY NAME', 'POINT OF CONTACT']}
            rows={this.mapClients(clients)}
            emptyMessage="No Owners"
          />
        </div>
        <SimplePagination
          currentPage={clientsCurrentPage}
          pageSize={clientsPageSize}
          total={clientsTotal}
          onPageChange={this.handleClientsPageChange}
        />

        <div className="buttons-container">
          {cancelFunction && (
            <Button
              key="cancel"
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Cancel"
              onClick={cancelFunction}
            />
          )}
          {backFunction && (
            <Button
              key="back"
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Back"
              onClick={backFunction}
            />
          )}
          <Button
            key="continue"
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label={this.clientSelected() ? 'Continue' : 'Skip & Select Later'}
            onClick={this.handleContinue}
          />
        </div>

        {showNewClientModal && <NewClientModal />}
      </React.Fragment>
    );
  }

  mapClients = (clients) => {
    const {
      projectForm: { clientId },
    } = this.props;

    return clients.map((client) => {
      return [
        <CheckBox
          id={client.id}
          checked={client.id === clientId}
          shape={'round'}
          size={'medium'}
          onChange={this.handleClientSelection.bind(this)}
        />,
        client.name,
        `${client.contact.first_name} ${client.contact.last_name}`,
      ];
    });
  };

  clientSelected = () => {
    const {
      projectForm: { clientId },
    } = this.props;

    return !!clientId;
  };
}

export default connect((state) => {
  return {
    clients: getClients(state),
    clientsCurrentPage: getClientsCurrentPage(state),
    clientsPageSize: getClientsPageSize(state),
    clientsTotal: getClientsTotal(state),
    projectForm: getProjectForm(state),
    showNewClientModal: getShowNewClientModal(state),
  };
})(ClientSelection);
