import {
  WORK_ORDERS_CREATION_WO_CANCELLATION_MODAL_CLOSE,
  WORK_ORDERS_CREATION_WO_CANCELLATION_MODAL_SHOW,
  WORK_ORDERS_CREATION_WO_NEW_PROCESS_STARTED,
} from '../../../../actions/admin/work-orders/action-types';

const initialState = {
  newWOProcessStarted: false,
  showWOCancellationConfirmationModal: false,
};

export default function creationUiReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_CREATION_WO_CANCELLATION_MODAL_CLOSE:
      return {
        ...state,
        showWOCancellationConfirmationModal: false,
      };

    case WORK_ORDERS_CREATION_WO_CANCELLATION_MODAL_SHOW:
      return {
        ...state,
        showWOCancellationConfirmationModal: true,
      };

    case WORK_ORDERS_CREATION_WO_NEW_PROCESS_STARTED:
      return {
        ...state,
        newWOProcessStarted: action.payload,
      };

    default:
      return state;
  }
}
