import React, { Component } from 'react';
import { connect } from 'react-redux';
import './NewProject.css';
import { getCurrentStage } from 'components/admin/projects/creation/store/selectors';
import {
  editProject,
  setProjectFormValues,
  prevStage,
} from 'components/admin/projects/creation/store/actions';
import ProjectDetails from 'components/admin/projects/creation/ProjectDetails';
import ClientSelection from 'components/admin/projects/creation/ClientSelection';
import ProjectLocationSelection from 'components/admin/projects/creation/ProjectLocationSelection';
import { loadProjectRequest } from 'components/admin/projects/details/store/actions';
import numeral from 'numeral';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { updateProject } from 'components/admin/projects/creation/store/actions';
import { goToProjectDetails } from 'components/admin/projects/store/actions';
import { getProjectForm } from 'components/admin/projects/creation/store/selectors';
import { getDarkMode } from 'selectors/theme';
import DetailsTitle from 'components/shared/details/DetailsTitle';

class EditProject extends Component {
  constructor(props) {
    super(props);

    props.dispatch(editProject());
  }

  handleCancelEdit = () => {
    this.props.dispatch(goToProjectDetails(this.props.currentProject.id));
  };

  handleUpdateProject = () => {
    const projectId = this.props.currentProject.id;
    this.props
      .dispatch(updateProject(projectId, this.props.projectForm))
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(goToProjectDetails(projectId));
        }
      });
  };

  componentDidMount() {
    const projectId = numeral(this.props.match.params.projectId).value();
    const currentProject = this.props.currentProject;
    if (currentProject && currentProject.id === projectId) {
      this.props.dispatch(setProjectFormValues(currentProject));
    } else {
      this.props.dispatch(loadProjectRequest(projectId)).then((response) => {
        if (response.project) {
          this.props.dispatch(setProjectFormValues(response.project));
        }
      });
    }
  }

  handlePrev = () => {
    this.props.dispatch(prevStage());
  };

  renderCurrentStage = () => {
    switch (this.props.currentStage) {
      case 'project-details':
        return <ProjectDetails cancelFunction={this.handleCancelEdit} />;

      case 'client-selection':
        return (
          <ClientSelection
            backFunction={this.handlePrev}
            cancelFunction={this.handleCancelEdit}
          />
        );

      case 'project-location-selection':
        return (
          <ProjectLocationSelection
            submitButtonLabel="Update Project"
            backFunction={this.handlePrev}
            cancelFunction={this.handleCancelEdit}
            submitFunction={this.handleUpdateProject}
          />
        );

      default:
        return null;
    }
  };

  render() {
    const { darkMode } = this.props;

    return (
      <div
        className={`project-form edit-project ${darkMode ? 'dark-mode' : ''}`}
      >
        <DetailsTitle className="main-form-title" title="EDIT PROJECT" />
        <div className="content-container">{this.renderCurrentStage()}</div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentStage: getCurrentStage(state),
    currentProject: getCurrentProject(state),
    projectForm: getProjectForm(state),
    darkMode: getDarkMode(state),
  };
})(EditProject);
