import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormModal from 'components/shared/modal/FormModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import DatePicker from 'components/shared/date-picker/DatePicker';
import './ProjectReportFormModal.css';

class FilterReportsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: '',
      endDate: '',
    };
  }

  clearFiltering = () => {
    this.setState({ startDate: '', endDate: '' });
  };

  render() {
    const { show, onClose, onSend } = this.props;
    const { startDate, endDate } = this.state;

    return (
      <FormModal
        id="project-report-filter-modal"
        className="project-report-filter-modal-view"
        show={show}
        onClose={onClose}
        subTitle="Filter Reports"
      >
        <div className="date-picker-section">
          <DatePicker
            placeholder="Start Date"
            onChange={(value) => this.setState({ startDate: value })}
            value={startDate}
          />
          <div className="separate-block" />
          <DatePicker
            placeholder="End Date"
            onChange={(value) => this.setState({ endDate: value })}
            value={endDate}
          />
        </div>
        <div className="button-confirm">
          <Button
            key="filter-confirm-button"
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label="Confirm"
            onClick={() => {
              onSend(startDate, endDate);
              onClose();
            }}
          />
        </div>
      </FormModal>
    );
  }
}

FilterReportsModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSend: PropTypes.func,
};

FilterReportsModal.defaultProps = {
  show: false,
  onClose: () => null,
};

export default connect(null, null, null, { withRef: true })(FilterReportsModal);
