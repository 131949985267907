import { createSelector } from 'reselect';

export const getCurrentPage = createSelector(
  (state) => state.projects.scenes.creation.ui.currentPage,
  (currentPage) => {
    return currentPage;
  }
);

export const getCurrentStage = createSelector(
  (state) => state.projects.scenes.creation.ui.currentStage,
  (currentStage) => {
    return currentStage;
  }
);

export const getProjectForm = createSelector(
  (state) => state.projects.scenes.creation.ui.projectForm,
  (projectForm) => {
    return projectForm;
  }
);

export const getSubscribers = createSelector(
  (state) => state.projects.scenes.creation.data.subscribers,
  (subscribers) => {
    return subscribers;
  }
);

export const getClientForm = createSelector(
  (state) => state.projects.scenes.creation.ui.clientForm,
  (clientForm) => {
    return clientForm;
  }
);

export const getShowNewClientModal = createSelector(
  (state) => state.projects.scenes.creation.ui.showNewClientModal,
  (showNewClientModal) => {
    return showNewClientModal;
  }
);

export const getProjectLocationForm = createSelector(
  (state) => state.projects.scenes.creation.ui.projectLocationForm,
  (projectLocationForm) => {
    return projectLocationForm;
  }
);

export const getShowNewProjectLocationModal = createSelector(
  (state) => state.projects.scenes.creation.ui.showNewProjectLocationModal,
  (showNewProjectLocationModal) => {
    return showNewProjectLocationModal;
  }
);

export const getCompanyForm = createSelector(
  (state) => state.projects.scenes.creation.ui.companyForm,
  (companyForm) => {
    return companyForm;
  }
);

export const getShowNewCompanyModal = createSelector(
  (state) => state.projects.scenes.creation.ui.showNewCompanyModal,
  (showNewCompanyModal) => {
    return showNewCompanyModal;
  }
);

export const getNewProjectId = createSelector(
  (state) => state.projects.scenes.creation.data.newProjectId,
  (newProjectId) => {
    return newProjectId;
  }
);

export const getClients = createSelector(
  (state) => state.projects.scenes.creation.data.clients,
  (clients) => {
    return clients;
  }
);

export const getClientsCurrentPage = createSelector(
  (state) => state.projects.scenes.creation.ui.clientsCurrentPage,
  (clientsCurrentPage) => {
    return clientsCurrentPage;
  }
);

export const getClientsTotal = createSelector(
  (state) => state.projects.scenes.creation.ui.clientsTotal,
  (clientsTotal) => {
    return clientsTotal;
  }
);

export const getClientsPageSize = createSelector(
  (state) => state.projects.scenes.creation.ui.clientsPageSize,
  (clientsPageSize) => {
    return clientsPageSize;
  }
);
