/* eslint-disable default-case */
import axios from 'axios';
import BaseApi from './base-api';

class ProjectScheduleApi extends BaseApi {
  // - index
  getProjectSchedules(projectId, filters = {}) {
    let url = `${this.baseUrl}/projects/${projectId}/schedule_categories`;
    url += this.makeParamsGetSchedules(filters);

    return axios
      .get(url)
      .then((response) => {
        const data = this.getFilteredData(response.data, filters);
        return { ok: true, data };
      })
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }
  getProjectSchedulesLog(projectId, filters = {}) {
    let url = `${this.baseUrl}/projects/${projectId}/schedule_category_log/print`;
    url += this.makeParamsGetSchedules(filters);

    return axios
      .get(url, { responseType: 'blob' })
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }
  // - show
  getProjectSchedulesCategory(projectId, categoryId, options = {}) {
    let url = `${this.baseUrl}/projects/${projectId}/schedule_categories/${categoryId}`;

    if (Object.keys(options).length > 0) {
      const params = [];
      if (options.schedulesStatus)
        params.push(`schedules_status=${options.schedulesStatus}`);
      url += `?${params.join('&')}`;
    }

    return axios
      .get(url, { loadingDisabled: options.loadingDisabled })
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }
  // - create & update
  saveCategoryProjectSchedules(projectId, category) {
    const {
      id,
      name,
      duplicate,
      copyDates,
      copyVisibility,
      copyEditing,
      visibility,
      visibilityUsers,
      editing,
      editingUsers,
    } = category;

    const isEdit = id !== null;

    const method = isEdit ? 'put' : 'post';
    const url = `${this.baseUrl}/projects/${projectId}/schedule_categories${
      isEdit ? `/${id}` : ''
    }`;

    let data = new FormData();
    data.append('schedule_category[name]', name);

    if (!isEdit && duplicate) {
      data.append('schedule_category[duplicate_id]', duplicate);
      data.append('schedule_category[copy_dates]', copyDates);
      data.append('schedule_category[copy_visibility]', copyVisibility);
      data.append('schedule_category[copy_editing]', copyEditing);
    }

    data.append('schedule_category[visibility]', visibility);
    visibilityUsers.forEach((u) => {
      data.append('schedule_category[visibility_users][]', u.id);
    });

    data.append('schedule_category[editing]', editing);
    editingUsers.forEach((u) => {
      data.append('schedule_category[editing_users][]', u.id);
    });

    return axios[method](url, data)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }
  // - create & update
  saveProjectSchedule(projectId, categoryId, schedule) {
    const {
      id,
      description,
      position,
      original_start,
      previous_start,
      current_start,
      original_completion,
      previous_completion,
      current_completion,
      responsible,
      responsible_company,
    } = schedule;
    const isEdit = id !== null;

    const method = isEdit ? 'put' : 'post';
    const url = `${
      this.baseUrl
    }/projects/${projectId}/schedule_categories/${categoryId}/schedules${
      isEdit ? `/${id}` : ''
    }`;

    let data = new FormData();
    data.append('schedule[description]', description);
    data.append('schedule[position]', position);
    data.append('schedule[original_start]', original_start);
    data.append('schedule[previous_start]', previous_start);
    data.append('schedule[current_start]', current_start);
    data.append('schedule[original_completion]', original_completion);
    data.append('schedule[previous_completion]', previous_completion);
    data.append('schedule[current_completion]', current_completion);
    data.append(
      'schedule[responsible_company_id]',
      responsible_company ? responsible_company.value : ''
    );

    data.append(
      'schedule[responsible_id]',
      responsible ? responsible.value : ''
    );

    return axios[method](url, data, { loadingDisabled: true })
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  // - order
  orderProjectSchedules(projectId, categoryId, schedules) {
    const url = `${this.baseUrl}/projects/${projectId}/schedule_categories/${categoryId}/schedules/sort`;

    let data = new FormData();
    schedules.forEach(({ id }) => {
      if (id) data.append('schedule[sorted_ids][]', id);
    });

    return axios
      .post(url, data)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }
  // - resolve
  resolveProjectSchedule(projectId, categoryId, scheduleId) {
    const url = `${this.baseUrl}/projects/${projectId}/schedule_categories/${categoryId}/schedules/${scheduleId}/resolve`;

    return axios
      .post(url)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }
  // - restore
  restoreProjectSchedule(projectId, categoryId, scheduleId) {
    const url = `${this.baseUrl}/projects/${projectId}/schedule_categories/${categoryId}/schedules/${scheduleId}/restore`;

    return axios
      .post(url)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }
  // - destroy
  deleteProjectSchedule(projectId, categoryId, scheduleId) {
    const url = `${this.baseUrl}/projects/${projectId}/schedule_categories/${categoryId}/schedules/${scheduleId}`;

    return axios
      .delete(url)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  // - publish
  publishCategoryProjectSchedule(projectId, categoryId) {
    const url = `${this.baseUrl}/projects/${projectId}/schedule_categories/${categoryId}/publish`;

    return axios
      .post(url)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }
  // - archive
  archiveCategoryProjectSchedules(projectId, categoryId) {
    const url = `${this.baseUrl}/projects/${projectId}/schedule_categories/${categoryId}/archive`;

    return axios
      .post(url)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }
  // - restore
  restoreCategoryProjectSchedule(projectId, categoryId) {
    const url = `${this.baseUrl}/projects/${projectId}/schedule_categories/${categoryId}/restore`;

    return axios
      .post(url)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }
  // - destroy
  deleteCategoryProjectSchedules(projectId, categoryId) {
    const url = `${this.baseUrl}/projects/${projectId}/schedule_categories/${categoryId}`;

    return axios
      .delete(url)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }
  // - export
  exportCategoryProjectSchedule(projectId, categoryId, type = 'pdf') {
    const url = `${this.baseUrl}/projects/${projectId}/schedule_category_log/${categoryId}/print_${type}`;
    return axios
      .get(url, { responseType: 'blob', loadingDisabled: true })
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }
  // - email
  sendEmail(projectId, categoryId, emails, subject, message, fileType) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const url = `${this.baseUrl}/projects/${projectId}/schedule_categories/${categoryId}/email`;

    let apiPayload = new FormData();
    emails.map((item) =>
      apiPayload.append(`schedule_category[emails][]`, item)
    );
    apiPayload.append(`schedule_category[subject]`, subject);
    apiPayload.append(`schedule_category[message]`, message);
    apiPayload.append(`schedule_category[file_type]`, fileType);

    return axios
      .put(url, apiPayload, headers)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  makeParamsGetSchedules = (filters) => {
    const params = [];

    if (filters.tab && filters.tab !== 'schedules') {
      params.push(`${filters.tab}=true`);

      if (filters.search && filters.search.length > 0) {
        params.push(`by_text=${filters.search}`);
      }

      if (filters.byName) {
        params.push(`by_parent_id=${filters.byName}`);
      }

      if (filters.byCompany) {
        params.push(`by_company_id=${filters.byCompany}`);
      }

      if (filters.sortName && filters.sortName !== 'all') {
        params.push(`sort_by_name=${filters.sortName}`);
      }

      if (filters.sortCompany && filters.sortCompany !== 'all') {
        params.push(`sort_by_company=${filters.sortCompany}`);
      }

      ['archived', 'created', 'published'].forEach((t) => {
        const fromKey = `${t}DateFrom`;
        const toKey = `${t}DateTo`;
        const sortKey = `sort${t[0].toUpperCase()}${t.slice(1)}Date`;
        const sortUserKey = `sort${t[0].toUpperCase()}${t.slice(1)}By`;
        const userKey = `by${t[0].toUpperCase()}${t.slice(1)}User`;

        if (filters[fromKey]) {
          params.push(`by_${t}_date_from=${filters[fromKey]}`);
        }
        if (filters[toKey]) {
          params.push(`by_${t}_date_to=${filters[toKey]}`);
        }
        if (filters[sortKey] && filters[sortKey] !== 'all') {
          params.push(`sort_by_${t}_at=${filters[sortKey]}`);
        }
        if (filters[sortUserKey] && filters[sortUserKey] !== 'all') {
          params.push(`sort_by_${t}_user=${filters[sortUserKey]}`);
        }
        if (filters[userKey]) {
          params.push(`by_${t}_user=${filters[userKey]}`);
        }
      });
    }

    if (params.length > 0) return '?' + params.join('&');
    return '';
  };

  getFilteredData = (data, filters) => {
    if (
      !filters.tab ||
      filters.tab !== 'schedules' ||
      !filters.search ||
      filters.search.length === 0
    )
      return data;

    return data.reduce((acc, cat) => {
      if (this.includesText(cat.name, filters.search)) acc.push(cat);
      else {
        const schedules = cat.schedules.filter((s) => {
          return (
            this.includesText(s.description, filters.search) ||
            this.includesText(s.created_by.full_name, filters.search)
          );
        });
        if (schedules.length > 0) acc.push({ ...cat, schedules });
      }

      return acc;
    }, []);
  };

  includesText(string, text) {
    return string
      .split(' ')
      .join('')
      .toUpperCase()
      .includes(text.split(' ').join('').toUpperCase());
  }
}

export const projectScheduleApi = new ProjectScheduleApi();
