import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import FormModal from 'components/shared/modal/FormModal';
import { Form } from 'react-bootstrap';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import Input from 'components/shared/input/Input';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import {
  createCloseoutCatRequest,
  updateCloseoutCatRequest,
  loadCloseoutCatsCompanyTypes,
  loadCloseoutContribsRequest,
} from 'components/admin/projects/details/project-closeout/store/actions';
import './CloseoutCatFormModal.css';

class CloseoutCatFormModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    closeoutCat: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = { name: props.name || '' };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (value) => {
    this.setState({ name: value });
  };

  formValid = () => {
    const { name } = this.state;
    return name.length > 0;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { currentProject, dispatch, onSubmit, closeoutCat } = this.props;
    const { name } = this.state;

    dispatch(
      closeoutCat
        ? updateCloseoutCatRequest(currentProject.id, name, closeoutCat.id)
        : createCloseoutCatRequest(currentProject.id, name)
    ).then((response) => {
      if (response.ok) {
        dispatch(loadCloseoutCatsCompanyTypes(currentProject.id));
        dispatch(loadCloseoutContribsRequest(currentProject.id));
        onSubmit && onSubmit();
      }
    });
  };

  submitButtonName = () => {
    const { name } = this.props;
    return name ? 'Update' : 'Add';
  };

  subTitle = () => {
    const { closeoutCat } = this.props;
    return closeoutCat ? 'Rename Item' : 'Add Item';
  };

  render() {
    const { show, onClose } = this.props;
    const { name } = this.state;

    return (
      <FormModal
        className="closeout-cat-creation-modal"
        subTitle={this.subTitle()}
        show={show}
        onClose={onClose}
      >
        <Form horizontal>
          <div className="form-block">
            <FormControlBlock
              label={!!name && 'Item Name'}
              control={
                <Input
                  id="item-name"
                  placeholder="Item Name"
                  value={name}
                  theme="normal"
                  onChange={this.handleChange}
                />
              }
            />
          </div>

          <div className="buttons-container">
            <Button
              type={BUTTON_TYPES.RECTANGLE}
              color={BUTTON_COLORS.WHITE}
              label="Cancel"
              onClick={onClose}
            />
            <Button
              type={BUTTON_TYPES.RECTANGLE}
              color={BUTTON_COLORS.GREEN}
              label={this.submitButtonName()}
              onClick={this.handleSubmit}
              disabled={!this.formValid()}
            />
          </div>
        </Form>
      </FormModal>
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
  };
})(CloseoutCatFormModal);
