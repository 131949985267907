export const FILE_TYPES = {
  ANY: {
    accept:
      'application/pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .gif, .png, .dwg,' +
      '.mp3, .wav, .wma, .mid, .midi, .7z, .rar, .zip, .ios, .csv, .log, .xml, .email, .eml,' +
      '.emlx, .msg, .oft, .ost, .pst, .vcf, .otf, .ttf, .ai, .indd, .bmp, .ico, .ps, .eps, .psd,' +
      '.svg, .tif, .tiff, .pcx, .rle, .dib, .htm, .key, .pps, .ppt, .pptx, .xlsm, .avi, .m4v,' +
      '.mov, .mp4, .mpg, .mpeg, .wmv, .swf, .rtf, .txt, .dxf, .cad, .wire, .fbx, .cmp, .gcd,' +
      '.skp, .rfa, .rvt, .rte, .nwd, .ifc, .ifcxml, .cobie, .fig, .dwt, .dxf, .dwf, .dst,' +
      '.mpp, .vsd, .3ds, .3dxl, .3ds, .heic',
    label: 'Drag Files Here',
  },
  DOCUMENT: {
    accept: 'application/pdf, .doc, .docx, .xls, .xlsx',
    label: 'Drag Files Here',
  },
  PDF: {
    accept: 'application/pdf',
    label: 'Drag PDFs Here',
  },
};
