import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import { Modal } from 'react-bootstrap';
import successSvg from './success.svg';
import successDarkSvg from './success-dark.svg';
import exclamationSvg from './exclamation.svg';
import exclamationDarkSvg from './exclamation-dark.svg';
import './Modal.css';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import simpleLoading from 'images/simple-loading.gif';
export const MODAL_ICONS = {
  SUCCESS: { default: successSvg, dark: successDarkSvg, alt: 'success' },
  EXCLAMATION: {
    default: exclamationSvg,
    dark: exclamationDarkSvg,
    alt: 'exclamation',
  },
  LOADING: { default: simpleLoading, dark: simpleLoading, alt: 'loading' },
};

class MessageModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    icon: PropTypes.shape({
      default: PropTypes.string,
      dark: PropTypes.string,
      alt: PropTypes.string,
    }),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      .isRequired,
    subMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    buttons: PropTypes.arrayOf(PropTypes.object),
    buttonMessage: PropTypes.string,
    disabled: PropTypes.bool,
    onHide: PropTypes.func,
    iconSize: PropTypes.number,
  };

  static defaultProps = {
    buttonMessage: 'Ok',
    disabled: false,
  };

  handleOk = () => {
    const { onHide } = this.props;

    if (onHide) {
      onHide();
    }
  };

  render() {
    const {
      show,
      icon,
      message,
      subMessage,
      buttons,
      buttonMessage,
      disabled,
      darkMode,
      iconSize,
    } = this.props;

    return (
      <Modal
        className={`basic-modal message-modal${darkMode ? ' dark-mode' : ''}`}
        show={show}
      >
        <Modal.Body>
          <div className="messages-container">
            {!!icon && (
              <img
                className="message-icon"
                src={darkMode ? icon.dark : icon.default}
                style={iconSize ? { width: iconSize } : undefined}
                alt={icon.alt}
              />
            )}
            <div className="main-message">{message}</div>
            {subMessage && <div className="sub-message">{subMessage}</div>}
          </div>
          {(!buttons || buttons.length > 0) && (
            <div className="buttons-container">
              {buttons ? (
                buttons
              ) : (
                <Button
                  type={BUTTON_TYPES.CONFIRMATION}
                  color={BUTTON_COLORS.GREEN}
                  label={buttonMessage}
                  disabled={disabled}
                  onClick={this.handleOk}
                />
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(MessageModal);
