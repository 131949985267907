import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { sameOrBeforeTodayValidation } from 'services/utils/date-util';
import { DailyReportUtil } from 'services/utils/daily-report-util';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import ProjectEmailModal from 'components/admin/projects/details/project-email-modal/ProjectEmailModal';
import DatePicker from 'components/shared/date-picker/DatePicker';
import FormModal from 'components/shared/modal/FormModal';
import SingleTradeForm from 'components/admin/projects/details/project-daily-report-log/SingleTradeForm';
import SingleTradeFormForSub from 'components/admin/projects/details/project-daily-report-log/SingleTradeFormForSub';
import MemberFormModal from 'components/admin/projects/details/project-daily-report-log/MemberFormModal';
import ReviewForm from 'components/admin/projects/details/project-daily-report-log/ReviewForm';
import DuplicateDailyReportModal from 'components/admin/projects/details/project-daily-report-log/DuplicateDailyReportModal';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { getSettings } from 'components/admin/projects/details/project-settings/store/selectors';
import { getShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/selectors';
import {
  getContributors,
  getActiveContributor,
  getContributorsForm,
  getShowReviewForm,
  getCurrentDailyReport,
  getDailyReportIsSubmitted,
  getEditMode,
  getActiveEntryId,
  getReportDate,
  getReportExtraInputs,
  getGeneralContactorName,
  getMemberForm,
  getMembers,
  getDeletedMembers,
  getReportOpenItem,
  getGCImages,
  getGCDeletedImages,
  getDailyReportsTabSelected,
  getAddFloor,
} from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import { loadSettings } from 'components/admin/projects/details/project-settings/store/actions';
import {
  setShowDailyReportModal,
  setContributors,
  setCurrentDailyReport,
  addDailyReport,
  updateDailyReport,
  addDailyReportEntries,
  setActiveContributor,
  setActiveEntriesId,
  setReviewFormAction,
  dailyReportFinaleUpdate,
  setSubmittedReport,
  clearFormAction,
  setEditMode,
  deleteReportRequest,
  loadFullReportsRequest,
  sendDailyReportEmails,
  setReportDate,
  setReportExtraInputs,
  setReportExtraInput,
  setGeneralContractorName,
  setMemberForm,
  addDailyReportMembers,
  setMembers,
  setDeletedMembers,
  setReportOpenItem,
  setGCImagesAction,
  setGCDeletedImagesAction,
  changeAmountOfTechnicians,
  setDailyReportAddFloor,
  restoreTradeForm,
  restoreMemberForm,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';
import './AddDailyReportModal.css';
import { utcTime } from 'services/utils/date-util';
import { showErrorMessage } from 'actions/messages';
import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';
import { getCurrentUser } from 'selectors/authentication';
import User from 'domain/user';
import Project from 'domain/project';
import DailyReportPermissions from 'permissions/daily-report-permissions';
import classnames from 'classnames';
import FormControlBlock from 'components/shared/form/FormControlBlock';

const SubDailyReportTabValue = 2;

class AddDailyReportModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    projectId: PropTypes.number,
  };

  static defaultProps = {
    show: false,
  };

  state = {
    errors: {},
    reviewErrors: [],
    errorsMemberForm: [],
    errorsForStaff: false,
    errorsOpenItemForm: {},
    errorsForOpenItem: false,
    submitFailed: false,
    showDuplicateModal: false,
    originalContributorsList: [],
  };

  componentDidMount() {
    this.loadDirectory();
    this.loadSettings();
  }

  getGeneralContractorLabel = (loadedDirectory) => {
    const { currentProject } = this.props;
    const directory = loadedDirectory || this.props.directory;

    const gcContributor = Project.gcContributor({
      project: currentProject,
      directory,
    });
    if (!gcContributor) return null;

    return DailyReportUtil.tradeLabel(gcContributor);
  };

  clearReviewErrors = () => {
    this.setState({
      reviewErrors: [],
    });
  };

  submitFailedTradeFormErrors = () => {
    if (this.state.submitFailed) {
      const errors = this.isSubDailyReport()
        ? this.getErrorsForSub()
        : this.getErrors();
      this.setState({ errors: { ...errors } });
    }
  };

  submitFailedMembersFormErrors = () => {
    const { submitFailed } = this.state;
    if (submitFailed) {
      const errorsMemberForm = this.getErrorsForMembers(submitFailed);
      const errorsOpenItemForm = this.getErrorsForOpenItem(submitFailed);
      this.setState({
        errorsMemberForm: [...errorsMemberForm],
        errorsOpenItemForm: { ...errorsOpenItemForm },
      });
    }
  };

  handleClose = (event) => {
    event && event.preventDefault();
    this.props.dispatch(setShowDailyReportModal(false));
    this.props.dispatch(setActiveContributor(0));
    this.props.dispatch(setActiveEntriesId(null));
    this.props.dispatch(setContributors([]));
    this.props.dispatch(setCurrentDailyReport(null));
    this.props.dispatch(setReviewFormAction(false));
    this.props.dispatch(setSubmittedReport(false));
    this.props.dispatch(clearFormAction());
    this.props.dispatch(setEditMode(false));
    this.props.dispatch(setReportDate(moment().format('YYYY-MM-DD')));
    this.props.dispatch(setReportExtraInputs({}));
    this.props.dispatch(setReportOpenItem({}));
    this.props.dispatch(setGeneralContractorName(null));
    this.props.dispatch(setMemberForm(false));
    this.props.dispatch(setMembers([]));
    this.props.dispatch(setDeletedMembers([]));
    this.props.dispatch(setGCImagesAction([]));
    this.props.dispatch(setGCDeletedImagesAction([]));
  };

  loadDirectory = () => {
    const { directory, projectId, dispatch } = this.props;
    if (projectId && (!directory || directory.project_id !== projectId)) {
      dispatch(loadDirectoryRequest(projectId)).then((response) => {
        if (response.ok) this.populateGeneralContractorName();
      });
    } else if (directory) {
      this.populateGeneralContractorName(directory);
    }
  };

  populateGeneralContractorName = (directory) => {
    const { dispatch, currentDailyReport } = this.props;
    if (currentDailyReport && currentDailyReport.gc_staff_on_site) {
      dispatch(
        setGeneralContractorName([0, this.getGeneralContractorLabel(directory)])
      );
    }
  };

  loadSettings = () => {
    const { settings, projectId, dispatch } = this.props;
    if (projectId) {
      if (!settings || settings.project_id !== projectId) {
        dispatch(loadSettings(projectId));
      }
    }
  };

  handleDateChange = (date) => {
    this.props.dispatch(setReportDate(date));
  };

  handleExtraInputChange = (attribute, value) => {
    this.props.dispatch(setReportExtraInput(attribute, value));
  };

  handleContributorsChange = (contributors) => {
    const errors = { ...this.state.errors };

    if (errors.contributorsField) {
      errors.contributorsField = false;
      this.setState({
        errors: errors,
      });
    }

    const generalContactorIndex = contributors.findIndex(
      (contrib) => contrib[0] === 0
    );

    if (generalContactorIndex >= 0) {
      const gcLabel = this.getGeneralContractorLabel();
      this.props.dispatch(setGeneralContractorName([0, gcLabel]));
      contributors.splice(generalContactorIndex, 1);
    } else {
      this.props.dispatch(setGeneralContractorName(null));
    }
    this.props.dispatch(setContributors(contributors));
  };

  addReport = (editTrades = false) => {
    const {
      contributors,
      projectId,
      currentDailyReport,
      dispatch,
      editMode,
      reportDate,
      generalContactorName,
    } = this.props;
    const errors = this.getErrors();

    if (errors && Object.keys(errors).length > 0) {
      this.setState({
        errors: { ...errors },
      });
    } else {
      if (projectId) {
        return currentDailyReport
          ? dispatch(
              updateDailyReport(
                contributors,
                projectId,
                currentDailyReport,
                editTrades === editMode,
                reportDate,
                generalContactorName,
                this.isSubDailyReport()
              )
            )
          : dispatch(
              addDailyReport(
                contributors,
                reportDate,
                projectId,
                generalContactorName,
                this.isSubDailyReport()
              )
            );
      }
    }
  };

  removeErrorsForSub = () => {
    this.setState({
      errors: {},
    });
  };

  removeErrors = (key, index, value) => {
    if (index !== undefined && this.state.errors.technicians) {
      const techniciansErrors = [...this.state.errors.technicians];
      const currentTech = techniciansErrors[index];
      if (currentTech) {
        currentTech[key] = value;
        if (key === 'hoursAmount') {
          currentTech.error = false;
        }

        if (key === 'workedFrom' && currentTech.workedTo) {
          currentTech.error = false;
        }

        if (key === 'workedTo' && currentTech.workedFrom) {
          currentTech.error = false;
        }

        this.setTechnicianErrors(
          techniciansErrors.filter((field) => field.error)
        );
      }
      return;
    }

    if (this.state.errors[key]) {
      this.setState({
        errors: {
          ...this.state.errors,
          [key]: false,
        },
      });
      return;
    }
  };

  setTechnicianErrors = (techniciansErrors) => {
    this.setState({
      errors:
        techniciansErrors.length > 0
          ? {
              ...this.state.errors,
              technicians: techniciansErrors,
            }
          : Object.keys(this.state.errors).reduce(
              (newErrors, key) =>
                key !== 'technicians'
                  ? { ...newErrors, [key]: this.state.errors[key] }
                  : newErrors,
              {}
            ),
    });
  };

  clearErrors = (index, key) => {
    let errors = [...this.state.errorsMemberForm];

    if (errors[index] && errors[index][key]) {
      errors[index][key] = false;

      if (key === 'hoursAmount') {
        errors[index].workedFrom = false;
        errors[index].workedTo = false;
      }

      if (key === 'workedFrom' && errors[index].workedTo) {
        errors[index].hoursAmount = false;
      }

      if (key === 'workedTo' && errors[index].workedFrom) {
        errors[index].hoursAmount = false;
      }

      this.setState({
        errorsMemberForm: errors,
      });
    }

    errors = { ...this.state.errorsOpenItemForm };
    if (errors[key]) {
      if (key === 'openItemAssigneeId') {
        errors.openItemAssigneeId = false;
      }

      if (key === 'openItemResolveBy') {
        errors.openItemResolveBy = false;
      }

      if (key === 'openItemDesc') {
        errors.openItemDesc = false;
      }

      this.setState({
        errorsOpenItemForm: errors,
      });
    }
  };

  getErrorsForSaveDraft = () => {
    const { members } = this.props;
    const errors = [];

    members.forEach((member, index) => {
      const error = {};

      if (!member.user) {
        error.user = true;
      }

      if (Object.keys(error).length > 0) {
        errors[index] = error;
      }
    });

    return errors;
  };

  getErrorsForMembers = (isSubmitting = false) => {
    const { members, currentDailyReport } = this.props;
    const errors = [];

    if (members.length === 0) {
      errors.push({ user: true });
    }

    members.forEach((member, index) => {
      const error = {};

      if (!member.user) {
        error.user = true;
      }

      if (currentDailyReport.status !== 'draft' || isSubmitting) {
        if (!member.supervisorTasks.length && !member.customTask) {
          error.supervisorTasks = true;
        }

        if (!member.hoursAmount && (!member.workedFrom || !member.workedTo)) {
          error.hoursAmount = true;
          error.workedFrom = true;
          error.workedTo = true;
        }

        if (member.workedFrom && !member.workedTo) {
          error.workedTo = true;
        }

        if (member.workedTo && !member.workedFrom) {
          error.workedFrom = true;
        }
      }

      if (Object.keys(error).length > 0) {
        errors[index] = error;
      }
    });

    return errors;
  };

  getErrorsForOpenItem = (isSubmitting = false) => {
    const { reportOpenItem, currentDailyReport } = this.props;
    const errors = {};

    if (
      reportOpenItem.isDelay &&
      (currentDailyReport.status !== 'draft' || isSubmitting)
    ) {
      if (!reportOpenItem.openItemAssigneeId) {
        errors.openItemAssigneeId = true;
      }

      if (!reportOpenItem.openItemResolveBy) {
        errors.openItemResolveBy = true;
      }

      if (!reportOpenItem.openItemDesc) {
        errors.openItemDesc = true;
      }
    }

    return errors;
  };

  noTradesAndNoGc = (errors) => {
    const { contributors, generalContactorName } = this.props;
    if (contributors.length === 0 && !generalContactorName) {
      errors.contributorsField = true;
    }
  };

  errorsNoTradesAndNoGc = () => {
    const errors = {};
    this.noTradesAndNoGc(errors);
    if (errors && Object.keys(errors).length > 0) {
      this.setState({
        errors: { ...errors },
      });
      return true;
    }
    return false;
  };

  getErrors = () => {
    const {
      activeContributor,
      activeEntryId,
      contributorsForm,
      settings,
    } = this.props;

    const errors = {};

    this.noTradesAndNoGc(errors);

    if (activeContributor) {
      let formValues = contributorsForm[activeEntryId];
      const techniciansErrors = [];

      if (!formValues.floor && settings && settings.multiple_floors) {
        errors.floor = true;
      }

      if (!formValues.workPerformed) {
        errors.workPerformed = true;
      }

      if (formValues.isDelay) {
        if (!formValues.openItemAssigneeId) {
          errors.openItemAssigneeId = true;
        }

        if (!formValues.openItemDesc) {
          errors.openItemDesc = true;
        }

        if (!formValues.openItemResolveBy) {
          errors.openItemResolveBy = true;
        }
      }

      if (formValues.technicians && formValues.technicians.length > 0) {
        formValues.technicians.forEach((technician, index) => {
          if (
            !technician.hoursAmount &&
            (!technician.workedFrom || !technician.workedTo)
          ) {
            techniciansErrors[index] = {
              error: true,
              hoursAmount: '',
              workedFrom: '',
              workedTo: '',
            };
          }

          if (technician.workedFrom && !technician.workedTo) {
            techniciansErrors[index] = {
              error: true,
              hoursAmount: '',
              workedFrom: technician.workedFrom,
              workedTo: '',
            };
          }

          if (technician.workedTo && !technician.workedFrom) {
            techniciansErrors[index] = {
              error: true,
              hoursAmount: '',
              workedFrom: '',
              workedTo: technician.workedTo,
            };
          }
        });
        if (techniciansErrors.length > 0) {
          errors.technicians = techniciansErrors;
        }
      }
    }

    return errors;
  };

  prepareBaseForErrorsTech = (techniciansErrors, index) => {
    if (!techniciansErrors[index]) {
      techniciansErrors[index] = {};
    }
  };

  prepareBaseForErrorsTechMoreInfo = (techniciansErrors, index, infoIndex) => {
    if (!techniciansErrors[index]) {
      techniciansErrors[index] = {};
    }
    if (!techniciansErrors[index].info) {
      techniciansErrors[index].info = [];
    }
    if (!techniciansErrors[index].info[infoIndex]) {
      techniciansErrors[index].info[infoIndex] = {};
    }
  };

  getErrorsForSub = () => {
    const {
      activeContributor,
      activeEntryId,
      contributorsForm,
      settings,
    } = this.props;

    const errors = {};

    this.noTradesAndNoGc(errors);

    if (activeContributor) {
      let formValues = contributorsForm[activeEntryId];
      const techniciansErrors = [];

      if (!formValues.floor && settings && settings.multiple_floors) {
        errors.floor = true;
      }

      if (formValues.isDelay) {
        if (!formValues.openItemAssigneeId) {
          errors.openItemAssigneeId = true;
        }

        if (!formValues.openItemDesc) {
          errors.openItemDesc = true;
        }

        if (!formValues.openItemResolveBy) {
          errors.openItemResolveBy = true;
        }
      }

      if (formValues.isFieldWorkOrder) {
        if (!formValues.fwoNumber) {
          errors.fwoNumber = true;
        }

        if (
          (!formValues.fwoPresetTasks || !formValues.fwoPresetTasks.length) &&
          !formValues.fwoCustomTask
        ) {
          errors.fwoPresetTasks = true;
        }

        if (!formValues.fwoWorkPerformed) {
          errors.fwoWorkPerformed = true;
        }

        if (!formValues.fwoLabor) {
          errors.fwoLabor = true;
        }
      }

      if (formValues.technicians && formValues.technicians.length > 0) {
        formValues.technicians.forEach((technician, index) => {
          if (
            !technician.hoursAmount &&
            (!technician.workedFrom || !technician.workedTo)
          ) {
            this.prepareBaseForErrorsTech(techniciansErrors, index);
            techniciansErrors[index].hoursAmount = true;
            techniciansErrors[index].workedFrom = true;
            techniciansErrors[index].workedTo = true;
          }

          if (technician.workedFrom && !technician.workedTo) {
            this.prepareBaseForErrorsTech(techniciansErrors, index);
            techniciansErrors[index].hoursAmount = false;
            techniciansErrors[index].workedFrom = false;
            techniciansErrors[index].workedTo = true;
          }

          if (technician.workedTo && !technician.workedFrom) {
            this.prepareBaseForErrorsTech(techniciansErrors, index);
            techniciansErrors[index].hoursAmount = false;
            techniciansErrors[index].workedFrom = true;
            techniciansErrors[index].workedTo = false;
          }

          if (
            (!technician.presetTasks || !technician.presetTasks.length) &&
            !technician.customTask
          ) {
            this.prepareBaseForErrorsTech(techniciansErrors, index);
            techniciansErrors[index].presetTasks = true;
            techniciansErrors[index].customTask = true;
          }

          if (technician.additionalInfo && technician.additionalInfo.length) {
            technician.additionalInfo.forEach((info, infoIndex) => {
              if (!info.hoursAmount && (!info.workedFrom || !info.workedTo)) {
                this.prepareBaseForErrorsTechMoreInfo(
                  techniciansErrors,
                  index,
                  infoIndex
                );
                techniciansErrors[index].info[infoIndex].hoursAmount = true;
                techniciansErrors[index].info[infoIndex].workedFrom = true;
                techniciansErrors[index].info[infoIndex].workedTo = true;
              }

              if (info.workedFrom && !info.workedTo) {
                this.prepareBaseForErrorsTechMoreInfo(
                  techniciansErrors,
                  index,
                  infoIndex
                );
                techniciansErrors[index].info[infoIndex].hoursAmount = false;
                techniciansErrors[index].info[infoIndex].workedFrom = false;
                techniciansErrors[index].info[infoIndex].workedTo = true;
              }

              if (info.workedTo && !info.workedFrom) {
                this.prepareBaseForErrorsTechMoreInfo(
                  techniciansErrors,
                  index,
                  infoIndex
                );
                techniciansErrors[index].info[infoIndex].hoursAmount = false;
                techniciansErrors[index].info[infoIndex].workedFrom = true;
                techniciansErrors[index].info[infoIndex].workedTo = false;
              }

              if (!info.presetTasks.length && !info.customTask) {
                this.prepareBaseForErrorsTechMoreInfo(
                  techniciansErrors,
                  index,
                  infoIndex
                );
                techniciansErrors[index].info[infoIndex].presetTasks = true;
                techniciansErrors[index].info[infoIndex].customTask = true;
              }
            });
          }
        });
        if (techniciansErrors.length > 0) {
          errors.technicians = techniciansErrors;
        }
      }
    }

    return errors;
  };

  getContribFormData = () => {
    const { contributorsForm, activeEntryId } = this.props;
    if (activeEntryId && contributorsForm[activeEntryId]) {
      return contributorsForm[activeEntryId];
    }
    return null;
  };

  nextContrib = () => {
    const {
      dispatch,
      activeContributor,
      contributors,
      currentDailyReport,
      activeEntryId,
    } = this.props;

    const isSub = this.isSubDailyReport();

    const errors = this.getErrors();

    if (
      !isSub &&
      errors &&
      Object.keys(errors).length > 0 &&
      currentDailyReport.status !== 'draft'
    ) {
      this.setState({
        errors: { ...errors },
      });
    } else {
      if (isSub) {
        this.submitTradeFormForSub().then((response) => {
          if (response.ok && activeContributor < contributors.length) {
            const contribFormData = this.getContribFormData();
            const techesValues = [];
            let indexTech = 0;
            contribFormData.technicians.forEach((tech) => {
              const additionalInfoRes = [];
              const resTech = {
                ...tech,
                id: response.dailyReport.sub_daily_report_teches[indexTech].id,
              };
              indexTech++;
              if (tech.additionalInfo && tech.additionalInfo.length) {
                resTech.additionalInfo.forEach((info) => {
                  const resInfo = {
                    ...info,
                    id:
                      response.dailyReport.sub_daily_report_teches[indexTech]
                        .id,
                  };
                  indexTech++;
                  additionalInfoRes.push(resInfo);
                });
                resTech.additionalInfo = additionalInfoRes;
              }
              techesValues.push(resTech);
            });
            dispatch(setActiveContributor(+activeContributor + 1));
            const sortedEntries = [
              ...currentDailyReport.sub_daily_report_entries,
            ].sort(
              (a, b) =>
                !!a.sub_daily_report_entry_id - !!b.sub_daily_report_entry_id
            );
            const currentIndex = sortedEntries.findIndex(
              (value) => value.id === activeEntryId
            );
            dispatch(
              changeAmountOfTechnicians(
                techesValues,
                currentDailyReport.sub_daily_report_entries[currentIndex].id
              )
            );
            if (
              currentIndex >= 0 &&
              currentDailyReport.sub_daily_report_entries[currentIndex + 1]
            ) {
              dispatch(setActiveEntriesId(sortedEntries[currentIndex + 1].id));
            }
          }
        });
      } else {
        this.submitTradeForm().then((response) => {
          if (response.ok && activeContributor < contributors.length) {
            dispatch(setActiveContributor(+activeContributor + 1));
            const sortedEntries = [
              ...currentDailyReport.daily_report_entries,
            ].sort(
              (a, b) => !!a.daily_report_entry_id - !!b.daily_report_entry_id
            );
            const currentIndex = sortedEntries.findIndex(
              (value) => value.id === activeEntryId
            );
            if (currentIndex >= 0 && sortedEntries[currentIndex + 1]) {
              dispatch(setActiveEntriesId(sortedEntries[currentIndex + 1].id));
            }
          }
        });
      }
    }
  };

  prevContribOrList = () => {
    const {
      dispatch,
      activeContributor,
      showReviewForm,
      currentDailyReport,
      activeEntryId,
      generalContactorName,
    } = this.props;
    const isSub = this.isSubDailyReport();
    dispatch(setActiveContributor(+activeContributor - 1));
    let sortedEntries;
    if (isSub) {
      sortedEntries = [...currentDailyReport.sub_daily_report_entries].sort(
        (a, b) => !!a.sub_daily_report_entry_id - !!b.sub_daily_report_entry_id
      );
    } else {
      sortedEntries = [...currentDailyReport.daily_report_entries].sort(
        (a, b) => !!a.daily_report_entry_id - !!b.daily_report_entry_id
      );
    }
    let currentIndex = sortedEntries.findIndex(
      (value) => value.id === activeEntryId
    );
    if (currentIndex >= 0) {
      let index = currentIndex === 0 ? 0 : currentIndex - 1;
      dispatch(setActiveEntriesId(sortedEntries[index].id));
      if (currentIndex === 0 && generalContactorName) {
        dispatch(setMemberForm(true));
      }
    }
    if (showReviewForm) {
      dispatch(setReviewFormAction(false));
    }
  };

  generateTitle = () => {
    const {
      activeContributor,
      showReviewForm,
      editMode,
      currentProject,
      isSubmitted,
      currentDailyReport,
      activeEntryId,
      contributors,
      memberForm,
      currentUser,
      reportDate,
    } = this.props;

    if (memberForm) {
      return 'Site Staff';
    }

    if (isSubmitted) {
      return currentProject.name;
    }

    if (!showReviewForm && !editMode) {
      return 'Add Daily Report';
    }

    let name = 'Daily Report';
    if (activeContributor) {
      let contrib;
      if (currentDailyReport.daily_report_entries) {
        contrib = currentDailyReport.daily_report_entries.find(
          (entry) => entry.id === activeEntryId
        );
      }
      if (currentDailyReport.sub_daily_report_entries) {
        contrib = currentDailyReport.sub_daily_report_entries.find(
          (entry) => entry.id === activeEntryId
        );
      }

      if (contrib && contrib.project_contributor) {
        name = DailyReportUtil.tradeLabel(contrib.project_contributor);
      } else {
        contrib = contributors[activeContributor - 1];
        name = `${contrib[1]}`;
      }
    }

    return (
      <React.Fragment>
        {DailyReportPermissions.canEditDate(currentUser, currentProject) ? (
          <DatePicker
            isValidDate={sameOrBeforeTodayValidation}
            value={reportDate}
            onChange={this.updateReportDate}
          />
        ) : (
          `${moment(currentDailyReport.report_date).format('MM/DD/YYYY')} - `
        )}
        {name}
      </React.Fragment>
    );
  };

  isSubDailyReport() {
    return this.subcontractor() || this.subTabSelected();
  }

  manager = () => {
    const {
      currentUser,
      currentProject,
      directory,
      currentDailyReport,
    } = this.props;

    return DailyReportPermissions.canManage(
      currentUser,
      currentProject,
      directory,
      currentDailyReport
    );
  };

  gcOar = () => {
    const { currentUser, currentProject } = this.props;

    return User.isGcOar(currentUser, currentProject);
  };

  subcontractor = () => {
    const { currentUser, currentProject } = this.props;

    return User.isContractor(currentUser, currentProject);
  };

  readOnly = () => {
    return !this.subcontractor() && !this.manager();
  };

  subTabSelected() {
    const { dailyReportsTabSelected } = this.props;
    return dailyReportsTabSelected === SubDailyReportTabValue;
  }

  submitTradeFormForSub = (isChildFloor) => {
    const {
      activeContributor,
      contributors,
      contributorsForm,
      activeEntryId,
      dispatch,
      editMode,
      projectId,
      currentDailyReport,
    } = this.props;

    const errors = this.getErrorsForSub();

    if (
      errors &&
      Object.keys(errors).length &&
      currentDailyReport.status !== 'draft'
    ) {
      this.setState({
        errors: { ...errors },
      });
      return;
    }

    let isThelastContributor = false;
    if (activeContributor) {
      isThelastContributor = +activeContributor === contributors.length;
    }

    if (activeEntryId) {
      const currentTrade = contributorsForm[activeEntryId];
      const value = {};
      value.floor = currentTrade.floor || '';
      value.area = currentTrade.area || '';
      if (currentTrade.isDelay) {
        value.project_open_item_attributes = {
          id: currentTrade.openItemId,
          assignee_id: currentTrade.openItemAssigneeId || '',
          recipient_ids:
            currentTrade.openItemRecipients &&
            currentTrade.openItemRecipients.map((rcpt) => rcpt[0]),
          description: currentTrade.openItemDesc || '',
          resolve_by: currentTrade.openItemResolveBy || '',
          critical: !!currentTrade.openItemCritical,
          images_attributes:
            currentTrade.openItemImages &&
            currentTrade.openItemImages.filter((img) => !!img.file),
          deleted_images_attributes:
            currentTrade.openItemDeletedImages &&
            currentTrade.openItemDeletedImages.filter((img) => !!img.id),
          documents_attributes:
            currentTrade.openItemDocuments &&
            currentTrade.openItemDocuments.filter((doc) => !!doc.file),
          deleted_documents_attributes:
            currentTrade.openItemDeletedDocuments &&
            currentTrade.openItemDeletedDocuments.filter((doc) => !!doc.id),
        };
      } else if (currentTrade.openItemId) {
        value.project_open_item_attributes = {
          id: currentTrade.openItemId,
          _destroy: true,
        };
      }

      if (currentTrade.isFieldWorkOrder) {
        value.field_work_order_attributes = {
          id: currentTrade.fwoId || '',
          number: currentTrade.fwoNumber || '',
          preset_task_ids:
            currentTrade.fwoPresetTasks &&
            currentTrade.fwoPresetTasks.length > 0
              ? currentTrade.fwoPresetTasks.map((task) => task[0])
              : [],
          preset_task_other: currentTrade.fwoCustomTask || '',
          work_performed: currentTrade.fwoWorkPerformed || '',
          comments: currentTrade.fwoComments || '',
          start_date: currentTrade.fwoStartDate || '',
          completion_date: currentTrade.fwoCompletionDate || '',
          labor: currentTrade.fwoLabor || '',
          materials: currentTrade.fwoMaterials || '',
        };
      } else if (currentTrade.fwoId) {
        value.field_work_order_attributes = {
          id: currentTrade.fwoId,
          _destroy: true,
        };
      }

      const resultTechnicians = [];
      currentTrade.technicians.forEach((technician) => {
        const newTechnician = {
          ...technician,
        };
        resultTechnicians.push(newTechnician);
        if (
          newTechnician.additionalInfo &&
          newTechnician.additionalInfo.length
        ) {
          newTechnician.additionalInfo.forEach((info) => {
            const newInfo = {
              ...info,
            };
            if (newTechnician.sub_technician) {
              newInfo.sub_technician = newTechnician.sub_technician;
            }
            newInfo.tech = newTechnician.tech;
            resultTechnicians.push(newInfo);
          });
        }
      });

      value.technicians_attributes = resultTechnicians.map((technician) => {
        let techValue = {
          hours_worked: technician.hoursAmount || '',
          started_at: utcTime(technician.workedFrom) || '',
          ended_at: utcTime(technician.workedTo) || '',
          location: technician.location || '',
          custom_task: technician.customTask || '',
          work_performed: technician.workPerformed || '',
          preset_task_ids:
            technician.presetTasks && technician.presetTasks.length > 0
              ? technician.presetTasks.map((task) => {
                  return task[0];
                })
              : '',
        };

        if (technician.id) {
          techValue.id = technician.id;
        }

        if (technician.sub_technician) {
          techValue.sub_technician_id = technician.sub_technician.value;
        }
        techValue.tech_number = technician.tech.value;

        return techValue;
      });
      value.deletedTechnicians = currentTrade.deletedTechnicians;
      value.images_attributes =
        currentTrade.images && currentTrade.images.filter((img) => !!img.file);
      value.deleted_images_attributes =
        currentTrade.deletedImages &&
        currentTrade.deletedImages.filter((img) => !!img.id);
      this.removeErrorsForSub();
      return dispatch(
        addDailyReportEntries(
          value,
          activeEntryId,
          currentTrade.images,
          isThelastContributor,
          editMode,
          projectId,
          this.isSubDailyReport(),
          isChildFloor
        )
      );
    }
  };

  submitTradeForm = (isChildFloor = false) => {
    const {
      activeContributor,
      contributors,
      contributorsForm,
      activeEntryId,
      dispatch,
      editMode,
      projectId,
      currentDailyReport,
    } = this.props;

    if (this.isSubDailyReport()) {
      return this.submitTradeFormForSub(isChildFloor);
    }
    const errors = this.getErrors();

    if (
      errors &&
      Object.keys(errors).length > 0 &&
      currentDailyReport.status !== 'draft'
    ) {
      this.setState({
        errors: { ...errors },
      });
      return;
    }

    let isThelastContributor = false;
    if (activeContributor) {
      isThelastContributor = +activeContributor === contributors.length;
    }

    if (activeEntryId) {
      const currentTrade = contributorsForm[activeEntryId];
      const value = {};
      value.preset_task_other = currentTrade.customTask || '';
      value.work_performed = currentTrade.workPerformed || '';
      value.comments = currentTrade.comments || '';
      value.floor = currentTrade.floor || '';
      value.area = currentTrade.area || '';
      if (currentTrade.isDelay) {
        value.project_open_item_attributes = {
          id: currentTrade.openItemId,
          assignee_id: currentTrade.openItemAssigneeId || '',
          recipient_ids:
            currentTrade.openItemRecipients &&
            currentTrade.openItemRecipients.map((rcpt) => rcpt[0]),
          description: currentTrade.openItemDesc || '',
          resolve_by: currentTrade.openItemResolveBy || '',
          critical: !!currentTrade.openItemCritical,
          images_attributes:
            currentTrade.openItemImages &&
            currentTrade.openItemImages.filter((img) => !!img.file),
          deleted_images_attributes:
            currentTrade.openItemDeletedImages &&
            currentTrade.openItemDeletedImages.filter((img) => !!img.id),
          documents_attributes:
            currentTrade.openItemDocuments &&
            currentTrade.openItemDocuments.filter((doc) => !!doc.file),
          deleted_documents_attributes:
            currentTrade.openItemDeletedDocuments &&
            currentTrade.openItemDeletedDocuments.filter((doc) => !!doc.id),
        };
      } else if (currentTrade.openItemId) {
        value.project_open_item_attributes = {
          id: currentTrade.openItemId,
          _destroy: true,
        };
      }

      value.deletedTechnicians = currentTrade.deletedTechnicians;
      value.technicians_attributes = currentTrade.technicians.map(
        (technician) => {
          let techValue = {
            hours_worked: technician.hoursAmount || '',
            started_at: utcTime(technician.workedFrom) || '',
            ended_at: utcTime(technician.workedTo) || '',
          };

          if (technician.id) {
            techValue.id = technician.id;
          }
          return techValue;
        }
      );
      value.preset_task_ids =
        currentTrade.presetTasks && currentTrade.presetTasks.length > 0
          ? currentTrade.presetTasks.map((task) => {
              return task[0];
            })
          : '';
      value.images_attributes =
        currentTrade.images && currentTrade.images.filter((img) => !!img.file);
      value.deleted_images_attributes =
        currentTrade.deletedImages &&
        currentTrade.deletedImages.filter((img) => !!img.id);
      return dispatch(
        addDailyReportEntries(
          value,
          activeEntryId,
          currentTrade.images,
          isThelastContributor,
          editMode,
          projectId,
          false,
          isChildFloor
        )
      );
    }
  };

  renderDateInput = () => {
    const {
      activeContributor,
      showReviewForm,
      editMode,
      isSubmitted,
      reportDate,
    } = this.props;

    if (!activeContributor && !showReviewForm && !editMode && !isSubmitted) {
      return (
        <div className="form-block">
          <FormControlBlock
            label={reportDate && 'Date'}
            control={
              <DatePicker
                isValidDate={sameOrBeforeTodayValidation}
                value={reportDate}
                placeholder="Date"
                onChange={this.handleDateChange}
              />
            }
          />
        </div>
      );
    }
  };

  currentContributors = () => {
    const { contributors, generalContactorName } = this.props;

    const values = [...contributors];
    if (generalContactorName) {
      values.unshift(generalContactorName);
    }
    return values;
  };

  renderTradesForm = () => {
    const { errors } = this.state;
    const {
      directory,
      activeContributor,
      showReviewForm,
      isSubmitted,
      currentUser,
      currentProject,
    } = this.props;

    let contributorsList = [];
    if (directory && directory.project_contributors) {
      const gcContributor = Project.gcContributor({
        project: currentProject,
        directory,
      });
      contributorsList = directory.project_contributors
        .filter((contrib) => {
          if (gcContributor && contrib.id === gcContributor.id) return false;

          if (this.subcontractor())
            return contrib.company_id === currentUser.accountable_id;

          return true;
        })
        .sort((contrib1, contrib2) =>
          contrib1.csi_code.division < contrib2.csi_code.division ? -1 : 1
        )
        .map((contrib) => {
          return { id: contrib.id, label: DailyReportUtil.tradeLabel(contrib) };
        });
    }

    const gcLabel = this.getGeneralContractorLabel();
    const gcOption = gcLabel && { id: 0, label: gcLabel };
    if (!this.subcontractor() && gcOption) {
      contributorsList.unshift(gcOption);
    }
    if (!activeContributor && !showReviewForm && !isSubmitted) {
      const values = this.currentContributors();
      return (
        directory &&
        directory.project_contributors && (
          <div className="form-block">
            <FormControlBlock
              label={values.length > 0 && 'Trades'}
              control={
                <MultipleDropdown
                  dropdownClassName="alternate-dropdown-control"
                  options={contributorsList}
                  currentValues={values}
                  placeholder="Trades"
                  onChange={(values) => this.handleContributorsChange(values)}
                  valid={!errors.contributorsField}
                />
              }
            />
          </div>
        )
      );
    }
  };

  renderSingleTradeForm = () => {
    const {
      activeContributor,
      showReviewForm,
      isSubmitted,
      currentDailyReport,
    } = this.props;
    const showForm = activeContributor && !showReviewForm && !isSubmitted;
    const subDailyReport = this.isSubDailyReport();
    if (showForm) {
      if (!subDailyReport) {
        return (
          <SingleTradeForm
            errors={this.state.errors}
            removeErrors={this.removeErrors}
            submitFailedErrors={this.submitFailedTradeFormErrors}
            currentDailyReport={currentDailyReport}
          />
        );
      } else {
        return (
          <SingleTradeFormForSub
            errors={this.state.errors}
            submitFailedErrors={this.submitFailedTradeFormErrors}
          />
        );
      }
    }
  };

  renderReviewForm = () => {
    const {
      showReviewForm,
      isSubmitted,
      projectId,
      currentDailyReport,
      dispatch,
      reportExtraInputs,
    } = this.props;
    if (showReviewForm && !isSubmitted && currentDailyReport) {
      return (
        <ReviewForm
          projectId={projectId}
          currentDailyReport={currentDailyReport}
          dispatch={dispatch}
          readOnly={this.readOnly()}
          reviewErrors={this.state.reviewErrors}
          errorsForStaff={this.state.errorsForStaff}
          errorsForOpenItem={this.state.errorsForOpenItem}
          clearReviewErrors={this.clearReviewErrors}
          reportExtraInputs={reportExtraInputs}
          handleExtraInputChange={this.handleExtraInputChange}
          generalContactorLabel={this.getGeneralContractorLabel()}
          clearErrorOnStaff={this.clearErrorOnStaff}
          clearErrorOnOpenItem={this.clearErrorOnOpenItem}
          isSub={this.isSubDailyReport()}
        />
      );
    }
  };

  renderCongratsView = () => {
    const { isSubmitted, currentDailyReport } = this.props;
    if (isSubmitted) {
      return (
        <p className="reports-modal-success-message">
          Daily Report #{currentDailyReport.number} submitted
        </p>
      );
    }
  };

  updateDailyReport = (attrs) => {
    const {
      dispatch,
      currentDailyReport,
      projectId,
      reportExtraInputs,
    } = this.props;
    if (currentDailyReport.id && projectId) {
      const values = {
        ...reportExtraInputs,
        status: attrs.status,
      };
      return dispatch(
        dailyReportFinaleUpdate(
          projectId,
          currentDailyReport.id,
          values,
          currentDailyReport.status,
          this.isSubDailyReport()
        )
      );
    }
  };

  isThereErrorsOnOpenItem = (openItem) => {
    return (
      !!openItem &&
      (!openItem.assignee_id || !openItem.resolve_by || !openItem.description)
    );
  };

  isThereErrorsOnFieldWorkOrder = (fieldWorkOrder) => {
    return (
      !!fieldWorkOrder &&
      (!fieldWorkOrder.number ||
        !fieldWorkOrder.work_performed ||
        !fieldWorkOrder.labor ||
        ((!fieldWorkOrder.preset_tasks ||
          !fieldWorkOrder.preset_tasks.length) &&
          !fieldWorkOrder.preset_task_other))
    );
  };

  isThereErrorOnReview = () => {
    const { daily_report_entries } = this.props.currentDailyReport;
    const { is_safety_accepted } = this.props.reportExtraInputs;
    const { settings } = this.props;
    if (daily_report_entries && daily_report_entries.length > 0) {
      const reviewErrors = [];

      daily_report_entries.forEach((report) => {
        if (this.isSubDailyReport()) {
          if (
            (!report.floor && settings && settings.multiple_floors) ||
            report.sub_daily_report_teches.length === 0 ||
            report.sub_daily_report_teches.some(
              (tech) =>
                !tech.hours_worked ||
                (tech.preset_tasks.length === 0 && !tech.preset_task_other)
            ) ||
            this.isThereErrorsOnOpenItem(report.project_open_item) ||
            this.isThereErrorsOnFieldWorkOrder(report.field_work_order)
          ) {
            reviewErrors.push(report.id);
          }
        } else {
          if (
            (!report.floor && settings && settings.multiple_floors) ||
            !report.work_performed ||
            report.technicians.length === 0 ||
            report.technicians.some((tech) => !tech.hours_worked) ||
            this.isThereErrorsOnOpenItem(report.project_open_item)
          ) {
            reviewErrors.push(report.id);
          }
        }
      });
      if (!this.isSubDailyReport() && !is_safety_accepted) {
        reviewErrors.push(this.props.currentDailyReport.id);
      }
      if (reviewErrors.length > 0) {
        this.setState({
          reviewErrors,
        });
        return reviewErrors;
      }
    } else {
      if (!this.isSubDailyReport() && !is_safety_accepted) {
        const reviewErrors = [];
        reviewErrors.push(this.props.currentDailyReport.id);
        this.setState({
          reviewErrors,
        });
        return reviewErrors;
      }
    }
  };

  isThereErrorOnStaff = () => {
    const isThereErrors = this.getErrorsForMembers(true).length > 0;
    this.setState({
      errorsForStaff: isThereErrors,
    });
    return isThereErrors;
  };

  clearErrorOnStaff = () => {
    this.setState({
      errorsForStaff: false,
      errorsMemberForm: [],
    });
  };

  setErrorsForOpenItem = () => {
    const { project_open_item } = this.props.currentDailyReport;
    const isThereErrors = this.isThereErrorsOnOpenItem(project_open_item);
    this.setState({
      errorsForOpenItem: isThereErrors,
    });
    return isThereErrors;
  };

  clearErrorOnOpenItem = () => {
    this.setState({
      errorsForOpenItem: false,
    });
  };

  submitReviewForm = (event, status) => {
    const { gc_staff_on_site } = this.props.currentDailyReport;
    const reviewErrors = this.isThereErrorOnReview();
    const staffErrors = gc_staff_on_site && this.isThereErrorOnStaff();
    const openItemErrors = this.setErrorsForOpenItem();
    const statusRes = status || 'submitted';

    if (!reviewErrors && !staffErrors && !openItemErrors) {
      this.updateDailyReport({ status: statusRes }).then((response) => {
        if (response.ok && !this.props.isSubmitted) {
          this.handleClose();
        }
      });
    } else {
      this.setState({ submitFailed: true });
    }
  };

  updateReportDate = (newReportDate) => {
    const {
      contributors,
      projectId,
      currentDailyReport,
      dispatch,
      editMode,
      generalContactorName,
    } = this.props;

    const promise = dispatch(
      updateDailyReport(
        contributors,
        projectId,
        currentDailyReport,
        editMode,
        newReportDate,
        generalContactorName,
        this.isSubDailyReport()
      )
    );
    promise &&
      promise.then((response) => {
        if (response.ok) {
          this.handleClose();
        }
      });
  };

  onApprove = (e) => {
    this.submitReviewForm(e, 'approved');
  };

  handleSaveDraftEntry = (event) => {
    event.persist();
    const promise = this.submitTradeForm(false);
    promise &&
      promise.then((response) => {
        if (response.ok) {
          this.handleClose(event);
        }
      });
  };

  handleSaveDraftReport = (event) => {
    event.persist();
    const promise = this.updateDailyReport({ status: 'draft' });
    promise &&
      promise.then((response) => {
        if (response.ok) {
          this.handleClose(event);
        }
      });
  };

  handleSaveDraftReportAndMemberForm = (event) => {
    event.persist();
    const promise = this.saveMemberWithDraft();
    promise &&
      promise.then((response) => {
        if (response.ok) {
          this.handleClose(event);
        }
      });
  };

  handleAddReportAndClose = (event) => {
    event.persist();
    const promise = this.addReport(true);
    promise &&
      promise.then((response) => {
        if (response.ok) {
          this.handleClose(event);
        }
      });
  };

  renderCloseOrDraftButton = () => {
    const {
      showReviewForm,
      activeContributor,
      isSubmitted,
      currentDailyReport,
      editMode,
    } = this.props;
    const showClosed =
      this.readOnly() ||
      isSubmitted ||
      (currentDailyReport && currentDailyReport.status === 'submitted');
    let handleClick = this.handleAddReportAndClose;

    if (showClosed) {
      handleClick = this.handleClose;
    } else if (showReviewForm) {
      handleClick = this.handleSaveDraftReport;
    } else if (!!activeContributor) {
      handleClick = this.handleSaveDraftEntry;
    }

    if (currentDailyReport && currentDailyReport.status === 'approved') {
      return (
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.WHITE}
          label="Close"
          onClick={this.handleClose}
        />
      );
    }

    return (
      !!showReviewForm &&
      !(
        editMode &&
        currentDailyReport &&
        currentDailyReport.status === 'submitted'
      ) && (
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.WHITE}
          label={showClosed ? 'Close' : 'Save Draft'}
          onClick={handleClick}
        />
      )
    );
  };

  handleDiscardDraft = () => {
    this.setState({ showDeleteConfirmation: true });
  };

  handleDeleteConfirmation = (confirmed) => {
    this.setState({ showDeleteConfirmation: false });
    confirmed && this.handleDelete();
  };

  handleDelete = () => {
    const { currentProject, currentDailyReport, dispatch } = this.props;
    dispatch(
      deleteReportRequest(
        currentProject.id,
        currentDailyReport.id,
        this.isSubDailyReport()
      )
    ).then((response) => {
      if (response.ok) {
        this.handleClose();
      }
    });
  };

  handleEditTrades = () => {
    this.setState({ originalContributorsList: this.currentContributors() });
    this.props.dispatch(setReviewFormAction(false));
    this.props.dispatch(setEditMode(true));
  };

  handleShowEmailModal = () => {
    this.props.dispatch(setShowEmailModal(true));
  };

  fromTradesToReview = () => {
    if (this.errorsNoTradesAndNoGc()) {
      return;
    }

    this.addReport(true)
      .then((response) => {
        if (response.ok) {
          const existingContributors = Object.keys(this.props.contributorsForm);
          const newContributors = [];
          if (
            response.dailyReport.sub_daily_report_entries &&
            response.dailyReport.sub_daily_report_entries.length
          ) {
            response.dailyReport.sub_daily_report_entries.forEach((entry) => {
              const entryExist = existingContributors.find(
                (contribId) => +contribId === +entry.id
              );
              if (!entryExist) {
                newContributors.push(entry.id);
              }
            });
          }
          return this.props.dispatch(
            loadFullReportsRequest(
              response.dailyReport.project_id,
              response.dailyReport.id,
              this.isSubDailyReport(),
              newContributors
            )
          );
        }

        return response;
      })
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(setReviewFormAction(true));
        }
      })
      .catch((error) => this.props.dispatch(showErrorMessage(error)));
  };

  renderDiscardDraftButton = () => {
    const { isSubmitted, currentDailyReport, showReviewForm } = this.props;
    const showDiscardDraft =
      !isSubmitted &&
      !!currentDailyReport &&
      !!showReviewForm &&
      currentDailyReport.status === 'draft';

    return (
      showDiscardDraft && (
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.WHITE}
          label="Discard Draft"
          onClick={this.handleDiscardDraft}
        />
      )
    );
  };

  renderEditTradesButton = () => {
    const { showReviewForm, isSubmitted, currentDailyReport } = this.props;

    if (this.readOnly()) {
      return null;
    }

    if (
      showReviewForm &&
      !isSubmitted &&
      currentDailyReport.status !== 'approved' &&
      (this.subcontractor() || (this.manager() && !this.subTabSelected()))
    ) {
      return (
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.WHITE}
          label="Trades"
          onClick={this.handleEditTrades}
        />
      );
    }

    return null;
  };

  renderEmailButton = () => {
    const { currentDailyReport, showReviewForm } = this.props;
    if (
      currentDailyReport &&
      (currentDailyReport.status === 'submitted' ||
        currentDailyReport.status === 'approved') &&
      showReviewForm &&
      !this.isSubDailyReport()
    ) {
      return (
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.WHITE}
          label="Email"
          onClick={this.handleShowEmailModal}
        />
      );
    }
  };

  renderNextOrConfirmButtons = (currentEntry) => {
    const {
      showReviewForm,
      activeContributor,
      contributors,
      isSubmitted,
      editMode,
      currentDailyReport,
      addFloor,
    } = this.props;

    if (this.readOnly()) {
      return null;
    }

    if (isSubmitted) {
      return null;
    }

    if (currentDailyReport && currentDailyReport.status === 'approved') {
      return null;
    }

    if (
      showReviewForm &&
      currentDailyReport &&
      currentDailyReport.status !== 'approved' &&
      ((this.manager() && !this.subTabSelected()) || this.subcontractor())
    ) {
      return (
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          label="Submit"
          onClick={this.submitReviewForm}
        />
      );
    }

    if (showReviewForm) {
      return;
    }

    let showNextButton = true;
    const entryId =
      currentEntry &&
      (currentEntry.sub_daily_report_entry_id ||
        currentEntry.daily_report_entry_id);
    if (activeContributor) {
      showNextButton = +activeContributor < contributors.length;
    }

    if (showNextButton && !editMode && !addFloor) {
      return (
        <React.Fragment>
          {activeContributor > 0 && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Back"
              onClick={this.prevContribOrList}
            />
          )}
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label="Next"
            onClick={activeContributor ? this.nextContrib : this.addReport}
          />
        </React.Fragment>
      );
    } else {
      if (addFloor || (currentEntry && entryId)) {
        return (
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label="Save floor"
            onClick={() => {
              this.saveFloor(currentEntry);
            }}
          />
        );
      }
      return (
        <React.Fragment>
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.WHITE}
            label={editMode ? 'Cancel' : 'Back'}
            onClick={editMode ? this.backTradesAction : this.prevContribOrList}
          />
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label={editMode ? 'Save' : 'Review'}
            onClick={
              activeContributor
                ? () => this.submitTradeForm()
                : this.fromTradesToReview
            }
          />
        </React.Fragment>
      );
    }
  };

  handleDuplicateModalClose = () => {
    this.setState({ showDuplicateModal: false });
  };

  handleShowDuplicateModal = () => {
    this.setState({ showDuplicateModal: true });
  };

  renderDuplicateButton = () => {
    const { currentDailyReport, showReviewForm, isSubmitted } = this.props;
    if (
      !isSubmitted &&
      showReviewForm &&
      currentDailyReport &&
      currentDailyReport.status !== 'draft' &&
      ((this.isSubDailyReport() && !this.gcOar()) ||
        (!this.isSubDailyReport() && !this.subcontractor()))
    ) {
      return (
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          label="Duplicate"
          onClick={this.handleShowDuplicateModal}
        />
      );
    }
  };

  renderApproveButton = () => {
    const { currentDailyReport, showReviewForm, isSubmitted } = this.props;
    if (
      !isSubmitted &&
      this.isSubDailyReport() &&
      this.manager() &&
      showReviewForm &&
      currentDailyReport &&
      currentDailyReport.status !== 'approved'
    ) {
      return (
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          label="Approve"
          onClick={this.onApprove}
        />
      );
    }
  };

  handleSendEmail = (emailsData) => {
    this.props.dispatch(
      sendDailyReportEmails(
        this.props.currentDailyReport.id,
        this.isSubDailyReport() ? 'SubDailyReport' : 'DailyReport',
        emailsData
      )
    );
  };

  buildEmailModalSubject = () => {
    const { currentProject, currentDailyReport } = this.props;
    return `${currentProject.number} ${currentProject.name} - Daily Report #${currentDailyReport.number}`;
  };

  buildEmailModalMessage = () => {
    const { currentProject, currentDailyReport } = this.props;
    return `Please find the attached Daily Report #${currentDailyReport.number} for Project ${currentProject.name}. If you have any questions, please reach out via chat or email.`;
  };

  saveMemberWithDraft = () => {
    const {
      dispatch,
      projectId,
      currentDailyReport,
      members,
      deletedMembers,
      reportOpenItem,
      gcImages,
      gcDeletedImages,
    } = this.props;
    const errors = this.getErrorsForSaveDraft();
    if (errors && errors.length > 0) {
      this.setState({
        errorsMemberForm: [...errors],
      });
    } else {
      return dispatch(
        addDailyReportMembers(
          projectId,
          currentDailyReport.id,
          members,
          deletedMembers,
          reportOpenItem,
          true,
          false,
          gcImages,
          gcDeletedImages
        )
      );
    }
  };

  saveFloor = (currentEntry) => {
    const { dispatch } = this.props;
    const entryId =
      currentEntry.sub_daily_report_entry_id ||
      currentEntry.daily_report_entry_id;
    const submitTradeFormPromise = this.submitTradeForm(entryId);
    if (submitTradeFormPromise) {
      submitTradeFormPromise.then((response) => {
        if (response.ok) {
          dispatch(setDailyReportAddFloor(false));
          dispatch(setReviewFormAction(false));
          dispatch(setActiveEntriesId(entryId));
        }
      });
    }
  };

  nextMemberAction = () => {
    const {
      dispatch,
      projectId,
      currentDailyReport,
      members,
      deletedMembers,
      reportOpenItem,
      gcImages,
      gcDeletedImages,
    } = this.props;
    const isDraft = currentDailyReport.status === 'draft';
    const errorsMemberForm = this.getErrorsForMembers(this.state.submitFailed);
    const errorsOpenItemForm = this.getErrorsForOpenItem(
      this.state.submitFailed
    );
    if (
      !isDraft &&
      ((errorsMemberForm && errorsMemberForm.length > 0) ||
        (errorsOpenItemForm && Object.keys(errorsOpenItemForm).length > 0))
    ) {
      this.setState({
        errorsMemberForm: [...errorsMemberForm],
        errorsOpenItemForm: { ...errorsOpenItemForm },
      });
    } else {
      dispatch(
        addDailyReportMembers(
          projectId,
          currentDailyReport.id,
          members,
          deletedMembers,
          reportOpenItem,
          isDraft,
          true,
          gcImages,
          gcDeletedImages
        )
      );
    }
  };

  backMemberAction = () => {
    const { dispatch, editMode } = this.props;
    if (editMode) {
      dispatch(restoreMemberForm());
      dispatch(setReviewFormAction(true));
      dispatch(setEditMode(false));
    } else {
      dispatch(setActiveContributor(0));
    }
    dispatch(setMemberForm(false));
  };

  backTradesAction = () => {
    const { activeContributor, dispatch } = this.props;
    const { originalContributorsList } = this.state;
    if (activeContributor) {
      this.setTechnicianErrors([]);
      dispatch(restoreTradeForm());
    } else {
      this.handleContributorsChange(originalContributorsList);
    }
    dispatch(setEditMode(false));
    dispatch(setReviewFormAction(true));
    dispatch(setActiveContributor(0));
  };

  renderMemberButtons = () => {
    const { editMode, contributors } = this.props;

    return (
      <React.Fragment>
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.WHITE}
          label={editMode ? 'Cancel' : 'Back'}
          onClick={this.backMemberAction}
        />
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          label={
            editMode ? 'Save' : contributors.length === 0 ? 'Review' : 'Next'
          }
          onClick={this.nextMemberAction}
        />
      </React.Fragment>
    );
  };

  missingFields = () => {
    const { showReviewForm, activeContributor, memberForm } = this.props;
    const {
      reviewErrors,
      errorsForStaff,
      errorsForOpenItem,
      errors,
      errorsMemberForm,
      errorsOpenItemForm,
    } = this.state;
    return (
      (showReviewForm &&
        !activeContributor &&
        !memberForm &&
        ((reviewErrors && reviewErrors.length > 0) ||
          errorsForStaff ||
          errorsForOpenItem)) ||
      (!!activeContributor &&
        errors &&
        !!Object.keys(errors).find((errorKey) => errors[errorKey])) ||
      (memberForm &&
        ((errorsMemberForm && errorsMemberForm.length > 0) ||
          (errorsOpenItemForm && Object.keys(errorsOpenItemForm).length > 0)))
    );
  };

  render() {
    const {
      show,
      memberForm,
      projectId,
      currentDailyReport,
      activeEntryId,
      isSubmitted,
      showReviewForm,
      activeContributor,
    } = this.props;
    const {
      showDeleteConfirmation,
      showDuplicateModal,
      errorsMemberForm,
      errorsOpenItemForm,
    } = this.state;
    let currentEntry;
    if (currentDailyReport) {
      if (currentDailyReport.daily_report_entries) {
        currentEntry = currentDailyReport.daily_report_entries.find(
          (val) => val.id === activeEntryId
        );
      }
      if (currentDailyReport.sub_daily_report_entries) {
        currentEntry = currentDailyReport.sub_daily_report_entries.find(
          (val) => val.id === activeEntryId
        );
      }
    }

    return (
      <FormModal
        id="daily-report-form-modal"
        className={classnames({
          'daily-report-form-modal': true,
          'submitted-message': isSubmitted,
          'member-form': memberForm,
          'active-contributor': activeContributor,
          'review-form': showReviewForm,
          'initial-form':
            !memberForm &&
            !activeContributor &&
            !showReviewForm &&
            !isSubmitted,
        })}
        show={show}
        onClose={this.handleClose}
        subTitle={this.generateTitle()}
      >
        <div className="daily-report-form-body">
          {memberForm ? (
            <MemberFormModal
              clearErrors={this.clearErrors}
              projectId={projectId}
              errors={{ errorsMemberForm, errorsOpenItemForm }}
              submitFailedErrors={this.submitFailedMembersFormErrors}
            />
          ) : (
            <React.Fragment>
              {this.renderDateInput()}
              {this.renderTradesForm()}
              {this.renderSingleTradeForm()}
              {this.renderReviewForm()}
              {this.renderCongratsView()}
            </React.Fragment>
          )}
        </div>
        <div className="reports-modal-actions">
          {this.missingFields() && !isSubmitted && (
            <h4 className="error-message">
              There are missing fields, please review.
            </h4>
          )}
          <div className="buttons-container">
            {memberForm ? (
              this.renderMemberButtons()
            ) : (
              <React.Fragment>
                {this.renderDiscardDraftButton()}
                {this.renderCloseOrDraftButton()}
                {this.renderEmailButton()}
                {this.renderEditTradesButton()}
                {this.renderNextOrConfirmButtons(currentEntry)}
                {this.renderDuplicateButton()}
                {this.renderApproveButton()}
              </React.Fragment>
            )}
          </div>
        </div>
        {showDeleteConfirmation && (
          <ConfirmationModal
            show={showDeleteConfirmation}
            message="Are you sure you want to discard this Daily Report?"
            onHide={this.handleDeleteConfirmation}
          />
        )}
        {this.props.showEmailModal && (
          <ProjectEmailModal
            subTitle={this.props.currentProject.name}
            handleSendEmail={this.handleSendEmail}
            projectId={this.props.projectId}
            initialSubject={this.buildEmailModalSubject()}
            initialMessage={this.buildEmailModalMessage()}
          />
        )}
        {showDuplicateModal && (
          <DuplicateDailyReportModal
            showModal={showDuplicateModal}
            isSubDailyReport={this.isSubDailyReport()}
            onSubmit={this.handleClose}
            onClose={this.handleDuplicateModalClose}
          />
        )}
      </FormModal>
    );
  }
}

export default connect((state) => {
  return {
    directory: getDirectory(state),
    settings: getSettings(state),
    currentUser: getCurrentUser(state),
    contributors: getContributors(state),
    contributorsForm: getContributorsForm(state),
    activeContributor: getActiveContributor(state),
    currentDailyReport: getCurrentDailyReport(state),
    showReviewForm: getShowReviewForm(state),
    isSubmitted: getDailyReportIsSubmitted(state),
    currentProject: getCurrentProject(state),
    editMode: getEditMode(state),
    activeEntryId: getActiveEntryId(state),
    showEmailModal: getShowEmailModal(state),
    reportDate: getReportDate(state),
    reportExtraInputs: getReportExtraInputs(state),
    generalContactorName: getGeneralContactorName(state),
    memberForm: getMemberForm(state),
    members: getMembers(state),
    deletedMembers: getDeletedMembers(state),
    reportOpenItem: getReportOpenItem(state),
    gcImages: getGCImages(state),
    gcDeletedImages: getGCDeletedImages(state),
    dailyReportsTabSelected: getDailyReportsTabSelected(state),
    addFloor: getAddFloor(state),
  };
})(AddDailyReportModal);
