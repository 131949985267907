import React from 'react';
import { connect } from 'react-redux';

import FormModal from 'components/shared/modal/FormModal';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import Input from 'components/shared/input/Input';
import Selector from 'components/shared/selector/Selector';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

import { getProjectSites } from 'components/admin/projects/details/project-site-area/store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getShowAddProjectAreaModal,
  getEditSiteAreaModal,
} from './store/selectors';
import useAddProjectAreaModal from './AddProjectAreaModal.hook';
import './AddProjectSiteArea.css';

const AddProjectAreaModal = (props) => {
  const { show, edit } = props;
  const {
    handleClose,
    handleFormChange,
    handleSubmit,
    form,
    invalidForm,
    siteOptions,
    areaOptions,
  } = useAddProjectAreaModal(props);
  const InputEditArea = (
    <FormControlBlock
      hideBottomLabel
      className="input-schedule"
      label={form.areaId ? (edit ? 'Area Name' : 'Duplicate Area') : ''}
      control={
        <Selector
          className="select-dropdown"
          placeholder={edit ? 'Area Name' : 'Duplicate Area'}
          value={form.areaId}
          onChange={(option) =>
            handleFormChange({ areaId: option ? option.value : option })
          }
          valid={!(form.errors && form.errors.areaId)}
          options={areaOptions}
        />
      }
    />
  );

  const InputSite = (
    <FormControlBlock
      hideBottomLabel
      className="input-schedule"
      label={form.siteId && 'Location Name'}
      control={
        <Selector
          className="select-dropdown"
          placeholder={'Location Name'}
          value={form.siteId}
          onChange={(option) =>
            handleFormChange({ siteId: option ? option.value : option })
          }
          valid={!(form.errors && form.errors.siteId)}
          options={siteOptions}
        />
      }
    />
  );

  const InputName = (
    <FormControlBlock
      hideBottomLabel
      className="input-name"
      label={form.name && `${edit ? 'Modified' : 'Area'} Name`}
      control={
        <Input
          type={'text'}
          placeholder={`${edit ? 'Modified' : 'Area'} Name`}
          value={form.name}
          valid={!(form.errors && form.errors.name)}
          onChange={(name) => handleFormChange({ name })}
        />
      }
    />
  );

  const InputDelete = (
    <LabeledCheckBox
      label={'Delete Area'}
      checked={form.delete}
      shape={'round'}
      onChange={(d) => handleFormChange({ delete: d })}
    />
  );

  return (
    <FormModal
      className="add-project-site"
      show={show}
      subTitle={`${edit ? 'Edit' : 'Add'} Area`}
      onClose={handleClose}
    >
      {edit && <div className="block-container">{InputEditArea}</div>}
      <div className="block-container">
        {!edit && InputSite}
        {InputName}
        {edit && <span className="or-span">or</span>}
        {edit && InputDelete}
      </div>
      {invalidForm && (
        <span className="error-message">
          There are some missing fields, please review.
        </span>
      )}
      <div className="button-container">
        <Button
          id="cancel-area"
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.WHITE}
          label="Cancel"
          onClick={() => handleClose(false)}
        />
        <Button
          id="add-area"
          label={form.delete ? 'Delete' : 'Save'}
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          onClick={handleSubmit}
        />
      </div>
    </FormModal>
  );
};

export default connect((state) => ({
  currentProject: getCurrentProject(state),
  projectSites: getProjectSites(state),
  show: getShowAddProjectAreaModal(state),
  edit: getEditSiteAreaModal(state),
}))(AddProjectAreaModal);
