import {
  TRANSFER_WORK_ORDER_CLIENT_SELECT,
  TRANSFER_WORK_ORDER_MESSAGE_MODAL_SHOW,
  TRANSFER_WORK_ORDER_MODAL_SHOW,
} from 'components/admin/work-orders/details/transfer/store/action-types';

const initialState = {
  selectedClient: null,
  showModal: false,
  showMessageModal: false,
};

export default function transferWorkOrderUiReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case TRANSFER_WORK_ORDER_CLIENT_SELECT:
      return {
        ...state,
        selectedClient: action.payload,
      };

    case TRANSFER_WORK_ORDER_MESSAGE_MODAL_SHOW:
      return {
        ...state,
        showMessageModal: action.payload,
      };

    case TRANSFER_WORK_ORDER_MODAL_SHOW:
      return {
        ...state,
        selectedClient: action.payload ? null : state.selectedClient,
        showModal: action.payload,
      };

    default:
      return state;
  }
}
