import { createSelector } from 'reselect';

export const getErrors = createSelector(
  (state) => state.setPassword.ui.errors,
  (errors) => {
    return errors;
  }
);

export const getPassword = createSelector(
  (state) => state.setPassword.ui.password,
  (password) => {
    return password;
  }
);

export const getPasswordConfirmation = createSelector(
  (state) => state.setPassword.ui.passwordConfirmation,
  (passwordConfirmation) => {
    return passwordConfirmation;
  }
);
