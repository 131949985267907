/* eslint-disable no-useless-escape */
import React, { Component, Fragment } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import DatePicker from 'components/shared/date-picker/DatePicker';
import CurrencyInput from 'components/CurrencyInput';
import moment from 'moment';
import numeral from 'numeral';
import { calculateDateDuration } from 'services/utils/date-util';
import { importContactInformation } from 'services/utils/project-report-util';
import Date from 'components/shared/text/Date';
import dragDropIcon from 'components/shared/dragDropIcon.svg';
import CheckBox from '../../../../../shared/checkbox/CheckBox';
import './ReportsForm.css';

const headers = [
  'DESCRIPTION *',
  'ORIGINAL CONTRACT*',
  'ADD SERVICES',
  'TOTAL AMOUNT',
  'PAID TO DATE',
  'BALANCE TO COMPLETE',

  'START *',
  'COMPLETION *',
  'DURATION',
  'INCLUDE AMOUNT',

  'ACTIONS',
];

class ContractInfoTable extends Component {
  static propTypes = {
    darkMode: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape).isRequired,
    onOrderChange: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    onAddMultiple: PropTypes.func.isRequired,
    onResolved: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    status: PropTypes.string,
    canDelete: PropTypes.bool.isRequired,
    reportId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    status: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      cols: [],
    };
  }

  componentDidMount() {
    this.cols();
  }

  cols = () => {
    let newCols = [];
    if (headers.length > 0) {
      for (let i = 0; i < headers.length; i++) {
        newCols.push(<col key={i + 1} />);
      }
    }
    this.setState({ cols: newCols });
  };

  onDragEnd = (result) => {
    const { onOrderChange } = this.props;
    const { destination, source } = result;
    if (!destination || destination.index === source.index) {
      return;
    }
    onOrderChange(result);
  };

  onImportFromExcel = (item) => (e) => {
    const data = importContactInformation(e);

    if (data.length > 0) {
      data[0].id = item.id;
      this.props.onAddMultiple(item, data);
      e.target.blur();
    }
  };

  toDateFormat = (value) => {
    return value ? <Date value={value} format="MM/DD/YYYY" /> : '--';
  };

  toFormat = (number) => {
    if (!number && number !== 0) {
      return '--';
    }
    const currency = numeral(number).format('$0,0.00');
    const result = currency.endsWith('.00') ? currency.slice(0, -3) : currency;
    return result;
  };

  renderButtonAction = (key, label, onClick) => (
    <span
      key={key}
      className={classnames({
        'select-item': true,
        'select-item-active': true,
      })}
      onClick={onClick}
    >
      {label}
    </span>
  );

  viewActions = (item) => {
    const { onResolved, onDelete, canDelete } = this.props;
    const isCurrent = !item.previous_published_report_id || item.isEdit;
    return (
      <div className="table-action">
        {!isCurrent &&
          this.renderButtonAction('resolved', 'Resolved', () =>
            onResolved({
              ...item,
              resolved_at: moment(new Date()).format('YYYY-MM-DD'),
            })
          )}
        {(canDelete || isCurrent) &&
          this.renderButtonAction('delete', 'Delete', () =>
            onDelete(item, !item.isEdit)
          )}
      </div>
    );
  };

  calculateTotal = (item) => {
    const total = Number(item.amount || 0) + Number(item.add_services || 0);

    return this.toFormat(total ? total : undefined);
  };

  calculateBalance = (item) => {
    const total = Number(item.amount || 0) + Number(item.add_services || 0);
    const paid = Number(item.paid_to_date || 0);
    const balance = total - paid;

    return this.toFormat(balance ? balance : undefined);
  };

  renderRows = (item) => {
    const { onValueChange, darkMode, missingField } = this.props;
    const isCurrent = !item.previous_published_report_id || item.isEdit;

    return (
      <Fragment key={item.id}>
        {!item._destroy && !item.resolved_at ? (
          <Fragment>
            <td key={1} className="td-icon">
              <div className="drag-drop-icon">
                <img src={dragDropIcon} alt="drag and drop" />
              </div>
              <div className="separate-icon">
                {isCurrent ? (
                  <TextareaAutosizeWrapper
                    placeholder="Description"
                    value={item.description}
                    onChange={(value) =>
                      onValueChange('description', value, item)
                    }
                    onPaste={this.onImportFromExcel(item)}
                    valid={!(missingField && !item.description)}
                  />
                ) : (
                  item.description
                )}
              </div>
            </td>
            <td key={2}>
              {isCurrent ? (
                <CurrencyInput
                  placeholder="--"
                  type="text"
                  value={item.amount || item.amount === 0 ? +item.amount : ''}
                  onChange={(e) =>
                    onValueChange(
                      'amount',
                      e.target.value.replace(/[^0-9\.]/g, ''),
                      item
                    )
                  }
                  darkMode={darkMode}
                  valid={!(missingField && !item.amount)}
                />
              ) : (
                this.toFormat(item.amount)
              )}
            </td>
            <td key={3}>
              <CurrencyInput
                placeholder="--"
                type="text"
                value={
                  item.add_services || item.add_services === 0
                    ? +item.add_services
                    : ''
                }
                onChange={(e) =>
                  onValueChange(
                    'add_services',
                    e.target.value.replace(/[^0-9\.]/g, ''),
                    item
                  )
                }
                darkMode={darkMode}
              />
            </td>
            <td key={4}>{this.calculateTotal(item)}</td>
            <td key={5}>
              <CurrencyInput
                placeholder="--"
                type="text"
                value={
                  item.paid_to_date || item.paid_to_date === 0
                    ? +item.paid_to_date
                    : ''
                }
                onChange={(e) =>
                  onValueChange(
                    'paid_to_date',
                    e.target.value.replace(/[^0-9\.]/g, ''),
                    item
                  )
                }
                darkMode={darkMode}
              />
            </td>
            <td key={6}>{this.calculateBalance(item)}</td>
            <td key={7}>
              {isCurrent ? (
                <DatePicker
                  key="start"
                  value={item.start}
                  onChange={(value) => onValueChange('start', value, item)}
                  valid={!(missingField && !item.start)}
                  clearable
                  hideUnderline
                />
              ) : (
                this.toDateFormat(item.start)
              )}
            </td>
            <td key={8}>
              {isCurrent ? (
                <DatePicker
                  key="completion"
                  value={item.completion}
                  onChange={(value) => onValueChange('completion', value, item)}
                  valid={!(missingField && !item.completion)}
                  clearable
                  hideUnderline
                />
              ) : (
                this.toDateFormat(item.completion)
              )}
            </td>
            <td key={9}>
              {calculateDateDuration(item.start, item.completion)}
            </td>
            <td key={10}>
              <div className="row-justify-center">
                <CheckBox
                  key="contract-information-include-amount"
                  className="project-info-checkbox"
                  checked={item.include_amount}
                  onChange={() =>
                    onValueChange('include_amount', !item.include_amount, item)
                  }
                />
              </div>
            </td>
            <td key={11}>{this.viewActions(item)}</td>
          </Fragment>
        ) : null}
      </Fragment>
    );
  };

  render() {
    const { data, canDelete } = this.props;
    const { cols } = this.state;

    const isAction = data.find(
      (item) => !item._destroy && !item.resolved_at && !item.isEdit
    );
    const header = [
      'DESCRIPTION *',
      'ORIGINAL CONTRACT*',
      'ADD SERVICES',
      'TOTAL AMOUNT',
      'PAID TO DATE',
      'BALANCE TO COMPLETE',

      'START *',
      'COMPLETION *',
      'DURATION',
      'INCLUDE AMOUNT',

      canDelete || isAction ? 'ACTION' : '',
    ];

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="contract-info">
          {(provided) => (
            <div
              {...provided.droppableProps}
              className="table-with-form contract-info"
              ref={provided.innerRef}
            >
              <Table
                className={classnames({
                  'simple-table': true,
                  'projects-table': true,
                  'field-project-report-contract-information-table': true,
                })}
              >
                {cols.length > 0 && <colgroup>{cols}</colgroup>}
                <thead>
                  <tr>
                    {header.map((element, index) => {
                      return (
                        <th key={index}>
                          <span>{element}</span>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => {
                    return (
                      <Draggable
                        key={item.id}
                        draggableId={item.id.toString()}
                        index={index}
                      >
                        {(provided, shapshot) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                            key={item.id}
                            className={`${
                              shapshot.isDragging ? 'dragging' : ''
                            }`}
                          >
                            {this.renderRows(item)}
                          </tr>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </tbody>
              </Table>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default ContractInfoTable;
