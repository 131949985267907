import React, { Component } from 'react';
import { Col, ControlLabel, Form, FormGroup } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import 'components/admin/projects/details/project-meeting-minutes/MeetingInfoForm.css';
import RadioButton from 'components/shared/radio-button/RadioButton';
import FormTimePicker from 'components/shared/form/FormTimePicker';
import FormDropDown from 'components/shared/form/FormDropDown';
import FormInput from 'components/shared/form/FormInput';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

class MeetingInfoForm extends Component {
  renderRadioButton = (props) => {
    return (
      <RadioButton
        value={props.input.value}
        onChange={(value) => props.input.onChange(value)}
        {...props}
      />
    );
  };

  render() {
    return (
      <div className="meeting-info-form">
        <Form horizontal onSubmit={this.props.handleSubmit}>
          <h4 className="small-regular">Weekly Meeting</h4>
          <FormGroup>
            <Col componentClass={ControlLabel} sm={2} className="small-regular">
              Day
            </Col>
            <Col sm={10}>
              <Field
                component={this.renderRadioButton}
                name="dayOfWeek"
                orientation="horizontal"
                options={[
                  { value: 1, label: 'Monday' },
                  { value: 2, label: 'Tuesday' },
                  { value: 3, label: 'Wednesday' },
                  { value: 4, label: 'Thursday' },
                  { value: 5, label: 'Friday' },
                  { value: 6, label: 'Saturday' },
                  { value: 0, label: 'Sunday' },
                ]}
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <Col componentClass={ControlLabel} sm={2} className="small-regular">
              Time
            </Col>
            <Col sm={10}>
              <Field
                component={FormTimePicker}
                name="startsAt"
                placeholder="From"
              />
              <span className="meeting-info-time-to small-regular">To</span>
              <Field
                component={FormTimePicker}
                name="endsAt"
                placeholder="To"
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <Col componentClass={ControlLabel} sm={2} className="small-regular">
              Time Zone
            </Col>
            <Col sm={4}>
              <Field
                component={FormDropDown}
                name="timeZone"
                options={[
                  {
                    value: 'Pacific Time (US & Canada)',
                    label: 'Pacific Time (US & Canada)',
                  },
                  {
                    value: 'Central Time (US & Canada)',
                    label: 'Central Time (US & Canada)',
                  },
                  {
                    value: 'Eastern Time (US & Canada)',
                    label: 'Eastern Time (US & Canada)',
                  },
                ]}
                placeholder="Time Zone"
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <Col componentClass={ControlLabel} sm={2} className="small-regular">
              Meeting Location
            </Col>
            <Col sm={4}>
              <Field
                component={FormInput}
                name="meetingLocation"
                placeholder="Meeting Location"
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <Col componentClass={ControlLabel} sm={2} className="small-regular">
              Site Walk
            </Col>
            <Col sm={4}>
              <Field
                component={FormInput}
                name="siteWalk"
                placeholder="Site Walk"
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <Col componentClass={ControlLabel} sm={2} className="small-regular">
              Call In Info
            </Col>
            <Col sm={4}>
              <Field
                component={FormInput}
                name="phoneNumber"
                placeholder="Phone Number"
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <Col componentClass={ControlLabel} sm={2} className="small-regular">
              Code
            </Col>
            <Col sm={4}>
              <Field component={FormInput} name="code" placeholder="Code" />
            </Col>
          </FormGroup>
          <FormGroup>
            <Col componentClass={ControlLabel} sm={2} className="small-regular">
              Host Pin
            </Col>
            <Col sm={4}>
              <Field
                component={FormInput}
                name="hostPin"
                placeholder="Host Pin"
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <Col componentClass={ControlLabel} sm={2} className="small-regular">
              Web Link
            </Col>
            <Col sm={4}>
              <Field
                component={FormInput}
                name="webLink"
                placeholder="Web Link"
              />
            </Col>
            <Col sm={4}>
              <Field
                component={FormInput}
                name="linkTitle"
                placeholder="Meeting Title"
              />
            </Col>
          </FormGroup>
          {this.props.invalid && this.props.submitFailed && (
            <div className="invalid-message">
              The outlined inputs should be added in order save the Meeting
              Info.
            </div>
          )}
          <FormGroup className="buttons-container">
            <Col sm={12}>
              <Button
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.WHITE}
                label="Cancel"
                onClick={this.props.onCancel}
              />
              <Button
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.GREEN}
                label="Save"
                onClick={this.props.handleSubmit}
                disabled={this.props.invalid}
              />
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  if (!values.dayOfWeek) {
    errors.dayOfWeek = 'Required';
  }

  if (!values.startsAt) {
    errors.startsAt = 'Required';
  }

  if (!values.endsAt) {
    errors.endsAt = 'Required';
  }

  if (!values.timeZone) {
    errors.timeZone = 'Required';
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = 'Required';
  }

  return errors;
}

export default reduxForm({
  form: 'MeetingInfoForm',
  validate,
})(MeetingInfoForm);
