import React, { Component } from 'react';
import './Main.css';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from '../client/login/Login';
import { PrivateRoute } from '../shared/PrivateRoute';
import UserInvitationsContainer from '../client/user-invitations/UserInvitationsContainer';
import SetPassword from '../client/user-invitations/SetPassword';
import Admin from '../admin/Admin';
import RecoverPassword from '../client/recover/RecoverPassword';
import ResetPassword from '../client/recover/ResetPassword';
import RegisterUser from '../client/register/RegisterUser';
import OrderMaterialsReview from '../order-materials/Review';

class Main extends Component {
  render() {
    return (
      <div className="main">
        <Switch>
          <Route path="/login" component={Login} />

          <Route path="/set-password" component={SetPassword} />

          <Route path="/recover-password" component={RecoverPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/register" component={RegisterUser} />
          <Route
            path="/order-materials/review/:projectId/:token"
            component={OrderMaterialsReview}
          />

          <PrivateRoute path="/admin" component={Admin} />
          <PrivateRoute
            path="/user-invitations"
            component={UserInvitationsContainer}
          />

          <Redirect from="/" to="/admin" />
          <Redirect from="/index.html" to="/admin" />
        </Switch>
      </div>
    );
  }
}

export default Main;
