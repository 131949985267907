import Schedule from 'domain/schedule';
import User from 'domain/user';

class SchedulePermissions {
  canDelete = (user, project, category) =>
    User.isAdminOrProjectSubscriber(user, project) ||
    Schedule.isCreator(user, category);

  canChangePermissions = (user, project, category) =>
    User.isAdminOrProjectSubscriber(user, project) ||
    Schedule.isCreator(user, category);

  canEdit = (user, project, category) =>
    User.isAdminOrProjectSubscriber(user, project) ||
    Schedule.isCreator(user, category) ||
    Schedule.hasSharedEdit(user, category) ||
    Schedule.isPublicEdit(category);

  canView = (user, project, category) =>
    User.isAdminOrProjectSubscriber(user, project) ||
    Schedule.isCreator(user, category) ||
    Schedule.hasSharedEdit(user, category) ||
    Schedule.isPublicEdit(category);
}

export default new SchedulePermissions();
