import { showErrorMessage } from 'actions/messages';
import { coiExpsApi } from 'services/api/coi-exps-api';
import { coiExpDocsApi } from 'services/api/coi-exp-docs-api';
import { liabilitiesApi } from 'services/api/liabilities-api';
import {
  COI_EXPS_SET,
  COI_EXP_SET,
  COI_EXP_DOCS_SET,
  LIABILITIES_SET,
  COI_EXP_DOCS_ADD,
  COI_EXP_DOCS_UPDATE,
  COI_EXP_DOCS_DESTROY,
} from 'components/admin/projects/details/project-coi-exps/store/action-types';

export function loadCoiExpsSuccess(payload) {
  return {
    type: COI_EXPS_SET,
    payload: payload,
  };
}

export function loadCoiExpsRequest(projectId, page) {
  return (dispatch) => {
    return coiExpsApi.getCoiExps(projectId, page).then((response) => {
      if (response.ok) {
        dispatch(loadCoiExpsSuccess({ ...response, coiExpsCurrentPage: page }));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function loadCoiExpSuccess(payload) {
  return {
    type: COI_EXP_SET,
    payload: payload,
  };
}

export function loadCoiExpRequest(projectId, coiExpId) {
  return (dispatch) => {
    return coiExpsApi.getCoiExp(projectId, coiExpId).then((response) => {
      if (response.ok) {
        dispatch(loadCoiExpSuccess(response.coiExp));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function loadCoiExpDocsSuccess(payload) {
  return {
    type: COI_EXP_DOCS_SET,
    payload: payload,
  };
}

export function loadCoiExpDocsRequest(coiExpId, page) {
  return (dispatch) => {
    return coiExpDocsApi.getCoiExpDocs(coiExpId, page).then((response) => {
      if (response.ok) {
        dispatch(
          loadCoiExpDocsSuccess({
            ...response,
            coiExpId,
            coiExpDocsCurrentPage: page,
          })
        );
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function loadLiabilitiesSuccess(payload) {
  return {
    type: LIABILITIES_SET,
    payload: payload,
  };
}

export function loadLiabilitiesRequest() {
  return (dispatch) => {
    return liabilitiesApi.getLiabilities().then((response) => {
      if (response.ok) {
        dispatch(loadLiabilitiesSuccess(response.liabilities));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function createCoiExpDocSuccess(payload) {
  return {
    type: COI_EXP_DOCS_ADD,
    payload: payload,
  };
}

export function createCoiExpDoc(coiExpId, values) {
  return (dispatch) => {
    return coiExpDocsApi.createCoiExpDoc(coiExpId, values).then((response) => {
      if (response.ok) {
        const { coiExpDoc } = response;
        dispatch(createCoiExpDocSuccess({ coiExpId, coiExpDoc }));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function updateCoiExpDocSuccess(payload) {
  return {
    type: COI_EXP_DOCS_UPDATE,
    payload: payload,
  };
}

export function updateCoiExpDoc(coiExpId, values) {
  return (dispatch) => {
    return coiExpDocsApi.updateCoiExpDoc(coiExpId, values).then((response) => {
      if (response.ok) {
        const { coiExpDoc } = response;
        dispatch(updateCoiExpDocSuccess({ coiExpId, coiExpDoc }));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function destroyCoiExpDocSuccess(payload) {
  return {
    type: COI_EXP_DOCS_DESTROY,
    payload: payload,
  };
}

export function destroyCoiExpDoc(coiExpId, coiExpDoc) {
  return (dispatch) => {
    return coiExpDocsApi
      .destroyCoiExpDoc(coiExpId, coiExpDoc.id)
      .then((response) => {
        if (response.ok) {
          dispatch(destroyCoiExpDocSuccess({ coiExpId, coiExpDoc }));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}
