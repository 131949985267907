import axios from 'axios';
import BaseApi from './base-api';

class TasksChecklistApi extends BaseApi {
  markItemChecklist(taskChecklistId, itemId, value) {
    return axios
      .put(`${this.baseUrl}/task_checklists/${taskChecklistId}`, {
        task_checklist: {
          [itemId]: value,
        },
      })
      .then((response) => {
        return {
          ok: true,
          taskChecklist: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  uploadPreExistingConditionsImages(taskChecklistId, images) {
    return this._uploadImages(
      taskChecklistId,
      'pre_existing_images_attributes',
      images
    );
  }

  uploadCompleteTaskImages(taskChecklistId, images) {
    return this._uploadImages(
      taskChecklistId,
      'completed_images_attributes',
      images
    );
  }

  _uploadImages(taskChecklistId, attribute, images) {
    let formData = new FormData();
    images.forEach((image) => {
      formData.append(`task_checklist[${attribute}][][image]`, image);
    });

    return axios
      .put(`${this.baseUrl}/task_checklists/${taskChecklistId}`, formData)
      .then((response) => {
        return {
          ok: true,
          taskChecklist: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  removePreExistingConditionsImage(taskChecklistId, imageId) {
    return this._removeImage(
      taskChecklistId,
      'pre_existing_images_attributes',
      imageId
    );
  }

  removeCompleteTaskImage(taskChecklistId, imageId) {
    return this._removeImage(
      taskChecklistId,
      'completed_images_attributes',
      imageId
    );
  }

  _removeImage(taskChecklistId, attribute, imageId) {
    return axios
      .put(`${this.baseUrl}/task_checklists/${taskChecklistId}`, {
        task_checklist: {
          [attribute]: [
            {
              id: imageId,
              _destroy: true,
            },
          ],
        },
      })
      .then((response) => {
        return {
          ok: true,
          taskChecklist: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  savePreExistingConditionsDescription(taskChecklistId, description) {
    return axios
      .put(`${this.baseUrl}/task_checklists/${taskChecklistId}`, {
        task_checklist: {
          pre_existing_desc: description,
        },
      })
      .then((response) => {
        return {
          ok: true,
          taskChecklist: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const tasksChecklistApi = new TasksChecklistApi();
