import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './SelectSubcategoryModal.css';
import FormModal from 'components/shared/modal/FormModal';
import Selector from 'components/shared/selector/Selector';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

class SelectSubcategoryModal extends Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { selectedOption: null };
  }

  handleChange = (option) => {
    this.setState({ selectedOption: option });
  };

  handleSubmit = () => {
    this.props.onSubmit(this.state.selectedOption.value);
  };

  render() {
    const { showModal, options, onClose } = this.props;
    const { selectedOption } = this.state;
    return (
      showModal && (
        <FormModal
          show={showModal}
          onClose={onClose}
          subTitle="Select the Subcategory"
        >
          <div className="project-documentation-select-subcategory-modal">
            <Selector
              options={options}
              placeholder="Subcategories..."
              value={selectedOption && selectedOption.value}
              onChange={this.handleChange}
            />
            <div className="buttons-container">
              <Button
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.GREEN}
                disabled={!selectedOption}
                label="Done"
                onClick={this.handleSubmit}
              />
            </div>
          </div>
        </FormModal>
      )
    );
  }
}

export default connect()(SelectSubcategoryModal);
