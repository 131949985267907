import axios from 'axios';
import BaseApi from './base-api';

class ProjectAdvancedDocumentsApi extends BaseApi {
  createAdvancedDocumentsFolder(projectId, values) {
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    let apiPayload = new FormData();
    apiPayload.append('advanced_documents[folder_name]', values.folderName);
    apiPayload.append('advanced_documents[prefix]', values.prefix);
    apiPayload.append('advanced_documents[visibility]', values.visibility);
    if (values.user_ids !== undefined) {
      if (values.user_ids.length === 0) {
        apiPayload.append('advanced_documents[user_ids][]', []);
      } else {
        values.user_ids.forEach((userId) => {
          apiPayload.append('advanced_documents[user_ids][]', userId);
        });
      }
    }

    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/advanced_documents/create_folder`,
        apiPayload,
        headers
      )
      .then((response) => {
        return { ok: true };
      })
      .catch((error) => {
        return {
          ok: false,
          errors:
            error.response && error.response.data && error.response.data.errors,
        };
      });
  }

  updateAdvancedDocument(projectId, documentId, values) {
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    let apiPayload = new FormData();
    if (values.visibility !== undefined) {
      apiPayload.append('advanced_documents[visibility]', values.visibility);
    }

    if (values.user_ids !== undefined) {
      if (values.user_ids.length === 0) {
        apiPayload.append('advanced_documents[user_ids][]', []);
      } else {
        values.user_ids.forEach((userId) => {
          apiPayload.append('advanced_documents[user_ids][]', userId);
        });
      }
    }

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/advanced_documents/${documentId}/update`,
        apiPayload,
        headers
      )
      .then((response) => {
        return { ok: true };
      })
      .catch((error) => {
        return {
          ok: false,
          errors:
            error.response && error.response.data && error.response.data.errors,
        };
      });
  }

  renameAdvancedDocumentsFolder(projectId, values, currentFileId) {
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    let apiPayload = new FormData();
    apiPayload.append('advanced_documents[folder_name]', values.folderName);
    apiPayload.append('advanced_documents[prefix]', values.prefix);
    apiPayload.append(
      'advanced_documents[old_folder_name]',
      values.oldFolderName
    );

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/advanced_documents/${currentFileId}/rename_folder`,
        apiPayload,
        headers
      )
      .then((response) => {
        return { ok: true };
      })
      .catch((error) => {
        return {
          ok: false,
          errors:
            error.response && error.response.data && error.response.data.errors,
        };
      });
  }

  uploadAdvancedDocuments(projectId, values, onUploadProgress) {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    if (typeof onUploadProgress == 'function') {
      config['onUploadProgress'] = onUploadProgress;
      config['loadingDisabled'] = true;
    }

    let apiPayload = new FormData();
    apiPayload.append('advanced_documents[prefix]', values.prefix);
    values.documents.forEach((doc, index) => {
      apiPayload.append(
        `advanced_documents[documents][${index}][document]`,
        doc.file,
        doc.name
      );
    });

    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/advanced_documents/upload_documents`,
        apiPayload,
        config
      )
      .then((response) => {
        return { ok: true };
      })
      .catch((error) => {
        return {
          ok: false,
          errors:
            error.response && error.response.data && error.response.data.errors,
        };
      });
  }

  notifyUploadAdvanceDocumentsRequest(projectId, prefix, fileNames) {
    let apiPayload = new FormData();
    apiPayload.append('advanced_documents[prefix]', prefix);
    fileNames.forEach((name) =>
      apiPayload.append(`advanced_documents[filenames][]`, name)
    );

    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/advanced_documents/upload_documents_notify`,
        apiPayload
      )
      .then(() => ({ ok: true }))
      .catch(({ response }) => ({
        ok: false,
        errors: response && response.data && response.data.errors,
      }));
  }

  deleteAdvancedDocuments(projectId, values) {
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    let apiPayload = new FormData();

    apiPayload.append('advanced_documents[prefix]', values.prefix);
    values.documents.forEach((doc, index) => {
      apiPayload.append(
        `advanced_documents[documents][${index}][document]`,
        doc
      );
    });
    values.folders.forEach((folder, index) => {
      apiPayload.append(
        `advanced_documents[folders][${index}][folder]`,
        folder
      );
    });

    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/advanced_documents/delete_files_and_folders`,
        apiPayload,
        headers
      )
      .then((response) => {
        return { ok: true };
      })
      .catch((error) => {
        return {
          ok: false,
          errors:
            error.response && error.response.data && error.response.data.errors,
        };
      });
  }

  downloadAdvancedDocumentsFolder(values) {
    const messageWithBreaks = values.message.replace(/\n/g, '<br>');

    return axios
      .post(`${process.env.REACT_APP_GATEWAY_API_URL}/download_folders`, {
        selectedFolders: values.selectedFolders,
        bucket: values.bucket,
        message: messageWithBreaks,
        subject: values.subject,
        recipients: values.recipients,
        rootFolder: values.rootFolder,
        selectedFiles: values.selectedFiles,
      })
      .then((response) => {
        return { ok: true };
      })
      .catch((error) => {
        return { ok: false, errors: error.response };
      });
  }

  moveAdvancedDocumentsFolder(projectId, values) {
    const headers = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    let apiPayload = new FormData();
    apiPayload.append(
      'advanced_documents[selected_object]',
      values.selectedObject
    );
    apiPayload.append(
      'advanced_documents[selected_destination',
      values.selectedDestination
    );
    apiPayload.append('advanced_documents[parent_folder', values.parentFolder);

    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/advanced_documents/move_object`,
        apiPayload,
        headers
      )
      .then((response) => {
        return { ok: true };
      })
      .catch((error) => {
        return {
          ok: false,
          errors:
            error.response && error.response.data && error.response.data.errors,
        };
      });
  }
}

export const projectAdvancedDocumentsApi = new ProjectAdvancedDocumentsApi();
