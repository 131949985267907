import React from 'react';
import { connect } from 'react-redux';
import MessageModal, {
  MODAL_ICONS,
} from 'components/shared/modal/MessageModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getProjectPhotosSelectedIds,
  getProjectPhotosShowDelete,
} from './store/selectors';
import { setProjectPhotosShowDelete } from './store/actions';
import useProjectPhotosActions from './ProjectPhotosActions.hook';

const ProjectPhotosDeleteModal = (props) => {
  const { dispatch, show, ids } = props;
  const { erase } = useProjectPhotosActions(props);

  const onClose = () => dispatch(setProjectPhotosShowDelete(false));

  const onErase = () => {
    erase(ids).then((response) => {
      if (response.ok) onClose();
    });
  };

  return (
    <MessageModal
      show={show}
      icon={MODAL_ICONS.EXCLAMATION}
      message={
        <>
          Deleted photos will be permanently removed and are not restorable.
          <br />
          Do you wish to continue?
        </>
      }
      buttons={[
        <Button
          key="no"
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.WHITE}
          label="No"
          onClick={onClose}
        />,
        <Button
          key="yes"
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          label="Yes"
          onClick={onErase}
        />,
      ]}
    />
  );
};

export default connect((state) => ({
  show: getProjectPhotosShowDelete(state),
  ids: getProjectPhotosSelectedIds(state),
  currentProject: getCurrentProject(state),
}))(ProjectPhotosDeleteModal);
