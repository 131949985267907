import { createSelector } from 'reselect';

export const getProjectOpenItemsCount = createSelector(
  (state) => state.projectOpenItems.data.projectOpenItemsCount,
  (projectOpenItemsCount) => {
    return projectOpenItemsCount;
  }
);

export const getProjectOpenItemsCritCount = createSelector(
  (state) => state.projectOpenItems.data.projectOpenItemsCritCount,
  (projectOpenItemsCritCount) => {
    return projectOpenItemsCritCount;
  }
);

export const getProjectOpenItems = createSelector(
  (state) => state.projectOpenItems.data.projectOpenItems,
  (projectOpenItems) => {
    return projectOpenItems;
  }
);

export const getCurrentStatus = createSelector(
  (state) => state.projectOpenItems.data.currentStatus,
  (currentStatus) => {
    return currentStatus;
  }
);

export const getTotal = createSelector(
  (state) => state.projectOpenItems.data.total,
  (total) => {
    return total;
  }
);

export const getCurrentPage = createSelector(
  (state) => state.projectOpenItems.data.currentPage,
  (currentPage) => {
    return currentPage;
  }
);

export const getPageSize = createSelector(
  (state) => state.projectOpenItems.data.pageSize,
  (pageSize) => {
    return pageSize;
  }
);
