import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Reports.css';
import { openTaskDetails } from 'actions/admin/tasks/tasks';
import { getReportResults } from 'selectors/admin/reports/reports';
import Date from 'components/shared/text/Date';
import BaseReport from 'components/admin/reports/BaseReport';

class OpenPricingReport extends Component {
  handleRowSelection = (index) => {
    this.props.dispatch(openTaskDetails(this.props.reportResults.data[index]));
  };

  createTableRowsData = () => {
    if (this.props.reportResults !== null) {
      let woNumber = null;
      return this.props.reportResults.data.map((result, index) => {
        let first = result.work_order.number !== woNumber;
        const rowData = {
          id: index,
          selectable: true,
          data: [
            first ? result.work_order.number : '',
            first ? result.work_order.name : '',
            first ? result.work_order.client.name : '',
            first ? (
              <Date value={result.work_order.created_at} format="LL" />
            ) : (
              ''
            ),
            result.number,
            result.name,
            <Date value={result.completion_date} format="LL" />,
          ],
        };
        if (!first) {
          rowData['className'] = 'no-top-border';
        }
        woNumber = result.work_order.number;
        return {
          rowData,
          first,
          workOrderId: result.work_order.id,
        };
      });
    } else {
      return [];
    }
  };

  render() {
    return (
      <BaseReport
        reportName="open_pricing_report"
        tableHeader={[
          'WO #',
          'NAME',
          'OWNER NAME',
          'CREATED AT',
          'TASK #',
          'TASK NAME',
          'COMPLETION DATE',
        ]}
        tableRowsData={this.createTableRowsData()}
        title="Open RFP Pricing"
        onRowSelection={this.handleRowSelection}
      />
    );
  }
}

export default connect((state) => {
  return {
    reportResults: getReportResults(state),
  };
})(OpenPricingReport);
