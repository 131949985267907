import noAlbumCover from 'components/admin/projects/details/project-photos-old/no-album-cover.png';

export const Permission = {
  PUBLIC: 'publicc',
  SHARED: 'shared',
  PRIVATE: 'privatee',
};

export const Status = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  DELETED: 'deleted',
};

export const PhotoStatus = {
  ACTIVE: 'active',
  RECYCLED: 'recycled',
  DELETED: 'deleted',
};

export const TAG_OPTIONS = {
  TAGS: { plural: 'tags', singular: 'tag' },
  LOCATIONS: { plural: 'locations', singular: 'location' },
  USERS: { plural: 'users', singular: 'user' },
  TRADES: { plural: 'trades', singular: 'trade' },
};

export const hasAlbumCover = (album) => {
  return !!album.cover_image.url;
};

export const albumCover = (album) => {
  let coverImage = hasAlbumCover(album);
  let image = coverImage
    ? `url(${album.cover_image.url})`
    : `url(${noAlbumCover})`;

  return {
    backgroundImage: image,
    backgroundPosition: 'center',
    backgroundSize: hasAlbumCover(album) ? 'cover' : 'initial',
    backgroundRepeat: 'no-repeat',
    backgroundColor: hasAlbumCover(album) ? '' : 'white',
  };
};

export const albumsByStatus = (albums, status) => {
  return albums.filter((album) => album.status === status);
};

export const anyArchivedAlbums = (albums) => {
  return albums.some((album) => album.status === Status.ARCHIVED);
};

export const albumsUserCanView = (albums, currentUser) => {
  return albums.filter((album) => {
    let managing_users = album.managing_users.map((user) => user.id);
    return (
      album.permission === Permission.PUBLIC ||
      ((Permission.SHARED === album.permission ||
        Permission.PRIVATE === album.permission) &&
        managing_users.includes(currentUser.id))
    );
  });
};
