import React, { useState } from 'react';
import { connect } from 'react-redux';

import FormModal from 'components/shared/modal/FormModal';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import Input from 'components/shared/input/Input';

import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import Selector from 'components/shared/selector/Selector';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  setEditTypeModal,
  setEditTitleModal,
  updateMeetingTitle,
  updateMeetingType,
  deleteMeetingsParentsType,
  deleteMeetingsParentsTitle,
  setDeleteTypeOrTitleErrorModalShow,
} from './store/actions';
import {
  getProjectNewMeetingsEditTypeModal,
  getProjectNewMeetingsEditTitleModal,
  getMeetingsParentsTitles,
  getMeetingsParentsTypes,
  getProjectNewMeetingsParents,
  getDeleteTypeOrTitleErrorModal,
} from './store/selectors';
import './AttendanceForm.css';
import './MeetingModal.css';
import './EditTypeOrTitleModal.css';
import RadioButton from '../../../../shared/radio-button/RadioButton';
import DeleteTypeOrTitleModal from './DeleteTypeOrTitleErrorModal';

const EditTypeOrTitleModal = (props) => {
  const {
    modalType,
    dispatch,
    project,
    showIfTitle,
    showIfType,
    meetingsParentsTypes,
    meetingsParentsTitles,
    meetingsParents,
    showDeleteTypeOrTitleErrorModal,
  } = props;
  const [newType, setNewType] = useState('');
  const [selectedType, setSelectedType] = useState({});
  const [deleteButton, setDeleteButton] = useState({});
  const handleClose = () => {
    if (modalType === 'type') {
      dispatch(setEditTypeModal(false));
    } else {
      dispatch(setEditTitleModal(false));
    }
    dispatch(setDeleteTypeOrTitleErrorModalShow(false));
  };
  const typeExists = (meetingTypeValue) => {
    return meetingsParents.some(
      (meeting) =>
        meeting.meeting_type === meetingTypeValue ||
        meeting.meetingType === meetingTypeValue
    );
  };
  const titleExists = (meetingTitleValue) => {
    return meetingsParents.some(
      (meeting) => meeting.title === meetingTitleValue
    );
  };

  const handleDeleteAction = () => {
    const checkExists = modalType === 'type' ? typeExists : titleExists;
    const deleteAction =
      modalType === 'type'
        ? deleteMeetingsParentsType
        : deleteMeetingsParentsTitle;
    const id =
      modalType === 'type' ? selectedType.typeId : selectedType.titleId;
    if (checkExists(selectedType.value)) {
      dispatch(setDeleteTypeOrTitleErrorModalShow(true));
      return;
    }
    dispatch(deleteAction(project.id, id));
    handleClose();
  };

  const handleUpdateAction = () => {
    const updateAction =
      modalType === 'type' ? updateMeetingType : updateMeetingTitle;
    const id =
      modalType === 'type' ? selectedType.typeId : selectedType.titleId;

    dispatch(updateAction(project.id, id, newType));
  };

  const handleSave = () => {
    if (deleteButton) {
      handleDeleteAction();
    } else {
      handleUpdateAction();
      handleClose();
    }
  };

  const selectorOptions =
    modalType === 'type' ? meetingsParentsTypes : meetingsParentsTitles;
  const radioButtonOptions = [
    {
      value: modalType === 'type' ? 'Delete Type' : 'Delete Title',
      label: modalType === 'type' ? 'Delete Type' : 'Delete Title',
    },
  ];
  return (
    <>
      <FormModal
        className={`meeting-modal editing`}
        show={modalType === 'type' ? showIfType : showIfTitle}
        onClose={handleClose}
        subTitle={modalType === 'type' ? 'Edit Type' : 'Edit Title'}
      >
        <FormControlBlock
          label="Meeting Type"
          position="right"
          readonly={false}
          control={
            <Selector
              className="select-dropdown"
              placeholder={'Meeting Type'}
              value={selectedType}
              onChange={(option) => setSelectedType(option)}
              options={selectorOptions}
              clearable
            />
          }
        ></FormControlBlock>
        <div className="form-block multicontrols-block">
          <FormControlBlock
            position="left"
            readonly={false}
            control={
              <Input
                type={'text'}
                placeholder="Modified Name"
                onChange={(value) => setNewType(value)}
                readonly={false}
                value={newType}
              />
            }
          />
          <div className="or-text">or</div>
          <RadioButton
            options={radioButtonOptions}
            value={deleteButton}
            orientation="horizontal-row"
            onChange={(value) => {
              setDeleteButton((prevDeleteButton) => {
                return Object.keys(prevDeleteButton).length === 0 ? value : {};
              });
            }}
          />
        </div>
        <div className="button-actions">
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.WHITE}
            label="Cancel"
            onClick={handleClose}
          />
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label={'Save'}
            onClick={handleSave}
          />
        </div>
      </FormModal>
      {showDeleteTypeOrTitleErrorModal && (
        <DeleteTypeOrTitleModal showActions={true} primary={true} />
      )}
    </>
  );
};

export default connect((state) => ({
  showIfType: getProjectNewMeetingsEditTypeModal(state),
  showIfTitle: getProjectNewMeetingsEditTitleModal(state),
  showDeleteTypeOrTitleErrorModal: getDeleteTypeOrTitleErrorModal(state),
  project: getCurrentProject(state),
  meetingsParentsTitles: getMeetingsParentsTitles(state),
  meetingsParentsTypes: getMeetingsParentsTypes(state),
  meetingsParents: getProjectNewMeetingsParents(state),
}))(EditTypeOrTitleModal);
