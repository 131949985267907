export const RFI_LOG_INIT = 'RFI_LOG_INIT';
export const RFIS_SET = 'RFIS_SET';
export const RFI_TAB_SELECTED_SET = 'RFI_TAB_SELECTED_SET';
export const RFI_MODAL_SHOW_SET = 'RFI_MODAL_SHOW_SET';
export const RFI_MODAL_INIT = 'RFI_MODAL_INIT';
export const RFI_MODAL_RESPONSE_SHOW_SET = 'RFI_MODAL_RESPONSE_SHOW_SET';
export const RFI_MODAL_RESPONSES_VIEW_SHOW_SET =
  'RFI_MODAL_RESPONSES_VIEW_SHOW_SET';
export const RFI_FORM_VALUE_UPDATE = 'RFI_FORM_VALUE_UPDATE';
export const RFI_QUESTION_VALUE_UPDATE = 'RFI_QUESTION_VALUE_UPDATE';
export const RFI_QUESTION_ERRORS_SET = 'RFI_QUESTION_ERRORS_SET';
export const RFI_QUESTION_ADD = 'RFI_QUESTION_ADD';
export const RFI_QUESTIONS_ATTACH = 'RFI_QUESTIONS_ATTACH';
export const RFI_QUESTION_DELETE = 'RFI_QUESTION_DELETE';
export const RFIS_ADD = 'RFIS_ADD';
export const RFIS_UPDATE = 'RFIS_UPDATE';
export const RFIS_DELETE = 'RFIS_DELETE';
export const CURRENT_RFI_SET = 'CURRENT_RFI_SET';
export const RFI_READ_ONLY_SET = 'RFI_READ_ONLY_SET';
export const RFI_FORM_VALUES_SET = 'RFI_FORM_VALUES_SET';
export const RFI_FORM_ERRORS_SET = 'RFI_FORM_ERRORS_SET';
export const RFI_QUESTION_FILE_DELETE = 'RFI_QUESTION_FILE_DELETE';
export const RFI_QUESTION_NOTE_ADD = 'RFI_QUESTION_NOTE_ADD';
export const RFI_GC_ACCELERATION_SET = 'RFI_GC_ACCELERATION_SET';
export const RFI_LOG_PDF_VIEWER_SET = 'RFI_LOG_PDF_VIEWER_SET';
export const RFI_LOG_PDF_SET = 'RFI_LOG_PDF_SET';
export const RFI_PDF_VIEWER_SET = 'RFI_PDF_VIEWER_SET';
export const RFI_SHOW_ERROR_MESSAGE_SET = 'RFI_SHOW_ERROR_MESSAGE_SET';
export const RFI_ADDL_ASSIGNEES_ADD = 'RFI_ADDL_ASSIGNEES_ADD';
export const RFI_ADDL_ASSIGNEES_UPDATE = 'RFI_ADDL_ASSIGNEES_UPDATE';
export const RFI_ADDL_ASSIGNEES_DELETE = 'RFI_ADDL_ASSIGNEES_DELETE';
export const RFI_ADDL_ASSIGNEE_RESPOND_SUCCESS =
  'RFI_ADDL_ASSIGNEE_RESPOND_SUCCESS';
