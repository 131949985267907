import {
  CLIENT_TEAM_CLIENT_FORM_INVALID,
  CLIENT_TEAM_CLIENT_FORM_VALID,
  CLIENT_TEAM_CLIENT_FORM_VALUE_UPDATE,
  CLIENT_TEAM_EDIT_FORM_LOAD_FROM_CURRENT,
  CLIENT_TEAM_INVITE_USER_FORM_LOAD,
  CLIENT_TEAM_INVITE_USER_FORM_VALUE_UPDATE,
  CLIENT_TEAM_INVITE_USER_ID_SET,
  CLIENT_TEAM_INVITE_USER_MODAL_SHOW,
  CLIENT_TEAM_SHOW_VERIFICATION_MODAL,
} from 'actions/admin/team/action-types';
import { UserModalState } from 'components/shared/modal/InviteUserModal';

const initialState = {
  currentUserId: 0,
  clientForm: {
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    globalPurchaseOrderRequired: false,
    purchaseOrderRequired: false,
    streetAddress: '',
    unitNumber: '',
    city: '',
    state: '',
    zipCode: '',
  },
  invalidFields: [],
  showInviteUserModal: false,
  userForm: {
    buildingsId: [],
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    rolesId: [],
    title: '',
  },
  userModalState: UserModalState.NEW, // one of UserModalState
  validationMessage: '',
  verificationModal: null,
};

export default function clientTeamUiReducer(state = initialState, action) {
  switch (action.type) {
    case CLIENT_TEAM_EDIT_FORM_LOAD_FROM_CURRENT:
      return {
        ...state,
        invalidFields: [],
        clientForm: {
          companyName: action.payload.name,
          firstName: action.payload.contact.first_name,
          lastName: action.payload.contact.last_name,
          email: action.payload.contact.email,
          phoneNumber:
            action.payload.contact.phone_number !== null
              ? action.payload.contact.phone_number
              : '',
          globalPurchaseOrderRequired: action.payload.global_po_required,
          purchaseOrderRequired: action.payload.po_required,
          streetAddress: action.payload.location
            ? action.payload.location.street_address_1
            : '',
          unitNumber: action.payload.location
            ? action.payload.location.street_address_2
            : '',
          city: action.payload.location ? action.payload.location.city : '',
          state: action.payload.location ? action.payload.location.state : '',
          zipCode: action.payload.location ? action.payload.location.zip : '',
        },
        validationMessage: '',
      };

    case CLIENT_TEAM_CLIENT_FORM_INVALID:
      return {
        ...state,
        invalidFields: action.payload.invalidFields,
        validationMessage: action.payload.message,
      };

    case CLIENT_TEAM_CLIENT_FORM_VALID:
      return {
        ...state,
        invalidFields: [],
        validationMessage: '',
      };

    case CLIENT_TEAM_CLIENT_FORM_VALUE_UPDATE:
      return {
        ...state,
        clientForm: {
          ...state.clientForm,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case CLIENT_TEAM_INVITE_USER_FORM_LOAD:
      return {
        ...state,
        userForm: action.payload,
        invalidFields: [],
        validationMessage: '',
      };

    case CLIENT_TEAM_INVITE_USER_FORM_VALUE_UPDATE:
      return {
        ...state,
        userForm: {
          ...state.userForm,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case CLIENT_TEAM_INVITE_USER_ID_SET:
      return {
        ...state,
        currentUserId: action.payload,
      };

    case CLIENT_TEAM_INVITE_USER_MODAL_SHOW:
      const newState = {
        ...state,
        invalidFields: [],
        showInviteUserModal: action.payload.open,
        validationMessage: '',
      };
      if (action.payload.state !== undefined) {
        newState['userModalState'] = action.payload.state;
      }

      return newState;

    case CLIENT_TEAM_SHOW_VERIFICATION_MODAL:
      return {
        ...state,
        verificationModal: action.payload,
      };

    default:
      return state;
  }
}
