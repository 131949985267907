import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';

import FormModal from 'components/shared/modal/FormModal';
import NavigationBar from 'components/shared/navigation/NavigationBar';
import UserPermissionsForm from 'components/shared/user-permissions/UserPermissionsForm';
import { Row, Col } from 'react-bootstrap';
import DetailsInfoItem from 'components/shared/details/DetailsInfoItem';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import Input from 'components/shared/input/Input';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import Selector from 'components/shared/selector/Selector';

import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

import { SCHEDULE_FORM_TABS, DEFAULT_SCHEDULE_FORM_TAB } from 'domain/schedule';
import SchedulePermissions from 'permissions/schedule-permissions';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';

import {
  toggleFormModalProjectSchedules,
  setFormProjectSchedules,
  setFormTabProjectSchedules,
  saveCategoryProjectSchedules,
} from './store/actions';
import {
  getProjectSchedules,
  getProjectSchedulesForm,
  getProjectSchedulesFormModal,
  getProjectSchedulesFormTab,
} from './store/selectors';
import './ProjectScheduleFormModal.css';
import moment from 'moment';

const ProjectScheduleFormModal = (props) => {
  const { dispatch, currentUser, project, show, tab, form, schedules } = props;
  const [errors, setErrors] = useState({});

  // form
  const isEdit = form.id !== null;
  const handleFormChange = (fields) => {
    dispatch(setFormProjectSchedules(fields));
  };
  const validateForm = () => {
    const errs = {};
    if (!form.name || form.name.length === 0) errs['name'] = true;
    return errs;
  };
  const handleSave = () => {
    const errs = validateForm();
    setErrors(errs);
    if (Object.keys(errs).length > 0) {
      if (errs.name) setTab(DEFAULT_SCHEDULE_FORM_TAB);
    } else dispatch(saveCategoryProjectSchedules(project.id, form));
  };

  // tabs
  const setTab = (key) => dispatch(setFormTabProjectSchedules(key));
  const buildTabs = () => {
    return SCHEDULE_FORM_TABS.map((t) => ({
      ...t,
      class: t.id === tab ? 'sel' : '',
    }));
  };

  // modal
  const handleClose = () => dispatch(toggleFormModalProjectSchedules(false));

  const duplicateOptions = useMemo(() => {
    let res = [];
    if (show) {
      schedules.forEach((c) => {
        res.push({ value: c.id, label: c.name });
      });
    }
    return res;
  }, [schedules, show]);

  if (!show) return null;

  const isNew = typeof form.id === 'undefined' || form.id === null;
  const canChangePermissions = SchedulePermissions.canChangePermissions(
    currentUser,
    project,
    form
  );

  return (
    <FormModal
      className={`schedule-modal  ${
        tab === 'permissions' ? form.visibility : ''
      } ${tab === 'editing' ? form.editing : ''}`}
      show={show}
      onClose={handleClose}
      subTitle={`${isEdit ? 'Edit' : 'Add'} Schedule`}
    >
      <NavigationBar
        items={buildTabs()}
        onSelected={setTab}
        selectedValue={tab}
      />
      {tab === 'general_info' && (
        <Row className="general-info">
          <Col md={6}>
            <DetailsInfoItem
              title="Created By"
              lines={[
                form.created_by
                  ? form.created_by.full_name
                  : currentUser.full_name,
              ]}
            />
          </Col>
          <Col md={6}>
            <DetailsInfoItem
              title="Date"
              lines={[moment(form.createdAt || undefined).format('MM/DD/YYYY')]}
            />
          </Col>
          <Col md={12}>
            <FormControlBlock
              hideBottomLabel
              className="input-type"
              label={form.name && 'Schedule Name'}
              control={
                <Input
                  type={'text'}
                  placeholder={'Schedule Name'}
                  value={form.name}
                  valid={!(errors && errors.name)}
                  onChange={(name) => handleFormChange({ name })}
                />
              }
            />
          </Col>
          {isNew && duplicateOptions.length > 0 && (
            <Col md={12}>
              <FormControlBlock
                hideBottomLabel
                className="duplicate-input"
                label={'Duplicate Schedule'}
                control={
                  <Selector
                    clearable
                    options={duplicateOptions}
                    value={form.duplicate}
                    onChange={(o) =>
                      handleFormChange({ duplicate: o ? o.value : null })
                    }
                    placeholder="Select Schedule"
                  />
                }
              />
            </Col>
          )}
          {isNew && duplicateOptions.length > 0 && (
            <Col md={4}>
              <LabeledCheckBox
                label="Copy Dates"
                position="left"
                readOnly={duplicateOptions.length === 0}
                onChange={(copyDates) => handleFormChange({ copyDates })}
                checked={form.copyDates}
              />
            </Col>
          )}
          {isNew && duplicateOptions.length > 0 && (
            <Col md={4}>
              <LabeledCheckBox
                label="Copy Permissions"
                position="left"
                readOnly={duplicateOptions.length === 0}
                onChange={(copyVisibility) =>
                  handleFormChange({ copyVisibility })
                }
                checked={form.copyVisibility}
              />
            </Col>
          )}
          {isNew && duplicateOptions.length > 0 && (
            <Col md={4}>
              <LabeledCheckBox
                label="Copy Editing"
                position="left"
                readOnly={duplicateOptions.length === 0}
                onChange={(copyEditing) => handleFormChange({ copyEditing })}
                checked={form.copyEditing}
              />
            </Col>
          )}
        </Row>
      )}
      {tab === 'permissions' && (
        <UserPermissionsForm
          actionText="View"
          users={form.visibilityUsers}
          visibility={form.visibility}
          canChangePermissions={canChangePermissions}
          onVisibilityChange={(visibility) => handleFormChange({ visibility })}
          onUsersChange={(visibilityUsers) =>
            handleFormChange({ visibilityUsers })
          }
        />
      )}
      {tab === 'editing' && (
        <UserPermissionsForm
          actionText="Editing"
          users={form.editingUsers}
          visibility={form.editing}
          canChangePermissions={canChangePermissions}
          onVisibilityChange={(editing) => handleFormChange({ editing })}
          onUsersChange={(editingUsers) => handleFormChange({ editingUsers })}
        />
      )}
      <div className="button-actions">
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.WHITE}
          label="Cancel"
          onClick={handleClose}
        />
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          label={'Save'}
          onClick={handleSave}
        />
      </div>
    </FormModal>
  );
};

export default connect((state) => ({
  show: getProjectSchedulesFormModal(state),
  currentUser: getCurrentUser(state),
  project: getCurrentProject(state),
  tab: getProjectSchedulesFormTab(state),
  form: getProjectSchedulesForm(state),
  schedules: getProjectSchedules(state),
}))(ProjectScheduleFormModal);
