import Range from 'services/utils/range-utils';
import {
  NEW_PROJECT,
  EDIT_PROJECT,
  PREV_STAGE,
  NEXT_STAGE,
  NEW_PROJECT_FORM_VALUE_CHANGE,
  NEW_PROJECT_CURRENT_PAGE_SET,
  NEW_PROJECT_CLIENT_FORM_VALUE_CHANGE,
  NEW_PROJECT_PROJECT_LOCATION_FORM_VALUE_CHANGE,
  NEW_PROJECT_COMPANY_FORM_VALUE_CHANGE,
  NEW_PROJECT_NEW_CLIENT_MODAL_SHOW,
  NEW_PROJECT_NEW_CLIENT_FORM_CLEAN,
  NEW_PROJECT_NEW_PROJECT_LOCATION_MODAL_SHOW,
  NEW_PROJECT_NEW_PROJECT_LOCATION_FORM_CLEAN,
  NEW_PROJECT_NEW_COMPANY_MODAL_SHOW,
  NEW_PROJECT_NEW_COMPANY_FORM_CLEAN,
  EDIT_PROJECT_FORM_SET_VALUES,
  NEW_PROJECT_CLIENTS_SET_PAGINATION_DATA,
} from 'components/admin/projects/creation/store/action-types';

const stages = [
  'subscriber-selection',
  'project-details',
  'client-selection',
  'project-location-selection',
  'success',
];

const initialState = {
  currentPage: 1,
  currentStage: 'subscriber-selection',
  projectForm: {
    creatorCompanyType: null,
    creatorCompanyCsiCode: null,
    creatorCompanyId: null,
    clientId: null,
    projectLocationId: null,
    number: '',
    name: '',
    status: '',
    sqFootage: '',
    estStartDate: '',
    estCompletionDate: '',
    bidDueDate: '',
    advancedDocumentView: false,
    terminationDate: null,
    terminationCategory: null,
    terminationReason: null,
    isPublic: true,
    estimatedCost: null,
    primaryUsersCount: null,
    industry: null,
    industryType: null,
    conditions: [],
    originalConditions: [],
  },
  clientForm: {
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  projectLocationForm: {
    name: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    zip: '',
  },
  companyForm: {
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    streetAddress: '',
    unitNumber: '',
    city: '',
    state: '',
    zipCode: '',
  },
  showNewClientModal: false,
  showNewProjectLocationModal: false,
  showNewCompanyModal: false,
  clientsCurrentPage: 1,
  clientsTotal: 0,
  clientsPageSize: 25,
};

export default function newProjectUiReducer(state = initialState, action) {
  switch (action.type) {
    case NEW_PROJECT:
      return initialState;

    case EDIT_PROJECT:
      return {
        ...initialState,
        currentStage: stages[1],
      };

    case NEW_PROJECT_CURRENT_PAGE_SET:
      return {
        ...state,
        currentPage: action.payload,
      };

    case PREV_STAGE:
      return {
        ...state,
        currentStage: stages[stages.indexOf(state.currentStage) - 1],
      };

    case NEXT_STAGE:
      return {
        ...state,
        currentStage: stages[stages.indexOf(state.currentStage) + 1],
      };

    case NEW_PROJECT_FORM_VALUE_CHANGE:
      return {
        ...state,
        projectForm: {
          ...state.projectForm,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case EDIT_PROJECT_FORM_SET_VALUES:
      return {
        ...state,
        projectForm: {
          ...state.projectForm,
          clientId: action.payload.client && action.payload.client.id,
          projectLocationId: action.payload.project_location.id,
          number: action.payload.number,
          name: action.payload.name,
          sqFootage: action.payload.sq_footage,
          bidDueDate: action.payload.bid_due_date,
          estStartDate: action.payload.est_start_date,
          estCompletionDate: action.payload.est_completion_date,
          advancedDocumentView: action.payload.advanced_document_view,
          status: action.payload.status,
          statusBeforeArchive: action.payload.status_before_archive,
          terminationDate: action.payload.termination_date,
          terminationCategory: action.payload.termination_category,
          terminationReason: action.payload.termination_reason,
          isPublic: action.payload.public,
          estimatedCost: Range.makeValue(
            action.payload.estimated_cost_from,
            action.payload.estimated_cost_to
          ),
          primaryUsersCount: Range.makeValue(
            action.payload.primary_users_count_from,
            action.payload.primary_users_count_to
          ),
          industry: action.payload.industry,
          industryType: action.payload.industry_type,
          originalConditions: action.payload.project_conditions,
          conditions:
            action.payload.project_conditions &&
            action.payload.project_conditions.map((condition) => [
              condition.condition,
              condition.condition,
            ]),
          id: action.payload.id,
        },
      };

    case NEW_PROJECT_CLIENT_FORM_VALUE_CHANGE:
      return {
        ...state,
        clientForm: {
          ...state.clientForm,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case NEW_PROJECT_PROJECT_LOCATION_FORM_VALUE_CHANGE:
      return {
        ...state,
        projectLocationForm: {
          ...state.projectLocationForm,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case NEW_PROJECT_COMPANY_FORM_VALUE_CHANGE:
      return {
        ...state,
        companyForm: {
          ...state.companyForm,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case NEW_PROJECT_NEW_CLIENT_MODAL_SHOW:
      return {
        ...state,
        showNewClientModal: action.payload,
      };

    case NEW_PROJECT_NEW_CLIENT_FORM_CLEAN:
      return {
        ...state,
        clientForm: initialState.clientForm,
      };

    case NEW_PROJECT_NEW_PROJECT_LOCATION_MODAL_SHOW:
      return {
        ...state,
        showNewProjectLocationModal: action.payload,
      };

    case NEW_PROJECT_NEW_PROJECT_LOCATION_FORM_CLEAN:
      return {
        ...state,
        projectLocationForm: initialState.projectLocationForm,
      };

    case NEW_PROJECT_NEW_COMPANY_MODAL_SHOW:
      return {
        ...state,
        showNewCompanyModal: action.payload,
      };

    case NEW_PROJECT_NEW_COMPANY_FORM_CLEAN:
      return {
        ...state,
        companyForm: initialState.companyForm,
      };

    case NEW_PROJECT_CLIENTS_SET_PAGINATION_DATA:
      return {
        ...state,
        clientsCurrentPage: action.payload.page,
        clientsTotal: action.payload.total,
        clientsPageSize: action.payload.pageSize,
      };

    default:
      return state;
  }
}
