import FieldReport from 'domain/field-report';
import User from 'domain/user';

class FieldReportPermissions {
  canManage = (user, project, report) =>
    User.isAdminOrProjectSubscriber(user, project) ||
    User.isOar(user, project) ||
    FieldReport.isCreator(user, report);

  canOpen = (user, project, report) =>
    User.isAdminOrProjectSubscriber(user, project) ||
    User.isOar(user, project) ||
    User.isClient(user, project) ||
    FieldReport.isCreator(user, report);

  canViewDraft = (user, project) =>
    User.isAdminOrProjectSubscriber(user, project) || User.isOar(user, project);

  canSendEmail = (user, project, report) =>
    !FieldReport.isDraft(report) && this.canOpen(user, project, report);

  canOpenPdf = (user, project, report) =>
    FieldReport.isPublished(report) && this.canOpen(user, project, report);

  canEdit = (user, project, report) =>
    FieldReport.isDraft(report) && this.canManage(user, project, report);

  canDelete = (user, project, report) =>
    !FieldReport.isDeleted(report) && this.canManage(user, project, report);
}

export default new FieldReportPermissions();
