import {
  CONTRACTORS_CONTRACTOR_LOAD_SUCCESS,
  CONTRACTORS_CONTRACTOR_WORK_ORDERS_LOAD_SUCCESS,
  CONTRACTORS_INVOICES_SET,
  CONTRACTORS_CONTRACTOR_PROJECTS_LOAD_SUCCESS,
  CONTRACTORS_LOAD_SUCCESS,
  CONTRACTORS_NEW_PARAMETERS_LOAD_SUCCESS,
  CONTRACTORS_USERS_SET,
  CONTRACTORS_CONTRACTOR_ROLES_LOAD_SUCCESS,
  CONTRACTORS_CONTRACTOR_USER_ADD,
} from 'actions/admin/contractors/action-types';

const initialState = {
  contractors: [],
  contractorInvoices: [],
  currentContractor: null,
  serviceAreas: [],
  trades: [],
  unions: [],
  users: [],
  workOrders: null,
  projects: null,
  roles: [],
};

export default function contractorsDataReducer(state = initialState, action) {
  switch (action.type) {
    case CONTRACTORS_CONTRACTOR_LOAD_SUCCESS:
      return {
        ...state,
        currentContractor: action.payload,
        users: initialState.users,
      };

    case CONTRACTORS_CONTRACTOR_WORK_ORDERS_LOAD_SUCCESS:
      return {
        ...state,
        workOrders: action.payload,
      };

    case CONTRACTORS_INVOICES_SET:
      return {
        ...state,
        contractorInvoices: action.payload,
      };

    case CONTRACTORS_CONTRACTOR_PROJECTS_LOAD_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      };

    case CONTRACTORS_LOAD_SUCCESS:
      return {
        ...state,
        contractors: action.payload,
      };

    case CONTRACTORS_NEW_PARAMETERS_LOAD_SUCCESS:
      return {
        ...state,
        serviceAreas:
          action.payload.serviceAreas !== undefined
            ? action.payload.serviceAreas
            : state.serviceAreas,
        trades:
          action.payload.trades !== undefined
            ? action.payload.trades
            : state.trades,
        unions:
          action.payload.unions !== undefined
            ? action.payload.unions
            : state.unions,
      };

    case CONTRACTORS_USERS_SET:
      return {
        ...state,
        users: action.payload,
      };

    case CONTRACTORS_CONTRACTOR_ROLES_LOAD_SUCCESS:
      return {
        ...state,
        roles: action.payload,
      };

    case CONTRACTORS_CONTRACTOR_USER_ADD:
      return {
        ...state,
        users: state.users.concat([action.payload]),
      };

    default:
      return state;
  }
}
