import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReviewFormForStaff from 'components/admin/projects/details/project-daily-report-log/ReviewFormForStaff';
import {
  setReviewFormAction,
  setActiveContributor,
  setActiveEntriesId,
  setEditMode,
  setDelivery,
  deleteDelivery,
  addExtraInput,
  removeDeliveriesExtraInput,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';
import moment from 'moment';
import {
  getDelivery,
  getExtraInputs,
} from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import ProjectOpenItemReview from 'components/admin/projects/details/project-daily-report-log/ProjectOpenItemReview';
import FieldWorkOrderReview from 'components/admin/projects/details/project-daily-report-log/FieldWorkOrderReview';
import LogoUpload from 'components/shared/logo-upload/LogoUpload';
import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import SearchableDropDownPaginated from 'components/shared/dropdown/SearchableDropDownPaginated';
import ReviewFormForSub from 'components/admin/projects/details/project-daily-report-log/ReviewFormForSub';
import { submittalsApi } from 'services/api/submittals-api';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import ReadOnlyInput from 'components/shared/input/ReadOnlyInput';

class ReviewForm extends Component {
  deliveryExtraInputObject = { name: 'deliveries', label: 'Other Deliveries' };
  focusDeliveries = false;
  inputValue;

  componentDidMount() {
    const { reportExtraInputs, dispatch } = this.props;
    if (reportExtraInputs['deliveries']) {
      dispatch(addExtraInput(this.deliveryExtraInputObject));
    }
  }

  componentWillUnmount() {
    this.props.dispatch(removeDeliveriesExtraInput());
  }

  componentDidUpdate() {
    if (this.focusDeliveries) {
      document.getElementById('deliveries').focus();
      this.focusDeliveries = false;
    }
  }

  editEntry = (report) => {
    const { currentDailyReport, dispatch } = this.props;
    const sortedEntries = currentDailyReport.daily_report_entries.filter(
      (value) =>
        !value.daily_report_entry_id && !value.sub_daily_report_entry_id
    );
    let reportIndex = sortedEntries.findIndex(
      (value) => value.id === report.id
    );
    if (report.sub_daily_report_entry_id) {
      reportIndex = sortedEntries.findIndex(
        (value) => value.id === report.sub_daily_report_entry_id
      );
    } else if (report.daily_report_entry_id) {
      reportIndex = sortedEntries.findIndex(
        (value) => value.id === report.daily_report_entry_id
      );
    }
    if (reportIndex >= 0) {
      dispatch(setReviewFormAction(false));
      dispatch(setActiveContributor(reportIndex + 1));
      dispatch(setActiveEntriesId(report.id));
      dispatch(setEditMode(true));
      this.props.clearReviewErrors();
    }
  };

  updateDeliveries() {
    const { handleExtraInputChange, delivery } = this.props;
    handleExtraInputChange(
      'received_submittal_ids',
      delivery.map((val) => val.value)
    );
  }

  updateIsSafetyAccepted(value) {
    const { handleExtraInputChange } = this.props;
    handleExtraInputChange('is_safety_accepted', value);
  }

  removeDelivery = (indx) => {
    this.props.dispatch(deleteDelivery(indx));
    this.updateDeliveries();
  };

  loadDeliveries = (input, page) => {
    const delValue = this.props.isSub ? 'not_delivered_sub' : 'not_delivered';
    return submittalsApi
      .getSubmittals(this.props.projectId, page, delValue, input)
      .then((submittals) => {
        let resp = {
          ok: true,
          options: submittals.submittals.map((element) => {
            let label = `${element.csi_code.division}-${element.number} ${element.title}`;
            if (element.field_delivery) {
              label += ` - ${moment(element.field_delivery).format(
                'MM/DD/YY'
              )}`;
            }
            return {
              value: element.id,
              label,
            };
          }),
          hasMore: true,
        };
        if (page === 1 || input !== this.inputValue) {
          this.inputValue = input;
          resp.options.push({ label: 'Others', value: 'others' });
        }
        return resp;
      });
  };

  showTechniciansDetails = (technicians) => {
    return (
      technicians &&
      [...new Set(technicians.map((tech) => tech.hours_worked))].length > 1
    );
  };

  render() {
    const {
      daily_report_entries,
      sub_daily_report_entries,
      gc_staff_members,
      gc_staff_on_site,
      project_open_item,
      images,
    } = this.props.currentDailyReport;
    let orderedEntriesArr = [];
    const orderedEntries = [];
    if (daily_report_entries && daily_report_entries.length > 0) {
      daily_report_entries.sort((report1, report2) => {
        if (report1.project_contributor && report2.project_contributor) {
          return (
            Number(report1.project_contributor.csi_code.division) -
            Number(report2.project_contributor.csi_code.division)
          );
        }
        return false;
      });
      orderedEntriesArr = daily_report_entries
        .filter((drEntry) => !drEntry.daily_report_entry_id)
        .map((el) => {
          daily_report_entries.filter(
            (drEntry) => drEntry.daily_report_entry_id
          );
          return [
            el,
            ...daily_report_entries.filter(
              (drEntry) => drEntry.daily_report_entry_id === el.id
            ),
          ];
        });
    }
    if (sub_daily_report_entries && sub_daily_report_entries.length > 0) {
      sub_daily_report_entries.sort((report1, report2) => {
        if (report1.project_contributor && report2.project_contributor) {
          return (
            Number(report1.project_contributor.csi_code.division) -
            Number(report2.project_contributor.csi_code.division)
          );
        }
        return false;
      });
      orderedEntriesArr = sub_daily_report_entries
        .filter((drEntry) => !drEntry.sub_daily_report_entry_id)
        .map((el) => {
          sub_daily_report_entries.filter(
            (drEntry) => drEntry.sub_daily_report_entry_id
          );
          return [
            el,
            ...sub_daily_report_entries.filter(
              (drEntry) => drEntry.sub_daily_report_entry_id === el.id
            ),
          ];
        });
    }

    orderedEntriesArr.map((arr) => orderedEntries.push(...arr));

    const extraDeliveries = this.props.extraInputs.find(
      (attr) => attr.name === 'deliveries'
    );

    return (
      <React.Fragment>
        {gc_staff_on_site && (
          <ReviewFormForStaff
            members={gc_staff_members}
            generalContactorLabel={this.props.generalContactorLabel}
            openItem={project_open_item}
            readOnly={this.props.readOnly}
            dispatch={this.props.dispatch}
            errorsForStaff={this.props.errorsForStaff}
            errorsForOpenItem={this.props.errorsForOpenItem}
            clearErrorOnStaff={this.props.clearErrorOnStaff}
            clearErrorOnOpenItem={this.props.clearErrorOnOpenItem}
            images={images}
          />
        )}
        {orderedEntries &&
          orderedEntries.length > 0 &&
          orderedEntries[0].project_contributor &&
          orderedEntries.map((report) => {
            const isThereAreError = this.props.reviewErrors.find(
              (error) => error === report.id
            );

            const subTeches = [];
            let techId = null;
            let techRes = {};

            if (
              report.sub_daily_report_teches &&
              report.sub_daily_report_teches.length
            ) {
              const techListTechNumbers = report.sub_daily_report_teches
                .filter((tech) => !tech.sub_technician_id && tech.tech_number)
                .sort((tech1, tech2) => tech1.tech_number - tech2.tech_number);
              const techListSubTechIds = report.sub_daily_report_teches
                .filter((tech) => tech.sub_technician_id)
                .sort(
                  (tech1, tech2) =>
                    tech1.sub_technician_id - tech2.sub_technician_id
                );
              const resultTechList = [
                ...techListTechNumbers,
                ...techListSubTechIds,
              ];
              resultTechList.forEach((tech) => {
                let newTechId = tech.sub_technician_id || tech.tech_number;
                if (techId !== newTechId) {
                  techRes = tech;
                  techId = newTechId;
                  subTeches.push(techRes);
                } else {
                  if (techRes.additionalInfo) {
                    const isExisting = techRes.additionalInfo.find(
                      (el) => el.id === tech.id
                    );
                    if (!isExisting) {
                      techRes.additionalInfo.push(tech);
                    }
                  } else {
                    techRes.additionalInfo = [tech];
                  }
                }
              });
              if (subTeches.length) {
                subTeches.sort(
                  (tech1, tech2) => tech1.tech_number - tech2.tech_number
                );
              }
            }

            return (
              <React.Fragment key={report.id}>
                <DetailsTitle
                  className="modal-form-title"
                  title={
                    <React.Fragment>
                      {report.project_contributor &&
                        !(
                          report.daily_report_entry_id ||
                          report.sub_daily_report_entry_id
                        ) && (
                          <p>
                            {report.project_contributor.csi_code.division} -{' '}
                            {report.project_contributor.csi_code.label}
                          </p>
                        )}
                      {(report.floor || report.area) && (
                        <p className="modal-form-title-subtitle">
                          {report.floor && `Floor ${report.floor}`}
                          {report.floor && report.area && ' - '}
                          {report.area || ''}
                        </p>
                      )}
                    </React.Fragment>
                  }
                  valid={!isThereAreError}
                />
                {subTeches.length ? (
                  subTeches.map((reportSub, index) => (
                    <ReviewFormForSub
                      key={reportSub.id}
                      parentReport={report}
                      report={reportSub}
                      index={index}
                      readOnly={this.props.readOnly}
                      editEntry={this.editEntry}
                      currentDailyReport={this.props.currentDailyReport}
                    />
                  ))
                ) : (
                  <React.Fragment>
                    <DetailsTitle
                      className="modal-form-subtitle"
                      title={
                        report.project_contributor &&
                        report.project_contributor.company.name
                      }
                      rightButtons={
                        !this.props.readOnly && [
                          <Button
                            key="edit"
                            type={BUTTON_TYPES.LINK}
                            color={BUTTON_COLORS.GREEN}
                            label="Edit"
                            onClick={this.editEntry.bind(null, report)}
                          />,
                        ]
                      }
                    />
                    <div className="form-block multicontrols-block">
                      <FormControlBlock
                        label="Total Technicians"
                        control={
                          <ReadOnlyInput
                            value={report.technicians_count || 0}
                          />
                        }
                      />
                      <FormControlBlock
                        label="Total Hours"
                        control={
                          <ReadOnlyInput value={report.hours_worked || 0} />
                        }
                      />
                    </div>
                    {this.showTechniciansDetails(report.technicians) &&
                      report.technicians.map((technician, index) => (
                        <div
                          key={technician.id}
                          className="form-block multicontrols-block"
                        >
                          <span className="form-block-title">
                            Technician {index + 1}
                          </span>
                          <FormControlBlock
                            label="Total Hours"
                            control={
                              <ReadOnlyInput
                                value={technician.hours_worked || 0}
                              />
                            }
                          />
                        </div>
                      ))}
                    {((report.preset_tasks && report.preset_tasks.length > 0) ||
                      report.preset_task_other) && (
                      <div className="form-block">
                        <FormControlBlock
                          label="Tasks Performed"
                          control={
                            <ul className="form-items-list small-regular">
                              {report.preset_tasks.length > 0 &&
                                report.preset_tasks.map((task) => (
                                  <li key={task.id}>{task.label}</li>
                                ))}
                              {report.preset_task_other && (
                                <li>{report.preset_task_other}</li>
                              )}
                            </ul>
                          }
                        />
                      </div>
                    )}
                    <div className="form-block">
                      <FormControlBlock
                        label="Description"
                        control={
                          <TextareaAutosizeWrapper
                            initialValue={report.work_performed || '-'}
                            disabled={true}
                          />
                        }
                      />
                    </div>
                  </React.Fragment>
                )}
                {report.comments && !this.props.readOnly && (
                  <div className="form-block">
                    <FormControlBlock
                      label="Comments"
                      control={
                        <TextareaAutosizeWrapper
                          initialValue={report.comments}
                          disabled={true}
                        />
                      }
                    />
                  </div>
                )}
                {report && report.images && report.images.length > 0 && (
                  <div className="form-resources-block">
                    <div className="form-images">
                      <FormControlBlock
                        className="without-focus"
                        label="Photos"
                        control={
                          <div className="form-resources-body">
                            <LogoUpload
                              readOnly
                              logos={report.images.map((image) => ({
                                preview: image.image.gallery.url,
                                url: image.image.url,
                              }))}
                            />
                          </div>
                        }
                      />
                    </div>
                  </div>
                )}
                {report.project_open_item && (
                  <ProjectOpenItemReview openItem={report.project_open_item} />
                )}
                {report.field_work_order && (
                  <FieldWorkOrderReview
                    fieldWorkOrder={report.field_work_order}
                  />
                )}
              </React.Fragment>
            );
          })}

        <DetailsTitle className="modal-form-title" title="Information" />

        <DetailsTitle
          className="modal-form-subtitle"
          title="Saftey"
          rightButtons={[
            <LabeledCheckBox
              key="safety"
              className="safety-checkbox"
              label="This Project has been safety inspected"
              position="left"
              checked={this.props.reportExtraInputs.is_safety_accepted}
              readOnly={
                this.props.readOnly ||
                this.props.currentDailyReport.status === 'approved'
              }
              onChange={() =>
                this.updateIsSafetyAccepted(
                  !this.props.reportExtraInputs.is_safety_accepted
                )
              }
              valid={
                !this.props.reviewErrors.includes(
                  this.props.currentDailyReport.id
                )
              }
            />,
          ]}
        />
        <div className="form-block">
          <FormControlBlock
            label={
              (!!this.props.reportExtraInputs.safety ||
                this.props.readOnly ||
                this.props.currentDailyReport.status === 'approved') &&
              'Safety Information'
            }
            control={
              <TextareaAutosizeWrapper
                placeholder="Safety Information"
                initialValue={
                  this.props.reportExtraInputs.safety ||
                  (this.props.readOnly ||
                  this.props.currentDailyReport.status === 'approved'
                    ? '-'
                    : '')
                }
                onChange={(value) =>
                  this.props.handleExtraInputChange('safety', value)
                }
                disabled={
                  this.props.readOnly ||
                  this.props.currentDailyReport.status === 'approved'
                }
              />
            }
          />
        </div>

        {((!this.props.readOnly &&
          this.props.currentDailyReport.status !== 'approved') ||
          (this.props.delivery && this.props.delivery.length > 0) ||
          !!this.props.reportExtraInputs.deliveries) && (
          <DetailsTitle
            className="modal-form-subtitle"
            title="Deliveries"
            rightButtons={
              !this.props.readOnly &&
              this.props.currentDailyReport.status !== 'approved'
                ? [
                    <SearchableDropDownPaginated
                      className="daily-report-add-delivery"
                      key="add-delivery"
                      placeholder="Add Delivery"
                      valid={true}
                      defaultArrow={true}
                      withCreate={true}
                      loadOptions={(input, page) =>
                        this.loadDeliveries(input, page)
                      }
                      onChange={(input) => {
                        if (input.value === 'others') {
                          this.props.dispatch(
                            addExtraInput(this.deliveryExtraInputObject)
                          );
                          this.focusDeliveries = true;
                        } else {
                          this.props.dispatch(setDelivery(input));
                          this.updateDeliveries();
                        }
                      }}
                    />,
                  ]
                : null
            }
          />
        )}
        {this.props.delivery && this.props.delivery.length > 0 && (
          <div className="form-block">
            <ul className="form-items-list small-regular">
              {this.props.delivery.map((val, indx) => {
                return (
                  <li key={`deliveries_${indx}`}>
                    <div className="form-items-list-item">
                      <span>{val.label}</span>
                      {!this.props.readOnly &&
                        this.props.currentDailyReport.status !== 'approved' && (
                          <Button
                            type={BUTTON_TYPES.LINK}
                            icon={BUTTON_ICONS.DELETE}
                            onClick={() => this.removeDelivery(indx)}
                          />
                        )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {!!extraDeliveries && (
          <div className="form-block">
            <FormControlBlock
              label={
                (!!this.props.reportExtraInputs.deliveries ||
                  this.props.readOnly) &&
                extraDeliveries.label
              }
              control={
                <TextareaAutosizeWrapper
                  id="deliveries"
                  placeholder={extraDeliveries.label}
                  initialValue={
                    this.props.reportExtraInputs.deliveries ||
                    (this.props.readOnly ? '-' : '')
                  }
                  onChange={(value) =>
                    this.props.handleExtraInputChange('deliveries', value)
                  }
                  disabled={this.props.currentDailyReport.status === 'approved'}
                />
              }
            />
          </div>
        )}

        <DetailsTitle
          className="modal-form-subtitle"
          title="Additional Information"
        />

        {this.props.extraInputs.map((attr, index) => {
          const { name, label } = attr;
          const value = this.props.reportExtraInputs[name];
          const readOnly =
            this.props.readOnly ||
            this.props.currentDailyReport.status === 'approved';
          return (
            name !== 'deliveries' && (
              <div key={index} className="form-block">
                <FormControlBlock
                  label={readOnly && label}
                  control={
                    <TextareaAutosizeWrapper
                      id={name}
                      placeholder={label}
                      initialValue={value || (readOnly ? '-' : '')}
                      onChange={(value) =>
                        this.props.handleExtraInputChange(name, value)
                      }
                      disabled={readOnly}
                    />
                  }
                />
              </div>
            )
          );
        })}
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    delivery: getDelivery(state),
    extraInputs: getExtraInputs(state),
  };
})(ReviewForm);
