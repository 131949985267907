export function toUpperFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function validateEmail(email) {
  const emailRegularExpression = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return emailRegularExpression.test(email);
}

/**
 * Format the phone number.
 *
 * @param phoneNumber
 * @return {*}
 */
export function formatPhoneNumber(phoneNumber = null) {
  if (phoneNumber === null) {
    return '';
  }
  let pass = /[+][1]\d{10}/.test(phoneNumber);
  if (pass && phoneNumber.length === 12) {
    return phoneNumber.replace(/\+1(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  } else {
    return phoneNumber;
  }
}

export function buildTaskName(trade) {
  return trade;
}

export function getLastTokenFromUrl(url, defaultValue = null) {
  let result = defaultValue;
  if (url !== undefined && url !== null) {
    const tokens = url.split('/');
    if (tokens.length > 0) {
      result = tokens[tokens.length - 1];
    }
  }
  return result;
}

export function formatNumber(number) {
  return number && number < 10 ? `0${number}` : number;
}

export function camelize(str, firstLower = false) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 && firstLower
        ? word.toLowerCase()
        : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export function numbersOnly(str) {
  const match = str.match(/(\d+)/);
  return match ? match[0] : '';
}

export function numberWithUnit(value) {
  const abs = Math.abs(Number(value));
  let number = abs;
  let unit = '';
  if (abs >= 1.0e9) {
    number = abs / 1.0e9;
    unit = ' B';
  } else if (abs >= 1.0e6) {
    number = abs / 1.0e6;
    unit = ' M';
  } else if (abs >= 1.0e3) {
    number = abs / 1.0e3;
    unit = ' K';
  }
  return `${value < 0 ? '-' : ''}${number.toFixed(
    !!number % 1 ? 1 : 0
  )}${unit}`;
}
