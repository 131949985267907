import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Companies.css';
import SimpleTable from 'components/shared/table/SimpleTable';
import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import ContractorStatus from 'components/admin/contractors/ContractorStatus';
import {
  loadCompanies,
  setCompanyDropdown,
  changePage,
  setNameToSearch,
  clearFilters,
  setCompanyData,
  goToNewCompany,
} from 'components/admin/companies/store/actions';

import {
  getCompanies,
  getCompaniesCurrentPage,
  getCompaniesTotal,
  getCompaniesPageSize,
  getCompaniesDropdown,
  getCompanyNameToSearch,
} from 'components/admin/companies/store/selectors';

import { simpleScrollToTop } from 'services/utils/scroller-util';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import DropdownMenu, {
  DROPDOWN_TYPES,
} from 'components/shared/menu/DropdownMenu';
import { companyTypeOptions } from 'domain/company-type-enum';
import SearchBar from 'components/shared/navigation/SearchBar';
import DetailsHeader from 'components/shared/details/DetailsHeader';
import User from 'domain/user';
import SimpleText from 'components/shared/text/SimpleText';

class Companies extends Component {
  getType() {
    return this.props.companiesDropdown;
  }

  handleCompanyDropdownChange = (option) => {
    const { dispatch, companyNameToSearch } = this.props;

    dispatch(setCompanyDropdown(option.value));
    dispatch(loadCompanies(option.value, companyNameToSearch));
  };

  componentDidMount() {
    const { companyNameToSearch, dispatch } = this.props;

    dispatch(loadCompanies(this.getType(), companyNameToSearch));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearFilters());
  }

  handleCompanySelection = (index) => {
    simpleScrollToTop();
    const { history, companies } = this.props;
    const companyId = companies[index].id;
    history.push(`/admin/companies/${companyId}`);
  };

  handleCompaniesPageChange = (newPage) => {
    const { companyNameToSearch, dispatch } = this.props;

    dispatch(changePage(newPage));
    dispatch(loadCompanies(this.getType(), companyNameToSearch, newPage));
  };

  handleInputNameChange = (value) => {
    const { dispatch } = this.props;
    dispatch(setNameToSearch(value));
    dispatch(loadCompanies(this.getType(), value));
  };

  handleInviteCompany = () => {
    const { dispatch } = this.props;
    dispatch(setCompanyData({}));
    dispatch(goToNewCompany());
  };

  handleGenerateLink = (index) => {
    const { companies } = this.props;
    const companyId = companies[index].id;
    return `companies/${companyId}`;
  };

  render() {
    return (
      <div className="companies">
        <DetailsHeader
          leftHeader={{
            value: 'Companies',
          }}
        />

        <div className="content-container">
          <SearchBar
            addButton={
              <Button
                type={BUTTON_TYPES.LINK}
                icon={BUTTON_ICONS.PLUS}
                label="Invite Company"
                onClick={this.handleInviteCompany}
              />
            }
            searchText={this.props.companyNameToSearch}
            onSearchTextChange={this.handleInputNameChange}
            onSearch={this.handleInputNameChange}
          >
            <DropdownMenu
              type={DROPDOWN_TYPES.SECTION_SELECTOR}
              className="provider-selector"
              options={[{ value: '', label: 'All' }, ...companyTypeOptions]}
              placeholder="Contributor Type"
              value={this.props.companiesDropdown}
              onChange={this.handleCompanyDropdownChange}
            />
          </SearchBar>

          <div className="company-table-container">
            <SimpleTable
              className="companies-table"
              headers={['COMPANY NAME', 'STATUS', 'ADDRESS', 'POC', 'EMAIL']}
              rows={this.mapCompanies(this.props.companies)}
              emptyMessage="No Companies"
              onRowSelected={this.handleCompanySelection}
              onGenerateLink={this.handleGenerateLink}
            />
          </div>

          <div className="pagination-container">
            <SimplePagination
              currentPage={this.props.companiesCurrentPage}
              pageSize={this.props.companiesPageSize}
              total={this.props.companiesTotal}
              onPageChange={this.handleCompaniesPageChange}
            />
          </div>
        </div>
      </div>
    );
  }

  mapCompanies = (companies) => {
    return companies.map((company) => {
      return [
        company.name,
        <ContractorStatus status={company.status} />,
        <SimpleText
          lines={
            company.location
              ? [company.location.street_address_1, company.location.city]
              : ['N/A']
          }
        />,
        company.contact ? User.fullName(company.contact) : 'N/A',
        (company.contact && company.contact.email) || 'N/A',
      ];
    });
  };
}

export default connect((state) => {
  return {
    companies: getCompanies(state),
    companiesCurrentPage: getCompaniesCurrentPage(state),
    companiesPageSize: getCompaniesPageSize(state),
    companiesDropdown: getCompaniesDropdown(state),
    companiesTotal: getCompaniesTotal(state),
    companyNameToSearch: getCompanyNameToSearch(state),
  };
})(Companies);
