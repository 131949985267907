import { showErrorMessage } from 'actions/messages';
import { contractorsInvoicesApi } from 'services/api/contractors-invoices-api';
import {
  CONTRACTORS_INVOICES_INVOICE_REMOVE,
  CONTRACTORS_INVOICES_INVOICE_UPDATE,
  CONTRACTORS_INVOICES_LOAD_SUCCESS,
  CONTRACTORS_INVOICES_PAGE_CHANGE,
  CONTRACTORS_INVOICES_PAGINATION_INIT,
  CONTRACTORS_INVOICES_SEARCH_TEXT_CHANGE,
  CONTRACTORS_INVOICES_VENDOR_INVOICES_INVOICE_UPDATE,
  CONTRACTORS_INVOICES_VENDOR_INVOICES_PAGE_CHANGE,
  CONTRACTORS_INVOICES_VENDOR_INVOICES_PAGINATION_INIT,
  CONTRACTORS_INVOICES_VENDOR_INVOICES_SET,
  CONTRACTORS_INVOICES_SEARCH_FILTERS_INIT,
  CONTRACTORS_INVOICES_SEARCH_FILTER_VALUE_CHANGE,
} from 'components/admin/contractors-invoices/store/action-types';
import { vendorInvoicesApi } from 'services/api/vendor-invoices-api';
import { push } from 'react-router-redux';

const baseRoutePath = '/admin/contractors-invoices';

function changePage(page) {
  return {
    type: CONTRACTORS_INVOICES_PAGE_CHANGE,
    payload: page,
  };
}

export function changePageRequest(page, workOrderJobNumber = null) {
  return (dispatch) => {
    return contractorsInvoicesApi
      .getInvoices(page, workOrderJobNumber)
      .then((response) => {
        const result = {};
        if (response.ok) {
          dispatch(loadContractorsInvoicesSuccess(response.invoices.content));
          dispatch(changePage(page));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return result;
      });
  };
}

export function changeSearchText(value) {
  return {
    type: CONTRACTORS_INVOICES_SEARCH_TEXT_CHANGE,
    payload: value,
  };
}

function changeVendorInvoicesPage(page, pageSize, total) {
  return {
    type: CONTRACTORS_INVOICES_VENDOR_INVOICES_PAGE_CHANGE,
    payload: { page, pageSize, total },
  };
}

export function changeVendorInvoicesPageRequest(
  page,
  workOrderJobNumber = null,
  searchFilters = null
) {
  return (dispatch) => {
    return vendorInvoicesApi
      .getVendorInvoices(page, workOrderJobNumber, searchFilters)
      .then((response) => {
        if (response.ok) {
          dispatch(setVendorsInvoices(response.invoices.content));
          dispatch(
            changeVendorInvoicesPage(
              page,
              response.invoices.pageSize,
              response.invoices.total
            )
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
  };
}

export function deleteContractorInvoiceRequest(invoiceId) {
  return (dispatch) => {
    return contractorsInvoicesApi.deleteInvoice(invoiceId).then((response) => {
      if (response.ok) {
        dispatch(removeContractorInvoice(invoiceId));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function getVendorsInvoices() {
  return (dispatch) => {
    return vendorInvoicesApi.getVendorInvoices().then((response) => {
      if (response.ok) {
        dispatch(setVendorsInvoices(response.invoices.content));
        dispatch(
          initializeVendorInvoicesPagination(
            response.invoices.pageSize,
            response.invoices.total
          )
        );
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function goToContractorInvoices() {
  return (dispatch) => {
    dispatch(push(baseRoutePath));
  };
}

export function initializePagination(pageSize, total) {
  return {
    type: CONTRACTORS_INVOICES_PAGINATION_INIT,
    payload: { pageSize, total },
  };
}

export function initializeVendorInvoicesPagination(pageSize, total) {
  return {
    type: CONTRACTORS_INVOICES_VENDOR_INVOICES_PAGINATION_INIT,
    payload: { pageSize, total },
  };
}

export function loadContractorsInvoicesRequest(
  page = 1,
  workOrderJobNumber = null
) {
  return (dispatch) => {
    return contractorsInvoicesApi
      .getInvoices(page, workOrderJobNumber)
      .then((response) => {
        const result = {};
        if (response.ok) {
          dispatch(loadContractorsInvoicesSuccess(response.invoices.content));
          dispatch(
            initializePagination(
              response.invoices.pageSize,
              response.invoices.total
            )
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return result;
      });
  };
}

export function loadContractorsInvoicesSuccess(invoices) {
  return {
    type: CONTRACTORS_INVOICES_LOAD_SUCCESS,
    payload: invoices,
  };
}

export function updateContractorInvoiceRequest(invoiceId, dueDate) {
  return (dispatch) => {
    return contractorsInvoicesApi
      .updateInvoice(invoiceId, dueDate)
      .then((response) => {
        if (response.ok) {
          dispatch(updateInvoice(response.invoice));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function markAsPaidRequest(invoiceId) {
  return (dispatch) => {
    return contractorsInvoicesApi.markAsPaid(invoiceId).then((response) => {
      const result = {};
      if (response.ok) {
        dispatch(updateInvoice(response.invoice));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return result;
    });
  };
}

export function markVendorInvoiceAsPaid(invoiceId) {
  return (dispatch) => {
    return vendorInvoicesApi.markAsPaid(invoiceId).then((response) => {
      const result = {};
      if (response.ok) {
        dispatch(updateVendorInvoice(response.invoice));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return result;
    });
  };
}

export function removeContractorInvoice(invoiceId) {
  return {
    type: CONTRACTORS_INVOICES_INVOICE_REMOVE,
    payload: invoiceId,
  };
}

function setVendorsInvoices(invoices) {
  return {
    type: CONTRACTORS_INVOICES_VENDOR_INVOICES_SET,
    payload: invoices,
  };
}

export function updateInvoice(invoice) {
  return {
    type: CONTRACTORS_INVOICES_INVOICE_UPDATE,
    payload: invoice,
  };
}

function updateVendorInvoice(invoice) {
  return {
    type: CONTRACTORS_INVOICES_VENDOR_INVOICES_INVOICE_UPDATE,
    payload: invoice,
  };
}

export function initSearchFilters() {
  return {
    type: CONTRACTORS_INVOICES_SEARCH_FILTERS_INIT,
  };
}

export function changeSearchFilterValue(attribute, value) {
  return {
    type: CONTRACTORS_INVOICES_SEARCH_FILTER_VALUE_CHANGE,
    payload: { attribute: attribute, value: value },
  };
}
