import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';

class ServiceProvidersApi extends BaseApi {
  getServiceProviders(type, byName, page, loadingDisabled = true) {
    let queryString = `type=${type}&page=${page || 1}`;

    if (byName) {
      queryString += `&by_name=${byName}`;
    }

    return axios
      .get(`${this.baseUrl}/service_providers?${queryString}`, {
        loadingDisabled: loadingDisabled,
      })
      .then((response) => {
        return {
          ok: true,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
          serviceProviders: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  showServiceProviderById(type, id, loadingDisabled = true) {
    let queryString = `type=${type}`;

    return axios
      .get(`${this.baseUrl}/service_providers/${id}?${queryString}`, {
        loadingDisabled: loadingDisabled,
      })
      .then((response) => {
        return {
          ok: true,
          serviceProvider: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getServiceProviderUsers(type, serviceProviderId, enabled = false) {
    let url = `${this.baseUrl}/service_providers/${serviceProviderId}/users?type=${type}`;
    if (enabled) {
      url += '&enabled=true';
    }
    return axios
      .get(url)
      .then((response) => {
        return {
          ok: true,
          users: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createServiceProvider(type, values) {
    const data = this.serviceProviderParams(values);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .post(`${this.baseUrl}/service_providers?type=${type}`, data, headers)
      .then((response) => {
        return {
          ok: true,
          serviceProvider: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateServiceProvider(type, id, values) {
    const data = this.serviceProviderParams(values);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .put(
        `${this.baseUrl}/service_providers/${id}?type=${type}`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          serviceProvider: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  editUser(values) {
    const data = this.userParams(values.type, values);

    return axios
      .put(
        `${this.baseUrl}/service_providers/${values.accountableId}/users/${values.id}`,
        data
      )
      .then((response) => {
        return {
          ok: true,
          updatedUser: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  deleteUser(values) {
    return axios
      .delete(
        `${this.baseUrl}/service_providers/${values.accountableId}/users/${values.id}?type=${values.type}`
      )
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createServiceProviderDirectory(type, values) {
    let data = new FormData();
    data.append('type', type);
    data.append('service_provider[name]', values.name);
    data.append(
      'service_provider[contact_attributes][first_name]',
      values.contact.firstName
    );
    data.append(
      'service_provider[contact_attributes][last_name]',
      values.contact.lastName
    );
    data.append(
      'service_provider[contact_attributes][email]',
      values.contact.email
    );
    data.append(
      'service_provider[contact_attributes][phone_number]',
      values.contact.phoneNumber
    );
    data.append(
      'service_provider[contact_attributes][office_phone_number]',
      values.contact.officePhoneNumber
    );
    data.append(
      'service_provider[contact_attributes][office_fax_number]',
      values.contact.officeFaxNumber
    );
    data.append(
      'service_provider[contact_attributes][phone_ext]',
      values.contact.phoneExt
    );
    data.append(
      'service_provider[contact_attributes][title]',
      values.contact.title
    );

    if (values.image && values.image.name && values.image.preview) {
      data.append('service_provider[logo_attributes][image]', values.image);
    }

    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };

    return axios
      .post(`${this.baseUrl}/service_providers`, data, headers)
      .then((response) => {
        return {
          ok: true,
          serviceProvider: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  inviteUser(type, serviceProviderId, values) {
    const data = this.userParams(type, values);

    return axios
      .post(
        `${this.baseUrl}/service_providers/${serviceProviderId}/invite_user`,
        data
      )
      .then((response) => {
        return {
          ok: true,
          user: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  sendInvite(user) {
    const { id, accountable_id, accountable_type } = user;

    return axios
      .put(
        `${this.baseUrl}/service_providers/${accountable_id}/users/${id}/send_invite?type=${accountable_type}`
      )
      .then((response) => {
        return {
          ok: true,
          user: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  activationLink(user) {
    const { id, accountable_id, accountable_type } = user;

    return axios
      .get(
        `${this.baseUrl}/service_providers/${accountable_id}/users/${id}/accept_invitation_url?type=${accountable_type}`
      )
      .then((response) => {
        return {
          ok: true,
          instructions: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  registerToNewCompany(type, values) {
    const data = this.serviceProviderParams(values);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .post(
        `${this.baseUrl}/service_providers/register_to_new_company?type=${type}`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          user: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  registerToExistentCompany(type, id, values) {
    const data = this.serviceProviderParams(values);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .post(
        `${this.baseUrl}/service_providers/${id}/register_to_existent_company?type=${type}`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          user: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  verifyUser(user) {
    const { id, accountable_id, accountable_type } = user;

    return axios
      .put(
        `${this.baseUrl}/service_providers/${accountable_id}/users/${id}/verify_user?type=${accountable_type}`
      )
      .then((response) => {
        return {
          ok: true,
          user: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  rejectUser(user) {
    const { id, accountable_id, accountable_type } = user;

    return axios
      .put(
        `${this.baseUrl}/service_providers/${accountable_id}/users/${id}/reject_user?type=${accountable_type}`
      )
      .then((response) => {
        return {
          ok: true,
          user: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  makeSimilarCompanyMsg = (params, company, criteria) => {
    const companyName = company.name;

    switch (criteria) {
      case 'company_name':
        return `Already exists a company with name ${companyName}.`;
      case 'email':
        return `Company ${companyName} has same email ${params.email}.`;
      case 'phone_number':
        return `Company ${companyName} has same primary contact phone number ${params.phone_number}.`;
      case 'office_phone_number':
        return `Company ${companyName} has same office phone number ${params.office_phone_number}.`;
      case 'office_fax_number':
        return `Company ${companyName} has same office fax number ${params.office_fax_number}.`;
      case 'company_name_similar':
        return `A similar company ${companyName} was found.`;
      default:
        return '';
    }
  };

  makeSimilarCompanyDisclaimer = (params, company, criteria) => {
    switch (criteria) {
      case 'company_name':
        return 'Name';
      case 'email':
        return 'Email';
      case 'phone_number':
        return 'Contact Phone';
      case 'office_phone_number':
        return 'Office Phone';
      case 'office_fax_number':
        return 'Office Fax';
      case 'company_name_similar':
        return 'Similar Name';
      default:
        return '';
    }
  };

  makeFindSimilarResult = (params, data) => {
    switch (params.mode) {
      case 'list':
        return this.makeFindSimilarListResult(params, data);
      default:
        return this.makeFindSimilarFirstResult(params, data);
    }
  };

  makeFindSimilarListResult = (params, data) => {
    return {
      ok: true,
      companies: data.map((field) => ({
        ...field,
        disclaimer: this.makeSimilarCompanyDisclaimer(
          params,
          field.company,
          field.criteria
        ),
      })),
    };
  };

  makeFindSimilarFirstResult = (params, data) => {
    if (data.length === 0) return { ok: true };

    const { company, criteria } = data[0];
    return {
      ok: true,
      company: company,
      criteria: criteria,
      message: this.makeSimilarCompanyMsg(params, company, criteria),
    };
  };

  findSimilar(params) {
    return axios
      .post(`${this.baseUrl}/service_providers/find_similar`, {
        mode: params.mode,
        company_type: params.company_type,
        name: params.name,
        email: params.email,
        phone_number: params.phone_number,
        office_phone_number: params.office_phone_number,
        office_fax_number: params.office_fax_number,
      })
      .then((response) => this.makeFindSimilarResult(params, response.data))
      .catch((error) => {
        return {
          ok: false,
          //TODO check why test is failing
          errors: (error.response && error.response.data.errors) || '',
        };
      });
  }

  serviceProviderParams(values) {
    const data = new FormData();
    if (values.name !== undefined) {
      data.append('service_provider[name]', values.name);
      if (values.contactId) {
        data.append(
          'service_provider[contact_attributes][id]',
          values.contactId
        );
      }

      data.append(
        'service_provider[contact_attributes][first_name]',
        values.firstName
      );
      data.append(
        'service_provider[contact_attributes][last_name]',
        values.lastName
      );
      data.append('service_provider[contact_attributes][email]', values.email);
      data.append(
        'service_provider[contact_attributes][phone_number]',
        values.phoneNumber
      );
      data.append(
        'service_provider[contact_attributes][office_phone_number]',
        values.officePhoneNumber
      );
      data.append(
        'service_provider[contact_attributes][office_fax_number]',
        values.officeFaxNumber
      );
      if (values.title) {
        data.append(
          'service_provider[contact_attributes][title]',
          values.title
        );
      }
      if (values.titleRole) {
        data.append(
          'service_provider[contact_attributes][title_role]',
          values.titleRole
        );
      }

      if (values.locationId) {
        data.append(
          'service_provider[location_attributes][id]',
          values.locationId
        );
      }

      data.append(
        'service_provider[location_attributes][street_address_1]',
        values.streetAddress
      );
      data.append(
        'service_provider[location_attributes][street_address_2]',
        values.unitNumber || ''
      );
      data.append('service_provider[location_attributes][city]', values.city);
      data.append('service_provider[location_attributes][state]', values.state);
      data.append('service_provider[location_attributes][zip]', values.zipCode);

      if (values.licenceNumber) {
        data.append('service_provider[license_number]', values.licenceNumber);
      }
      if (values.otherUnion) {
        data.append('service_provider[other_union]', values.otherUnion);
      }
      if (values.selectedUnionsId) {
        values.selectedUnionsId.forEach((unionId) =>
          data.append('service_provider[union_ids][]', unionId)
        );
      }
      if (values.tradesId) {
        values.tradesId.forEach((id) =>
          data.append('service_provider[trade_ids][]', id)
        );
      }
    }

    if (values.logo) {
      if (values.logo.id) {
        data.append('service_provider[logo_attributes][id]', values.logo.id);
      }

      if (values.logo.destroy) {
        data.append('service_provider[logo_attributes][_destroy]', true);
      } else if (values.logo.file) {
        const { file } = values.logo;
        data.append(
          'service_provider[logo_attributes][image]',
          file,
          file.name
        );
      }
    }

    if (values.stamp) {
      const { stamp } = values;
      if (stamp.id) {
        data.append('service_provider[stamp_attributes][id]', stamp.id);
      }

      if (stamp.destroy) {
        data.append('service_provider[stamp_attributes][_destroy]', true);
      } else {
        data.append('service_provider[stamp_attributes][legal]', stamp.legal);
        stamp.stampActions &&
          stamp.stampActions.forEach((stampAction, index) => {
            if (stampAction.id) {
              data.append(
                `service_provider[stamp_attributes][stamp_actions_attributes][${index}][id]`,
                stampAction.id
              );
            }

            if (stampAction.destroy) {
              data.append(
                `service_provider[stamp_attributes][stamp_actions_attributes][${index}][_destroy]`,
                stampAction.destroy
              );
            } else {
              data.append(
                `service_provider[stamp_attributes][stamp_actions_attributes][${index}][action]`,
                stampAction.action
              );
              data.append(
                `service_provider[stamp_attributes][stamp_actions_attributes][${index}][title]`,
                stampAction.title
              );
              data.append(
                `service_provider[stamp_attributes][stamp_actions_attributes][${index}][subtitle]`,
                stampAction.subtitle || ''
              );
            }
          });
      }
    }

    if (values.user) {
      data.append('user[first_name]', values.user.firstName);
      data.append('user[last_name]', values.user.lastName);
      data.append('user[email]', values.user.email);
      data.append('user[phone_number]', values.user.phoneNumber);
      if (values.user.title) {
        data.append('user[title]', values.user.title);
      }
      if (values.user.rolesId) {
        values.user.rolesId.forEach((id) =>
          data.append('user[role_ids][]', id)
        );
      }
    }

    return data;
  }

  userParams(type, values) {
    return {
      type: type,
      user: {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone_number: values.phoneNumber,
        phone_ext: values.phoneExt,
        title: values.title,
        role_ids: values.rolesId,
      },
    };
  }

  userFormParams(type, values) {
    return {
      type: type,
      user: {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone_number: values.phoneNumber,
        phone_ext: values.phoneExt,
        title: values.title,
        role_ids: values.rolesId,
      },
    };
  }
}

export const serviceProvidersApi = new ServiceProvidersApi();
