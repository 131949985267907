import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'components/admin/projects/details/project-directory/UserForm.css';
import { Form } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import Input from 'components/shared/input/Input';
import Selector from 'components/shared/selector/Selector';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import {
  companyTitleOptions,
  companyRoleOptions,
  COMPANY_TITLES,
} from 'domain/user-role-enum';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

class InviteUserForm extends Component {
  state = {
    showCustomTitle: false,
  };

  static propTypes = {
    roles: PropTypes.arrayOf(PropTypes.shape({})),
    submitLabel: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    canSetAccountAdmin: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    if (this.props.initialValues) {
      const { title } = this.props.initialValues;
      const isStandardTitle = COMPANY_TITLES.includes(title);

      this.setState({
        showCustomTitle: !isStandardTitle,
      });

      if (!isStandardTitle) {
        this.props.change('dropdownTitle', 'Other');
        this.props.change('customTitle', title);
      } else {
        this.props.change('dropdownTitle', title);
      }
    }
  }
  renderInput = (props) => {
    return (
      <Input
        valid={!props.meta.error || !props.meta.submitFailed}
        value={props.input.value}
        onChange={(value) => props.input.onChange(value)}
        {...props}
      />
    );
  };

  onChangeDropDown = (option, props) => {
    const value = option && option.value ? option.value : '';
    props.input.onChange(value);

    if (props.input.name === 'dropdownTitle') {
      const showCustom = value === 'Other';
      this.setState({ showCustomTitle: showCustom });

      if (!showCustom) {
        this.props.change('customTitle', '');
      }
    }
  };

  renderDropDown = (props) => {
    return (
      <Selector
        valid={!props.meta.error || !props.meta.submitFailed}
        value={props.input.value}
        onChange={(option) => this.onChangeDropDown(option, props)}
        {...props}
      />
    );
  };

  renderCheckbox = (props) => {
    return (
      <LabeledCheckBox
        valid={!props.meta.error || !props.meta.submitFailed}
        checked={!!props.input.value}
        onChange={(value) => props.input.onChange(value)}
        size="medium"
        position="left"
        {...props}
      />
    );
  };

  render() {
    const { canSetAccountAdmin, roles, onClose } = this.props;
    const { showCustomTitle } = this.state;

    return (
      <div className="invite-user-form">
        <Form horizontal onSubmit={this.props.handleSubmit}>
          <div className="form-block">
            <label className="form-block-label">First Name</label>
            <Field
              component={this.renderInput}
              name="firstName"
              placeholder="First Name"
            />
          </div>
          <div className="form-block">
            <label className="form-block-label">Last Name</label>
            <Field
              component={this.renderInput}
              name="lastName"
              placeholder="Last Name"
            />
          </div>
          <div className="form-block">
            <label className="form-block-label">Email</label>
            <Field
              component={this.renderInput}
              name="email"
              placeholder="Email"
            />
          </div>
          <div className="form-block">
            <label className="form-block-label">Phone Number</label>
            <Field
              component={this.renderInput}
              name="phoneNumber"
              placeholder="Phone Number"
            />
            <Field
              component={this.renderInput}
              name="phoneExt"
              placeholder="Ext"
            />
          </div>
          <div className="form-block">
            <label className="form-block-label">Title</label>
            <Field
              component={this.renderDropDown}
              name="dropdownTitle"
              placeholder="Select Title"
              options={[
                { value: 'Other', label: 'Other' },
                ...companyTitleOptions,
              ]}
            />
            {showCustomTitle && (
              <Field
                component={this.renderInput}
                name="customTitle"
                placeholder="Custom Title"
              />
            )}
          </div>
          <div className="form-block">
            <label className="form-block-label">Role</label>
            <Field
              component={this.renderDropDown}
              name="assignedRole"
              placeholder="Select Role"
              options={companyRoleOptions(roles)}
            />
          </div>
          {canSetAccountAdmin && (
            <div className="form-block">
              <label className="form-block-label"></label>
              <Field
                component={this.renderCheckbox}
                name="accountAdmin"
                label="Account Administrator"
              />
            </div>
          )}
          {this.props.invalid && this.props.submitFailed && (
            <div className="invalid-message">
              The outlined inputs should be added in order to Invite a User.
            </div>
          )}
          <div className="buttons-container">
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Cancel"
              onClick={onClose}
            />
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label={this.props.submitLabel}
              onClick={this.props.handleSubmit}
              disabled={this.props.invalid}
            />
          </div>
        </Form>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = 'Required';
  }

  if (!values.lastName) {
    errors.lastName = 'Required';
  }

  if (!values.email) {
    errors.email = 'Required';
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = 'Required';
  }

  if (!values.title) {
    errors.title = 'Required';
  }

  return errors;
}

export default reduxForm({
  form: 'InviteUserForm',
  validate,
})(InviteUserForm);
