export const CLOSEOUT_CATS_SET = 'CLOSEOUT_CATS_SET';
export const CLOSEOUT_COMPANY_TYPES_SET = 'CLOSEOUT_COMPANY_TYPES_SET';
export const CLOSEOUT_SET = 'CLOSEOUT_SET';
export const CLOSEOUT_CONTRIBS_SET = 'CLOSEOUT_CONTRIBS_SET';
export const CLOSEOUT_CONTRIB_SET = 'CLOSEOUT_CONTRIB_SET';
export const CLOSEOUT_PERCENTAGE_SET = 'CLOSEOUT_PERCENTAGE_SET';
export const CLOSEOUT_MANUALS_SET = 'CLOSEOUT_MANUALS_SET';
export const CLOSEOUT_MANUAL_SET = 'CLOSEOUT_MANUAL_SET';
export const CLOSEOUT_MANUAL_UPDATE = 'CLOSEOUT_MANUAL_UPDATE';
export const CLOSEOUT_MANUAL_DELETE = 'CLOSEOUT_MANUAL_DELETE';
export const CLOSEOUT_CATS_COMPANY_TYPES_SET =
  'CLOSEOUT_CATS_COMPANY_TYPES_SET';
export const CLOSEOUT_CATS_COMPANY_TYPE_SET = 'CLOSEOUT_CATS_COMPANY_TYPE_SET';
export const CLOSEOUT_CAT_DELETE = 'CLOSEOUT_CAT_DELETE';
export const CLOSEOUT_CAT_UPDATE = 'CLOSEOUT_CAT_UPDATE';
export const CLOSEOUT_CATS_ORDER_CHANGE = 'CLOSEOUT_CATS_ORDER_CHANGE';
export const SHOW_CLOSEOUT_CAT_DELETION_CONFIRMATION =
  'SHOW_CLOSEOUT_CAT_DELETION_CONFIRMATION';
export const SHOW_CLOSEOUT_CAT_MODAL = 'SHOW_CLOSEOUT_CAT_MODAL';
export const CLOSEOUT_CAT_SET = 'CLOSEOUT_CAT_SET';
export const CLOSEOUT_CONTRIB_CAT_UPDATE = 'CLOSEOUT_CONTRIB_CAT_UPDATE';
export const HIDE_CLOSEOUT_CAT_MODAL = 'HIDE_CLOSEOUT_CAT_MODAL';
export const HIDE_CLOSEOUT_CAT_DELETION_CONFIRMATION =
  'HIDE_CLOSEOUT_CAT_DELETION_CONFIRMATION';
