import moment from 'moment';

export class DueDateFilterUtil {
  static getDueDateQuery(dueDateFilter, serverDate) {
    const todaysDate = moment().format('YYYY-MM-DD');
    let dueDateQuery = null;
    switch (dueDateFilter) {
      case 'pending':
        dueDateQuery = serverDate
          ? 'over_due=true'
          : `all_before_due=${todaysDate}`;
        break;
      case 'overdue':
        dueDateQuery = serverDate
          ? 'before_due=true'
          : `all_past_due=${todaysDate}`;
        break;
      case 'ascending':
        dueDateQuery = 'due_asc=true';
        break;
      case 'descending':
        dueDateQuery = 'due_desc=true';
        break;
      default:
    }
    return dueDateQuery;
  }
}
