import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Rfis.css';
import NavigationBar from 'components/shared/navigation/NavigationBar';
import { getCurrentUser } from 'selectors/authentication';
import { rfisRequest } from 'components/admin/rfis/store/actions';
import RfisTable from 'components/admin/rfis/RfisTable';
import User from 'domain/user';

class Rfis extends Component {
  static propTypes = {
    filters: PropTypes.shape(),
  };

  static defaultProps = {
    filters: {},
  };

  constructor(props) {
    super(props);

    this.isSubscriber = User.isAdminOrSubscriber(props.currentUser);
    this.state = { rfisTab: 2 };
  }

  componentDidMount() {
    this.handlePageChange(1, this.defaultStatus());
  }

  handlePageChange = (page, status) => {
    const { filters, dispatch } = this.props;
    dispatch(rfisRequest(page, status, { ...filters, [status]: true }));
  };

  defaultStatus = (rfisTab = 2) => {
    return (
      (this.isSubscriber && (rfisTab === 1 ? 'pending' : 'not_pre')) || 'all'
    );
  };

  handleRfisTabSelection = (rfisTab) => {
    this.handlePageChange(1, this.defaultStatus(rfisTab));
    this.setState({ rfisTab: rfisTab });
  };

  render() {
    const { rfisTab } = this.state;
    return (
      <div className="rfis">
        <div className="rfis-header">
          <NavigationBar
            items={[
              {
                id: 1,
                title: 'P-RFI Log',
                show: this.isSubscriber,
              },
              {
                id: 2,
                title: 'RFI Log',
              },
            ]}
            selectedValue={rfisTab}
            onSelected={this.handleRfisTabSelection}
          />
        </div>
        <RfisTable onPageChange={this.handlePageChange} />
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
  };
})(Rfis);
