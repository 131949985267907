import User from 'domain/user';
import DailyReport from 'domain/daily-report';

class DailyReportPermissions {
  canManage = (user, project, directory, dailyReport) => {
    if (User.isAdminOrProjectSubscriber(user, project)) return true;

    if (DailyReport.isSubmittedByGcOar(directory, dailyReport))
      return DailyReport.isSubmittedByUserCompany(user, dailyReport);

    return User.isGcOar(user, project);
  };

  canEditDate = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project);

  canView = (user, project) => !User.isContractor(user, project);

  canViewSub = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project) ||
    User.isContractor(user, project);

  canViewEwos = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project) ||
    User.isContractor(user, project);

  canViewFieldWorkOrdersGcStage = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project);

  canAdd = (user, project) => User.isAdminProjectSubscriberGcOar(user, project);

  canAddSub = (user, project) => User.isContractor(user, project);

  canSetMultipleFloors = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project);

  canViewLog = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project);
}

export default new DailyReportPermissions();
