import React from 'react';
import { connect } from 'react-redux';
import { itemsToIds } from 'services/utils/project-photos-util';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { createBreakpoint } from 'react-use';
import { Grid, Typography } from '@mui/material';
import Button, { BUTTON_TYPES } from 'components/shared/button/Button';
import IconImage from 'images/image.svg';
import {
  getProjectPhotosCurrentAlbums,
  getProjectPhotosCurrentPhotos,
  getProjectPhotosFilters,
  getProjectPhotosFixedAlbums,
  getProjectPhotosSelectedAlbums,
  getProjectPhotosSelectedPhotos,
  getProjectPhotosSelecting,
  getProjectPhotosTotalAlbums,
  getProjectPhotosTotalPhotos,
} from './store/selectors';
import ProjectPhotosItem from './ProjectPhotosItem';
import DraggableGallery from 'components/shared/draggable-gallery/DraggableGallery';
import ProjectPhotosTitle from './ProjectPhotosTitle';
import {
  orderProjectPhotos,
  setProjectPhotosFilters,
  setProjectPhotosSelectedAlbums,
  setProjectPhotosSelectedPhotos,
  openProjectPhotosDetail,
} from './store/actions';
import useProjectPhotosActions from './ProjectPhotosActions.hook';

const useBreakpoint = createBreakpoint({ XL: 1480, L: 1100, M: 800, S: 480 });

const DROPPABLE_KEY = 'PROJECT_PHOTOS_ALBUMS_AND_PHOTOS';
const ProjectPhotosContent = (props) => {
  const {
    dispatch,
    filters,
    selecting,
    currentProject,
    currentPhotos,
    totalPhotos,
    currentAlbums,
    totalAlbums,
    selectedAlbums,
    selectedPhotos,
    fixedAlbums,
  } = props;

  const {
    moveToAlbum,
    moveToArchive,
    moveToFavorites,
    moveToRecycle,
    onUnselect,
  } = useProjectPhotosActions(props);

  // columns
  const breakpoint = useBreakpoint();
  const getColumns = () => {
    switch (breakpoint) {
      case 'XL':
        return 5;
      case 'L':
        return 4;
      case 'M':
        return 2;
      case 'S':
        return 1;
      default:
        return 4;
    }
  };
  const columns = getColumns();
  const showAlbums =
    totalAlbums > 0 ||
    (filters.albumId === null &&
      !filters.favorites &&
      filters.status !== 'archived' &&
      filters.status !== 'recycled');

  const getAlbumItems = () => {
    if (
      filters.albumId !== null ||
      filters.favorites ||
      filters.status === 'archived' ||
      filters.status === 'recycled'
    )
      return currentAlbums;

    const items = [...currentAlbums];
    if (fixedAlbums !== null) {
      if (currentAlbums.length > 0 || currentPhotos.length > 0)
        items.splice(0, 0, fixedAlbums.favorites);
      items.splice(Math.min(6, items.length), 0, fixedAlbums.archives);
      items.splice(Math.min(7, items.length), 0, fixedAlbums.recycle_bin);

      if (items.length > 8 && filters.albumsPage === 1)
        items.splice(8, items.length - 8);
    }

    return items;
  };
  const isFixedAlbum = (album) => {
    return ['favorites', 'archive', 'recycle_bin'].includes(album.id);
  };

  // click
  const onClickAlbum = (album) => {
    const newFilters = { doSearch: true };
    if (album.id === 'favorites') newFilters.favorites = true;
    else if (album.id === 'archive') newFilters.status = 'archived';
    else if (album.id === 'recycle_bin') newFilters.status = 'recycled';
    else newFilters.albumId = album.id;

    onUnselect();
    dispatch(setProjectPhotosFilters(newFilters));
  };
  const onClickPhoto = (photo) => {
    onUnselect();
    dispatch(openProjectPhotosDetail(currentProject.id, photo.id, 'photo'));
  };

  // order
  const onOrderAlbums = (items) => {
    const albums = items.filter((a) => !isFixedAlbum(a));
    const ids = albums.map(({ id }) => id);

    dispatch(orderProjectPhotos(currentProject.id, ids, 'album'));
  };
  const onOrderPhotos = (photos) => {
    const ids = photos.map(({ id }) => id);

    dispatch(orderProjectPhotos(currentProject.id, ids, 'photo'));
  };
  const onMoveToAlbum = (movingItems, album) => {
    let items = movingItems;
    if (selectedAlbums.length > 0 || selectedPhotos.length > 0)
      items = [...selectedAlbums, ...selectedPhotos];

    const ids = itemsToIds(items);

    if (album.id === 'favorites') moveToFavorites(ids);
    else if (album.id === 'archive') moveToArchive(ids);
    else if (album.id === 'recycle_bin') moveToRecycle(ids);
    else moveToAlbum(ids, album);
  };

  // select
  const onSelectAlbums = (items) => {
    const albums = items || [];
    const selecting = albums.length > 0 || selectedPhotos.length > 0;
    dispatch(setProjectPhotosSelectedAlbums(albums, selecting));
  };
  const onSelectPhotos = (items) => {
    const photos = items || [];
    const selecting = photos.length > 0 || selectedAlbums.length > 0;
    dispatch(setProjectPhotosSelectedPhotos(photos, selecting));
  };

  // load more
  const canLoadMoreAlbums = () => {
    const total = totalAlbums;
    const count = currentAlbums.length;
    const page = filters.albumsPage;

    return count < total || (count > 5 && page === 1);
  };
  const canLoadMorePhotos = () => {
    const total = totalPhotos;
    const count = currentPhotos.length;
    return count < total;
  };
  const loadMoreAlbums = () => {
    dispatch(
      setProjectPhotosFilters({
        doSearch: true,
        albumsPage: filters.albumsPage + 1,
      })
    );
  };
  const loadMorePhotos = () => {
    dispatch(
      setProjectPhotosFilters({
        doSearch: true,
        photosPage: filters.photosPage + 1,
      })
    );
  };
  const getMoreAlbumsText = () => {
    if (!canLoadMoreAlbums) return '';

    if (filters.albumsPage === 1 && filters.albumId === null)
      return `View All ${totalAlbums} Albums`;

    return 'View More';
  };

  return (
    <Grid
      container
      className="project-photos-content"
      flexDirection={'column'}
      paddingTop={1}
      spacing={2.5}
    >
      {showAlbums && (
        <Grid item>
          <DraggableGallery
            columns={columns}
            spacing={16}
            enableDrag
            enableSelect
            onItemClick={onClickAlbum}
            onOrder={onOrderAlbums}
            onMove={onMoveToAlbum}
            onSelectedChange={onSelectAlbums}
            selected={selecting ? selectedAlbums : null}
            items={getAlbumItems()}
            droppableKey={DROPPABLE_KEY}
            canItemBeMoved={(item) => !isFixedAlbum(item)}
            canItemBeSelected={(item) => !isFixedAlbum(item)}
            canItemBeOrder={(item) => !isFixedAlbum(item)}
            renderItem={(album, selected) => {
              const isFixed = isFixedAlbum(album);

              return (
                <ProjectPhotosItem
                  id={album.id}
                  cover={album.cover_image}
                  title={album.title}
                  count={album.count_albums + album.count_photos}
                  createdBy={album.created_by}
                  createdAt={album.created_at}
                  favorite={album.favorite}
                  selected={selected}
                  hideCreatedInfo={isFixed}
                  hideFavorite={isFixed}
                  hideEdit={isFixed || selecting}
                  hideExpand={isFixed}
                  type="album"
                />
              );
            }}
          />
          {canLoadMoreAlbums() && (
            <div className="more-container">
              <Button
                key="more-albums"
                className="more-button"
                type={BUTTON_TYPES.LINK}
                label={getMoreAlbumsText()}
                onClick={loadMoreAlbums}
              />
            </div>
          )}
        </Grid>
      )}
      {showAlbums && (
        <Grid item>
          <ProjectPhotosTitle title={'Photos'} count={totalPhotos} />
        </Grid>
      )}
      {totalPhotos > 0 && (
        <Grid item>
          <DraggableGallery
            columns={columns}
            spacing={16}
            enableSelect
            enableDrag
            enableOrder={!!filters.albumId}
            onItemClick={onClickPhoto}
            onOrder={onOrderPhotos}
            items={currentPhotos}
            selected={selecting ? selectedPhotos : null}
            droppableKey={DROPPABLE_KEY}
            initialCount={(selectedAlbums || []).length}
            onSelectedChange={onSelectPhotos}
            renderItem={(photo, selected) => {
              return (
                <ProjectPhotosItem
                  type="photo"
                  id={photo.id}
                  isVideo={photo.is_video}
                  selected={selected}
                  favorite={photo.favorite}
                  createdBy={photo.created_by}
                  createdAt={photo.created_at}
                  hideEdit={selecting}
                  cover={photo.is_video ? photo.video : photo.image}
                />
              );
            }}
          />
          {canLoadMorePhotos() && (
            <div className="more-container">
              <Button
                key="more-photos"
                className="more-button"
                type={BUTTON_TYPES.LINK}
                label={'View More'}
                onClick={loadMorePhotos}
              />
            </div>
          )}
        </Grid>
      )}

      {totalPhotos === 0 && !showAlbums && (
        <Grid item>
          <Grid
            container
            height={300}
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            className="empty-message"
          >
            <Grid item>
              <img src={IconImage} alt="preview" />
            </Grid>
            <Grid item>
              <Typography color={'text.secondary'} variant="h6" paddingTop={3}>
                No Photos Added
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default connect((state) => ({
  currentProject: getCurrentProject(state),
  filters: getProjectPhotosFilters(state),
  currentPhotos: getProjectPhotosCurrentPhotos(state),
  totalPhotos: getProjectPhotosTotalPhotos(state),
  currentAlbums: getProjectPhotosCurrentAlbums(state),
  totalAlbums: getProjectPhotosTotalAlbums(state),
  selectedAlbums: getProjectPhotosSelectedAlbums(state),
  selectedPhotos: getProjectPhotosSelectedPhotos(state),
  selecting: getProjectPhotosSelecting(state),
  fixedAlbums: getProjectPhotosFixedAlbums(state),
}))(ProjectPhotosContent);
