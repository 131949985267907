import axios from 'axios';
// import numeral from 'numeral';

import BaseApi from './base-api';

class ProjectSiteAreaApi extends BaseApi {
  getProjectSites(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/project_sites`)
      .then((response) => ({ ok: true, sites: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  getProjectAreas(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/project_areas`)
      .then((response) => ({ ok: true, areas: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  addProjectSite(projectId, site) {
    let apiPayload = new FormData();
    apiPayload.append('project_site[name]', site.name);
    apiPayload.append('project_site[number]', site.number ? site.number : '');
    site.duplicateAreas.forEach((area_id) =>
      apiPayload.append('project_site[project_area_ids][]', area_id)
    );

    return axios
      .post(`${this.baseUrl}/projects/${projectId}/project_sites`, apiPayload)
      .then((response) => ({ ok: true, site: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  editProjectSite(projectId, site) {
    let apiPayload = new FormData();
    apiPayload.append('project_site[name]', site.name);
    apiPayload.append('project_site[number]', site.number ? site.number : '');

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/project_sites/${site.siteId}`,
        apiPayload
      )
      .then((response) => ({ ok: true, site: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  deleteProjectSite(projectId, site) {
    return axios
      .delete(
        `${this.baseUrl}/projects/${projectId}/project_sites/${site.siteId}`
      )
      .then((response) => ({ ok: true, site: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  addProjectArea(projectId, area) {
    let apiPayload = new FormData();
    apiPayload.append('project_area[name]', area.name);
    apiPayload.append('project_site_id', area.siteId);

    return axios
      .post(`${this.baseUrl}/projects/${projectId}/project_areas`, apiPayload)
      .then((response) => ({ ok: true, area: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  editProjectArea(projectId, area) {
    let apiPayload = new FormData();
    apiPayload.append('project_area[name]', area.name);

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/project_areas/${area.areaId}`,
        apiPayload
      )
      .then((response) => ({ ok: true, area: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  deleteProjectArea(projectId, area) {
    return axios
      .delete(
        `${this.baseUrl}/projects/${projectId}/project_areas/${area.areaId}`
      )
      .then((response) => ({ ok: true, area: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }
}

export const projectSiteAreaApi = new ProjectSiteAreaApi();
