import {
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_ADD,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_INVALID_CONFIRMATION,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_CANCEL,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_INVALID,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_SUCCESS,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_VALID,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_QUESTION_ANSWERED,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_VALID_CONFIRMATION,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_STATE_INIT,
  WORK_ORDERS_CREATION_UNION_TRADES_SELECT,
  WORK_ORDERS_CREATION_UNION_TRADES_VALID_CONFIRMATION,
  WORK_ORDERS_CREATION_UNION_TRADES_INVALID_CONFIRMATION,
} from '../../../../actions/admin/work-orders/action-types';

const initialState = {
  dontKnowUnions: false,
  noUnions: false,
  subContractorAnswer: null,
  showNewContractor: false,
  validationError: '',
  unionTradesValidationError: '',
};

export default function subContractorsUnionTradesUiReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case WORK_ORDERS_CREATION_SUB_CONTRACTORS_STATE_INIT:
      return {
        ...state,
        dontKnowUnions: action.payload.dontKnowUnions,
        noUnions: false,
        subContractorAnswer: action.payload.dontKnowSubContractor,
      };

    case WORK_ORDERS_CREATION_SUB_CONTRACTORS_ADD:
      return {
        ...state,
        showNewContractor: true,
        validationError: '',
      };

    case WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_CANCEL:
      return {
        ...state,
        showNewContractor: false,
        validationError: '',
      };

    case WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_VALID:
      return {
        ...state,
        validationError: '',
      };

    case WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_INVALID:
      return {
        ...state,
        validationError: action.payload.validationMessage,
      };

    case WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_SUCCESS:
      return {
        ...state,
        showNewContractor: false,
      };

    case WORK_ORDERS_CREATION_SUB_CONTRACTORS_QUESTION_ANSWERED:
      return {
        ...state,
        subContractorAnswer: action.payload.value,
        validationError: '',
      };

    case WORK_ORDERS_CREATION_SUB_CONTRACTORS_VALID_CONFIRMATION:
      return {
        ...state,
        validationError: '',
      };

    case WORK_ORDERS_CREATION_SUB_CONTRACTORS_INVALID_CONFIRMATION:
      return {
        ...state,
        validationError: action.payload,
      };

    case WORK_ORDERS_CREATION_UNION_TRADES_VALID_CONFIRMATION:
      return {
        ...state,
        unionTradesValidationError: '',
      };

    case WORK_ORDERS_CREATION_UNION_TRADES_INVALID_CONFIRMATION:
      return {
        ...state,
        unionTradesValidationError: action.payload,
      };

    case WORK_ORDERS_CREATION_UNION_TRADES_SELECT:
      return {
        ...state,
        dontKnowUnions: action.payload.dontKnowUnions,
        noUnions: action.payload.noUnions,
        unionTradesValidationError: '',
      };

    default:
      return state;
  }
}
