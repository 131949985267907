import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormModal from 'components/shared/modal/FormModal';
import Selector from 'components/shared/selector/Selector';
import { sameOrBeforeTodayValidation } from 'services/utils/date-util';

import {
  getShowLostOrCancelledModal,
  getMessage,
  getDate,
  getTerminationCategory,
} from 'components/admin/projects/details/project-proposal/store/selectors';

import {
  setShowCancelModal,
  setShowLostModal,
  setDate,
  setMessage,
  setTerminationCategory,
  initProjectLostCancelModal,
  updateProject,
} from 'components/admin/projects/details/project-proposal/store/actions';

import Button from 'components/shared/button/Button';
import TextArea from 'components/shared/input/TextArea';
import DatePicker from 'components/shared/date-picker/DatePicker';
import './ProjectCancelledOrLostModal.css';
import { simpleScrollToTop } from 'services/utils/scroller-util';

class ProjectCancelledOrLostModal extends Component {
  componentDidMount() {
    const initPayload = {
      date: this.props.terminationDate,
      message: this.props.terminationReason || this.props.message,
      terminationCategory: this.props.projectTerminationCategory,
    };
    this.props.dispatch(initProjectLostCancelModal(initPayload));
  }

  handleUpdateProject = () => {
    const projectId = this.props.projectId;
    this.props
      .dispatch(
        updateProject(projectId, {
          terminationDate: this.props.date,
          terminationCategory: this.props.terminationCategory,
          terminationReason: this.props.message,
          status: this.props.statusSelected,
        })
      )
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(setShowCancelModal(false));
          simpleScrollToTop();
        }
      });
  };

  handleClose = (event) => {
    event.preventDefault();
    this.props.dispatch(setShowCancelModal(false));
    this.props.dispatch(setShowLostModal(false));
  };

  handleMessageChange = (message) => {
    this.props.dispatch(setMessage(message));
  };

  handleDateChange = (date) => {
    this.props.dispatch(setDate(date));
  };

  handleTerminationCategoryChange = (value) => {
    this.props.dispatch(setTerminationCategory(value.value));
  };

  isSendButtonEnabled = (
    statusSelected,
    terminationCategory,
    date,
    message
  ) => {
    if (date !== null && date !== '' && message !== null && message !== '') {
      if (statusSelected === 'lost') {
        if (terminationCategory !== undefined && terminationCategory !== '') {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  };

  render() {
    const {
      statusSelected,
      showLostOrCancelledModal,
      message,
      date,
      terminationCategory,
    } = this.props;
    return (
      <FormModal
        className="project-cancelled-lost-modal"
        show={showLostOrCancelledModal}
        title=""
        onClose={this.handleClose}
      >
        <div className="project-cancelled-lost-container">
          <table>
            <colgroup>
              <col />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <td className="body-bold message">Date</td>
                <td>
                  <DatePicker
                    placeholder="Date"
                    value={date}
                    isValidDate={sameOrBeforeTodayValidation}
                    onChange={(value) => this.handleDateChange(value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="body-bold message">
                  {statusSelected === 'cancelled'
                    ? 'Reason for Cancellation'
                    : 'Reason for Loss'}
                </td>
                <td>
                  {statusSelected === 'lost' && (
                    <Selector
                      options={[
                        {
                          value: 'Pricing',
                          label: 'Pricing',
                        },
                        {
                          value: 'Schedule',
                          label: 'Schedule',
                        },
                        {
                          value: 'Using prefered contractor',
                          label: 'Using prefered contractor',
                        },
                        {
                          value: 'Other',
                          label: 'Other',
                        },
                      ]}
                      placeholder="Select Option"
                      onChange={(value) =>
                        this.handleTerminationCategoryChange(value)
                      }
                      value={terminationCategory}
                      className={`dropdown-${statusSelected}`}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <TextArea
                    placeholder="Reason"
                    value={message}
                    onChange={(value) => this.handleMessageChange(value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="button-container">
            <Button
              className="button-orange"
              disabled={
                !this.isSendButtonEnabled(
                  statusSelected,
                  terminationCategory,
                  date,
                  message
                )
              }
              label="Submit"
              onClick={() => this.handleUpdateProject()}
            />
          </div>
        </div>
      </FormModal>
    );
  }
}

export default connect((state) => {
  return {
    showLostOrCancelledModal: getShowLostOrCancelledModal(state),
    message: getMessage(state),
    date: getDate(state),
    terminationCategory: getTerminationCategory(state),
  };
})(ProjectCancelledOrLostModal);
