import { CHAT_ACTIVITY_LOAD } from 'components/admin/chats/store/action-types';

const initialState = {
  chatActivity: null,
};

export default function chatsDataReducer(state = initialState, action) {
  switch (action.type) {
    case CHAT_ACTIVITY_LOAD:
      return {
        ...state,
        chatActivity: action.payload,
      };

    default:
      return state;
  }
}
