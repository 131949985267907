import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './ProjectRfiLog.css';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import NavigationBar from 'components/shared/navigation/NavigationBar';
import PdfViewer from 'components/shared/pdf-viewer/PdfViewer';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getNotifications } from 'selectors/notifications';
import { unreadNotifications } from 'domain/notification';
import { markNotifiableNotificationsAsRead } from 'actions/notifications';
import {
  initRfiLog,
  loadRfisRequest,
  setRfiTabSelected,
  setRfiModalShow,
  openRfi,
  setRfiLogPdfViewer,
  loadRfiLogPdfRequest,
} from 'components/admin/projects/details/project-rfi-log/store/actions';
import {
  getRfis,
  getTotal,
  getCurrentPage,
  getCurrentStatus,
  getDueDateFilter,
  getPageSize,
  getRfiTabSelected,
  getCurrentRfi,
  getRfiLogPdfViewer,
  getRfiLogPdf,
} from 'components/admin/projects/details/project-rfi-log/store/selectors';
import RfiLogTable from 'components/admin/projects/details/project-rfi-log/RfiLogTable';
import RfiModalContainer from 'components/admin/projects/details/project-rfi-log/RfiModalContainer';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import RfiPermissions from 'permissions/rfi-permissions';
import { openRfiResponseModalWithData } from './store/actions';

class ProjectRfiLog extends Component {
  constructor(props) {
    super(props);

    props.dispatch(initRfiLog());
  }

  componentDidMount() {
    const { currentProject, dispatch, match, history } = this.props;
    const { subsection, itemId } = match.params;
    const tabValue = this.getTabValueFromSubsection(subsection);

    dispatch(setRfiTabSelected(tabValue));
    if (!subsection) {
      history.replace(`/admin/projects/${currentProject.id}/rfis/rfi`);
    }

    if (currentProject) {
      this.handlePageChange(1, this.defaultStatus());
      this.loadDirectory();
    }

    if (itemId) {
      this.handleOpenRfi(itemId, true);
    }
  }

  componentDidUpdate(prevProps) {
    const prevSubsection = prevProps.match.params.subsection;
    const currentSubsection = this.props.match.params.subsection;
    const prevItemId = prevProps.match.params.itemId;
    const currentItemId = this.props.match.params.itemId;

    if (prevSubsection !== currentSubsection) {
      const tabValue = this.getTabValueFromSubsection(currentSubsection);
      this.props.dispatch(setRfiTabSelected(tabValue));
      this.handlePageChange(1, this.defaultStatus(tabValue));
    }

    if (prevItemId !== currentItemId) {
      if (currentItemId) {
        // Open the Rfi modal if it's not already open and the url has an itemId
        if (!this.props.showRfiModal) {
          this.handleOpenRfi(currentItemId, true);
        }
      }
    }
  }

  getTabValueFromSubsection = (subsection) => {
    switch (subsection) {
      case 'p-rfi':
        return 1;
      case 'rfi':
        return 2;
      default:
        return 2;
    }
  };

  getSubsectionFromTabValue = (tabValue) => {
    switch (tabValue) {
      case 1:
        return 'p-rfi';
      case 2:
        return 'rfi';
      default:
        return 'rfi';
    }
  };

  loadDirectory = () => {
    const { currentProject, directory, dispatch } = this.props;
    if (!directory || directory.project_id !== currentProject.id) {
      dispatch(loadDirectoryRequest(currentProject.id));
    }
  };

  handleRfiTabSelection = (rfiTab) => {
    const { history, match, dispatch } = this.props;
    const projectId = match.params.projectId;
    const subsection = this.getSubsectionFromTabValue(rfiTab);

    history.push(`/admin/projects/${projectId}/rfis/${subsection}`);

    dispatch(setRfiTabSelected(rfiTab));
    this.handlePageChange(1, this.defaultStatus(rfiTab));
  };

  handlePageChange = (page, status, dueDateFilter) => {
    const { currentProject, dispatch } = this.props;
    dispatch(loadRfisRequest(currentProject.id, page, status, dueDateFilter));
  };

  showPreRfi = () => {
    const { currentUser, currentProject } = this.props;

    return (
      RfiPermissions.canViewPending(currentUser, currentProject) &&
      RfiPermissions.canViewSubmitted(currentUser, currentProject)
    );
  };

  defaultStatus = (rfiTab = 2) => {
    const showPreRfi = !!this.showPreRfi();
    return (showPreRfi && (rfiTab === 1 ? 'pending' : 'not_pre')) || 'all';
  };

  handleOpenRfiModal = () => {
    this.props.dispatch(setRfiModalShow(true));
  };

  handleOpenRfi = (id, readOnly = false) => {
    const { currentProject, dispatch, history, match } = this.props;
    const { projectId, subsection } = match.params;

    history.push(`/admin/projects/${projectId}/rfis/${subsection}/${id}`);

    dispatch(openRfi(currentProject.id, id, readOnly));
    dispatch(markNotifiableNotificationsAsRead(id, 'Rfi'));
  };
  handleOpenResponseModal = (id, readOnly = false) => {
    const { currentProject, dispatch } = this.props;
    dispatch(openRfiResponseModalWithData(currentProject.id, id, readOnly));
    dispatch(markNotifiableNotificationsAsRead(id, 'Rfi'));
  };
  handleViewRfiLogPdf = () => {
    const { currentProject, dispatch, rfiTabSelected } = this.props;
    dispatch(
      loadRfiLogPdfRequest(
        currentProject.id,
        this.defaultStatus(rfiTabSelected)
      )
    ).then((response) => {
      if (response.ok) {
        dispatch(setRfiLogPdfViewer(true));
      }
    });
  };

  handleRfiLogPdfViewerClose = () => {
    this.props.dispatch(setRfiLogPdfViewer(false));
  };

  renderHeaderButtons() {
    const { currentUser, currentProject } = this.props;

    return [
      RfiPermissions.canCreate(currentUser, currentProject) && (
        <Button
          key="add-submittal"
          type={BUTTON_TYPES.LINK}
          icon={BUTTON_ICONS.PLUS}
          label="Add RFI"
          onClick={this.handleOpenRfiModal}
        />
      ),
      <Button
        key="view-log"
        type={BUTTON_TYPES.LINK}
        color={BUTTON_COLORS.GREEN}
        label="View Log"
        onClick={this.handleViewRfiLogPdf}
      />,
    ].filter((component) => component);
  }

  render() {
    const contribType =
      this.props.currentProject && this.props.currentProject.contributor_type;
    const unreadById = unreadNotifications(this.props.notifications, 'Rfi');
    const {
      currentUser,
      currentProject,
      directory,
      rfis,
      total,
      currentPage,
      currentStatus,
      pageSize,
      rfiTabSelected,
      rfiLogPdfViewer,
      rfiLogPdf,
      dueDateFilter,
      renderHeaders,
    } = this.props;
    return (
      currentProject &&
      directory && (
        <div className="content-container rfi-log">
          {renderHeaders(this.renderHeaderButtons())}
          <div className="rfi-log-header">
            <NavigationBar
              items={[
                {
                  id: 1,
                  title: 'P-RFI Log',
                  show: this.showPreRfi(),
                },
                {
                  id: 2,
                  title: 'RFI Log',
                },
              ]}
              selectedValue={rfiTabSelected}
              onSelected={this.handleRfiTabSelection}
            />
          </div>

          {/*Navigation bar items content*/}
          <RfiLogTable
            rfis={rfis}
            total={total}
            currentPage={currentPage}
            currentStatus={currentStatus}
            dueDateFilter={dueDateFilter}
            pageSize={pageSize}
            currentUser={currentUser}
            currentProject={currentProject}
            directory={directory}
            contribType={contribType}
            unreadById={unreadById}
            rfiTabSelected={rfiTabSelected}
            handlePageChange={this.handlePageChange}
            handleOpenRfi={this.handleOpenRfi}
            handleOpenRfiResponseModal={this.handleOpenResponseModal}
          />

          {rfiLogPdfViewer && (
            <PdfViewer
              content={{
                source: rfiLogPdf,
                type: 'blob',
              }}
              show={rfiLogPdfViewer}
              onClose={this.handleRfiLogPdfViewerClose}
            />
          )}

          <RfiModalContainer />
        </div>
      )
    );
  }
}

export default withRouter(
  connect((state) => {
    return {
      currentUser: getCurrentUser(state),
      currentProject: getCurrentProject(state),
      directory: getDirectory(state),
      rfis: getRfis(state),
      rfiTabSelected: getRfiTabSelected(state),
      currentRfi: getCurrentRfi(state),
      total: getTotal(state),
      currentPage: getCurrentPage(state),
      currentStatus: getCurrentStatus(state),
      dueDateFilter: getDueDateFilter(state),
      pageSize: getPageSize(state),
      notifications: getNotifications(state),
      rfiLogPdfViewer: getRfiLogPdfViewer(state),
      rfiLogPdf: getRfiLogPdf(state),
    };
  })(ProjectRfiLog)
);
