import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';

class VendorInvoicesApi extends BaseApi {
  deleteVendorInvoice(vendorInvoiceId) {
    return axios
      .delete(`${this.baseUrl}/vendor_invoices/${vendorInvoiceId}`)
      .then(() => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getVendorInvoices(page = 1, workOrderJobNumber = null, searchFilters = null) {
    let filter = '';
    if (workOrderJobNumber !== null && workOrderJobNumber !== '') {
      filter += `&by_job_number=${workOrderJobNumber}`;
    }

    if (searchFilters) {
      Object.keys(searchFilters).forEach(function (attr) {
        filter += `&${attr}=${searchFilters[attr]}`;
      });
    }

    return axios
      .get(`${this.baseUrl}/vendor_invoices?page=${page}${filter}`)
      .then((response) => {
        return {
          ok: true,
          invoices: {
            content: response.data,
            pageSize: numeral(response.headers['per-page']).value(),
            total: numeral(response.headers['total']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  markAsPaid(invoiceId) {
    return axios
      .put(`${this.baseUrl}/vendor_invoices/${invoiceId}/paid`)
      .then((response) => {
        return {
          ok: true,
          invoice: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const vendorInvoicesApi = new VendorInvoicesApi();
