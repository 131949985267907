import React from 'react';
import { connect } from 'react-redux';
import FormControlBlock, {
  CONTROL_SIZE,
} from 'components/shared/form/FormControlBlock';
import Selector from 'components/shared/selector/Selector';
import Input from 'components/shared/input/Input';
import { getRoles, getForm } from './store/selectors';
import { updateForm } from './store/actions';
import {
  COMPANY_TITLES,
  companyTitleOptions,
  companyRoleOptions,
} from 'domain/user-role-enum';
import RadioButton from 'components/shared/radio-button/RadioButton';
import TooltipButton from 'components/shared/tooltip/TooltipButton';

function PrimaryContact(props) {
  const {
    available_roles,
    form: {
      primary_contact: {
        same_user,
        email,
        first_name,
        last_name,
        phone_number,
        title,
        role_id,
      },
    },
    dispatch,
  } = props;

  const handleFormChange = (field, value) => {
    dispatch(
      updateForm('primary_contact', {
        ...props.form.primary_contact,
        [field]: value,
      })
    );
  };

  return (
    <React.Fragment>
      <div className="sidebar-form-body-subtitle">
        Primary Contact
        <TooltipButton
          tooltipId="primary-contact-tooltip"
          tooltip={
            <span>
              A Primary Contact is typically an individual who is a Company
              Owner, Executive, Administrator, or a similar decision making
              role. Select <b>No</b>, to add a name other than your own.
            </span>
          }
          tooltipSize="big"
        />
        <RadioButton
          options={[
            {
              value: 1,
              label: 'Yes',
            },
            {
              value: 0,
              label: 'No',
            },
          ]}
          value={same_user ? 1 : 0}
          orientation="horizontal-row"
          onChange={(value) => handleFormChange('same_user', value === 1)}
        />
      </div>

      {!same_user && (
        <React.Fragment>
          <div className="form-block">
            <FormControlBlock
              label={!!first_name && 'First Name'}
              size={CONTROL_SIZE.SMALL}
              control={
                <Input
                  placeholder="First Name"
                  value={first_name}
                  onChange={(value) => handleFormChange('first_name', value)}
                />
              }
            />
            <FormControlBlock
              label={!!last_name && 'Last Name'}
              size={CONTROL_SIZE.SMALL}
              control={
                <Input
                  placeholder="Last Name"
                  value={last_name}
                  onChange={(value) => handleFormChange('last_name', value)}
                />
              }
            />
          </div>

          <div className="form-block">
            <FormControlBlock
              label={!!phone_number && 'Phone Number'}
              size={CONTROL_SIZE.SMALL}
              control={
                <Input
                  placeholder="Phone Number"
                  value={phone_number}
                  onChange={(value) => handleFormChange('phone_number', value)}
                />
              }
            />
            <FormControlBlock
              label={!!email && 'Email'}
              size={CONTROL_SIZE.SMALL}
              control={
                <Input
                  placeholder="Email"
                  value={email}
                  onChange={(value) => handleFormChange('email', value)}
                />
              }
            />
          </div>

          <div className="form-block">
            <FormControlBlock
              label={!!role_id && 'Job Title'}
              size={CONTROL_SIZE.SMALL}
              control={
                <Selector
                  placeholder="Job Title"
                  value={role_id}
                  onChange={(option) =>
                    handleFormChange('role_id', option.value)
                  }
                  options={companyRoleOptions(available_roles)}
                />
              }
            />
            <FormControlBlock
              label={!!title && 'Job Title'}
              size={CONTROL_SIZE.SMALL}
              control={
                <Selector
                  placeholder={
                    title && !COMPANY_TITLES.includes(title)
                      ? 'Custom'
                      : 'Job Title'
                  }
                  value={title}
                  onChange={(option) => handleFormChange('title', option.label)}
                  options={companyTitleOptions}
                />
              }
            />
            <FormControlBlock
              label={!!title && 'Or Type in'}
              size={CONTROL_SIZE.SMALL}
              control={
                <Input
                  placeholder="Or Type in"
                  value={title}
                  onChange={(value) => handleFormChange('title', value)}
                />
              }
            />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default connect((state) => {
  return {
    available_roles: getRoles(state),
    form: getForm(state),
  };
})(PrimaryContact);
