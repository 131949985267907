import { createSelector } from 'reselect';

export const getProjectNewMeetingsFilters = createSelector(
  (state) => state.projects.scenes.details.scenes.newMeetings.ui.filters,
  (filters) => filters
);

export const getMeetingsInstancesFilters = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui.instancesFilters,
  (instancesFilters) => instancesFilters
);

export const getProjectNewMeetingsParents = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.data.meetingsParents,
  (meetingsParents) => meetingsParents
);

export const getProjectNewMeetingsInstances = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.data.meetingsInstances,
  (meetingsInstances) => meetingsInstances
);

export const getProjectNewMeetingsFormModal = createSelector(
  (state) => state.projects.scenes.details.scenes.newMeetings.ui.showFormModal,
  (show) => show
);

export const getProjectNewMeetingsInfoModal = createSelector(
  (state) => state.projects.scenes.details.scenes.newMeetings.ui.showInfoModal,
  (show) => show
);

export const getMeetingsInstanceModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui.showInstanceForm,
  (showInstanceForm) => showInstanceForm
);

export const getMeetingsInstanceModalTab = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui.instanceFormTab,
  (instanceFormTab) => instanceFormTab
);

export const getProjectMeetingsParentsInfoTab = createSelector(
  (state) => state.projects.scenes.details.scenes.newMeetings.ui.infoTab,
  (show) => show
);

export const getProjectNewMeetingsAddTypeModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui.showAddTypeModal,
  (showAddTypeModal) => showAddTypeModal
);
export const getDeleteTypeOrTitleErrorModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui
      .showDeleteTypeOrTitleErrorModal,
  (showDeleteTypeOrTitleErrorModal) => showDeleteTypeOrTitleErrorModal
);

export const getProjectNewMeetingsAddTitleModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui.showAddTitleModal,
  (showAddTitleModal) => showAddTitleModal
);

export const getProjectNewMeetingsEditTypeModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui.showEditTypeModal,
  (showEditTypeModal) => showEditTypeModal
);

export const getProjectNewMeetingsEditTitleModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui.showEditTitleModal,
  (showEditTitleModal) => showEditTitleModal
);

export const getProjectMeetingsParentsFormTab = createSelector(
  (state) => state.projects.scenes.details.scenes.newMeetings.ui.formTab,
  (tab) => tab
);

export const getProjectMeetingsParentsTabNavigation = createSelector(
  (state) => state.projects.scenes.details.scenes.newMeetings.ui.tab,
  (tab) => tab
);

export const getProjectNewMeetingsForm = createSelector(
  (state) => state.projects.scenes.details.scenes.newMeetings.data.form,
  (form) => form
);

export const getMeetingsInstanceForm = createSelector(
  (state) => state.projects.scenes.details.scenes.newMeetings.data.instanceForm,
  (instanceForm) => instanceForm
);

export const getProjectNewMeetingsModalMessage = createSelector(
  (state) => state.projects.scenes.details.scenes.newMeetings.ui.message,
  (message) => message
);

export const getProjectNewMeetingsModalMessageType = createSelector(
  (state) => state.projects.scenes.details.scenes.newMeetings.ui.messageType,
  (type) => type
);

export const getTotalNewMeetings = createSelector(
  (state) => state.projects.scenes.details.scenes.newMeetings.data.total,
  (total) => total
);

export const getMeetingsTitles = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.data.meetingsTitles,
  (meetingsTitles) => meetingsTitles
);

export const getMeetingsTypes = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.data.meetingsTypes,
  (meetingsTypes) => meetingsTypes
);

export const getMeetingsParentsTitles = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.data.meetingsParentsTitles,
  (meetingsParentsTitles) => meetingsParentsTitles
);

export const getMeetingsParentsTypes = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.data.meetingsParentsTypes,
  (meetingsParentsTypes) => meetingsParentsTypes
);
export const getActionItemsCategories = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.data.actionItemsCategories,
  (actionItemsCategories) => actionItemsCategories
);
export const getAddNextMeetingModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui.showAddNewMeetingModal,
  (showAddNewMeetingModal) => showAddNewMeetingModal
);

export const getEditNextMeetingModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui
      .showEditNextMeetingModal,
  (showEditNextMeetingModal) => showEditNextMeetingModal
);

export const getAddCategoryModalShow = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui.showAddCategoryModal,
  (showAddCategoryModal) => showAddCategoryModal
);

export const getAddGuestModalShow = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui.showAddGuestModal,
  (showAddGuestModal) => showAddGuestModal
);
export const getActionItemsResolvedModalShow = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui
      .showActionItemsResolvedModal,
  (showActionItemsResolvedModal) => showActionItemsResolvedModal
);
export const getActionItems = createSelector(
  (state) => state.projects.scenes.details.scenes.newMeetings.data.actionItems,
  (actionItems) => actionItems
);
export const getActionItemHistoryModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui
      .showActionItemHistoryModal,
  (showActionItemHistoryModal) => showActionItemHistoryModal
);
export const getActionItemHistory = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui.actionItemHistory,
  (actionItemHistory) => actionItemHistory
);

export const getActionItemHistoryFilters = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui
      .actionItemHistoryFilters,
  (actionItemHistoryFilters) => actionItemHistoryFilters
);

export const getActionItemsResolvedFilters = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui
      .actionItemResolvedFilters,
  (actionItemResolvedFilters) => actionItemResolvedFilters
);

export const getNotepadShow = createSelector(
  (state) => state.projects.scenes.details.scenes.newMeetings.ui.showNotepad,
  (showNotepad) => showNotepad
);

export const getSubmitCorrectionsModalShow = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui
      .showSubmitCorrectionsModal,
  (showSubmitCorrectionsModal) => showSubmitCorrectionsModal
);

export const getEditCorrectionsModalShow = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui
      .showEditCorrectionsModal,
  (showEditCorrectionsModal) => showEditCorrectionsModal
);

export const getViewCorrectionsModalShow = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui
      .showViewCorrectionsModal,
  (showViewCorrectionsModal) => showViewCorrectionsModal
);

export const getReviewCorrectionsModalShow = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui
      .showReviewCorrectionsModal,
  (showReviewCorrectionsModal) => showReviewCorrectionsModal
);

export const getPendingCorrectionsErrorModalShow = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui
      .showPendingCorrectionsErrorModal,
  (showPendingCorrectionsErrorModal) => showPendingCorrectionsErrorModal
);
export const getActionItemsErrors = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui.actionItemsErrors,
  (actionItemsErrors) => actionItemsErrors
);
export const getInstanceInfoErrors = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui.instanceInfoErrors,
  (instanceInfoErrors) => instanceInfoErrors
);

export const getInstancesPdfViewerShow = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui
      .showMeetingsInstancePdfModal,
  (showMeetingsInstancePdfModal) => showMeetingsInstancePdfModal
);

export const getMeetingsInstanceIsBackup = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui
      .meetingsInstanceIsBackup,
  (meetingsInstanceIsBackup) => meetingsInstanceIsBackup
);
export const getConfirmCloseModalShow = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui.showConfirmCloseModal,
  (showConfirmCloseModal) => showConfirmCloseModal
);
export const getPreviewModalShow = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui.showPreviewModal,
  (showPreviewModal) => showPreviewModal
);
export const getMeetingsInstanceHtml = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.data.meetingsInstanceHtml,
  (meetingsInstanceHtml) => meetingsInstanceHtml
);

export const getInstancesHtmlViewerShow = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.newMeetings.ui
      .showMeetingsInstanceHtmlViewer,
  (showMeetingsInstanceHtmlViewer) => showMeetingsInstanceHtmlViewer
);
