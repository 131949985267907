import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SingleImageDescription from './SingleImageDescription';
import Dropzone from 'react-dropzone';
import { getDarkMode } from 'selectors/theme';
import uploadSvg from 'components/shared/document-upload/upload.svg';
import uploadSvgDark from 'components/shared/document-upload/uploadDarkMode.svg';

import './ImageDescriptionUpload.css';
import { imageRotation } from '../../../services/utils/images-util';
import FullScreenImageViewer from './FullScreenImageViewer';
import convertHeicFile from '../../../services/utils/heic-converter';

const ImageDescriptionUpload = (props) => {
  const {
    images,
    deletedImages,
    onChange,
    onDelete,
    darkMode,
    multiple,
    hideDescription,
    dropZoneText,
  } = props;
  // Add full screen viewer and rotation
  const [fullScreenUrl, setFullScreenUrl] = useState('');
  const [isRotating, setIsRotating] = useState(false);

  const handleDelete = (i) => {
    if (typeof onDelete === 'function') {
      const result_images = [...images].filter((_, index) => index !== i);
      const delete_images = [...deletedImages, images[i]];
      onDelete(result_images, delete_images);
    }
  };

  const handleChange = (image, i) => {
    if (typeof onChange === 'function') {
      const result_images = [...images];
      result_images[i] = image;
      onChange(result_images);
    }
  };

  const handleDropImage = async (files) => {
    if (typeof onChange === 'function') {
      const newFilesPromises = files.map(async (file) => {
        const convertedFile = await convertHeicFile(file);
        return {
          file: convertedFile,
          url: URL.createObjectURL(convertedFile),
        };
      });

      const newFiles = await Promise.all(newFilesPromises);

      onChange([...images, ...newFiles]);
    }
  };

  const handleRotateImage = async (degrees) => {
    if (isRotating) return;
    setIsRotating(true);
    try {
      const rotationResult = await imageRotation(
        images,
        fullScreenUrl,
        degrees,
        () => {},
        () => {},
        onChange
      );
      if (rotationResult) setFullScreenUrl(rotationResult.rotatedImageUrl);
    } catch (error) {
      console.error('Rotation image is invalid');
    } finally {
      setIsRotating(false);
    }
  };
  const handleFullScreenClose = () => {
    setFullScreenUrl('');
  };
  const handleFullScreenOpen = (url) => {
    setFullScreenUrl(url);
  };

  return (
    <div className="image-description-upload">
      {images.map((image, i) => (
        <div onClick={() => handleFullScreenOpen(image.url)}>
          <SingleImageDescription
            value={image}
            key={`image_upload_${i}`}
            onDelete={() => handleDelete(i)}
            onChange={(image) => handleChange(image, i)}
            hideDescription={hideDescription}
          />
        </div>
      ))}
      {(multiple || images.length === 0) && (
        <Dropzone
          onDrop={handleDropImage}
          className="drop-zone"
          accept={'image/*'}
          multiple={multiple}
        >
          <img src={darkMode ? uploadSvgDark : uploadSvg} alt="upload" />
          <span>{dropZoneText}</span>
        </Dropzone>
      )}
      <FullScreenImageViewer
        show={!!fullScreenUrl}
        url={fullScreenUrl}
        onClose={handleFullScreenClose}
        onImageRotate={handleRotateImage}
        readOnly={false}
        isRotating={isRotating}
      />
    </div>
  );
};

ImageDescriptionUpload.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      preview: PropTypes.string,
      file: PropTypes.object,
      description: PropTypes.string,
    })
  ),
  deletedImages: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      preview: PropTypes.string,
      file: PropTypes.object,
      description: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  multiple: PropTypes.bool,
  hideDescription: PropTypes.bool,
  dropZoneText: PropTypes.string,
};

ImageDescriptionUpload.defaultProps = {
  images: [],
  deletedImages: [],
  onChange: undefined,
  onDelete: undefined,
  multiple: true,
  hideDescription: false,
  dropZoneText: 'Drag Photos Here',
};

export default connect((state) => ({ darkMode: getDarkMode(state) }))(
  ImageDescriptionUpload
);
