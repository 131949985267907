import moment from 'moment';
import { weatherWindOptions } from 'domain/weather';
import css from './FieldReportHtmlStyle';

const renderWeatherWindOption = (direction) => {
  const abbreviation = weatherWindOptions.filter(
    (opt) => opt.value === direction
  );
  if (abbreviation && abbreviation.length > 0) return abbreviation[0].label;
  return null;
};

const renderTime = (t) => {
  if (!t) return null;
  if (t.includes('T')) {
    const momentTime = moment(t).format('hh:mm A');
    return momentTime;
  }
  const momentTime = moment(t, 'YYYY-MM-DD hh:mm A').format('hh:mm A');
  return momentTime;
};

const html = (form, project, currentUser) => `
<!DOCTYPE html>
 <html lang="en">
 <head>
 ${css}
   <meta charset="UTF-8">
   <title>Project Report</title>
   <link rel="stylesheet" href="project_report_pdf.css"> 
   <script>
     function number_pages() {
       var vars = {};
       var x = document.location.search.substring(1).split('&');
       for (var i in x) {
         var z = x[i].split('=', 2);
         vars[z[0]] = unescape(z[1]);
       }
       var x = ['topage', 'page'];
       for (var i in x) {
         var y = document.getElementsByClassName(x[i]);
         for (var j = 0; j < y.length; ++j) y[j].textContent = vars[x[i]];
       }
     }
   </script>
 </head>
 <body onload="number_pages()">
   <div class="project-report-header">
      <div class="first">
        <h2>${project.number} ${project.name}</h2>
        <h3>${project.project_location.location.street_address_1}, ${
  project.project_location.location.street_address_2
}, ${project.project_location.location.city}, ${
  project.project_location.location.state
} ${project.project_location.location.zip}</h3>
      </div>
      <div class="second">
        <h2> Field Report <span> #${form.formatted_number || '--'}</span> </h2>
        
      </div>
   </div>
   <div class="project-report-title">
     <div class="flexrow">
       <div class="col no-height">
         <p class="title">${project.creator_company.company_name}</p>
         <div class="flexrow justify start">
           <div class="col no-height no-padding">
             <div class="detail-item">
               <div class="label">Prepared By</div>
               <div class="value">
                 <span>${(currentUser && currentUser.label) || '--'}</span>
               </div>
             </div>
           </div>
           <div class="col col-20 no-height no-padding margin-top">
             <div class="detail-item">
               <div class="label">Report Date</div>
               <div class="value">
                 <span>${form.date || '--'}</span>
               </div>
             </div>
           </div>
         </div>
         <div class="flexrow justify start">
         <div class="col no-height no-padding">
           <div class="detail-item">
             <div class="label">Report Title</div>
             <div class="value">
               <span>${form.title}</span>
             </div>
           </div>
         </div>
         <div class="col col-20 no-height no-padding margin-top">
           <div class="detail-item">
             <div class="label">Observation Time</div>
             <div class="value">
               <span>${
                 form.arrival_time ? renderTime(form.arrival_time) : '--'
               } - ${
  form.departure_time ? renderTime(form.departure_time) : '--'
}</span>
             </div>
           </div>
         </div>
       </div>
       </div>
     </div>
   </div>
 
   <div class="weather-report avoid-break">
     <div class="header">Weather Conditions</div>
     <div class="content flexrow">
       <div class="col">
         <div class="flexrow justify">
           <div class="detail-item flexrow small">
             <div class="label">Temperature</div>
             <div class="value">${
               form.weather_temperature ? form.weather_temperature + 'F' : '--'
             }</div>
           </div>
           <div class="detail-item flexrow small">
             <div class="label">Condition</div>
             <div class="value">${form.weather_condition || '--'}</div>
           </div>
           <div class="detail-item flexrow small">
             <div class="label">Humidity</div>
             <div class="value">${
               form.weather_humidity ? form.weather_humidity + '%' : '--'
             }</div>
           </div>
           <div class="detail-item flexrow small">
             <div class="label">Wind</div>
             <div class="value">${form.weather_wind || '--'} ${
  form.weather_wind_direction
    ? renderWeatherWindOption(form.weather_wind_direction)
    : '--'
} ${form.weather_wind && form.weather_wind_direction ? 'mph' : ''}
             </div>
           </div>
           <div class="detail-item flexrow small">
             <div class="label">Precipitation</div>
             <div class="value">${
               (form.weather_precipitation &&
                 form.weather_precipitation.label) ||
               '--'
             } </div>
           </div>
         </div>
         <div class="content flexrow">
          <p class="weather-report-remarks">${form.weather_remark || ''}</p>
        </div>
       </div>
     </div>
   </div>
 
   <div class="weather-report avoid-break">
     <div class="header">Report Summary</div>
     <p class="detail-remarks">${form.description || '--'}</p>
     </div>
   </div>
 <div class="observation-report">
 ${
   form.trade && form.trade.label
     ? `<div class="header">${
         form.trade && form.trade.label ? form.trade.label : '--'
       }</div>`
     : ''
 }
  <div class="header">Project Status</div>
  <div class="subheader>${
    form.location && form.location.label ? form.location.label : '--'
  } - ${form.area && form.area.label ? form.area.label : '--'}</div>
  <div class="content">
      ${
        form.observation_trade_count
          ? `
        <div class="detail-item inline">
          <div class="label">Trade Count</div>
          <div class="value">${form.observation_trade_count}</div>
        </div>`
          : ''
      }
      ${
        form.observation_task
          ? `
        <div class="detail-item inline">
          <div class="label">Task</div>
          <div class="value">${form.observation_task}</div>
        </div>`
          : ''
      }
      ${
        form.observation_task_remarks
          ? `
        <div class="detail-item inline">
          <div class="label">Task Remarks</div>
          <div class="value">${form.observation_task_remarks}</div>
        </div>`
          : ''
      }
      ${
        form.observation_trade_count ||
        form.observation_task ||
        form.observation_task_remarks
          ? '<div class="space"></div>'
          : ''
      }
    <span>
      ${
        form.observation_contract_complete
          ? `
        <div class="detail-item inline">
          <div class="label">Contract Complete</div>
          <div class="value">${form.observation_contract_complete}%</div>
        </div>`
          : ''
      }
      ${
        form.formatted_observation_schedule_status
          ? `
        <div class="detail-item inline">
          <div class="label">Schedule Status</div>
          <div class="value">${form.formatted_observation_schedule_status}</div>
        </div>`
          : ''
      }
      ${
        form.observation_schedule_remarks
          ? `
        <div class="detail-item inline">
          <div class="label">Schedule Remarks</div>
          <div class="value">${form.observation_schedule_remarks}</div>
        </div>`
          : ''
      }
      ${
        form.observation_contract_complete ||
        !form.observation_schedule_none ||
        form.observation_schedule_remarks
          ? '<div class="space"></div>'
          : ''
      }
    </span>
    ${
      form.formatted_observation_design_compliance
        ? `
      <div class="detail-item inline">
        <div class="label">Design Compliance</div>
        <div class="value">${form.formatted_observation_design_compliance}</div>
      </div>`
        : ''
    }
    ${
      form.observation_design_remarks
        ? `
      <div class="detail-item inline">
        <div class="label">Design Remarks</div>
        <div class="value">${form.observation_design_remarks}</div>
      </div>`
        : ''
    }
    ${
      !form.observation_design_none || form.observation_design_remarks
        ? '<div class="space"></div>'
        : ''
    }
    ${
      form.formatted_observation_quality_compliance
        ? `
      <div class="detail-item inline">
        <div class="label">Quality Compliance</div>
        <div class="value">${form.formatted_observation_quality_compliance}</div>
      </div>`
        : ''
    }
    ${
      form.observation_quality_remarks
        ? `
      <div class="detail-item inline">
        <div class="label">Quality Remarks</div>
        <div class="value">${form.observation_quality_remarks}</div>
      </div>`
        : ''
    }
    ${
      !form.observation_quality_none || form.observation_quality_remarks
        ? '<div class="space"></div>'
        : ''
    }
    ${
      form.formatted_observation_safety_compliance
        ? `
      <div class="detail-item inline">
        <div class="label">Safety Compliance</div>
        <div class="value">${form.formatted_observation_safety_compliance}</div>
      </div>`
        : ''
    }
    ${
      form.observation_safety_remarks
        ? `
      <div class="detail-item inline">
        <div class="label">Safety Remarks</div>
        <div class="value">${form.observation_safety_remarks}</div>
      </div>`
        : ''
    }
    ${
      !form.observation_safety_none || form.observation_safety_remarks
        ? '<div class="space"></div>'
        : ''
    }
    ${
      form.observation_material_remarks
        ? `
      <div class="detail-item inline">
        <div class="label">Material & Delivery Remarks</div>
        <div class="value">${form.observation_material_remarks}</div>
      </div>`
        : ''
    }
    ${
      form.observation_equipment_remarks
        ? `
      <div class="detail-item inline">
        <div class="label">Equipment Remarks</div>
        <div class="value">${form.observation_equipment_remarks}</div>
      </div>`
        : ''
    }
    ${
      form.observation_testing_remarks
        ? `
      <div class="detail-item inline">
        <div class="label">Testing & Inspection Remarks</div>
        <div class="value">${form.observation_testing_remarks}</div>
      </div>`
        : ''
    }
    ${
      form.observation_other_remarks
        ? `
      <div class="detail-item inline">
        <div class="label">Others Remarks</div>
        <div class="value">${form.observation_other_remarks}</div>
      </div>`
        : ''
    }
  </div>
</div>
${
  form.open_items && form.open_items.length > 0
    ? `
  <div class="project-open-items avoid-break">
    <div class="sub-header">Open Items</div>
    <table>
      <thead>
        <tr>
          <th>Item No</th>
          <th>Description</th>
          <th>Assigned To</th>
          <th>Due Date</th>
          <th>Critical</th>
        </tr>
      </thead>
      <tbody>
        ${form.open_items
          .map(
            (item) => `
          <tr>
            <td>${item.formatted_number || '--'}</td>
            <td>${item.description || '--'}</td>
            <td>${item.assigneeLabel || '--'}</td>
            <td>${
              item.resolve_by
                ? new Date(item.resolve_by).toLocaleDateString('en-US')
                : '--'
            }</td>
            <td>${item.critical ? 'YES' : 'NO'}</td>
          </tr>
        `
          )
          .join('')}
      </tbody>
    </table>
  </div>
`
    : ''
}
${
  form.project_images && form.project_images.length > 0
    ? `
  <div class="project-report-photos avoid-break">
    <div class="images-body avoid-break">
      <div class="header">Photos</div>
      <div class="container flexrow avoid-break">
        ${form.project_images
          .map(
            (image) => `
          <div class="img-content ${image.portrait ? 'portrait' : 'landscape'}">
            <img src="${image.url}" alt="Report Image">
            <p class="img-description">${image.description || ''}</p>
          </div>
        `
          )
          .join('')}
      </div>
    </div>
  </div>
`
    : ''
}
${
  form.report_images && form.report_images.length > 0
    ? `
  <div class="project-report-photos avoid-break">
    <div class="images-body avoid-break">
      <div class="header">Photos</div>
      <div class="container flexrow avoid-break">
        ${form.report_images
          .map(
            (image) => `
          <div class="img-content ${image.portrait ? 'portrait' : 'landscape'}">
            <img src="${image.url}" alt="Report Image">
            <p class="img-description">${image.description || ''}</p>
          </div>
        `
          )
          .join('')}
      </div>
    </div>
  </div>
`
    : ''
}

<div class="project-report-disclaimer">
  <div class="header">Disclaimer</div>
  <div class="content">${form.disclaimer || '--'}</div>
</div>
<div class="project-report-disclaimer">
  <div class="header">Documents</div>
  <div class="content">Documents are not shown on this preview</div>
</div>
<div class="padding-bottom"></div>
 </body>
 </html>
 `;

export default html;
