export const OPEN_ITEMS_MODAL_SHOW = 'OPEN_ITEMS_MODAL_SHOW';
export const OPEN_ITEMS_MODAL_CLOSE = 'OPEN_ITEMS_MODAL_CLOSE';
export const OPEN_ITEMS_REPORT_MODAL_SHOW = 'OPEN_ITEMS_REPORT_MODAL_SHOW';
export const OPEN_ITEMS_REPORT_MODAL_CLOSE = 'OPEN_ITEMS_REPORT_MODAL_CLOSE';
export const OPEN_ITEMS_LOAD_SUCCESS = 'OPEN_ITEMS_LOAD_SUCCESS';
export const OPEN_ITEM_FORM_MODAL_SHOW = 'OPEN_ITEM_FORM_MODAL_SHOW';
export const OPEN_ITEM_FORM_MODAL_CLOSE = 'OPEN_ITEM_FORM_MODAL_CLOSE';
export const OPEN_ITEM_FORM_ATTR_SET = 'OPEN_ITEM_FORM_ATTR_SET';
export const OPEN_ITEM_FORM_ERRORS_SET = 'OPEN_ITEM_FORM_ERRORS_SET';
export const OPEN_ITEM_FORM_ERRORS_ATTR_CLEAR =
  'OPEN_ITEM_FORM_ERRORS_ATTR_CLEAR';
export const OPEN_ITEM_CREATE_SUCCESS = 'OPEN_ITEM_CREATE_SUCCESS';
export const OPEN_ITEM_FORM_SET = 'OPEN_ITEM_FORM_SET';
export const OPEN_ITEM_FORM_RESET = 'OPEN_ITEM_FORM_RESET';
export const OPEN_ITEM_DETAILS_MODAL_SHOW = 'OPEN_ITEM_DETAILS_MODAL_SHOW';
export const OPEN_ITEM_DETAILS_MODAL_CLOSE = 'OPEN_ITEM_DETAILS_MODAL_CLOSE';
export const OPEN_ITEM_DETAILS_MODAL_EDIT = 'OPEN_ITEM_DETAILS_MODAL_EDIT';
export const OPEN_ITEM_DETAILS_MODAL_EDIT_CANCEL =
  'OPEN_ITEM_DETAILS_MODAL_EDIT_CANCEL';
export const OPEN_ITEM_DETAILS_MODAL_RESPOND =
  'OPEN_ITEM_DETAILS_MODAL_RESPOND';
export const OPEN_ITEM_DETAILS_MODAL_RESPOND_CANCEL =
  'OPEN_ITEM_DETAILS_MODAL_RESPOND_CANCEL';
export const OPEN_ITEM_LOAD_SUCCESS = 'OPEN_ITEM_LOAD_SUCCESS';
export const OPEN_ITEM_UPDATE_SUCCESS = 'OPEN_ITEM_UPDATE_SUCCESS';
export const OPEN_ITEM_DELETE_SUCCESS = 'OPEN_ITEM_DELETE_SUCCESS';
export const OPEN_ITEMS_COUNT_SUCCESS = 'OPEN_ITEMS_COUNT_SUCCESS';
export const OPEN_ITEMS_COUNT_INIT = 'OPEN_ITEMS_COUNT_INIT';
export const OPEN_ITEMS_CRITICAL_SET = 'OPEN_ITEMS_CRITICAL_SET';
export const OPEN_ITEM_NOTES_LOAD_SUCCESS = 'OPEN_ITEM_NOTES_LOAD_SUCCESS';
export const OPEN_ITEM_NOTE_CREATE_SUCCESS = 'OPEN_ITEM_NOTE_CREATE_SUCCESS';
export const OPEN_ITEM_NOTE_CLEAR = 'OPEN_ITEM_NOTE_CLEAR';
export const OPEN_ITEM_UPDATE_VALUE = 'OPEN_ITEM_UPDATE_VALUE';
export const OPEN_ITEM_PDF_VIEWER_SET = 'OPEN_ITEM_PDF_VIEWER_SET';
