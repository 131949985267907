import {
  SURVEYS_SET,
  SURVEYS_SURVEY_SET,
} from 'components/admin/surveys/store/action-types';

const initialState = {
  currentSurvey: null,
  surveys: [],
};

export default function surveysDataReducer(state = initialState, action) {
  switch (action.type) {
    case SURVEYS_SET:
      return {
        ...state,
        surveys: action.payload,
      };

    case SURVEYS_SURVEY_SET:
      return {
        ...state,
        currentSurvey: action.payload,
      };

    default:
      return state;
  }
}
