import React from 'react';
import { connect } from 'react-redux';

import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
} from 'components/shared/button/Button';

import ProjectPhotoPermissions from 'permissions/project-photo-permissions';

import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getProjectPhotosCurrentAlbum,
  getProjectPhotosFilters,
} from './store/selectors';
import {
  setProjectPhotosForm,
  setProjectPhotosShowAlbumForm,
} from './store/actions';

const AddAlbumButton = (props) => {
  const {
    dispatch,
    currentAlbum,
    currentUser,
    currentProject,
    currentFilters,
  } = props;

  if (
    currentFilters.status ||
    currentFilters.favorites ||
    !ProjectPhotoPermissions.canAddItem(
      currentUser,
      currentProject,
      currentAlbum
    )
  )
    return null;

  const handleAdd = () => {
    dispatch(setProjectPhotosForm());
    const albumId = currentAlbum ? currentAlbum.id : undefined;
    dispatch(setProjectPhotosForm({ albumId }));
    dispatch(setProjectPhotosShowAlbumForm(true));
  };

  return (
    <Button
      key="add-album"
      label="Add Album"
      onClick={handleAdd}
      type={BUTTON_TYPES.LINK}
      icon={BUTTON_ICONS.PLUS}
    />
  );
};

export default connect((state) => ({
  currentFilters: getProjectPhotosFilters(state),
  currentAlbum: getProjectPhotosCurrentAlbum(state),
  currentUser: getCurrentUser(state),
  currentProject: getCurrentProject(state),
}))(AddAlbumButton);
