import React, { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';

import { Grid } from '@mui/material';
import FormModal from 'components/shared/modal/FormModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

import {
  getProjectPhotosForm,
  getProjectPhotosShowLocations,
} from './store/selectors';
import { setProjectPhotosShowLocations } from './store/actions';
import ProjectPhotosFilterSitesAreas from './ProjectPhotosFilterSitesAreas';

const ProjectPhotosFormLocationsModal = (props) => {
  const { dispatch, form, show, onChange } = props;
  const [sites, setSites] = useState(form.sites || []);
  const [areas, setAreas] = useState(form.areas || []);

  useEffect(() => {
    if (show) handleChange(form);
  }, [show]);

  const handleChange = ({ sites: ss, areas: aa }) => {
    setSites(ss || sites);
    setAreas(aa || areas);
  };

  const onClose = () => {
    dispatch(setProjectPhotosShowLocations(false));
  };
  const onSave = () => {
    onChange({ sites, areas });
    onClose();
  };

  return (
    <FormModal
      className="project-photos-locations-modal"
      show={show}
      onClose={onClose}
      subTitle="Locations"
    >
      <Grid
        container
        justifyContent="center"
        paddingX={4}
        paddingBottom={2.5}
        spacing={4}
      >
        <Grid item xs={12}>
          <ProjectPhotosFilterSitesAreas
            sites={sites}
            areas={areas}
            onChange={handleChange}
          />
        </Grid>
        <Grid item>
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label={'Save'}
            onClick={onSave}
          />
        </Grid>
      </Grid>
    </FormModal>
  );
};

export default connect((state) => ({
  form: getProjectPhotosForm(state),
  show: getProjectPhotosShowLocations(state),
}))(ProjectPhotosFormLocationsModal);
