import { createSelector } from 'reselect';

// data
export const getProjectPhotosCurrentAlbum = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.data.currentAlbum,
  (album) => album
);
export const getProjectPhotosCurrentAlbums = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.data.albums,
  (albums) => albums
);
export const getProjectPhotosTotalAlbums = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.data.totalAlbums,
  (total) => total
);
export const getProjectPhotosCurrentPhotos = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.data.photos,
  (photos) => photos
);
export const getProjectPhotosTotalPhotos = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.data.totalPhotos,
  (total) => total
);
export const getProjectPhotosFilters = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.data.filters,
  (filters) => filters
);
export const getProjectPhotosForm = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.data.form,
  (form) => form
);
export const getProjectPhotosFixedAlbums = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.data.fixedAlbums,
  (albums) => albums
);

// ui
export const getProjectPhotosShowAlbumForm = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.ui.showAlbumForm,
  (show) => show
);
export const getProjectPhotosShowForm = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.ui.showForm,
  (show) => show
);
export const getProjectPhotosShowFilters = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.ui.showFilters,
  (show) => show
);
export const getProjectPhotosShowDetail = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.ui.showDetail,
  (show) => show
);
export const getProjectPhotosShowUpload = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.ui.showUpload,
  (show) => show
);
export const getProjectPhotosShowTags = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.ui.showTags,
  (show) => show
);
export const getProjectPhotosShowTrades = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.ui.showTrades,
  (show) => show
);
export const getProjectPhotosShowLocations = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.ui.showLocations,
  (show) => show
);
export const getProjectPhotosShowUsers = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.ui.showUsers,
  (show) => show
);
export const getProjectPhotosShowMoveTo = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.ui.showMoveTo,
  (show) => show
);
export const getProjectPhotosShowDelete = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.ui.showDelete,
  (show) => show
);
export const getProjectPhotosDetail = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.data.detail,
  (detail) => detail
);
export const getProjectPhotosDetailPhotoId = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.ui.detailPhotoId,
  (id) => id
);
export const getProjectPhotosSelectedIds = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.ui.selectedIds,
  (selectedIds) => selectedIds
);
export const getProjectPhotosSelectedAlbums = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.ui.selectedAlbums,
  (selectedAlbums) => selectedAlbums
);
export const getProjectPhotosSelectedPhotos = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.ui.selectedPhotos,
  (selectedPhotos) => selectedPhotos
);
export const getProjectPhotosSelecting = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.ui.selecting,
  (selecting) => selecting
);
export const getProjectPhotosEditImage = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.ui.editImage,
  (edit) => edit
);
export const getProjectPhotosStartUpload = createSelector(
  (state) => state.projects.scenes.details.scenes.photos.ui.startUpload,
  (start) => start
);
