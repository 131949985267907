export const SET_REPORT_VIEWS = 'SET_REPORT_VIEWS';
export const SET_AVAILABLE_COMPANIES = 'SET_AVAILABLE_COMPANIES';
export const SET_AVAILABLE_PROJECTS = 'SET_AVAILABLE_PROJECTS';
export const NEW_REPORT_VIEW = 'NEW_REPORT_VIEW';
export const EDIT_REPORT_VIEW = 'EDIT_REPORT_VIEW';
export const REPORT_VIEW_FORM_VALUE_CHANGE = 'REPORT_VIEW_FORM_VALUE_CHANGE';
export const SHOW_SAVE_VIEW_MODAL = 'SHOW_SAVE_VIEW_MODAL';
export const HIDE_SAVE_VIEW_MODAL = 'HIDE_SAVE_VIEW_MODAL';
export const SET_RUNNING_REPORT = 'SET_RUNNING_REPORT';
export const CLEAR_RUNNING_REPORT = 'CLEAR_RUNNING_REPORT';
