import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';

const DEFAULT_GET_WORK_ORDERS_CRITERIA = {
  page: 1,
  perPage: 25,
  status: null,
  critical: false,
  withoutContractorInvoice: false,
  jobNumber: '',
};

class WorkOrdersApi extends BaseApi {
  /**
   * Get work orders based on given criteria.
   * @param criteria: { page: number, status: WorkOrderStatusEnum, critical: boolean, jobNumber: string }
   * @return {Promise}
   */
  getWorkOrders(criteria = DEFAULT_GET_WORK_ORDERS_CRITERIA) {
    // Remove null|undefined values
    const filteredCriteria = { ...criteria };
    Object.keys(filteredCriteria).forEach(
      (key) =>
        (filteredCriteria[key] === null ||
          filteredCriteria[key] === undefined) &&
        delete filteredCriteria[key]
    );

    // Merge default with properly criteria
    const finalCriteria = {
      ...DEFAULT_GET_WORK_ORDERS_CRITERIA,
      ...filteredCriteria,
    };

    let filter = '';
    if (finalCriteria.status !== null) {
      filter += `&${finalCriteria.status}=true`;
    }
    if (finalCriteria.critical !== null && finalCriteria.critical) {
      filter += '&critical=true';
    }
    if (finalCriteria.jobNumber !== '') {
      filter += `&by_job_number=${finalCriteria.jobNumber}`;
    }
    if (
      finalCriteria.withoutContractorInvoice !== null &&
      finalCriteria.withoutContractorInvoice
    ) {
      filter += '&without_contractor_invoice=true';
    }
    if (finalCriteria.perPage !== null) {
      filter += `&per_page=${finalCriteria.perPage}`;
    }

    return axios
      .get(`${this.baseUrl}/work_orders?page=${finalCriteria.page}${filter}`)
      .then((response) => {
        return {
          ok: true,
          workOrders: {
            content: response.data,
            total: numeral(response.headers['total']).value(),
            totalComplete: numeral(response.headers['total-complete']).value(),
            totalCritical: numeral(response.headers['total-critical']).value(),
            totalDraft: numeral(response.headers['total-draft']).value(),
            totalInProgress: numeral(
              response.headers['total-in_progress']
            ).value(),
            totalPending: numeral(response.headers['total-pending']).value(),
            totalPlm: numeral(response.headers['total-plm']).value(),
            totalWoa: numeral(response.headers['total-woa']).value(),
            totalClosed: numeral(response.headers['total-closed']).value(),
            totalDeclined: numeral(response.headers['total-declined']).value(),
            pageSize: numeral(response.headers['per-page']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getWorkOrder(id) {
    return axios
      .get(this.baseUrl + '/work_orders/' + id)
      .then((response) => {
        return {
          ok: true,
          workOrder: {
            ...response.data,
            unread_notes:
              response.headers['unread-notes'] !== undefined &&
              response.headers['unread-notes'] === 'true',
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createWorkOrder(idBuilding, clientId) {
    const data = {
      building_id: idBuilding,
    };
    if (clientId !== undefined) {
      data['client_id'] = clientId;
    }

    return axios
      .post(this.baseUrl + '/work_orders', data)
      .then((response) => {
        return {
          ok: true,
          newWorkOrder: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateBuilding(workOrderId, buildingId, files) {
    return this._updateWorkOrder(workOrderId, 'building_id', buildingId);
  }

  updateClient(workOrderId, clientId) {
    return this._updateWorkOrder(workOrderId, 'client_id', clientId);
  }

  addSubContractors(workOrderId, contractorsId) {
    return this._updateWorkOrder(
      workOrderId,
      'propietary_sub_contractor_ids',
      contractorsId
    );
  }

  addWorkingHours(workOrderId, workingHours) {
    return this._updateWorkOrder(workOrderId, 'working_hours', workingHours);
  }

  addUnionTrades(workOrderId, tradesId) {
    return this._updateWorkOrder(workOrderId, 'union_ids', tradesId);
  }

  updateWorkOrder(workOrderId, values) {
    return axios
      .put(this.baseUrl + '/work_orders/' + workOrderId, {
        work_order: values,
      })
      .then((response) => {
        return {
          ok: true,
          workOrder: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  _updateWorkOrder(workOrderId, attribute, value) {
    const workOrderData = {};
    workOrderData[attribute] = value;

    return axios
      .put(this.baseUrl + '/work_orders/' + workOrderId, {
        work_order: workOrderData,
      })
      .then((response) => {
        return {
          ok: true,
          workOrder: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getSummary(workOrderId) {
    return axios
      .get(this.baseUrl + '/work_orders/' + workOrderId + '/summary')
      .then((response) => {
        return {
          ok: true,
          summary: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  confirmWorkOrder(workOrderId) {
    return axios
      .put(this.baseUrl + '/work_orders/' + workOrderId + '/submit')
      .then((response) => {
        return {
          ok: true,
          workOrder: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  requestSubmit(workOrderId) {
    return axios
      .post(this.baseUrl + '/work_orders/' + workOrderId + '/request_submit')
      .then((response) => {
        return {
          ok: true,
          workOrder: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  deleteWorkOrder(workOrderId) {
    return axios
      .delete(this.baseUrl + '/work_orders/' + workOrderId)
      .then(() => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  uploadDocuments(workOrderId, documents) {
    let data = new FormData();
    documents.forEach((document) => {
      data.append('documents[][document]', document);
    });

    return axios
      .post(this.baseUrl + '/work_orders/' + workOrderId + '/documents', data)
      .then((response) => {
        return {
          ok: true,
          documents: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  deleteDocument(workOrderId, documentId) {
    return axios
      .delete(
        this.baseUrl +
          '/work_orders/' +
          workOrderId +
          '/documents/' +
          documentId
      )
      .then(() => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getTasks(workOrderId) {
    return axios
      .get(this.baseUrl + '/work_orders/' + workOrderId + '/tasks')
      .then((response) => {
        return {
          ok: true,
          tasks: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getQuotes(workOrderId) {
    return axios
      .get(this.baseUrl + '/work_orders/' + workOrderId + '/quotes')
      .then((response) => {
        return {
          ok: true,
          quotes: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getProposals(workOrderId) {
    return axios
      .get(this.baseUrl + '/work_orders/' + workOrderId + '/proposals')
      .then((response) => {
        return {
          ok: true,
          proposals: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getNotes(workOrderId) {
    return axios
      .get(`${this.baseUrl}/work_orders/${workOrderId}/notes`)
      .then((response) => {
        return {
          ok: true,
          notes: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  addNote(workOrderId, content) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const apiPayload = new FormData();
    apiPayload.append('note[content]', content.content);

    let index = 0;
    if (content.images && content.images.length > 0) {
      content.images
        .filter((image) => !image.id)
        .forEach((image) => {
          apiPayload.append(
            `note[images_attributes][${index}][image]`,
            image.file,
            image.file.name
          );
          index++;
        });
    }

    index = 0;
    if (content.documents && content.documents.length > 0) {
      content.documents
        .filter((doc) => !doc.id)
        .forEach((doc) => {
          apiPayload.append(
            `note[documents_attributes][${index}][document]`,
            doc.file,
            doc.file.name
          );
          index++;
        });
    }

    return axios
      .post(
        `${this.baseUrl}/work_orders/${workOrderId}/notes`,
        apiPayload,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          note: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  markNotesAsReaded(workOrderId) {
    return axios
      .put(`${this.baseUrl}/work_orders/${workOrderId}/notes/mark_all_read`)
      .then(() => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  addMember(workOrderId, userId, note) {
    const data = {
      work_order_user: {
        user_id: userId,
      },
    };

    if (note !== '') {
      data['work_order_user']['note'] = note;
    }

    return axios
      .post(`${this.baseUrl}/work_orders/${workOrderId}/work_order_users`, data)
      .then((response) => {
        return {
          ok: true,
          member: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getOpenItems(workOrderId) {
    return axios
      .get(`${this.baseUrl}/work_orders/${workOrderId}/open_items`)
      .then((response) => {
        return {
          ok: true,
          openItems: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getMembers(workOrderId) {
    return axios
      .get(`${this.baseUrl}/work_orders/${workOrderId}/work_order_users`)
      .then((response) => {
        return {
          ok: true,
          members: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createJobNumber(workOrderId, value) {
    const data = {
      work_order_job: {
        number: value,
      },
    };
    return axios
      .post(
        `${this.baseUrl}/work_orders/${workOrderId}/work_order_jobs`,
        data,
        {
          loadingDisabled: true,
        }
      )
      .then((response) => {
        return {
          ok: true,
          workOrderJob: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateJobNumber(workOrderJobId, value) {
    const data = {
      work_order_job: {
        number: value,
      },
    };
    return axios
      .put(`${this.baseUrl}/work_order_jobs/${workOrderJobId}`, data, {
        loadingDisabled: true,
      })
      .then((response) => {
        return {
          ok: true,
          workOrderJob: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateName(workOrderId, value) {
    const data = {
      work_order: {
        name: value,
      },
    };
    return axios
      .put(`${this.baseUrl}/work_orders/${workOrderId}`, data, {
        loadingDisabled: true,
      })
      .then((response) => {
        return {
          ok: true,
          workOrder: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateNote(workOrderId, value, reminderValue) {
    const data = {
      work_order: {
        note: value,
        note_reminder_date: reminderValue,
      },
    };
    return axios
      .put(`${this.baseUrl}/work_orders/${workOrderId}`, data, {
        loadingDisabled: true,
      })
      .then((response) => {
        return {
          ok: true,
          workOrder: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createBudget(
    workOrderId,
    taskValuesById,
    qualifications,
    qualificationsById
  ) {
    const data = {
      budget: {
        budget_tasks_attributes: Object.keys(taskValuesById).map((taskId) => {
          return {
            task_id: taskId,
            cost: taskValuesById[taskId].cost,
            fee: taskValuesById[taskId].fee,
            insurance: taskValuesById[taskId].insurance,
            markup: taskValuesById[taskId].markup,
          };
        }),
        budget_qualifications_attributes: qualifications
          .filter((q) => qualificationsById[q.id])
          .map((q) => {
            const value = qualificationsById[q.id];
            return {
              qualification_id: value.id,
              option: value.option !== null ? value.option.label : null,
            };
          }),
      },
    };
    return axios
      .post(`${this.baseUrl}/work_orders/${workOrderId}/budget`, data)
      .then((response) => {
        return {
          ok: true,
          budget: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateBudget(workOrderId, budgetTasks, budgetQualifications) {
    const data = {
      budget: {
        budget_tasks_attributes: budgetTasks,
        budget_qualifications_attributes: budgetQualifications,
      },
    };
    return axios
      .put(`${this.baseUrl}/work_orders/${workOrderId}/budget`, data)
      .then((response) => {
        return {
          ok: true,
          budget: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getBudget(workOrderId) {
    return axios
      .get(`${this.baseUrl}/work_orders/${workOrderId}/budget`)
      .then((response) => {
        return {
          ok: true,
          budget: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  approveBudget(workOrderId, tasksId) {
    const data = {
      budget: {
        task_ids: tasksId,
      },
    };
    return axios
      .put(`${this.baseUrl}/work_orders/${workOrderId}/budget/approve`, data)
      .then((response) => {
        return {
          ok: true,
          budget: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updatePurchaseOrder(workOrderId, file = null, number = null) {
    let formData = new FormData();

    if (number !== null && number !== '') {
      formData.append('work_order[purchase_order]', number.toString());
    }

    if (file !== null) {
      formData.append(
        'work_order[purchase_order_doc_attributes][document]',
        file
      );
    }

    return axios
      .put(`${this.baseUrl}/work_orders/${workOrderId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        return {
          ok: true,
          workOrder: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getBudgetAsPdf(workOrderId) {
    return axios
      .get(`${this.baseUrl}/work_orders/${workOrderId}/budget/print`, {
        responseType: 'blob',
      })
      .then((response) => {
        return {
          ok: true,
          pdf: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  sendBudgetEmail(workOrderId, emails, subject, message) {
    const data = {
      budget: {
        send_to: emails,
        subject: subject,
        message: message,
      },
    };
    return axios
      .put(`${this.baseUrl}/work_orders/${workOrderId}/budget/email`, data)
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getContractorInvoices(workOrderId) {
    return axios
      .get(`${this.baseUrl}/work_orders/${workOrderId}/contractor_invoices`)
      .then((response) => {
        return {
          ok: true,
          invoices: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  uploadContractorInvoice(workOrderId, values) {
    let data = new FormData();
    data.append('contractor_invoice[cost]', values.cost);
    data.append(
      'contractor_invoice[document_attributes][document]',
      values.document
    );

    return axios
      .post(
        `${this.baseUrl}/work_orders/${workOrderId}/contractor_invoices`,
        data
      )
      .then((response) => {
        return {
          ok: true,
          contractorInvoice: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getContractorLienReleases(workOrderId) {
    return axios
      .get(
        `${this.baseUrl}/work_orders/${workOrderId}/contractor_lien_releases`
      )
      .then((response) => {
        return {
          ok: true,
          lienReleases: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  uploadContractorLienRelease(workOrderId, document) {
    let data = new FormData();
    data.append(
      'contractor_lien_release[document_attributes][document]',
      document
    );

    return axios
      .post(
        `${this.baseUrl}/work_orders/${workOrderId}/contractor_lien_releases`,
        data
      )
      .then((response) => {
        return {
          ok: true,
          contractorLienRelease: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getReceipts(workOrderId) {
    return axios
      .get(`${this.baseUrl}/work_orders/${workOrderId}/receipts`)
      .then((response) => {
        return {
          ok: true,
          receipts: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getTime(workOrderId) {
    return axios
      .get(`${this.baseUrl}/work_orders/${workOrderId}/time_entries`)
      .then((response) => {
        return {
          ok: true,
          time: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getVendorInvoices(workOrderId) {
    return axios
      .get(`${this.baseUrl}/work_orders/${workOrderId}/vendor_invoices`)
      .then((response) => {
        return {
          ok: true,
          invoices: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  declineBudget(workOrderId, values) {
    const apiPayload = {
      budget: {
        password: values.password,
        rejected_reason_1: values.reason,
        rejected_reason_2: values.additionalInfo,
      },
    };
    return axios
      .put(
        `${this.baseUrl}/work_orders/${workOrderId}/budget/reject`,
        apiPayload
      )
      .then((response) => {
        return {
          ok: true,
          budget: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  changeClient(workOrderId, clientId) {
    const apiPayload = {
      work_order: {
        client_id: clientId,
      },
    };
    return axios
      .put(`${this.baseUrl}/work_orders/${workOrderId}/transfer`, apiPayload)
      .then(() => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  submitQuotes(workOrderId, contractorId, values) {
    const apiPayload = {
      contractor: {
        quotes_attributes: values.map((item) => {
          const result = {
            id: item.quoteId,
            estimate: item.totalCost,
            start_date: item.startDate,
            days_of_duration: item.durationInDays,
            material_lead_days: item.materialLeadDays,
            description: item.description,
          };
          return result;
        }),
      },
    };
    return axios
      .put(
        `${this.baseUrl}/work_orders/${workOrderId}/contractors/${contractorId}/submit_quotes`,
        apiPayload
      )
      .then((response) => {
        return {
          ok: true,
          quotes: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateWorkOrderAcrDocs(workOrderId, docs, docToDestroyId) {
    let docsIndex = 0;
    let workOrderData = new FormData();

    if (docs && docs.length > 0) {
      docs.forEach((doc) => {
        workOrderData.append(
          `work_order[acr_documents_attributes][${docsIndex}][document]`,
          doc
        );
        docsIndex++;
      });
    }

    if (docToDestroyId) {
      workOrderData.append(
        `work_order[acr_documents_attributes][${docsIndex}][id]`,
        docToDestroyId
      );
      workOrderData.append(
        `work_order[acr_documents_attributes][${docsIndex}][_destroy]`,
        true
      );
    }

    return axios
      .put(`${this.baseUrl}/work_orders/${workOrderId}`, workOrderData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        return {
          ok: true,
          workOrder: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const workOrdersApi = new WorkOrdersApi();
export default WorkOrdersApi = new WorkOrdersApi();
