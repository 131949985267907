import {
  GENERAL_CONTRACTORS_LOAD_SUCCESS,
  GENERAL_CONTRACTOR_LOAD_SUCCESS,
  GENERAL_CONTRACTOR_PROJECTS_LOAD_SUCCESS,
  GENERAL_CONTRACTORS_USERS_SET,
  GENERAL_CONTRACTORS_ROLES_LOAD_SUCCESS,
  GENERAL_CONTRACTORS_USER_ADD,
  GENERAL_CONTRACTORS_NEW_PARAMETERS_LOAD_SUCCESS,
} from 'components/admin/general-contractors/store/action-types';

const initialState = {
  generalContractors: { data: [], total: 0 },
  currentGeneralContractor: null,
  projects: null,
  users: [],
  roles: [],
  unions: [],
};

export default function generalContractorsDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GENERAL_CONTRACTOR_LOAD_SUCCESS:
      return {
        ...state,
        currentGeneralContractor: action.payload,
      };

    case GENERAL_CONTRACTORS_LOAD_SUCCESS:
      return {
        ...state,
        generalContractors: action.payload,
      };

    case GENERAL_CONTRACTOR_PROJECTS_LOAD_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      };

    case GENERAL_CONTRACTORS_USERS_SET:
      return {
        ...state,
        users: action.payload,
      };

    case GENERAL_CONTRACTORS_ROLES_LOAD_SUCCESS:
      return {
        ...state,
        roles: action.payload,
      };

    case GENERAL_CONTRACTORS_USER_ADD:
      return {
        ...state,
        users: state.users.concat([action.payload]),
      };

    case GENERAL_CONTRACTORS_NEW_PARAMETERS_LOAD_SUCCESS:
      return {
        ...state,
        unions:
          action.payload.unions !== undefined
            ? action.payload.unions
            : state.unions,
      };

    default:
      return state;
  }
}
