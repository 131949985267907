import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './BaseStatus.css';

class BaseStatus extends Component {
  static propTypes = {
    labels: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    status: PropTypes.string.isRequired,
  };

  render() {
    let value = this.props.status;
    if (
      this.props.labels !== undefined &&
      this.props.labels.filter((element) => element.id === this.props.status)
        .length > 0
    ) {
      value = this.props.labels.find(
        (element) => element.id === this.props.status
      ).label;
    }

    return (
      <span className="base-status">
        <div className={'base-status-color ' + this.props.status} />
        {value}
      </span>
    );
  }
}

export default BaseStatus;
