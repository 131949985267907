import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import DropdownMenu from 'components/shared/menu/DropdownMenu';
import Input from 'components/shared/input/Input';
import './FilterHeader.css';
import vectorSvg from './vector.svg';
import vectorDarkSvg from './vector-dark.svg';
import vectorSelectedSvg from './vector-selected.svg';
import vectorSelectedDarkSvg from './vector-selected-dark.svg';
import deleteSvg from 'images/deleteCross.svg';
import deleteDarkSvg from 'images/deleteCrossDarkMode.svg';
import { camelize } from 'services/utils/text-util';

function FilterHeader(props) {
  const {
    className,
    label,
    selected,
    options,
    search,
    onFilter,
    darkMode,
    upperCaseLabel,
    icon,
    disabled,
    clearable,
  } = props;
  const [searchText, setSearchText] = useState('');
  const handleSearch = (text) => {
    setSearchText(text);
  };

  const handleChange = (option) => {
    setSearchText('');
    if (onFilter) {
      onFilter(option.value);
    }
  };

  const formatLabel = (label) => {
    if (!upperCaseLabel) return label;
    else return camelize(label.toLowerCase());
  };

  const handleRenderSelector = (selectedOption, open) => {
    const hasValue = !!selectedOption && selectedOption.value !== options[0].id;

    return (
      <div
        className={classnames({
          'filter-header-selector': true,
          'filter-open': open,
        })}
      >
        <div className="filter-header-title">
          {hasValue || (open && search) ? formatLabel(label) : ' '}
        </div>
        <div className="filter-header-body">
          {open && search ? (
            <Input
              className="filter-header-input"
              value={searchText}
              autoFocus={true}
              onChange={handleSearch}
            />
          ) : (
            <div
              className={classnames({
                'filter-header-value': true,
                'selected-value': hasValue,
              })}
            >
              {hasValue ? selectedOption.label : label}
            </div>
          )}
          {clearable && !!selectedOption && (
            <div
              className="header-label-clear"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleChange({ value: null });
              }}
            >
              <img
                className="header-label-icon-clear"
                src={!darkMode ? deleteSvg : deleteDarkSvg}
                alt="selector"
              />
            </div>
          )}
          <div className="header-label-icon-container">
            <img
              className="header-label-icon"
              src={
                icon
                  ? icon
                  : hasValue || open
                  ? darkMode
                    ? vectorSelectedDarkSvg
                    : vectorSelectedSvg
                  : darkMode
                  ? vectorDarkSvg
                  : vectorSvg
              }
              alt="selector"
            />
          </div>
        </div>
      </div>
    );
  };

  return !!options && options.length > 0 ? (
    <>
      <DropdownMenu
        className={classnames({
          'filter-header': true,
          'search-header': search,
          'clearable-header': clearable,
          'disabled-header': disabled,
          [className]: !!className,
        })}
        options={(search && searchText
          ? options.filter(
              (option) =>
                typeof option.label.toLowerCase == 'undefined' ||
                option.label.toLowerCase().indexOf(searchText.toLowerCase()) >=
                  0
            )
          : options
        ).map((option) => ({
          value: option.id,
          label: option.label,
          closeOnSelect: option.closeOnSelect,
          className: option.className,
          show: option.show,
          isDate: option.isDate,
          onDateChange: option.onDateChange,
          dateValue: option.dateValue,
          subitems:
            option.subitems &&
            option.subitems.map((so) => ({
              value: so.id,
              label: so.label,
              isDate: so.isDate,
              onDateChange: so.onDateChange,
              dateValue: option.dateValue,
            })),
        }))}
        value={selected}
        onChange={handleChange}
        renderSelector={handleRenderSelector}
      />
    </>
  ) : (
    <div className="simple-header">{label}</div>
  );
}

FilterHeader.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      closeOnSelect: PropTypes.bool,
    })
  ),
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  search: PropTypes.bool,
  upperCaseLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  icon: PropTypes.string,
  onFilter: PropTypes.func,
};

FilterHeader.defaultTypes = {
  className: null,
  selected: false,
  upperCaseLabel: true,
  disabled: false,
  clearable: false,
  search: null,
  icon: null,
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(FilterHeader);
