import { combineReducers } from 'redux';

/**
 * Create a reducer for a scene.
 * @param dataReducer
 * @param uiReducer
 * @param scenes
 * @return {Reducer<any>}
 */
export function createSceneReducer(dataReducer, uiReducer, scenes) {
  const reducers = {};

  if (dataReducer) {
    reducers['data'] = dataReducer;
  }

  if (uiReducer) {
    reducers['ui'] = uiReducer;
  }

  if (scenes) {
    reducers['scenes'] = scenes;
  }

  return combineReducers(reducers);
}
