import React from 'react';
import { connect } from 'react-redux';

import {
  getCurrentFieldReport,
  getFieldReportDeleteModal,
} from './store/selectors';
import {
  deleteFieldReportRequest,
  setFieldReportDeleteModal,
} from './store/actions';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';

import ConfirmationModal from 'components/shared/modal/ConfirmationModal';

const FieldReportDeleteConfirmationModal = (props) => {
  const { dispatch, show, currentFieldReport, currentProject } = props;
  const handleDeleteConfirmation = (ok) => {
    if (ok) {
      dispatch(
        deleteFieldReportRequest(currentProject.id, currentFieldReport.id)
      );
    } else dispatch(setFieldReportDeleteModal(false));
  };

  if (!show) return null;

  return (
    <ConfirmationModal
      show={show}
      message="Are you sure you want to discard this Field Report?"
      onHide={handleDeleteConfirmation}
    />
  );
};

export default connect(
  (state) => ({
    show: getFieldReportDeleteModal(state),
    currentFieldReport: getCurrentFieldReport(state),
    currentProject: getCurrentProject(state),
  }),
  null
)(FieldReportDeleteConfirmationModal);
