import axios from 'axios';
import BaseApi from './base-api';

class CloseoutCompanyTypesApi extends BaseApi {
  getCloseoutCompanyTypes() {
    return axios
      .get(`${this.baseUrl}/closeout_company_types`)
      .then((response) => {
        return {
          ok: true,
          closeoutCompanyTypes: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const closeoutCompanyTypesApi = new CloseoutCompanyTypesApi();
