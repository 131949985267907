import axios from 'axios';
import BaseApi from './base-api';

class DirectoriesApi extends BaseApi {
  getDirectory(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/directory`)
      .then((response) => {
        return {
          ok: true,
          directory: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getDirectoryPdf(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/directory/print`, {
        responseType: 'blob',
      })
      .then((response) => {
        return {
          ok: true,
          directoryPdf: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const directoriesApi = new DirectoriesApi();
