import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';
import { useCounter, useMap, useQueue } from 'react-use';

import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import useDirectUpload from 'services/hooks/direct-upload';
import { showErrorMessage } from 'actions/messages';
import { getDarkMode } from 'selectors/theme';

import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import Dropzone from 'react-dropzone';
import {
  faCheck,
  faClock,
  faExclamation,
  faPlay,
  faTimes,
  faUpload,
} from '@fortawesome/fontawesome-free-solid';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import uploadSvg from 'components/shared/document-upload/upload.svg';
import uploadSvgDark from 'components/shared/document-upload/uploadDarkMode.svg';

import {
  dataUrlToFile,
  urlToFileNameAndExtension,
} from 'services/utils/files-util';
import VideoThumb from 'components/shared/video/VideoThumb';

import './ProjectPhotosUpload.css';
import { getProjectPhotosStartUpload } from './store/selectors';
import {
  addProjectPhotosCurrentPhotos,
  createProjectPhoto,
  saveProjectPhoto,
} from './store/actions';
import DocumentUploadPreview from '../../../../shared/document-upload/DocumentUploadPreview';
import ImageThumb from '../../../../shared/video/ImageThumb';

const ACCEPTED_TYPES = 'image/*,video/*';
const MAX_UPLOADING = 5;
// STATUSES
const STATUS = {
  INITIAL: 'INITIAL',
  WAITING: 'WAITING',
  UPLOADING: 'UPLOADING',
  FINISHED: 'FINISHED',
  ERROR: 'ERROR',
};

const ProjectPhotoUpload = (props) => {
  const {
    dispatch,
    darkMode,
    reset,
    startUpload,
    albumId,
    onChange,
    currentProject,
    onClose,
  } = props;

  const { directUpload } = useDirectUpload();
  const [photos, setPhotos] = useMap({});
  const [count, setCount] = useCounter(0);
  const queue = useQueue();

  const isUploading = queue.size !== 0 || count !== 0;

  useEffect(() => {
    if (reset) {
      setPhotos.reset();
      setCount.reset(0);
    }
  }, [reset]);

  useEffect(() => {
    if (startUpload && albumId) upload();
  }, [startUpload, albumId]);

  // DROPZONE
  const onDropAccepted = (dropA) => {
    dropA.forEach((file) => {
      if (typeof setPhotos.get(file.name) === 'undefined') {
        const fileData = {
          file,
          name: file.name,
          isVideo: file.type.includes('video'),
          status: STATUS.INITIAL,
          percent: 0,
        };
        setPhotos.set(file.name, fileData);
      }
    });
    onChange(Object.keys(photos).length + dropA.length);
  };
  const onDropRejected = (dropR) => {
    let msg = 'Some files were rejected.';
    if (dropR.length <= 1) msg = 'One file was rejected.';
    dispatch(showErrorMessage(msg));
  };
  const renderDropzone = () => {
    return (
      <Dropzone
        multiple
        accept={ACCEPTED_TYPES}
        onDropAccepted={onDropAccepted}
        onDropRejected={onDropRejected}
        className={'project-photo-dropzone'}
        disabled={isUploading}
      >
        <div className="document-drop-zone">
          <img src={darkMode ? uploadSvgDark : uploadSvg} alt="upload" />
          <span>Drag Files Here</span>
        </div>
      </Dropzone>
    );
  };

  // PREVIEWS
  const addPreview = (file, thumb_name) => async (base64) => {
    const thumb_file = await dataUrlToFile(base64, thumb_name);

    file.thumb = thumb_file;
    setPhotos.set(file.name, file);
  };
  const renderPreviewVideo = (fileData) => {
    const { name } = urlToFileNameAndExtension(fileData.name);
    const thumb_name = `thumb_${name}.png`;

    return (
      <VideoThumb
        video={fileData.file.preview}
        onCreated={addPreview(fileData, thumb_name)}
      />
    );
  };
  const renderPreviewImage = (fileData) => {
    const thumb_name = `gallery_${fileData.name}`;
    return (
      <ImageThumb
        image={fileData.file.preview}
        onCreated={addPreview(fileData, thumb_name)}
        alt={fileData.name}
      />
    );
  };
  const removePreview = (name) => () => {
    setPhotos.remove(name);
    onChange(Object.keys(photos).length - 1);
  };
  const getPreviewIcon = (status) => {
    if (status === STATUS.ERROR) return faExclamation;
    if (status === STATUS.UPLOADING) return faUpload;
    if (status === STATUS.WAITING) return faClock;
    return faCheck;
  };
  const renderPreview = (file) => {
    return (
      <div className={`project-photo-preview ${file.status}`} key={file.name}>
        {file.status === STATUS.INITIAL && (
          <div className="cancel" onClick={removePreview(file.name)}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}
        {file.status === STATUS.UPLOADING && (
          <div className="cancel percent">{Math.round(file.percent)}%</div>
        )}
        {file.status !== STATUS.INITIAL && file.status !== STATUS.UPLOADING && (
          <div className="cancel">
            <FontAwesomeIcon icon={getPreviewIcon(file.status)} />
          </div>
        )}
        {file.isVideo ? renderPreviewVideo(file) : renderPreviewImage(file)}
        {file.isVideo && (
          <div className="video-icon">
            <FontAwesomeIcon icon={faPlay} />
          </div>
        )}
      </div>
    );
  };
  const renderPreviews = () => {
    return (
      <div className="project-photos-previews">
        {Object.values(photos).map(renderPreview)}
      </div>
    );
  };

  // UPLOAD
  const allFinished = () => {
    return Object.values(photos).reduce((acc, curr) => {
      return acc && curr.status === STATUS.FINISHED;
    }, true);
  };
  const setError = (fileData) => {
    fileData.status = STATUS.ERROR;
    setCount.dec();
  };
  const createProjectPhotoModel = async (fileData) => {
    const res = await dispatch(createProjectPhoto(currentProject.id, albumId));
    if (!res.ok) setError(fileData);
    return res.ok ? res.data : null;
  };
  const uploadProgress = (fileData) => (pEvent) => {
    if (pEvent.isTrusted) {
      fileData.percent = (pEvent.loaded / pEvent.total) * 100;
      setPhotos.set(fileData.name, fileData);
    }
  };
  const uploadS3 = async (fileData, identifier) => {
    const S3params = {
      model: 'project_photos',
      subModel: fileData.isVideo ? 'videos' : 'images',
      identifier,
    };

    const res = await directUpload(
      fileData.file,
      S3params,
      uploadProgress(fileData)
    );
    if (!res.ok) setError(fileData);

    if (res.ok && fileData.thumb) {
      const resThumb = await directUpload(fileData.thumb, S3params);
      if (!resThumb.ok) setError(fileData);
    }

    return res.ok ? res : null;
  };
  const saveProjectPhotoModel = async (fileData, projectPhoto) => {
    const res = await dispatch(
      saveProjectPhoto(currentProject.id, projectPhoto.id, {
        photo: fileData,
        albumId,
      })
    );
    if (!res.ok) setError(fileData);
    return res.ok ? res.data : null;
  };

  const uploadFile = async (fileData) => {
    // create ProjectPhoto
    const projectPhoto = await createProjectPhotoModel(fileData);
    if (!projectPhoto) return;

    // upload S3
    const response = await uploadS3(fileData, projectPhoto.id);
    if (!response) return;

    // update ProjectPhoto
    const saved = await saveProjectPhotoModel(fileData, projectPhoto);
    if (!saved) return;

    dispatch(addProjectPhotosCurrentPhotos([saved]));
    fileData.status = STATUS.FINISHED;
    setCount.dec();
  };
  const processUploadQueue = () => {
    if (queue.size > 0 && count < MAX_UPLOADING) {
      const first = queue.first;
      first.status = STATUS.UPLOADING;
      queue.remove();
      setCount.inc();
      uploadFile(first);
    }
  };
  useEffect(() => processUploadQueue(), [queue.size, count]);

  const upload = () => {
    Object.values(photos).forEach((fileData) => {
      if (
        fileData.status === STATUS.INITIAL ||
        fileData.status === STATUS.ERROR
      ) {
        fileData.status = STATUS.WAITING;
        queue.add(fileData);
      }
    });
  };
  const renderProgress = () => {
    const totalCount = Object.keys(photos).length;
    const finishedCount = Object.values(photos).filter(
      (p) => p.status === STATUS.FINISHED || p.status === STATUS.ERROR
    ).length;

    let status = 'complete';
    let percentage = 100;

    if (finishedCount < totalCount) {
      status = `uploading ${finishedCount + 1} of ${totalCount}`;

      const totalPercent = totalCount * 100;
      const totalPercentUploaded = Object.values(photos).reduce(
        (acc, curr) => acc + curr.percent,
        0
      );
      percentage = (totalPercentUploaded * 100) / totalPercent;
      percentage = Math.round(percentage);
    }

    if (totalCount === 0) return null;

    return (
      <div className="multiple-preview">
        <DocumentUploadPreview
          percentage={percentage}
          showCompleteIcon
          status={status}
        />
      </div>
    );
  };

  return (
    <Grid container className="project-photo-upload" spacing={2}>
      <Grid item xs={12}>
        {renderDropzone()}
      </Grid>

      {startUpload && (
        <Grid item xs={12}>
          {renderProgress()}
        </Grid>
      )}

      {Object.keys(photos).length > 0 && (
        <Grid item xs={12}>
          {renderPreviews()}
        </Grid>
      )}
      {startUpload && (
        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent={'center'} paddingY={2}>
            {!allFinished() && (
              <Grid item>
                <Button
                  type={BUTTON_TYPES.CONFIRMATION}
                  color={BUTTON_COLORS.GREEN}
                  label="Upload"
                  disable={isUploading}
                  onClick={upload}
                />
              </Grid>
            )}
            <Grid item>
              <Button
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.WHITE}
                label={'Done'}
                disable={isUploading}
                onClick={onClose}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default connect((state) => ({
  darkMode: getDarkMode(state),
  currentProject: getCurrentProject(state),
  startUpload: getProjectPhotosStartUpload(state),
}))(ProjectPhotoUpload);
