import {
  WORK_ORDERS_CREATION_BUILDING_LOCATION_UPDATE_SUCCESS,
  WORK_ORDERS_CREATION_NEW_BUILDING_CHANGE_VALUE,
  WORK_ORDERS_CREATION_NEW_BUILDING_REQUEST,
  WORK_ORDERS_CREATION_NEW_BUILDING_SUCCESS,
  WORK_ORDERS_CREATION_SELECT_BUILDING,
  WORK_ORDERS_WO_ADD,
} from '../../../../actions/admin/work-orders/action-types';

const initialState = {
  selectedBuildingId: 0,
  newBuildingForm: createNewBuildingForm(),
};

function createNewBuildingForm() {
  return {
    name: '',
    streetAddress: '',
    unitNumber: '',
    city: '',
    state: '',
    zipCode: '',
  };
}

export default function buildingsDataReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_CREATION_SELECT_BUILDING:
      return {
        ...state,
        selectedBuildingId: action.payload,
      };

    case WORK_ORDERS_CREATION_NEW_BUILDING_REQUEST:
      return {
        ...state,
        selectedBuildingId: 0,
        newBuildingForm: createNewBuildingForm(),
      };

    case WORK_ORDERS_CREATION_NEW_BUILDING_CHANGE_VALUE:
      const newForm = {
        ...state.newBuildingForm,
      };
      newForm[action.payload.attribute] = action.payload.value;

      return {
        ...state,
        newBuildingForm: newForm,
      };

    case WORK_ORDERS_CREATION_NEW_BUILDING_SUCCESS:
      return {
        ...state,
        newBuildingForm: {
          ...state.newBuildingForm,
          name: action.payload.name !== null ? action.payload.name : '',
          streetAddress: action.payload.location.street_address_1,
          city: action.payload.location.city,
          state: action.payload.location.state,
          zipCode: action.payload.location.zip,
        },
        selectedBuildingId: action.payload.id,
      };

    case WORK_ORDERS_CREATION_BUILDING_LOCATION_UPDATE_SUCCESS:
      return {
        ...state,
        newBuildingForm: {
          ...state.newBuildingForm,
          streetAddress: action.payload.location.street_address_1,
          city: action.payload.location.city,
          state: action.payload.location.state,
          zipCode: action.payload.location.zip,
        },
      };

    case WORK_ORDERS_WO_ADD:
      return {
        ...state,
        selectedBuildingId: 0,
      };

    default:
      return state;
  }
}
