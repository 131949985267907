import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './SubmittalsTable.css';
import { Table } from 'react-bootstrap';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import Date from 'components/shared/text/Date';
import SubmittalModalContainer from 'components/admin/projects/details/project-submittal-log/SubmittalModalContainer';
import Submittal from 'domain/submittal';
import { getCurrentUser } from 'selectors/authentication';
import {
  getSubmittals,
  getCurrentStatus,
  getTotal,
  getCurrentPage,
  getPageSize,
} from 'components/admin/submittals/store/selectors';
import { loadProjectRequest } from 'components/admin/projects/details/store/actions';
import { openSubmittal } from 'components/admin/projects/details/project-submittal-log/store/actions';
import { getDarkMode } from 'selectors/theme';

class SubmittalsTable extends Component {
  static propTypes = {
    onPageChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { showModal: false };
  }

  open = (submittal) => {
    const { currentUser, dispatch } = this.props;
    this.setState({ showModal: true });
    dispatch(loadProjectRequest(submittal.project_id));
    dispatch(openSubmittal(submittal.project_id, submittal.id, currentUser));
  };

  close = () => {
    const { currentPage, currentStatus, onPageChange } = this.props;
    this.setState({ showModal: false });
    onPageChange(currentPage, currentStatus);
  };

  addlAssignee = (submittal) => {
    const { currentUser } = this.props;
    return Submittal.addlAssignee(submittal, currentUser);
  };

  dueDate = (submittal) => {
    const addlAssignee = this.addlAssignee(submittal);
    return addlAssignee
      ? addlAssignee.due_date
      : submittal.extended_due_date
      ? submittal.extended_due_date
      : submittal.due_date;
  };

  handleGenerateLink = (id) => {
    const { submittals } = this.props;
    const submittal = submittals.find((submittal) => submittal.id === id);
    return `projects/${submittal.project_id}?submittalId=${id}`;
  };

  mapSubmittals = () => {
    const { currentStatus } = this.props;
    let currentCsiCodeId = null;
    return [].concat(
      ...this.props.submittals.map((submittal) => {
        const dueDate = this.dueDate(submittal);
        const link = this.handleGenerateLink(submittal.id);
        let row = [];
        if (currentCsiCodeId !== submittal.csi_code.id) {
          currentCsiCodeId = submittal.csi_code.id;
          row.push(
            <tr key={`csi-${currentCsiCodeId}`} className="csi-code-row">
              <td></td>
              <td></td>
              <td>{submittal.csi_code.division}</td>
              <td>{submittal.csi_code.label}</td>
              <td></td>
            </tr>
          );
        }
        row.push(
          <tr
            key={submittal.id}
            className="selectable link-tab"
            onClick={(e) => {
              e.preventDefault();
              this.open(submittal, currentStatus);
            }}
          >
            <td>
              <a href={link}>{submittal.project_number}</a>
            </td>
            <td>
              <a href={link}>{submittal.project_name}</a>
            </td>
            <td>
              <a href={link}>{submittal.formatted_number}</a>
            </td>
            <td>
              <a href={link}>{submittal.title || '-'}</a>
            </td>
            <td className="due-date">
              <a href={link}>
                {dueDate ? <Date value={dueDate} format="MM/DD/YYYY" /> : '-'}
              </a>
            </td>
          </tr>
        );

        return row;
      })
    );
  };

  render() {
    const {
      submittals,
      currentStatus,
      total,
      currentPage,
      pageSize,
      onPageChange,
      darkMode,
    } = this.props;
    const { showModal } = this.state;
    const headers = ['PROJECT NO', 'PROJECT NAME', 'COST CODE', 'TITLE', 'DUE'];
    return (
      <div className="submittals-table-container">
        <Table
          className={`simple-table submittals-table ${
            darkMode ? 'dark-mode' : ''
          }`}
        >
          <colgroup>
            {headers.map((element, index) => {
              return <col key={index + 1} />;
            })}
          </colgroup>

          <thead>
            <tr>
              {headers.map((element, index) => {
                return (
                  <th key={index}>
                    <span>{element}</span>
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {submittals.length === 0 ? (
              <tr>
                <td className="td-empty-table" colSpan={headers.length}>
                  No Submittals added yet.
                </td>
              </tr>
            ) : (
              this.mapSubmittals()
            )}
          </tbody>
        </Table>
        <div className="pagination-container">
          <SimplePagination
            currentPage={currentPage}
            pageSize={pageSize}
            total={total}
            onPageChange={(page) => onPageChange(page, currentStatus)}
          />
        </div>

        {showModal && <SubmittalModalContainer onClose={this.close} />}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    submittals: getSubmittals(state),
    currentStatus: getCurrentStatus(state),
    total: getTotal(state),
    currentPage: getCurrentPage(state),
    pageSize: getPageSize(state),
    darkMode: getDarkMode(state),
  };
})(SubmittalsTable);
