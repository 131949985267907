import { push } from 'react-router-redux';
import { contractorsApi } from 'services/api/contractors-api';
import { tradesApi } from 'services/api/trades-api';
import { serviceAreasApi } from 'services/api/service-areas-api';
import { unionsApi } from 'services/api/union-api';
import { goToTeam } from 'actions/admin/team/contractor-team';
import {
  CONTRACTORS_CONTACTS_MODAL_SHOW,
  CONTRACTORS_CONTRACTOR_LOAD_SUCCESS,
  CONTRACTORS_CONTRACTOR_WORK_ORDERS_LOAD_SUCCESS,
  CONTRACTORS_CONTRACTOR_WORK_ORDERS_PAGE_CHANGE,
  CONTRACTORS_CONTRACTOR_PROJECTS_LOAD_SUCCESS,
  CONTRACTORS_EDIT_FORM_LOAD_FROM_CURRENT,
  CONTRACTORS_INVOICES_SET,
  CONTRACTORS_LOAD_SUCCESS,
  CONTRACTORS_NEW_CLEAR_DATA,
  CONTRACTORS_NEW_FORM_INVALID,
  CONTRACTORS_NEW_FORM_VALID,
  CONTRACTORS_NEW_FORM_VALUE_CHANGE,
  CONTRACTORS_NEW_PARAMETERS_LOAD_SUCCESS,
  CONTRACTORS_NEW_SERVICE_AREA_SELECT,
  CONTRACTORS_NEW_TRADE_SELECT,
  CONTRACTORS_NEW_UNIONS_SELECT,
  CONTRACTORS_USERS_SET,
  CONTRACTORS_MSA_SIGNING_URL_CHANGE,
  CONTRACTORS_MSA_SIGNING_MODAL_HIDE,
  CONTRACTORS_SET_PAGINATION_DATA,
  CONTRACTORS_CONTRACTOR_ROLES_LOAD_SUCCESS,
  CONTRACTORS_CONTRACTOR_INVITE_USER_SHOW,
  CONTRACTORS_CONTRACTOR_USER_ADD,
  CONTRACTORS_INVITE_FORM_CLEAR,
  CONTRACTORS_SET_OTHER_UNION,
  CONTRACTORS_SHOW_OTHER_UNION,
} from 'actions/admin/contractors/action-types';
import { showErrorMessage } from 'actions/messages';
import {
  setCurrentPage,
  setPaginationPageSize,
  setPaginationTotal,
} from 'components/shared/pagination/table/store/actions';
import { rolesApi } from 'services/api/roles-api';
import { COMPANY_TYPES } from 'domain/company-type-enum';
import { USER_ROLES, USER_ROLE_LABELS } from 'domain/user-role-enum';

export const baseRoutePath = '/admin/contractors';
export const signEasyBasePath = 'dl.getsigneasy.com';

export function changePage(newPage) {
  return {
    type: CONTRACTORS_CONTRACTOR_WORK_ORDERS_PAGE_CHANGE,
    payload: newPage,
  };
}

export function editContractor(contractorId) {
  return (dispatch) => {
    dispatch(push(`${baseRoutePath}/${contractorId}/edit`));
  };
}

export function getContractorInvoices(contractorId, page = 1) {
  return (dispatch) => {
    return contractorsApi.getInvoices(contractorId, page).then((response) => {
      if (response.ok) {
        dispatch(setContractorInvoices(response.contractorInvoices.content));
        dispatch(setCurrentPage(page));
        dispatch(setPaginationTotal(response.contractorInvoices.total));
        dispatch(setPaginationPageSize(response.contractorInvoices.pageSize));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function goToContractor(contractorId) {
  return (dispatch) => {
    dispatch(push(`${baseRoutePath}/${contractorId}`));
  };
}

export function goToContractors() {
  return (dispatch) => {
    dispatch(push(`${baseRoutePath}`));
  };
}

export function loadContractorSuccess(contractor) {
  return {
    type: CONTRACTORS_CONTRACTOR_LOAD_SUCCESS,
    payload: contractor,
  };
}

export function loadContractorsRequest(page, label, tradesFilter = '') {
  return (dispatch) => {
    return contractorsApi
      .getContractors([tradesFilter], [], page, label)
      .then((response) => {
        if (response.ok) {
          dispatch(loadContractorsSuccess(response.contractors));

          if (page) {
            dispatch(
              setContractorsPaginationData(
                page,
                response.total,
                response.pageSize
              )
            );
          }
        } else {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
}

export function loadContractorsSuccess(contractors) {
  return {
    type: CONTRACTORS_LOAD_SUCCESS,
    payload: contractors,
  };
}

export function setContractorsPaginationData(page, total, pageSize) {
  return {
    type: CONTRACTORS_SET_PAGINATION_DATA,
    payload: { page: page, total: total, pageSize: pageSize },
  };
}

export function loadContractorRequest(contractorId) {
  return (dispatch) => {
    return contractorsApi.getContractor(contractorId).then((response) => {
      if (response.ok) {
        dispatch(loadContractorSuccess(response.contractor));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function loadNewContractorParametersRequest(
  serviceAreas,
  trades,
  unions
) {
  return (dispatch) => {
    let promises = [];

    if (serviceAreas === undefined || serviceAreas.length === 0) {
      promises.push(serviceAreasApi.getServiceAreas());
    }

    if (trades === undefined || trades.length === 0) {
      promises.push(tradesApi.getTrades());
    }

    if (unions === undefined || unions.length === 0) {
      promises.push(unionsApi.getUnions());
    }

    if (promises.length === 0) {
      // All data already loaded
      return Promise.resolve([]);
    } else {
      return Promise.all(promises).then((values) => {
        const result = {};
        values.forEach((value) => {
          if (value.ok) {
            checkParameterResultProperty(value, 'serviceAreas', result);
            checkParameterResultProperty(value, 'unions', result);
            checkParameterResultProperty(value, 'trades', result);
          }
        });

        dispatch(loadNewContractorParametersSuccess(result));
      });
    }
  };
}

function checkParameterResultProperty(value, attribute, result) {
  if (value.hasOwnProperty(attribute)) {
    result[attribute] = value[attribute];
  }
}

export function loadNewContractorParametersSuccess(result) {
  return {
    type: CONTRACTORS_NEW_PARAMETERS_LOAD_SUCCESS,
    payload: result,
  };
}

export function loadWorkOrdersRequest(
  contractorId,
  page,
  workOrderStatus,
  critical
) {
  return (dispatch) => {
    return contractorsApi
      .getWorkOrders(contractorId, page, workOrderStatus, critical)
      .then((response) => {
        if (response.ok) {
          dispatch(loadWorkOrdersSuccess(response.workOrders));
          dispatch(changePage(page));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadProjectsRequest(contractorId, page, projectStatus) {
  return (dispatch) => {
    return contractorsApi
      .getProjects(contractorId, page, projectStatus)
      .then((response) => {
        if (response.ok) {
          dispatch(loadProjectsSuccess(response.projects));
          dispatch(changePage(page));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadWorkOrdersSuccess(workOrders) {
  return {
    type: CONTRACTORS_CONTRACTOR_WORK_ORDERS_LOAD_SUCCESS,
    payload: workOrders,
  };
}

export function loadProjectsSuccess(projects) {
  return {
    type: CONTRACTORS_CONTRACTOR_PROJECTS_LOAD_SUCCESS,
    payload: projects,
  };
}

export function newContractor() {
  return (dispatch) => {
    dispatch(clearContractorData());
    dispatch(push(baseRoutePath + '/new'));
  };
}

export function clearContractorData() {
  return {
    type: CONTRACTORS_NEW_CLEAR_DATA,
  };
}

export function selectServiceArea(value) {
  return {
    type: CONTRACTORS_NEW_SERVICE_AREA_SELECT,
    payload: value,
  };
}

export function selectTrade(value) {
  return {
    type: CONTRACTORS_NEW_TRADE_SELECT,
    payload: value,
  };
}

export function selectUnion(value, unions, selectedUnionsId) {
  let newUnions = [];
  if (selectedUnionsId.find((element) => element === value) !== undefined) {
    newUnions = selectedUnionsId.filter((element) => element !== value);
  } else {
    newUnions = selectedUnionsId.concat(value);
  }

  return {
    type: CONTRACTORS_NEW_UNIONS_SELECT,
    payload: newUnions,
  };
}

function setContractorInvoices(contractorInvoices) {
  return {
    type: CONTRACTORS_INVOICES_SET,
    payload: contractorInvoices,
  };
}

function setContractorUsers(users) {
  return {
    type: CONTRACTORS_USERS_SET,
    payload: users,
  };
}

/**
 *
 * @param form
 * @param selectedServiceAreasId
 * @param selectedTradesId
 * @param selectedUnionsId
 * @param sendInvitation
 * @param contractorId - Id of the contractor under edition. If undefined, the it's a new contractor.
 * @return {Function}
 */
export function submitFormRequest(
  form,
  selectedServiceAreasId,
  selectedTradesId,
  selectedUnionsId,
  sendInvitation,
  contractorId
) {
  return (dispatch) => {
    // Validate form
    const requiredFields = [
      'companyName',
      'firstName',
      'lastName',
      'email',
      'phoneNumber',
      'streetAddress',
      'city',
      'state',
      'zipCode',
    ];

    if (form.confirmed) {
      requiredFields.push('licenseNumber');
    }

    const invalidFields = [];
    requiredFields.forEach((attribute) => {
      if (form[attribute] === '') {
        invalidFields.push(attribute);
      }
    });

    if (invalidFields.length > 0) {
      dispatch(
        invalidForm(
          invalidFields,
          'The outlined inputs should be added in order to add a contractor.'
        )
      );

      return null;
    } else {
      dispatch(validForm());

      const data = {
        name: form.companyName,
        licenseNumber: form.licenseNumber,
        contact: {
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email,
          phoneNumber: form.phoneNumber,
        },
        location: {
          streetAddress: form.streetAddress,
          unitNumber: form.unitNumber,
          city: form.city,
          state: form.state,
          zipCode: form.zipCode,
        },
        tradesId: selectedTradesId,
        serviceAreasId: selectedServiceAreasId,
        unionsId: selectedUnionsId,
        confirmed: form.confirmed,
        otherUnion: form.otherUnion,
      };

      let promise;
      if (contractorId !== undefined) {
        promise = contractorsApi.updateContractor(
          contractorId,
          data,
          sendInvitation
        );
      } else {
        promise = contractorsApi.createContractor(data, sendInvitation);
      }

      return promise.then((response) => {
        if (response.ok) {
          if (contractorId !== undefined) {
            dispatch(loadContractorSuccess(response.contractor));
            dispatch(goToTeam());
          }
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
    }
  };
}

export function invalidForm(invalidFields, message) {
  return {
    type: CONTRACTORS_NEW_FORM_INVALID,
    payload: { invalidFields, message },
  };
}

export function showContactsModal(value) {
  return {
    type: CONTRACTORS_CONTACTS_MODAL_SHOW,
    payload: value,
  };
}

export function showContactsRequest(contractorId, users) {
  return (dispatch) => {
    if (users.length > 0) {
      dispatch(showContactsModal(true));
    } else {
      return contractorsApi.getUsers(contractorId).then((response) => {
        if (response.ok) {
          dispatch(setContractorUsers(response.users));
          dispatch(showContactsModal(true));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
    }
  };
}

export function validForm() {
  return {
    type: CONTRACTORS_NEW_FORM_VALID,
  };
}

export function updateFormFromCurrentContractor(currentContractor) {
  return {
    type: CONTRACTORS_EDIT_FORM_LOAD_FROM_CURRENT,
    payload: currentContractor,
  };
}

export function updateFormValue(attribute, value) {
  return {
    type: CONTRACTORS_NEW_FORM_VALUE_CHANGE,
    payload: { attribute, value },
  };
}

export function setMsaSigningUrl(url) {
  return {
    type: CONTRACTORS_MSA_SIGNING_URL_CHANGE,
    payload: url,
  };
}

export function hideMsaSigningModal() {
  return {
    type: CONTRACTORS_MSA_SIGNING_MODAL_HIDE,
  };
}

export function showSignMsaModal(contractorId) {
  return (dispatch) => {
    contractorsApi.getMsaSigningUrl(contractorId, '').then((response) => {
      if (response.ok) {
        const signingUrl = response.data.url.replace(
          signEasyBasePath,
          `${signEasyBasePath}/#`
        );
        dispatch(setMsaSigningUrl(signingUrl));
      }
    });
  };
}

export function loadRolesRequest() {
  return (dispatch) => {
    return rolesApi.getRoles(COMPANY_TYPES.CONTRACTOR).then((response) => {
      if (response.ok) {
        dispatch(loadRolesSuccess(response.permissions));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadRolesSuccess(roles) {
  return {
    type: CONTRACTORS_CONTRACTOR_ROLES_LOAD_SUCCESS,
    payload: roles,
  };
}

export function setShowInviteUserContractorModal(value) {
  return {
    type: CONTRACTORS_CONTRACTOR_INVITE_USER_SHOW,
    payload: value,
  };
}

export function submitInviteUserFormRequest(form, contractorId, roles) {
  return (dispatch) => {
    const requiredFields = [
      'firstName',
      'lastName',
      'email',
      'phoneNumber',
      'roleId',
    ];
    const invalidFields = [];

    requiredFields.forEach((attribute) => {
      if (form[attribute] === '' || form[attribute] === null) {
        invalidFields.push(attribute);
      }
    });

    if (invalidFields.length > 0) {
      dispatch(
        invalidForm(
          invalidFields,
          'The outlined inputs should be added in order to invite a new user.'
        )
      );

      return null;
    } else {
      const role = roles.find((role) => role.id === form.roleId);
      form.title = USER_ROLE_LABELS[role.name];
      const accountAdminRole = form.accountAdmin
        ? roles.find((role) => role.name === USER_ROLES.ACCOUNT_ADMIN)
        : null;
      form.rolesId = [
        form.roleId,
        accountAdminRole ? accountAdminRole.id : null,
      ];
      dispatch(validForm());
      return contractorsApi.inviteUser(contractorId, form).then((response) => {
        if (response.ok) {
          dispatch(addUser(response.user));
          dispatch(setShowInviteUserContractorModal(false));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
    }
  };
}

export function addUser(user) {
  return {
    type: CONTRACTORS_CONTRACTOR_USER_ADD,
    payload: user,
  };
}

export function clearContractorForm() {
  return {
    type: CONTRACTORS_INVITE_FORM_CLEAR,
  };
}

export function setContractorOtherUnion(value) {
  return {
    type: CONTRACTORS_SET_OTHER_UNION,
    payload: value,
  };
}

export function showFormOtherUnion(show) {
  return {
    type: CONTRACTORS_SHOW_OTHER_UNION,
    payload: show,
  };
}
