const keys = {};

export const WorkOrderStatusKeys = {
  DRAFT: 'draft',
  PENDING: 'pending',
  PLM: 'plm',
  WOA: 'woa',
  IN_PROGRESS: 'in_progress',
  COMPLETE: 'complete',
  CLOSED: 'closed',
  DECLINED: 'declined',
  UNKNOWN: 'unknown',
};

export class WorkOrderStatusEnum {
  constructor(status, label) {
    this.status = status;
    this.label = label;

    keys[status] = this;
  }

  static create(status) {
    if (keys[status]) {
      return keys[status];
    }

    return WorkOrderStatusEnum.UNKNOWN;
  }

  equals(workOrderStatusEnum) {
    return this.status === workOrderStatusEnum.status;
  }
}

WorkOrderStatusEnum.DRAFT = new WorkOrderStatusEnum('draft', 'Draft');
WorkOrderStatusEnum.PENDING = new WorkOrderStatusEnum('pending', 'Pending');
WorkOrderStatusEnum.PLM = new WorkOrderStatusEnum('plm', 'PLM');
WorkOrderStatusEnum.WOA = new WorkOrderStatusEnum('woa', 'WOA');
WorkOrderStatusEnum.IN_PROGRESS = new WorkOrderStatusEnum(
  'in_progress',
  'In Progress'
);
WorkOrderStatusEnum.COMPLETE = new WorkOrderStatusEnum('complete', 'Complete');
WorkOrderStatusEnum.CLOSED = new WorkOrderStatusEnum('closed', 'Closed');
WorkOrderStatusEnum.DECLINED = new WorkOrderStatusEnum('declined', 'Declined');
WorkOrderStatusEnum.UNKNOWN = new WorkOrderStatusEnum('unknown', 'Unknown');
