/* eslint-disable default-case */
import axios from 'axios';
import BaseApi from './base-api';

class ProjectPhotosApi extends BaseApi {
  getDetail(projectId, id, type) {
    const model = this.getModel(type);
    let url = `${this.baseUrl}/projects/${projectId}/${model}s/${id}`;

    return axios
      .get(url)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  getFixedAlbums(projectId) {
    let url = `${this.baseUrl}/projects/${projectId}/project_photo_albums/fixed_albums`;

    return axios
      .get(url)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  getAll(projectId, type = 'album', filters = {}, loadingDisabled = false) {
    const model = this.getModel(type);
    const params = this.getSearchParams(filters, type);
    const url = `${this.baseUrl}/projects/${projectId}/${model}s${params}`;
    const config = { loadingDisabled };

    return axios
      .get(url, config)
      .then((response) => ({
        ok: true,
        [`${type}s`]: response.data,
        page: filters[`${type}sPage`],
        total: Number(response.headers.total),
      }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  create(projectId, form, type) {
    const model = this.getModel(type);
    const url = `${this.baseUrl}/projects/${projectId}/${model}s`;

    let data;
    if (type === 'album') {
      data = this.savePayload(model, form);
    } else {
      data = new FormData();
      data.append(`${model}[project_photo_album_id]`, form.albumId);
    }

    const config = { loadingDisabled: type !== 'album' };

    return axios
      .post(url, data, config)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  save(projectId, id, type, form) {
    const model = this.getModel(type);

    const url = `${this.baseUrl}/projects/${projectId}/${model}s/${id}`;
    const headers = {
      headers: { 'Content-Type': 'multipart/form-data' },
      loadingDisabled: type !== 'album',
    };
    const data = this.savePayload(model, form);

    return axios
      .put(url, data, headers)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  toggleFavorites(projectId, ids, value, type) {
    const model = this.getModel(type);
    const url = `${this.baseUrl}/projects/${projectId}/${model}s/favorite`;

    const data = new FormData();
    data.append(`${model}[add]`, value);
    ids.forEach((id) => {
      data.append(`${model}[${model}s_ids][]`, id);
    });

    return axios
      .post(url, data)
      .then(() => ({ ok: true }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  changeStatus(projectId, status, ids, type) {
    const model = this.getModel(type);
    const url = `${this.baseUrl}/projects/${projectId}/${model}s/change_status`;

    const data = new FormData();
    data.append(`${model}[action]`, status);
    ids.forEach((id) => {
      data.append(`${model}[${model}s_ids][]`, id);
    });

    return axios
      .post(url, data)
      .then(() => ({ ok: true }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  moveToAlbum(projectId, albumId, ids, type) {
    const model = this.getModel(type);
    const url = `${this.baseUrl}/projects/${projectId}/${model}s/move`;

    const data = new FormData();
    if (albumId) data.append(`${model}[project_photo_album_id]`, albumId);
    ids.forEach((id) => {
      data.append(`${model}[${model}s_ids][]`, id);
    });

    return axios
      .put(url, data)
      .then(() => ({ ok: true }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  order(projectId, ids, type) {
    const model = this.getModel(type);
    const url = `${this.baseUrl}/projects/${projectId}/${model}s/order`;

    const data = new FormData();
    ids.forEach((id) => {
      data.append(`${model}[order_ids][]`, id);
    });

    return axios
      .put(url, data)
      .then(() => ({ ok: true }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  update(projectId, type, form) {
    const model = this.getModel(type);

    const url = `${this.baseUrl}/projects/${projectId}/${model}s/update_all`;
    const data = this.savePayload(model, form);

    return axios
      .put(url, data)
      .then((_) => ({ ok: true }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  erase(projectId, ids, type) {
    const model = this.getModel(type);

    const url = `${this.baseUrl}/projects/${projectId}/${model}s/delete`;
    const data = new FormData();
    ids.forEach((id) => data.append(`${model}[${model}s_ids][]`, id));

    return axios
      .post(url, data)
      .then((_) => ({ ok: true }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  download(projectId, { albumIds, photoIds }) {
    console.log('DOWNLOAD', projectId, { albumIds, photoIds });
    const url = `${this.baseUrl}/projects/${projectId}/project_photo_albums/download`;

    const data = new FormData();
    albumIds.forEach((id) =>
      data.append(`project_photo_album[project_photo_albums_ids][]`, id)
    );
    photoIds.forEach((id) =>
      data.append(`project_photo_album[project_photos_ids][]`, id)
    );

    return axios
      .post(url, data)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  email(projectId, { albumIds, photoIds }, recipients, subject, message) {
    const url = `${this.baseUrl}/projects/${projectId}/project_photo_albums/email`;
    let data = new FormData();
    recipients.map((item) =>
      data.append(`project_photo_album[emails][]`, item)
    );
    data.append(`project_photo_album[subject]`, subject);
    data.append(`project_photo_album[message]`, message);

    albumIds.forEach((id) =>
      data.append(`project_photo_album[project_photo_albums_ids][]`, id)
    );
    photoIds.forEach((id) =>
      data.append(`project_photo_album[project_photos_ids][]`, id)
    );

    return axios
      .put(url, data)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  // AUX
  getModel(type) {
    return type !== 'photo' ? 'project_photo_album' : 'project_photo';
  }

  savePayload(model, form) {
    const apiPayload = new FormData();
    const isAlbum = model === 'project_photo_album';

    if (form[`${isAlbum ? 'album' : 'photo'}Ids`]) {
      form[`${isAlbum ? 'album' : 'photo'}Ids`].forEach((id) => {
        apiPayload.append(`${model}[${model}s_ids][]`, id);
      });
    }

    if (isAlbum && typeof form.title !== 'undefined')
      apiPayload.append(`${model}[title]`, form.title);
    if (typeof form.description !== 'undefined')
      apiPayload.append(`${model}[description]`, form.description);
    if (typeof form.albumId !== 'undefined')
      apiPayload.append(`${model}[project_photo_album_id]`, form.albumId);

    // permissions
    if (
      isAlbum &&
      typeof form.permission !== 'undefined' &&
      form.permission !== null
    ) {
      apiPayload.append(`${model}[permission]`, form.permission);
      if (form.permission === 'shared') {
        form.permissionUsers.forEach(({ id }) => {
          apiPayload.append(`${model}[permission_user_ids][]`, id);
        });
      }
    }

    // cover
    if (isAlbum && form.cover) {
      if (form.cover.id) {
        apiPayload.append(`${model}[cover_attributes][id]`, form.cover.id);
        if (form.cover._destroy) {
          apiPayload.append(`${model}[cover_attributes][_destroy]`, true);
        }
      } else if (form.cover.file) {
        apiPayload.append(
          `${model}[cover_attributes][image]`,
          form.cover.file,
          form.cover.file.name
        );
      }
    }

    // photo (image or video)
    if (!isAlbum && form.photo) {
      const isVideo = form.photo.isVideo;
      const type = isVideo ? 'video' : 'image';
      apiPayload.append(`${model}[${type}_attributes][name]`, form.photo.name);
    }

    // project_tags
    if (form.tags) {
      form.tags.forEach(({ value: id }) => {
        apiPayload.append(`${model}[project_tag_ids][]`, id);
      });
    }
    if (form.deletedTags) {
      form.deletedTags.forEach(({ value: id }) => {
        apiPayload.append(`${model}[deleted_project_tag_ids][]`, id);
      });
    }

    // users
    if (form.users) {
      form.users
        .filter((u) => !u.disabled)
        .forEach(({ id }) => {
          apiPayload.append(`${model}[user_ids][]`, id);
        });
    }
    if (form.deletedUsers) {
      form.deletedUsers.forEach(({ id }) => {
        apiPayload.append(`${model}[deleted_user_ids][]`, id);
      });
    }

    // project_sites
    if (form.sites) {
      form.sites.forEach(({ value: id }) => {
        apiPayload.append(`${model}[project_site_ids][]`, id);
      });
    }
    if (form.deletedSites) {
      form.deletedSites.forEach(({ value: id }) => {
        apiPayload.append(`${model}[deleted_project_site_ids][]`, id);
      });
    }

    // project_areas
    if (form.areas) {
      form.areas.forEach(({ value: id }) => {
        apiPayload.append(`${model}[project_area_ids][]`, id);
      });
    }
    if (form.deletedAreas) {
      form.deletedAreas.forEach(({ value: id }) => {
        apiPayload.append(`${model}[deleted_project_area_ids][]`, id);
      });
    }

    // csi_codes
    if (form.trades) {
      form.trades.forEach(({ value: id }) => {
        apiPayload.append(`${model}[csi_code_ids][]`, id);
      });
    }
    if (form.deletedTrades) {
      form.deletedTrades.forEach(({ value: id }) => {
        apiPayload.append(`${model}[deleted_csi_code_ids][]`, id);
      });
    }

    return apiPayload;
  }

  getSearchParams(filters, type) {
    const isAlbum = type === 'album';
    const params = [];

    if (!filters.all) {
      params.push(`per_page=${filters.perPage || 8}`);
      params.push(`page=${filters[`${type}sPage`]}`);
    }
    if (filters.all) params.push(`all=true`);
    if (filters.detailData) params.push(`detail=true`);
    if (filters[`${type}Ids`]) {
      params.push(`by_ids=${filters[`${type}Ids`].join(',')}`);
    }
    if (isAlbum && filters.search && filters.search.length > 0) {
      params.push(`by_text=${filters.search}`);
    }
    if (!isAlbum && filters.type) {
      params.push(`by_content_type=${filters.type}`);
    }
    if (filters.albumId) {
      params.push(`by_project_photo_album_id=${filters.albumId}`);
    }
    if (filters.from) params.push(`by_created_date_from=${filters.from}`);
    if (filters.to) params.push(`by_created_date_to=${filters.to}`);
    if (filters.trades && filters.trades.length > 0) {
      params.push(
        `by_csi_code=${filters.trades.map(({ value }) => value).join(',')}`
      );
    }
    if (filters.sites && filters.sites.length > 0) {
      params.push(
        `by_project_site=${filters.sites.map(({ value }) => value).join(',')}`
      );
    }
    if (filters.areas && filters.areas.length > 0) {
      params.push(
        `by_project_area=${filters.areas.map(({ value }) => value).join(',')}`
      );
    }
    if (filters.users && filters.users.length > 0) {
      params.push(
        `by_tagged_user=${filters.users.map(({ value }) => value).join(',')}`
      );
    }
    if (filters.tags && filters.tags.length > 0) {
      params.push(
        `by_project_tag=${filters.tags.map(({ value }) => value).join(',')}`
      );
    }
    if (filters.favorites) params.push(`by_favorites_user=true`);
    if (filters.liked) params.push(`by_liked_user=true`);
    if (filters.status) params.push(`by_status=${filters.status}`);

    if (filters.sort && filters.sort !== 'all') {
      if (filters.sort.includes('title')) {
        params.push(`sort_by_title=${filters.sort.replace('_title', '')}`);
      } else {
        params.push(`sort_by_created_at=${filters.sort}`);
      }
    }

    if (params.length > 0) return '?' + params.join('&');
    return '';
  }
}

export const projectPhotosApi = new ProjectPhotosApi();
