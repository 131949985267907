import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './SubmittalDocuments.css';
import DocumentLinkBluebeam from 'components/shared/document/DocumentLinkBluebeam';
import ImageUpload from 'components/shared/image-upload/ImageUpload';
import DirectS3Uploader from 'components/shared/document-upload/DirectS3Uploader';
import { getSubmittalForm } from 'components/admin/projects/details/project-submittal-log/store/selectors';
import { setDirectUploadInProcess } from 'components/admin/projects/details/store/actions';
import FormControlBlock from 'components/shared/form/FormControlBlock';

class SubmittalDocuments extends Component {
  static propTypes = {
    submittal: PropTypes.shape({}),
    submittalAddlAssignee: PropTypes.shape({}),
    form: PropTypes.shape({}).isRequired,
    readOnly: PropTypes.bool,
    fileType: PropTypes.oneOf(['req', 'res']).isRequired,
    handleFormChange: PropTypes.func.isRequired,
    handleFormDeleteFile: PropTypes.func.isRequired,
  };

  static defaultProps = {
    submittal: null,
    submittalAddlAssignee: null,
  };

  constructor(props) {
    super(props);

    switch (props.fileType) {
      case 'req':
        this.imgsAttr = 'reqImages';
        this.docsAttr = 'reqDocs';
        this.imgsDelAttr = 'reqImagesDelete';
        this.docsDelAttr = 'reqDocsDelete';
        this.submittalImgsAttr = 'request_images';
        this.submittalDocsAttr = 'request_documents';
        break;
      case 'res':
        this.imgsAttr = 'resImages';
        this.docsAttr = 'resDocs';
        this.imgsDelAttr = 'resImagesDelete';
        this.docsDelAttr = 'resDocsDelete';
        this.submittalImgsAttr = 'response_images';
        this.submittalDocsAttr = 'response_documents';
        break;
      default:
    }
  }

  componentDidUpdate() {
    this.initFiles();
  }

  documentType = (file) => {
    return file.document && file.document.url ? 'document' : 'document_direct';
  };

  mapFiles = (files, type) => {
    return (files || []).map((file) => {
      const documentType = type || this.documentType(file);
      return {
        id: file.id,
        url: file[documentType].url,
        preview: file[documentType].gallery && file[documentType].gallery.url,
      };
    });
  };

  initFiles = () => {
    const { submittalAddlAssignee, form, fileType, readOnly } = this.props;

    if (!form.id || readOnly || this.initialized) {
      return;
    }
    this.initialized = true;

    if (submittalAddlAssignee && fileType === 'res') {
      this.props.handleFormChange(
        this.imgsAttr,
        this.mapFiles(submittalAddlAssignee[this.submittalImgsAttr], 'image')
      );
      this.props.handleFormChange(
        this.docsAttr,
        this.mapFiles(submittalAddlAssignee[this.submittalDocsAttr])
      );
    } else {
      this.props.handleFormChange(
        this.imgsAttr,
        this.mapFiles(form[this.submittalImgsAttr], 'image')
      );
      this.props.handleFormChange(
        this.docsAttr,
        this.mapFiles(form[this.submittalDocsAttr])
      );
    }
  };

  setImages = (files, imagesRef) => {
    let images = imagesRef.slice();
    files.forEach(function (val) {
      images.push({
        url: val.url || URL.createObjectURL(val),
        file: val,
      });
    });

    return images;
  };

  setSubmittalImages = (files) => {
    const images = this.setImages(files, this.props.form[this.imgsAttr]);
    this.props.handleFormChange(this.imgsAttr, images);
  };

  removeSubmittalImage = (index) => {
    const img = this.props.form[this.imgsAttr][index];
    this.props.form[this.imgsAttr].splice(index, 1);
    this.props.handleFormDeleteFile(this.imgsDelAttr, img);
    this.props.handleFormChange(this.imgsAttr, this.props.form[this.imgsAttr]);
  };

  dropDocuments = (files, docsRef) => {
    let docs = docsRef.slice();
    files.forEach(function (val) {
      docs.push({
        file: val,
        name: val.name,
        url: val.url,
      });
    });

    return docs;
  };

  dropSubmittalDocuments = (files) => {
    const docs = this.dropDocuments(files, this.props.form[this.docsAttr]);
    this.props.handleFormChange(this.docsAttr, docs);
  };

  removeSubmittalDocument = (index) => {
    const doc = this.props.form[this.docsAttr][index];
    this.props.form[this.docsAttr].splice(index, 1);
    this.props.handleFormDeleteFile(this.docsDelAttr, doc);
    this.props.handleFormChange(this.docsAttr, this.props.form[this.docsAttr]);
  };

  modelForFile = () => {
    const { submittalAddlAssignee } = this.props;

    return submittalAddlAssignee ? 'submittal_addl_assignees' : 'submittals';
  };

  uidForFile = () => {
    const { submittalAddlAssignee, form } = this.props;

    return submittalAddlAssignee
      ? submittalAddlAssignee.uid
        ? submittalAddlAssignee.uid
        : submittalAddlAssignee.id.toString()
      : form.uid
      ? form.uid
      : form.id.toString();
  };

  renderDocuments = () => {
    const { form, submittalAddlAssignee, readOnly } = this.props;

    let documents = (submittalAddlAssignee || form)[this.submittalDocsAttr];
    documents = (!readOnly && form[this.docsAttr]) || this.mapFiles(documents);

    return (
      (documents.length > 0 || !readOnly) && (
        <div className="submittal-documents">
          <FormControlBlock
            className="without-focus"
            label="Documents"
            control={
              <div className="submittal-resources-body">
                {documents.map((doc, index) => (
                  <DocumentLinkBluebeam
                    key={index}
                    file={doc}
                    onRemove={
                      !readOnly
                        ? () => this.removeSubmittalDocument(index)
                        : null
                    }
                  />
                ))}
                {!readOnly && (
                  <DirectS3Uploader
                    acceptedTypes="application/pdf, .doc, .docx, .xls, .xlsx"
                    model={this.modelForFile()}
                    modelIdentifier={this.uidForFile()}
                    apppendToState={this.dropSubmittalDocuments}
                    updateStateUploadInProcess={setDirectUploadInProcess}
                  />
                )}
              </div>
            }
          />
        </div>
      )
    );
  };

  renderImages = () => {
    const { form, submittalAddlAssignee, readOnly } = this.props;

    const images =
      (!readOnly && form[this.imgsAttr]) ||
      this.mapFiles(
        (submittalAddlAssignee || form)[this.submittalImgsAttr],
        'image'
      );

    return (
      (images.length > 0 || !readOnly) && (
        <div className="submittal-images">
          <FormControlBlock
            className="without-focus"
            label="Photos"
            control={
              <div className="submittal-resources-body">
                <ImageUpload
                  extensions={['.jpg', '.jpeg', '.gif', '.png', '.heic']}
                  images={images}
                  readOnly={readOnly}
                  onRemove={(index) => this.removeSubmittalImage(index)}
                  onUpload={(files) => this.setSubmittalImages(files)}
                />
              </div>
            }
          />
        </div>
      )
    );
  };

  render() {
    const documents = this.renderDocuments();
    const images = this.renderImages();

    return (
      !!(documents || images) && (
        <div className="submittal-resources">
          {documents}
          {images}
        </div>
      )
    );
  }
}

export default connect((state) => {
  return {
    form: getSubmittalForm(state),
  };
})(SubmittalDocuments);
