import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormModal from 'components/shared/modal/FormModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import doneIcon from 'images/done.svg';
import doneIconDark from 'images/doneDarkMode.svg';
import './AddProjectReportLog.css';

class ProjectReportSubmittedReviewModal extends Component {
  render() {
    const { show, onClose, darkMode } = this.props;
    return (
      <FormModal
        id="project-report-save-draft-modal"
        className="project-report-save-draft-modal-view"
        show={show}
        onClose={onClose}
        subTitle=""
      >
        <div className="modal-body-section">
          <img
            className="done-icon"
            src={darkMode ? doneIconDark : doneIcon}
            alt="Done"
          />
          <div className="text-section">
            Your report has been submitted for review
          </div>
          <div className="buttons-section">
            <Button
              key="save-draft-button"
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Ok"
              onClick={onClose}
            />
          </div>
        </div>
      </FormModal>
    );
  }
}

ProjectReportSubmittedReviewModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  darkMode: PropTypes.bool,
};

ProjectReportSubmittedReviewModal.defaultProps = {
  show: false,
  onClose: () => null,
  darkMode: false,
};

export default ProjectReportSubmittedReviewModal;
