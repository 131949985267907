import Meeting from 'domain/new-meeting';
import { useState } from 'react';
import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';
import {
  setInfoModalProjectMeetings,
  setCurrentMeeting,
  setCurrentMeetingInstance,
  setMeetingsModalShow,
  updateMeetingsParent,
  setInstanceFormShow,
  setFormMeetingInstance,
  getMeetingsInstance,
  setActionItems,
  setSubmitCorrectionsModalShow,
  setFormProjectMeetings,
  setReviewCorrectionsModalShow,
  setViewCorrectionsModalShow,
  updateMeetingsInstance,
  setPendingCorrectionsErrorModalShow,
  setMeetingsInstancePdfViewerShow,
  getMeetingsInstanceAgendaBackup,
  setInstanceFormIsBackup,
  setPreviewModalShow,
} from './store/actions';
import { snakeToCamel } from '../../../../../services/utils/case-utils';
import newMeetingsPermissions from '../../../../../permissions/new-meetings-permissions';

const useParentsInstanceTable = (props) => {
  const { currentProject, dispatch, instanceForm, rows, currentUser } = props;
  const [instancePdfUrl, setInstancePdfUrl] = useState('');
  const [emailSubject, setEmalSubject] = useState('');
  const handleShowInfo = (meeting) => {
    dispatch(setInfoModalProjectMeetings(true));
    dispatch(setCurrentMeeting(meeting));
  };

  const handleEditMeeting = (meeting) => {
    dispatch(setMeetingsModalShow(true));
    dispatch(setCurrentMeeting(meeting));
  };
  const handleArchive = (meeting) => {
    const camelCaseMeeting = snakeToCamel(meeting);
    const form = {
      ...camelCaseMeeting,
      previousState: camelCaseMeeting.status,
      status: 'archived',
    };
    dispatch(updateMeetingsParent(currentProject.id, form.id, form));
  };
  const handleRestore = (meeting) => {
    const camelCaseMeeting = snakeToCamel(meeting);
    const form = {
      ...camelCaseMeeting,
      status: Meeting.parentStatusMap(camelCaseMeeting.previousState),
    };
    dispatch(updateMeetingsParent(currentProject.id, form.id, form));
  };

  const handleAddAgenda = (meetingParent) => {
    const values = Meeting.setNewInstanceFormForNewAgenda(meetingParent);

    dispatch(setCurrentMeeting(meetingParent));
    dispatch(setCurrentMeetingInstance(values));
    dispatch(setFormMeetingInstance(values));
    dispatch(setActionItems(values.meetingsActionItems));
    dispatch(setInstanceFormShow(true));
  };
  const handleAddMinutes = (meetingParent) => {
    const camelCaseParent = snakeToCamel(meetingParent);
    const values = Meeting.setNewInstanceFormForNewMinutes(meetingParent);
    const hasPendingMeetingsCorrections = Meeting.hasPendingCorrections(
      camelCaseParent.meetingsCorrections
    );
    if (hasPendingMeetingsCorrections) {
      dispatch(setFormProjectMeetings(camelCaseParent));
      dispatch(setPendingCorrectionsErrorModalShow(true));
      return;
    }
    dispatch(setCurrentMeeting(meetingParent));
    dispatch(setCurrentMeetingInstance(values));
    dispatch(setFormMeetingInstance(values));
    dispatch(setActionItems(values.meetingsActionItems));
    dispatch(setInstanceFormShow(true));
  };
  const handleConvertToMinutes = (instance) => {
    const hasPendingMeetingsCorrections = Meeting.hasPendingCorrections(
      instance.meetingsCorrections
    );
    if (hasPendingMeetingsCorrections) {
      dispatch(setPendingCorrectionsErrorModalShow(true));
      return;
    }
    const convertedInstance = {
      ...instance,
      status: 'draft_minutes',
      isAgenda: false,
      deletedDocuments: [],
      deletedImages: [],
      meetingsAgendaBackup: {
        ...instance,
        id: null,
        meetingsInstanceId: instanceForm.id,
        isAgenda: true,
      },
    };
    dispatch(
      updateMeetingsInstance(currentProject.id, instance.id, convertedInstance)
    );
  };
  const handleMail = (subjectitem, instance, type) => {
    setEmalSubject(subjectitem);
    dispatch(setFormMeetingInstance({ ...instance, documentType: type }));
    dispatch(setShowEmailModal(true));
  };

  const handleDownloadIcal = (parent) => {
    const icalData = parent.ical_data;
    const blob = new Blob([icalData], { type: 'text/calendar' });
    const url = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.download = `Meeting${parent.id}360Plus`;
    tempLink.href = url;
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(url);
  };

  const getFormattedNumberFromUrl = (url) => {
    console.log(url);
    const match = url.match(/_(\d+\.\w+)\.pdf$/);
    return match ? match[1] : null;
  };

  const isFormattedNumberMatching = (url, formattedNumber) => {
    const numberFromUrl = getFormattedNumberFromUrl(url);
    console.log(numberFromUrl);
    console.log(formattedNumber);
    return numberFromUrl === formattedNumber;
  };

  const handleDownloadPdf = (instance, type = 'all') => {
    dispatch(getMeetingsInstance(currentProject.id, instance.id));
    console.log('url', instance);
    let pdfUrl;
    if (type === 'agenda') {
      pdfUrl = Meeting.getInstanceAgendaPdfUrl(instance);
      if (!isFormattedNumberMatching(pdfUrl, instance.formatted_number)) {
        dispatch(setPreviewModalShow(true));
        return;
      }
    } else if (type === 'agendanotes') {
      pdfUrl = Meeting.getInstanceAgendaAndNotesPdfUrl(instance);
      if (!isFormattedNumberMatching(pdfUrl, instance.formatted_number)) {
        dispatch(setPreviewModalShow(true));
        return;
      }
    } else {
      pdfUrl = Meeting.getInstancePdfUrl(instance);
      if (!isFormattedNumberMatching(pdfUrl, instance.formatted_number)) {
        dispatch(setPreviewModalShow(true));
        return;
      }
    }
    if (!pdfUrl) {
      dispatch(setPreviewModalShow(true));
      return;
    }
    setInstancePdfUrl(pdfUrl);

    dispatch(setMeetingsInstancePdfViewerShow(true));
  };
  const handleClosePreviewModal = () => {
    dispatch(setPreviewModalShow(false));
  };
  const handleClosePdfViewer = () => {
    dispatch(setMeetingsInstancePdfViewerShow(false));
  };
  const instanceMenuOptions = (instance) => {
    let options = [];
    const snakeCaseInstance = snakeToCamel(instance);
    const hasNotes =
      snakeCaseInstance &&
      snakeCaseInstance.meetingsNotes &&
      snakeCaseInstance.meetingsNotes.notes &&
      snakeCaseInstance.meetingsNotes.notes.length > 0;
    const isDraftAgenda = snakeCaseInstance.status === 'draft_agenda';
    const isPublishedAgenda = snakeCaseInstance.status === 'published_agenda';
    const isRepublishedAgenda =
      snakeCaseInstance.status === 'republished_agenda';
    const isDraftMinutes = snakeCaseInstance.status === 'draft_minutes';
    const isPublishedMinutes = snakeCaseInstance.status === 'published_minutes';
    const isRepublishedMinutes =
      snakeCaseInstance.status === 'republished_minutes';
    const hasBackup =
      snakeCaseInstance.meetingsAgendaBackup &&
      snakeCaseInstance.meetingsAgendaBackup.id;
    const parent = rows.data.filter(
      (p) => p.id === snakeCaseInstance.meetingsParentId
    );
    if (isDraftAgenda || isPublishedAgenda || isRepublishedAgenda) {
      options = [
        ...options,
        {
          content: 'Email',
          subitems: [
            {
              content: 'Agenda',
              onClick: () => handleMail('agenda', instance, 'all'),
            },
          ],
        },
        {
          content: 'PDF',
          subitems: [
            {
              content: 'Agenda',
              onClick: () => handleDownloadPdf(instance, 'all'),
            },
          ],
        },
      ];
    }
    if (
      (isDraftAgenda || isPublishedAgenda || isRepublishedAgenda) &&
      hasNotes
    ) {
      options = [
        ...options,
        {
          content: 'Email',
          subitems: [
            {
              content: 'Agenda',
              onClick: () => handleMail('agenda', instance, 'all'),
            },
            {
              content: 'Agenda & Notes',
              onClick: () => handleMail('agendanotes', instance, 'all'),
            },
          ],
        },
        {
          content: 'PDF',
          subitems: [
            {
              content: 'Agenda',
              onClick: () => handleDownloadPdf(instance, 'all'),
            },
            {
              content: 'Agenda & Notes',
              onClick: () => handleDownloadPdf(instance, 'all'),
            },
          ],
        },
      ];
    }
    if (isDraftMinutes || isPublishedMinutes || isRepublishedMinutes) {
      if (hasBackup)
        options = [
          ...options,
          {
            content: 'Email',
            subitems: [
              {
                content: 'Agenda',
                onClick: () => handleMail('agenda', instance, 'agenda'),
              },
              {
                content: 'Agenda & Notes',
                onClick: () =>
                  handleMail('agendanotes', instance, 'agendanotes'),
              },
              {
                content: 'Meeting Minutes',
                onClick: () => handleMail('minutes', instance, 'all'),
              },
            ],
          },
          {
            content: 'PDF',
            subitems: [
              {
                content: 'Meeting Agenda',
                onClick: () => handleDownloadPdf(instance, 'agenda'),
              },
              {
                content: 'Meeting Agenda & Notes',
                onClick: () => handleDownloadPdf(instance, 'agendanotes'),
              },
              {
                content: 'Meeting Minutes',
                onClick: () => handleDownloadPdf(instance, 'all'),
              },
            ],
          },
        ];
      else
        options = [
          ...options,
          {
            content: 'Email',
            subitems: [
              {
                content: 'Meeting Minutes',
                onClick: () => handleMail('minutes', instance, 'all'),
              },
            ],
          },
          {
            content: 'PDF',
            subitems: [
              {
                content: 'Meeting Minutes',
                onClick: () => handleDownloadPdf(instance, 'all'),
              },
            ],
          },
        ];
    }
    if (
      parent[0].latest_instance_id === instance.id &&
      newMeetingsPermissions.canEditInstance(
        currentUser,
        instance,
        parent.status === 'archived'
      )
    ) {
      options = [
        ...options,
        {
          content: 'Edit',
          onClick: () => handleOpenMinutes(instance, rows, instanceForm),
        },
      ];
    }
    return options;
  };
  const menuActions = (meeting) => {
    const snakeCaseParent = snakeToCamel(meeting);
    const isFirstInstance = !(
      snakeCaseParent.meetingsInstances &&
      snakeCaseParent.meetingsInstances.length > 0
    );
    const isRecurrent = !!snakeCaseParent.recurrence;
    if (!isRecurrent) {
      // Non recurrent can have many agendas and minutes at the same time
      return [
        { content: 'Add Agenda', onClick: () => handleAddAgenda(meeting) },
        { content: 'Add Minutes', onClick: () => handleAddMinutes(meeting) },
        { content: 'Edit Meeting', onClick: () => handleEditMeeting(meeting) },
        { content: 'Archive', onClick: () => handleArchive(meeting) },
      ];
    }
    if (isFirstInstance)
      return [
        { content: 'Add Agenda', onClick: () => handleAddAgenda(meeting) },
        { content: 'Add Minutes', onClick: () => handleAddMinutes(meeting) },
        { content: 'Edit Meeting', onClick: () => handleEditMeeting(meeting) },
        { content: 'Archive', onClick: () => handleArchive(meeting) },
      ];
    const latestInstanceArray = snakeCaseParent.meetingsInstances.find(
      (i) => i.id === snakeCaseParent.latestInstanceId
    );
    const latestInstance = latestInstanceArray;
    const isDraftAgenda = latestInstance.status === 'draft_agenda';
    const isPublishedAgenda = latestInstance.status === 'published_agenda';
    const isRepublishedAgenda = latestInstance.status === 'republished_agenda';
    const isDraftMinutes = latestInstance.status === 'draft_minutes';
    const isPublishedMinutes = latestInstance.status === 'published_minutes';
    const isRepublishedMinutes =
      latestInstance && latestInstance.status === 'republished_minutes';
    const minutesSubItems = [
      { content: 'Add New Minutes', onClick: () => handleAddMinutes(meeting) },
      {
        content: 'Republish Previous Minutes',
        onClick: () => handleOpenMinutes(latestInstance, rows, instanceForm),
      },
    ];

    if (isDraftAgenda)
      return [
        {
          content: 'Edit Agenda',
          onClick: () => handleOpenMinutes(latestInstance, rows, instanceForm),
        },
        {
          content: 'Convert to Minutes',
          onClick: () => handleConvertToMinutes(latestInstance),
        },
        { content: 'Edit Meeting', onClick: () => handleEditMeeting(meeting) },
        { content: 'Archive', onClick: () => handleArchive(meeting) },
      ];
    if (isPublishedAgenda || isRepublishedAgenda)
      return [
        {
          content: 'Republish Agenda',
          onClick: () => handleOpenMinutes(latestInstance, rows, instanceForm),
        },
        {
          content: 'Convert to Minutes',
          onClick: () => handleConvertToMinutes(latestInstance),
        },
        { content: 'Edit Meeting', onClick: () => handleEditMeeting(meeting) },
        { content: 'Archive', onClick: () => handleArchive(meeting) },
      ];
    if (isDraftMinutes)
      return [
        {
          content: 'Edit Minutes',
          onClick: () => handleOpenMinutes(latestInstance, rows, instanceForm),
        },
        { content: 'Edit Meeting', onClick: () => handleEditMeeting(meeting) },
        { content: 'Archive', onClick: () => handleArchive(meeting) },
      ];
    if (isPublishedMinutes || isRepublishedMinutes)
      return [
        { content: 'Add Agenda', onClick: () => handleAddAgenda(meeting) },
        { content: 'Minutes', subitems: minutesSubItems },
        { content: 'Edit Meeting', onClick: () => handleEditMeeting(meeting) },
        { content: 'Archive', onClick: () => handleArchive(meeting) },
      ];
  };

  const handleOpenMinutes = (instance, rows, instanceForm) => {
    const parent = Meeting.setInstanceParent(instance, rows.data);
    dispatch(getMeetingsInstance(currentProject.id, instance.id));
    dispatch(setCurrentMeeting(parent));
    dispatch(setActionItems(instanceForm.meetingsActionItems));
    dispatch(setInstanceFormIsBackup(false));
    dispatch(setInstanceFormShow(true));
  };
  const handleOpenAgenda = (instance, rows, instanceForm) => {
    const parent = Meeting.setInstanceParent(instance, rows.data);
    if (!instance.isAgenda) {
      dispatch(getMeetingsInstanceAgendaBackup(currentProject.id, instance.id));
      dispatch(setInstanceFormIsBackup(true));
    } else {
      dispatch(getMeetingsInstance(currentProject.id, instance.id));
      dispatch(setActionItems(instanceForm.meetingsActionItems));
    }
    dispatch(setCurrentMeeting(parent));
    dispatch(setInstanceFormShow(true));
  };
  const handleOpenSubmitCorrectionsModal = (meetingsParent) => {
    dispatch(setFormProjectMeetings(snakeToCamel(meetingsParent)));
    dispatch(setSubmitCorrectionsModalShow(true));
  };
  const handleOpenReviewCorrectionsModal = (meetingsParent) => {
    dispatch(setFormProjectMeetings(snakeToCamel(meetingsParent)));
    dispatch(setReviewCorrectionsModalShow(true));
  };
  const handleCorrectionMenuChange = (option, meetingsParent) => {
    if (option.value === 'submit_correction')
      handleOpenSubmitCorrectionsModal(meetingsParent);
    if (option.value === 'review_corrections')
      handleOpenReviewCorrectionsModal(meetingsParent);
    if (option.value === 'view_all_corrections')
      handleViewAllCorrectionsModal(meetingsParent);
  };
  const handleViewAllCorrectionsModal = (meetingsParent) => {
    dispatch(setFormProjectMeetings(snakeToCamel(meetingsParent)));
    dispatch(setViewCorrectionsModalShow(true));
  };
  return {
    handleAddAgenda,
    handleAddMinutes,
    handleArchive,
    handleRestore,
    handleEditMeeting,
    handleShowInfo,
    menuActions,
    handleOpenMinutes,
    handleOpenAgenda,
    handleCorrectionMenuChange,
    instanceMenuOptions,
    instancePdfUrl,
    handleClosePdfViewer,
    emailSubject,
    handleClosePreviewModal,
    handleDownloadIcal,
  };
};

export default useParentsInstanceTable;
