import {
  SET_REPORT_VIEWS,
  SET_AVAILABLE_COMPANIES,
  SET_AVAILABLE_PROJECTS,
} from './action-types';

const initialState = {
  reportViews: [],
  availableCompanies: null,
  availableProjects: null,
  availableCities: null,
  availableStates: null,
  availableZips: null,
  availableAddresses: null,
};

export default function reportsDataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_REPORT_VIEWS:
      return {
        ...state,
        reportViews: action.payload.views || [],
      };

    case SET_AVAILABLE_COMPANIES:
      return {
        ...state,
        availableCompanies: action.payload.companies || [],
      };

    case SET_AVAILABLE_PROJECTS:
      return {
        ...state,
        availableProjects: action.payload.projects || [],
        availableCities: action.payload.cities || [],
        availableStates: action.payload.states || [],
        availableZips: action.payload.zips || [],
        availableAddresses: action.payload.addresses || [],
      };

    default:
      return state;
  }
}
