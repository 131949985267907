import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './RfiForm.css';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import Selector from 'components/shared/selector/Selector';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import DatePicker from 'components/shared/date-picker/DatePicker';
import { todayValidation } from 'services/utils/date-util';
import { countdown } from 'services/utils/date-util';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import Project from 'domain/project';
import User from 'domain/user';
import { RfiStatusKeys, RfiStatusEnum } from 'domain/rfi-status-enum';
import {
  loadProjectProposalRequest,
  loadCsiCodes,
} from 'components/admin/projects/details/project-proposal/store/actions';
import {
  updateRfiFormValue,
  setRfiFormValues,
} from 'components/admin/projects/details/project-rfi-log/store/actions';
import {
  getProjectProposal,
  getCsiCodes,
} from 'components/admin/projects/details/project-proposal/store/selectors';
import { getRfiForm } from 'components/admin/projects/details/project-rfi-log/store/selectors';
import { getDarkMode } from 'selectors/theme';
import FormControlBlock from 'components/shared/form/FormControlBlock';

class RfiForm extends Component {
  static propTypes = {
    currentUser: PropTypes.shape({}).isRequired,
    contribType: PropTypes.string.isRequired,
    project: PropTypes.shape({}).isRequired,
    directory: PropTypes.shape({}).isRequired,
    currentRfi: PropTypes.shape({}),
  };

  constructor(props) {
    super(props);

    if (props.currentRfi) {
      this.initFormDetails(props.currentRfi);
    }
  }

  componentDidMount() {
    const { currentUser, project } = this.props;

    if (!User.isContractor(currentUser, project)) this.loadCsiCodes();
  }

  nextStatus = (rfi) => {
    const { currentUser, project } = this.props;

    const assigned = !rfi.rfi_questions.find((qus) => !qus.assignee);
    const subcontractor = User.isContractor(currentUser, project);
    return assigned && !subcontractor
      ? RfiStatusKeys.ASSIGNED
      : RfiStatusKeys.PENDING;
  };

  initStatus = (rfi) => {
    const stEnum = RfiStatusEnum.create(rfi.status);
    return RfiStatusEnum.DRAFT.equals(stEnum)
      ? this.nextStatus(rfi)
      : rfi.status;
  };

  initFormDetails = (rfi) => {
    this.props.dispatch(
      setRfiFormValues({
        ...rfi,
        csiCodeId: rfi.csi_code_id,
        contractorId: rfi.contractor_id,
        dueDate: rfi.due_date,
        extendedDueDate: rfi.extended_due_date,
        status: this.initStatus(rfi),
        relCsiCodes: rfi.rel_csi_codes.map((csiCode) => [
          csiCode.id,
          `${csiCode.division} - ${csiCode.label}`,
        ]),
        recipients: rfi.recipients.map((rcpt) => [
          rcpt.id,
          User.fullNameWithCompany(rcpt),
        ]),
      })
    );
  };

  loadCsiCodes = () => {
    const { project, projectProposal, csiCodes, dispatch } = this.props;

    if (projectProposal && projectProposal.project_id === project.id) {
      return;
    }

    dispatch(loadProjectProposalRequest(project.id)).then((response) => {
      if (response.projectProposal || (csiCodes && csiCodes.length > 0)) {
        return;
      }

      dispatch(loadCsiCodes());
    });
  };

  rfiCsiCodeOptions = () => {
    const {
      project,
      directory,
      currentUser,
      projectProposal,
      csiCodes,
    } = this.props;
    return Project.csiCodesOptions({
      project,
      directory,
      currentUser,
      projectProposal,
      csiCodes,
    });
  };

  rfiCcCsiCodeOptions = () => {
    const {
      form,
      project,
      directory,
      currentUser,
      projectProposal,
      csiCodes,
    } = this.props;
    return Project.csiCodesOptions({
      project,
      directory,
      currentUser,
      projectProposal,
      csiCodes,
    })
      .filter((csiCode) => csiCode.value !== form.csiCodeId)
      .map((csiCode) => {
        return {
          id: csiCode.value,
          label: csiCode.label,
        };
      });
  };

  recipientOptions = () => {
    const { directory } = this.props;
    return Project.recipientOptions({ directory });
  };

  rfiSubcontractorOptions = (csiCodeId) => {
    const { project, directory } = this.props;
    return Project.subcontractorOptions({
      project,
      directory,
      csiCodeId,
    });
  };

  rfiStatusOptions = () => {
    return Object.keys(RfiStatusKeys)
      .filter((status) => !RfiStatusEnum.DRAFT.equals(RfiStatusEnum[status]))
      .map((status) => {
        const statusEnum = RfiStatusEnum[status];
        return {
          value: statusEnum.status,
          label: `${statusEnum.label
            .charAt(0)
            .toUpperCase()}${statusEnum.label.slice(1)}`,
        };
      });
  };

  handleFormChange = (attribute, value) => {
    this.props.dispatch(updateRfiFormValue(attribute, value));
  };

  handleCsiCodeChange = (csiCodeId) => {
    this.handleFormChange('csiCodeId', csiCodeId);
    this.handleFormChange('contractorId', null);

    const subOptions = this.rfiSubcontractorOptions(csiCodeId);
    if (subOptions.length === 1) {
      this.handleFormChange('contractorId', subOptions[0].value);
    }
  };

  render() {
    const { form, currentUser, project, currentRfi } = this.props;
    const subOptions = this.rfiSubcontractorOptions(form.csiCodeId);
    const subcontractor = User.isContractor(currentUser, project);
    const assigned =
      currentRfi && !currentRfi.rfi_questions.find((qus) => !qus.assignee);
    const today = moment();
    let dueDateCountdown =
      form && form.dueDate
        ? countdown(today.format('YYYY-MM-DD'), form.dueDate)
        : null;
    let extendedDueDateCountdown =
      form && form.extendedDueDate
        ? countdown(today.format('YYYY-MM-DD'), form.extendedDueDate)
        : null;
    if (dueDateCountdown) {
      dueDateCountdown += today > moment(form.dueDate) ? ' past due' : ' left';
    }
    if (extendedDueDateCountdown) {
      extendedDueDateCountdown +=
        today > moment(form.extendedDueDate) ? ' past due' : ' left';
    }

    return (
      <div className="rfi-form">
        {(!currentRfi || form.csiCodeId) && (
          <Form horizontal>
            <div className="form-block">
              <FormControlBlock
                label={!!form.csiCodeId && 'Cost Code'}
                control={
                  <Selector
                    placeholder="Cost Code"
                    value={form.csiCodeId}
                    onChange={(option) =>
                      this.handleCsiCodeChange(option.value)
                    }
                    options={this.rfiCsiCodeOptions()}
                    valid={!form.errors.csiCodeId}
                  />
                }
              />
            </div>
            {!subcontractor && (
              <React.Fragment>
                <div className="form-block">
                  <FormControlBlock
                    label={
                      form.relCsiCodes &&
                      form.relCsiCodes.length > 0 &&
                      'CC Trades'
                    }
                    control={
                      <MultipleDropdown
                        placeholder="CC Trades"
                        options={this.rfiCcCsiCodeOptions()}
                        currentValues={form.relCsiCodes}
                        onChange={(values) =>
                          this.handleFormChange('relCsiCodes', values)
                        }
                      />
                    }
                  />
                </div>
                <div className="form-block">
                  <FormControlBlock
                    label={
                      form.recipients &&
                      form.recipients.length > 0 &&
                      'CC Recipients'
                    }
                    control={
                      <MultipleDropdown
                        placeholder="CC Recipients"
                        options={this.recipientOptions()}
                        currentValues={form.recipients}
                        onChange={(values) =>
                          this.handleFormChange('recipients', values)
                        }
                      />
                    }
                  />
                </div>
                {subOptions.length > 1 && (
                  <div className="form-block">
                    <FormControlBlock
                      label={!!form.contractorId && 'Subcontractor'}
                      control={
                        <Selector
                          placeholder="Subcontractor"
                          value={form.contractorId}
                          onChange={(option) =>
                            this.handleFormChange('contractorId', option.value)
                          }
                          options={subOptions}
                        />
                      }
                    />
                  </div>
                )}
              </React.Fragment>
            )}
            <div className="form-block multicontrols-block">
              <FormControlBlock
                label={!!form.dueDate && 'Due Date'}
                bottomLabel={dueDateCountdown}
                control={
                  <DatePicker
                    placeholder="Due Date"
                    clearable={true}
                    isValidDate={todayValidation}
                    value={form.dueDate}
                    onChange={(value) =>
                      this.handleFormChange('dueDate', value)
                    }
                    valid={!form.errors.dueDate}
                  />
                }
              />
              {assigned && (
                <FormControlBlock
                  label={!!form.extendedDueDate && 'Extended Due Date'}
                  bottomLabel={extendedDueDateCountdown}
                  control={
                    <DatePicker
                      placeholder="Extended Due"
                      clearable={true}
                      isValidDate={todayValidation}
                      value={form.extendedDueDate}
                      onChange={(value) =>
                        this.handleFormChange('extendedDueDate', value)
                      }
                      valid={!form.errors.extendedDueDate}
                    />
                  }
                />
              )}
              <FormControlBlock
                className="rfi-form-status"
                label={(!!form.status || subcontractor) && 'Status'}
                control={
                  <Selector
                    placeholder="Status"
                    value={form.status}
                    onChange={(option) =>
                      this.handleFormChange('status', option.value)
                    }
                    options={this.rfiStatusOptions()}
                    valid={!form.errors.status}
                    readOnly={subcontractor}
                  />
                }
              />
            </div>
            <div className="form-block">
              <FormControlBlock
                label={!!form.title && 'Title'}
                control={
                  <TextareaAutosizeWrapper
                    placeholder="Title"
                    initialValue={form.title}
                    valid={!form.errors.title}
                    onChange={(value) => this.handleFormChange('title', value)}
                  />
                }
              />
            </div>
          </Form>
        )}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    projectProposal: getProjectProposal(state),
    csiCodes: getCsiCodes(state),
    form: getRfiForm(state),
    darkMode: getDarkMode(state),
  };
})(RfiForm);
