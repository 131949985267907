import React from 'react';

const textStyle = {
  fontSize: '14px',
  height: '500px',
  overflowX: 'hidden',
  textAlign: 'left',
};

const TermsModalText = () => (
  <div style={textStyle}>
    <h3>TERMS OF USE</h3>
    <p>
      Spackle, Inc. ("Spackle" or "We") provides an interactive software product
      ("App") via online services such as the Apple iTunes App Store and Google
      Play Store. We own and operate{' '}
      <a
        href="https://www.spackle.co"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.spackle.co
      </a>{' '}
      (our "Site") to provide access to information about Spackle and the App(s)
      (our "Content"). Please read the following terms and conditions ("Terms of
      Use") carefully. These Terms of Use govern your access to and use of our
      App, Site and Content (defined below) and set forth the legally binding
      terms for your use of the App and Content.
    </p>
    <p>
      Our App, Site and Content are available for individuals aged 13 years or
      older. BY ACCESSING OR USING OUR SITE OR BY PURCHASING, DOWNLOADING AND/OR
      INSTALLING OUR APP, YOU REPRESENT THAT YOU ARE AT LEAST 13 YEARS OLD AND
      THAT YOU HAVE READ AND UNDERSTAND AND ACCEPT THESE TERMS OF USE AND YOU
      AGREE AND UNDERSTAND THAT YOU ARE BOUND BY THESE TERMS OF USE AND ALL
      TERMS, POLICIES AND GUIDELINES INCORPORATED IN THESE TERMS OF USE,
      INCLUDING WITHOUT LIMITATION OUR PRIVACY POLICY LOCATED AT{' '}
      <a
        href="https://www.spackle.co/privacy/"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.spackle.co/privacy
      </a>
      . IF YOU DO NOT AGREE TO THESE TERMS OF USE, THEN YOU HAVE NO RIGHT TO
      ACCESS OR USE OUR SITE, APPS OR CONTENT.
      <br />
      We reserve the right to amend these Terms of Use in any and all respects
      at any time. IF YOU ARE NOT AT LEAST 13 YEARS OLD AND/OR YOU DO NOT AGREE
      TO THE TERMS OF THIS AGREEMENT, YOU MUST IMMEDIATELY (1) REFRAIN FROM
      USING THE SOFTWARE AND ANY OTHER MATERIALS ASSOCIATED WITH THE SOFTWARE,
      (2) COMPLETELY DELETE ALL COPIES OF THE SOFTWARE AND ALL RELATED FILES
      FROM YOUR DEVICE.
    </p>
    <p>
      IN ADDITION TO THE FOREGOING, IF YOU ARE PURCHASING, DOWNLOADING AND/OR
      INSTALLING OUR APP FROM AN ONLINE SERVICE SUCH AS APPLE ITUNES APP STORE
      OR GOOGLE PLAY STORE, YOU REPRESENT THAT YOU ARE NOT LOCATED IN A COUNTRY
      EMBARGOED BY THE UNITED STATES AND/OR ON THE U.S. TREASURY
      DEPARTMENT&rsquo;S LIST OF SPECIALLY-DESIGNATED NATIONALS. IF YOU DO NOT
      MEET THIS REQUIREMENT, YOU MUST IMMEDIATELY (1) REFRAIN FROM USING THE
      SOFTWARE AND ANY OTHER MATERIALS ASSOCIATED WITH THE SOFTWARE, (2)
      COMPLETELY DELETE ALL COPIES OF THE SOFTWARE AND ALL RELATED FILES FROM
      YOUR DEVICE.
    </p>
    <p>
      We reserve the right to modify or discontinue the Site, App or Content in
      any and all respects. You agree to check{' '}
      <a
        href="https://www.spackle.co/terms"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.spackle.co/terms
      </a>{' '}
      periodically for new information and terms that govern your use of our
      services. Revisions to terms affecting existing services shall be
      effective thirty (30) days after posting at spackle.co/terms. Terms for
      new services are effective immediately upon posting at{' '}
      <a
        href="https://www.spackle.co/terms"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.spackle.co/terms
      </a>
      .
    </p>
    <h4>Table of Contents</h4>
    <ol>
      <li>Certain Definitions Intellectual Property Rights</li>
      <li>Contributing Third Party Content</li>
      <li>
        Takedown Procedure under the U.S. Digital Millennium Copyright Act
      </li>
      <li>Contributing User Content; License Grant to Us</li>
      <li>Third Party Services</li>
      <li>Privacy</li>
      <li>Rules of Conduct</li>
      <li>Termination</li>
      <li>NO WARRANTY</li>
      <li>LIMITATION OF LIABILITY</li>
      <li>Indemnity</li>
      <li>Injunctive Relief</li>
      <li>Controlling Law and Jurisdiction</li>
      <li>Entire Agreement; Severability; Waiver</li>
      <li>Notices</li>
    </ol>
    <h4>Certain Definitions</h4>
    <p>
      Certain types of content are made available through the Site and/or App.
      "Content" includes, but is not limited to, the software, data, technology,
      text, forum posts, chat posts, profiles, widgets, messages, links,
      e-mails, music, sound, graphics, pictures, images, illustrations, forms,
      video, code, and all audio visual or other material appearing on or
      emanating to and/or from the Site or App, including their look and feel
      attributes, as well as the design and appearance of the Site, the Spackle
      trademarks and logos and other content made available through the Site and
      App. "User Content" means any text, data, graphics, images, photos, video
      or audiovisual content, hypertext links and any other content uploaded,
      transmitted or submitted by individual users to the Site or App, including
      through use of any widget.
    </p>
    <h4>Intellectual Property Rights</h4>
    <p>
      The Site, App and Content are owned by Spackle or Spackle&rsquo;s
      third-party licensors and are protected by U.S. copyright laws, trade
      dress, patent, and trademark laws, international conventions, and other
      laws protecting intellectual property and related proprietary rights. You
      may not copy or download any Content unless you are expressly authorized
      to do so. You do not acquire any ownership rights in our Site, App or
      Content by using the Site, App or Content.
    </p>
    <p>
      You agree not to remove, obscure, or alter copyright, patent, trademark,
      or other proprietary rights notices affixed to Content or App. You may not
      sell, license, distribute, copy, modify, publicly perform or display,
      transmit, publish, edit, adapt, create derivative works from, or otherwise
      make unauthorized use of App or Content. We reserve all rights in our
      Site, App and Content that are not expressly granted to you in these Terms
      of Use. Making unauthorized copies of App and/or Content found on the Site
      will result in the termination of your rights to use the Site and App and
      further legal action. We or our licensors and exclusive licensees may take
      criminal or civil action against you for unauthorized use of intellectual
      property. You agree to indemnify and hold us and our employees,
      representatives, agents, licensees and providers of Third-Party Services
      to our Site and App harmless from any unauthorized or illegal conduct by
      you, or through the use of the Site or App.
    </p>
    <p>
      We welcome and encourage you to provide feedback, comments and suggestions
      for improvements to the Site, products and Content ("Feedback"). You may
      submit Feedback by emailing us at support@spackle.co. You acknowledge and
      agree that all Feedback will be our sole and exclusive property and you
      hereby irrevocably assign to Spackle and its licensees and successors and
      assigns, and agree to irrevocably assign to Spackle and its licensees and
      successors and assigns all of your right, title, and interest in and to
      all Feedback, including without limitation all worldwide patent rights,
      copyright rights, trade secret rights, and other proprietary or
      intellectual property rights therein. At our request and expense, you will
      execute documents and take such further acts as we may reasonably request
      to assist us to acquire, perfect, and maintain its intellectual property
      rights and other legal protections for the Feedback.
    </p>
    <h4>Contributing Third Party Content</h4>
    <p>
      We respect the intellectual property rights of others. You must have the
      legal right to upload User Content to the Site or App. You may not upload
      or post any User Content that is protected by copyright, trademark or
      other intellectual property rights unless (i) you are the owner of all of
      those rights or (ii) you have the prior written consent of the owner(s) of
      those rights to make such use of that User Content. We may, without prior
      notice to you and in our sole judgment, remove User Content that may
      infringe the intellectual property rights of a third party. If you are an
      infringer of our or a third party&rsquo;s intellectual property rights, we
      may terminate your right to access the Site or App without notice to you.
      If your access is terminated to the Site or App as a result of any
      infringement of intellectual property rights, you are not entitled to a
      refund for any fees you have paid.
      <br />
      Takedown Procedure under the U.S. Digital Millennium Copyright Act
    </p>
    <p>
      We have adopted and implemented a policy respecting the U.S. copyright law
      that provides for the removal of User Content that infringes the rights of
      third parties and, in appropriate circumstances, disabling access to the
      Site and/or App and/or Content of users who are infringers of intellectual
      property rights, including copyrights under U.S. law. If you believe that
      one of our users is, through the use of one of our App or Site, unlawfully
      infringing the copyright(s) in a work, and wish to have the allegedly
      infringing material removed from our Site or App(s), the following
      information in the form of a written notification (pursuant to 17 U.S.C. -
      512(c)) must be provided to our designated Copyright Agent:
    </p>
    <p>
      Identification of the copyrighted work(s) that you claim to have been
      infringed;
    </p>
    <p>
      Identification of where the infringing material is located on our Site or
      products;
    </p>
    <p>
      A statement that you have a good faith belief that use of the
      objectionable material is not authorized by the copyright owner, its
      agent, or under the law;
    </p>
    <p>
      A statement that the information in the notification is accurate, and that
      under penalty of perjury, you are either the owner of the copyright that
      has allegedly been infringed or that you are authorized to act on behalf
      of the copyright owner;
    </p>
    <p>Your address, telephone number, and e-mail address; and</p>
    <p>Your physical or electronic signature.</p>
    <p>
      Please note that pursuant to 17 U.S.C. - 512(f), any misrepresentation of
      material fact (falsities) in a written notification automatically subjects
      the complaining party to liability for any damages, costs and
      attorney&rsquo;s fees incurred by Spackle in connection with the written
      notification and allegation of copyright infringement.
    </p>
    <h4>Contributing User Content; License Grant to Spackle</h4>
    <p>
      When you contribute User Content to the Site or a App, you expressly grant
      to us and our licensees, successors and assigns, a non-exclusive,
      perpetual, worldwide, complete, irrevocable, fully-paid and royalty-free
      right to quote, re-post, use, reproduce, modify, create derivative works
      from, syndicate, license, print, sublicense, distribute, transmit,
      broadcast, and otherwise communicate, and publicly display and perform the
      User Content, or any portion thereof, in any manner or form and in any
      medium or forum, whether now known or hereafter devised, without notice,
      payment or attribution of any kind to you or any third party. You grant us
      all licenses, consents and clearances to enable us to use such User
      Content for such purposes. You waive and agree not to assert any moral or
      similar rights you may have in such User Content.
    </p>
    <h4>Third Party Services</h4>
    <p>
      The Site and App may contain or link to third-party websites or resources,
      such as Apple iTunes App Store, Google Play Store, Amazon Appstore for
      Android, Samsung Appstore, and websites owned and operated by publishing
      and distribution partners ("Third Party Services") where you can access
      the content, products, or services on or available from such websites or
      resources ("Third Party Materials"). Third Party Services, Third Party
      Materials and links to other websites are provided solely as a convenience
      to you, and links to such Third Party Services do not imply any
      endorsement by us of such Third Party Services or Third Party Materials
      available from such websites or resources nor do such links imply an
      endorsement by the providers of such Third Party Services or Third Party
      Materials of us, or the App or Content. You acknowledge and agree that (i)
      we are not responsible or liable for examining or evaluating the content,
      the availability or accuracy, completeness, timeliness, validity,
      copyright compliance, legality, decency, quality, maintenance or support,
      or any other aspect of such Third Party Services or Third Party Materials,
      and (ii) the providers of such Third Party Services or Third Party
      Materials are not responsible or liable for examining or evaluating the
      content, the availability or accuracy, completeness, timeliness, validity,
      copyright compliance, legality, decency, quality, maintenance or support,
      or any other aspect of our Site, App and Content. We encourage you to
      review the terms of use or terms of service of any other Third Party
      Services provider from whom you request services, because by visiting a
      Third Party Service that is linked to the Site or App, you will be
      governed by such party&rsquo;s terms of use or terms of service and
      privacy policy and you should consult that site&rsquo;s terms of use or
      terms of service, and that site&rsquo;s privacy policy before entering and
      using that site and before providing any personal information.
    </p>
    <h4>Privacy</h4>
    <p>
      Personal information gathered in connection with your use of the Site or
      App or submitted with User Content shall be held by Spackle in compliance
      with Spackle&rsquo;s Privacy Policy, which is incorporated herein by
      reference. See our Privacy Policy at{' '}
      <a
        href="https://www.spackle.co/privacy"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.spackle.co/privacy
      </a>{' '}
      or information and notices concerning our collection and use of your
      personal information. If you have any questions about our Privacy Policy,
      please contact us at pa@spackle.co. Because we cannot control the
      activities of third parties (including the providers of such Third-Party
      Services or Third-Party Materials), we cannot accept responsibility for
      any use of your personal information by such third parties, and we cannot
      guarantee that they will adhere to the same privacy and security practices
      as we do. We encourage you to review the privacy policies of any other
      Third Party Services provider from whom you request services, because by
      visiting a Third Party Service that is linked to the Site or App, you will
      be governed by such party&rsquo;s terms of use or terms of service and
      privacy policy and you should consult that site&rsquo;s terms of use or
      terms of service and that site&rsquo;s privacy policy before entering and
      using that site and before providing any personal information.
    </p>
    <h4>Rules of Conduct</h4>
    <p>By agreeing to these Terms of Use you acknowledge that you may not:</p>
    <p>
      Post, transmit, promote, or distribute User Content that is illegal.
      <br />
      Impersonate another person (including celebrities); indicate falsely that
      you are our employee or our representative, or attempt to mislead users by
      indicating that you represent us or any of our partners or affiliates.
      <br />
      Engage in any commercial activities, including but not limited to any
      attempts to raise money for anyone or advertise or promote a product,
      service, website, pyramid scheme or multi-tiered marketing scheme.
      <br />
      Attempt to get a password, account information, or other private
      information from any user.
      <br />
      Upload any software or User Content that you do not own or have permission
      to freely distribute.
      <br />
      Promote or encourage any illegal activity including hacking, cracking or
      distribution of counterfeit software.
      <br />
      Upload files that contain a virus or corrupted data.
      <br />
      Post messages for any purpose other than personal communication, including
      advertising or promotional messaging, chain letters, pyramid schemes, or
      other commercial activities.
      <br />
      Use or distribute "auto" software programs, "macro" software programs or
      other "cheat utility" software program or applications.
      <br />
      Modify any part of the Site, App or Content that we do not specifically
      authorize you to modify.
      <br />
      Attempt to interfere with, hack into or decipher any transmissions to or
      from our servers.
      <br />
      Attempt to use App on or through any service that is not controlled or
      authorized by us.
      <br />
      Interfere with the ability of others to enjoy playing a App or take
      actions that interfere with or materially increase the cost to provide App
      for the enjoyment of all its users.
    </p>
    <p>
      Specific Third Party Services may also post additional rules that apply to
      your conduct on those services. You must also obey all applicable laws,
      regulations and rules that apply to your activities when you use the Site
      or App.
    </p>
    <p>
      If you encounter another user who is violating any of the Rules of
      Conduct, please report this activity to Spackle at support@spackle.co.
    </p>
    <h4>Termination</h4>
    <p>
      We reserve the right to terminate your access and to prevent your use of
      any and all of our Site or App if you engage in illegal activity or
      violate these Terms of Use. We will have the right to investigate and
      prosecute violations of any of the above, including intellectual property
      rights infringement and Site and App security issues, to the fullest
      extent of the law. We may involve and cooperate with law enforcement
      authorities in prosecuting users who violate these Terms of Use. You
      acknowledge that we have no obligation to monitor your access to or use of
      the Site, App or Content or to review or edit any User Content, but has
      the right to do so for the purpose of operating the Site and App, to
      ensure your compliance with these Terms of Use, or to comply with
      applicable law or the order or requirement of a court, administrative
      agency or other governmental body. In addition, if as a result of our
      monitoring your access to or use of the Site, App or Content, we find that
      you have not accessed or used the Site or App for a period of twelve (12)
      months or more, we reserve the right to terminate your account. We reserve
      the right, at any time and without prior notice, to remove or disable
      access to any Content or other content, including any User Content, that
      we, in our sole discretion, considers to be in violation of these Terms of
      Use or otherwise harmful to the Site or App.
    </p>
    <h4>NO WARRANTY</h4>
    <p>
      YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE SITE, APPS AND
      CONTENT, AS WELL AS THE THIRD-PARTY SERVICES LINKED TO OUR SITE AND APPS,
      AND THE THIRD-PARTY MATERIALS OFFERED THROUGH SUCH LINKS IS AT YOUR SOLE
      RISK AND THAT THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE,
      ACCURACY AND EFFORT IS WITH YOU. TO THE MAXIMUM EXTENT PERMITTED BY
      APPLICABLE LAW, THE SITE, APPS AND CONTENT AND ANY THIRD PARTY SERVICES OR
      THIRD PARTY MATERIALS PERFORMED OR PROVIDED BY THE SITE OR PRODUCTS ARE
      PROVIDED "AS IS" AND "AS AVAILABLE", WITH ALL FAULTS AND WITHOUT WARRANTY
      OF ANY KIND, AND WE AND THE PROVIDERS OF THE THIRD PARTY SERVICES LINKED
      TO OUR SITE AND APPS, AND THE THIRD PARTY MATERIALS OFFERED THROUGH SUCH
      LINKS, HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE
      SITE, APPS AND CONTENT AND ANY THIRD PARTY SERVICES AND THIRD PARTY
      MATERIALS, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT
      LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, OF SATISFACTORY
      QUALITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, OF QUIET
      ENJOYMENT, AND OF NON-INFRINGEMENT OF THIRD PARTY RIGHTS. WE DO NOT
      WARRANT AGAINST INTERFERENCE WITH YOUR ENJOYMENT OF THE SITE, APPS AND
      CONTENT, THAT THE FUNCTIONS CONTAINED IN, OR SERVICES PERFORMED OR
      PROVIDED BY, THE SITE, APPS AND CONTENT WILL MEET YOUR
    </p>
    <p>
      REQUIREMENTS OR THAT ANY ERRORS IN THE OPERATION OF THE SITE, APPS OR
      THIRD-PARTY SERVICES OR THIRD- PARTY MATERIALS WILL BE CORRECTED. NO ORAL
      OR WRITTEN INFORMATION OR ADVICE GIVEN BY US OR OUR AUTHORIZED
      REPRESENTATIVES OR BY THIRD PARTY SERVICES LINKED TO OUR SITE AND APPS
      SHALL CREATE A WARRANTY. SHOULD THE SITE, APPS, CONTENT, THIRD PARTY
      SERVICES OR THIRD-PARTY MATERIALS PROVE DEFECTIVE, YOU ASSUME THE ENTIRE
      COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION INCURRED BY YOU AS A
      RESULT OF YOUR USE OF THE SITE, APPS, CONTENT, THIRD PARTY SERVICES OR
      THIRD PARTY MATERIALS. FOR THE AVOIDANCE OF DOUBT, WE ARE NOT AUTHORIZED
      TO MAKE AND DO NOT MAKE ANY WARRANTIES ON BEHALF OF THE PROVIDERS OF THE
      THIRD PARTY SERVICES LINKED TO OUR SITE AND APPS, AND THE THIRD PARTY
      MATERIALS OFFERED THROUGH SUCH LINKS, AND OUR LICENSEES AND THE PROVIDERS
      OF THE THIRD PARTY SERVICES LINKED TO OUR SITE AND APPS, AND THE THIRD
      PARTY MATERIALS OFFERED THROUGH SUCH LINKS ARE NOT AUTHORIZED TO MAKE AND
      DO NOT MAKE ANY WARRANTIES ON BEHALF OF OUR SITE, APPS OR CONTENT. SOME
      JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR
      LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE
      EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.
    </p>
    <h4>LIMITATION OF LIABILITY</h4>
    <p>
      TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL WE OR ANY OF OUR
      EMPLOYEES, REPRESENTATIVES, AGENTS OR LICENSEES BE LIABLE FOR PERSONAL
      INURY OR ANY INCIDENTAL, SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES
      WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS,
      LOSS OF DATA, BUSINESS INTERRUPTION OR ANY OTHER COMMERCIAL DAMAGES OR
      LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR INABILITY TO USE THE
      SITE, PRODUCTS, CONTENT, THIRD PARTY SERVICES OR THIRD PARTY MATERIALS,
      HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT OR
      OTHERWISE) AND EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
      DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR
      PERSONAL INJURY OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS
      LIMITATION MAY NOT APPLY TO YOU. IN NO EVENT SHALL OUR TOTAL LIABILITY TO
      YOU FOR ALL DAMAGES (OTHER THAN AS MAY BE REQUIRED BY APPLICABLE LAW IN
      CASES INVOLVING PERSONAL INJURY) EXCEED THE AMOUNT OF FIVE DOLLARS (US$5).
      THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS
      OF ITS ESSENTIAL PURPOSE.
    </p>
    <h4>Indemnity</h4>
    <p>
      You agree to defend, indemnify, and hold us and our employees,
      representatives, agents, licensees and providers of Third Party Services
      linked to our Site and App harmless from and against any claims,
      liabilities, damages, losses, and expenses, including, without limitation,
      reasonable legal and accounting fees, arising out of or in any way
      connected with User Content you submit to us, your access to or use of our
      Site, App or Content, or your violation of these Terms of Use.
    </p>
    <h4>Injunctive Relief</h4>
    <p>
      You agree that a breach of these Terms of Use will cause irreparable
      injury to us for which monetary damages would not be an adequate remedy
      and we shall be entitled to seek equitable relief in addition to any
      remedies we may have hereunder or at law without a bond, other security or
      proof of damages.
    </p>
    <h4>Controlling Law and Jurisdiction</h4>
    <p>
      These Terms of Use and any action related thereto will be governed by the
      laws of the State of California (USA) without regard to its conflict of
      laws provisions. Any legal suit, action or proceeding arising out of, or
      related to, these Terms of Use, the App or the Site shall be instituted
      exclusively in the federal courts of the United States or the courts of
      the State of California in each case located in Los Angeles County,
      California; although we retain the right to bring any suit, action or
      proceeding against you for breach of these Terms of Use in your state or
      country of residence or any other relevant state or country if different
      from the State of California and the United States. You waive any and all
      objections to the exercise of jurisdiction over you by such courts and to
      venue in such courts.
    </p>
    <h4>Entire Agreement; Severability; Waiver</h4>
    <p>
      These Terms of Use constitute the entire and exclusive understanding and
      agreement between Spackle and you regarding the Site, App and Content, and
      these Terms of Use supersede and replace any and all prior oral or written
      understandings or agreements between Spackle and you regarding the Site,
      App and Content. You agree that these Terms of Use are not intended to
      confer and do not confer any rights or remedies upon any person other than
      the parties to these Terms of
    </p>
    <p>
      Use. You also understand and agree that these Terms of Use and our Privacy
      Policy{' '}
      <a
        href="https://www.spackle.co/privacy/"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://www.spackle.co/privacy/
      </a>{' '}
      incorporated by reference into these Terms of Use, including our
      enforcement of those policies, are not intended to confer, and do not
      confer, any rights or remedies upon any third party. If any part of these
      Terms of Use is held invalid or unenforceable, that portion shall be
      interpreted in a manner consistent with applicable law to reflect, as
      nearly as possible, our original intentions, and the remaining portions
      shall remain in full force and effect. Our failure to enforce any right or
      provision of these Terms of Use will not constitute a waiver of future
      enforcement of that right or provision.
    </p>
    <h4>Notices</h4>
    <p>
      You consent to the use of (i) electronic means to complete these Terms of
      Use and to deliver any notices or other communications permitted or
      required hereunder and (ii) electronic records to store information
      related to these Terms of Use or your use of the Site, App or Content. Any
      notices or other communications permitted to required hereunder, including
      those regarding modifications to these Terms of Use, will be in writing
      and given (x) by us via email (in each case to the address that you
      provide) or (y) by posting to the Site or App. For notices made by e-mail,
      the date of receipt will be deemed the date on which such notice is
      transmitted.
    </p>
  </div>
);

export default TermsModalText;
