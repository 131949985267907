import { showErrorMessage } from 'actions/messages';
import { projectsApi } from 'services/api/projects-api';
import {
  PROJECT_DETAILS_INIT,
  PROJECT_DETAILS_CURRENT_PROJECT_SET,
  PROJECT_DETAILS_NAV_BAR_SELECTION,
  SHOW_PDF_VIEWER_SET,
  DIRECT_UPLOAD_IN_PROCESS,
  SHOW_DASHBOARD,
} from 'components/admin/projects/details/store/action-types';
import { projectResourceMailerApi } from 'services/api/project-resource-mailer-api';
import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';
import { persistShowDashboard } from './persist';

export function initProjectDetails() {
  return {
    type: PROJECT_DETAILS_INIT,
  };
}

export function loadProjectSuccess(project) {
  return {
    type: PROJECT_DETAILS_CURRENT_PROJECT_SET,
    payload: project,
  };
}

export function loadProjectRequest(prjectId) {
  return (dispatch) => {
    return projectsApi.getProject(prjectId).then((response) => {
      if (response.ok) {
        dispatch(loadProjectSuccess(response.project));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
  };
}

export function selectNavigationBar(value) {
  return (dispatch) => {
    dispatch({
      type: PROJECT_DETAILS_NAV_BAR_SELECTION,
      payload: value,
    });
  };
}

export function sendProjectResourceEmails(resourceId, resourceType, emailData) {
  return (dispatch) => {
    return projectResourceMailerApi
      .postResourceEmails(resourceId, resourceType, emailData)
      .then((response) => {
        if (response.ok) {
          dispatch(setShowEmailModal(false));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setShowPdfViewer(showPdfViewer) {
  return {
    type: SHOW_PDF_VIEWER_SET,
    payload: showPdfViewer,
  };
}

export function changeProjectStatus(projectId, status) {
  return (dispatch) => {
    return projectsApi
      .updateProject(projectId, { status: status })
      .then((response) => {
        if (response.ok) {
          dispatch(loadProjectRequest(projectId));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function changeProjectLogo(projectId, logo) {
  return (dispatch) => {
    return projectsApi
      .updateProject(projectId, { logo: logo })
      .then((response) => {
        if (response.ok) {
          dispatch(loadProjectRequest(projectId));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function changeProjectDescription(projectId, description) {
  return (dispatch) => {
    return projectsApi
      .updateProject(projectId, { description: description })
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
}

export function setDirectUploadInProcess(directUploadInProcess, error) {
  return (dispatch) => {
    if (!directUploadInProcess && !!error) {
      dispatch(showErrorMessage(error));
    }
    dispatch({
      type: DIRECT_UPLOAD_IN_PROCESS,
      payload: directUploadInProcess,
    });
  };
}

export function setShowDashboard(show, userId) {
  return (dispatch) => {
    persistShowDashboard(show, userId);
    dispatch({ type: SHOW_DASHBOARD, payload: show });
  };
}
