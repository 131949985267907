import { createSelector } from 'reselect';

export const getOrderMaterials = createSelector(
  (state) => state.orderMaterials.data.order,
  (order) => {
    return order;
  }
);

export const getOrderMaterialsForm = createSelector(
  (state) => state.orderMaterials.ui.orderMaterialsForm,
  (orderMaterialsForm) => {
    return orderMaterialsForm;
  }
);

export const showConfirmationMessage = createSelector(
  (state) => state.orderMaterials.ui.showConfirmationMessage,
  (showConfirmationMessage) => {
    return showConfirmationMessage;
  }
);
