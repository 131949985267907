import { createSelector } from 'reselect';

export const getServiceProvidersCurrentPage = createSelector(
  (state) => state.serviceProviders.ui.currentPage,
  (currentPage) => {
    return currentPage;
  }
);

export const getServiceProviders = createSelector(
  (state) => state.serviceProviders.ui.serviceProviders,
  (serviceProviders) => {
    return serviceProviders;
  }
);

export const getServiceProvidersDropdown = createSelector(
  (state) => state.serviceProviders.ui.serviceProviderDropdown,
  (serviceProviderDropdown) => {
    return serviceProviderDropdown;
  }
);

export const getServiceProvidersTotal = createSelector(
  (state) => state.serviceProviders.ui.total,
  (total) => {
    return total;
  }
);

export const getServiceProvidersPageSize = createSelector(
  (state) => state.serviceProviders.ui.pageSize,
  (pageSize) => {
    return pageSize;
  }
);

export const getServiceProviderNameToSearch = createSelector(
  (state) => state.serviceProviders.ui.nameToSearch,
  (nameToSearch) => {
    return nameToSearch;
  }
);

export const getCompanyData = createSelector(
  (state) => state.serviceProviders.ui.companyData,
  (companyData) => {
    return companyData;
  }
);

export const getUserList = createSelector(
  (state) => state.serviceProviders.ui.userList,
  (userList) => {
    return userList;
  }
);

export const getUserForm = createSelector(
  (state) => state.serviceProviders.ui.userForm,
  (userForm) => {
    return userForm;
  }
);

export const getShowInviteUserModal = createSelector(
  (state) => state.serviceProviders.ui.showInviteUserModal,
  (showInviteUserModal) => {
    return showInviteUserModal;
  }
);

export const getShowConfirmationDeleteModal = createSelector(
  (state) => state.serviceProviders.ui.showConfirmationDeleteModal,
  (showConfirmationDeleteModal) => {
    return showConfirmationDeleteModal;
  }
);

export const getCurrentUserCompany = createSelector(
  (state) => state.serviceProviders.ui.currentUserCompany,
  (currentUserCompany) => {
    return currentUserCompany;
  }
);

export const getVerificationModal = createSelector(
  (state) => state.serviceProviders.ui.verificationModal,
  (verificationModal) => {
    return verificationModal;
  }
);
