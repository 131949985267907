import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './CloseoutForm.css';
import DatePicker from 'components/shared/date-picker/DatePicker';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import MessageModal from 'components/shared/modal/MessageModal';
import { todayValidation } from 'services/utils/date-util';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCloseout } from 'components/admin/projects/details/project-closeout/store/selectors';
import {
  updateCloseout,
  requestCloseout,
  downloadCloseoutDocumentsRequest,
} from 'components/admin/projects/details/project-closeout/store/actions';
import PropertyOwnerModal from 'components/admin/projects/details/project-closeout/PropertyOwnerModal';
import WarrantyDocumentsModal from 'components/admin/projects/details/project-closeout/WarrantyDocumentsModal';
import { loadCloseoutRequest } from 'components/admin/projects/details/project-closeout/store/actions';
import CloseoutCatFormModal from 'components/admin/projects/details/project-closeout/CloseoutCatFormModal';
import CloseoutPermissions from 'permissions/closeout-permissions';
import { getCurrentUser } from 'selectors/authentication';

class CloseoutForm extends Component {
  static propTypes = {
    readOnly: PropTypes.bool,
    currentTab: PropTypes.number,
  };

  static defaultProps = {
    readOnly: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      form: this.initializeForm(props.closeout),
      showRequestConfirmation: false,
      showRequestConfirmationSuccess: false,
      showPropOwnerModal: false,
      showWarrantyDocumentsModal: false,
      showDownloadDocumentsMessage: false,
      showCloseoutCatFormModal: false,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { closeout } = this.props;
    const newCloseout = newProps.closeout;
    if (closeout !== newCloseout) {
      this.setState({ form: this.initializeForm(newCloseout) });
    }
  }

  initializeForm = (closeout) => {
    if (closeout) {
      return {
        warrantyDate: closeout.warranty_date,
        dueDate: closeout.due_date,
        requestedAt: closeout.requested_at,
      };
    }
  };

  handleAttributeChange = (attr, value) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          [attr]: value,
        },
      },
      this.handleSave
    );
  };

  handleSave = () => {
    const { currentProject, dispatch } = this.props;
    const { form } = this.state;
    dispatch(updateCloseout(currentProject.id, form));
  };

  handleRequestConfirmation = () => {
    this.setState({ showRequestConfirmation: true });
  };

  sendRequest = () => {
    const { currentProject, dispatch } = this.props;
    dispatch(requestCloseout(currentProject.id)).then((response) => {
      if (response.ok) {
        this.setState({ showRequestConfirmationSuccess: true });
      }
    });
  };

  handleRequest = (confirmed) => {
    if (confirmed) {
      if (this.props.currentProject.project_location.property_owner_name) {
        this.sendRequest();
      } else {
        this.setState({ showPropOwnerModal: true });
      }
    }
    this.setState({ showRequestConfirmation: false });
  };

  handleDismissRequestSuccess = () => {
    this.setState({ showRequestConfirmationSuccess: false });
  };

  handlePropOwnerSubmit = () => {
    this.sendRequest();
    this.setState({ showPropOwnerModal: false });
  };

  handlePropOwnerClose = () => {
    this.setState({ showPropOwnerModal: false });
  };

  handleShowWarrantyDocumentsModal = () => {
    const { currentProject, dispatch } = this.props;
    dispatch(loadCloseoutRequest(currentProject.id)).then((response) => {
      if (response.ok) {
        this.setState({ showWarrantyDocumentsModal: true });
      }
    });
  };

  handleCloseWarrantyDocumentsModal = () => {
    this.setState({ showWarrantyDocumentsModal: false });
  };

  readOnly = () => {
    const { readOnly, currentUser, currentProject } = this.props;

    return (
      readOnly || !CloseoutPermissions.canEdit(currentUser, currentProject)
    );
  };

  handleDownloadDocumentsMessage = () => {
    const { dispatch, currentProject } = this.props;
    dispatch(downloadCloseoutDocumentsRequest(currentProject.id));
    this.setState({ showDownloadDocumentsMessage: true });
  };

  handleHideDownloadDocumentsMessage = () => {
    this.setState({ showDownloadDocumentsMessage: false });
  };

  handleShowCloseoutCatFormModal = () => {
    this.setState({ showCloseoutCatFormModal: true });
  };

  handleCloseCloseoutCatFormModal = () => {
    this.setState({ showCloseoutCatFormModal: false });
  };

  render() {
    const {
      form,
      showRequestConfirmation,
      showRequestConfirmationSuccess,
      showPropOwnerModal,
      showWarrantyDocumentsModal,
      showDownloadDocumentsMessage,
      showCloseoutCatFormModal,
    } = this.state;
    const { currentTab, closeout } = this.props;
    const readOnly = this.readOnly();

    return (
      form && (
        <React.Fragment>
          <div className="closeout-form">
            {form.requestedAt && (
              <FormControlBlock
                label="Requested On"
                control={
                  <DatePicker
                    value={form.requestedAt}
                    hideAdornment
                    readOnly={true}
                  />
                }
              />
            )}
            <FormControlBlock
              label={form.warrantyDate ? 'Warranty Date' : null}
              control={
                <DatePicker
                  onChange={(value) =>
                    this.handleAttributeChange('warrantyDate', value)
                  }
                  placeholder={'Warranty Date'}
                  value={form.warrantyDate}
                  readOnly={readOnly}
                />
              }
            />
            <FormControlBlock
              label={form.dueDate ? 'Due Date' : null}
              control={
                <DatePicker
                  isValidDate={todayValidation}
                  onChange={(value) =>
                    this.handleAttributeChange('dueDate', value)
                  }
                  placeholder={'Due Date'}
                  value={form.dueDate}
                  readOnly={readOnly}
                />
              }
            />
            <div className="closeout-form-block">
              {!readOnly && (
                <Button
                  type={BUTTON_TYPES.LINK}
                  color={BUTTON_COLORS.GREEN}
                  label="Upload Documents"
                  onClick={this.handleShowWarrantyDocumentsModal}
                />
              )}
            </div>
            {currentTab === 3 && (
              <React.Fragment>
                <div className="closeout-form-buttons">
                  <Button
                    type={BUTTON_TYPES.LINK}
                    color={BUTTON_COLORS.GREEN}
                    label={
                      form.requestedAt ? 'Request Again' : 'Request Closeout'
                    }
                    disabled={!form.warrantyDate || !form.dueDate}
                    onClick={this.handleRequestConfirmation}
                  />
                </div>
                <div className="closeout-form-buttons">
                  <Button
                    type={BUTTON_TYPES.LINK}
                    icon={BUTTON_ICONS.PLUS}
                    label="Add Item"
                    onClick={this.handleShowCloseoutCatFormModal}
                  />
                </div>
              </React.Fragment>
            )}

            {currentTab === 1 && closeout.warranty_documents.length > 0 && (
              <div className="closeout-form-buttons download-documents">
                <Button
                  type={BUTTON_TYPES.LINK}
                  color={BUTTON_COLORS.GREEN}
                  label="Download Documents"
                  onClick={this.handleDownloadDocumentsMessage}
                />
              </div>
            )}
          </div>
          {showRequestConfirmation && (
            <ConfirmationModal
              show={showRequestConfirmation}
              message="Are you sure you want to send the Closeout Request email notification?"
              onHide={this.handleRequest}
            />
          )}
          {showRequestConfirmationSuccess && (
            <MessageModal
              show={showRequestConfirmationSuccess}
              message="An email has been sent to subcontractors requesting Closeout documents."
              onHide={this.handleDismissRequestSuccess}
            />
          )}
          {showPropOwnerModal && (
            <PropertyOwnerModal
              show={showPropOwnerModal}
              onSubmit={this.handlePropOwnerSubmit}
              onClose={this.handlePropOwnerClose}
            />
          )}
          {showWarrantyDocumentsModal && (
            <WarrantyDocumentsModal
              show={showWarrantyDocumentsModal}
              onSubmit={this.handleAttributeChange}
              onClose={this.handleCloseWarrantyDocumentsModal}
            />
          )}
          {showDownloadDocumentsMessage && (
            <MessageModal
              show={showDownloadDocumentsMessage}
              message="Closeout documents will be sent to your email."
              onHide={this.handleHideDownloadDocumentsMessage}
            />
          )}
          {showCloseoutCatFormModal && (
            <CloseoutCatFormModal
              show={showCloseoutCatFormModal}
              onClose={this.handleCloseCloseoutCatFormModal}
              onSubmit={this.handleCloseCloseoutCatFormModal}
            />
          )}
        </React.Fragment>
      )
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    closeout: getCloseout(state),
  };
})(CloseoutForm);
