import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';

class ContractorsInvoicesApi extends BaseApi {
  getInvoices(page = 1, workOrderJobNumber = null) {
    let filter = '';
    if (workOrderJobNumber !== null && workOrderJobNumber !== '') {
      filter += `&by_job_number=${workOrderJobNumber}`;
    }
    return axios
      .get(`${this.baseUrl}/contractor_invoices?page=${page}${filter}`)
      .then((response) => {
        return {
          ok: true,
          invoices: {
            content: response.data,
            pageSize: numeral(response.headers['per-page']).value(),
            total: numeral(response.headers['total']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateInvoice(invoiceId, dueDate) {
    const data = {
      contractor_invoice: {
        due_date: dueDate,
      },
    };

    return axios
      .put(`${this.baseUrl}/contractor_invoices/${invoiceId}`, data)
      .then((response) => {
        return {
          ok: true,
          invoice: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  deleteInvoice(invoiceId) {
    return axios
      .delete(`${this.baseUrl}/contractor_invoices/${invoiceId}`)
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  markAsPaid(invoiceId) {
    return axios
      .put(`${this.baseUrl}/contractor_invoices/${invoiceId}/paid`)
      .then((response) => {
        return {
          ok: true,
          invoice: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export let contractorsInvoicesApi = new ContractorsInvoicesApi();
