import { showErrorMessage } from 'actions/messages';
import {
  CLIENTS_CLIENT_BUILDINGS_LOAD_SUCCESS,
  CLIENTS_CLIENT_FILTER_CHANGE,
  CLIENTS_CLIENT_INVITE_USER_SHOW,
  CLIENTS_CLIENT_INVOICES_LOAD_SUCCESS,
  CLIENTS_CLIENT_LOAD_SUCCESS,
  CLIENTS_CLIENT_NOTE_EDITOR_EDIT_SET,
  CLIENTS_CLIENT_NOTE_EDITOR_REMINDER_CHANGE,
  CLIENTS_CLIENT_NOTE_EDITOR_SAVING_SET,
  CLIENTS_CLIENT_NOTE_EDITOR_TEXT_CHANGE,
  CLIENTS_CLIENT_PAGE_CHANGE,
  CLIENTS_CLIENT_PURCHASE_ORDERS_SUCCESS,
  CLIENTS_CLIENT_ROLES_LOAD_SUCCESS,
  CLIENTS_CLIENT_SET,
  CLIENTS_CLIENT_SURVEYS_LOAD_SUCCESS,
  CLIENTS_CLIENT_USERS_ADD,
  CLIENTS_CLIENT_WORK_ORDERS_LOAD_SUCCESS,
  CLIENTS_CONTACTS_MODAL_SHOW,
  CLIENTS_INVITE_ATTRIBUTE_CHANGE,
  CLIENTS_INVITE_CLIENT_ADD,
  CLIENTS_INVITE_FORM_CLEAR,
  CLIENTS_INVITE_FORM_INVALID,
  CLIENTS_INVITE_FORM_VALID,
  CLIENTS_INVITE_USER_MODAL_SHOW,
  CLIENTS_LOAD_SUCCESS,
  CLIENTS_USERS_SET,
  CLIENTS_SET_PAGINATION_DATA,
  CLIENTS_ADD_TO_BEGINNING,
  CLIENTS_CLIENT_PROJECTS_LOAD_SUCCESS,
} from 'actions/admin/clients/action-types';
import { push } from 'react-router-redux';
import { clientsApi } from 'services/api/clients-api';
import { serviceProvidersApi } from 'services/api/service-providers-api';
import { rolesApi } from 'services/api/roles-api';
import { COMPANY_TYPES } from 'domain/company-type-enum';
import { USER_ROLES } from 'domain/user-role-enum';
import { buildingsApi } from 'services/api/buildings-api';
import { surveysApi } from 'services/api/surveys-api';

export const baseRoutePath = '/admin/clients';

export function addClient(client) {
  return {
    type: CLIENTS_INVITE_CLIENT_ADD,
    payload: client,
  };
}

export function addUsers(users) {
  return {
    type: CLIENTS_CLIENT_USERS_ADD,
    payload: users,
  };
}

export function changeFilter(filter) {
  return {
    type: CLIENTS_CLIENT_FILTER_CHANGE,
    payload: filter,
  };
}

export function changePage(newPage) {
  return {
    type: CLIENTS_CLIENT_PAGE_CHANGE,
    payload: newPage,
  };
}

export function clearClientForm() {
  return {
    type: CLIENTS_INVITE_FORM_CLEAR,
  };
}

export function loadBuildingsRequest(clientId) {
  return (dispatch) => {
    return buildingsApi.getBuildings(clientId).then((response) => {
      if (response.ok) {
        dispatch(loadBuildingsSuccess(response.buildings));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

function loadBuildingsSuccess(buildings) {
  return {
    type: CLIENTS_CLIENT_BUILDINGS_LOAD_SUCCESS,
    payload: buildings,
  };
}

export function loadRolesRequest() {
  return (dispatch) => {
    return rolesApi.getRoles(COMPANY_TYPES.CLIENT).then((response) => {
      if (response.ok) {
        dispatch(loadRolesSuccess(response.permissions));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadRolesSuccess(roles) {
  return {
    type: CLIENTS_CLIENT_ROLES_LOAD_SUCCESS,
    payload: roles,
  };
}

export function invalidForm(invalidFields, message) {
  return {
    type: CLIENTS_INVITE_FORM_INVALID,
    payload: { invalidFields, message },
  };
}

export function goToClient(clientId) {
  return (dispatch) => {
    dispatch(push(`${baseRoutePath}/${clientId}`));
  };
}

export function goToEditClient(clientId) {
  return (dispatch) => {
    dispatch(push(`${baseRoutePath}/${clientId}/edit`));
  };
}

export function loadClientsRequest(page, label) {
  return (dispatch) => {
    return clientsApi.getClients(page, label).then((response) => {
      if (response.ok) {
        dispatch(loadClientsSuccess(response.clients));

        if (page) {
          dispatch(
            setClientsPaginationData(page, response.total, response.pageSize)
          );
        }
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadClientsSuccess(clients) {
  return {
    type: CLIENTS_LOAD_SUCCESS,
    payload: clients,
  };
}

export function setClientsPaginationData(page, total, pageSize) {
  return {
    type: CLIENTS_SET_PAGINATION_DATA,
    payload: { page: page, total: total, pageSize: pageSize },
  };
}

export function loadClientRequest(clientId) {
  return (dispatch) => {
    return clientsApi.getClient(clientId).then((response) => {
      if (response.ok) {
        dispatch(loadClientSuccess(response.client));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function loadClientSuccess(client) {
  return {
    type: CLIENTS_CLIENT_LOAD_SUCCESS,
    payload: client,
  };
}

export function loadInvoicesRequest(buildingId, page, invoiceStatus) {
  return (dispatch) => {
    return clientsApi
      .getInvoices(buildingId, page, invoiceStatus)
      .then((response) => {
        if (response.ok) {
          dispatch(loadInvoicesSuccess(response.invoices));
          dispatch(changePage(page));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadInvoicesSuccess(invoices) {
  return {
    type: CLIENTS_CLIENT_INVOICES_LOAD_SUCCESS,
    payload: invoices,
  };
}

export function loadPurchaseOrdersRequest(clientId, page) {
  return (dispatch) => {
    return clientsApi.getPurchaseOrders(clientId, page).then((response) => {
      if (response.ok) {
        dispatch(loadPurchaseOrdersSuccess(response.purchaseOrders));
        dispatch(changePage(page));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadPurchaseOrdersSuccess(purchaseOrders) {
  return {
    type: CLIENTS_CLIENT_PURCHASE_ORDERS_SUCCESS,
    payload: purchaseOrders,
  };
}

export function loadSurveysRequest(clientId, page = 1) {
  return (dispatch) => {
    return surveysApi.getClientSurveys(clientId, page).then((response) => {
      if (response.ok) {
        dispatch(loadSurveysSuccess(response.surveys));
        dispatch(changePage(page));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

function loadSurveysSuccess(surveys) {
  return {
    type: CLIENTS_CLIENT_SURVEYS_LOAD_SUCCESS,
    payload: surveys,
  };
}

export function loadWorkOrdersRequest(clientId, page, workOrderStatus) {
  return (dispatch) => {
    return clientsApi
      .getWorkOrders(clientId, page, workOrderStatus)
      .then((response) => {
        if (response.ok) {
          dispatch(loadWorkOrdersSuccess(response.workOrders));
          dispatch(changePage(page));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadProjectsRequest(clientId, page, projectStatus) {
  return (dispatch) => {
    return clientsApi
      .getClientProjects(clientId, page, projectStatus)
      .then((response) => {
        if (response.ok) {
          dispatch(loadProjectsSuccess(response.projects));
          dispatch(changePage(page));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
}

export function loadWorkOrdersSuccess(workOrders) {
  return {
    type: CLIENTS_CLIENT_WORK_ORDERS_LOAD_SUCCESS,
    payload: workOrders,
  };
}

export function loadProjectsSuccess(projects) {
  return {
    type: CLIENTS_CLIENT_PROJECTS_LOAD_SUCCESS,
    payload: projects,
  };
}

function setClientUsers(users) {
  return {
    type: CLIENTS_USERS_SET,
    payload: users,
  };
}

export function setShowInviteClientModal(value) {
  return {
    type: CLIENTS_INVITE_USER_MODAL_SHOW,
    payload: value,
  };
}

export function setShowInviteUserClientModal(value) {
  return {
    type: CLIENTS_CLIENT_INVITE_USER_SHOW,
    payload: value,
  };
}

export function showContactsModal(value) {
  return {
    type: CLIENTS_CONTACTS_MODAL_SHOW,
    payload: value,
  };
}

export function showContactsRequest(clientId, users, buildings) {
  return (dispatch) => {
    if (users.length === 0 && buildings.length === 0) {
      let promises = [];
      promises.push(clientsApi.getUsers(clientId));
      promises.push(buildingsApi.getBuildings(clientId));

      return Promise.all(promises).then((values) => {
        const result = {};
        if (values[0].ok && values[1].ok) {
          dispatch(setClientUsers(values[0].users));
          dispatch(loadBuildingsSuccess(values[1].buildings));
          dispatch(showContactsModal(true));
          result.ok = true;
        } else {
          let errors = [];
          if (!values[0].ok) {
            errors = errors.concat(values[0].errors);
          }
          if (!values[1].ok) {
            errors = errors.concat(values[1].errors);
          }
          dispatch(showErrorMessage(errors));
          result.ok = false;
        }

        return result;
      });
    } else if (users.length === 0) {
      return clientsApi.getUsers(clientId).then((response) => {
        if (response.ok) {
          dispatch(setClientUsers(response.users));
          dispatch(showContactsModal(true));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
    } else if (buildings.length === 0) {
      return buildingsApi.getBuildings(clientId).then((response) => {
        if (response.ok) {
          dispatch(loadBuildingsSuccess(response.buildings));
          dispatch(showContactsModal(true));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
    } else {
      dispatch(showContactsModal(true));
    }
  };
}

export function convertInviteUserFormToApiData(form) {
  const data = {
    ...form,
    permissions: form.rolesId.map((id) => {
      return { id };
    }),
  };
  delete data.rolesId;
  return [data];
}

function setCurrentClient(value) {
  return {
    type: CLIENTS_CLIENT_SET,
    payload: value,
  };
}

export function setNoteEditorEdit(value) {
  return {
    type: CLIENTS_CLIENT_NOTE_EDITOR_EDIT_SET,
    payload: value,
  };
}

function setNotesEditorSaving(value) {
  return {
    type: CLIENTS_CLIENT_NOTE_EDITOR_SAVING_SET,
    payload: value,
  };
}

export function submitInviteUserFormRequest(form, clientId, roles) {
  return (dispatch) => {
    let businessMgrRoleSelected = false;
    const businessMaganerRole = roles.find(
      (role) => role.name === USER_ROLES.BUILDING_MANAGER
    );
    if (businessMaganerRole !== null) {
      businessMgrRoleSelected =
        form.rolesId.find((id) => id === businessMaganerRole.id) !== undefined;
    }

    // Validate form
    const requiredFields = ['firstName', 'lastName', 'email', 'phoneNumber'];
    if (businessMgrRoleSelected) {
      requiredFields.push('buildingsId');
    }
    const invalidFields = [];
    requiredFields.forEach((attribute) => {
      if (attribute === 'buildingsId') {
        if (form[attribute].length === 0) {
          invalidFields.push(attribute);
        }
      } else {
        if (form[attribute] === '' || form[attribute] === null) {
          invalidFields.push(attribute);
        }
      }
    });

    if (invalidFields.length > 0) {
      dispatch(
        invalidForm(
          invalidFields,
          'The outlined inputs should be added in order to invite a new user.'
        )
      );

      return null;
    } else {
      dispatch(validForm());
      return clientsApi
        .inviteUsers(clientId, convertInviteUserFormToApiData(form))
        .then((response) => {
          if (response.ok) {
            dispatch(addUsers(response.users));
            dispatch(setShowInviteUserClientModal(false));
          } else {
            dispatch(showErrorMessage(response.errors));
          }

          return response;
        });
    }
  };
}

export function submitFormRequest(form) {
  return (dispatch) => {
    // Validate form
    const requiredFields = [
      'companyName',
      'firstName',
      'lastName',
      'email',
      'phoneNumber',
      'streetAddress1',
      'city',
      'state',
      'zip',
    ];
    const invalidFields = [];
    requiredFields.forEach((attribute) => {
      if (form[attribute] === '') {
        invalidFields.push(attribute);
      }
    });

    if (invalidFields.length > 0) {
      dispatch(
        invalidForm(
          invalidFields,
          'The outlined inputs should be added in order to invite an owner.'
        )
      );

      return null;
    } else {
      dispatch(validForm());
      return serviceProvidersApi
        .findSimilar({
          company_type: 'Client',
          name: form.companyName,
          email: form.email,
          phone_number: form.phoneNumber,
        })
        .then((response) => {
          if (!response.ok) {
            dispatch(showErrorMessage(response.errors));
          }

          return { ...response, client: response.company };
        });
    }
  };
}

export function inviteClient(form) {
  return (dispatch) => {
    return clientsApi.inviteClient(form).then((response) => {
      if (response.ok) {
        dispatch(addClient(response.client));
        dispatch(setShowInviteClientModal(false));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function inviteExistentClient(clientId) {
  return (dispatch) => {
    return clientsApi.inviteExistent(clientId).then((response) => {
      if (response.ok) {
        dispatch(addClient(response.client));
        dispatch(setShowInviteClientModal(false));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function updateFormValue(attribute, value) {
  return {
    type: CLIENTS_INVITE_ATTRIBUTE_CHANGE,
    payload: { attribute, value },
  };
}

export function updateNotes(clientId, text, reminder) {
  return (dispatch) => {
    dispatch(setNotesEditorSaving(true));
    return clientsApi.updateNotes(clientId, text, reminder).then((response) => {
      dispatch(setNoteEditorEdit(false));
      dispatch(setNotesEditorSaving(false));
      if (response.ok) {
        dispatch(setCurrentClient(response.client));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function updateNoteEditorReminder(value) {
  return {
    type: CLIENTS_CLIENT_NOTE_EDITOR_REMINDER_CHANGE,
    payload: value,
  };
}

export function updateNoteEditorText(value) {
  return {
    type: CLIENTS_CLIENT_NOTE_EDITOR_TEXT_CHANGE,
    payload: value,
  };
}

export function validForm() {
  return {
    type: CLIENTS_INVITE_FORM_VALID,
  };
}

export function addClientToBeginning(client) {
  return {
    type: CLIENTS_ADD_TO_BEGINNING,
    payload: client,
  };
}

export function updateClientData(clientId, clientData) {
  return (dispatch) => {
    return clientsApi.updateClient(clientId, clientData).then((response) => {
      if (response.ok) {
        dispatch(setCurrentClient(response.client));
        dispatch(goToClient(clientId));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}
