import React from 'react';
import { connect } from 'react-redux';

import MultipleSelector from 'components/shared/selector/MultipleSelector';

import Project from 'domain/project';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import {
  getProjectProposal,
  getCsiCodes,
} from 'components/admin/projects/details/project-proposal/store/selectors';

const ProjectPhotosFilterTrades = (props) => {
  const { value, onChange } = props;

  const onFilter = (trades) => onChange({ trades });
  const tradeOptions = Project.csiCodesOptions(props);

  return (
    <MultipleSelector
      onChange={onFilter}
      value={value || []}
      className="trades-dropdown"
      placeholder="Trade"
      options={tradeOptions}
    />
  );
};

export default connect((state) => ({
  directory: getDirectory(state),
  project: getCurrentProject(state),
  projectProposal: getProjectProposal(state),
  currentUser: getCurrentUser(state),
  csiCodes: getCsiCodes(state),
}))(ProjectPhotosFilterTrades);
