import { createSelector } from 'reselect';

export const getDirectory = createSelector(
  (state) => state.projects.scenes.details.scenes.directory.data.directory,
  (directory) => {
    return directory;
  }
);

export const getDirectoryPdf = createSelector(
  (state) => state.projects.scenes.details.scenes.directory.data.directoryPdf,
  (directoryPdf) => {
    return directoryPdf;
  }
);

export const getShowNewContributorModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.directory.ui.showNewContributorModal,
  (showNewContributorModal) => {
    return showNewContributorModal;
  }
);

export const getShowEditUserModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.directory.ui.showEditUserModal,
  (showEditUserModal) => {
    return showEditUserModal;
  }
);

export const getCompanyUsers = createSelector(
  (state) => state.projects.scenes.details.scenes.directory.data.companyUsers,
  (companyUsers) => {
    return companyUsers;
  }
);

export const getRoles = createSelector(
  (state) => state.projects.scenes.details.scenes.directory.data.roles,
  (roles) => {
    return roles;
  }
);

export const getShowNewCompanyForm = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.directory.ui.showNewCompanyForm,
  (showNewCompanyForm) => {
    return showNewCompanyForm;
  }
);

export const getShowSelectUsersForm = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.directory.ui.showSelectUsersForm,
  (showSelectUsersForm) => {
    return showSelectUsersForm;
  }
);

export const getShowInviteUserForm = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.directory.ui.showInviteUserForm,
  (showInviteUserForm) => {
    return showInviteUserForm;
  }
);

export const getShowDirectoryPdfViewer = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.directory.ui.showDirectoryPdfViewer,
  (showPdfViewer) => {
    return showPdfViewer;
  }
);

export const getCurrentContributor = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.directory.ui.currentContributor,
  (currentContributor) => {
    return currentContributor;
  }
);

export const getNewContributorType = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.directory.ui.newContributorType,
  (newContributorType) => {
    return newContributorType;
  }
);

export const getContributorSettings = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.directory.ui.contributorSettings,
  (contributorSettings) => {
    return contributorSettings;
  }
);

export const getUserSettings = createSelector(
  (state) => state.projects.scenes.details.scenes.directory.ui.userSettings,
  (userSettings) => {
    return userSettings;
  }
);

export const getEditedUser = createSelector(
  (state) => state.projects.scenes.details.scenes.directory.ui.editedUser,
  (editedUser) => {
    return editedUser;
  }
);

export const getShowNotificationSettingsModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.directory.ui
      .showNotificationSettingsModal,
  (showNotificationSettingsModal) => {
    return showNotificationSettingsModal;
  }
);
