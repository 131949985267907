import {
  ACR_EDIT_SET,
  ACR_SAVING_SET,
  ACR_ADDITIONAL_COST_EDIT_SET,
  ACR_ADDITIONAL_COST_SAVING_SET,
} from 'components/admin/work-orders/details/anticipated-cost-report/store/action-types';

const initialState = {
  edit: {},
  saving: {},
  additionalCostEdit: false,
  additionalCostSaving: false,
};

export default function anticipatedCostReportUiReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case ACR_EDIT_SET:
      return {
        ...state,
        edit: {
          ...state.edit,
          [action.payload]: !state.edit[action.payload],
        },
      };

    case ACR_SAVING_SET:
      return {
        ...state,
        saving: {
          ...state.saving,
          [action.payload]: !state.saving[action.payload],
        },
      };

    case ACR_ADDITIONAL_COST_EDIT_SET:
      return {
        ...state,
        additionalCostEdit: !state.additionalCostEdit,
      };

    case ACR_ADDITIONAL_COST_SAVING_SET:
      return {
        ...state,
        additionalCostSaving: !state.additionalCostSaving,
      };

    default:
      return state;
  }
}
