import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './RfiActions.css';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import {
  setRfiFormErrors,
  setRfiQuestionErrors,
  createRfi,
  updateRfi,
  respondRfi,
  respondRfiAddlAssignee,
  setRfiGcAcceleration,
  deleteRfi,
  setSetShowRfiErrorMessage,
  setRfiResponseModalShow,
  setRfiResponsesViewModalShow,
  setRfiFormValues,
} from 'components/admin/projects/details/project-rfi-log/store/actions';
import { RfiStatusKeys, RfiStatusEnum } from 'domain/rfi-status-enum';
import { getRfiForm } from 'components/admin/projects/details/project-rfi-log/store/selectors';
import {
  getRfiQuestions,
  getRfiQuestionsDeleted,
  getRfiShowErrorMessage,
} from 'components/admin/projects/details/project-rfi-log/store/selectors';

import RfiPermissions from 'permissions/rfi-permissions';
import Rfi from 'domain/rfi';

class RfiActions extends Component {
  static propTypes = {
    currentUser: PropTypes.shape({}).isRequired,
    contribType: PropTypes.string.isRequired,
    project: PropTypes.shape({}).isRequired,
    directory: PropTypes.shape({}).isRequired,
    currentRfi: PropTypes.shape({}),
    modalReadOnly: PropTypes.bool,
    readOnly: PropTypes.bool,
    gcAcceleration: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { showDeleteConfirmation: false };
  }

  addlAssigneeQuestions = (questions) => {
    const { currentUser } = this.props;
    return questions.filter(
      (question) =>
        !!question.rfi_addl_assignees.find(
          (addlAssignee) => addlAssignee.assignee_id === currentUser.id
        )
    );
  };
  assignedQuestions = () => {
    const { currentUser, currentRfi } = this.props;
    return currentRfi
      ? currentRfi.rfi_questions.filter(
          (question) =>
            question.assignee_id === currentUser.id ||
            !!question.rfi_addl_assignees.find(
              (addlAssignee) => addlAssignee.assignee_id === currentUser.id
            )
        )
      : [];
  };

  validateForm = (draft = false) => {
    const { readOnly, form, dispatch } = this.props;
    const errors = {};
    const status = RfiStatusEnum.create(form.status);

    if (!readOnly) {
      if (!form.csiCodeId) {
        errors.csiCodeId = true;
      }
      if (!draft) {
        if (!form.dueDate && !RfiStatusEnum.FOR_RECORD.equals(status)) {
          errors.dueDate = true;
        }
        if (!form.status) {
          errors.status = true;
        }
        if (!form.title) {
          errors.title = true;
        }
      }
      dispatch(setRfiFormErrors(errors));
    }
    return Object.keys(errors).length === 0;
  };

  validateQuestions = (draft = false) => {
    const { gcAcceleration, form, questions, dispatch } = this.props;
    const status = RfiStatusEnum.create(form.status);
    const assignedQuestions = this.assignedQuestions();
    const oneAssignedQuestion =
      assignedQuestions && assignedQuestions.length === 1;
    let valid = true;
    Object.keys(questions).forEach((key) => {
      const question = questions[key];
      const assigned =
        oneAssignedQuestion && assignedQuestions[0].id === question.id;
      const errors = {};
      if (!question.action) {
        errors.action = true;
      }
      if (!question.question) {
        errors.question = true;
      }
      if (
        !draft &&
        RfiStatusEnum.ASSIGNED.equals(status) &&
        question.assignee.length === 0
      ) {
        errors.assignee = true;
      }
      if (
        !draft &&
        (assigned || gcAcceleration) &&
        question.id &&
        !question.response
      ) {
        errors.response = true;
      }
      dispatch(setRfiQuestionErrors(key, errors));
      valid = valid && Object.keys(errors).length === 0;
    });
    return valid;
  };

  validate = (draft = false) => {
    const validForm = this.validateForm(draft);
    const validQuestions = this.validateQuestions(draft);
    return validForm && validQuestions;
  };

  handleSaveDraft = () => {
    this.handleSubmit(true);
  };

  handleOpenResponseModal = () => {
    const { currentUser, project, directory, currentRfi } = this.props;
    const canAccelerate = RfiPermissions.canAccelerate(
      currentUser,
      project,
      directory,
      currentRfi
    );
    if (canAccelerate) this.handleGcAcceleration();
    this.props.dispatch(setRfiFormValues(currentRfi));
    this.props.dispatch(setRfiResponseModalShow(true));
  };

  handleOpenViewResponsesModal = () => {
    this.props.dispatch(setRfiResponsesViewModalShow(true));
  };

  handleSubmit = (draft = false) => {
    if (!this.validate(draft)) {
      this.props.dispatch(setSetShowRfiErrorMessage(true));
      return;
    }
    const questions = Object.keys(this.props.questions)
      .map((key) => this.props.questions[key])
      .map((qus) => {
        return {
          ...qus,
          assigneeId: qus.assignee[0] || '',
          draftResponse: draft,
          reqImages: qus.reqImages || qus.request_images,
          reqDocs: qus.reqDocs || qus.request_documents,
        };
      });
    const { form } = this.props;
    const rfi = {
      ...form,
      relCsiCodeIds: form.relCsiCodes.map((csiCode) => csiCode[0]),
      recipientIds: form.recipients.map((recpt) => recpt[0]),
      draft: draft || '',
      status: draft ? RfiStatusKeys.DRAFT : form.status,
      questions: questions,
      questionsDeleted: this.props.questionsDeleted,
    };
    const {
      currentUser,
      project,
      currentRfi,
      gcAcceleration,
      readOnly,
      handleClose,
      dispatch,
    } = this.props;
    const promises = [];

    if (currentRfi) {
      if (!readOnly) {
        promises.push(dispatch(updateRfi(project.id, currentRfi.id, rfi)));
      }

      if (gcAcceleration) {
        promises.push(dispatch(respondRfi(project.id, currentRfi.id, rfi)));
      } else {
        if (Rfi.isAssignedToUser(currentUser, currentRfi)) {
          rfi.questions = rfi.questions.filter(
            (qus) => qus.assignee[0] === currentUser.id
          );
          promises.push(dispatch(respondRfi(project.id, currentRfi.id, rfi)));
        }

        if (Rfi.isAddlAssignedToUser(currentUser, currentRfi)) {
          promises.push(
            dispatch(
              respondRfiAddlAssignee(
                currentUser.id,
                this.addlAssigneeQuestions(questions)
              )
            )
          );
        }
      }
    } else {
      promises.push(dispatch(createRfi(project.id, rfi)));
    }

    Promise.all(promises).then((responses) => {
      if (!responses.find((response) => !response.ok)) {
        handleClose();
      }
    });
  };

  handleGcAcceleration = () => {
    this.props.dispatch(setRfiGcAcceleration(true));
  };

  handleDiscardDraft = () => {
    this.setState({ showDeleteConfirmation: true });
  };

  handleDeleteConfirmation = (confirmed) => {
    this.setState({ showDeleteConfirmation: false });
    confirmed && this.handleDelete();
  };

  handleDelete = () => {
    const { project, currentRfi, handleClose, dispatch } = this.props;
    dispatch(deleteRfi(project.id, currentRfi.id)).then((response) => {
      if (response.ok) {
        handleClose();
      }
    });
  };

  render() {
    const {
      currentUser,
      project,
      directory,
      currentRfi,
      modalReadOnly,
      readOnly,
      gcAcceleration,
      showErrorMessage,
      handleClose,
    } = this.props;

    const { showDeleteConfirmation } = this.state;

    const canDiscard = RfiPermissions.canDiscard(currentRfi);

    const canAccelerate =
      !modalReadOnly &&
      !gcAcceleration &&
      RfiPermissions.canAccelerate(currentUser, project, directory, currentRfi);
    const canSaveDraft =
      !modalReadOnly && RfiPermissions.canSaveDraft(currentUser, currentRfi);

    const assigned =
      Rfi.isAssignedToUser(currentUser, currentRfi) ||
      Rfi.isAddlAssignedToUser(currentUser, currentRfi);
    const canSubmit = !readOnly || (!modalReadOnly && assigned);

    const canRespond = assigned || canAccelerate || gcAcceleration;

    return (
      <div className="rfi-actions">
        {showErrorMessage && (
          <h4 className="error-message">
            There are missing fields, please review.
          </h4>
        )}
        <div className="buttons-container">
          {canDiscard && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Discard"
              onClick={this.handleDiscardDraft}
            />
          )}
          {!modalReadOnly && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Cancel"
              onClick={handleClose}
            />
          )}
          {canSaveDraft && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Save Draft"
              onClick={this.handleSaveDraft}
            />
          )}
          {canSubmit && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Submit"
              onClick={() => this.handleSubmit()}
            />
          )}
          {currentRfi && currentRfi.id && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="View Responses"
              onClick={() => this.handleOpenViewResponsesModal()}
            />
          )}
          {canRespond && (
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Respond"
              onClick={() => this.handleOpenResponseModal()}
            />
          )}
        </div>
        {showDeleteConfirmation && (
          <ConfirmationModal
            show={showDeleteConfirmation}
            message="Are you sure you want to discard this RFI?"
            onHide={this.handleDeleteConfirmation}
          />
        )}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    form: getRfiForm(state),
    questions: getRfiQuestions(state),
    questionsDeleted: getRfiQuestionsDeleted(state),
    showErrorMessage: getRfiShowErrorMessage(state),
  };
})(RfiActions);
