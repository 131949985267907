import { createSelector } from 'reselect';

export const getCurrentUserId = createSelector(
  (state) => state.team.scenes.contractor.ui.currentUserId,
  (currentUserId) => {
    return currentUserId;
  }
);

export const getCurrentTechnicianId = createSelector(
  (state) => state.team.scenes.contractor.ui.currentTechnicianId,
  (currentTechnicianId) => {
    return currentTechnicianId;
  }
);

export const getForm = createSelector(
  (state) => state.team.scenes.contractor.ui.form,
  (form) => {
    return form;
  }
);

export const getCurrentPageTechnicians = createSelector(
  (state) => state.team.scenes.contractor.ui.currentPageTechnicians,
  (currentPageTechnicians) => {
    return currentPageTechnicians;
  }
);

export const getPerPageTechnicians = createSelector(
  (state) => state.team.scenes.contractor.ui.perPageTechnicians,
  (perPageTechnicians) => {
    return perPageTechnicians;
  }
);

export const getTotalTechnicians = createSelector(
  (state) => state.team.scenes.contractor.ui.totalTechnicians,
  (totalTechnicians) => {
    return totalTechnicians;
  }
);

export const getActiveTab = createSelector(
  (state) => state.team.scenes.contractor.ui.activeTab,
  (activeTab) => {
    return activeTab;
  }
);

export const getRoles = createSelector(
  (state) => state.team.scenes.contractor.data.roles,
  (roles) => {
    return roles;
  }
);

export const getUsers = createSelector(
  (state) => state.team.scenes.contractor.data.users,
  (users) => {
    return users;
  }
);

export const getTechnicians = createSelector(
  (state) => state.team.scenes.contractor.data.technicians,
  (technicians) => {
    return technicians;
  }
);

export const getShowInviteUserModal = createSelector(
  (state) => state.team.scenes.contractor.ui.showInviteUserModal,
  (showInviteUserModal) => {
    return showInviteUserModal;
  }
);

export const getShowInviteTechnicianModal = createSelector(
  (state) => state.team.scenes.contractor.ui.showInviteTechnicianModal,
  (showInviteTechnicianModal) => {
    return showInviteTechnicianModal;
  }
);

export const getInvalidFields = createSelector(
  (state) => state.team.scenes.contractor.ui.invalidFields,
  (invalidFields) => {
    return invalidFields;
  }
);

export const getUserModalState = createSelector(
  (state) => state.team.scenes.contractor.ui.userModalState,
  (userModalState) => {
    return userModalState;
  }
);

export const getTechnicianModalState = createSelector(
  (state) => state.team.scenes.contractor.ui.technicianModalState,
  (technicianModalState) => {
    return technicianModalState;
  }
);

export const getValidationMessage = createSelector(
  (state) => state.team.scenes.contractor.ui.validationMessage,
  (validationMessage) => {
    return validationMessage;
  }
);

export const getVerificationModal = createSelector(
  (state) => state.team.scenes.contractor.ui.verificationModal,
  (verificationModal) => {
    return verificationModal;
  }
);
