import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './ProjectOpenItemsTable.css';
import SimpleTable from 'components/shared/table/SimpleTable';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import Date from 'components/shared/text/Date';
import ProjectOpenItemDetailsModal from 'components/admin/projects/details/project-open-items/ProjectOpenItemDetailsModal';
import { formatNumber } from 'services/utils/text-util';
import {
  getProjectOpenItems,
  getCurrentStatus,
  getTotal,
  getCurrentPage,
  getPageSize,
} from 'components/admin/project-open-items/store/selectors';
import { loadProjectRequest } from 'components/admin/projects/details/store/actions';
import {
  loadOpenItem,
  setOpenItemsCritical,
} from 'components/admin/projects/details/project-open-items/store/actions';

class ProjectOpenItemsTable extends Component {
  static propTypes = {
    onPageChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { showModal: false };
  }

  handleGenerateLink = (id) => {
    const { projectOpenItems } = this.props;
    const poi = projectOpenItems.find(
      (projectOpenItem) => projectOpenItem.id === id
    );
    const projId = poi.project_id;
    let query = `projectOpenItemId=${id}`;
    if (poi.critical) {
      query += `&projectOpenItemCritical=${poi.critical}`;
    }

    return `projects/${projId}?${query}`;
  };

  open = (id) => {
    const { projectOpenItems, dispatch } = this.props;
    const projectOpenItem = projectOpenItems.find(
      (projectOpenItem) => projectOpenItem.id === id
    );
    this.setState({ showModal: true });
    dispatch(loadProjectRequest(projectOpenItem.project_id));
    dispatch(setOpenItemsCritical(projectOpenItem.critical));
    dispatch(loadOpenItem(projectOpenItem.project_id, projectOpenItem.id));
  };

  close = () => {
    const { currentPage, currentStatus, onPageChange } = this.props;
    this.setState({ showModal: false });
    onPageChange(currentPage, currentStatus);
  };

  mapProjectOpenItems = () => {
    return this.props.projectOpenItems.map((projectOpenItem) => {
      return {
        id: projectOpenItem.id,
        data: [
          projectOpenItem.project_number,
          projectOpenItem.project_name,
          formatNumber(projectOpenItem.number),
          (projectOpenItem.csi_code && projectOpenItem.csi_code.division) ||
            '00',
          projectOpenItem.description || '-',
          projectOpenItem.resolve_by ? (
            <Date
              className="due-date"
              value={projectOpenItem.resolve_by}
              format="MM/DD/YYYY"
            />
          ) : (
            '-'
          ),
        ],
      };
    });
  };

  render() {
    const {
      currentStatus,
      total,
      currentPage,
      pageSize,
      onPageChange,
    } = this.props;
    const { showModal } = this.state;

    return (
      <div className="project-open-items-all-table">
        <SimpleTable
          className="projects-table"
          headers={[
            'PROJECT NO',
            'PROJECT NAME',
            'ITEM NO',
            'COST CODE',
            'DESCRIPTION',
            'DATE DUE',
          ]}
          rows={this.mapProjectOpenItems()}
          emptyMessage="No Open Items"
          onRowSelected={this.open}
          onGenerateLink={this.handleGenerateLink}
        />
        <div className="pagination-container">
          <SimplePagination
            currentPage={currentPage}
            pageSize={pageSize}
            total={total}
            onPageChange={(page) => onPageChange(page, currentStatus)}
          />
        </div>

        {showModal && <ProjectOpenItemDetailsModal onClose={this.close} />}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    projectOpenItems: getProjectOpenItems(state),
    currentStatus: getCurrentStatus(state),
    total: getTotal(state),
    currentPage: getCurrentPage(state),
    pageSize: getPageSize(state),
  };
})(ProjectOpenItemsTable);
