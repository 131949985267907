import React from 'react';

import { Grid } from '@mui/material';
import DatePicker from 'components/shared/date-picker/DatePicker';
import {
  sameOrAfterDateValidation,
  sameOrBeforeTodayValidation,
  sameOrBeforeDateValidation,
} from 'services/utils/date-util';

const ProjectPhotosFilterDate = (props) => {
  const { from, to, onChange } = props;

  const onFilter = (attr) => (value) => onChange({ [attr]: value });

  return (
    <Grid container spacing={3}>
      <Grid item>
        <DatePicker
          clearable
          placeholder="From"
          value={from}
          className="create-date"
          onChange={onFilter('from')}
          isValidDate={(date) =>
            sameOrBeforeDateValidation(date, to) &&
            sameOrBeforeTodayValidation(date)
          }
        />
      </Grid>
      <Grid item>
        <DatePicker
          clearable
          placeholder="To"
          value={to}
          className="create-date"
          onChange={onFilter('to')}
          isValidDate={(date) => sameOrAfterDateValidation(date, from)}
        />
      </Grid>
    </Grid>
  );
};

export default ProjectPhotosFilterDate;
