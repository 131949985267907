import {
  COI_EXPS_SET,
  COI_EXP_SET,
  COI_EXP_DOCS_SET,
  LIABILITIES_SET,
  COI_EXP_DOCS_ADD,
  COI_EXP_DOCS_UPDATE,
  COI_EXP_DOCS_DESTROY,
} from 'components/admin/projects/details/project-coi-exps/store/action-types';

const initialState = {
  coiExps: null,
  coiExpsCurrentPage: 1,
  coiExpsTotal: 0,
  coiExpsPageSize: 5,
  coiExpDocs: {},
  liabilities: null,
};

export default function projectCoiExpsDataReducer(
  state = initialState,
  action
) {
  const { coiExpId, coiExpDoc } = action.payload || {};

  switch (action.type) {
    case COI_EXPS_SET:
      return {
        ...state,
        ...action.payload,
      };

    case COI_EXP_SET:
      return {
        ...state,
        coiExps: state.coiExps.map((coiExp) => {
          if (coiExp.id === action.payload.id) {
            return action.payload;
          } else {
            return coiExp;
          }
        }),
      };

    case COI_EXP_DOCS_SET:
      return {
        ...state,
        coiExpDocs: {
          ...state.coiExpDocs,
          [coiExpId]: action.payload,
        },
      };

    case LIABILITIES_SET:
      return {
        ...state,
        liabilities: action.payload,
      };

    case COI_EXP_DOCS_ADD:
      return {
        ...state,
        coiExps: state.coiExps.map((coiExp) => {
          if (
            coiExp.id === coiExpId &&
            coiExpDoc.subcategory === 'client_landlord'
          ) {
            return {
              ...coiExp,
              coi_exp_docs_count: coiExp.coi_exp_docs_count + 1,
            };
          } else {
            return coiExp;
          }
        }),
        coiExpDocs: {
          ...state.coiExpDocs,
          [coiExpId]: {
            ...state.coiExpDocs[coiExpId],
            coiExpDocs: [coiExpDoc, ...state.coiExpDocs[coiExpId].coiExpDocs],
          },
        },
      };

    case COI_EXP_DOCS_UPDATE:
      return {
        ...state,
        coiExpDocs: {
          ...state.coiExpDocs,
          [coiExpId]: {
            ...state.coiExpDocs[coiExpId],
            coiExpDocs: state.coiExpDocs[coiExpId].coiExpDocs.map((doc) => {
              if (doc.id === coiExpDoc.id) {
                return coiExpDoc;
              } else {
                return doc;
              }
            }),
          },
        },
      };

    case COI_EXP_DOCS_DESTROY:
      return {
        ...state,
        coiExps: state.coiExps.map((coiExp) => {
          if (
            coiExp.id === coiExpId &&
            coiExpDoc.subcategory === 'client_landlord'
          ) {
            return {
              ...coiExp,
              coi_exp_docs_count: coiExp.coi_exp_docs_count - 1,
            };
          } else {
            return coiExp;
          }
        }),
        coiExpDocs: {
          ...state.coiExpDocs,
          [coiExpId]: {
            ...state.coiExpDocs[coiExpId],
            coiExpDocs: state.coiExpDocs[coiExpId].coiExpDocs.filter(
              (doc) => doc.id !== coiExpDoc.id
            ),
          },
        },
      };

    default:
      return state;
  }
}
