import { capitalize } from '@mui/material';
import { useSnackbar as useSnackbarRoot } from 'notistack';

const VARIANTS = ['error', 'success', 'info'];

const useSnackbar = () => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbarRoot();

  return {
    showSnackbar: (message, props) => enqueueSnackbar(message, props),
    closeSnackbar: (message) => closeSnackbar(message),
    cleanSnackbar: () => closeSnackbar(),
    ...VARIANTS.reduce((methods, variant) => ({
      ...methods,
      [`show${capitalize(variant)}`]: (message, props = {}) =>
        enqueueSnackbar(message, { ...props, variant }),
    })),
  };
};

export default useSnackbar;
