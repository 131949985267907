import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MessageModal from 'components/shared/modal/MessageModal';

class RunReportModal extends Component {
  static propTypes = {
    runningReport: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onReload: PropTypes.func.isRequired,
  };

  state = {
    intervalId: null,
  };

  componentDidMount() {
    const { onReload } = this.props;

    const intervalId = setInterval(onReload, 5000);
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  render() {
    const {
      runningReport: { status },
      onClose,
    } = this.props;
    const done = status === 'done';

    return (
      <MessageModal
        show={true}
        onHide={onClose}
        message={done ? 'Report Complete' : 'Running Report...'}
        buttonMessage={done ? 'Download' : 'Cancel'}
      />
    );
  }
}

export default RunReportModal;
