import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './FieldWorkOrdersTable.css';
import SimpleTable from 'components/shared/table/SimpleTable';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import Date from 'components/shared/text/Date';
import FieldWorkOrderModal from 'components/admin/projects/details/project-daily-report-log/FieldWorkOrderModal';
import {
  getFieldWorkOrders,
  getTotal,
  getCurrentPage,
  getPageSize,
} from 'components/admin/field-work-orders/store/selectors';
import { loadProjectRequest } from 'components/admin/projects/details/store/actions';
import { openFieldWorkOrder } from 'components/admin/projects/details/project-daily-report-log/store/actions';

class FieldWorkOrdersTable extends Component {
  static propTypes = {
    onPageChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { showModal: false };
  }

  handleGenerateLink = (id) => {
    const { fieldWorkOrders } = this.props;
    const fwo = fieldWorkOrders.find((fwo) => fwo.id === id);
    return `projects/${fwo.project_id}?fieldWorkOrderId=${id}`;
  };

  open = (id) => {
    const fieldWorkOrder = this.props.fieldWorkOrders.find(
      (fieldWorkOrder) => fieldWorkOrder.id === id
    );
    const { dispatch } = this.props;
    this.setState({ showModal: true });
    dispatch(loadProjectRequest(fieldWorkOrder.project_id));
    dispatch(openFieldWorkOrder(fieldWorkOrder.project_id, fieldWorkOrder.id));
  };

  close = () => {
    const { currentPage, onPageChange } = this.props;
    this.setState({ showModal: false });
    onPageChange(currentPage);
  };

  mapFieldWorkOrders = () => {
    return this.props.fieldWorkOrders.map((fieldWorkOrder) => {
      return {
        id: fieldWorkOrder.id,
        data: [
          fieldWorkOrder.project_number,
          fieldWorkOrder.project_name,
          fieldWorkOrder.number,
          fieldWorkOrder.csi_code.division,
          fieldWorkOrder.work_performed || '-',
          fieldWorkOrder.start_date ? (
            <Date
              className="date-field"
              value={fieldWorkOrder.start_date}
              format="MM/DD/YYYY"
            />
          ) : (
            'TBD'
          ),
          fieldWorkOrder.completion_date ? (
            <Date
              className="date-field"
              value={fieldWorkOrder.completion_date}
              format="MM/DD/YYYY"
            />
          ) : (
            'TBD'
          ),
        ],
      };
    });
  };

  render() {
    const { total, currentPage, pageSize, onPageChange } = this.props;
    const { showModal } = this.state;
    return (
      <div className="field-work-orders-table">
        <SimpleTable
          className="projects-table"
          headers={[
            'PROJECT NO',
            'PROJECT NAME',
            '#',
            'COST CODE',
            'WORK TO BE PERFORMED',
            'START',
            'COMPLETION',
          ]}
          rows={this.mapFieldWorkOrders()}
          emptyMessage="No EWO's added yet."
          onRowSelected={this.open}
          onGenerateLink={this.handleGenerateLink}
        />
        <div className="pagination-container">
          <SimplePagination
            currentPage={currentPage}
            pageSize={pageSize}
            total={total}
            onPageChange={onPageChange}
          />
        </div>

        {showModal && <FieldWorkOrderModal onClose={this.close} />}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    fieldWorkOrders: getFieldWorkOrders(state),
    total: getTotal(state),
    currentPage: getCurrentPage(state),
    pageSize: getPageSize(state),
  };
})(FieldWorkOrdersTable);
