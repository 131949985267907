import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getMessagesErrors } from '../../../selectors/messages';
import './Messages.css';
import closePng from '../../../images/close.png';
import invalidPng from '../../../images/invalid.png';
import { closeErrorMessage } from '../../../actions/messages';

class Messages extends Component {
  constructor(props) {
    super(props);
    this.timeoutIds = {}; // { errorId: number, timeoutId: number }
    this.defaultDuration = 5000;
  }

  render() {
    return this.props.errors.length > 0 ? (
      <div className="messages">
        {this.props.errors.map((element) => {
          return this.renderErrorMessage(element);
        })}
      </div>
    ) : null;
  }

  renderErrorMessage(error) {
    if (this.timeoutIds[error.id] === undefined) {
      this.timeoutIds[error.id] = setTimeout(() => {
        // Update local timeout id object
        delete this.timeoutIds[error.id];

        this.props.dispatch(closeErrorMessage(error.id));
      }, this.defaultDuration);
    }

    return (
      <div key={error.id} className="error-message">
        <div>
          <img src={invalidPng} alt="invalid" />
        </div>
        <div>{error.message}</div>
        <div>
          <img
            src={closePng}
            alt="close"
            onClick={(event) => this.handleErrorClose(event, error.id)}
          />
        </div>
      </div>
    );
  }

  handleErrorClose = (event, id) => {
    event.preventDefault();

    // Clear timeout for current error and update local timeout id object
    clearTimeout(this.timeoutIds[id]);
    delete this.timeoutIds[id];

    this.props.dispatch(closeErrorMessage(id));
  };
}

export default connect((state) => {
  return {
    errors: getMessagesErrors(state),
  };
})(Messages);
