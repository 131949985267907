import React from 'react';
import { connect } from 'react-redux';

import FormModal from 'components/shared/modal/FormModal';

import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { MODAL_ICONS } from 'components/shared/modal/MessageModal';
import { getDarkMode } from 'selectors/theme';
import {
  setPendingCorrectionsErrorModalShow,
  setReviewCorrectionsModalShow,
} from './store/actions';
import { getPendingCorrectionsErrorModalShow } from './store/selectors';
import './AttendanceForm.css';
import './MeetingModal.css';
import './CorrectionsSubmitModal.css';

const PendingCorrectionsErrorModal = (props) => {
  const { darkMode, dispatch, show } = props;
  const handleClose = () => {
    dispatch(setPendingCorrectionsErrorModalShow(false));
  };

  const handleContinue = () => {
    dispatch(setPendingCorrectionsErrorModalShow(false));
    dispatch(setReviewCorrectionsModalShow(true));
  };

  return (
    <FormModal
      className={`meeting-modal editing`}
      show={show}
      onClose={handleClose}
      subTitle="Error"
    >
      <div className="messages-container">
        <img
          className="exclamation"
          src={
            darkMode
              ? MODAL_ICONS.EXCLAMATION.dark
              : MODAL_ICONS.EXCLAMATION.default
          }
          alt={MODAL_ICONS.EXCLAMATION.alt}
        />
        <div className="main-message">
          Review of all Meeting Corrections is required prior to creating the
          next Meeting Minutes.
        </div>
      </div>
      <div className="button-actions">
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.WHITE}
          label={'Close'}
          onClick={handleClose}
        />
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          label={'Continue'}
          onClick={handleContinue}
        />
      </div>
    </FormModal>
  );
};

export default connect((state) => ({
  show: getPendingCorrectionsErrorModalShow(state),
  project: getCurrentProject(state),
  darkMode: getDarkMode(state),
}))(PendingCorrectionsErrorModal);
