import {
  WORK_ORDERS_DETAILS_NEW_TASK_ATTRIBUTE_CHANGE,
  WORK_ORDERS_DETAILS_NEW_TASK_FORM_CLEAR,
  WORK_ORDERS_DETAILS_NEW_TASK_IMAGE_REMOVE,
  WORK_ORDERS_DETAILS_NEW_TASK_MODAL_SHOW,
} from 'actions/admin/work-orders/action-types';

const initialState = {
  form: {
    completionDate: null,
    description: '',
    floorNumber: '',
    images: [],
    name: '',
    number: 0,
    room: '',
    subTrade: 0,
    trade: 0,
  },
  showModal: false,
};

export default function newTaskUiReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_DETAILS_NEW_TASK_ATTRIBUTE_CHANGE:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case WORK_ORDERS_DETAILS_NEW_TASK_FORM_CLEAR:
      return {
        ...state,
        form: initialState.form,
      };

    case WORK_ORDERS_DETAILS_NEW_TASK_IMAGE_REMOVE:
      return {
        ...state,
        form: {
          ...state.form,
          images: state.form.images.filter(
            (image, index) => index !== action.payload
          ),
        },
      };

    case WORK_ORDERS_DETAILS_NEW_TASK_MODAL_SHOW:
      return {
        ...state,
        showModal: action.payload,
      };

    default:
      return state;
  }
}
