import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './DuplicateDailyReportModal.css';
import FormModal from 'components/shared/modal/FormModal';
import DatePicker from 'components/shared/date-picker/DatePicker';

import { simpleScrollToTop } from 'services/utils/scroller-util';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentDailyReport } from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import {
  duplicateDailyReport,
  loadDailyReportInBkg,
  openDraftReport,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

class DuplicateDailyReportModal extends Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    isSubDailyReport: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  state = {
    form: {},
    dupDailyReportId: null,
    intervalId: null,
  };

  componentDidMount() {
    const intervalId = setInterval(this.fetchDailyReport, 2000);
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  fetchDailyReport = () => {
    const { currentProject, isSubDailyReport, onSubmit, dispatch } = this.props;
    const { dupDailyReportId } = this.state;
    if (dupDailyReportId) {
      dispatch(
        loadDailyReportInBkg(
          currentProject.id,
          dupDailyReportId,
          isSubDailyReport
        )
      ).then((response) => {
        if (!response.dailyReport.dup_in_progress) {
          this.setState({ dupDailyReportId: null });
          onSubmit();
          simpleScrollToTop();
          dispatch(
            openDraftReport(
              currentProject.id,
              dupDailyReportId,
              isSubDailyReport
            )
          );
        }
      });
    }
  };

  formValid = () => {
    const { form, dupDailyReportId } = this.state;
    return !!form.reportDate && !dupDailyReportId;
  };

  handleFormChange = (attr, value) => {
    const { form } = this.state;
    this.setState({ form: { ...form, [attr]: value } });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      currentProject,
      currentDailyReport,
      isSubDailyReport,
      dispatch,
    } = this.props;
    const { form } = this.state;
    dispatch(
      duplicateDailyReport(
        currentProject.id,
        currentDailyReport.id,
        form,
        isSubDailyReport
      )
    ).then((response) => {
      if (response.ok) {
        this.setState({ dupDailyReportId: response.dailyReport.id });
      }
    });
  };

  render() {
    const { showModal, onClose } = this.props;
    const { form, dupDailyReportId } = this.state;
    return (
      showModal && (
        <FormModal
          className="duplicate-daily-report-modal"
          show={showModal}
          showHeader={!dupDailyReportId}
          onClose={onClose}
          subTitle="Duplicate Daily Report"
        >
          <div className="form-block">
            <label className="form-block-label">Report Date</label>
            <DatePicker
              placeholder="Report Date"
              onChange={(value) => this.handleFormChange('reportDate', value)}
              value={form.reportDate}
            />
          </div>
          <div className="buttons-container">
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              disabled={!this.formValid()}
              label={dupDailyReportId ? 'Creating Duplicate...' : 'Duplicate'}
              onClick={this.handleSubmit}
            />
          </div>
        </FormModal>
      )
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    currentDailyReport: getCurrentDailyReport(state),
  };
})(DuplicateDailyReportModal);
