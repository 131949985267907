import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Users.css';
import SimpleTable from '../../shared/table/SimpleTable';
import { getUsers } from '../../../selectors/admin/users/users';
import DetailsHeader from 'components/shared/details/DetailsHeader';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import {
  loadUserForm,
  loadUsersRequest,
  setCurrentUserId,
  setShowInviteUserModal,
  setResendInviteUser,
} from '../../../actions/admin/users/users';
import { UserModalState } from '../../shared/modal/InviteUserModal';
import UsersInviteUserModalContainer from './UsersInviteUserModalContainer';
import UserStatus from '../../shared/text/UserStatus';
import { formatPhoneNumber } from 'services/utils/text-util';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import User from 'domain/user';

class Users extends Component {
  componentDidMount() {
    this.props.dispatch(loadUsersRequest());
  }

  handleInviteUser = (event) => {
    event.preventDefault();
    this.props.dispatch(setCurrentUserId(0));
    this.props.dispatch(loadUserForm(null));
    this.props.dispatch(setShowInviteUserModal(true, UserModalState.NEW));
  };

  handleUserSelection = (event, user, userId) => {
    event.preventDefault();
    this.props.dispatch(setCurrentUserId(userId));
    this.props.dispatch(loadUserForm(user));
    this.props.dispatch(setShowInviteUserModal(true, UserModalState.READ_ONLY));
  };

  handleResendUserInvite = (event, index) => {
    event.preventDefault();
    this.props.dispatch(
      setResendInviteUser(true, index, UserModalState.RESEND_INVITE)
    );
  };

  render() {
    return (
      <div className="users">
        <DetailsHeader
          leftHeader={{
            value: 'Users',
          }}
        />
        <div className="content-container">
          <DetailsTitle
            rightButtons={[
              <Button
                key="invite-user"
                type={BUTTON_TYPES.LINK}
                icon={BUTTON_ICONS.PLUS}
                label="Invite User"
                onClick={this.handleInviteUser}
              />,
            ]}
          />
          <div className="users-table-container">
            <SimpleTable
              className="users-table"
              headers={[
                'NAME',
                'ROLES/PERMISSIONS',
                'TITLE',
                'USER STATUS',
                'PHONE NUMBER',
                'ACTIONS',
              ]}
              rows={this.mapUsers(this.props.users)}
              emptyMessage="No Users"
            />
          </div>

          <UsersInviteUserModalContainer />
        </div>
      </div>
    );
  }

  mapUsers(users) {
    return users.map((user) => {
      let status;
      if (
        user.invitation_sent_at === null ||
        user.invitation_accepted_at !== null
      ) {
        status = user.status;
      } else if (user.invitation_accepted_at === null) {
        status = 'pending_invitation';
      }

      return [
        `${user.first_name} ${user.last_name}`,
        User.companyRoleFullLabel(user),
        user.title !== null ? user.title : 'N/A',
        <UserStatus status={status} />,
        user.phone_number !== null
          ? formatPhoneNumber(user.phone_number)
          : 'N/A',
        <div className="action-buttons">
          <Button
            type={BUTTON_TYPES.LINK}
            color={BUTTON_COLORS.GREEN}
            label="Edit"
            onClick={(e) => this.handleUserSelection(e, user, user.id)}
          />
          {(status === 'inactive' || status === 'pending_invitation') && (
            <Button
              type={BUTTON_TYPES.LINK}
              color={BUTTON_COLORS.GREEN}
              label="Resend invite"
              onClick={(e) => this.handleResendUserInvite(e, user.id)}
            />
          )}
        </div>,
      ];
    });
  }
}

export default connect((state) => {
  return {
    users: getUsers(state),
  };
})(Users);
