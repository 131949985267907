import { createSelector } from 'reselect';

export const getWorkOrders = createSelector(
  (state) => state.workOrders.data.orders,
  (workOrders) => {
    return workOrders;
  }
);

export const getCurrentPage = createSelector(
  (state) => state.workOrders.ui.currentPage,
  (currentPage) => {
    return currentPage;
  }
);

export const getCurrentInvoicesTab = createSelector(
  (state) => state.workOrders.ui.currentInvoicesTab,
  (currentInvoicesTab) => {
    return currentInvoicesTab;
  }
);

export const getCurrentWorkOrder = createSelector(
  (state) => state.workOrders.data.currentWorkOrder,
  (currentWorkOrder) => {
    return currentWorkOrder;
  }
);

export const getTasks = createSelector(
  (state) => state.workOrders.data.tasks,
  (tasks) => {
    return tasks;
  }
);

export const getBuildings = createSelector(
  (state) => state.workOrders.data.buildings,
  (buildings) => {
    return buildings;
  }
);

export const getTrades = createSelector(
  (state) => state.workOrders.data.trades,
  (trades) => {
    return trades;
  }
);

export const getSubTradesByTradeId = createSelector(
  (state) => state.workOrders.data.subTradesByTradeId,
  (subTradesByTradeId) => {
    return subTradesByTradeId;
  }
);

export const getContractors = createSelector(
  (state) => state.workOrders.data.contractors,
  (contractors) => {
    return contractors;
  }
);

export const getUnionTrades = createSelector(
  (state) => state.workOrders.data.unionTrades,
  (unionTrades) => {
    return unionTrades;
  }
);

export const getSummary = createSelector(
  (state) => state.workOrders.data.summary,
  (summary) => {
    return summary;
  }
);

export const getShowNoWorkOrdersOverlay = createSelector(
  (state) => state.workOrders.ui.showNoWorkOrdersOverlay,
  (showNoWorkOrdersOverlay) => {
    return showNoWorkOrdersOverlay;
  }
);

export const searchTextSelector = createSelector(
  (state) => state.workOrders.ui.searchText,
  (searchText) => {
    return searchText;
  }
);
