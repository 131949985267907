import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormControlBlock, {
  CONTROL_SIZE,
} from 'components/shared/form/FormControlBlock';
import Selector from 'components/shared/selector/Selector';
import DatePicker from 'components/shared/date-picker/DatePicker';
import {
  sameOrBeforeTodayValidation,
  sameOrBeforeDateValidation,
  sameOrAfterDateValidation,
} from 'services/utils/date-util';
import {
  getReportViews,
  getCurrentReportViewId,
  getCurrentReportViewDetails,
} from './store/selectors';
import {
  loadReportViews,
  newReportView,
  editReportView,
  setReportViewFormValue,
} from './store/actions';

class ReportDetails extends Component {
  constructor(props) {
    super(props);
    const { views, dispatch } = this.props;

    this.types = [
      {
        value: 'standard',
        label: 'Standard',
      },
      {
        value: 'analytics',
        label: 'Analytics',
        scopes: [
          {
            value: 'day',
            label: 'Day',
          },
          {
            value: 'week',
            label: 'Week',
          },
          {
            value: 'month',
            label: 'Month',
          },
          {
            value: 'quarter',
            label: 'Quarter',
          },
          {
            value: 'semiannual',
            label: 'Semi-Annual',
          },
          {
            value: 'year',
            label: 'Year',
          },
        ],
      },
    ];

    if (views.length === 0) {
      dispatch(loadReportViews());
    }
  }

  handleFormChange = (attribute, value) => {
    const { dispatch } = this.props;
    dispatch(setReportViewFormValue('details', attribute, value));
  };

  handleViewChange = (view) => {
    const { dispatch } = this.props;
    dispatch(view !== 'new' ? editReportView(view) : newReportView());
  };

  render() {
    const {
      views,
      currentViewId,
      currentReportViewDetails: { report_type, scope, start_date, end_date },
    } = this.props;

    const currentType =
      report_type && this.types.find((option) => option.value === report_type);
    const scopes = (currentType && currentType.scopes) || [];

    return (
      <React.Fragment>
        <div className="sidebar-form-body-subtitle">Report Type</div>

        <div className="form-block">
          <FormControlBlock
            label="Saved View"
            size={CONTROL_SIZE.BIG}
            control={
              <Selector
                placeholder="Saved View"
                value={currentViewId || 'new'}
                onChange={(option) => this.handleViewChange(option.value)}
                options={[
                  {
                    value: 'new',
                    label: 'New View',
                  },
                  ...views.map((view) => ({
                    value: view.id,
                    label: view.name,
                  })),
                ]}
              />
            }
          />
          <FormControlBlock size={CONTROL_SIZE.BIG} />
        </div>

        <div className="form-block">
          <FormControlBlock
            label={!!report_type && 'Report Type'}
            size={CONTROL_SIZE.BIG}
            control={
              <Selector
                placeholder="Report Type"
                value={report_type}
                onChange={(option) =>
                  this.handleFormChange('report_type', option.value)
                }
                options={this.types}
              />
            }
          />
          <FormControlBlock
            label={!!scope && 'Analytics Type'}
            size={CONTROL_SIZE.BIG}
            control={
              <Selector
                placeholder="Analytics Type"
                value={scope}
                onChange={(option) =>
                  this.handleFormChange('scope', option.value)
                }
                options={scopes}
                disabled={scopes.length === 0}
              />
            }
          />
        </div>

        <div className="sidebar-form-body-subtitle">Report Dates</div>

        <div className="form-block">
          <FormControlBlock
            label={!!start_date && 'Start Date'}
            size={CONTROL_SIZE.SMALL}
            control={
              <DatePicker
                isValidDate={(date) =>
                  sameOrBeforeTodayValidation(date) &&
                  sameOrBeforeDateValidation(date, end_date)
                }
                placeholder="Start Date"
                value={start_date}
                clearable
                onChange={(value) => this.handleFormChange('start_date', value)}
              />
            }
          />
          <FormControlBlock
            label={!!end_date && 'End Date'}
            size={CONTROL_SIZE.SMALL}
            control={
              <DatePicker
                isValidDate={(date) =>
                  sameOrBeforeTodayValidation(date) &&
                  sameOrAfterDateValidation(date, start_date)
                }
                placeholder="End Date"
                value={end_date}
                clearable
                onChange={(value) => this.handleFormChange('end_date', value)}
              />
            }
          />
          <FormControlBlock size={CONTROL_SIZE.BIG} />
        </div>
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    views: getReportViews(state),
    currentViewId: getCurrentReportViewId(state),
    currentReportViewDetails: getCurrentReportViewDetails(state),
  };
})(ReportDetails);
