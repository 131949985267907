import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';

class PurchaseOrdersApi extends BaseApi {
  createPurchaseOrders(values) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };

    let apiPayload = new FormData();
    apiPayload.append('purchase_order[number]', values.number);
    apiPayload.append('purchase_order[amount]', values.amount);
    if (values.document) {
      apiPayload.append(
        'purchase_order[document_attributes][document]',
        values.document
      );
    }

    return axios
      .post(`${this.baseUrl}/purchase_orders`, apiPayload, headers)
      .then((response) => {
        return {
          ok: true,
          purchaseOrder: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getPurchaseOrders(page = 1) {
    return axios
      .get(`${this.baseUrl}/purchase_orders?page=${page}`)
      .then((response) => {
        return {
          ok: true,
          purchaseOrders: {
            content: response.data,
            total: numeral(response.headers['total']).value(),
            pageSize: numeral(response.headers['per-page']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export let purchaseOrdersApi = new PurchaseOrdersApi();
