import axios from 'axios';
import BaseApi from './base-api';

class SubDailyReportsEntriesApi extends BaseApi {
  dailyReportEntryParams(values) {
    let apiPayload = new FormData();
    apiPayload.append('sub_daily_report_entry[floor]', values.floor);
    apiPayload.append('sub_daily_report_entry[area]', values.area);

    if (values.project_open_item_attributes) {
      const {
        id,
        _destroy,
        assignee_id,
        recipient_ids,
        description,
        resolve_by,
        critical,
        images_attributes,
        deleted_images_attributes,
        documents_attributes,
        deleted_documents_attributes,
      } = values.project_open_item_attributes;
      if (id) {
        apiPayload.append(
          'sub_daily_report_entry[project_open_item_attributes][id]',
          id
        );
      }

      if (_destroy) {
        apiPayload.append(
          'sub_daily_report_entry[project_open_item_attributes][_destroy]',
          _destroy
        );
      } else {
        apiPayload.append(
          'sub_daily_report_entry[project_open_item_attributes][assignee_id]',
          assignee_id
        );
        apiPayload.append(
          'sub_daily_report_entry[project_open_item_attributes][description]',
          description
        );
        apiPayload.append(
          'sub_daily_report_entry[project_open_item_attributes][resolve_by]',
          resolve_by
        );
        apiPayload.append(
          'sub_daily_report_entry[project_open_item_attributes][critical]',
          critical
        );

        if (recipient_ids && recipient_ids.length > 0) {
          recipient_ids.forEach((rcptId) => {
            apiPayload.append(
              'sub_daily_report_entry[project_open_item_attributes][recipient_ids][]',
              rcptId
            );
          });
        } else {
          apiPayload.append(
            'sub_daily_report_entry[project_open_item_attributes][recipient_ids][]',
            []
          );
        }

        let index = 0;
        if (images_attributes && images_attributes.length > 0) {
          images_attributes.forEach((image) => {
            apiPayload.append(
              `sub_daily_report_entry[project_open_item_attributes][images_attributes][${index}][image]`,
              image.file,
              image.file.name
            );
            index++;
          });
        }

        if (deleted_images_attributes && deleted_images_attributes.length > 0) {
          deleted_images_attributes.forEach((image) => {
            apiPayload.append(
              `sub_daily_report_entry[project_open_item_attributes][images_attributes][${index}][id]`,
              image.id
            );
            apiPayload.append(
              `sub_daily_report_entry[project_open_item_attributes][images_attributes][${index}][_destroy]`,
              true
            );
            index++;
          });
        }

        index = 0;
        if (documents_attributes && documents_attributes.length > 0) {
          documents_attributes.forEach((doc) => {
            apiPayload.append(
              `sub_daily_report_entry[project_open_item_attributes][documents_attributes][${index}][document]`,
              doc.file,
              doc.file.name
            );
            index++;
          });
        }

        if (
          deleted_documents_attributes &&
          deleted_documents_attributes.length > 0
        ) {
          deleted_documents_attributes.forEach((doc) => {
            apiPayload.append(
              `sub_daily_report_entry[project_open_item_attributes][documents_attributes][${index}][id]`,
              doc.id
            );
            apiPayload.append(
              `sub_daily_report_entry[project_open_item_attributes][documents_attributes][${index}][_destroy]`,
              true
            );
            index++;
          });
        }
      }
    }

    if (values.field_work_order_attributes) {
      const {
        id,
        _destroy,
        number,
        preset_task_ids,
        preset_task_other,
        work_performed,
        comments,
        start_date,
        completion_date,
        labor,
        materials,
      } = values.field_work_order_attributes;
      if (id) {
        apiPayload.append(
          'sub_daily_report_entry[field_work_order_attributes][id]',
          id
        );
      }

      if (_destroy) {
        apiPayload.append(
          'sub_daily_report_entry[field_work_order_attributes][_destroy]',
          _destroy
        );
      } else {
        apiPayload.append(
          'sub_daily_report_entry[field_work_order_attributes][number]',
          number
        );
        apiPayload.append(
          'sub_daily_report_entry[field_work_order_attributes][preset_task_other]',
          preset_task_other
        );
        apiPayload.append(
          'sub_daily_report_entry[field_work_order_attributes][work_performed]',
          work_performed
        );
        apiPayload.append(
          'sub_daily_report_entry[field_work_order_attributes][comments]',
          comments
        );
        apiPayload.append(
          'sub_daily_report_entry[field_work_order_attributes][start_date]',
          start_date
        );
        apiPayload.append(
          'sub_daily_report_entry[field_work_order_attributes][completion_date]',
          completion_date
        );
        apiPayload.append(
          'sub_daily_report_entry[field_work_order_attributes][labor]',
          labor
        );
        apiPayload.append(
          'sub_daily_report_entry[field_work_order_attributes][materials]',
          materials
        );

        if (preset_task_ids && preset_task_ids.length > 0) {
          preset_task_ids.forEach((taskId) => {
            apiPayload.append(
              'sub_daily_report_entry[field_work_order_attributes][preset_task_ids][]',
              taskId
            );
          });
        } else {
          apiPayload.append(
            'sub_daily_report_entry[field_work_order_attributes][preset_task_ids][]',
            []
          );
        }
      }
    }

    let techIndex = 0;

    if (
      values.technicians_attributes &&
      values.technicians_attributes.length > 0
    ) {
      values.technicians_attributes.forEach((attr) => {
        if (attr.id) {
          apiPayload.append(
            `sub_daily_report_entry[sub_daily_report_teches_attributes][${techIndex}][id]`,
            attr.id
          );
        }

        apiPayload.append(
          `sub_daily_report_entry[sub_daily_report_teches_attributes][${techIndex}][hours_worked]`,
          attr.hours_worked
        );
        apiPayload.append(
          `sub_daily_report_entry[sub_daily_report_teches_attributes][${techIndex}][started_at]`,
          attr.started_at
        );
        apiPayload.append(
          `sub_daily_report_entry[sub_daily_report_teches_attributes][${techIndex}][ended_at]`,
          attr.ended_at
        );
        apiPayload.append(
          `sub_daily_report_entry[sub_daily_report_teches_attributes][${techIndex}][preset_task_other]`,
          attr.custom_task
        );
        apiPayload.append(
          `sub_daily_report_entry[sub_daily_report_teches_attributes][${techIndex}][location]`,
          attr.location
        );
        apiPayload.append(
          `sub_daily_report_entry[sub_daily_report_teches_attributes][${techIndex}][work_performed]`,
          attr.work_performed
        );

        if (attr.preset_task_ids && attr.preset_task_ids.length > 0) {
          attr.preset_task_ids.forEach((id) => {
            apiPayload.append(
              `sub_daily_report_entry[sub_daily_report_teches_attributes][${techIndex}][preset_task_ids][]`,
              id
            );
          });
        } else {
          apiPayload.append(
            `sub_daily_report_entry[sub_daily_report_teches_attributes][${techIndex}][preset_task_ids][]`,
            []
          );
        }

        if (attr.sub_technician_id) {
          apiPayload.append(
            `sub_daily_report_entry[sub_daily_report_teches_attributes][${techIndex}][sub_technician_id]`,
            attr.sub_technician_id
          );
        } else {
          apiPayload.append(
            `sub_daily_report_entry[sub_daily_report_teches_attributes][${techIndex}][sub_technician_id]`,
            ''
          );
        }

        apiPayload.append(
          `sub_daily_report_entry[sub_daily_report_teches_attributes][${techIndex}][tech_number]`,
          attr.tech_number
        );

        techIndex++;
      });
    }

    if (values.deletedTechnicians && values.deletedTechnicians.length > 0) {
      values.deletedTechnicians.forEach((attr) => {
        apiPayload.append(
          `sub_daily_report_entry[sub_daily_report_teches_attributes][${techIndex}][id]`,
          attr.id
        );
        apiPayload.append(
          `sub_daily_report_entry[sub_daily_report_teches_attributes][${techIndex}][_destroy]`,
          true
        );
        techIndex++;
      });
    }

    let imageIndex = 0;

    if (values.images_attributes && values.images_attributes.length > 0) {
      values.images_attributes.forEach((image) => {
        apiPayload.append(
          `sub_daily_report_entry[images_attributes][${imageIndex}][image]`,
          image.file,
          image.file.name
        );
        imageIndex++;
      });
    }

    if (
      values.deleted_images_attributes &&
      values.deleted_images_attributes.length > 0
    ) {
      values.deleted_images_attributes.forEach((image) => {
        apiPayload.append(
          `sub_daily_report_entry[images_attributes][${imageIndex}][id]`,
          image.id
        );
        apiPayload.append(
          `sub_daily_report_entry[images_attributes][${imageIndex}][_destroy]`,
          true
        );
        imageIndex++;
      });
    }

    return apiPayload;
  }

  updateDailyReportEntry(values, reportId) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = this.dailyReportEntryParams(values);

    return axios
      .put(
        `${this.baseUrl}/sub_daily_report_entries/${reportId}`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          dailyReport: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getSubDailyReportsEntries(projectId, reportDate) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/sub_daily_report_entries?submitted=true&by_report_date=${reportDate}`
      )
      .then((response) => {
        return {
          ok: true,
          subDailyReportEntries: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  postDailyReportEntry(entryId) {
    const apiPayload = {
      sub_daily_report_entry: {
        sub_daily_report_entry_id: entryId,
      },
    };
    return axios
      .post(`${this.baseUrl}/sub_daily_report_entries`, apiPayload)
      .then((response) => {
        return {
          ok: true,
          newEntry: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  deleteDailyReportEntry(entryId) {
    return axios
      .delete(`${this.baseUrl}/sub_daily_report_entries/${entryId}`)
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const subDailyReportsEntriesApi = new SubDailyReportsEntriesApi();
