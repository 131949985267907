import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import './NewCompanyModal.css';
import FormModal from 'components/shared/modal/FormModal';
import {
  getProjectForm,
  getCompanyForm,
  getShowNewCompanyModal,
} from 'components/admin/projects/creation/store/selectors';
import Input from 'components/shared/input/Input';
import {
  setCompanyFormValue,
  createSubscriber,
  setShowNewCompanyModal,
  cleanCompanyFormValues,
  addSubscriberToBeginning,
} from 'components/admin/projects/creation/store/actions';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import FormControlBlock from 'components/shared/form/FormControlBlock';

class NewCompanyModal extends Component {
  constructor(props) {
    super(props);
    props.dispatch(cleanCompanyFormValues());
  }

  handleCompanyFormChange = (attribute, value) => {
    this.props.dispatch(setCompanyFormValue(attribute, value));
  };

  handleClose = () => {
    this.props.dispatch(setShowNewCompanyModal(false));
  };

  createCompany = (callback) => {
    const { dispatch, form } = this.props;

    return dispatch(createSubscriber(form)).then((response) => {
      callback(response, response.company);
    });
  };

  handleSubmit = (event) => {
    const { dispatch } = this.props;
    event.preventDefault();
    this.createCompany((response, company) => {
      if (response.ok) {
        dispatch(addSubscriberToBeginning(company));
        dispatch(setShowNewCompanyModal(false));
      }
    });
  };

  companyFormValid = () => {
    const companyForm = this.props.form;
    return (
      !!companyForm.name &&
      !!companyForm.firstName &&
      !!companyForm.lastName &&
      !!companyForm.email &&
      !!companyForm.phoneNumber &&
      !!companyForm.streetAddress &&
      !!companyForm.city &&
      !!companyForm.state
    );
  };

  render() {
    const {
      form: {
        name,
        firstName,
        lastName,
        email,
        phoneNumber,
        streetAddress,
        unitNumber,
        city,
        state,
        zipCode,
      },
    } = this.props;
    return (
      <div id="project-creation-new-company-modal">
        <FormModal
          className="project-creation-new-company-modal"
          show={this.props.showNewCompanyModal}
          subTitle={'New Company Information'}
          onClose={this.handleClose}
        >
          <Form horizontal onSubmit={this.handleSubmit}>
            <div className="form-block">
              <FormControlBlock
                label={!!name && 'Company Name'}
                control={
                  <Input
                    id="company-name"
                    placeholder="Company Name"
                    value={name}
                    onChange={this.handleCompanyFormChange.bind(this, 'name')}
                  />
                }
              />
            </div>

            <div className="form-block multi-control">
              <FormControlBlock
                label={!!firstName && 'First Name'}
                control={
                  <Input
                    id="first-name"
                    placeholder="First Name"
                    value={firstName}
                    onChange={this.handleCompanyFormChange.bind(
                      this,
                      'firstName'
                    )}
                  />
                }
              />
              <FormControlBlock
                label={!!lastName && 'Last Name'}
                control={
                  <Input
                    id="last-name"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={this.handleCompanyFormChange.bind(
                      this,
                      'lastName'
                    )}
                  />
                }
              />
            </div>

            <div className="form-block multi-control">
              <FormControlBlock
                label={!!email && 'Email'}
                control={
                  <Input
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={this.handleCompanyFormChange.bind(this, 'email')}
                  />
                }
              />
              <FormControlBlock
                label={!!phoneNumber && 'Phone Number'}
                control={
                  <Input
                    id="phone-number"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={this.handleCompanyFormChange.bind(
                      this,
                      'phoneNumber'
                    )}
                  />
                }
              />
            </div>

            <div className="form-block multi-control">
              <FormControlBlock
                label={!!streetAddress && 'Address Line 1'}
                control={
                  <Input
                    id="street-address-1"
                    placeholder="Address Line 1"
                    value={streetAddress}
                    onChange={this.handleCompanyFormChange.bind(
                      this,
                      'streetAddress'
                    )}
                  />
                }
              />
              <FormControlBlock
                label={!!unitNumber && 'Address Line 2'}
                control={
                  <Input
                    id="street-address-2"
                    placeholder="Address Line 2"
                    value={unitNumber}
                    onChange={this.handleCompanyFormChange.bind(
                      this,
                      'unitNumber'
                    )}
                  />
                }
              />
            </div>

            <div className="form-block multi-control">
              <FormControlBlock
                label={!!city && 'City'}
                control={
                  <Input
                    id="city"
                    placeholder="City"
                    value={city}
                    onChange={this.handleCompanyFormChange.bind(this, 'city')}
                  />
                }
              />
              <FormControlBlock
                label={!!state && 'State'}
                control={
                  <Input
                    id="state"
                    placeholder="State"
                    value={state}
                    onChange={this.handleCompanyFormChange.bind(this, 'state')}
                  />
                }
              />
              <FormControlBlock
                label={!!zipCode && 'Zip'}
                control={
                  <Input
                    id="zip"
                    placeholder="Zip"
                    value={zipCode}
                    onChange={this.handleCompanyFormChange.bind(
                      this,
                      'zipCode'
                    )}
                  />
                }
              />
            </div>

            <div className="buttons-container">
              <Button
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.GREEN}
                label="Add"
                onClick={this.handleSubmit}
                disabled={!this.companyFormValid()}
              />
            </div>
          </Form>
        </FormModal>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    projectForm: getProjectForm(state),
    form: getCompanyForm(state),
    showNewCompanyModal: getShowNewCompanyModal(state),
  };
})(NewCompanyModal);
