import {
  INIT_PROJECT_EMAIL_MODAL,
  SET_SHOW_MODAL,
  SET_EMAILS,
  ADD_EMAIL,
  ADD_RECIPIENT,
  SET_RECIPIENTS,
  SAVED_PDFTRON_SUCCESS,
  RESET_PDFTRON_MESSAGES,
} from 'components/shared/pdf-viewer-bluebeam/store/action-types';

export function savedPdfTronSuccess(response) {
  return {
    type: SAVED_PDFTRON_SUCCESS,
    payload: response,
  };
}

export function resetPdfTronMessages() {
  return {
    type: RESET_PDFTRON_MESSAGES,
  };
}

export function initProjectEmailModal(payload) {
  return {
    type: INIT_PROJECT_EMAIL_MODAL,
    payload: payload,
  };
}

export function setShowModal(payload) {
  return {
    type: SET_SHOW_MODAL,
    payload: payload,
  };
}

export function setEmails(emails) {
  return {
    type: SET_EMAILS,
    payload: emails,
  };
}

export function addEmail(emails) {
  return {
    type: ADD_EMAIL,
    payload: emails,
  };
}

export function addRecipient(recipient) {
  return {
    type: ADD_RECIPIENT,
    payload: recipient,
  };
}

export function setRecipients(recipients) {
  return {
    type: SET_RECIPIENTS,
    payload: recipients,
  };
}
