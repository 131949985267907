import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './ProjectSubmittalLog.css';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import NavigationBar from 'components/shared/navigation/NavigationBar';
import PdfViewer from 'components/shared/pdf-viewer/PdfViewer';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getNotifications } from 'selectors/notifications';
import { unreadNotifications } from 'domain/notification';
import { markNotifiableNotificationsAsRead } from 'actions/notifications';
import {
  getSubmittals,
  getSubmittalsTradeCodes,
  getTotal,
  getCurrentPage,
  getCurrentStatus,
  getDueDateFilter,
  getTradeCodeFilter,
  getPageSize,
  getSubmittalTabSelected,
  getSubmittalLogPdfViewer,
  getSubmittalLogPdf,
} from 'components/admin/projects/details/project-submittal-log/store/selectors';
import {
  initSubmittalLog,
  loadSubmittalsRequest,
  setSubmittalTabSelected,
  initSubmittalModal,
  setSubmittalModalShow,
  openSubmittal,
  reviseSubmittal,
  setSubmittalLogPdfViewer,
  loadSubmittalLogPdfRequest,
  loadSubmittalLeadTimeLogPdfRequest,
} from 'components/admin/projects/details/project-submittal-log/store/actions';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import SubmittalPermissions from 'permissions/submittal-permissions';
import SubmittalLogTable from 'components/admin/projects/details/project-submittal-log/SubmittalLogTable';
import SubmittalLeadTimeLogTable from 'components/admin/projects/details/project-submittal-log/SubmittalLeadTimeLogTable';
import SubmittalModalContainer from 'components/admin/projects/details/project-submittal-log/SubmittalModalContainer';

class ProjectSubmittalLog extends Component {
  constructor(props) {
    super(props);
    this.state = { searchText: null };

    props.dispatch(initSubmittalLog());
  }

  componentDidMount() {
    const { currentProject, match, dispatch, history } = this.props;
    const { subsection, itemId } = match.params;

    if (!subsection) {
      history.replace(
        `/admin/projects/${match.params.projectId}/submittals/submittal-log`
      );
    }

    if (currentProject) {
      const submittalTab = this.getTabValueFromSubsection(subsection);
      dispatch(setSubmittalTabSelected(submittalTab));
      this.handlePageChange(1, this.defaultStatus(submittalTab));
      this.loadDirectory();
    }

    if (itemId) {
      this.handleOpenSubmittal(itemId);
    }
  }

  componentDidUpdate(prevProps) {
    const { match, dispatch } = this.props;
    const prevSubsection = prevProps.match.params.subsection;
    const currentSubsection = match.params.subsection;
    const prevItemId = prevProps.match.params.itemId;
    const currentItemId = match.params.itemId;

    if (prevSubsection !== currentSubsection) {
      const submittalTab = this.getTabValueFromSubsection(currentSubsection);
      dispatch(setSubmittalTabSelected(submittalTab));
      this.handlePageChange(1, this.defaultStatus(submittalTab));
    }

    if (prevItemId !== currentItemId) {
      if (currentItemId) {
        // Open the Submittal modal if it's not already open and the url has an itemId
        if (!this.props.showSubmittalModal) {
          this.handleOpenSubmittal(currentItemId);
        }
      }
    }
  }
  getTabValueFromSubsection = (subsection) => {
    switch (subsection) {
      case 'p-submittal-log':
        return 1;
      case 'submittal-log':
        return 2;
      case 'lead-time-log':
        return 3;
      default:
        return 2;
    }
  };

  getSubsectionFromTabValue = (tabValue) => {
    switch (tabValue) {
      case 1:
        return 'p-submittal-log';
      case 2:
        return 'submittal-log';
      case 3:
        return 'lead-time-log';
      default:
        return 'submittal-log';
    }
  };

  loadDirectory = () => {
    const { currentProject, directory, dispatch } = this.props;
    if (!directory || directory.project_id !== currentProject.id) {
      dispatch(loadDirectoryRequest(currentProject.id));
    }
  };

  handleSubmittalTabSelection = (submittalTab) => {
    const { match, history } = this.props;
    const { projectId } = match.params;
    const subsection = this.getSubsectionFromTabValue(submittalTab);

    history.push(`/admin/projects/${projectId}/submittals/${subsection}`);

    this.handlePageChange(1, this.defaultStatus(submittalTab));
    this.props.dispatch(setSubmittalTabSelected(submittalTab));
  };

  handlePageChange = (page, status, dueDateFilter, tradeCodeFilter) => {
    const { currentProject, dispatch } = this.props;
    const { searchText } = this.state;
    dispatch(
      loadSubmittalsRequest(
        currentProject.id,
        page,
        status,
        dueDateFilter,
        tradeCodeFilter,
        searchText
      )
    );
  };

  showPreSubmittal = () => {
    const { currentUser, currentProject } = this.props;

    return (
      SubmittalPermissions.canViewSubmitted(currentUser, currentProject) &&
      SubmittalPermissions.canViewPending(currentUser, currentProject)
    );
  };

  defaultStatus = (submittalTab = 2) => {
    return submittalTab === 3
      ? 'cls_approved'
      : (this.showPreSubmittal() && (submittalTab === 1 ? 'pre' : 'not_pre')) ||
          'all';
  };

  handleAddSubmittal = () => {
    this.props.dispatch(initSubmittalModal());
    this.props.dispatch(setSubmittalModalShow(true));
  };

  handleOpenSubmittal = (id) => {
    const {
      currentUser,
      currentProject,
      dispatch,
      history,
      match,
    } = this.props;
    const { projectId, subsection } = match.params;

    history.push(`/admin/projects/${projectId}/submittals/${subsection}/${id}`);

    dispatch(openSubmittal(currentProject.id, id, currentUser));
    dispatch(markNotifiableNotificationsAsRead(id, 'Submittal'));
  };

  handleReviseSubmittal = (id) => {
    const { currentUser, currentProject, dispatch } = this.props;
    dispatch(reviseSubmittal(currentProject.id, id, currentUser));
  };

  handleViewPdf = () => {
    const { currentProject, submittalTabSelected, dispatch } = this.props;
    const promise =
      submittalTabSelected === 3
        ? dispatch(loadSubmittalLeadTimeLogPdfRequest(currentProject.id))
        : dispatch(
            loadSubmittalLogPdfRequest(
              currentProject.id,
              this.defaultStatus(submittalTabSelected)
            )
          );
    promise.then((response) => {
      if (response.ok) {
        dispatch(setSubmittalLogPdfViewer(true));
      }
    });
  };

  handleSubmittalLogPdfViewerClose = () => {
    this.props.dispatch(setSubmittalLogPdfViewer(false));
  };

  renderHeaderButtons() {
    const { currentUser, currentProject } = this.props;

    return [
      SubmittalPermissions.canCreate(currentUser, currentProject) && (
        <Button
          key="add-submittal"
          type={BUTTON_TYPES.LINK}
          icon={BUTTON_ICONS.PLUS}
          label="Add Submittal"
          onClick={this.handleAddSubmittal}
        />
      ),
      <Button
        key="view-log"
        type={BUTTON_TYPES.LINK}
        color={BUTTON_COLORS.GREEN}
        label="View Log"
        onClick={this.handleViewPdf}
      />,
    ].filter((component) => component);
  }

  handleSearch = (text) => {
    const { currentStatus, dueDateFilter, tradeCodeFilter } = this.props;

    this.setState({ searchText: text }, () =>
      this.handlePageChange(1, currentStatus, dueDateFilter, tradeCodeFilter)
    );
  };

  render() {
    const {
      currentUser,
      currentProject,
      directory,
      notifications,
      submittalTabSelected,
      submittals,
      tradeCodes,
      total,
      currentPage,
      currentStatus,
      pageSize,
      submittalLogPdfViewer,
      submittalLogPdf,
      dueDateFilter,
      tradeCodeFilter,
      renderHeaders,
    } = this.props;
    const unreadById = unreadNotifications(notifications, 'Submittal');
    return (
      currentProject &&
      directory && (
        <div className="content-container submittal-log">
          {renderHeaders(this.renderHeaderButtons(), this.handleSearch)}
          <div className="submittal-log-header">
            <NavigationBar
              items={[
                {
                  id: 1,
                  title: 'P-Submittal Log',
                  show: this.showPreSubmittal(),
                },
                {
                  id: 2,
                  title: 'Submittal Log',
                },
                {
                  id: 3,
                  title: 'Lead Time Log',
                },
              ]}
              selectedValue={submittalTabSelected}
              onSelected={this.handleSubmittalTabSelection}
            />
          </div>

          {/*Navigation bar items content*/}
          {submittalTabSelected === 3 ? (
            <SubmittalLeadTimeLogTable
              submittals={submittals}
              tradeCodes={tradeCodes}
              total={total}
              currentPage={currentPage}
              currentStatus={currentStatus}
              tradeCodeFilter={tradeCodeFilter}
              pageSize={pageSize}
              unreadById={unreadById}
              handlePageChange={this.handlePageChange}
              handleOpenSubmittal={this.handleOpenSubmittal}
            />
          ) : (
            <SubmittalLogTable
              submittals={submittals}
              tradeCodes={tradeCodes}
              total={total}
              currentPage={currentPage}
              currentStatus={currentStatus}
              dueDateFilter={dueDateFilter}
              tradeCodeFilter={tradeCodeFilter}
              pageSize={pageSize}
              currentUser={currentUser}
              currentProject={currentProject}
              directory={directory}
              unreadById={unreadById}
              submittalTabSelected={submittalTabSelected}
              handlePageChange={this.handlePageChange}
              handleOpenSubmittal={this.handleOpenSubmittal}
              handleReviseSubmittal={this.handleReviseSubmittal}
            />
          )}

          {submittalLogPdfViewer && (
            <PdfViewer
              content={{
                source: submittalLogPdf,
                type: 'blob',
              }}
              show={submittalLogPdfViewer}
              onClose={this.handleSubmittalLogPdfViewerClose}
            />
          )}

          <SubmittalModalContainer />
        </div>
      )
    );
  }
}

export default withRouter(
  connect((state) => {
    return {
      currentUser: getCurrentUser(state),
      currentProject: getCurrentProject(state),
      directory: getDirectory(state),
      total: getTotal(state),
      currentPage: getCurrentPage(state),
      currentStatus: getCurrentStatus(state),
      dueDateFilter: getDueDateFilter(state),
      tradeCodeFilter: getTradeCodeFilter(state),
      pageSize: getPageSize(state),
      submittals: getSubmittals(state),
      tradeCodes: getSubmittalsTradeCodes(state),
      submittalTabSelected: getSubmittalTabSelected(state),
      notifications: getNotifications(state),
      submittalLogPdfViewer: getSubmittalLogPdfViewer(state),
      submittalLogPdf: getSubmittalLogPdf(state),
    };
  })(ProjectSubmittalLog)
);
