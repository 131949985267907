import ContractorsApi from '../../../../services/api/contractors-api';
import WorkOrdersApi from '../../../../services/api/work-orders-api';
import UnionsApi from '../../../../services/api/union-api';
import {
  loadContractorsSuccess,
  loadUnionTradesSuccess,
  newContractorSuccess,
  updateWorkOrder,
} from '../work-orders';
import {
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_ADD,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_CURRENT_SET,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_INVALID_CONFIRMATION,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_CANCEL,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_INVALID,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_SUCCESS,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_VALID,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_VALUE_CHANGE,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_QUESTION_ANSWERED,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_REMOVE,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_SELECT,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_STATE_INIT,
  WORK_ORDERS_CREATION_SUB_CONTRACTORS_VALID_CONFIRMATION,
  WORK_ORDERS_CREATION_UNION_TRADES_CURRENT_SET,
  WORK_ORDERS_CREATION_UNION_TRADES_SELECT,
  WORK_ORDERS_CREATION_WO_NEW_PROCESS_STARTED,
  WORK_ORDERS_CREATION_UNION_TRADES_VALID_CONFIRMATION,
  WORK_ORDERS_CREATION_UNION_TRADES_INVALID_CONFIRMATION,
} from '../action-types';
import { showErrorMessage } from '../../../messages';
import { goToSummary, goToWorkingHours } from './creation';
import { UnionTradesExtraKeys } from '../../../../components/admin/work-orders/creation/UnionTrades';

export function initializeComponentAppState(
  currentSubContractorsId,
  dontKnowSubContractor,
  dontKnowUnions
) {
  return {
    type: WORK_ORDERS_CREATION_SUB_CONTRACTORS_STATE_INIT,
    payload: {
      dontKnowSubContractor:
        currentSubContractorsId.length > 0
          ? 'yes'
          : dontKnowSubContractor
          ? 'dont-know'
          : null,
      dontKnowUnions,
    },
  };
}

export function selectSubContractor(contractorId) {
  return {
    type: WORK_ORDERS_CREATION_SUB_CONTRACTORS_SELECT,
    payload: contractorId,
  };
}

export function addSubContractor() {
  return {
    type: WORK_ORDERS_CREATION_SUB_CONTRACTORS_ADD,
  };
}

export function cancelAddSubContractor() {
  return {
    type: WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_CANCEL,
  };
}

function getInvalidFields(newData) {
  return Object.keys(newData)
    .filter((key) => {
      return key !== 'unitNumberFloor' && newData[key].value === '';
    })
    .map((key) => {
      return key;
    });
}

export function newSubContractorValid() {
  return {
    type: WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_VALID,
  };
}

export function newSubContractorInvalid(fields, validationMessage) {
  return {
    type: WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_INVALID,
    payload: {
      fields,
      validationMessage,
    },
  };
}

export function formValueChange(attribute, value) {
  return {
    type: WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_VALUE_CHANGE,
    payload: {
      attribute,
      value,
    },
  };
}

export function createSubContractorRequest(newData) {
  return (dispatch) => {
    const invalidFields = getInvalidFields(newData);
    if (invalidFields.length === 0) {
      dispatch(newSubContractorValid());
      return ContractorsApi.createContractor({
        name: newData.subContractorName.value,
        contact: {
          firstName: newData.contactFirstName.value,
          lastName: newData.contactLastName.value,
          email: newData.email.value,
          phoneNumber: newData.phoneNumber.value,
        },
        location: {
          streetAddress: newData.streetAddress.value,
          unitNumber: newData.unitNumberFloor.value,
          city: newData.city.value,
          state: newData.state.value,
          zipCode: newData.zipCode.value,
        },
      }).then((response) => {
        if (response.ok) {
          dispatch(newContractorSuccess(response.contractor));
          dispatch(createSubContractorSuccess(response.contractor));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
    } else {
      dispatch(
        newSubContractorInvalid(
          invalidFields,
          'The outlined inputs should be added in order to add a contractor.'
        )
      );
    }
  };
}

export function createSubContractorSuccess(newContractor) {
  return {
    type: WORK_ORDERS_CREATION_SUB_CONTRACTORS_NEW_SUCCESS,
    payload: newContractor,
  };
}

export function removeSubContractor(id) {
  return {
    type: WORK_ORDERS_CREATION_SUB_CONTRACTORS_REMOVE,
    payload: id,
  };
}

export function confirmSubContractorsUnionTradesRequest(
  workOrderId,
  contractorsId,
  subContractorAnswer,
  selectedUnionTradesId,
  dontKnowUnions,
  noUnions,
  newWOProcessStarted
) {
  return (dispatch) => {
    if (subContractorAnswer === null) {
      let msg = 'Please answer the above question.';

      if (subContractorAnswer === null) {
        dispatch(subContractorConfirmationInvalid(msg));
      }

      return null;
    } else {
      dispatch(subContractorConfirmationValid());
      dispatch(unionTradesConfirmationValid());

      const data = {
        dont_know_subcons: subContractorAnswer === 'dont-know',
        dont_know_unions: dontKnowUnions,
        propietary_sub_contractor_ids: contractorsId,
        union_ids: selectedUnionTradesId,
      };
      return WorkOrdersApi.updateWorkOrder(workOrderId, data).then(
        (response) => {
          if (response.ok) {
            dispatch(updateWorkOrder(response.workOrder));

            if (newWOProcessStarted) {
              dispatch(goToWorkingHours());
            } else {
              dispatch(goToSummary());
            }
          } else {
            dispatch(showErrorMessage(response.errors));
          }

          return response;
        }
      );
    }
  };
}

export function answerQuestion(value) {
  return {
    type: WORK_ORDERS_CREATION_SUB_CONTRACTORS_QUESTION_ANSWERED,
    payload: {
      clearSelected: value !== 'yes',
      value,
    },
  };
}

export function subContractorConfirmationInvalid(message) {
  return {
    type: WORK_ORDERS_CREATION_SUB_CONTRACTORS_INVALID_CONFIRMATION,
    payload: message,
  };
}

export function subContractorConfirmationValid() {
  return {
    type: WORK_ORDERS_CREATION_SUB_CONTRACTORS_VALID_CONFIRMATION,
  };
}

export function unionTradesConfirmationInvalid(message) {
  return {
    type: WORK_ORDERS_CREATION_UNION_TRADES_INVALID_CONFIRMATION,
    payload: message,
  };
}

export function unionTradesConfirmationValid() {
  return {
    type: WORK_ORDERS_CREATION_UNION_TRADES_VALID_CONFIRMATION,
  };
}

export function loadContractorsRequest(currentSubContractorsId, tradesId) {
  return (dispatch) => {
    return ContractorsApi.getContractors(tradesId).then((response) => {
      if (response.ok) {
        dispatch(loadContractorsSuccess(response.contractors));
        dispatch(setCurrentSubContractorsId(currentSubContractorsId));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function setCurrentSubContractorsId(currentSubContractorsId) {
  return {
    type: WORK_ORDERS_CREATION_SUB_CONTRACTORS_CURRENT_SET,
    payload: currentSubContractorsId,
  };
}

export function loadUnionTradesRequest(currentUnionTradesId) {
  return (dispatch) => {
    return UnionsApi.getUnions().then((response) => {
      if (response.ok) {
        dispatch(loadUnionTradesSuccess(response.unions));
        dispatch(setCurrentUnionTradesId(currentUnionTradesId));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function setCurrentUnionTradesId(currentUnionTradesId) {
  return {
    type: WORK_ORDERS_CREATION_UNION_TRADES_CURRENT_SET,
    payload: currentUnionTradesId,
  };
}

export function selectUnionTrade(id, selectedUnionTradesId, unionTrades) {
  let newSelectedUnionTradesId;
  let dontKnowUnions = false;
  let noUnions = false;

  if (id === UnionTradesExtraKeys.ALL) {
    if (selectedUnionTradesId.length === unionTrades.length) {
      // If all are selected, deselect all
      newSelectedUnionTradesId = [];
    } else {
      newSelectedUnionTradesId = unionTrades.map((element) => element.id);
    }
  } else if (id === UnionTradesExtraKeys.DONT_KNOW) {
    newSelectedUnionTradesId = [];
    dontKnowUnions = true;
    noUnions = false;
  } else if (id === UnionTradesExtraKeys.NO) {
    newSelectedUnionTradesId = [];
    dontKnowUnions = false;
    noUnions = true;
  } else {
    newSelectedUnionTradesId = selectedUnionTradesId.slice();
    if (newSelectedUnionTradesId.findIndex((element) => element === id) > -1) {
      // The item is selected, deselect it
      newSelectedUnionTradesId = newSelectedUnionTradesId.filter(
        (element) => element !== id
      );
    } else {
      // Add it
      newSelectedUnionTradesId.push(id);
    }
  }

  return {
    type: WORK_ORDERS_CREATION_UNION_TRADES_SELECT,
    payload: { newSelectedUnionTradesId, dontKnowUnions, noUnions },
  };
}

export function setNewWOProcessStarted(value) {
  return {
    type: WORK_ORDERS_CREATION_WO_NEW_PROCESS_STARTED,
    payload: value,
  };
}
