import colors from '../styles/colors';

export const theme = {
  palette: {
    'txt-primary': colors['light-mode-black-text'],
    'txt-primary-invert': colors['white-text'],
    'txt-secondary': colors['light-mode-black-text'],
    'txt-secondary-invert': colors['white-text'],
    'txt-placeholder': colors['light-mode-black-text'],

    'accent-primary': colors['primary-green'],
    'accent-primary-hover': colors['primary-green'],
    'accent-primary-active': colors['primary-green'],
    'accent-primary-disabled': colors['primary-green'],

    'bg-primary': colors['very-light-grey'],
    'bg-primary-hover': colors['very-light-grey'],
    'bg-primary-active': colors['white-main'],
    'bg-secondary': colors['very-light-grey'],

    'btn-primary-text': colors['light-mode-black-text'],
    'btn-disabled-text': colors['light-mode-black-text'],

    'icons-primary': colors['light-mode-black-text'],
    'icons-secondary': '#7a7e8b',
    'icons-placeholder': '#7a7e8b',
    'borders-primary': '#7a7e8b',
    'borders-secondary': '#7a7e8b',
    'borders-strong': colors['primary-green'],

    warning: colors['chart-red'],
    error: colors['chart-red'],
  },
  typography: {
    fontFamily: 'Titillium Web, sans-serif',
    baseLineHeight: 1.3,
  },
};
export const themeDark = {
  palette: {
    'txt-primary': colors['white-text'],
    'txt-primary-invert': colors['light-mode-black-text'],
    'txt-secondary': colors['white-text'],
    'txt-secondary-invert': colors['light-mode-black-text'],
    'txt-placeholder': colors['white-text'],

    'accent-primary': colors['neon'],
    'accent-primary-hover': colors['neon'],
    'accent-primary-active': colors['neon'],
    'accent-primary-disabled': colors['neon'],

    'bg-primary': colors['dark-mode-grey'],
    'bg-primary-hover': colors['dark-mode-grey'],
    'bg-primary-active': colors['dark-mode-black'],
    'bg-secondary': colors['dark-mode-grey'],

    'btn-primary-text': colors['white-text'],
    'btn-disabled-text': colors['white-text'],

    'icons-primary': colors['white-text'],
    'icons-secondary': '#7a7e8b',
    'icons-placeholder': '#7a7e8b',
    'borders-primary': '#7a7e8b',
    'borders-secondary': '#7a7e8b',
    'borders-strong': colors['neon'],

    warning: colors['chart-blue'],
    error: colors['chart-blue'],
  },
  typography: {
    fontFamily: 'Titillium Web, sans-serif',
    baseLineHeight: 1.3,
  },
};
