import { createSelector } from 'reselect';

export const getShowEmailModal = createSelector(
  (state) => state.projects.scenes.details.scenes.emailModal.ui.showEmailModal,
  (showEmailModal) => {
    return showEmailModal;
  }
);

export const getShowCustomEmailError = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.emailModal.ui.showCustomEmailError,
  (showCustomEmailError) => {
    return showCustomEmailError;
  }
);

export const getShowNewCustomEmail = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.emailModal.ui.showNewCustomEmail,
  (showNewCustomEmail) => {
    return showNewCustomEmail;
  }
);

export const getEmails = createSelector(
  (state) => state.projects.scenes.details.scenes.emailModal.ui.emails,
  (emails) => {
    return emails;
  }
);

export const getSubject = createSelector(
  (state) => state.projects.scenes.details.scenes.emailModal.ui.subject,
  (subject) => {
    return subject;
  }
);

export const getMessage = createSelector(
  (state) => state.projects.scenes.details.scenes.emailModal.ui.message,
  (message) => {
    return message;
  }
);

export const getRecipients = createSelector(
  (state) => state.projects.scenes.details.scenes.emailModal.ui.recipients,
  (recipients) => {
    return recipients;
  }
);

export const getCustomEmail = createSelector(
  (state) => state.projects.scenes.details.scenes.emailModal.ui.customEmail,
  (customEmail) => {
    return customEmail;
  }
);

export const getHistory = createSelector(
  (state) => state.projects.scenes.details.scenes.emailModal.data.history,
  (history) => {
    return history;
  }
);
