export const SECURITY_CAMERA_CONNECTORS = [
  { value: '', label: 'Disabled' },
  { value: 'hikvision', label: 'Hikvision' },
];

class SecurityCamera {
  isConfigured = (configuration) =>
    !!(configuration && configuration.connector);
}

export default new SecurityCamera();
