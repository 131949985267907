import React, { Component } from 'react';
import './NotificationViewer.css';
import bellSvg from './bell.svg';
import bellOpenSvg from './bellOpen.svg';
import PropTypes from 'prop-types';
import Popover from 'react-awesome-popover';
import SimpleTable from 'components/shared/table/SimpleTable';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import Date from 'components/shared/text/Date';
import PreviewPopover from 'components/shared/preview-popover/PreviewPopover';
import { bool } from 'prop-types';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import TextTruncate from 'react-text-truncate';

class NotificationViewer extends Component {
  static propTypes = {
    recentActivity: PropTypes.bool,
    currentPage: PropTypes.number,
    currentProject: PropTypes.object,
    pageSize: PropTypes.number,
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        message: PropTypes.string.isRequired,
        target: PropTypes.string.isRequired,
        unread: PropTypes.bool.isRequired,
        user: PropTypes.string,
        content: PropTypes.string,
      })
    ),
    showLoader: PropTypes.bool,
    total: PropTypes.number,
    totalUnread: PropTypes.number,
    onMarkAllAsRead: PropTypes.func,
    onMessageOpen: PropTypes.func,
    onMessageRead: PropTypes.func,
    onMessageUnread: PropTypes.func,
    onShowNewer: PropTypes.func,
    onShowOlder: PropTypes.func,
    onPageChange: PropTypes.func,
    darkMode: bool,
  };

  static defaultProps = {
    recentActivity: false,
    currentPage: 1,
    pageSize: 25,
    messages: [],
    showLoader: false,
    total: 0,
    totalUnread: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      showRead: false,
      open: false,
    };
  }

  scrollToTop = () => {
    if (this.recentActContentEl) {
      this.recentActContentEl.scrollTop = 0;
    }
  };

  render() {
    const {
      recentActivity,
      currentPage,
      currentProject,
      pageSize,
      total,
      title,
      darkMode,
    } = this.props;
    return recentActivity ? (
      <div
        className={`activity-container recent-activity ${
          darkMode ? 'dark-mode' : ''
        }`}
        ref={(el) => (this.recentActContentEl = el)}
      >
        <div className="activity-header">
          <div className="activity-title">{title}</div>
          <div className="activity-total">{total}</div>
        </div>
        <div className="activity-content">
          <SimpleTable
            headers={[
              'DATE',
              'FROM',
              !currentProject && 'PROJECT',
              'ACTIVITY',
            ].filter((header) => header)}
            rows={this.renderRecentActivityMessages()}
            emptyMessage="No Activity"
            onRowSelected={this.handleMessageOpen}
            stickyHeader={true}
          />
        </div>
        <div className="pagination-container">
          <SimplePagination
            currentPage={currentPage}
            pageSize={pageSize}
            total={total}
            onPageChange={this.handlePageChange}
          />
        </div>
      </div>
    ) : (
      <div className={`notification-viewer ${darkMode ? 'dark-mode' : ''}`}>
        <Popover
          placement="bottom-end"
          arrowProps={{
            className: 'nv-popover-arrow',
          }}
          open={this.state.open}
          onClose={this.handleOnClose}
          onOpen={this.handleOnOpen}
        >
          <div className="notifications-selector">
            <img
              className="nv-bell-image"
              src={this.state.open ? bellOpenSvg : bellSvg}
              alt="bell"
            />
            {this.props.totalUnread > 0 && (
              <div className="nv-unread-indicator"></div>
            )}
          </div>
          {this.renderNotificationsContainer()}
        </Popover>
      </div>
    );
  }

  renderNotificationsContainer() {
    const {
      currentPage,
      pageSize,
      total,
      totalUnread,
      showLoader,
      messages,
    } = this.props;

    return (
      <div className="nv-notification-container">
        <div className="nv-notifications-header">
          <div className="nv-notifications-title">Notifications</div>
          {totalUnread > 0 && (
            <Button
              type={BUTTON_TYPES.LINK}
              color={BUTTON_COLORS.GREEN}
              className="nv-read-all-button"
              label="Mark all as read"
              onClick={this.handleMarkAllAsRead}
            />
          )}
        </div>
        <div className="nv-notifications-body">
          {showLoader
            ? this.renderRow('loading', 'nv-loading', 'Loading...')
            : total === 0
            ? this.renderRow(
                'notifications',
                'nv-no-notifications',
                'No Notifications'
              )
            : messages.map(this.renderMessage)}
        </div>
        <SimplePagination
          currentPage={currentPage}
          pageSize={pageSize}
          total={total}
          goToPage={false}
          onPageChange={this.handlePageChange}
        />
      </div>
    );
  }

  renderRow = (key, className, body) => {
    return (
      <React.Fragment key={key}>
        <div className={`nv-notifications-item ${className}`}>{body}</div>
        <div className="nv-notifications-separator"></div>
      </React.Fragment>
    );
  };

  renderMessage = (element) => {
    const { id, unread, content, webAvailable } = element;

    return this.renderRow(
      id,
      'nv-message-item',
      <React.Fragment>
        <div className="nv-notifications-item-content">
          {unread && <div className="nv-notifications-item-unread"></div>}
          <span className="nv-notifications-item-title">
            {this.renderMessageCopy(element)}
          </span>
          {content && (
            <span className="nv-notifications-item-text">{content}</span>
          )}
        </div>
        {this.renderMessagePopover(unread, id, webAvailable)}
      </React.Fragment>
    );
  };

  renderMessageCopy = (element) => {
    const { user, target, message } = element;
    return user
      ? `${user} - ${message} - ${target}`
      : `${target}  ${target ? '-' : ''} ${message}`;
  };

  renderMessages(messages) {
    return (
      <div className="nv-messages">
        <ul>
          {messages.map((element) => {
            if (element.user !== null && element.user !== undefined) {
              return (
                <li key={element.id}>
                  <div className={element.unread ? 'unread' : 'read'}>
                    <span className="user">{element.user}</span>{' '}
                    <span>{element.message}</span>{' '}
                    <span className="target">{element.target}</span>
                    {element.content && (
                      <div>
                        <span>{element.content}</span>
                      </div>
                    )}
                  </div>
                  {this.renderMessagePopover(
                    element.unread,
                    element.id,
                    element.webAvailable
                  )}
                </li>
              );
            } else {
              return (
                <li key={element.id}>
                  <div className={element.unread ? 'unread' : 'read'}>
                    <span className="target">{element.target}</span>{' '}
                    <span>{element.message}.</span>
                    {element.content && (
                      <div>
                        <span>{element.content}</span>
                      </div>
                    )}
                  </div>
                  {this.renderMessagePopover(
                    element.unread,
                    element.id,
                    element.webAvailable
                  )}
                </li>
              );
            }
          })}
        </ul>
      </div>
    );
  }

  renderMessagePopover(unread, id, webAvailable = true) {
    return (
      <div className="nv-message-popover">
        <div className="nv-message-popover-buttons">
          {webAvailable && (
            <Button
              color={BUTTON_COLORS.WHITE}
              label="Open"
              onClick={this.handleMessageOpen.bind(this, id)}
            />
          )}
          {unread ? (
            <Button
              color={BUTTON_COLORS.GREEN}
              label="Mark as read"
              onClick={this.handleMessageRead.bind(this, id)}
            />
          ) : (
            <Button
              color={BUTTON_COLORS.GREEN}
              label="Mark as unread"
              onClick={this.handleMessageUnread.bind(this, id)}
            />
          )}
        </div>
      </div>
    );
  }

  renderRecentActivityMessages() {
    const { messages, currentProject } = this.props;

    return messages.map((element) => {
      const date = <Date value={element.createdAt} format={'MM/DD'} />;
      const userName = element.user;
      const project = element.project;
      const unreadClassName = element.unread ? 'unread' : 'read';
      const message = element.message;

      return {
        id: element.id,
        className: unreadClassName,
        data: [
          <div className="activity-message">{date}</div>,
          <div className="activity-message">{userName || '-'}</div>,
          !currentProject && (
            <div className="activity-message">{project || '-'}</div>
          ),
          <PreviewPopover
            id={element.id}
            title={message}
            subtitle={userName}
            date={date}
            content={this.renderMessageCopy(element)}
            button={
              <TextTruncate
                line={2}
                element="span"
                text={this.renderMessageCopy(element)}
                truncateText="..."
              />
            }
          />,
        ].filter((row) => row),
      };
    });
  }

  handleMessageOpen = (id, event) => {
    event && event.preventDefault();
    if (this.props.onMessageOpen) {
      this.props.onMessageOpen(id);
      // Update state after calling "on" function
      this.setState({
        open: false,
      });
    }
  };

  handleMessageRead = (id, event) => {
    event.preventDefault();
    if (this.props.onMessageRead) {
      this.props.onMessageRead(id);
    }
  };

  handleMessageUnread = (id, event) => {
    event.preventDefault();
    if (this.props.onMessageUnread) {
      this.props.onMessageUnread(id);
    }
  };

  handleMarkAllAsRead = (event) => {
    event.preventDefault();
    if (this.props.onMarkAllAsRead) {
      this.props.onMarkAllAsRead();
    }
  };

  handleOnClose = () => {
    this.setState({
      open: false,
    });
  };

  handleOnOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleNewer = (event) => {
    event.preventDefault();
    if (this.props.onShowNewer) {
      this.props.onShowNewer();
    }
  };

  handleOlder = (event) => {
    event.preventDefault();
    if (this.props.onShowOlder) {
      this.props.onShowOlder();
    }
  };

  handlePageChange = (newPage) => {
    const { onPageChange } = this.props;
    this.scrollToTop();
    onPageChange && onPageChange(newPage);
  };
}

export default NotificationViewer;
