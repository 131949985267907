import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './ProjectOpenItemsTable.css';
import SimpleTable from 'components/shared/table/SimpleTable';
import FilterHeader from 'components/shared/table/FilterHeader';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import Date from 'components/shared/text/Date';
import moment from 'moment';
import { formatNumber } from 'services/utils/text-util';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getProjectOpenItems,
  getCurrentPage,
  getPageSize,
  getTotal,
  getCritical,
  getStatus,
  getDueDateFilter,
} from 'components/admin/projects/details/project-open-items/store/selectors';
import { loadOpenItems } from 'components/admin/projects/details/project-open-items/store/actions';
import BaseStatus from 'components/shared/text/BaseStatus';
import { getNotifications } from 'selectors/notifications';
import { unreadNotifications } from 'domain/notification';

class ProjectOpenItemsTable extends Component {
  static propTypes = {
    onRowSelected: PropTypes.func.isRequired,
  };

  handleGenerateLink = (id) => {
    const { critical } = this.props;
    let query = `?projectOpenItemId=${id}`;
    if (critical) {
      query += `&projectOpenItemCritical=${critical}`;
    }

    return query;
  };

  loadOpenItemsPage = (page, status, dueDateFilter) => {
    const { currentProject, critical, dispatch } = this.props;
    dispatch(
      loadOpenItems(currentProject.id, page, critical, status, dueDateFilter)
    );
  };

  handleStatusChange = (st) => {
    const status = st === 'undefined' ? undefined : st;
    this.loadOpenItemsPage(1, status, this.props.dueDateFilter);
  };

  handleRowSelected = (id) => {
    this.props.onRowSelected(id);
  };

  handlePageChange = (newPage) => {
    this.loadOpenItemsPage(
      newPage,
      this.props.status,
      this.props.dueDateFilter
    );
  };

  mapOpenItems = () => {
    const { projectOpenItems, notifications } = this.props;
    const unreadById = unreadNotifications(notifications, 'ProjectOpenItem');
    return projectOpenItems.map((openItem) => {
      const unread = unreadById && !!unreadById[openItem.id];
      return {
        id: openItem.id,
        className:
          !openItem.draft &&
          !openItem.resolved &&
          openItem.resolve_by &&
          moment(openItem.resolve_by).isBefore(moment().format('YYYY-MM-DD'))
            ? 'past-due'
            : '',
        data: [
          <div>
            {unread && (
              <BaseStatus
                labels={[
                  {
                    id: 'unread',
                    label: '',
                  },
                ]}
                status="unread"
              />
            )}
            {formatNumber(openItem.number)}
          </div>,
          (openItem.csi_code && openItem.csi_code.division) || '00',
          openItem.assignee_label,
          openItem.description,
          <Date value={openItem.created_at} format="MM/DD/YYYY" />,
          openItem.resolve_by && (
            <Date value={openItem.resolve_by} format="MM/DD/YYYY" />
          ),
          openItem.responded_at && (
            <Date value={openItem.responded_at} format="MM/DD/YYYY" />
          ),
          <span
            className={
              openItem.draft || (openItem.draft_response && !openItem.resolved)
                ? 'draft'
                : ''
            }
          >
            {openItem.draft
              ? 'Draft'
              : openItem.resolved
              ? 'Resolved'
              : 'Pending'}
          </span>,
        ],
      };
    });
  };

  render() {
    const {
      currentPage,
      pageSize,
      total,
      status,
      dueDateFilter = 'all',
    } = this.props;
    return (
      <div className="project-open-items-table">
        <SimpleTable
          className="projects-table"
          headers={[
            'ITEM NO',
            'COST CODE',
            'ASSIGNED TO',
            'DESCRIPTION',
            'CREATED',
            <FilterHeader
              label="DATE DUE"
              options={[
                {
                  id: 'all',
                  label: 'All',
                },
                {
                  id: 'pending',
                  label: 'Pending',
                },
                {
                  id: 'overdue',
                  label: 'Overdue',
                },
                {
                  id: 'ascending',
                  label: 'Ascending',
                },
                {
                  id: 'descending',
                  label: 'Descending',
                },
              ].filter((st) => !!st)}
              selected={dueDateFilter}
              onFilter={(filter) => {
                this.loadOpenItemsPage(1, status, filter);
              }}
            />,
            'RESPONDED',
            <FilterHeader
              label="STATUS"
              options={[
                {
                  id: 'undefined',
                  label: 'All',
                },
                {
                  id: 'past_due',
                  label: 'Past Due',
                },
                {
                  id: 'unresolved',
                  label: 'Pending',
                },
                {
                  id: 'resolved',
                  label: 'Resolved',
                },
              ]}
              selected={status}
              onFilter={this.handleStatusChange}
            />,
          ]}
          rows={this.mapOpenItems()}
          onRowSelected={this.handleRowSelected}
          emptyMessage="No Open Items"
          onGenerateLink={this.handleGenerateLink}
        />
        <div className="pagination-container">
          <SimplePagination
            currentPage={currentPage}
            pageSize={pageSize}
            total={total}
            onPageChange={this.handlePageChange}
          />
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    projectOpenItems: getProjectOpenItems(state),
    currentPage: getCurrentPage(state),
    pageSize: getPageSize(state),
    total: getTotal(state),
    critical: getCritical(state),
    status: getStatus(state),
    dueDateFilter: getDueDateFilter(state),
    notifications: getNotifications(state),
  };
})(ProjectOpenItemsTable);
