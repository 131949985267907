import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { InputLabel, Input, FormControl } from '@mui/material';
import './Invitation.css';
import './AddInvitation.css';
import CheckBox from '../../shared/checkbox/CheckBox';
import { InvitationPropTypes } from './UserInvitations';

class AddInvitation extends Component {
  static propTypes = {
    invitation: PropTypes.shape(InvitationPropTypes),
    onChange: PropTypes.func,
  };

  handleSelectChange = (id) => {
    this.props.onChange({
      ...this.props.invitation,
      permissions: this.props.invitation.permissions.map((element, index) => {
        if (index === id) {
          element.selected = !element.selected;
        }
        return element;
      }),
    });
  };

  handleFirstNameChange = (event) => {
    this.props.onChange({
      ...this.props.invitation,
      firstName: event.target.value,
    });
  };

  handleLastNameChange = (event) => {
    this.props.onChange({
      ...this.props.invitation,
      lastName: event.target.value,
    });
  };

  handleEmailChange = (event) => {
    this.props.onChange({
      ...this.props.invitation,
      email: event.target.value,
    });
  };

  handleTitleChange = (event) => {
    this.props.onChange({
      ...this.props.invitation,
      title: event.target.value,
    });
  };

  render() {
    return (
      <div className="invitation-container add-invitation">
        <Row>
          <Col md={12}>
            <div className="title-2">User Details</div>
          </Col>
        </Row>
        <form>
          <Row>
            <Col md={6}>
              <FormGroup>
                <FormControl fullWidth>
                  <InputLabel htmlFor="standard-adornment-firstname">
                    First Name
                  </InputLabel>
                  <Input
                    id="standard-adornment-firstname"
                    type="text"
                    value={this.props.invitation.firstName}
                    onChange={this.handleFirstNameChange}
                    required
                  />
                </FormControl>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <FormControl fullWidth>
                  <InputLabel htmlFor="standard-adornment-lastname">
                    Last Name
                  </InputLabel>
                  <Input
                    id="standard-adornment-lastname"
                    type="text"
                    value={this.props.invitation.lastName}
                    onChange={this.handleLastNameChange}
                    required
                  />
                </FormControl>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup>
                <FormControl fullWidth>
                  <InputLabel htmlFor="standard-adornment-email">
                    Email
                  </InputLabel>
                  <Input
                    id="standard-adornment-email"
                    type="email"
                    value={this.props.invitation.email}
                    onChange={this.handleEmailChange}
                    required
                  />
                </FormControl>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup>
                <FormControl fullWidth>
                  <InputLabel htmlFor="standard-adornment-title">
                    Title
                  </InputLabel>
                  <Input
                    id="standard-adornment-title"
                    type="text"
                    value={this.props.invitation.title}
                    onChange={this.handleTitleChange}
                    required
                  />
                </FormControl>
              </FormGroup>
            </Col>
          </Row>
        </form>

        <Row>
          <Col md={12}>
            <div className="title-2">Account Permissions</div>
            <div className="subtitle">
              You may change these in the Dashboard after creation.
            </div>
          </Col>
        </Row>
        <Row className="add-invitation-permissions-row">
          <Col md={6} sm={6}>
            <div className="permissions-table">
              {this.props.invitation.permissions.map((permission, index) => {
                return (
                  <div className="permissions-table-row" key={permission.id}>
                    <div className="permissions-table-cell permissions-table-cell-checkbox">
                      <CheckBox
                        id={index}
                        checked={permission.selected}
                        onChange={this.handleSelectChange}
                        darkModeFixed={true}
                      />
                    </div>
                    <div className="permissions-table-cell">
                      <span className="permission-label label">
                        {permission.label}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AddInvitation;
