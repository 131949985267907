import axios from 'axios';
import BaseApi from './base-api';
import setAgendaBackupPayload from './project-new-api-agenda-backup-payload';
class NewMeetingsInstancesApi extends BaseApi {
  createMeetingsInstance(projectId, values) {
    const data = setInstancePayload(values);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };

    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/meetings_instances`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          meetingsInstance: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
  updateInstanceMeeting(projectId, meetingsInstanceId, values) {
    const data = setInstancePayload(values);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/meetings_instances/${meetingsInstanceId}`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          meetingsInstance: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
  getMeetingsInstanceById(projectId, meetingsInstanceId) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/meetings_instances/${meetingsInstanceId}`
      )
      .then((response) => {
        return {
          ok: true,
          meetingsInstance: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  sendEmail(
    projectId,
    instanceId,
    emails,
    subject,
    message,
    documentType = 'all'
  ) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const url = `${this.baseUrl}/projects/${projectId}/meetings_instances/${instanceId}/email`;

    let apiPayload = new FormData();
    emails.map((item) =>
      apiPayload.append(`meetings_instance[emails][]`, item)
    );
    apiPayload.append(`meetings_instance[subject]`, subject);
    apiPayload.append(`meetings_instance[message]`, message);
    apiPayload.append(`meetings_instance[document_type]`, documentType);
    return axios
      .post(url, apiPayload, headers)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }
}
const appendTypeFilesInInstance = (apiPayload, values, type) => {
  const key = `${type === 'document' ? 'attachedDocuments' : 'attachedImages'}`;
  const files = values[key];
  const deletedFilesType = `${
    type === 'document' ? 'deletedDocuments' : 'deletedImages'
  }`;
  const deletedFiles = values[deletedFilesType];
  let index = 0;

  files
    .filter((file) => file.file)
    .forEach(({ file }) => {
      apiPayload.append(
        `meetings_instance[attached_${type}s_attributes][${index}][${type}]`,
        file,
        file.name
      );
      index++;
    });
  deletedFiles
    .filter((file) => file.id)
    .forEach((file) => {
      apiPayload.append(
        `meetings_instance[attached_${type}s_attributes][${index}][id]`,
        file.id
      );
      apiPayload.append(
        `meetings_instance[attached_${type}s_attributes][${index}][_destroy]`,
        true
      );
      index++;
    });
};
const appendFilesInInstance = (apiPayload, values) => {
  appendTypeFilesInInstance(apiPayload, values, 'document');
  appendTypeFilesInInstance(apiPayload, values, 'image');
};
export const setInstancePayload = (values) => {
  const apiPayload = new FormData();
  if (values.id) {
    apiPayload.append('meetings_instance[id]', values.id);
  }
  if (values.createdById) {
    apiPayload.append('meetings_instance[created_by_id]', values.createdById);
  }
  if (values.agendaCreatedById) {
    apiPayload.append(
      'meetings_instance[agenda_created_by_id]',
      values.agendaCreatedById
    );
  }
  if (values.minutesCreatedById) {
    apiPayload.append(
      'meetings_instance[minutes_created_by_id]',
      values.minutesCreatedById
    );
  }
  if (values.minutesSubmittedById) {
    apiPayload.append(
      'meetings_instance[minutes_submitted_by_id]',
      values.minutesSubmittedById
    );
  }
  if (values.minutesPublishedDate) {
    apiPayload.append(
      'meetings_instance[minutes_published_date]',
      values.minutesPublishedDate
    );
  }
  if (values.minutesRepublishedDate) {
    apiPayload.append(
      'meetings_instance[minutes_republished_date]',
      values.minutesReublishedDate
    );
  }
  if (values.agendaPublishedDate) {
    apiPayload.append(
      'meetings_instance[agenda_published_date]',
      values.agendaPublishedDate
    );
  }
  if (values.agendaRepublishedDate) {
    apiPayload.append(
      'meetings_instance[agenda_republished_date]',
      values.agendaReublishedDate
    );
  }
  if (values.agendaSubmittedById) {
    apiPayload.append(
      'meetings_instance[agenda_submitted_by_id]',
      values.agendaSubmittedById
    );
  }
  if (values.agendaRepublishedById) {
    apiPayload.append(
      'meetings_instance[agenda_republished_by_id]',
      values.agendaRepublishedById
    );
  }
  if (values.meetingType) {
    apiPayload.append('meetings_instance[meeting_type]', values.meetingType);
  }

  if (values.projectId) {
    apiPayload.append('meetings_instance[project_id]', values.projectId);
  }
  if (values.formattedNumber) {
    apiPayload.append(
      'meetings_instance[formatted_number]',
      values.formattedNumber
    );
  }
  if (values.meetingNumber) {
    apiPayload.append(
      'meetings_instance[meeting_number]',
      values.meetingNumber
    );
  }

  if (values.disclaimer) {
    apiPayload.append('meetings_instance[disclaimer]', values.disclaimer);
  }

  if (values.nonUserAttendees && values.nonUserAttendees.length > 0) {
    values.nonUserAttendees.forEach((usr, index) => {
      const stringifiedUser = JSON.stringify(usr);
      apiPayload.append(
        `meetings_instance[non_user_attendees][${index}][]`,
        stringifiedUser
      );
    });
  }

  if (values.nonUserAttendeesDetails) {
    const attendeeDetailsStr = JSON.stringify(values.nonUserAttendeesDetails);
    apiPayload.append(
      'meetings_instance[non_user_attendees_details]',
      attendeeDetailsStr
    );
  }

  if (values.attendees && values.attendees.length > 0) {
    values.attendees.forEach((usr) => {
      apiPayload.append('meetings_instance[attendee_ids][]', usr.id);
    });
  }
  if (values.meetingDate) {
    apiPayload.append('meetings_instance[meeting_date]', values.meetingDate);
  }
  if (values.title) {
    apiPayload.append('meetings_instance[title]', values.title);
  }
  if (values.address) {
    apiPayload.append('meetings_instance[address]', values.address);
  }
  if (values.location) {
    apiPayload.append('meetings_instance[location]', values.location);
  }
  if (values.jobWalk) {
    apiPayload.append('meetings_instance[job_walk]', values.jobWalk);
  }
  if (values.access) {
    apiPayload.append('meetings_instance[access]', values.access);
  }
  if (values.ranking) {
    apiPayload.append('meetings_instance[ranking]', values.ranking);
  }
  if (values.callInfo) {
    apiPayload.append('meetings_instance[call_info]', values.callInfo);
  }
  if (values.callLink) {
    apiPayload.append('meetings_instance[call_link]', values.callLink);
  }
  if (values.webLink) {
    apiPayload.append('meetings_instance[web_link]', values.webLink);
  }
  if (values.otherInfo) {
    apiPayload.append('meetings_instance[other_info]', values.otherInfo);
  }
  if (values.privateMeeting || values.privateMeeting === false) {
    apiPayload.append(
      'meetings_instance[private_meeting]',
      values.privateMeeting
    );
  }
  if (values.startDate) {
    apiPayload.append('meetings_instance[start_date]', values.startDate);
  }
  if (values.startTime) {
    apiPayload.append('meetings_instance[start_time]', values.startTime);
  }
  if (values.endTime) {
    apiPayload.append('meetings_instance[end_time]', values.endTime);
  }
  if (values.meetingExceptions) {
    apiPayload.append(
      'meetings_instance[meeting_exceptions]',
      values.meetingExceptions
    );
  }
  if (values.isAgenda || values.isAgenda === false) {
    apiPayload.append('meetings_instance[is_agenda]', values.isAgenda);
  }
  if (values.status) {
    apiPayload.append('meetings_instance[status]', values.status);
  }

  if (values.jobWalkConducted) {
    apiPayload.append('meetings_instance[status]', values.status);
  }

  if (values.attendeeDetails) {
    const attendeeDetailsStr =
      typeof values.attendeeDetails === 'string'
        ? values.attendeeDetails
        : JSON.stringify(values.attendeeDetails);
    apiPayload.append(
      'meetings_instance[attendee_details]',
      attendeeDetailsStr
    );
  }
  if (values.meetingsParentId) {
    apiPayload.append(
      'meetings_instance[meetings_parent_id]',
      values.meetingsParentId
    );
  }
  if (values.meetingsCorrections && values.meetingsCorrections.length > 0) {
    values.meetingsCorrections.forEach((mc, mcIndex) => {
      if (mc.id && values.id) {
        apiPayload.append(
          `meetings_instance[meetings_corrections_attributes][${mcIndex}][id]`,
          mc.id
        );
      }
      if (mc.status) {
        apiPayload.append(
          `meetings_instance[meetings_corrections_attributes][${mcIndex}][status]`,
          mc.status
        );
      }
      if (mc.meetingsParentId) {
        apiPayload.append(
          `meetings_instance[meetings_corrections_attributes][${mcIndex}][meetings_parent_id]`,
          mc.meetingsParentId
        );
      }
      if (mc.meetingsInstanceId) {
        apiPayload.append(
          `meetings_instance[meetings_corrections_attributes][${mcIndex}][meetings_instance_id]`,
          mc.meetingsInstanceId
        );
      }
      if (mc.meetingsActionItemId) {
        apiPayload.append(
          `meetings_instance[meetings_corrections_attributes][${mcIndex}][meetings_action_item_id]`,
          mc.meetingsActionItemId
        );
      }
      if (mc.correctionRemark) {
        apiPayload.append(
          `meetings_instance[meetings_corrections_attributes][${mcIndex}][correction_remark]`,
          mc.correctionRemark
        );
      }
      if (mc.correctionRemark) {
        apiPayload.append(
          `meetings_instance[meetings_corrections_attributes][${mcIndex}][correction_remark]`,
          mc.correctionRemark
        );
      }
      if (mc.submittedById) {
        apiPayload.append(
          `meetings_instance[meetings_corrections_attributes][${mcIndex}][submitted_by_id]`,
          mc.submittedById
        );
      }
      if (mc.createdById) {
        apiPayload.append(
          `meetings_instance[meetings_corrections_attributes][${mcIndex}][created_by_id]`,
          mc.createdById
        );
      }
      if (mc.createdAt) {
        apiPayload.append(
          `meetings_instance[meetings_corrections_attributes][${mcIndex}][created_at]`,
          mc.createdAt
        );
      }
      if (mc.meetingCorrectionNo) {
        apiPayload.append(
          `meetings_instance[meetings_corrections_attributes][${mcIndex}][meeting_correction_no]`,
          mc.meetingCorrectionNo
        );
      }
    });
  }
  if (values.meetingsActionItems && values.meetingsActionItems.length > 0) {
    values.meetingsActionItems.forEach((actionItem, actionItemIndex) => {
      if (actionItem.dueDate) {
        apiPayload.append(
          `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][due_date]`,
          actionItem.dueDate
        );
      }
      if (actionItem.meetingsInstanceId) {
        apiPayload.append(
          `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][meetings_instance_id]`,
          actionItem.meetingsInstanceId
        );
      }
      if (actionItem.meetingsParentId) {
        apiPayload.append(
          `meetings_parent[meetings_action_items_attributes][${actionItemIndex}][meetings_parent_id]`,
          actionItem.meetingsParentId
        );
      }

      if (actionItem.id && typeof actionItem.id !== 'string') {
        apiPayload.append(
          `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][id]`,
          actionItem.id
        );
      }
      if (actionItem.status) {
        apiPayload.append(
          `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][status]`,
          actionItem.status
        );
      }
      if (actionItem.priority) {
        apiPayload.append(
          `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][priority]`,
          actionItem.priority
        );
      }
      if (actionItem.description) {
        apiPayload.append(
          `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][description]`,
          actionItem.description
        );
      }
      if (actionItem.category && actionItem.category !== 'nocategory') {
        apiPayload.append(
          `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][category]`,
          actionItem.category
        );
      }
      if (actionItem.responsibleId) {
        apiPayload.append(
          `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][responsible_id]`,
          actionItem.responsibleId
        );
      }
      if (actionItem.responsibleCompanyId) {
        apiPayload.append(
          `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][responsible_company_id]`,
          actionItem.responsibleCompanyId
        );
      }
      if (actionItem.isDiscarded) {
        apiPayload.append(
          `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][is_discarded]`,
          actionItem.isDiscarded
        );
      }
      if (actionItem.isResolved) {
        apiPayload.append(
          `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][is_resolved]`,
          actionItem.isResolved
        );
      }
      if (actionItem.createdOnInstanceId) {
        apiPayload.append(
          `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][created_on_instance_id]`,
          actionItem.createdOnInstanceId
        );
      }
      if (actionItem.submittedById) {
        apiPayload.append(
          `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][submitted_by_id]`,
          actionItem.submittedById
        );
      }
      if (actionItem.createdById) {
        apiPayload.append(
          `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][created_by_id]`,
          actionItem.createdById
        );
      }
      if (actionItem.resolvedById) {
        apiPayload.append(
          `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][resolved_by_id]`,
          actionItem.resolvedById
        );
      }
      if (actionItem.projectId) {
        apiPayload.append(
          `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][project_id]`,
          actionItem.projectId
        );
      }

      if (
        actionItem.meetingsActionItemsNotes &&
        actionItem.meetingsActionItemsNotes.length > 0
      ) {
        actionItem.meetingsActionItemsNotes.forEach((note, noteIndex) => {
          if (note.id) {
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][meetings_action_items_notes_attributes][${noteIndex}][id]`,
              note.id
            );
          }
          if (note.notes)
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][meetings_action_items_notes_attributes][${noteIndex}][notes]`,
              note.notes
            );
          if (note.userId)
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][meetings_action_items_notes_attributes][${noteIndex}][user_id]`,
              note.userId
            );
          if (note.createdById)
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][meetings_action_items_notes_attributes][${noteIndex}][created_by_id]`,
              note.createdById
            );
          if (note.meetingsParentId)
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][meetings_action_items_notes_attributes][${noteIndex}][meetings_parent_id]`,
              note.meetingsParentId
            );
          if (note.meetingsActionItemId)
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][meetings_action_items_notes_attributes][${noteIndex}][meetings_action_item_id]`,
              note.meetingsActionItemId
            );
          if (note.projectId)
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][meetings_action_items_notes_attributes][${noteIndex}][project_id]`,
              note.projectId
            );
          if (note.createdAt)
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][meetings_action_items_notes_attributes][${noteIndex}][created_at]`,
              note.createdAt
            );
        });
      }

      if (
        actionItem.actionItemsUpdates &&
        actionItem.actionItemsUpdates.length > 0
      ) {
        actionItem.actionItemsUpdates.forEach((update, updateIndex) => {
          if (update.id && typeof update.id === 'number') {
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][id]`,
              update.id
            );
          }
          if (update.status) {
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][status]`,
              update.status
            );
          }
          if (update.description) {
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][description]`,
              update.description
            );
          }
          if (update.dueDate) {
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][due_date]`,
              update.dueDate
            );
          }
          if (update.priority) {
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][priority]`,
              update.priority
            );
          }
          if (update.projectId) {
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][project_id]`,
              update.projectId
            );
          }

          if (update.createdOnInstanceId) {
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][created_on_instance_id]`,
              update.createdOnInstanceId
            );
          }
          if (update.submittedById) {
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][submitted_by_id]`,
              update.submittedById
            );
          }
          if (update.createdById) {
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][created_by_id]`,
              update.createdById
            );
          }
          if (update.resolvedById) {
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][resolved_by_id]`,
              update.resolvedById
            );
          }
          if (update.isDiscarded) {
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][is_discarded]`,
              update.isDiscarded
            );
          }
          if (update.isResolved) {
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][is_resolved]`,
              update.isResolved
            );
          }
          if (update.responsibleId) {
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][responsible_id]`,
              update.responsibleId
            );
          }
          if (update.responsibleCompanyId) {
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][responsible_company_id]`,
              update.responsibleCompanyId
            );
          }
          if (update.meetingsInstanceId) {
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][meetings_instance_id]`,
              update.meetingsInstanceId
            );
          }
          if (update.meetingsParentId) {
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][meetings_parent_id]`,
              update.meetingsParentId
            );
          }
          if (update.meetingsActionItemId) {
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][meetings_action_item_id]`,
              update.meetingsActionItemId
            );
          }
          if (update.createdAt) {
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][created_at]`,
              update.createdAt
            );
          }
          if (update.resolvedAt) {
            apiPayload.append(
              `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][resolved_at]`,
              update.resolvedAt
            );
          }
          if (update.nestedUpdates && update.nestedUpdates.length > 0) {
            update.nestedUpdates.forEach((nu, nuIndex) => {
              if (nu.id && typeof nu.id === 'number') {
                apiPayload.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][id]`,
                  nu.id
                );
              }
              if (nu.projectId) {
                apiPayload.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][project_id]`,
                  nu.projectId
                );
              }
              if (nu.description) {
                apiPayload.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][description]`,
                  nu.description
                );
              }
              if (nu.status) {
                apiPayload.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][status]`,
                  nu.status
                );
              }
              if (nu.priority) {
                apiPayload.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][priority]`,
                  nu.priority
                );
              }
              if (nu.dueDate) {
                apiPayload.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][due_date]`,
                  nu.dueDate
                );
              }
              if (nu.responsibleId) {
                apiPayload.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][responsible_id]`,
                  nu.responsibleId
                );
              }
              if (nu.responsibleCompanyId) {
                apiPayload.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][responsible_company_id]`,
                  nu.responsibleCompanyId
                );
              }
              if (nu.submittedById) {
                apiPayload.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][submitted_by_id]`,
                  nu.submittedById
                );
              }
              if (nu.createdById) {
                apiPayload.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][created_by_id]`,
                  nu.createdById
                );
              }
              if (nu.resolvedById) {
                apiPayload.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][resolved_by_id]`,
                  nu.resolvedById
                );
              }
              if (nu.createdOnInstanceId) {
                apiPayload.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][created_on_instance_id]`,
                  nu.createdOnInstanceId
                );
              }
              if (nu.meetingsParentId) {
                apiPayload.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][meetings_parent_id]`,
                  nu.meetingsParentId
                );
              }
              if (nu.meetingsInstanceId) {
                apiPayload.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][meetings_instance_id]`,
                  nu.meetingsInstanceId
                );
              }
              if (nu.meetingsActionItemId) {
                apiPayload.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][meetings_action_item_id]`,
                  nu.meetingsActionItemId
                );
              }
              if (nu.parentUpdateId) {
                apiPayload.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][parent_update_id]`,
                  nu.parentUpdateId
                );
              }
              if (nu.isDiscarded) {
                apiPayload.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][is_discarded]`,
                  nu.isDiscarded
                );
              }
              if (nu.isResolved) {
                apiPayload.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][is_resolved]`,
                  nu.isResolved
                );
              }
              if (nu.createdAt) {
                apiPayload.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][created_at]`,
                  nu.createdAt
                );
              }
              if (nu.resolvedAt) {
                apiPayload.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][resolved_at]`,
                  nu.resolvedAt
                );
              }
              if (nu.createdOnInstanceId) {
                apiPayload.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][created_on_instance_id]`,
                  nu.createdOnInstanceId
                );
              }
            });
          }
        });
      }
    });
  }
  if (values.meetingsInstancesNotes) {
    if (values.meetingsInstancesNotes.id) {
      apiPayload.append(
        'meetings_instance[meetings_instances_notes_attributes][0][id]',
        values.meetingsInstancesNotes.id
      );
    }
    if (values.meetingsInstancesNotes.notes)
      apiPayload.append(
        'meetings_instance[meetings_instances_notes_attributes][0][notes]',
        values.meetingsInstancesNotes.notes
      );
    if (values.meetingsInstancesNotes.userId)
      apiPayload.append(
        'meetings_instance[meetings_instances_notes_attributes][0][user_id]',
        values.meetingsInstancesNotes.userId
      );
    if (values.meetingsInstancesNotes.createdById)
      apiPayload.append(
        'meetings_instance[meetings_instances_notes_attributes][0][created_by_id]',
        values.meetingsInstancesNotes.createdById
      );
    if (values.meetingsInstancesNotes.meetingsParentId)
      apiPayload.append(
        'meetings_instance[meetings_instances_notes_attributes][0][meetings_parent_id]',
        values.meetingsInstancesNotes.meetingsParentId
      );
    if (values.meetingsInstancesNotes.meetingsInstanceId)
      apiPayload.append(
        'meetings_instance[meetings_instances_notes_attributes][0][meetings_instance_id]',
        values.meetingsInstancesNotes.meetingsInstanceId
      );
    if (values.meetingsInstancesNotes.projectId)
      apiPayload.append(
        'meetings_instance[meetings_instances_notes_attributes][0][project_id]',
        values.meetingsInstancesNotes.projectId
      );
    if (values.meetingsInstancesNotes.createdAt)
      apiPayload.append(
        'meetings_instance[meetings_instances_notes_attributes][0][created_at]',
        values.meetingsInstancesNotes.createdAt
      );
  }
  if (values.hasDirectoryPdf || values.hasDirectoryPdf === false) {
    apiPayload.append(
      'meetings_instance[has_directory_pdf]',
      values.hasDirectoryPdf
    );
  }
  if (values.hasOpenItemsLogPdf || values.hasOpenItemsLogPdf === false) {
    apiPayload.append(
      'meetings_instance[has_open_items_log_pdf]',
      values.hasOpenItemsLogPdf
    );
  }
  if (values.hasRfaLogPdf || values.hasRfaLogPdf === false) {
    apiPayload.append(
      'meetings_instance[has_rfa_log_pdf]',
      values.hasRfaLogPdf
    );
  }
  if (values.hasRfiLogPdf || values.hasRfiLogPdf === false) {
    apiPayload.append(
      'meetings_instance[has_rfi_log_pdf]',
      values.hasRfiLogPdf
    );
  }
  if (values.hasRfiLogPdf || values.hasRfiLogPdf === false) {
    apiPayload.append(
      'meetings_instance[has_rfi_log_pdf]',
      values.hasRfiLogPdf
    );
  }
  if (values.hasSubmittalLogPdf || values.hasSubmittalLogPdf === false) {
    apiPayload.append(
      'meetings_instance[has_submittal_log_pdf]',
      values.hasSubmittalLogPdf
    );
  }
  if (values.hasScheduleSummaryPdf || values.hasScheduleSummaryPdf === false) {
    apiPayload.append(
      'meetings_instance[has_schedule_summary_pdf]',
      values.hasScheduleSummaryPdf
    );
  }
  if (values.hasLookaheadPdf || values.hasLookaheadPdf === false) {
    apiPayload.append(
      'meetings_instance[has_lookahead_pdf]',
      values.hasLookaheadPdf
    );
  }
  if (values.hasMilestonesPdf || values.hasMilestonesPdf === false) {
    apiPayload.append(
      'meetings_instance[has_milestones_pdf]',
      values.hasMilestonesPdf
    );
  }
  if (values.meetingsAgendaBackup) {
    setAgendaBackupPayload(apiPayload, values.meetingsAgendaBackup);
  }
  appendFilesInInstance(apiPayload, values);

  return apiPayload;
};
export const newMeetingsInstancesApi = new NewMeetingsInstancesApi();
