import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {
  faChevronUp,
  faChevronDown,
} from '@fortawesome/fontawesome-free-solid';
import { getDarkMode } from 'selectors/theme';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import SimpleTable from 'components/shared/table/SimpleTable';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import Date from 'components/shared/text/Date';
import {
  addProjectReportProjectStatusItem,
  setProjectReportTableItemAttrByType,
  setResolvedStatusByAttr,
  getProjectReportResolveInfoByAttr,
  projectReportResolvedTableFilter,
  deleteProjectReportProjectStatus,
  projectReportRestoreItemByAttr,
  setProjectReportTableMultipleItemsByType,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';
import {
  getProjectReportValue,
  getFieldProjectResolvedInfo,
} from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import ProjectReportProjectStatusModal from './ProjectReportProjectStatusModal';
import { statusesType } from 'domain/project-reports';
import { uuidUtil } from 'services/utils/uuid-util.js';
import { importStatusInformation } from 'services/utils/project-report-util';
import ProjectReportPermissions from 'permissions/project-report-permissions';

import './ReportsForm.css';

class ProjectStatusForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownInfo: {},
      numberAddItemArray: {},
      showModal: false,
      statusTypeId: null,
    };
  }

  componentDidMount() {
    const { projectId, projectReportId, dispatch } = this.props;
    if (projectReportId) {
      dispatch(
        getProjectReportResolveInfoByAttr(
          projectId,
          projectReportId,
          'statuses'
        )
      );
    }
  }

  handleAttributeChange = (item, attr, value) => {
    this.props.dispatch(
      setProjectReportTableItemAttrByType(item, attr, value, 'statuses')
    );
  };

  handleDropdownClick = (key) => {
    const { dropdownInfo } = this.state;
    this.setState({
      dropdownInfo: {
        ...dropdownInfo,
        [key]: !dropdownInfo[key],
      },
    });
  };

  handleAddItem = (id) => {
    const { report, number, projectReportId } = this.props;
    this.props.dispatch(
      addProjectReportProjectStatusItem(
        id,
        null,
        number || report.number,
        projectReportId
      )
    );
  };

  handleModalShow = (value) => {
    const { projectId, projectReportId, dispatch } = this.props;
    this.setState({ showModal: value });
    value &&
      dispatch(
        getProjectReportResolveInfoByAttr(
          projectId,
          projectReportId,
          'statuses'
        )
      );
  };

  getStatusesById = (id) =>
    this.props.report.statuses.filter(
      (elem) =>
        elem.project_report_status_type_id === id &&
        !elem._destroy &&
        !elem.resolved_at
    );

  onImportFromExcel = (item) => (e) => {
    const data = importStatusInformation(e, item);

    if (data.length > 0) {
      data[0].id = item.id;
      this.props.dispatch(
        setProjectReportTableMultipleItemsByType(item, data, 'statuses')
      );
      e.target.blur();
    }
  };

  viewDescription = (item) => {
    return (
      <TextareaAutosizeWrapper
        placeholder="Description"
        value={item.description || ''}
        onChange={(value) =>
          this.handleAttributeChange(item, 'description', value)
        }
        valid={!(this.props.missingField && !item.description)}
        onPaste={this.onImportFromExcel(item)}
      />
    );
  };

  renderButtonAction = (key, label, onClick) => (
    <span
      key={key}
      className={classnames({
        'select-item': true,
        'select-item-active': true,
      })}
      onClick={onClick}
    >
      {label}
    </span>
  );

  viewActions = (item, id) => {
    const {
      projectId,
      projectReportId,
      dispatch,
      report,
      currentUser,
      currentProject,
      number,
    } = this.props;
    const canDelete = ProjectReportPermissions.canDeleteStatus(
      currentUser,
      currentProject
    );
    const revision_number =
      Math.max(
        ...this.getStatusesById(id)
          .filter((el) => el.code === item.code)
          .map((el) => +el.revision_number)
      ) + 1;
    const updateItem = {
      project_report_status_type_id: id,
      id: 'newItem' + uuidUtil.uuidv4(),
      code: item.code,
      description: item.description,
      date: moment(new Date()).format('YYYY-MM-DD'),
      revision_number,
      isEdit: true,
    };
    const isCurrent = !item.previous_published_report_id || item.isEdit;
    return (
      <div className="table-action">
        {!isCurrent &&
          this.renderButtonAction('resolved', 'Resolved', () =>
            dispatch(
              setResolvedStatusByAttr(projectId, projectReportId, 'statuses', {
                ...item,
                resolved_at: moment(new Date()).format('YYYY-MM-DD'),
              })
            )
          )}
        {!isCurrent &&
          this.renderButtonAction('update', 'Update', () =>
            dispatch(
              addProjectReportProjectStatusItem(
                id,
                updateItem,
                number || report.number,
                projectReportId
              )
            )
          )}
        {(canDelete || isCurrent) &&
          this.renderButtonAction('delete', 'Delete', () =>
            dispatch(deleteProjectReportProjectStatus(item.id))
          )}
      </div>
    );
  };

  renderEditRowStatusType = (item) => [
    item.revision_number ? `${item.code}-${item.revision_number}` : item.code,
    this.viewDescription(item),
    <Date value={item.date} format="MM/DD/YYYY" />,
    this.viewActions(item),
  ];

  renderNoEditRowStatusType = (item, id) => [
    item.revision_number ? `${item.code}-${item.revision_number}` : item.code,
    item.description,
    item.date ? <Date value={item.date} format="MM/DD/YYYY" /> : '--',
    this.viewActions(item, id),
  ];

  mapRowsTable = (id) => {
    const status = this.getStatusesById(id);
    const array = status
      .sort((item1, item2) =>
        item1.code !== item2.code
          ? item1.code.localeCompare(item2.code)
          : item1.revision_number - item2.revision_number
      )
      .map((item) => ({
        id: item.id,
        data:
          item.isEdit || item.isUpdate || !item.previous_published_report_id
            ? this.renderEditRowStatusType(item, status.length)
            : this.renderNoEditRowStatusType(item, id),
      }));

    return array;
  };

  resolvedInfoFiltered = (statuses) => {
    const { statusTypeId } = this.state;
    return statusTypeId && statusTypeId !== 'all'
      ? statuses.filter(
          (item) => item.project_report_status_type_id === statusTypeId
        )
      : statuses;
  };

  render() {
    const {
      darkMode,
      resolvedInfo,
      dispatch,
      projectId,
      projectReportId,
    } = this.props;
    const { dropdownInfo, showModal, statusTypeId } = this.state;
    const headers = ['ITEM NO', 'DESCRIPTION *', 'DATE', 'ACTION'];
    const colorChevron = darkMode ? '#D4FB00' : '#00B091';
    const dataForModal = {
      data: this.resolvedInfoFiltered(resolvedInfo.statuses),
      nonFilteredData: resolvedInfo.statuses,
      type: 'statuses',
      attr: 'description',
    };

    return (
      <div>
        <div className="project-status-view-resolved">
          {resolvedInfo.statuses && resolvedInfo.statuses.length > 0 && (
            <Button
              key="project-status-view-resolved"
              type={BUTTON_TYPES.LINK}
              color={BUTTON_COLORS.GREEN}
              label="View Resolved"
              onClick={this.handleModalShow.bind(null, true)}
            />
          )}
        </div>
        {statusesType.map((item) => (
          <div
            key={item.key}
            className={classnames({
              'report-form-block': true,
              'non-margin-bottom-form-block': dropdownInfo[item.key],
            })}
          >
            <div className="row-block row-space-between">
              <div>
                <label className="report-form-block-label">{item.label}</label>
                <FontAwesomeIcon
                  icon={!dropdownInfo[item.key] ? faChevronUp : faChevronDown}
                  color={colorChevron}
                  size="1x"
                  onClick={() => this.handleDropdownClick(item.key)}
                />
              </div>
              {!dropdownInfo[item.key] && (
                <div className="row-block">
                  <Button
                    key="project-status-add-item"
                    type={BUTTON_TYPES.LINK}
                    icon={BUTTON_ICONS.PLUS}
                    label="Add Item"
                    onClick={() => this.handleAddItem(item.id)}
                  />
                </div>
              )}
            </div>
            {!dropdownInfo[item.key] && (
              <div className="table-with-form">
                <SimpleTable
                  className="simple-table projects-table field-project-report-project-status-table"
                  headers={headers}
                  rows={this.mapRowsTable(item.id)}
                  noRows={true}
                />
              </div>
            )}
          </div>
        ))}
        <ProjectReportProjectStatusModal
          data={dataForModal.data || []}
          nonFilteredData={dataForModal.nonFilteredData || []}
          show={showModal}
          onClose={this.handleModalShow.bind(null, false)}
          onSearch={(search) =>
            dispatch(
              projectReportResolvedTableFilter(
                dataForModal.type,
                search,
                dataForModal.attr
              )
            )
          }
          onRestore={(item) =>
            dispatch(
              projectReportRestoreItemByAttr(
                projectId,
                projectReportId,
                'statuses',
                item
              )
            )
          }
          onFilter={(type) => this.setState({ statusTypeId: type })}
          statuses={statusesType}
          type={statusTypeId}
        />
      </div>
    );
  }
}

ProjectStatusForm.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  report: PropTypes.shape().isRequired,
  projectReportId: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  missingField: PropTypes.bool.isRequired,
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
};

export default connect(
  (state) => ({
    darkMode: getDarkMode(state),
    report: getProjectReportValue(state),
    resolvedInfo: getFieldProjectResolvedInfo(state),
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
  }),
  null
)(ProjectStatusForm);
