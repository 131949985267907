import React, { Component } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import Checkbox from 'components/shared/checkbox/CheckBox.js';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import { connect } from 'react-redux';
import {
  getCloseoutCompanyTypes,
  getCloseoutCatsCompanyTypes,
} from 'components/admin/projects/details/project-closeout/store/selectors';
import {
  showCloseoutCatDeletionConfirmation,
  showCloseoutCatModal,
  updateCloseoutCatsCompanyType,
} from 'components/admin/projects/details/project-closeout/store/actions';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import './CloseoutCatRow.css';
import dragDropIcon from 'components/shared/dragDropIcon.svg';

class CloseoutCatRow extends Component {
  static propTypes = {
    closeoutCat: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
  };

  findCatCompanyType = (companyType) => {
    const { closeoutCat, closeoutCatsCompanyTypes } = this.props;
    return closeoutCatsCompanyTypes.find((catCompanyType) => {
      return (
        catCompanyType.closeout_cat_id === closeoutCat.id &&
        catCompanyType.closeout_company_type_id === companyType.id
      );
    });
  };

  handleDelete = (closeoutCat) => {
    const { dispatch } = this.props;
    dispatch(showCloseoutCatDeletionConfirmation(closeoutCat));
  };

  handleRename = (closeoutCat) => {
    const { dispatch } = this.props;
    dispatch(showCloseoutCatModal(closeoutCat));
  };

  updateCatCompanyType = (catCompanyType) => {
    const { dispatch, currentProject } = this.props;
    dispatch(
      updateCloseoutCatsCompanyType(
        currentProject.id,
        catCompanyType.id,
        !catCompanyType.applies
      )
    );
  };

  render() {
    const { closeoutCompanyTypes, index, closeoutCat } = this.props;
    closeoutCompanyTypes.sort((a, b) => a.id - b.id);

    return (
      <Draggable
        key={closeoutCat.id}
        draggableId={closeoutCat.id.toString()}
        index={index}
      >
        {(provided, shapshot) => (
          <tr
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            key={closeoutCat.id}
            className={`${shapshot.isDragging ? 'dragging' : ''}`}
          >
            <td key={1}>
              <img src={dragDropIcon} alt="drag and drop" />
              {closeoutCat.label}
            </td>
            {closeoutCompanyTypes.map((companyType, index) => {
              let catCompanyType = this.findCatCompanyType(companyType);
              return (
                <td key={index + 1}>
                  <Checkbox
                    color="orange"
                    id={!!catCompanyType ? catCompanyType.id : null}
                    checked={!!catCompanyType ? catCompanyType.applies : false}
                    size="small"
                    shape="square"
                    readOnly={!!!catCompanyType}
                    onChange={this.updateCatCompanyType.bind(
                      this,
                      catCompanyType
                    )}
                  />
                </td>
              );
            })}
            <td key={5}>
              <Button
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Rename"
                onClick={this.handleRename.bind(this, closeoutCat)}
              />
              <Button
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Delete"
                onClick={this.handleDelete.bind(this, closeoutCat)}
              />
            </td>
          </tr>
        )}
      </Draggable>
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    closeoutCompanyTypes: getCloseoutCompanyTypes(state),
    closeoutCatsCompanyTypes: getCloseoutCatsCompanyTypes(state),
  };
})(CloseoutCatRow);
