import {
  INIT_PROJECT_PROPOSAL,
  TOGGLE_CREATE_PROPOSAL,
  TOGGLE_CREATE_PROPOSAL_SUCCESS,
  TOGGLE_PDF_VIEWER_SUCCESS,
  TOGGLE_APPROVE_PROPOSAL_SUCCESS,
  PROJECT_PROPOSAL_ITEMS_SET,
  PROJECT_PROPOSAL_ADDL_ITEM_ADD,
  PROJECT_PROPOSAL_ADDL_ITEM_VALUE_UPDATE,
  PROJECT_PROPOSAL_ITEM_ADD,
  PROJECT_PROPOSAL_ITEM_VALUE_UPDATE,
  PROJECT_PROPOSAL_SUB_ITEM_ADD,
  PROJECT_PROPOSAL_SUB_ITEM_VALUE_UPDATE,
  PROJECT_PROPOSAL_FORM_VALUE_UPDATE,
  PROJECT_PROPOSAL_ITEM_DELETE_TOGGLE,
  PROJECT_PROPOSAL_ITEMS_RESET,
  PROJECT_PROPOSAL_READ_ONLY_SET,
  AWARD_RECOMMENDATION_TOGGLE,
  SET_SHOW_CANCEL_MODAL,
  SET_SHOW_LOST_MODAL,
  SET_DATE_MODAL,
  SET_MESSAGE,
  SET_TERMINATION_CATEGORY,
  INIT_PROJECT_LOST_CANCEL_MODAL,
} from 'components/admin/projects/details/project-proposal/store/action-types';

export const initialState = {
  showCreateProposal: false,
  showCreateProposalSuccess: false,
  showPdfViewer: false,
  showApproveProposalSuccess: false,
  projectProposalAddlItems: {
    permits: {},
    generalConditions: {},
    insurance: {},
    fee: {},
    contingency: {},
  },
  projectProposalItems: {},
  projectProposalForm: {
    insuranceRatio: 0.01,
    feeRatio: 0.03,
  },
  itemDelete: {},
  cancelledLostModal: {
    message: '',
  },
  readOnly: true,
  showAwardRecommendation: true,
  showCancelModal: false,
  showLostModal: false,
  showLostOrCancelledModal: false,
  isCancelledModal: false,
  isLostModal: false,
};

export default function projectProposalUiReducer(state = initialState, action) {
  let item = null;
  let subItem = null;

  switch (action.type) {
    case INIT_PROJECT_PROPOSAL:
      return initialState;

    case TOGGLE_CREATE_PROPOSAL:
      return {
        ...state,
        showCreateProposal: !state.showCreateProposal,
      };

    case TOGGLE_CREATE_PROPOSAL_SUCCESS:
      return {
        ...state,
        showCreateProposalSuccess: !state.showCreateProposalSuccess,
      };

    case TOGGLE_PDF_VIEWER_SUCCESS:
      return {
        ...state,
        showPdfViewer: !state.showPdfViewer,
      };

    case TOGGLE_APPROVE_PROPOSAL_SUCCESS:
      return {
        ...state,
        showApproveProposalSuccess: !state.showApproveProposalSuccess,
      };

    case PROJECT_PROPOSAL_ITEMS_SET:
      return {
        ...state,
        projectProposalItems: action.payload.items,
        projectProposalAddlItems: action.payload.addlItems,
      };

    case PROJECT_PROPOSAL_ADDL_ITEM_ADD:
      return {
        ...state,
        projectProposalAddlItems: {
          ...state.projectProposalAddlItems,
          [action.payload.type]: {
            ...state.projectProposalAddlItems[action.payload.type],
            [action.payload.key]: action.payload.item,
          },
        },
      };

    case PROJECT_PROPOSAL_ADDL_ITEM_VALUE_UPDATE:
      item =
        state.projectProposalAddlItems[action.payload.type][action.payload.key];
      return {
        ...state,
        projectProposalAddlItems: {
          ...state.projectProposalAddlItems,
          [action.payload.type]: {
            ...state.projectProposalAddlItems[action.payload.type],
            [action.payload.key]: {
              ...item,
              [action.payload.attribute]: action.payload.value,
            },
          },
        },
      };

    case PROJECT_PROPOSAL_ITEM_ADD:
      return {
        ...state,
        projectProposalItems: {
          ...state.projectProposalItems,
          [action.payload.key]: action.payload.item,
        },
      };

    case PROJECT_PROPOSAL_ITEM_VALUE_UPDATE:
      item = state.projectProposalItems[action.payload.key];
      return {
        ...state,
        projectProposalItems: {
          ...state.projectProposalItems,
          [action.payload.key]: {
            ...item,
            [action.payload.attribute]: action.payload.value,
          },
        },
      };

    case PROJECT_PROPOSAL_SUB_ITEM_ADD:
      item = state.projectProposalItems[action.payload.itemKey];
      return {
        ...state,
        projectProposalItems: {
          ...state.projectProposalItems,
          [action.payload.itemKey]: {
            ...item,
            subItems: {
              ...item.subItems,
              [action.payload.key]: action.payload.subItem,
            },
          },
        },
      };

    case PROJECT_PROPOSAL_SUB_ITEM_VALUE_UPDATE:
      item = state.projectProposalItems[action.payload.itemKey];
      subItem = item.subItems[action.payload.key];
      return {
        ...state,
        projectProposalItems: {
          ...state.projectProposalItems,
          [action.payload.itemKey]: {
            ...item,
            subItems: {
              ...item.subItems,
              [action.payload.key]: {
                ...subItem,
                [action.payload.attribute]: action.payload.value,
              },
            },
          },
        },
      };

    case PROJECT_PROPOSAL_FORM_VALUE_UPDATE:
      return {
        ...state,
        projectProposalForm: {
          ...state.projectProposalForm,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case PROJECT_PROPOSAL_ITEM_DELETE_TOGGLE:
      return {
        ...state,
        itemDelete: {
          [action.payload]: !state.itemDelete[action.payload],
        },
      };

    case PROJECT_PROPOSAL_ITEMS_RESET:
      return {
        ...state,
        ...initialState,
      };

    case PROJECT_PROPOSAL_READ_ONLY_SET:
      return {
        ...state,
        readOnly: action.payload,
      };

    case AWARD_RECOMMENDATION_TOGGLE:
      return {
        ...state,
        showAwardRecommendation: !state.showAwardRecommendation,
      };

    case SET_SHOW_CANCEL_MODAL:
      return {
        ...state,
        isCancelledModal: action.payload,
        showLostOrCancelledModal: action.payload,
      };

    case SET_SHOW_LOST_MODAL:
      return {
        ...state,
        isLostModal: action.payload,
        showLostOrCancelledModal: action.payload,
      };

    case SET_DATE_MODAL:
      return {
        ...state,
        cancelledLostModal: {
          ...state.cancelledLostModal,
          date: action.payload,
        },
      };

    case SET_MESSAGE:
      return {
        ...state,
        cancelledLostModal: {
          ...state.cancelledLostModal,
          message: action.payload,
        },
      };

    case SET_TERMINATION_CATEGORY:
      return {
        ...state,
        cancelledLostModal: {
          ...state.cancelledLostModal,
          terminationCategory: action.payload,
        },
      };

    case INIT_PROJECT_LOST_CANCEL_MODAL:
      return {
        ...state,
        cancelledLostModal: {
          date: action.payload.date,
          message: action.payload.message,
          terminationCategory: action.payload.terminationCategory,
        },
      };

    default:
      return state;
  }
}
