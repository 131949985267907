import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import togglerSvg from './sidebar-toggler.svg';
import togglerDarkSvg from './sidebar-toggler-dark.svg';

class FormModalSidebar extends Component {
  static propTypes = {
    className: PropTypes.string,
    show: PropTypes.bool.isRequired,
    title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    content: PropTypes.element,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onChange: () => {},
  };

  render() {
    const { className, show, title, content, onChange, darkMode } = this.props;

    return (
      <div
        className={`form-modal-sidebar ${className || ''} ${
          show ? 'sidebar-open' : 'sidebar-closed'
        } ${darkMode ? 'dark-mode' : ''}`}
      >
        <div className="sidebar-header" onClick={onChange}>
          <div className="sidebar-title">{title}</div>
          <img
            className="sidebar-toggler"
            alt="toggler"
            src={darkMode ? togglerDarkSvg : togglerSvg}
          />
        </div>
        <div className="sidebar-content">{content}</div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(FormModalSidebar);
