import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import DetailsInfoItem from 'components/shared/details/DetailsInfoItem';
import Number from 'components/shared/text/Number';
import { goToEditProject } from 'components/admin/projects/store/actions';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import DashboardRecentActivity from 'components/admin/dashboard/DashboardRecentActivity';
import ChatActivity from 'components/admin/chats/ChatActivity';
import LogoUpload, {
  LOGO_UPLOAD_ICON,
} from 'components/shared/logo-upload/LogoUpload';
import CharsCounterTextarea from 'components/shared/chars-counter/CharsCounterTextarea';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import {
  changeProjectLogo,
  changeProjectDescription,
} from 'components/admin/projects/details/store/actions';
import { getShowNewContributorModal } from 'components/admin/projects/details/project-directory/store/selectors';
import { setShowNewContributorModal } from 'components/admin/projects/details/project-directory/store/actions';
import NewContributorModal from 'components/admin/projects/details/project-directory/NewContributorModal';
import { COMPANY_TYPES } from 'domain/company-type-enum';
import ProjectPermissions from 'permissions/project-permissions';
import DirectoryPermissions from 'permissions/directory-permissions';
import './ProjectHome.css';

class ProjectHome extends Component {
  constructor(props) {
    super(props);

    const { currentUser, currentProject } = props;

    this.canEdit = ProjectPermissions.canEdit(currentUser, currentProject);
    this.canEditDescription = ProjectPermissions.canEditDescription(
      currentUser,
      currentProject
    );
    this.canEditLogo = ProjectPermissions.canEditLogo(
      currentUser,
      currentProject
    );
    this.canAddMembers = DirectoryPermissions.canAddContributor(
      currentUser,
      currentProject
    );
    this.loadLogo();
  }

  onEditClick() {
    this.props.dispatch(goToEditProject(this.props.currentProject.id));
  }

  renderHeaderButtons() {
    return [
      this.canEdit && (
        <Button
          key="edit-details"
          type={BUTTON_TYPES.LINK}
          color={BUTTON_COLORS.GREEN}
          label="Edit Info"
          onClick={() => this.onEditClick()}
        />
      ),
    ].filter((component) => component);
  }

  loadLogo() {
    const { currentProject } = this.props;
    let logo = currentProject && currentProject.logo;
    if (logo && logo.image && logo.image.url) {
      logo.url = logo.image.url;
      this.logo = logo;
    } else {
      this.logo = null;
    }
  }

  setLogo(file) {
    const { currentProject } = this.props;
    const logo = file
      ? {
          url: URL.createObjectURL(file),
          file: file,
          image: file,
        }
      : {
          id: this.logo.id,
          url: '',
        };
    this.props.dispatch(changeProjectLogo(currentProject.id, logo));
  }

  renderLogo() {
    this.loadLogo();

    return (
      <div className="project-logo">
        <LogoUpload
          className="details-uploader"
          icon={LOGO_UPLOAD_ICON.PLUS}
          extensions={['.jpg', '.jpeg', '.gif', '.png', '.heic']}
          logos={this.logo ? [this.logo] : []}
          readOnly={!this.canEditLogo}
          useDropzone={true}
          dropzoneLabel="Upload Project Image"
          deleteConfirmationTitle="Delete Photo"
          deleteConfirmationMessage="Are you sure you want to delete the photo?"
          onRemove={() => this.setLogo()}
          onUpload={(files) => this.setLogo(files[0])}
        />
      </div>
    );
  }

  setDescription(description) {
    const { currentProject } = this.props;
    if (
      (description || currentProject.description) &&
      description !== currentProject.description
    ) {
      this.props
        .dispatch(changeProjectDescription(currentProject.id, description))
        .then(() => {
          currentProject.description = description;
        });
    }
  }

  renderDescription() {
    return (
      <div className="project-description">
        <CharsCounterTextarea
          readOnly={!this.canEditDescription}
          value={this.props.currentProject.description}
          placeholder="Project Description"
          onBlur={(e) => this.setDescription(e.target.value)}
        />
      </div>
    );
  }

  renderProjectNumber() {
    return (
      <DetailsInfoItem
        className="half-item"
        title="Project Number"
        lines={[this.props.currentProject.number]}
      />
    );
  }

  renderProjectSize() {
    return (
      <DetailsInfoItem
        className="half-item"
        title="Project Sq Ft"
        lines={[
          <Number value={this.props.currentProject.sq_footage} format="0,0" />,
        ]}
      />
    );
  }

  addMember(type) {
    if (type === COMPANY_TYPES.CLIENT) return this.onEditClick();

    this.contributorType = type;
    this.props.dispatch(setShowNewContributorModal(true));
  }

  renderMember(title, name, location, add = false) {
    const lines =
      location || name
        ? [
            name,
            (location && location.street_address_1) || '',
            (location && location.street_address_2) || '',
            (location &&
              `${location.city || ''}, ${location.state || ''} ${
                location.zip || ''
              }`) ||
              '',
          ].filter((line) => line)
        : this.canAddMembers && add
        ? [
            <Button
              key="add-button"
              type={BUTTON_TYPES.LINK}
              className="add-member-button"
              label={add.label}
              icon={BUTTON_ICONS.PLUS}
              onClick={() => this.addMember(add.type)}
            />,
          ]
        : [];

    return <DetailsInfoItem title={title} lines={lines} />;
  }

  renderAddress() {
    return this.renderMember(
      'Project Address',
      null,
      this.props.currentProject.project_location.location
    );
  }

  renderOwner() {
    const owner = this.props.currentProject.client;
    return this.renderMember(
      'Owner',
      owner && owner.name,
      owner && owner.location,
      { type: COMPANY_TYPES.CLIENT, label: 'Add Owner' }
    );
  }

  renderArchitect() {
    const architect = this.props.currentProject.architect;
    return this.renderMember(
      'Architect',
      architect && architect.name,
      architect && architect.location,
      { type: COMPANY_TYPES.ARCHITECT, label: 'Add Architect' }
    );
  }

  renderOAR() {
    const oar = this.props.currentProject.oar;
    return this.renderMember(
      'Owners Authorized Representative',
      oar && oar.name,
      oar && oar.location,
      { type: COMPANY_TYPES.OWNER_AUTHORIZED_REP, label: 'Add OAR' }
    );
  }

  renderGC() {
    const gc = this.props.currentProject.general_contractor;
    return this.renderMember('Contractor', gc && gc.name, gc && gc.location, {
      type: COMPANY_TYPES.GENERAL_CONTRACTOR,
      label: 'Add Contractor',
    });
  }

  renderBuildingOwner() {
    const landlord = this.props.currentProject.landlord;
    return this.renderMember(
      'Landlord',
      landlord && landlord.name,
      landlord && landlord.location,
      {
        type: COMPANY_TYPES.LANDLORD,
        label: 'Add Landlord',
      }
    );
  }

  renderBuildingManager() {
    const pm = this.props.currentProject.property_manager;
    return this.renderMember(
      'Property Manager',
      pm && pm.name,
      pm && pm.location,
      {
        type: COMPANY_TYPES.PROPERTY_MANAGER,
        label: 'Add Manager',
      }
    );
  }

  render() {
    const {
      renderHeaders,
      currentProject,
      darkMode,
      showNewContributorModal,
    } = this.props;

    return (
      currentProject && (
        <div className={`project-home ${darkMode ? 'dark-mode' : ''}`}>
          <div className="content-container">
            {renderHeaders(this.renderHeaderButtons())}
            <div className="home-title">
              <h3 className="h1">Project Information</h3>
            </div>
            <div className="home-body">
              <div className="home-details">
                {this.renderLogo()}
                {this.renderDescription()}
              </div>
              <div className="home-items">
                {this.renderAddress()}
                {this.renderOwner()}
                {this.renderArchitect()}
                {this.renderBuildingOwner()}
                {this.renderProjectNumber()}
                {this.renderProjectSize()}
                {this.renderOAR()}
                {this.renderGC()}
                {this.renderBuildingManager()}
              </div>
            </div>
          </div>
          <div className="project-home-activity">
            <DashboardRecentActivity currentProject={currentProject} />
            <ChatActivity currentProject={currentProject} />
          </div>
          {showNewContributorModal && (
            <NewContributorModal fixedContributorType={this.contributorType} />
          )}
        </div>
      )
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    currentUser: getCurrentUser(state),
    darkMode: getDarkMode(state),
    showNewContributorModal: getShowNewContributorModal(state),
  };
})(ProjectHome);
