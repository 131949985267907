import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormModal from 'components/shared/modal/FormModal';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import './AddProjectReportLog.css';

class ProjectReportSendReviewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectEmails: [],
    };
  }
  render() {
    const { emails, show, onClose, onSend, title } = this.props;
    const { selectEmails } = this.state;
    return (
      <FormModal
        id="project-report-save-draft-modal"
        className="project-report-save-draft-modal-view"
        show={show}
        onClose={onClose}
        subTitle={title}
      >
        <div className="modal-body-section">
          <div className="dropdown-description">
            Select the recipients who are required to review this report or be
            notified of its status, prior to formal issuance.
          </div>
          <div className="dropdown-section">
            <MultipleDropdown
              options={emails}
              currentValues={selectEmails}
              placeholder="To"
              onChange={(value) => this.setState({ selectEmails: value })}
            />
          </div>
          <div className="buttons-section">
            <Button
              key="save-draft-button"
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Send"
              onClick={() => {
                onSend(selectEmails);
                onClose();
              }}
            />
          </div>
        </div>
      </FormModal>
    );
  }
}

ProjectReportSendReviewModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSend: PropTypes.func,
  title: PropTypes.string,
};

ProjectReportSendReviewModal.defaultProps = {
  show: false,
  onClose: () => null,
  onSend: () => null,
  title: 'Send For Review',
};

export default ProjectReportSendReviewModal;
