import moment from 'moment';
import { uuidUtil } from 'services/utils/uuid-util.js';

import {
  DAILY_REPORTS_SET,
  DAILY_REPORTS_UPDATE,
  SET_CONTRIBUTORS,
  SET_CURRENT_DAILY_REPORT,
  LOAD_PRESET_TASKS,
  ADD_DAILY_REPORT_SUCCESS,
  INITIALIZE_TRADE_FORM,
  UPDATE_TRADE_FORM,
  RESTORE_TRADE_FORM,
  RESTORE_MEMBER_FORM,
  SET_MEMBER_FORM,
  CHANGE_AMOUNT_OF_TECHNICIANS,
  HANDLE_WORKED_FORM,
  HANDLE_WORKED_TO,
  HANDLE_HOURS_AMOUNT,
  HANDLE_HOURS_AMOUNT_MORE_INFO,
  HANDLE_ATTRIBUTE_CHANGE,
  CLEAR_FORM,
  SET_DAILY_REPORT_LOG_PDF,
  SET_REPORTS_IDS,
  DELETE_DAILY_REPORT,
  SET_ACTIVE_ENTRIES_ID,
  SET_REPORT_PDF,
  SET_REPORT_DATE,
  SET_REPORT_EXTRA_INPUTS,
  SET_REPORT_EXTRA_INPUT,
  SET_REPORT_OPEN_ITEM,
  SET_REPORT_OPEN_ITEM_VALUE,
  SET_GENERAL_CONTACTOR_NAME,
  SET_SUPERVISOR_TASKS,
  SET_MEMBERS,
  SET_DELETED_MEMBERS,
  SET_GCX_IMAGES,
  SET_GCX_DELETED_IMAGES,
  SUB_DAILY_REPORTS_COUNT_SUCCESS,
  SUB_DAILY_REPORTS_COUNT_INIT,
  HANDLE_ATTRIBUTE_CHANGE_FOR_SUB,
  HANDLE_DATE_MORE_INFO,
  HANDLE_ATTRIBUTE_CHANGE_FOR_SUB_MORE_ACTION,
  ADDED_DELETED_TECHNICIANS,
  FIELD_WORK_ORDERS_SET,
  FIELD_WORK_ORDER_SET,
  FIELD_WORK_ORDER_FORM_INIT,
  FIELD_WORK_ORDER_FORM_ATTRIBUTE_SET,
  UPDATE_FIELD_WORK_ORDER_SUCCESS,
  DUPLICATE_DAILY_REPORT_SUCCESS,
  SET_PROJECT_REPORT_ATTR,
  ADD_PROJECT_REPORT_CONTACT_INFORMATION_ITEM,
  ADD_PROJECT_REPORT_PROJECT_STATUS_ITEM,
  SET_PROJECT_REPORT_DOCUMENTS_PHOTOS_ATTR,
  SET_PROJECT_REPORT_PDF,
  PROJECT_REPORTS_SET,
  PROJECT_REPORT_RELOAD,
  DELETE_PROJECT_REPORT,
  PROJECT_REPORT_SET,
  PROJECT_REPORT_SET_NUMBER,
  RESET_PROJECT_REPORT_FORM,
  ADD_ITEM_PROJECT_REPORT_TABLE,
  DELETE_PROJECT_REPORT_TABLE_ITEM_BY_TYPE,
  SET_PROJECT_REPORT_PROJECT_TABLE_ITEM_ATTR_BY_TYPE,
  ADD_PROJECT_REPORT_BUDGET_DESCRIPTION_BY_ID,
  GET_PROJECT_REPORT_RESOLVED_INFO_BY_ATTR,
  PROJECT_REPORT_RESOLVED_TABLE_FILTER,
  PROJECT_REPORT_TABLES_ORDER_CHANGE,
  PROJECT_REPORT_PHOTOS_AND_DOCUMENTS_FILTER,
  DELETE_PROJECT_REPORT_PROJECT_STATUS,
  PROJECT_REPORT_PHOTOS_AND_DOCUMENTS_FILTER_CLEAR,
  SET_LAST_PROJECT_REPORT,
  SET_PROJECT_REPORT_TABLE_MULTIPLE_ITEMS_BY_TYPE,
  SIMPLIFIED_REPORT_CREATION_SUCCESS,
  SIMPLIFIED_REPORT_UPDATE_SUCCESS,
} from 'components/admin/projects/details/project-daily-report-log/store/action-types';

const projectReportsBudgetsDefaultValues = [
  'Land & Acquisition',
  'Construction Contractor',
  'Construction Owner',
  'Design & Engineering',
  'Misc. Soft Costs',
  'Financing',
  'Contingency',
];
const budgetsDefaultTableOne = projectReportsBudgetsDefaultValues.map(
  (item, index) => ({
    description: item,
    id: `1${index}`,
    project_report_budget_category_id: 1,
    isEdit: true,
  })
);
const budgetsDefaultTableTwo = projectReportsBudgetsDefaultValues.map(
  (item, index) => ({
    description: item,
    id: `2${index}`,
    project_report_budget_category_id: 2,
    isEdit: true,
  })
);

const setNewOrder = (array, action) => {
  const newOrder = Array.from(array);
  const data = newOrder.splice(action.payload.result.source.index, 1);
  newOrder.splice(action.payload.result.destination.index, 0, data[0]);
  return newOrder;
};

const sortStatuses = (statuses) => {
  statuses = statuses.sort((a, b) => {
    if (a.project_report_status_type_id !== b.project_report_status_type_id)
      return a.project_report_status_type_id - b.project_report_status_type_id;

    let aSplit = a.code.split('.');
    let bSplit = b.code.split('.');
    if (aSplit[0] === bSplit[0]) {
      return aSplit[1] - bSplit[1];
    } else {
      return aSplit[0] - bSplit[0];
    }
  });

  const numbers = {};
  const versions = {};
  return statuses.map((item) => {
    if (item._destroy || item.resolved_at) return item;

    const originalCode = item.code;
    const itemReport = item.code.split('.')[0];

    numbers[itemReport] =
      (numbers[itemReport] || 0) + (!versions[originalCode] ? 1 : 0);
    item.code = item.code = `${itemReport}.${
      numbers[itemReport] < 10 ? 0 : ''
    }${numbers[itemReport]}`;

    versions[originalCode] = versions[originalCode] || 1;
    if (item.revision_number) {
      item.revision_number = versions[originalCode];
      versions[originalCode]++;
    }
    return item;
  });
};

const initialState = {
  dailyReports: [],
  fieldWorkOrders: [],
  fieldProjectReports: [],
  currentDailyReport: null,
  currentDailyReportIds: null,
  currentFieldWorkOrder: null,
  contributors: [],
  presetTasks: {},
  contributorsForm: {},
  dailyReportLogPdf: null,
  projectReportPdf: null,
  activeEntryId: null,
  reportLogPdf: null,
  subDailyReportsCount: null,
  reportDate: moment().format('YYYY-MM-DD'),
  reportExtraInputs: {},
  reportOpenItem: {},
  generalContactor: null,
  members: [],
  deletedMembers: [],
  supervisorTasks: [],
  gcImages: [],
  gcDeletedImages: [],
  fieldWorkOrderForm: {},
  fieldProjectReportForm: {
    date: moment(new Date()).format('YYYY-MM-DD'),
    remarks: '',
    cover_image: null,
    contract_info: [],
    statuses: [],
    budgets: [...budgetsDefaultTableOne, ...budgetsDefaultTableTwo],

    schedule_categories: [],
    directory_details_type: 'nonee',
    directory_details: '',
  },
  projectReportResolvedInfo: {},
  originalContributorForm: null,
  originalMemberForm: null,
  lastProjectReport: null,
};

export default function projectDailyReportLogDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case DAILY_REPORTS_SET:
      return {
        ...state,
        dailyReports: action.payload,
      };

    case PROJECT_REPORTS_SET:
      return {
        ...state,
        fieldProjectReports: action.payload,
      };

    case PROJECT_REPORT_RELOAD:
      return {
        ...state,
        fieldProjectReports: state.fieldProjectReports.map((report) =>
          report.id === action.payload.id ? action.payload : report
        ),
      };

    case PROJECT_REPORT_SET:
      const report = action.payload;
      const attach_documents = {};
      report.attach_documents.forEach((item) => {
        attach_documents[item] = {
          key: item,
          value: true,
        };
      });

      return {
        ...state,
        fieldProjectReportForm: {
          id: report.id,
          number: report.number,
          date: report.date,
          directory_details: report.directory_details,
          directory_details_type: report.directory_details_type || 'nonee',
          attach_documents: attach_documents,
          recipients: report.users.map((item) => [item.id, item.label]),
          remarks: report.remarks || '',
          cover_image: report.cover_image && [
            {
              ...report.cover_image,
              url: report.cover_image.image.url,
            },
          ],
          budget_remarks: report.budget_remarks || '',
          completion_date: report.completion_date || '',
          original_completion_date: report.original_completion_date || '',
          previous_completion_date: report.previous_completion_date || '',
          schedule_remarks: report.schedule_remarks || '',
          disclaimer_remarks: report.disclaimer_remarks || '',
          images: report.images.map((image) => ({
            ...image,
            url: image.image.url,
          })),
          documents: report.documents.map((doc) => ({
            ...doc,
            url: doc.document.url,
          })),
          contract_info: report.project_report_contract_infos,
          statuses: report.project_report_statuses.sort(
            (a, b) => a.code.split('.')[1] - b.code.split('.')[1]
          ),
          budgets: report.project_report_budgets,
          schedule_categories: (report.schedule_categories || []).map(
            (s) => s.id
          ),
          status: report.status,
        },
      };

    case PROJECT_REPORT_SET_NUMBER:
      return {
        ...state,
        fieldProjectReportForm: {
          ...state.fieldProjectReportForm,
          number: action.payload.number,
        },
      };

    case FIELD_WORK_ORDERS_SET:
      return {
        ...state,
        fieldWorkOrders: action.payload,
      };

    case FIELD_WORK_ORDER_SET:
      return {
        ...state,
        currentFieldWorkOrder: action.payload,
      };

    case SET_REPORTS_IDS:
      return {
        ...state,
        currentDailyReportIds: action.payload,
      };

    case SET_ACTIVE_ENTRIES_ID:
      return {
        ...state,
        activeEntryId: action.payload,
        originalContributorForm:
          action.payload && state.contributorsForm[action.payload]
            ? {
                id: action.payload,
                data: JSON.parse(
                  JSON.stringify(state.contributorsForm[action.payload])
                ),
              }
            : null,
      };

    case RESTORE_TRADE_FORM:
      return state.originalContributorForm
        ? {
            ...state,
            contributorsForm: {
              ...state.contributorsForm,
              [state.originalContributorForm.id]:
                state.originalContributorForm.data,
            },
            originalContributorForm: null,
          }
        : state;

    case SET_MEMBER_FORM:
      return {
        ...state,
        originalMemberForm: action.payload
          ? JSON.parse(
              JSON.stringify({
                members: state.members,
                deletedMembers: state.deletedMembers,
                reportOpenItem: state.reportOpenItem,
                gcImages: state.gcImages,
                gcDeletedImages: state.gcDeletedImages,
              })
            )
          : null,
      };

    case RESTORE_MEMBER_FORM:
      return state.originalMemberForm
        ? {
            ...state,
            members: state.originalMemberForm.members,
            deletedMembers: state.originalMemberForm.deletedMembers,
            reportOpenItem: state.originalMemberForm.reportOpenItem,
            gcImages: state.originalMemberForm.gcImages,
            gcDeletedImages: state.originalMemberForm.gcDeletedImages,
            originalMemberForm: null,
          }
        : state;

    case DAILY_REPORTS_UPDATE:
      return {
        ...state,
        dailyReports: state.dailyReports.map((report) => {
          if (report.id === action.payload.id) {
            return action.payload;
          }
          return report;
        }),
      };

    case SET_CONTRIBUTORS:
      return {
        ...state,
        contributors: action.payload,
      };

    case SET_CURRENT_DAILY_REPORT:
      return {
        ...state,
        currentDailyReport: action.payload,
      };

    case LOAD_PRESET_TASKS:
      return {
        ...state,
        presetTasks: action.payload,
      };

    case ADD_DAILY_REPORT_SUCCESS:
      return {
        ...state,
        currentDailyReport: action.payload,
        dailyReports: [
          action.payload,
          ...state.dailyReports.filter(
            (report) => report.id !== action.payload.id
          ),
        ],
        currentDailyReportIds: action.payload.daily_report_entries
          .sort((report1, report2) => report1.id - report2.id)
          .map((report) => report.id),
      };

    case INITIALIZE_TRADE_FORM:
      return {
        ...state,
        contributorsForm: {
          ...state.contributorsForm,
          ...action.payload,
        },
      };

    case UPDATE_TRADE_FORM:
      return {
        ...state,
        contributorsForm: action.payload,
      };

    case CHANGE_AMOUNT_OF_TECHNICIANS:
      return {
        ...state,
        contributorsForm: {
          ...state.contributorsForm,
          [action.payload.contribId]: {
            ...state.contributorsForm[action.payload.contribId],
            technicians: action.payload.technicians,
          },
        },
      };

    case ADDED_DELETED_TECHNICIANS:
      return {
        ...state,
        contributorsForm: {
          ...state.contributorsForm,
          [action.payload.contribId]: {
            ...state.contributorsForm[action.payload.contribId],
            deletedTechnicians: [
              ...state.contributorsForm[action.payload.contribId]
                .deletedTechnicians,
              action.payload.technician,
            ],
          },
        },
      };

    case HANDLE_WORKED_FORM:
      let newTechnicians = [
        ...state.contributorsForm[action.payload.contribId].technicians,
      ];
      newTechnicians[action.payload.techId] = {
        ...state.contributorsForm[action.payload.contribId].technicians[
          action.payload.techId
        ],
        workedFrom: action.payload.value,
        hoursAmount: '',
      };
      return {
        ...state,
        contributorsForm: {
          ...state.contributorsForm,
          [action.payload.contribId]: {
            ...state.contributorsForm[action.payload.contribId],
            technicians: newTechnicians,
          },
        },
      };

    case HANDLE_WORKED_TO:
      let newTechniciansValues = [
        ...state.contributorsForm[action.payload.contribId].technicians,
      ];
      newTechniciansValues[action.payload.techId] = {
        ...state.contributorsForm[action.payload.contribId].technicians[
          action.payload.techId
        ],
        workedTo: action.payload.value,
        hoursAmount: '',
      };
      return {
        ...state,
        contributorsForm: {
          ...state.contributorsForm,
          [action.payload.contribId]: {
            ...state.contributorsForm[action.payload.contribId],
            technicians: newTechniciansValues,
          },
        },
      };

    case HANDLE_ATTRIBUTE_CHANGE_FOR_SUB:
      let newTechniciansValuesForSub = [
        ...state.contributorsForm[action.payload.contribId].technicians,
      ];
      let additionalInfo =
        newTechniciansValuesForSub[action.payload.techId].additionalInfo;
      if (action.payload.attribute === 'sub_technician' && additionalInfo) {
        additionalInfo = additionalInfo.map((info) => ({
          ...info,
          sub_technician_id: '',
          sub_technician: action.payload.value,
        }));
      }
      newTechniciansValuesForSub[action.payload.techId] = {
        ...state.contributorsForm[action.payload.contribId].technicians[
          action.payload.techId
        ],
        [action.payload.attribute]: action.payload.value,
        additionalInfo,
      };
      return {
        ...state,
        contributorsForm: {
          ...state.contributorsForm,
          [action.payload.contribId]: {
            ...state.contributorsForm[action.payload.contribId],
            technicians: newTechniciansValuesForSub,
          },
        },
      };

    case HANDLE_ATTRIBUTE_CHANGE_FOR_SUB_MORE_ACTION:
      let techniciansValuesForMoreInfoAttribute = [
        ...state.contributorsForm[action.payload.contribId].technicians,
      ];
      let additionalInfoValuesAttribute = [
        ...state.contributorsForm[action.payload.contribId].technicians[
          action.payload.techId
        ].additionalInfo,
      ];
      additionalInfoValuesAttribute[action.payload.infoIndex] = {
        ...additionalInfoValuesAttribute[action.payload.infoIndex],
        [action.payload.attribute]: action.payload.value,
      };

      techniciansValuesForMoreInfoAttribute[action.payload.techId] = {
        ...state.contributorsForm[action.payload.contribId].technicians[
          action.payload.techId
        ],
        additionalInfo: additionalInfoValuesAttribute,
      };
      return {
        ...state,
        contributorsForm: {
          ...state.contributorsForm,
          [action.payload.contribId]: {
            ...state.contributorsForm[action.payload.contribId],
            technicians: techniciansValuesForMoreInfoAttribute,
          },
        },
      };

    case HANDLE_HOURS_AMOUNT:
      let techniciansValues = [
        ...state.contributorsForm[action.payload.contribId].technicians,
      ];
      techniciansValues[action.payload.techId] = {
        ...state.contributorsForm[action.payload.contribId].technicians[
          action.payload.techId
        ],
        workedTo: '',
        workedFrom: '',
        hoursAmount: action.payload.value,
      };
      return {
        ...state,
        contributorsForm: {
          ...state.contributorsForm,
          [action.payload.contribId]: {
            ...state.contributorsForm[action.payload.contribId],
            technicians: techniciansValues,
          },
        },
      };

    case HANDLE_HOURS_AMOUNT_MORE_INFO:
      let techniciansValuesForMoreInfoHours = [
        ...state.contributorsForm[action.payload.contribId].technicians,
      ];
      let additionalInfoValues = [
        ...state.contributorsForm[action.payload.contribId].technicians[
          action.payload.techId
        ].additionalInfo,
      ];
      additionalInfoValues[action.payload.infoIndex] = {
        ...additionalInfoValues[action.payload.infoIndex],
        workedTo: '',
        workedFrom: '',
        hoursAmount: action.payload.value,
      };

      techniciansValuesForMoreInfoHours[action.payload.techId] = {
        ...state.contributorsForm[action.payload.contribId].technicians[
          action.payload.techId
        ],
        additionalInfo: additionalInfoValues,
      };
      return {
        ...state,
        contributorsForm: {
          ...state.contributorsForm,
          [action.payload.contribId]: {
            ...state.contributorsForm[action.payload.contribId],
            technicians: techniciansValuesForMoreInfoHours,
          },
        },
      };

    case HANDLE_DATE_MORE_INFO:
      let techniciansValuesForMoreInfoWorkedFrom = [
        ...state.contributorsForm[action.payload.contribId].technicians,
      ];
      let additionalInfoValuesWorkedFrom = [
        ...state.contributorsForm[action.payload.contribId].technicians[
          action.payload.techId
        ].additionalInfo,
      ];
      additionalInfoValuesWorkedFrom[action.payload.infoIndex] = {
        ...additionalInfoValuesWorkedFrom[action.payload.infoIndex],
        [action.payload.key]: action.payload.value,
        hoursAmount: '',
      };

      techniciansValuesForMoreInfoWorkedFrom[action.payload.techId] = {
        ...state.contributorsForm[action.payload.contribId].technicians[
          action.payload.techId
        ],
        additionalInfo: additionalInfoValuesWorkedFrom,
      };
      return {
        ...state,
        contributorsForm: {
          ...state.contributorsForm,
          [action.payload.contribId]: {
            ...state.contributorsForm[action.payload.contribId],
            technicians: techniciansValuesForMoreInfoWorkedFrom,
          },
        },
      };

    case HANDLE_ATTRIBUTE_CHANGE:
      return {
        ...state,
        contributorsForm: {
          ...state.contributorsForm,
          [action.payload.contribId]: {
            ...state.contributorsForm[action.payload.contribId],
            [action.payload.attribute]: action.payload.value,
          },
        },
      };

    case CLEAR_FORM:
      return {
        ...state,
        contributorsForm: {},
      };

    case SET_DAILY_REPORT_LOG_PDF:
      return {
        ...state,
        dailyReportLogPdf: action.payload,
      };

    case DELETE_DAILY_REPORT:
      return {
        ...state,
        dailyReports: state.dailyReports.filter(
          (report) => report.id !== action.payload
        ),
      };
    case DELETE_PROJECT_REPORT:
      return {
        ...state,
        fieldProjectReports: state.fieldProjectReports.map((report) => {
          if (action.payload === report.id) {
            const item = {
              ...report,
              status: 'deleted',
            };
            return item;
          } else {
            return report;
          }
        }),
      };

    case SET_REPORT_PDF:
      return {
        ...state,
        reportLogPdf: action.payload,
      };

    case SET_REPORT_DATE:
      return {
        ...state,
        reportDate: action.payload,
      };

    case SET_REPORT_EXTRA_INPUTS:
      return {
        ...state,
        reportExtraInputs: action.payload,
      };

    case SET_REPORT_EXTRA_INPUT:
      return {
        ...state,
        reportExtraInputs: {
          ...state.reportExtraInputs,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case SET_REPORT_OPEN_ITEM:
      return {
        ...state,
        reportOpenItem: action.payload,
      };

    case SET_REPORT_OPEN_ITEM_VALUE:
      return {
        ...state,
        reportOpenItem: {
          ...state.reportOpenItem,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case SET_GENERAL_CONTACTOR_NAME:
      return {
        ...state,
        generalContactor: action.payload,
      };

    case SET_SUPERVISOR_TASKS:
      return {
        ...state,
        supervisorTasks: action.payload,
      };

    case SET_MEMBERS:
      return {
        ...state,
        members: action.payload,
      };

    case SET_DELETED_MEMBERS:
      return {
        ...state,
        deletedMembers: action.payload,
      };
    case SET_GCX_IMAGES:
      return {
        ...state,
        gcImages: action.payload,
      };
    case SET_GCX_DELETED_IMAGES:
      return {
        ...state,
        gcDeletedImages: action.payload,
      };

    case SUB_DAILY_REPORTS_COUNT_SUCCESS:
      return {
        ...state,
        subDailyReportsCount: action.payload,
      };

    case SUB_DAILY_REPORTS_COUNT_INIT:
      return {
        ...state,
        subDailyReportsCount: initialState.subDailyReportsCount,
      };

    case FIELD_WORK_ORDER_FORM_INIT:
      return {
        ...state,
        fieldWorkOrderForm: action.payload,
      };

    case FIELD_WORK_ORDER_FORM_ATTRIBUTE_SET:
      return {
        ...state,
        fieldWorkOrderForm: {
          ...state.fieldWorkOrderForm,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case UPDATE_FIELD_WORK_ORDER_SUCCESS:
      return {
        ...state,
        currentFieldWorkOrder: action.payload,
        fieldWorkOrders: state.fieldWorkOrders.map((fwo) => {
          if (fwo.id === action.payload.id) {
            return action.payload;
          } else {
            return fwo;
          }
        }),
      };

    case DUPLICATE_DAILY_REPORT_SUCCESS:
      return {
        ...state,
        dailyReports: [
          action.payload,
          ...state.dailyReports.filter(
            (report) => report.id !== action.payload.id
          ),
        ],
      };

    case SET_PROJECT_REPORT_ATTR:
      return {
        ...state,
        fieldProjectReportForm: {
          ...state.fieldProjectReportForm,
          [action.payload.attr]: action.payload.value,
        },
      };

    case ADD_PROJECT_REPORT_CONTACT_INFORMATION_ITEM:
      return {
        ...state,
        fieldProjectReportForm: {
          ...state.fieldProjectReportForm,
          contract_info: [
            ...state.fieldProjectReportForm.contract_info,
            action.payload
              ? action.payload
              : {
                  isEdit: true,
                  id: 'newItem' + uuidUtil.uuidv4(),
                  description: '',
                  amount: '',
                  add_services: '',
                  paid_to_date: '',
                  start: '',
                  completion: '',
                  duration: '',
                  include_amount: false,
                  include_team: false,
                },
          ],
        },
      };

    case ADD_PROJECT_REPORT_PROJECT_STATUS_ITEM: {
      const { projectReportId, number, value: v, restore, id } = action.payload;
      const data = state.fieldProjectReportForm.statuses
        .filter(
          (item) =>
            ((projectReportId === item.project_report_id &&
              !item.previous_published_report_id) ||
              (item.isEdit &&
                number === parseInt(item.code.split('.')[0], 10))) &&
            !item._destroy
        )
        .map((item) => +item.code.split('.')[1]);

      const statusNumber = data.length > 0 ? Math.max(...data) : 0;

      let value = v;
      if (value && !restore) value.description = '';

      let newArray = [
        ...state.fieldProjectReportForm.statuses,
        v
          ? v
          : {
              isEdit: true,
              project_report_status_type_id: id,
              id: 'newItem' + uuidUtil.uuidv4(),
              date: moment().format('YYYY-MM-DD'),
              description: '',
              code: `${number}.${statusNumber < 9 ? 0 : ''}${statusNumber + 1}`,
            },
      ];
      return {
        ...state,
        fieldProjectReportForm: {
          ...state.fieldProjectReportForm,
          statuses: sortStatuses(newArray),
        },
      };
    }
    case DELETE_PROJECT_REPORT_PROJECT_STATUS:
      return {
        ...state,
        fieldProjectReportForm: {
          ...state.fieldProjectReportForm,
          statuses: sortStatuses(
            state.fieldProjectReportForm.statuses
              .filter((item) => item.id !== action.payload.id || !item.isEdit)
              .map((item) =>
                item.id === action.payload.id
                  ? { ...item, _destroy: true }
                  : item
              )
          ),
        },
      };
    case SET_PROJECT_REPORT_TABLE_MULTIPLE_ITEMS_BY_TYPE: {
      const { type, first, items } = action.payload;
      const form = { ...state.fieldProjectReportForm };

      if (type === 'budgets' && first.parent_id) {
        const pIndex = form[type].findIndex(({ id }) => id === first.parent_id);
        if (pIndex >= 0) {
          const index = form[type][pIndex].children.findIndex(
            ({ id }) => id === first.id
          );
          if (index >= 0) {
            form[type][pIndex].children.splice(index, 1, ...items);
            form[type][pIndex] = calculateParentBudget(form[type][pIndex]);
          }
        }
      } else {
        const index = form[type].findIndex(({ id }) => id === first.id);
        form[type].splice(index, 1, ...items);
      }

      return { ...state, fieldProjectReportForm: form };
    }

    case SET_PROJECT_REPORT_PROJECT_TABLE_ITEM_ATTR_BY_TYPE: {
      const { item: updateItem, type, attr, value } = action.payload;
      const fieldProjectReportForm = { ...state.fieldProjectReportForm };

      const newItem = { ...updateItem, [attr]: value };
      if (attr === 'original_cost') {
        if (!newItem['previous_cost']) newItem['previous_cost'] = value;
        if (!newItem['current_cost']) newItem['current_cost'] = value;
      }

      if (updateItem.parent_id) {
        const parentIndex = fieldProjectReportForm[type].findIndex(
          (item) => item.id === updateItem.parent_id
        );
        if (parentIndex >= 0) {
          const childIndex = fieldProjectReportForm[type][
            parentIndex
          ].children.findIndex((item) => item.id === updateItem.id);

          if (childIndex >= 0) {
            const parent = fieldProjectReportForm[type][parentIndex];
            parent.children[childIndex] = newItem;
            fieldProjectReportForm[type][parentIndex] = calculateParentBudget(
              fieldProjectReportForm[type][parentIndex]
            );
          }
        }
      } else {
        const index = fieldProjectReportForm[type].findIndex(
          (item) => item.id === updateItem.id
        );
        if (index >= 0) fieldProjectReportForm[type][index] = newItem;
      }

      return { ...state, fieldProjectReportForm };
    }
    case DELETE_PROJECT_REPORT_TABLE_ITEM_BY_TYPE: {
      const { destroy, type, item: deletedItem } = action.payload;

      const fieldProjectReportForm = { ...state.fieldProjectReportForm };
      const newItem = { ...deletedItem, _destroy: true };

      if (type === 'budgets' && deletedItem.parent_id) {
        const parentIndex = fieldProjectReportForm[type].findIndex(
          (item) => item.id === deletedItem.parent_id
        );
        if (parentIndex >= 0) {
          const parent = { ...fieldProjectReportForm[type][parentIndex] };
          const childIndex = parent.children.findIndex(
            (item) => item.id === deletedItem.id
          );
          if (childIndex >= 0) {
            if (destroy) parent.children[childIndex] = newItem;
            else parent.children.splice(childIndex, 1);
          }
          fieldProjectReportForm[type][parentIndex] = calculateParentBudget(
            parent
          );
        }
      } else {
        const index = fieldProjectReportForm[type].findIndex(
          (item) => item.id === deletedItem.id
        );

        if (index >= 0) {
          if (destroy) fieldProjectReportForm[type][index] = newItem;
          else fieldProjectReportForm[type].splice(index, 1);
        }
      }

      return { ...state, fieldProjectReportForm };
    }
    case ADD_PROJECT_REPORT_BUDGET_DESCRIPTION_BY_ID: {
      const { id, parent_id, value } = action.payload;
      const budgets = [...state.fieldProjectReportForm.budgets];
      let newItem = value;

      if (!value) {
        newItem =
          id === 1
            ? {
                id: 'newItem' + uuidUtil.uuidv4(),
                isEdit: true,
                original_cost: '',
                description: '',
                current_cost: '',
                previous_cost: '',
                cost_sf: '',
                project_report_budget_category_id: id,
                parent_id,
              }
            : {
                id: 'newItem' + uuidUtil.uuidv4(),
                isEdit: true,
                contract_amount: '',
                description: '',
                paid_to_date_amount: '',
                project_report_budget_category_id: id,
                parent_id,
              };
      }
      if (parent_id) {
        const index = budgets.findIndex((b) => b.id === parent_id);
        if (index >= 0) {
          const children = [...(budgets[index].children || []), newItem];
          budgets[index] = { ...budgets[index], children };
          if (children.length === 1) {
            if (id === 1) {
              budgets[index].original_cost = '';
              budgets[index].current_cost = '';
              budgets[index].previous_cost = '';
              budgets[index].cost_sf = '';
            } else {
              budgets[index].contract_amount = '';
              budgets[index].paid_to_date_amount = '';
            }
          }
        }
      } else {
        budgets.push(newItem);
      }

      return {
        ...state,
        fieldProjectReportForm: { ...state.fieldProjectReportForm, budgets },
      };
    }

    case SET_PROJECT_REPORT_DOCUMENTS_PHOTOS_ATTR:
      return {
        ...state,
        fieldProjectReportForm: {
          ...state.fieldProjectReportForm,
          photosDocuments: {
            ...state.fieldProjectReportForm.photosDocuments,
            [action.payload.attr]: action.payload.value,
          },
        },
      };

    case SET_PROJECT_REPORT_PDF:
      return { ...state, projectReportPdf: action.payload.pdf };

    case RESET_PROJECT_REPORT_FORM:
      return {
        ...state,
        fieldProjectReportForm: {
          date: moment(new Date()).format('YYYY-MM-DD'),
          remarks: '',
          cover_image: null,
          contract_info: [],
          statuses: [],
          budgets: [...budgetsDefaultTableOne, ...budgetsDefaultTableTwo],
          schedule_categories: [],
          directory_details_type: 'nonee',
          directory_details: '',
          number: 1,
        },
      };

    case ADD_ITEM_PROJECT_REPORT_TABLE:
      const { item, reportId } = action.payload;
      const values = {
        attach_documents: item.attach_documents,
        completion_date: item.completion_date,
        date: item.date,
        directory_details: item.directory_details,
        directory_details_type: item.directory_details_type,
        number: item.number,
        remarks: item.remarks,
        status: item.status,
        submitted_by: item.submitted_by,
      };
      const updates = reportId
        ? state.fieldProjectReports.map((elem) =>
            elem.id === reportId ? { ...elem, ...values } : elem
          )
        : [{ id: reportId, ...values }, ...state.fieldProjectReports];

      return {
        ...state,
        fieldProjectReports: updates,
      };

    case GET_PROJECT_REPORT_RESOLVED_INFO_BY_ATTR:
      return {
        ...state,
        projectReportResolvedInfo: {
          ...state.projectReportResolvedInfo,
          [action.payload.attr]: action.payload.data,
          ['all' + action.payload.attr]: action.payload.data,
        },
      };

    case PROJECT_REPORT_RESOLVED_TABLE_FILTER:
      return {
        ...state,
        projectReportResolvedInfo: {
          ...state.projectReportResolvedInfo,
          [action.payload.type]: state.projectReportResolvedInfo[
            'all' + action.payload.type
          ].filter((item) =>
            item[action.payload.attr]
              .toLowerCase()
              .includes(action.payload.search.toLowerCase())
          ),
        },
      };

    case PROJECT_REPORT_PHOTOS_AND_DOCUMENTS_FILTER:
      const start = moment(action.payload.startDate);
      const end = moment(action.payload.endDate);
      return {
        ...state,
        projectReportResolvedInfo: {
          ...state.projectReportResolvedInfo,
          photosAndDocuments: state.projectReportResolvedInfo.allphotosAndDocuments.filter(
            (item) => {
              return moment(item.date).isBetween(start, end);
            }
          ),
        },
      };

    case PROJECT_REPORT_PHOTOS_AND_DOCUMENTS_FILTER_CLEAR:
      return {
        ...state,
        projectReportResolvedInfo: {
          ...state.projectReportResolvedInfo,
          photosAndDocuments:
            state.projectReportResolvedInfo.allphotosAndDocuments,
        },
      };

    case PROJECT_REPORT_TABLES_ORDER_CHANGE: {
      const { type, result } = action.payload;
      const fieldProjectReportForm = { ...state.fieldProjectReportForm };

      if (type === 'budgets') {
        const { source, destination } = result;
        const newOrder = [...state.fieldProjectReportForm[type]];

        if (result.type === 'mainItems') {
          const item = newOrder.splice(source.index, 1);
          newOrder.splice(destination.index, 0, item[0]);
        } else if (result.type === 'subItems') {
          const parent_id = Number(source.droppableId.split('-')[2]);
          const index = newOrder.findIndex((item) => item.id === parent_id);

          const newSubOrder = [...newOrder[index].children];
          const item = newSubOrder.splice(source.index, 1);
          newSubOrder.splice(destination.index, 0, item[0]);
          newOrder[index].children = newSubOrder;
        }

        fieldProjectReportForm[type] = newOrder;
      } else {
        fieldProjectReportForm[type] = setNewOrder(
          state.fieldProjectReportForm[type],
          action
        );
      }

      return { ...state, fieldProjectReportForm };
    }
    case SIMPLIFIED_REPORT_CREATION_SUCCESS:
      return {
        ...state,
        currentDailyReport: action.payload,
        dailyReports: [action.payload, ...state.dailyReports],
      };
    case SIMPLIFIED_REPORT_UPDATE_SUCCESS:
      return {
        ...state,
        dailyReports: state.dailyReports.filter((r) =>
          r.id === action.payload.id ? action.payload : r
        ),
      };
    case SET_LAST_PROJECT_REPORT:
      return { ...state, lastProjectReport: action.payload };

    default:
      return state;
  }
}

const calculateParentBudget = (parent) => {
  let newParent = parent;

  if (parent.children.length > 0) {
    newParent = {
      ...parent,
      ...parent.children.reduce(
        (acc, c) => {
          if (c._destroy || c.resolved_at) return acc;

          let res = { ...acc };
          Object.keys(acc).forEach(
            (k) => (res[k] += isNaN(c[k]) ? 0 : Number(c[k]))
          );

          return res;
        },
        parent.project_report_budget_category_id === 1
          ? { original_cost: 0, current_cost: 0, previous_cost: 0, cost_sf: 0 }
          : { contract_amount: 0, paid_to_date_amount: 0 }
      ),
    };
  }

  return newParent;
};
