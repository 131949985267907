import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getDarkMode } from 'selectors/theme';
import './RecentActivity.css';
import NotificationsContainer from 'components/header/NotificationsContainer';

class RecentActivity extends Component {
  static propTypes = {
    title: PropTypes.string,
    recentActivity: PropTypes.shape(),
    onPageChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    title: 'Recent Activity',
    recentActivity: null,
  };

  constructor(props) {
    super(props);

    this.state = { currentPage: 1 };
  }

  componentDidMount() {
    this.handlePageChange(1);
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
    this.props.onPageChange(page);
  };

  render() {
    const { title, recentActivity, currentProject } = this.props;
    const { currentPage } = this.state;
    return (
      recentActivity && (
        <NotificationsContainer
          recentActivity={true}
          notifications={recentActivity}
          currentPage={currentPage}
          currentProject={currentProject}
          onPageChange={this.handlePageChange}
          title={title}
        />
      )
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(RecentActivity);
