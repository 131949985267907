import { showErrorMessage } from 'actions/messages';
import { directoriesApi } from 'services/api/directories-api';
import { projectContributorsApi } from 'services/api/project-contributors-api';
import { companiesApi } from 'services/api/companies-api';
import { rolesApi } from 'services/api/roles-api';
import { notificationSettingsApi } from 'services/api/notification-settings-api';
import { projectsApi } from 'services/api/projects-api';
import {
  DIRECTORY_SET,
  DIRECTORY_PDF_SET,
  SHOW_NEW_CONTRIBUTOR_MODAL_SET,
  SHOW_EDIT_USER_MODAL_SET,
  NEW_CONTRIBUTOR_MODAL_INIT,
  COMPANY_USERS_SET,
  PROJECT_CONTRIBUTORS_UPDATE,
  SHOW_NEW_COMPANY_FORM_SET,
  SHOW_SELECT_USERS_FORM_SET,
  SHOW_INVITE_USER_FORM_SET,
  SHOW_DIRECTORY_PDF_VIEWER_SET,
  CURRENT_CONTRIBUTOR_SET,
  NEW_CONTRIBUTOR_TYPE_SET,
  CONTRIBUTOR_SETTINGS_TOGGLE,
  USER_SETTINGS_TOGGLE,
  PROJECT_CONTRIBUTORS_DELETE,
  PROJECT_CONTRIBUTORS_UPDATE_WITH_EDIT_USER,
  SET_ROLES,
} from 'components/admin/projects/details/project-directory/store/action-types';
import { loadProjectRequest } from 'components/admin/projects/details/store/actions';
import {
  resetFormNotificationSettings,
  setFormNotificationSettings,
} from 'components/shared/notification-settings/store/actions';
import { dataToFormNotificationSetting } from 'services/utils/notification-setting-util';
import { SET_SHOW_NOTIFICATION_SETTINGS_MODAL } from './action-types';

export function loadDirectorySuccess(directory) {
  return {
    type: DIRECTORY_SET,
    payload: directory,
  };
}

export function loadDirectoryRequest(projectId) {
  return (dispatch) => {
    return directoriesApi.getDirectory(projectId).then((response) => {
      if (response.ok) {
        dispatch(loadDirectorySuccess(response.directory));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function loadDirectoryPdfSuccess(directoryPdf) {
  return {
    type: DIRECTORY_PDF_SET,
    payload: directoryPdf,
  };
}

export function loadDirectoryPdfRequest(projectId) {
  return (dispatch) => {
    return directoriesApi.getDirectoryPdf(projectId).then((response) => {
      if (response.ok) {
        dispatch(loadDirectoryPdfSuccess(response.directoryPdf));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function searchDirectory(projectId) {
  return (dispatch) => {
    return directoriesApi.getDirectory(projectId).then((response) => {
      if (!response.ok) {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function setShowNewContributorModal(showNewContributorModal) {
  return {
    type: SHOW_NEW_CONTRIBUTOR_MODAL_SET,
    payload: showNewContributorModal,
  };
}

export function setShowEditUserModal(showEditUserModal, user) {
  return {
    type: SHOW_EDIT_USER_MODAL_SET,
    payload: { showEditUserModal, user },
  };
}

export function initNewContributorModal() {
  return {
    type: NEW_CONTRIBUTOR_MODAL_INIT,
  };
}

export function setShowSelectUsersForm(showSelectUsersForm) {
  return {
    type: SHOW_SELECT_USERS_FORM_SET,
    payload: showSelectUsersForm,
  };
}

export function setShowInviteUserForm(showInviteUserForm) {
  return {
    type: SHOW_INVITE_USER_FORM_SET,
    payload: showInviteUserForm,
  };
}

export function setShowDirectoryPdfViewer(showDirectoryPdfViewer) {
  return {
    type: SHOW_DIRECTORY_PDF_VIEWER_SET,
    payload: showDirectoryPdfViewer,
  };
}

export function setCurrentContributor(contrib) {
  return {
    type: CURRENT_CONTRIBUTOR_SET,
    payload: contrib,
  };
}

export function fetchCompanies(type, byName, page) {
  return (dispatch) => {
    return companiesApi.getCompanies(type, byName, page).then((response) => {
      if (!response.ok) {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function loadCompanyUsersSuccess(users) {
  return {
    type: COMPANY_USERS_SET,
    payload: users,
  };
}

export function loadCompanyUsers(companyId) {
  return (dispatch) => {
    return companiesApi.getCompanyUsers(companyId, true).then((response) => {
      if (response.ok) {
        dispatch(loadCompanyUsersSuccess(response.users));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function createProjectContributor(projectId, values) {
  return (dispatch) => {
    return projectContributorsApi
      .createProjectContributor(projectId, values)
      .then((response) => {
        if (response.ok) {
          dispatch(loadDirectoryRequest(projectId));
          dispatch(loadProjectRequest(projectId));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function updateProjectContributorSuccess(projectContributor) {
  return {
    type: PROJECT_CONTRIBUTORS_UPDATE,
    payload: projectContributor,
  };
}

export function updateProjectContributor(projectId, id, values) {
  return (dispatch) => {
    return projectContributorsApi
      .updateProjectContributor(projectId, id, values)
      .then((response) => {
        if (response.ok) {
          dispatch(
            updateProjectContributorSuccess(response.projectContributor)
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setShowNewCompanyForm(showNewCompanyForm) {
  return {
    type: SHOW_NEW_COMPANY_FORM_SET,
    payload: showNewCompanyForm,
  };
}

export function createCompany(projectId, values) {
  return (dispatch) => {
    return projectsApi
      .createCompanyDirectory(projectId, values)
      .then((response) => {
        if (!response.ok) dispatch(showErrorMessage(response.errors));

        return response;
      });
  };
}

export function inviteExistentCompany(projectId, companyId) {
  return (dispatch) => {
    return projectsApi.inviteExistent(projectId, companyId).then((response) => {
      if (!response.ok) dispatch(showErrorMessage(response.errors));

      return response;
    });
  };
}

const makeFindSimilarValues = ({
  name,
  email,
  phoneNumber,
  officePhoneNumber,
  officeFaxNumber,
}) => ({
  mode: 'list',
  name: name,
  email: email,
  phone_number: phoneNumber,
  office_phone_number: officePhoneNumber,
  office_fax_number: officeFaxNumber,
});

export function findSimilarCompany(values) {
  return (dispatch) => {
    return companiesApi
      .findSimilar(makeFindSimilarValues(values))
      .then((response) => {
        if (!response.ok) dispatch(showErrorMessage(response.errors));

        return response;
      });
  };
}

const processUserAccountAdmin = (form, canSetAccountAdmin) => {
  if (!canSetAccountAdmin) return form;

  return {
    ...form,
    [form.accountAdmin ? 'assignedAccountAdmin' : 'removedAccountAdmin']: true,
  };
};

export function editUser(form, canSetAccountAdmin) {
  form = processUserAccountAdmin(form, canSetAccountAdmin);
  return (dispatch) => {
    return companiesApi.editUser(form).then((response) => {
      if (!response.ok) {
        dispatch(showErrorMessage(response.errors));
      } else {
        dispatch(setShowEditUserModal(false));
        dispatch(updateContributersWithEditUser(response.updatedUser));
      }

      return response;
    });
  };
}

export function updateContributersWithEditUser(user) {
  return {
    type: PROJECT_CONTRIBUTORS_UPDATE_WITH_EDIT_USER,
    payload: user,
  };
}

export function setNewContributorType(type) {
  return {
    type: NEW_CONTRIBUTOR_TYPE_SET,
    payload: type,
  };
}

export function toggleContributorSettings(id) {
  return {
    type: CONTRIBUTOR_SETTINGS_TOGGLE,
    payload: id,
  };
}

export function toggleUserSettings(id) {
  return {
    type: USER_SETTINGS_TOGGLE,
    payload: id,
  };
}

export function projectContributorDeleteSuccess(id) {
  return {
    type: PROJECT_CONTRIBUTORS_DELETE,
    payload: id,
  };
}

export function deleteProjectContributor(projectId, id) {
  return (dispatch) => {
    return projectContributorsApi
      .destroyProjectContributor(projectId, id)
      .then((response) => {
        if (response.ok) {
          dispatch(projectContributorDeleteSuccess(id));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function inviteUser(companyId, form, canSetAccountAdmin) {
  form = processUserAccountAdmin(form, canSetAccountAdmin);
  return (dispatch) => {
    return companiesApi.inviteUser(companyId, form).then((response) => {
      if (!response.ok) {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function sendInvite(user) {
  return (dispatch) => {
    return companiesApi.sendInvite(user).then((response) => {
      if (!response.ok) {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function activationLink(user) {
  return (dispatch) => {
    return companiesApi.activationLink(user).then((response) => {
      if (!response.ok) {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function loadRolesRequest() {
  return (dispatch) => {
    return rolesApi.getRoles('Company').then((response) => {
      if (response.ok) {
        dispatch(loadRolesSuccess(response.permissions));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadRolesSuccess(roles) {
  return {
    type: SET_ROLES,
    payload: roles,
  };
}

export function setShowNotificationSettingsModal(show) {
  return { type: SET_SHOW_NOTIFICATION_SETTINGS_MODAL, payload: show };
}

export function loadUserNotificationSetting(projectId, userId) {
  return (dispatch) => {
    return notificationSettingsApi
      .getForProjectContributorUser(projectId, userId)
      .then((response) => {
        if (response.ok) {
          dispatch(resetFormNotificationSettings());
          const form = dataToFormNotificationSetting(response.data);
          dispatch(setFormNotificationSettings(form));
          dispatch(setShowNotificationSettingsModal(true));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
  };
}
