import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ProjectDirectory.css';
import InactiveUserIcon from '@mui/icons-material/ReportProblem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SimpleTable from 'components/shared/table/SimpleTable';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import Menu, { MENU_SELECTOR } from 'components/shared/menu/Menu';
import PdfViewer from 'components/shared/pdf-viewer/PdfViewer';
import MessageModal from 'components/shared/modal/MessageModal';
import { formatPhoneNumber } from 'services/utils/text-util';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';
import { getShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/selectors';
import {
  getDirectory,
  getDirectoryPdf,
  getCompanyUsers,
  getContributorSettings,
  getUserSettings,
  getShowNewContributorModal,
  getShowEditUserModal,
  getShowSelectUsersForm,
  getShowInviteUserForm,
  getShowDirectoryPdfViewer,
  getCurrentContributor,
  getEditedUser,
  getRoles,
  getShowNotificationSettingsModal,
} from 'components/admin/projects/details/project-directory/store/selectors';
import {
  loadDirectoryRequest,
  loadDirectoryPdfRequest,
  loadCompanyUsers,
  setShowNewContributorModal,
  setShowEditUserModal,
  setShowSelectUsersForm,
  setShowInviteUserForm,
  setShowDirectoryPdfViewer,
  setCurrentContributor,
  updateProjectContributor,
  toggleContributorSettings,
  toggleUserSettings,
  deleteProjectContributor,
  inviteUser,
  editUser,
  sendInvite,
  activationLink,
  loadRolesRequest,
  loadUserNotificationSetting,
  setShowNotificationSettingsModal,
} from 'components/admin/projects/details/project-directory/store/actions';
import { sendProjectResourceEmails } from 'components/admin/projects/details/store/actions';
import NewContributorModal from 'components/admin/projects/details/project-directory/NewContributorModal';
import SelectUsersModal from 'components/admin/projects/details/project-directory/SelectUsersModal';
import UserModal from 'components/admin/projects/details/project-directory/UserModal';
import { getCurrentUser } from 'selectors/authentication';
import ProjectEmailModal from 'components/admin/projects/details/project-email-modal/ProjectEmailModal';
import { usersApi } from 'services/api/users-api';
import { getDarkMode } from 'selectors/theme';
import User from 'domain/user';
import Project from 'domain/project';
import DirectoryPermissions from 'permissions/directory-permissions';
import NotificationSettingModal from './NotificationSettingModal';

class ProjectDirectory extends Component {
  constructor(props) {
    super(props);

    const { currentUser, currentProject } = props;
    this.canAddContributor = DirectoryPermissions.canAddContributor(
      currentUser,
      currentProject
    );
    this.canSendEmail = DirectoryPermissions.canSendEmail(
      currentUser,
      currentProject
    );
    this.canViewLog = DirectoryPermissions.canViewLog(
      currentUser,
      currentProject
    );
    this.canEditUser = DirectoryPermissions.canEditUsers(
      currentUser,
      currentProject
    );
    this.canSendInvite = DirectoryPermissions.canSendUsersInvite(
      currentUser,
      currentProject
    );
    this.canGetActivationLink = DirectoryPermissions.canGetUsersActivationLink(
      currentUser,
      currentProject
    );
  }

  componentDidMount() {
    if (this.props.currentProject) {
      this.props.dispatch(loadDirectoryRequest(this.props.currentProject.id));
    }
    if (!this.props.roles) {
      this.props.dispatch(loadRolesRequest());
    }
  }

  projectContributorsByPrimary = (primary) =>
    this.props.directory.project_contributors.filter(
      (contrib) => contrib.primary === primary
    );

  pendingInvite = (user) => {
    return !!user.invitation_sent_at && !user.invitation_accepted_at;
  };

  renderUserActions = (user, contributor) => {
    const pendingInvite = this.pendingInvite(user);
    const canEditNotificationSettings = DirectoryPermissions.canEditContributorNotifications(
      this.props.currentUser,
      this.props.currentProject,
      contributor
    );

    const items = [
      this.canEditUser && {
        content: 'Edit User',
        onClick: () => this.toggleUserEditMenu(user),
      },
      canEditNotificationSettings && {
        content: 'Notifications',
        onClick: () => this.handleEditNotificationSettings(user),
      },
      pendingInvite &&
        this.canSendInvite && {
          content: 'Resend Invite',
          onClick: () => this.handleSendInvite(user),
        },
      pendingInvite &&
        this.canGetActivationLink && {
          content: 'Activation Link',
          onClick: () => this.handleGetActivationLink(user),
        },
    ].filter((item) => item);

    return (
      <div className="action-buttons">
        {items.length > 0 && (
          <Menu selector={MENU_SELECTOR.MORE_OPTIONS} items={items} />
        )}
      </div>
    );
  };

  renderFullName = (user) => (
    <>
      {User.fullName(user)}
      {user.status === 'inactive' && (
        <Tooltip title="User is inactive">
          <IconButton className="inactive-user-icon">
            <InactiveUserIcon />
          </IconButton>
        </Tooltip>
      )}
    </>
  );

  mapContributorUsers = (users, contributor) => {
    return users.map((user) => {
      return [
        this.renderFullName(user),
        user.title || 'N/A',
        User.companyRoleFullLabel(user),
        user.email,
        `${formatPhoneNumber(user.phone_number)}${
          user.phone_ext ? ` ext ${user.phone_ext}` : ''
        }`,
        this.renderUserActions(user, contributor),
      ];
    });
  };

  handleResendInvite = (id) => {
    usersApi.resendInvite(id);
  };

  handleContributorSettingsClick = (id) => {
    this.props.dispatch(toggleContributorSettings(id));
  };

  handleUserSettingClick = (id) => {
    this.props.dispatch(toggleUserSettings(id));
  };

  toggleUserEditMenu = (user) => {
    this.props.dispatch(setShowEditUserModal(true, user));
  };

  handleUpdateProjectContributor = (id, values) => {
    this.props.dispatch(
      updateProjectContributor(this.props.currentProject.id, id, values)
    );
  };

  handleContributorSelectUsersClick = (contrib) => {
    this.props
      .dispatch(loadCompanyUsers(contrib.company_id))
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(setCurrentContributor(contrib));
          this.props.dispatch(setShowSelectUsersForm(true));
        }
      });
  };

  handleContributorInviteUserClick = (contrib) => {
    this.props.dispatch(setCurrentContributor(contrib));
    this.props.dispatch(setShowInviteUserForm(true));
  };

  handleSelectUsers = (values) => {
    const { currentProject, currentContributor, dispatch } = this.props;
    dispatch(
      updateProjectContributor(currentProject.id, currentContributor.id, values)
    ).then((response) => {
      if (response.ok) {
        this.handleSelectUsersClose();
      }
    });
  };

  handleInviteUser = (values) => {
    const {
      currentUser,
      currentProject,
      currentContributor,
      dispatch,
    } = this.props;

    const canSetAccountAdmin = DirectoryPermissions.canSetAccountAdmin(
      currentUser
    );

    const { dropdownTitle, customTitle } = values;
    const title = dropdownTitle === 'Other' ? customTitle : dropdownTitle;
    const submitValues = {
      ...values,
      title,
    };

    dispatch(
      inviteUser(
        currentContributor.company_id,
        submitValues,
        canSetAccountAdmin
      )
    ).then((response) => {
      if (response.ok) {
        const userIds = currentContributor.users
          .map((usr) => usr.id)
          .concat(response.user.id);
        dispatch(
          updateProjectContributor(currentProject.id, currentContributor.id, {
            userIds: userIds,
          })
        ).then((response) => {
          if (response.ok) {
            this.handleInviteUserClose();
          }
        });
      }
    });
  };

  handleSendInvite = (user) => {
    this.props.dispatch(sendInvite(user)).then((response) => {
      if (response.ok) {
        this.setState({ inviteSentTo: user.email });
      }
    });
  };

  hideSendInviteSuccess = () => {
    this.setState({ inviteSentTo: '' });
  };

  renderSendInviteSuccess = () => {
    const inviteSentTo = !!this.state && this.state.inviteSentTo;
    return (
      !!inviteSentTo && (
        <MessageModal
          show={!!inviteSentTo}
          message={
            <div>
              Invitation successfully sent to
              <br />
              {inviteSentTo}
            </div>
          }
          onHide={this.hideSendInviteSuccess}
        />
      )
    );
  };

  handleGetActivationLink = (user) => {
    this.props.dispatch(activationLink(user)).then((response) => {
      if (response.ok) {
        this.setState({
          activationUser: `${user.first_name} ${user.last_name}`,
          activationLink: response.instructions.accept_invitation_url,
        });
      }
    });
  };

  hideActivationLinkSuccess = () => {
    this.setState({ activationUser: '', activationLink: '' });
  };

  renderActivationLinkSuccess = () => {
    const activationUser = !!this.state && this.state.activationUser;
    const activationLink = !!this.state && this.state.activationLink;
    return (
      !!activationUser &&
      !!activationLink && (
        <MessageModal
          show={!!activationUser && !!activationLink}
          message={
            <div className="activation-link">
              Activation Link for {activationUser}:<br />
              {activationLink}
            </div>
          }
          onHide={this.hideActivationLinkSuccess}
        />
      )
    );
  };

  handleSelectUsersClose = () => {
    this.props.dispatch(setShowSelectUsersForm(false));
  };

  handleInviteUserClose = () => {
    this.props.dispatch(setShowInviteUserForm(false));
  };

  handleNotificationSettingsClose = () => {
    this.props.dispatch(setShowNotificationSettingsModal(false));
  };

  handlePdfViewerClose = () => {
    this.props.dispatch(setShowDirectoryPdfViewer(false));
  };

  handleContributorDeleteClick = (id) => {
    this.props.dispatch(
      deleteProjectContributor(this.props.currentProject.id, id)
    );
  };

  handleEditNotificationSettings = (user) => {
    this.props.dispatch(
      loadUserNotificationSetting(this.props.currentProject.id, user.id)
    );
  };

  handleAddContributor = () => {
    this.props.dispatch(setShowNewContributorModal(true));
  };

  handleViewPdf = () => {
    this.props
      .dispatch(loadDirectoryPdfRequest(this.props.currentProject.id))
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(setShowDirectoryPdfViewer(true));
        }
      });
  };

  handleShowEmailModal = () => {
    this.props.dispatch(setShowEmailModal(true));
  };

  handleSendEmail = (emailsData) => {
    this.props.dispatch(
      sendProjectResourceEmails(
        this.props.directory.id,
        'Directory',
        emailsData
      )
    );
  };

  buildEmailModalSubject = (project) => {
    return `${project.number} ${project.name} - Directory`;
  };

  buildEmailModalMessage = (project) => {
    let location = '';
    if (project.project_location && project.project_location.location) {
      const projectLocation = project.project_location.location;
      location = ` at ${projectLocation.street_address_1}, ${projectLocation.city} ${projectLocation.state}`;
    }

    return `Please find the attached Directory for Project ${project.name}${location}. If you have any questions, please reach out via chat or email.`;
  };

  handleCloseEditUserModal = () => {
    this.props.dispatch(setShowEditUserModal(false));
  };

  handleEditUserOnSubmit = (values) => {
    const { currentUser, dispatch } = this.props;
    const canSetAccountAdmin = DirectoryPermissions.canSetAccountAdmin(
      currentUser
    );

    const { dropdownTitle, customTitle } = values;
    const title = dropdownTitle === 'Other' ? customTitle : dropdownTitle;
    const submitValues = {
      ...values,
      title,
    };

    dispatch(editUser(submitValues, canSetAccountAdmin));
  };

  getInitialValuesForEditedUser = (user) => {
    return {
      id: user.id,
      type: user.accountable_type,
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      phoneNumber: formatPhoneNumber(user.phone_number),
      phoneExt: user.phone_ext,
      title: user.title,
      assignedRole: User.companyRoleName(user),
      accountAdmin: User.isAccountAdmin(user),
      accountableId: user.accountable_id,
    };
  };

  renderHeaderButtons() {
    return [
      this.canAddContributor && (
        <Button
          key="add-contributor"
          type={BUTTON_TYPES.LINK}
          icon={BUTTON_ICONS.PLUS}
          label="Add Contributor"
          onClick={this.handleAddContributor}
        />
      ),
      this.canSendEmail && (
        <Button
          key="email"
          type={BUTTON_TYPES.LINK}
          color={BUTTON_COLORS.GREEN}
          label="Email"
          onClick={this.handleShowEmailModal}
        />
      ),
      this.canViewLog && (
        <Button
          key="view-log"
          type={BUTTON_TYPES.LINK}
          color={BUTTON_COLORS.GREEN}
          label="View Log"
          onClick={this.handleViewPdf}
        />
      ),
    ].filter((component) => component);
  }

  renderContributorActions(contributor) {
    const { currentUser, currentProject } = this.props;

    const items = [
      DirectoryPermissions.canSelectUsers(
        currentUser,
        currentProject,
        contributor
      ) && {
        content: 'Select Users',
        onClick: () => this.handleContributorSelectUsersClick(contributor),
      },
      DirectoryPermissions.canInviteUsers(
        currentUser,
        currentProject,
        contributor
      ) && {
        content: 'Invite User',
        onClick: () => this.handleContributorInviteUserClick(contributor),
      },
      DirectoryPermissions.canDeleteUser(currentUser, currentProject) && {
        content: 'Delete',
        onClick: () => this.handleContributorDeleteClick(contributor.id),
      },
    ].filter((item) => item);
    return (
      items.length && (
        <Menu
          selector={MENU_SELECTOR.MORE_OPTIONS_VERTICAL}
          darkModeSelector={true}
          items={items}
        />
      )
    );
  }

  renderContributor(contributor, index) {
    const { users } = contributor;

    return (
      <div key={index} className="project-directory-contributor">
        <div className="project-directory-contributor-header">
          <h5 className="project-directory-contributor-title">
            {Project.contributorLabel(contributor)}
          </h5>
          {this.renderContributorActions(contributor)}
        </div>
        <div className="project-directory-contributor-table">
          <SimpleTable
            headers={[
              'NAME',
              'TITLE',
              'ROLE',
              'EMAIL',
              'PHONE NUMBER',
              'ACTIONS',
            ]}
            rows={this.mapContributorUsers(users, contributor)}
            emptyMessage="No Users"
          />
        </div>
      </div>
    );
  }

  //this should be a component but it's going to be replaced by new architecture
  renderContributorsGroup(primary) {
    const contributors = this.projectContributorsByPrimary(primary);

    return (
      <div className="project-directory-group">
        <div className="project-directory-group-header">
          <h3 className="project-directory-group-title">
            {primary ? 'Primary' : 'Subcontractors, Vendors'}
          </h3>
        </div>
        <div className="project-directory-contributors">
          {contributors.length > 0 ? (
            contributors.map((contributor, index) =>
              this.renderContributor(contributor, index)
            )
          ) : (
            <SimpleTable rows={[]} emptyMessage="No Contributors Yet" />
          )}
        </div>
      </div>
    );
  }

  render() {
    const { currentUser, currentProject, renderHeaders, darkMode } = this.props;
    return (
      currentProject && (
        <div>
          {this.props.directory && (
            <div
              className={`content-container project-directory ${
                darkMode ? 'dark-mode' : ''
              }`}
            >
              {renderHeaders(this.renderHeaderButtons())}
              {this.renderContributorsGroup(true)}
              {this.renderContributorsGroup(false)}

              {this.props.showNewContributorModal && <NewContributorModal />}

              {this.props.showEditUserModal && (
                <UserModal
                  show={this.props.showEditUserModal}
                  handleClose={this.handleCloseEditUserModal}
                  handleSubmit={this.handleEditUserOnSubmit}
                  roles={this.props.roles}
                  initialValues={this.getInitialValuesForEditedUser(
                    this.props.editedUser
                  )}
                  canSetAccountAdmin={DirectoryPermissions.canSetAccountAdmin(
                    currentUser
                  )}
                />
              )}

              {this.props.showSelectUsersForm && (
                <SelectUsersModal
                  show={this.props.showSelectUsersForm}
                  users={this.props.companyUsers}
                  selectedUsers={this.props.currentContributor.users}
                  handleSubmit={this.handleSelectUsers}
                  handleClose={this.handleSelectUsersClose}
                />
              )}

              {this.props.showInviteUserForm && (
                <UserModal
                  show={this.props.showInviteUserForm}
                  roles={this.props.roles}
                  handleSubmit={this.handleInviteUser}
                  handleClose={this.handleInviteUserClose}
                  canSetAccountAdmin={DirectoryPermissions.canSetAccountAdmin(
                    currentUser
                  )}
                />
              )}

              {this.props.showNotificationSettingsModal && (
                <NotificationSettingModal
                  show={this.props.showNotificationSettingsModal}
                  onClose={this.handleNotificationSettingsClose}
                />
              )}

              {this.props.showDirectoryPdfViewer && (
                <PdfViewer
                  content={{
                    source: this.props.directoryPdf,
                    type: 'blob',
                  }}
                  show={this.props.showDirectoryPdfViewer}
                  onClose={this.handlePdfViewerClose}
                />
              )}

              {this.props.showEmailModal && (
                <ProjectEmailModal
                  subTitle={this.props.currentProject.name}
                  handleSendEmail={(emailsData) =>
                    this.handleSendEmail(emailsData)
                  }
                  projectId={this.props.currentProject.id}
                  initialSubject={this.buildEmailModalSubject(
                    this.props.currentProject
                  )}
                  initialMessage={this.buildEmailModalMessage(
                    this.props.currentProject
                  )}
                />
              )}

              {this.renderSendInviteSuccess()}
              {this.renderActivationLinkSuccess()}
            </div>
          )}
        </div>
      )
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    directory: getDirectory(state),
    directoryPdf: getDirectoryPdf(state),
    companyUsers: getCompanyUsers(state),
    contributorSettings: getContributorSettings(state),
    userSettings: getUserSettings(state),
    editedUser: getEditedUser(state),
    showNewContributorModal: getShowNewContributorModal(state),
    showEditUserModal: getShowEditUserModal(state),
    showSelectUsersForm: getShowSelectUsersForm(state),
    showInviteUserForm: getShowInviteUserForm(state),
    showNotificationSettingsModal: getShowNotificationSettingsModal(state),
    showDirectoryPdfViewer: getShowDirectoryPdfViewer(state),
    currentContributor: getCurrentContributor(state),
    currentUser: getCurrentUser(state),
    showEmailModal: getShowEmailModal(state),
    roles: getRoles(state),
    darkMode: getDarkMode(state),
  };
})(ProjectDirectory);
