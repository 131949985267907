const css = `
<style>

@font-face {
    font-family: 'Titillium Web';
    src: local('Titillium Web Regular'), local('TitilliumWeb-Regular'),
      url('<%= asset_data_uri("Titillium_Web/TitilliumWeb-Regular.otf") %>');
    font-style: normal;
    font-weight: 400;
  }
  
  @font-face {
    font-family: 'Titillium Web';
    src: url('<%= asset_data_uri("Titillium_Web/TitilliumWeb-SemiBold.otf") %>');
    font-weight: 600;
  }
  
  @font-face {
    font-family: 'Titillium Web';
    src: url('<%= asset_data_uri("Titillium_Web/TitilliumWeb-Italic.otf") %>');
    font-weight: 400;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Titillium Web';
    src: url('<%= asset_data_uri("Titillium_Web/TitilliumWeb-SemiBoldItalic.otf") %>');
    font-weight: 600;
    font-style: italic;
  }
  
  .avoid-break {
    page-break-inside: avoid !important;
  }
  
  body {
    font-family: 'Titillium Web', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3c4249;
    text-align: left;
    font-size: 12px;
    margin: 0;
    padding: 0;
    

  }
  
  .col {
    -webkit-box-flex: 1;
  }
  
  .flexrow {
    display: -webkit-box;
    -webkit-box-pack: flex-start;
  
    &.justify{
      -webkit-box-pack: flex-justify;
    }
  
    &.start{
      -webkit-box-align: flex-start;
    }
  }
  
  .vertical-justify {
    color: blue;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-pack: justify;
  }
  
  .header {
    color: white;
    font-size: 23px;
    background-color: #191b24;
    text-align: left;
    padding: 5px 28px;
    font-weight: 600;
  }
  
  .subheader {
    font-size: 23px;
    background-color: #f3f3f3;
    text-align: left;
    padding: 5px 28px;
    font-weight: 100;
  }
  
  .sub-header {
    font-style: italic;
    font-size: 24px;
    padding-left: 26px;
    margin-top: 16px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      background-color: #eaeaec;
    }
    tr:nth-child(even) {
      background-color: #f3f3f3;
    }
  }
  
  .empty {
    display: -webkit-box;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    min-height: 200px;
    font-size: 21px;
  }
  
  .remarks {
    padding: 10px 26px 0px 26px;
    font-size: 21px;
  }
  
  p.small-memo {
    background-color: #eaeaec;
    font-style: italic;
    font-weight: 600;
    font-size: 18px;
    padding: 0px 28px;
    margin: 0px;
    width: 100%;
  }
  
  .detail-item {
    margin-right: 10px;
    margin-left: 5px;

    .label {
      font-size: 20px;
      font-weight: 600;
    }
    .value {
      font-size: 20px;
  
      span {
        display: block;
      }
    }
  
    &.small{
      .label {
        font-size: 18px;
      }
      .value {
        font-size: 18px;
      }
    }
  
    &.flexrow{
      .label{
        margin-right: 12px;
      }
    }
  
    &.inline{
      .value, .label{
        display: inline;
      }
  
      .label{
        margin-right: 12px;
      }
    }
  }
  
  .detail-remarks {
    margin-left: 30px;
    font-size: 20px;
    width: 100%;
  }
  
  .first-page {
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  
  .project-report-header {
    background: #191b24;
    color: white;
    display: -webkit-box;
    -webkit-box-pack: flex-start;
    -webkit-box-align: center;
  }
  
  .project-report-header .first {
    padding: 12px 28px;
    -webkit-box-flex: 1;
  }
  
  .project-report-header .first h2 {
    margin: 0px;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .project-report-header .first h3 {
    margin: 0px;
    font-size: 20px;
    font-weight: normal;
    text-transform: uppercase;
  }
  
  .project-report-header .second {
    padding: 0px 28px;
    -webkit-box-flex: 1;
  }
  
  .project-report-header .second h2 {
    text-align: right;
    font-size: 22px;
    font-weight: normal;
  }
  
  .project-report-header .second h2 span {
    margin-left: 14px;
    color: #d4fb00 !important;
  }
  
  
  
  .project-report-footer {
    .page-number {
      text-align: right;
      font-size: 15px;
      padding-right: 32px;
    }
  
    &.field-reports{
      display: -webkit-box;
      -webkit-box-pack: justify;
      -webkit-box-align: start;
      padding-left: 24px;
  
      .page-number {
        margin-top: 6px;
      }
  
      .company-info{
        margin-top: -2px;
        font-size: 14px;
        line-height: 19px;
        width: 70%;
  
        .name{
          display: block;
          font-weight: 600;
          width: 100%;
        }
  
        .address{
          width: 100%;
        }
      }
  
    }
  }
  
  .project-report-title {
    .col {
      min-height: 310px;
      &.no-height{
        min-height: 0;
      }
    }
  
    .col:first-child {
      width: 48%;
      padding-left: 28px;
      margin-top: 18px;
  
      p {
        margin-bottom: 28px;
        font-size: 30px;
        line-height: 36px;
        font-weight: normal;
        min-height: 72px;
  
        &.title{
          margin-bottom: 18px;
          min-height: 0;
        }
      }
      .title-details {
        margin: 0px;
        .label {
          margin: 0;
          width: 25;
          font-size: 19px;
          min-width: 130px;
          font-weight: 600;
        }
        .value {
          font-size: 21px;
          width: 75%;
  
          span {
            display: block;
          }
        }
      }
      .title-details:nth-child(2) {
        margin-bottom: 46px;
      }
    }
  
    .col:nth-child(2) {
      width: 52%;
      position: relative;
      img {
        width: 100%;
        height: 310px;
      }
    }
  
    .col.no-padding{
      padding-left:0;
    }
    .col.margin-top{
      margin-top: 18px;
    }
  
    .col.col-20{
      width: 18%;
    }
  
    .users {
      width: 100%;
      min-height: 110px;
      padding: 0px 28px;
      .title-details {
        margin: 0px;
        .label {
          margin: 0;
          width: 25;
          font-size: 19px;
          min-width: 130px;
          font-weight: 600;
        }
        .value {
          font-size: 21px;
          width: 75%;
        }
      }
    }
  
    .images-container {
      width: 100%;
      margin-bottom: 24px;
      white-space: nowrap;
      padding: 0px 28px;
      margin-top: 24px;
      min-height: 160px;
      .images-container-row {
        width: 100%;
        padding: 0px;
        img {
          width: auto;
          height: 160px;
          padding: 5px;
          &.landscape {
            width: 266px;
          }
          &.portrait {
            width: 127px;
          }
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
  
  .project-report-summary {
    min-height: 260px;
    .content {
      padding: 22px 24px 0px;
      .col {
        padding: 0px;
        width: 48%;
      }
      .col:nth-child(2) {
        width: 35%;
        margin-left: 28px;
        position: relative;
        .completion-date {
          position: absolute;
          display: inline-block;
          top: 180px;
          left: 30px;
          > span {
            font-size: 18px;
            font-weight: 600;
            display: block;
            text-align: right;
          }
        }
        .graph {
          img {
            padding-top: 45px;
          }
        }
      }
  
      p {
        font-size: 21px;
      }
  
      .label {
        font-size: 21px;
        display: inline-block;
        width: 100%;
        font-weight: 600;
      }
      .value {
        display: block;
        background-color: #d4fb00;
        padding: 20px 28px;
        font-size: 31px;
        font-weight: 600;
      }
      .row-current-costs {
        margin-top: 20px;
        .paid-to-date {
          width: 60%;
        }
        .current-sf {
          width: 40%;
          .label, .value {
            margin-left: 15px;
          }
        }
      }
    }
  }
  
  .project-report-directory {
    margin-bottom: 24px;
    .content-header {
      .header {
        margin-top: 3px;
        -webkit-box-flex: 1;
      }
      .header:first-child {
        margin-right: 3px;
      }
      .header:nth-child(2) {
        margin-left: 3px;
      }
    }
  
    .content {
      min-height: 225px;
      font-size: 13px;
      .col {
        width: 50%;
        min-height: 100px;
      }
      .col:first-child {
        padding-right: 3px;
      }
      .col:nth-child(2) {
        padding-left: 3px;
      }
      table {
        thead {
          th {
            width: 50%;
            div {
              background-color: #eaeaec;
              font-weight: 600;
              font-style: italic;
              font-size: 18px;
              padding: 4px 0px;
              margin-bottom: 20px;
            }
            &:first-child {
              div {
                padding-left: 26px;
              }
            }
          }
        }
        td {
          width: 50%;
          background-color: #FFFFFF;
          font-size: 21px;
          padding: 2px 0px;
          &:first-child {
            padding-left: 26px;
          }
        }
      }
      .sub-header {
        background-color: #eaeaec;
        margin-bottom: 20px;
        font-size: 18px;
        font-style: italic;
        font-weight: 600;
        padding: 4px 26px;
      }
      ul {
        font-size: 21px;
        list-style-type: none;
        margin: 0px;
  
        li {
          margin: 0px;
          &:before {
            content: '\x15AA';
            font-size: 12px;
            margin-top: -4px;
            margin-right: 12px;
          }
        }
      }
    }
  }
  
  .weather-report{
    margin-top: 36px;
  
    .content {
      padding: 22px 24px;
    }
  }
  .weather-report-remarks{
    margin-left: -20px;
    font-size: 20px;
    width: 100%;
  }
  .observation-report{
    .content {
      padding: 22px 24px 32px;
  
      .space{
        height: 27px;
      }
  
      .detail-item{
        margin-top: 9px;
      }
  
      span{
        .detail-item{
          display: inline;
          margin-right: 36px;
  
          &:last-child{
            margin-right: 0;
          }
          
          .label,.value{
            display: inline;
          }
        }
      }
  
      .flexrow .detail-item:first-child{
        margin-right: 36px;
      }
    }
  }
  
  .project-open-items{
    .sub-header {
      font-style: italic;
      font-size: 24px;
      padding-left: 26px;
      margin-top: 16px;
      margin-bottom: 10px;
    }
    margin-left: 20px;
    table {
      table-layout: fixed;
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      margin-top: 1px;  
  
      thead {
        th {
          font-weight: 600;
          font-style: italic;
          font-size: 20px;
  
          &:first-child {
            padding-left: 26px;
            width: 12%;
          }
          &:nth-child(4){
            width: 14%;
          }
          &:last-child {
            padding-right: 26px;
            width: 8%;
          }
        }
      }
  
      td {
        font-size: 20px;
        &:first-child {
          padding-left: 26px;
          width: 12%;
  
        }
        &:nth-child(4){
          width: 14%;
        }
        &:last-child {
          padding-right: 26px;
          width: 8%;
        }
      }
    }
  }
  
  .right-cell {
    float: right;
  }
  
  .project-report-contract {
    padding-top: 3px;
    padding-bottom: 24px;
  
    min-height: 125px;
    table {
      table-layout: fixed;
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      margin-top: 1px;
  
      th {
        font-weight: 600;
        font-style: italic;
        font-size: 18px;
        padding: 4px 0px;
        width: 12%;
  
        &:first-child {
          padding-left: 26px;
          width: 41%;
        }
        &:nth-child(2), 
        &:nth-child(3) {
          width: 16%;
        }
        .header:nth-child(2) {
          margin-left: 3px;
        }
  
        &:nth-child(6) {
          width: 18%;
        }
        &:last-child {
          padding-right: 5%;
          width: 17%;
        }
      }
  
      td {
        font-size: 21px;
        padding: 1px 0px;
  
        &:first-child {
          padding-left: 26px;
        }
        &:last-child {
          padding-right: 5%;
        }
      }
    }
  
    .right-cell {
      white-space: nowrap; 
    }
  }
  
  .project-report-status {
    padding-top: 3px;
    padding-bottom: 24px;
  
    .sub-header {
      font-style: italic;
      font-size: 26px;
      padding-left: 26px;
      margin-top: 16px;
    }
    .header + * {
      margin-top: 0px;
    }
    table {
      table-layout: fixed;
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      margin-top: 1px;
  
      thead {
        th {
          font-weight: 600;
          font-style: italic;
          font-size: 18px;
          padding: 4px 0px;
  
          &:first-child {
            padding-left: 26px;
            width: 20%;
          }
          &:last-child {
            padding-right: 5%;
            width: 80%;
          }
        }
      }
  
      td {
        font-size: 21px;
        padding: 1px 0px;
  
        &:first-child {
          padding-left: 26px;
        }
        &:last-child {
          padding-right: 5%;
        }
      }
    }
  }
  
  .project-report-schedule {
    padding-top: 3px;
    padding-bottom: 24px;
  
    .header {
      margin-top: 0px;
    }
  
    .sub-header {
      font-style: italic;
      font-size: 26px;
      padding-left: 26px;
  
      &:table{
        margin-top: 0px;
      }
  
      &.attached{
        background-color: #eaeaec;
        margin-top: 16px;
        margin-bottom: 20px;
        font-size: 18px;
        font-style: italic;
        font-weight: 600;
        padding: 4px 26px;
  
      }
    }
  
    .header + * {
      margin-top: 0px;
    }
    table {
      table-layout: fixed;
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      margin-top: 1px;
      thead {
        th {
          font-weight: 600;
          font-style: italic;
          font-size: 18px;
          padding: 4px 0px;
          width: 13%;
          &:first-child {
            padding-left: 26px;
          }
          &:last-child {
            padding-right: 5%;
          }
        }
      }
      td {
        font-size: 21px;
        padding: 1px 0px;
        &:first-child {
          padding-left: 26px;
        }
        &:last-child {
          padding-right: 5%;
        }
      }
    }
   
    ul {
      font-size: 21px;
      list-style-type: none;
      margin: 0px;
  
      li {
        margin: 0px;
        &:before {
          content: '\x15AA';
          font-size: 12px;
          margin-top: -4px;
          margin-right: 12px;
        }
      }
    }
  }
  
  .project-report-budget {
    padding-top: 3px;
    padding-bottom: 24px;
  
    .header {
      margin-top: 0px;
    }
  
    .sub-header {
      font-style: italic;
      font-size: 26px;
      padding-left: 26px;
      margin-top: 16px;
    }
    .header + * {
      margin-top: 0px;
    }
    table {
      table-layout: fixed;
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      margin-top: 1px;
  
      thead {
        th {
          font-weight: 600;
          font-style: italic;
          font-size: 18px;
          padding: 4px 0px;
          width: 16%;
  
          &:first-child {
            padding-left: 26px;
            width: 25%;
          }
          &:last-child {
            padding-right: 3%;
            width: 17%;
          }
        }
      }
      
      td {
        font-size: 21px;
        padding: 1px 0px;
  
        &:first-child {
          padding-left: 26px;
        }
        &:last-child {
          padding-right: 3%;
        }
      }
  
      tr.child {
        td {
          &:first-child {
            padding-left: 52px;
          }
        }
      }
  
      tr:last-child {
        font-weight: 600;
      }
  
      th:not(:first-child),
      td:not(:first-child) {
        text-align: right;
      }
    }
  }
  
  .project-report-photos {
    padding-top: 3px;
    padding-bottom: 24px;
  }
  
  .project-report-photos .header {
    margin-top: 0px;
  }
  
  .project-report-photos .container {
    display: flex;
    flex-direction: column; /* Stack images vertically */
    width: 100%;
    padding: 0px 13px;
    align-items: center; /* Center-align the images */
  }
  
  .project-report-photos .img-content {
    padding: 13px;
  }
  
  .project-report-photos .img-content.landscape,
  .project-report-photos .img-content.portrait {
    width: auto; /* Adjust the width based on the content */
  }
  
  .project-report-photos img {
    width: 100%; /* Make images responsive */
    height: auto;
  }
  
  .project-report-photos .img-description {
    width: 100%;
    font-style: italic;
    font-size: 18px;
    line-height: 22px;
    margin: 0px;
  }
  
  
  .project-report-attached-documents {
    padding-top: 3px;
    padding-bottom: 24px;
  
    .header {
      margin-top: 0px;
    }
  
    ul {
      display: inline-block;
      font-size: 21px;
      list-style-type: none;
      margin: 0px;
      width: 45%;
  
      li {
        margin: 0px;
        &:before {
          content: '\x15AA';
          font-size: 12px;
          margin-top: -4px;
          margin-right: 12px;
        }
      }
    }
  }
  
  .project-report-disclaimer {
    padding-top: 3px;
  
    .header {
      margin-top: 0px;
    }
  
    .content{
      padding: 10px 26px 0px 26px;
    }
  }
  
  
  .clearfix{
    clear: both;
  }
  
  .padding-bottom{
    padding-bottom: 36px;
  }

  .avoid-break {
    page-break-inside: avoid !important;
  }
  
  
  .table-sub-section-header,
  .details-sub-section-header {
    text-align: left;
    background-color: #191B24;
    color: white;
    font-weight: 600;
    font-size: 18px;
    padding: 2px 28px;
    margin: 1px 0px;
  
    &.details-sub-section-header {
      margin-top: 32px;
      margin-bottom: 16px;
    }
  }
  
  .details-sub-section-header-no-margin {
    text-align: left;
    background-color: #191B24;
    color: white;
    font-weight: 600;
    font-size: 18px;
    padding: 2px 28px;
    margin: 1px 0px;
  
    &.details-sub-section-header-no-margin {
      margin-top: 32px;
    }
  }
  
  .details-grey-sub-section-header {
    text-align: left;
    background-color: #eaeaec;
    color: #191B24;
    font-weight: 600;
    font-size: 18px;
    padding: 2px 28px;
    margin: 1px 0px;
    font-style: italic;
    &.details-grey-sub-section-header {
      margin-bottom: 16px;
    }
  }
  
  .page-break {
    clear: both;
    display: block;
    page-break-after: always;
  }
  
  .no-break {
    page-break-inside: avoid !important;
  }
  
  ul {
    list-style-type: none;
    margin: 0;
    padding-left: 0;
  }
  
  .logo {
    margin-top: -6px;
    height: 80px;
    width: 80px;
    border-radius: 40px;
    background-color: #EAEAEC;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    float: right;
    margin-right: 26px;
  }
  
  
  
  
  
  
  
  
  .meeting {
    font-size: 18px;
  
    .meeting-info {
      margin-bottom: 40px;
      text-align: left;
  
      .meeting-info-table {
        tr {
          td {
            &:first-child {
              font-weight: bold;
              width: 25%;
            }
          }
        }
      }
    }
  
    .meeting-attendees-table {
      margin-bottom: 50px;
      text-align: left;
  
      tr {
        td {
          &:first-child {
            font-weight: bold;
            width: 25%;
          }
  
          &:nth-child(2) {
            width: 25%;
          }
  
          &:nth-child(3) {
            width: 40%;
          }
  
          &:last-child {
            font-weight: bold;
            text-align: center;
            width: 10%;
          }
        }
      }
    }
  
    .meeting-category {
      padding: 8px 0;
  
      .meeting-items-content {
        text-align: left;
  
        .meeting-item {
          line-height: 1.4;
          margin-top: 10px;
  
          .meeting-item-header {
            font-size: 18px;
            font-style: italic;
            font-weight: 600;
            padding: 4px 0px;
          }
  
          .discussion {
            width: 100%;
            font-size: 16px;
  
            > div {
              display: inline;
  
              p {
                white-space: pre-wrap;
              }
  
              &.info {
                p:last-child {
                  display: inline;
                }
              }
  
              &.more-info {
                p:first-child {
                  display: inline;
                }
              }
  
              ul {
                list-style-type: initial;
                padding-left: 28px;
              }
            }
          }
  
          .resolved {
            color: #ef4834;
            font-weight: bold;
  
            img {
              width: 8px;
            }
          }
        }
      }
    }
  
    .message-container {
      margin-top: 10px;
      text-align: left;
  
      .prepared-by {
        margin-top: 50px;
        page-break-inside: avoid;
  
        .user {
          text-align: center;
        }
      }
    }
  }
  
  
  
  
  
  .daily-report-details {
    .log-table {
      margin-bottom: 20px;
  
      tr {
        td {
          vertical-align: top;
  
          h4 {
            margin-bottom: 0;
          }
  
          ul {
            list-style-position: inside;
            list-style-type: initial;
          }
  
          .floor-and-area {
            margin-top: 5px;
          }
        }
      }
  
      &.with-comments {
        tr {
          th {
            &:nth-child(3) {
              text-align: center;
            }
          }
  
          td {
            &:nth-child(1) {
              width: 20%;
            }
  
            &:nth-child(2) {
              width: 40%;
            }
  
            &:nth-child(3) {
              text-align: center;
              width: 10%;
            }
  
            &:nth-child(4) {
              width: 15%;
            }
  
            &:nth-child(5) {
              width: 15%;
            }
          }
        }
      }
  
      &.sub-dr {
        tr {
          &.same-tech {
            td {
              border: none;
            }
          }
  
          td {
            &:nth-child(1) {
              width: 15%;
            }
  
            &:nth-child(2) {
              width: 25%;
            }
  
            &:nth-child(3) {
              width: 45%;
            }
  
            &:nth-child(4) {
              width: 15%;
            }
          }
        }
      }
  
      &.open-item {
        tr {
          td {
            &:nth-child(1) {
              width: 15%;
            }
  
            &:nth-child(2) {
              width: 70%;
            }
  
            &:nth-child(3) {
              width: 15%;
            }
          }
        }
      }
  
      &.fwo {
        tr {
          td {
            &:nth-child(1) {
              width: 15%;
            }
  
            &:nth-child(2) {
              width: 55%;
            }
  
            &:nth-child(3) {
              width: 15%;
            }
  
            &:nth-child(4) {
              width: 15%;
            }
          }
        }
      }
    }
  
    table.log-table.gc-staff {
      tr {
        td {
          border-top: none;
          padding: 8px;
        }
      }
    }
  
    .site-photos {
      margin-bottom: 0;
  
      tr:last-child td {
        border-bottom: none;
      }
    }
  }
  
  .log {
    width: 100%;
    .log-body {
      text-align: left;
      width: 100%;

      .response {
        color: #FB6120;
      }
  
      .attached-img {
        padding: 0px 26px;
        text-align: right;
      }
    }
  }
  
 
  
  
  
  .row {
    display: table;
    margin-bottom: 5px;
    padding: 0px 26px;
    width: 95%;
    font-size: 18px;
  
    &.bold-font{
      font-weight: 600;
    }
  
    &.response {
      margin-top: 20px;
      font-size: 20px;
    }
  
    &.subtitle {
      margin-bottom: 20px;
      font-size: 20px;
    }
  
    &.request {
      margin: 20px 0px 16px 0px;
      font-size: 24px;
      font-weight: 600;
    }
  
    &.response {
      margin: 20px 0px 16px 0px;
      font-size: 22px;
      font-weight: 600;
    }
  
    .document-name {
      display: -webkit-box;
      -webkit-box-pack: flex-start;
      width: 100%;
  
      & :first-child {
        height: 20px;
        margin-top: 4px;
        margin-right: 12px;
      }
    }
  
    .preview_photos {
      display: -webkit-box;
      -webkit-box-pack: flex-start;
      width: 100%;
  
      & > * {
        margin-top: 12px;
        margin-right: 12px;
        width: 80px;
        height: 80px;
      }
    }
  
    .disclaimer {
      width: 100%;
      font-size: 18px;
      font-style: italic;
    }
  
    .col {
      display: table-cell;
      padding-bottom: 5px;
  
      &:first-child {
        vertical-align: top;
        width: 20%;
        font-weight: 600;
      }
  
      &:last-child {
        width: 80%;
      }
    }
  .col-small {
      display: table-cell;
      padding-bottom: 5px;
  
      &:first-child {
        vertical-align: top;
        width: 15%;
        font-weight: 600;
      }
      &:nth-child(2) {
        vertical-align: top;
        width: 50%;
      }
      &:nth-child(3) {
        vertical-align: top;
        width: 10%;
        font-weight: 600;
      }
      &:last-child {
        width: 25%;
      }
    }
    .col-smaller {
      display: table-cell;
      padding-bottom: 5px;
      &:first-child {
        vertical-align: top;
        width: 15%;
        font-weight: 600;
      }
    }
    .col-bigger {
      display: table-cell;
      padding-bottom: 5px;
      &:first-child {
        vertical-align: top;
        width: 85%;
        font-weight: 600;
      }
    }
    .col-attendee-table-header{
      display: table-cell;
      padding-bottom: 5px;
      font-weight: 600;
      &:first-child {
        vertical-align: top;
        width: 60%;
      }
      &:nth-child(2) {
        vertical-align: top;
        width: 10%;
      }
      &:nth-child(3) {
        vertical-align: top;
        width: 10%;
      }
      &:nth-child(4) {
        vertical-align: top;
        width: 10%;
      }
      &:last-child {
        vertical-align: top;
        width: 10%;
      }
    }
    .col-attendee-table{
      display: table-cell;
      padding-bottom: 5px;
      &:first-child {
        vertical-align: top;
        width: 60%;
      }
      &:nth-child(2) {
        vertical-align: top;
        width: 10%;
      }
      &:nth-child(3) {
        vertical-align: top;
        width: 10%;
      }
      &:nth-child(4) {
        vertical-align: top;
        width: 10%;
      }
      &:last-child {
        vertical-align: top;
        width: 10%;
      }
    }
    .col-half {
      display: table-cell;
      width: 50%;
  
      .row {
        padding: 0px;
      }
  
      &:last-child {
        padding-left: 52px;
      }
  
      .col {
        &:first-child {
          width: 40%;
        }
  
        &:last-child {
          width: 60%;
        }
      }
    }
  }
  .action-item-row {
    display: table-row;
    margin-bottom: 5px;
    padding: 0px 26px;
    width: 100%;
    font-size: 18px;
  }
  
  .action-item-cell {
    display: table-cell;
    padding: 5px; 
    text-align: left;
    &:first-child {
        vertical-align: top;
        width: 10%;
      }
      &:nth-child(2) {
        vertical-align: top;
        width: 50%;
      }
      &:nth-child(3) {
        vertical-align: top;
        width: 10%;
      }
      &:nth-child(4) {
        vertical-align: top;
        width: 10%;
      }
      &:nth-child(5) {
        vertical-align: top;
        width: 10%;
      }
      &:last-child {
        vertical-align: top;
        width: 10%;
      }
     
  }
  .action-item-note-row{
    display:table-row;
  }
  .action-item-note-cell {
    display: table-cell;
    padding: 5px;
    text-align: left;
    overflow: visible;
    color: #1E957B;
    colspan: 6 !important;
  }
  .note-color{
      color: #1E957B;
  }
  .action-item-note-paragraph {
    padding: 5px;
    text-align: left;
    overflow: visible;
    color: #1E957B;
  }
  .action-item-table-html {
    display: table;
    width: 100%; /* Ensures table spans the width of its container */
    margin: 0 auto;
  }
  
  .bullet-list-container {
    display: flex;
    flex-direction: row; /* Change to row for horizontal layout */
    list-style-type: disc; /* Add bullet point style */
    padding: 0; /* Remove default padding */
  }
  
  .bullet-point {
     flex: 1; /* Each bullet point takes equal width */
    text-align: left; /* Align the text to the left */
    margin-right: 20px; /* Add some spacing between bullet points */
  }
  
  .corrections-row {
    display: table-row;
    margin-bottom: 5px;
    padding: 0px 26px;
    width: 100%;
    font-size: 18px;
  }
  
  .corrections-cell {
    display: table-cell;
    padding: 5px; /* Adjust padding as necessary */
    text-align: left;
    &:first-child {
        vertical-align: top;
        width: 10%;
      }
      &:nth-child(2) {
        vertical-align: top;
        width: 60%;
      }
      &:nth-child(3) {
        vertical-align: top;
        width: 10%;
      }
      &:nth-child(4) {
        vertical-align: top;
        width: 10%;
      }
      &:last-child {
        vertical-align: top;
        width: 10%;
      }
     
  }
  
  .corrections-table {
    display: table;
    width: 100%; /* Ensures table spans the width of its container */
    margin: 0 auto;
  }
  
  .text {
    white-space: pre-wrap;
  }
  
  .legal {
    font-size: 12px;
    font-style: italic;
  }
  
  .link {
    color: #1a0dab;
    max-width: 512px;
  }
  
  .bullet-list {
    list-style-type: initial;
    padding-left: 28px;
  
    li {
      padding-bottom: 5px;
  
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
  
  table.small_table.schedule-table{
    thead {
      th {
        width: 105px;  
        word-spacing: 105px;    
        line-height: 1.2;
        padding: 6px 0;
  
        &:nth-child(2), &:nth-child(6){
          width: 90px;  
          word-spacing: 90px;    
        }
        &:nth-child(10){
          width: 105px;  
          word-spacing: 105px;    
        }
  
        &:first-child, &:last-child{
          width: auto;
        }
      }
    }
  
    tbody{
      tr {
        td{
          padding: 4px 0;
        }
      }
    }
    
    th, td {
      &:nth-child(1) {
        padding-left: 28px !important;
      }
    }
  }
  
  
  .submittal-stamp-container {
    background: #fafafa;
    padding: 11px 0;
  }
  
  .submittal-stamp {
    background: #fff;
    font-size: 12px;
    font-weight: normal;
    margin-left: 35px;
    width: 460px;
  
    .row.submittal-stamp-block {
      margin-left: 22px;
      padding: 22px 0;
      width: 416px;
  
      &:first-child {
        background: #fafafa;
        margin-left: 0;
        padding: 11px 0;
        width: 100%;
      }
  
      &:last-child {
        border-top: 1px solid #d2d5d9;
      }
    }
  
    .col-half.submittal-stamp-logo {
      font-size: 16px;
      font-weight: bold;
  
      img {
        vertical-align: middle;
      }
    }
  
    .col-half.submittal-stamp-title {
      font-size: 16px;
      font-weight: bold;
      padding-left: 0;
      text-align: right;
    }
  
    .submittal-stamp-action {
      .col {
        font-size: 12px;
        padding-bottom: 0;
  
        b {
          font-size: 14px;
        }
  
        &:first-child {
          width: 5%;
  
          img {
            height: 15px;
            vertical-align: top;
          }
        }
      }
    }
  
    .submittal-stamp-legal {
      font-size: 12px;
      text-align: justify;
  
      > p {
        margin: 0;
        margin-bottom: 4px;
      }
    }
  }
  
  .spe-footer {
    text-align: left;
    p {
      font-size: 15px;
      padding: 0px 28px;
      margin: 0px;
      &.name {
        font-weight: bold;
      }
    }
    .bottom-line {
      width: 100%;
      > * {
        -webkit-box-flex: 1;
      }
      .page-number {
        text-align: right;
      }
    }
  }
  
  .combined-pdf-link {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin-top: 80px;
  }
  
  .new-page-start {
    page-break-before: always;
  }
  
  .simple-text{
    font-weight:400;
    text-align:left;
  }
  .project-report-header-docs {
    background: #191b24;
    color: white;
    display: -webkit-box;
    -webkit-box-pack: flex-start;
    -webkit-box-align: center;
    width: 100%;
    .first {
      padding: 12px 28px;
      -webkit-box-flex: 1;
  
      h2 {
        margin: 0px;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
      }
      h3 {
        margin: 0px;
        font-size: 18px;
        font-weight: normal;
        text-transform: uppercase;
      }
    }
  
    .second {
      padding: 0px 28px;
      -webkit-box-flex: 1;
      h3 {
        text-align: right;
        font-size: 18px;
        font-weight: normal;
        span {
          margin-left: 14px;
          color: #d4fb00;
        }
      }
    }
    .second-vertical {
      padding: 12px 28px;
      -webkit-box-flex: 1;
        text-align: right;
      h3 {
        font-size: 18px;
        font-weight: normal;
        text-align: right;
        padding:0;
        margin:0
      }
      span {
        font-size: 18px;
        font-weight: normal;
        text-align: right;
        color: #d4fb00;
        padding: 0;
        margin:0;
        }
    }
  }
  .overdue, .urgent {
    color: #EC6A32;
  }
  </style>

  `;

export default css;
