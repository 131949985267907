import { showErrorMessage } from 'actions/messages';
import { usersApi } from '../services/api/users-api';
import { updateUser } from 'actions/authentication.js';
import { THEMES } from 'domain/theme.js';

export const SET_THEME = 'SET_THEME';
export const SET_CHAT_OPEN = 'SET_CHAT_OPEN';

export function setBrightnessMode(isDark) {
  return setTheme(isDark ? THEMES.DARK : THEMES.LIGHT);
}

export function setTheme(theme) {
  return (dispatch) => {
    return usersApi.setTheme(theme).then((response) => {
      if (response.ok) {
        dispatch(updateUser(response.data));
        dispatch(updateTheme(theme));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function updateTheme(theme = '') {
  return {
    type: SET_THEME,
    payload: theme,
  };
}

export function setChatOpen(open) {
  return {
    type: SET_CHAT_OPEN,
    payload: open,
  };
}
