import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

class FieldWorkOrderActions extends Component {
  static propTypes = {
    handleClose: PropTypes.func.isRequired,
  };

  render() {
    const {
      handleClose,
      handleCancel,
      handleSave,
      handleEdit,
      fieldWorkOrderEdit,
      showErrorMessage,
      fieldWorkOrder,
    } = this.props;
    const readOnly =
      fieldWorkOrder.status === 'gc_approved' ||
      fieldWorkOrder.status === 'gc_denied';

    return (
      <div className="field-work-order-actions">
        {showErrorMessage && (
          <h4 className="error-message">
            There are missing fields, please review.
          </h4>
        )}
        {fieldWorkOrderEdit ? (
          <div className="buttons-container">
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Cancel"
              onClick={handleCancel}
            />
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Save"
              onClick={handleSave}
            />
          </div>
        ) : (
          <div className="buttons-container">
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Close"
              onClick={handleClose}
            />
            {!readOnly && (
              <Button
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.GREEN}
                label="Edit"
                onClick={handleEdit}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default FieldWorkOrderActions;
