import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import ReadOnlyInput from 'components/shared/input/ReadOnlyInput';

class FieldWorkOrderReview extends Component {
  static propTypes = {
    fieldWorkOrder: PropTypes.shape({}).isRequired,
  };

  render() {
    const { fieldWorkOrder } = this.props;
    return (
      <div className="review-form-trade-block review-form-field-work-order">
        <DetailsTitle
          className="modal-form-subtitle"
          title="Extra Work Order"
        />
        <div className="form-block multicontrols-block">
          {!!fieldWorkOrder.number && (
            <FormControlBlock
              label="EWO#"
              control={<ReadOnlyInput value={fieldWorkOrder.number} />}
            />
          )}
          <FormControlBlock
            label="Start Date"
            control={
              <ReadOnlyInput
                value={
                  !!fieldWorkOrder.start_date
                    ? moment(fieldWorkOrder.start_date).format('MM/DD/YYYY')
                    : 'TBD'
                }
              />
            }
          />
          <FormControlBlock
            label="Completion Date"
            control={
              <ReadOnlyInput
                value={
                  !!fieldWorkOrder.completion_date
                    ? moment(fieldWorkOrder.completion_date).format(
                        'MM/DD/YYYY'
                      )
                    : 'TBD'
                }
              />
            }
          />
        </div>
        {((fieldWorkOrder.preset_tasks && fieldWorkOrder.preset_tasks.length) ||
          fieldWorkOrder.preset_task_other) && (
          <div className="form-block">
            <FormControlBlock
              label="Tasks To Be Performed"
              control={
                <ul className="form-items-list small-regular">
                  {!!fieldWorkOrder.preset_tasks.length &&
                    fieldWorkOrder.preset_tasks.map((task) => (
                      <li key={task.id}>{task.label}</li>
                    ))}
                  {!!fieldWorkOrder.preset_task_other && (
                    <li>{fieldWorkOrder.preset_task_other}</li>
                  )}
                </ul>
              }
            />
          </div>
        )}
        {!!fieldWorkOrder.work_performed && (
          <div className="form-block">
            <FormControlBlock
              label="Work Required"
              control={
                <TextareaAutosizeWrapper
                  initialValue={fieldWorkOrder.work_performed}
                  disabled={true}
                />
              }
            />
          </div>
        )}
        {!!fieldWorkOrder.comments && (
          <div className="form-block">
            <FormControlBlock
              label="Comments"
              control={
                <TextareaAutosizeWrapper
                  initialValue={fieldWorkOrder.comments}
                  disabled={true}
                />
              }
            />
          </div>
        )}
        {!!fieldWorkOrder.labor && (
          <div className="form-block">
            <FormControlBlock
              label="Labor"
              control={
                <TextareaAutosizeWrapper
                  initialValue={fieldWorkOrder.labor}
                  disabled={true}
                />
              }
            />
          </div>
        )}
        {!!fieldWorkOrder.materials && (
          <div className="form-block">
            <FormControlBlock
              label="Materials"
              control={
                <TextareaAutosizeWrapper
                  initialValue={fieldWorkOrder.materials}
                  disabled={true}
                />
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export default FieldWorkOrderReview;
