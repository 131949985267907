import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { showErrorMessage } from 'actions/messages';
import Dashboard from 'components/admin/dashboard/Dashboard';

/**
 * Parent container for all dashboard components/routes. Also catch render errors and redirects to /
 */
class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    this.props.dispatch(
      showErrorMessage(
        'Something went wrong with the request, please try again later.'
      )
    );
  }

  render() {
    if (this.state.hasError) {
      return <Redirect to="/" />;
    } else {
      return (
        <Switch>
          <Route exact path={this.props.match.url} component={Dashboard} />
        </Switch>
      );
    }
  }
}

export default DashboardContainer;
