import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getCloseoutCats,
  getCloseoutCompanyTypes,
  getCloseoutCatsCompanyTypes,
} from 'components/admin/projects/details/project-closeout/store/selectors';
import CloseoutCatRow from 'components/admin/projects/details/project-closeout/CloseoutCatRow';
import { getDarkMode } from 'selectors/theme';

class CloseoutCatsTable extends Component {
  static propTypes = {
    headers: PropTypes.arrayOf(PropTypes.string),
  };

  constructor(props) {
    super(props);
    this.state = {
      cols: [],
    };
  }

  componentDidMount() {
    this.cols();
  }

  cols = () => {
    const { headers } = this.props;

    let newCols = [];
    if (headers.length > 0) {
      for (let i = 0; i < headers.length; i++) {
        newCols.push(<col key={i + 1} />);
      }
    }
    this.setState({ cols: newCols });
  };

  render() {
    const { headers, closeoutCats, innerRef, provided, darkMode } = this.props;
    const { cols } = this.state;

    return (
      <div ref={innerRef} {...provided.droppableProps}>
        <Table
          className={`simple-table sticky-header closeout-cats ${
            darkMode ? 'dark-mode' : ''
          }`}
        >
          {cols.length > 0 && <colgroup>{cols}</colgroup>}
          <thead>
            <tr>
              {headers.map((element, index) => {
                return (
                  <th key={index}>
                    <span>{element}</span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {closeoutCats.map((cat, index) => {
              return (
                <CloseoutCatRow closeoutCat={cat} index={index} key={index} />
              );
            })}
            {this.props.provided.placeholder}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    closeoutCats: getCloseoutCats(state),
    closeoutCompanyTypes: getCloseoutCompanyTypes(state),
    closeoutCatsCompanyTypes: getCloseoutCatsCompanyTypes(state),
    darkMode: getDarkMode(state),
  };
})(CloseoutCatsTable);
