import { createSelector } from 'reselect';

export const getShowOpenItemsModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.openItems.ui.showOpenItemsModal,
  (showOpenItemsModal) => {
    return showOpenItemsModal;
  }
);

export const getShowOpenItemsReportModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.openItems.ui.showOpenItemsReportModal,
  (showOpenItemsReportModal) => {
    return showOpenItemsReportModal;
  }
);

export const getProjectOpenItems = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.openItems.data.projectOpenItems,
  (projectOpenItems) => {
    return projectOpenItems;
  }
);

export const getCurrentPage = createSelector(
  (state) => state.projects.scenes.details.scenes.openItems.data.currentPage,
  (currentPage) => {
    return currentPage;
  }
);

export const getTotal = createSelector(
  (state) => state.projects.scenes.details.scenes.openItems.data.total,
  (currentPage) => {
    return currentPage;
  }
);

export const getPageSize = createSelector(
  (state) => state.projects.scenes.details.scenes.openItems.data.pageSize,
  (currentPage) => {
    return currentPage;
  }
);

export const getCritical = createSelector(
  (state) => state.projects.scenes.details.scenes.openItems.data.critical,
  (critical) => {
    return critical;
  }
);

export const getStatus = createSelector(
  (state) => state.projects.scenes.details.scenes.openItems.data.status,
  (status) => {
    return status;
  }
);

export const getDueDateFilter = createSelector(
  (state) => state.projects.scenes.details.scenes.openItems.data.dueDateFilter,
  (dueDateFilter) => {
    return dueDateFilter;
  }
);

export const getShowOpenItemFormModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.openItems.ui.showOpenItemFormModal,
  (showOpenItemFormModal) => {
    return showOpenItemFormModal;
  }
);

export const getOpenItemForm = createSelector(
  (state) => state.projects.scenes.details.scenes.openItems.ui.openItemForm,
  (openItemForm) => {
    return openItemForm;
  }
);

export const getOpenItemFormErrors = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.openItems.ui.openItemFormErrors,
  (openItemFormErrors) => {
    return openItemFormErrors;
  }
);

export const getShowOpenItemDetailsModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.openItems.ui.showOpenItemDetailsModal,
  (showOpenItemDetailsModal) => {
    return showOpenItemDetailsModal;
  }
);

export const getEditOpenItemDetailsModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.openItems.ui.editOpenItemDetailsModal,
  (editOpenItemDetailsModal) => {
    return editOpenItemDetailsModal;
  }
);

export const getRespondOpenItemDetailsModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.openItems.ui
      .respondOpenItemDetailsModal,
  (respondOpenItemDetailsModal) => {
    return respondOpenItemDetailsModal;
  }
);

export const getCurrentProjectOpenItem = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.openItems.data.currentProjectOpenItem,
  (currentProjectOpenItem) => {
    return currentProjectOpenItem;
  }
);

export const getProjectOpenItemsCount = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.openItems.data.projectOpenItemsCount,
  (projectOpenItemsCount) => {
    return projectOpenItemsCount;
  }
);

export const getProjectOpenItemsCritCount = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.openItems.data
      .projectOpenItemsCritCount,
  (projectOpenItemsCritCount) => {
    return projectOpenItemsCritCount;
  }
);

export const getProjectOpenItemNotes = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.openItems.data.projectOpenItemNotes,
  (projectOpenItemNotes) => {
    return projectOpenItemNotes;
  }
);

export const getOpenItemPdfViewer = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.openItems.ui.openItemPdfViewer,
  (openItemPdfViewer) => {
    return openItemPdfViewer;
  }
);
