import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Submittal from 'domain/submittal';
import SubmittalPermissions from 'permissions/submittal-permissions';
import {
  getSubmittalForm,
  getSubmittalReadOnly,
  getSubmittalGcAcceleration,
} from 'components/admin/projects/details/project-submittal-log/store/selectors';
import { updateSubmittalFormValue } from 'components/admin/projects/details/project-submittal-log/store/actions';
import StampDetails from 'components/shared/stamp/StampDetails';

class SubmittalStamp extends Component {
  static propTypes = {
    currentUser: PropTypes.shape({}).isRequired,
    submittal: PropTypes.shape({}),
  };

  componentDidMount() {
    const { submittal } = this.props;
    const addlAssignee = submittal && this.addlAssignee();
    addlAssignee &&
      this.handleFormChange('stampAction', addlAssignee.stamp_action);
  }

  handleFormChange = (attribute, value) => {
    this.props.dispatch(updateSubmittalFormValue(attribute, value));
  };

  handleStampActionChange = (value) => {
    const { submittal } = this.props;
    const addlAssignee = submittal && this.addlAssignee();
    this.handleFormChange('stampAction', value);
    !addlAssignee && this.handleFormChange('status', value);
  };

  addlAssignee = () => {
    const { currentUser, submittal } = this.props;
    return Submittal.addlAssignee(submittal, currentUser);
  };

  assigneeCompanyName = () => {
    const { submittal } = this.props;
    const addlAssignee = this.addlAssignee();
    const assignee = addlAssignee ? addlAssignee.assignee : submittal.assignee;
    const company = assignee && assignee.accountable;
    return company && company.name;
  };

  assigneeStamp = () => {
    const { submittal } = this.props;
    const addlAssignee = this.addlAssignee();
    return addlAssignee
      ? addlAssignee.assignee_stamp
      : submittal.assignee_stamp;
  };

  render() {
    const {
      currentUser,
      submittal,
      form,
      readOnly,
      gcAcceleration,
    } = this.props;
    const assigneeCompanyName = submittal && this.assigneeCompanyName();
    const assigneeStamp = submittal && this.assigneeStamp();

    const canRespond =
      (readOnly ||
        SubmittalPermissions.canRespond(
          currentUser,
          submittal,
          gcAcceleration
        )) &&
      !Submittal.isNewRevision(form);

    return (
      canRespond && (
        <StampDetails
          position="horizontal"
          companyName={assigneeCompanyName}
          stamp={assigneeStamp}
          value={form.stampAction}
          error={form.errors.stampAction}
          readOnly={readOnly}
          onChange={this.handleStampActionChange}
        />
      )
    );
  }
}

export default connect((state) => {
  return {
    form: getSubmittalForm(state),
    readOnly: getSubmittalReadOnly(state),
    gcAcceleration: getSubmittalGcAcceleration(state),
  };
})(SubmittalStamp);
