import {
  WORK_ORDERS_CONTRACTOR_NEW_SUCCESS,
  WORK_ORDERS_CREATION_BUILDING_DOCUMENTS_REMOVE_SUCCESS,
  WORK_ORDERS_CREATION_BUILDING_DOCUMENTS_UPLOAD_SUCCESS,
  WORK_ORDERS_CREATION_BUILDING_LOCATION_UPDATE_SUCCESS,
  WORK_ORDERS_CREATION_BUILDING_UPDATE_SUCCESS,
  WORK_ORDERS_CREATION_LOAD_BUILDINGS_SUCCESS,
  WORK_ORDERS_CREATION_NEW_BUILDING_SUCCESS,
  WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_SUCCESS,
  WORK_ORDERS_CREATION_TASKS_TASK_SAVE_SUCCESS,
  WORK_ORDERS_DETAILS_DOCUMENT_REMOVE_SUCCESS,
  WORK_ORDERS_DETAILS_DOCUMENTS_UPLOAD_SUCCESS,
  WORK_ORDERS_DETAILS_UNREAD_NOTES_SET,
  WORK_ORDERS_LOAD_CONTRACTORS_SUCCESS,
  WORK_ORDERS_LOAD_SUB_TRADES_SUCCESS,
  WORK_ORDERS_LOAD_SUCCESS,
  WORK_ORDERS_LOAD_TRADES_SUCCESS,
  WORK_ORDERS_SUMMARY_LOAD_SUCCESS,
  WORK_ORDERS_UNION_TRADES_LOAD_SUCCESS,
  WORK_ORDERS_WO_ADD,
  WORK_ORDERS_WO_LOAD_TASKS_SUCCESS,
  WORK_ORDERS_WO_SELECT_SUCCESS,
  WORK_ORDERS_WO_ATTRIBUTE_UPDATE,
  WORK_ORDERS_WO_UPDATE,
  WORK_ORDERS_WORK_ORDER_CREATION_SUCCESS,
  WORK_ORDERS_TASK_UPDATE,
  WORK_ORDERS_CURRENT_WO_COMPLETE,
  WORK_ORDERS_TASK_REMOVE,
  WORK_ORDERS_SUMMARY_TASK_REMOVE,
  WORK_ORDERS_TASK_CHECKLIST_UPDATE,
  WORK_ORDERS_CURRENT_WO_ATTRIBUTE_UPDATE,
  WORK_ORDERS_ADD_BUILDING_TO_BEGINNING,
} from 'actions/admin/work-orders/action-types';

const initialState = {
  buildings: [],
  orders: null,
  contractors: [],
  currentWorkOrder: null,
  tasks: [],
  trades: [],
  subTradesByTradeId: {},
  summary: null,
  unionTrades: [],
};

export default function workOrdersDataReducer(state = initialState, action) {
  let newTasks;
  let index;
  let newBuildings;

  switch (action.type) {
    case WORK_ORDERS_LOAD_SUCCESS:
      return {
        ...state,
        orders: action.payload,
      };

    case WORK_ORDERS_WO_SELECT_SUCCESS:
      return {
        ...state,
        currentWorkOrder: action.payload,
        summary: null,
        tasks: [],
      };

    case WORK_ORDERS_WO_LOAD_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.payload,
      };

    case WORK_ORDERS_CREATION_LOAD_BUILDINGS_SUCCESS:
      return {
        ...state,
        buildings: action.payload,
        errors: [],
      };

    case WORK_ORDERS_WORK_ORDER_CREATION_SUCCESS:
      return {
        ...state,
        currentWorkOrder: action.payload,
      };

    case WORK_ORDERS_LOAD_TRADES_SUCCESS:
      return {
        ...state,
        trades: action.payload,
      };

    case WORK_ORDERS_LOAD_SUB_TRADES_SUCCESS:
      const newSubTrades = {
        ...state.subTradesByTradeId,
      };
      newSubTrades[action.payload.tradeId] = action.payload.subTrades;
      return {
        ...state,
        subTradesByTradeId: newSubTrades,
      };

    case WORK_ORDERS_CREATION_TASKS_TASK_SAVE_SUCCESS:
      newTasks = state.tasks.slice();
      newTasks.push(action.payload);
      return {
        ...state,
        tasks: newTasks,
      };

    case WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_SUCCESS:
    case WORK_ORDERS_TASK_REMOVE:
      return {
        ...state,
        tasks: state.tasks.filter((element) => element.id !== action.payload),
      };

    case WORK_ORDERS_LOAD_CONTRACTORS_SUCCESS:
      return {
        ...state,
        contractors: action.payload,
      };

    case WORK_ORDERS_CONTRACTOR_NEW_SUCCESS:
      return {
        ...state,
        contractors: state.contractors.concat([action.payload]),
      };

    case WORK_ORDERS_CURRENT_WO_ATTRIBUTE_UPDATE:
      return {
        ...state,
        currentWorkOrder: {
          ...state.currentWorkOrder,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case WORK_ORDERS_WO_ATTRIBUTE_UPDATE:
      return {
        ...state,
        currentWorkOrder: {
          ...state.currentWorkOrder,
          [action.payload.attribute]:
            action.payload.workOrder[action.payload.attribute],
        },
      };

    case WORK_ORDERS_WO_UPDATE:
      return {
        ...state,
        currentWorkOrder: action.payload,
      };

    case WORK_ORDERS_UNION_TRADES_LOAD_SUCCESS:
      return {
        ...state,
        unionTrades: action.payload,
      };

    case WORK_ORDERS_SUMMARY_LOAD_SUCCESS:
      return {
        ...state,
        summary: action.payload,
        tasks: action.payload.tasks, // The complete tasks info can be obtained from the WO summary
      };

    case WORK_ORDERS_SUMMARY_TASK_REMOVE:
      return {
        ...state,
        summary: {
          ...state.summary,
          tasks: state.summary.tasks.filter(
            (element) => element.id !== action.payload
          ),
        },
      };

    case WORK_ORDERS_WO_ADD:
      return {
        ...state,
        currentWorkOrder: null,
        tasks: [],
        summary: null,
      };

    case WORK_ORDERS_TASK_CHECKLIST_UPDATE:
      return {
        ...state,
        tasks: state.tasks.map((task) => {
          if (task.id === action.payload.taskId) {
            return {
              ...task,
              task_checklist: action.payload.taskChecklist,
            };
          } else {
            return task;
          }
        }),
      };

    case WORK_ORDERS_TASK_UPDATE:
      return {
        ...state,
        tasks: state.tasks.map((task) => {
          if (task.id === action.payload.id) {
            return action.payload;
          } else {
            return task;
          }
        }),
      };

    case WORK_ORDERS_DETAILS_DOCUMENTS_UPLOAD_SUCCESS:
      return {
        ...state,
        currentWorkOrder: {
          ...state.currentWorkOrder,
          documents: state.currentWorkOrder.documents.concat(action.payload),
        },
      };

    case WORK_ORDERS_DETAILS_UNREAD_NOTES_SET:
      return {
        ...state,
        currentWorkOrder: {
          ...state.currentWorkOrder,
          unread_notes: action.payload,
        },
      };

    case WORK_ORDERS_DETAILS_DOCUMENT_REMOVE_SUCCESS:
      return {
        ...state,
        currentWorkOrder: {
          ...state.currentWorkOrder,
          documents: state.currentWorkOrder.documents.filter(
            (element) => element.id !== action.payload
          ),
        },
      };

    case WORK_ORDERS_CREATION_NEW_BUILDING_SUCCESS:
      return {
        ...state,
        buildings: state.buildings.concat([action.payload]),
      };

    case WORK_ORDERS_CREATION_BUILDING_LOCATION_UPDATE_SUCCESS:
      newBuildings = state.buildings.slice();
      index = newBuildings.findIndex(
        (element) => element.id === action.payload.selectedBuildingId
      );
      newBuildings[index] = {
        ...newBuildings[index],
        location: action.payload.location,
      };
      newBuildings[index].location.latitude = action.payload.latitude;
      newBuildings[index].location.longitude = action.payload.longitude;

      return {
        ...state,
        buildings: newBuildings,
      };

    case WORK_ORDERS_CREATION_BUILDING_DOCUMENTS_UPLOAD_SUCCESS:
    case WORK_ORDERS_CREATION_BUILDING_DOCUMENTS_REMOVE_SUCCESS:
    case WORK_ORDERS_CREATION_BUILDING_UPDATE_SUCCESS:
      newBuildings = state.buildings.slice();
      index = newBuildings.findIndex(
        (element) => element.id === action.payload.id
      );
      newBuildings[index] = action.payload;
      return {
        ...state,
        buildings: newBuildings,
      };

    case WORK_ORDERS_CURRENT_WO_COMPLETE:
      return {
        ...state,
        currentWorkOrder: {
          ...state.currentWorkOrder,
          status: action.payload,
        },
      };

    case WORK_ORDERS_ADD_BUILDING_TO_BEGINNING:
      return {
        ...state,
        buildings: [action.payload, ...state.buildings],
      };

    default:
      return state;
  }
}
