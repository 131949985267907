// data
export const SET_PROJECT_PHOTOS_CURRENT_ALBUM =
  'SET_PROJECT_PHOTOS_CURRENT_ALBUM';
export const SET_PROJECT_PHOTOS_CURRENT_ALBUMS =
  'SET_PROJECT_PHOTOS_CURRENT_ALBUMS';
export const ADD_PROJECT_PHOTOS_CURRENT_ALBUMS =
  'ADD_PROJECT_PHOTOS_CURRENT_ALBUMS';
export const UPDATE_PROJECT_PHOTOS_ALBUM = 'UPDATE_PROJECT_PHOTOS_ALBUM';
export const ORDER_PROJECT_PHOTOS_CURRENT_ALBUMS =
  'ORDER_PROJECT_PHOTOS_CURRENT_ALBUMS';
export const REMOVE_PROJECT_PHOTOS_CURRENT_ALBUMS =
  'REMOVE_PROJECT_PHOTOS_CURRENT_ALBUMS';
export const SET_PROJECT_PHOTOS_CURRENT_PHOTOS =
  'SET_PROJECT_PHOTOS_CURRENT_PHOTOS';
export const ADD_PROJECT_PHOTOS_CURRENT_PHOTOS =
  'ADD_PROJECT_PHOTOS_CURRENT_PHOTOS';
export const ORDER_PROJECT_PHOTOS_CURRENT_PHOTOS =
  'ORDER_PROJECT_PHOTOS_CURRENT_PHOTOS';
export const REMOVE_PROJECT_PHOTOS_CURRENT_PHOTOS =
  'REMOVE_PROJECT_PHOTOS_CURRENT_PHOTOS';
export const SET_PROJECT_PHOTOS_FILTERS = 'SET_PROJECT_PHOTOS_FILTERS';
export const SET_PROJECT_PHOTOS_FORM = 'SET_PROJECT_PHOTOS_FORM';
export const SET_PROJECT_PHOTOS_FAVORITE = 'SET_PROJECT_PHOTOS_FAVORITE';
export const ADD_PROJECT_PHOTOS_COMMENT = 'ADD_PROJECT_PHOTOS_COMMENT';
export const SET_PROJECT_PHOTOS_FIXED_ALBUMS =
  'SET_PROJECT_PHOTOS_FIXED_ALBUMS';

// ui
export const SET_PROJECT_PHOTOS_SHOW_ALBUM_FORM =
  'SET_PROJECT_PHOTOS_SHOW_ALBUM_FORM';
export const SET_PROJECT_PHOTOS_SHOW_FORM = 'SET_PROJECT_PHOTOS_SHOW_FORM';
export const SET_PROJECT_PHOTOS_SHOW_FILTERS =
  'SET_PROJECT_PHOTOS_SHOW_FILTERS';
export const SET_PROJECT_PHOTOS_SHOW_DETAIL = 'SET_PROJECT_PHOTOS_SHOW_DETAIL';
export const SET_PROJECT_PHOTOS_SHOW_UPLOAD = 'SET_PROJECT_PHOTOS_SHOW_UPLOAD';
export const SET_PROJECT_PHOTOS_DETAIL = 'SET_PROJECT_PHOTOS_DETAIL';
export const SET_PROJECT_PHOTOS_SELECTED_ALBUMS =
  'SET_PROJECT_PHOTOS_SELECTED_ALBUMS';
export const SET_PROJECT_PHOTOS_SELECTED_PHOTOS =
  'SET_PROJECT_PHOTOS_SELECTED_PHOTOS';
export const SET_PROJECT_PHOTOS_SHOW_TAGS = 'SET_PROJECT_PHOTOS_SHOW_TAGS';
export const SET_PROJECT_PHOTOS_SHOW_USERS = 'SET_PROJECT_PHOTOS_SHOW_USERS';
export const SET_PROJECT_PHOTOS_SHOW_LOCATIONS =
  'SET_PROJECT_PHOTOS_SHOW_LOCATIONS';
export const SET_PROJECT_PHOTOS_SHOW_TRADES = 'SET_PROJECT_PHOTOS_SHOW_TRADES';
export const SET_PROJECT_PHOTOS_SHOW_MOVE_TO =
  'SET_PROJECT_PHOTOS_SHOW_MOVE_TO';
export const SET_PROJECT_PHOTOS_EDIT_IMAGE = 'SET_PROJECT_PHOTOS_EDIT_IMAGE';
export const UPDATE_PROJECT_PHOTOS_DETAIL = 'UPDATE_PROJECT_PHOTOS_DETAIL';
export const SET_PROJECT_PHOTOS_SELECTED_IDS =
  'SET_PROJECT_PHOTOS_SELECTED_IDS';
export const SET_PROJECT_PHOTOS_SHOW_EMAIL = 'SET_PROJECT_PHOTOS_SHOW_EMAIL';
export const SET_PROJECT_PHOTOS_SHOW_DELETE = 'SET_PROJECT_PHOTOS_SHOW_DELETE';
export const REMOVE_PROJECT_PHOTOS_DETAIL = 'REMOVE_PROJECT_PHOTOS_DETAIL';
export const SET_PROJECT_PHOTOS_START_UPLOAD =
  'SET_PROJECT_PHOTOS_START_UPLOAD';
export const OPEN_PROJECT_PHOTOS_CURRENT_DETAIL =
  'OPEN_PROJECT_PHOTOS_CURRENT_DETAIL';
