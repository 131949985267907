import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import Meeting from 'domain/new-meeting';
import { IconButton } from '@mui/material';
import moment from 'moment';
import MeetingsPermissions from 'permissions/new-meetings-permissions';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import EditDarkIcon from 'images/editable-pencil-dark.svg';
import EditIcon from 'images/editable-pencil.svg';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import dragDropIcon from 'images/dragDropIcon.svg';
import dragDropIconDark from 'images/dragDropIconDark.svg';
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';
import Selector from '../../../../shared/selector/Selector';
import DatePicker from 'components/shared/date-picker/DatePicker';
import DoneIcon from '@mui/icons-material/Done';
import documentSearchIconSvg from 'images/documentSearchIcon.svg';
import documentSearchIconDarkSvg from 'images/documentSearchIconDark.svg';
import deleteSvg from 'images/delete.svg';
import deleteDarkSvg from 'images/deleteDark.svg';
import deleteCrossSvg from 'images/deleteCross.svg';
import deleteCrossDarkSvg from 'images/deleteCrossDarkMode.svg';

import AddBoxIcon from '@mui/icons-material/AddBox';
import EditNoteIcon from '@mui/icons-material/EditNote';
import {
  getProjectNewMeetingsFilters,
  getMeetingsTitles,
  getMeetingsTypes,
  getMeetingsParentsTitles,
  getMeetingsParentsTypes,
  getMeetingsInstanceModal,
  getProjectNewMeetingsForm,
  getMeetingsInstanceForm,
  getActionItemsCategories,
  getActionItems,
} from './store/selectors';
import {
  loadActionItemsCategories,
  setActionItemHistoryModal,
  setActionItemsResolvedModal,
} from './store/actions';
import './ActionItemsTable.css';
import useActionItems from './ActionItemsTable.hook';
import DropdownMenuWithIcon from '../../../../shared/menu/DropdownMenuWithIcon';
import newMeetingsPermissions from '../../../../../permissions/new-meetings-permissions';
const ActionItemsTable = ({
  className,
  headersOnly,
  darkMode,
  currentProject,
  actionItemsCategories,
  dispatch,
  instanceForm,
  form,
  directory,
  actionItems,
  currentUser,
}) => {
  const [isCreatingNewItem, setIsCreatingNewItem] = useState(false);
  const [categorizedItems, setCategorizedItems] = useState({});
  const [oldItems, setOldItems] = useState([]);
  const meetingsInstanceId = instanceForm.id;
  const meetingsParentId = form.id;
  const {
    handleAddItemNestedUpdate,
    handleBlurUpdate,
    handleBlurNestedUpdate,
    handleTableUpdateChange,
    handleDeleteItem,
    handleDeleteItemNestedUpdate,
    handleDeleteItemUpdate,
    onDragEnd,
    handleResolveItem,
    handleResponsibleChange,
    handleResponsibleNestedUpdateChange,
    handleResponsibleUpdateChange,
    handleAddItemUpdate,
    handleTableNestedUpdateChange,
    handleDescriptionChange,
    handleTableChange,
    handleUpdateDescriptionChange,
    handleNestedUpdateDescriptionChange,
    handleAddItem,
    handleAddResolvedRemark,
    handleAddResolvedRemarkNested,
    handleSetEditModeInCategory,
    handleAddItemNote,
    handleNoteChange,
    editingInCategory,
  } = useActionItems({
    dispatch,
    actionItems,
    currentProject,
    instanceForm,
    meetingsParentId,
    meetingsInstanceId,
    setIsCreatingNewItem,
    isCreatingNewItem,
    currentUser,
  });
  const hasUncategorizedItems = instanceForm.meetingsActionItems.some(
    (ai) => !!ai.category
  );
  const hasNoCategories =
    !actionItemsCategories || actionItemsCategories.length === 0;
  const hasItems =
    instanceForm.meetingsActionItems &&
    instanceForm.meetingsActionItems.length > 0;

  useEffect(() => {
    dispatch(loadActionItemsCategories(currentProject.id));

    let categorized;
    if (hasNoCategories && hasItems) {
      categorized = Meeting.categorizeItemsByCategory(
        instanceForm.meetingsActionItems,
        [{ name: 'nocategory' }]
      );
      setCategorizedItems((currentItems) => {
        return { ...currentItems, ...categorized };
      });
      setOldItems(instanceForm.meetingsActionItems);
      return;
    }
    if (!hasNoCategories && hasUncategorizedItems) {
      categorized = Meeting.categorizeItemsByCategory(
        instanceForm.meetingsActionItems,
        [...actionItemsCategories, { name: 'nocategory' }]
      );
      setCategorizedItems((currentItems) => {
        return { ...currentItems, ...categorized };
      });
      setOldItems(instanceForm.meetingsActionItems);
      return;
    }
    categorized = Meeting.categorizeItemsByCategory(
      instanceForm.meetingsActionItems,
      [...actionItemsCategories, { name: 'nocategory' }]
    );
    setCategorizedItems((currentItems) => {
      return { ...currentItems, ...categorized };
    });
    setOldItems(instanceForm.meetingsActionItems);
  }, []);

  useEffect(() => {
    let categorized;
    if (hasNoCategories && hasItems) {
      categorized = Meeting.categorizeItemsByCategory(
        instanceForm.meetingsActionItems,
        [{ name: 'nocategory' }]
      );
      setCategorizedItems(categorized);
      return;
    }
    if (!hasNoCategories && hasItems) {
      categorized = Meeting.categorizeItemsByCategory(
        instanceForm.meetingsActionItems,
        [...actionItemsCategories, { name: 'nocategory' }]
      );
      setCategorizedItems(categorized);
      return;
    }
    categorized = Meeting.categorizeItemsByCategory(
      instanceForm.meetingsActionItems,
      [...actionItemsCategories, { name: 'nocategory' }]
    );
    setCategorizedItems(categorized);
  }, [instanceForm.meetingsActionItems, actionItemsCategories]);

  const getResponsibleOptions = () => {
    const options = [];
    if (directory) {
      directory.project_contributors.forEach((c) => {
        if (!options.find((o) => o.value === c.company_id)) {
          const opt = {
            value: c.company_id,
            label: c.company.name,
            subOptions: c.users.map((u) => ({
              value: u.id,
              label: u.full_name,
            })),
          };
          options.push(opt);
        }
      });
    }
    return options;
  };
  const statusOptions = [
    { value: 'open', label: 'Open' },
    { value: 'closed', label: 'Closed' },
    { value: 'on_hold', label: 'On Hold' },
  ];
  const priorityOptions = [
    { value: 'low', label: 'Low' },
    { value: 'high', label: 'High' },
    { value: 'medium', label: 'Medium' },
    { value: 'urgent', label: 'Urgent' },
  ];

  const handleOpenResolvedModal = () =>
    dispatch(setActionItemsResolvedModal(true));
  const handleOpenActionItemHistoryModal = (row) =>
    dispatch(setActionItemHistoryModal({ show: true, actionItemHistory: row }));

  const renderActionItemHeader = () => {
    return (
      <tr>
        <th>ITEM NO</th>
        <th>DESCRIPTION</th>
        <th>RESPONSIBLE</th>
        <th>DUE DATE</th>
        <th>PRIORITY</th>
        <th>STATUS</th>
        <th>ACTIONS</th>
      </tr>
    );
  };
  const actionItemsUpdatesActions = (row, update) => {
    const { isAgenda } = instanceForm;
    const handleChange = (option) =>
      option.value === 'add_remark'
        ? handleAddResolvedRemarkNested(row, update)
        : handleResolveItem(row);

    return (
      <div className="action-item-actions-small-container-outer">
        <div className={`${'action-item-actions-small-container-inner'}`}>
          {newMeetingsPermissions.canAddUpdatesAndResolve(
            currentUser,
            instanceForm,
            form.status === 'archived'
          ) && (
            <IconButton onClick={() => handleAddItemNestedUpdate(row, update)}>
              <AddBoxIcon className="action-items-icons-small" />
            </IconButton>
          )}
          {!isAgenda &&
            typeof update.id !== 'string' &&
            newMeetingsPermissions.canAddUpdatesAndResolve(
              currentUser,
              instanceForm,
              form.status === 'archived'
            ) && (
              <DropdownMenuWithIcon
                renderIcon
                icon={() => {
                  return <DoneIcon className="dropdown-icon-resolve-small" />;
                }}
                options={[
                  { value: 'add_remark', label: 'Add Remark' },
                  { value: 'no_remark', label: 'No Remark' },
                ]}
                onChange={(option) => handleChange(option)}
              />
            )}
          {typeof update.id === 'string' &&
            update.createdOnInstanceId === instanceForm.id && (
              <IconButton
                onClick={() => handleDeleteItemUpdate(row, update)}
                className="action-items-icons-small"
              >
                <img
                  alt="delete-action-item-update"
                  src={darkMode ? deleteCrossDarkSvg : deleteCrossSvg}
                  className="img-style"
                />
              </IconButton>
            )}
        </div>
      </div>
    );
  };
  const actionItemsNestedUpdatesActions = (row, update, nestedUpdate) => {
    const { isAgenda } = instanceForm;
    const handleChange = (option) =>
      option.value === 'add_remark'
        ? handleAddResolvedRemarkNested(row, update)
        : handleResolveItem(row);

    return (
      <div className="action-item-actions-small-container-outer">
        <div className={`${'action-item-actions-small-container-inner'}`}>
          <IconButton onClick={() => handleAddItemNestedUpdate(row, update)}>
            <AddBoxIcon className="action-items-icons-small" />
          </IconButton>
          {!isAgenda &&
            typeof nestedUpdate.id !== 'string' &&
            MeetingsPermissions.canResolveActionItem(
              instanceForm,
              row,
              form.status === 'archived'
            ) &&
            newMeetingsPermissions.canAddUpdatesAndResolve(
              currentUser,
              instanceForm,
              form.status === 'archived'
            ) && (
              <DropdownMenuWithIcon
                renderIcon
                icon={() => {
                  return <DoneIcon className="dropdown-icon-resolve-small" />;
                }}
                options={[
                  { value: 'add_remark', label: 'Add Remark' },
                  { value: 'no_remark', label: 'No Remark' },
                ]}
                onChange={(option) => {
                  handleChange(option);
                }}
              />
            )}
          {typeof nestedUpdate.id === 'string' &&
            nestedUpdate.createdOnInstanceId === instanceForm.id && (
              <IconButton
                onClick={() =>
                  handleDeleteItemNestedUpdate(row, update, nestedUpdate)
                }
                className="action-items-icons-small"
              >
                <img
                  alt="delete-action-item-update"
                  src={darkMode ? deleteCrossDarkSvg : deleteCrossSvg}
                  className="img-style"
                />
              </IconButton>
            )}
        </div>
      </div>
    );
  };
  const renderNestedUpdate = (row, update, nu, category) => {
    const {
      description,
      status,
      dueDate,
      responsible,
      responsibleCompany,
      createdAt,
      priority,
      isDiscarded,
    } = nu;
    const isOldNested = Meeting.isOldUpdate(oldItems, nu);
    if (isDiscarded) return null;
    return (
      <tr className="nested-row">
        <td>{actionItemsNestedUpdatesActions(row, update, nu)}</td>
        <td>
          <div className="update-description-area-description">
            <div className="nested-update-creation-date">
              {'*'}
              {createdAt
                ? moment(createdAt).format('MM/DD')
                : moment().format('MM/DD')}
            </div>
            <TextareaAutosizeWrapper
              maxRows={2}
              placeholder={'Description'}
              value={description}
              onChange={(desc) =>
                handleNestedUpdateDescriptionChange(desc, row, update, nu)
              }
              onBlur={() => handleBlurNestedUpdate(row)}
              className="smaller-description-field"
              disabled={isOldNested || editingInCategory !== category}
              style={isOldNested ? {} : { fontWeight: 600 }}
            />
          </div>
        </td>
        <td>
          <div className="update-description-area">
            <Selector
              clearable
              hideUnderline
              placeholder="--"
              className="responsible-dropdown"
              options={getResponsibleOptions()}
              value={{
                mainValue: {
                  value:
                    responsibleCompany &&
                    (responsibleCompany.id || responsibleCompany.value),
                  label:
                    responsibleCompany &&
                    (responsibleCompany.name || responsibleCompany.label),
                },
                subValue: responsible
                  ? {
                      value:
                        responsible &&
                        (responsible.id || responsibleCompany.value),
                      label:
                        responsible &&
                        (responsible.fullName || responsible.label),
                    }
                  : null,
              }}
              onChange={({ mainValue: rc, subValue: r }) => {
                handleResponsibleNestedUpdateChange(row, r, rc, update, nu);
              }}
              disabled={isOldNested || editingInCategory !== category}
              optionFontWeight={isOldNested ? 400 : 600}
            />
          </div>
        </td>
        <td>
          {' '}
          <div className="update-description-area">
            <DatePicker
              clearable
              hideUnderline
              value={dueDate}
              placeholder={'--'}
              onChange={(due) => {
                handleTableNestedUpdateChange(row, update, {
                  ...nu,
                  dueDate: due,
                });
              }}
              className={'action-item-datetime-picker'}
              calendarIcon={EditIcon}
              calendarIconDark={EditDarkIcon}
              disabled={isOldNested || editingInCategory !== category}
              optionFontWeight={isOldNested ? 400 : 600}
            />
          </div>
        </td>
        <td>
          {' '}
          <div className="update-description-area">
            <Selector
              clearable
              hideUnderline
              placeholder="--"
              className="responsible-dropdown"
              options={priorityOptions}
              value={priority}
              onChange={(option) => {
                handleTableNestedUpdateChange(row, update, {
                  ...nu,
                  priority: option && option.value,
                });
              }}
              disabled={isOldNested || editingInCategory !== category}
              optionFontWeight={isOldNested ? 400 : 600}
            />
          </div>
        </td>
        <td>
          <Selector
            clearable
            hideUnderline
            placeholder="--"
            className="responsible-dropdown"
            options={statusOptions}
            value={status}
            onChange={(option) => {
              handleTableNestedUpdateChange(row, update, {
                ...nu,
                status: option && option.value,
              });
            }}
            disabled={isOldNested || editingInCategory !== category}
            optionFontWeight={isOldNested ? 400 : 600}
          />
        </td>
        <td></td>
      </tr>
    );
  };
  const renderActionItemUpdate = (row, update, category) => {
    const {
      description,
      status,
      dueDate,
      responsible,
      responsibleCompany,
      createdAt,
      priority,
      isDiscarded,
      nestedUpdates,
    } = update;
    if (isDiscarded) return;
    const isOldUpdate = Meeting.isOldUpdate(oldItems, update);
    const hasNestedUpdates = nestedUpdates && nestedUpdates.length > 0;

    return (
      <>
        <tr className="nested-row">
          <td>{actionItemsUpdatesActions(row, update)}</td>
          <td>
            <div className="update-description-area-description">
              <div className="update-creation-date">
                {createdAt
                  ? `${moment(createdAt).format('MM/DD/YYYY')} `
                  : `${moment().format('MM/DD/YYYY')} `}
              </div>
              <TextareaAutosizeWrapper
                maxRows={2}
                placeholder={'Description'}
                value={description}
                onChange={(desc) =>
                  handleUpdateDescriptionChange(desc, row, update)
                }
                className="smaller-description-field"
                onBlur={() => handleBlurUpdate(row)}
                disabled={isOldUpdate || editingInCategory !== category}
                style={isOldUpdate ? {} : { fontWeight: 600 }}
              />
            </div>
          </td>
          <td>
            <Selector
              clearable
              hideUnderline
              placeholder="--"
              className="responsible-dropdown"
              options={getResponsibleOptions()}
              value={{
                mainValue: {
                  value:
                    responsibleCompany &&
                    (responsibleCompany.id || responsibleCompany.value),
                  label:
                    responsibleCompany &&
                    (responsibleCompany.name || responsibleCompany.label),
                },
                subValue: responsible
                  ? {
                      value:
                        responsible &&
                        (responsible.id || responsibleCompany.value),
                      label:
                        responsible &&
                        (responsible.fullName || responsible.label),
                    }
                  : null,
              }}
              onChange={({ mainValue: rc, subValue: r }) => {
                handleResponsibleUpdateChange(row, responsible, rc, update);
              }}
              disabled={isOldUpdate || editingInCategory !== category}
              optionFontWeight={isOldUpdate ? 400 : 600}
            />
          </td>
          <td>
            <DatePicker
              clearable
              hideUnderline
              value={dueDate}
              placeholder={'--'}
              onChange={(due) => {
                handleTableUpdateChange(row, { ...update, dueDate: due });
              }}
              className={'action-item-datetime-picker'}
              calendarIcon={EditIcon}
              calendarIconDark={EditDarkIcon}
              disabled={isOldUpdate || editingInCategory !== category}
              optionFontWeight={isOldUpdate ? 400 : 600}
            />
          </td>
          <td>
            <Selector
              clearable
              hideUnderline
              placeholder="--"
              className="responsible-dropdown"
              options={priorityOptions}
              value={priority}
              onChange={(option) => {
                handleTableUpdateChange(row, {
                  ...update,
                  priority: option && option.value,
                });
              }}
              disabled={isOldUpdate || editingInCategory !== category}
              optionFontWeight={isOldUpdate ? 400 : 600}
            />
          </td>
          <td>
            <Selector
              clearable
              hideUnderline
              placeholder="--"
              className="responsible-dropdown"
              options={statusOptions}
              value={status}
              onChange={(option) => {
                handleTableUpdateChange(row, {
                  ...update,
                  status: option && option.value,
                });
              }}
              disabled={isOldUpdate || editingInCategory !== category}
              optionFontWeight={isOldUpdate ? 400 : 600}
            />
          </td>
          <td></td>
        </tr>
        {hasNestedUpdates &&
          nestedUpdates.map((nu) =>
            renderNestedUpdate(row, update, nu, category)
          )}
      </>
    );
  };

  const renderActionItem = (row, provided, snapshot, tableCategory) => {
    const isNew = !row.id;
    const category = row.category || tableCategory;
    const newItemNo =
      row.stringifiedActionNo || instanceForm.meetingsActionItems.length + 1;
    const newInstanceFormNumber = form.meetingsInstances.length + 1;
    const instanceFormNumber =
      instanceForm.instance_number || newInstanceFormNumber;
    const newItemNumber =
      (instanceForm.formattedNumber ||
        Meeting.numberFormatting(instanceFormNumber)) +
      '.' +
      Meeting.numberFormatting(newItemNo);
    const isOldItem = Meeting.isOldItem(oldItems, row);

    const actionItemActions = (row) => {
      const handleChange = (option) =>
        option.value === 'add_remark'
          ? handleAddResolvedRemark(row)
          : handleResolveItem(row);
      return (
        <div className="action-item-actions-big-container">
          {row.id &&
            newMeetingsPermissions.canAddUpdatesAndResolve(
              currentUser,
              instanceForm,
              form.status === 'archived'
            ) && (
              <IconButton onClick={() => handleAddItemUpdate(row)}>
                <AddBoxIcon className="action-items-icons" />
              </IconButton>
            )}
          {instanceForm.isAgenda && (
            <IconButton onClick={() => handleAddItemNote(row)}>
              <EditNoteIcon className="action-items-icons" />
            </IconButton>
          )}
          {MeetingsPermissions.canResolveActionItem(
            instanceForm,
            row,
            form.status === 'archived'
          ) &&
            newMeetingsPermissions.canAddUpdatesAndResolve(
              currentUser,
              instanceForm,
              form.status === 'archived'
            ) && (
              <DropdownMenuWithIcon
                renderIcon
                icon={() => {
                  return <DoneIcon className="dropdown-icon-resolve" />;
                }}
                options={[
                  { value: 'add_remark', label: 'Add Remark' },
                  { value: 'no_remark', label: 'No Remark' },
                ]}
                onChange={(option) => handleChange(option)}
              />
            )}
          {typeof row.id === 'string' &&
            row.createdOnInstanceId === instanceForm.id && (
              <IconButton
                className="action-item-actions-big-delete-button"
                onClick={() => handleDeleteItem(row)}
              >
                <img
                  alt="delete-action-item"
                  src={darkMode ? deleteDarkSvg : deleteSvg}
                />
              </IconButton>
            )}
          {
            <IconButton
              className="search-icon-reposition"
              onClick={() => handleOpenActionItemHistoryModal(row)}
            >
              <img
                alt="action-item-history"
                src={
                  darkMode ? documentSearchIconDarkSvg : documentSearchIconSvg
                }
                className="search-icon-resize"
              />
            </IconButton>
          }
        </div>
      );
    };

    const responsibleSelectorValues = {
      mainValue: {
        value:
          row.responsibleCompany &&
          (row.responsibleCompany.id || row.responsibleCompany.value),
        label:
          row.responsibleCompany &&
          (row.responsibleCompany.name || row.responsibleCompany.label),
      },
      subValue: row.responsible
        ? {
            value:
              row.responsible && (row.responsible.id || row.responsible.value),
            label:
              row.responsible &&
              (row.responsible.fullName || row.responsible.label),
          }
        : null,
    };
    const hasUpdates =
      row.actionItemsUpdates && row.actionItemsUpdates.length > 0;
    const hasNotes =
      row.meetingsActionItemsNotes && row.meetingsActionItemsNotes.length > 0;
    const { meetingsActionItemsNotes } = row;
    return (
      <>
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            backgroundColor: snapshot.isDragging ? 'lightgreen' : 'transparent',
            border: '0px solid black',
            maxHeight: 40,
          }}
          tabIndex={-1}
          key={row.id || 'new'}
        >
          <td className="main-row">
            <div className="drag-drop-icon-action-items-table">
              <img
                src={darkMode ? dragDropIconDark : dragDropIcon}
                alt="drag and drop"
              />
              <div>{newItemNumber}</div>
            </div>
          </td>
          <td className="main-row">
            <TextareaAutosizeWrapper
              maxRows={2}
              placeholder={'Description'}
              value={row.description}
              onChange={(description) =>
                handleDescriptionChange(row, category, description, isNew)
              }
              className="action-item-text-area"
              disabled={isOldItem || editingInCategory !== category}
              style={isOldItem ? {} : { fontWeight: 600 }}
            />
            {hasNotes &&
              meetingsActionItemsNotes.map((note) => (
                <TextareaAutosizeWrapper
                  maxRows={2}
                  placeholder={'Notes'}
                  value={note.notes}
                  onChange={(notes) => handleNoteChange(notes, row, note)}
                  className="action-item-text-area"
                  onBlur={() => handleBlurUpdate(row)}
                  disabled={editingInCategory !== category}
                  style={isOldItem ? {} : { fontWeight: 600 }}
                />
              ))}
          </td>
          <td className="main-row">
            <Selector
              clearable
              hideUnderline
              placeholder="--"
              className="responsible-dropdown"
              options={getResponsibleOptions()}
              value={responsibleSelectorValues}
              onChange={({
                mainValue: responsibleCompany,
                subValue: responsible,
              }) => {
                handleResponsibleChange(row, responsible, responsibleCompany);
              }}
              disabled={isOldItem || editingInCategory !== category}
              optionFontWeight={isOldItem ? 400 : 600}
            />
          </td>
          <td className="main-row">
            {
              <DatePicker
                clearable
                hideUnderline
                value={row.dueDate}
                placeholder={'--'}
                onChange={(dueDate) => {
                  handleTableChange(row, category, { dueDate }, isNew);
                }}
                className={'action-item-datetime-picker'}
                calendarIcon={EditIcon}
                calendarIconDark={EditDarkIcon}
                disabled={isOldItem || editingInCategory !== category}
                optionFontWeight={isOldItem ? 400 : 600}
              />
            }
          </td>
          <td className="main-row">
            {
              <Selector
                clearable
                hideUnderline
                placeholder="--"
                className="responsible-dropdown"
                options={priorityOptions}
                value={row.priority}
                onChange={(option) => {
                  handleTableChange(
                    row,
                    category,
                    { priority: option && option.value },
                    isNew
                  );
                }}
                disabled={isOldItem || editingInCategory !== category}
                optionFontWeight={isOldItem ? 400 : 600}
              />
            }
          </td>
          <td className="main-row">
            <Selector
              clearable
              hideUnderline
              placeholder="--"
              className="status-dropdown"
              options={statusOptions}
              value={row.status}
              onChange={(option) => {
                handleTableChange(
                  row,
                  category,
                  { status: option && option.value },
                  isNew
                );
              }}
              disabled={isOldItem || editingInCategory !== category}
              optionFontWeight={isOldItem ? 400 : 600}
            />
          </td>
          <td className="main-row">{actionItemActions(row)}</td>
        </tr>
        {hasUpdates &&
          row.actionItemsUpdates.map((update) =>
            renderActionItemUpdate(row, update, category)
          )}
      </>
    );
  };

  const AddActionItemButton = (category) => {
    if (
      !newMeetingsPermissions.canEditAttendance(
        currentUser,
        instanceForm,
        form.status === 'archived'
      )
    )
      return null;
    const hasCategory = category !== 'nocategory';
    return (
      <Button
        type={BUTTON_TYPES.LINK}
        icon={BUTTON_ICONS.PLUS}
        label={hasCategory ? 'Add Item' : 'Add Item without category'}
        onClick={() => handleAddItem(category)}
      />
    );
  };
  const categoryTitle = (categoryTitle) => {
    if (categoryTitle === 'undefined' || categoryTitle === 'nocategory')
      return <div className="category-title"> </div>;
    return <div className="category-title">{categoryTitle.toUpperCase()}</div>;
  };
  const emptyMessage = 'To add items within a category';
  const emptyMessage2 = 'first add a new category';
  return (
    <React.Fragment>
      <DragDropContext onDragEnd={onDragEnd}>
        <Table
          className={`action-item-table ${className || ''} ${
            darkMode ? 'dark-mode' : ''
          }`}
        >
          {
            <tbody>
              {Object.entries(categorizedItems).length === 0 && (
                <>
                  {!isCreatingNewItem && (
                    <div className="add-action-item-button-container">
                      {AddActionItemButton('nocategory')}
                    </div>
                  )}
                  <tr>
                    <td className="td-empty-table" colSpan={7}>
                      {emptyMessage}
                    </td>
                  </tr>
                  <tr>
                    <td className="td-empty-table-second" colSpan={7}>
                      {emptyMessage2}
                    </td>
                  </tr>
                </>
              )}
              {Object.entries(categorizedItems).length > 0 &&
                Object.entries(categorizedItems).map(
                  ([category, items], index) => (
                    <Droppable
                      key={`${category ? category : 'nocategory'}-key-${index}`}
                      droppableId={`${
                        category ? category : 'nocategory'
                      }-${index}`}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="action-items-container"
                          style={{
                            backgroundColor: snapshot.isDraggingOver
                              ? 'lightblue'
                              : 'transparent',
                          }}
                        >
                          <div className="category-title-and-buttons-container">
                            {categoryTitle(category)}
                            <div className="category-title-buttons">
                              {newMeetingsPermissions.canEditInstance(
                                currentUser,
                                instanceForm,
                                form.status === 'archived'
                              ) && (
                                <Button
                                  key="edit"
                                  type={BUTTON_TYPES.LINK}
                                  color={BUTTON_COLORS.GREEN}
                                  label={
                                    editingInCategory === category
                                      ? 'Save'
                                      : 'Edit'
                                  }
                                  onClick={() =>
                                    editingInCategory === category
                                      ? handleSetEditModeInCategory('')
                                      : handleSetEditModeInCategory(category)
                                  }
                                />
                              )}
                              <Button
                                key="view-resolved"
                                type={BUTTON_TYPES.LINK}
                                color={BUTTON_COLORS.GREEN}
                                label="View Resolved"
                                onClick={handleOpenResolvedModal}
                              />
                              ,
                            </div>
                          </div>
                          {renderActionItemHeader()}
                          {items.map((item, index) => (
                            <Draggable
                              key={`${item.id}` || 'new-action-item-key'}
                              draggableId={
                                `${item.id}` || 'new-action-item-key'
                              }
                              index={index}
                            >
                              {(provided, snapshot) =>
                                renderActionItem(
                                  item,
                                  provided,
                                  snapshot,
                                  category
                                )
                              }
                            </Draggable>
                          ))}
                          {provided.placeholder}
                          {!isCreatingNewItem && (
                            <div className="add-action-item-button-container">
                              {AddActionItemButton(category)}
                            </div>
                          )}
                        </div>
                      )}
                    </Droppable>
                  )
                )}
            </tbody>
          }
        </Table>
      </DragDropContext>
    </React.Fragment>
  );
};

ActionItemsTable.propTypes = {
  className: PropTypes.string,
  headers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  emptyMessage: PropTypes.string,
  showBottomLine: PropTypes.bool,
  headersOnly: PropTypes.bool,
  actionCells: PropTypes.number,
  onRowSelected: PropTypes.func,
  onGenerateLink: PropTypes.func,
  stickyHeader: PropTypes.bool,
  noRows: PropTypes.bool,
  handleFilterChange: PropTypes.func,
};

ActionItemsTable.defaultProps = {
  headers: [],
  rows: [],
  emptyMessage: 'No Items',
  showBottomLine: false,
  headersOnly: false,
  newView: false,
  stickyHeader: false,
  noRows: false,
  actionCells: 0,
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
    meetingsTitles: getMeetingsTitles(state),
    meetingsTypes: getMeetingsTypes(state),
    meetingsParentsTitles: getMeetingsParentsTitles(state),
    meetingsParentsTypes: getMeetingsParentsTypes(state),
    currentFilters: getProjectNewMeetingsFilters(state),
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    showInstanceForm: getMeetingsInstanceModal(state),
    form: getProjectNewMeetingsForm(state),
    instanceForm: getMeetingsInstanceForm(state),
    actionItemsCategories: getActionItemsCategories(state),
    directory: getDirectory(state),
    actionItems: getActionItems(state),
  };
})(ActionItemsTable);
