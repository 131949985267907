import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loaderEnd, loaderStart } from '../../../actions/loader';
import rotateRight from 'images/rotateRight.svg';
import rotateLeft from 'images/rotateLeft.svg';
import 'react-image-lightbox/style.css';

class FullScreenImageViewer extends Component {
  static propTypes = {
    show: PropTypes.bool,
    url: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    readOnly: PropTypes.bool,
    onImageRotate: PropTypes.func,
    isRotating: PropTypes.func,
  };

  static defaultProps = {
    show: false,
    readOnly: true,
    onImageRotate: () => {},
    isRotating: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      hasRotated: false,
    };
  }
  componentDidUpdate(prevProps) {
    const isRotatingNow = this.props.isRotating;
    const wasRotating = prevProps.isRotating;
    if (!wasRotating && isRotatingNow) this.props.actions.loaderStart();
    if (wasRotating && !isRotatingNow) this.props.actions.loaderEnd();
  }
  handleClose = (event) => {
    if (this.props.onClose) {
      this.setState({ hasRotated: false });
      this.props.onClose();
    }
  };

  rotateButton = ({ rotationType }) => {
    const handleRotation = this.props.onImageRotate;
    const handleOnClick = async () => {
      const degrees = rotationType === 'right' ? 90 : -90;
      await handleRotation(degrees);
      this.setState({ hasRotated: true });
    };

    return (
      <img
        src={rotationType === 'right' ? rotateRight : rotateLeft}
        alt="rotation-button-lightbox"
        className="rotation-button-image-viewer"
        onClick={() => handleOnClick()}
      />
    );
  };

  renderToolbarButtons = () => {
    if (this.props.readOnly) return [];
    return [
      this.rotateButton({ rotationType: 'left' }),
      this.rotateButton({ rotationType: 'right' }),
    ];
  };

  render() {
    if (this.props.show) {
      return (
        <>
          <Lightbox
            mainSrc={this.props.url}
            reactModalStyle={{
              overlay: {
                zIndex: 1999,
              },
            }}
            onCloseRequest={this.handleClose}
            toolbarButtons={this.renderToolbarButtons()}
            closeLabel={this.state.hasRotated ? 'Save and Close' : 'Close'}
          />
        </>
      );
    } else {
      return <div />;
    }
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ loaderStart, loaderEnd }, dispatch),
  };
};
export default connect(() => {}, mapDispatchToProps)(FullScreenImageViewer);
