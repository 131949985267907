import {
  RECEIPTS_ADD,
  RECEIPTS_REMOVE,
  RECEIPTS_SET,
  RECEIPTS_UPDATE,
} from 'components/admin/work-orders/details/receipts/store/action-types';
import { WORK_ORDERS_DETAILS_STATE_INIT } from 'actions/admin/work-orders/action-types';

const initialState = {
  receipts: [],
};

export default function receiptsDataReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIPTS_ADD:
      return {
        ...state,
        receipts: state.receipts.concat([action.payload]),
      };

    case RECEIPTS_REMOVE:
      return {
        ...state,
        receipts: state.receipts.filter(
          (receipt) => receipt.id !== action.payload
        ),
      };

    case RECEIPTS_SET:
      return {
        ...state,
        receipts: action.payload,
      };

    case RECEIPTS_UPDATE:
      return {
        ...state,
        receipts: state.receipts.map((receipt) => {
          if (receipt.id === action.payload.id) {
            return action.payload;
          }
          return receipt;
        }),
      };

    case WORK_ORDERS_DETAILS_STATE_INIT:
      return {
        ...state,
        receipts: initialState.receipts,
      };

    default:
      return state;
  }
}
