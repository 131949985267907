import {
  WORK_ORDERS_CREATION_WORKING_HOURS_INVALID,
  WORK_ORDERS_CREATION_WORKING_HOURS_VALID,
} from '../../../../actions/admin/work-orders/action-types';

const initialState = {
  validationError: '',
};

export default function workingHoursUiReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_CREATION_WORKING_HOURS_VALID:
      return {
        ...state,
        validationError: '',
      };

    case WORK_ORDERS_CREATION_WORKING_HOURS_INVALID:
      return {
        ...state,
        validationError: action.payload,
      };

    default:
      return state;
  }
}
