import { showErrorMessage } from 'actions/messages';
import { chatsApi } from 'services/api/chats-api';
import { CHAT_ACTIVITY_LOAD } from 'components/admin/chats/store/action-types';

export function chatActivityRequestSuccess(chatActivity) {
  return {
    type: CHAT_ACTIVITY_LOAD,
    payload: chatActivity,
  };
}

export function chatActivityRequest(filters) {
  return (dispatch) => {
    return chatsApi.getChatActivity(filters).then((response) => {
      if (response.ok) {
        dispatch(
          chatActivityRequestSuccess({ ...response, currentPage: filters.page })
        );
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function markRead(noteId, read) {
  return (dispatch) => {
    return chatsApi.markRead(noteId, read).then((response) => {
      if (!response.ok) {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
  };
}
