import React from 'react';
import { connect } from 'react-redux';

import { setFormMeetingInstance } from './store/actions';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getDarkMode } from 'selectors/theme';
import { getCurrentUser } from 'selectors/authentication';
import {
  getMeetingsInstanceForm,
  getProjectNewMeetingsForm,
} from './store/selectors';
import './MeetingsInstanceForm.css';
import newMeetingsPermissions from '../../../../../permissions/new-meetings-permissions';

const MeetingsInstanceFormNotepad = (props) => {
  const { dispatch, instanceForm, darkMode, currentUser, form } = props;

  const notesObject = instanceForm && instanceForm.meetingsInstancesNotes;

  const notesValue =
    instanceForm &&
    instanceForm.meetingsInstancesNotes &&
    instanceForm.meetingsInstancesNotes.notes;
  const handleTextChange = (text) => {
    let editedNotes = {
      ...notesObject,
      notes: text,
      meetingsParentId: instanceForm.meetingsParentId,
    };
    if (!(notesObject && notesObject.createdAt)) {
      editedNotes = { ...editedNotes }; //In backend make instanceForm only get the notes for hte user
    }
    if (instanceForm.id)
      editedNotes = { ...editedNotes, meetingsInstanceId: instanceForm.id };
    if (!(notesObject && notesObject.createdById))
      editedNotes = { ...editedNotes, createdById: currentUser.id };

    dispatch(
      setFormMeetingInstance({
        ...instanceForm,
        meetingsInstancesNotes: editedNotes,
      })
    );
  };
  const textAreaStyle = {
    flex: 1,
    resize: 'none',
    padding: '10px',
    border: 'none',
    outline: 'none',
    background: darkMode ? '#21242f' : 'white',
    color: darkMode ? 'white' : 'black',
    width: '100%',
    height: '100%',
    minHeight: '800px',
  };
  return (
    <React.Fragment>
      <div className="notepad-form-container">
        <textarea
          value={notesValue}
          onChange={(e) => handleTextChange(e.target.value)}
          style={textAreaStyle}
          readOnly={
            !newMeetingsPermissions.canEditInstance(
              currentUser,
              instanceForm,
              form.status === 'archived'
            )
          }
        />
      </div>
    </React.Fragment>
  );
};

export default connect((state) => ({
  currentProject: getCurrentProject(state),
  instanceForm: getMeetingsInstanceForm(state),
  darkMode: getDarkMode(state),
  currentUser: getCurrentUser(state),
  form: getProjectNewMeetingsForm(state),
}))(MeetingsInstanceFormNotepad);
