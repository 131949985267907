import {
  WORK_ORDERS_DETAILS_QUOTES_REQUEST_MODAL_CLOSE,
  WORK_ORDERS_DETAILS_QUOTES_REQUEST_MODAL_SHOW,
  WORK_ORDERS_DETAILS_QUOTES_REQUEST_PREVIEW_GO_BACK,
  WORK_ORDERS_DETAILS_QUOTES_REQUEST_PREVIEW_SHOW,
  WORK_ORDERS_DETAILS_QUOTES_REQUEST_SUBMIT_SUCCESS,
} from '../../../../actions/admin/work-orders/action-types';

const initialState = {
  currentView: 'form', // possible values: 'form', 'preview' and 'message'
  showQuotesRequestModal: false,
};

export default function quotesRequestUiReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_DETAILS_QUOTES_REQUEST_MODAL_SHOW:
      return {
        ...state,
        currentView: 'form',
        showQuotesRequestModal: true,
      };

    case WORK_ORDERS_DETAILS_QUOTES_REQUEST_PREVIEW_SHOW:
      return {
        ...state,
        currentView: 'preview',
      };

    case WORK_ORDERS_DETAILS_QUOTES_REQUEST_PREVIEW_GO_BACK:
      return {
        ...state,
        currentView: 'form',
      };

    case WORK_ORDERS_DETAILS_QUOTES_REQUEST_SUBMIT_SUCCESS:
      return {
        ...state,
        currentView: 'message',
      };

    case WORK_ORDERS_DETAILS_QUOTES_REQUEST_MODAL_CLOSE:
      return {
        ...state,
        showQuotesRequestModal: false,
      };

    default:
      return state;
  }
}
