import { createSceneReducer } from 'reducers/reducers-util';
import { combineReducers } from 'redux';
import reportsDataReducer from '../reports/store/reducers.data';
import reportsUiReducer from '../reports/store/reducers.ui';

export default createSceneReducer(
  combineReducers({
    reports: createSceneReducer(reportsDataReducer, reportsUiReducer),
  })
);
