import { showErrorMessage } from 'actions/messages';
import { projectPostsApi } from 'services/api/project-posts-api';

export const toggleProjectPostLike = (projectId, postId, value = true) => {
  return (dispatch) => {
    return projectPostsApi.like(projectId, postId, value).then((response) => {
      if (!response.ok) dispatch(showErrorMessage(response.errors));

      return response;
    });
  };
};

export const addProjectPostComment = (projectId, postId, data) => {
  return (dispatch) => {
    return projectPostsApi.comment(projectId, postId, data).then((response) => {
      if (!response.ok) dispatch(showErrorMessage(response.errors));

      return response;
    });
  };
};
