import {
  DOCUMENTATION_SET,
  DOCUMENTATION_COUNT_SET,
  DOCUMENTATION_RESET,
  DIRECTORY_SET,
  ADVANCED_DOCUMENTS_LOADED,
  DOCUMENTATION_PREFIX_SET,
} from 'components/admin/projects/details/project-documentation/store/action-types';

const initialState = {
  documentation: {},
  documentationCount: null,
  advancedDocuments: [],
  isUpdate: false,
};

export default function projectDocumentationDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case DOCUMENTATION_SET:
      return {
        ...state,
        documentation: {
          ...state.documentation,
          [action.payload.category]: action.payload.documentation,
        },
      };

    case DOCUMENTATION_COUNT_SET:
      return {
        ...state,
        documentationCount: action.payload,
      };

    case DOCUMENTATION_RESET:
      return initialState;

    case DIRECTORY_SET:
      return {
        ...state,
        directory: action.payload,
      };

    case DOCUMENTATION_PREFIX_SET:
      return {
        ...state,
        initialPrefix: action.payload,
      };

    case ADVANCED_DOCUMENTS_LOADED:
      return {
        ...state,
        advancedDocuments: action.payload,
        isUpdate: !state.isUpdate,
      };

    default:
      return state;
  }
}
