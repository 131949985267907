export const CONTRACTORS_INVOICES_INVOICE_REMOVE =
  'CONTRACTORS_INVOICES_INVOICE_REMOVE';
export const CONTRACTORS_INVOICES_LOAD_SUCCESS =
  'CONTRACTORS_INVOICES_LOAD_SUCCESS';
export const CONTRACTORS_INVOICES_INVOICE_UPDATE =
  'CONTRACTORS_INVOICES_INVOICE_UPDATE';
export const CONTRACTORS_INVOICES_PAGE_CHANGE =
  'CONTRACTORS_INVOICES_PAGE_CHANGE';
export const CONTRACTORS_INVOICES_PAGINATION_INIT =
  'CONTRACTORS_INVOICES_PAGINATION_INIT';
export const CONTRACTORS_INVOICES_SEARCH_TEXT_CHANGE =
  'CONTRACTORS_INVOICES_SEARCH_TEXT_CHANGE';
export const CONTRACTORS_INVOICES_VENDOR_INVOICES_INVOICE_UPDATE =
  'CONTRACTORS_INVOICES_VENDOR_INVOICES_INVOICE_UPDATE';
export const CONTRACTORS_INVOICES_VENDOR_INVOICES_SET =
  'CONTRACTORS_INVOICES_VENDOR_INVOICES_SET';
export const CONTRACTORS_INVOICES_VENDOR_INVOICES_PAGE_CHANGE =
  'CONTRACTORS_INVOICES_VENDOR_INVOICES_PAGE_CHANGE';
export const CONTRACTORS_INVOICES_VENDOR_INVOICES_PAGINATION_INIT =
  'CONTRACTORS_INVOICES_VENDOR_INVOICES_PAGINATION_INIT';
export const CONTRACTORS_INVOICES_SEARCH_FILTERS_INIT =
  'CONTRACTORS_INVOICES_SEARCH_FILTERS_INIT';
export const CONTRACTORS_INVOICES_SEARCH_FILTER_VALUE_CHANGE =
  'CONTRACTORS_INVOICES_SEARCH_FILTER_VALUE_CHANGE';
