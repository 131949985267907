import AuthenticationStorage from 'services/authentication-storage.js';

const LOCAL_STORAGE_KEY_SHOW_DASHBOARD = 'spkl_show_dashboard_';
export const persistShowDashboard = (show, userId) => {
  AuthenticationStorage.setLocalStorageItem(
    LOCAL_STORAGE_KEY_SHOW_DASHBOARD + userId,
    show
  );
};
export const getPersistedShowDashboard = (userId) => {
  return AuthenticationStorage.retrieveLocalStorageItem(
    LOCAL_STORAGE_KEY_SHOW_DASHBOARD + userId
  );
};

const LOCAL_STORAGE_KEY_CLOSE_SCHEDULE_CATEGORY =
  'spkl_show_schedule_category_';
export const persistCloseScheduleCategory = (userId, categoryId) => {
  AuthenticationStorage.setLocalStorageItem(
    LOCAL_STORAGE_KEY_CLOSE_SCHEDULE_CATEGORY + userId + '_' + categoryId,
    true
  );
};
export const removeCloseScheduleCategory = (userId, categoryId) => {
  AuthenticationStorage.removeLocalStorageItem(
    LOCAL_STORAGE_KEY_CLOSE_SCHEDULE_CATEGORY + userId + '_' + categoryId
  );
};
export const getPersistedCloseScheduleCategory = (userId, categoryId) => {
  return AuthenticationStorage.retrieveLocalStorageItem(
    LOCAL_STORAGE_KEY_CLOSE_SCHEDULE_CATEGORY + userId + '_' + categoryId
  );
};
