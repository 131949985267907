import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImageUpload from 'components/shared/image-upload/ImageUpload';
import Project from 'domain/project';
import { imageExtensions } from 'services/utils/files-util';
import DatePicker from 'components/shared/date-picker/DatePicker';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import RadioButton from 'components/shared/radio-button/RadioButton';
import { setProjectReportAttr } from 'components/admin/projects/details/project-daily-report-log/store/actions';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getProjectReportValue } from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import { getDarkMode } from 'selectors/theme';
import './ReportsForm.css';

const selectTypesArray = [
  { value: 'primary_contributors', label: 'Primary Contributors' },
  { value: 'all_contributors', label: 'All Contributors' },
  { value: 'nonee', label: 'None' },
];

const selectDetailssArray = [
  { value: 'company_name', label: 'Company Name' },
  { value: 'company_details', label: 'Company Name, Address, & Users' },
  { value: 'full_project_directory', label: 'All Contact Details' },
];

class ReportInformationForm extends Component {
  componentDidMount() {
    this.loadDirectory();
  }

  componentDidUpdate() {
    const { report } = this.props;
    const recipients = this.recipientOptions();
    const isUpdateRecipients =
      report.recipients &&
      report.recipients.filter((item) =>
        recipients.find((el) => el.id === item[0])
      );
    if (
      report.recipients &&
      report.recipients.length !== isUpdateRecipients.length
    ) {
      this.handleAttributeChange('recipients', isUpdateRecipients);
    }
  }

  handleDropdownAttributeChange = (value) => {
    this.setState({
      recipients: value,
    });
  };

  handleAttributeChange = (attr, value) => {
    this.props.dispatch(setProjectReportAttr(attr, value));
  };

  loadDirectory = () => {
    const { project, directory, dispatch } = this.props;
    if (!directory || directory.project_id !== project.id) {
      dispatch(loadDirectoryRequest(project.id));
    }
  };

  recipientOptions = () => {
    const { permissions } = this.props;
    permissions.push('oar');
    const recipients = Project.filterReportRecipients({
      directory: this.props.directory,
      permissions,
      type: 'name',
    });
    return recipients;
  };

  getArrayAttribute = (attr) => {
    const { report } = this.props;
    if (report && report[attr] && report[attr].length > 0) {
      return report[attr];
    }
    return [];
  };

  setImages = (files) => {
    const images = [...this.getArrayAttribute('cover_image')];
    files.forEach(function (val) {
      images.push({
        url: val.url || URL.createObjectURL(val),
        file: val,
      });
    });

    this.handleAttributeChange('cover_image', images);
  };

  removeImage = (index) => {
    const images = [...this.getArrayAttribute('cover_image')];
    const deletedImages = [...this.getArrayAttribute('deleted_cover_image')];
    const deletedImage = images.splice(index, 1);
    deletedImages.push(deletedImage[0]);
    this.handleAttributeChange('cover_image', images);
    this.handleAttributeChange('deleted_cover_image', deletedImages);
  };

  render() {
    const { report } = this.props;
    return (
      <div>
        <div className="row-block">
          <div className="report-form-block">
            <label className="report-form-block-label">Report Date</label>

            <div className="item-block">
              <DatePicker
                placeholder="Report Date"
                onChange={(value) => this.handleAttributeChange('date', value)}
                value={report.date}
              />
            </div>
          </div>
          <div className="separate-block-in-row" />
          <div className="report-form-block">
            <label className="report-form-block-label">Report Image</label>
            <div className="image-upload-container item-block">
              <div className="cover-image">
                <ImageUpload
                  multiple={false}
                  extensions={imageExtensions}
                  onUpload={(files) => this.setImages(files)}
                  onRemove={(index) => this.removeImage(index)}
                  images={report.cover_image || []}
                  visibleButton={
                    this.getArrayAttribute('cover_image').length === 0
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="report-form-block">
          <label className="report-form-block-label">Report Recipients</label>
          <div className="multiple-dropdown-container">
            <MultipleDropdown
              options={this.recipientOptions() || []}
              currentValues={report.recipients || []}
              placeholder="Report Recipients"
              onChange={(value) =>
                this.handleAttributeChange('recipients', value)
              }
            />
          </div>
        </div>
        <div className="report-form-block">
          <label className="report-form-block-label">Project Directory</label>
          <div className="row-block">
            <div className="item-block">
              <label className="report-form-block-label radiobox-group-label">
                Select Types:
              </label>
              <RadioButton
                options={selectTypesArray}
                orientation="vertical"
                value={report.directory_details_type}
                onChange={(value) =>
                  this.handleAttributeChange('directory_details_type', value)
                }
              />
            </div>
            {report.directory_details_type !== 'nonee' && (
              <div className="item-block">
                <label className="report-form-block-label radiobox-group-label">
                  Select Details:
                </label>
                <RadioButton
                  options={selectDetailssArray}
                  orientation="vertical"
                  value={report.directory_details}
                  onChange={(value) =>
                    this.handleAttributeChange('directory_details', value)
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    darkMode: getDarkMode(state),
    report: getProjectReportValue(state),
    directory: getDirectory(state),
    project: getCurrentProject(state),
    currentUser: getCurrentUser(state),
  }),
  null
)(ReportInformationForm);
