/* eslint-disable default-case */
import axios from 'axios';
import BaseApi from './base-api';

class ProjectPostsApi extends BaseApi {
  like(projectId, postId, value) {
    const url = `${this.baseUrl}/projects/${projectId}/project_posts/${postId}/like`;

    const data = new FormData();
    data.append('project_post[add]', value);

    return axios
      .post(url, data)
      .then(() => ({ ok: true }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  comment(projectId, postId, commentData) {
    const url = `${this.baseUrl}/projects/${projectId}/project_posts/${postId}/comment`;

    const data = new FormData();
    data.append('project_post[comment]', commentData.comment);
    if (commentData.reply)
      data.append('project_post[reply_id]', commentData.reply);

    return axios
      .post(url, data)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }
}

export const projectPostsApi = new ProjectPostsApi();
