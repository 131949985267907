import React from 'react';
import Selector from 'components/shared/selector/Selector';

/**
 * Wrapper for Dropdown field used inside redux-form.
 * @param props
 * @return {*}
 * @constructor
 */
export default function FormDropDown(props) {
  return (
    <Selector
      valid={!props.meta.error || !props.meta.submitFailed}
      value={props.input.value}
      onChange={(option) => props.input.onChange(option ? option.value : '')}
      {...props}
    />
  );
}
