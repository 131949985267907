import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';

class ClientsApi extends BaseApi {
  /**
   * Create account
   * @param {object} json
   * @return {{ok: boolean, errors: []}}
   */
  createAccount(json) {
    const data = {
      client: {
        name: json.companyName,
        contact_attributes: {
          first_name: json.firstName,
          last_name: json.lastName,
          email: json.email,
          phone_number: json.phoneNumber,
          password: json.password,
          password_confirmation: json.passwordConfirmation,
        },
        location_attributes: {
          street_address_1: json.address1,
          street_address_2: json.address2,
          city: json.city,
          state: json.state,
          zip: json.zip,
        },
      },
      confirm_success_url: json.confirmSuccessUrl,
    };

    return axios
      .post(`${this.baseUrl}/clients`, data)
      .then((response) => {
        return {
          ok: true,
          errors: [],
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  resendInvitation(email, confirmSuccessUrl) {
    return axios
      .post(this.baseUrl + '/user_confirmations', {
        email: email,
        confirm_success_url: confirmSuccessUrl,
      })
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  _makeClientParams(data, skipInvitation) {
    let params = {
      client: {
        name: data.companyName,
        contact_attributes: {
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
          phone_number: data.phoneNumber,
        },
      },
    };

    if (skipInvitation) {
      params['skip_invitation'] = true;
    } else {
      params['client']['location_attributes'] = {
        street_address_1: data.streetAddress1,
        city: data.city,
        state: data.state,
        zip: data.zip,
      };

      if (data.streetAddress2 !== '') {
        params['client']['location_attributes']['street_address_2'] =
          data.streetAddress2;
      }
    }

    return params;
  }

  inviteClient(data, skipInvitation) {
    const params = this._makeClientParams(data, skipInvitation);

    return axios
      .post(`${this.baseUrl}/clients/invite`, params)
      .then((response) => {
        return {
          ok: true,
          client: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  inviteExistent(clientId) {
    return axios
      .post(`${this.baseUrl}/clients/${clientId}/invite_existent`, {})
      .then((response) => {
        return {
          ok: true,
          client: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  inviteUsers(clientId, invitations) {
    let params = {
      users: invitations.map((element) => {
        let result = {
          first_name: element.firstName,
          last_name: element.lastName,
          email: element.email,
          role_ids: element.permissions.map((element) => {
            return element.id;
          }),
        };

        if (element.phoneNumber !== '') {
          result['phone_number'] = element.phoneNumber;
        }

        if (element.title !== '') {
          result['title'] = element.title;
        }

        if (element.buildingsId !== null) {
          result['building_ids'] = element.buildingsId;
        }

        return result;
      }),
    };

    return axios
      .post(`${this.baseUrl}/clients/${clientId}/invite_users`, params)
      .then((response) => {
        return {
          ok: true,
          users: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getClients(page, label) {
    let filter = page ? `page=${page}` : '';

    filter += label ? `&by_company_name=${label}` : '';

    return axios
      .get(`${this.baseUrl}/clients?${filter}`)
      .then((response) => {
        return {
          ok: true,
          clients: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getClient(clientId) {
    return axios
      .get(`${this.baseUrl}/clients/${clientId}`)
      .then((response) => {
        return {
          ok: true,
          client: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getWorkOrders(clientId, page, workOrderStatus) {
    let filter = '';
    if (workOrderStatus !== undefined) {
      filter = `&${workOrderStatus.status}=true`;
    }

    return axios
      .get(
        `${this.baseUrl}/clients/${clientId}/work_orders?page=${
          page !== undefined ? page : 1
        }${filter}`
      )
      .then((response) => {
        return {
          ok: true,
          workOrders: {
            content: response.data,
            total: numeral(response.headers['total']).value(),
            totalComplete: numeral(response.headers['total-complete']).value(),
            totalDraft: numeral(response.headers['total-draft']).value(),
            totalInProgress: numeral(
              response.headers['total-in_progress']
            ).value(),
            totalPending: numeral(response.headers['total-pending']).value(),
            totalPlm: numeral(response.headers['total-plm']).value(),
            totalWoa: numeral(response.headers['total-woa']).value(),
            totalClosed: numeral(response.headers['total-closed']).value(),
            totalDeclined: numeral(response.headers['total-declined']).value(),
            pageSize: numeral(response.headers['per-page']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getClientProjects(clientId, page, status) {
    return axios
      .get(
        `${this.baseUrl}/clients/${clientId}/projects?page=${
          page !== undefined ? page : 1
        }${status ? `&${status}=true` : ''}`
      )
      .then((response) => {
        return {
          ok: true,
          projects: {
            content: response.data,
            total: numeral(response.headers['total']).value(),
            totalPending: numeral(response.headers['total-pending']).value(),
            totalInProgress: numeral(
              response.headers['total-in_progress']
            ).value(),
            totalComplete: numeral(response.headers['total-complete']).value(),
            pageSize: numeral(response.headers['per-page']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getInvoices(clientId, page, invoiceStatus) {
    let filter = '';
    if (invoiceStatus !== undefined) {
      filter = `&${invoiceStatus}=true`;
    }

    return axios
      .get(
        `${this.baseUrl}/clients/${clientId}/invoices?page=${
          page !== undefined ? page : 1
        }${filter}`
      )
      .then((response) => {
        return {
          ok: true,
          invoices: {
            content: response.data,
            total: numeral(response.headers['total']).value(),
            pageSize: numeral(response.headers['per-page']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateClient(id, client) {
    let data = new FormData();
    data.append('client[name]', client.name);
    data.append('client[contact_attributes][first_name]', client.firstName);
    data.append('client[contact_attributes][last_name]', client.lastName);
    data.append('client[contact_attributes][email]', client.email);
    data.append('client[contact_attributes][phone_number]', client.phoneNumber);
    data.append(
      'client[contact_attributes][office_phone_number]',
      client.officePhoneNumber
    );
    data.append(
      'client[contact_attributes][office_fax_number]',
      client.officeFaxNumber
    );
    data.append(
      'client[location_attributes][street_address_1]',
      client.streetAddress
    );
    data.append(
      'client[location_attributes][street_address_2]',
      client.unitNumber
    );
    data.append('client[location_attributes][city]', client.city);
    data.append('client[location_attributes][state]', client.state);
    data.append('client[location_attributes][zip]', client.zipCode);

    const clientLogo = client.clientLogo;
    const imageLogo = clientLogo && clientLogo.image;
    if (imageLogo && imageLogo.name && imageLogo.preview) {
      data.append('client[logo_attributes][image]', imageLogo);
    } else if (clientLogo && clientLogo.id && clientLogo.url === '') {
      data.append('client[logo_attributes][id]', client.clientLogo.id);
      data.append('client[logo_attributes][_destroy]', true);
    }

    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .put(`${this.baseUrl}/clients/${id}`, data, headers)
      .then((response) => {
        return {
          ok: true,
          client: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getUsers(clientId) {
    return axios
      .get(`${this.baseUrl}/clients/${clientId}/users`)
      .then((response) => {
        return {
          ok: true,
          users: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getPurchaseOrders(clientId, page = 1) {
    return axios
      .get(`${this.baseUrl}/clients/${clientId}/purchase_orders?page=${page}`)
      .then((response) => {
        return {
          ok: true,
          purchaseOrders: {
            content: response.data,
            total: numeral(response.headers['total']).value(),
            pageSize: numeral(response.headers['per-page']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  searchByCompanyName(value = '') {
    return axios
      .get(`${this.baseUrl}//clients?by_company_name=${value}`, {
        loadingDisabled: true,
      })
      .then((response) => {
        return {
          ok: true,
          clients: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateNotes(clientId, text = '', reminder = null) {
    const data = {
      client: {
        note: text,
        note_reminder_date: reminder,
      },
    };
    return axios
      .put(`${this.baseUrl}/clients/${clientId}`, data)
      .then((response) => {
        return {
          ok: true,
          client: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export let clientsApi = new ClientsApi();
export default ClientsApi = new ClientsApi();
