import {
  CHANGE_ORDERS_LOAD_SUCCESS,
  CHANGE_ORDERS_ADD,
  CHANGE_ORDERS_UPDATE,
  CHANGE_ORDER_START_DATE_UPDATE,
  CHANGE_ORDER_COMPLETION_DATE_UPDATE,
  CHANGE_ORDER_VALUES_UPDATE,
  CHANGE_ORDER_APPROVAL_MODAL_CLOSE,
  CHANGE_ORDER_APPROVAL_SELECT,
  CHANGE_ORDER_USERS_LOAD_SUCCESS,
} from 'components/admin/work-orders/details/change-orders/store/action-types';

const initialState = {
  changeOrders: [],
  startDate: null,
  completionDate: null,
  valuesByQuoteId: {},
  selectedChangeOrderId: null,
  selectedChangeOrderNumber: null,
  users: [],
};

export default function changeOrdersDataReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_ORDERS_LOAD_SUCCESS:
      return {
        ...state,
        changeOrders: action.payload,
      };

    case CHANGE_ORDERS_ADD:
      return {
        ...state,
        changeOrders: [...state.changeOrders, action.payload],
      };

    case CHANGE_ORDERS_UPDATE:
      return {
        ...state,
        changeOrders: state.changeOrders.map((co) => {
          if (co.id === action.payload.id) {
            return action.payload;
          } else {
            return co;
          }
        }),
      };

    case CHANGE_ORDER_START_DATE_UPDATE:
      return {
        ...state,
        startDate: action.payload,
      };

    case CHANGE_ORDER_COMPLETION_DATE_UPDATE:
      return {
        ...state,
        completionDate: action.payload,
      };

    case CHANGE_ORDER_VALUES_UPDATE:
      return {
        ...state,
        valuesByQuoteId: {
          ...state.valuesByQuoteId,
          [action.payload.quoteId]: {
            ...state.valuesByQuoteId[action.payload.quoteId],
            [action.payload.key]: action.payload.value,
          },
        },
      };

    case CHANGE_ORDER_APPROVAL_MODAL_CLOSE:
      return {
        ...state,
        selectedChangeOrderId: null,
        selectedChangeOrderNumber: null,
      };

    case CHANGE_ORDER_APPROVAL_SELECT:
      return {
        ...state,
        selectedChangeOrderId: action.payload.id,
        selectedChangeOrderNumber: action.payload.number,
      };

    case CHANGE_ORDER_USERS_LOAD_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };

    default:
      return state;
  }
}
