import {
  TASKS_LOAD_SUCCESS,
  TASKS_TRADES_SUCCESS,
} from '../../../actions/admin/tasks/action-types';

const initialState = {
  tasks: null,
  trades: [],
};

export default function tasksDataReducer(state = initialState, action) {
  switch (action.type) {
    case TASKS_LOAD_SUCCESS:
      return {
        ...state,
        tasks: action.payload,
      };

    case TASKS_TRADES_SUCCESS:
      return {
        ...state,
        trades: action.payload,
      };

    default:
      return state;
  }
}
