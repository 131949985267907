import React from 'react';
import { connect } from 'react-redux';

import { ALBUM_FORM_TABS } from 'domain/project-photo';
import ProjectPhotoPermissions from 'permissions/project-photo-permissions';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import FormModal from 'components/shared/modal/FormModal';
import NavigationBar from 'components/shared/navigation/NavigationBar';
import UserPermissionsForm from 'components/shared/user-permissions/UserPermissionsForm';

import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';

import {
  getProjectPhotosForm,
  getProjectPhotosShowAlbumForm,
  getProjectPhotosStartUpload,
} from './store/selectors';
import useProjectPhotosAlbumForm from './ProjectPhotosAlbumForm.hook';
import ProjectPhotosAlbumFormInfo from './ProjectPhotosAlbumFormInfo';
import { Grid } from '@mui/material';
import ProjectPhotosUpload from './ProjectPhotosUpload';
import './ProjectPhotosAlbumFormModal.css';

const ProjectPhotosAlbumFormModal = (props) => {
  const { show, form, startUpload, currentUser, currentProject } = props;
  const {
    tab,
    setTab,
    onClose,
    onSave,
    onFormChange,
  } = useProjectPhotosAlbumForm(props);

  const buildTabs = () => {
    return ALBUM_FORM_TABS.map((t) => ({
      ...t,
      class: t.id === tab ? 'sel' : '',
    }));
  };

  if (!show) return null;

  return (
    <FormModal
      id="project-photos-album-form-modal"
      className="project-photos-album-form"
      show={show}
      onClose={!startUpload ? onClose : undefined}
      subTitle="Create Album"
    >
      <div className="form-header">
        <NavigationBar
          items={buildTabs()}
          onSelected={setTab}
          disabled={startUpload}
          selectedValue={startUpload ? 'upload' : tab}
        />
      </div>
      <div className={`form-content ${tab}`}>
        <div
          style={{
            display: tab === 'general' && !startUpload ? 'block' : 'none',
          }}
        >
          <ProjectPhotosAlbumFormInfo />
        </div>
        <div
          style={{
            display: tab === 'permissions' && !startUpload ? 'block' : 'none',
          }}
        >
          <UserPermissionsForm
            users={form.permissionUsers}
            visibility={form.permission}
            canChangePermissions={ProjectPhotoPermissions.canChangePermissions(
              currentUser,
              currentProject,
              form
            )}
            onVisibilityChange={(permission) => onFormChange({ permission })}
            onUsersChange={(permissionUsers) =>
              onFormChange({ permissionUsers })
            }
          />
        </div>
        <div
          style={{
            display: tab === 'upload' || startUpload ? 'block' : 'none',
          }}
        >
          <ProjectPhotosUpload
            onClose={onClose}
            onChange={(count_photos) => onFormChange({ count_photos })}
            albumId={form.ids.length > 0 ? form.ids[0].id : null}
          />
        </div>
      </div>
      {!startUpload && (
        <div className="form-footer">
          <Grid container spacing={1} justifyContent={'center'} paddingY={2}>
            <Grid item>
              <Button
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.WHITE}
                label="Cancel"
                onClick={onClose}
              />
            </Grid>
            <Grid item>
              <Button
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.GREEN}
                label={'Save'}
                onClick={onSave}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </FormModal>
  );
};

export default connect((state) => ({
  show: getProjectPhotosShowAlbumForm(state),
  directory: getDirectory(state),
  currentProject: getCurrentProject(state),
  currentUser: getCurrentUser(state),
  form: getProjectPhotosForm(state),
  startUpload: getProjectPhotosStartUpload(state),
}))(ProjectPhotosAlbumFormModal);
