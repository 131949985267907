import { createSelector } from 'reselect';

export const getProjectSites = createSelector(
  (state) => state.projects.scenes.details.scenes.projectSites.data,
  (data) => data
);
export const getShowAddProjectSiteModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.projectSites.ui.showAddSitesModal,
  (show) => show
);
export const getShowAddProjectAreaModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.projectSites.ui.showAddAreaModal,
  (show) => show
);

export const getEditSiteAreaModal = createSelector(
  (state) => state.projects.scenes.details.scenes.projectSites.ui.edit,
  (edit) => edit
);
