import React, { Component } from 'react';
import { connect } from 'react-redux';
import './DashboardProjects.css';
import PropTypes from 'prop-types';
import NavigationBar from 'components/shared/navigation/NavigationBar';
import Projects from 'components/admin/projects/Projects';
import WorkOrders from 'components/admin/work-orders/WorkOrders';

class DashboardProjects extends Component {
  static propTypes = {
    perPage: PropTypes.number,
    showNav: PropTypes.bool,
    showSw: PropTypes.bool,
  };

  static defaultProps = {
    perPage: 5,
    showNav: false,
    showSw: false,
  };

  constructor(props) {
    super(props);

    this.state = { tab: 1 };
  }

  handleTabSelection = (tab) => {
    this.setState({ tab: tab });
  };

  render() {
    const { perPage, showNav, showSw } = this.props;
    const { tab } = this.state;
    return (
      <div className="dashboard-projects">
        {showSw && (
          <div className="dashboard-projects-header">
            <NavigationBar
              items={[
                {
                  id: 1,
                  title: 'Projects',
                },
                {
                  id: 2,
                  title: 'Work Orders',
                },
              ]}
              selectedValue={tab}
              onSelected={this.handleTabSelection}
            />
          </div>
        )}

        {tab === 1 && (
          <Projects
            perPage={perPage}
            embedded={true}
            showControls={false}
            showNav={showNav}
          />
        )}
        {tab === 2 && (
          <WorkOrders
            perPage={perPage}
            showTitle={false}
            showControls={false}
          />
        )}
      </div>
    );
  }
}

export default connect()(DashboardProjects);
