import React from 'react';
import PropTypes from 'prop-types';

function ChatTitle(props) {
  const { name } = props;

  return (
    <div className="chat-title small-bold">
      Chat is for clarification only.
      <br />
      Provide official response in the {name}.
    </div>
  );
}

ChatTitle.propTypes = {
  name: PropTypes.string,
};

export default ChatTitle;
