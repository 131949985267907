import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  loadPresetTasksRequest,
  handleWorkedFrom,
  handleWorkedTo,
  handleAttributeChangeAction,
  handleHoursAmountAction,
  changeAmountOfTechnicians,
  addedDeletedTechnicians,
  setDailyReportAddFloor,
  loadPresetTasksSuccess,
  setActiveEntriesId,
  deleteDailyReportEntry,
  updateTradeForm,
  addDailyReportEntriesFloor,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { getSettings } from 'components/admin/projects/details/project-settings/store/selectors';
import {
  getActiveContributor,
  getContributors,
  getPresetTasks,
  getContributorsForm,
  getActiveEntryId,
  getAddFloor,
} from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import ProjectOpenItemForm from 'components/admin/projects/details/project-daily-report-log/ProjectOpenItemForm';
import Input from 'components/shared/input/Input';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import FloorButton from 'components/shared/button/FloorButton';
import TimePicker from 'components/shared/time-picker/TimePicker';
import ImageUpload from 'components/shared/image-upload/ImageUpload';
import { imageExtensions } from 'services/utils/files-util';
import { containerScrollToTop } from 'services/utils/scroller-util';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import DetailsTitle from 'components/shared/details/DetailsTitle';

const defaultTechnician = { hoursAmount: '', workedFrom: '', workedTo: '' };

class SingleTradesFormComponent extends Component {
  handleDate = (techId, value, key) => {
    const { dispatch, activeEntryId, removeErrors } = this.props;
    removeErrors(key, techId, value);
    if (key === 'workedFrom') {
      dispatch(handleWorkedFrom(value, activeEntryId, techId));
    } else {
      dispatch(handleWorkedTo(value, activeEntryId, techId));
    }
  };

  handleAttributeChange = (attribute, value) => {
    const { dispatch, activeEntryId, removeErrors } = this.props;
    removeErrors(attribute);
    dispatch(handleAttributeChangeAction(attribute, value, activeEntryId));
  };

  handleHoursAmount = (techId, value) => {
    const { dispatch, activeEntryId, removeErrors } = this.props;
    removeErrors('hoursAmount', techId, value);
    dispatch(handleHoursAmountAction(value, activeEntryId, techId));
  };

  changeTechnicianAmount = (action, index = null) => {
    const { dispatch, activeEntryId } = this.props;

    const contribFormData = this.getContribFormData();
    if (contribFormData && contribFormData.technicians) {
      const newTechnicians = [...contribFormData.technicians];
      if (action === 'add') {
        newTechnicians.push(defaultTechnician);
      } else {
        const deletedTech =
          index !== null
            ? newTechnicians.splice(index, 1)[0]
            : newTechnicians.pop(defaultTechnician);
        if (deletedTech.id) {
          dispatch(addedDeletedTechnicians(deletedTech, activeEntryId));
        }
      }
      dispatch(changeAmountOfTechnicians(newTechnicians, activeEntryId));
    }
  };

  addTechnician = () => {
    this.changeTechnicianAmount('add');
  };

  removeTechnician = (index = null) => {
    this.changeTechnicianAmount('remove', index);
  };

  handleAddFloor = (drEntry = null) => {
    const { currentDailyReport, dispatch, activeEntryId } = this.props;
    const presetTasks = [...this.props.presetTasks];
    dispatch(setDailyReportAddFloor(true));
    if (drEntry) {
      dispatch(setActiveEntriesId(drEntry.id));
    } else {
      dispatch(addDailyReportEntriesFloor(activeEntryId, currentDailyReport));
    }
    dispatch(loadPresetTasksSuccess(presetTasks));
    containerScrollToTop('daily-report-form-modal');
  };

  handleDeleteFloorClick = (e) => {
    const {
      dispatch,
      activeEntryId,
      currentDailyReport,
      contributorsForm,
    } = this.props;
    const currentEntry = currentDailyReport.daily_report_entries.find(
      (val) => val.id === activeEntryId
    );
    const filteredForm = Object.entries(contributorsForm).filter(
      ([key, value]) => value.id !== activeEntryId
    );
    const newForm = {};
    filteredForm.forEach(([key, val]) => {
      newForm[key] = {
        workPerformed: val.workPerformed,
        floor: val.floor,
        area: val.area,
        comments: val.comments,
        presetTasks: val.presetTasks,
        customTask: val.customTask,
        images: val.images,
        technicians: val.technicians,
        deletedImages: val.deletedImages,
        deletedTechnicians: val.deletedTechnicians,
      };
    });
    dispatch(setActiveEntriesId(currentEntry['daily_report_entry_id']));
    dispatch(deleteDailyReportEntry(activeEntryId, currentDailyReport));
    dispatch(updateTradeForm(newForm));
    dispatch(setDailyReportAddFloor(false));
  };

  duplicateTechnician = () => {
    const contribFormData = this.getContribFormData();
    if (contribFormData && contribFormData.technicians) {
      const tech =
        contribFormData.technicians[contribFormData.technicians.length - 1];
      if (tech) {
        const newTechnicians = [...contribFormData.technicians];
        for (let i = 1; i < contribFormData.techCount; i++) {
          newTechnicians.push({ ...tech, id: '' });
        }
        this.handleAttributeChange('duplicateTech', false);
        this.handleAttributeChange('technicians', newTechnicians);
      }
    }
  };

  isTheLastTechnician = () => {
    const contribFormData = this.getContribFormData();
    return (
      contribFormData &&
      contribFormData.technicians &&
      contribFormData.technicians.length === 1
    );
  };

  setImages = (attribute, files) => {
    let images = [...this.getImages()];
    files.forEach(function (val) {
      images.push({
        url: val.url || URL.createObjectURL(val),
        file: val,
      });
    });

    this.handleAttributeChange(attribute, images);
  };

  removeImages = (attribute, index) => {
    let images = [...this.getImages()];
    let deletedImages = [...this.getDeletedImages()];
    let deletedImage = images.splice(index, 1);
    deletedImages.push(deletedImage[0]);

    this.handleAttributeChange(attribute, images);
    this.handleAttributeChange('deletedImages', deletedImages);
  };

  getImages = () => {
    const contribFormData = this.getContribFormData();
    if (
      contribFormData &&
      contribFormData.images &&
      contribFormData.images.length > 0
    ) {
      return contribFormData.images;
    }
    return [];
  };

  getDeletedImages = () => {
    const contribFormData = this.getContribFormData();
    if (
      contribFormData &&
      contribFormData.deletedImages &&
      contribFormData.deletedImages.length > 0
    ) {
      return contribFormData.deletedImages;
    }
    return [];
  };

  loadPresetTasks = () => {
    const { dispatch, activeContributor, contributors, directory } = this.props;
    if (contributors.length > 0) {
      const contributor = directory.project_contributors.find((contrib) => {
        return contrib.id === contributors[activeContributor - 1][0];
      });
      dispatch(loadPresetTasksRequest(contributor.csi_code.id));
    }
  };

  getContribFormData = () => {
    const { contributorsForm, activeEntryId } = this.props;
    if (activeEntryId && contributorsForm[activeEntryId]) {
      return contributorsForm[activeEntryId];
    }
    return null;
  };

  isErrorForHoursAmount = (index) => {
    const { errors } = this.props;
    if (
      errors.technicians &&
      errors.technicians[index] &&
      errors.technicians[index].error
    ) {
      if (
        errors.technicians[index].workedFrom ||
        errors.technicians[index].workedTo
      ) {
        return false;
      }
      return true;
    }
  };

  isErrorForWorkDate = (index, key) => {
    const { errors } = this.props;
    return (
      errors.technicians &&
      errors.technicians[index] &&
      errors.technicians[index].error &&
      !errors.technicians[index][key]
    );
  };

  validTechCount = () => {
    const contribFormData = this.getContribFormData();
    return (
      !!contribFormData &&
      !!contribFormData.techCount &&
      contribFormData.techCount > 0 &&
      contribFormData.techCount < 100
    );
  };

  componentDidMount() {
    const { submitFailedErrors } = this.props;
    this.loadPresetTasks();
    if (submitFailedErrors) {
      submitFailedErrors();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeEntryId !== this.props.activeEntryId) {
      this.loadPresetTasks();
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setDailyReportAddFloor(false));
  }

  render() {
    const {
      activeContributor,
      activeEntryId,
      presetTasks,
      errors,
      addFloor,
      currentDailyReport,
      settings,
    } = this.props;
    const contribFormData = this.getContribFormData();
    const currentEntry = currentDailyReport.daily_report_entries.find(
      (val) => val.id === activeEntryId
    );
    const childFloors = currentDailyReport.daily_report_entries.filter(
      (val) => val.daily_report_entry_id === activeEntryId
    );
    const isChildrenFloor = currentEntry && currentEntry.daily_report_entry_id;
    let presetTaskList = [];
    if (presetTasks && presetTasks.length > 0) {
      presetTaskList = presetTasks.map((task) => {
        return { id: task.id, label: task.label };
      });
    }
    return (
      <React.Fragment>
        {contribFormData ? (
          <div className="single-trade-form-wrapper">
            {settings && settings.multiple_floors && (
              <div className="form-block">
                <FormControlBlock
                  label={!!contribFormData.floor && 'Floor'}
                  control={
                    <Input
                      type="number"
                      placeholder="Floor"
                      onChange={this.handleAttributeChange.bind(null, 'floor')}
                      value={contribFormData.floor || ''}
                      valid={!errors.floor}
                    />
                  }
                />
                <FormControlBlock
                  label={!!contribFormData.area && 'Area'}
                  control={
                    <Input
                      placeholder="Area"
                      onChange={this.handleAttributeChange.bind(null, 'area')}
                      value={contribFormData.area || ''}
                    />
                  }
                />
              </div>
            )}

            <DetailsTitle
              className="modal-form-title"
              title="Technicians"
              rightButtons={[
                <div
                  key="duplicate-add"
                  className="form-block multicontrols-block"
                >
                  <LabeledCheckBox
                    label="Duplicate Tech"
                    position="left"
                    onChange={this.handleAttributeChange.bind(
                      null,
                      'duplicateTech'
                    )}
                    checked={contribFormData.duplicateTech}
                  />
                  {!contribFormData.duplicateTech ? (
                    <Button
                      type={BUTTON_TYPES.LINK}
                      icon={BUTTON_ICONS.PLUS}
                      label="Add Technician"
                      onClick={this.addTechnician}
                    />
                  ) : (
                    <React.Fragment>
                      <FormControlBlock
                        hideBottomLabel
                        label={!!contribFormData.techCount && 'How Many'}
                        control={
                          <Input
                            className="tech-count"
                            placeholder="How Many"
                            type="number"
                            onChange={this.handleAttributeChange.bind(
                              null,
                              'techCount'
                            )}
                            value={contribFormData.techCount}
                          />
                        }
                      />
                      <Button
                        type={BUTTON_TYPES.LINK}
                        icon={BUTTON_ICONS.PLUS}
                        disabled={!this.validTechCount()}
                        label="Duplicate"
                        onClick={this.duplicateTechnician}
                      />
                    </React.Fragment>
                  )}
                </div>,
              ]}
            />
            {contribFormData.technicians &&
              contribFormData.technicians.map((technician, index) => {
                return (
                  <div
                    className="form-block multicontrols-block right-block"
                    key={`${activeContributor}${index}`}
                  >
                    <span className="form-block-title">
                      Technician {+index + 1}
                    </span>
                    <FormControlBlock
                      label={!!technician.hoursAmount && 'Worked Hours'}
                      hideBottomLabel
                      control={
                        <Input
                          type="number"
                          onChange={(value) =>
                            this.handleHoursAmount(index, value)
                          }
                          value={technician.hoursAmount}
                          placeholder="Worked Hours"
                          valid={!this.isErrorForHoursAmount(index)}
                        />
                      }
                    />
                    <span className="form-block-text">Or</span>
                    <FormControlBlock
                      label={!!technician.workedFrom && 'Worked From'}
                      hideBottomLabel
                      control={
                        <TimePicker
                          placeholder="Worked From"
                          onChange={(value) =>
                            this.handleDate(index, value, 'workedFrom')
                          }
                          value={technician.workedFrom}
                          valid={!this.isErrorForWorkDate(index, 'workedFrom')}
                        />
                      }
                    />
                    <FormControlBlock
                      label={!!technician.workedTo && 'To'}
                      hideBottomLabel
                      control={
                        <TimePicker
                          placeholder="To"
                          onChange={(value) =>
                            this.handleDate(index, value, 'workedTo')
                          }
                          value={technician.workedTo}
                          valid={!this.isErrorForWorkDate(index, 'workedTo')}
                        />
                      }
                    />
                    {contribFormData.technicians.length > 1 && (
                      <Button
                        type={BUTTON_TYPES.LINK}
                        icon={BUTTON_ICONS.DELETE}
                        onClick={() => this.removeTechnician(index)}
                      />
                    )}
                  </div>
                );
              })}

            <DetailsTitle className="modal-form-title" title="Information" />
            <div className="form-block">
              <FormControlBlock
                label={
                  (contribFormData.presetTasks || []).length > 0 &&
                  'Preset Tasks'
                }
                control={
                  <MultipleDropdown
                    options={presetTaskList}
                    currentValues={contribFormData.presetTasks || []}
                    placeholder="Preset Tasks"
                    onChange={(values) =>
                      this.handleAttributeChange('presetTasks', values)
                    }
                  />
                }
              />
            </div>
            <div className="form-block">
              <FormControlBlock
                label={!!contribFormData.customTask && 'Custom Task'}
                control={
                  <Input
                    placeholder="Custom Task"
                    onChange={this.handleAttributeChange.bind(
                      null,
                      'customTask'
                    )}
                    value={contribFormData.customTask}
                  />
                }
              />
            </div>
            <div className="form-block">
              <FormControlBlock
                label={!!contribFormData.workPerformed && 'Description'}
                control={
                  <TextareaAutosizeWrapper
                    placeholder="Description"
                    initialValue={contribFormData.workPerformed || ''}
                    valid={!errors.workPerformed}
                    onChange={(value) =>
                      this.handleAttributeChange('workPerformed', value)
                    }
                  />
                }
              />
            </div>
            <div className="form-block">
              <FormControlBlock
                label={!!contribFormData.comments && 'Comments'}
                control={
                  <TextareaAutosizeWrapper
                    placeholder="Comments"
                    initialValue={contribFormData.comments || ''}
                    onChange={(value) =>
                      this.handleAttributeChange('comments', value)
                    }
                  />
                }
              />
            </div>
            <div className="form-resources-block">
              <div className="form-images">
                <FormControlBlock
                  className="without-focus"
                  label="Photos"
                  control={
                    <div className="form-resources-body">
                      <ImageUpload
                        extensions={imageExtensions}
                        multiple
                        onUpload={(files) => this.setImages('images', files)}
                        onRemove={(index) => this.removeImages('images', index)}
                        images={this.getImages()}
                      />
                    </div>
                  }
                />
              </div>
            </div>

            <DetailsTitle
              className="modal-form-subtitle"
              title="Open Item"
              rightButtons={[
                <LabeledCheckBox
                  key="open-item"
                  label="Is there an Open Item to report?"
                  position="left"
                  onChange={this.handleAttributeChange.bind(null, 'isDelay')}
                  checked={contribFormData.isDelay}
                />,
              ]}
            />
            {contribFormData.isDelay && (
              <ProjectOpenItemForm
                form={contribFormData}
                errors={errors}
                handleAttributeChange={this.handleAttributeChange}
              />
            )}

            {((childFloors && !!childFloors.length) ||
              (settings && settings.multiple_floors)) && (
              <React.Fragment>
                <DetailsTitle
                  className="modal-form-subtitle"
                  title="Floors"
                  rightButtons={
                    settings && settings.multiple_floors
                      ? [
                          addFloor || isChildrenFloor ? (
                            <FloorButton
                              key="delete-floor"
                              isForAdd={false}
                              onClick={this.handleDeleteFloorClick}
                            />
                          ) : (
                            <FloorButton
                              key="add-floor"
                              isForAdd={true}
                              onClick={this.handleAddFloor}
                            />
                          ),
                        ]
                      : null
                  }
                />
                {childFloors && !!childFloors.length && (
                  <ul className="trade-item-floor-items small-regular">
                    {childFloors.map((drEntry) => (
                      <li
                        className="trade-item-floor-item"
                        key={drEntry.id}
                        onClick={() => this.handleAddFloor(drEntry)}
                      >
                        {!!drEntry.floor && `Floor ${drEntry.floor}`}
                        {!!drEntry.floor && !!drEntry.area && ' - '}
                        {!!drEntry.area && drEntry.area}
                        {!drEntry.floor && !drEntry.area && 'Draft Floor'}
                      </li>
                    ))}
                  </ul>
                )}
              </React.Fragment>
            )}
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    directory: getDirectory(state),
    settings: getSettings(state),
    contributorsForm: getContributorsForm(state),
    presetTasks: getPresetTasks(state),
    activeContributor: getActiveContributor(state),
    contributors: getContributors(state),
    activeEntryId: getActiveEntryId(state),
    addFloor: getAddFloor(state),
  };
})(SingleTradesFormComponent);
