import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Box, Modal } from '@mui/material';
import Button, { BUTTON_TYPES } from 'components/shared/button/Button';

import arrowIcon from 'images/arrow.svg';
import cancelSvg from 'images/cancel.svg';
import cancelDarkSvg from 'images/cancel-dark.svg';
import { getDarkMode } from 'selectors/theme';
import {
  getProjectPhotosEditImage,
  getProjectPhotosShowDetail,
} from './store/selectors';
import {
  setProjectPhotosDetail,
  setProjectPhotosShowDetail,
} from './store/actions';
import ProjectPhotosForm from './ProjectPhotosForm';
import ProjectPhotosDetailSlider from './ProjectPhotosDetailSlider';
import './ProjectPhotosDetailModal.css';

const ProjectPhotosDetailModal = (props) => {
  const { dispatch, show, darkMode, edit } = props;
  const [showForm, setShowForm] = useState(true);

  const toggleForm = () => setShowForm(!showForm);

  const onClose = () => {
    dispatch(setProjectPhotosShowDetail(false));
    dispatch(setProjectPhotosDetail());
  };

  return (
    <Modal
      open={show}
      onClose={onClose}
      style={{ zIndex: 1000 }}
      className={`project-photos-detail-modal ${
        showForm ? 'show-form' : 'hide-form'
      }`}
      slotProps={{ backdrop: { className: 'detail-backdrop' } }}
    >
      <>
        <Box className="main-content" bgcolor="background.paper">
          <ProjectPhotosDetailSlider />
        </Box>
        <Box className="right-form" bgcolor="background.paper">
          <Box className="close-space" bgcolor="background.paper"></Box>
          <ProjectPhotosForm />
        </Box>
        <Button
          className="toggle-form-button"
          onClick={toggleForm}
          type={BUTTON_TYPES.LINK}
          label={
            <img
              src={arrowIcon}
              style={{ width: 16, height: 16 }}
              alt="Close"
            />
          }
        />
        {(!edit || showForm) && (
          <Button
            className="close-button"
            onClick={onClose}
            type={BUTTON_TYPES.LINK}
            label={
              <img
                src={darkMode ? cancelDarkSvg : cancelSvg}
                style={{ width: 20, height: 20 }}
                alt="Close"
              />
            }
          />
        )}
      </>
    </Modal>
  );
};

export default connect((state) => ({
  show: getProjectPhotosShowDetail(state),
  darkMode: getDarkMode(state),
  edit: getProjectPhotosEditImage(state),
}))(ProjectPhotosDetailModal);
