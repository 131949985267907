import {
  REPORT_RESULTS_LOAD_SUCCESS,
  REPORTS_RESET,
} from 'actions/admin/reports/action-types';

const initialState = {
  results: null,
};

export default function reportsDataReducer(state = initialState, action) {
  switch (action.type) {
    case REPORT_RESULTS_LOAD_SUCCESS:
      return {
        ...state,
        results: action.payload,
      };

    case REPORTS_RESET:
      return initialState;

    default:
      return state;
  }
}
