import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import './ProjectStatus.css';

class ProjectStatus extends Component {
  configuration = {
    pending: {
      position: 0,
      total: 2,
    },
    in_progress: {
      position: 1,
      total: 2,
    },
  };

  static propTypes = {
    status: PropTypes.shape({
      status: PropTypes.string,
      label: PropTypes.string,
    }),
    type: PropTypes.string,
  };

  renderDetails() {
    const { status, darkMode } = this.props;
    return (
      <div className={`project-status ${darkMode ? 'dark-mode' : ''}`}>
        <div className={'status-text ' + (status ? status.status : '')}>
          {status ? status.label.toUpperCase() : ''}
        </div>
      </div>
    );
  }

  renderList() {
    const { status, darkMode } = this.props;
    return (
      <div
        className={`project-status-list-item ${darkMode ? 'dark-mode' : ''}`}
      >
        {status ? status.label : ''}
      </div>
    );
  }

  renderLabel() {
    const { status, darkMode } = this.props;
    return (
      <span className={`project-status-label ${darkMode ? 'dark-mode' : ''}`}>
        {status ? status.label : ''}
      </span>
    );
  }

  render() {
    const { type } = this.props;
    switch (type) {
      case 'list':
        return this.renderList();
      case 'label':
        return this.renderLabel();
      default:
        return this.renderDetails();
    }
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(ProjectStatus);
