import axios from 'axios';
import BaseApi from './base-api';
import { Permission } from 'domain/album';

class AlbumsApi extends BaseApi {
  getAlbums(projectId, parentId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/albums?parent_id=${parentId}`)
      .then((response) => {
        return {
          ok: true,
          albums: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
  getSharedAlbums(projectId) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/albums?parent_id=nil&by_permission=shared&shared_with_me=true&active=true`
      )
      .then((response) => {
        return {
          ok: true,
          albums: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createAlbum(projectId, form) {
    let apiPayload = this.albumParams(form);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .post(`${this.baseUrl}/projects/${projectId}/albums`, apiPayload, headers)
      .then((response) => {
        return {
          ok: true,
          album: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getAlbum(projectId, id) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/albums/${id}`)
      .then((response) => {
        return {
          ok: true,
          album: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateAlbum(projectId, id, form) {
    let apiPayload = this.albumParams(form);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/albums/${id}`,
        apiPayload,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          album: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  destroyAlbum(projectId, id) {
    return axios
      .delete(`${this.baseUrl}/projects/${projectId}/albums/${id}`)
      .then((response) => {
        return {
          ok: true,
          album: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  archiveAlbum(projectId, id) {
    return axios
      .put(`${this.baseUrl}/projects/${projectId}/albums/${id}/archive`)
      .then((response) => {
        return {
          ok: true,
          album: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  batchUpdateAlbums(projectId, selectedAlbums, action) {
    return axios
      .post(`${this.baseUrl}/projects/${projectId}/albums/batch_update`, {
        album: {
          album_ids: selectedAlbums,
          action: action,
        },
      })
      .then((response) => {
        return {
          ok: true,
          album: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getAllPhotos(projectId, per_page = null) {
    let url = `${this.baseUrl}/projects/${projectId}/albums/all_photos`;
    if (per_page) url += `?per_page=${per_page}`;

    return axios
      .get(url)
      .then((response) => {
        return {
          ok: true,
          photos: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  moveAlbum(projectId, id, newParentId) {
    let params = {
      album: { move_to_album_id: newParentId },
    };
    return axios
      .post(`${this.baseUrl}/projects/${projectId}/albums/${id}/move`, params)
      .then((response) => {
        return {
          ok: true,
          album: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  movePhotos(projectId, albumId, photoIds) {
    let params = {
      album: {
        photo_ids: photoIds,
      },
    };
    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/albums/${albumId}/move_photos`,
        params
      )
      .then((response) => {
        return {
          ok: true,
          album: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  downloadAlbums(projectId, selectedAlbums, form) {
    let download = form.download;
    let params = {
      album: {
        album_ids: selectedAlbums,
        user_ids: download.recipients.map((user) => user[0]),
        message: download.message,
        subject: download.subject,
      },
    };
    return axios
      .post(`${this.baseUrl}/projects/${projectId}/albums/download`, params)
      .then(() => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  albumFavorited(projectId, album, currentUserId) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    let apiPayload = new FormData();
    if (album.photo_favorite) {
      apiPayload.append(
        'album[photo_favorites_attributes][0][id]',
        album.photo_favorite.id
      );
      apiPayload.append('album[photo_favorites_attributes][0][_destroy]', true);
    } else {
      apiPayload.append(
        'album[photo_favorites_attributes][0][user_id]',
        currentUserId
      );
    }

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/albums/${album.id}`,
        apiPayload,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          album: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  photoUpdateParams(albumId, form) {
    let photo = form.photo;
    let apiPayload = new FormData();
    let key = 'album[photos_attributes][0]';
    apiPayload.append('album[id]', albumId);
    apiPayload.append(`${key}[id]`, photo.id);
    apiPayload.append(`${key}[description]`, photo.description);

    photo.tags
      .filter((tag) => !('tag_join_id' in tag))
      .forEach((tag, index) => {
        apiPayload.append(
          `${key}[tag_joins_attributes][${index}][tag_id]`,
          tag.id
        );
      });

    photo.remove_tags.forEach((tag, index) => {
      apiPayload.append(
        `${key}[tag_joins_attributes][${index}][id]`,
        tag.tag_join_id
      );
      apiPayload.append(
        `${key}[tag_joins_attributes][${index}][_destroy]`,
        true
      );
    });
    return apiPayload;
  }

  albumParams(form) {
    let album = form.album;
    let apiPayload = new FormData();
    apiPayload.append('album[name]', album.name);
    apiPayload.append('album[description]', album.description);
    if (album.cover_image && album.cover_image.file) {
      apiPayload.append('album[cover_image]', album.cover_image.image);
    }
    apiPayload.append('album[created_by_id]', album.created_by_id);
    apiPayload.append('album[permission]', album.permission);
    apiPayload.append('album[uid]', album.uid);
    if (album.parent_id && album.parent_id > 0) {
      apiPayload.append('album[parent_id]', album.parent_id);
    }
    if (album.permission === Permission.SHARED) {
      album.user_ids.forEach((user_id) => {
        apiPayload.append('album[user_ids][]', user_id);
      });
      apiPayload.append('album[user_ids][]', album.created_by_id);
    } else if (album.permission === Permission.PRIVATE) {
      apiPayload.append('album[user_ids][]', album.created_by_id);
    }

    if (album.remove_user_ids.length > 0) {
      album.remove_user_ids.forEach((remove_user_id) => {
        apiPayload.append('album[remove_user_ids][]', remove_user_id);
      });
    }

    album.photos
      .filter((photo) => !photo.id || (photo.id && photo._destroy))
      .forEach((photo, index) => {
        apiPayload.append(
          `album[photos_attributes][${index}][key]`,
          photo.name
        );
        apiPayload.append(
          `album[photos_attributes][${index}][created_by_id]`,
          album.created_by_id
        );
        apiPayload.append(
          `album[photos_attributes][${index}][album_uid]`,
          album.uid
        );
      });

    let tagJoinIndex = 0;
    album.tags
      .concat(album.locations)
      .filter((tag) => !('tag_join_id' in tag))
      .forEach((tag, index) => {
        tagJoinIndex = index;
        apiPayload.append(
          `album[tag_joins_attributes][${index}][tag_id]`,
          tag.id
        );
      });

    album.remove_tags
      .concat(album.remove_locations)
      .filter((tag) => 'tag_join_id' in tag)
      .forEach((tag) => {
        tagJoinIndex = tagJoinIndex === 0 ? 0 : tagJoinIndex + 1;
        apiPayload.append(
          `album[tag_joins_attributes][${tagJoinIndex}][id]`,
          tag.tag_join_id
        );
        apiPayload.append(
          `album[tag_joins_attributes][${tagJoinIndex}][_destroy]`,
          true
        );
        tagJoinIndex = tagJoinIndex + 1;
      });

    let userJoinIndex = 0;
    album.users
      .filter((user) => !('user_join_id' in user))
      .forEach((user, index) => {
        userJoinIndex = index;
        apiPayload.append(
          `album[user_joins_attributes][${index}][user_id]`,
          user.id
        );
      });

    userJoinIndex = userJoinIndex === null ? 0 : userJoinIndex;
    album.remove_users.forEach((user) => {
      userJoinIndex = userJoinIndex === 0 ? 0 : userJoinIndex + 1;
      apiPayload.append(
        `album[user_joins_attributes][${userJoinIndex}][id]`,
        user.user_join_id
      );
      apiPayload.append(
        `album[user_joins_attributes][${userJoinIndex}][_destroy]`,
        true
      );
    });

    let tradeJoinIndex = 0;
    album.trades
      .filter((trade) => !('trade_join_id' in trade))
      .forEach((trade, index) => {
        tradeJoinIndex = index;
        apiPayload.append(
          `album[trade_joins_attributes][${index}][trade_id]`,
          trade.id
        );
      });

    album.remove_trades.forEach((trade) => {
      tradeJoinIndex = tradeJoinIndex === 0 ? 0 : tradeJoinIndex + 1;
      apiPayload.append(
        `album[trade_joins_attributes][${tradeJoinIndex}][id]`,
        trade.trade_join_id
      );
      apiPayload.append(
        `album[trade_joins_attributes][${tradeJoinIndex}][_destroy]`,
        true
      );
    });

    return apiPayload;
  }
}

export const albumsApi = new AlbumsApi();
