import React, { Component } from 'react';
import { connect } from 'react-redux';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import faAngleDown from '@fortawesome/fontawesome-free-solid/faAngleDown';
import faAngleUp from '@fortawesome/fontawesome-free-solid/faAngleUp';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCloseout } from 'components/admin/projects/details/project-closeout/store/selectors';
import { Collapse } from 'react-bootstrap';
import SimpleTable from 'components/shared/table/SimpleTable';
import {
  getCloseoutCats,
  getCloseoutContribs,
  getCloseoutCatsCompanyTypes,
} from 'components/admin/projects/details/project-closeout/store/selectors';
import './CloseoutCatsByCompanyTable.css';
import { compareCompanyTypeHierarchy } from 'services/utils/closeout-table-util';
import Checkbox from 'components/shared/checkbox/CheckBox.js';
import { updateCloseoutContribCatRequest } from 'components/admin/projects/details/project-closeout/store/actions';
import { getDarkMode } from 'selectors/theme';

class CloseoutCatsByCompanyTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: false,
    };
  }

  showTable = () => {
    const { showTable } = this.state;
    this.setState({ showTable: !showTable });
  };

  displayCloseoutCat = (closeoutCat) => {
    const { closeoutCatsCompanyTypes } = this.props;
    if (closeoutCat.value === 'misc') {
      return false;
    }
    return closeoutCatsCompanyTypes.some((catCompanyType) => {
      return (
        catCompanyType.closeout_cat_id === closeoutCat.id &&
        !!catCompanyType.applies
      );
    });
  };

  tableHeaders = () => {
    const { closeoutCats } = this.props;
    const headers = ['COMPANY TYPE', 'COMPANY NAME'];
    return headers.concat(
      closeoutCats
        .filter((cat) => this.displayCloseoutCat(cat))
        .map((cat) => cat.label.toUpperCase())
    );
  };

  findCloseoutContribCat = (closeoutCat, closeoutContrib) => {
    return closeoutContrib.closeout_contributor_cats.find(
      (contribCat) => contribCat.closeout_cat_id === closeoutCat.id
    );
  };

  updateCloseoutContributorCat = (closeoutContribuorCat) => {
    const { dispatch, currentProject } = this.props;
    dispatch(
      updateCloseoutContribCatRequest(
        currentProject.id,
        closeoutContribuorCat.id,
        !closeoutContribuorCat.applies
      )
    );
  };

  displayCheckbox = (closeoutContribCat) => {
    const { closeoutCatsCompanyTypes } = this.props;
    const catCompanyType = closeoutCatsCompanyTypes.find(
      (catCompanyType) =>
        catCompanyType.id === closeoutContribCat.closeout_cats_company_type_id
    );
    return catCompanyType.applies;
  };

  rows = () => {
    const { closeoutContribs, closeoutCats } = this.props;
    return closeoutContribs
      .sort((contrib1, contrib2) =>
        compareCompanyTypeHierarchy(
          contrib1.company_type,
          contrib2.company_type
        )
      )
      .map((closeoutContrib) => {
        return {
          id: closeoutContrib.id,
          data: [
            `${closeoutContrib.csi_code_division} ${closeoutContrib.csi_code_label}`,
            closeoutContrib.company_name,
          ].concat(
            closeoutCats
              .filter((cat) => this.displayCloseoutCat(cat))
              .map((closeoutCat) => {
                const closeoutContribCat = this.findCloseoutContribCat(
                  closeoutCat,
                  closeoutContrib
                );
                // Older Closoeouts will not have closeoutContribCat for all CloseoutCats
                if (
                  closeoutContribCat &&
                  this.displayCheckbox(closeoutContribCat)
                ) {
                  return (
                    <Checkbox
                      className="orange"
                      id={closeoutContribCat.id}
                      checked={closeoutContribCat.applies}
                      size="small"
                      shape="square"
                      onChange={this.updateCloseoutContributorCat.bind(
                        this,
                        closeoutContribCat
                      )}
                    />
                  );
                } else {
                  return '';
                }
              })
          ),
        };
      });
  };

  render() {
    const { darkMode } = this.props;
    const { showTable } = this.state;

    return (
      <div
        className={`closeout-cats-by-company-container ${
          darkMode ? 'dark-mode' : ''
        }`}
      >
        <div className="divider"></div>
        <div
          className="closeout-cats-by-company-header"
          onClick={this.showTable}
        >
          <h4>Items By Company</h4>
          <span className="closeout-cats-by-company-toggle">
            <FontAwesomeIcon icon={showTable ? faAngleUp : faAngleDown} />
          </span>
        </div>
        <Collapse in={showTable} className="collapse-info">
          <div className="closeout-cats-by-company-table-container">
            {showTable && (
              <SimpleTable
                headers={this.tableHeaders()}
                className="closeout-cats-by-company-table"
                rows={this.rows()}
              />
            )}
          </div>
        </Collapse>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    closeout: getCloseout(state),
    closeoutCats: getCloseoutCats(state),
    closeoutContribs: getCloseoutContribs(state),
    closeoutCatsCompanyTypes: getCloseoutCatsCompanyTypes(state),
    darkMode: getDarkMode(state),
  };
})(CloseoutCatsByCompanyTable);
