import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';

class CloseoutContributorsApi extends BaseApi {
  getCloseoutContributors(projectId) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/closeout/closeout_contributors`
      )
      .then((response) => {
        return {
          ok: true,
          closeoutContributors: response.data,
          total: numeral(response.headers['total']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateCloseoutContributor(projectId, closeoutContrib, values) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = this.closeoutContributorParams(closeoutContrib, values);

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/closeout/closeout_contributors/${closeoutContrib.id}`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          closeoutContributor: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  closeoutContributorParams(closeoutContrib, values) {
    const apiPayload = new FormData();
    apiPayload.append('closeout_contributor[comments]', values.comments || '');

    closeoutContrib.closeout_contributor_cats.forEach((contribCat, index) => {
      const attrs = values[contribCat.id];

      apiPayload.append(
        `closeout_contributor[closeout_contributor_cats_attributes][${index}][id]`,
        contribCat.id
      );

      apiPayload.append(
        `closeout_contributor[closeout_contributor_cats_attributes][${index}][applies]`,
        attrs.applies || false
      );

      apiPayload.append(
        `closeout_contributor[closeout_contributor_cats_attributes][${index}][comments]`,
        attrs.comments || ''
      );

      let docsIndex = 0;
      if (attrs.documents && attrs.documents.length > 0) {
        attrs.documents
          .filter((doc) => !doc.id)
          .forEach((doc) => {
            apiPayload.append(
              `closeout_contributor[closeout_contributor_cats_attributes][${index}][documents_attributes][${docsIndex}][document]`,
              doc.file,
              doc.file.name
            );
            docsIndex++;
          });
      }

      if (attrs.deletedDocuments && attrs.deletedDocuments.length > 0) {
        attrs.deletedDocuments
          .filter((doc) => doc.id)
          .forEach((doc) => {
            apiPayload.append(
              `closeout_contributor[closeout_contributor_cats_attributes][${index}][documents_attributes][${docsIndex}][id]`,
              doc.id
            );
            apiPayload.append(
              `closeout_contributor[closeout_contributor_cats_attributes][${index}][documents_attributes][${docsIndex}][_destroy]`,
              true
            );
            docsIndex++;
          });
      }
    });

    return apiPayload;
  }
}

export const closeoutContributorsApi = new CloseoutContributorsApi();
