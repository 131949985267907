import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import { MenuItem as MenuItemComponent } from '@mui/material';
import DatePicker from 'components/shared/date-picker/DatePicker';
import vector from './vector.svg';
import vectorDark from './vector-dark.svg';

export const ITEM_TYPE = PropTypes.shape({
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  onClick: PropTypes.func,
  closeOnSelect: PropTypes.bool,
});

ITEM_TYPE.subitems = PropTypes.arrayOf(ITEM_TYPE);

const MenuItem = (props) => {
  const { item, separator, onSubitemClick, darkMode } = props;
  const [submenuOpen, setSubmenuOpen] = useState(false);

  const hasSubitems = item.subitems && item.subitems.length > 0;

  const handleItemSelect = (event) => {
    if (item.onClick) item.onClick(event);
  };

  const handleSubitemSelect = (event, subitem) => {
    onSubitemClick(event, subitem);
  };

  const handleToggleSubmenu = (event) => {
    event.stopPropagation();
    setSubmenuOpen(!submenuOpen);
  };

  const renderContent = (currentItem, handleItemClick) => {
    if (currentItem.isDate) {
      return (
        <div
          className="date-picker-container"
          onClick={(e) => e.stopPropagation()}
        >
          <DatePicker
            placeholder={currentItem.placeholder || 'Select Date'}
            value={currentItem.dateValue}
            onChange={(dateValue) =>
              currentItem.onDateChange && currentItem.onDateChange(dateValue)
            }
          />
        </div>
      );
    }

    // For non-date items, handle as regular click
    return <div onClick={handleItemClick}>{currentItem.content}</div>;
  };

  return (
    <>
      <MenuItemComponent
        className={`menu-item ${item.className || ''} ${
          submenuOpen ? 'submenu-open' : ''
        }`}
        style={{ paddingLeft: item.child ? `${item.child * 32}px` : null }}
        onClick={hasSubitems ? handleToggleSubmenu : (e) => handleItemSelect(e)}
      >
        {renderContent(item, () => handleItemSelect())}
        {hasSubitems && (
          <div
            className="menu-item-arrow-container"
            onClick={handleToggleSubmenu}
          >
            <img
              className="menu-item-arrow"
              src={darkMode ? vectorDark : vector}
              alt="arrow"
            />
          </div>
        )}
      </MenuItemComponent>
      {separator && <div className="menu-item-separator"></div>}
      {submenuOpen && (
        <div className="submenu">
          {item.subitems.map((subitem, index) => (
            <MenuItem
              key={index}
              item={{
                ...subitem,
                onClick: (e) => handleSubitemSelect(e, subitem),
              }}
              separator={separator}
              onSubitemClick={onSubitemClick}
              darkMode={darkMode}
            />
          ))}
        </div>
      )}
    </>
  );
};

MenuItem.propTypes = {
  item: ITEM_TYPE.isRequired,
  separator: PropTypes.bool,
  darkMode: PropTypes.bool,
  onSubitemClick: PropTypes.func,
};

export default connect((state) => ({
  darkMode: getDarkMode(state),
}))(MenuItem);
