import React from 'react';
import Input from 'components/shared/input/Input';

/**
 * Wrapper for Input field used inside redux-form.
 * @param props
 * @return {*}
 * @constructor
 */
export default function FormInput(props) {
  return (
    <Input
      valid={!props.meta.error || !props.meta.submitFailed}
      value={props.input.value}
      onChange={(value) => props.input.onChange(value)}
      {...props}
    />
  );
}
