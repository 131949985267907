import { Grid } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';

import {
  getProjectPhotosTotalAlbums,
  getProjectPhotosTotalPhotos,
} from './store/selectors';
import ProjectPhotosTitle from './ProjectPhotosTitle';
import ProjectPhotosMenu from './ProjectPhotosMenu';
import ProjectPhotosFilterSort from './ProjectPhotosFilterSort';
import ProjectPhotosFilterSearch from './ProjectPhotosFilterSearch';
import ProjectPhotosFiltersChips from './ProjectPhotosFiltersChips';
import ProjectPhotosFilterFavorite from './ProjectPhotosFilterFavorite';

const ProjectPhotosHeader = (props) => {
  const { totalAlbums, totalPhotos } = props;

  const getTitleCount = () => {
    if (totalAlbums > 0) return { title: 'Project Albums', count: totalAlbums };
    if (totalPhotos > 0)
      return { title: 'Photos & Videos', count: totalPhotos };

    return { title: '', count: undefined };
  };
  const { title, count } = getTitleCount();

  return (
    <div className="project-photos-header">
      <Grid
        container
        spacing={2}
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Grid item flex={1}>
          <ProjectPhotosTitle title={title} count={count} />
        </Grid>

        <Grid item>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <ProjectPhotosFilterFavorite height={21} width={23} outlined />
            </Grid>
            <Grid item>
              <ProjectPhotosFilterSearch />
            </Grid>
            <Grid item>
              <ProjectPhotosMenu />
            </Grid>
            <Grid item>
              <ProjectPhotosFilterSort />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ProjectPhotosFiltersChips />
    </div>
  );
};

export default connect((state) => ({
  totalPhotos: getProjectPhotosTotalPhotos(state),
  totalAlbums: getProjectPhotosTotalAlbums(state),
}))(ProjectPhotosHeader);
