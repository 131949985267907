import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormModal from 'components/shared/modal/FormModal';
import SearchBar from 'components/shared/navigation/SearchBar';
import ResolvedReportItem from '../ResolvedReportItem';
import './ProjectReportFormModal.css';

class ProjectReportScheduleModal extends Component {
  constructor(props) {
    super(props);
    this.state = { search: '' };
  }

  render() {
    const { show, onClose, onSearch, data } = this.props;

    return (
      <FormModal
        id="project-report-form-modal"
        className="project-report-form-modal-view"
        show={show}
        onClose={onClose}
        subTitle="Project Report"
      >
        <div className="container-header-title">
          <div className="modal-name text-color">Resolved Schedule Remarks</div>
          <SearchBar
            className="search-input-background"
            searchPlaceHolder="Search"
            searchText={''}
            onSearch={(value) => this.setState({ search: value })}
            onSearchTextChange={(value) => {
              this.setState({ search: value });
              onSearch(value);
            }}
          />
        </div>
        <div className="container-body-remark">
          {data.length > 0 ? (
            data
              .filter((report) => report.schedule_remarks)
              .map((report) => (
                <ResolvedReportItem
                  key={report.id}
                  number={report.number}
                  date={report.date}
                  submitted_by={report.submitted_by}
                  remarks={report.schedule_remarks}
                />
              ))
          ) : (
            <div className="modal-no-items text-color">No Remarks</div>
          )}
        </div>
      </FormModal>
    );
  }
}

ProjectReportScheduleModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape()),
};

ProjectReportScheduleModal.defaultProps = {
  show: false,
  onClose: () => null,
  data: [],
};

export default ProjectReportScheduleModal;
