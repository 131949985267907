import User from 'domain/user';

class DirectoryPermissions {
  canViewLog = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project) ||
    User.isPrimary(user, project);

  canSendEmail = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project) ||
    User.isPrimary(user, project);

  canAddContributor = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project);

  canSelectUsers = (user, project, contributor) =>
    User.isAdminOrProjectSubscriber(user, project) ||
    (User.isGcOar(user, project) &&
      (User.isSameCompany(user, contributor) || contributor.added));

  canInviteUsers = (user, project, contributor) =>
    User.isAdminOrProjectSubscriber(user, project) ||
    (User.isGcOar(user, project) &&
      (User.isSameCompany(user, contributor) || contributor.added));

  canEditUsers = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project);

  canSendUsersInvite = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project);

  canReSendUserInvite = (user, wantedUser, project = null) =>
    !wantedUser.invitation_accepted_at &&
    ((project
      ? User.isAdminProjectSubscriberGcOar(user, project)
      : User.isAdminOrSubscriber(user)) ||
      User.isSameCompany(user, wantedUser));

  canGetUsersActivationLink = (user, project) =>
    User.isAdminProjectSubscriberGcOar(user, project);

  canDeleteUser = (user, project, contributor) =>
    User.isAdminProjectSubscriberGcOar(user, project) &&
    !User.isClient(contributor, project) &&
    !User.isProjectSubscriber(contributor, project);

  canEditContributorNotifications = (user, project, contributor) =>
    User.isAdminProjectSubscriberGcOar(user, project) ||
    User.isSameCompany(user, contributor);

  canSetAccountAdmin = (user) =>
    User.isAdmin(user) || User.isAccountAdmin(user);

  canEditCompanyUser = (user) =>
    User.isAdmin(user) || User.isAccountAdmin(user);
}

export default new DirectoryPermissions();
