import { showErrorMessage } from 'actions/messages';
import { projectDocumentationsApi } from 'services/api/project-documentations-api';
import { projectAdvancedDocumentsApi } from 'services/api/project-advanced-documents-api';
import {
  DOCUMENTATION_SET,
  DOCUMENTATION_COUNT_SET,
  DOCUMENTATION_RESET,
  DIRECTORY_SET,
  ADVANCED_DOCUMENTS_LOADED,
  DOCUMENTATION_PREFIX_SET,
} from 'components/admin/projects/details/project-documentation/store/action-types';
import { directoriesApi } from 'services/api/directories-api';

export function loadDocumentationSuccess(documentation, category) {
  return {
    type: DOCUMENTATION_SET,
    payload: {
      documentation,
      category,
    },
  };
}

export function loadDocumentationCountSuccess(documentationCount) {
  return { type: DOCUMENTATION_COUNT_SET, payload: documentationCount };
}

export function loadAdvancedDocumentsSuccess(advancedDocuments) {
  return { type: ADVANCED_DOCUMENTS_LOADED, payload: advancedDocuments };
}

export function setInitialPrefixDocuments(initalPrefix) {
  return { type: DOCUMENTATION_PREFIX_SET, payload: initalPrefix };
}

export function resetDocumentation() {
  return { type: DOCUMENTATION_RESET };
}

export function loadDocumentationRequest(projectId, filters) {
  return (dispatch) => {
    return projectDocumentationsApi
      .getProjectDocumentation(projectId, filters)
      .then((response) => {
        if (response.ok) {
          dispatch(
            loadDocumentationSuccess(
              response.projectDocumentation,
              filters.category
            )
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadDocumentationCountRequest(projectId) {
  return (dispatch) => {
    return projectDocumentationsApi
      .getProjectDocumentationCount(projectId)
      .then((response) => {
        if (response.ok) {
          dispatch(
            loadDocumentationCountSuccess(response.projectDocumentationCount)
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadAdvancedDocuments(projectId) {
  return (dispatch) => {
    return projectDocumentationsApi
      .getAdvancedDocuments(projectId)
      .then((response) => {
        if (response.ok) {
          dispatch(loadAdvancedDocumentsSuccess(response.advancedDocuments));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function updateDocumentationRequest(projectId, values, filters) {
  return (dispatch) => {
    return projectDocumentationsApi
      .updateProjectDocumentation(projectId, values, filters)
      .then((response) => {
        if (response.ok) {
          dispatch(
            loadDocumentationSuccess(
              response.projectDocumentation,
              filters.category
            )
          );
          dispatch(loadDocumentationCountRequest(projectId));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function createAdvancedDocumentsFolderRequest(projectId, values) {
  return (dispatch) => {
    return projectAdvancedDocumentsApi
      .createAdvancedDocumentsFolder(projectId, values)
      .then((response) => {
        if (response.ok) {
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function updateAdvancedDocumentRequest(projectId, documentId, values) {
  return (dispatch) => {
    return projectAdvancedDocumentsApi
      .updateAdvancedDocument(projectId, documentId, values)
      .then((response) => {
        if (response.ok) {
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function renameAdvancedDocumentsFolderRequest(
  projectId,
  values,
  currentFileId
) {
  return (dispatch) => {
    return projectAdvancedDocumentsApi
      .renameAdvancedDocumentsFolder(projectId, values, currentFileId)
      .then((response) => {
        if (response.ok) {
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function notifyUploadAdvanceDocumentsRequest(
  projectId,
  prefix,
  fileNames
) {
  return (dispatch) => {
    return projectAdvancedDocumentsApi
      .notifyUploadAdvanceDocumentsRequest(projectId, prefix, fileNames)
      .then((response) => {
        if (!response.ok) dispatch(showErrorMessage(response.errors));

        return response;
      });
  };
}

export function deleteAdvancedDocumentsRequest(projectId, values) {
  return (dispatch) => {
    return projectAdvancedDocumentsApi
      .deleteAdvancedDocuments(projectId, values)
      .then((response) => {
        if (response.ok) {
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function downloadAdvancedDocumentsFolderRequest(values) {
  return (dispatch) => {
    return projectAdvancedDocumentsApi
      .downloadAdvancedDocumentsFolder(values)
      .then((response) => {
        if (response.ok) {
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function moveAdvancedDocumentsFolderRequest(projectId, values) {
  return (dispatch) => {
    return projectAdvancedDocumentsApi
      .moveAdvancedDocumentsFolder(projectId, values)
      .then((response) => {
        if (response.ok) {
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadDirectorySuccess(directory) {
  return {
    type: DIRECTORY_SET,
    payload: directory,
  };
}

export function loadDirectoryRequest(projectId) {
  return (dispatch) => {
    return directoriesApi.getDirectory(projectId).then((response) => {
      if (response.ok) {
        dispatch(loadDirectorySuccess(response.directory));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}
