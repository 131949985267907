import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';
import moment from 'moment';
import { DueDateFilterUtil } from 'services/utils/due-date-filter-util';

class ProjectOpenItemsApi extends BaseApi {
  getProjectOpenItems(projectId, page, critical, status, dueDateFilter) {
    const dueDateQuery = DueDateFilterUtil.getDueDateQuery(dueDateFilter);
    let filters = `page=${page}`;
    if (critical !== undefined) {
      filters += critical ? '&critical=true' : '&non_critical=true';
    }

    if (status !== undefined) {
      filters +=
        status === 'past_due'
          ? `&${status}=${moment().format('YYYY-MM-DD')}`
          : `&${status}=true`;
    }

    filters += dueDateQuery ? `&${dueDateQuery}` : '';

    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/project_open_items?${filters}`
      )
      .then((response) => {
        return {
          ok: true,
          projectOpenItems: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getProjectOpenItemsAll(page, filters) {
    let query = `page=${page}`;
    Object.keys(filters).forEach((filter) => {
      query += `&${filter}=${filters[filter]}`;
    });

    return axios
      .get(`${this.baseUrl}/project_open_items?${query}`)
      .then((response) => {
        return {
          ok: true,
          projectOpenItems: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getProjectOpenItem(projectId, id) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/project_open_items/${id}`)
      .then((response) => {
        return {
          ok: true,
          projectOpenItem: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getProjectOpenItemsAllCount(filters) {
    let query = '';
    Object.keys(filters).forEach((filter, index) => {
      if (index > 0) {
        query += '&';
      }
      query += `${filter}=${filters[filter]}`;
    });

    return axios
      .get(`${this.baseUrl}/project_open_items/count?${query}`)
      .then((response) => {
        return {
          ok: true,
          count: numeral(response.headers['total']).value(),
          countCritical: numeral(response.headers['total-critical']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getProjectOpenItemsCount(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/project_open_items/count`)
      .then((response) => {
        return {
          ok: true,
          total: numeral(response.headers['total']).value(),
          totalCritical: numeral(response.headers['total-critical']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getProjectOpenItemNotes(id) {
    return axios
      .get(`${this.baseUrl}/project_open_items/${id}/notes`)
      .then((response) => {
        return {
          ok: true,
          projectOpenItemNotes: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  markProjectOpenItemNotesRead(id) {
    return axios
      .put(`${this.baseUrl}/project_open_items/${id}/notes/mark_all_read`)
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createProjectOpenItemNote(id, content) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const apiPayload = new FormData();
    apiPayload.append('note[content]', content.content);

    let index = 0;
    if (content.images && content.images.length > 0) {
      content.images
        .filter((image) => !image.id)
        .forEach((image) => {
          apiPayload.append(
            `note[images_attributes][${index}][image]`,
            image.file,
            image.file.name
          );
          index++;
        });
    }

    index = 0;
    if (content.documents && content.documents.length > 0) {
      content.documents
        .filter((doc) => !doc.id)
        .forEach((doc) => {
          apiPayload.append(
            `note[documents_attributes][${index}][document]`,
            doc.file,
            doc.file.name
          );
          index++;
        });
    }

    return axios
      .post(
        `${this.baseUrl}/project_open_items/${id}/notes`,
        apiPayload,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          projectOpenItemNote: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createProjectOpenItem(projectId, values) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = this.projectOpenItemParams(values);

    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/project_open_items`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          projectOpenItem: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateProjectOpenItem(projectId, id, values) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = this.projectOpenItemParams(values);

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/project_open_items/${id}`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          projectOpenItem: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  respondProjectOpenItem(projectId, id, values) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = this.respondProjectOpenItemParams(values);

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/project_open_items/${id}/respond`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          projectOpenItem: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  deleteProjectOpenItem(projectId, id) {
    return axios
      .delete(`${this.baseUrl}/projects/${projectId}/project_open_items/${id}`)
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  projectOpenItemParams(values) {
    const apiPayload = new FormData();
    apiPayload.append(
      'project_open_item[project_contributor_id]',
      values.projectContributorId || ''
    );
    apiPayload.append(
      'project_open_item[assignee_id]',
      values.assigneeId || ''
    );
    apiPayload.append('project_open_item[resolve_by]', values.resolveBy || '');
    apiPayload.append(
      'project_open_item[description]',
      values.description || ''
    );
    apiPayload.append('project_open_item[critical]', values.critical || false);
    apiPayload.append('project_open_item[resolved]', values.resolved || false);
    apiPayload.append('project_open_item[draft]', values.draft || false);

    if (values.recipientIds && values.recipientIds.length > 0) {
      values.recipientIds.forEach((rcptId) => {
        apiPayload.append('project_open_item[recipient_ids][]', rcptId);
      });
    } else {
      apiPayload.append('project_open_item[recipient_ids][]', []);
    }

    let index = 0;
    if (values.images && values.images.length > 0) {
      values.images
        .filter((image) => !image.id)
        .forEach((image) => {
          apiPayload.append(
            `project_open_item[images_attributes][${index}][image]`,
            image.file,
            image.file.name
          );
          index++;
        });
    }

    if (values.deletedImages && values.deletedImages.length > 0) {
      values.deletedImages
        .filter((image) => image.id)
        .forEach((image) => {
          apiPayload.append(
            `project_open_item[images_attributes][${index}][id]`,
            image.id
          );
          apiPayload.append(
            `project_open_item[images_attributes][${index}][_destroy]`,
            true
          );
          index++;
        });
    }

    index = 0;
    if (values.documents && values.documents.length > 0) {
      values.documents
        .filter((doc) => !doc.id)
        .forEach((doc) => {
          apiPayload.append(
            `project_open_item[documents_attributes][${index}][document]`,
            doc.file,
            doc.file.name
          );
          index++;
        });
    }

    if (values.deletedDocuments && values.deletedDocuments.length > 0) {
      values.deletedDocuments
        .filter((doc) => doc.id)
        .forEach((doc) => {
          apiPayload.append(
            `project_open_item[documents_attributes][${index}][id]`,
            doc.id
          );
          apiPayload.append(
            `project_open_item[documents_attributes][${index}][_destroy]`,
            true
          );
          index++;
        });
    }

    return apiPayload;
  }

  respondProjectOpenItemParams(values) {
    const apiPayload = new FormData();
    apiPayload.append('project_open_item[response]', values.response || '');
    apiPayload.append(
      'project_open_item[draft_response]',
      values.draftResponse || false
    );

    let index = 0;
    if (values.images && values.images.length > 0) {
      values.images
        .filter((image) => !image.id)
        .forEach((image) => {
          apiPayload.append(
            `project_open_item[response_images_attributes][${index}][image]`,
            image.file,
            image.file.name
          );
          index++;
        });
    }

    if (values.deletedImages && values.deletedImages.length > 0) {
      values.deletedImages
        .filter((image) => image.id)
        .forEach((image) => {
          apiPayload.append(
            `project_open_item[response_images_attributes][${index}][id]`,
            image.id
          );
          apiPayload.append(
            `project_open_item[response_images_attributes][${index}][_destroy]`,
            true
          );
          index++;
        });
    }

    index = 0;
    if (values.documents && values.documents.length > 0) {
      values.documents
        .filter((doc) => !doc.id)
        .forEach((doc) => {
          apiPayload.append(
            `project_open_item[response_documents_attributes][${index}][document]`,
            doc.file,
            doc.file.name
          );
          index++;
        });
    }

    if (values.deletedDocuments && values.deletedDocuments.length > 0) {
      values.deletedDocuments
        .filter((doc) => doc.id)
        .forEach((doc) => {
          apiPayload.append(
            `project_open_item[response_documents_attributes][${index}][id]`,
            doc.id
          );
          apiPayload.append(
            `project_open_item[response_documents_attributes][${index}][_destroy]`,
            true
          );
          index++;
        });
    }

    return apiPayload;
  }
}

export const projectOpenItemsApi = new ProjectOpenItemsApi();
