import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Grid } from '@mui/material';
import TreeView from 'components/shared/tree-view/TreeView';
import FormModal from 'components/shared/modal/FormModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getProjectPhotosSelectedIds,
  getProjectPhotosShowMoveTo,
} from './store/selectors';
import {
  loadRequestProjectPhotos,
  setProjectPhotosShowMoveTo,
} from './store/actions';
import useProjectPhotosActions from './ProjectPhotosActions.hook';

const ROOT_ALBUM = {
  id: null,
  title: 'Project Root',
};

const ProjectPhotosMoveToModal = (props) => {
  const { dispatch, show, currentProject, selectedIds } = props;
  const { moveToAlbum } = useProjectPhotosActions(props);
  const [selected, setSelected] = useState(null);

  const onClose = () => {
    dispatch(setProjectPhotosShowMoveTo(false));
  };
  const canMoveItems = (ids) => true;
  const onMove = () => {
    if (canMoveItems(selectedIds)) {
      moveToAlbum(selectedIds, selected).then((response) => {
        if (response.ok) onClose();
      });
    }
  };
  const getAsyncOptions = async (opt) => {
    const response = await dispatch(
      loadRequestProjectPhotos(currentProject.id, { albumId: opt.id }, 'album')
    );
    if (!response.ok) return [];
    return response.albums;
  };
  const onSelect = (opt) =>
    setSelected((s) => (!s || !opt || s.id !== opt.id ? opt : null));
  const disableEnter = (opt) =>
    typeof opt.count_albums !== 'undefined' && opt.count_albums === 0;

  const hasPhotoSelected = () =>
    selectedIds.reduce((acc, curr) => acc || curr.type === 'photo', false);
  const disableCheck = (opt) => opt.id === null && hasPhotoSelected();

  if (!show) return null;

  return (
    <FormModal
      className="project-photos-move-to-modal"
      show={show}
      onClose={onClose}
      subTitle="Move To"
    >
      <Grid
        container
        justifyContent="center"
        paddingX={4}
        paddingBottom={2.5}
        spacing={4}
      >
        <Grid item xs={12}>
          <TreeView
            options={[ROOT_ALBUM]}
            getAsyncOptions={getAsyncOptions}
            selected={selected ? [selected] : []}
            onSelect={onSelect}
            disableEnter={disableEnter}
            disableCheck={disableCheck}
            labelKey={'title'}
            valueKey={'id'}
          />
        </Grid>
        <Grid item>
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label={'Move'}
            onClick={onMove}
            disabled={!selected}
          />
        </Grid>
      </Grid>
    </FormModal>
  );
};

export default connect((state) => ({
  show: getProjectPhotosShowMoveTo(state),
  currentProject: getCurrentProject(state),
  selectedIds: getProjectPhotosSelectedIds(state),
}))(ProjectPhotosMoveToModal);
