import React from 'react';
import { connect } from 'react-redux';

import DragableTable from 'components/shared/table/DragableTable';
import { SCHEDULES_TABLE_HEADERS } from 'domain/schedule';

import SchedulesCategory from './SchedulesCategory';
import { getProjectSchedules } from './store/selectors';

const Schedules = ({ schedules }) => {
  return (
    <>
      <DragableTable
        headers={SCHEDULES_TABLE_HEADERS}
        className={`schedules-table ${
          schedules.length > 0 ? 'table-header' : ''
        }`}
        containerClassName={'schedules-table-header-container'}
        emptyMessage={'No Schedules'}
      />
      {schedules.map((category) => (
        <SchedulesCategory
          key={`schedule_category_${category.id}`}
          category={category}
        />
      ))}
    </>
  );
};

export default connect((state) => ({
  schedules: getProjectSchedules(state),
}))(Schedules);
