import {
  RFI_LOG_INIT,
  RFI_TAB_SELECTED_SET,
  RFI_MODAL_SHOW_SET,
  RFI_MODAL_INIT,
  RFI_FORM_VALUE_UPDATE,
  RFI_QUESTION_VALUE_UPDATE,
  RFI_QUESTION_ERRORS_SET,
  RFI_QUESTION_ADD,
  RFI_QUESTIONS_ATTACH,
  RFI_QUESTION_DELETE,
  RFI_READ_ONLY_SET,
  RFI_FORM_VALUES_SET,
  RFI_FORM_ERRORS_SET,
  RFI_QUESTION_FILE_DELETE,
  RFI_QUESTION_NOTE_ADD,
  RFI_GC_ACCELERATION_SET,
  RFI_LOG_PDF_VIEWER_SET,
  RFI_PDF_VIEWER_SET,
  RFI_SHOW_ERROR_MESSAGE_SET,
  RFI_ADDL_ASSIGNEES_ADD,
  RFI_ADDL_ASSIGNEES_UPDATE,
  RFI_ADDL_ASSIGNEES_DELETE,
  RFI_MODAL_RESPONSE_SHOW_SET,
  RFI_MODAL_RESPONSES_VIEW_SHOW_SET,
} from 'components/admin/projects/details/project-rfi-log/store/action-types';
import { RfiStatusKeys } from 'domain/rfi-status-enum';

const initialState = {
  rfiTabSelected: 2,
  rfiModalShow: false,
  rfiForm: {
    title: '',
    status: RfiStatusKeys.PENDING,
    relCsiCodes: [],
    recipients: [],
    errors: {},
  },
  rfiQuestions: {},
  rfiQuestionsDeleted: [],
  rfiReadOnly: false,
  rfiGcAcceleration: false,
  rfiLogPdfViewer: false,
  rfiPdfViewer: false,
  rfiShowErrorMessage: false,
  rfiResponseModalShow: false,
  rfiResponsesViewModalShow: false,
};
let question = null;
let questions = {};
let addlAssignees = null;

export default function projectRfiLogUiReducer(state = initialState, action) {
  switch (action.type) {
    case RFI_LOG_INIT:
      return initialState;

    case RFI_TAB_SELECTED_SET:
      return {
        ...state,
        rfiTabSelected: action.payload,
      };

    case RFI_MODAL_SHOW_SET:
      return {
        ...state,
        rfiModalShow: action.payload,
      };

    case RFI_MODAL_RESPONSE_SHOW_SET:
      return {
        ...state,
        rfiResponseModalShow: action.payload,
      };
    case RFI_MODAL_RESPONSES_VIEW_SHOW_SET:
      return {
        ...state,
        rfiResponsesViewModalShow: action.payload,
      };

    case RFI_MODAL_INIT:
      return {
        ...state,
        rfiForm: initialState.rfiForm,
        rfiQuestions: initialState.rfiQuestions,
        rfiQuestionsDeleted: initialState.rfiQuestionsDeleted,
        rfiGcAcceleration: initialState.rfiGcAcceleration,
        rfiShowErrorMessage: initialState.rfiShowErrorMessage,
      };

    case RFI_FORM_VALUE_UPDATE:
      return {
        ...state,
        rfiShowErrorMessage: false,
        rfiForm: {
          ...state.rfiForm,
          [action.payload.attribute]: action.payload.value,
          errors: {
            ...state.rfiForm.errors,
            [action.payload.attribute]: false,
          },
        },
      };

    case RFI_FORM_VALUES_SET:
      return {
        ...state,
        rfiForm: {
          ...state.rfiForm,
          ...action.payload,
        },
      };

    case RFI_FORM_ERRORS_SET:
      return {
        ...state,
        rfiForm: {
          ...state.rfiForm,
          errors: action.payload,
        },
      };

    case RFI_QUESTION_VALUE_UPDATE:
      question = state.rfiQuestions[action.payload.key];
      return {
        ...state,
        rfiShowErrorMessage: false,
        rfiQuestions: {
          ...state.rfiQuestions,
          [action.payload.key]: {
            ...question,
            [action.payload.attribute]: action.payload.value,
            errors: {
              ...question.errors,
              [action.payload.attribute]: false,
            },
          },
        },
      };

    case RFI_QUESTION_ERRORS_SET:
      question = state.rfiQuestions[action.payload.key];
      return {
        ...state,
        rfiQuestions: {
          ...state.rfiQuestions,
          [action.payload.key]: {
            ...question,
            errors: action.payload.errors,
          },
        },
      };

    case RFI_QUESTION_ADD:
      return {
        ...state,
        rfiQuestions: {
          ...state.rfiQuestions,
          [action.payload.key]: {
            ...action.payload.item,
            errors: {},
          },
        },
      };

    case RFI_QUESTIONS_ATTACH:
      return {
        ...state,
        rfiQuestions: {
          ...state.rfiQuestions,
          ...action.payload,
        },
      };

    case RFI_QUESTION_DELETE:
      question = state.rfiQuestions[action.payload];
      questions = { ...state.rfiQuestions };
      delete questions[action.payload];
      return {
        ...state,
        rfiQuestions: questions,
        rfiQuestionsDeleted: [...state.rfiQuestionsDeleted, question],
      };

    case RFI_READ_ONLY_SET:
      return {
        ...state,
        rfiReadOnly: action.payload,
      };

    case RFI_QUESTION_FILE_DELETE:
      question = state.rfiQuestions[action.payload.key];
      return {
        ...state,
        rfiQuestions: {
          ...state.rfiQuestions,
          [action.payload.key]: {
            ...question,
            [action.payload.attribute]: [
              ...question[action.payload.attribute],
              action.payload.value,
            ],
          },
        },
      };

    case RFI_QUESTION_NOTE_ADD:
      question = state.rfiQuestions[action.payload.key];
      return {
        ...state,
        rfiQuestions: {
          ...state.rfiQuestions,
          [action.payload.key]: {
            ...question,
            notes: [...question.notes, action.payload.note],
          },
        },
      };

    case RFI_GC_ACCELERATION_SET:
      return {
        ...state,
        rfiGcAcceleration: action.payload,
      };

    case RFI_LOG_PDF_VIEWER_SET:
      return {
        ...state,
        rfiLogPdfViewer: action.payload,
      };

    case RFI_PDF_VIEWER_SET:
      return {
        ...state,
        rfiPdfViewer: action.payload,
      };

    case RFI_SHOW_ERROR_MESSAGE_SET:
      return {
        ...state,
        rfiShowErrorMessage: action.payload,
      };

    case RFI_ADDL_ASSIGNEES_ADD:
      question = state.rfiQuestions[action.payload.key];
      return {
        ...state,
        rfiQuestions: {
          ...state.rfiQuestions,
          [action.payload.key]: {
            ...question,
            addlAssignees: [
              ...question.addlAssignees,
              action.payload.addlAssignee,
            ],
          },
        },
      };

    case RFI_ADDL_ASSIGNEES_UPDATE:
      question = state.rfiQuestions[action.payload.key];
      addlAssignees = [...question.addlAssignees];
      addlAssignees[action.payload.index] = {
        ...addlAssignees[action.payload.index],
        ...action.payload.addlAssignee,
      };
      return {
        ...state,
        rfiQuestions: {
          ...state.rfiQuestions,
          [action.payload.key]: {
            ...question,
            addlAssignees,
          },
        },
      };

    case RFI_ADDL_ASSIGNEES_DELETE:
      question = state.rfiQuestions[action.payload.key];
      addlAssignees = [...question.addlAssignees];
      addlAssignees[action.payload.index] = {
        ...addlAssignees[action.payload.index],
        destroy: true,
      };
      return {
        ...state,
        rfiQuestions: {
          ...state.rfiQuestions,
          [action.payload.key]: {
            ...question,
            addlAssignees,
          },
        },
      };

    default:
      return state;
  }
}
