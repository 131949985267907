import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import FormModal from 'components/shared/modal/FormModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import SubmittalDocuments from 'components/admin/projects/details/project-submittal-log/SubmittalDocuments';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import ReadOnlyInput from 'components/shared/input/ReadOnlyInput';
import Submittal from 'domain/submittal';
import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';
import { setSubmittalPdfViewer } from 'components/admin/projects/details/project-submittal-log/store/actions';
import { downloadFile } from 'services/utils/download-util';

import './SubmittalResponsesViewModal.css';
import { getSubmittalResponsesViewModalShow } from './store/selectors';
import { setSubmittalResponsesViewModalShow } from './store/actions';

export const SubmittalResponsesViewModal = (props) => {
  const {
    form,
    canAddRemarks,
    currentUser,
    submittal,
    makeAssignedLabel,
    makeResponseLabel,
    handleFormChange,
    handleFormDeleteFile,
    show,
    dispatch,
  } = props;

  const handleClose = () => {
    dispatch(setSubmittalResponsesViewModalShow(false));
  };

  const handleShowEmailModal = () => {
    dispatch(setShowEmailModal(true));
  };

  const hasPdf = () => {
    const { pdf_document } = submittal;
    return pdf_document && pdf_document.document && pdf_document.document.url
      ? true
      : false;
  };

  const handleViewPdf = () => {
    dispatch(setSubmittalPdfViewer(true));
  };

  const handleDownloadPdf = () => {
    const { submittal, project } = this.props;
    const { formatted_number: number, pdf_document } = submittal;
    const file = pdf_document.document.url + `?time=${moment().unix()}`;
    const fileName = `${project.name} - Submittal ${number ? number : 'Draft'}`;
    downloadFile(file, fileName);
  };

  const additionalAssignees =
    submittal && submittal.submittal_addl_assignees
      ? submittal.submittal_addl_assignees
      : [];
  const submittalWasAccelerated =
    submittal && submittal.assignee_id !== submittal.responded_by_id;
  const newRevision = Submittal.isNewRevision(submittal);

  return (
    <FormModal
      show={show}
      onClose={handleClose}
      className="submittal-response-modal"
      actions={[
        submittal && !newRevision && (
          <Button
            key="email"
            type={BUTTON_TYPES.LINK}
            color={BUTTON_COLORS.GREEN}
            label="Email"
            onClick={handleShowEmailModal}
          />
        ),
        submittal && !newRevision && hasPdf() && (
          <Button
            key="view_pdf"
            type={BUTTON_TYPES.LINK}
            color={BUTTON_COLORS.GREEN}
            label="View PDF"
            onClick={handleViewPdf}
          />
        ),
        submittal && !newRevision && hasPdf() && (
          <Button
            key="download_pdf"
            type={BUTTON_TYPES.LINK}
            color={BUTTON_COLORS.GREEN}
            label="Download"
            onClick={handleDownloadPdf}
          />
        ),
      ].filter((action) => action)}
    >
      <DetailsTitle
        className="submittal-response-title official-response"
        title="Official Responses"
        onClose={handleClose}
      />
      <div className="submittal-response-modal-content-container">
        {
          <div className="form-block">
            {form.responded_by && submittalWasAccelerated && (
              <FormControlBlock
                className="highlighted"
                label="Responded By"
                control={makeResponseLabel(form.responded_by, form.returned_at)}
              />
            )}
          </div>
        }
        <div className="form-block">
          {form.responded_by && !submittalWasAccelerated ? (
            <FormControlBlock
              className="highlighted"
              label="Responded By"
              control={makeResponseLabel(form.responded_by, form.returned_at)}
            />
          ) : (
            <FormControlBlock
              className="highlighted"
              label="Assigned To"
              control={makeAssignedLabel(form.assignee)}
            />
          )}
        </div>
        {form.stamp_title && (
          <div className="form-block">
            <FormControlBlock
              label="Stamp"
              control={<ReadOnlyInput value={form.stamp_title} />}
            />
          </div>
        )}
        <div className="form-block">
          <FormControlBlock
            label={(!canAddRemarks || !!form.remarks) && 'Remarks'}
            control={
              <TextareaAutosizeWrapper
                placeholder="Remarks"
                disabled={!canAddRemarks}
                initialValue={form.remarks || (!canAddRemarks ? '-' : '')}
                onChange={(value) => handleFormChange('remarks', value)}
              />
            }
          />
        </div>
        <SubmittalDocuments
          currentUser={currentUser}
          submittal={submittal}
          form={form}
          fileType="res"
          handleFormChange={handleFormChange}
          handleFormDeleteFile={handleFormDeleteFile}
          readOnly={true}
        />

        {additionalAssignees.length > 0 && (
          <React.Fragment>
            <DetailsTitle
              className="addl-assignees-responses"
              title={`Contributors Responses`}
            />
            {additionalAssignees.map((addlAssignee, index) => (
              <React.Fragment key={index}>
                <div className="form-block">
                  {addlAssignee.stamp_action ? (
                    <FormControlBlock
                      className="highlighted"
                      label="Responded By"
                      control={makeResponseLabel(
                        addlAssignee.assignee,
                        addlAssignee.returned_at
                      )}
                    />
                  ) : (
                    <FormControlBlock
                      className="highlighted"
                      label="Assigned To"
                      control={makeAssignedLabel(addlAssignee.assignee)}
                    />
                  )}
                </div>
                {addlAssignee.stamp_title && (
                  <div className="form-block">
                    <FormControlBlock
                      label="Stamp"
                      control={
                        <ReadOnlyInput value={addlAssignee.stamp_title} />
                      }
                    />
                  </div>
                )}
                <SubmittalDocuments
                  currentUser={currentUser}
                  submittal={submittal}
                  submittalAddlAssignee={addlAssignee}
                  form={form}
                  fileType="res"
                  handleFormChange={handleFormChange}
                  handleFormDeleteFile={handleFormDeleteFile}
                  readOnly={true}
                />
              </React.Fragment>
            ))}
          </React.Fragment>
        )}
        <div className="submittal-response-buttons">
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.WHITE}
            label="Cancel"
            onClick={handleClose}
          />
        </div>
      </div>
    </FormModal>
  );
};

export default connect((state) => {
  return {
    show: getSubmittalResponsesViewModalShow(state),
  };
})(SubmittalResponsesViewModal);
