import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import ProjectEmailModal from 'components/admin/projects/details/project-email-modal/ProjectEmailModal';

import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/selectors';
import { sendProjectPhotosEmail } from './store/actions';
import { getProjectPhotosSelectedIds } from './store/selectors';

const ProjectPhotosEmailModal = (props) => {
  const { dispatch, show, ids, currentProject } = props;

  const handleSendEmail = ({ recipients, subject, message }) => {
    dispatch(
      sendProjectPhotosEmail(
        currentProject.id,
        ids,
        recipients,
        subject,
        message
      )
    );
  };

  const buildEmailModalSubject = () => {
    return `${currentProject.number} ${currentProject.name} - ${
      currentProject.client.company_name
    } - Photos ${moment().format('MM/DD/YYYY')}`;
  };

  const buildEmailModalMessage = () => {
    let location = '';
    if (currentProject.building && currentProject.building.location) {
      const buildingLocation = currentProject.building.location;
      location = ` at ${buildingLocation.street_address_1}, ${buildingLocation.city} ${buildingLocation.state}`;
    }

    return `Please find the attached photos for Project ${currentProject.name} located at ${location}. If you have any questions, please reach out via chat or email.`;
  };

  if (!show) return null;

  return (
    <ProjectEmailModal
      subTitle={currentProject.name}
      projectId={currentProject.id}
      handleSendEmail={handleSendEmail}
      initialSubject={buildEmailModalSubject()}
      initialMessage={buildEmailModalMessage()}
      itemType="ProjectPhotos"
      itemId={ids[0].id}
    />
  );
};

export default connect((state) => ({
  show: getShowEmailModal(state),
  ids: getProjectPhotosSelectedIds(state),
  currentProject: getCurrentProject(state),
}))(ProjectPhotosEmailModal);
