import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import FormModal from 'components/shared/modal/FormModal';
import SimpleTable from 'components/shared/table/SimpleTable';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import SearchBar from 'components/shared/navigation/SearchBar';

import { calculateDateDuration } from 'services/utils/date-util';
import { SCHEDULES_TABLE_HEADERS } from 'domain/schedule';
import SchedulePermissions from 'permissions/schedule-permissions';

import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';

import {
  restoreProjectSchedule,
  setModalResolvedProjectSchedule,
} from './store/actions';
import {
  getProjectSchedulesCurrentCategory,
  getProjectSchedulesResolvedModal,
} from './store/selectors';
import './ProjectScheduleResolvedModal.css';

const ProjectScheduleResolvedModal = (props) => {
  const { dispatch, currentUser, project, show, category } = props;
  const [search, setSearch] = useState('');

  const handleClose = () => dispatch(setModalResolvedProjectSchedule(false));

  const handleRestore = (id) =>
    dispatch(restoreProjectSchedule(project.id, category.id, id));
  const canRestore = () =>
    SchedulePermissions.canEdit(currentUser, project, category);

  const parseDate = (date) => {
    const d = moment(date);
    return d.isValid() ? d.utc().format('MM/DD/YYYY') : '--';
  };
  const getRow = (row) => {
    return [
      row.description,
      calculateDateDuration(row.current_start, row.current_completion),
      parseDate(row.original_start),
      parseDate(row.previous_start),
      parseDate(row.current_start),
      calculateDateDuration(row.original_start, row.current_start),
      parseDate(row.original_completion),
      parseDate(row.previous_completion),
      parseDate(row.current_completion),
      calculateDateDuration(row.original_completion, row.current_completion),
      row.created_by.full_name,
      canRestore() ? (
        <Button
          type={BUTTON_TYPES.LINK}
          color={BUTTON_COLORS.GREEN}
          label={'Restore'}
          onClick={() => handleRestore(row.id)}
        />
      ) : (
        ''
      ),
    ];
  };
  const getRows = () =>
    category.resolved_schedules
      .filter((s) => {
        return (
          !search ||
          s.description.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        );
      })
      .map((r, i) => getRow(r, i));

  if (!show) return null;

  const titleComponent = (
    <SearchBar
      title={category.name}
      searchText={search}
      onSearchTextChange={setSearch}
      onSearch={setSearch}
    />
  );

  return (
    <FormModal
      className={`schedules-resolved-modal`}
      show={show}
      onClose={handleClose}
      subTitle={'Resolved Items'}
      title={titleComponent}
    >
      <SimpleTable
        className="schedule-resolved-table"
        emptyMessage={`No Resolved Items`}
        headers={SCHEDULES_TABLE_HEADERS}
        actionCells={1}
        rows={getRows()}
        stickyHeader={true}
      />
    </FormModal>
  );
};

export default connect((state) => ({
  show: getProjectSchedulesResolvedModal(state),
  currentUser: getCurrentUser(state),
  project: getCurrentProject(state),
  category: getProjectSchedulesCurrentCategory(state),
}))(ProjectScheduleResolvedModal);
