import { showErrorMessage } from 'actions/messages';
import { projectProposalsApi } from 'services/api/project-proposals-api';
import { projectsApi } from 'services/api/projects-api';

import { csiCodesApi } from 'services/api/csi-codes-api';
import {
  INIT_PROJECT_PROPOSAL,
  TOGGLE_CREATE_PROPOSAL,
  TOGGLE_CREATE_PROPOSAL_SUCCESS,
  TOGGLE_PDF_VIEWER_SUCCESS,
  TOGGLE_APPROVE_PROPOSAL_SUCCESS,
  PROJECT_PROPOSAL_SET,
  PROJECT_PROPOSAL_PDF_SET,
  PROJECT_PROPOSAL_ITEMS_SET,
  PROJECT_PROPOSAL_ADDL_ITEM_ADD,
  PROJECT_PROPOSAL_ADDL_ITEM_VALUE_UPDATE,
  PROJECT_PROPOSAL_ITEM_ADD,
  PROJECT_PROPOSAL_ITEM_VALUE_UPDATE,
  PROJECT_PROPOSAL_SUB_ITEM_ADD,
  PROJECT_PROPOSAL_SUB_ITEM_VALUE_UPDATE,
  PROJECT_PROPOSAL_FORM_VALUE_UPDATE,
  CSI_CODES_SET,
  PROJECT_PROPOSAL_ITEM_DELETE_TOGGLE,
  PROJECT_PROPOSAL_ITEMS_RESET,
  PROJECT_PROPOSAL_READ_ONLY_SET,
  AWARD_RECOMMENDATION_TOGGLE,
  SET_SHOW_CANCEL_MODAL,
  SET_SHOW_LOST_MODAL,
  SET_DATE_MODAL,
  SET_MESSAGE,
  SET_TERMINATION_CATEGORY,
  INIT_PROJECT_LOST_CANCEL_MODAL,
} from 'components/admin/projects/details/project-proposal/store/action-types';
import { loadProjectRequest } from 'components/admin/projects/details/store/actions';
import { companiesApi } from 'services/api/companies-api';
import numeral from 'numeral';

export function initProjectProposal() {
  return {
    type: INIT_PROJECT_PROPOSAL,
  };
}

export function toggleCreateProposal() {
  return {
    type: TOGGLE_CREATE_PROPOSAL,
  };
}

export function toggleCreateProposalSuccess() {
  return {
    type: TOGGLE_CREATE_PROPOSAL_SUCCESS,
  };
}

export function togglePdfViewer() {
  return {
    type: TOGGLE_PDF_VIEWER_SUCCESS,
  };
}

export function toggleApproveProposalSuccess() {
  return {
    type: TOGGLE_APPROVE_PROPOSAL_SUCCESS,
  };
}

export function loadProjectProposalSuccess(projectProposal) {
  return {
    type: PROJECT_PROPOSAL_SET,
    payload: projectProposal,
  };
}

export function buildProjectProposalForm(projectProposal) {
  return (dispatch) => {
    let hasAwardRecommendation = false;
    let totalTradeCosts = 0;
    const items = {};
    const addlItems = {
      permits: {},
      generalConditions: {},
      insurance: {},
      fee: {},
      contingency: {},
    };
    projectProposal.project_proposal_items.forEach((item, index) => {
      const subItems = {};
      item.project_proposal_sub_items.forEach((subItem, index) => {
        subItems[index] = {
          ...subItem,
          tradeCosts: subItem.trade_costs,
          awardRecommendationId:
            subItem.award_recommendation && subItem.award_recommendation.id,
          awardRecommendationName:
            subItem.award_recommendation && subItem.award_recommendation.name,
        };
        hasAwardRecommendation =
          hasAwardRecommendation || !!subItem.award_recommendation;
        totalTradeCosts += numeral(subItem.trade_costs).value();
      });
      items[index] = {
        id: item.id,
        csiCodeId: item.csi_code.id,
        tradeCosts: item.trade_costs,
        awardRecommendationId:
          item.award_recommendation && item.award_recommendation.id,
        awardRecommendationName:
          item.award_recommendation && item.award_recommendation.name,
        subItems: subItems,
      };

      hasAwardRecommendation =
        hasAwardRecommendation || !!item.award_recommendation;
      totalTradeCosts += numeral(item.trade_costs).value();
    });
    [
      ['costsShownToPrimary', 'costs_shown_to_primary'],
      ['permits'],
      ['generalConditions', 'general_conditions'],
      ['insurance'],
      ['fee'],
      ['contingency'],
    ].forEach((attr) => {
      if (projectProposal[attr[1] || attr[0]] !== undefined) {
        dispatch(
          updateProjectProposalFormValue(
            attr[0],
            projectProposal[attr[1] || attr[0]]
          )
        );
      }

      if (projectProposal[`${attr[1] || attr[0]}_items`] !== undefined) {
        projectProposal[`${attr[1] || attr[0]}_items`].forEach(
          (item, index) => {
            addlItems[attr[0]][index] = { ...item };
          }
        );
      }
    });

    if (!hasAwardRecommendation) {
      dispatch(toggleAwardRecommendation());
    }

    dispatch(
      updateProjectProposalFormValue(
        'insuranceRatio',
        totalTradeCosts ? projectProposal.insurance / totalTradeCosts : 1
      )
    );
    dispatch(
      updateProjectProposalFormValue(
        'feeRatio',
        totalTradeCosts ? projectProposal.fee / totalTradeCosts : 1
      )
    );
    dispatch(setProjectProposalItems(items, addlItems));
  };
}

export function loadProjectProposalRequest(projectId) {
  return (dispatch) => {
    return projectProposalsApi
      .getProjectProposal(projectId)
      .then((response) => {
        if (response.ok) {
          dispatch(loadProjectProposalSuccess(response.projectProposal));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadProjectProposalPdfSuccess(pdf) {
  return {
    type: PROJECT_PROPOSAL_PDF_SET,
    payload: pdf,
  };
}

export function loadProjectProposalPdfRequest(projectId) {
  return (dispatch) => {
    return projectProposalsApi
      .getProjectProposalPdf(projectId)
      .then((response) => {
        if (response.ok) {
          dispatch(loadProjectProposalPdfSuccess(response.pdf));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function newItem(items, csiCodeId) {
  const key = Object.keys(items).length;
  const item = {
    csiCodeId: csiCodeId || '',
    newItem: !csiCodeId,
    tradeCosts: '',
    subItems: {},
  };
  return (dispatch) => {
    dispatch(addProjectProposalItem(key, item));
  };
}

export function addProjectProposalItem(key, item) {
  return {
    type: PROJECT_PROPOSAL_ITEM_ADD,
    payload: { key: key, item: item },
  };
}

export function updateProjectProposalItemValue(key, attribute, value) {
  return {
    type: PROJECT_PROPOSAL_ITEM_VALUE_UPDATE,
    payload: { key: key, attribute: attribute, value: value },
  };
}

export function newSubItem(itemKey, item, csiCode) {
  const keys = Object.keys(item.subItems || {});
  const key = keys.length;
  const letter = keys.filter((key) => !item.subItems[key].delete).length;
  const subItem = {
    division: `${csiCode.division}.${String.fromCharCode(97 + letter)}`,
    label: '',
    tradeCosts: '',
  };
  return (dispatch) => {
    dispatch(addProjectProposalSubItem(itemKey, key, subItem));
  };
}

export function addProjectProposalSubItem(itemKey, key, subItem) {
  return {
    type: PROJECT_PROPOSAL_SUB_ITEM_ADD,
    payload: { itemKey, key, subItem },
  };
}

export function updateProjectProposalSubItemValue(
  itemKey,
  key,
  attribute,
  value
) {
  return {
    type: PROJECT_PROPOSAL_SUB_ITEM_VALUE_UPDATE,
    payload: { itemKey, key, attribute, value },
  };
}

export function newAddlItem(itemKey, subItems) {
  const key = Object.keys(subItems || {}).length;
  const item = {
    summary: '',
    cost: '',
  };
  return (dispatch) => {
    dispatch(addProjectProposalAddlItem(itemKey, key, item));
  };
}

export function addProjectProposalAddlItem(type, key, item) {
  return {
    type: PROJECT_PROPOSAL_ADDL_ITEM_ADD,
    payload: { type, key, item },
  };
}

export function updateProjectProposalAddlItemValue(
  type,
  key,
  attribute,
  value
) {
  return {
    type: PROJECT_PROPOSAL_ADDL_ITEM_VALUE_UPDATE,
    payload: { type, key, attribute, value },
  };
}

export function setProjectProposalItems(items, addlItems) {
  return {
    type: PROJECT_PROPOSAL_ITEMS_SET,
    payload: { items, addlItems },
  };
}

export function updateProjectProposalFormValue(attribute, value) {
  return {
    type: PROJECT_PROPOSAL_FORM_VALUE_UPDATE,
    payload: { attribute: attribute, value: value },
  };
}

export function createProjectProposal(projectId, values) {
  return (dispatch) => {
    return projectProposalsApi
      .createProjectProposal(projectId, values)
      .then((response) => {
        if (response.ok) {
          dispatch(loadProjectProposalSuccess(response.projectProposal));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function updateProjectProposal(projectId, values) {
  return (dispatch) => {
    return projectProposalsApi
      .updateProjectProposal(projectId, values)
      .then((response) => {
        if (response.ok) {
          dispatch(loadProjectProposalSuccess(response.projectProposal));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function updateProjectProposalCostsShown(projectId, shown) {
  return (dispatch) => {
    return projectProposalsApi
      .updateProjectProposalCostsShown(projectId, shown)
      .then((response) => {
        if (response.ok) {
          dispatch(loadProjectProposalSuccess(response.projectProposal));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function updateProject(projectId, values) {
  return (dispatch) => {
    return projectsApi.updateProject(projectId, values).then((response) => {
      if (response.ok) {
        dispatch(loadProjectRequest(projectId));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function approveProjectProposal(projectId, status) {
  return (dispatch) => {
    return projectProposalsApi
      .approveProjectProposal(projectId, status)
      .then((response) => {
        if (response.ok) {
          dispatch(loadProjectProposalSuccess(response.projectProposal));
          dispatch(loadProjectRequest(projectId));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadCsiCodesSuccess(csiCodes) {
  return {
    type: CSI_CODES_SET,
    payload: csiCodes,
  };
}

export function loadCsiCodes() {
  return (dispatch) => {
    return csiCodesApi.getCsiCodes().then((response) => {
      if (response.ok) {
        dispatch(loadCsiCodesSuccess(response.csiCodes));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function fetchSubcontractors(byName, _byCsiCode, page) {
  return (dispatch) => {
    return companiesApi.getCompanies(null, byName, page).then((response) => {
      if (!response.ok) {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function toggleItemDelete(key) {
  return {
    type: PROJECT_PROPOSAL_ITEM_DELETE_TOGGLE,
    payload: key,
  };
}

export function resetProjectProposalItems() {
  return {
    type: PROJECT_PROPOSAL_ITEMS_RESET,
  };
}

export function setReadOnly(readOnly) {
  return {
    type: PROJECT_PROPOSAL_READ_ONLY_SET,
    payload: readOnly,
  };
}

export function toggleAwardRecommendation() {
  return {
    type: AWARD_RECOMMENDATION_TOGGLE,
  };
}

export function setShowLostModal(value) {
  return {
    type: SET_SHOW_LOST_MODAL,
    payload: value,
  };
}

export function setShowCancelModal(value) {
  return {
    type: SET_SHOW_CANCEL_MODAL,
    payload: value,
  };
}

export function setDate(date) {
  return {
    type: SET_DATE_MODAL,
    payload: date,
  };
}

export function setMessage(message) {
  return {
    type: SET_MESSAGE,
    payload: message,
  };
}

export function setTerminationCategory(value) {
  return {
    type: SET_TERMINATION_CATEGORY,
    payload: value,
  };
}

export function initProjectLostCancelModal(payload) {
  return {
    type: INIT_PROJECT_LOST_CANCEL_MODAL,
    payload: payload,
  };
}
