import {
  CLIENT_TEAM_BUILDINGS_LOAD_SUCCESS,
  CLIENT_TEAM_CLIENT_LOAD_SUCCESS,
  CLIENT_TEAM_ROLES_LOAD_SUCCESS,
  CLIENT_TEAM_USERS_ADD,
  CLIENT_TEAM_USERS_EDIT,
  CLIENT_TEAM_USERS_LOAD_SUCCESS,
  CLIENT_TEAM_USERS_REMOVE,
} from 'actions/admin/team/action-types';
import { sortByAttribute } from 'services/utils/sort-util';
import { AUTHENTICATION_SUCCESS } from 'actions/authentication';

const initialState = {
  buildings: [],
  client: null,
  roles: [],
  users: [],
};

export default function clientTeamDataReducer(state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATION_SUCCESS:
      // Remove info after login
      return initialState;

    case CLIENT_TEAM_BUILDINGS_LOAD_SUCCESS:
      return {
        ...state,
        buildings: action.payload,
      };

    case CLIENT_TEAM_CLIENT_LOAD_SUCCESS:
      return {
        ...state,
        client: action.payload,
      };

    case CLIENT_TEAM_ROLES_LOAD_SUCCESS:
      return {
        ...state,
        roles: action.payload,
      };

    case CLIENT_TEAM_USERS_ADD:
      return {
        ...state,
        users: sortByAttribute(
          state.users.concat([action.payload]),
          'first_name'
        ),
      };

    case CLIENT_TEAM_USERS_EDIT:
      return {
        ...state,
        users: state.users.map((user) => {
          if (user.id === action.payload.id) {
            return action.payload;
          } else {
            return user;
          }
        }),
      };

    case CLIENT_TEAM_USERS_LOAD_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };

    case CLIENT_TEAM_USERS_REMOVE:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload),
      };

    default:
      return state;
  }
}
