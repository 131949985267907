import {
  INIT_PROJECT_EMAIL_MODAL,
  SET_SHOW_MODAL,
  SET_EMAILS,
  ADD_EMAIL,
  ADD_RECIPIENT,
  SET_RECIPIENTS,
  SAVED_PDFTRON_SUCCESS,
  RESET_PDFTRON_MESSAGES,
} from 'components/shared/pdf-viewer-bluebeam/store/action-types';

export const initialState = {
  showModal: false,
  showCustomEmailError: false,
  showNewCustomEmail: false,
  emails: [],
  subject: '',
  message: '',
  recipients: [],
  customEmail: '',
  pdfTronSuccess: null,
};

export default function usersNotificationModalUiReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case INIT_PROJECT_EMAIL_MODAL:
      return {
        ...state,
        recipients: [],
      };
    case SET_SHOW_MODAL:
      return {
        ...state,
        showModal: action.payload.modal,
        documentId: action.payload.documentId,
      };
    case SET_EMAILS:
      return {
        ...state,
        emails: action.payload,
      };
    case ADD_EMAIL:
      return {
        ...state,
        emails: [action.payload, ...state.emails],
      };
    case ADD_RECIPIENT:
      return {
        ...state,
        recipients: [action.payload, ...state.recipients],
      };
    case SET_RECIPIENTS:
      return {
        ...state,
        recipients: action.payload,
      };

    case SAVED_PDFTRON_SUCCESS:
      return {
        ...state,
        pdfTronSuccess: action.payload,
      };

    case RESET_PDFTRON_MESSAGES:
      return {
        ...state,
        pdfTronSuccess: null,
      };

    default:
      return state;
  }
}
