import axios from 'axios';
import BaseApi from './base-api';

class RfiQuestionsApi extends BaseApi {
  getNotes(questionId) {
    return axios
      .get(`${this.baseUrl}/rfi_questions/${questionId}/notes`)
      .then((response) => {
        return {
          ok: true,
          notes: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  markNotesRead(questionId) {
    return axios
      .put(`${this.baseUrl}/rfi_questions/${questionId}/notes/mark_all_read`)
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createNote(questionId, content) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const apiPayload = new FormData();
    apiPayload.append('note[content]', content.note);

    let index = 0;
    if (content.images && content.images.length > 0) {
      content.images
        .filter((image) => !image.id)
        .forEach((image) => {
          apiPayload.append(
            `note[images_attributes][${index}][image]`,
            image.file,
            image.file.name
          );
          index++;
        });
    }

    index = 0;
    if (content.documents && content.documents.length > 0) {
      content.documents
        .filter((doc) => !doc.id)
        .forEach((doc) => {
          apiPayload.append(
            `note[documents_attributes][${index}][document]`,
            doc.file,
            doc.file.name
          );
          index++;
        });
    }

    return axios
      .post(
        `${this.baseUrl}/rfi_questions/${questionId}/notes`,
        apiPayload,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          note: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const rfiQuestionsApi = new RfiQuestionsApi();
