import { snakeToCamel } from '../services/utils/case-utils';
import { companyRoles, userRoleLabel } from './user-role-enum';
import User from './user';
export const MEETING_TABS = [
  { title: 'Meetings', id: 'meetings' },
  { title: 'Archived', id: 'archived' },
];

export const DEFAULT_MEETING_TAB = 'meetings';

export const INSTANCE_FORM_TABS = [
  { title: 'General Info', id: 'general_info' },
  { title: 'Attendance', id: 'action_items' },
  { title: 'Meeting Corrections', id: 'meeting_corrections' },
  { title: 'Attachments', id: 'attachments' },
  { title: 'Notepad', id: 'notepad' },
];

export const MEETING_FORM_TABS = [
  { title: 'General Info', id: 'general_info' },
  { title: 'Schedule', id: 'schedule' },
  { title: 'Attendance', id: 'attendance' },
];

export const DEFAULT_MEETING_FORM_TAB = 'general_info';
export const DEFAULT_MEETING_INFO_TAB = 'general_info';
export const DEFAULT_INSTANCE_FORM_TAB = 'general_info';
export const defaultMeetingsTitleOptions = [
  { value: 'OAC', label: 'OAC' },
  { value: 'Safety', label: 'Safety' },
  { value: 'Preconstruction', label: 'Preconstruction' },
  { value: 'addTitle', label: 'Add Title' },
  { value: 'editTitle', label: 'Edit Title' },
];

export const defaultMeetingsTypeOptions = [
  { value: 'Construction', label: 'Construction' },
  { value: 'Design', label: 'Design' },
  { value: 'Owner', label: 'Owner' },
  { value: 'addType', label: 'Add Type' },
  { value: 'editType', label: 'Edit Type' },
];

export const frequencyOptions = [
  { value: 'daily', label: 'Daily', id: 'daily' },
  { value: 'monday', label: ' Weekly, Monday', id: 'monday' },
  { value: 'tuesday', label: ' Weekly, Tuesday', id: 'tuesday' },
  { value: 'wednesday', label: ' Weekly, Wednesday', id: 'wednesday' },
  { value: 'thursday', label: ' Weekly, Thursday', id: 'thursday' },
  { value: 'friday', label: ' Weekly, Friday', id: 'friday' },
];

export const NEW_ACTION_ITEM_DEFAULT = {
  description: '',
  category: '',
  responsibleId: null,
  responsibleCompanyId: null,
  responsibleCompany: null,
  responsible: null,
  resolvedById: null,
  dueDate: '',
  priority: '',
  status: null,
  itemNumber: null,
  createdAt: '',
  resolvedAt: 'nul',
  actionItemsUpdate: [],
};

export const NEW_ACTION_ITEM_UPDATE_DEFAULT = {
  description: '',
  category: '',
  responsibleId: null,
  responsibleCompanyId: null,
  responsibleCompany: null,
  responsible: null,
  resolvedById: null,
  dueDate: '',
  priority: '',
  status: null,
  createdAt: '',
  resolvedAt: null,
  nestedUpdates: [],
};

export const DEFAULT_INSTANCE_FORM = {
  isQuickStart: false,
  editAttendees: false,
  meetingType: '',
  title: '',
  address: '',
  location: '',
  access: '',
  jobWalk: '',
  ranking: '',
  callInfo: '',
  callLink: '',
  webLink: '',
  otherInfo: '',
  privateMeeting: false,
  startDate: null,
  endDate: null,
  recurrence: '',
  startTime: null,
  endTime: null,
  meetingExceptions: [],
  meetingsActionItems: [],
  listBy: '',
  company: '',
  meetingsTitles: defaultMeetingsTitleOptions,
  meetingsTypes: defaultMeetingsTypeOptions,
  meetingsInstancesNotes: { notes: '' },
  attachedImages: [],
  attachedDocuments: [],
  deletedImages: [],
  deletedDocuments: [],
  attendees: [],
  attendeeIds: [],
  meetingsCorrections: [],
};
export const DEFAULT_PARENT_FORM = {
  meetingType: '',
  title: '',
  address: '',
  location: '',
  access: '',
  jobWalk: '',
  ranking: '',
  callInfo: '',
  callLink: '',
  webLink: '',
  otherInfo: '',
  privateMeeting: false,
  startDate: null,
  endDate: null,
  recurrence: '',
  startTime: null,
  endTime: null,
  meetingExceptions: [],
  listBy: '',
  company: '',
  meetingsTitles: defaultMeetingsTitleOptions,
  meetingsTypes: defaultMeetingsTypeOptions,
  meetingsInstances: [],
};

class Meeting {
  isCreatorUser = (user, meeting) =>
    !!meeting && meeting.created_by && meeting.created_by.id === user.id;
  creatorCompany = (meeting) => {
    const creatorUser = meeting && meeting.created_by;
    return creatorUser.label.split(' - ')[1];
  };
  isCreatorCompany = (user, meeting) => {
    const camelCaseMeeting = snakeToCamel(meeting);
    const userCompanyId = User.companyId(user);
    const creatorCompanyId =
      camelCaseMeeting.createdBy && camelCaseMeeting.createdBy.accountableId;
    return userCompanyId === creatorCompanyId;
  };
  isAttendee = (user, meeting) => {
    if (!meeting.attendee_ids) return false;
    return meeting.attendee_ids.includes(user.id);
  };
  hasErrorsGenInfoSection = (errors) => {
    const fieldsWithError = errors ? Object.keys(errors) : [];
    return fieldsWithError.includes('date');
  };

  hasErrorsAttendanceSection = (errors) => {
    const fieldsWithError = errors ? Object.keys(errors) : [];
    return fieldsWithError.includes('date');
  };
  getPreviousInstanceInParent = (parent) => {
    if (!parent) return null;
    if (parent && !parent.meetings_instances) return null;
    if (
      parent &&
      parent.meetings_instances &&
      parent.meetings_instances.length === 0
    )
      return null;
    const meetingInstances = parent.meetings_instances;
    const lastInstance = meetingInstances.reduce((max, instance) => {
      if (!max || instance.created_at > max.created_at) {
        return instance;
      }
      return max;
    }, null);
    return lastInstance;
  };
  setNewInstanceWithPreviousInstanceData = (parent) => {
    const lastInstance = this.getPreviousInstanceInParent(parent);
    if (!lastInstance) {
      const parentWithoutId = (({ id, meetings_instances, ...rest }) => rest)(
        parent
      );
      return snakeToCamel({
        ...DEFAULT_INSTANCE_FORM,
        ...parentWithoutId,
        meetingDate: parent.start_date,
        meetingNumber: 1,
        formattedNumber: '01',
      });
    }

    const previousActionItems = lastInstance.meetings_action_items;
    const openItems = previousActionItems.filter((ai) => ai.status === 'open');

    let editedItems = openItems.map((ai) => {
      // Remove id and meetings_instance_id for action item
      const editedAi = {
        ...ai,
        meetings_instance_id: undefined,
        id: undefined,
      };

      // Also remove id for each action_updates if they exist
      if (editedAi.action_items_updates) {
        editedAi.action_items_updates = editedAi.action_items_updates.map(
          (update) => {
            const editedUpdate = {
              ...update,
              id: undefined,
              meetings_instance_id: undefined,
            };

            // Further remove id for each nested_updates within action_updates
            if (editedUpdate.nested_updates) {
              editedUpdate.nested_updates = editedUpdate.nested_updates.map(
                (nested) => ({
                  ...nested,
                  id: undefined,
                  parent_update_id: undefined,
                })
              );
            }

            return editedUpdate;
          }
        );
      }

      return editedAi;
    });

    const lastInstanceWithoutId = (({
      id,
      meetings_instances,
      agenda_published_by_id,
      agenda_republished_by_id,
      minutes_published_by_id,
      minutes_republished_by_id,
      agenda_published_date,
      agenda_republished_date,
      minutes_published_date,
      minutes_republished_date,
      meeting_date,
      meetings_agenda_backup,
      ...rest
    }) => rest)(lastInstance);

    return snakeToCamel({
      ...lastInstanceWithoutId,
      meetings_action_items: editedItems,
      meeting_date: parent.next_meeting,
      start_date: parent.next_meeting,
      meeting_number: lastInstanceWithoutId.meeting_number + 1,
      formatted_number: this.numberFormatting(
        lastInstanceWithoutId.meeting_number + 1
      ),
    });
  };

  setNewInstanceFormForNewAgenda = (parent) => {
    const auxForm = snakeToCamel(
      this.setNewInstanceWithPreviousInstanceData(parent)
    );
    const auxFormItems = auxForm.meetingsActionItems;
    const formValues = {
      ...auxForm,
      status: 'draft_agenda',
      isAgenda: true,
      meetingsParentId: parent.id,
      meetingsActionItems: auxFormItems || [],
      attachedDocuments: [],
      attachedImages: [],
      deletedDocuments: [],
      deletedImages: [],
    };
    return formValues;
  };
  setNewInstanceFormForNewMinutes = (parent) => {
    const auxForm = this.setNewInstanceWithPreviousInstanceData(parent);
    const auxFormItems = auxForm.meetingsActionItems;
    const formValues = {
      ...auxForm,
      status: 'draft_minutes',
      isAgenda: false,
      meetingsParentId: parent.id,
      meetingsActionItems: auxFormItems || [],
      attachedDocuments: [],
      attachedImages: [],
      deletedDocuments: [],
      deletedImages: [],
    };
    return formValues;
  };
  setInstanceFormForExistingMinutes = (instance, rows) => {
    const { non_directory_attendees } = instance;
    const parent =
      rows.data && rows.data.find((p) => p.id === instance.meetings_parent_id);
    let snakeCaseInstance;
    let parentNonDirectoryAttendees;
    if (
      !non_directory_attendees ||
      (non_directory_attendees && non_directory_attendees)
    ) {
      parentNonDirectoryAttendees = parent.non_directory_attendees;
      snakeCaseInstance = snakeToCamel(instance);
      snakeCaseInstance = {
        ...snakeCaseInstance,
        nonDirectoryAttendees: parentNonDirectoryAttendees,
      };
    } else {
      snakeCaseInstance = snakeToCamel(instance);
    }
    return snakeCaseInstance;
  };
  setInstanceParent = (instance, parents) => {
    return parents && parents.find((p) => p.id === instance.meetingsParentId);
  };

  filterByResolved = (items) => {
    const nonResolvedItems = items && items.filter((i) => !i.isResolved);
    return nonResolvedItems;
  };

  nestedFilterByResolved = (items) => {
    return items
      .filter((item) => !item.is_resolved)
      .map((item) => {
        const actionItemsUpdates = item.action_items_updates
          ? item.action_items_updates
              .filter((update) => !update.is_resolved)
              .map((update) => {
                const nestedUpdates = update.nested_updates
                  ? update.nested_updates.filter(
                      (nestedUpdate) => !nestedUpdate.is_resolved
                    )
                  : [];
                return { ...update, nestedUpdates };
              })
          : [];
        return { ...item, actionItemsUpdates };
      });
  };

  categorizeItemsByCategory = (items, itemCategories) => {
    // Initialize all categories with empty arrays
    let filteredItems = this.filterDiscardedItems(items);
    let catItems = {};
    itemCategories.forEach((cat) => {
      catItems[cat.name] = [];
    });
    const hasUncategorized = itemCategories.some(
      (c) => c.name === 'nocategory'
    );
    // Loop through each item
    filteredItems.forEach((item) => {
      // Get the category of the current item
      const category = item.category;

      // If the category exists in itemCategories, add the item to its category array

      if (category && catItems.hasOwnProperty(category)) {
        const isItemAlreadyIncluded = catItems[category].some(
          (existingItem) =>
            existingItem.description === item.description &&
            existingItem.dueDate === item.dueDate &&
            existingItem.responsibleId === item.responsibleId &&
            existingItem.responsibleCompany === item.responsibleCompany &&
            existingItem.status === item.status
        );
        if (!isItemAlreadyIncluded) catItems[category].push(snakeToCamel(item));
      }
      if (!category && hasUncategorized) {
        const isItemAlreadyIncluded = catItems['nocategory'].some(
          (existingItem) =>
            existingItem.description === item.description &&
            existingItem.dueDate === item.dueDate &&
            existingItem.responsibleId === item.responsibleId &&
            existingItem.responsibleCompany === item.responsibleCompany &&
            existingItem.status === item.status
        );
        if (!isItemAlreadyIncluded)
          catItems['nocategory'].push(snakeToCamel(item));
      }
    });
    return catItems;
  };

  filterDiscardedItems = (items) => {
    const filteredItems = items && items.filter((ai) => !ai.isDiscarded);
    return filteredItems;
  };

  filterDiscardedUpdatesInItem = (actionItem) => {
    // Filter out discarded updates
    const filteredUpdates =
      actionItem &&
      actionItem.actionItemsUpdates &&
      actionItem.actionItemsUpdates.filter((update) => !update.isDiscarded);

    // Filter out discarded nestedUpdates within each update
    if (filteredUpdates)
      filteredUpdates.forEach((update) => {
        if (update.nestedUpdates) {
          update.nestedUpdates = update.nestedUpdates.filter(
            (nestedUpdate) => !nestedUpdate.isDiscarded
          );
        }
      });

    // Replace the actionItemsUpdates with the filtered list
    return {
      ...actionItem,
      actionItemsUpdates: filteredUpdates,
    };
  };

  isOldItem = (oldItems, item) => {
    if (!oldItems) return;
    return oldItems.some(
      (o) =>
        o.description === item.description &&
        o.dueDate === item.dueDate &&
        o.responsibleId === item.responsibleId &&
        o.responsibleCompany === item.responsibleCompany &&
        o.status === item.status
    );
  };
  isOldUpdate = (oldItems, u) => {
    if (!oldItems) return;
    return oldItems.some((ai) =>
      ai.actionItemsUpdates.some(
        (update) =>
          update.description === u.description &&
          update.dueDate === u.dueDate &&
          update.responsibleId === u.responsibleId &&
          update.responsibleCompany === u.responsibleCompany &&
          update.status === u.status
      )
    );
  };
  isOldNestedUpdate = (oldItems, nu) => {
    return oldItems.some((ai) =>
      ai.actionItemsUpdates.some((update) =>
        update.nestedUpdates.some(
          (nested) =>
            nested.description === nu.description &&
            nested.dueDate === nu.dueDate &&
            nested.responsibleId === nu.responsibleId &&
            nested.responsibleCompany === nu.responsibleCompany &&
            nested.status === nu.status
        )
      )
    );
  };
  hasPendingCorrections = (corrections) => {
    if (!corrections) return false;
    return corrections.some((c) => c.status === 'pending');
  };
  numberFormatting = (num) => num.toString().padStart(2, '0');
  meetingsParentsErrors = (values) => {
    let errors = {};
    if (!values.title) errors = { ...errors, title: 'Title is mandatory' };
    if (!values.meetingType)
      errors = { ...errors, meetingType: 'Meeting Type is mandatory' };
    if (!values.startDate)
      errors = { ...errors, startDate: "Meeting's Start Date is mandatory" };
    if (!values.startTime)
      errors = { ...errors, startTime: "Meeting's Start Time is mandatory" };
    if (!values.endTime)
      errors = { ...errors, endTime: "Meeting's End Time is mandatory" };
    return errors;
  };
  checkIfActionItemsAreIncomplete = (actionItems) => {
    if (!actionItems || actionItems.length === 0) return [];
    const camelCaseItems = snakeToCamel(actionItems);
    const itemsAreIncomplete = camelCaseItems.some(
      (ai) => !ai.description || !ai.status
    );
    const updatesAreIncomplete = camelCaseItems.some(
      (ai) =>
        ai.actionItemsUpdates &&
        ai.actionItemsUpdates.length > 0 &&
        ai.actionItemsUpdates.some((u) => !u.description)
    );
    const nestedUpdatesAreIncomplete = camelCaseItems.some(
      (ai) =>
        ai.actionItemsUpdates &&
        ai.actionItemsUpdates.length > 0 &&
        ai.actionItemsUpdates.some(
          (u) =>
            u.nestedUpdates &&
            u.nestedUpdates.length > 0 &&
            u.nestedUpdates.some((nu) => !nu.description)
        )
    );
    let actionItemsCompletionStatus = [];
    const actionItemsErrorMsg =
      'All Action Items need to have a status and description set to submit or create new item.';
    const updatesErrorMsg =
      'All Action Items Updates need to have a description set';
    if (updatesAreIncomplete || nestedUpdatesAreIncomplete)
      actionItemsCompletionStatus = [updatesErrorMsg];
    if (itemsAreIncomplete)
      actionItemsCompletionStatus = [
        ...actionItemsCompletionStatus,
        actionItemsErrorMsg,
      ];

    return actionItemsCompletionStatus;
  };
  instanceInfoFormErrors = (values) => {
    let errors = {};
    if (!values.title) errors = { ...errors, title: 'Title is mandatory' };
    if (!values.meetingType)
      errors = { ...errors, meetingType: 'Meeting Type is mandatory' };
    if (!values.meetingDate)
      errors = { ...errors, meetingDate: "Meeting's  Date is mandatory" };
    if (!values.startTime)
      errors = { ...errors, startTime: "Meeting's Start Time is mandatory" };
    if (!values.endTime)
      errors = { ...errors, endTime: "Meeting's End Time is mandatory" };
    return errors;
  };
  instanceValidation = (instance) => {
    const actionItemsErrors = this.checkIfActionItemsAreIncomplete(
      instance.meetingsActionItems
    );
    const instanceInfoErrors = this.instanceInfoFormErrors(instance);

    return [actionItemsErrors, instanceInfoErrors];
  };

  parentStatusMap = (s) => {
    switch (s) {
      case 0:
        return 'draft';
      case 1:
        return 'published';
      case 2:
        return 'republished';
      case 3:
        return 'discarded';
      case 4:
        return 'archived';
      default:
        return 'draft';
    }
  };

  getInstancePdfUrl = (instance) => {
    const camelCaseInstance = snakeToCamel(instance);
    return (
      (camelCaseInstance &&
        camelCaseInstance.pdfDocument &&
        camelCaseInstance.pdfDocument.document &&
        camelCaseInstance.pdfDocument.document.url) ||
      null
    );
  };
  getInstanceAgendaPdfUrl = (instance) => {
    const camelCaseInstance = snakeToCamel(instance);
    return (
      (camelCaseInstance &&
        camelCaseInstance.agendaPdfDocument &&
        camelCaseInstance.agendaPdfDocument.document &&
        camelCaseInstance.agendaPdfDocument.document.url) ||
      null
    );
  };
  getInstanceAgendaAndNotesPdfUrl = (instance) => {
    const camelCaseInstance = snakeToCamel(instance);
    return (
      (camelCaseInstance &&
        camelCaseInstance.agendaAndNotesPdfDocument &&
        camelCaseInstance.agendaAndNotesPdfDocument.document &&
        camelCaseInstance.agendaAndNotesPdfDocument.document.url) ||
      null
    );
  };
  isAgendaBackup = (instance) => {
    const transformedInstance = snakeToCamel(instance);
    if (
      (transformedInstance.meetingsAgendaBackup &&
        transformedInstance.meetingsAgendaBackup.id) ||
      (transformedInstance.meetings_agenda_backup &&
        transformedInstance.meetings_agenda_backup.id)
    )
      return true;
  };
  createParentValuesFromQuickStart = (instance) => {
    const parentForm = {
      title: instance.title,
      meetingType: instance.meetingType,
      address: instance.address,
      location: instance.location,
      jobWalk: instance.jobWalk,
      privateMeeting: instance.privateMeeting,
      startDate: instance.meetingDate,
      endDate: instance.meetingDate,
      callInfo: instance.callInfo,
      otherInfo: instance.otherInfo,
      callLink: instance.callLink,
      webLink: instance.webLink,
      nextMeeting: instance.nextMeeting,
      attendees: instance.attendees,
      attendeeIds: instance.attendeeIds,
      meetingsInstances: [instance],
      startTime: instance.startTime,
      endTime: instance.endTime,
      timezone: instance.timezone,
      projectId: instance.projectId,
      createdById: instance.createdById,
    };
    return parentForm;
  };

  companyRoleLabel = (user) => {
    const role = this.companyRoleName(user);
    return userRoleLabel(role);
  };

  userRoles = (user) => {
    if (!user || !user.roles) return [];

    const resourceType = this.companyId(user) ? 'Company' : null;

    return user.roles.filter((role) => role.resourceType === resourceType);
  };

  companyRoleName = (user) => {
    const role = this.companyRole(user);
    return (role && role.name) || null;
  };

  companyRole = (user) => companyRoles(this.userRoles(user))[0];

  companyId = (user) => user && (user.accountableId || user.companyId);
}

export default new Meeting();
