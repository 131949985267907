import {
  PROJECT_SCHEDULE_INIT,
  PROJECT_SCHEDULE_SET_FORM_MODAL,
  PROJECT_SCHEDULE_SET_FILTERS,
  PROJECT_SCHEDULE_SET_FORM_TAB,
  PROJECT_SCHEDULE_SET_MESSAGE,
  PROJECT_SCHEDULE_SET_EMAIL_MODAL,
  PROJECT_SCHEDULE_SET_PDF_MODAL,
  PROJECT_SCHEDULE_SET_RESOLVED_MODAL,
} from './action-types';
import {
  DEFAULT_SCHEDULE_TAB,
  DEFAULT_SCHEDULE_FORM_TAB,
} from 'domain/schedule';

export const INITIAL_SCHEDULE_FILTERS = {
  tab: DEFAULT_SCHEDULE_TAB,
  search: '',

  sortName: 'all',
  byName: null,

  sortCompany: 'all',
  byCompany: null,

  sortCreatedBy: 'all',
  byCreatedUser: null,

  sortArchivedBy: 'all',
  byArchivedUser: null,

  sortCreatedDate: 'all',
  createdDateFrom: null,
  createdDateTo: null,

  sortPublishedDate: 'all',
  publishedDateFrom: null,
  publishedDateTo: null,

  sortArchivedDate: 'all',
  archivedDateFrom: null,
  archivedDateTo: null,
};

const initialState = {
  filters: INITIAL_SCHEDULE_FILTERS,
  showFormModal: false,
  formTab: DEFAULT_SCHEDULE_FORM_TAB,
  message: null,
  messageType: 'SUCCESS',
  typeEmailModal: null,
  pdf: null,
  pdfType: 'pdf',
  showResolvedModal: false,
};

export default function projectScheduleUiReducer(state = initialState, action) {
  switch (action.type) {
    case PROJECT_SCHEDULE_INIT:
      return initialState;
    case PROJECT_SCHEDULE_SET_FILTERS:
      return { ...state, filters: action.payload };
    case PROJECT_SCHEDULE_SET_FORM_MODAL:
      return { ...state, showFormModal: action.payload };
    case PROJECT_SCHEDULE_SET_FORM_TAB:
      return { ...state, formTab: action.payload };
    case PROJECT_SCHEDULE_SET_MESSAGE: {
      const { message, type: messageType } = action.payload;
      return { ...state, message, messageType };
    }
    case PROJECT_SCHEDULE_SET_PDF_MODAL: {
      const { pdf, type: pdfType } = action.payload;
      return { ...state, pdf, pdfType };
    }
    case PROJECT_SCHEDULE_SET_EMAIL_MODAL:
      return { ...state, typeEmailModal: action.payload.type };
    case PROJECT_SCHEDULE_SET_RESOLVED_MODAL:
      return { ...state, showResolvedModal: action.payload };
    default:
      return state;
  }
}
