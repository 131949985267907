import ActionCable from 'actioncable';

class NotificationsSocket {
  constructor(uid, token, client, onUpdate) {
    const url = `${process.env.REACT_APP_CABLE_WSS_URL}?uid=${uid}&token=${token}&client=${client}`;
    this.cable = ActionCable.createConsumer(url);
    this.onUpdate = onUpdate;
  }

  subscribe(channel) {
    return this.cable.subscriptions.create(channel, {
      received: this.onUpdate,
    });
  }

  disconnect() {
    if (this.cable) {
      this.cable.disconnect();
    }
  }
}

export default NotificationsSocket;
