import React from 'react';
import { connect } from 'react-redux';
import MessageModal, {
  MODAL_ICONS,
} from 'components/shared/modal/MessageModal';
import {
  getProjectSchedulesModalMessage,
  getProjectSchedulesModalMessageType,
} from './store/selectors';
import { setModalMessageProjectSchedule } from './store/actions';

const ProjectScheduleMessageModal = (props) => {
  const { dispatch, message, type } = props;

  const onHide = () => {
    dispatch(setModalMessageProjectSchedule(null));
  };

  if (!message) return null;

  return (
    <MessageModal
      show={!!message}
      icon={MODAL_ICONS[type]}
      message={<div>{message}</div>}
      onHide={onHide}
      iconSize={type === 'LOADING' ? 45 : undefined}
    />
  );
};

export default connect((state) => ({
  message: getProjectSchedulesModalMessage(state),
  type: getProjectSchedulesModalMessageType(state),
}))(ProjectScheduleMessageModal);
