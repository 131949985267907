import {
  OPEN_PROJECT_PHOTOS_CURRENT_DETAIL,
  SET_PROJECT_PHOTOS_EDIT_IMAGE,
  SET_PROJECT_PHOTOS_SELECTED_ALBUMS,
  SET_PROJECT_PHOTOS_SELECTED_IDS,
  SET_PROJECT_PHOTOS_SELECTED_PHOTOS,
  SET_PROJECT_PHOTOS_SHOW_ALBUM_FORM,
  SET_PROJECT_PHOTOS_SHOW_DELETE,
  SET_PROJECT_PHOTOS_SHOW_DETAIL,
  SET_PROJECT_PHOTOS_SHOW_FILTERS,
  SET_PROJECT_PHOTOS_SHOW_FORM,
  SET_PROJECT_PHOTOS_SHOW_LOCATIONS,
  SET_PROJECT_PHOTOS_SHOW_MOVE_TO,
  SET_PROJECT_PHOTOS_SHOW_TAGS,
  SET_PROJECT_PHOTOS_SHOW_TRADES,
  SET_PROJECT_PHOTOS_SHOW_UPLOAD,
  SET_PROJECT_PHOTOS_SHOW_USERS,
  SET_PROJECT_PHOTOS_START_UPLOAD,
} from './action-types';

const initialState = {
  showAlbumForm: false,
  showForm: false,
  showFilters: false,
  showDetail: false,
  showUpload: false,
  showTags: false,
  showTrades: false,
  showLocations: false,
  showUsers: false,
  showMoveTo: false,
  showDelete: false,
  startUpload: false,
  selecting: false,
  selectedAlbums: [],
  selectedPhotos: [],
  selectedIds: [], // used in move & email modal
  editImage: false,
  detailPhotoId: null,
};

export default function projectPhotosUiReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROJECT_PHOTOS_SHOW_ALBUM_FORM:
      return { ...state, showAlbumForm: action.payload };
    case SET_PROJECT_PHOTOS_SHOW_FORM:
      return { ...state, showForm: action.payload };
    case SET_PROJECT_PHOTOS_SHOW_FILTERS:
      return { ...state, showFilters: action.payload };
    case SET_PROJECT_PHOTOS_SHOW_DETAIL:
      return { ...state, showDetail: action.payload };
    case SET_PROJECT_PHOTOS_SHOW_UPLOAD:
      return { ...state, showUpload: action.payload };
    case SET_PROJECT_PHOTOS_SHOW_TAGS:
      return { ...state, showTags: action.payload };
    case SET_PROJECT_PHOTOS_SHOW_TRADES:
      return { ...state, showTrades: action.payload };
    case SET_PROJECT_PHOTOS_SHOW_LOCATIONS:
      return { ...state, showLocations: action.payload };
    case SET_PROJECT_PHOTOS_SHOW_USERS:
      return { ...state, showUsers: action.payload };
    case SET_PROJECT_PHOTOS_SHOW_MOVE_TO:
      return { ...state, showMoveTo: action.payload };
    case SET_PROJECT_PHOTOS_SHOW_DELETE:
      return { ...state, showDelete: action.payload };
    case SET_PROJECT_PHOTOS_START_UPLOAD:
      return { ...state, startUpload: action.payload };
    case SET_PROJECT_PHOTOS_SELECTED_ALBUMS: {
      const { selectedAlbums, selecting } = action.payload;
      return { ...state, selectedAlbums, selecting };
    }
    case SET_PROJECT_PHOTOS_SELECTED_PHOTOS: {
      const { selectedPhotos, selecting } = action.payload;
      return { ...state, selectedPhotos, selecting };
    }
    case SET_PROJECT_PHOTOS_EDIT_IMAGE: {
      return { ...state, editImage: action.payload };
    }
    case SET_PROJECT_PHOTOS_SELECTED_IDS: {
      return { ...state, selectedIds: action.payload };
    }
    case OPEN_PROJECT_PHOTOS_CURRENT_DETAIL: {
      return { ...state, detailPhotoId: action.payload };
    }

    default:
      return state;
  }
}
