import { animateScroll as scroll, scroller } from 'react-scroll';

const containerId = 'admin-col-item-container'; // defined in Admin.js

/**
 * Helper function around react-scroll scrollTo method (with default values related to the app design)
 * @param element
 */
export function simpleScrollTo(element) {
  scroller.scrollTo(element, {
    containerId: containerId,
    delay: 50,
    offset: -70,
    smooth: true,
  });
}

export function simpleScrollToTop() {
  containerScrollToTop(containerId);
}

export function containerScrollToTop(id) {
  scroll.scrollToTop({
    containerId: id,
    delay: 0,
    duration: 0,
  });
}

export function simpleScrollToBottom() {
  containerScrollToBottom(containerId);
}

export function containerScrollToBottom(id) {
  scroll.scrollToBottom({
    containerId: id,
    delay: 0,
    duration: 0,
  });
}
