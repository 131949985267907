export const INIT_PROJECT_EMAIL_MODAL = 'INIT_PROJECT_EMAIL_MODAL';
export const SET_SHOW_EMAIL_MODAL = 'SET_SHOW_EMAIL_MODAL';
export const SET_SHOW_CUSTOM_EMAIL_ERROR = 'SET_SHOW_CUSTOM_EMAIL_ERROR';
export const SET_SHOW_NEW_CUSTOM_EMAIL = 'SET_SHOW_NEW_CUSTOM_EMAIL';
export const SET_EMAILS = 'SET_EMAILS';
export const ADD_EMAIL = 'ADD_EMAIL';
export const ADD_RECIPIENT = 'ADD_RECIPIENT';
export const SET_SUBJECT = 'SET_SUBJECT';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_RECIPIENTS = 'SET_RECIPIENTS';
export const SET_CUSTOM_EMAIL = 'SET_CUSTOM_EMAIL';
export const SET_EMAIL_HISTORY = 'SET_EMAIL_HISTORY';
