import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './UnionTrades.css';
import SelectList from '../../../shared/select/SelectList';

export const UnionTradesExtraKeys = {
  ALL: 'all',
  DONT_KNOW: 'dont-know',
  NO: 'no',
};

class UnionTrades extends Component {
  static propTypes = {
    dontKnowUnions: PropTypes.bool,
    noUnions: PropTypes.bool,
    unionTradesValidationError: PropTypes.string,
    selectedUnionTradesId: PropTypes.arrayOf(PropTypes.number),
    unionTrades: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        value: PropTypes.string,
        label: PropTypes.string,
        detail: PropTypes.string,
      })
    ),
    onItemSelected: PropTypes.func,
  };

  handleUnionTradeSelection = (id) => {
    this.props.onItemSelected(id);
  };

  render() {
    return (
      <div className="union-trades">
        <div>Requirements</div>
        <div>
          Do you or your Building require Union Trades for any of the following?
        </div>

        <div className="union-trades-list">
          <SelectList
            values={this.props.unionTrades
              .map((element) => {
                return {
                  id: element.id,
                  value: (
                    <span className="trade-value">
                      {element.label}
                      {element.detail !== null ? (
                        <span className="trade-detail">({element.detail})</span>
                      ) : null}
                    </span>
                  ),
                  selected:
                    this.props.selectedUnionTradesId.findIndex(
                      (selected) => selected === element.id
                    ) > -1,
                };
              })
              .concat({
                id: UnionTradesExtraKeys.ALL,
                value: 'All Union Trades',
                selected:
                  this.props.selectedUnionTradesId.length > 0 &&
                  this.props.unionTrades.length ===
                    this.props.selectedUnionTradesId.length,
              })
              .concat({
                id: UnionTradesExtraKeys.DONT_KNOW,
                value: "I don't know",
                selected: this.props.dontKnowUnions,
              })
              .concat({
                id: UnionTradesExtraKeys.NO,
                value: 'No',
                selected: this.props.noUnions,
              })}
            onSelect={this.handleUnionTradeSelection}
          />
        </div>

        {this.props.unionTradesValidationError !== '' && (
          <div className="invalid-message">
            {this.props.unionTradesValidationError}
          </div>
        )}
      </div>
    );
  }
}

export default UnionTrades;
