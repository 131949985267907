import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import './Loader.css';
import loaderSvg from './loader.svg';
import loaderSvgDark from './loaderDark.svg';

class Loader extends Component {
  static propTypes = {
    show: PropTypes.bool,
  };

  render() {
    const { show, darkMode } = this.props;

    return (
      show && (
        <div className="loader-container">
          <img src={darkMode ? loaderSvgDark : loaderSvg} alt="loader" />
        </div>
      )
    );
  }
}

export default connect((state) => {
  return {
    show: state.loader.loading,
    darkMode: getDarkMode(state),
  };
}, null)(Loader);
