import axios from 'axios';
import BaseApi from './base-api';

class RfiAddlAssigneesApi extends BaseApi {
  updaterfiAddlAssignee(id, values) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = this.rfiAddlAssigneeParams(values);

    return axios
      .put(`${this.baseUrl}/rfi_addl_assignees/${id}`, data, headers)
      .then((response) => {
        return {
          ok: true,
          rfiAddlAssignee: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  rfiAddlAssigneeParams(values) {
    let resImgsIndex = 0;
    let resDocsIndex = 0;
    let apiPayload = new FormData();
    apiPayload.append('rfi_addl_assignee[response]', values.response || '');

    values.resImages
      .filter((img) => img.file)
      .forEach((img) => {
        let file = img.file;
        apiPayload.append(
          `rfi_addl_assignee[response_images_attributes][${resImgsIndex}][image]`,
          file,
          file.name
        );
        resImgsIndex++;
      });

    values.resDocs
      .filter((doc) => doc.file)
      .forEach((doc) => {
        let file = doc.file;
        apiPayload.append(
          `rfi_addl_assignee[response_documents_attributes][${resDocsIndex}][document]`,
          file,
          file.name
        );
        resDocsIndex++;
      });

    values.resImagesDelete
      .filter((img) => img.id)
      .forEach((img) => {
        apiPayload.append(
          `rfi_addl_assignee[response_images_attributes][${resImgsIndex}][id]`,
          img.id
        );
        apiPayload.append(
          `rfi_addl_assignee[response_images_attributes][${resImgsIndex}][_destroy]`,
          true
        );
        resImgsIndex++;
      });

    values.resDocsDelete
      .filter((doc) => doc.id)
      .forEach((doc) => {
        apiPayload.append(
          `rfi_addl_assignee[response_documents_attributes][${resDocsIndex}][id]`,
          doc.id
        );
        apiPayload.append(
          `rfi_addl_assignee[response_documents_attributes][${resDocsIndex}][_destroy]`,
          true
        );
        resDocsIndex++;
      });

    return apiPayload;
  }
}

export const rfiAddlAssigneesApi = new RfiAddlAssigneesApi();
