import { CHANGE_ORDER_TASKS_SHOW_MODAL_SET } from 'components/admin/work-orders/details/change-order-tasks/store/action-types';

const initialState = {
  showChangeOrderTaskModal: false,
};

export default function changeOrderTasksUiReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case CHANGE_ORDER_TASKS_SHOW_MODAL_SET:
      return {
        ...state,
        showChangeOrderTaskModal: !state.showChangeOrderTaskModal,
      };

    default:
      return state;
  }
}
