import React, { Component } from 'react';
import { connect } from 'react-redux';
import './SubmittalForm.css';
import { Form } from 'react-bootstrap';
import Date from 'components/shared/text/Date';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import Input from 'components/shared/input/Input';
import DatePicker from 'components/shared/date-picker/DatePicker';
import moment from 'moment';
import {
  getSubmittalForm,
  getSubmittalLeadTimeReadOnly,
} from 'components/admin/projects/details/project-submittal-log/store/selectors';
import { updateSubmittalFormValue } from 'components/admin/projects/details/project-submittal-log/store/actions';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import ReadOnlyInput from 'components/shared/input/ReadOnlyInput';

class SubmittalLeadTimeForm extends Component {
  componentDidMount() {
    this.scrollToLeadTime();
  }

  scrollToLeadTime = () => {
    this.leadTimeSection.scrollIntoView();
  };

  handleFormChange = (attribute, value) => {
    this.props.dispatch(updateSubmittalFormValue(attribute, value));
  };

  handleFieldDeliveryChange = (value) => {
    const dateTo = moment(value);
    const dateFrom = dateTo.subtract(7, 'd').format();
    this.handleFormChange('fieldDelivery', value);
    this.handleFormChange('leadTimeReminder', dateFrom);
  };

  render() {
    const { form, readOnly } = this.props;

    return (
      <div className="submittal-form submittal-lead-time-form">
        <DetailsTitle
          className="lead-time-title"
          title={`Submittal Lead Time - ${form.title}`}
          rightButtons={[
            <LabeledCheckBox
              key="applicable"
              position="left"
              label="Lead Time Applicable"
              readOnly={readOnly}
              checked={form.leadTimeApplies}
              onChange={(value) =>
                this.handleFormChange('leadTimeApplies', value)
              }
            />,
          ]}
        />
        <Form horizontal>
          {form.leadTimeApplies && (
            <React.Fragment>
              <div className="form-block">
                <FormControlBlock
                  label={
                    (readOnly || !!form.leadTimeWeeks) && 'Lead Time Weeks'
                  }
                  control={
                    readOnly ? (
                      <ReadOnlyInput
                        value={
                          form.leadTimeWeeks !== ''
                            ? `${form.leadTimeWeeks} ${
                                form.leadTimeWeeks === 1 ? 'Week' : 'Weeks'
                              }`
                            : '-'
                        }
                      />
                    ) : (
                      <Input
                        className="short-input"
                        type="number"
                        placeholder="Lead Time Weeks"
                        value={form.leadTimeWeeks}
                        onChange={(value) =>
                          this.handleFormChange('leadTimeWeeks', value)
                        }
                      />
                    )
                  }
                />
                <FormControlBlock
                  label={(readOnly || !!form.fieldDelivery) && 'Field Delivery'}
                  control={
                    readOnly ? (
                      <ReadOnlyInput
                        value={
                          form.fieldDelivery ? (
                            <Date
                              value={form.fieldDelivery}
                              format="MM/DD/YYYY"
                            />
                          ) : (
                            '-'
                          )
                        }
                      />
                    ) : (
                      <DatePicker
                        placeholder="Field Delivery"
                        clearable={true}
                        isValidDate={(date) => {
                          return (
                            (!form.vendorDelivery ||
                              date.isSameOrAfter(form.vendorDelivery)) &&
                            (!form.leadTimeReminder ||
                              date.isSameOrAfter(form.leadTimeReminder))
                          );
                        }}
                        value={form.fieldDelivery}
                        onChange={this.handleFieldDeliveryChange}
                      />
                    )
                  }
                />
              </div>
              <div className="form-block">
                <FormControlBlock
                  label={
                    (readOnly || !!form.vendorDelivery) && 'Vendor Delivery'
                  }
                  control={
                    readOnly ? (
                      <ReadOnlyInput
                        value={
                          form.vendorDelivery ? (
                            <Date
                              value={form.vendorDelivery}
                              format="MM/DD/YYYY"
                            />
                          ) : (
                            '-'
                          )
                        }
                      />
                    ) : (
                      <DatePicker
                        placeholder="Vendor Delivery"
                        clearable={true}
                        isValidDate={(date) => {
                          return (
                            !form.fieldDelivery ||
                            date.isSameOrBefore(form.fieldDelivery)
                          );
                        }}
                        value={form.vendorDelivery}
                        onChange={(value) =>
                          this.handleFormChange('vendorDelivery', value)
                        }
                      />
                    )
                  }
                />
                <FormControlBlock
                  label={(readOnly || !!form.leadTimeReminder) && 'Reminder'}
                  control={
                    readOnly ? (
                      <ReadOnlyInput
                        value={
                          form.leadTimeReminder ? (
                            <Date
                              value={form.leadTimeReminder}
                              format="MM/DD/YYYY"
                            />
                          ) : (
                            '-'
                          )
                        }
                      />
                    ) : (
                      <DatePicker
                        placeholder="Reminder"
                        clearable={true}
                        isValidDate={(date) => {
                          return (
                            !form.fieldDelivery ||
                            date.isSameOrBefore(form.fieldDelivery)
                          );
                        }}
                        value={form.leadTimeReminder}
                        onChange={(value) =>
                          this.handleFormChange('leadTimeReminder', value)
                        }
                      />
                    )
                  }
                />
              </div>
            </React.Fragment>
          )}
        </Form>

        <div
          ref={(el) => {
            this.leadTimeSection = el;
          }}
        />
      </div>
    );
  }
}

export default connect((state) => {
  return {
    form: getSubmittalForm(state),
    readOnly: getSubmittalLeadTimeReadOnly(state),
  };
})(SubmittalLeadTimeForm);
