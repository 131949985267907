import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormModal from 'components/shared/modal/FormModal';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getShowOpenItemFormModal,
  getEditOpenItemDetailsModal,
  getCurrentProjectOpenItem,
  getCritical,
} from 'components/admin/projects/details/project-open-items/store/selectors';
import {
  closeOpenItemFormModal,
  createProjectOpenItem,
  updateProjectOpenItem,
} from 'components/admin/projects/details/project-open-items/store/actions';
import ProjectOpenItemForm from 'components/admin/projects/details/project-open-items/ProjectOpenItemForm';
import './ProjectOpenItemFormModal.css';

class ProjectOpenItemFormModal extends Component {
  handleOpenItemFormModalClose = () => {
    this.props.dispatch(closeOpenItemFormModal());
    if (typeof this.props.handleClose === 'function') {
      this.props.handleClose({ ok: false });
    }
  };

  handleSave = (values) => {
    const { currentProject, dispatch } = this.props;
    dispatch(createProjectOpenItem(currentProject.id, values)).then(
      (response) => {
        if (typeof this.props.handleClose === 'function') {
          this.props.handleClose(response);
        }
      }
    );
  };

  handleEdit = (values) => {
    const { currentProject, dispatch } = this.props;
    dispatch(updateProjectOpenItem(currentProject.id, values.id, values)).then(
      (response) => {
        this.props.dispatch(closeOpenItemFormModal());
        if (typeof this.props.handleClose === 'function') {
          this.props.handleClose(response);
        }
      }
    );
  };

  modalSubtitle = () => {
    const { currentProject, openItem, edit, critical } = this.props;

    if (edit && openItem)
      return `${currentProject.name} - ${
        critical ? 'Critical Item' : 'Open Item'
      } ${openItem ? openItem.number : ''}`;

    return `${currentProject.name} - ${
      this.props.critical ? 'New Critical Open Item' : 'New Open Item'
    }`;
  };

  render() {
    const { showOpenItemFormModal, edit } = this.props;
    return (
      showOpenItemFormModal && (
        <FormModal
          className="project-open-item-form-modal create-project-open-item"
          show={showOpenItemFormModal}
          onClose={this.handleOpenItemFormModalClose}
          subTitle={this.modalSubtitle()}
        >
          <ProjectOpenItemForm
            onSave={edit ? this.handleEdit : this.handleSave}
            onCancel={this.handleOpenItemFormModalClose}
          />
        </FormModal>
      )
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    showOpenItemFormModal: getShowOpenItemFormModal(state),
    critical: getCritical(state),
    edit: getEditOpenItemDetailsModal(state),
    openItem: getCurrentProjectOpenItem(state),
  };
})(ProjectOpenItemFormModal);
