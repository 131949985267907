import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import Checkbox from './CheckBox';
import TooltipControl from '../tooltip/TooltipControl';
import './CheckBox.css';

class LabeledCheckBox extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    readOnly: PropTypes.bool,
    valid: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium']),
    shape: PropTypes.oneOf(['round', 'square', 'bell']),
    position: PropTypes.oneOf(['left', 'right']),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    tooltipPosition: PropTypes.string,
    darkModeFixed: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    className: null,
    readOnly: false,
    valid: true,
    shape: 'square',
    size: 'small',
    position: 'right',
    tooltipPosition: 'left',
    label: null,
    squareEmpty: null,
    darkModeFixed: false,
    onChange: () => {},
  };

  handleSelectChange = () => {
    this.props.onChange(!this.props.checked);
  };

  render() {
    const {
      id,
      className,
      size,
      shape,
      position,
      readOnly,
      valid,
      checked,
      label,
      tooltip,
      tooltipPosition,
      darkMode,
      darkModeFixed,
    } = this.props;

    const iconComponent = (
      <Checkbox
        key="checkbox"
        checked={checked}
        className={className}
        id={id}
        readOnly={readOnly}
        valid={valid}
        shape={shape}
        size={size}
        darkModeFixed={darkModeFixed}
        onChange={this.handleSelectChange}
      />
    );
    const labelComponent = (
      <div
        key="label"
        className={`checkbox-label ${readOnly && 'checkbox-label-readonly'}`}
        onClick={() => {
          !readOnly && this.handleSelectChange();
        }}
      >
        {label}
      </div>
    );

    const control = (
      <div
        className={`labeled-checkbox ${
          darkMode || darkModeFixed ? 'dark-mode' : ''
        } ${!valid ? 'invalid' : ''}`}
      >
        {position === 'left'
          ? [iconComponent, labelComponent]
          : [labelComponent, iconComponent]}
      </div>
    );

    return tooltip ? (
      <TooltipControl
        control={control}
        tooltip={tooltip}
        position={tooltipPosition}
      />
    ) : (
      control
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(LabeledCheckBox);
