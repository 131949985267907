import {
  CLIENTS_CLIENT_FILTER_CHANGE,
  CLIENTS_CLIENT_INVITE_USER_SHOW,
  CLIENTS_CLIENT_LOAD_SUCCESS,
  CLIENTS_CLIENT_NOTE_EDITOR_EDIT_SET,
  CLIENTS_CLIENT_NOTE_EDITOR_REMINDER_CHANGE,
  CLIENTS_CLIENT_NOTE_EDITOR_SAVING_SET,
  CLIENTS_CLIENT_NOTE_EDITOR_TEXT_CHANGE,
  CLIENTS_CLIENT_PAGE_CHANGE,
  CLIENTS_CONTACTS_MODAL_SHOW,
  CLIENTS_INVITE_ATTRIBUTE_CHANGE,
  CLIENTS_INVITE_FORM_CLEAR,
  CLIENTS_INVITE_FORM_INVALID,
  CLIENTS_INVITE_FORM_VALID,
  CLIENTS_INVITE_USER_MODAL_SHOW,
  CLIENTS_SET_PAGINATION_DATA,
} from 'actions/admin/clients/action-types';

const formInitialState = {
  buildingsId: [], // for client user invite
  companyName: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  globalPurchaseOrderRequired: null,
  purchaseOrderRequired: null,
  rolesId: [], // for client user invite
  streetAddress1: '',
  streetAddress2: '',
  city: '',
  state: '',
  zip: '',
};

const initialState = {
  currentFilter: 'all',
  currentPage: 1,
  form: formInitialState,
  invalidFields: [],
  noteEditor: {
    edit: false,
    reminder: null,
    saving: false,
    text: '',
  },
  showContacts: false,
  showInviteClientModal: false,
  showInviteClientUserModal: false, // modal to invite a user within a client
  validationMessage: '',
  clientsCurrentPage: 1,
  clientsTotal: 0,
  clientsPageSize: 25,
};

export default function clientsUiReducer(state = initialState, action) {
  switch (action.type) {
    case CLIENTS_CLIENT_FILTER_CHANGE:
      return {
        ...state,
        currentFilter: action.payload,
      };

    case CLIENTS_CLIENT_INVITE_USER_SHOW:
      return {
        ...state,
        showInviteClientUserModal: action.payload,
      };

    case CLIENTS_CLIENT_LOAD_SUCCESS:
      return {
        ...state,
        noteEditor: {
          ...state.noteEditor,
          edit: false,
          reminder: action.payload.note_reminder_date,
          text: action.payload.note !== null ? action.payload.note : '',
        },
      };

    case CLIENTS_CLIENT_NOTE_EDITOR_EDIT_SET:
      return {
        ...state,
        noteEditor: {
          ...state.noteEditor,
          edit: action.payload,
        },
      };

    case CLIENTS_CLIENT_NOTE_EDITOR_REMINDER_CHANGE:
      return {
        ...state,
        noteEditor: {
          ...state.noteEditor,
          reminder: action.payload,
        },
      };

    case CLIENTS_CLIENT_NOTE_EDITOR_SAVING_SET:
      return {
        ...state,
        noteEditor: {
          ...state.noteEditor,
          saving: action.payload,
        },
      };

    case CLIENTS_CLIENT_NOTE_EDITOR_TEXT_CHANGE:
      return {
        ...state,
        noteEditor: {
          ...state.noteEditor,
          text: action.payload,
        },
      };

    case CLIENTS_CLIENT_PAGE_CHANGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case CLIENTS_INVITE_ATTRIBUTE_CHANGE:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case CLIENTS_INVITE_FORM_CLEAR:
      return {
        ...state,
        form: formInitialState,
        invalidFields: [],
        validationMessage: '',
      };

    case CLIENTS_INVITE_FORM_INVALID:
      return {
        ...state,
        invalidFields: action.payload.invalidFields,
        validationMessage: action.payload.message,
      };

    case CLIENTS_INVITE_FORM_VALID:
      return {
        ...state,
        invalidFields: [],
        validationMessage: '',
      };

    case CLIENTS_INVITE_USER_MODAL_SHOW:
      return {
        ...state,
        showInviteClientModal: action.payload,
      };

    case CLIENTS_CONTACTS_MODAL_SHOW:
      return {
        ...state,
        showContacts: action.payload,
      };

    case CLIENTS_SET_PAGINATION_DATA:
      return {
        ...state,
        clientsCurrentPage: action.payload.page,
        clientsTotal: action.payload.total,
        clientsPageSize: action.payload.pageSize,
      };

    default:
      return state;
  }
}
