import {
  CONTRACTOR_TEAM_ROLES_LOAD_SUCCESS,
  CONTRACTOR_TEAM_USERS_ADD,
  CONTRACTOR_TEAM_USERS_EDIT,
  CONTRACTOR_TEAM_USERS_LOAD_SUCCESS,
  CONTRACTOR_TEAM_USERS_REMOVE,
  CONTRACTOR_TEAM_TECHNICIANS_LOAD_SUCCESS,
  CONTRACTOR_TEAM_TECHNICIANS_ADD,
  CONTRACTOR_TEAM_TECHNICIANS_EDIT,
  CONTRACTOR_TEAM_TECHNICIANS_REMOVE,
} from 'actions/admin/team/action-types';
import { sortByAttribute } from 'services/utils/sort-util';

export const initialState = {
  roles: [],
  users: [],
  technicians: [],
};

export default function contractorTeamDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case CONTRACTOR_TEAM_ROLES_LOAD_SUCCESS:
      return {
        ...state,
        roles: action.payload,
      };

    case CONTRACTOR_TEAM_USERS_ADD:
      return {
        ...state,
        users: sortByAttribute(
          state.users.concat([action.payload]),
          'first_name'
        ),
      };

    case CONTRACTOR_TEAM_TECHNICIANS_ADD:
      return {
        ...state,
        technicians: sortByAttribute(
          state.technicians.concat([action.payload]),
          'first_name'
        ),
      };

    case CONTRACTOR_TEAM_USERS_EDIT:
      return {
        ...state,
        users: state.users.map((user) => {
          if (user.id === action.payload.id) {
            return action.payload;
          } else {
            return user;
          }
        }),
      };

    case CONTRACTOR_TEAM_TECHNICIANS_EDIT:
      return {
        ...state,
        technicians: state.technicians.map((user) => {
          if (user.id === action.payload.id) {
            return action.payload;
          } else {
            return user;
          }
        }),
      };

    case CONTRACTOR_TEAM_USERS_LOAD_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };

    case CONTRACTOR_TEAM_TECHNICIANS_LOAD_SUCCESS:
      return {
        ...state,
        technicians: action.payload,
      };

    case CONTRACTOR_TEAM_USERS_REMOVE:
      return {
        ...state,
        users: state.users.filter((user) => user.id !== action.payload),
      };

    case CONTRACTOR_TEAM_TECHNICIANS_REMOVE:
      return {
        ...state,
        technicians: state.technicians.filter(
          (technician) => technician.id !== action.payload
        ),
      };

    default:
      return state;
  }
}
