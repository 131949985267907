import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import closeSvg from './close.svg';
import closeDarkSvg from './close-dark.svg';
import leftSvg from './left.svg';
import leftDarkSvg from './left-dark.svg';
import './FormModal.css';

class FormModal extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    show: PropTypes.bool.isRequired,
    showHeader: PropTypes.bool,
    subTitle: PropTypes.any,
    title: PropTypes.any,
    actions: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    ),
    sidebar: PropTypes.element,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    showHeader: true,
    actions: [],
    onClose: () => {},
  };

  render() {
    const {
      id,
      className,
      show,
      showHeader,
      title,
      subTitle,
      actions,
      sidebar,
      children,
      onBack,
      onClose,
      darkMode,
    } = this.props;

    return (
      <Modal
        id={id}
        className={
          'basic-modal form-modal' +
          (className !== undefined ? ' ' + className : '') +
          (darkMode ? ' dark-mode' : '')
        }
        enforceFocus={false}
        show={show}
      >
        {sidebar}
        <div className="modal-container">
          {showHeader && (
            <Modal.Header>
              <div className="modal-header-bar">
                {onBack !== undefined && (
                  <img
                    className="back-icon"
                    src={darkMode ? leftDarkSvg : leftSvg}
                    alt="close"
                    onClick={onBack}
                  />
                )}
                {subTitle !== undefined && (
                  <div className="modal-header-bar-label">{subTitle}</div>
                )}
                {title === undefined && actions.length > 0 && (
                  <div className="modal-header-bar-actions">{actions}</div>
                )}
                <img
                  className="close-icon"
                  src={darkMode ? closeDarkSvg : closeSvg}
                  alt="close"
                  onClick={onClose}
                />
              </div>
              {title !== undefined && (
                <div className="modal-header-title">
                  <div className="modal-header-title-label">{title}</div>
                  {actions.length > 0 && (
                    <div className="modal-header-title-actions">{actions}</div>
                  )}
                </div>
              )}
            </Modal.Header>
          )}
          <Modal.Body>{children}</Modal.Body>
        </div>
      </Modal>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(FormModal);
