import { push } from 'react-router-redux';
import { usersApi } from 'services/api/users-api';
import SubTechniciansApi from 'services/api/sub-technicians-api';
import {
  CONTRACTOR_TEAM_INVITE_USER_FORM_INVALID,
  CONTRACTOR_TEAM_INVITE_USER_FORM_LOAD,
  CONTRACTOR_TEAM_INVITE_USER_FORM_VALID,
  CONTRACTOR_TEAM_INVITE_USER_FORM_VALUE_UPDATE,
  CONTRACTOR_TEAM_INVITE_USER_ID_SET,
  CONTRACTOR_TEAM_INVITE_USER_MODAL_SHOW,
  CONTRACTOR_TEAM_INVITE_TECHNICIAN_MODAL_SHOW,
  CONTRACTOR_TEAM_ROLES_LOAD_SUCCESS,
  CONTRACTOR_TEAM_USERS_ADD,
  CONTRACTOR_TEAM_USERS_EDIT,
  CONTRACTOR_TEAM_USERS_LOAD_SUCCESS,
  CONTRACTOR_TEAM_USERS_REMOVE,
  CONTACTOR_TEAM_CURRENT_PAGE_TECHNICIANS,
  CONTACTOR_TEAM_PER_PAGE_TECHNICIANS,
  CONTACTOR_TEAM_TOTAL_TECHNICIANS,
  CONTACTOR_TEAM_SET_ACTIVE_TAB,
  CONTRACTOR_TEAM_TECHNICIANS_LOAD_SUCCESS,
  CONTRACTOR_TEAM_TECHNICIANS_ADD,
  CONTRACTOR_TEAM_TECHNICIANS_EDIT,
  CONTRACTOR_TEAM_INVITE_TECHNICIAN_ID_SET,
  CONTRACTOR_TEAM_TECHNICIANS_REMOVE,
  CONTRACTOR_TEAM_SHOW_VERIFICATION_MODAL,
} from 'actions/admin/team/action-types';
import { rolesApi } from 'services/api/roles-api';
import { serviceProvidersApi } from 'services/api/service-providers-api';
import { COMPANY_TYPES } from 'domain/company-type-enum';
import { USER_ROLES } from 'domain/user-role-enum';
import User from 'domain/user';
import { showErrorMessage } from 'actions/messages';

export const baseRoutePath = '/admin/team';

export const ContactorTeamTabs = {
  Users: { value: 1, label: 'Users' },
  Technicians: { value: 2, label: 'Technicians' },
};

export function addUser(user) {
  return {
    type: CONTRACTOR_TEAM_USERS_ADD,
    payload: user,
  };
}

export function addTechnician(technician) {
  return {
    type: CONTRACTOR_TEAM_TECHNICIANS_ADD,
    payload: technician,
  };
}

export function deleteTechnicianRequest(technicianId) {
  return (dispatch) => {
    return SubTechniciansApi.removeTechnician(technicianId).then((response) => {
      if (response.ok) {
        dispatch(removeTechnician(technicianId));
        dispatch(setShowInviteTechnicianModal(false));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function deleteUserRequest(userId) {
  return (dispatch) => {
    return usersApi.removeUser(userId).then((response) => {
      if (response.ok) {
        dispatch(removeUser(userId));
        dispatch(setShowInviteUserModal(false));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function editUser(user) {
  return {
    type: CONTRACTOR_TEAM_USERS_EDIT,
    payload: user,
  };
}

export function editTechnician(technician) {
  return {
    type: CONTRACTOR_TEAM_TECHNICIANS_EDIT,
    payload: technician,
  };
}

export function goToEditContractor() {
  return (dispatch) => {
    dispatch(push(`${baseRoutePath}/edit`));
  };
}

export function goToTeam() {
  return (dispatch) => {
    dispatch(push(`${baseRoutePath}`));
  };
}

export function invalidForm(invalidFields, message) {
  return {
    type: CONTRACTOR_TEAM_INVITE_USER_FORM_INVALID,
    payload: { invalidFields, message },
  };
}

export function loadRolesRequest() {
  return (dispatch) => {
    return rolesApi.getRoles(COMPANY_TYPES.CONTRACTOR).then((response) => {
      if (response.ok) {
        dispatch(loadRolesSuccess(response.permissions));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadRolesSuccess(roles) {
  return {
    type: CONTRACTOR_TEAM_ROLES_LOAD_SUCCESS,
    payload: roles,
  };
}

export function loadTechnicianFormRequest(technician) {
  return (dispatch) => {
    let form;
    if (technician) {
      form = {
        firstName: technician.first_name,
        lastName: technician.last_name || '',
        email: technician.email || '',
        phoneNumber: technician.phone_number || '',
      };
    } else {
      form = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
      };
    }
    dispatch(loadUserForm(form));
  };
}

export function loadUserFormRequest(user) {
  return (dispatch) => {
    let form;
    if (user !== null) {
      const titleRole = User.companyRoleLabel(user);
      form = {
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        phoneNumber: user.phone_number != null ? user.phone_number : '',
        rolesId: titleRole ? titleRole.id : null,
        accountAdmin: User.isAccountAdmin(user),
      };
    } else {
      form = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        rolesId: null,
        accountAdmin: false,
      };
    }

    dispatch(loadUserForm(form));
  };
}

export function loadUserForm(form) {
  return {
    type: CONTRACTOR_TEAM_INVITE_USER_FORM_LOAD,
    payload: form,
  };
}

export function setCurrentPageTechnicians(page) {
  return {
    type: CONTACTOR_TEAM_CURRENT_PAGE_TECHNICIANS,
    payload: page,
  };
}

export function setPerPageTechnicians(amount) {
  return {
    type: CONTACTOR_TEAM_PER_PAGE_TECHNICIANS,
    payload: amount,
  };
}

export function setTotalTechnicians(amount) {
  return {
    type: CONTACTOR_TEAM_TOTAL_TECHNICIANS,
    payload: amount,
  };
}

export function setActiveTab(amount) {
  return {
    type: CONTACTOR_TEAM_SET_ACTIVE_TAB,
    payload: amount,
  };
}

export function loadUsersRequest(activeTab = 1) {
  return (dispatch) => {
    return usersApi.getUsers().then((response) => {
      if (response.ok) {
        dispatch(loadUsersSuccess(response.users));
        dispatch(setActiveTab(activeTab));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function loadTechnicianRequest(page = 1, activeTab = 1) {
  return (dispatch) => {
    return SubTechniciansApi.getTechnicians(page).then((response) => {
      if (response.ok) {
        dispatch(loadTechniciansSuccess(response.technicians));
        dispatch(setActiveTab(activeTab));
        if (response.perPage) {
          dispatch(setPerPageTechnicians(+response.perPage));
        }
        if (response.total) {
          dispatch(setTotalTechnicians(+response.total));
        }
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadUsersSuccess(users) {
  return {
    type: CONTRACTOR_TEAM_USERS_LOAD_SUCCESS,
    payload: users,
  };
}

export function loadTechniciansSuccess(technicians) {
  return {
    type: CONTRACTOR_TEAM_TECHNICIANS_LOAD_SUCCESS,
    payload: technicians,
  };
}

export function removeUser(userId) {
  return {
    type: CONTRACTOR_TEAM_USERS_REMOVE,
    payload: userId,
  };
}

export function removeTechnician(technicianId) {
  return {
    type: CONTRACTOR_TEAM_TECHNICIANS_REMOVE,
    payload: technicianId,
  };
}

export function setCurrentUserId(userId) {
  return {
    type: CONTRACTOR_TEAM_INVITE_USER_ID_SET,
    payload: userId,
  };
}

export function setCurrentTechnicianId(technicianId) {
  return {
    type: CONTRACTOR_TEAM_INVITE_TECHNICIAN_ID_SET,
    payload: technicianId,
  };
}

export function setShowInviteUserModal(open, state) {
  return {
    type: CONTRACTOR_TEAM_INVITE_USER_MODAL_SHOW,
    payload: { open, state: state ? state : undefined },
  };
}

export function setShowInviteTechnicianModal(open, state) {
  return {
    type: CONTRACTOR_TEAM_INVITE_TECHNICIAN_MODAL_SHOW,
    payload: { open, state: state ? state : undefined },
  };
}

export function submitFormRequestForTechnician(form, currentTechnicianId) {
  return (dispatch) => {
    const requiredFields = ['firstName'];
    const invalidFields = [];
    requiredFields.forEach((attribute) => {
      if (form[attribute] === '' || form[attribute] === null) {
        invalidFields.push(attribute);
      }
    });
    if (invalidFields.length > 0) {
      dispatch(
        invalidForm(
          invalidFields,
          'The outlined inputs should be added in order to add a technician.'
        )
      );
    } else {
      dispatch(validForm());

      if (!currentTechnicianId) {
        return SubTechniciansApi.addTechnician(form).then((response) => {
          if (response.ok) {
            dispatch(addTechnician(response.technician));
            dispatch(setShowInviteTechnicianModal(false));
          } else {
            dispatch(showErrorMessage(response.errors));
          }
        });
      } else {
        return SubTechniciansApi.updateTechnician(
          currentTechnicianId,
          form
        ).then((response) => {
          if (response.ok) {
            dispatch(editTechnician(response.technician));
            dispatch(setShowInviteTechnicianModal(false));
          } else {
            dispatch(showErrorMessage(response.errors));
          }
          return response;
        });
      }
    }
  };
}

export function submitFormRequest(form, currentUserId, roles = []) {
  return (dispatch) => {
    // Validate form
    const requiredFields = [
      'firstName',
      'lastName',
      'email',
      'phoneNumber',
      'rolesId',
    ];
    const invalidFields = [];
    requiredFields.forEach((attribute) => {
      if (form[attribute] === '' || form[attribute] === null) {
        invalidFields.push(attribute);
      }
    });

    if (invalidFields.length > 0) {
      dispatch(
        invalidForm(
          invalidFields,
          'The outlined inputs should be added in order to invite a user.'
        )
      );
    } else {
      dispatch(validForm());

      const accountAdminRole = form.accountAdmin
        ? roles.find((role) => role.name === USER_ROLES.ACCOUNT_ADMIN)
        : null;
      const userRoles = [
        form.rolesId,
        accountAdminRole ? accountAdminRole.id : null,
      ];

      if (currentUserId === 0) {
        return usersApi
          .addUser(
            form.firstName,
            form.lastName,
            form.email,
            form.phoneNumber,
            userRoles,
            form.title
          )
          .then((response) => {
            if (response.ok) {
              dispatch(addUser(response.user));
              dispatch(setShowInviteUserModal(false));
            } else {
              dispatch(showErrorMessage(response.errors));
            }

            return response;
          });
      } else {
        return usersApi
          .updateUser(
            currentUserId,
            form.firstName,
            form.lastName,
            form.email,
            form.phoneNumber,
            userRoles,
            form.title
          )
          .then((response) => {
            if (response.ok) {
              dispatch(editUser(response.user));
              dispatch(setShowInviteUserModal(false));
            } else {
              dispatch(showErrorMessage(response.errors));
            }

            return response;
          });
      }
    }
  };
}

export function updateFormValue(attribute, value) {
  return {
    type: CONTRACTOR_TEAM_INVITE_USER_FORM_VALUE_UPDATE,
    payload: { attribute, value },
  };
}

export function validForm() {
  return {
    type: CONTRACTOR_TEAM_INVITE_USER_FORM_VALID,
  };
}

export function setShowVerificationModal(show, user = null, action = null) {
  return {
    type: CONTRACTOR_TEAM_SHOW_VERIFICATION_MODAL,
    payload: show ? { user, action } : null,
  };
}

function verificationAction(user, action) {
  switch (action) {
    case 'reject':
      return serviceProvidersApi.rejectUser(user);
    default:
      return serviceProvidersApi.verifyUser(user);
  }
}

export function verifyUser(user, action) {
  return (dispatch) => {
    verificationAction(user, action).then((response) => {
      dispatch(setShowVerificationModal(false));
      if (response.ok) {
        dispatch(editUser(response.user));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}
