import {
  WORK_ORDERS_DETAILS_ADD_OPEN_ITEM_FORM_INVALID,
  WORK_ORDERS_DETAILS_ADD_OPEN_ITEM_FORM_VALID,
  WORK_ORDERS_DETAILS_ADD_OPEN_ITEM_FORM_VALUE_UPDATE,
  WORK_ORDERS_DETAILS_ADD_OPEN_ITEM_MODAL_SHOW,
  WORK_ORDERS_DETAILS_OPEN_ITEM_EDIT_SET,
  WORK_ORDERS_DETAILS_EDIT_OPEN_ITEM_FORM_VALUE_UPDATE,
  WORK_ORDERS_DETAILS_OPEN_ITEM_SELECTED,
} from '../../../../actions/admin/work-orders/action-types';

const initialState = {
  editOpenItem: false,
  form: {
    critical: false,
    description: '',
    dueDate: '',
    memberId: null,
  },
  editForm: {
    userId: null,
    description: '',
    dueDate: null,
    resolved: false,
    resolvedNote: '',
  },
  invalidFields: [],
  showAddOpenItemModal: false,
  validationMessage: '',
};

export default function openItemsUiReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_DETAILS_ADD_OPEN_ITEM_FORM_INVALID:
      return {
        ...state,
        invalidFields: action.payload.invalidFields,
        validationMessage: action.payload.message,
      };

    case WORK_ORDERS_DETAILS_ADD_OPEN_ITEM_FORM_VALID:
      return {
        ...state,
        invalidFields: [],
        validationMessage: '',
      };

    case WORK_ORDERS_DETAILS_ADD_OPEN_ITEM_FORM_VALUE_UPDATE:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case WORK_ORDERS_DETAILS_EDIT_OPEN_ITEM_FORM_VALUE_UPDATE:
      return {
        ...state,
        editForm: {
          ...state.editForm,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case WORK_ORDERS_DETAILS_ADD_OPEN_ITEM_MODAL_SHOW:
      return {
        ...initialState,
        showAddOpenItemModal: action.payload,
      };

    case WORK_ORDERS_DETAILS_OPEN_ITEM_EDIT_SET:
      return {
        ...state,
        editOpenItem: action.payload,
      };

    case WORK_ORDERS_DETAILS_OPEN_ITEM_SELECTED:
      return {
        ...initialState,
        editOpenItem: false,
        resolved:
          action.payload !== null ? action.payload.resolved : state.resolved,
      };

    default:
      return state;
  }
}
