export const SCHEDULE_TABS = [
  { title: 'Schedules', id: 'schedules' },
  { title: 'Published', id: 'published' },
  { title: 'Archived', id: 'archived' },
];
export const DEFAULT_SCHEDULE_TAB = 'schedules';

export const SCHEDULE_FORM_TABS = [
  { title: 'General Info', id: 'general_info' },
  { title: 'Permissions', id: 'permissions' },
  { title: 'Editing', id: 'editing' },
];
export const DEFAULT_SCHEDULE_FORM_TAB = 'general_info';

export const alphabeticalFilterOptions = [
  { id: 'all', label: 'All' },
  { id: 'asc', label: 'Ascending' },
  { id: 'desc', label: 'Descending' },
];
export const dateFilterOptions = [
  { id: 'all', label: 'All' },
  { id: 'desc', label: 'Newest' },
  { id: 'asc', label: 'Oldest' },
];

export const SCHEDULES_TABLE_HEADERS = [
  'DESCRIPTION',
  'DURATION',
  'ORIGINAL START',
  'PREVIOUS START',
  'CURRENT START',
  'START VARIANCE',
  'ORIGINAL COMPLETION',
  'PREVIOUS COMPLETION',
  'CURRENT COMPLETION',
  'COMPLETION VARIANCE',
  'RESPONSIBLE',
  'ACTION',
];

class Schedule {
  isSharedView = (category) => category.visibility === 'shared';
  isPublicView = (category) => category.visibility === 'public';
  isSharedEdit = (category) => category.editing === 'shared';
  isPublicEdit = (category) => category.editing === 'public';

  isCreator = (user, cat) =>
    !!cat && !!cat.created_by && user.id === cat.created_by.id;

  hasSharedView = (user, category) =>
    this.isSharedView(category) &&
    category.visibility_users &&
    category.visibility_users.find((u) => u.id === user.id);

  hasSharedEdit = (user, category) =>
    this.isSharedEdit(category) &&
    category.editing_users &&
    category.editing_users.find((u) => u.id === user.id);
}

export default new Schedule();
