/* eslint-disable default-case */
import axios from 'axios';
import BaseApi from './base-api';

class ProjectTagsApi extends BaseApi {
  getAll(projectId) {
    const url = `${this.baseUrl}/projects/${projectId}/project_tags`;

    return axios
      .get(url)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  save(projectId, tagLabel) {
    const url = `${this.baseUrl}/projects/${projectId}/project_tags`;
    const data = new FormData();
    data.append('project_tag[label]', tagLabel);

    return axios
      .post(url, data)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }
}

export const projectTagsApi = new ProjectTagsApi();
