import React, { Component } from 'react';
import { connect } from 'react-redux';
import './CloseoutTable.css';
import doneSvg from 'images/done.svg';
import doneDarkModeSvg from 'images/doneDarkMode.svg';
import SimpleTable from 'components/shared/table/SimpleTable';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getCloseoutCats,
  getCloseoutContribs,
  getTotal,
  getCloseoutCatsCompanyTypes,
} from 'components/admin/projects/details/project-closeout/store/selectors';
import { getDarkMode } from 'selectors/theme';
import { compareCompanyTypeHierarchy } from 'services/utils/closeout-table-util';

class CloseoutTable extends Component {
  closeoutContribComments = (closeoutContrib) => {
    const misc = closeoutContrib.closeout_contributor_cats.find(
      (contribCat) => contribCat.closeout_cat.value === 'misc'
    );
    const miscComments = misc && misc.applies && misc.comments;
    return (
      <span>
        {closeoutContrib.comments ? closeoutContrib.comments : 'N/A'}
        {miscComments && (
          <span>
            {closeoutContrib.comments && (
              <span>
                <br />
                <br />
              </span>
            )}
            <b>Item Name:</b> {miscComments}
          </span>
        )}
      </span>
    );
  };

  displayCloseoutCat = (closeoutCat) => {
    const { closeoutCatsCompanyTypes } = this.props;
    if (closeoutCat.value === 'misc') {
      return false;
    }
    return closeoutCatsCompanyTypes.some((catCompanyType) => {
      return (
        catCompanyType.closeout_cat_id === closeoutCat.id &&
        !!catCompanyType.applies
      );
    });
  };

  mapCloseoutContribs = () => {
    const { closeoutContribs, closeoutCats, darkMode } = this.props;
    return closeoutContribs
      .filter((contrib) =>
        contrib.closeout_contributor_cats.some(
          (contribCat) => !!contribCat.applies
        )
      )
      .sort((contrib1, contrib2) =>
        compareCompanyTypeHierarchy(
          contrib1.company_type,
          contrib2.company_type
        )
      )
      .map((closeoutContrib) => {
        return {
          id: closeoutContrib.id,
          data: [
            `${closeoutContrib.csi_code_division} ${
              closeoutContrib.csi_code_label.length > 20
                ? `${closeoutContrib.csi_code_label.substring(0, 20)}...`
                : closeoutContrib.csi_code_label
            }`,
            closeoutContrib.company_name.length > 20
              ? `${closeoutContrib.company_name.substring(0, 20)}...`
              : closeoutContrib.company_name,
          ]
            .concat(
              closeoutCats
                .filter((closeoutCat) => this.displayCloseoutCat(closeoutCat))
                .map((closeoutCat) => {
                  const closeoutContribCat = closeoutContrib.closeout_contributor_cats.find(
                    (contribCat) =>
                      contribCat.closeout_cat_id === closeoutCat.id
                  );
                  return closeoutContribCat && closeoutContribCat.applies ? (
                    closeoutContribCat.documents.length > 0 ? (
                      <img
                        alt="checked"
                        src={darkMode ? doneDarkModeSvg : doneSvg}
                      />
                    ) : (
                      ''
                    )
                  ) : (
                    '--'
                  );
                })
            )
            .concat(this.closeoutContribComments(closeoutContrib)),
        };
      });
  };

  render() {
    const { closeoutCats } = this.props;
    return (
      <div className="closeout-table">
        <SimpleTable
          className="projects-table"
          headers={['COMPANY TYPE', 'COMPANY NAME']
            .concat(
              closeoutCats
                .filter((closeoutCat) => this.displayCloseoutCat(closeoutCat))
                .map((cat) => cat.label.toUpperCase())
            )
            .concat('REMARKS')}
          rows={this.mapCloseoutContribs()}
          emptyMessage="No Items"
          stickyHeader={true}
        />
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    closeoutCats: getCloseoutCats(state),
    closeoutContribs: getCloseoutContribs(state),
    total: getTotal(state),
    darkMode: getDarkMode(state),
    closeoutCatsCompanyTypes: getCloseoutCatsCompanyTypes(state),
  };
})(CloseoutTable);
