import axios from 'axios';
import BaseApi from './base-api';

class ProjectProposalsApi extends BaseApi {
  getProjectProposal(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/project_proposal`)
      .then((response) => {
        return {
          ok: true,
          projectProposal: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getProjectProposalPdf(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/project_proposal/print`, {
        responseType: 'blob',
      })
      .then((response) => {
        return {
          ok: true,
          pdf: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createProjectProposal(projectId, values) {
    const data = this.projectProposalParams(values);

    return axios
      .post(`${this.baseUrl}/projects/${projectId}/project_proposal`, data)
      .then((response) => {
        return {
          ok: true,
          projectProposal: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateProjectProposal(projectId, values) {
    const data = this.projectProposalParams(values);

    return axios
      .put(`${this.baseUrl}/projects/${projectId}/project_proposal`, data)
      .then((response) => {
        return {
          ok: true,
          projectProposal: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateProjectProposalCostsShown(projectId, shown) {
    const data = {
      project_proposal: {
        costs_shown_to_primary: shown,
      },
    };

    return axios
      .put(`${this.baseUrl}/projects/${projectId}/project_proposal`, data)
      .then((response) => {
        return {
          ok: true,
          projectProposal: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  approveProjectProposal(projectId, status) {
    return axios
      .put(`${this.baseUrl}/projects/${projectId}/project_proposal/approve`, {
        status: status,
      })
      .then((response) => {
        return {
          ok: true,
          projectProposal: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  projectProposalParams(values) {
    const params = {
      project_proposal: {
        costs_shown_to_primary: values.costsShownToPrimary,
        permits: values.permits,
        general_conditions: values.generalConditions,
        insurance: values.insurance,
        fee: values.fee,
        contingency: values.contingency,
        project_proposal_items_attributes: values.projectProposalItems.map(
          (item) => {
            const subItemParams = item.subItems.map((subItem) => {
              return {
                id: subItem.id,
                division: subItem.division,
                label: subItem.label,
                trade_costs: subItem.tradeCosts,
                award_recommendation_id: subItem.awardRecommendationId,
                _destroy: !!subItem.delete,
              };
            });

            const itemParams = {
              csi_code_id: item.csiCodeId,
              trade_costs: item.tradeCosts,
              award_recommendation_id: item.awardRecommendationId,
              project_proposal_sub_items_attributes: subItemParams,
            };

            if (item.id) {
              itemParams['id'] = item.id;
              itemParams['_destroy'] = !!item.delete;
            }

            return itemParams;
          }
        ),
      },
    };

    [
      ['permits', 'permits_items_attributes'],
      ['generalConditions', 'general_conditions_items_attributes'],
      ['insurance', 'insurance_items_attributes'],
      ['fee', 'fee_items_attributes'],
      ['contingency', 'contingency_items_attributes'],
    ].forEach((attr) => {
      params['project_proposal'][attr[1]] = values[`${attr[0]}Items`].map(
        (item) => {
          return {
            id: item.id,
            summary: item.summary,
            cost: item.cost,
            _destroy: !!item.delete,
          };
        }
      );
    });

    return params;
  }
}

export const projectProposalsApi = new ProjectProposalsApi();
