import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormModal from 'components/shared/modal/FormModal';
import CoiExpDocForm from 'components/admin/projects/details/project-coi-exps/CoiExpDocForm';

class CoiExpDocModal extends Component {
  static propTypes = {
    initialValues: PropTypes.shape({}),
    showModal: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  render() {
    const { initialValues, showModal, onSubmit, onClose } = this.props;
    return (
      showModal && (
        <FormModal
          className="coi-exp-doc-form-modal"
          show={showModal}
          onClose={onClose}
          subTitle="COI Document Liabilities"
        >
          <CoiExpDocForm initialValues={initialValues} onSubmit={onSubmit} />
        </FormModal>
      )
    );
  }
}

export default connect()(CoiExpDocModal);
