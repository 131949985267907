import {
  WORK_ORDERS_LOAD_SUCCESS,
  WORK_ORDERS_PAGE_CHANGE,
  WORK_ORDERS_SEARCH_TEXT_CHANGE,
  WORK_ORDERS_INVOICES_TAB_CHANGE,
} from 'actions/admin/work-orders/action-types';

const initialState = {
  currentPage: 1,
  searchText: '',
  showNoWorkOrdersOverlay: false,
  currentInvoicesTab: 1,
};

export default function workOrdersUiReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_LOAD_SUCCESS:
      return {
        ...state,
        showNoWorkOrdersOverlay: action.payload.total === 0, // show overlay if no WO created yet
      };

    case WORK_ORDERS_SEARCH_TEXT_CHANGE:
      return {
        ...state,
        searchText: action.payload,
      };

    case WORK_ORDERS_PAGE_CHANGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case WORK_ORDERS_INVOICES_TAB_CHANGE:
      return {
        ...state,
        currentInvoicesTab: action.payload,
      };

    default:
      return state;
  }
}
