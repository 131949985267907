import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import { Drawer, Grid, Typography } from '@mui/material';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import cancelSvg from 'images/cancel.svg';
import cancelDarkSvg from 'images/cancel-dark.svg';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import { getDarkMode } from 'selectors/theme';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { INITIAL_FILTERS } from 'domain/project-photo';
import {
  setProjectPhotosFilters,
  setProjectPhotosShowFilters,
  loadProjectPhotosCount,
} from './store/actions';
import {
  getProjectPhotosFilters,
  getProjectPhotosShowFilters,
} from './store/selectors';
import ProjectPhotosFilterType from './ProjectPhotosFilterType';
import ProjectPhotosFilterTrades from './ProjectPhotosFilterTrades';
import ProjectPhotosFilterSitesAreas from './ProjectPhotosFilterSitesAreas';
import ProjectPhotosFilterUsers from './ProjectPhotosFilterUsers';
import ProjectPhotosFilterTags from './ProjectPhotosFilterTags';
import ProjectPhotosFilterDate from './ProjectPhotosFilterDate';

const ProjectPhotosFiltersModal = (props) => {
  const {
    dispatch,
    show,
    directory,
    currentProject,
    currentFilters,
    darkMode,
  } = props;
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [countResults, setCountResults] = useState(0);
  const onFilterChange = (newFilters = {}) => {
    const fs = { ...filters, ...newFilters };
    setFilters(fs);
    dispatch(loadProjectPhotosCount(currentProject.id, fs)).then((response) => {
      if (response.ok) setCountResults(response.data);
    });
  };

  const onClose = () => dispatch(setProjectPhotosShowFilters(false));
  const onViewResults = () => {
    dispatch(setProjectPhotosFilters({ ...filters, doSearch: true }));
    onClose();
  };

  useEffect(() => {
    if (show && currentProject.id) {
      if (!directory || directory.project_id !== currentProject.id) {
        dispatch(loadDirectoryRequest(currentProject.id));
      }
    }
    if (show) setFilters({ ...currentFilters });
  }, [show]);

  const FilterLabel = ({ label, children }) => {
    return (
      <Grid container spacing={1} flexDirection={'column'}>
        <Grid item>
          <Typography fontSize={18} color="text.primary">
            {label}
          </Typography>
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    );
  };

  if (!show) return null;

  return (
    <Drawer
      open={show}
      anchor="right"
      onClose={onClose}
      className="project-photos-filter-modal"
    >
      <div className="filter-modal-header">
        <Grid
          container
          justifyContent={'space-between'}
          bgcolor="background.paper"
          alignItems={'center'}
          paddingX={4}
          paddingY={2}
        >
          <Grid item>
            <Typography fontSize={20} color="text.primary">
              Filter
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={onClose}
              type={BUTTON_TYPES.LINK}
              label={
                <img
                  src={darkMode ? cancelDarkSvg : cancelSvg}
                  style={{ width: 20, height: 20 }}
                  alt="Close"
                />
              }
            />
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        flexDirection={'column'}
        paddingX={4}
        paddingY={1}
        spacing={4}
        width={500}
      >
        <Grid item>
          <ProjectPhotosFilterType
            value={filters.type}
            liked={filters.liked}
            onChange={onFilterChange}
          />
        </Grid>
        <Grid item>
          <FilterLabel label={'By Trade'}>
            <ProjectPhotosFilterTrades
              value={filters.trades}
              onChange={onFilterChange}
            />
          </FilterLabel>
        </Grid>
        <Grid item>
          <FilterLabel label={'By Location'}>
            <ProjectPhotosFilterSitesAreas
              sites={filters.sites}
              areas={filters.areas}
              onChange={onFilterChange}
            />
          </FilterLabel>
        </Grid>
        <Grid item>
          <FilterLabel label={'By User'}>
            <ProjectPhotosFilterUsers
              value={filters.users}
              onChange={onFilterChange}
            />
          </FilterLabel>
        </Grid>
        <Grid item>
          <FilterLabel label={'By Tag'}>
            <ProjectPhotosFilterTags
              value={filters.tags}
              onChange={onFilterChange}
            />
          </FilterLabel>
        </Grid>
        <Grid item>
          <FilterLabel label={'By Date'}>
            <ProjectPhotosFilterDate
              from={filters.from}
              to={filters.to}
              onChange={onFilterChange}
            />
          </FilterLabel>
        </Grid>
      </Grid>
      <div className="filter-modal-footer">
        <Grid
          container
          bgcolor="background.paper"
          paddingX={4}
          paddingBottom={2}
          paddingTop={3}
        >
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            disabled={countResults === 0}
            label={`View ${
              countResults ? numeral(countResults).format('0,0') : ''
            } Result${countResults !== 1 ? 's' : ''}`}
            onClick={onViewResults}
          />
        </Grid>
      </div>
    </Drawer>
  );
};

export default connect((state) => ({
  show: getProjectPhotosShowFilters(state),
  directory: getDirectory(state),
  currentProject: getCurrentProject(state),
  currentFilters: getProjectPhotosFilters(state),
  darkMode: getDarkMode(state),
}))(ProjectPhotosFiltersModal);
