import React from 'react';
import PropTypes from 'prop-types';
import FormModal from 'components/shared/modal/FormModal';
import NotificationSettingForm from 'components/shared/notification-settings/NotificationSettingsForm';

import './NotificationSettingModal.css';

const NotificationSettingModal = (props) => {
  const { show, onClose } = props;

  return (
    <FormModal
      show={show}
      onClose={onClose}
      subTitle={'Notifications Settings'}
      className="notification-settings-modal"
    >
      <div className="notification-settings-container">
        <NotificationSettingForm
          handleSubmit={onClose}
          handleCancel={onClose}
        />
      </div>
    </FormModal>
  );
};

NotificationSettingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NotificationSettingModal;
