import axios from 'axios';
import BaseApi from 'services/api/base-api';

class ReceiptsApi extends BaseApi {
  deleteReceipt(receiptId) {
    return axios
      .delete(`${this.baseUrl}/receipts/${receiptId}`)
      .then(() => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  editReceipt(
    receiptId,
    values,
    imageIdToDelete = null,
    documentIdToDelete = null
  ) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    let apiPayload = new FormData();
    apiPayload.append('receipt[cost]', values.cost);
    apiPayload.append('receipt[description]', values.description);

    if (imageIdToDelete !== null) {
      apiPayload.append('receipt[image_attributes][id]', imageIdToDelete);
      apiPayload.append('receipt[image_attributes][_destroy]', 'true');
    } else if (values.image && values.image.type !== undefined) {
      // It's a file object
      apiPayload.append('receipt[image_attributes][image]', values.image);
    }

    if (documentIdToDelete !== null) {
      apiPayload.append('receipt[document_attributes][id]', documentIdToDelete);
      apiPayload.append('receipt[document_attributes][_destroy]', 'true');
    } else if (values.document && values.document.type !== undefined) {
      // It's a file object
      apiPayload.append(
        'receipt[document_attributes][document]',
        values.document
      );
    }

    return axios
      .put(`${this.baseUrl}/receipts/${receiptId}`, apiPayload, headers)
      .then((response) => {
        return {
          ok: true,
          receipt: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const receiptsApi = new ReceiptsApi();
