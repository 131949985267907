import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ConfirmationModal from 'components/shared/modal/ConfirmationModal';

import { getConfirmCloseModalShow } from './store/selectors';

const ConfirmCloseModal = (props) => {
  const { show, onClose, onConfirm } = props;
  const handleHide = (ok) => {
    if (ok) onConfirm();
    else onClose();
  };

  if (!show) return null;

  return (
    <ConfirmationModal
      show={show}
      message="This item contains content that has not been saved. Would you like
      to save this information?"
      onHide={handleHide}
    />
  );
};

ConfirmCloseModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

ConfirmCloseModal.defaultProps = {
  show: false,
  onClose: () => null,
  onConfirm: () => null,
};

export default connect((state) => ({ show: getConfirmCloseModalShow(state) }))(
  ConfirmCloseModal
);
