import axios from 'axios';
import BaseApi from './base-api';
import { formToFormData } from '../utils/notification-setting-util';

class NotificationSettingsApi extends BaseApi {
  getForProjectContributorUser(projectId, userId) {
    const url = `${this.baseUrl}/notification_settings/view?user_id=${userId}&project_id=${projectId}`;

    return axios
      .get(url)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  update(form) {
    const url = `${this.baseUrl}/notification_settings/${form.id}`;
    const data = formToFormData(form);

    return axios
      .put(url, data)
      .then((response) => ({ ok: true, report: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }
}

export const notificationSettingsApi = new NotificationSettingsApi();
