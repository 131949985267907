import React, { Component } from 'react';
import PropTypes from 'prop-types';

class GoToPage extends Component {
  static propTypes = {
    numberOfPages: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { page: '' };
  }

  onChange() {
    const { numberOfPages, onChange } = this.props;

    const page = parseInt(this.state.page, 10);
    this.setState({ page: '' });
    if (page && page > 0 && page <= numberOfPages) {
      onChange(page);
    }
  }

  render() {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        this.onChange();
      }
    };

    return (
      <div className="go-to-page">
        <label className="go-to-page-title">Go to page</label>
        <div className="go-to-page-body">
          <input
            type="text"
            className="go-to-page-textbox"
            value={this.state.page}
            onChange={(event) => this.setState({ page: event.target.value })}
            onKeyDown={handleKeyDown}
          />
          <button className="go-to-page-button" onClick={() => this.onChange()}>
            {'›'}
          </button>
        </div>
      </div>
    );
  }
}

export default GoToPage;
