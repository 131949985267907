import React from 'react';
import { connect } from 'react-redux';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import ProjectPhotoPermissions from 'permissions/project-photo-permissions';
import Menu, { MENU_SELECTOR } from 'components/shared/menu/Menu';
import useProjectPhotosActions from './ProjectPhotosActions.hook';
import { getProjectPhotosForm } from './store/selectors';

const ProjectPhotosFormMenu = (props) => {
  const { form, currentUser, currentProject } = props;
  const {
    onMove,
    onEditAlbum,
    onDownload,
    onEmail,
    onArchive,
    onRecycle,
    onRestoreArchived,
    onRestoreRecycled,
    onErase,
  } = useProjectPhotosActions(props);

  const getActions = () => {
    const res = [];
    const ids = form.ids;
    const hasPermission = ProjectPhotoPermissions.hasItemPermission(
      currentUser,
      currentProject,
      form
    );

    if (hasPermission) res.push({ content: 'Move', onClick: onMove(ids) });
    if (ids.length === 1 && ids[0].type === 'album' && hasPermission)
      res.push({ content: 'Edit', onClick: onEditAlbum(ids) });
    if (hasPermission)
      res.push({ content: 'Download', onClick: onDownload(ids) });
    if (hasPermission) res.push({ content: 'Email', onClick: onEmail(ids) });
    if (form.status === 'active' && hasPermission)
      res.push({ content: 'Archive', onClick: onArchive(ids) });
    if (form.status === 'archived')
      res.push({ content: 'Restore', onClick: onRestoreArchived(ids) });
    if (form.status === 'active' && hasPermission)
      res.push({ content: 'Recycle', onClick: onRecycle(ids) });
    if (form.status === 'recycled' && hasPermission)
      res.push({ content: 'Restore', onClick: onRestoreRecycled(ids) });
    if (
      form.status === 'recycled' &&
      ProjectPhotoPermissions.canErase(currentUser, currentProject)
    )
      res.push({ content: 'Delete', onClick: onErase(ids) });

    return res;
  };
  const actions = getActions();

  return (
    actions.length > 0 && (
      <Menu
        key="more-actions"
        className={'rotated-icon'}
        selector={MENU_SELECTOR.MORE_OPTIONS_VERTICAL}
        items={actions}
      />
    )
  );
};

export default connect((state) => ({
  form: getProjectPhotosForm(state),
  currentProject: getCurrentProject(state),
  currentUser: getCurrentUser(state),
}))(ProjectPhotosFormMenu);
