import React, { Component } from 'react';
import FormModal from 'components/shared/modal/FormModal';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getShowNewContributorModal,
  getCompanyUsers,
  getShowNewCompanyForm,
  getNewContributorType,
} from 'components/admin/projects/details/project-directory/store/selectors';
import {
  setShowNewContributorModal,
  initNewContributorModal,
  fetchCompanies,
  loadCompanyUsers,
  createProjectContributor,
  setShowNewCompanyForm,
  createCompany,
  findSimilarCompany,
  inviteExistentCompany,
  setNewContributorType,
  loadCompanyUsersSuccess,
} from 'components/admin/projects/details/project-directory/store/actions';
import { loadCsiCodes } from 'components/admin/projects/details/project-proposal/store/actions';
import { getCsiCodes } from 'components/admin/projects/details/project-proposal/store/selectors';
import NewContributorForm from 'components/admin/projects/details/project-directory/NewContributorForm';
import NewCompanyForm from 'components/admin/projects/details/project-directory/NewCompanyForm';
import { connect } from 'react-redux';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import { companyTypeOptions } from 'domain/company-type-enum';
import SimilarCompaniesModal from 'components/admin/companies/SimilarCompaniesModal';

class NewContributorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmSimilarCompanies: null,
    };

    this.firstCompaniesLoad = true;
    props.dispatch(initNewContributorModal());
    this.props.dispatch(loadCompanyUsersSuccess([]));
    if (this.props.fixedContributorType) {
      this.props.dispatch(
        setNewContributorType(this.props.fixedContributorType)
      );
    }
  }

  componentDidMount() {
    this.props.dispatch(loadCsiCodes());
  }

  handleClose = () => {
    this.props.dispatch(setShowNewContributorModal(false));
    this.handleBack();
  };

  handleBack = () => {
    this.firstCompaniesLoad = true;
    this.props.dispatch(setShowNewCompanyForm(false));
  };

  handleOnSubmit = (values) => {
    this.props
      .dispatch(createProjectContributor(this.props.currentProject.id, values))
      .then((response) => {
        if (response.ok) {
          this.handleClose();
        }
      });
  };

  handleSubmitNewCompanyContributor = (company, values) => {
    const { dispatch } = this.props;
    dispatch(loadCompanyUsers(company.id)).then((response) => {
      if (!response.ok) return;

      values.contributorCompanyId = company.id;
      values.userIds = response.users.map((user) => user.id);
      this.handleOnSubmit(values);
    });
  };

  handleCreateCompany = (values) => {
    const { dispatch, currentProject } = this.props;

    dispatch(createCompany(currentProject.id, values)).then((response) => {
      if (response.ok)
        this.handleSubmitNewCompanyContributor(response.company, values);
    });
  };

  handleInviteExistentCompany = (companyId, values) => {
    const { dispatch, currentProject } = this.props;

    dispatch(inviteExistentCompany(currentProject.id, companyId)).then(
      (response) => {
        if (response.ok)
          this.handleSubmitNewCompanyContributor(response.company, values);
      }
    );
  };

  handleSubmitNewCompany = (values) => {
    const { dispatch } = this.props;

    dispatch(findSimilarCompany(values)).then(({ ok, companies }) => {
      if (!ok) return;

      if (companies.length > 0)
        return this.setState({
          confirmSimilarCompanies: { values, companies },
        });

      this.handleCreateCompany(values);
    });
  };

  handleResolveSimilar = (companyId) => {
    const {
      confirmSimilarCompanies: { values },
    } = this.state;

    return companyId
      ? this.handleInviteExistentCompany(companyId, values)
      : this.handleCreateCompany(values);
  };

  handleCancelSimilar = () => this.setState({ confirmSimilarCompanies: null });

  loadCompanies = async (input, page) => {
    let options = [];
    if (this.firstCompaniesLoad) {
      this.firstCompaniesLoad = false;
      options.push({
        value: 'newCompany',
        label: (
          <span className="new-company-option">
            <span className="new-company-option-plus">+ </span>
            New Company
            <hr />
          </span>
        ),
      });
    }

    return this.props
      .dispatch(fetchCompanies(null, input, page))
      .then((response) => {
        if (response.ok) {
          return {
            ok: true,
            options: options.concat(
              response.companies.map((element) => {
                return {
                  value: element.id,
                  label: element.name,
                };
              })
            ),
            hasMore: true,
          };
        }
      });
  };

  handleNewContributorTypeChange = (props, value) => {
    this.firstCompaniesLoad = true;
    this.props.dispatch(setNewContributorType(value));
    props.input.onChange(value);
  };

  handleCompanyOptionClick = (props, input) => {
    if (!input) {
      return;
    }
    const value = input.value;

    if (value === 'newCompany') {
      this.props.dispatch(setShowNewCompanyForm(true));
    } else {
      props.input.onChange(value);
      this.props.dispatch(loadCompanyUsers(value));
    }
  };

  formModalTitle = () => {
    return this.props.showNewCompanyForm
      ? 'New Company Details'
      : 'Add Contributor';
  };

  formModalSubTitle = () => {
    return this.props.currentProject.name;
  };

  render() {
    const initialValues = {
      contributorCompanyType: this.props.newContributorType,
      userIds: [],
      sendNotifications: true,
    };
    const types = this.props.fixedContributorType
      ? companyTypeOptions.filter(
          (option) => option.value === this.props.fixedContributorType
        )
      : companyTypeOptions;
    const { confirmSimilarCompanies } = this.state;

    return (
      <div>
        {this.props.currentProject && (
          <FormModal
            className={`new-contributor-modal ${
              this.props.showNewCompanyForm ? 'new-company' : ''
            }`}
            show={this.props.show}
            subTitle={this.formModalSubTitle()}
            title={this.formModalTitle()}
            onBack={this.props.showNewCompanyForm ? this.handleBack : undefined}
            onClose={this.handleClose}
          >
            <SlideDown>
              {this.props.showNewCompanyForm && (
                <NewCompanyForm
                  initialValues={initialValues}
                  types={types}
                  newContributorType={this.props.newContributorType}
                  handleNewContributorTypeChange={
                    this.handleNewContributorTypeChange
                  }
                  csiCodes={this.props.csiCodes}
                  onSubmit={this.handleSubmitNewCompany}
                  onClose={this.handleBack}
                />
              )}
            </SlideDown>

            {!this.props.showNewCompanyForm && (
              <NewContributorForm
                initialValues={initialValues}
                types={types}
                newContributorType={this.props.newContributorType}
                loadCompanies={this.loadCompanies}
                handleNewContributorTypeChange={
                  this.handleNewContributorTypeChange
                }
                handleCompanyOptionClick={this.handleCompanyOptionClick}
                companyUsers={this.props.companyUsers}
                csiCodes={this.props.csiCodes}
                onSubmit={this.handleOnSubmit}
                onClose={this.handleClose}
              />
            )}
          </FormModal>
        )}
        {confirmSimilarCompanies !== null && (
          <SimilarCompaniesModal
            companies={confirmSimilarCompanies.companies}
            onSubmit={(companyId) => this.handleResolveSimilar(companyId)}
            onClose={() => this.handleCancelSimilar()}
          />
        )}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    show: getShowNewContributorModal(state),
    companyUsers: getCompanyUsers(state),
    showNewCompanyForm: getShowNewCompanyForm(state),
    newContributorType: getNewContributorType(state),
    csiCodes: getCsiCodes(state),
  };
})(NewContributorModal);
