import {
  PROJECT_DETAILS_INIT,
  PROJECT_DETAILS_NAV_BAR_SELECTION,
  SHOW_PDF_VIEWER_SET,
  DIRECT_UPLOAD_IN_PROCESS,
  SHOW_DASHBOARD,
} from 'components/admin/projects/details/store/action-types';

const initialState = {
  navBarSelectedItem: null,
  showDashboard: null,
};

export default function projectDetailsUiReducer(state = initialState, action) {
  switch (action.type) {
    case PROJECT_DETAILS_INIT:
      return initialState;

    case PROJECT_DETAILS_NAV_BAR_SELECTION:
      return {
        ...state,
        navBarSelectedItem: action.payload,
      };

    case SHOW_PDF_VIEWER_SET:
      return {
        ...state,
        showPdfViewer: action.payload,
      };

    case DIRECT_UPLOAD_IN_PROCESS:
      return {
        ...state,
        directUploadInProcess: action.payload,
      };

    case SHOW_DASHBOARD:
      return {
        ...state,
        showDashboard: action.payload,
      };

    default:
      return state;
  }
}
