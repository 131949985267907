import {
  WORK_ORDERS_CREATION_SUMMARY_CONFIRMATION_SUCCESS,
  WORK_ORDERS_CREATION_SUMMARY_CONFIRMATION_WITHOUT_PICS_SUCCESS,
  WORK_ORDERS_CREATION_SUMMARY_START,
} from '../../../../actions/admin/work-orders/action-types';

const initialState = {
  showSubmitRequestConfirmation: false,
  showWorkOrderCreatedConfirmation: false,
};

export default function workOrderSummaryUiReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case WORK_ORDERS_CREATION_SUMMARY_CONFIRMATION_SUCCESS:
      return {
        ...state,
        showWorkOrderCreatedConfirmation: true,
      };

    case WORK_ORDERS_CREATION_SUMMARY_CONFIRMATION_WITHOUT_PICS_SUCCESS:
      return {
        ...state,
        showSubmitRequestConfirmation: true,
      };

    case WORK_ORDERS_CREATION_SUMMARY_START:
      return {
        ...state,
        showSubmitRequestConfirmation: false,
        showWorkOrderCreatedConfirmation: false,
      };

    default:
      return state;
  }
}
