import React, { useState } from 'react';
import { connect } from 'react-redux';

import FormModal from 'components/shared/modal/FormModal';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import Input from 'components/shared/input/Input';

import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { createActionItemCategory, setAddCategoryModal } from './store/actions';
import {
  getAddCategoryModalShow,
  getMeetingsInstanceForm,
  getProjectNewMeetingsForm,
} from './store/selectors';
import './AttendanceForm.css';
import './MeetingModal.css';

const AddCategoryModal = (props) => {
  const { dispatch, project, showAddCategoryModal, form, instanceForm } = props;
  const [newCategory, setNewCategory] = useState('');

  const handleClose = () => {
    dispatch(setAddCategoryModal(false));
  };
  const handleSave = () => {
    const values = {
      name: newCategory,
      projectId: project.id,
      meetingsParentId: form.id,
      meetingsInstanceId: instanceForm.id,
    };
    dispatch(createActionItemCategory(project.id, values));
    handleClose();
  };
  return (
    <FormModal
      className={`meeting-modal editing`}
      show={showAddCategoryModal}
      onClose={handleClose}
      subTitle="Add Action Item Category"
    >
      <FormControlBlock
        position="left"
        readonly={false}
        control={
          <Input
            type={'text'}
            placeholder="Action Item Category"
            onChange={(value) => setNewCategory(value)}
            readonly={false}
            value={newCategory}
          />
        }
      />
      <div className="button-actions">
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.WHITE}
          label="Cancel"
          onClick={handleClose}
        />
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          label={'Save'}
          onClick={handleSave}
        />
      </div>
    </FormModal>
  );
};

export default connect((state) => ({
  showAddCategoryModal: getAddCategoryModalShow(state),
  project: getCurrentProject(state),
  instanceForm: getMeetingsInstanceForm(state),
  form: getProjectNewMeetingsForm(state),
}))(AddCategoryModal);
