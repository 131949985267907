import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import FormModal from 'components/shared/modal/FormModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

import { getCurrentProject } from 'components/admin/projects/details/store/selectors';

import Input from 'components/shared/input/Input';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import FormControlBlock from 'components/shared/form/FormControlBlock';

import { getSecurityCamerasCamerasForm } from './store/selectors';
import {
  setSecurityCameraCamerasForm,
  setSecurityCameraCamerasFormValue,
  saveSecurityCameraCameras,
} from './store/actions';
import './SecurityCamerasModal.css';

const SecurityCamerasModal = ({ dispatch, currentProject, camerasForm }) => {
  const handleClose = () => dispatch(setSecurityCameraCamerasForm());

  const handleSubmit = () =>
    dispatch(saveSecurityCameraCameras(currentProject.id, camerasForm));

  const handleCameraChange = (index, name, value) =>
    dispatch(setSecurityCameraCamerasFormValue(index, name, value));

  return (
    <FormModal
      className="security-cameras-modal"
      show
      onClose={handleClose}
      subTitle="Select Security Cameras"
    >
      <div className="cameras-list">
        {camerasForm.cameras.map((camera, index) => (
          <div
            className={classnames('camera', {
              selected: camera.selected,
            })}
            key={camera.channel}
          >
            <div
              className="camera-picture"
              style={{
                backgroundImage: `url(data:image/png;base64,${camera.picture})`,
              }}
              onClick={() =>
                handleCameraChange(index, 'selected', !camera.selected)
              }
            >
              <div className="camera-channel">Channel {camera.channel}</div>
            </div>
            {camera.selected && (
              <div className="camera-form">
                <div className="form-block">
                  <FormControlBlock
                    label={!!camera.name && 'Name'}
                    control={
                      <Input
                        placeholder="Name"
                        value={camera.name}
                        onChange={(value) =>
                          handleCameraChange(index, 'name', value)
                        }
                      />
                    }
                  />
                </div>
                <div className="form-block">
                  <FormControlBlock
                    label={!!camera.description && 'Description'}
                    control={
                      <TextareaAutosizeWrapper
                        placeholder="Description"
                        initialValue={camera.description}
                        onChange={(value) =>
                          handleCameraChange(index, 'description', value)
                        }
                      />
                    }
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="buttons-container">
        <Button
          type={BUTTON_TYPES.RECTANGLE}
          color={BUTTON_COLORS.WHITE}
          className="button-cancel"
          label="CANCEL"
          onClick={handleClose}
        />
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          label="SAVE"
          onClick={handleSubmit}
        />
      </div>
    </FormModal>
  );
};

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    camerasForm: getSecurityCamerasCamerasForm(state),
  };
})(SecurityCamerasModal);
