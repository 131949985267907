import React, { Component } from 'react';
import { connect } from 'react-redux';
import './NewProject.css';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import SimpleTable from 'components/shared/table/SimpleTable';
import CheckBox from 'components/shared/checkbox/CheckBox';
import {
  loadProjectLocationsRequest,
  loadProjectLocationsSuccess,
} from 'actions/admin/project-locations/project-locations';
import {
  getProjectLocations,
  getProjectLocationsCurrentPage,
  getProjectLocationsPageSize,
  getProjectLocationsTotal,
} from 'selectors/admin/project-locations/project-locations';
import {
  getProjectForm,
  getShowNewProjectLocationModal,
} from 'components/admin/projects/creation/store/selectors';
import {
  setProjectFormValue,
  setShowNewProjectLocationModal,
} from 'components/admin/projects/creation/store/actions';
import NewProjectLocationModal from 'components/admin/projects/creation/NewProjectLocationModal';
import PropTypes from 'prop-types';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import SearchBar from 'components/shared/navigation/SearchBar';

class ProjectLocationSelection extends Component {
  static propTypes = {
    submitButtonLabel: PropTypes.string.isRequired,
    submitFunction: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      nameToSearch: '',
    };
  }

  componentDidMount() {
    const {
      projectForm: { clientId },
      dispatch,
    } = this.props;

    dispatch(loadProjectLocationsRequest(1, clientId));
  }

  handleProjectFormChange = (attribute, value) => {
    const { dispatch } = this.props;

    dispatch(setProjectFormValue(attribute, value));
  };

  showAddNewProjectLocationModal = () => {
    const { dispatch } = this.props;
    dispatch(setShowNewProjectLocationModal(true));
  };

  loadProjectLocations = (page) => {
    const { nameToSearch } = this.state;
    const {
      dispatch,
      projectForm: { clientId },
    } = this.props;
    dispatch(loadProjectLocationsSuccess([]));
    dispatch(loadProjectLocationsRequest(page, clientId, nameToSearch));
  };

  handleProjectLocationsPageChange = (newPage) => {
    this.loadProjectLocations(newPage);
  };

  handleSearchTextChange = (value) => {
    this.setState({ nameToSearch: value }, () => this.loadProjectLocations(1));
  };

  handleSearchTextClear = () => {
    this.handleSearchTextChange('');
  };

  render() {
    const { nameToSearch } = this.state;
    const {
      projectLocations,
      projectLocationsCurrentPage,
      projectLocationsPageSize,
      projectLocationsTotal,
      submitButtonLabel,
      showNewProjectModal,
      submitFunction,
      cancelFunction,
      backFunction,
    } = this.props;

    return (
      <React.Fragment>
        <SearchBar
          title="What property is the Project for?"
          searchText={nameToSearch}
          onSearchTextChange={this.handleSearchTextChange}
          onSearch={this.handleSearchTextChange}
          addButton={
            <Button
              type={BUTTON_TYPES.LINK}
              icon={BUTTON_ICONS.PLUS}
              label="Add New Project Location"
              onClick={this.showAddNewProjectLocationModal}
            />
          }
        />

        <div className="selection-table-container project-location-selection">
          <SimpleTable
            headers={['', 'ADDRESS', 'LOCATION NAME']}
            rows={this.mapProjectLocations(projectLocations)}
            emptyMessage="No Project Locations"
          />
        </div>
        <SimplePagination
          currentPage={projectLocationsCurrentPage}
          pageSize={projectLocationsPageSize}
          total={projectLocationsTotal}
          onPageChange={this.handleProjectLocationsPageChange}
        />

        <div className="buttons-container">
          {cancelFunction && (
            <Button
              key="cancel"
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Cancel"
              onClick={cancelFunction}
            />
          )}
          {backFunction && (
            <Button
              key="back"
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Back"
              onClick={backFunction}
            />
          )}
          <Button
            key="submit"
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label={submitButtonLabel}
            disabled={!this.canContinue()}
            onClick={submitFunction}
          />
        </div>

        {showNewProjectModal && <NewProjectLocationModal />}
      </React.Fragment>
    );
  }

  mapProjectLocations = (projectLocations) => {
    const {
      projectForm: { projectLocationId },
    } = this.props;

    return projectLocations.map((projectLocation) => {
      return [
        <CheckBox
          id={projectLocation.id}
          checked={projectLocation.id === projectLocationId}
          shape={'round'}
          size={'medium'}
          onChange={this.handleProjectFormChange.bind(
            this,
            'projectLocationId'
          )}
        />,
        `${projectLocation.location.street_address_1}, ${
          projectLocation.location.city
        }, ${projectLocation.location.state} ${
          projectLocation.location.zip || ''
        }`,
        projectLocation.name || 'N/A',
      ];
    });
  };

  canContinue = () => {
    const {
      projectForm: { projectLocationId },
    } = this.props;

    return !!projectLocationId;
  };
}

export default connect((state) => {
  return {
    projectLocations: getProjectLocations(state),
    projectLocationsCurrentPage: getProjectLocationsCurrentPage(state),
    projectLocationsPageSize: getProjectLocationsPageSize(state),
    projectLocationsTotal: getProjectLocationsTotal(state),
    projectForm: getProjectForm(state),
    showNewProjectModal: getShowNewProjectLocationModal(state),
  };
})(ProjectLocationSelection);
