import { showErrorMessage } from 'actions/messages';
import { projectOpenItemsApi } from 'services/api/project-open-items-api';
import {
  OPEN_ITEMS_MODAL_SHOW,
  OPEN_ITEMS_MODAL_CLOSE,
  OPEN_ITEMS_REPORT_MODAL_SHOW,
  OPEN_ITEMS_REPORT_MODAL_CLOSE,
  OPEN_ITEMS_LOAD_SUCCESS,
  OPEN_ITEM_FORM_MODAL_SHOW,
  OPEN_ITEM_FORM_MODAL_CLOSE,
  OPEN_ITEM_FORM_ATTR_SET,
  OPEN_ITEM_FORM_ERRORS_SET,
  OPEN_ITEM_FORM_ERRORS_ATTR_CLEAR,
  OPEN_ITEM_CREATE_SUCCESS,
  OPEN_ITEM_FORM_SET,
  OPEN_ITEM_FORM_RESET,
  OPEN_ITEM_DETAILS_MODAL_SHOW,
  OPEN_ITEM_DETAILS_MODAL_CLOSE,
  OPEN_ITEM_DETAILS_MODAL_EDIT,
  OPEN_ITEM_DETAILS_MODAL_EDIT_CANCEL,
  OPEN_ITEM_DETAILS_MODAL_RESPOND,
  OPEN_ITEM_DETAILS_MODAL_RESPOND_CANCEL,
  OPEN_ITEM_NOTE_CLEAR,
  OPEN_ITEM_LOAD_SUCCESS,
  OPEN_ITEM_UPDATE_SUCCESS,
  OPEN_ITEM_DELETE_SUCCESS,
  OPEN_ITEMS_COUNT_SUCCESS,
  OPEN_ITEMS_COUNT_INIT,
  OPEN_ITEMS_CRITICAL_SET,
  OPEN_ITEM_NOTES_LOAD_SUCCESS,
  OPEN_ITEM_NOTE_CREATE_SUCCESS,
  OPEN_ITEM_UPDATE_VALUE,
  OPEN_ITEM_PDF_VIEWER_SET,
} from 'components/admin/projects/details/project-open-items/store/action-types';

export function showOpenItemsModalSuccess() {
  return {
    type: OPEN_ITEMS_MODAL_SHOW,
  };
}

export function closeOpenItemsModal() {
  return {
    type: OPEN_ITEMS_MODAL_CLOSE,
  };
}

export function loadOpenItemsSuccess(
  openItems,
  page,
  critical,
  status,
  dueDateFilter
) {
  return {
    type: OPEN_ITEMS_LOAD_SUCCESS,
    payload: {
      ...openItems,
      currentPage: page,
      critical,
      status,
      dueDateFilter,
    },
  };
}

export function showOpenItemsModal(projectId, page, critical) {
  return (dispatch) => {
    return dispatch(loadOpenItems(projectId, page, critical)).then(
      (response) => {
        if (response.ok) {
          dispatch(showOpenItemsModalSuccess());
        }

        return response;
      }
    );
  };
}

export function loadOpenItems(
  projectId,
  page,
  critical,
  status,
  dueDateFilter
) {
  return (dispatch) => {
    return projectOpenItemsApi
      .getProjectOpenItems(projectId, page, critical, status, dueDateFilter)
      .then((response) => {
        if (response.ok) {
          dispatch(
            loadOpenItemsSuccess(
              response,
              page,
              critical,
              status,
              dueDateFilter
            )
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function showOpenItemsReportModal() {
  return {
    type: OPEN_ITEMS_REPORT_MODAL_SHOW,
  };
}

export function closeOpenItemsReportModal() {
  return {
    type: OPEN_ITEMS_REPORT_MODAL_CLOSE,
  };
}

export function showOpenItemFormModal() {
  return {
    type: OPEN_ITEM_FORM_MODAL_SHOW,
  };
}

export function closeOpenItemFormModal() {
  return {
    type: OPEN_ITEM_FORM_MODAL_CLOSE,
  };
}

export function setOpenItemFormAttr(attr, value) {
  return {
    type: OPEN_ITEM_FORM_ATTR_SET,
    payload: { attr, value },
  };
}

export function setOpenItemFormErrors(errors) {
  return {
    type: OPEN_ITEM_FORM_ERRORS_SET,
    payload: errors,
  };
}

export function clearOpenItemFormErrorAttr(attr) {
  return {
    type: OPEN_ITEM_FORM_ERRORS_ATTR_CLEAR,
    payload: attr,
  };
}

export function createOpenItemSuccess(openItem) {
  return {
    type: OPEN_ITEM_CREATE_SUCCESS,
    payload: openItem,
  };
}

export function setOpenItemPdfViewer(pdfViewer) {
  return {
    type: OPEN_ITEM_PDF_VIEWER_SET,
    payload: pdfViewer,
  };
}

export function createProjectOpenItem(projectId, values) {
  return (dispatch) => {
    return projectOpenItemsApi
      .createProjectOpenItem(projectId, values)
      .then((response) => {
        if (response.ok) {
          dispatch(createOpenItemSuccess(response.projectOpenItem));
          dispatch(closeOpenItemFormModal());
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setOpenItemForm(form) {
  return {
    type: OPEN_ITEM_FORM_SET,
    payload: form,
  };
}

export function resetOpenItemForm() {
  return {
    type: OPEN_ITEM_FORM_RESET,
  };
}

export function showOpenItemDetailsModal() {
  return {
    type: OPEN_ITEM_DETAILS_MODAL_SHOW,
  };
}

export function closeOpenItemDetailsModal() {
  return {
    type: OPEN_ITEM_DETAILS_MODAL_CLOSE,
  };
}

export function editOpenItemDetailsModal() {
  return {
    type: OPEN_ITEM_DETAILS_MODAL_EDIT,
  };
}

export function cancelEditOpenItemDetailsModal() {
  return {
    type: OPEN_ITEM_DETAILS_MODAL_EDIT_CANCEL,
  };
}

export function respondOpenItemDetailsModal() {
  return {
    type: OPEN_ITEM_DETAILS_MODAL_RESPOND,
  };
}

export function cancelRespondOpenItemDetailsModal() {
  return {
    type: OPEN_ITEM_DETAILS_MODAL_RESPOND_CANCEL,
  };
}

export function loadOpenItemSuccess(openItem) {
  return {
    type: OPEN_ITEM_LOAD_SUCCESS,
    payload: openItem,
  };
}

export function updateOpenItemSuccess(openItem) {
  return {
    type: OPEN_ITEM_UPDATE_SUCCESS,
    payload: openItem,
  };
}

export function loadOpenItem(projectId, id) {
  return (dispatch) => {
    return projectOpenItemsApi
      .getProjectOpenItem(projectId, id)
      .then((response) => {
        if (response.ok) {
          dispatch(loadOpenItemSuccess(response.projectOpenItem));
          dispatch(showOpenItemDetailsModal());
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function updateProjectOpenItem(projectId, id, values) {
  return (dispatch) => {
    return projectOpenItemsApi
      .updateProjectOpenItem(projectId, id, values)
      .then((response) => {
        if (response.ok) {
          dispatch(loadOpenItemSuccess(response.projectOpenItem));
          dispatch(updateOpenItemSuccess(response.projectOpenItem));
          dispatch(cancelEditOpenItemDetailsModal());
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function deleteOpenItemSuccess(openItemId) {
  return {
    type: OPEN_ITEM_DELETE_SUCCESS,
    payload: openItemId,
  };
}

export function deleteProjectOpenItem(projectId, id) {
  return (dispatch) => {
    return projectOpenItemsApi
      .deleteProjectOpenItem(projectId, id)
      .then((response) => {
        if (response.ok) {
          dispatch(deleteOpenItemSuccess(id));
          dispatch(closeOpenItemDetailsModal());
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function openItemsCountSuccess(total, totalCritical) {
  return {
    type: OPEN_ITEMS_COUNT_SUCCESS,
    payload: { total, totalCritical },
  };
}

export function openItemsCount(projectId) {
  return (dispatch) => {
    return projectOpenItemsApi
      .getProjectOpenItemsCount(projectId)
      .then((response) => {
        if (response.ok) {
          dispatch(
            openItemsCountSuccess(response.total, response.totalCritical)
          );
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function initOpenItemsCount() {
  return {
    type: OPEN_ITEMS_COUNT_INIT,
  };
}

export function setOpenItemsCritical(critical) {
  return {
    type: OPEN_ITEMS_CRITICAL_SET,
    payload: critical,
  };
}

export function respondProjectOpenItem(projectId, id, values) {
  return (dispatch) => {
    return projectOpenItemsApi
      .respondProjectOpenItem(projectId, id, values)
      .then((response) => {
        if (response.ok) {
          dispatch(loadOpenItemSuccess(response.projectOpenItem));
          dispatch(updateOpenItemSuccess(response.projectOpenItem));
          dispatch(cancelRespondOpenItemDetailsModal());
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadOpenItemNotesSuccess(openItemNotes) {
  return {
    type: OPEN_ITEM_NOTES_LOAD_SUCCESS,
    payload: openItemNotes,
  };
}

export function clearItemNotes() {
  return {
    type: OPEN_ITEM_NOTE_CLEAR,
  };
}

export function loadOpenItemNotes(id) {
  return (dispatch) => {
    return projectOpenItemsApi.getProjectOpenItemNotes(id).then((response) => {
      if (response.ok) {
        dispatch(loadOpenItemNotesSuccess(response.projectOpenItemNotes));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function updateOpenItemValue(attr, value) {
  return {
    type: OPEN_ITEM_UPDATE_VALUE,
    payload: { attr, value },
  };
}

export function markOpenItemNotesRead(openItem, userId) {
  return (dispatch) => {
    return projectOpenItemsApi
      .markProjectOpenItemNotesRead(openItem.id)
      .then((response) => {
        if (response.ok) {
          const notesReadBy = openItem.notes_read_by.concat(`${userId}`);
          dispatch(updateOpenItemValue('notes_read_by', notesReadBy));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function createOpenItemNoteSuccess(openItemNote) {
  return {
    type: OPEN_ITEM_NOTE_CREATE_SUCCESS,
    payload: openItemNote,
  };
}

export function createOpenItemNote(id, content) {
  return (dispatch) => {
    return projectOpenItemsApi
      .createProjectOpenItemNote(id, content)
      .then((response) => {
        if (response.ok) {
          dispatch(createOpenItemNoteSuccess(response.projectOpenItemNote));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}
