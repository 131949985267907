import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormControlBlock, {
  CONTROL_SIZE,
} from 'components/shared/form/FormControlBlock';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import { getCurrentReportViewSubscription } from './store/selectors';
import { setReportViewFormValue } from './store/actions';

class ReportSubscriptions extends Component {
  handleFormChange = (attribute, value) => {
    const { dispatch } = this.props;
    dispatch(setReportViewFormValue('subscription', attribute, value));
  };

  render() {
    const {
      currentReportViewSubscription: {
        per_term_report,
        terms,
        fee,
        payment_mode,
        start_date,
        cancel_date,
        duration,
        renewal_terms,
        renewal_total_terms,
        renewal_fee,
        renewal_paid_to_date,
        renewal_payment_mode,
        renewal_start_date,
        renewal_cancel_date,
        renewal_duration,
        archived_terms,
        archived_total_terms,
        archived_fee,
        archived_paid_to_date,
        archived_payment_mode,
        archived_start_date,
        archived_cancel_date,
        archived_duration,
        statuses,
        counts,
        times_suspended,
        total_project_count,
      },
    } = this.props;

    return (
      <React.Fragment>
        <div className="form-block">
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('per_term_report', value)
                }
                checked={per_term_report}
                label="Per Term Report"
                position="left"
              />
            }
          />
          <FormControlBlock size={CONTROL_SIZE.MEDIUM} />
          <FormControlBlock size={CONTROL_SIZE.SMALL} />
          <FormControlBlock size={CONTROL_SIZE.SMALL} />
          <FormControlBlock size={CONTROL_SIZE.SMALL} />
        </div>

        <div className="sidebar-form-body-subtitle">Inital Subscriptions</div>

        <div className="form-block">
          <FormControlBlock
            label={!!terms.length > 0 && 'Subscription Term'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Subscription Term"
                currentValues={terms}
                onChange={(values) => this.handleFormChange('terms', values)}
                search={false}
                options={[]}
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.MEDIUM}
            control={
              <LabeledCheckBox
                onChange={(value) => this.handleFormChange('fee', value)}
                checked={fee}
                label="Subscription Fee"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.SMALL}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('payment_mode', value)
                }
                checked={payment_mode}
                label="Payment Method"
                position="left"
              />
            }
          />
          <FormControlBlock size={CONTROL_SIZE.SMALL} />
          <FormControlBlock size={CONTROL_SIZE.SMALL} />
        </div>

        <div className="form-block">
          <FormControlBlock
            size={CONTROL_SIZE.SMALL}
            control={
              <LabeledCheckBox
                onChange={(value) => this.handleFormChange('start_date', value)}
                checked={start_date}
                label="Start Date"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.SMALL}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('cancel_date', value)
                }
                checked={cancel_date}
                label="Cancel Date"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.MEDIUM}
            control={
              <LabeledCheckBox
                onChange={(value) => this.handleFormChange('duration', value)}
                checked={duration}
                label="Inc Duration Calculation"
                position="left"
              />
            }
          />
          <FormControlBlock size={CONTROL_SIZE.SMALL} />
          <FormControlBlock size={CONTROL_SIZE.SMALL} />
          <FormControlBlock size={CONTROL_SIZE.SMALL} />
        </div>

        <div className="sidebar-form-body-subtitle">Subscription Renewal</div>

        <div className="form-block">
          <FormControlBlock
            label={!!renewal_terms.length > 0 && 'Renewal Term'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Renewal Term"
                currentValues={renewal_terms}
                onChange={(values) =>
                  this.handleFormChange('renewal_terms', values)
                }
                search={false}
                options={[]}
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.MEDIUM}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('renewal_total_terms', value)
                }
                checked={renewal_total_terms}
                label="Total Renewal Terms"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.SMALL}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('renewal_fee', value)
                }
                checked={renewal_fee}
                label="Renewal Fee"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.SMALL}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('renewal_paid_to_date', value)
                }
                checked={renewal_paid_to_date}
                label="Paid To Date"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.SMALL}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('renewal_payment_mode', value)
                }
                checked={renewal_payment_mode}
                label="Payment Method"
                position="left"
              />
            }
          />
        </div>

        <div className="form-block">
          <FormControlBlock
            size={CONTROL_SIZE.SMALL}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('renewal_start_date', value)
                }
                checked={renewal_start_date}
                label="Start Date"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.SMALL}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('renewal_cancel_date', value)
                }
                checked={renewal_cancel_date}
                label="Cancel Date"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.MEDIUM}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('renewal_duration', value)
                }
                checked={renewal_duration}
                label="Inc Duration Calculation"
                position="left"
              />
            }
          />
          <FormControlBlock size={CONTROL_SIZE.SMALL} />
          <FormControlBlock size={CONTROL_SIZE.SMALL} />
          <FormControlBlock size={CONTROL_SIZE.SMALL} />
        </div>

        <div className="sidebar-form-body-subtitle">Data Subscriptions</div>

        <div className="form-block">
          <FormControlBlock
            label={!!archived_terms.length > 0 && 'Data Term'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Data Term"
                currentValues={renewal_terms}
                onChange={(values) =>
                  this.handleFormChange('archived_terms', values)
                }
                search={false}
                options={[]}
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.MEDIUM}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('archived_total_terms', value)
                }
                checked={archived_total_terms}
                label="Total Data Terms"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.SMALL}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('archived_fee', value)
                }
                checked={archived_fee}
                label="Data Fee"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.SMALL}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('archived_paid_to_date', value)
                }
                checked={archived_paid_to_date}
                label="Paid To Date"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.SMALL}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('archived_payment_mode', value)
                }
                checked={archived_payment_mode}
                label="Payment Method"
                position="left"
              />
            }
          />
        </div>

        <div className="form-block">
          <FormControlBlock
            size={CONTROL_SIZE.SMALL}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('archived_start_date', value)
                }
                checked={archived_start_date}
                label="Start Date"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.SMALL}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('archived_cancel_date', value)
                }
                checked={archived_cancel_date}
                label="Cancel Date"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.MEDIUM}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('archived_duration', value)
                }
                checked={archived_duration}
                label="Inc Duration Calculation"
                position="left"
              />
            }
          />
          <FormControlBlock size={CONTROL_SIZE.SMALL} />
          <FormControlBlock size={CONTROL_SIZE.SMALL} />
          <FormControlBlock size={CONTROL_SIZE.SMALL} />
        </div>

        <div className="sidebar-form-body-subtitle">Other Information</div>

        <div className="form-block">
          <FormControlBlock
            label={!!statuses.length > 0 && 'Subscription Status'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Subscription Status"
                currentValues={statuses}
                onChange={(values) => this.handleFormChange('statuses', values)}
                search={false}
                options={[]}
              />
            }
          />
          <FormControlBlock
            label={!!counts.length > 0 && 'Subscription Count'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Subscription Count"
                currentValues={counts}
                onChange={(values) => this.handleFormChange('counts', values)}
                search={false}
                options={[]}
              />
            }
          />
          <FormControlBlock size={CONTROL_SIZE.MEDIUM} />
          <FormControlBlock size={CONTROL_SIZE.SMALL} />
        </div>

        <div className="form-block">
          <FormControlBlock
            size={CONTROL_SIZE.SMALL}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('times_suspended', value)
                }
                checked={times_suspended}
                label="Times Suspended"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.SMALL}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('total_project_count', value)
                }
                checked={total_project_count}
                label="Total Project Count"
                position="left"
              />
            }
          />
          <FormControlBlock size={CONTROL_SIZE.MEDIUM} />
          <FormControlBlock size={CONTROL_SIZE.SMALL} />
          <FormControlBlock size={CONTROL_SIZE.SMALL} />
          <FormControlBlock size={CONTROL_SIZE.SMALL} />
        </div>
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    currentReportViewSubscription: getCurrentReportViewSubscription(state),
  };
})(ReportSubscriptions);
