import axios from 'axios';
import BaseApi from './base-api';

class ProjectContributorsApi extends BaseApi {
  createProjectContributor(projectId, values) {
    const data = {
      project_contributor: {
        contributor_type: values.contributorCompanyType,
        company_id: values.contributorCompanyId,
        csi_code_id: values.csiCodeId,
        user_ids: values.userIds,
        primary: values.primary,
        send_notifications: values.sendNotifications,
      },
    };

    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/directory/project_contributors`,
        data
      )
      .then((response) => {
        return {
          ok: true,
          projectContributor: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateProjectContributor(projectId, id, values) {
    const data = {
      project_contributor: {
        user_ids: values.userIds,
        send_notifications: values.sendNotifications,
      },
    };

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/directory/project_contributors/${id}`,
        data
      )
      .then((response) => {
        return {
          ok: true,
          projectContributor: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  destroyProjectContributor(projectId, id) {
    return axios
      .delete(
        `${this.baseUrl}/projects/${projectId}/directory/project_contributors/${id}`
      )
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const projectContributorsApi = new ProjectContributorsApi();
