import axios from 'axios';
import BaseApi from './base-api';

class SubmittalAddlAssigneesApi extends BaseApi {
  updateSubmittalAddlAssignee(id, values) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = this.submittalAddlAssigneeParams(values);

    return axios
      .put(`${this.baseUrl}/submittal_addl_assignees/${id}`, data, headers)
      .then((response) => {
        return {
          ok: true,
          submittalAddlAssignee: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  submittalAddlAssigneeParams(values) {
    let resImgsIndex = 0;
    let resDocsIndex = 0;
    let apiPayload = new FormData();
    apiPayload.append(
      'submittal_addl_assignee[stamp_action]',
      values.stampAction
    );
    if (values.closed || values.closed === false)
      apiPayload.append('submittal_addl_assignee[closed]', values.closed);

    values.resImages
      .filter((img) => img.file)
      .forEach((img) => {
        let file = img.file;
        apiPayload.append(
          `submittal_addl_assignee[response_images_attributes][${resImgsIndex}][image]`,
          file,
          file.name
        );
        resImgsIndex++;
      });

    values.resDocs
      .filter((doc) => doc.file)
      .forEach((doc) => {
        let file = doc.file;
        apiPayload.append(
          `submittal_addl_assignee[response_documents_attributes][${resDocsIndex}][key]`,
          file.name
        );
        resDocsIndex++;
      });

    values.resImagesDelete
      .filter((img) => img.id)
      .forEach((img) => {
        apiPayload.append(
          `submittal_addl_assignee[response_images_attributes][${resImgsIndex}][id]`,
          img.id
        );
        apiPayload.append(
          `submittal_addl_assignee[response_images_attributes][${resImgsIndex}][_destroy]`,
          true
        );
        resImgsIndex++;
      });

    values.resDocsDelete
      .filter((doc) => doc.id)
      .forEach((doc) => {
        apiPayload.append(
          `submittal_addl_assignee[response_documents_attributes][${resDocsIndex}][id]`,
          doc.id
        );
        apiPayload.append(
          `submittal_addl_assignee[response_documents_attributes][${resDocsIndex}][_destroy]`,
          true
        );
        resDocsIndex++;
      });

    return apiPayload;
  }
}

export const submittalAddlAssigneesApi = new SubmittalAddlAssigneesApi();
