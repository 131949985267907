import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { projectOpenItemsRequest } from 'components/admin/project-open-items/store/actions';
import ProjectOpenItemsTable from 'components/admin/project-open-items/ProjectOpenItemsTable';

class ProjectOpenItemsCritical extends Component {
  static propTypes = {
    filters: PropTypes.shape(),
  };

  static defaultProps = {
    filters: {},
  };

  componentDidMount() {
    this.handlePageChange(1);
  }

  handlePageChange = (page) => {
    const { filters, dispatch } = this.props;
    dispatch(
      projectOpenItemsRequest(page, {
        ...filters,
        unresolved: true,
        critical: true,
      })
    );
  };

  render() {
    return (
      <div className="project-open-items">
        <ProjectOpenItemsTable onPageChange={this.handlePageChange} />
      </div>
    );
  }
}

export default connect((state) => {
  return {};
})(ProjectOpenItemsCritical);
