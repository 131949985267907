import React from 'react';
import { connect } from 'react-redux';

import FavoriteIcon from 'components/shared/icons/FavoriteIcon';
import Button, { BUTTON_TYPES } from 'components/shared/button/Button';
import colors from 'styles/colors';

import { getDarkMode } from 'selectors/theme';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';

import {
  setProjectPhotosFavorites,
  toggleProjectPhotoFavorite,
} from './store/actions';

const ProjectPhotosFavorite = (props) => {
  const {
    id,
    favorite,
    type: t,
    dispatch,
    currentProject,
    darkMode,
    outlined,
    width,
    height,
  } = props;

  const toggleFavorite = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const type = t || 'current';

    dispatch(
      toggleProjectPhotoFavorite(currentProject.id, [{ id, type }], !favorite)
    ).then((response) => {
      if (response.ok && type === 'current') {
        dispatch(setProjectPhotosFavorites([id], !favorite, type));
      }
    });
  };

  const color = favorite
    ? darkMode
      ? colors.neon
      : colors['primary-green']
    : colors['dark-mode-light-white'];

  const strokeColor = outlined
    ? colors['light-mode-dark-black']
    : colors['white-text'];

  return (
    <Button
      onClick={toggleFavorite}
      type={BUTTON_TYPES.LINK}
      label={
        <FavoriteIcon
          stroke={favorite ? color : strokeColor}
          fill={color}
          outlined={outlined}
          width={width}
          height={height}
        />
      }
    />
  );
};

export default connect((state) => ({
  currentProject: getCurrentProject(state),
  darkMode: getDarkMode(state),
}))(ProjectPhotosFavorite);
