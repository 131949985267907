import React from 'react';
import { connect } from 'react-redux';

import ConfirmationModal from 'components/shared/modal/ConfirmationModal.js';

import { getShowArchiveConfirmationModal } from './store/selectors';
import { setShowArchiveConfirmation } from './store/actions';

const ArchiveProjectConfirmationModal = (props) => {
  const { dispatch, showDeleteConfirmation, handleArchiveProject } = props;

  const handleArchiveConfirmation = (ok) => {
    if (ok) handleArchiveProject();
    else dispatch(setShowArchiveConfirmation(false));
  };

  if (!showDeleteConfirmation) return null;

  return (
    <ConfirmationModal
      show={showDeleteConfirmation}
      message="Are you sure you want to archive this project?"
      onHide={handleArchiveConfirmation}
    />
  );
};

export default connect((state) => {
  return {
    showDeleteConfirmation: getShowArchiveConfirmationModal(state),
  };
})(ArchiveProjectConfirmationModal);
