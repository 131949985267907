import axios from 'axios';
import BaseApi from './base-api';

class PdfTronApi extends BaseApi {
  savePdf(data, documentId, fileName) {
    let formData = new FormData();
    formData.append('pdf_data', data, fileName);
    formData.append('document_id', documentId);

    return axios
      .put(`${this.baseUrl}/pdftron/save`, formData, {
        'Content-Type': 'multipart/form-data',
      })
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const pdfTronApi = new PdfTronApi();
