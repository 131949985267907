import React, { Component } from 'react';
import { connect } from 'react-redux';
import './NewProject.css';
import { getCurrentStage } from 'components/admin/projects/creation/store/selectors';
import {
  newProject,
  prevStage,
} from 'components/admin/projects/creation/store/actions';
import SubscriberCompanySelection from 'components/admin/projects/creation/SubscriberCompanySelection';
import ProjectDetails from 'components/admin/projects/creation/ProjectDetails';
import ClientSelection from 'components/admin/projects/creation/ClientSelection';
import ProjectLocationSelection from 'components/admin/projects/creation/ProjectLocationSelection';
import Success from 'components/admin/projects/creation/Success';
import { createProject } from 'components/admin/projects/creation/store/actions';
import { getProjectForm } from 'components/admin/projects/creation/store/selectors';
import { getDarkMode } from 'selectors/theme';
import DetailsTitle from 'components/shared/details/DetailsTitle';

class NewProject extends Component {
  constructor(props) {
    super(props);

    props.dispatch(newProject());
  }

  handleCreateProject = () => {
    this.props.dispatch(createProject(this.props.projectForm));
  };

  handlePrev = () => {
    this.props.dispatch(prevStage());
  };

  renderCurrentStage = () => {
    let stage;

    switch (this.props.currentStage) {
      case 'subscriber-selection':
        stage = <SubscriberCompanySelection />;
        break;

      case 'project-details':
        stage = <ProjectDetails backFunction={this.handlePrev} />;
        break;

      case 'client-selection':
        stage = <ClientSelection backFunction={this.handlePrev} />;
        break;

      case 'project-location-selection':
        stage = (
          <ProjectLocationSelection
            submitButtonLabel="Create Project"
            backFunction={this.handlePrev}
            submitFunction={this.handleCreateProject}
          />
        );
        break;

      case 'success':
        stage = <Success />;
        break;

      default:
        stage = null;
    }

    return stage;
  };

  render() {
    const { darkMode } = this.props;

    return (
      <div
        className={`project-form new-project ${darkMode ? 'dark-mode' : ''}`}
      >
        <DetailsTitle className="main-form-title" title="CREATE PROJECT" />
        <div className="content-container">{this.renderCurrentStage()}</div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentStage: getCurrentStage(state),
    projectForm: getProjectForm(state),
    darkMode: getDarkMode(state),
  };
})(NewProject);
