import React, { useState, useEffect } from 'react';
import Button, {
  BUTTON_COLORS,
  BUTTON_TYPES,
} from 'components/shared/button/Button';
import { Rnd } from 'react-rnd';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import { getMeetingsInstanceForm, getNotepadShow } from './store/selectors';
import { setFormMeetingInstance, setNotepadShow } from './store/actions';
import { getCurrentUser } from 'selectors/authentication';

const Notepad = (props) => {
  const { darkMode, show, dispatch, currentUser, instanceForm } = props;

  const [size, setSize] = useState({ width: 600, height: 400 });
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [text, setText] = useState('');
  useEffect(() => {
    setText(
      instanceForm &&
        instanceForm.meetingsInstancesNotes &&
        instanceForm.meetingsInstancesNotes.notes
    );
  }, []);
  const userId = currentUser.id;
  const handleClose = () => dispatch(setNotepadShow(false));
  /* t.text "notes"
    t.datetime "created_at"
    t.integer "created_by_id"
    t.integer "user_id"
    t.integer "meetings_parent_id"
    t.integer "meetings_instance_id" */
  const handleSave = () => {
    const { meetingsInstancesNotes } = instanceForm;
    let editedNotes = {
      ...meetingsInstancesNotes,
      notes: text,
      meetingsParentId: instanceForm.meetingsParentId,
    };
    if (!(meetingsInstancesNotes && meetingsInstancesNotes.createdAt)) {
      editedNotes = { ...editedNotes, createdById: userId, userId }; //In backend make instanceForm only get the notes for hte user
    }
    if (instanceForm.id)
      editedNotes = { ...editedNotes, meetingsInstanceId: instanceForm.id };
    if (!(meetingsInstancesNotes && meetingsInstancesNotes.id))
      editedNotes = { ...editedNotes, createdById: currentUser.id };
    dispatch(
      setFormMeetingInstance({
        ...instanceForm,
        meetingsInstancesNotes: editedNotes,
      })
    );
  };
  const handleSaveAndExit = () => {
    handleSave();
    handleClose();
  };
  const modalStyle = {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    padding: '0px',
    border: '1px solid black',
    height: '100%',
    backgroundColor: darkMode ? '#21242f' : 'white',
  };
  const dragHandleStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: darkMode ? '#3a3e4a' : '#f0f0f0',
    cursor: 'move',

    padding: '0 10px',
  };

  const titleStyle = {
    fontWeight: 600,
    color: darkMode ? 'white' : 'black',
  };
  const textAreaStyle = {
    flex: 1,
    resize: 'none',
    padding: '10px',
    border: 'none',
    outline: 'none',
    background: darkMode ? '#21242f' : 'white',
    color: darkMode ? 'white' : 'black',
  };

  return (
    show && (
      <Rnd
        size={{ width: size.width, height: size.height }}
        position={{ x: position.x, y: position.y }}
        onDragStop={(e, d) => setPosition({ x: d.x, y: d.y })}
        onResizeStop={(e, direction, ref, delta, position) => {
          setSize({ width: ref.offsetWidth, height: ref.offsetHeight });
          setPosition(position);
        }}
        bounds="window"
        dragHandleClassName="drag-handle"
        enableResizing={{
          top: true,
          right: true,
          bottom: true,
          left: true,
          topRight: true,
          bottomRight: true,
          bottomLeft: true,
          topLeft: true,
        }}
        minWidth={300}
        minHeight={200}
      >
        <div style={modalStyle} className="modal-style">
          {/* Drag Handle Area */}
          <div className="drag-handle" style={dragHandleStyle}>
            <div style={titleStyle}>Meeting Notes</div>
            <div style={{ gap: 30, display: 'flex', flexDirection: 'row' }}>
              <Button
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Save"
                onClick={() => handleSave()}
              />
              <Button
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Save&Exit"
                onClick={() => handleSaveAndExit()}
              />
              <Button
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Exit"
                onClick={() => handleClose()}
              />
            </div>
          </div>

          {/* Textarea */}
          <textarea
            style={textAreaStyle}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </div>
      </Rnd>
    )
  );
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
    show: getNotepadShow(state),
    currentUser: getCurrentUser(state),
    instanceForm: getMeetingsInstanceForm(state),
  };
})(Notepad);
