import React from 'react';
import { connect } from 'react-redux';

import FormModal from 'components/shared/modal/FormModal';

import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { MODAL_ICONS } from 'components/shared/modal/MessageModal';
import { getDarkMode } from 'selectors/theme';
import { setDeleteTypeOrTitleErrorModalShow } from './store/actions';
import { getDeleteTypeOrTitleErrorModal } from './store/selectors';
import './AttendanceForm.css';
import './MeetingModal.css';

const DeleteTypeOrTitleModal = (props) => {
  const { darkMode, dispatch, showDeleteTypeOrTitleErrorModal } = props;
  const handleClose = () => {
    dispatch(setDeleteTypeOrTitleErrorModalShow(false));
  };

  return (
    <FormModal
      className={`meeting-modal editing`}
      show={showDeleteTypeOrTitleErrorModal}
      onClose={handleClose}
      subTitle="Error"
    >
      <div className="messages-container">
        <img
          className="exclamation"
          src={
            darkMode
              ? MODAL_ICONS.EXCLAMATION.dark
              : MODAL_ICONS.EXCLAMATION.default
          }
          alt={MODAL_ICONS.EXCLAMATION.alt}
        />
        <div className="main-message">
          The name cannot be deleted as it is linked to published meeting
          minutes.
        </div>
      </div>
      <div className="button-actions"></div>
      <Button
        type={BUTTON_TYPES.CONFIRMATION}
        color={BUTTON_COLORS.GREEN}
        label={'Close'}
        onClick={handleClose}
      />
    </FormModal>
  );
};

export default connect((state) => ({
  showDeleteTypeOrTitleErrorModal: getDeleteTypeOrTitleErrorModal(state),
  project: getCurrentProject(state),
  darkMode: getDarkMode(state),
}))(DeleteTypeOrTitleModal);
