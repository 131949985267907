export const GENERAL_CONTRACTORS_LOAD_SUCCESS =
  'GENERAL_CONTRACTORS_LOAD_SUCCESS';
export const GENERAL_CONTRACTORS_PAGE_CHANGE =
  'GENERAL_CONTRACTORS_PAGE_CHANGE';
export const NEXT_STAGE = 'NEXT_STAGE';
export const LICENCE_NUMBER_VALUE_CHANGE = 'LICENCE_NUMBER_VALUE_CHANGE';
export const NEW_ONBOARDING = 'NEW_ONBOARDING';
export const COMPANY_LOGO_FILE_SET = 'COMPANY_LOGO_FILE_SET';
export const GENERAL_CONTRACTOR_LOAD_SUCCESS =
  'GENERAL_CONTRACTOR_LOAD_SUCCESS';
export const GENERAL_CONTRACTOR_ONBOARDING_STAGE_CHANGE =
  'GENERAL_CONTRACTOR_ONBOARDING_STAGE_CHANGE';
export const GENERAL_CONTRACTOR_PROJECTS_LOAD_SUCCESS =
  'GENERAL_CONTRACTOR_PROJECTS_LOAD_SUCCESS';
export const GENERAL_CONTRACTOR_PROJECTS_PAGE_CHANGE =
  'GENERAL_CONTRACTOR_PROJECTS_PAGE_CHANGE';
export const GENERAL_CONTRACTORS_CONTACTS_MODAL_SHOW =
  'GENERAL_CONTRACTORS_CONTACTS_MODAL_SHOW';
export const GENERAL_CONTRACTORS_USERS_SET = 'GENERAL_CONTRACTORS_USERS_SET';
export const GENERAL_CONTRACTORS_INVITE_FORM_CLEAR =
  'GENERAL_CONTRACTORS_INVITE_FORM_CLEAR';
export const GENERAL_CONTRACTORS_INVITE_USER_SHOW =
  'GENERAL_CONTRACTORS_INVITE_USER_SHOW';
export const GENERAL_CONTRACTORS_ROLES_LOAD_SUCCESS =
  'GENERAL_CONTRACTORS_ROLES_LOAD_SUCCESS';
export const GENERAL_CONTRACTORS_USER_ADD = 'GENERAL_CONTRACTORS_USER_ADD';
export const GENERAL_CONTRACTORS_NEW_FORM_VALID =
  'GENERAL_CONTRACTORS_NEW_FORM_VALID';
export const GENERAL_CONTRACTORS_NEW_FORM_INVALID =
  'GENERAL_CONTRACTORS_NEW_FORM_INVALID';
export const GENERAL_CONTRACTORS_NEW_FORM_VALUE_CHANGE =
  'GENERAL_CONTRACTORS_NEW_FORM_VALUE_CHANGE';
export const GENERAL_CONTRACTORS_NEW_PARAMETERS_LOAD_SUCCESS =
  'GENERAL_CONTRACTORS_NEW_PARAMETERS_LOAD_SUCCESS';
