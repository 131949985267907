import axios from 'axios';
import numeral from 'numeral';

import BaseApi from './base-api';

class FieldWorkOrdersApi extends BaseApi {
  getFieldWorkOrders(projectId, currentPage, gcStage) {
    const gcFilters = gcStage ? '&gc_stage=true' : '';
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/field_work_orders?page=${currentPage}${gcFilters}`
      )
      .then((response) => {
        return {
          ok: true,
          fieldWorkOrders: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getFieldWorkOrdersAll(page, filters) {
    let query = `page=${page}`;
    Object.keys(filters).forEach((filter) => {
      query += `&${filter}=${filters[filter]}`;
    });

    return axios
      .get(`${this.baseUrl}/field_work_orders?${query}`)
      .then((response) => {
        return {
          ok: true,
          fieldWorkOrders: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getFieldWorkOrdersAllCount(filters) {
    let query = '';
    Object.keys(filters).forEach((filter, index) => {
      if (index > 0) {
        query += '&';
      }
      query += `${filter}=${filters[filter]}`;
    });

    return axios
      .get(`${this.baseUrl}/field_work_orders/count?${query}`)
      .then((response) => {
        return {
          ok: true,
          count: numeral(response.headers['total']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getFieldWorkOrder(projectId, id) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/field_work_orders/${id}`)
      .then((response) => {
        return {
          ok: true,
          fieldWorkOrder: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateFieldWorkOrder(projectId, fieldWorkOrderId, fieldWorkOrderData) {
    const data = this.fieldWorkOrderParams(fieldWorkOrderData);

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/field_work_orders/${fieldWorkOrderId}`,
        data
      )
      .then((response) => {
        return {
          ok: true,
          fieldWorkOrder: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  fieldWorkOrderParams(fieldWorkOrderData) {
    const status = fieldWorkOrderData.fwoStatus.value;
    let data = {
      field_work_order: {
        number: fieldWorkOrderData.fwoNumber,
        preset_task_ids:
          fieldWorkOrderData.fwoPresetTasks.length > 0
            ? fieldWorkOrderData.fwoPresetTasks.map((task) => task[0])
            : [],
        preset_task_other: fieldWorkOrderData.fwoCustomTask,
        work_performed: fieldWorkOrderData.fwoWorkPerformed,
        comments: fieldWorkOrderData.fwoComments,
        start_date: fieldWorkOrderData.fwoStartDate,
        completion_date: fieldWorkOrderData.fwoCompletionDate,
        labor: fieldWorkOrderData.fwoLabor,
        materials: fieldWorkOrderData.fwoMaterials,
        cost_impact: fieldWorkOrderData.fwoCostImpact,
        cost: fieldWorkOrderData.fwoCostImpact
          ? fieldWorkOrderData.fwoCost
          : '',
        delay: fieldWorkOrderData.fwoDelay,
        delay_reason: fieldWorkOrderData.fwoDelay
          ? fieldWorkOrderData.fwoDelayReason
          : '',
        denied_reason: '',
      },
    };

    if (status === 'approved') {
      data = {
        field_work_order: {
          ...data.field_work_order,
          approved: true,
        },
      };
    }

    if (status === 'denied') {
      data = {
        field_work_order: {
          ...data.field_work_order,
          denied: true,
          denied_reason: fieldWorkOrderData.fwoDeniedReason,
        },
      };
    }

    return data;
  }
}

export const fieldWorkOrdersApi = new FieldWorkOrdersApi();
