import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Grid } from '@mui/material';
import FormModal from 'components/shared/modal/FormModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getProjectPhotosCurrentAlbum,
  getProjectPhotosShowUpload,
  getProjectPhotosStartUpload,
} from './store/selectors';
import {
  setProjectPhotosShowUpload,
  setProjectPhotosStartUpload,
} from './store/actions';
import ProjectPhotosUpload from './ProjectPhotosUpload';

const ProjectPhotosUploadModal = (props) => {
  const { dispatch, show, currentAlbum, startUpload } = props;
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (show) setCount(0);
  }, [show]);

  const onClose = () => {
    dispatch(setProjectPhotosShowUpload(false));
    dispatch(setProjectPhotosStartUpload(false));
  };

  const onSave = () => {
    dispatch(setProjectPhotosStartUpload(true));
  };

  if (!currentAlbum) return null;

  /* drag & drop files to upload on current album */
  return (
    <FormModal
      id="project-photos-upload-modal"
      className="project-photos-upload"
      show={show}
      onClose={onClose}
      subTitle="Upload Photos & Videos"
    >
      <Grid
        container
        direction={'column'}
        spacing={3}
        paddingY={2}
        paddingX={5}
      >
        <Grid item>
          <ProjectPhotosUpload
            onClose={onClose}
            onChange={setCount}
            albumId={currentAlbum.id}
          />
        </Grid>
        {!startUpload && (
          <Grid item>
            <Grid container spacing={1} justifyContent={'center'}>
              <Grid item>
                <Button
                  type={BUTTON_TYPES.CONFIRMATION}
                  color={BUTTON_COLORS.WHITE}
                  label="Cancel"
                  onClick={onClose}
                />
              </Grid>
              <Grid item>
                <Button
                  type={BUTTON_TYPES.CONFIRMATION}
                  color={BUTTON_COLORS.GREEN}
                  label={'Save'}
                  onClick={onSave}
                  disabled={count === 0}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </FormModal>
  );
};

export default connect((state) => ({
  show: getProjectPhotosShowUpload(state),
  currentProject: getCurrentProject(state),
  currentAlbum: getProjectPhotosCurrentAlbum(state),
  startUpload: getProjectPhotosStartUpload(state),
}))(ProjectPhotosUploadModal);
