import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import FormModal from 'components/shared/modal/FormModal';
import SimpleTable from 'components/shared/table/SimpleTable';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import SearchBar from 'components/shared/navigation/SearchBar';
import FilterHeader from 'components/shared/table/FilterHeader';

import { getCurrentUser } from 'selectors/authentication';

import {
  setActionItems,
  setActionItemsResolvedModal,
  setFormMeetingInstance,
} from './store/actions';
import {
  getActionItems,
  getActionItemsResolvedModalShow,
  getMeetingsInstanceForm,
} from './store/selectors';
import './ActionItemsResolvedModal.css';
import { upperCasetoCamelCase } from '../../../../../services/utils/case-utils';

const ActionItemsResolvedModal = (props) => {
  const { dispatch, show, actionItems, instanceForm } = props;
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({});
  const [dateTo, setDateTo] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [originalOrder, setOriginalOrder] = useState([]);

  useEffect(() => {
    setOriginalOrder([...actionItems]);
  }, []);

  useEffect(() => {
    if (!dateFrom) return;
    let sortedItems = [...actionItems];
    sortedItems = sortedItems.filter((a) => {
      return a.due_date
        ? moment(a.due_date.split('T')[0]).isAfter(moment(dateFrom), 'day')
        : false;
    });
    dispatch(setActionItems(sortedItems));
  }, [dateFrom]);
  useEffect(() => {
    if (!dateTo) return;
    let sortedItems = [...actionItems];
    sortedItems = sortedItems.filter((a) => {
      return a.due_date
        ? moment(a.due_date.split('T')[0]).isBefore(moment(dateTo), 'day')
        : false;
    });
    dispatch(setActionItems(sortedItems));
  }, [dateTo]);
  const handleClose = () => {
    dispatch(setActionItems(originalOrder));
    dispatch(setActionItemsResolvedModal(false));
  };

  const handleRestore = (actionItem) => {
    const restoredItem = { ...actionItem, isResolved: false };
    const editedItems = actionItems.map((ai) =>
      actionItem.id === ai.id ? restoredItem : ai
    );
    dispatch(setActionItems(editedItems));
    dispatch(
      setFormMeetingInstance({
        ...instanceForm,
        meetingsActionItems: editedItems,
      })
    );
  };

  const parseDate = (date) => {
    const d = moment(date);
    return d.isValid() ? d.utc().format('MM/DD/YYYY') : '--';
  };
  const actionItemsHeaders = [
    'ITEM NO',
    'ITEM DATE',
    'DESCRIPTION',
    'CATEGORY',
    'RESPONSIBLE',
    'DUE DATE',
    'PRIORITY',
    'RESOLVED BY',
    'RESOLVED ON',
    'ACTIONS',
  ];

  const handleDateChangeFrom = (option) => {
    handleFilterChange(option, 'FROM');
  };
  const handleDateChangeTo = (option) => {
    handleFilterChange(option, 'TO');
  };
  const handleDateValueChangeTo = (value) => {
    setDateTo(value);
    handleFilterChange('to', 'TO');
  };
  const handleDateValueChangeFrom = (value) => {
    setDateFrom(value);
    handleFilterChange('from', 'FROM');
  };
  const textFilterOptions = [
    { id: 'clear_filter', label: 'Clear Filter' },
    { id: 'ascending', label: 'Ascending' },
    { id: 'descending', label: 'Descending' },
  ];
  const dateFilterOptions = [
    { id: 'clear_filter', label: 'Clear Filter' },
    { id: 'newest', label: 'Newest' },
    { id: 'oldest', label: 'Oldest' },
    {
      id: 'range',
      label: 'Range',
      subitems: [
        {
          id: 'from',
          label: 'From',
          isDate: true,
          onChange: handleDateChangeFrom,
          onDateChange: handleDateValueChangeFrom,
          dateValue: dateFrom,
        },
        {
          id: 'to',
          label: 'To',
          isDate: true,
          onChange: handleDateChangeTo,
          onDateChange: handleDateValueChangeTo,
          dateValue: dateTo,
        },
      ],
    },
  ];

  const handleFilterChange = (option, name) => {
    const key = upperCasetoCamelCase(name);
    let sortedItems = [...actionItems];
    setFilters({ ...filters, [name]: option });
    if (option === 'ascending' || option === 'newest') {
      sortedItems.sort((a, b) => (a[key] > b[key] ? -1 : 1));
      dispatch(setActionItems(sortedItems));
    } else if (option === 'descending' || option === 'oldest') {
      sortedItems.sort((a, b) => (a[key] < b[key] ? -1 : 1));
      dispatch(setActionItems(sortedItems));
    }
    if (option === 'clear_filter' || !option)
      dispatch(setActionItems(originalOrder));
  };

  const actionItemsFilters = (headers) => {
    return headers.map((f) => {
      const isSearchable =
        f === 'CATEGORY' ||
        f === 'RESPONSIBLE' ||
        f === 'DESCRIPTION' ||
        f === 'ITEM_NO' ||
        f === 'PRIORITY';
      if (f === 'ACTIONS') return f;
      return (
        <FilterHeader
          clearable
          search={isSearchable}
          label={f}
          options={isSearchable ? textFilterOptions : dateFilterOptions}
          className="simple-header"
          onFilter={(option) => handleFilterChange(option, f)}
          selected={filters[f]}
        />
      );
    });
  };

  const parseResponsible = (row) => {
    if (!row.responsible && row.responsibleCompany)
      return row.responsibleCompany.name;
    if (!row.responsible && row.responsible_company)
      return row.responsible_company.name;

    if (row.responsible && row.responsible.fullName)
      return row.responsible.fullName;
    if (row.responsible && row.responsible.full_name)
      return row.responsible.full_name;

    return '--';
  };
  const parseResolvedBy = (row) => {
    if (row.resolvedBy) return row.resolvedBy.fullName;
    if (row.resolved_by) return row.resolved_by.full_name;
    return '--';
  };
  const getRow = (row) => {
    return [
      row.item_number || row.itemNumber,
      row.createdOn ? parseDate(row.createdOn) : parseDate(row.created_on),
      row.description,
      row.category,
      parseResponsible(row),
      row.dueDate ? parseDate(row.dueDate) : parseDate(row.due_date),
      row.priority,
      parseResolvedBy(row),
      row.resolvedOn ? parseDate(row.resolvedOn) : parseDate(row.resolved_on),
      <Button
        type={BUTTON_TYPES.LINK}
        color={BUTTON_COLORS.GREEN}
        label={'Restore'}
        onClick={() => handleRestore(row)}
      />,
    ];
  };
  const getRows = () =>
    actionItems
      .filter((ai) => {
        return (
          (ai.isResolved || ai.is_resolved) &&
          (!search ||
            ai.description
              .toLocaleLowerCase()
              .includes(search.toLocaleLowerCase()))
        );
      })
      .map((r, i) => getRow(r, i));
  if (!show) return null;

  const titleComponent = (
    <SearchBar
      title="Resolved Items"
      searchText={search}
      onSearchTextChange={setSearch}
      onSearch={setSearch}
    />
  );
  return (
    <FormModal
      className={`action-items-resolved-modal`}
      show={show}
      onClose={handleClose}
      subTitle={'Meeting Minutes'}
      title={titleComponent}
    >
      <SimpleTable
        className="action-item-resolved-table"
        emptyMessage={`No Resolved Items`}
        headers={actionItemsFilters(actionItemsHeaders)}
        actionCells={1}
        rows={getRows()}
        stickyHeader={true}
      />
    </FormModal>
  );
};

export default connect((state) => ({
  show: getActionItemsResolvedModalShow(state),
  currentUser: getCurrentUser(state),
  actionItems: getActionItems(state),
  instanceForm: getMeetingsInstanceForm(state),
}))(ActionItemsResolvedModal);
