export const SUBMITTAL_LOG_INIT = 'SUBMITTAL_LOG_INIT';
export const SUBMITTALS_SET = 'SUBMITTALS_SET';
export const SUBMITTAL_TAB_SELECTED_SET = 'SUBMITTAL_TAB_SELECTED_SET';
export const SUBMITTAL_MODAL_SHOW_SET = 'SUBMITTAL_MODAL_SHOW_SET';
export const SUBMITTAL_MODAL_INIT = 'SUBMITTAL_MODAL_INIT';
export const SUBMITTAL_FORM_VALUE_UPDATE = 'SUBMITTAL_FORM_VALUE_UPDATE';
export const SUBMITTAL_FILE_DELETE = 'SUBMITTAL_FILE_DELETE';
export const SUBMITTAL_READ_ONLY_SET = 'SUBMITTAL_READ_ONLY_SET';
export const SUBMITTAL_LEAD_TIME_READ_ONLY_SET =
  'SUBMITTAL_LEAD_TIME_READ_ONLY_SET';
export const SHOW_TRANSMITTAL_PDF_SET = 'SHOW_TRANSMITTAL_PDF_SET';
export const TRANSMITTAL_PDF_SET = 'TRANSMITTAL_PDF_SET';
export const SUBMITTAL_FORM_SET = 'SUBMITTAL_FORM_SET';
export const SUBMITTAL_FORM_ERRORS_SET = 'SUBMITTAL_FORM_ERRORS_SET';
export const CURRENT_SUBMITTAL_SET = 'CURRENT_SUBMITTAL_SET';
export const SUBMITTAL_UPDATE = 'SUBMITTAL_UPDATE';
export const SUBMITTAL_NOTES_SET = 'SUBMITTAL_NOTES_SET';
export const SUBMITTAL_NOTES_ADD = 'SUBMITTAL_NOTES_ADD';
export const SUBMITTAL_GC_ACCELERATION_SET = 'SUBMITTAL_GC_ACCELERATION_SET';
export const SUBMITTAL_LOG_PDF_VIEWER_SET = 'SUBMITTAL_LOG_PDF_VIEWER_SET';
export const SUBMITTAL_LOG_PDF_SET = 'SUBMITTAL_LOG_PDF_SET';
export const SUBMITTAL_PDF_VIEWER_SET = 'SUBMITTAL_PDF_VIEWER_SET';
export const SUBMITTAL_SHOW_ERROR_MESSAGE_SET =
  'SUBMITTAL_SHOW_ERROR_MESSAGE_SET';
export const SUBMITTAL_ADDL_ASSIGNEES_ADD = 'SUBMITTAL_ADDL_ASSIGNEES_ADD';
export const SUBMITTAL_ADDL_ASSIGNEES_UPDATE =
  'SUBMITTAL_ADDL_ASSIGNEES_UPDATE';
export const SUBMITTAL_ADDL_ASSIGNEES_DELETE =
  'SUBMITTAL_ADDL_ASSIGNEES_DELETE';
export const SUBMITTAL_ADDL_ASSIGNEE_RESPONSE =
  'SUBMITTAL_ADDL_ASSIGNEE_RESPONSE';
export const SUBMITTALS_DELETE = 'SUBMITTALS_DELETE';
export const SUBMITTAL_CREATED_REVISION_MODAL_SHOW_SET =
  'SUBMITTAL_CREATED_REVISION_MODAL_SHOW_SET';
export const REVISION_SUBMITTAL_SET = 'REVISION_SUBMITTAL_SET';
export const SUBMITTALS_RESPONSE_MODAL_SET = 'SUBMITTALS_RESPONSE_MODAL_SET';
export const SUBMITTALS_RESPONSES_VIEW_MODAL_SET =
  'SUBMITTALS_RESPONSES_VIEW_MODAL_SET';
