import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Submittals.css';
import NavigationBar from 'components/shared/navigation/NavigationBar';
import { getCurrentUser } from 'selectors/authentication';
import { submittalsRequest } from 'components/admin/submittals/store/actions';
import SubmittalsTable from 'components/admin/submittals/SubmittalsTable';
import User from 'domain/user';

class Submittals extends Component {
  static propTypes = {
    filters: PropTypes.shape(),
  };

  static defaultProps = {
    filters: {},
  };

  constructor(props) {
    super(props);

    this.isSubscriber = User.isAdminOrSubscriber(props.currentUser);
    this.state = { submittalsTab: 2 };
  }

  componentDidMount() {
    this.handlePageChange(1, this.defaultStatus());
  }

  handlePageChange = (page, status) => {
    const { filters, dispatch } = this.props;
    dispatch(submittalsRequest(page, status, { ...filters, [status]: true }));
  };

  defaultStatus = (submittalsTab = 2) => {
    return (
      (this.isSubscriber && (submittalsTab === 1 ? 'pre' : 'not_pre')) || 'all'
    );
  };

  handleSubmittalsTabSelection = (submittalsTab) => {
    this.handlePageChange(1, this.defaultStatus(submittalsTab));
    this.setState({ submittalsTab: submittalsTab });
  };

  render() {
    const { submittalsTab } = this.state;
    return (
      <div className="submittals">
        <div className="submittals-header">
          <NavigationBar
            items={[
              {
                id: 1,
                title: 'P-Submittal Log',
                show: this.isSubscriber,
              },
              {
                id: 2,
                title: 'Submittal Log',
              },
            ]}
            selectedValue={submittalsTab}
            onSelected={this.handleSubmittalsTabSelection}
          />
        </div>
        <SubmittalsTable onPageChange={this.handlePageChange} />
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
  };
})(Submittals);
