import React from 'react';
import { connect } from 'react-redux';

import PdfViewer from 'components/shared/pdf-viewer/PdfViewer';

import {
  getProjectSchedulesPdfModal,
  getProjectSchedulesPdfTypeModal,
} from './store/selectors';
import { setModalPdfProjectSchedule } from './store/actions';

const ProjectSchedulePdfModal = (props) => {
  const { dispatch, pdf: source, pdfType: type } = props;
  const handleClose = () => dispatch(setModalPdfProjectSchedule(null));

  if (!source) return null;

  return (
    <PdfViewer show={true} onClose={handleClose} content={{ source, type }} />
  );
};

export default connect((state) => ({
  pdf: getProjectSchedulesPdfModal(state),
  pdfType: getProjectSchedulesPdfTypeModal(state),
}))(ProjectSchedulePdfModal);
