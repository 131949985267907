import * as ACTIONS from '../actions/pdfViewerBluebeam';

const initialState = {
  createdBluebeamSession: false,
};

export default function pdfViewerBluebeamReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.CREATED_BB_SESSION:
      return {
        ...state,
        createdBluebeamSession: true,
      };

    case ACTIONS.CREATED_BB_SNAPSHOT:
      return {
        ...state,
        createdBluebeamSession: false,
      };

    default:
      return state;
  }
}
