import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './RfiLogTable.css';
import SimpleTable from 'components/shared/table/SimpleTable';
import FilterHeader from 'components/shared/table/FilterHeader';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import Date from 'components/shared/text/Date';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import BaseStatus from 'components/shared/text/BaseStatus';
import { RfiStatusEnum } from 'domain/rfi-status-enum';
import { formatNumber } from 'services/utils/text-util';
import Rate from 'components/shared/rate/Rate';
import RfiPermissions from 'permissions/rfi-permissions';
import Rfi from 'domain/rfi';

class RfiLogTable extends Component {
  static propTypes = {
    rfis: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    total: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    currentStatus: PropTypes.string.isRequired,
    pageSize: PropTypes.number.isRequired,
    currentUser: PropTypes.shape({}).isRequired,
    currentProject: PropTypes.shape({}).isRequired,
    directory: PropTypes.shape({}).isRequired,
    contribType: PropTypes.string.isRequired,
    unreadById: PropTypes.shape({}),
    rfiTabSelected: PropTypes.number.isRequired,
    handlePageChange: PropTypes.func.isRequired,
    handleOpenRfi: PropTypes.func.isRequired,
    handleOpenRfiResponseModal: PropTypes.func.isRequired,
  };

  onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  uniqAssigneeIds = (rfi) => {
    return rfi.rfi_questions
      .map((qus) => [
        qus.assignee_id,
        ...qus.rfi_addl_assignees.map(
          (addlAssignee) => addlAssignee.assignee_id
        ),
      ])
      .flat()
      .filter((assigneeId) => !!assigneeId)
      .filter(this.onlyUnique);
  };

  questionsForAssigneeId = (rfi, assigneeId) => {
    return rfi.rfi_questions
      .map((question) => {
        const addlAssignee = question.rfi_addl_assignees.find(
          (addlAssignee) => addlAssignee.assignee_id === assigneeId
        );
        if (question.assignee_id === assigneeId) {
          return question;
        } else if (addlAssignee) {
          return { ...question, ...addlAssignee };
        }

        return null;
      })
      .filter((question) => !!question);
  };

  responsesForQuestions = (questions) => {
    return questions
      .filter((qus) => !!qus.response)
      .map((qus, index) => (
        <p key={index}>
          {qus.number}. {qus.response}
        </p>
      ));
  };

  handleGenerateLink = (id) => {
    return `?rfiId=${id}`;
  };

  renderAssigneeTooltip = (label, response) => {
    return (
      <React.Fragment>
        {label}
        {response && <div>{response}</div>}
      </React.Fragment>
    );
  };

  onRowSelected = (id) => {
    const { handleOpenRfi } = this.props;
    handleOpenRfi(id, false);
  };

  renderStatus = (rfi) => {
    const uniqAssigneeIds = this.uniqAssigneeIds(rfi);
    const rfiStatusEnum = RfiStatusEnum.create(rfi.status);
    const rfiStatusLabel = rfiStatusEnum.label;

    let titleTooltip = null;
    let totalItems = uniqAssigneeIds.length;
    let currentItems = 0;
    let itemTooltips = [];

    if (uniqAssigneeIds.length > 0) {
      itemTooltips = uniqAssigneeIds.map((assigneeId) => {
        const questions = this.questionsForAssigneeId(rfi, assigneeId);
        const responses = this.responsesForQuestions(questions);
        const hasPending = questions.find(
          (qus) => !qus.response || qus.draft_response
        );

        if (!hasPending) {
          currentItems++;
        }

        return this.renderAssigneeTooltip(
          questions[0].assignee_label,
          !hasPending ? responses : ''
        );
      });

      if (uniqAssigneeIds.length === 1) {
        titleTooltip = itemTooltips[0];
        totalItems = 0;
        currentItems = 0;
        itemTooltips = [];
      }
    }

    return (
      <Rate
        className="submittalStep"
        title={rfiStatusLabel}
        titleTooltip={titleTooltip}
        totalItems={totalItems}
        currentItems={currentItems}
        itemTooltips={itemTooltips}
      />
    );
  };

  mapRfis = () => {
    const { currentUser, currentProject, directory } = this.props;

    return this.props.rfis.map((rfi) => {
      const {
        id,
        number,
        status,
        csi_code,
        title,
        received_at,
        submitted_at,
        resolved_at,
        due_date,
        extended_due_date,
      } = rfi;

      const rfiStatusEnum = RfiStatusEnum.create(status);
      const draftRfi = RfiStatusEnum.DRAFT.equals(rfiStatusEnum);
      const unread = this.props.unreadById && !!this.props.unreadById[id];
      const assigned =
        Rfi.isAssignedToUser(currentUser, rfi) ||
        Rfi.isAddlAssignedToUser(currentUser, rfi);
      const responded = Rfi.isRespondedByUser(currentUser, rfi);
      const canView = RfiPermissions.canView(currentUser, currentProject, rfi);
      const canEdit = RfiPermissions.canEdit(
        currentUser,
        currentProject,
        directory,
        rfi
      );

      return {
        id: rfi.id,
        selectable: true,
        data: [
          <div>
            {unread && (
              <BaseStatus
                labels={[
                  {
                    id: 'unread',
                    label: '',
                  },
                ]}
                status="unread"
              />
            )}
            {formatNumber(number)}
          </div>,
          csi_code.division,
          title || '-',
          received_at ? (
            <Date value={rfi.received_at} format="MM/DD/YYYY" />
          ) : (
            '-'
          ),
          submitted_at ? (
            <Date value={rfi.submitted_at} format="MM/DD/YYYY" />
          ) : (
            '-'
          ),
          due_date ? (
            <Date
              value={rfi.extended_due_date ? extended_due_date : due_date}
              format="MM/DD/YYYY"
            />
          ) : (
            '-'
          ),
          resolved_at ? <Date value={resolved_at} format="MM/DD/YYYY" /> : '-',
          <div className={draftRfi ? 'draft' : ''}>
            {this.renderStatus(rfi)}
          </div>,
          <div className="action-buttons">
            {canView && (
              <Button
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="View"
                onClick={() => this.props.handleOpenRfi(id, true)}
              />
            )}
            {canEdit && (
              <Button
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label={assigned && !responded ? 'Respond' : 'Edit'}
                onClick={() =>
                  assigned && !responded
                    ? this.props.handleOpenRfiResponseModal(id, false)
                    : this.props.handleOpenRfi(id, false)
                }
              />
            )}
          </div>,
        ],
      };
    });
  };

  render() {
    const {
      currentUser,
      currentProject,
      currentPage,
      currentStatus,
      pageSize,
      total,
      rfiTabSelected,
      handlePageChange,
      dueDateFilter = 'all',
    } = this.props;

    const canSeePending = RfiPermissions.canViewPending(
      currentUser,
      currentProject
    );
    const canSeeSubmitted = RfiPermissions.canViewSubmitted(
      currentUser,
      currentProject
    );
    const canSeeDraft = RfiPermissions.canViewDraft(
      currentUser,
      currentProject
    );

    return (
      <div className="rfi-log-table">
        <SimpleTable
          className="projects-table"
          headers={[
            'RFI NO',
            'COST CODE',
            'TITLE',
            'RECEIVED',
            'SUBMITTED',
            <FilterHeader
              label="DUE"
              options={[
                {
                  id: 'all',
                  label: 'All',
                },
                {
                  id: 'pending',
                  label: 'Pending',
                },
                {
                  id: 'overdue',
                  label: 'Overdue',
                },
                {
                  id: 'ascending',
                  label: 'Ascending',
                },
                {
                  id: 'descending',
                  label: 'Descending',
                },
              ].filter((st) => !!st)}
              selected={dueDateFilter}
              onFilter={(filter) => {
                handlePageChange(1, currentStatus, filter);
              }}
            />,
            'RESOLVED',
            rfiTabSelected === 1 ? (
              'STATUS'
            ) : (
              <FilterHeader
                label="STATUS"
                options={[
                  canSeePending && canSeeSubmitted
                    ? {
                        id: 'not_pre',
                        label: 'All',
                      }
                    : {
                        id: 'all',
                        label: 'All',
                      },
                  canSeeDraft && {
                    id: 'draft',
                    label: 'Draft',
                  },
                  canSeePending &&
                    !canSeeSubmitted && {
                      id: 'pending',
                      label: 'Pending',
                    },
                  {
                    id: 'assigned',
                    label: 'Assigned',
                  },
                  {
                    id: 'responded',
                    label: 'Responded',
                  },
                  {
                    id: 'resolved',
                    label: 'Resolved',
                  },
                  {
                    id: 'for_record',
                    label: 'For Record Only',
                  },
                  canSeePending && {
                    id: 'void',
                    label: 'Void',
                  },
                ].filter((st) => !!st)}
                selected={currentStatus}
                onFilter={(status) => {
                  handlePageChange(1, status, dueDateFilter);
                }}
              />
            ),
            'ACTIONS',
          ]}
          rows={this.mapRfis()}
          onRowSelected={this.onRowSelected}
          emptyMessage="No RFIs"
          onGenerateLink={this.handleGenerateLink}
        />
        <div className="pagination-container">
          <SimplePagination
            currentPage={currentPage}
            pageSize={pageSize}
            total={total}
            onPageChange={(page) => {
              handlePageChange(page, currentStatus);
            }}
          />
        </div>
      </div>
    );
  }
}

export default connect()(RfiLogTable);
