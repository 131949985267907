import {
  WORK_ORDERS_CREATION_CLIENTS_CLIENT_SET,
  WORK_ORDERS_WO_ADD,
} from '../../../../actions/admin/work-orders/action-types';

const initialState = {
  selectedClientId: null,
};

export default function clientsUiReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_WO_ADD:
      return {
        ...state,
        selectedClientId: null,
      };

    case WORK_ORDERS_CREATION_CLIENTS_CLIENT_SET:
      return {
        ...state,
        selectedClientId: action.payload,
      };

    default:
      return state;
  }
}
