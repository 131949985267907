import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import Input from 'components/shared/input/Input';
import { Field, reduxForm } from 'redux-form';
import 'components/admin/projects/details/project-directory/NewCompanyForm.css';
import Selector from 'components/shared/selector/Selector';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import FormImageUpload from 'components/shared/form/FormImageUpload';
import { imageExtensions } from 'services/utils/files-util';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import { companyTitleOptions } from 'domain/user-role-enum';

class NewCompanyForm extends Component {
  renderInput = (props) => {
    return (
      <Input
        valid={!props.meta.error || !props.meta.submitFailed}
        value={props.input.value}
        onChange={(value) => props.input.onChange(value)}
        {...props}
      />
    );
  };

  renderDropDown = (props) => {
    return (
      <Selector
        valid={!props.meta.error || !props.meta.submitFailed}
        value={props.input.value}
        onChange={(option) => props.input.onChange(option.value)}
        {...props}
      />
    );
  };

  renderTypeDropDown = (props) => {
    return (
      <Selector
        valid={!props.meta.error || !props.meta.submitFailed}
        value={props.input.value}
        onChange={(option) =>
          this.props.handleNewContributorTypeChange(props, option.value)
        }
        {...props}
      />
    );
  };

  renderCheckBox = (props) => {
    return (
      <LabeledCheckBox
        checked={!!props.input.value}
        onChange={(value) => props.input.onChange(value)}
        {...props}
      />
    );
  };

  render() {
    return (
      <div className="new-company-form">
        <Form horizontal onSubmit={this.props.handleSubmit}>
          <div className="form-block">
            <label className="form-block-label">Company Name</label>
            <Field
              component={this.renderInput}
              name="name"
              placeholder="Company Name"
            />
          </div>
          <div className="form-block">
            <label className="form-block-label">Company Logo</label>
            <Field
              component={FormImageUpload}
              name="logo"
              extensions={imageExtensions}
            />
          </div>
          <div className="form-block">
            <label className="form-block-label">First Name</label>
            <Field
              component={this.renderInput}
              name="firstName"
              placeholder="First Name"
            />
          </div>
          <div className="form-block">
            <label className="form-block-label">Last Name</label>
            <Field
              component={this.renderInput}
              name="lastName"
              placeholder="Last Name"
            />
          </div>
          <div className="form-block">
            <label className="form-block-label">Email</label>
            <Field
              component={this.renderInput}
              name="email"
              placeholder="Email"
            />
          </div>
          <div className="form-block">
            <label className="form-block-label">Phone Number</label>
            <Field
              component={this.renderInput}
              name="phoneNumber"
              placeholder="Phone Number"
            />
            <Field
              component={this.renderInput}
              name="phoneExt"
              placeholder="ext"
            />
          </div>
          <div className="form-block">
            <label className="form-block-label">Office</label>
            <Field
              component={this.renderInput}
              name="officePhoneNumber"
              placeholder="Office Phone Number"
            />
            <Field
              component={this.renderInput}
              name="officeFaxNumber"
              placeholder="Office Fax Number"
            />
          </div>
          <div className="form-block">
            <label className="form-block-label">Title</label>
            <Field
              component={this.renderDropDown}
              name="title"
              placeholder="Select Title"
              options={companyTitleOptions}
            />
            <Field
              component={this.renderInput}
              name="title"
              placeholder="Or Type in"
            />
          </div>
          <div className="form-block">
            <label className="form-block-label">Contributor Type</label>
            <Field
              component={this.renderTypeDropDown}
              name="contributorCompanyType"
              placeholder="Select Type"
              options={this.props.types}
            />
          </div>
          <div className="form-block">
            <label className="form-block-label">Cost Code</label>
            <Field
              component={this.renderDropDown}
              name="csiCodeId"
              placeholder="Select Code"
              options={this.props.csiCodes
                .filter(
                  (csiCode) =>
                    this.props.newContributorType &&
                    csiCode.contributor_type === this.props.newContributorType
                )
                .map((code) => {
                  return {
                    value: code.id,
                    label: `${code.division} - ${code.label}`,
                  };
                })}
            />
          </div>
          <div className="form-block">
            <Field
              component={this.renderCheckBox}
              name="sendNotifications"
              label={
                <div className="send-notifications-checkbox-label">
                  <div className="small-regular">Notifications*</div>
                  <div className="small-bold">
                    Contacts will receive notifications for changes to the
                    project.
                  </div>
                </div>
              }
            />
          </div>
          {this.props.invalid && this.props.submitFailed && (
            <div className="invalid-message">
              The outlined inputs should be added in order to add a Company.
            </div>
          )}
          <div className="buttons-container">
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Cancel"
              onClick={this.props.onClose}
            />
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Add"
              onClick={this.props.handleSubmit}
              disabled={this.props.invalid}
            />
          </div>
        </Form>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  if (!values.contributorCompanyType) {
    errors.contributorCompanyType = 'Required';
  }

  if (!values.name) {
    errors.name = 'Required';
  }

  if (!values.firstName) {
    errors.firstName = 'Required';
  }

  if (!values.lastName) {
    errors.lastName = 'Required';
  }

  if (!values.email) {
    errors.email = 'Required';
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = 'Required';
  }

  if (!values.title) {
    errors.title = 'Required';
  }

  if (!values.csiCodeId) {
    errors.csiCodeId = 'Required';
  }

  return errors;
}

export default reduxForm({
  form: 'NewCompanyForm',
  validate,
})(NewCompanyForm);
