import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './DocumentsTable.css';
import removeSvg from 'images/deleteCross.svg';
import removeDarkSvg from 'images/deleteCrossDarkMode.svg';
import NavigationBar from 'components/shared/navigation/NavigationBar';
import BaseStatus from 'components/shared/text/BaseStatus';
import SimpleTable from 'components/shared/table/SimpleTable';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import Date from 'components/shared/text/Date';
import DocumentLink from 'components/shared/document/DocumentLink';
import DocumentUpload from 'components/shared/document-upload/DocumentUpload';
import SelectSubcategoryModal from 'components/admin/projects/details/project-documentation/SelectSubcategoryModal';
import { getDarkMode } from 'selectors/theme';

class DocumentsTable extends Component {
  static propTypes = {
    category: PropTypes.shape({}).isRequired,
    perPage: PropTypes.number.isRequired,
    readOnly: PropTypes.bool,
    handleDrop: PropTypes.func.isRequired,
    handleRemove: PropTypes.func.isRequired,
    handlePageChange: PropTypes.func.isRequired,
    handleDocumentRead: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const categories = this.buildCategories(props.category);
    this.state = {
      currentPage: 1,
      selectedCategory: categories[0],
      categories,
      showSelectSubcatModal: false,
    };
  }

  buildCategories = (category) => {
    const subCats = (category.subCategories || []).map((subCat, index) => {
      return { id: index + 2, ...subCat };
    });
    return [{ id: 1, ...category }, ...subCats];
  };

  handleNavSelect = (id) => {
    const { categories } = this.state;
    const selectedCategory = categories.find((cat) => cat.id === id);
    this.setState({ selectedCategory });
    this.handlePageChange(1, selectedCategory);
  };

  handlePageChange = (page, category) => {
    this.props.handlePageChange(page, category);
    this.setState({ currentPage: page });
  };

  handleDrop = (files) => {
    const { handleDrop } = this.props;
    const { selectedCategory } = this.state;
    const subCatsPresent =
      selectedCategory.subCategories &&
      selectedCategory.subCategories.length > 0;
    if (subCatsPresent) {
      this.setState({ showSelectSubcatModal: true, files: files });
    } else {
      handleDrop(files, selectedCategory);
      this.setState({ currentPage: 1 });
    }
  };

  handleSelectSubcatModalSubmit = (param) => {
    const { handleDrop } = this.props;
    const { selectedCategory, files } = this.state;
    handleDrop(files, { ...selectedCategory, param });
    this.setState({ showSelectSubcatModal: false, currentPage: 1 });
  };

  handleSelectSubcatModalClose = () => {
    this.setState({ showSelectSubcatModal: false });
  };

  handleRemove = (doc) => {
    const { handleRemove } = this.props;
    const { currentPage, selectedCategory, categories } = this.state;
    const param = categories.find((cat) => cat.name === `${doc.label}s`).param;
    handleRemove(doc.id, currentPage, { ...selectedCategory, param });
  };

  mapDocuments = (docs) => {
    const { readOnly, handleDocumentRead, darkMode } = this.props;
    const { selectedCategory } = this.state;
    return (this.props[selectedCategory.name] || []).map((doc) => {
      return [
        <div className="doc-viewer">
          {this.props[`${selectedCategory.name}_unread_${doc.id}`] && (
            <BaseStatus
              labels={[
                {
                  id: 'unread',
                  label: '',
                },
              ]}
              status="unread"
            />
          )}
          {!readOnly && (
            <img
              className="remove-doc-icon"
              src={darkMode ? removeDarkSvg : removeSvg}
              alt="remove"
              onClick={() => this.handleRemove(doc)}
            />
          )}
          <DocumentLink
            iconPosition="right"
            url={doc.document.url}
            onClick={() => handleDocumentRead(doc)}
          />
        </div>,
        <Date value={doc.created_at} format="MM/DD/YYYY" />,
      ];
    });
  };

  render() {
    const { category, perPage, readOnly, darkMode } = this.props;
    const {
      currentPage,
      selectedCategory,
      categories,
      showSelectSubcatModal,
    } = this.state;
    const subCatsPresent =
      category.subCategories && category.subCategories.length > 0;
    const total = this.props[`${selectedCategory.name}_count`];
    return (
      <div
        className={`documents-table ${darkMode ? 'dark-mode' : ''} ${
          !readOnly ? 'can-remove-doc' : ''
        } ${subCatsPresent ? 'with-subcategories' : ''}`}
      >
        <NavigationBar
          items={categories.map((cat) => {
            return {
              ...cat,
              title: (
                <React.Fragment>
                  {this.props[`${cat.name}_unread`] && (
                    <BaseStatus
                      labels={[
                        {
                          id: 'unread',
                          label: '',
                        },
                      ]}
                      status="unread"
                    />
                  )}
                  {cat.label}
                </React.Fragment>
              ),
              total: this.props[`${cat.name}_count`],
            };
          })}
          selectedValue={selectedCategory.id}
          onSelected={this.handleNavSelect}
        />
        <div className="documents-table-container">
          <SimpleTable
            className="stretch-table"
            headers={['FILENAME', 'DATE ADDED']}
            rows={this.mapDocuments()}
            emptyMessage="No documents added yet."
          />
        </div>
        {!readOnly && <DocumentUpload onDrop={this.handleDrop} />}
        {total > perPage && (
          <div className="pagination-container">
            <SimplePagination
              currentPage={currentPage}
              pageSize={perPage}
              total={total}
              onPageChange={(page) =>
                this.handlePageChange(page, selectedCategory)
              }
            />
          </div>
        )}
        {showSelectSubcatModal && (
          <SelectSubcategoryModal
            showModal={showSelectSubcatModal}
            options={selectedCategory.subCategories.map((subCat) => {
              return { value: subCat.param, label: subCat.label };
            })}
            onSubmit={this.handleSelectSubcatModalSubmit}
            onClose={this.handleSelectSubcatModalClose}
          />
        )}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(DocumentsTable);
