import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CharsCounterTextarea from 'components/shared/chars-counter/CharsCounterTextarea';
import { getDarkMode } from 'selectors/theme';
import removeSvg from 'images/deleteCross.svg';
import removeDarkSvg from 'images/deleteCrossDarkMode.svg';

import './SingleImageDescription.css';

const SingleImageDescription = (props) => {
  const { value, onChange, onDelete, darkMode, hideDescription } = props;
  const [description, setDescription] = useState(value.description || '');

  const handleDescriptionChange = () => {
    if (typeof onChange === 'function') {
      onChange({ ...value, description });
    }
  };

  const handleDeleteImage = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (typeof onDelete === 'function') {
      onDelete();
    }
  };

  return (
    <div className="single-image-upload">
      <div className="image-block">
        <div className="preview">
          <img
            className="img-full-screen"
            alt={value.description}
            src={
              (value.file && value.file.preview) || value.url || value.preview
            }
          />
          <div className="close-button">
            <img
              className="remove-image"
              src={darkMode ? removeDarkSvg : removeSvg}
              alt="remove"
              onClick={handleDeleteImage}
            />
          </div>
        </div>
      </div>
      {!hideDescription && (
        <div className="description-block">
          <CharsCounterTextarea
            placeholder={'Description'}
            value={description || ''}
            onChange={setDescription}
            onBlur={handleDescriptionChange}
            limit={200}
          />
        </div>
      )}
    </div>
  );
};

SingleImageDescription.propTypes = {
  value: PropTypes.shape({
    url: PropTypes.string,
    preview: PropTypes.string,
    file: PropTypes.object,
    description: PropTypes.string,
  }),
  darkMode: PropTypes.bool,
  hideDescription: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

SingleImageDescription.defaultProps = {
  value: { url: '', preview: '', file: null, description: '' },
  onChange: undefined,
  onDelete: undefined,
  darkMode: true,
  hideDescription: false,
};

export default connect((state) => ({ darkMode: getDarkMode(state) }))(
  SingleImageDescription
);
