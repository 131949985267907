export const snakeToCamel = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((v) => snakeToCamel(v));
  }

  return Object.keys(obj).reduce((acc, key) => {
    const camelKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
    let value = obj[key];

    if (typeof value === 'object' && value !== null) {
      value = snakeToCamel(value);
    }

    acc[camelKey] = value;
    return acc;
  }, {});
};

export const camelToSnake = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj; // Return the obj if it's not an object
  }

  if (Array.isArray(obj)) {
    return obj.map((v) => camelToSnake(v)); // Recursively apply to each element if it's an array
  }

  return Object.keys(obj).reduce((acc, key) => {
    const snakeKey = key.replace(/([A-Z])/g, '_$1').toLowerCase(); // Convert camelCase to snake_case
    let value = obj[key];

    // If value is an object, recursively apply the conversion
    if (typeof value === 'object' && value !== null) {
      value = camelToSnake(value);
    }

    acc[snakeKey] = value;
    return acc;
  }, {});
};

export const upperCasetoCamelCase = (str) => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (match, chr) => chr.toUpperCase());
};

export const capitalizeFirstLetters = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const camelToSnakeString = (str) =>
  str.replace(/([A-Z])/g, '_$1').toLowerCase();
