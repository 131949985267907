import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'components/admin/companies/CompaniesDetails.css';
import {
  getCompanyData,
  getProjectsList,
} from 'components/admin/companies/store/selectors';
import SimpleTable from 'components/shared/table/SimpleTable';
import Project from 'domain/project';
import SimpleText from 'components/shared/text/SimpleText';
import ProjectStatus from 'components/shared/project-status/ProjectStatus';
import {
  ProjectStatusEnum,
  ProjectStatusSorted,
} from 'domain/project-status-enum';
import { goToProjectDetails } from 'components/admin/projects/store/actions';
import SearchBar from 'components/shared/navigation/SearchBar';
import DropdownMenu, {
  DROPDOWN_TYPES,
  CounterItem,
} from 'components/shared/menu/DropdownMenu';

class ProjectTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameToSearch: '',
      selectedStatus: 'all',
      selectedCsiCode: 'all',
    };
  }

  filterProject = (project) => {
    const { nameToSearch, selectedStatus, selectedCsiCode } = this.state;

    if (selectedStatus !== 'all' && selectedStatus !== project.status)
      return false;

    if (
      selectedCsiCode !== 'all' &&
      !project.contributors.some(
        (contributor) => contributor.csi_code.id === selectedCsiCode
      )
    )
      return false;

    const nameToSearchLower = nameToSearch.toLowerCase();
    return (
      !nameToSearchLower ||
      project.number.toLowerCase().indexOf(nameToSearchLower) >= 0 ||
      project.name.toLowerCase().indexOf(nameToSearchLower) >= 0
    );
  };

  mapProjects = (projects) => {
    return projects
      .filter((project) => this.filterProject(project))
      .map((project) => ({
        id: project.id,
        data: [
          project.number,
          project.name,
          <SimpleText
            lines={project.contributors
              .map((contributor) => Project.csiCodeLabel(contributor.csi_code))
              .sort()}
          />,
          project.client ? project.client.name : '-',
          project.creator_company.name,
          <SimpleText
            lines={[
              project.project_location.location.street_address_1,
              project.project_location.location.city,
            ]}
          />,
          <ProjectStatus
            status={ProjectStatusEnum.create(project.status)}
            type="list"
          />,
        ],
      }));
  };

  handleRowSelection = (id) => this.props.dispatch(goToProjectDetails(id));

  handleGenerateLink = (id) => `/admin/projects/${id}`;

  projectStatusOptions = () => {
    const { projects } = this.props;
    const totalsByStatus = projects.reduce(
      (totals, project) => ({
        ...totals,
        [project.status]: (totals[project.status] || 0) + 1,
      }),
      {}
    );
    return ProjectStatusSorted.map((status) => {
      const counter = totalsByStatus[status.status] || 0;
      return {
        className: status.isSubStatus() ? 'substatus-option' : null,
        value: status.status,
        label: CounterItem(status.label, counter),
        show: counter > 0,
      };
    });
  };

  csiCodeOptions = () => {
    const { projects } = this.props;
    const { selectedStatus } = this.state;

    const csiCodes = projects
      .filter(
        (project) =>
          selectedStatus === 'all' || selectedStatus === project.status
      )
      .reduce(
        (projectsCodes, project) =>
          project.contributors.reduce((codes, { csi_code: csiCode }) => {
            const label = Project.csiCodeLabel(csiCode);
            if (codes[label]) {
              codes[label].counter++;
              return codes;
            }

            return {
              ...codes,
              [label]: {
                id: csiCode.id,
                counter: 1,
              },
            };
          }, projectsCodes),
        {}
      );
    return Object.keys(csiCodes)
      .sort()
      .map((label) => ({
        value: csiCodes[label].id,
        label: CounterItem(label, csiCodes[label].counter),
      }));
  };

  handleSearchTextChange = (nameToSearch) => this.setState({ nameToSearch });

  handleSelectedStatusChange = (selectedStatus) =>
    this.setState({ selectedStatus });

  handleSelectedCsiCodeChange = (selectedCsiCode) =>
    this.setState({ selectedCsiCode });

  render() {
    const { projects } = this.props;
    const { nameToSearch, selectedStatus, selectedCsiCode } = this.state;

    return (
      <div>
        <SearchBar
          searchText={nameToSearch}
          onSearchTextChange={this.handleSearchTextChange}
          onSearch={this.handleSearchTextChange}
        >
          <DropdownMenu
            type={DROPDOWN_TYPES.SECTION_SELECTOR}
            className="status-selector"
            options={[
              {
                value: 'all',
                label: CounterItem('All Stages', projects.length),
              },
            ].concat(this.projectStatusOptions())}
            value={selectedStatus}
            onChange={(option) => this.handleSelectedStatusChange(option.value)}
          />
          <DropdownMenu
            type={DROPDOWN_TYPES.SECTION_SELECTOR}
            className="status-selector"
            options={[
              {
                value: 'all',
                label: CounterItem('All Codes'),
              },
            ].concat(this.csiCodeOptions())}
            value={selectedCsiCode}
            onChange={(option) =>
              this.handleSelectedCsiCodeChange(option.value)
            }
          />
        </SearchBar>
        <div className="projects-table">
          <SimpleTable
            className="companies-table"
            headers={[
              'PROJECT NO',
              'PROJECT NAME',
              'COST CODE',
              'OWNER',
              'SUBSCRIBER',
              'LOCATION',
              'STAGE',
            ]}
            rows={this.mapProjects(projects)}
            emptyMessage="No Projects"
            onRowSelected={this.handleRowSelection}
            onGenerateLink={this.handleGenerateLink}
          />
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    companyData: getCompanyData(state),
    projects: getProjectsList(state),
  };
})(ProjectTable);
