import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export const DATE_FORMAT = {
  DEFAULT: 'L',
  TEXT: 'll',
  TEXT_LONG: 'LL',
};

class Date extends Component {
  static propTypes = {
    className: PropTypes.string,
    format: PropTypes.string,
    value: PropTypes.string.isRequired,
  };

  static defaultProps = {
    format: DATE_FORMAT.DEFAULT,
  };

  render() {
    let date;
    let dMoment = moment(this.props.value, moment.defaultFormat);
    if (dMoment.isValid()) {
      date = moment(this.props.value).format(
        this.props.format ? this.props.format : 'L'
      );
    } else {
      date = 'Invalid date';
    }

    return (
      <span
        className={
          this.props.className !== undefined ? this.props.className : null
        }
      >
        {date}
      </span>
    );
  }
}

export default Date;
