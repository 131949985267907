import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormModal from 'components/shared/modal/FormModal';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import Input from 'components/shared/input/Input';
import TextArea from 'components/shared/input/TextArea';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import EmailHistoryModal from 'components/admin/projects/details/project-email-modal/EmailHistoryModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import './AddProjectReportLog.css';

class ProjectReportSendEmailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectEmails:
        props.emails.length > 0
          ? [[props.emails[0].id, props.emails[0].label]]
          : [],
      subject: this.initialSubject(),
      message: this.initialMessage(),
      showHistoryModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reportNumber !== this.props.reportNumber) {
      this.setState({
        subject: this.initialSubject(),
        message: this.initialMessage(),
      });
    }
  }

  handleSubjectChange = (value) => {
    this.setState({ subject: value });
  };

  handleMessageChange = (value) => {
    this.setState({ message: value });
  };

  handleSend = () => {
    const { onSend, onClose } = this.props;
    const { selectEmails, subject, message } = this.state;
    onSend(selectEmails, subject, message);
    onClose();
  };

  handleHistoryClick = () => {
    const { getHistoryEmails } = this.props;
    getHistoryEmails();
    this.setState({ showHistoryModal: true });
  };

  initialSubject = () => {
    const { project, reportNumber } = this.props;
    let number = `${reportNumber < 10 ? '0' : ''}${reportNumber}`;
    return `${project.number} ${project.name} - Project Report ${number}`;
  };

  initialMessage = () => {
    const { project, reportNumber } = this.props;
    const { street_address_1, city, state } = project.project_location.location;

    return `Please find the attached Project Report ${reportNumber} \
for ${project.name} located at ${street_address_1}, ${city}, ${state}. \
If you have any questions, please reach out via chat or email.`;
  };

  render() {
    const { emails, show, onClose, title, history } = this.props;
    const { selectEmails, subject, message, showHistoryModal } = this.state;
    return (
      <FormModal
        id="project-report-send-email-modal"
        className="send-email-modal"
        show={show}
        onClose={onClose}
        subTitle={title}
      >
        <div className="send-email-container">
          <FormControlBlock
            label={selectEmails.length > 0 ? 'To' : ''}
            control={
              <MultipleDropdown
                options={emails}
                currentValues={selectEmails}
                placeholder="To"
                onChange={(value) => this.setState({ selectEmails: value })}
              />
            }
          />
          <FormControlBlock
            label="Subject"
            control={
              <Input
                placeholder="Subject"
                value={subject}
                onChange={(value) => this.handleSubjectChange(value)}
              />
            }
          />
          <FormControlBlock
            label="Message"
            control={
              <TextArea
                placeholder="Message"
                value={message}
                onChange={(value) => this.handleMessageChange(value)}
              />
            }
          />
          <div className="buttons-container">
            <Button
              key="history-button"
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="History"
              onClick={this.handleHistoryClick}
            />
            <div className="buttons-separate" />
            <Button
              key="send-button"
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Send"
              disabled={selectEmails.length === 0}
              onClick={this.handleSend}
            />
          </div>
        </div>
        {showHistoryModal && (
          <EmailHistoryModal
            show={showHistoryModal}
            subTitle={title}
            history={history}
            onClose={() => {
              this.setState({ showHistoryModal: false });
            }}
          />
        )}
      </FormModal>
    );
  }
}

ProjectReportSendEmailModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSend: PropTypes.func,
  title: PropTypes.string,
  project: PropTypes.shape().isRequired,
  reportNumber: PropTypes.number,
};

ProjectReportSendEmailModal.defaultProps = {
  show: false,
  onClose: () => null,
  onSend: () => null,
  title: 'Send For Review',
  reportNumber: null,
};

export default ProjectReportSendEmailModal;
