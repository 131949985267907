import axios from 'axios';
import BaseApi from './base-api';

class WorkOrderAuthorizationsApi extends BaseApi {
  getWorkOrderAuthorizations(workOrderId) {
    return axios
      .get(
        `${this.baseUrl}/work_orders/${workOrderId}/work_order_authorizations`
      )
      .then((response) => {
        return {
          ok: true,
          workOrderAuthorizations: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getSigningUrl(workOrderAuthorizationId, redirectUrl) {
    return axios
      .get(
        `${this.baseUrl}/work_order_authorizations/${workOrderAuthorizationId}/signing_url?redirect_url=${redirectUrl}`
      )
      .then((response) => {
        return {
          ok: true,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export let workOrderAuthorizationsApi = new WorkOrderAuthorizationsApi();
export default workOrderAuthorizationsApi = new WorkOrderAuthorizationsApi();
