import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';

import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import SidebarForm from 'components/shared/sidebar-form/SidebarForm';
import MeetingsCorrectionsReviewModal from './MeetingsCorrectionsReviewModal';
import MessageModal, {
  MODAL_ICONS,
} from 'components/shared/modal/MessageModal';
import PdfViewer from 'components/shared/pdf-viewer/PdfViewer';

import './MeetingsInstanceForm.css';
import {
  setFormMeetingInstance,
  setInstanceFormShow,
  createMeetingsInstance,
  updateMeetingsInstance,
  setAddCategoryModal,
  setAddGuestModal,
  setNotepadShow,
  setReviewCorrectionsModalShow,
  setSubmitCorrectionsModalShow,
  setInstanceInfoErrors,
  setActionItemsErrors,
  setMeetingsInstancePdfViewerShow,
  setInstanceFormIsBackup,
  setConfirmCloseModalShow,
  createMeetingsParent,
  getMeetingsInstance,
  setMeetingsInstanceHtml,
} from './store/actions';
import {
  getActionItemHistory,
  getActionItemHistoryModal,
  getActionItemsResolvedModalShow,
  getAddCategoryModalShow,
  getAddGuestModalShow,
  getMeetingsInstanceForm,
  getNotepadShow,
  getProjectNewMeetingsForm,
  getActionItemsErrors,
  getInstanceInfoErrors,
  getInstancesPdfViewerShow,
  getPreviewModalShow,
  getMeetingsInstanceIsBackup,
  getSubmitCorrectionsModalShow,
  getReviewCorrectionsModalShow,
} from './store/selectors';
import Meeting from 'domain/new-meeting';
import MeetingsInstanceFormInfo from './MeetingsInstanceFormInfo';
import MeetingsInstanceFormAttendance from './MeetingsInstanceFormAttendance';
import MeetingsInstanceFormActionItems from './MeetingsInstanceFormActionItems';
import AddCategoryModal from './AddCategoryModal';
import AddGuestModal from './AddGuestModal';
import ActionItemsResolvedModal from './ActionItemsResolvedModal';
import ActionItemHistoryModal from './ActionItemHistoryModal';
import Notepad from './Notepad';
import MeetingsInstanceFormNotepad from './MeetingsInstanceFormNotepad';
import MeetingsInstanceFormCorrections from './MeetingsInstanceFormCorrections';
import MeetingsInstanceFormAttachments from './MeetingsInstanceFormAttachments';
import { DEFAULT_INSTANCE_FORM } from '../../../../../domain/new-meeting';
import ConfirmCloseModal from './ConfirmCloseModal';
import newMeetingsPermissions from '../../../../../permissions/new-meetings-permissions';
import MeetingsInstanceFormQuickStart from './MeetingsInstanceFormQuickStart';
import MeetingsInstanceDocumentHtmlViewer from './MeetingsInstanceDocumentHtmlViewer';
import MeetingsCorrectionsSubmitModal from './MeetingsCorrectionsSubmitModal';

const MeetingsInstanceForm = (props) => {
  const {
    instanceForm,
    dispatch,
    form,
    currentProject,
    showAddCategoryModal,
    showAddGuestmodal,
    showActionItemsResolvedModal,
    showActionItemHistoryModal,
    showReviewCorrectionsModal,
    actionItemHistory,
    showNotepad,
    actionItemsErrors,
    instanceInfoErrors,
    showMeetingsInstancePdfModal,
    showWaitingPreview,
    currentUser,
    meetingsInstanceIsBackup,
    showSubmitCorrectionsModal,
  } = props;

  useEffect(() => {
    dispatch(setActionItemsErrors(instanceForm.meetingsActionItems));
  }, [instanceForm.meetingsActionItems]);
  useEffect(() => {
    dispatch(setInstanceInfoErrors(instanceForm));
  }, [
    instanceForm.title,
    instanceForm.meetingType,
    instanceForm.startDate,
    instanceForm.startTime,
    instanceForm.endTime,
  ]);
  const [pdfUrl, setPdfUrl] = useState('');

  const getFormattedNumberFromUrl = (url) => {
    console.log(url);
    const match = url.match(/_(\d+\.\w+)\.pdf$/);
    return match ? match[1] : null;
  };

  const isFormattedNumberMatching = (url, formattedNumber) => {
    const numberFromUrl = getFormattedNumberFromUrl(url);
    console.log(numberFromUrl);
    console.log(formattedNumber);
    return numberFromUrl === formattedNumber;
  };

  const handleFormChange = (fields) => {
    dispatch(setFormMeetingInstance(fields));
  };
  const handleClosePdfViewer = () => {
    dispatch(setMeetingsInstancePdfViewerShow(false));
  };
  const handleView = (instance) => {
    if (instance.id)
      dispatch(getMeetingsInstance(currentProject.id, instance.id));
    const url = Meeting.getInstancePdfUrl(instance);

    if (!url) {
      dispatch(setMeetingsInstanceHtml(instance));
      return;
    }
    const isMatchingNumber = isFormattedNumberMatching(
      url,
      instance.formattedNumber
    );
    if (!isMatchingNumber) {
      dispatch(setMeetingsInstanceHtml(instance));
      return;
    }

    dispatch(setMeetingsInstancePdfViewerShow(true));
    setPdfUrl(url);
  };
  const handleSaveDraftAgenda = () => {
    const status = 'draft_agenda';
    const values = { ...instanceForm, status };
    if (
      actionItemsErrors.length > 0 ||
      Object.keys(instanceInfoErrors).length > 0
    ) {
      return;
    }
    if (!instanceForm.id) {
      dispatch(
        createMeetingsInstance(currentProject.id, {
          ...values,
          createdById: currentUser.id,
          agendaCreatedById: currentUser.id,
        })
      );
    } else {
      dispatch(
        updateMeetingsInstance(currentProject.id, instanceForm.id, values)
      );
    }
    dispatch(setInstanceFormShow(false));
  };
  const handleSaveDraftMinutes = () => {
    const status = 'draft_minutes';
    const values = { ...instanceForm, status };
    if (
      actionItemsErrors.length > 0 ||
      Object.keys(instanceInfoErrors).length > 0
    ) {
      return;
    }
    if (instanceForm.isQuickStart) {
      const parentForm = Meeting.createParentValuesFromQuickStart(instanceForm);
      dispatch(
        createMeetingsParent(currentProject.id, {
          ...parentForm,
          createdById: currentUser.id,
          projectId: currentProject.id,
        })
      );
      dispatch(setInstanceFormShow(false));
      return;
    }
    if (!instanceForm.id) {
      dispatch(
        createMeetingsInstance(currentProject.id, {
          ...values,
          createdById: currentUser.id,
          minutesCreatedById: currentUser.id,
        })
      );
    } else {
      dispatch(
        updateMeetingsInstance(currentProject.id, instanceForm.id, values)
      );
    }
    dispatch(setInstanceFormShow(false));
  };
  const handleConvert = () => {
    const newStatus = 'draft_minutes';
    const values = {
      ...instanceForm,
      isAgenda: false,
      status: newStatus,
      minutesSubmittedById: currentUser.id,
    };
    dispatch(setFormMeetingInstance(values));
    if (!instanceForm.id) {
      dispatch(
        createMeetingsInstance(currentProject.id, {
          ...values,
          minutesCreatedById: currentUser.id,
        })
      );
    } else {
      // conversion case
      const conversionValues = {
        ...values,
        meetingsAgendaBackup: {
          ...values,
          id: null,
          meetingsInstanceId: instanceForm.id,
          isAgenda: true,
        },
      };

      dispatch(
        updateMeetingsInstance(
          currentProject.id,
          instanceForm.id,
          conversionValues
        )
      );
    }
  };
  const handleChangeAttendanceEditState = () => {
    dispatch(
      setFormMeetingInstance({
        ...instanceForm,
        editAttendees: !instanceForm.editAttendees,
      })
    );
  };
  const handlePublishAgenda = () => {
    const status = 'published_agenda';

    const values = {
      ...instanceForm,
      status,
      agendaSubmittedById: currentUser.id,
    };
    if (
      actionItemsErrors.length > 0 ||
      Object.keys(instanceInfoErrors).length > 0
    ) {
      return;
    }
    if (!instanceForm.id) {
      dispatch(
        createMeetingsInstance(currentProject.id, {
          ...values,
          createdById: currentUser.id,
          agendaCreatedById: currentUser.id,
        })
      );
    } else {
      dispatch(
        updateMeetingsInstance(currentProject.id, instanceForm.id, values)
      );
    }

    dispatch(setInstanceFormShow(false));
  };
  const handlePublishMinutes = () => {
    const status = 'published_minutes';

    const values = {
      ...instanceForm,
      status,
      minutesSubmittedById: currentUser.id,
    };
    if (
      actionItemsErrors.length > 0 ||
      Object.keys(instanceInfoErrors).length > 0
    ) {
      return;
    }
    if (instanceForm.isQuickStart) {
      const parentForm = Meeting.createParentValuesFromQuickStart({
        ...instanceForm,
        projectId: currentProject.id,
        createdById: currentUser.id,
      });
      dispatch(createMeetingsParent(currentProject.id, parentForm));
      dispatch(setInstanceFormShow(false));
      return;
    }
    if (!instanceForm.id) {
      dispatch(
        createMeetingsInstance(currentProject.id, {
          ...values,
          createdById: currentUser.id,
          minutesCreatedById: currentUser.id,
        })
      );
    } else {
      dispatch(
        updateMeetingsInstance(currentProject.id, instanceForm.id, values)
      );
    }
    dispatch(setInstanceFormShow(false));
  };
  const handleRepublishAgenda = () => {
    const status = 'republished_agenda';
    const values = { ...instanceForm, status };
    if (
      actionItemsErrors.length > 0 ||
      Object.keys(instanceInfoErrors).length > 0
    ) {
      return;
    }
    if (!instanceForm.id) {
      dispatch(
        createMeetingsInstance(currentProject.id, {
          ...values,
          createdById: currentUser.id,
          agendaCreatedBy: currentUser.id,
        })
      );
    } else {
      dispatch(
        updateMeetingsInstance(currentProject.id, instanceForm.id, values)
      );
    }
    dispatch(setInstanceFormShow(false));
  };
  const handleRepublishMinutes = () => {
    const status = 'republished_minutes';
    const values = { ...instanceForm, status };
    if (
      actionItemsErrors.length > 0 ||
      Object.keys(instanceInfoErrors).length > 0
    ) {
      return;
    }
    if (!instanceForm.id) {
      dispatch(
        createMeetingsInstance(currentProject.id, {
          ...values,
          createdById: currentUser.id,
          minutesCreatedBy: currentUser.id,
        })
      );
    } else {
      dispatch(
        updateMeetingsInstance(currentProject.id, instanceForm.id, values)
      );
    }
    dispatch(setInstanceFormShow(false));
  };
  const handleShowConfirmCloseModal = () => {
    dispatch(setConfirmCloseModalShow(true));
  };
  const handleClosePreviewModal = () => {
    dispatch(setConfirmCloseModalShow(false));
  };
  const handleCancel = () => {
    dispatch(setInstanceFormShow(false));
    dispatch(setFormMeetingInstance(DEFAULT_INSTANCE_FORM));
    dispatch(setInstanceFormIsBackup(false));
    dispatch(setConfirmCloseModalShow(false));
  };
  const handleCloseConfirm = () => {
    if (instanceForm.status === 'republished_minutes') handleRepublishMinutes();
    else if (instanceForm.status === 'republished_agenda')
      handleRepublishAgenda();
    else if (instanceForm.status.includes('agenda')) handleSaveDraftAgenda();
    else if (instanceForm.status.includes('minutes')) handleSaveDraftMinutes();
    dispatch(setInstanceFormShow(false));
    dispatch(setFormMeetingInstance(DEFAULT_INSTANCE_FORM));
    dispatch(setInstanceFormIsBackup(false));
    dispatch(setConfirmCloseModalShow(false));
  };
  const handleAddCategory = () => {
    dispatch(setAddCategoryModal(true));
  };
  const handleAddGuest = () => {
    dispatch(setAddGuestModal(true));
  };
  const handleShowNotepad = () => {
    dispatch(setNotepadShow(true));
  };
  const sections = [
    {
      id: 'attendance',
      title: 'Attendance',
      render: () => (
        <MeetingsInstanceFormAttendance
          form={instanceForm}
          handleFormChange={handleFormChange}
          meetingsParent={form}
        />
      ),
    },
    {
      id: 'action_items',
      title: 'Action Items',
      render: () => (
        <MeetingsInstanceFormActionItems
          form={form}
          handleFormChange={handleFormChange}
          meetingsParent={form}
        />
      ),
    },
    {
      id: 'corrections',
      title: 'Meetings Corrections',
      render: () => <MeetingsInstanceFormCorrections />,
    },
    {
      id: 'attachments',
      title: 'Project Documents',
      render: () => (
        <MeetingsInstanceFormAttachments handleFormChange={handleFormChange} />
      ),
    },
    {
      id: 'notepad',
      title: 'Notepad',
      render: () => <MeetingsInstanceFormNotepad />,
    },
  ];
  if (!instanceForm.isQuickStart) {
    sections.unshift({
      id: 'general_info',
      title: 'General Info',
      render: () => (
        <MeetingsInstanceFormInfo
          form={instanceForm}
          handleFormChange={handleFormChange}
          meetingsParent={form}
        />
      ),
    });
  } else {
    sections.unshift({
      id: 'quick_start',
      title: 'General Info',
      render: () => (
        <MeetingsInstanceFormQuickStart
          form={instanceForm}
          handleFormChange={handleFormChange}
          meetingsParent={form}
        />
      ),
    });
  }
  const [formSection, setFormSection] = useState(
    instanceForm.isQuickStart ? 'quick_start' : 'general_info'
  );
  const { isAgenda, formattedNumber, isQuickStart } = instanceForm;
  const agendaPublishLabel = () => {
    const { status } = instanceForm;
    if (status === 'draft_agenda') return 'Publish';
    if (status === 'published_agenda') return 'Republish';
    if (status === 'republished_agenda') return 'Republish';
    return 'Publish';
  };
  const minutesPublishAgendaLabel = () => {
    const { status } = instanceForm;
    if (status === 'draft_minutes') return 'Publish';
    if (status === 'published_minutes') return 'Republish';
    if (status === 'republished_minutes') return 'Republish';
    return 'Publish';
  };
  const handlePublishCases = () => {
    const { status } = instanceForm;
    if (status === 'published_agenda') handleRepublishAgenda();
    if (status === 'republished_agenda') handleRepublishAgenda();
    if (status === 'draft_agenda') handlePublishAgenda();
  };
  const handleMinutesPublishCases = () => {
    const { status } = instanceForm;
    if (status === 'published_minutes') handleRepublishMinutes();
    if (status === 'republished_minutes') handleRepublishMinutes();
    if (status === 'draft_minutes') handlePublishMinutes();
  };
  const filteredItemHistoryModal = Meeting.filterDiscardedUpdatesInItem(
    actionItemHistory
  );
  const handleReview = () => {
    dispatch(setReviewCorrectionsModalShow(true));
  };
  const handleAddCorrection = () => {
    dispatch(setSubmitCorrectionsModalShow(true));
  };
  const actionItemsTopButtons = [
    <Button
      key="add-new-guest-button"
      className="add-new-guest-button"
      type={BUTTON_TYPES.LINK}
      icon={BUTTON_ICONS.PLUS}
      label="Add Category"
      onClick={() => handleAddCategory()}
    />,
    <Button
      key="edit-attendance-button"
      className="edit-attendance-button"
      type={BUTTON_TYPES.LINK}
      color={BUTTON_COLORS.GREEN}
      label="Notepad"
      onClick={() => handleShowNotepad()}
    />,
  ];
  const attendanceTopButtons = [
    <Button
      key="add-new-guest-button"
      className="add-new-guest-button"
      type={BUTTON_TYPES.LINK}
      icon={BUTTON_ICONS.PLUS}
      label="Add guest"
      onClick={() => handleAddGuest()}
    />,
    <Button
      key="edit-attendance-button"
      className="edit-attendance-button"
      type={BUTTON_TYPES.LINK}
      color={BUTTON_COLORS.GREEN}
      label={instanceForm.editAttendees ? 'Save' : 'Edit Attendance'}
      onClick={() => handleChangeAttendanceEditState()}
    />,
  ];
  const viewPdfButton = (
    <Button
      key="view"
      type={BUTTON_TYPES.CONFIRMATION}
      color={BUTTON_COLORS.TRANSPARENT}
      label="View PDF"
      onClick={() => handleView(instanceForm)}
    />
  );
  const agendaActions = [
    <Button
      key="draft"
      type={BUTTON_TYPES.CONFIRMATION}
      color={BUTTON_COLORS.TRANSPARENT}
      label="Save Draft"
      onClick={handleSaveDraftAgenda}
    />,
    <Button
      key="Convert"
      type={BUTTON_TYPES.CONFIRMATION}
      color={BUTTON_COLORS.GREEN}
      label="Convert"
      onClick={handleConvert}
    />,
    <Button
      key="publish"
      type={BUTTON_TYPES.CONFIRMATION}
      color={BUTTON_COLORS.GREEN}
      label={agendaPublishLabel()}
      onClick={handlePublishCases}
    />,
    <Button
      key="cancel"
      type={BUTTON_TYPES.CONFIRMATION}
      color={BUTTON_COLORS.TRANSPARENT}
      label="Close"
      onClick={handleShowConfirmCloseModal}
    />,
  ];
  agendaActions.unshift(viewPdfButton);
  const actions = [
    <Button
      key="draft-minutes"
      type={BUTTON_TYPES.CONFIRMATION}
      color={BUTTON_COLORS.TRANSPARENT}
      label="Save Draft"
      onClick={handleSaveDraftMinutes}
    />,
    <Button
      key="review-minutes"
      type={BUTTON_TYPES.CONFIRMATION}
      color={BUTTON_COLORS.GREEN}
      label="Review"
      onClick={handleReview}
    />,
    <Button
      key="publish-minutes"
      type={BUTTON_TYPES.CONFIRMATION}
      color={BUTTON_COLORS.GREEN}
      label={minutesPublishAgendaLabel()}
      onClick={handleMinutesPublishCases}
    />,
    <Button
      key="cancel-minutes"
      type={BUTTON_TYPES.CONFIRMATION}
      color={BUTTON_COLORS.GREEN}
      label="Close"
      onClick={handleShowConfirmCloseModal}
    />,
  ];
  const quickStartActions = actions;
  if (instanceForm.id) actions.unshift(viewPdfButton);
  const nonCreatorAttendeesActions = [
    <Button
      key="view-attendees"
      type={BUTTON_TYPES.CONFIRMATION}
      color={BUTTON_COLORS.GREEN}
      label="Add Correction"
      onClick={handleAddCorrection}
    />,
    <Button
      key="cancel-attendees"
      type={BUTTON_TYPES.CONFIRMATION}
      color={BUTTON_COLORS.GREEN}
      label="Close"
      onClick={handleShowConfirmCloseModal}
    />,
  ];
  const backupActions = [
    viewPdfButton,
    <Button
      key="cancel-attendees"
      type={BUTTON_TYPES.CONFIRMATION}
      color={BUTTON_COLORS.GREEN}
      label="Close"
      onClick={handleShowConfirmCloseModal}
    />,
  ];
  if (instanceForm.id) nonCreatorAttendeesActions.unshift(viewPdfButton);

  const titleButtons = () => {
    if (
      formSection === 'action_items' &&
      newMeetingsPermissions.canEditInstance(
        currentUser,
        instanceForm,
        form.status === 'archived'
      )
    )
      return actionItemsTopButtons;
    else if (
      formSection === 'attendance' &&
      newMeetingsPermissions.canEditAttendance(
        currentUser,
        instanceForm,
        form.status === 'archived'
      )
    )
      return attendanceTopButtons;
    else if (
      formSection === 'corrections' &&
      newMeetingsPermissions.canEditInstance(
        currentUser,
        instanceForm,
        form.status === 'archived'
      )
    )
      return [
        <Button
          key="view-attendees"
          type={BUTTON_TYPES.LINK}
          icon={BUTTON_ICONS.PLUS}
          label="Add Correction"
          onClick={handleAddCorrection}
        />,
      ];
    else return [];
  };
  const actionsCheck = () => {
    if (instanceForm.isQuickStart) return quickStartActions;
    if (meetingsInstanceIsBackup) return backupActions;
    if (
      !newMeetingsPermissions.canSeeMoreSideFormButtons(
        currentUser,
        instanceForm
      )
    )
      return nonCreatorAttendeesActions;
    if (instanceForm.isAgenda) return agendaActions;
    if (!instanceForm.isAgenda) return actions;
  };
  const renderFormattedNumber = (formattedNumber) => {
    if (!formattedNumber) return '01';
    return formattedNumber;
  };
  return (
    <React.Fragment>
      <div className="sidebar-form-container">
        <SidebarForm
          className="meetings-instance-form"
          title={`Meeting ${isAgenda ? 'Agenda' : 'Minutes'} ${
            isQuickStart ? '01' : renderFormattedNumber(formattedNumber)
          }`}
          sections={sections}
          actions={actionsCheck()}
          selectedSection={formSection}
          onChange={(s) => setFormSection(s.id)}
          titleButtons={titleButtons()}
        />
      </div>
      <MessageModal
        show={showWaitingPreview}
        icon={MODAL_ICONS.LOADING}
        message={<div>Making PDF, please wait...</div>}
        iconSize={45}
        onHide={handleClosePreviewModal}
      />
      {showAddCategoryModal && (
        <AddCategoryModal show={showAddCategoryModal} primary={true} />
      )}
      {showAddGuestmodal && (
        <AddGuestModal show={showAddGuestmodal} primary={true} />
      )}
      {showActionItemsResolvedModal && (
        <ActionItemsResolvedModal show={showActionItemsResolvedModal} />
      )}
      {showActionItemHistoryModal && (
        <ActionItemHistoryModal
          show={showActionItemHistoryModal}
          actionItem={filteredItemHistoryModal}
        />
      )}
      {showNotepad && <Notepad />}
      {showMeetingsInstancePdfModal && (
        <PdfViewer
          content={{
            source: pdfUrl,
            type: 'url',
          }}
          show={showMeetingsInstancePdfModal}
          onClose={handleClosePdfViewer}
        />
      )}
      {
        <ConfirmCloseModal
          onClose={handleCancel}
          onConfirm={handleCloseConfirm}
        />
      }
      {showReviewCorrectionsModal && <MeetingsCorrectionsReviewModal />}
      {showSubmitCorrectionsModal && <MeetingsCorrectionsSubmitModal />}

      <MeetingsInstanceDocumentHtmlViewer project={currentProject} />
    </React.Fragment>
  );
};

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    currentUser: getCurrentUser(state),
    instanceForm: getMeetingsInstanceForm(state),
    form: getProjectNewMeetingsForm(state),
    showAddCategoryModal: getAddCategoryModalShow(state),
    showAddGuestmodal: getAddGuestModalShow(state),
    showActionItemsResolvedModal: getActionItemsResolvedModalShow(state),
    showActionItemHistoryModal: getActionItemHistoryModal(state),
    actionItemHistory: getActionItemHistory(state),
    showNotepad: getNotepadShow(state),
    actionItemsErrors: getActionItemsErrors(state),
    instanceInfoErrors: getInstanceInfoErrors(state),
    showMeetingsInstancePdfModal: getInstancesPdfViewerShow(state),
    showWaitingPreview: getPreviewModalShow(state),
    meetingsInstanceIsBackup: getMeetingsInstanceIsBackup(state),
    showSubmitCorrectionsModal: getSubmitCorrectionsModalShow(state),
    showReviewCorrectionsModal: getReviewCorrectionsModalShow(state),
  };
})(MeetingsInstanceForm);
