import {
  WORK_ORDERS_DETAILS_DENY_QUOTE_MODAL_CLOSE,
  WORK_ORDERS_DETAILS_DENY_QUOTE_MODAL_SHOW,
  WORK_ORDERS_DETAILS_DENY_QUOTE_QUESTION_ANSWERED,
  WORK_ORDERS_DETAILS_DENY_QUOTE_REASON_CHANGE,
} from '../../../../actions/admin/work-orders/action-types';

const initialState = {
  reason: '',
  show: false,
  sure: null,
};

export default function denyQuoteUiReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_DETAILS_DENY_QUOTE_MODAL_CLOSE:
      return {
        ...state,
        show: false,
      };

    case WORK_ORDERS_DETAILS_DENY_QUOTE_MODAL_SHOW:
      return {
        ...state,
        reason: '',
        show: true,
        sure: null,
      };

    case WORK_ORDERS_DETAILS_DENY_QUOTE_REASON_CHANGE:
      return {
        ...state,
        reason: action.payload,
      };

    case WORK_ORDERS_DETAILS_DENY_QUOTE_QUESTION_ANSWERED:
      return {
        ...state,
        sure: action.payload,
      };

    default:
      return state;
  }
}
