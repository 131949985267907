import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormModal from 'components/shared/modal/FormModal';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import {
  getShowEmailModal,
  getDocumentId,
  getEmails,
  getRecipients,
} from 'components/shared/pdf-viewer-bluebeam/store/selectors';
import {
  initProjectEmailModal,
  setShowModal,
  setEmails,
  setRecipients,
} from 'components/shared/pdf-viewer-bluebeam/store/actions';
import Button from 'components/shared/button/Button';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import { bluebeamApi } from 'services/api/bluebeam-api';

class UsersNotificationModal extends Component {
  componentDidMount() {
    const {
      currentProject,
      directory,
      initialSubject,
      initialMessage,
      dispatch,
    } = this.props;
    const initPayload = { subject: initialSubject, message: initialMessage };

    if (!directory || directory.project_id !== currentProject.id) {
      dispatch(loadDirectoryRequest(currentProject.id)).then((response) => {
        if (response.ok) {
          dispatch(
            setEmails(
              this.recipientOptions(response.directory.project_contributors)
            )
          );
        }
      });
    } else {
      dispatch(
        setEmails(this.recipientOptions(directory.project_contributors))
      );
    }

    dispatch(initProjectEmailModal(initPayload));
  }

  handleClose = (event) => {
    event.preventDefault();
    this.props.dispatch(setShowModal(false));
  };

  handleRecipientsChange = (recipients) => {
    this.props.dispatch(setRecipients(recipients));
  };

  isSendButtonEnabled = () => {
    return (
      this.props.recipients.length > 0 &&
      this.props.subject !== '' &&
      this.props.message !== ''
    );
  };

  handleSendEmail = (recipients) => {
    bluebeamApi
      .inviteUsers(
        recipients.recipients,
        this.props.currentProject.id,
        this.props.documentId
      )
      .then((response) => {
        if (response.ok) {
          this.props.dispatch(setShowModal(false));
        }
      });
  };

  uniqueEmails(emails) {
    let uniques = [];
    emails.forEach((email) => {
      if (!uniques.find((unique) => unique.id === email.id)) {
        uniques.push(email);
      }
    });
    return uniques;
  }

  recipientOptions = (project_contributors) => {
    return this.uniqueEmails(
      project_contributors
        .map((pc) => {
          return pc.users.map((user) => ({ id: user.id, label: user.email }));
        })
        .flat(1)
    );
  };

  render() {
    const { showModal, recipients, emails } = this.props;
    return (
      <FormModal
        className="project-email-modal"
        show={showModal || false}
        title="Select emails to send invites"
        onClose={this.handleClose}
      >
        <div className="project-email-container">
          <table>
            <colgroup>
              <col />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <td className="body-bold">To</td>
                <td>
                  <div className="users-email-container">
                    <MultipleDropdown
                      className="to-emails-dropdown"
                      dropdownClassName="alternate-dropdown-control"
                      options={emails}
                      currentValues={recipients}
                      placeholder="Select emails..."
                      onChange={(values) => this.handleRecipientsChange(values)}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="button-container">
            <Button
              className="button-orange"
              disabled={!this.isSendButtonEnabled()}
              label="Send"
              onClick={() =>
                this.handleSendEmail({
                  recipients: recipients.map((recipient) => recipient[1]),
                })
              }
            />
          </div>
        </div>
      </FormModal>
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    directory: getDirectory(state),
    showModal: getShowEmailModal(state),
    documentId: getDocumentId(state),
    emails: getEmails(state),
    recipients: getRecipients(state),
  };
})(UsersNotificationModal);
