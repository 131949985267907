const keys = {};

export const MeetingStatusKeys = {
  DRAFT: 'draft',
  PENDING: 'pending',
  APPROVED: 'approved',
};

export class MeetingStatusEnum {
  constructor(status, label) {
    this.status = status;
    this.label = label;

    keys[status] = this;
  }

  static create(status) {
    if (keys[status]) {
      return keys[status];
    }

    return MeetingStatusEnum.UNKNOWN;
  }

  equals(meetingStatusEnum) {
    return this.status === meetingStatusEnum.status;
  }
}

MeetingStatusEnum.DRAFT = new MeetingStatusEnum('draft', 'draft');
MeetingStatusEnum.PENDING = new MeetingStatusEnum('pending', 'pending');
MeetingStatusEnum.APPROVED = new MeetingStatusEnum('approved', 'published');
