import React from 'react';
import { connect } from 'react-redux';

import DocumentUpload, { FILE_TYPES } from './DocumentUpload';
import DocumentLinkBluebeam from '../document/DocumentLinkBluebeam';

const DocumentUploadWithPreview = (props) => {
  const {
    onDrop,
    onRemove,
    onDropAccepted,
    onDropRejected,
    readOnly,
    documents,
    multiple = false,
    fileType = FILE_TYPES.ANY,
    className = '',
  } = props;

  return (
    <div className={`document-upload-container ${className}`}>
      {documents.map((doc, index) => (
        <DocumentLinkBluebeam
          key={doc.id || index}
          file={doc}
          onRemove={(!readOnly && (() => onRemove(index))) || null}
        />
      ))}
      {!readOnly && (
        <DocumentUpload
          onDrop={onDrop}
          onDropAccepted={onDropAccepted}
          onDropRejected={onDropRejected}
          multiple={multiple}
          fileType={fileType}
        />
      )}
    </div>
  );
};

export default connect()(DocumentUploadWithPreview);
