export const SET_FIELD_REPORTS = 'SET_FIELD_REPORTS';
export const SET_FIELD_REPORTS_FILTERS = 'SET_FIELD_REPORTS_FILTERS';

export const SET_FIELD_REPORT = 'SET_FIELD_REPORT';
export const FIELD_REPORT_RELOAD = 'FIELD_REPORT_RELOAD';
export const SET_FIELD_REPORT_PDF = 'SET_FIELD_REPORT_PDF';

export const SET_FIELD_REPORT_DELETE_MODAL = 'SET_FIELD_REPORT_DELETE_MODAL';
export const DELETE_FIELD_REPORT = 'DELETE_FIELD_REPORT';

export const SET_FIELD_REPORT_FORM = 'SET_FIELD_REPORT_FORM';
export const SET_FIELD_REPORT_RESOLVED_MODAL =
  'SET_FIELD_REPORT_RESOLVED_MODAL';

export const SET_FIELD_REPORT_DRAFT_MODAL = 'SET_FIELD_REPORT_DRAFT_MODAL';
export const SET_FIELD_REPORTS_CREATORS = 'SET_FIELD_REPORTS_CREATORS';

export const SET_FIELD_REPORT_WAITING_PREVIEW =
  'SET_FIELD_REPORT_WAITING_PREVIEW';

export const SET_FIELD_REPORT_HTML = 'SET_FIELD_REPORT_HTML';
