import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './MeetingItemsTable.css';
import { Table } from 'react-bootstrap';
import TextareaAutosize from 'react-autosize-textarea';
import classnames from 'classnames';
import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import MeetingItem from 'components/admin/projects/details/project-meeting-minutes/MeetingItem';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getMeetingCategories,
  getMeetingItems,
  getMeetingItemSettings,
} from 'components/admin/projects/details/project-meeting-minutes/store/selectors';
import {
  updateMeetingItemValue,
  addNewMeetingItem,
  setMeetingItemSettings,
} from 'components/admin/projects/details/project-meeting-minutes/store/actions';
import { searchDirectory } from 'components/admin/projects/details/project-directory/store/actions';
import { getDarkMode } from 'selectors/theme';
import User from 'domain/user';

class MeetingItemsTable extends Component {
  static propTypes = {
    meetingNumber: PropTypes.number.isRequired,
    meetingDate: PropTypes.string.isRequired,
    reviewMeeting: PropTypes.bool.isRequired,
  };

  handleMeetingItemSettingsClick = (key) => {
    if (this.props.meetingItemSettings[key]) {
      this.props.dispatch(setMeetingItemSettings(key, null));
    } else {
      this.props.dispatch(setMeetingItemSettings(key, 'menu'));
    }
  };

  handleMeetingInfoMenuClick = (e, key, menu) => {
    if (e) {
      e.stopPropagation();
    }

    if (menu === 'delete') {
      this.handleMeetingItemChange(key, 'destroy', true);
      this.refreshItemCodesAfterDelete(key);
    } else if (menu === 'mark-unresolved') {
      this.handleMarkUnresolved(key);
    } else {
      this.props.dispatch(setMeetingItemSettings(key, menu));
    }
  };

  renderMenuForm = (key, menu) => {
    const item = this.props.meetingItems[key];
    let title = '';
    let subTitle = '';
    let value = '';
    if (menu === 'new-info') {
      title = 'Add New Information';
      subTitle = 'What are new updates about this line item?';
      value = item.newInfoDraft || item.newInfo;
    } else if (menu === 'mark-resolved') {
      title = 'Mark as Resolved';
      subTitle = 'How was this resolved?';
      value = item.resolvedNoteDraft || item.resolvedNote;
    }

    return (
      <div>
        <h6 className="small-regular">{title}</h6>
        <div className="small-bold">{subTitle}</div>
        <TextareaAutosize
          rows={1}
          maxRows={4}
          className={classnames({
            'text-area': true,
            'input-area': true,
            'form-control': true,
            'dark-mode': this.props.darkMode,
          })}
          defaultValue={value || ''}
          placeholder="Enter description here..."
          onBlur={(e) =>
            this.handleMeetingItemNoteChange(key, menu, e.target.value)
          }
        />
        <div className="settings-menu-buttons">
          <Button
            color={BUTTON_COLORS.WHITE}
            label="Go Back"
            onClick={(e) => {
              this.handleMeetingInfoMenuClick(e, key, 'menu');
            }}
          />
          <Button
            color={BUTTON_COLORS.GREEN}
            label="Complete"
            onClick={() => {
              this.handleMenuContinue(key, menu);
            }}
          />
        </div>
      </div>
    );
  };

  handleMeetingItemNoteChange = (key, menu, value) => {
    const attr = menu === 'new-info' ? 'newInfoDraft' : 'resolvedNoteDraft';
    this.handleMeetingItemChange(key, attr, value);
  };

  handleMenuContinue = (key, menu) => {
    const item = this.props.meetingItems[key];
    const { meetingDate } = this.props;
    if (menu === 'new-info') {
      this.handleMeetingItemChange(key, 'newInfo', item.newInfoDraft);
      this.handleMeetingItemChange(key, 'newInfoDate', meetingDate);
    } else {
      this.handleMeetingItemChange(key, 'resolved', true);
      this.handleMeetingItemChange(key, 'resolvedNote', item.resolvedNoteDraft);
      this.handleMeetingItemChange(key, 'resolvedDate', meetingDate);
    }

    this.handleMeetingItemSettingsClick(key);
  };

  handleMarkUnresolved = (key) => {
    this.handleMeetingItemChange(key, 'resolved', false);
    this.handleMeetingItemChange(key, 'resolvedNoteDraft', '');
    this.handleMeetingItemChange(key, 'resolvedNote', '');
    this.handleMeetingItemChange(key, 'resolvedDate', null);
    this.handleMeetingItemSettingsClick(key);
  };

  loadContributors = () => {
    let users = {};
    return this.props
      .dispatch(searchDirectory(this.props.currentProject.id))
      .then((response) => {
        if (response.ok) {
          const contributors = response.directory.project_contributors
            .map((pc) => {
              return pc.users
                .filter((usr) => !users[usr.id])
                .map((usr) => {
                  users[usr.id] = true;
                  return {
                    value: usr.id,
                    label: User.fullNameWithCompany({
                      ...usr,
                      accountable: pc.company,
                    }),
                  };
                });
            })
            .reduce((acc, val) => acc.concat(val), []);

          return {
            ok: true,
            options: contributors,
          };
        }
      });
  };

  extractMeetingNumber = (key) => {
    return parseInt(this.props.meetingItems[key].code.split('.')[0], 10);
  };

  extractItemNumber = (key) => {
    return parseInt(this.props.meetingItems[key].code.split('.')[1], 10);
  };

  buildItemCode = (meetingNumber, itemNumber) => {
    return `${meetingNumber}.${
      itemNumber < 10 ? `0${itemNumber}` : itemNumber
    }`;
  };

  refreshItemCodesAfterDelete = (key) => {
    Object.keys(this.props.meetingItems)
      .filter((itemKey) => {
        const item = this.props.meetingItems[itemKey];
        return (
          item.code &&
          !item.destroy &&
          this.extractMeetingNumber(itemKey) ===
            this.extractMeetingNumber(key) &&
          this.extractItemNumber(itemKey) > this.extractItemNumber(key)
        );
      })
      .forEach((itemKey) => {
        const itemCode = this.buildItemCode(
          this.extractMeetingNumber(itemKey),
          this.extractItemNumber(itemKey) - 1
        );
        this.props.dispatch(updateMeetingItemValue(itemKey, 'code', itemCode));
      });
  };

  nextItemCode = (meetingCat) => {
    const { meetingItems, meetingNumber, dispatch } = this.props;
    let newItemNumber;
    Object.keys(meetingItems)
      .filter((itemKey) => {
        const item = meetingItems[itemKey];
        return (
          item.code &&
          !item.destroy &&
          item.meetingCategory.orderNumber > meetingCat.order_number &&
          this.extractMeetingNumber(itemKey) === meetingNumber
        );
      })
      .forEach((itemKey) => {
        if (!newItemNumber) {
          newItemNumber = this.extractItemNumber(itemKey);
        }

        const itemCode = this.buildItemCode(
          this.extractMeetingNumber(itemKey),
          this.extractItemNumber(itemKey) + 1
        );
        dispatch(updateMeetingItemValue(itemKey, 'code', itemCode));
      });

    if (!newItemNumber) {
      newItemNumber =
        Object.keys(meetingItems).filter(
          (key) =>
            !!meetingItems[key].code &&
            !!meetingItems[key].newItem &&
            !meetingItems[key].destroy
        ).length + 1;
    }

    return this.buildItemCode(meetingNumber, newItemNumber);
  };

  setItemCode = (key) => {
    const { meetingItems, dispatch } = this.props;
    const item = meetingItems[key];
    if (!item.code) {
      dispatch(
        updateMeetingItemValue(
          key,
          'code',
          this.nextItemCode(item.meetingCategory)
        )
      );
    }
  };

  handleMeetingItemChange = (key, attribute, value) => {
    if (attribute === 'info') {
      this.setItemCode(key);
    }

    this.props.dispatch(updateMeetingItemValue(key, attribute, value));
  };

  handleAddNewItem = (meetingCat) => {
    const { meetingItems, dispatch } = this.props;
    dispatch(
      addNewMeetingItem(meetingItems, this.nextItemCode(meetingCat), meetingCat)
    );
  };

  mapMeetingItems = (meetingCat) => {
    let itemsWithCode = false;
    let empty = true;
    let items = Object.keys(this.props.meetingItems)
      .filter((key) => {
        const item = this.props.meetingItems[key];
        return (
          item.meetingCategoryId === meetingCat.id &&
          (!this.props.reviewMeeting || (item.code && item.info)) &&
          !item.destroy
        );
      })
      .map((key) => {
        const item = this.props.meetingItems[key];
        itemsWithCode = itemsWithCode || item.code;
        empty = false;
        return (
          <MeetingItem
            key={key}
            itemKey={key}
            item={item}
            meetingItemSettings={this.props.meetingItemSettings[key]}
            reviewMeeting={this.props.reviewMeeting}
            handleMeetingItemSettingsClick={this.handleMeetingItemSettingsClick}
            handleMeetingInfoMenuClick={this.handleMeetingInfoMenuClick}
            handleMeetingItemChange={this.handleMeetingItemChange}
            renderMenuForm={this.renderMenuForm}
            loadContributors={this.loadContributors}
          />
        );
      });

    if (!this.props.reviewMeeting && (itemsWithCode || empty)) {
      items.push(
        <tr key={`${meetingCat.id}-new`}>
          <td></td>
          <td colSpan={3}>
            <Button
              type={BUTTON_TYPES.LINK}
              icon={BUTTON_ICONS.PLUS}
              label="Add Another Row"
              onClick={() => this.handleAddNewItem(meetingCat)}
            />
          </td>
        </tr>
      );
    }

    if (this.props.reviewMeeting && !itemsWithCode) {
      items.push(
        <tr key={`${meetingCat.id}-no-items`}>
          <td className="td-empty-table" colSpan={4}>
            No Items discussed.
          </td>
        </tr>
      );
    }

    return items;
  };

  render() {
    const darkModeClass = this.props.darkMode ? 'dark-mode' : '';

    return (
      <div className={`meeting-items ${darkModeClass}`}>
        {this.props.meetingCategories.map((meetingCat) => {
          return (
            <div key={meetingCat.id}>
              <h5>{meetingCat.label}</h5>
              <Table
                className={`simple-table meeting-items-table ${darkModeClass}`}
              >
                <colgroup>
                  <col key={1} />
                  <col key={2} />
                  <col key={3} />
                  <col key={4} />
                </colgroup>
                <thead>
                  <tr>
                    {['', 'ITEM', '', 'CONTRIBUTORS'].map((element, index) => {
                      return (
                        <th key={index}>
                          <span>{element}</span>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>{this.mapMeetingItems(meetingCat)}</tbody>
              </Table>
            </div>
          );
        })}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    meetingCategories: getMeetingCategories(state),
    meetingItems: getMeetingItems(state),
    meetingItemSettings: getMeetingItemSettings(state),
    darkMode: getDarkMode(state),
  };
})(MeetingItemsTable);
