import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Grid, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  InputLabel,
  InputAdornment,
  Input,
  IconButton,
  FormControl,
} from '@mui/material';
import queryString from 'query-string';
import {
  getErrors,
  getPassword,
  getPasswordConfirmation,
} from 'selectors/client/set-password';
import { confirmPassword, updatePassword } from 'actions/client/set-password';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import Loader from 'components/shared/loader/LoginLoader';
import eyePng from '../login/eye.png';
import eyeSlachPng from '../login/eyeSlash.png';
import logoPng from '../login/logo.png';
import { getLoginBackgroundImage } from '../../../actions/loginBackgroundImage';
import '../recover/ResetPassword';
import { browserClassname } from 'services/utils/browser-util';
import SupportLink from '../SupportLink';

class SetPassword extends Component {
  state = {
    termsAccepted: false,
    showPassword: false,
    showPasswordConfirmation: false,
    loadImage: true,
    loadImageError: false,
    noPassword: false,
    noPasswordConfirmation: false,
  };

  componentDidMount() {
    if (!this.props.imageUrl) {
      this.props.getBackgroundImage();
      this.setState({
        loadImage: false,
        loadImageError: false,
      });
    }
  }

  componentDidUpdate() {
    if (
      (this.props.imageUrl || this.props.imageUrl === '') &&
      !this.state.loadImage
    ) {
      const imageLoader = new Image();
      imageLoader.src = this.props.imageUrl;
      imageLoader.onload = () => {
        this.setState({
          loadImage: true,
          loadImageError: false,
        });
      };

      imageLoader.onerror = () => {
        this.setState({
          loadImage: true,
          loadImageError: true,
        });
      };
    }
  }

  handleChange = (attribute, event) => {
    attribute === 'password' && this.setState({ noPassword: false });
    attribute === 'passwordConfirmation' &&
      this.setState({ noPasswordConfirmation: false });
    this.props.onUpdatePassword(attribute, event.target.value);
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.props.password || !this.props.passwordConfirmation) {
      this.setState({
        noPassword: !this.props.password,
        noPasswordConfirmation: !this.props.passwordConfirmation,
      });
      return;
    }

    const params = queryString.parse(this.props.location.search);
    this.props.onConfirmPassword(
      params.invitation_token,
      this.props.password,
      this.props.passwordConfirmation,
      this.state.termsAccepted
    );
  };

  toggleTermsAccept = () => {
    this.setState((state) => ({ termsAccepted: !state.termsAccepted }));
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleClickShowPasswordConfirmation = () => {
    this.setState({
      showPasswordConfirmation: !this.state.showPasswordConfirmation,
    });
  };

  handleMouseDownPasswordConfirmation = (event) => {
    event.preventDefault();
  };

  render() {
    const backgroundImage =
      !this.state.loadImageError && this.props.imageUrl
        ? { backgroundImage: 'url(' + this.props.imageUrl + ')' }
        : {};
    return this.state.loadImage ? (
      <div className="client" style={backgroundImage}>
        <Grid className="max-height">
          <div className="align-center">
            <Col md={9}>
              <div
                className={browserClassname({
                  firefox: 'form-container-firefox',
                  default: 'form-container',
                })}
              >
                <form onSubmit={this.handleSubmit}>
                  <Col md={7} sm={9} className="left-panel">
                    <Row>
                      <Col md={12}>
                        <div className="logo">
                          <img src={logoPng} alt="Logo" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="title">
                          <span className="title-1">Create Password</span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FormControl
                          fullWidth
                          className={this.state.noPassword ? 'label-error' : ''}
                        >
                          <InputLabel
                            shrink
                            htmlFor="standard-adornment-password"
                          >
                            Password
                          </InputLabel>
                          <Input
                            id="standard-adornment-password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            value={this.props.password}
                            onChange={this.handleChange.bind(this, 'password')}
                            error={
                              this.props.errors.length > 0 ||
                              this.state.noPassword
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                  onMouseDown={this.handleMouseDownPassword}
                                >
                                  {
                                    <img
                                      src={
                                        this.state.showPassword
                                          ? eyeSlachPng
                                          : eyePng
                                      }
                                      className="eye"
                                      alt="eye"
                                    />
                                  }
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Col>

                      <Col md={12} className="input-margin-bottom" />

                      <Col md={12}>
                        <FormControl
                          fullWidth
                          className={
                            this.state.noPasswordConfirmation
                              ? 'label-error'
                              : ''
                          }
                        >
                          <InputLabel
                            shrink
                            htmlFor="standard-adornment-password-confirmation"
                          >
                            Password Confirmation
                          </InputLabel>
                          <Input
                            id="standard-adornment-password-confirmation"
                            type={
                              this.state.showPasswordConfirmation
                                ? 'text'
                                : 'password'
                            }
                            value={this.props.passwordConfirmation}
                            onChange={this.handleChange.bind(
                              this,
                              'passwordConfirmation'
                            )}
                            error={
                              this.props.errors.length > 0 ||
                              this.state.noPasswordConfirmation
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={
                                    this.handleClickShowPasswordConfirmation
                                  }
                                  onMouseDown={
                                    this.handleMouseDownPasswordConfirmation
                                  }
                                >
                                  {
                                    <img
                                      src={
                                        this.state.showPasswordConfirmation
                                          ? eyeSlachPng
                                          : eyePng
                                      }
                                      className="eye"
                                      alt="eye"
                                    />
                                  }
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Col>

                      {this.props.errors.length > 0 && (
                        <Col md={12}>
                          <div className="errors">{this.props.errors} </div>
                        </Col>
                      )}
                      <Col md={12}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: 10,
                            marginTop: 40,
                          }}
                        >
                          <LabeledCheckBox
                            checked={this.state.termsAccepted}
                            onChange={this.toggleTermsAccept}
                            id="terms-and-conditions-checkbox"
                            darkModeFixed={true}
                          />
                          <span style={{ color: 'white', marginLeft: 10 }}>
                            I accept the{' '}
                            <Link
                              target="_blank"
                              style={{ cursor: 'pointer', color: '#D4FB00' }}
                              to={{
                                pathname: 'https://360plusapp.com/',
                              }}
                            >
                              Terms of Use
                            </Link>
                          </span>
                        </div>
                      </Col>

                      <Col className="mobile-container-button">
                        <Button
                          type="submit"
                          className={`btn-continue button-reset ${
                            !this.state.termsAccepted && 'button-disabled'
                          }`}
                        >
                          <span className="text-btn">Continue</span>
                          <span className="arrow" />
                        </Button>
                      </Col>

                      <Col md={6} mdOffset={3}>
                        <div style={{ height: 50 }} />
                        <SupportLink />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={5} sm={3} className="right-panel">
                    <Button
                      type="submit"
                      className={`btn-continue button-reset ${
                        !this.state.termsAccepted && 'button-disabled'
                      }`}
                    >
                      <span className="text-btn">Continue</span>
                      <span className="arrow" />
                    </Button>
                  </Col>
                </form>
              </div>
            </Col>
          </div>
        </Grid>
      </div>
    ) : (
      <Loader loadImage={this.state.loadImage} />
    );
  }
}

export default connect(
  (state) => {
    return {
      errors: getErrors(state),
      password: getPassword(state),
      passwordConfirmation: getPasswordConfirmation(state),
      imageUrl: state.loginBackgroundImage.url,
    };
  },
  (dispatch) => {
    return {
      getBackgroundImage: () => dispatch(getLoginBackgroundImage()),
      onUpdatePassword: (attribute, value) =>
        dispatch(updatePassword(attribute, value)),
      onConfirmPassword: (
        invitation_token,
        password,
        passwordConfirmation,
        termsAccepted
      ) =>
        dispatch(
          confirmPassword(
            invitation_token,
            password,
            passwordConfirmation,
            termsAccepted
          )
        ),
    };
  }
)(SetPassword);
