import moment from 'moment';
import { handlePasteTableFromExcel } from 'services/utils/import-utils';
import { uuidUtil } from 'services/utils/uuid-util.js';

export const importContactInformation = (e) => {
  const res = [];
  const table = handlePasteTableFromExcel(e);

  if (table.length > 0) {
    const columns = [];

    table[0].forEach((_, col) => {
      switch (columns.length) {
        case 0:
          columns.push(['description', col]); // col = 0
          break;
        case 1:
          if (checkNumberColumn(table, col)) columns.push(['amount', col]);
          break;
        case 2:
          if (checkDateColumn(table, col)) columns.push(['start', col]);
          break;
        case 3:
          if (checkDateColumn(table, col)) columns.push(['completion', col]);
          break;
        case 4:
          if (checkBoolColumn(table, col))
            columns.push(['include_amount', col]);
          break;
        case 5:
          if (checkBoolColumn(table, col)) columns.push(['include_team', col]);
          break;
        default:
          break;
      }
    });

    if (columns.length >= 4) {
      table.forEach((row) => {
        const rowData = {
          isEdit: true,
          id: 'newItem' + uuidUtil.uuidv4(),
          description: '',
          amount: '',
          start: '',
          completion: '',
          duration: '',
          include_amount: false,
          include_team: false,
        };

        columns.forEach(([key, col]) => {
          if (key === 'completion' || key === 'start')
            rowData[key] =
              row[col] && moment(row[col]).isValid()
                ? moment(row[col]).format('YYYY-MM-DD')
                : '';
          else if (key === 'include_amount' || key === 'include_team')
            rowData[key] = row[col] === 'true';
          else rowData[key] = row[col] || '';
        });
        res.push(rowData);
      });
    }
  }

  return res;
};

export const importBudgetInformationOne = (e, item) => {
  const res = [];
  const table = handlePasteTableFromExcel(e);

  if (table.length > 0) {
    const columns = [];

    table[0].forEach((_, col) => {
      switch (columns.length) {
        case 0:
          columns.push(['description', col]); // col = 0
          break;
        case 1:
          if (checkNumberColumn(table, col))
            columns.push(['original_cost', col]);
          break;
        case 2:
          if (checkNumberColumn(table, col))
            columns.push(['previous_cost', col]);
          break;
        case 3:
          if (checkNumberColumn(table, col))
            columns.push(['current_cost', col]);
          break;
        case 4:
          if (checkBoolColumn(table, col)) columns.push(['construction', col]);
          break;
        default:
          break;
      }
    });

    if (columns.length >= 2) {
      table.forEach((row) => {
        const rowData = {
          id: 'newItem' + uuidUtil.uuidv4(),
          isEdit: true,
          description: row[columns[0][1]] || '',
          original_cost: row[columns[1][1]] || '',
          previous_cost:
            (columns.length >= 3 ? row[columns[2][1]] : row[columns[1][1]]) ||
            '',
          current_cost:
            (columns.length >= 4 ? row[columns[3][1]] : row[columns[1][1]]) ||
            '',
          cost_sf: '',
          project_report_budget_category_id:
            item.project_report_budget_category_id,
          parent_id: item.parent_id,
          construction: columns.length >= 5 && row[columns[4][1]] === 'true',
        };
        res.push(rowData);
      });
    }
  }

  return res;
};

export const importBudgetInformationTwo = (e, item) => {
  const res = [];
  const table = handlePasteTableFromExcel(e);

  if (table.length > 0) {
    const columns = [];

    table[0].forEach((_, col) => {
      switch (columns.length) {
        case 0:
          columns.push(['description', col]); // col = 0
          break;
        case 1:
          if (checkNumberColumn(table, col))
            columns.push(['contract_amount', col]);
          break;
        case 2:
          if (checkNumberColumn(table, col))
            columns.push(['paid_to_date_amount', col]);
          break;
        case 3:
          if (checkBoolColumn(table, col)) columns.push(['construction', col]);
          break;
        default:
          break;
      }
    });

    if (columns.length >= 2) {
      table.forEach((row) => {
        const rowData = {
          id: 'newItem' + uuidUtil.uuidv4(),
          isEdit: true,
          description: row[columns[0][1]] || '',
          contract_amount: row[columns[1][1]] || '',
          paid_to_date_amount:
            (columns.length >= 3 ? row[columns[2][1]] : '') || '',
          project_report_budget_category_id:
            item.project_report_budget_category_id,
          parent_id: item.parent_id,
          construction: columns.length >= 4 && row[columns[3][1]] === 'true',
        };
        res.push(rowData);
      });
    }
  }

  return res;
};

export const importStatusInformation = (e, item) => {
  const res = [];
  const table = handlePasteTableFromExcel(e);

  if (table.length > 0) {
    if (table[0].length >= 2) {
      const number = parseInt(item.code.split('.')[0], 10);
      const first = parseInt(item.code.split('.')[1], 10) - 1;

      table.forEach((row, i) => {
        const statusNumber = first + i;
        const rowData = {
          isEdit: true,
          id: 'newItem' + uuidUtil.uuidv4(),
          project_report_status_type_id: item.project_report_status_type_id,
          date: moment().format('YYYY-MM-DD'),
          description: row[1] || '',
          code: `${number}.${statusNumber < 9 ? 0 : ''}${statusNumber + 1}`,
        };

        res.push(rowData);
      });
    }
  }

  return res;
};

const checkDateColumn = (table, colIndex) => {
  return table.slice(1).every((r) => {
    const content = r[colIndex];
    return content === '' || moment(content).isValid();
  });
};

const checkNumberColumn = (table, colIndex) => {
  return table.slice(1).every((r) => {
    const content = r[colIndex];
    return content === '' || !isNaN(content);
  });
};

const checkBoolColumn = (table, colIndex) => {
  return table.slice(1).every((r) => {
    const content = r[colIndex];
    return (
      content === '' ||
      content.toLowerCase() === 'true' ||
      content.toLowerCase() === 'false'
    );
  });
};
