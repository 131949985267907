export const PROJECTS_LOAD_SUCCESS = 'PROJECTS_LOAD_SUCCESS';
export const PROJECTS_MISSING_DRS_LOAD_SUCCESS =
  'PROJECTS_MISSING_DRS_LOAD_SUCCESS';
export const PROJECTS_BIDS_DUE_LOAD_SUCCESS = 'PROJECTS_BIDS_DUE_LOAD_SUCCESS';
export const PROJECTS_PAGE_CHANGE = 'PROJECTS_PAGE_CHANGE';
export const PROJECTS_MISSING_DRS_PAGE_CHANGE =
  'PROJECTS_MISSING_DRS_PAGE_CHANGE';
export const PROJECTS_BIDS_DUE_PAGE_CHANGE = 'PROJECTS_BIDS_DUE_PAGE_CHANGE';
export const PROJECTS_NAV_CHANGE = 'PROJECTS_NAV_CHANGE';
export const PROJECTS_MISSING_DRS_COUNT = 'PROJECTS_MISSING_DRS_COUNT';
export const PROJECTS_BIDS_DUE_COUNT = 'PROJECTS_BIDS_DUE_COUNT';
export const PROJECTS_SHOW_ARCHIVE_CONFIRMATION_MODAL =
  'PROJECTS_SHOW_ARCHIVE_CONFIRMATION_MODAL';
