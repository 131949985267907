import axios from 'axios';
import BaseApi from './base-api';

class OrderMaterialsApi extends BaseApi {
  getOrderMaterials(projectId, token) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/order-materials/review?token=${token}`
      )
      .then((response) => {
        return {
          ok: true,
          order: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  confirmOrder(projectId, token, params) {
    console.log(params);
    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/order-materials/confirm?token=${token}`,
        {
          order: params,
        }
      )
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}
export const orderMaterialsApi = new OrderMaterialsApi();
