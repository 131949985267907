import axios from 'axios';
import BaseApi from './base-api';

class DailyReportsEntriesApi extends BaseApi {
  dailyReportEntryParams(values) {
    let apiPayload = new FormData();
    apiPayload.append('daily_report_entry[floor]', values.floor);
    apiPayload.append('daily_report_entry[area]', values.area);
    apiPayload.append(
      'daily_report_entry[preset_task_other]',
      values.preset_task_other
    );
    apiPayload.append(
      'daily_report_entry[work_performed]',
      values.work_performed
    );

    if (values.project_open_item_attributes) {
      const {
        id,
        _destroy,
        assignee_id,
        recipient_ids,
        description,
        resolve_by,
        critical,
        images_attributes,
        deleted_images_attributes,
        documents_attributes,
        deleted_documents_attributes,
      } = values.project_open_item_attributes;
      if (id) {
        apiPayload.append(
          'daily_report_entry[project_open_item_attributes][id]',
          id
        );
      }

      if (_destroy) {
        apiPayload.append(
          'daily_report_entry[project_open_item_attributes][_destroy]',
          _destroy
        );
      } else {
        apiPayload.append(
          'daily_report_entry[project_open_item_attributes][assignee_id]',
          assignee_id
        );
        apiPayload.append(
          'daily_report_entry[project_open_item_attributes][description]',
          description
        );
        apiPayload.append(
          'daily_report_entry[project_open_item_attributes][resolve_by]',
          resolve_by
        );
        apiPayload.append(
          'daily_report_entry[project_open_item_attributes][critical]',
          critical
        );

        if (recipient_ids && recipient_ids.length > 0) {
          recipient_ids.forEach((rcptId) => {
            apiPayload.append(
              'daily_report_entry[project_open_item_attributes][recipient_ids][]',
              rcptId
            );
          });
        } else {
          apiPayload.append(
            'daily_report_entry[project_open_item_attributes][recipient_ids][]',
            []
          );
        }

        let index = 0;
        if (images_attributes && images_attributes.length > 0) {
          images_attributes.forEach((image) => {
            apiPayload.append(
              `daily_report_entry[project_open_item_attributes][images_attributes][${index}][image]`,
              image.file,
              image.file.name
            );
            index++;
          });
        }

        if (deleted_images_attributes && deleted_images_attributes.length > 0) {
          deleted_images_attributes.forEach((image) => {
            apiPayload.append(
              `daily_report_entry[project_open_item_attributes][images_attributes][${index}][id]`,
              image.id
            );
            apiPayload.append(
              `daily_report_entry[project_open_item_attributes][images_attributes][${index}][_destroy]`,
              true
            );
            index++;
          });
        }

        index = 0;
        if (documents_attributes && documents_attributes.length > 0) {
          documents_attributes.forEach((doc) => {
            apiPayload.append(
              `daily_report_entry[project_open_item_attributes][documents_attributes][${index}][document]`,
              doc.file,
              doc.file.name
            );
            index++;
          });
        }

        if (
          deleted_documents_attributes &&
          deleted_documents_attributes.length > 0
        ) {
          deleted_documents_attributes.forEach((doc) => {
            apiPayload.append(
              `daily_report_entry[project_open_item_attributes][documents_attributes][${index}][id]`,
              doc.id
            );
            apiPayload.append(
              `daily_report_entry[project_open_item_attributes][documents_attributes][${index}][_destroy]`,
              true
            );
            index++;
          });
        }
      }
    }

    if (values.comments) {
      apiPayload.append('daily_report_entry[comments]', values.comments);
    }

    let techIndex = 0;

    if (
      values.technicians_attributes &&
      values.technicians_attributes.length > 0
    ) {
      values.technicians_attributes.forEach((attr) => {
        if (attr.id) {
          apiPayload.append(
            `daily_report_entry[technicians_attributes][${techIndex}][id]`,
            attr.id
          );
        }

        apiPayload.append(
          `daily_report_entry[technicians_attributes][${techIndex}][hours_worked]`,
          attr.hours_worked
        );
        apiPayload.append(
          `daily_report_entry[technicians_attributes][${techIndex}][started_at]`,
          attr.started_at
        );
        apiPayload.append(
          `daily_report_entry[technicians_attributes][${techIndex}][ended_at]`,
          attr.ended_at
        );
        techIndex++;
      });
    }

    if (values.deletedTechnicians && values.deletedTechnicians.length > 0) {
      values.deletedTechnicians.forEach((attr) => {
        apiPayload.append(
          `daily_report_entry[technicians_attributes][${techIndex}][id]`,
          attr.id
        );
        apiPayload.append(
          `daily_report_entry[technicians_attributes][${techIndex}][_destroy]`,
          true
        );
        techIndex++;
      });
    }

    if (values.preset_task_ids && values.preset_task_ids.length > 0) {
      values.preset_task_ids.forEach((id) => {
        apiPayload.append('daily_report_entry[preset_task_ids][]', id);
      });
    } else {
      apiPayload.append('daily_report_entry[preset_task_ids][]', []);
    }

    if (values.images_attributes && values.images_attributes.length > 0) {
      values.images_attributes.forEach((image, index) => {
        apiPayload.append(
          `daily_report_entry[images_attributes][${index}][image]`,
          image.file,
          image.file.name
        );
      });
    }

    if (
      values.deleted_images_attributes &&
      values.deleted_images_attributes.length > 0
    ) {
      values.deleted_images_attributes.forEach((image, index) => {
        apiPayload.append(
          `daily_report_entry[images_attributes][${index}][id]`,
          image.id
        );
        apiPayload.append(
          `daily_report_entry[images_attributes][${index}][_destroy]`,
          true
        );
      });
    }

    return apiPayload;
  }

  updateDailyReportEntry(values, reportId) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = this.dailyReportEntryParams(values);

    return axios
      .put(`${this.baseUrl}/daily_report_entries/${reportId}`, data, headers)
      .then((response) => {
        return {
          ok: true,
          dailyReport: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  postDailyReportEntry(entryId) {
    const apiPayload = {
      daily_report_entry: {
        daily_report_entry_id: entryId,
      },
    };
    return axios
      .post(`${this.baseUrl}/daily_report_entries`, apiPayload)
      .then((response) => {
        return {
          ok: true,
          newEntry: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  deleteDailyReportEntry(entryId) {
    return axios
      .delete(`${this.baseUrl}/daily_report_entries/${entryId}`)
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const dailyReportsEntriesApi = new DailyReportsEntriesApi();
