import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import FilterHeader from 'components/shared/table/FilterHeader';
import AddProjectSiteModal from 'components/admin/projects/details/project-site-area/AddProjectSiteModal';
import vectorSelectedSvg from 'components/shared/table/vector-selected.svg';
import vectorSelectedDarkSvg from 'components/shared/table/vector-selected-dark.svg';
import {
  loadProjectSitesAreas,
  setShowAddProjectSiteModal,
} from './store/action';
import { getProjectSites } from 'components/admin/projects/details/project-site-area/store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getDarkMode } from 'selectors/theme';
import './ProjectSiteAreaSelector.css';

const ProjectSitesSelector = (props) => {
  const {
    dispatch,
    handleSelect,
    selected,
    darkMode,
    projectSites,
    currentProject,
  } = props;

  const [searching, setSearching] = useState(false);
  const [sortByNumber, setSortByNumber] = useState(false);

  useEffect(() => {
    if (
      currentProject.id &&
      (!projectSites || projectSites.projectId !== currentProject.id)
    ) {
      dispatch(loadProjectSitesAreas(currentProject.id));
    }
  }, []);

  const sortAlphabetically = (a, b) => {
    const nameA = a.name.toLowerCase(),
      nameB = b.name.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  };

  const sortNumerically = (a, b) => {
    const numberA = (a.number || '').toLowerCase(),
      numberB = (b.number || '').toLowerCase();
    if (numberA < numberB) return -1;
    if (numberA > numberB) return 1;
    return 0;
  };

  const options = useMemo(() => {
    const result = [];

    let count = 0;
    if (
      projectSites &&
      projectSites.sites &&
      currentProject &&
      projectSites.projectId === currentProject.id
    ) {
      projectSites.sites
        .sort(sortByNumber ? sortNumerically : sortAlphabetically)
        .forEach((s) => {
          let label = s.name;
          if (s.number && s.number !== '0') label += ` - #${s.number}`;
          result.push({ id: s.id, label });
          count++;
        });
    }

    result.unshift({ id: 'all', label: 'All Locations' });
    result.unshift({
      id: 'add',
      label: <span className="secondary">Add Location</span>,
    });

    if (count > 1) {
      result.unshift({
        id: 'by_number',
        label: (
          <span className="secondary">
            List By Location {sortByNumber ? 'Name' : 'Number'}
          </span>
        ),
        closeOnSelect: false,
      });
    }

    if (count > 1 && !searching) {
      result.unshift({
        id: 'search',
        label: <span className="secondary">Search</span>,
        closeOnSelect: false,
      });
    }

    if (result.length > 2)
      result.push({
        id: 'edit',
        label: <span className="secondary">Edit Locations</span>,
      });

    return result;
  }, [projectSites, searching, sortByNumber]);

  const handleChange = (site) => {
    if (site === 'add') dispatch(setShowAddProjectSiteModal(true));
    else if (site === 'edit') dispatch(setShowAddProjectSiteModal(true, true));
    else if (site === 'search') setSearching(true);
    else if (site === 'by_number') setSortByNumber(!sortByNumber);
    else handleSelect(!site ? null : site);
  };

  const getLabel = () => {
    if (!selected) return 'Select Location';
    else if (selected === 'all') return '';
    else return 'Location';
  };

  return (
    <>
      <FilterHeader
        className="project-site-area-selector"
        label={getLabel()}
        icon={darkMode ? vectorSelectedDarkSvg : vectorSelectedSvg}
        options={options}
        selected={selected}
        onFilter={handleChange}
        search={searching}
        upperCaseLabel
        clearable
      />
      <AddProjectSiteModal />
    </>
  );
};

export default connect((state) => ({
  darkMode: getDarkMode(state),
  projectSites: getProjectSites(state),
  currentProject: getCurrentProject(state),
}))(ProjectSitesSelector);
