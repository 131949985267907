import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import './RfaLogTable.css';

import SimpleTable from 'components/shared/table/SimpleTable';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import FilterHeader from 'components/shared/table/FilterHeader';
import SortHeader from 'components/shared/table/SortHeader';
import Menu, { MENU_SELECTOR } from 'components/shared/menu/Menu';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import WARNING_ICON from 'images/warning.png';

import {
  getRfaTabNavigation,
  getRfaFilters,
  getTotalRfas,
  getRfas,
} from 'components/admin/projects/details/project-rfa-log/store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';

import {
  setRfaLogPdf,
  setCurrentRfa,
  openRfa,
  startRfaRevision,
  setShowDeleteConfirmation,
} from 'components/admin/projects/details/project-rfa-log/store/actions';
import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';

import Rfa, { dueFilterOptions, statusFilterOptions } from 'domain/rfa';
import RfaPermissions from 'permissions/rfa-permissions';
import { parsePersonName, getDate, parseDate } from 'services/utils/rfa-util';

const RfaLogTable = (props) => {
  const {
    rfas,
    total,
    currentUser,
    currentFilters,
    currentProject,
    handleFilterChange,
    dispatch,
  } = props;

  const statusFilterOptionsByUser = (user, project, pre) => {
    const canSeeDeleted = RfaPermissions.canViewDeleted(user, project);

    return statusFilterOptions.filter(({ id }) => {
      if (id === 'pending') return pre === null;

      if (id === 'deleted') return canSeeDeleted;

      return true;
    });
  };

  const hfc = (key, value) => {
    const newfilters = { ...currentFilters, page: 1, [key]: value };
    const sortKeys = [
      'number_order',
      'company_number_order',
      'title_order',
      'submitted_order',
      'reviewed_order',
      'resolved_order',
      'due',
    ];
    if (sortKeys.includes(key)) {
      sortKeys.forEach((k) => {
        if (k !== key) newfilters[k] = null;
      });
    }
    handleFilterChange(newfilters);
  };

  const tableHeaders = [
    <SortHeader
      label="RFA NO"
      selected={(currentFilters.number_order || 'all') !== 'all'}
      desc={currentFilters.number_order === 'desc'}
      onChange={(selected, desc) =>
        hfc('number_order', !selected ? null : desc ? 'desc' : 'asc')
      }
    />,
    <SortHeader
      label="COMPANY NO"
      selected={(currentFilters.company_number_order || 'all') !== 'all'}
      desc={currentFilters.company_number_order === 'desc'}
      onChange={(selected, desc) =>
        hfc('company_number_order', !selected ? null : desc ? 'desc' : 'asc')
      }
    />,
    'TRADE',
    'TYPE',
    <SortHeader
      label="TITLE"
      selected={(currentFilters.title_order || 'all') !== 'all'}
      desc={currentFilters.title_order === 'desc'}
      onChange={(selected, desc) =>
        hfc('title_order', !selected ? null : desc ? 'desc' : 'asc')
      }
    />,
    'SUBMITTED BY',
    'RESPONSE BY',
    <SortHeader
      label="SUBMITTED"
      selected={(currentFilters.submitted_order || 'all') !== 'all'}
      desc={currentFilters.submitted_order === 'desc'}
      onChange={(selected, desc) =>
        hfc('submitted_order', !selected ? null : desc ? 'desc' : 'asc')
      }
    />,
    <SortHeader
      label="REVIEWED"
      selected={(currentFilters.reviewed_order || 'all') !== 'all'}
      desc={currentFilters.reviewed_order === 'desc'}
      onChange={(selected, desc) =>
        hfc('reviewed_order', !selected ? null : desc ? 'desc' : 'asc')
      }
    />,
    <FilterHeader
      label="DUE"
      options={dueFilterOptions}
      selected={currentFilters.due}
      onFilter={(value) => hfc('due', value)}
    />,
    <SortHeader
      label="RESOLVED"
      selected={(currentFilters.resolved_order || 'all') !== 'all'}
      desc={currentFilters.resolved_order === 'desc'}
      onChange={(selected, desc) =>
        hfc('resolved_order', !selected ? null : desc ? 'desc' : 'asc')
      }
    />,
    <FilterHeader
      label="STATUS"
      options={statusFilterOptionsByUser(
        currentUser,
        currentProject,
        currentFilters.pre
      )}
      selected={currentFilters.status}
      onFilter={(status) => hfc('status', status)}
    />,
    'ACTIONS',
  ];

  const handleView = (rfa) =>
    dispatch(openRfa(currentProject.id, rfa.id, true));

  const handleEdit = (rfa) => dispatch(openRfa(currentProject.id, rfa.id));

  const handleSendEmail = (rfa) => {
    dispatch(setCurrentRfa(rfa));
    dispatch(setShowEmailModal(true));
  };
  const handleOpenPdf = (rfa) => {
    const urlPdf = Rfa.getPdfUrl(rfa) + `?time=${moment().unix()}`;
    dispatch(setRfaLogPdf(urlPdf, true));
  };
  const handleDelete = (rfa) => {
    dispatch(setCurrentRfa(rfa));
    dispatch(setShowDeleteConfirmation(true));
  };
  const handleRevise = (rfa) =>
    dispatch(startRfaRevision(currentUser, currentProject, rfa.id));

  const onRowSelected = (rfa_index) => {
    const rfa = rfas[rfa_index];
    handleView(rfa);
  };

  const parseResponseBy = (person, accelerated) => {
    return parsePersonName(person) + (accelerated ? ' (accelerated)' : '');
  };

  const parseDueDate = (dueDate, resolvedDate) => {
    const due = getDate(dueDate);
    const resolved = getDate(resolvedDate);
    const today = moment();
    const overdue = due && !resolved && due.isBefore(today, 'day');

    return (
      <div className={`due-date ${overdue ? 'text-orange' : ''}`}>
        <span>{parseDate(dueDate)}</span>
        {overdue && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip placement="top" id="overdue" children="Overdue" />
            }
          >
            <img src={WARNING_ICON} alt="warning" className="warning-icon" />
          </OverlayTrigger>
        )}
      </div>
    );
  };

  const renderRowActions = (rfa) => {
    const actions = [];

    if (RfaPermissions.canEmail(currentUser, currentProject, rfa))
      actions.push({
        label: 'Email',
        action: () => handleSendEmail(rfa),
        type: 'menu',
      });

    if (RfaPermissions.canEdit(currentUser, currentProject, rfa))
      actions.push({
        label: 'Edit',
        action: () => handleEdit(rfa),
        type: 'menu',
      });

    if (RfaPermissions.canRevise(currentUser, currentProject, rfa))
      actions.push({
        label: 'Revise',
        action: () => handleRevise(rfa),
        type: 'menu',
      });

    if (Rfa.getPdfUrl(rfa))
      actions.push({
        label: 'PDF',
        action: () => handleOpenPdf(rfa),
        type: 'menu',
      });

    if (RfaPermissions.canDelete(currentUser, currentProject, rfa))
      actions.push({
        label: 'Delete',
        action: () => handleDelete(rfa),
        type: 'menu',
      });

    return (
      <div className="actions">
        {actions
          .filter((a) => a.type === 'button')
          .map((a, index) => (
            <Button
              key={`action_${rfa.id}_${index}`}
              type={BUTTON_TYPES.LINK}
              color={BUTTON_COLORS.GREEN}
              label={a.label}
              onClick={a.action}
            />
          ))}
        <Menu
          key="more-actions"
          selector={MENU_SELECTOR.MORE_OPTIONS_VERTICAL}
          items={actions
            .filter((a) => a.type === 'menu')
            .map((a) => {
              return { content: a.label, onClick: a.action };
            })}
        />
      </div>
    );
  };

  const rows = rfas.map((rfa) => {
    return [
      rfa.formatted_number,
      rfa.formatted_company_number,
      rfa.csi_code ? rfa.csi_code.division : '00',
      rfa.formatted_request_type || '--',
      rfa.request_title || '--',
      parsePersonName(rfa.submitted_by || rfa.created_by),
      parseResponseBy(rfa.responded_by, rfa.accelerated_at),
      parseDate(rfa.submitted_at),
      parseDate(rfa.reviewed_at),
      parseDueDate(rfa.due_date, rfa.responded_at),
      parseDate(rfa.resolved_at),
      rfa.formatted_status,
      renderRowActions(rfa),
    ];
  });

  return (
    <div className={`rfa-log-table ${rows.length === 0 ? 'empty-table' : ''}`}>
      <div className="rfa-table-container">
        <SimpleTable
          className={'rfas-table'}
          emptyMessage={props.tabNavigation === 1 ? 'No P-RFAs' : 'No RFAs'}
          headers={tableHeaders}
          onRowSelected={onRowSelected}
          actionCells={1}
          rows={rows}
        />
      </div>
      <div className="pagination-container">
        <SimplePagination
          onPageChange={(page) => hfc('page', page)}
          pageSize={currentFilters.pageSize}
          currentPage={currentFilters.page}
          total={total}
        />
      </div>
    </div>
  );
};

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    tabNavigation: getRfaTabNavigation(state),
    currentFilters: getRfaFilters(state),
    total: getTotalRfas(state),
    rfas: getRfas(state),
  };
})(RfaLogTable);
