import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './SearchBar.css';
import { FormControl, FormGroup, InputGroup, Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import searchIconSvg from './searchIcon.svg';
import searchIconSvgDark from './searchIconDark.svg';
import { useDebouncedCallback } from 'use-debounce';

export const TYPING_TIMEOUT = 500;

function SearchBar(props) {
  const [text, setText] = useState(props.searchText);

  const handleSearch = () => {
    if (props.onSearch !== null) {
      props.onSearch(text);
    }
  };

  const debouncedTextChange = useDebouncedCallback((value) => {
    props.onSearchTextChange(value);
  }, TYPING_TIMEOUT);

  const handleValueChange = (event) => {
    const value = event.target.value;
    setText(value);
    if (props.onSearchTextChange !== null) {
      debouncedTextChange(value);
    }
  };

  const icon = props.searchIcon
    ? props.searchIcon
    : props.darkMode
    ? searchIconSvgDark
    : searchIconSvg;

  const {
    title,
    addButton,
    children,
    searchPlaceHolder,
    onSearch,
    darkMode,
    className,
  } = props;

  return (
    <div className={`search-bar ${darkMode ? 'dark-mode' : ''}`}>
      {!!title && <div className="search-bar-title">{title}</div>}
      {children}
      {onSearch !== null && (
        <Navbar.Form className="search-bar-form">
          <FormGroup>
            <InputGroup>
              <FormControl
                className={`search-input ${className}`}
                placeholder={searchPlaceHolder}
                type="text"
                value={text}
                onChange={handleValueChange}
              />
              <InputGroup.Addon
                className="search-button"
                onClick={handleSearch}
              >
                <img src={icon} alt="plus" />
              </InputGroup.Addon>
            </InputGroup>
          </FormGroup>
        </Navbar.Form>
      )}
      {addButton !== null && (
        <div className="search-bar-right-content">{addButton}</div>
      )}
    </div>
  );
}

SearchBar.propTypes = {
  title: PropTypes.string,
  addButton: PropTypes.any, // Button to show next to search input
  searchPlaceHolder: PropTypes.string,
  searchText: PropTypes.string,
  onSearch: PropTypes.func,
  onSearchTextChange: PropTypes.func,
  className: PropTypes.string,
};

SearchBar.defaultProps = {
  title: null,
  addButton: null,
  searchPlaceHolder: 'Search',
  searchText: '',
  onClear: null,
  onSearch: null,
  onSearchTextChange: null,
  className: '',
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(SearchBar);
