import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Grid } from '@mui/material';
import Button, { BUTTON_TYPES } from 'components/shared/button/Button';
import { getDarkMode } from 'selectors/theme';
import cancelSvg from 'images/cancel.svg';
import cancelDarkSvg from 'images/cancel-dark.svg';

import { setProjectPhotosShowForm } from './store/actions';
import { getProjectPhotosShowForm } from './store/selectors';
import ProjectPhotosForm from './ProjectPhotosForm';

const ProjectPhotosFormModal = (props) => {
  const { dispatch, show, darkMode } = props;

  const onClose = () => {
    dispatch(setProjectPhotosShowForm(false));
  };

  return (
    <Drawer
      open={show}
      anchor="right"
      onClose={onClose}
      style={{ zIndex: 1000 }}
      className="project-photos-form-modal"
    >
      <Grid container paddingY={3} paddingX={4} justifyContent={'end'}>
        <Grid item>
          <Button
            onClick={onClose}
            type={BUTTON_TYPES.LINK}
            label={
              <img
                src={darkMode ? cancelDarkSvg : cancelSvg}
                style={{ width: 20, height: 20 }}
                alt="Close"
              />
            }
          />
        </Grid>
      </Grid>
      <ProjectPhotosForm />
    </Drawer>
  );
};

export default connect((state) => ({
  show: getProjectPhotosShowForm(state),
  darkMode: getDarkMode(state),
}))(ProjectPhotosFormModal);
