export const INIT_PROJECT_PROPOSAL = 'INIT_PROJECT_PROPOSAL';
export const TOGGLE_CREATE_PROPOSAL = 'TOGGLE_CREATE_PROPOSAL';
export const TOGGLE_CREATE_PROPOSAL_SUCCESS = 'TOGGLE_CREATE_PROPOSAL_SUCCESS';
export const TOGGLE_PDF_VIEWER_SUCCESS = 'TOGGLE_PDF_VIEWER_SUCCESS';
export const TOGGLE_APPROVE_PROPOSAL_SUCCESS =
  'TOGGLE_APPROVE_PROPOSAL_SUCCESS';
export const PROJECT_PROPOSAL_SET = 'PROJECT_PROPOSAL_SET';
export const PROJECT_PROPOSAL_PDF_SET = 'PROJECT_PROPOSAL_PDF_SET';
export const PROJECT_PROPOSAL_ITEMS_SET = 'PROJECT_PROPOSAL_ADDL_ITEMS_SET';
export const PROJECT_PROPOSAL_ADDL_ITEM_ADD = 'PROJECT_PROPOSAL_ADDL_ITEM_ADD';
export const PROJECT_PROPOSAL_ADDL_ITEM_VALUE_UPDATE =
  'PROJECT_PROPOSAL_ADDL_ITEM_VALUE_UPDATE';
export const PROJECT_PROPOSAL_ITEM_ADD = 'PROJECT_PROPOSAL_ITEM_ADD';
export const PROJECT_PROPOSAL_ITEM_VALUE_UPDATE =
  'PROJECT_PROPOSAL_ITEM_VALUE_UPDATE';
export const PROJECT_PROPOSAL_SUB_ITEM_ADD = 'PROJECT_PROPOSAL_SUB_ITEM_ADD';
export const PROJECT_PROPOSAL_SUB_ITEM_VALUE_UPDATE =
  'PROJECT_PROPOSAL_SUB_ITEM_VALUE_UPDATE';
export const PROJECT_PROPOSAL_FORM_VALUE_UPDATE =
  'PROJECT_PROPOSAL_FORM_VALUE_UPDATE';
export const CSI_CODES_SET = 'CSI_CODES_SET';
export const PROJECT_PROPOSAL_ITEM_DELETE_TOGGLE =
  'PROJECT_PROPOSAL_ITEM_DELETE_TOGGLE';
export const PROJECT_PROPOSAL_ITEMS_RESET = 'PROJECT_PROPOSAL_ITEMS_RESET';
export const PROJECT_PROPOSAL_READ_ONLY_SET = 'PROJECT_PROPOSAL_READ_ONLY_SET';
export const AWARD_RECOMMENDATION_TOGGLE = 'AWARD_RECOMMENDATION_TOGGLE';
export const SET_SHOW_CANCEL_MODAL = 'SET_SHOW_CANCEL_MODAL';
export const SET_SHOW_LOST_MODAL = 'SET_SHOW_LOST_MODAL';
export const SET_DATE_MODAL = 'SET_DATE_MODAL';
export const SET_MESSAGE = 'SET_MESSAGE';
export const INIT_PROJECT_LOST_CANCEL_MODAL = 'INIT_PROJECT_LOST_CANCEL_MODAL';
export const SET_TERMINATION_CATEGORY = 'SET_TERMINATION_CATEGORY';
