import { showErrorMessage } from '../../messages';
import { push } from 'react-router-redux';
import {
  PROJECT_LOCATIONS_LOAD_SUCCESS,
  PROJECT_LOCATIONS_NEW_CLEAR_DATA,
  PROJECT_LOCATIONS_NEW_FORM_VALUE_CHANGE,
  PROJECT_LOCATIONS_NEW_FORM_INVALID,
  PROJECT_LOCATIONS_NEW_FORM_VALID,
  PROJECT_LOCATIONS_PROJECT_LOCATION_LOAD_SUCCESS,
  PROJECT_LOCATIONS_PROJECT_LOCATION_WORK_ORDERS_PAGE_CHANGE,
  PROJECT_LOCATIONS_PROJECT_LOCATION_PROJECTS_LOAD_SUCCESS,
  PROJECT_LOCATIONS_EDIT_FORM_LOAD_FROM_CURRENT,
  PROJECT_LOCATIONS_SET_PAGINATION_DATA,
  PROJECT_LOCATIONS_ADD_PROJECT_LOCATION_TO_BEGINNING,
} from 'actions/admin/project-locations/action-types';
import { projectLocationsApi } from 'services/api/project-locations-api';

export const baseRoutePath = '/admin/project-locations';

export function changePage(newPage) {
  return {
    type: PROJECT_LOCATIONS_PROJECT_LOCATION_WORK_ORDERS_PAGE_CHANGE,
    payload: newPage,
  };
}

export function editProjectLocation(projectLocationId) {
  return (dispatch) => {
    dispatch(push(`${baseRoutePath}/${projectLocationId}/edit`));
  };
}

export function goToProjectLocation(projectLocationId) {
  return (dispatch) => {
    dispatch(push(`${baseRoutePath}/${projectLocationId}`));
  };
}

export function loadProjectLocationDetailsRequest(projectLocationId) {
  return (dispatch) => {
    return projectLocationsApi
      .getProjectLocation(projectLocationId)
      .then((response) => {
        if (response.ok) {
          dispatch(loadProjectLocationSuccess(response.project_location));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadProjectLocationSuccess(projectLocation) {
  return {
    type: PROJECT_LOCATIONS_PROJECT_LOCATION_LOAD_SUCCESS,
    payload: projectLocation,
  };
}

export function loadProjectsSuccess(projects) {
  return {
    type: PROJECT_LOCATIONS_PROJECT_LOCATION_PROJECTS_LOAD_SUCCESS,
    payload: projects,
  };
}

export function loadProjectLocationsRequest(page, clientId, search) {
  return (dispatch) => {
    return projectLocationsApi
      .getProjectLocations(clientId, page, search)
      .then((response) => {
        if (response.ok) {
          dispatch(loadProjectLocationsSuccess(response.project_locations));

          if (page) {
            dispatch(
              setProjectLocationsPaginationData(
                page,
                response.total,
                response.pageSize
              )
            );
          }
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
  };
}

export function loadProjectLocationsSuccess(projectLocations) {
  return {
    type: PROJECT_LOCATIONS_LOAD_SUCCESS,
    payload: projectLocations,
  };
}

export function setProjectLocationsPaginationData(page, total, pageSize) {
  return {
    type: PROJECT_LOCATIONS_SET_PAGINATION_DATA,
    payload: { page: page, total: total, pageSize: pageSize },
  };
}

export function loadProjectsRequest(projectLocationId, page) {
  return (dispatch) => {
    return projectLocationsApi
      .getProjects(projectLocationId, page)
      .then((response) => {
        if (response.ok) {
          dispatch(loadProjectsSuccess(response.projects));
          dispatch(changePage(page));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function newProjectLocation() {
  return (dispatch) => {
    dispatch(clearProjectLocationData());
    dispatch(push(baseRoutePath + '/new'));
  };
}

export function clearProjectLocationData() {
  return {
    type: PROJECT_LOCATIONS_NEW_CLEAR_DATA,
  };
}

export function submitFormRequest(form, projectLocationId) {
  return (dispatch) => {
    // Validate form
    const requiredFields = ['streetAddress', 'city', 'state'];
    const invalidFields = [];
    requiredFields.forEach((attribute) => {
      if (form[attribute] === '') {
        invalidFields.push(attribute);
      }
    });

    if (invalidFields.length > 0) {
      dispatch(
        invalidForm(
          invalidFields,
          'The outlined inputs should be added in order to add a project location.'
        )
      );
    } else {
      dispatch(validForm());

      const data = createProjectLocationDataObject(form);
      let promise;
      if (projectLocationId !== undefined) {
        promise = projectLocationsApi.updateProjectLocation(
          projectLocationId,
          data
        );
      } else {
        promise = projectLocationsApi.createProjectLocation(data);
      }

      return promise.then((response) => {
        if (response.ok) {
          if (projectLocationId !== undefined) {
            dispatch(loadProjectLocationSuccess(response.project_location));
            dispatch(goToProjectLocation(projectLocationId));
          } else {
            dispatch(push(baseRoutePath));
          }
        } else {
          dispatch(showErrorMessage(response.errors));
        }
      });
    }
  };
}

export function createProjectLocationDataObject(form) {
  return {
    name: form.projectLocationName,
    location: {
      id: form.locationId,
      streetAddress: form.streetAddress,
      unitNumber: form.unitNumber,
      city: form.city,
      state: form.state,
      zipCode: form.zipCode,
    },
  };
}

export function invalidForm(invalidFields, message) {
  return {
    type: PROJECT_LOCATIONS_NEW_FORM_INVALID,
    payload: { invalidFields, message },
  };
}

export function validForm() {
  return {
    type: PROJECT_LOCATIONS_NEW_FORM_VALID,
  };
}

export function updateFormFromCurrentProjectLocation(currentProjectLocation) {
  return {
    type: PROJECT_LOCATIONS_EDIT_FORM_LOAD_FROM_CURRENT,
    payload: currentProjectLocation,
  };
}

export function updateFormValue(attribute, value) {
  return {
    type: PROJECT_LOCATIONS_NEW_FORM_VALUE_CHANGE,
    payload: { attribute, value },
  };
}

export function addProjectLocationToBeginning(projectLocation) {
  return {
    type: PROJECT_LOCATIONS_ADD_PROJECT_LOCATION_TO_BEGINNING,
    payload: projectLocation,
  };
}
