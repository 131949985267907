import React, { Component } from 'react';
import './HeaderNav.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { getCurrentUser } from '../../selectors/authentication';
import { getDarkMode } from '../../selectors/theme';
import { getActiveRoute } from '../../selectors/admin/sidebar/sidebar';
import GlobalPermissions from 'permissions/global-permissions';
import Menu from 'components/shared/menu/Menu';
import ProjectsMenu from './ProjectsMenu';

class HeaderNav extends Component {
  constructor(props) {
    super(props);

    const { currentUser } = props;

    this.state = {
      activeNavItem: '',
    };

    this.defaultRoute = '/dashboard';

    this.items = [
      {
        title: 'Dashboard',
        value: 'dashboard',
        route: 'dashboard',
        show: GlobalPermissions.canViewDashboardPage(currentUser),
      },
      {
        title: 'Projects',
        value: 'projects',
        route: 'projects',
        menu: this.renderProjectsMenu,
        show: GlobalPermissions.canViewProjectsPage(currentUser),
      },
      {
        title: 'My Company',
        value: 'team',
        route: 'team',
        show: GlobalPermissions.canViewTeamPage(props.currentUser),
      },
      {
        title: 'Directory',
        value: 'directory',
        show: GlobalPermissions.canViewDirectoryPage(currentUser),
        menu: [
          {
            label: 'Companies',
            route: 'companies',
            icon: 'companies',
            show: GlobalPermissions.canViewCompaniesPage(props.currentUser),
          },
          {
            label: 'Users',
            route: 'users',
            icon: 'users',
            show: GlobalPermissions.canViewUsersPage(props.currentUser),
          },
          {
            label: 'Project Locations',
            route: 'project-locations',
            icon: 'project-locations',
            show: GlobalPermissions.canViewProjectLocationsPage(
              props.currentUser
            ),
          },
        ].filter((item) => item.show),
      },
      {
        title: 'Admin',
        value: 'superadmin',
        route: 'superadmin',
        show: false,
      },
    ].filter((item) => item.show && (!item.menu || item.menu.length > 0));

    this.routes = this.items.reduce((result, item) => {
      if (item.route) {
        result[item.route] = { item: item };
      }
      if (item.menu && typeof item.menu === 'object') {
        result = item.menu.reduce((result, subitem) => {
          result[subitem.route] = { item: subitem, parent: item };
          return result;
        }, result);
      }
      return result;
    }, {});
  }

  static propTypes = {
    currentUser: PropTypes.shape({}).isRequired,
  };

  menuItemIsActive = (pattern) => {
    return new RegExp(pattern, 'gi').test(this.props.activeRoute);
  };

  activeItem = (item) => {
    return (
      (item.route && this.menuItemIsActive(item.route)) ||
      (item.menu &&
        typeof item.menu === 'object' &&
        item.menu.some((subitem) => this.menuItemIsActive(subitem.route)))
    );
  };

  handleGenerateLink = (id) => {
    return `${id}`;
  };

  handleItemClick = (item, parent) => {
    this.setState({ activeNavItem: parent ? parent.value : item.value });
    this.props.dispatch(push(`/admin/${this.handleGenerateLink(item.route)}`));
  };

  componentDidMount() {
    const activeRoute = this.routes[this.props.activeRoute];
    const activeItem = activeRoute && activeRoute.parent;

    const isServiceWork = activeItem && activeItem.value === 'serviceWork';
    const isDirectory = activeItem && activeItem.value === 'directory';

    this.setState({
      activeNavItem: isServiceWork
        ? 'serviceWork'
        : isDirectory
        ? 'directory'
        : this.props.activeRoute,
    });
  }

  renderItemMenuSelector = (rootItem) => (
    <span
      className={`headerNavItem menuItem ${
        this.activeItem(rootItem) ? 'active' : ''
      }`}
    >
      {rootItem.title}
    </span>
  );

  renderProjectsMenu = (rootItem, index) => {
    return (
      <ProjectsMenu
        key={index}
        selector={this.renderItemMenuSelector(rootItem)}
        onClick={(filter) =>
          this.handleItemClick({
            ...rootItem,
            route: `${rootItem.route}?status=${filter}`,
          })
        }
      />
    );
  };

  renderItemWithMenu = (rootItem, index) => {
    const menuItems = rootItem.menu.map((subitem) => ({
      content: subitem.label,
      onClick: () => this.handleItemClick(subitem, rootItem),
    }));

    return (
      <Menu
        className="item-with-menu"
        key={index}
        selector={this.renderItemMenuSelector(rootItem)}
        items={menuItems}
      />
    );
  };

  renderSingleItem = (item, index) => {
    return (
      /* eslint-disable jsx-a11y/anchor-is-valid */
      <a
        className={`headerNavItem singleItem ${
          this.activeItem(item) ? 'active' : ''
        }`}
        key={index}
        onClick={() => {
          this.handleItemClick(item);
        }}
      >
        {item.title}
      </a>
    );
  };

  render() {
    const { darkMode } = this.props;

    return (
      <div className={`header-nav ${darkMode ? 'dark-mode' : ''}`}>
        {this.items.map((item, index) =>
          !item.menu
            ? this.renderSingleItem(item, index)
            : typeof item.menu === 'function'
            ? item.menu(item, index)
            : this.renderItemWithMenu(item, index)
        )}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    activeRoute: getActiveRoute(state),
    darkMode: getDarkMode(state),
  };
})(HeaderNav);
