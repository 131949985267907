import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  COMPANY_TYPES,
  COMPANY_TYPES_SORTED,
  COMPANY_TYPE_LABELS,
} from 'domain/company-type-enum';
import FormControlBlock, {
  CONTROL_SIZE,
} from 'components/shared/form/FormControlBlock';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import { getCurrentReportViewProjectUsage } from './store/selectors';
import { setReportViewFormValue } from './store/actions';

class ReportProjectUsage extends Component {
  constructor(props) {
    super(props);

    const availableUserTypes = [
      COMPANY_TYPES.OWNER_AUTHORIZED_REP,
      COMPANY_TYPES.GENERAL_CONTRACTOR,
      COMPANY_TYPES.CLIENT,
      COMPANY_TYPES.ARCHITECT,
      COMPANY_TYPES.CONSULTANT,
      COMPANY_TYPES.LANDLORD,
      COMPANY_TYPES.PROPERTY_MANAGER,
      COMPANY_TYPES.CONTRACTOR,
    ];
    this.userTypeCountOptions = COMPANY_TYPES_SORTED.filter((userType) =>
      availableUserTypes.includes(userType)
    ).map((userType) => ({
      id: userType,
      label: COMPANY_TYPE_LABELS[userType],
    }));
  }

  handleFormChange = (attribute, value) => {
    const { dispatch } = this.props;
    dispatch(setReportViewFormValue('project_usage', attribute, value));
  };

  render() {
    const {
      currentReportViewProjectUsage: {
        total_users_count,
        primary_users_count,
        user_types_counts,
        meetings_count,
        documents_count,
        documents_size,
        rfis_count,
        submittals_count,
        daily_reports_count,
        sub_daily_reports_count,
        closeout_files_size,
        project_files_size,
        emails_sent_count,
      },
    } = this.props;

    return (
      <React.Fragment>
        <div className="sidebar-form-body-subtitle">Project Data</div>
        <div className="sidebar-form-description">
          Select additional information to include about each Project
        </div>

        <div className="form-block">
          <FormControlBlock
            label={!!user_types_counts.length > 0 && 'User Type Count'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="User Type Count"
                currentValues={user_types_counts}
                onChange={(values) =>
                  this.handleFormChange('user_types_counts', values)
                }
                search={false}
                options={this.userTypeCountOptions}
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('primary_users_count', value)
                }
                checked={primary_users_count}
                label="Total Primary Users"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('total_users_count', value)
                }
                checked={total_users_count}
                label="Total Users"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('emails_sent_count', value)
                }
                checked={emails_sent_count}
                label="Total Emails Sent"
                position="left"
              />
            }
          />
        </div>

        <div className="form-block">
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('project_files_size', value)
                }
                checked={project_files_size}
                label="Total Data Usage"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('meetings_count', value)
                }
                checked={meetings_count}
                label="Total Meeting Minuets"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('documents_count', value)
                }
                checked={documents_count}
                label="Total Documents"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('documents_size', value)
                }
                checked={documents_size}
                label="Total Document Data Size"
                position="left"
              />
            }
          />
        </div>

        <div className="form-block">
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={
              <LabeledCheckBox
                onChange={(value) => this.handleFormChange('rfis_count', value)}
                checked={rfis_count}
                label="Total RFIs"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('submittals_count', value)
                }
                checked={submittals_count}
                label="Total Submittals"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('sub_daily_reports_count', value)
                }
                checked={sub_daily_reports_count}
                label="Total Sub Daily Reports"
                position="left"
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('daily_reports_count', value)
                }
                checked={daily_reports_count}
                label="Total Daily Reports"
                position="left"
              />
            }
          />
        </div>

        <div className="form-block">
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormChange('closeout_files_size', value)
                }
                checked={closeout_files_size}
                label="Total Closeout Data Size"
                position="left"
              />
            }
          />
          <FormControlBlock size={CONTROL_SIZE.BIG} />
          <FormControlBlock size={CONTROL_SIZE.BIG} />
          <FormControlBlock size={CONTROL_SIZE.BIG} />
        </div>
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    currentReportViewProjectUsage: getCurrentReportViewProjectUsage(state),
  };
})(ReportProjectUsage);
