import {
  WORK_ORDERS_WO_ADD,
  WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_HIDE,
  WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_NEW,
  WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_SHOW,
  WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_SUCCESS,
} from '../../../../actions/admin/work-orders/action-types';

const initialState = {
  showCancel: false,
};

export default function tasksUiReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_WO_ADD:
      return {
        ...state,
        showCancel: false,
      };

    case WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_SHOW:
      return {
        ...state,
        showCancel: true,
      };

    case WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_HIDE:
      return {
        ...state,
        showCancel: false,
      };

    case WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_NEW:
    case WORK_ORDERS_CREATION_TASKS_TASK_CANCEL_SUCCESS:
      return {
        ...state,
        showCancel: false,
      };

    default:
      return state;
  }
}
