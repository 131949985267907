import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import FormModal from 'components/shared/modal/FormModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import SubmittalPermissions from 'permissions/submittal-permissions';
import Submittal from 'domain/submittal';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import SubmittalDocuments from 'components/admin/projects/details/project-submittal-log/SubmittalDocuments';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import ReadOnlyInput from 'components/shared/input/ReadOnlyInput';
import {
  SubmittalStatusEnum,
  SubmittalStatusKeys,
} from 'domain/submittal-status-enum';
import './SubmittalResponseModal.css';
import SubmittalStamp from './SubmittalStamp';
import {
  getSubmittalResponseModalShow,
  getSubmittalReadOnly,
} from './store/selectors';
import {
  setSubmittalResponseModalShow,
  respondSubmittal,
  setSetShowSubmittalErrorMessage,
  setSubmittalReadOnly,
  respondSubmittalAddlAssignee,
  setSubmittalFormErrors,
  setSubmittalCreatedRevisionModalShow,
  setSubmittalModalShow,
  setSubmittalGcAcceleration,
} from './store/actions';

export const SubmittalResponseModal = (props) => {
  const {
    form,
    canAddRemarks,
    currentUser,
    addlAssignees,
    readOnly,
    submittal,
    makeAssignedLabel,
    makeResponseLabel,
    handleFormChange,
    handleFormDeleteFile,
    show,
    project,
    directory,
    dispatch,
  } = props;

  useEffect(() => {
    if (canAccelerate && !submittal.stamp_action) {
      dispatch(setSubmittalGcAcceleration(true));
      dispatch(setSubmittalReadOnly(false));
    }
  }, []);
  const handleClose = () => {
    dispatch(setSubmittalReadOnly(true));
    dispatch(setSubmittalResponseModalShow(false));
  };

  const handleCloseAll = () => {
    dispatch(setSubmittalResponseModalShow(false));
    dispatch(setSubmittalModalShow(false));
  };
  const isAddl = addlAssignees.some((a) => a.assignee_id === currentUser.id);
  const addlAssignee = isAddl
    ? addlAssignees.filter((a) => a.assignee_id === currentUser.id)[0]
    : null;
  const isMainAssignee = form.assignee_id === currentUser.id;

  const canAccelerate =
    SubmittalPermissions.canAccelerate(
      currentUser,
      project,
      directory,
      submittal,
      form
    ) && !isAddl;

  const handleCreated = (_response) => {
    const { form, dispatch } = props;

    if (form.newRevision) {
      dispatch(setSubmittalCreatedRevisionModalShow(true));
    } else {
      handleCloseAll();
    }
  };
  const formStatus = (draft = false) => {
    const { submittal, form } = props;
    const status = submittal && SubmittalStatusEnum.create(submittal.status);
    let newStatus = form.status;
    if (draft) {
      if (!status || SubmittalStatusEnum.DRAFT.equals(status)) {
        newStatus = SubmittalStatusKeys.DRAFT;
      } else if (
        SubmittalStatusEnum.PENDING.equals(status) ||
        SubmittalStatusEnum.DRAFT_PENDING.equals(status)
      ) {
        newStatus = SubmittalStatusKeys.DRAFT_PENDING;
      } else if (
        SubmittalStatusEnum.ASSIGNED.equals(status) ||
        SubmittalStatusEnum.DRAFT_ASSIGNED.equals(status)
      ) {
        newStatus = SubmittalStatusKeys.DRAFT_ASSIGNED;
      }
    } else {
      if (newStatus === 'for_record') {
        newStatus = SubmittalStatusKeys.FOR_RECORD;
      } else {
        newStatus =
          form.stampAction ||
          (form.assignee.length === 0
            ? SubmittalStatusKeys.PENDING
            : SubmittalStatusKeys.ASSIGNED);
      }
    }

    handleFormChange('status', newStatus);
    return newStatus;
  };

  const validateForm = (draft = false) => {
    const {
      currentUser,
      project,
      directory,
      submittal,
      gcAcceleration,
      form,
      dispatch,
    } = props;

    const errors = {};
    const status = SubmittalStatusEnum.create(form.status);
    const isResponse = Submittal.isUserResponse(
      currentUser,
      submittal,
      gcAcceleration
    );
    const canEdit = SubmittalPermissions.canEdit(
      currentUser,
      project,
      directory,
      submittal
    );

    if (canEdit) {
      if (!form.csiCodeId) {
        errors.csiCodeId = true;
      }
      if (!draft) {
        if (!form.dueDate && !SubmittalStatusEnum.FOR_RECORD.equals(status)) {
          errors.dueDate = true;
        }
        if (!form.status) {
          errors.status = true;
        }
        if (!form.title) {
          errors.title = true;
        }
        if (!form.question) {
          errors.question = true;
        }
      }
    }
    if (!draft && isResponse && !form.stampAction) {
      errors.stampAction = true;
    }
    dispatch(setSubmittalFormErrors(errors));
    return Object.keys(errors).length === 0;
  };

  const validate = (draft = false) => {
    return validateForm(draft);
  };
  const handleSubmit = (draft = false, closed = false) => {
    const { project, form, dispatch } = props;

    if (!validate(draft)) {
      dispatch(setSetShowSubmittalErrorMessage(true));
      return;
    }

    const status = SubmittalStatusEnum.create(formStatus(draft));
    const submittal = {
      ...form,
      status: status.status,
      assigneeId: form.assignee[0] || '',
      recipientIds: form.recipients.map((recpt) => recpt[0]),
      requestedAt: form.requestedDate ? moment(form.requestedDate) : '',
      closed:
        !!form.closed ||
        closed ||
        !!(canAccelerate && form.stampAction) ||
        false,
      remarks: form.remarks || '',
      stampAction: form.stampAction || '',
    };

    let promise;

    if (form.id && !form.newRevision) {
      if (addlAssignee) {
        promise = dispatch(
          respondSubmittalAddlAssignee(addlAssignee.id, submittal)
        );
      } else {
        promise = dispatch(respondSubmittal(project.id, form.id, submittal));
      }
    }

    promise.then((response) => {
      if (response.ok) {
        if (canAccelerate && !isAddl) {
          dispatch(respondSubmittal(project.id, form.id, submittal)).then(
            (response) => {
              if (response.ok) {
                handleCreated(response);
              }
            }
          );
        } else {
          handleCreated(response);
        }
      }
    });
  };

  const handleSubmitAndClose = (draft = false, closed = false) => {
    const { project, form, dispatch } = props;

    if (!validate(draft)) {
      dispatch(setSetShowSubmittalErrorMessage(true));
      return;
    }

    const status = SubmittalStatusEnum.create(formStatus(draft));
    const submittal = {
      ...form,
      status: status.status,
      assigneeId: form.assignee[0] || '',
      recipientIds: form.recipients.map((recpt) => recpt[0]),
      requestedAt: form.requestedDate ? moment(form.requestedDate) : '',
      closed: true,
      remarks: form.remarks || '',
      stampAction: form.stampAction || '',
    };

    let promise;

    if (form.id && !form.newRevision) {
      if (addlAssignee) {
        promise = dispatch(
          respondSubmittalAddlAssignee(addlAssignee.id, submittal)
        );
      } else {
        promise = dispatch(respondSubmittal(project.id, form.id, submittal));
      }
    }

    promise.then((response) => {
      if (response.ok) {
        if (canAccelerate && !isAddl) {
          dispatch(respondSubmittal(project.id, form.id, submittal)).then(
            (response) => {
              if (response.ok) {
                handleCreated(response);
              }
            }
          );
        } else {
          handleCreated(response);
        }
      }
    });
  };

  return (
    <FormModal
      show={show}
      onClose={handleClose}
      className="submittal-response-modal"
    >
      <DetailsTitle
        className="submittal-response-title official-response"
        title={`${isMainAssignee ? 'Official' : 'Contributor'} Response`}
        onClose={handleClose}
      />
      <div className="submittal-response-modal-content-container">
        {(isMainAssignee || canAccelerate) && (
          <>
            <div className="form-block">
              {form.responded_by ? (
                <FormControlBlock
                  className="highlighted"
                  label="Responded By"
                  control={makeResponseLabel(
                    form.responded_by,
                    form.returned_at
                  )}
                />
              ) : (
                <FormControlBlock
                  className="highlighted"
                  label="Assigned To"
                  control={makeAssignedLabel(form.assignee)}
                />
              )}
            </div>
            {form.stamp_title && (
              <div className="form-block">
                <FormControlBlock
                  label="Stamp"
                  control={<ReadOnlyInput value={form.stamp_title} />}
                />
              </div>
            )}
            <div className="form-block">
              <FormControlBlock
                label={(!canAddRemarks || !!form.remarks) && 'Remarks'}
                control={
                  <TextareaAutosizeWrapper
                    placeholder="Remarks"
                    disabled={readOnly || !(isMainAssignee || canAccelerate)}
                    initialValue={form.remarks || (!canAddRemarks ? '-' : '')}
                    onChange={(value) => handleFormChange('remarks', value)}
                  />
                }
              />
            </div>
            <SubmittalDocuments
              currentUser={currentUser}
              submittal={submittal}
              form={form}
              fileType="res"
              handleFormChange={handleFormChange}
              handleFormDeleteFile={handleFormDeleteFile}
              readOnly={readOnly || !(isMainAssignee || canAccelerate)}
            />
            {(isMainAssignee || canAccelerate) && (
              <SubmittalStamp currentUser={currentUser} submittal={submittal} />
            )}
          </>
        )}
        {isAddl && addlAssignee && (
          <React.Fragment key={'addl-fragment'}>
            <div className="form-block">
              {addlAssignee.stamp_action ? (
                <FormControlBlock
                  className="highlighted"
                  label="Responded By"
                  control={makeResponseLabel(
                    addlAssignee.assignee,
                    addlAssignee.returned_at
                  )}
                />
              ) : (
                <FormControlBlock
                  className="highlighted"
                  label="Assigned To"
                  control={makeAssignedLabel(addlAssignee.assignee)}
                />
              )}
            </div>
            {addlAssignee.stamp_title && (
              <div className="form-block">
                <FormControlBlock
                  label="Stamp"
                  control={<ReadOnlyInput value={addlAssignee.stamp_title} />}
                />
              </div>
            )}
            <SubmittalDocuments
              currentUser={currentUser}
              submittal={submittal}
              submittalAddlAssignee={addlAssignee}
              form={form}
              fileType="res"
              handleFormChange={handleFormChange}
              handleFormDeleteFile={handleFormDeleteFile}
              readOnly={readOnly || addlAssignee.assignee_id !== currentUser.id}
            />
          </React.Fragment>
        )}
        {isAddl && (
          <SubmittalStamp currentUser={currentUser} submittal={submittal} />
        )}
      </div>
      <div className="submittal-response-buttons">
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.WHITE}
          label="Cancel"
          onClick={handleClose}
        />
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          label="Submit"
          onClick={() => handleSubmit()}
        />
        {submittal && !submittal.closed && (
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label="Submit&Close"
            onClick={() => handleSubmitAndClose()}
          />
        )}
      </div>
    </FormModal>
  );
};

export default connect((state) => {
  return {
    show: getSubmittalResponseModalShow(state),
    readOnly: getSubmittalReadOnly(state),
  };
})(SubmittalResponseModal);
