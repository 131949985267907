import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './RfiResponse.css';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import RfiDocuments from 'components/admin/projects/details/project-rfi-log/RfiDocuments';
import { getDarkMode } from 'selectors/theme';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import moment from 'moment';
import User from 'domain/user';

class RfiResponse extends Component {
  static propTypes = {
    questionKey: PropTypes.string.isRequired,
    question: PropTypes.shape({}).isRequired,
    questionAddlAssignee: PropTypes.shape({}),
    currentUser: PropTypes.shape({}).isRequired,
    contribType: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    gcAcceleration: PropTypes.bool,
    handleResponseChange: PropTypes.func.isRequired,
    handleQuestionDeleteFile: PropTypes.func.isRequired,
  };

  isAssigned = () => {
    const { question, questionAddlAssignee, currentUser } = this.props;

    return questionAddlAssignee
      ? questionAddlAssignee.assignee_id === currentUser.id
      : question.assignee[0] === currentUser.id;
  };

  makeResponseLabel = () => {
    const { question, questionAddlAssignee } = this.props;

    let userName, respondedAt;
    const responded_by = question.responded_by;
    if (questionAddlAssignee) {
      userName = questionAddlAssignee.assignee_label;
      respondedAt = questionAddlAssignee.responded_at;
    } else if (responded_by) {
      const company = responded_by.accountable;
      userName = User.fullNameWithCompany({
        ...responded_by,
        accountable: company,
      });
      respondedAt = question.responded_at;
    } else {
      userName = 'User Deleted';
    }

    return (
      <span className="rfi-response-label small-regular">
        {`${userName} on ${moment(respondedAt).format(
          'MM/DD/YYYY'
        )} at ${moment(respondedAt).format('hh:mm A')}`}
      </span>
    );
  };

  makeAssignedLabel = () => {
    const { question, questionAddlAssignee } = this.props;

    let userName = questionAddlAssignee
      ? questionAddlAssignee.assignee_label
      : question.assignee_label || 'Not assigned.';

    return <span className="rfi-response-label small-regular">{userName}</span>;
  };

  render() {
    const {
      questionKey,
      question,
      questionAddlAssignee,
      gcAcceleration,
      readOnly,
      handleResponseChange,
      handleQuestionDeleteFile,
      darkMode,
    } = this.props;

    const isAssigned = this.isAssigned();

    const showActions =
      !readOnly &&
      !!question.id &&
      (isAssigned || (gcAcceleration && !questionAddlAssignee));

    const response = questionAddlAssignee
      ? questionAddlAssignee.response
      : question.officialResponse;

    return (
      <div className={`rfi-response ${darkMode ? 'dark-mode' : ''}`}>
        <div className="form-block">
          {response ? (
            <FormControlBlock
              className="highlighted"
              label="Responded By"
              control={this.makeResponseLabel()}
            />
          ) : (
            <FormControlBlock
              className="highlighted"
              label="Assigned To"
              control={this.makeAssignedLabel()}
            />
          )}
        </div>
        <div className="form-block">
          <FormControlBlock
            label={(!showActions || !!response) && 'Response'}
            control={
              <TextareaAutosizeWrapper
                placeholder={
                  !showActions
                    ? 'N/A – no response has been submitted yet.'
                    : 'Response'
                }
                initialValue={response}
                valid={!showActions || !question.errors.response}
                disabled={!showActions}
                onChange={(value) =>
                  handleResponseChange(questionKey, 'response', value)
                }
              />
            }
          />
        </div>
        <RfiDocuments
          questionKey={questionKey}
          question={question}
          questionAddlAssignee={questionAddlAssignee}
          readOnly={!showActions}
          fileType="res"
          handleQuestionChange={handleResponseChange}
          handleQuestionDeleteFile={handleQuestionDeleteFile}
        />
      </div>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(RfiResponse);
