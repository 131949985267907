import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import useWindowDragAndDrop from 'components/shared/drag-and-drop/WindowDragAndDrop.hook';

import ProjectPhotoPermissions from 'permissions/project-photo-permissions';

import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getProjectPhotosCurrentAlbum,
  getProjectPhotosShowAlbumForm,
} from './store/selectors';
import { setProjectPhotosShowUpload } from './store/actions';

const UploadPhotosButton = (props) => {
  const {
    dispatch,
    currentAlbum,
    currentUser,
    currentProject,
    showAlbumModal,
  } = props;
  const canUpload = ProjectPhotoPermissions.canAddItem(
    currentUser,
    currentProject,
    currentAlbum
  );

  const { dragging } = useWindowDragAndDrop({
    disabled: !canUpload || showAlbumModal,
  });

  useEffect(() => {
    if (dragging) dispatch(setProjectPhotosShowUpload(true));
  }, [dragging]);

  const handleUpload = () => {
    dispatch(setProjectPhotosShowUpload(true));
  };

  if (!canUpload) return null;

  return (
    <Button
      key="upload-photos"
      className="upload-photos-button"
      type={BUTTON_TYPES.LINK}
      icon={BUTTON_ICONS.UPLOAD_GREEN}
      label="Upload"
      onClick={handleUpload}
    />
  );
};

export default connect((state) => ({
  showAlbumModal: getProjectPhotosShowAlbumForm(state),
  currentAlbum: getProjectPhotosCurrentAlbum(state),
  currentUser: getCurrentUser(state),
  currentProject: getCurrentProject(state),
}))(UploadPhotosButton);
