import React, { Component } from 'react';
import './Reports.css';
import { goToWorkOrder } from 'actions/admin/work-orders/work-orders';
import Number from 'components/shared/text/Number';
import BaseReport from 'components/admin/reports/BaseReport';
import { connect } from 'react-redux';
import { getReportResults } from 'selectors/admin/reports/reports';
import numeral from 'numeral';

class WorkOrderVarianceReport extends Component {
  handleRowSelection = (index) => {
    this.props.dispatch(goToWorkOrder(this.props.reportResults.data[index].id));
  };

  createTableRowsData = () => {
    if (this.props.reportResults !== null) {
      return this.props.reportResults.data.map((result, index) => {
        const woAmount = numeral(result.wo_amount).value();
        const additionalCost =
          numeral(result.total_value).value() +
          numeral(result.total_markup).value() +
          numeral(result.total_fee).value() +
          numeral(result.total_insurance).value();
        const contractorCoCost = numeral(
          result.contractor_change_orders_cost
        ).value();
        const revenue = additionalCost - contractorCoCost;
        const variance =
          woAmount === 0 ? 0 : Math.round((revenue * 100) / woAmount);
        const rowData = {
          id: index,
          selectable: true,
          data: [
            result.number,
            result.name,
            result.client.name,
            <strong>
              <Number value={woAmount} format="$0,0" />
            </strong>,
            <strong>
              <Number value={woAmount - revenue} format="$0,0" />
            </strong>,
            <strong>
              <Number value={revenue} format="$0,0" />
            </strong>,
            <Number value={result.total_value} format="$0,0" />,
            <Number value={result.total_markup} format="$0,0" />,
            <Number value={result.total_fee} format="$0,0" />,
            <Number value={result.total_insurance} format="$0,0" />,
            `${variance}%`,
          ],
        };
        return {
          first: true,
          rowData,
          workOrderId: result.id,
        };
      });
    } else {
      return [];
    }
  };

  render() {
    return (
      <BaseReport
        reportName="work_order_variance_report"
        tableHeader={[
          'WO #',
          'NAME',
          'OWNER NAME',
          'WO AMOUNT',
          'ACTUAL COST',
          'REVENUE',
          'VALUE',
          'MARKUP',
          'FEE',
          'INSURANCE',
          'VARIANCE',
        ]}
        tableRowsData={this.createTableRowsData()}
        title="Work Order Variance"
        onRowSelection={this.handleRowSelection}
      />
    );
  }
}

export default connect((state) => {
  return {
    reportResults: getReportResults(state),
  };
})(WorkOrderVarianceReport);
