import React from 'react';
import { useTable } from 'react-table';
import MaUTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const cellStyle = {
  padding: '15px 8px',
  fontSize: '14px',
  boxSizing: 'border-box',
  fontFamily: 'Titillium Web',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};
const leftPadding = '40px';

function BasicTable(props) {
  const { rowsData, headersData, handleRowClick } = props;

  const readyHeaders = headersData.map((el, i) => ({
    header: el,
    accessor: `col${i}`,
  }));

  const readyRows = rowsData.map((row, i) => {
    if (Array.isArray(row)) {
      return row.reduce((acc, current, i) => {
        acc[`col${i}`] = current;
        return acc;
      }, {});
    } else if (typeof row === 'object' && row !== null) {
      return { col0: row.data[0] };
    }
    return row;
  });

  const data = React.useMemo(() => readyRows, [rowsData]);

  const columns = React.useMemo(() => readyHeaders, [headersData]);

  const getCellWidth = (i, arrLength) => {
    if (i === 0) {
      return '8%';
    } else if (i === arrLength - 1) {
      return '6%';
    }
    return `${86 / arrLength - 2}%`;
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <MaUTable
      {...getTableProps()}
      style={{ width: '100%', tableLayout: 'fixed' }}
    >
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i, arr) => (
              <TableCell
                {...column.getHeaderProps({
                  style: {
                    ...cellStyle,
                    fontWeight: 'bold',
                    width: getCellWidth(i, arr.length),
                    paddingLeft: i ? '0' : leftPadding,
                  },
                })}
              >
                {column.render('header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow
              {...row.getRowProps()}
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                handleRowClick(row.id, props.isSelectable, e);
              }}
            >
              {row.cells.map((cell, i) => {
                return (
                  <TableCell
                    {...cell.getCellProps({
                      style: {
                        ...cellStyle,
                        paddingLeft: i ? '0' : leftPadding,
                      },
                    })}
                  >
                    {cell.value}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </MaUTable>
  );
}
export default BasicTable;
