import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Grid, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loader from 'components/shared/loader/LoginLoader';
import './UserInvitations.css';
import { getLoginBackgroundImage } from '../../../actions/loginBackgroundImage';
import logoPng from '../login/logo.png';
import AddInvitation from './AddInvitation';
import ShowInvitation from './ShowInvitation';
import { browserClassname } from 'services/utils/browser-util';
import SupportLink from '../SupportLink';

export const InvitationPropTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      selected: PropTypes.bool,
    })
  ),
  title: PropTypes.string,
};

class UserInvitations extends Component {
  static propTypes = {
    invitations: PropTypes.arrayOf(PropTypes.shape(InvitationPropTypes)),
    currentInvitation: PropTypes.shape(InvitationPropTypes),
    onAdd: PropTypes.func,
    onSent: PropTypes.func,
    onCurrentInvitationChange: PropTypes.func,
    onRemove: PropTypes.func,
  };

  constructor() {
    super();
    this.state = {
      addEnable: false,
      loadImage: true,
      loadImageError: false,
    };
  }

  componentDidMount() {
    if (!this.props.imageUrl) {
      this.props.getBackgroundImage();
      this.setState({
        loadImage: false,
        loadImageError: false,
      });
    }
  }

  componentDidUpdate() {
    if (
      (this.props.imageUrl || this.props.imageUrl === '') &&
      !this.state.loadImage
    ) {
      const imageLoader = new Image();
      imageLoader.src = this.props.imageUrl;
      imageLoader.onload = () => {
        this.setState({
          loadImage: true,
          loadImageError: false,
        });
      };

      imageLoader.onerror = () => {
        this.setState({
          loadImage: true,
          loadImageError: true,
        });
      };
    }
  }

  handleInvitationRemove = (id) => {
    this.props.onRemove(id);
  };

  handleAddUser = () => {
    if (this.state.addEnable) {
      this.props.onAdd({
        firstName: this.props.currentInvitation.firstName,
        lastName: this.props.currentInvitation.lastName,
        email: this.props.currentInvitation.email,
        permissions: this.props.currentInvitation.permissions,
        title: this.props.currentInvitation.title,
      });

      this.setState({
        addEnable: false,
      });
    }
  };

  handleAddInvitationChange = (newState) => {
    let addEnable =
      newState.firstName !== '' &&
      newState.lastName !== '' &&
      newState.email !== '' &&
      newState.permissions.filter((element) => {
        return element.selected;
      }).length > 0;

    this.setState({
      addEnable: addEnable,
    });

    this.props.onCurrentInvitationChange(newState);
  };

  handleSentInvitations = () => {
    if (this.props.invitations.length > 0) {
      this.props.onSent();
    }
  };

  render() {
    const backgroundImage =
      !this.state.loadImageError && this.props.imageUrl
        ? { backgroundImage: 'url(' + this.props.imageUrl + ')' }
        : {};
    return this.state.loadImage ? (
      <div className="user-invitations client" style={backgroundImage}>
        <Grid className="max-height">
          <div className="align-center">
            <Col md={9}>
              <div
                className={browserClassname({
                  firefox: 'form-container-firefox',
                  default: 'form-container',
                })}
              >
                <Col md={7} sm={12} className="left-panel">
                  <Row>
                    <Col md={12}>
                      <div className="logo-invite">
                        <img src={logoPng} alt="Logo" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <span className="title-1">Invite Users</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <div className="subtitle">
                        <span>
                          Users will be emailed an invite to finish creating
                          their account.
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      {this.props.invitations.map((invitation) => (
                        <ShowInvitation
                          user={invitation}
                          onDelete={this.handleInvitationRemove}
                          key={invitation.id}
                        />
                      ))}
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <AddInvitation
                        invitation={this.props.currentInvitation}
                        onChange={this.handleAddInvitationChange}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mobile-container-button-invite">
                      <div className="buttons">
                        <Button
                          className={`btn-continue ${
                            !this.state.addEnable && 'button-disabled'
                          }`}
                          onClick={this.handleAddUser}
                          id="add-user"
                          style={{ marginBottom: 20 }}
                        >
                          <span className="text-btn">Add additional user</span>
                          <span className="plus" />
                        </Button>

                        <Button
                          className={`btn-continue ${
                            this.props.invitations.length === 0 &&
                            'button-disabled'
                          }`}
                          onClick={this.handleSentInvitations}
                          id="invite-users"
                        >
                          <span className="text-btn">Invite Users</span>
                          <span className="arrow" />
                        </Button>

                        <div className="bottom-title">
                          <span>
                            <Link to="/" className="link-later">
                              I&#39;ll do it later
                            </Link>
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} mdOffset={3}>
                      <SupportLink />
                    </Col>
                  </Row>
                </Col>
                <Col md={5} sm={12} className="right-panel">
                  <div className="buttons">
                    <Button
                      className={`btn-continue ${
                        !this.state.addEnable && 'button-disabled'
                      }`}
                      onClick={this.handleAddUser}
                      id="add-user"
                      style={{ marginBottom: 20 }}
                    >
                      <span className="text-btn">Add additional user</span>
                      <span className="plus" />
                    </Button>

                    <Button
                      className={`btn-continue ${
                        this.props.invitations.length === 0 && 'button-disabled'
                      }`}
                      onClick={this.handleSentInvitations}
                      id="invite-users"
                    >
                      <span className="text-btn">Invite Users</span>
                      <span className="arrow" />
                    </Button>

                    <div className="bottom-title">
                      <span>
                        <Link to="/" className="link-later">
                          I&#39;ll do it later
                        </Link>
                      </span>
                    </div>
                  </div>
                </Col>
              </div>
            </Col>
          </div>
        </Grid>
      </div>
    ) : (
      <Loader loadImage={this.state.loadImage} />
    );
  }
}

export default connect(
  (state) => {
    return {
      imageUrl: state.loginBackgroundImage.url,
    };
  },
  (dispatch) => {
    return {
      getBackgroundImage: () => dispatch(getLoginBackgroundImage()),
    };
  }
)(UserInvitations);
