export const NEW_PROJECT = 'NEW_PROJECT';
export const EDIT_PROJECT = 'EDIT_PROJECT';
export const PREV_STAGE = 'PREV_STAGE';
export const NEXT_STAGE = 'NEXT_STAGE';
export const NEW_PROJECT_FORM_VALUE_CHANGE = 'NEW_PROJECT_FORM_VALUE_CHANGE';
export const NEW_PROJECT_GC_SET = 'NEW_PROJECT_GC_SET';
export const NEW_PROJECT_CURRENT_PAGE_SET = 'NEW_PROJECT_CURRENT_PAGE_SET';
export const NEW_PROJECT_CLIENT_FORM_VALUE_CHANGE =
  'NEW_PROJECT_CLIENT_FORM_VALUE_CHANGE';
export const NEW_PROJECT_PROJECT_LOCATION_FORM_VALUE_CHANGE =
  'NEW_PROJECT_PROJECT_LOCATION_FORM_VALUE_CHANGE';
export const NEW_PROJECT_COMPANY_FORM_VALUE_CHANGE =
  'NEW_PROJECT_COMPANY_FORM_VALUE_CHANGE';
export const NEW_PROJECT_NEW_CLIENT_MODAL_SHOW =
  'NEW_PROJECT_NEW_CLIENT_MODAL_SHOW';
export const NEW_PROJECT_NEW_CLIENT_FORM_CLEAN =
  'NEW_PROJECT_NEW_CLIENT_FORM_CLEAN';
export const NEW_PROJECT_NEW_PROJECT_LOCATION_MODAL_SHOW =
  'NEW_PROJECT_NEW_PROJECT_LOCATION_MODAL_SHOW';
export const NEW_PROJECT_NEW_PROJECT_LOCATION_FORM_CLEAN =
  'NEW_PROJECT_NEW_PROJECT_LOCATION_FORM_CLEAN';
export const NEW_PROJECT_NEW_COMPANY_MODAL_SHOW =
  'NEW_PROJECT_NEW_COMPANY_MODAL_SHOW';
export const NEW_PROJECT_NEW_COMPANY_FORM_CLEAN =
  'NEW_PROJECT_NEW_COMPANY_FORM_CLEAN';
export const NEW_PROJECT_ID_SET = 'NEW_PROJECT_ID_SET';
export const EDIT_PROJECT_FORM_SET_VALUES = 'EDIT_PROJECT_FORM_SET_VALUES';
export const SUBSCRIBERS_ADD_TO_BEGINNING = 'SUBSCRIBERS_ADD_TO_BEGINNING';
export const NEW_PROJECT_CLIENTS_ADD_TO_BEGINNING =
  'NEW_PROJECT_CLIENTS_ADD_TO_BEGINNING';
export const NEW_PROJECT_CLIENTS_SET = 'NEW_PROJECT_CLIENTS_SET';
export const NEW_PROJECT_CLIENTS_SET_PAGINATION_DATA =
  'NEW_PROJECT_CLIENTS_SET_PAGINATION_DATA';
