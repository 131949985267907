import {
  WORK_ORDERS_DETAILS_PURCHASE_ORDER_CANCEL,
  WORK_ORDERS_DETAILS_PURCHASE_ORDER_DOCUMENT_FILE_SET,
  WORK_ORDERS_DETAILS_PURCHASE_ORDER_DOCUMENT_REMOVE,
  WORK_ORDERS_DETAILS_PURCHASE_ORDER_EDIT_SET,
  WORK_ORDERS_DETAILS_PURCHASE_ORDER_NUMBER_SET,
  WORK_ORDERS_DETAILS_PURCHASE_ORDER_UPDATE,
  WORK_ORDERS_WO_SELECT_SUCCESS,
} from 'actions/admin/work-orders/action-types';

const initialState = {
  documentFile: null,
  documentUrl: null,
  edit: false,
  number: '',
};

function getPurchaseOrderInfo(workOrder) {
  return {
    document:
      workOrder.purchase_order_doc !== null &&
      workOrder.purchase_order_doc !== undefined
        ? workOrder.purchase_order_doc.document.url
        : null,
    number:
      workOrder.purchase_order !== null &&
      workOrder.purchase_order !== undefined
        ? workOrder.purchase_order
        : '',
  };
}

export default function purchaseOrderUiReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_DETAILS_PURCHASE_ORDER_UPDATE:
    case WORK_ORDERS_DETAILS_PURCHASE_ORDER_CANCEL:
      const result = getPurchaseOrderInfo(action.payload);
      return {
        ...state,
        edit: false,
        documentFile: null,
        documentUrl: result.document,
        number: result.number,
      };

    case WORK_ORDERS_DETAILS_PURCHASE_ORDER_DOCUMENT_FILE_SET:
      return {
        ...state,
        documentFile: action.payload,
      };

    case WORK_ORDERS_DETAILS_PURCHASE_ORDER_DOCUMENT_REMOVE:
      return {
        ...state,
        documentFile: null,
        documentUrl: null,
      };

    case WORK_ORDERS_DETAILS_PURCHASE_ORDER_EDIT_SET:
      return {
        ...state,
        edit: action.payload,
      };

    case WORK_ORDERS_DETAILS_PURCHASE_ORDER_NUMBER_SET:
      return {
        ...state,
        number: action.payload,
      };

    case WORK_ORDERS_WO_SELECT_SUCCESS:
      if (action.payload !== null) {
        const result = getPurchaseOrderInfo(action.payload);
        // Take into account that this action is used to set the current WO to null
        return {
          ...state,
          documentFile: null,
          documentUrl: result.document,
          number: result.number,
        };
      } else {
        return state;
      }

    default:
      return state;
  }
}
