import {
  SET_COMPANY_DROPDOWN,
  SET_CURRENT_PAGE,
  SET_COMPANIES_DATA,
  SET_TOTAL,
  SET_PAGE_SIZE,
  SET_NAME_TO_SEARCH,
  SET_COMPANY_USER_DATA,
  SET_COMPANY_PROJECTS_DATA,
  SET_COMPANY_DATA,
  SET_CURRENT_USER_COMPANY,
  COMPANIES_INVITE_USER_ID_SET,
  COMPANIES_INVITE_USER_MODAL_SHOW,
  COMPANIES_INVITE_USER_FORM_LOAD,
  COMPANIES_SHOW_CONFIRMATION_MODAL,
  COMPANIES_CLEAR_FILTERS,
  COMPANIES_SHOW_VERIFICATION_MODAL,
  SET_UNIONS,
  SET_ROLES,
  NEXT_STAGE,
  LICENCE_NUMBER_VALUE_CHANGE,
  NEW_ONBOARDING,
  COMPANY_LOGO_FILE_SET,
  COMPANY_ONBOARDING_STAGE_CHANGE,
  COMPANY_SELECT_TAB,
} from 'components/admin/companies/store/action-types';
import { UserModalState } from 'components/shared/modal/InviteUserModal';

const stages = ['licence-input', 'logo-upload'];

const initialState = {
  currentPage: 1,
  companyDropdown: '',
  companies: [],
  total: 1,
  pageSize: 10,
  nameToSearch: '',
  companyData: { name: '', status: '', contact: {} },
  currentUserId: 0,
  clientForm: {
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    globalPurchaseOrderRequired: false,
    purchaseOrderRequired: false,
    streetAddress: '',
    unitNumber: '',
    city: '',
    state: '',
    zipCode: '',
  },
  invalidFields: [],
  showInviteUserModal: false,
  userForm: {
    buildingsId: [],
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    assignedRole: '',
    accountAdmin: false,
    title: '',
  },
  userModalState: UserModalState.NEW, // one of UserModalState
  validationMessage: '',
  currentUserCompany: null,
  verificationModal: null,
  unions: [],
  roles: null,
  currentStage: 'licence-input',
  licenceNumber: '',
  companyLogo: { url: '', image: '' },
  selectedTab: 'user',
};

export default function companiesUIReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case SET_COMPANY_DROPDOWN:
      return {
        ...initialState,
        companyDropdown: action.payload,
      };

    case SET_COMPANIES_DATA:
      return {
        ...state,
        companies: action.payload,
      };

    case SET_NAME_TO_SEARCH:
      return {
        ...state,
        nameToSearch: action.payload,
      };

    case SET_TOTAL:
      return {
        ...state,
        total: action.payload,
      };

    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    case SET_COMPANY_USER_DATA:
      return {
        ...state,
        userList: action.payload,
      };
    case SET_COMPANY_PROJECTS_DATA:
      return {
        ...state,
        projectsList: action.payload,
      };
    case SET_COMPANY_DATA:
      return {
        ...state,
        companyData: action.payload,
      };

    case SET_CURRENT_USER_COMPANY:
      return {
        ...state,
        currentUserCompany: action.payload,
      };

    case COMPANIES_INVITE_USER_ID_SET:
      return {
        ...state,
        currentUserId: action.payload,
      };

    case COMPANIES_INVITE_USER_FORM_LOAD:
      return {
        ...state,
        userForm: action.payload,
        invalidFields: [],
        validationMessage: '',
      };

    case COMPANIES_INVITE_USER_MODAL_SHOW:
      const newState = {
        ...state,
        invalidFields: [],
        showInviteUserModal: action.payload,
        validationMessage: '',
      };

      return newState;

    case COMPANIES_SHOW_CONFIRMATION_MODAL:
      return {
        ...state,
        showConfirmationDeleteModal: action.payload,
      };

    case COMPANIES_CLEAR_FILTERS:
      return initialState;

    case COMPANIES_SHOW_VERIFICATION_MODAL:
      return {
        ...state,
        verificationModal: action.payload,
      };

    case SET_UNIONS:
      return {
        ...state,
        unions: action.payload,
      };

    case SET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };

    case NEW_ONBOARDING:
      return {
        ...state,
        ...action.payload,
      };

    case NEXT_STAGE:
      return {
        ...state,
        currentStage: stages[stages.indexOf(state.currentStage) + 1],
      };

    case COMPANY_ONBOARDING_STAGE_CHANGE:
      return {
        ...state,
        currentStage: action.payload,
      };

    case LICENCE_NUMBER_VALUE_CHANGE:
      return {
        ...state,
        licenceNumber: action.payload,
      };

    case COMPANY_LOGO_FILE_SET:
      return {
        ...state,
        companyLogo: { url: action.payload.url, image: action.payload.file },
      };

    case COMPANY_SELECT_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };

    default:
      return state;
  }
}
