import moment from 'moment';

export const parseNumber = (number, defaultNumber = '--') => {
  if (number) {
    if (isNaN(number)) return number;
    else return parseInt(number) >= 10 ? number : `0${parseInt(number)}`;
  } else return defaultNumber;
};

export const parsePersonName = (person, full = false) => {
  if (person) return full ? person.label : person.full_name;
  else return '--';
};

export const getDate = (date) => {
  if (!date) return null;
  const d = moment(date);
  return d.isValid() ? d : null;
};

export const parseDate = (date, full = false, approx = false) => {
  const dateMoment = date && moment(date);
  if (!dateMoment) return '--';

  const format = full ? `L [at${approx ? ' approx ' : ''}] LT` : 'L';
  return dateMoment.format(format);
};
