import React, { useMemo, useRef, useState } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getDarkMode } from 'selectors/theme';
import { Menu as MenuComponent, Grid } from '@mui/material';

import MenuItem, { ITEM_TYPE } from '../menu/MenuItem';
import moreOptionsSvg from '../menu/more-options.svg';
import moreOptionsDarkSvg from '../menu/more-options-dark.svg';
import './MegaMenu.css';

export const MENU_SELECTOR = {
  MORE_OPTIONS: 'more-options',
  MORE_OPTIONS_VERTICAL: 'more-options rotated-icon',
};

const MegaMenu = (props) => {
  const {
    className,
    renderSelector: customSelector,
    selector,
    darkModeSelector,
    darkMode,
    arrow,
    separator,
    placement,
    items,
    fetchItems,
    darkModeMenu,
    closeOnSelect,
    categories,
    megaMenuWidth,
  } = props;
  const hasSubitems = useRef(false);
  const isTemplateSelector = useRef(
    typeof selector === 'string' &&
      !!Object.keys(MENU_SELECTOR).find(
        (key) => MENU_SELECTOR[key] === selector
      )
  );

  // OPEN
  const anchor = useRef(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    if (fetchItems) {
      const callbackOpen = () => setOpen(true);
      fetchItems(callbackOpen);
    } else {
      setOpen(true);
    }
  };

  // SELECTOR
  const selectorClassName = () => (isTemplateSelector.current ? selector : '');
  const renderImageSelector = (image) => (
    <div className={`mega-menu-image-sector-container`}>
      <img className="mega-menu-image-sector" src={image} alt="selector" />
    </div>
  );
  const renderLabelSelector = () => (
    <div className="mega-menu-label-sector">{selector}</div>
  );
  const renderSelector = () => {
    if (customSelector) return customSelector(open);
    if (typeof selector !== 'string') return selector;
    if (
      selector === MENU_SELECTOR.MORE_OPTIONS_VERTICAL ||
      selector === MENU_SELECTOR.MORE_OPTIONS
    ) {
      const isDark = darkMode || darkModeSelector;
      return renderImageSelector(isDark ? moreOptionsDarkSvg : moreOptionsSvg);
    }
    return renderLabelSelector();
  };

  // ITEMS
  const handleItemSelect = (event, item) => {
    if (!item.onClick) return;
    if (closeOnSelect && item.closeOnSelect !== false) setOpen(false);
    if (item.onClick) item.onClick(event);
  };

  const renderItems = () => {
    if (!categories) {
      let menuItems;
      if (typeof items === 'function') menuItems = items();
      else menuItems = items;

      return menuItems.map((item, index) => {
        const hasItemSubitems = item.subitems && item.subitems.length > 0;
        hasSubitems.current = hasSubitems.current || hasItemSubitems;
        return (
          <MenuItem
            key={index}
            item={{ ...item, onClick: (e) => handleItemSelect(e, item) }}
            separator={separator}
            onSubitemClick={handleItemSelect}
          />
        );
      });
    }
    return (
      <Grid
        container
        spacing={2}
        className="mega-menu-dropdown-container section-selector-mega-menu-dropdown"
        style={{ fontSize: 24, flexWrap: 'nowrap' }}
      >
        {Object.entries(categories).map(([category, options]) => (
          <Grid item xs={4} key={category}>
            <div className="mega-menu-dropdown-selector extra-padding">
              {category}
            </div>
            {options.map((option, index) => {
              return (
                <MenuItem
                  key={index}
                  item={{
                    ...option,
                    onClick: (e) => handleItemSelect(e, option),
                  }}
                  separator={separator}
                />
              );
            })}
          </Grid>
        ))}
      </Grid>
    );
  };

  // PLACEMENT
  const getAnchorOrigin = () => {
    const [h, v] = placement.split('-');
    let horizontal = 'center';
    let vertical = 'center';

    if (h === 'left' || ((h === 'top' || h === 'bottom') && v === 'start'))
      horizontal = 'left';
    if (h === 'right' || ((h === 'top' || h === 'bottom') && v === 'end'))
      horizontal = 'right';
    if (h === 'top' || ((h === 'left' || h === 'right') && v === 'start'))
      vertical = 'top';
    if (h === 'bottom' || ((h === 'left' || h === 'right') && v === 'end'))
      vertical = 'bottom';

    // 'left-start',       -> Left Top
    // 'left-center',      -> Left Center
    // 'left-end',         -> Left Bottom
    // 'right-start',      -> Right Top
    // 'right-center',     -> Right Center
    // 'right-end',        -> Right Bottom

    // 'top-start',        -> Left Top
    // 'top-center',       -> Center Top
    // 'top-end',          -> Right Top
    // 'bottom-start',     -> Left Bottom
    // 'bottom-center',    -> Center Bottom
    // 'bottom-end',       -> Right Bottom

    return { horizontal, vertical };
  };
  const getTransformOrigin = () => {
    const [h, v] = placement.split('-');
    let horizontal = 'center';
    let vertical = 'center';

    if (h === 'left' || ((h === 'top' || h === 'bottom') && v === 'end'))
      horizontal = 'right';
    if (h === 'right' || ((h === 'top' || h === 'bottom') && v === 'start'))
      horizontal = 'left';
    if (h === 'top' || ((h === 'left' || h === 'right') && v === 'end'))
      vertical = 'bottom';
    if (h === 'bottom' || ((h === 'left' || h === 'right') && v === 'start'))
      vertical = 'top';

    // 'left-start',       -> R T
    // 'left-center',      -> R C
    // 'left-end',         -> R B
    // 'right-start',      -> L T
    // 'right-center',     -> L C
    // 'right-end',        -> L B

    // 'top-start',        -> L B
    // 'top-center',       -> C B
    // 'top-end',          -> R B
    // 'bottom-start',     -> L T
    // 'bottom-center',    -> C T
    // 'bottom-end',       -> R T

    return { horizontal, vertical };
  };
  const { anchorOrigin, transformOrigin } = useMemo(
    () => ({
      anchorOrigin: getAnchorOrigin(),
      transformOrigin: getTransformOrigin(),
    }),
    [placement]
  );

  const isDark = darkMode || darkModeMenu;

  return (
    <div
      className={`
        mega-menu ${selectorClassName()} 
        ${isDark ? 'dark-mode' : ''} 
        ${open ? 'mega-menu-open' : ''} 
        ${className || ''}
        `}
    >
      <div
        className="mega-menu-selector"
        aria-haspopup="true"
        onClick={handleOpen}
        ref={anchor}
      >
        {renderSelector()}
      </div>
      <MenuComponent
        className={`mega-menu-popover ${isDark ? 'dark-mode' : ''} ${
          arrow ? 'mega-menu-popover-arrow' : ''
        }`}
        onClose={handleClose}
        anchorEl={anchor.current}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        elevation={1}
        open={open}
        transitionDuration={0}
        PaperProps={{
          style: {
            width: megaMenuWidth,
            padding: '20px',
            flexWrap: 'nowrap',
          },
        }}
      >
        {arrow && <div className="arrow" />}
        <div
          className={`popover-children ${hasSubitems ? 'with-submenu' : ''} ${
            arrow ? 'popover-arrow' : ''
          } ${className || ''} ${placement}`}
        >
          {open && renderItems()}
        </div>
      </MenuComponent>
    </div>
  );
};

MegaMenu.propTypes = {
  className: PropTypes.string,

  renderSelector: PropTypes.func,
  selector: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  darkModeSelector: PropTypes.bool,

  arrow: PropTypes.bool,
  separator: PropTypes.bool,
  placement: PropTypes.oneOf([
    'top-start',
    'top-center',
    'top-end',
    'left-start',
    'left-center',
    'left-end',
    'right-start',
    'right-center',
    'right-end',
    'bottom-start',
    'bottom-center',
    'bottom-end',
  ]),

  items: PropTypes.oneOfType([PropTypes.arrayOf(ITEM_TYPE), PropTypes.func]),
  categories: PropTypes.shape({}),
  fetchItems: PropTypes.func,
  darkModeMenu: PropTypes.bool,

  darkMode: PropTypes.bool,

  closeOnSelect: PropTypes.bool,
};

MegaMenu.defaultProps = {
  separator: true,
  arrow: false,
  placement: 'bottom-end',
  darkMode: false,
  darkModeMenu: false,
  darkModeSelector: false,
  closeOnSelect: true,
  categories: {},
};

export default connect((state) => ({
  darkMode: getDarkMode(state),
}))(MegaMenu);
