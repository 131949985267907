import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Chat.css';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import ChatTitle from 'components/shared/chat/ChatTitle';
import ChatMessage from 'components/shared/chat/ChatMessage';
import ChatButtons from 'components/shared/chat/ChatButtons';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import FormModalSidebar from 'components/shared/modal/FormModalSidebar';
import { getDarkMode, getChatOpen } from 'selectors/theme';
import { setChatOpen } from 'actions/theme';
import chatSvg from './chat.svg';
import chatDarkSvg from './chat-dark.svg';

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = { newNote: { content: '', images: [], documents: [] } };
  }

  componentDidMount() {
    const { loadChat } = this.props;
    loadChat().then(() => this.scrollToBottom());
  }

  valid = () => {
    const { newNote } = this.state;
    return (
      newNote.content ||
      (newNote.images && newNote.images.length > 0) ||
      (newNote.documents && newNote.documents.length > 0)
    );
  };

  submit = () => {
    if (this.valid()) {
      const { handleSubmit } = this.props;
      const { newNote } = this.state;
      handleSubmit(newNote).then(() => {
        this.scrollToBottom();
        this.setState({
          newNote: { content: '', images: [], documents: [] },
        });
      });
    }
  };

  scrollToBottom = () => {
    const objDiv = document.getElementById('slide-chat');
    objDiv.scrollTop = objDiv.scrollHeight;
  };

  handleFormChange = (attr, value) => {
    const { newNote } = this.state;
    this.setState({
      newNote: {
        ...newNote,
        [attr]: value,
      },
    });
  };

  renderChat() {
    const { notes, name } = this.props;
    const { newNote } = this.state;
    return (
      <React.Fragment>
        {name && <ChatTitle name={name} />}
        <div id="slide-chat" className="chat-body">
          {!notes || notes.length === 0 ? (
            <div className="no-comments">No Messages</div>
          ) : (
            notes.map((note) => <ChatMessage key={note.id} note={note} />)
          )}
        </div>

        <FormControlBlock
          className="chat-textbox"
          label="Message"
          control={
            <TextareaAutosizeWrapper
              clear={newNote.content === ''}
              onBlur={(value) => this.handleFormChange('content', value)}
            />
          }
        />

        <ChatButtons
          newNote={newNote}
          handleFormChange={this.handleFormChange}
          handleSubmit={this.submit}
        />
      </React.Fragment>
    );
  }

  onSidebarChange = () => {
    const { chatOpen, dispatch } = this.props;
    dispatch(setChatOpen(!chatOpen));
  };

  render() {
    const { chatOpen, darkMode } = this.props;

    return (
      <FormModalSidebar
        className="chat"
        show={chatOpen}
        title={
          chatOpen ? (
            'Chat'
          ) : (
            <img
              className="chat-sidebar-icon"
              alt="chat"
              src={darkMode ? chatDarkSvg : chatSvg}
            />
          )
        }
        content={this.renderChat()}
        onChange={this.onSidebarChange}
      />
    );
  }
}

Chat.propTypes = {
  name: PropTypes.string,
  notes: PropTypes.arrayOf(PropTypes.shape()),
  loadChat: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
    chatOpen: getChatOpen(state),
  };
})(Chat);
