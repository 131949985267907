import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCurrentUser } from 'selectors/authentication';
import AllRecentActivity from 'components/admin/recent-activity/AllRecentActivity';

class DashboardRecentActiviy extends Component {
  render() {
    const { currentProject } = this.props;

    return <AllRecentActivity currentProject={currentProject} />;
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
  };
})(DashboardRecentActiviy);
