import {
  TIME_ADD_MODAL_SHOW,
  TIME_ID_RATE_TO_EDIT,
  TIME_RATE_CONFIRM_SUCCESS,
  TIME_RATE_SET,
  TIME_TO_EDIT,
} from 'components/admin/work-orders/details/time/store/action-types';

const initialState = {
  rate: null,
  showAddModal: false,
  timeToEdit: null,
  timeIdRateToEdit: null,
};

export default function timeUiReducer(state = initialState, action) {
  switch (action.type) {
    case TIME_ADD_MODAL_SHOW:
      return {
        ...state,
        showAddModal: action.payload,
      };

    case TIME_ID_RATE_TO_EDIT:
      return {
        ...state,
        rate:
          action.payload.rate !== null ? action.payload.rate.toString() : '0',
        timeIdRateToEdit: action.payload.timeId,
      };

    case TIME_RATE_CONFIRM_SUCCESS:
      return {
        ...state,
        rate: initialState.rate,
        timeIdRateToEdit: initialState.timeIdRateToEdit,
      };

    case TIME_RATE_SET:
      return {
        ...state,
        rate: action.payload,
      };

    case TIME_TO_EDIT:
      return {
        ...state,
        timeToEdit: action.payload,
      };

    default:
      return state;
  }
}
