import {
  SUBMITTALS_COUNT,
  SUBMITTALS_LOAD,
} from 'components/admin/submittals/store/action-types';

const initialState = {
  submittalsCount: 0,
  submittals: [],
  currentStatus: 'not_pre',
  total: 0,
  currentPage: 1,
  pageSize: 25,
};

export default function submittalsDataReducer(state = initialState, action) {
  switch (action.type) {
    case SUBMITTALS_COUNT:
      return {
        ...state,
        submittalsCount: action.payload,
      };

    case SUBMITTALS_LOAD:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
