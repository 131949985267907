import React from 'react';
import { connect } from 'react-redux';

import Chat from 'components/shared/chat/Chat';

import Rfa from 'domain/rfa';

import {
  getCurrentRfa,
  getRfaNotes,
} from 'components/admin/projects/details/project-rfa-log/store/selectors';

import {
  loadRfaNotes,
  createRfaNote,
  markRfaNotesRead,
} from 'components/admin/projects/details/project-rfa-log/store/actions';

const RfaNotes = (props) => {
  const { dispatch, currentRfa, rfaNotes } = props;

  if (!currentRfa || Rfa.isDraft(currentRfa) || Rfa.isDeleted(currentRfa))
    return null;

  const loadNotes = () => {
    dispatch(markRfaNotesRead(currentRfa.id));
    return dispatch(loadRfaNotes(currentRfa.id));
  };

  const buildNote = (newNote) => {
    return {
      note: newNote.content ? newNote.content : '',
      images: newNote.images ? newNote.images : [],
      documents: newNote.documents ? newNote.documents : [],
    };
  };

  const handleSubmit = (newNote) => {
    return dispatch(createRfaNote(currentRfa.id, buildNote(newNote)));
  };

  return (
    <Chat
      name="RFA"
      notes={rfaNotes}
      loadChat={loadNotes}
      handleSubmit={handleSubmit}
    />
  );
};

export default connect((state) => {
  return {
    rfaNotes: getRfaNotes(state),
    currentRfa: getCurrentRfa(state),
  };
})(RfaNotes);
