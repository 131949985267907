import {
  WORK_ORDERS_WO_ADD,
  WORK_ORDERS_CREATION_WORKING_HOURS_CURRENT_SET,
  WORK_ORDERS_CREATION_WORKING_HOURS_OTHER_CHANGE,
  WORK_ORDERS_CREATION_WORKING_HOURS_SELECT,
} from '../../../../actions/admin/work-orders/action-types';

const initialState = {
  otherWorkingHour: '',
  workingHours: [
    {
      id: 'monday-friday-day',
      value: 'Monday-Friday 5AM-5PM',
      selected: false,
    },
    {
      id: 'monday-friday-night',
      value: 'Monday-Friday 5PM-5AM',
      selected: false,
    },
    {
      id: 'saturday',
      value: 'Saturday',
      selected: false,
    },
    {
      id: 'sunday',
      value: 'Sunday',
      selected: false,
    },
    {
      id: 'other',
      value: 'Other',
      selected: false,
    },
  ],
};

export default function workingHoursDataReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_CREATION_WORKING_HOURS_SELECT:
      return {
        ...state,
        workingHours: state.workingHours.map((element) => {
          if (element.id === action.payload) {
            return {
              ...element,
              selected: !element.selected,
            };
          } else {
            return element;
          }
        }),
      };

    case WORK_ORDERS_CREATION_WORKING_HOURS_OTHER_CHANGE:
      return {
        ...state,
        otherWorkingHour: action.payload,
      };

    case WORK_ORDERS_WO_ADD:
      return {
        ...state,
        otherWorkingHour: '',
        workingHours: state.workingHours.map((element) => {
          return {
            ...element,
            selected: false,
          };
        }),
      };

    case WORK_ORDERS_CREATION_WORKING_HOURS_CURRENT_SET:
      let otherWorkingHour = '';
      const newWorkingHours = state.workingHours.slice();

      // Current working hours
      action.payload.forEach((element) => {
        const index = newWorkingHours.findIndex((wh) => wh.value === element);
        if (index > -1) {
          newWorkingHours[index] = {
            ...newWorkingHours[index],
            selected: true,
          };
        } else {
          // Text not found on list, should be "other"
          otherWorkingHour = element;
        }
      });

      return {
        ...state,
        otherWorkingHour: otherWorkingHour,
        workingHours: newWorkingHours,
      };

    default:
      return state;
  }
}
