import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { useMeasure } from 'react-use';
import FormModal from 'components/shared/modal/FormModal';
import SimpleTable from 'components/shared/table/SimpleTable';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import Chip from 'components/shared/chip/Chip';

const MAX_HEIGHT = 74;
const ChipSelector = (props) => {
  const {
    label,
    icon,
    onAdd,
    values: v,
    fixedValues,
    onChange,
    headers,
    renderRow,
    showHeaders,
    valueKey,
    labelKey,
    readOnly,
  } = props;
  const [ref, { height }] = useMeasure();
  const [more, setMore] = useState(false);
  const [values, setValues] = useState(v);

  const handleMore = () => setMore(!more);
  const handleDelete = (v, triggerChange = false) => () => {
    const res = values.filter((val) => v[valueKey] !== val[valueKey]);
    setValues(res);

    if (triggerChange) onChange(res);
  };
  const onSave = () => {
    onChange(values);
    handleMore();
  };
  useEffect(() => setValues(v), [v]);

  const renderRows = () => {
    return [...fixedValues, ...values].map((r) => [
      ...renderRow(r, labelKey),
      <Button
        type={BUTTON_TYPES.LINK}
        color={BUTTON_COLORS.TRANSPARENT}
        icon={BUTTON_ICONS.DELETE}
        disabled={r.disabled}
        onClick={handleDelete(r, false)}
        label={''}
      />,
    ]);
  };

  const first = (
    <Chip
      size="medium"
      label={<b>{label}</b>}
      onClick={!readOnly ? onAdd : null}
      icon={<img src={icon} alt="open" width={22} height={22} />}
      disabled={readOnly}
    />
  );

  const showMore = height >= MAX_HEIGHT;
  const hasValues = values.length > 0 || fixedValues.length > 0;

  const styles = {
    width: hasValues ? '100%' : 'auto',
    marginRight: hasValues ? 0 : 6,
    position: 'relative',
  };
  const moreStyles = {
    cursor: 'pointer',
    position: 'absolute',
    textTransform: 'none',
    fontSize: 13,
    right: 0,
    bottom: -4,
  };

  return (
    <>
      <div className="chip-selector" style={styles}>
        <Grid
          ref={ref}
          container
          marginBottom={1.5}
          overflow="hidden"
          alignItems="flex-end"
          spacing={hasValues ? 0.75 : 0}
          maxHeight={showMore ? MAX_HEIGHT : 'auto'}
        >
          <Grid item>{first}</Grid>
          {[...fixedValues, ...v].map((v, i) => {
            return (
              <Grid item key={'chip_select_' + i + '_' + v[valueKey]}>
                <Chip
                  label={v[labelKey]}
                  size="small"
                  onDelete={
                    !v.disabled && !readOnly ? handleDelete(v, true) : undefined
                  }
                />
              </Grid>
            );
          })}
        </Grid>
        {showMore && (
          <Typography
            onClick={handleMore}
            variant="button"
            color="text.primary"
            style={moreStyles}
          >
            View More
          </Typography>
        )}
      </div>
      <FormModal
        className="chip-selector-modal"
        show={more}
        onClose={handleMore}
        subTitle={label}
      >
        <Grid container paddingX={4} paddingY={1} direction={'column'}>
          <Grid item>
            <SimpleTable
              headers={showHeaders ? [...headers, ''] : []}
              rows={renderRows()}
              emptyMessage={`No ${label}`}
            />
          </Grid>
        </Grid>
        <div className="footer-modal">
          <Grid container justifyContent={'center'} paddingY={2} paddingX={2.5}>
            <Grid item>
              <Button
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.GREEN}
                label={'Save'}
                onClick={onSave}
              />
            </Grid>
          </Grid>
        </div>
      </FormModal>
    </>
  );
};

ChipSelector.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onAdd: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array,
  fixedValues: PropTypes.array,
  headers: PropTypes.array,
  showHeaders: PropTypes.bool,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  readOnly: PropTypes.bool,
};
ChipSelector.defaultProps = {
  values: [],
  fixedValues: [],
  headers: [''],
  renderRow: (r, labelKey) => [r[labelKey]],
  showHeaders: false,
  valueKey: 'value',
  labelKey: 'label',
  readOnly: false,
};

export default connect()(ChipSelector);
