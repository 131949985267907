import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getCompanyLogo,
  getLicenceNumber,
  getCompanyData,
} from './store/selectors';
import { setCompanyLogo, submitOnboarding } from './store/actions';
import CompanyOnboardingStatus from './CompanyOnboardingStatus';
import LogoUpload from 'components/shared/logo-upload/LogoUpload';
import { setHideSideBar } from 'actions/admin/sidebar/sidebar';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

class CompanyLogoUpload extends Component {
  handleCompanyLogoChange = (files) => {
    const file = files[0];
    const logo = {
      url: URL.createObjectURL(file),
      file: file,
    };
    this.logos = [logo];
    this.props.dispatch(setCompanyLogo(logo));
  };

  handleCompanyLogoRemove = () => {
    this.logos = [];
    const logo = {
      url: '',
      file: '',
    };
    this.props.dispatch(setCompanyLogo(logo));
  };

  handleContinue = () => {
    const onboardingData = {
      company: this.props.currentCompany,
      licenceNumber: this.props.licenceNumber,
      companyLogo: this.props.companyLogo,
      confirmed: true,
    };
    this.props.dispatch(submitOnboarding(onboardingData));
    this.props.dispatch(setHideSideBar(false));
  };

  render() {
    return (
      <div className="company-logo-upload">
        <CompanyOnboardingStatus activeStep={2} />

        <h2 className="onboarding-title">Company Logo</h2>
        <p className="add-message">
          Your company logo will be added to any proposals you deliver
        </p>
        <div className="details-form">
          <LogoUpload
            className="form-uploader"
            extensions={['.jpg', '.jpeg', '.gif', '.png', '.heic']}
            logos={this.logos}
            onRemove={() => this.handleCompanyLogoRemove({})}
            onUpload={(files) => this.handleCompanyLogoChange(files)}
            useDropzone={true}
          />
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label="Finished"
            onClick={this.handleContinue}
          />
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    companyLogo: getCompanyLogo(state),
    licenceNumber: getLicenceNumber(state),
    currentCompany: getCompanyData(state),
  };
})(CompanyLogoUpload);
