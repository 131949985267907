import React, { Component } from 'react';
import { connect } from 'react-redux';
import EnterpriseSidebar from 'components/admin/sidebar/EnterpriseSidebar';
import { Redirect, Switch } from 'react-router-dom';
import ProjectLocationsContainer from './project-locations/ProjectLocationsContainer';
import { getCurrentUser } from '../../selectors/authentication';
import GlobalPermissions from 'permissions/global-permissions';
import CompaniesContainer from 'components/admin/companies/CompaniesContainer';
import { ConditionRoute } from '../shared/ConditionRoute';
import {
  getActiveRoute,
  getHideSidebar,
} from 'selectors/admin/sidebar/sidebar';
import { getDarkMode } from '../../selectors/theme';
import { selectRoute, setHideSideBar } from 'actions/admin/sidebar/sidebar';
import TeamContainer from './team/TeamContainer';
import Users from './users/Users';
import { simpleScrollToTop } from 'services/utils/scroller-util';
import ProjectsContainer from 'components/admin/projects/ProjectsContainer';
import DashboardContainer from 'components/admin/dashboard/DashboardContainer';
import SuperadminContainer from 'components/admin/superadmin/SuperadminContainer';
import { getCompanyData } from 'components/admin/companies/store/selectors';
import {
  loadCompanyById,
  showOnboarding,
} from 'components/admin/companies/store/actions';
import TermsModal from 'components/shared/modal/TermsModal';
import { updateTermsAndConditions } from 'actions/admin/users/users';
import User from 'domain/user';
import './Admin.css';

class Admin extends Component {
  constructor(props) {
    super(props);

    const { currentUser, dispatch } = props;

    dispatch(setHideSideBar(false));

    this.defaultRoute = '/dashboard';

    this.sidebarItems = [
      {
        label: 'Companies',
        route: '/companies',
        icon: 'companies',
        show: GlobalPermissions.canViewCompaniesPage(currentUser),
        type: 'shared-bottom',
      },
      {
        label: 'Project Locations',
        route: '/project-locations',
        icon: 'project-locations',
        show: GlobalPermissions.canViewProjectLocationsPage(currentUser),
        type: 'shared-bottom',
      },
      {
        label: 'My Company',
        route: '/team',
        icon: 'team',
        show: GlobalPermissions.canViewTeamPage(currentUser),
        type: 'shared-bottom',
      },
      {
        label: 'Users',
        route: '/users',
        icon: 'users',
        show: GlobalPermissions.canViewUsersPage(currentUser),
        type: 'shared-bottom',
      },
      {
        label: 'Projects',
        route: '/projects',
        icon: 'projects',
        show: GlobalPermissions.canViewProjectsPage(currentUser),
        type: 'enterprise',
      },
      {
        label: 'Dashboard',
        route: '/dashboard',
        icon: 'dashboard',
        show: GlobalPermissions.canViewDashboardPage(currentUser),
        type: 'shared-top',
      },
    ];
  }

  UNSAFE_componentWillMount() {
    const { currentUser } = this.props;
    if (User.isPendingTermsAndConditions(currentUser))
      this.setState({ showTerms: true });

    if (User.isPendingConfirmation(currentUser))
      this.props
        .dispatch(loadCompanyById(currentUser.accountable_id))
        .then(() => this.redirectToCompanyOnboarding());
  }

  redirectToCompanyOnboarding = () => {
    const { company } = this.props;
    if (!User.isPendingConfirmation({ accountable: company })) return;

    this.props.dispatch(setHideSideBar(true));
    this.props.dispatch(showOnboarding());
  };

  handleItemSelected = (id) => {
    simpleScrollToTop();
    this.props.dispatch(
      selectRoute(
        id,
        this.props.match.url +
          this.sidebarItems.find((element) => element.icon === id).route
      )
    );
  };

  handleGenerateLink = (id) => {
    return `${this.props.match.url}/${id}`;
  };

  onTermsAccept = () => {
    this.props.dispatch(updateTermsAndConditions(true)).then((response) => {
      if (response.ok) {
        this.setState((state) => ({ showTerms: !state.showTerms }));
      }
    });
  };

  render() {
    const { currentUser } = this.props;

    return (
      <div
        id="admin"
        className={`admin ${this.props.darkMode ? 'dark-mode' : ''}`}
      >
        <div className={'admin-col sidebar-container  hidden'}>
          <EnterpriseSidebar
            sharedItemsTop={this.sidebarItems
              .filter((item) => item.type === 'shared-top')
              .map((item) => {
                return {
                  ...item,
                  selected: item.icon === this.props.activeRoute,
                };
              })}
            enterpriseItems={this.sidebarItems
              .filter((item) => item.type === 'enterprise')
              .map((item) => {
                return {
                  ...item,
                  selected: item.icon === this.props.activeRoute,
                };
              })}
            sharedItemsBottom={this.sidebarItems
              .filter((item) => item.type === 'shared-bottom')
              .map((item) => {
                return {
                  ...item,
                  selected: item.icon === this.props.activeRoute,
                };
              })}
            onItemSelected={this.handleItemSelected}
            onGenerateLink={this.handleGenerateLink}
          />
        </div>
        <div id="admin-col-item-container" className="admin-col item-container">
          {User.isPendingTermsAndConditions(currentUser) && (
            <TermsModal
              show={this.state.showTerms}
              onHide={this.onTermsAccept}
            />
          )}
          <Switch>
            <ConditionRoute
              path={`${this.props.match.url}/project-locations`}
              component={ProjectLocationsContainer}
              show={GlobalPermissions.canViewProjectLocationsPage(currentUser)}
            />

            <ConditionRoute
              path={`${this.props.match.url}/companies`}
              component={CompaniesContainer}
              show
            />

            <ConditionRoute
              path={`${this.props.match.url}/team`}
              component={TeamContainer}
              show={GlobalPermissions.canViewTeamPage(currentUser)}
            />

            <ConditionRoute
              path={`${this.props.match.url}/users`}
              component={Users}
              show={GlobalPermissions.canViewUsersPage(currentUser)}
            />

            <ConditionRoute
              path={`${this.props.match.url}/projects`}
              component={ProjectsContainer}
              show={GlobalPermissions.canViewProjectsPage(currentUser)}
            />

            <ConditionRoute
              path={`${this.props.match.url}/dashboard`}
              component={DashboardContainer}
              show={GlobalPermissions.canViewDashboardPage(currentUser)}
            />

            <ConditionRoute
              path={`${this.props.match.url}/superadmin`}
              component={SuperadminContainer}
              show={false}
            />

            <Redirect to={this.props.match.url + this.defaultRoute} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    activeRoute: getActiveRoute(state),
    currentUser: getCurrentUser(state),
    company: getCompanyData(state),
    hideSideBar: getHideSidebar(state),
    darkMode: getDarkMode(state),
  };
})(Admin);
