import {
  PROJECT_OPEN_ITEMS_COUNT,
  PROJECT_OPEN_ITEMS_CRITICAL_COUNT,
  PROJECT_OPEN_ITEMS_LOAD,
} from 'components/admin/project-open-items/store/action-types';

const initialState = {
  projectOpenItemsCount: 0,
  projectOpenItemsCritCount: 0,
  projectOpenItems: [],
  currentStatus: 'not_pre',
  total: 0,
  currentPage: 1,
  pageSize: 25,
};

export default function projectOpenItemsDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case PROJECT_OPEN_ITEMS_COUNT:
      return {
        ...state,
        projectOpenItemsCount: action.payload,
      };

    case PROJECT_OPEN_ITEMS_CRITICAL_COUNT:
      return {
        ...state,
        projectOpenItemsCritCount: action.payload,
      };

    case PROJECT_OPEN_ITEMS_LOAD:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
