import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button, { BUTTON_COLORS } from 'components/shared/button/Button';
import Date from 'components/shared/text/Date';
import DatePicker from 'components/shared/date-picker/DatePicker';
import closePng from 'images/close.png';
import OutsideAlerter from 'components/shared/OutsideAlerter';

class MeetingTitle extends Component {
  static propTypes = {
    reviewMeeting: PropTypes.bool.isRequired,
    meetingForm: PropTypes.shape({}).isRequired,
    showAttendeesModal: PropTypes.bool.isRequired,
    handleRollCall: PropTypes.func.isRequired,
    handleCloseAttendeesModal: PropTypes.func.isRequired,
    renderAttendeesPopup: PropTypes.func.isRequired,
    handleMeetingFormChange: PropTypes.func.isRequired,
  };

  render() {
    const { reviewMeeting, meetingForm, showAttendeesModal } = this.props;
    return reviewMeeting ? (
      <div>
        <div>{`Meeting Minutes ${meetingForm.number}`}</div>
        <div className="review-date">
          <Date value={meetingForm.meetingDate} format="MM/DD/YYYY" />
        </div>
      </div>
    ) : (
      <div>
        <div>{`Meeting Minutes ${meetingForm.number}`}</div>
        <Button
          color={BUTTON_COLORS.WHITE}
          label="Roll Call"
          onClick={this.props.handleRollCall}
        />
        {showAttendeesModal && (
          <OutsideAlerter onClickOutside={this.props.handleCloseAttendeesModal}>
            <div className="attendees">
              <span className="attendees-header">
                <span className="attendees-title">Attendees </span>
                <span className="attendees-sub-title">
                  (Mark who is present)
                </span>
                <span className="attendees-close-icon">
                  <img
                    src={closePng}
                    alt="close"
                    onClick={this.props.handleCloseAttendeesModal}
                  />
                </span>
              </span>
              <hr />
              <div className="attendees-selection">
                {this.props.renderAttendeesPopup()}
              </div>
            </div>
          </OutsideAlerter>
        )}
        <DatePicker
          placeholder="Choose Date..."
          value={meetingForm.meetingDate}
          onChange={(value) =>
            this.props.handleMeetingFormChange('meetingDate', value)
          }
        />
      </div>
    );
  }
}

export default connect()(MeetingTitle);
