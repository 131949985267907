import {
  CHANGE_ORDER_QUOTES_SELECT,
  CHANGE_ORDER_QUOTE_DETAILS_MODAL_CLOSE,
} from 'components/admin/work-orders/details/change-order-quotes/store/action-types';

const initialState = {
  selectedChangeOrderTaskId: null,
};

export default function changeOrderQuotesDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case CHANGE_ORDER_QUOTES_SELECT:
      return {
        ...state,
        selectedChangeOrderTaskId: action.payload,
      };

    case CHANGE_ORDER_QUOTE_DETAILS_MODAL_CLOSE:
      return {
        ...state,
        selectedChangeOrderTaskId: null,
      };

    default:
      return state;
  }
}
