import User from 'domain/user';
import SecurityCamera from 'domain/security-camera';

class SecurityCameraPermissions {
  canManage = (user, project) =>
    User.isAdminOrProjectSubscriberAccountAdmin(user, project);

  canView = (user, project) =>
    project.security_cameras || this.canManage(user, project);

  canConfigure = (user, project) => this.canManage(user, project);

  canEditPermissions = (user, project, configuration) =>
    this.canManage(user, project) && SecurityCamera.isConfigured(configuration);

  canEditCameras = (user, project, configuration) =>
    this.canManage(user, project) && SecurityCamera.isConfigured(configuration);
}

export default new SecurityCameraPermissions();
