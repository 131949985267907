import queryString from 'query-string';
import { useEffect } from 'react';
import { loadProjectPhotos, setProjectPhotosFilters } from './store/actions';

const useProjectPhotos = (props) => {
  const { dispatch, filters, currentProject, location } = props;

  useEffect(() => initialLoad(), []);
  useEffect(() => loadAlbum(), [filters.doSearch]);

  const initialLoad = () => {
    const state =
      location && (location.state || queryString.parse(location.search));

    let fs = { doSearch: true };
    if (state && state.albumId) fs.albumId = state.albumId;

    dispatch(setProjectPhotosFilters(fs));
  };

  const loadAlbum = () => {
    if (filters.doSearch) {
      dispatch(loadProjectPhotos(currentProject.id, filters));
    }
  };

  return {};
};

export default useProjectPhotos;
