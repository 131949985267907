import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import './FormControlBlock.css';

export const CONTROL_SIZE = {
  AUTO: '',
  SMALL: 'small-control',
  MEDIUM: 'medium-control',
  BIG: 'big-control',
  DOUBLE_BIG: 'double-big-control',
  UNSET: 'unset-size-control',
};

class FormControlBlock extends Component {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.any,
    bottomLabel: PropTypes.any,
    control: PropTypes.element,
    size: PropTypes.string,
    hideBottomLabel: PropTypes.bool,
    hideLabel: PropTypes.bool,
  };

  static defaultProps = {
    size: CONTROL_SIZE.AUTO,
    hideBottomLabel: false,
    hideLabel: false,
  };

  render() {
    const {
      className,
      label,
      bottomLabel,
      hideBottomLabel,
      hideLabel,
      control,
      size,
      darkMode,
    } = this.props;

    return (
      <div
        className={`form-control-block ${className || ''} ${size} ${
          darkMode ? 'dark-mode' : ''
        }`}
      >
        {!hideLabel && (
          <label className="form-control-label">{label || ''}</label>
        )}
        {control}
        {!hideBottomLabel && (
          <label className="form-control-bottom-label">
            {bottomLabel || ''}
          </label>
        )}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(FormControlBlock);
