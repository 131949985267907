import React from 'react';
import { connect } from 'react-redux';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import DatePicker from 'components/shared/date-picker/DatePicker';
import TimePicker from 'components/shared/time-picker/TimePicker';
import Input from 'components/shared/input/Input';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

import {
  setAddNewMeetingModalShow,
  setEditNextMeetingModalShow,
} from './store/actions';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import {
  getAddNextMeetingModal,
  getMeetingsInstanceForm,
  getEditNextMeetingModal,
  getActionItemsErrors,
  getInstanceInfoErrors,
  getMeetingsInstanceIsBackup,
} from './store/selectors';
import './MeetingsInstanceForm.css';
import AddNextMeetingModal from './AddNextMeetingModal';
import EditNextMeetingModal from './EditNextMeetingModal';
import newMeetingsPermissions from '../../../../../permissions/new-meetings-permissions';

const MeetingsInstanceFormInfo = (props) => {
  const {
    handleFormChange,
    instanceForm,
    showAddNextMeetingModal,
    showEditNextMeetingModal,
    dispatch,
    meetingsParent,
    actionItemsErrors,
    instanceInfoErrors,
    currentUser,
    meetingsInstanceIsBackup,
  } = props;

  const handleEditNextMeetingInfo = () => {
    if (!meetingsParent.nextMeeting) {
      dispatch(setAddNewMeetingModalShow(true));
    } else {
      dispatch(setEditNextMeetingModalShow(true));
    }
  };
  const errors = () => {
    const instanceInfoErrorsKeys = Object.keys(instanceInfoErrors);
    return (
      <div className="errors-container">
        {actionItemsErrors
          ? actionItemsErrors.map((e) => <div>{e}</div>)
          : null}
        {instanceInfoErrorsKeys.length > 0
          ? instanceInfoErrorsKeys.map((e) => (
              <div>{instanceInfoErrors[e]}</div>
            ))
          : null}
      </div>
    );
  };
  const MeetingDate = (
    <FormControlBlock
      hideBottomLabel
      label={instanceForm.meetingDate && 'Meeting Date'}
      control={
        <DatePicker
          className="date"
          placeholder={'Meeting Date'}
          value={instanceForm.meetingDate}
          onChange={(value) => handleFormChange({ meetingDate: value })}
          readOnly={
            meetingsInstanceIsBackup ||
            !newMeetingsPermissions.canEditInstance(
              currentUser,
              instanceForm,
              meetingsParent.status === 'archived'
            )
          }
        />
      }
    />
  );

  const StartTime = (
    <FormControlBlock
      hideBottomLabel
      label={instanceForm.startTime && 'Arrival Time'}
      control={
        <TimePicker
          className={'clock'}
          placeholder={'Start Time'}
          onChange={(value) => handleFormChange({ startTime: value })}
          value={instanceForm.startTime}
          valid={!(instanceForm.errors && instanceForm.errors.startTime)}
          readOnly={
            meetingsInstanceIsBackup ||
            !newMeetingsPermissions.canEditInstance(
              currentUser,
              instanceForm,
              meetingsParent.status === 'archived'
            )
          }
        />
      }
    />
  );

  const EndTime = (
    <FormControlBlock
      hideBottomLabel
      label={instanceForm.endTime && 'End Time'}
      control={
        <TimePicker
          className={'clock'}
          placeholder={'End Time'}
          onChange={(value) => handleFormChange({ endTime: value })}
          value={instanceForm.endTime}
          readOnly={
            meetingsInstanceIsBackup ||
            !newMeetingsPermissions.canEditInstance(
              currentUser,
              instanceForm,
              meetingsParent.status === 'archived'
            )
          }
        />
      }
    />
  );
  const JobWalkCheckbox = (
    <FormControlBlock
      hideBottomLabel
      className="input-condition"
      control={
        <LabeledCheckBox
          label="Job Walk Conducted"
          checked={!!instanceForm.jobWalkConducted}
          onChange={(jobWalkConducted) =>
            handleFormChange({ jobWalkConducted })
          }
          position="left"
          readOnly={
            meetingsInstanceIsBackup ||
            !newMeetingsPermissions.canEditInstance(
              currentUser,
              instanceForm,
              meetingsParent.status === 'archived'
            )
          }
        />
      }
    />
  );

  const AddressInput = (
    <FormControlBlock
      hideBottomLabel
      className="input-address"
      hideLabel={!instanceForm.address}
      label={instanceForm.address && 'Address'}
      control={
        <Input
          type={'text'}
          placeholder={'Address'}
          value={instanceForm.address}
          onChange={(address) => handleFormChange({ address })}
          readOnly={
            meetingsInstanceIsBackup ||
            !newMeetingsPermissions.canEditInstance(
              currentUser,
              instanceForm,
              meetingsParent.status === 'archived'
            )
          }
        />
      }
    />
  );
  const LocationInput = (
    <FormControlBlock
      hideBottomLabel
      hideLabel={!instanceForm.location}
      label={instanceForm.title && 'Location'}
      control={
        <Input
          type={'text'}
          placeholder={'Location'}
          value={instanceForm.location}
          onChange={(location) => handleFormChange({ location })}
          readOnly={
            meetingsInstanceIsBackup ||
            !newMeetingsPermissions.canEditInstance(
              currentUser,
              instanceForm,
              meetingsParent.status === 'archived'
            )
          }
        />
      }
    />
  );
  const CallInfo = (
    <FormControlBlock
      hideBottomLabel
      hideLabel={!instanceForm.callInfo}
      label={instanceForm.title && 'Call Info'}
      control={
        <Input
          type={'text'}
          placeholder={'Call Info'}
          value={instanceForm.callInfo}
          onChange={(callInfo) => handleFormChange({ callInfo })}
          readOnly={
            meetingsInstanceIsBackup ||
            !newMeetingsPermissions.canEditInstance(
              currentUser,
              instanceForm,
              meetingsParent.status === 'archived'
            )
          }
        />
      }
    />
  );
  const CallLink = (
    <FormControlBlock
      hideBottomLabel
      hideLabel={!instanceForm.callLink}
      label={instanceForm.title && 'Call Link'}
      control={
        <Input
          type={'text'}
          placeholder={'Call Link'}
          value={instanceForm.callLink}
          onChange={(callLink) => handleFormChange({ callLink })}
          readOnly={
            meetingsInstanceIsBackup ||
            !newMeetingsPermissions.canEditInstance(
              currentUser,
              instanceForm,
              meetingsParent.status === 'archived'
            )
          }
        />
      }
    />
  );

  const WebLink = (
    <FormControlBlock
      hideBottomLabel
      hideLabel={!instanceForm.webLink}
      label={instanceForm.webLink && 'Weblink'}
      control={
        <Input
          type={'text'}
          placeholder={'Weblink'}
          value={instanceForm.webLink}
          onChange={(webLink) => handleFormChange({ webLink })}
          readOnly={
            meetingsInstanceIsBackup ||
            !newMeetingsPermissions.canEditInstance(
              currentUser,
              instanceForm,
              meetingsParent.status === 'archived'
            )
          }
        />
      }
    />
  );

  return (
    <React.Fragment>
      <div>
        <div className="form-block-container">
          <div className="form-block">{MeetingDate}</div>

          <div className="form-block">
            <div className="form-control-container">
              {StartTime}
              {EndTime}
              {JobWalkCheckbox}
            </div>
          </div>
        </div>
        <div className="form-block-container">
          <div className="form-block address">{AddressInput}</div>
          <div className="form-block location">{LocationInput}</div>
        </div>

        <div className="form-block-container">
          <div className="form-block call-info">{CallInfo}</div>
          <div className="form-block call-link">{CallLink}</div>
        </div>
        <div className="form-block-container">
          <div className="form-block weblink">{WebLink}</div>
        </div>
        <div className="form-block-container">
          <div className="next-meeting-text">Next Meeting</div>
          <div className="edit-button-container">
            {newMeetingsPermissions.canEditInstance(
              currentUser,
              instanceForm,
              meetingsParent.status === 'archived'
            ) && (
              <Button
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Edit"
                onClick={() => handleEditNextMeetingInfo()}
              />
            )}
          </div>
        </div>
        <div className="next-meeting-datetimes-text">
          {meetingsParent.nextMeeting || 'No meetings set'}
        </div>
        {errors()}
      </div>
      {!meetingsParent.nextMeeting && showAddNextMeetingModal && (
        <AddNextMeetingModal handleFormChange={handleFormChange} />
      )}
      {meetingsParent.nextMeeting && showEditNextMeetingModal && (
        <EditNextMeetingModal handleFormChange={handleFormChange} />
      )}
    </React.Fragment>
  );
};

export default connect((state) => ({
  currentProject: getCurrentProject(state),
  instanceForm: getMeetingsInstanceForm(state),
  showAddNextMeetingModal: getAddNextMeetingModal(state),
  showEditNextMeetingModal: getEditNextMeetingModal(state),
  actionItemsErrors: getActionItemsErrors(state),
  instanceInfoErrors: getInstanceInfoErrors(state),
  currentUser: getCurrentUser(state),
  meetingsInstanceIsBackup: getMeetingsInstanceIsBackup(state),
}))(MeetingsInstanceFormInfo);
