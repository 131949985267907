import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import disclaimerSvg from './disclaimer.svg';
import disclaimerSvgDark from './disclaimer-dark.svg';
import TooltipControl from './TooltipControl';
import './TooltipButton.css';

export const ICON_SIZES = {
  SMALL: 'small',
  NORMAL: 'normal',
  BIG: 'big',
};

export const ICON_POSITIONS = {
  TOP: 'top',
  CENTER: 'center',
  BOTTOM: 'bottom',
};

export const ICON_TYPES = {
  DISCLAIMER: {
    light: disclaimerSvg,
    dark: disclaimerSvgDark,
    size: ICON_SIZES.SMALL,
    position: ICON_POSITIONS.TOP,
  },
};

function TooltipButton(props) {
  const {
    className,
    tooltip,
    tooltipId,
    tooltipClassName,
    tooltipPlacement,
    tooltipPosition,
    tooltipSize,
    icon,
    darkMode,
  } = props;

  return (
    <TooltipControl
      id={tooltipId}
      tooltip={tooltip}
      className={tooltipClassName}
      placement={tooltipPlacement}
      position={tooltipPosition}
      size={tooltipSize}
      control={
        <img
          className={classnames({
            'tooltip-button': true,
            [className]: !!className,
            [`tooltip-button-size-${icon.size}`]: true,
            [`tooltip-button-position-${icon.position}`]: true,
          })}
          src={darkMode ? icon.dark : icon.light}
          alt="tooltip icon"
        />
      }
    />
  );
}

TooltipButton.propTypes = {
  className: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  tooltipId: PropTypes.string,
  tooltipClassName: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  tooltipPosition: PropTypes.string,
  tooltipSize: PropTypes.string,
  icon: PropTypes.object,
};

TooltipButton.defaultProps = {
  tooltipId: 'tooltip-button',
  tooltipPlacement: 'top',
  tooltipPosition: 'left',
  tooltipSize: 'medium',
  icon: ICON_TYPES.DISCLAIMER,
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(TooltipButton);
