import React, { Component } from 'react';
import './UserHeader.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import vectorSvg from './vector.svg';
import Account from 'components/header/Account';
import Menu from 'components/shared/menu/Menu';
import { getDarkMode } from '../../selectors/theme';
import User from 'domain/user';

class UserHeader extends Component {
  static propTypes = {
    currentUser: PropTypes.shape({}).isRequired,
    accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onAccountClick: PropTypes.func.isRequired,
    onAddAccount: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
  };

  handleAccountClick = (event, acc) => {
    event.preventDefault();
    this.props.onAccountClick(acc);
  };

  handleAddAccount = (event) => {
    event.preventDefault();
    this.props.onAddAccount();
  };

  handleLogout = (event) => {
    event.preventDefault();
    this.props.onLogout();
  };

  handleCustomerSupport = (event) => {
    const { currentUser } = this.props;
    event.preventDefault();
    const url =
      `${process.env.REACT_APP_CLICKUP_FORM_URL}` +
      `?Name=${encodeURIComponent(
        currentUser.first_name + ' ' + currentUser.last_name
      )}` +
      `&Email=${encodeURIComponent(currentUser.email)}` +
      `&${encodeURIComponent('Phone Number')}=${encodeURIComponent(
        currentUser.phone_number
      )}` +
      `&${encodeURIComponent('User Id')}=${encodeURIComponent(
        currentUser.uid
      )}` +
      `&Role=${encodeURIComponent(User.companyName(currentUser))}`;
    window.open(url, '_blank');
  };

  getAccountSortField = (acc) => {
    return (acc.user && acc.user.first_name) || '';
  };

  sortAccounts = (acc1, acc2) => {
    const { currentUser } = this.props;
    if (currentUser.id === acc1.user.id) {
      return -1;
    }
    if (currentUser.id === acc2.user.id) {
      return 1;
    }
    return this.getAccountSortField(acc1).localeCompare(
      this.getAccountSortField(acc2)
    );
  };

  render() {
    const { currentUser, accounts, darkMode } = this.props;

    const accountsItems = accounts
      .sort(this.sortAccounts)
      .map((account, index) => ({
        className: `account-item ${index === 0 ? 'selected' : ''}`,
        content: (
          <React.Fragment>
            <Account user={account.user} />
          </React.Fragment>
        ),
        onClick: (e) => this.handleAccountClick(e, account),
      }));

    return (
      <div className={`user-header ${darkMode ? 'dark-mode' : ''}`}>
        <div className="user-name">
          <span>Hello</span>
          <span>{currentUser.first_name}</span>
        </div>
        <Menu
          className="user-options"
          arrow
          selector={<img src={vectorSvg} alt="options" />}
          items={[
            ...accountsItems,
            {
              content: 'Add Account',
              onClick: this.handleAddAccount,
            },
            {
              content: 'Customer Support',
              onClick: this.handleCustomerSupport,
            },
            {
              content: 'Logout',
              onClick: this.handleLogout,
            },
          ]}
        />
      </div>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(UserHeader);
