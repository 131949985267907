import React from 'react';
import { Link } from 'react-router-dom';

function SupportLink(props) {
  return (
    <div className="bottom-title-wrapper">
      Need help?
      <Link
        target="_blank"
        style={{ cursor: 'pointer', color: '#D4FB00' }}
        to={{
          pathname: process.env.REACT_APP_CLICKUP_FORM_URL,
        }}
        className="support"
      >
        Support
      </Link>
    </div>
  );
}

export default SupportLink;
