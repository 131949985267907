import moment from 'moment';

export const NOTIFICATIONS_FREQUENCY_TYPES = [
  { value: 'everyday', label: 'Everyday' },
  { value: 'weekdays', label: 'Weekdays' },
  { value: 'custom', label: 'Custom' },
];

export const NOTIFICATIONS_FREQUENCY_WEEKDAYS = [
  { value: 'monday', label: 'Monday' },
  { value: 'tuesday', label: 'Tuesday' },
  { value: 'wednesday', label: 'Wednesday' },
  { value: 'thursday', label: 'Thursday' },
  { value: 'friday', label: 'Friday' },
  { value: 'saturday', label: 'Saturday' },
  { value: 'sunday', label: 'Sunday' },
];

// TEST - ADD ALL NECESARY
export const NOTIFICATIONS_SUBJECTS = [
  { value: 'project_report', label: 'Project Reports' },
  { value: 'field_report', label: 'Field Reports' },
  { value: 'submittal', label: 'Submittals' },
  { value: 'rfi', label: 'RFIs' },
  { value: 'rfa', label: 'RFAs' },
  { value: 'photo', label: 'Photos' },
  { value: 'document', label: 'Documents' },
];

export const NOTIFICATIONS_TIME_OPTIONS = Array.from(
  { length: 49 },
  (_, index) => {
    const date = moment()
      .startOf('day')
      .add(index * 30, 'minutes');
    return {
      value:
        index === 48
          ? date.subtract(1, 'minute').format('HH:mm:ss')
          : date.format('HH:mm:ss'),
      label: index === 48 ? 'Midnight' : date.format('hh:mm A'),
    };
  }
);

export const DEFAULT_FREQUENCY_TYPE = NOTIFICATIONS_FREQUENCY_TYPES[0];
export const DEFAULT_FREQUENCY_TIME_SLOT = {
  start: NOTIFICATIONS_TIME_OPTIONS[16], // 8:00 AM
  end: NOTIFICATIONS_TIME_OPTIONS[44], // 10:00 PM
};

export const DEFAULT_NOTIFICATION_SETTINGS_FORM = {
  ...DEFAULT_FREQUENCY_TIME_SLOT,
  allowInApp: true,
  allowEmail: true,
  frequencyType: DEFAULT_FREQUENCY_TYPE,
  frequencies: NOTIFICATIONS_FREQUENCY_WEEKDAYS.map((wd) => ({
    ...wd,
    ...DEFAULT_FREQUENCY_TIME_SLOT,
    allow: false,
  })),
  subjects: [],
};
