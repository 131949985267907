import {
  NEW_ACTION_ITEM_UPDATE_DEFAULT,
  NEW_ACTION_ITEM_DEFAULT,
} from 'domain/new-meeting';

import { setActionItems, setFormMeetingInstance } from './store/actions';
import { useState, useEffect } from 'react';

const useActionItems = (props) => {
  const {
    dispatch,
    actionItems,
    instanceForm,
    meetingsParentId,
    meetingsInstanceId,
    setIsCreatingNewItem,
    currentUser,
  } = props;
  const [isResolvedRemark, setIsResolvedRemark] = useState(false);
  const [isResolvedRemarkNested, setIsResolvedRemarkNested] = useState(false);
  const [editingInCategory, setEditingInCategory] = useState('');
  useEffect(() => {
    const allAreComplete = actionItems.every(
      (ai) => ai.description && ai.status
    );
    if (allAreComplete) setIsCreatingNewItem(false);
  }, [actionItems]);
  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    // If new action item, doing it on the same category
    if (
      source.droppableId === destination.droppableId &&
      (!draggableId || draggableId === 'undefined')
    ) {
      const category = destination.droppableId.split('-')[0];
      let updatedActionItems = [...actionItems];
      const row = actionItems.find(
        (ai) => ai.id && ai.id.toString() === draggableId
      );
      const rowWithCategory = { ...row, category };
      updatedActionItems.splice(source.index, 1);
      updatedActionItems.splice(destination.index, 0, rowWithCategory);
      dispatch(setActionItems(updatedActionItems));
      dispatch(
        setFormMeetingInstance({
          ...instanceForm,
          meetingsActionItems: updatedActionItems,
        })
      );
      return;
    }
    //If new action item, changing category
    if (
      source.droppableId !== destination.droppableId &&
      (!draggableId || draggableId === 'undefined')
    ) {
      const row = actionItems.find(
        (ai) => ai.id && ai.id.toString() === draggableId
      );
      const newCategory = destination.droppableId.split('-')[0];
      const recategorizedItem = { ...row, category: newCategory };
      const reOrganizedActionItems = actionItems.map((ai) => {
        if (!ai.id) {
          return recategorizedItem;
        } else {
          return ai;
        }
      });
      dispatch(setActionItems(reOrganizedActionItems));
      dispatch(
        setFormMeetingInstance({
          ...instanceForm,
          meetingsActionItems: reOrganizedActionItems,
        })
      );

      return;
    }
    //If existing action item, dragging on the same category
    if (source.droppableId === destination.droppableId && !!draggableId) {
      let updatedActionItems = [...actionItems];
      const row = actionItems.find((ai) => ai.id.toString() === draggableId);
      updatedActionItems.splice(source.index, 1);
      updatedActionItems.splice(destination.index, 0, row);
      dispatch(setActionItems(updatedActionItems));
      // dispatch(updateActionItem(currentProject.id, row.id, updatedActionItems));

      return;
    }
    //If existing action item, changing category
    if (source.droppableId !== destination.droppableId && !!draggableId) {
      const row = actionItems.find((ai) => ai.id.toString() === draggableId);
      const newCategory = destination.droppableId.split('-')[0];
      const recategorizedItem = { ...row, category: newCategory };
      const reOrganizedActionItems = actionItems.map((ai) => {
        if (row.id === ai.id) {
          return recategorizedItem;
        } else {
          return ai;
        }
      });
      dispatch(setActionItems(reOrganizedActionItems));
      dispatch(
        setFormMeetingInstance({
          ...instanceForm,
          meetingsActionItems: reOrganizedActionItems,
        })
      );
      // dispatch(updateActionItem(currentProject.id, row.id, recategorizedItem));
      return;
    }
  };
  const handleDeleteItem = (row) => {
    // Deletes the item (soft delete)
    const deletedRow = {
      ...row,
      isDiscarded: true,
      meetingsParentId,
      meetingsInstanceId,
    };
    const filteredItems = actionItems.map((ai) =>
      ai.id === deletedRow.id && !!ai.createdOnInstanceId ? deletedRow : ai
    );
    dispatch(
      setFormMeetingInstance({
        ...instanceForm,
        meetingsActionItems: filteredItems,
      })
    );
    dispatch(setActionItems(filteredItems));
    setIsCreatingNewItem(false);
  };
  const handleDeleteItemUpdate = (row, update) => {
    // Deletes the item (soft delete)
    const deletedUpdate = {
      ...update,
      isDiscarded: true,
      meetingsParentId,
      meetingsInstanceId,
    };

    const editedUpdates = row.actionItemsUpdates.map((u) =>
      u.id === deletedUpdate.id ? deletedUpdate : u
    );

    const updatedItem = { ...row, actionItemsUpdates: editedUpdates };
    const updatedItems = actionItems.map((ai) =>
      ai.id === row.id ? updatedItem : ai
    );

    dispatch(
      setFormMeetingInstance({
        ...instanceForm,
        meetingsActionItems: updatedItems,
      })
    );

    dispatch(setActionItems(updatedItems));
  };
  const handleDeleteItemNestedUpdate = (row, update, nestedUpdate) => {
    const deletedNestedUpdate = {
      ...nestedUpdate,
      isDiscarded: true,
      meetingsParentId,
      meetingsInstanceId,
    };

    const editedNestedUpdates = update.nestedUpdates.map((nu) =>
      nu.id === deletedNestedUpdate.id ? deletedNestedUpdate : nu
    );
    const editedUpdate = { ...update, nestedUpdates: editedNestedUpdates };
    const editedUpdates = row.actionItemsUpdates.map((aiu) =>
      aiu.id === editedUpdate.id ? editedUpdate : aiu
    );
    const editedActionItem = { ...row, actionItemsUpdates: editedUpdates };
    const editedActionItems = actionItems.map((ai) =>
      ai.id === row.id ? editedActionItem : ai
    );
    dispatch(setActionItems(editedActionItems));
    dispatch(
      setFormMeetingInstance({
        ...instanceForm,
        meetingsActionItems: editedActionItems,
      })
    );
  };

  const handleResponsibleChange = (row, responsible, responsibleCompany) => {
    const isNew = !row.id;
    const newValues = {
      ...row,
      responsible,
      responsibleCompany,
      responsibleId: responsible && responsible.value,
      responsibleCompanyId: responsibleCompany && responsibleCompany.value,
    };
    if (isNew) {
      const modifiedActionItems = actionItems.map((ai) => {
        if (!ai.id) {
          return newValues;
        } else {
          return ai;
        }
      });
      dispatch(setActionItems(modifiedActionItems));
      dispatch(
        setFormMeetingInstance({
          ...instanceForm,
          meetingsActionItems: modifiedActionItems,
        })
      );

      // handleSetNewItem(row, newValues);
    } else {
      const modifiedActionItems = actionItems.map((ai) => {
        if (ai.id === row.id) {
          return newValues;
        } else {
          return ai;
        }
      });
      dispatch(setActionItems(modifiedActionItems));
      dispatch(
        setFormMeetingInstance({
          ...instanceForm,
          meetingsActionItems: modifiedActionItems,
        })
      );
    }
  };
  const handleResponsibleUpdateChange = (
    row,
    responsible,
    responsibleCompany,
    update
  ) => {
    const responsibleId = responsible && responsible.value;
    const responsibleCompanyId = responsibleCompany && responsibleCompany.value;
    const newUpdate = {
      ...update,
      responsibleCompany,
      responsible,
      responsibleId,
      responsibleCompanyId,
    };
    const editedUpdates = row.actionItemsUpdates.map((u) =>
      u.id === update.id ? newUpdate : u
    );
    const editedActionItem = { ...row, actionItemsUpdates: editedUpdates };
    const newActionItems = actionItems.map((ai) =>
      ai.id === row.id ? editedActionItem : ai
    );
    dispatch(setActionItems(newActionItems));
    dispatch(
      setFormMeetingInstance({
        ...instanceForm,
        meetingsActionItems: newActionItems,
      })
    );
  };
  const handleResponsibleNestedUpdateChange = (
    row,
    responsible,
    responsibleCompany,
    update,
    nestedUpdate
  ) => {
    const responsibleId = responsible && responsible.value;
    const responsibleCompanyId = responsibleCompany && responsibleCompany.value;
    const newNestedUpdate = {
      ...nestedUpdate,
      responsibleCompany,
      responsible,
      responsibleId,
      responsibleCompanyId,
    };
    const editedNestedUpdates = update.nestedUpdates.map((nu) =>
      nu.id === nestedUpdate.id ? newNestedUpdate : nu
    );
    const editedUpdate = { ...update, nestedUpdates: editedNestedUpdates };
    const editedUpdates = row.actionItemsUpdates.map((u) =>
      u.id === update.id ? editedUpdate : u
    );
    const editedActionItem = { ...row, actionItemsUpdates: editedUpdates };
    const editedActionItems = actionItems.map((ai) =>
      ai.id === row.id ? editedActionItem : ai
    );
    dispatch(setActionItems(editedActionItems));
    dispatch(
      setFormMeetingInstance({
        ...instanceForm,
        meetingsActionItems: editedActionItems,
      })
    );
    /* if (nestedUpdate.id) {
      dispatch(
        updateActionItemUpdate(
          currentProject.id,
          nestedUpdate.id,
          newNestedUpdate
        )
      );
    } */
  };
  const handleTableNestedUpdateChange = (row, update, values) => {
    const editedNestedUpdates = update.nestedUpdates.map((nu) =>
      nu.id === values.id ? values : nu
    );
    const editedUpdate = { ...update, nestedUpdates: editedNestedUpdates };
    const editedUpdates = row.actionItemsUpdates.map((aiu) =>
      aiu.id === update.id ? editedUpdate : aiu
    );
    const editedActionItem = { ...row, actionItemsUpdates: editedUpdates };
    const editedActionItems = actionItems.map((ai) =>
      ai.id === row.id ? editedActionItem : ai
    );
    dispatch(setActionItems(editedActionItems));
    dispatch(
      setFormMeetingInstance({
        ...instanceForm,
        meetingsActionItems: editedActionItems,
      })
    );
    /*  if (values.id)
      dispatch(updateActionItemUpdate(currentProject.id, values.id, values)); */
  };

  const handleTableUpdateChange = (row, values) => {
    const editedUpdates = row.actionItemsUpdates.map((u) =>
      u.id === values.id ? values : u
    );
    const editedActionItems = actionItems.map((ai) => {
      if (ai.id === row.id) {
        return { ...ai, actionItemsUpdates: editedUpdates };
      } else {
        return ai;
      }
    });
    dispatch(setActionItems(editedActionItems));
    dispatch(
      setFormMeetingInstance({
        ...instanceForm,
        meetingsActionItems: editedActionItems,
      })
    );
  };
  const handleTableChange = (row, category, values, isNew) => {
    const newValues = { ...row, category, ...values };
    if (isNew) {
      const editedActionItems = actionItems.map((ai) => {
        if (!ai.id) {
          return newValues;
        } else {
          return ai;
        }
      });
      dispatch(setActionItems(editedActionItems));
      dispatch(
        setFormMeetingInstance({
          ...instanceForm,
          meetingsActionItems: editedActionItems,
        })
      );
    } else {
      const editedActionItems = actionItems.map((ai) => {
        if (ai.id === row.id) {
          return newValues;
        } else {
          return ai;
        }
      });

      dispatch(setActionItems(editedActionItems));
      dispatch(
        setFormMeetingInstance({
          ...instanceForm,
          meetingsActionItems: editedActionItems,
        })
      );
    }
  };
  const handleDescriptionChange = (row, category, description, isNew) => {
    const newValues = { ...row, category, description };
    if (isNew) {
      const editedActionItems = actionItems.map((ai) => {
        if (!ai.id) {
          return newValues;
        } else {
          return ai;
        }
      });
      dispatch(setActionItems(editedActionItems));
      dispatch(
        setFormMeetingInstance({
          ...instanceForm,
          meetingsActionItems: editedActionItems,
        })
      );
    } else {
      const editedActionItems = actionItems.map((ai) => {
        if (ai.id === row.id) {
          return newValues;
        } else {
          return ai;
        }
      });
      dispatch(setActionItems(editedActionItems));
      dispatch(
        setFormMeetingInstance({
          ...instanceForm,
          meetingsActionItems: editedActionItems,
        })
      );
    }
  };
  const handleNestedUpdateDescriptionChange = (
    description,
    row,
    update,
    nu
  ) => {
    const newNestedUpdate = { ...nu, description };
    const editedNestedUpdates = update.nestedUpdates.map((n) =>
      n.id === nu.id ? newNestedUpdate : n
    );
    const editedUpdate = { ...update, nestedUpdates: editedNestedUpdates };
    const editedUpdates = row.actionItemsUpdates.map((u) =>
      u.id === update.id ? editedUpdate : u
    );
    const updatedActionItem = { ...row, actionItemsUpdates: editedUpdates };
    const updatedActionItems = actionItems.map((ai) =>
      ai.id === row.id ? updatedActionItem : ai
    );
    dispatch(setActionItems(updatedActionItems));
    dispatch(
      setFormMeetingInstance({
        ...instanceForm,
        meetingsActionItems: updatedActionItems,
      })
    );
    //  if (nu.id) dispatch(updateActionItemUpdate(currentProject.id, nu.id, nu));
  };
  const handleNoteChange = (notes, row, note) => {
    const newNote = { ...note, notes };
    const editedNotes = row.meetingsActionItemsNotes.map((n) => {
      if (n.id === note.id) {
        return newNote;
      } else {
        return n;
      }
    });
    const editedItem = { ...row, meetingsActionItemsNotes: editedNotes };
    const updatedActionItems = actionItems.map((ai) =>
      ai.id === row.id ? editedItem : ai
    );
    dispatch(setActionItems(updatedActionItems));
    dispatch(
      setFormMeetingInstance({
        ...instanceForm,
        meetingsActionItems: updatedActionItems,
      })
    );
  };
  const handleUpdateDescriptionChange = (description, row, update) => {
    const newUpdate = { ...update, description };
    const editedUpdates = row.actionItemsUpdates.map((u) => {
      if (u.id === update.id) {
        return newUpdate;
      } else {
        return u;
      }
    });
    const updatedActionItem = { ...row, actionItemsUpdates: editedUpdates };
    const updatedActionItems = actionItems.map((ai) =>
      ai.id === row.id ? updatedActionItem : ai
    );
    dispatch(setActionItems(updatedActionItems));
    dispatch(
      setFormMeetingInstance({
        ...instanceForm,
        meetingsActionItems: updatedActionItems,
      })
    );
  };

  const handleResolveItem = (row) => {
    const resolvedRow = {
      ...row,
      isResolved: true,
      resolvedById: currentUser.id,
      meetingsParentId,
      meetingsInstanceId,
      status: 'closed',
    };
    const updatedActionItems = actionItems.map((ai) =>
      ai.id === row.id ? resolvedRow : ai
    );
    // dispatch(updateActionItem(currentProject.id, row.id, resolvedRow));
    dispatch(setActionItems(updatedActionItems));
    dispatch(
      setFormMeetingInstance({
        ...instanceForm,
        meetingsActionItems: updatedActionItems,
      })
    );
  };
  const handleAddItemNestedUpdate = (row, u) => {
    handleSetEditModeInCategory(row.category || 'nocategory');
    let newNestedUpdate;
    const { actionItemsUpdates } = row;
    const { nestedUpdates } = u;
    const nestedUpdatesLength = nestedUpdates.length;
    if (u.id) {
      newNestedUpdate = {
        ...NEW_ACTION_ITEM_UPDATE_DEFAULT,
        projectId: instanceForm.projectId,
        parentUpdateId: u.id,
        createdOnInstanceId: instanceForm.id,
        id: `new-nested-${nestedUpdatesLength}`,
      };
    } else {
      newNestedUpdate = {
        ...NEW_ACTION_ITEM_UPDATE_DEFAULT,
        projectId: instanceForm.projectId,
        id: `new-nested-${nestedUpdatesLength}`,
      };
    }
    const editedNestedUpdates = [...nestedUpdates, newNestedUpdate];
    const editedActionItemsUpdates = actionItemsUpdates.map((aiu) =>
      aiu.id === u.id ? { ...u, nestedUpdates: editedNestedUpdates } : aiu
    );
    const editedActionItem = {
      ...row,
      actionItemsUpdates: editedActionItemsUpdates,
    };
    const editedActionItems = actionItems.map((ai) =>
      ai.id === row.id ? editedActionItem : ai
    );
    dispatch(setActionItems(editedActionItems));
    dispatch(
      setFormMeetingInstance({
        ...instanceForm,
        meetingsActionItems: editedActionItems,
      })
    );
  };
  const handleAddItemUpdate = (row) => {
    let newUpdate;
    handleSetEditModeInCategory(row.category || 'nocategory');
    const updatesNumber = row.actionItemsUpdates.length;
    if (instanceForm.id) {
      newUpdate = {
        ...NEW_ACTION_ITEM_UPDATE_DEFAULT,
        projectId: instanceForm.projectId,
        meetingsActionItemId: row.id,
        createdOnInstanceId: instanceForm.id,
        id: `new-action-item-update-${updatesNumber}`,
      };
    } else {
      newUpdate = {
        ...NEW_ACTION_ITEM_UPDATE_DEFAULT,
        projectId: instanceForm.projectId,
        id: `new-action-item-update-${updatesNumber}`,
      };
    }
    const filteredUpdates =
      row.actionItemsUpdates &&
      row.actionItemsUpdates.filter((aiu) => !aiu.isDiscarded);
    const rowsUpdates = row.actionItemsUpdates ? [...filteredUpdates] : [];
    const rowWithUpdate = {
      ...row,
      actionItemsUpdates: [...rowsUpdates, newUpdate],
    };
    const updatedActionItems = actionItems.map((ai) => {
      if (ai.id === row.id) {
        return rowWithUpdate;
      } else {
        return ai;
      }
    });
    dispatch(setActionItems(updatedActionItems));
    dispatch(
      setFormMeetingInstance({
        ...instanceForm,
        meetingsActionItems: updatedActionItems,
      })
    );
  };
  const handleAddItemNote = (row) => {
    handleSetEditModeInCategory(row.category || 'nocategory');
    const newNote = {
      notes: '',
      createdAt: '',
      createdById: currentUser.id,
    };

    const notes = row.meetingsActionItemsNotes || [];
    const editedActionItem = {
      ...row,
      meetingsActionItemsNotes: [...notes, newNote],
    };
    const editedActionItems = actionItems.map((ai) =>
      ai.id === row.id ? editedActionItem : ai
    );
    dispatch(setActionItems(editedActionItems));

    dispatch(
      setFormMeetingInstance({
        ...instanceForm,
        meetingsActionItems: editedActionItems,
      })
    );
  };
  const handleAddItem = (category) => {
    setIsCreatingNewItem(true);
    handleSetEditModeInCategory(category);
    const temporalId = instanceForm.meetingsActionItems.length + 1;
    let newItemsNewData = {
      ...NEW_ACTION_ITEM_DEFAULT,
      projectId: instanceForm.projectId,
      category,
      id: `new-item-${temporalId}`,
    };
    if (instanceForm.id)
      newItemsNewData = {
        ...newItemsNewData,
        createdOnInstanceId: instanceForm.id,
      };
    dispatch(setActionItems([...actionItems, newItemsNewData]));
    dispatch(
      setFormMeetingInstance({
        ...instanceForm,
        meetingsActionItems: [
          ...instanceForm.meetingsActionItems,
          newItemsNewData,
        ],
      })
    );
  };
  const handleAddResolvedRemark = (row) => {
    setIsResolvedRemark(true);
    handleAddItemUpdate(row);
  };
  const handleAddResolvedRemarkNested = (row, update) => {
    setIsResolvedRemarkNested(true);
    handleAddItemNestedUpdate(row, update);
  };

  const handleBlurUpdate = (row) => {
    if (isResolvedRemark) {
      const editedActionItem = { ...row, isResolved: true };
      const editedActionItems = instanceForm.meetingsActionItems.map((ai) =>
        ai.id === row.id ? editedActionItem : ai
      );
      dispatch(setActionItems(editedActionItems));
      dispatch(
        setFormMeetingInstance({
          ...instanceForm,
          meetingsActionItems: [editedActionItems],
        })
      );
    }
  };
  const handleBlurNestedUpdate = (row, update) => {
    if (isResolvedRemarkNested) {
      const editedUpdate = { ...update, isResolved: true };
      const editedUpdates = row.actionItemsUpdates.map((aiu) =>
        aiu.id === update.id ? editedUpdate : aiu
      );
      const editedActionItem = { ...row, meetingsActionItems: editedUpdates };
      const editedActionItems = instanceForm.meetingsActionItems.map((ai) =>
        ai.id === row.id ? editedActionItem : ai
      );
      dispatch(setActionItems(editedActionItems));
      dispatch(
        setFormMeetingInstance({
          ...instanceForm,
          meetingsActionItems: [editedActionItems],
        })
      );
    }
  };
  const handleSetEditModeInCategory = (category) => {
    setEditingInCategory(category);
  };
  return {
    handleBlurUpdate,
    handleBlurNestedUpdate,
    handleAddItem,
    handleAddItemNestedUpdate,
    handleTableUpdateChange,
    handleDeleteItem,
    handleDeleteItemNestedUpdate,
    handleDeleteItemUpdate,
    onDragEnd,
    handleResolveItem,
    handleResponsibleChange,
    handleResponsibleNestedUpdateChange,
    handleResponsibleUpdateChange,
    handleAddItemUpdate,
    handleTableNestedUpdateChange,
    handleDescriptionChange,
    handleTableChange,
    handleUpdateDescriptionChange,
    handleNestedUpdateDescriptionChange,
    handleAddResolvedRemark,
    handleAddResolvedRemarkNested,
    editingInCategory,
    handleSetEditModeInCategory,
    handleAddItemNote,
    handleNoteChange,
  };
};

export default useActionItems;
