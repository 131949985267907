import React from 'react';
import { connect } from 'react-redux';

import FormModal from 'components/shared/modal/FormModal';
import NavigationBar from 'components/shared/navigation/NavigationBar';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import Input from 'components/shared/input/Input';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import DatePicker from 'components/shared/date-picker/DatePicker';
import TimePicker from 'components/shared/time-picker/TimePicker';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

import { MEETING_FORM_TABS } from 'domain/new-meeting';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';

import {
  setInfoTabProjectMeetings,
  setInfoModalProjectMeetings,
  setCurrentMeeting,
} from './store/actions';
import {
  getProjectNewMeetingsParents,
  getProjectNewMeetingsInstances,
  getProjectNewMeetingsInfoModal,
  getProjectNewMeetingsForm,
  getMeetingsTitles,
  getMeetingsTypes,
  getMeetingsParentsTitles,
  getMeetingsParentsTypes,
  getProjectNewMeetingsFilters,
  getProjectMeetingsParentsInfoTab,
} from './store/selectors';
import AttendanceForm from './AttendanceForm';
import './AttendanceForm.css';
import './MeetingModal.css';
import AddTypeOrTitleModal from './AddTypeOrTitleModal';
import EditTypeOrTitleModal from './EditTypeOrTitleModal';
import {
  getWeekendsAndHolidays,
  iCalToReadable,
} from '../../../../../services/utils/date-util';
const InfoMeetingModal = (props) => {
  const {
    dispatch,
    show,
    tab,
    form,
    showAddTypeModal,
    showAddTitleModal,
    showEditTypeModal,
    showEditTitleModal,
  } = props;
  // form
  const isEdit = form.id !== null;

  // tabs
  const setTab = (key) => dispatch(setInfoTabProjectMeetings(key));
  const buildTabs = () => {
    return MEETING_FORM_TABS.map((t) => ({
      ...t,
      class: t.id === tab ? 'sel' : '',
    }));
  };

  // modal
  const handleClose = () => {
    dispatch(setInfoModalProjectMeetings(false));
    setCurrentMeeting(null);
  };
  if (!show) return null;

  return (
    <>
      <FormModal
        className={`meeting-modal  ${
          tab === 'permissions' ? form.visibility : ''
        } ${tab === 'editing' ? form.editing : ''}`}
        show={show}
        onClose={handleClose}
        subTitle={`${isEdit ? 'Edit' : 'Add'} Meeting`}
      >
        <NavigationBar
          items={buildTabs()}
          onSelected={setTab}
          selectedValue={tab}
        />
        {tab === 'general_info' && (
          <div className="form-container">
            <div className="form-block multicontrols-block">
              <div className="two-fields-container">
                <FormControlBlock
                  label="Meeting Type"
                  position="right"
                  readonly={true}
                  control={
                    <Input
                      type={'text'}
                      placeholder={'Meeting Type'}
                      value={form.meetingType}
                      onChange={() => {}}
                      readonly={true}
                      disabled
                    />
                  }
                ></FormControlBlock>
                <FormControlBlock
                  label="Meeting Title"
                  position="left"
                  readonly={true}
                  control={
                    <Input
                      type={'text'}
                      placeholder={'Meeting Title'}
                      value={form.title}
                      onChange={() => {}}
                      readonly={true}
                      disabled
                    />
                  }
                ></FormControlBlock>
              </div>
            </div>
            <div className="form-block">
              <FormControlBlock
                label="Address"
                position="left"
                readonly={true}
                control={
                  <Input
                    type={'text'}
                    placeholder={'Address'}
                    value={form.address}
                    onChange={() => {}}
                    readonly={true}
                    disabled
                  />
                }
              ></FormControlBlock>
            </div>

            <div className="form-block multicontrols-block">
              <div className="two-fields-container">
                <FormControlBlock
                  label="Location"
                  position="left"
                  readonly={true}
                  control={
                    <Input
                      type={'text'}
                      placeholder={'Location'}
                      value={form.location}
                      onChange={() => {}}
                    />
                  }
                ></FormControlBlock>
                <FormControlBlock
                  label="Access"
                  position="right"
                  readonly={true}
                  control={
                    <Input
                      type={'text'}
                      placeholder={'Access'}
                      value={form.access}
                      onChange={() => {}}
                    />
                  }
                ></FormControlBlock>
              </div>
            </div>
            <div className="form-block multicontrols-block">
              <div className="two-fields-container">
                <FormControlBlock
                  label="Job Walk"
                  position="left"
                  readonly={true}
                  control={
                    <Input
                      type={'text'}
                      placeholder={'Job Walk'}
                      value={form.jobWalk}
                      onChange={(jobWalk) => {}}
                    />
                  }
                ></FormControlBlock>
                <FormControlBlock
                  label="Parking"
                  position="right"
                  readonly={true}
                  control={
                    <Input
                      type={'text'}
                      placeholder={'Parking'}
                      value={form.parking}
                      onChange={() => {}}
                    />
                  }
                ></FormControlBlock>
              </div>
            </div>
            <div className="form-block multicontrols-block">
              <div className="two-fields-container">
                <FormControlBlock
                  label="Call Info"
                  position="left"
                  readonly={true}
                  control={
                    <Input
                      type={'text'}
                      placeholder={'Call Info'}
                      value={form.callInfo}
                      onChange={() => {}}
                    />
                  }
                ></FormControlBlock>
                <FormControlBlock
                  label="Call Link"
                  position="right"
                  readonly={true}
                  control={
                    <Input
                      type={'text'}
                      placeholder={'Call Link'}
                      value={form.callLink}
                      onChange={() => {}}
                    />
                  }
                ></FormControlBlock>
              </div>
            </div>
            <div className="form-block">
              <FormControlBlock
                label="Weblink"
                position="left"
                readonly={true}
                control={
                  <Input
                    type={'text'}
                    placeholder={'Weblink'}
                    value={form.webLink}
                    onChange={() => {}}
                    readonly={true}
                    disabled
                  />
                }
              ></FormControlBlock>
            </div>
            <div className="form-block multicontrols-block">
              <FormControlBlock
                label="Other Info"
                position="left"
                readonly={true}
                control={
                  <Input
                    type={'text'}
                    placeholder={'Other Info'}
                    value={form.otherInfo}
                    onChange={() => {}}
                  />
                }
              ></FormControlBlock>
              <FormControlBlock
                position="right"
                readonly={true}
                control={
                  <LabeledCheckBox
                    label="Private Meeting"
                    checked={!!form.private}
                    onChange={() => {}}
                    {...props}
                  />
                }
              ></FormControlBlock>
            </div>
          </div>
        )}
        {tab === 'schedule' && (
          <div className="form-container">
            <div className="form-block multicontrols-block">
              <div className="three-fields-container">
                <FormControlBlock
                  label="Start Date"
                  readonly={true}
                  control={
                    <DatePicker
                      format="MM/DD/YYYY"
                      placeholder={'MM/DD/YYYY'}
                      value={form.startDate}
                      clearable
                      onChange={() => {}}
                    />
                  }
                />
                <FormControlBlock
                  label="End Date"
                  readonly={true}
                  control={
                    <DatePicker
                      format="MM/DD/YYYY"
                      placeholder={'MM/DD/YYYY'}
                      value={form.endDate}
                      clearable
                      onChange={() => {}}
                    />
                  }
                />
                <FormControlBlock
                  label="Frequency"
                  position="right"
                  readonly={true}
                  control={
                    <Input
                      type={'text'}
                      value={iCalToReadable(form.recurrence)}
                      onChange={() => {}}
                    />
                  }
                />
              </div>
            </div>
            <div className="form-block multicontrols-block">
              <div className="times-container">
                <FormControlBlock
                  label="Start Time"
                  readonly={true}
                  control={
                    <TimePicker
                      className={'clock'}
                      placeholder={'Start Time'}
                      onChange={() => {}}
                      value={form.startTime}
                      valid={!(form.errors && form.errors.startTime)}
                    />
                  }
                />
                <FormControlBlock
                  label="End Time"
                  readonly={true}
                  control={
                    <TimePicker
                      className={'clock'}
                      placeholder={'End Time'}
                      onChange={() => {}}
                      value={form.endTime}
                      valid={!(form.errors && form.errors.endTime)}
                    />
                  }
                />
                <FormControlBlock
                  label="Access"
                  position="right"
                  readonly={true}
                  control={
                    <Input type={'text'} value={'Pacific Time'} readonly />
                  }
                />
              </div>
            </div>
            <div className="form-block">
              <FormControlBlock
                label="Weblink"
                position="left"
                readonly={true}
                control={
                  <Input
                    type={'text'}
                    placeholder={'Weblink'}
                    value={form.webLink}
                    onChange={() => {}}
                    readonly={true}
                    disabled
                  />
                }
              ></FormControlBlock>
            </div>
            <div className="form-block">
              <div className="vertical-block">
                <div className="exceptions-title">Next Meeting</div>
                <div className="exceptions-text">
                  Recurring meetings will not take place on the following dates
                  due to holidays or other events
                </div>
                {form.startDate &&
                  form.endDate &&
                  getWeekendsAndHolidays(form.startDate, form.endDate).map(
                    (date) => {
                      return <div className="exceptions-text">{date}</div>;
                    }
                  )}
              </div>
            </div>
          </div>
        )}
        {tab === 'attendance' && (
          <div className="form-container">
            <AttendanceForm
              users={form.attendees}
              onUsersChange={() => {}}
              readOnly={true}
            />
          </div>
        )}
        <div className="button-actions">
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.WHITE}
            label="Close"
            onClick={handleClose}
          />
        </div>
      </FormModal>
      {showAddTypeModal && (
        <AddTypeOrTitleModal
          showActions={true}
          primary={true}
          modalType="type"
        />
      )}
      {showAddTitleModal && (
        <AddTypeOrTitleModal
          showActions={true}
          primary={true}
          modalType="title"
        />
      )}
      {showEditTypeModal && (
        <EditTypeOrTitleModal
          showActions={true}
          primary={true}
          modalType="type"
        />
      )}
      {showEditTitleModal && (
        <EditTypeOrTitleModal
          showActions={true}
          primary={true}
          modalType="title"
        />
      )}
    </>
  );
};

export default connect((state) => ({
  show: getProjectNewMeetingsInfoModal(state),
  currentUser: getCurrentUser(state),
  filters: getProjectNewMeetingsFilters(state),
  project: getCurrentProject(state),
  tab: getProjectMeetingsParentsInfoTab(state),
  form: getProjectNewMeetingsForm(state),
  parents: getProjectNewMeetingsParents(state),
  instances: getProjectNewMeetingsInstances(state),
  meetingsTitles: getMeetingsTitles(state),
  meetingsTypes: getMeetingsTypes(state),
  meetingsParentsTitles: getMeetingsParentsTitles(state),
  meetingsParentsTypes: getMeetingsParentsTypes(state),
}))(InfoMeetingModal);
