import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Companies from 'components/admin/companies/Companies';
import CompaniesDetails from 'components/admin/companies/CompaniesDetails';
import CompaniesEdit from 'components/admin/companies/CompaniesEdit';
import CompanyOnboarding from 'components/admin/companies/CompanyOnboarding';
import GlobalPermissions from 'permissions/global-permissions';
import { getCurrentUser } from 'selectors/authentication';
import { showErrorMessage } from 'actions/messages';

class CompaniesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    this.props.dispatch(
      showErrorMessage(
        'Something went wrong with the request, please try again later.'
      )
    );
  }

  render() {
    const {
      currentUser,
      match: { url },
    } = this.props;
    const { hasError } = this.state;

    if (hasError) return <Redirect to="/" />;

    const canView = GlobalPermissions.canViewCompaniesPage(currentUser) || null;
    return (
      <div>
        <Switch>
          <Route
            exact
            path={`${url}/onboarding`}
            component={CompanyOnboarding}
          />
          {canView && <Route exact path={`${url}/`} component={Companies} />}
          {canView && (
            <Route exact path={`${url}/new`} component={CompaniesEdit} />
          )}
          {canView && (
            <Route exact path={`${url}/:id`} component={CompaniesDetails} />
          )}
          {canView && (
            <Route exact path={`${url}/:id/edit`} component={CompaniesEdit} />
          )}
        </Switch>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
  };
})(CompaniesContainer);
