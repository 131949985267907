import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import './RfaSearchFilter.css';

import Input from 'components/shared/input/Input';
import { TYPING_TIMEOUT } from 'components/shared/navigation/SearchBar';

import { setShowRfaFilters } from 'components/admin/projects/details/project-rfa-log/store/actions';
import { getRfaFilters } from 'components/admin/projects/details/project-rfa-log/store/selectors';
import Button, {
  BUTTON_ICONS,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

const RfaSearchFilter = (props) => {
  const { handleFilterChange: hfc, currentFilters, dispatch } = props;
  const [value, setValue] = useState(currentFilters.search);

  const debouncedTextChange = useDebouncedCallback(
    (value) => hfc({ ...currentFilters, search: value }),
    TYPING_TIMEOUT
  );

  const handleValueChange = (value) => {
    setValue(value);
    debouncedTextChange(value);
  };

  const handleClick = () => dispatch(setShowRfaFilters(true));

  return (
    <div className="rfa-search-filter">
      <Input placeholder="Search" value={value} onChange={handleValueChange} />
      <Button
        icon={BUTTON_ICONS.FILTER}
        color={BUTTON_COLORS.WHITE}
        onClick={handleClick}
      />
    </div>
  );
};

export default connect((state) => {
  return {
    currentFilters: getRfaFilters(state),
  };
})(RfaSearchFilter);
