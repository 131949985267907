import OpenItem from 'domain/open-item';
import User from 'domain/user';

class OpenItemPermissions {
  canManage = (user, project, directory, openItem) => {
    if (User.isAdminOrProjectSubscriber(user, project)) return true;

    if (OpenItem.isCreatedByGcOar(directory, openItem))
      return OpenItem.isCreatedByUserCompany(user, openItem);

    return User.isGcOar(user, project);
  };

  canAdd = (user, project) =>
    User.isAdminGcOar(user, project) || User.isPrimary(user, project);

  canViewReports = (user, project) =>
    User.isAdminGcOar(user, project) ||
    User.isPrimary(user, project) ||
    User.isContractor(user, project);

  canDelete = (user, project, directory, openItem) =>
    (!openItem || !openItem.responded_at) &&
    this.canManage(user, project, directory, openItem);

  canAccelerate = (user, project, openItem) =>
    !OpenItem.isAssignedToUser(user, openItem) &&
    User.isAdminGcOar(user, project);

  canRespond = (user, openItem) =>
    (!openItem || !openItem.resolved) &&
    OpenItem.isAssignedToUser(user, openItem);

  canEdit = (user, project, directory, openItem) =>
    this.canManage(user, project, directory, openItem);
}

export default new OpenItemPermissions();
