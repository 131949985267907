import React from 'react';
import './FormCheckBoxGroup.css';
import LabelCheckBox from 'components/shared/checkbox/LabeledCheckBox';

class FormCheckBoxGroup extends React.Component {
  checkboxGroup() {
    let { options, input } = this.props;

    return options.map((option, index) => {
      return (
        <li className="checkbox" key={index}>
          <LabelCheckBox
            position="left"
            label={option.name}
            checked={input.value.indexOf(option.id) !== -1}
            id={option.id}
            value={option.id}
            onChange={(checked) => {
              const newValue = [...input.value];
              if (checked) {
                newValue.push(option.id);
              } else {
                newValue.splice(newValue.indexOf(option.id), 1);
              }

              return input.onChange(newValue);
            }}
            name={`${input.name}[${option.id}]`}
          />
        </li>
      );
    });
  }

  render() {
    return (
      <div className="form-check-box-group">
        <ul>{this.checkboxGroup()}</ul>
      </div>
    );
  }
}

export default FormCheckBoxGroup;
