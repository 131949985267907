import React from 'react';
import { connect } from 'react-redux';

import HtmlViewer from 'components/shared/html-viewer/HtmlViewer';
import { getCurrentUser } from 'selectors/authentication';

import { getMeetingsInstanceHtml } from './store/selectors';
import { setMeetingsInstanceHtml } from './store/actions';

import html from './MeetingsInstanceDocumentHtml';

const MeetingsInstanceDocumentHtmlViewer = (props) => {
  const { dispatch, meetingsInstanceHtml, project, currentUser } = props;
  const onClose = () => {
    dispatch(setMeetingsInstanceHtml(null));
  };
  if (!meetingsInstanceHtml) return null;
  const htmlWithData = html(meetingsInstanceHtml, project, currentUser);
  return (
    <HtmlViewer
      content={{
        source: htmlWithData,
        type: 'text/html',
      }}
      show={meetingsInstanceHtml !== null}
      onClose={onClose}
    />
  );
};

export default connect(
  (state) => ({
    meetingsInstanceHtml: getMeetingsInstanceHtml(state),
    currentUser: getCurrentUser(state),
  }),
  null
)(MeetingsInstanceDocumentHtmlViewer);
