import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCompanyData } from 'components/admin/companies/store/selectors';
import { simpleScrollToTop } from 'services/utils/scroller-util';
import { formatPhoneNumber } from 'services/utils/text-util';
import {
  loadCompanyById,
  updateCompany,
  createCompany,
  findSimilarCompany,
  inviteExistentCompany,
  goToCompanies,
  goToCompanyDetails,
} from 'components/admin/companies/store/actions';
import CompaniesEditForm from 'components/admin/companies/CompaniesEditForm';
import { getCurrentUser } from 'selectors/authentication';
import SimilarCompaniesModal from './SimilarCompaniesModal';

class CompaniesEdit extends Component {
  constructor(props) {
    super(props);
    const { match, currentUser } = this.props;
    this.state = {
      confirmSimilarCompanies: null,
    };

    this.companyId = (match && match.params.id) || props.id || null;
    if (match.path.indexOf('team') >= 0)
      this.companyId = currentUser.accountable_id;
  }

  componentDidUpdate() {
    simpleScrollToTop();
  }

  componentDidMount() {
    const { companyData, dispatch } = this.props;
    if (this.companyId && (!companyData || !companyData.id)) {
      dispatch(loadCompanyById(this.companyId));
    }
  }

  buildLogo = (logo) => {
    if (logo && logo.image && logo.image.url) logo.url = logo.image.url;
    return logo;
  };

  buildLegal = (companyName) => {
    return (
      `${companyName} has reviewed this Submittal,` +
      ' but only for the purpose of checking for' +
      ' conformance with the design intent expressed in the Contract Documents.' +
      ` ${companyName}’s action on a specific` +
      ' item does not indicate approval of an assembly of' +
      ' which the item is a component, nor of an item as delivered and installed if it does' +
      ' not conform to the Contract Documents.' +
      '\n\n' +
      'Contractor is responsible for checking for deviations between this Submittal and' +
      ' differing information or conditions in the Contract Documents and field conditions;' +
      ' for determining or substantiating the accuracy and completeness of other details such' +
      ' as dimensions and quantities; for substantiating instructions for installation or' +
      ' performance of equipment or systems designed by Contractor; for construction means,' +
      ' methods, techniques, schedules, sequences, procedures, and fabrication processes; for' +
      ' errors and omissions in Submittals; for coordination of the Work of the trades, safety' +
      ' precautions and performing the Work in a safe and satisfactory manner and in conformance' +
      ' with the Contract Documents.' +
      '\n\n' +
      'If more than one submittal review stamp appears on this Submittal, the most stringent action' +
      ' and notations thereon apply.' +
      ` ${companyName} nor Spackle and its` +
      ' consultants, employees, contractors or agents have not reviewed work not within their' +
      ' respective professional discipline or scope of services.'
    );
  };

  buildStamp = (stamp, companyName) => {
    const initialStamp = { destroy: !stamp };
    if (stamp) {
      initialStamp.id = stamp.id;
      initialStamp.legal = stamp.legal;
      initialStamp.stampActions = stamp.stamp_actions;
    }

    initialStamp.legal = initialStamp.legal || this.buildLegal(companyName);
    return initialStamp;
  };

  initialValues = () => {
    const { companyData } = this.props;
    const initialValues = {};
    if (!companyData || !companyData.id) return initialValues;

    initialValues.id = companyData.id;
    initialValues.name = companyData.name;
    initialValues.licenceNumber = companyData.license_number;
    initialValues.logo = this.buildLogo(companyData.logo);
    initialValues.stamp = this.buildStamp(companyData.stamp, companyData.name);

    initialValues.selectedUnionsId = companyData.unions.map(
      (union) => union.id
    );
    if (companyData.other_union) initialValues.selectedUnionsId.push('other');
    initialValues.otherUnion = companyData.other_union;
    initialValues.showOtherUnion = !!companyData.other_union;

    if (companyData.contact) {
      const { contact } = companyData;
      initialValues.contactId = contact.id;
      initialValues.firstName = contact.first_name;
      initialValues.lastName = contact.last_name;
      initialValues.email = contact.email;
      initialValues.phoneNumber = formatPhoneNumber(contact.phone_number);
    }

    if (companyData.location) {
      const { location } = companyData;
      initialValues.locationId = location.id;
      initialValues.streetAddress = location.street_address_1;
      initialValues.unitNumber = location.street_address_2;
      initialValues.city = location.city;
      initialValues.state = location.state;
      initialValues.zipCode = location.zip;
    }

    return initialValues;
  };

  handleCreateCompany = (values) => {
    const { dispatch } = this.props;
    dispatch(createCompany(values)).then((response) => {
      if (response.ok) dispatch(goToCompanyDetails(response.company.id));
    });
  };

  handleUpdateCompany = (companyId, values) => {
    const { dispatch } = this.props;
    dispatch(updateCompany(companyId, values)).then((response) => {
      if (response.ok) dispatch(goToCompanyDetails(response.company.id));
    });
  };

  handleInviteExistentCompany = (companyId) => {
    const { dispatch } = this.props;
    dispatch(inviteExistentCompany(companyId)).then((response) => {
      if (response.ok) dispatch(goToCompanyDetails(response.company.id));
    });
  };

  handleSubmitNewCompany = (values) => {
    const { dispatch } = this.props;

    dispatch(findSimilarCompany(values)).then(({ ok, companies }) => {
      if (!ok) return;

      if (companies.length > 0)
        return this.setState({
          confirmSimilarCompanies: { values, companies },
        });

      this.handleCreateCompany(values);
    });
  };

  handleSubmit = (values) =>
    this.companyId
      ? this.handleUpdateCompany(this.companyId, values)
      : this.handleSubmitNewCompany(values);

  handleCancel = () => {
    const { dispatch } = this.props;
    if (this.companyId) {
      dispatch(goToCompanyDetails(this.companyId));
    } else {
      dispatch(goToCompanies());
    }
  };

  handleResolveSimilar = (companyId) =>
    companyId
      ? this.handleInviteExistentCompany(companyId)
      : this.handleCreateCompany(this.state.confirmSimilarCompanies.values);

  handleCancelSimilar = () => this.setState({ confirmSimilarCompanies: null });

  render() {
    const initialValues = this.initialValues();
    const { confirmSimilarCompanies } = this.state;

    return (
      <>
        <CompaniesEditForm
          initialValues={initialValues.id ? initialValues : null}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
        />

        {confirmSimilarCompanies !== null && (
          <SimilarCompaniesModal
            companies={confirmSimilarCompanies.companies}
            onSubmit={(companyId) => this.handleResolveSimilar(companyId)}
            onClose={() => this.handleCancelSimilar()}
          />
        )}
      </>
    );
  }
}

export default connect((state) => {
  return {
    companyData: getCompanyData(state),
    currentUser: getCurrentUser(state),
  };
})(CompaniesEdit);
