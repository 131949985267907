import { createSelector } from 'reselect';

export const getRfis = createSelector(
  (state) => state.projects.scenes.details.scenes.rfiLog.data.rfis,
  (rfis) => {
    return rfis;
  }
);

export const getTotal = createSelector(
  (state) => state.projects.scenes.details.scenes.rfiLog.data.total,
  (total) => {
    return total;
  }
);

export const getCurrentPage = createSelector(
  (state) => state.projects.scenes.details.scenes.rfiLog.data.currentPage,
  (currentPage) => {
    return currentPage;
  }
);

export const getCurrentStatus = createSelector(
  (state) => state.projects.scenes.details.scenes.rfiLog.data.currentStatus,
  (currentStatus) => {
    return currentStatus;
  }
);

export const getDueDateFilter = createSelector(
  (state) => state.projects.scenes.details.scenes.rfiLog.data.dueDateFilter,
  (dueDateFilter) => {
    return dueDateFilter;
  }
);

export const getPageSize = createSelector(
  (state) => state.projects.scenes.details.scenes.rfiLog.data.pageSize,
  (pageSize) => {
    return pageSize;
  }
);

export const getRfiTabSelected = createSelector(
  (state) => state.projects.scenes.details.scenes.rfiLog.ui.rfiTabSelected,
  (rfiTabSelected) => {
    return rfiTabSelected;
  }
);

export const getRfiModalShow = createSelector(
  (state) => state.projects.scenes.details.scenes.rfiLog.ui.rfiModalShow,
  (rfiModalShow) => {
    return rfiModalShow;
  }
);

export const getRfiResponseModalShow = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.rfiLog.ui.rfiResponseModalShow,
  (rfiResponseModalShow) => {
    return rfiResponseModalShow;
  }
);

export const getRfiResponsesViewModalShow = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.rfiLog.ui.rfiResponsesViewModalShow,
  (rfiResponsesViewModalShow) => {
    return rfiResponsesViewModalShow;
  }
);

export const getRfiForm = createSelector(
  (state) => state.projects.scenes.details.scenes.rfiLog.ui.rfiForm,
  (rfiForm) => {
    return rfiForm;
  }
);

export const getRfiQuestions = createSelector(
  (state) => state.projects.scenes.details.scenes.rfiLog.ui.rfiQuestions,
  (rfiQuestions) => {
    return rfiQuestions;
  }
);

export const getRfiQuestionsDeleted = createSelector(
  (state) => state.projects.scenes.details.scenes.rfiLog.ui.rfiQuestionsDeleted,
  (rfiQuestionsDeleted) => {
    return rfiQuestionsDeleted;
  }
);

export const getCurrentRfi = createSelector(
  (state) => state.projects.scenes.details.scenes.rfiLog.data.currentRfi,
  (currentRfi) => {
    return currentRfi;
  }
);

export const getRfiReadOnly = createSelector(
  (state) => state.projects.scenes.details.scenes.rfiLog.ui.rfiReadOnly,
  (rfiReadOnly) => {
    return rfiReadOnly;
  }
);

export const getRfiGcAcceleration = createSelector(
  (state) => state.projects.scenes.details.scenes.rfiLog.ui.rfiGcAcceleration,
  (rfiGcAcceleration) => {
    return rfiGcAcceleration;
  }
);

export const getRfiLogPdfViewer = createSelector(
  (state) => state.projects.scenes.details.scenes.rfiLog.ui.rfiLogPdfViewer,
  (rfiLogPdfViewer) => {
    return rfiLogPdfViewer;
  }
);

export const getRfiLogPdf = createSelector(
  (state) => state.projects.scenes.details.scenes.rfiLog.data.rfiLogPdf,
  (rfiLogPdf) => {
    return rfiLogPdf;
  }
);

export const getRfiPdfViewer = createSelector(
  (state) => state.projects.scenes.details.scenes.rfiLog.ui.rfiPdfViewer,
  (rfiPdfViewer) => {
    return rfiPdfViewer;
  }
);

export const getRfiChatImages = createSelector(
  (state) => state.projects.scenes.details.scenes.rfiLog.ui.chatImages,
  (chatImages) => {
    return chatImages;
  }
);

export const getRfiChatDocuments = createSelector(
  (state) => state.projects.scenes.details.scenes.rfiLog.ui.chatDocuments,
  (chatDocuments) => {
    return chatDocuments;
  }
);

export const getRfiShowErrorMessage = createSelector(
  (state) => state.projects.scenes.details.scenes.rfiLog.ui.rfiShowErrorMessage,
  (rfiShowErrorMessage) => {
    return rfiShowErrorMessage;
  }
);
