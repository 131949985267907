import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ProjectOpenItemsModal.css';
import FormModal from 'components/shared/modal/FormModal';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getShowOpenItemsModal,
  getCritical,
} from 'components/admin/projects/details/project-open-items/store/selectors';
import {
  showOpenItemsReportModal,
  closeOpenItemsModal,
  showOpenItemFormModal,
  loadOpenItem,
  openItemsCount,
} from 'components/admin/projects/details/project-open-items/store/actions';
import { markNotifiableNotificationsAsRead } from 'actions/notifications';
import ProjectOpenItemsTable from 'components/admin/projects/details/project-open-items/ProjectOpenItemsTable';
import ProjectOpenItemFormModal from 'components/admin/projects/details/project-open-items/ProjectOpenItemFormModal';
import ProjectOpenItemDetailsModal from 'components/admin/projects/details/project-open-items/ProjectOpenItemDetailsModal';
import Button, { BUTTON_COLORS } from 'components/shared/button/Button';
import OpenItemPermissions from 'permissions/open-item-permissions';

class ProjectOpenItemsModal extends Component {
  handleOpenItemsModalClose = () => {
    const { currentProject, dispatch } = this.props;
    dispatch(openItemsCount(currentProject.id));
    dispatch(closeOpenItemsModal());
  };

  handleAddNewOpenItem = () => {
    this.props.dispatch(showOpenItemFormModal());
  };

  handleShowReports = () => {
    const { dispatch } = this.props;
    this.handleOpenItemsModalClose();
    dispatch(showOpenItemsReportModal());
  };

  handleOpenItemSelected = (id) => {
    const { currentProject, dispatch } = this.props;
    dispatch(loadOpenItem(currentProject.id, id));
    dispatch(markNotifiableNotificationsAsRead(id, 'ProjectOpenItem'));
  };

  modalTitle = () => {
    return this.props.critical ? 'Critical Open Items' : 'Open Items';
  };

  modalActions = () => {
    const { currentUser, currentProject } = this.props;

    const canAddOpenItem = OpenItemPermissions.canAdd(
      currentUser,
      currentProject
    );

    const canShowReports = OpenItemPermissions.canViewReports(
      currentUser,
      currentProject
    );

    return [
      canAddOpenItem && (
        <Button
          key="add-item"
          color={BUTTON_COLORS.GREEN}
          label={this.props.critical ? 'Add Critical Item' : 'Add Open Item'}
          onClick={this.handleAddNewOpenItem}
        />
      ),
      canShowReports && (
        <Button
          key="reports"
          color={BUTTON_COLORS.GREEN}
          label="Reports"
          onClick={this.handleShowReports}
        />
      ),
    ];
  };

  render() {
    const { currentProject, showOpenItemsModal } = this.props;
    return (
      showOpenItemsModal && (
        <React.Fragment>
          <FormModal
            className="project-open-items-modal"
            show={showOpenItemsModal}
            onClose={this.handleOpenItemsModalClose}
            subTitle={currentProject.name}
            title={this.modalTitle()}
            actions={this.modalActions()}
          >
            <ProjectOpenItemsTable
              onRowSelected={this.handleOpenItemSelected}
            />
          </FormModal>

          <ProjectOpenItemFormModal />
          <ProjectOpenItemDetailsModal />
        </React.Fragment>
      )
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    showOpenItemsModal: getShowOpenItemsModal(state),
    critical: getCritical(state),
  };
})(ProjectOpenItemsModal);
