import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import './Invitation.css';
import './ShowInvitation.css';
import { InvitationPropTypes } from './UserInvitations';

class ShowInvitation extends Component {
  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.number,
      ...InvitationPropTypes,
    }),
    onDelete: PropTypes.func,
  };

  handleRemove = () => {
    this.props.onDelete(this.props.user.id);
  };

  render() {
    return (
      <div className="invitation-container show-invitation">
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={12}>
                <span className="label name">
                  {this.props.user.firstName + ' ' + this.props.user.lastName}
                </span>
              </Col>
              <Col lg={12}>
                <span className="label">{this.props.user.email}</span>
              </Col>
              {this.props.user.title !== '' && (
                <Col lg={12}>
                  <span className="label">{this.props.user.title}</span>
                </Col>
              )}
            </Row>
          </Col>

          <Col lg={12}>
            <Row>
              <Col lg={12}>
                <div className="permissions-table">
                  {this.props.user.permissions.map((permission) => {
                    return (
                      <div
                        className="permissions-table-row"
                        key={permission.id}
                      >
                        <div className="permissions-table-cell permissions-table-cell-checkbox">
                          {permission.selected ? (
                            <span className="glyphicon glyphicon-ok selected-icon" />
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="permissions-table-cell">
                          <span
                            className={
                              permission.selected
                                ? 'label'
                                : 'not-selected label'
                            }
                          >
                            {permission.label}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Col>
              <Col lg={2}>
                <Row>
                  <Col lg={12}>
                    <span className="button-remove" onClick={this.handleRemove}>
                      Remove
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ShowInvitation;
