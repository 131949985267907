import { SET_PROJECT_AREAS, SET_PROJECT_SITES } from './action-types';

const initialState = {
  projectId: null,
  sites: [],
  areas: [],
};

export default function projectSitesDataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROJECT_SITES:
      return {
        ...state,
        sites: action.payload.sites,
        projectId: action.payload.projectId,
      };
    case SET_PROJECT_AREAS:
      return {
        ...state,
        areas:
          action.payload.projectId === state.projectId && action.payload.areas
            ? action.payload.areas
            : [],
      };
    default:
      return state;
  }
}
