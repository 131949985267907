import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';

class CoiExpDocsApi extends BaseApi {
  getCoiExpDocs(coiExpId, page = 1) {
    const query = `page=${page}&per_page=5`;
    return axios
      .get(`${this.baseUrl}/coi_exps/${coiExpId}/coi_exp_docs?${query}`)
      .then((response) => {
        return {
          ok: true,
          coiExpDocs: response.data,
          coiExpDocsTotal: numeral(response.headers['total']).value(),
          coiExpDocsPageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createCoiExpDoc(coiExpId, values) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = this.coiExpDocParams(values);

    return axios
      .post(`${this.baseUrl}/coi_exps/${coiExpId}/coi_exp_docs`, data, headers)
      .then((response) => {
        return {
          ok: true,
          coiExpDoc: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateCoiExpDoc(coiExpId, values) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = this.coiExpDocParams(values);

    return axios
      .put(
        `${this.baseUrl}/coi_exps/${coiExpId}/coi_exp_docs/${values.id}`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          coiExpDoc: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  destroyCoiExpDoc(coiExpId, coiExpDocId) {
    return axios
      .delete(
        `${this.baseUrl}/coi_exps/${coiExpId}/coi_exp_docs/${coiExpDocId}`
      )
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  coiExpDocParams(values) {
    const apiPayload = new FormData();
    apiPayload.append('coi_exp_doc[subcategory]', values.subcategory);
    if (!values.id) {
      apiPayload.append(
        'coi_exp_doc[document_attributes][document]',
        values.coiExpDocFile,
        values.coiExpDocFile.name
      );
    }

    values.coiExpDocLiabilities.forEach((coiExpDocLiability, index) => {
      if (coiExpDocLiability.id) {
        apiPayload.append(
          `coi_exp_doc[coi_exp_doc_liabilities_attributes][${index}][id]`,
          coiExpDocLiability.id
        );
        apiPayload.append(
          `coi_exp_doc[coi_exp_doc_liabilities_attributes][${index}][_destroy]`,
          coiExpDocLiability.destroy
        );
      }

      if (!coiExpDocLiability.destroy) {
        apiPayload.append(
          `coi_exp_doc[coi_exp_doc_liabilities_attributes][${index}][liability_id]`,
          coiExpDocLiability.liabilityId
        );
        apiPayload.append(
          `coi_exp_doc[coi_exp_doc_liabilities_attributes][${index}][exp_date]`,
          coiExpDocLiability.expDate
        );
      }
    });

    return apiPayload;
  }
}

export const coiExpDocsApi = new CoiExpDocsApi();
