import React, { Component } from 'react';
import './Account.css';
import PropTypes from 'prop-types';
import User from 'domain/user';

class Account extends Component {
  static propTypes = {
    user: PropTypes.shape({}).isRequired,
  };

  render() {
    const { user } = this.props;
    return (
      <div className="account">
        <div className="account-name">{User.fullName(user)}</div>
        <div className="account-type">{User.companyName(user)}</div>
        <div className="account-address">{user.email}</div>
      </div>
    );
  }
}

export default Account;
