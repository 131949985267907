import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import classnames from 'classnames';
import './SidebarForm.css';

function SidebarForm(props) {
  const {
    className,
    title,
    actions,
    onChange,
    darkMode,
    selectedSection,
    titleButtons,
  } = props;
  const sections = props.sections.filter((section) => section.show !== false);
  const [sectionId, setSectionId] = useState(
    selectedSection || (sections.length > 0 ? sections[0].id : null)
  );
  const currentSection = sections.find((section) => section.id === sectionId);

  useEffect(() => {
    if (sections.find((section) => section.id === selectedSection))
      setSectionId(selectedSection);
  }, [selectedSection]);

  const handleChange = (section) => {
    setSectionId(section.id);
    if (onChange) onChange(section);
  };

  return (
    <div
      className={classnames({
        'sidebar-form': true,
        [className]: !!className,
        'dark-mode': darkMode,
      })}
    >
      <div className="sidebar-form-sidebar">
        {!!title && <div className="sidebar-form-title">{title}</div>}
        <div className="sidebar-form-options">
          {sections
            .filter((section) => section.filter !== false)
            .map((section) => (
              <div
                key={section.id}
                className={classnames({
                  'sidebar-form-option': true,
                  'dark-mode': darkMode,
                  error: section.error,
                  selected: section.id === sectionId,
                })}
                onClick={() => handleChange(section)}
              >
                {section.title}
              </div>
            ))}
        </div>
        {actions.length > 0 && (
          <div className="sidebar-form-actions">{actions}</div>
        )}
      </div>
      <div className="sidebar-form-body">
        {!!currentSection && (
          <React.Fragment>
            {titleButtons.length > 0 ? (
              <div className="title-and-buttons-container">
                <div className="sidebar-form-body-title">
                  {currentSection.title}
                </div>
                <div className="title-buttons-container">
                  {titleButtons.map((tb) => tb)}
                </div>
              </div>
            ) : (
              <div className="sidebar-form-body-title">
                {currentSection.title}
              </div>
            )}
            <div className="sidebar-form-body-content">
              {currentSection.render()}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

SidebarForm.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      show: PropTypes.bool,
      error: PropTypes.bool,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      render: PropTypes.func,
    })
  ).isRequired,
  actions: PropTypes.arrayOf(PropTypes.element),
  selectedSection: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  titleButtons: PropTypes.arrayOf(PropTypes.shape({})),
};

SidebarForm.defaultProps = {
  className: '',
  title: null,
  actions: [],
  onChange: null,
  titleButtons: [],
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(SidebarForm);
