import React, { Component } from 'react';
import Stepper from 'react-stepper-horizontal';
import { setStage } from './store/actions';
import { connect } from 'react-redux';
import { getCurrentStage } from './store/selectors';
import { getDarkMode } from 'selectors/theme';

class OnboardingStatus extends Component {
  handleStageChange = (value) => {
    this.props.dispatch(setStage(value));
  };

  render() {
    const { darkMode } = this.props;

    return (
      <Stepper
        steps={[
          {
            title: 'Contractor License',
            href: null,
            onClick: () => {
              this.handleStageChange('licence-input');
            },
          },
          {
            title: 'Company Logo ',
            href: null,
            onClick: () => {
              this.handleStageChange('logo-upload');
            },
          },
        ]}
        size={16}
        lineMarginOffset={0}
        activeStep={this.props.activeStep}
        activeColor={darkMode ? '#ffffff' : '#eaeaec'}
        completeColor={darkMode ? '#d4fb00' : '#00b091'}
        defaultColor={darkMode ? '#909197' : '#9ea0aa'}
        circleFontSize={0}
        completeBarColor={darkMode ? '#ffffff' : '#eaeaec'}
        defaultBarColor={darkMode ? '#bcbdc1' : '#7a7e8b'}
        defaultTitleColor={darkMode ? '#909197' : '#9ea0aa'}
        activeTitleColor={darkMode ? '#ffffff' : '#eaeaec'}
        completeTitleColor={darkMode ? '#d4fb00' : '#00b091'}
      />
    );
  }
}

export default connect((state) => {
  return {
    currentStage: getCurrentStage(state),
    darkMode: getDarkMode(state),
  };
})(OnboardingStatus);
