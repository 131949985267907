import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './CoiExpDocs.css';
import classnames from 'classnames';
import removeSvg from 'images/deleteCross.svg';
import removeDarkSvg from 'images/deleteCrossDarkMode.svg';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getLiabilities } from 'components/admin/projects/details/project-coi-exps/store/selectors';
import {
  loadCoiExpRequest,
  createCoiExpDoc,
  updateCoiExpDoc,
  destroyCoiExpDoc,
} from 'components/admin/projects/details/project-coi-exps/store/actions';
import SimpleTable from 'components/shared/table/SimpleTable';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import DocumentUpload, {
  FILE_TYPES,
} from 'components/shared/document-upload/DocumentUpload';
import DocumentLink from 'components/shared/document/DocumentLink';
import Date from 'components/shared/text/Date';
import moment from 'moment';
import CoiExpDocModal from 'components/admin/projects/details/project-coi-exps/CoiExpDocModal';
import { getDarkMode } from 'selectors/theme';

class CoiExpDocs extends Component {
  static propTypes = {
    coiExpId: PropTypes.number.isRequired,
    coiExpDocs: PropTypes.shape({}),
    readOnly: PropTypes.bool,
    onPageChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    coiExpDocs: {
      coiExpDocs: [],
      coiExpDocsTotal: 0,
      coiExpDocsCurrentPage: 1,
      coiExpDocsPageSize: 5,
      readOnly: true,
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      showCoiExpDocModal: false,
      coiExpDocFile: null,
      currentCoiExpDoc: null,
    };
  }

  handlePageChange = (page) => {
    const { onPageChange } = this.props;
    onPageChange(page);
  };

  expiresSoon = (coiExpDocLiability) => {
    return (
      coiExpDocLiability &&
      moment().diff(coiExpDocLiability.exp_date, 'days') > -2
    );
  };

  expired = (coiExpDocLiability) => {
    return (
      coiExpDocLiability &&
      moment().diff(coiExpDocLiability.exp_date, 'days') >= 0
    );
  };

  mapCoiExpDocs = () => {
    const { coiExpDocs, liabilities, readOnly, darkMode } = this.props;
    const coiExpDocsList = coiExpDocs.coiExpDocs;
    return coiExpDocsList.map((coiExpDoc) => {
      return {
        id: coiExpDoc.id,
        data: [
          !readOnly && (
            <div className="doc-viewer">
              {coiExpDoc.subcategory === 'client_landlord'
                ? 'Owner/Landlord'
                : 'General Contractor'}
              <img
                className="remove-doc-icon"
                src={darkMode ? removeDarkSvg : removeSvg}
                alt="remove"
                onClick={(event) =>
                  this.handleRemoveCoiExpDoc(event, coiExpDoc)
                }
              />
            </div>
          ),
          coiExpDoc.document && (
            <DocumentLink url={coiExpDoc.document.document.url} />
          ),
          ...liabilities.map((liability) => {
            const docLiability = coiExpDoc.coi_exp_doc_liabilities.find(
              (coiExpDocLiability) =>
                coiExpDocLiability.liability_id === liability.id
            );
            return docLiability ? (
              <Date
                className={classnames({
                  'expires-soon': this.expiresSoon(docLiability),
                  expired: this.expired(docLiability),
                })}
                value={docLiability.exp_date}
                format="MM/DD/YYYY"
              />
            ) : (
              'N/A'
            );
          }),
        ].filter((col) => !!col),
      };
    });
  };

  handleDrop = (files) => {
    this.setState({
      showCoiExpDocModal: true,
      coiExpDocFile: files[0],
      coiExpDoc: null,
    });
  };

  handleCloseCoiExpDocModal = () => {
    this.setState({
      showCoiExpDocModal: false,
      coiExpDocFile: null,
      coiExpDoc: null,
    });
  };

  handleRowSelected = (id) => {
    const { coiExpDocs } = this.props.coiExpDocs;
    const coiExpDoc = coiExpDocs.find((coiExpDoc) => coiExpDoc.id === id);
    this.setState({
      showCoiExpDocModal: true,
      coiExpDocFile: null,
      coiExpDoc: coiExpDoc,
    });
  };

  handleSubmitCoiExpDoc = ({ subcategory, coiExpDocLiabilities }) => {
    const { currentProject, coiExpId, dispatch } = this.props;
    const { coiExpDocFile, coiExpDoc } = this.state;
    const values = { coiExpDocFile, subcategory, coiExpDocLiabilities };
    let promise = null;
    if (coiExpDocFile && !coiExpDoc) {
      promise = dispatch(createCoiExpDoc(coiExpId, values));
    } else if (coiExpDoc && !coiExpDocFile) {
      promise = dispatch(
        updateCoiExpDoc(coiExpId, { id: coiExpDoc.id, ...values })
      );
    }

    promise &&
      promise.then((response) => {
        if (response.ok) {
          dispatch(loadCoiExpRequest(currentProject.id, coiExpId));
          this.handleCloseCoiExpDocModal();
        }
      });
  };

  handleRemoveCoiExpDoc = (event, coiExpDoc) => {
    event.stopPropagation();
    const confirmed = window.confirm('Are you sure?');
    if (confirmed) {
      const { currentProject, coiExpId, dispatch } = this.props;
      dispatch(destroyCoiExpDoc(coiExpId, coiExpDoc)).then((response) => {
        if (response.ok) {
          dispatch(loadCoiExpRequest(currentProject.id, coiExpId));
        }
      });
    }
  };

  render() {
    const { coiExpDocs, liabilities, readOnly, darkMode } = this.props;
    const {
      coiExpDocsTotal,
      coiExpDocsCurrentPage,
      coiExpDocsPageSize,
    } = coiExpDocs;
    const { showCoiExpDocModal, coiExpDoc } = this.state;
    return (
      <div
        className={`coi-exp-docs ${darkMode ? 'dark-mode' : ''} ${
          !readOnly ? 'with-cert-holder' : ''
        }`}
      >
        <SimpleTable
          className={classnames({
            'projects-table': true,
            'coi-exp-docs-table': true,
            'read-only': readOnly,
          })}
          headers={[
            !readOnly ? 'CERT HOLDER' : false,
            'FILENAME',
            ...Array(liabilities.length).fill(''),
          ].filter((header) => header !== false)}
          rows={this.mapCoiExpDocs()}
          emptyMessage="No Documents"
          onRowSelected={this.handleRowSelected}
        />
        {!readOnly && (
          <DocumentUpload fileType={FILE_TYPES.PDF} onDrop={this.handleDrop} />
        )}
        <div className="pagination-container">
          <SimplePagination
            currentPage={coiExpDocsCurrentPage}
            pageSize={coiExpDocsPageSize}
            total={coiExpDocsTotal}
            onPageChange={this.handlePageChange}
          />
        </div>
        {showCoiExpDocModal && (
          <CoiExpDocModal
            initialValues={coiExpDoc}
            showModal={showCoiExpDocModal}
            onSubmit={this.handleSubmitCoiExpDoc}
            onClose={this.handleCloseCoiExpDocModal}
          />
        )}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    liabilities: getLiabilities(state),
    darkMode: getDarkMode(state),
  };
})(CoiExpDocs);
