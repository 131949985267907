import React from 'react';
import PropTypes from 'prop-types';
import ImageUpload from 'components/shared/image-upload/ImageUpload';
import DocumentLink from 'components/shared/document/DocumentLink';

export default function ChatAttachments(props) {
  const { note } = props;

  return (
    <React.Fragment>
      {note.documents && note.documents.length ? (
        <div className="chat-attachments">
          {note.documents.map((doc, index) => {
            return (
              <DocumentLink
                key={index}
                file={doc.document}
                url={doc.document.url}
              />
            );
          })}
        </div>
      ) : null}
      {note.images && note.images.length ? (
        <div className="chat-images">
          {note.images.map((img, index) => {
            return (
              <ImageUpload
                key={index}
                images={[
                  {
                    id: img.id,
                    url: img.image.url,
                    preview: img.image.preview.url,
                  },
                ]}
                readOnly={true}
              />
            );
          })}
        </div>
      ) : null}
    </React.Fragment>
  );
}

ChatAttachments.propTypes = {
  note: PropTypes.shape(),
};
