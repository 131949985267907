class Meeting {
  isStatus = (meeting, status) => !!meeting && meeting.status === status;

  isApproved = (meeting) => this.isStatus(meeting, 'approved');

  isLast = (meeting, meetings) =>
    !!meeting && !!meetings && meeting.id === meetings[meetings.length - 1].id;

  isUserAssignedToItem = (meetingItem, user) =>
    !!meetingItem &&
    meetingItem.contributors.some((contributor) => contributor.id === user.id);

  isNewItem = (meetingItem) => !!meetingItem && !!meetingItem.new_item;

  isResolvedItem = (meetingItem) => !!meetingItem && !!meetingItem.resolved;
}

export default new Meeting();
