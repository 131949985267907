import {
  PROJECT_LOCATIONS_NEW_CLEAR_DATA,
  PROJECT_LOCATIONS_NEW_FORM_VALUE_CHANGE,
  PROJECT_LOCATIONS_NEW_FORM_INVALID,
  PROJECT_LOCATIONS_NEW_FORM_VALID,
  PROJECT_LOCATIONS_PROJECT_LOCATION_WORK_ORDERS_PAGE_CHANGE,
  PROJECT_LOCATIONS_EDIT_FORM_LOAD_FROM_CURRENT,
  PROJECT_LOCATIONS_SET_PAGINATION_DATA,
} from '../../../actions/admin/project-locations/action-types';

const initialState = {
  currentPage: 1,
  engineerAdded: false,
  files: [], // array { removed: boolean, file: file uploaded, document: document from backend }
  form: {
    projectLocationName: '',
    streetAddress: '',
    unitNumber: '',
    city: '',
    state: '',
    zipCode: '',
    managerFirstName: '',
    managerLastName: '',
    managerEmail: '',
    managerPhoneNumber: '',
    engineerFirstName: '',
    engineerLastName: '',
    engineerEmail: '',
    engineerPhoneNumber: '',
  },
  invalidFields: [],
  parkingOnSite: null, // boolean
  selectedClientsId: [],
  selectedContractors: [], // array { value, label }
  selectedUnionsId: [],
  validationMessage: '',
  projectLocationsCurrentPage: 1,
  projectLocationsTotal: 0,
  projectLocationsPageSize: 25,
};

export default function projectLocationsUiReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case PROJECT_LOCATIONS_PROJECT_LOCATION_WORK_ORDERS_PAGE_CHANGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case PROJECT_LOCATIONS_EDIT_FORM_LOAD_FROM_CURRENT:
      return {
        ...state,
        engineerAdded: action.payload.engineer,
        form: {
          projectLocationName: action.payload.name,
          locationId: action.payload.location.id,
          streetAddress: action.payload.location.street_address_1,
          unitNumber: action.payload.location.street_address_2
            ? action.payload.location.street_address_2
            : '',
          city: action.payload.location.city,
          state: action.payload.location.state,
          zipCode: action.payload.location.zip,
        },
        invalidFields: [],
        validationMessage: '',
      };

    case PROJECT_LOCATIONS_NEW_CLEAR_DATA:
      return initialState;

    case PROJECT_LOCATIONS_NEW_FORM_INVALID:
      return {
        ...state,
        invalidFields: action.payload.invalidFields,
        validationMessage: action.payload.message,
      };

    case PROJECT_LOCATIONS_NEW_FORM_VALID:
      return {
        ...state,
        invalidFields: [],
        validationMessage: '',
      };

    case PROJECT_LOCATIONS_NEW_FORM_VALUE_CHANGE:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case PROJECT_LOCATIONS_SET_PAGINATION_DATA:
      return {
        ...state,
        projectLocationsCurrentPage: action.payload.page,
        projectLocationsTotal: action.payload.total,
        projectLocationsPageSize: action.payload.pageSize,
      };

    default:
      return state;
  }
}
