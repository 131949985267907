import {
  SURVEYS_PAGINATION_INFO_SET,
  SURVEYS_PAGINATION_PAGE_CHANGE,
  SURVEYS_SURVEY_FORM_ATTRIBUTE_CHANGE,
  SURVEYS_SURVEY_FORM_SET,
  SURVEYS_SURVEY_FORM_VALIDATION_MESSAGE_SET,
  SURVEYS_TOTAL_PENDING_SET,
} from 'components/admin/surveys/store/action-types';

const initialState = {
  surveyForm: null,
  paginationInfo: null, // { currentPage: number, pageSize: number, total: number }
  pendingTotal: 0,
  showValidationMessage: false,
};

export default function surveysUiReducer(state = initialState, action) {
  switch (action.type) {
    case SURVEYS_PAGINATION_INFO_SET:
      return {
        ...state,
        paginationInfo: {
          currentPage: action.payload.page,
          pageSize: action.payload.pageSize,
          total: action.payload.total,
        },
      };

    case SURVEYS_PAGINATION_PAGE_CHANGE:
      return {
        ...state,
        paginationInfo: {
          ...state.paginationInfo,
          currentPage: action.payload,
        },
      };

    case SURVEYS_SURVEY_FORM_ATTRIBUTE_CHANGE:
      return {
        ...state,
        surveyForm: {
          ...state.surveyForm,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case SURVEYS_SURVEY_FORM_SET:
      return {
        ...state,
        showValidationMessage: false,
        surveyForm: action.payload,
      };

    case SURVEYS_SURVEY_FORM_VALIDATION_MESSAGE_SET:
      return {
        ...state,
        showValidationMessage: action.payload,
      };

    case SURVEYS_TOTAL_PENDING_SET:
      return {
        ...state,
        pendingTotal: action.payload,
      };

    default:
      return state;
  }
}
