import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import SearchableDropDownPaginated from 'components/shared/dropdown/SearchableDropDownPaginated';
import { Field, reduxForm } from 'redux-form';
import 'components/admin/projects/details/project-directory/NewContributorForm.css';
import Selector from 'components/shared/selector/Selector';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import MultipleSelectList from 'components/shared/select/MultipleSelectList';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

class NewContributorForm extends Component {
  renderCompanySearchableDropDown = (props) => {
    return (
      <SearchableDropDownPaginated
        valid={!props.meta.error || !props.meta.submitFailed}
        defaultArrow={true}
        withCreate={true}
        loadOptions={this.props.loadCompanies}
        value={props.input.value}
        onChange={(input) => this.props.handleCompanyOptionClick(props, input)}
        {...props}
      />
    );
  };

  renderTypeDropDown = (props) => {
    return (
      <Selector
        valid={!props.meta.error || !props.meta.submitFailed}
        value={props.input.value}
        onChange={(option) =>
          this.props.handleNewContributorTypeChange(props, option.value)
        }
        {...props}
      />
    );
  };

  renderDropDown = (props) => {
    return (
      <Selector
        valid={!props.meta.error || !props.meta.submitFailed}
        value={props.input.value}
        onChange={(option) => props.input.onChange(option && option.value)}
        {...props}
      />
    );
  };

  renderMultipleSelect = (props) => {
    return (
      <MultipleSelectList
        options={props.options.map((user) => {
          return {
            id: user.id,
            selected: props.input.value.includes(user.id),
            value: `${user.first_name} ${user.last_name}${
              user.title ? ` - ${user.title}` : ''
            }`,
          };
        })}
        show={5}
        onSelect={(id) => this.handleUserSelection(props, id)}
      />
    );
  };

  handleUserSelection = (props, id) => {
    if (props.input.value.includes(id)) {
      props.input.onChange(props.input.value.filter((item) => item !== id));
    } else {
      props.input.onChange(props.input.value.concat(id));
    }
  };

  renderCheckBox = (props) => {
    return (
      <LabeledCheckBox
        checked={!!props.input.value}
        onChange={(value) => props.input.onChange(value)}
        {...props}
      />
    );
  };

  render() {
    return (
      <div className="new-contributor-form">
        <Form horizontal onSubmit={this.props.handleSubmit}>
          <div className="form-block">
            <label className="form-block-label">Company Name</label>
            <Field
              component={this.renderCompanySearchableDropDown}
              name="contributorCompanyId"
              placeholder="Select Company"
            />
          </div>

          <div className="form-block">
            <label className="form-block-label">Employees</label>
            <Field
              component={this.renderMultipleSelect}
              name="userIds"
              options={this.props.companyUsers}
            />
          </div>
          <div className="form-block">
            <label className="form-block-label">Contributor Type</label>
            <Field
              component={this.renderTypeDropDown}
              name="contributorCompanyType"
              placeholder="Select Type"
              options={this.props.types}
            />
          </div>
          <div className="form-block">
            <label className="form-block-label">Cost Code</label>
            <Field
              component={this.renderDropDown}
              name="csiCodeId"
              placeholder="Select Code"
              options={this.props.csiCodes
                .filter(
                  (csiCode) =>
                    this.props.newContributorType &&
                    csiCode.contributor_type === this.props.newContributorType
                )
                .map((code) => {
                  return {
                    value: code.id,
                    label: `${code.division} - ${code.label}`,
                  };
                })}
            />
          </div>
          <div className="form-block">
            <Field
              component={this.renderCheckBox}
              name="sendNotifications"
              label={
                <div className="send-notifications-checkbox-label">
                  <div className="small-regular">Notifications</div>
                  <div className="small-bold">
                    Contacts will receive notifications for changes to the
                    project.
                  </div>
                </div>
              }
            />
          </div>
          {this.props.invalid && this.props.submitFailed && (
            <div className="invalid-message">
              The outlined inputs should be added in order to add a Contributor.
            </div>
          )}
          <div className="buttons-container">
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Cancel"
              onClick={this.props.onClose}
            />
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Add"
              onClick={this.props.handleSubmit}
              disabled={this.props.invalid}
            />
          </div>
        </Form>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};
  if (!values.contributorCompanyType) {
    errors.contributorCompanyType = 'Required';
  }

  if (!values.contributorCompanyId) {
    errors.contributorCompanyId = 'Required';
  }

  if (!values.userIds || values.userIds.length === 0) {
    errors.userIds = 'Required';
  }

  if (values.contributorCompanyType === 'Contractor') {
    if (!values.csiCodeId) {
      errors.csiCodeId = 'Required';
    }
  }

  return errors;
}

export default reduxForm({
  form: 'NewContributorForm',
  validate,
})(NewContributorForm);
