import { WORK_ORDERS_DETAILS_ADD_OPEN_ITEM_MEMBERS_LOAD_SUCCESS } from '../../../../actions/admin/work-orders/action-types';

const initialState = {
  members: [],
};

export default function openItemsDataReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_DETAILS_ADD_OPEN_ITEM_MEMBERS_LOAD_SUCCESS:
      return {
        ...state,
        members: action.payload,
      };

    default:
      return state;
  }
}
