import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { showErrorMessage } from 'actions/messages';
import Superadmin from 'components/admin/superadmin/Superadmin';

/**
 * Parent container for all superadmin components/routes. Also catch render errors and redirects to /
 */
class SuperadminContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    this.props.dispatch(
      showErrorMessage(
        'Something went wrong with the request, please try again later.'
      )
    );
  }

  render() {
    if (this.state.hasError) {
      return <Redirect to="/" />;
    } else {
      return (
        <React.Fragment>
          <Switch>
            <Route exact path={this.props.match.url} component={Superadmin} />
          </Switch>
        </React.Fragment>
      );
    }
  }
}

export default SuperadminContainer;
