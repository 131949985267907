import { createSelector } from 'reselect';

export const getFieldReports = createSelector(
  (state) => state.projects.scenes.details.scenes.fieldReports.data.reports,
  (reports) => reports
);

export const getCurrentFieldReport = createSelector(
  (state) => state.projects.scenes.details.scenes.fieldReports.data.report,
  (report) => report
);

export const getFieldReportFilters = createSelector(
  (state) => state.projects.scenes.details.scenes.fieldReports.data.filters,
  (filters) => filters
);

export const getFieldReportPdf = createSelector(
  (state) => state.projects.scenes.details.scenes.fieldReports.data.reportPdf,
  (pdf) => pdf
);

export const getFieldReportHtml = createSelector(
  (state) => state.projects.scenes.details.scenes.fieldReports.data.reportHtml,
  (html) => html
);

export const getFieldReportDeleteModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.fieldReports.ui.showDeleteConfirmation,
  (show) => show
);

export const getFieldReportForm = createSelector(
  (state) => state.projects.scenes.details.scenes.fieldReports.ui.showForm,
  (show) => show
);

export const getFieldReportsResovedModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.fieldReports.ui.showResolvedModal,
  (show) => show
);

export const getFieldReportDraftModal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.fieldReports.ui.showDraftModal,
  (show) => show
);

export const getFieldReportsCreators = createSelector(
  (state) => state.projects.scenes.details.scenes.fieldReports.data.creators,
  (creators) => creators
);
export const getShowWaitingPreview = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.fieldReports.ui.showWaitingPreview,
  (show) => show
);
