import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  setFormMeetingInstance,
  setFormProjectMeetings,
} from './store/actions';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import SimpleTable from 'components/shared/table/SimpleTable';
import RadioButton from '../../../../shared/radio-button/RadioButton';
import {
  getMeetingsInstanceForm,
  getProjectNewMeetingsForm,
} from './store/selectors';
import { capitalizeFirstLetters } from '../../../../../services/utils/case-utils';
import './MeetingsInstanceForm.css';
import './MeetingsInstanceFormCorrections.css';
const MeetingsInstanceFormCorrections = (props) => {
  const { dispatch, instanceForm, form, currentUser } = props;
  const [editingRows, setEditingRows] = useState({});

  const parseDate = (date) => {
    const d = moment(date);
    return d.isValid() ? d.utc().format('MM/DD/YYYY') : '--';
  };

  const correctionsHeaders = [
    'ITEM NO',
    'CORRECTION REMARK',
    'SUBMITTED BY',
    'DATE',
    'STATUS',
    '',
  ];
  const statusOptions = [
    { value: 'approved', label: 'Approve' },
    { value: 'rejected', label: 'Reject' },
  ];
  const handleStatusChange = (row, option) => {
    const editedParentCorrections = instanceForm.meetingsCorrections.map((c) =>
      c.id === row.id
        ? { ...row, status: option, resolved_by_id: currentUser.id }
        : c
    );
    const editedInstanceCorrections = instanceForm.meetingsCorrections.map(
      (c) => (c.id === row.id ? { ...row, status: option } : c)
    );
    const editedInstance = {
      ...instanceForm,
      meetingsCorrections: editedInstanceCorrections,
    };
    const editedParent = {
      ...form,
      meetingsCorrections: editedParentCorrections,
    };
    dispatch(setFormMeetingInstance(editedInstance));
    dispatch(setFormProjectMeetings(editedParent));
    setEditingRows((prev) => ({ ...prev, [row.id]: false }));
  };
  const toggleEdit = (rowId) => {
    // Toggle the editing state for the row
    setEditingRows((prev) => ({ ...prev, [rowId]: !prev[rowId] }));
  };

  const actions = (row) => {
    const isEditing = editingRows[row.id];

    if (isEditing || row.status === 'pending') {
      return (
        <RadioButton
          options={statusOptions}
          onChange={(option) => handleStatusChange(row, option)}
          className={'approve-options'}
          orientation={'horizontal-row'}
          value={row.status}
        />
      );
    }

    return capitalizeFirstLetters(row.status);
  };

  const descriptionCell = (row) => {
    if (!row.resolvedBy || !row.resolvedBy.id) return row.correctionRemark;
    const resolvedDate = new Date(row.resolvedAt);
    const formattedDate = resolvedDate.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    const approvalText = `Approved by ${row.resolvedBy.label} on ${formattedDate} ${form.timezone}`;
    return (
      <div className="remark-with-resolved">
        <div>{row.correctionRemark}</div>
        <div>{approvalText}</div>
      </div>
    );
  };
  const getRow = (row) => {
    const editButtonCell =
      row.status === 'approved' || row.status === 'rejected' ? (
        <Button
          type={BUTTON_TYPES.LINK}
          color={BUTTON_COLORS.GREEN}
          label="Edit"
          onClick={() => toggleEdit(row.id)}
        />
      ) : null;
    const rowClass = editingRows[row.id]
      ? 'correction-row-style edit-mode-row'
      : 'correction-row-style';
    return {
      id: row.id,
      className: rowClass,
      selectable: true,
      data: [
        row.meetingCorrectionNo,
        descriptionCell(row),
        row.submittedBy && row.submittedBy.fullName,
        parseDate(row.createdAt),
        actions(row),
        editButtonCell,
      ],
    };
  };

  const getRows = () => {
    if (!(instanceForm && instanceForm.meetingsCorrections)) return [];
    const rows = instanceForm.meetingsCorrections.map((c) => getRow(c));
    return rows;
  };

  return (
    <React.Fragment>
      <div className="notepad-form-container">
        <SimpleTable
          className="instance-custom-table-style"
          emptyMessage={`No Corrections have been submitted`}
          headers={correctionsHeaders}
          actionCells={1}
          rows={getRows()}
          stickyHeader={true}
        />
      </div>
    </React.Fragment>
  );
};

export default connect((state) => ({
  currentProject: getCurrentProject(state),
  instanceForm: getMeetingsInstanceForm(state),
  form: getProjectNewMeetingsForm(state),
  currentUser: getCurrentUser(state),
}))(MeetingsInstanceFormCorrections);
