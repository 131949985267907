import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Dropzone from 'react-dropzone';
import { getDarkMode } from 'selectors/theme';
import plusSvg from 'images/mdi_plus-circle.svg';
import plusSvgDark from 'images/mdi_plus-circle-dark.svg';
import deleteCross from 'images/deleteCross.svg';
import deleteCrossDM from 'images/deleteCrossDarkMode.svg';
import { FILE_TYPES } from 'components/shared/document-upload/DocumentUpload';

import useWindowDragAndDrop from './WindowDragAndDrop.hook';
import './WindowDragAndDrop.css';

const WindowDragAndDrop = (props) => {
  const { onDrop, accept, disabled, darkMode } = props;
  const { dragging, setDragging } = useWindowDragAndDrop({ disabled });

  const handleDrop = (files) => {
    onDrop(files);
    setDragging(false);
  };

  if (!dragging) return null;

  return (
    <div className="drag-and-drop-modal">
      <div className="close-icon">
        <img
          alt="close"
          src={darkMode ? deleteCrossDM : deleteCross}
          onClick={() => setDragging(false)}
        />
      </div>
      <Dropzone
        onDrop={handleDrop}
        className="drop-zone"
        accept={accept}
        multiple={true}
      >
        <div className="border-drop-zone">
          <img src={darkMode ? plusSvgDark : plusSvg} alt="upload" />
          <h2>Drop</h2>
          <span>Your files here</span>
        </div>
      </Dropzone>
    </div>
  );
};

WindowDragAndDrop.propTypes = {
  onDrop: PropTypes.func.isRequired,
  accept: PropTypes.string,
};

WindowDragAndDrop.defaultProps = {
  accept: FILE_TYPES.ANY.accept,
};

export default connect((state) => ({ darkMode: getDarkMode(state) }))(
  WindowDragAndDrop
);
