import React from 'react';

const StampOptions = [
  {
    value: 'approved',
    label: <b>No Exceptions Taken/Approved</b>,
  },
  {
    value: 'approved_as_noted',
    label: (
      <span>
        <b>Corrections Required as Noted</b>
        <div>
          Resubmittal only required if Contractor cannot comply with
          corrections.
        </div>
      </span>
    ),
  },
  {
    value: 'revise',
    label: <b>Revise and Resubmit as Noted</b>,
  },
  {
    value: 'rejected',
    label: (
      <span>
        <b>Rejected</b>
      </span>
    ),
  },
  {
    value: 'not_reviewed',
    label: (
      <span>
        <b>Not Reviewed</b>
        <div>Submittal not required.</div>
      </span>
    ),
  },
  {
    value: 'for_record',
    label: (
      <span>
        <b>For Record Only</b>
        <div>Submittal not reviewed.</div>
      </span>
    ),
  },
];

export default StampOptions;
