import React from 'react';
import { connect } from 'react-redux';
import { getReportMetrics } from './store/selectors';
import Indicator from './Indicator';
import './CurrentBudget.css';

const CurrentBudget = (props) => {
  const { metrics } = props;
  const { current, previous, original } =
    (metrics && metrics.total_budget) || {};

  return (
    <Indicator
      className="project-dashboard-current-budget"
      name="Project Current Budget"
      type="currency"
      current={current}
      previous={previous}
      original={original}
    />
  );
};

export default connect((state) => {
  return {
    metrics: getReportMetrics(state),
  };
})(CurrentBudget);
