import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import User from 'domain/user';
import FormModal from 'components/shared/modal/FormModal';
import FieldWorkOrderActions from 'components/admin/projects/details/project-daily-report-log/FieldWorkOrderActions';
import FieldWorkOrderDetails from 'components/admin/projects/details/project-daily-report-log/FieldWorkOrderDetails';
import FieldWorkOrderForm from 'components/admin/projects/details/project-daily-report-log/FieldWorkOrderForm';
import {
  getCurrentFieldWorkOrder,
  getShowFieldWorkOrderModal,
  getFieldWorkOrderEdit,
  getFieldWorkOrderForm,
  getShowFieldWorkOrderError,
} from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import {
  setShowFieldWorkOrderModal,
  initFieldWorkOrderForm,
  setFieldWorkOrderEdit,
  updateFieldWorkOrder,
  setShowFieldWorkOrderError,
  setFieldWorkOrderFormAttribute,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';

class FieldWorkOrderModal extends Component {
  static propTypes = {
    onClose: PropTypes.func,
  };

  static defaultProps = {
    onClose: null,
  };

  handleAttributeChange = (attribute, value) => {
    const { dispatch } = this.props;
    dispatch(setFieldWorkOrderFormAttribute(attribute, value));
  };

  handleClose = () => {
    this.props.dispatch(setShowFieldWorkOrderModal(false));
    this.props.dispatch(setFieldWorkOrderEdit(false));
    this.props.dispatch(setShowFieldWorkOrderError(false));
    this.props.onClose && this.props.onClose();
  };

  handleEdit = () => {
    this.props.dispatch(setFieldWorkOrderEdit(true));
    this.props.dispatch(
      initFieldWorkOrderForm(this.props.currentFieldWorkOrder)
    );
  };

  handleCancel = () => {
    this.props.dispatch(setFieldWorkOrderEdit(false));
    this.props.dispatch(setShowFieldWorkOrderError(false));
  };

  handleSave = () => {
    const {
      dispatch,
      currentFieldWorkOrder,
      fieldWorkOrderForm,
      currentProject,
    } = this.props;
    if (this.incompleteForm()) {
      dispatch(setShowFieldWorkOrderError(true));
    } else {
      dispatch(
        updateFieldWorkOrder(
          currentProject.id,
          currentFieldWorkOrder.id,
          fieldWorkOrderForm
        )
      );
      dispatch(setShowFieldWorkOrderError(false));
    }
  };

  incompleteForm = () => {
    const { fieldWorkOrderForm } = this.props;
    return (
      !fieldWorkOrderForm.fwoNumber ||
      !fieldWorkOrderForm.fwoWorkPerformed ||
      !fieldWorkOrderForm.fwoStatus ||
      ((!fieldWorkOrderForm.fwoPresetTasks ||
        !fieldWorkOrderForm.fwoPresetTasks.length) &&
        !fieldWorkOrderForm.fwoCustomTask) ||
      (fieldWorkOrderForm.fwoStatus.value === 'denied' &&
        !fieldWorkOrderForm.fwoDeniedReason) ||
      (fieldWorkOrderForm.fwoDelay && !fieldWorkOrderForm.fwoDelayReason) ||
      !fieldWorkOrderForm.fwoLabor
    );
  };

  render() {
    const {
      currentUser,
      currentFieldWorkOrder,
      showFieldWorkOrderModal,
      fieldWorkOrderEdit,
      showErrorMessage,
      fieldWorkOrderForm,
      currentProject,
    } = this.props;

    const isSubcontractor =
      currentProject && User.isContractor(currentUser, currentProject);
    return (
      currentProject &&
      showFieldWorkOrderModal && (
        <FormModal
          className="field-work-order-modal"
          onClose={this.handleClose}
          show={true}
          subTitle={`Extra Work Order ${currentFieldWorkOrder.number}`}
        >
          <div className="field-work-order-body">
            {fieldWorkOrderEdit && isSubcontractor ? (
              <FieldWorkOrderForm
                fieldWorkOrderForm={fieldWorkOrderForm}
                handleAttributeChange={this.handleAttributeChange}
                fieldWorkOrder={currentFieldWorkOrder}
                showErrorMessage={showErrorMessage}
              />
            ) : (
              <FieldWorkOrderDetails
                fieldWorkOrder={currentFieldWorkOrder}
                fieldWorkOrderForm={fieldWorkOrderForm}
                handleAttributeChange={this.handleAttributeChange}
                fieldWorkOrderEdit={fieldWorkOrderEdit}
                showErrorMessage={showErrorMessage}
              />
            )}
          </div>
          <FieldWorkOrderActions
            handleClose={this.handleClose}
            handleEdit={this.handleEdit}
            handleCancel={this.handleCancel}
            handleSave={() => this.handleSave()}
            fieldWorkOrderEdit={fieldWorkOrderEdit}
            showErrorMessage={showErrorMessage}
            fieldWorkOrder={currentFieldWorkOrder}
          />
        </FormModal>
      )
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentFieldWorkOrder: getCurrentFieldWorkOrder(state),
    showFieldWorkOrderModal: getShowFieldWorkOrderModal(state),
    fieldWorkOrderEdit: getFieldWorkOrderEdit(state),
    currentProject: getCurrentProject(state),
    fieldWorkOrderForm: getFieldWorkOrderForm(state),
    showErrorMessage: getShowFieldWorkOrderError(state),
  };
})(FieldWorkOrderModal);
