import axios from 'axios';
import BaseApi from './base-api';

class TradesApi extends BaseApi {
  getTrades() {
    return axios
      .get(this.baseUrl + '/trades')
      .then((response) => {
        return {
          ok: true,
          trades: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getSubTrades(idTrade) {
    return axios
      .get(this.baseUrl + '/sub_trades?by_trade=' + idTrade)
      .then((response) => {
        return {
          ok: true,
          subTrades: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const tradesApi = new TradesApi();
export default TradesApi = new TradesApi();
