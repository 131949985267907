import axios from 'axios';
import BaseApi from './base-api';

class AuthApi extends BaseApi {
  login(email, password) {
    const data = {
      email: email,
      password: password,
    };

    return axios
      .post(this.baseUrl + '/auth/sign_in', data)
      .then((response) => {
        return {
          ok: true,
          data: {
            accessToken: response.headers['access-token'],
            client: response.headers['client'],
            user: response.data.data,
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getLoginBackgroundImage() {
    return axios
      .get(`${this.baseUrl}/login_background_image`)
      .then((response) => {
        return {
          ok: true,
          data: {
            urlImage: response.data.image.url,
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  confirmPassword(token, password, passwordConfirmation, termsAccepted) {
    return axios
      .put(this.baseUrl + '/auth/invitation', {
        invitation_token: token,
        password: password,
        password_confirmation: passwordConfirmation,
        terms_and_conditions: termsAccepted,
      })
      .then((response) => {
        return {
          ok: true,
          email: response.data.email,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  logout() {
    return axios
      .delete(this.baseUrl + '/auth/sign_out')
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  recoverPassword(email, redirectUrl) {
    return axios
      .post(`${this.baseUrl}/auth/password`, {
        email: email,
        redirect_url: redirectUrl,
      })
      .then((response) => {
        return {
          ok: true,
          message: response.data.message,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  resetPassword(password, passwordConfirmation, clientId, token, uid) {
    return axios
      .put(
        `${this.baseUrl}/auth/password`,
        {
          password: password,
          password_confirmation: passwordConfirmation,
        },
        {
          headers: {
            'access-token': token,
            client: clientId,
            uid: uid,
          },
        }
      )
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const authApi = new AuthApi();
export default AuthApi = new AuthApi();
