import {
  WORK_ORDERS_DETAILS_BUDGET_BACK,
  WORK_ORDERS_DETAILS_BUDGET_CURRENT_VIEW_SET,
  WORK_ORDERS_DETAILS_BUDGET_EMAIL_ADD,
  WORK_ORDERS_DETAILS_BUDGET_EMAIL_CLEAR,
  WORK_ORDERS_DETAILS_BUDGET_EMAIL_MODAL_SHOW_SET,
  WORK_ORDERS_DETAILS_BUDGET_EMAIL_REMOVE,
  WORK_ORDERS_DETAILS_BUDGET_MESSAGE_SET,
  WORK_ORDERS_DETAILS_BUDGET_MODAL_SHOW_APPROVAL_CONFIRMATION_SET,
  WORK_ORDERS_DETAILS_BUDGET_MODAL_SHOW_APPROVE_REVIEW_SET,
  WORK_ORDERS_DETAILS_BUDGET_MODAL_SHOW_SET,
  WORK_ORDERS_DETAILS_BUDGET_NEXT,
  WORK_ORDERS_DETAILS_BUDGET_PDF_VIEWER_CLOSE,
  WORK_ORDERS_DETAILS_BUDGET_PRINT_SUCCESS,
  WORK_ORDERS_DETAILS_BUDGET_SUBJECT_SET,
  WORK_ORDERS_DETAILS_BUDGET_TASK_CHECK,
  WORK_ORDERS_DETAILS_BUDGET_VALUE_UPDATE,
  WORK_ORDERS_DETAILS_BUDGET_VALUES_INIT,
} from 'actions/admin/work-orders/action-types';

const initialState = {
  approvedTasksId: [],
  currentView: 'form', // oneOf: [form, review, thank-you]
  emails: [],
  message: '',
  pdf: null,
  show: false,
  showApprovalConfirmation: false,
  showApproveReviewModal: false,
  showEmailModal: false,
  showPdfViewer: false,
  subject: '',
  valuesByTaskId: {}, // { cost, fee, markup, insurance }
};

export default function budgetUiReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_DETAILS_BUDGET_CURRENT_VIEW_SET:
      return {
        ...state,
        currentView: action.payload,
      };

    case WORK_ORDERS_DETAILS_BUDGET_EMAIL_ADD:
      if (
        state.emails.find((email) => email === action.payload) === undefined
      ) {
        return {
          ...state,
          emails: state.emails.concat([action.payload]),
        };
      } else {
        return state;
      }

    case WORK_ORDERS_DETAILS_BUDGET_EMAIL_CLEAR:
      return {
        ...state,
        emails: [],
      };

    case WORK_ORDERS_DETAILS_BUDGET_EMAIL_MODAL_SHOW_SET:
      return {
        ...state,
        showEmailModal: action.payload,
      };

    case WORK_ORDERS_DETAILS_BUDGET_EMAIL_REMOVE:
      return {
        ...state,
        emails: state.emails.filter((email) => email !== action.payload),
      };

    case WORK_ORDERS_DETAILS_BUDGET_MESSAGE_SET:
      return {
        ...state,
        message: action.payload,
      };

    case WORK_ORDERS_DETAILS_BUDGET_MODAL_SHOW_APPROVAL_CONFIRMATION_SET:
      return {
        ...state,
        showApprovalConfirmation: action.payload,
      };

    case WORK_ORDERS_DETAILS_BUDGET_MODAL_SHOW_APPROVE_REVIEW_SET:
      return {
        ...state,
        showApproveReviewModal: action.payload,
      };

    case WORK_ORDERS_DETAILS_BUDGET_MODAL_SHOW_SET:
      return {
        ...state,
        show: action.payload,
      };

    case WORK_ORDERS_DETAILS_BUDGET_BACK:
    case WORK_ORDERS_DETAILS_BUDGET_NEXT:
      return {
        ...state,
        currentView: action.payload,
      };

    case WORK_ORDERS_DETAILS_BUDGET_PDF_VIEWER_CLOSE:
      return {
        ...state,
        showPdfViewer: false,
      };

    case WORK_ORDERS_DETAILS_BUDGET_PRINT_SUCCESS:
      return {
        ...state,
        pdf: action.payload,
        showPdfViewer: true,
      };

    case WORK_ORDERS_DETAILS_BUDGET_SUBJECT_SET:
      return {
        ...state,
        subject: action.payload,
      };

    case WORK_ORDERS_DETAILS_BUDGET_TASK_CHECK:
      if (
        state.approvedTasksId.find((id) => id === action.payload.id) !==
        undefined
      ) {
        if (!action.payload.value) {
          return {
            ...state,
            approvedTasksId: state.approvedTasksId.filter(
              (id) => id !== action.payload.id
            ),
          };
        } else {
          // Already selected, don't do anything
          return state;
        }
      } else {
        if (action.payload.value) {
          return {
            ...state,
            approvedTasksId: state.approvedTasksId.concat([action.payload.id]),
          };
        } else {
          // Already unselected, don't do anything
          return state;
        }
      }

    case WORK_ORDERS_DETAILS_BUDGET_VALUE_UPDATE:
      return {
        ...state,
        valuesByTaskId: {
          ...state.valuesByTaskId,
          [action.payload.taskId]: {
            ...state.valuesByTaskId[action.payload.taskId],
            [action.payload.valueKey]: action.payload.value,
          },
        },
      };

    case WORK_ORDERS_DETAILS_BUDGET_VALUES_INIT:
      return {
        ...state,
        valuesByTaskId: action.payload,
      };

    default:
      return state;
  }
}
