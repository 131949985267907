import {
  WORK_ORDERS_DETAILS_QUOTES_REQUEST_BID_DUE_DATE_SET,
  WORK_ORDERS_DETAILS_QUOTES_REQUEST_CONTRACTOR_ADD,
  WORK_ORDERS_DETAILS_QUOTES_REQUEST_CONTRACTOR_REMOVE,
  WORK_ORDERS_DETAILS_QUOTES_REQUEST_CONTRACTOR_SET,
  WORK_ORDERS_DETAILS_QUOTES_REQUEST_MODAL_SHOW,
} from '../../../../actions/admin/work-orders/action-types';

const initialState = {
  bidDueDate: null,
  selectedContractor: null, // object { value: NUMBER, label: STRING }
  selectedContractors: [], // array of object { value: NUMBER, label: STRING }
};

export default function quotesRequestDataReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_DETAILS_QUOTES_REQUEST_BID_DUE_DATE_SET:
      return {
        ...state,
        bidDueDate: action.payload,
      };

    case WORK_ORDERS_DETAILS_QUOTES_REQUEST_CONTRACTOR_SET:
      return {
        ...state,
        selectedContractor: action.payload,
      };

    case WORK_ORDERS_DETAILS_QUOTES_REQUEST_CONTRACTOR_ADD:
      return {
        ...state,
        selectedContractor: null,
        selectedContractors: state.selectedContractors
          .filter((element) => element.value !== action.payload.value) // Filter existing contractor (if exists)
          .concat([action.payload]),
      };

    case WORK_ORDERS_DETAILS_QUOTES_REQUEST_CONTRACTOR_REMOVE:
      return {
        ...state,
        selectedContractors: state.selectedContractors.filter(
          (element, index) => index !== action.payload
        ),
      };

    case WORK_ORDERS_DETAILS_QUOTES_REQUEST_MODAL_SHOW:
      return {
        ...state,
        bidDueDate: null,
        selectedContractor: null,
        selectedContractors: action.payload,
      };

    default:
      return state;
  }
}
