export const DAILY_REPORTS_SET = 'DAILY_REPORTS_SET';
export const DAILY_REPORTS_UPDATE = 'DAILY_REPORTS_UPDATE';
export const SHOW_ADD_DAILY_REPORT_SET = 'SHOW_ADD_DAILY_REPORT_SET';
export const SHOW_PROJECT_REPORT_PERMISSIONS_SET =
  'SHOW_PROJECT_REPORT_PERMISSIONS_SET';
export const SET_CONTRIBUTORS = 'SET_CONTRIBUTORS';
export const SET_CURRENT_DAILY_REPORT = 'SET_CURRENT_DAILY_REPORT';
export const ADD_DAILY_REPORT_SUCCESS = 'ADD_DAILY_REPORT_SUCCESS';
export const SET_ACTIVE_CONTRIBUTOR = 'SET_ACTIVE_CONTRIBUTOR';
export const SET_ACTIVE_ENTRIES_ID = 'SET_ACTIVE_ENTRIES_ID';
export const LOAD_PRESET_TASKS = 'LOAD_PRESET_TASKS';
export const SET_PRESET_TASKS = 'SET_PRESET_TASKS';
export const INITIALIZE_TRADE_FORM = 'INITIALIZE_TRADE_FORM';
export const UPDATE_TRADE_FORM = 'UPDATE_TRADE_FORM';
export const RESTORE_TRADE_FORM = 'RESTORE_TRADE_FORM';
export const RESTORE_MEMBER_FORM = 'RESTORE_MEMBER_FORM';
export const CHANGE_AMOUNT_OF_TECHNICIANS = 'CHANGE_AMOUNT_OF_TECHNICIANS';
export const HANDLE_WORKED_FORM = 'HANDLE_WORKED_FORM';
export const HANDLE_WORKED_TO = 'HANDLE_WORKED_TO';
export const HANDLE_HOURS_AMOUNT = 'HANDLE_HOURS_AMOUNT';
export const HANDLE_ATTRIBUTE_CHANGE = 'HANDLE_ATTRIBUTE_CHANGE';
export const SET_IMAGES = 'SET_IMAGES';
export const SET_REVIEW_FORM = 'SET_REVIEW_FORM';
export const SET_REPORT_SUBMITTED = 'SET_REPORT_SUBMITTED';
export const CLEAR_FORM = 'CLEAR_FORM';
export const SET_DAILY_REPORT_LOG_PDF = 'SET_DAILY_REPORT_LOG_PDF';
export const SET_DAILY_REPORT_LOG_PDF_VIEWER =
  'SET_DAILY_REPORT_LOG_PDF_VIEWER';
export const SET_EDIT_MODE = 'SET_EDIT_MODE';
export const SET_REPORTS_IDS = 'SET_REPORTS_IDS';
export const DELETE_DAILY_REPORT = 'DELETE_DAILY_REPORT';
export const SET_REPORT_PDF = 'SET_REPORT_PDF';
export const SET_REPORT_PDF_VIEWER = 'SET_REPORT_PDF_VIEWER';
export const SET_REPORT_DATE = 'SET_REPORT_DATE';
export const SET_REPORT_EXTRA_INPUTS = 'SET_REPORT_EXTRA_INPUTS';
export const SET_REPORT_EXTRA_INPUT = 'SET_REPORT_EXTRA_INPUT';
export const SET_REPORT_OPEN_ITEM = 'SET_REPORT_OPEN_ITEM';
export const SET_REPORT_OPEN_ITEM_VALUE = 'SET_REPORT_OPEN_ITEM_VALUE';
export const SET_GENERAL_CONTACTOR_NAME = 'SET_GENERAL_CONTACTOR_NAME';
export const SET_MEMBER_FORM = 'SET_MEMBER_FORM';
export const SET_SUPERVISOR_TASKS = 'SET_SUPERVISOR_TASKS';
export const SET_MEMBERS = 'SET_MEMBERS';
export const SET_DELETED_MEMBERS = 'SET_DELETED_MEMBERS';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_REPORTS_TOTAL = 'SET_REPORTS_TOTAL';
export const SET_REPORTS_PAGE_SIZE = 'SET_REPORTS_PAGE_SIZE';
export const SET_GCX_IMAGES = 'SET_GCX_IMAGES';
export const SET_GCX_DELETED_IMAGES = 'SET_GCX_DELETED_IMAGES';
export const SET_DELIVERY = 'SET_DELIVERY';
export const DELETE_DELIVERY = 'DELETE_DELIVERY';
export const ADD_EXTRA_INPUT = 'ADD_EXTRA_INPUT';
export const REMOVE_DELIVERIES_EXTRA_INPUT = 'REMOVE_DELIVERIES_EXTRA_INPUT';
export const SET_DAILY_REPORT_TAB = 'SET_DAILY_REPORT_TAB';
export const SUB_DAILY_REPORTS_COUNT_SUCCESS =
  'SUB_DAILY_REPORTS_COUNT_SUCCESS';
export const SUB_DAILY_REPORTS_COUNT_INIT = 'SUB_DAILY_REPORTS_COUNT_INIT';
export const HANDLE_ATTRIBUTE_CHANGE_FOR_SUB =
  'HANDLE_ATTRIBUTE_CHANGE_FOR_SUB';
export const HANDLE_HOURS_AMOUNT_MORE_INFO = 'HANDLE_HOURS_AMOUNT_MORE_INFO';
export const HANDLE_DATE_MORE_INFO = 'HANDLE_DATE_MORE_INFO';
export const HANDLE_ATTRIBUTE_CHANGE_FOR_SUB_MORE_ACTION =
  'HANDLE_ATTRIBUTE_CHANGE_FOR_SUB_MORE_ACTION';
export const ADDED_DELETED_TECHNICIANS = 'ADDED_DELETED_TECHNICIANS';
export const FIELD_WORK_ORDERS_SET = 'FIELD_WORK_ORDERS_SET';
export const SET_SHOW_FIELD_WORK_ORDER = 'SET_SHOW_FIELD_WORK_ORDER';
export const FIELD_WORK_ORDER_SET = 'FIELD_WORK_ORDER_SET';
export const FIELD_WORK_ORDER_FORM_INIT = 'FIELD_WORK_ORDER_FORM_INIT';
export const FIELD_WORK_ORDER_FORM_ATTRIBUTE_SET =
  'FIELD_WORK_ORDER_FORM_ATTRIBUTE_SET';
export const SET_FIELD_WORK_ORDER_EDIT = 'SET_FIELD_WORK_ORDER_EDIT';
export const UPDATE_FIELD_WORK_ORDER_SUCCESS =
  'UPDATE_FIELD_WORK_ORDER_SUCCESS';
export const SET_SHOW_FIELD_WORK_ORDER_ERROR =
  'SET_SHOW_FIELD_WORK_ORDER_ERROR';
export const SET_DAILY_REPORT_ADD_FLOOR = 'SET_DAILY_REPORT_ADD_FLOOR';
export const DUPLICATE_DAILY_REPORT_SUCCESS = 'DUPLICATE_DAILY_REPORT_SUCCESS';
export const SET_PROJECT_REPORT_ATTR = 'SET_PROJECT_REPORT_ATTR';
export const ADD_PROJECT_REPORT_CONTACT_INFORMATION_ITEM =
  'ADD_PROJECT_REPORT_CONTACT_INFORMATION_ITEM';
export const ADD_PROJECT_REPORT_PROJECT_STATUS_ITEM =
  'ADD_PROJECT_REPORT_PROJECT_STATUS_ITEM';
export const SET_PROJECT_REPORT_DOCUMENTS_PHOTOS_ATTR =
  'SET_PROJECT_REPORT_DOCUMENTS_PHOTOS_ATTR';
export const SET_PROJECT_REPORT_PDF = 'SET_PROJECT_REPORT_PDF';
export const SET_PROJECT_REPORT_PDF_VIEWER = 'SET_PROJECT_REPORT_PDF_VIEWER';
export const PROJECT_REPORTS_SET = 'PROJECT_REPORTS_SET';
export const PROJECT_REPORT_RELOAD = 'PROJECT_REPORT_RELOAD';
export const DELETE_PROJECT_REPORT = 'DELETE_PROJECT_REPORT';
export const OPEN_PROJECT_REPORT_DETAILS = 'OPEN_PROJECT_REPORT_DETAILS';
export const PROJECT_REPORT_SET = 'PROJECT_REPORT_SET';
export const PROJECT_REPORT_SET_NUMBER = 'PROJECT_REPORT_SET_NUMBER';
export const GET_PROJECT_REPORT_ATTR_DETAILS =
  'GET_PROJECT_REPORT_ATTR_DETAILS';
export const RESET_PROJECT_REPORT_FORM = 'RESET_PROJECT_REPORT_FORM';
export const ADD_ITEM_PROJECT_REPORT_TABLE = 'ADD_ITEM_PROJECT_REPORT_TABLE';
export const ADD_PROJECT_REPORT_SCHEDULE_DESCRIPTION_BY_ID =
  'ADD_PROJECT_REPORT_SCHEDULE_DESCRIPTION_BY_ID';
export const ADD_PROJECT_REPORT_BUDGET_DESCRIPTION_BY_ID =
  'ADD_PROJECT_REPORT_BUDGET_DESCRIPTION_BY_ID';
export const DELETE_PROJECT_REPORT_TABLE_ITEM_BY_TYPE =
  'DELETE_PROJECT_REPORT_TABLE_ITEM_BY_TYPE';
export const SET_PROJECT_REPORT_PROJECT_TABLE_ITEM_ATTR_BY_TYPE =
  'SET_PROJECT_REPORT_PROJECT_TABLE_ITEM_ATTR_BY_TYPE';
export const GET_PROJECT_REPORT_RESOLVED_INFO_BY_ATTR =
  'GET_PROJECT_REPORT_RESOLVED_INFO_BY_ATTR';
export const PROJECT_REPORT_RESOLVED_TABLE_FILTER =
  'PROJECT_REPORT_RESOLVED_TABLE_FILTER';
export const SET_PROJECT_REPORTS_PERMISSIONS =
  'SET_PROJECT_REPORTS_PERMISSIONS';
export const PROJECT_REPORT_TABLES_ORDER_CHANGE =
  'PROJECT_REPORT_TABLES_ORDER_CHANGE';
export const PROJECT_REPORT_PHOTOS_AND_DOCUMENTS_FILTER =
  'PROJECT_REPORT_PHOTOS_AND_DOCUMENTS_FILTER';
export const DELETE_PROJECT_REPORT_PROJECT_STATUS =
  'DELETE_PROJECT_REPORT_PROJECT_STATUS';
export const PROJECT_REPORT_PHOTOS_AND_DOCUMENTS_FILTER_CLEAR =
  'PROJECT_REPORT_PHOTOS_AND_DOCUMENTS_FILTER_CLEAR';
export const SET_PROJECT_PHOTO_AND_DOCUMENTS_FILTERING_ACTIVE =
  'SET_PROJECT_PHOTO_AND_DOCUMENTS_FILTERING_ACTIVE';
export const SET_LAST_PROJECT_REPORT = 'SET_LAST_PROJECT_REPORT';
export const SET_PROJECT_REPORT_PREVIEW = 'SET_PROJECT_REPORT_PREVIEW';
export const SET_PROJECT_REPORT_INITIAL_TAB = 'SET_PROJECT_REPORT_INITIAL_TAB';
export const SET_PROJECT_REPORT_TABLE_MULTIPLE_ITEMS_BY_TYPE =
  'SET_PROJECT_REPORT_TABLE_MULTIPLE_ITEMS_BY_TYPE';
export const SHOW_SIMPLIFIED_ADD_DAILY_REPORT_SET =
  'SHOW_SIMPLIFIED_ADD_DAILY_REPORT_SET';
export const SET_SIMPLIFIED_DAILY_REPORT_FORM =
  'SET_SIMPLIFIED_DAILY_REPORT_FORM';
export const SIMPLIFIED_REPORT_CREATION_SUCCESS =
  'SIMPLIFIED_REPORT_CREATION_SUCCESS';
export const SIMPLIFIED_REPORT_UPDATE_SUCCESS =
  'SIMPLIFIED_REPORT_UPDATE_SUCCESS';
