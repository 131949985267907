import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Button, {
  BUTTON_COLORS,
  BUTTON_TYPES,
} from 'components/shared/button/Button';
import './SelectorModal.css';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';

class SelectorModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
    initialValue: PropTypes.string.isRequired,
    onHide: PropTypes.func.isRequired,
    hideText: PropTypes.string,
    onComplete: PropTypes.func.isRequired,
    completeText: PropTypes.string,
  };

  static defaultProps = {
    message: 'Select an option an then confirm',
    hideText: 'Cancel',
    completeText: 'Complete',
  };

  constructor(props) {
    super(props);
    this.state = { value: props.initialValue };
  }

  onComplete = () => {
    this.props.onComplete(this.state.value);
  };

  render() {
    return (
      <Modal
        className="selector-modal"
        show={this.props.show}
        onHide={this.props.onHide}
      >
        <Modal.Body>
          <div>{this.props.title}</div>
          <div>{this.props.message}</div>
          <div className="options">
            {this.props.options.map((option, index) => {
              return (
                <LabeledCheckBox
                  key={'option_' + index}
                  label={option.label}
                  checked={this.state.value === option.value}
                  onChange={() => this.setState({ value: option.value })}
                />
              );
            })}
          </div>
          <div>
            <Button
              onClick={this.props.onHide}
              color={BUTTON_COLORS.WHITE}
              type={BUTTON_TYPES.CONFIRMATION}
              label={this.props.hideText}
            />
            <Button
              onClick={this.onComplete}
              color={BUTTON_COLORS.GREEN}
              type={BUTTON_TYPES.CONFIRMATION}
              label={this.props.completeText}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default SelectorModal;
