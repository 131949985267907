export const imageExtensions = ['.jpg', '.jpeg', '.gif', '.png', '.heic'];

export function validateFile(name, extensions) {
  const index = name.lastIndexOf('.');
  if (index === -1) {
    // The file has no extension, so is not valid
    alert(
      'File "' + name + '" could not be uploaded because has no extension.'
    );
    return false;
  } else if (extensions.length > 0) {
    const fileExtension = name.substring(index);
    if (
      extensions.find(
        (element) => element.toLowerCase() === fileExtension.toLowerCase()
      ) === undefined
    ) {
      alert(
        'File "' +
          name +
          '" has an invalid extensions, should be one of [' +
          extensions.join(', ') +
          '].'
      );
      return false;
    }
  }

  return true;
}

function getDataUrlMimeType(dataUrl) {
  return dataUrl.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
}

function getMimeTypeExtension(mimeType) {
  return mimeType.split('/')[1];
}

export const fileToDataUrl = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export async function dataUrlToFile(dataUrl, fileName = null) {
  const mimeType = getDataUrlMimeType(dataUrl);
  fileName = fileName || `image.${getMimeTypeExtension(mimeType)}`;

  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], fileName, { type: mimeType });
}

const htmlEscape = (str) => {
  return str
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/\//g, '&#x2F;')
    .replace(/`/g, '&#x60;')
    .replace(/=/g, '&#x3D;');
};

const fullPathToFileName = (path) => {
  return htmlEscape(path.replace(/^.*[\\/]/, ''));
};

export const urlToFileNameAndExtension = (url) => {
  const fileName = fullPathToFileName(url);
  const separated = fileName.split('.');

  const extension = separated[separated.length - 1];
  separated.pop();
  const name = separated.join('.');

  return { name, extension };
};
