import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import ProjectLocations from './ProjectLocations';
import NewProjectLocation from './NewProjectLocation';
import ProjectLocationDetails from './ProjectLocationDetails';
import { showErrorMessage } from 'actions/messages';
import { ConditionRoute } from 'components/shared/ConditionRoute';
import { getCurrentUser } from 'selectors/authentication';
import ProjectLocationPermissions from 'permissions/project-location-permissions';

class ProjectLocationsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    this.props.dispatch(
      showErrorMessage(
        'Something went wrong with the request, please try again later.'
      )
    );
  }

  render() {
    const { currentUser } = this.props;

    if (this.state.hasError) {
      return <Redirect to="/" />;
    } else {
      return (
        <div>
          <Switch>
            <Route
              exact
              path={`${this.props.match.url}/`}
              component={ProjectLocations}
            />
            <ConditionRoute
              fallbackUrl={`${this.props.match.url}`}
              path={`${this.props.match.url}/new`}
              component={NewProjectLocation}
              show={ProjectLocationPermissions.canCreate(currentUser)}
            />
            <Route
              exact
              path={`${this.props.match.url}/:id`}
              component={ProjectLocationDetails}
            />
            <ConditionRoute
              fallbackUrl={`${this.props.match.url}`}
              path={`${this.props.match.url}/:id/edit`}
              component={NewProjectLocation}
              show={ProjectLocationPermissions.canEdit(currentUser)}
            />
          </Switch>
        </div>
      );
    }
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
  };
})(ProjectLocationsContainer);
