import React, { useState } from 'react';
import FormModal from 'components/shared/modal/FormModal';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import DetailsInfoItem from 'components/shared/details/DetailsInfoItem';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import UserPermissionsForm from 'components/shared/user-permissions/UserPermissionsForm';
import DocumentPermissions from 'permissions/document-permissions';
import './SharedFolderPermissionsModal.css';

const SharedFolderUsersModal = (props) => {
  const {
    showModal,
    onClose,
    onSubmit,
    currentFile,
    currentUser,
    currentProject,
  } = props;

  const [form, setForm] = useState({
    visibility: currentFile.visibility || 'public',
    users: currentFile.users ? [...currentFile.users] : [],
  });

  const handleSubmit = () => {
    const { visibility, users } = form;
    const user_ids = users.map(({ id }) => id);
    onSubmit({ user_ids, visibility });
  };

  if (!showModal) return null;

  const createdBtyLines = [
    currentFile.author
      ? currentFile.author.first_name + ' ' + currentFile.author.last_name
      : 'deleted user',
  ];
  const dateLines = [moment(currentFile.created_at).format('L')];

  return (
    <FormModal
      show={showModal}
      onClose={onClose}
      subTitle={'Folder Permissions'}
      className={`users-modal ${form.visibility}`}
    >
      {form.visibility === 'shared' && (
        <Row>
          <Col md={3}>
            <DetailsInfoItem title="Created By" lines={createdBtyLines} />
          </Col>
          <Col md={3}>
            <DetailsInfoItem title="Date" lines={dateLines} />
          </Col>
        </Row>
      )}
      <div className="users-list">
        <UserPermissionsForm
          users={form.users}
          visibility={form.visibility}
          onVisibilityChange={(visibility) => setForm({ ...form, visibility })}
          onUsersChange={(users) => setForm({ ...form, users })}
          canChangePermissions={DocumentPermissions.canManagePrivateFolders(
            currentUser,
            currentProject
          )}
        />
      </div>
      <div className="buttons-container">
        <Button
          type={BUTTON_TYPES.RECTANGLE}
          color={BUTTON_COLORS.WHITE}
          className="button-cancel"
          label="Cancel"
          onClick={onClose}
        />
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          className="button-submit"
          label="Submit"
          onClick={handleSubmit}
        />
      </div>
    </FormModal>
  );
};

export default SharedFolderUsersModal;
