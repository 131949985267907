import React from 'react';
import { connect } from 'react-redux';

import SelectorModal from 'components/shared/modal/SelectorModal';

import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getShowCreateModal,
  getCreateModalRevisedRfaId,
} from 'components/admin/projects/details/project-rfa-log/store/selectors.js';

import {
  setNewRfa,
  setNewRfaRevision,
  setShowCreateModal,
} from 'components/admin/projects/details/project-rfa-log/store/actions';

const ModalRfaCreateOptions = (props) => {
  const { dispatch, currentProject, revisedRfaId, show } = props;

  const handleHide = () => {
    dispatch(setShowCreateModal(false));
  };

  const handleNext = (status) =>
    dispatch(
      revisedRfaId
        ? setNewRfaRevision(currentProject.id, revisedRfaId, status)
        : setNewRfa(status)
    );

  return (
    <SelectorModal
      show={show}
      title={`${currentProject.name} - RFA ${
        revisedRfaId ? 'Revision' : 'Draft'
      }`}
      message={'Select the type of record to be created'}
      options={[
        { label: 'New RFA', value: 'draft' },
        { label: 'RFA For Record', value: 'draft_for_record' },
      ]}
      initialValue={'draft'}
      onHide={handleHide}
      onComplete={handleNext}
      completeText={'Next'}
    />
  );
};

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    show: getShowCreateModal(state),
    revisedRfaId: getCreateModalRevisedRfaId(state),
  };
})(ModalRfaCreateOptions);
