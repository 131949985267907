import User from 'domain/user';
import { COMPANY_UNDERSCORE_TYPES } from 'domain/company-type-enum';

class ProjectReportPermissions {
  canView = (user, project, reportPermissions = null) => {
    if (
      User.isAdmin(user) ||
      User.isOar(user, project) ||
      User.isProjectSubscriber(user, project)
    )
      return true;

    const contributorType =
      COMPANY_UNDERSCORE_TYPES[User.contributorType(user, project) || ''];
    return (
      reportPermissions ||
      project.project_report_permissions ||
      []
    ).includes(contributorType);
  };

  canEdit = (user, project) =>
    User.isAdmin(user) ||
    User.isOar(user, project) ||
    User.isProjectSubscriber(user, project);

  canDeleteBudget = (user, _project) => User.isAdmin(user);

  canDeleteStatus = (user, _project) => User.isAdmin(user);

  canDeleteContractInfo = (user, _project) => User.isAdmin(user);
}

export default new ProjectReportPermissions();
