import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import './NewProjectLocationModal.css';
import FormModal from 'components/shared/modal/FormModal';
import {
  getProjectLocationForm,
  getShowNewProjectLocationModal,
  getProjectForm,
} from 'components/admin/projects/creation/store/selectors';
import Input from 'components/shared/input/Input';
import {
  setProjectLocationFormValue,
  createProjectLocation,
  setShowNewProjectLocationModal,
  cleanProjectLocationFormValues,
} from 'components/admin/projects/creation/store/actions';
import { addProjectLocationToBeginning } from 'actions/admin/project-locations/project-locations.js';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import FormControlBlock from 'components/shared/form/FormControlBlock';

class NewProjectLocationModal extends Component {
  constructor(props) {
    super(props);
    props.dispatch(cleanProjectLocationFormValues());
  }

  handleProjectLocationFormChange = (attribute, value) => {
    this.props.dispatch(setProjectLocationFormValue(attribute, value));
  };

  handleClose = () => {
    this.props.dispatch(setShowNewProjectLocationModal(false));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { dispatch } = this.props;
    const projectLocationData = this.mapProjectLocationData();
    dispatch(createProjectLocation(projectLocationData)).then((response) => {
      if (response.ok) {
        dispatch(addProjectLocationToBeginning(response.project_location));
        dispatch(setShowNewProjectLocationModal(false));
      }
    });
  };

  projectLocationFormValid = () => {
    const projectLocationForm = this.props.form;
    return (
      !!projectLocationForm.streetAddress1 &&
      !!projectLocationForm.city &&
      !!projectLocationForm.state
    );
  };

  mapProjectLocationData = () => {
    const projectLocationForm = this.props.form;
    return {
      name: projectLocationForm.name,
      location: {
        streetAddress: projectLocationForm.streetAddress1,
        unitNumber: projectLocationForm.streetAddress2 || '',
        city: projectLocationForm.city,
        state: projectLocationForm.state,
        zipCode: projectLocationForm.zip || '',
      },
      relatedCompanyId: this.props.projectForm.clientId,
    };
  };

  render() {
    const {
      form: { name, streetAddress1, streetAddress2, city, state, zip },
    } = this.props;

    return (
      <div id="new-project-location-form-modal">
        <FormModal
          className="new-project-location-modal"
          show={this.props.showNewProjectLocationModal}
          subTitle={'New ProjectLocation Information'}
          onClose={this.handleClose}
        >
          <Form horizontal onSubmit={this.handleSubmit}>
            <div className="form-block">
              <FormControlBlock
                label={!!name && 'Project Location Name (optional)'}
                control={
                  <Input
                    id="name"
                    placeholder="Project Location Name (optional)"
                    value={name}
                    onChange={this.handleProjectLocationFormChange.bind(
                      this,
                      'name'
                    )}
                  />
                }
              />
            </div>

            <div className="form-block">
              <FormControlBlock
                label={!!streetAddress1 && 'Address Line 1'}
                control={
                  <Input
                    id="street-address-1"
                    placeholder="Address Line 1"
                    value={streetAddress1}
                    onChange={this.handleProjectLocationFormChange.bind(
                      this,
                      'streetAddress1'
                    )}
                  />
                }
              />
            </div>

            <div className="form-block">
              <FormControlBlock
                label={!!streetAddress2 && 'Address Line 2'}
                control={
                  <Input
                    id="street-address-2"
                    placeholder="Address Line 2"
                    value={streetAddress2}
                    onChange={this.handleProjectLocationFormChange.bind(
                      this,
                      'streetAddress2'
                    )}
                  />
                }
              />
            </div>

            <div className="form-block">
              <FormControlBlock
                label={!!city && 'City'}
                control={
                  <Input
                    id="city"
                    placeholder="City"
                    value={city}
                    onChange={this.handleProjectLocationFormChange.bind(
                      this,
                      'city'
                    )}
                  />
                }
              />
            </div>

            <div className="form-block multi-control">
              <FormControlBlock
                label={!!state && 'State'}
                control={
                  <Input
                    id="state"
                    placeholder="State"
                    value={state}
                    onChange={this.handleProjectLocationFormChange.bind(
                      this,
                      'state'
                    )}
                  />
                }
              />
              <FormControlBlock
                label={!!zip && 'Zip'}
                control={
                  <Input
                    id="zip"
                    placeholder="Zip"
                    value={zip}
                    onChange={this.handleProjectLocationFormChange.bind(
                      this,
                      'zip'
                    )}
                  />
                }
              />
            </div>

            <div className="buttons-container">
              <Button
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.GREEN}
                label="Add"
                onClick={this.handleSubmit}
                disabled={!this.projectLocationFormValid()}
              />
            </div>
          </Form>
        </FormModal>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    form: getProjectLocationForm(state),
    projectForm: getProjectForm(state),
    showNewProjectLocationModal: getShowNewProjectLocationModal(state),
  };
})(NewProjectLocationModal);
