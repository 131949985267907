import {
  WORK_ORDERS_CREATION_NEW_BUILDING_EDIT,
  WORK_ORDERS_CREATION_NEW_BUILDING_REQUEST,
} from '../../../../actions/admin/work-orders/action-types';

const initialState = {
  readOnly: true,
};

export default function buildingsUiReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_CREATION_NEW_BUILDING_REQUEST:
      return {
        ...state,
        readOnly: true,
      };

    case WORK_ORDERS_CREATION_NEW_BUILDING_EDIT:
      return {
        ...state,
        readOnly: false,
      };

    default:
      return state;
  }
}
