import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './SubmittalAddlAssignees.css';
import { getSubmittalForm } from 'components/admin/projects/details/project-submittal-log/store/selectors';
import {
  addSubmittalAddlAssignee,
  updateSubmittalAddlAssignee,
  deleteSubmittalAddlAssignee,
} from 'components/admin/projects/details/project-submittal-log/store/actions';
import AddlAssigneeForm from 'components/shared/additional-assignees/AddlAssigneeForm';
import moment from 'moment';
import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import SimpleTable from 'components/shared/table/SimpleTable';

class SubmittalAddlAssignees extends Component {
  static propTypes = {
    project: PropTypes.shape({}).isRequired,
    readOnly: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { showAddlAssigneeForm: false };
  }

  handleShowAddlAssigneeForm = (addlAssignee) => {
    this.setState({ showAddlAssigneeForm: true, addlAssignee });
  };

  handleCloseAddlAssigneeForm = () => {
    this.setState({ showAddlAssigneeForm: false });
  };

  handleSubmitAddlAssigneeForm = (addlAssignee) => {
    const { dispatch } = this.props;
    const { id, index } = this.state.addlAssignee;
    if (index !== undefined && index !== null) {
      dispatch(updateSubmittalAddlAssignee({ id, ...addlAssignee }, index));
    } else {
      dispatch(addSubmittalAddlAssignee(addlAssignee));
    }
    this.setState({ showAddlAssigneeForm: false });
  };

  handleDeleteAddlAssignee = (index) => {
    const { dispatch } = this.props;
    const confirmed = window.confirm('Are you sure?');
    confirmed && dispatch(deleteSubmittalAddlAssignee(index));
  };

  mapAssigneees = () => {
    const { form, readOnly } = this.props;

    return form.addlAssignees.map((addlAssignee, index) => {
      return (
        !addlAssignee.destroy && [
          <div className="submittal-addl-assignee">
            <span className="form-block-text">
              {addlAssignee.assigneeLabel}
            </span>
          </div>,
          <div className="submittal-addl-assignee-data">
            <span className="assignee-date form-block-text">
              {addlAssignee.stampAction
                ? addlAssignee.returnedAt &&
                  `${addlAssignee.stampTitle} - ${moment(
                    addlAssignee.returnedAt
                  ).format('MM/DD/YYYY')}`
                : `Due - ${moment(addlAssignee.dueDate).format('MM/DD/YYYY')}`}
            </span>
          </div>,
          <div className="submittal-addl-assignee-actions">
            {!readOnly && (
              <Button
                className="assignee-button"
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Edit"
                onClick={() =>
                  this.handleShowAddlAssigneeForm({
                    index,
                    ...addlAssignee,
                  })
                }
              />
            )}
            {!readOnly && (
              <Button
                className="assignee-button"
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Delete"
                onClick={() => this.handleDeleteAddlAssignee(index)}
              />
            )}
          </div>,
        ]
      );
    });
  };

  render() {
    const { form, readOnly } = this.props;
    const { showAddlAssigneeForm, addlAssignee } = this.state;
    return (
      <div className="submittal-addl-assignees">
        {form.addlAssignees &&
        form.addlAssignees.filter((row) => !row.destroy).length > 0 ? (
          <SimpleTable
            className="submittal-addl-assignees-table"
            rows={this.mapAssigneees().filter((row) => row)}
          />
        ) : (
          readOnly && (
            <p className="submittal-addl-assignees-empty form-block-text">-</p>
          )
        )}
        {!readOnly && (
          <Button
            className="add-assignee-button"
            type={BUTTON_TYPES.LINK}
            icon={BUTTON_ICONS.PLUS}
            label="Add Assignee"
            onClick={this.handleShowAddlAssigneeForm}
          />
        )}
        {showAddlAssigneeForm && (
          <AddlAssigneeForm
            showModal={showAddlAssigneeForm}
            maxDueDate={form.extendedDueDate || form.dueDate}
            addlAssignees={form.addlAssignees}
            addlAssignee={addlAssignee}
            onSubmit={this.handleSubmitAddlAssigneeForm}
            onClose={this.handleCloseAddlAssigneeForm}
          />
        )}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    form: getSubmittalForm(state),
  };
})(SubmittalAddlAssignees);
