import { createSelector } from 'reselect';

export const getBudget = createSelector(
  (state) => state.workOrders.scenes.details.data.budget,
  (budget) => {
    return budget;
  }
);

export const getContractorInvoice = createSelector(
  (state) => state.workOrders.scenes.details.data.contractorInvoice,
  (contractorInvoice) => {
    return contractorInvoice;
  }
);

export const getContractorLienRelease = createSelector(
  (state) => state.workOrders.scenes.details.data.contractorLienRelease,
  (contractorLienRelease) => {
    return contractorLienRelease;
  }
);

export const getContractorLienReleases = createSelector(
  (state) => state.workOrders.scenes.details.data.contractorLienReleases,
  (contractorLienReleases) => {
    return contractorLienReleases;
  }
);

export const getCurrentTask = createSelector(
  (state) => state.workOrders.scenes.details.data.currentTask,
  (currentTask) => {
    return currentTask;
  }
);

export const getEditTask = createSelector(
  (state) => state.workOrders.scenes.details.ui.editTask,
  (editTask) => {
    return editTask;
  }
);

export const getMembers = createSelector(
  (state) => state.workOrders.scenes.details.data.members,
  (members) => {
    return members;
  }
);

export const getNavigationBarSelectedItem = createSelector(
  (state) => state.workOrders.scenes.details.ui.navigationBarSelectedItem,
  (navigationBarSelectedItem) => {
    return navigationBarSelectedItem;
  }
);

export const getProposals = createSelector(
  (state) => state.workOrders.scenes.details.data.proposals,
  (proposals) => {
    return proposals;
  }
);

export const getQuotes = createSelector(
  (state) => state.workOrders.scenes.details.data.quotes,
  (quotes) => {
    return quotes;
  }
);

export const getQuotesSelectedByTaskId = createSelector(
  (state) => state.workOrders.scenes.details.data.quotesSelectedByTaskId,
  (quotesSelectedByTaskId) => {
    return quotesSelectedByTaskId;
  }
);

export const getQuotesTableSelectedFilter = createSelector(
  (state) => state.workOrders.scenes.details.ui.quotesTableSelectedFilter,
  (quotesTableSelectedFilter) => {
    return quotesTableSelectedFilter;
  }
);

export const getSelectedOpenItemId = createSelector(
  (state) => state.workOrders.scenes.details.ui.selectedOpenItemId,
  (selectedOpenItemId) => {
    return selectedOpenItemId;
  }
);

export const getSelectedQuoteId = createSelector(
  (state) => state.workOrders.scenes.details.data.selectedQuoteId,
  (selectedQuoteId) => {
    return selectedQuoteId;
  }
);

export const getTaskNotes = createSelector(
  (state) => state.workOrders.scenes.details.data.taskNotes,
  (taskNotes) => {
    return taskNotes;
  }
);

export const getOpenItemsByTaskId = createSelector(
  (state) => state.workOrders.scenes.details.data.openItemsByTaskId,
  (openItemsByTaskId) => {
    return openItemsByTaskId;
  }
);

export const getPreExistingConditions = createSelector(
  (state) => state.workOrders.scenes.details.ui.preExistingConditions,
  (preExistingConditions) => {
    return preExistingConditions;
  }
);

export const getPreExistingConditionsDescription = createSelector(
  (state) =>
    state.workOrders.scenes.details.ui.preExistingConditionsDescription,
  (preExistingConditionsDescription) => {
    return preExistingConditionsDescription;
  }
);

export const getShowClientStreamModal = createSelector(
  (state) => state.workOrders.scenes.details.ui.showClientStreamModal,
  (showClientStreamModal) => {
    return showClientStreamModal;
  }
);

export const getWorkOrderJobNumberEdit = createSelector(
  (state) => state.workOrders.scenes.details.ui.workOrderJobNumberEdit,
  (workOrderJobNumberEdit) => {
    return workOrderJobNumberEdit;
  }
);

export const getWorkOrderJobNumberSaving = createSelector(
  (state) => state.workOrders.scenes.details.ui.workOrderJobNumberSaving,
  (workOrderJobNumberSaving) => {
    return workOrderJobNumberSaving;
  }
);

export const getWorkOrderJobNumberValue = createSelector(
  (state) => state.workOrders.scenes.details.ui.workOrderJobNumberValue,
  (workOrderJobNumberValue) => {
    return workOrderJobNumberValue;
  }
);

export const getWorkOrderNameEdit = createSelector(
  (state) => state.workOrders.scenes.details.ui.workOrderNameEdit,
  (workOrderNameEdit) => {
    return workOrderNameEdit;
  }
);

export const getWorkOrderNameSaving = createSelector(
  (state) => state.workOrders.scenes.details.ui.workOrderNameSaving,
  (workOrderNameSaving) => {
    return workOrderNameSaving;
  }
);

export const getWorkOrderNameValue = createSelector(
  (state) => state.workOrders.scenes.details.ui.workOrderNameValue,
  (workOrderNameValue) => {
    return workOrderNameValue;
  }
);

export const getWorkOrderNoteEdit = createSelector(
  (state) => state.workOrders.scenes.details.ui.workOrderNoteEdit,
  (workOrderNoteEdit) => {
    return workOrderNoteEdit;
  }
);

export const getWorkOrderNoteSaving = createSelector(
  (state) => state.workOrders.scenes.details.ui.workOrderNoteSaving,
  (workOrderNoteSaving) => {
    return workOrderNoteSaving;
  }
);

export const getWorkOrderNoteValue = createSelector(
  (state) => state.workOrders.scenes.details.ui.workOrderNoteValue,
  (workOrderNoteValue) => {
    return workOrderNoteValue;
  }
);

export const getWorkOrderNoteReminderValue = createSelector(
  (state) => state.workOrders.scenes.details.ui.workOrderNoteReminderValue,
  (workOrderNoteReminderValue) => {
    return workOrderNoteReminderValue;
  }
);

export const getWorkOrderNotes = createSelector(
  (state) => state.workOrders.scenes.details.data.workOrderNotes,
  (workOrderNotes) => {
    return workOrderNotes;
  }
);

export const getSelectedTasks = createSelector(
  (state) => state.workOrders.scenes.details.ui.selectedTasks,
  (selectedTasks) => {
    return selectedTasks;
  }
);

export const getSelectAllChecked = createSelector(
  (state) => state.workOrders.scenes.details.ui.selectAllChecked,
  (selectAllChecked) => {
    return selectAllChecked;
  }
);

export const getWoaSigningUrl = createSelector(
  (state) => state.workOrders.scenes.details.ui.woaSigningUrl,
  (woaSigningUrl) => {
    return woaSigningUrl;
  }
);

export const getShowWoaSigningModal = createSelector(
  (state) => state.workOrders.scenes.details.ui.showWoaSigningModal,
  (showWoaSigningModal) => {
    return showWoaSigningModal;
  }
);

export const getWorkOrderAuthorizations = createSelector(
  (state) => state.workOrders.scenes.details.data.workOrderAuthorizations,
  (workOrderAuthorizations) => {
    return workOrderAuthorizations;
  }
);
