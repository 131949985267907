import {
  PROJECT_LOCATIONS_PROJECT_LOCATION_LOAD_SUCCESS,
  PROJECT_LOCATIONS_PROJECT_LOCATION_PROJECTS_LOAD_SUCCESS,
  PROJECT_LOCATIONS_LOAD_SUCCESS,
  PROJECT_LOCATIONS_ADD_PROJECT_LOCATION_TO_BEGINNING,
} from '../../../actions/admin/project-locations/action-types';

const initialState = {
  projectLocations: [],
  clients: [],
  currentProjectLocation: null, // we need another attribute because the projectLocations list doesn't contain all the info required
  unions: [],
  workOrders: null,
  projects: null,
};

export default function projectLocationsDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case PROJECT_LOCATIONS_PROJECT_LOCATION_LOAD_SUCCESS:
      return {
        ...state,
        currentProjectLocation: action.payload,
      };

    case PROJECT_LOCATIONS_PROJECT_LOCATION_PROJECTS_LOAD_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      };

    case PROJECT_LOCATIONS_LOAD_SUCCESS:
      return {
        ...state,
        projectLocations: action.payload,
      };

    case PROJECT_LOCATIONS_ADD_PROJECT_LOCATION_TO_BEGINNING:
      return {
        ...state,
        projectLocations: [action.payload, ...state.projectLocations],
      };

    default:
      return state;
  }
}
