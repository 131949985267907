import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './RfiQuestion.css';
import Selector from 'components/shared/selector/Selector';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import SearchableDropDownPaginated from 'components/shared/dropdown/SearchableDropDownPaginated';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import RfiDocuments from 'components/admin/projects/details/project-rfi-log/RfiDocuments';
import RfiResponse from 'components/admin/projects/details/project-rfi-log/RfiResponse';
import RfiAddlAssignees from 'components/admin/projects/details/project-rfi-log/RfiAddlAssignees';
import { getDarkMode } from 'selectors/theme';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import Button, {
  BUTTON_COLORS,
  BUTTON_TYPES,
} from 'components/shared/button/Button';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import User from 'domain/user';
import RfiPermissions from 'permissions/rfi-permissions';

class RfiQuestion extends Component {
  static propTypes = {
    currentUser: PropTypes.shape({}).isRequired,
    project: PropTypes.shape({}).isRequired,
    currentRfi: PropTypes.shape({}),
    contribType: PropTypes.string.isRequired,
    dueDate: PropTypes.string,
    questionKey: PropTypes.string.isRequired,
    question: PropTypes.shape({}).isRequired,
    assigneeChanged: PropTypes.func.isRequired,
    resolvedChanged: PropTypes.func.isRequired,
    loadContributors: PropTypes.func.isRequired,
    handleQuestionChange: PropTypes.func.isRequired,
    handleQuestionDeleteFile: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    modalReadOnly: PropTypes.bool,
    gcAcceleration: PropTypes.bool,
    showResponses: PropTypes.bool,
  };

  assigneeExists = (userId) => {
    return this.props.question.assignee[0] === userId;
  };

  render() {
    const {
      contribType,
      currentUser,
      project,
      currentRfi,
      dueDate,
      questionKey,
      question,
      handleQuestionChange,
      handleQuestionDeleteFile,
      handleDeleteRfi,
      assigneeChanged,
      resolvedChanged,
      loadContributors,
      readOnly,
      modalReadOnly,
      gcAcceleration,
      darkMode,
      showResponses,
      questionsReadOnly,
    } = this.props;

    //my reply goes last
    const addlAssignees = [
      ...question.rfi_addl_assignees.filter(
        (assignee) =>
          assignee.assignee_id !== currentUser.id && assignee.response
      ),
      question.rfi_addl_assignees.find(
        (assignee) => assignee.assignee_id === currentUser.id
      ),
    ].filter((assignee) => assignee);

    const canAssign = RfiPermissions.canAssign(currentUser, project);

    return (
      <div className={`rfi-question ${darkMode ? 'dark-mode' : ''}`}>
        <DetailsTitle
          className="rfi-question-title"
          title={`Request ${question.number}`}
          rightButtons={[
            (question.responded_at || gcAcceleration) && (
              <LabeledCheckBox
                key="resolved"
                checked={!!question.resolved}
                onChange={(value) => {
                  handleQuestionChange(questionKey, 'resolved', value);
                  resolvedChanged(questionKey);
                }}
                label="Resolved"
                position="left"
                readOnly={readOnly || User.isContractor(currentUser, project)}
              />
            ),
            !readOnly &&
              questionKey > 1 &&
              !question.responded_at &&
              !questionsReadOnly && (
                <Button
                  key="delete-question"
                  type={BUTTON_TYPES.LINK}
                  color={BUTTON_COLORS.GREEN}
                  label="Delete"
                  onClick={() => {
                    handleDeleteRfi(questionKey);
                  }}
                />
              ),
          ].filter((button) => button)}
        />

        {(readOnly || canAssign) && (
          <React.Fragment>
            <div className="form-block">
              <FormControlBlock
                label={
                  readOnly
                    ? 'Assigned To'
                    : question.assignee.length > 0
                    ? 'Assign To'
                    : null
                }
                control={
                  <SearchableDropDownPaginated
                    placeholder="Assign To"
                    defaultArrow={true}
                    loadOptions={loadContributors}
                    onChange={(option) => {
                      if (!this.assigneeExists(option.value)) {
                        handleQuestionChange(questionKey, 'assignee', [
                          option.value,
                          option.label,
                        ]);
                        assigneeChanged(questionKey);
                      }
                    }}
                    valid={!question.errors.assignee}
                    readOnly={readOnly || questionsReadOnly}
                    value={
                      question.assignee.length > 0
                        ? {
                            value: question.assignee[1],
                            label: question.assignee[1],
                          }
                        : readOnly
                        ? '-'
                        : null
                    }
                  />
                }
              />
            </div>
            <div className="form-block">
              <FormControlBlock
                label="Additional Assignees"
                control={
                  <RfiAddlAssignees
                    contribType={contribType}
                    dueDate={dueDate}
                    question={question}
                    questionKey={questionKey}
                    readOnly={readOnly || questionsReadOnly}
                  />
                }
              />
            </div>
          </React.Fragment>
        )}
        <div className="form-block">
          <FormControlBlock
            label={(readOnly || !!question.action) && 'Action'}
            control={
              <Selector
                placeholder="Action"
                value={question.action}
                onChange={(option) =>
                  handleQuestionChange(questionKey, 'action', option.value)
                }
                options={[
                  { value: 'Question', label: 'Question' },
                  { value: 'Confirm Receipt', label: 'Confirm Receipt' },
                  {
                    value: 'Approval Request',
                    label: 'Approval Request',
                  },
                ]}
                valid={!question.errors.action}
                readOnly={readOnly || questionsReadOnly}
              />
            }
          />
        </div>
        <div className="form-block">
          <FormControlBlock
            label={(readOnly || !!question.question) && 'Request'}
            control={
              <TextareaAutosizeWrapper
                placeholder="Request"
                initialValue={question.question}
                valid={!question.errors.question}
                disabled={readOnly || questionsReadOnly}
                onChange={(value) =>
                  handleQuestionChange(questionKey, 'question', value)
                }
              />
            }
          />
        </div>

        <RfiDocuments
          questionKey={questionKey}
          question={question}
          readOnly={readOnly || questionsReadOnly}
          fileType="req"
          handleQuestionChange={handleQuestionChange}
          handleQuestionDeleteFile={handleQuestionDeleteFile}
        />

        {currentRfi && showResponses && (
          <React.Fragment>
            <DetailsTitle
              className="rfi-response-title official-response"
              title={`Request ${question.number} Official Response`}
            />
            <RfiResponse
              questionKey={questionKey}
              question={question}
              currentUser={currentUser}
              contribType={contribType}
              readOnly={modalReadOnly}
              gcAcceleration={gcAcceleration}
              handleResponseChange={handleQuestionChange}
              handleQuestionDeleteFile={handleQuestionDeleteFile}
            />

            {addlAssignees.length > 0 && (
              <React.Fragment>
                <DetailsTitle
                  className="rfi-response-title contributor-response"
                  title={`Request ${question.number} Contributors Responses`}
                />
                {addlAssignees.map((addlAssignee, index) => (
                  <RfiResponse
                    key={index}
                    questionKey={questionKey}
                    question={question}
                    questionAddlAssignee={addlAssignee}
                    currentUser={currentUser}
                    contribType={contribType}
                    readOnly={modalReadOnly}
                    gcAcceleration={gcAcceleration}
                    handleResponseChange={handleQuestionChange}
                    handleQuestionDeleteFile={handleQuestionDeleteFile}
                  />
                ))}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(RfiQuestion);
