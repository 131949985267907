const setAgendaBackupPayload = (formData, values) => {
  if (values.meetingType) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][meeting_type]',
      values.meetingType
    );
  }
  if (values.projectId) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][project_id]',
      values.projectId
    );
  }
  if (values.minutesSubmittedById) {
    formData.append(
      'meetings_instance[minutes_submitted_by_id]',
      values.minutesSubmittedById
    );
  }
  if (values.minutesPublishedDate) {
    formData.append(
      'meetings_instance[minutes_published_date]',
      values.minutesPublishedDate
    );
  }
  if (values.minutesRepublishedDate) {
    formData.append(
      'meetings_instance[minutes_republished_date]',
      values.minutesReublishedDate
    );
  }
  if (values.agendaPublishedDate) {
    formData.append(
      'meetings_instance[agenda_published_date]',
      values.agendaPublishedDate
    );
  }
  if (values.agendaRepublishedDate) {
    formData.append(
      'meetings_instance[agenda_republished_date]',
      values.agendaReublishedDate
    );
  }
  if (values.agendaSubmittedById) {
    formData.append(
      'meetings_instance[agenda_submitted_by_id]',
      values.agendaSubmittedById
    );
  }
  if (values.agendaRepublishedById) {
    formData.append(
      'meetings_instance[agenda_republished_by_id]',
      values.agendaRepublishedById
    );
  }
  if (values.id) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][id]',
      values.id
    );
  }
  if (values.formattedNumber) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][formatted_number]',
      values.formattedNumber
    );
  }
  if (values.meetingNumber) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][meeting_number]',
      values.meetingNumber
    );
  }

  if (values.disclaimer) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][disclaimer]',
      values.disclaimer
    );
  }

  if (values.nonUserAttendees && values.nonUserAttendees.length > 0) {
    values.nonUserAttendees.forEach((usr, index) => {
      const stringifiedUser = JSON.stringify(usr);
      formData.append(
        `meetings_instance[meetings_agenda_backup_attributes][non_user_attendees][${index}][]`,
        stringifiedUser
      );
    });
  }

  if (values.nonUserAttendeesDetails) {
    const attendeeDetailsStr = JSON.stringify(values.nonUserAttendeesDetails);
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][non_user_attendees_details]',
      attendeeDetailsStr
    );
  }

  if (values.attendees && values.attendees.length > 0) {
    values.attendees.forEach((usr) => {
      formData.append(
        'meetings_instance[meetings_agenda_backup_attributes][attendee_ids][]',
        usr.id
      );
    });
  }
  if (values.meetingDate) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][meeting_date]',
      values.meetingDate
    );
  }
  if (values.title) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][title]',
      values.title
    );
  }
  if (values.address) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][address]',
      values.address
    );
  }
  if (values.location) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][location]',
      values.location
    );
  }
  if (values.jobWalk) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][job_walk]',
      values.jobWalk
    );
  }
  if (values.access) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][access]',
      values.access
    );
  }
  if (values.ranking) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][ranking]',
      values.ranking
    );
  }
  if (values.callInfo) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][call_info]',
      values.callInfo
    );
  }
  if (values.callLink) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][call_link]',
      values.callLink
    );
  }
  if (values.webLink) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][web_link]',
      values.webLink
    );
  }
  if (values.otherInfo) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][other_info]',
      values.otherInfo
    );
  }
  if (values.privateMeeting || values.privateMeeting === false) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][private_meeting]',
      values.privateMeeting
    );
  }
  if (values.startDate) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][start_date]',
      values.startDate
    );
  }
  if (values.startTime) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][start_time]',
      values.startTime
    );
  }
  if (values.endTime) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][end_time]',
      values.endTime
    );
  }
  if (values.meetingExceptions) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][meeting_exceptions]',
      values.meetingExceptions
    );
  }
  if (values.isAgenda || values.isAgenda === false) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][is_agenda]',
      values.isAgenda
    );
  }
  if (values.status) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][status]',
      values.status
    );
  }

  if (values.jobWalkConducted) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][status]',
      values.status
    );
  }

  if (values.attendeeDetails) {
    const attendeeDetailsStr =
      typeof values.attendeeDetails === 'string'
        ? values.attendeeDetails
        : JSON.stringify(values.attendeeDetails);
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][attendee_details]',
      attendeeDetailsStr
    );
  }

  if (values.meetingsCorrections && values.meetingsCorrections.length > 0) {
    values.meetingsCorrections.forEach((mc, mcIndex) => {
      if (mc.id && values.id) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_corrections_attributes][${mcIndex}][id]`,
          mc.id
        );
      }
      if (mc.resolvedById) {
        formData.append(
          `meetings_parent[meetings_corrections_attributes][${mcIndex}][resolved_by_id]`,
          mc.resolvedById
        );
      }
      if (mc.status) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_corrections_attributes][${mcIndex}][status]`,
          mc.status
        );
      }
      if (mc.meetingsParentId) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_corrections_attributes][${mcIndex}][meetings_parent_id]`,
          mc.meetingsParentId
        );
      }
      if (mc.meetingsAgendaBackupId) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_corrections_attributes][${mcIndex}][meetings_agenda_backup_id]`,
          mc.meetingsAgendaBackupId
        );
      }
      if (mc.meetingsActionItemId) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_corrections_attributes][${mcIndex}][meetings_action_item_id]`,
          mc.meetingsActionItemId
        );
      }
      if (mc.correctionRemark) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_corrections_attributes][${mcIndex}][correction_remark]`,
          mc.correctionRemark
        );
      }
      if (mc.correctionRemark) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_corrections_attributes][${mcIndex}][correction_remark]`,
          mc.correctionRemark
        );
      }
      if (mc.submittedById) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_corrections_attributes][${mcIndex}][submitted_by_id]`,
          mc.submittedById
        );
      }
      if (mc.createdById) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_corrections_attributes][${mcIndex}][created_by_id]`,
          mc.createdById
        );
      }
      if (mc.createdAt) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_corrections_attributes][${mcIndex}][created_at]`,
          mc.createdAt
        );
      }
      if (mc.resolvedAt) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_corrections_attributes][${mcIndex}][resolved_at]`,
          mc.resolvedAt
        );
      }
      if (mc.meetingCorrectionNo) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_corrections_attributes][${mcIndex}][meeting_correction_no]`,
          mc.meetingCorrectionNo
        );
      }
    });
  }
  if (values.meetingsActionItems && values.meetingsActionItems.length > 0) {
    values.meetingsActionItems.forEach((actionItem, actionItemIndex) => {
      if (actionItem.dueDate) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][due_date]`,
          actionItem.dueDate
        );
      }
      if (actionItem.meetingsAgendaBackupId) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][meetings_agenda_backup_id]`,
          actionItem.meetingsAgendaBackupId
        );
      }
      if (actionItem.meetingsParentId) {
        formData.append(
          `meetings_parent[meetings_action_items_attributes][${actionItemIndex}][meetings_parent_id]`,
          actionItem.meetingsParentId
        );
      }
      if (actionItem.id && values.id) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][id]`,
          actionItem.id
        );
      }
      if (actionItem.status) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][status]`,
          actionItem.status
        );
      }
      if (actionItem.priority) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][priority]`,
          actionItem.priority
        );
      }
      if (actionItem.description) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][description]`,
          actionItem.description
        );
      }
      if (actionItem.category && actionItem.category !== 'nocategory') {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][category]`,
          actionItem.category
        );
      }
      if (actionItem.responsibleId) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][responsible_id]`,
          actionItem.responsibleId
        );
      }
      if (actionItem.responsibleCompanyId) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][responsible_company_id]`,
          actionItem.responsibleCompanyId
        );
      }
      if (actionItem.isDiscarded) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][is_discarded]`,
          actionItem.isDiscarded
        );
      }
      if (actionItem.isResolved) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][is_resolved]`,
          actionItem.isResolved
        );
      }
      if (actionItem.createdOnInstanceId) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][created_on_instance_id]`,
          actionItem.createdOnInstanceId
        );
      }
      if (actionItem.submittedById) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][submitted_by_id]`,
          actionItem.submittedById
        );
      }
      if (actionItem.createdById) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][created_by_id]`,
          actionItem.createdById
        );
      }
      if (actionItem.resolvedById) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][resolved_by_id]`,
          actionItem.resolvedById
        );
      }
      if (actionItem.projectId) {
        formData.append(
          `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][project_id]`,
          actionItem.projectId
        );
      }
      if (
        actionItem.meetingsActionItemsNotes &&
        actionItem.meetingsActionItemsNotes.length > 0
      ) {
        actionItem.meetingsActionItemsNotes.forEach((note, noteIndex) => {
          if (note.id && values.id) {
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][meetings_action_items_notes_attributes][${noteIndex}][id]`,
              note.id
            );
          }
          if (note.notes)
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][meetings_action_items_notes_attributes][${noteIndex}][notes]`,
              note.notes
            );
          if (note.userId)
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][meetings_action_items_notes_attributes][${noteIndex}][user_id]`,
              note.userId
            );
          if (note.createdById)
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][meetings_action_items_notes_attributes][${noteIndex}][created_by_id]`,
              note.createdById
            );
          if (note.meetingsParentId)
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][meetings_action_items_notes_attributes][${noteIndex}][meetings_parent_id]`,
              note.meetingsParentId
            );
          if (note.meetingsActionItemId)
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][meetings_action_items_notes_attributes][${noteIndex}][meetings_action_item_id]`,
              note.meetingsActionItemId
            );
          if (note.projectId)
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][meetings_action_items_notes_attributes][${noteIndex}][project_id]`,
              note.projectId
            );
          if (note.createdAt)
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][meetings_action_items_notes_attributes][${noteIndex}][created_at]`,
              note.createdAt
            );
        });
      }
      if (
        actionItem.actionItemsUpdates &&
        actionItem.actionItemsUpdates.length > 0
      ) {
        actionItem.actionItemsUpdates.forEach((update, updateIndex) => {
          if (update.id && values.id) {
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][id]`,
              update.id
            );
          }
          if (update.status) {
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][status]`,
              update.status
            );
          }
          if (update.description) {
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][description]`,
              update.description
            );
          }
          if (update.dueDate) {
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][due_date]`,
              update.dueDate
            );
          }
          if (update.priority) {
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][priority]`,
              update.priority
            );
          }
          if (update.projectId) {
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][project_id]`,
              update.projectId
            );
          }

          if (update.createdOnInstanceId) {
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][created_on_instance_id]`,
              update.createdOnInstanceId
            );
          }
          if (update.submittedById) {
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][submitted_by_id]`,
              update.submittedById
            );
          }
          if (update.createdById) {
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][created_by_id]`,
              update.createdById
            );
          }
          if (update.resolvedById) {
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][resolved_by_id]`,
              update.resolvedById
            );
          }
          if (update.isDiscarded) {
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][is_discarded]`,
              update.isDiscarded
            );
          }
          if (update.isResolved) {
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][is_resolved]`,
              update.isResolved
            );
          }
          if (update.responsibleId) {
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][responsible_id]`,
              update.responsibleId
            );
          }
          if (update.responsibleCompanyId) {
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][responsible_company_id]`,
              update.responsibleCompanyId
            );
          }
          /*   if (update.meetingsInstanceId) {
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][meetings_instance_id]`,
              update.meetingsInstanceId
            );
          } */
          if (update.meetingsAgendaBackupId) {
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][meetings_agenda_backup_id]`,
              update.meetingsAgendaBackupId
            );
          }
          if (update.meetingsParentId) {
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][meetings_parent_id]`,
              update.meetingsParentId
            );
          }
          if (update.meetingsActionItemId) {
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][meetings_action_item_id]`,
              update.meetingsActionItemId
            );
          }
          if (update.createdAt) {
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][created_at]`,
              update.createdAt
            );
          }
          if (update.resolvedAt) {
            formData.append(
              `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][resolved_at]`,
              update.resolvedAt
            );
          }
          if (update.nestedUpdates && update.nestedUpdates.length > 0) {
            update.nestedUpdates.forEach((nu, nuIndex) => {
              if (nu.id && values.id && typeof nu.id === 'number') {
                formData.append(
                  `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][id]`,
                  nu.id
                );
              }
              if (nu.description) {
                formData.append(
                  `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][description]`,
                  nu.description
                );
              }
              if (nu.projectId) {
                formData.append(
                  `meetings_instance[meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][project_id]`,
                  nu.projectId
                );
              }
              if (nu.status) {
                formData.append(
                  `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][status]`,
                  nu.status
                );
              }
              if (nu.priority) {
                formData.append(
                  `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][priority]`,
                  nu.priority
                );
              }
              if (nu.dueDate) {
                formData.append(
                  `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][due_date]`,
                  nu.dueDate
                );
              }
              if (nu.responsibleId) {
                formData.append(
                  `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][responsible_id]`,
                  nu.responsibleId
                );
              }
              if (nu.responsibleCompanyId) {
                formData.append(
                  `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][responsible_company_id]`,
                  nu.responsibleCompanyId
                );
              }
              if (nu.submittedById) {
                formData.append(
                  `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][submitted_by_id]`,
                  nu.submittedById
                );
              }
              if (nu.createdById) {
                formData.append(
                  `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][created_by_id]`,
                  nu.createdById
                );
              }
              if (nu.resolvedById) {
                formData.append(
                  `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][resolved_by_id]`,
                  nu.resolvedById
                );
              }
              if (nu.createdOnInstanceId) {
                formData.append(
                  `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][created_on_instance_id]`,
                  nu.createdOnInstanceId
                );
              }
              if (nu.meetingsParentId) {
                formData.append(
                  `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][meetings_parent_id]`,
                  nu.meetingsParentId
                );
              }
              /* if (nu.meetingsInstanceId) {
                formData.append(
                  `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][meetings_instance_id]`,
                  nu.meetingsInstanceId
                );
              } */
              if (nu.meetingsAgendaBackupId) {
                formData.append(
                  `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][meetings_agenda_backup_id]`,
                  nu.meetingsAgendaBackupId
                );
              }
              if (nu.meetingsActionItemId) {
                formData.append(
                  `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][meetings_action_item_id]`,
                  nu.meetingsActionItemId
                );
              }
              if (nu.parentUpdateId) {
                formData.append(
                  `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][parent_update_id]`,
                  nu.parentUpdateId
                );
              }
              if (nu.isDiscarded) {
                formData.append(
                  `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][is_discarded]`,
                  nu.isDiscarded
                );
              }
              if (nu.isResolved) {
                formData.append(
                  `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][is_resolved]`,
                  nu.isResolved
                );
              }
              if (nu.createdAt) {
                formData.append(
                  `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][created_at]`,
                  nu.createdAt
                );
              }
              if (nu.resolvedAt) {
                formData.append(
                  `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][resolved_at]`,
                  nu.resolvedAt
                );
              }
              if (nu.createdOnInstanceId) {
                formData.append(
                  `meetings_instance[meetings_agenda_backup_attributes][meetings_action_items_attributes][${actionItemIndex}][action_items_updates_attributes][${updateIndex}][nested_updates_attributes][${nuIndex}][created_on_instance_id]`,
                  nu.createdOnInstanceId
                );
              }
            });
          }
        });
      }
    });
  }
  if (values.meetingsInstancesNotes) {
    if (values.meetingsInstancesNotes.id && values.id) {
      formData.append(
        'meetings_instance[meetings_agenda_backup_attributes][meetings_instances_notes_attributes][0][id]',
        values.meetingsInstancesNotes.id
      );
    }
    if (values.meetingsInstancesNotes.notes)
      formData.append(
        'meetings_instance[meetings_agenda_backup_attributes][meetings_instances_notes_attributes][0][notes]',
        values.meetingsInstancesNotes.notes
      );
    if (values.meetingsInstancesNotes.userId)
      formData.append(
        'meetings_instance[meetings_agenda_backup_attributes][meetings_instances_notes_attributes][0][user_id]',
        values.meetingsInstancesNotes.userId
      );
    if (values.meetingsInstancesNotes.createdById)
      formData.append(
        'meetings_instance[meetings_agenda_backup_attributes][meetings_instances_notes_attributes][0][created_by_id]',
        values.meetingsInstancesNotes.createdById
      );
    if (values.meetingsInstancesNotes.meetingsParentId)
      formData.append(
        'meetings_instance[meetings_agenda_backup_attributes][meetings_instances_notes_attributes][0][meetings_parent_id]',
        values.meetingsInstancesNotes.meetingsParentId
      );
    /*  if (values.meetingsInstancesNotes.meetingsInstanceId)
      formData.append(
        'meetings_instance[meetings_agenda_backup_attributes][meetings_instances_notes_attributes][0][meetings_instance_id]',
        values.meetingsInstancesNotes.meetingsInstanceId
      ); */
    if (values.meetingsInstancesNotes.meetingsAgendaBackupId)
      formData.append(
        'meetings_instance[meetings_agenda_backup_attributes][meetings_instances_notes_attributes][0][meetings_agenda_backup_id]',
        values.meetingsInstancesNotes.meetingsAgendaBackupId
      );
    if (values.meetingsInstancesNotes.projectId)
      formData.append(
        'meetings_instance[meetings_agenda_backup_attributes][meetings_instances_notes_attributes][0][project_id]',
        values.meetingsInstancesNotes.projectId
      );
    if (values.meetingsInstancesNotes.createdAt)
      formData.append(
        'meetings_instance[meetings_agenda_backup_attributes][meetings_instances_notes_attributes][0][created_at]',
        values.meetingsInstancesNotes.createdAt
      );
  }
  if (values.hasDirectoryPdf || values.hasDirectoryPdf === false) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][has_directory_pdf]',
      values.hasDirectoryPdf
    );
  }
  if (values.hasOpenItemsLogPdf || values.hasOpenItemsLogPdf === false) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][has_open_items_log_pdf]',
      values.hasOpenItemsLogPdf
    );
  }
  if (values.hasRfaLogPdf || values.hasRfaLogPdf === false) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][has_rfa_log_pdf]',
      values.hasRfaLogPdf
    );
  }
  if (values.hasRfiLogPdf || values.hasRfiLogPdf === false) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][has_rfi_log_pdf]',
      values.hasRfiLogPdf
    );
  }
  if (values.hasRfiLogPdf || values.hasRfiLogPdf === false) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][has_rfi_log_pdf]',
      values.hasRfiLogPdf
    );
  }
  if (values.hasSubmittalLogPdf || values.hasSubmittalLogPdf === false) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][has_submittal_log_pdf]',
      values.hasSubmittalLogPdf
    );
  }
  if (values.hasScheduleSummaryPdf || values.hasScheduleSummaryPdf === false) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][has_schedule_summary_pdf]',
      values.hasScheduleSummaryPdf
    );
  }
  if (values.hasLookaheadPdf || values.hasLookaheadPdf === false) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][has_lookahead_pdf]',
      values.hasLookaheadPdf
    );
  }
  if (values.hasMilestonesPdf || values.hasMilestonesPdf === false) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][has_milestones_pdf]',
      values.hasMilestonesPdf
    );
  }
  if (values.meetingsInstanceId) {
    formData.append(
      'meetings_instance[meetings_agenda_backup_attributes][meetings_instance_id]',
      values.meetingsInstanceId
    );
  }
  appendFilesInAgendaBackup(formData, values);
  return formData;
};
const appendTypeFilesInAgendaBackup = (apiPayload, values, type) => {
  const key = `${type === 'document' ? 'attachedDocuments' : 'attachedImages'}`;
  const files = values[key];
  const deletedFilesType = `${
    type === 'document' ? 'deletedDocuments' : 'deletedImages'
  }`;
  const deletedFiles = values[deletedFilesType];
  let index = 0;
  if (!files) return;
  files
    .filter((file) => file.file)
    .forEach(({ file }) => {
      apiPayload.append(
        `meetings_instance[meetings_agenda_backup_attributes][attached_${type}s_attributes][${index}][${type}]`,
        file,
        file.name
      );
      index++;
    });
  if (!deletedFiles) return;
  deletedFiles
    .filter((file) => file.id)
    .forEach((file) => {
      apiPayload.append(
        `meetings_instance[meetings_agenda_backup_attributes][attached_${type}s_attributes][${index}][id]`,
        file.id
      );
      apiPayload.append(
        `meetings_instance[meetings_agenda_backup_attributes][attached_${type}s_attributes][${index}][_destroy]`,
        true
      );
      index++;
    });
};
const appendFilesInAgendaBackup = (apiPayload, values) => {
  appendTypeFilesInAgendaBackup(apiPayload, values, 'document');
  appendTypeFilesInAgendaBackup(apiPayload, values, 'image');
};

export default setAgendaBackupPayload;
