import React, { Component } from 'react';
import { Col, Grid, Row, Button } from 'react-bootstrap';
import '../Client.css';
import './Login.css';
import { Link, Redirect } from 'react-router-dom';
import {
  InputLabel,
  InputAdornment,
  Input,
  IconButton,
  FormControl,
} from '@mui/material';
import queryString from 'query-string';
import accountVerifiedPng from './account-verified.png';
import logoPng from './logo.png';
import eyePng from './eye.png';
import eyeSlachPng from './eyeSlash.png';
import { connect } from 'react-redux';
import Loader from 'components/shared/loader/LoginLoader';
import { authenticationRequest } from '../../../actions/authentication';
import { getLoginBackgroundImage } from '../../../actions/loginBackgroundImage';
import PropTypes from 'prop-types';
import { browserClassname } from 'services/utils/browser-util';
import SupportLink from '../SupportLink';

export class Login extends Component {
  static propTypes = {
    loggedIn: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string),
    onSubmit: PropTypes.func,
  };

  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      showPassword: false,
      loadImage: true,
      loadImageError: false,
      noEmail: false,
      noPassword: false,
    };
  }

  componentDidMount() {
    if (!this.props.imageUrl) {
      this.props.getBackgroundImage();
      this.setState({
        loadImage: false,
        loadImageError: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.imageUrl || this.props.imageUrl === '') &&
      !this.state.loadImage
    ) {
      const imageLoader = new Image();
      imageLoader.src = this.props.imageUrl;
      imageLoader.onload = () => {
        this.setState({
          loadImage: true,
          loadImageError: false,
        });
      };

      imageLoader.onerror = () => {
        this.setState({
          loadImage: true,
          loadImageError: true,
        });
      };
    }
  }

  handleEmailChange = (event) => {
    this.setState({
      email: event.target.value,
      noEmail: false,
    });
  };

  handlePasswordChange = (event) => {
    this.setState({
      password: event.target.value,
      noPassword: false,
    });
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.state.email || !this.state.password) {
      this.setState({
        noEmail: !this.state.email,
        noPassword: !this.state.password,
      });
      return;
    }

    this.props
      .onSubmit(this.state.email, this.state.password)
      .then((response) => {
        if (!response.ok) {
          this.setState({ password: '' });
        }
      });
  };

  render() {
    const params = queryString.parse(this.props.location.search);
    const onboarding = params.onboarding;
    const backgroundImage =
      !this.state.loadImageError && this.props.imageUrl
        ? { backgroundImage: 'url(' + this.props.imageUrl + ')' }
        : {};

    let currentView;
    if (this.props.loggedIn) {
      if (onboarding) {
        currentView = <Redirect to={{ pathname: '/user-invitations' }} />;
      } else {
        let newLocation = '/';
        let newLocationSearch = null;
        if (
          this.props.location.state !== undefined &&
          this.props.location.state.hasOwnProperty('referrer')
        ) {
          // If a referrer location is defined in state, go to it
          newLocation = this.props.location.state.referrer;
          newLocationSearch = this.props.location.state.search;
        }

        currentView = (
          <Redirect
            to={{
              pathname: newLocation,
              search: newLocationSearch,
            }}
          />
        );
      }
    } else {
      currentView = (
        <div className="login client" style={backgroundImage}>
          <Grid className="max-height">
            <div className="align-center">
              <Col md={9}>
                <div
                  className={browserClassname({
                    firefox: 'form-container-firefox',
                    default: 'form-container',
                  })}
                >
                  <form onSubmit={this.handleSubmit}>
                    <Col md={7} sm={9} className="left-panel">
                      <Row>
                        <Col md={12}>
                          <div className="logo">
                            <img src={logoPng} alt="Logo" />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div className="title">
                            <div className="title-1">Welcome</div>
                            <div className="title-2">
                              Log In To 360+ Projects
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {onboarding ? (
                        <Row>
                          <Col md={6} mdOffset={3}>
                            <div className="subtitle">
                              <img
                                src={accountVerifiedPng}
                                alt="Account verified"
                              />
                              <span>Account Verified</span>
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                      <Row>
                        <Col md={12}>
                          <FormControl
                            fullWidth
                            className={this.state.noEmail ? 'label-error' : ''}
                          >
                            <InputLabel htmlFor="standard-adornment-username">
                              Username
                            </InputLabel>
                            <Input
                              id="standard-adornment-username"
                              type="email"
                              defaultValue={this.state.email}
                              onChange={this.handleEmailChange}
                              error={
                                this.props.errors.length > 0 ||
                                this.state.noEmail
                              }
                            />
                          </FormControl>
                        </Col>

                        <Col md={12} className="input-margin-bottom" />

                        <Col md={12}>
                          <FormControl
                            fullWidth
                            className={
                              this.state.noPassword ? 'label-error' : ''
                            }
                          >
                            <InputLabel htmlFor="standard-adornment-password">
                              Password
                            </InputLabel>
                            <Input
                              id="standard-adornment-password"
                              type={
                                this.state.showPassword ? 'text' : 'password'
                              }
                              defaultValue={this.state.password}
                              onChange={this.handlePasswordChange}
                              error={
                                this.props.errors.length > 0 ||
                                this.state.noPassword
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                  >
                                    {
                                      <img
                                        src={
                                          this.state.showPassword
                                            ? eyeSlachPng
                                            : eyePng
                                        }
                                        alt="Show password"
                                        className="eye"
                                      />
                                    }
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                            <Link
                              to="/recover-password"
                              className="recover-password-span"
                            >
                              Forgot your password?
                            </Link>
                          </FormControl>
                        </Col>

                        {this.props.errors.length > 0 ? (
                          <Col md={12}>
                            <div className="errors">{this.props.errors}</div>
                          </Col>
                        ) : null}

                        <Col className="mobile-container-button">
                          <Button type="submit" className="button-log-in">
                            <div className="text-log-in" />
                            <div className="icon-log-in" />
                          </Button>
                        </Col>

                        <Col md={6} mdOffset={3}>
                          <SupportLink />
                          {/*<div className="bottom-title-wrapper register-link-wrapper">
                            <Link to="/register" className="register-link-span">
                              Create new account
                            </Link>
                          </div>*/}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={5} sm={3} className="right-panel">
                      <Row md={12}>
                        <Button type="submit" className="button-log-in">
                          <div className="text-log-in" />
                          <div className="icon-log-in" />
                        </Button>
                      </Row>
                    </Col>
                  </form>
                </div>
              </Col>
            </div>
          </Grid>
        </div>
      );
    }

    return this.state.loadImage ? (
      <div>{currentView}</div>
    ) : (
      <Loader loadImage={this.state.loadImage} />
    );
  }
}

export default connect(
  (state) => {
    return {
      loggedIn: state.auth.loggedIn,
      errors: state.auth.errors,
      imageUrl: state.loginBackgroundImage.url,
    };
  },
  (dispatch) => {
    return {
      onSubmit: (email, password) => {
        return dispatch(authenticationRequest(email, password));
      },
      getBackgroundImage: () => dispatch(getLoginBackgroundImage()),
    };
  }
)(Login);
