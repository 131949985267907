import { createSceneReducer } from '../../reducers-util';
import { combineReducers } from 'redux';
import contractorTeamDataReducer from './contractor-team.data';
import contractorTeamUiReducer from './contractor-team.ui';
import clientTeamDataReducer from './client-team.data';
import clientTeamUiReducer from './client-team.ui';
import generalContractorTeamDataReducer from 'reducers/admin/team/general-contractor-team.data';
import generalContractorTeamUiReducer from 'reducers/admin/team/general-contractor-team.ui';
import ownerAuthorizedRepTeamDataReducer from 'reducers/admin/team/owner-authorized-rep-team.data';
import ownerAuthorizedRepTeamUiReducer from 'reducers/admin/team/owner-authorized-rep-team.ui';

export default createSceneReducer(
  undefined,
  undefined,
  combineReducers({
    client: createSceneReducer(clientTeamDataReducer, clientTeamUiReducer),
    contractor: createSceneReducer(
      contractorTeamDataReducer,
      contractorTeamUiReducer
    ),
    generalContractor: createSceneReducer(
      generalContractorTeamDataReducer,
      generalContractorTeamUiReducer
    ),
    ownerAuthorizedRep: createSceneReducer(
      ownerAuthorizedRepTeamDataReducer,
      ownerAuthorizedRepTeamUiReducer
    ),
  })
);
