import React, { Component } from 'react';
import { connect } from 'react-redux';
import InviteUserModal, {
  UserModalState,
} from 'components/shared/modal/InviteUserModal';
import {
  getCurrentUserId,
  getForm,
  getInvalidFields,
  getRoles,
  getShowInviteUserModal,
  getUserModalState,
  getValidationMessage,
} from 'selectors/admin/users/users';
import {
  deleteUserRequest,
  loadRolesRequest,
  setShowInviteUserModal,
  submitFormRequest,
  updateFormValue,
} from 'actions/admin/users/users';
import MessageModal from 'components/shared/modal/MessageModal';
import { formatPhoneNumber } from 'services/utils/text-util';
import { companyRoleOptions } from 'domain/user-role-enum';

class UsersInviteUserModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmationMessage: false,
    };
  }

  componentDidMount() {
    if (this.props.roles.length === 0) {
      this.props.dispatch(loadRolesRequest());
    }
  }

  handleClose = () => {
    this.props.dispatch(setShowInviteUserModal(false));
  };

  handleSubmit = () => {
    const promise = this.props.dispatch(
      submitFormRequest(this.props.form, this.props.currentUserId)
    );

    if (promise !== null && this.props.userModalState === UserModalState.NEW) {
      promise.then((response) => {
        if (response.ok) {
          this.setState({
            showConfirmationMessage: true,
          });
        }
      });
    }
  };

  handleAttributeChange = (attribute, value) => {
    this.props.dispatch(updateFormValue(attribute, value));
  };

  handleEdit = () => {
    this.props.dispatch(setShowInviteUserModal(true, UserModalState.EDIT));
  };

  handleDeleteConfirmation = (result) => {
    if (result) {
      this.props.dispatch(deleteUserRequest(this.props.currentUserId));
    }
  };

  handleCloseConfirmationModal = () => {
    this.setState({
      showConfirmationMessage: false,
    });
  };

  render() {
    return (
      <div>
        <InviteUserModal
          form={[
            {
              attribute: 'firstName',
              label: 'First Name',
              renderer: 'input',
            },
            {
              attribute: 'lastName',
              label: 'Last Name',
              renderer: 'input',
            },
            {
              attribute: 'title',
              label: 'Title',
              renderer: 'input',
            },
            {
              attribute: 'email',
              label: 'Email',
              renderer: 'input',
            },
            {
              attribute: 'phoneNumber',
              formatter: formatPhoneNumber,
              label: 'Phone Number',
              renderer: 'input',
            },
            {
              attribute: 'rolesId',
              label: 'Role',
              renderer: 'dropdown',
              options: companyRoleOptions(this.props.roles),
            },
          ]}
          invalidFields={this.props.invalidFields}
          modalState={this.props.userModalState}
          show={this.props.showInviteUserModal}
          values={this.props.form}
          validationMessage={this.props.validationMessage}
          onAttributeChange={this.handleAttributeChange}
          onClose={this.handleClose}
          onDeleteConfirmation={this.handleDeleteConfirmation}
          onEdit={this.handleEdit}
          onSubmit={this.handleSubmit}
        />

        <MessageModal
          show={this.state.showConfirmationMessage}
          message={'Invite has been sent'}
          onHide={this.handleCloseConfirmationModal}
        />
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentUserId: getCurrentUserId(state),
    form: getForm(state),
    invalidFields: getInvalidFields(state),
    roles: getRoles(state),
    showInviteUserModal: getShowInviteUserModal(state),
    userModalState: getUserModalState(state),
    validationMessage: getValidationMessage(state),
  };
})(UsersInviteUserModalContainer);
