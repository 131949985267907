import React from 'react';
import { connect } from 'react-redux';

import { getDarkMode } from 'selectors/theme';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';

import Menu from 'components/shared/menu/Menu';
import moreOptionsSvg from 'images/more-options.svg';
import moreOptionsDarkSvg from 'images/more-options-dark.svg';
import moreOptionsHighlightedSvg from 'images/more-options-highlighted.svg';
import moreOptionsHighlightedDarkSvg from 'images/more-options-highlighted-dark.svg';

import { itemsToIds } from 'services/utils/project-photos-util';
import ProjectPhotoPermissions from 'permissions/project-photo-permissions';

import {
  getProjectPhotosCurrentAlbum,
  getProjectPhotosFilters,
  getProjectPhotosSelectedAlbums,
  getProjectPhotosSelectedPhotos,
  getProjectPhotosSelecting,
} from './store/selectors';
import useProjectPhotosActions from './ProjectPhotosActions.hook';

const ProjectPhotosMenu = (props) => {
  const {
    darkMode,
    selecting,
    currentUser,
    currentProject,
    currentAlbum,
    selectedAlbums,
    selectedPhotos,
    currentFilters,
  } = props;
  const {
    onUnselect,
    onSelect,
    onMove,
    onEdit,
    onDownload,
    onEmail,
    onArchive,
    onRecycle,
    onRestoreArchived,
    onRestoreRecycled,
    onErase,
  } = useProjectPhotosActions(props);

  const getIds = () => {
    if (!selecting && !currentAlbum) return [];
    if (!selecting) return [{ id: currentAlbum.id, type: 'album' }];

    const items = [...selectedAlbums, ...selectedPhotos];
    return itemsToIds(items);
  };

  const reducer = (acc, curr) =>
    acc &&
    ProjectPhotoPermissions.hasItemPermission(
      currentUser,
      currentProject,
      curr
    );
  const hasPermissionItems = () => {
    if (!selecting)
      return ProjectPhotoPermissions.hasItemPermission(
        currentUser,
        currentProject,
        currentAlbum
      );

    let can = selectedAlbums.length > 0 || selectedPhotos.length > 0;
    if (can) can = selectedAlbums.reduce(reducer, can);
    if (can) can = selectedPhotos.reduce(reducer, can);

    return can;
  };

  const getActions = () => {
    const res = [];
    const ids = getIds();
    const hasPermission = hasPermissionItems();

    if (!selecting) res.push({ content: 'Select', onClick: onSelect });
    if (selecting) res.push({ content: 'End Selection', onClick: onUnselect });

    if (selecting && hasPermission)
      res.push({ content: 'Move', onClick: onMove(ids) });
    if (hasPermission) res.push({ content: 'Edit', onClick: onEdit(ids) });

    if (currentAlbum || selectedAlbums.length || selectedPhotos.length)
      res.push({ content: 'Download', onClick: onDownload(ids) });
    if (hasPermission) res.push({ content: 'Email', onClick: onEmail(ids) });

    if (!currentFilters.status && selecting && hasPermission)
      res.push({ content: 'Archive', onClick: onArchive(ids) });
    if (currentFilters.status === 'archived' && selecting && hasPermission)
      res.push({ content: 'Restore', onClick: onRestoreArchived(ids) });

    if (!currentFilters.status && selecting && hasPermission)
      res.push({ content: 'Recycle', onClick: onRecycle(ids) });
    if (currentFilters.status === 'recycled' && selecting && hasPermission)
      res.push({ content: 'Restore', onClick: onRestoreRecycled(ids) });

    if (
      selecting &&
      currentFilters.status === 'recycled' &&
      ProjectPhotoPermissions.canErase(currentUser, currentProject)
    )
      res.push({ content: 'Delete', onClick: onErase(ids) });

    return res;
  };
  const actions = getActions();

  const selectorImage = () => {
    if (selecting)
      return darkMode
        ? moreOptionsHighlightedDarkSvg
        : moreOptionsHighlightedSvg;

    return darkMode ? moreOptionsDarkSvg : moreOptionsSvg;
  };

  const selector = (
    <div
      className={`menu-image-sector-container ${selecting ? 'batch' : null}`}
    >
      <img className="menu-image-sector" src={selectorImage()} alt="selector" />
      {selecting ? (
        <div className="menu-image-sector-batch">Batch Action</div>
      ) : null}
    </div>
  );

  return (
    actions.length > 0 && (
      <Menu
        key="more-actions"
        className={'rotated-icon'}
        selector={selector}
        items={actions}
        highlighted={selecting}
      />
    )
  );
};

export default connect((state) => ({
  darkMode: getDarkMode(state),
  currentUser: getCurrentUser(state),
  currentProject: getCurrentProject(state),
  currentAlbum: getProjectPhotosCurrentAlbum(state),
  selecting: getProjectPhotosSelecting(state),
  selectedAlbums: getProjectPhotosSelectedAlbums(state),
  selectedPhotos: getProjectPhotosSelectedPhotos(state),
  currentFilters: getProjectPhotosFilters(state),
}))(ProjectPhotosMenu);
