import React, { Component } from 'react';
import { connect } from 'react-redux';
import Input from 'components/shared/input/Input';
import PropTypes from 'prop-types';
import FormModal from 'components/shared/modal/FormModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import './CreateFolderModal.css';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import DocumentPermissions from 'permissions/document-permissions';

class CreateFolderModal extends Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    folderName: PropTypes.string,
    prefix: PropTypes.string,
    title: PropTypes.string.isRequired,
    renameFolder: PropTypes.bool,
    buttonLabel: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      folderName: props.folderName || '',
      visibility: '',
    };
  }

  isSubFolder = () => {
    return /\/.*\//.test(this.props.prefix);
  };

  handleChange = (folderName) => {
    this.setState({ folderName });
  };

  handleSubmit = () => {
    const { prefix, parentFile } = this.props;
    const { folderName, visibility } = this.state;
    let visibilityKey = parentFile
      ? parentFile.visibilityKey
      : visibility || 'public';
    var values = {
      prefix: prefix,
      oldFolderName: this.props.folderName,
      folderName: folderName,
      visibility: visibilityKey,
    };

    this.props.onSubmit(values);
  };

  togglePrivate = (key) => {
    const isSameValue = key === this.state.visibility;
    this.setState({ visibility: isSameValue ? '' : key });
  };

  render() {
    const {
      showModal,
      onClose,
      title,
      currentUser,
      currentProject,
      renameFolder,
      buttonLabel,
    } = this.props;
    const { folderName, visibility } = this.state;
    return (
      showModal && (
        <FormModal
          className="create-folder-modal"
          show={showModal}
          onClose={onClose}
          subTitle={title}
        >
          <Input
            id="folderName"
            placeholder="Folder Name"
            theme="normal"
            value={folderName}
            onChange={this.handleChange}
          />
          {!renameFolder && !this.isSubFolder() && (
            <React.Fragment>
              <div className="title-permissions">Folder Permission</div>
              <div className="container-permissions-checkbox">
                <LabeledCheckBox
                  className="permissions-checkbox"
                  label="Public"
                  position="left"
                  checked={visibility === 'public'}
                  onChange={() => this.togglePrivate('public')}
                />
                <div className="checkbox-divide" />
                <LabeledCheckBox
                  className="permissions-checkbox"
                  label="Shared"
                  position="left"
                  checked={visibility === 'shared'}
                  onChange={() => this.togglePrivate('shared')}
                />
                <div className="checkbox-divide" />
                {DocumentPermissions.canManagePrivateFolders(
                  currentUser,
                  currentProject
                ) && (
                  <LabeledCheckBox
                    className="permissions-checkbox"
                    label="Private"
                    position="left"
                    checked={visibility === 'private'}
                    onChange={() => this.togglePrivate('private')}
                  />
                )}
              </div>
            </React.Fragment>
          )}
          <div className="buttons-container">
            <Button
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              disabled={!folderName}
              label={buttonLabel}
              onClick={this.handleSubmit}
            />
          </div>
        </FormModal>
      )
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
  };
})(CreateFolderModal);
