import React, { Component } from 'react';
import './Reports.css';
import { goToWorkOrder } from 'actions/admin/work-orders/work-orders';
import BaseReport from 'components/admin/reports/BaseReport';
import { connect } from 'react-redux';
import { getReportResults } from 'selectors/admin/reports/reports';

class MissingContractorInvoicesReport extends Component {
  handleRowSelection = (index) => {
    this.props.dispatch(goToWorkOrder(this.props.reportResults.data[index].id));
  };

  createTableRowsData = () => {
    if (this.props.reportResults !== null) {
      return this.props.reportResults.data.map((result, index) => {
        const contractorsWithoutInvoice = result.contractors_without_invoice;
        let contractorsWithoutInvoiceField;
        if (contractorsWithoutInvoice.length > 0) {
          contractorsWithoutInvoiceField = contractorsWithoutInvoice
            .map(function (con) {
              return con.name;
            })
            .join(' - ');
        } else {
          contractorsWithoutInvoiceField = 'All invoices uploaded';
        }

        const rowData = {
          id: index,
          selectable: true,
          data: [
            result.number,
            result.name,
            result.client.name,
            contractorsWithoutInvoiceField,
          ],
        };
        return {
          first: true,
          rowData,
          workOrderId: result.id,
        };
      });
    } else {
      return [];
    }
  };

  render() {
    return (
      <BaseReport
        reportName="missing_contractor_invoices_report"
        tableHeader={[
          'WO #',
          'NAME',
          'OWNER NAME',
          'MISSING CONTRACTOR INVOICES',
        ]}
        tableRowsData={this.createTableRowsData()}
        title="Missing Contractor Invoices Report"
        onRowSelection={this.handleRowSelection}
      />
    );
  }
}

export default connect((state) => {
  return {
    reportResults: getReportResults(state),
  };
})(MissingContractorInvoicesReport);
