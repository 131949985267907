export const USER_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PENDING_INVITATION: 'pending_invitation',
  REGISTERING: 'registering',
  VERIFYING: 'verifying',
  REJECTED: 'rejected',
};

export const USER_STATUS_SORTED = [
  USER_STATUS.ACTIVE,
  USER_STATUS.INACTIVE,
  USER_STATUS.PENDING_INVITATION,
  USER_STATUS.REGISTERING,
  USER_STATUS.VERIFYING,
  USER_STATUS.REJECTED,
];

export const USER_STATUS_LABELS = {
  [USER_STATUS.ACTIVE]: 'Active',
  [USER_STATUS.INACTIVE]: 'Inactive',
  [USER_STATUS.PENDING_INVITATION]: 'Pending Invitation',
  [USER_STATUS.REGISTERING]: 'Registering',
  [USER_STATUS.VERIFYING]: 'Verifying',
  [USER_STATUS.REJECTED]: 'Access Denied',
};

export const userStatusLabel = (role) => USER_STATUS_LABELS[role] || role;
