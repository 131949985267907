import axios from 'axios';
import { s3Api } from 'services/api/s3-api';

const useDirectUpload = () => {
  const getSignedUrl = async (file, S3params) => {
    const { name, type: contentType } = file;

    const params = { ...S3params, name, contentType };
    const response = await s3Api.getSignedUrl(params, true);

    return response.data.signedUrl;
  };

  const uploadRequest = async (url, file, onUploadProgress) => {
    const config = {
      headers: { 'x-amz-acl': 'public-read', 'Content-Type': file.type },
    };
    if (typeof onUploadProgress === 'function') {
      config.onUploadProgress = onUploadProgress;
    }

    const API = axios.create({});
    const response = await API.put(url, file, config);

    return { ok: response.statusText === 'OK' };
  };

  const validateUpload = async (signedUrl) => {
    const url = getUrl(signedUrl);
    const response = await s3Api.getMetadata(url, true);

    const { headers } = response;
    const size = headers['content-length'];
    if (!size || size === '0') response.ok = false;

    return response;
  };

  const getUrl = (signedUrl) => {
    return signedUrl.split('?')[0];
  };

  const buildResponse = (result, validate, signedUrl, file) => {
    if (!result.ok || !validate.ok) return { ok: false };

    return {
      name: file.name,
      type: file.type,
      url: getUrl(signedUrl),
      ok: true,
    };
  };

  const directUpload = async (
    file,
    S3params,
    onUploadProgress = () => {},
    check = true
  ) => {
    const signedUrl = await getSignedUrl(file, S3params);
    const result = await uploadRequest(signedUrl, file, onUploadProgress);
    const validate = check ? await validateUpload(signedUrl) : { ok: true };
    const response = buildResponse(result, validate, signedUrl, file);

    return response;
  };

  return { directUpload };
};

export default useDirectUpload;
