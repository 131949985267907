import User from 'domain/user';
import ProjectPermissions from 'permissions/project-permissions';
import moment from 'moment';

const PER_PAGE = 5;

export class Dashboard {
  constructor(currentUser) {
    this._perPage = PER_PAGE;
    this._admin = User.isAdmin(currentUser);
    this._showMyProjects = ProjectPermissions.canViewMyProjects(currentUser);

    this._filtersGeneral = this.buildFiltersGeneral();
    this._filters = this.buildFilters();
    this._filtersDrs = this.buildFiltersDrs();
    this._filtersBidsDue = this.buildFiltersBidsDue();
    this._filtersQuotesBidsDue = this.buildFiltersQuotesBidsDue();
    this._filtersCritical = this.buildFiltersCritical();
    this._filtersFwos = this.buildFiltersFwos();
  }

  get perPage() {
    return this._perPage;
  }

  get admin() {
    return this._spackle;
  }

  get showMyProjects() {
    return this._showMyProjects;
  }

  get filtersGeneral() {
    return this._filtersGeneral;
  }

  get filters() {
    return this._filters;
  }

  get filtersDrs() {
    return this._filtersDrs;
  }

  get filtersBidsDue() {
    return this._filtersBidsDue;
  }

  get filtersQuotesBidsDue() {
    return this._filtersQuotesBidsDue;
  }

  get filtersCritical() {
    return this._filtersCritical;
  }

  get filtersFwos() {
    return this._filtersFwos;
  }

  buildFiltersGeneral() {
    const filters = { per_page: this.perPage };
    if (this.showMyProjects) filters.my_projects = true;
    else if (!this.admin) filters.assigned_to_me = true;

    return filters;
  }

  buildFilters() {
    return { ...this.filtersGeneral, past_due: moment().format('YYYY-MM-DD') };
  }

  buildFiltersDrs() {
    return {
      ...this.filtersGeneral,
      missing_drs: moment().subtract(3, 'days').format('YYYY-MM-DD'),
    };
  }

  buildFiltersBidsDue() {
    return { per_page: this.perPage, bids_due: moment().format('YYYY-MM-DD') };
  }

  buildFiltersQuotesBidsDue() {
    return { per_page: this.perPage, agg: true, waiting: true };
  }

  buildFiltersCritical() {
    return { ...this.filtersGeneral, unresolved: true, critical: true };
  }

  buildFiltersFwos() {
    return { ...this.filtersGeneral, pending: true };
  }
}
