import React, { Component } from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import './ProjectLocationDetails.css';
import {
  getCurrentProjectLocation,
  getCurrentPage,
  getProjects,
} from 'selectors/admin/project-locations/project-locations';
import {
  editProjectLocation,
  loadProjectLocationDetailsRequest,
  loadProjectsRequest,
} from 'actions/admin/project-locations/project-locations';
import DetailsHeader from 'components/shared/details/DetailsHeader';
import DetailsInfoItem from 'components/shared/details/DetailsInfoItem';
import { simpleScrollToTop } from 'services/utils/scroller-util';
import ProjectLocationPermissions from 'permissions/project-location-permissions';
import { getCurrentUser } from 'selectors/authentication';
import ProjectLocationDetailsProjects from 'components/admin/project-locations/ProjectLocationDetailsProjects';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import DetailsTitle from 'components/shared/details/DetailsTitle';

class ProjectLocationDetails extends Component {
  constructor(props) {
    super(props);

    // Keep track of id to avoid showing an "old" location while loading the current one
    this.projectLocationId = numeral(this.props.match.params.id).value();
    this.canEdit = ProjectLocationPermissions.canEdit(props.currentUser);
    this.currentNav = 1;
  }

  componentDidMount() {
    if (
      this.props.currentProjectLocation === null ||
      this.props.currentProjectLocation.id !== this.projectLocationId
    ) {
      this.props.dispatch(
        loadProjectLocationDetailsRequest(this.projectLocationId)
      );
    }
  }

  handleEdit = () => {
    simpleScrollToTop();
    this.props.dispatch(
      editProjectLocation(this.props.currentProjectLocation.id)
    );
  };

  handleNavSelect = (value) => {
    this.currentNav = value;
    this.handleChange(1);
  };

  handlePageChange = (newPage) => {
    this.handleChange(newPage);
  };

  handleChange(page) {
    switch (this.currentNav) {
      case 1:
        this.props.dispatch(
          loadProjectsRequest(this.props.currentProjectLocation.id, page)
        );
        break;

      default:
    }
  }

  render() {
    const { currentProjectLocation } = this.props;
    return (
      <div className="project-location-details">
        {currentProjectLocation !== null &&
          currentProjectLocation.id === this.projectLocationId && (
            <React.Fragment>
              <DetailsHeader
                leftHeader={{
                  className: 'header-field',
                  title: 'PROJECT LOCATION',
                  value:
                    currentProjectLocation.name !== null &&
                    currentProjectLocation.name !== ''
                      ? currentProjectLocation.name
                      : currentProjectLocation.location.street_address_1,
                }}
                rightHeaders={[
                  [
                    {
                      title: 'IN PROGRESS',
                      value: currentProjectLocation.projects_in_progress_count,
                    },
                    {
                      title: 'COMPLETED',
                      value: currentProjectLocation.projects_complete_count,
                    },
                  ],
                ].filter((component) => component)}
              />
              <div className="content-container">
                <DetailsTitle
                  title="Location Information"
                  rightButtons={
                    this.canEdit && [
                      <Button
                        key="edit"
                        type={BUTTON_TYPES.LINK}
                        color={BUTTON_COLORS.GREEN}
                        label="Edit Info"
                        onClick={this.handleEdit}
                      />,
                    ]
                  }
                />

                <div className="information-container">
                  <div className="information-items">
                    <DetailsInfoItem
                      key="address"
                      lines={[
                        currentProjectLocation.location.street_address_1,
                        `${currentProjectLocation.location.city}, ${currentProjectLocation.location.state} ${currentProjectLocation.location.zip}`,
                      ]}
                      title="ADDRESS"
                    />
                  </div>
                </div>

                <DetailsTitle title="Projects" />
                <ProjectLocationDetailsProjects />
              </div>
            </React.Fragment>
          )}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentProjectLocation: getCurrentProjectLocation(state),
    currentPage: getCurrentPage(state),
    currentUser: getCurrentUser(state),
    prjects: getProjects(state),
  };
})(ProjectLocationDetails);
