import React from 'react';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import { LinearProgress } from '@mui/material';
import doneSvg from 'images/done.svg';
import doneDarkModeSvg from 'images/doneDarkMode.svg';

const DocumentUploadPreview = ({
  name = '',
  status,
  percentage,
  showCompleteIcon = false,
  darkMode,
}) => {
  return (
    <div className="file-upload-preview">
      {name.length > 0 ? (
        <span>
          <b>{name} </b>({status})
        </span>
      ) : (
        <span>{status}</span>
      )}
      <LinearProgress
        value={percentage}
        variant="determinate"
        className={`preview-progress-bar ${darkMode ? 'dark-mode' : ''}`}
      />
      {showCompleteIcon && percentage >= 100 ? (
        <img
          className="complete-icon"
          alt="done check"
          src={darkMode ? doneDarkModeSvg : doneSvg}
        />
      ) : (
        <span className="percentage">{percentage}%</span>
      )}
    </div>
  );
};

export default connect((state) => ({
  darkMode: getDarkMode(state),
}))(DocumentUploadPreview);
