import { createSelector } from 'reselect';

export const getFormNotificationSettings = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.notificationSettings.data.form,
  (form) => form
);

export const getSubjectsNotificationSettings = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.notificationSettings.data.subjects,
  (subjects) => subjects
);
