import { createSelector } from 'reselect';

export const getProjectScheduleFilters = createSelector(
  (state) => state.projects.scenes.details.scenes.schedule.ui.filters,
  (filters) => filters
);

export const getProjectSchedules = createSelector(
  (state) => state.projects.scenes.details.scenes.schedule.data.schedules,
  (schedules) => schedules
);

export const getProjectSchedulesFormModal = createSelector(
  (state) => state.projects.scenes.details.scenes.schedule.ui.showFormModal,
  (show) => show
);

export const getProjectSchedulesFormTab = createSelector(
  (state) => state.projects.scenes.details.scenes.schedule.ui.formTab,
  (tab) => tab
);

export const getProjectSchedulesForm = createSelector(
  (state) => state.projects.scenes.details.scenes.schedule.data.form,
  (form) => form
);

export const getProjectSchedulesModalMessage = createSelector(
  (state) => state.projects.scenes.details.scenes.schedule.ui.message,
  (message) => message
);

export const getProjectSchedulesModalMessageType = createSelector(
  (state) => state.projects.scenes.details.scenes.schedule.ui.messageType,
  (type) => type
);

export const getProjectSchedulesTypeEmailModal = createSelector(
  (state) => state.projects.scenes.details.scenes.schedule.ui.typeEmailModal,
  (type) => type
);

export const getProjectSchedulesCurrentCategory = createSelector(
  (state) => state.projects.scenes.details.scenes.schedule.data.category,
  (category) => category
);
export const getProjectSchedulesPdfModal = createSelector(
  (state) => state.projects.scenes.details.scenes.schedule.ui.pdf,
  (pdf) => pdf
);
export const getProjectSchedulesPdfTypeModal = createSelector(
  (state) => state.projects.scenes.details.scenes.schedule.ui.pdfType,
  (type) => type
);

export const getProjectSchedulesResolvedModal = createSelector(
  (state) => state.projects.scenes.details.scenes.schedule.ui.showResolvedModal,
  (show) => show
);
