import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Date from 'components/shared/text/Date';
import { nextDay } from 'services/utils/date-util';
import { formatPhoneNumber } from 'services/utils/text-util';
import moment from 'moment';
import { SlideDown } from 'react-slidedown';
import MeetingInfoForm from 'components/admin/projects/details/project-meeting-minutes/MeetingInfoForm';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import DetailsTitle from 'components/shared/details/DetailsTitle';

class MeetingInfo extends Component {
  static propTypes = {
    meetingInfoEdit: PropTypes.bool.isRequired,
    initialMeetingInfoValues: PropTypes.shape({}),
    meetingInfo: PropTypes.shape({}),
    showActions: PropTypes.bool.isRequired,
    handleSubmitMeetingInfo: PropTypes.func.isRequired,
    handleCancelMeetingInfo: PropTypes.func.isRequired,
    handleEditMeetingInfo: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="meeting-info">
        <DetailsTitle
          title="Meeting Information"
          rightButtons={[
            !this.props.meetingInfoEdit && this.props.showActions && (
              <Button
                key="edit-meeting"
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Edit Meeting"
                onClick={this.props.handleEditMeetingInfo}
              />
            ),
          ].filter((button) => button)}
        />
        {this.props.meetingInfoEdit ? (
          <SlideDown>
            <MeetingInfoForm
              initialValues={this.props.initialMeetingInfoValues}
              onSubmit={this.props.handleSubmitMeetingInfo}
              onCancel={this.props.handleCancelMeetingInfo}
            />
          </SlideDown>
        ) : (
          <div className="meeting-info-read-only">
            <Row>
              <Col sm={2} className="meeting-info-label small-bold">
                Weekly Meeting:
              </Col>
              <Col sm={8} className="meeting-info-value small-regular">
                {this.props.meetingInfo ? (
                  <div>
                    <Date
                      value={nextDay(this.props.meetingInfo.day_of_week)}
                      format="ll"
                    />
                    &nbsp;-&nbsp;
                    {moment
                      .utc(this.props.meetingInfo.starts_at)
                      .format('hh:mm A')}
                    <span> To </span>
                    {moment
                      .utc(this.props.meetingInfo.ends_at)
                      .format('hh:mm A')}
                    <span>, </span>
                    {this.props.meetingInfo.time_zone}
                  </div>
                ) : (
                  'Add Month Day, Year – Time'
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={2} className="meeting-info-label small-bold">
                Meeting Location:
              </Col>
              <Col sm={8} className="meeting-info-value small-regular">
                {this.props.meetingInfo &&
                this.props.meetingInfo.meeting_location ? (
                  <div>{this.props.meetingInfo.meeting_location}</div>
                ) : (
                  'Add Location'
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={2} className="meeting-info-label small-bold">
                Site Walk:
              </Col>
              <Col sm={8} className="meeting-info-value small-regular">
                {this.props.meetingInfo && this.props.meetingInfo.site_walk ? (
                  <div>{this.props.meetingInfo.site_walk}</div>
                ) : (
                  'Add Location'
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={2} className="meeting-info-label small-bold">
                Call In Info:
              </Col>
              <Col sm={8} className="meeting-info-value small-regular">
                {this.props.meetingInfo &&
                this.props.meetingInfo.phone_number ? (
                  <div>
                    {formatPhoneNumber(this.props.meetingInfo.phone_number)}
                    {this.props.meetingInfo.code && (
                      <span> / Code: {this.props.meetingInfo.code}</span>
                    )}
                    {this.props.meetingInfo.host_pin && (
                      <span>
                        {' '}
                        / Host PIN: {this.props.meetingInfo.host_pin}
                      </span>
                    )}
                  </div>
                ) : (
                  'Add Phone # / Code / Host Pin'
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={2} className="meeting-info-label small-bold">
                Web Link:
              </Col>
              <Col sm={8} className="meeting-info-value small-regular">
                {this.props.meetingInfo && this.props.meetingInfo.web_link ? (
                  <a
                    href={`${this.props.meetingInfo.web_link}`}
                    className="external-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.props.meetingInfo.link_title ||
                      this.props.meetingInfo.web_link}
                  </a>
                ) : (
                  'Add Web Link'
                )}
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default connect()(MeetingInfo);
