// data
export const SET_SECURITY_CAMERA_CONFIGURATION =
  'SET_SECURITY_CAMERA_CONFIGURATION';

// ui
export const SET_SECURITY_CAMERA_CONFIGURATION_FORM =
  'SET_SECURITY_CAMERA_CONFIGURATION_FORM';
export const SET_SECURITY_CAMERA_CONFIGURATION_FORM_VALUE =
  'SET_SECURITY_CAMERA_CONFIGURATION_FORM_VALUE';

export const SET_SECURITY_CAMERA_CAMERAS_FORM =
  'SET_SECURITY_CAMERA_CAMERAS_FORM';
export const SET_SECURITY_CAMERA_CAMERAS_FORM_VALUE =
  'SET_SECURITY_CAMERA_CAMERAS_FORM_VALUE';

export const SET_SECURITY_CAMERA_PERMISSIONS_FORM =
  'SET_SECURITY_CAMERA_PERMISSIONS_FORM';
export const SET_SECURITY_CAMERA_PERMISSIONS_FORM_VALUE =
  'SET_SECURITY_CAMERA_PERMISSIONS_FORM_VALUE';
