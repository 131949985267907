export const PROJECT_NEW_MEETINGS_INIT = 'PROJECT_NEW_MEETINGS_INIT';
export const PROJECT_NEW_MEETINGS_SET_FILTERS =
  'PROJECT_NEW_MEETINGS_SET_FILTERS';
export const GET_MEETING_TYPES = 'GET_MEETING_TYPES';
export const GET_MEETING_TITLES = 'GET_MEETING_TITLES';
export const GET_MEETING_TYPES_ERROR = 'GET_MEETING_TYPES_ERROR';
export const GET_MEETING_TITLES_ERROR = 'GET_MEETING_TITLES_ERROR';
export const GET_MEETING_TYPES_SUCCESS = 'GET_MEETING_TYPES_SUCCESS';
export const GET_MEETING_TITLES_SUCCESS = 'GET_MEETING_TITLES_SUCCESS';
export const PROJECT_NEW_MEETINGS_SET = 'PROJECT_NEW_MEETINGS_SET';
export const PROJECT_NEW_MEETINGS_EDIT = 'PROJECT_NEW_MEETINGS_EDIT_CATEGORY';
export const PROJECT_NEW_MEETINGS_REMOVE =
  'PROJECT_NEW_MEETINGS_REMOVE_CATEGORY';
export const PROJECT_NEW_MEETINGS_ADD_NEW_MEETING =
  'PROJECT_NEW_MEETINGS_ADD_NEW_MEETING';
export const PROJECT_NEW_MEETINGS_SET_FORM = 'PROJECT_NEW_MEETINGS_SET_FORM';

export const PROJECT_NEW_MEETINGS_SET_MESSAGE =
  'PROJECT_NEW_MEETINGS_SET_MESSAGE';
export const PROJECT_NEW_MEETINGS_SET_FORM_MODAL =
  'PROJECT_NEW_MEETINGS_SET_FORM_MODAL';
export const DELETE_TYPE_ERROR_MODAL_TOGGLE = 'DELETE_TYPE_ERROR_MODAL_TOGGLE';
export const PROJECT_NEW_MEETINGS_SET_FORM_TAB =
  'PROJECT_NEW_MEETINGS_SET_FORM_TAB';
export const SET_MEETINGS_INSTANCE_FORM_TAB = 'SET_MEETINGS_INSTANCE_FORM_TAB';
export const GET_MEETINGS_PARENTS_START = 'GET_MEETINGS_PARENTS_START';
export const GET_MEETINGS_PARENTS_SUCCESS = 'GET_MEETINGS_PARENTS_SUCCESS';
export const GET_MEETINGS_PARENTS_ERROR = 'PROJECT_NEW_MEETINGS_PARENTS_ERROR';
export const CREATE_MEETINGS_PARENT_SUCCESS = 'CREATE_MEETINGS_PARENT_SUCCESS';
export const CREATE_MEETINGS_PARENT_ERROR = 'CREATE_MEETINGS_PARENT_ERROR';
export const GET_ACTION_ITEMS_CATEGORIES = 'GET_ACTION_ITEMS_CATEGORIES';
export const GET_ACTION_ITEMS_CATEGORIES_SUCCESS =
  'GET_ACTION_ITEMS_CATEGORIES_SUCCESS';
export const GET_ACTION_ITEMS_CATEGORIES_ERROR =
  'GET_ACTION_ITEMS_CATEGORIES_ERROR';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_CATEGORY_ERROR = 'CREATE_CATEGORY_ERROR';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_TITLE = 'CREATE_TITLE';
export const CREATE_TITLE_ERROR = 'CREATE_TITLE_ERROR';
export const CREATE_TITLE_SUCCESS = 'CREATE_TITLE_SUCCESS';
export const CREATE_TYPE = 'CREATE_TYPE';
export const CREATE_TYPE_ERROR = 'CREATE_TYPE_ERROR';
export const CREATE_TYPE_SUCCESS = 'CREATE_TYPE_SUCCESS';
export const UPDATE_TITLE = 'UPDATE_TITLE';
export const UPDATE_TITLE_ERROR = 'UPDATE_TITLE_ERROR';
export const UPDATE_TITLE_SUCCESS = 'UPDATE_TITLE_SUCCESS';
export const UPDATE_TYPE = 'UPDATE_TYPE';
export const UPDATE_TYPE_ERROR = 'UPDATE_TYPE_ERROR';
export const UPDATE_TYPE_SUCCESS = 'UPDATE_TYPE_SUCCESS';
export const DELETE_TITLE = 'DELETE_TITLE';
export const DELETE_TITLE_ERROR = 'DELETE_TITLE_ERROR';
export const DELETE_TITLE_SUCCESS = 'DELETE_TITLE_SUCCESS';
export const DELETE_TYPE = 'DELETE_TYPE';
export const DELETE_TYPE_ERROR = 'DELETE_TYPE_ERROR';
export const DELETE_TYPE_SUCCESS = 'DELETE_TYPE_SUCCESS';
export const PROJECT_NEW_MEETINGS_SET_ADD_TYPE_MODAL =
  'PROJECT_NEW_MEETINGS_SET_ADD_TYPE_MODAL';
export const PROJECT_NEW_MEETINGS_SET_ADD_TITLE_MODAL =
  'PROJECT_NEW_MEETINGS_SET_ADD_TITLE_MODAL';
export const PROJECT_NEW_MEETINGS_SET_EDIT_TITLE_MODAL =
  'PROJECT_NEW_MEETINGS_SET_EDIT_TITLE_MODAL';
export const PROJECT_NEW_MEETINGS_SET_EDIT_TYPE_MODAL =
  'PROJECT_NEW_MEETINGS_SET_EDIT_TYPE_MODAL';
export const PROJECT_NEW_MEETINGS_SET_INFO_MODAL =
  'PROJECT_NEW_MEETINGS_SET_INFO_MODAL';
export const SET_SCHEDULE_MODAL = 'SET_INFO_MODAL';
export const PROJECT_NEW_MEETINGS_SET_INFO_TAB =
  'PROJECT_NEW_MEETINGS_SET_INFO_TAB';
export const UPDATE_MEETINGS_PARENT_SUCCESS = 'UPDATE_MEETINGS_PARENT_SUCCESS';
export const UPDATE_MEETINGS_PARENT_ERROR = 'UPDATE_MEETINGS_PARENT_ERROR';
export const SET_MEETINGS_TABLE_TAB = 'SET_MEETINGS_TABLE_TAB';
export const SET_MEETINGS_INSTANCE_FORM = 'SET_MEETINGS_INSTANCE_FORM';
export const SET_MEETINGS_INSTANCE_AGENDA_FORM =
  'SET_MEETINGS_INSTANCE_AGENDA_FORM';
export const SET_MEETINGS_INSTANCE_MINUTES_FORM =
  'SET_MEETINGS_INSTANCE_MINUTES_FORM';
export const CREATE_MEETINGS_INSTANCE_SUCCESS =
  'CREATE_MEETINGS_INSTANCE_SUCCESS';
export const CREATE_MEETINGS_INSTANCE_ERROR = 'CREATE_MEETINGS_INSTANCE_ERROR';
export const UPDATE_MEETINGS_INSTANCE_SUCCESS =
  'UPDATE_MEETINGS_INSTANCE_SUCCESS';
export const UPDATE_MEETINGS_INSTANCE_ERROR = 'UPDATE_MEETINGS_INSTANCE_ERROR';
export const MEETINGS_INSTANCE_SET = 'MEETINGS_INSTANCE_SET';
export const MEETINGS_INSTANCE_SET_FORM = 'MEETINGS_INSTANCE_SET_FORM';
export const SET_MEETINGS_INSTANCE_FORM_SHOW =
  'SET_MEETINGS_INSTANCE_FORM_SHOW';
export const ADD_NEW_MEETING_MODAL_SHOW = 'ADD_NEW_MEETING_MODAL_SHOW';
export const EDIT_NEXT_MEETING_MODAL_SHOW = 'EDIT_NEXT_MEETING_MODAL_SHOW';
export const ADD_CATEGORY_MODAL_SHOW = 'ADD_CATEGORY_MODAL_SHOW';
export const ADD_GUEST_MODAL_SHOW = 'ADD_GUEST_MODAL_SHOW';
export const ACTION_ITEMS_RESOLVED_MODAL_SHOW =
  'ACTION_ITEMS_RESOLVED_MODAL_SHOW';
export const CREATE_NON_DIRECTORY_ATTENDEE = 'CREATE_NON_DIRECTORY_ATTENDEE';
export const CREATE_NON_DIRECTORY_ATTENDEE_ERROR =
  'CREATE_NON_DIRECTORY_ATTENDEE_ERROR';
export const CREATE_NON_DIRECTORY_ATTENDEE_SUCCESS =
  'CREATE_NON_DIRECTORY_ATTENDEE_SUCCESS';
export const GET_MEETINGS_INSTANCE_ERROR = 'GET_MEETINGS_INSTANCE_ERROR';
export const GET_MEETINGS_INSTANCE_SUCCESS = 'GET_MEETINGS_INSTANCE_SUCCESS';
export const CREATE_ACTION_ITEM = 'CREATE_ACTION_ITEM';
export const CREATE_ACTION_ITEM_SUCCESS = 'CREATE_ACTION_ITEM_SUCCESS';
export const CREATE_ACTION_ITEM_ERROR = 'CREATE_ACTION_ITEM_ERROR';
export const UPDATE_ACTION_ITEM = 'UPDATE_ACTION_ITEM';
export const UPDATE_ACTION_ITEM_SUCCESS = 'UPDATE_ACTION_ITEM_SUCCESS';
export const UPDATE_ACTION_ITEM_ERROR = 'UPDATE_ACTION_ITEM_ERROR';
export const DELETE_ACTION_ITEM = 'DELETE_ACTION_ITEM';
export const DELETE_ACTION_ITEM_SUCCESS = 'DELETE_ACTION_ITEM_SUCCESS';
export const DELETE_ACTION_ITEM_ERROR = 'DELETE_ACTION_ITEM_ERROR';
export const SET_INSTANCE_ACTION_ITEMS = 'SET_INSTANCE_ACTION_ITEMS';
export const DELETE_INSTANCE_ERROR = 'DELETE_INSTANCE_ERROR';
export const DELETE_INSTANCE_SUCCESS = 'DELETE_INSTANCE_SUCCESS';
export const CREATE_ACTION_ITEM_UPDATE = 'CREATE_ACTION_ITEM_UPDATE';
export const CREATE_ACTION_ITEM_UPDATE_SUCCESS =
  'CREATE_ACTION_ITEM_UPDATE_SUCCESS';
export const CREATE_ACTION_ITEM_UPDATE_ERROR =
  'CREATE_ACTION_ITEM_UPDATE_ERROR';
export const UPDATE_ACTION_ITEM_UPDATE = 'UPDATE_ACTION_ITEM_UPDATE';
export const UPDATE_ACTION_ITEM_UPDATE_SUCCESS =
  'UPDATE_ACTION_ITEM_UPDATE_SUCCESS';
export const UPDATE_ACTION_ITEM_UPDATE_ERROR =
  'UPDATE_ACTION_ITEM_UPDATE_ERROR';
export const ACTION_ITEMS_HISTORY_MODAL_SHOW =
  'ACTION_ITEMS_HISTORY_MODAL_SHOW';
export const ACTION_ITEMS_HISTORY_SET_FILTERS =
  'ACTION_ITEMS_HISTORY_SET_FILTERS';
export const ACTION_ITEMS_RESOLVED_SET_FILTERS =
  'ACTION_ITEMS_RESOLVED_SET_FILTERS';
export const SET_NOTEPAD_SHOW = 'SET_NOTEPAD_SHOW';
export const SET_SUBMIT_CORRECTIONS_MODAL_SHOW =
  'SET_SUBMIT_CORRECTIONS_MODAL_SHOW';
export const SET_REVIEW_CORRECTIONS_MODAL_SHOW =
  'SET_REVIEW_CORRECTIONS_MODAL_SHOW';
export const SET_EDIT_CORRECTIONS_MODAL_SHOW =
  'SET_EDIT_CORRECTIONS_MODAL_SHOW';
export const SET_VIEW_CORRECTIONS_MODAL_SHOW =
  'SET_VIEW_CORRECTIONS_MODAL_SHOW';
export const SET_PENDING_CORRECTIONS_ERROR_MODAL_SHOW =
  'SET_PENDING_CORRECTIONS_ERROR_MODAL_SHOW';
export const CREATE_CORRECTION_ERROR = 'CREATE_CORRECTION_ERROR';
export const CREATE_CORRECTION_SUCCESS = 'CREATE_CORRECTION_ERROR';
export const MEETINGS_PARENTS_COUNT = 'MEETINGS_PARENTS_COUNT';
export const SET_INSTANCE_INFO_ERRORS = 'SET_INSTANCE_INFO_ERRORS';
export const SET_ACTION_ITEMS_ERRORS = 'SET_ACTION_ITEMS_ERRORS';
export const SET_MEETINGS_INSTANCE_PDF_VIEWER =
  'SET_MEETINGS_INSTANCE_PDF_VIEWER';
export const GET_MEETINGS_PARENT_SUCCESS = 'GET_MEETINGS_PARENT_SUCCESS';
export const GET_MEETINGS_PARENT_ERROR = 'GET_MEETINGS_PARENT_ERROR';
export const GET_MEETINGS_INSTANCE_AGENDA_BACKUP_SUCCESS =
  'GET_MEETINGS_INSTANCE_AGENDA_BACKUP_SUCCESS';
export const SET_MEETINGS_INSTANCE_FORM_IS_BACKUP =
  'SET_MEETINGS_INSTANCE_FORM_IS_BACKUP';
export const SET_CONFIRM_CLOSE_MODAL_SHOW = 'SET_CONFIRM_CLOSE_MODAL_SHOW';
export const SET_PDF_WAITING_PREVIEW = 'SET_PDF_WAITING_PREVIEW';
export const PROJECT_NEW_MEETINGS_SET_INSTACES_FILTERS =
  'PROJECT_NEW_MEETINGS_SET_INSTACES_FILTERS';
export const SET_MEETINGS_INSTANCE_HTML = 'SET_MEETINGS_INSTANCE_HTML';
export const SET_MEETINGS_INSTANCE_HTML_VIEWER =
  'SET_MEETINGS_INSTANCE_HTML_VIEWER';
