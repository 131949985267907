import {
  RFA_DELETE_CONFIRMATION_SET,
  RFA_TAB_NAVIGATION_SET,
  RFA_INIT,
  RFAS_SET,
  RFA_SET,
  RFA_LOG_PDF_SET,
  RFA_DELETE,
  RFA_SHOW_MODAL_SET,
  RFA_SHOW_CREATE_MODAL_SET,
  RFA_NOTES_SET,
  RFA_NOTE_ADD,
  RFA_READONLY_SET,
  RFA_FORM_SET,
  RFA_FORM_RESET,
  RFA_DATA_CHANGED_SET,
  RFA_FILTERS_SHOW_SET,
} from './action-types';
import { showErrorMessage } from 'actions/messages';
import { rfasApi } from 'services/api/rfas-api.js';
import moment from 'moment';
import RfaPermissions from 'permissions/rfa-permissions';

export function initRfaLog() {
  return { type: RFA_INIT };
}

export function setRfaTabNavigation(tab) {
  return {
    type: RFA_TAB_NAVIGATION_SET,
    payload: tab,
  };
}

export function setRfaLogPdf(logPdf, showLogPdf, logPdfType = 'url') {
  return {
    type: RFA_LOG_PDF_SET,
    payload: {
      logPdf,
      logPdfType,
      showLogPdf,
    },
  };
}

export function setCurrentRfa(rfa) {
  return {
    type: RFA_SET,
    payload: { currentRfa: rfa },
  };
}

export function setShowDeleteConfirmation(show) {
  return {
    type: RFA_DELETE_CONFIRMATION_SET,
    payload: { showDeleteConfirmation: show },
  };
}

export function deleteRfaSuccess(id) {
  return {
    type: RFA_DELETE,
    payload: { rfaId: id },
  };
}

export function loadRfasSuccess(rfasData, filters) {
  return {
    type: RFAS_SET,
    payload: {
      ...rfasData,
      filters: { ...filters },
    },
  };
}

export function setShowRfaModal(show) {
  return {
    type: RFA_SHOW_MODAL_SET,
    payload: { show },
  };
}

export function setShowRfaFilters(show) {
  return {
    type: RFA_FILTERS_SHOW_SET,
    payload: { show },
  };
}

export function setRfaFormValues(values) {
  return {
    type: RFA_FORM_SET,
    payload: { values },
  };
}

export function resetRfaForm(rfa = null) {
  let values = {};
  if (rfa) values = rfaToFormRfa(rfa);
  return { type: RFA_FORM_RESET, payload: { values } };
}

export function setShowCreateModal(show, revisionId) {
  return {
    type: RFA_SHOW_CREATE_MODAL_SET,
    payload: { show, revisionId },
  };
}

export function setRfaNotes(notes) {
  return {
    type: RFA_NOTES_SET,
    payload: notes,
  };
}

export function addRfaNote(note) {
  return {
    type: RFA_NOTE_ADD,
    payload: note,
  };
}

export function setRfaReadOnly(readOnly) {
  return {
    type: RFA_READONLY_SET,
    payload: { readOnly },
  };
}

export function setRfaDataChanged(changed) {
  return {
    type: RFA_DATA_CHANGED_SET,
    payload: changed,
  };
}

const rfaFileToForm = (rfa, section, type) => {
  const key = `${section}_${type}s`;
  return {
    [key]: (rfa[key] || []).map((file) => ({
      id: file.id,
      url: file[type].url,
      preview: file[type].gallery && file[type].gallery.url,
    })),
  };
};

const rfaFilesToForm = (rfa, section) => ({
  ...rfaFileToForm(rfa, section, 'document'),
  ...rfaFileToForm(rfa, section, 'image'),
});

const rfaDetailsToForm = (rfa) => {
  const {
    status,
    formatted_status,
    request_type,
    request_title,
    request_description,
    due_date,
    csi_code,
    created_by,
    submitted_by,
    submitted_at,
  } = rfa;

  return {
    status,
    formatted_status,
    request_type,
    request_title: request_title || '',
    request_description: request_description || '',
    due_date,
    csi_code_id: csi_code ? csi_code.id : null,
    csi_code_label: csi_code ? csi_code.division : '00',
    submitted_by: submitted_by
      ? { label: submitted_by.label, value: submitted_by.id }
      : created_by
      ? { label: created_by.label, value: created_by.id }
      : null,
    submitted_at: submitted_at,
    ...rfaFilesToForm(rfa, 'request'),
  };
};

const rfaResponseToForm = (rfa) => {
  const {
    response_code,
    formatted_response_code,
    response_description,
    responded_by,
    responded_at,
    accelerated_by,
    accelerated_at,
    response_method,
    formatted_response_method,
    response_method_description,
  } = rfa;

  return {
    response_code: response_code !== 'not_responded' ? response_code : null,
    formatted_response_code:
      response_code !== 'not_responded' ? formatted_response_code : null,
    response_description: response_description || '',
    responded_by: responded_by
      ? { label: responded_by.label, value: responded_by.id }
      : null,
    responded_at: responded_at,
    responded_at_date: responded_at
      ? moment(responded_at).format('YYYY-MM-DD HH:mm')
      : null,
    responded_at_time: responded_at
      ? moment(responded_at).format('YYYY-MM-DD HH:mm')
      : null,
    accelerated_by: accelerated_by
      ? { label: accelerated_by.label, value: accelerated_by.id }
      : null,
    accelerated_at: accelerated_at,
    response_method:
      response_method !== 'standard_response' ? response_method : null,
    formatted_response_method:
      response_method !== 'standard_response'
        ? formatted_response_method
        : null,
    response_method_description: response_method_description || '',
    ...rfaFilesToForm(rfa, 'response'),
  };
};

const rfaReviewToForm = (rfa) => {
  const { review_description, reviewed_by, reviewed_at } = rfa;

  return {
    review_description: review_description || '',
    reviewed_by: reviewed_by
      ? {
          label: reviewed_by.label,
          value: reviewed_by.id,
          full_name: reviewed_by.full_name,
        }
      : null,
    reviewed_at: reviewed_at,
    ...rfaFilesToForm(rfa, 'review'),
  };
};

const rfaAssignedToForm = ({ assigned_to, ccs, addl_recipients }) => ({
  assigned_to: assigned_to
    ? { label: assigned_to.label, value: assigned_to.id }
    : null,
  ccs: ccs.map((cc) => [cc.id, cc.label] || []),
  addl_recipients: addl_recipients.map((cc) => [cc.id, cc.label] || []),
});

export function rfaToFormRfa(rfa, revision_status = null) {
  return {
    status: revision_status || rfa.status,
    [revision_status ? 'revised_rfa_id' : 'id']: rfa.id,
    ...rfaDetailsToForm(rfa),
    ...rfaResponseToForm(rfa),
    ...rfaReviewToForm(rfa),
    ...rfaAssignedToForm(rfa),
  };
}

export function setNewRfa(status) {
  return (dispatch) => {
    dispatch(setRfaFormValues({ status: status }));
    dispatch(setShowCreateModal(false));
    dispatch(setShowRfaModal(true));
    dispatch(setRfaReadOnly(false));
  };
}

export function openRfa(projectId, rfaId, readOnly = false) {
  return (dispatch) => {
    dispatch(loadRfaRequest(projectId, rfaId)).then((response) => {
      if (!response.ok) return;

      dispatch(setCurrentRfa(response.rfa));
      dispatch(setRfaFormValues(rfaToFormRfa(response.rfa)));
      dispatch(setRfaReadOnly(readOnly));
      dispatch(setShowRfaModal(true));
    });
  };
}

export function setNewRfaRevision(projectId, revisedRfaId, status) {
  return (dispatch) => {
    dispatch(loadRfaRequest(projectId, revisedRfaId)).then((response) => {
      if (!response.ok) return;

      response.rfa.status = status;
      dispatch(setShowCreateModal(false));
      dispatch(setCurrentRfa(null));
      dispatch(setRfaFormValues(rfaToFormRfa(response.rfa, true)));
      dispatch(setRfaReadOnly(false));
      dispatch(setShowRfaModal(true));
    });
  };
}

export function loadRfaRequest(projectId, rfaId) {
  return (dispatch) => {
    return rfasApi.getRfa(projectId, rfaId).then((response) => {
      if (!response.ok) dispatch(showErrorMessage(response.errors));

      return response;
    });
  };
}

export function loadRfasRequest(projectId, filters) {
  return (dispatch) => {
    return rfasApi.getProjectRfas(projectId, filters).then((response) => {
      dispatch(setRfaDataChanged(false));
      if (response.ok) {
        dispatch(loadRfasSuccess(response, filters));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function loadRfasLogPdfRequest(projectId, pre) {
  return (dispatch) => {
    return rfasApi.getRfasLogPdf(projectId, pre).then((response) => {
      if (response.ok) {
        dispatch(setRfaLogPdf(response.rfaLogPdf, true, 'blob'));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function deleteRfaRequest(projectId, rfaId) {
  return (dispatch) => {
    return rfasApi.deleteRfa(projectId, rfaId).then((response) => {
      if (response.ok) {
        dispatch(deleteRfaSuccess(rfaId));
        dispatch(setShowDeleteConfirmation(false));
        dispatch(closeRfa());
        dispatch(setRfaDataChanged(true));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
  };
}

export function closeRfa() {
  return (dispatch) => {
    dispatch(resetRfaForm());
    dispatch(setCurrentRfa(null));
    dispatch(setRfaNotes([]));
    dispatch(setShowRfaModal(false));
  };
}

export function saveRfa(projectId, values, action = 'save') {
  return (dispatch) => {
    return rfasApi.saveRfa(projectId, values, action).then((response) => {
      if (response.ok) {
        dispatch(closeRfa());
        dispatch(setRfaDataChanged(true));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
  };
}

export function loadRfaNotes(rfaId) {
  return (dispatch) => {
    return rfasApi.getNotes(rfaId).then((response) => {
      if (response.ok) {
        dispatch(setRfaNotes(response.notes));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
  };
}

export function createRfaNote(rfaId, content) {
  return (dispatch) => {
    return rfasApi.createNote(rfaId, content).then((response) => {
      if (response.ok) {
        dispatch(addRfaNote(response.note));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
  };
}

export function markRfaNotesRead(rfaId) {
  return (dispatch) => {
    return rfasApi.markNotesRead(rfaId).then((response) => {
      if (!response.ok) dispatch(showErrorMessage(response.errors));
      return response;
    });
  };
}

export function startNewRfa(currentUser, currentProject) {
  return (dispatch) => {
    dispatch(closeRfa());
    if (RfaPermissions.canSubmit(currentUser, currentProject)) {
      dispatch(setShowCreateModal(true));
    } else {
      dispatch(setNewRfa('draft_pending'));
    }
  };
}

export function startRfaRevision(currentUser, currentProject, revisedRfaId) {
  return (dispatch) => {
    dispatch(closeRfa());
    if (RfaPermissions.canSubmit(currentUser, currentProject)) {
      dispatch(setShowCreateModal(true, revisedRfaId));
    } else {
      dispatch(
        setNewRfaRevision(currentProject.id, revisedRfaId, 'draft_pending')
      );
    }
  };
}
