import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { default as MuiSwitch } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import classnames from 'classnames';
import { getDarkMode } from 'selectors/theme';
import { muiThemeColor } from 'styles/colors';
import './Switch.css';

const StyledSwitch = styled(({ darkMode, ...other }) => (
  <MuiSwitch {...other} />
))(({ darkMode }) => {
  const trackColor = muiThemeColor(
    'light-mode-clear-black',
    'dark-mode-grey-over-light-grey'
  )({ darkMode });
  const thumbColor = muiThemeColor('primary-green', 'neon')({ darkMode });
  return {
    width: 48,
    height: 24,
    padding: 0,
    '& .MuiSwitch-track': {
      borderRadius: 100,
      opacity: 1,
      backgroundColor: trackColor,
    },
    '& .MuiSwitch-thumb': {
      width: 16,
      height: 16,
      margin: 4,
      boxShadow: 'none',
      backgroundColor: thumbColor,
    },
    '& .MuiSwitch-switchBase': {
      color: trackColor,
      padding: 0,
      '&.Mui-checked': {
        color: thumbColor,
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: trackColor,
        },
      },
    },
    '& .Mui-checked': {
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: trackColor,
      },
    },
    '&.twocolors': {
      '& .MuiSwitch-thumb': {
        backgroundColor: '#ffffff',
      },
      '& .Mui-checked': {
        color: thumbColor,
        '& .MuiSwitch-thumb': {
          backgroundColor: thumbColor,
        },
      },
    },
  };
});

const Switch = (props) => {
  const {
    className,
    disabled,
    checkedLabel,
    unCheckedLabel,
    value,
    onChange,
    darkMode,
    variant,
  } = props;

  const handleOnChange = (event) => {
    if (!onChange) return;

    onChange(event.target.checked);
  };

  return (
    <div
      className={classnames('switch-control', {
        [className]: !!className,
        'dark-mode': darkMode,
      })}
    >
      {!!unCheckedLabel && (
        <label
          className={classnames('switch-control-label', { selected: !value })}
        >
          {unCheckedLabel}
        </label>
      )}
      <StyledSwitch
        checked={value}
        disabled={disabled}
        onChange={handleOnChange}
        darkMode={darkMode}
        className={variant}
        disableRipple
      />
      {!!checkedLabel && (
        <label
          className={classnames('switch-control-label', { selected: value })}
        >
          {checkedLabel}
        </label>
      )}
    </div>
  );
};

Switch.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  checkedLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  unCheckedLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  value: PropTypes.bool,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['onecolor', 'twocolors']),
};

Switch.defaultProps = {
  className: '',
  disabled: false,
  value: false,
  variant: 'onecolor',
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(Switch);
