import {
  WORK_ORDERS_DETAILS_PROPOSAL_SUCCESS_MODAL_OPEN,
  WORK_ORDERS_DETAILS_PROPOSAL_SUCCESS_MODAL_CLOSE,
} from 'actions/admin/work-orders/action-types';

const initialState = {
  showSuccessMessage: false,
};

export default function proposalApprovalUiReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case WORK_ORDERS_DETAILS_PROPOSAL_SUCCESS_MODAL_OPEN:
      return {
        ...state,
        showSuccessMessage: true,
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_SUCCESS_MODAL_CLOSE:
      return {
        ...state,
        showSuccessMessage: false,
      };

    default:
      return state;
  }
}
