import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './StampDetails.css';
import StampOptions from 'domain/stamp-options';
import RadioButton from 'components/shared/radio-button/RadioButton';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';

class StampDetails extends Component {
  static propTypes = {
    companyName: PropTypes.string,
    stamp: PropTypes.shape({}),
    value: PropTypes.string,
    error: PropTypes.bool,
    readOnly: PropTypes.bool,
    position: PropTypes.oneOf(['horizontal', 'vertical']),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    companyName: '',
    stamp: null,
    value: '',
    error: false,
    readOnly: true,
    position: 'vertical',
    onChange: null,
  };

  stampOptions = () => {
    const { stamp } = this.props;
    return stamp
      ? stamp.stamp_actions.map((stampAction) => {
          return {
            value: stampAction.action,
            label: (
              <span>
                <b>{stampAction.title}</b>
                {stampAction.subtitle && <div>{stampAction.subtitle}</div>}
              </span>
            ),
          };
        })
      : StampOptions;
  };

  handleChange = (value) => {
    const { onChange } = this.props;
    onChange && onChange(value);
  };

  render() {
    const {
      companyName,
      stamp,
      value,
      error,
      readOnly,
      position,
      darkMode,
    } = this.props;

    return (
      <div
        className={`stamp-details ${
          darkMode ? 'dark-mode' : ''
        } ${position}-stamp`}
      >
        <div className="stamp-details-block stamp-details-header">
          <div className="stamp-details-title">
            <b>SUBMITTAL REVIEW</b>
          </div>
        </div>
        <div className="stamp-body">
          <div className="stamp-details-block stamp-details-form">
            <RadioButton
              className={classnames({
                'stamp-details-action': true,
                'stamp-details-action-error': error,
              })}
              orientation="vertical"
              options={this.stampOptions()}
              value={value}
              readOnly={readOnly}
              onChange={this.handleChange}
            />
          </div>
          <div className="stamp-details-block stamp-details-legal">
            {stamp ? (
              stamp.legal
            ) : (
              <React.Fragment>
                <p>
                  {companyName || 'General Contractor'} has reviewed this
                  Submittal, but only for the purpose of checking for
                  conformance with the design intent expressed in the Contract
                  Documents. {companyName || 'General Contractor'}’s action on a
                  specific item does not indicate approval of an assembly of
                  which the item is a component, nor of an item as delivered and
                  installed if it does not conform to the Contract Documents.
                </p>
                <p>
                  Contractor is responsible for checking for deviations between
                  this Submittal and differing information or conditions in the
                  Contract Documents and field conditions; for determining or
                  substantiating the accuracy and completeness of other details
                  such as dimensions and quantities; for substantiating
                  instructions for installation or performance of equipment or
                  systems designed by Contractor; for construction means,
                  methods, techniques, schedules, sequences, procedures, and
                  fabrication processes; for errors and omissions in Submittals;
                  for coordination of the Work of the trades, safety precautions
                  and performing the Work in a safe and satisfactory manner and
                  in conformance with the Contract Documents.
                </p>
                <p>
                  If more than one submittal review stamp appears on this
                  Submittal, the most stringent action and notations thereon
                  apply. {companyName || 'General Contractor'} nor 360Plus and
                  its consultants, employees, contractors or agents have not
                  reviewed work not within their respective professional
                  discipline or scope of services.
                </p>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(StampDetails);
