export const RFA_INIT = 'RFA_INIT';
export const RFAS_SET = 'RFAS_SET';
export const RFA_SET = 'RFA_SET';
export const RFA_TAB_NAVIGATION_SET = 'RFA_TAB_NAVIGATION_SET';
export const RFA_LOG_PDF_SET = 'RFA_LOG_PDF_SET';
export const RFA_DELETE_CONFIRMATION_SET = 'RFA_DELETE_CONFIRMATION_SET';
export const RFA_DELETE = 'RFA_DELETE';
export const RFA_SHOW_MODAL_SET = 'RFA_SHOW_MODAL_SET';
export const RFA_SHOW_CREATE_MODAL_SET = 'RFA_SHOW_CREATE_MODAL_SET';
export const RFA_NOTES_SET = 'RFA_NOTES_SET';
export const RFA_NOTE_ADD = 'RFA_NOTE_ADD';
export const RFA_READONLY_SET = 'RFA_READONLY_SET';
export const RFA_FORM_SET = 'RFA_FORM_SET';
export const RFA_FORM_RESET = 'RFA_FORM_RESET';
export const RFA_DATA_CHANGED_SET = 'RFA_DATA_CHANGED_SET';
export const RFA_FILTERS_SHOW_SET = 'RFA_FILTERS_SHOW_SET';
