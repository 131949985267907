import React, { useState, useRef } from 'react';
import { useCounter, useMap } from 'react-use';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import {
  default as DropzoneUploader,
  defaultClassNames,
} from 'react-dropzone-uploader';
import { s3Api } from 'services/api/s3-api';
import uploadSvg from '../document-upload/upload.svg';
import uploadSvgDark from '../document-upload/uploadDarkMode.svg';
import DocumentUploadPreview from './DocumentUploadPreview';
function DirectS3Uploader(props) {
  const {
    acceptedTypes,
    model,
    modelIdentifier,
    apppendToState,
    updateStateUploadInProcess,
    dispatch,
    darkMode,
    fetchChangeStatus,
    fetchFileSelectedCount,
    subModel,
    hideFilePreview,
    showMultiplePreview,
    enableQueueing,
    queueAmount,
  } = props;
  const [fileCounter, setFileCounter] = useCounter(0); // total Uploaded
  const [counter, setCounter] = useCounter(0); // on queue && being processx

  const hiddenFileInput = useRef(null);
  const [filesUrls, setFilesUrls] = useMap({});
  const [selectedFileCount, setSelectedFileCount] = useState(0);
  const [uploadInProcess, setUploadInProcess] = useState(null);

  const getUrl = (signedUrl) => {
    return signedUrl.split('?')[0];
  };

  const getUploadParams = async ({ file, meta: { name } }) => {
    const params = {
      name,
      model,
      subModel,
      identifier: modelIdentifier,
      contentType: file.type,
    };

    let fileCount = selectedFileCount + 1;
    if (fetchFileSelectedCount) fetchFileSelectedCount(fileCount);
    setSelectedFileCount(fileCount);

    const response = await s3Api.getSignedUrl(params);
    const { data } = response;
    const { signedUrl } = data;

    setFilesUrls.set(name, getUrl(signedUrl));

    return {
      body: file,
      url: signedUrl,
      method: 'PUT',
      headers: { 'x-amz-acl': 'public-read' },
    };
  };

  const changeUploadInProcess = (inProcess, error = null) => {
    setUploadInProcess(inProcess);
    dispatch(updateStateUploadInProcess(inProcess, error));
  };

  const STATUS_ERRORS = {
    rejected_file_type: 'Invalid file type',
    rejected_max_files: 'Files count limit reached',
    error_file_size: 'File exceed size limit',
    error_validation: 'Invalid file',
    error_upload_params: 'Error preparing upload',
    exception_upload: 'Exception uploading file',
    aborted: 'File upload aborted',
    error_upload: 'Error uploading file',
  };

  const addToQueue = (file) => {
    setCounter.inc();
    setFileCounter.inc();
    if (counter < queueAmount) file.restart();
  };

  const removeFromQueue = (_, files) => {
    setCounter.dec();
    const file = files.find((f) => f.meta.status === 'ready');
    if (file) file.restart();
  };

  const handleChangeStatus = (file, status, files) => {
    const { meta, remove } = file;
    if (fetchChangeStatus) fetchChangeStatus(status);

    if (status === 'ready') {
      if (enableQueueing) addToQueue(file);
    } else if (status === 'headers_received') {
      remove();
    } else if (status === 'uploading') {
      changeUploadInProcess(true);
    } else if (status === 'done') {
      fileDone(meta);
      if (enableQueueing) removeFromQueue(file, files);
    } else if (status === 'cancel') {
    } else if (STATUS_ERRORS[status]) {
      changeUploadInProcess(false, STATUS_ERRORS[status]);
      if (enableQueueing) removeFromQueue(file, files);
    }
  };

  const validateDoneFile = async (meta) => {
    const url = filesUrls[meta.name];
    const response = await s3Api.getMetadata(url);
    if (!response.ok) {
      return 'Uploaded file not found';
    }
    const { headers } = response;
    const size = headers['content-length'];
    if (!size || size === '0') {
      return 'File upload failed';
    }
  };

  const fileDone = async (meta) => {
    meta.url = filesUrls[meta.name];
    const error = await validateDoneFile(meta);
    if (!error) {
      apppendToState([meta]);
    }
    changeUploadInProcess(false, error);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const multiplePreview = (previews) => {
    const remainingCount = previews.length;
    const totalCount = fileCounter;
    const uploadedCount = totalCount - remainingCount;

    let status = 'complete';
    let percentage = 100;

    if (remainingCount > 0) {
      status = `uploading ${uploadedCount + 1} of ${totalCount}`;

      const totalPercent = totalCount * 100;
      const totalPercentCompleted = uploadedCount * 100;
      const totalPercentUploaded = previews.reduce(
        (acc, curr) => acc + curr.props.meta.percent,
        totalPercentCompleted
      );
      percentage = (totalPercentUploaded * 100) / totalPercent;
      percentage = Math.round(percentage);
    }

    if (totalCount === 0) return null;

    return (
      <div className="multiple-preview">
        <DocumentUploadPreview
          percentage={percentage}
          showCompleteIcon
          status={status}
        />
      </div>
    );
  };

  const Layout = (params) => {
    const {
      input,
      previews,
      dropzoneProps,
      files,
      extra: { maxFiles },
    } = params;

    return (
      <>
        <div {...dropzoneProps}>{files.length < maxFiles && input}</div>
        {!hideFilePreview && previews}
        {showMultiplePreview && multiplePreview(previews)}
      </>
    );
  };

  const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
    return (
      <div className="document-drop-zone" onClick={handleClick}>
        <img
          className="document-drop-zone-icon"
          src={darkMode ? uploadSvgDark : uploadSvg}
          alt="upload"
        />
        <span className="document-drop-zone-label">
          {uploadInProcess ? 'Uploading files...' : 'Drag Files Here'}
        </span>
        <input
          style={{ display: 'none' }}
          type="file"
          accept={accept}
          multiple
          ref={hiddenFileInput}
          onChange={async (e) => {
            const target = e.target;
            const chosenFiles = await getFilesFromEvent(e);
            onFiles(chosenFiles);
            target.value = null;
          }}
        />
      </div>
    );
  };

  const Preview = ({ meta }) => {
    const { name, percent, status } = meta;
    const percentage = Math.round(percent);

    return (
      <DocumentUploadPreview
        name={name}
        status={status}
        percentage={percentage}
      />
    );
  };

  return (
    <DropzoneUploader
      classNames={{ inputLabelWithFiles: defaultClassNames.inputLabel }}
      LayoutComponent={Layout}
      InputComponent={Input}
      getUploadParams={getUploadParams}
      PreviewComponent={Preview}
      accept={acceptedTypes}
      onChangeStatus={handleChangeStatus}
      autoUpload={!enableQueueing}
    />
  );
}

DirectS3Uploader.propTypes = {
  acceptedTypes: PropTypes.string,
  model: PropTypes.string,
  modelIdentifier: PropTypes.string,
  apppendToState: PropTypes.func,
  updateStateUploadInProcess: PropTypes.func,
  fetchChangeStatus: PropTypes.func,
  fetchFileSelectedCount: PropTypes.func,
  subModel: PropTypes.string,
  hideFilePreview: PropTypes.bool,
  showMultiplePreview: PropTypes.bool,
  enableQueueing: PropTypes.bool,
  queueAmount: PropTypes.number,
};

DirectS3Uploader.defaultProps = {
  hideFilePreview: false,
  showMultiplePreview: false,
  enableQueueing: false,
  queueAmount: 5,
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(DirectS3Uploader);
