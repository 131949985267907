import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './RfiAddlAssignees.css';
import {
  addRfiAddlAssignee,
  updateRfiAddlAssignee,
  deleteRfiAddlAssignee,
} from 'components/admin/projects/details/project-rfi-log/store/actions';
import AddlAssigneeForm from 'components/shared/additional-assignees/AddlAssigneeForm';
import moment from 'moment';
import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import SimpleTable from 'components/shared/table/SimpleTable';

class RfiAddlAssignees extends Component {
  static propTypes = {
    contribType: PropTypes.string.isRequired,
    dueDate: PropTypes.string,
    questionKey: PropTypes.string.isRequired,
    question: PropTypes.shape({}).isRequired,
    readOnly: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = { showAddlAssigneeForm: false };
  }

  handleShowAddlAssigneeForm = (addlAssignee) => {
    this.setState({ showAddlAssigneeForm: true, addlAssignee });
  };

  handleCloseAddlAssigneeForm = () => {
    this.setState({ showAddlAssigneeForm: false });
  };

  handleSubmitAddlAssigneeForm = (addlAssignee) => {
    const { questionKey, dispatch } = this.props;
    const { id, index } = this.state.addlAssignee;
    if (index !== undefined && index !== null) {
      dispatch(
        updateRfiAddlAssignee(questionKey, { id, ...addlAssignee }, index)
      );
    } else {
      dispatch(addRfiAddlAssignee(questionKey, addlAssignee));
    }
    this.setState({ showAddlAssigneeForm: false });
  };

  handleDeleteAddlAssignee = (index) => {
    const { questionKey, dispatch } = this.props;
    const confirmed = window.confirm('Are you sure?');
    confirmed && dispatch(deleteRfiAddlAssignee(questionKey, index));
  };

  mapAssigneees = () => {
    const { question, readOnly } = this.props;

    return question.addlAssignees.map((addlAssignee, index) => {
      return (
        !addlAssignee.destroy && [
          <div className="rfi-addl-assignee">
            <span className="form-block-text">
              {addlAssignee.assigneeLabel}
            </span>
          </div>,
          <div className="rfi-addl-assignee-data">
            <span className="assignee-date form-block-text">
              {addlAssignee.response
                ? addlAssignee.respondedAt &&
                  `Response - ${moment(addlAssignee.respondedAt).format(
                    'MM/DD/YYYY'
                  )}`
                : `Due - ${moment(addlAssignee.dueDate).format('MM/DD/YYYY')}`}
            </span>
          </div>,
          <div className="rfi-addl-assignee-actions">
            {!readOnly && (
              <Button
                className="assignee-button"
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Edit"
                onClick={() =>
                  this.handleShowAddlAssigneeForm({
                    index,
                    ...addlAssignee,
                  })
                }
              />
            )}
            {!readOnly && (
              <Button
                className="assignee-button"
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Delete"
                onClick={() => this.handleDeleteAddlAssignee(index)}
              />
            )}
          </div>,
        ]
      );
    });
  };

  render() {
    const { dueDate, question, readOnly } = this.props;
    const { showAddlAssigneeForm, addlAssignee } = this.state;
    return (
      <div className="rfi-addl-assignees">
        {question.addlAssignees &&
        question.addlAssignees.filter((row) => !row.destroy).length > 0 ? (
          <SimpleTable
            className="rfi-addl-assignees-table"
            rows={this.mapAssigneees().filter((row) => row)}
          />
        ) : (
          readOnly && (
            <p className="rfi-addl-assignees-empty form-block-text">-</p>
          )
        )}
        {!readOnly && (
          <Button
            className="add-assignee-button"
            type={BUTTON_TYPES.LINK}
            icon={BUTTON_ICONS.PLUS}
            label="Add Assignee"
            onClick={this.handleShowAddlAssigneeForm}
          />
        )}
        {showAddlAssigneeForm && (
          <AddlAssigneeForm
            showModal={showAddlAssigneeForm}
            maxDueDate={dueDate}
            addlAssignees={question.addlAssignees}
            addlAssignee={addlAssignee}
            onSubmit={this.handleSubmitAddlAssigneeForm}
            onClose={this.handleCloseAddlAssigneeForm}
          />
        )}
      </div>
    );
  }
}

export default connect()(RfiAddlAssignees);
