import { showErrorMessage } from 'actions/messages';
import { rfisApi } from 'services/api/rfis-api';
import {
  RFIS_COUNT,
  RFIS_LOAD,
} from 'components/admin/rfis/store/action-types';

export function rfisCountSuccess(count) {
  return {
    type: RFIS_COUNT,
    payload: count,
  };
}

export function rfisCount(filters) {
  return (dispatch) => {
    return rfisApi.getRfisAllCount(filters).then((response) => {
      if (response.ok) {
        dispatch(rfisCountSuccess(response.count));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function rfisSuccess(rfis) {
  return {
    type: RFIS_LOAD,
    payload: rfis,
  };
}

export function rfisRequest(page, status, filters) {
  return (dispatch) => {
    return rfisApi.getRfisAll(page, filters).then((response) => {
      if (response.ok) {
        dispatch(
          rfisSuccess({ ...response, currentPage: page, currentStatus: status })
        );
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}
