import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Grid, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  InputLabel,
  InputAdornment,
  Input,
  IconButton,
  FormControl,
} from '@mui/material';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/fontawesome-free-solid';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import Loader from 'components/shared/loader/LoginLoader';
import './RegisterUser.css';
import logoPng from '../login/logo.png';
import { browserClassname, isFirefox } from 'services/utils/browser-util';
import SupportLink from '../SupportLink';
import { getLoginBackgroundImage } from 'actions/loginBackgroundImage';
import { updateForm, registerUser } from 'actions/client/register-user';
import { getForm, getErrors } from 'selectors/client/register-user';
import eyePng from '../login/eye.png';
import eyeSlachPng from '../login/eyeSlash.png';

class RegisterUser extends Component {
  constructor() {
    super();
    this.state = {
      loadImage: true,
      loadImageError: false,
      showPassword: false,
    };
  }

  componentDidMount() {
    const { imageUrl, getBackgroundImage } = this.props;

    if (!imageUrl) {
      getBackgroundImage();
      this.setState({
        loadImage: false,
        loadImageError: false,
      });
    }
  }

  componentDidUpdate() {
    const { imageUrl } = this.props;
    const { loadImage } = this.state;

    if ((imageUrl || imageUrl === '') && !loadImage) {
      const imageLoader = new Image();
      imageLoader.src = imageUrl;
      imageLoader.onload = () => {
        this.setState({
          loadImage: true,
          loadImageError: false,
        });
      };

      imageLoader.onerror = () => {
        this.setState({
          loadImage: true,
          loadImageError: true,
        });
      };
    }
  }

  handleFormChange = (field, value) => {
    const { onFormChange } = this.props;

    onFormChange(field, value);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { form, onSubmit } = this.props;

    if (!this.canRegister()) {
      return;
    }

    onSubmit(form);
  };

  canRegister = () => {
    const {
      form: { email, password, first_name, last_name, terms_and_conditions },
    } = this.props;

    return (
      !!email &&
      !!password &&
      !!first_name &&
      !!last_name &&
      terms_and_conditions
    );
  };

  isFieldValid = (field) => {
    const { errors } = this.props;

    return !errors.fields.includes(field);
  };

  render() {
    const {
      imageUrl,
      history,
      errors,
      form: { email, password, first_name, last_name, terms_and_conditions },
    } = this.props;
    const { loadImage, loadImageError, showPassword } = this.state;

    const backgroundImage =
      !loadImageError && imageUrl
        ? { backgroundImage: 'url(' + imageUrl + ')' }
        : {};

    if (!loadImage) {
      return <Loader loadImage={loadImage} />;
    }

    return (
      <div className="register-user client" style={backgroundImage}>
        <Grid className="max-height">
          <form
            onSubmit={this.handleSubmit}
            className="align-center"
            autoComplete="off"
          >
            <Col md={9}>
              <div
                className={browserClassname({
                  firefox: 'form-container-firefox',
                  default: 'form-container',
                })}
              >
                <div className="top-buttons">
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    color="white"
                    style={{ fontSize: '25px' }}
                    onClick={() => history.push('/login')}
                  />
                </div>
                <Col md={7} sm={12} className="left-panel">
                  <Row>
                    <Col md={12}>
                      <div className="logo logo-with-back">
                        <img src={logoPng} alt="Logo" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <span className="title-1">New User</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <span className="title-2">Create New Profile</span>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12} className="input-margin-bottom" />

                    <Col md={12}>
                      <FormControl
                        fullWidth
                        className={
                          !this.isFieldValid('first_name') ? 'label-error' : ''
                        }
                      >
                        <InputLabel htmlFor="standard-adornment-first-name">
                          First Name
                        </InputLabel>
                        <Input
                          id="standard-adornment-first-name"
                          type="text"
                          value={first_name}
                          error={!this.isFieldValid('first_name')}
                          onChange={(event) =>
                            this.handleFormChange(
                              'first_name',
                              event.target.value
                            )
                          }
                        />
                      </FormControl>
                    </Col>

                    <Col md={12} className="input-margin-bottom" />

                    <Col md={12}>
                      <FormControl
                        fullWidth
                        className={
                          !this.isFieldValid('last_name') ? 'label-error' : ''
                        }
                      >
                        <InputLabel htmlFor="standard-adornment-last-name">
                          Last Name
                        </InputLabel>
                        <Input
                          id="standard-adornment-last-name"
                          type="text"
                          value={last_name}
                          error={!this.isFieldValid('last_name')}
                          onChange={(event) =>
                            this.handleFormChange(
                              'last_name',
                              event.target.value
                            )
                          }
                        />
                      </FormControl>
                    </Col>

                    <Col md={12} className="input-margin-bottom" />

                    <Col md={12}>
                      <FormControl
                        fullWidth
                        className={
                          !this.isFieldValid('email') ? 'label-error' : ''
                        }
                      >
                        <InputLabel htmlFor="standard-adornment-email">
                          Email Address
                        </InputLabel>
                        <Input
                          id="standard-adornment-email"
                          type="email"
                          value={email}
                          error={!this.isFieldValid('email')}
                          onChange={(event) =>
                            this.handleFormChange('email', event.target.value)
                          }
                        />
                      </FormControl>
                    </Col>

                    <Col md={12} className="input-margin-bottom" />

                    <Col md={12}>
                      <FormControl
                        fullWidth
                        className={
                          !this.isFieldValid('password') ? 'label-error' : ''
                        }
                      >
                        <InputLabel htmlFor="standard-adornment-password">
                          Create Password
                        </InputLabel>
                        <Input
                          id="standard-adornment-password"
                          type={
                            !showPassword && isFirefox() ? 'password' : 'text'
                          }
                          autoComplete="new-password"
                          value={password}
                          error={!this.isFieldValid('password')}
                          style={
                            !showPassword
                              ? {
                                  WebkitTextSecurity: 'disc',
                                }
                              : null
                          }
                          onChange={(event) =>
                            this.handleFormChange(
                              'password',
                              event.target.value
                            )
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  this.setState({ showPassword: !showPassword })
                                }
                                onMouseDown={(event) => event.preventDefault()}
                              >
                                {
                                  <img
                                    src={showPassword ? eyeSlachPng : eyePng}
                                    alt="Show password"
                                    className="eye"
                                  />
                                }
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Col>

                    <Col md={12}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginBottom: 10,
                          marginTop: 40,
                        }}
                      >
                        <LabeledCheckBox
                          checked={terms_and_conditions}
                          onChange={() =>
                            this.handleFormChange(
                              'terms_and_conditions',
                              !terms_and_conditions
                            )
                          }
                          id="terms-and-conditions-checkbox"
                          darkModeFixed={true}
                        />
                        <span style={{ color: 'white', marginLeft: 10 }}>
                          I accept the{' '}
                          <Link
                            target="_blank"
                            style={{ cursor: 'pointer', color: '#D4FB00' }}
                            to={{
                              pathname: 'https://360plusapp.com/',
                            }}
                          >
                            Terms of Use
                          </Link>
                        </span>
                      </div>
                    </Col>

                    {errors.messages.length > 0 ? (
                      <Col md={12}>
                        <div className="errors">{errors.messages}</div>
                      </Col>
                    ) : null}

                    <Col md={6} mdOffset={3}>
                      <SupportLink />
                    </Col>
                  </Row>
                </Col>
                <Col md={5} sm={12} className="right-panel">
                  <div className="buttons">
                    <Button
                      className={`btn-continue ${
                        !this.canRegister() && 'button-disabled'
                      }`}
                      type="submit"
                    >
                      <span className="text-btn">Continue</span>
                      <span className="arrow" />
                    </Button>
                  </div>
                </Col>
              </div>
            </Col>
          </form>
        </Grid>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      imageUrl: state.loginBackgroundImage.url,
      form: getForm(state),
      errors: getErrors(state),
    };
  },
  (dispatch) => {
    return {
      onFormChange: (field, value) => {
        return dispatch(updateForm(field, value));
      },
      onSubmit: (form) => {
        return dispatch(registerUser(form));
      },
      getBackgroundImage: () => dispatch(getLoginBackgroundImage()),
    };
  }
)(RegisterUser);
