export const DEPOSITS_LOAD_SUCCESS = 'DEPOSITS_LOAD_SUCCESS';
export const DEPOSITS_DEPOSIT_ATTRIBUTE_UPDATE =
  'DEPOSITS_DEPOSIT_ATTRIBUTE_UPDATE';
export const DEPOSITS_PAGE_CHANGE = 'DEPOSITS_PAGE_CHANGE';
export const DEPOSITS_DEPOSIT_LOAD_SUCCESS = 'DEPOSITS_DEPOSIT_LOAD_SUCCESS';
export const DEPOSITS_DEPOSIT_PRINT_SUCCESS = 'DEPOSITS_DEPOSIT_PRINT_SUCCESS';
export const DEPOSITS_DEPOSIT_PDF_VIEWER_CLOSE =
  'DEPOSITS_DEPOSIT_PDF_VIEWER_CLOSE';
export const DEPOSITS_USERS_LOAD_SUCCESS = 'DEPOSITS_USERS_LOAD_SUCCESS';
export const DEPOSITS_DEPOSIT_EMAIL_MODAL_SHOW_SET =
  'DEPOSITS_DEPOSIT_EMAIL_MODAL_SHOW_SET';
