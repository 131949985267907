import React, { Component } from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import FormControlBlock, {
  CONTROL_SIZE,
} from 'components/shared/form/FormControlBlock';
import Selector from 'components/shared/selector/Selector';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import DatePicker from 'components/shared/date-picker/DatePicker';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import {
  sameOrBeforeDateValidation,
  sameOrAfterDateValidation,
} from 'services/utils/date-util';
import Range from 'services/utils/range-utils';
import {
  multipleDropdownOptions,
  sortDropdownOptions,
} from 'services/utils/dropdown-utils';
import Input from 'components/shared/input/Input';
import {
  CONDITION_OPTIONS,
  INDUSTRY_OPTIONS,
  INDUSTRY_TYPE_OPTIONS,
  PRIMARY_USERS_OPTIONS,
  COST_OPTIONS,
  SQ_FOOTAGE_OPTIONS,
} from 'domain/project';
import {
  COMPANY_TYPES,
  COMPANY_TYPES_SORTED,
  COMPANY_TYPE_LABELS,
} from 'domain/company-type-enum';
import { ProjectStatusSorted } from 'domain/project-status-enum';
import {
  getAvailableCompanies,
  getAvailableProjects,
  getAvailableCities,
  getAvailableStates,
  getAvailableZips,
  getAvailableAddresses,
  getCurrentReportViewCompanyInformation,
  getCurrentReportViewProjectInformation,
} from './store/selectors';
import {
  loadAvailableCompanies,
  loadAvailableProjects,
  setReportViewFormValue,
} from './store/actions';

class ReportProjectInformation extends Component {
  constructor(props) {
    super(props);
    const { availableCompanies, availableProjects, dispatch } = this.props;

    this.conditionOptions = multipleDropdownOptions(CONDITION_OPTIONS);
    this.industryOptions = multipleDropdownOptions(INDUSTRY_OPTIONS);
    this.industryTypeOptions = Object.keys(INDUSTRY_TYPE_OPTIONS).reduce(
      (options, option) => ({
        ...options,
        [option]: multipleDropdownOptions(INDUSTRY_TYPE_OPTIONS[option]),
      }),
      {}
    );
    this.primaryUsersOptions = Range.makeOptions(
      PRIMARY_USERS_OPTIONS,
      false,
      true,
      true
    );
    this.costOptions = Range.makeOptions(COST_OPTIONS, true, true, true);
    this.sqFootageOptions = Range.makeOptions(
      SQ_FOOTAGE_OPTIONS,
      true,
      true,
      true
    );
    this.suscriberCompanyTypeOptions = [
      {
        id: COMPANY_TYPES.OWNER_AUTHORIZED_REP,
        label: COMPANY_TYPE_LABELS[COMPANY_TYPES.OWNER_AUTHORIZED_REP],
      },
      {
        id: COMPANY_TYPES.GENERAL_CONTRACTOR,
        label: COMPANY_TYPE_LABELS[COMPANY_TYPES.GENERAL_CONTRACTOR],
      },
    ];
    this.contributerCompanyTypeOptions = COMPANY_TYPES_SORTED.map((name) => ({
      id: name,
      label: COMPANY_TYPE_LABELS[name],
    }));
    this.statusOptions = ProjectStatusSorted.map((status) => ({
      className: status.isSubStatus() ? 'substatus-option' : null,
      id: status.status,
      label: status.label,
    }));

    if (!availableCompanies) {
      dispatch(loadAvailableCompanies());
    }
    if (!availableProjects) {
      dispatch(loadAvailableProjects());
    }
  }

  handleFormCompanyChange = (attribute, value) => {
    const { dispatch } = this.props;
    dispatch(setReportViewFormValue('company_information', attribute, value));
  };

  handleFormProjectChange = (attribute, value) => {
    const { dispatch } = this.props;
    dispatch(setReportViewFormValue('project_information', attribute, value));
  };

  getIndustryTypeOptions = () => {
    const {
      currentReportViewProjectInformation: { industries },
    } = this.props;

    return (industries.length > 0
      ? industries.map((type) => type[0])
      : INDUSTRY_OPTIONS
    ).reduce(
      (types, type) => [
        ...types,
        ...this.industryTypeOptions[type].filter(
          (option) => !types.find((existent) => existent.id === option.id)
        ),
      ],
      []
    );
  };

  getSuscriberCompanies = () => {
    const {
      availableCompanies,
      currentReportViewCompanyInformation: { suscriber_company_types },
    } = this.props;

    if (!availableCompanies) {
      return [];
    }

    const availableTypes =
      suscriber_company_types.length > 0
        ? suscriber_company_types.reduce(
            (companies, company) => ({
              ...companies,
              [company[0]]: true,
            }),
            []
          )
        : this.suscriberCompanyTypeOptions.reduce(
            (companies, company) => ({
              ...companies,
              [company.id]: true,
            }),
            []
          );

    const options = Object.keys(availableCompanies)
      .filter(
        (company) => availableTypes[availableCompanies[company].company_type]
      )
      .map((company) => ({
        id: company,
        label: availableCompanies[company].name,
      }));
    return sortDropdownOptions(options);
  };

  getContributorCompanies = () => {
    const {
      availableCompanies,
      currentReportViewCompanyInformation: { contributer_company_types },
    } = this.props;

    if (!availableCompanies) {
      return [];
    }

    const availableTypes =
      contributer_company_types.length > 0
        ? contributer_company_types.reduce(
            (companies, company) => ({
              ...companies,
              [company[0]]: true,
            }),
            []
          )
        : null;

    const options = Object.keys(availableCompanies)
      .filter(
        (company) =>
          !availableTypes ||
          availableCompanies[company].contributor_types.find(
            (type) => availableTypes[type]
          )
      )
      .map((company) => ({
        id: company,
        label: availableCompanies[company].name,
      }));
    return sortDropdownOptions(options);
  };

  getProjects = () => {
    const { availableProjects } = this.props;

    return availableProjects
      ? sortDropdownOptions(
          availableProjects.map((project) => ({
            id: project.id,
            label: project.name,
          }))
        )
      : [];
  };

  getAddresses = () => {
    const { availableAddresses } = this.props;

    return availableAddresses
      ? multipleDropdownOptions(Object.keys(availableAddresses), true)
      : [];
  };

  getAddressUnits = () => {
    const {
      availableAddresses,
      currentReportViewProjectInformation: { street_address_1s },
    } = this.props;

    if (!availableAddresses) {
      return [];
    }

    const options = (street_address_1s.length > 0
      ? street_address_1s.map((address) => address[0])
      : Object.keys(availableAddresses)
    ).reduce((result, address) => {
      const units = availableAddresses[address] || [];
      return [...result, ...units.filter((unit) => !result.includes(unit))];
    }, []);
    return multipleDropdownOptions(options, true);
  };

  getCities = () => {
    const { availableCities } = this.props;

    return multipleDropdownOptions(availableCities || [], true);
  };

  getStates = () => {
    const { availableStates } = this.props;

    return multipleDropdownOptions(availableStates || [], true);
  };

  getZips = () => {
    const { availableZips } = this.props;

    return multipleDropdownOptions(availableZips || [], true);
  };

  render() {
    const {
      currentReportViewCompanyInformation: {
        suscriber_company_types,
        suscriber_companies,
        contributer_company_types,
        contributer_companies,
        contributer_companies_status,
      },
      currentReportViewProjectInformation: {
        projects,
        street_address_1s,
        street_address_2s,
        cities,
        states,
        zips,
        statuses,
        conditions,
        industries,
        industry_types,
        primary_users_counts,
        sq_footages,
        sq_footage_from,
        sq_footage_to,
        estimated_costs,
        estimated_cost_from,
        estimated_cost_to,
        est_start_date_from,
        est_start_date_to,
        est_completion_date_from,
        est_completion_date_to,
        est_duration,
        creation_date_from,
        creation_date_to,
        termination_date_from,
        termination_date_to,
        duration,
      },
    } = this.props;
    const isPublic = this.props.currentReportViewProjectInformation.public;

    return (
      <React.Fragment>
        <div className="sidebar-form-body-subtitle">Project Companies</div>

        <div className="form-block">
          <FormControlBlock
            label={
              !!suscriber_company_types.length > 0 && 'Subscriber Company Type'
            }
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Subscriber Company Type"
                currentValues={suscriber_company_types}
                onChange={(values) =>
                  this.handleFormCompanyChange(
                    'suscriber_company_types',
                    values
                  )
                }
                search={false}
                options={this.suscriberCompanyTypeOptions}
              />
            }
          />
          <FormControlBlock
            label={
              !!suscriber_companies.length > 0 && 'Subscriber Company Name'
            }
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Subscriber Company Name"
                currentValues={suscriber_companies}
                onChange={(values) =>
                  this.handleFormCompanyChange('suscriber_companies', values)
                }
                options={this.getSuscriberCompanies()}
              />
            }
          />
          <FormControlBlock size={CONTROL_SIZE.BIG} />
        </div>

        <div className="form-block">
          <FormControlBlock
            label={
              !!contributer_company_types.length > 0 &&
              'Contributer Company Type'
            }
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Contributer Company Type"
                currentValues={contributer_company_types}
                onChange={(values) =>
                  this.handleFormCompanyChange(
                    'contributer_company_types',
                    values
                  )
                }
                search={false}
                options={this.contributerCompanyTypeOptions}
              />
            }
          />
          <FormControlBlock
            label={
              !!contributer_companies.length > 0 && 'Contributer Company Name'
            }
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Contributer Company Name"
                currentValues={contributer_companies}
                onChange={(values) =>
                  this.handleFormCompanyChange('contributer_companies', values)
                }
                options={this.getContributorCompanies()}
              />
            }
          />
          <FormControlBlock
            label={
              !!contributer_companies_status && 'Contributer Company Status'
            }
            size={CONTROL_SIZE.BIG}
            control={
              <Selector
                placeholder="Contributer Company Status"
                value={contributer_companies_status}
                onChange={(option) =>
                  this.handleFormCompanyChange(
                    'contributer_companies_status',
                    option ? option.value : null
                  )
                }
                options={[
                  {
                    value: 'active',
                    label: 'Active',
                  },
                  {
                    value: 'inactive',
                    label: 'Inactive',
                  },
                ]}
                clearable
              />
            }
          />
        </div>

        <div className="sidebar-form-body-subtitle">Project Address</div>

        <div className="form-block">
          <FormControlBlock
            label={!!projects.length > 0 && 'Project Name'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Project Name"
                currentValues={projects}
                onChange={(values) =>
                  this.handleFormProjectChange('projects', values)
                }
                options={this.getProjects()}
              />
            }
          />
          <FormControlBlock
            label={!!street_address_1s.length > 0 && 'Project Address'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Project Address"
                currentValues={street_address_1s}
                onChange={(values) =>
                  this.handleFormProjectChange('street_address_1s', values)
                }
                options={this.getAddresses()}
              />
            }
          />
          <FormControlBlock
            label={!!street_address_2s.length > 0 && 'Project Address Unit'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Project Address Unit"
                currentValues={street_address_2s}
                onChange={(values) =>
                  this.handleFormProjectChange('street_address_2s', values)
                }
                options={this.getAddressUnits()}
              />
            }
          />
        </div>

        <div className="form-block">
          <FormControlBlock
            label={!!cities.length > 0 && 'Project City'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Project City"
                currentValues={cities}
                onChange={(values) =>
                  this.handleFormProjectChange('cities', values)
                }
                options={this.getCities()}
              />
            }
          />
          <FormControlBlock
            label={!!states.length > 0 && 'Project State'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Project State"
                currentValues={states}
                onChange={(values) =>
                  this.handleFormProjectChange('states', values)
                }
                options={this.getStates()}
              />
            }
          />
          <FormControlBlock
            label={!!zips.length > 0 && 'Project Postal Code'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Project Postal Code"
                currentValues={zips}
                onChange={(values) =>
                  this.handleFormProjectChange('zips', values)
                }
                options={this.getZips()}
              />
            }
          />
        </div>

        <div className="sidebar-form-body-subtitle">Project Setup</div>

        <div className="form-block">
          <FormControlBlock
            label={!!statuses.length > 0 && 'Project Stage'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Project Stage"
                currentValues={statuses}
                onChange={(values) =>
                  this.handleFormProjectChange('statuses', values)
                }
                options={this.statusOptions}
                search={false}
              />
            }
          />
          <FormControlBlock
            label={isPublic !== null && 'Private or Public'}
            size={CONTROL_SIZE.BIG}
            control={
              <Selector
                placeholder="Private or Public"
                value={isPublic}
                onChange={(option) =>
                  this.handleFormProjectChange(
                    'public',
                    option ? option.value : null
                  )
                }
                options={[
                  {
                    value: 0,
                    label: 'Private',
                  },
                  {
                    value: 1,
                    label: 'Public',
                  },
                ]}
                clearable
              />
            }
          />
          <FormControlBlock
            label={!!conditions.length > 0 && 'Condition'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Condition"
                currentValues={conditions}
                onChange={(values) =>
                  this.handleFormProjectChange('conditions', values)
                }
                options={this.conditionOptions}
                search={false}
              />
            }
          />
        </div>

        <div className="form-block">
          <FormControlBlock
            label={!!industries.length > 0 && 'Project Industry'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Project Industry"
                currentValues={industries}
                onChange={(values) =>
                  this.handleFormProjectChange('industries', values)
                }
                options={this.industryOptions}
                search={false}
              />
            }
          />
          <FormControlBlock
            label={!!industry_types.length > 0 && 'Project Type'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Project Type"
                currentValues={industry_types}
                onChange={(values) =>
                  this.handleFormProjectChange('industry_types', values)
                }
                options={this.getIndustryTypeOptions()}
                search={false}
              />
            }
          />
          <FormControlBlock size={CONTROL_SIZE.BIG} />
        </div>

        <div className="form-block">
          <FormControlBlock
            label={!!sq_footages.length > 0 && 'Project SqFt'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Project SqFt"
                currentValues={sq_footages}
                onChange={(values) =>
                  this.handleFormProjectChange('sq_footages', values)
                }
                options={this.sqFootageOptions}
              />
            }
          />
          <FormControlBlock
            label={!!sq_footage_from && 'Range From'}
            size={CONTROL_SIZE.SMALL}
            control={
              <Input
                placeholder="Range From"
                value={
                  sq_footage_from ? numeral(sq_footage_from).format('0,0') : ''
                }
                onChange={(value) =>
                  this.handleFormProjectChange(
                    'sq_footage_from',
                    numeral(value).value()
                  )
                }
              />
            }
          />
          <FormControlBlock
            label={!!sq_footage_to && 'Range To'}
            size={CONTROL_SIZE.SMALL}
            control={
              <Input
                placeholder="Range To"
                value={
                  sq_footage_to ? numeral(sq_footage_to).format('0,0') : ''
                }
                onChange={(value) =>
                  this.handleFormProjectChange(
                    'sq_footage_to',
                    numeral(value).value()
                  )
                }
              />
            }
          />
          <FormControlBlock size={CONTROL_SIZE.BIG} />
        </div>

        <div className="form-block">
          <FormControlBlock
            label={!!estimated_costs.length > 0 && 'Est Project Cost'}
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Est Project Cost"
                currentValues={estimated_costs}
                onChange={(values) =>
                  this.handleFormProjectChange('estimated_costs', values)
                }
                options={this.costOptions}
              />
            }
          />
          <FormControlBlock
            label={!!estimated_cost_from && 'Range From'}
            size={CONTROL_SIZE.SMALL}
            control={
              <Input
                placeholder="Range From"
                value={
                  estimated_cost_from
                    ? numeral(estimated_cost_from).format('$ 0,0')
                    : ''
                }
                onChange={(value) =>
                  this.handleFormProjectChange(
                    'estimated_cost_from',
                    numeral(value).value()
                  )
                }
              />
            }
          />
          <FormControlBlock
            label={!!estimated_cost_to && 'Range To'}
            size={CONTROL_SIZE.SMALL}
            control={
              <Input
                placeholder="Range To"
                value={
                  estimated_cost_to
                    ? numeral(estimated_cost_to).format('$ 0,0')
                    : ''
                }
                onChange={(value) =>
                  this.handleFormProjectChange(
                    'estimated_cost_to',
                    numeral(value).value()
                  )
                }
              />
            }
          />
          <FormControlBlock size={CONTROL_SIZE.BIG} />
        </div>

        <div className="form-block sidebar-form-titles-block">
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={<span className="form-block-text">Est Project Start</span>}
          />
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={
              <span className="form-block-text">Est Project Completion</span>
            }
          />
          <FormControlBlock size={CONTROL_SIZE.BIG} />
        </div>

        <div className="form-block">
          <FormControlBlock
            label={!!est_start_date_from && 'Date From'}
            size={CONTROL_SIZE.SMALL}
            control={
              <DatePicker
                isValidDate={(date) =>
                  sameOrBeforeDateValidation(date, est_start_date_to)
                }
                placeholder="Date From"
                value={est_start_date_from}
                clearable
                onChange={(value) =>
                  this.handleFormProjectChange('est_start_date_from', value)
                }
              />
            }
          />
          <FormControlBlock
            label={!!est_start_date_to && 'Date To'}
            size={CONTROL_SIZE.SMALL}
            control={
              <DatePicker
                isValidDate={(date) =>
                  sameOrAfterDateValidation(date, est_start_date_from)
                }
                placeholder="Date To"
                value={est_start_date_to}
                clearable
                onChange={(value) =>
                  this.handleFormProjectChange('est_start_date_to', value)
                }
              />
            }
          />
          <FormControlBlock
            label={!!est_completion_date_from && 'Date From'}
            size={CONTROL_SIZE.SMALL}
            control={
              <DatePicker
                isValidDate={(date) =>
                  sameOrBeforeDateValidation(date, est_completion_date_to)
                }
                placeholder="Date From"
                value={est_completion_date_from}
                clearable
                onChange={(value) =>
                  this.handleFormProjectChange(
                    'est_completion_date_from',
                    value
                  )
                }
              />
            }
          />
          <FormControlBlock
            label={!!est_completion_date_to && 'Date To'}
            size={CONTROL_SIZE.SMALL}
            control={
              <DatePicker
                isValidDate={(date) =>
                  sameOrAfterDateValidation(date, est_completion_date_from)
                }
                placeholder="Date To"
                value={est_completion_date_to}
                clearable
                onChange={(value) =>
                  this.handleFormProjectChange('est_completion_date_to', value)
                }
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormProjectChange('est_duration', value)
                }
                checked={est_duration}
                label="Inc Duration Calculation"
                position="left"
              />
            }
          />
        </div>

        <div className="form-block sidebar-form-titles-block">
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={<span className="form-block-text">Project Creation</span>}
          />
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={<span className="form-block-text">Project Complete</span>}
          />
          <FormControlBlock size={CONTROL_SIZE.BIG} />
        </div>

        <div className="form-block">
          <FormControlBlock
            label={!!creation_date_from && 'Date From'}
            size={CONTROL_SIZE.SMALL}
            control={
              <DatePicker
                isValidDate={(date) =>
                  sameOrBeforeDateValidation(date, creation_date_to)
                }
                placeholder="Date From"
                value={creation_date_from}
                clearable
                onChange={(value) =>
                  this.handleFormProjectChange('creation_date_from', value)
                }
              />
            }
          />
          <FormControlBlock
            label={!!creation_date_to && 'Date To'}
            size={CONTROL_SIZE.SMALL}
            control={
              <DatePicker
                isValidDate={(date) =>
                  sameOrAfterDateValidation(date, creation_date_from)
                }
                placeholder="Date To"
                value={creation_date_to}
                clearable
                onChange={(value) =>
                  this.handleFormProjectChange('creation_date_to', value)
                }
              />
            }
          />
          <FormControlBlock
            label={!!termination_date_from && 'Date From'}
            size={CONTROL_SIZE.SMALL}
            control={
              <DatePicker
                isValidDate={(date) =>
                  sameOrBeforeDateValidation(date, termination_date_to)
                }
                placeholder="Date From"
                value={termination_date_from}
                clearable
                onChange={(value) =>
                  this.handleFormProjectChange('termination_date_from', value)
                }
              />
            }
          />
          <FormControlBlock
            label={!!termination_date_to && 'Date To'}
            size={CONTROL_SIZE.SMALL}
            control={
              <DatePicker
                isValidDate={(date) =>
                  sameOrAfterDateValidation(date, termination_date_from)
                }
                placeholder="Date To"
                value={termination_date_to}
                clearable
                onChange={(value) =>
                  this.handleFormProjectChange('termination_date_to', value)
                }
              />
            }
          />
          <FormControlBlock
            size={CONTROL_SIZE.BIG}
            control={
              <LabeledCheckBox
                onChange={(value) =>
                  this.handleFormProjectChange('duration', value)
                }
                checked={duration}
                label="Inc Duration Calculation"
                position="left"
              />
            }
          />
        </div>

        <div className="form-block">
          <FormControlBlock
            label={
              !!primary_users_counts.length > 0 && 'Total Est Project Users'
            }
            size={CONTROL_SIZE.BIG}
            control={
              <MultipleDropdown
                placeholder="Total Est Project Users"
                currentValues={primary_users_counts}
                onChange={(values) =>
                  this.handleFormProjectChange('primary_users_counts', values)
                }
                options={this.primaryUsersOptions}
                search={false}
              />
            }
          />
          <FormControlBlock size={CONTROL_SIZE.BIG} />
          <FormControlBlock size={CONTROL_SIZE.BIG} />
        </div>
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    availableCompanies: getAvailableCompanies(state),
    availableProjects: getAvailableProjects(state),
    availableCities: getAvailableCities(state),
    availableStates: getAvailableStates(state),
    availableZips: getAvailableZips(state),
    availableAddresses: getAvailableAddresses(state),
    currentReportViewCompanyInformation: getCurrentReportViewCompanyInformation(
      state
    ),
    currentReportViewProjectInformation: getCurrentReportViewProjectInformation(
      state
    ),
  };
})(ReportProjectInformation);
