import React from 'react';
import { connect } from 'react-redux';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import CharsCounterTextarea from 'components/shared/chars-counter/CharsCounterTextarea';
import DatePicker from 'components/shared/date-picker/DatePicker';
import TimePicker from 'components/shared/time-picker/TimePicker';
import UnitsInput from 'components/shared/input/UnitsInput';
import Selector from 'components/shared/selector/Selector';
import SearchableDropDown from 'components/shared/dropdown/SearchableDropDown';
import Project from 'domain/project';
import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import MapSelectLocation from 'components/shared/map/MapSelectLocation';

import { sameOrBeforeTodayValidation } from 'services/utils/date-util';
import {
  weatherConditionOptions,
  weatherPrecipitationOptions,
  weatherWindOptions,
} from 'domain/weather';

import { showErrorMessage } from 'actions/messages';
import {
  importWeatherInformation,
  loadPreviousFieldReport,
  loadFieldReportsResolved,
  loadTimezone,
} from './store/actions';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import FieldReportsResolvedModal from './FieldReportsResolvedModal';
import timezonesArray from './TimezonesList';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';

const FieldReportFormInfo = (props) => {
  const { handleFormChange, form, dispatch, currentProject } = props;

  const handleLocationChange = async (latLng) => {
    const response = await dispatch(loadTimezone(latLng));

    if (response.ok) {
      const timezone_name = response.timezone_name;
      const timezone = timezonesArray.filter(
        (o) => o.timezone_name === timezone_name
      ).value;
      handleFormChange({
        location: latLng,
        timezone,
        timezone_name,
      });
    } else {
      dispatch(showErrorMessage('An error occurred.'));
    }
  };

  const InputTitle = (
    <FormControlBlock
      hideBottomLabel
      hideLabel={!form.title}
      label={form.title && 'Optional Title'}
      control={
        <CharsCounterTextarea
          limit={50}
          className="title"
          placeholder={'Optional Title'}
          value={form.title}
          valid={!(form.errors && form.errors.title)}
          onChange={(value) => handleFormChange({ title: value })}
        />
      }
    />
  );

  const InputDate = (
    <FormControlBlock
      hideBottomLabel
      label={form.date && 'Report Date *'}
      control={
        <DatePicker
          className="date"
          placeholder={'Report Date *'}
          value={form.date}
          onChange={(value) => handleFormChange({ date: value })}
          isValidDate={sameOrBeforeTodayValidation}
          valid={!(form.errors && form.errors.date)}
        />
      }
    />
  );

  const InputArrival = (
    <FormControlBlock
      hideBottomLabel
      label={form.arrival_time && 'Arrival'}
      control={
        <TimePicker
          className={'clock'}
          placeholder={'Arrival'}
          onChange={(value) => handleFormChange({ arrival_time: value })}
          value={form.arrival_time}
          valid={!(form.errors && form.errors.arrival_time)}
        />
      }
    />
  );

  const InputDeparture = (
    <FormControlBlock
      hideBottomLabel
      label={form.departure_time && 'Departure'}
      control={
        <TimePicker
          className={'clock'}
          placeholder={'Departure'}
          onChange={(value) => handleFormChange({ departure_time: value })}
          value={form.departure_time}
          valid={!(form.errors && form.errors.departure_time)}
        />
      }
    />
  );
  const InputTimezone = (
    <FormControlBlock
      hideBottomLabel
      className="input-condition"
      control={
        <SearchableDropDown
          className="select-dropdown"
          placeholder="Timezone"
          value={form.timezone}
          onChange={(option) =>
            handleFormChange({
              timezone: option ? option.value : option,
              timezone_name: option ? option.timezone_name : option,
            })
          }
          loadOptions={() =>
            new Promise((resolve) =>
              resolve({ ok: true, options: timezonesArray })
            )
          }
          clearable
        />
      }
    />
  );

  const InputTemperature = (
    <FormControlBlock
      hideBottomLabel
      className="input-temperature"
      label={form.weather_temperature && 'Temperature'}
      control={
        <UnitsInput
          type={'number'}
          placeholder={'Temperature'}
          value={form.weather_temperature}
          unit="F"
          unitPosition={'end'}
          onChange={(value) => {
            handleFormChange({ weather_temperature: value });
          }}
        />
      }
    />
  );

  const InputWind = (
    <FormControlBlock
      hideBottomLabel
      className="input-wind"
      label={form.weather_wind && 'Wind'}
      control={
        <UnitsInput
          type={'number'}
          placeholder={'Wind'}
          value={form.weather_wind}
          unit={
            form.weather_wind_direction
              ? `mph ${
                  weatherWindOptions.find(
                    (o) => o.value === form.weather_wind_direction
                  ).short
                }`
              : 'mph'
          }
          unitPosition={'end'}
          onChange={(value) => handleFormChange({ weather_wind: value })}
        />
      }
    />
  );

  const InputWindDirection = (
    <FormControlBlock
      hideBottomLabel
      label={form.weather_wind && 'Wind Direction'}
      className="input-wind-direction"
      control={
        <Selector
          className="select-dropdown"
          placeholder={'Wind Direction'}
          value={form.weather_wind_direction}
          onChange={(option) =>
            handleFormChange({
              weather_wind_direction: option ? option.value : option,
            })
          }
          valid={!(form.errors && form.errors.weather_wind_direction)}
          options={weatherWindOptions}
          clearable
        />
      }
    />
  );

  const InputHumidity = (
    <FormControlBlock
      hideBottomLabel
      className="input-humidity"
      label={form.weather_humidity && 'Humidity'}
      control={
        <UnitsInput
          type={'number'}
          placeholder={'Humidity'}
          value={form.weather_humidity}
          unit="%"
          unitPosition={'end'}
          onChange={(value) => handleFormChange({ weather_humidity: value })}
        />
      }
    />
  );

  const InputCondition = (
    <FormControlBlock
      hideBottomLabel
      className="input-condition"
      label={form.weather_condition && 'Condition'}
      control={
        <Selector
          className="select-dropdown"
          placeholder={'Condition'}
          value={form.weather_condition}
          onChange={(option) =>
            handleFormChange({
              weather_condition: option ? option.value : option,
            })
          }
          valid={!(form.errors && form.errors.weather_condition)}
          options={weatherConditionOptions}
          clearable
        />
      }
    />
  );

  const InputPrecipitation = (
    <FormControlBlock
      hideBottomLabel
      label={form.weather_precipitation && 'Precipitation'}
      className="input-precipitation"
      control={
        <Selector
          className="select-dropdown"
          placeholder={'Precipitation'}
          value={form.weather_precipitation}
          onChange={(option) =>
            handleFormChange({
              weather_precipitation: option ? option.value : option,
            })
          }
          options={weatherPrecipitationOptions}
          valid={!(form.errors && form.errors.weather_precipitation)}
          clearable
        />
      }
    />
  );

  const InputWeatherRemarks = (
    <FormControlBlock
      hideBottomLabel
      className="input-weather-remarks inline-block"
      label={form.weather_remarks && 'Remarks'}
      control={
        <CharsCounterTextarea
          placeholder={'Remarks'}
          value={form.weather_remarks}
          valid={!(form.errors && form.errors.weather_remarks)}
          onChange={(value) => handleFormChange({ weather_remarks: value })}
          limit={100}
        />
      }
    />
  );

  const InputDescription = (
    <FormControlBlock
      hideBottomLabel
      className="input-report-remarks"
      hideLabel={!form.description}
      label={form.description && 'Remarks'}
      control={
        <CharsCounterTextarea
          placeholder={'Remarks'}
          value={form.description}
          valid={!(form.errors && form.errors.description)}
          onChange={(value) => handleFormChange({ description: value })}
          limit={500}
        />
      }
    />
  );

  const InputLocation = (
    <MapSelectLocation
      onChange={(latLng) => handleLocationChange(latLng)}
      defaultCenterLat={form.location ? form.location.latitude : undefined}
      defaultCenterLng={form.longitude ? form.location.longitude : undefined}
      markerLat={form.location ? form.location.latitude : undefined}
      markerLng={form.location ? form.location.longitude : undefined}
    />
  );

  const recipientOptions = () => {
    const permissions = [...currentProject.project_report_permissions, 'oar'];
    return Project.filterReportRecipients({
      directory: props.directory,
      permissions,
      type: 'name',
    });
  };

  const reportRecipients = (
    <MultipleDropdown
      options={recipientOptions()}
      currentValues={form.recipients || []}
      placeholder="Report Recipients"
      onChange={(value) => {
        handleFormChange({ recipients: value });
      }}
    />
  );

  const handleImportWeather = async () => {
    if (form.location && form.date) {
      const response = await dispatch(
        importWeatherInformation(
          form.location,
          form.date,
          form.arrival_time,
          form.departure_time
        )
      );
      if (response.ok) handleFormChange(response.info);
    } else {
      dispatch(
        showErrorMessage(
          'Location and Date are required to import weather information.'
        )
      );
    }
  };

  const handleLoadPrevius = async () => {
    const response = await dispatch(loadPreviousFieldReport(currentProject.id));
    if (response.ok && response.report)
      handleFormChange({ description: response.report.description });
  };

  const handleViewResolved = () => {
    dispatch(loadFieldReportsResolved(currentProject.id, { page: 1 }));
  };

  return (
    <React.Fragment>
      <div>
        <div className="form-block">
          <label className="form-label">Title</label>
          <small>
            Optional titles can be used to designate specific reporting aside
            from general observation
          </small>
          {InputTitle}
        </div>

        <div className="form-block-container">
          <div className="form-block">
            <label className="form-label">Report Date</label>
            {InputDate}
          </div>

          <div className="form-block">
            <label className="form-label">Observation Time</label>

            <div className="form-control-container">
              {InputArrival}
              {InputDeparture}
            </div>
          </div>
        </div>

        <div className="form-block">
          <label className="report-form-block-label">Report Recipients</label>
          {reportRecipients}
        </div>

        <div className="form-block location-block">
          <label className="form-label">Location</label>
          {InputLocation}
        </div>
        <div className="form-block timezone-block">
          <label className="form-label">Timezone</label>
          {InputTimezone}
        </div>
        <div className="form-block weather-block">
          <div className="weather-label">
            <label className="form-label">Weather Conditions</label>
            <Button
              key="weather-import"
              type={BUTTON_TYPES.LINK}
              icon={BUTTON_ICONS.DOWNLOAD}
              label="Import"
              onClick={handleImportWeather}
            />
          </div>
          <div className="form-control-container weather">
            {InputTemperature}
            {InputHumidity}
            {InputWind}
            {InputWindDirection}
          </div>
          <div className="form-control-container weather">
            {InputCondition}
            {InputPrecipitation}
            {InputWeatherRemarks}
          </div>
        </div>

        <div className="form-block description-block">
          <div className="description-label">
            <label className="form-label">Report Summary</label>
            <Button
              key="load-prev-report"
              type={BUTTON_TYPES.LINK}
              color={BUTTON_COLORS.GREEN}
              label="Load Previous"
              onClick={handleLoadPrevius}
            />
            <Button
              key="view-resolved-reports"
              type={BUTTON_TYPES.LINK}
              color={BUTTON_COLORS.GREEN}
              label="View Resolved"
              onClick={handleViewResolved}
            />
          </div>
          {InputDescription}
        </div>
      </div>
      <FieldReportsResolvedModal />
    </React.Fragment>
  );
};

export default connect((state) => ({
  currentProject: getCurrentProject(state),
  directory: getDirectory(state),
}))(FieldReportFormInfo);
