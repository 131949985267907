import { showErrorMessage } from 'actions/messages';
import { submittalsApi } from 'services/api/submittals-api';
import {
  SUBMITTALS_COUNT,
  SUBMITTALS_LOAD,
} from 'components/admin/submittals/store/action-types';

export function submittalsCountSuccess(count) {
  return {
    type: SUBMITTALS_COUNT,
    payload: count,
  };
}

export function submittalsCount(filters) {
  return (dispatch) => {
    return submittalsApi.getSubmittalsAllCount(filters).then((response) => {
      if (response.ok) {
        dispatch(submittalsCountSuccess(response.count));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function submittalsSuccess(submittals) {
  return {
    type: SUBMITTALS_LOAD,
    payload: submittals,
  };
}

export function submittalsRequest(page, status, filters) {
  return (dispatch) => {
    return submittalsApi.getSubmittalsAll(page, filters).then((response) => {
      if (response.ok) {
        dispatch(
          submittalsSuccess({
            ...response,
            currentPage: page,
            currentStatus: status,
          })
        );
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}
