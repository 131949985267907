import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'components/shared/date-picker/DatePicker';
import './ReportFilter.css';

export default function ReportFilter(props) {
  const handleStartDateChange = (value) => {
    if (props.onStartDateChange) {
      props.onStartDateChange(value);
    }
  };

  const handleEndDateChange = (value) => {
    if (props.onEndDateChange) {
      props.onEndDateChange(value);
    }
  };

  return (
    <div className="report-filter">
      <DatePicker
        placeholder="Start Date"
        value={props.startDate}
        onChange={(value) => handleStartDateChange(value)}
      />
      <DatePicker
        placeholder="End Date"
        value={props.endDate}
        onChange={(value) => handleEndDateChange(value)}
      />
      {props.extraFilters.map((filter, i) => (
        <div className="extra-filter" key={i}>
          {filter}
        </div>
      ))}
    </div>
  );
}

ReportFilter.propTypes = {
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
  extraFilters: PropTypes.array,
};

ReportFilter.defaultProps = {
  extraFilters: [],
};
