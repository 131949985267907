export const SURVEYS_PAGINATION_INFO_SET = 'SURVEYS_PAGINATION_INFO_SET';
export const SURVEYS_PAGINATION_PAGE_CHANGE = 'SURVEYS_PAGINATION_PAGE_CHANGE';
export const SURVEYS_TOTAL_PENDING_SET = 'SURVEYS_TOTAL_PENDING_SET';
export const SURVEYS_SET = 'SURVEYS_SET';
export const SURVEYS_SURVEY_FORM_ATTRIBUTE_CHANGE =
  'SURVEYS_SURVEY_FORM_ATTRIBUTE_CHANGE';
export const SURVEYS_SURVEY_FORM_SET = 'SURVEYS_SURVEY_FORM_SET';
export const SURVEYS_SURVEY_FORM_VALIDATION_MESSAGE_SET =
  'SURVEYS_SURVEY_FORM_VALIDATION_MESSAGE_SET';
export const SURVEYS_SURVEY_SET = 'SURVEYS_SURVEY_SET';
