import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ProjectLocations.css';
import DetailsHeader from 'components/shared/details/DetailsHeader';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import SimpleTable from 'components/shared/table/SimpleTable';
import {
  goToProjectLocation,
  loadProjectLocationDetailsRequest,
  loadProjectLocationsRequest,
  newProjectLocation,
} from 'actions/admin/project-locations/project-locations';
import SimpleText from 'components/shared/text/SimpleText';
import {
  getProjectLocations,
  getProjectLocationsCurrentPage,
  getProjectLocationsTotal,
  getProjectLocationsPageSize,
} from 'selectors/admin/project-locations/project-locations';
import { simpleScrollToTop } from 'services/utils/scroller-util';
import { getCurrentUser } from 'selectors/authentication';
import ProjectLocationPermissions from 'permissions/project-location-permissions';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
} from 'components/shared/button/Button';

class ProjectLocations extends Component {
  componentDidMount() {
    this.props.dispatch(loadProjectLocationsRequest(1));
  }

  componentDidUpdate() {
    simpleScrollToTop();
  }

  handleAddProjectLocation = (event) => {
    event.preventDefault();
    this.props.dispatch(newProjectLocation());
  };

  handleGenerateLink = (index) => {
    const { projectLocations } = this.props;
    return `project-locations/${projectLocations[index].id}`;
  };

  mapProjectLocations = (projectLocations) => {
    return projectLocations.map((projectLocation) => {
      return [
        <SimpleText
          lines={[
            projectLocation.location.street_address_1,
            projectLocation.location.city,
          ]}
          style={{ fontWeight: 600 }}
        />,
        projectLocation.name !== '' && projectLocation.name !== null
          ? projectLocation.name
          : 'N/A',
      ];
    });
  };

  static renderContactName(contact) {
    let result = 'N/A';
    if (
      contact !== null &&
      (contact.first_name !== '' || contact.last_name !== '')
    ) {
      result = contact.first_name + ' ' + contact.last_name;
    }

    return result;
  }

  handleProjectLocationSelection = (index) => {
    this.props
      .dispatch(
        loadProjectLocationDetailsRequest(this.props.projectLocations[index].id)
      )
      .then((response) => {
        // When projectLocation is loaded successfully, go to projectLocation page
        if (response.ok) {
          simpleScrollToTop();
          this.props.dispatch(
            goToProjectLocation(this.props.projectLocations[index].id)
          );
        }
      });
  };

  handleProjectLocationsPageChange = (newPage) => {
    this.props.dispatch(loadProjectLocationsRequest(newPage));
  };

  render() {
    return (
      <div className="project-locations">
        <DetailsHeader
          leftHeader={{
            value: 'Project Locations',
          }}
        />

        <div className="content-container">
          <DetailsTitle
            rightButtons={
              ProjectLocationPermissions.canCreate(this.props.currentUser) && [
                <Button
                  key="invite-user"
                  type={BUTTON_TYPES.LINK}
                  icon={BUTTON_ICONS.PLUS}
                  label="Add Location"
                  onClick={this.handleAddProjectLocation}
                />,
              ]
            }
          />

          <div className="project-locations-table-container">
            <SimpleTable
              className="project-locations-table"
              headers={['ADDRESS', 'LOCATION NAME']}
              rows={this.mapProjectLocations(this.props.projectLocations)}
              emptyMessage="No Locations"
              onRowSelected={this.handleProjectLocationSelection}
              onGenerateLink={this.handleGenerateLink}
            />
          </div>

          <div className="pagination-container">
            <SimplePagination
              currentPage={this.props.projectLocationsCurrentPage}
              pageSize={this.props.projectLocationsPageSize}
              total={this.props.projectLocationsTotal}
              onPageChange={this.handleProjectLocationsPageChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    projectLocations: getProjectLocations(state),
    currentUser: getCurrentUser(state),
    projectLocationsCurrentPage: getProjectLocationsCurrentPage(state),
    projectLocationsTotal: getProjectLocationsTotal(state),
    projectLocationsPageSize: getProjectLocationsPageSize(state),
  };
})(ProjectLocations);
