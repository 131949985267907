import React, { Component } from 'react';
import './SidebarItem.css';
import PropTypes from 'prop-types';

class SidebarItem extends Component {
  static propTypes = {
    label: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    image: PropTypes.any,
    selected: PropTypes.bool,
    onSelected: PropTypes.func,
    onGenerateLink: PropTypes.func,
  };

  handleClick = (event) => {
    event.preventDefault();
    this.props.onSelected(this.props.id);
  };

  handleGenerateLink = () => {
    const { id, onGenerateLink } = this.props;
    return onGenerateLink && onGenerateLink(id);
  };

  render() {
    return (
      <div className="sidebar-item-row">
        <a
          href={this.handleGenerateLink()}
          className={
            'sidebar-item-cell' + (this.props.selected ? ' selected' : '')
          }
          onClick={this.handleClick}
        >
          <img src={this.props.image} alt="icon" />
          <span className={this.props.selected ? 'selected' : ''}>
            {this.props.label}
          </span>
        </a>
      </div>
    );
  }
}

export default SidebarItem;
