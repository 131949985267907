import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Grid, Row } from 'react-bootstrap';
import Loader from 'components/shared/loader/LoginLoader';
import './UserInvitations.css';
import ShowInvitation from './ShowInvitation';
import { Link } from 'react-router-dom';
import { getLoginBackgroundImage } from '../../../actions/loginBackgroundImage';
import logoPng from '../login/logo.png';
import { browserClassname } from 'services/utils/browser-util';
import SupportLink from '../SupportLink';

class UserInvitationsSent extends Component {
  static propTypes = {
    invitations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        permissions: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
            selected: PropTypes.bool,
          })
        ),
      })
    ),
    onRemove: PropTypes.func,
  };

  constructor() {
    super();
    this.state = {
      loadImage: true,
      loadImageError: false,
    };
  }

  componentDidMount() {
    if (!this.props.imageUrl) {
      this.props.getBackgroundImage();
      this.setState({
        loadImage: false,
        loadImageError: false,
      });
    }
  }

  componentDidUpdate() {
    if (
      (this.props.imageUrl || this.props.imageUrl === '') &&
      !this.state.loadImage
    ) {
      const imageLoader = new Image();
      imageLoader.src = this.props.imageUrl;
      imageLoader.onload = () => {
        this.setState({
          loadImage: true,
          loadImageError: false,
        });
      };

      imageLoader.onerror = () => {
        this.setState({
          loadImage: true,
          loadImageError: true,
        });
      };
    }
  }

  handleInvitationRemove = (id) => {
    this.props.onRemove(id);
  };

  render() {
    const backgroundImage =
      !this.state.loadImageError && this.props.imageUrl
        ? { backgroundImage: 'url(' + this.props.imageUrl + ')' }
        : {};
    return this.state.loadImage ? (
      <div className="user-invitations client" style={backgroundImage}>
        <Grid className="max-height">
          <div className="align-center">
            <Col md={9}>
              <div
                className={browserClassname({
                  firefox: 'form-container-firefox',
                  default: 'form-container',
                })}
              >
                <Col md={7} sm={9} className="left-panel">
                  <Row>
                    <Col md={12}>
                      <div className="logo">
                        <img src={logoPng} alt="Logo" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className="title">
                        <span className="title-1">Invites Sent</span>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <div className="subtitle">
                        <span>
                          You will be notified when the user accepts your
                          invite.
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      {this.props.invitations.map((invitation) => (
                        <ShowInvitation
                          user={invitation}
                          onDelete={this.handleInvitationRemove}
                          key={invitation.id}
                        />
                      ))}
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mobile-container-button">
                      <div className="button-container">
                        <Link to={'/'}>
                          <button className={'btn btn-select btn-invite'}>
                            Go to Dashboard
                          </button>
                        </Link>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} mdOffset={3}>
                      <div style={{ marginTop: 60 }} />
                      <SupportLink />
                    </Col>
                  </Row>
                </Col>
                <Col md={5} sm={3} className="right-panel">
                  <Row>
                    <Col md={12}>
                      <div style={{ marginTop: '80%' }} />
                      <div className="button-container">
                        <Link to={'/'}>
                          <button className={'btn btn-select btn-invite'}>
                            Go to Dashboard
                          </button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>
            </Col>
          </div>
        </Grid>
      </div>
    ) : (
      <Loader loadImage={this.state.loadImage} />
    );
  }
}

export default connect(
  (state) => {
    return {
      imageUrl: state.loginBackgroundImage.url,
    };
  },
  (dispatch) => {
    return {
      getBackgroundImage: () => dispatch(getLoginBackgroundImage()),
    };
  }
)(UserInvitationsSent);
