import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getDarkMode } from 'selectors/theme';
import './DocumentUpload.css';
import uploadSvg from './upload.svg';
import uploadSvgDark from './uploadDarkMode.svg';
import Dropzone from 'react-dropzone';
import convertHeicFile from '../../../services/utils/heic-converter';
import { validateFile } from '../../../services/utils/files-util';
import { loaderEnd, loaderStart } from '../../../actions/loader';
import { showErrorMessage } from '../../../actions/messages';

import { FILE_TYPES } from 'domain/files';
export { FILE_TYPES } from 'domain/files';

const DocumentUpload = (props) => {
  const {
    className,
    fileType,
    multiple,
    darkMode,
    onDrop,
    onDropAccepted,
    onDropRejected,
    showErrorMessage,
    actions,
  } = props;

  const handleDrop = (files) => {
    if (typeof onDrop === 'function') onDrop(files);
  };

  const validateAndConvertFile = async (file) => {
    if (validateFile(file.name, FILE_TYPES)) {
      const processedFile = await convertHeicFile(file);
      return processedFile;
    }
  };

  const convertAllFiles = async (files) => {
    const failedFiles = [];
    const filesArray = [...files];
    const successFiles = await Promise.all(
      filesArray
        .map(async (file) => {
          try {
            return await validateAndConvertFile(file);
          } catch (_e) {
            failedFiles.push(file);
          }
        })
        .filter((file) => file)
    );
    return { failedFiles, successFiles };
  };

  const handleDropAccepted = async (files) => {
    // First check what files are valid
    actions.loaderStart(); // start loading
    const conversionResult = await convertAllFiles(files);
    const { successFiles, failedFiles } = conversionResult;

    // Error message:
    const failedFileNames = failedFiles.map((file) => file.fileName);
    if (failedFiles && failedFiles.length > 0) {
      // If there are any heic files that could not be converted
      const msg = `Error while converting files ${failedFileNames.join(', ')}`;
      showErrorMessage(msg);
    }

    actions.loaderEnd();
    if (typeof onDropAccepted === 'function') onDropAccepted(successFiles);
  };

  const handleDropRejected = (files) => {
    if (typeof onDropRejected === 'function') onDropRejected(files);
  };

  return (
    <Dropzone
      className={`document-upload ${className || ''} ${
        darkMode ? 'dark-mode' : ''
      }`}
      accept={fileType.accept}
      multiple={multiple}
      onDrop={handleDrop}
      onDropAccepted={handleDropAccepted}
      onDropRejected={handleDropRejected}
    >
      <div className="document-drop-zone">
        <img
          className="document-drop-zone-icon"
          src={darkMode ? uploadSvgDark : uploadSvg}
          alt="upload"
        />
        {fileType.label && (
          <span className="document-drop-zone-label">{fileType.label}</span>
        )}
      </div>
    </Dropzone>
  );
};

DocumentUpload.propTypes = {
  className: PropTypes.string,
  fileType: PropTypes.shape({
    accept: PropTypes.string,
    label: PropTypes.string,
  }),
  multiple: PropTypes.bool,
  onDrop: PropTypes.func,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
};

DocumentUpload.defaultProps = {
  fileType: FILE_TYPES.ANY,
  multiple: false,
};

export default connect(
  (state) => ({ darkMode: getDarkMode(state) }),
  (dispatch) => ({
    actions: bindActionCreators({ loaderStart, loaderEnd }, dispatch),
    showErrorMessage: (message) => dispatch(showErrorMessage(message)),
  })
)(DocumentUpload);
