import moment from 'moment';

export const SCHEDULE_STATUS_OPTIONS = [
  { value: 'not_started', label: 'Not Started' },
  { value: 'on_schedule', label: 'On Schedule' },
  { value: 'ahead', label: 'Ahead' },
  { value: 'delay', label: 'Delay' },
  { value: 'complete', label: 'Complete' },
];

export const SCHEDULE_DELAY_STATUS = 'delay';
export const NO_COMPLIENCE = 'no';
export const SECTION_INFORMATION = 'information';
export const SECTION_STATUS = 'project_status';

export const FIELD_REPORT_FORM_INIT = {
  title: '',
  date: moment().format('YYYY-MM-DD'),
  arrival_time: moment().format('YYYY-MM-DD hh:mm A'),
  departure_time: null,
  weather_temperature: null,
  weather_condition: null,
  weather_humidity: null,
  weather_wind: null,
  weather_wind_direction: null,
  weather_precipitation: null,
  weather_remarks: '',
  description: '',
  trade: null,
  location: {
    latitude: 34.052026101343586,
    longitude: -118.2483483753089,
  },
  timezone: 'PST-08:00',
  timezone_name: 'Pacific Standard Time -08:00',
  site: null,
  area: null,
  observation_trade_count: null,
  observation_task: '',
  observation_task_remarks: '',
  observation_contract_complete: null,
  observation_schedule_status: null,
  observation_schedule_remarks: '',
  observation_design_complience: null,
  observation_design_remarks: '',
  observation_quality_complience: null,
  observation_quality_remarks: '',
  observation_safety_complience: null,
  observation_safety_remarks: '',
  observation_material_remarks: '',
  observation_equipment_remarks: '',
  observation_testing_remarks: '',
  observation_other_remarks: '',
  open_items: [],
  project_images: [],
  deleted_project_images: [],
  report_images: [],
  deleted_report_images: [],
  report_documents: [],
  deleted_report_documents: [],
  disclaimer: '',
  recipients: [],
  status: '',
};

class FieldReport {
  isCreator = (user, report) =>
    !!report.created_by && report.created_by.id === user.id;

  hasStatus = (report, wantedStatus) =>
    !!report && report.status === wantedStatus;

  isDraft = (report) => this.hasStatus(report, 'draft');

  isPublished = (report) => this.hasStatus(report, 'published');

  isDeleted = (report) => this.hasStatus(report, 'deleted');

  hasErrorInformaitonSection = (errors) => {
    const fieldsWithError = errors ? Object.keys(errors) : [];
    return fieldsWithError.includes('date');
  };

  hasErrorProjectStatusSection = (errors) => {
    const fieldsWithError = errors ? Object.keys(errors) : [];
    return fieldsWithError.some((field) =>
      [
        'observation_design_remarks',
        'observation_quality_remarks',
        'observation_safety_remarks',
        'observation_schedule_remarks',
      ].includes(field)
    );
  };
}

export default new FieldReport();
