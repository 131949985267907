import React, { Component } from 'react';
import './MenuOld.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Popover from 'react-awesome-popover';
import { getDarkMode } from 'selectors/theme';
import MenuItem, { ITEM_TYPE } from './MenuItemOld';
import moreOptionsSvg from './more-options.svg';
import moreOptionsDarkSvg from './more-options-dark.svg';

export const MENU_SELECTOR = {
  MORE_OPTIONS: 'more-options',
  MORE_OPTIONS_VERTICAL: 'more-options rotated-icon',
};

class Menu extends Component {
  static propTypes = {
    className: PropTypes.string,
    selector: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    items: PropTypes.oneOfType([PropTypes.arrayOf(ITEM_TYPE), PropTypes.func]),
    fetchItems: PropTypes.func,
    separator: PropTypes.bool,
    arrow: PropTypes.bool,
    closeOnSelect: PropTypes.bool,
    overlay: PropTypes.string,
    placement: PropTypes.string,
    darkModeMenu: PropTypes.bool,
    darkModeSelector: PropTypes.bool,
    renderSelector: PropTypes.func,
  };

  static defaultProps = {
    separator: true,
    closeOnSelect: true,
    arrow: true,
    overlay: null, //default is "rgba(0,0,0,0.5)"
    placement: 'bottom-end',
    darkModeMenu: false,
    darkModeSelector: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.isTemplateSelector =
      typeof props.selector === 'string' &&
      !!Object.keys(MENU_SELECTOR).find(
        (key) => MENU_SELECTOR[key] === props.selector
      );
  }

  handleItemSelect = (event, item) => {
    if (!item.onClick) return;

    if (this.props.closeOnSelect && item.closeOnSelect !== false)
      this.setState({ open: false });

    if (item.onClick) item.onClick(event);
  };

  renderItems() {
    let { items } = this.props;

    this.hasSubitems = false;

    if (typeof items === 'function') {
      items = items();
    }

    return items.map((item, index) => {
      this.hasSubitems =
        this.hasSubitems || (item.subitems && item.subitems.length > 0);
      return (
        <MenuItem
          key={index}
          item={{
            ...item,
            onClick: (e) => this.handleItemSelect(e, item),
          }}
          separator={this.props.separator}
          onSubitemClick={this.handleItemSelect}
        />
      );
    });
  }

  renderImageSelector = (image) => {
    return (
      <div className={`menu-image-sector-container`}>
        <img className="menu-image-sector" src={image} alt="selector" />
      </div>
    );
  };

  renderLabelSelector = () => {
    const { selector } = this.props;

    return <div className="menu-label-sector">{selector}</div>;
  };

  renderSelector = () => {
    const { selector, darkMode, darkModeSelector, renderSelector } = this.props;
    const { open } = this.state;

    if (renderSelector) {
      return renderSelector(open);
    }

    const isDark = darkMode || darkModeSelector;

    if (typeof selector !== 'string') {
      return selector;
    }

    switch (selector) {
      case MENU_SELECTOR.MORE_OPTIONS:
      case MENU_SELECTOR.MORE_OPTIONS_VERTICAL:
        return this.renderImageSelector(
          isDark ? moreOptionsDarkSvg : moreOptionsSvg
        );
      default:
        return this.renderLabelSelector();
    }
  };

  getSelectorClassName = () => {
    const { selector } = this.props;

    return this.isTemplateSelector ? selector : '';
  };

  handleOpen = () => {
    const { fetchItems } = this.props;

    const open = () => {
      this.setState({ open: true });
    };

    if (fetchItems) {
      fetchItems(open);
    } else {
      open();
    }
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      className,
      placement,
      darkMode,
      darkModeMenu,
      overlay,
      arrow,
    } = this.props;

    const isDark = darkMode || darkModeMenu;

    if (typeof this.props.items !== 'function' && this.props.items.length === 0)
      return null;

    const items = this.state.open && this.renderItems();

    return (
      <div
        className={`menu-old ${this.getSelectorClassName()} ${
          isDark ? 'dark-mode' : ''
        } ${this.state.open ? 'menu-open' : ''} ${className || ''}`}
      >
        <Popover
          placement={placement}
          arrowProps={{ className: 'popover-arrow' }}
          overlayColor={overlay}
          open={this.state.open}
          onOpen={this.handleOpen}
          onClose={this.handleClose}
          arrow={arrow}
        >
          {this.renderSelector()}
          <div
            className={`popover-children ${
              this.hasSubitems ? 'with-submenu' : ''
            }`}
          >
            {items}
          </div>
        </Popover>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(Menu);
