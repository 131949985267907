/**
 * Sort the array, based on the attribute of the array's objects.
 * Assumes each object contains the attribute.
 * The array is sorted in place.
 *
 * @param array
 * @param attribute
 * @param asc
 * @return {*}
 */
export function sortByAttribute(array, attribute, asc) {
  const ascOrder = asc !== undefined ? asc : true;

  return array.sort((a, b) => {
    if (a[attribute] < b[attribute]) {
      return ascOrder ? -1 : 1;
    }

    if (a[attribute] > b[attribute]) {
      return ascOrder ? 1 : -1;
    }

    return 0;
  });
}
