import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { Grid } from '@mui/material';
import MultipleSelector from 'components/shared/selector/MultipleSelector';
import FormControlBlock from 'components/shared/form/FormControlBlock';

import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getProjectSites } from 'components/admin/projects/details/project-site-area/store/selectors';
import { loadProjectSitesAreas } from 'components/admin/projects/details/project-site-area/store/action';

const ProjectPhotosFilterSiteAreas = (props) => {
  const {
    dispatch,
    sites,
    areas,
    onChange,
    currentProject,
    projectSites,
  } = props;

  const onSiteChange = (s) => onChange({ sites: s });

  const onAreaChange = (a) => onChange({ areas: a });

  useEffect(() => {
    if (currentProject.id) {
      if (!projectSites || projectSites.projectId !== currentProject.id) {
        dispatch(loadProjectSitesAreas(currentProject.id));
      }
    }
  }, []);

  const { siteOptions, areaOptions } = useMemo(() => {
    const siteOptions = [];
    const areaOptions = [];

    if (
      projectSites &&
      projectSites.sites &&
      currentProject &&
      projectSites.projectId === currentProject.id
    ) {
      projectSites.sites.forEach((s) => {
        let label = s.name;
        if (s.number && s.number !== '0') label = `#${s.number} - ${label}`;
        siteOptions.push({ value: s.id, label });
      });

      projectSites.areas.forEach((a) => {
        if (sites.find(({ value }) => value === a.project_site_id)) {
          let label = a.name;
          if (a.project_site_number && a.project_site_number !== '0')
            label += ` - #${a.project_site_number} - ${a.project_site_name}`;
          else label += ` - ${a.name}`;
          areaOptions.push({ value: a.id, label });
        }
      });
    }

    return { siteOptions, areaOptions };
  }, [projectSites, sites]);

  return (
    <Grid container spacing={1} flexDirection={'column'}>
      <Grid item>
        <FormControlBlock
          label={sites.length > 0 ? 'Locations' : ''}
          hideBottomLabel
          control={
            <MultipleSelector
              onChange={onSiteChange}
              value={sites || []}
              className="sites-dropdown"
              placeholder="Location"
              options={siteOptions}
            />
          }
        />
      </Grid>
      {sites && sites.length > 0 && areaOptions.length > 0 && (
        <Grid item>
          <FormControlBlock
            label={areas.length > 0 ? 'Areas' : ''}
            hideBottomLabel
            control={
              <MultipleSelector
                onChange={onAreaChange}
                value={areas || []}
                className="areas-dropdown"
                placeholder="Area"
                options={areaOptions}
              />
            }
          />
        </Grid>
      )}
    </Grid>
  );
};

export default connect((state) => ({
  currentProject: getCurrentProject(state),
  projectSites: getProjectSites(state),
}))(ProjectPhotosFilterSiteAreas);
