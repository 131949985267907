import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import './Button.css';
import plusSvg from 'images/mdi_plus-circle.svg';
import plusSvgDark from 'images/mdi_plus-circle-dark.svg';
import deleteSvg from 'images/deleteCross.svg';
import deleteSvgDark from 'images/deleteCrossDarkMode.svg';
import uploadSvg from 'images/upload.svg';
import uploadSvgDark from 'images/uploadSvgDark.svg';
import uploadSvgGreen from 'images/uploadSvg.svg';
import filterSvg from 'images/filterSvg.svg';
import filterSvgDark from 'images/filterDarkSvg.svg';
import editSvg from 'images/edit-pencil.svg';
import editSvgDark from 'images/edit-pencil-dark.svg';
import editWhiteSvg from 'images/edit-pencil-white.svg';
import editBlackSvg from 'images/edit-pencil-black.svg';
import reviseSvg from 'images/revise.svg';
import reviseSvgDark from 'images/reviseDark.svg';
import emailBlackSvg from 'images/email-black.svg';
import emailSvg from 'images/email.svg';
import emailSvgDark from 'images/emailDark.svg';
import pdfSvg from 'images/pdf.svg';
import pdfSvgDark from 'images/pdfDark.svg';
import downloadSvg from 'images/download.svg';
import downloadSvgDark from 'images/downloadDark.svg';
import downloadBlackSvg from 'images/download-black.svg';
import deleteBinBlackSvg from 'images/delete-black.svg';
import deleteBinSvg from 'images/delete.svg';
import deleteBinSvgDark from 'images/deleteDark.svg';
import searchSvg from 'images/search.svg';
import searchSvgDark from 'images/search-dark.svg';
import backSvg from 'images/left-arrow.svg';
import moveSvg from 'images/move.svg';
import markupSvg from 'images/markup.svg';
import markupNeonSvg from 'images/markup-neon.svg';
import markupGreenSvg from 'images/markup-green.svg';
import caretUpLight from 'images/caret-up-light.svg';
import caretUpDark from 'images/caret-up-dark.svg';
import caretDownLight from 'images/caret-down-light.svg';
import caretDownDark from 'images/caret-down-dark.svg';

export const BUTTON_TYPES = {
  DEFAULT: 'btn button',
  RECTANGLE: 'btn button rectangle-button',
  CONFIRMATION: 'btn button confirmation-button',
  COLLAPSABLE: 'btn button collapsable-button',
  LINK: 'link-button',
};

export const BUTTON_COLORS = {
  TRANSPARENT: 'button-transparent',
  GREEN: 'button-green',
  BLUE_GRAY: 'button-blue-grey',
  WHITE: 'button-white',
};

export const BUTTON_ICONS = {
  PLUS: { light: plusSvg, dark: plusSvgDark, size: 'normal' },
  DELETE: { light: deleteSvg, dark: deleteSvgDark, size: 'small' },
  UPLOAD: { light: uploadSvg, dark: uploadSvgDark, size: 'small' },
  FILTER: { light: filterSvg, dark: filterSvgDark, size: 'small' },
  EDIT: { light: editSvg, dark: editSvgDark, size: 'normal' },
  EDIT_BLACK: { light: editWhiteSvg, dark: editBlackSvg, size: 'small' },
  REVISE: { light: reviseSvg, dark: reviseSvgDark, size: 'normal' },
  EMAIL: { light: emailSvg, dark: emailSvgDark, size: 'normal' },
  EMAIL_BLACK: { light: emailBlackSvg, dark: emailBlackSvg, size: 'normal' },
  PDF: { light: pdfSvg, dark: pdfSvgDark, size: 'normal' },
  DOWNLOAD: { light: downloadSvg, dark: downloadSvgDark, size: 'normal' },
  DOWNLOAD_BLACK: {
    light: downloadBlackSvg,
    dark: downloadBlackSvg,
    size: 'normal',
  },
  DELETE_BIN: { light: deleteBinSvg, dark: deleteBinSvgDark, size: 'normal' },
  DELETE_BIN_BLACK: {
    light: deleteBinBlackSvg,
    dark: deleteBinBlackSvg,
    size: 'normal',
  },
  SEARCH: { light: searchSvg, dark: searchSvgDark, size: 'normal' },
  UPLOAD_GREEN: { light: uploadSvgGreen, dark: uploadSvgDark, size: 'normal' },
  BACK: { light: backSvg, dark: backSvg, size: 'normal' },
  MOVE: { light: moveSvg, dark: moveSvg, size: 'normal' },
  MARKUP: { light: markupSvg, dark: markupSvg, size: 'normal' },
  MARKUP_GREEN: { light: markupGreenSvg, dark: markupNeonSvg, size: 'normal' },
  ARROW_UP: { light: caretUpLight, dark: caretUpDark, size: 'normal' },
  ARROW_DOWN: { light: caretDownLight, dark: caretDownDark, size: 'normal' },
};

function Button(props) {
  const {
    type,
    color,
    className,
    disabled,
    label,
    icon,
    href,
    onClick,
    darkMode,
    iconLast,
  } = props;

  const handleClick = (event) => {
    event.preventDefault();
    if (onClick !== undefined) {
      onClick(event);
    }
  };

  const classes =
    type +
    (color !== undefined ? ' ' + color : '') +
    (className !== undefined ? ' ' + className : '') +
    (darkMode ? ' dark-mode' : '');

  let iconSrc =
    icon && typeof icon === 'object' ? icon[darkMode ? 'dark' : 'light'] : icon;
  let iconSize = (icon && typeof icon === 'object' && icon.size) || 'normal';

  const content =
    iconSrc !== null ? (
      iconLast ? (
        <React.Fragment>
          <span className="icon-last">{label}</span>
          <img
            src={iconSrc}
            className={`${iconSize}-button-icon icon-last`}
            alt="plus"
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <img src={iconSrc} className={`${iconSize}-button-icon`} alt="plus" />
          <span>{label}</span>
        </React.Fragment>
      )
    ) : (
      label
    );

  return href ? (
    <a className={classes} disabled={disabled} href={href}>
      {content}
    </a>
  ) : (
    <button className={classes} disabled={disabled} onClick={handleClick}>
      {content}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  href: PropTypes.string,
  onClick: PropTypes.func,
  iconLast: PropTypes.bool,
};

Button.defaultProps = {
  type: BUTTON_TYPES.DEFAULT,
  disabled: false,
  icon: null,
  href: null,
  label: '',
  iconLast: false,
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(Button);
