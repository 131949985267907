import { createSelector } from 'reselect';

export const getErrors = createSelector(
  (state) => state.registerUser.ui.errors,
  (errors) => {
    return errors;
  }
);

export const getForm = createSelector(
  (state) => state.registerUser.ui.form,
  (form) => {
    return form;
  }
);
