import axios from 'axios';
import numeral from 'numeral';
import BaseApi from './base-api';
import { toICalString } from '../utils/date-util';
import { setInstancePayload } from './project-new-meetings-instances-api';

class NewMeetingsApi extends BaseApi {
  getMeetingsParentTypes(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/meetings_parents_types`)
      .then((response) => {
        return {
          ok: true,
          meetingsParentsTypes: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
  getMeetingsParentTitles(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/meetings_parents_titles`)
      .then((response) => {
        return {
          ok: true,
          meetingsParentsTitles: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
  getActionItemsCategories(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/action_items_categories`)
      .then((response) => {
        return {
          ok: true,
          actionItemsCategories: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createActionItemCategory(projectId, values) {
    // const values = { actionItemsCategory: category };
    const data = this.setActionItemCategoryPayload(values);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/action_items_categories`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          actionItemsCategory: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
  createMeetingsParentsType(projectId, meetingType) {
    const values = { meetingsParentsType: meetingType };
    const data = this.setTypePayload(values);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/meetings_parents_types`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          meetingsParentsType: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
  createMeetingsParentsTitle(projectId, meetingTitle) {
    const values = { meetingsParentsTitle: meetingTitle };
    const data = this.setTitlePayload(values);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/meetings_parents_titles`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          meetingsParentsTitle: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createMeetingsCorrection(projectId, correction) {
    const data = this.setMeetingsCorrectionPayload(correction);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/meetings_corrections`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          meetingsCorrection: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
  createNonDirectoryAttendee(projectId, attendee) {
    const data = this.setNonDirectoryAttendeePayload(attendee);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/non_directory_attendees`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          nonDirectoryAttendee: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
  createActionItem(projectId, item) {
    const data = this.setActionItemPayload(item);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/meetings_action_items`,
        data,
        headers
      )
      .then((response) => {
        return { ok: true, meetingsActionItem: response.data };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
  updateActionItem(projectId, itemId, item) {
    const data = this.setActionItemPayload(item);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/meetings_action_items/${itemId}`,
        data,
        headers
      )
      .then((response) => {
        return { ok: true, meetingsActionItem: response.data };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
  updateNonDirectoryAttendee(projectId, attendeeId, attendee) {
    const data = this.setNonDirectoryAttendeePayload(attendee);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/non_directory_attendees/${attendeeId}`,
        data,
        headers
      )
      .then((response) => {
        return { ok: true, nonDirectoryAttendee: response.data };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
  updateMeetingsParentsType(projectId, typeId, newName) {
    const values = { meetingsParentsType: newName, typeId };
    const data = this.setTypePayload(values);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .patch(
        `${this.baseUrl}/projects/${projectId}/meetings_parents_types/${typeId}`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          meetingsParentsType: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
  updateMeetingsParentsTitle(projectId, titleId, newName) {
    const values = { meetingsParentsTitle: newName, titleId };
    const data = this.setTitlePayload(values);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .patch(
        `${this.baseUrl}/projects/${projectId}/meetings_parents_titles/${titleId}`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          meetingsParentsTitle: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
  deleteMeetingsParentsType(projectId, typeId) {
    return axios
      .delete(
        `${this.baseUrl}/projects/${projectId}/meetings_parents_types/${typeId}`
      )
      .then((response) => {
        return {
          ok: true,
          meetingsParents: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
  deleteMeetingsInstance(projectId, meetingsInstanceId) {
    return axios
      .delete(
        `${this.baseUrl}/projects/${projectId}/meetings_instances/${meetingsInstanceId}`
      )
      .then((response) => {
        return {
          ok: true,
          meetingsInstance: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
  deleteMeetingsParentsTitle(projectId, titleId) {
    return axios
      .delete(
        `${this.baseUrl}/projects/${projectId}/meetings_parents_titles/${titleId}`
      )
      .then((response) => {
        return {
          ok: true,
          meetingsParents: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getMeetingsParents(projectId, page, filters, instancesFilters = {}) {
    let filtersUrl = `page=${page}`;
    Object.entries(filters).forEach(([key, value]) => {
      if (!value) return;
      filtersUrl += `&${key}=${value}`;
    });
    Object.entries(instancesFilters).forEach(([key, value]) => {
      if (!value) return;
      // Prefix instance filters to differentiate them
      filtersUrl += `&instances_${key}=${value}`;
    });
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/meetings_parents?${filtersUrl}`
      )
      .then((response) => {
        return {
          ok: true,
          meetingsParents: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
  getMeetingsParentById(projectId, meetingsParentId) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/meetings_parents/${meetingsParentId}`
      )
      .then((response) => {
        return {
          ok: true,
          meetingsParent: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createMeetingsParent(projectId, values) {
    const data = this.setParentPayload(values);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };

    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/meetings_parents`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          meetingsParent: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateParentMeeting(projectId, meetingsParentId, values) {
    const data = this.setParentPayload(values);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/meetings_parents/${meetingsParentId}`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          meetingsParent: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error,
        };
      });
  }

  createActionItemUpdate = (projectId, values) => {
    const data = this.setActionItemUpdatePayload(values);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/action_items_updates`,
        data,
        headers
      )
      .then((response) => {
        return { ok: true, actionItemsUpdate: response.data };
      })
      .catch((error) => {
        return { ok: false, errors: error.response.data.errors };
      });
  };
  updateActionItemUpdate = (projectId, updateId, values) => {
    const data = this.setActionItemUpdatePayload(values);
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/action_items_updates/${updateId}`,
        data,
        headers
      )
      .then((response) => {
        return { ok: true, actionItemsUpdate: response.data };
      })
      .catch((error) => {
        return { ok: false, errors: error.response.data.errors };
      });
  };
  setParentPayload(values) {
    const apiPayload = new FormData();
    if (values.onlineMeeting || values.onlineMeeting === false) {
      apiPayload.append(
        'meetings_parent[online_meeting]',
        values.onlineMeeting
      );
    }
    if (values.projectId) {
      apiPayload.append('meetings_parent[project_id]', values.projectId);
    }
    if (values.meetingType) {
      apiPayload.append('meetings_parent[meeting_type]', values.meetingType);
    }
    if (values.previousState) {
      apiPayload.append(
        'meetings_parent[previous_state]',
        values.previousState
      );
    }
    if (values.latestInstanceId) {
      apiPayload.append(
        'meetings_parent[latest_instance_id]',
        values.latestInstanceId
      );
    }
    if (values.title) {
      apiPayload.append('meetings_parent[title]', values.title);
    }
    if (values.nextMeeting) {
      apiPayload.append('meetings_parent[next_meeting]', values.nextMeeting);
    }

    if (values.nextMeetingTime) {
      apiPayload.append(
        'meetings_parent[next_meeting_time]',
        values.nextMeetingTime
      );
    }
    if (
      values.sendNextMeetingEditNotification ||
      values.sendNextMeetingEditNotification === false
    ) {
      apiPayload.append(
        'meetings_parent[send_next_meeting_edit_notification]',
        values.sendNextMeetingEditNotification
      );
    }
    if (values.address) {
      apiPayload.append('meetings_parent[address]', values.address);
    }
    if (values.location) {
      apiPayload.append('meetings_parent[location]', values.location);
    }
    if (values.jobWalk) {
      apiPayload.append('meetings_parent[job_walk]', values.jobWalk);
    }
    if (values.access) {
      apiPayload.append('meetings_parent[access]', values.access);
    }
    if (values.ranking) {
      apiPayload.append('meetings_parent[ranking]', values.ranking);
    }
    if (values.callInfo) {
      apiPayload.append('meetings_parent[call_info]', values.callInfo);
    }
    if (values.callLink) {
      apiPayload.append('meetings_parent[call_link]', values.callLink);
    }
    if (values.webLink) {
      apiPayload.append('meetings_parent[web_link]', values.webLink);
    }
    if (values.otherInfo) {
      apiPayload.append('meetings_parent[other_info]', values.otherInfo);
    }
    if (values.parking) {
      apiPayload.append('meetings_parent[parking]', values.parking);
    }
    if (values.privateMeeting || values.privateMeeting === false) {
      apiPayload.append(
        'meetings_parent[private_meeting]',
        values.privateMeeting
      );
    }
    if (values.startDate) {
      apiPayload.append('meetings_parent[start_date]', values.startDate);
    }
    if (values.endDate) {
      apiPayload.append('meetings_parent[end_date]', values.endDate);
    }
    if (values.recurrence) {
      const iCal = toICalString(values.recurrence);
      apiPayload.append('meetings_parent[recurrence]', iCal);
    }
    if (values.startTime) {
      apiPayload.append('meetings_parent[start_time]', values.startTime);
    }
    if (values.timezone) {
      apiPayload.append('meetings_parent[timezone]', values.timezone);
    }
    if (values.endTime) {
      apiPayload.append('meetings_parent[end_time]', values.endTime);
    }
    if (values.meetingExceptions && values.meetingExceptions.length > 0) {
      values.meetingExceptions.forEach((exception, index) => {
        apiPayload.append(`meetings_parent[meeting_exceptions][]`, exception);
      });
    }

    if (values.createdById) {
      apiPayload.append('meetings_parent[created_by_id]', values.createdById);
    }
    if (values.submittedById) {
      apiPayload.append(
        'meetings_parent[submitted_by_id]',
        values.submittedByIdd
      );
    }

    if (values.publishedById) {
      apiPayload.append(
        'meetings_parent[published_by_id]',
        values.publishedById
      );
    }
    if (values.attendees && values.attendees.length > 0) {
      values.attendees.forEach((usr) => {
        apiPayload.append('meetings_parent[attendee_ids][]', usr.id);
      });
    }
    if (values.status) {
      apiPayload.append('meetings_parent[status]', values.status);
    }
    if (values.previousStatus) {
      apiPayload.append('meetings_parent[status]', values.previousStatus);
    }
    if (values.meetingsCorrections && values.meetingsCorrections.length > 0) {
      values.meetingsCorrections.forEach((mc, mcIndex) => {
        if (mc.id) {
          apiPayload.append(
            `meetings_parent[meetings_corrections_attributes][${mcIndex}][id]`,
            mc.id
          );
        }
        if (mc.status) {
          apiPayload.append(
            `meetings_parent[meetings_corrections_attributes][${mcIndex}][status]`,
            mc.status
          );
        }
        if (mc.meetingsParentId) {
          apiPayload.append(
            `meetings_parent[meetings_corrections_attributes][${mcIndex}][meetings_parent_id]`,
            mc.meetingsParentId
          );
        }
        if (mc.meetingsInstanceId) {
          apiPayload.append(
            `meetings_parent[meetings_corrections_attributes][${mcIndex}][meetings_instance_id]`,
            mc.meetingsInstanceId
          );
        }
        if (mc.meetingsActionItemId) {
          apiPayload.append(
            `meetings_parent[meetings_corrections_attributes][${mcIndex}][meetings_action_item_id]`,
            mc.meetingsActionItemId
          );
        }
        if (mc.correctionRemark) {
          apiPayload.append(
            `meetings_parent[meetings_corrections_attributes][${mcIndex}][correction_remark]`,
            mc.correctionRemark
          );
        }
        if (mc.correctionRemark) {
          apiPayload.append(
            `meetings_parent[meetings_corrections_attributes][${mcIndex}][correction_remark]`,
            mc.correctionRemark
          );
        }
        if (mc.submittedById) {
          apiPayload.append(
            `meetings_parent[meetings_corrections_attributes][${mcIndex}][submitted_by_id]`,
            mc.submittedById
          );
        }
        if (mc.createdById) {
          apiPayload.append(
            `meetings_parent[meetings_corrections_attributes][${mcIndex}][created_by_id]`,
            mc.createdById
          );
        }
        if (mc.resolvedById) {
          apiPayload.append(
            `meetings_parent[meetings_corrections_attributes][${mcIndex}][resolved_by_id]`,
            mc.resolvedById
          );
        }
        if (mc.createdAt) {
          apiPayload.append(
            `meetings_parent[meetings_corrections_attributes][${mcIndex}][created_at]`,
            mc.createdAt
          );
        }
        if (mc.resolvedAt) {
          apiPayload.append(
            `meetings_parent[meetings_corrections_attributes][${mcIndex}][resolved_at]`,
            mc.resolvedAt
          );
        }
        if (mc.meetingCorrectionNo) {
          apiPayload.append(
            `meetings_parent[meetings_corrections_attributes][${mcIndex}][meeting_correction_no]`,
            mc.meetingCorrectionNo
          );
        }
      });
    }

    /* Quick start*/
    const transformKey = (originalKey) => {
      // Detect and maintain array notation '[]' in keys
      const isArray = originalKey.endsWith('[]');
      // Remove the 'meetings_instance[' prefix and ']' suffix, if present
      let cleanKey = originalKey
        .replace(/^meetings_instance\[/, '')
        .replace(/\]$/, '');
      // If the key is an array, remove the '[]' notation for now to ease processing
      if (isArray) {
        cleanKey = cleanKey.replace(/\[\]$/, '');
      }

      // For nested keys, adjust their structure
      if (cleanKey.includes('[')) {
        // Split the key into parts for nested attributes
        const parts = cleanKey.split(/\]\[|\[|\]/).filter((p) => p);
        // Reconstruct the key with proper nesting for the parent payload
        const newKey = `meetings_parent[meetings_instances_attributes][0][${parts.join(
          ']['
        )}]`;
        // Re-add the array notation '[]' at the end if the original key was an array
        return isArray ? `${newKey}[]` : newKey;
      } else {
        // For non-nested keys, simply prefix with the parent structure
        return `meetings_parent[meetings_instances_attributes][0][${cleanKey}]${
          isArray ? '[]' : ''
        }`;
      }
    };

    if (
      values.meetingsInstances &&
      values.meetingsInstances.length > 0 &&
      values.meetingsInstances[0].isQuickStart
    ) {
      const instancePayload = setInstancePayload(values.meetingsInstances[0]);
      for (const [key, value] of instancePayload.entries()) {
        const modifiedKey = transformKey(key);

        apiPayload.append(modifiedKey, value);
      }
    }
    /*End of Quick start */
    return apiPayload;
  }

  setTypePayload = (values) => {
    const apiPayload = new FormData();
    if (values.meetingsParentsType) {
      apiPayload.append(
        'meetings_parents_type[name]',
        values.meetingsParentsType
      );
      apiPayload.append('meetings_parents_type[archived]', false);
    }
    if (values.typeId) {
      apiPayload.append('meetings_parents_type[id]', values.typeId);
    }
    return apiPayload;
  };
  setTitlePayload = (values) => {
    const apiPayload = new FormData();
    if (values.meetingsParentsTitle) {
      apiPayload.append(
        'meetings_parents_title[name]',
        values.meetingsParentsTitle
      );
      apiPayload.append('meetings_parents_title[archived]', false);
    }
    if (values.titleId) {
      apiPayload.append('meetings_parents_title[id]', values.titleId);
    }
    return apiPayload;
  };
  setActionItemCategoryPayload = (values) => {
    const apiPayload = new FormData();
    if (values.name) {
      apiPayload.append('action_items_category[name]', values.name);
    }
    if (values.projectId) {
      apiPayload.append('action_items_category[project_id]', values.projectId);
    }
    if (values.meetingsParentId) {
      apiPayload.append(
        'action_items_category[meetings_parent_id]',
        values.meetingsParentId
      );
    }
    if (values.meetingsInstanceId) {
      apiPayload.append(
        'action_items_category[meetings_instance_id]',
        values.meetingsInstanceId
      );
    }
    return apiPayload;
  };
  setNonDirectoryAttendeePayload = (values) => {
    const apiPayload = new FormData();
    if (values.firstName) {
      apiPayload.append('non_directory_attendee[first_name]', values.firstName);
    }
    if (values.lastName) {
      apiPayload.append('non_directory_attendee[last_name]', values.lastName);
    }
    if (values.company) {
      apiPayload.append('non_directory_attendee[company]', values.company);
    }
    if (values.title) {
      apiPayload.append('non_directory_attendee[title]', values.title);
    }
    if (values.meetingsParentId) {
      apiPayload.append(
        'non_directory_attendee[meetings_parent_id]',
        values.meetingsParentId
      );
    }
    if (values.meetingsInstanceId) {
      apiPayload.append(
        'non_directory_attendee[meetings_instance_id]',
        values.meetingsInstanceId
      );
    }
    return apiPayload;
  };
  setActionItemPayload = (values) => {
    const apiPayload = new FormData();

    if (values.id && typeof values.id !== 'string') {
      apiPayload.append('meetings_action_item[id]', values.id);
    }

    if (values.resolvedById) {
      apiPayload.append(
        'meetings_action_item[resolved_by_id]',
        values.resolvedById
      );
    }
    if (values.isResolved || values.isResolved === false) {
      apiPayload.append('meetings_action_item[is_resolved]', values.isResolved);
    }
    if (values.isRestored) {
      apiPayload.append('meetings_action_item[is_restored]', values.isRestored);
    }
    if (values.isDiscarded) {
      apiPayload.append(
        'meetings_action_item[is_discarded]',
        values.isDiscarded
      );
    }
    if (values.meetingsParentId) {
      apiPayload.append(
        'meetings_action_item[meetings_parent_id]',
        values.meetingsParentId
      );
    }
    if (values.meetingsInstanceId) {
      apiPayload.append(
        'meetings_action_item[meetings_instance_id]',
        values.meetingsInstanceId
      );
    }
    if (values.description) {
      apiPayload.append(
        'meetings_action_item[description]',
        values.description
      );
    }
    if (values.dueDate) {
      apiPayload.append('meetings_action_item[due_date]', values.dueDate);
    }
    if (values.itemNumber) {
      apiPayload.append('meetings_action_item[item_number]', values.itemNumber);
    }
    if (values.formattedNumber) {
      apiPayload.append(
        'meetings_action_item[formatted_number]',
        values.formattedNumber
      );
    }
    if (
      values.responsibleId ||
      values.responsibleId === undefined ||
      values.responsibleId === null
    ) {
      apiPayload.append(
        'meetings_action_item[responsible_id]',
        values.responsibleId
      );
    }
    if (values.responsibleCompanyId) {
      apiPayload.append(
        'meetings_action_item[responsible_company_id]',
        values.responsibleCompanyId
      );
    }
    if (values.priority) {
      apiPayload.append('meetings_action_item[priority]', values.priority);
    }
    if (values.status) {
      apiPayload.append('meetings_action_item[status]', values.status);
    }
    if (values.category) {
      apiPayload.append('meetings_action_item[category]', values.category);
    }
    if (values.actionItemsUpdates && values.actionItemsUpdates.length > 0) {
      values.actionItemsUpdates.forEach((actionItemUpdate, updateIndex) => {
        // Assuming camelToSnake is a function you've defined to convert camelCase keys to snake_case
        Object.keys(actionItemUpdate).forEach((key) => {
          if (
            key !== 'id' &&
            key !== 'nestedUpdates' &&
            key !== 'responsible' &&
            key !== 'responsibleCompany'
          )
            // Skip id and actionItemsUpdates fields
            apiPayload.append(
              `meetings_action_item[action_items_updates_attributes][${updateIndex}][${key}]`,
              actionItemUpdate[key]
            );
        });
      });
    }
    return apiPayload;
  };
  setActionItemUpdatePayload(values) {
    const apiPayload = new FormData();
    if (values.id && typeof values.id === 'number') {
      apiPayload.append('action_items_update[id]', values.id);
    }
    if (values.resolvedById) {
      apiPayload.append(
        'action_items_update[resolved_by_id]',
        values.resolvedById
      );
    }
    if (values.isResolved || values.isResolved === false) {
      apiPayload.append('action_items_update[is_resolved]', values.isResolved);
    }
    if (values.parentUpdateId) {
      apiPayload.append(
        'action_items_update[parent_update_id]',
        values.parentUpdateId
      );
    }
    if (values.isRestored) {
      apiPayload.append('action_items_update[is_restored]', values.isRestored);
    }
    if (values.isDiscarded) {
      apiPayload.append(
        'action_items_update[is_discarded]',
        values.isDiscarded
      );
    }
    if (values.meetingsActionItemId) {
      apiPayload.append(
        'action_items_update[meetings_action_item_id]',
        values.meetingsActionItemId
      );
    }
    if (values.meetingsParentId) {
      apiPayload.append(
        'action_items_update[meetings_parent_id]',
        values.meetingsParentId
      );
    }
    if (values.meetingsInstanceId) {
      apiPayload.append(
        'action_items_update[meetings_instance_id]',
        values.meetingsInstanceId
      );
    }
    if (values.description) {
      apiPayload.append('action_items_update[description]', values.description);
    }
    if (values.dueDate) {
      apiPayload.append('action_items_update[due_date]', values.dueDate);
    }
    if (
      values.responsibleId ||
      values.responsibleId === undefined ||
      values.responsibleId === null
    ) {
      apiPayload.append(
        'action_items_update[responsible_id]',
        values.responsibleId
      );
    }
    if (values.responsibleCompanyId) {
      apiPayload.append(
        'action_items_update[responsible_company_id]',
        values.responsibleCompanyId
      );
    }
    if (values.priority) {
      apiPayload.append('action_items_update[priority]', values.priority);
    }
    if (values.status) {
      apiPayload.append('action_items_update[status]', values.status);
    }
    return apiPayload;
  }
  setMeetingsCorrectionPayload = (values) => {
    const apiPayload = new FormData();
    if (values.correctionRemark) {
      apiPayload.append(
        'meetings_correction[correction_remark]',
        values.correctionRemark
      );
    }
    if (values.createdAt) {
      apiPayload.append('meetings_correction[created_at]', values.createdAt);
    }
    if (values.submittedById) {
      apiPayload.append(
        'meetings_correction[submitted_by_id]',
        values.submittedById
      );
    }
    if (values.createdById) {
      apiPayload.append(
        'meetings_correction[created_by_id]',
        values.createdById
      );
    }
    if (values.meetingsParentId) {
      apiPayload.append(
        'meetings_correction[meetings_parent_id]',
        values.meetingsParentId
      );
    }
    if (values.meetingsInstanceId) {
      apiPayload.append(
        'meetings_correction[meetings_instance_id]',
        values.meetingsInstanceId
      );
    }
    if (values.projectId) {
      apiPayload.append('meetings_correction[project_id]', values.projectId);
    }
    if (values.status) {
      apiPayload.append('meetings_correction[status]', values.status);
    }
    if (values.meetingCorrectionNo) {
      apiPayload.append(
        'meetings_correction[meeting_correction_no]',
        values.meetingCorrectionNo
      );
    }
    return apiPayload;
  };

  sendNextMeetingChangeEmail(projectId, parentId, emails, subject, message) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const url = `${this.baseUrl}/projects/${projectId}/meetings_parents/${parentId}/email`;

    let apiPayload = new FormData();
    emails.map((item) => apiPayload.append(`meetings_parent[emails][]`, item));
    apiPayload.append(`meetings_parent[subject]`, subject);
    apiPayload.append(`meetings_parent[message]`, message);
    return axios
      .post(url, apiPayload, headers)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => {
        return { ok: false, errors: error.response.data.errors };
      });
  }
}

export const getMeetingsAgendaBackupById = (
  projectId,
  meetingsAgendaBackupId
) => {
  return axios
    .get(
      `${this.baseUrl}/projects/${projectId}/meetings_agenda_backups/${meetingsAgendaBackupId}`
    )
    .then((response) => {
      return {
        ok: true,
        meetingsAgendaBackup: response.data,
      };
    })
    .catch((error) => {
      return {
        ok: false,
        errors: error.response.data.errors,
      };
    });
};

export const newMeetingsApi = new NewMeetingsApi();
