import { showErrorMessage } from 'actions/messages';
import { ADD_PROJECT_TAG, SET_PROJECT_TAGS } from './action-types';
import { projectTagsApi } from 'services/api/project-tags-api';

export const setProjectTags = (tags) => {
  return { type: SET_PROJECT_TAGS, payload: tags };
};
export const addProjectTags = (tag) => {
  return { type: ADD_PROJECT_TAG, payload: tag };
};

export const loadProjectTags = (projectId) => {
  return (dispatch) => {
    return projectTagsApi.getAll(projectId).then((response) => {
      if (!response.ok) dispatch(showErrorMessage(response.errors));
      else {
        dispatch(setProjectTags(response.data));
      }
    });
  };
};
export const createProjectTag = (projectId, tagLabel) => {
  return (dispatch) => {
    return projectTagsApi.save(projectId, tagLabel).then((response) => {
      if (!response.ok) dispatch(showErrorMessage(response.errors));
      else dispatch(addProjectTags(response.data));

      return response;
    });
  };
};
