export function isFirefox() {
  return navigator.userAgent.includes('Firefox');
}

export function browserClassname(classnames) {
  if (isFirefox()) {
    return classnames.firefox;
  } else {
    return classnames.default;
  }
}
