import React from 'react';

import { Grid } from '@mui/material';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import { TYPE_OPTIONS } from 'domain/project-photo';

const ProjectPhotosFilterType = (props) => {
  const { value, liked, onChange } = props;

  const onFilter = (type) => (s) => {
    const filters = {};
    if (type === 'liked') filters.liked = s;
    else filters.type = s ? type : null;

    onChange(filters);
  };

  return (
    <Grid container spacing={5}>
      {TYPE_OPTIONS.map((o) => (
        <Grid item key={'type_' + o.id}>
          <LabeledCheckBox
            checked={value === o.id || (liked && o.id === 'liked')}
            position="left"
            size="medium"
            label={o.label}
            onChange={onFilter(o.id)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProjectPhotosFilterType;
