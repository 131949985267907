import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import './InviteUserModal.css';
import Input from 'components/shared/input/Input';
import Selector from 'components/shared/selector/Selector';
import CheckBox from 'components/shared/checkbox/CheckBox';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import FormModal from 'components/shared/modal/FormModal';

export const UserModalState = {
  NEW: 'new',
  EDIT: 'edit',
  RESEND_INVITE: 'resendInvite',
  READ_ONLY: 'readOnly',
};

class InviteUserModal extends Component {
  static propTypes = {
    className: PropTypes.string,
    form: PropTypes.arrayOf(
      PropTypes.shape({
        attribute: PropTypes.string.isRequired,
        formatter: PropTypes.func,
        label: PropTypes.string.isRequired,
        // renderer: PropTypes.oneOf(['input', 'dropdown', 'multiple']).isRequired,
        renderer: PropTypes.oneOfType([
          PropTypes.oneOf(['input', 'dropdown', 'multiple']),
          PropTypes.node,
        ]).isRequired,
        show: PropTypes.bool, // default to true
        options: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.string,
          })
        ),
      })
    ).isRequired,
    invalidFields: PropTypes.array,
    modalState: PropTypes.oneOf(Object.values(UserModalState)).isRequired,
    show: PropTypes.bool.isRequired,
    validationMessage: PropTypes.string,
    values: PropTypes.shape().isRequired,
    onAttributeChange: PropTypes.func,
    onClose: PropTypes.func,
    onDeleteConfirmation: PropTypes.func,
    onEdit: PropTypes.func,
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    className: null,
    invalidFields: [],
    rolesInput: 'single',
    validationMessage: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      showConfirmation: false,
    };
  }

  handleClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
  };

  handleAttributeChange = (attribute, value) => {
    if (this.props.onAttributeChange) {
      this.props.onAttributeChange(attribute, value);
    }
  };

  handleMultipleSelected = (attribute, value) => {
    if (this.props.onAttributeChange) {
      let newValue;
      if (
        this.props.values[attribute].find((element) => element === value) !==
        undefined
      ) {
        newValue = this.props.values[attribute].filter(
          (element) => element !== value
        );
      } else {
        newValue = this.props.values[attribute].concat([value]);
      }
      this.props.onAttributeChange(attribute, newValue);
    }
  };

  handleEdit = () => {
    if (this.props.onEdit) {
      this.props.onEdit();
    }
  };

  handleDelete = () => {
    this.setState({
      showConfirmation: true,
    });
  };

  handleDeleteConfirmation = (result) => {
    this.setState({
      showConfirmation: false,
    });

    if (this.props.onDeleteConfirmation) {
      this.props.onDeleteConfirmation(result);
    }
  };

  render() {
    return (
      <div>
        <FormModal
          className={
            'invite-user-modal' +
            (this.props.className !== null ? ' ' + this.props.className : '')
          }
          show={this.props.show}
          subTitle={
            this.props.modalState === UserModalState.NEW
              ? 'Invite User'
              : 'Edit User'
          }
          onClose={this.handleClose}
        >
          <Form horizontal onSubmit={this.handleSubmit}>
            {this.props.form
              .filter((item) => item.show === undefined || item.show)
              .map((item, index) => {
                return item.renderer ? (
                  <div key={index} className="form-block">
                    {typeof item.renderer === 'string' ? (
                      <React.Fragment>
                        <label className="form-block-label">
                          {item.renderer !== 'checkbox' ? item.label : ''}
                        </label>
                        {this.renderItem(item)}
                      </React.Fragment>
                    ) : (
                      item.renderer
                    )}
                  </div>
                ) : null;
              })}

            {this.props.validationMessage !== '' && (
              <div className="invalid-message">
                {this.props.validationMessage}
              </div>
            )}

            <div className="buttons-container">
              {this.props.modalState !== UserModalState.READ_ONLY ? (
                <Button
                  type={BUTTON_TYPES.CONFIRMATION}
                  color={BUTTON_COLORS.GREEN}
                  label={
                    this.props.modalState === UserModalState.NEW
                      ? 'Invite'
                      : 'Update'
                  }
                  onClick={this.handleSubmit}
                />
              ) : (
                <React.Fragment>
                  <Button
                    type={BUTTON_TYPES.CONFIRMATION}
                    color={BUTTON_COLORS.GREEN}
                    id="edit-user"
                    label="Edit"
                    onClick={this.handleEdit}
                  />
                  <Button
                    type={BUTTON_TYPES.CONFIRMATION}
                    color={BUTTON_COLORS.GREEN}
                    id="delete-user"
                    label="Delete"
                    onClick={this.handleDelete}
                  />
                </React.Fragment>
              )}
            </div>
          </Form>
        </FormModal>

        <ConfirmationModal
          show={this.state.showConfirmation}
          message="Are you sure?"
          onHide={this.handleDeleteConfirmation}
        />
      </div>
    );
  }

  renderItem(item) {
    if (item.renderer === 'input') {
      const readOnly = this.props.modalState === UserModalState.READ_ONLY;
      return (
        <Input
          id={item.attribute}
          placeholder={item.label}
          readOnly={readOnly}
          theme="normal"
          type={item.attribute === 'email' ? 'email' : 'text'}
          valid={
            this.props.invalidFields.find(
              (element) => element === item.attribute
            ) === undefined
          }
          value={
            readOnly
              ? item.formatter
                ? item.formatter(this.props.values[item.attribute])
                : this.props.values[item.attribute]
              : this.props.values[item.attribute]
          }
          onChange={this.handleAttributeChange.bind(this, item.attribute)}
        />
      );
    } else if (item.renderer === 'dropdown') {
      return (
        <Selector
          id={item.attribute}
          options={item.options}
          placeholder={item.label}
          hideUnderline={this.props.modalState === UserModalState.READ_ONLY}
          readOnly={this.props.modalState === UserModalState.READ_ONLY}
          theme="normal"
          valid={
            this.props.invalidFields.find(
              (element) => element === item.attribute
            ) === undefined
          }
          value={this.props.values[item.attribute]}
          onChange={(newOption) =>
            newOption
              ? this.handleAttributeChange(item.attribute, newOption.value)
              : ''
          }
        />
      );
    } else if (item.renderer === 'checkbox') {
      return (
        <LabeledCheckBox
          readOnly={this.props.modalState === UserModalState.READ_ONLY}
          id={item.attribute}
          checked={this.props.values[item.attribute]}
          size="medium"
          position="left"
          label={item.label}
          onChange={(value) =>
            this.handleAttributeChange(item.attribute, value)
          }
        />
      );
    } else if (item.renderer === 'multiple') {
      return (
        <div className="multiple-container">
          {item.options.map((option) => {
            return (
              <div key={option.value}>
                <CheckBox
                  checked={
                    this.props.values[item.attribute].find(
                      (element) => element === option.value
                    ) !== undefined
                  }
                  id={option.value}
                  readOnly={this.props.modalState === UserModalState.READ_ONLY}
                  shape="square"
                  size="medium"
                  onChange={this.handleMultipleSelected.bind(
                    this,
                    item.attribute
                  )}
                />
                <span className="small-regular">{option.label}</span>
              </div>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default InviteUserModal;
