import moment from 'moment';
import css from './MeetingsInstanceDocumentHtmlStyle';

const renderMeetingCorrections = (corrections) => {
  if (corrections && corrections.length > 0) {
    return `
      <p class="details-grey-sub-section-header">The following Meeting Corrections have been submitted for the previous Meeting Minutes.</p>
      <table class="corrections-table">
        <thead>
          <tr class="corrections-row">
            <th class="corrections-cell">ITEM NO</th>
            <th class="corrections-cell">DESCRIPTION</th>
            <th class="corrections-cell">RESPONSIBLE</th>
            <th class="corrections-cell">DUE DATE</th>
            <th class="corrections-cell">PRIORITY</th>
            <th class="corrections-cell">STATUS</th>
          </tr>
        </thead>
        <tbody>
          ${corrections
            .map(
              (item) => `
            <tr class="corrections-row">
              <td class="corrections-cell">${
                item.meetingCorrectionNo || '--'
              }</td>
              <td class="corrections-cell">${item.correctionRemark || '--'}</td>
              <td class="corrections-cell">${
                item.submittedBy ? item.submittedBy.fullName : '--'
              }</td>
              <td class="corrections-cell">${item.createdAt || '--'}</td>
              <td class="corrections-cell">${item.priority || '--'}</td>
              <td class="corrections-cell">${item.status || '--'}</td>
            </tr>`
            )
            .join('')}
        </tbody>
      </table>
    `;
  }
  return `
      <div class="row">
        <div class="col">No corrections have been submitted.</div>
      </div>
    `;
};

const renderProjectRecords = (form) => {
  if (
    form.hasDirectoryPdf ||
    form.hasOpenItemsLogPdf ||
    form.hasRfaLogPdf ||
    form.hasSubmittalLogPdf ||
    form.hasMeetingsCorrectionsPdf
  ) {
    return `
      <p class="details-grey-sub-section-header">The following Project records have been attached to these minutes</p>
      <div class="bullet-list-container">
        <ul class="bullet-list">
          ${
            form.hasDirectoryPdf
              ? '<li class="bullet-point">Directory</li>'
              : ''
          }
          ${
            form.hasOpenItemsLogPdf
              ? '<li class="bullet-point">Open Items Log</li>'
              : ''
          }
        </ul>
        <ul class="bullet-list">
          ${form.hasRfaLogPdf ? '<li class="bullet-point">RFA Log</li>' : ''}
          ${form.has_rfi_log_pdf ? '<li class="bullet-point">RFI Log</li>' : ''}
        </ul>
        <ul class="bullet-list">
          ${
            form.hasSubmittalLogPdf
              ? '<li class="bullet-point">Submittal Log</li>'
              : ''
          }
          ${
            form.hasMeetingsCorrectionsPdf
              ? '<li class="bullet-point">Meetings Corrections</li>'
              : ''
          }
        </ul>
      </div>
    `;
  }
  return `
      <div class="row">
        <div class="col">No Project resources have been attached to these minutes.</div>
      </div>
    `;
};

const groupAttendees = (form) => {
  if (!form.attendees || form.attendees.length === 0) return [];
  let groupedAttendees = {};
  groupedAttendees = form.attendees.forEach((user) => {
    const companyName = user.accountableName;
    if (!groupedAttendees[companyName]) {
      groupedAttendees[companyName] = [];
    }
    groupedAttendees[companyName].push(user);
  });
  return groupedAttendees;
};

const renderAttendees = (groupedAttendees, attendeeDetails) => {
  if (!groupedAttendees) return [];
  if (!attendeeDetails) return [];
  if (Object.keys(groupedAttendees).length === 0) return [];
  return Object.entries(groupedAttendees)
    .map(
      ([companyName, attendees]) => `
      <div class="row company-name">
        <div class="col bold-font">${companyName}</div>
        <div class="col"></div>
        <div class="col"></div>
        <div class="col"></div>
        <div class="col"></div>
      </div>
      ${attendees
        .map((attendee) => {
          const attendanceStatus = attendeeDetails[attendee.id.toString()];
          const inPerson = attendanceStatus === 'present_in_person';
          const remote = attendanceStatus === 'present_remote';
          const absent = attendanceStatus === 'absent';
          const distribution = attendanceStatus === 'distribution';
          return `
          <div class="row attendee-name">
            <div class="col-attendee-table">${attendee.label}</div>
            <div class="col-attendee-table">${inPerson ? 'YES' : '--'}</div>
            <div class="col-attendee-table">${remote ? 'YES' : '--'}</div>
            <div class="col-attendee-table">${absent ? 'YES' : '--'}</div>
            <div class="col-attendee-table">${distribution ? 'YES' : '--'}</div>
          </div>`;
        })
        .join('')}
    `
    )
    .join('');
};

const renderActionItems = (items) =>
  items
    .map((item) => {
      const itemIsOverdue = item.dueDate
        ? new Date() > moment().isAfter(moment(item.dueDate))
        : false;
      const itemIsUrgent = item.dueDate ? item.priority === 'urgent' : false;
      const updates = item.actionItemsUpdates
        ? renderUpdates(item.actionItemsUpdates)
        : '';

      return `
      <tr class="action-item-row">
        <td class="action-item-cell">${item.formattedNumber || '--'}</td>
        <td class="action-item-cell">${item.description || '--'}</td>
        <td class="action-item-cell">${
          item.responsible ? 'responsible' : '--'
        }</td>
        <td class="action-item-cell ${itemIsOverdue ? 'overdue' : ''}">${
        item.dueDate ? moment(item.dueDate).format('MM/DD/YYYY') : '--'
      }</td>
        <td class="action-item-cell ${itemIsUrgent ? 'urgent' : ''}">${
        item.priority
          ? item.priority.charAt(0).toUpperCase() + item.priority.slice(1)
          : '--'
      }</td>
        <td class="action-item-cell">${item.status || '--'}</td>
      </tr>
      ${updates}
    `;
    })
    .join('');

const renderUpdates = (updates) =>
  updates
    .map((update) => {
      const updateIsOverdue = update.dueDate
        ? moment().isAfter(moment(update.dueDate))
        : false;
      const updateIsUrgent = update.dueDate
        ? update.priority === 'urgent'
        : false;
      const nestedUpdates = update.nestedUpdates
        ? renderNestedUpdates(update.nestedUpdates)
        : '';

      return `
      <tr class="action-item-row">
        <td class="action-item-cell"></td>
        <td class="action-item-cell">${
          update.createdAt
            ? `${moment(update.createdAt).format('DD/MM/YYYY')} ${
                update.description
              }`
            : update.description || ''
        }</td>
        <td class="action-item-cell">${
          update.responsible ? 'responsible' : '--'
        }</td>
        <td class="action-item-cell ${updateIsOverdue ? 'overdue' : ''}">${
        update.dueDate ? moment(update.dueDate).format('DD/MM/YYYY') : '--'
      }</td>
        <td class="action-item-cell ${updateIsUrgent ? 'urgent' : ''}">${
        update.priority
          ? update.priority.charAt(0).toUpperCase() + update.priority.slice(1)
          : '--'
      }</td>
        <td class="action-item-cell">${update.status || '--'}</td>
      </tr>
      ${nestedUpdates}
    `;
    })
    .join('');

const renderNestedUpdates = (nestedUpdates) =>
  nestedUpdates
    .map((nested) => {
      const nestedIsOverdue = nested.dueDate
        ? moment().isAfter(moment(nested.dueDate))
        : false;
      const nestedIsUrgent = nested.dueDate
        ? nested.priority === 'urgent'
        : false;

      return `
      <tr class="action-item-row">
        <td class="action-item-cell"></td>
        <td class="action-item-cell">${
          nested.createdAt
            ? `*${moment(nested.createdAt).format('MM/DD/YYYY')} ${
                nested.description
              }`
            : nested.description || ''
        }</td>
        <td class="action-item-cell">${
          nested.responsible ? 'responsible' : '--'
        }</td>
        <td class="action-item-cell ${nestedIsOverdue ? 'overdue' : ''}">${
        nested.dueDate ? new Date(nested.dueDate).toLocaleDateString() : '--'
      }</td>
        <td class="action-item-cell ${nestedIsUrgent ? 'urgent' : ''}">${
        nested.priority
          ? nested.priority.charAt(0).toUpperCase() + nested.priority.slice(1)
          : '--'
      }</td>
        <td class="action-item-cell">${nested.status || '--'}</td>
      </tr>
    `;
    })
    .join('');

const html = (form, project, currentUser) => {
  const groupedAttendees = groupAttendees(form);
  let { attendeeDetails } = form;
  if (typeof attendeeDetails !== 'object') {
    attendeeDetails = form.attendeeDetails
      ? JSON.parse(form.attendeeDetails)
      : {};
  }
  return `
<!DOCTYPE html>
 <html lang="en">
 <head>
 ${css}
   <meta charset="UTF-8">
   <title>Meeting ${form.isAgenda ? 'Agenda' : 'Minutes'}</title>
   <link rel="stylesheet" href="project_report_pdf.css"> 
   <script>
     function number_pages() {
       var vars = {};
       var x = document.location.search.substring(1).split('&');
       for (var i in x) {
         var z = x[i].split('=', 2);
         vars[z[0]] = unescape(z[1]);
       }
       var x = ['topage', 'page'];
       for (var i in x) {
         var y = document.getElementsByClassName(x[i]);
         for (var j = 0; j < y.length; ++j) y[j].textContent = vars[x[i]];
       }
     }
   </script>
 </head>
 <body onload="number_pages()">
   <div class="project-report-header">
      <div class="first">
        <h2>${project.number} ${project.name}</h2>
        <h3>${project.project_location.location.street_address_1}, ${
    project.project_location.location.street_address_2
  }, ${project.project_location.location.city}, ${
    project.project_location.location.state
  } ${project.project_location.location.zip}</h3>
      </div>
      <div class="second">
        <h2> Meetings ${form.isAgenda ? 'Agenda' : 'Minutes'} <span> #${
    form.formattedNumber || '--'
  }</span> </h2>
        
      </div>
   </div>
   <div class="log">
   <div class="log-body">
     <div class="row">
       <div class="col-small">Meeting Title:</div>
       <div class="col-small">${form.title || '--'}</div>
       <div class="col-small">Type:</div>
       <div class="col-small">${form.meetingType || '--'}</div>
     </div>
     <div class="row">
       <div class="col-small">Meeting Date:</div>
       <div class="col-small">${form.instanceDate || '--'}</div>
       <div class="col-small">Job Walk:</div>
       <div class="col-small">${form.jobWalk || '--'}</div>
     </div>
     <div class="row">
       <div class="col-small">Address:</div>
       <div class="col-small">${form.address || '--'}</div>
       <div class="col-small">Location:</div>
       <div class="col-small">${form.location || '--'}</div>
     </div>
     <div class="row">
       <div class="col-smaller">Call Info:</div>
       <div class="col-bigger">${form.callInfo || '--'}</div>
     </div>
     <div class="row">
       <div class="col-smaller">Next meeting:</div>
       <div class="col-bigger">${form.nextMeeting || '--'}</div>
     </div>
     <div class="row">
     <div class="col-attendee-table-header">Attendees</div>
     <div class="col-attendee-table-header">In Person</div>
     <div class="col-attendee-table-header">Remote</div>
     <div class="col-attendee-table-header">Absent</div>
     <div class="col-attendee-table-header">Distribution</div>
   </div>
   ${renderAttendees(groupedAttendees, attendeeDetails)}
     <p class="details-sub-section-header">Action Items</p>
     <table class="action-item-table-html">
     <thead>
       <tr class="action-item-row">
         <th class="action-item-cell">ITEM NO</th>
         <th class="action-item-cell">DESCRIPTION</th>
         <th class="action-item-cell">RESPONSIBLE</th>
         <th class="action-item-cell">DUE DATE</th>
         <th class="action-item-cell">PRIORITY</th>
         <th class="action-item-cell">STATUS</th>
       </tr>
     </thead>
     ${
       form.meetingsActionItems && form.meetingsActionItems.length > 0
         ? renderActionItems(form.meetingsActionItems)
         : '<tr><td colspan="6" class="action-item-cell">No Action Items submitted.</td></tr>'
     }
     </tbody>
     </table>
     <p class="details-sub-section-header">Action Items</p>
     ${renderProjectRecords(form)}
     <p class="details-sub-section-header">Meeting Corrections Items</p>

     ${renderMeetingCorrections(form.meetingsCorrections)}
     <p class="details-sub-section-header">Disclaimer</p>
     <p class="simple-text">${form.disclaimer || '--'}</p>
${
  form.attachedImages && form.attachedImages.length > 0
    ? `
  <div class="project-report-photos avoid-break">
    <div class="images-body avoid-break">
      <div class="header">Photos</div>
      <div class="container flexrow avoid-break">
        ${form.attachedImages
          .map(
            (image) => `
          <div class="img-content ${image.portrait ? 'portrait' : 'landscape'}">
            <img src="${image.uri}" alt="Report Image">
            <p class="img-description">${image.description || ''}</p>
          </div>
        `
          )
          .join('')}
      </div>
    </div>
  </div>
`
    : ''
}
<div class="padding-bottom"></div>
 </body>
 </html>
 `;
};

export default html;
