import { push } from 'react-router-redux';
import { showErrorMessage } from 'actions/messages';
import {
  PROJECTS_LOAD_SUCCESS,
  PROJECTS_MISSING_DRS_LOAD_SUCCESS,
  PROJECTS_BIDS_DUE_LOAD_SUCCESS,
  PROJECTS_PAGE_CHANGE,
  PROJECTS_MISSING_DRS_PAGE_CHANGE,
  PROJECTS_BIDS_DUE_PAGE_CHANGE,
  PROJECTS_NAV_CHANGE,
  PROJECTS_MISSING_DRS_COUNT,
  PROJECTS_BIDS_DUE_COUNT,
  PROJECTS_SHOW_ARCHIVE_CONFIRMATION_MODAL,
} from 'components/admin/projects/store/action-types';
import { projectsApi } from 'services/api/projects-api';

export const baseRoutePath = '/admin/projects';

export function addProject() {
  return (dispatch) => {
    dispatch(push(`${baseRoutePath}/new-project`));
  };
}

export function loadProjectsRequest(filters) {
  return (dispatch) => {
    return projectsApi.getProjects(filters).then((response) => {
      if (response.ok) {
        dispatch(loadProjectsSuccess(response.projects));
        dispatch(changePage(filters.page));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadMissingDrsRequest(filters) {
  return (dispatch) => {
    return projectsApi.getProjects(filters).then((response) => {
      if (response.ok) {
        dispatch(loadMissingDrsSuccess(response.projects));
        dispatch(changeMissingDrsPage(filters.page));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadBidsDueRequest(filters) {
  return (dispatch) => {
    return projectsApi.getProjects(filters).then((response) => {
      if (response.ok) {
        dispatch(loadBidsDueSuccess(response.projects));
        dispatch(changeBidsDuePage(filters.page));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadProjectsSuccess(projects) {
  return {
    type: PROJECTS_LOAD_SUCCESS,
    payload: projects,
  };
}

export function loadMissingDrsSuccess(projects) {
  return {
    type: PROJECTS_MISSING_DRS_LOAD_SUCCESS,
    payload: projects,
  };
}

export function loadBidsDueSuccess(projects) {
  return {
    type: PROJECTS_BIDS_DUE_LOAD_SUCCESS,
    payload: projects,
  };
}

export function changePage(newPage) {
  return {
    type: PROJECTS_PAGE_CHANGE,
    payload: newPage,
  };
}

export function changeMissingDrsPage(newPage) {
  return {
    type: PROJECTS_MISSING_DRS_PAGE_CHANGE,
    payload: newPage,
  };
}

export function changeBidsDuePage(newPage) {
  return {
    type: PROJECTS_BIDS_DUE_PAGE_CHANGE,
    payload: newPage,
  };
}

export function changeNav(newNav) {
  return {
    type: PROJECTS_NAV_CHANGE,
    payload: newNav,
  };
}

export function goToProjects() {
  return (dispatch) => {
    dispatch(push(baseRoutePath));
  };
}

export function goToProjectDetails(projectId, params, target) {
  return (dispatch) => {
    dispatch(
      push({
        pathname: `${baseRoutePath}/${projectId}/${target || 'home'}`,
        state: params,
      })
    );
  };
}

export function goToEditProject(projectId) {
  return (dispatch) => {
    dispatch(push(`${baseRoutePath}/edit/${projectId}`));
  };
}

export function missingDrsCountSuccess(count) {
  return {
    type: PROJECTS_MISSING_DRS_COUNT,
    payload: count,
  };
}

export function missingDrsCount(filters) {
  return (dispatch) => {
    return projectsApi.getProjectsCount(filters).then((response) => {
      if (response.ok) {
        dispatch(missingDrsCountSuccess(response.count));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function bidsDueCountSuccess(count) {
  return {
    type: PROJECTS_BIDS_DUE_COUNT,
    payload: count,
  };
}

export function bidsDueCount(filters) {
  return (dispatch) => {
    return projectsApi.getProjectsCount(filters).then((response) => {
      if (response.ok) {
        dispatch(bidsDueCountSuccess(response.count));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function dailyReportsReminder(id) {
  return (dispatch) => {
    return projectsApi.dailyReportsReminder(id).then((response) => {
      if (!response.ok) {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function setShowArchiveConfirmation(show) {
  return {
    type: PROJECTS_SHOW_ARCHIVE_CONFIRMATION_MODAL,
    payload: show,
  };
}
