import {
  SET_TRADE_CODES,
  SET_UNIONS,
  SET_ROLES,
  SET_SIMILAR_COMPANIES,
} from './action-types';

export const initialState = {
  trade_codes: [],
  unions: [],
  roles: [],
  similar_companies: [],
};

export default function registrationUiReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TRADE_CODES:
      return {
        ...state,
        trade_codes: action.payload,
      };

    case SET_UNIONS:
      return {
        ...state,
        unions: action.payload,
      };

    case SET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };

    case SET_SIMILAR_COMPANIES:
      return {
        ...state,
        similar_companies: action.payload,
      };

    default:
      return state;
  }
}
