import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import vector from './vector.svg';
import vectorDark from './vector-dark.svg';

export const ITEM_TYPE = PropTypes.shape({
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  onClick: PropTypes.func,
  closeOnSelect: PropTypes.bool,
});
ITEM_TYPE.subitems = PropTypes.arrayOf(ITEM_TYPE);

class MenuItem extends Component {
  static propTypes = {
    item: ITEM_TYPE.isRequired,
    separator: PropTypes.bool,
    onSubitemClick: PropTypes.func,
  };

  constructor(props) {
    super(props);
    if (this.hasSubitems()) {
      this.state = {
        submenuOpen: false,
      };
    }
  }

  hasSubitems = () => {
    const { item } = this.props;
    return item.subitems && item.subitems.length > 0;
  };

  handleItemSelect = (event) => {
    const { item } = this.props;
    if (item.onClick) {
      item.onClick(event);
    }
  };

  handleSubitemSelect = (event, subitem) => {
    const { onSubitemClick } = this.props;
    onSubitemClick(event, subitem);
  };

  handleToggleSubmenu = (event) => {
    event.stopPropagation();
    this.setState({ submenuOpen: !this.state.submenuOpen });
  };

  render() {
    const { item, separator, onSubitemClick, darkMode } = this.props;

    const hasSubitems = this.hasSubitems();
    const submenuOpen = hasSubitems && this.state.submenuOpen;

    return (
      <React.Fragment>
        <div
          className={`menu-item ${item.className || ''} ${
            submenuOpen ? 'submenu-open' : ''
          }`}
          style={{
            paddingLeft: item.child ? `${item.child * 32}px` : null,
          }}
          onClick={this.handleItemSelect}
        >
          {item.content}
          {hasSubitems && (
            <div
              className="menu-item-arrow-container"
              onClick={this.handleToggleSubmenu}
            >
              <img
                className="menu-item-arrow"
                src={darkMode ? vectorDark : vector}
                alt="arrow"
              />
            </div>
          )}
        </div>
        {separator && <div className="menu-item-separator"></div>}
        {submenuOpen && (
          <div className="submenu">
            {item.subitems.map((subitem, index) => (
              <MenuItem
                key={index}
                item={{
                  ...subitem,
                  onClick: (e) => this.handleSubitemSelect(e, subitem),
                }}
                separator={separator}
                onSubitemClick={onSubitemClick}
              />
            ))}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(MenuItem);
