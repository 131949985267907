import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Reports.css';
import { goToWorkOrder } from 'actions/admin/work-orders/work-orders';
import {
  getReportResults,
  getExtraFilterValues,
} from 'selectors/admin/reports/reports';
import { changeExtraFilterValue } from 'actions/admin/reports/reports';
import BaseReport from 'components/admin/reports/BaseReport';
import Date from 'components/shared/text/Date';
import Number from 'components/shared/text/Number';
import Input from 'components/shared/input/Input';

class ReceiptsReport extends Component {
  handleRowSelection = (index) => {
    this.props.dispatch(
      goToWorkOrder(this.props.reportResults.data[index].work_order.id)
    );
  };

  createTableRowsData = () => {
    if (this.props.reportResults !== null) {
      let woNumber = null;
      return this.props.reportResults.data.map((result, index) => {
        let first = result.work_order.number !== woNumber;
        const rowData = {
          id: index,
          selectable: true,
          data: [
            first ? result.work_order.number : '',
            first
              ? result.work_order_job !== null
                ? result.work_order_job
                : 'N/A'
              : '',
            first ? result.work_order.name : '',
            first ? result.work_order.client.name : '',
            result.task.number,
            result.task.name,
            <Date value={result.created_at} format="LL" />,
            <Number value={result.cost} format="$0,0.[00]" />,
            result.description,
          ],
        };
        if (!first) {
          rowData['className'] = 'no-top-border';
        }
        woNumber = result.work_order.number;
        return {
          rowData,
          first,
          workOrderId: result.work_order.id,
        };
      });
    } else {
      return [];
    }
  };

  handleExtraFilterValueChange = (attribute, value) => {
    this.props.dispatch(changeExtraFilterValue(attribute, value));
  };

  extraFilters = () => {
    return [
      <Input
        type="number"
        placeholder="Min. Cost"
        value={this.props.extraFilterValues['min_cost']}
        onChange={(value) =>
          this.handleExtraFilterValueChange('min_cost', value)
        }
      />,
      <Input
        type="number"
        placeholder="Max. Cost"
        value={this.props.extraFilterValues['max_cost']}
        onChange={(value) =>
          this.handleExtraFilterValueChange('max_cost', value)
        }
      />,
    ];
  };

  render() {
    return (
      <BaseReport
        reportName="receipts_report"
        tableHeader={[
          'WO #',
          'JOB #',
          'NAME',
          'OWNER NAME',
          'TASK #',
          'TASK NAME',
          'UPLOADED DATE',
          'AMOUNT',
          'DESCRIPTION',
        ]}
        tableRowsData={this.createTableRowsData()}
        title="Materials"
        onRowSelection={this.handleRowSelection}
        extraFilters={this.extraFilters()}
      />
    );
  }
}

export default connect((state) => {
  return {
    reportResults: getReportResults(state),
    extraFilterValues: getExtraFilterValues(state),
  };
})(ReceiptsReport);
