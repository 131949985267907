import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'components/admin/companies/CompaniesDetails.css';
import UserStatus from 'components/shared/text/UserStatus';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import {
  getCompanyData,
  getUserList,
  getUserForm,
  getShowInviteUserModal,
  getShowConfirmationDeleteModal,
  getVerificationModal,
} from 'components/admin/companies/store/selectors';
import SimpleTable from 'components/shared/table/SimpleTable';
import { formatPhoneNumber } from 'services/utils/text-util';
import {
  setCurrentUserId,
  setShowEditUserModal,
  loadUserForm,
  editUser,
  deleteUser,
  inviteUser,
  setShowConfirmationModal,
  setShowVerificationModal,
  verifyUser,
} from 'components/admin/companies/store/actions';
import { activationLink } from 'components/admin/projects/details/project-directory/store/actions';
import UserModal from 'components/admin/companies/UserModal';
import { clearUserData } from 'components/admin/companies/store/actions';
import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import Menu, { MENU_SELECTOR } from 'components/shared/menu/Menu';
import { usersApi } from 'services/api/users-api';
import { getCurrentUser } from 'selectors/authentication';
import SearchBar from 'components/shared/navigation/SearchBar';
import queryString from 'query-string';
import { showErrorMessage } from 'actions/messages';
import User from 'domain/user';
import DirectoryPermissions from 'permissions/directory-permissions';
import MessageModal from 'components/shared/modal/MessageModal';

class UserTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nameToSearch: '',
    };
  }

  componentDidMount() {
    this.handleVerificationLink();
  }

  handleVerificationLink = () => {
    const { users, dispatch } = this.props;
    const params = queryString.parse(window.location.search);
    let { verification_user, verification_action } = params;
    if (
      !verification_user ||
      !['verify', 'reject'].includes(verification_action)
    )
      return;

    verification_user = parseInt(verification_user, 10);
    const user = users.find((user) => user.id === verification_user);
    if (!user) {
      dispatch(showErrorMessage('Verifying user not found'));
    } else if (
      (verification_action === 'verify' && User.isEnabled(user)) ||
      (verification_action === 'reject' && !User.isVerifying(user))
    ) {
      dispatch(showErrorMessage('User verification already resolved'));
    } else {
      dispatch(setShowVerificationModal(true, user, verification_action));
    }
  };

  handleCloseEditUserModal = () => {
    const { dispatch } = this.props;
    dispatch(setShowEditUserModal(false));
  };

  handleInviteClient = () => {
    const { dispatch } = this.props;
    dispatch(clearUserData());
    dispatch(setShowEditUserModal(true));
  };

  handleEditUserOnSubmit = (values) => {
    const { dispatch, companyData, currentUser, user } = this.props;
    if (DirectoryPermissions.canSetAccountAdmin(currentUser))
      values[
        values.accountAdmin ? 'assignedAccountAdmin' : 'removedAccountAdmin'
      ] = true;

    if (user) {
      dispatch(editUser(values, companyData.id));
    } else {
      dispatch(inviteUser(values, companyData.id));
    }
  };

  handleDeleteEditUserModal = (response) => {
    const { user, dispatch, companyData } = this.props;
    if (response) {
      dispatch(deleteUser(user, companyData.id));
    }
    dispatch(setShowConfirmationModal(false));
  };

  handleShowConfirmationDelete = () => {
    const { dispatch } = this.props;
    dispatch(setShowConfirmationModal(true));
  };

  handleUserSelection = (user) => {
    const { dispatch } = this.props;
    dispatch(setCurrentUserId(user.id));
    dispatch(loadUserForm(user));
    dispatch(setShowEditUserModal(true));
  };

  handleResendInvite = (id) => {
    usersApi.resendInvite(id);
  };

  handleRejectUser = (user) => {
    this.props.dispatch(setShowVerificationModal(true, user, 'reject'));
  };

  handleVerifyUser = (user) => {
    this.props.dispatch(setShowVerificationModal(true, user, 'verify'));
  };

  handleCloseVerificationModal = (confirmed) => {
    const {
      verificationModal: { user, action },
      dispatch,
    } = this.props;

    dispatch(
      confirmed ? verifyUser(user, action) : setShowVerificationModal(false)
    );
  };

  handleGetActivationLink = (user) => {
    this.props.dispatch(activationLink(user)).then((response) => {
      if (response.ok) {
        this.setState({
          activationUser: `${user.first_name} ${user.last_name}`,
          activationLink: response.instructions.accept_invitation_url,
        });
      }
    });
  };

  hideActivationLinkSuccess = () => {
    this.setState({ activationUser: '', activationLink: '' });
  };

  renderActivationLinkSuccess = () => {
    const activationUser = !!this.state && this.state.activationUser;
    const activationLink = !!this.state && this.state.activationLink;
    return (
      !!activationUser &&
      !!activationLink && (
        <MessageModal
          show={!!activationUser && !!activationLink}
          message={
            <div className="activation-link">
              Activation Link for {activationUser}:<br />
              {activationLink}
            </div>
          }
          onHide={this.hideActivationLinkSuccess}
        />
      )
    );
  };

  actionBtns(currentUser, user) {
    const canInvite = DirectoryPermissions.canReSendUserInvite(
      currentUser,
      user
    );
    const canEdit = DirectoryPermissions.canEditCompanyUser(currentUser);

    const items = [
      canEdit && {
        content: 'Edit User',
        onClick: () => this.handleUserSelection(user),
      },
      canInvite && {
        content: 'Resend Invite',
        onClick: () => this.handleResendInvite(user.id),
      },
      canInvite && {
        content: 'Activation Link',
        onClick: () => this.handleGetActivationLink(user),
      },
      User.isVerifying(user) && {
        content: 'Deny Access',
        onClick: () => this.handleRejectUser(user),
      },
      !User.isEnabled(user) && {
        content: 'Approve',
        onClick: () => this.handleVerifyUser(user),
      },
    ].filter((item) => item);

    return (
      <div className="action-buttons">
        {items.length > 0 && (
          <Menu selector={MENU_SELECTOR.MORE_OPTIONS} items={items} />
        )}
      </div>
    );
  }

  mapUsers = (users) => {
    const { currentUser } = this.props;
    const { nameToSearch } = this.state;

    const nameToSearchLower = nameToSearch.toLowerCase();
    return users
      .map((user) => ({
        id: user.id,
        data: [
          user.first_name !== null ? User.fullName(user) : 'N/A',
          user.title,
          User.companyRoleFullLabel(user),
          <UserStatus status={user.status} />,
          (user.projects_in_progress_count || 0) +
            (user.projects_complete_count || 0),
          user.email || 'N/A',
          `${formatPhoneNumber(user.phone_number)}${
            user.phone_ext ? ` ext ${user.phone_ext}` : ''
          }`,
          this.actionBtns(currentUser, user),
        ],
      }))
      .filter(
        ({ data: [userName] }) =>
          !userName || userName.toLowerCase().indexOf(nameToSearchLower) >= 0
      );
  };

  handleSearchTextChange = (nameToSearch) => this.setState({ nameToSearch });

  render() {
    const {
      users,
      showInviteUserModal,
      showConfirmationDeleteModal,
      verificationModal,
    } = this.props;
    const { nameToSearch } = this.state;

    return (
      <div>
        {users && (
          <React.Fragment>
            <SearchBar
              addButton={
                <Button
                  key="invite-user"
                  type={BUTTON_TYPES.LINK}
                  icon={BUTTON_ICONS.PLUS}
                  label="Invite User"
                  onClick={this.handleInviteClient}
                />
              }
              searchText={nameToSearch}
              onSearchTextChange={this.handleSearchTextChange}
              onSearch={this.handleSearchTextChange}
            />
            <div className="users-table">
              <SimpleTable
                className="companies-table"
                headers={[
                  'NAME',
                  'TITLE',
                  'ROLE',
                  'STATUS',
                  'PROJECTS',
                  'EMAIL',
                  'PHONE',
                  'ACTIONS',
                ]}
                rows={this.mapUsers(users)}
                emptyMessage="No Users"
              />
            </div>
          </React.Fragment>
        )}
        {showInviteUserModal && (
          <UserModal
            show={true}
            handleSubmit={this.handleEditUserOnSubmit}
            handleClose={this.handleCloseEditUserModal}
            handleDelete={this.handleShowConfirmationDelete}
            initialValues={this.props.user}
          />
        )}
        {showConfirmationDeleteModal && (
          <ConfirmationModal
            show={showConfirmationDeleteModal}
            message={'Are you sure you want to delete this user?'}
            onHide={this.handleDeleteEditUserModal}
          />
        )}

        {verificationModal && (
          <ConfirmationModal
            show={true}
            message={
              verificationModal.action === 'reject'
                ? 'Are you sure you want to deny user access?'
                : 'Are you sure you want to verify user?'
            }
            onHide={this.handleCloseVerificationModal}
          />
        )}
        {this.renderActivationLinkSuccess()}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    companyData: getCompanyData(state),
    users: getUserList(state),
    user: getUserForm(state),
    showInviteUserModal: getShowInviteUserModal(state),
    showConfirmationDeleteModal: getShowConfirmationDeleteModal(state),
    currentUser: getCurrentUser(state),
    verificationModal: getVerificationModal(state),
  };
})(UserTable);
