import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CloseoutForm from './CloseoutForm';
import { connect } from 'react-redux';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCloseout } from 'components/admin/projects/details/project-closeout/store/selectors';
import {
  getCloseoutCats,
  getCloseoutCompanyTypes,
  getCloseoutCatsCompanyTypes,
  getCloseoutCatShowDeleteConfirmation,
  getCloseoutCatSelected,
  getCloseoutCatShowModal,
} from 'components/admin/projects/details/project-closeout/store/selectors';
import {
  deleteCloseoutCatRequest,
  updateCloseoutCatsOrderRequest,
  setCloseoutCatsOrderChange,
  hideCloseoutCatModal,
  hideCloseoutCatDeletionConfirmation,
} from 'components/admin/projects/details/project-closeout/store/actions';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import CloseoutCatFormModal from 'components/admin/projects/details/project-closeout/CloseoutCatFormModal';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import CloseoutCatsTable from 'components/admin/projects/details/project-closeout/CloseoutCatsTable';
import CloseoutCatsByCompanyTable from 'components/admin/projects/details/project-closeout/CloseoutCatsByCompanyTable';
import './CloseoutRequirements.css';

class CloseoutRequirements extends Component {
  static propTypes = {
    currentTab: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedCloseoutCat: null,
      showRenameCloseoutCatModal: false,
    };
  }

  headers = () => {
    const { closeoutCompanyTypes } = this.props;

    if (closeoutCompanyTypes) {
      let headers = closeoutCompanyTypes
        .sort((a, b) => a.id - b.id)
        .map((type) => type.name.toUpperCase());

      headers.unshift('CLOSEOUT ITEM');
      headers.push('ACTION');
      return headers;
    }
  };

  isDataLoaded = () => {
    const { closeoutCatsCompanyTypes, closeoutCats } = this.props;
    return closeoutCatsCompanyTypes.length > 0 && closeoutCats.length > 0;
  };

  handleHideCloseoutCatDeletionConfirmation = (response) => {
    const { dispatch, currentProject, closeoutCatSelected } = this.props;
    if (!!response) {
      dispatch(
        deleteCloseoutCatRequest(currentProject.id, closeoutCatSelected.id)
      );
    } else {
      dispatch(hideCloseoutCatDeletionConfirmation());
    }
  };

  closeoutCatDeletionConfirmationMessage = () => {
    const { closeoutCatSelected } = this.props;
    return `Are you sure you want to delete ${closeoutCatSelected.label}?`;
  };

  onDragEnd = (result) => {
    const { dispatch, currentProject } = this.props;
    const { destination, source } = result;
    if (!destination || destination.index === source.index) {
      return;
    }

    dispatch(setCloseoutCatsOrderChange(result)).then(() => {
      dispatch(
        updateCloseoutCatsOrderRequest(
          currentProject.id,
          this.props.closeoutCats
        )
      );
    });
  };

  handleCloseRenameCloseoutCat = () => {
    const { dispatch } = this.props;
    dispatch(hideCloseoutCatModal());
  };

  render() {
    const {
      closeoutCatShowDeleteConfirmation,
      closeoutCatSelected,
      closeoutCatShowModal,
      currentTab,
    } = this.props;

    return (
      <React.Fragment>
        <CloseoutForm currentTab={currentTab} />
        {this.isDataLoaded() && (
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="closeout-cats">
              {(provided) => (
                <CloseoutCatsTable
                  headers={this.headers()}
                  innerRef={provided.innerRef}
                  provided={provided}
                />
              )}
            </Droppable>
          </DragDropContext>
        )}
        <CloseoutCatsByCompanyTable />
        {closeoutCatShowDeleteConfirmation && (
          <ConfirmationModal
            show={closeoutCatShowDeleteConfirmation}
            message={this.closeoutCatDeletionConfirmationMessage()}
            onHide={this.handleHideCloseoutCatDeletionConfirmation}
          />
        )}
        {closeoutCatShowModal && (
          <CloseoutCatFormModal
            show={closeoutCatShowModal}
            onClose={this.handleCloseRenameCloseoutCat}
            name={closeoutCatSelected ? closeoutCatSelected.label : ''}
            closeoutCat={closeoutCatSelected}
          />
        )}
      </React.Fragment>
    );
  }
}

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    closeout: getCloseout(state),
    closeoutCats: getCloseoutCats(state),
    closeoutCompanyTypes: getCloseoutCompanyTypes(state),
    closeoutCatsCompanyTypes: getCloseoutCatsCompanyTypes(state),
    closeoutCatShowDeleteConfirmation: getCloseoutCatShowDeleteConfirmation(
      state
    ),
    closeoutCatSelected: getCloseoutCatSelected(state),
    closeoutCatShowModal: getCloseoutCatShowModal(state),
  };
})(CloseoutRequirements);
