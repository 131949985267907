import axios from 'axios';
import BaseApi from './base-api';

class ProjectDocumentationsApi extends BaseApi {
  getProjectDocumentation(projectId, filters) {
    let query = '';
    Object.keys(filters).forEach((filter, index) => {
      if (index > 0) {
        query += '&';
      }
      query += `${filter}=${filters[filter]}`;
    });

    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/project_documentation?${query}`
      )
      .then((response) => {
        return {
          ok: true,
          projectDocumentation: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getProjectDocumentationCount(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/project_documentation/count`)
      .then((response) => {
        return {
          ok: true,
          projectDocumentationCount: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getAdvancedDocuments(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/advanced_documents`)
      .then((response) => {
        return {
          ok: true,
          advancedDocuments: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getSharedFolders(projectId) {
    return axios
      .get(
        `${this.baseUrl}/projects/${projectId}/advanced_documents?by_visibility=shared&shared_with_me=true`
      )
      .then((response) => {
        return {
          ok: true,
          folders: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateProjectDocumentation(projectId, values, filters) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = this.projectDocumentationParams(values, filters);

    return axios
      .put(
        `${this.baseUrl}/projects/${projectId}/project_documentation`,
        data,
        headers
      )
      .then((response) => {
        return {
          ok: true,
          projectDocumentation: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  projectDocumentationParams(values, filters) {
    let apiPayload = new FormData();
    if (values.hyperlinks) {
      if (values.hyperlinks.length > 0) {
        values.hyperlinks.forEach((hyperlink) => {
          apiPayload.append('project_documentation[hyperlinks][]', hyperlink);
        });
      } else {
        apiPayload.append('project_documentation[hyperlinks][]', '');
      }
    }

    [
      { name: 'building_documents_attributes', param: 'buildingDocuments' },
      { name: 'bidding_documents_attributes', param: 'biddingDocuments' },
      { name: 'plan_check_documents_attributes', param: 'planCheckDocuments' },
      { name: 'ifc_documents_attributes', param: 'ifcDocuments' },
      {
        name: 'bulletin_arch_documents_attributes',
        param: 'bulletinArchDocuments',
      },
      {
        name: 'bulletin_structural_documents_attributes',
        param: 'bulletinStructuralDocuments',
      },
      {
        name: 'bulletin_mep_documents_attributes',
        param: 'bulletinMepDocuments',
      },
      { name: 'sketches_documents_attributes', param: 'sketchesDocuments' },
      { name: 'ffe_documents_attributes', param: 'ffeDocuments' },
      { name: 'bulletin_documents_attributes', param: 'bulletinDocuments' },
      { name: 'permit_documents_attributes', param: 'permitDocuments' },
      { name: 'schedule_documents_attributes', param: 'scheduleDocuments' },
      {
        name: 'change_estimate_documents_attributes',
        param: 'changeEstimateDocuments',
      },
      { name: 'cad_documents_attributes', param: 'cadDocuments' },
      { name: 'misc_documents_attributes', param: 'miscDocuments' },
    ].forEach((attr) => {
      const name = attr.name;
      const param = attr.param;
      !!values[param] &&
        values[param].forEach((doc, index) => {
          if (doc.id) {
            apiPayload.append(
              `project_documentation[${name}][${index}][id]`,
              doc.id
            );
            apiPayload.append(
              `project_documentation[${name}][${index}][_destroy]`,
              doc.destroy
            );
          } else {
            apiPayload.append(
              `project_documentation[${name}][${index}][document]`,
              doc.file,
              doc.name
            );
          }
        });
    });

    Object.keys(filters).forEach((filter) => {
      apiPayload.append(filter, filters[filter]);
    });

    return apiPayload;
  }
}

export const projectDocumentationsApi = new ProjectDocumentationsApi();
