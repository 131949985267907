import { showErrorMessage } from 'actions/messages';
import { surveysApi } from 'services/api/surveys-api';
import {
  SURVEYS_SET,
  SURVEYS_PAGINATION_INFO_SET,
  SURVEYS_PAGINATION_PAGE_CHANGE,
  SURVEYS_TOTAL_PENDING_SET,
  SURVEYS_SURVEY_SET,
  SURVEYS_SURVEY_FORM_SET,
  SURVEYS_SURVEY_FORM_ATTRIBUTE_CHANGE,
  SURVEYS_SURVEY_FORM_VALIDATION_MESSAGE_SET,
} from 'components/admin/surveys/store/action-types';
import { push } from 'react-router-redux';
import { surveyFormSelector } from 'components/admin/surveys/store/selectors';

const baseRoutePath = '/admin/surveys';

export function changeAttribute(attribute, value) {
  return (dispatch, getState) => {
    dispatch(changeFormAttribute(attribute, value));
    const surveyForm = surveyFormSelector(getState());
    if (
      surveyForm.response_time_sat !== null &&
      surveyForm.work_quality_sat !== null &&
      surveyForm.recommend_spackle !== null
    ) {
      dispatch(setShowValidationMessage(false));
    }
  };
}

function changeFormAttribute(attribute, value) {
  return {
    type: SURVEYS_SURVEY_FORM_ATTRIBUTE_CHANGE,
    payload: { attribute, value },
  };
}

function changePage(page) {
  return {
    type: SURVEYS_PAGINATION_PAGE_CHANGE,
    payload: page,
  };
}

export function getSurvey(surveyId) {
  return (dispatch) => {
    return surveysApi.getSurvey(surveyId).then((response) => {
      if (response.ok) {
        dispatch(setCurrentSurvey(response.survey));
        // Load form with survey, in case it's possible to edit it
        dispatch(setSurveyForm(response.survey));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function getSurveys(page = 1, pending = null) {
  return (dispatch) => {
    return surveysApi.getSurveys(page, pending).then((response) => {
      if (response.ok) {
        dispatch(setSurveys(response.surveys.content));
        dispatch(setPendingTotal(response.surveys.totalPending));
        if (page === 1) {
          dispatch(
            setPaginationInfo(
              response.surveys.total,
              response.surveys.pageSize,
              page
            )
          );
        } else {
          dispatch(changePage(page));
        }
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function goToSurveys() {
  return (dispatch) => {
    dispatch(push(`${baseRoutePath}`));
  };
}

export function goToSurvey(surveyId) {
  return (dispatch) => {
    dispatch(push(`${baseRoutePath}/${surveyId}`));
  };
}

function setCurrentSurvey(survey) {
  return {
    type: SURVEYS_SURVEY_SET,
    payload: survey,
  };
}

function setPaginationInfo(total, pageSize, page) {
  return {
    type: SURVEYS_PAGINATION_INFO_SET,
    payload: { total, pageSize, page },
  };
}

function setPendingTotal(total) {
  return {
    type: SURVEYS_TOTAL_PENDING_SET,
    payload: total,
  };
}

function setShowValidationMessage(value) {
  return {
    type: SURVEYS_SURVEY_FORM_VALIDATION_MESSAGE_SET,
    payload: value,
  };
}

export function setSurveyForm(survey) {
  return {
    type: SURVEYS_SURVEY_FORM_SET,
    payload: survey,
  };
}

function setSurveys(surveys) {
  return {
    type: SURVEYS_SET,
    payload: surveys,
  };
}

export function submitSurvey(surveyForm) {
  return (dispatch) => {
    if (
      surveyForm.response_time_sat === null ||
      surveyForm.work_quality_sat === null ||
      surveyForm.recommend_spackle === null
    ) {
      dispatch(setShowValidationMessage(true));
    } else {
      return surveysApi
        .updateSurvey(surveyForm.id, surveyForm)
        .then((response) => {
          if (response.ok) {
            dispatch(setCurrentSurvey(response.survey));
            dispatch(push(`${baseRoutePath}`));
          } else {
            dispatch(showErrorMessage(response.errors));
          }
        });
    }
  };
}
