export const BUILDINGS_BUILDING_LOAD_SUCCESS =
  'BUILDINGS_BUILDING_LOAD_SUCCESS';
export const BUILDINGS_BUILDING_WORK_ORDERS_PAGE_CHANGE =
  'BUILDINGS_BUILDING_WORK_ORDERS_PAGE_CHANGE';
export const BUILDINGS_BUILDING_PROJECTS_LOAD_SUCCESS =
  'BUILDINGS_BUILDING_PROJECTS_LOAD_SUCCESS';
export const BUILDINGS_EDIT_FORM_LOAD_FROM_CURRENT =
  'BUILDINGS_EDIT_FORM_LOAD_FROM_CURRENT';
export const BUILDINGS_LOAD_SUCCESS = 'BUILDINGS_LOAD_SUCCESS';
export const BUILDINGS_NEW_CLEAR_DATA = 'BUILDINGS_NEW_CLEAR_DATA';
export const BUILDINGS_NEW_FORM_INVALID = 'BUILDINGS_NEW_FORM_INVALID';
export const BUILDINGS_NEW_FORM_VALID = 'BUILDINGS_NEW_FORM_VALID';
export const BUILDINGS_NEW_FORM_VALUE_CHANGE =
  'BUILDINGS_NEW_FORM_VALUE_CHANGE';
export const BUILDINGS_SET_PAGINATION_DATA = 'BUILDINGS_SET_PAGINATION_DATA';
export const BUILDINGS_ADD_BUILDING_TO_BEGINNING =
  'BUILDINGS_ADD_BUILDING_TO_BEGINNING';
