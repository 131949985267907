export const CHANGE_ORDERS_LOAD_SUCCESS = 'CHANGE_ORDERS_LOAD_SUCCESS';
export const CHANGE_ORDERS_ADD = 'CHANGE_ORDERS_ADD';
export const CHANGE_ORDERS_UPDATE = 'CHANGE_ORDERS_UPDATE';
export const CHANGE_ORDER_MODAL_CLOSE = 'CHANGE_ORDER_MODAL_CLOSE';
export const CHANGE_ORDER_MODAL_SHOW = 'CHANGE_ORDER_MODAL_SHOW';
export const CHANGE_ORDER_START_DATE_UPDATE = 'CHANGE_ORDER_START_DATE_UPDATE';
export const CHANGE_ORDER_COMPLETION_DATE_UPDATE =
  'CHANGE_ORDER_COMPLETION_DATE_UPDATE';
export const CHANGE_ORDER_VALUES_UPDATE = 'CHANGE_ORDER_VALUES_UPDATE';
export const CHANGE_ORDER_BACK = 'CHANGE_ORDER_BACK';
export const CHANGE_ORDER_CREATE_SUCCESS = 'CHANGE_ORDER_CREATE_SUCCESS';
export const CHANGE_ORDER_NEXT = 'CHANGE_ORDER_NEXT';
export const CHANGE_ORDER_APPROVAL_MODAL_CLOSE =
  'CHANGE_ORDER_APPROVAL_MODAL_CLOSE';
export const CHANGE_ORDER_APPROVAL_SELECT = 'CHANGE_ORDER_APPROVAL_SELECT';
export const CHANGE_ORDER_SUCCESS_MODAL_OPEN =
  'CHANGE_ORDER_SUCCESS_MODAL_OPEN';
export const CHANGE_ORDER_SUCCESS_MODAL_CLOSE =
  'CHANGE_ORDER_SUCCESS_MODAL_CLOSE';
export const CHANGE_ORDER_PRINT_SUCCESS = 'CHANGE_ORDER_PRINT_SUCCESS';
export const CHANGE_ORDER_PDF_VIEWER_CLOSE = 'CHANGE_ORDER_PDF_VIEWER_CLOSE';
export const CHANGE_ORDER_EMAIL_MODAL_SHOW_SET =
  'CHANGE_ORDER_EMAIL_MODAL_SHOW_SET';
export const CHANGE_ORDER_USERS_LOAD_SUCCESS =
  'CHANGE_ORDER_USERS_LOAD_SUCCESS';
export const CHANGE_ORDER_SUBJECT_SET = 'CHANGE_ORDER_SUBJECT_SET';
export const CHANGE_ORDER_MESSAGE_SET = 'CHANGE_ORDER_MESSAGE_SET';
export const CHANGE_ORDER_EMAIL_ADD = 'CHANGE_ORDER_EMAIL_ADD';
export const CHANGE_ORDER_EMAIL_CLEAR = 'CHANGE_ORDER_EMAIL_CLEAR';
export const CHANGE_ORDER_EMAIL_REMOVE = 'CHANGE_ORDER_EMAIL_REMOVE';
