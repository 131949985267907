import User from 'domain/user';

export const INITIAL_FILTERS = {
  doSearch: false, // trigger to make search
  albumId: null, // if null then is project root
  search: '',
  sort: 'all',
  type: null,
  trades: [],
  sites: [],
  areas: [],
  users: [],
  tags: [],
  from: null,
  to: null,
  albumsPage: 1,
  photosPage: 1,
  status: null,
  favorites: false,
  liked: false,
};
export const INITIAL_FORM = {
  ids: [],
  title: '',
  description: '',
  likes: [],
  createdBy: null,
  createdAt: null,
  trades: [],
  tags: [],
  sites: [],
  areas: [],
  users: [],
  comments: [],
  permission: 'public',
  permissionUsers: [],
  count_photos: 0,
  projectAlbumId: null,
};
export const SORT_OPTIONS = [
  { id: 'all', label: 'All' },
  { id: 'asc_title', label: 'A-Z' },
  { id: 'desc_title', label: 'Z-A' },
  { id: 'desc', label: 'Newest' },
  { id: 'asc', label: 'Oldest' },
];
export const TYPE_OPTIONS = [
  { id: 'image', label: 'Photos' },
  { id: 'video', label: 'Videos' },
  { id: 'liked', label: 'Liked' },
];
export const INITIAL_ALBUM_FORM_TAB = 'general';
export const PERMISSIONS_ALBUM_FORM_TAB = 'permissions';
export const ALBUM_FORM_TABS = [
  { title: 'General Info', id: 'general' },
  { title: 'Permissions', id: 'permissions' },
  { title: 'Upload', id: 'upload' },
];

class ProjectPhoto {
  isShared = (item) => item.permission === 'shared';
  isPublic = (item) => item.permission === 'public';

  isCreator = (user, item) => {
    return (
      item &&
      ((item.createdBy && user.id === item.createdBy.id) ||
        (item.created_by && user.id === item.created_by.id))
    );
  };
  isCompanyUser = (user, item) => User.isSameCompany(user, item.created_by);

  hasPublicPermissions = (user, item) =>
    this.isPublic(item) && this.isCompanyUser(user, item);

  hasSharedPermissions = (user, item) =>
    this.isShared(item) &&
    item.permission_users &&
    item.permission_users.find((u) => u.id === user.id);
}

export default new ProjectPhoto();
