import {
  CONTRACTORS_INVOICES_PAGE_CHANGE,
  CONTRACTORS_INVOICES_PAGINATION_INIT,
  CONTRACTORS_INVOICES_SEARCH_TEXT_CHANGE,
  CONTRACTORS_INVOICES_VENDOR_INVOICES_PAGE_CHANGE,
  CONTRACTORS_INVOICES_VENDOR_INVOICES_PAGINATION_INIT,
  CONTRACTORS_INVOICES_SEARCH_FILTERS_INIT,
  CONTRACTORS_INVOICES_SEARCH_FILTER_VALUE_CHANGE,
} from 'components/admin/contractors-invoices/store/action-types';

const initialState = {
  paginationInfo: null, // { currentPage: number, pageSize: number, total: number }
  searchText: '',
  vendorInvoicesPaginationInfo: null, // { currentPage: number, pageSize: number, total: number }
  searchFilters: {},
};

export default function contractorsInvoicesUiReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case CONTRACTORS_INVOICES_PAGE_CHANGE:
      return {
        ...state,
        paginationInfo: {
          ...state.paginationInfo,
          currentPage: action.payload,
        },
      };

    case CONTRACTORS_INVOICES_PAGINATION_INIT:
      return {
        ...state,
        paginationInfo: {
          currentPage: 1,
          pageSize: action.payload.pageSize,
          total: action.payload.total,
        },
      };

    case CONTRACTORS_INVOICES_SEARCH_TEXT_CHANGE:
      return {
        ...state,
        searchText: action.payload,
      };

    case CONTRACTORS_INVOICES_VENDOR_INVOICES_PAGE_CHANGE:
      return {
        ...state,
        vendorInvoicesPaginationInfo: {
          ...state.vendorInvoicesPaginationInfo,
          currentPage: action.payload.page,
          pageSize: action.payload.pageSize,
          total: action.payload.total,
        },
      };

    case CONTRACTORS_INVOICES_VENDOR_INVOICES_PAGINATION_INIT:
      return {
        ...state,
        vendorInvoicesPaginationInfo: {
          currentPage: 1,
          pageSize: action.payload.pageSize,
          total: action.payload.total,
        },
      };

    case CONTRACTORS_INVOICES_SEARCH_FILTERS_INIT:
      return {
        ...state,
        searchFilters: {},
      };

    case CONTRACTORS_INVOICES_SEARCH_FILTER_VALUE_CHANGE:
      return {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          [action.payload.attribute]: action.payload.value,
        },
      };

    default:
      return state;
  }
}
