import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';

class ContractorsApi extends BaseApi {
  getContractors(byTrades, unionIds, page, label) {
    let filter = '';
    if (
      byTrades !== undefined &&
      typeof byTrades === 'object' &&
      byTrades.length > 0
    ) {
      // ?by_trades[]=2&by_trades[]=7
      filter = byTrades.reduce((accumulator, currentValue, currentIndex) => {
        return (
          accumulator +
          (currentIndex === 0
            ? 'by_trades[]=' + currentValue
            : '&by_trades[]=' + currentValue)
        );
      }, '');
    }

    if (unionIds !== undefined && unionIds.length > 0) {
      filter = unionIds.reduce((accumulator, currentValue) => {
        return `${accumulator}&by_unions[]=${currentValue}`;
      }, filter);
    }

    filter += page ? `&page=${page}` : '';

    filter += label ? `&by_name=${label}` : '';

    return axios
      .get(`${this.baseUrl}/contractors?${filter}`)
      .then((response) => {
        return {
          ok: true,
          contractors: response.data,
          total: numeral(response.headers['total']).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getContractor(contractorId) {
    return axios
      .get(`${this.baseUrl}/contractors/${contractorId}`)
      .then((response) => {
        return {
          ok: true,
          contractor: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getWorkOrders(contractorId, page, workOrderStatus, critical) {
    let filter = '';
    if (workOrderStatus !== undefined) {
      filter = `&${workOrderStatus.status}=true`;
    } else if (critical !== undefined && critical) {
      filter = '&critical=true';
    }

    return axios
      .get(
        `${this.baseUrl}/contractors/${contractorId}/work_orders?page=${
          page !== undefined ? page : 1
        }${filter}`
      )
      .then((response) => {
        return {
          ok: true,
          workOrders: {
            content: response.data,
            total: numeral(response.headers['total']).value(),
            totalComplete: numeral(response.headers['total-complete']).value(),
            totalCritical: numeral(response.headers['total-critical']).value(),
            totalDraft: numeral(response.headers['total-draft']).value(),
            totalInProgress: numeral(
              response.headers['total-in_progress']
            ).value(),
            totalPending: numeral(response.headers['total-pending']).value(),
            totalPlm: numeral(response.headers['total-plm']).value(),
            totalWoa: numeral(response.headers['total-woa']).value(),
            totalClosed: numeral(response.headers['total-closed']).value(),
            totalDeclined: numeral(response.headers['total-declined']).value(),
            pageSize: numeral(response.headers['per-page']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getProjects(contractorId, page, projectStatus) {
    return axios
      .get(
        `${this.baseUrl}/contractors/${contractorId}/projects?page=${
          page !== undefined ? page : 1
        }${projectStatus ? `&${projectStatus}=true` : ''}`
      )
      .then((response) => {
        return {
          ok: true,
          projects: {
            content: response.data,
            total: numeral(response.headers['total']).value(),
            totalPending: numeral(response.headers['total-pending']).value(),
            totalInProgress: numeral(
              response.headers['total-in_progress']
            ).value(),
            totalComplete: numeral(response.headers['total-complete']).value(),
            pageSize: numeral(response.headers['per-page']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  /**
   * Create a new contractor.
   * @param contractor
   * @param sendInvitation: boolean
   * @return {Promise<{ok: boolean, contractor: any} | {ok: boolean, errors: *}>}
   */
  createContractor(contractor, sendInvitation) {
    let contractorData = new FormData();
    contractorData.append('contractor[name]', contractor.name);
    if (contractor.otherUnion) {
      contractorData.append('contractor[other_union]', contractor.otherUnion);
    }
    contractorData.append(
      'contractor[contact_attributes][first_name]',
      contractor.contact.firstName
    );
    contractorData.append(
      'contractor[contact_attributes][last_name]',
      contractor.contact.lastName
    );
    contractorData.append(
      'contractor[contact_attributes][email]',
      contractor.contact.email
    );
    contractorData.append(
      'contractor[contact_attributes][phone_number]',
      contractor.contact.phoneNumber
    );
    contractorData.append(
      'contractor[contact_attributes][office_phone_number]',
      contractor.contact.officePhoneNumber
    );
    contractorData.append(
      'contractor[contact_attributes][office_fax_number]',
      contractor.contact.officeFaxNumber
    );
    contractorData.append(
      'contractor[contact_attributes][title]',
      contractor.contact.title
    );

    if (contractor.location) {
      contractorData.append(
        'contractor[location_attributes][street_address_1]',
        contractor.location.streetAddress
      );
      contractorData.append(
        'contractor[location_attributes][street_address_2]',
        contractor.location.unitNumber
      );
      contractorData.append(
        'contractor[location_attributes][city]',
        contractor.location.city
      );
      contractorData.append(
        'contractor[location_attributes][state]',
        contractor.location.state
      );
      contractorData.append(
        'contractor[location_attributes][zip]',
        contractor.location.zipCode
      );
    }

    if (contractor.tradesId !== undefined) {
      contractor.tradesId.forEach((id) =>
        contractorData.append('contractor[trade_ids][]', id)
      );
    }

    if (contractor.serviceAreasId !== undefined) {
      contractor.serviceAreasId.forEach((id) =>
        contractorData.append('contractor[service_area_ids][]', id)
      );
    }

    if (contractor.unionsId !== undefined) {
      contractor.unionsId.forEach((id) =>
        contractorData.append('contractor[union_ids][]', id)
      );
    }

    return axios
      .post(
        this.baseUrl +
          '/contractors' +
          (sendInvitation !== undefined
            ? '?send_invite=' + sendInvitation
            : ''),
        contractorData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
      .then((response) => {
        return {
          ok: true,
          contractor: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateContractor(contractorId, contractor, sendInvitation) {
    let contractorData = new FormData();
    contractorData.append('contractor[name]', contractor.name);
    if (contractor.otherUnion) {
      contractorData.append('contractor[other_union]', contractor.otherUnion);
    }
    contractorData.append(
      'contractor[license_number]',
      contractor.licenseNumber
    );
    contractorData.append(
      'contractor[contact_attributes][first_name]',
      contractor.contact.firstName
    );
    contractorData.append(
      'contractor[contact_attributes][last_name]',
      contractor.contact.lastName
    );
    contractorData.append(
      'contractor[contact_attributes][email]',
      contractor.contact.email
    );
    contractorData.append(
      'contractor[contact_attributes][phone_number]',
      contractor.contact.phoneNumber
    );
    contractorData.append(
      'contractor[contact_attributes][office_phone_number]',
      contractor.contact.officePhoneNumber
    );
    contractorData.append(
      'contractor[contact_attributes][office_fax_number]',
      contractor.contact.officeFaxNumber
    );
    contractorData.append(
      'contractor[location_attributes][street_address_1]',
      contractor.location.streetAddress
    );
    contractorData.append(
      'contractor[location_attributes][street_address_2]',
      contractor.location.unitNumber
    );
    contractorData.append(
      'contractor[location_attributes][city]',
      contractor.location.city
    );
    contractorData.append(
      'contractor[location_attributes][state]',
      contractor.location.state
    );
    contractorData.append(
      'contractor[location_attributes][zip]',
      contractor.location.zipCode
    );
    contractorData.append('contractor[confirmed]', contractor.confirmed);

    if (contractor.tradesId !== undefined) {
      contractor.tradesId.forEach((id) =>
        contractorData.append('contractor[trade_ids][]', id)
      );
    }

    if (contractor.serviceAreasId !== undefined) {
      contractor.serviceAreasId.forEach((id) =>
        contractorData.append('contractor[service_area_ids][]', id)
      );
    }

    if (contractor.unionsId !== undefined) {
      contractor.unionsId.forEach((id) =>
        contractorData.append('contractor[union_ids][]', id)
      );
    }

    return axios
      .put(
        `${this.baseUrl}/contractors/${contractorId}?send_invite=${
          sendInvitation !== undefined ? sendInvitation : false
        }`,
        contractorData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      )
      .then((response) => {
        return {
          ok: true,
          contractor: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  searchContractors(filter, tradeIds, unionIds, csiCodeId, page) {
    let tradeFilter = '';
    if (
      tradeIds !== undefined &&
      typeof tradeIds === 'object' &&
      tradeIds.length > 0
    ) {
      // ?by_trades[]=2&by_trades[]=7
      tradeFilter = tradeIds.reduce(
        (accumulator, currentValue, currentIndex) => {
          return (
            accumulator +
            (currentIndex === 0
              ? '&by_trades[]=' + currentValue
              : '&by_trades[]=' + currentValue)
          );
        },
        ''
      );
    }

    if (unionIds !== undefined && unionIds.length > 0) {
      tradeFilter = unionIds.reduce((accumulator, currentValue) => {
        return `${accumulator}&by_unions[]=${currentValue}`;
      }, tradeFilter);
    }

    if (csiCodeId) {
      tradeFilter += `&by_csi_code=${csiCodeId}`;
    }

    const pageFilter = page ? `&page=${page}` : '';

    return axios
      .get(
        `${this.baseUrl}/contractors?by_name=${filter}${tradeFilter}${pageFilter}`,
        {
          loadingDisabled: true,
        }
      )
      .then((response) => {
        return {
          ok: true,
          contractors: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getUsers(contractorId) {
    return axios
      .get(`${this.baseUrl}/contractors/${contractorId}/users`)
      .then((response) => {
        return {
          ok: true,
          users: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  addQuoteRequest(workOrderId, taskIds, contractorId, bidDueDate) {
    return axios
      .put(this.baseUrl + '/contractors/' + contractorId + '/quotes', {
        contractor: {
          bid_due_date: bidDueDate,
          work_order_id: workOrderId,
          task_ids: taskIds,
        },
      })
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getInvoices(contractorId, page = 1) {
    return axios
      .get(
        `${this.baseUrl}/contractors/${contractorId}/contractor_invoices?page=${page}`
      )
      .then((response) => {
        return {
          ok: true,
          contractorInvoices: {
            content: response.data,
            pageSize: numeral(response.headers['per-page']).value(),
            total: numeral(response.headers['total']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getMsaSigningUrl(contractorId, redirectUrl) {
    return axios
      .get(
        `${this.baseUrl}/contractors/${contractorId}/msa_signing_url?redirect_url=${redirectUrl}`
      )
      .then((response) => {
        return {
          ok: true,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  inviteUser(contractorId, userInvitation) {
    let user = {
      first_name: userInvitation.firstName,
      last_name: userInvitation.lastName,
      email: userInvitation.email,
      role_ids: userInvitation.rolesId,
    };

    if (userInvitation.phoneNumber !== '') {
      user['phone_number'] = userInvitation.phoneNumber;
    }

    if (userInvitation.title !== '') {
      user['title'] = userInvitation.title;
    }

    let params = { user: user };

    return axios
      .post(`${this.baseUrl}/contractors/${contractorId}/invite_user`, params)
      .then((response) => {
        return {
          ok: true,
          user: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export let contractorsApi = new ContractorsApi();
export default ContractorsApi = new ContractorsApi();
