import { createSelector } from 'reselect';

export const getUserInvitationsPermissions = createSelector(
  (state) => state.userInvitations.data.permissions,
  (permissions) => {
    return permissions;
  }
);

export const getUserInvitationsUsers = createSelector(
  (state) => state.userInvitations.data.users,
  (users) => {
    return users;
  }
);
