import { useEffect, useState } from 'react';
import moment from 'moment';

import {
  loadDirectoryRequest,
  searchDirectory,
} from 'components/admin/projects/details/project-directory/store/actions';
import {
  loadProjectProposalRequest,
  loadCsiCodes,
} from 'components/admin/projects/details/project-proposal/store/actions';

import {
  resetRfaForm,
  saveRfa,
  setCurrentRfa,
  setRfaFormValues,
  setRfaReadOnly,
  setShowRfaModal,
} from './store/actions';

import Project from 'domain/project';
import User from 'domain/user';
import Rfa, {
  approveStatusOptions,
  REASSIGN_STATUS,
  APPROVED_STATUS,
  OTHER_RESPONSE_METHOD,
} from 'domain/rfa';
import RfaPermissions from 'permissions/rfa-permissions';
import { parseDate } from 'services/utils/rfa-util';

const useRfaForm = (props) => {
  const {
    dispatch,
    currentRfa,
    currentProject,
    directory,
    form,
    currentUser,
    readOnly,
    projectProposal,
    csiCodes,
  } = props;

  const showFormError = form.errors && Object.keys(form.errors).length > 0;

  // current rfa
  const isBasic = ['draft', 'submitted', 'resolved'].includes(form.status);
  const isForRecord = ['draft_for_record', 'for_record'].includes(form.status);
  const isPRfa = ['draft_pending', 'pending'].includes(form.status);
  const isDraft = Rfa.isDraft(form);
  const isPending = form.status === 'pending';
  const isSubmitted = form.status === 'submitted';
  const isApproved = form.status === 'resolved';
  const isAccelerated = form.accelerated;
  const isReviewed = !!form.reviewed_at;
  const isResponded = !!form.responded_at;

  // RFA STATES (for record & basic):
  // draft -> submitted -> (approved | approved_as_noted | revise | rejected)
  // -> deleted

  // PRFA STATES:
  // draft -> pending -> submitted  -> (approved | approved_as_noted | revise | rejected)
  // -> deleted

  // current user
  const isSubscriber = RfaPermissions.canSubmit(currentUser, currentProject);
  const isAssignee =
    !!form.assigned_to && form.assigned_to.value === currentUser.id;

  // attributes for same form inputs that changes attr
  const inputsAttributes = {
    documents:
      isDraft || isForRecord
        ? 'request_documents'
        : isPending
        ? 'review_documents'
        : 'response_documents',
    images:
      isDraft || isForRecord
        ? 'request_images'
        : isPending
        ? 'review_images'
        : 'response_images',
    deleteDocuments:
      isDraft || isForRecord
        ? 'deleted_request_documents'
        : isPending
        ? 'deleted_review_documents'
        : 'deleted_response_documents',
    deleteImages:
      isDraft || isForRecord
        ? 'deleted_request_images'
        : isPending
        ? 'deleted_review_images'
        : 'deleted_response_images',
    remarks: isPending ? 'review_description' : 'response_description',
  };

  const tradeCodeOptions = Project.csiCodesOptions({
    project: currentProject,
    directory,
    currentUser,
    projectProposal,
    csiCodes,
  });

  const [showAdditionalRecipients, setShowAdditionalRecipients] = useState(
    false
  );

  const handleFormChange = (input, value) => {
    dispatch(setRfaFormValues({ [input]: value }));
  };

  const loadContributors = () => {
    let users = {};
    return dispatch(searchDirectory(currentProject.id)).then((response) => {
      if (response.ok) {
        const contributors = response.directory.project_contributors
          .filter((pc) => pc.primary)
          .map((pc) => {
            return pc.users
              .filter((usr) => !users[usr.id])
              .map((usr) => {
                users[usr.id] = true;
                const value = usr.id;
                const label = User.fullNameWithCompany({
                  ...usr,
                  accountable: pc.company,
                });
                return { value, label };
              });
          })
          .reduce((acc, val) => acc.concat(val), []);
        return { ok: true, options: contributors };
      }
    });
  };

  const loadDirectory = () => {
    if (!directory || directory.project_id !== currentProject.id) {
      dispatch(loadDirectoryRequest(currentProject.id));
    }
  };

  const loadTradeCodes = () => {
    if (projectProposal && projectProposal.project_id === currentProject.id)
      return;

    dispatch(loadProjectProposalRequest(currentProject.id)).then((response) => {
      if (response.projectProposal || (csiCodes && csiCodes.length > 0)) return;
      dispatch(loadCsiCodes());
    });
  };

  const recipientOptions = () => {
    const involvedUsers = [
      form.submitted_by,
      form.responded_by,
      form.accelerated_by,
      form.assigned_to,
    ];
    return Project.recipientOptions({ directory }).filter(
      ({ id }) => !involvedUsers.includes(id)
    );
  };

  const handleUploadImage = (files) => {
    const images = [...form[inputsAttributes.images]];
    files.forEach((file) => {
      images.push({ url: URL.createObjectURL(file), file });
    });
    handleFormChange(inputsAttributes.images, images);
  };

  const handleUploadDocument = (files) => {
    const documents = [...form[inputsAttributes.documents]];
    files.forEach((file) => {
      documents.push({ file, name: file.name });
    });
    handleFormChange(inputsAttributes.documents, documents);
  };

  const handleRemoveImage = (index) => {
    const images = [...form[inputsAttributes.images]];
    const deletedImages = [
      ...form[inputsAttributes.deleteImages],
      ...images.splice(index, 1),
    ];

    handleFormChange(inputsAttributes.images, images);
    handleFormChange(inputsAttributes.deleteImages, deletedImages);
  };

  const handleRemoveDocument = (index) => {
    const documents = [...form[inputsAttributes.documents]];
    const deletedDocuments = [
      ...form[inputsAttributes.deleteDocuments],
      ...documents.splice(index, 1),
    ];

    handleFormChange(inputsAttributes.documents, documents);
    handleFormChange(inputsAttributes.deleteDocuments, deletedDocuments);
  };

  const handleEditImages = (editedImages, toDelete) => {
    handleFormChange(inputsAttributes.images, editedImages); // Rotates images on form
    handleFormChange(inputsAttributes.deleteImages, toDelete);
  };
  const handleAccelerate = () => {
    handleFormChange('accelerated_by', {
      label: currentUser.label,
      value: currentUser.id,
    });
    handleFormChange('accelerated_at', moment().format());
    handleFormChange('accelerated', true);
  };

  const getApprovedStatusOptions = () => {
    const options = [...approveStatusOptions];
    if (!isPending) options.pop();
    return options;
  };

  const getByAtText = (by, at, action = null, defaultBy = '--') => {
    if (!by && !at) return `${action ? `${action} ` : ''}--`;

    return `${action ? `${action} by ` : ''}${
      by ? by.label : defaultBy
    } on ${parseDate(at, true)}`;
  };

  const handleAddRecipients = () => {
    setShowAdditionalRecipients(true);
  };

  const validateForm = () => {
    const errors = {};

    // new or edit draft
    if (isDraft) {
      if (!form.request_type) errors.request_type = true;
      if (!form.request_title) errors.request_title = true;
      if (!form.request_description) errors.request_description = true;

      // basic to submitted validation
      if (isBasic) {
        if (!form.assigned_to) errors.assigned_to = true;
        if (!form.due_date) errors.due_date = true;
      }

      // for_record to submitted validation
      if (isForRecord) {
        if (!form.responded_by) errors.responded_by = true;
        if (!form.responded_at_date) errors.responded_at_date = true;
        if (!form.responded_at_time) errors.responded_at_time = true;
        if (!form.response_method) errors.response_method = true;
        if (
          form.response_method === OTHER_RESPONSE_METHOD &&
          !form.response_method_description
        )
          errors.response_method_description = true;
        if (!form.response_code) errors.response_code = true;
        if (
          form.response_code !== APPROVED_STATUS &&
          !form.response_description
        )
          errors.response_description = true;
      }

      // prfa to pending validation
      if (isPRfa) {
        // this has to be validated only if company is associated with multiple trades on project
        if (!form.csi_code_id && tradeCodeOptions.length > 1)
          errors.csi_code_id = true;
        if (!form.due_date) errors.due_date = true;
      }
    }

    // P-RFA to submitted validation
    if (isPending) {
      if (!form.response_code) errors.response_code = true;
      if (form.response_code !== APPROVED_STATUS && !form.review_description)
        errors.review_description = true;
      if (form.response_code === REASSIGN_STATUS && !form.assigned_to)
        errors.assigned_to = true;
    }

    // PRFA & RFA to approved validation
    if (isSubmitted) {
      if (!form.response_code) errors.approved_status = true;
      if (
        !form.response_code ||
        (form.response_code !== APPROVED_STATUS && !form.response_description)
      )
        errors.response_description = true;

      if (isAccelerated) {
        if (!form.responded_by) errors.responded_by = true;
        if (!form.responded_at_date) errors.responded_at_date = true;
        if (!form.responded_at_time) errors.responded_at_time = true;
        if (!form.response_method) errors.response_method = true;
        if (
          form.response_method === OTHER_RESPONSE_METHOD &&
          !form.response_method_description
        )
          errors.response_method_description = true;
      }
    }
    return errors;
  };

  const validateFormForEditing = () => {
    const errors = {};

    // new or edit draft
    if (isDraft) {
      if (!form.request_type) errors.request_type = true;
      if (!form.request_title) errors.request_title = true;
      if (!form.request_description) errors.request_description = true;

      // basic to submitted validation
      if (isBasic) {
        if (!form.assigned_to) errors.assigned_to = true;
        if (!form.due_date) errors.due_date = true;
      }

      // for_record to submitted validation
      if (isForRecord) {
        if (!form.responded_by) errors.responded_by = true;
        if (!form.responded_at_date) errors.responded_at_date = true;
        if (!form.responded_at_time) errors.responded_at_time = true;
        if (!form.response_method) errors.response_method = true;
        if (
          form.response_method === OTHER_RESPONSE_METHOD &&
          !form.response_method_description
        )
          errors.response_method_description = true;
        if (!form.response_code) errors.response_code = true;
        if (
          form.response_code !== APPROVED_STATUS &&
          !form.response_description
        )
          errors.response_description = true;
      }

      // prfa to pending validation
      if (isPRfa) {
        // this has to be validated only if company is associated with multiple trades on project
        if (!form.csi_code_id && tradeCodeOptions.length > 1)
          errors.csi_code_id = true;
        if (!form.due_date) errors.due_date = true;
      }
    }
    return errors;
  };

  const validatePRfa = () => {
    const errors = {};

    if (!form.request_type) errors.request_type = true;
    if (!form.request_title) errors.request_title = true;
    if (!form.request_description) errors.request_description = true;
    if (!form.assigned_to) errors.assigned_to = true;
    if (!form.due_date) errors.due_date = true;
    if (!form.csi_code_id) errors.csi_code_id = true;
    return errors;
  };

  const handleCancel = () => {
    dispatch(resetRfaForm(currentRfa));
    dispatch(setRfaReadOnly(true));
    if (!currentRfa || readOnly) {
      dispatch(setShowRfaModal(false));
      dispatch(setCurrentRfa(null));
    }
  };

  const getSaveAction = (draft) => {
    if (draft) return 'save_draft';
    if (isSubmitted) return 'respond';
    if (isPending) return 'review';
    return 'save';
  };

  window.moment = moment;
  const onSave = (draft) => {
    let responded_at = null;
    if (
      (isForRecord || (isSubmitted && isAccelerated)) &&
      form.responded_at_date &&
      form.responded_at_time
    ) {
      const date = moment(form.responded_at_date);
      const time = moment(form.responded_at_time);
      responded_at = moment(
        date.format('YYYY-MM-DD') + ' ' + time.format('HH:mm')
      )
        .utc()
        .format('YYYY-MM-DD HH:mm');
    }

    let csi_code_id = form.csi_code_id;
    if (isPRfa && isDraft && tradeCodeOptions.length === 1)
      csi_code_id = tradeCodeOptions[0].value;

    const response_code =
      form.response_code !== REASSIGN_STATUS ? form.response_code : null;

    const values = {
      ...form,
      responded_at,
      ccs: form.ccs.map((ccr) => ccr[0]),
      addl_recipients: form.addl_recipients.map((ccr) => ccr[0]),
      csi_code_id: csi_code_id,
      response_code: response_code,
    };
    dispatch(saveRfa(currentProject.id, values, getSaveAction(draft)));
  };

  // This one transforms a PRFA into a RFA, if the user is not an assignee
  const onAgreeAndSubmit = () => {
    let csi_code_id = form.csi_code_id;
    if (isPRfa && isDraft && tradeCodeOptions.length === 1)
      csi_code_id = tradeCodeOptions[0].value;
    const values = {
      ...form,
      status: 'submitted',
      ccs: form.ccs.map((ccr) => ccr[0]),
      addl_recipients: form.addl_recipients.map((ccr) => ccr[0]),
      csi_code_id: csi_code_id,
    };
    console.log('values');
    dispatch(saveRfa(currentProject.id, values, 'review'));
  };

  const onReassign = (draft) => {
    let responded_at = null;
    if (
      (isForRecord || (isSubmitted && isAccelerated)) &&
      form.responded_at_date &&
      form.responded_at_time
    ) {
      const date = moment(form.responded_at_date);
      const time = moment(form.responded_at_time);
      responded_at = moment(
        date.format('YYYY-MM-DD') + ' ' + time.format('HH:mm')
      )
        .utc()
        .format('YYYY-MM-DD HH:mm');
    }

    let csi_code_id = form.csi_code_id;
    if (isPRfa && isDraft && tradeCodeOptions.length === 1)
      csi_code_id = tradeCodeOptions[0].value;

    const response_code =
      form.response_code !== REASSIGN_STATUS ? form.response_code : null;

    const values = {
      ...form,
      responded_at,
      ccs: form.ccs.map((ccr) => ccr[0]),
      addl_recipients: form.addl_recipients.map((ccr) => ccr[0]),
      csi_code_id: csi_code_id,
      response_code: response_code,
      status: 'submitted',
    };
    dispatch(saveRfa(currentProject.id, values, 'submit'));
  };

  const handleSave = () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      handleFormChange('errors', errors);
    } else {
      onSave(false);
    }
  };

  const handleAgreeAndSubmit = () => {
    const errors = validatePRfa();
    if (Object.keys(errors).length > 0) {
      handleFormChange('errors', errors);
    } else {
      onAgreeAndSubmit();
    }
  };

  const handleReassign = () => {
    const errors = validateFormForEditing();
    if (Object.keys(errors).length > 0) {
      handleFormChange('errors', errors);
    } else {
      onReassign(false);
    }
  };

  const handleSaveDraft = () => onSave(true);

  useEffect(() => {
    loadDirectory();
    loadTradeCodes();
  }, []);

  return {
    isPRfa,
    isBasic,
    isDraft,
    isPending,
    isApproved,
    isAssignee,
    isForRecord,
    isSubmitted,
    isSubscriber,
    isAccelerated,
    isReviewed,
    isResponded,
    showFormError,
    inputsAttributes,
    showAdditionalRecipients,
    handleSave,
    handleReassign,
    getByAtText,
    handleCancel,
    handleSaveDraft,
    handleAgreeAndSubmit,
    recipientOptions,
    loadContributors,
    handleFormChange,
    handleAccelerate,
    handleRemoveImage,
    handleUploadImage,
    handleEditImages,
    handleAddRecipients,
    tradeCodeOptions,
    handleRemoveDocument,
    handleUploadDocument,
    getApprovedStatusOptions,
  };
};

export default useRfaForm;
