export const SET_COMPANY_DROPDOWN = 'SET_COMPANY_DROPDOWN';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_COMPANIES_DATA = 'SET_COMPANIES_DATA';
export const SET_TOTAL = 'SET_TOTAL';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const SET_NAME_TO_SEARCH = 'SET_NAME_TO_SEARCH';
export const SET_COMPANY_USER_DATA = 'SET_COMPANY_USER_DATA';
export const SET_COMPANY_PROJECTS_DATA = 'SET_COMPANY_PROJECTS_DATA';
export const SET_COMPANY_DATA = 'SET_COMPANY_DATA';
export const SET_CURRENT_USER_COMPANY = 'SET_CURRENT_USER_COMPANY';
export const COMPANIES_INVITE_USER_FORM_LOAD =
  'COMPANIES_INVITE_USER_FORM_LOAD';
export const COMPANIES_INVITE_USER_MODAL_SHOW =
  'COMPANIES_INVITE_USER_MODAL_SHOW';
export const COMPANIES_INVITE_USER_ID_SET = 'COMPANIES_INVITE_USER_ID_SET';
export const COMPANIES_SHOW_CONFIRMATION_MODAL =
  'COMPANIES_SHOW_CONFIRMATION_MODAL';
export const COMPANIES_CLEAR_FILTERS = 'COMPANIES_CLEAR_FILTERS';
export const COMPANIES_SHOW_VERIFICATION_MODAL =
  'COMPANIES_SHOW_VERIFICATION_MODAL';
export const SET_UNIONS = 'SET_UNIONS';
export const SET_ROLES = 'SET_ROLES';
export const NEXT_STAGE = 'NEXT_STAGE';
export const LICENCE_NUMBER_VALUE_CHANGE = 'LICENCE_NUMBER_VALUE_CHANGE';
export const NEW_ONBOARDING = 'NEW_ONBOARDING';
export const COMPANY_LOGO_FILE_SET = 'COMPANY_LOGO_FILE_SET';
export const COMPANY_ONBOARDING_STAGE_CHANGE =
  'COMPANY_ONBOARDING_STAGE_CHANGE';
export const COMPANY_SELECT_TAB = 'COMPANY_SELECT_TAB';
