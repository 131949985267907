export const SET_SERVICE_PROVIDER_DROPDOWN = 'SET_SERVICE_PROVIDER_DROPDOWN';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_SERVICE_PROVIDER_DATA = 'SET_SERVICE_PROVIDER_DATA';
export const SET_TOTAL = 'SET_TOTAL';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
export const SET_NAME_TO_SEARCH = 'SET_NAME_TO_SEARCH';
export const SET_SERVICE_PROVIDER_USER_DATA = 'SET_SERVICE_PROVIDER_USER_DATA';
export const SET_COMPANY_DATA = 'SET_COMPANY_DATA';
export const SET_CURRENT_USER_COMPANY = 'SET_CURRENT_USER_COMPANY';
export const SERVICE_PROVIDERS_INVITE_USER_FORM_LOAD =
  'SERVICE_PROVIDERS_INVITE_USER_FORM_LOAD';
export const SERVICE_PROVIDERS_INVITE_USER_MODAL_SHOW =
  'SERVICE_PROVIDERS_INVITE_USER_MODAL_SHOW';
export const SERVICE_PROVIDERS_INVITE_USER_ID_SET =
  'SERVICE_PROVIDERS_INVITE_USER_ID_SET';
export const SERVICE_PROVIDERS_SHOW_CONFIRMATION_MODAL =
  'SERVICE_PROVIDERS_SHOW_CONFIRMATION_MODAL';
export const SERVICE_PROVIDERS_CLEAR_FILTERS =
  'SERVICE_PROVIDERS_CLEAR_FILTERS';
export const SERVICE_PROVIDERS_SHOW_VERIFICATION_MODAL =
  'SERVICE_PROVIDERS_SHOW_VERIFICATION_MODAL';
