import {
  WORK_ORDERS_DETAILS_MEMBERS_ADD,
  WORK_ORDERS_DETAILS_MEMBERS_LOAD_SUCCESS,
  WORK_ORDERS_DETAILS_NAV_BAR_SELECTION,
  WORK_ORDERS_DETAILS_NOTE_ADD_SUCCESS,
  WORK_ORDERS_DETAILS_NOTES_LOAD_SUCCESS,
  WORK_ORDERS_DETAILS_OPEN_ITEMS_ADD,
  WORK_ORDERS_DETAILS_OPEN_ITEMS_LOAD_SUCCESS,
  WORK_ORDERS_DETAILS_OPEN_ITEMS_UPDATE,
  WORK_ORDERS_DETAILS_CHANGE_ORDER_ADD,
  WORK_ORDERS_DETAILS_MOVED_TO_ACR,
  WORK_ORDERS_DETAILS_PROPOSAL_ADD,
  WORK_ORDERS_DETAILS_PROPOSAL_UPDATE,
  WORK_ORDERS_DETAILS_PROPOSALS_LOAD_SUCCESS,
  WORK_ORDERS_DETAILS_QUOTE_DETAIL_MODAL_CLOSE,
  WORK_ORDERS_DETAILS_QUOTES_LOAD_SUCCESS,
  WORK_ORDERS_DETAILS_QUOTES_UPDATE_SUCCESS,
  WORK_ORDERS_DETAILS_QUOTES_DELETE_SUCCESS,
  WORK_ORDERS_DETAILS_QUOTES_REMOVE,
  WORK_ORDERS_DETAILS_QUOTES_SELECT,
  WORK_ORDERS_DETAILS_QUOTES_TABLE_FILTER_CHANGE,
  WORK_ORDERS_DETAILS_QUOTES_TABLE_RADIO_SELECT,
  WORK_ORDERS_DETAILS_QUOTES_ADD,
  WORK_ORDERS_DETAILS_QUOTES_UPDATE,
  WORK_ORDERS_DETAILS_STATE_INIT,
  WORK_ORDERS_DETAILS_TASK_CANCEL,
  WORK_ORDERS_DETAILS_TASK_CHANGE_VALUE,
  WORK_ORDERS_DETAILS_TASK_IMAGE_REMOVE_SUCCESS,
  WORK_ORDERS_DETAILS_TASK_LOAD_SUCCESS,
  WORK_ORDERS_DETAILS_TASK_UPDATE,
  WORK_ORDERS_DETAILS_TASK_NOTE_ADD_SUCCESS,
  WORK_ORDERS_DETAILS_TASK_NOTES_LOAD_SUCCESS,
  WORK_ORDERS_DETAILS_SET_CURRENT_WORK_ORDER,
  WORK_ORDERS_DETAILS_BUDGET_SET,
  WORK_ORDERS_DETAILS_CONTRACTOR_INVOICE_SET,
  WORK_ORDERS_DETAILS_CONTRACTOR_LIEN_RELEASE_SET,
  WORK_ORDERS_DETAILS_CONTRACTOR_LIEN_RELEASES_SET,
  WORK_ORDERS_DETAILS_CONTRACTOR_LIEN_RELEASE_REMOVE,
  WORK_ORDERS_DETAILS_MEMBERS_REMOVE,
  WORK_ORDERS_DETAILS_WOAS_SET,
} from 'actions/admin/work-orders/action-types';
import { buildTaskName } from 'services/utils/text-util';

const initialState = {
  budget: null,
  contractorInvoice: null,
  contractorLienRelease: null,
  contractorLienReleases: [],
  currentTask: null,
  currentWorkOrder: null,
  members: [],
  openItemsByTaskId: null, // object with taskId as key
  proposals: [],
  quotes: [],
  quotesSelectedByTaskId: {}, // { taskId, selectedQuoteId: null OR id}
  selectedQuoteId: null,
  taskNotes: [],
  workOrderNotes: [],
  workOrderAuthorizations: [],
};

export default function detailsDataReducer(state = initialState, action) {
  switch (action.type) {
    case WORK_ORDERS_DETAILS_BUDGET_SET:
      return {
        ...state,
        budget: action.payload,
      };

    case WORK_ORDERS_DETAILS_MEMBERS_ADD:
      return {
        ...state,
        members: state.members.concat([action.payload]),
      };

    case WORK_ORDERS_DETAILS_MEMBERS_REMOVE:
      return {
        ...state,
        members: state.members.filter((member) => member.id !== action.payload),
      };

    case WORK_ORDERS_DETAILS_MEMBERS_LOAD_SUCCESS:
      return {
        ...state,
        members: action.payload,
      };

    case WORK_ORDERS_DETAILS_NOTE_ADD_SUCCESS:
      return {
        ...state,
        workOrderNotes: state.workOrderNotes.concat([action.payload]),
      };

    case WORK_ORDERS_DETAILS_NOTES_LOAD_SUCCESS:
      return {
        ...state,
        workOrderNotes: action.payload,
      };

    case WORK_ORDERS_DETAILS_OPEN_ITEMS_ADD:
      const newOpenItemsByTaskId = {
        ...state.openItemsByTaskId,
      };
      if (newOpenItemsByTaskId[action.payload.taskId] === undefined) {
        newOpenItemsByTaskId[action.payload.taskId] = [action.payload.item];
      } else {
        newOpenItemsByTaskId[action.payload.taskId] = newOpenItemsByTaskId[
          action.payload.taskId
        ].concat([action.payload.item]);
      }
      return {
        ...state,
        openItemsByTaskId: newOpenItemsByTaskId,
      };

    case WORK_ORDERS_DETAILS_OPEN_ITEMS_UPDATE:
      return {
        ...state,
        openItemsByTaskId: {
          ...state.openItemsByTaskId,
          [action.payload.taskId]: state.openItemsByTaskId[
            action.payload.taskId
          ].map((openItem) => {
            if (openItem.id === action.payload.item.id) {
              return action.payload.item;
            } else {
              return openItem;
            }
          }),
        },
      };

    case WORK_ORDERS_DETAILS_OPEN_ITEMS_LOAD_SUCCESS:
      return {
        ...state,
        openItemsByTaskId: action.payload,
      };

    case WORK_ORDERS_DETAILS_CHANGE_ORDER_ADD:
      return {
        ...state,
        quotesSelectedByTaskId: {},
      };

    case WORK_ORDERS_DETAILS_MOVED_TO_ACR:
      return {
        ...state,
        quotesSelectedByTaskId: {},
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_ADD:
      return {
        ...state,
        proposals: state.proposals.concat([action.payload]),
        quotesSelectedByTaskId: {},
      };

    case WORK_ORDERS_DETAILS_PROPOSALS_LOAD_SUCCESS:
      return {
        ...state,
        proposals: action.payload,
      };

    case WORK_ORDERS_DETAILS_TASK_LOAD_SUCCESS:
      return {
        ...state,
        currentTask: action.payload,
      };

    case WORK_ORDERS_DETAILS_TASK_NOTE_ADD_SUCCESS:
      return {
        ...state,
        taskNotes: state.taskNotes.concat([action.payload]),
      };

    case WORK_ORDERS_DETAILS_TASK_NOTES_LOAD_SUCCESS:
      return {
        ...state,
        taskNotes: action.payload,
      };

    case WORK_ORDERS_DETAILS_QUOTE_DETAIL_MODAL_CLOSE:
      return {
        ...state,
        selectedQuoteId: null,
      };

    case WORK_ORDERS_DETAILS_QUOTES_LOAD_SUCCESS:
      return {
        ...state,
        quotes: action.payload,
      };

    case WORK_ORDERS_DETAILS_QUOTES_UPDATE_SUCCESS:
      return {
        ...state,
        quotes: state.quotes.map((quote) => {
          if (quote.id === action.payload.id) {
            quote['estimate'] = action.payload['estimate'];
            quote['counter'] = action.payload['counter'];
            quote['counter_status'] = action.payload['counter_status'];
            quote['value'] = action.payload['value'];
            quote['contractor_name'] = action.payload['contractor_name'];
          }

          return quote;
        }),
      };

    case WORK_ORDERS_DETAILS_QUOTES_DELETE_SUCCESS:
      return {
        ...state,
        quotes: state.quotes.filter((quote) => quote.id !== action.payload),
      };

    case WORK_ORDERS_DETAILS_QUOTES_REMOVE:
      return {
        ...state,
        quotes: state.quotes.filter(
          (quote) => quote.task.id !== action.payload
        ),
      };

    case WORK_ORDERS_DETAILS_QUOTES_SELECT:
      return {
        ...state,
        selectedQuoteId: action.payload,
      };

    case WORK_ORDERS_DETAILS_QUOTES_TABLE_RADIO_SELECT:
      return {
        ...state,
        quotesSelectedByTaskId: {
          ...state.quotesSelectedByTaskId,
          [action.payload.taskId]: action.payload.quoteId,
        },
      };

    case WORK_ORDERS_DETAILS_QUOTES_TABLE_FILTER_CHANGE:
      return {
        ...state,
        quotesSelectedByTaskId: {},
      };

    case WORK_ORDERS_DETAILS_QUOTES_ADD:
      return {
        ...state,
        quotes: [...state.quotes, action.payload],
      };

    case WORK_ORDERS_DETAILS_QUOTES_UPDATE:
      return {
        ...state,
        // quotes: action.payload
        quotes: state.quotes.map((quote) => {
          // action.payload is a list of quotes
          const foundQuote = action.payload.find(
            (payloadQuote) => payloadQuote.id === quote.id
          );

          // If quote exists, update with the value that come in the payload
          if (foundQuote !== undefined) {
            return foundQuote;
          } else {
            return quote;
          }
        }),
      };

    case WORK_ORDERS_DETAILS_TASK_CHANGE_VALUE:
      const newTask = {
        ...state.currentTask,
      };

      if (action.payload.attribute === 'images') {
        newTask['images'] = newTask['images'].concat(action.payload.value);
      } else {
        newTask[action.payload.attribute] = action.payload.value;
      }

      // If trades are updated, removeUser value for subTrade
      if (action.payload.attribute === 'trade') {
        newTask['tradeLabel'] = action.payload.label;
        newTask['subTrade'] = 0;
      }

      if (
        action.payload.attribute === 'trade' &&
        newTask['tradeLabel'] !== ''
      ) {
        newTask['name'] = buildTaskName(newTask['tradeLabel']);
      }

      return {
        ...state,
        currentTask: newTask,
      };

    case WORK_ORDERS_DETAILS_TASK_CANCEL:
      return {
        ...state,
        currentTask: null,
      };

    case WORK_ORDERS_DETAILS_TASK_IMAGE_REMOVE_SUCCESS:
      return {
        ...state,
        currentTask: {
          ...state.currentTask,
          images: state.currentTask.images.filter((element, index) => {
            return index !== action.payload;
          }),
        },
      };

    case WORK_ORDERS_DETAILS_TASK_UPDATE:
      return {
        ...state,
        currentTask: action.payload,
      };

    case WORK_ORDERS_DETAILS_STATE_INIT:
      return {
        ...state,
        currentTask: null,
        quotesSelectedByTaskId: {},
        receipts: [],
      };

    case WORK_ORDERS_DETAILS_NAV_BAR_SELECTION:
      return {
        ...state,
        currentTask: null,
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_UPDATE:
      return {
        ...state,
        proposals: state.proposals.map((proposal) => {
          if (proposal.id === action.payload.id) {
            return action.payload;
          } else {
            return proposal;
          }
        }),
      };

    case WORK_ORDERS_DETAILS_SET_CURRENT_WORK_ORDER:
      return {
        ...state,
        currentWorkOrder: action.payload,
      };

    case WORK_ORDERS_DETAILS_CONTRACTOR_INVOICE_SET:
      return {
        ...state,
        contractorInvoice: action.payload,
      };

    case WORK_ORDERS_DETAILS_CONTRACTOR_LIEN_RELEASE_SET:
      return {
        ...state,
        contractorLienRelease: action.payload,
      };

    case WORK_ORDERS_DETAILS_CONTRACTOR_LIEN_RELEASES_SET:
      return {
        ...state,
        contractorLienReleases: action.payload,
      };

    case WORK_ORDERS_DETAILS_CONTRACTOR_LIEN_RELEASE_REMOVE:
      return {
        ...state,
        contractorLienReleases: state.contractorLienReleases.filter(
          (lienRelease) => lienRelease.id !== action.payload
        ),
      };

    case WORK_ORDERS_DETAILS_WOAS_SET:
      return {
        ...state,
        workOrderAuthorizations: action.payload,
      };

    default:
      return state;
  }
}
