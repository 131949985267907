import { SETTINGS_SET } from 'components/admin/projects/details/project-settings/store/action-types';

const initialState = {
  settings: null,
};

export default function projectSettingsDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SETTINGS_SET:
      return {
        ...state,
        settings: action.payload,
      };

    default:
      return state;
  }
}
