import { showErrorMessage } from 'actions/messages';
import { meetingInfosApi } from 'services/api/meeting-infos-api';
import { meetingsApi } from 'services/api/meetings-api';
import { meetingTypesApi } from 'services/api/meeting-types-api';
import { meetingCategoriesApi } from 'services/api/meeting-categories-api';
import {
  MEETING_MINUTES_INIT,
  MEETING_INFO_SET,
  MEETING_INFO_EDIT_SET,
  MEETINGS_SET,
  MEETING_MODAL_SHOW_SET,
  MEETING_CATEGORIES_SET,
  MEETING_MODAL_INIT,
  MEETING_FORM_VALUE_UPDATE,
  MEETING_ITEM_VALUE_UPDATE,
  MEETING_ITEM_ADD,
  MEETING_ITEMS_ATTACH,
  ATTENDEES_MODAL_SHOW_SET,
  REVIEW_MEETING_SET,
  MEETINGS_ADD,
  MEETING_ITEM_SETTINGS_SET,
  CURRENT_MEETING_SET,
  MEETINGS_UPDATE,
  MEETING_PDF_SET,
  TOGGLE_PDF_VIEWER_SUCCESS,
  CONFIRMATION_MSG_SET,
  CONFIRM_APPROVAL_SET,
  MEETING_REMOVE,
  MEETING_TYPES_SET,
  SELECTED_MEETING_TYPE_SET,
} from 'components/admin/projects/details/project-meeting-minutes/store/action-types';

export function initMeetingMinutes() {
  return {
    type: MEETING_MINUTES_INIT,
  };
}

export function loadMeetingInfoSuccess(meetingInfo) {
  return {
    type: MEETING_INFO_SET,
    payload: meetingInfo,
  };
}

export function loadMeetingInfoRequest(projectId, meetingTypeId) {
  return (dispatch) => {
    return meetingInfosApi
      .getMeetingInfo(projectId, meetingTypeId)
      .then((response) => {
        if (response.ok) {
          dispatch(loadMeetingInfoSuccess(response.meetingInfo));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setMeetingInfoEdit(edit) {
  return {
    type: MEETING_INFO_EDIT_SET,
    payload: edit,
  };
}

export function createMeetingInfo(projectId, values, meetingTypeId) {
  return (dispatch) => {
    return meetingInfosApi
      .createMeetingInfo(projectId, { ...values, meetingTypeId })
      .then((response) => {
        if (response.ok) {
          dispatch(loadMeetingInfoSuccess(response.meetingInfo));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function updateMeetingInfo(projectId, values, meetingTypeId) {
  return (dispatch) => {
    return meetingInfosApi
      .updateMeetingInfo(projectId, { ...values, meetingTypeId })
      .then((response) => {
        if (response.ok) {
          dispatch(loadMeetingInfoSuccess(response.meetingInfo));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function loadMeetingsSuccess(meetings) {
  return {
    type: MEETINGS_SET,
    payload: meetings,
  };
}

export function loadMeetingsRequest(projectId, meetingTypeId) {
  return (dispatch) => {
    return meetingsApi
      .getMeetings(projectId, meetingTypeId)
      .then((response) => {
        if (response.ok) {
          dispatch(loadMeetingsSuccess(response.meetings));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function togglePdfViewer() {
  return {
    type: TOGGLE_PDF_VIEWER_SUCCESS,
  };
}

export function loadMeetingPdfSuccess(pdf) {
  return {
    type: MEETING_PDF_SET,
    payload: pdf,
  };
}

export function loadMeetingPdfRequest(projectId, meetingId) {
  return (dispatch) => {
    return meetingsApi.getMeetingPdf(projectId, meetingId).then((response) => {
      if (response.ok) {
        dispatch(loadMeetingPdfSuccess(response.pdf));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function setMeetingModalShow(meetingModalShow) {
  return {
    type: MEETING_MODAL_SHOW_SET,
    payload: meetingModalShow,
  };
}

export function loadMeetingCategoriesSuccess(meetingCategories) {
  return {
    type: MEETING_CATEGORIES_SET,
    payload: meetingCategories,
  };
}

export function loadMeetingCategoriesRequest() {
  return (dispatch) => {
    return meetingCategoriesApi.getMeetingCategories().then((response) => {
      if (response.ok) {
        dispatch(loadMeetingCategoriesSuccess(response.meetingCategories));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function loadMeetingRequest(projectId, meetingId) {
  return (dispatch) => {
    return meetingsApi.getMeeting(projectId, meetingId).then((response) => {
      if (!response.ok) {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function initMeetingModal() {
  return {
    type: MEETING_MODAL_INIT,
  };
}

export function updateMeetingFormValue(attribute, value) {
  return {
    type: MEETING_FORM_VALUE_UPDATE,
    payload: { attribute: attribute, value: value },
  };
}

export function updateMeetingItemValue(key, attribute, value) {
  return {
    type: MEETING_ITEM_VALUE_UPDATE,
    payload: { key: key, attribute: attribute, value: value },
  };
}

export function addMeetingItem(key, item) {
  return {
    type: MEETING_ITEM_ADD,
    payload: { key: key, item: item },
  };
}

export function removeMeeting(projectId, meetingId) {
  return {
    type: MEETING_REMOVE,
    payload: { projectId: projectId, meetingId: meetingId },
  };
}

export function addNewMeetingItem(items, code, meetingCat) {
  const key = Object.keys(items).length + 1;
  const item = {
    code: code,
    meetingCategoryId: meetingCat.id,
    newItem: true,
    info: '',
    contributorIds: [],
    meetingCategory: {
      orderNumber: meetingCat.order_number,
    },
  };

  return (dispatch) => {
    dispatch(addMeetingItem(key, item));
  };
}

export function attachMeetingItems(items) {
  return {
    type: MEETING_ITEMS_ATTACH,
    payload: items,
  };
}

export function setShowAttendeesModal(show) {
  return {
    type: ATTENDEES_MODAL_SHOW_SET,
    payload: show,
  };
}

export function setReviewMeeting(review) {
  return {
    type: REVIEW_MEETING_SET,
    payload: review,
  };
}

export function createMeetingSuccess(meeting) {
  return {
    type: MEETINGS_ADD,
    payload: meeting,
  };
}

export function createMeeting(projectId, values, meetingTypeId) {
  return (dispatch) => {
    return meetingsApi
      .createMeeting(projectId, { ...values, meetingTypeId })
      .then((response) => {
        if (response.ok) {
          dispatch(createMeetingSuccess(response.meeting));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function updateMeetingSuccess(meeting) {
  return {
    type: MEETINGS_UPDATE,
    payload: meeting,
  };
}

export function updateMeeting(projectId, meetingId, values, meetingTypeId) {
  return (dispatch) => {
    return meetingsApi
      .updateMeeting(projectId, meetingId, { ...values, meetingTypeId })
      .then((response) => {
        if (response.ok) {
          dispatch(updateMeetingSuccess(response.meeting));
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
  };
}

export function setMeetingItemSettings(key, menu) {
  return {
    type: MEETING_ITEM_SETTINGS_SET,
    payload: { key: key, menu: menu },
  };
}

export function setCurrentMeeting(meeting) {
  return {
    type: CURRENT_MEETING_SET,
    payload: meeting,
  };
}

export function setConfirmationMsg(confirmationMsg) {
  return {
    type: CONFIRMATION_MSG_SET,
    payload: confirmationMsg,
  };
}

export function openMeeting(projectId, meetingId) {
  return (dispatch) => {
    dispatch(loadMeetingRequest(projectId, meetingId)).then((response) => {
      if (response.ok) {
        dispatch(setSelectedMeetingType(response.meeting.meeting_type_id));
        dispatch(setCurrentMeeting(response.meeting));
        dispatch(setMeetingModalShow(true));
      }
    });
  };
}

export function deleteMeetingRequest(projectId, meetingId) {
  return (dispatch) => {
    return meetingsApi.deleteMeeting(projectId, meetingId).then((response) => {
      if (response.ok) {
        dispatch(removeMeeting(projectId, meetingId));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function setConfirmApproval(confirmApproval) {
  return {
    type: CONFIRM_APPROVAL_SET,
    payload: confirmApproval,
  };
}

export function setMeetingTypes(meetingTypes = null) {
  return {
    type: MEETING_TYPES_SET,
    payload: meetingTypes,
  };
}

export function loadMeetingTypesRequest(projectId) {
  return (dispatch) => {
    return meetingTypesApi.getMeetingTypes(projectId).then((response) => {
      if (response.ok) {
        dispatch(setMeetingTypes(response.meetingTypes));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function setSelectedMeetingType(meetingType = null) {
  return {
    type: SELECTED_MEETING_TYPE_SET,
    payload: meetingType,
  };
}
