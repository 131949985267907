import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';

import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';

import SimpleTable from 'components/shared/table/SimpleTable';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import Checkbox from 'components/shared/checkbox/CheckBox';
import SearchBar from 'components/shared/navigation/SearchBar';
import Selector from 'components/shared/selector/Selector';
import DetailsInfoItem from 'components/shared/details/DetailsInfoItem';

import './UserPermissionsForm.css';

const PAGE_SIZE = 8;
const UserPermissionsForm = (props) => {
  const {
    dispatch,
    users,
    visibility,
    currentProject,
    directory,
    onVisibilityChange,
    onUsersChange,
    actionText,
    canChangePermissions,
  } = props;

  const defaultFilters = {
    page: 1,
    list_by: null,
    company_id: null,
    search: '',
  };
  const [filters, setFilters] = useState(defaultFilters);

  const handleFilterChange = (next) => {
    const newFilters = { ...filters, ...next };
    if (!Object.keys(next).includes('page')) newFilters.page = 1;
    setFilters(newFilters);
  };

  useEffect(() => {
    if (!directory || directory.project_id !== currentProject.id)
      dispatch(loadDirectoryRequest(currentProject.id));
  }, []);

  const isSelected = (user) => users.findIndex(({ id }) => id === user.id) >= 0;

  const { list, total, companies } = useMemo(() => {
    let list = [];
    let companies = [];

    // all
    if (directory) {
      directory.project_contributors.forEach((contributor) => {
        const { company, users: contributorUsers } = contributor;
        const companyName = company.name;
        contributorUsers.forEach((u) => list.push({ ...u, companyName }));
        companies.push({ value: company.id, label: companyName });
      });
    }

    // filter
    const { page, search, list_by, company_id } = filters;
    list = list.filter(({ full_name: name, accountable_id }) => {
      let res = true;
      if (res && search && search.length > 0)
        res = name.toLowerCase().indexOf(search.toLowerCase()) >= 0;
      if (res && company_id) res = accountable_id === company_id;
      return res;
    });

    // sort
    list = list.sort((a, b) => {
      if (list_by && list_by === 'company')
        return a.accountable_id - b.accountable_id;
      if (list_by && list_by === 'name')
        return a.full_name.localeCompare(b.full_name);

      const aSelected = isSelected(a);
      const bSelected = isSelected(b);
      if (aSelected !== bSelected) return aSelected ? -1 : 1;
      return a.full_name.localeCompare(b.full_name);
    });

    // paginate
    const total = list.length;
    const startList = (page - 1) * PAGE_SIZE;
    const endList = startList + PAGE_SIZE;
    list = list.slice(startList, endList);

    return { list, total, companies };
  }, [directory, filters]);

  const handleToggleUser = (user) => {
    const checked = isSelected(user);
    const result = users.filter(({ id }) => id !== user.id);
    if (!checked) result.push(user);
    onUsersChange(result);
  };

  const renderCheckbox = (checked, onChange) => {
    return (
      <Checkbox
        key="checkbox"
        checked={checked}
        className="permission-checkbox"
        shape="square"
        size="medium"
        onChange={onChange}
        title={checked ? 'Remove' : 'Add'}
        readOnly={!canChangePermissions}
      />
    );
  };

  const userItem = (user) => {
    const checked = isSelected(user);
    const onChange = () => handleToggleUser(user);
    return [
      renderCheckbox(checked, onChange),
      user.full_name,
      user.accountable_name,
    ];
  };

  const getPermissionLabel = (label) => {
    return actionText ? label + ' ' + actionText : label;
  };

  const areAllChecked = () => {
    return list.reduce((acc, curr) => acc && isSelected(curr), true);
  };
  const onCheckAll = () => {
    const result = [...users].filter(({ id }) => {
      return typeof list.find((u) => u.id === id) === 'undefined';
    });
    if (!areAllChecked()) list.forEach((u) => result.push(u));
    onUsersChange(result);
  };

  const visibilityInput = (
    <DetailsInfoItem
      title="Permissions"
      className="permission-label"
      lines={[
        <Selector
          options={[
            { value: 'public', label: getPermissionLabel('Public') },
            { value: 'private', label: getPermissionLabel('Private') },
            { value: 'shared', label: getPermissionLabel('Shared') },
          ]}
          disabled={!canChangePermissions}
          value={visibility}
          onChange={({ value }) => onVisibilityChange(value)}
        />,
      ]}
    />
  );
  const searchFilter = (
    <SearchBar
      searchPlaceHolder="Search"
      searchText={filters.search}
      onSearchTextChange={(search) => handleFilterChange({ search })}
      onSearch={(search) => handleFilterChange({ search })}
      onClearText={() => handleFilterChange({ search: '' })}
    ></SearchBar>
  );
  const listByFilter = (
    <DetailsInfoItem
      title="List By"
      className="permission-label"
      lines={[
        <Selector
          clearable
          options={[
            { value: 'name', label: 'Name' },
            { value: 'company', label: 'Company' },
          ]}
          value={filters.list_by}
          placeholder="Select Order"
          onChange={(o) =>
            handleFilterChange(
              o ? { list_by: o.value } : { list_by: null, company_id: null }
            )
          }
        />,
      ]}
    />
  );
  const companyFilter = (
    <DetailsInfoItem
      title="Company"
      className="permission-label"
      lines={[
        <Selector
          clearable
          options={companies}
          value={filters.company_id}
          onChange={(o) =>
            handleFilterChange({ company_id: o ? o.value : null })
          }
          placeholder="Select Company"
        />,
      ]}
    />
  );

  const isShared = visibility === 'shared';

  return (
    <div className="user-permissions-form">
      <div className="user-permissions-filters">
        <Grid>
          <Row>
            <Col sm={5}>
              {typeof onVisibilityChange === 'function' && visibilityInput}
            </Col>
            {isShared && <Col sm={7}>{searchFilter}</Col>}
          </Row>
          {isShared && (
            <Row style={{ marginTop: 24 }}>
              <Col sm={5}>{listByFilter}</Col>
              <Col sm={7}>{companyFilter}</Col>
            </Row>
          )}
        </Grid>
      </div>
      {isShared && (
        <div className="user-permissions-table">
          <SimpleTable
            headers={[
              renderCheckbox(areAllChecked(), onCheckAll),
              'NAME',
              'COMPANY NAME',
            ]}
            rows={list.map(userItem)}
            emptyMessage="No Users"
          />
          <SimplePagination
            currentPage={filters.page}
            pageSize={PAGE_SIZE}
            total={total}
            onPageChange={(page) => handleFilterChange({ page })}
          />
        </div>
      )}
    </div>
  );
};

UserPermissionsForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  users: PropTypes.array,
  visibility: PropTypes.string,
  currentProject: PropTypes.shape({}),
  directory: PropTypes.shape({}),
  onVisibilityChange: PropTypes.func,
  onUsersChange: PropTypes.func.isRequired,
  actionText: PropTypes.string,
  canChangePermissions: PropTypes.bool,
};

UserPermissionsForm.defaultProps = {
  users: [],
  visibility: 'shared',
  actionText: '',
  canChangePermissions: true,
};

export default connect((state) => ({
  directory: getDirectory(state),
  currentProject: getCurrentProject(state),
}))(UserPermissionsForm);
