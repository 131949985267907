import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import Menu, { MENU_SELECTOR } from 'components/shared/menu/Menu';

import SecurityCameraPermissions from 'permissions/security-camera-permissions';

import { getDarkMode } from 'selectors/theme';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';

import {
  getSecurityCamerasConfiguration,
  getSecurityCamerasConfigurationForm,
  getSecurityCamerasCamerasForm,
  getSecurityCamerasPermissionsForm,
} from './store/selectors';
import {
  setSecurityCameraConfiguration,
  loadSecurityCameraConfiguration,
  loadSecurityCameraConfigurationForm,
  loadSecurityCameraPermissionsForm,
  loadSecurityCameraCamerasForm,
} from './store/actions';
import SecurityCameras from './SecurityCameras';
import ConfigureModal from './ConfigureModal';
import PermissionsModal from './PermissionsModal';
import SecurityCamerasModal from './SecurityCamerasModal';
import './ProjectSecurityCameras.css';

const ProjectSecurityCameras = (props) => {
  const {
    dispatch,
    renderHeaders,
    darkMode,
    currentUser,
    currentProject,
    configuration,
    configurationForm,
    camerasForm,
    permissionsForm,
  } = props;

  const headerButtons = () => {
    const menuItems = [
      SecurityCameraPermissions.canConfigure(currentUser, currentProject) && {
        content: 'Configure',
        onClick: () =>
          dispatch(loadSecurityCameraConfigurationForm(currentProject.id)),
      },
      SecurityCameraPermissions.canEditPermissions(
        currentUser,
        currentProject,
        configuration
      ) && {
        content: 'Permissions',
        onClick: () =>
          dispatch(loadSecurityCameraPermissionsForm(currentProject.id)),
      },
      SecurityCameraPermissions.canEditCameras(
        currentUser,
        currentProject,
        configuration
      ) && {
        content: 'Select Cameras',
        onClick: () =>
          dispatch(loadSecurityCameraCamerasForm(currentProject.id)),
      },
    ].filter((item) => item);

    return [
      menuItems.length > 0 && (
        <Menu
          key="menu"
          selector={MENU_SELECTOR.MORE_OPTIONS_VERTICAL}
          items={menuItems}
        />
      ),
    ].filter((button) => button);
  };

  if (!currentProject) return null;

  useEffect(() => {
    dispatch(loadSecurityCameraConfiguration(currentProject.id));

    return () => {
      dispatch(setSecurityCameraConfiguration());
    };
  }, []);

  return (
    <div
      className={classnames('security-cameras-content-container', {
        'dark-mode': darkMode,
      })}
    >
      {renderHeaders(headerButtons())}
      <div className="content-container">
        <SecurityCameras
          projectId={currentProject.id}
          cameras={(configuration && configuration.security_cameras) || []}
        />
      </div>
      {configurationForm && <ConfigureModal />}
      {permissionsForm && <PermissionsModal />}
      {camerasForm && <SecurityCamerasModal />}
    </div>
  );
};

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    configuration: getSecurityCamerasConfiguration(state),
    configurationForm: getSecurityCamerasConfigurationForm(state),
    camerasForm: getSecurityCamerasCamerasForm(state),
    permissionsForm: getSecurityCamerasPermissionsForm(state),
    darkMode: getDarkMode(state),
  };
})(ProjectSecurityCameras);
