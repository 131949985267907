import {
  FIELD_WORK_ORDERS_COUNT,
  FIELD_WORK_ORDERS_LOAD,
} from 'components/admin/field-work-orders/store/action-types';

const initialState = {
  fieldWorkOrdersCount: 0,
  fieldWorkOrders: [],
  total: 0,
  currentPage: 1,
  pageSize: 25,
};

export default function fieldWorkOrdersDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case FIELD_WORK_ORDERS_COUNT:
      return {
        ...state,
        fieldWorkOrdersCount: action.payload,
      };

    case FIELD_WORK_ORDERS_LOAD:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
