import axios from 'axios';
import BaseApi from './base-api';
import { COMPANY_TYPES } from 'domain/company-type-enum';

class ReportsViewsApi extends BaseApi {
  get(viewId) {
    return axios
      .get(`${this.baseUrl}/report_views/${viewId}`)
      .then((response) => {
        return {
          ok: true,
          view: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  list() {
    return axios
      .get(`${this.baseUrl}/report_views`)
      .then((response) => {
        return {
          ok: true,
          views: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  availableCompanies() {
    return axios
      .get(`${this.baseUrl}/report_views/available_companies`)
      .then((response) => {
        return {
          ok: true,
          companies: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  availableProjects() {
    return axios
      .get(`${this.baseUrl}/report_views/available_projects`)
      .then((response) => {
        return {
          ...response.data,
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  mapMultipleValues = (values) => values.map((value) => value[0]);

  existsFieldMultipleValues = (values, field) =>
    !!values.find((value) => value[0] === field);

  booleanMapToArray = (map) => Object.keys(map).filter((key) => map[key]);

  makeDetailsRequest = (values) => ({
    name: values.name,
    report_type: values.report_type,
    scope: values.scope,
    start_date: values.start_date,
    end_date: values.end_date,
  });

  makeCompanyInformationRequest = (values, isNew) => ({
    id: !isNew ? values.id : null,
    suscriber_company_types: this.mapMultipleValues(
      values.suscriber_company_types
    ),
    suscriber_companies: this.mapMultipleValues(values.suscriber_companies),
    contributer_company_types: this.mapMultipleValues(
      values.contributer_company_types
    ),
    contributer_companies: this.mapMultipleValues(values.contributer_companies),
    contributer_companies_status: values.contributer_companies_status,
  });

  makeProjectInformationRequest = (values, isNew) => ({
    id: !isNew ? values.id : null,
    projects: this.mapMultipleValues(values.projects),
    street_address_1s: this.mapMultipleValues(values.street_address_1s),
    street_address_2s: this.mapMultipleValues(values.street_address_2s),
    cities: this.mapMultipleValues(values.cities),
    states: this.mapMultipleValues(values.states),
    zips: this.mapMultipleValues(values.zips),
    statuses: this.mapMultipleValues(values.statuses),
    conditions: this.mapMultipleValues(values.conditions),
    public: values.public !== null ? !!values.public : null,
    industries: this.mapMultipleValues(values.industries),
    industry_types: this.mapMultipleValues(values.industry_types),
    primary_users_counts: this.mapMultipleValues(values.primary_users_counts),
    sq_footages: this.mapMultipleValues(values.sq_footages),
    sq_footage_from: values.sq_footage_from,
    sq_footage_to: values.sq_footage_to,
    estimated_costs: this.mapMultipleValues(values.estimated_costs),
    estimated_cost_from: values.estimated_cost_from,
    estimated_cost_to: values.estimated_cost_to,
    est_start_date_from: values.est_start_date_from,
    est_start_date_to: values.est_start_date_to,
    est_completion_date_from: values.est_completion_date_from,
    est_completion_date_to: values.est_completion_date_to,
    est_duration: values.est_duration,
    creation_date_from: values.creation_date_from,
    creation_date_to: values.creation_date_to,
    termination_date_from: values.termination_date_from,
    termination_date_to: values.termination_date_to,
    duration: values.duration,
  });

  makeProjectUsageRequest = (values, isNew) => ({
    id: !isNew ? values.id : null,
    total_users_count: values.total_users_count,
    primary_users_count: values.primary_users_count,
    owner_authorized_reps_count: this.existsFieldMultipleValues(
      values.user_types_counts,
      COMPANY_TYPES.OWNER_AUTHORIZED_REP
    ),
    general_contractors_count: this.existsFieldMultipleValues(
      values.user_types_counts,
      COMPANY_TYPES.GENERAL_CONTRACTOR
    ),
    clients_count: this.existsFieldMultipleValues(
      values.user_types_counts,
      COMPANY_TYPES.CLIENT
    ),
    architects_count: this.existsFieldMultipleValues(
      values.user_types_counts,
      COMPANY_TYPES.ARCHITECT
    ),
    consultants_count: this.existsFieldMultipleValues(
      values.user_types_counts,
      COMPANY_TYPES.CONSULTANT
    ),
    landlords_count: this.existsFieldMultipleValues(
      values.user_types_counts,
      COMPANY_TYPES.LANDLORD
    ),
    property_managers_count: this.existsFieldMultipleValues(
      values.user_types_counts,
      COMPANY_TYPES.PROPERTY_MANAGER
    ),
    contractors_count: this.existsFieldMultipleValues(
      values.user_types_counts,
      COMPANY_TYPES.CONTRACTOR
    ),
    meetings_count: values.meetings_count,
    documents_count: values.documents_count,
    documents_size: values.documents_size,
    rfis_count: values.rfis_count,
    submittals_count: values.submittals_count,
    daily_reports_count: values.daily_reports_count,
    sub_daily_reports_count: values.sub_daily_reports_count,
    closeout_files_size: values.closeout_files_size,
    emails_sent_count: values.emails_sent_count,
    project_files_size: values.project_files_size,
  });

  makeMaterialOrdersRequest = (values, isNew) => ({
    id: !isNew ? values.id : null,
    active_count: this.existsFieldMultipleValues(
      values.status_counts,
      'active'
    ),
    canceled_count: this.existsFieldMultipleValues(
      values.status_counts,
      'canceled'
    ),
    revised_count: this.existsFieldMultipleValues(
      values.status_counts,
      'revised'
    ),
    order_date_from: values.order_date_from,
    order_date_to: values.order_date_to,
    customer_titles: this.mapMultipleValues(values.customer_titles),
    vendors: this.mapMultipleValues(values.vendors),
    products_revised: values.products_revised,
    products_removed: values.products_removed,
    items_offered: values.items_offered,
    categories: this.mapMultipleValues(values.categories),
    item_types: this.mapMultipleValues(values.item_types),
    vendor_notes: values.vendor_notes,
    lead_time: values.lead_time,
    brands: this.mapMultipleValues(values.brands),
    brands2: this.mapMultipleValues(values.brands2),
    manufacturers: this.mapMultipleValues(values.manufacturers),
    manufacturers2: this.mapMultipleValues(values.manufacturers2),
    title: values.title,
    title2: values.title2,
    description: values.description,
    description2: values.description2,
    multipack: values.multipack,
    details: this.booleanMapToArray(values.details),
    ids: this.booleanMapToArray(values.ids),
    features: this.booleanMapToArray(values.features),
  });

  makeSubscriptionRequest = (values, isNew) => ({
    id: !isNew ? values.id : null,
    per_term_report: values.per_term_report,
    terms: this.mapMultipleValues(values.terms),
    fee: values.fee,
    payment_mode: values.payment_mode,
    start_date: values.start_date,
    cancel_date: values.cancel_date,
    duration: values.duration,
    renewal_terms: this.mapMultipleValues(values.renewal_terms),
    renewal_total_terms: values.renewal_total_terms,
    renewal_fee: values.renewal_fee,
    renewal_paid_to_date: values.renewal_paid_to_date,
    renewal_payment_mode: values.renewal_payment_mode,
    renewal_start_date: values.renewal_start_date,
    renewal_cancel_date: values.renewal_cancel_date,
    renewal_duration: values.renewal_duration,
    archived_terms: this.mapMultipleValues(values.archived_terms),
    archived_total_terms: values.archived_total_terms,
    archived_fee: values.archived_fee,
    archived_paid_to_date: values.archived_paid_to_date,
    archived_payment_mode: values.archived_payment_mode,
    archived_start_date: values.archived_start_date,
    archived_cancel_date: values.archived_cancel_date,
    archived_duration: values.archived_duration,
    statuses: this.mapMultipleValues(values.statuses),
    counts: this.mapMultipleValues(values.counts),
    times_suspended: values.times_suspended,
    total_project_count: values.total_project_count,
  });

  makeRequest = (values, isNew = false) => ({
    report_view: {
      ...this.makeDetailsRequest(values.details),
      report_view_company_information_attributes: this.makeCompanyInformationRequest(
        values.company_information,
        isNew
      ),
      report_view_project_information_attributes: this.makeProjectInformationRequest(
        values.project_information,
        isNew
      ),
      report_view_project_usage_attributes: this.makeProjectUsageRequest(
        values.project_usage,
        isNew
      ),
      report_view_material_order_attributes: this.makeMaterialOrdersRequest(
        values.material_order,
        isNew
      ),
      report_view_subscription_attributes: this.makeSubscriptionRequest(
        values.subscription,
        isNew
      ),
      status: values.status || 'draft',
    },
  });

  create(values) {
    const request = this.makeRequest(values, true);

    return axios
      .post(`${this.baseUrl}/report_views`, request)
      .then((response) => {
        return {
          ok: true,
          view: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  run(values) {
    return this.create({
      ...values,
      details: {
        ...values.details,
        name: null,
      },
      status: 'pending',
    });
  }

  update(viewId, values) {
    const request = this.makeRequest(values);

    return axios
      .put(`${this.baseUrl}/report_views/${viewId}`, request)
      .then((response) => {
        return {
          ok: true,
          view: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  delete(viewId) {
    return axios
      .delete(`${this.baseUrl}/report_views/${viewId}`)
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const reportsViewsApi = new ReportsViewsApi();
