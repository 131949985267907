import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import classnames from 'classnames';
import './StampForm.css';
import editPencil from 'images/edit-pencil.svg';
import editPencilDark from 'images/edit-pencil-dark.svg';
import StampActions from 'domain/stamp-actions';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import Input from 'components/shared/input/Input';
import TextareaAutosize from 'react-autosize-textarea';
import Button, { BUTTON_COLORS } from 'components/shared/button/Button';

const propTypes = {
  stamp: PropTypes.shape({}),
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
const defaultProps = {
  stamp: { destroy: true },
  error: false,
};

class StampForm extends Component {
  buildLabel = (action, stampAction) => {
    const { stamp, darkMode } = this.props;
    return (
      <div className="stamp-action-label">
        {!stampAction || stampAction.destroy || !stampAction.edit ? (
          <React.Fragment>
            <div className="stamp-action-attr">
              <span className="stamp-action-title">
                {(stampAction && stampAction.title) || action.title}
              </span>
              {stampAction && !stampAction.destroy && !stampAction.edit && (
                <img
                  className={classnames({
                    'stamp-action-edit': true,
                    'stamp-action-edit-read-only': !!stamp.destroy,
                  })}
                  src={darkMode ? editPencilDark : editPencil}
                  alt="edit"
                  onClick={() =>
                    !stamp.destroy &&
                    this.handleStampActionChange(stampAction, 'edit', true)
                  }
                />
              )}
            </div>
            {((stampAction && stampAction.subtitle) ||
              ((!stampAction || !stampAction.title) && action.subtitle)) && (
              <div className="stamp-action-attr">
                <span>
                  {(stampAction && stampAction.subtitle) || action.subtitle}
                </span>
                {stampAction && !stampAction.destroy && !stampAction.edit && (
                  <img
                    className={classnames({
                      'stamp-action-edit': true,
                      'stamp-action-edit-read-only': !!stamp.destroy,
                    })}
                    src={editPencil}
                    alt="edit"
                    onClick={() =>
                      !stamp.destroy &&
                      this.handleStampActionChange(stampAction, 'edit', true)
                    }
                  />
                )}
              </div>
            )}
            {stampAction &&
              stampAction.title &&
              stampAction.title !== action.title && (
                <div className="stamp-action-original-label">
                  Original Label: {action.title}
                </div>
              )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Input
              className={classnames({
                'stamp-action-input': true,
                'stamp-action-title-input': true,
                'stamp-invalid': stampAction.saveFailed && !stampAction.title,
              })}
              placeholder={!stamp.destroy ? action.title : 'Title'}
              value={stampAction.title}
              readOnly={!!stamp.destroy}
              onChange={(value) =>
                this.handleStampActionChange(stampAction, 'title', value)
              }
            />
            <Input
              className="stamp-action-input stamp-action-subtitle-input"
              placeholder={!stamp.destroy ? action.subtitle : 'Subtitle'}
              value={stampAction.subtitle}
              readOnly={!!stamp.destroy}
              onChange={(value) =>
                this.handleStampActionChange(stampAction, 'subtitle', value)
              }
            />
            <div className="stamp-action-buttons">
              <Button
                className="stamp-action-save"
                color={BUTTON_COLORS.WHITE}
                label="Save"
                onClick={() => {
                  if (!stamp.destroy) {
                    if (stampAction.title) {
                      this.handleStampActionChange(stampAction, 'edit', false);
                    } else {
                      this.handleStampActionChange(
                        stampAction,
                        'saveFailed',
                        true
                      );
                    }
                  }
                }}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  findStampAction = (action) => {
    const { stamp } = this.props;
    return (
      stamp.stampActions &&
      stamp.stampActions.find((stampAction) => stampAction.action === action)
    );
  };

  handleStampActionSelect = (action, value) => {
    const { stamp } = this.props;
    const stampAction = this.findStampAction(action.action);
    let stampActions = stamp.stampActions || [];
    if (stampAction) {
      stampActions = [
        ...stampActions.filter((stampAct) => stampAct.action !== action.action),
        { ...stampAction, destroy: !value, edit: false },
      ];
    } else {
      stampActions = [...stampActions, { ...action }];
    }

    this.handleFormChange('stampActions', stampActions);
  };

  handleStampActionChange = (stampAction, attr, value) => {
    const { stamp } = this.props;
    const stampActions = [
      ...stamp.stampActions.filter(
        (prevStampAction) => prevStampAction.action !== stampAction.action
      ),
      { ...stampAction, [attr]: value },
    ];
    this.handleFormChange('stampActions', stampActions);
  };

  handleFormChange = (attr, value) => {
    this.props.onChange(attr, value);
  };

  render() {
    const { stamp, error, darkMode } = this.props;
    return (
      <div className={`stamp ${darkMode ? 'dark-mode' : ''}`}>
        <div className="stamp-header">
          <LabeledCheckBox
            checked={!stamp.destroy}
            label="Create a Custom Stamp"
            size="small"
            position="left"
            onChange={(value) => this.handleFormChange('destroy', !value)}
          />
        </div>
        <div className={classnames({ 'stamp-read-only': !!stamp.destroy })}>
          <div className="stamp-block">
            <p
              className={classnames({
                'small-bold': true,
                'stamp-label': true,
                'stamp-label-invalid':
                  error &&
                  (!stamp.stampActions ||
                    !stamp.stampActions.find(
                      (stampAction) => !stampAction.destroy
                    )),
              })}
            >
              Select and edit the call to actions you want to use.
            </p>
            <p className="small-regular stamp-disclaimer">
              Please be advised that the action must remain the same as the
              verbiage is the only thing that can be edited.
            </p>
            {StampActions.map((action, index) => {
              const stampAction = this.findStampAction(action.action);
              return (
                <div key={index} className="stamp-action">
                  <LabeledCheckBox
                    checked={!!stampAction && !stampAction.destroy}
                    label={this.buildLabel(action, stampAction)}
                    readOnly={!!stamp.destroy}
                    size="small"
                    position="left"
                    onChange={(value) =>
                      this.handleStampActionSelect(action, value)
                    }
                  />
                </div>
              );
            })}
          </div>
          <div className="stamp-block stamp-legal">
            <p className="small-bold stamp-label">Legal Information.</p>
            <TextareaAutosize
              rows={5}
              className={classnames({
                'text-area': true,
                'input-area': true,
                'form-control': true,
                'dark-mode': darkMode,
                invalid: error && !stamp.legal,
              })}
              placeholder="Legal"
              defaultValue={stamp.legal || ''}
              readOnly={!!stamp.destroy}
              onBlur={(e) => this.handleFormChange('legal', e.target.value)}
            />
          </div>
        </div>
      </div>
    );
  }
}

StampForm.propTypes = propTypes;
StampForm.defaultProps = defaultProps;

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(StampForm);
