import React from 'react';
import { connect } from 'react-redux';
import FormModal from 'components/shared/modal/FormModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import { getDarkMode } from 'selectors/theme';
import { RfiStatusKeys, RfiStatusEnum } from 'domain/rfi-status-enum';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getRfiResponseModalShow,
  getRfiForm,
  getRfiQuestions,
} from './store/selectors';
import Rfi from 'domain/rfi';

import './RfiResponseModal.css';
import './RfiActions.css';
import {
  setRfiResponseModalShow,
  setRfiFormErrors,
  setRfiQuestionErrors,
  respondRfiAddlAssignee,
  setSetShowRfiErrorMessage,
  openRfi,
  respondRfi,
} from './store/actions';
import RfiQuestions from './RfiQuestions';

const RfiResponseModal = (props) => {
  const {
    rfiResponseModalShow,
    rfi,
    currentUser,
    contribType,
    gcAcceleration,
    project,
    dispatch,
    directory,
  } = props;

  const handleClose = () => {
    dispatch(setRfiResponseModalShow(false));
  };

  const addlAssigneeQuestions = (questions) => {
    const { currentUser } = props;
    return questions.filter(
      (question) =>
        !!question.rfi_addl_assignees.find(
          (addlAssignee) => addlAssignee.assignee_id === currentUser.id
        )
    );
  };
  const hasAssignedQuestions = () => {
    const { currentUser, currentRfi } = props;
    return currentRfi
      ? currentRfi.rfi_questions.filter(
          (question) =>
            question.assignee_id === currentUser.id ||
            !!question.rfi_addl_assignees.find(
              (addlAssignee) => addlAssignee.assignee_id === currentUser.id
            )
        )
      : [];
  };

  const validateForm = (draft = false) => {
    const { readOnly, dispatch, rfi } = props;
    const errors = {};
    const status = RfiStatusEnum.create(rfi.status);
    if (!readOnly) {
      if (!rfi.csi_code_id) {
        errors.csiCodeId = true;
      }
      if (!draft) {
        if (!rfi.due_date && !RfiStatusEnum.FOR_RECORD.equals(status)) {
          errors.dueDate = true;
        }
        if (!rfi.status) {
          errors.status = true;
        }
        if (!rfi.title) {
          errors.title = true;
        }
      }
      dispatch(setRfiFormErrors(errors));
    }
    return Object.keys(errors).length === 0;
  };

  const validateQuestions = (draft = false) => {
    const { gcAcceleration, questions, dispatch } = props;
    const status = RfiStatusEnum.create(rfi.status);
    const assignedQuestions = hasAssignedQuestions();
    const oneAssignedQuestion =
      assignedQuestions && assignedQuestions.length === 1;
    let valid = true;
    Object.keys(questions).forEach((key) => {
      const question = questions[key];
      const assigned =
        oneAssignedQuestion && assignedQuestions[0].id === question.id;
      const errors = {};
      if (!question.action) {
        errors.action = true;
      }
      if (!question.question) {
        errors.question = true;
      }
      if (
        !draft &&
        RfiStatusEnum.ASSIGNED.equals(status) &&
        question.assignee.length === 0
      ) {
        errors.assignee = true;
      }
      if (
        !draft &&
        (assigned || gcAcceleration) &&
        question.id &&
        !question.response
      ) {
        errors.response = true;
      }
      dispatch(setRfiQuestionErrors(key, errors));
      valid = valid && Object.keys(errors).length === 0;
    });
    return valid;
  };

  const validate = (draft = false) => {
    const validForm = validateForm(draft);
    const validQuestions = validateQuestions(draft);
    return validForm && validQuestions;
  };

  const handleSubmit = (draft = false) => {
    if (!validate(draft)) {
      props.dispatch(setSetShowRfiErrorMessage(true));
      return;
    }
    const questions = Object.keys(props.questions)
      .map((key) => props.questions[key])
      .map((qus) => {
        return {
          ...qus,
          assigneeId: qus.assignee[0] || '',
          draftResponse: draft,
          reqImages: qus.reqImages || qus.request_images,
          reqDocs: qus.reqDocs || qus.request_documents,
        };
      });
    const { form } = props;
    const currentRfi = {
      ...form,
      relCsiCodeIds: form.relCsiCodes.map((csiCode) => csiCode[0]),
      recipientIds: form.recipients.map((recpt) => recpt[0]),
      draft: draft || '',
      status: draft ? RfiStatusKeys.DRAFT : form.status,
      questions: questions,
      questionsDeleted: props.questionsDeleted,
    };
    const { currentUser, project, gcAcceleration, readOnly, dispatch } = props;
    const promises = [];
    if (currentRfi) {
      if (gcAcceleration) {
        promises.push(
          dispatch(respondRfi(project.id, currentRfi.id, currentRfi))
        );
      } else {
        if (Rfi.isAssignedToUser(currentUser, rfi)) {
          currentRfi.questions = currentRfi.questions.filter(
            (qus) => qus.assignee[0] === currentUser.id
          );
          promises.push(
            dispatch(respondRfi(project.id, currentRfi.id, currentRfi))
          );
        }

        if (Rfi.isAddlAssignedToUser(currentUser, currentRfi)) {
          promises.push(
            dispatch(
              respondRfiAddlAssignee(
                currentUser.id,
                addlAssigneeQuestions(questions)
              )
            )
          );
        }
      }
    }
    Promise.all(promises).then((responses) => {
      if (!responses.find((response) => !response.ok)) {
        handleClose();
        dispatch(openRfi(project.id, currentRfi.id, readOnly));
      }
    });
  };

  return (
    <FormModal
      show={rfiResponseModalShow}
      subTitle={`RFI ${rfi ? rfi.number : ''} ${
        rfi ? rfi.title : ''
      } Responses`}
      className="rfi-response-modal"
      onClose={handleClose}
    >
      <div className="rfi-response-modal-response-container">
        <RfiQuestions
          currentUser={currentUser}
          contribType={contribType}
          project={project}
          directory={directory}
          currentRfi={rfi}
          readOnly={false}
          modalReadOnly={false}
          gcAcceleration={gcAcceleration}
          showResponses={true}
          questionsReadOnly={true}
          filterForUser={true}
        />
        <div className="rfi-response-actions">
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.WHITE}
            label="Cancel"
            onClick={handleClose}
          />
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label="Submit"
            onClick={() => handleSubmit(false)}
          />
        </div>
      </div>
    </FormModal>
  );
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
    rfiResponseModalShow: getRfiResponseModalShow(state),
    form: getRfiForm(state),
    questions: getRfiQuestions(state),
    project: getCurrentProject(state),
  };
})(RfiResponseModal);
