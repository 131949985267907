import React from 'react';
import { connect } from 'react-redux';

import sortSvg from 'images/sortSvg.svg';
import sortSvgDark from 'images/sortDarkSvg.svg';
import FilterHeader from 'components/shared/table/FilterHeader';
import { SORT_OPTIONS } from 'domain/project-photo';
import { getDarkMode } from 'selectors/theme';

import { getProjectPhotosFilters } from './store/selectors';
import { setProjectPhotosFilters } from './store/actions';

const ProjectPhotosFilterSort = (props) => {
  const { dispatch, filters, darkMode } = props;

  const onFilter = (sort) => {
    dispatch(setProjectPhotosFilters({ sort, doSearch: true }));
  };

  return (
    <FilterHeader
      label={''}
      className="sort-filter"
      icon={darkMode ? sortSvgDark : sortSvg}
      options={SORT_OPTIONS}
      selected={filters.sort}
      onFilter={onFilter}
    />
  );
};

export default connect((state) => ({
  filters: getProjectPhotosFilters(state),
  darkMode: getDarkMode(state),
}))(ProjectPhotosFilterSort);
