import WorkOrdersApi, { workOrdersApi } from 'services/api/work-orders-api';
import TradesApi from 'services/api/trades-api';
import { push, goBack } from 'react-router-redux';
import {
  WORK_ORDERS_CREATION_LOAD_BUILDINGS_SUCCESS,
  WORK_ORDERS_LOAD_SUCCESS,
  WORK_ORDERS_WO_LOAD_TASKS_SUCCESS,
  WORK_ORDERS_WO_SELECT_SUCCESS,
  WORK_ORDERS_WORK_ORDER_CREATION_SUCCESS,
  WORK_ORDERS_WO_ADD,
  WORK_ORDERS_LOAD_TRADES_SUCCESS,
  WORK_ORDERS_LOAD_SUB_TRADES_SUCCESS,
  WORK_ORDERS_LOAD_CONTRACTORS_SUCCESS,
  WORK_ORDERS_CONTRACTOR_NEW_SUCCESS,
  WORK_ORDERS_WO_UPDATE,
  WORK_ORDERS_UNION_TRADES_LOAD_SUCCESS,
  WORK_ORDERS_SUMMARY_LOAD_SUCCESS,
  WORK_ORDERS_TASK_UPDATE_SUCCESS,
  WORK_ORDERS_TASK_UPDATE,
  WORK_ORDERS_PAGE_CHANGE,
  WORK_ORDERS_CURRENT_WO_COMPLETE,
  WORK_ORDERS_TASK_REMOVE,
  WORK_ORDERS_SUMMARY_TASK_REMOVE,
  WORK_ORDERS_TASK_CHECKLIST_UPDATE,
  WORK_ORDERS_CURRENT_WO_ATTRIBUTE_UPDATE,
  WORK_ORDERS_SEARCH_TEXT_CHANGE,
  WORK_ORDERS_INVOICES_TAB_CHANGE,
  WORK_ORDERS_ADD_BUILDING_TO_BEGINNING,
} from './action-types';
import { WorkOrderStatusEnum } from 'services/utils/work-order-status-enum';
import { showErrorMessage } from 'actions/messages';
import { setNewWOProcessStarted } from './creation/sub-contractors-union-trades';
import { selectTaskSuccess } from './details/details';
import { WORK_ORDERS_WO_ATTRIBUTE_UPDATE } from 'actions/admin/work-orders/action-types';

export const baseRoutePath = '/admin/work-orders';

export function addWorkOrder() {
  return (dispatch) => {
    dispatch({
      type: WORK_ORDERS_WO_ADD,
    });
    dispatch(setNewWOProcessStarted(true));
    dispatch(push(baseRoutePath + '/new-work-order'));
  };
}

// export function getWorkOrdersByJobNumber(value) {
//   return dispatch => {
//     return workOrdersApi.getWorkOrdersByJobNumber(value)
//       .then(response => {
//         if (response.ok) {
//           dispatch(loadWorkOrdersSuccess(response.workOrders));
//           dispatch(searchTextChange(''));
//
//         } else {
//           dispatch(showErrorMessage(response.errors));
//         }
//       });
//   }
// }

export function goToWorkOrder(workOrderId, workOrderStatus) {
  return (dispatch) => {
    if (
      workOrderStatus !== undefined &&
      WorkOrderStatusEnum.DRAFT.equals(
        WorkOrderStatusEnum.create(workOrderStatus)
      )
    ) {
      dispatch(push(`${baseRoutePath}/work-order-summary`));
    } else {
      dispatch(selectTaskSuccess(null)); // unselect task if necessary
      dispatch(push(`${baseRoutePath}/${workOrderId}`));
    }
  };
}

export function goHistoryBack() {
  return (dispatch) => {
    dispatch(goBack());
  };
}

export function cancelWorkOrderRequest(workOrderId) {
  return (dispatch) => {
    return WorkOrdersApi.deleteWorkOrder(workOrderId).then((response) => {
      if (response.ok) {
        dispatch(push(baseRoutePath));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function changePage(newPage) {
  return {
    type: WORK_ORDERS_PAGE_CHANGE,
    payload: newPage,
  };
}

export function changeInvoicesTab(newTab) {
  return {
    type: WORK_ORDERS_INVOICES_TAB_CHANGE,
    payload: newTab,
  };
}

export function removeTask(id) {
  return {
    type: WORK_ORDERS_TASK_REMOVE,
    payload: id,
  };
}

export function removeTaskFromSummary(id) {
  return {
    type: WORK_ORDERS_SUMMARY_TASK_REMOVE,
    payload: id,
  };
}

export function loadWorkOrdersRequest(criteria) {
  return (dispatch) => {
    return workOrdersApi.getWorkOrders(criteria).then((response) => {
      if (response.ok) {
        dispatch(loadWorkOrdersSuccess(response.workOrders));
        dispatch(changePage(criteria.page));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function loadWorkOrdersSuccess(workOrders) {
  return {
    type: WORK_ORDERS_LOAD_SUCCESS,
    payload: workOrders,
  };
}

export function selectWorkOrderRequest(workOrderId, status) {
  return (dispatch) => {
    return WorkOrdersApi.getWorkOrder(workOrderId).then((response) => {
      if (response.ok) {
        dispatch(selectWorkOrderSuccess(response.workOrder));
        dispatch(goToWorkOrder(workOrderId, status));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function selectWorkOrderSuccess(workOrder) {
  return {
    type: WORK_ORDERS_WO_SELECT_SUCCESS,
    payload: workOrder,
  };
}

export function loadTasksFromWorkOrderSuccess(tasks) {
  return {
    type: WORK_ORDERS_WO_LOAD_TASKS_SUCCESS,
    payload: tasks,
  };
}

export function loadBuildingSuccess(buildings) {
  return {
    type: WORK_ORDERS_CREATION_LOAD_BUILDINGS_SUCCESS,
    payload: buildings,
  };
}

export function createWorkOrderSuccess(newWorkOrder) {
  return {
    type: WORK_ORDERS_WORK_ORDER_CREATION_SUCCESS,
    payload: newWorkOrder,
  };
}

export function loadTradesSuccess(trades) {
  return {
    type: WORK_ORDERS_LOAD_TRADES_SUCCESS,
    payload: trades,
  };
}

export function loadSubTradesRequest(tradeId) {
  return (dispatch) => {
    return TradesApi.getSubTrades(tradeId).then((response) => {
      if (response.ok) {
        dispatch(loadSubTradesSuccess(tradeId, response.subTrades));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadSubTradesSuccess(tradeId, subTrades) {
  return {
    type: WORK_ORDERS_LOAD_SUB_TRADES_SUCCESS,
    payload: {
      tradeId,
      subTrades,
    },
  };
}

export function loadContractorsSuccess(contractors) {
  return {
    type: WORK_ORDERS_LOAD_CONTRACTORS_SUCCESS,
    payload: contractors,
  };
}

export function newContractorSuccess(newContractor) {
  return {
    type: WORK_ORDERS_CONTRACTOR_NEW_SUCCESS,
    payload: newContractor,
  };
}

export function updateCurrentWorkOrderAttribute(attribute, value) {
  return {
    type: WORK_ORDERS_CURRENT_WO_ATTRIBUTE_UPDATE,
    payload: { attribute, value },
  };
}

export function updateWorkOrder(workOrder) {
  return {
    type: WORK_ORDERS_WO_UPDATE,
    payload: workOrder,
  };
}

export function updateWorkOrderAttribute(workOrder, attribute) {
  return {
    type: WORK_ORDERS_WO_ATTRIBUTE_UPDATE,
    payload: { workOrder, attribute },
  };
}

export function loadUnionTradesSuccess(unionTrades) {
  return {
    type: WORK_ORDERS_UNION_TRADES_LOAD_SUCCESS,
    payload: unionTrades,
  };
}

export function loadWorkOrderSummarySuccess(summary) {
  return {
    type: WORK_ORDERS_SUMMARY_LOAD_SUCCESS,
    payload: summary,
  };
}

export function searchTextChange(value) {
  return {
    type: WORK_ORDERS_SEARCH_TEXT_CHANGE,
    payload: value,
  };
}

export function updateTask(task) {
  return {
    type: WORK_ORDERS_TASK_UPDATE,
    payload: task,
  };
}

export function updateTaskChecklist(taskId, taskChecklist) {
  return {
    type: WORK_ORDERS_TASK_CHECKLIST_UPDATE,
    payload: { taskId, taskChecklist },
  };
}

export function updateTaskSuccess() {
  return {
    type: WORK_ORDERS_TASK_UPDATE_SUCCESS,
  };
}

export function markCurrentWorkOrderAsComplete() {
  return {
    type: WORK_ORDERS_CURRENT_WO_COMPLETE,
    payload: 'complete',
  };
}

export function addBuildingToBeginning(building) {
  return {
    type: WORK_ORDERS_ADD_BUILDING_TO_BEGINNING,
    payload: building,
  };
}
