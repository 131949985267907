import React, { useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { useMedia } from 'react-use';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Text,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import colors from 'styles/colors';
import Number from 'components/shared/text/Number';
import { getDarkMode } from 'selectors/theme';
import { getReportMetrics } from './store/selectors';
import { numberWithUnit } from 'services/utils/text-util';
import viewMoreSvg from 'components/shared/swiper/view-more.svg';
import viewMoreSvgDark from 'components/shared/swiper/view-more-dark.svg';
import PropTypes from 'prop-types';
import './Budget.css';
import BudgetModal from './BudgetModal';

const ROWS_TO_SHOW = 7;
const Budget = (props) => {
  const { metrics, darkMode, expandable, scrollable } = props;
  const isMedium = useMedia('(max-height: 1000px), (max-width: 1600px)');
  const isSmall = useMedia('(max-height: 800px), (max-width: 1300px)');
  const [focusBar, setFocusBar] = useState(null);
  const [mouseLeave, setMouseLeave] = useState(true);
  const [modal, setModal] = useState(false);

  const makeDisabledRows = () =>
    [...Array(ROWS_TO_SHOW)].map(() => ({
      name: '',
      original: 1000000,
      current: 1000000,
    }));

  const makeRows = () => {
    if (!metrics || !metrics.budget) return [];

    let result = metrics.budget.map((row) => ({
      ...row,
      original: parseFloat(row.original, 10),
      current: parseFloat(row.current, 10),
    }));
    result.sort(
      (a, b) =>
        Math.max(b.current, b.original) - Math.max(a.current, a.original)
    );

    if (expandable && result.length > ROWS_TO_SHOW) {
      const others = result.splice(ROWS_TO_SHOW - 1, result.length);
      const other = others.reduce(
        (acc, row, i) => ({
          current: acc.current + row.current,
          original: acc.original + row.original,
          name: acc.name,
          label: acc.label + `${i > 0 ? ',' : ''} ${row.name}`,
        }),
        { current: 0, original: 0, name: 'Others', label: '' }
      );
      result.push(other);
    }
    return result;
  };

  let rows = makeRows();
  const disabled = rows.length === 0;
  if (disabled) rows = makeDisabledRows();
  const totals = rows.reduce(
    (totals, row) => ({
      current: totals.current + row.current,
      original: totals.original + row.original,
    }),
    { current: 0, original: 0 }
  );
  const colorOriginal =
    colors[disabled ? 'light-mode-medium-black' : 'chart-blue'];
  const colorCurrent = colors[disabled ? 'light-mode-black-text' : 'chart-red'];

  let tooltipBar = null;
  const BudgetTooltip = ({ active, payload }) => {
    if (!(active && payload && payload.length)) return null;
    const { name, original, current, label } = payload[0].payload;

    let fields = [
      { label: 'Current', value: current, total: totals.current },
      { label: 'Original', value: original, total: totals.original },
    ];
    if (tooltipBar === 'original') fields = fields.reverse();

    return (
      <div className={classnames('budget-tooltip', { 'dark-mode': darkMode })}>
        <p className="budget-tooltip-name">
          {typeof label != 'undefined' ? label : name}
        </p>
        <p className="budget-tooltip-main-value">
          {`${fields[0].label} `}
          <Number value={fields[0].value} format="$0,0" />
        </p>
        <p className="budget-tooltip-value budget">
          {`${fields[1].label} `}
          <Number value={fields[1].value} format="$0,0" />
        </p>
        <p className="budget-tooltip-value">
          {`Variance `}
          <Number value={original - current} format="$0,0" />
        </p>
        <p className="budget-tooltip-value budget-tooltip-percentage">
          {`${Math.round(
            (fields[0].value * 100) / fields[0].total
          )}% of Project Total`}
        </p>
      </div>
    );
  };

  return (
    <>
      <div
        className={classnames('project-dashboard-budget', {
          'dark-mode': darkMode,
        })}
      >
        <div className="budget-header">
          <div className="budget-title">Budget</div>
          <div className="budget-legend">
            <div className="budget-legend-item">
              <div
                className="budget-legend-item-icon"
                style={{ backgroundColor: colorOriginal }}
              />
              <div className="budget-legend-item-label">Original</div>
            </div>
            <div className="budget-legend-item">
              <div
                className="budget-legend-item-icon"
                style={{ backgroundColor: colorCurrent }}
              />
              <div className="budget-legend-item-label">Current</div>
            </div>
          </div>
          {expandable && (
            <div className="budget-view-more" onClick={() => setModal(true)}>
              <span>View More</span>
              <img
                src={darkMode ? viewMoreSvgDark : viewMoreSvg}
                alt="view-more"
              />
            </div>
          )}
        </div>
        <div className={'budget-chart' + (scrollable ? ' scrollable' : '')}>
          <ResponsiveContainer
            width={'100%'}
            height="100%"
            minWidth={scrollable ? rows.length * 100 : undefined}
          >
            <BarChart
              data={rows}
              barGap={0}
              barSize={20}
              margin={
                isSmall
                  ? {
                      top: 8,
                      right: 16,
                      left: 8,
                      bottom: 4,
                    }
                  : isMedium
                  ? {
                      top: 12,
                      right: 24,
                      left: 16,
                      bottom: 8,
                    }
                  : {
                      top: 20,
                      right: 32,
                      left: 8,
                      bottom: 16,
                    }
              }
              onMouseMove={(state) => {
                if (!state) return;
                if (state.isTooltipActive) {
                  setFocusBar(state.activeTooltipIndex);
                  setMouseLeave(false);
                } else {
                  setFocusBar(null);
                  setMouseLeave(true);
                }
              }}
            >
              <defs>
                <linearGradient id="colorOriginal" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="15%"
                    stopColor={colorOriginal}
                    stopOpacity={0.4}
                  />
                  <stop
                    offset="85%"
                    stopColor={colorOriginal}
                    stopOpacity={1}
                  />
                </linearGradient>
                <linearGradient id="colorCurrent" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="15%"
                    stopColor={colorCurrent}
                    stopOpacity={0.4}
                  />
                  <stop offset="85%" stopColor={colorCurrent} stopOpacity={1} />
                </linearGradient>
              </defs>
              <CartesianGrid
                stroke={colors[darkMode ? 'dark-line-grey' : 'light-line-grey']}
                vertical={false}
              />
              <XAxis
                interval={0}
                height={isSmall ? 42 : 62}
                dataKey="name"
                axisLine={false}
                tickLine={false}
                tickMargin={isSmall ? 0 : 6}
                lineHeight="24px"
                tick={(props) => (
                  <Text
                    {...props}
                    className="recharts-cartesian-axis-tick-value"
                    lineHeight={isSmall ? 18 : 24}
                    width={isSmall ? 72 : 96}
                    fontSize={isSmall ? 12 : 16}
                    fill={
                      colors[darkMode ? 'white-text' : 'light-mode-black-text']
                    }
                    style={
                      {
                        /* needed to recalculate words */
                      }
                    }
                  >
                    {props.payload.value}
                  </Text>
                )}
              />
              <YAxis
                width={isSmall ? 48 : 52}
                axisLine={false}
                tickLine={false}
                tick={{
                  fontSize: isSmall ? 12 : 14,
                  fill:
                    colors[
                      darkMode
                        ? 'dark-mode-clear-gray'
                        : 'light-mode-medium-black'
                    ],
                }}
                tickFormatter={(value) => `$ ${numberWithUnit(value)}`}
              />
              <Bar
                dataKey="original"
                name="Original"
                fill={colorOriginal}
                onMouseOver={() => (tooltipBar = 'original')}
                onMouseLeave={() => (tooltipBar = null)}
              >
                {rows.map((entry, index) => (
                  <Cell
                    key={index}
                    fill="url(#colorOriginal)"
                    opacity={focusBar === index || mouseLeave ? 1 : 0.5}
                  />
                ))}
              </Bar>
              <Bar
                dataKey="current"
                name="Current"
                fill={colorCurrent}
                onMouseOver={() => (tooltipBar = 'current')}
                onMouseLeave={() => (tooltipBar = null)}
              >
                {rows.map((entry, index) => (
                  <Cell
                    key={index}
                    fill="url(#colorCurrent)"
                    opacity={focusBar === index || mouseLeave ? 1 : 0.5}
                  />
                ))}
              </Bar>
              {!disabled && (
                <Tooltip
                  content={<BudgetTooltip />}
                  cursor={false}
                  allowEscapeViewBox={{ y: true }}
                  position={{ y: 16 }}
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      {expandable && (
        <BudgetModal show={modal} onHide={() => setModal(false)} />
      )}
    </>
  );
};

Budget.propTypes = {
  metrics: PropTypes.shape().isRequired,
  darkMode: PropTypes.bool.isRequired,
  expandable: PropTypes.bool,
  scrollable: PropTypes.bool,
};

Budget.defaultProps = {
  expandable: true,
  scrollable: false,
};

export default connect((state) => {
  return {
    metrics: getReportMetrics(state),
    darkMode: getDarkMode(state),
  };
})(Budget);
