import { showErrorMessage } from 'actions/messages';
import { reportsViewsApi } from 'services/api/reports-views-api';
import {
  SET_REPORT_VIEWS,
  SET_AVAILABLE_COMPANIES,
  SET_AVAILABLE_PROJECTS,
  NEW_REPORT_VIEW,
  EDIT_REPORT_VIEW,
  REPORT_VIEW_FORM_VALUE_CHANGE,
  SHOW_SAVE_VIEW_MODAL,
  HIDE_SAVE_VIEW_MODAL,
  SET_RUNNING_REPORT,
  CLEAR_RUNNING_REPORT,
} from './action-types';

function setReportViews(views) {
  return {
    type: SET_REPORT_VIEWS,
    payload: { views },
  };
}

export function loadReportViews() {
  return (dispatch) => {
    return reportsViewsApi.list().then((response) => {
      if (response.ok) {
        dispatch(setReportViews(response.views));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

function setAvailableCompanies(companies) {
  return {
    type: SET_AVAILABLE_COMPANIES,
    payload: { companies },
  };
}

export function loadAvailableCompanies() {
  return (dispatch) => {
    return reportsViewsApi.availableCompanies().then((response) => {
      if (response.ok) {
        dispatch(setAvailableCompanies(response.companies));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

function setAvailableProjects(data) {
  return {
    type: SET_AVAILABLE_PROJECTS,
    payload: data,
  };
}

export function loadAvailableProjects() {
  return (dispatch) => {
    return reportsViewsApi.availableProjects().then((response) => {
      if (response.ok) {
        dispatch(setAvailableProjects(response));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function newReportView() {
  return {
    type: NEW_REPORT_VIEW,
  };
}

function setEditReportView(view) {
  return {
    type: EDIT_REPORT_VIEW,
    payload: { view },
  };
}

export function editReportView(viewId) {
  return (dispatch) => {
    return reportsViewsApi.get(viewId).then((response) => {
      if (response.ok) {
        dispatch(setEditReportView(response.view));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function setReportViewFormValue(section, attribute, value) {
  return {
    type: REPORT_VIEW_FORM_VALUE_CHANGE,
    payload: { section, attribute, value },
  };
}

export function showSaveViewModal() {
  return {
    type: SHOW_SAVE_VIEW_MODAL,
    payload: {},
  };
}

export function hideSaveViewModal() {
  return {
    type: HIDE_SAVE_VIEW_MODAL,
    payload: {},
  };
}

function onReportViewSaved(dispatch, view) {
  dispatch(setEditReportView(view));
  dispatch(loadReportViews());
  dispatch(hideSaveViewModal());
}

export function createReportView(values) {
  return (dispatch) => {
    return reportsViewsApi.create(values).then((response) => {
      if (response.ok) {
        onReportViewSaved(dispatch, response.view);
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function updateReportView(id, values) {
  return (dispatch) => {
    return reportsViewsApi.update(id, values).then((response) => {
      if (response.ok) {
        onReportViewSaved(dispatch, response.view);
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

function setRunningReport(view) {
  return {
    type: SET_RUNNING_REPORT,
    payload: { view },
  };
}

export function clearRunningReport() {
  return {
    type: CLEAR_RUNNING_REPORT,
  };
}

export function runReport(values) {
  return (dispatch) => {
    return reportsViewsApi.run(values).then((response) => {
      if (response.ok) {
        dispatch(setRunningReport(response.view));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function reloadRunningReport(viewId) {
  return (dispatch) => {
    return reportsViewsApi.get(viewId).then((response) => {
      if (response.ok) {
        dispatch(setRunningReport(response.view));
      } else {
        dispatch(showErrorMessage(response.errors));
        dispatch(setRunningReport(null));
      }

      return response;
    });
  };
}
