import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { INITIAL_FILTERS } from 'domain/project-photo';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import Chips from 'components/shared/chip/Chips';
import { Grid } from '@mui/material';

import { getProjectPhotosFilters } from './store/selectors';
import { setProjectPhotosFilters } from './store/actions';

const ProjectPhotosFiltersChips = (props) => {
  const { dispatch, filters } = props;

  const onClearAll = () => {
    dispatch(
      setProjectPhotosFilters({
        ...INITIAL_FILTERS,
        albumId: filters.albumId,
        search: filters.search,
        sort: filters.sort,
        doSearch: true,
      })
    );
  };
  const onClear = ({ attr, value: id }) => {
    let newValue = null;
    if (['trades', 'sites', 'areas', 'users', 'tags'].includes(attr))
      newValue = filters[attr].filter(({ value }) => id !== value);

    dispatch(setProjectPhotosFilters({ [attr]: newValue, doSearch: true }));
  };

  const getActiveFilters = () => {
    const res = [];

    if (filters.favorites)
      res.push({ label: 'Favorites', attr: 'favorites', value: false });
    if (filters.status === 'archived')
      res.push({ label: 'Archived', attr: 'status', value: null });
    if (filters.status === 'recycled')
      res.push({ label: 'Recycled', attr: 'status', value: null });

    if (filters.type) {
      res.push({
        label: filters.type[0].toUpperCase() + filters.type.slice(1),
        attr: 'type',
        value: null,
      });
    }
    ['trades', 'sites', 'areas', 'users', 'tags'].forEach((attr) => {
      filters[attr].forEach(({ value, label }) => {
        res.push({ label, attr, value });
      });
    });

    if (filters.from || filters.to) {
      let label = '';
      let attr = '';

      if (filters.from && filters.to) {
        label =
          moment(filters.from).format('MM/DD/YYYY') +
          ' - ' +
          moment(filters.to).format('MM/DD/YYYY');
        attr = 'date';
      } else if (filters.from) {
        label = 'From ' + moment(filters.from).format('MM/DD/YYYY');
        attr = 'from';
      } else {
        label = 'To ' + moment(filters.to).format('MM/DD/YYYY');
        attr = 'to';
      }

      res.push({ label, attr, value: null });
    }

    return res;
  };
  const activeFilters = getActiveFilters();

  if (activeFilters.length === 0) return null;

  return (
    <Grid
      container
      justifyContent="space-between"
      wrap="nowrap"
      spacing={2}
      marginTop={-0.5}
    >
      <Grid item>
        <Grid container spacing={1}>
          <Chips values={activeFilters} onDelete={onClear} />
        </Grid>
      </Grid>
      <Grid item marginTop={0.5} flexShrink={0}>
        <Button
          type={BUTTON_TYPES.LINK}
          color={BUTTON_COLORS.TRANSPARENT}
          label={'Clear All'}
          onClick={onClearAll}
        />
      </Grid>
    </Grid>
  );
};

export default connect((state) => ({
  filters: getProjectPhotosFilters(state),
}))(ProjectPhotosFiltersChips);
