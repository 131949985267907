import * as ACTIONS from '../actions/authentication';

const initialState = {
  accessToken: '',
  client: '',
  errors: [],
  loggedIn: false,
  user: null,
  accounts: [],
};

export default function authenticationReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.AUTHENTICATION_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        client: action.payload.client,
        errors: [],
        loggedIn: true,
        user: action.payload.user,
      };

    case ACTIONS.AUTHENTICATION_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };

    case ACTIONS.AUTHENTICATION_LOGOUT:
      return {
        ...state,
        accessToken: '',
        client: '',
        loggedIn: false,
        user: null,
      };

    case ACTIONS.AUTHENTICATION_ACCOUNTS_SET:
      return {
        ...state,
        accounts: action.payload,
      };

    default:
      return state;
  }
}
