import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormModal from 'components/shared/modal/FormModal';
import SearchBar from 'components/shared/navigation/SearchBar';
import Date from 'components/shared/text/Date';
import SimpleTable from 'components/shared/table/SimpleTable';
import FilterHeader from 'components/shared/table/FilterHeader';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import './ProjectReportFormModal.css';
import { getFieldProjectReports } from 'components/admin/projects/details/project-daily-report-log/store/selectors';

class ProjectReportProjectStatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
    };
  }

  toDateFormat = (value) => {
    return value ? <Date value={value} format="MM/DD/YYYY" /> : '--';
  };

  getName = (value) => {
    return value ? value.first_name + ' ' + value.last_name : '';
  };

  findReportNum = (projectReportId) => {
    const { fieldProjectReports } = this.props;
    let report = fieldProjectReports.find(
      (report) => report.id === projectReportId
    );
    return report ? report.number : '';
  };

  mapRowsTable = () => {
    const { data, onRestore, statuses } = this.props;
    return data.map((item) => {
      return {
        id: item.id,
        data: [
          item.revision_number
            ? `${item.code}-${item.revision_number}`
            : item.code,
          statuses.find(
            (status) => status.id === item.project_report_status_type_id
          ).label,
          item.description,
          this.getName(item.resolved_by),
          this.toDateFormat(item.resolved_at),
          <Button
            key="action-restore"
            type={BUTTON_TYPES.LINK}
            color={BUTTON_COLORS.GREEN}
            label="Restore"
            onClick={() => onRestore(item)}
          />,
        ],
      };
    });
  };

  availableStatuses = () => {
    const { nonFilteredData, statuses } = this.props;
    const availableIds = nonFilteredData.reduce(
      (available, { project_report_status_type_id: statusId }) =>
        !available.includes(statusId) ? [...available, statusId] : available,
      []
    );
    return statuses.filter((status) => availableIds.includes(status.id));
  };

  render() {
    const { show, onClose, onFilter, onSearch, type } = this.props;
    const title = 'Resolved Project Status';
    const headers = [
      'ITEM NO',
      <FilterHeader
        label="CATEGORY"
        options={[{ id: 'all', label: 'All' }, ...this.availableStatuses()]}
        selected={type}
        onFilter={(id) => onFilter(id)}
      />,
      'DESCRIPTION',
      'RESOLVED BY',
      'RESOLVED ON ',
      '',
    ];
    return (
      <FormModal
        id="project-report-form-modal"
        className="project-report-form-modal-view"
        show={show}
        onClose={onClose}
        subTitle="Project Report"
      >
        <div className="container-header-title">
          <div className="modal-name text-color">{title}</div>
          <SearchBar
            className="search-input-background"
            searchPlaceHolder="Search"
            searchText={this.state.search}
            onSearchTextChange={(search) => onSearch(search)}
            onSearch={(search) => onSearch(search)}
          />
        </div>
        <div className="container-body-project-report-modal-table">
          <SimpleTable
            className="simple-table projects-table modal-project-status-table"
            headers={headers}
            rows={this.mapRowsTable()}
          />
        </div>
      </FormModal>
    );
  }
}

ProjectReportProjectStatusModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  type: PropTypes.any,
  data: PropTypes.arrayOf(PropTypes.shape()),
  nonFilteredData: PropTypes.arrayOf(PropTypes.shape()),
};

ProjectReportProjectStatusModal.defaultProps = {
  show: false,
  data: [],
  nonFilteredData: [],
};

export default connect((state) => {
  return {
    fieldProjectReports: getFieldProjectReports(state),
  };
})(ProjectReportProjectStatusModal);
