import User from 'domain/user';
import ProjectReportPermissions from './project-report-permissions';

class ProjectDashboardPermissions {
  canView = (user, project) => ProjectReportPermissions.canView(user, project);

  canViewByDefault = (user, project) => User.isClient(user, project);
}

export default new ProjectDashboardPermissions();
