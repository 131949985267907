import {
  PROJECTS_PAGE_CHANGE,
  PROJECTS_MISSING_DRS_PAGE_CHANGE,
  PROJECTS_BIDS_DUE_PAGE_CHANGE,
  PROJECTS_NAV_CHANGE,
  PROJECTS_SHOW_ARCHIVE_CONFIRMATION_MODAL,
} from 'components/admin/projects/store/action-types';

const initialState = {
  currentPage: 1,
  missingDrsCurrentPage: 1,
  bidsDueCurrentPage: 1,
  currentNav: 1,
};

export default function projectsUiReducer(state = initialState, action) {
  switch (action.type) {
    case PROJECTS_PAGE_CHANGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case PROJECTS_MISSING_DRS_PAGE_CHANGE:
      return {
        ...state,
        missingDrsCurrentPage: action.payload,
      };

    case PROJECTS_BIDS_DUE_PAGE_CHANGE:
      return {
        ...state,
        bidsDueCurrentPage: action.payload,
      };

    case PROJECTS_NAV_CHANGE:
      return {
        ...state,
        currentNav: action.payload,
      };
    case PROJECTS_SHOW_ARCHIVE_CONFIRMATION_MODAL:
      return { ...state, showArchiveConfirmationModal: action.payload };
    default:
      return state;
  }
}
