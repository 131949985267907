import React from 'react';
import Date from 'components/shared/text/Date';
import './ResolvedReportItem.css';

const ResolvedReportItem = ({ number, date, submitted_by, remarks }) => {
  const toDateFormat = (value) => {
    return value ? <Date value={value} format="MM/DD/YYYY" /> : '--';
  };

  return (
    <div className="resolved-report-item">
      <div className="label-section">
        <span className="label text-color">Report No</span>
        <span className="value text-color">
          {number < 10 ? `0${number}` : number}
        </span>
        <span className="label text-color">Report Date </span>
        <span className="value text-color">{toDateFormat(date)}</span>
        <span className="label text-color">Submitted By</span>
        <span className="value text-color">
          {submitted_by ? submitted_by.label : ''}
        </span>
      </div>
      <div className="remark-section">
        <span className="label-remark">Remark</span>
        <div className="value-remark text-color">{remarks}</div>
      </div>
    </div>
  );
};

export default ResolvedReportItem;
