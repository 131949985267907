import {
  RFA_INIT,
  RFA_TAB_NAVIGATION_SET,
  RFA_DELETE_CONFIRMATION_SET,
  RFA_LOG_PDF_SET,
  RFA_SHOW_MODAL_SET,
  RFA_SHOW_CREATE_MODAL_SET,
  RFA_READONLY_SET,
  RFA_FILTERS_SHOW_SET,
} from 'components/admin/projects/details/project-rfa-log/store/action-types';

const initialState = {
  tabNavigation: 2,
  showLogPdf: false,
  showDeleteConfirmation: false,
  showRfaModal: false,
  showRfaCreateModal: false,
  showRfaFilters: false,
  createModalRevisedRfaId: false,
  readOnly: true,
};

export default function projectRfaLogUiReducer(state = initialState, action) {
  switch (action.type) {
    case RFA_INIT:
      return initialState;

    case RFA_TAB_NAVIGATION_SET:
      return { ...state, tabNavigation: action.payload };

    case RFA_LOG_PDF_SET:
      return { ...state, showLogPdf: action.payload.showLogPdf };

    case RFA_DELETE_CONFIRMATION_SET:
      return {
        ...state,
        showDeleteConfirmation: action.payload.showDeleteConfirmation,
      };

    case RFA_SHOW_MODAL_SET:
      return {
        ...state,
        showRfaModal: action.payload.show,
      };
    case RFA_FILTERS_SHOW_SET:
      return {
        ...state,
        showRfaFilters: action.payload.show,
      };

    case RFA_SHOW_CREATE_MODAL_SET:
      return {
        ...state,
        showRfaCreateModal: action.payload.show,
        createModalRevisedRfaId: action.payload.show
          ? action.payload.revisionId
          : null,
      };

    case RFA_READONLY_SET:
      return {
        ...state,
        readOnly: action.payload.readOnly,
      };

    default:
      return state;
  }
}
