import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getCurrentProjectLocation,
  getCurrentPage,
  getProjects,
} from 'selectors/admin/project-locations/project-locations';
import { loadProjectsRequest } from 'actions/admin/project-locations/project-locations';
import ResourceProjects from 'components/shared/projects/ResourceProjects';

class ProjectLocationDetailsProjects extends Component {
  render() {
    const { currentProjectLocation, currentPage, projects } = this.props;
    return (
      <ResourceProjects
        resource={currentProjectLocation}
        projects={projects}
        currentPage={currentPage}
        loadProjectsRequest={loadProjectsRequest}
      />
    );
  }
}

export default connect((state) => {
  return {
    currentProjectLocation: getCurrentProjectLocation(state),
    currentPage: getCurrentPage(state),
    projects: getProjects(state),
  };
})(ProjectLocationDetailsProjects);
