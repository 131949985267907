import React from 'react';
import { connect } from 'react-redux';

import HtmlViewer from 'components/shared/html-viewer/HtmlViewer';
import { getCurrentUser } from 'selectors/authentication';

import { getFieldReportHtml } from './store/selectors';
import { setFieldReportHtml } from './store/actions';

import html from './FieldReportHtml';

const FieldReportHtmlViewer = (props) => {
  const { dispatch, fieldReportHtmlData, project, currentUser } = props;
  const onClose = () => dispatch(setFieldReportHtml(null));
  if (fieldReportHtmlData === null) return null;

  const htmlWithData = html(fieldReportHtmlData, project, currentUser);
  return (
    <HtmlViewer
      content={{
        source: htmlWithData,
        type: 'text/html',
      }}
      show={fieldReportHtmlData !== null}
      onClose={onClose}
    />
  );
};

export default connect(
  (state) => ({
    fieldReportHtmlData: getFieldReportHtml(state),
    currentUser: getCurrentUser(state),
  }),
  null
)(FieldReportHtmlViewer);
