import {
  RFIS_COUNT,
  RFIS_LOAD,
} from 'components/admin/rfis/store/action-types';

const initialState = {
  rfisCount: 0,
  rfis: [],
  currentStatus: 'not_pre',
  total: 0,
  currentPage: 1,
  pageSize: 25,
};

export default function rfisDataReducer(state = initialState, action) {
  switch (action.type) {
    case RFIS_COUNT:
      return {
        ...state,
        rfisCount: action.payload,
      };

    case RFIS_LOAD:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
