import React, { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';

import { Grid } from '@mui/material';
import FormModal from 'components/shared/modal/FormModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import UserPermissionsForm from 'components/shared/user-permissions/UserPermissionsForm';
import Chips from 'components/shared/chip/Chips';
import colors from 'styles/colors';

import { getDarkMode } from 'selectors/theme';
import {
  getProjectPhotosForm,
  getProjectPhotosShowUsers,
} from './store/selectors';
import { setProjectPhotosShowUsers } from './store/actions';

const ProjectPhotosFormUsersModal = (props) => {
  const { dispatch, form, show, darkMode, onChange } = props;
  const [users, setUsers] = useState(form.users);

  useEffect(() => {
    if (show) setUsers(form.users);
  }, [show]);

  const onClose = () => {
    dispatch(setProjectPhotosShowUsers(false));
  };
  const onSave = () => {
    onChange({ users });
    onClose();
  };

  const onUserDelete = (user) => {
    const uu = users.filter((u) => u.id !== user.id);
    setUsers(uu);
  };

  const usersInput = (
    <FormControlBlock
      label={'Users'}
      hideBottomLabel
      control={
        <Grid
          paddingTop={1.25}
          paddingBottom={0.4}
          borderBottom={1}
          borderColor={
            colors[darkMode ? 'dark-mode-light-white' : 'light-mode-dark-black']
          }
        >
          <Chips
            values={(form.createdBy
              ? [{ ...form.createdBy, disabled: true }, ...users]
              : users
            ).map(({ id, full_name: label, disabled }) => ({
              id,
              label,
              disabled,
            }))}
            spacing={0.4}
            onDelete={onUserDelete}
          />
        </Grid>
      }
    />
  );

  const userListInput = (
    <UserPermissionsForm
      canChangePermissions
      users={users}
      onUsersChange={setUsers}
    />
  );

  return (
    <FormModal
      className="project-photos-users-modal"
      show={show}
      onClose={onClose}
      subTitle="Users"
    >
      <Grid container spacing={2} paddingX={4}>
        <Grid item xs={12} children={usersInput} />
        <Grid item xs={12} children={userListInput} />
      </Grid>
      <div className="modal-footer">
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          label={'Save'}
          onClick={onSave}
        />
      </div>
    </FormModal>
  );
};

export default connect((state) => ({
  form: getProjectPhotosForm(state),
  show: getProjectPhotosShowUsers(state),
  darkMode: getDarkMode(state),
}))(ProjectPhotosFormUsersModal);
