import React, { Component } from 'react';
import { connect } from 'react-redux';
import AWS from 'aws-sdk';
import moment from 'moment';
import PropTypes from 'prop-types';
import './AdvancedDocumentsTable.css';
import CreateFolderModal from './CreateFolderModal';
import UploadFilesModal from './UploadFilesModal';
import ConfirmationModal from 'components/shared/modal/ConfirmationModal';
import DownloadObjectsModal from './DownloadObjectsModal';
import {
  createAdvancedDocumentsFolderRequest,
  renameAdvancedDocumentsFolderRequest,
  notifyUploadAdvanceDocumentsRequest,
  deleteAdvancedDocumentsRequest,
  downloadAdvancedDocumentsFolderRequest,
  moveAdvancedDocumentsFolderRequest,
  loadAdvancedDocuments,
  updateAdvancedDocumentRequest,
} from 'components/admin/projects/details/project-documentation/store/actions';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import NativeCheckbox from 'components/shared/checkbox/NativeCheckbox';
import MoveFolderOrFileModal from './MoveFolderOrFileModal';
import SortHeader from 'components/shared/table/SortHeader';
import { getDarkMode } from 'selectors/theme';
import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import Menu, { MENU_SELECTOR } from 'components/shared/menu/Menu';
import DocumentLink from 'components/shared/document/DocumentLink';
import SharedFolderPermissionsModal from './SharedFolderPermissionsModal';
import withSnackbar from 'components/shared/snackbar/withSnackbar';
import {
  getAdvancedDocuments,
  getIsUpdateValue,
  getInitialPrefix,
} from 'components/admin/projects/details/project-documentation/store/selectors';
import DocumentPermissions from 'permissions/document-permissions';

AWS.config.region = 'us-east-1';

const s3 = new AWS.S3();
class AdvancedDocumentsTable extends Component {
  static propTypes = {
    bucket: PropTypes.string.isRequired,
    prefix: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
    delimiter: PropTypes.string.isRequired,
    isUpdate: PropTypes.bool.isRequired,
    showSnackbar: PropTypes.func.isRequired,
    closeSnackbar: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      prefix: this.props.initialPrefix || this.props.prefix,
      showCreateFolderModal: false,
      currentFolderName: null,
      showUploadFilesModal: false,
      renameFolder: false,
      selectAll: false,
      showDeleteFilesConfirmation: false,
      showDownloadObjectsModal: false,
      showMoveFolderModal: false,
      moveObjectSelected: null,
      showEmailObjectsModal: false,
      filesHeaderStatus: null,
      filesSortDirection: 'ascending',
      showSharedFolderPermissionsModal: false,
      currentFile: null,
      parentFile: null,
      hasItemsSelected: false,
      actionSelectedFiles: [],
      actionSelectedFolders: [],
      showDropFilesOverlay: false,
    };

    this.handleFileDragEnter = this.handleFileDragEnter.bind(this);
  }

  componentDidMount = () => {
    this.renderDocuments();
    this.startListeningDropFiles();
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.initialPrefix !== this.props.initialPrefix &&
      this.props.initialPrefix
    )
      this.setState({ prefix: this.props.initialPrefix });
    if (prevProps.isUpdate !== this.props.isUpdate) this.fetchFiles();
  };

  componentWillUnmount() {
    this.stopListeningDropFiles();
  }

  renderDocuments = () => {
    this.fetchAdvancedDocuments();
    this.fetchFiles();
  };

  fetchAdvancedDocuments = () => {
    const { currentProject, dispatch } = this.props;
    dispatch(loadAdvancedDocuments(currentProject.id));
  };

  startListeningDropFiles = () => {
    document.addEventListener('dragenter', this.handleFileDragEnter, false);
  };
  stopListeningDropFiles = () => {
    document.removeEventListener('dragenter', this.handleFileDragEnter, false);
  };

  handleFileDragEnter = (e) => {
    var dt = e && e.dataTransfer;
    var isFile =
      dt && dt.types && dt.types.length === 1 && dt.types[0] === 'Files';
    if (isFile) this.setState({ showUploadFilesModal: true });
  };

  retrieveFolder = (file) => {
    this.setState(
      {
        prefix: file.Prefix,
        parentFile: file,
      },
      () => {
        this.renderDocuments();
      }
    );
  };

  bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Bytes';

    const ii = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return `${Math.round(bytes / 1024 ** ii, 2)} ${sizes[ii]}`;
  };

  htmlEscape = (str) => {
    return str
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/\//g, '&#x2F;')
      .replace(/`/g, '&#x60;')
      .replace(/=/g, '&#x3D;');
  };

  isFile = (file) => {
    return typeof file.Key !== 'undefined';
  };

  isFolder = (path) => {
    return path.endsWith('/');
  };

  isThisDocument = (key) => {
    return key === 'index.html';
  };

  getPath = (file) => {
    return file.Key ? file.Key : file.Prefix;
  };

  fullPathToFileName = (path) => {
    return this.htmlEscape(path.replace(/^.*[\\/]/, ''));
  };

  fullPathToFolder = (prefix, escape = false) => {
    const parts = prefix.split('/');
    const rc = `${parts[parts.length - 2]}/`;
    return escape ? this.htmlEscape(rc) : rc;
  };

  fileS3Path = (key) => {
    var enckey = key
      .split('/')
      .map(function (x) {
        return encodeURIComponent(x);
      })
      .join('/');

    if (AWS.config.region === 'us-east-1') {
      return (
        document.location.protocol +
        '//' +
        this.props.bucket +
        '.s3.amazonaws.com/' +
        enckey
      );
    } else {
      return (
        document.location.protocol +
        '//' +
        this.props.bucket +
        '.s3-' +
        AWS.config.region +
        '.amazonaws.com/' +
        enckey
      );
    }
  };

  renderObject = (file) => {
    const path = this.getPath(file);

    return file.Prefix ? (
      <DocumentLink
        file={{
          name: this.fullPathToFolder(path),
          folder: true,
        }}
        onClick={() => this.retrieveFolder(file)}
      />
    ) : (
      <DocumentLink
        file={{
          name: this.fullPathToFileName(path),
        }}
        url={this.fileS3Path(file.Key)}
      />
    );
  };

  getFolderPath = () => {
    return `${this.props.bucket}/${this.state.prefix}`;
  };

  breadCrumbClick = (e, savePrefix) => {
    e.preventDefault();

    this.setState(
      {
        prefix: savePrefix,
      },
      () => {
        this.fetchFiles();
      }
    );
  };

  topLevelBreadCrumbClick = (e) => {
    e.preventDefault();

    this.setState(
      {
        prefix: '',
      },
      () => {
        this.fetchFiles();
      }
    );
  };

  breadcrumbPrefix = (parts, part) => {
    const partIndex = parts.indexOf(part);

    let prefix = '';
    parts.forEach((part, index) => {
      // Skip prefix for the bucket if present
      if (index <= partIndex && !this.props.bucket.includes(part)) {
        prefix = prefix.concat(`${part}/`);
      }
    });
    return prefix;
  };

  displayPart = (part) => {
    const prefix = this.props.prefix.replace('/', '');

    return prefix === part ? 'Documents' : part;
  };

  folderToBreadcrumbs = () => {
    var parts = [this.props.bucket];

    if (this.state.prefix) {
      parts.push.apply(
        parts,
        this.state.prefix.endsWith('/')
          ? this.state.prefix.slice(0, -1).split('/')
          : this.state.prefix.split('/')
      );
    }

    const buildPrefix = parts.map((part, i) => {
      if (i !== 0) {
        if (i === parts.length - 1) {
          return (
            <li key={i} className="active">
              {this.displayPart(part)}
            </li>
          );
        } else {
          /* eslint-disable jsx-a11y/anchor-is-valid */
          return (
            <li key={i}>
              <a
                href=""
                onClick={(e) => {
                  this.breadCrumbClick(e, this.breadcrumbPrefix(parts, part));
                }}
              >
                {this.displayPart(part)}
              </a>
            </li>
          );
        }
      }
      return false;
    });
    return buildPrefix;
  };

  updateFilesDisplayed = (scope) => (err, data) => {
    const { filesSortDirection } = this.state;
    const { advancedDocuments } = this.props;
    if (err) {
      scope.stop = true;
    } else {
      var files = data.Contents;

      files = files.concat(data.CommonPrefixes);
      if (scope.params.Prefix) {
        // filter out folder if prefix
        files = files.filter((file) => file.Key !== scope.params.Prefix);
      }

      if (advancedDocuments.length > 0) {
        files = this.filterFilesByVisibility(files);
      }

      this.setState({ files }, () => {
        this.sortFilesColumns(filesSortDirection);
      });
    }
  };

  filterFilesByVisibility = (files) => {
    // filter by allowed folders keys in db
    const { advancedDocuments, currentUser, currentProject } = this.props;
    files = files.filter((file) => {
      if (
        advancedDocuments.map((doc) => doc.folder_key).includes(file.Prefix)
      ) {
        return advancedDocuments.find((doc) => {
          if (doc.folder_key === file.Prefix) {
            if (
              (doc.visibility === 'private' &&
                DocumentPermissions.canViewPrivateFolders(
                  doc,
                  currentUser,
                  currentProject
                )) ||
              (doc.visibility === 'shared' &&
                DocumentPermissions.canViewSharedFolders(
                  doc,
                  currentUser,
                  currentProject
                )) ||
              doc.visibility === 'public'
            ) {
              Object.assign(file, doc);
              return true;
            }
          }
          return false;
        });
      } else {
        // allowed to see not saved in db
        return true;
      }
    });

    return files;
  };

  showRenameFolderModal = (file) => {
    const folderName = this.fullPathToFolder(file.Prefix).replace('/', '');
    this.setState({
      showCreateFolderModal: true,
      currentFolderName: folderName,
      renameFolder: true,
      currentFile: file,
    });
  };

  renameFolderButton = (file) => {
    const { currentUser, currentProject } = this.props;
    if (DocumentPermissions.canRenameFolder(file, currentUser, currentProject))
      return {
        content: 'Rename',
        onClick: (e) => this.showRenameFolderModal(file),
      };
  };

  moveObjectButton = (file) => {
    const { currentUser, currentProject } = this.props;
    if (DocumentPermissions.canMoveFolder(file, currentUser, currentProject))
      return {
        content: 'Move',
        onClick: (e) => this.showMoveFolderModalClick(file),
      };
  };

  deleteObjectButton = (file) => {
    return (
      this.canDelete() && {
        content: 'Delete',
        onClick: () => {
          const isFolder = typeof file.Prefix != 'undefined';
          this.setState({
            showDeleteFilesConfirmation: true,
            actionSelectedFolders: isFolder ? [file.Prefix] : [],
            actionSelectedFiles: isFolder ? [] : [file.Key],
          });
        },
      }
    );
  };

  downloadObjectButton = (file) => {
    return {
      content: 'Download',
      onClick: () => {
        const isFolder = typeof file.Prefix != 'undefined';
        this.setState({
          showEmailObjectsModal: true,
          actionSelectedFolders: isFolder ? [file.Prefix] : [],
          actionSelectedFiles: isFolder ? [] : [file.Key],
        });
      },
    };
  };

  emailObjectButton = (file) => {
    return {
      content: 'Email',
      onClick: () => {
        const isFolder = typeof file.Prefix != 'undefined';
        this.setState({
          showEmailObjectsModal: true,
          actionSelectedFolders: isFolder ? [file.Prefix] : [],
          actionSelectedFiles: isFolder ? [] : [file.Key],
        });
      },
    };
  };

  copyLinkObjectButton = (file) => {
    const isFolder = typeof file.Prefix != 'undefined';
    return (
      !isFolder && {
        content: 'Copy Link',
        onClick: () => {
          const url = this.fileS3Path(file.Key);
          navigator.clipboard.writeText(url);

          this.props.showSnackbar('Link copied successfully!', {
            action: (snackbarId) => (
              <button onClick={() => this.props.closeSnackbar(snackbarId)}>
                OK
              </button>
            ),
          });
        },
      }
    );
  };

  menuObjectButtons = (file) => {
    return (
      <Menu
        selector={MENU_SELECTOR.MORE_OPTIONS}
        items={[
          this.downloadObjectButton(file),
          this.emailObjectButton(file),
          this.copyLinkObjectButton(file),
          this.renameFolderButton(file),
          this.deleteObjectButton(file),
          this.moveObjectButton(file),
        ].filter((item) => item)}
      />
    );
  };

  showMoveFolderModalClick = (file) => {
    this.setState({
      showMoveFolderModal: true,
      moveObjectSelected: file.Key || file.Prefix,
    });
  };

  handleMoveFolderModalClose = () => {
    this.setState({ showMoveFolderModal: false });
  };

  handleMoveFolderModalSubmit = () => {};

  showCreateFolderModalClick = () => {
    this.setState({
      showCreateFolderModal: true,
      renameFolder: false,
      currentFolderName: null,
    });
  };

  showSharedFolderPermissionsModalClick = (file) => {
    this.setState({
      currentFile: file,
      showSharedFolderPermissionsModal: true,
    });
  };

  handleCreateFolderModalClose = () => {
    this.setState({
      showCreateFolderModal: false,
      renameFolder: false,
      currentFolderName: null,
    });
  };

  handleSharedFolderPermissionsModalClose = () => {
    this.setState({
      showSharedFolderPermissionsModal: false,
    });
  };

  handleCreateFolderModalSubmit = (submitValues) => {
    const { currentProject, dispatch } = this.props;
    const { renameFolder, currentFile } = this.state;
    const values = {
      folderName: submitValues.folderName,
      prefix: submitValues.prefix,
      visibility: submitValues.visibility,
    };

    if (!renameFolder) {
      dispatch(
        createAdvancedDocumentsFolderRequest(currentProject.id, values)
      ).then((response) => {
        if (response.ok) {
          this.setState({ showCreateFolderModal: false });
          this.renderDocuments();
        }
      });
    } else {
      values.oldFolderName = submitValues.oldFolderName;
      values.prefix = submitValues.prefix;

      dispatch(
        renameAdvancedDocumentsFolderRequest(
          currentProject.id,
          values,
          currentFile.id
        )
      ).then((response) => {
        if (response.ok) {
          this.handleCreateFolderModalClose();
          this.renderDocuments();
        }
      });
    }
  };

  handleSharedFolderModalSubmit = (submitValues) => {
    const { currentProject, dispatch } = this.props;
    const { currentFile } = this.state;
    const values = {
      visibility: submitValues.visibility,
      user_ids: submitValues.user_ids,
    };

    if (!currentFile.id) {
      values.prefix = currentFile.Prefix.split('/').slice(0, -2).join('/');
      values.folderName = this.fullPathToFolder(currentFile.Prefix).slice(
        0,
        -1
      );
      dispatch(
        createAdvancedDocumentsFolderRequest(currentProject.id, values)
      ).then((response) => {
        if (response.ok) {
          this.handleSharedFolderPermissionsModalClose();
          this.renderDocuments();
        }
      });
    } else {
      dispatch(
        updateAdvancedDocumentRequest(currentProject.id, currentFile.id, values)
      ).then((response) => {
        if (response.ok) {
          this.handleSharedFolderPermissionsModalClose();
          this.renderDocuments();
        }
      });
    }
  };

  handleUploadFilesModalSubmit = (documents) => {
    const { currentProject, dispatch } = this.props;
    const { prefix } = this.state;
    const fileNames = documents.map((d) => d.name);

    dispatch(
      notifyUploadAdvanceDocumentsRequest(currentProject.id, prefix, fileNames)
    ).then((response) => {
      if (response.ok) {
        this.setState({ showUploadFilesModal: false });
        this.renderDocuments();
      }
    });
  };

  fetchFiles = () => {
    var params = {
      Bucket: this.props.bucket,
      Prefix: this.state.prefix,
      Delimiter: this.props.delimiter,
    };

    var scope = {
      Contents: [],
      CommonPrefixes: [],
      params: params,
      stop: false,
    };

    scope.cb = this.updateFilesDisplayed;
    return s3.makeUnauthenticatedRequest(
      'listObjectsV2',
      scope.params,
      this.updateFilesDisplayed(scope)
    );
  };

  createFolderTitle = () => {
    return this.state.renameFolder ? 'Rename Folder' : 'Create Folder';
  };

  showUploadFilesModalClick = () => {
    this.setState({ showUploadFilesModal: true });
  };

  handleUploadFilesModalClose = () => {
    this.setState({ showUploadFilesModal: false });
  };

  showDeleteFilesConfirmationClick = () => {
    this.setState({
      showDeleteFilesConfirmation: true,
      actionSelectedFiles: this.selectedFiles(),
      actionSelectedFolders: this.selectedFolders(),
    });
  };

  showDownloadObjectsModalClick = (prefix) => {
    this.setState({
      showDownloadObjectsModal: true,
      actionSelectedFiles: this.selectedFiles(),
      actionSelectedFolders: this.selectedFolders(),
    });
  };

  showEmailObjectsModalClick = () => {
    this.setState({
      showEmailObjectsModal: true,
      actionSelectedFiles: this.selectedFiles(),
      actionSelectedFolders: this.selectedFolders(),
    });
  };

  handleEmailObjectsModalClose = () => {
    this.setState({ showEmailObjectsModal: false });
  };

  canDelete = () => {
    const { currentUser, currentProject } = this.props;
    return DocumentPermissions.canDeleteFolders(currentUser, currentProject);
  };

  renderActionButtons = () => {
    const actions = [
      <Button
        key="upload-file"
        type={BUTTON_TYPES.LINK}
        icon={BUTTON_ICONS.PLUS}
        label="Upload File"
        onClick={this.showUploadFilesModalClick}
      />,
      <Button
        key="create-folder"
        type={BUTTON_TYPES.LINK}
        icon={BUTTON_ICONS.PLUS}
        label="Create Folder"
        onClick={this.showCreateFolderModalClick}
      />,
    ];

    if (this.state.hasItemsSelected) {
      actions.push(
        <Menu
          key="more-options"
          selector={MENU_SELECTOR.MORE_OPTIONS_VERTICAL}
          items={[
            {
              content: 'Download',
              onClick: this.showDownloadObjectsModalClick,
            },
            {
              content: 'Email',
              onClick: this.showEmailObjectsModalClick,
            },
            this.canDelete() && {
              content: 'Delete',
              onClick: this.showDeleteFilesConfirmationClick,
            },
          ].filter((item) => item)}
        />
      );
    }
    return actions;
  };

  renderCheckbox = (file) => {
    return (
      <NativeCheckbox
        id={file.Key ? file.Key : file.Prefix}
        inputClassName={file.Key ? 'file' : 'folder'}
        onChange={this.handleItemSelect}
      />
    );
  };

  allCheckboxes = () => {
    return document.querySelectorAll(
      "input[class='file'], input[class='folder']"
    );
  };

  selectAllCheckboxes = () => {
    const value = !this.state.selectAll;
    var checkboxes = this.allCheckboxes();

    checkboxes.forEach((checkbox) => {
      checkbox.checked = value ? 'checked' : '';
    });
    this.setState({ selectAll: value });
    this.handleItemSelect();
  };

  unselectAllCheckboxes = () => {
    var checkboxes = this.allCheckboxes();

    checkboxes.forEach((checkbox) => {
      checkbox.checked = '';
    });
    this.setState({ selectAll: false });
    this.handleItemSelect();
  };

  selectedFiles = () => {
    var selectedFiles = [];
    const checkboxes = document.querySelectorAll("input[class='file']");

    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) return selectedFiles.push(checkbox.value);
    });

    return selectedFiles;
  };

  selectedFolders = () => {
    var selectedFolders = [];
    const checkboxes = document.querySelectorAll("input[class='folder']");

    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) return selectedFolders.push(checkbox.value);
    });

    return selectedFolders;
  };

  areObjectsSelected = () => {
    return this.selectedFolders().length > 0 || this.selectedFiles().length > 0;
  };

  handleItemSelect = () => {
    this.setState({ hasItemsSelected: this.areObjectsSelected() });
  };

  deleteSelectedObjects = () => {
    const { currentProject, dispatch } = this.props;
    const {
      prefix,
      actionSelectedFiles: documents,
      actionSelectedFolders: folders,
    } = this.state;
    const values = { prefix, documents, folders };

    dispatch(deleteAdvancedDocumentsRequest(currentProject.id, values)).then(
      (response) => {
        if (response.ok) {
          this.renderDocuments();
          this.unselectAllCheckboxes();
        }
      }
    );
  };

  handleDeleteFilesConfirmation = (confirmed) => {
    this.setState({ showDeleteFilesConfirmation: false });
    confirmed && this.deleteSelectedObjects();
  };

  handleDownloadObjectModalClose = () => {
    this.setState({ showDownloadObjectsModal: false });
  };

  handleDownloadObjectsModalSubmit = (params) => {
    const { bucket, region, dispatch } = this.props;
    const { prefix, actionSelectedFiles, actionSelectedFolders } = this.state;
    const values = {
      bucket: bucket,
      region: region,
      rootFolder: prefix,
      recipients: params.recipients,
      subject: params.subject,
      message: params.message,
      selectedFolders: actionSelectedFolders,
      selectedFiles: actionSelectedFiles,
    };

    dispatch(downloadAdvancedDocumentsFolderRequest(values)).then(
      (response) => {
        if (response.ok) {
          this.handleDownloadObjectModalClose();
          this.handleEmailObjectsModalClose();
          this.renderDocuments();
          this.unselectAllCheckboxes();
        }
      }
    );
  };

  handleMoveFolderModalSubmit = (params) => {
    const { dispatch, currentProject } = this.props;

    dispatch(
      moveAdvancedDocumentsFolderRequest(currentProject.id, params)
    ).then((response) => {
      if (response.ok) {
        this.handleMoveFolderModalClose();
        this.renderDocuments();
      }
    });
  };

  sortTable = (table, col, reverse) => {
    var tb = table.tBodies[0], // use `<tbody>` to ignore `<thead>` and `<tfoot>` rows
      tr = Array.prototype.slice.call(tb.rows, 0), // put rows into array
      i;
    reverse = -(+reverse || -1);
    tr = tr.sort(function (a, b) {
      // sort rows
      return (
        reverse * // `-1 *` if want opposite order
        a.cells[col].textContent
          .trim() // using `.textContent.trim()` for test
          .localeCompare(b.cells[col].textContent.trim())
      );
    });
    for (i = 0; i < tr.length; ++i) tb.appendChild(tr[i]); // append each row in order
  };

  sortFilesColumns = (direction) => {
    const table = document.getElementById('advanced-documents-table-table');
    const ascending = direction !== 'ascending';

    this.setState({ filesSortDirection: direction });
    this.sortTable(table, 1, ascending);
  };

  emailObjectsModalMessage = () => {
    const { currentProject } = this.props;
    const {
      street_address_1,
      city,
      state,
    } = currentProject.project_location.location;

    return `Please find the below link to download  documents \
for ${currentProject.name}, located at ${street_address_1}, ${city}, ${state}.\n\n\
If you have questions, please contact me via the in app chat or email.`.trim();
  };

  isSubFolder = () => {
    return /\/.*\//.test(this.state.prefix);
  };

  buildLabel(file) {
    let visibility = file.visibility;
    if (visibility) {
      return visibility;
    } else if (this.isSubFolder()) {
      return null;
    } else {
      return 'Public';
    }
  }

  renderHeaderButtons() {
    return [
      <ul key="breadcrumb" id="breadcrumb" className="breadcrumb">
        {this.folderToBreadcrumbs()}
      </ul>,
      ...this.renderActionButtons(),
    ];
  }

  render() {
    const {
      currentProject,
      currentUser,
      bucket,
      renderHeaders,
      darkMode,
    } = this.props;
    const {
      showCreateFolderModal,
      currentFolderName,
      showUploadFilesModal,
      prefix,
      showDeleteFilesConfirmation,
      showDownloadObjectsModal,
      showMoveFolderModal,
      moveObjectSelected,
      showEmailObjectsModal,
      filesSortDirection,
      showSharedFolderPermissionsModal,
      renameFolder,
      currentFile,
      parentFile,
    } = this.state;
    const darkModeClass = darkMode ? 'dark-mode' : '';

    return (
      <div
        className={`content-container advanced-documents project-directory ${darkModeClass}`}
      >
        {renderHeaders(this.renderHeaderButtons())}
        <div className="advanced-documents-table">
          <table
            className={`simple-table table stretch-table ${darkModeClass}`}
            id="advanced-documents-table-table"
          >
            <thead>
              <tr>
                <th data-sort-method="none">
                  <NativeCheckbox
                    id="select-all"
                    onChange={this.selectAllCheckboxes}
                  />
                </th>
                <th data-sort-default>
                  <SortHeader
                    label="FILES"
                    selected={true}
                    desc={filesSortDirection === 'descending'}
                    onChange={() =>
                      this.sortFilesColumns(
                        filesSortDirection === 'descending'
                          ? 'ascending'
                          : 'descending'
                      )
                    }
                  />
                </th>
                <th data-sort-method="none">LAST MODIFIED</th>
                <th data-sort-method="none">SIZE</th>
                <th data-sort-method="none">PERMISSIONS</th>
                <th data-sort-method="none">ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {this.state.files.map((file, index) => (
                <tr key={index} className="link-tab">
                  <td>{this.renderCheckbox(file)}</td>
                  <td>{this.renderObject(file)}</td>
                  <td>
                    {file.LastModified
                      ? moment(file.LastModified)
                          .local()
                          .format('YYYY-MM-DD HH:mm:ss')
                      : ''}
                  </td>
                  <td>{file.Size ? this.bytesToSize(file.Size) : ''}</td>
                  <td>
                    <div className="action-buttons">
                      <Button
                        className="file-visibility-button"
                        type={BUTTON_TYPES.LINK}
                        color={BUTTON_COLORS.GREEN}
                        label={this.buildLabel(file)}
                        disabled={
                          file.visibility
                            ? !DocumentPermissions.canManagePrivateFolders(
                                currentUser,
                                currentProject
                              )
                            : this.isFile(file)
                        }
                        onClick={this.showSharedFolderPermissionsModalClick.bind(
                          this,
                          file
                        )}
                      />
                      {file.visibility === 'shared' && (
                        <Button
                          type={BUTTON_TYPES.LINK}
                          color={BUTTON_COLORS.GREEN}
                          label="Users"
                          onClick={this.showSharedFolderPermissionsModalClick.bind(
                            this,
                            file
                          )}
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="action-buttons">
                      {this.menuObjectButtons(file)}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {showCreateFolderModal && (
          <CreateFolderModal
            showModal={showCreateFolderModal}
            onSubmit={this.handleCreateFolderModalSubmit}
            onClose={this.handleCreateFolderModalClose}
            folderName={currentFolderName}
            prefix={prefix}
            title={this.createFolderTitle()}
            buttonLabel="Confirm"
            renameFolder={renameFolder}
            parentFile={parentFile}
          />
        )}
        {showSharedFolderPermissionsModal && (
          <SharedFolderPermissionsModal
            showModal={showSharedFolderPermissionsModal}
            onSubmit={this.handleSharedFolderModalSubmit}
            onClose={this.handleSharedFolderPermissionsModalClose}
            currentFile={currentFile}
            currentUser={currentUser}
            currentProject={currentProject}
          />
        )}
        {showUploadFilesModal && (
          <UploadFilesModal
            showModal={showUploadFilesModal}
            onSubmit={this.handleUploadFilesModalSubmit}
            onClose={this.handleUploadFilesModalClose}
            folderName={currentFolderName}
            prefix={prefix}
          />
        )}
        {showDeleteFilesConfirmation && (
          <ConfirmationModal
            show={showDeleteFilesConfirmation}
            message="Are you sure you want to delete these items and any sub items?"
            subMessage="You cannot undo this action."
            onHide={this.handleDeleteFilesConfirmation}
          />
        )}
        {showDownloadObjectsModal && (
          <DownloadObjectsModal
            showModal={showDownloadObjectsModal}
            onSubmit={this.handleDownloadObjectsModalSubmit}
            onClose={this.handleDownloadObjectModalClose}
            folders={() => this.selectedFolders()}
            projectId={currentProject.id}
            currentUser={currentUser}
            prefix={prefix}
            title="Download"
          />
        )}
        {showEmailObjectsModal && (
          <DownloadObjectsModal
            showModal={showEmailObjectsModal}
            onSubmit={this.handleDownloadObjectsModalSubmit}
            onClose={this.handleEmailObjectsModalClose}
            folders={() => this.selectedFolders()}
            projectId={currentProject.id}
            currentUser={currentUser}
            prefix={prefix}
            title="Email"
            message={this.emailObjectsModalMessage()}
          />
        )}
        {showMoveFolderModal && (
          <MoveFolderOrFileModal
            showModal={showMoveFolderModal}
            onSubmit={this.handleMoveFolderModalSubmit}
            onClose={this.handleMoveFolderModalClose}
            bucket={bucket}
            prefix={prefix}
            selectedObject={moveObjectSelected}
          />
        )}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    darkMode: getDarkMode(state),
    advancedDocuments: getAdvancedDocuments(state),
    isUpdate: getIsUpdateValue(state),
    initialPrefix: getInitialPrefix(state),
  };
})(withSnackbar(AdvancedDocumentsTable));
