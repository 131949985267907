import React, { Component } from 'react';
import './WorkOrders.css';
import emptyArrowPng from './empty-arrow.png';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  addWorkOrder,
  goToWorkOrder,
  loadWorkOrdersRequest,
  searchTextChange,
  selectWorkOrderRequest,
} from 'actions/admin/work-orders/work-orders';
import {
  WorkOrderStatusEnum,
  WorkOrderStatusKeys,
} from 'services/utils/work-order-status-enum';
import {
  getCurrentPage,
  getShowNoWorkOrdersOverlay,
  getWorkOrders,
  searchTextSelector,
} from 'selectors/admin/work-orders/work-orders';
import SearchBar from 'components/shared/navigation/SearchBar';
import NavigationBar from 'components/shared/navigation/NavigationBar';
import WorkOrdersTable from 'components/shared/table/WorkOrdersTable';
import { getCurrentUser } from 'selectors/authentication';
import { simpleScrollToTop } from 'services/utils/scroller-util';
import { getNotifications } from 'selectors/notifications';
import { markAllAsRead } from 'actions/notifications';
import WorkOrdersTitle from 'components/admin/work-orders/WorkOrdersTitle';
import { getClient } from 'selectors/admin/team/client-team';
import { loadClientRequest } from 'actions/admin/team/client-team';
import User from 'domain/user';

class WorkOrders extends Component {
  static propTypes = {
    perPage: PropTypes.number,
    showTitle: PropTypes.bool,
    showControls: PropTypes.bool,
  };

  static defaultProps = {
    perPage: 25,
    showTitle: true,
    showControls: true,
  };

  constructor(props) {
    super(props);
    this.navSelected = 1;
    this.userIsContractor = User.isContractor(props.currentUser);
  }

  componentDidMount() {
    const { client, currentUser, perPage, dispatch } = this.props;
    dispatch(loadWorkOrdersRequest({ page: 1, perPage }));
    if (User.isClient(currentUser)) {
      const clientId = currentUser.accountable_id;
      if (client === null || client.id !== clientId) {
        dispatch(loadClientRequest(clientId));
      }
    }
  }

  componentDidUpdate() {
    simpleScrollToTop();
  }

  handleAddWorkOrder = () => {
    this.props.dispatch(addWorkOrder());
  };

  showOverlay = () => {
    return this.navSelected === 1 && this.props.showNoWorkOrdersOverlay;
  };

  handleTableSelection = (index) => {
    // "draft" WO pages expect the currentWO to be loaded, but "not draft" WO loads the info in WO details
    const workOrderStatus = this.props.workOrders.content[index].status;

    // Mark all notifications for the selected WO as read
    this.props.dispatch(markAllAsRead(this.props.workOrders.content[index].id));

    if (
      WorkOrderStatusEnum.DRAFT.equals(
        WorkOrderStatusEnum.create(workOrderStatus)
      )
    ) {
      this.props.dispatch(
        selectWorkOrderRequest(
          this.props.workOrders.content[index].id,
          workOrderStatus
        )
      );
    } else {
      this.props.dispatch(
        goToWorkOrder(this.props.workOrders.content[index].id)
      );
    }
  };

  handleNavSelect = (value) => {
    this.navSelected = value;
    this.handleChange(1);
  };

  handlePageChange = (newPage) => {
    this.handleChange(newPage);
  };

  handleChange(page) {
    const criteria = { page };
    switch (this.navSelected) {
      case 2:
        criteria.critical = true;
        break;
      case 3:
        criteria.status = WorkOrderStatusKeys.DRAFT;
        break;
      case 4:
        criteria.status = WorkOrderStatusKeys.PENDING;
        break;
      case 5:
        criteria.status = WorkOrderStatusKeys.PLM;
        break;
      case 6:
        criteria.status = WorkOrderStatusKeys.WOA;
        break;
      case 7:
        criteria.status = WorkOrderStatusKeys.IN_PROGRESS;
        break;
      case 8:
        criteria.status = WorkOrderStatusKeys.COMPLETE;
        break;
      case 9:
        criteria.status = WorkOrderStatusKeys.CLOSED;
        break;
      case 10:
        criteria.status = WorkOrderStatusKeys.DECLINED;
        break;
      default:
    }
    if (this.props.searchText !== '') {
      criteria.jobNumber = this.props.searchText;
    }
    if (this.props.perPage) {
      criteria.perPage = this.props.perPage;
    }
    this.props.dispatch(loadWorkOrdersRequest(criteria));
  }

  handleContractorSearch = () => {
    this.handleChange(1);
  };

  handleSearchTextChange = (value) => {
    this.props.dispatch(searchTextChange(value));
  };

  render() {
    let workOrderMarkById = {};
    if (
      this.props.notifications !== null &&
      this.props.notifications.content.length > 0
    ) {
      // Look for notifications not read yet, to mark the WO
      workOrderMarkById = this.props.notifications.content
        .filter((notification) => notification.read_at === null)
        .reduce((accumulator, currentNotification) => {
          let id = null;
          if (currentNotification.notifiable_type === 'WorkOrder') {
            id = currentNotification.notifiable.id;
          } else if (currentNotification.notifiable !== null) {
            id = currentNotification.notifiable.work_order_id;
          }
          if (id !== null) {
            accumulator[id] = true;
          }
          return accumulator;
        }, {});
    }

    let clientPurchaseOrder =
      this.props.client !== null ? this.props.client.purchase_order : null;

    let onContractorSearch = null;
    if (this.userIsContractor) {
      onContractorSearch = this.handleContractorSearch;
    }

    const { showTitle } = this.props;

    return (
      <div className="work-orders">
        {showTitle && (
          <div className="full-title">
            <WorkOrdersTitle
              purchaseOrder={clientPurchaseOrder}
              title="Work Orders"
            />
          </div>
        )}

        {this.props.workOrders !== null && (
          <div className="content-container">
            <SearchBar
              addButton={this.renderAddButton()}
              searchPlaceHolder="Search by Job #"
              searchText={this.props.searchText}
              onSearch={onContractorSearch}
              onSearchTextChange={this.handleSearchTextChange}
            >
              <NavigationBar
                items={[
                  {
                    id: 1,
                    title: 'All',
                  },
                  {
                    id: 2,
                    show: this.props.workOrders.totalCritical > 0,
                    title: 'Critical',
                    total: this.props.workOrders.totalCritical,
                  },
                  {
                    id: 3,
                    show: this.props.workOrders.totalDraft > 0,
                    title: 'Draft',
                    total: this.props.workOrders.totalDraft,
                  },
                  {
                    id: 4,
                    show: this.props.workOrders.totalPending > 0,
                    title: 'Pending',
                    total: this.props.workOrders.totalPending,
                  },
                  {
                    id: 5,
                    show: this.props.workOrders.totalPlm > 0,
                    title: 'PLM',
                    total: this.props.workOrders.totalPlm,
                  },
                  {
                    id: 6,
                    show: this.props.workOrders.totalWoa > 0,
                    title: 'WOA',
                    total: this.props.workOrders.totalWoa,
                  },
                  {
                    id: 7,
                    show: this.props.workOrders.totalInProgress > 0,
                    title: 'In Progress',
                    total: this.props.workOrders.totalInProgress,
                  },
                  {
                    id: 8,
                    show: this.props.workOrders.totalComplete > 0,
                    title: 'Complete',
                    total: this.props.workOrders.totalComplete,
                  },
                  {
                    id: 9,
                    show: this.props.workOrders.totalClosed > 0,
                    title: 'Closed',
                    total: this.props.workOrders.totalClosed,
                  },
                  {
                    id: 10,
                    show: this.props.workOrders.totalDeclined > 0,
                    title: 'Declined',
                    total: this.props.workOrders.totalDeclined,
                  },
                ]}
                onSelected={this.handleNavSelect}
              />
            </SearchBar>

            <WorkOrdersTable
              currentPage={this.props.currentPage}
              currentUser={this.props.currentUser}
              pageSize={this.props.workOrders.pageSize}
              showFilter={false}
              totals={{
                all: this.props.workOrders.total,
              }}
              workOrders={this.props.workOrders.content}
              workOrderMarkById={workOrderMarkById}
              onPageChange={this.handlePageChange}
              onWorkOrderSelection={this.handleTableSelection}
            />
          </div>
        )}

        {!User.isContractor(this.props.currentUser) && this.showOverlay() && (
          <div className="no-work-orders-overlay">
            <div>
              Welcome to the Spackle App
              <img src={emptyArrowPng} alt="arrow" />
            </div>
            <div>Create your first work order</div>
          </div>
        )}
      </div>
    );
  }

  renderAddButton() {
    const { showControls } = this.props;
    // Don't show add WO button if user is a contractor or is business dev or field admin
    const canAdd =
      showControls &&
      (User.isAdmin(this.props.currentUser) ||
        User.isClient(this.props.currentUser) ||
        User.isGeneralContractor(this.props.currentUser));
    return (
      canAdd && (
        <React.Fragment>
          <input
            className={
              'btn button button-orange button-add-work-order' +
              (this.showOverlay() ? ' on-top' : '')
            }
            type="button"
            value="Add Work Order"
            onClick={this.handleAddWorkOrder}
          />
          {this.showOverlay() && <div className="empty-button-block" />}
        </React.Fragment>
      )
    );
  }
}

export default connect((state) => {
  return {
    client: getClient(state),
    currentPage: getCurrentPage(state),
    currentUser: getCurrentUser(state),
    notifications: getNotifications(state),
    showNoWorkOrdersOverlay: getShowNoWorkOrdersOverlay(state),
    searchText: searchTextSelector(state),
    workOrders: getWorkOrders(state),
  };
})(WorkOrders);
