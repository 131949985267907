import moment from 'moment';

class WeatherApi {
  constructor() {
    this.historyUrl =
      'https://api.openweathermap.org/data/3.0/onecall/timemachine';
    this.currentUrl = 'https://api.openweathermap.org/data/2.5/weather';
    this.apiKey = process.env.REACT_APP_WEATHER_API_KEY;
  }

  getHistoryWeather(location, timestamp) {
    let url = `${this.historyUrl}?appid=${this.apiKey}&units=imperial&lang=en`;
    url += `&lat=${location.latitude}&lon=${location.longitude}`;
    url += `&dt=${timestamp}`;

    return fetch(url)
      .then((res) => res.json())
      .then((response) => {
        if (response.data.length > 0) {
          const data = response.data[0];
          return { ok: true, data };
        } else {
          return { ok: false, errors: 'No results for this date and location' };
        }
      })
      .catch((error) => ({ ok: false, errors: error.message }));
  }

  getCurrentWeather(location) {
    let url = `${this.currentUrl}?appid=${this.apiKey}&units=imperial&lang=en`;
    url += `&lat=${location.latitude}&lon=${location.longitude}`;

    return fetch(url)
      .then((res) => res.json())
      .then((response) => {
        if (response.cod === 200) {
          const data = {
            ...response,
            temp: response.main.temp,
            humidity: response.main.humidity,
            wind_deg: response.wind.speed,
            wind_speed: response.wind.deg,
          };
          return { ok: true, data };
        } else {
          return { ok: false, errors: response.message };
        }
      })
      .catch((error) => ({ ok: false, errors: error.message }));
  }

  getWeather(location, date, start_time, end_time) {
    const now = moment();
    const dt = moment(date);
    let start = moment(start_time);

    if (start.isValid()) {
      start.set({ date: dt.date(), month: dt.month(), year: dt.year() });
    } else {
      start = dt.clone();
    }

    const isNow = Math.abs(now.diff(start, 'hour')) < 6;

    if (isNow) {
      return this.getCurrentWeather(location);
    } else {
      let end = moment(end_time);
      if (end.isValid()) {
        end.set({ date: dt.date(), month: dt.month(), year: dt.year() });
      } else {
        end = start.clone();
      }

      const diff = start.diff(end);
      const d = start.clone().add(diff / 2);
      const timestamp = d.unix();

      return this.getHistoryWeather(location, timestamp);
    }
  }
}

export const weatherApi = new WeatherApi();
