import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/fontawesome-free-solid';

import { getDarkMode } from 'selectors/theme';
import colors from 'styles/colors';

const TreeView = (props) => {
  const {
    darkMode,
    selected,
    onSelect,
    disableEnter,
    disableCheck,
    getAsyncOptions,
    options,
    labelKey,
    valueKey,
  } = props;

  const [items, setItems] = useState(options);
  const [parents, setParent] = useState([]);

  const handleSelect = (o) => () => onSelect(o);

  const handleEnter = (o) => async () => {
    if (typeof getAsyncOptions === 'function') {
      const itms = await getAsyncOptions(o);
      setItems(itms);
    } else {
      const itms = o.children;
      setItems(itms);
    }
    setParent((parents) => [...parents, o]);
    onSelect(o);
  };

  const handleBack = async () => {
    const ps = [...parents];
    ps.pop();
    if (ps.length === 0) {
      setItems(options);
    } else if (typeof getAsyncOptions === 'function') {
      const itms = await getAsyncOptions(ps[ps.length - 1]);
      setItems(itms);
    } else {
      const itms = ps[ps.length - 1].children;
      setItems(itms);
    }
    setParent(ps);
    onSelect(ps.length > 0 ? ps[ps.length - 1] : null);
  };

  const isSelected = (o) => {
    return selected.findIndex((s) => s[valueKey] === o[valueKey]) !== -1;
  };

  const isDisabled = (o) => {
    if (typeof disableEnter === 'function') return disableEnter(o);

    return typeof o.children !== 'undefined' && o.children.length > 1;
  };

  const isCheckDisable = (o) => {
    if (typeof disableEnter === 'function') return disableCheck(o);
    return false;
  };

  const color = colors[darkMode ? 'white-text' : 'light-mode-black-text'];

  return (
    <List>
      {parents.length !== 0 && (
        <ListItem key={'back'} disablePadding>
          <ListItemButton
            role={undefined}
            onClick={handleBack}
            disableRipple
            dense
          >
            <ListItemIcon>
              <div style={{ fontSize: 16, marginLeft: -4, color }}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </div>
            </ListItemIcon>
            <ListItemText
              secondary={
                parents.length < 2
                  ? 'Back'
                  : parents[parents.length - 2][labelKey]
              }
            />
          </ListItemButton>
        </ListItem>
      )}
      {items.map((o) => {
        return (
          <ListItem
            key={'list_item_' + o[valueKey]}
            disablePadding
            secondaryAction={
              !isDisabled(o) && (
                <IconButton edge="end" onClick={handleEnter(o)} disableRipple>
                  <div style={{ fontSize: 16, width: 21, height: 21, color }}>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </IconButton>
              )
            }
          >
            <ListItemButton
              role={undefined}
              disabled={isCheckDisable(o)}
              onClick={handleSelect(o)}
              disableRipple
              dense
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={isSelected(o)}
                  tabIndex={-1}
                  disableRipple
                  style={{ padding: 0.5 }}
                />
              </ListItemIcon>
              <ListItemText
                id={o[valueKey]}
                primary={o[labelKey]}
                primaryTypographyProps={{ color }}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

TreeView.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getAsyncOptions: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  disableEnter: PropTypes.func,
  disableCheck: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.shape({})),
};

TreeView.defaultProps = {
  labelKey: 'label',
  valueKey: 'value',
  selected: [],
};

export default connect((state) => ({ darkMode: getDarkMode(state) }))(TreeView);
