import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import './DetailsTitle.css';

class DetailsTitle extends Component {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rightButtons: PropTypes.arrayOf(PropTypes.element),
    valid: PropTypes.bool,
  };

  static defaultProps = {
    title: '',
    rightButtons: [],
    valid: true,
  };

  render() {
    const {
      className,
      title,
      total,
      rightButtons,
      valid,
      darkMode,
    } = this.props;

    return (
      <div
        className={`details-title ${className || ''} ${
          darkMode ? 'dark-mode' : ''
        } ${!valid ? 'invalid' : ''}`}
      >
        <h3 className="details-title-text">
          {title}
          {total && <span className="details-title-total">{total}</span>}
        </h3>
        <div className="details-title-right-buttons">{rightButtons}</div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(DetailsTitle);
