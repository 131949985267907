import { createSelector } from 'reselect';

export const getCoiExps = createSelector(
  (state) => state.projects.scenes.details.scenes.coiExps.data.coiExps,
  (coiExps) => {
    return coiExps;
  }
);

export const getCoiExpsTotal = createSelector(
  (state) => state.projects.scenes.details.scenes.coiExps.data.coiExpsTotal,
  (coiExpsTotal) => {
    return coiExpsTotal;
  }
);

export const getCoiExpsCurrentPage = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.coiExps.data.coiExpsCurrentPage,
  (coiExpsCurrentPage) => {
    return coiExpsCurrentPage;
  }
);

export const getCoiExpsPageSize = createSelector(
  (state) => state.projects.scenes.details.scenes.coiExps.data.coiExpsPageSize,
  (coiExpsPageSize) => {
    return coiExpsPageSize;
  }
);

export const getCoiExpDocs = createSelector(
  (state) => state.projects.scenes.details.scenes.coiExps.data.coiExpDocs,
  (coiExpDocs) => {
    return coiExpDocs;
  }
);

export const getLiabilities = createSelector(
  (state) => state.projects.scenes.details.scenes.coiExps.data.liabilities,
  (liabilities) => {
    return liabilities;
  }
);
