import React from 'react';
import { connect } from 'react-redux';

import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { getProjectSites } from 'components/admin/projects/details/project-site-area/store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import { getDarkMode } from 'selectors/theme';
import { getShowOpenItemFormModal } from 'components/admin/projects/details/project-open-items/store/selectors';
import {
  getShowAddProjectAreaModal,
  getShowAddProjectSiteModal,
} from 'components/admin/projects/details/project-site-area/store/selectors';

import CollapsibleSection from 'components/shared/collapsible-section/CollapsibleSection';
import FilterHeader from 'components/shared/table/FilterHeader';
import vectorSelectedSvg from 'components/shared/table/vector-selected.svg';
import vectorSelectedDarkSvg from 'components/shared/table/vector-selected-dark.svg';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import Input from 'components/shared/input/Input';
import CharsCounterTextarea from 'components/shared/chars-counter/CharsCounterTextarea';
import UnitsInput from 'components/shared/input/UnitsInput';
import Selector from 'components/shared/selector/Selector';
import {
  SCHEDULE_STATUS_OPTIONS,
  SCHEDULE_DELAY_STATUS,
  NO_COMPLIENCE,
} from 'domain/field-report';
import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import ProjectOpenItemFormModal from 'components/admin/projects/details/project-open-items/ProjectOpenItemFormModal';
import SimpleTable from 'components/shared/table/SimpleTable';
import WindowDragAndDrop from 'components/shared/drag-and-drop/WindowDragAndDrop';

import useFieldReportFormStatus from './FieldReportFormStatus.hook';
import ImageDescriptionUpload from '../../../../../shared/image-upload/ImageDescriptionUpload';
import ProjectSitesSelector from '../../project-site-area/ProjectSitesSelector';
import ProjectAreasSelector from '../../project-site-area/ProjectAreasSelector';

const FieldReportStatus = (props) => {
  const {
    handleFormChange,
    form,
    darkMode,
    showOpenItemFormModal,
    showAddProjectAreaModal,
    showAddProjectSiteModal,
  } = props;
  const {
    handleAddOpenItem,
    handleAddOpenItemClose,
    handleAddPhotos,
    handleLoadPrevius,
    openItemsRows,
    tradeOptions,
    showImages,
  } = useFieldReportFormStatus(props);

  const getLabel = ({ defaultLabel, selectedLabel, allLabel, key }) => {
    if (!form[key]) return defaultLabel;
    else if (form[key] === 'all') return allLabel;
    else return selectedLabel;
  };

  const InputTradeCode = (
    <FilterHeader
      className="select-label"
      label={getLabel({
        defaultLabel: 'Select Cost Code',
        selectedLabel: 'Cost Code',
        allLabel: 'All Cost Codes',
        key: 'trade',
      })}
      icon={darkMode ? vectorSelectedDarkSvg : vectorSelectedSvg}
      options={tradeOptions}
      selected={form.trade}
      onFilter={(trade) => handleFormChange({ trade })}
      upperCaseLabel
      clearable
    />
  );
  const InputLocation = (
    <ProjectSitesSelector
      selected={form.site}
      handleSelect={(site) => handleFormChange({ site })}
    />
  );
  const InputArea = (
    <ProjectAreasSelector
      selected={form.area}
      selectedSite={form.site}
      handleSelect={(area) => handleFormChange({ area })}
    />
  );
  const InputTradeCount = (
    <FormControlBlock
      hideBottomLabel
      className="input-trade-count"
      label={form.observation_trade_count && 'Trade Count'}
      control={
        <Input
          type={'number'}
          placeholder={'Trade Count'}
          value={form.observation_trade_count}
          onChange={(v) => handleFormChange({ observation_trade_count: v })}
        />
      }
    />
  );
  const InputTask = (
    <FormControlBlock
      hideBottomLabel
      className="input-task inline-block"
      label={form.observation_task && 'Task'}
      control={
        <CharsCounterTextarea
          placeholder={'Task'}
          value={form.observation_task}
          valid={!(form.errors && form.errors.observation_task)}
          onChange={(value) => handleFormChange({ observation_task: value })}
          limit={500}
        />
      }
    />
  );
  const InputContractComplete = (
    <FormControlBlock
      hideBottomLabel
      className="input-contract"
      label={form.observation_contract_complete && 'Contract Complete'}
      control={
        <UnitsInput
          type={'number'}
          placeholder={'Contract Complete'}
          value={form.observation_contract_complete}
          unit="%"
          unitPosition={'end'}
          onChange={(v) =>
            handleFormChange({ observation_contract_complete: v })
          }
        />
      }
    />
  );
  const InputSchedule = (
    <FormControlBlock
      hideBottomLabel
      className="input-schedule"
      label={form.observation_schedule_status && 'Schedule Status'}
      control={
        <Selector
          className="select-dropdown"
          placeholder={'Schedule Status'}
          value={form.observation_schedule_status}
          onChange={(option) =>
            handleFormChange({
              observation_schedule_status: option ? option.value : option,
            })
          }
          valid={!(form.errors && form.errors.observation_schedule_status)}
          options={SCHEDULE_STATUS_OPTIONS}
          clearable
        />
      }
    />
  );
  const InputDesign = (
    <InputYesOrNo
      form={form}
      handleFormChange={handleFormChange}
      className={'input-design'}
      label={'Design Compliance'}
      formKey="observation_design_complience"
    />
  );
  const InputQuality = (
    <InputYesOrNo
      form={form}
      handleFormChange={handleFormChange}
      className={'input-quality'}
      label={'Quality Compliance'}
      formKey="observation_quality_complience"
    />
  );
  const InputSafety = (
    <InputYesOrNo
      form={form}
      handleFormChange={handleFormChange}
      className={'input-safety'}
      label={'Safety Compliance'}
      formKey="observation_safety_complience"
    />
  );
  const InputTaskRemarks = (
    <InputRemarks
      form={form}
      handleFormChange={handleFormChange}
      label="Task Remarks"
      formKey="observation_task_remarks"
    />
  );
  const InputScheduleRemarks = (
    <InputRemarks
      form={form}
      handleFormChange={handleFormChange}
      label={`Schedule Remarks${
        form.observation_schedule_status === SCHEDULE_DELAY_STATUS ? ' *' : ''
      }`}
      formKey="observation_schedule_remarks"
    />
  );
  const InputDesignRemarks = (
    <InputRemarks
      form={form}
      handleFormChange={handleFormChange}
      label={`Design Remarks${
        form.observation_design_complience === NO_COMPLIENCE ? ' *' : ''
      }`}
      formKey="observation_design_remarks"
    />
  );
  const InputQualityRemarks = (
    <InputRemarks
      form={form}
      handleFormChange={handleFormChange}
      label={`Quality Remarks${
        form.observation_quality_complience === NO_COMPLIENCE ? ' *' : ''
      }`}
      formKey="observation_quality_remarks"
    />
  );
  const InputSafetyRemarks = (
    <InputRemarks
      form={form}
      handleFormChange={handleFormChange}
      label={`Safety Remarks${
        form.observation_safety_complience === NO_COMPLIENCE ? ' *' : ''
      }`}
      formKey="observation_safety_remarks"
    />
  );
  const InputMaterialRemarks = (
    <InputRemarks
      form={form}
      handleFormChange={handleFormChange}
      label="Material & Delivery Remarks"
      formKey="observation_material_remarks"
    />
  );
  const InputEquipmentRemarks = (
    <InputRemarks
      form={form}
      handleFormChange={handleFormChange}
      label="Equipment Remarks"
      formKey="observation_equipment_remarks"
    />
  );
  const InputTestingRemarks = (
    <InputRemarks
      form={form}
      handleFormChange={handleFormChange}
      label="Testing & Inspection Remarks"
      formKey="observation_testing_remarks"
    />
  );
  const InputOtherRemarks = (
    <InputRemarks
      form={form}
      handleFormChange={handleFormChange}
      label="Other Remarks"
      formKey="observation_other_remarks"
    />
  );

  const TableOpenItems = (
    <SimpleTable
      className="open-items-field-report"
      headers={[
        'ITEM NO',
        'DESCRIPTION',
        'ASSIGN TO',
        'DUE DATE',
        'CRITICAL',
        '',
      ]}
      rows={openItemsRows}
      noRows={true}
    />
  );
  const ImageUploader = (
    <ImageDescriptionUpload
      images={form.project_images}
      deletedImages={form.deleted_project_images}
      onChange={(project_images) => {
        handleFormChange({ project_images });
      }}
      onDelete={(project_images, deleted_project_images) => {
        handleFormChange({ project_images, deleted_project_images });
      }}
    />
  );

  return (
    <React.Fragment>
      <div>
        <div className="form-block-container trade-block">
          <div className="form-block small-margin">{InputTradeCode}</div>
          {form.open_items.length === 0 && (
            <Button
              key="add-open-item"
              label="Add Open Item"
              type={BUTTON_TYPES.LINK}
              icon={BUTTON_ICONS.PLUS}
              onClick={handleAddOpenItem}
            />
          )}
          {!showImages && (
            <Button
              key="add-photo"
              label="Add Photos"
              type={BUTTON_TYPES.LINK}
              icon={BUTTON_ICONS.PLUS}
              onClick={handleAddPhotos}
            />
          )}
        </div>
        <div className="form-block-container">
          <div className="form-block small-margin">{InputLocation}</div>
          <div className="form-block small-margin">{InputArea}</div>
        </div>
        <div className="form-block small-margin">
          <CollapsibleSection
            title="Observation"
            initialShow
            right={
              <Button
                key="load-prev-report"
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Load Previous"
                className={'load-prev-report'}
                onClick={handleLoadPrevius}
              />
            }
          >
            <div className="form-block observation-block">
              <div className="form-control-container observation">
                {InputTradeCount}
                {InputTask}
                {InputTaskRemarks}
              </div>
              <div className="form-control-container observation">
                {InputContractComplete}
                {InputSchedule}
                {InputScheduleRemarks}
              </div>
              <div className="form-control-container observation">
                {InputDesign}
                {InputDesignRemarks}
              </div>
              <div className="form-control-container observation">
                {InputQuality}
                {InputQualityRemarks}
              </div>
              <div className="form-control-container observation">
                {InputSafety}
                {InputSafetyRemarks}
              </div>
              <div className="form-control-container observation">
                {InputMaterialRemarks}
                {InputEquipmentRemarks}
              </div>
              <div className="form-control-container observation">
                {InputTestingRemarks}
                {InputOtherRemarks}
              </div>
            </div>
          </CollapsibleSection>
        </div>
        {form.open_items.length > 0 && (
          <CollapsibleSection
            title="Open Items"
            initialShow
            right={
              <Button
                key="add-open-item"
                label="Add Open Item"
                type={BUTTON_TYPES.LINK}
                icon={BUTTON_ICONS.PLUS}
                onClick={handleAddOpenItem}
              />
            }
          >
            {TableOpenItems}
          </CollapsibleSection>
        )}
        {showImages && (
          <div className="form-block small-margin">
            <CollapsibleSection title="Photos" initialShow>
              {ImageUploader}
            </CollapsibleSection>
          </div>
        )}
      </div>

      <ProjectOpenItemFormModal handleClose={handleAddOpenItemClose} />
      <WindowDragAndDrop
        accept={'image/*'}
        disabled={
          showOpenItemFormModal ||
          showAddProjectAreaModal ||
          showAddProjectSiteModal
        }
        onDrop={(images) => {
          handleAddPhotos();
          const project_images = [...form.project_images];
          images.forEach((file) => {
            project_images.push({ url: URL.createObjectURL(file), file });
          });
          handleFormChange({ project_images });
        }}
      />
    </React.Fragment>
  );
};

const InputRemarks = ({
  form,
  handleFormChange,
  formKey,
  className = '',
  label,
}) => {
  return (
    <FormControlBlock
      hideBottomLabel
      className={`${className} inline-block`}
      label={form[formKey] && label}
      control={
        <CharsCounterTextarea
          placeholder={label}
          value={form[formKey]}
          valid={!(form.errors && form.errors[formKey])}
          onChange={(v) => handleFormChange({ [formKey]: v })}
          limit={500}
        />
      }
    />
  );
};

const InputYesOrNo = ({
  form,
  handleFormChange,
  formKey,
  className = '',
  label,
}) => (
  <FormControlBlock
    hideBottomLabel
    className={className}
    label={form[formKey] && label}
    control={
      <Selector
        className="select-dropdown"
        placeholder={label}
        value={form[formKey]}
        onChange={(option) =>
          handleFormChange({
            [formKey]: option ? option.value : option,
          })
        }
        valid={!(form.errors && form.errors[formKey])}
        options={[
          { value: 'yes', label: 'Yes' },
          { value: 'no', label: 'No' },
        ]}
        clearable
      />
    }
  />
);

export default connect((state) => ({
  darkMode: getDarkMode(state),
  directory: getDirectory(state),
  projectSites: getProjectSites(state),
  currentProject: getCurrentProject(state),
  currentUser: getCurrentUser(state),
  showOpenItemFormModal: getShowOpenItemFormModal(state),
  showAddProjectAreaModal: getShowAddProjectAreaModal(state),
  showAddProjectSiteModal: getShowAddProjectSiteModal(state),
}))(FieldReportStatus);
