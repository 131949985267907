import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { getDarkMode } from 'selectors/theme';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import PersonIcon from '@mui/icons-material/Person';
import IconButton from '@mui/material/IconButton';
import ListAltIcon from '@mui/icons-material/ListAlt';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import MessageModal, {
  MODAL_ICONS,
} from 'components/shared/modal/MessageModal';
import vectorSvg from 'components/shared/table/vector.svg';
import vectorDarkSvg from 'components/shared/table/vector-dark.svg';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import newMeetingsPermissions from 'permissions/new-meetings-permissions';
import Meeting from 'domain/new-meeting';
import Menu, { MENU_SELECTOR } from 'components/shared/menu/Menu';
import DropdownMenuWithIcon from 'components/shared/menu/DropdownMenuWithIcon';
import EditNoteIcon from '@mui/icons-material/EditNote';
import FilterHeader from 'components/shared/table/FilterHeader';
import { snakeToCamel } from '../../../../../services/utils/case-utils';
import { iCalToReadable } from '../../../../../services/utils/date-util';
import LockIcon from '@mui/icons-material/Lock';
import {
  getProjectNewMeetingsFilters,
  getMeetingsTitles,
  getMeetingsTypes,
  getMeetingsParentsTitles,
  getMeetingsParentsTypes,
  getProjectNewMeetingsInfoModal,
  getProjectMeetingsParentsTabNavigation,
  getProjectNewMeetingsAddTypeModal,
  getProjectNewMeetingsAddTitleModal,
  getProjectNewMeetingsEditTypeModal,
  getProjectNewMeetingsEditTitleModal,
  getMeetingsInstanceModal,
  getProjectNewMeetingsForm,
  getMeetingsInstanceForm,
  getActionItems,
  getSubmitCorrectionsModalShow,
  getReviewCorrectionsModalShow,
  getViewCorrectionsModalShow,
  getPendingCorrectionsErrorModalShow,
  getInstancesPdfViewerShow,
  getPreviewModalShow,
  getMeetingsInstancesFilters,
} from './store/selectors';
import './ParentInstancesTable.css';
import {
  setAddTypeModalShow,
  setAddTitleModalShow,
  setEditTypeModalShow,
  setEditTitleModalShow,
} from './store/actions';
import InfoMeetingModal from './InfoMeetingModal';
import AddTypeOrTitleModal from './AddTypeOrTitleModal';
import EditTypeOrTitleModal from './EditTypeOrTitleModal';
import MeetingsCorrectionsSubmitModal from './MeetingsCorrectionsSubmitModal';
import MeetingsCorrectionsReviewModal from './MeetingsCorrectionsReviewModal';
import MeetingsCorrectionsViewAllModal from './MeetingsCorrectionsViewAllModal';
import useParentsInstanceTable from './ParentsInstancesTable.hook';
import PendingCorrectionsErrorModal from './PendingCorrectionsErrorModal';
import PdfViewer from '../../../../shared/pdf-viewer/PdfViewer';
import MeetingsInstanceSendEmailModal from './MeetingsInstanceSendEmailModal';

const VectorIcon = (props) => {
  const { initialState, darkMode, toggleTable, toggleRow, table } = props;
  const [isToggled, setIsToggled] = useState(initialState);

  return (
    <img
      alt="collapsable-indicator"
      src={`${darkMode ? vectorDarkSvg : vectorSvg}`}
      className={`${isToggled ? 'rotated-vector-icon' : null}`}
      onClick={() => {
        setIsToggled(!isToggled);
        table ? toggleTable() : toggleRow();
      }}
    />
  );
};

const ParentsInstancesTable = ({
  className,
  rows,
  emptyMessage,
  headersOnly,
  newView,
  darkMode,
  stickyHeader,
  noRows,
  handleFilterChange,
  meetingsParentsTitles,
  meetingsParentsTypes,
  currentFilters,
  currentInstancesFilters,
  currentUser,
  currentProject,
  infoModalShow,
  dispatch,
  tabNavigation,
  showEditTypeModal,
  showEditTitleModal,
  showAddTitleModal,
  showAddTypeModal,
  showInstanceForm,
  instanceForm,
  showReviewCorrectionsModal,
  showSubmitCorrectionsModal,
  showViewCorrectionsModal,
  showPendingCorrectionsErrorModal,
  showMeetingsInstancePdfModal,
  showWaitingPreview,
}) => {
  const [expandedParentRows, setExpandedParentRows] = useState({});
  const [showTable, setShowTable] = useState(true);
  const {
    handleRestore,
    handleShowInfo,
    menuActions,
    handleOpenMinutes,
    handleCorrectionMenuChange,
    instanceMenuOptions,
    instancePdfUrl,
    handleClosePdfViewer,
    emailSubject,
    handleOpenAgenda,
    handleClosePreviewModal,
    handleDownloadIcal,
  } = useParentsInstanceTable({
    currentProject,
    dispatch,
    instanceForm,
    rows,
    currentUser,
  });
  const toggleParentRow = useCallback((id) => {
    setExpandedParentRows((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  }, []);
  const hfc = (key, value) => {
    const newfilters = { ...currentFilters, page: 1, [key]: value };
    const sortKeys = ['by_meeting_parent_type', 'by_meeting_parent_title'];
    if (sortKeys.includes(key)) {
      sortKeys.forEach((k) => {
        if (k !== key) newfilters[k] = null;
      });
    }
    handleFilterChange(newfilters, currentInstancesFilters);
  };
  const hfcInstance = (key, value, parent) => {
    const newFilters = { ...currentInstancesFilters, page: 1, [key]: value };
    const sortKeys = ['by_meeting_parent_type', 'by_meeting_parent_title'];
    if (sortKeys.includes(key)) {
      sortKeys.forEach((k) => {
        if (k !== key) newFilters[k] = null;
      });
    }
    handleFilterChange(currentFilters, { ...newFilters, parent_id: parent.id });
  };

  const correctionsMenuOptions = [
    { value: 'submit_correction', label: 'Submit Correction' },
    { value: 'view_all_corrections', label: 'View All Corrections' },
  ];

  const childActions = (instance) => {
    const camelCaseInstance = snakeToCamel(instance);
    const hasNotes =
      camelCaseInstance.meetingsInstancesNotes &&
      camelCaseInstance.meetingsInstancesNotes[0] &&
      camelCaseInstance.meetingsInstancesNotes[0].notes &&
      camelCaseInstance.meetingsInstancesNotes[0].notes.length > 0;

    const canOpenAgenda = newMeetingsPermissions.canOpenAgenda(
      currentUser,
      instance
    );
    const canOpenMinutes = !(
      !!camelCaseInstance.isAgenda &&
      newMeetingsPermissions.canOpenMinutes(currentUser, instance)
    );
    return (
      <div className="actions-container">
        <IconButton
          className="action-icon"
          style={{ opacity: canOpenAgenda ? 1 : 0.5 }}
          onClick={() =>
            handleOpenAgenda(camelCaseInstance, rows, instanceForm)
          }
          disabled={!canOpenAgenda}
        >
          <OpenInNewIcon />
        </IconButton>
        <IconButton
          className="action-icon"
          style={{ opacity: canOpenMinutes ? 1 : 0.5 }}
          onClick={() =>
            handleOpenMinutes(camelCaseInstance, rows, instanceForm)
          }
          disabled={!canOpenMinutes}
        >
          <ListAltIcon />
        </IconButton>

        <IconButton
          className={`action-icon ${!hasNotes ? 'disabled' : ''}`}
          style={{ opacity: hasNotes ? 1 : 0.5 }}
          onClick={() => {}}
          disabled
        >
          <EditNoteIcon />
        </IconButton>

        <div className="menu-container-small">
          <Menu
            key="more-actions-meetings"
            selector={MENU_SELECTOR.MORE_OPTIONS_VERTICAL}
            items={instanceMenuOptions(instance)}
          />
        </div>
      </div>
    );
  };
  const actions = (meeting) => {
    const unresolvedCorrections = meeting.meetings_corrections.filter(
      (c) => !(c.status === 'approved' || c.status === 'rejected')
    );
    const hasUnresolved =
      unresolvedCorrections && unresolvedCorrections.length > 0;
    if (
      newMeetingsPermissions.canReviewCorrections(currentUser, meeting) &&
      correctionsMenuOptions.length < 3
    ) {
      correctionsMenuOptions.push({
        value: 'review_corrections',
        label: 'Review Corrections',
      });
    }
    if (tabNavigation === 'archived') {
      return (
        <div className="actions-container-archived">
          {newMeetingsPermissions.canSeeInfo(currentUser, meeting) && (
            <Tooltip
              title={
                <div className="tooltip-container">
                  <div className="tooltip-title">Meeting Info</div>
                  <div className="tooltip-text">
                    Meeting Information (read only).
                  </div>
                </div>
              }
              position="bottom"
            >
              <IconButton
                className="action-icon"
                onClick={() => handleShowInfo(meeting)}
              >
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
          {newMeetingsPermissions.canSeeInfo(currentUser, meeting) && (
            <div className="button-container-archived">
              <Button
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Restore"
                onClick={() => handleRestore(meeting)}
              />
            </div>
          )}
        </div>
      );
    }
    return (
      <div className="actions-container">
        {newMeetingsPermissions.canSeeInfo(currentUser, meeting) && (
          <Tooltip
            title={
              <div className="tooltip-container">
                <div className="tooltip-title">Meeting Info</div>
                <div className="tooltip-text">
                  Meeting Information (read only).
                </div>
              </div>
            }
            position="bottom"
          >
            <IconButton
              className="action-icon-button"
              onClick={() => handleShowInfo(meeting)}
            >
              <InfoOutlinedIcon className="action-icon" />
            </IconButton>
          </Tooltip>
        )}
        {newMeetingsPermissions.canDownloadiCal(currentUser, meeting) && (
          <Tooltip
            title={
              <div className="tooltip-container">
                <div className="tooltip-title">Download Ical</div>
                <div className="tooltip-text">Downloads Ical file.</div>
              </div>
            }
            position="bottom"
          >
            <IconButton
              className="action-icon-button"
              onClick={() => handleDownloadIcal(meeting)}
            >
              <CalendarTodayOutlinedIcon className="action-icon" />
            </IconButton>
          </Tooltip>
        )}

        {
          <Tooltip
            title={
              <div className="tooltip-container">
                <div className="tooltip-title">Meeting Corrections</div>
                <div className="tooltip-text">
                  See and submit meeting corrections.
                </div>
              </div>
            }
            placement="top"
          >
            <div style={{ marginLeft: -10 }}>
              <DropdownMenuWithIcon
                renderIcon
                style={{ marginLeft: -10 }}
                className="dropdown-alert-menu"
                icon={() => {
                  return (
                    <WarningAmberOutlinedIcon
                      className={`${
                        hasUnresolved
                          ? 'dropdown-alert-icon'
                          : 'dropdown-alert-icon-green'
                      }`}
                    />
                  );
                }}
                options={correctionsMenuOptions}
                onChange={(option) =>
                  handleCorrectionMenuChange(option, meeting)
                }
              />
            </div>
          </Tooltip>
        }
        {newMeetingsPermissions.canSeeMenu(currentUser, meeting) && (
          <div className="menu-container-parent" style={{ marginLeft: 10 }}>
            <Menu
              key="more-actions-meetings"
              selector={MENU_SELECTOR.MORE_OPTIONS_VERTICAL}
              items={menuActions(meeting)}
            />
          </div>
        )}
      </div>
    );
  };
  const typeCell = (parentRow) => {
    return (
      <>
        <div className="type-cell">
          {Meeting.isAttendee(currentUser, parentRow) && (
            <IconButton className="person-icon" onClick={() => {}}>
              <PersonIcon />
            </IconButton>
          )}
          <div className="parent-row-text">{parentRow.meeting_type}</div>
        </div>
      </>
    );
  };
  const renderRow = (parentRow) => {
    const userIsAttendee = Meeting.isAttendee(currentUser, parentRow);
    if (parentRow.privateMeeting && !userIsAttendee) return null;
    const childRows =
      parentRow.meetings_instances && parentRow.meetings_instances.length > 0
        ? parentRow.meetings_instances.map((childRow) =>
            renderChildRow(childRow)
          )
        : null;
    const startTime = parentRow.start_time
      ? new Date(parentRow.start_time).toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })
      : '';
    const scheduleCell = parentRow.next_meeting
      ? `${parentRow.next_meeting} ${startTime}`
      : '';
    const addressCell = parentRow.onlineMeeting
      ? 'Online Meeting'
      : parentRow.address;

    return (
      <>
        <tr key={parentRow.id} className={`parent-row`}>
          <td className="small-row-parent">
            <VectorIcon
              darkMode={darkMode}
              initialState={false}
              toggleRow={() => toggleParentRow(parentRow.id)}
              table={false}
            />
          </td>

          <td className="parent-row-cell">
            <div className="parent-row-text">{typeCell(parentRow)}</div>
          </td>
          <td className="parent-row-cell">
            <div className="parent-row-text">{parentRow.title}</div>
          </td>
          <td className="parent-row-cell">
            <div className="parent-row-text">
              {parentRow.created_by ? parentRow.created_by.full_name : '--'}
            </div>
          </td>
          <td className="parent-row-cell">
            <div className="parent-row-text">{scheduleCell}</div>
          </td>
          <td className="parent-row-cell">
            <div className="parent-row-text">
              {parentRow.recurrence ? iCalToReadable(parentRow.recurrence) : ''}
            </div>
          </td>
          <td className="parent-row-cell">
            <div className="parent-row-text">{addressCell}</div>
          </td>
          <td className="parent-row-cell">
            <div className="parent-row-text">{actions(parentRow)}</div>
          </td>
        </tr>
        {!!expandedParentRows[parentRow.id] && childRowsHeader(parentRow)}
        {!!expandedParentRows[parentRow.id] && childRows}
      </>
    );
  };

  const submitterFilterOptions = [
    { id: 'ascending', label: 'Ascending' },
    { id: 'descending', label: 'Descending' },
  ];

  const childRowsHeader = (parent) => {
    const handleChangeDateFrom = (value) => {
      hfcInstance('date_from', value, parent);
    };
    const handleChangeDateTo = (value) => {
      hfcInstance('date_to', value, parent);
    };
    const handleChangeDateFilter = (value) => {
      hfcInstance('by_date', value, parent);
    };
    const handleSubmitterFilterChange = (value) => {
      hfcInstance('by_submitter', value, parent);
    };
    const dateFilterOptions = [
      { id: 'clear_filter', label: 'Clear Filter' },
      { id: 'newest', label: 'Newest' },
      { id: 'oldest', label: 'Oldest' },
      {
        id: 'range',
        label: 'Range',
        subitems: [
          {
            id: 'from',
            label: 'From',
            isDate: true,
            onChange: handleChangeDateFrom,
            onDateChange: handleChangeDateFrom,
            dateValue: currentInstancesFilters.date_from,
          },
          {
            id: 'to',
            label: 'To',
            isDate: true,
            onChange: handleChangeDateTo,
            onDateChange: handleChangeDateTo,
            dateValue: currentInstancesFilters.date_to,
          },
        ],
      },
    ];
    return (
      <tr key="meetings-table-header" className="child-header-row">
        <td className="child-header-small-row-cell">
          {parent.private_meeting && (
            <IconButton className="action-icon-lock" disabled>
              <LockIcon className="action-icon" />
            </IconButton>
          )}
        </td>
        <td className="child-header-row-cell">MEETING NO</td>
        <td className="child-header-row-cell">
          <FilterHeader
            clearable
            search={true}
            label="DATE"
            options={dateFilterOptions}
            selected={currentInstancesFilters.by_date}
            className="simple-header"
            onFilter={handleChangeDateFilter}
          />
        </td>
        <td className="child-header-row-cell-big">
          <FilterHeader
            clearable
            search={true}
            label="SUBMITTED BY"
            options={submitterFilterOptions}
            selected={currentInstancesFilters.by_submitter}
            className="simple-header"
            onFilter={handleSubmitterFilterChange}
          />
        </td>
        <td className="child-header-row-cell"></td>
        <td className="child-header-row-cell">STATUS</td>
        <td className="child-header-row-cell">AGENDA</td>
        <td className="child-header-row-cell">ACTIONS</td>
      </tr>
    );
  };
  const renderInstanceStatus = (childRow) => {
    const {
      status,
      minutes_published_date,
      minutes_republished_date,
    } = childRow;
    if (!status) return 'Agenda Mode';
    if (status.includes('agenda')) return 'Agenda Mode';
    if (status === 'draft_minutes') return 'Draft';
    if (status === 'published_minutes')
      return `Published ${moment(minutes_published_date).format('MM/DD/YYYY')}`;
    if (status === 'republished_minutes')
      return `Republished ${moment(minutes_republished_date).format(
        'MM/DD/YYYY'
      )}`;
  };
  const renderAgendaStatus = (childRow) => {
    if (childRow.status === 'draft_agenda') return 'Draft';
    if (childRow.agenda_published_date && !childRow.agenda_republished_date)
      return `Published ${moment(childRow.agenda_published_date).format(
        'MM/DD/YYYY'
      )}`;
    if (childRow.agenda_republished_date)
      return `Republished ${moment(childRow.agenda_republished_date).format(
        'MM/DD/YYYY'
      )}`;
  };
  const renderChildRow = (childRow) => {
    const formattedMeetingDate = childRow.meeting_date
      ? moment(childRow.meeting_date).format('MM/DD/YYYY')
      : '--';
    return (
      <tr key={childRow.id} className="child-row">
        <td className="child-header-small-row-cell"></td>
        <td className="child-row-cell">{childRow.formatted_number}</td>
        <td className="child-row-cell">{formattedMeetingDate}</td>
        <td className="child-row-cell-big" colSpan={2}>
          {childRow.minutes_submitted_by
            ? `${childRow.minutes_submitted_by.label}`
            : '--'}
        </td>
        <td className="child-row-cell">{renderInstanceStatus(childRow)}</td>
        <td className="child-row-cell">{renderAgendaStatus(childRow)}</td>
        <td className="child-row-cell">{childActions(childRow)}</td>
      </tr>
    );
  };

  const toggleTable = () => {
    setShowTable(!showTable);
  };

  /* These two because of how FilterHeader is set up*/
  let meetingTypesOptions = meetingsParentsTypes.map((option) => {
    return { ...option, id: option.label };
  });
  let meetingTitlesOptions = meetingsParentsTitles.map((option) => {
    return { ...option, id: option.label };
  });
  meetingTypesOptions = [
    ...meetingTypesOptions,
    { id: 'Add Type', value: 'addType', label: 'Add Type' },
    { id: 'Edit Type', value: 'editType', label: 'Edit Type' },
  ];
  meetingTitlesOptions = [
    ...meetingTitlesOptions,
    { id: 'Add Title', value: 'addTitle', label: 'Add Title' },
    { id: 'Edit Title', value: 'editTitle', label: 'Edit Title' },
  ];
  const createdByOptions = [
    { id: 'ascending', label: 'Ascending' },
    { id: 'descending', label: 'Descending' },
  ];
  const handleCreatedByFilterChange = (value) => {
    hfc('by_created_by', value);
  };
  const handleTypeFilterChange = (value) => {
    if (value === 'Add Type') {
      dispatch(setAddTypeModalShow(true));
      return;
    }
    if (value === 'Edit Type') {
      dispatch(setEditTypeModalShow(true));
      return;
    }
    hfc('by_meeting_parent_type', value);
  };
  const handleTitleFilterChange = (value) => {
    if (value === 'Add Title') {
      dispatch(setAddTitleModalShow(true));
      return;
    }
    if (value === 'Edit Title') {
      dispatch(setEditTitleModalShow(true));
      return;
    }
    hfc('by_meeting_parent_title', value);
  };

  const renderHeaders = () => {
    return (
      <tr key="meetings-table-header" className="header-row">
        <td className="small-row-header">
          <VectorIcon
            darkMode={darkMode}
            initialState={false}
            toggleTable={toggleTable}
            table={true}
          />
        </td>
        <td className="filter-container">
          <FilterHeader
            clearable
            search={true}
            label="TYPE"
            options={meetingTypesOptions}
            selected={currentFilters.by_meeting_parent_type}
            className="simple-header"
            onFilter={handleTypeFilterChange}
          />
        </td>
        <td className="filter-container">
          <FilterHeader
            clearable
            search={true}
            label="TITLE"
            options={meetingTitlesOptions}
            selected={currentFilters.by_meeting_parent_title}
            className="simple-header"
            onFilter={handleTitleFilterChange}
          />
        </td>

        <td className="header-row-cell">
          <span className="header-row-text">
            <FilterHeader
              clearable
              search={true}
              label="CREATED BY"
              options={createdByOptions}
              selected={currentFilters.by_created_by}
              className="simple-header"
              onFilter={handleCreatedByFilterChange}
            />
          </span>
        </td>
        <td className="header-row-cell">
          <span className="header-row-text">SCHEDULE</span>
        </td>
        <td className="header-row-cell">
          <span className="header-row-text">RECURRENCE</span>
        </td>
        <td className="header-row-cell">
          <span className="header-row-text">ADDRESS</span>
        </td>
        <td className="header-row-cell">
          <span className="header-row-text">ACTIONS</span>
        </td>
      </tr>
    );
  };

  const emptyRows = !noRows && (
    <tr>
      <td className="td-empty-table" colSpan={7}>
        {emptyMessage}
      </td>
    </tr>
  );
  const archivedRows =
    rows.data && rows.data.filter((row) => row.status === 'archived');
  const nonArchivedRows =
    rows.data && rows.data.filter((row) => row.status !== 'archived');

  return (
    <React.Fragment>
      {!newView && !showInstanceForm && (
        <Table
          className={`parents-table ${className || ''} ${
            darkMode ? 'dark-mode' : ''
          } ${stickyHeader ? 'sticky-header' : ''}`}
        >
          {renderHeaders()}
          {showTable && !headersOnly && (
            <tbody>
              {tabNavigation === 'archived'
                ? archivedRows.length > 0
                  ? archivedRows.map((row) => renderRow(row))
                  : emptyRows
                : nonArchivedRows.length > 0
                ? nonArchivedRows.map((row) => renderRow(row))
                : emptyRows}
            </tbody>
          )}
        </Table>
      )}
      {infoModalShow && <InfoMeetingModal showActions={true} primary={true} />}
      {showAddTypeModal && (
        <AddTypeOrTitleModal
          showActions={true}
          primary={true}
          modalType="type"
        />
      )}
      {showAddTitleModal && (
        <AddTypeOrTitleModal
          showActions={true}
          primary={true}
          modalType="title"
        />
      )}
      {showEditTypeModal && (
        <EditTypeOrTitleModal
          showActions={true}
          primary={true}
          modalType="type"
        />
      )}
      {showEditTitleModal && (
        <EditTypeOrTitleModal
          showActions={true}
          primary={true}
          modalType="title"
        />
      )}
      {showSubmitCorrectionsModal && <MeetingsCorrectionsSubmitModal />}
      {showReviewCorrectionsModal && <MeetingsCorrectionsReviewModal />}
      {showViewCorrectionsModal && <MeetingsCorrectionsViewAllModal />}
      {showPendingCorrectionsErrorModal && <PendingCorrectionsErrorModal />}
      {showMeetingsInstancePdfModal && (
        <PdfViewer
          content={{
            source: instancePdfUrl,
            type: 'url',
          }}
          show={showMeetingsInstancePdfModal}
          onClose={handleClosePdfViewer}
        />
      )}
      <MessageModal
        show={showWaitingPreview}
        icon={MODAL_ICONS.LOADING}
        message={<div>Making PDF, please wait...</div>}
        iconSize={45}
        onHide={handleClosePreviewModal}
      />
      <MeetingsInstanceSendEmailModal subjectItem={emailSubject} />
    </React.Fragment>
  );
};

ParentsInstancesTable.propTypes = {
  className: PropTypes.string,
  headers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  emptyMessage: PropTypes.string,
  showBottomLine: PropTypes.bool,
  headersOnly: PropTypes.bool,
  actionCells: PropTypes.number,
  onRowSelected: PropTypes.func,
  onGenerateLink: PropTypes.func,
  stickyHeader: PropTypes.bool,
  noRows: PropTypes.bool,
  handleFilterChange: PropTypes.func,
  showMeetingsInstanceModal: PropTypes.bool,
};

ParentsInstancesTable.defaultProps = {
  headers: [],
  rows: [],
  emptyMessage: 'No Items',
  showBottomLine: false,
  headersOnly: false,
  newView: false,
  stickyHeader: false,
  noRows: false,
  actionCells: 0,
};

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
    meetingsTitles: getMeetingsTitles(state),
    meetingsTypes: getMeetingsTypes(state),
    meetingsParentsTitles: getMeetingsParentsTitles(state),
    meetingsParentsTypes: getMeetingsParentsTypes(state),
    currentFilters: getProjectNewMeetingsFilters(state),
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    infoModalShow: getProjectNewMeetingsInfoModal(state),
    tabNavigation: getProjectMeetingsParentsTabNavigation(state),
    showAddTypeModal: getProjectNewMeetingsAddTypeModal(state),
    showAddTitleModal: getProjectNewMeetingsAddTitleModal(state),
    showEditTypeModal: getProjectNewMeetingsEditTypeModal(state),
    showEditTitleModal: getProjectNewMeetingsEditTitleModal(state),
    showInstanceForm: getMeetingsInstanceModal(state),
    form: getProjectNewMeetingsForm(state),
    instanceForm: getMeetingsInstanceForm(state),
    actionItems: getActionItems(state),
    showSubmitCorrectionsModal: getSubmitCorrectionsModalShow(state),
    showReviewCorrectionsModal: getReviewCorrectionsModalShow(state),
    showViewCorrectionsModal: getViewCorrectionsModalShow(state),
    showPendingCorrectionsErrorModal: getPendingCorrectionsErrorModalShow(
      state
    ),
    showMeetingsInstancePdfModal: getInstancesPdfViewerShow(state),
    showWaitingPreview: getPreviewModalShow(state),
    currentInstancesFilters: getMeetingsInstancesFilters(state),
  };
})(ParentsInstancesTable);
