const keys = {};

export const RfiStatusKeys = {
  DRAFT: 'draft',
  PENDING: 'pending',
  ASSIGNED: 'assigned',
  RESPONDED: 'responded',
  RESOLVED: 'resolved',
  VOID: 'void',
  FOR_RECORD: 'for_record',
};

export class RfiStatusEnum {
  constructor(status, label) {
    this.status = status;
    this.label = label;

    keys[status] = this;
  }

  static create(status) {
    if (keys[status]) {
      return keys[status];
    }

    return RfiStatusEnum.UNKNOWN;
  }

  equals(rfiStatusEnum) {
    return this.status === rfiStatusEnum.status;
  }
}

RfiStatusEnum.DRAFT = new RfiStatusEnum('draft', 'draft');
RfiStatusEnum.PENDING = new RfiStatusEnum('pending', 'pending');
RfiStatusEnum.ASSIGNED = new RfiStatusEnum('assigned', 'assigned');
RfiStatusEnum.RESPONDED = new RfiStatusEnum('responded', 'responded');
RfiStatusEnum.RESOLVED = new RfiStatusEnum('resolved', 'resolved');
RfiStatusEnum.VOID = new RfiStatusEnum('void', 'void');
RfiStatusEnum.FOR_RECORD = new RfiStatusEnum('for_record', 'For Record Only');
