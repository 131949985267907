export function setImages(files, form, handleFormChange) {
  let images = [...getImages(form)];
  files.forEach(function (val) {
    images.push({
      url: URL.createObjectURL(val),
      file: val,
    });
  });
  handleFormChange('images', images);
}

export function removeImage(index, form, handleFormChange) {
  let images = [...getImages(form)];
  let deletedImages = [];
  let deletedImage = images.splice(index, 1);
  deletedImages.push(deletedImage[0]);
  handleFormChange('images', images, 'deletedImages', deletedImages);
}

export function getImages(form) {
  if (form.images) {
    return form.images;
  }
  return [];
}

export function setDocuments(files, form, handleFormChange) {
  let docs = [...getDocuments(form)];
  files.forEach((val) => {
    docs.push({
      file: val,
      name: val.name,
    });
  });
  handleFormChange('documents', docs);
}

export function removeDocument(index, form, handleFormChange) {
  let docs = [...getDocuments(form)];
  let deletedDocs = [];
  let deletedDoc = docs.splice(index, 1);
  deletedDocs.push(deletedDoc[0]);
  handleFormChange('documents', docs, 'deletedDocs', deletedDocs);
}

export function getDocuments(form) {
  if (form.documents) {
    return form.documents;
  }
  return [];
}
