import React from 'react';
import {
  Player,
  ControlBar,
  BigPlayButton,
  PlayToggle,
  VolumeMenuButton,
  ProgressControl,
  FullscreenToggle,
} from 'video-react';
import 'video-react/dist/video-react.css';

const ProjectPhotosDetailVideo = (props) => {
  const { video } = props;

  return (
    <Player
      playsInline
      fluid={false}
      autoPlay={false}
      height={'100%'}
      src={video.video.video.url}
      className="detail-video"
    >
      <ControlBar disableDefaultControls className="detail-video-control-bar">
        <PlayToggle />
        <VolumeMenuButton />
        <ProgressControl />
        <FullscreenToggle />
      </ControlBar>
      <BigPlayButton position="center" className="detail-video-play-button" />
    </Player>
  );
};

export default ProjectPhotosDetailVideo;
