import axios from 'axios';
import BaseApi from './base-api';

class BluebeamApi extends BaseApi {
  isAuthorized() {
    return axios
      .get(`${this.baseUrl}/bluebeam/authorized`)
      .then((response) => {
        return {
          ok: true,
          data: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  authorize(documentId) {
    return axios
      .get(`${this.baseUrl}/bluebeam/authorize?doc_id=${documentId}`)
      .then((response) => {
        return {
          ok: true,
          url: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  editInBluebeam(documentId, documentSrc) {
    return axios
      .get(
        `${this.baseUrl}/bluebeam/open_session?doc_id=${documentId}&src=${documentSrc}`
      )
      .then((response) => {
        return {
          ok: true,
          url: response.data.url,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  closeSession(sessionId) {
    return axios
      .post(`${this.baseUrl}/bluebeam/close_session?id=${sessionId}`)
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  inviteUsers(recipients, projectId, documentId) {
    return axios
      .post(`${this.baseUrl}/bluebeam/invite_users`, {
        recipients: recipients,
        project_id: projectId,
        document_id: documentId,
      })
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  generateSnapshot(documentId) {
    return axios
      .post(`${this.baseUrl}/bluebeam/generate_snapshot`, {
        document_id: documentId,
      })
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const bluebeamApi = new BluebeamApi();
