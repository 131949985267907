import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './SubmittalCard.css';
import moment from 'moment';
import TextareaAutosizeWrapper from 'components/shared/input/TextareaAutosizeWrapper';
import SearchableDropDownPaginated from 'components/shared/dropdown/SearchableDropDownPaginated';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import Project from 'domain/project';
import SubmittalPermissions from 'permissions/submittal-permissions';
import { SubmittalStatusEnum } from 'domain/submittal-status-enum';
import {
  getSubmittalForm,
  getSubmittalReadOnly,
  getSubmittalGcAcceleration,
} from 'components/admin/projects/details/project-submittal-log/store/selectors';
import {
  updateSubmittalFormValue,
  deleteSubmittalFile,
  markSubmittalNotesRead,
} from 'components/admin/projects/details/project-submittal-log/store/actions';
import SubmittalDocuments from 'components/admin/projects/details/project-submittal-log/SubmittalDocuments';
import SubmittalAddlAssignees from 'components/admin/projects/details/project-submittal-log/SubmittalAddlAssignees';
import { getDarkMode } from 'selectors/theme';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import ReadOnlyInput from 'components/shared/input/ReadOnlyInput';
import User from 'domain/user';
import SubmittalResponseModal from './SubmittalResponseModal';
import SubmittalResponsesViewModal from './SubmittalResponsesViewModal';
import CollapsibleSection from '../../../../shared/collapsible-section/CollapsibleSection';
class SubmittalCard extends Component {
  static propTypes = {
    currentUser: PropTypes.shape({}).isRequired,
    project: PropTypes.shape({}).isRequired,
    directory: PropTypes.shape({}).isRequired,
    submittal: PropTypes.shape({}),
  };

  componentDidMount() {
    this.handleMarkSubmittalNotesRead();
  }

  loadContributors = () => {
    const { directory } = this.props;

    return {
      ok: true,
      options: Project.filterRecipients({
        directory,
        primaryOnly: true,
      }).map(({ id, label }) => ({ value: id, label })),
    };
  };

  recipientOptions = () => {
    const { directory } = this.props;
    return Project.recipientOptions({ directory });
  };

  handleFormChange = (attribute, value) => {
    this.props.dispatch(updateSubmittalFormValue(attribute, value));
  };

  handleFormDeleteFile = (attribute, value) => {
    this.props.dispatch(deleteSubmittalFile(attribute, value));
  };

  handleMarkSubmittalNotesRead = () => {
    const { currentUser, form, dispatch } = this.props;
    if (form.id) {
      dispatch(markSubmittalNotesRead(form.id, form, currentUser.id));
    }
  };

  makeDate = (date) => {
    return date
      ? ` on ${moment(date).format('MM/DD/YYYY')} at ${moment(date).format(
          'hh:mm A'
        )}`
      : '';
  };

  makeResponseLabel = (user, date) => {
    return (
      <ReadOnlyInput
        value={`${User.fullNameWithCompany(user)}${this.makeDate(date)}`}
      />
    );
  };

  makeAssignedLabel = (user) => {
    const userName = Array.isArray(user)
      ? user[1]
      : User.fullNameWithCompany(user);
    return <ReadOnlyInput value={userName || '-'} />;
  };

  makeSubmittedLabel = () => {
    const { form, currentUser } = this.props;

    return (
      <ReadOnlyInput
        value={`${User.fullNameWithCompany(
          form.submittedBy || currentUser
        )}${this.makeDate(form.submitted_at || form.received_at)}`}
      />
    );
  };

  render() {
    const {
      currentUser,
      project,
      directory,
      submittal,
      form,
      readOnly,
      gcAcceleration,
      darkMode,
    } = this.props;

    const canAssign = SubmittalPermissions.canAssign(
      currentUser,
      project,
      directory,
      submittal
    );

    const requestReadOnly =
      readOnly ||
      !SubmittalPermissions.canEdit(currentUser, project, directory, submittal);

    const canAddRemarks =
      !readOnly &&
      SubmittalPermissions.canAddRemarks(
        currentUser,
        submittal,
        gcAcceleration
      );

    //my reply goes last
    const addlAssignees = form.submittal_addl_assignees
      ? [
          ...form.submittal_addl_assignees.filter(
            (assignee) =>
              assignee.assignee_id !== currentUser.id && assignee.stamp_action
          ),
          form.submittal_addl_assignees.find(
            (assignee) => assignee.assignee_id === currentUser.id
          ),
        ].filter((assignee) => assignee)
      : [];

    return (
      <>
        <div className={`submittal-card ${darkMode ? 'dark-mode' : ''}`}>
          <CollapsibleSection title="Submittal Question">
            <DetailsTitle
              className="submittal-question-title"
              title="Submittal testing"
            />
            <div className="form-block">
              <FormControlBlock
                label="Submitted by"
                control={this.makeSubmittedLabel()}
              />
            </div>
            {(requestReadOnly || canAssign) && (
              <React.Fragment>
                <div className="form-block">
                  <FormControlBlock
                    label={
                      requestReadOnly
                        ? 'Assigned To'
                        : form.assignee.length > 0 && 'Assign To'
                    }
                    control={
                      <SearchableDropDownPaginated
                        placeholder="Assign To"
                        defaultArrow={true}
                        loadOptions={this.loadContributors}
                        onChange={(option) => {
                          this.handleFormChange('assignee', [
                            option && option.value,
                            option && option.label,
                          ]);
                          this.handleFormChange(
                            'status',
                            SubmittalStatusEnum.ASSIGNED.status
                          );
                        }}
                        readOnly={requestReadOnly}
                        value={
                          form.assignee.length > 0
                            ? {
                                value: form.assignee[1],
                                label: form.assignee[1],
                              }
                            : requestReadOnly
                            ? '-'
                            : null
                        }
                      />
                    }
                  />
                </div>
                <div className="form-block">
                  <FormControlBlock
                    label="Additional Assignees"
                    control={
                      <SubmittalAddlAssignees
                        project={project}
                        readOnly={requestReadOnly}
                      />
                    }
                  />
                </div>
              </React.Fragment>
            )}
            {canAssign && (
              <div className="form-block">
                <FormControlBlock
                  label={
                    (requestReadOnly || form.recipients.length > 0) &&
                    'CC Recipients'
                  }
                  control={
                    requestReadOnly ? (
                      <ReadOnlyInput
                        value={
                          form.recipients.length > 0
                            ? form.recipients.map((rcpt) => rcpt[1]).join(', ')
                            : '-'
                        }
                      />
                    ) : (
                      <MultipleDropdown
                        placeholder="CC Recipients"
                        options={this.recipientOptions()}
                        currentValues={form.recipients}
                        onChange={(values) =>
                          this.handleFormChange('recipients', values)
                        }
                      />
                    )
                  }
                />
              </div>
            )}
            <div className="form-block">
              <FormControlBlock
                label={(requestReadOnly || !!form.question) && 'Request'}
                control={
                  <TextareaAutosizeWrapper
                    placeholder={!requestReadOnly ? 'Request' : '-'}
                    disabled={requestReadOnly}
                    initialValue={form.question || ''}
                    valid={!form.errors.question}
                    onChange={(value) =>
                      this.handleFormChange('question', value)
                    }
                  />
                }
              />
            </div>
          </CollapsibleSection>
        </div>
        <SubmittalDocuments
          submittal={submittal}
          form={form}
          fileType="req"
          handleFormChange={this.handleFormChange}
          handleFormDeleteFile={this.handleFormDeleteFile}
          readOnly={requestReadOnly}
        />

        <SubmittalResponseModal
          form={form}
          canAddRemarks={canAddRemarks}
          currentUser={currentUser}
          addlAssignees={addlAssignees}
          readOnly={readOnly}
          submittal={submittal}
          makeAssignedLabel={this.makeAssignedLabel}
          makeResponseLabel={this.makeResponseLabel}
          handleFormChange={this.handleFormChange}
          handleFormDeleteFile={this.handleFormDeleteFile}
          project={project}
        />
        <SubmittalResponsesViewModal
          form={form}
          canAddRemarks={canAddRemarks}
          currentUser={currentUser}
          addlAssignees={addlAssignees}
          readOnly={readOnly}
          submittal={submittal}
          makeAssignedLabel={this.makeAssignedLabel}
          makeResponseLabel={this.makeResponseLabel}
          handleFormChange={this.handleFormChange}
          handleFormDeleteFile={this.handleFormDeleteFile}
          project={project}
        />
      </>
    );
  }
}

export default connect((state) => {
  return {
    form: getSubmittalForm(state),
    readOnly: getSubmittalReadOnly(state),
    gcAcceleration: getSubmittalGcAcceleration(state),
    darkMode: getDarkMode(state),
  };
})(SubmittalCard);
