export const OWNER_AUTHORIZED_REPS_LOAD_SUCCESS =
  'OWNER_AUTHORIZED_REPS_LOAD_SUCCESS';
export const OWNER_AUTHORIZED_REPS_PAGE_CHANGE =
  'OWNER_AUTHORIZED_REPS_PAGE_CHANGE';
export const OWNER_AUTHORIZED_REP_LOAD_SUCCESS =
  'OWNER_AUTHORIZED_REP_LOAD_SUCCESS';
export const OWNER_AUTHORIZED_REP_ONBOARDING_STAGE_CHANGE =
  'OWNER_AUTHORIZED_REP_ONBOARDING_STAGE_CHANGE';
export const OWNER_AUTHORIZED_REPS_CONTACTS_MODAL_SHOW =
  'OWNER_AUTHORIZED_REPS_CONTACTS_MODAL_SHOW';
export const OWNER_AUTHORIZED_REP_PROJECTS_LOAD_SUCCESS =
  'OWNER_AUTHORIZED_REP_PROJECTS_LOAD_SUCCESS';
export const OWNER_AUTHORIZED_REP_PROJECTS_PAGE_CHANGE =
  'OWNER_AUTHORIZED_REP_PROJECTS_PAGE_CHANGE';
export const OWNER_AUTHORIZED_REPS_USERS_SET =
  'OWNER_AUTHORIZED_REPS_USERS_SET';
export const OWNER_AUTHORIZED_REPS_INVITE_FORM_CLEAR =
  'OWNER_AUTHORIZED_REPS_INVITE_FORM_CLEAR';
export const OWNER_AUTHORIZED_REPS_INVITE_USER_SHOW =
  'OWNER_AUTHORIZED_REPS_INVITE_USER_SHOW';
export const OWNER_AUTHORIZED_REPS_ROLES_LOAD_SUCCESS =
  'OWNER_AUTHORIZED_REPS_ROLES_LOAD_SUCCESS';
export const OWNER_AUTHORIZED_REPS_USER_ADD = 'OWNER_AUTHORIZED_REPS_USER_ADD';
export const OWNER_AUTHORIZED_REPS_NEW_FORM_VALID =
  'OWNER_AUTHORIZED_REPS_NEW_FORM_VALID';
export const OWNER_AUTHORIZED_REPS_NEW_FORM_INVALID =
  'OWNER_AUTHORIZED_REPS_NEW_FORM_INVALID';
export const OWNER_AUTHORIZED_REPS_NEW_FORM_VALUE_CHANGE =
  'OWNER_AUTHORIZED_REPS_NEW_FORM_VALUE_CHANGE';
