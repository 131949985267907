import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-autosize-textarea';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import classnames from 'classnames';
import vector from '../dropdown/vector.svg';
import vectorDark from '../dropdown/vector-dark.svg';

class TextareaAutosizeWrapper extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    rows: PropTypes.number,
    maxRows: PropTypes.number,
    maxExpandedRows: PropTypes.number,
    placeholder: PropTypes.string,
    initialValue: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    autoFocus: PropTypes.bool,
    valid: PropTypes.bool,
    clear: PropTypes.bool,
    maxLength: PropTypes.number,
    defaultValue: PropTypes.string,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onPaste: PropTypes.func,
    showExpandButton: PropTypes.bool,
    style: PropTypes.shape({}),
  };

  static defaultProps = {
    rows: 1,
    maxRows: 4,
    valid: true,
    showExpandButton: true,
    maxExpandedRows: 100,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.value || props.initialValue || '',
      expanded: null,
    };
    this.textArea = React.createRef();
    this.expandButton = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.clear && nextProps.clear) {
      this.setState({ value: nextProps.value || nextProps.initialValue || '' });
    }

    if (
      typeof nextProps.value !== 'undefined' &&
      this.props.value !== nextProps.value
    ) {
      this.setState({ value: nextProps.value || '' });
    }
  }

  handleOnBlur = (e) => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(e.target.value);
    }
  };

  handleOnChange = (e) => {
    const { onChange } = this.props;

    this.setState({ value: e.target.value });
    if (onChange) {
      onChange(e.target.value);
    }
  };

  setExpanded = (expanded) => {
    const { value } = this.state;

    this.setState({ expanded, value: `${value} ` });
    window.setTimeout(() => this.setState({ value: `${value}` }), 10);
  };

  isExpanded = () => {
    const { disabled } = this.props;
    const { expanded } = this.state;

    return expanded !== null ? expanded : disabled;
  };

  hasOverflow = () => {
    if (
      !this.textArea ||
      !this.textArea.current ||
      !this.textArea.current.textarea ||
      !this.textArea.current.state ||
      !this.textArea.current.state.lineHeight ||
      !this.expandButton ||
      !this.expandButton.current
    )
      return false;

    const { maxRows } = this.props;
    const {
      textarea: domElement,
      state: { lineHeight },
    } = this.textArea.current;
    const size = parseInt(domElement.style.height.replace('px', ''), 10);
    const currentRows = size / lineHeight;
    return currentRows > maxRows;
  };

  render() {
    const {
      id,
      className,
      rows,
      maxRows,
      maxExpandedRows,
      placeholder,
      disabled,
      required,
      valid,
      maxLength,
      defaultValue,
      readOnly,
      darkMode,
      autoFocus,
      onPaste,
      showExpandButton,
      style,
    } = this.props;
    const { value } = this.state;

    const isExpanded = this.isExpanded();

    const classNames = {
      'text-area': true,
      'input-area': true,
      'form-control': true,
      'dark-mode': darkMode,
      invalid: !valid,
      'without-border': disabled,
    };
    if (className) {
      classNames[className] = true;
    }

    const textArea = (
      <TextareaAutosize
        id={id}
        className={classnames(classNames)}
        rows={rows}
        maxRows={showExpandButton && isExpanded ? maxExpandedRows : maxRows}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        value={value}
        maxLength={maxLength}
        defaultValue={defaultValue}
        readOnly={readOnly}
        autoFocus={autoFocus}
        onChange={(e) => this.handleOnChange(e)}
        onBlur={(e) => this.handleOnBlur(e)}
        onPaste={onPaste}
        ref={this.textArea}
        style={style}
      />
    );
    if (!showExpandButton) return textArea;

    return (
      <div
        className={classnames('text-area-container', {
          'dark-mode': darkMode,
          invalid: !valid,
          'without-border': disabled,
        })}
      >
        {textArea}
        <div
          onClick={() => this.setExpanded(!isExpanded)}
          className={classnames('expand-textarea-button', {
            expanded: isExpanded,
            'dark-mode': darkMode,
          })}
          style={{ display: !this.hasOverflow() ? 'none' : null }}
          ref={this.expandButton}
        >
          <img src={darkMode ? vectorDark : vector} alt={'arrow'} />
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(TextareaAutosizeWrapper);
