import {
  WORK_ORDERS_DETAILS_PROPOSAL_APPROVAL_MODAL_CLOSE,
  WORK_ORDERS_DETAILS_PROPOSAL_APPROVAL_SELECT,
} from '../../../../actions/admin/work-orders/action-types';

const initialState = {
  selectedProposalId: null,
  selectedProposalNumber: null,
};

export default function proposalApprovalDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case WORK_ORDERS_DETAILS_PROPOSAL_APPROVAL_MODAL_CLOSE:
      return {
        ...state,
        selectedProposalId: null,
        selectedProposalNumber: null,
      };

    case WORK_ORDERS_DETAILS_PROPOSAL_APPROVAL_SELECT:
      return {
        ...state,
        selectedProposalId: action.payload.id,
        selectedProposalNumber: action.payload.number,
      };

    default:
      return state;
  }
}
