import {
  WORK_ORDERS_DETAILS_QUALIFICATIONS_ADD_SUCCESS,
  WORK_ORDERS_DETAILS_QUALIFICATIONS_LOAD_SUCCESS,
  WORK_ORDERS_DETAILS_QUALIFICATIONS_TRADES_LOAD_SUCCESS,
} from '../../../../actions/admin/work-orders/action-types';

const initialState = {
  qualifications: [],
  trades: [],
};

export default function qualificationsDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case WORK_ORDERS_DETAILS_QUALIFICATIONS_ADD_SUCCESS:
      return {
        ...state,
        qualifications: state.qualifications.concat([action.payload]),
      };

    case WORK_ORDERS_DETAILS_QUALIFICATIONS_LOAD_SUCCESS:
      return {
        ...state,
        qualifications: action.payload,
      };

    case WORK_ORDERS_DETAILS_QUALIFICATIONS_TRADES_LOAD_SUCCESS:
      return {
        ...state,
        trades: action.payload,
      };

    default:
      return state;
  }
}
