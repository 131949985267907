import axios from 'axios';
import BaseApi from 'services/api/base-api';

class TimeApi extends BaseApi {
  deleteTimeEntry(timeId) {
    return axios
      .delete(`${this.baseUrl}/time_entries/${timeId}`)
      .then(() => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  editTimeEntry(timeId, values) {
    const apiPayload = {
      time_entry: {
        name_title: values.name,
        start_date: values.startDate,
        time_in: values.timeIn,
        time_out: values.timeOut,
      },
    };
    return axios
      .put(`${this.baseUrl}/time_entries/${timeId}`, apiPayload)
      .then((response) => {
        return {
          ok: true,
          timeEntry: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateTimeEntryRate(timeId, rate) {
    const apiPayload = {
      time_entry: {
        rate: rate,
      },
    };
    return axios
      .put(`${this.baseUrl}/time_entries/${timeId}`, apiPayload)
      .then((response) => {
        return {
          ok: true,
          timeEntry: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const timeApi = new TimeApi();
