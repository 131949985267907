import {
  USERS_INVITE_USER_FORM_INVALID,
  USERS_INVITE_USER_FORM_LOAD,
  USERS_INVITE_USER_FORM_VALID,
  USERS_INVITE_USER_FORM_VALUE_UPDATE,
  USERS_INVITE_USER_ID_SET,
  USERS_INVITE_USER_MODAL_SHOW,
} from '../../../actions/admin/users/action-types';
import { UserModalState } from '../../../components/shared/modal/InviteUserModal';

const initialState = {
  currentUserId: 0,
  form: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    rolesId: null,
    title: '',
  },
  invalidFields: [],
  showInviteUserModal: false,
  userModalState: UserModalState.NEW,
  validationMessage: '',
};

export default function usersUiReducer(state = initialState, action) {
  switch (action.type) {
    case USERS_INVITE_USER_FORM_INVALID:
      return {
        ...state,
        invalidFields: action.payload.invalidFields,
        validationMessage: action.payload.message,
      };

    case USERS_INVITE_USER_FORM_LOAD:
      return {
        ...state,
        form: action.payload,
        invalidFields: [],
        validationMessage: '',
      };

    case USERS_INVITE_USER_FORM_VALID:
      return {
        ...state,
        invalidFields: [],
        validationMessage: '',
      };

    case USERS_INVITE_USER_FORM_VALUE_UPDATE:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case USERS_INVITE_USER_ID_SET:
      return {
        ...state,
        currentUserId: action.payload,
      };

    case USERS_INVITE_USER_MODAL_SHOW:
      const newState = {
        ...state,
        showInviteUserModal: action.payload.open,
      };
      if (action.payload.state !== undefined) {
        newState['userModalState'] = action.payload.state;
      }

      return newState;

    default:
      return state;
  }
}
