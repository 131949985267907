import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import FormModal from 'components/shared/modal/FormModal';
import SearchBar from 'components/shared/navigation/SearchBar';
import Date from 'components/shared/text/Date';
import SimpleTable from 'components/shared/table/SimpleTable';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import './ProjectReportFormModal.css';
import { getFieldProjectReports } from 'components/admin/projects/details/project-daily-report-log/store/selectors';

class ProjectReportProjectSummaryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
    };
  }

  toDateFormat = (value) => {
    return value ? <Date value={value} format="MM/DD/YYYY" /> : '--';
  };

  getName = (value) => {
    return value.first_name + ' ' + value.last_name;
  };

  toFormat = (number) => {
    return numeral(number).format('$0,0.00');
  };

  viewNoItems = (label) => {
    return <div className="modal-no-items text-color">No {label}</div>;
  };

  findReportNum = (projectReportId) => {
    const { fieldProjectReports } = this.props;
    let report = fieldProjectReports.find(
      (report) => report.id === projectReportId
    );
    return report ? report.number : '';
  };

  mapContractInformation = () => {
    const { data, onRestore } = this.props;

    return data.map((item) => {
      let reportNum = this.findReportNum(
        item.previous_published_report_id || item.project_report_id
      );
      return {
        id: item.id,
        data: [
          reportNum < 10 ? `0${reportNum}` : reportNum,
          item.description,
          this.toFormat(item.amount),
          this.toDateFormat(item.start),
          this.toDateFormat(item.completion),
          this.getName(item.resolved_by),
          this.toDateFormat(item.resolved_at),
          <Button
            key="action-restore"
            type={BUTTON_TYPES.LINK}
            color={BUTTON_COLORS.GREEN}
            label="Restore"
            onClick={() => onRestore(item)}
          />,
        ],
      };
    });
  };

  render() {
    const { show, modalName, onClose, onSearch, data } = this.props;
    const remarks = modalName === 'resolved-remarks' ? data : [];
    const title =
      modalName === 'resolved-remarks'
        ? 'Resolved Summary Remarks'
        : 'Resolved Contract Information';
    const headers = [
      'REPORT NO',
      'DESCRIPTION',
      'AMOUNT',
      'START',
      'COMPLETION',
      'RESOLVED BY',
      'RESOLVED ON ',
      '',
    ];

    return (
      <FormModal
        id="project-report-form-modal"
        className="project-report-form-modal-view"
        show={show}
        onClose={() => {
          onClose();
          this.setState({ search: '' });
        }}
        subTitle="Project Report"
      >
        <div className="container-header-title">
          <div className="modal-name text-color">{title}</div>
          <SearchBar
            className="search-input-background"
            searchPlaceHolder="Search"
            searchText={this.state.search}
            onSearch={(value) => this.setState({ search: value })}
            onSearchTextChange={(value) => {
              this.setState({ search: value });
              onSearch(value);
            }}
          />
        </div>
        {modalName === 'resolved-remarks' && (
          <div className="container-body-remark">
            {remarks.length > 0
              ? remarks
                  .filter((report) => report.remarks)
                  .map((report) => (
                    <div key={report.id}>
                      <div className="label-section">
                        <span className="label text-color">Report No</span>
                        <span className="value text-color">
                          {report.number < 10
                            ? `0${report.number}`
                            : report.number}
                        </span>
                        <span className="label text-color">Report Date </span>
                        <span className="value text-color">
                          {this.toDateFormat(report.date)}
                        </span>
                        <span className="label text-color">Submitted By</span>
                        <span className="value text-color">
                          {report.submitted_by && report.submitted_by.label}
                        </span>
                      </div>
                      <div className="remark-section">
                        <span className="label-remark">Remark</span>
                        <div className="value-remark text-color">
                          {report.remarks}
                        </div>
                      </div>
                    </div>
                  ))
              : this.viewNoItems('Remarks')}
          </div>
        )}
        {modalName === 'resolved-contract' && (
          <div className="container-body-project-report-modal-table">
            <SimpleTable
              className="simple-table projects-table modal-contract-info-table"
              headers={headers}
              rows={this.mapContractInformation()}
              noRows={data.length === 0}
            />
          </div>
        )}
      </FormModal>
    );
  }
}

ProjectReportProjectSummaryModal.propTypes = {
  show: PropTypes.bool,
  modalName: PropTypes.string,
  onClose: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape()),
  onRestore: PropTypes.func,
};

ProjectReportProjectSummaryModal.defaultProps = {
  show: false,
  modalName: 'resolved-remarks',
  onClose: () => null,
  onRestore: () => null,
  data: [],
};

export default connect((state) => {
  return {
    fieldProjectReports: getFieldProjectReports(state),
  };
})(ProjectReportProjectSummaryModal);
