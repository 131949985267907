export const CREATED_BB_SESSION = 'CREATED_BB_SESSION';
export const CREATED_BB_SNAPSHOT = 'CREATED_BB_SNAPSHOT';

export function createdBluebeamSession() {
  return {
    type: CREATED_BB_SESSION,
  };
}

export function createdBluebeamSnapshot() {
  return {
    type: CREATED_BB_SNAPSHOT,
  };
}
