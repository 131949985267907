import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';

import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';

import ProjectPhotoPermissions from 'permissions/project-photo-permissions';

import {
  getProjectPhotosDetail,
  getProjectPhotosEditImage,
} from './store/selectors';
import ProjectPhotosFavorite from './ProjectPhotosFavorite';
import {
  removeProjectPhotosDetail,
  setProjectPhotosEditImage,
} from './store/actions';
import useProjectPhotosActions from './ProjectPhotosActions.hook';

const ProjectPhotosDetailMenu = (props) => {
  const { dispatch, currentUser, currentProject, currentPhoto, edit } = props;
  const { onMove, onRecycle, onEmail, onDownload } = useProjectPhotosActions(
    props
  );

  const hasPermission = ProjectPhotoPermissions.hasItemPermission(
    currentUser,
    currentProject,
    currentPhoto
  );
  const canUserEdit = !currentPhoto.is_video && hasPermission;
  const itemsIds = [{ type: 'photo', id: currentPhoto.id }];

  const onEdit = () => {
    dispatch(setProjectPhotosEditImage(!edit));
  };
  const handleDelete = () => {
    onRecycle(itemsIds)().then((response) => {
      if (response.ok) dispatch(removeProjectPhotosDetail(currentPhoto.id));
    });
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={1.5}
      className="slider-menu"
    >
      {hasPermission && (
        <Grid item>
          <ProjectPhotosFavorite
            id={currentPhoto.id}
            favorite={currentPhoto.favorite}
            type="photo"
            outlined={!currentPhoto.favorite}
            width={20}
            height={20}
          />
        </Grid>
      )}
      {canUserEdit && (
        <Grid item>
          <Button
            type={BUTTON_TYPES.LINK}
            icon={edit ? BUTTON_ICONS.MARKUP_GREEN : BUTTON_ICONS.MARKUP}
            onClick={onEdit}
          />
        </Grid>
      )}
      {hasPermission && (
        <Grid item>
          <Button
            type={BUTTON_TYPES.LINK}
            icon={BUTTON_ICONS.MOVE}
            onClick={onMove(itemsIds)}
          />
        </Grid>
      )}
      {hasPermission && (
        <Grid item>
          <Button
            type={BUTTON_TYPES.LINK}
            icon={BUTTON_ICONS.DOWNLOAD_BLACK}
            onClick={onDownload(itemsIds)}
          />
        </Grid>
      )}
      {hasPermission && (
        <Grid item>
          <Button
            type={BUTTON_TYPES.LINK}
            icon={BUTTON_ICONS.EMAIL_BLACK}
            onClick={onEmail(itemsIds)}
          />
        </Grid>
      )}
      {hasPermission && (
        <Grid item>
          <Button
            type={BUTTON_TYPES.LINK}
            icon={BUTTON_ICONS.DELETE_BIN_BLACK}
            onClick={handleDelete}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default connect((state) => ({
  photos: getProjectPhotosDetail(state),
  currentProject: getCurrentProject(state),
  currentUser: getCurrentUser(state),
  edit: getProjectPhotosEditImage(state),
}))(ProjectPhotosDetailMenu);
