import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './RfiDocuments.css';
import DocumentUpload, {
  FILE_TYPES,
} from 'components/shared/document-upload/DocumentUpload';
import DocumentLinkBluebeam from 'components/shared/document/DocumentLinkBluebeam';
import ImageUpload from 'components/shared/image-upload/ImageUpload';
import FormControlBlock from 'components/shared/form/FormControlBlock';

class RfiDocuments extends Component {
  static propTypes = {
    questionKey: PropTypes.string.isRequired,
    question: PropTypes.shape({}).isRequired,
    questionAddlAssignee: PropTypes.shape({}),
    readOnly: PropTypes.bool,
    fileType: PropTypes.oneOf(['req', 'res']).isRequired,
    handleQuestionChange: PropTypes.func.isRequired,
    handleQuestionDeleteFile: PropTypes.func.isRequired,
  };

  static defaultProps = {
    questionAddlAssignee: null,
  };

  constructor(props) {
    super(props);

    switch (props.fileType) {
      case 'req':
        this.imgsAttr = 'reqImages';
        this.docsAttr = 'reqDocs';
        this.imgsDelAttr = 'reqImagesDelete';
        this.docsDelAttr = 'reqDocsDelete';
        this.rfiImgsAttr = 'request_images';
        this.rfiDocsAttr = 'request_documents';
        break;
      case 'res':
        this.imgsAttr = 'resImages';
        this.docsAttr = 'resDocs';
        this.imgsDelAttr = 'resImagesDelete';
        this.docsDelAttr = 'resDocsDelete';
        this.rfiImgsAttr = 'response_images';
        this.rfiDocsAttr = 'response_documents';
        break;
      default:
    }
  }

  componentDidUpdate() {
    this.initFiles();
  }

  mapFiles = (files, type) => {
    return (files || []).map((file) => {
      return {
        id: file.id,
        url: file[type].url,
        preview: file[type].gallery && file[type].gallery.url,
      };
    });
  };

  initFiles = () => {
    const { questionAddlAssignee, question, fileType, readOnly } = this.props;

    if (!question.id || readOnly || this.initialized) {
      return;
    }
    this.initialized = true;

    if (questionAddlAssignee && fileType === 'res') {
      this.handleFormChange(
        this.imgsAttr,
        this.mapFiles(questionAddlAssignee[this.rfiImgsAttr], 'image')
      );
      this.handleFormChange(
        this.docsAttr,
        this.mapFiles(questionAddlAssignee[this.rfiDocsAttr], 'document')
      );
    } else {
      this.handleFormChange(
        this.imgsAttr,
        this.mapFiles(question[this.rfiImgsAttr], 'image')
      );
      this.handleFormChange(
        this.docsAttr,
        this.mapFiles(question[this.rfiDocsAttr], 'document')
      );
    }
  };

  setImages = (files, imagesRef) => {
    let images = imagesRef.slice();
    files.forEach(function (val) {
      images.push({
        url: val.url || URL.createObjectURL(val),
        file: val,
      });
    });

    return images;
  };

  setRfiImages = (files) => {
    const images = this.setImages(files, this.props.question[this.imgsAttr]);
    this.handleFormChange(this.imgsAttr, images);
  };

  removeRfiImage = (index) => {
    const img = this.props.question[this.imgsAttr][index];
    this.props.question[this.imgsAttr].splice(index, 1);
    this.handleFormDeleteFile(this.imgsDelAttr, img);
    this.handleFormChange(this.imgsAttr, this.props.question[this.imgsAttr]);
  };

  dropDocuments = (files, docsRef) => {
    let docs = docsRef.slice();
    files.forEach(function (val) {
      docs.push({
        file: val,
        name: val.name,
      });
    });

    return docs;
  };

  dropRfiDocuments = (files) => {
    const docs = this.dropDocuments(files, this.props.question[this.docsAttr]);
    this.handleFormChange(this.docsAttr, docs);
  };

  removeRfiDocument = (index) => {
    const doc = this.props.question[this.docsAttr][index];
    this.props.question[this.docsAttr].splice(index, 1);
    this.handleFormDeleteFile(this.docsDelAttr, doc);
    this.handleFormChange(this.docsAttr, this.props.question[this.docsAttr]);
  };

  renderUploaderName = (uploadedBy) => {
    return (
      uploadedBy && (
        <div>
          {uploadedBy.first_name} {uploadedBy.last_name}
        </div>
      )
    );
  };

  handleFormChange = (attribute, value) => {
    const { questionKey, handleQuestionChange } = this.props;
    handleQuestionChange(questionKey, attribute, value);
  };

  handleFormDeleteFile = (attribute, value) => {
    const { questionKey, handleQuestionDeleteFile } = this.props;
    handleQuestionDeleteFile(questionKey, attribute, value);
  };

  renderDocuments = () => {
    const { question, questionAddlAssignee, readOnly } = this.props;
    const documents =
      (!readOnly && question[this.docsAttr]) ||
      this.mapFiles(
        (questionAddlAssignee || question)[this.rfiDocsAttr],
        'document'
      );

    return (
      (documents.length > 0 || !readOnly) && (
        <div className="rfi-documents">
          <FormControlBlock
            className="without-focus"
            label="Documents"
            control={
              <div className="rfi-resources-body">
                {documents.map((doc, index) => (
                  <DocumentLinkBluebeam
                    key={index}
                    file={doc}
                    onRemove={
                      !readOnly ? () => this.removeRfiDocument(index) : null
                    }
                  />
                ))}
                {!readOnly && (
                  <DocumentUpload
                    fileType={FILE_TYPES.ANY}
                    onDrop={this.dropRfiDocuments}
                  />
                )}
              </div>
            }
          />
        </div>
      )
    );
  };

  renderImages = () => {
    const { question, questionAddlAssignee, readOnly } = this.props;

    const images =
      (!readOnly && question[this.imgsAttr]) ||
      this.mapFiles(
        (questionAddlAssignee || question)[this.rfiImgsAttr],
        'image'
      );

    return (
      (images.length > 0 || !readOnly) && (
        <div className="rfi-images">
          <FormControlBlock
            className="without-focus"
            label="Photos"
            control={
              <div className="rfi-resources-body">
                <ImageUpload
                  extensions={['.jpg', '.jpeg', '.gif', '.png', '.heic']}
                  images={images}
                  readOnly={readOnly}
                  onRemove={(index) => this.removeRfiImage(index)}
                  onUpload={(files) => this.setRfiImages(files)}
                />
              </div>
            }
          />
        </div>
      )
    );
  };

  render() {
    const documents = this.renderDocuments();
    const images = this.renderImages();

    return (
      !!(documents || images) && (
        <div className="rfi-resources">
          {documents}
          {images}
        </div>
      )
    );
  }
}

export default connect()(RfiDocuments);
