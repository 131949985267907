import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import './DetailsHeader.css';

class DetailsHeader extends Component {
  static propTypes = {
    leftHeader: PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
      value: PropTypes.string,
    }).isRequired,
    leftHeaderExtraComponents: PropTypes.any,
    rightHeaders: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          value: PropTypes.any,
        })
      )
    ),
  };

  static defaultProps = {
    rightHeaders: [],
  };

  render() {
    return (
      <div className="details-header">
        {this.props.leftHeader.title && (
          <Col md={12}>
            <div className="left-title">{this.props.leftHeader.title}</div>
          </Col>
        )}
        <div>
          <Col md={6}>
            <div className="left-header">
              <div>
                <div className="h1">{this.props.leftHeader.value}</div>
                {this.props.leftHeaderExtraComponents !== undefined && (
                  <div className="left-header-extra">
                    {this.props.leftHeaderExtraComponents}
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col md={6} className="right-container">
            {this.props.rightHeaders.map((item, index) => (
              <div key={index} className="right-header">
                <div>
                  {item.map((header, index) => {
                    return (
                      <div key={index} className={header.className}>
                        <div className="small-bold">{header.title}</div>
                        <div className="small-regular">{header.value}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </Col>
        </div>
      </div>
    );
  }
}

export default DetailsHeader;
