import { fieldReportsApi } from 'services/api/field-reports-api';
import { showErrorMessage } from 'actions/messages';
import {
  SET_FIELD_REPORTS,
  SET_FIELD_REPORTS_FILTERS,
  SET_FIELD_REPORT,
  SET_FIELD_REPORT_PDF,
  SET_FIELD_REPORT_HTML,
  SET_FIELD_REPORT_DELETE_MODAL,
  DELETE_FIELD_REPORT,
  SET_FIELD_REPORT_FORM,
  SET_FIELD_REPORT_RESOLVED_MODAL,
  SET_FIELD_REPORT_DRAFT_MODAL,
  SET_FIELD_REPORTS_CREATORS,
  FIELD_REPORT_RELOAD,
  SET_FIELD_REPORT_WAITING_PREVIEW,
} from './action-types';
import {
  loadDailyReportLogPdfSuccess,
  setCurrentPage,
  setReportsPageSize,
  setReportsTotal,
} from '../../store/actions';
import { weatherApi } from 'services/api/weather-api';
import {
  degreesToWindDirectionOption,
  weatherCodeToCondition,
  weatherCodeToPrecipitation,
  weatherCodeToRemarks,
} from 'domain/weather';
import { FIELD_REPORT_FORM_INIT } from 'domain/field-report';
import { loaderStart, loaderEnd } from 'actions/loader';
import moment from 'moment';
import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';
import TimezoneApi from 'services/api/timezone-api';

export function setFieldReportFilters(filters) {
  return {
    type: SET_FIELD_REPORTS_FILTERS,
    payload: filters,
  };
}

function setFieldReports(reports, total, filters) {
  return {
    type: SET_FIELD_REPORTS,
    payload: {
      total,
      reports,
      page: filters.page,
    },
  };
}

function deleteFieldReport(reportId) {
  return {
    type: DELETE_FIELD_REPORT,
    payload: reportId,
  };
}

const fieldReportFileToForm = (report, key, type) => {
  return {
    [key]: (report[key] || []).map((file) => ({
      id: file.id,
      url: file[type].url,
      preview: file[type].gallery && file[type].gallery.url,
      ...(type === 'image' ? { description: file.description } : {}),
    })),
  };
};

export function fieldReportToForm(report) {
  return {
    id: report.id,
    formatted_number: report.formatted_number,
    title: report.title || '',
    weather_temperature: report.weather_temperature,
    weather_condition:
      report.weather_condition && report.weather_condition !== 'none'
        ? report.weather_condition
        : null,
    weather_humidity: report.weather_humidity,
    weather_wind: report.weather_wind,
    weather_wind_direction:
      report.weather_wind_direction && report.weather_wind_direction !== 'none'
        ? report.weather_wind_direction
        : null,
    weather_precipitation:
      report.weather_precipitation && report.weather_precipitation !== 'none'
        ? report.weather_precipitation
        : null,
    weather_remarks: report.weather_remarks || '',
    description: report.description || '',
    date: moment(report.date).format('YYYY-MM-DD'),
    arrival_time: report.arrival_time,
    departure_time: report.departure_time,
    location:
      report.latitude && report.longitude
        ? {
            latitude: report.latitude,
            longitude: report.longitude,
          }
        : { ...FIELD_REPORT_FORM_INIT.location },
    trade: report.project_contributor
      ? report.project_contributor.id
      : report.project_contributor_type === 'all'
      ? 'all'
      : null,
    site: report.project_site
      ? report.project_site.id
      : report.project_site_type === 'all'
      ? 'all'
      : null,
    area: report.project_area
      ? report.project_area.id
      : report.project_area_type === 'all'
      ? 'all'
      : null,
    observation_trade_count: report.observation_trade_count,
    observation_task: report.observation_task || '',
    observation_task_remarks: report.observation_task_remarks || '',
    observation_contract_complete: report.observation_contract_complete,
    observation_schedule_status:
      report.observation_schedule_status &&
      report.observation_schedule_status !== 'none'
        ? report.observation_schedule_status
        : null,
    observation_schedule_remarks: report.observation_schedule_remarks || '',
    observation_design_complience:
      report.observation_design_complience &&
      report.observation_design_complience !== 'none'
        ? report.observation_design_complience
        : null,
    observation_design_remarks: report.observation_design_remarks || '',
    observation_quality_complience:
      report.observation_quality_complience &&
      report.observation_quality_complience !== 'none'
        ? report.observation_quality_complience
        : null,
    observation_quality_remarks: report.observation_quality_remarks || '',
    observation_safety_complience:
      report.observation_safety_complience &&
      report.observation_safety_complience !== 'none'
        ? report.observation_safety_complience
        : null,
    observation_safety_remarks: report.observation_safety_remarks || '',
    observation_material_remarks: report.observation_material_remarks || '',
    observation_equipment_remarks: report.observation_equipment_remarks || '',
    observation_testing_remarks: report.observation_testing_remarks || '',
    observation_other_remarks: report.observation_other_remarks || '',
    open_items: report.project_open_items,
    ...fieldReportFileToForm(report, 'project_images', 'image'),
    ...fieldReportFileToForm(report, 'report_images', 'image'),
    ...fieldReportFileToForm(report, 'report_documents', 'document'),
    deleted_project_images: [],
    deleted_report_images: [],
    deleted_report_documents: [],
    disclaimer: report.disclaimer || '',
    timezone: report.timezone || {
      value: 'PST-08:00',
      label: 'Pacific Standard Time -08:00',
      timezone_name: 'Pacific Standard Time',
    },
    recipients:
      report.users.map((recipient) => [recipient.id, recipient.label]) || [],
    status: report.status,
  };
}

function setFieldReportsCreators(creators) {
  return {
    type: SET_FIELD_REPORTS_CREATORS,
    payload: creators,
  };
}

export function reloadFieldReportSuccess(report) {
  return {
    type: FIELD_REPORT_RELOAD,
    payload: report,
  };
}

export function setFieldReportWaitingPreview(show) {
  return {
    type: SET_FIELD_REPORT_WAITING_PREVIEW,
    payload: show,
  };
}

export function setCurrentFieldReport(report) {
  return {
    type: SET_FIELD_REPORT,
    payload: report ? fieldReportToForm(report) : null,
  };
}

export function setFieldReportPdf(pdf) {
  return {
    type: SET_FIELD_REPORT_PDF,
    payload: pdf,
  };
}

export function setFieldReportHtml(html) {
  return {
    type: SET_FIELD_REPORT_HTML,
    payload: html,
  };
}

export function setFieldReportDeleteModal(show) {
  return {
    type: SET_FIELD_REPORT_DELETE_MODAL,
    payload: show,
  };
}

export function setFieldReportForm(show) {
  return {
    type: SET_FIELD_REPORT_FORM,
    payload: show,
  };
}

export function setFieldReportDraftModal(show) {
  return {
    type: SET_FIELD_REPORT_DRAFT_MODAL,
    payload: show,
  };
}

export function setFieldReportsResovedModal(show) {
  return {
    type: SET_FIELD_REPORT_RESOLVED_MODAL,
    payload: show,
  };
}

export function loadFieldReportsRequest(projectId, filters) {
  return (dispatch) => {
    return fieldReportsApi
      .getFieldReports(projectId, filters)
      .then((response) => {
        if (response.ok) {
          dispatch(setCurrentPage(filters.page));
          dispatch(setReportsTotal(response.total));
          dispatch(setReportsPageSize(response.pageSize));
          dispatch(setFieldReportFilters(filters));
          dispatch(setFieldReports(response.reports, response.total, filters));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
}

export function reloadFieldReport(projectId, reportId) {
  return (dispatch) => {
    return fieldReportsApi
      .getFieldReport(projectId, reportId, true)
      .then((response) => {
        if (response.ok) {
          dispatch(reloadFieldReportSuccess(response.report));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
}

const WAIT_PREVIEW_TIMEOUT = 120 * 1000;
export function waitFieldReportPreview(
  projectId,
  reportId,
  originalPdf,
  startTime
) {
  return (dispatch) => {
    return fieldReportsApi
      .getFieldReport(projectId, reportId, false, true)
      .then((response) => {
        if (response.ok) {
          if (new Date() - startTime < WAIT_PREVIEW_TIMEOUT) {
            const pdf = response.report.pdf_document;
            if (
              pdf &&
              pdf.document &&
              pdf.document.url &&
              (!originalPdf ||
                moment(originalPdf.updated_at).isBefore(
                  moment(pdf.updated_at),
                  's'
                ))
            ) {
              setTimeout(() => {
                dispatch(setFieldReportPdf(pdf.document.url));
                dispatch(setFieldReportWaitingPreview(false));
              }, 2000);
            } else {
              setTimeout(
                () =>
                  dispatch(
                    waitFieldReportPreview(
                      projectId,
                      reportId,
                      originalPdf,
                      startTime
                    )
                  ),
                6000
              );
            }
          } else {
            dispatch(showErrorMessage('Timeout waiting for preview'));
            dispatch(setFieldReportWaitingPreview(false));
          }
        } else {
          dispatch(showErrorMessage(response.errors));
          dispatch(setFieldReportWaitingPreview(false));
        }
        return response;
      });
  };
}

export function deleteFieldReportRequest(projectId, reportId) {
  return (dispatch) => {
    return fieldReportsApi
      .deleteFieldReport(projectId, reportId)
      .then((response) => {
        if (response.ok) {
          dispatch(deleteFieldReport(reportId));
          dispatch(setFieldReportDeleteModal(false));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
}

export function loadFieldReportsLogs(projectId) {
  return (dispatch) => {
    return fieldReportsApi.getFieldReportsLogs(projectId).then((response) => {
      if (response.ok) {
        dispatch(loadDailyReportLogPdfSuccess(response.pdf));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
  };
}

function weatherResponseToInformation(data) {
  const res = {
    weather_temperature: Math.round(data.temp),
    weather_humidity: Math.round(data.humidity),
  };

  if (data.wind) {
    res.weather_wind = Math.round(data.wind_speed);
    res.weather_wind_direction = degreesToWindDirectionOption(
      data.wind_deg
    ).value;
  }

  if (data.weather && data.weather.length > 0) {
    res.weather_condition = weatherCodeToCondition(data.weather[0]).value;
    res.weather_precipitation = weatherCodeToPrecipitation(data.weather[0]);
    if (res.weather_precipitation)
      res.weather_precipitation = res.weather_precipitation.value;
    res.weather_remarks = weatherCodeToRemarks(data.weather[0]);
  }

  return res;
}

export function importWeatherInformation(
  location,
  date,
  arrival_time,
  departure_time
) {
  return (dispatch) => {
    dispatch(loaderStart());
    return weatherApi
      .getWeather(location, date, arrival_time, departure_time)
      .then((response) => {
        dispatch(loaderEnd());
        if (response.ok) {
          return {
            ok: true,
            info: weatherResponseToInformation(response.data),
          };
        } else {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
}

export function loadPreviousFieldReport(projectId) {
  return (dispatch) => {
    return fieldReportsApi.getLastReport(projectId).then((response) => {
      if (response.ok) {
        return {
          ok: true,
          report: response.report ? fieldReportToForm(response.report) : null,
        };
      } else {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
  };
}

export function loadFieldReportsResolved(projectId, filters) {
  const newFilters = { ...filters, status: 'published', pageSize: 20 };
  return (dispatch) =>
    fieldReportsApi.getFieldReports(projectId, newFilters).then((response) => {
      if (response.ok) {
        dispatch(setCurrentPage(newFilters.page));
        dispatch(setReportsTotal(response.total));
        dispatch(setReportsPageSize(response.pageSize));
        dispatch(setFieldReports(response.reports, response.total, newFilters));
        dispatch(setFieldReportsResovedModal(true));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
}

export function saveFieldReport(projectId, report, isPreview) {
  return (dispatch) => {
    if (isPreview) dispatch(setFieldReportWaitingPreview(true));
    return fieldReportsApi
      .saveFieldReport(projectId, report, isPreview)
      .then((response) => {
        if (response.ok) {
          if (isPreview) {
            dispatch(
              waitFieldReportPreview(
                projectId,
                response.report.id,
                response.report.pdf_document,
                new Date()
              )
            );
          }
        } else {
          dispatch(showErrorMessage(response.errors));
          if (isPreview) dispatch(setFieldReportWaitingPreview(false));
        }
        return response;
      });
  };
}

function loadFieldReport(projectId, rfaId) {
  return (dispatch) => {
    return fieldReportsApi.getFieldReport(projectId, rfaId).then((response) => {
      if (!response.ok) dispatch(showErrorMessage(response.errors));
      return response;
    });
  };
}

export function openFieldReport(projectId, rfaId) {
  return (dispatch) => {
    dispatch(loadFieldReport(projectId, rfaId)).then((response) => {
      if (!response.ok) return;

      dispatch(setCurrentFieldReport(response.report));
      dispatch(setFieldReportForm(true));
    });
  };
}

export function loadFieldReportsCreators(projectId) {
  return (dispatch) => {
    return fieldReportsApi
      .getFieldReportsCreators(projectId)
      .then((response) => {
        if (response.ok) {
          dispatch(setFieldReportsCreators(response.creators));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
}

export function sendEmailFieldReport(
  projectId,
  reportId,
  emails,
  subject,
  message
) {
  return (dispatch) => {
    return fieldReportsApi
      .sendEmail(projectId, reportId, emails, subject, message)
      .then((response) => {
        if (response.ok) {
          dispatch(setShowEmailModal(false));
        } else {
          dispatch(showErrorMessage(response.errors));
        }
        return response;
      });
  };
}

export function loadTimezone(location) {
  return (dispatch) => {
    dispatch(loaderStart());
    return TimezoneApi.getTimezoneByLatLong(
      location.latitude,
      location.longitude
    ).then((response) => {
      dispatch(loaderEnd());
      if (response.ok) {
        return {
          ok: true,
          timezone_abbreviation: response.timezone_abbreviation,
          timezone_name: response.timezone_name,
        };
      } else {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
  };
}
