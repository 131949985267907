import { push } from 'react-router-redux';
import { usersApi } from 'services/api/users-api';
import { authenticationRequest } from 'actions/authentication';
import _ from 'lodash';

export const REGISTER_USER_UPDATE_FORM = 'REGISTER_USER_UPDATE_FORM';
export const REGISTER_USER_SET_ERRORS = 'REGISTER_USER_SET_ERRORS';

export function updateForm(attribute, value) {
  return {
    type: REGISTER_USER_UPDATE_FORM,
    payload: { attribute, value },
  };
}

const getErrorFields = (error) => {
  switch (error) {
    case 'Email has already been taken':
      return ['email'];
    default:
      return [];
  }
};

export function setErrors(errors = []) {
  return {
    type: REGISTER_USER_SET_ERRORS,
    payload: {
      messages: errors,
      fields: _.union(...errors.map(getErrorFields)),
    },
  };
}

export function registerUser(form) {
  return (dispatch) => {
    return usersApi.registerUser(form).then((response) => {
      if (response.ok) {
        return dispatch(authenticationRequest(form.email, form.password)).then(
          (response) => {
            if (response.ok) {
              dispatch(push('/'));
            } else {
              dispatch(setErrors(response.errors));
            }
          }
        );
      } else {
        dispatch(setErrors(response.errors));
      }
    });
  };
}
