import React from 'react';
import { Grid } from '@mui/material';
import Chip from './Chip';

const Chips = (props) => {
  const { values, onDelete, spacing } = props;

  const handleDelete = (v) => () => onDelete(v);

  return (
    <Grid className="chips-component" container spacing={spacing || 1}>
      {values.map((v, i) => (
        <Grid item key={'chip_' + i}>
          <Chip
            label={v.label}
            onDelete={!v.disabled ? handleDelete(v) : undefined}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default Chips;
