import React from 'react';
import moment from 'moment';
import FormModal from 'components/shared/modal/FormModal';
import './EmailHistoryModal.css';

const EmailHistoryModal = (props) => {
  const { title, subTitle, history, onClose, show } = props;
  return (
    <FormModal
      className="project-email-modal"
      show={show}
      title={title || 'Mail History'}
      subTitle={subTitle}
      onClose={onClose}
    >
      <div className="email-history-modal">
        {history.length < 1 && (
          <div className="small-regular">History is empty</div>
        )}
        {history.map((el, i) => (
          <div key={`${el.from_label}${i}`}>
            <div className="small-regular">{el.from_label}</div>
            <div className="flex-row">
              <div className="body-bold history-label small-bold">
                Sent to:{' '}
              </div>
              <div className="small-regular">
                {el.send_to.map((mail, index) => (
                  <div key={`${mail}${index}`}>{mail}</div>
                ))}
              </div>
            </div>
            <div>
              <span className="small-bold">
                {moment(el.created_at).format('LLL')}
              </span>
            </div>
            <hr />
          </div>
        ))}
      </div>
    </FormModal>
  );
};

export default EmailHistoryModal;
