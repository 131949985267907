// https://openweathermap.org/weather-conditions - API CODES REFERENCE

export const weatherConditionOptions = [
  { value: 'clear', label: 'Clear' },
  { value: 'cloud', label: 'Clouds' },
  { value: 'drizzle', label: 'Drizzle' },
  { value: 'rain', label: 'Rain' },
  { value: 'thunderstorm', label: 'Thunderstorm' },
  { value: 'snow', label: 'Snow' },
  { value: 'other', label: 'Other' },
];

export const weatherPrecipitationOptions = [
  { value: 'drizzle', label: 'Drizzle' },
  { value: 'rain', label: 'Rain' },
  { value: 'thunderstorm', label: 'Thunderstorm' },
  { value: 'snow', label: 'Snow' },
  { value: 'mist', label: 'Mist' },
  { value: 'smoke', label: 'Smoke' },
  { value: 'haze', label: 'Haze' },
  { value: 'dust', label: 'Dust' },
  { value: 'fog', label: 'Fog' },
  { value: 'sand', label: 'Sand' },
  { value: 'ash', label: 'Ash' },
  { value: 'squall', label: 'Squall' },
  { value: 'tornado', label: 'Tornado' },
  { value: 'none', label: '--' },
];

export const weatherWindOptions = [
  { value: 'north', label: 'North', short: 'N', deg: 0 },
  { value: 'northeast', label: 'Northeast', short: 'NE', deg: 45 },
  { value: 'east', label: 'East', short: 'E', deg: 90 },
  { value: 'southeast', label: 'Southeast', short: 'SE', deg: 135 },
  { value: 'south', label: 'South', short: 'S', deg: 180 },
  { value: 'southwest', label: 'Southwest', short: 'SW', deg: 225 },
  { value: 'west', label: 'West', short: 'W', deg: 270 },
  { value: 'northwest', label: 'Northwest', short: 'NW', deg: 315 },
];

export const degreesToWindDirectionOption = (deg) => {
  const normDeg = deg % 360;
  const countDirections = weatherWindOptions.length;
  const directionDegrees = 360 / countDirections;
  const arrayPosition =
    Math.round((normDeg / directionDegrees) % countDirections) %
    countDirections;
  return weatherWindOptions[arrayPosition];
};

export const weatherCodeToCondition = (code) => {
  const codeId = code.id;

  if (codeId === 800) return weatherConditionOptions[0]; // clear
  if (codeId > 800) return weatherConditionOptions[1]; // clouds
  if (codeId >= 300 && codeId < 400) return weatherConditionOptions[2]; // drizzle
  if (codeId >= 500 && codeId < 600) return weatherConditionOptions[3]; // rain
  if (codeId >= 200 && codeId < 300) return weatherConditionOptions[4]; // thunderstorm
  if (codeId >= 600 && codeId < 700) return weatherConditionOptions[5]; // snow

  return weatherConditionOptions[6]; // other
};
export const weatherCodeToPrecipitation = (code) => {
  const codeId = code.id;

  if (codeId >= 300 && codeId < 400) return weatherPrecipitationOptions[0]; // drizzle
  if (codeId >= 500 && codeId < 600) return weatherPrecipitationOptions[1]; // rain
  if (codeId >= 200 && codeId < 300) return weatherPrecipitationOptions[2]; // thunderstorm
  if (codeId >= 600 && codeId < 700) return weatherPrecipitationOptions[3]; // snow
  if (codeId === 701) return weatherPrecipitationOptions[4]; // mist
  if (codeId === 711) return weatherPrecipitationOptions[5]; // smoke
  if (codeId === 721) return weatherPrecipitationOptions[6]; // haze
  if (codeId === 731 || codeId === 761) return weatherPrecipitationOptions[7]; // dust
  if (codeId === 741) return weatherPrecipitationOptions[8]; // fog
  if (codeId === 751) return weatherPrecipitationOptions[9]; // sand
  if (codeId === 762) return weatherPrecipitationOptions[10]; // ash
  if (codeId === 771) return weatherPrecipitationOptions[11]; // squall
  if (codeId === 781) return weatherPrecipitationOptions[12]; // tornado

  return weatherPrecipitationOptions[13]; // other
};

export const weatherCodeToRemarks = (code) => {
  const remark = code.description || '';
  return remark.charAt(0).toUpperCase() + remark.slice(1);
};
