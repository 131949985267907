import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import './DetailsInfoItem.css';
import SimpleTruncate from '../text/SimpleTruncate';

class DetailsInfoItem extends Component {
  defaultLines = 5;

  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.object,
    lines: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    ).isRequired,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    truncate: PropTypes.bool, // Show lines with a "Show more" if more than 5 lines. WARNING: lines should be plain text
  };

  static defaultProps = {
    truncate: false,
  };

  render() {
    return (
      <div className={`details-info-item ${this.props.className || ''}`}>
        <div className="small-bold">
          {this.props.title}
          {this.props.icon !== undefined && (
            <span>
              <FontAwesomeIcon icon={this.props.icon} />
            </span>
          )}
        </div>
        <div className="small-regular">
          {!this.props.truncate ? (
            this.props.lines.map((element, index) => {
              return <div key={index}>{element}</div>;
            })
          ) : (
            <SimpleTruncate
              shownLines={this.defaultLines}
              text={this.props.lines.reduce((accumulator, currentValue) => {
                return accumulator + currentValue + '\n';
              }, '')}
            />
          )}
        </div>
        <div className="clearfix" />
      </div>
    );
  }
}

export default DetailsInfoItem;
