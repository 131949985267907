import {
  SET_PROJECT_AREAS,
  SET_PROJECT_SITES,
  SET_SHOW_ADD_AREA_MODAL,
  SET_SHOW_ADD_SITE_MODAL,
} from './action-types';
import { showErrorMessage } from 'actions/messages';
import { projectSiteAreaApi } from '../../../../../../services/api/project-site-area-api';

const setProjectSites = (projectId, sites) => {
  return {
    type: SET_PROJECT_SITES,
    payload: { projectId, sites },
  };
};

const setProjectAreas = (projectId, areas) => {
  return {
    type: SET_PROJECT_AREAS,
    payload: { projectId, areas },
  };
};

export const setShowAddProjectSiteModal = (show, edit = false) => {
  return {
    type: SET_SHOW_ADD_SITE_MODAL,
    payload: { show, edit },
  };
};

export const setShowAddProjectAreaModal = (show, edit = false) => {
  return {
    type: SET_SHOW_ADD_AREA_MODAL,
    payload: { show, edit },
  };
};

export const loadProjectSitesAreas = (projectId, loadAreas = true) => {
  return (dispatch) => {
    return projectSiteAreaApi.getProjectSites(projectId).then((response) => {
      if (response.ok) {
        dispatch(setProjectSites(projectId, response.sites));
        if (loadAreas) dispatch(loadProjectAreas(projectId));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
};

const loadProjectAreas = (projectId) => {
  return (dispatch) => {
    return projectSiteAreaApi.getProjectAreas(projectId).then((response) => {
      if (response.ok) {
        dispatch(setProjectAreas(projectId, response.areas));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
};

export const submitProjectSite = (projectId, site) => {
  return (dispatch) => {
    let method = 'add'; // method is one of {add, edit, delete}
    if (site.siteId) method = site.delete ? 'delete' : 'edit';
    const apiMethod = `${method}ProjectSite`;
    return projectSiteAreaApi[apiMethod](projectId, site).then((response) => {
      if (response.ok) {
        dispatch(loadProjectSitesAreas(projectId));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
  };
};

export const submitProjectArea = (projectId, area) => {
  return (dispatch) => {
    let method = 'add'; // method is one of {add, edit, delete}
    if (area.areaId) method = area.delete ? 'delete' : 'edit';
    const apiMethod = `${method}ProjectArea`;
    return projectSiteAreaApi[apiMethod](projectId, area).then((response) => {
      if (response.ok) {
        dispatch(loadProjectSitesAreas(projectId));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
  };
};
