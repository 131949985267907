import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';
import { ProjectStatusSorted } from 'domain/project-status-enum';
import Range from 'services/utils/range-utils';

class ProjectsApi extends BaseApi {
  getProjects(filters) {
    let query = '';
    Object.keys(filters)
      .filter((filter) => filters[filter])
      .forEach((filter, index) => {
        if (index > 0) {
          query += '&';
        }
        query += `${filter}=${filters[filter]}`;
      });

    return axios
      .get(`${this.baseUrl}/projects?${query}`)
      .then((response) => {
        const projects = {
          content: response.data,
          total: numeral(response.headers['total']).value(),
          totalMyProjects: numeral(
            response.headers['total-my_projects']
          ).value(),
          pageSize: numeral(response.headers['per-page']).value(),
        };
        ProjectStatusSorted.forEach((status) => {
          const headerKey = `total-${status.status}`;
          projects[status.getTotalKey()] = numeral(
            response.headers[headerKey]
          ).value();
          if (status.isCategory()) {
            projects[status.getTotalKey(true)] = numeral(
              response.headers[headerKey + '-category']
            ).value();
          }
        });
        return {
          ok: true,
          projects: projects,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getProjectsCount(filters) {
    let query = '';
    Object.keys(filters).forEach((filter, index) => {
      if (index > 0) {
        query += '&';
      }
      query += `${filter}=${filters[filter]}`;
    });

    return axios
      .get(`${this.baseUrl}/projects/count?${query}`)
      .then((response) => {
        return {
          ok: true,
          count: numeral(response.headers['total']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getProject(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}`)
      .then((response) => {
        return {
          ok: true,
          project: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  dailyReportsReminder(id) {
    return axios
      .put(`${this.baseUrl}/projects/${id}/daily_reports_reminder`)
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createProject(values) {
    const data = this.projectParams(values);

    if (values.creatorCompanyType && values.creatorCompanyId) {
      data.append(
        'project[creator_company_contributor_type]',
        values.creatorCompanyType
      );
      data.append(
        'project[creator_company_csi_code]',
        values.creatorCompanyCsiCode
      );
      data.append('project[creator_company_id]', values.creatorCompanyId);
    }

    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .post(`${this.baseUrl}/projects`, data, headers)
      .then((response) => {
        return {
          ok: true,
          project: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateProject(id, values) {
    const data = this.projectParams(values);

    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    return axios
      .put(`${this.baseUrl}/projects/${id}`, data, headers)
      .then((response) => {
        return {
          ok: true,
          project: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  setParam(data, key, value) {
    if (value !== undefined) {
      data.append(key, value);
    }
  }

  projectParams(values) {
    const estimatedCostRange = Range.decode(values.estimatedCost);
    const primaryUsersCountRange = Range.decode(values.primaryUsersCount);

    let data = new FormData();
    this.setParam(data, 'project[number]', values.number);
    this.setParam(data, 'project[name]', values.name);
    this.setParam(data, 'project[description]', values.description);
    this.setParam(data, 'project[sq_footage]', values.sqFootage);
    this.setParam(data, 'project[bid_due_date]', values.bidDueDate);
    this.setParam(data, 'project[est_start_date]', values.estStartDate);
    this.setParam(
      data,
      'project[est_completion_date]',
      values.estCompletionDate
    );
    if (values.clientId)
      this.setParam(data, 'project[client_id]', values.clientId);
    this.setParam(
      data,
      'project[project_location_id]',
      values.projectLocationId
    );
    this.setParam(data, 'project[status]', values.status);
    this.setParam(
      data,
      'project[status_before_archive]',
      values.statusBeforeArchive
    );
    this.setParam(data, 'project[termination_date]', values.terminationDate);
    this.setParam(
      data,
      'project[termination_reason]',
      values.terminationReason
    );
    this.setParam(
      data,
      'project[termination_category]',
      values.terminationCategory
    );
    this.setParam(
      data,
      'project[advanced_document_view]',
      values.advancedDocumentView
    );
    this.setParam(data, 'project[public]', values.isPublic);
    this.setParam(
      data,
      'project[estimated_cost_from]',
      estimatedCostRange.from
    );
    this.setParam(data, 'project[estimated_cost_to]', estimatedCostRange.to);
    this.setParam(
      data,
      'project[primary_users_count_from]',
      primaryUsersCountRange.from
    );
    this.setParam(
      data,
      'project[primary_users_count_to]',
      primaryUsersCountRange.to
    );
    this.setParam(data, 'project[industry]', values.industry);
    this.setParam(data, 'project[industry_type]', values.industryType);

    const logo = values.logo;
    const imageLogo = logo && logo.image;
    if (imageLogo && imageLogo.name && imageLogo.preview) {
      data.append('project[logo_attributes][image]', imageLogo);
    } else if (logo && logo.id && logo.url === '') {
      data.append('project[logo_attributes][id]', logo.id);
      data.append('project[logo_attributes][_destroy]', true);
    }

    if (values.conditions) {
      let conditionsIndex = 0;
      values.conditions
        .filter(
          (condition) =>
            !values.originalConditions.find(
              (original) => original.condition === condition[0]
            )
        )
        .forEach((condition) => {
          data.append(
            `project[project_conditions_attributes][${conditionsIndex}][condition]`,
            condition[0]
          );
          conditionsIndex++;
        });
      values.originalConditions
        .filter(
          (original) =>
            !values.conditions.find(
              (condition) => original.condition === condition[0]
            )
        )
        .forEach((original) => {
          data.append(
            `project[project_conditions_attributes][${conditionsIndex}][id]`,
            original.id
          );
          data.append(
            `project[project_conditions_attributes][${conditionsIndex}][_destroy]`,
            true
          );
          conditionsIndex++;
        });
    }

    return data;
  }

  createCompanyDirectory(projectId, values) {
    let data = new FormData();
    data.append('company[name]', values.name);
    data.append('company[contact_attributes][first_name]', values.firstName);
    data.append('company[contact_attributes][last_name]', values.lastName);
    data.append('company[contact_attributes][email]', values.email);
    data.append(
      'company[contact_attributes][phone_number]',
      values.phoneNumber
    );
    if (values.phoneExt)
      data.append('company[contact_attributes][phone_ext]', values.phoneExt);
    if (values.officePhoneNumber)
      data.append(
        'company[contact_attributes][office_phone_number]',
        values.officePhoneNumber
      );
    if (values.officeFaxNumber)
      data.append(
        'company[contact_attributes][office_fax_number]',
        values.officeFaxNumber
      );
    data.append('company[contact_attributes][title]', values.title);

    if (values.image && values.image.name && values.image.preview) {
      data.append('company[logo_attributes][image]', values.image);
    }

    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };

    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/create_company`,
        data,
        headers
      )
      .then((response) => ({ ok: true, company: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  inviteExistent(projectId, companyId) {
    let data = new FormData();
    data.append('company[id]', companyId);

    return axios
      .post(
        `${this.baseUrl}/projects/${projectId}/invite_existent_company`,
        data
      )
      .then((response) => ({ ok: true, company: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }
}

export const projectsApi = new ProjectsApi();
