import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import FilterHeader from 'components/shared/table/FilterHeader';
import AddProjectAreaModal from 'components/admin/projects/details/project-site-area/AddProjectAreaModal';
import vectorSelectedSvg from 'components/shared/table/vector-selected.svg';
import vectorSelectedDarkSvg from 'components/shared/table/vector-selected-dark.svg';
import {
  loadProjectSitesAreas,
  setShowAddProjectAreaModal,
} from './store/action';
import { getProjectSites } from 'components/admin/projects/details/project-site-area/store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getDarkMode } from 'selectors/theme';
import './ProjectSiteAreaSelector.css';

const ProjectAreasSelector = (props) => {
  const {
    dispatch,
    handleSelect,
    selected,
    selectedSite,
    darkMode,
    projectSites,
    currentProject,
  } = props;

  useEffect(() => {
    if (
      currentProject.id &&
      (!projectSites || projectSites.projectId !== currentProject.id)
    ) {
      dispatch(loadProjectSitesAreas(currentProject.id));
    }
  }, []);

  const options = useMemo(() => {
    const result = [
      { id: 'add', label: <span className="secondary">Add Area</span> },
      { id: 'all', label: 'All Areas' },
    ];

    if (
      projectSites &&
      projectSites.areas &&
      currentProject &&
      projectSites.projectId === currentProject.id
    ) {
      projectSites.areas
        .filter(
          (a) =>
            !selectedSite ||
            selectedSite === 'all' ||
            selectedSite === a.project_site_id
        )
        .forEach((a) => {
          result.push({
            id: a.id,
            label: `${a.name}${
              !selectedSite || selectedSite === 'all'
                ? ` - ${a.project_site_name}`
                : ''
            }`,
          });
        });
    }

    if (result.length > 2)
      result.push({
        id: 'edit',
        label: <span className="secondary">Edit Areas</span>,
      });

    return result;
  }, [projectSites, selectedSite]);

  const handleChange = (area) => {
    if (area === 'add') dispatch(setShowAddProjectAreaModal(true));
    else if (area === 'edit') dispatch(setShowAddProjectAreaModal(true, true));
    else handleSelect(!area ? null : area);
  };

  const getLabel = () => {
    if (!selected) return 'Select Area';
    else if (selected === 'all') return '';
    else return 'Area';
  };

  return (
    <>
      <FilterHeader
        className="project-site-area-selector"
        label={getLabel()}
        icon={darkMode ? vectorSelectedDarkSvg : vectorSelectedSvg}
        options={options}
        selected={selected}
        disabled={!selectedSite || selectedSite === 'all'}
        onFilter={handleChange}
        upperCaseLabel
        clearable
      />
      <AddProjectAreaModal selectedSite={selectedSite} />
    </>
  );
};

export default connect((state) => ({
  darkMode: getDarkMode(state),
  projectSites: getProjectSites(state),
  currentProject: getCurrentProject(state),
}))(ProjectAreasSelector);
