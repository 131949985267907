import Meeting from 'domain/new-meeting';
import User from 'domain/user';

class MeetingPermissions {
  // Parents Permissions
  canSeeInfo = (user, meeting) =>
    User.isAdmin(user) ||
    Meeting.isAttendee(user, meeting) ||
    Meeting.isCreatorCompany(user, meeting);

  canDownloadiCal = (user, meeting) =>
    User.isAdmin(user) ||
    Meeting.isAttendee(user, meeting) ||
    Meeting.isCreatorCompany(user, meeting);

  canAddMinutes = (user, meeting) =>
    User.isAdmin(user) || Meeting.isCreatorCompany(user, meeting);

  canAddAgenda = (user, meeting) =>
    User.isAdmin(user) ||
    Meeting.isAttendee(user, meeting) ||
    Meeting.isCreatorCompany(user, meeting);

  canEdit = (user, meeting) =>
    User.isAdmin(user) ||
    Meeting.isAttendee(user, meeting) ||
    Meeting.isCreatorCompany(user, meeting);

  canArchive = (user, meeting) =>
    User.isAdmin(user) ||
    Meeting.isAttendee(user, meeting) ||
    Meeting.isCreatorCompany(user, meeting);

  canSeeMenu = (user, meeting) =>
    User.isAdmin(user) || Meeting.isCreatorCompany(user, meeting);

  // Instances permissions

  canOpenMinutes = (user, meeting) =>
    User.isAdmin(user) ||
    Meeting.isAttendee(user, meeting) ||
    Meeting.isCreatorCompany(user, meeting);

  canOpenAgenda = (user, meeting) =>
    (User.isAdmin(user) ||
      Meeting.isAttendee(user, meeting) ||
      Meeting.isCreatorCompany(user, meeting)) &&
    (meeting.is_agenda || Meeting.isAgendaBackup(meeting));

  canEditInstance = (user, meeting, isArchived) =>
    (User.isAdmin(user) ||
      Meeting.isCreatorCompany(user, meeting) ||
      !!meeting.isQuickStart) &&
    !isArchived;

  canSeeMoreSideFormButtons = (user, meeting) =>
    User.isAdmin(user) ||
    Meeting.isCreatorCompany(user, meeting) ||
    !meeting.isQuickStart;
  canEditAttendance = (user, meeting, isArchived) =>
    (User.isAdmin(user) ||
      Meeting.isCreatorCompany(user, meeting) ||
      meeting.isQuickStart) &&
    !isArchived;
  // Action Items permissions
  canAddUpdatesAndResolve = (user, instance, isArchived) =>
    (User.isAdmin(user) || Meeting.isCreatorCompany(user, instance)) &&
    !isArchived;

  canResolveActionItem = (instance, item, isArchived) =>
    instance.id !== item.createdOnInstanceId &&
    !item.isAgenda &&
    !item.isResolved &&
    !isArchived;
  canDeleteActionItem = (instance, item, isArchived) =>
    instance.id === item.cretedonInstanceId && !isArchived;
  canViewHistory = (instance, item) => instance.id !== item.createdOnInstanceId;
  // Corrections
  canReviewCorrections = (user, meeting) => {
    return User.isAdmin(user) || Meeting.isCreatorCompany(user, meeting);
  };
}

export default new MeetingPermissions();
