import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import FormModal from 'components/shared/modal/FormModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import DocumentUpload from 'components/shared/document-upload/DocumentUpload';
import DocumentLink from 'components/shared/document/DocumentLink';
import { getDarkMode } from 'selectors/theme';
import removeSvg from 'images/deleteCross.svg';
import removeDarkSvg from 'images/deleteCrossDarkMode.svg';
import useDirectUpload from 'services/hooks/direct-upload';
import DocumentUploadPreview from 'components/shared/document-upload/DocumentUploadPreview';
import { useCounter, useMap, useQueue } from 'react-use';
import './UploadFilesModal.css';

const MAX_UPLOADING = 5;
// STATUSES
const STATUS = {
  INITIAL: 'INITIAL',
  WAITING: 'WAITING',
  UPLOADING: 'UPLOADING',
  FINISHED: 'FINISHED',
  ERROR: 'ERROR',
};

const UploadFilesModal = (props) => {
  const { showModal, onClose, onSubmit, darkMode, prefix } = props;
  const [uploading, setUploading] = useState(false);

  const { directUpload } = useDirectUpload();

  const [documents, setDocuments] = useMap({});
  const [count, setCount] = useCounter(0);
  const queue = useQueue();

  const removeDocument = (name) => setDocuments.remove(name);
  const addDocuments = (files) => {
    files.forEach((file) => {
      if (typeof setDocuments.get(file.name) === 'undefined') {
        const name = file.name;
        const status = STATUS.INITIAL;
        setDocuments.set(name, { file, name, status, percent: 0 });
      }
    });
  };

  const uploadProgress = (data) => (pEvent) => {
    if (pEvent.isTrusted) {
      data.percent = (pEvent.loaded / pEvent.total) * 100;
      setDocuments.set(data.name, data);
    }
  };
  const setError = (data) => {
    data.status = STATUS.ERROR;
    setCount.dec();
  };
  const setFinish = (data, url) => {
    data.status = STATUS.FINISHED;
    data.url = url;
    setCount.dec();
  };

  const uploadFile = async (data) => {
    // upload S3
    const S3params = { prefix, advancedDocument: true };
    const response = await directUpload(
      data.file,
      S3params,
      uploadProgress(data),
      false
    );

    if (!response.ok) setError(data);
    else setFinish(data, response.url);
  };

  const processUploadQueue = () => {
    if (queue.size > 0 && count < MAX_UPLOADING) {
      const first = queue.first;
      first.status = STATUS.UPLOADING;
      queue.remove();
      setCount.inc();
      uploadFile(first);
    } else if (queue.size === 0 && count === 0 && uploading) {
      setUploading(false);

      if (
        Object.values(documents).reduce(
          (acc, curr) => acc && curr.status === STATUS.FINISHED,
          true
        )
      )
        onSubmit(Object.values(documents));
    }
  };
  useEffect(processUploadQueue, [queue.size, count]);

  const handleSubmit = () => {
    setUploading(true);
    Object.values(documents).forEach((data) => {
      if (data.status === STATUS.INITIAL || data.status === STATUS.ERROR) {
        data.status = STATUS.WAITING;
        queue.add(data);
      }
    });
  };

  const renderProgress = () => {
    const totalCount = Object.keys(documents).length;
    const finishedCount = Object.values(documents).filter(
      (p) => p.status === STATUS.FINISHED || p.status === STATUS.ERROR
    ).length;

    let status = 'Complete';
    let percentage = 100;

    if (finishedCount < totalCount) {
      status = `Uploading ${finishedCount + 1} of ${totalCount}`;

      const totalPercent = totalCount * 100;
      const totalPercentUploaded = Object.values(documents).reduce(
        (acc, curr) => acc + curr.percent,
        0
      );
      percentage = (totalPercentUploaded * 100) / totalPercent;
      percentage = Math.round(percentage);
    }

    if (totalCount === 0) return null;

    return <DocumentUploadPreview status={status} percentage={percentage} />;
  };

  if (!showModal) return null;

  return (
    <FormModal
      className="update-files-modal"
      show={showModal}
      onClose={onClose}
      subTitle="Upload Files"
    >
      <DocumentUpload multiple={true} onDrop={addDocuments} />
      {Object.keys(documents).length > 0 && (
        <div className="files-viewer">
          <ul>
            {Object.values(documents).map((data, index) => {
              return (
                <li key={index}>
                  <DocumentLink
                    file={{ name: data.name }}
                    url={data.url}
                    showStatus={data.status !== 'INITIAL'}
                    status={data.status}
                    readOnly={true}
                  />
                  {!uploading && (
                    <div>
                      <img
                        alt="Remove"
                        src={darkMode ? removeDarkSvg : removeSvg}
                        onClick={() => removeDocument(data.name)}
                      />
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {uploading && renderProgress()}
      <div className="buttons-container">
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          disabled={documents.length === 0 || uploading}
          onClick={handleSubmit}
          label="Upload"
        />
      </div>
    </FormModal>
  );
};

export default connect((state) => ({ darkMode: getDarkMode(state) }))(
  UploadFilesModal
);
