import {
  DEPOSITS_PAGE_CHANGE,
  DEPOSITS_DEPOSIT_PDF_VIEWER_CLOSE,
  DEPOSITS_DEPOSIT_PRINT_SUCCESS,
  DEPOSITS_DEPOSIT_EMAIL_MODAL_SHOW_SET,
} from 'actions/admin/deposits/action-types';

const initialState = {
  currentPage: 1,
  pdf: null,
  showPdfViewer: false,
  showEmailModal: false,
};

export default function depositsUiReducer(state = initialState, action) {
  switch (action.type) {
    case DEPOSITS_PAGE_CHANGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case DEPOSITS_DEPOSIT_PDF_VIEWER_CLOSE:
      return {
        ...state,
        showPdfViewer: false,
      };

    case DEPOSITS_DEPOSIT_PRINT_SUCCESS:
      return {
        ...state,
        pdf: action.payload,
        showPdfViewer: true,
      };

    case DEPOSITS_DEPOSIT_EMAIL_MODAL_SHOW_SET:
      return {
        ...state,
        showEmailModal: action.payload,
      };

    default:
      return state;
  }
}
