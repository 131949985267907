import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import InputMaxLength from 'components/shared/input/InputMaxLength';
import DatePicker from 'components/shared/date-picker/DatePicker';
import CollapsibleSection from 'components/shared/collapsible-section/CollapsibleSection';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import FormControlBlock from 'components/shared/form/FormControlBlock';
import ReadOnlyInput from 'components/shared/input/ReadOnlyInput';
import { calculateDateDuration } from 'services/utils/date-util';
import {
  setProjectReportAttr,
  getProjectReportResolveInfoByAttr,
  projectReportResolvedTableFilter,
} from 'components/admin/projects/details/project-daily-report-log/store/actions';
import { loadProjectSchedules } from 'components/admin/projects/details/project-schedule/store/actions';
import {
  getProjectReportValue,
  getFieldProjectResolvedInfo,
} from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import { getProjectSchedules } from 'components/admin/projects/details/project-schedule/store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import { getDarkMode } from 'selectors/theme';
import ProjectReportScheduleModal from './ProjectReportScheduleModal';
import './ReportsForm.css';
import { INITIAL_SCHEDULE_FILTERS } from '../../project-schedule/store/reducers.ui';

class ScheduleForm extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }

  componentDidMount() {
    this.getProjectReportResolveInfo();
    this.getProjectSchedules();
  }

  getProjectReportResolveInfo = () => {
    const { projectId, dispatch, projectReportId } = this.props;
    dispatch(
      getProjectReportResolveInfoByAttr(projectId, projectReportId, 'remarks')
    );
  };

  getProjectSchedules = () => {
    const { dispatch, projectId } = this.props;
    dispatch(loadProjectSchedules(projectId, INITIAL_SCHEDULE_FILTERS));
  };

  handleModalShow = (showModal) => {
    this.setState({ showModal });
    if (showModal) this.getProjectReportResolveInfo();
  };

  handleSetAttr = (attr) => (value) => {
    const { dispatch } = this.props;
    dispatch(setProjectReportAttr(attr, value));
  };

  handleModalSearch = (search) => {
    const { dispatch } = this.props;
    dispatch(projectReportResolvedTableFilter('remarks', search, 'remarks'));
  };

  handleSelectSchedule = (id) => (selected) => {
    const { report } = this.props;
    const schedules = report.schedule_categories.filter((s) => s !== id);
    if (selected) schedules.push(id);
    this.handleSetAttr('schedule_categories')(schedules);
  };

  render() {
    const { report, resolvedInfo, missingField, schedules } = this.props;
    const { showModal } = this.state;
    return (
      <div>
        <div className="row-block">
          <div className="report-form-block">
            <label className="report-form-block-label">
              Projected Completion
            </label>
            <div className="item-block shedule-datetime-picker">
              <FormControlBlock
                hideBottomLabel
                label={report.original_completion_date && 'Original Completion'}
                control={
                  <DatePicker
                    placeholder="Original Completion"
                    onChange={this.handleSetAttr('original_completion_date')}
                    value={report.original_completion_date || ''}
                    valid={!(missingField && !report.original_completion_date)}
                    clearable
                  />
                }
              />
              <FormControlBlock
                hideBottomLabel
                label={report.previous_completion_date && 'Previous Completion'}
                control={
                  <DatePicker
                    placeholder="Previous Completion"
                    onChange={this.handleSetAttr('previous_completion_date')}
                    value={report.previous_completion_date || ''}
                    valid={!(missingField && !report.previous_completion_date)}
                    clearable
                  />
                }
              />
              <FormControlBlock
                hideBottomLabel
                label={report.completion_date && 'Current Completion *'}
                control={
                  <DatePicker
                    placeholder="Current Completion *"
                    onChange={this.handleSetAttr('completion_date')}
                    value={report.completion_date || ''}
                    valid={!(missingField && !report.completion_date)}
                    clearable
                  />
                }
              />
              <FormControlBlock
                hideBottomLabel
                label={'Variance'}
                className="variance"
                control={
                  <ReadOnlyInput
                    value={calculateDateDuration(
                      report.original_completion_date,
                      report.completion_date
                    )}
                  />
                }
              />
            </div>
          </div>
        </div>
        {schedules.length > 0 && (
          <div className="report-form-block schedule_categories">
            <label className="report-form-block-label">Project Schedules</label>
            <p className="report-form-block-note">
              Include the following schedules in this section of the report:
            </p>
            <div className="item-block">
              {schedules.map((s) => (
                <LabeledCheckBox
                  key={'schedule_' + s.id}
                  label={s.name}
                  position="left"
                  onChange={this.handleSelectSchedule(s.id)}
                  checked={report.schedule_categories.includes(s.id)}
                />
              ))}
            </div>
          </div>
        )}

        <div className="report-form-block">
          <CollapsibleSection
            title="Schedule Remarks"
            initialShow
            right={
              resolvedInfo.remarks &&
              resolvedInfo.remarks.length > 0 && (
                <Button
                  key="remarks-view-resolved"
                  type={BUTTON_TYPES.LINK}
                  color={BUTTON_COLORS.GREEN}
                  label="View Resolved"
                  onClick={this.handleModalShow.bind(null, true)}
                />
              )
            }
          >
            <InputMaxLength
              label="Remarks"
              maxLength={500}
              value={report.schedule_remarks || ''}
              onClear={() => this.handleSetAttr('schedule_remarks')('')}
              onChange={this.handleSetAttr('schedule_remarks')}
            />
          </CollapsibleSection>
        </div>
        <ProjectReportScheduleModal
          show={showModal}
          data={resolvedInfo.remarks}
          onClose={this.handleModalShow.bind(null, false)}
          onSearch={this.handleModalSearch}
        />
      </div>
    );
  }
}

export default connect((state) => ({
  darkMode: getDarkMode(state),
  report: getProjectReportValue(state),
  resolvedInfo: getFieldProjectResolvedInfo(state),
  currentUser: getCurrentUser(state),
  schedules: getProjectSchedules(state),
}))(ScheduleForm);
