import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Chat from 'components/shared/chat/Chat';
import { getSubmittalNotes } from 'components/admin/projects/details/project-submittal-log/store/selectors';
import {
  loadSubmittalNotes,
  createSubmittalNote,
} from 'components/admin/projects/details/project-submittal-log/store/actions';

class SubmittalComments extends Component {
  static propTypes = {
    submittal: PropTypes.shape({}).isRequired,
  };

  loadNotes = () => {
    const { submittal, dispatch } = this.props;
    return dispatch(loadSubmittalNotes(submittal.id));
  };

  handleSubmit = (newNote) => {
    const { submittal, dispatch } = this.props;
    return dispatch(createSubmittalNote(submittal.id, newNote));
  };

  render() {
    const { submittalNotes } = this.props;
    return (
      <Chat
        name="Submittal"
        notes={submittalNotes}
        loadChat={this.loadNotes}
        handleFormChange={this.handleFormChange}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

export default connect((state) => {
  return {
    submittalNotes: getSubmittalNotes(state),
  };
})(SubmittalComments);
