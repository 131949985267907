import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import TextareaAutosize from 'react-autosize-textarea';
import classnames from 'classnames';
import './CharsCounterTextarea.css';

class CharsCounterTextarea extends Component {
  static propTypes = {
    limit: PropTypes.number,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    lockValue: PropTypes.bool,
    valid: PropTypes.bool,
    rows: PropTypes.number,
    maxRows: PropTypes.number,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    limit: 500,
    rows: 1,
    maxRows: 4,
    readOnly: false,
    lockValue: true,
    valid: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value || '',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.value) {
      this.setState({ value: nextProps.value || '' });
    }
  }

  handleOnChange(e) {
    this.setState({
      value: e.target.value,
    });
    if (this.props.onChange !== undefined) {
      this.props.onChange(e.target.value);
    }
  }

  render() {
    const {
      limit,
      valid,
      readOnly,
      lockValue,
      rows,
      maxRows,
      className,
      placeholder,
      onBlur,
      darkMode,
    } = this.props;

    return (
      <div
        className={`chars-counter-textarea ${darkMode ? 'dark-mode' : ''} ${
          readOnly ? 'readOnly' : ''
        } ${!valid ? 'invalid' : ''} ${className || ''}`}
      >
        <TextareaAutosize
          rows={rows}
          maxRows={maxRows}
          className={classnames({
            'text-area': true,
            'input-area': true,
            'form-control': true,
            'dark-mode': darkMode,
          })}
          disabled={readOnly}
          placeholder={placeholder}
          onChange={(e) => this.handleOnChange(e)}
          onBlur={onBlur}
          value={this.state.value}
          maxLength={lockValue ? limit : null}
        />
        {!readOnly && (
          <div
            className={classnames({
              'chars-counter': true,
              exceeded: this.state.value.length >= limit,
            })}
          >
            {this.state.value.length}/{limit}
          </div>
        )}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(CharsCounterTextarea);
