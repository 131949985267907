import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import _ from 'lodash';
import './Projects.css';
import SearchBar from 'components/shared/navigation/SearchBar';
import SimpleTable from 'components/shared/table/SimpleTable';
import Date from 'components/shared/text/Date';
import { getCurrentUser } from 'selectors/authentication';
import ProjectPermissions from 'permissions/project-permissions';
import { getNotifications } from 'selectors/notifications';
import { unreadNotifications } from 'domain/notification';
import { getDarkMode } from 'selectors/theme';
import {
  getCurrentPage,
  getProjects,
  getCurrentNav,
} from 'components/admin/projects/store/selectors';
import {
  loadProjectsRequest,
  addProject,
  goToProjectDetails,
  changeNav,
} from 'components/admin/projects/store/actions';
import SimpleText from 'components/shared/text/SimpleText';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import { simpleScrollToTop } from 'services/utils/scroller-util';
import BaseStatus from 'components/shared/text/BaseStatus';
import ProjectStatus from 'components/shared/project-status/ProjectStatus';
import {
  ProjectStatusEnum,
  ProjectStatusSorted,
} from 'domain/project-status-enum';
import SortHeader from 'components/shared/table/SortHeader';
import DropdownMenu, {
  DROPDOWN_TYPES,
  CounterItem,
} from 'components/shared/menu/DropdownMenu';
import DetailsHeader from 'components/shared/details/DetailsHeader';
import Button, {
  BUTTON_TYPES,
  BUTTON_ICONS,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import MessageModal from 'components/shared/modal/MessageModal';
import {
  updateProject,
  setProjectFormValues,
} from 'components/admin/projects/creation/store/actions';
import { getProjectForm } from 'components/admin/projects/creation/store/selectors';
import ArchiveProjectConfirmationModal from './ArchiveProjectConfirmationModal';
import { getShowArchiveConfirmationModal } from './store/selectors';
import { setShowArchiveConfirmation } from './store/actions';

const sortingHeaders = {
  'PROJECT NO': 'number',
  'PROJECT NAME': 'name',
  SUBSCRIBER: 'creator_company.name',
  OWNER: 'client.name',
  ARCHITECT: 'architect.name',
  'PROJECT ADDRESS': 'project_location.location.street_address_1',
  CITY: 'project_location.location.city',
  STAGE: 'status',
  DUE: 'bid_due_date',
};

class Projects extends Component {
  static propTypes = {
    perPage: PropTypes.number,
    embedded: PropTypes.bool,
    showControls: PropTypes.bool,
    showNav: PropTypes.bool,
    showConfirmationModal: PropTypes.bool,
  };

  static defaultProps = {
    perPage: 25,
    embedded: false,
    showControls: true,
    showNav: true,
    showConfirmationModal: false,
  };

  constructor(props) {
    super(props);

    this.canCreateProject = ProjectPermissions.canCreate(props.currentUser);
    this.showMyProjects = ProjectPermissions.canViewMyProjects(
      props.currentUser
    );

    this.state = {
      nameToSearch: '',
      currentSorting: null,
      first: true,
      showCantCreateMessage: false,
    };
  }

  defaultTabSelection() {
    const { location } = this.props;
    const params = queryString.parse(location && location.search);

    if (params.status) return params.status;
    return this.showMyProjects ? 'my_projects' : 'in_progress';
  }

  componentDidMount() {
    this.handleNavSelect(this.defaultTabSelection());
  }

  componentDidUpdate(newProps) {
    this.props.showControls && simpleScrollToTop();
    if (this.props.location !== newProps.location) {
      this.handleNavSelect(this.defaultTabSelection());
    }
  }

  handleGenerateLink = (id) => `projects/${id}`;

  handleNavSelect = (id) => {
    this.loadProjects(id, 1);
    this.props.dispatch(changeNav(id));
  };

  handlePageChange = (newPage) => {
    this.loadProjects(this.props.currentNav, newPage);
  };

  loadProjects(tabSelection, newPage) {
    const { perPage, dispatch } = this.props;
    const { nameToSearch } = this.state;
    const filters = {
      page: newPage,
      per_page: perPage,
      by_name: nameToSearch,
    };
    switch (tabSelection || 'all') {
      case 'all':
        dispatch(loadProjectsRequest(filters));
        break;

      case 'my_projects':
        dispatch(loadProjectsRequest({ ...filters, my_projects: true }));
        break;
      case 'archived':
        dispatch(loadProjectsRequest({ ...filters, archived_projects: true }));
        break;

      default: {
        const status = ProjectStatusEnum.create(tabSelection);
        const filter = status.status + (status.isCategory() ? '_category' : '');
        dispatch(
          loadProjectsRequest({
            ...filters,
            [filter]: true,
          })
        );
        break;
      }
    }
  }

  handleAddProject = () => {
    if (!this.canCreateProject)
      return this.setState({ showCantCreateMessage: true });

    this.props.dispatch(addProject());
  };

  handleRowSelection = (id) => {
    const project =
      this.props.projects &&
      this.props.projects.content &&
      this.props.projects.content.filter((p) => p.id === id);

    if (project && project.length === 1 && project[0].status === 'archived')
      return;
    this.props.dispatch(goToProjectDetails(id));
  };

  handleSearchTextChange = (value) => {
    const { dispatch } = this.props;
    this.setState({ nameToSearch: value }, () => this.loadProjects('all', 1));
    dispatch(changeNav('all'));
  };

  setSorting = (el, selected, desc) => {
    if (selected) {
      this.setState({
        currentSorting: {
          name: el,
          sortBy: sortingHeaders[el],
          isReversed: desc,
        },
      });
    } else {
      this.setState({ currentSorting: null });
    }
  };

  sortByCallback = (o) => {
    const {
      currentSorting: { sortBy },
    } = this.state;
    const splitted = sortBy.split('.');
    if (splitted.length === 3) {
      return (
        (o[splitted[0]] &&
          o[splitted[0]][splitted[1]] &&
          o[splitted[0]][splitted[1]][splitted[2]]) ||
        ''
      );
    }
    if (splitted.length === 2) {
      return (o[splitted[0]] && o[splitted[0]][splitted[1]]) || '';
    }
    return o[sortBy] || '';
  };

  sortProjects = (projects) => {
    const { currentSorting } = this.state;

    if (currentSorting && !currentSorting.isReversed) {
      return _.sortBy(projects, this.sortByCallback);
    } else if (currentSorting && currentSorting.isReversed) {
      return _.sortBy(projects, this.sortByCallback).reverse();
    }
    return projects;
  };

  handleShowArchiveConfirmationModal = async (project) => {
    const updatedProject = {
      ...project,
      status: 'archived',
      status_before_archive: project.status,
    };
    await this.props.dispatch(setProjectFormValues(updatedProject));
    this.props.dispatch(setShowArchiveConfirmation(true));
  };

  handleArchiveProject = () => {
    const projectId = this.props.projectForm.id;
    this.props
      .dispatch(updateProject(projectId, this.props.projectForm))
      .then((response) => {
        if (response.ok) {
          console.log('ok');
          this.loadProjects(this.props.currentNav, this.props.currentPage);
          this.props.dispatch(setShowArchiveConfirmation(false));
        }
      });
  };

  handleRestoreProject = async (e, project) => {
    e.preventDefault();
    e.stopPropagation();
    const projectId = project.id;
    const updatedProject = {
      ...project,
      status: project.status_before_archive,
    };

    await this.props.dispatch(setProjectFormValues(updatedProject));
    this.props
      .dispatch(updateProject(projectId, this.props.projectForm))
      .then((response) => {
        if (response.ok) {
          this.loadProjects(this.props.currentNav, this.props.currentPage);
        }
      });
  };

  renderHeaders = () => {
    const { currentNav } = this.props;
    const { currentSorting } = this.state;
    const showDueDate = currentNav === 2;
    const headers = [
      'PROJECT NO',
      'PROJECT NAME',
      'OWNER',
      'ARCHITECT',
      'SUBSCRIBER',
      'PROJECT ADDRESS',
      'CITY',
      'STAGE',
      'ACTIONS',
      showDueDate && 'DUE',
    ].filter((header) => !!header);
    return headers.map((el) => {
      const selected = currentSorting && currentSorting.name === el;
      if (el !== 'ACTIONS')
        return (
          <SortHeader
            label={el}
            selected={selected}
            desc={selected && currentSorting.isReversed}
            onChange={(selected, desc) => {
              this.setSorting(el, selected, desc);
            }}
          />
        );
      return el;
    });
  };

  hasProjects() {
    const { projects } = this.props;

    return (
      !!projects &&
      !!ProjectStatusSorted.find(
        (status) => projects[status.getTotalKey(true)] > 0
      )
    );
  }

  render() {
    const { nameToSearch, showCantCreateMessage } = this.state;
    const {
      embedded,
      showControls,
      showNav,
      darkMode,
      loading,
      showConfirmationModal,
    } = this.props;
    const newUserMessage = !nameToSearch && !loading && !this.hasProjects();

    return (
      <div
        className={`projects ${darkMode ? 'dark-mode' : ''} ${
          embedded ? 'embedded-projects' : 'main-projects'
        }`}
      >
        {!embedded && (
          <DetailsHeader
            leftHeader={{
              value: 'Projects',
            }}
          />
        )}

        {this.props.projects !== null && (
          <div
            className={`content-container ${
              embedded
                ? 'content-embedded-container'
                : 'content-table-container'
            }`}
          >
            {showNav && (
              <SearchBar
                addButton={
                  showControls && (
                    <Button
                      type={BUTTON_TYPES.LINK}
                      label="Add Project "
                      icon={BUTTON_ICONS.PLUS}
                      onClick={this.handleAddProject}
                    />
                  )
                }
                searchText={nameToSearch}
                onSearchTextChange={this.handleSearchTextChange}
                onSearch={showControls ? this.handleSearchTextChange : null}
              >
                <DropdownMenu
                  type={DROPDOWN_TYPES.SECTION_SELECTOR}
                  className="status-selector"
                  options={[
                    {
                      value: 'all',
                      label: 'All',
                    },
                    {
                      value: 'my_projects',
                      label: CounterItem(
                        'My Projects',
                        this.props.projects.totalMyProjects
                      ),
                      show: this.showMyProjects,
                    },
                  ].concat(
                    ProjectStatusSorted.map((status) => {
                      const counter = this.props.projects[
                        status.getTotalKey(true)
                      ];
                      return {
                        className: status.isSubStatus()
                          ? 'substatus-option'
                          : null,
                        value: status.status,
                        label: CounterItem(status.label, counter),
                        show: counter > 0,
                      };
                    })
                  )}
                  value={this.props.currentNav}
                  onChange={(option) => this.handleNavSelect(option.value)}
                />
              </SearchBar>
            )}

            {!newUserMessage && (
              <React.Fragment>
                <SimpleTable
                  headers={this.renderHeaders()}
                  rows={
                    this.props.currentNav === 'all'
                      ? this.mapProjects(
                          this.props.projects.content.filter(
                            (proj) => proj.status !== 'archived'
                          )
                        )
                      : this.mapProjects(this.props.projects.content)
                  }
                  emptyMessage="No Projects"
                  onRowSelected={this.handleRowSelection}
                  onGenerateLink={this.handleGenerateLink}
                  actionCells={1}
                />
                <div className="pagination-container">
                  <SimplePagination
                    currentPage={this.props.currentPage}
                    pageSize={this.props.projects.pageSize}
                    total={this.props.projects.total}
                    onPageChange={this.handlePageChange}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        )}

        {this.props.projects !== null && newUserMessage && (
          <div className="zero-state">
            <div className="zero-state-content">
              <div className="zero-state-title">Welcome to 360Plus</div>
              {
                <div>
                  <div className="zero-state-subtitle">
                    Click below to create your first project
                  </div>
                  <Button
                    color={BUTTON_ICONS.GREEN}
                    label="Create Project"
                    onClick={this.handleAddProject}
                  />
                </div>
              }
            </div>
          </div>
        )}

        {showCantCreateMessage && (
          <MessageModal
            show
            message={
              <div>Please contact 360+ to change your subscription plan.</div>
            }
            onHide={() => this.setState({ showCantCreateMessage: false })}
          />
        )}
        {showConfirmationModal && (
          <ArchiveProjectConfirmationModal
            show={showConfirmationModal}
            message="Are you sure you want to archive this project?"
            handleArchiveProject={this.handleArchiveProject}
          />
        )}
      </div>
    );
  }

  renderActions = (project) => {
    const actions = [];
    const canArchiveOrRestore = ProjectPermissions.canEdit(
      this.props.currentUser,
      project
    );
    if (project.status === 'archived' && canArchiveOrRestore)
      actions.push({
        label: 'Restore',
        action: (e) => this.handleRestoreProject(e, project),
      });
    if (project.status !== 'archived' && canArchiveOrRestore)
      actions.push({
        label: 'Archive',
        action: () => this.handleShowArchiveConfirmationModal(project),
      });
    if (!canArchiveOrRestore) actions.push({ label: ' ', action: () => {} });

    const buttons = actions.map((a, index) => (
      <div className="action-buttons-container">
        <Button
          key={`action_${project.id}_${index}`}
          type={BUTTON_TYPES.LINK}
          color={BUTTON_COLORS.GREEN}
          label={a.label}
          onClick={a.action}
        />
      </div>
    ));

    return buttons;
  };
  mapProjects = (projects) => {
    const unreadById = unreadNotifications(
      this.props.notifications,
      'Project',
      'project_id'
    );
    const showDueDate = this.props.currentNav === 2;
    return this.sortProjects(projects).map((project) => {
      const unread = unreadById && !!unreadById[project.id];
      const data = [
        <div>
          {unread && (
            <BaseStatus
              labels={[
                {
                  id: 'unread',
                  label: '',
                },
              ]}
              status="unread"
              darkMode={this.props.darkMode}
            />
          )}
          {project.number}
        </div>,
        project.name,
        project.client ? project.client.name : '-',
        project.architect ? project.architect.name : '-',
        project.creator_company ? project.creator_company.name : '-',
        <SimpleText
          lines={[project.project_location.location.street_address_1]}
        />,
        project.project_location.location.city,
        showDueDate && (
          <Date value={project.bid_due_date} format={'MM/DD/YYYY'} />
        ),
        <ProjectStatus
          status={ProjectStatusEnum.create(project.status)}
          type="list"
        />,
        this.renderActions(project),
      ];
      return {
        id: project.id,
        data: data.filter((attr) => attr),
      };
    });
  };
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentPage: getCurrentPage(state),
    projects: getProjects(state),
    notifications: getNotifications(state),
    currentNav: getCurrentNav(state),
    darkMode: getDarkMode(state),
    loading: state.loader && state.loader.loading,
    projectForm: getProjectForm(state),
    showConfirmationModal: getShowArchiveConfirmationModal(state),
  };
})(Projects);
