import React from 'react';
import { connect } from 'react-redux';
import FormControlBlock, {
  CONTROL_SIZE,
} from 'components/shared/form/FormControlBlock';
import Selector from 'components/shared/selector/Selector';
import MultipleDropdown from 'components/shared/dropdown/MultipleDropdown';
import Input from 'components/shared/input/Input';
import LogoUpload, {
  LOGO_UPLOAD_ICON,
} from 'components/shared/logo-upload/LogoUpload';
import { getTradeCodes, getUnions, getForm } from './store/selectors';
import { updateForm } from './store/actions';
import { companyTypeOptions } from 'domain/company-type-enum';

function CompanyInformation(props) {
  const {
    available_trade_codes,
    available_unions,
    form: {
      company: {
        name,
        street_address_1,
        street_address_2,
        city,
        state,
        zip,
        phone_number,
        fax_number,
        logo,
        type,
        license_number,
        trade_codes,
        unions,
        other_union,
      },
    },
    dispatch,
  } = props;

  const hasOtherUnion = unions.some((union) => union[0] === 'other');

  const handleFormChange = (field, value) => {
    dispatch(
      updateForm('company', {
        ...props.form.company,
        [field]: value,
      })
    );
  };

  const handleSelectLogo = (file) => {
    handleFormChange(
      'logo',
      file
        ? {
            url: URL.createObjectURL(file),
            file: file,
            image: file,
          }
        : null
    );
  };

  return (
    <React.Fragment>
      <div className="sidebar-form-body-subtitle">Company Address</div>

      <div className="form-block">
        <FormControlBlock
          label={!!name && 'Company'}
          size={CONTROL_SIZE.BIG}
          control={
            <Input
              placeholder="Company"
              value={name}
              onChange={(value) => handleFormChange('name', value)}
            />
          }
        />
      </div>

      <div className="form-block">
        <FormControlBlock
          label={!!street_address_1 && 'Address'}
          size={CONTROL_SIZE.BIG}
          control={
            <Input
              placeholder="Address"
              value={street_address_1}
              onChange={(value) => handleFormChange('street_address_1', value)}
            />
          }
        />
        <FormControlBlock
          label={!!street_address_2 && 'Unit No'}
          size={CONTROL_SIZE.SMALL}
          control={
            <Input
              placeholder="Unit No"
              value={street_address_2}
              onChange={(value) => handleFormChange('street_address_2', value)}
            />
          }
        />
      </div>

      <div className="form-block">
        <FormControlBlock
          label={!!city && 'City'}
          size={CONTROL_SIZE.SMALL}
          control={
            <Input
              placeholder="City"
              value={city}
              onChange={(value) => handleFormChange('city', value)}
            />
          }
        />
        <FormControlBlock
          label={!!state && 'State'}
          size={CONTROL_SIZE.SMALL}
          control={
            <Input
              placeholder="State"
              value={state}
              onChange={(value) => handleFormChange('state', value)}
            />
          }
        />
        <FormControlBlock
          label={!!zip && 'Postal Code'}
          size={CONTROL_SIZE.SMALL}
          control={
            <Input
              placeholder="Postal Code"
              value={zip}
              onChange={(value) => handleFormChange('zip', value)}
            />
          }
        />
      </div>

      <div className="form-block">
        <FormControlBlock
          label={!!phone_number && 'Office Phone Number'}
          size={CONTROL_SIZE.SMALL}
          control={
            <Input
              placeholder="Office Phone Number"
              value={phone_number}
              onChange={(value) => handleFormChange('phone_number', value)}
            />
          }
        />
        <FormControlBlock
          label={!!fax_number && 'Office Fax Number'}
          size={CONTROL_SIZE.SMALL}
          control={
            <Input
              placeholder="Office Fax Number"
              value={fax_number}
              onChange={(value) => handleFormChange('fax_number', value)}
            />
          }
        />
        <LogoUpload
          className="form-control-logo-uploader"
          icon={LOGO_UPLOAD_ICON.PLUS}
          extensions={['.jpg', '.jpeg', '.gif', '.png', '.heic']}
          logos={logo ? [logo] : []}
          useDropzone={true}
          dropzoneLabel="Add Company Logo"
          deleteConfirmationTitle="Delete Company Logo"
          deleteConfirmationMessage="Are you sure you want to delete the logo?"
          onRemove={() => handleSelectLogo(null)}
          onUpload={(files) => handleSelectLogo(files[0])}
        />
      </div>

      <div className="sidebar-form-body-subtitle">Company Details</div>

      <div className="form-block">
        <FormControlBlock
          label={!!type && 'Company Type'}
          size={CONTROL_SIZE.SMALL}
          control={
            <Selector
              placeholder="Company Type"
              value={type}
              onChange={(option) => handleFormChange('type', option.value)}
              options={companyTypeOptions}
            />
          }
        />
        <FormControlBlock
          label={!!license_number && 'License No'}
          size={CONTROL_SIZE.SMALL}
          control={
            <Input
              placeholder="License No"
              value={license_number}
              onChange={(value) => handleFormChange('license_number', value)}
            />
          }
        />
      </div>

      <div className="form-block">
        <FormControlBlock
          label={!!trade_codes && 'Cost Codes'}
          size={CONTROL_SIZE.BIG}
          control={
            <MultipleDropdown
              placeholder="Cost Codes"
              currentValues={trade_codes}
              onChange={(values) => handleFormChange('trade_codes', values)}
              search={false}
              options={available_trade_codes}
            />
          }
        />
      </div>

      <div className="form-block">
        <FormControlBlock
          label={!!unions && 'Union Affiliations'}
          size={CONTROL_SIZE.BIG}
          control={
            <MultipleDropdown
              placeholder="Union Affiliations"
              currentValues={unions}
              onChange={(values) => handleFormChange('unions', values)}
              search={false}
              options={available_unions.map((union) => ({
                id: union.value === 'other' ? union.value : union.id,
                label: union.label,
              }))}
            />
          }
        />
        {hasOtherUnion && (
          <FormControlBlock
            label={!!other_union && 'Other Union'}
            size={CONTROL_SIZE.SMALL}
            control={
              <Input
                placeholder="Other Union"
                value={other_union}
                onChange={(value) => handleFormChange('other_union', value)}
              />
            }
          />
        )}
      </div>
    </React.Fragment>
  );
}

export default connect((state) => {
  return {
    available_trade_codes: getTradeCodes(state),
    available_unions: getUnions(state),
    form: getForm(state),
  };
})(CompanyInformation);
