import React, { useState } from 'react';
import { connect } from 'react-redux';

import FormModal from 'components/shared/modal/FormModal';
import FormControlBlock from 'components/shared/form/FormControlBlock';

import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import DatePicker from 'components/shared/date-picker/DatePicker';
import TimePicker from 'components/shared/time-picker/TimePicker';
import {
  setEditNextMeetingModalShow,
  setFormMeetingInstance,
  setFormProjectMeetings,
  updateMeetingsParent,
  sendNextMeetingChangeEmail,
} from './store/actions';
import {
  getMeetingsParentsTitles,
  getMeetingsParentsTypes,
  getProjectNewMeetingsParents,
  getEditNextMeetingModal,
  getMeetingsInstanceForm,
  getProjectNewMeetingsForm,
} from './store/selectors';
import './AddNextMeetingModal.css';
import SingleRadioButton from './SingleRadioButton';
import RadioButton from '../../../../shared/radio-button/RadioButton';

const EditNextMeeting = (props) => {
  const {
    dispatch,
    project,
    showEditNextMeetingModal,
    form,
    instanceForm,
  } = props;
  const [sendNotification, setSendNotification] = useState('no');
  const [skipNextMeeting, setSkipNextMeeting] = useState('no');
  const handleClose = () => {
    dispatch(setEditNextMeetingModalShow(false));
  };
  const handleSetSkipMeeting = () => {
    if (skipNextMeeting === 'yes') {
      setSkipNextMeeting('no');
    } else {
      setSkipNextMeeting('yes');
    }
  };

  const handleSetSendNotification = (option) => {
    setSendNotification(option);
  };
  const handleSendEmail = (emailsData) => {
    dispatch(
      sendNextMeetingChangeEmail(
        project.id,
        form.id,
        emailsData.recipients,
        emailsData.subject,
        emailsData.message
      )
    );
  };

  const buildEmailSubject = () => {
    return `${project.name} - Meeting  ${form.title}`;
  };

  const buildEmailMessage = () => {
    let location = '';
    if (project.project_location && project.project_location.location) {
      const projectLocation = project.project_location.location;
      location = ` at ${projectLocation.street_address_1}, ${projectLocation.city} ${projectLocation.state}`;
    }

    return `Meeting ${form.title} for Project ${
      project.name
    }${location} has changed it's next meeting date to ${form.nextMeeting} ${
      form.nextMeetingTime ? 'at ' + form.nextMeetingTime : ''
    }. If you have any questions, please reach out via email.`;
  };
  const handleFormChange = (fields) => {
    dispatch(setFormProjectMeetings({ ...form, ...fields }));
  };
  const handleSave = () => {
    let payload = {};
    if (skipNextMeeting === 'yes') {
      payload = {
        ...form,
        nextMeeting: form.secondNextMeeting,
        sendNextMeetingEditNotification: sendNotification,
      };
      dispatch(
        setFormMeetingInstance({
          ...instanceForm,
          nextMeeting: form.secondNextMeeting,
        })
      );
    } else {
      payload = {
        ...form,
        nextMeeting: form.nextMeeting,
        nextMeetingTime: form.nextMeetingTime,
      };
    }
    if (form.id) dispatch(updateMeetingsParent(project.id, form.id, payload));
    if (instanceForm.nextMeeting) {
      dispatch(
        setFormMeetingInstance({
          ...instanceForm,
          nextMeeting: form.nextMeeting,
          nextMeetingTime: form.nextMeetingTime,
        })
      );
    }
    if (sendNotification === 'yes') {
      const subject = buildEmailSubject();
      const message = buildEmailMessage();
      const attendeeMails = form.attendees.map((attendee) => attendee.email);
      const recipients = attendeeMails;
      const emailsData = { subject, message, recipients };
      handleSendEmail(emailsData);
    }
    dispatch(setEditNextMeetingModalShow(false));
  };
  return (
    <>
      <FormModal
        className={`next-meeting-modal`}
        show={showEditNextMeetingModal}
        onClose={handleClose}
        subTitle="Edit Next Meeting"
      >
        <div className="field-and-label">
          <div className="description-text">Skip Next Meeting</div>
          <FormControlBlock
            hideBottomLabel
            control={
              <SingleRadioButton
                option={{ value: 'yes', label: 'Yes' }}
                selectedValue={skipNextMeeting === 'yes'}
                onChange={() => handleSetSkipMeeting()}
              />
            }
          />
        </div>
        <div className="description-text">or</div>
        <div className="field-and-label">
          <div className="description-text">Change Next Meeting Date</div>
          <FormControlBlock
            readonly={false}
            control={
              <DatePicker
                format="MM/DD/YYYY"
                placeholder={'MM/DD/YYYY'}
                value={form.nextMeeting}
                clearable
                onChange={(nextMeeting) => handleFormChange({ nextMeeting })}
              />
            }
          />
        </div>
        <div className="description-text">and or</div>
        <div className="field-and-label">
          <div className="description-text">Add Next Meeting Time</div>
          <div className="time-container">
            <FormControlBlock
              hideBottomLabel
              control={
                <TimePicker
                  className={'clock'}
                  placeholder={'Start Time'}
                  onChange={(value) =>
                    handleFormChange({ nextMeetingTime: value })
                  }
                  value={form.nextMeetingTime}
                />
              }
            />
          </div>
        </div>
        <div className="field-and-label">
          <div className="description-text">Issue Notification?</div>
          <div className="radio-buttons-container">
            <FormControlBlock
              hideBottomLabel
              control={
                <RadioButton
                  options={[
                    { value: 'yes', label: 'Yes' },
                    { value: 'no', label: 'No' },
                  ]}
                  selectedValue={sendNotification}
                  onChange={(option) => handleSetSendNotification(option)}
                  value={sendNotification}
                />
              }
            />
          </div>
        </div>
        <div className="help-text">
          Meeting recurrence can be set from the Schedule tab in the Edit View
        </div>
        <div className="button-actions">
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.WHITE}
            label="Cancel"
            onClick={handleClose}
          />
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            label={'Save'}
            onClick={handleSave}
          />
        </div>
      </FormModal>
    </>
  );
};

export default connect((state) => ({
  showEditNextMeetingModal: getEditNextMeetingModal(state),
  project: getCurrentProject(state),
  meetingsParentsTitles: getMeetingsParentsTitles(state),
  meetingsParentsTypes: getMeetingsParentsTypes(state),
  meetingsParents: getProjectNewMeetingsParents(state),
  instanceForm: getMeetingsInstanceForm(state),
  form: getProjectNewMeetingsForm(state),
}))(EditNextMeeting);
