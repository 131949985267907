import {
  itemDataToForm,
  multipleItemsToForm,
  separateAlbumsFromPhotos,
} from 'services/utils/project-photos-util';
import { downloadFile } from 'services/utils/download-util';
import { projectPhotosApi } from 'services/api/project-photos-api';
import { showErrorMessage } from 'actions/messages';
import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';
import {
  SET_PROJECT_PHOTOS_FILTERS,
  SET_PROJECT_PHOTOS_FORM,
  SET_PROJECT_PHOTOS_CURRENT_ALBUM,
  SET_PROJECT_PHOTOS_SHOW_ALBUM_FORM,
  SET_PROJECT_PHOTOS_SHOW_DETAIL,
  SET_PROJECT_PHOTOS_SHOW_FILTERS,
  SET_PROJECT_PHOTOS_SHOW_FORM,
  SET_PROJECT_PHOTOS_SHOW_UPLOAD,
  SET_PROJECT_PHOTOS_FAVORITE,
  SET_PROJECT_PHOTOS_CURRENT_ALBUMS,
  SET_PROJECT_PHOTOS_CURRENT_PHOTOS,
  ADD_PROJECT_PHOTOS_CURRENT_ALBUMS,
  ADD_PROJECT_PHOTOS_CURRENT_PHOTOS,
  SET_PROJECT_PHOTOS_DETAIL,
  SET_PROJECT_PHOTOS_SELECTED_ALBUMS,
  SET_PROJECT_PHOTOS_SELECTED_PHOTOS,
  REMOVE_PROJECT_PHOTOS_CURRENT_ALBUMS,
  REMOVE_PROJECT_PHOTOS_CURRENT_PHOTOS,
  SET_PROJECT_PHOTOS_SHOW_TAGS,
  SET_PROJECT_PHOTOS_SHOW_TRADES,
  SET_PROJECT_PHOTOS_SHOW_LOCATIONS,
  SET_PROJECT_PHOTOS_SHOW_USERS,
  SET_PROJECT_PHOTOS_SHOW_MOVE_TO,
  ADD_PROJECT_PHOTOS_COMMENT,
  SET_PROJECT_PHOTOS_EDIT_IMAGE,
  UPDATE_PROJECT_PHOTOS_DETAIL,
  SET_PROJECT_PHOTOS_SELECTED_IDS,
  REMOVE_PROJECT_PHOTOS_DETAIL,
  SET_PROJECT_PHOTOS_SHOW_DELETE,
  SET_PROJECT_PHOTOS_START_UPLOAD,
  SET_PROJECT_PHOTOS_FIXED_ALBUMS,
  ORDER_PROJECT_PHOTOS_CURRENT_ALBUMS,
  ORDER_PROJECT_PHOTOS_CURRENT_PHOTOS,
  UPDATE_PROJECT_PHOTOS_ALBUM,
  OPEN_PROJECT_PHOTOS_CURRENT_DETAIL,
} from './action-types';

// data state
// CURRENT ALBUM
export const setProjectPhotosCurrentAlbum = (album) => {
  return { type: SET_PROJECT_PHOTOS_CURRENT_ALBUM, payload: album };
};
export const setProjectPhotosFixedAlbums = (albums) => {
  return { type: SET_PROJECT_PHOTOS_FIXED_ALBUMS, payload: albums };
};

// ALBUMS
export const setProjectPhotosCurrentAlbums = (albums) => {
  return {
    type: SET_PROJECT_PHOTOS_CURRENT_ALBUMS,
    payload: albums,
  };
};
// append
export const addProjectPhotosCurrentAlbums = (albums) => {
  return {
    type: ADD_PROJECT_PHOTOS_CURRENT_ALBUMS,
    payload: albums,
  };
};
export const updateProjectPhotosAlbum = (album) => {
  return {
    type: UPDATE_PROJECT_PHOTOS_ALBUM,
    payload: album,
  };
};
export const orderProjectPhotosCurrentAlbums = (albumsIds) => {
  return {
    type: ORDER_PROJECT_PHOTOS_CURRENT_ALBUMS,
    payload: albumsIds,
  };
};
export const removeProjectPhotosCurrentAlbums = (albumsIds) => {
  return {
    type: REMOVE_PROJECT_PHOTOS_CURRENT_ALBUMS,
    payload: albumsIds,
  };
};

// PHOTOS
export const setProjectPhotosCurrentPhotos = (photos) => {
  return {
    type: SET_PROJECT_PHOTOS_CURRENT_PHOTOS,
    payload: photos,
  };
};
// append
export const addProjectPhotosCurrentPhotos = (photos) => {
  return {
    type: ADD_PROJECT_PHOTOS_CURRENT_PHOTOS,
    payload: photos,
  };
};
export const orderProjectPhotosCurrentPhotos = (photoIds) => {
  return {
    type: ORDER_PROJECT_PHOTOS_CURRENT_PHOTOS,
    payload: photoIds,
  };
};
export const removeProjectPhotosCurrentPhotos = (photoIds) => {
  return {
    type: REMOVE_PROJECT_PHOTOS_CURRENT_PHOTOS,
    payload: photoIds,
  };
};

// FILTERS
export const setProjectPhotosFilters = (filters) => {
  return { type: SET_PROJECT_PHOTOS_FILTERS, payload: filters };
};

// FORM
export const setProjectPhotosForm = (form) => {
  return { type: SET_PROJECT_PHOTOS_FORM, payload: form };
};
export const setProjectPhotosFavorites = (ids, value, type) => {
  return { type: SET_PROJECT_PHOTOS_FAVORITE, payload: { ids, value, type } };
};
export const addProjectPhotosFormComment = (comment) => {
  return { type: ADD_PROJECT_PHOTOS_COMMENT, payload: comment };
};
export const setProjectPhotosStartUpload = (start) => {
  return { type: SET_PROJECT_PHOTOS_START_UPLOAD, payload: start };
};

// ui state
export const setProjectPhotosShowFilters = (show) => {
  return { type: SET_PROJECT_PHOTOS_SHOW_FILTERS, payload: show };
};
export const setProjectPhotosShowForm = (show) => {
  return { type: SET_PROJECT_PHOTOS_SHOW_FORM, payload: show };
};
export const setProjectPhotosShowAlbumForm = (show) => {
  return { type: SET_PROJECT_PHOTOS_SHOW_ALBUM_FORM, payload: show };
};
export const setProjectPhotosShowDetail = (show) => {
  return { type: SET_PROJECT_PHOTOS_SHOW_DETAIL, payload: show };
};
export const setProjectPhotosShowUpload = (show) => {
  return { type: SET_PROJECT_PHOTOS_SHOW_UPLOAD, payload: show };
};
export const setProjectPhotosShowTags = (show) => {
  return { type: SET_PROJECT_PHOTOS_SHOW_TAGS, payload: show };
};
export const setProjectPhotosShowTrades = (show) => {
  return { type: SET_PROJECT_PHOTOS_SHOW_TRADES, payload: show };
};
export const setProjectPhotosShowLocations = (show) => {
  return { type: SET_PROJECT_PHOTOS_SHOW_LOCATIONS, payload: show };
};
export const setProjectPhotosShowUsers = (show) => {
  return { type: SET_PROJECT_PHOTOS_SHOW_USERS, payload: show };
};
export const setProjectPhotosShowMoveTo = (show) => {
  return { type: SET_PROJECT_PHOTOS_SHOW_MOVE_TO, payload: show };
};
export const setProjectPhotosShowDelete = (show) => {
  return { type: SET_PROJECT_PHOTOS_SHOW_DELETE, payload: show };
};
export const setProjectPhotosDetail = (detail) => {
  return { type: SET_PROJECT_PHOTOS_DETAIL, payload: detail };
};
export const removeProjectPhotosDetail = (id) => {
  return { type: REMOVE_PROJECT_PHOTOS_DETAIL, payload: id };
};
export const setProjectPhotosDetailCurrentImage = (photoId) => {
  return { type: OPEN_PROJECT_PHOTOS_CURRENT_DETAIL, payload: photoId };
};
export const setProjectPhotosSelectedAlbums = (
  selectedAlbums = [],
  selecting = false
) => {
  return {
    type: SET_PROJECT_PHOTOS_SELECTED_ALBUMS,
    payload: { selectedAlbums, selecting },
  };
};
export const setProjectPhotosSelectedPhotos = (
  selectedPhotos = [],
  selecting = false
) => {
  return {
    type: SET_PROJECT_PHOTOS_SELECTED_PHOTOS,
    payload: { selectedPhotos, selecting },
  };
};
export const setProjectPhotosEditImage = (edit) => {
  return { type: SET_PROJECT_PHOTOS_EDIT_IMAGE, payload: edit };
};
export const updateProjectPhotosDetail = (id, fields) => {
  return { type: UPDATE_PROJECT_PHOTOS_DETAIL, payload: { id, fields } };
};
export const setProjectPhotosSelectedIds = (ids) => {
  return { type: SET_PROJECT_PHOTOS_SELECTED_IDS, payload: ids };
};

// aux
const promiseResponseOk = (responses) => {
  return responses.reduce((acc, curr) => acc && curr.ok, true);
};
const separateRequestProjectPhotos = (
  projectId,
  itemIds,
  request,
  ...props
) => {
  return (dispatch) => {
    const { albumIds, photoIds } = separateAlbumsFromPhotos(itemIds);
    const promises = [];

    if (albumIds.length > 0) {
      promises.push(dispatch(request(projectId, albumIds, 'album', ...props)));
    }
    if (photoIds.length > 0) {
      promises.push(dispatch(request(projectId, photoIds, 'photo', ...props)));
    }

    return Promise.all(promises).then((values) => {
      if (promiseResponseOk(values)) return { ok: true, data: values };

      return { ok: false };
    });
  };
};
const changeStatusProjectPhotos = (projectId, itemIds, status) => {
  const request = changeStatusRequestProjectPhotos;
  return separateRequestProjectPhotos(projectId, itemIds, request, status);
};
const loadMultipleProjectPhotos = (projectId, ids, type) => {
  const filters = { [`${type}Ids`]: ids, all: true, detailData: true };

  return loadRequestProjectPhotos(projectId, filters, type);
};
export const loadProjectPhotoForm = (
  projectId,
  id,
  type,
  configuration = false
) => {
  return (dispatch) => {
    return dispatch(loadDetailRequestProjectPhotos(projectId, id, type)).then(
      (response) => {
        if (response.ok) {
          const form = itemDataToForm(response.data, type, configuration);
          dispatch(setProjectPhotosForm(form));
        }
        return response;
      }
    );
  };
};

// REQUESTS
export const loadDetailRequestProjectPhotos = (
  projectId,
  contentId,
  type = 'album'
) => {
  return (dispatch) => {
    return projectPhotosApi
      .getDetail(projectId, contentId, type)
      .then((response) => {
        if (!response.ok) dispatch(showErrorMessage(response.errors));

        return response;
      });
  };
};
export const loadRequestProjectPhotos = (
  projectId,
  filters,
  type,
  loadingDisabled = false
) => {
  return (dispatch) => {
    return projectPhotosApi
      .getAll(projectId, type, filters, loadingDisabled)
      .then((response) => {
        if (!response.ok) dispatch(showErrorMessage(response.errors));
        return response;
      });
  };
};
export const loadProjectPhotosMixedAlbums = (projectId) => {
  return (dispatch) => {
    return projectPhotosApi.getFixedAlbums(projectId).then((response) => {
      if (!response.ok) dispatch(showErrorMessage(response.errors));

      dispatch(setProjectPhotosFixedAlbums(response.data));
      return response;
    });
  };
};
export const toggleFavoriteRequestProjectPhotos = (
  projectId,
  ids,
  type = 'current',
  value = true
) => {
  // type is one of: current, album, photo
  return (dispatch) => {
    return projectPhotosApi
      .toggleFavorites(projectId, ids, value, type)
      .then((response) => {
        if (!response.ok) dispatch(showErrorMessage(response.errors));
        else dispatch(setProjectPhotosFavorites(ids, value, type));

        return response;
      });
  };
};
export const changeStatusRequestProjectPhotos = (
  projectId,
  ids,
  type,
  status
) => {
  return (dispatch) => {
    return projectPhotosApi
      .changeStatus(projectId, status, ids, type)
      .then((response) => {
        if (!response.ok) dispatch(showErrorMessage(response.errors));
        else {
          if (type === 'album') dispatch(removeProjectPhotosCurrentAlbums(ids));
          if (type === 'photo') dispatch(removeProjectPhotosCurrentPhotos(ids));
        }

        return response;
      });
  };
};
export const moveRequestProjectPhotosToAlbum = (
  projectId,
  ids,
  type,
  albumId
) => {
  // type is on of: album, photo
  return (dispatch) => {
    return projectPhotosApi
      .moveToAlbum(projectId, albumId, ids, type)
      .then((response) => {
        if (!response.ok) dispatch(showErrorMessage(response.errors));
        else {
          if (type === 'album') dispatch(removeProjectPhotosCurrentAlbums(ids));
          if (type === 'photo') dispatch(removeProjectPhotosCurrentPhotos(ids));
        }
        return response;
      });
  };
};
export const orderRequestProjectPhotos = (projectId, ids, type) => {
  // type is on of: album, photo
  return (dispatch) => {
    return projectPhotosApi.order(projectId, ids, type).then((response) => {
      if (!response.ok) dispatch(showErrorMessage(response.errors));
      return response;
    });
  };
};
export const updateRequestProjectPhotos = (projectId, ids, type, values) => {
  const fields = { ...values, [`${type}Ids`]: ids };
  return (dispatch) => {
    return projectPhotosApi.update(projectId, type, fields).then((response) => {
      if (!response.ok) dispatch(showErrorMessage(response.errors));

      return response;
    });
  };
};
export const eraseRequestProjectPhotos = (projectId, ids, type) => {
  return (dispatch) => {
    return projectPhotosApi.erase(projectId, ids, type).then((response) => {
      if (!response.ok) dispatch(showErrorMessage(response.errors));
      else {
        if (type === 'album') dispatch(removeProjectPhotosCurrentAlbums(ids));
        if (type === 'photo') dispatch(removeProjectPhotosCurrentPhotos(ids));
      }
      return response;
    });
  };
};

// ACTIONS
/* list */
export const loadProjectPhotosCount = (projectId, filters) => {
  return (dispatch) => {
    const promises = [
      dispatch(loadRequestProjectPhotos(projectId, filters, 'album', true)),
      dispatch(loadRequestProjectPhotos(projectId, filters, 'photo', true)),
    ];

    return Promise.all(promises).then((responses) => {
      if (!promiseResponseOk(responses)) return { ok: true, data: 0 };

      return { ok: true, data: responses[0].total + responses[1].total };
    });
  };
};
export const loadProjectPhotos = (projectId, filters) => {
  return (dispatch) => {
    const loadAlbumDetail = filters.albumId !== null;
    const promises = [
      dispatch(loadRequestProjectPhotos(projectId, filters, 'album')),
      dispatch(loadRequestProjectPhotos(projectId, filters, 'photo')),
    ];

    if (loadAlbumDetail) {
      promises.push(
        dispatch(loadDetailRequestProjectPhotos(projectId, filters.albumId))
      );
    }

    if (!loadAlbumDetail && !filters.status && !filters.favorites)
      promises.push(dispatch(loadProjectPhotosMixedAlbums(projectId)));

    Promise.all(promises)
      .then((responses) => {
        if (promiseResponseOk(responses)) {
          const [albums, photos, detail] = responses;

          const albumData = loadAlbumDetail ? detail.data : null;
          dispatch(setProjectPhotosCurrentAlbum(albumData));
          dispatch(setProjectPhotosCurrentAlbums(albums));
          dispatch(setProjectPhotosCurrentPhotos(photos));
        }
      })
      .finally(() => {
        dispatch(setProjectPhotosFilters({ doSearch: false }));
      });
  };
};
export const moveProjectPhotosToAlbum = (projectId, albumId, itemIds) => {
  const request = moveRequestProjectPhotosToAlbum;
  return separateRequestProjectPhotos(projectId, itemIds, request, albumId);
};
export const orderProjectPhotos = (projectId, ids, type) => {
  return (dispatch) => {
    if (type === 'album') dispatch(orderProjectPhotosCurrentAlbums(ids));
    if (type === 'photo') dispatch(orderProjectPhotosCurrentPhotos(ids));

    return dispatch(orderRequestProjectPhotos(projectId, ids, type));
  };
};
export const downloadProjectPhotos = (
  projectId,
  projectName,
  projectNumber,
  itemIds
) => {
  return (dispatch) => {
    const ids = separateAlbumsFromPhotos(itemIds);
    return projectPhotosApi.download(projectId, ids).then((response) => {
      if (!response.ok) {
        dispatch(showErrorMessage(response.errors));
      } else {
        downloadFile(
          response.data.url,
          `${projectNumber} - ${projectName} - Photos`
        );
      }
      return response;
    });
  };
};
export const sendProjectPhotosEmail = (
  projectId,
  itemIds,
  recipients,
  subject,
  message
) => {
  return (dispatch) => {
    const ids = separateAlbumsFromPhotos(itemIds);
    return projectPhotosApi
      .email(projectId, ids, recipients, subject, message)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
        } else {
          dispatch(setShowEmailModal(false));
        }

        return response;
      });
  };
};

/* change statuses */
export const archiveProjectPhotos = (projectId, itemIds) => {
  return changeStatusProjectPhotos(projectId, itemIds, 'archive');
};
export const recycleProjectPhoto = (projectId, itemIds) => {
  return changeStatusProjectPhotos(projectId, itemIds, 'recycle');
};
export const restoreProjectPhoto = (projectId, itemIds) => {
  return changeStatusProjectPhotos(projectId, itemIds, 'restore_recycled');
};
export const restoreArchivedProjectPhoto = (projectId, itemIds) => {
  return changeStatusProjectPhotos(projectId, itemIds, 'restore_archived');
};
export const toggleProjectPhotoFavorite = (
  projectId,
  itemIds,
  value = true
) => {
  const request = toggleFavoriteRequestProjectPhotos;
  return separateRequestProjectPhotos(projectId, itemIds, request, value);
};
export const eraseProjectPhoto = (projectId, itemIds) => {
  const request = eraseRequestProjectPhotos;
  return separateRequestProjectPhotos(projectId, itemIds, request);
};

/* form */
export const openProjectPhotosForm = (projectId, itemIds) => {
  return (dispatch) => {
    const request = loadMultipleProjectPhotos;
    dispatch(separateRequestProjectPhotos(projectId, itemIds, request)).then(
      (responses) => {
        if (!responses.ok) return;

        const items = [];
        responses.data.forEach((r) => {
          const type = typeof r.albums !== 'undefined' ? 'album' : 'photo';
          r[`${type}s`].forEach((item) => items.push({ ...item, type }));
        });

        if (items.length > 0) {
          const form = multipleItemsToForm(items);
          dispatch(setProjectPhotosForm(form));
          dispatch(setProjectPhotosShowForm(true));
        }
      }
    );
  };
};
export const updateProjectPhotosForm = (projectId, itemIds, fields) => {
  return (dispatch) => {
    const request = updateRequestProjectPhotos;
    dispatch(
      separateRequestProjectPhotos(projectId, itemIds, request, fields)
    ).then((responses) => {
      if (responses.ok) dispatch(setProjectPhotosForm(fields));
      return responses;
    });
  };
};
export const openProjectPhotosAlbumForm = (projectId, albumId) => {
  return (dispatch) => {
    dispatch(loadProjectPhotoForm(projectId, albumId, 'album', true)).then(
      (response) => {
        if (response.ok) dispatch(setProjectPhotosShowAlbumForm(true));
      }
    );
  };
};
export const createProjectPhotoAlbum = (projectId, form) => {
  return (dispatch) => {
    return projectPhotosApi
      .create(projectId, form, 'album')
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
        } else {
          const ids = [{ id: response.data.id, type: 'album' }];
          dispatch(setProjectPhotosForm({ ids }));
          dispatch(addProjectPhotosCurrentAlbums([response.data]));

          if (form.count_photos === 0) {
            const filters = { albumId: response.data.id, doSearch: true };
            dispatch(setProjectPhotosFilters(filters));
            dispatch(setProjectPhotosShowAlbumForm(false));
            dispatch(setProjectPhotosForm());
          } else {
            dispatch(setProjectPhotosStartUpload(true));
          }
        }

        return response;
      });
  };
};
export const createProjectPhoto = (projectId, albumId) => {
  return (dispatch) => {
    return projectPhotosApi
      .create(projectId, { albumId }, 'photo')
      .then((response) => {
        if (!response.ok) dispatch(showErrorMessage(response.errors));
        return response;
      });
  };
};
export const saveProjectAlbum = (projectId, form) => {
  return (dispatch) => {
    return projectPhotosApi
      .save(projectId, form.ids[0].id, 'album', form)
      .then((response) => {
        if (!response.ok) dispatch(showErrorMessage(response.errors));
        else {
          if (form.count_photos === 0) {
            const filters = { albumId: response.data.id, doSearch: true };
            dispatch(setProjectPhotosFilters(filters));
            dispatch(setProjectPhotosShowAlbumForm(false));
            dispatch(setProjectPhotosForm());
          } else {
            dispatch(setProjectPhotosStartUpload(true));
          }
        }
        return response;
      });
  };
};
export const saveProjectPhoto = (projectId, photoId, values) => {
  return (dispatch) => {
    return projectPhotosApi
      .save(projectId, photoId, 'photo', values)
      .then((response) => {
        if (!response.ok) dispatch(showErrorMessage(response.errors));
        return response;
      });
  };
};

/* detail */
export const loadProjectPhotosDetail = (projectId, filters) => {
  return (dispatch) => {
    return dispatch(loadRequestProjectPhotos(projectId, filters, 'photo')).then(
      (response) => {
        if (response.ok) dispatch(setProjectPhotosDetail(response.photos));

        return response;
      }
    );
  };
};
export const openProjectPhotosDetail = (projectId, id, type) => {
  return (dispatch) => {
    const isAlbum = type === 'album';

    const promises = [dispatch(loadProjectPhotoForm(projectId, id, type))];
    if (isAlbum)
      promises.push(dispatch(loadProjectPhotosDetail(projectId, id, 1)));

    Promise.all(promises).then((responses) => {
      if (promiseResponseOk(responses)) {
        if (!isAlbum) dispatch(setProjectPhotosDetailCurrentImage(id));
        dispatch(setProjectPhotosShowDetail(true));
      }
    });
  };
};
