import User from './user';

class OpenItem {
  isCreatedByUserCompany = (user, openItem) =>
    !!openItem &&
    !!openItem.created_by &&
    User.isSameCompany(user, openItem.created_by);

  isCreatedByGcOar = (directory, openItem) =>
    !!openItem &&
    !!openItem.created_by &&
    User.isGcOar(User.userContributor(directory, openItem.created_by));

  isCreatorUser = (user, openItem) =>
    !!openItem && openItem.created_by && openItem.created_by.id === user.id;

  isAssignedToUser = (user, openItem) =>
    !!openItem && openItem.assignee_id === user.id;
}

export default new OpenItem();
