import { push } from 'react-router-redux';
import { showErrorMessage } from 'actions/messages';
import { clientsApi } from 'services/api/clients-api';
import {
  CLIENT_TEAM_BUILDINGS_LOAD_SUCCESS,
  CLIENT_TEAM_CLIENT_FORM_INVALID,
  CLIENT_TEAM_CLIENT_FORM_VALID,
  CLIENT_TEAM_CLIENT_FORM_VALUE_UPDATE,
  CLIENT_TEAM_CLIENT_LOAD_SUCCESS,
  CLIENT_TEAM_EDIT_FORM_LOAD_FROM_CURRENT,
  CLIENT_TEAM_INVITE_USER_FORM_LOAD,
  CLIENT_TEAM_INVITE_USER_FORM_VALUE_UPDATE,
  CLIENT_TEAM_INVITE_USER_ID_SET,
  CLIENT_TEAM_INVITE_USER_MODAL_SHOW,
  CLIENT_TEAM_ROLES_LOAD_SUCCESS,
  CLIENT_TEAM_USERS_ADD,
  CLIENT_TEAM_USERS_EDIT,
  CLIENT_TEAM_USERS_LOAD_SUCCESS,
  CLIENT_TEAM_USERS_REMOVE,
  CLIENT_TEAM_SHOW_VERIFICATION_MODAL,
} from 'actions/admin/team/action-types';
import { usersApi } from 'services/api/users-api';
import { rolesApi } from 'services/api/roles-api';
import { serviceProvidersApi } from 'services/api/service-providers-api';
import { COMPANY_TYPES } from 'domain/company-type-enum';
import { USER_ROLES } from 'domain/user-role-enum';
import { buildingsApi } from 'services/api/buildings-api';
import { authRefreshUserAccountable } from 'actions/authentication';

export const baseRoutePath = '/admin/team';

export function addUser(user) {
  return {
    type: CLIENT_TEAM_USERS_ADD,
    payload: user,
  };
}

export function deleteUserRequest(userId) {
  return (dispatch) => {
    return usersApi.removeUser(userId).then((response) => {
      if (response.ok) {
        dispatch(removeUser(userId));
        dispatch(setShowInviteUserModal(false));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function editUser(user) {
  return {
    type: CLIENT_TEAM_USERS_EDIT,
    payload: user,
  };
}

export function invalidForm(invalidFields, message) {
  return {
    type: CLIENT_TEAM_CLIENT_FORM_INVALID,
    payload: { invalidFields, message },
  };
}

export function goToEditClient() {
  return (dispatch) => {
    dispatch(push(`${baseRoutePath}/edit`));
  };
}

export function goToTeam() {
  return (dispatch) => {
    dispatch(push(`${baseRoutePath}`));
  };
}

export function loadBuildingsRequest(clientId) {
  return (dispatch) => {
    return buildingsApi.getBuildings(clientId).then((response) => {
      if (response.ok) {
        dispatch(loadBuildingsSuccess(response.buildings));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

function loadBuildingsSuccess(buildings) {
  return {
    type: CLIENT_TEAM_BUILDINGS_LOAD_SUCCESS,
    payload: buildings,
  };
}

export function loadClientRequest(clientId) {
  return (dispatch) => {
    return clientsApi.getClient(clientId).then((response) => {
      if (response.ok) {
        dispatch(loadClientSuccess(response.client));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function loadClientSuccess(client) {
  return {
    type: CLIENT_TEAM_CLIENT_LOAD_SUCCESS,
    payload: client,
  };
}

export function loadRolesRequest() {
  return (dispatch) => {
    return rolesApi.getRoles(COMPANY_TYPES.CLIENT).then((response) => {
      if (response.ok) {
        dispatch(loadRolesSuccess(response.permissions));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}

export function loadRolesSuccess(roles) {
  return {
    type: CLIENT_TEAM_ROLES_LOAD_SUCCESS,
    payload: roles,
  };
}

export function loadUserForm(user) {
  return (dispatch) => {
    let form;
    if (user !== null) {
      form = {
        buildingsId: user.building_ids,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        phoneNumber: user.phone_number != null ? user.phone_number : '',
        rolesId: user.roles.map((role) => role.id),
        title: user.title !== null ? user.title : '',
      };
    } else {
      form = {
        buildingsId: [],
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        rolesId: [],
        title: '',
      };
    }

    dispatch({
      type: CLIENT_TEAM_INVITE_USER_FORM_LOAD,
      payload: form,
    });
  };
}

export function loadUsersRequest() {
  return (dispatch) => {
    return usersApi.getUsers().then((response) => {
      if (response.ok) {
        dispatch(loadUsersSuccess(response.users));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function loadUsersSuccess(users) {
  return {
    type: CLIENT_TEAM_USERS_LOAD_SUCCESS,
    payload: users,
  };
}

export function removeUser(userId) {
  return {
    type: CLIENT_TEAM_USERS_REMOVE,
    payload: userId,
  };
}

export function setCurrentUserId(userId) {
  return {
    type: CLIENT_TEAM_INVITE_USER_ID_SET,
    payload: userId,
  };
}

export function setShowInviteUserModal(open, state) {
  return {
    type: CLIENT_TEAM_INVITE_USER_MODAL_SHOW,
    payload: { open, state: state ? state : undefined },
  };
}

export function submitClientFormRequest(form, clientId) {
  return (dispatch) => {
    // Validate form
    const requiredFields = [
      'companyName',
      'firstName',
      'lastName',
      'email',
      'phoneNumber',
      'streetAddress',
      'city',
      'state',
      'zipCode',
    ];
    const invalidFields = [];
    requiredFields.forEach((attribute) => {
      if (form[attribute] === '') {
        invalidFields.push(attribute);
      }
    });

    if (invalidFields.length > 0) {
      dispatch(
        invalidForm(invalidFields, 'The outlined inputs are mandatory.')
      );
    } else {
      dispatch(validForm());

      const data = {
        name: form.companyName,
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        phoneNumber: form.phoneNumber,
        streetAddress: form.streetAddress,
        unitNumber: form.unitNumber,
        city: form.city,
        state: form.state,
        zipCode: form.zipCode,
        globalPurchaseOrderRequired: form.globalPurchaseOrderRequired,
        purchaseOrderRequired: form.purchaseOrderRequired,
      };

      return clientsApi.updateClient(clientId, data).then((response) => {
        if (response.ok) {
          dispatch(loadClientSuccess(response.client));
          authRefreshUserAccountable(response.client);
          dispatch(goToTeam());
          window.location.reload();
        } else {
          dispatch(showErrorMessage(response.errors));
        }

        return response;
      });
    }
  };
}

export function submitUserFormRequest(form, userId, roles) {
  return (dispatch) => {
    let businessMgrRoleSelected = false;
    const businessMaganerRole = roles.find(
      (role) => role.name === USER_ROLES.BUILDING_MANAGER
    );
    if (businessMaganerRole !== null) {
      businessMgrRoleSelected =
        form.rolesId.find((id) => id === businessMaganerRole.id) !== undefined;
    }

    // Validate form
    let requiredFields = ['firstName', 'lastName', 'email', 'phoneNumber'];
    const invalidFields = [];
    requiredFields.forEach((attribute) => {
      if (form[attribute] === '' || form[attribute] === null) {
        invalidFields.push(attribute);
      }
    });

    if (form['rolesId'].length === 0) {
      invalidFields.push('rolesId');
    }

    if (businessMgrRoleSelected && form['buildingsId'].length === 0) {
      invalidFields.push('buildingsId');
    }

    if (invalidFields.length > 0) {
      dispatch(
        invalidForm(
          invalidFields,
          'The outlined inputs should be added in order to invite a user.'
        )
      );
    } else {
      dispatch(validForm());

      if (userId === 0) {
        return usersApi
          .addUser(
            form.firstName,
            form.lastName,
            form.email,
            form.phoneNumber,
            form.rolesId,
            form.title,
            form.buildingsId
          )
          .then((response) => {
            if (response.ok) {
              dispatch(addUser(response.user));
              dispatch(setShowInviteUserModal(false));
            } else {
              dispatch(showErrorMessage(response.errors));
            }

            return response;
          });
      } else {
        return usersApi
          .updateUser(
            userId,
            form.firstName,
            form.lastName,
            form.email,
            form.phoneNumber,
            form.rolesId,
            form.title,
            form.buildingsId
          )
          .then((response) => {
            if (response.ok) {
              dispatch(editUser(response.user));
              dispatch(setShowInviteUserModal(false));
            } else {
              dispatch(showErrorMessage(response.errors));
            }

            return response;
          });
      }
    }
  };
}

export function updateClientFormValue(attribute, value) {
  return {
    type: CLIENT_TEAM_CLIENT_FORM_VALUE_UPDATE,
    payload: { attribute, value },
  };
}

export function updateFormFromCurrentClient(currentClient) {
  return {
    type: CLIENT_TEAM_EDIT_FORM_LOAD_FROM_CURRENT,
    payload: currentClient,
  };
}

export function updateUserFormValue(attribute, value) {
  return {
    type: CLIENT_TEAM_INVITE_USER_FORM_VALUE_UPDATE,
    payload: { attribute, value },
  };
}

export function validForm() {
  return {
    type: CLIENT_TEAM_CLIENT_FORM_VALID,
  };
}

export function setShowVerificationModal(show, user = null, action = null) {
  return {
    type: CLIENT_TEAM_SHOW_VERIFICATION_MODAL,
    payload: show ? { user, action } : null,
  };
}

function verificationAction(user, action) {
  switch (action) {
    case 'reject':
      return serviceProvidersApi.rejectUser(user);
    default:
      return serviceProvidersApi.verifyUser(user);
  }
}

export function verifyUser(user, action) {
  return (dispatch) => {
    verificationAction(user, action).then((response) => {
      dispatch(setShowVerificationModal(false));
      if (response.ok) {
        dispatch(editUser(response.user));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
    });
  };
}
