import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProjects } from 'components/admin/projects/store/selectors';
import { ProjectStatusSorted } from 'domain/project-status-enum';
import { loadProjectsRequest } from 'components/admin/projects/store/actions';
import { getCurrentUser } from 'selectors/authentication';
import Menu from 'components/shared/menu/Menu';
import ProjectPermissions from 'permissions/project-permissions';

class ProjectsMenu extends Component {
  static propTypes = {
    selector: PropTypes.element.isRequired,
    onClick: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.showMyProjects = ProjectPermissions.canViewMyProjects(
      props.currentUser
    );
  }

  handleItemClick = (filter) => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(filter);
    }
  };

  renderItem(label, counter) {
    return (
      <React.Fragment>
        {label}
        {counter && <span className="menu-item-counter">{counter}</span>}
      </React.Fragment>
    );
  }

  renderStatus = (status) => {
    const { projects } = this.props;

    const counter = projects[status.getTotalKey(true)];
    const isCategory = status.isCategory();

    return (
      counter && {
        content: this.renderItem(status.label, counter),
        onClick: () => this.handleItemClick(status.status),
        subitems:
          isCategory &&
          status.subStatuses.map(this.renderStatus).filter((status) => status),
      }
    );
  };

  fetchItems = (callback) => {
    const { dispatch, projects } = this.props;

    if (projects) {
      callback();
    } else {
      dispatch(loadProjectsRequest({})).then(callback);
    }
  };

  renderItems = () => {
    const { projects } = this.props;

    return projects
      ? [
          {
            content: this.renderItem('All'),
            onClick: () => this.handleItemClick('all'),
          },
          this.showMyProjects && {
            content: this.renderItem('My Projects'),
            onClick: () =>
              this.handleItemClick('my_projects', projects.totalMyProjects),
          },
        ]
          .concat(
            ProjectStatusSorted.filter((status) => !status.isSubStatus())
              .map((status) => this.renderStatus(status))
              .filter((status) => status)
          )
          .filter((item) => item)
      : [];
  };

  render() {
    const { selector } = this.props;

    return (
      <Menu
        className="projects-menu"
        selector={selector}
        items={this.renderItems}
        fetchItems={this.fetchItems}
      />
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    projects: getProjects(state),
  };
})(ProjectsMenu);
