import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Portal } from 'react-portal';
import './Footer.css';

const Footer = (props) => {
  const { className, parentId, children } = props;
  const parent = document.getElementById(parentId);
  const parentClassNames = [
    'with-footer',
    className ? `with-footer-${className}` : '',
  ].filter((className) => !!className);

  useEffect(() => {
    parentClassNames.forEach((className) => parent.classList.add(className));

    return () => {
      parentClassNames.forEach((className) =>
        parent.classList.remove(className)
      );
    };
  });

  return (
    <Portal node={parent}>
      <div className={classnames('footer', { [className]: !!className })}>
        {children}
      </div>
    </Portal>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  parentId: PropTypes.string,
  children: PropTypes.element.isRequired,
};

Footer.defaultProps = {
  className: '',
  parentId: 'admin',
};

export default Footer;
