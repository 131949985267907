import {
  NOTIFICATIONS_CURRENT_PAGE_SET,
  NOTIFICATIONS_SHOW_LOADER_SET,
} from '../actions/notifications';

const initialState = {
  currentPage: 1,
  showLoader: false,
};

export default function notificationsUiReducer(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATIONS_CURRENT_PAGE_SET:
      return {
        ...state,
        currentPage: action.payload,
      };

    case NOTIFICATIONS_SHOW_LOADER_SET:
      return {
        ...state,
        showLoader: action.payload,
      };

    default:
      return state;
  }
}
