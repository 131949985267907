import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormModal from 'components/shared/modal/FormModal';
import UserForm from 'components/admin/projects/details/project-directory/UserForm';

class UserModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    roles: PropTypes.arrayOf(PropTypes.shape({})),
    canSetAccountAdmin: PropTypes.bool.isRequired,
    initialValues: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
      title: PropTypes.string,
      assignedRole: PropTypes.string,
      accountAdmin: PropTypes.bool,
      accountableId: PropTypes.number,
    }),
  };

  render() {
    const {
      show,
      handleSubmit,
      handleClose,
      initialValues,
      roles,
      canSetAccountAdmin,
    } = this.props;

    return (
      <FormModal
        show={show}
        subTitle={initialValues ? 'Edit User' : 'Invite User'}
        onClose={handleClose}
      >
        <UserForm
          onSubmit={handleSubmit}
          onClose={handleClose}
          initialValues={initialValues}
          roles={roles}
          submitLabel={initialValues ? 'Save' : 'Invite'}
          canSetAccountAdmin={canSetAccountAdmin}
        />
      </FormModal>
    );
  }
}

export default UserModal;
