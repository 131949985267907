import { SET_THEME, SET_CHAT_OPEN } from '../actions/theme';

const initialState = {
  chatOpen: true,
};

export default function themeReducer(state = initialState, action) {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case SET_CHAT_OPEN:
      return {
        ...state,
        chatOpen: action.payload,
      };

    default:
      return state;
  }
}
