import React, { useState } from 'react';
import { connect } from 'react-redux';

import FormModal from 'components/shared/modal/FormModal';
import SearchBar from 'components/shared/navigation/SearchBar';
import ResolvedReportItem from '../ResolvedReportItem';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

import {
  getFieldReports,
  getFieldReportsResovedModal,
} from './store/selectors';
import {
  getCurrentPage,
  getReportsPageSize,
  getReportsTotal,
} from 'components/admin/projects/details/project-daily-report-log/store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  loadFieldReportsResolved,
  setFieldReportsResovedModal,
} from './store/actions';
import './FieldReportsResolvedModal.css';

const FieldReportsResolvedModal = (props) => {
  const {
    dispatch,
    show,
    reports,
    currentProject,
    reportsPageSize,
    reportsTotal,
    currentPage,
  } = props;
  const [search, setSearch] = useState('');

  const onClose = () => {
    dispatch(setFieldReportsResovedModal(false));
  };

  const loadResolvedReports = (filters) => {
    dispatch(loadFieldReportsResolved(currentProject.id, filters));
  };

  return (
    <FormModal
      id="field-reports-resolved-modal"
      className="field-reports-resolved-modal"
      show={show}
      onClose={onClose}
      subTitle="Field Report"
    >
      <div className="container-header-title">
        <div className="modal-name text-color">Resolved Summary Remarks</div>
        <SearchBar
          className="search-input-background"
          searchPlaceHolder="Search"
          searchText={search}
          onSearchTextChange={setSearch}
          onSearch={() => loadResolvedReports({ text: search, page: 1 })}
        />
      </div>

      <div className="container-body-remark">
        {reports.length > 0 ? (
          reports
            .filter((report) => report.description)
            .map((report) => (
              <ResolvedReportItem
                key={report.id}
                number={report.number}
                date={report.date}
                submitted_by={report.published_by}
                remarks={report.description}
              />
            ))
        ) : (
          <div className="modal-no-items text-color">No Remarks</div>
        )}
      </div>
      {reportsPageSize * currentPage < reportsTotal && (
        <div className="pagination-container">
          <Button
            key="load-more"
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.TRANSPARENT}
            label="Load More"
            onClick={() =>
              loadResolvedReports({ text: search, page: currentPage + 1 })
            }
          />
        </div>
      )}
    </FormModal>
  );
};

export default connect((state) => ({
  show: getFieldReportsResovedModal(state),
  reports: getFieldReports(state),
  reportsPageSize: getReportsPageSize(state),
  reportsTotal: getReportsTotal(state),
  currentPage: getCurrentPage(state),
  currentProject: getCurrentProject(state),
}))(FieldReportsResolvedModal);
