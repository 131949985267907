import { createTheme } from '@mui/material';
import { enUS } from '@mui/material/locale';

import colors from '../styles/colors';

export const theme = createTheme({
  typography: { fontFamily: 'Titillium Web, sans-serif', fontSize: 22 },
  palette: {
    primary: { main: colors['primary-green'] },
    secondary: { main: colors['chart-red'] },
    error: { main: colors['chart-red'] },
    info: { main: colors['light-mode-clear-black'] },
    success: { main: colors['primary-green'] },
    background: {
      default: colors['white-main'],
      paper: colors['very-light-grey'],
    },
    text: {
      primary: colors['light-mode-black-text'],
      disabled: colors['dark-mode-medium-white'],
      secondary: colors['light-mode-dark-black'],
    },
  },
  enUS,
});

export const themeDark = createTheme({
  typography: { fontFamily: 'Titillium Web, sans-serif', fontSize: 22 },
  palette: {
    primary: { main: colors['neon'] },
    secondary: { main: colors['chart-blue'] },
    info: { main: colors['dark-line-grey'] },
    error: { main: colors['chart-blue'] },
    success: { main: colors['dark-mode-green'] },
    background: {
      default: colors['dark-mode-black'],
      paper: colors['dark-mode-grey'],
    },
    text: {
      primary: colors['white-text'],
      disabled: colors['light-mode-clear-black'],
      secondary: colors['dark-mode-light-white'],
    },
  },
  enUS,
});
