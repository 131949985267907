import { createSelector } from 'reselect';

export const contractorInvoicesSelector = createSelector(
  (state) => state.contractors.data.contractorInvoices,
  (contractorInvoices) => {
    return contractorInvoices;
  }
);

export const getContractors = createSelector(
  (state) => state.contractors.data.contractors,
  (contractors) => {
    return contractors;
  }
);

export const getCurrentContractor = createSelector(
  (state) => state.contractors.data.currentContractor,
  (currentContractor) => {
    return currentContractor;
  }
);

export const getCurrentPage = createSelector(
  (state) => state.contractors.ui.currentPage,
  (currentPage) => {
    return currentPage;
  }
);

export const getForm = createSelector(
  (state) => state.contractors.ui.form,
  (form) => {
    return form;
  }
);

export const getInvalidFields = createSelector(
  (state) => state.contractors.ui.invalidFields,
  (invalidFields) => {
    return invalidFields;
  }
);

export const getServiceAreas = createSelector(
  (state) => state.contractors.data.serviceAreas,
  (serviceAreas) => {
    return serviceAreas;
  }
);

export const getSelectedServiceAreasId = createSelector(
  (state) => state.contractors.ui.selectedServiceAreasId,
  (selectedServiceAreasId) => {
    return selectedServiceAreasId;
  }
);

export const getSelectedTradesId = createSelector(
  (state) => state.contractors.ui.selectedTradesId,
  (selectedTradesId) => {
    return selectedTradesId;
  }
);

export const getSelectedUnionsId = createSelector(
  (state) => state.contractors.ui.selectedUnionsId,
  (selectedUnionsId) => {
    return selectedUnionsId;
  }
);

export const getShowContactsModal = createSelector(
  (state) => state.contractors.ui.showContacts,
  (showContacts) => {
    return showContacts;
  }
);

export const getTrades = createSelector(
  (state) => state.contractors.data.trades,
  (trades) => {
    return trades;
  }
);

export const getUnions = createSelector(
  (state) => state.contractors.data.unions,
  (unions) => {
    return unions;
  }
);

export const getValidationMessage = createSelector(
  (state) => state.contractors.ui.validationMessage,
  (validationMessage) => {
    return validationMessage;
  }
);

export const getUsers = createSelector(
  (state) => state.contractors.data.users,
  (users) => {
    return users;
  }
);

export const getWorkOrders = createSelector(
  (state) => state.contractors.data.workOrders,
  (workOrders) => {
    return workOrders;
  }
);

export const getProjects = createSelector(
  (state) => state.contractors.data.projects,
  (projects) => {
    return projects;
  }
);

export const getMsaSigningUrl = createSelector(
  (state) => state.contractors.ui.msaSigningUrl,
  (msaSigningUrl) => {
    return msaSigningUrl;
  }
);

export const getShowMsaSigningModal = createSelector(
  (state) => state.contractors.ui.showMsaSigningModal,
  (showMsaSigningModal) => {
    return showMsaSigningModal;
  }
);

export const getContractorsCurrentPage = createSelector(
  (state) => state.contractors.ui.contractorsCurrentPage,
  (contractorsCurrentPage) => {
    return contractorsCurrentPage;
  }
);

export const getContractorsTotal = createSelector(
  (state) => state.contractors.ui.contractorsTotal,
  (contractorsTotal) => {
    return contractorsTotal;
  }
);

export const getContractorsPageSize = createSelector(
  (state) => state.contractors.ui.contractorsPageSize,
  (contractorsPageSize) => {
    return contractorsPageSize;
  }
);

export const getRoles = createSelector(
  (state) => state.contractors.data.roles,
  (roles) => {
    return roles;
  }
);

export const getShowInviteContractorUserModal = createSelector(
  (state) => state.contractors.ui.showInviteContractorUserModal,
  (showInviteContractorUserModal) => {
    return showInviteContractorUserModal;
  }
);

export const getShowContractorUnionOther = createSelector(
  (state) => state.contractors.ui.showOtherUnion,
  (showOtherUnion) => {
    return showOtherUnion;
  }
);
