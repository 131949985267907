import { SET_SECURITY_CAMERA_CONFIGURATION } from './action-types';

const initialState = {
  configuration: null,
};

export default function projectPhotosDataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SECURITY_CAMERA_CONFIGURATION: {
      return { ...state, configuration: action.payload };
    }

    default:
      return state;
  }
}
