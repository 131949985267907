import { TUTORIALS_LOAD_SUCCESS } from './action-types';

const initialState = {
  tutorials: [],
};

export default function tutorialsDataReducer(state = initialState, action) {
  switch (action.type) {
    case TUTORIALS_LOAD_SUCCESS:
      return {
        ...state,
        tutorials: action.payload,
      };

    default:
      return state;
  }
}
