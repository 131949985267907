import React from 'react';
import { connect } from 'react-redux';

import { Grid } from '@mui/material';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/fontawesome-free-solid';
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from 'react-filerobot-image-editor';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import Button, { BUTTON_TYPES } from 'components/shared/button/Button';

import { theme, themeDark } from 'theme/fie-theme';
import useDirectUpload from 'services/hooks/direct-upload';
import {
  dataUrlToFile,
  urlToFileNameAndExtension,
} from 'services/utils/files-util';
import { getDarkMode } from 'selectors/theme';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';

import { getProjectPhotosEditImage } from './store/selectors';
import {
  saveProjectPhoto,
  setProjectPhotosEditImage,
  updateProjectPhotosDetail,
} from './store/actions';
import { getImageThumbnail } from 'services/utils/images-util';

const WIDTH = 384;
const HEIGHT = 288;

const ProjectPhotosDetailPhoto = (props) => {
  const { dispatch, photo, edit, currentProject, darkMode } = props;
  const { directUpload } = useDirectUpload();

  const renderControls = ({ zoomIn, zoomOut }) => {
    return (
      <Grid container className="carousel-zoom-controls" alignItems="center">
        <Grid item>
          <Button
            onClick={() => zoomIn(0.5)}
            type={BUTTON_TYPES.LINK}
            label={<FontAwesomeIcon icon={faPlus} />}
          />
        </Grid>
        <Grid item>
          <Button
            onClick={() => zoomOut(0.5)}
            type={BUTTON_TYPES.LINK}
            label={<FontAwesomeIcon icon={faMinus} />}
          />
        </Grid>
      </Grid>
    );
  };

  const getThumbnailFile = async (name, canvas, type) => {
    const img = canvas.toDataURL(type).toString();
    const base64 = await getImageThumbnail(img, WIDTH, HEIGHT);

    const thumb_name = `gallery_${name}`;
    const thumb_file = await dataUrlToFile(base64, thumb_name);

    return thumb_file;
  };

  const getImageFile = async (name, canvas, type) => {
    const base64 = canvas.toDataURL(type).toString();
    const file = await dataUrlToFile(base64, name);

    return file;
  };

  const getVersionFileName = (name, extension) => {
    let version = 1;
    let versionName = name;
    const regex = /_v(\d+)$/;

    if (regex.test(name)) {
      const match = regex.exec(name);
      if (match && match[1]) {
        version = parseInt(match[1], 10) + 1;
        versionName = name.replace(regex, '');
      }
    }

    return `${versionName}_v${version}.${extension}`;
  };

  const onSave = async (editedImageObject) => {
    const { extension, name, imageCanvas, mimeType } = editedImageObject;

    const imageName = getVersionFileName(name, extension);
    const file = await getImageFile(imageName, imageCanvas, mimeType);
    const thumb = await getThumbnailFile(imageName, imageCanvas, mimeType);

    const S3params = {
      model: 'project_photos',
      subModel: 'images',
      identifier: photo.id,
    };
    const result = await directUpload(file, S3params);
    const resultThumb = await directUpload(thumb, S3params);

    if (result.ok && resultThumb.ok) {
      const projectId = currentProject.id;
      const fields = { photo: result };

      const res = await dispatch(saveProjectPhoto(projectId, photo.id, fields));
      if (!res.ok) return;

      dispatch(updateProjectPhotosDetail(photo.id, { image: res.data.image }));
    }

    onClose();
  };
  const onClose = () => dispatch(setProjectPhotosEditImage(false));

  const getImage = () => {
    const res = new Image();
    res.src = photo.image.image.url;

    res.src = res.src + '?' + new Date().getTime();
    res.setAttribute('crossOrigin', '');
    return res;
  };

  if (edit) {
    const {
      name: defaultName,
      extension: defaultExtension,
    } = urlToFileNameAndExtension(photo.image.image.url);

    return (
      <div className="file-editor">
        <FilerobotImageEditor
          source={getImage()}
          onSave={onSave}
          onClose={onClose}
          Pen={{ strokeWidth: 3 }}
          Line={{ strokeWidth: 3 }}
          Arrow={{ strokeWidth: 3 }}
          Polygon={{ fill: 'transparent' }}
          Ellipse={{ fill: 'transparent' }}
          Rect={{ cornerRadius: 0, fill: 'transparent' }}
          Text={{
            text: '',
            fontSize: 16,
            strokeWidth: 0,
            fontFamily: 'Titillium Web, sans-serif',
            fill: '#FF0000',
          }}
          annotationsCommon={{
            strokeWidth: 3,
            stroke: '#FF0000',
          }}
          tabsIds={[TABS.ANNOTATE, TABS.ADJUST]}
          defaultTabId={TABS.ANNOTATE}
          defaultToolId={TOOLS.PEN}
          defaultSavedImageType={defaultExtension}
          defaultSavedImageName={defaultName}
          useCloudimage={false}
          avoidChangesNotSavedAlertOnLeave
          useBackendTranslations={false}
          theme={darkMode ? themeDark : theme}
        />
      </div>
    );
  }

  return (
    <TransformWrapper
      pinch={{ step: 20 }}
      velocityAnimation={{ equalToMove: true, sensitivity: 500 }}
      centerZoomedOut
      smooth
    >
      {(utils) => (
        <>
          {renderControls(utils)}
          <TransformComponent
            contentStyle={{ height: '100%', width: '100%' }}
            wrapperStyle={{ height: '100%', width: '100%' }}
          >
            <img src={photo.image.image.url} alt={photo.image.image.url} />
          </TransformComponent>
        </>
      )}
    </TransformWrapper>
  );
};

export default connect((state) => ({
  edit: getProjectPhotosEditImage(state),
  currentProject: getCurrentProject(state),
  darkMode: getDarkMode(state),
}))(ProjectPhotosDetailPhoto);
