import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Dropdown.css';
import 'react-select/dist/react-select.css';
import 'babel-polyfill';
import AsyncPaginate from 'react-select-async-paginate';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import ReadOnlyInput from '../input/ReadOnlyInput';
import vector from './vector.svg';
import vectorDark from './vector-dark.svg';

class SearchableDropDownPaginated extends Component {
  static propTypes = {
    arrowRenderer: PropTypes.func,
    defaultArrow: PropTypes.bool,
    className: PropTypes.string,
    clearable: PropTypes.bool,
    withCreate: PropTypes.bool,
    loadOptions: PropTypes.func.isRequired, // Return promise (Object with "ok", "options" or "errors", function receives the input from the drop down, should return array of [value, label]
    placeholder: PropTypes.string,
    value: PropTypes.any,
    pageSize: PropTypes.number,
    readOnly: PropTypes.bool,
    valid: PropTypes.bool,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    defaultArrow: false,
    arrowRenderer: null,
    clearable: false,
    valid: true,
    withCreate: false,
    placeholder: '',
    pageSize: 25,
    onChange: () => {},
  };

  loadOptions = async (input, loadedOptions) => {
    let loadedOptionsCount = loadedOptions.length;
    if (this.props.withCreate) {
      loadedOptionsCount -= 1;
    }

    const page = Math.ceil(loadedOptionsCount / this.props.pageSize) + 1;
    const { options, hasMore } = await this.props.loadOptions(input, page);

    return {
      options,
      hasMore,
    };
  };

  renderDefaultArrow = () => (
    <img
      className="dropdown-arrow"
      src={this.props.darkMode ? vectorDark : vector}
      alt={'arrow'}
    />
  );

  render() {
    const {
      className,
      clearable,
      placeholder,
      value,
      readOnly,
      onChange,
      darkMode,
      valid,
    } = this.props;

    const arrowRenderer =
      this.props.arrowRenderer ||
      (this.props.defaultArrow && this.renderDefaultArrow) ||
      null;

    return readOnly ? (
      <ReadOnlyInput
        className={className !== undefined ? ' ' + className : ''}
        value={(value && value.label) || value || placeholder}
      />
    ) : (
      <div
        className={
          'dropdown searchable-dropdown searchable-dropdown-paginated' +
          (className !== undefined ? ' ' + className : '') +
          (darkMode ? ' dark-mode' : '') +
          (!valid ? ' invalid' : '')
        }
      >
        <AsyncPaginate
          arrowRenderer={arrowRenderer}
          clearable={clearable}
          placeholder={placeholder}
          value={value}
          loadOptions={this.loadOptions}
          onChange={onChange}
        />
      </div>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(SearchableDropDownPaginated);
