import User from './user';
import { RfiStatusEnum } from './rfi-status-enum';

class Rfi {
  addlAssignees = (user, rfi) =>
    ((rfi && rfi.rfi_questions) || [])
      .map((qus) => qus.rfi_addl_assignees)
      .flat()
      .filter((addlAssignee) => user.id === addlAssignee.assignee_id);

  isSubmittedByUserCompany = (user, rfi) =>
    !!rfi && !!rfi.submitted_by && User.isSameCompany(user, rfi.submitted_by);

  isSubmittedByGcOar = (directory, rfi) =>
    !!rfi &&
    !!rfi.submitted_by &&
    User.isGcOar(User.userContributor(directory, rfi.submitted_by));

  isAddlAssignedToUser = (user, rfi) =>
    !!rfi && this.addlAssignees(user, rfi).length > 0;

  isAssignedToUser = (user, rfi) =>
    !!rfi &&
    (rfi.assignee_ids || []).some((assignedId) => user.id === assignedId);

  isAddlRespondedByUser = (user, rfi) =>
    !!rfi &&
    this.addlAssignees(user, rfi).some((addlAssignee) => addlAssignee.response);

  isRespondedByUser = (user, rfi) =>
    rfi.responder_ids.some((responderId) => user.id === responderId) ||
    this.isAddlRespondedByUser(user, rfi);

  isPendingUserResponse = (user, rfi) =>
    ((rfi && rfi.rfi_questions) || []).some(
      ({ assignee_id: assigneeId, response, draft_response: draftResponse }) =>
        assigneeId === user.id && (!response || draftResponse)
    );

  isUnresolvedUserResponse = (user, rfi) =>
    ((rfi && rfi.rfi_questions) || []).some(
      ({ assignee_id: assigneeId, resolved }) =>
        assigneeId === user.id && !resolved
    );

  isSubcontractorUser = (user, project, rfi) =>
    !!rfi &&
    user.accountable_id === rfi.contractor_id &&
    User.isContractor(user, project);

  isStatus = (rfi, status) =>
    !!rfi && RfiStatusEnum[status].equals(RfiStatusEnum.create(rfi.status));

  isDraft = (rfi) => !rfi || this.isStatus(rfi, 'DRAFT');

  isPending = (rfi) => this.isStatus(rfi, 'PENDING');

  isResolved = (rfi) => this.isStatus(rfi, 'RESOLVED');
}

export default new Rfi();
