import {
  MEETING_MINUTES_INIT,
  MEETING_INFO_EDIT_SET,
  MEETING_MODAL_SHOW_SET,
  MEETING_MODAL_INIT,
  MEETING_FORM_VALUE_UPDATE,
  MEETING_ITEM_VALUE_UPDATE,
  MEETING_ITEM_ADD,
  MEETING_ITEMS_ATTACH,
  ATTENDEES_MODAL_SHOW_SET,
  REVIEW_MEETING_SET,
  MEETING_ITEM_SETTINGS_SET,
  TOGGLE_PDF_VIEWER_SUCCESS,
  CONFIRMATION_MSG_SET,
  CONFIRM_APPROVAL_SET,
  SELECTED_MEETING_TYPE_SET,
} from 'components/admin/projects/details/project-meeting-minutes/store/action-types';

const initialState = {
  meetingInfoEdit: false,
  meetingModalShow: false,
  meetingForm: { attendeeIds: [] },
  meetingItems: {},
  showAttendeesModal: false,
  reviewMeeting: false,
  meetingItemSettings: {},
  showPdfViewer: false,
  confirmationMsg: '',
  confirmApproval: false,
  selectedMeetingType: null,
};

export default function projectMeetingMinutesUiReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case MEETING_MINUTES_INIT:
      return initialState;

    case MEETING_INFO_EDIT_SET:
      return {
        ...state,
        meetingInfoEdit: action.payload,
      };

    case MEETING_MODAL_SHOW_SET:
      return {
        ...state,
        meetingModalShow: action.payload,
      };

    case MEETING_MODAL_INIT:
      return {
        ...state,
        meetingForm: initialState.meetingForm,
        meetingItems: initialState.meetingItems,
        showAttendeesModal: initialState.showAttendeesModal,
        reviewMeeting: initialState.reviewMeeting,
        meetingItemSettings: initialState.meetingItemSettings,
        confirmApproval: initialState.confirmApproval,
      };

    case MEETING_FORM_VALUE_UPDATE:
      return {
        ...state,
        meetingForm: {
          ...state.meetingForm,
          [action.payload.attribute]: action.payload.value,
        },
      };

    case MEETING_ITEM_VALUE_UPDATE:
      const item = state.meetingItems[action.payload.key];
      return {
        ...state,
        meetingItems: {
          ...state.meetingItems,
          [action.payload.key]: {
            ...item,
            [action.payload.attribute]: action.payload.value,
          },
        },
      };

    case MEETING_ITEM_ADD:
      return {
        ...state,
        meetingItems: {
          ...state.meetingItems,
          [action.payload.key]: action.payload.item,
        },
      };

    case MEETING_ITEMS_ATTACH:
      return {
        ...state,
        meetingItems: {
          ...state.meetingItems,
          ...action.payload,
        },
      };

    case ATTENDEES_MODAL_SHOW_SET:
      return {
        ...state,
        showAttendeesModal: action.payload,
      };

    case REVIEW_MEETING_SET:
      return {
        ...state,
        reviewMeeting: action.payload,
      };

    case MEETING_ITEM_SETTINGS_SET:
      return {
        ...state,
        meetingItemSettings: {
          [action.payload.key]: action.payload.menu,
        },
      };

    case TOGGLE_PDF_VIEWER_SUCCESS:
      return {
        ...state,
        showPdfViewer: !state.showPdfViewer,
      };

    case CONFIRMATION_MSG_SET:
      return {
        ...state,
        confirmationMsg: action.payload,
      };

    case CONFIRM_APPROVAL_SET:
      return {
        ...state,
        confirmApproval: action.payload,
      };

    case SELECTED_MEETING_TYPE_SET:
      return {
        ...state,
        selectedMeetingType: action.payload,
      };

    default:
      return state;
  }
}
