import { createSelector } from 'reselect';

export const getRfaTabNavigation = createSelector(
  (state) => state.projects.scenes.details.scenes.rfaLog.ui.tabNavigation,
  (tabNavigation) => tabNavigation
);

export const getRfaFilters = createSelector(
  (state) => state.projects.scenes.details.scenes.rfaLog.data.filters,
  (filters) => filters
);

export const getRfaDataChanged = createSelector(
  (state) => state.projects.scenes.details.scenes.rfaLog.data.dataChanged,
  (dataChanged) => dataChanged
);

export const getTotalRfas = createSelector(
  (state) => state.projects.scenes.details.scenes.rfaLog.data.total,
  (total) => total
);

export const getRfas = createSelector(
  (state) => state.projects.scenes.details.scenes.rfaLog.data.rfas,
  (rfas) => rfas
);

export const getCurrentRfa = createSelector(
  (state) => state.projects.scenes.details.scenes.rfaLog.data.currentRfa,
  (rfa) => rfa
);

export const getRfaLogPdf = createSelector(
  (state) => state.projects.scenes.details.scenes.rfaLog.data.logPdf,
  (pdf) => pdf
);
export const getRfaLogPdfType = createSelector(
  (state) => state.projects.scenes.details.scenes.rfaLog.data.logPdfType,
  (type) => type
);

export const getShowRfaLogPdf = createSelector(
  (state) => state.projects.scenes.details.scenes.rfaLog.ui.showLogPdf,
  (show) => show
);

export const getShowDeleteConfirmation = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.rfaLog.ui.showDeleteConfirmation,
  (show) => show
);

export const getShowRfaModal = createSelector(
  (state) => state.projects.scenes.details.scenes.rfaLog.ui.showRfaModal,
  (show) => show
);

export const getShowRfaFilters = createSelector(
  (state) => state.projects.scenes.details.scenes.rfaLog.ui.showRfaFilters,
  (show) => show
);

export const getShowCreateModal = createSelector(
  (state) => state.projects.scenes.details.scenes.rfaLog.ui.showRfaCreateModal,
  (show) => show
);

export const getCreateModalRevisedRfaId = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.rfaLog.ui.createModalRevisedRfaId,
  (show) => show
);

export const getRfaForm = createSelector(
  (state) => state.projects.scenes.details.scenes.rfaLog.data.form,
  (form) => form
);

export const getRfaReadOnly = createSelector(
  (state) => state.projects.scenes.details.scenes.rfaLog.ui.readOnly,
  (readOnly) => readOnly
);

export const getRfaNotes = createSelector(
  (state) => state.projects.scenes.details.scenes.rfaLog.data.rfaNotes,
  (rfaNotes) => rfaNotes
);
