import { SET_PROJECT_TAGS, ADD_PROJECT_TAG } from './action-types';

const initialState = { tags: [] };

export default function projectTagsDataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROJECT_TAGS: {
      return {
        ...state,
        tags: action.payload.map(({ id: value, label }) => ({ value, label })),
      };
    }

    case ADD_PROJECT_TAG: {
      return {
        ...state,
        tags: [
          ...state.tags,
          { value: action.payload.id, label: action.payload.label },
        ],
      };
    }

    default:
      return state;
  }
}
