import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';

class NotificationsApi extends BaseApi {
  getNotifications(page = 1, filters = {}) {
    const byProject = filters.by_project;
    delete filters.by_project;

    let query = `page=${page}`;
    Object.keys(filters).forEach((filter) => {
      query += `&${filter}=${filters[filter]}`;
    });

    let path = 'notifications';
    if (byProject) {
      path = `projects/${byProject}/notifications`;
    }

    return axios
      .get(`${this.baseUrl}/${path}?${query}`, {
        loadingDisabled: true,
      })
      .then((response) => {
        return {
          ok: true,
          notifications: {
            content: response.data,
            total: numeral(response.headers['total']).value(),
            totalUnread: numeral(response.headers['total-unread']).value(),
            pageSize: numeral(response.headers['per-page']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  markAsRead(id) {
    return axios
      .put(`${this.baseUrl}/notifications/${id}/mark_as_read`, null, {
        loadingDisabled: true,
      })
      .then((response) => {
        return {
          ok: true,
          notification: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  markAsUnread(id) {
    return axios
      .put(`${this.baseUrl}/notifications/${id}/mark_as_unread`, null, {
        loadingDisabled: true,
      })
      .then((response) => {
        return {
          ok: true,
          notification: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  markAllAsRead(workOrderId, notifiableId, notifiableType) {
    let query = '';
    if (workOrderId !== undefined) {
      query = `?by_wo=${workOrderId}`;
    } else if (notifiableId !== undefined && notifiableType !== undefined) {
      query = `?by_notifiable[id]=${notifiableId}&by_notifiable[type]=${notifiableType}`;
    }

    return axios
      .put(`${this.baseUrl}/notifications/mark_all_read${query}`, null, {
        loadingDisabled: true,
      })
      .then((response) => {
        return {
          ok: true,
          notifications: {
            content: response.data,
            total: numeral(response.headers['total']).value(),
            totalUnread: numeral(response.headers['total-unread']).value(),
            pageSize: numeral(response.headers['per-page']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const notificationsApi = new NotificationsApi();
