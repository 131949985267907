import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ConfirmationModal from 'components/shared/modal/ConfirmationModal';

import { setFieldReportDraftModal } from './store/actions';
import { getFieldReportDraftModal } from './store/selectors';

const FieldReportSaveDraftModal = (props) => {
  const { dispatch, show, onClose, onConfirm } = props;

  const handleHide = (ok) => {
    dispatch(setFieldReportDraftModal(false));
    if (ok) onConfirm();
    else onClose();
  };

  if (!show) return null;

  return (
    <ConfirmationModal
      show={show}
      message="This item contains content that has not been saved. Would you like
      to save this information?"
      onHide={handleHide}
    />
  );
};

FieldReportSaveDraftModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

FieldReportSaveDraftModal.defaultProps = {
  show: false,
  onClose: () => null,
  onConfirm: () => null,
};

export default connect((state) => ({ show: getFieldReportDraftModal(state) }))(
  FieldReportSaveDraftModal
);
