import React from 'react';
import PropTypes from 'prop-types';
import './ImageButton.css';

export default function ImageButton(props) {
  const handleClick = (event) => {
    event.stopPropagation();
    if (props.onClick && !props.disabled) {
      props.onClick();
    }
  };

  return (
    <img
      alt={props.alt}
      className={`image-button${props.disabled ? ' disabled' : ''}`}
      src={props.src}
      onClick={handleClick}
    />
  );
}

ImageButton.propTypes = {
  alt: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  src: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

ImageButton.defaultProps = {
  disabled: false,
};
