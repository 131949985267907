import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import './SimpleTruncate.css';

class SimpleTruncate extends Component {
  static propTypes = {
    shownLines: PropTypes.number,
    text: PropTypes.string,
  };

  static defaultProps = {
    shownLines: 3,
    text: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      lines: this.props.shownLines,
    };
  }

  handleShowMore = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      lines: 0,
    });
  };

  handleShowLess = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      lines: this.props.shownLines,
    });
  };

  render() {
    const classNames = ['simple-truncate'];
    if (this.state.lines === 0) {
      classNames.push('break-text');
    }
    return (
      <div className={classNames.join(' ')}>
        <Truncate
          lines={this.state.lines}
          ellipsis={
            <span>
              ...{' '}
              <Button
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Show more"
                onClick={this.handleShowMore}
              />
            </span>
          }
        >
          <div>
            {this.props.text &&
              this.props.text.split('\n').map((line, i, arr) => {
                const newLine = <span key={i}>{line}</span>;
                if (i === arr.length - 1) {
                  return newLine;
                } else {
                  return [newLine, <br key={i + 'br'} />];
                }
              })}
          </div>
        </Truncate>
        {this.state.lines === 0 && (
          <Button
            type={BUTTON_TYPES.LINK}
            color={BUTTON_COLORS.GREEN}
            label="Show less"
            onClick={this.handleShowLess}
          />
        )}
      </div>
    );
  }
}

export default SimpleTruncate;
