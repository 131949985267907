import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './CoiExpDocForm.css';
import { getLiabilities } from 'components/admin/projects/details/project-coi-exps/store/selectors';
import { todayValidation } from 'services/utils/date-util';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import SimpleTable from 'components/shared/table/SimpleTable';
import LabeledCheckBox from 'components/shared/checkbox/LabeledCheckBox';
import DatePicker from 'components/shared/date-picker/DatePicker';
import Selector from 'components/shared/selector/Selector';

class CoiExpDocForm extends Component {
  static propTypes = {
    initialValues: PropTypes.shape({}),
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    initialValues: null,
  };

  constructor(props) {
    super(props);

    const { initialValues, liabilities } = props;
    const coiExpDocLiabilities =
      (initialValues && initialValues.coi_exp_doc_liabilities) || [];
    this.state = {
      subcategory: '',
      coiExpDocLiabilities: liabilities.map((liability) => {
        const docLiability = coiExpDocLiabilities.find(
          (coiExpDocLiability) =>
            coiExpDocLiability.liability_id === liability.id
        );
        return {
          id: docLiability && docLiability.id,
          liabilityId: liability.id,
          expDate: docLiability && docLiability.exp_date,
          destroy: !docLiability,
        };
      }),
    };
  }

  subcategories = () => {
    return [
      { value: 'client_landlord', label: 'Owner/Landlord' },
      { value: 'general_contractor', label: 'General Contractor' },
    ];
  };

  formValid = () => {
    const { subcategory, coiExpDocLiabilities } = this.state;
    return (
      !!subcategory &&
      !!coiExpDocLiabilities.find(
        (coiExpDocLiability) => !coiExpDocLiability.destroy
      ) &&
      !coiExpDocLiabilities.find(
        (coiExpDocLiability) =>
          !coiExpDocLiability.destroy && !coiExpDocLiability.expDate
      )
    );
  };

  mapLiabilities = () => {
    const { coiExpDocLiabilities } = this.state;
    const { liabilities } = this.props;
    return liabilities.map((liability) => {
      const docLiability = coiExpDocLiabilities.find(
        (coiExpDocLiability) => coiExpDocLiability.liabilityId === liability.id
      );
      return [
        <LabeledCheckBox
          onChange={(value) =>
            this.handleChange(docLiability, 'destroy', !value)
          }
          checked={!docLiability.destroy}
          label={liability.label}
          position="left"
        />,
        !docLiability.destroy && (
          <DatePicker
            isValidDate={todayValidation}
            onChange={(value) =>
              this.handleChange(docLiability, 'expDate', value)
            }
            value={docLiability.expDate}
          />
        ),
      ];
    });
  };

  handleSubcategoryChange = (option) => {
    this.setState({ subcategory: option.value });
  };

  handleChange = (docLiability, attr, value) => {
    const { coiExpDocLiabilities } = this.state;
    this.setState({
      coiExpDocLiabilities: coiExpDocLiabilities.map((coiExpDocLiability) => {
        if (coiExpDocLiability.liabilityId === docLiability.liabilityId) {
          return { ...docLiability, [attr]: value };
        } else {
          return coiExpDocLiability;
        }
      }),
    });
  };

  handleSubmit = () => {
    const { subcategory, coiExpDocLiabilities } = this.state;
    const { onSubmit } = this.props;
    onSubmit({ subcategory, coiExpDocLiabilities });
  };

  render() {
    const { subcategory } = this.state;
    return (
      <div className="coi-exp-doc-form">
        <div className="form-block">
          <Selector
            options={this.subcategories()}
            placeholder="Cert Holder..."
            value={subcategory}
            onChange={this.handleSubcategoryChange}
          />
        </div>
        <SimpleTable
          className="projects-table"
          headers={[]}
          rows={this.mapLiabilities()}
          emptyMessage="No Liabilities"
        />
        <div className="buttons-container">
          <Button
            type={BUTTON_TYPES.CONFIRMATION}
            color={BUTTON_COLORS.GREEN}
            disabled={!this.formValid()}
            label="Done"
            onClick={this.handleSubmit}
          />
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    liabilities: getLiabilities(state),
  };
})(CoiExpDocForm);
