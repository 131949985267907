import {
  QUOTES_LOAD_SUCCESS,
  QUOTES_COUNT_SUCCESS,
} from '../../../actions/admin/quotes/action-types';

const initialState = {
  quotes: null,
  quotesCount: 0,
};

export default function quotesDataReducer(state = initialState, action) {
  switch (action.type) {
    case QUOTES_LOAD_SUCCESS:
      return {
        ...state,
        quotes: action.payload,
      };

    case QUOTES_COUNT_SUCCESS:
      return {
        ...state,
        quotesCount: action.payload,
      };

    default:
      return state;
  }
}
