import User from './user';

class Document {
  hasAdvancedDocuments = (project) => project.advanced_document_view;

  isDocumentFolder = (file) => !!file.Prefix;

  isDocumentFolderCreator = (file, user) =>
    !!file.author && file.author.id === user.id;

  isDocumentFolderCreatorCompany = (file, user) =>
    User.isSameCompany(file.author, user);

  isDocumentFolderSharedWithUser = (file, user) =>
    (file.users || []).some((fileUser) => fileUser.id === user.id);
}

export default new Document();
