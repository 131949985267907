import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SimpleText extends Component {
  static propTypes = {
    lines: PropTypes.arrayOf(PropTypes.string).isRequired,
    style: PropTypes.object,
  };

  static defaultProps = {
    style: {},
  };

  render() {
    return this.props.lines.map((element, index) => {
      return (
        <div key={index} style={this.props.style}>
          {element}
        </div>
      );
    });
  }
}

export default SimpleText;
