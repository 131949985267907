import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import PropTypes from 'prop-types';
import './DocumentLink.css';
import moment from 'moment';
import pdfSvg from './pdf.svg';
import pdfDarkSvg from './pdfDarkMode.svg';
import docSvg from './doc.svg';
import docDarkSvg from './docDarkMode.svg';
import xlsSvg from './xls.svg';
import xlsDarkSvg from './xlsDarkMode.svg';
import imageSvg from './image.svg';
import imageDarkSvg from './imageDarkMode.svg';
import removeSvg from 'images/deleteCross.svg';
import removeDarkSvg from 'images/deleteCrossDarkMode.svg';
import { getLastTokenFromUrl } from 'services/utils/text-util';

import PdfViewerBluebeam from 'components/shared/pdf-viewer-bluebeam/PdfViewerBluebeam';
import { bluebeamApi } from 'services/api/bluebeam-api';

import { getCreatedBluebeamSession } from 'selectors/pdfViewerBluebeam';
import { createdBluebeamSnapshot } from 'actions/pdfViewerBluebeam';

class DocumentLinkBluebeam extends Component {
  static propTypes = {
    file: PropTypes.any,
    showIcon: PropTypes.bool,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    showLink: PropTypes.bool,
    createdBluebeamSession: PropTypes.bool,
    showInModal: PropTypes.bool,
    onRemove: PropTypes.func,
  };

  static defaultProps = {
    file: null,
    showIcon: true,
    iconPosition: 'left',
    showLink: true,
    createdBluebeamSession: false,
    showInModal: true,
    onRemove: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      showDocumentPdfViewer: false,
      pdfUrl: null,
    };
  }

  getName = (props) => {
    let result = 'Unknown';
    if (props.file.url) {
      const name = getLastTokenFromUrl(props.file.url);
      if (name) {
        result = decodeURIComponent(name);
      }
    } else if (props.file) {
      result = props.file.name;
    }
    return result;
  };

  renderIcon = (icon, alt) => {
    return <img className="document-link-image" src={icon} alt={alt} />;
  };

  getIcon = (props) => {
    let icon;
    let fileName = '';

    if (props.file.url) {
      const tokens = props.file.url.split('/');
      if (tokens.length > 0) {
        fileName = tokens[tokens.length - 1];
      }
    } else if (props.file !== null) {
      fileName = props.file.name;
    }

    let extension = this.getExtension(fileName);
    switch (extension) {
      case 'pdf':
        icon = this.renderIcon(props.darkMode ? pdfDarkSvg : pdfSvg, 'pdf');
        break;

      case 'doc':
      case 'docx':
        icon = this.renderIcon(props.darkMode ? docDarkSvg : docSvg, 'doc');
        break;

      case 'xls':
      case 'xlsx':
        icon = this.renderIcon(props.darkMode ? xlsDarkSvg : xlsSvg, 'xls');
        break;

      case 'jpeg':
      case 'jpg':
      case 'png':
      case 'heic':
        icon = this.renderIcon(props.darkMode ? imageDarkSvg : imageSvg, 'pic');
        break;

      default:
        icon = this.renderIcon(props.darkMode ? docDarkSvg : docSvg, 'doc');
        break;
    }

    return icon;
  };

  getExtension = (fileName) => {
    const fileNameTokes = fileName.split('.');
    if (fileNameTokes.length > 0) {
      return fileNameTokes[fileNameTokes.length - 1].toLowerCase();
    }
  };

  getUrl = () => {
    if (this.props.file.url !== null) {
      return `${this.props.file.url}?time=${moment().unix()}`;
    } else if (
      this.props.file !== null &&
      this.props.file.hasOwnProperty('preview')
    ) {
      return this.props.file.preview;
    } else {
      return null;
    }
  };

  showPdf = () => {
    this.setState({
      pdfUrl: this.getUrl(this.props),
      showDocumentPdfViewer: true,
    });
  };

  handleDocumentPdfViewerClose = () => {
    this.setState({
      pdfUrl: null,
      showDocumentPdfViewer: false,
    });
    if (this.props.createdBluebeamSession) {
      bluebeamApi.generateSnapshot(this.props.file.id);
      this.props.onCreatedBluebeamSnapshot();
    }
  };

  getRemoveIcon = (props) => {
    return (
      <img
        className="document-link-remove"
        src={props.darkMode ? removeDarkSvg : removeSvg}
        alt="remove"
        onClick={(event) => this.handleRemove(props, event)}
      />
    );
  };

  handleRemove = (props, event) => {
    event.stopPropagation();
    props.onRemove(event);
  };

  render() {
    const props = this.props;
    const fileName = this.getName(props);
    const extension = this.getExtension(fileName);

    return (
      <React.Fragment>
        <div
          className={`document-link ${props.darkMode ? 'dark-mode' : ''} ${
            props.readOnly ? 'read-only' : ''
          }`}
        >
          {props.showIcon &&
            props.iconPosition === 'left' &&
            this.getIcon(props)}
          {props.onRemove &&
            props.iconPosition === 'right' &&
            this.getRemoveIcon(props)}

          <div className="document-link-label">
            {props.file.id && extension === 'pdf' ? (
              /* eslint-disable jsx-a11y/anchor-is-valid */
              <a
                className={!props.showLink ? 'not-active' : null}
                onClick={this.showPdf}
              >
                {fileName}
              </a>
            ) : (
              <a
                className={!props.showLink ? 'not-active' : null}
                href={this.getUrl(props)}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(event) => event.stopPropagation()} // to use inside SimpleTable (which prevents defaults on tr clicks)
              >
                {fileName}
              </a>
            )}
          </div>

          {props.showIcon &&
            props.iconPosition === 'right' &&
            this.getIcon(props)}
          {props.onRemove &&
            props.iconPosition === 'left' &&
            this.getRemoveIcon(props)}
        </div>

        {props.showInModal && props.file.id && extension === 'pdf' && (
          <React.Fragment>
            <PdfViewerBluebeam
              content={{
                source: this.state.pdfUrl,
                type: 'url',
              }}
              show={this.state.showDocumentPdfViewer}
              onClose={this.handleDocumentPdfViewerClose}
              documentId={props.file.id}
              fileName={fileName}
              pdftronIntegration={true}
              bbIntegration={true}
              blueBeamLink={null}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    createdBluebeamSession: getCreatedBluebeamSession(state),
    darkMode: getDarkMode(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCreatedBluebeamSnapshot: () => {
    dispatch(createdBluebeamSnapshot());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentLinkBluebeam);
