import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormModal from 'components/shared/modal/FormModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import questionMark from 'images/questionMark.svg';
import './AddProjectReportLog.css';

class ProjectReportSaveDraftModal extends Component {
  render() {
    const { show, onClose, onConfirm, onCloseModal } = this.props;
    return (
      <FormModal
        id="project-report-save-draft-modal"
        className="project-report-save-draft-modal-view"
        show={show}
        onClose={onCloseModal}
        subTitle="Save Draft"
      >
        <div className="modal-body-section">
          <img src={questionMark} alt="Question Mark Icon" />
          <div className="text-section">
            This item contains content that has not been saved. Would you like
            to save this information?
          </div>
          <div className="buttons-section">
            <Button
              key="disgard-button"
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.WHITE}
              label="Discard Changes"
              onClick={onClose}
            />
            <div className="buttons-separate" />
            <Button
              key="save-draft-button"
              type={BUTTON_TYPES.CONFIRMATION}
              color={BUTTON_COLORS.GREEN}
              label="Save Draft"
              onClick={onConfirm}
            />
          </div>
        </div>
      </FormModal>
    );
  }
}

ProjectReportSaveDraftModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onCloseModal: PropTypes.func,
};

ProjectReportSaveDraftModal.defaultProps = {
  show: false,
  onClose: () => null,
  onConfirm: () => null,
  onCloseModal: () => null,
};

export default ProjectReportSaveDraftModal;
