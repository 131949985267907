import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import AWS from 'aws-sdk';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';
import { getDarkMode } from 'selectors/theme';
import { goToProjectDetails } from 'components/admin/projects/store/actions';
import DocumentPermissions from 'permissions/document-permissions';
import { getSharedAlbums, getSharedFolders } from './store/selectors';
import './SharedFolders.css';
import album from './images/album.svg';
import albumDark from './images/album-dark.svg';
import folder from './images/folder.svg';
import folderDark from './images/folder-dark.svg';
import disabledFolder from './images/disabled-folder.svg';
import disabledFolderDark from './images/disabled-folder-dark.svg';

AWS.config.region = 'us-east-1';
const s3 = new AWS.S3();

const DISABLED_FOLDERS = 5;

const SharedFolders = (props) => {
  const {
    currentUser,
    currentProject,
    sharedAlbums,
    sharedFolders,
    dispatch,
    darkMode,
  } = props;
  const [folders, setFolders] = useState([]);

  const list = [
    ...(sharedAlbums || []).map((album) => ({ ...album, type: 'album' })),
    ...(folders || []).map((folder) => ({ ...folder, type: 'folder' })),
  ].sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    if (sharedFolders && sharedFolders.length >= 0) fetchS3();
  }, [sharedFolders]);

  const fetchS3 = () => {
    var params = {
      Bucket: `${process.env.REACT_APP_ADVANCED_DOCUMENTS_S3_BUCKET}`,
      Prefix: currentProject.advanced_document_view_root_directory,
      Delimiter: '/',
    };

    return s3.makeUnauthenticatedRequest(
      'listObjectsV2',
      params,
      fetchS3Callback
    );
  };

  const fetchS3Callback = (err, data) => {
    if (err) return;

    const result = [];
    data.CommonPrefixes.forEach(({ Prefix: folder_key }) => {
      const advancedFolder = sharedFolders.find(
        (doc) => doc.folder_key === folder_key
      );

      if (
        !advancedFolder ||
        (advancedFolder.visibility === 'private' &&
          DocumentPermissions.canViewPrivateFolders(
            advancedFolder,
            currentUser,
            currentProject
          )) ||
        (advancedFolder.visibility === 'shared' &&
          DocumentPermissions.canViewSharedFolders(
            advancedFolder,
            currentUser,
            currentProject
          )) ||
        advancedFolder.visibility === 'public'
      ) {
        result.push({ folder_key, name: folder_key.split('/')[1] });
      }
    });

    setFolders(result);
  };

  const getIcon = (type) => {
    switch (type) {
      case 'album':
        return darkMode ? albumDark : album;
      case 'disabled':
        return darkMode ? disabledFolderDark : disabledFolder;
      default:
        return darkMode ? folderDark : folder;
    }
  };

  const makeOpenFolderRequest = (folder) => {
    switch (folder.type) {
      case 'album':
        return { albumId: folder.id };
      case 'folder':
        return { documentation: true, folder: folder.folder_key };
      default:
        return null;
    }
  };

  const handleOpenFolder = (folder) => {
    dispatch(
      goToProjectDetails(
        currentProject.id,
        makeOpenFolderRequest(folder),
        'documents'
      )
    );
  };

  const renderFolder = (folder, index) => {
    const { type, name } = folder;
    return (
      <div
        key={index}
        className={`shared-folder ${type}-folder`}
        onClick={type !== 'disabled' ? () => handleOpenFolder(folder) : null}
      >
        <div className="shared-folder-icon">
          <img src={getIcon(type)} alt={type} />
        </div>
        <div className="shared-folder-details">
          <div className="shared-folder-name">{name}</div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={classnames('project-dashboard-shared-folders', {
        'dark-mode': darkMode,
        disabled: list.length === 0,
      })}
    >
      <div className="shared-folders-title">Shared Folders</div>
      <div className="shared-folders-list">
        {list.length > 0
          ? list.map(renderFolder)
          : [...Array(DISABLED_FOLDERS)].map((_, index) =>
              renderFolder({ type: 'disabled' }, index)
            )}
      </div>
    </div>
  );
};

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    sharedAlbums: getSharedAlbums(state),
    sharedFolders: getSharedFolders(state),
    darkMode: getDarkMode(state),
  };
})(SharedFolders);
