import { createSelector } from 'reselect';

export const getSubmittals = createSelector(
  (state) => state.projects.scenes.details.scenes.submittalLog.data.submittals,
  (submittals) => {
    return submittals;
  }
);

export const getSubmittalsTradeCodes = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.submittalLog.data.submittalsTradeCodes,
  (submittalsTradeCodes) => {
    return submittalsTradeCodes;
  }
);

export const getTotal = createSelector(
  (state) => state.projects.scenes.details.scenes.submittalLog.data.total,
  (total) => {
    return total;
  }
);

export const getCurrentPage = createSelector(
  (state) => state.projects.scenes.details.scenes.submittalLog.data.currentPage,
  (currentPage) => {
    return currentPage;
  }
);

export const getCurrentStatus = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.submittalLog.data.currentStatus,
  (currentStatus) => {
    return currentStatus;
  }
);

export const getDueDateFilter = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.submittalLog.data.dueDateFilter,
  (dueDateFilter) => {
    return dueDateFilter;
  }
);

export const getTradeCodeFilter = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.submittalLog.data.tradeCodeFilter,
  (tradeCodeFilter) => {
    return tradeCodeFilter;
  }
);

export const getPageSize = createSelector(
  (state) => state.projects.scenes.details.scenes.submittalLog.data.pageSize,
  (pageSize) => {
    return pageSize;
  }
);

export const getSubmittalTabSelected = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.submittalLog.ui.submittalTabSelected,
  (submittalTabSelected) => {
    return submittalTabSelected;
  }
);

export const getSubmittalModalShow = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.submittalLog.ui.submittalModalShow,
  (submittalModalShow) => {
    return submittalModalShow;
  }
);

export const getSubmittalForm = createSelector(
  (state) => state.projects.scenes.details.scenes.submittalLog.ui.submittalForm,
  (submittalForm) => {
    return submittalForm;
  }
);

export const getSubmittalReadOnly = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.submittalLog.ui.submittalReadOnly,
  (submittalReadOnly) => {
    return submittalReadOnly;
  }
);

export const getSubmittalLeadTimeReadOnly = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.submittalLog.ui
      .submittalLeadTimeReadOnly,
  (submittalLeadTimeReadOnly) => {
    return submittalLeadTimeReadOnly;
  }
);

export const getSubmittalGcAcceleration = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.submittalLog.ui
      .submittalGcAcceleration,
  (submittalGcAcceleration) => {
    return submittalGcAcceleration;
  }
);

export const getShowTransmittalPdf = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.submittalLog.ui.showTransmittalPdf,
  (showTransmittalPdf) => {
    return showTransmittalPdf;
  }
);

export const getTransmittalPdf = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.submittalLog.data.transmittalPdf,
  (transmittalPdf) => {
    return transmittalPdf;
  }
);

export const getCurrentSubmittal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.submittalLog.data.currentSubmittal,
  (currentSubmittal) => {
    return currentSubmittal;
  }
);

export const getSubmittalNotes = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.submittalLog.data.submittalNotes,
  (submittalNotes) => {
    return submittalNotes;
  }
);

export const getSubmittalLogPdfViewer = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.submittalLog.ui.submittalLogPdfViewer,
  (submittalLogPdfViewer) => {
    return submittalLogPdfViewer;
  }
);

export const getSubmittalLogPdf = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.submittalLog.data.submittalLogPdf,
  (submittalLogPdf) => {
    return submittalLogPdf;
  }
);

export const getSubmittalPdfViewer = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.submittalLog.ui.submittalPdfViewer,
  (submittalPdfViewer) => {
    return submittalPdfViewer;
  }
);

export const getSubmittalShowErrorMessage = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.submittalLog.ui
      .submittalShowErrorMessage,
  (submittalShowErrorMessage) => {
    return submittalShowErrorMessage;
  }
);

export const getSubmittalCreatedRevisionModalShow = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.submittalLog.ui
      .submittalCreatedRevisionModalShow,
  (submittalCreatedRevisionModalShow) => {
    return submittalCreatedRevisionModalShow;
  }
);

export const getSubmittalResponseModalShow = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.submittalLog.ui
      .submittalResponseModalShow,
  (submittalResponseModalShow) => {
    return submittalResponseModalShow;
  }
);

export const getSubmittalResponsesViewModalShow = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.submittalLog.ui
      .submittalResponsesViewModalShow,
  (submittalResponsesViewModalShow) => {
    return submittalResponsesViewModalShow;
  }
);

export const getRevisionSubmittal = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.submittalLog.data.revisionSubmittal,
  (revisionSubmittal) => {
    return revisionSubmittal;
  }
);
