import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Col, Grid, Row, Button } from 'react-bootstrap';
import {
  InputLabel,
  InputAdornment,
  Input,
  IconButton,
  FormControl,
} from '@mui/material';
import Loader from 'components/shared/loader/LoginLoader';
import { authApi } from '../../../services/api/auth-api';
import queryString from 'query-string';
import { Link, withRouter } from 'react-router-dom';
import { getLoginBackgroundImage } from '../../../actions/loginBackgroundImage';
import eyePng from '../login/eye.png';
import eyeSlachPng from '../login/eyeSlash.png';
import logoPng from '../login/logo.png';
import './ResetPassword.css';
import { browserClassname } from 'services/utils/browser-util';
import SupportLink from '../SupportLink';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirmation: '',
      errors: [],
      showPassword: false,
      showPasswordConfirmation: false,
      loadImage: true,
      loadImageError: false,
      noPassword: false,
      noPasswordConfirmation: false,
    };

    const params = queryString.parse(this.props.location.search);
    this.clientId = params['client_id'];
    this.token = params['token'];
    this.uid = params['uid'];
  }

  componentDidMount() {
    if (!this.props.imageUrl) {
      this.props.getBackgroundImage();
      this.setState({
        loadImage: false,
        loadImageError: false,
      });
    }
  }

  componentDidUpdate() {
    if (
      (this.props.imageUrl || this.props.imageUrl === '') &&
      !this.state.loadImage
    ) {
      const imageLoader = new Image();
      imageLoader.src = this.props.imageUrl;
      imageLoader.onload = () => {
        this.setState({
          loadImage: true,
          loadImageError: false,
        });
      };

      imageLoader.onerror = () => {
        this.setState({
          loadImage: true,
          loadImageError: true,
        });
      };
    }
  }

  handlePasswordChange = (event) => {
    this.setState({ noPassword: false });
    this.setState({ password: event.target.value });
  };

  handlePasswordConfirmationChange = (event) => {
    this.setState({ noPasswordConfirmation: false });
    this.setState({ passwordConfirmation: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.state.password || !this.state.passwordConfirmation) {
      this.setState({
        noPassword: !this.props.password,
        noPasswordConfirmation: !this.props.passwordConfirmation,
      });
      return;
    }

    authApi
      .resetPassword(
        this.state.password,
        this.state.passwordConfirmation,
        this.clientId,
        this.token,
        this.uid
      )
      .then((response) => {
        if (response.ok) {
          this.props.history.push('/login');
        } else {
          this.setState({
            errors: response.errors.full_messages,
          });
        }
      });
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleClickShowPasswordConfirmation = () => {
    this.setState({
      showPasswordConfirmation: !this.state.showPasswordConfirmation,
    });
  };

  handleMouseDownPasswordConfirmation = (event) => {
    event.preventDefault();
  };

  render() {
    const backgroundImage =
      !this.state.loadImageError && this.props.imageUrl
        ? { backgroundImage: 'url(' + this.props.imageUrl + ')' }
        : {};
    return this.state.loadImage ? (
      <div className="client reset-password" style={backgroundImage}>
        <Grid className="max-height">
          <div className="align-center">
            <Col md={9}>
              <div
                className={browserClassname({
                  firefox: 'form-container-firefox',
                  default: 'form-container',
                })}
              >
                <form onSubmit={this.handleSubmit}>
                  <Col md={7} sm={9} className="left-panel">
                    <Row>
                      <Col md={12}>
                        <div className="logo">
                          <img src={logoPng} alt="Logo" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className="title">
                          <span className="title-1">Reset your password</span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FormControl
                          fullWidth
                          className={this.state.noPassword ? 'label-error' : ''}
                        >
                          <InputLabel htmlFor="standard-adornment-password">
                            Password
                          </InputLabel>
                          <Input
                            id="standard-adornment-password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            value={this.state.password}
                            onChange={this.handlePasswordChange}
                            error={
                              this.state.errors.length > 0 ||
                              this.state.noPassword
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                  onMouseDown={this.handleMouseDownPassword}
                                >
                                  {
                                    <img
                                      src={
                                        this.state.showPassword
                                          ? eyeSlachPng
                                          : eyePng
                                      }
                                      className="eye"
                                      alt="eye"
                                    />
                                  }
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Col>

                      <Col md={12} className="input-margin-bottom" />

                      <Col md={12}>
                        <FormControl
                          fullWidth
                          className={this.state.noPassword ? 'label-error' : ''}
                        >
                          <InputLabel htmlFor="standard-adornment-password-confirmation">
                            Password confirmation
                          </InputLabel>
                          <Input
                            id="standard-adornment-password-confirmation"
                            type={
                              this.state.showPasswordConfirmation
                                ? 'text'
                                : 'password'
                            }
                            value={this.state.passwordConfirmation}
                            onChange={this.handlePasswordConfirmationChange}
                            error={
                              this.state.errors.length > 0 ||
                              this.state.noPasswordConfirmation
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={
                                    this.handleClickShowPasswordConfirmation
                                  }
                                  onMouseDown={
                                    this.handleMouseDownPasswordConfirmation
                                  }
                                >
                                  {
                                    <img
                                      src={
                                        this.state.showPasswordConfirmation
                                          ? eyeSlachPng
                                          : eyePng
                                      }
                                      className="eye"
                                      alt="eye"
                                    />
                                  }
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Col>

                      {this.state.errors.length > 0 && (
                        <Col md={12}>
                          <div className="errors">{this.state.errors}</div>
                        </Col>
                      )}

                      {this.showWarning() && (
                        <Col md={12} className="warning-container">
                          <Alert bsStyle="warning">
                            Some parameters are missing, please go{' '}
                            <strong>
                              <Link to="/recover-password">here</Link>
                            </strong>{' '}
                            to properly recover your password.
                          </Alert>
                        </Col>
                      )}

                      <Col className="mobile-container-button">
                        <Button
                          type="submit"
                          className={`btn-continue button-reset`}
                        >
                          <span className="text-btn">Continue</span>
                          <span className="arrow" />
                        </Button>
                      </Col>

                      <Col md={6} mdOffset={3}>
                        <div style={{ height: 40 }} />
                        <SupportLink />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={5} sm={3} className="right-panel">
                    <Button
                      type="submit"
                      className={`btn-continue button-reset`}
                    >
                      <span className="text-btn">Continue</span>
                      <span className="arrow" />
                    </Button>
                  </Col>
                </form>
              </div>
            </Col>
          </div>
        </Grid>
      </div>
    ) : (
      <Loader loadImage={this.state.loadImage} />
    );
  }

  showWarning = () => {
    return (
      this.clientId === '' ||
      this.token === '' ||
      this.uid === '' ||
      this.uid === undefined
    );
  };
}

export default withRouter(
  connect(
    (state) => {
      return {
        imageUrl: state.loginBackgroundImage.url,
      };
    },
    (dispatch) => {
      return {
        getBackgroundImage: () => dispatch(getLoginBackgroundImage()),
      };
    }
  )(ResetPassword)
);
