import {
  SIDEBAR_SELECT_ROUTE,
  HIDE_SIDE_BAR_CHANGE,
  TOGGLE_NEW_SERVICE_WORK,
} from '../../../actions/admin/sidebar/action-types';
import { LOCATION_CHANGE } from 'react-router-redux';
import Route from 'route-parser';

const initialState = {
  activeRoute: null,
  hideSideBar: false,
};

export default function sidebarReducer(state = initialState, action) {
  switch (action.type) {
    case SIDEBAR_SELECT_ROUTE:
      return {
        ...state,
        activeRoute: action.payload,
      };

    case HIDE_SIDE_BAR_CHANGE:
      return {
        ...state,
        hideSideBar: action.payload,
      };

    case LOCATION_CHANGE:
      const route = new Route('/admin/:route(/*rest)');
      const result = route.match(action.payload.pathname);
      if (result) {
        return {
          ...state,
          activeRoute: result.route,
        };
      } else {
        return state;
      }

    case TOGGLE_NEW_SERVICE_WORK:
      const currentState = !!state.showNewServiceWorkModal;
      return {
        ...state,
        showNewServiceWorkModal: !currentState,
      };

    default:
      return state;
  }
}
