import { push } from 'react-router-redux';
import { showErrorMessage } from 'actions/messages';
import { purchaseOrdersApi } from 'services/api/purchase-orders-api';
import {
  PURCHASE_ORDERS_CURRENT_PAGE_SET,
  PURCHASE_ORDERS_SET,
  PURCHASE_ORDERS_SHOW_MODAL_SET,
} from 'components/admin/purchase-orders/store/action-types';

export const baseRoutePath = '/admin/purchase-orders';

export function changePage(newPage) {
  return (dispatch) => {
    return dispatch(getPurchaseOrders(newPage)).then((response) => {
      if (response.ok) {
        dispatch(setCurrentPage(newPage));
      }
    });
  };
}

export function createPurchaseOrder(values) {
  return (dispatch) => {
    return purchaseOrdersApi.createPurchaseOrders(values).then((response) => {
      if (response.ok) {
        dispatch(setShowCreateModal(false));
        dispatch(changePage(1)); // load orders again
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

export function getPurchaseOrders(page) {
  return (dispatch) => {
    return purchaseOrdersApi.getPurchaseOrders(page).then((response) => {
      if (response.ok) {
        dispatch(setPurchaseOrders(response.purchaseOrders));
      } else {
        dispatch(showErrorMessage(response.errors));
      }

      return response;
    });
  };
}

function setCurrentPage(page) {
  return {
    type: PURCHASE_ORDERS_CURRENT_PAGE_SET,
    payload: page,
  };
}

function setPurchaseOrders(purchaseOrders) {
  return {
    type: PURCHASE_ORDERS_SET,
    payload: purchaseOrders,
  };
}

export function setShowCreateModal(value) {
  return {
    type: PURCHASE_ORDERS_SHOW_MODAL_SET,
    payload: value,
  };
}

export function goToPurchaseOrders() {
  return (dispatch) => {
    dispatch(push(baseRoutePath));
  };
}
