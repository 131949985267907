export const DIRECTORY_SET = 'DIRECTORY_SET';
export const DIRECTORY_PDF_SET = 'DIRECTORY_PDF_SET';
export const SHOW_NEW_CONTRIBUTOR_MODAL_SET = 'SHOW_NEW_CONTRIBUTOR_MODAL_SET';
export const SHOW_EDIT_USER_MODAL_SET = 'SHOW_EDIT_USER_MODAL_SET';
export const NEW_CONTRIBUTOR_MODAL_INIT = 'NEW_CONTRIBUTOR_MODAL_INIT';
export const COMPANY_USERS_SET = 'COMPANY_USERS_SET';
export const PROJECT_CONTRIBUTORS_UPDATE = 'PROJECT_CONTRIBUTORS_UPDATE';
export const PROJECT_CONTRIBUTORS_UPDATE_WITH_EDIT_USER =
  'PROJECT_CONTRIBUTORS_UPDATE_WITH_EDIT_USER';
export const SHOW_NEW_COMPANY_FORM_SET = 'SHOW_NEW_COMPANY_FORM_SET';
export const SHOW_SELECT_USERS_FORM_SET = 'SHOW_SELECT_USERS_FORM_SET';
export const SHOW_INVITE_USER_FORM_SET = 'SHOW_INVITE_USER_FORM_SET';
export const SHOW_DIRECTORY_PDF_VIEWER_SET = 'SHOW_DIRECTORY_PDF_VIEWER_SET';
export const CURRENT_CONTRIBUTOR_SET = 'CURRENT_CONTRIBUTOR_SET';
export const NEW_CONTRIBUTOR_TYPE_SET = 'NEW_CONTRIBUTOR_TYPE_SET';
export const CONTRIBUTOR_SETTINGS_TOGGLE = 'CONTRIBUTOR_SETTINGS_TOGGLE';
export const USER_SETTINGS_TOGGLE = 'USER_SETTINGS_TOGGLE';
export const PROJECT_CONTRIBUTORS_DELETE = 'PROJECT_CONTRIBUTORS_DELETE';
export const SET_ROLES = 'SET_ROLES';
export const SET_SHOW_NOTIFICATION_SETTINGS_MODAL =
  'SET_SHOW_NOTIFICATION_SETTINGS_MODAL';
