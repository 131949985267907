import {
  SHOW_ADD_DAILY_REPORT_SET,
  SHOW_SIMPLIFIED_ADD_DAILY_REPORT_SET,
  SHOW_PROJECT_REPORT_PERMISSIONS_SET,
  SET_ACTIVE_CONTRIBUTOR,
  SET_REVIEW_FORM,
  SET_REPORT_SUBMITTED,
  SET_DAILY_REPORT_LOG_PDF_VIEWER,
  SET_EDIT_MODE,
  SET_REPORT_PDF_VIEWER,
  SET_MEMBER_FORM,
  SET_CURRENT_PAGE,
  SET_REPORTS_PAGE_SIZE,
  SET_REPORTS_TOTAL,
  SET_DELIVERY,
  DELETE_DELIVERY,
  ADD_EXTRA_INPUT,
  REMOVE_DELIVERIES_EXTRA_INPUT,
  SET_DAILY_REPORT_TAB,
  SET_SHOW_FIELD_WORK_ORDER,
  SET_FIELD_WORK_ORDER_EDIT,
  SET_SHOW_FIELD_WORK_ORDER_ERROR,
  SET_DAILY_REPORT_ADD_FLOOR,
  SET_PROJECT_REPORT_PDF_VIEWER,
  OPEN_PROJECT_REPORT_DETAILS,
  SET_PROJECT_REPORTS_PERMISSIONS,
  SET_PROJECT_PHOTO_AND_DOCUMENTS_FILTERING_ACTIVE,
  SET_PROJECT_REPORT_PREVIEW,
  SET_PROJECT_REPORT_INITIAL_TAB,
  SET_SIMPLIFIED_DAILY_REPORT_FORM,
} from 'components/admin/projects/details/project-daily-report-log/store/action-types';
import moment from 'moment';

const initialState = {
  showAddDailyReportModal: false,
  showProjectReportPermissionsModal: false,
  showFieldWorkOrderModal: false,
  showReviewForm: false,
  isSubmitted: false,
  activeContributor: 0,
  dailyReportLogPdfViewer: false,
  projectReportPdfViewer: false,
  editMode: false,
  reportPdfViewer: false,
  memberForm: false,
  currentPage: 1,
  reportsPageSize: 1,
  reportsTotal: 0,
  fieldWorkOrdersPageSize: 1,
  fieldWorkOrdersTotal: 0,
  delivery: [],
  extraInputs: [
    { name: 'inspections', label: 'Inspections' },
    { name: 'visitors', label: 'Meetings/Visitors' },
    { name: 'phone_calls', label: 'Job Scheduling/Phone Calls' },
  ],
  dailyReportsTabSelected: 1,
  fieldWorkOrderEdit: false,
  showFieldWorkOrderError: false,
  addFloor: false,
  isSafetyAccepted: false,
  projectReportOpenDetails: { value: false, id: 0 },
  projectReportsPermissions: [],
  projectPhotosAndDocumentsFilteringActive: false,
  projectReportPreview: null,
  initialTab: null,
  showAddSimplifiedDailyReportModal: false,
  simplifiedDailyReportForm: {
    id: null,
    images: [],
    deleted_images: [],
    safety_issues: false,
    is_simplified_daily_report: true,
  },
};

function addExtraInput(action, state) {
  const extraInputs = state.extraInputs;
  if (!extraInputs.find((option) => option.name === action.payload.name)) {
    extraInputs.unshift(action.payload);
  }
  return [...extraInputs];
}

function deliveryOptions(action, state) {
  let delivery;
  if (action.payload instanceof Array) {
    delivery = action.payload.map((element) => {
      let label = `${element.division}-${element.number} ${element.title}`;
      if (element.field_delivery) {
        label += ` - ${moment(element.field_delivery).format('MM/DD/YYYY')}`;
      }
      return {
        value: element.id,
        label,
      };
    });
  } else {
    delivery = state.delivery;
    if (!delivery.find((option) => option.value === action.payload.value)) {
      delivery.push(action.payload);
    }
  }
  return [...delivery];
}

function deleteDelivery(action, state) {
  const delivery = state.delivery;
  delivery.splice(action.payload, 1);
  return [...delivery];
}

export default function projectDailyReportLogUiReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case SHOW_ADD_DAILY_REPORT_SET:
      return {
        ...state,
        showAddDailyReportModal: action.payload,
      };
    case SHOW_SIMPLIFIED_ADD_DAILY_REPORT_SET:
      return {
        ...state,
        showAddSimplifiedDailyReportModal: action.payload,
      };
    case SET_SIMPLIFIED_DAILY_REPORT_FORM:
      return {
        ...state,
        simplifiedDailyReportForm: action.payload,
      };
    case SHOW_PROJECT_REPORT_PERMISSIONS_SET:
      return {
        ...state,
        showProjectReportPermissionsModal: action.payload,
      };

    case SET_SHOW_FIELD_WORK_ORDER:
      return {
        ...state,
        showFieldWorkOrderModal: action.payload,
      };

    case SET_ACTIVE_CONTRIBUTOR:
      return {
        ...state,
        activeContributor: action.payload,
      };

    case SET_REVIEW_FORM:
      return {
        ...state,
        showReviewForm: action.payload,
      };

    case SET_REPORT_SUBMITTED:
      return {
        ...state,
        isSubmitted: action.payload,
      };

    case SET_DAILY_REPORT_LOG_PDF_VIEWER:
      return {
        ...state,
        dailyReportLogPdfViewer: action.payload,
      };

    case SET_EDIT_MODE:
      return {
        ...state,
        editMode: action.payload,
      };

    case SET_REPORT_PDF_VIEWER:
      return {
        ...state,
        reportPdfViewer: action.payload,
      };

    case SET_MEMBER_FORM:
      return {
        ...state,
        memberForm: action.payload,
      };

    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };

    case SET_REPORTS_TOTAL:
      return {
        ...state,
        reportsTotal: action.payload,
      };

    case SET_REPORTS_PAGE_SIZE:
      return {
        ...state,
        reportsPageSize: action.payload,
      };

    case SET_DELIVERY:
      return {
        ...state,
        delivery: deliveryOptions(action, state),
      };

    case ADD_EXTRA_INPUT:
      return {
        ...state,
        extraInputs: addExtraInput(action, state),
      };

    case DELETE_DELIVERY:
      return {
        ...state,
        delivery: deleteDelivery(action, state),
      };

    case REMOVE_DELIVERIES_EXTRA_INPUT: {
      return {
        ...state,
        extraInputs: state.extraInputs.filter(
          (element) => element.name !== 'deliveries'
        ),
      };
    }

    case SET_DAILY_REPORT_TAB:
      return {
        ...state,
        dailyReportsTabSelected: action.payload,
      };

    case SET_FIELD_WORK_ORDER_EDIT:
      return {
        ...state,
        fieldWorkOrderEdit: action.payload,
      };

    case SET_SHOW_FIELD_WORK_ORDER_ERROR:
      return {
        ...state,
        showFieldWorkOrderError: action.payload,
      };

    case SET_DAILY_REPORT_ADD_FLOOR:
      return {
        ...state,
        addFloor: action.payload,
      };

    case SET_PROJECT_REPORT_PDF_VIEWER:
      return {
        ...state,
        projectReportPdfViewer: action.payload,
      };

    case OPEN_PROJECT_REPORT_DETAILS:
      return {
        ...state,
        projectReportOpenDetails: action.payload,
      };

    case SET_PROJECT_REPORTS_PERMISSIONS:
      return {
        ...state,
        projectReportsPermissions: action.payload,
      };

    case SET_PROJECT_PHOTO_AND_DOCUMENTS_FILTERING_ACTIVE:
      return {
        ...state,
        projectPhotosAndDocumentsFilteringActive: action.payload,
      };

    case SET_PROJECT_REPORT_PREVIEW:
      return {
        ...state,
        projectReportPreview: action.payload.pdf,
      };

    case SET_PROJECT_REPORT_INITIAL_TAB:
      return {
        ...state,
        initialTab: action.payload,
      };

    default:
      return state;
  }
}
