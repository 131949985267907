import React from 'react';
import PropTypes from 'prop-types';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import ImageUpload from 'components/shared/image-upload/ImageUpload';
import DocumentLink from 'components/shared/document/DocumentLink';
import DocumentUpload, {
  FILE_TYPES,
} from 'components/shared/document-upload/DocumentUpload';
import { imageExtensions } from 'services/utils/files-util';
import {
  setImages,
  removeImage,
  getImages,
  setDocuments,
  removeDocument,
} from 'services/utils/attachments-util';

export default function ChatButtons(props) {
  const { newNote, handleFormChange, handleSubmit } = props;

  return (
    <React.Fragment>
      {newNote.documents && newNote.documents.length ? (
        <div className="chat-upload-links">
          {newNote.documents.map((doc, index) => {
            return (
              <DocumentLink
                file={doc}
                url={doc.url}
                onRemove={() =>
                  removeDocument(index, newNote, handleFormChange)
                }
              />
            );
          })}
        </div>
      ) : null}
      <DocumentUpload
        fileType={FILE_TYPES.ANY}
        onDrop={(files) => setDocuments(files, newNote, handleFormChange)}
      />
      <ImageUpload
        extensions={imageExtensions}
        multiple
        onUpload={(files) => setImages(files, newNote, handleFormChange)}
        onRemove={(index) => removeImage(index, newNote, handleFormChange)}
        images={getImages(newNote)}
      />
      <Button
        className="send-button"
        type={BUTTON_TYPES.CONFIRMATION}
        color={BUTTON_COLORS.GREEN}
        label="Send"
        onClick={handleSubmit}
      />
    </React.Fragment>
  );
}

ChatButtons.propTypes = {
  newNote: PropTypes.shape(),
  handleFormChange: PropTypes.func,
  handleSubmit: PropTypes.func,
};
