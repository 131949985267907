import {
  SIDEBAR_SELECT_ROUTE,
  HIDE_SIDE_BAR_CHANGE,
  TOGGLE_NEW_SERVICE_WORK,
} from './action-types';
import { push } from 'react-router-redux';

export function selectRoute(id, route) {
  return (dispatch) => {
    dispatch(routeChange(id));
    dispatch(push(route));
  };
}

export function routeChange(id) {
  return {
    type: SIDEBAR_SELECT_ROUTE,
    payload: id,
  };
}

export function setHideSideBar(value) {
  return {
    payload: value,
    type: HIDE_SIDE_BAR_CHANGE,
  };
}

export function toggleNewServiceWorkModal() {
  return {
    payload: '',
    type: TOGGLE_NEW_SERVICE_WORK,
  };
}
