import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PdfViewer from 'components/shared/pdf-viewer/PdfViewer';
import { formatNumber } from 'services/utils/text-util';
import { getCurrentUser } from 'selectors/authentication';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/selectors';
import {
  getRfis,
  getRfiModalShow,
  getCurrentRfi,
  getRfiReadOnly,
  getRfiGcAcceleration,
  getRfiPdfViewer,
} from 'components/admin/projects/details/project-rfi-log/store/selectors';
import { sendProjectResourceEmails } from 'components/admin/projects/details/store/actions';
import {
  setRfiModalShow,
  setCurrentRfi,
  setRfiReadOnly,
  setRfiPdfViewer,
} from 'components/admin/projects/details/project-rfi-log/store/actions';
import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { loadDirectoryRequest } from 'components/admin/projects/details/project-directory/store/actions';
import ProjectEmailModal from 'components/admin/projects/details/project-email-modal/ProjectEmailModal';
import RfiModal from 'components/admin/projects/details/project-rfi-log/RfiModal';
import moment from 'moment';

class RfiModalContainer extends Component {
  static propTypes = {
    onClose: PropTypes.func,
  };

  static defaultProps = {
    onClose: null,
  };

  componentDidMount() {
    if (this.props.currentProject) this.loadDirectory();
  }

  loadDirectory = () => {
    const { currentProject, directory, dispatch } = this.props;
    if (!directory || directory.project_id !== currentProject.id) {
      dispatch(loadDirectoryRequest(currentProject.id));
    }
  };

  handleCloseRfiModal = () => {
    const { onClose, dispatch } = this.props;
    dispatch(setRfiModalShow(false));
    dispatch(setCurrentRfi(null));
    dispatch(setRfiReadOnly(false));
    onClose && onClose();
  };

  handleRfiPdfViewerClose = () => {
    this.props.dispatch(setRfiPdfViewer(false));
  };

  handleSendEmail = (emailsData) => {
    this.props.dispatch(
      sendProjectResourceEmails(this.props.currentRfi.id, 'Rfi', emailsData)
    );
  };

  buildEmailModalSubject = () => {
    const { currentProject, currentRfi } = this.props;
    return `${currentProject.number} ${
      currentProject.name
    } - RFI ${formatNumber(currentRfi.number)} - ${currentRfi.title}`;
  };

  buildEmailModalMessage = () => {
    const { currentProject, currentRfi } = this.props;
    let location = '';
    if (
      currentProject.project_location &&
      currentProject.project_location.location
    ) {
      const projectLocation = currentProject.project_location.location;
      location = ` at ${projectLocation.street_address_1}, ${projectLocation.city} ${projectLocation.state}`;
    }

    return `Please find the attached RFI ${formatNumber(
      currentRfi.number
    )} for Project ${
      currentProject.name
    }${location}. If you have any questions, please reach out via chat or email.`;
  };

  currentOrNextNumber = () => {
    const { currentRfi, rfis } = this.props;
    return currentRfi
      ? formatNumber(currentRfi.number)
      : formatNumber(rfis.length + 1);
  };

  render() {
    const {
      currentUser,
      currentProject,
      directory,
      currentRfi,
      rfiModalShow,
      rfiReadOnly,
      rfiGcAcceleration,
      rfiPdfViewer,
      showEmailModal,
    } = this.props;
    const contribType = currentProject && currentProject.contributor_type;
    return (
      currentProject && (
        <React.Fragment>
          {rfiModalShow && (
            <RfiModal
              currentUser={currentUser}
              contribType={contribType}
              project={currentProject}
              directory={directory}
              number={this.currentOrNextNumber()}
              show={rfiModalShow}
              currentRfi={currentRfi}
              readOnly={rfiReadOnly}
              gcAcceleration={rfiGcAcceleration}
              handleClose={this.handleCloseRfiModal}
            />
          )}

          {rfiPdfViewer && (
            <PdfViewer
              content={{
                source: `${
                  currentRfi.pdf_document.document.url
                }?time=${moment().unix()}`,
                type: 'url',
              }}
              show={rfiPdfViewer}
              onClose={this.handleRfiPdfViewerClose}
            />
          )}

          {showEmailModal && (
            <ProjectEmailModal
              subTitle={currentProject.name}
              handleSendEmail={this.handleSendEmail}
              projectId={currentProject.id}
              initialSubject={this.buildEmailModalSubject()}
              initialMessage={this.buildEmailModalMessage()}
              showHistoryButton
              itemType="Rfi"
              itemId={currentRfi.id}
            />
          )}
        </React.Fragment>
      )
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    currentProject: getCurrentProject(state),
    directory: getDirectory(state),
    rfis: getRfis(state),
    rfiModalShow: getRfiModalShow(state),
    currentRfi: getCurrentRfi(state),
    rfiReadOnly: getRfiReadOnly(state),
    rfiGcAcceleration: getRfiGcAcceleration(state),
    rfiPdfViewer: getRfiPdfViewer(state),
    showEmailModal: getShowEmailModal(state),
  };
})(RfiModalContainer);
