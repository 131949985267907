import {
  CHANGE_ORDER_TASKS_LOAD_SUCCESS,
  CHANGE_ORDER_TASKS_ADD,
  CHANGE_ORDER_TASKS_UPDATE,
  CHANGE_ORDER_TASK_DELETE,
} from 'components/admin/work-orders/details/change-order-tasks/store/action-types';

const initialState = {
  changeOrderTasks: [],
};

export default function changeOrderTasksDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case CHANGE_ORDER_TASKS_LOAD_SUCCESS:
      return {
        ...state,
        changeOrderTasks: action.payload,
      };

    case CHANGE_ORDER_TASKS_ADD:
      return {
        ...state,
        changeOrderTasks: [...state.changeOrderTasks, action.payload],
      };

    case CHANGE_ORDER_TASKS_UPDATE:
      return {
        ...state,
        changeOrderTasks: state.changeOrderTasks.map((changeOrder) => {
          if (action.payload.id === changeOrder.id) {
            return action.payload;
          } else {
            return changeOrder;
          }
        }),
      };

    case CHANGE_ORDER_TASK_DELETE:
      return {
        ...state,
        changeOrderTasks: state.changeOrderTasks.filter(
          (changeOrder) => action.payload !== changeOrder.id
        ),
      };
    default:
      return state;
  }
}
