import axios from 'axios';
import BaseApi from './base-api';
import numeral from 'numeral';

class QuotesApi extends BaseApi {
  getQuotes(page, waiting, denied, agg, perPage) {
    return axios
      .get(
        this.baseUrl +
          '/quotes?page=' +
          (page !== undefined ? page : 1) +
          (waiting !== undefined ? '&waiting=' + waiting : '') +
          (denied !== undefined ? '&denied=' + denied : '') +
          (agg !== undefined ? '&agg=' + agg : '') +
          (perPage !== undefined ? '&per_page=' + perPage : '')
      )
      .then((response) => {
        return {
          ok: true,
          quotes: {
            content: response.data,
            total: numeral(response.headers['total']).value(),
            totalDenied: numeral(response.headers['total-denied']).value(),
            totalWaiting: numeral(response.headers['total-waiting']).value(),
            pageSize: numeral(response.headers['per-page']).value(),
          },
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getQuotesCount(filters) {
    let query = '';
    Object.keys(filters).forEach((filter, index) => {
      if (index > 0) {
        query += '&';
      }
      query += `${filter}=${filters[filter]}`;
    });

    return axios
      .get(`${this.baseUrl}/quotes/count?${query}`)
      .then((response) => {
        return {
          ok: true,
          count: numeral(response.headers['total']).value(),
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  getQuotesByTask(taskId) {
    return axios
      .get(this.baseUrl + '/quotes?by_task=' + taskId)
      .then((response) => {
        return {
          ok: true,
          quotes: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  counterQuote(quoteId, values) {
    const apiPayload = {
      quote: {
        counter: values['counter'],
      },
    };

    return axios
      .put(`${this.baseUrl}/quotes/${quoteId}/counter`, apiPayload)
      .then((response) => {
        return {
          ok: true,
          quote: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  respondCounterQuote(quoteId, action) {
    return axios
      .put(`${this.baseUrl}/quotes/${quoteId}/${action}_counter`)
      .then((response) => {
        return {
          ok: true,
          quote: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  updateQuote(quoteId, values) {
    const apiPayload = {
      quote: {
        misc_cost: values['miscCost'],
        contractor_id: values['contractorId'],
        estimate: values['estimate'],
        value: values['value'],
      },
    };

    return axios
      .put(`${this.baseUrl}/quotes/${quoteId}`, apiPayload)
      .then((response) => {
        return {
          ok: true,
          quote: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  deleteQuote(quoteId) {
    return axios
      .delete(`${this.baseUrl}/quotes/${quoteId}`)
      .then((response) => {
        return {
          ok: true,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  createQuote(values) {
    const apiPayload = {
      quote: {
        task_id: values['taskId'],
        start_date: values['startDate'],
        estimate: values['cost'],
        days_of_duration: values['duration'],
        material_lead_days: values['materialLeadDays'],
        description: values['description'],
      },
    };

    return axios
      .post(`${this.baseUrl}/quotes`, apiPayload)
      .then((response) => {
        return {
          ok: true,
          quote: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  /**
   *
   * @param quoteId
   * @param values - {startDate: string, cost: number, duration: number, description: string}
   * @return {Promise<{ok: boolean, quotes: any} | {ok: boolean, errors: *}>}
   */
  submitQuote(quoteId, values) {
    const apiPayload = {
      quote: {
        start_date: values['startDate'],
        estimate: values['cost'],
        days_of_duration: values['duration'],
        material_lead_days: values['materialLeadDays'],
        description: values['description'],
      },
    };

    return axios
      .put(`${this.baseUrl}/quotes/${quoteId}/submit`, apiPayload)
      .then((response) => {
        return {
          ok: true,
          quote: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  denyQuote(quoteId, reason) {
    const data = {};
    if (reason !== undefined) {
      data['quote'] = {
        denied_reason: reason,
      };
    }

    return axios
      .put(this.baseUrl + '/quotes/' + quoteId + '/deny', data)
      .then((response) => {
        return {
          ok: true,
          quote: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }

  withdrawQuote(quoteId) {
    return axios
      .put(`${this.baseUrl}/quotes/${quoteId}/withdraw`)
      .then((response) => {
        return {
          ok: true,
          quote: response.data,
        };
      })
      .catch((error) => {
        return {
          ok: false,
          errors: error.response.data.errors,
        };
      });
  }
}

export const quotesApi = new QuotesApi();
