import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, FormGroup } from 'react-bootstrap';
import Input from 'components/shared/input/Input';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import LogoUpload from 'components/shared/logo-upload/LogoUpload';
import StampForm from 'components/shared/stamp/StampForm';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import {
  getUnions,
  getSelectedUnionsId,
} from 'components/admin/companies/store/selectors';
import { loadUnions } from 'components/admin/companies/store/actions';
import FormCheckBoxGroup from 'components/shared/form/FormCheckBoxGroup';
import 'components/admin/companies/CompaniesEditForm.css';

class CompaniesEditForm extends Component {
  static propTypes = {
    unions: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      showOtherUnion: this.props.initialValues
        ? this.props.initialValues.showOtherUnion
        : false,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(loadUnions());
  }

  componentDidUpdate(prevProps) {
    const { selectedUnionsId } = this.props;
    const { showOtherUnion } = this.state;
    if (
      selectedUnionsId &&
      selectedUnionsId.includes('other') &&
      !showOtherUnion
    ) {
      this.setState({ showOtherUnion: true });
    }
  }

  setLogo = (props, files) => {
    const file = files[0];
    const logo = {
      url: URL.createObjectURL(file),
      file: file,
    };
    props.input.onChange(logo);
  };

  removeLogo = (props) => {
    props.input.onChange({ ...props.input.value, destroy: true });
  };

  logoComponent = (props, logo) => {
    return (
      <LogoUpload
        className="form-uploader"
        extensions={['.jpg', '.jpeg', '.gif', '.png', '.heic']}
        logos={[props.input.value].filter((logo) => !!logo && !logo.destroy)}
        onRemove={() => this.removeLogo(props)}
        onUpload={(files) => this.setLogo(props, files)}
        useDropzone={true}
      />
    );
  };

  handleStampFormChange = (props, attr, value) => {
    props.input.onChange({ ...props.input.value, [attr]: value });
  };

  stampFormComponent = (props) => {
    return (
      <StampForm
        stamp={props.input.value || undefined}
        error={props.meta.error && props.meta.submitFailed}
        onChange={(attr, value) =>
          this.handleStampFormChange(props, attr, value)
        }
      />
    );
  };

  renderInput = (props) => {
    return (
      <Input
        valid={!props.meta.error || !props.meta.submitFailed}
        value={props.input.value}
        onChange={(value) => props.input.onChange(value)}
        {...props}
      />
    );
  };

  render() {
    const {
      unions,
      initialValues,
      handleSubmit,
      invalid,
      submitFailed,
      onCancel,
    } = this.props;
    const { showOtherUnion } = this.state;

    return (
      <div className="edit-company">
        <DetailsTitle
          className="main-form-title"
          title={`${initialValues ? 'EDIT' : 'NEW'} COMPANY`}
        />
        <div className="content-container">
          <DetailsTitle
            title="Company Information"
            rightButtons={[
              <Button
                key="cancel"
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.WHITE}
                label="Cancel"
                onClick={onCancel}
              />,
              <Button
                key="save"
                type={BUTTON_TYPES.CONFIRMATION}
                color={BUTTON_COLORS.GREEN}
                label="Save"
                onClick={handleSubmit}
              />,
            ]}
          />
          <div className="company-form-row">
            <Col sm={8} lg={8} className="edit-company-form">
              <Form horizontal onSubmit={handleSubmit}>
                <FormGroup>
                  <Col sm={6}>
                    <Field
                      component={this.renderInput}
                      name="name"
                      placeholder="Company Name"
                      type="text"
                    />
                  </Col>
                  <Col sm={6}>
                    <Field
                      component={this.renderInput}
                      name="licenceNumber"
                      placeholder="License Number"
                      type="text"
                    />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col sm={6}>
                    <Field
                      component={this.renderInput}
                      name="firstName"
                      placeholder="Contact First Name"
                      type="text"
                    />
                  </Col>
                  <Col sm={6}>
                    <Field
                      component={this.renderInput}
                      name="lastName"
                      placeholder="Contact Last Name"
                      type="text"
                    />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col sm={6}>
                    <Field
                      component={this.renderInput}
                      name="email"
                      placeholder="Email"
                      type="email"
                    />
                  </Col>
                  <Col sm={6}>
                    <Field
                      component={this.renderInput}
                      name="phoneNumber"
                      placeholder="Phone Number"
                      type="text"
                    />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col sm={6}>
                    <Field
                      component={this.renderInput}
                      name="streetAddress"
                      placeholder="Street Address"
                      type="text"
                    />
                  </Col>
                  <Col sm={6}>
                    <Field
                      component={this.renderInput}
                      name="unitNumber"
                      placeholder="Unit number / Floor"
                      type="text"
                    />
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Col sm={6}>
                    <Field
                      component={this.renderInput}
                      name="city"
                      placeholder="City"
                      type="text"
                    />
                  </Col>
                  <Col sm={3}>
                    <Field
                      component={this.renderInput}
                      name="state"
                      placeholder="State"
                      type="text"
                    />
                  </Col>
                  <Col sm={3}>
                    <Field
                      component={this.renderInput}
                      name="zipCode"
                      placeholder="Zip Code"
                      type="text"
                    />
                  </Col>
                </FormGroup>

                <DetailsTitle title="Company Logo" />
                <FormGroup>
                  <Field component={this.logoComponent} name="logo" />
                </FormGroup>

                {invalid && submitFailed && (
                  <div className="invalid-message">
                    The outlined inputs should be added in order to submit the
                    form.
                  </div>
                )}

                <DetailsTitle title="Which unions do you belong to?" />
                <FormGroup>
                  <Col sm={8}>
                    <Field
                      component={FormCheckBoxGroup}
                      name="selectedUnionsId"
                      options={unions.map((union) => {
                        return {
                          id: union.value === 'other' ? union.value : union.id,
                          value: union.label,
                          name: union.label,
                        };
                      })}
                      reduxForm={true}
                    />
                    {showOtherUnion && (
                      <div className="union-other-wrapper">
                        <Field
                          component={this.renderInput}
                          name="otherUnion"
                          placeholder="Other Union"
                          type="text"
                        />
                      </div>
                    )}
                  </Col>
                </FormGroup>
              </Form>
            </Col>
            <Col sm={12} lg={12} className="special-note">
              <Field component={this.stampFormComponent} name="stamp" />
            </Col>
          </div>
        </div>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};
  if (!values.name) {
    errors.name = 'Required';
  }
  if (!values.firstName) {
    errors.firstName = 'Required';
  }
  if (!values.lastName) {
    errors.lastName = 'Required';
  }
  if (!values.email) {
    errors.email = 'Required';
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = 'Required';
  }
  if (!values.streetAddress) {
    errors.streetAddress = 'Required';
  }
  if (!values.city) {
    errors.city = 'Required';
  }
  if (!values.state) {
    errors.state = 'Required';
  }
  if (!values.zipCode) {
    errors.zipCode = 'Required';
  }
  if (values.stamp && !values.stamp.destroy) {
    const { stamp } = values;
    errors.stamp =
      !stamp.legal ||
      !stamp.stampActions ||
      !stamp.stampActions.find((stampAction) => !stampAction.destroy) ||
      !!stamp.stampActions.find(
        (stampAction) => !stampAction.destroy && !stampAction.title
      );
  }
  return errors;
}

CompaniesEditForm = connect((state) => {
  return {
    unions: getUnions(state),
    selectedUnionsId: getSelectedUnionsId(state),
  };
})(CompaniesEditForm);

export default reduxForm({
  form: 'CompaniesEditForm',
  validate,
})(CompaniesEditForm);
