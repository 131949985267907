import axios from 'axios';
import numeral from 'numeral';

import BaseApi from './base-api';

class FieldReportsApi extends BaseApi {
  getFieldReports(projectId, filters) {
    const url = this.getAllUrl(projectId, filters);
    return axios
      .get(url)
      .then((response) => ({
        ok: true,
        reports: response.data,
        total: numeral(response.headers['total']).value(),
        pageSize: numeral(response.headers['per-page']).value(),
      }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  getFieldReport(projectId, reportId, reload = false, loadingDisabled = false) {
    let url = `${this.baseUrl}/projects/${projectId}/field_reports/${reportId}`;
    if (reload) url += '?reload=true';
    return axios
      .get(url, { loadingDisabled })
      .then((response) => ({ ok: true, report: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  sendEmail(projectId, reportId, emails, subject, message) {
    const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
    const url = `${this.baseUrl}/projects/${projectId}/field_reports/${reportId}/email`;

    let apiPayload = new FormData();
    emails.map((item) => apiPayload.append(`field_report[emails][]`, item));
    apiPayload.append(`field_report[subject]`, subject);
    apiPayload.append(`field_report[message]`, message);

    return axios
      .post(url, apiPayload, headers)
      .then((response) => ({ ok: true, data: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  getFieldReportsLogs(projectId) {
    const url = `${this.baseUrl}/projects/${projectId}/field_reports_log/print`;
    return axios
      .get(url, { responseType: 'blob' })
      .then((response) => ({ ok: true, pdf: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  deleteFieldReport(projectId, reportId) {
    return axios
      .delete(`${this.baseUrl}/projects/${projectId}/field_reports/${reportId}`)
      .then(() => ({ ok: true }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  getLastReport(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/field_reports/last`)
      .then((response) => ({ ok: true, report: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  getFieldReportsCreators(projectId) {
    return axios
      .get(`${this.baseUrl}/projects/${projectId}/field_reports/creators`)
      .then((response) => ({ ok: true, creators: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  saveFieldReport(projectId, report, preview = false) {
    const headers = {
      headers: { 'Content-Type': 'multipart/form-data' },
      loadingDisabled: preview,
    };
    const url = this.getSaveUrl(projectId, report);
    const method = this.getSaveMethod(report);
    const data = this.getSavePayload(report, preview);

    return axios[method](url, data, headers)
      .then((response) => ({ ok: true, report: response.data }))
      .catch((error) => ({ ok: false, errors: error.response.data.errors }));
  }

  getAllUrl(projectId, filters) {
    let url = `${this.baseUrl}/projects/${projectId}/field_reports`;
    url += `?page=${filters.page || 1}`;

    if (filters.date && filters.date !== 'all') {
      url += `&date_order=${filters.date}`;
    }

    if (filters.publishedBy && filters.publishedBy !== 'all') {
      url += `&published_by_order=${filters.publishedBy}`;
    }

    if (filters.status && filters.status !== 'all') {
      url += `&${filters.status}=true`;
    }

    if (filters.createdBy && filters.createdBy.length > 0) {
      url += `&by_created_user=${filters.createdBy.join(',')}`;
    }

    return url;
  }

  getSaveUrl(projectId, report) {
    let url = `${this.baseUrl}/projects/${projectId}/field_reports`;
    if (report.id) url += `/${report.id}`;
    if (report.id && !report.draft) url += '/submit';
    return url;
  }

  getSaveMethod(report) {
    if (report.id && report.draft) return 'put';
    return 'post';
  }

  getSavePayload(report, preview) {
    let apiPayload = new FormData();

    apiPayload.append('field_report[date]', report.date);

    // this have the same name on the api and on the frontend
    const optionalParams = [
      'draft',
      'title',
      'arrival_time',
      'departure_time',
      'latitude',
      'longitude',
      'description',
      'disclaimer',
      'weather_condition',
      'weather_humidity',
      'weather_precipitation',
      'weather_remarks',
      'weather_temperature',
      'weather_wind',
      'weather_wind_direction',
      'observation_trade_count',
      'observation_task',
      'observation_task_remarks',
      'observation_contract_complete',
      'observation_schedule_status',
      'observation_schedule_remarks',
      'observation_design_complience',
      'observation_design_remarks',
      'observation_quality_complience',
      'observation_quality_remarks',
      'observation_safety_complience',
      'observation_safety_remarks',
      'observation_equipment_remarks',
      'observation_material_remarks',
      'observation_testing_remarks',
      'observation_other_remarks',
    ];
    const selectorParams = [
      'weather_condition',
      'weather_precipitation',
      'weather_wind_direction',
      'observation_schedule_status',
      'observation_design_complience',
      'observation_quality_complience',
      'observation_safety_complience',
    ];

    optionalParams.forEach((k) => {
      if (report[k]) {
        apiPayload.append(`field_report[${k}]`, report[k]);
      } else if (selectorParams.includes(k)) {
        apiPayload.append(`field_report[${k}]`, 'none');
      } else if (k === 'title') {
        apiPayload.append(`field_report[${k}]`, 'General Observation');
      } else if (typeof report[k] === 'string') {
        apiPayload.append(`field_report[${k}]`, '');
      } else if (typeof report[k] === 'number') {
        apiPayload.append(`field_report[${k}]`, 0);
      }
    });
    if (report.timezone) {
      apiPayload.append('field_report[timezone]', report.timezone);
    }
    if (report.timezone_name) {
      apiPayload.append('field_report[timezone_name]', report.timezone_name);
    }
    if (report.trade)
      apiPayload.append('field_report[project_contributor_id]', report.trade);
    if (report.site)
      apiPayload.append('field_report[project_site_id]', report.site);
    if (report.area)
      apiPayload.append('field_report[project_area_id]', report.area);

    if (report.open_items.length > 0) {
      report.open_items.forEach((item) => {
        apiPayload.append(`field_report[open_items_ids][]`, item);
      });
    }

    if (report.user_ids) {
      report.user_ids.forEach((item) => {
        apiPayload.append(`field_report[user_ids][]`, item);
      });
    }

    this.appendFiles(apiPayload, report, 'project_images', 'image');
    this.appendFiles(apiPayload, report, 'report_images', 'image');
    this.appendFiles(apiPayload, report, 'report_documents', 'document');

    if (preview) apiPayload.append('field_report[preview]', true);

    return apiPayload;
  }

  appendFiles(apiPayload, values, key, type) {
    const files = values[key];
    const deletedFiles = values[`deleted_${key}`];

    let index = 0;
    files.forEach(({ id, file, description }) => {
      if (id) {
        apiPayload.append(`field_report[${key}_attributes][${index}][id]`, id);
      } else if (file) {
        apiPayload.append(
          `field_report[${key}_attributes][${index}][${type}]`,
          file,
          file.name
        );
      }
      if (type === 'image' && (file || id))
        apiPayload.append(
          `field_report[${key}_attributes][${index}][description]`,
          description || ''
        );
      index++;
    });

    deletedFiles
      .filter((file) => file.id)
      .forEach((file) => {
        apiPayload.append(
          `field_report[${key}_attributes][${index}][id]`,
          file.id
        );
        apiPayload.append(
          `field_report[${key}_attributes][${index}][_destroy]`,
          true
        );
        index++;
      });
  }
}

export const fieldReportsApi = new FieldReportsApi();
