import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './ProjectMeetingMinutes.css';
import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';
import { getShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/selectors';
import { sendProjectResourceEmails } from 'components/admin/projects/details/store/actions';
import ProjectEmailModal from 'components/admin/projects/details/project-email-modal/ProjectEmailModal';
import MessageModal from 'components/shared/modal/MessageModal';
import SimpleTable from 'components/shared/table/SimpleTable';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_ICONS,
} from 'components/shared/button/Button';
import { getNotifications } from 'selectors/notifications';
import { unreadNotifications } from 'domain/notification';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import {
  getMeetingInfo,
  getMeetingInfoEdit,
  getMeetings,
  getMeetingModalShow,
  getMeetingPdf,
  getShowPdfViewer,
  getConfirmationMsg,
  getCurrentMeeting,
  getMeetingTypes,
  getSelectedMeetingType,
} from 'components/admin/projects/details/project-meeting-minutes/store/selectors';
import {
  initMeetingMinutes,
  loadMeetingInfoRequest,
  setMeetingInfoEdit,
  createMeetingInfo,
  updateMeetingInfo,
  loadMeetingsRequest,
  setMeetingModalShow,
  setCurrentMeeting,
  loadMeetingPdfRequest,
  togglePdfViewer,
  setConfirmationMsg,
  openMeeting,
  deleteMeetingRequest,
  loadMeetingTypesRequest,
  setMeetingTypes,
  setSelectedMeetingType,
} from 'components/admin/projects/details/project-meeting-minutes/store/actions';
import MeetingInfo from 'components/admin/projects/details/project-meeting-minutes/MeetingInfo';
import Date from 'components/shared/text/Date';
import { formatPhoneNumber } from 'services/utils/text-util';
import BaseStatus from 'components/shared/text/BaseStatus';
import MeetingModal from 'components/admin/projects/details/project-meeting-minutes/MeetingModal';
import { getCurrentUser } from 'selectors/authentication';
import { MeetingStatusEnum } from 'domain/meeting-status-enum';
import { markNotifiableNotificationsAsRead } from 'actions/notifications';
import PdfViewer from 'components/shared/pdf-viewer/PdfViewer';
import { getDarkMode } from 'selectors/theme';
import DetailsTitle from 'components/shared/details/DetailsTitle';
import DropdownMenu, {
  DROPDOWN_TYPES,
} from 'components/shared/menu/DropdownMenu';
import MeetingPermissions from 'permissions/meeting-permissions';

const ProjectMeetingMinutes = ({
  currentUser,
  currentProject,
  meetingInfo,
  meetingTypes,
  selectedMeetingType,
  meetingInfoEdit,
  meetings,
  meetingModalShow,
  showPdfViewer,
  meetingPdf,
  confirmationMsg,
  notifications,
  showEmailModal,
  currentMeeting,
  darkMode,
  dispatch,
  renderHeaders,
}) => {
  const handleEditMeetingInfo = () => {
    dispatch(setMeetingInfoEdit(true));
  };

  const handleViewPdf = (meetingId) => {
    dispatch(loadMeetingPdfRequest(currentProject.id, meetingId)).then(
      (response) => {
        if (response.ok) {
          dispatch(togglePdfViewer());
        }
      }
    );
  };

  const handlePdfViewerClose = () => {
    dispatch(togglePdfViewer());
  };

  const handleSubmitMeetingInfo = (values) => {
    let promise = null;
    if (meetingInfo) {
      promise = dispatch(
        updateMeetingInfo(currentProject.id, values, selectedMeetingType)
      );
    } else {
      promise = dispatch(
        createMeetingInfo(currentProject.id, values, selectedMeetingType)
      );
    }

    promise.then((response) => {
      if (response.ok) {
        dispatch(setMeetingInfoEdit(false));
      }
    });
  };

  const handleCancelMeetingInfo = () => {
    dispatch(setMeetingInfoEdit(false));
  };

  const handleShowEmailModal = (meeting) => {
    dispatch(setCurrentMeeting(meeting));
    dispatch(setShowEmailModal(true));
  };

  const handleSendEmail = (emailsData) => {
    dispatch(
      sendProjectResourceEmails(currentMeeting.id, 'Meeting', emailsData)
    );
  };

  const buildEmailModalSubject = () =>
    `${currentProject.number} ${currentProject.name} - Meeting ${currentMeeting.number}`;

  const buildEmailModalMessage = () => {
    let location = '';
    if (
      currentProject.project_location &&
      currentProject.project_location.location
    ) {
      const projectLocation = currentProject.project_location.location;
      location = ` at ${projectLocation.street_address_1}, ${projectLocation.city} ${projectLocation.state}`;
    }

    return `Please find the attached Meeting #${currentMeeting.number} for Project ${currentProject.name}${location}. If you have any questions, please reach out via chat or email.`;
  };

  const handleGenerateLink = (meetingId) => {
    return `?meetingId=${meetingId}`;
  };

  const mapMeetings = () => {
    const unreadById = unreadNotifications(notifications, 'Meeting');

    return meetings.map((meeting) => {
      const submittedBy = meeting.submitted_by;
      const meetingStatusEnum = MeetingStatusEnum.create(meeting.status);
      const statusLabel = meetingStatusEnum.label;
      const draftMeeting = MeetingStatusEnum.DRAFT.equals(meetingStatusEnum);
      const unread = unreadById && !!unreadById[meeting.id];
      const isMeetingDeletable = MeetingPermissions.canDelete(
        currentUser,
        meeting,
        meetings
      );

      return {
        id: meeting.id,
        data: [
          <div>
            {unread && (
              <BaseStatus
                labels={[
                  {
                    id: 'unread',
                    label: '',
                  },
                ]}
                status="unread"
              />
            )}
            {meeting.number}
          </div>,
          <Date value={meeting.meeting_date} format="MM/DD/YYYY" />,
          meeting.summary || `Meeting Minutes ${meeting.number}`,
          submittedBy
            ? `${submittedBy.first_name} ${submittedBy.last_name}${
                submittedBy.title ? ` - ${submittedBy.title}` : ''
              }`
            : '-',
          <div className={draftMeeting ? 'draft' : ''}>
            {statusLabel.charAt(0).toUpperCase()}
            {statusLabel.slice(1)}
          </div>,
          meeting.approved_at ? (
            <Date value={meeting.approved_at} format="MM/DD/YYYY" />
          ) : (
            '-'
          ),
          <div className="action-buttons">
            <Button
              type={BUTTON_TYPES.LINK}
              color={BUTTON_COLORS.GREEN}
              label="Email"
              onClick={() => handleShowEmailModal(meeting)}
            />
            <Button
              type={BUTTON_TYPES.LINK}
              color={BUTTON_COLORS.GREEN}
              label="PDF"
              onClick={() => handleViewPdf(meeting.id)}
            />
            <Button
              type={BUTTON_TYPES.LINK}
              color={BUTTON_COLORS.GREEN}
              label="Open"
              onClick={() => handleViewDetails(meeting.id)}
            />
            {isMeetingDeletable && (
              <Button
                type={BUTTON_TYPES.LINK}
                color={BUTTON_COLORS.GREEN}
                label="Delete"
                onClick={() => handleDeleteMeeting(meeting.id)}
              />
            )}
          </div>,
        ],
      };
    });
  };

  const handleAddMeeting = () => {
    dispatch(setCurrentMeeting(null));
    dispatch(setMeetingModalShow(true));
  };

  const handleViewDetails = (meetingId) => {
    dispatch(openMeeting(currentProject.id, meetingId));
    dispatch(markNotifiableNotificationsAsRead(meetingId, 'Meeting'));
  };

  const handleDeleteMeeting = (meetingId) => {
    const confirmed = window.confirm('Are you sure?');
    confirmed &&
      dispatch(deleteMeetingRequest(currentProject.id, meetingId)).then(
        (response) => {
          if (response.ok) {
            dispatch(
              loadMeetingsRequest(currentProject.id, selectedMeetingType)
            );
          }
        }
      );
  };

  const handleConfirm = () => {
    dispatch(setConfirmationMsg(''));
    dispatch(setMeetingModalShow(false));
  };

  const renderHeaderButtons = () => {};

  const getMeetingTypesOptions = (rootTypes) => {
    const options = [];

    const addTypes = (types, child = 0) =>
      types.forEach((type) => {
        options.push({
          value: type.id,
          label: type.name,
          child: child,
        });
        if (type.children) addTypes(type.children, child + 1);
      });
    addTypes(rootTypes);
    return options;
  };

  const makeTypesSelector = () => (
    <DropdownMenu
      type={DROPDOWN_TYPES.SECTION_SELECTOR}
      className="meeting-types-selector"
      options={getMeetingTypesOptions(meetingTypes)}
      value={selectedMeetingType}
      onChange={({ value }) => dispatch(setSelectedMeetingType(value))}
    />
  );

  if (!currentProject) return null;

  useEffect(() => {
    dispatch(initMeetingMinutes());
    dispatch(loadMeetingTypesRequest(currentProject.id)).then((response) => {
      if (!response.ok || selectedMeetingType || !response.meetingTypes.length)
        return;

      dispatch(setSelectedMeetingType(response.meetingTypes[0].id));
    });

    return () => {
      dispatch(setMeetingTypes());
      dispatch(setSelectedMeetingType());
    };
  }, []);

  useEffect(() => {
    if (!selectedMeetingType) return;

    dispatch(loadMeetingsRequest(currentProject.id, selectedMeetingType));
    dispatch(loadMeetingInfoRequest(currentProject.id, selectedMeetingType));
  }, [selectedMeetingType]);

  if (!selectedMeetingType) return null;

  const canAddMeeting = MeetingPermissions.canAdd(currentUser, currentProject);

  const canEditMeetingInfo = MeetingPermissions.canEditInfo(
    currentUser,
    currentProject
  );

  const canEditMeetings = MeetingPermissions.canEdit(
    currentUser,
    currentProject
  );

  const isMeetingPrimary = MeetingPermissions.canPrimaryContribute(
    currentUser,
    currentProject
  );

  let initialMeetingInfoValues = {};
  if (meetingInfo) {
    initialMeetingInfoValues = {
      dayOfWeek: meetingInfo.day_of_week,
      startsAt: meetingInfo.starts_at,
      endsAt: meetingInfo.ends_at,
      timeZone: meetingInfo.time_zone,
      meetingLocation: meetingInfo.meeting_location,
      siteWalk: meetingInfo.site_walk,
      phoneNumber: formatPhoneNumber(meetingInfo.phone_number),
      code: meetingInfo.code,
      hostPin: meetingInfo.host_pin,
      webLink: meetingInfo.web_link,
    };
  }

  return (
    <div
      className={`content-container project-meeting-minutes ${
        darkMode ? 'dark-mode' : ''
      }`}
    >
      {renderHeaders(renderHeaderButtons(), null, makeTypesSelector())}
      <MeetingInfo
        meetingInfoEdit={meetingInfoEdit}
        initialMeetingInfoValues={initialMeetingInfoValues}
        meetingInfo={meetingInfo}
        showActions={canEditMeetingInfo}
        handleSubmitMeetingInfo={handleSubmitMeetingInfo}
        handleCancelMeetingInfo={handleCancelMeetingInfo}
        handleEditMeetingInfo={handleEditMeetingInfo}
      />

      <div className="meeting-minutes">
        <DetailsTitle
          title="Meeting Minutes"
          rightButtons={[
            canAddMeeting && (
              <Button
                key="add-meeting"
                type={BUTTON_TYPES.LINK}
                icon={BUTTON_ICONS.PLUS}
                label="Add Meeting"
                onClick={handleAddMeeting}
              />
            ),
          ].filter((button) => button)}
        />
        <div className="meeting-minutes-table-container">
          <SimpleTable
            className="projects-table meeting-minutes-table"
            headers={[
              '#',
              'MEETING DATE',
              'TITLE',
              'SUBMITTED BY',
              'STATUS',
              'PUBLISHED',
              'ACTIONS',
            ]}
            rows={mapMeetings()}
            emptyMessage="No Meetings Added"
            onGenerateLink={handleGenerateLink}
            onRowSelected={(meetingId) => handleViewDetails(meetingId)}
          />
        </div>
      </div>

      <PdfViewer
        content={{
          source: meetingPdf,
          type: 'blob',
        }}
        show={showPdfViewer}
        onClose={handlePdfViewerClose}
      />

      {meetingModalShow && (
        <MeetingModal
          showActions={canEditMeetings}
          primary={isMeetingPrimary}
        />
      )}

      {confirmationMsg && (
        <MessageModal
          show={!!confirmationMsg}
          message="Thank you!"
          subMessage={confirmationMsg}
          buttonMessage="Continue"
          onHide={handleConfirm}
        />
      )}

      {showEmailModal && (
        <ProjectEmailModal
          subTitle={currentProject.name}
          handleSendEmail={handleSendEmail}
          projectId={currentProject.id}
          initialSubject={buildEmailModalSubject()}
          initialMessage={buildEmailModalMessage()}
        />
      )}
    </div>
  );
};

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    meetingInfo: getMeetingInfo(state),
    meetingTypes: getMeetingTypes(state),
    selectedMeetingType: getSelectedMeetingType(state),
    meetingInfoEdit: getMeetingInfoEdit(state),
    meetings: getMeetings(state),
    meetingModalShow: getMeetingModalShow(state),
    currentUser: getCurrentUser(state),
    showPdfViewer: getShowPdfViewer(state),
    meetingPdf: getMeetingPdf(state),
    confirmationMsg: getConfirmationMsg(state),
    notifications: getNotifications(state),
    showEmailModal: getShowEmailModal(state),
    currentMeeting: getCurrentMeeting(state),
    darkMode: getDarkMode(state),
  };
})(ProjectMeetingMinutes);
