import {
  NOTIFICATIONS_ADD,
  NOTIFICATIONS_NOTIFICATION_UPDATE,
  NOTIFICATIONS_SET,
  NOTIFICATIONS_TOTAL_UPDATE,
  NOTIFICATIONS_UNREAD_TOTAL_UPDATE,
  NOTIFICATIONS_UPDATE,
  NOTIFICATIONS_CHAT_UPDATE,
} from '../actions/notifications';
import moment from 'moment';

const initialState = {
  notifications: null, // { content: array, total: number, totalUnread: number, pageSize: number }
};

export default function notificationsDataReducer(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATIONS_ADD:
      const newContent = state.notifications.content.slice();
      newContent.unshift(action.payload);
      return {
        ...state,
        notifications: {
          ...state.notifications,
          content: newContent,
        },
      };

    case NOTIFICATIONS_SET:
      return {
        ...state,
        notifications: action.payload,
      };

    case NOTIFICATIONS_TOTAL_UPDATE:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          total: action.payload,
        },
      };

    case NOTIFICATIONS_CHAT_UPDATE:
      const newNotificationsContent = state.notifications.content.map(
        (notification) => {
          if (
            notification.notifiable_type === 'Note' &&
            notification.notifiable.noteable_id === action.payload.id &&
            notification.notifiable.noteable_type === action.payload.type
          ) {
            return {
              ...notification,
              read_at: moment(),
            };
          } else {
            return notification;
          }
        }
      );
      return {
        ...state,
        notifications: {
          ...state.notifications,
          content: newNotificationsContent,
          totalUnread: newNotificationsContent.filter(
            (notification) => !notification.read_at
          ).length,
        },
      };

    case NOTIFICATIONS_UPDATE:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          content: state.notifications.content.map((notification) => {
            const found = action.payload.find(
              (temp) => temp.id === notification.id
            );
            if (found !== undefined) {
              return found;
            } else {
              return notification;
            }
          }),
        },
      };

    case NOTIFICATIONS_UNREAD_TOTAL_UPDATE:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          totalUnread: action.payload,
        },
      };

    case NOTIFICATIONS_NOTIFICATION_UPDATE:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          content: state.notifications.content.map((notification) => {
            if (notification.id === action.payload.id) {
              return action.payload;
            } else {
              return notification;
            }
          }),
        },
      };

    default:
      return state;
  }
}
