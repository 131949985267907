import { createSelector } from 'reselect';

export const getBuildings = createSelector(
  (state) => state.team.scenes.client.data.buildings,
  (buildings) => {
    return buildings;
  }
);

export const getClient = createSelector(
  (state) => state.team.scenes.client.data.client,
  (client) => {
    return client;
  }
);

export const getClientForm = createSelector(
  (state) => state.team.scenes.client.ui.clientForm,
  (clientForm) => {
    return clientForm;
  }
);

export const getCurrentUserId = createSelector(
  (state) => state.team.scenes.client.ui.currentUserId,
  (currentUserId) => {
    return currentUserId;
  }
);

export const getInvalidFields = createSelector(
  (state) => state.team.scenes.client.ui.invalidFields,
  (invalidFields) => {
    return invalidFields;
  }
);

export const getRoles = createSelector(
  (state) => state.team.scenes.client.data.roles,
  (roles) => {
    return roles;
  }
);

export const getShowInviteUserModal = createSelector(
  (state) => state.team.scenes.client.ui.showInviteUserModal,
  (showInviteUserModal) => {
    return showInviteUserModal;
  }
);

export const getUserForm = createSelector(
  (state) => state.team.scenes.client.ui.userForm,
  (userForm) => {
    return userForm;
  }
);

export const getUserModalState = createSelector(
  (state) => state.team.scenes.client.ui.userModalState,
  (userModalState) => {
    return userModalState;
  }
);

export const getUsers = createSelector(
  (state) => state.team.scenes.client.data.users,
  (users) => {
    return users;
  }
);

export const getValidationMessage = createSelector(
  (state) => state.team.scenes.client.ui.validationMessage,
  (validationMessage) => {
    return validationMessage;
  }
);

export const getVerificationModal = createSelector(
  (state) => state.team.scenes.client.ui.verificationModal,
  (verificationModal) => {
    return verificationModal;
  }
);
