import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getDarkMode } from 'selectors/theme';
import DocumentLinkBluebeam from 'components/shared/document/DocumentLinkBluebeam';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const DocumentPreview = (props) => {
  const { documents, onRemove, onOrder, disabled } = props;

  const sortDisabled = typeof onOrder !== 'function';

  const handleRemove = (doc, index) => () => {
    const docs = documents.filter((_, i) => i !== index);
    onRemove(doc, index, docs);
  };

  const handleDrag = ({ source, destination }) => {
    if (!destination || destination.index === source.index) return;

    const newDocuments = [...documents];
    const movedDocuments = newDocuments.splice(source.index, 1);
    newDocuments.splice(destination.index, 0, ...movedDocuments);
    newDocuments.forEach((d, index) => (d.position = index));

    onOrder(newDocuments, source, destination);
  };

  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable
        direction="vertical"
        droppableId="dropable"
        isDropDisabled={sortDisabled}
      >
        {(provided) => (
          <div
            {...provided.droppableProps}
            className="document-previews"
            ref={provided.innerRef}
          >
            {documents.map((doc, index) => (
              <Draggable
                index={index}
                key={doc.id || index}
                draggableId={(doc.id || index).toString()}
                isDragDisabled={sortDisabled}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <DocumentLinkBluebeam
                      onRemove={
                        !disabled ? handleRemove(doc, index) : undefined
                      }
                      file={doc}
                    />
                  </div>
                )}
              </Draggable>
            ))}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DocumentPreview.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape()),
  onRemove: PropTypes.func.isRequired,
  onOrder: PropTypes.func,
  darkMode: PropTypes.bool,
  disabled: PropTypes.bool,
};

DocumentPreview.defaultProps = {
  documents: [],
  darkMode: true,
  disabled: false,
};

export default connect((state) => ({ darkMode: getDarkMode(state) }))(
  DocumentPreview
);
