import { VENDOR_INVOICES_ADD_INVOICE_MODAL_SHOW } from 'components/admin/work-orders/details/vendor-invoices/store/action-types';

const initialState = {
  showAddInvoiceModal: false,
};

export default function vendorInvoicesUiReducer(state = initialState, action) {
  switch (action.type) {
    case VENDOR_INVOICES_ADD_INVOICE_MODAL_SHOW:
      return {
        ...state,
        showAddInvoiceModal: action.payload,
      };

    default:
      return state;
  }
}
